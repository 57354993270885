import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

export default function PdfViewer(props) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(null);
  /*To Prevent right click on screen*/
  document.addEventListener('contextmenu', (event) => {
    event.preventDefault();
  });

  /*When document gets loaded successfully*/
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  return (
      <div className="main">
        <Document file={props.url} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.apply(null, Array(numPages))
            .map((x, i) => i + 1)
            .map((page) => (
              <Page pageNumber={page} />
            ))}
        </Document>
      </div>
  );
}
