import { put, takeLatest, call, takeEvery } from 'redux-saga/effects';
import {
  CULTURE_ASSESSMENTS_REVIEWLIST_SAGA,
  GET_ALLOCATE_JOB,
  GET_ALLOCATE_JOB_GROUP,
  GET_JOBDOMAIN_REVIEW_LIST_SAGA,
  GET_JOBFUNCTION_REVIEW_LIST_SAGA,
  GET_JOBPROFILE_REVIEW_LIST_SAGA,
  GET_JOBROLE_REVIEW_LIST_SAGA,
  GET_JOB_NODE_JOB_REVIEW_LIST_SAGA,
  JOB_ASSESSMENTS_REVIEWLIST_SAGA,
  JOB_GROUP_JOB_REVIEWLIST_SAGA,
  JOB_TYPE_JOB_REVIEWLIST_SAGA,
  LOADER_START,
  LOADER_STOP,
  RELATED_REVIEWLIST_DISTINCT_DATA,
  REVIEWLIST_DISTINCT_DATA,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MIDDLEPANE_STATE,
  SET_POPUP_VALUE,
  SET_REVIEW_LIST_RELATE_DATA
} from '../../actionType';
import { CULTURE_MODULE_URL, JOB_MODULE_URL } from '../../endpoints';
import { EXCEPTION_ERROR_MESSAGE } from '../../errorMessage';
import Store from '../../store';

const apiCallFunction = async (requestObj) => {
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      Authorization: localStorage.getItem('idToken')
    }),
    body: JSON.stringify(requestObj.data)
  };
  const response = await fetch(requestObj.URL, requestOptions);
  const json = await response.json();
  return json;
};

function* workerJobProfileReviewListSaga(data) {
  try {
    const response = yield call(apiCallFunction, {
      data: data.payload.request,
      URL: JOB_MODULE_URL
    });
    if (response.responseCode === '000') {
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: 'job-profiles',
            middlePaneHeaderBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderBadgeThree: '',
            middlePaneHeaderBadgeFour: '',
            middlePaneHeaderDuplicate: 'job-profiles',
            middlePaneHeaderDuplicateBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderDuplicateBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderDuplicateBadgeThree: '',
            middlePaneHeaderDuplicateBadgeFour: '',
            typeOfMiddlePaneList: data.payload.request.actionFilter === 'Job-Profile-Shared-List' ? 'MarketplaceJobProfilesDistinctReviewList' : 'jobProfilesDistinctReviewList',
            scanCount: response && response.countTotal,
            duplicateScanCount: response && response.countTotal,
            showMiddlePaneState: true,
            isSelectActive: data.payload.isSelectActive,
            selectedTagsArray: data.payload.selectedTagsArray
          }
        });
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: "previousMiddlePaneState", value: data.payload.request.actionFilter === 'Job-Profile-Shared-List' ? 'MarketplaceJobProfilesDistinctReviewList' : 'jobProfilesDistinctReviewList',
          },
        })
      }
      yield put({
        type: data.payload.isMiddlePaneList
          ? REVIEWLIST_DISTINCT_DATA
          : RELATED_REVIEWLIST_DISTINCT_DATA,
        payload: response.responseObject
      });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: response.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({ type: LOADER_STOP });
  }
}
function* workeJobGroupJobReviewListSaga(data) {
  try {
    const response = yield call(apiCallFunction, {
      data: data.payload.request,
      URL: JOB_MODULE_URL
    });
    // const response ={responseCode:'000',countTotal:30}
    if (response.responseCode === '000') {
      let responseObj = null;
      if (Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData.length > 0) {
        responseObj = {
          ...Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData[0],
          jobProfile: [
            ...Store.getState().DisplayPaneTwoReducer?.relatedReviewListDistinctData[0]?.jobProfile,
            ...response.responseObject[0]?.jobProfile
          ]
        };
        //to add list more and adding all the record
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
      } else {
        responseObj = response.responseObject[0];
      }
      yield put({
        type: RELATED_REVIEWLIST_DISTINCT_DATA,
        payload: [responseObj]
      });
      yield put({
        type: RELATED_REVIEWLIST_DISTINCT_DATA,
        payload: [response.responseObject[0]]
      });
      yield put({
        type: SET_REVIEW_LIST_RELATE_DATA,
        payload: response.responseObject
      });
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: data.payload.HeaderOne,
            middlePaneHeaderBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderBadgeThree: '',
            middlePaneHeaderBadgeFour: '',
            middlePaneHeaderDuplicate:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
            middlePaneHeaderDuplicateBadgeOne:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            middlePaneHeaderDuplicateBadgeTwo:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            middlePaneHeaderDuplicateBadgeThree:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
            middlePaneHeaderDuplicateBadgeFour:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour,
            typeOfMiddlePaneList: 'jobProfileGroupJobProfileReviewList',
            scanCount: response && response.countTotal,
            duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
            showMiddlePaneState: true
          }
        });
      }
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: response.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }

    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workeJobTypeJobReviewListSaga(data) {
  try {
    const response = yield call(apiCallFunction, {
      data: data.payload.request,
      URL: JOB_MODULE_URL
    });
    // const response ={responseCode:'000',countTotal:30}
    if (response.responseCode === '000') {
      let responseObj = null;
      if (Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData.length > 0) {
        responseObj = {
          ...Store.getState()?.DisplayPaneTwoReducer?.relatedReviewListDistinctData[0],
          jobProfile: [
            ...Store.getState()?.DisplayPaneTwoReducer?.relatedReviewListDistinctData[0]
              ?.jobProfile,
            ...response?.responseObject[0]?.jobProfile
          ]
        };
        //to add list more and adding all the record
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
      } else {
        responseObj = response.responseObject[0];
      }
      yield put({
        type: RELATED_REVIEWLIST_DISTINCT_DATA,
        payload: [responseObj]
      });
      yield put({
        type: RELATED_REVIEWLIST_DISTINCT_DATA,
        payload: [response.responseObject[0]]
      });
      yield put({
        type: SET_REVIEW_LIST_RELATE_DATA,
        payload: response.responseObject
      });
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: data.payload.HeaderOne,
            middlePaneHeaderBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderBadgeThree: data.payload.BadgeThree,
            middlePaneHeaderBadgeFour: data.payload.BadgeFour,
            middlePaneHeaderDuplicate:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
            middlePaneHeaderDuplicateBadgeOne:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            middlePaneHeaderDuplicateBadgeTwo:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            middlePaneHeaderDuplicateBadgeThree:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
            middlePaneHeaderDuplicateBadgeFour:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour,
            typeOfMiddlePaneList: 'jobProfileTypeJobProfileReviewList',
            scanCount: response && response.countTotal,
            duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
            showMiddlePaneState: true
          }
        });
      }
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: response.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }

    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerReviewListJobProfileAllocateSaga(data) {
  try {
    const userResponse = yield call(apiCallFunction, {
      data: data.payload.request,
      URL: JOB_MODULE_URL
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === '000') {
      let responseObj = null;
      if (Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData.length > 0) {
        responseObj = {
          ...data.payload.revisedGroupObject,
          jobProfile: [
            ...Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData[0].jobProfile,
            ...userResponse.responseObject
          ]
        };
        //to add list more and adding all the record
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
      } else {
        responseObj = {
          ...data.payload.revisedGroupObject,
          jobProfile: userResponse.responseObject
        };
      }
      yield put({
        type: SET_MIDDLEPANE_STATE,
        payload: {
          middlePaneHeader: data.payload.headerOne || 'job-profiles',
          middlePaneHeaderBadgeOne: 'distinct',
          middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
          middlePaneHeaderBadgeThree: '',
          middlePaneHeaderBadgeFour: '',
          middlePaneHeaderDuplicate:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
          middlePaneHeaderDuplicateBadgeOne:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
          middlePaneHeaderDuplicateBadgeTwo:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
          middlePaneHeaderDuplicateBadgeThree:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
          middlePaneHeaderDuplicateBadgeFour:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour,
          typeOfMiddlePaneList: data.payload.typeOfMiddlePaneList,
          scanCount: userResponse && userResponse.countTotal,
          duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
          showMiddlePaneState: true,
          isSelectActive: 'multiple',
          selectedTagsArray: data.payload.existingJobProfileId,
          unselectedTagsArray: data.payload.unselectedTagsArray
        }
      });
      yield put({ type: RELATED_REVIEWLIST_DISTINCT_DATA, payload: [responseObj] });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerReviewListJobProfileGroupAllocateSaga(data) {
  try {
    const userResponse = yield call(apiCallFunction, {
      data: data.payload.request,
      URL: JOB_MODULE_URL
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === '000') {
      let responseObj = {
        ...data.payload.revisedGroupObject,
        jobProfileGroup: userResponse.responseObject
      };
      yield put({
        type: SET_MIDDLEPANE_STATE,
        payload: {
          middlePaneHeader: data.payload.headerOne || 'job-profiles',
          middlePaneHeaderBadgeOne: 'group',
          middlePaneHeaderBadgeTwo: 'active',
          middlePaneHeaderBadgeThree: '',
          middlePaneHeaderBadgeFour: '',
          middlePaneHeaderDuplicateBadgeOne:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
          middlePaneHeaderDuplicateBadgeTwo:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
          middlePaneHeaderDuplicateBadgeThree: '',
          middlePaneHeaderDuplicateBadgeFour: '',
          typeOfMiddlePaneList: data.payload.typeOfMiddlePaneList,
          scanCount: userResponse && userResponse.countTotal,
          duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
          showMiddlePaneState: true,
          isSelectActive: 'multiple',
          selectedTagsArray: data.payload.existingJobProfileId
        }
      });
      yield put({ type: RELATED_REVIEWLIST_DISTINCT_DATA, payload: [responseObj] });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workeJobNodeJobReviewListSaga(data) {
  try {
    const response = yield call(apiCallFunction, {
      data: data.payload.request,
      URL: JOB_MODULE_URL
    });
    // const response ={responseCode:'000',countTotal:30}
    if (response.responseCode === '000') {
      let responseObj = null;
      if (
        Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData.length > 0 &&
        data.payload.isMiddlePaneList
      ) {
        responseObj = {
          ...Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData[0],
          jobProfile: [
            ...Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData[0]?.jobProfile,
            ...response.responseObject[0].jobProfile
          ]
        };
        //to add list more and adding all the record
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
      } else {
        responseObj = response.responseObject[0];
      }
      yield put({
        type: RELATED_REVIEWLIST_DISTINCT_DATA,
        payload: [responseObj]
      });
      yield put({
        type: RELATED_REVIEWLIST_DISTINCT_DATA,
        payload: [response.responseObject[0]]
      });
      // yield put({
      //   type: SET_REVIEW_LIST_RELATE_DATA,
      //   payload: response.responseObject
      // });
      let relatedReviewListPaneThree2 = {
        ...Store.getState().DisplayPaneThreeReducer.relatedReviewListPaneThree,
        jobProfile: response.responseObject[0].jobProfile
      };

      yield put({
        type: SET_REVIEW_LIST_RELATE_DATA,
        payload: relatedReviewListPaneThree2
      });
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: data.payload.HeaderOne,
            middlePaneHeaderBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderBadgeThree: '',
            middlePaneHeaderBadgeFour: '',
            middlePaneHeaderDuplicate:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
            middlePaneHeaderDuplicateBadgeOne:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            middlePaneHeaderDuplicateBadgeTwo:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            middlePaneHeaderDuplicateBadgeThree:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
            middlePaneHeaderDuplicateBadgeFour:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour,
            typeOfMiddlePaneList: data.payload.request.actionFilter === 'Share-Job-Profile-Node' ? 'jobProfileNodeMarketPlaceJobProfileReviewList' : 'jobProfileNodeJobProfileReviewList',
            scanCount: response && response.countTotal,
            duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
            showMiddlePaneState: true
          }
        });
      }
    } else {
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: response.responseMessage, popupMode: 'responseErrorMsg' }
        });
      }
    }

    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workeJobDomainReviewListSaga(data) {
  try {
    yield put({ type: LOADER_START });
    const response = yield call(apiCallFunction, {
      data: data.payload.request,
      URL: JOB_MODULE_URL
    });
    // const response ={responseCode:'000',countTotal:30}
    if (response.responseCode === '000') {
      let templist = {
        jobDomain: response.responseObject?.jobDomain,
        jobFunction: response.responseObject?.jobFunction,
        jobCompetency: response.responseObject?.jobCompetency,
        jobRole: response.responseObject?.jobRole
      };
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'jobProfilerReviewList', value: templist }
      });
      // yield put({ type: SET_NEXT_POPUP, payload: { isPopUpValue: 'POPUPDOMAINMSG' } });
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: data?.payload?.nextPopupValue || 'POPUPDOMAINMSG',
          popupMode: 'JOBCREATE'
        }
      });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: response.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workeJobFunctionReviewListSaga(data) {
  try {
    yield put({ type: LOADER_START });
    const response = yield call(apiCallFunction, {
      data: data.payload.request,
      URL: JOB_MODULE_URL
    });
    // const response ={responseCode:'000',countTotal:30}
    if (response.responseCode === '000') {
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'jobProfileFunctionReviewList', value: response.responseObject }
      });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: response.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workeJobRoleReviewListSaga(data) {
  try {
    yield put({ type: LOADER_START });
    const response = yield call(apiCallFunction, {
      data: data.payload.request,
      URL: JOB_MODULE_URL
    });
    // const response ={responseCode:'000',countTotal:30}
    if (response.responseCode === '000') {
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'jobProfileRoleReviewList', value: response.responseObject }
      });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: response.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerCultureProfileAssessment(data) {
  try {
    const response = yield call(apiCallFunction, {
      data: data.payload.request,
      URL: CULTURE_MODULE_URL
    });
    // const response ={responseCode:'000',countTotal:30}
    if (response.responseCode === '000') {
      yield put({ type: RELATED_REVIEWLIST_DISTINCT_DATA, payload: response.responseObject });
      yield put({ type: SET_REVIEW_LIST_RELATE_DATA, payload: response.responseObject });
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: 'assessments',
            middlePaneHeaderBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderBadgeThree: '',
            middlePaneHeaderBadgeFour: '',
            middlePaneHeaderDuplicate:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
            middlePaneHeaderDuplicateBadgeOne:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            middlePaneHeaderDuplicateBadgeTwo:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            middlePaneHeaderDuplicateBadgeThree:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
            middlePaneHeaderDuplicateBadgeFour:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour,
            typeOfMiddlePaneList: 'cultureProfileAssessmentReviewList',
            scanCount: response && response.countTotal,
            duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
            showMiddlePaneState: true,
            isSelectActive: data.payload.isSelectActive,
            selectedTagsArray: data.payload.existingAssessmentId
          }
        });
      } else {
      }
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: response.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }

    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerJobProfileAssessment(data) {
  try {
    const response = yield call(apiCallFunction, {
      data: data.payload.request,
      URL: JOB_MODULE_URL
    });
    // const response ={responseCode:'000',countTotal:30}
    if (response.responseCode === '000') {
      yield put({ type: RELATED_REVIEWLIST_DISTINCT_DATA, payload: response.responseObject });
      yield put({ type: SET_REVIEW_LIST_RELATE_DATA, payload: response.responseObject });
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: 'assessments',
            middlePaneHeaderBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderBadgeThree: '',
            middlePaneHeaderBadgeFour: '',
            middlePaneHeaderDuplicate:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
            middlePaneHeaderDuplicateBadgeOne:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            middlePaneHeaderDuplicateBadgeTwo:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            middlePaneHeaderDuplicateBadgeThree:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
            middlePaneHeaderDuplicateBadgeFour:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour,
            typeOfMiddlePaneList: 'jobProfilepAssessmentReviewList',
            scanCount: response && response.countTotal,
            duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
            showMiddlePaneState: true
          }
        });
      }
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: response.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }

    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
export default function* watchReviewListJobProfileSaga() {
  yield takeLatest(GET_JOBPROFILE_REVIEW_LIST_SAGA, workerJobProfileReviewListSaga);
  yield takeLatest(JOB_GROUP_JOB_REVIEWLIST_SAGA, workeJobGroupJobReviewListSaga);
  yield takeEvery(JOB_TYPE_JOB_REVIEWLIST_SAGA, workeJobTypeJobReviewListSaga);
  yield takeLatest(GET_ALLOCATE_JOB, workerReviewListJobProfileAllocateSaga);
  yield takeLatest(GET_ALLOCATE_JOB_GROUP, workerReviewListJobProfileGroupAllocateSaga);
  yield takeLatest(GET_JOB_NODE_JOB_REVIEW_LIST_SAGA, workeJobNodeJobReviewListSaga);
  yield takeLatest(GET_JOBDOMAIN_REVIEW_LIST_SAGA, workeJobDomainReviewListSaga);
  yield takeLatest(GET_JOBFUNCTION_REVIEW_LIST_SAGA, workeJobFunctionReviewListSaga);
  yield takeLatest(GET_JOBROLE_REVIEW_LIST_SAGA, workeJobRoleReviewListSaga);
  yield takeLatest(CULTURE_ASSESSMENTS_REVIEWLIST_SAGA, workerCultureProfileAssessment);
  yield takeLatest(JOB_ASSESSMENTS_REVIEWLIST_SAGA, workerJobProfileAssessment);
}
