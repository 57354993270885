import React, { useEffect, useState } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import Popup from '../Molecules/PopUp/PopUp';
import '../Molecules/PopUp/PopUp.css';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  CLEAR_ASSESSMENT_INFO,
  POPUP_CLOSE,
  SET_NEXT_POPUP,
  UPDATE_ASSOCIATE_CURRENCY_INFO
} from '../actionType';
import { CAN_NOT_REVISED_MESSAGE, REQUIRED_ERROR_MESSAGE } from '../errorMessage';
import { Check, Clear } from '@material-ui/icons';
import { DialogTitle, IconButton, Paper } from '@material-ui/core';
import SunEditor from 'suneditor-react';
import { align, font, fontColor, fontSize } from 'suneditor/src/plugins';
import 'suneditor/dist/css/suneditor.min.css';
const PopUpItemTemplate = (props) => {
  const dispatch = useDispatch();
  const { popupMode, isPopUpValue } = useSelector((state) => state.PopUpReducer);
  //props
  const {
    isActive = false,
    headerPanelColour,
    headerOne,
    headerOneBadgeOne,
    headerOneBadgeTwo,
    inputHeaderBadgeThree = null,
    isRequired = false,
    basicInfo,
    typeOfSetObject,
    nextPopUpValue = '',
    handleNextPopupValue,
    mode,
    isNotRevised = false,
    headerOneBadgeThree = '',
    setDependantObj = '',
    defaultSheetValue = '',
    setDependantObjOne = ''
    //defaultSheetValue = '<p>item (label)</p><p>item</p><p>item (explanation)</p><p>response</p><p>response (explanation)</p><p>response (choice)</p><p>response (choice explanation)</p>'
  } = props;
  //states
  let errorMessage = isNotRevised ? CAN_NOT_REVISED_MESSAGE : '';
  const [state, setState] = useState({ isError: errorMessage });
  const { reviewMode } = useSelector((state) => state.DisplayPaneThreeReducer);
  const [localObject, setLocalObject] = useState(basicInfo);
  const [innerContent, setInnerContent] = useState(defaultSheetValue);
  const [localTemplate, setLocalTemplate] = useState([]);
  console.log('defaultSheetValue', defaultSheetValue);
  useEffect(() => {
    setLocalObject(basicInfo);
  }, [basicInfo]);

  const handleChange = (content) => {
    console.log('EDITOR===>', content);
    setInnerContent(content);
    let parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    console.log(doc);
    let responseArr = [];
    let contentLength = doc.getElementsByTagName('p');
    for (let i = 0; i < contentLength.length; i++) {
      let styles = stringToStyleArray(contentLength[i].outerHTML);
      console.log(styles);
      let innerContent = contentLength[i].innerText;
      console.log(innerContent);
      let styleStr = styles.toString();
      // let responseObj = {
      //   assessmentItemFrameworkOne: innerContent,
      //   assessmentItemFrameworkOneStyle: styleStr
      // }
      let responseObj = {};
      if (headerOne === 'assessment') {
        responseObj = {
          assessmentItemFrameworkOne: innerContent,
          assessmentItemFrameworkOneStyle: styleStr
        };
      } else {
        responseObj = {
          assignmentItemFrameworkOne: innerContent,
          assignmentItemFrameworkOneStyle: styleStr
        };
      }
      responseArr.push(responseObj);
    }
    console.log('responseArr', responseArr);
    setLocalTemplate(responseArr);
    dispatch({
      type: '',
      payload: ''
    });
  };
  const onClickYes = () => {
    console.log(innerContent);
    if (typeOfSetObject !== '') {
      dispatch({
        type: typeOfSetObject,
        payload: {
          stateName: setDependantObj,
          value: [...localTemplate]
        }
      });
      dispatch({
        type: typeOfSetObject,
        payload: {
          stateName: setDependantObjOne,
          value: innerContent
        }
      });
    }
    dispatch({ type: POPUP_CLOSE });
  };
  const closePopup = () => {
    console.log('ON CLOSE');
    if (mode === 'revise') {
      dispatch({ type: POPUP_CLOSE });
    } else {
      dispatch({ type: CLEAR_ASSESSMENT_INFO });
    }
  };
  const stringToStyleArray = (string, split = true) => {
    let styles = [];
    let dom = new DOMParser().parseFromString(string, 'text/html');
    dom.querySelectorAll('[style]').forEach((el) => {
      if (split) {
        //styles = [...styles, ...el.getAttribute("style").split(';')];
        styles = [...styles, el.getAttribute('style')];
      } else {
        styles.push(el.getAttribute('style'));
      }
    });
    if (dom.querySelectorAll('strong').length > 0) {
      console.log(styles);
      styles = [...styles, 'font-weight: bold'];
    }
    if (dom.querySelectorAll('em').length > 0) {
      console.log(styles);
      styles = [...styles, 'font-style: italic'];
    }
    return styles;
  };
  return (
    <div>
      <Popup isActive={isActive}>
        <DialogTitle id="dialog-title" className={'popupHeaderTitle'}>
          <Paper
            style={{
              maxWidth: '600px',
              alignItems: 'center',
              padding: '5px',
              display: 'flex'
            }}
            className={[`titleSolid-${headerPanelColour}`].join(' ')}
          >
            <div
              style={{
                width: '100%',
                alignItems: 'center',
                padding: '5px',
                display: 'flex'
              }}
            >
              <div className={'textSheetTitleBox'}>
                <span>{headerOne}</span>&nbsp;
                {headerOneBadgeOne ? (
                  <>
                    <span className={'iguru-header-badge1_0'}>{headerOneBadgeOne}</span>
                    &nbsp;
                  </>
                ) : null}
                {headerOneBadgeTwo ? (
                  <>
                    <span className={'iguru-header-badge1_0'}>{headerOneBadgeTwo}</span>
                    &nbsp;
                  </>
                ) : null}
                {headerOneBadgeThree ? (
                  <>
                    <span className={'iguru-header-badge1_0'}>{headerOneBadgeThree}</span>
                    &nbsp;
                  </>
                ) : null}
              </div>
              <div className={'backArrow'}>
                <IconButton className="MuiIconButton-root-1602" onClick={onClickYes}>
                  <Check className={'popupClose'}  />
                </IconButton>
              </div>
              <div className={'backArrow'}>
                <IconButton className="MuiIconButton-root-1602" onClick={closePopup}>
                  <Clear className={'popupClose'}  />
                </IconButton>
              </div>
            </div>
          </Paper>
        </DialogTitle>
        <DialogContent className={['textsheetPopupContent'].join(' ')}>
          <SunEditor
            setOptions={{
              //showPathLabel: false,
              minHeight: '50vh',
              maxHeight: '50vh',
              font: ['Arial', 'Calibri', 'Roboto', 'Times New Roman'],
              //fontSize: [8, 10, 14, 18, 24, 36],
              plugins: [align, fontSize, fontColor, font],
              //   align,
              //   font,
              //   fontColor,
              //   fontSize,
              // ],
              buttonList: [['bold', 'italic', 'align', 'fontSize', 'fontColor', 'font']]
            }}
            setContents={innerContent}
            onChange={handleChange}
          />
        </DialogContent>
      </Popup>
    </div>
  );
};
PopUpItemTemplate.propTypes = {
  className: PropTypes.string,
  headerPanelColour: PropTypes.oneOf([
    'displayPaneLeft',
    'displayPaneCentre',
    'displayPaneRight',
    'genericOne',
    'genericTwo'
  ]),
  headerOne: PropTypes.string,
  headerOneBadgeOne: PropTypes.string,
  headerOneBadgeTwo: PropTypes.string,
  headerOneBadgeThree: PropTypes.string,
  isActive: PropTypes.bool
};
export default PopUpItemTemplate;
