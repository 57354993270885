import { put, takeLatest, call } from 'redux-saga/effects';
import {
  ASSESSEE_REVIEW_DISTINCT_SAGA,
  ASSESSMENT_REVIEW_DISTINCT_SAGA,
  ASSIGNMENT_REVIEW_LIST_DISTINCT_SAGA,
  CULTURE_GROUP_CULTURE_REVIEWLIST_SAGA,
  CULTURE_TYPE_CULTURE_REVIEWLIST_SAGA,
  GET_ASSESSEEGROUP_ASSESSEE_REVIEW_LIST,
  GET_ASSESSEETYPE_ASSESSEE_REVIEW_LIST,
  GET_ASSESSMENTGROUP_ASSESSMENT_REVIEWLIST_SAGA,
  GET_ASSESSMENTTYPE_ASSESSMENT_REVIEWLIST_SAGA,
  GET_ASSIGNMENTGROUP_ASSIGNMENT_REVIEWLIST_SAGA,
  GET_ASSIGNMENTTYPE_ASSIGNMENT_REVIEWLIST_SAGA,
  GET_ASSOCIATEGROUP_ASSOCIATE_REVIEW_LIST_SAGA,
  GET_ASSOCIATES_NODE_REVIEW_LIST_SAGA,
  GET_CULTUREPROFILE_REVIEW_LIST_SAGA,
  GET_ITEMGROUPITEM_REVIEW_LIST_SAGA,
  GET_ITEMTYPEITEM_REVIEW_LIST_SAGA,
  GET_ITEM_REVIEW_LIST_SAGA,
  GET_JOBPROFILE_REVIEW_LIST_SAGA,
  JOB_GROUP_JOB_REVIEWLIST_SAGA,
  JOB_TYPE_JOB_REVIEWLIST_SAGA,
  LOADER_STOP,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_PAGE_COUNT,
  SET_POPUP_SINGLE_STATE,
  SET_POPUP_VALUE,
  SHARE_DISTINCT_SAGA
} from '../../actionType';
import {
  ASSESSEE_MODULE_URL,
  ASSESSMENT_MODULE_URL,
  ASSIGNMENT_MODULE_URL,
  ASSOCIATE_MODULE_URL,
  CULTURE_MODULE_URL,
  ITEM_MODULE_URL,
  JOB_MODULE_URL
} from '../../endpoints';
import { EXCEPTION_ERROR_MESSAGE } from '../../errorMessage';
import Store from '../../store';

const distinctShareApi = async (requestObj) => {
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      Authorization:
        ((requestObj.shareValue === 'cultureProfileDistinct' ||
          requestObj.shareValue === 'jobProfileDistinct') &&
          localStorage.getItem('idToken')) ||
        localStorage.getItem('token')
    }),
    body: JSON.stringify(requestObj.data)
  };
  const response = await fetch(requestObj.URL, requestOptions);
  const json = await response.json();
  return json;
};

function* workerDistinctShareSaga(data) {
  try {
    let URL;
    if (data.payload.apiCall === 'shareApiCall') {
      URL =
        (data.payload.shareValue === 'assesseeDistinct' && ASSESSEE_MODULE_URL) ||
        (data.payload.shareValue === 'assessmentDistinct' && ASSESSMENT_MODULE_URL) ||
        (data.payload.shareValue === 'assignmentDistinct' && ASSIGNMENT_MODULE_URL) ||
        (data.payload.shareValue === 'associateDistinct' && ASSOCIATE_MODULE_URL) ||
        (data.payload.shareValue === 'cultureProfileDistinct' && CULTURE_MODULE_URL) ||
        (data.payload.shareValue === 'jobProfileDistinct' && JOB_MODULE_URL) ||
        (data.payload.shareValue === 'itemDistinct' && ITEM_MODULE_URL) ||
        '';
    } else {
      URL =
        (data.payload.shareValue === 'assesseeDistinct' && ASSESSEE_MODULE_URL) ||
        (data.payload.shareValue === 'assessmentDistinct' && ASSESSMENT_MODULE_URL) ||
        (data.payload.shareValue === 'assignmentDistinct' && ASSIGNMENT_MODULE_URL) ||
        (data.payload.shareValue === 'associateDistinct' && ASSOCIATE_MODULE_URL) ||
        (data.payload.shareValue === 'cultureProfileDistinct' && CULTURE_MODULE_URL) ||
        (data.payload.shareValue === 'jobProfileDistinct' && JOB_MODULE_URL) ||
        (data.payload.shareValue === 'itemDistinct' && ITEM_MODULE_URL) ||
        '';
    }
    const userResponse = yield call(distinctShareApi, {
      data: data.payload.request,
      URL: URL,
      shareValue: data.payload.shareValue
    });
    if (userResponse.responseCode === '000') {
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'reviewListDistinctData', value: [] }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'allocatedTagsArray', value: [] }
      });
      yield put({
        type: SET_POPUP_SINGLE_STATE,
        payload: {
          stateName: 'selectionValue',
          value: ''
        }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'tempStr', value: '' }
      });
      if (
        data.payload.shareValue === 'assesseeDistinct' &&
        Store.getState().DisplayPaneTwoReducer.typeOfMiddlePaneList ===
        'assesseesGroupAssesseeReviewList'
      ) {
        yield put({
          type: GET_ASSESSEEGROUP_ASSESSEE_REVIEW_LIST,
          payload: {
            HeaderOne: 'assessees',
            request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
            isMiddlePaneList: true,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
          }
        });
      } else if (
        data.payload.shareValue === 'assesseeDistinct' &&
        Store.getState().DisplayPaneTwoReducer.typeOfMiddlePaneList ===
        'assesseesTypeAssesseeReviewList'
      ) {
        yield put({
          type: GET_ASSESSEETYPE_ASSESSEE_REVIEW_LIST,
          payload: {
            HeaderOne: 'assessee',
            request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
            isMiddlePaneList: true,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
          }
        });
      } else if (data.payload.shareValue === 'assesseeDistinct') {
        yield put({ type: SET_PAGE_COUNT, payload: 0 });
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'reviewListDistinctData', value: [] }
        });
        yield put({
          type: ASSESSEE_REVIEW_DISTINCT_SAGA,
          payload: {
            HeaderOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
            request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            isMiddlePaneList: true,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
          }
        });
      } else if (
        data.payload.shareValue === 'assessmentDistinct' &&
        Store.getState().DisplayPaneTwoReducer.typeOfMiddlePaneList ===
        'assessmentGroupAssessmentReviewList'
      ) {
        yield put({
          type: GET_ASSESSMENTGROUP_ASSESSMENT_REVIEWLIST_SAGA,
          payload: {
            HeaderOne: 'assessments',
            request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
            isMiddlePaneList: true,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
          }
        });
      } else if (
        data.payload.shareValue === 'assessmentDistinct' &&
        Store.getState().DisplayPaneTwoReducer.typeOfMiddlePaneList ===
        'assessmentTypeAssessmentReviewList'
      ) {
        yield put({
          type: GET_ASSESSMENTTYPE_ASSESSMENT_REVIEWLIST_SAGA,
          payload: {
            HeaderOne: 'assessments',
            request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
            isMiddlePaneList: true,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
          }
        });
      } else if (data.payload.shareValue === 'assessmentDistinct') {
        yield put({
          type: ASSESSMENT_REVIEW_DISTINCT_SAGA,
          payload: {
            HeaderOne: 'assessments',
            request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            isMiddlePaneList: true,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
          }
        });
      } else if (
        data.payload.shareValue === 'assignmentDistinct' &&
        Store.getState().DisplayPaneTwoReducer.typeOfMiddlePaneList ===
        'assignmentGroupAssignmentReviewList'
      ) {
        yield put({
          type: GET_ASSIGNMENTGROUP_ASSIGNMENT_REVIEWLIST_SAGA,
          payload: {
            HeaderOne: 'assignments',
            request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
            isMiddlePaneList: true,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
          }
        });
      } else if (
        data.payload.shareValue === 'assignmentDistinct' &&
        Store.getState().DisplayPaneTwoReducer.typeOfMiddlePaneList ===
        'assignmentTypeAssignmentReviewList'
      ) {
        yield put({
          type: GET_ASSIGNMENTTYPE_ASSIGNMENT_REVIEWLIST_SAGA,
          payload: {
            HeaderOne: 'assignments',
            request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
            isMiddlePaneList: true,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
          }
        });
      } else if (data.payload.shareValue === 'assignmentDistinct') {
        yield put({
          type: ASSIGNMENT_REVIEW_LIST_DISTINCT_SAGA,
          payload: {
            HeaderOne: 'assignments',
            request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            isMiddlePaneList: true,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
          }
        });
      } else if (
        data.payload.shareValue === 'associateDistinct' ||
        Store.getState().DisplayPaneTwoReducer.typeOfMiddlePaneList ===
        'associatesGroupAssociateReviewList'
      ) {
        yield put({
          type: GET_ASSOCIATEGROUP_ASSOCIATE_REVIEW_LIST_SAGA,
          payload: {
            HeaderOne: 'associates',
            request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
            isMiddlePaneList: true,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
          }
        });
      } else if (data.payload.shareValue === 'associateDistinct') {
        yield put({
          type: GET_ASSOCIATES_NODE_REVIEW_LIST_SAGA,
          payload: {
            HeaderOne: 'associates',
            request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            isMiddlePaneList: true,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
          }
        });
      } else if (
        data.payload.shareValue === 'cultureProfileDistinct' &&
        Store.getState().DisplayPaneTwoReducer.typeOfMiddlePaneList ===
        'cultureProfileGroupCultureProfileReviewList'
      ) {
        yield put({
          type: CULTURE_GROUP_CULTURE_REVIEWLIST_SAGA,
          payload: {
            HeaderOne: 'culture-profiles',
            request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
            isMiddlePaneList: true,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
          }
        });
      } else if (
        data.payload.shareValue === 'cultureProfileDistinct' &&
        Store.getState().DisplayPaneTwoReducer.typeOfMiddlePaneList ===
        'cultureProfileTypeCultureProfileReviewList'
      ) {
        yield put({
          type: CULTURE_TYPE_CULTURE_REVIEWLIST_SAGA,
          payload: {
            HeaderOne: 'culture-profiles',
            request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
            isMiddlePaneList: true,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
          }
        });
      } else if (data.payload.shareValue === 'cultureProfileDistinct') {
        yield put({
          type: GET_CULTUREPROFILE_REVIEW_LIST_SAGA,
          payload: {
            HeaderOne: 'culture-profiles',
            request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            isMiddlePaneList: true,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
          }
        });
      } else if (
        data.payload.shareValue === 'jobProfileDistinct' &&
        Store.getState().DisplayPaneTwoReducer.typeOfMiddlePaneList ===
        'jobProfileGroupJobProfileReviewList'
      ) {
        yield put({
          type: JOB_GROUP_JOB_REVIEWLIST_SAGA,
          payload: {
            HeaderOne: 'job-profiles',
            request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
            isMiddlePaneList: true,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
          }
        });
      } else if (
        data.payload.shareValue === 'jobProfileDistinct' &&
        Store.getState().DisplayPaneTwoReducer.typeOfMiddlePaneList ===
        'jobProfileTypeJobProfileReviewList'
      ) {
        yield put({
          type: JOB_TYPE_JOB_REVIEWLIST_SAGA,
          payload: {
            HeaderOne: 'job-profiles',
            request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
            isMiddlePaneList: true,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
          }
        });
      } else if (data.payload.shareValue === 'jobProfileDistinct') {
        yield put({
          type: GET_JOBPROFILE_REVIEW_LIST_SAGA,
          payload: {
            HeaderOne: 'job-profiles',
            request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            isMiddlePaneList: true,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
          }
        });
      } else if (
        data.payload.shareValue === 'itemDistinct' &&
        Store.getState().DisplayPaneTwoReducer.typeOfMiddlePaneList === 'itemGroupItemReviewList'
      ) {
        yield put({
          type: GET_ITEMGROUPITEM_REVIEW_LIST_SAGA,
          payload: {
            HeaderOne: 'items',
            request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            isMiddlePaneList: true,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
          }
        });
      } else if (
        data.payload.shareValue === 'itemDistinct' &&
        Store.getState().DisplayPaneTwoReducer.typeOfMiddlePaneList === 'itemTypeItemReviewList'
      ) {
        yield put({
          type: GET_ITEMTYPEITEM_REVIEW_LIST_SAGA,
          payload: {
            HeaderOne: 'items',
            request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            isMiddlePaneList: true,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
          }
        });
      } else if (data.payload.shareValue === 'itemDistinct') {
        yield put({
          type: GET_ITEM_REVIEW_LIST_SAGA,
          payload: {
            HeaderOne: 'items',
            request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            isMiddlePaneList: true,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
          }
        });
      }
    } else {
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'reviewListDistinctData', value: [] }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'allocatedTagsArray', value: [] }
      });
      yield put({
        type: SET_POPUP_SINGLE_STATE,
        payload: {
          stateName: 'selectionValue',
          value: ''
        }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'tempStr', value: '' }
      });
      if (
        data.payload.shareValue === 'assesseeDistinct' &&
        Store.getState().DisplayPaneTwoReducer.typeOfMiddlePaneList ===
        'assesseesGroupAssesseeReviewList'
      ) {
        yield put({
          type: GET_ASSESSEEGROUP_ASSESSEE_REVIEW_LIST,
          payload: {
            HeaderOne: 'assessees',
            request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
            isMiddlePaneList: true,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
          }
        });
      } else if (
        data.payload.shareValue === 'assesseeDistinct' &&
        Store.getState().DisplayPaneTwoReducer.typeOfMiddlePaneList ===
        'assesseesTypeAssesseeReviewList'
      ) {
        yield put({
          type: GET_ASSESSEETYPE_ASSESSEE_REVIEW_LIST,
          payload: {
            HeaderOne: 'assessee',
            request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
            isMiddlePaneList: true,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
          }
        });
      } else if (data.payload.shareValue === 'assesseeDistinct') {
        yield put({ type: SET_PAGE_COUNT, payload: 0 });
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'reviewListDistinctData', value: [] }
        });
        yield put({
          type: ASSESSEE_REVIEW_DISTINCT_SAGA,
          payload: {
            HeaderOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
            request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            isMiddlePaneList: true,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
          }
        });
      } else if (
        data.payload.shareValue === 'assessmentDistinct' &&
        Store.getState().DisplayPaneTwoReducer.typeOfMiddlePaneList ===
        'assessmentGroupAssessmentReviewList'
      ) {
        yield put({
          type: GET_ASSESSMENTGROUP_ASSESSMENT_REVIEWLIST_SAGA,
          payload: {
            HeaderOne: 'assessments',
            request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
            isMiddlePaneList: true,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
          }
        });
      } else if (
        data.payload.shareValue === 'assessmentDistinct' &&
        Store.getState().DisplayPaneTwoReducer.typeOfMiddlePaneList ===
        'assessmentTypeAssessmentReviewList'
      ) {
        yield put({
          type: GET_ASSESSMENTTYPE_ASSESSMENT_REVIEWLIST_SAGA,
          payload: {
            HeaderOne: 'assessments',
            request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
            isMiddlePaneList: true,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
          }
        });
      } else if (data.payload.shareValue === 'assessmentDistinct') {
        yield put({
          type: ASSESSMENT_REVIEW_DISTINCT_SAGA,
          payload: {
            HeaderOne: 'assessments',
            request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            isMiddlePaneList: true,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
          }
        });
      } else if (
        data.payload.shareValue === 'assignmentDistinct' &&
        Store.getState().DisplayPaneTwoReducer.typeOfMiddlePaneList ===
        'assignmentGroupAssignmentReviewList'
      ) {
        yield put({
          type: GET_ASSIGNMENTGROUP_ASSIGNMENT_REVIEWLIST_SAGA,
          payload: {
            HeaderOne: 'assignments',
            request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
            isMiddlePaneList: true,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
          }
        });
      } else if (
        data.payload.shareValue === 'assignmentDistinct' &&
        Store.getState().DisplayPaneTwoReducer.typeOfMiddlePaneList ===
        'assignmentTypeAssignmentReviewList'
      ) {
        yield put({
          type: GET_ASSIGNMENTTYPE_ASSIGNMENT_REVIEWLIST_SAGA,
          payload: {
            HeaderOne: 'assignments',
            request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
            isMiddlePaneList: true,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
          }
        });
      } else if (data.payload.shareValue === 'assignmentDistinct') {
        yield put({
          type: ASSIGNMENT_REVIEW_LIST_DISTINCT_SAGA,
          payload: {
            HeaderOne: 'assignments',
            request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            isMiddlePaneList: true,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
          }
        });
      } else if (
        data.payload.shareValue === 'associateDistinct' ||
        Store.getState().DisplayPaneTwoReducer.typeOfMiddlePaneList ===
        'associatesGroupAssociateReviewList'
      ) {
        yield put({
          type: GET_ASSOCIATEGROUP_ASSOCIATE_REVIEW_LIST_SAGA,
          payload: {
            HeaderOne: 'associates',
            request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
            isMiddlePaneList: true,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
          }
        });
      } else if (data.payload.shareValue === 'associateDistinct') {
        yield put({
          type: GET_ASSOCIATES_NODE_REVIEW_LIST_SAGA,
          payload: {
            HeaderOne: 'associates',
            request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            isMiddlePaneList: true,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
          }
        });
      } else if (
        data.payload.shareValue === 'cultureProfileDistinct' &&
        Store.getState().DisplayPaneTwoReducer.typeOfMiddlePaneList ===
        'cultureProfileGroupCultureProfileReviewList'
      ) {
        yield put({
          type: CULTURE_GROUP_CULTURE_REVIEWLIST_SAGA,
          payload: {
            HeaderOne: 'culture-profiles',
            request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
            isMiddlePaneList: true,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
          }
        });
      } else if (
        data.payload.shareValue === 'cultureProfileDistinct' &&
        Store.getState().DisplayPaneTwoReducer.typeOfMiddlePaneList ===
        'cultureProfileTypeCultureProfileReviewList'
      ) {
        yield put({
          type: CULTURE_TYPE_CULTURE_REVIEWLIST_SAGA,
          payload: {
            HeaderOne: 'culture-profiles',
            request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
            isMiddlePaneList: true,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
          }
        });
      } else if (data.payload.shareValue === 'cultureProfileDistinct') {
        yield put({
          type: GET_CULTUREPROFILE_REVIEW_LIST_SAGA,
          payload: {
            HeaderOne: 'culture-profiles',
            request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            isMiddlePaneList: true,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
          }
        });
      } else if (
        data.payload.shareValue === 'jobProfileDistinct' &&
        Store.getState().DisplayPaneTwoReducer.typeOfMiddlePaneList ===
        'jobProfileGroupJobProfileReviewList'
      ) {
        yield put({
          type: JOB_GROUP_JOB_REVIEWLIST_SAGA,
          payload: {
            HeaderOne: 'job-profiles',
            request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
            isMiddlePaneList: true,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
          }
        });
      } else if (
        data.payload.shareValue === 'jobProfileDistinct' &&
        Store.getState().DisplayPaneTwoReducer.typeOfMiddlePaneList ===
        'jobProfileTypeJobProfileReviewList'
      ) {
        yield put({
          type: JOB_TYPE_JOB_REVIEWLIST_SAGA,
          payload: {
            HeaderOne: 'job-profiles',
            request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
            isMiddlePaneList: true,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
          }
        });
      } else if (data.payload.shareValue === 'jobProfileDistinct') {
        yield put({
          type: GET_JOBPROFILE_REVIEW_LIST_SAGA,
          payload: {
            HeaderOne: 'job-profiles',
            request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            isMiddlePaneList: true,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
          }
        });
      } else if (
        data.payload.shareValue === 'itemDistinct' &&
        Store.getState().DisplayPaneTwoReducer.typeOfMiddlePaneList === 'itemGroupItemReviewList'
      ) {
        yield put({
          type: GET_ITEMGROUPITEM_REVIEW_LIST_SAGA,
          payload: {
            HeaderOne: 'items',
            request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            isMiddlePaneList: true,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
          }
        });
      } else if (
        data.payload.shareValue === 'itemDistinct' &&
        Store.getState().DisplayPaneTwoReducer.typeOfMiddlePaneList === 'itemTypeItemReviewList'
      ) {
        yield put({
          type: GET_ITEMTYPEITEM_REVIEW_LIST_SAGA,
          payload: {
            HeaderOne: 'items',
            request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            isMiddlePaneList: true,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
          }
        });
      } else if (data.payload.shareValue === 'itemDistinct') {
        yield put({
          type: GET_ITEM_REVIEW_LIST_SAGA,
          payload: {
            HeaderOne: 'items',
            request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            isMiddlePaneList: true,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
          }
        });
      }
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
      yield put({ type: LOADER_STOP });
    }
    yield put({ type: SET_PAGE_COUNT, payload: 0 });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}

export default function* watcherdistinctShareSaga() {
  yield takeLatest(SHARE_DISTINCT_SAGA, workerDistinctShareSaga);
}
