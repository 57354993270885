import React, { useState } from "react";
import { isMobile } from "react-device-detect";
// import AllocationAccordian from '../Accordian/AllocationAccordian';
import Manuscript from "@material-ui/icons/Description";
import { useDispatch, useSelector } from "react-redux";
import DisplayPanelAccordianReviewListOne from "../Accordian/DisplayPanelAccordianReviewListOne";
import DisplayPanelAccordianInformation from "../Accordian/DisplayPanelAccordianInformation";
import { Paper } from "@material-ui/core";
import {
  GET_ASSESSMENT_GROUP_REVIEW_LIST_SAGA,
  GET_ASSOCIATE_GROUP_REVIEW_LIST_SAGA,
  GET_ASSOCIATE_TYPE_REVIEW_LIST_SAGA,
  INTERNAL_NODE_LIST_WITH_PERMISSIONS_SAGA,
  LOADER_START,
  SET_CORE_GROUP_REVIEW_LIST_REQ_OBJECT,
  SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT,
  SET_CORE_TYPE_REVIEW_LIST_REQ_OBJECT,
  SET_POPUP_VALUE,
  SET_STATUS_POPUP_VALUE,
} from "../../actionType";
import DisplayPanelAccordianReviewListTwo from "../Accordian/DisplayPanelAccordianReviewListTwo";
import { makeAssociateGroupObj, makeAssociateTypeObj, makeInternalNodeObj } from "../../Actions/GenericActions";

const DisplayPaneThreeSectionOneAssociateGroup = () => {
  // const [listExpand, setListExpand] = useState('');
  const { responseObject, reviewMode } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  const { selectedAssociateInfo, assignmentCreateType } = useSelector(
    (state) => state.DisplayPaneTwoReducer
  );
  const { informationEngagement, informationSetup, informationAllocation } =
    responseObject;
  const dispatch = useDispatch();
  //ascendant
  let ascendantAll = [];
  if (
    informationAllocation?.associateGroupAscendant
      ?.associateGroupAscendantAll &&
    informationAllocation?.associateGroupAscendant?.associateGroupAscendantAll
      .length > 0
  ) {
    const tempArr =
      informationAllocation?.associateGroupAscendant
        ?.associateGroupAscendantAll;
    tempArr.forEach((ob) => {
      ascendantAll.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.associateGroupName || "",
        textTwo: ob?.informationBasic?.associateGroupDescription || "",
        status: ob?.informationEngagement?.associateGroupStatus || "",
      });
    });
  }
  let ascendantPrimary = [];
  if (
    informationAllocation?.associateGroupAscendant
      ?.associateGroupAscendantPrimary &&
    informationAllocation?.associateGroupAscendant
      ?.associateGroupAscendantPrimary.length > 0
  ) {
    const tempArr =
      informationAllocation?.associateGroupAscendant
        ?.associateGroupAscendantPrimary;
    tempArr.forEach((ob) => {
      ascendantPrimary.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.associateGroupName || "",
        textTwo: ob?.informationBasic?.associateGroupDescription || "",
        status: ob?.informationEngagement?.associateGroupStatus || "",
      });
    });
  }
  let ascendantSecondary = [];
  if (
    informationAllocation?.associateGroupAscendant
      ?.associateGroupAscendantSecondary &&
    informationAllocation?.associateGroupAscendant
      ?.associateGroupAscendantSecondary.length > 0
  ) {
    const tempArr =
      informationAllocation?.associateGroupAscendant
        ?.associateGroupAscendantSecondary;
    tempArr.forEach((ob) => {
      ascendantSecondary.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.associateGroupName || "",
        textTwo: ob?.informationBasic?.associateGroupDescription || "",
        status: ob?.informationEngagement?.associateGroupStatus || "",
      });
    });
  }
  //decendent
  let descendantAll = [];
  if (
    informationAllocation?.associateGroupDescendant
      ?.associateGroupDescendantAll &&
    informationAllocation?.associateGroupDescendant?.associateGroupDescendantAll
      .length > 0
  ) {
    const tempArr =
      informationAllocation?.associateGroupDescendant
        ?.associateGroupDescendantAll;
    tempArr.forEach((ob) => {
      descendantAll.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.associateGroupName || "",
        textTwo: ob?.informationBasic?.associateGroupDescription || "",
        status: ob?.informationEngagement?.associateGroupStatus || "",
      });
    });
  }
  let descendantPrimary = [];
  if (
    informationAllocation?.associateGroupDescendant
      ?.associateGroupDescendantPrimary &&
    informationAllocation?.associateGroupDescendant
      ?.associateGroupDescendantPrimary.length > 0
  ) {
    const tempArr =
      informationAllocation?.associateGroupDescendant
        ?.associateGroupDescendantPrimary;
    tempArr.forEach((ob) => {
      descendantPrimary.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.associateGroupName || "",
        textTwo: ob?.informationBasic?.associateGroupDescription || "",
        status: ob?.informationEngagement?.associateGroupStatus || "",
      });
    });
  }
  let descendantSecondary = [];
  if (
    informationAllocation?.associateGroupDescendant
      ?.associateGroupDescendantSecondary &&
    informationAllocation?.associateGroupDescendant
      ?.associateGroupDescendantSecondary.length > 0
  ) {
    const tempArr =
      informationAllocation?.associateGroupDescendant
        ?.associateGroupDescendantSecondary;
    tempArr.forEach((ob) => {
      descendantSecondary.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.associateGroupName || "",
        textTwo: ob?.informationBasic?.associateGroupDescription || "",
        status: ob?.informationEngagement?.associateGroupStatus || "",
      });
    });
  }
  // node primary list
  let nodePrimary = [];
  if (
    informationAllocation?.associateGroupNode?.associateGroupNodePrimary &&
    informationAllocation?.associateGroupNode?.associateGroupNodePrimary
      .length > 0
  ) {
    const tempArr =
      informationAllocation?.associateGroupNode?.associateGroupNodePrimary;
    tempArr.forEach((ob) => {
      nodePrimary.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.associateNodeName || "",
        textTwo: ob?.informationBasic?.associateNodeDescription || "",
        status: ob?.informationEngagement?.associateNodeStatus || "",
      });
    });
  }

  // node secondary list
  let nodeSecondary = [];
  if (
    informationAllocation?.associateGroupNode?.associateGroupNodeSecondary &&
    informationAllocation?.associateGroupNode?.associateGroupNodeSecondary
      .length > 0
  ) {
    const tempArr =
      informationAllocation?.associateGroupNode?.associateGroupNodeSecondary;
    tempArr.forEach((ob) => {
      nodeSecondary.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.associateNodeName || "",
        textTwo: ob?.informationBasic?.associateNodeDescription || "",
        status: ob?.informationEngagement?.associateNodeStatus || "",
      });
    });
  }
  
  let groupTypeListPrimary = [];
  if (
    informationAllocation?.associateGroupType?.associateGroupTypePrimary &&
    informationAllocation?.associateGroupType?.associateGroupTypePrimary.length > 0
  ) {
    const tempArr = informationAllocation?.associateGroupType?.associateGroupTypePrimary;
    tempArr.forEach((ob) => {
      groupTypeListPrimary.push({
        id: ob?.id + '-' || '',
        textOne: ob?.informationBasic?.associateTypeName || '',
        textTwo: ob?.informationBasic?.associateTypeDescription || '',
        status:  ob?.informationEngagement?.associateTypeStatus || ''
      });
    });
  }
  let groupTypeListSecondary = [];
  if (
    informationAllocation?.associateGroupType?.associateGroupTypeSecondary &&
    informationAllocation?.associateGroupType?.associateGroupTypeSecondary.length > 0
  ) {
    const tempArr = informationAllocation?.associateGroupType?.associateGroupTypeSecondary;
    tempArr.forEach((ob) => {
      groupTypeListSecondary.push({
        id: ob?.id + '-' || '',
        textOne: ob?.informationBasic?.associateTypeName || '',
        textTwo: ob?.informationBasic?.associateTypeDescription || '',
        status:  ob?.informationEngagement?.associateTypeStatus || ''
      });
    });
  }

  function capitalizeFirstLetter(string) {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  const list2 = [
    {
      id: "a1",
      labelTextOneOne: "group",
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: "ascendant",
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: "all",
              innerList: ascendantAll.sort((a, b) =>
                a.textOne.toLowerCase() !== b.textOne.toLowerCase()
                  ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
                    ? -1
                    : 1
                  : 0
              ),
            },
            {
              labelTextTwoBadge: "primary",
              innerList: ascendantPrimary.sort((a, b) =>
                a.textOne.toLowerCase() !== b.textOne.toLowerCase()
                  ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
                    ? -1
                    : 1
                  : 0
              ),
            },
            {
              labelTextTwoBadge: "secondary",
              innerList: ascendantSecondary.sort((a, b) =>
                a.textOne.toLowerCase() !== b.textOne.toLowerCase()
                  ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
                    ? -1
                    : 1
                  : 0
              ),
            },
          ],
        },
        {
          labelTextOneOneBadge: "descendant",
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: "all",
              innerList: descendantAll.sort((a, b) =>
                a.textOne.toLowerCase() !== b.textOne.toLowerCase()
                  ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
                    ? -1
                    : 1
                  : 0
              ),
            },
            {
              labelTextTwoBadge: "primary",
              innerList: descendantPrimary.sort((a, b) =>
                a.textOne.toLowerCase() !== b.textOne.toLowerCase()
                  ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
                    ? -1
                    : 1
                  : 0
              ),
            },
            {
              labelTextTwoBadge: "secondary",
              innerList: descendantSecondary.sort((a, b) =>
                a.textOne.toLowerCase() !== b.textOne.toLowerCase()
                  ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
                    ? -1
                    : 1
                  : 0
              ),
            },
          ],
        },
      ],
      innerInfo: "No Information",
      isListCard: true,
      isReviewLink: false,
      isMultiList: true,
    },
    // {
    //   id: 'a2',
    //   labelTextOneOne: 'manager',
    //   labelTextOneOneBadgeOne: 'primary',
    //   labelTextOneOneBadgeTwo: 'secondary',
    //   labelTextOneOneBadgeThree: '',
    //   labelTextOneOneBadgeFour: '',
    //   labelTextOneOneBadges: [
    //     {
    //       labelTextOneOneBadge: 'primary',
    //       innerList: [
    //         {
    //           id: 'associate1',
    //           textOne: 'Simple Sample 01',
    //           textTwo: 'Manager',
    //           status: 'active'
    //         },
    //         {
    //           id: 'associate2',
    //           textOne: 'Simple Sample 02',
    //           textTwo: 'Manager',
    //           status: 'active'
    //         },
    //         {
    //           id: 'associate3',
    //           textOne: 'Simple Sample 03',
    //           textTwo: 'Manager',
    //           status: 'active'
    //         }
    //       ]
    //     },
    //     {
    //       labelTextOneOneBadge: 'secondary',
    //       innerList: []
    //     }
    //   ],
    //   innerInfo: 'No Information',
    //   isListCard: true
    // },
    {
      id: "a3",
      labelTextOneOne: "node",
      labelTextOneOneBadgeOne: "primary",
      labelTextOneOneBadgeTwo: "secondary",
      labelTextOneOneBadgeThree: "",
      labelTextOneOneBadgeFour: "",
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'primary',
          innerList: nodePrimary.sort((a, b) =>
            a.textOne.toLowerCase() !== b.textOne.toLowerCase()
              ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
                ? -1
                : 1
              : 0
          )
        },
        {
          labelTextOneOneBadge: 'secondary',
          innerList: nodeSecondary.sort((a, b) =>
            a.textOne.toLowerCase() !== b.textOne.toLowerCase()
              ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
                ? -1
                : 1
              : 0
          )
        }
      ],
      innerInfo: "No Information",
      isListCard: true,
    },
    {
      id: "a4",
      labelTextOneOne: "type",
      labelTextOneOneBadgeOne: "primary",
      labelTextOneOneBadgeTwo: "secondary",
      labelTextOneOneBadgeThree: "",
      labelTextOneOneBadgeFour: "",
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: "primary",
          innerList: groupTypeListPrimary.sort((a, b) =>
          a.textOne.toLowerCase() !== b.textOne.toLowerCase()
            ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
              ? -1
              : 1
            : 0
        ),
        },
        {
          labelTextOneOneBadge: "secondary",
          innerList: groupTypeListSecondary.sort((a, b) =>
          a.textOne.toLowerCase() !== b.textOne.toLowerCase()
            ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
              ? -1
              : 1
            : 0
        ),
        },
      ],
      innerInfo: "No Information",
      isListCard: true,
    },
  ];
  const list3 = [
    {
      id: "a1",
      labelTextOneOne: "log",
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: "all",
          textOne: "",
        },
        {
          labelTextOneOneBadge: "key",
          textOne: "",
        },
      ],
      innerAssociateList: [],
      innerInfo: "assessees",
      isListCard: false,
      IconOne: Manuscript,
    },
    {
      id: "a2",
      textOneOne:
        capitalizeFirstLetter(informationEngagement?.associateGroupStatus) ||
        "No Information",
      labelTextOneOne: "status",
      innerAssociateList: [],
      innerInfo: "No Information",
      isListCard: false,
    },
    {
      id: "a3",
      labelTextOneOne: "tag",
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: "primary",
          textOne:
            informationEngagement?.associateGroupTag
              ?.associateGroupTagPrimary || "No Information",
        },
        {
          labelTextOneOneBadge: "secondary",
          textOne:
            informationEngagement?.associateGroupTag
              ?.associateGroupTagSecondary || "No Information",
        },
      ],
      innerAssociateList: [],
      innerInfo: "No Information",
      isListCard: false,
    },
    {
      id: "a4",
      labelTextOneOne: "tenure",
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: "start",
          textOne:
            informationEngagement?.associateGroupTenure
              ?.associateGroupTenureDateTimeStart || "No Information",
        },
        {
          labelTextOneOneBadge: "end",
          textOne:
            informationEngagement?.associateGroupTenure
              ?.associateGroupTenureDateTimeEnd || "No Information",
        },
      ],
      innerAssociateList: [],
      innerInfo: "Assessee",
      isListCard: false,
    },
  ];

  const list4 = [
    {
      id: "a1",
      textOneOne:
        informationSetup?.associateGroupClassification
          .associateGroupClassificationPrimary || "No Information",
      labelTextOneOne: "classification",
      innerAssociateList: [],
      innerInfo: "No Information",
      isListCard: false,
    },
    {
      id: 'setupList1',
      labelTextOneOne: 'fee',
      labelTextOneOneBadges: 
      [
        {
          labelTextOneOneBadge: 'move',
          textOne: informationSetup?.associateGroupMoveFee || 0
        },
        {
          labelTextOneOneBadge: 'sell',
          textOne: informationSetup?.associateGroupSellFee || 0
        },
        {
          labelTextOneOneBadge: 'share',
          textOne: informationSetup?.associateGroupShareFee ||  0
        }
      ],
      innerInfo: 'No',
      isListCard: false,
      isReviewLink: false,
      isMultiInfoCard: false
    }
  ];

  const reviseAllocation = (e) => {
    const labelName = e.currentTarget.getAttribute("data-value");
    const selectedBadgeName = e.currentTarget.getAttribute("data-key");
    const innerSelectedBadgeName = e.currentTarget.getAttribute("id");
    console.log("=====>", labelName, selectedBadgeName);
    //group click open popup
    if (labelName === "group") {
      if (
        selectedBadgeName === "ascendant" &&
        innerSelectedBadgeName === "primary"
      ) {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: "GROUPLISTPOPUP",
            popupMode: "associatesGROUPCREATE",
          },
        });
        dispatch({ type: LOADER_START });
        let requestObj = makeAssociateGroupObj(
          selectedAssociateInfo,
          "active",
          0,
          -1,
          'list',
          assignmentCreateType
        );
        dispatch({
          type: SET_CORE_GROUP_REVIEW_LIST_REQ_OBJECT,
          payload: requestObj,
        });
        dispatch({
          type: GET_ASSOCIATE_GROUP_REVIEW_LIST_SAGA,
          payload: {
            request: requestObj,
            BadgeOne: "",
            BadgeTwo: "",
            BadgeThree: "",
            isMiddlePaneList: false,
          },
        });
      }
    }
    //
    if (labelName === "node") {
      if (selectedBadgeName === "primary") {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: "NODELISTPOPUP",
            popupMode: "associatesGROUPCREATE",
          },
        });
      dispatch({ type: LOADER_START });
      let nodeRequestObj = makeInternalNodeObj(selectedAssociateInfo, 'active', 0, -1, '', 'Associate-Self-Node-List-Create');
        dispatch({ type: SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT, payload: nodeRequestObj });
        console.log('nodeRequestObj',nodeRequestObj);
        dispatch({ type: LOADER_START });
        dispatch({
          type: INTERNAL_NODE_LIST_WITH_PERMISSIONS_SAGA,
          payload: {
            request: nodeRequestObj,
            BadgeOne: '',
            BadgeTwo: '',
            BadgeThree: '',
            nodeViewState: 'list',
            isMiddlePaneList: false
          }
        });
      }
      if (selectedBadgeName === "secondary") {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: "NODESECONDARYLISTPOPUP",
            popupMode: "associatesGROUPCREATE",
          },
        });
        dispatch({ type: LOADER_START });
        let nodeRequestObj = makeInternalNodeObj(selectedAssociateInfo, 'active', 0, -1, '', 'Associate-Self-Node-List-Create');
          dispatch({ type: SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT, payload: nodeRequestObj });
          console.log('nodeRequestObj',nodeRequestObj);
          dispatch({ type: LOADER_START });
          dispatch({
            type: INTERNAL_NODE_LIST_WITH_PERMISSIONS_SAGA,
            payload: {
              request: nodeRequestObj,
              BadgeOne: '',
              BadgeTwo: '',
              BadgeThree: '',
              nodeViewState: 'list',
              isMiddlePaneList: false
            }
          });
      }
    }
    if (labelName === "role") {
      if (selectedBadgeName === "primary") {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: "ROLELISTPOPUP",
            popupMode: "associatesGROUPCREATE",
          },
        });
      }
      if (selectedBadgeName === "secondary") {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: "ROLESECONDARYLISTPOPUP",
            popupMode: "associatesGROUPCREATE",
          },
        });
      }
    }

    if (labelName === "type") {
      let typeRequestObj = makeAssociateTypeObj(selectedAssociateInfo, 'active', 0, -1);
      dispatch({ type: LOADER_START });
      dispatch({
        type: SET_CORE_TYPE_REVIEW_LIST_REQ_OBJECT,
        payload: typeRequestObj
      });
      dispatch({
        type: GET_ASSOCIATE_TYPE_REVIEW_LIST_SAGA,
        payload: {
          request: typeRequestObj,
          BadgeOne: '',
          BadgeTwo: '',
          BadgeThree: '',
          isMiddlePaneList: false
        }
      });
      if (selectedBadgeName === "primary") {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: "TYPELISTPOPUP",
            popupMode: "associatesGROUPCREATE",
          },
        });
      }
      if (selectedBadgeName === "secondary") {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: "TYPESECONDARYLISTPOPUP",
            popupMode: "associatesGROUPCREATE",
          },
        });
      }
    }
  };

  const reviseEngagement = (e) => {
    const labelName = e.currentTarget.getAttribute("data-value");
    const selectedBadgeName = e.currentTarget.getAttribute("data-key");
    console.log("=====>", labelName);
    if (labelName === "status") {
      dispatch({
        type: SET_STATUS_POPUP_VALUE,
        payload: capitalizeFirstLetter(
          informationEngagement?.associateGroupStatus
        ),
      });
      dispatch({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: "STATUSPOPUP",
          popupMode: "associatesGROUPCREATE",
        },
      });
    }
    if (labelName === "tag") {
      if (selectedBadgeName === "primary") {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: "TAGREADONLYPRIMARYPOPUP",
            popupMode: "associatesGROUPCREATE",
          },
        });
      }
      if (selectedBadgeName === "secondary") {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: "TAGSECONDARYPOPUP",
            popupMode: "associatesGROUPCREATE",
          },
        });
      }
    }
    if (labelName === "tenure") {
      if (selectedBadgeName === "start") {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: "TENURESATRTDATEPOPUP",
            popupMode: "associatesGROUPCREATE",
          },
        });
      }
      if (selectedBadgeName === "end") {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: "TENUREENDDATEPOPUP",
            popupMode: "associatesGROUPCREATE",
          },
        });
      }
    }
  };

  const reviseCLassification = (e) => {
    const labelName = e.currentTarget.getAttribute("data-value");
    const selectedBadgeName = e.currentTarget.getAttribute("data-key");
    console.log("=====>", labelName);
    if (labelName === "classification") {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: "CLASSIFICATIONLISTPOPUP",
          popupMode: "associatesGROUPCREATE",
        },
      });
    }
    if (labelName === 'fee' && selectedBadgeName === 'move') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'GROUPSASSOCIATEMOVEFEEPOPUP', popupMode: 'associatesGROUPCREATE' }
      });
    }
    if (labelName === 'fee' && selectedBadgeName === 'sell') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'GROUPSASSOCIATESELLFEEPOPUP', popupMode: 'associatesGROUPCREATE' }
      });
    }
    if (labelName === 'fee' && selectedBadgeName === 'share') {
      // dispatch({
      //   type: SET_POPUP_VALUE,
      //   payload: {
      //     isPopUpValue: 'GROUPSASSOCIATESHAREFEEPOPUP',
      //     popupMode: 'associatesGROUPCREATE'
      //   }
      // });
    }
  };

  return (
    <div>
      <>
        <div style={{ padding: "5px 2.5px 2.5px 2.5px" }}>
          <Paper className={"dossierContainerTop"}>
            {list2.map((ob) => {
              return (
                <div key={ob.id}>
                  {ob.isListCard ? (
                    <>
                      {ob.isMultiList ? (
                        <DisplayPanelAccordianReviewListTwo
                          onClickReview={null}
                          onClickRevise={reviseAllocation}
                          accordianObject={ob}
                          mode={reviewMode}
                        />
                      ) : (
                        <DisplayPanelAccordianReviewListOne
                          onClickRevise={reviseAllocation}
                          className=""
                          accordianObject={ob}
                          mode={reviewMode}
                        />
                      )}
                    </>
                  ) : (
                    <DisplayPanelAccordianInformation
                      onClickRevise={reviseAllocation}
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  )}
                  {/* {ob.isListCard ? (
                    <DisplayPanelAccordianReviewListOne
                      onClickRevise={reviseAllocation}
                      className=""
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  ) : (
                    <DisplayPanelAccordianInformation
                      onClickRevise={reviseAllocation}
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  )} */}
                </div>
              );
            })}
          </Paper>
        </div>
        <div className="containerPadding">
          <Paper className={"dossierContainerTop"}>
            {list3.map((ob) => {
              return (
                <div key={ob.id}>
                  {ob.isListCard ? (
                    <DisplayPanelAccordianReviewListOne
                      onClickRevise={reviseEngagement}
                      className=""
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  ) : (
                    <DisplayPanelAccordianInformation
                      onClickRevise={reviseEngagement}
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  )}
                </div>
              );
            })}
          </Paper>
        </div>

        <div className="containerPadding">
          <Paper className={"dossierContainerTop"}>
            {list4.map((ob) => {
              return (
                <div key={ob.id}>
                  {ob.isListCard ? (
                    <DisplayPanelAccordianReviewListOne
                      onClickRevise={reviseCLassification}
                      className=""
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  ) : (
                    <DisplayPanelAccordianInformation
                      onClickRevise={reviseCLassification}
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  )}
                </div>
              );
            })}
          </Paper>
        </div>
      </>
      {isMobile && (
        <div className={"containerPadding"} style={{ height: "55px" }}>
          {" "}
        </div>
      )}
    </div>
  );
};

export default DisplayPaneThreeSectionOneAssociateGroup;
