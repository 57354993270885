import React, { useState } from "react";
import { isMobile } from "react-device-detect";
// import AllocationAccordian from '../Accordian/AllocationAccordian';
// import Manuscript from '@material-ui/icons/Description';
import { useDispatch, useSelector } from "react-redux";
import DisplayPanelAccordianReviewListOne from "../Accordian/DisplayPanelAccordianReviewListOne";
import DisplayPanelAccordianInformation from "../Accordian/DisplayPanelAccordianInformation";
import { Paper } from "@material-ui/core";
import { getAssesseeeDistinctToAllocate, getAssesseeTypeAssesseeDistinctApiCall} from "../../Actions/AssesseeModuleAction";
import { makeAdministratorsReviewListRequestObject, makeAssesseeReviewListRequestObject } from "../../Actions/GenericActions";

const DisplayPaneThreeSectionTwoAssesseeType = () => {
  const [listExpand, setListExpand] = useState("");
  const dispatch = useDispatch();
  const {
    reviewMode,
    relatedReviewListPaneThree,
    responseObject,
    headerOne,
  } = useSelector((state) => state.DisplayPaneThreeReducer);
  const { selectedAssociateInfo, countPage, middlePaneHeader } = useSelector(
    (state) => state.DisplayPaneTwoReducer
  );
  // const { informationEngagement, informationSetup } = responseObject;
  // function capitalizeFirstLetter(string) {
  //   if (!string) return '';
  //   return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  // }
  let assessee = relatedReviewListPaneThree[0]?.assessee || [];

  let assesseeArray = [];
  assessee.forEach((ob) => {
    const { id, informationBasic,informationEngagement, associateId } = ob;
    assesseeArray.push({
      id,
      textOne: `${informationBasic.assesseeNameFirst} ${informationBasic.assesseeNameOther} ${informationBasic.assesseeNameLast}`,
      textTwo: informationBasic.assesseeAlias || "",
      status: informationEngagement?.assesseeStatus || '',
      isShared: ob?.assesseeShared,
      associateId
    });
  });
  const list2 = [
    {
      id: "a1",
      labelTextOneOne: headerOne ||  "assessees",
      labelTextOneOneBadgeOne: "",
      labelTextOneOneBadgeTwo: "",
      labelTextOneOneBadgeThree: "",
      labelTextOneOneBadgeFour: "",
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: "distinct",
          innerList: assesseeArray,
        },
      ],
      innerInfo: "No Information",
      isListCard: true,
      isReviewLink: true
    },
  ];

  /// TEXT file code here
  const onclickReviewAssessee = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    if (labelName === "assessees" && selectedBadgeName === "distinct") {
      console.log(
        'relatedReviewListPaneThree',
        relatedReviewListPaneThree.length,
        relatedReviewListPaneThree
      );
      getAssesseeTypeAssesseeDistinctApiCall(
        selectedAssociateInfo,
        '',
        //'all',
        -1,
        dispatch,
        'distinct',
        responseObject.id, //group id
        '',
        false,
        true
      );
    }
    if (labelName === "administrators" && selectedBadgeName === "distinct") {
      console.log(
        'relatedReviewListPaneThree',
        relatedReviewListPaneThree.length,
        relatedReviewListPaneThree
      );
      getAssesseeTypeAssesseeDistinctApiCall(
        selectedAssociateInfo,
        '',
        -1,
        dispatch,
        'distinct',
        responseObject.id, //group id
        '',
        false,
        middlePaneHeader,
      );
    }
  };
  const onclickReviseAssessee = (e) => {
    const labelName = e.currentTarget.getAttribute("data-value");
    const selectedBadgeName = e.currentTarget.getAttribute("data-key");
    if (labelName === "assessees" && selectedBadgeName === "distinct") {
      let requestObect = makeAssesseeReviewListRequestObject(
        selectedAssociateInfo,
        '',
        //"activeinactive",
        0,
        countPage
      );
      let revisedGroupObject = {
        id: responseObject.id,
        assesseeTypeName: responseObject.informationBasic.assesseeTypeName,
        assesseeTypeDescription:
          responseObject.informationBasic.assesseeTypeDescription,
        assesseeTypeStatus:
          responseObject.informationEngagement.assesseeTypeStatus,
      };
      let existingAssesseeId = [];
      let tempArr = relatedReviewListPaneThree[0]?.assessee || [];
      existingAssesseeId = tempArr.map((val) => {
        return val.id;
      });
      getAssesseeeDistinctToAllocate(
        dispatch,
        revisedGroupObject,
        requestObect,
        (existingAssesseeId && existingAssesseeId) || [],
        'assesseeTypeAssesseeRevise',
        'assesseesTypeAssesseeReviewList',
        []
      );
    }
    if (labelName === "administrators" && selectedBadgeName === "distinct") {
      let requestObect = makeAdministratorsReviewListRequestObject(
        selectedAssociateInfo,
        'all',
        0,
        countPage
      );
      let revisedGroupObject = {
        id: responseObject.id,
        assesseeTypeName: responseObject.informationBasic.assesseeTypeName,
        assesseeTypeDescription:
          responseObject.informationBasic.assesseeTypeDescription,
        assesseeTypeStatus:
          responseObject.informationEngagement.assesseeTypeStatus,
      };
      let existingAssesseeId = [];
      let tempArr = relatedReviewListPaneThree[0]?.assessee || [];
      existingAssesseeId = tempArr.map((val) => {
        return val.id;
      });
      getAssesseeeDistinctToAllocate(
        dispatch,
        revisedGroupObject,
        requestObect,
        (existingAssesseeId && existingAssesseeId) || [],
        'assesseeTypeAssesseeRevise',
        'assesseesTypeAssesseeReviewList',
        [],
        [],
        middlePaneHeader
      );
    }
  };
  /////
  return (
    <div>
      <>
        <div className={"containerPadding"}>
          <Paper className={"dossierContainerTop"}>
            {list2.map((ob) => {
              return (
                <div key={ob.id}>
                  {ob.isListCard ? (
                    <DisplayPanelAccordianReviewListOne
                      onClickRevise={onclickReviseAssessee}
                      onClickReview={onclickReviewAssessee}
                      className=""
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  ) : (
                    <DisplayPanelAccordianInformation
                      onClickRevise={onclickReviseAssessee}
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  )}
                </div>
              );
            })}
          </Paper>
        </div>
      </>
      {isMobile && (
        <div className={"containerPadding"} style={{ height: "55px" }}>
          {" "}
        </div>
      )}
    </div>
  );
};

export default DisplayPaneThreeSectionTwoAssesseeType;
