import React from 'react';
import { isMobile } from 'react-device-detect';
// import AllocationAccordian from '../Accordian/AllocationAccordian';
// import Manuscript from '@material-ui/icons/Description';
import { useDispatch, useSelector } from 'react-redux';
import DisplayPanelAccordianReviewListOne from '../Accordian/DisplayPanelAccordianReviewListOne';
import DisplayPanelAccordianInformation from '../Accordian/DisplayPanelAccordianInformation';
import { Paper } from '@material-ui/core';
import { makeAssessmentReviewListRequestObject } from '../../Actions/GenericActions';
import {
  getAssessmentDistinctApiCall,
  getAssessmentDistinctToAllocate,
  getAssessmentTypeAssessmentDistinctApiCall
} from '../../Actions/AssessmentModuleAction';

const DisplayPaneThreeSectionTwoAssessmentType = () => {
  // const [listExpand, setListExpand] = useState('');
  const { reviewMode, relatedReviewListPaneThree, responseObject } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  const { selectedAssociateInfo, countPage, reviewListDistinctData, assignmentCreateType } = useSelector(
    (state) => state.DisplayPaneTwoReducer
  );
  const dispatch = useDispatch();
  // const { informationEngagement, informationSetup } = responseObject;
  // function capitalizeFirstLetter(string) {
  //   if (!string) return '';
  //   return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  // }

  const onclickReviewAssessment = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    if (labelName === 'assessments' && selectedBadgeName === 'distinct') {
      console.log(
        'relatedReviewListPaneThree',
        relatedReviewListPaneThree.length,
        relatedReviewListPaneThree
      );
      getAssessmentTypeAssessmentDistinctApiCall(
        selectedAssociateInfo,
        '',
        //'all',
        -1,
        dispatch,
        'distinct',
        responseObject.id, //group id
        '',
        false,
        true
      );
    }
  };
  const onclickReviseAssessment = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    if (labelName === 'assessments' && selectedBadgeName === 'distinct') {
      console.log('assessment CLICK :::::::>>>>>>>', relatedReviewListPaneThree);
      let requestObect = makeAssessmentReviewListRequestObject(
        selectedAssociateInfo,
        '',
        //'activeinactive',
        countPage,
        0,
        ''
      );
      let revisedRelatedObject = {
        id: responseObject.id,
        assessmentTypeName: responseObject.informationBasic?.assessmentTypeName,
        assessmentTypeDescription: responseObject.informationBasic?.assessmentTypeDescription,
        assessmentTypeStatus: responseObject.informationEngagement?.assessmentTypeStatus
      };
      let existingAssessmentId = [];
      if (relatedReviewListPaneThree?.length > 0) {
        existingAssessmentId = relatedReviewListPaneThree[0]?.assessment.map((ob) => ob.id);
        console.log("existingAssessmentId",existingAssessmentId);
      }
      console.log("relatedReviewListPaneThree---",relatedReviewListPaneThree);
      getAssessmentDistinctToAllocate(
        revisedRelatedObject,
        requestObect,
        existingAssessmentId ? existingAssessmentId : [],
        dispatch,
        'assessmentTypeAssessmentRevise',
        'assessmentTypeAssessmentReviewList',
        []
      );
    }
  };
  console.log('relatedReviewListPaneThree', relatedReviewListPaneThree);
  let assessmentArray = [];
  if (relatedReviewListPaneThree?.length > 0) {
    let assessmentList = relatedReviewListPaneThree[0]?.assessment || [];
    assessmentList &&
      assessmentList.forEach((ob) => {
        const { id, informationBasic, informationEngagement, associateId } = ob;
        assessmentArray.push({
          id,
          textOne: informationBasic?.assessmentName || '',
          textTwo: informationBasic?.assessmentDescription || '',
          status: informationEngagement?.assessmentStatus || '',
          isShared: ob?.assessmentShared,
      associateId
        });
      });
  }
  const list2 = [
    {
      id: 'a1',
      labelTextOneOne: 'assessments',
      labelTextOneOneBadgeOne: '',
      labelTextOneOneBadgeTwo: '',
      labelTextOneOneBadgeThree: '',
      labelTextOneOneBadgeFour: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'distinct',
          innerList: assessmentArray
        }
      ],
      innerInfo: 'No Information',
      isListCard: true,
      isReviewLink: true
    }
  ];

  return (
    <div>
      <>
        <div className={'containerPadding'}>
          <Paper className={'dossierContainerTop'}>
            {list2.map((ob) => {
              return (
                <div key={ob.id}>
                  {ob.isListCard ? (
                    <DisplayPanelAccordianReviewListOne
                      onClickRevise={onclickReviseAssessment}
                      onClickReview={onclickReviewAssessment}
                      className=""
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  ) : (
                    <DisplayPanelAccordianInformation
                      onClickRevise={onclickReviseAssessment}
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  )}
                </div>
              );
            })}
          </Paper>
        </div>
      </>
      {isMobile && (
        <div className={'containerPadding'} style={{ height: '55px' }}>
          {' '}
        </div>
      )}
    </div>
  );
};

export default DisplayPaneThreeSectionTwoAssessmentType;
