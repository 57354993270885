import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PopUpPicture from '../../PopUpInformation/PopUpPicture';
import PopUpTextField from '../../PopUpInformation/PopUpTextField';
import PopUpConfirm from '../../PopUpGeneric/PopUpConfirm';
import {
  POPUP_CLOSE,
  CREATE_GROUP_SAGA,
  LOADER_START,
  CLEAR_GROUP_REDUCER_STATE,
  UPDATE_ASSESSEE_PERSONAL_INFO,
  UPDATE_ASSESSEE_ENGAGEMENT_INFO,
  SET_DISPLAY_THREE_SINGLE_STATE,
  SET_GROUP_ALLOCATION_STATE,
  SET_ASSESSEE_CLASSIFICAION_STATE,
  SET_ASSESSMENT_CLASSIFICAION_STATE,
  SET_ASSIGNMENT_CLASSIFICAION_STATE,
  SET_ASSOCIATE_CLASSIFICAION_STATE,
  SET_CULTUREPROFILE_CLASSIFICAION_STATE,
  SET_JOBPROFILE_CLASSIFICAION_STATE,
  SET_ITEM_CLASSIFICAION_STATE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_GROUP_ALLOCATION_GROUP_ASCENDANT_STATE,
  SET_POPUP_SINGLE_STATE,
  SET_GROUPS_SELL_FEE_STATE,
  SET_GROUPS_MOVE_FEE_STATE,
  SET_GROUPS_SHARE_FEE_STATE,
  POPUP_OPEN,
  SET_POPUP_STATE
} from '../../actionType';
import PopUpReviewList from '../../PopUpInformation/PopUpReviewList';
import PopUpDropList from '../../PopUpInformation/PopUpDropList';
import PopUpTagSecondary from '../../PopUpInformation/PopUpTagSecondary';
import {
  ASSESSEES_GROUP_REVIEW_LIST_POPUP_OPTION,
  GROUP_REVIEW_LIST_POPUP_OPTION,
} from '../../PopUpConfig';
import { distinctGroupSharePopupOpen, getPopUpOptionList } from '../../Actions/GenericActions';

const PopUpGroupCreate = (props) => {
  const {
    headerOne,
    reducerObeject,
    groupDescription,
    groupName,
    setReducerObject,
    objectName,
    groupNameVerification,
    groupPicture,
    groupPictureVerification,
    typeName,
    typeDescription,
    typeDefaultKey
  } = props;
  const {
    isPopUpValue,
    popupErrorMsg,
    selectedTagValue,
    selectedTagInformationSetup,
    duplicateHeaderOne,
    duplicateHeaderOneBadgeOne
  } = useSelector((state) => state.PopUpReducer);
  const [nodeSelectedError, setNodeSelectedError] = useState('');
  const [roleSelectedError, setRoleSelectedError] = useState('');
  const [gropselected, setgropselected] = useState('');
  const {
    selectedAssociateInfo,
    coreNodeReviewListData,
    coreTypeReviewListData,
    signedAssesseePermission,
    coreGroupReviewListData
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const dispatch = useDispatch();
  const { reviewMode, responseObject, statusPopUpValue, createMode } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  useEffect(() => {
    let tempTypeArr = coreTypeReviewListData.filter((tt) => {
      return tt.informationSetup[typeDefaultKey];
    });
    if (tempTypeArr.length > 0) {
      console.log('reducerObeject', reducerObeject);
      let arr = tempTypeArr.map((ob) => ob.id);
      reducerObeject.informationAllocation[objectName + 'Type'][objectName + 'TypePrimary'] = arr;
    }
  }, [coreTypeReviewListData, createMode]);
  const updateAllocationObj = (e, stateName, actualStateName) => {
    let tagId = e.currentTarget.getAttribute('tag');
    console.log('tagId', tagId);
    setNodeSelectedError('');
    let groupArr = reducerObeject.informationAllocation[stateName][actualStateName];
    let isDefault = e.currentTarget.getAttribute('data-default') || 'false';
    if (isDefault !== 'true') {
      if (groupArr.includes(tagId)) {
        document.getElementById(tagId).style.backgroundColor = 'white';
        groupArr = groupArr.filter(function (number) {
          return number !== tagId;
        });
      } else {
        groupArr.push(tagId);
        document.getElementById(tagId).style.backgroundColor = '#F0F0F0';
      }
    }
    dispatch({
      type: SET_GROUP_ALLOCATION_STATE,
      payload: {
        objectName: objectName,
        stateName: stateName,
        actualStateName: actualStateName,
        value: groupArr
      }
    });
  };

  let primaryListTypeArr = coreTypeReviewListData.filter((ele) => {
    return ele.informationSetup[typeDefaultKey];
  });
  // let secondaryListTypeArr = coreTypeReviewListData.filter(
  //   (tt) => !primaryListTypeArr.includes(tt)
  // );
  let listTypeArr = coreTypeReviewListData.filter((tt) => !primaryListTypeArr.includes(tt));
  let secondaryListTypeArr =
    listTypeArr.length > 0
      ? listTypeArr.sort((a, b) =>
          a.informationBasic[objectName + 'TypeName']?.toLowerCase() !==
          b.informationBasic[objectName + 'TypeName']?.toLowerCase()
            ? a.informationBasic[objectName + 'TypeName']?.toLowerCase() <
              b.informationBasic[objectName + 'TypeName']?.toLowerCase()
              ? -1
              : 1
            : 0
        )
      : [];
  // console.log('primaryListTypeArr length', primaryListTypeArr.length);
  // console.log('secondaryListTypeArr length', secondaryListTypeArr.length);

  //
  const updateAllocationGroupObj = (e, stateName) => {
    let tagId = e.currentTarget.getAttribute('tag');
    console.log('tagId', tagId);
    setNodeSelectedError('');
    let groupArr = reducerObeject.informationAllocation[stateName];
    console.log('groupArr', groupArr);
    console.log('stateName', stateName);
    if (groupArr.includes(tagId)) {
      document.getElementById(tagId).style.backgroundColor = 'white';
      groupArr = groupArr.filter(function (number) {
        return number !== tagId;
      });
    } else {
      groupArr.push(tagId);
      document.getElementById(tagId).style.backgroundColor = '#F0F0F0';
    }
    dispatch({
      type: SET_GROUP_ALLOCATION_GROUP_ASCENDANT_STATE,
      payload: {
        objectName: objectName,
        stateName: stateName,
        // actualStateName: actualStateName,
        value: groupArr
      }
    });
  };
  //

  const onClickCancelYes = () => {
    dispatch({
      type: SET_DISPLAY_THREE_SINGLE_STATE,
      payload: { stateName: 'createMode', value: '' }
    });
    dispatch({ type: CLEAR_GROUP_REDUCER_STATE });
    dispatch({ type: POPUP_CLOSE });
  };
  const onClickYes = () => {
    // add default root node in allocation if node not selected
    if (
      reducerObeject.informationAllocation[objectName + 'Node'][objectName + 'NodePrimary']
        .length === 0
    ) {
      let rootNode = coreNodeReviewListData.filter((node) => {
        return node.informationFramework.associateNodeAscendantPrimary === null;
      });
      let rootNodeId = rootNode[0].id;
      reducerObeject.informationAllocation[objectName + 'Node'][objectName + 'NodePrimary'] = [
        ...reducerObeject.informationAllocation[objectName + 'Node'][objectName + 'NodePrimary'],
        rootNodeId
      ];
    }
    let reqBody = {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      whichGroupCreate: headerOne,
      [objectName]: reducerObeject
    };
    let permissionSetup;
    if (headerOne === 'assessees') {
      permissionSetup =
        signedAssesseePermission?.assesseeDistinctPermission?.assesseeAssesseeGroupPermission;
      reqBody = {
        whichGroupCreate: headerOne,
        module: 'Assessee-Group',
        action: 'Create',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          [objectName]: reducerObeject
        }
      };
    }
    if (headerOne === 'administrators' || headerOne === 'managers' || headerOne === 'assistants') {
      permissionSetup =
        signedAssesseePermission?.assesseeDistinctPermission?.assesseeAssesseeGroupPermission;
      reqBody = {
        previousHeaderOne: headerOne,
        whichGroupCreate: 'assessees',
        module: 'Assessee-Group',
        action: 'Create',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          [objectName]: reducerObeject
        }
      };
    }
    if (headerOne === 'assessments') {
      permissionSetup =
        signedAssesseePermission?.assessmentDistictPermission?.assesseeAssessmentGroupPermission;
      reqBody = {
        whichGroupCreate: headerOne,
        module: 'Assessment-Group',
        action: 'Create',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          [objectName]: reducerObeject
        }
      };
    }
    if (headerOne === 'assignments') {
      permissionSetup =
        signedAssesseePermission?.assigmentDistictPermission?.assesseeAssignmentGroupPermission;
      reqBody = {
        whichGroupCreate: headerOne,
        module: 'Assignment-Group',
        action: 'Create',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          [objectName]: reducerObeject
        }
      };
    }
    if (headerOne === 'associates') {
      permissionSetup =
        signedAssesseePermission?.associateDistinctPermission?.assesseeAssociateGroupPermission;
      reqBody = {
        whichGroupCreate: headerOne,
        module: 'Associate-Group',
        action: 'Create',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          [objectName]: reducerObeject
        }
      };
    }
    if (headerOne === 'items') {
      permissionSetup = signedAssesseePermission?.itemDistictPermssion?.assesseeItemGroupPermission;
      reqBody = {
        whichGroupCreate: headerOne,
        module: 'Item-Group',
        action: 'Create',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          [objectName]: reducerObeject
        }
      };
    }
    if (headerOne === 'culture-profiles') {
      permissionSetup =
        signedAssesseePermission?.iGuruAnalyticDistinctPermission
          ?.assesseeiGuruAnalyticGroupPermission;
      reqBody = {
        whichGroupCreate: headerOne,
        module: 'Culture-Profile-Group',
        action: 'Create',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          [objectName]: reducerObeject
        }
      };
    }
    if (headerOne === 'job-profiles') {
      permissionSetup =
        signedAssesseePermission?.iGuruAnalyticDistinctPermission
          ?.assesseeiGuruAnalyticGroupPermission;
      reqBody = {
        whichGroupCreate: headerOne,
        objectName: objectName,
        module: 'Job-Profile-Group',
        action: 'Create',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          [objectName]: reducerObeject
        }
      };
    }
    // let tempArray =
    //   headerOne === 'assessees' ||
    //   headerOne === 'administrators' ||
    //   headerOne === 'managers' ||
    //   headerOne === 'assistants'
    //     ? ASSESSEES_GROUP_REVIEW_LIST_POPUP_OPTION
    //     : GROUP_REVIEW_LIST_POPUP_OPTION;
    console.log('reqBody', reqBody);
    console.log('headerOne------', headerOne);
    let popupContentArrValue = getPopUpOptionList(
      GROUP_REVIEW_LIST_POPUP_OPTION,
      permissionSetup
    );
    let finalPopupOptionArray = [];
    let classification =
      reducerObeject?.informationSetup[objectName + 'Classification'][
        objectName + 'ClassificationPrimary'
      ];
    popupContentArrValue.forEach((element) => {
      if (classification === 'Bespoke' && element.data === 'share') {
        finalPopupOptionArray.push({ ...element, disabled: true });
      } else {
        finalPopupOptionArray.push(element);
      }
    });
    console.log('finalPopupOptionArray>>', finalPopupOptionArray); //changes
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: finalPopupOptionArray
      }
    });
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: {
        stateName: 'popupOptionArr',
        value: finalPopupOptionArray
      }
    });
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: {
        stateName: 'originPrimaryArrOprion',
        value: finalPopupOptionArray
      }
    });
    //console.log('CREATE group api', reqBody);
    dispatch({ type: LOADER_START });
    dispatch({ type: CREATE_GROUP_SAGA, payload: reqBody });
  };
  let tagPrimary = '';
  let classificationArray = [];
  let tenureStartDate = 'mm/dd/yyyy --:-- --';
  let tenureEndDate = 'mm/dd/yyyy --:-- --';
  if (headerOne === 'assessees') {
    tagPrimary =
      responseObject?.informationEngagement?.assesseeGroupTag?.assesseeGroupTagPrimary || '';
    tenureStartDate =
      responseObject?.informationEngagement?.assesseeGroupTenure
        ?.assesseeGroupTenureDateTimeStart || 'mm/dd/yyyy --:-- --';
    tenureEndDate =
      responseObject?.informationEngagement?.assesseeGroupTenure?.assesseeGroupTenureDateTimeEnd ||
      'mm/dd/yyyy --:-- --';
    if (
      signedAssesseePermission?.assesseeDistinctPermission?.assesseeAssesseeGroupBespokePermission
        ?.create
    ) {
      classificationArray.push({ id: 'Bespoke', name: 'Bespoke' });
    }
    if (
      signedAssesseePermission?.assesseeDistinctPermission?.assesseeAssesseeGroupGenericPermission
        ?.create
    ) {
      classificationArray.push({ id: 'Generic', name: 'Generic' });
    }
  }
  if (headerOne === 'administrators' || headerOne === 'managers' || headerOne === 'assistants') {
    tagPrimary =
      responseObject?.informationEngagement?.assesseeGroupTag?.assesseeGroupTagPrimary || '';
    tenureStartDate =
      responseObject?.informationEngagement?.assesseeGroupTenure
        ?.assesseeGroupTenureDateTimeStart || 'mm/dd/yyyy --:-- --';
    tenureEndDate =
      responseObject?.informationEngagement?.assesseeGroupTenure?.assesseeGroupTenureDateTimeEnd ||
      'mm/dd/yyyy --:-- --';
    if (
      signedAssesseePermission?.assesseeDistinctPermission?.assesseeAssesseeGroupBespokePermission
        ?.create
    ) {
      classificationArray.push({ id: 'Bespoke', name: 'Bespoke' });
    }
    if (
      signedAssesseePermission?.assesseeDistinctPermission?.assesseeAssesseeGroupGenericPermission
        ?.create
    ) {
      classificationArray.push({ id: 'Generic', name: 'Generic' });
    }
  }
  if (headerOne === 'associates') {
    tagPrimary =
      responseObject?.informationEngagement?.associateGroupTag?.associateGroupTagPrimary || '';
    tenureStartDate =
      responseObject?.informationEngagement?.associateGroupTenure
        ?.associateGroupTenureDateTimeStart || 'mm/dd/yyyy --:-- --';
    tenureEndDate =
      responseObject?.informationEngagement?.associateGroupTenure
        ?.associateGroupTenureDateTimeEnd || 'mm/dd/yyyy --:-- --';
    if (
      signedAssesseePermission?.associateDistinctPermission?.assesseeAssociateGroupBespokePermission
        ?.create
    ) {
      classificationArray.push({ id: 'Bespoke', name: 'Bespoke' });
    }
    if (
      signedAssesseePermission?.associateDistinctPermission?.assesseeAssociateGroupGenericPermission
        ?.create
    ) {
      classificationArray.push({ id: 'Generic', name: 'Generic' });
    }
  }
  if (headerOne === 'assessments') {
    tagPrimary =
      responseObject?.informationEngagement?.assessmentGroupTag?.assessmentGroupTagPrimary || '';
    tenureStartDate =
      responseObject?.informationEngagement?.assessmentGroupTenure
        ?.assessmentGroupTenureDateTimeStart || 'mm/dd/yyyy --:-- --';
    tenureEndDate =
      responseObject?.informationEngagement?.assessmentGroupTenure
        ?.assessmentGroupTenureDateTimeEnd || 'mm/dd/yyyy --:-- --';
    if (
      signedAssesseePermission?.assessmentDistictPermission
        ?.assesseeAssessmentGroupBespokePermission?.create
    ) {
      classificationArray.push({ id: 'Bespoke', name: 'Bespoke' });
    }
    if (
      signedAssesseePermission?.assessmentDistictPermission
        ?.assesseeAssessmentGroupGenericPermission?.create
    ) {
      classificationArray.push({ id: 'Generic', name: 'Generic' });
    }
  }
  if (headerOne === 'assignments') {
    tagPrimary =
      responseObject?.informationEngagement?.assignmentGroupTag?.assignmentGroupTagPrimary || '';
    tenureStartDate =
      responseObject?.informationEngagement?.assignmentGroupTenure
        ?.assignmentGroupTenureDateTimeStart || 'mm/dd/yyyy --:-- --';
    tenureEndDate =
      responseObject?.informationEngagement?.assignmentGroupTenure
        ?.assignmentGroupTenureDateTimeEnd || 'mm/dd/yyyy --:-- --';
    if (
      signedAssesseePermission?.assigmentDistictPermission?.assesseeAssignmentGroupBespokePermission
        ?.create
    ) {
      classificationArray.push({ id: 'Bespoke', name: 'Bespoke' });
    }
    if (
      signedAssesseePermission?.assigmentDistictPermission?.assesseeAssignmentGroupGenericPermission
        ?.create
    ) {
      classificationArray.push({ id: 'Generic', name: 'Generic' });
    }
  }
  if (headerOne === 'items') {
    tagPrimary = responseObject?.informationEngagement?.itemGroupTag?.itemGroupTagPrimary || '';
    tenureStartDate =
      responseObject?.informationEngagement?.itemGroupTenure?.itemGroupTenureDateTimeStart ||
      'mm/dd/yyyy --:-- --';
    tenureEndDate =
      responseObject?.informationEngagement?.itemGroupTenure?.itemGroupTenureDateTimeEnd ||
      'mm/dd/yyyy --:-- --';
    if (
      signedAssesseePermission?.itemDistictPermssion?.assesseeItemGroupBespokePermission?.create
    ) {
      classificationArray.push({ id: 'Bespoke', name: 'Bespoke' });
    }
    if (
      signedAssesseePermission?.itemDistictPermssion?.assesseeItemGroupGenericPermission?.create
    ) {
      classificationArray.push({ id: 'Generic', name: 'Generic' });
    }
  }
  if (headerOne === 'job-profiles') {
    tagPrimary =
      responseObject?.informationEngagement?.jobProfileGroupTag?.jobProfileGroupTagPrimary || '';
    tenureStartDate =
      responseObject?.informationEngagement?.jobProfileGroupTenure
        ?.jobProfileGroupTenureDateTimeStart || 'mm/dd/yyyy --:-- --';
    tenureEndDate =
      responseObject?.informationEngagement?.jobProfileGroupTenure
        ?.jobProfileGroupTenureDateTimeEnd || 'mm/dd/yyyy --:-- --';
    if (
      signedAssesseePermission?.iGuruAnalyticDistinctPermission
        ?.assesseeiGuruAnalyticGroupBespokePermission?.create
    ) {
      classificationArray.push({ id: 'Bespoke', name: 'Bespoke' });
    }
    if (
      signedAssesseePermission?.iGuruAnalyticDistinctPermission
        ?.assesseeiGuruAnalyticGroupGenericPermission?.create
    ) {
      classificationArray.push({ id: 'Generic', name: 'Generic' });
    }
  }
  if (headerOne === 'culture-profiles') {
    tagPrimary =
      responseObject?.informationEngagement?.cultureProfileGroupTag
        ?.cultureProfileGroupTagPrimary || '';
    tenureStartDate =
      responseObject?.informationEngagement?.cultureProfileGroupTenure
        ?.cultureProfileGroupTenureDateTimeStart || 'mm/dd/yyyy --:-- --';
    tenureEndDate =
      responseObject?.informationEngagement?.cultureProfileGroupTenure
        ?.cultureProfileGroupTenureDateTimeEnd || 'mm/dd/yyyy --:-- --';
    if (
      signedAssesseePermission?.iGuruAnalyticDistinctPermission
        ?.assesseeiGuruAnalyticGroupBespokePermission?.create
    ) {
      classificationArray.push({ id: 'Bespoke', name: 'Bespoke' });
    }
    if (
      signedAssesseePermission?.iGuruAnalyticDistinctPermission
        ?.assesseeiGuruAnalyticGroupGenericPermission?.create
    ) {
      classificationArray.push({ id: 'Generic', name: 'Generic' });
    }
  }
  console.log('responseObject--', responseObject);
  let tempArrAscendantPrimary = [];
  let tempArr = [];
  if (Object.keys(responseObject).length > 0) {
    console.log(
      'tempArrAscendantPrimary.map>>>',
      responseObject?.informationAllocation[objectName + 'Descendant'][objectName + 'DescendantAll']
    );
    tempArr =
      responseObject?.informationAllocation[objectName + 'Descendant'][
        objectName + 'DescendantAll'
      ];
    if (tempArr.length > 0) {
      tempArrAscendantPrimary = tempArr?.map((dd) => {
        return dd.id;
      });
    }
  }
  // const onClickShareFee = () => {
  //   distinctGroupSharePopupOpen(
  //     dispatch,
  //     selectedTagValue,
  //     duplicateHeaderOne,
  //     duplicateHeaderOneBadgeOne
  //   );
  // };

  const signedAssociateId =
    selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary;
  return (
    <div>
      {isPopUpValue === 'NAMEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'name'}
          actualLableValue={groupName}
          actualLableValueVerification={groupNameVerification}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'group'}
          headerOneBadgeTwo={'information'}
          nextPopUpValue={'ALIASPOPUP'}
          basicInfo={reducerObeject.informationBasic}
          typeOfSetObject={setReducerObject}
          isRequired={true}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          errorMsg={popupErrorMsg}
          isNotRevised={reviewMode === 'revise' && responseObject.associateId !== signedAssociateId}
        />
      )}
      {isPopUpValue === 'ALIASPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'description'}
          actualLableValue={groupDescription}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'group'}
          headerOneBadgeTwo={'information'}
          basicInfo={reducerObeject.informationBasic}
          nextPopUpValue={'PICTUREPOPUP'}
          typeOfSetObject={setReducerObject}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={reviewMode === 'revise' && responseObject.associateId !== signedAssociateId}
        />
      )}
      {isPopUpValue === 'PICTUREPOPUP' && (
        <PopUpPicture
          isActive={true}
          headerPanelColour={'genericOne'}
          actualLableValue={groupPicture}
          actualLableValueVerification={groupPictureVerification}
          headerOne={headerOne}
          headerOneBadgeOne={'group'}
          headerOneBadgeTwo={'information'}
          nextPopUpValue={'GROUPLISTPOPUP'}
          basicInfo={reducerObeject.informationBasic}
          typeOfSetObject={setReducerObject}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={reviewMode === 'revise' && responseObject.associateId !== signedAssociateId}
        />
      )}
      {isPopUpValue === 'GROUPLISTPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'group'}
          headerOneBadgeTwo={'information'}
          nextPopUpValue={'NODELISTPOPUP'}
          inputHeader={'ascendant'}
          inputHeaderBadge={'primary'}
          infoMsg={'select a ascendant primary'}
          isRequired={false}
          // ListData={coreGroupReviewListData}
          ListData={
            (responseObject?.id &&
              coreGroupReviewListData.filter(function (group) {
                return (
                  group.id != responseObject?.id && !tempArrAscendantPrimary.includes(group.id)
                );
              })) ||
            coreGroupReviewListData
          }
          textOne={groupName}
          textTwo={groupDescription}
          selectedList={reducerObeject.informationAllocation[objectName + 'AscendantPrimary']}
          // onClickEvent={onClickGroupSelect}
          onClickEvent={(e) => {
            updateAllocationGroupObj(e, objectName + 'AscendantPrimary');
          }}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={reviewMode === 'revise' && responseObject.associateId !== signedAssociateId}
          useLocalState={true}
          stateName={`${objectName}AscendantPrimary`}
          objectName={objectName}
          updateState={SET_GROUP_ALLOCATION_GROUP_ASCENDANT_STATE}
        />
      )}

      {isPopUpValue === 'NODELISTPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'group'}
          headerOneBadgeTwo={'information'}
          nextPopUpValue={'TYPELISTPOPUP'}
          inputHeader={'node'}
          inputHeaderBadge={'primary'}
          infoMsg={'select a node'}
          isRequired={true}
          minimumSelected={1}
          ListData={coreNodeReviewListData}
          textOne={'associateNodeName'}
          textTwo={'associateNodeDescription'}
          setErrorMsg={setNodeSelectedError}
          errorMsg={nodeSelectedError}
          // onClickEvent={updateAllocationObj}
          selectedList={
            reducerObeject.informationAllocation[objectName + 'Node'][objectName + 'NodePrimary']
          }
          onClickEvent={(e) => {
            updateAllocationObj(e, objectName + 'Node', objectName + 'NodePrimary');
          }}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={reviewMode === 'revise' && responseObject.associateId !== signedAssociateId}
          useLocalState={true}
          stateName={`${objectName}Node`}
          actualStateName={`${objectName}NodePrimary`}
          objectName={objectName}
          updateState={SET_GROUP_ALLOCATION_STATE}
        />
      )}
      {isPopUpValue === 'NODESECONDARYLISTPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'group'}
          headerOneBadgeTwo={'information'}
          nextPopUpValue={''}
          inputHeader={'node'}
          inputHeaderBadge={'secondary'}
          infoMsg={'select a node'}
          ListData={coreNodeReviewListData}
          textOne={'associateNodeName'}
          textTwo={'associateNodeDescription'}
          onClickEvent={(e) => {
            updateAllocationObj(e, objectName + 'Node', objectName + 'NodeSecondary');
          }}
          selectedList={
            reducerObeject.informationAllocation[objectName + 'Node'][objectName + 'NodeSecondary']
          }
          // errorMsg={nodeSelectedError}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={reviewMode === 'revise' && responseObject.associateId !== signedAssociateId}
          useLocalState={true}
          stateName={`${objectName}Node`}
          actualStateName={`${objectName}NodeSecondary`}
          objectName={objectName}
          updateState={SET_GROUP_ALLOCATION_STATE}
        />
      )}
      {isPopUpValue === 'TYPELISTPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'group'}
          headerOneBadgeTwo={'information'}
          nextPopUpValue={'CLASSIFICATIONLISTPOPUP'}
          inputHeader={'type'}
          inputHeaderBadge={'primary'}
          dataDefault={typeDefaultKey}
          infoMsg={'select a type'}
          ListData={primaryListTypeArr}
          textOne={typeName}
          textTwo={typeDescription}
          onClickEvent={(e) => {
            updateAllocationObj(e, objectName + 'Type', objectName + 'TypePrimary');
          }}
          selectedList={
            reducerObeject.informationAllocation[objectName + 'Type'][objectName + 'TypePrimary']
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={reviewMode === 'revise' && responseObject.associateId !== signedAssociateId}
        />
      )}
      {isPopUpValue === 'TYPESECONDARYLISTPOPUP' && ( //
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'group'}
          headerOneBadgeTwo={'information'}
          nextPopUpValue={'CONFIRMATIONPOPUP'}
          inputHeader={'type'}
          inputHeaderBadge={'secondary'}
          infoMsg={'select a type'}
          ListData={secondaryListTypeArr}
          textOne={typeName}
          textTwo={typeDescription}
          onClickEvent={(e) => {
            console.log(
              reducerObeject.informationAllocation[objectName + 'Type'][
                objectName + 'TypeSecondary'
              ]
            );
            updateAllocationObj(e, objectName + 'Type', objectName + 'TypeSecondary');
          }}
          selectedList={
            reducerObeject.informationAllocation[`${objectName}Type`][`${objectName}TypeSecondary`]
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={reviewMode === 'revise' && responseObject.associateId !== signedAssociateId}
          useLocalState={true}
          stateName={`${objectName}Type`}
          actualStateName={`${objectName}TypeSecondary`}
          objectName={objectName}
          updateState={SET_GROUP_ALLOCATION_STATE}
        />
      )}
      {isPopUpValue === 'CLASSIFICATIONLISTPOPUP' && ( //
        <PopUpDropList
          isActive={true}
          tag={objectName + 'ClassificationPrimary'}
          label={'classification'}
          listSelect={classificationArray}
          mappingValue={'id'}
          inputHeader={''}
          inputHeaderBadgeOne={''}
          inputHeaderBadgeTwo={''}
          labelval={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'group'}
          headerOneBadgeTwo={'information'}
          isRequired={true}
          nextPopUpValue={reviewMode === 'revise' ? '' : 'CONFIRMATIONPOPUP'}
          basicInfo={reducerObeject?.informationSetup[objectName + 'Classification']}
          typeOfSetObject={
            objectName === 'assesseeGroup'
              ? SET_ASSESSEE_CLASSIFICAION_STATE
              : objectName === 'assessmentGroup'
              ? SET_ASSESSMENT_CLASSIFICAION_STATE
              : objectName === 'assignmentGroup'
              ? SET_ASSIGNMENT_CLASSIFICAION_STATE
              : objectName === 'associateGroup'
              ? SET_ASSOCIATE_CLASSIFICAION_STATE
              : objectName === 'cultureProfileGroup'
              ? SET_CULTUREPROFILE_CLASSIFICAION_STATE
              : objectName === 'jobProfileGroup'
              ? SET_JOBPROFILE_CLASSIFICAION_STATE
              : objectName === 'itemGroup'
              ? SET_ITEM_CLASSIFICAION_STATE
              : null
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={reviewMode === 'revise' && responseObject.associateId !== signedAssociateId}
        />
      )}
      {isPopUpValue === 'CANCELPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'cancel'}
          headerOneBadgeOne={''}
          mode={'cancel'}
          onClickYes={onClickCancelYes}
        />
      )}
      {isPopUpValue === 'CONFIRMATIONPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'group'}
          headerOneBadgeTwo={'create'}
          onClickYes={onClickYes}
          onClickNoFun={onClickCancelYes}
        />
      )}
      {isPopUpValue === 'TAGREADONLYPRIMARYPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tag'}
          labelBadgeOne={'primary'}
          actualLableValue={objectName + 'TagPrimary'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'group'}
          headerOneBadgeTwo={'information'}
          basicInfo={responseObject?.informationEngagement[objectName + 'Tag']}
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={''}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TENURESATRTDATEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tenure'}
          labelBadgeOne={'start'}
          actualLableValue={objectName + 'TenureDateTimeStart'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'group'}
          headerOneBadgeTwo={'information'}
          //basicInfo={tenureStartDate}
          basicInfo={responseObject?.informationEngagement[objectName + 'Tenure']}
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={''}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TENUREENDDATEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tenure'}
          labelBadgeOne={'end'}
          actualLableValue={objectName + 'TenureDateTimeEnd'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'group'}
          headerOneBadgeTwo={'information'}
          basicInfo={responseObject?.informationEngagement[objectName + 'Tenure']}
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={''}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'STATUSPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={objectName + 'Status'}
          label={'status'}
          listSelect={[
            { id: 'ACTIVE', name: 'Active' },
            { id: 'ARCHIVED', name: 'Archived' },
            { id: 'CONFIRMED', name: 'Confirmed' },
            { id: 'SUSPENDED', name: 'Suspended' },
            { id: 'TERMINATED', name: 'Terminated' },
            { id: 'UNVERIFIED', name: 'Unverified' }
          ]}
          mappingValue={'id'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'group'}
          headerOneBadgeTwo={'information'}
          isRequired={true}
          basicInfo={responseObject?.informationEngagement}
          nextPopUpValue={''}
          typeOfSetObject={UPDATE_ASSESSEE_PERSONAL_INFO}
          handleNextPopupValue={() => {}}
          isNotRevised={true}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TAGSECONDARYPOPUP' && (
        <PopUpTagSecondary
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'group'}
          headerOneBadgeTwo={'information'}
          tagSecondary={reducerObeject?.informationEngagement || {}}
          signInSetup={reducerObeject?.informationSetup || {}}
          nextPopUpValue={'CONFIRMATIONPOPUP'}
          typeOfSetObject={UPDATE_ASSESSEE_ENGAGEMENT_INFO}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={reviewMode === 'revise' && responseObject.associateId !== signedAssociateId}
        />
      )}
      {isPopUpValue === 'GROUPSASSOCIATEMOVEFEEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'fee'}
          tag={objectName + 'MoveFee'}
          headerPanelColour={'genericOne'}
          inputHeader={headerOne? headerOne:'associates'}
          inputHeaderBadgeOne={'groups'}
          inputHeaderBadgeTwo={'move'}
          type={'number'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          isRequired={false}
          actualLableValue={objectName + 'MoveFee'}
          basicInfo={responseObject.informationSetup}
          typeOfSetObject={SET_GROUPS_MOVE_FEE_STATE}
          objectName={objectName}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'GROUPSASSOCIATESELLFEEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'fee'}
          tag={objectName + 'SellFee'}
          headerPanelColour={'genericOne'}
          inputHeader={headerOne? headerOne: 'associates'}
          inputHeaderBadgeOne={'groups'}
          inputHeaderBadgeTwo={'sell'}
          type={'number'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          isRequired={false}
          actualLableValue={objectName + 'SellFee'}
          basicInfo={responseObject.informationSetup}
          typeOfSetObject={SET_GROUPS_SELL_FEE_STATE}
          objectName={objectName}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'GROUPSASSOCIATESHAREFEEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'fee'}
          tag={objectName + 'ShareFee'}
          headerPanelColour={'genericOne'}
          inputHeader={headerOne ? headerOne : 'associate'}
          inputHeaderBadgeOne={'group'}
          inputHeaderBadgeTwo={'share'}
          type={'number'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          isRequired={false}
          actualLableValue={objectName + 'ShareFee'}
          basicInfo={responseObject.informationSetup}
          typeOfSetObject={SET_GROUPS_SHARE_FEE_STATE}
          objectName={objectName}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {/* {isPopUpValue === 'SHAREGROUPFEEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'fee'}
          tag={objectName + 'ShareFee'}
          headerPanelColour={'genericOne'}
          inputHeader={'associates'}
          inputHeaderBadgeOne={'groups'}
          inputHeaderBadgeTwo={'share'}
          type={'number'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          isRequired={false}
          actualLableValue={objectName + 'ShareFee'}
          basicInfo={selectedTagInformationSetup}
          typeOfSetObject={SET_GROUPS_SHARE_FEE_STATE}
          objectName={objectName}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          onClickYes={onClickShareFee}
        />
      )} */}
    </div>
  );
};

export default PopUpGroupCreate;
