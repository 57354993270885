import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CLEAR_DISPLAY_PANE_THREE,
  FILTERMODE,
  FILTERMODE_ENABLE,
  GET_ALLOCATE_ASSOCIATE,
  GET_ASSOCIATEGROUP_ASSOCIATE_REVIEW_LIST_SAGA,
  POPUP_OPEN,
  SET_ASSESSEE_GROUP_ASSESSEE_ID_LIST,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MIDDLEPANE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_PAGE_COUNT,
  SET_POPUP_STATE,
  SET_UNSELECTED_ASSESSEE_GROUP_ASSESSEE_ID_LIST
} from '../actionType';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import { FilterList } from '@material-ui/icons';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import { ASSOCIATE_REVIEW_LIST_POPUP_OPTION } from '../PopUpConfig';
import Card from '../Molecules/Card/Card';
import CrossIcon from '@material-ui/icons/Clear';
import {
  getAssesseeGroupAssesseeDistinctApiCall,
  onClickCheckBoxSelection
} from '../Actions/AssesseeModuleAction';
import { assesseeStatus } from '../Actions/StatusAction';
import ReviseIcon from '@material-ui/icons/RadioButtonChecked';
import Check from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { associateGropuReviseApicall, getAssociateGroupAssociateDistinctApiCall } from '../Actions/AssociateModuleAction';
import { closeRelatedList, getPopUpOptionList } from '../Actions/GenericActions';
import ListLoader from '../Atoms/ListLoader/ListLoader';

const AssociateGroupAssociateDistinctReviewList = (props) => {
  const dispatch = useDispatch();
  const [isShowReviseIcon, setIsShowReviseIcon] = useState(true);
  const { originPrimaryArrOprion } = useSelector((state) => state.PopUpReducer);
  const {
    middlePaneSelectedValue,
    reviewListDistinctData,
    selectedAssociateInfo,
    relatedReviewListDistinctData,
    middlePaneHeaderBadgeOne,
    middlePaneHeaderBadgeTwo,
    middlePaneHeaderDuplicate,
    middlePaneHeaderDuplicateBadgeOne,
    middlePaneHeaderDuplicateBadgeTwo,
    middlePaneHeaderDuplicateBadgeThree,
    middlePaneHeaderDuplicateBadgeFour,
    middlePaneHeaderDuplicateOne,
    middlePaneHeaderDuplicateOneBadgeOne,
    middlePaneHeaderDuplicateOneBadgeTwo,
    middlePaneHeaderDuplicateOneBadgeThree,
    middlePaneHeaderDuplicateOneBadgeFour,
    typeOfMiddlePaneList,
    isSelectActive,
    selectedTagsArray,
    unselectedTagsArray,
    middlePaneHeader,
    isMiddlePaneList,
    numberPage,
    scanCount,
    duplicateScanCount,
    signedAssesseePermission,//////////////
    relatedReviewListObjectCopy,
    countPage,
    isListLoading
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { FilterModeEnable, FilterMode } = useSelector((state) => state.FilterReducer);
  const { reviewMode, createMode, headerOneBadgeOne } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  const { associateGroup } = useSelector((state) => state.GroupCreateReducer);
  const onClickRevise = () => {
    console.log('ON CLICK REVISE ICON');
    setIsShowReviseIcon(false);
  };
  const onClickReviseCancel = () => {
    if (isListLoading) {
      return
    }
    console.log('ON CLICK cancel ICON');
    setIsShowReviseIcon(true);
    closeRelatedList(
      dispatch,
      createMode,
      responseObject,
      middlePaneHeaderDuplicate,
      middlePaneHeaderDuplicateBadgeOne,
      middlePaneHeaderDuplicateBadgeTwo,
      middlePaneHeaderDuplicateBadgeThree,
      middlePaneHeaderDuplicateBadgeFour,
      'associatesGroupDistinctReviewList',
      duplicateScanCount,
      'associateGroupDistinct',
      duplicateScanCount,
      middlePaneHeaderDuplicateOne,
      middlePaneHeaderDuplicateOneBadgeOne,
      middlePaneHeaderDuplicateOneBadgeTwo,
      middlePaneHeaderDuplicateOneBadgeThree,
      middlePaneHeaderDuplicateOneBadgeFour,
    );
    dispatch({type:SET_PAGE_COUNT,payload:Math.round(parseInt(reviewListDistinctData.length)/countPage)-1})
    if (headerOneBadgeOne !== 'group') {
      dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
    }
  };
  const onClickReviseFinish = () => {
    console.log('ON CLICK finish ICON', selectedTagsArray, unselectedTagsArray);
    setIsShowReviseIcon(true);
    //if (typeOfMiddlePaneList !== ''
    dispatch({
      type: FILTERMODE,
      payload: { FilterMode: '' }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'isSelectActive', value: '' }
    });
    dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
    dispatch({ type: SET_ASSESSEE_GROUP_ASSESSEE_ID_LIST, payload: selectedTagsArray });
    dispatch({
      type: SET_UNSELECTED_ASSESSEE_GROUP_ASSESSEE_ID_LIST,
      payload: unselectedTagsArray
    });
    associateGropuReviseApicall(
      responseObject,
      selectedAssociateInfo,
      selectedTagsArray,
      unselectedTagsArray,
      associateGroup,
      dispatch,
      createMode,
      true
    );
    closeRelatedList(
      dispatch,
      createMode,
      responseObject,
      middlePaneHeaderDuplicate,
      middlePaneHeaderDuplicateBadgeOne,
      middlePaneHeaderDuplicateBadgeTwo,
      middlePaneHeaderDuplicateBadgeThree,
      middlePaneHeaderDuplicateBadgeFour,
      'associatesGroupDistinctReviewList',
      duplicateScanCount,
      'associateGroupDistinct',
      duplicateScanCount,
      middlePaneHeaderDuplicateOne,
      middlePaneHeaderDuplicateOneBadgeOne,
      middlePaneHeaderDuplicateOneBadgeTwo,
      middlePaneHeaderDuplicateOneBadgeThree,
      middlePaneHeaderDuplicateOneBadgeFour,
    );
  };
  const revisePrimaryIcon = [{ label: 'revise', onClick: onClickRevise, Icon: ReviseIcon }];

  const reviseSecondaryIcons = [
    { label: 'cancel', onClick: onClickReviseCancel, Icon: ClearIcon },
    { label: 'finish', onClick: onClickReviseFinish, Icon: Check }
  ];
  const listDistinctData = relatedReviewListDistinctData[0];

  const siftApiCall = (siftKey) => {
    getAssociateGroupAssociateDistinctApiCall(
      selectedAssociateInfo,
      siftKey,
      countPage,
      dispatch,
      middlePaneHeaderBadgeOne,
      listDistinctData.id
    );
    // document.getElementById('middleComponentId').scrollTop = '0px';
  };
  const onClickFooter = (e) => {
    let siftValue = e.currentTarget.getAttribute('data-value');
    if (
      siftValue === 'suspended' ||
      siftValue === 'terminated' ||
      siftValue === 'disapproved' ||
      siftValue === 'unapproved' ||
      siftValue === 'unconfirmed'
    )
      siftApiCall(siftValue);
    dispatch({ type: FILTERMODE_ENABLE });
  };
  /* for middle pane */
  const primaryIcon = [{ label: 'sift', onClick: onClickFooter, Icon: FilterList }];
  const secondaryIcon = [
    { label: 'disapproved', onClick: onClickFooter, Icon: FilterList },
    { label: 'suspended', onClick: onClickFooter, Icon: FilterList },
    { label: 'terminated', onClick: onClickFooter, Icon: FilterList },
    { label: 'unapproved', onClick: onClickFooter, Icon: FilterList },
    { label: 'unconfirmed', onClick: onClickFooter, Icon: FilterList }
  ];

  const openListPopup = (e) => {
    console.log(e.currentTarget.getAttribute('tag'));
    let tempArr = [];
    let stats = e.currentTarget.getAttribute('status');
    let associateDistinctPermission =
      signedAssesseePermission?.associateDistinctPermission?.assesseeAssociateDistinctPermission;
    let popupContentArrValue = getPopUpOptionList(
      ASSOCIATE_REVIEW_LIST_POPUP_OPTION,
      associateDistinctPermission
    );
    popupContentArrValue.forEach((element) => {
      if (stats === 'UNCONFIRMED' && element.data === 'share') {
        tempArr.push({ ...element, disabled: true });
      } else if (
        (stats === 'ARCHIVED' || stats === 'TERMINATED' || stats === 'SUSPENDED') &&
        (element.data === 'allocate' ||
          element.data === 'revise' ||
          element.data === 'select' ||
          element.data === 'share')
      ) {
        tempArr.push({ ...element, disabled: true });
      } else tempArr.push(element);
    });
    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: 'associate',
       popupHeaderOneBadgeOne: middlePaneHeaderBadgeOne,
        isPopUpValue: '',
        popupOpenType: 'primary',
        popupContentArrValue: tempArr,
        originPrimaryArrOprion: originPrimaryArrOprion,
        selectedTagValue: e.currentTarget.getAttribute('tag'),
        selectedTagStatus: e.currentTarget.getAttribute('status')
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: tempArr
      }
    });
  };

  // this code for assessment type list
  const { responseObject } = useSelector((state) => state.DisplayPaneThreeReducer);
  // const [isFetching, setIsFetching] = useState(false);

  // useEffect(() => {
  //   document.getElementById('middleComponentId').addEventListener('scroll', handleScroll);
  // }, []);
  // const handleScroll = (event) => {
  //   var targetPt = event.target;
  //   if (
  //     Math.ceil(targetPt.scrollHeight - targetPt.scrollTop) !== targetPt.clientHeight ||
  //     isFetching
  //   )
  //     return;
  //   setIsFetching(true);
  //   console.log(isFetching);
  // };
  const fetchData = async () => {
    if (relatedReviewListDistinctData.length > 0) {
      if (relatedReviewListDistinctData[0].associate.length < scanCount) {
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'isListLoading', value: true }
        })
        let obj = {
          ...relatedReviewListObjectCopy.requestObject,
          numberPage: numberPage + 1
        };
        if (reviewMode === 'revise') {
          dispatch({
            type: GET_ALLOCATE_ASSOCIATE,
            payload: {
              existingAssesseeId: selectedTagsArray,
              unselectedTagsArray,
              request: {
                module: 'Associate-Distinct',
                action: 'Review-List',
                requestObject: obj
              },
              HeaderOne: middlePaneHeader,
              BadgeOne: 'distinct',
              // BadgeTwo:
              //   FilterMode === 'associateGroupAssociateRevise' ? 'all' : middlePaneHeaderBadgeTwo,
              BadgeTwo: middlePaneHeaderBadgeTwo,
              isMiddlePaneList: true,
              typeOfMiddlePaneList: 'associatesGroupAssociateReviewList',
              revisedGroupObject: {
                id: responseObject.id,
                associateGroupName: responseObject.informationBasic.associateGroupName,
                associateGroupDescription:
                  responseObject.informationBasic.associateGroupDescription,
                associateGroupStatus: responseObject.informationEngagement.associateGroupStatus
              }
            }
          });
        } else {
          dispatch({
            type: GET_ASSOCIATEGROUP_ASSOCIATE_REVIEW_LIST_SAGA,
            payload: {
              request: {
                module: 'Associate-Group',
                action: 'Review-List',
                actionFilter: 'Associate-Group-Associate',
                requestObject: obj
              },
              HeaderOne: 'assessees',
              BadgeOne: middlePaneHeaderBadgeOne,
              BadgeTwo: middlePaneHeaderBadgeTwo,
              BadgeThree: '',
              isMiddlePaneList: isMiddlePaneList
            }
          });
        }
        dispatch({ type: SET_PAGE_COUNT, payload: numberPage + 1 });
      }
    }
  };

  // useEffect(() => {
  //   if (!isFetching) return;
  //   fetchMoreListItems();
  // }, [isFetching]);

  // const fetchMoreListItems = () => {
  //   fetchData();
  //   setIsFetching(false);
  // };

  // this code for assessment type list

  // infinite scroll
  const [hasMore, setHasMore] = useState(true);
  let elementRef = useRef(null);

  const onIntersection = (entries) => {
    const firstEntry = entries[0];
    relatedReviewListDistinctData[0].associate.length >= scanCount ? setHasMore(false) : setHasMore(true)
    if (firstEntry.isIntersecting && hasMore && !isListLoading) {
      fetchData();
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(onIntersection, { threshold: 0.90 });

    if (observer && elementRef.current) {
      // console.log('here at observer...', elementRef.current)
      observer.observe(elementRef.current);
    }

    return () => {
      if (observer) observer.disconnect();
    };
  }, [isListLoading, hasMore, relatedReviewListDistinctData, selectedTagsArray, unselectedTagsArray, isSelectActive]);

  // infinite scroll end
  return (
    <div>
      {listDistinctData && (
        <Card
          textOneOne={listDistinctData.associateGroupName}
          textTwoOne={listDistinctData.associateGroupDescription}
          IconOne={CrossIcon}
          isIcon={true}
          labelTwoTwo={'group'}
          onClickIconOne={onClickReviseCancel}
          isAlliance
          relatedCardFixed={true}
          className={'iguru-iconboxSVG'}
        />
      )}
      {listDistinctData &&
        listDistinctData.associate.map((item, index) => {
          return (
            <div className="containerPadding" key={index} ref={elementRef}>
              <ReviewList
                className=""
                id={index}
                tag={item?.informationEngagement?.associateTag?.associateTagPrimary}
                isSelectedReviewList={
                  middlePaneSelectedValue ===
                  item?.informationEngagement?.associateTag?.associateTagPrimary
                }
                status={item?.informationEngagement?.associateStatus}
                // status={assesseeStatus(
                //   middlePaneHeaderBadgeTwo,
                //   item?.informationEngagement?.associateStatus
                // )}
                actualStatus={item?.informationEngagement?.associateStatus}
                textOne={item.informationBasic.associateName}
                textTwo={item.informationBasic.associateDescription}
                isTooltipActive={false}
                onClickEvent={openListPopup}
                isSelectActive={isSelectActive}
                isSelected={selectedTagsArray.includes(
                  item?.informationEngagement?.associateTag?.associateTagPrimary
                )}
                onClickCheckBox={(event) => {
                  !isListLoading && onClickCheckBoxSelection(selectedTagsArray, unselectedTagsArray, event, dispatch);
                }}
              />
            </div>
          );
        })}
      {isListLoading ? <div> <ListLoader /> </div> : <div style={{ height: "60px", width: "100%" }}></div>}
      {FilterMode === 'associateGroupAssociateRevise' && (
        <FooterIconTwo
          FilterModeEnable={isShowReviseIcon}
          FilterMode={FilterMode}
          onClick={onClickRevise}
          primaryIcon={revisePrimaryIcon}
          secondaryIcon={reviseSecondaryIcons}
        />
      )}
      {FilterMode === 'associateGroupAssociateDistinctinactive' && (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={primaryIcon}
          secondaryIcon={secondaryIcon}
        />
      )}
    </div>
  );
};
export default AssociateGroupAssociateDistinctReviewList;
