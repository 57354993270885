import React, { useEffect, useState } from 'react';
import ReviewList from '../ReviewList/ReviewList';
import { FormControl, InputLabel } from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import './DisplayPanelAccordian.css';
import { useSelector } from 'react-redux';
const DisplayPanelAccordianReviewListOne = (props) => {
  const {
    accordianObject,
    mode = '',
    onClickRevise,
    onClickReview = null,
    getReviewList = null
  } = props;
  const { responseObject, assignmentRelatedReviewListPaneThree } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  const {
    labelTextOneOne = '',
    innerInfo = 'No Information',
    labelTextOneOneBadges,
    isReviewLink = false
  } = accordianObject;
  const [isListSelectExpanded, setIsListSelectExpanded] = useState(false);
  const [selectedBadge, setSelectedBadge] = useState('');
  const reviewLabelClass = isReviewLink ? 'reviewLinkText' : '';
  useEffect(() => {
    if (
      labelTextOneOneBadges[0].labelTextOneOneBadge === 'primary' ||
      labelTextOneOneBadges[0].labelTextOneOneBadge === ''
    ) {
      setSelectedBadge(labelTextOneOneBadges[0]);
    }
    else{
      setIsListSelectExpanded(false)
      setSelectedBadge('')
    }
  }, [responseObject]);

  useEffect(() => {
    if (
      labelTextOneOneBadges[0].labelTextOneOneBadge === 'primary' ||
      labelTextOneOneBadges[0].labelTextOneOneBadge === ''
    ) {
      setSelectedBadge(labelTextOneOneBadges[0]);
    } else {
      console.log('selectedBadge',selectedBadge);
      setSelectedBadge(selectedBadge?.labelTextOneOneBadge);
      setIsListSelectExpanded(false);
    }
  }, [assignmentRelatedReviewListPaneThree]);
  return (
    <>
      <div className={'detailsContactContainer'} style={{paddingTop:isListSelectExpanded?'2.5px':''}}>
        <div className={'detsailsPadding'}>
          <div className={['FormBox', 'detailsHeight'].join(' ')}>
            <FormControl className={['formControlReviewName', 'formControlRight'].join(' ')}>
              <InputLabel
                htmlFor="name-input"
                className={[
                  'textForLabel',
                  'textForLabelRight',
                  'careerLabelRight',
                  'careerLabelRighttransform'
                ].join(' ')}
              >
                <span
                  onClick={mode === 'revise' ? onClickRevise : onClickReview}
                  data-value={labelTextOneOne}
                  data-key={selectedBadge?.labelTextOneOneBadge || ''}
                  className={mode === 'revise' ? 'linkText' : reviewLabelClass}
                >
                  {labelTextOneOne}
                </span>
                {labelTextOneOneBadges.map((ob, key) => {
                  return (
                    <React.Fragment key={key}>
                      {ob.labelTextOneOneBadge !== '' && (
                        <sup
                          key={`badge-${key}`}
                          style={{
                            backgroundColor:
                              selectedBadge &&
                              selectedBadge.labelTextOneOneBadge === ob.labelTextOneOneBadge
                                ? '#F2F2F2'
                                : '#ffffff'
                          }}
                          onClick={() => {
                            setSelectedBadge(ob);
                          }}
                        >
                          {ob.labelTextOneOneBadge}
                        </sup>
                      )}
                    </React.Fragment>
                  );
                })}
              </InputLabel>
            </FormControl>
            <div className={'unitFlex'}></div>
            <div
              onClick={() => {
                if (selectedBadge) {
                  if (!isListSelectExpanded && getReviewList) {
                    getReviewList(labelTextOneOne, selectedBadge?.labelTextOneOneBadge);
                  }
                  setIsListSelectExpanded((state) => !state);
                }
              }}
              className={['unitFlex', 'careerLabelRight', 'showLessMoreList'].join(' ')}
            >
              {isListSelectExpanded ? (
                <ExpandLess className={'showLessMoreListIcon'} />
              ) : (
                <ExpandMore className={'showLessMoreListIcon'} />
              )}
            </div>
          </div>
        </div>
        {isListSelectExpanded && (
          <div>
            <>
              {labelTextOneOne === 'communiqué' || labelTextOneOne === 'manuscript' ? (
                <>
                  <div
                    style={{
                      // height: '50px',
                      padding: '2.5px 5px',
                      alignItems: 'center'
                      // display: 'flex'
                    }}
                    dangerouslySetInnerHTML={{ __html: selectedBadge.innerList }}
                  ></div>
                </>
              ) : (
                <>
                  {selectedBadge && selectedBadge?.innerList?.length > 0 ? (
                    <>
                      {selectedBadge &&
                        selectedBadge.innerList.map((associate, key) => {
                          return (
                            <div style={{ padding: '2.5px 0' }} key={key}>
                              <ReviewList
                                className=""
                                id={associate.id}
                                status={associate.status}
                                textOne={associate.textOne}
                                textTwo={associate.textTwo}
                                associateId={associate.associateId}
                                isInformation={associate?.isInformation}
                                isInformationValue={associate?.isInformationValue}
                                isShared={associate?.isShared}
                                isListIconActive={associate?.isListIconActive}                                
                                onClickListIcon={associate?.onClickListIcon}                                
                              />
                            </div>
                          );
                        })}
                    </>
                  ) : (
                    <div
                      style={{
                        height: '50px',
                        padding: '2.5px 5px',
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                      {innerInfo}
                    </div>
                  )}
                </>
              )}
            </>
          </div>
        )}
      </div>
    </>
  );
};

export default DisplayPanelAccordianReviewListOne;
