import { put, takeLatest, call } from 'redux-saga/effects';
import {
  CREATE_ASSESSEE_ROLE_SAGA,
  LOADER_STOP,
  POPUP_CLOSE,
  SET_ASSESSEE_ROLE_CLASSIFICAION_STATE,
  SET_ASSESSEE_ROLE_REDUCER_STATE,
  SET_DISPLAY_PANE_THREE_STATE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_POPUP_SINGLE_STATE,
  SET_POPUP_VALUE
} from '../../actionType';
import { ASSESSEE_MODULE_URL } from '../../endpoints';
import { EXCEPTION_ERROR_MESSAGE, INFORMATION_EXIST_ERROR_MESSAGE } from '../../errorMessage';

const createAssesseeRoleApi = async (requestObj) => {
  console.log(requestObj.data);
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      Authorization: localStorage.getItem('token')
    }),
    body: JSON.stringify(requestObj.data)
  };
  const response = await fetch(ASSESSEE_MODULE_URL, requestOptions);
  const json = await response.json();
  return json;
};

function* workerCreateAssesseeRoleSaga(data) {
  try {
    const userResponse = yield call(createAssesseeRoleApi, { data: data.payload.request });
    console.log('loading end', data);
    const { headerOne = 'assessees' } = data.payload;
    if (userResponse.responseCode === '000') {
      yield put({
        type: SET_DISPLAY_PANE_THREE_STATE,
        payload: {
          headerOne: headerOne,
          headerOneBadgeOne: 'role',
          headerOneBadgeTwo: 'information',
          headerOneBadgeThree: 'key',
          responseObject: userResponse.responseObject[0],
          reviewMode: 'revise',
          createMode: 'assesseesRole'
        }
      });
      yield put({
        type: SET_ASSESSEE_ROLE_REDUCER_STATE,
        payload: userResponse.responseObject[0].informationBasic
      });
      yield put({
        type: SET_ASSESSEE_ROLE_CLASSIFICAION_STATE,
        payload: userResponse.responseObject[0].informationSetup.assesseeRoleClassification
      });

      yield put({ type: POPUP_CLOSE });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: {
          stateName: 'middlePaneListPopupOptions',
          value: data.payload.popupContentArr
        }
      });
      yield put({
        type: SET_POPUP_SINGLE_STATE,
        payload: {
          stateName: 'selectedTagStatus',
          value: userResponse.responseObject[0].informationEngagement.assesseeRoleStatus
        }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'middlePaneSelectedValue', value: userResponse.responseObject[0].id }
      });
      yield put({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'selectedTagValue', value: userResponse.responseObject[0].id }
      });
    } else if (userResponse.responseCode === '514') {
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'NAMEPOPUP',
          popupMode: `assesseesROLECREATE`,
          popupErrorMsg: INFORMATION_EXIST_ERROR_MESSAGE
        }
      });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    yield put({ type: LOADER_STOP });
    yield put({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}

export default function* watchcreateAssesseeRoleSaga() {
  yield takeLatest(CREATE_ASSESSEE_ROLE_SAGA, workerCreateAssesseeRoleSaga);
}
