import { put, takeLatest, call } from 'redux-saga/effects';
import {
  LOADER_STOP,
  SET_POPUP_VALUE,
  POPUP_CLOSE,
  SET_REQUEST_OBJECT,
  GET_ASSESSEE_ROLE_REVIEW_LIST_SAGA,
  GET_ASSOCIATE_ROLE_REVIEW_LIST_SAGA,
  GET_ASSESSEE_TYPE_REVIEW_LIST_SAGA,
  GET_ASSOCIATE_TYPE_REVIEW_LIST_SAGA,
  GET_ITEM_TYPE_REVIEW_LIST_SAGA,
  GET_ASSESSMENT_TYPE_REVIEW_LIST_SAGA,
  GET_ASSIGNMENT_TYPE_REVIEW_LIST_SAGA,
  GET_CULTUREPROFILE_TYPE_REVIEW_LIST_SAGA,
  GET_JOBPROFILE_TYPE_REVIEW_LIST_SAGA,
  GET_ASSESSEE_GROUP_REVIEW_LIST_SAGA,
  GET_ASSOCIATE_GROUP_REVIEW_LIST_SAGA,
  GET_ITEM_GROUP_REVIEW_LIST_SAGA,
  GET_JOBPROFILE_GROUP_REVIEW_LIST_SAGA,
  GET_CULTUREPROFILE_GROUP_REVIEW_LIST_SAGA,
  GET_ASSIGNMENT_GROUP_REVIEW_LIST_SAGA,
  GET_ASSESSMENT_GROUP_REVIEW_LIST_SAGA,
  ASSOCIATE_DISTINCT_SHARE_SAGA,
  SET_POPUP_SINGLE_STATE,
  ASSESSEE_REVIEW_DISTINCT_SAGA,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MIDDLEPANE_STATE
} from '../../actionType';
import {
  ASSIGNMENT_MODULE_URL,
  ASSESSMENT_MODULE_URL,
  ITEM_MODULE_URL,
  CULTURE_MODULE_URL,
  JOB_MODULE_URL,
  ASSOCIATE_MODULE_URL,
  ASSESSEE_MODULE_URL
} from '../../endpoints';
import { EXCEPTION_ERROR_MESSAGE } from '../../errorMessage';
import Store from '../../store';

const distinctSharedApiCall = async (requestObj) => {
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      Authorization:
        ((requestObj.shareValue === 'cultureProfileGroup' ||
          requestObj.shareValue === 'jobProfileGroup' ||
          requestObj.shareValue === 'cultureProfileType' ||
          requestObj.shareValue === 'jobProfileType') &&
          localStorage.getItem('idToken')) ||
        localStorage.getItem('token')
    }),
    body: JSON.stringify(requestObj.data)
  };
  const response = await fetch(requestObj.URL, requestOptions);
  const json = await response.json();
  return json;
};

function* workerAssociateDistinctShareSaga(data) {
  console.log('data------', data);
  try {
    let APIURL = '';
    if (data.payload.shareValue === 'assessmentType') {
      APIURL = ASSESSMENT_MODULE_URL;
    }
    if (data.payload.shareValue === 'assesseeRole') {
      APIURL = ASSESSEE_MODULE_URL;
    }
    if (data.payload.shareValue === 'assesseeGroup') {
      APIURL = ASSESSEE_MODULE_URL;
    }
    if (data.payload.shareValue === 'associateGroup') {
      APIURL = ASSOCIATE_MODULE_URL;
    }
    if (data.payload.shareValue === 'itemGroup') {
      APIURL = ITEM_MODULE_URL;
    }
    if (data.payload.shareValue === 'assessmentGroup') {
      APIURL = ASSESSMENT_MODULE_URL;
    }
    if (data.payload.shareValue === 'assignmentGroup') {
      APIURL = ASSIGNMENT_MODULE_URL;
    }
    if (data.payload.shareValue === 'cultureProfileGroup') {
      APIURL = CULTURE_MODULE_URL;
    }
    if (data.payload.shareValue === 'jobProfileGroup') {
      APIURL = JOB_MODULE_URL;
    }
    if (data.payload.shareValue === 'associateRole') {
      APIURL = ASSOCIATE_MODULE_URL;
    }
    if (data.payload.shareValue === 'assesseeType') {
      APIURL = ASSESSEE_MODULE_URL;
    }
    if (data.payload.shareValue === 'associateType') {
      APIURL = ASSOCIATE_MODULE_URL;
    }
    if (data.payload.shareValue === 'itemType') {
      APIURL = ITEM_MODULE_URL;
    }
    if (data.payload.shareValue === 'assignmentType') {
      APIURL = ASSIGNMENT_MODULE_URL;
    }
    if (data.payload.shareValue === 'cultureProfileType') {
      APIURL = CULTURE_MODULE_URL;
    }
    if (data.payload.shareValue === 'jobProfileType') {
      APIURL = JOB_MODULE_URL;
    }
    console.log('APIURL---', APIURL);
    const userResponse = yield call(distinctSharedApiCall, {
      data: data.payload.request,
      URL: APIURL,
      shareValue: data.payload.shareValue
    });
    if (userResponse.responseCode === '000') {
      if (Store.getState().DisplayPaneThreeReducer.createMode === '') {
        yield put({
          type: SET_REQUEST_OBJECT,
          payload: Store.getState().DisplayPaneTwoReducer.reviewListReqObj
        });
        yield put({
          type: SET_POPUP_SINGLE_STATE,
          payload: {
            stateName: 'selectionValue',
            value: ''
          }
        });
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'allocatedTagsArray', value: [] }
        });
        yield put({
          type:
            data.payload.shareValue === 'assessmentType'
              ? GET_ASSESSMENT_TYPE_REVIEW_LIST_SAGA
              : data.payload.shareValue === 'assesseeRole'
              ? GET_ASSESSEE_ROLE_REVIEW_LIST_SAGA
              : data.payload.shareValue === 'associateRole'
              ? GET_ASSOCIATE_ROLE_REVIEW_LIST_SAGA
              : data.payload.shareValue === 'assesseeType'
              ? GET_ASSESSEE_TYPE_REVIEW_LIST_SAGA
              : data.payload.shareValue === 'associateType'
              ? GET_ASSOCIATE_TYPE_REVIEW_LIST_SAGA
              : data.payload.shareValue === 'itemType'
              ? GET_ITEM_TYPE_REVIEW_LIST_SAGA
              : data.payload.shareValue === 'assignmentType'
              ? GET_ASSIGNMENT_TYPE_REVIEW_LIST_SAGA
              : data.payload.shareValue === 'cultureProfileType'
              ? GET_CULTUREPROFILE_TYPE_REVIEW_LIST_SAGA
              : data.payload.shareValue === 'jobProfileType'
              ? GET_JOBPROFILE_TYPE_REVIEW_LIST_SAGA
              : data.payload.shareValue === 'assesseeGroup'
              ? GET_ASSESSEE_GROUP_REVIEW_LIST_SAGA
              : data.payload.shareValue === 'associateGroup'
              ? GET_ASSOCIATE_GROUP_REVIEW_LIST_SAGA
              : data.payload.shareValue === 'itemGroup'
              ? GET_ITEM_GROUP_REVIEW_LIST_SAGA
              : data.payload.shareValue === 'assessmentGroup'
              ? GET_ASSESSMENT_GROUP_REVIEW_LIST_SAGA
              : data.payload.shareValue === 'assignmentGroup'
              ? GET_ASSIGNMENT_GROUP_REVIEW_LIST_SAGA
              : data.payload.shareValue === 'cultureProfileGroup'
              ? GET_CULTUREPROFILE_GROUP_REVIEW_LIST_SAGA
              : data.payload.shareValue === 'jobProfileGroup'
              ? GET_JOBPROFILE_GROUP_REVIEW_LIST_SAGA
              : null,
          payload: {
            request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
            isMiddlePaneList: true
          }
        });
        console.log('payload-----', Store.getState().DisplayPaneTwoReducer.reviewListReqObj);
      } else {
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: '',
            middlePaneHeaderBadgeOne: '',
            middlePaneHeaderBadgeTwo: '',
            middlePaneHeaderBadgeThree: '',
            middlePaneHeaderBadgeFour: '',
            typeOfMiddlePaneList: '',
            showMiddlePaneState: false
          }
        });
      }
      yield put({ type: POPUP_CLOSE });
    } else {
      console.log('data.payload.shareValue', data.payload.shareValue);
      // debugger;
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
export default function* watchAssociateDistinctShare() {
  yield takeLatest(ASSOCIATE_DISTINCT_SHARE_SAGA, workerAssociateDistinctShareSaga);
}
