import React, { useEffect, useState } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import Popup from '../Molecules/PopUp/PopUp';
import PopupHeader from '../Molecules/PopUp/PopUpHeader';
import FormControl from '@material-ui/core/FormControl';
import InputFeild from '../Atoms/InputField/InputField';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import '../Molecules/PopUp/PopUp.css';
import { useDispatch, useSelector } from 'react-redux';
import { POPUP_CLOSE, SET_NEXT_POPUP } from '../actionType';
import PropTypes from 'prop-types';
import { REQUIRED_ERROR_MESSAGE } from '../errorMessage';
import { FormatIndentDecrease } from '@material-ui/icons';
//import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import SelectField from '../Atoms/SelectField/SelectField';
const PopUpTextFieldFormula = (props) => {
  const dispatch = useDispatch();
  const { popupMode } = useSelector((state) => state.PopUpReducer);
  const { reviewMode } = useSelector((state) => state.DisplayPaneThreeReducer);
  const {
    headerPanelColour = '',
    headerOne = '',
    headerOneBadgeOne = '',
    headerOneBadgeTwo = '',
    isActive = false,
    isRequired = false,
    label = '',
    nextPopUpValue = '',
    basicInfo,
    typeOfSetObject,
    actualLableValue,
    labelBadgeOne = '',
    mode,
    type = 'text',
    isNotRevised = false,
    inputHeader = '',
    inputHeaderBadgeOne = '',
    inputHeaderBadgeTwo = '',
    currencySymbol = '',
    issetTime = false,
    actualLableValueVerification,
    mappingValue,
    listSelect
  } = props;
  let errorMessage = isNotRevised ? 'this information cannot be revised' : '';
  const [state, setState] = useState({
    error: errorMessage,
    isVerified: true
  });
  const [localObject, setLocalObject] = useState(basicInfo);
  const [formula, setFormula] = useState('');
  const [number, setNumber] = useState([10]);
  useEffect(() => {
    setLocalObject(basicInfo);
    setFormula(basicInfo?.assessmentScoreCalculator || '');
  }, [basicInfo]);

  const validateFun = () => {
    let isValidate = true;
    if (isRequired) {
      if (localObject) {
        if (localObject[actualLableValue] === '') {
          setState((prevState) => ({ ...prevState, error: REQUIRED_ERROR_MESSAGE }));
          return false;
        }
      }
    }
    return isValidate;
  };
  // const handleChange = (event) => {
  //     if (!isNotRevised) {
  //         const { name, value } = event.target;
  //         dispatch({ type: typeOfSetObject, payload: { ...localObject, [name]: value } });
  //         setLocalObject({ ...basicInfo, [name]: value });
  //         setState((prevState) => ({
  //             ...prevState,
  //             error: '',
  //             isVerified: false
  //         }));
  //     }
  // };

  const handleClick = () => {
    /*according to creation mode popup sequence will chanlocalObjectge*/
    if (validateFun() && !isNotRevised) {
      Object.keys(localObject).map((k) =>
        typeof localObject[k] === 'string'
          ? (localObject[k] = localObject[k]?.trim())
          : localObject[k]
      );
      //dispatch({ type: typeOfSetObject, payload: { ...localObject } });
      dispatch({ type: typeOfSetObject, payload: { ...localObject, [actualLableValue]: formula } });
      if (reviewMode === 'revise') {
        dispatch({ type: POPUP_CLOSE });
      } else {
        dispatch({ type: SET_NEXT_POPUP, payload: { isPopUpValue: nextPopUpValue } });
      }
    }
  };

  const handleButtonClick = (event) => {
    const value = event.currentTarget.value;
    if (value === 'clear') {
      setFormula('');
    } else {
      setFormula(formula + value);
    }
    // dispatch({ type: typeOfSetObject, payload: { ...localObject, [actualLableValue]: value } });
    // setLocalObject({ ...basicInfo, [actualLableValue]: value });
  };
  const handleChange = (event) => {
    const value = event.target.value;
    if (value === 'clear') {
      setFormula('');
    } else {
      setFormula(formula + value);
    }
    // dispatch({ type: typeOfSetObject, payload: { ...localObject, [actualLableValue]: value } });
    // setLocalObject({ ...basicInfo, [actualLableValue]: value });
  };
  //console.log(formula);
  return (
    <div>
      <Popup isActive={isActive}>
        <PopupHeader
          headerPanelColour={headerPanelColour}
          headerOne={headerOne}
          headerOneBadgeOne={headerOneBadgeOne}
          headerOneBadgeTwo={headerOneBadgeTwo}
          headerOneBadgeThree={''}
          onClick={handleClick}
          mode={mode}
          isNotRevised={isNotRevised}
        />
        <DialogContent
          className={['popupContent', 'fixed10PadDim', 'revisePopupContent'].join(' ')}
        >
          {inputHeader !== '' && (
            <div className={'fitContent'}>
              <div className={['PopupFormBox', 'labelPopupBox', 'popupMinHei'].join(' ')}>
                <InputLabel htmlFor="name-input" className={'textForLabelPopup'}>
                  <>
                    {inputHeader}&nbsp;
                    {inputHeaderBadgeOne ? (
                      <span className={'headerBadge'}>{inputHeaderBadgeOne}</span>
                    ) : null}
                    &nbsp;
                    {inputHeaderBadgeTwo ? (
                      <span className={'headerBadge'}>{inputHeaderBadgeTwo}</span>
                    ) : null}
                  </>
                </InputLabel>
                <div className={'infoSymbol'}></div>
                <div className={'infoSymbol'}>{/* <InfoToolTip message={infoMsg} /> */}</div>
              </div>
            </div>
          )}
          <FormControl style={{ width: '100%' }}>
            <InputFeild
              id={actualLableValue}
              label={label}
              type={type}
              //value={basicInfo && isNotRevised ? basicInfo : localObject[actualLableValue]}
              value={formula}
              //onClick={handleChange}
              errorMsg={state.error}
              labelBadgeOne={labelBadgeOne}
              currencySymbol={currencySymbol}
              issetTime={issetTime}
              isNotRevised={isNotRevised}
              classNames={''}
              isMultiLine={true}
            />
            <div>
              <Grid container spacing={{ xs: 1, md: 2 }} columns={{ xs: 3, sm: 5, md: 4 }}>
                {[...Array(10)].map((_, index) => (
                  <Grid item xs={1} sm={2} md={3} key={index}>
                    {/* <Item>{index}</Item> */}
                    <Button onClick={handleButtonClick} value={index}>
                      {index}
                    </Button>
                  </Grid>
                ))}
                <Button onClick={handleButtonClick} value="-">
                  {'-'}
                </Button>
                <Button onClick={handleButtonClick} value="(">
                  {'('}
                </Button>
                <Button onClick={handleButtonClick} value=")">
                  {')'}
                </Button>
                <Button onClick={handleButtonClick} value="*">
                  {'*'}
                </Button>
                <Button onClick={handleButtonClick} value="+">
                  {'+'}
                </Button>
                <Button onClick={handleButtonClick} value="/">
                  {'/'}
                </Button>
                <Button
                  onClick={handleButtonClick}
                  value="{assesseeAssignmentAssessmentClusterItemScoreTotal}"
                >
                  {'ClusterItemScoreTotal'}
                </Button>
                <Button
                  onClick={handleButtonClick}
                  value="{assesseeAssignmentAssessmentClusterItemTotal}"
                >
                  {'ClusterItemTotal'}
                </Button>
                <Button onClick={handleButtonClick} value="{assesseeAssignmentAssessmentScoreRaw}">
                  {'Raw-Score'}
                </Button>
                <Button onClick={handleButtonClick} value="{assessmentScoreExtremumMaximum}">
                  {'ScoreExtremumMaximum'}
                </Button>
                <Button onClick={handleButtonClick} value="{assessmentScoreZ}">
                  {'Z-Score'}
                </Button>
                <Button onClick={handleButtonClick} value="{assessmentScoreMean}">
                  {'Mean'}
                </Button>
                <Button onClick={handleButtonClick} value="{assessmentScoreStandardDeviation}">
                  {'Standard-Deviation'}
                </Button>
                <Button onClick={handleButtonClick} value="clear">
                  {'AC'}
                </Button>
              </Grid>
              {/* {
                            [...Array(10)].map((data, ind) => (
                                <div>
                                    <Button onClick={handleButtonClick} value={ind} >{ind}</Button>
                                </div>
                            ))} */}

              {/*                             
                            {[...Array(10)].map((data, ind) => (
                                <div>
                                    <Button onClick={handleButtonClick} value={ind} >{ind}</Button>
                                </div>
                            ))}
                             */}
              <SelectField
                label={label}
                listSelect={listSelect}
                onChange={handleChange}
                value={isNotRevised ? localObject : localObject && localObject[actualLableValue]}
                mappingValue={mappingValue}
                labelBadgeOne={labelBadgeOne}
                isMultiSelect={false}
              />
            </div>
          </FormControl>
        </DialogContent>
      </Popup>
    </div>
  );
};

PopUpTextFieldFormula.propTypes = {
  className: PropTypes.string,
  headerPanelColour: PropTypes.oneOf([
    'displayPaneLeft',
    'displayPaneCentre',
    'displayPaneRight',
    'genericOne',
    'genericTwo'
  ]),
  headerOne: PropTypes.string,
  headerOneBadgeOne: PropTypes.string,
  headerOneBadgeTwo: PropTypes.string,
  headerOneBadgeThree: PropTypes.string,
  isActive: PropTypes.bool
};
export default PopUpTextFieldFormula;
