import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ASSOCIATE_POPUP_CLOSE,
  ASSIGNMENT_MARKETPLACE_REVIEW_GROUP_SAGA,
  FILTERMODE_ENABLE,
  LOADER_START,
  POPUP_OPEN,
  SET_PAGE_COUNT,
  SET_POPUP_STATE,
  SET_REQUEST_OBJECT,
  SET_DISPLAY_TWO_SINGLE_STATE,
  FILTERMODE,
  ASSIGNMENT_ALLOCATE_SAGA,
  ASSESSEE_ALLOCATE_ASSIGNMENT_SAGA,
  ASSESSMENT_ALLOCATE_ASSIGNMENT_SAGA,
  CULTUREPROFILE_ALLOCATE_ASSIGNMENT_SAGA,
  JOBPROFILE_ALLOCATE_ASSIGNMENT_SAGA,
  SET_POPUP_SINGLE_STATE,
  CLEAR_DISPLAY_PANE_THREE,
  ASSESSEE_INFO_CREATE,
  SECTION_POPUP_CLOSE,
  SET_DISPLAY_PANE_THREE_STATE
} from '../actionType';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import { FilterList } from '@material-ui/icons';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import { getPopUpOptionList } from '../Actions/GenericActions';
import {
  ASSIGNMENT_GROUP_REVIEW_LIST_POPUP_OPTION,
  GROUP_REVIEW_LIST_POPUP_OPTION
} from '../PopUpConfig';
import {
  onClickCheckBoxSelection,
  setFlagedArray,
  onClickFlagSelection
} from '../Actions/AssesseeModuleAction';
import ReviseIcon from '@material-ui/icons/RadioButtonChecked';
import Check from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import FileExplorerTheme from 'react-sortable-tree-theme-full-node-drag';
import SortableTree from 'react-sortable-tree';
import { AccountTree } from '@material-ui/icons';
import ListIcon from '@material-ui/icons/FormatListBulleted';
import {
  assignmentsGroupApiCall,
  getMarketplaceAssignmentGroupApiCall
} from '../Actions/AssignmentModuleAction';
import ListLoader from '../Atoms/ListLoader/ListLoader';

const AssignmentGroupReviewList = (props) => {
  const dispatch = useDispatch();
  // const { secondaryOptionCheckValue } = useSelector((state) => state.AssesseeCreateReducer);
  const { cardValue } = useSelector((state) => state.PopUpReducer);
  const {
    numberPage,
    scanCount,
    countPage,
    reviewListDistinctData,
    reviewListReqObj,
    middlePaneSelectedValue,
    selectedAssociateInfo,
    middlePaneHeaderBadgeOne,
    middlePaneHeaderBadgeTwo,
    middlePaneHeaderBadgeThree,
    isSelectActive,
    selectedTagsArray,
    unselectedTagsArray,
    allocatedTagsArray,
    allocateStr,
    nodeViewState,
    scanString,
    searchFocusIndex,
    selectedFlagedArray,
    unselectedFlagedArray,
    flagedValue,
    signedAssesseePermission,
    assignmentCreateType,
    paneOneSelectedSection,
    typeOfMiddlePaneList,
    isListLoading
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { FilterModeEnable, FilterMode } = useSelector((state) => state.FilterReducer);
  // const [isFetching, setIsFetching] = useState(false);
  const [filterState, setFilterState] = useState('');
  // useEffect(() => {
  //   document.getElementById('middleComponentId').addEventListener('scroll', handleScroll);
  // }, []);
  // const handleScroll = (event) => {
  //   var targetPt = event.target;
  //   if (
  //     Math.ceil(targetPt.scrollHeight - targetPt.scrollTop) !== targetPt.clientHeight ||
  //     isFetching
  //   )
  //     return;
  //   setIsFetching(true);
  //   console.log(isFetching);
  // };
  const fetchData = async () => {
    if (reviewListDistinctData.length < scanCount && nodeViewState !== 'hierarchy') {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isListLoading', value: true }
      });
      let obj = {
        ...reviewListReqObj.requestObject,
        numberPage: numberPage + 1
      };
      dispatch({
        type: ASSIGNMENT_MARKETPLACE_REVIEW_GROUP_SAGA,
        payload: {
          request: {
            module: 'Assignment-Group',
            action: 'Review-List',
            actionFilter: 'Shared-Assignment-groupList',
            requestObject: obj
          },
          BadgeOne: middlePaneHeaderBadgeOne,
          BadgeTwo: middlePaneHeaderBadgeTwo,
          BadgeThree: middlePaneHeaderBadgeThree,
          isMiddlePaneList: true,
          cardValue: cardValue,
          isSelectActive: isSelectActive,
          selectedTagsArray: selectedTagsArray
        }
      });
      dispatch({ type: SET_PAGE_COUNT, payload: numberPage + 1 });
    }
  };
  // useEffect(() => {
  //   console.log(reviewListDistinctData);
  //   if (!isFetching) return;
  //   fetchMoreListItems();
  // }, [isFetching]);

  // const fetchMoreListItems = () => {
  //   fetchData();
  //   setIsFetching(false);
  // };
  useEffect(() => {
    if (nodeViewState === 'list')
      setFlagedArray(reviewListDistinctData, 'assignmentGroupFlag', dispatch);
  }, [reviewListDistinctData]);
  const siftApiCall = (siftKey, requestObect) => {
    dispatch({ type: LOADER_START });
    dispatch({
      type: FILTERMODE,
      payload: { FilterMode: 'assignmentsGroupDistinct' + siftKey }
    });
    dispatch({
      type: ASSIGNMENT_MARKETPLACE_REVIEW_GROUP_SAGA,
      payload: {
        request: requestObect,
        BadgeOne: middlePaneHeaderBadgeOne,
        BadgeTwo: middlePaneHeaderBadgeTwo === 'distinct' ? middlePaneHeaderBadgeTwo : siftKey,
        BadgeThree: middlePaneHeaderBadgeTwo === 'distinct' ? siftKey : middlePaneHeaderBadgeThree,
        isMiddlePaneList: true,
        cardValue: cardValue,
        isSelectActive: isSelectActive,
        selectedTagsArray: selectedTagsArray,
        typeOfMiddlePaneList
      }
    });
    dispatch({
      type: SET_DISPLAY_PANE_THREE_STATE,
      payload: {
        isReviewRevise: false,
        headerOne: '',
        headerOneBadgeOne: '',
        headerOneBadgeTwo: '',
        headerOneBadgeThree: '',
        reviewMode: 'review'
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'typeOfMiddlePaneList', value: '' }
    });
    dispatch({ type: ASSOCIATE_POPUP_CLOSE });
    document.getElementById('middleComponentId').scrollTop = '0px';
  };

  //resetdata function call

  const resetDataFunction = () => {
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'allocateStr', value: '' }
    });
    // document.getElementById('middleComponentId').scrollTop = '0px';
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: { stateName: 'cardValue', value: 'NoCard' }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'typeOfMiddlePaneList', value: '' }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'middlePaneSelectedValue', value: '' }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'selectedFlagedArray', value: [] }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'unselectedFlagedArray', value: [] }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'selectedTagsArray', value: [] }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'unselectedTagsArray', value: [] }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'flagedValue', value: '' }
    });
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: { stateName: 'selectionValue', value: '' }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'scanString', value: '' }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'allocatedTagsArray', value: [] }
    });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
    dispatch({ type: ASSESSEE_INFO_CREATE });
    dispatch({ type: SECTION_POPUP_CLOSE });
  };
  //

  const onClickFooter = (e) => {
    let siftValue = e.currentTarget.getAttribute('data-value');
    dispatch({ type: FILTERMODE_ENABLE });
    setFilterState(siftValue);
    if (siftValue === 'list' || siftValue === 'hierarchy') {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'scanString', value: '' }
      });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'nodeViewState', value: siftValue }
      });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'typeOfMiddlePaneList', value: '' }
      });
      // resetDataFunction();
      let badgeTwo =
        middlePaneHeaderBadgeTwo === 'generic' || middlePaneHeaderBadgeTwo === 'bespoke'
          ? 'active'
          : middlePaneHeaderBadgeTwo;

      if (cardValue === 'Card') {
        badgeTwo =
          middlePaneHeaderBadgeThree === 'generic' || middlePaneHeaderBadgeThree === 'bespoke'
            ? 'active'
            : middlePaneHeaderBadgeThree;
      }
      dispatch({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'selectionValue', value: '' }
      });
      if (paneOneSelectedSection === 'paneoneSection3') {
        getMarketplaceAssignmentGroupApiCall(
          selectedAssociateInfo,
          badgeTwo,
          countPage,
          dispatch,
          'groups',
          cardValue,
          '',
          siftValue,
          [],
          assignmentCreateType
        );
        return;
      } else {
        assignmentsGroupApiCall(
          selectedAssociateInfo,
          badgeTwo,
          countPage,
          dispatch,
          'groups',
          cardValue,
          '',
          siftValue,
          [],
          assignmentCreateType
        );
      }
    }
    if (siftValue === 'cancel') {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isSelectActive', value: '' }
      });
      dispatch({
        type: FILTERMODE,
        payload: { FilterMode: '' }
      });
    }
  };
  /* for middle pane */
  const primaryIconOne = [{ label: 'sift', onClick: onClickFooter, Icon: FilterList }];
  const secondaryIconOne = [
    { label: 'suspended', onClick: onClickFooter, Icon: FilterList },
    { label: 'terminated', onClick: onClickFooter, Icon: FilterList }
  ];
  const secondaryOneIconSift = [
    { label: 'bespoke', onClick: onClickFooter, Icon: FilterList },
    { label: 'generic', onClick: onClickFooter, Icon: FilterList }
  ];

  /* for middle pane */
  const primaryIcon = [
    // { label: 'sift', onClick: onClickFooter, Icon: FilterList },
    { label: 'view', onClick: onClickFooter, Icon: FilterList }
  ];
  const secondaryIcon = [
    { label: 'hierarchy', onClick: onClickFooter, Icon: AccountTree },
    { label: 'list', onClick: onClickFooter, Icon: ListIcon }
  ];

  let signedAssociateId =
    selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary;

  const openListPopup = (node, e, target, canUpdate) => {
    console.log(e.currentTarget.getAttribute('tag'));
    console.log('node', node);
    let tempArr = [];
    let classification = e.currentTarget.getAttribute('data-classification');
    let isShared = e.currentTarget.getAttribute('data-shared');
    let status = e.currentTarget.getAttribute('status');
    let associateId = e.currentTarget.getAttribute('associateid');
    let groupId = e.currentTarget.getAttribute('tag');
    let flaged = e.currentTarget.getAttribute('data-flag') === 'true' && true;
    if (target === 'hirarchy') {
      groupId = node.node.id;
      status = node.node.status;
      flaged = node.node.flag;
      isShared = node.node.assignmentGroupShared;
      classification = node.node.classification;
      associateId = node.node.associateId;
    }
    let assigmentDistictPermission =
      signedAssesseePermission?.assigmentDistictPermission?.assesseeAssignmentGroupPermission ||
      signedAssesseePermission?.associateSelfPermission?.assesseeAssignmentGroupPermission;
    let popupContentArrValue = getPopUpOptionList(
      cardValue === 'Card'
        ? GROUP_REVIEW_LIST_POPUP_OPTION
        : ASSIGNMENT_GROUP_REVIEW_LIST_POPUP_OPTION,
      assigmentDistictPermission
    );
    console.log(associateId);
    console.log(signedAssociateId);
    console.log(associateId !== signedAssociateId);

    popupContentArrValue.map((element) => {
      if (classification === 'Bespoke' && element.data === 'share' && status === 'ACTIVE')
        tempArr.push({ ...element, disabled: true });
      else if (
        (isShared === 'true' || isShared) &&
        associateId !== signedAssociateId &&
        (element.data === 'archive' ||
          element.data === 'delete' ||
          element.data === 'flag' ||
          element.data === 'publish' ||
          element.data === 'suspend' ||
          element.data === 'terminate')
      ) {
        tempArr.push({ ...element, disabled: true });
      } else if (
        (status === 'TERMINATED' || status === 'SUSPENDED' || status === 'ARCHIVED') &&
        (element.data === 'allocate' ||
          element.data === 'revise' ||
          element.data === 'select' ||
          element.data === 'share')
      ) {
        tempArr.push({ ...element, disabled: true });
      } else tempArr.push(element);
    });
    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: 'assignments',
        popupHeaderOneBadgeOne: 'group',
        duplicateHeaderOneBadgeTwo: middlePaneHeaderBadgeTwo,
        popupHeaderOneBadgeTwo: '',
        isPopUpValue: '',
        popupOpenType: 'primary',
        popupContentArrValue: tempArr,
        originPrimaryArrOprion: tempArr,
        selectedTagValue: groupId, //e.currentTarget.getAttribute("tag"),
        selectedTagStatus: status, //e.currentTarget.getAttribute("status"),
        isShared: isShared,
        selectedTagInformationSetup: JSON.parse(e.currentTarget.getAttribute('informationsetup')),
        selectedTagGroupId: e.currentTarget.getAttribute('data-value'),
        selectedTagShared: e.currentTarget.getAttribute('data-shared'),
        isFlaged: flaged
        //e.currentTarget.getAttribute("data-flag") === "true" ? true : false,
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: tempArr
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
  };
  console.log('isSelectActive', isSelectActive);
  const changedNode = (node) => {
    console.log(node.id);
    let dragedNodeId = node.id;
    let dragedNodeParentId = '';
    reviewListDistinctData.map((nodeData) => {
      console.log('nodeData', nodeData);
      nodeData.children.map((chnode) => {
        console.log(chnode.id);
        if (dragedNodeId === chnode.id) {
          dragedNodeParentId = nodeData.id;
        }
      });
    });
  };
  const customSearchMethod = ({ node, searchQuery }) =>
    searchQuery &&
    (node.title?.toLowerCase().indexOf(searchQuery?.toLowerCase()) > -1 ||
      node.subtitle?.toLowerCase().indexOf(searchQuery?.toLowerCase()) > -1);
  // console.log('signedAssociateId', signedAssociateId);
  // console.log('associateId', reviewListDistinctData);
  // console.log('associateId', reviewListDistinctData.associateId);

  // infinite scroll
  const [hasMore, setHasMore] = useState(true);
  let elementRef = useRef(null);

  const onIntersection = (entries) => {
    const firstEntry = entries[0];
    reviewListDistinctData.length >= scanCount ? setHasMore(false) : setHasMore(true);
    if (firstEntry.isIntersecting && hasMore && !isListLoading) {
      fetchData();
    }
  };

  useEffect(() => {
    // console.log('####MYREF######', elementRef)
    if (nodeViewState !== 'hierarchy') {
      const observer = new IntersectionObserver(onIntersection, { threshold: 0.9 });

      if (observer && elementRef.current) {
        // console.log('here at observer...', elementRef.current)
        observer.observe(elementRef.current);
      }

      return () => {
        if (observer) observer.disconnect();
      };
    }
  }, [
    isListLoading,
    hasMore,
    reviewListDistinctData,
    selectedTagsArray,
    unselectedTagsArray,
    isSelectActive
  ]);

  // infinite scroll end

  return (
    <div>
      {reviewListDistinctData && nodeViewState === 'hierarchy' ? (
        <div style={{ minheight: 'calc(100vh - 135px)' }} key={scanString}>
          <SortableTree
            treeData={reviewListDistinctData}
            onChange={(treeData) => {
              treeData.length === 1 &&
                dispatch({
                  type: SET_DISPLAY_TWO_SINGLE_STATE,
                  payload: {
                    stateName: 'reviewListDistinctData',
                    value: treeData
                  }
                });
            }}
            searchMethod={customSearchMethod}
            searchQuery={scanString}
            searchFocusOffset={searchFocusIndex}
            canDrag={({ node }) => true && node.parentId !== null}
            onMoveNode={({ node }) => changedNode(node)}
            searchFinishCallback={(matches) => {
              console.log(matches);
              dispatch({
                type: SET_DISPLAY_TWO_SINGLE_STATE,
                payload: {
                  stateName: 'searchFocusIndex',
                  value: matches.length > 0 ? searchFocusIndex % matches.length : 0
                }
              });
            }}
            theme={FileExplorerTheme}
            isVirtualized={false}
            rowHeight={55}
            scaffoldBlockPxWidth={31}
            slideRegionSize={50}
            generateNodeProps={(node) => ({
              onClick: (event) => {
                if (event.target.type !== 'button' && node.node.title) {
                  // openListPopup(node, event, 'hirarchy', true);
                  //  openListHierarchyPopup(node,event, 'hierarchy', true);
                }
              }
            })}
          />
        </div>
      ) : (
        reviewListDistinctData &&
        reviewListDistinctData.map((item, index) => {
          return (
            <div className="containerPadding" key={index} ref={elementRef}>
              <ReviewList
                className=""
                id={index}
                tag={item?.id}
                associateId={item?.associateId}
                isSelectedReviewList={middlePaneSelectedValue === item?.id}
                //status={item.informationEngagement.assignmentGroupStatus}
                status={
                  FilterMode === 'assignmentsGroupDistinctactive' ||
                  FilterMode === 'assignmentsGroupDistinctbespoke' ||
                  FilterMode === 'assignmentsGroupDistinctgeneric'
                    ? item.informationSetup?.assignmentGroupClassification
                        ?.assignmentGroupClassificationPrimary
                    : item.informationEngagement.assignmentGroupStatus
                }
                actualStatus={item.informationEngagement.assignmentGroupStatus}
                textOne={item.informationBasic.assignmentGroupName}
                textTwo={item.informationBasic.assignmentGroupDescription}
                informationSetup={item?.informationSetup}
                isTooltipActive={false}
                // onClickEvent={(event) => {
                //     openListPopup(item.id, event, 'list', true);
                // }}
                //onClickEvent={openListPopup}
                isSelectActive={isSelectActive}
                isSelected={selectedTagsArray.includes(item.id)}
                onClickCheckBox={(event) => {
                  !isListLoading &&
                    onClickCheckBoxSelection(
                      selectedTagsArray,
                      unselectedTagsArray,
                      event,
                      dispatch
                    );
                }}
                // disableonClickCheckBox={item.associateId !== signedAssociateId ? true : false}
                isShared={item?.assignmentGroupShared}
                //shared={item.assignmentGroupShared ? 'SHARED' : 'UNSHARED'}
                shared={
                  item.informationSetup?.assignmentGroupClassification
                    ?.assignmentGroupClassificationPrimary
                }
                isFlagActive={selectedFlagedArray.includes(item.id)}
                flagedValue={flagedValue}
                onClickAddFladed={(event) => {
                  onClickFlagSelection(selectedFlagedArray, unselectedFlagedArray, event, dispatch);
                }}
              />
            </div>
          );
        })
      )}
      {isListLoading ? (
        <div>
          {' '}
          <ListLoader />{' '}
        </div>
      ) : <div style={{ height: "60px", width: "100%" }}></div>}
      {(FilterMode === 'assignmentsGroupDistinctinactive' ||
        FilterMode === 'assignmentsGroupDistinctterminated' ||
        FilterMode === 'assignmentsGroupDistinctsuspended') && (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={primaryIconOne}
          secondaryIcon={secondaryIconOne}
        />
      )}
      {(FilterMode === 'assignmentsGroupDistinctactive' ||
        FilterMode === 'assignmentsGroupDistinctactivebespoke' ||
        FilterMode === 'assignmentsGroupDistinctactivegeneric' ||
        FilterMode === 'assignmentsGroupDistinctbespoke' ||
        FilterMode === 'assignmentsGroupDistinctgeneric') && (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={primaryIcon}
          secondaryIcon={filterState === 'sift' ? secondaryOneIconSift : secondaryIcon}
        />
      )}
      {/* {(FilterMode === 'assignmentAllocateToGroup' ||
        FilterMode === 'assignmentUnAllocateToGroup') && (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={[
            {
              label: `${FilterMode === 'assignmentAllocateToGroup' ? 'allocate' : 'unallocate'}`,
              onClick: onClickFooter,
              Icon: ReviseIcon
            }
          ]}
          secondaryIcon={[
            { label: 'cancel', onClick: onClickFooter, Icon: ClearIcon },
            { label: 'finish', onClick: onClickFooter, Icon: Check }
          ]}
        />
      )} */}
    </div>
  );
};
export default AssignmentGroupReviewList;
