import { put, takeLatest, call } from 'redux-saga/effects';
import {
  ASSESSEE_ROLE_INFO_REVISE_SAGA,
  CLEAR_DISPLAY_PANE_THREE,
  CLEAR_ROLE_REDUCER_STATE,
  GET_ASSESSEEROLE_ASSESSEE_REVIEW_LIST,
  GET_ASSESSEE_ROLE_REVIEW_INFO_SAGA,
  GET_ASSESSEE_ROLE_REVIEW_LIST_SAGA,
  LOADER_STOP,
  SET_ASSESSEE_ROLE_ASSESSEE_ID_LIST,
  SET_ASSESSEE_ROLE_CLASSIFICAION_STATE,
  SET_ASSESSEE_ROLE_REDUCER_STATE,
  SET_DISPLAY_PANE_THREE_STATE,
  SET_DISPLAY_THREE_SINGLE_STATE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_POPUP_SINGLE_STATE,
  SET_POPUP_VALUE,
  SET_SETUP_PERMISSION_NEW,
  SET_UNSELECTED_ASSESSEE_ROLE_ASSESSEE_ID_LIST
} from '../../actionType';
import { ASSESSEE_MODULE_URL } from '../../endpoints';
import { EXCEPTION_ERROR_MESSAGE } from '../../errorMessage';
import Store from '../../store';
const assesseeRoleInfoApi = async (requestObj) => {
  console.log(requestObj.data);
  let URL = ASSESSEE_MODULE_URL;
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      Authorization: localStorage.getItem('token')
    }),
    body: JSON.stringify(requestObj.data)
  };
  const response = await fetch(URL, requestOptions);
  const json = await response.json();
  return json;
};

function* workerReviewAssesseeRoleInfoSaga(data) {
  try {
    const userResponse = yield call(assesseeRoleInfoApi, {
      data: data.payload.reqBody
    });
    if (userResponse.responseCode === '000') {
      const { isReviseMode = false, assesseeRoleAssesseeReqBody = null, createMode, headerOne } = data.payload;
      console.log('IN ROLE REVIEW+++++', userResponse);
      if (assesseeRoleAssesseeReqBody !== null) {
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
        yield put({
          type: GET_ASSESSEEROLE_ASSESSEE_REVIEW_LIST,
          payload: {
            request: assesseeRoleAssesseeReqBody,
            HeaderOne: headerOne ? headerOne : Store.getState().DisplayPaneTwoReducer.middlePaneHeader,
            BadgeOne: '',
            BadgeTwo: '',
            BadgeThree: '',
            isMiddlePaneList: false
          }
        });
      }
      yield put({
        type: SET_DISPLAY_PANE_THREE_STATE,
        payload: {
          headerOne: headerOne ? headerOne : Store.getState().DisplayPaneTwoReducer.middlePaneHeader,
          headerOneBadgeOne: 'role',
          headerOneBadgeTwo: 'information',
          headerOneBadgeThree: 'key',
          responseObject: userResponse.responseObject[0],
          reviewMode: isReviseMode ? 'revise' : '',
          createMode: createMode
        }
      });
      if (isReviseMode) {
        yield put({ type: CLEAR_ROLE_REDUCER_STATE });
        yield put({
          type: SET_ASSESSEE_ROLE_REDUCER_STATE,
          payload: userResponse.responseObject[0].informationBasic
        });

        if (userResponse.responseObject[0].informationSetup.assesseeRoleClassification) {
          yield put({
            type: SET_ASSESSEE_ROLE_CLASSIFICAION_STATE,
            payload: userResponse.responseObject[0].informationSetup.assesseeRoleClassification
          });
          yield put({
            type: SET_SETUP_PERMISSION_NEW,
            payload: {
              objectName: 'assesseeRole',
              stateName: 'assesseeRoleDefault',
              value: userResponse.responseObject[0].informationSetup.assesseeRoleDefault
            }
          });
          yield put({
            type: SET_SETUP_PERMISSION_NEW,
            payload: {
              objectName: 'assesseeRole',
              stateName: 'assesseeRoleAdmin',
              value: userResponse.responseObject[0].informationSetup.assesseeRoleAdmin
            }
          });
          yield put({
            type: SET_SETUP_PERMISSION_NEW,
            payload: {
              objectName: 'assesseeRole',
              stateName: 'assesseeRoleManager',
              value: userResponse.responseObject[0].informationSetup.assesseeRoleManager
            }
          });
          yield put({
            type: SET_SETUP_PERMISSION_NEW,
            payload: {
              objectName: 'assesseeRole',
              stateName: 'assesseeRoleAssistant',
              value: userResponse.responseObject[0].informationSetup.assesseeRoleAssistant
            }
          });
        }

        if (userResponse.responseObject[0].informationSetup.assesseeRolePermission) {
          yield put({
            type: SET_SETUP_PERMISSION_NEW,
            payload: {
              objectName: 'assesseeRole',
              stateName: 'assesseeRolePermission',
              value: userResponse.responseObject[0].informationSetup.assesseeRolePermission
            }
          });
        }
      }
    } else {
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerReviseAssesseeRoleInfoSaga(data) {
  try {
    const userResponse = yield call(assesseeRoleInfoApi, {
      data: data.payload.reqBody
    });
    if (userResponse.responseCode === '000') {
      const { createMode = '', isReviseCallFromPaneTwo = false, headerOne } = data.payload;
      let middlePaneHeader =
        Store.getState().DisplayPaneTwoReducer.middlePaneHeader === ''
          ? 'assessees'
          : Store.getState().DisplayPaneTwoReducer.middlePaneHeader;
      if (data.payload.assesseeRoleAssesseeReqBody !== null) {
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
        yield put({
          type: GET_ASSESSEEROLE_ASSESSEE_REVIEW_LIST,
          payload: {
            request: data.payload.assesseeRoleAssesseeReqBody,
            HeaderOne: headerOne ? headerOne : middlePaneHeader,
            BadgeOne: '',
            BadgeTwo: '',
            BadgeThree: '',
            isMiddlePaneList: false
          }
        });
        if (isReviseCallFromPaneTwo) {
          yield put({
            type: SET_DISPLAY_THREE_SINGLE_STATE,
            payload: { stateName: 'isFrameworkOpen', value: 'assesseeRole' }
          });
        } else {
          yield put({
            type: SET_DISPLAY_THREE_SINGLE_STATE,
            payload: { stateName: 'isFrameworkOpen', value: '' }
          });
        }
        yield put({
          type: SET_DISPLAY_PANE_THREE_STATE,
          payload: {
            headerOne:  headerOne ? headerOne : middlePaneHeader,
            headerOneBadgeOne: 'role',
            headerOneBadgeTwo: 'information',
            headerOneBadgeThree: 'key',
            responseObject: isReviseCallFromPaneTwo
              ? Store.getState().DisplayPaneThreeReducer.responseObject
              : userResponse.responseObject[0],
            createMode: data.payload.createMode,
            reviewMode: isReviseCallFromPaneTwo ? 'revise' : ''
          }
        });
        if (!isReviseCallFromPaneTwo) {
          yield put({ type: SET_ASSESSEE_ROLE_ASSESSEE_ID_LIST, payload: [] });
          yield put({ type: SET_UNSELECTED_ASSESSEE_ROLE_ASSESSEE_ID_LIST, payload: [] });
        }
      }
      if (createMode === '') {
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'reviewListDistinctData', value: [] }
        });
        yield put({
          type: GET_ASSESSEE_ROLE_REVIEW_LIST_SAGA,
          payload: {
            HeaderOne: headerOne ? headerOne : middlePaneHeader,
            request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
            BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
            isMiddlePaneList: true
          }
        });
      } else {
        let status = userResponse.responseObject[0].informationEngagement.assesseeRoleStatus;
        let classification =
          userResponse.responseObject[0].informationSetup.assesseeRoleClassification;
        let tempArr = [];
        if (status === 'DELETED') {
          yield put({ type: CLEAR_DISPLAY_PANE_THREE });
        } else {
          yield put({
            type: SET_ASSESSEE_ROLE_REDUCER_STATE,
            payload: userResponse.responseObject[0].informationBasic
          });
          yield put({
            type: SET_ASSESSEE_ROLE_CLASSIFICAION_STATE,
            payload: classification
          });
          let popupContentArr = Store.getState().DisplayPaneTwoReducer.middlePaneListPopupOptions;
          yield put({
            type: SET_POPUP_SINGLE_STATE,
            payload: {
              stateName: 'selectedTagStatus',
              value: status
            }
          });
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: {
              stateName: 'middlePaneSelectedValue',
              value: userResponse.responseObject[0].id
            }
          });
          yield put({
            type: SET_POPUP_SINGLE_STATE,
            payload: { stateName: 'selectedTagValue', value: userResponse.responseObject[0].id }
          });
          popupContentArr.forEach((element) => {
            if (classification === 'Bespoke' && element.data === 'share' && status === 'ACTIVE')
              tempArr.push({ ...element, disabled: true });
            else if (
              (status === 'TERMINATED' || status === 'SUSPENDED' || status === 'ARCHIVED') &&
              (element.data === 'allocate' ||
                element.data === 'revise' ||
                element.data === 'select' ||
                element.data === 'share')
            ) {
              tempArr.push({ ...element, disabled: true });
            } else tempArr.push(element);
          });
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: {
              stateName: 'middlePaneListPopupOptions',
              value: tempArr
            }
          });

          yield put({ type: LOADER_STOP });
        }
      }
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: userResponse.responseMessage || EXCEPTION_ERROR_MESSAGE,
          popupMode: 'responseErrorMsg'
        }
      });
      yield put({ type: LOADER_STOP });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}

export default function* watchReviewAssesseeRoleInfoSaga() {
  yield takeLatest(GET_ASSESSEE_ROLE_REVIEW_INFO_SAGA, workerReviewAssesseeRoleInfoSaga);
  yield takeLatest(ASSESSEE_ROLE_INFO_REVISE_SAGA, workerReviseAssesseeRoleInfoSaga);
}
