import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PopUpPicture from '../../PopUpInformation/PopUpPicture';
import PopUpTextField from '../../PopUpInformation/PopUpTextField';
import PopUpConfirm from '../../PopUpGeneric/PopUpConfirm';
import {
  POPUP_CLOSE,
  SET_ASSIGNMENT_BASIC_REDUCER_STATE,
  CLEAR_ASSIGNMENT_INFO,
  CREATE_ASSIGNMENT_SAGA,
  LOADER_START,
  SET_ASSIGNMENT_DYNAMIC_SINGLE_STATE,
  SET_DISPLAY_THREE_SINGLE_STATE,
  SET_ASSIGNMENT_FRAMEWORK_STATE,
  SET_ASSIGNMENT_DYNAMIC_FRAMEWORK_STATE,
  SET_ASSIGNMENT_FRAMEWORK_TEMPLATE_ALIGNMENT_STATE,
  SET_ASSIGNMENT_GAUGE_STATE,
  ASSIGNMENT_PUBLISH_SAGA,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_ASSIGNMENT_TIMELINE_STATE,
  SET_ASSIGNMENT_ALL_STATE,
  SET_POPUP_SINGLE_STATE,
  SET_ASSIGNMENT_DYNAMIC_SETUP_STATE,
  POPUP_OPEN,
  SET_POPUP_STATE
} from '../../actionType';
import PopUpReviewList from '../../PopUpInformation/PopUpReviewList';
import PopUpDropList from '../../PopUpInformation/PopUpDropList';
import PopUpTextEditor from '../../PopUpIcon/PopUpTextEditor';
import PopUpItemTemplate from '../../PopUpInformation/PopUpItemTemplate';
import PopUpTagSecondary from '../../PopUpInformation/PopUpTagSecondary';
import PopUpMessageGeneric from '../../PopUpGeneric/PopUpMessageGeneric';
import { ASSIGNMENT_REVIEW_LIST_POPUP_OPTION } from '../../PopUpConfig';
import { distinctGroupSharePopupOpen, getPopUpOptionList } from '../../Actions/GenericActions';
import { CAN_NOT_REVISED_MESSAGE } from '../../errorMessage';
import InputField from '../../Atoms/InputField/InputField';

const PopUpAssignmentCreate = (props) => {
  const { headerOne } = props;
  const {
    isPopUpValue,
    popupErrorMsg,
    selectedTagValue,
    selectedTagInformationSetup,
    duplicateHeaderOne,
    duplicateHeaderOneBadgeOne
  } = useSelector((state) => state.PopUpReducer);
  const {
    informationBasic,
    informationAllocation,
    informationFramework,
    assignmentAdminSequence,
    informationSetup
  } = useSelector((state) => state.AssignmentReducer);
  const assignmentRed = useSelector((state) => state.AssignmentReducer);
  const {
    selectedAssociateInfo,
    coreTypeReviewListData,
    coreGroupReviewListData,
    coreNodeReviewListData,
    middlePaneSelectedValue,
    indexPointer,
    assignmentCreateType,
    signedAssesseePermission,
    sectionCreateType,
    sectionCreateTypeSecondary
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const {
    responseObject,
    reviewMode,
    statusPopUpValue,
    assignmentRelatedReviewListPaneThree,
    createMode
  } = useSelector((state) => state.DisplayPaneThreeReducer);
  const dispatch = useDispatch();
  const [requiredErrorMsg, setRequiredErrorMsg] = useState('');

  let primaryListTypeArr = coreTypeReviewListData.filter((tt) => {
    return tt.informationSetup.assignmentTypeDefault;
  });
  let secandaryListTypeArr = coreTypeReviewListData.filter(
    (tt) => !primaryListTypeArr.includes(tt)
  );
  useEffect(() => {
    let tempTypeArr = coreTypeReviewListData.filter((tt) => {
      return tt.informationSetup?.assignmentTypeDefault;
    });
    if (tempTypeArr.length > 0) {
      let arr = tempTypeArr.map((ob) => ob.id);
      informationAllocation.assignmentType.assignmentTypePrimary = arr;
    }
    console.log('arr---', tempTypeArr);
  }, [coreTypeReviewListData, createMode]);

  useEffect(() => {
    if (sectionCreateType !== '' && reviewMode !== 'revise') {
      let secondaryTypeValue = sectionCreateType + '_ASSIGNMENTS';
      console.log(
        'secondaryTypeValue--',
        `REACT_APP_${secondaryTypeValue.replace(' ', '_').toUpperCase()}`,
        '=',
        `${process.env[`REACT_APP_${secondaryTypeValue.replace(' ', '_').toUpperCase()}`]}`
      );
      let arrTypeSecondary = [];
      arrTypeSecondary.push(
        `${process.env[`REACT_APP_${secondaryTypeValue.replace(' ', '_').toUpperCase()}`]}`
      );
      informationAllocation.assignmentType.assignmentTypeSecondary = arrTypeSecondary;
    }
  }, [coreTypeReviewListData, createMode, sectionCreateTypeSecondary, sectionCreateType]);
  /**
  useEffect(() => {
    let tempTypeArr = coreTypeReviewListData.filter((tt) => {
      return tt.informationSetup.assignmentTypeDefault;
    });
    let arrTypeSecondary = [];
    console.log('assignmentCreateType', assignmentCreateType);
    console.log('sectionCreateTypeSecondary', sectionCreateTypeSecondary);
    if (assignmentCreateType !== 'iGuru_Assignment') {
      arrTypeSecondary.push(
        `${process.env[`REACT_APP_` + assignmentCreateType.replace(' ', '_').toUpperCase()]}`
      );

    }
    if (tempTypeArr.length > 0) {
      let arr = primaryListTypeArr.map((ob) => ob.id);
      informationAllocation.assignmentType.assignmentTypePrimary = arr;
      informationAllocation.assignmentType.assignmentTypeSecondary = arrTypeSecondary;
    }
  }, [coreTypeReviewListData, createMode ]);
   */

  const onClickCancelYes = () => {
    dispatch({
      type: SET_DISPLAY_THREE_SINGLE_STATE,
      payload: { stateName: 'createMode', value: '' }
    });
    dispatch({ type: CLEAR_ASSIGNMENT_INFO });
    dispatch({ type: POPUP_CLOSE });
  };
  const onClickYes = () => {
    // add default root node in allocation if node not selected
    if (informationAllocation.assignmentNode.assignmentNodePrimary.length === 0) {
      let rootNode = coreNodeReviewListData.filter((node) => {
        return node.informationFramework.associateNodeAscendantPrimary === null;
      });
      let rootNodeId = rootNode[0].id;
      informationAllocation.assignmentNode.assignmentNodePrimary = [
        ...informationAllocation.assignmentNode.assignmentNodePrimary,
        rootNodeId
      ];
    }
    let reqBody = {
      module: 'Assignment-Distinct',
      action: 'Create',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        assignment: {
          informationBasic: informationBasic,
          informationAllocation: informationAllocation
        }
      }
    };
    dispatch({ type: LOADER_START });
    dispatch({
      type: CREATE_ASSIGNMENT_SAGA,
      payload: { reqBody: reqBody, assignmentCreateType: assignmentCreateType }
    });
    let assigmentDistictPermission =
      signedAssesseePermission?.assigmentDistictPermission?.assesseeAssignmentDistinctPermission;
    let popupContentArrValue = getPopUpOptionList(
      ASSIGNMENT_REVIEW_LIST_POPUP_OPTION,
      assigmentDistictPermission
    );
    let finalPopupOptionArray = [];
    popupContentArrValue.forEach((element) => {
      if (element.data === 'administer' || element.data === 'share' || element.data === 'reset') {
        finalPopupOptionArray.push({ ...element, disabled: true });
      } else {
        finalPopupOptionArray.push(element);
      }
      if (assignmentCreateType !== 'iGuru Analytics_Occupation') {
        // delete finalPopupOptionArray[16];
        // delete finalPopupOptionArray[17];
        // delete finalPopupOptionArray[18];
        finalPopupOptionArray.splice(16, 2);
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: finalPopupOptionArray
      }
    });
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: {
        stateName: 'popupOptionArr',
        value: finalPopupOptionArray
      }
    });
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: {
        stateName: 'originPrimaryArrOprion',
        value: finalPopupOptionArray
      }
    });
  };
  const onClickForceYes = () => {
    let reqBody = {
      module: 'Assignment-Publish',
      action: 'Publish',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        assignmentId: middlePaneSelectedValue,
        processWithInvalidAssessment: true
      }
    };
    dispatch({ type: LOADER_START });
    dispatch({
      type: ASSIGNMENT_PUBLISH_SAGA,
      payload: {
        secondaryOptionCheckValue: '',
        hideRightPane: true,
        headerOne: '',
        reqBody
      }
    });
  };
  let selectedPrimaryGroup = informationAllocation?.assignmentGroup.assignmentGroupPrimary || [];
  let selectedSecondaryGroup =
    informationAllocation?.assignmentGroup.assignmentGroupSecondary || [];
  let filteredCoreGroupReviewListDataPrimary = [];
  if (coreGroupReviewListData && coreGroupReviewListData.length > 0) {
    coreGroupReviewListData.forEach((group) => {
      // for primary popup list
      if (!selectedSecondaryGroup.includes(group.id))
        filteredCoreGroupReviewListDataPrimary.push(group);
    });
  }
  let filteredCoreGroupReviewListDataSecondary = [];
  if (coreGroupReviewListData && coreGroupReviewListData.length > 0) {
    coreGroupReviewListData.forEach((group) => {
      // for Secondary popup list
      if (!selectedPrimaryGroup.includes(group.id))
        filteredCoreGroupReviewListDataSecondary.push(group);
    });
  }
  const updateAllocationObj = (e, stateName, actualStateName) => {
    let groupid = e.currentTarget.getAttribute('tag');
    let groupArr = informationAllocation[stateName][actualStateName];
    setRequiredErrorMsg('');
    let isDefault = e.currentTarget.getAttribute('data-default');
    if (
      isDefault !== 'true' &&
      groupid !==
        `${process.env[`REACT_APP_` + assignmentCreateType.replace(' ', '_').toUpperCase()]}`
    ) {
      if (groupArr.includes(groupid)) {
        document.getElementById(groupid).style.backgroundColor = 'white';
        groupArr = groupArr.filter(function (number) {
          return number !== groupid;
        });
      } else {
        groupArr.push(groupid);
        document.getElementById(groupid).style.backgroundColor = '#F0F0F0';
      }
      dispatch({
        type: SET_ASSIGNMENT_DYNAMIC_SINGLE_STATE,
        payload: {
          stateName: stateName,
          actualStateName: actualStateName,
          value: groupArr
        }
      });
    }
  };
  const updateGaugeFramework = (e) => {
    let tagId = e.currentTarget.getAttribute('tag');
    let tagArr = [];
    if (tagArr.includes(tagId)) {
      document.getElementById(tagId).style.backgroundColor = 'white';
      tagArr = tagArr.filter(function (number) {
        return number !== tagId;
      });
    } else {
      tagArr.push(tagId);
      document.getElementById(tagId).style.backgroundColor = '#F0F0F0';
    }

    // let tagArr = [];
    // if (tagArr.includes(tagId)) {
    //   document.getElementById(tagId).style.backgroundColor = 'white';
    //   tagArr = tagArr.filter(function (number) {
    //     return number !== tagId;
    //   });
    // } else {
    //   tagArr.push(tagId);
    //   document.getElementById(tagId).style.backgroundColor = '#F0F0F0';
    // }
    // let newArr = [];
    // informationFramework?.igaugeOccupationFrameworkOneGaugeList.forEach((element) => {
    //   if (tagArr.includes(element.id)) {
    //     newArr.push(element);
    //   }
    // });
    dispatch({ type: SET_ASSIGNMENT_GAUGE_STATE, payload: tagArr });
  };
  let assignmentCommunique = informationFramework?.assignmentCommunique || [];
  let assignmentSynopsis = informationFramework?.assignmentSynopsis || [];
  let isNotRevised =
    responseObject?.informationEngagement?.assignmentStatus === 'ADMINISTERED' ||
    responseObject?.informationEngagement?.assignmentStatus === 'PUBLISHED';

  // const onClickShareFee = () => {
  //   distinctGroupSharePopupOpen(
  //     dispatch,
  //     selectedTagValue,
  //     duplicateHeaderOne,
  //     duplicateHeaderOneBadgeOne
  //   );
  // };
  return (
    <div>
      {isPopUpValue === 'NAMEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'name'}
          actualLableValue={'assignmentName'}
          actualLableValueVerification={'assignmentNameVerification'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          headerOneBadgeTwo={''}
          nextPopUpValue={'ALIASPOPUP'}
          basicInfo={informationBasic}
          typeOfSetObject={SET_ASSIGNMENT_BASIC_REDUCER_STATE}
          isRequired={true}
          errorMsg={popupErrorMsg}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={isNotRevised}
        />
      )}
      {isPopUpValue === 'ALIASPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'description'}
          actualLableValue={'assignmentDescription'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          headerOneBadgeTwo={''}
          basicInfo={informationBasic}
          nextPopUpValue={'PICTUREPOPUP'}
          typeOfSetObject={SET_ASSIGNMENT_BASIC_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={isNotRevised}
        />
      )}
      {isPopUpValue === 'PICTUREPOPUP' && (
        <PopUpPicture
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          headerOneBadgeTwo={''}
          actualLableValue={'assignmentPicture'}
          actualLableValueVerification={'assignmentPictureVerification'}
          basicInfo={informationBasic}
          typeOfSetObject={SET_ASSIGNMENT_BASIC_REDUCER_STATE}
          nextPopUpValue={'GROUPPOPUP'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={isNotRevised}
        />
      )}
      {isPopUpValue === 'GROUPPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'NODEPOPUP'}
          inputHeader={'group'}
          inputHeaderBadge={'primary'}
          infoMsg={'select a group'}
          ListData={filteredCoreGroupReviewListDataPrimary}
          textOne={'assignmentGroupName'}
          textTwo={'assignmentGroupDescription'}
          // onClickEvent={updateAssignmentGroups}
          onClickEvent={(e) => {
            updateAllocationObj(e, 'assignmentGroup', 'assignmentGroupPrimary');
          }}
          selectedList={informationAllocation.assignmentGroup.assignmentGroupPrimary}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={isNotRevised}
          setErrorMsg={setRequiredErrorMsg}
          useLocalState={true}
          stateName={`assignmentGroup`}
          actualStateName={`assignmentGroupPrimary`}
          objectName={'informationAllocation'}
          updateState={SET_ASSIGNMENT_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'GROUPSECONDARYPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'NODEPOPUP'}
          inputHeader={'group'}
          inputHeaderBadge={'secondary'}
          infoMsg={'select a group'}
          ListData={filteredCoreGroupReviewListDataSecondary}
          textOne={'assignmentGroupName'}
          textTwo={'assignmentGroupDescription'}
          selectedList={informationAllocation.assignmentGroup.assignmentGroupSecondary}
          onClickEvent={(e) => {
            updateAllocationObj(e, 'assignmentGroup', 'assignmentGroupSecondary');
          }}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={isNotRevised}
          setErrorMsg={setRequiredErrorMsg}
          useLocalState={true}
          stateName={`assignmentGroup`}
          actualStateName={`assignmentGroupSecondary`}
          objectName={'informationAllocation'}
          updateState={SET_ASSIGNMENT_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'NODEPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'TYPEPOPUP'}
          inputHeader={'node'}
          inputHeaderBadge={'primary'}
          infoMsg={'select a node'}
          isRequired={true}
          minimumSelected={1}
          setErrorMsg={setRequiredErrorMsg}
          errorMsg={requiredErrorMsg}
          ListData={coreNodeReviewListData}
          textOne={'associateNodeName'}
          textTwo={'associateNodeDescription'}
          selectedList={informationAllocation.assignmentNode.assignmentNodePrimary}
          onClickEvent={(e) => {
            updateAllocationObj(e, 'assignmentNode', 'assignmentNodePrimary');
          }}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={isNotRevised}
          useLocalState={true}
          stateName={`assignmentNode`}
          actualStateName={`assignmentNodePrimary`}
          objectName={'informationAllocation'}
          updateState={SET_ASSIGNMENT_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'NODESECONDARYPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'TYPEPOPUP'}
          inputHeader={'node'}
          inputHeaderBadge={'secondary'}
          infoMsg={'select a node'}
          ListData={coreNodeReviewListData}
          textOne={'associateNodeName'}
          textTwo={'associateNodeDescription'}
          selectedList={informationAllocation.assignmentNode.assignmentNodeSecondary}
          onClickEvent={(e) => {
            updateAllocationObj(e, 'assignmentNode', 'assignmentNodeSecondary');
          }}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={isNotRevised}
          setErrorMsg={setRequiredErrorMsg}
          useLocalState={true}
          stateName={`assignmentNode`}
          actualStateName={`assignmentNodeSecondary`}
          objectName={'informationAllocation'}
          updateState={SET_ASSIGNMENT_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'TYPEPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'CONFIRMATIONPOPUP'}
          inputHeader={'type'}
          inputHeaderBadge={'primary'}
          dataDefault={'assignmentTypeDefault'}
          infoMsg={'select a type'}
          ListData={primaryListTypeArr}
          textOne={'assignmentTypeName'}
          textTwo={'assignmentTypeDescription'}
          onClickEvent={(e) => {
            updateAllocationObj(e, 'assignmentType', 'assignmentTypePrimary');
          }}
          isRequired={assignmentCreateType === 'iGuru_Assignment' ? false : true}
          minimumSelected={1}
          setErrorMsg={setRequiredErrorMsg}
          errorMsg={requiredErrorMsg}
          selectedList={informationAllocation.assignmentType.assignmentTypePrimary}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={isNotRevised}
          useLocalState={true}
          stateName={`assignmentType`}
          actualStateName={`assignmentTypePrimary`}
          objectName={'informationAllocation'}
          updateState={SET_ASSIGNMENT_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'TYPESECONDARYPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'CONFIRMATIONPOPUP'}
          inputHeader={'type'}
          inputHeaderBadge={'secondary'}
          infoMsg={'select a type'}
          ListData={secandaryListTypeArr}
          textOne={'assignmentTypeName'}
          textTwo={'assignmentTypeDescription'}
          onClickEvent={(e) => {
            updateAllocationObj(e, 'assignmentType', 'assignmentTypeSecondary');
          }}
          selectedList={informationAllocation.assignmentType.assignmentTypeSecondary}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={isNotRevised}
          useLocalState={true}
          setErrorMsg={setRequiredErrorMsg}
          stateName={`assignmentType`}
          actualStateName={`assignmentTypeSecondary`}
          objectName={'informationAllocation'}
          updateState={SET_ASSIGNMENT_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'GAUGEPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={''}
          inputHeader={'gauge'}
          inputHeaderBadge={''}
          infoMsg={'select a gauge'}
          ListData={informationFramework.igaugeOccupationFrameworkOneGaugeList}
          textOne={'iGaugeOccupationFrameworkOneGaugeName'}
          textTwo={'iGaugeOccupationFrameworkOneGaugeDescription'}
          onClickEvent={(e) => {
            updateGaugeFramework(e);
          }}
          selectedList={informationFramework?.assignmentGaugeDistinct || []}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={isNotRevised}
        />
      )}
      {isPopUpValue === 'CANCELPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'cancel'}
          headerOneBadgeOne={''}
          mode={'cancel'}
          onClickYes={onClickCancelYes}
        />
      )}
      {isPopUpValue === 'CONFIRMATIONPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'create'}
          headerOneBadgeTwo={''}
          onClickYes={onClickYes}
          onClickNoFun={onClickCancelYes}
        />
      )}
      {isPopUpValue === 'ADMINPROCTORPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assignmentAdministrationProctor'}
          label={'proctor'}
          listSelect={[
            { id: false, name: 'No' },
            { id: true, name: 'Yes' }
          ]}
          mappingValue={'id'}
          inputHeaderBadgeOne={''}
          inputHeaderBadgeTwo={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={informationFramework}
          typeOfSetObject={SET_ASSIGNMENT_FRAMEWORK_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={isNotRevised}
        />
      )}
      {isPopUpValue === 'IS_ASSIGNMENT_FOR_EVAL_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assignmentDistinctEvaluationRef'}
          label={'evaluation ref'}
          listSelect={[
            { id: false, name: 'No' },
            { id: true, name: 'Yes' }
          ]}
          mappingValue={'id'}
          inputHeaderBadgeOne={''}
          inputHeaderBadgeTwo={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={assignmentRed}
          typeOfSetObject={SET_ASSIGNMENT_ALL_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          // isNotRevised={isNotRevised}
        />
      )}
      {isPopUpValue === 'ADMINREPEATPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assignmentAdministrationRepeat'}
          label={'repeat'}
          listSelect={[
            { id: false, name: 'No' },
            { id: true, name: 'Yes' }
          ]}
          mappingValue={'id'}
          inputHeaderBadgeOne={''}
          inputHeaderBadgeTwo={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={informationFramework}
          typeOfSetObject={SET_ASSIGNMENT_FRAMEWORK_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={isNotRevised}
        />
      )}
      {isPopUpValue === 'ADMINRESETPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assignmentAdministrationReset'}
          label={'reset'}
          listSelect={[
            { id: false, name: 'No' },
            { id: true, name: 'Yes' }
          ]}
          mappingValue={'id'}
          inputHeaderBadgeOne={''}
          inputHeaderBadgeTwo={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={informationFramework}
          typeOfSetObject={SET_ASSIGNMENT_FRAMEWORK_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={isNotRevised}
        />
      )}
      {isPopUpValue === 'ADMINSHUFFLEPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assignmentAdministrationShuffle'}
          label={'shuffle'}
          listSelect={[
            { id: false, name: 'No' },
            { id: true, name: 'Yes' }
          ]}
          mappingValue={'id'}
          inputHeaderBadgeOne={''}
          inputHeaderBadgeTwo={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={informationFramework}
          typeOfSetObject={SET_ASSIGNMENT_FRAMEWORK_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={isNotRevised}
        />
      )}
      {isPopUpValue === 'ADMINSUPERVISEPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assignmentAdministrationSupervise'}
          label={'supervise'}
          listSelect={[
            { id: false, name: 'No' },
            { id: true, name: 'Yes' }
          ]}
          mappingValue={'id'}
          inputHeaderBadgeOne={''}
          inputHeaderBadgeTwo={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={informationFramework}
          typeOfSetObject={SET_ASSIGNMENT_FRAMEWORK_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={isNotRevised}
        />
      )}
      {isPopUpValue === 'ADMINSEQUENCEPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assignmentAdministrationSequence'}
          label={'sequence'}
          listSelect={assignmentAdminSequence ? assignmentAdminSequence : ['assignment assessment']}
          isMultiSelect={true}
          inputHeaderBadgeOne={''}
          inputHeaderBadgeTwo={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={''}
          basicInfo={informationFramework}
          typeOfSetObject={SET_ASSIGNMENT_FRAMEWORK_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={isNotRevised}
        />
      )}
      {isPopUpValue === 'ADMINVERSONPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assignmentAdministrationVersion'}
          label={'version'}
          listSelect={[
            { id: 'Fixed', name: 'Fixed' },
            { id: 'Random', name: 'Random' }
          ]}
          mappingValue={'id'}
          inputHeaderBadgeOne={''}
          inputHeaderBadgeTwo={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={informationFramework}
          typeOfSetObject={SET_ASSIGNMENT_FRAMEWORK_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={isNotRevised}
        />
      )}
      {isPopUpValue === 'COMMUNIQUE_TEXTSHEET_POPUP' && (
        <PopUpTextEditor
          isActive={true}
          headerOne={'assignment'}
          headerPanelColour={'genericOne'}
          headerOneBadgeOne={'communiqué'}
          headerOneBadgeTwo={indexPointer}
          defaultSheetValue={assignmentCommunique[indexPointer - 1]}
          onClickSave={(data) => {
            //dispatch({ type: SET_ASSIGNMENT_COMMUNIQUE_FRAMEWORK_STATE, payload: data });
            if (assignmentCommunique[indexPointer - 1]) {
              if (data !== '') {
                assignmentCommunique.splice(indexPointer - 1, 1, data);
              } else {
                assignmentCommunique.splice(indexPointer - 1, 1);
              }
            } else if (data !== '') {
              assignmentCommunique.push(data);
            } else {
            }
            dispatch({
              type: SET_ASSIGNMENT_DYNAMIC_FRAMEWORK_STATE,
              payload: {
                stateName: 'assignmentCommunique',
                value: assignmentCommunique
              }
            });
          }}
          actualLableValue={indexPointer - 1}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={isNotRevised}
        />
      )}
      {isPopUpValue === 'SYNOPSIS_TEXTSHEET_POPUP' && (
        <PopUpTextEditor
          isActive={true}
          headerOne={'assignment'}
          headerPanelColour={'genericOne'}
          headerOneBadgeOne={'synopsis'}
          headerOneBadgeTwo={indexPointer}
          defaultSheetValue={assignmentSynopsis[indexPointer - 1]}
          onClickSave={(data) => {
            //dispatch({ type: SET_ASSIGNMENT_SYNOPSIS_FRAMEWORK_STATE, payload: data });

            if (assignmentSynopsis[indexPointer - 1]) {
              if (data !== '') {
                assignmentSynopsis.splice(indexPointer - 1, 1, data);
              } else {
                assignmentSynopsis.splice(indexPointer - 1, 1);
              }
            } else if (data !== '') {
              assignmentSynopsis.push(data);
            } else {
            }
            dispatch({
              type: SET_ASSIGNMENT_DYNAMIC_FRAMEWORK_STATE,
              payload: {
                stateName: 'assignmentSynopsis',
                value: assignmentSynopsis
              }
            });
          }}
          actualLableValue={indexPointer - 1}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={isNotRevised}
        />
      )}
      {isPopUpValue === 'TEMPLATEPOPUP' && (
        <PopUpItemTemplate
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          basicInfo={informationFramework?.assignmentAdministrationTemplateMedia}
          typeOfSetObject={SET_ASSIGNMENT_DYNAMIC_FRAMEWORK_STATE}
          setDependantObj={'assignmentAdministrationTemplateMedia'}
          setDependantObjOne={'assignmentAdministrationTemplateMediaHtml'}
          defaultSheetValue={informationFramework?.assignmentAdministrationTemplateMediaHtml}
          nextPopUpValue={''}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={isNotRevised}
        />
      )}
      {isPopUpValue === 'ASSESSMENTMEDIA' && (
        <PopUpDropList
          isActive={true}
          tag={'assignmentItemFrameworkOneResponseChoiceAlignment'}
          label={'response-choice'}
          labelBadgeOne={'alignment'}
          listSelect={[
            { id: '', name: '' },
            { id: 'horizontal', name: 'horizontal' },
            { id: 'vertical', name: 'vertical' }
          ]}
          mappingValue={'id'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={informationFramework?.assignmentAdministrationTemplateAlignment}
          typeOfSetObject={SET_ASSIGNMENT_FRAMEWORK_TEMPLATE_ALIGNMENT_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={isNotRevised}
        />
      )}
      {isPopUpValue === 'STATUSPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assignmentStatus'}
          label={'status'}
          listSelect={[
            { id: 'Active', name: 'Active' },
            { id: 'Suspended', name: 'Suspended' },
            { id: 'Terminated', name: 'Terminated' },
            { id: 'Unverified', name: 'Unverified' },
            { id: 'Confirmed', name: 'Confirmed' },
            { id: 'Unconfirmed', name: 'Unconfirmed' },
            { id: 'Unpublished', name: 'Unpublished' },
            { id: 'Published', name: 'Published' }
          ]}
          mappingValue={'id'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          isRequired={true}
          basicInfo={statusPopUpValue}
          nextPopUpValue={''}
          isNotRevised={true}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TENURESATRTDATEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tenure'}
          labelBadgeOne={'start'}
          actualLableValue={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          basicInfo={
            responseObject?.informationEngagement.assignmentTenure?.assignmentTenureDateTimeStart ||
            'mm/dd/yyyy --:-- --'
          }
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={''}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TENUREENDDATEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tenure'}
          labelBadgeOne={'end'}
          actualLableValue={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          basicInfo={
            responseObject?.informationEngagement.assignmentTenure?.assignmentTenureDateTimeEnd ||
            'mm/dd/yyyy --:-- --'
          }
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={''}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TIMELINE_STAR_TPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'timeline'}
          type={'datetime-local'}
          labelBadgeOne={'start'}
          actualLableValue={'assignmentTimelineDateTimeStart'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          basicInfo={informationFramework?.assignmentTimeline || 'mm/dd/yyyy --:-- --'}
          nextPopUpValue={''}
          typeOfSetObject={SET_ASSIGNMENT_TIMELINE_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={isNotRevised}
        />
      )}
      {isPopUpValue === 'TIMELINE_END_TPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'timeline'}
          type={'datetime-local'}
          labelBadgeOne={'end'}
          actualLableValue={'assignmentTimelineDateTimeEnd'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          basicInfo={informationFramework?.assignmentTimeline || 'mm/dd/yyyy --:-- --'}
          nextPopUpValue={''}
          typeOfSetObject={SET_ASSIGNMENT_TIMELINE_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={isNotRevised}
        />
      )}
      {isPopUpValue === 'TAGREADONLYPRIMARYPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tag'}
          labelBadgeOne={'primary'}
          actualLableValue={'associateTagPrimary'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          basicInfo={responseObject?.informationEngagement.assignmentTag.assignmentTagPrimary || ''}
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={''}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TAGSECONDARYPOPUP' && (
        <PopUpTagSecondary
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          tagSecondary={responseObject?.informationEngagement}
          signInSetup={responseObject?.informationSetup}
          nextPopUpValue={'CONFIRMATIONPOPUP'}
          typeOfSetObject={''}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={isNotRevised}
        />
      )}
      {isPopUpValue === 'FORCE_TO_PUBLISH_ASSIGNMENT' && (
        <PopUpMessageGeneric
          isActive={true}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'PUBLISH_YES'}
          textOneOne={'Invalid/unpublish assessments'}
          textOneTwo={''}
          textOneThree={'do you want to publish it'}
          textOneFour={``}
          mode={'next'}
        />
      )}
      {isPopUpValue === 'PUBLISH_YES' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'publish'}
          mode={'cancel'}
          onClickYes={onClickForceYes}
        />
      )}
      {isPopUpValue === 'ASSESSMENT_POPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={''}
          inputHeader={'assessment'}
          inputHeaderBadge={'distinct'}
          infoMsg={'assessment'}
          ListData={assignmentRelatedReviewListPaneThree.assessment}
          textOne={'assessmentName'}
          textTwo={'assessmentDescription'}
          selectedList={[]}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={true}
          errorMsg={CAN_NOT_REVISED_MESSAGE}
        />
      )}
      {isPopUpValue === 'ASSESSMENT_GROUP_POPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={''}
          inputHeader={'assessment'}
          inputHeaderBadge={'group'}
          infoMsg={'assessment'}
          ListData={assignmentRelatedReviewListPaneThree?.assessmentGroup}
          textOne={'assessmentGroupName'}
          textTwo={'assessmentGroupDescription'}
          selectedList={[]}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={true}
          errorMsg={CAN_NOT_REVISED_MESSAGE}
        />
      )}
      {isPopUpValue === 'CULTUREPROFILE_POPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={''}
          inputHeader={'culture-profiles'}
          inputHeaderBadge={'distinct'}
          infoMsg={'culture-profiles'}
          ListData={assignmentRelatedReviewListPaneThree.cultureProfile}
          textOne={'cultureProfileName'}
          textTwo={'cultureProfileDescription'}
          selectedList={[]}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={true}
          errorMsg={CAN_NOT_REVISED_MESSAGE}
        />
      )}
      {isPopUpValue === 'CULTURE_GROUP_POPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={''}
          inputHeader={'culture-profiles'}
          inputHeaderBadge={'group'}
          infoMsg={'culture-profiles'}
          ListData={assignmentRelatedReviewListPaneThree?.cultureProfileGroup}
          textOne={'cultureProfileName'}
          textTwo={'cultureProfileDescription'}
          selectedList={[]}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={true}
          errorMsg={CAN_NOT_REVISED_MESSAGE}
        />
      )}
      {isPopUpValue === 'JOBPROFILE_POPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={''}
          inputHeader={'job-profiles'}
          inputHeaderBadge={'distinct'}
          infoMsg={'job-profiles'}
          ListData={assignmentRelatedReviewListPaneThree.jobProfile}
          textOne={'jobProfileName'}
          textTwo={'jobProfileDescription'}
          selectedList={[]}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={true}
          errorMsg={CAN_NOT_REVISED_MESSAGE}
        />
      )}
      {isPopUpValue === 'JOB_GROUP_POPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={''}
          inputHeader={'job-profiles'}
          inputHeaderBadge={'group'}
          infoMsg={'job-profiles'}
          ListData={assignmentRelatedReviewListPaneThree?.jobProfileGroup || []}
          textOne={'jobProfileName'}
          textTwo={'jobProfileDescription'}
          selectedList={[]}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={true}
          errorMsg={CAN_NOT_REVISED_MESSAGE}
        />
      )}
      {isPopUpValue === 'ASSIGNMENTCREATEFEEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'fee'}
          headerPanelColour={'genericOne'}
          inputHeader={'assignment'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'share'}
          type={'number'}
          headerOne={'assignment'}
          headerOneBadgeOne={'information'}
          isRequired={false}
          actualLableValue={'assignmentShareFee'}
          basicInfo={informationSetup}
          typeOfSetObject={SET_ASSIGNMENT_DYNAMIC_SETUP_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          currencySymbol={''}
          isNotRevised={isNotRevised}
        />
      )}
      {isPopUpValue === 'VALIDITY_POPUP' && (
        <PopUpTextField
          isActive={true}
          label={'validity'}
          headerPanelColour={'genericOne'}
          inputHeader={'assignment'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'revise'}
          type={'number'}
          headerOne={'assignment'}
          headerOneBadgeOne={'information'}
          isRequired={false}
          actualLableValue={'assignmentValidity'}
          basicInfo={informationFramework}
          typeOfSetObject={SET_ASSIGNMENT_FRAMEWORK_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          endAdornment={'month'}
          isNotRevised={isNotRevised}
        />
      )}
      {/* {isPopUpValue === 'SHAREASSIGNMENTFEEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'fee'}
          headerPanelColour={'genericOne'}
          inputHeader={'assignment'}
          inputHeaderBadgeOne={'distinct'}
          // inputHeaderBadgeTwo={'share'}
          type={'number'}
          headerOne={'assignment'}
          headerOneBadgeOne={'information'}
          isRequired={false}
          actualLableValue={'assignmentShareFee'}
          basicInfo={selectedTagInformationSetup}
          typeOfSetObject={SET_ASSIGNMENT_DYNAMIC_SETUP_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          currencySymbol={''}
          onClickYes={onClickShareFee}
        />
      )} */}
    </div>
  );
};

export default PopUpAssignmentCreate;
