import React from 'react'
// import RefreshIcon from '@material-ui/icons/Refresh';
import './ListLoader.css'
import { CircularProgress } from '@material-ui/core';

const ListLoader = (props) => {
    return (
        <div className='ListLoader'>
            <br />
            {/* <RefreshIcon className="loading-icon" /> */}
            <CircularProgress color="primary" size={20} />
        </div>
    )
}

export default ListLoader;