import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PopupHeader from '../Molecules/PopUp/PopUpHeader';
import Popup from '../Molecules/PopUp/PopUp';
import '../Molecules/PopUp/PopUp.css';
import { DialogContent } from '@material-ui/core';
import {
  ASSIGNMENT_POPUP_CLOSE,
  CLEAR_ASSIGNMENT_INFO,
  CLEAR_DISPLAY_PANE_THREE,
  GET_ASSIGNMENT_TYPE_REVIEW_LIST_SAGA,
  IGAUGE_SECTION_POPUP_CLOSE,
  LOADER_START,
  POPUP_CLOSE,
  SET_ASSIGNMENT_NEXT_POPUP,
  SET_ASSIGNMENT_PREVIOUS_POPUP,
  SET_ASSIGNMENT_SECONDARY_OPTION_VALUE,
  SET_ASSIGNMENT_SINGLE_STATE,
  SET_CORE_TYPE_REVIEW_LIST_REQ_OBJECT,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MIDDLEPANE_STATE,
  SET_POPUP_SINGLE_STATE,
  SET_PREVIOUS_SECTION_POPUP
} from '../actionType';
import JsonRenderComponent from '../Actions/JsonRenderComponent';
import { getInternalNodeApiCall } from '../Actions/AssociateModuleAction';
import {
  assignmentsDistinctApiCall,
  assignmentsGroupApiCall,
  assignmentTypeApiCall,
  createAssignmentPopupApiCall,
  getMarketplaceAssignmentDistinctApiCall,
  getMarketplaceAssignmentGroupApiCall
} from '../Actions/AssignmentModuleAction';
import { makeAssignmentTypeObj } from '../Actions/GenericActions';

const PopUpAssignmentModule = (props) => {
  const {
    currentPopUpOption,
    assignmentsPopUpType, //
    assignmentsHeaderOne,
    assignmentsHeaderOneBadgeOne,
    secondaryOptionCheckValue,
    isBackToSectionPopUp
  } = useSelector((state) => state.AssignmentReducer);

  const dispatch = useDispatch();
  const { headerPanelColour = 'displayPaneLeft' } = props;
  const {
    countPage,
    selectedAssociateInfo,
    assignmentCreateType,
    signedAssesseePermission,
    isListLoading,
    paneOneSelectedSection
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const setSecondaryOptionValue = (e) => {
    dispatch({
      type: SET_ASSIGNMENT_SECONDARY_OPTION_VALUE,
      payload: e.currentTarget.getAttribute('data-value')
    });
  };
  const resetDataFunction = () => {
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'allocateStr', value: '' }
    });
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: { stateName: 'cardValue', value: 'NoCard' }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'typeOfMiddlePaneList', value: '' }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'middlePaneSelectedValue', value: '' }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'selectedFlagedArray', value: [] }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'unselectedFlagedArray', value: [] }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'selectedTagsArray', value: [] }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'unselectedTagsArray', value: [] }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'flagedValue', value: '' }
    });
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: { stateName: 'selectionValue', value: '' }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'nodeViewState', value: '' }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'scanString', value: '' }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'allocatedTagsArray', value: [] }
    });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
    dispatch({ type: CLEAR_ASSIGNMENT_INFO });
  };
  const ChangeOptionPopup = (e) => {
    let targetValue = e.currentTarget.getAttribute('data-value');
    if (targetValue === 'information' && !isListLoading) {
      resetDataFunction();
      createAssignmentPopupApiCall(
        selectedAssociateInfo,
        secondaryOptionCheckValue,
        dispatch,
        assignmentCreateType
      );
      dispatch({
        type: SET_MIDDLEPANE_STATE,
        payload: {
          middlePaneHeader: '',
          middlePaneHeaderBadgeOne: '',
          middlePaneHeaderBadgeTwo: '',
          middlePaneHeaderBadgeThree: '',
          middlePaneHeaderBadgeFour: '',
          typeOfMiddlePaneList: '',
          scanCount: null,
          showMiddlePaneState: false
        }
      });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'middlePaneSelectedValue', value: '' }
      });
      dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
    } else if (targetValue === 'distinct' && !isListLoading) {
      resetDataFunction();
      if (paneOneSelectedSection === "paneoneSection3") {
        console.debug({ call: "call" })
        return getMarketplaceAssignmentDistinctApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          targetValue,
          '',
          assignmentCreateType
        );

      }
      else {
        assignmentsDistinctApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          targetValue,
          '',
          assignmentCreateType
        )
      };
    } else if (targetValue === 'groups' && !isListLoading) {
      resetDataFunction();
      if (paneOneSelectedSection === "paneoneSection3") {
        getMarketplaceAssignmentGroupApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          targetValue,
          'noCard',
          '',
          'hierarchy',
          [],
          assignmentCreateType)
        return
      }
      else {
        assignmentsGroupApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          targetValue,
          'noCard',
          '',
          'hierarchy',
          [],
          assignmentCreateType
        )
      };
    } else if (targetValue === 'types' && !isListLoading) {
      resetDataFunction();

      if (paneOneSelectedSection === "paneoneSection3") {
        assignmentTypeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          targetValue,
          '',
          '',
          'Assignment-Type-Shared-List'
        );

      } else {
        assignmentTypeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          targetValue
        );
      }

    } else if (targetValue === 'nodes' && !isListLoading) {
      resetDataFunction();
      getInternalNodeApiCall(
        selectedAssociateInfo,
        secondaryOptionCheckValue,
        countPage,
        dispatch,
        targetValue,
        '',
        'hierarchy',
        'assignments'
      );
    } else {
      console.log('signedAssesseePermission', signedAssesseePermission);
      dispatch({
        type: SET_ASSIGNMENT_NEXT_POPUP,
        // payload: e.currentTarget.getAttribute('data-value')
        payload: {
          targetValue,
          permissionObject: signedAssesseePermission?.assigmentDistictPermission
        }
      });
    }
  };
  const BackHandlerEvent = (e) => {
    if (isBackToSectionPopUp) {
      dispatch({ type: CLEAR_ASSIGNMENT_INFO });
      dispatch({ type: SET_PREVIOUS_SECTION_POPUP });
    } else {
      dispatch({
        type: SET_ASSIGNMENT_PREVIOUS_POPUP,
        payload: {
          permissionObject:
            signedAssesseePermission?.assigmentDistictPermission
              ?.assesseeAssignmentDistinctPermission
        }
      });
    }
  };

  const onClosePopUp = () => {
    dispatch({ type: POPUP_CLOSE });
    dispatch({ type: ASSIGNMENT_POPUP_CLOSE });
    dispatch({ type: IGAUGE_SECTION_POPUP_CLOSE });
    dispatch({
      type: SET_ASSIGNMENT_SINGLE_STATE,
      payload: { stateName: 'assignmentsPopUpType', value: 'primary' }
    });
  };
  return (
    <div>
      <Popup isActive={props.isActive}>
        <PopupHeader
          headerPanelColour={headerPanelColour + assignmentsPopUpType}
          headerOne={assignmentsHeaderOne}
          headerOneBadgeOne={assignmentsHeaderOneBadgeOne}
          onClick={BackHandlerEvent}
          onClosePopUpEvent={onClosePopUp}
          mode={''}
        />
        <DialogContent className={['popupContent', 'fixed05PadDim'].join(' ')}>
          <JsonRenderComponent
            setSecondaryOptionValue={setSecondaryOptionValue}
            ChangeOptionPopup={ChangeOptionPopup}
            currentPopUpOption={currentPopUpOption}
            secondaryOptionCheckValue={secondaryOptionCheckValue}
          />
        </DialogContent>
      </Popup>
    </div>
  );
};

export default PopUpAssignmentModule;
