import {
  SET_TYPE_REDUCER_STATE,
  CLEAR_ITEM_REDUCER_STATE,
  SET_ITEM_DYNAMIC_SINGLE_STATE,
  SET_ITEM_FRAMEWORK_DYNAMIC_SINGLE_STATE,
  SET_ITEM_FRAMWORK_TYPE,
  SET_ITEMFRAMEWORK_REDUCER_STATE,
  SET_ITEM_FRAMEWORK_INNER_SINGLE_STATE,
  SET_ITEM_SINGLE_STATE,
  SET_ITEM_SETUP_INFO_STATE
} from '../actionType';
const initialState = {
  itemInformation: {
    informationBasic: {
      itemName: '',
      itemNameVerification: false,
      itemDescription: '',
      itemPicture: '',
      itemPictureVerification: false
    },
    informationAllocation: {
      itemGroup: {
        itemGroupPrimary: [],
        itemGroupSecondary: []
      },
      itemNode: {
        itemNodePrimary: [],
        itemNodeSecondary: []
      },
      itemType: {
        itemTypePrimary: [],
        itemTypeSecondary: []
      }
    },
    informationEngagement: {
      itemStatus: '',
      itemTag: { itemTagPrimary: '', itemTagSecondary: '' },
      itemTenure: { itemTenureDateTimeStart: '', itemTenureDateTimeEnd: '' }
    },
    informationSetup: {
      itemShareFee: 0.0,
      itemSellFee: 0.0,
      itemMoveFee: 0.0,
    },
    informationFramework: {
      itemFrameworkOne: {
        itemFrameworkOneCluster: [],
        itemFrameworkOneClusterNumber: [],
        itemFrameworkOneLevel: null,
        itemFrameworkOneBlank: '',
        itemFrameworkOneExplanation: {
          itemFrameworkOneExplanationDisplay: false,
          itemFrameworkOneExplanationMedia: null
        }, //itemDescription,
        itemFrameworkOneLabel: {
          itemFrameworkOneLabelDisplay: false,
          itemFrameworkOneLabelMedia: null
        },
        itemFrameworkOneMedia: null, //itemLabel,
        itemFrameworkOnePassage: {
          itemFrameworkOnePassageDisplay: false,
          itemFrameworkOnePassageMedia: null
        },
        itemFrameworkOnePolarity: '',
        itemFrameworkOneResponseAttachment: '',
        itemFrameworkOneResponseChoice: [],
        itemFrameworkOneResponseChoiceAlignment: '',
        itemFrameworkOneResponseChoiceSequenceAdministration:[],
        itemFrameworkOneResponseCorrect: [],
        itemFrameworkOneResponseLabel: {
          itemFrameworkOneResponseLabelDisplay: false,
          itemFrameworkOneResponseLabelMedia: null
        },
        itemFrameworkOneResponseExplanation: {
          itemFrameworkOneResponseExplanationDisplay: false,
          itemFrameworkOneResponseExplanationMedia: null
        },
        itemFrameworkOneScale: [],
        itemFrameworkOneScore: null,
        itemFrameworkOneSection: [
          {
            itemFrameworkOneSectionSequence: 1,
            itemFrameworkOne: {
              itemFrameworkOneMedia: null,
              itemFrameworkOneExplanation: {
                itemFrameworkOneExplanationDisplay: false,
                itemFrameworkOneExplanationMedia: null
              },
              itemFrameworkOneResponseLabel: {
                itemFrameworkOneResponseLabelDisplay: false,
                itemFrameworkOneResponseLabelMedia: null
              },
              itemFrameworkOneScore: null,
              itemFrameworkOneType: '',
              itemFrameworkOneResponseChoice: [
                // {
                //   itemFrameworkOneResponseChoiceColumnMatch: '',
                //   itemFrameworkOneResponseChoiceExplanation: {
                //     itemFrameworkOneResponseChoiceExplanationMedia: null,
                //     itemFrameworkOneResponseChoiceExplanationDisplay: false
                //   },
                //   itemFrameworkOneResponseChoiceMedia: null,
                //   itemFrameworkOneResponseChoiceNumber: '1',
                //   itemFrameworkOneResponseChoicePolarity: '',
                //   itemFrameworkOneResponseChoiceScore: '',
                //   itemFrameworkOneResponseChoiceWeightage: ''
                // },
                // {
                //   itemFrameworkOneResponseChoiceColumnMatch: '',
                //   itemFrameworkOneResponseChoiceExplanation: {
                //     itemFrameworkOneResponseChoiceExplanationMedia: null,
                //     itemFrameworkOneResponseChoiceExplanationDisplay: false
                //   },
                //   itemFrameworkOneResponseChoiceMedia: null, //optionLabel,
                //   itemFrameworkOneResponseChoiceNumber: '2',
                //   itemFrameworkOneResponseChoicePolarity: '',
                //   itemFrameworkOneResponseChoiceScore: '',
                //   itemFrameworkOneResponseChoiceWeightage: ''
                // },
                // {
                //   itemFrameworkOneResponseChoiceColumnMatch: '',
                //   itemFrameworkOneResponseChoiceExplanation: {
                //     itemFrameworkOneResponseChoiceExplanationMedia: null,
                //     itemFrameworkOneResponseChoiceExplanationDisplay: false
                //   },
                //   itemFrameworkOneResponseChoiceMedia: null, //optionLabel,
                //   itemFrameworkOneResponseChoiceNumber: '3',
                //   itemFrameworkOneResponseChoicePolarity: '',
                //   itemFrameworkOneResponseChoiceScore: '',
                //   itemFrameworkOneResponseChoiceWeightage: ''
                // }
              ]
            }
          }
        ],
        itemFrameworkOneSequence: '',
        itemFrameworkOneTime: 0,
        itemFrameworkOneType: '',
        itemFrameworkOneWord: {
          itemFrameworkOneWordMaximum: '',
          itemFrameworkOneWordMinimum: ''
        },
        itemFrameworkOneWeightage: null
      },
    }
  },
  itemTypeList:[],
};

const ItemCreateReducer = (istate = JSON.parse(JSON.stringify(initialState)), action) => {
  // console.log(action.type);
  switch (action.type) {
    case SET_ITEM_SINGLE_STATE:
      return {
        ...istate,
        [action.payload.stateName]: action.payload.value
      };
    case SET_TYPE_REDUCER_STATE:
      return {
        ...istate,
        itemInformation: {
          ...istate.itemInformation,
          informationBasic: action.payload
        }
      };
    case SET_ITEM_SETUP_INFO_STATE:
      return {
        ...istate,
        itemInformation: {
          ...istate.itemInformation,
          informationSetup: action.payload
        }
      };
    case SET_ITEMFRAMEWORK_REDUCER_STATE:
      return {
        ...istate,
        itemInformation: {
          ...istate.itemInformation,
          informationFramework: {
            ...istate.itemInformation.informationFramework,
            itemFrameworkOne: action.payload,
          }
        }
      };
    case SET_ITEM_DYNAMIC_SINGLE_STATE:
      return {
        ...istate,
        itemInformation: {
          ...istate.itemInformation,
          [action.payload.objectName]: {
            ...istate.itemInformation[action.payload.objectName],
            [action.payload.stateName]: {
              ...istate.itemInformation[action.payload.objectName][action.payload.stateName],
              [action.payload.actualStateName]: action.payload.value
            }
          }
        }
      };
    case SET_ITEM_FRAMEWORK_DYNAMIC_SINGLE_STATE:
      return {
        ...istate,
        itemInformation: {
          ...istate.itemInformation,
          informationFramework: {
            ...istate.itemInformation.informationFramework,
            itemFrameworkOne: {
              ...istate.itemInformation.informationFramework.itemFrameworkOne,
              [action.payload.stateName]: action.payload.value
            }
          }
        }
      };
    case SET_ITEM_FRAMEWORK_INNER_SINGLE_STATE:
      return {
        ...istate,
        itemInformation: {
          ...istate.itemInformation,
          informationFramework: {
            ...istate.itemInformation.informationFramework,
            itemFrameworkOne: {
              ...istate.itemInformation.informationFramework.itemFrameworkOne,
              [action.payload.objectName]: {
                ...istate.itemInformation.informationFramework.itemFrameworkOne[
                  action.payload.objectName
                ],
                [action.payload.actualStateName]: action.payload.value
              }
            }
          },
        }
      };
    case SET_ITEM_FRAMWORK_TYPE:
      return {
        ...istate,
            itemTypeList: action.payload
      };
    case CLEAR_ITEM_REDUCER_STATE:
      return JSON.parse(JSON.stringify(initialState));
    default:
      return istate;
  }
};

export default ItemCreateReducer;
