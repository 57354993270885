import React, { Fragment, useEffect, useState } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import Popup from '../Molecules/PopUp/PopUp';
import PopupHeader from '../Molecules/PopUp/PopUpHeader';
import { InputLabel, FormControl, Checkbox } from '@material-ui/core';
import InputFeild from '../Atoms/InputField/InputField';
import SelectField from '../Atoms/SelectField/SelectField';
import '../Molecules/PopUp/PopUp.css';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  POPUP_CLOSE,
  SET_ITEM_FRAMEWORK_DYNAMIC_SINGLE_STATE,
  SET_ASSESSMENT_FRAMEWORK_DYNAMIC_SINGLE_STATE,
  SET_ASSESSMENT_SECTION_FRAMEWORK_DYNAMIC_SINGLE_STATE
} from '../actionType';
import InfoToolTip from '../Atoms/InfoToolTip/InfoToolTip';

const PopUpItemFramework = (props) => {
  const {
    isActive,
    primaryheader,
    primaryheaderTwo = '',
    inputHeader,
    headerPanelColour,
    headerOne,
    headerOneBadgeOne,
    headerOneBadgeTwo = '',
    choiceOb = null,
    mode,
    isItemFramework = false,
    subQuestionId = '',
    subQuestionResponseChoiceId,
    responseChoiceId,
    //isAssessmentPreviewShow,
    itemFrameworkOne,
    itemFrameworkOneResponseChoice,
    assessmentClusterArr = [],
    assessmentScaleArr = []
    //itemFrameworkOneResponseChoiceSequenceAdministrationArr = []
  } = props;
  const dispatch = useDispatch();
  const { itemInformation } = useSelector((state) => state.ItemCreateReducer);
  const { itemConfigStates } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { isAssessmentPreviewShow = false, isAssessmentSectionShow = false } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  const { isItemTrial } = useSelector((state) => state.AssesseeAssignmentAssessmentReducer);

  const { informationFramework } = useSelector((state) => state.AssessmentReducer);
  const { sectionInformation } = useSelector((state) => state.SectionCreateReducer);
  const itemFrameworkOneResponseChoiceSequenceAdministrationArr =
    sectionInformation?.assessmentSectionItemDistinctReviseObject?.itemFrameworkOne
      ?.itemFrameworkOneResponseChoiceSequenceAdministration || [];
  // // if(isAssessmentPreviewShow){
  //   const itemFrameworkOneResponseChoice =isAssessmentPreviewShow?informationFramework?.assessmentSection[currentItemIndex]?.assessmentSectionItemDistinct[currentItemIndex].itemFrameworkOne:
  //   itemInformation?.informationFramework?.itemFrameworkOne?.itemFrameworkOneResponseChoice || [];
  // const itemFrameworkOne = isAssessmentPreviewShow?informationFramework?.assessmentSection[currentItemIndex]?.assessmentSectionItemDistinct[currentItemIndex].itemFrameworkOne:
  // itemInformation?.informationFramework.itemFrameworkOne;
  const itemFrameworkOneClusterNumber = itemFrameworkOne?.itemFrameworkOneClusterNumber || [];
  const itemFrameworkOneScaleNumber = itemFrameworkOne?.itemFrameworkOneScaleNumber || [];
  const itemFrameworkOneResponseChoiceSequenceAdministration =
    itemFrameworkOne?.itemFrameworkOneResponseChoiceSequenceAdministration || [];

  const [blank, setBlank] = useState('');
  const [classification, setclassification] = useState([]);
  const [level, setlevel] = useState(null);
  const [polarity, setpolarity] = useState('');
  const [score, setscore] = useState(0);
  const [scale, setscale] = useState('');
  const [scaleId, setscaleId] = useState('');
  const [time, settime] = useState('');
  const [weightage, setweightage] = useState('');
  const [responseLabel, setresponseLabel] = useState('');
  // console.log('props', props);
  console.log('subQuestionId', subQuestionId);
  console.log('subQuestionResponseChoiceId', subQuestionResponseChoiceId);
  useEffect(() => {
    let cluster = [];
    let subques = [];
    let subResponse = [];
    if (isItemFramework) {
      if (subQuestionId) {
        subques = itemFrameworkOne.itemFrameworkOneSection.filter(function (sub) {
          return sub.itemFrameworkOneSectionSequence === subQuestionId;
        });
        setscore(subques[0]?.itemFrameworkOne?.itemFrameworkOneScore);
        settime(subques[0]?.itemFrameworkOne?.itemFrameworkOneTime);
      }
      if (subQuestionResponseChoiceId && subques.length > 0) {
        subques = itemFrameworkOne.itemFrameworkOneSection.filter(function (sub) {
          return sub.itemFrameworkOneSectionSequence === subQuestionId;
        });
        subResponse = subques.itemFrameworkOneResponseChoice.filter(function (sub) {
          return parseInt(sub.itemFrameworkOneResponseChoiceNumber) === subQuestionId;
        });
        setscore(subResponse[0]?.itemFrameworkOneResponseChoiceScore);
        setweightage(subResponse[0]?.itemFrameworkOneResponseChoiceWeightage);
      } else {
        setscore(itemFrameworkOne?.itemFrameworkOneScore);
      }
      if (itemFrameworkOneClusterNumber.length > 0) {
        itemFrameworkOneClusterNumber.map((cc, index) => {
          let cust = assessmentClusterArr.filter((dd) => {
            return cc.assessmentClusterId === dd.id;
          });
          cluster.push(cust[0].assessmentClusterName);
        });
      }
      if (itemFrameworkOneScaleNumber) {
        assessmentScaleArr.map((cc, index) => {
          if (itemFrameworkOneScaleNumber === cc.id) {
            setscale(cc.assessmentScaleName);
            setscaleId(cc.id);
          }
        });
      }
      setclassification(cluster);
      setlevel(itemFrameworkOne?.itemFrameworkOneLevel);
      setpolarity(itemFrameworkOne?.itemFrameworkOnePolarity);
      settime(itemFrameworkOne?.itemFrameworkOneTime);
    } else if (subQuestionId !== '' && subQuestionResponseChoiceId !== '') {
      subques = itemFrameworkOne.itemFrameworkOneSection.filter(function (sub) {
        return sub.itemFrameworkOneSectionSequence === subQuestionId;
      });
      if (subques.length > 0) {
        subResponse = subques[0].itemFrameworkOne?.itemFrameworkOneResponseChoice.filter(function (
          sub
        ) {
          return parseInt(sub.itemFrameworkOneResponseChoiceNumber) === subQuestionResponseChoiceId;
        });
        console.log('subques', subques);
        console.log('subResponse', subResponse);
        setscore(subResponse[0]?.itemFrameworkOneResponseChoiceScore);
        setweightage(subResponse[0]?.itemFrameworkOneResponseChoiceWeightage);
      }
    } else {
      if (itemFrameworkOneResponseChoiceSequenceAdministration.length > 0) {
        setresponseLabel(itemFrameworkOneResponseChoiceSequenceAdministration[responseChoiceId]);
      } else if (itemFrameworkOneResponseChoiceSequenceAdministrationArr.length > 0) {
        setresponseLabel(itemFrameworkOneResponseChoiceSequenceAdministrationArr[responseChoiceId]);
      }
    }
  }, [subQuestionId, itemFrameworkOne?.itemFrameworkOneCluster]);
  const onChangeCluster = (event) => {
    const { value } = event.target;
    setclassification(typeof value === 'string' ? value.split(',') : value);
  };
  console.log('responseChoiceId', responseChoiceId);
  const handleClick = () => {
    console.log(
      blank,
      classification,
      level,
      polarity,
      score,
      scale,
      time,
      weightage,
      responseLabel,
      isItemFramework
    );
    if (isItemFramework) {
      if (isAssessmentSectionShow) {
        var reviseCluster = [];
        var reviseClusterObj = [];
        var reviseScale = [];
        var responseArr = [];
        var noOfResponse = 0;
        if (itemFrameworkOne?.itemFrameworkOneSection?.length > 0)
          noOfResponse =
            itemFrameworkOne?.itemFrameworkOneSection[0]?.itemFrameworkOne
              ?.itemFrameworkOneResponseChoice?.length || [];
        if (classification.length > 0) {
          assessmentClusterArr &&
            assessmentClusterArr.forEach((clust, index) => {
              if (classification.includes(clust.assessmentClusterName)) {
                let data = {
                  assessmentClusterId: clust.id,
                  assessmentClusterResponseChoiceCorrect: Array.from(Array(noOfResponse)).map(
                    (_, i) => '0'
                  )
                };
                reviseCluster.push(data);
                let newObj = { ...clust, ...data };
                reviseClusterObj.push(newObj);
              }
            });
        }
        if (scale !== '') {
          reviseScale=assessmentScaleArr.filter((ob)=>{return ob.id === scaleId})
          // assessmentScaleArr.map((ob) => {
          //   if (ob.id === scaleId) {
          //     reviseScale = ob;
          //   }
          // });
        }
        dispatch({
          type: SET_ASSESSMENT_SECTION_FRAMEWORK_DYNAMIC_SINGLE_STATE,
          payload: { stateName: 'itemFrameworkOneClusterNumber', value: reviseCluster }
        });
        dispatch({
          type: SET_ASSESSMENT_SECTION_FRAMEWORK_DYNAMIC_SINGLE_STATE,
          payload: { stateName: 'itemFrameworkOneCluster', value: reviseClusterObj }
        });
        dispatch({
          type: SET_ASSESSMENT_SECTION_FRAMEWORK_DYNAMIC_SINGLE_STATE,
          payload: { stateName: 'itemFrameworkOneScale', value: reviseScale }
        });
        dispatch({
          type: SET_ASSESSMENT_SECTION_FRAMEWORK_DYNAMIC_SINGLE_STATE,
          payload: { stateName: 'itemFrameworkOneScaleNumber', value: scaleId }
        });
        dispatch({
          type: SET_ASSESSMENT_SECTION_FRAMEWORK_DYNAMIC_SINGLE_STATE,
          payload: { stateName: 'itemFrameworkOneBlank', value: blank }
        });
        dispatch({
          type: SET_ASSESSMENT_SECTION_FRAMEWORK_DYNAMIC_SINGLE_STATE,
          payload: { stateName: 'itemFrameworkOneLevel', value: level }
        });
        dispatch({
          type: SET_ASSESSMENT_SECTION_FRAMEWORK_DYNAMIC_SINGLE_STATE,
          payload: { stateName: 'itemFrameworkOnePolarity', value: polarity }
        });
        dispatch({
          type: SET_ASSESSMENT_SECTION_FRAMEWORK_DYNAMIC_SINGLE_STATE,
          payload: { stateName: 'itemFrameworkOneScore', value: parseInt(score) }
        });
        dispatch({
          type: SET_ASSESSMENT_SECTION_FRAMEWORK_DYNAMIC_SINGLE_STATE,
          payload: { stateName: 'itemFrameworkOneTime', value: time }
        });
        dispatch({
          type: SET_ASSESSMENT_SECTION_FRAMEWORK_DYNAMIC_SINGLE_STATE,
          payload: { stateName: 'itemFrameworkOneWeightage', value: weightage }
        });
      } else {
        let reviseCluster = [];
        let reviseScale = [];
        if (classification.length > 0) {
          reviseCluster =
            assessmentClusterArr &&
            assessmentClusterArr.filter((clust) => {
              return classification.includes(clust.assessmentClusterName);
            });
        }
        if (subQuestionId && subQuestionResponseChoiceId === '') {
          let opArr = itemFrameworkOne?.itemFrameworkOneSection;
          console.log('opArr', opArr);
          opArr.forEach((element) => {
            if (element.itemFrameworkOneSectionSequence === subQuestionId) {
              // element.itemFrameworkOneSection.itemFrameworkOnePolarity = polarity;
              element.itemFrameworkOneSection.itemFrameworkOneWeightage = weightage;
              element.itemFrameworkOneSection.itemFrameworkOneScore = parseInt(score);
              element.itemFrameworkOneSection.itemFrameworkOneTime = parseFloat(time);
            }
          });
          dispatch({
            type: SET_ITEM_FRAMEWORK_DYNAMIC_SINGLE_STATE,
            payload: {
              stateName: 'itemFrameworkOneSection',
              value: opArr
            }
          });
        }
        if (scale !== '') {
          assessmentScaleArr.map((ob) => {
            if (ob.id === scaleId) {
              reviseScale = ob;
            }
          });
        }
        dispatch({
          type: SET_ITEM_FRAMEWORK_DYNAMIC_SINGLE_STATE,
          payload: { stateName: 'itemFrameworkOneScale', value: reviseScale }
        });
        dispatch({
          type: SET_ITEM_FRAMEWORK_DYNAMIC_SINGLE_STATE,
          payload: { stateName: 'itemFrameworkOneScaleNumber', value: scaleId }
        });
        dispatch({
          type: SET_ITEM_FRAMEWORK_DYNAMIC_SINGLE_STATE,
          payload: { stateName: 'itemFrameworkOneCluster', value: reviseCluster }
        });
        dispatch({
          type: SET_ITEM_FRAMEWORK_DYNAMIC_SINGLE_STATE,
          payload: { stateName: 'itemFrameworkOneBlank', value: blank }
        });
        dispatch({
          type: SET_ITEM_FRAMEWORK_DYNAMIC_SINGLE_STATE,
          payload: { stateName: 'itemFrameworkOneLevel', value: level }
        });
        dispatch({
          type: SET_ITEM_FRAMEWORK_DYNAMIC_SINGLE_STATE,
          payload: { stateName: 'itemFrameworkOnePolarity', value: polarity }
        });
        dispatch({
          type: SET_ITEM_FRAMEWORK_DYNAMIC_SINGLE_STATE,
          payload: { stateName: 'itemFrameworkOneScore', value: parseInt(score) }
        });
        dispatch({
          type: SET_ITEM_FRAMEWORK_DYNAMIC_SINGLE_STATE,
          payload: { stateName: 'itemFrameworkOneTime', value: time }
        });
        dispatch({
          type: SET_ITEM_FRAMEWORK_DYNAMIC_SINGLE_STATE,
          payload: { stateName: 'itemFrameworkOneWeightage', value: weightage }
        });
      }
    } else if (subQuestionId) {
      let opArr = itemFrameworkOne?.itemFrameworkOneSection;
      if (subQuestionId !== '' && subQuestionResponseChoiceId !== '') {
        opArr.forEach((element) => {
          if (element.itemFrameworkOneSectionSequence === subQuestionId) {
            element.itemFrameworkOne.itemFrameworkOneResponseChoice.forEach((ele) => {
              if (
                parseInt(ele.itemFrameworkOneResponseChoiceNumber) === subQuestionResponseChoiceId
              ) {
                ele.itemFrameworkOneResponseChoiceScore = parseInt(score);
                ele.itemFrameworkOneResponseChoiceWeightage = parseInt(weightage);
              }
            });
          }
        });
      } else {
        opArr.forEach((element) => {
          if (element.itemFrameworkOneSectionSequence === subQuestionId) {
            element.itemFrameworkOne.itemFrameworkOnePolarity = polarity;
            element.itemFrameworkOne.itemFrameworkOneWeightage = weightage;
            element.itemFrameworkOne.itemFrameworkOneScore = parseInt(score);
            element.itemFrameworkOne.itemFrameworkOneTime = parseFloat(time);
          }
        });
      }

      if (isAssessmentPreviewShow) {
        dispatch({
          type: SET_ASSESSMENT_FRAMEWORK_DYNAMIC_SINGLE_STATE,
          payload: {
            stateName: 'itemFrameworkOneSection',
            value: opArr
          }
        });
      } else if (isAssessmentSectionShow) {
        dispatch({
          type: SET_ASSESSMENT_SECTION_FRAMEWORK_DYNAMIC_SINGLE_STATE,
          payload: {
            stateName: 'itemFrameworkOneSection',
            value: opArr
          }
        });
      } else {
        dispatch({
          type: SET_ITEM_FRAMEWORK_DYNAMIC_SINGLE_STATE,
          payload: {
            stateName: 'itemFrameworkOneSection',
            value: opArr
          }
        });
      }
    } else {
      if (choiceOb !== null) {
        let tempArr = itemFrameworkOneResponseChoice;
        tempArr.forEach((element) => {
          if (element.itemFrameworkOneResponseChoice === choiceOb.itemFrameworkOneResponseChoice) {
            element.itemFrameworkOneResponseChoiceScore = parseInt(score);
            element.itemFrameworkOneResponseChoiceWeightage = weightage;
            element.itemFrameworkOneResponseChoicePolarity = polarity;
          }
        });
        if (responseLabel !== '') {
          responseArr =
            itemFrameworkOne?.itemFrameworkOneResponseChoiceSequenceAdministration ||
            itemFrameworkOneResponseChoiceSequenceAdministrationArr;
          if (responseArr.length === 0) {
            itemFrameworkOneResponseChoice.map((element, ind) => {
              responseArr.push('');
            });
          }
          responseArr[responseChoiceId] = responseLabel;
        }
        // dispatch({
        //   type: SET_ASSESSMENT_SECTION_FRAMEWORK_DYNAMIC_SINGLE_STATE,
        //   payload: {
        //     stateName: 'itemFrameworkOneResponseChoiceSequenceAdministration',
        //     value: responseArr
        //   }
        // });
        if (isAssessmentPreviewShow) {
          dispatch({
            type: SET_ASSESSMENT_FRAMEWORK_DYNAMIC_SINGLE_STATE,
            payload: {
              stateName: 'itemFrameworkOneResponseChoice',
              value: tempArr
            }
          });
          dispatch({
            type: SET_ASSESSMENT_FRAMEWORK_DYNAMIC_SINGLE_STATE,
            payload: {
              stateName: 'itemFrameworkOneResponseChoiceSequenceAdministration',
              value: responseArr
            }
          });
        } else if (isAssessmentSectionShow) {
          dispatch({
            type: SET_ASSESSMENT_SECTION_FRAMEWORK_DYNAMIC_SINGLE_STATE,
            payload: {
              stateName: 'itemFrameworkOneResponseChoice',
              value: tempArr
            }
          });
          dispatch({
            type: SET_ASSESSMENT_SECTION_FRAMEWORK_DYNAMIC_SINGLE_STATE,
            payload: {
              stateName: 'itemFrameworkOneResponseChoiceSequenceAdministration',
              value: responseArr
            }
          });
        } else {
          dispatch({
            type: SET_ITEM_FRAMEWORK_DYNAMIC_SINGLE_STATE,
            payload: {
              stateName: 'itemFrameworkOneResponseChoice',
              value: tempArr
            }
          });
          dispatch({
            type: SET_ASSESSMENT_FRAMEWORK_DYNAMIC_SINGLE_STATE,
            payload: {
              stateName: 'itemFrameworkOneResponseChoiceSequenceAdministration',
              value: responseArr
            }
          });
        }
      }
    }
    dispatch({ type: POPUP_CLOSE });
  };
  return (
    <div>
      <Popup isActive={isActive}>
        <PopupHeader
          headerPanelColour={headerPanelColour}
          headerOne={headerOne}
          headerOneBadgeOne={headerOneBadgeOne}
          headerOneBadgeTwo={headerOneBadgeTwo}
          onClick={handleClick}
          mode={mode}
        />
        <DialogContent
          className={['popupContent', 'fixed10PadDim', 'revisePopupContent'].join(' ')}
        >
          <div className={'fitContent'}>
            <div className={['PopupFormBox', 'labelPopupBox', 'popupMinHei'].join(' ')}>
              <InputLabel htmlFor="name-input" className={'textForLabelPopup'}>
                <Fragment>
                  {inputHeader}&nbsp;
                  {primaryheader ? (
                    <>
                      <span className={'headerBadge'}>{primaryheader}</span>&nbsp;
                    </>
                  ) : null}
                  {primaryheaderTwo ? (
                    <span className={'headerBadge'}>{primaryheaderTwo}</span>
                  ) : null}
                </Fragment>
              </InputLabel>
              <div className={'infoSymbol'}></div>
              <div className={'infoSymbol'}>
                <InfoToolTip message="Click me, I will stay visible until you click outside." />
              </div>
            </div>
          </div>
          <FormControl style={{ width: '100%' }}>
            {isItemFramework && itemConfigStates.blankState && (
              <InputFeild
                id={'blank'}
                label={'blank'}
                value={blank}
                errorMsg={''}
                type={'number'}
                onClick={(e) => {
                  setBlank(e.target.value);
                }}
              />
            )}
            {isItemFramework && itemConfigStates.classificationState && !isItemTrial && (
              <SelectField
                tag={'cluster'}
                label={'cluster'}
                dataValue={'cluster'}
                labelBadgeOne={'distinct'}
                listSelect={assessmentClusterArr || []}
                errorMsg={() => {}}
                onChange={onChangeCluster}
                isMultiSelect={true}
                value={classification}
                mappingValue={'assessmentClusterName'}
              />
            )}
            {isItemFramework && itemConfigStates.scaleState && (
              <SelectField
                tag={'scale'}
                label={'scale'}
                dataValue={'scale'}
                labelBadgeOne={'distinct'}
                listSelect={assessmentScaleArr || []}
                errorMsg={() => {}}
                onChange={(e) => {
                  setscale(e.target.value);
                  setscaleId(e.currentTarget.getAttribute('data-key'));
                }}
                value={scale}
                mappingValue={'assessmentScaleName'}
                dataValueKey={'id'}
              />
            )}
            {isItemFramework && itemConfigStates.levelState && !isItemTrial && (
              <SelectField
                tag={'level'}
                label={'level'}
                dataValue={'level'}
                listSelect={[
                  { id: '', name: '' },
                  { id: 'High', name: 'High' },
                  { id: 'Low', name: 'Low' },
                  { id: 'Medium', name: 'Medium' }
                ]}
                errorMsg={() => {}}
                onChange={(e) => {
                  setlevel(e.target.value);
                }}
                value={level}
                mappingValue={'id'}
              />
            )}
            {itemConfigStates.polarityState && !isItemTrial && (
              <SelectField
                tag={'polarity'}
                label={'polarity'}
                dataValue={'polarity'}
                listSelect={[
                  { id: '', name: '' },
                  { id: 'Negative', name: 'Negative' },
                  { id: 'Positive', name: 'Positive' }
                ]}
                errorMsg={() => {}}
                onChange={(e) => {
                  setpolarity(e.target.value);
                }}
                value={polarity}
                mappingValue={'id'}
              />
            )}
            {itemConfigStates.scoreState && (
              <InputFeild
                id={'score'}
                label={'score'}
                value={score}
                errorMsg={''}
                type={'number'}
                onClick={(e) => {
                  setscore(e.target.value);
                }}
              />
            )}

            {isItemFramework && itemConfigStates.timeState && !isItemTrial && (
              <InputFeild
                id={'time'}
                label={'time'}
                value={time}
                type={'time'}
                errorMsg={''}
                issetTime={true}
                onClick={(e) => {
                  settime(e.target.value);
                }}
              />
            )}
            {itemConfigStates.weightageState&& !isItemTrial && (
              <InputFeild
                id={'weightage'}
                label={'weightage'}
                value={weightage}
                type={'number'}
                errorMsg={''}
                onClick={(e) => {
                  setweightage(e.target.value);
                }}
              />
            )}
            {!isItemFramework && itemConfigStates.responseLabel && (
              <InputFeild
                id={'label'}
                label={'label'}
                value={responseLabel}
                type={'text'}
                errorMsg={''}
                onClick={(e) => {
                  setresponseLabel(e.target.value);
                }}
              />
            )}
          </FormControl>
        </DialogContent>
      </Popup>
    </div>
  );
};

PopUpItemFramework.propTypes = {
  className: PropTypes.string,
  headerPanelColour: PropTypes.oneOf([
    'displayPaneLeft',
    'displayPaneCentre',
    'displayPaneRight',
    'genericOne',
    'genericTwo'
  ]),
  headerOne: PropTypes.string,
  headerOneBadgeOne: PropTypes.string,
  headerOneBadgeTwo: PropTypes.string,
  headerOneBadgeThree: PropTypes.string,
  subQuestionId: PropTypes.string,
  isActive: PropTypes.bool
};

export default PopUpItemFramework;
