import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
// import AllocationAccordian from '../Accordian/AllocationAccordian';
import Manuscript from '@material-ui/icons/Description';
import { useDispatch, useSelector } from 'react-redux';
import DisplayPanelAccordianReviewListOne from '../Accordian/DisplayPanelAccordianReviewListOne';
import DisplayPanelAccordianInformation from '../Accordian/DisplayPanelAccordianInformation';
import { Paper } from '@material-ui/core';
import { SET_DISPLAY_TWO_SINGLE_STATE, SET_POPUP_VALUE, SET_STATUS_POPUP_VALUE } from '../../actionType';
import { getRoleGroupReviewListApi } from '../../Actions/AssesseeModuleAction';
import { getPermissionStr } from '../../Actions/GenericActions';

const DisplayPaneThreeSectionOneAssociateRole = () => {
  // const [listExpand, setListExpand] = useState('');
  const dispatch = useDispatch();
  const { responseObject, reviewMode } = useSelector((state) => state.DisplayPaneThreeReducer);
  const { selectedAssociateInfo } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { informationEngagement, informationAllocation, informationSetup } = responseObject;
  function capitalizeFirstLetter(string) {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  let associateRoleGroupList = [];
  const tempRoleGroup = informationAllocation?.associateRoleGroup;
  if (tempRoleGroup) {
    associateRoleGroupList.push({
      id: tempRoleGroup?.id || '',
      textOne: tempRoleGroup?.informationBasic?.associateRoleGroupName || '',
      textTwo: tempRoleGroup?.informationBasic?.associateRoleGroupDescription || '',
      status: ''
    });
  }
  
  const list3 = [
    {
      id: 'a1',
      labelTextOneOne: 'log',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'all',
          textOne: ''
        },
        {
          labelTextOneOneBadge: 'key',
          textOne: ''
        }
      ],
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false,
      IconOne: Manuscript
    },
    {
      id: 'a2',
      textOneOne:
        capitalizeFirstLetter(informationEngagement?.associateRoleStatus) || 'No Information',
      labelTextOneOne: 'status',
      innerAssociateList: [],
      innerInfo: 'No Information',
      isListCard: false
    },
    {
      id: 'a3',
      labelTextOneOne: 'tag',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'primary',
          textOne:
            informationEngagement?.associateRoleTag?.associateRoleTagPrimary || 'No Information'
        },
        {
          labelTextOneOneBadge: 'secondary',
          textOne:
            informationEngagement?.associateRoleTag?.associateRoleTagSecondary || 'No Information'
        }
      ],
      innerAssociateList: [],
      innerInfo: 'No Information',
      isListCard: false
    },
    {
      id: 'a4',
      labelTextOneOne: 'tenure',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'start',
          textOne:
            informationEngagement?.associateRoleTenure?.associateRoleTenureDateTimeStart ||
            'No Information'
        },
        {
          labelTextOneOneBadge: 'end',
          textOne:
            informationEngagement?.associateRoleTenure?.associateRoleTenureDateTimeEnd ||
            'No Information'
        }
      ],
      innerAssociateList: [],
      innerInfo: 'Assessee',
      isListCard: false
    }
  ];
  const getPermissionStr2 = (permissionObj) => {
    // console.log('permissionObj', permissionObj);
    let per = '';
    if (permissionObj) {
      Object.keys(permissionObj).map(function (key, val) {
        if (typeof permissionObj[key] === 'boolean' && permissionObj[key] === true) {
          per = per !== '' ? per + ', ' + splitCamelCaseToString(key) : splitCamelCaseToString(key);
        }
      });
    }
    return per;
  };

  let assesseeSelf = getPermissionStr2(
    informationSetup?.associateRolePermission?.associateAssesseeSelfPermission
  );
  let associateSelf = getPermissionStr2(
    informationSetup?.associateRolePermission?.associateAssociateSelfPermission
  );

  let assesseePermission = getPermissionStr(
    informationSetup?.associateRolePermission?.associateAssesseeDistinctPermission
  );
  let assesseeGroupBespokePermission = getPermissionStr(
    informationSetup?.associateRolePermission?.associateAssesseeGroupBespokePermission
  );
  let assesseeGroupGenericPermission = getPermissionStr(
    informationSetup?.associateRolePermission?.associateAssesseeGroupGenericPermission
  );
  let assesseeRoleBespokePermission = getPermissionStr(
    informationSetup?.associateRolePermission?.associateAssesseeRoleBespokePermission
  );
  let assesseeRoleGenericPermission = getPermissionStr(
    informationSetup?.associateRolePermission?.associateAssesseeRoleGenericPermission
  );
  let assesseeTypeBespokePermission = getPermissionStr(
    informationSetup?.associateRolePermission?.associateAssesseeTypeBespokePermission
  );
  let assesseeTypeGenericPermission = getPermissionStr(
    informationSetup?.associateRolePermission?.associateAssesseeTypeGenericPermission
  );
  let assessmentPermission = getPermissionStr(
    informationSetup?.associateRolePermission?.associateAssessmentDistinctPermission
  );
  let assessmentGroupBespokePermission = getPermissionStr(
    informationSetup?.associateRolePermission?.associateAssessmentGroupBespokePermission
  );
  let assessmentGroupGenericPermission = getPermissionStr(
    informationSetup?.associateRolePermission?.associateAssessmentGroupGenericPermission
  );
  let assessmentTypeBespokePermission = getPermissionStr(
    informationSetup?.associateRolePermission?.associateAssessmentTypeBespokePermission
  );
  let assessmentTypeGenericPermission = getPermissionStr(
    informationSetup?.associateRolePermission?.associateAssessmentTypeGenericPermission
  );
  let assignmentPermission = getPermissionStr(
    informationSetup?.associateRolePermission?.associateAssignmentDistinctPermission
  );
  let assignmentGroupBespokePermission = getPermissionStr(
    informationSetup?.associateRolePermission?.associateAssignmentGroupBespokePermission
  );
  let assignmentGroupGenericPermission = getPermissionStr(
    informationSetup?.associateRolePermission?.associateAssignmentGroupGenericPermission
  );
  let assignmentTypeBespokePermission = getPermissionStr(
    informationSetup?.associateRolePermission?.associateAssignmentTypeBespokePermission
  );
  let assignmentTypeGenericPermission = getPermissionStr(
    informationSetup?.associateRolePermission?.associateAssignmentTypeGenericPermission
  );
  let associatePermission = getPermissionStr(
    informationSetup?.associateRolePermission?.associateAssociateDistinctPermission
  );
  let associateGroupBespokePermission = getPermissionStr(
    informationSetup?.associateRolePermission?.associateAssociateGroupBespokePermission
  );
  let associateGroupGenericPermission = getPermissionStr(
    informationSetup?.associateRolePermission?.associateAssociateGroupGenericPermission
  );
  let associateNodeBepsokePermission = getPermissionStr(
    informationSetup?.associateRolePermission?.associateAssociateNodeBespokePermission
  );
  let associateNodeGenericPermission = getPermissionStr(
    informationSetup?.associateRolePermission?.associateAssociateNodeGenericPermission
  );
  let associateRoleBepsokePermission = getPermissionStr(
    informationSetup?.associateRolePermission?.associateAssociateRoleBespokePermission
  );
  let associateRoleGenericPermission = getPermissionStr(
    informationSetup?.associateRolePermission?.associateAssociateRoleGenericPermission
  );
  let associateTypeBepsokePermission = getPermissionStr(
    informationSetup?.associateRolePermission?.associateAssociateTypeBespokePermission
  );
  let associateTypeGenericPermission = getPermissionStr(
    informationSetup?.associateRolePermission?.associateAssociateTypeGenericPermission
  );
  let iguruAnalyticPermission = getPermissionStr(
    informationSetup?.associateRolePermission?.associateiGuruAnalyticDistinctPermission
  );
  let iguruAnalyticGroupBespokePermission = getPermissionStr(
    informationSetup?.associateRolePermission?.associateiGuruAnalyticGroupBespokePermission
  );
  let iguruAnalyticGroupGenericPermission = getPermissionStr(
    informationSetup?.associateRolePermission?.associateiGuruAnalyticGroupGenericPermission
  );
  let iguruAnalyticTypeBespokePermission = getPermissionStr(
    informationSetup?.associateRolePermission?.associateiGuruAnalyticTypeBespokePermission
  );
  let iguruAnalyticTypeGenericPermission = getPermissionStr(
    informationSetup?.associateRolePermission?.associateiGuruAnalyticTypeGenericPermission
  );
  let itemPermission = getPermissionStr(
    informationSetup?.associateRolePermission?.associateItemDistinctPermission
  );
  let itemGroupBespokePermission = getPermissionStr(
    informationSetup?.associateRolePermission?.associateItemGroupBespokePermission
  );
  let itemGroupGenericPermission = getPermissionStr(
    informationSetup?.associateRolePermission?.associateItemGroupGenericPermission
  );
  let itemTypeBespokePermission = getPermissionStr(
    informationSetup?.associateRolePermission?.associateItemTypeBespokePermission
  );
  let itemTypeGenericPermission = getPermissionStr(
    informationSetup?.associateRolePermission?.associateItemTypeGenericPermission
  );
  const setUpList = [
    {
      id: 's1',
      labelTextOneOne: 'assessee',
      labelTextOneOneBadges: [
        {
          labelTextTwoBadge: 'self',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'permission',
              innerLabelBadgeList: assesseeSelf || 'No Information',
            }
          ]
        }
      ],
      innerInfo: 'No',
      isListCard: false,
      isReviewLink: false,
      isMultiInfoCard: true
    },
    {
      id: 's2',
      labelTextOneOne: 'associate',
      labelTextOneOneBadges: [
        {
          labelTextTwoBadge: 'self',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'permission',
              innerLabelBadgeList: associateSelf || 'No Information',
            }
          ]
        }
      ],
      innerInfo: 'No',
      isListCard: false,
      isReviewLink: false,
      isMultiInfoCard: true
    },
    {
      id: 'a1',
      labelTextOneOne: 'assessees',
      labelTextOneOneBadges: [
        {
          labelTextTwoBadge: 'distinct',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'permission',
              innerLabelBadgeList: assesseePermission || 'No Information'
            }
          ]
        },
        {
          labelTextTwoBadge: 'group',
          innerLabelBadgeList: [
            //assesseePermission || 'No Information'
            // labelTextTwoBadge: 'permission',
            // innerLabelBadgeList: assesseePermission || 'No Information'
            {
              labelTextTwoBadge: 'bespoke',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assesseeGroupBespokePermission || 'No Information'
                }
              ]
            },
            {
              labelTextTwoBadge: 'generic',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assesseeGroupGenericPermission || 'No Information'
                }
              ]
            }
          ]
        },
        // {
        //   labelTextTwoBadge: 'manager',
        //   innerLabelBadgeList: [
        //     {
        //       labelTextTwoBadge: 'permission',
        //       innerLabelBadgeList: assesseePermission || 'No Information'
        //     }
        //   ]
        // },
        {
          labelTextTwoBadge: 'role',
          innerLabelBadgeList: [
            //assesseePermission || 'No Information'
            // labelTextTwoBadge: 'permission',
            // innerLabelBadgeList: assesseePermission || 'No Information'
            {
              labelTextTwoBadge: 'bespoke',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assesseeRoleBespokePermission || 'No Information'
                }
              ]
            },
            {
              labelTextTwoBadge: 'generic',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assesseeRoleGenericPermission || 'No Information'
                }
              ]
            }
          ]
          // innerLabelBadgeList: [
          //   {
          //     labelTextTwoBadge: 'permission',
          //     innerLabelBadgeList: assesseeRoleBespokePermission || 'No Information'
          //   }
          // ]
        },
        {
          labelTextTwoBadge: 'type',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'bespoke',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assesseeTypeBespokePermission || 'No Information'
                }
              ]
            },
            {
              labelTextTwoBadge: 'generic',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assesseeTypeGenericPermission || 'No Information'
                }
              ]
            }
          ]
          // innerLabelBadgeList: [
          //   {
          //     labelTextTwoBadge: 'permission',
          //     innerLabelBadgeList: assesseeTypeBespokePermission || 'No Information'
          //   }
          // ]
        }
      ],
      innerInfo: 'No',
      isListCard: false,
      isReviewLink: false,
      isMultiInfoCard: true
    },
    {
      id: 'a-assessments',
      labelTextOneOne: 'assessments',
      labelTextOneOneBadges: [
        {
          labelTextTwoBadge: 'distinct',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'permission',
              innerLabelBadgeList: assessmentPermission || 'No Information'
            }
          ]
        },
        {
          labelTextTwoBadge: 'group',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'bespoke',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assessmentGroupBespokePermission || 'No Information'
                }
              ]
            },
            {
              labelTextTwoBadge: 'generic',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assessmentGroupGenericPermission || 'No Information'
                }
              ]
            }
          ]
          // [
          //   {
          //     labelTextTwoBadge: 'permission',
          //     innerLabelBadgeList: assessmentPermission || 'No Information'
          //   }
          // ]
        },
        // {
        //   labelTextTwoBadge: 'manager',
        //   innerLabelBadgeList: [
        //     {
        //       labelTextTwoBadge: 'permission',
        //       innerLabelBadgeList: assessmentPermission || 'No Information'
        //     }
        //   ]
        // },
        {
          labelTextTwoBadge: 'type',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'bespoke',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assessmentTypeBespokePermission || 'No Information'
                }
              ]
            },
            {
              labelTextTwoBadge: 'generic',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assessmentTypeGenericPermission || 'No Information'
                }
              ]
            }
          ]
          // innerLabelBadgeList: [
          //   {
          //     labelTextTwoBadge: 'permission',
          //     innerLabelBadgeList: assessmentPermission || 'No Information'
          //   }
          // ]
        }
      ],
      innerInfo: 'No',
      isListCard: false,
      isReviewLink: false,
      isMultiInfoCard: true
    },
    {
      id: 'a-assignments',
      labelTextOneOne: 'assignments',
      labelTextOneOneBadges: [
        {
          labelTextTwoBadge: 'distinct',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'permission',
              innerLabelBadgeList: assignmentPermission || 'No Information'
            }
          ]
        },
        {
          labelTextTwoBadge: 'group',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'bespoke',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assignmentGroupBespokePermission || 'No Information'
                }
              ]
            },
            {
              labelTextTwoBadge: 'generic',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assignmentGroupGenericPermission || 'No Information'
                }
              ]
            }
          ]
          // innerLabelBadgeList: [
          //   {
          //     labelTextTwoBadge: 'permission',
          //     innerLabelBadgeList: assignmentPermission || 'No Information'
          //   }
          // ]
        },
        // {
        //   labelTextTwoBadge: 'manager',
        //   innerLabelBadgeList: [
        //     {
        //       labelTextTwoBadge: 'permission',
        //       innerLabelBadgeList: assignmentPermission || 'No Information'
        //     }
        //   ]
        // },
        {
          labelTextTwoBadge: 'type',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'bespoke',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assignmentTypeBespokePermission || 'No Information'
                }
              ]
            },
            {
              labelTextTwoBadge: 'generic',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assignmentTypeGenericPermission || 'No Information'
                }
              ]
            }
          ]
          // innerLabelBadgeList: [
          //   {
          //     labelTextTwoBadge: 'permission',
          //     innerLabelBadgeList: assignmentPermission || 'No Information'
          //   }
          // ]
        }
      ],
      innerInfo: 'No',
      isListCard: false,
      isReviewLink: false,
      isMultiInfoCard: true
    },
    {
      id: 'a-associates',
      labelTextOneOne: 'associates',
      labelTextOneOneBadges: [
        {
          labelTextTwoBadge: 'distinct',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'permission',
              innerLabelBadgeList: associatePermission || 'No Information'
            }
          ]
        },
        {
          labelTextTwoBadge: 'group',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'bespoke',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: associateGroupBespokePermission || 'No Information'
                }
              ]
            },
            {
              labelTextTwoBadge: 'generic',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: associateGroupGenericPermission || 'No Information'
                }
              ]
            }
          ]
          // innerLabelBadgeList: [
          //   {
          //     labelTextTwoBadge: 'permission',
          //     innerLabelBadgeList: associatePermission || 'No Information'
          //   }
          // ]
        },
        // {
        //   labelTextTwoBadge: 'manager',
        //   innerLabelBadgeList: [
        //     {
        //       labelTextTwoBadge: 'permission',
        //       innerLabelBadgeList: associatePermission || 'No Information'
        //     }
        //   ]
        // },
        {
          labelTextTwoBadge: 'node',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'bespoke',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: associateNodeBepsokePermission || 'No Information'
                }
              ]
            },
            {
              labelTextTwoBadge: 'generic',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: associateNodeGenericPermission || 'No Information'
                }
              ]
            }
          ]
          // innerLabelBadgeList: [
          //   {
          //     labelTextTwoBadge: 'permission',
          //     innerLabelBadgeList: associatePermission || 'No Information'
          //   }
          // ]
        },
        {
          labelTextTwoBadge: 'role',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'bespoke',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: associateRoleBepsokePermission || 'No Information'
                }
              ]
            },
            {
              labelTextTwoBadge: 'generic',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: associateRoleGenericPermission || 'No Information'
                }
              ]
            }
          ]
          // innerLabelBadgeList: [
          //   {
          //     labelTextTwoBadge: 'permission',
          //     innerLabelBadgeList: associatePermission || 'No Information'
          //   }
          // ]
        },
        {
          labelTextTwoBadge: 'type',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'bespoke',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: associateTypeBepsokePermission || 'No Information'
                }
              ]
            },
            {
              labelTextTwoBadge: 'generic',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: associateTypeGenericPermission || 'No Information'
                }
              ]
            }
          ]
          // innerLabelBadgeList: [
          //   {
          //     labelTextTwoBadge: 'permission',
          //     innerLabelBadgeList: associatePermission || 'No Information'
          //   }
          // ]
        }
      ],
      innerInfo: 'No',
      isListCard: false,
      isReviewLink: false,
      isMultiInfoCard: true
    },
    {
      id: 'a-classification',
      textOneOne:
        informationSetup?.associateRoleClassification?.associateRoleClassificationPrimary ||
        'No Information',
      labelTextOneOne: 'classification',
      innerAssociateList: [],
      innerInfo: 'No Information',
      isListCard: false
    },
    {
      id: 'a-iGuru analytics',
      labelTextOneOne: 'iGuru analytics',
      labelTextOneOneBadges: [
        {
          labelTextTwoBadge: 'distinct',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'permission',
              innerLabelBadgeList: iguruAnalyticPermission || 'No Information'
            }
          ]
        },
        {
          labelTextTwoBadge: 'group',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'bespoke',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: iguruAnalyticGroupBespokePermission || 'No Information'
                }
              ]
            },
            {
              labelTextTwoBadge: 'generic',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: iguruAnalyticGroupGenericPermission || 'No Information'
                }
              ]
            }
          ]
          // innerLabelBadgeList: [
          //   {
          //     labelTextTwoBadge: 'permission',
          //     innerLabelBadgeList: iguruAnalyticPermission || 'No Information'
          //   }
          // ]
        },
        // {
        //   labelTextTwoBadge: 'manager',
        //   innerLabelBadgeList: [
        //     {
        //       labelTextTwoBadge: 'permission',
        //       innerLabelBadgeList: iguruAnalyticPermission || 'No Information'
        //     }
        //   ]
        // },
        {
          labelTextTwoBadge: 'type',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'bespoke',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: iguruAnalyticTypeBespokePermission || 'No Information'
                }
              ]
            },
            {
              labelTextTwoBadge: 'generic',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: iguruAnalyticTypeGenericPermission || 'No Information'
                }
              ]
            }
          ]
          // innerLabelBadgeList: [
          //   {
          //     labelTextTwoBadge: 'permission',
          //     innerLabelBadgeList: iguruAnalyticPermission || 'No Information'
          //   }
          // ]
        }
      ],
      innerInfo: 'No',
      isListCard: false,
      isReviewLink: false,
      isMultiInfoCard: true
    },
    {
      id: 'a-items',
      labelTextOneOne: 'items',
      labelTextOneOneBadges: [
        {
          labelTextTwoBadge: 'distinct',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'permission',
              innerLabelBadgeList: itemPermission || 'No Information'
            }
          ]
        },
        {
          labelTextTwoBadge: 'group',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'bespoke',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: itemGroupBespokePermission || 'No Information'
                }
              ]
            },
            {
              labelTextTwoBadge: 'generic',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: itemGroupGenericPermission || 'No Information'
                }
              ]
            }
          ]
          // innerLabelBadgeList: [
          //   {
          //     labelTextTwoBadge: 'permission',
          //     innerLabelBadgeList: itemPermission || 'No Information'
          //   }
          // ]
        },
        // {
        //   labelTextTwoBadge: 'manager',
        //   innerLabelBadgeList: [
        //     {
        //       labelTextTwoBadge: 'permission',
        //       innerLabelBadgeList: itemPermission || 'No Information'
        //     }
        //   ]
        // },
        {
          labelTextTwoBadge: 'type',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'bespoke',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: itemTypeBespokePermission || 'No Information'
                }
              ]
            },
            {
              labelTextTwoBadge: 'generic',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: itemTypeGenericPermission || 'No Information'
                }
              ]
            }
          ]
          // innerLabelBadgeList: [
          //   {
          //     labelTextTwoBadge: 'permission',
          //     innerLabelBadgeList: itemPermission || 'No Information'
          //   }
          // ]
        }
      ],
      innerInfo: 'No',
      isListCard: false,
      isReviewLink: false,
      isMultiInfoCard: true
    }
  ];
  // const setUpList = [
  //   {
  //     id: 'a2',
  //     labelTextOneOne: 'permission',
  //     labelTextOneOneBadgeOne: '',
  //     labelTextOneOneBadgeTwo: '',
  //     labelTextOneOneBadgeThree: '',
  //     labelTextOneOneBadgeFour: '',
  //     labelTextOneOneBadges: [
  //       {
  //         labelTextOneOneBadge: '',
  //         innerList: [
  //           {
  //             id: 'associate1',
  //             textOne: 'Simple Sample 01',
  //             textTwo: '',
  //             status: 'active'
  //           },
  //           {
  //             id: 'associate2',
  //             textOne: 'Simple Sample 02',
  //             textTwo: '',
  //             status: 'active'
  //           },
  //           {
  //             id: 'associate3',
  //             textOne: 'Simple Sample 03',
  //             textTwo: '',
  //             status: 'active'
  //           }
  //         ]
  //       }
  //     ],
  //     innerInfo: 'No Information',
  //     isListCard: true,
  //     isReviewLink: true
  //   }
  // ];
  function splitCamelCaseToString(s) {
    return s
      .split(/(?=[A-Z])/)
      .map(function (p) {
        return p.charAt(0).toUpperCase() + p.slice(1);
      })
      .join(' ');
  }
  const reviseSetUp = (e, selectedBadgeArray) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    let badgeName = '';
    // console.log('labelName', labelName);
    // console.log('selectedBadgeName', selectedBadgeName);
    if (selectedBadgeArray?.length > 0) {
      selectedBadgeArray.forEach((element) => {
        badgeName = badgeName + element.labelTextTwoBadge.charAt(0).toUpperCase() + element.labelTextTwoBadge.slice(1);
      });
    }
    // console.log(labelName, '+labelName+', selectedBadgeArray, '=Array=', selectedBadgeName);
    
    if (labelName === 'classification') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ROLEGROUPPOPUP', popupMode: 'associatesROLECREATE' }
      });
    }if(labelName!=='' && selectedBadgeName!==''&& badgeName!==''  ){
      let lname = labelName;
      if(lname === 'assessee' || lname === 'associate'){
        lname=lname+'e';
      }
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'permissionStateOne', value: lname }
      });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'permissionStateTwo', value: selectedBadgeName }
      });
      let selectedLabel='';
      if(lname ==='iGuru analytics'){
        selectedLabel='iGuruAnalytic'+badgeName.charAt(0).toUpperCase() + badgeName.slice(1)
      }
      else{
        selectedLabel = lname.charAt(0).toUpperCase() + lname.slice(1).slice(0, -1) + '' + badgeName.charAt(0).toUpperCase() + badgeName.slice(1);
      }
      // console.log('selectedLabel', selectedLabel);
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: {
          stateName: 'permissionStateThree',
          value: 'associate' + selectedLabel + 'Permission'
        }
      });
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'PERMISSIONPOPUP', popupMode: 'associatesROLECREATE' }
      });
    }
  }
  // const reviseSetUp = (e, data, selectedBadge) => {
  //   //const labelName = e.currentTarget.getAttribute('data-value');
  //   let selected = data ? data[0] : null;
  //   if (
  //     labelName !== '' &&
  //     (selected?.labelTextTwoBadge === 'distinct' ||
  //       selected?.labelTextTwoBadge === 'group' ||
  //       selected?.labelTextTwoBadge === 'manager' ||
  //       selected?.labelTextTwoBadge === 'node' ||
  //       selected?.labelTextTwoBadge === 'role' ||
  //       selected?.labelTextTwoBadge === 'type') &&
  //     selectedBadge?.labelTextTwoBadge === 'permission'
  //   ) {
  //     dispatch({
  //       type: SET_DISPLAY_TWO_SINGLE_STATE,
  //       payload: { stateName: 'permissionStateOne', value: labelName }
  //     });
  //     dispatch({
  //       type: SET_DISPLAY_TWO_SINGLE_STATE,
  //       payload: { stateName: 'permissionStateTwo', value: selected?.labelTextTwoBadge }
  //     });
  //     let camelCaseStr =
  //       labelName === 'iGuru analytics'
  //         ? 'iGuruAnalytic'
  //         : splitCamelCaseToString(labelName).slice(0, -1);
  //     dispatch({
  //       type: SET_DISPLAY_TWO_SINGLE_STATE,
  //       payload: {
  //         stateName: 'permissionStateThree',
  //         value: 'associate' + camelCaseStr + 'Permission'
  //       }
  //     });
  //     dispatch({
  //       type: SET_POPUP_VALUE,
  //       payload: { isPopUpValue: 'PERMISSIONPOPUP', popupMode: 'associatesROLECREATE' }
  //     });
  //   }
  //   else if (labelName === 'classification') {
  //     dispatch({
  //       type: SET_POPUP_VALUE,
  //       payload: { isPopUpValue: 'ROLEGROUPPOPUP', popupMode: 'associatesROLECREATE' }
  //     });
  //   }
  // };
  const reviseAllocation = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    console.log('=====>', labelName);
    if (labelName === 'group') {
      getRoleGroupReviewListApi(selectedAssociateInfo, dispatch, 'associates');
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ROLEGROUPPOPUP', popupMode: 'associatesROLECREATE' }
      });
    }
  };

  const reviseEngagement = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    console.log('=====>', labelName);
    if (labelName === 'status') {
      dispatch({
        type: SET_STATUS_POPUP_VALUE,
        payload:informationEngagement 
        // payload: capitalizeFirstLetter(informationEngagement?.associateRoleStatus)
      });
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'STATUSPOPUP', popupMode: 'associatesROLECREATE' }
      });
    }
    if (labelName === 'tag') {
      if (selectedBadgeName === 'primary') {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'TAGREADONLYPRIMARYPOPUP', popupMode: 'associatesROLECREATE' }
        });
      }
      if (selectedBadgeName === 'secondary') {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'TAGSECONDARYPOPUP', popupMode: 'associatesROLECREATE' }
        });
      }
    }
    if (labelName === 'tenure') {
      if (selectedBadgeName === 'start') {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'TENURESATRTDATEPOPUP', popupMode: 'associatesROLECREATE' }
        });
      }
      if (selectedBadgeName === 'end') {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'TENUREENDDATEPOPUP', popupMode: 'associatesROLECREATE' }
        });
      }
    }
  };
  return (
    <div>
      <>
        <div className={'containerPadding'}>
          <Paper className={'dossierContainerTop'}>
            {list3.map((ob) => {
              return (
                <div key={ob.id}>
                  {ob.isListCard ? (
                    <DisplayPanelAccordianReviewListOne
                      className=""
                      accordianObject={ob}
                      mode={reviewMode}
                      onClickRevise={reviseEngagement}
                    />
                  ) : (
                    <DisplayPanelAccordianInformation accordianObject={ob} mode={reviewMode}
                    onClickRevise={reviseEngagement} />
                  )}
                </div>
              );
            })}
          </Paper>
        </div>
        <div className={'containerPadding'}>
          <Paper className={'dossierContainerTop'}>
            {setUpList.map((ob) => {
              return (
                <div key={ob.id}>
                  {ob.isListCard ? (
                    <DisplayPanelAccordianReviewListOne
                      className=""
                      accordianObject={ob}
                      mode={reviewMode}
                      onClickRevise={reviseSetUp}
                    />
                  ) : (
                    <DisplayPanelAccordianInformation
                      accordianObject={ob}
                      mode={reviewMode}
                      onClickRevise={reviseSetUp}
                    />
                  )}
                </div>
              );
            })}
          </Paper>
        </div>
      </>
      {isMobile && (
        <div className={'containerPadding'} style={{ height: '55px' }}>
          {' '}
        </div>
      )}
    </div>
  );
};

export default DisplayPaneThreeSectionOneAssociateRole;
