import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ASSOCIATE_ALLOCATE_SAGA,
  ASSOCIATE_DISTINCT_SHARE_SAGA,
  ASSOCIATE_POPUP_CLOSE,
  ASSOCIATE_REVIEW_DISTINCT_SAGA,
  CLEAR_DISPLAY_PANE_THREE,
  FILTERMODE,
  FILTERMODE_ENABLE,
  GET_ASSOCIATE_INFO_SAGA,
  LOADER_START,
  POPUP_OPEN,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_PAGE_COUNT,
  SET_POPUP_STATE,
  SET_REQUEST_OBJECT,
  SHARE_GROUPS_ROLES_TYPES_SAGA
} from '../actionType';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import { FilterList } from '@material-ui/icons';
import Check from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import CrossIcon from '@material-ui/icons/Clear';
import ReviseIcon from '@material-ui/icons/RadioButtonChecked';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import {
  getDistinctShareApicall,
  getDistinctShareDistinctApicall,
  getPopUpOptionList,
  makeAssociateReviewListRequestObject
} from '../Actions/GenericActions';
import { assesseeStatus } from '../Actions/StatusAction';
import { ASSOCIATE_DISTINCT_REVIEW_LIST_POPUP_OPTION } from '../PopUpConfig';
import PopUpDisplayPaneTwoReviewList from '../PopUpDisplayPanel/PopUpDisplayPaneTwoReviewList';
import {
  onClickCheckBoxSelection,
  onClickFlagSelection,
  setFlagedArray
} from '../Actions/AssesseeModuleAction';
import ListLoader from '../Atoms/ListLoader/ListLoader';
const AssociateDistinctReviewList = (props) => {
  const { popupAllClose } = props;
  const dispatch = useDispatch();
  // const { secondaryOptionCheckValue } = useSelector((state) => state.AssociateCreateReducer);
  const {
    numberPage,
    scanCount,
    countPage,
    middlePaneHeader,
    middlePaneHeaderBadgeOne,
    middlePaneHeaderBadgeTwo,
    reviewListDistinctData,
    reviewListReqObj,
    middlePaneSelectedValue,
    selectedAssociateInfo,
    isSelectActive,
    selectedTagsArray,
    unselectedTagsArray,
    selectedFlagedArray,
    unselectedFlagedArray,
    allocatedTagsArray,
    allocateStr,
    flagedValue,
    signedAssesseePermission,
    previousList,
    isDistinct,
    tempStr,
    isListLoading
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { FilterModeEnable, FilterMode } = useSelector((state) => state.FilterReducer);
  const {
    isPopUpValue,
    selectedTagValue,
    popupHeaderOneBadgeOne,
    secondaryOptionCheckValue: popUpSecondaryOptionCheckValue
  } = useSelector((state) => state.PopUpReducer);
  // const [isFetching, setIsFetching] = useState(false);
  // useEffect(() => {
  //   document.getElementById('middleComponentId').addEventListener('scroll', handleScroll);
  // }, []);
  // const handleScroll = (event) => {
  //   var targetPt = event.target;
  //   if (
  //     Math.ceil(targetPt.scrollHeight - targetPt.scrollTop) !== targetPt.clientHeight ||
  //     isFetching
  //   )
  //     return;
  //   setIsFetching(true);
  //   console.log(isFetching);
  // };
  const fetchData = async () => {
    if (reviewListDistinctData.length < scanCount && FilterMode !== 'associateDistinctShare') {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isListLoading', value: true }
      })
      let obj = {
        ...reviewListReqObj.requestObject,
        numberPage: numberPage + 1
      };
      dispatch({
        type: ASSOCIATE_REVIEW_DISTINCT_SAGA,
        payload: {
          request: { module: 'Associate-Distinct', action: 'Review-List', requestObject: obj },
          HeaderOne: middlePaneHeader,
          BadgeOne: 'distinct',
          BadgeTwo: middlePaneHeaderBadgeTwo,
          isMiddlePaneList: true,
          isSelectActive,
          selectedTagsArray
          // BadgeOne: 'distinct',
          // BadgeTwo: secondaryOptionCheckValue,
        }
      });
      dispatch({ type: SET_PAGE_COUNT, payload: numberPage + 1 });
    }
  };
  // useEffect(() => {
  //   console.log(reviewListDistinctData);
  //   if (!isFetching) return;
  //   fetchMoreListItems();
  // }, [isFetching]);

  // const fetchMoreListItems = () => {
  //   fetchData();
  //   setIsFetching(false);
  // };
  useEffect(() => {
    setFlagedArray(reviewListDistinctData, 'associateFlag', dispatch);
  }, [reviewListDistinctData]);

  const siftApiCall = (siftKey) => {
    let requestObect = makeAssociateReviewListRequestObject(
      selectedAssociateInfo,
      siftKey,
      0,
      countPage
    );
    dispatch({ type: LOADER_START });
    dispatch({ type: SET_PAGE_COUNT, payload: 0 });
    dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
    dispatch({
      type: ASSOCIATE_REVIEW_DISTINCT_SAGA,
      payload: {
        request: requestObect,
        BadgeOne: 'distinct',
        BadgeTwo: siftKey,
        isSelectActive: isSelectActive,
        selectedTagsArray: selectedTagsArray
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'typeOfMiddlePaneList', value: '' }
    });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
    dispatch({ type: ASSOCIATE_POPUP_CLOSE });
    document.getElementById('middleComponentId').scrollTop = '0px';
  };
  const onClickFooter = (e) => {
    let siftValue = e.currentTarget.getAttribute('data-value');
    if (
      siftValue === 'suspended' ||
      siftValue === 'terminated' ||
      siftValue === 'disapproved' ||
      siftValue === 'unapproved' ||
      siftValue === 'unconfirmed'
    )
      siftApiCall(siftValue);
    else if (siftValue === 'finish') {
      console.log('allocateStr', allocateStr);
      let distinctAllocateStr =
        allocateStr === 'associatesdistinct'
          ? 'associateDistinct'
          : allocateStr === 'associatesgroups'
            ? 'associateGroup'
            : allocateStr === 'associatestypes'
              ? 'associateType'
              : allocateStr === 'associatesnodes'
                ? 'associateNode'
                : allocateStr === 'associatenodes'
                  ? 'associateNode'
                  : allocateStr === 'associatesroles'
                    ? 'associateRole'
                    : '';

      if (distinctAllocateStr !== '' && selectedTagsArray.length !== 0) {
        let request = {};
        if (
          distinctAllocateStr === 'associateGroup' ||
          distinctAllocateStr === 'associateDistinct' ||
          distinctAllocateStr === 'associateType' ||
          distinctAllocateStr === 'associateRole' ||
          distinctAllocateStr === 'associateNode'
        ) {
          request = {
            module: 'Associate-Distinct',
            action: FilterMode.toLowerCase().includes('unallocate') ? 'Unallocate' : 'Allocate',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              associateDistinctAllocate: {
                [distinctAllocateStr]: allocatedTagsArray
              },
              associateDistinctAllocateInformation: {
                associateDistinct: selectedTagsArray
              }
            }
          };
          dispatch({ type: LOADER_START });
          dispatch({ type: ASSOCIATE_ALLOCATE_SAGA, payload: { request: request } });
        }
      }
    } else if (siftValue === 'cancel') {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isSelectActive', value: '' }
      });
      dispatch({
        type: FILTERMODE,
        payload: { FilterMode: '' }
      });
    } else {
      console.log(siftValue);
    }

    dispatch({ type: FILTERMODE_ENABLE });
  };
  /* for middle pane */
  const primaryIcon = [{ label: 'sift', onClick: onClickFooter, Icon: FilterList }];
  const secondaryIcon = [
    { label: 'disapproved', onClick: onClickFooter, Icon: FilterList },
    { label: 'suspended', onClick: onClickFooter, Icon: FilterList },
    { label: 'terminated', onClick: onClickFooter, Icon: FilterList },
    { label: 'unapproved', onClick: onClickFooter, Icon: FilterList },
    { label: 'unconfirmed', onClick: onClickFooter, Icon: FilterList }
  ];
  const openListPopup = (e) => {
    let associateStatus = e.currentTarget.getAttribute('status');
    let associateDistinctPermission =
      signedAssesseePermission?.associateDistinctPermission?.assesseeAssociateDistinctPermission;
    let popupContentArrValue = getPopUpOptionList(
      ASSOCIATE_DISTINCT_REVIEW_LIST_POPUP_OPTION,
      associateDistinctPermission
    );
    let tempArr = [];
    popupContentArrValue.forEach((element) => {
      if (
        (associateStatus === 'SUSPENDED' ||
          associateStatus === 'TERMINATED' ||
          associateStatus === 'ARCHIVED') &&
        (element.data === 'approve' || element.data === 'allocate' || element.data === 'revise')
      ) {
        tempArr.push({ ...element, disabled: true });
      } else if (associateStatus === 'UNAPPROVED' && element.data === 'allocate') {
        tempArr.push({ ...element, disabled: true });
      } else if (
        (associateStatus === 'UNCONFIRMED' || associateStatus === 'DISAPPROVED') &&
        (element.data === 'approve' || element.data === 'allocate')
      ) {
        tempArr.push({ ...element, disabled: true });
      } else {
        tempArr.push(element);
      }
    });
    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: 'associate',
        popupHeaderOneBadgeOne: middlePaneHeaderBadgeOne,
        isPopUpValue: '',
        popupOpenType: 'primary',
        //popupContentArrValue: ASSOCIATE_DISTINCT_REVIEW_LIST_POPUP_OPTION,
        popupContentArrValue: tempArr,
        selectedTagStatus: associateStatus,
        selectedTagValue: e.currentTarget.getAttribute('tag'),
        selectedTagStatus: e.currentTarget.getAttribute('status')
        // isFlaged: e.currentTarget.getAttribute('data-flag') === 'true' ? true : false
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: tempArr //ASSOCIATE_DISTINCT_REVIEW_LIST_POPUP_OPTION
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
  };
  const openAssociateRightPaneInformation = () => {
    console.log(selectedTagValue);
    dispatch({ type: LOADER_START });
    dispatch({
      type: GET_ASSOCIATE_INFO_SAGA,
      payload: {
        secondaryOptionCheckValue: popUpSecondaryOptionCheckValue,
        popupHeaderOneBadgeOne,
        reqBody: {
          module: 'Associate-Distinct',
          action: 'Review',
          requestObject: {
            assesseeId: selectedAssociateInfo?.assesseeId,
            associateId:
              selectedAssociateInfo?.associate?.informationEngagement.associateTag
                .associateTagPrimary, //605255729d3c823d3964e0ec
            filter: true,
            search: [
              {
                condition: 'and',
                searchBy: [
                  {
                    dataType: 'String',
                    conditionColumn: 'id',
                    conditionValue: {
                      condition: 'eq',
                      value: {
                        from: selectedTagValue
                      }
                    }
                  }
                ]
              }
            ]
          }
        }
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'middlePaneSelectedValue', value: selectedTagValue }
    });
    dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
    popupAllClose();
  };
  // console.log('reviewListDistinctData--', reviewListDistinctData);

  // infinite scroll
  const [hasMore, setHasMore] = useState(true);
  let elementRef = useRef(null);

  const onIntersection = (entries) => {
    const firstEntry = entries[0];
    reviewListDistinctData.length >= scanCount ? setHasMore(false) : setHasMore(true)
    if (firstEntry.isIntersecting && hasMore && !isListLoading) {
      fetchData();
    }
  };

  useEffect(() => {
    // console.log('####MYREF######', elementRef)
    const observer = new IntersectionObserver(onIntersection, { threshold: 0.90 });

    if (observer && elementRef.current) {
      // console.log('here at observer...', elementRef.current)
      observer.observe(elementRef.current);
    }

    return () => {
      if (observer) observer.disconnect();
    };
  }, [isListLoading, hasMore, reviewListDistinctData, selectedTagsArray, unselectedTagsArray, isSelectActive]);

  // infinite scroll end

  let signedAssociateId =
    selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary;
  return (
    <div>
      {reviewListDistinctData &&
        reviewListDistinctData.map((item, index) => {
          return (
            <div className="containerPadding" key={index} ref={elementRef}>
              <ReviewList
                className=""
                id={index}
                tag={
                  item?.informationEngagement?.associateTag
                    ? item?.informationEngagement?.associateTag?.associateTagPrimary
                    : item
                }
                isSelectedReviewList={
                  middlePaneSelectedValue ===
                    item.informationEngagement?.associateTag?.associateTagPrimary
                    ? true
                    : false
                }
                // status={assesseeStatus(
                //   middlePaneHeaderBadgeOne,
                //   item.informationEngagement?.associateStatus
                // )}
                status={item.informationEngagement?.associateStatus}
                actualStatus={item.informationEngagement?.associateStatus}
                textOne={item.informationBasic?.associateName}
                textTwo={item.informationBasic?.associateDescription}
                isTooltipActive={false}
                onClickEvent={openListPopup}
                isSelectActive={isSelectActive}
                isSelected={selectedTagsArray.includes(
                  item.informationEngagement?.associateTag?.associateTagPrimary
                )}
                onClickCheckBox={(event) => {
                  !isListLoading && onClickCheckBoxSelection(selectedTagsArray, unselectedTagsArray, event, dispatch);
                }}
                // disableonClickCheckBox={item.associateId !== signedAssociateId ? true : false}
                isFlagActive={selectedFlagedArray.includes(item.id)}
                flagedValue={flagedValue}
                onClickAddFladed={(event) => {
                  onClickFlagSelection(selectedFlagedArray, unselectedFlagedArray, event, dispatch);
                }}
              />
            </div>
          );
        })}
      {isListLoading ? <div> <ListLoader /> </div> : <div style={{ height: "60px", width: "100%" }}></div>}
      {FilterMode === 'associateDistinctinactive' && (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={primaryIcon}
          secondaryIcon={secondaryIcon}
        />
      )}
      {isPopUpValue === 'middlePaneListPopup' && (
        <PopUpDisplayPaneTwoReviewList
          isActive={true}
          onClickInformation={openAssociateRightPaneInformation}
        />
      )}
      {(FilterMode === 'allocateToAssociate' || FilterMode === 'associatesAllocateToDistinct' || FilterMode === 'associatesUnAllocateToDistinct' || FilterMode === 'unAllocateToAssociate') && (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={[{ label: FilterMode.toLowerCase().includes('unallocate') ? 'unallocate' : 'allocate', onClick: onClickFooter, Icon: ReviseIcon }]}
          secondaryIcon={[
            { label: 'cancel', onClick: onClickFooter, Icon: ClearIcon },
            { label: 'finish', onClick: onClickFooter, Icon: Check }
          ]}
        />
      )}
    </div>
  );
};
export default AssociateDistinctReviewList;
