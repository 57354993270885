import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import AllocationAccordian from '../Accordian/AllocationAccordian';
import Manuscript from '@material-ui/icons/Description';
import { useDispatch, useSelector } from 'react-redux';
import DisplayPanelAccordianReviewListOne from '../Accordian/DisplayPanelAccordianReviewListOne';
import DisplayPanelAccordianInformation from '../Accordian/DisplayPanelAccordianInformation';
import { Paper } from '@material-ui/core';
import {
  GET_ASSIGNMENT_GROUP_REVIEW_LIST_SAGA,
  GET_ASSIGNMENT_TYPE_REVIEW_LIST_SAGA,
  INTERNAL_NODE_LIST_WITH_PERMISSIONS_SAGA,
  LOADER_START,
  SET_CORE_GROUP_REVIEW_LIST_REQ_OBJECT,
  SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT,
  SET_CORE_TYPE_REVIEW_LIST_REQ_OBJECT,
  SET_POPUP_VALUE,
  SET_STATUS_POPUP_VALUE
} from '../../actionType';
import {
  makeAssignmentGroupObj,
  makeAssignmentTypeObj,
  makeInternalNodeObj
} from '../../Actions/GenericActions';

const DisplayPaneThreeSectionOneAssignment = () => {
  const [listExpand, setListExpand] = useState('');
  const dispatch = useDispatch();
  const { responseObject,  headerOneBadgeThree, reviewMode } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  const { countPage, selectedAssociateInfo, assignmentCreateType } = useSelector(
    (state) => state.DisplayPaneTwoReducer
  );
  const { informationEngagement, informationAllocation,informationSetup,informationFramework } = responseObject;
  const AssignmentReducerInfo = useSelector((state) => state.AssignmentReducer);
  function capitalizeFirstLetter(string) {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  let isOccupationType = assignmentCreateType;
  if (
    AssignmentReducerInfo?.informationAllocation?.assignmentType?.assignmentTypeSecondary.includes(
      process.env.REACT_APP_IGURU_ANALYTICS_OCCUPATION
    )
  ) {
    isOccupationType = 'iGuru Analytics_Occupation';
  }
  const allianceList = [
    {
      id: 'a342',
      labelTextOneOne: 'assessor',
      labelTextOneOneBadgeOne: 'primary',
      labelTextOneOneBadgeTwo: 'secondary',
      labelTextOneOneBadgeThree: '',
      labelTextOneOneBadgeFour: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'primary',
          innerList: [
            {
              id: 'associate1',
              textOne: 'Simple Sample 01',
              textTwo: 'assessor',
              status: 'active'
            },
            {
              id: 'associate2',
              textOne: 'Simple Sample 02',
              textTwo: 'assessor',
              status: 'active'
            },
            {
              id: 'associate3',
              textOne: 'Simple Sample 03',
              textTwo: 'assessor',
              status: 'active'
            }
          ]
        },
        {
          labelTextOneOneBadge: 'secondary',
          innerList: []
        }
      ],
      innerInfo: 'No Information',
      isListCard: true
    },
    {
      id: 'a2',
      labelTextOneOne: 'collaborator',
      labelTextOneOneBadgeOne: 'primary',
      labelTextOneOneBadgeTwo: 'secondary',
      labelTextOneOneBadgeThree: '',
      labelTextOneOneBadgeFour: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'primary',
          innerList: [
            {
              id: 'associate1',
              textOne: 'Simple Sample 01',
              textTwo: 'collaborator',
              status: 'active'
            },
            {
              id: 'associate2',
              textOne: 'Simple Sample 02',
              textTwo: 'collaborator',
              status: 'active'
            },
            {
              id: 'associate3',
              textOne: 'Simple Sample 03',
              textTwo: 'collaborator',
              status: 'active'
            }
          ]
        },
        {
          labelTextOneOneBadge: 'secondary',
          innerList: []
        }
      ],
      innerInfo: 'No Information',
      isListCard: true
    }
  ];
  let assignmentGroupListPrimary = [];
  if (
    informationAllocation?.assignmentGroup?.assignmentGroupPrimary &&
    informationAllocation?.assignmentGroup?.assignmentGroupPrimary.length > 0
  ) {
    const tempArr = informationAllocation?.assignmentGroup?.assignmentGroupPrimary;
    tempArr.forEach((ob) => {
      assignmentGroupListPrimary.push({
        id: ob?.id + '-' || '',
        textOne: ob?.informationBasic?.assignmentGroupName || '',
        textTwo: ob?.informationBasic?.assignmentGroupDescription || '',
        status: ob?.informationEngagement?.assignmentGroupStatus || ''
      });
    });
  }
  let assignmentGroupListSecondary = [];
  if (
    informationAllocation?.assignmentGroup?.assignmentGroupSecondary &&
    informationAllocation?.assignmentGroup?.assignmentGroupSecondary.length > 0
  ) {
    const tempArr = informationAllocation?.assignmentGroup?.assignmentGroupSecondary;
    tempArr.forEach((ob) => {
      assignmentGroupListSecondary.push({
        id: ob?.id + '-' || '',
        textOne: ob?.informationBasic?.assignmentGroupName || '',
        textTwo: ob?.informationBasic?.assignmentGroupDescription || '',
        status: ob?.informationEngagement?.assignmentGroupStatus || ''
      });
    });
  }
  let assignmentNodeListPrimary = [];
  if (
    informationAllocation?.assignmentNode?.assignmentNodePrimary &&
    informationAllocation?.assignmentNode?.assignmentNodePrimary.length > 0
  ) {
    const tempArr = informationAllocation?.assignmentNode?.assignmentNodePrimary;
    tempArr.forEach((ob) => {
      assignmentNodeListPrimary.push({
        id: ob?.id + '-' || '',
        textOne: ob?.informationBasic?.associateNodeName || '',
        textTwo: ob?.informationBasic?.associateNodeDescription || '',
        status: ob?.informationEngagement?.associateNodeStatus || ''
      });
    });
  }
  let assignmentNodeListSecondary = [];
  if (
    informationAllocation?.assignmentNode?.assignmentNodeSecondary &&
    informationAllocation?.assignmentNode?.assignmentNodeSecondary.length > 0
  ) {
    const tempArr = informationAllocation?.assignmentNode?.assignmentNodeSecondary;
    tempArr.forEach((ob) => {
      assignmentNodeListSecondary.push({
        id: ob?.id + '-' || '',
        textOne: ob?.informationBasic?.associateNodeName || '',
        textTwo: ob?.informationBasic?.associateNodeDescription || '',
        status: ob?.informationEngagement?.associateNodeStatus || ''
      });
    });
  }
  let assignmentTypeListPrimary = [];
  if (
    informationAllocation?.assignmentType?.assignmentTypePrimary &&
    informationAllocation?.assignmentType?.assignmentTypePrimary.length > 0
  ) {
    const tempArr = informationAllocation?.assignmentType?.assignmentTypePrimary;
    tempArr.forEach((ob) => {
      assignmentTypeListPrimary.push({
        id: ob?.id + '-' || '',
        textOne: ob?.informationBasic?.assignmentTypeName || '',
        textTwo: ob?.informationBasic?.assignmentTypeDescription || '',
        status: ob?.informationEngagement?.assignmentTypeStatus || ''
      });
    });
  }
  let assignmentTypeListSecondary = [];
  if (
    informationAllocation?.assignmentType?.assignmentTypeSecondary &&
    informationAllocation?.assignmentType?.assignmentTypeSecondary.length > 0
  ) {
    const tempArr = informationAllocation?.assignmentType?.assignmentTypeSecondary;
    tempArr.forEach((ob) => {
      assignmentTypeListSecondary.push({
        id: ob?.id + '-' || '',
        textOne: ob?.informationBasic?.assignmentTypeName || '',
        textTwo: ob?.informationBasic?.assignmentTypeDescription || '',
        status: ob?.informationEngagement?.assignmentTypeStatus || ''
      });
    });
  }
  const allocationList = [
    {
      id: 'a1',
      labelTextOneOne: 'group',
      labelTextOneOneBadgeOne: 'primary',
      labelTextOneOneBadgeTwo: 'secondary',
      labelTextOneOneBadgeThree: '',
      labelTextOneOneBadgeFour: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'primary',
          innerList: assignmentGroupListPrimary.sort((a, b) =>
            a.textOne.toLowerCase().localeCompare(b.textOne.toLowerCase())
          )
        },
        {
          labelTextOneOneBadge: 'secondary',
          innerList: assignmentGroupListSecondary.sort((a, b) =>
            a.textOne.toLowerCase().localeCompare(b.textOne.toLowerCase())
          )
        }
      ],
      innerInfo: 'No Information',
      isListCard: true
    },
    {
      id: 'a3',
      labelTextOneOne: 'node',
      labelTextOneOneBadgeOne: 'primary',
      labelTextOneOneBadgeTwo: 'secondary',
      labelTextOneOneBadgeThree: '',
      labelTextOneOneBadgeFour: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'primary',
          innerList: assignmentNodeListPrimary.sort((a, b) =>
            a.textOne.toLowerCase().localeCompare(b.textOne.toLowerCase())
          )
        },
        {
          labelTextOneOneBadge: 'secondary',
          innerList: assignmentNodeListSecondary.sort((a, b) =>
            a.textOne.toLowerCase().localeCompare(b.textOne.toLowerCase())
          )
        }
      ],
      innerInfo: 'No Information',
      isListCard: true
    },
    {
      id: 'a4',
      labelTextOneOne: 'type',
      labelTextOneOneBadgeOne: 'primary',
      labelTextOneOneBadgeTwo: 'secondary',
      labelTextOneOneBadgeThree: '',
      labelTextOneOneBadgeFour: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'primary',
          innerList: assignmentTypeListPrimary.sort((a, b) =>
            a.textOne.toLowerCase().localeCompare(b.textOne.toLowerCase())
          )
        },
        {
          labelTextOneOneBadge: 'secondary',
          innerList: assignmentTypeListSecondary.sort((a, b) =>
            a.textOne.toLowerCase().localeCompare(b.textOne.toLowerCase())
          )
        }
      ],
      innerInfo: 'No Information',
      isListCard: true
    }
  ];
  const engagementListAll = [
    {
      id: 'a1',
      labelTextOneOne: 'log',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'all',
          textOne: ''
        },
        {
          labelTextOneOneBadge: 'key',
          textOne: ''
        }
      ],
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false,
      IconOne: Manuscript
    },
    {
      id: 'a2',
      textOneOne: capitalizeFirstLetter(informationEngagement?.assignmentStatus) || 'No Information',
      labelTextOneOne: 'status',
      innerAssociateList: [],
      innerInfo: 'No Information',
      isListCard: false
    },
    {
      id: 'a3',
      labelTextOneOne: 'tag',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'primary',
          textOne: informationEngagement?.assignmentTag.assignmentTagPrimary || 'No Information'
        },
        {
          labelTextOneOneBadge: 'secondary',
          textOne: informationEngagement?.assignmentTag.assignmentTagSecondary || 'No Information'
        }
      ],
      innerAssociateList: [],
      innerInfo: 'No Information',
      isListCard: false
    },
    {
      id: 'a4',
      labelTextOneOne: 'tenure',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'start',
          textOne: informationEngagement?.assignmentTenureDate || 'No Information'
        },
        {
          labelTextOneOneBadge: 'end',
          textOne: informationEngagement?.assignmentTenureDate || 'No Information'
        }
      ],
      innerAssociateList: [],
      innerInfo: 'Assessee',
      isListCard: false
    }
  ];
  const setupList = [
    {
      id: 'setupList1',
      labelTextOneOne: 'fee',
      labelTextOneOneBadges: 
      [
        {
          labelTextOneOneBadge: 'move',
          textOne: informationSetup?.assignmentMoveFee || 'No Information'
        },
        {
          labelTextOneOneBadge: 'sell',
          textOne: informationSetup?.assignmentSellFee || 'No Information'
        },
        {
          labelTextOneOneBadge: 'share',
          textOne: informationSetup?.assignmentShareFee ||  'No Information'
        }
      ],
      innerInfo: 'No',
      isListCard: false,
      isReviewLink: false,
      isMultiInfoCard: false
    }  
  ];
if(isOccupationType === 'iGuru Mine_Career' ||
isOccupationType === 'iGuru Mine_Education' ||
isOccupationType === 'iGuru Mine_Occupation' ||
isOccupationType === 'iGuru Mine_Pulse'){
  
  let validityObj = 
  {
    id: 'validity-001',
    labelTextOneOne: 'iGuru mine',
    labelTextOneOneBadges: [
      {
        labelTextTwoBadge: 'certificate',
        innerLabelBadgeList: [
          {
            labelTextTwoBadge: 'validity',
            innerLabelBadgeList: informationFramework?.assignmentValidity === 1 ?informationFramework?.assignmentValidity+" month" :informationFramework?.assignmentValidity+" months"  || 'No Information'
          }
        ]
      }
    ],
    innerInfo: 'No',
    isListCard: false,
    isReviewLink: false,
    isMultiInfoCard: true
  }
  setupList.push(validityObj);
}
  const reviseAllocation = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    console.log('=====>', labelName);
    if (labelName === 'group') {
      dispatch({ type: LOADER_START });
      let requestObj = {
        module: 'Assignment-Group',
        action: 'Review-List',
        requestObject: makeAssignmentGroupObj(
          selectedAssociateInfo,
          'active',
          0,
          -1,
          'list',
          assignmentCreateType
        )
      };
      dispatch({ type: SET_CORE_GROUP_REVIEW_LIST_REQ_OBJECT, payload: requestObj });
      dispatch({
        type: GET_ASSIGNMENT_GROUP_REVIEW_LIST_SAGA,
        payload: {
          request: requestObj,
          BadgeOne: '',
          BadgeTwo: '',
          BadgeThree: '',
          isMiddlePaneList: false
        }
      });
      if (selectedBadgeName === 'primary') {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'GROUPPOPUP', popupMode: 'ASSIGNMENTCREATE' }
        });
      }
      if (selectedBadgeName === 'secondary') {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'GROUPSECONDARYPOPUP', popupMode: 'ASSIGNMENTCREATE' }
        });
      }
    }

    if (labelName === 'node') {
      dispatch({ type: LOADER_START });
      let nodeRequestObj = makeInternalNodeObj(selectedAssociateInfo, 'active', 0, -1, '', 'Associate-Self-Node-List-Create');
      dispatch({ type: SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT, payload: nodeRequestObj });
      dispatch({
        type: INTERNAL_NODE_LIST_WITH_PERMISSIONS_SAGA,
        payload: {
          request: nodeRequestObj,
          BadgeOne: '',
          BadgeTwo: '',
          BadgeThree: '',
          nodeViewState: 'list',
          isMiddlePaneList: false
        }
      });
      if (selectedBadgeName === 'primary') {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'NODEPOPUP', popupMode: 'ASSIGNMENTCREATE' }
        });
      }
      if (selectedBadgeName === 'secondary') {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'NODESECONDARYPOPUP', popupMode: 'ASSIGNMENTCREATE' }
        });
      }
    }
    if (labelName === 'type') {
      dispatch({ type: LOADER_START });
      let roleRequestObj = {
        module: 'Assignment-Type',
        action: 'Review-List',
        requestObject: makeAssignmentTypeObj(selectedAssociateInfo, 'active', 0, -1)
      };
      dispatch({ type: SET_CORE_TYPE_REVIEW_LIST_REQ_OBJECT, payload: roleRequestObj });
      dispatch({
        type: GET_ASSIGNMENT_TYPE_REVIEW_LIST_SAGA,
        payload: {
          request: roleRequestObj,
          BadgeOne: '',
          BadgeTwo: '',
          BadgeThree: '',
          isMiddlePaneList: false
        }
      });
      if (selectedBadgeName === 'primary') {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'TYPEPOPUP', popupMode: 'ASSIGNMENTCREATE' }
        });
      }
      if (selectedBadgeName === 'secondary') {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'TYPESECONDARYPOPUP', popupMode: 'ASSIGNMENTCREATE' }
        });
      }
    }
  };
  const reviseEngagement = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    console.log('=====>', labelName);
    if (labelName === 'status') {
      dispatch({
        type: SET_STATUS_POPUP_VALUE,
        payload: capitalizeFirstLetter(informationEngagement?.assignmentStatus)
      });
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'STATUSPOPUP', popupMode: 'ASSIGNMENTCREATE' }
      });
    }
    if (labelName === 'tag') {
      if (selectedBadgeName === 'primary') {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'TAGREADONLYPRIMARYPOPUP', popupMode: 'ASSIGNMENTCREATE' }
        });
      }
      if (selectedBadgeName === 'secondary') {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'TAGSECONDARYPOPUP', popupMode: 'ASSIGNMENTCREATE' }
        });
      }
    }
    if (labelName === 'tenure') {
      if (selectedBadgeName === 'start') {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'TENURESATRTDATEPOPUP', popupMode: 'ASSIGNMENTCREATE' }
        });
      }
      if (selectedBadgeName === 'end') {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'TENUREENDDATEPOPUP', popupMode: 'ASSIGNMENTCREATE' }
        });
      }
    }
  };
  const reviseSetup = (e,selectedBadgeArray) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    console.log('=====>', labelName);
    console.log('=====>', selectedBadgeName);
    let badgeName = '';
    if (selectedBadgeArray) {
      if (selectedBadgeArray.length > 0) {
        selectedBadgeArray.forEach((element) => {
          badgeName = badgeName + element.labelTextTwoBadge;
        });
      }
    }
    console.log('=====>', badgeName);
    if(labelName === 'iGuru mine' && badgeName ==='certificate' && selectedBadgeName ==='validity' ){
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'VALIDITY_POPUP', popupMode: 'ASSIGNMENTCREATE' }
      });
    }
    if (labelName ==='fee' && selectedBadgeName ==='move' ){
      console.log('open share popup');
    }
    if (labelName ==='fee' && selectedBadgeName ==='sell' ){
      console.log('open share popup');
    }
    if (labelName ==='fee' && selectedBadgeName ==='share'){
      dispatch({
        type: 'SET_POPUP_VALUE',
        payload: { isPopUpValue: 'ASSIGNMENTCREATEFEEPOPUP', popupMode: 'ASSIGNMENTCREATE' }
      });
    }
  };

  const reviewSetup = (e) =>{
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    console.log('reviewSetup=====>X', labelName);
    console.log('=====>selectedBadgeNameX', selectedBadgeName);
  }

  return (
    <div>
      { headerOneBadgeThree === 'all' ? (
        <>
          <div className="containerPadding">
            <AllocationAccordian
              headerOne="alliance"
              isDisplayCardExpanded={listExpand === 'alliance'}
              setListExpand={setListExpand}
              list={allianceList}
              mode={reviewMode}
            />
          </div>
          <div className="containerPadding">
            <AllocationAccordian
              headerOne="allocation"
              isDisplayCardExpanded={listExpand === 'allocation'}
              setListExpand={setListExpand}
              list={allocationList}
              mode={reviewMode}
              onClickRevise={reviseAllocation}
            />
          </div>
          <div className="containerPadding">
            <AllocationAccordian
              headerOne="engagement"
              isDisplayCardExpanded={listExpand === 'engagement'}
              setListExpand={setListExpand}
              list={engagementListAll}
              mode={reviewMode}
              onClickRevise={reviseEngagement}
              onClickReview={reviseEngagement}
            />
          </div>
          <div className="containerPadding">
            <AllocationAccordian
              headerOne="setup"
              isDisplayCardExpanded={listExpand === 'setup'}
              setListExpand={setListExpand}
              list={setupList}
              mode={reviewMode}
              onClickRevise={reviseSetup}
              onClickReview={reviewSetup}
            />
          </div>
        </>
      ) : (
        <>
          <div className="containerPadding">
            <Paper className={'dossierContainerTop'}>
              {allocationList.map((ob) => {
                return (
                  <div key={ob.id}>
                    {ob.isListCard ? (
                      <DisplayPanelAccordianReviewListOne
                        onClickRevise={reviseAllocation}
                        className=""
                        accordianObject={ob}
                        mode={reviewMode}
                      />
                    ) : (
                      <DisplayPanelAccordianInformation
                        onClickRevise={reviseAllocation}
                        accordianObject={ob}
                        mode={reviewMode}
                      />
                    )}
                  </div>
                );
              })}
            </Paper>
          </div>
          <div className="containerPadding">
            <Paper className={'dossierContainerTop'}>
              {engagementListAll.map((ob) => {
                return (
                  <div key={ob.id}>
                    {ob.isListCard ? (
                      <DisplayPanelAccordianReviewListOne
                        className=""
                        accordianObject={ob}
                        mode={reviewMode}
                        onClickReview={reviseEngagement}
                      />
                    ) : (
                      <DisplayPanelAccordianInformation
                        accordianObject={ob}
                        mode={reviewMode}
                        onClickReview={reviseEngagement}
                      />
                    )}
                  </div>
                );
              })}
            </Paper>
          </div>
        </>
      )}
      {isMobile && (
        <div className={'containerPadding'} style={{ height: '55px' }}>
          {' '}
        </div>
      )}
    </div>
  );
};

export default DisplayPaneThreeSectionOneAssignment;