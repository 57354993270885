import React, { Fragment, useEffect, useState } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import Popup from '../Molecules/PopUp/PopUp';
import PopupHeader from '../Molecules/PopUp/PopUpHeader';
import { InputLabel, FormControl, Checkbox } from '@material-ui/core';
import InputFeild from '../Atoms/InputField/InputField';
import SelectField from '../Atoms/SelectField/SelectField';
import '../Molecules/PopUp/PopUp.css';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { POPUP_CLOSE, SET_NEXT_POPUP } from '../actionType';
import InfoToolTip from '../Atoms/InfoToolTip/InfoToolTip';
import { REQUIRED_ERROR_MESSAGE } from '../errorMessage';
import { getCitiesByStateId, getStatesByCountryId } from '../Actions/GenericActions';

const PopUpBirthplace = (props) => {
  const { popupMode } = useSelector((state) => state.PopUpReducer);
  const dispatch = useDispatch();
  const {
    isActive,
    primaryheader,
    inputHeader,
    headerPanelColour,
    headerOne,
    headerOneBadgeOne,
    nextPopUpValue,
    typeOfSetObject,
    basicInfo,
    mode
  } = props;
  const objectKeys = Object.keys(basicInfo);
  const [localObject, setLocalObject] = useState(basicInfo);
  const [country, setCountry] = useState('');
  const [countryErr, setCountryErr] = useState('');
  const [stateErr, setStateErr] = useState('');
  const [cityErr, setCityErr] = useState('');
  useEffect(() => {
    setLocalObject(basicInfo);
  }, []);
  const { allCountryList, allProvinceList, allCityList } = useSelector(
    (state) => state.DisplayPaneTwoReducer
  );
  const { reviewMode } = useSelector((state) => state.DisplayPaneThreeReducer);

  const handleChange = (e) => {
    console.log(e.target.name);
    console.log(objectKeys.indexOf(e.target.name));
    //console.log(e.currentTarget.getAttribute('data-value'));
    let tag = e.target.value;
    let tagName = e.currentTarget.getAttribute('data-name');
    let currentTargetId = e.currentTarget.id;
    if (currentTargetId === 'country') {
      getStatesByCountryId(dispatch, tag);
      setCountry(tag);
    }
    if (currentTargetId === 'province') {
      getCitiesByStateId(dispatch, country, tag);
    }
    const { name, value } = e.target;
    if (objectKeys.indexOf(e.target.name) === 0) {
      setCountryErr('');
    }
    if (objectKeys.indexOf(e.target.name) === 1) {
      setStateErr('');
    }
    if (objectKeys.indexOf(e.target.name) === 2) {
      setCityErr('');
    }
    if (
      currentTargetId === 'country' ||
      currentTargetId === 'province' ||
      currentTargetId === 'city'
    ) {
      setLocalObject({ ...localObject, [name]: tag, [name.slice(0, -3)]: tagName });
    } else {
      setLocalObject({ ...localObject, [name]: value });
      dispatch({ type: typeOfSetObject, payload: { ...basicInfo, [name]: value } });
    }
  };
  const validateFun = () => {
    let isValid = true;
    if (localObject.assesseeBirthplaceCityTag === '') {
      isValid = false;
      setCityErr(REQUIRED_ERROR_MESSAGE);
    }
    if (localObject.assesseeBirthplaceCountryRegionTag === '') {
      isValid = false;
      setCountryErr(REQUIRED_ERROR_MESSAGE);
    }
    if (localObject.assesseeBirthplaceProvinceStateTag === '') {
      isValid = false;
      setStateErr(REQUIRED_ERROR_MESSAGE);
    }
    return isValid;
  };
  const handleClick = () => {
    /*according to creation mode popup sequence will change*/
    if (
      localObject.assesseeBirthplaceCityTag === '' &&
      localObject.assesseeBirthplaceCountryRegionTag === '' &&
      localObject.assesseeBirthplaceProvinceStateTag === ''
    ) {
      console.log('nothing');
      dispatch({ type: POPUP_CLOSE });
    } else {
      console.log('has something', validateFun());
      if (validateFun()) {
        dispatch({ type: typeOfSetObject, payload: { ...localObject } });
        dispatch({ type: POPUP_CLOSE });
      }
    }
  };
  return (
    <div>
      <Popup isActive={isActive}>
        <PopupHeader
          headerPanelColour={headerPanelColour}
          headerOne={headerOne}
          headerOneBadgeOne={headerOneBadgeOne}
          onClick={handleClick}
          mode={mode}
        />
        <DialogContent
          className={['popupContent', 'fixed10PadDim', 'revisePopupContent'].join(' ')}
        >
          <div className={'fitContent'}>
            <div className={['PopupFormBox', 'labelPopupBox', 'popupMinHei'].join(' ')}>
              <InputLabel htmlFor="name-input" className={'textForLabelPopup'}>
                <Fragment>
                  {inputHeader}&nbsp;
                  {primaryheader ? <span className={'headerBadge'}>{primaryheader}</span> : null}
                </Fragment>
              </InputLabel>
              <div className={'infoSymbol'}></div>
              <div className={'infoSymbol'}>
                <InfoToolTip message="Click me, I will stay visible until you click outside." />
              </div>
            </div>
          </div>
          <FormControl style={{ width: '100%' }}>
            <SelectField
              tag={objectKeys[4]}
              label={'country / region'}
              dataValue={'country'}
              listSelect={allCountryList}
              errorMsg={countryErr}
              onChange={(e) => {
                setCountryErr('');
                handleChange(e);
              }}
              value={localObject && localObject[objectKeys[4]]}
              mappingValue={'_id'}
            />

            {/* <SelectField
            tag={objectKeys[1]}
              tag={'assesseeBirthplaceCountryRegion'}
              label={'country / region'}
              dataValue={'country'}
              listSelect={allCountryList}
              // listSelect={[
              //   { countryCode: '', name: '' },
              //   { countryCode: '91', name: 'India' },
              //   { countryCode: '22', name: 'USA' }
              // ]}
              onChange={handleChange}
              value={(basicInfo && basicInfo?.assesseeBirthplaceCountryRegion) || ''}
              //mappingValue={'countryCode'}
              mappingValue={'_id'}
            /> */}
            <SelectField
              tag={objectKeys[6]}
              label={'province / state'}
              dataValue={'province'}
              listSelect={allProvinceList}
              errorMsg={stateErr}
              onChange={(e) => {
                setStateErr('');
                handleChange(e);
              }}
              mappingValue={'_id'}
              value={localObject && localObject[objectKeys[6]]}
            />
            {/* <SelectField
              tag={'assesseeBirthplaceProvinceState'}
              label={'province / state'}
              listSelect={[
                { stateCode: '', name: '' },
                { stateCode: '211', name: 'Maharashtra' },
                { stateCode: '234', name: 'Karnataka' }
              ]}
              onChange={handleChange}
              mappingValue={'stateCode'}
              value={(basicInfo && basicInfo?.assesseeBirthplaceProvinceState) || ''}
            /> */}
            <SelectField
              tag={objectKeys[8]}
              label={'city'}
              dataValue={'city'}
              listSelect={allCityList}
              mappingValue={'_id'}
              errorMsg={cityErr}
              onChange={(e) => {
                setCityErr('');
                handleChange(e);
              }}
              value={localObject && localObject[objectKeys[8]]}
            />
            {/* <SelectField
              tag={'assesseeBirthplaceCity'}
              label={'city'}
              listSelect={[
                { cityCode: '', name: '' },
                { cityCode: '345', name: 'Mumbai' },
                { cityCode: '356', name: 'Pune' }
              ]}
              mappingValue={'cityCode'}
              onChange={handleChange}
              value={(basicInfo && basicInfo?.assesseeBirthplaceCity) || ''}
            /> */}
          </FormControl>
        </DialogContent>
      </Popup>
    </div>
  );
};

PopUpBirthplace.propTypes = {
  className: PropTypes.string,
  headerPanelColour: PropTypes.oneOf([
    'displayPaneLeft',
    'displayPaneCentre',
    'displayPaneRight',
    'genericOne',
    'genericTwo'
  ]),
  headerOne: PropTypes.string,
  headerOneBadgeOne: PropTypes.string,
  headerOneBadgeTwo: PropTypes.string,
  headerOneBadgeThree: PropTypes.string,
  isActive: PropTypes.bool
};

export default PopUpBirthplace;
