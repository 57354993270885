import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    ASSESSEE_ALLOCATE_ASSIGNMENT_SAGA,
    ASSESSEE_INFO_CREATE,
    ASSESSEE_MARKETPLACE_REVIEW_DISTINCT_SAGA,
    FILTERMODE,
    FILTERMODE_ENABLE,
    LOADER_START,
    POPUP_OPEN,
    SET_DISPLAY_TWO_SINGLE_STATE,
    SET_PAGE_COUNT,
    SET_POPUP_STATE,
    SET_REQUEST_OBJECT,
    ASSESSEE_ALLOCATE_GRP_SAGA,
    CLEAR_DISPLAY_PANE_THREE
} from '../actionType';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import { FilterList } from '@material-ui/icons';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import ListLoader from '../Atoms/ListLoader/ListLoader';
import {
    getPopUpOptionList,
    makeAdministratorsReviewListRequestObject,
    makeAssesseeReviewListRequestObject,
    makeAssistantsReviewListRequestObject,
    makeManagersReviewListRequestObject,
    makeAssesseeMarketplaceDistinctReviewListRequestObject
} from '../Actions/GenericActions';
import { assesseeStatus } from '../Actions/StatusAction';
import { ASSESSEE_REVIEW_LIST_POPUP_OPTION } from '../PopUpConfig';
import Check from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import ReviseIcon from '@material-ui/icons/RadioButtonChecked';
import {
    onClickCheckBoxSelection,
    onClickFlagSelection,
    setFlagedArray
} from '../Actions/AssesseeModuleAction';
const MarketplaceAssesseeDistinctReviewList = (props) => {
    const dispatch = useDispatch();
    // const { secondaryOptionCheckValue } = useSelector((state) => state.AssesseeCreateReducer);
    const {
        numberPage,
        scanCount,
        countPage,
        middlePaneHeader,
        middlePaneHeaderBadgeOne,
        middlePaneHeaderBadgeTwo,
        reviewListDistinctData,
        reviewListReqObj,
        middlePaneSelectedValue,
        typeOfMiddlePaneList,
        selectedAssociateInfo,
        isSelectActive,
        selectedTagsArray,
        unselectedTagsArray,
        selectedFlagedArray,
        unselectedFlagedArray,
        allocatedTagsArray,
        flagedValue,
        allocateStr,
        signedAssesseePermission,
        paneOneSelectedSection,
        isListLoading
    } = useSelector((state) => state.DisplayPaneTwoReducer);
    const { FilterModeEnable, FilterMode } = useSelector((state) => state.FilterReducer);
    const fetchData = async () => {
        if (reviewListDistinctData.length < scanCount) {
            dispatch({
                type: SET_DISPLAY_TWO_SINGLE_STATE,
                payload: { stateName: 'isListLoading', value: true }
            });

            let obj = {
                ...reviewListReqObj.requestObject,
                numberPage: numberPage + 1
            };
            // setTimeout(() => {
            dispatch({
                type: ASSESSEE_MARKETPLACE_REVIEW_DISTINCT_SAGA,
                payload: {
                    request: {
                        module: 'Assessee-Distinct',
                        action: 'Shared-Assessee-List',
                        requestObject: obj
                    },
                    HeaderOne: middlePaneHeader,
                    BadgeOne: 'distinct',
                    BadgeTwo: middlePaneHeaderBadgeTwo,
                    isMiddlePaneList: true,
                    isSelectActive: isSelectActive,
                    selectedTagsArray: selectedTagsArray || [],
                    unselectedTagsArray: unselectedTagsArray || []
                }
            });
            dispatch({ type: SET_PAGE_COUNT, payload: numberPage + 1 });
            // }, 3000);
        }
    };
    useEffect(() => {
        setFlagedArray(reviewListDistinctData, 'assesseeFlag', dispatch);
    }, [reviewListDistinctData]);

    const siftApiCall = (siftKey) => {
        let requestObect = {};
        if (
            typeOfMiddlePaneList === 'administratorsDistinctReviewList' ||
            middlePaneHeader === 'administrators'
        ) {
            requestObect = makeAdministratorsReviewListRequestObject(
                selectedAssociateInfo,
                siftKey,
                0,
                countPage
            );
        } else if (
            middlePaneHeader === 'assistants' ||
            typeOfMiddlePaneList === 'assistantsDistinctReviewList'
        ) {
            requestObect = makeAssistantsReviewListRequestObject(
                selectedAssociateInfo,
                siftKey,
                0,
                countPage
            );
        } else if (
            typeOfMiddlePaneList === 'managersDistinctReviewList' ||
            middlePaneHeader === 'managers'
        ) {
            requestObect = makeManagersReviewListRequestObject(
                selectedAssociateInfo,
                siftKey,
                0,
                countPage
            );
        } else {
            requestObect = makeAssesseeMarketplaceDistinctReviewListRequestObject(
                selectedAssociateInfo,
                siftKey,
                0,
                countPage
            );
        }

        dispatch({ type: LOADER_START });
        dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
        dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        dispatch({
            type: ASSESSEE_MARKETPLACE_REVIEW_DISTINCT_SAGA,
            payload: {
                request: requestObect,
                HeaderOne: middlePaneHeader,
                BadgeOne: 'distinct',
                BadgeTwo: siftKey,
                isSelectActive,
                selectedTagsArray
            }
        });
        dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        dispatch({ type: ASSESSEE_INFO_CREATE });
        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
        //
        // document.getElementById('middleComponentId').scrollTop = '0px';
    };
    const onClickFooter = (e) => {
        let siftValue = e.currentTarget.getAttribute('data-value');
        dispatch({ type: FILTERMODE_ENABLE });
        if (
            siftValue === 'suspended' ||
            siftValue === 'terminated' ||
            siftValue === 'disapproved' ||
            siftValue === 'unapproved' ||
            siftValue === 'unconfirmed'
        ) {
            siftApiCall(siftValue);
        } else if (siftValue === 'flagCancel') {
            dispatch({
                type: SET_DISPLAY_TWO_SINGLE_STATE,
                payload: { stateName: 'flagedValue', value: '' }
            });
            dispatch({
                type: FILTERMODE,
                payload: { FilterMode: 'assesseeDistinct' + middlePaneHeaderBadgeTwo }
            });
        } else if (siftValue === 'flagFinish') {
            // console.log('api call for multiple flag assessee');
        } else if (siftValue === 'finish') {
            // console.log('allocateStr', allocateStr);
            let distinctAllocateStr =
                allocateStr === 'assesseesgroups'
                    ? 'assesseeGroup'
                    : allocateStr === 'assesseesdistinct'
                        ? 'assesseeDistinct'
                        : allocateStr === 'assesseestypes'
                            ? 'assesseeType'
                            : allocateStr === 'assesseesroles'
                                ? 'assesseeRole'
                                : allocateStr === 'assesseesnodes'
                                    ? 'assesseeNode'
                                    : allocateStr === 'assignmentsdistinct'
                                        ? 'assignmentDistinct'
                                        : allocateStr === 'assignmentsgroups'
                                            ? 'assignmentGroup'
                                            : allocateStr === 'assignmentstypes'
                                                ? 'assignmentType'
                                                : allocateStr === 'assignmentsnodes'
                                                    ? 'assignmentNode'
                                                    : '';
            if (distinctAllocateStr !== '' && selectedTagsArray.length !== 0) {
                let request = {};
                if (
                    distinctAllocateStr === 'assesseeGroup' ||
                    distinctAllocateStr === 'assesseeDistinct' ||
                    distinctAllocateStr === 'assesseeType' ||
                    distinctAllocateStr === 'assesseeRole' ||
                    distinctAllocateStr === 'assesseeNode'
                ) {
                    request = {
                        module: 'Assessee-Distinct',
                        action: FilterMode?.toLowerCase().includes('unallocate') ? 'Unallocate' : 'Allocate',
                        requestObject: {
                            assesseeId: selectedAssociateInfo?.assesseeId,
                            associateId:
                                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                                    .associateTagPrimary,
                            [FilterMode.toLowerCase().includes('unallocate')
                                ? 'assesseeDistinctUnallocate'
                                : 'assesseeDistinctAllocate']: {
                                [distinctAllocateStr]: allocatedTagsArray
                            },
                            [FilterMode.toLowerCase().includes('unallocate')
                                ? 'assesseeDistinctUnallocateInformation'
                                : 'assesseeDistinctAllocateInformation']: {
                                assesseeDistinct: selectedTagsArray
                            }
                        }
                    };
                    dispatch({ type: LOADER_START });
                    dispatch({ type: ASSESSEE_ALLOCATE_GRP_SAGA, payload: { request: request } });
                }
                if (
                    distinctAllocateStr === 'assignmentDistinct' ||
                    distinctAllocateStr === 'assignmentGroup' ||
                    distinctAllocateStr === 'assignmentType' ||
                    distinctAllocateStr === 'assignmentNode'
                ) {
                    request = {
                        module: 'Assessee-Distinct',
                        action: FilterMode.toLowerCase().includes('unallocate') ? 'Unallocate' : 'Allocate',
                        actionFilter: 'Assignment',
                        requestObject: {
                            assesseeId: selectedAssociateInfo?.assesseeId,
                            associateId:
                                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                                    .associateTagPrimary,
                            [FilterMode.toLowerCase().includes('unallocate')
                                ? 'assesseeDistinctUnallocate'
                                : 'assesseeDistinctAllocate']: {
                                //[distinctAllocateStr]: allocatedTagsArray
                                assesseeDistinct: selectedTagsArray
                                //[distinctAllocateStr]: selectedTagsArray
                            },
                            [FilterMode.toLowerCase().includes('unallocate')
                                ? 'assesseeDistinctUnallocateInformation'
                                : 'assesseeDistinctAllocateInformation']: {
                                [distinctAllocateStr === 'assignmentDistinct' ? 'assignment' : distinctAllocateStr]:
                                    allocatedTagsArray
                                //assignment: allocatedTagsArray
                                //assesseeDistinct: allocatedTagsArray
                            }
                        }
                    };
                    dispatch({ type: LOADER_START });
                    dispatch({ type: ASSESSEE_ALLOCATE_ASSIGNMENT_SAGA, payload: { request: request } });
                }
            }
            // if (FilterMode === 'allocateToAssessee') {
            //   let request = {
            //     assesseeId: selectedAssociateInfo?.assesseeId,
            //     associateId:
            //       selectedAssociateInfo?.associate?.informationEngagement.associateTag
            //         .associateTagPrimary,
            //     assesseeDistinctAllocate: {
            //       assesseeDistinct: selectedTagsArray
            //     },
            //     assesseeDistinctAllocateInformation: {
            //       assignment: allocatedTagsArray
            //     }
            //   };
            //   dispatch({ type: LOADER_START });
            //   dispatch({ type: ASSESSEE_ALLOCATE_ASSIGNMENT_SAGA, payload: { request: request } });
            // }
        } else if (siftValue === 'cancel') {
            dispatch({
                type: SET_DISPLAY_TWO_SINGLE_STATE,
                payload: { stateName: 'isSelectActive', value: '' }
            });
            dispatch({
                type: FILTERMODE,
                payload: { FilterMode: '' }
            });
        }
        //  else {
        //   dispatch({ type: FILTERMODE_ENABLE });
        // }
    };
    /* for middle pane */
    const primaryIcon = [{ label: 'sift', onClick: onClickFooter, Icon: FilterList }];
    const flagPrimaryIcon = [{ label: 'flag', onClick: onClickFooter, Icon: FilterList }];
    const flagSecondaryIcon = [
        { label: 'cancel', dataValue: 'flagCancel', onClick: onClickFooter, Icon: ClearIcon },
        { label: 'finish', dataValue: 'flagFinish', onClick: onClickFooter, Icon: Check }
    ];
    const secondaryIcon = [
        { label: 'disapproved', onClick: onClickFooter, Icon: FilterList },
        { label: 'suspended', onClick: onClickFooter, Icon: FilterList },
        { label: 'terminated', onClick: onClickFooter, Icon: FilterList },
        { label: 'unapproved', onClick: onClickFooter, Icon: FilterList },
        { label: 'unconfirmed', onClick: onClickFooter, Icon: FilterList }
    ];
    const openAssesseeListPopup = (e) => {
        let assesseeStatus = e.currentTarget.getAttribute('status');
        let popupHeaderOne =
            typeOfMiddlePaneList === 'administratorsDistinctReviewList'
                ? 'administrator'
                : typeOfMiddlePaneList === 'managersDistinctReviewList'
                    ? 'manager'
                    : typeOfMiddlePaneList === 'assistantsDistinctReviewList'
                        ? 'assistant'
                        : 'assessee';
        let assesseeDistinctPermission =
            signedAssesseePermission?.assesseeDistinctPermission?.assesseeAssesseeDistinctPermission;
        let popupContentArrValue = getPopUpOptionList(
            ASSESSEE_REVIEW_LIST_POPUP_OPTION,
            assesseeDistinctPermission
        );
        let tempArr = [];
        popupContentArrValue.forEach((element) => {
            if (
                //assesseeStatus === 'UNCONFIRMED' ||
                (assesseeStatus === 'SUSPENDED' ||
                    assesseeStatus === 'TERMINATED' ||
                    assesseeStatus === 'DISAPPROVED' ||
                    assesseeStatus === 'ARCHIVED') &&
                (element.data === 'approve' || element.data === 'share' || element.data === 'allocate')
            ) {
                tempArr.push({ ...element, disabled: true });
            } else if (
                (assesseeStatus === 'TERMINATED' ||
                    assesseeStatus === 'SUSPENDED' ||
                    assesseeStatus === 'ARCHIVED') &&
                (element.data === 'approve' ||
                    element.data === 'share' ||
                    element.data === 'revise' ||
                    element.data === 'select' ||
                    element.data === 'allocate')
            ) {
                tempArr.push({ ...element, disabled: true });
            } else if (
                (assesseeStatus === 'UNCONFIRMED' || assesseeStatus === 'CONFIRMED') &&
                element.data === 'approve'
            ) {
                tempArr.push({ ...element, disabled: true });
            } else if (paneOneSelectedSection === 'section4' && element.data === 'revise') {
                tempArr.push({ ...element, disabled: true });
            } else {
                tempArr.push(element);
            }
        });

        dispatch({
            type: SET_POPUP_STATE,
            payload: {
                popupHeaderOne: middlePaneHeader.slice(0, -1),
                popupHeaderOneBadgeOne: middlePaneHeaderBadgeOne,
                duplicateHeaderOneBadgeTwo: middlePaneHeaderBadgeTwo,
                isPopUpValue: '',
                popupOpenType: 'primary',
                popupContentArrValue: tempArr,
                selectedTagValue: e.currentTarget.getAttribute('tag'),
                selectedTagStatus: assesseeStatus,
                isFlaged: e.currentTarget.getAttribute('data-flag') === 'true' ? true : false
            }
        });
        dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
        dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: {
                stateName: 'middlePaneListPopupOptions',
                value: tempArr //ASSESSEE_REVIEW_LIST_POPUP_OPTION
            }
        });
    };

    // const [page, setPage] = useState('')
    // const [products, setProducts] = useState([])
    // const fetchMoreItems = async () => {
    //   const response = await fetch(`https://dummyjson.com/products?limit=10&skip=${page * 10}`)
    //   const data = await response.json()
    //   if (data.products.length === 0) {
    //     setHasMore(false)
    //   } else {
    //     setProducts((prevState) => [...prevState, ...data.products])
    //     setPage(prevPage => prevPage + 1)
    //   }
    // }

    // infinite scroll
    const [hasMore, setHasMore] = useState(true);
    let elementRef = useRef(null);

    const onIntersection = (entries) => {
        const firstEntry = entries[0];
        reviewListDistinctData.length >= scanCount ? setHasMore(false) : setHasMore(true);
        if (firstEntry.isIntersecting && hasMore && !isListLoading) {
            fetchData();
        }
    };

    useEffect(() => {
        // console.log('####MYREF######', elementRef)
        const observer = new IntersectionObserver(onIntersection, { threshold: 0.90 });

        if (observer && elementRef.current) {
            // console.log('here at observer...', elementRef.current)
            observer.observe(elementRef.current);
        }

        return () => {
            if (observer) observer.disconnect();
        };
    }, [
        isListLoading,
        hasMore,
        reviewListDistinctData,
        isSelectActive,
        selectedTagsArray,
        unselectedTagsArray
    ]);

    // infinite scroll end

    let signedAssociateId =
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary;
    // console.log('reviewListDistinctData', reviewListDistinctData)
    // console.log(reviewListDistinctData.length , scanCount, 'scanCount')
    return (
        <div>
            {/* {products && products.map(item=> (<div> {item.price} </div>)
     )} */}

            {reviewListDistinctData &&
                reviewListDistinctData.map((item, index) => {
                    return (
                        <div className="containerPadding" key={index} ref={elementRef}>
                            {
                                <ReviewList
                                    className=""
                                    id={index}
                                    tag={
                                        item.informationEngagement?.assesseeTag
                                            ? item.informationEngagement?.assesseeTag?.assesseeTagPrimary
                                            : item
                                    }
                                    associateId={item?.associateId}
                                    //  status={assesseeStatus(
                                    //    middlePaneHeaderBadgeTwo,
                                    //   item.informationEngagement.assesseeStatus
                                    // )}
                                    status={item.informationEngagement?.assesseeStatus}
                                    actualStatus={item.informationEngagement?.assesseeStatus}
                                    isSelectedReviewList={
                                        middlePaneSelectedValue ===
                                            item.informationEngagement?.assesseeTag?.assesseeTagPrimary
                                            ? true
                                            : false
                                    }
                                    textOne={
                                        item?.informationBasic?.assesseeNameFirst +
                                        ' ' +
                                        item?.informationBasic?.assesseeNameOther +
                                        ' ' +
                                        item?.informationBasic?.assesseeNameLast +
                                        ' ' +
                                        item?.informationBasic?.assesseeNameSuffix
                                    }
                                    textTwo={item?.informationBasic?.assesseeAlias}
                                    isTooltipActive={false}
                                    // onClickEvent={openAssesseeListPopup}
                                    isFlagActive={selectedFlagedArray.includes(
                                        item?.informationEngagement?.assesseeTag?.assesseeTagPrimary
                                    )}
                                    isShared={item?.assesseeShared}
                                    flagedValue={flagedValue}
                                    isSelectActive={isSelectActive}
                                    isSelected={selectedTagsArray?.includes(
                                        item?.informationEngagement?.assesseeTag?.assesseeTagPrimary
                                    )}
                                    onClickCheckBox={(event) => {
                                        !isListLoading &&
                                            onClickCheckBoxSelection(
                                                selectedTagsArray,
                                                unselectedTagsArray,
                                                event,
                                                dispatch
                                            );
                                    }}
                                    // disableonClickCheckBox={item.associateId !== signedAssociateId ? true : false}
                                    onClickAddFladed={(event) => {
                                        onClickFlagSelection(
                                            selectedFlagedArray,
                                            unselectedFlagedArray,
                                            event,
                                            dispatch
                                        );
                                    }}
                                />
                            }
                        </div>
                    );
                })}
            {isListLoading ? (
                <div>
                    {' '}
                    <ListLoader />{' '}
                </div>
            ) : <div style={{ height: "60px", width: "100%" }}></div>}
            {(FilterMode === 'assesseeDistinctinactive' ||
                FilterMode === 'assistantDistinctinactive' ||
                FilterMode === 'managerDistinctinactive' ||
                FilterMode === 'administratorDistinctinactive') && (
                    <FooterIconTwo
                        FilterModeEnable={FilterModeEnable}
                        FilterMode={FilterMode}
                        onClick={onClickFooter}
                        primaryIcon={primaryIcon}
                        secondaryIcon={secondaryIcon}
                    />
                )}
            {FilterMode === 'assesseeFlag' && (
                <FooterIconTwo
                    FilterModeEnable={FilterModeEnable}
                    FilterMode={FilterMode}
                    onClick={onClickFooter}
                    primaryIcon={flagPrimaryIcon}
                    secondaryIcon={flagSecondaryIcon}
                />
            )}
            {(FilterMode === 'allocateToAssessee' || FilterMode === 'unAllocateToAssessee') && (
                <FooterIconTwo
                    FilterModeEnable={FilterModeEnable}
                    FilterMode={FilterMode}
                    onClick={onClickFooter}
                    primaryIcon={[
                        {
                            label: `${FilterMode === 'allocateToAssessee' ? 'allocate' : 'unallocate'}`,
                            onClick: onClickFooter,
                            Icon: ReviseIcon
                        }
                    ]}
                    secondaryIcon={[
                        { label: 'cancel', onClick: onClickFooter, Icon: ClearIcon },
                        { label: 'finish', onClick: onClickFooter, Icon: Check }
                    ]}
                />
            )}
        </div>
    );
};
export default MarketplaceAssesseeDistinctReviewList;
