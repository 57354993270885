import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import './SelectField.css';
import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  Input
} from '@material-ui/core';

export const SelectField = (props) => {
  const {
    tag,
    label,
    listSelect = [],
    errorMsg,
    onChange,
    value,
    mappingValue,
    dataValue = '',
    labelBadgeOne = '',
    labelBadgeTwo = '',
    isMultiSelect = false,
    dataValueKey = '',
    classNames = ''
  } = props;

  return (
    <Fragment>
      <FormControl className={'selectFormText'}>
        <InputLabel htmlFor={tag} style={{ marginBottom: 0, fontSize: '1.6rem' }}>
          {label} &nbsp;
          {labelBadgeOne ? <span className={'labelheaderBadge1'}>{labelBadgeOne}</span> : null}&nbsp;
          {labelBadgeTwo ? <span className={'labelheaderBadge1'}>{labelBadgeTwo}</span> : null}
        </InputLabel>
        <Select
          id={tag}
          name={tag}
          value={isMultiSelect ? (value === null ? [] : value) : tag === "assesseeStatus" && label === "status" ? value.charAt(0) + value.slice(1).toLowerCase() : value}
          onChange={onChange}
          multiple={isMultiSelect}
          renderValue={isMultiSelect ? (selected) => selected.join(', ') : null}
          input={<Input />}
          className={['selectFontAlign', classNames].join(' ')}
        //disabled={isNotRevised}
        >
          {mappingValue
            ? listSelect.map((option, index) =>
              option === 'divider' ? (
                <Divider light />
              ) : (
                <MenuItem
                  key={`${tag}-${index}`}
                  id={dataValue}
                  value={option[mappingValue]}
                  data-name={option?.name || option[mappingValue]}
                  data-key={dataValueKey && option[dataValueKey]}
                  className={'selectMenu'}
                >
                  {/* <div>{createNameWithBadge(option[name])}</div> */}
                  <div>{`${(option.name && option.name) || option[mappingValue]} ${option.description ? `(${option.description})` : ''
                    }`}</div>
                </MenuItem>
              )
            )
            : listSelect.map((option, index) => (
              <MenuItem key={`${tag}-${index}`} value={option} className={'selectMenu'}>
                {option}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormHelperText className={['helperText', 'margin-left-right-5', 'helptextmargin'].join(' ')}>
        {errorMsg}
      </FormHelperText>
    </Fragment>
  );
};

SelectField.propTypes = {
  tag: PropTypes.string,
  label: PropTypes.string,
  listSelect: PropTypes.array
};

export default SelectField;
