import { put, takeLatest, call } from 'redux-saga/effects';
import {
  ASSOCIATE_ROLE_REVISE_INFO_SAGA,
  GET_ASSOCIATEROLE_ASSOCIATE_REVIEW_LIST_SAGA,
  GET_ASSOCIATE_ROLE_REVIEW_INFO_SAGA,
  GET_ASSOCIATE_ROLE_REVIEW_LIST_SAGA,
  LOADER_STOP,
  SET_ASSESSEE_ROLE_ASSESSEE_ID_LIST,
  SET_ASSOCIATE_ROLE_CLASSIFICAION_STATE,
  SET_ASSOCIATE_ROLE_REDUCER_STATE,
  SET_DISPLAY_PANE_THREE_STATE,
  SET_DISPLAY_THREE_SINGLE_STATE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_POPUP_VALUE,
  SET_ROLE_DYNAMIC_STATE,
  SET_ROLE_REDUCER_STATE,
  SET_SETUP_PERMISSION_NEW,
  SET_UNSELECTED_ASSESSEE_ROLE_ASSESSEE_ID_LIST
} from '../../actionType';
import { ASSOCIATE_MODULE_URL } from '../../endpoints';
import { EXCEPTION_ERROR_MESSAGE } from '../../errorMessage';
import Store from '../../store';

const assesseeRoleInfoApi = async (requestObj) => {
  console.log(requestObj.data);
  let URL = ASSOCIATE_MODULE_URL;
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      Authorization: localStorage.getItem('token')
    }),
    body: JSON.stringify(requestObj.data)
  };
  const response = await fetch(URL, requestOptions);
  const json = await response.json();
  return json;
};

function* workerReviewAssociateRoleInfoSaga(data) {
  try {
    const userResponse = yield call(assesseeRoleInfoApi, {
      data: data.payload.reqBody
    });
    if (userResponse.responseCode === '000') {
      console.log('IN ASSOCIATE ROLE Review', userResponse);
      const { isReviseMode = false, associateRoleAssociateReqBody, createMode } = data.payload;
      if (associateRoleAssociateReqBody !== null) {
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
        yield put({
          type: GET_ASSOCIATEROLE_ASSOCIATE_REVIEW_LIST_SAGA,
          payload: {
            request: associateRoleAssociateReqBody,
            HeaderOne: 'associates',
            BadgeOne: '',
            BadgeTwo: '',
            BadgeThree: '',
            isMiddlePaneList: false
          }
        });
      }
      yield put({
        type: SET_DISPLAY_PANE_THREE_STATE,
        payload: {
          headerOne: 'associates',
          headerOneBadgeOne: 'role',
          headerOneBadgeTwo: 'information',
          headerOneBadgeThree: 'key',
          responseObject: userResponse.responseObject[0],
          reviewMode: isReviseMode ? 'revise' : '',
          createMode
        }
      });
      // yield put({
      //   type: SET_ROLE_REDUCER_STATE,
      //   payload: {
      //     objectName: 'associateRole',
      //     stateName: 'informationSetup',
      //     value: userResponse.responseObject[0].informationSetup
      //   }
      // });
      yield put({
        type: SET_ASSOCIATE_ROLE_CLASSIFICAION_STATE,
        payload: userResponse.responseObject[0].informationSetup?.associateRoleClassification
      });
      yield put({
        type: SET_SETUP_PERMISSION_NEW,
        payload: {
          objectName: 'associateRole',
          stateName: 'associateRoleDefault',
          value: userResponse.responseObject[0].informationSetup.associateRoleDefault
        }
      });

      if (userResponse.responseObject[0].informationSetup?.associateRolePermission) {
        yield put({
          type: SET_ROLE_DYNAMIC_STATE,
          payload: {
            objectName: 'associateRole',
            stateName: 'informationSetup',
            actualStateName: 'associateRolePermission',
            value: userResponse.responseObject[0].informationSetup?.associateRolePermission
          }
        });
      }
      if (isReviseMode) {
        yield put({
          type: SET_ASSOCIATE_ROLE_REDUCER_STATE,
          payload: userResponse.responseObject[0].informationBasic
        });
      }
      console.log('loading end');
      yield put({ type: LOADER_STOP });
    } else {
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}

function* workerReviseAssociateRoleInfoSaga(data) {
  try {
    const userResponse = yield call(assesseeRoleInfoApi, {
      data: data.payload.reqBody
    });
    if (userResponse.responseCode === '000') {
      console.log('IN ASSOCIATE ROLE Review', userResponse);
      const {
        associateRoleAssociateReqBody = null,
        createMode = '',
        isReviseCallFromPaneTwo = false
      } = data.payload;
      if (associateRoleAssociateReqBody !== null) {
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
        yield put({
          type: GET_ASSOCIATEROLE_ASSOCIATE_REVIEW_LIST_SAGA,
          payload: {
            request: associateRoleAssociateReqBody,
            HeaderOne: 'associates',
            BadgeOne: '',
            BadgeTwo: '',
            BadgeThree: '',
            isMiddlePaneList: false
          }
        });
        if (isReviseCallFromPaneTwo) {
          yield put({
            type: SET_DISPLAY_THREE_SINGLE_STATE,
            payload: { stateName: 'isFrameworkOpen', value: 'assesseeRole' }
          });
        } else {
          yield put({
            type: SET_DISPLAY_PANE_THREE_STATE,
            payload: {
              headerOne: 'associates',
              headerOneBadgeOne: 'role',
              headerOneBadgeTwo: 'information',
              headerOneBadgeThree: 'key',
              responseObject: userResponse.responseObject[0],
              createMode,
              reviewMode: isReviseCallFromPaneTwo ? 'revise' : ''
            }
          });
          yield put({
            type: SET_DISPLAY_THREE_SINGLE_STATE,
            payload: { stateName: 'isFrameworkOpen', value: '' }
          });
        }
       
      }
      if (createMode === '') {
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'reviewListDistinctData', value: [] }
        });
        yield put({
          type: GET_ASSOCIATE_ROLE_REVIEW_LIST_SAGA,
          payload: {
            HeaderOne: 'associates',
            request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
            BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
            isMiddlePaneList: true
          }
        });
      } else {
        yield put({ type: LOADER_STOP });
      }
    } else {
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    yield put({ type: SET_ASSESSEE_ROLE_ASSESSEE_ID_LIST, payload: [] });
    yield put({ type: SET_UNSELECTED_ASSESSEE_ROLE_ASSESSEE_ID_LIST, payload: [] });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}

export default function* watchReviewAssociateRoleInfoSaga() {
  yield takeLatest(GET_ASSOCIATE_ROLE_REVIEW_INFO_SAGA, workerReviewAssociateRoleInfoSaga);
  yield takeLatest(ASSOCIATE_ROLE_REVISE_INFO_SAGA, workerReviseAssociateRoleInfoSaga);
}
