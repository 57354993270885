import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import NextIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MoreVert from '@material-ui/icons/MoreVert';
import '../../Molecules/Header/HeaderCard.css';
import {
  CLEAR_ASSESSEE_ASSESSMENT_REDUCER_STATE,
  GET_ASSESSEE_ASSIGNMENT_SAGA,
  LOADER_START,
  POPUP_CLOSE,
  POPUP_OPEN,
  SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
  SET_DISPLAY_PANE_FOUR_SHOW,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MIDDLEPANE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_POPUP_SINGLE_STATE,
  SET_POPUP_STATE
} from '../../actionType';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import Clear from '@material-ui/icons/Clear';
import { CANCEL_POPUP } from '../../PopUpConfig';

const DisplayPaneSixHeader = (props) => {
  const {
    headerOne = 'dashboard',
    headerOneBadgeOne = '',
    headerOneBadgeTwo = '',
    headerOneBadgeThree = '',
    headerOneBadgeFour = '',
    headerPanelColour
  } = props;
  const dispatch = useDispatch();
  const { isDisplayPaneSixShow } = useSelector((state) => state.AssessmentReducer);
  const { isAssessmentStart, assesseeAssessmentStartData } = useSelector(
    (state) => state.AssesseeAssignmentAssessmentReducer
  );
  const { isAssessmentPreviewShow } = useSelector((state) => state.DisplayPaneThreeReducer);
  const { scanCount, mobilePanestate, relatedReviewListDistinctData, reviewListReqObj } =
    useSelector((state) => state.DisplayPaneTwoReducer);
  const updatedAssessmentList = () => {
    // debugger;
    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: 'assessment',
        popupHeaderOneBadgeOne: 'cancel',
        popupHeaderOneBadgeTwo: '',
        isPopUpValue: '',
        popupOpenType: 'primary',
        popupContentArrValue: CANCEL_POPUP
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'paneSevenPopup' });
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: { stateName: 'headerButtons', value: false }
    });
  };
  const clickOnAssessmentStart = () => {
    if (
      isMobile &&
      isAssessmentStart !== '' &&
      !isAssessmentPreviewShow &&
      assesseeAssessmentStartData
    ) {
      dispatch({
        type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
        payload: { stateName: 'isAssessmentStart', value: 'PROGRESS' }
      });
      dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneSeven' });
    } else if (
      assesseeAssessmentStartData === null &&
      isMobile &&
      isAssessmentStart !== '' &&
      !isAssessmentPreviewShow
    ) {
      dispatch({
        type: SET_MIDDLEPANE_STATE,
        payload: {
          middlePaneHeader: 'assessments',
          middlePaneHeaderBadgeOne: 'active',
          middlePaneHeaderBadgeTwo: '',
          middlePaneHeaderBadgeThree: '',
          middlePaneHeaderBadgeFour: '',
          typeOfMiddlePaneList: 'assesseesAssginmentAssessmentReviewList',
          scanCount: scanCount,
          showMiddlePaneState: true
        }
      });
      dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
    } else if (isAssessmentPreviewShow && isMobile && isAssessmentStart !== '') {
      dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
    } else {
      dispatch({ type: SET_DISPLAY_PANE_FOUR_SHOW, payload: false });
    }
  };
  return (
    <>
      {isDisplayPaneSixShow ? (
        <div className={'iguru-leftpanel'}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'iguru-usercardcontainer'}>
            <Paper
              className={[
                `iguru-assesseescontainer`,
                `iguru-assesseescontainer${headerPanelColour}`
              ].join(' ')}
            >
              <div className={'iguru-componentinnerdiv'}>
                <div className={'iguru-moretextpanelheader'}>
                  <div>
                    <span>{headerOne}</span>&nbsp;
                    {headerOneBadgeOne !== '' ? (
                      <Fragment>
                        <span className={'iguru-header-badge1_0'}>{headerOneBadgeOne}</span>
                        &nbsp;
                      </Fragment>
                    ) : null}
                    {headerOneBadgeTwo !== '' ? (
                      <Fragment>
                        <span className={'iguru-header-badge1_0'}>{headerOneBadgeTwo}</span>
                        &nbsp;
                      </Fragment>
                    ) : null}
                    {headerOneBadgeThree !== '' ? (
                      <Fragment>
                        <span className={'iguru-header-badge1_0'}>{headerOneBadgeThree}</span>
                        &nbsp;
                      </Fragment>
                    ) : null}
                    {headerOneBadgeFour !== '' ? (
                      <Fragment>
                        <span className={'iguru-header-badge1_0'}>{headerOneBadgeFour}</span>
                        &nbsp;
                      </Fragment>
                    ) : null}
                  </div>
                </div>
                <div className={'iguru-iconbox'}>
                  {!isMobile && mobilePanestate === 'displayPaneSeven' && (
                    <IconButton onClick={clickOnAssessmentStart}>
                      <ArrowBackIcon className={'iguru-iconbardefault'} />
                    </IconButton>
                  )}{' '}
                </div>
                <div className={'iguru-iconbox'}>
                  {/* <IconButton>
                    <MoreVert className={'iguru-iconbardefault'} />
                  </IconButton> */}
                  {isAssessmentStart === 'START' && (
                    <IconButton onClick={updatedAssessmentList}>
                      <Clear className={'iguru-iconbardefault'} />
                    </IconButton>
                  )}
                </div>
              </div>
            </Paper>
          </Grid>
        </div>
      ) : (
        <div className={'iguru-leftpanel'} style={{ width: '88%' }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className={'iguru-usercardcontainer'}
            style={{ width: '100%' }}
          >
            <Paper
              className={[
                `iguru-assesseescontainer`,
                `iguru-assesseescontainer${headerPanelColour}`
              ].join(' ')}
              style={{ width: '100%' }}
            >
              <div className={'iguru-componentinnerdiv'} style={{ width: '100%' }}>
                <div className={'iguru-iconbox'}>
                  <IconButton
                    onClick={() => {
                      // setIsDisplayPaneShow(true);
                      dispatch({ type: SET_DISPLAY_PANE_FOUR_SHOW, payload: true });
                    }}
                    style={{ width: '100%' }}
                  >
                    <NextIcon className={'iguru-iconbardefault'} />
                    {/* <ArrowBackIcon className={'iguru-iconbardefault'} /> */}
                  </IconButton>
                </div>
              </div>
            </Paper>
          </Grid>
        </div>
      )}
    </>
  );
};

export default DisplayPaneSixHeader;
