import React, { Fragment } from 'react';
import SectionButton from '../../Atoms/Section/SectionButton';
import SectionLine from '../../Atoms/Section/SectionLine';
import '../../Atoms/Section/Section.css';
import { useDispatch, useSelector } from 'react-redux';
import { SET_DISPLAY_TWO_SINGLE_STATE } from '../../actionType';
const Section = (props) => {
  const { listSections, selectedSection, setSelectedSection,onClickFrom='' } = props;
  const { paneOneSelectedSection,paneThreeSelectedSection } = useSelector((state) => state.DisplayPaneTwoReducer);
  // const [selectedSection, setSelectedSection] = useState(listSections[0]);
  const dispatch = useDispatch();

  return (
    <>
      <div className={['containerPadding'].join(' ')}>
        <div className={'middleTabLabel'}>
          <SectionLine />
          {listSections.map((section, index) => {
            return (
              <Fragment key={index}>
                <SectionButton
                  sectionClick={() => {
                    setSelectedSection(section)
                    if(onClickFrom === 'sectionOne'){
                      dispatch({
                        type: SET_DISPLAY_TWO_SINGLE_STATE,
                        payload: { stateName: 'paneOneSelectedSection', value: section.id }
                      })
                    }else{
                      dispatch({
                        type: SET_DISPLAY_TWO_SINGLE_STATE,
                        payload: { stateName: 'paneThreeSelectedSection', value: section.id }
                      })
                    }
                    
                  }}
                  isSelectActive={onClickFrom === 'sectionOne' ? paneOneSelectedSection === section.id : paneThreeSelectedSection === section.id}
                />
                <SectionLine />
              </Fragment>
            );
          })}
        </div>
      </div>
      <div style={{
        //height: 'calc(100vh - 355px)',
        height: 'calc(100vh - 300px)',
        overflow: 'overlay'
      }}>
        {selectedSection.sectionComponent && <selectedSection.sectionComponent />}
        <div className='containerPadding' style={{ height: '50px' }}>
        </div>
      </div>
    </>
  );
};

export default Section;
