import { useDispatch, useSelector } from 'react-redux';
import { ASSIGNMENT_REVISE_SAGA, FILTERMODE, LOADER_START, SET_DISPLAY_THREE_SINGLE_STATE, SET_DISPLAY_TWO_SINGLE_STATE, SET_MIDDLEPANE_STATE, SET_MOBILE_PANE_STATE } from '../actionType';
import ReviewListAccordion from '../Molecules/Accordian/ReviewListAccordion';
import { useState } from 'react';
import CrossIcon from '@material-ui/icons/Clear';
import ClearIcon from '@material-ui/icons/Clear';
import Check from '@material-ui/icons/Check';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import ReviseIcon from '@material-ui/icons/RadioButtonChecked';
import { closeRelatedList } from '../Actions/GenericActions';

const AssesseeGroupMineReviewList = () => {
    const dispatch = useDispatch()
    const {
        reviewListMineGroupDistinctData,
        reviewListDistinctDataDuplicate,
        selectedAssociateInfo,
        middlePaneHeaderDuplicate,
        middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderDuplicateBadgeTwo,
        middlePaneHeaderDuplicateBadgeThree,
        middlePaneHeaderDuplicateBadgeFour,
        middlePaneHeaderDuplicateOne,
        middlePaneHeaderDuplicateOneBadgeOne,
        middlePaneHeaderDuplicateOneBadgeTwo,
        middlePaneHeaderDuplicateOneBadgeThree,
        middlePaneHeaderDuplicateOneBadgeFour,
        typeOfMiddlePaneList,
        selectedTagsArray,
        unselectedTagsArray,
        isSelectActive,
        duplicateScanCount
    } = useSelector((state) => state.DisplayPaneTwoReducer)

    const { FilterModeEnable, FilterMode } = useSelector((state) => state.FilterReducer);

    const {
        createMode,
        responseObject,
        assignmentAssesseeList = [],
        assignmentAssessmentList = [],
        assignmentCultureProfileList = [],
        assignmentJobProfileList = [],
        assignmentAssesseeGroupList = [],
        assignmentAssessmentGroupList = [],
        assignmentCultureProfileGroupList = [],
        assignmentJobProfileGroupList = [],
        assignmentAssesseeMineGroup ,
        reviewMode
    } = useSelector((state) => state.DisplayPaneThreeReducer);
    const assignmentInfo = useSelector((state) => state.AssignmentReducer);

    const [listExpand, setListExpand] = useState('');
    const [isShowReviseIcon, setIsShowReviseIcon] = useState(true);


    const onClickRevise = () => {
        console.log('ON CLICK REVISE ICON');
        setIsShowReviseIcon(false);
    };

    const onClickCheckBoxSelection = (selectedTagsArray, unselectedTagsArray, event, dispatch) => {

        let id = event.target.id;
        let checkedArr = [...selectedTagsArray];
        let unCheckArr = [...unselectedTagsArray];
        if (checkedArr.includes(id)) {
            checkedArr = checkedArr.filter(function (number) {
                return number !== id;
            });
            unCheckArr.push(id);
        } else {
            checkedArr.push(id);
            unCheckArr = unCheckArr.filter(function (number) {
                return number !== id;
            });
        }
        dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'selectedTagsArray', value: checkedArr }
        });
        dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'unselectedTagsArray', value: unCheckArr }
        });
    };

    const setPrevList = () => {
        if (createMode === '') {
            // console.log('hereXXX')
            dispatch({ type: SET_DISPLAY_TWO_SINGLE_STATE , payload: {  stateName: "reviewListDistinctData", value: reviewListDistinctDataDuplicate }});
            closeRelatedList(
                dispatch,
                createMode,
                responseObject,
                middlePaneHeaderDuplicate,
                middlePaneHeaderDuplicateBadgeOne,
                middlePaneHeaderDuplicateBadgeTwo,
                middlePaneHeaderDuplicateBadgeThree,
                middlePaneHeaderDuplicateBadgeFour,
                'assignmentDistinctReviewList',
                duplicateScanCount,
                'assignmentsDistinct', //'assignmentsDistinctinactive',
                duplicateScanCount,
                middlePaneHeaderDuplicateOne,
                middlePaneHeaderDuplicateOneBadgeOne,
                middlePaneHeaderDuplicateOneBadgeTwo,
                middlePaneHeaderDuplicateOneBadgeThree,
                middlePaneHeaderDuplicateOneBadgeFour,
            );
        } else {
            dispatch({
                type: SET_MIDDLEPANE_STATE,
                payload: {
                    middlePaneHeader: '',
                    middlePaneHeaderBadgeOne: '',
                    middlePaneHeaderBadgeTwo: '',
                    middlePaneHeaderBadgeThree: '',
                    middlePaneHeaderBadgeFour: '',
                    typeOfMiddlePaneList: '',
                    scanCount: '',
                    showMiddlePaneState: false
                }
            });
        }
    };

    const onClickReviseCancel = () => {
        setIsShowReviseIcon(true);
    }

    const onClickReviseFinish = () => {
        setIsShowReviseIcon(true);
        setPrevList();
        if (typeOfMiddlePaneList !== '') {
            dispatch({
                type: FILTERMODE,
                payload: { FilterMode: '' }
            });
        }
        dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'isSelectActive', value: '' }
        });
        dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });

        dispatch({
            type: SET_DISPLAY_THREE_SINGLE_STATE,
            payload: { stateName: 'assignmentRelatedReviewListPaneThree', value: {} }
        });
        let minseAssesseeGroupList = [...selectedTagsArray];

        console.log('minseAssesseeList', minseAssesseeGroupList);
        const { id } = responseObject;
        let frameworkObj = {
            ...assignmentInfo.informationFramework,
            // assignmentAssesseeDistinct: selectedTagsArray || [],
            // assignmentAssesseeMineDistinct: selectedTagsArray || [],
            assignmentAssessmentDistinct: assignmentAssessmentList || [],
            assignmentCultureProfileDistinct: assignmentCultureProfileList || [],
            assignmentJobProfileDistinct: assignmentJobProfileList || [],
            assignmentAssesseeGroup: assignmentAssesseeGroupList || [],
            assignmentAssessmentGroup: assignmentAssessmentGroupList || [],
            assignmentCultureProfileGroup: assignmentCultureProfileGroupList || [],
            assignmentJobProfileGroup: assignmentJobProfileGroupList || [],
            assignmentAssesseeMineGroup: minseAssesseeGroupList || [],
        };
        const reqBody = {
            module: 'Assignment-Distinct',
            action: 'Revise',
            requestObject: {
                assesseeId: selectedAssociateInfo?.assesseeId,
                associateId:
                    selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
                assignment: {
                    id,
                    informationBasic: assignmentInfo.informationBasic,
                    informationAllocation: assignmentInfo.informationAllocation,
                    informationFramework: frameworkObj,
                    informationSetup: assignmentInfo.informationSetup
                }
            }
        };
        dispatch({ type: LOADER_START });
        dispatch({
            type: ASSIGNMENT_REVISE_SAGA,
            payload: {
                reqBody
            }
        });

        dispatch({ type: SET_DISPLAY_TWO_SINGLE_STATE , payload: {  stateName: "reviewListDistinctData", value: reviewListDistinctDataDuplicate }});
        closeRelatedList(
            dispatch,
            createMode,
            responseObject,
            middlePaneHeaderDuplicate,
            middlePaneHeaderDuplicateBadgeOne,
            middlePaneHeaderDuplicateBadgeTwo,
            middlePaneHeaderDuplicateBadgeThree,
            middlePaneHeaderDuplicateBadgeFour,
            'assignmentDistinctReviewList',
            duplicateScanCount,
            'assignmentsDistinct', //'assignmentsDistinctinactive',
            duplicateScanCount,
            middlePaneHeaderDuplicateOne,
            middlePaneHeaderDuplicateOneBadgeOne,
            middlePaneHeaderDuplicateOneBadgeTwo,
            middlePaneHeaderDuplicateOneBadgeThree,
            middlePaneHeaderDuplicateOneBadgeFour,
        );
        dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
    }

    const revisePrimaryIcon = [{ label: 'revise', onClick: onClickRevise, Icon: ReviseIcon }];

    const reviseSecondaryIcons = [
        { label: 'cancel', onClick: onClickReviseCancel, Icon: ClearIcon },
        { label: 'finish', onClick: onClickReviseFinish, Icon: Check }
    ];
    return (
        <>
            {reviewListMineGroupDistinctData.length > 0 && reviewListMineGroupDistinctData.map((data, index) => {
                return (
                    <div className='containerPadding' key={index}>
                        <ReviewListAccordion
                            setListExpand={setListExpand}
                            isDisplayCardExpanded={listExpand === data.title}
                            headerOne={data.title}
                            listData={data.children}
                            selectedTagsArray={selectedTagsArray}
                            unselectedTagsArray={unselectedTagsArray}
                            isSelectActive={isSelectActive}
                            onClickCheckBoxSelection={onClickCheckBoxSelection}
                        ></ReviewListAccordion>
                    </div>
                )
            })}

            {FilterMode === 'assignmentDistinctMineGroupDistinct' && (
                // {true && (
                <FooterIconTwo
                    FilterModeEnable={isShowReviseIcon}
                    FilterMode={FilterMode}
                    onClick={onClickRevise}
                    primaryIcon={revisePrimaryIcon}
                    secondaryIcon={reviseSecondaryIcons}
                />
            )}
        </>
    )
}

export default AssesseeGroupMineReviewList