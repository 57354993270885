import { put, takeLatest, call } from 'redux-saga/effects';
import {
  GET_ASSOCIATE_GROUP_REVIEW_INFO_SAGA,
  LOADER_STOP,
  SET_DISPLAY_PANE_THREE_STATE,
  ASSOCIATE_GROUP_REVISE_INFO_SAGA,
  SET_ASSOCIATE_GROUP_REDUCER_STATE,
  GET_ASSOCIATEGROUP_ASSOCIATE_REVIEW_LIST_SAGA,
  SET_ASSESSEE_GROUP_ASSESSEE_ID_LIST,
  SET_UNSELECTED_ASSESSEE_GROUP_ASSESSEE_ID_LIST,
  SET_POPUP_VALUE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  GET_ASSOCIATE_GROUP_REVIEW_LIST_SAGA,
  SET_GROUP_SETUP_STATE,
  SET_DISPLAY_THREE_SINGLE_STATE,
  SET_GROUP_ALLOCATION_GROUP_ASCENDANT_STATE,
  SET_GROUP_ALLOCATION_STATE,
  SET_PAGE_COUNT
} from '../../actionType';
import { ASSOCIATE_MODULE_URL } from '../../endpoints';
import { EXCEPTION_ERROR_MESSAGE } from '../../errorMessage';
import Store from '../../store';

const associateGroupInfoApi = async (requestObj) => {
  console.log(requestObj.data);
  let URL = ASSOCIATE_MODULE_URL;
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      Authorization: localStorage.getItem('token')
    }),
    body: JSON.stringify(requestObj.data)
  };
  const response = await fetch(URL, requestOptions);
  const json = await response.json();
  return json;
};

function* workerReviewAssociateGroupInfoSaga(data) {
  try {
    const userResponse = yield call(associateGroupInfoApi, {
      data: data.payload.reqBody
    });
    if (userResponse.responseCode === '000') {
      console.log('IN GROUP REVIEW+++++', userResponse);
      const {
        isReviseMode = false,
        associateGroupAssociateReqBody = null,
        createMode = ''
      } = data.payload;
      if (associateGroupAssociateReqBody !== null) {
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
        yield put({
          type: GET_ASSOCIATEGROUP_ASSOCIATE_REVIEW_LIST_SAGA,
          payload: {
            request: associateGroupAssociateReqBody,
            HeaderOne: 'associates',
            BadgeOne: '',
            BadgeTwo: '',
            BadgeThree: '',
            isMiddlePaneList: false
          }
        });
      }
      yield put({
        type: SET_DISPLAY_PANE_THREE_STATE,
        payload: {
          headerOne: 'associates',
          headerOneBadgeOne: 'group',
          headerOneBadgeTwo: 'information',
          headerOneBadgeThree: 'key',
          createMode: createMode,
          responseObject: userResponse.responseObject[0],
          reviewMode: isReviseMode ? 'revise' : ''
        }
      });
      if (isReviseMode) {
        yield put({
          type: SET_ASSOCIATE_GROUP_REDUCER_STATE,
          payload: userResponse.responseObject[0].informationBasic
        });
        yield put({
          type: SET_GROUP_SETUP_STATE,
          payload: {
            objectName: 'associateGroup',
            value: userResponse.responseObject[0].informationSetup
          }
        });
        let tempArr = [];
        if (
          userResponse.responseObject[0].informationAllocation &&
          userResponse.responseObject[0].informationAllocation?.associateGroupAscendant
            ?.associateGroupAscendantPrimary &&
          userResponse.responseObject[0].informationAllocation?.associateGroupAscendant
            ?.associateGroupAscendantPrimary?.length > 0
        ) {
          tempArr =
            userResponse.responseObject[0].informationAllocation?.associateGroupAscendant?.associateGroupAscendantPrimary.map(
              (ob) => ob.id
            );
        }
        yield put({
          type: SET_GROUP_ALLOCATION_GROUP_ASCENDANT_STATE,
          payload: {
            objectName: 'associateGroup',
            stateName: 'associateGroupAscendantPrimary',
            value: tempArr
          }
        });
        if (
          userResponse.responseObject[0].informationAllocation &&
          userResponse.responseObject[0].informationAllocation?.associateGroupNode
            ?.associateGroupNodePrimary &&
          userResponse.responseObject[0].informationAllocation?.associateGroupNode
            ?.associateGroupNodePrimary?.length > 0
        ) {
          let tempArr =
            userResponse.responseObject[0].informationAllocation?.associateGroupNode?.associateGroupNodePrimary.map(
              (ob) => ob.id
            );
          yield put({
            type: SET_GROUP_ALLOCATION_STATE,
            payload: {
              objectName: 'associateGroup',
              stateName: 'associateGroupNode',
              actualStateName: 'associateGroupNodePrimary',
              value: tempArr
            }
          });
        }
        if (
          userResponse.responseObject[0].informationAllocation &&
          userResponse.responseObject[0].informationAllocation?.associateGroupNode
            ?.associateGroupNodeSecondary &&
          userResponse.responseObject[0].informationAllocation?.associateGroupNode
            ?.associateGroupNodeSecondary?.length > 0
        ) {
          let tempArr =
            userResponse.responseObject[0].informationAllocation?.associateGroupNode?.associateGroupNodeSecondary.map(
              (ob) => ob.id
            );
          yield put({
            type: SET_GROUP_ALLOCATION_STATE,
            payload: {
              objectName: 'associateGroup',
              stateName: 'associateGroupNode',
              actualStateName: 'associateGroupNodeSecondary',
              value: tempArr
            }
          });
        }

        if (
          userResponse.responseObject[0].informationAllocation &&
          userResponse.responseObject[0].informationAllocation?.associateGroupType
            ?.associateGroupTypeSecondary &&
          userResponse.responseObject[0].informationAllocation?.associateGroupType
            ?.associateGroupTypeSecondary?.length > 0
        ) {
          let tempArr =
            userResponse.responseObject[0].informationAllocation?.associateGroupType?.associateGroupTypeSecondary.map(
              (ob) => ob.id
            );
          yield put({
            type: SET_GROUP_ALLOCATION_STATE,
            payload: {
              objectName: 'associateGroup',
              stateName: 'associateGroupType',
              actualStateName: 'associateGroupTypeSecondary',
              value: tempArr
            }
          });
        }

      }
    } else {
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: userResponse.responseMessage,
          popupMode: 'responseErrorMsg'
        }
      });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
    yield put({ type: LOADER_STOP });
  }
}

function* workerReviseAssociateGroupInfoSaga(data) {
  try {
    const userResponse = yield call(associateGroupInfoApi, {
      data: data.payload.reqBody
    });
    if (userResponse.responseCode === '000') {
      console.log('IN GROUP REVIEW+++++', userResponse);
      const {
        associateGroupAssociateReqBody = null,
        createMode = '',
        cardValue = '',
        isReviseCallFromPaneTwo = false
      } = data.payload;
      if (associateGroupAssociateReqBody !== null) {
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
        yield put({
          type: GET_ASSOCIATEGROUP_ASSOCIATE_REVIEW_LIST_SAGA,
          payload: {
            request: associateGroupAssociateReqBody,
            HeaderOne: 'associates',
            BadgeOne: '',
            BadgeTwo: '',
            BadgeThree: '',
            isMiddlePaneList: false
          }
        });
        if (isReviseCallFromPaneTwo) {
          yield put({
            type: SET_DISPLAY_THREE_SINGLE_STATE,
            payload: { stateName: 'isFrameworkOpen', value: 'associateGroup' }
          });
        } else {
          yield put({
            type: SET_DISPLAY_THREE_SINGLE_STATE,
            payload: { stateName: 'isFrameworkOpen', value: '' }
          });
        }
        console.log('class>>>>>>>',userResponse.responseObject[0]);
        console.log('class>>>>>>>',userResponse.responseObject[0].informationSetup);
        console.log('class>>>>>>>',userResponse.responseObject[0].informationSetup?.associateGroupClassification);
        console.log('class>>>>>>>',userResponse.responseObject[0].informationSetup?.associateGroupClassification?.associateGroupClassificationPrimary);
        let tempArr = [];
        Store.getState().DisplayPaneTwoReducer.middlePaneListPopupOptions.forEach((element) => {
          if (
            element.data === 'share' &&
            userResponse.responseObject[0].informationSetup?.associateGroupClassification
              ?.associateGroupClassificationPrimary === 'Bespoke'
          ) {
            tempArr.push({ ...element, disabled: true });
          }  else if (
            element.data === 'share' &&
            userResponse.responseObject[0].informationSetup?.associateGroupClassification
              ?.associateGroupClassificationPrimary === 'Generic'
          ) {
            tempArr.push({ ...element, disabled: false });
          } else tempArr.push(element);
        });
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'middlePaneListPopupOptions',
            value: tempArr
          }
        });
        yield put({
          type: SET_DISPLAY_PANE_THREE_STATE,
          payload: {
            headerOne: 'associates',
            headerOneBadgeOne: 'group',
            headerOneBadgeTwo: 'information',
            headerOneBadgeThree: 'key',
            responseObject: userResponse.responseObject[0],
            createMode,
            reviewMode: isReviseCallFromPaneTwo ? 'revise' : ''
          }
        });
      }
      if (createMode === '') {
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'reviewListDistinctData', value: [] }
        });
        yield put({ type: SET_PAGE_COUNT, payload: 0 });
        yield put({
          type: GET_ASSOCIATE_GROUP_REVIEW_LIST_SAGA,
          payload: {
            HeaderOne: 'associates',
            request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
            BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
            cardValue,
            isMiddlePaneList: true
          }
        });
        yield put({ type: SET_ASSESSEE_GROUP_ASSESSEE_ID_LIST, payload: [] });
        yield put({
          type: SET_UNSELECTED_ASSESSEE_GROUP_ASSESSEE_ID_LIST,
          payload: []
        });
      } else {
        yield put({ type: LOADER_STOP });
      }
    } else {
      console.log('loading end');
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: userResponse.responseMessage,
          popupMode: 'responseErrorMsg'
        }
      });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
    yield put({ type: LOADER_STOP });
  }
}

export default function* watchReviewAssociateGroupInfoSaga() {
  yield takeLatest(GET_ASSOCIATE_GROUP_REVIEW_INFO_SAGA, workerReviewAssociateGroupInfoSaga);
  yield takeLatest(ASSOCIATE_GROUP_REVISE_INFO_SAGA, workerReviseAssociateGroupInfoSaga);
}
