import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ASSESSMENT_INFO_REVISE_SAGA,
  CLEAR_VERSION_REDUCER_STATE,
  FILTERMODE,
  FILTERMODE_ENABLE,
  LOADER_START,
  LOADER_STOP,
  POPUP_OPEN,
  RELATED_REVIEWLIST_DISTINCT_DATA,
  SET_ASSESSEE_GROUP_ASSESSEE_ID_LIST,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MIDDLEPANE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_POPUP_STATE,
  SET_RELATED_REQUEST_OBJECT,
  SET_UNSELECTED_ASSESSEE_GROUP_ASSESSEE_ID_LIST,
  SET_VERSION_REDUCER_STATE
} from '../actionType';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import { FilterList } from '@material-ui/icons';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import { ASSESSEES_SUBMODULE_REVIEW_LIST_POPUP_OPTION } from '../PopUpConfig';
import Card from '../Molecules/Card/Card';
import CrossIcon from '@material-ui/icons/Clear';
import { onClickCheckBoxSelectionForVersion } from '../Actions/AssesseeModuleAction';
import ReviseIcon from '@material-ui/icons/RadioButtonChecked';
import Check from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { getItemGroupItemDistinctApiCall } from '../Actions/ItemModuleAction';
import ListLoader from '../Atoms/ListLoader/ListLoader';
import store from '../store';

const AssessmentSectionItemDistinctReviewList = (props) => {
  const dispatch = useDispatch();
  const [isShowReviseIcon, setIsShowReviseIcon] = useState(true);
  const { responseObject, headerOneBadgeThree, createMode } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  const assessmentInfo = useSelector((state) => state.AssessmentReducer);

  const {
    reviewListDistinctData,
    selectedAssociateInfo,
    relatedReviewListDistinctData,
    middlePaneHeaderBadgeOne,
    selectedTagsArray,
    isSelectActive,
    unselectedTagsArray,
    typeOfMiddlePaneList,
    assessmentResponseObject,
    relatedReviewListReqObj,
    assessmentSelecedSection,
    countPage,
    assessmentSelecedVersion,
    isListLoading
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { FilterMode } = useSelector((state) => state.FilterReducer);
  const { versionInformation } = useSelector((state) => state.VersionCreateReducer);
  const { assesseeAssessmentStartData } = useSelector(
    (state) => state.AssesseeAssignmentAssessmentReducer
  );

  const setPrevList = () => {
    dispatch({ type: LOADER_START });
    let array =
      assessmentResponseObject?.informationFramework?.assessmentSectionFramework[0]
        .assessmentVersionFramework || [];
    // let array = assessmentResponseObject?.informationFramework?.assessmentSection || [];
    let reviseResponseObj = {
      countTotal: array.length || 0,
      responseObject: [
        {
          // sections: array || [],
          versions: array || [],
          assessmentName: assessmentResponseObject?.informationBasic.assessmentName,
          assessmentDescription: assessmentResponseObject?.informationBasic.assessmentDescription,
          assessmentStatus: assessmentResponseObject?.informationEngagement.assessmentStatus,
          id: assessmentResponseObject?.id
        }
      ]
    };
    setTimeout(function () {
      dispatch({
        type: SET_RELATED_REQUEST_OBJECT,
        payload: relatedReviewListReqObj
      });
      dispatch({
        type: SET_MIDDLEPANE_STATE,
        payload: {
          middlePaneHeader: 'assessments',
          middlePaneHeaderBadgeOne: 'version',
          middlePaneHeaderBadgeTwo: 'distinct',
          middlePaneHeaderBadgeThree: 'active',
          middlePaneHeaderBadgeFour: '',
          // typeOfMiddlePaneList: 'assessmentsectionsReviewList',
          typeOfMiddlePaneList: 'assessmentversionsReviewList',
          scanCount: reviseResponseObj.countTotal,
          showMiddlePaneState: true,
          middlePaneHeaderDuplicate:
              store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
            middlePaneHeaderDuplicateBadgeOne:
              store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            middlePaneHeaderDuplicateBadgeTwo:
              store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            middlePaneHeaderDuplicateBadgeThree:
              store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
            middlePaneHeaderDuplicateBadgeFour: '',
            middlePaneHeaderDuplicateOne:
              store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOne,
            middlePaneHeaderDuplicateOneBadgeOne:
              store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeOne,
            middlePaneHeaderDuplicateOneBadgeTwo:
              store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeTwo,
            middlePaneHeaderDuplicateOneBadgeThree:
              store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeThree,
            middlePaneHeaderDuplicateOneBadgeFour:
              store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeFour,
            duplicateScanCount: store.getState().DisplayPaneTwoReducer.duplicateScanCount,
        }
      });
      dispatch({
        type: RELATED_REVIEWLIST_DISTINCT_DATA,
        payload: reviseResponseObj.responseObject
      });
      dispatch({
        type: FILTERMODE,
        payload: { FilterMode: '' }
      });
    }, 2000);
    dispatch({ type: LOADER_STOP });
  };
  const onClickRevise = () => {
    console.log('ON CLICK REVISE ICON');
    setIsShowReviseIcon(false);
  };
  const onClickReviseCancel = () => {
    setPrevList();
    setIsShowReviseIcon(true);
  };
  const callApiToUpdateItem = (reviseArray) => {
    console.log('reviseArray', reviseArray);
    let sectionObj = assessmentInfo.informationFramework.assessmentSectionFramework;
    let itemArr = [];
    // let assessmentVersionItemDistinct =
    //   assesseeAssessmentStartData?.assessmentVersionItemDistinct || [];
    // assessmentVersionItemDistinct.length > 0 &&
    //   assessmentVersionItemDistinct.map((dd) => {
    //     itemArr.push({ itemSequence: dd.itemSequence, itemTagPrimary: dd.itemTagPrimary });
    //   });
    let dddd = {
      assessmentVersionName: versionInformation.assessmentVersionName,
      assessmentVersionVerification: versionInformation.assessmentVersionVerification,
      assessmentVersionDescription: versionInformation.assessmentVersionDescription,
      assessmentVersionItemDistinct: reviseArray
    };
    sectionObj[assessmentSelecedSection].assessmentVersionFramework[
      assessmentSelecedVersion
    ] = dddd;
    let id = relatedReviewListDistinctData[0].id;
    const reqBody = {
      module: 'Assessment-Distinct',
      action: 'Revise',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        assessment: {
          id,
          informationBasic: assessmentInfo.informationBasic,
          informationAllocation: assessmentInfo.informationAllocation,
          informationSetup: assessmentInfo.informationSetup,
          informationFramework: {
            ...assessmentInfo.informationFramework,
            assessmentSectionFramework: delete sectionObj.assessmentVersion && sectionObj
          }
        }
      }
    };
    console.log('ASSESSMENT REVISE version===', reqBody);
    dispatch({ type: LOADER_START });
    dispatch({
      type: ASSESSMENT_INFO_REVISE_SAGA,
      payload: {
        secondaryOptionCheckValue: headerOneBadgeThree,
        headerOne: 'assessment',
        reqBody,
        createMode: 'revise',
        assessmentSector: 'version',
        selectedSector: assessmentSelecedSection,
        selectedVersion: assessmentSelecedVersion
      }
    });
  };
  const onClickReviseFinish = () => {
    console.log(
      'ON CLICK finish ICON',
      selectedTagsArray,
      unselectedTagsArray,
      reviewListDistinctData
    );
    setIsShowReviseIcon(true);

    if (typeOfMiddlePaneList === 'assessmentSectionItemDistinctReviewList') {
      console.log('versionInformation0', versionInformation);
      let reviseArray = [];
      let temp = [];
      let sequenceNotSet = [];
      let versionItem = responseObject?.assessmentVersionItemDistinct;
      if (versionItem.length > 0) {
        versionItem.forEach((element) => {
          temp.push(element.itemTagPrimary);
        });
        let oldItemInVersion = temp.filter((x) => !selectedTagsArray.includes(x));
        let newItemTobeAdded = selectedTagsArray.filter((xx) => !temp.includes(xx));
        versionItem.forEach((aa) => {
          if (!oldItemInVersion.includes(aa.itemTagPrimary)) {
            reviseArray.push({
              itemSequence: aa.itemSequence,
              itemTagPrimary: aa.itemTagPrimary
            });
            sequenceNotSet.push(aa.itemSequence);
          }
        });
        let newarr = Array.from(Array(selectedTagsArray.length), (_, index) => index + 1);
        let intersec = newarr.filter((x) => !sequenceNotSet.includes(x));
        intersec.forEach((xx, index) => {
          if (!sequenceNotSet.includes(xx)) {
            reviseArray.push({
              itemSequence: xx,
              itemTagPrimary: newItemTobeAdded[index]
            });
          }
        });
        versionInformation.assessmentVersionItemDistinct = reviseArray;
      } else {
        selectedTagsArray.map((it, ind) => {
          reviseArray.push({
            itemSequence: ind + 1,
            itemTagPrimary: it
          });
        });
        versionInformation.assessmentVersionItemDistinct = reviseArray;
      }
      dispatch({ type: LOADER_START });
      dispatch({
        type: SET_VERSION_REDUCER_STATE,
        payload: versionInformation
      });
      callApiToUpdateItem(reviseArray);
    }
    setPrevList();
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'isSelectActive', value: '' }
    });
    dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
    dispatch({ type: SET_ASSESSEE_GROUP_ASSESSEE_ID_LIST, payload: selectedTagsArray });
    dispatch({
      type: SET_UNSELECTED_ASSESSEE_GROUP_ASSESSEE_ID_LIST,
      payload: unselectedTagsArray
    });
  };
  const revisePrimaryIcon = [{ label: 'revise', onClick: onClickRevise, Icon: ReviseIcon }];

  const reviseSecondaryIcons = [
    { label: 'cancel', onClick: onClickReviseCancel, Icon: ClearIcon },
    { label: 'finish', onClick: onClickReviseFinish, Icon: Check }
  ];

  const closeRelatedList = () => {
    if (isListLoading) {
      return
    }
    setPrevList();
  };
  const listDistinctData = relatedReviewListDistinctData[0];

  const siftApiCall = (siftKey) => {
    getItemGroupItemDistinctApiCall(
      selectedAssociateInfo,
      siftKey,
      countPage,
      dispatch,
      middlePaneHeaderBadgeOne,
      listDistinctData.id,
      '',
      false
    );
    // document.getElementById('middleComponentId').scrollTop = '0px';
  };
  const onClickFooter = (e) => {
    let siftValue = e.currentTarget.getAttribute('data-value');
    if (siftValue === 'suspended' || siftValue === 'terminated') siftApiCall(siftValue);
    dispatch({ type: FILTERMODE_ENABLE });
  };
  /* for middle pane */
  const primaryIcon = [{ label: 'sift', onClick: onClickFooter, Icon: FilterList }];
  const secondaryIcon = [
    { label: 'suspended', onClick: onClickFooter, Icon: FilterList },
    { label: 'terminated', onClick: onClickFooter, Icon: FilterList }
  ];

  const openListPopup = (e) => {
    console.log(e.currentTarget.getAttribute('tag'));
    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: 'item',
        popupHeaderOneBadgeOne: '',
        isPopUpValue: '',
        popupOpenType: 'primary',
        popupContentArrValue: ASSESSEES_SUBMODULE_REVIEW_LIST_POPUP_OPTION,
        selectedTagValue: e.currentTarget.getAttribute('tag'),
        selectedTagStatus: e.currentTarget.getAttribute('status')
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: ASSESSEES_SUBMODULE_REVIEW_LIST_POPUP_OPTION
      }
    });
  };
  const itemTotalForVer =
    assessmentResponseObject.informationFramework?.assessmentSectionFramework[
      assessmentSelecedSection
    ];
  console.log('itemTotalForVer', itemTotalForVer?.assessmentSectionItemTotal);
  console.log('listDistinctData', listDistinctData);
  return (
    <div>
      {listDistinctData && (
        <Card
          textOneOne={listDistinctData?.assessmentSectionName}
          textTwoOne={listDistinctData?.assessmentSectionDescription}
          IconOne={CrossIcon}
          isIcon={true}
          labelTwoTwo={'version'}
          onClickIconOne={closeRelatedList}
          isAlliance
          relatedCardFixed={true}
          className={'iguru-iconboxSVG'}
        />
      )}
      {listDistinctData &&
        listDistinctData?.item.map((item, index) => {
          return (
            <div className="containerPadding" key={index}>
              <ReviewList
                className=""
                id={index}
                tag={item?.itemTagPrimary}
                // isSelectedReviewList={middlePaneSelectedValue === item?.id}
                status={item.informationEngagement?.itemStatus || 'published'}
                actualStatus={item.informationEngagement?.itemStatus}
                textOne={item.informationBasic?.itemName}
                textTwo={item.informationBasic?.itemDescription}
                isTooltipActive={false}
                onClickEvent={openListPopup}
                isSelectActive={isSelectActive}
                isSelected={selectedTagsArray.includes(item?.itemTagPrimary)}
                onClickCheckBox={(event) => {
                  !isListLoading &&
                  onClickCheckBoxSelectionForVersion(
                    selectedTagsArray,
                    unselectedTagsArray,
                    event,
                    dispatch,
                    itemTotalForVer?.assessmentSectionItemTotal
                  );
                }}
                // onClickCheckBox={(event) => {
                //   itemTotalForVer?.assessmentSectionItemTotal
                //     ? selectedTagsArray.length+1 < itemTotalForVer?.assessmentSectionItemTotal &&
                //       onClickCheckBoxSelection(
                //         selectedTagsArray,
                //         unselectedTagsArray,
                //         event,
                //         dispatch
                //       )
                //     : onClickCheckBoxSelection(
                //         selectedTagsArray,
                //         unselectedTagsArray,
                //         event,
                //         dispatch
                //       );
                // }}
              />
            </div>
          );
        })}
      {isListLoading ? <div> <ListLoader /> </div> : <div style={{ height: "60px", width: "100%" }}></div>}
      {FilterMode === 'assessmentSectionItemRevise' && (
        <FooterIconTwo
          FilterModeEnable={isShowReviseIcon}
          FilterMode={FilterMode}
          onClick={onClickRevise}
          primaryIcon={revisePrimaryIcon}
          secondaryIcon={reviseSecondaryIcons}
        />
      )}
      {FilterMode === 'itemGroupItemDistinctinactive' && (
        <FooterIconTwo
          FilterModeEnable={isShowReviseIcon}
          FilterMode={FilterMode}
          onClick={onClickRevise}
          primaryIcon={primaryIcon}
          secondaryIcon={secondaryIcon}
        />
      )}
    </div>
  );
};
export default AssessmentSectionItemDistinctReviewList;
