import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CLEAR_DISPLAY_PANE_THREE,
  FILTERMODE_ENABLE,
  GET_ASSIGNMENT_REPORT_SAGA,
  LOADER_START,
  LOADER_STOP,
  POPUP_CLOSE,
  POPUP_OPEN,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MIDDLEPANE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_POPUP_STATE
} from '../actionType';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import Card from '../Molecules/Card/Card';
import CrossIcon from '@material-ui/icons/Clear';
import { ASSESSEE_REPORT_POPUP } from '../PopUpConfig';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import { FilterList } from '@material-ui/icons';
import store from '../store';

const AssignmentDistinctReportMetricReviewList = (props) => {
  const dispatch = useDispatch();
  const {
    middlePaneSelectedValue,
    reviewListDistinctData,
    relatedReviewListDistinctData,
    assignmentReportsData,
    assignmentReportResponse,
    currentRevisedAssignmentType,
    selectedAssociateInfo
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { FilterModeEnable, FilterMode } = useSelector((state) => state.FilterReducer);
  const { responseObject } = useSelector((state) => state.DisplayPaneThreeReducer);

  const onClickFooter = (e) => {
    // console.log('assignmentReportResponse',assignmentReportResponse);
    let siftValue = e.currentTarget.getAttribute('data-value');
    // console.log(siftValue)
    dispatch({ type: FILTERMODE_ENABLE });

    if (siftValue !== 'sift') {

      let requestObj = {
        module: 'Assessee-Assignment',
        action: 'Merit-List',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          assignmentId: relatedReviewListDistinctData[0]?.assignmentId,
          resultResponse: 'siftLabel',
          siftLabel: siftValue,

        }
      };
      // console.log(requestObj)
      dispatch({ type: LOADER_START })
      dispatch({
        type: GET_ASSIGNMENT_REPORT_SAGA,
        payload: {
          request: requestObj,
          assignmentId: relatedReviewListDistinctData[0]?.assignmentId,
          assignmentName: relatedReviewListDistinctData[0]?.assignmentName,
          assignmentDescription: relatedReviewListDistinctData[0]?.assignmentDescription,
          isRightPaneReview: false,
          righpaneheaderOne: 'reports',
          reportId: relatedReviewListDistinctData[0]?.reportId,
          reportName: relatedReviewListDistinctData[0]?.reportName,
          reportDescription: relatedReviewListDistinctData[0]?.reportDescription
        }
      });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'relatedReviewListDistinctData', value: [] }
      });
      dispatch({ type: POPUP_CLOSE });
    }

  };
  let primaryIcon = [];
  let secondaryIcon = [];
  let order = { 'excellent-fit': 1, 'good-fit': 2, 'moderate-fit': 3, 'low-risk': 4, 'high-risk': 5, default: Number.MAX_VALUE };
  if (assignmentReportResponse?.informationFramework?.assignmentGaugeFramework.length > 0) {

    primaryIcon.push({ label: 'sift', onClick: onClickFooter, Icon: FilterList })
    let gauge = assignmentReportResponse?.informationFramework?.assignmentGaugeFramework[0].iGaugeOccupationFrameworkOneGaugeMetric;
    gauge.forEach(ele => {
      secondaryIcon.push(
        { label: ele.iGaugeOccupationFrameworkOneGaugeMetricLabel, onClick: onClickFooter, Icon: FilterList }
      )
    });

  }
  secondaryIcon.sort((a, b) => (order[a.label] || order.default) - (order[b.label] || order.default) ||
    a.label > b.label || -(a.label < b.label)
  );
  const closeRelatedList = () => {
    dispatch({
      type: SET_MIDDLEPANE_STATE,
      payload: {
        middlePaneHeader: 'assignments',
        middlePaneHeaderBadgeOne: 'distinct',
        middlePaneHeaderBadgeTwo: 'active',
        middlePaneHeaderBadgeThree: '',
        middlePaneHeaderBadgeFour: '',
        typeOfMiddlePaneList: 'assignmentDistinctReviewList',
        scanCount: reviewListDistinctData.length,
        showMiddlePaneState: true,
        middlePaneHeaderDuplicate:
          store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
        middlePaneHeaderDuplicateBadgeOne:
          store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderDuplicateBadgeTwo:
          store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
        middlePaneHeaderDuplicateBadgeThree:
          store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree, //
        middlePaneHeaderDuplicateBadgeFour:
          store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour, //
        middlePaneHeaderDuplicateOne: store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOne,
        middlePaneHeaderDuplicateOneBadgeOne: store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeOne,
        middlePaneHeaderDuplicateOneBadgeTwo: store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeTwo,
        middlePaneHeaderDuplicateOneBadgeThree: store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeThree,
        middlePaneHeaderDuplicateOneBadgeFour: store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeFour,
        duplicateScanCount: store.getState().DisplayPaneTwoReducer.duplicateScanCount,
      }
    });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
    dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
  };
  const closeRelatedList2 = () => {
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'typeOfMiddlePaneList', value: '' }
    });
    dispatch({ type: LOADER_START });
    let assignmentReportsData2 = assignmentReportsData
    if (currentRevisedAssignmentType === 'iGuru Analytics_Occupation') {
      assignmentReportsData2 = [
        {
          id: 4,
          reportName: 'Merit List',
          reportDescription: 'Culture-Fit'
        },
        {
          id: 6,
          reportName: 'Merit List',
          reportDescription: 'Job-Fit'
        },
        {
          id: 9,
          reportName: 'Merit List',
          reportDescription: 'Work-Fit'
        },
      ]
    } else {
      assignmentReportsData2 = [
        {
          id: 1,
          reportName: 'Assignment Report',
          // reportDescription: 'Bespoke'
        },
      ]
    }

    let response = {
      report: assignmentReportsData2,
      assingmentId: relatedReviewListDistinctData[0]?.assignmentId,
      assignmentName: relatedReviewListDistinctData[0]?.assignmentName,
      assignmentDescription: relatedReviewListDistinctData[0]?.assignmentDescription
    };
    setTimeout(function () {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'relatedReviewListDistinctData', value: [response] }
      });
      dispatch({
        type: SET_MIDDLEPANE_STATE,
        payload: {
          middlePaneHeader: 'assignment',
          middlePaneHeaderBadgeOne: 'reports',
          middlePaneHeaderBadgeTwo: '',
          middlePaneHeaderBadgeThree: '',
          middlePaneHeaderBadgeFour: '',
          typeOfMiddlePaneList: 'assignmentReportReviewList',
          scanCount: (assignmentReportsData2 && assignmentReportsData2.length) || 0,
          showMiddlePaneState: true,
          middlePaneHeaderDuplicate:
            store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
          middlePaneHeaderDuplicateBadgeOne:
            store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
          middlePaneHeaderDuplicateBadgeTwo:
            store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
          middlePaneHeaderDuplicateBadgeThree:
            store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree, //
          middlePaneHeaderDuplicateBadgeFour:
            store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour, //
          middlePaneHeaderDuplicateOne: store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOne,
          middlePaneHeaderDuplicateOneBadgeOne: store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeOne,
          middlePaneHeaderDuplicateOneBadgeTwo: store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeTwo,
          middlePaneHeaderDuplicateOneBadgeThree: store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeThree,
          middlePaneHeaderDuplicateOneBadgeFour: store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeFour,
          duplicateScanCount: store.getState().DisplayPaneTwoReducer.duplicateScanCount,
        }
      });

      dispatch({ type: LOADER_STOP });
    }, 1000);
    dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  };
  const listDistinctData = relatedReviewListDistinctData[0];
  const openListPopup = (e) => {
    console.log(e.currentTarget.getAttribute('id'));
    let optArr = ASSESSEE_REPORT_POPUP;
    let tempArr = [];
    optArr.map((element) => {
      if (element.data === 'review') {
        tempArr.push(element);
      }
    });
    tempArr = [
      { ...tempArr[0], data: 'analytics', dataValue: 'reviewReport', dataKey: 'reviewReport' }
    ];
    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: 'assessee',
        popupHeaderOneBadgeOne: 'distinct',
        isPopUpValue: '',
        popupOpenType: 'primary',
        popupContentArrValue: tempArr,
        selectedTagValue: e.currentTarget.getAttribute('tag'),
        selectedTagStatus: e.currentTarget.getAttribute('id')
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: tempArr
      }
    });
  };

  let backgroundColorObj = {
    "excellent-fit": '#B1DE84',
    "good-fit": '#CBE9AD',
    "moderate-fit": '#E5F4D6',
    "low-risk": '#CCEFFF',
    "high-risk": '#99DFFF',
  }

  return (
    <div>
      {listDistinctData && (
        <React.Fragment>
          <Card
            textOneOne={listDistinctData?.assignmentName}
            textTwoOne={listDistinctData?.assignmentDescription}
            IconOne={CrossIcon}
            isIcon={true}
            labelTwoTwo={'assignment'}
            onClickIconOne={closeRelatedList}
            isAlliance
            relatedCardFixed={true}
            className={'iguru-iconboxSVG'}
          />
          <Card
            textOneOne={listDistinctData?.reportName}
            textTwoOne={listDistinctData?.reportDescription}
            IconOne={CrossIcon}
            isIcon={true}
            labelTwoTwo={'report'}
            onClickIconOne={closeRelatedList2}
            isAlliance
            relatedCardFixed={true}
            className={'iguru-iconboxSVG'}
          />
        </React.Fragment>
      )}
      {listDistinctData &&
        listDistinctData?.assignmentReport?.map((item, index) => {
          return (
            <div className="containerPadding" key={index}>
              <ReviewList
                className=""
                // customBackgroundColor={'#7DC832'}
                customBackgroundColor={backgroundColorObj[item?.assesseeAssignmentCultureJobProfileDistinct[0].assignmentGaugeLabel]}
                id={item?.assignmentId}
                tag={item?.assesseeId}
                isSelectedReviewList={middlePaneSelectedValue === item?.assesseeId}
                status={listDistinctData?.reportDescription}
                actualStatus={''}
                isInformation={true}
                isInformationValue={
                  (listDistinctData?.reportDescription === 'Work-Fit' &&
                    item?.assesseeAssignmentCultureJobProfileDistinct?.length > 0 &&
                    item?.assesseeAssignmentCultureJobProfileDistinct[0].overallFit?.toFixed(2) +
                    '%') ||
                  (listDistinctData?.reportDescription === 'Culture-Fit' &&
                    item?.assesseeAssignmentCultureJobProfileDistinct?.length > 0 &&
                    item?.assesseeAssignmentCultureJobProfileDistinct[0].cultureFit?.toFixed(2) +
                    '%') ||
                  (listDistinctData?.reportDescription === 'Job-Fit' &&
                    item?.assesseeAssignmentCultureJobProfileDistinct?.length > 0 &&
                    item?.assesseeAssignmentCultureJobProfileDistinct[0].jobFit?.toFixed(2) +
                    '%') ||
                  ''
                }
                textOne={item?.assessee?.assesseeNameFirst + ' ' + item?.assessee?.assesseeNameLast}
                textTwo={item?.assessee?.assesseeAlias}
                statusTwo={
                  item?.assesseeAssignmentCultureJobProfileDistinct?.length > 0 &&
                  item?.assesseeAssignmentCultureJobProfileDistinct[0].assignmentGaugeLabel
                }
                isInformationTwo={true}
                isInformationTwoValue={
                  (item?.assesseeAssignmentCultureJobProfileDistinct?.length > 0 &&
                    ((item?.assesseeAssignmentCultureJobProfileDistinct[0].assignmentGaugeLabel ===
                      'excellent-fit' &&
                      '<span><b>&#10003;&#10003;</b></span>') ||
                      (item?.assesseeAssignmentCultureJobProfileDistinct[0].assignmentGaugeLabel ===
                        'good-fit' &&
                        '<span><b>&#10003;</b></span>') ||
                      (item?.assesseeAssignmentCultureJobProfileDistinct[0].assignmentGaugeLabel ===
                        'moderate-fit' &&
                        '<span><b>&#33;</b></span>') ||
                      (item?.assesseeAssignmentCultureJobProfileDistinct[0].assignmentGaugeLabel ===
                        'low-risk' &&
                        '<span><b>&#10007;</b></span>') ||
                      (item?.assesseeAssignmentCultureJobProfileDistinct[0].assignmentGaugeLabel ===
                        'high-risk' &&
                        '<span><b>&#10007;&#10007;</b></span>'))) ||
                  ''
                }
                isTooltipActive={false}
                onClickEvent={openListPopup}
              />
            </div>
          );
        })}

      {secondaryIcon.length > 0 && (<FooterIconTwo
        FilterModeEnable={FilterModeEnable}
        FilterMode={FilterMode}
        onClick={onClickFooter}
        primaryIcon={primaryIcon}
        secondaryIcon={secondaryIcon}
      />)
      }
    </div>
  );
};
export default AssignmentDistinctReportMetricReviewList;
