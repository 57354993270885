import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PopupHeader from "../Molecules/PopUp/PopUpHeader";
import Popup from "../Molecules/PopUp/PopUp";
import "../Molecules/PopUp/PopUp.css";
import { DialogContent, FormControl, FormHelperText } from "@material-ui/core";
import InputFeild from "../Atoms/InputField/InputField";
import SelectField from "../Atoms/SelectField/SelectField";
import UploadIcon from "@material-ui/icons/Publish";
import DownloadIcon from "@material-ui/icons/GetApp";
import {
  SET_POPUP_VALUE,
  GET_ASSOCIATE_INFO_SAGA,
  LOADER_START,
  POPUP_CLOSE,
  SET_MIDDLEPANE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_SECONDARY_CREATE_OPTION_VALUE,
  SET_POPUP_STATE,
  SET_SECONDARY_OPTION_VALUE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  FILTERMODE,
  SET_REQUEST_OBJECT,
  GET_ASSESSEE_ROLE_REVIEW_LIST_SAGA,
  GET_ASSOCIATE_ROLE_REVIEW_LIST_SAGA,
  GET_ASSIGNMENT_GROUP_REVIEW_LIST_SAGA,
  CLEAR_DISPLAY_PANE_THREE,
  SET_POPUP_SINGLE_STATE,
  CLEAR_ROLE_REDUCER_STATE,
  SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT,
  CLEAR_NODE_REDUCER_STATE,
  CLEAR_GROUP_REDUCER_STATE,
  CLEAR_TYPE_REDUCER_STATE,
  GET_ASSESSEEROLE_ASSESSEE_REVIEW_LIST,
  ASSOCIATE_EXCHANGE_REVISE_SAGA,
  CLEAR_PANE_TWO_REDUCER,
  REVIEWLIST_DISTINCT_DATA,
  CLEAR_GUAGE_REDUCER_STATE,
  CREATE_GAUGE_SAGA,
  SET_PAGE_COUNT,
  SET_USER,
  GET_ASSESSEE_GROUP_REVIEW_LIST_SAGA,
  SET_CORE_GROUP_REVIEW_LIST_REQ_OBJECT,
  GET_ASSESSMENT_GROUP_REVIEW_LIST_SAGA,
  GET_ASSOCIATE_GROUP_REVIEW_LIST_SAGA,
  GET_JOBPROFILE_GROUP_REVIEW_LIST_SAGA,
  GET_ITEM_GROUP_REVIEW_LIST_SAGA,
  GET_CULTUREPROFILE_GROUP_REVIEW_LIST_SAGA,
  CLEAR_IGAUGE_REDUCER,
} from "../actionType";
import {
  NOTIFICATION_REPORT_POPUP,
  ASSOCIATE_CARD_POPUP_OPTION,
  MODULE_POPUP_OPTION,
  REVIEW_REVISE_POPUP,
  EXCHANGE_POPUP_OPTION,
  REVIEW_POPUP_OPTIONS,
  REVIEW_DISTINCT_POPUP_OPTION,
  CREATE_INFORMATION_POPUP,
  ASSESSEE_REVIEW_REVISE_POPUP,
  NODE_POPUP_OPTION,
  SELF_POPUP,
  GROUP_TYPE_POPUP_OPTION,
  GROUP_POPUP_OPTION,
  ANALYTICS_POPUP,
  MINE_REVIEW,
  UPLOAD_DOWNLOAD_POPUP,
  ROLE_POPUP_OPTION,
  TYPE_POPUP_OPTION,
  ASSOCIATE_SELF_POPUP,
  UPLOAD_DOWNLOAD_ASSESSEE_POPUP,
  UPLOAD_DOWNLOAD_ASSESSEE_SUBMODULE_POPUP,
  UPLOAD_DOWNLOAD_SUBMODULE_POPUP,
} from "../PopUpConfig";
import JsonRenderComponent from "../Actions/JsonRenderComponent";
import {
  makeAssociateRoleObj,
  imageUploadMethod,
  getPopUpOptionList,
  setAssociateCardPermissionInJson,
  getGuageDistinctApiCall,
} from "../Actions/GenericActions";
import {
  AssociateSetUpApiCalls,
  createAssociateNodeFunction,
  getAssociateGroupCreateAPI,
  getAssociateGroupDistinctApiCall,
  getAssociatesTypeApiCall,
  getInternalNodeApiCall,
  getInternalNodeWithPermissionsApiCall,
} from "../Actions/AssociateModuleAction";
import {
  assesseeCreateApiCalls,
  getAdminManagerDistinctApiCall,
  getAdminManagerRoleApiCall,
  getAssesseeGroupCreateAPI,
  getAssesseeGroupDistinctApiCall,
  getAssesseeRoleAssesseeReqObj,
  getAssesseeRoleDistinctApiCall,
  getAssesseeTypeApiCall,
} from "../Actions/AssesseeModuleAction";
import {
  createItemPopupApiCall,
  getItemGroupCreateAPI,
  getItemGroupDistinctApiCall,
  getItemsDistinctApiCall,
  getItemsTypeApiCall,
} from "../Actions/ItemModuleAction";
import IconButton from "../Molecules/IconButton/IconButton";
import { Fragment } from "react";
import {
  getAssessmentGroupApiCall,
  getAssessmentGroupCreateAPI,
  getAssessmentTypeApiCall,
} from "../Actions/AssessmentModuleAction";
import { ADMINISTRATOR_SECONDARY_ID } from "../endpoints";
import {
  assignmentsGroupApiCall,
  assignmentTypeApiCall,
  getAssignmentGroupCreateAPI,
} from "../Actions/AssignmentModuleAction";
import {
  cultureProfileCreatePopup,
  getCultureProfileDiamentionList,
  getCultureProfileGroupApiCall,
  getCultureProfileGroupCreateAPI,
  getCultureProfilesDistinctApiCall,
  getCultureProfileTypeApiCall,
  getMarketPlaceCultureProfileGroupApiCall,
  getMarketplaceCultureProfilesDistinctApiCall,
  getMarketPlaceCultureProfileTypeApiCall,
} from "../Actions/ActionCultureProfile";
import {
  getJobProfileGroupApiCall,
  getJobProfileGroupCreateAPI,
  getJobProfilesDistinctApiCall,
  getJobProfileTypeApiCall,
  jobProfileCreatePopup,
} from "../Actions/ActionJobProfile";
const PopUpDisplayPanelAssociate = (props) => {
  const {
    popupHeaderOne,
    popupHeaderOneBadgeOne,
    popupHeaderOneBadgeTwo,
    popupHeaderOneBadgeThree,
    popupOpenType,
    secondaryOptionCheckValue,
    tertiaryOptionCheckValue,
    forthOptionCheckValue,
    currentPopUpOption,
    popupContentArrValue,
    originPrimaryArrOprion,
    selectedSectionPopup
  } = useSelector((state) => state.PopUpReducer);
  const { userData, assesseePermission } = useSelector(
    (state) => state.UserReducer
  );
  const {
    countPage,
    selectedAssociateInfo,
    signedAssesseePermission,
    signInRes,
    paneOneSelectedSection,
    itemDistictPermssion,
    assignmentCreateType
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const [isReviseMode, setIsReviseMode] = useState(false);
  const [exchageMode, setexchageMode] = useState(false);
  const [exchageLocation, setexchageLocation] = useState("");
  const [fileName, setFileName] = useState("");
  const [duplicateHeader, setDuplicateHeader] = useState("");
  const [duplicateBadgeOne, setDuplicateBadgeOne] = useState("");
  const [duplicateBadgeTwo, setDuplicateBadgeTwo] = useState("");
  const [duplicateValueArr, setDuplicateValueArr] = useState("");
  const [duplicateSecondaryOpt, setDuplicateSecondaryOpt] = useState("");
  const [filepathURL, setFilePathURL] = useState("");
  const dispatch = useDispatch();
  const { headerPanelColour = "displayPaneLeft", isActive } = props;
  let originArr = ASSOCIATE_CARD_POPUP_OPTION;
  const inputFile = useRef(null);
  useEffect(() => {
    setexchageMode(false);
  }, []);

  const resetDataFunction = (cardValue) => {
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: "allocateStr", value: "" },
    });
    // document.getElementById('middleComponentId').scrollTop = '0px';
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: { stateName: "cardValue", value: cardValue || "Card" },
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: "typeOfMiddlePaneList", value: "" },
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: "middlePaneSelectedValue", value: "" },
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: "assignmentSelectedGuage", value: "" },
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: "selectedFlagedArray", value: [] },
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: "unselectedFlagedArray", value: [] },
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: "selectedTagsArray", value: [] },
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: "unselectedTagsArray", value: [] },
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: "flagedValue", value: "" },
    });
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: { stateName: "selectionValue", value: "" },
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: "nodeViewState", value: "" },
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: "scanString", value: "" },
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'allocatedTagsArray', value: [] }
    });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  };
  const clearMiddlePaneInfo = () => {
    dispatch({
      type: SET_MIDDLEPANE_STATE,
      payload: {
        middlePaneHeader: "",
        middlePaneHeaderBadgeOne: "",
        middlePaneHeaderBadgeTwo: "",
        middlePaneHeaderBadgeThree: "",
        middlePaneHeaderBadgeFour: "",
        typeOfMiddlePaneList: "",
        scanCount: "",
        showMiddlePaneState: false,
      },
    });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  };
  const setSecondaryOptionValue = (e) => {
    if (popupHeaderOne === "roles") {
      if (
        e.currentTarget.getAttribute("data-value") === "assessees" ||
        e.currentTarget.getAttribute("data-value") === "associates"
      ) {
        dispatch({
          type: SET_SECONDARY_OPTION_VALUE,
          payload: {
            dataValue: e.currentTarget.getAttribute("data-value"),
            permissionObject: signedAssesseePermission?.associateSelfPermission,
            dataPermission: e.currentTarget.getAttribute("data-permission"),
            originPrimaryArrOprion: originPrimaryArrOprion
              ? originPrimaryArrOprion
              : popupContentArrValue,
          },
        });
      }
    } else if (
      popupHeaderOne === "administrators" ||
      popupHeaderOne === "managers" ||
      popupHeaderOne === "assistants" ||
      popupHeaderOne === "items" ||
      popupHeaderOne === "interviews" ||
      // popupHeaderOne === 'exchange' ||
      popupHeaderOne === "assessment centres" ||
      popupHeaderOne === "culture-profiles" ||
      popupHeaderOne === "job-profiles" ||
      popupHeaderOne === "education-profiles" ||
      popupHeaderOne === "personal-profiles" ||
      popupHeaderOne === "occupation-profiles" ||
      popupHeaderOne === "profilers" ||
      popupHeaderOne === "gauges" ||
      popupHeaderOne === "associate"
    ) {
      console.log(popupHeaderOne);
      dispatch({
        type: SET_SECONDARY_CREATE_OPTION_VALUE,
        payload: e.currentTarget.getAttribute("data-value"),
      });
    } else if (popupHeaderOneBadgeOne === "exchange") {
      dispatch({
        type: SET_SECONDARY_CREATE_OPTION_VALUE,
        payload: e.currentTarget.getAttribute("data-value"),
      });
    } else if (popupHeaderOneBadgeOne === "upload" || popupHeaderOneBadgeOne === "download") {
      dispatch({
        type: SET_SECONDARY_CREATE_OPTION_VALUE,
        payload: e.currentTarget.getAttribute("data-value"),
      });
    } else {
      console.log(popupHeaderOne);
      dispatch({
        type: SET_SECONDARY_OPTION_VALUE,
        payload: {
          dataValue: e.currentTarget.getAttribute("data-value"),
          permissionObj:
            signedAssesseePermission?.iGuruAnalyticDistinctPermission,
          permissionObject: signedAssesseePermission?.associateSelfPermission,
          dataPermission: e.currentTarget.getAttribute("data-permission"),
          originPrimaryArrOprion: originPrimaryArrOprion
            ? originPrimaryArrOprion
            : popupContentArrValue,
          popupHeaderOne: popupHeaderOne
        },
      });
    }

    // dispatch({type:SET_POPUP_SINGLE_STATE,payload:{stateName:'popupContentArrValue',value:''}})
  };
  const fileUploadOnAWS = (e) => {
    let pathUrl = filepathURL.substring(
      filepathURL.indexOf("com/") + 4,
      filepathURL.length
    );
    let requestBody = {
      module: "Assessment-Exchange",
      action: "Exchange",
      requestObject: {
        assessmentExchangeDocumentPath: pathUrl,
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag
            .associateTagPrimary,
      },
    };
    dispatch({
      type: ASSOCIATE_EXCHANGE_REVISE_SAGA,
      payload: {
        reqBody: requestBody,
      },
    });
    setexchageMode(false);
    setFileName("");
    setexchageLocation("");
    dispatch({ type: LOADER_START });
    dispatch({ type: POPUP_CLOSE });
  };
  const fileUploadOnChange = async (e) => {
    const { files } = e.target;
    if (files && files.length) {
      const filename = files[0].name;
      let filePath = await imageUploadMethod(files[0]);
      console.log(filePath);
      setFilePathURL(filePath.file.url);
      var parts = filename.split(".");
      const fileType = parts[parts.length - 1];
      console.log("fileType", fileType);
      setFileName(filename);
    }
  };
  const onChangeExchangeLocation = (e) => {
    console.log(e);
    inputFile.current.click();
    setexchageLocation(e.target.value);
  };
  const ChangeOptionPopup = (e) => {
    console.log("clickValue", e.currentTarget.getAttribute("data-value"));
    let clickValue = e.currentTarget.getAttribute("data-value");
    let revisePopupHeaderOne = "";
    let revisepopupHeaderOneBadgeOne = "";
    let revisepopupHeaderOneBadgeTwo = "";
    let reviseisPopUpValue = "";
    let revisePopupType = "primary";
    let reviseSecondaryOptionCheckValue = "";
    let reviseThiredOptionCheckValue = "";
    let reviseForthOptionCheckValue = "";
    let valueArr = [];

    let assesseeDistinctPermission =
      signedAssesseePermission?.associateSelfPermission;

    if (
      (clickValue === "administrators" || clickValue === "managers" || clickValue === "assistants") &&
      popupHeaderOne !== "culture-profiles" &&
      popupHeaderOne !== "profilers" &&
      popupHeaderOne !== "gauges" &&
      popupHeaderOne !== "items" &&
      popupHeaderOne !== "job-profiles"
    ) {
      let tempArr = getPopUpOptionList(
        MODULE_POPUP_OPTION,
        signedAssesseePermission?.associateSelfPermission?.assesseeAssesseeDistinctPermission
      );

      revisePopupHeaderOne = clickValue;
      revisepopupHeaderOneBadgeOne = "";
      reviseisPopUpValue = "ASSOCIATE_CARD_POPUP";
      revisePopupType = "secondary";
      valueArr = tempArr;
      originArr = tempArr;
      reviseSecondaryOptionCheckValue = "all";
    }
    if (
      clickValue === "mine" ||
      clickValue === "interviews" ||
      clickValue === "marketplace"
    ) {
      revisePopupHeaderOne = clickValue;
      revisepopupHeaderOneBadgeOne = "";
      reviseisPopUpValue = "ASSOCIATE_CARD_POPUP";
      revisePopupType = "secondary";
      valueArr = MODULE_POPUP_OPTION;
      originArr = MODULE_POPUP_OPTION;
      reviseSecondaryOptionCheckValue = "all";
    }
    if (
      (popupHeaderOne === "administrators" ||
        popupHeaderOne === "managers" ||
        popupHeaderOne === "assistants" ||
        popupHeaderOne === "interviews") &&
      clickValue === "review"
    ) {
      revisePopupHeaderOne = popupHeaderOne;
      revisepopupHeaderOneBadgeOne = "review";
      reviseisPopUpValue = "ASSOCIATE_CARD_POPUP";
      revisePopupType = "secondary";
      valueArr = setAssociateCardPermissionInJson(
        REVIEW_POPUP_OPTIONS,
        signedAssesseePermission.assesseeDistinctPermission,
        "optionPrimary"
      );
      originArr = REVIEW_POPUP_OPTIONS;
      reviseSecondaryOptionCheckValue = "active";
    }
    if (clickValue === "associatereview" || clickValue === "associaterevise") {
      revisePopupHeaderOne = "associate";
      revisepopupHeaderOneBadgeOne = "self";
      revisepopupHeaderOneBadgeTwo =
        clickValue === "associatereview" ? "review" : "revise";
      reviseisPopUpValue = "ASSOCIATE_CARD_POPUP";
      revisePopupType = "secondary";
      originArr = JSON.parse(JSON.stringify(REVIEW_REVISE_POPUP));
      reviseSecondaryOptionCheckValue =
        signedAssesseePermission?.associateSelfPermission
          ?.assesseeAssociateDistinctPermission
          ?.assesseePermissionInformation || "all";
      REVIEW_REVISE_POPUP.forEach((ele) => {
        if (
          ele.dataValue === "all" &&
          reviseSecondaryOptionCheckValue === "key"
        ) {
          ele.disabled = true;
        }
        valueArr.push(ele);
      });
    }
    if (
      clickValue === "nodes" &&
      popupOpenType === "primary" &&
      popupHeaderOne !== "administrators" &&
      popupHeaderOne !== "items" &&
      popupHeaderOne !== "culture-profiles" &&
      popupHeaderOne !== "job-profiles" &&
      popupHeaderOne !== "profilers" &&
      popupHeaderOne !== "gauges" &&
      popupHeaderOne !== "interviews" &&
      popupHeaderOne !== "assistants" &&
      popupHeaderOne !== "managers"
    ) {
      revisePopupHeaderOne = clickValue;
      revisepopupHeaderOneBadgeOne = "";
      reviseisPopUpValue = "ASSOCIATE_CARD_POPUP";
      revisePopupType = "secondary";
      originArr = JSON.parse(JSON.stringify(NODE_POPUP_OPTION));
      valueArr = setAssociateCardPermissionInJson(
        NODE_POPUP_OPTION,
        assesseeDistinctPermission,
        "optionPrimary"
      );
      reviseSecondaryOptionCheckValue = "associates";
    }
    if (
      clickValue === "groups" &&
      popupHeaderOne !== "administrators" &&
      popupHeaderOne !== "managers" &&
      popupHeaderOne !== "assistants" &&
      popupHeaderOne !== "assessees" &&
      popupHeaderOne !== "assessments" &&
      popupHeaderOne !== "assignments" &&
      popupHeaderOne !== "profilers" &&
      popupHeaderOne !== "gauges" &&
      popupHeaderOne !== "culture-profiles" &&
      popupHeaderOne !== "job-profiles" &&
      popupHeaderOne !== "items"
    ) {
      originArr = JSON.parse(JSON.stringify(GROUP_POPUP_OPTION));
      let popupContentArr = setAssociateCardPermissionInJson(
        GROUP_POPUP_OPTION,
        assesseeDistinctPermission,
        "optionSecondary"
      );
      revisePopupHeaderOne = clickValue;
      revisepopupHeaderOneBadgeOne = "";
      reviseisPopUpValue = "ASSOCIATE_CARD_POPUP";
      revisePopupType = "secondary";
      valueArr = popupContentArr;
      reviseSecondaryOptionCheckValue = "";
    }
    if (
      clickValue === "types" &&
      popupHeaderOne !== "administrators" &&
      popupHeaderOne !== "managers" &&
      popupHeaderOne !== "assistants" &&
      popupHeaderOne !== "assessees" &&
      popupHeaderOne !== "assessments" &&
      popupHeaderOne !== "profilers" &&
      popupHeaderOne !== "gauges" &&
      popupHeaderOne !== "culture-profiles" &&
      popupHeaderOne !== "job-profiles" &&
      popupHeaderOne !== "items"
    ) {
      originArr = JSON.parse(JSON.stringify(TYPE_POPUP_OPTION));
      let popupContentArr = setAssociateCardPermissionInJson(
        TYPE_POPUP_OPTION,
        assesseeDistinctPermission,
        "optionSecondary"
      );
      revisePopupHeaderOne = clickValue;
      revisepopupHeaderOneBadgeOne = "";
      reviseisPopUpValue = "ASSOCIATE_CARD_POPUP";
      revisePopupType = "secondary";
      valueArr = popupContentArr;
      reviseSecondaryOptionCheckValue = "";
    }
    if (
      clickValue === "roles" &&
      popupHeaderOne !== "administrators" &&
      popupHeaderOne !== "managers" &&
      popupHeaderOne !== "assistants" &&
      popupHeaderOne !== "assessees" &&
      popupHeaderOne !== "profilers" &&
      popupHeaderOne !== "gauges" &&
      popupHeaderOne !== "assessments" &&
      popupHeaderOne !== "assessments" &&
      popupHeaderOne !== "items"
    ) {
      originArr = JSON.parse(JSON.stringify(ROLE_POPUP_OPTION));
      let popupContentArr = setAssociateCardPermissionInJson(
        ROLE_POPUP_OPTION,
        assesseeDistinctPermission,
        "optionSecondary"
      );
      revisePopupHeaderOne = clickValue;
      revisepopupHeaderOneBadgeOne = "";
      reviseisPopUpValue = "ASSOCIATE_CARD_POPUP";
      revisePopupType = "secondary";
      valueArr = popupContentArr;
      reviseSecondaryOptionCheckValue = "";
    }
    if (
      (popupHeaderOne === "administrators" || popupHeaderOne === "managers" || popupHeaderOne === "assistants") &&
      clickValue === "roles"
    ) {
      // debugger
      //call admin and manager role api
      getAdminManagerRoleApiCall(
        selectedAssociateInfo,
        secondaryOptionCheckValue,
        countPage,
        popupHeaderOne,
        dispatch
      );
    }

    if (clickValue === "exchange") {
      revisePopupHeaderOne = clickValue;
      revisepopupHeaderOneBadgeOne = "";
      reviseisPopUpValue = "ASSOCIATE_CARD_POPUP";
      revisePopupType = "secondary";
      originArr = JSON.parse(JSON.stringify(EXCHANGE_POPUP_OPTION));
      valueArr = EXCHANGE_POPUP_OPTION;
      reviseSecondaryOptionCheckValue = "";
    }
    if (
      ((clickValue === "download" || clickValue === "upload") &&
        secondaryOptionCheckValue === "assessees") ||
      secondaryOptionCheckValue === "associates" ||
      secondaryOptionCheckValue === "assessments" ||
      secondaryOptionCheckValue === "assignments" ||
      secondaryOptionCheckValue === "culture-profiles" ||
      secondaryOptionCheckValue === "job-profiles" ||
      secondaryOptionCheckValue === "items"
    ) {
      revisePopupHeaderOne = secondaryOptionCheckValue;
      revisepopupHeaderOneBadgeOne = clickValue;
      reviseisPopUpValue = "ASSOCIATE_CARD_POPUP";
      revisePopupType = "secondary";
      originArr = JSON.parse(JSON.stringify(REVIEW_POPUP_OPTIONS));
      console.log('secondaryOptionCheckValue', secondaryOptionCheckValue);
      let permissions =
        secondaryOptionCheckValue === 'assessees'
          ? signedAssesseePermission?.assesseeDistinctPermission
          : secondaryOptionCheckValue === 'associates'
            ? signedAssesseePermission?.associateDistinctPermission
            : secondaryOptionCheckValue === 'assessments'
              ? signedAssesseePermission?.assessmentDistictPermission
              : secondaryOptionCheckValue === 'assignments'
                ? signedAssesseePermission?.assigmentDistictPermission
                : secondaryOptionCheckValue === 'culture-profiles'
                  ? signedAssesseePermission?.iGuruAnalyticDistinctPermission
                  : secondaryOptionCheckValue === 'job-profiles'
                    ? signedAssesseePermission?.iGuruAnalyticDistinctPermission
                    : secondaryOptionCheckValue === 'items'
                      ? signedAssesseePermission?.itemDistictPermssion
                      : null;
      valueArr = setAssociateCardPermissionInJson(
        REVIEW_POPUP_OPTIONS,
        permissions,
        'optionPrimary',
        '',
        '',
        '',
        paneOneSelectedSection
      );
      // valueArr = REVIEW_POPUP_OPTIONS;
      reviseSecondaryOptionCheckValue = "active";
    }
    if (
      (clickValue === 'download' || clickValue === 'upload') &&
      (secondaryOptionCheckValue === 'assessment centres' ||
        secondaryOptionCheckValue === 'interviews')
    ) {
      dispatch({ type: POPUP_CLOSE })
    }
    if (
      (clickValue === "distinct" ||
        clickValue === "groups" ||
        clickValue === "roles" ||
        clickValue === "nodes" ||
        clickValue === "types") &&
      (popupHeaderOne === "assessees" ||
        popupHeaderOne === "associates" ||
        popupHeaderOne === "assessments" ||
        popupHeaderOne === "assignments" ||
        popupHeaderOne === "assessment centres" ||
        popupHeaderOne === "culture-profiles" ||
        popupHeaderOne === "job-profiles" ||
        popupHeaderOne === "education-profiles" ||
        popupHeaderOne === "personal-profiles" ||
        popupHeaderOne === "occupation-profiles" ||
        popupHeaderOne === "interviews" ||
        popupHeaderOne === "items") &&
      (popupHeaderOneBadgeOne === "download" ||
        popupHeaderOneBadgeOne === "upload")
    ) {
      revisePopupHeaderOne = popupHeaderOne;
      revisepopupHeaderOneBadgeOne = "exchange";
      revisepopupHeaderOneBadgeTwo = popupHeaderOneBadgeOne;
      reviseisPopUpValue = "ASSOCIATE_CARD_POPUP";
      revisePopupType = "secondary";
      let tempPopupArr =
        popupHeaderOne === 'assessees' && clickValue === 'distinct'
          ? UPLOAD_DOWNLOAD_ASSESSEE_POPUP
          : popupHeaderOne === 'assessees' && clickValue !== 'distinct'
            ? UPLOAD_DOWNLOAD_ASSESSEE_SUBMODULE_POPUP
            : popupHeaderOne !== 'assessees' && clickValue !== 'distinct'
              ? UPLOAD_DOWNLOAD_SUBMODULE_POPUP
              : UPLOAD_DOWNLOAD_POPUP;
      console.log(tempPopupArr);
      valueArr = tempPopupArr;
      originArr = tempPopupArr;
      reviseSecondaryOptionCheckValue = clickValue === 'distinct' ? 'all' : 'key';
      reviseThiredOptionCheckValue =
        popupHeaderOne === 'assessees' ? 'first-name other-name last-name' : ''
      reviseForthOptionCheckValue = "template";
      setexchageMode(true);
      clearMiddlePaneInfo();
    }
    if (
      clickValue === "information" &&//
      popupHeaderOne === "associate" &&
      (popupHeaderOneBadgeTwo === "review" ||
        popupHeaderOneBadgeTwo === "revise")
    ) {
      dispatch({ type: LOADER_START });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'paneThreeSelectedSection', value: 'section1' }
      });
      let assesseeRoleAssesseeReqBody = getAssesseeRoleAssesseeReqObj(
        selectedAssociateInfo,
        ADMINISTRATOR_SECONDARY_ID,
        "active",
        0,
        countPage
      );
      dispatch({
        type: GET_ASSESSEEROLE_ASSESSEE_REVIEW_LIST,
        payload: {
          request: assesseeRoleAssesseeReqBody,
          HeaderOne: "",
          BadgeOne: "",
          BadgeTwo: "",
          BadgeThree: "",
          isMiddlePaneList: false,
          isAdministratorSecondary: true,
        },
      });

      dispatch({
        type: GET_ASSOCIATE_INFO_SAGA,
        payload: {
          secondaryOptionCheckValue,
          popupHeaderOneBadgeOne,
          isReviseMode,
          reqBody: {
            module: "Associate-Distinct",
            action: "Review",
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement
                  .associateTag.associateTagPrimary,
              isAssociateSelf:true,
              filter: true,
              search: [
                {
                  condition: "and",
                  searchBy: [
                    {
                      dataType: "String",
                      conditionColumn: "id",
                      conditionValue: {
                        condition: "eq",
                        value: {
                          from: selectedAssociateInfo?.associate
                            ?.informationEngagement.associateTag
                            .associateTagPrimary,
                        },
                      },
                    },
                  ],
                },
              ],
            },
          },
        },
      });
      AssociateSetUpApiCalls(
        selectedAssociateInfo,
        selectedAssociateInfo?.associate?.informationEngagement.associateTag
          .associateTagPrimary,
        dispatch
      );

      clearMiddlePaneInfo();
      dispatch({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: "cardValue", value: "Card" },
      });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: {
          stateName: "middlePaneSelectedValue",
          value:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
        },
      });
      let associatePermission = signedAssesseePermission?.associateSelfPermission;
      let popupContentArr = setAssociateCardPermissionInJson(
        ASSOCIATE_SELF_POPUP,
        associatePermission
      );
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: "middlePaneListPopupOptions", value: popupContentArr },
      });
      dispatch({
        type: SET_POPUP_SINGLE_STATE,
        payload: {
          stateName: 'popupOptionArr',
          value: popupContentArr
        }
      });
      dispatch({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: "originPrimaryArrOprion", value: popupContentArr },
      });
      originArr = popupContentArr;
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: {
          stateName: "typeOfMiddlePaneList",
          value: "associateSelfReview",
        },
      });
      dispatch({ type: SET_MOBILE_PANE_STATE, payload: "displayPaneThree" });
    }
    if (clickValue === "associaterevise") {
      setIsReviseMode(true);
    } else {
      setIsReviseMode(false);
    }
    if (clickValue === "switch") {
      if (userData) {
        dispatch({
          type: FILTERMODE,
          payload: { FilterMode: "assesseeRelatedAssociate" },
        });
        dispatch({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: "associate",
            middlePaneHeaderBadgeOne: "active",
            middlePaneHeaderBadgeTwo: "",
            middlePaneHeaderBadgeThree: "",
            middlePaneHeaderBadgeFour: "",
            typeOfMiddlePaneList: "assesseeRelatedAssociate",
            scanCount: signInRes.signInResponse.length,
            showMiddlePaneState: true,
          },
        });
        dispatch({ type: SET_USER, payload: signInRes.signInResponse });
        // dispatch({
        //   type: SET_DISPLAY_TWO_SINGLE_STATE,
        //   payload: { stateName: 'signedAssesseePermission', value: '' }
        // });      
        dispatch({ type: SET_MOBILE_PANE_STATE, payload: "displayPaneTwo" });
        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
      }
    }
    if (clickValue === "items") {
      revisePopupHeaderOne = "items";
      revisepopupHeaderOneBadgeOne = "";
      revisepopupHeaderOneBadgeTwo = "";
      reviseisPopUpValue = "ASSOCIATE_CARD_POPUP";
      revisePopupType = "secondary";
      originArr = JSON.parse(JSON.stringify(MODULE_POPUP_OPTION));
      valueArr = getPopUpOptionList(
        MODULE_POPUP_OPTION,
        signedAssesseePermission.itemDistictPermssion
          .assesseeItemDistinctPermission
      );
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: {
          stateName: 'assignmentCreateType',
          value: 'iGuru_Assignment'
        }
      });
      reviseSecondaryOptionCheckValue = "";
    }
    if (clickValue === "analytics") {
      revisePopupHeaderOne = "analytics";
      revisepopupHeaderOneBadgeOne = "";
      revisepopupHeaderOneBadgeTwo = "";
      reviseisPopUpValue = "ASSOCIATE_CARD_POPUP";
      revisePopupType = "secondary";
      originArr = JSON.parse(JSON.stringify(ANALYTICS_POPUP));
      valueArr = originArr;
      //valueArr = setAssociateCardPermissionInJson(
      //   ANALYTICS_POPUP,
      //   assesseeDistinctPermission,
      //   'optionSecondary'
      // );
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: {
          stateName: 'assignmentCreateType',
          value: 'iGuru_Assignment'
        }
      });
      reviseSecondaryOptionCheckValue = "";
    }
    if (
      clickValue === "create" &&
      (popupHeaderOne === "groups" ||
        popupHeaderOne === "roles" ||
        popupHeaderOne === "types" ||
        popupHeaderOne === "nodes")
    ) {
      revisePopupHeaderOne = secondaryOptionCheckValue;
      revisepopupHeaderOneBadgeOne = popupHeaderOne;
      revisepopupHeaderOneBadgeTwo = "create";
      reviseisPopUpValue = "ASSOCIATE_CARD_POPUP";
      revisePopupType = "secondary";
      originArr = JSON.parse(JSON.stringify(CREATE_INFORMATION_POPUP));
      valueArr = CREATE_INFORMATION_POPUP;
      reviseSecondaryOptionCheckValue = "key";
    }
    if (
      (clickValue === "create" || clickValue === "review") &&
      popupHeaderOne === "items"
    ) {
      console.log("signedAssesseePermission", signedAssesseePermission);
      console.log("assesseeDistinctPermission", assesseeDistinctPermission);
      revisePopupHeaderOne = popupHeaderOne;
      revisepopupHeaderOneBadgeOne = clickValue;
      revisepopupHeaderOneBadgeTwo = "";
      reviseisPopUpValue = "ASSOCIATE_CARD_POPUP";
      revisePopupType = "secondary";
      originArr = JSON.parse(
        JSON.stringify(
          clickValue === "create" ? REVIEW_REVISE_POPUP : REVIEW_POPUP_OPTIONS
        )
      ); //items
      console.log('for item');
      valueArr = setAssociateCardPermissionInJson(
        clickValue === "create" ? REVIEW_REVISE_POPUP : REVIEW_POPUP_OPTIONS,
        signedAssesseePermission.itemDistictPermssion,
        'optionPrimary',
        '',
        '',
        '',
        paneOneSelectedSection
      );
      reviseSecondaryOptionCheckValue =
        clickValue === "create" ? "all" : "active";
    }
    if (
      (clickValue === "create" || clickValue === "review") &&
      popupHeaderOne === "analytics"
    ) {
      console.log("signedAssesseePermission", signedAssesseePermission);
      console.log("secondaryOptionCheckValue", secondaryOptionCheckValue);
      let arrayObj = REVIEW_REVISE_POPUP;
      let reviewPopupOption = [];
      let allorkey = "all";
      if (
        (secondaryOptionCheckValue === "gauges" ||
          secondaryOptionCheckValue === "profilers") &&
        clickValue === "create"
      ) {
        arrayObj = REVIEW_REVISE_POPUP;
        allorkey = "all";
      }
      revisePopupHeaderOne = secondaryOptionCheckValue;
      revisepopupHeaderOneBadgeOne = clickValue;
      revisepopupHeaderOneBadgeTwo = "";
      reviseisPopUpValue = "ASSOCIATE_CARD_POPUP";
      revisePopupType = "secondary";
      valueArr = setAssociateCardPermissionInJson(
        clickValue === "create" ? arrayObj : REVIEW_POPUP_OPTIONS,
        signedAssesseePermission.iGuruAnalyticDistinctPermission,
        "optionPrimary",
        '',
        '',
        '',
        paneOneSelectedSection
      );
      originArr = JSON.stringify(
        clickValue === "create"
          ? CREATE_INFORMATION_POPUP
          : REVIEW_POPUP_OPTIONS
      );
      reviseSecondaryOptionCheckValue =
        clickValue === "create" ? allorkey : "active";
      if (
        (secondaryOptionCheckValue === "gauges" ||
          secondaryOptionCheckValue === "profilers") &&
        clickValue === "review"
      ) {
        valueArr.forEach((ele) => {
          if (
            ele.data === "groups" ||
            ele.data === "nodes" ||
            ele.data === "types"
          )
            ele.disabled = true;
        });
      }
    }
    if (
      (popupHeaderOne === "groups" ||
        popupHeaderOne === "nodes" ||
        popupHeaderOne === "roles" ||
        popupHeaderOne === "types") &&
      clickValue === "review"
    ) {
      revisePopupHeaderOne =
        secondaryOptionCheckValue === "associate"
          ? "associates"
          : secondaryOptionCheckValue;
      revisepopupHeaderOneBadgeOne = popupHeaderOne;
      revisepopupHeaderOneBadgeTwo = "review";
      reviseisPopUpValue = "ASSOCIATE_CARD_POPUP";
      revisePopupType = "secondary";
      originArr = JSON.parse(JSON.stringify(REVIEW_DISTINCT_POPUP_OPTION));
      valueArr = REVIEW_DISTINCT_POPUP_OPTION;
      reviseSecondaryOptionCheckValue = "active";
    }
    if (
      clickValue === "create" &&
      (popupHeaderOne === "mine" || popupHeaderOne === "marketplace")
    ) {
      revisePopupHeaderOne = popupHeaderOne;
      revisepopupHeaderOneBadgeOne = "create";
      revisepopupHeaderOneBadgeTwo = "";
      reviseisPopUpValue = "ASSOCIATE_CARD_POPUP";
      revisePopupType = "secondary";
      originArr = JSON.parse(JSON.stringify(CREATE_INFORMATION_POPUP));
      valueArr = CREATE_INFORMATION_POPUP;
      reviseSecondaryOptionCheckValue = "key";
    }
    if (clickValue === "create" && popupHeaderOne === "interviews") {
      revisePopupHeaderOne = popupHeaderOne;
      revisepopupHeaderOneBadgeOne = "create";
      revisepopupHeaderOneBadgeTwo = "";
      reviseisPopUpValue = "ASSOCIATE_CARD_POPUP";
      revisePopupType = "secondary";
      originArr = JSON.parse(JSON.stringify(REVIEW_REVISE_POPUP));
      valueArr = REVIEW_REVISE_POPUP;
      reviseSecondaryOptionCheckValue = "all";
    }
    if (
      clickValue === "review" &&
      (popupHeaderOne === "mine" || popupHeaderOne === "marketplace")
    ) {
      revisePopupHeaderOne = popupHeaderOne;
      revisepopupHeaderOneBadgeOne = "review";
      revisepopupHeaderOneBadgeTwo = "";
      reviseisPopUpValue = "ASSOCIATE_CARD_POPUP";
      revisePopupType = "secondary";
      originArr = JSON.parse(JSON.stringify(MINE_REVIEW));
      valueArr = MINE_REVIEW;
      reviseSecondaryOptionCheckValue = "active";
    }
    if (
      clickValue === "create" &&
      (popupHeaderOne === "administrators" || popupHeaderOne === "managers" || popupHeaderOne === "assistants")
    ) {
      revisePopupHeaderOne = popupHeaderOne;
      revisepopupHeaderOneBadgeOne = "create";
      revisepopupHeaderOneBadgeTwo = "";
      reviseisPopUpValue = "ASSOCIATE_CARD_POPUP";
      revisePopupType = "secondary";
      reviseSecondaryOptionCheckValue = "all";
      // originArr = JSON.parse(JSON.stringify(ASSESSEE_REVIEW_REVISE_POPUP));
      let tempArr = JSON.parse(JSON.stringify(ASSESSEE_REVIEW_REVISE_POPUP));
      console.log(tempArr);
      let tempPopupArr = []
      tempArr.forEach((element) => {
        console.log(element.data);
        if (element.data === 'anonymous' || element.data === 'blank') {
          tempPopupArr.push({ ...element, disabled: true });
        } else {
          tempPopupArr.push({ ...element, disabled: false });
        }
      });
      console.log(tempPopupArr);
      originArr = [...tempPopupArr]
      valueArr = [...tempPopupArr];
    }
    if (
      clickValue === "information" &&
      (popupHeaderOne === "assessees" || popupHeaderOne === "associates")
    ) {
      // console.log('*************IN ASSESSEE _*************');
      // dispatch({
      //   type: SET_POPUP_VALUE,
      //   payload: { isPopUpValue: 'NAMEPOPUP', popupMode: popupHeaderOne + 'ROLECREATE' }
      // });
    }
    if (
      clickValue === "distinct" &&
      popupHeaderOne === "profilers" &&
      popupHeaderOneBadgeOne === "review"
    ) {
      resetDataFunction();
      let requestType = "Review-List";
      let profilesData = [
        {
          id: "iGauge Occupation (Culture-Profiler)",
          profilerName: "iGauge Occupation",
          profilerDescription: "Culture-Profiler",
        },
        {
          id: "iGauge Occupation (Job-Profiler)",
          profilerName: " iGauge Occupation",
          profilerDescription: "Job-Profiler",
        },
      ];
      getCultureProfileDiamentionList(
        selectedAssociateInfo,
        requestType,
        dispatch
      );
      dispatch({
        type: SET_MIDDLEPANE_STATE,
        payload: {
          middlePaneHeader: "profilers",
          middlePaneHeaderBadgeOne: clickValue,
          // middlePaneHeaderBadgeTwo: secondaryOptionCheckValue, list is static so set active badge only
          middlePaneHeaderBadgeTwo: "active",
          middlePaneHeaderBadgeThree: "",
          middlePaneHeaderBadgeFour: "",
          middlePaneHeaderDuplicate: "profilers",
          middlePaneHeaderDuplicateBadgeOne: clickValue,
          middlePaneHeaderDuplicateBadgeTwo: "active",
          middlePaneHeaderDuplicateBadgeThree: "",
          middlePaneHeaderDuplicateBadgeFour: "",
          typeOfMiddlePaneList: "profilerDistinctReviewList",
          scanCount: 2,
          duplicateScanCount: 2,
          showMiddlePaneState: true,
        },
      });
      dispatch({ type: SET_MOBILE_PANE_STATE, payload: "displayPaneTwo" });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: "reviewListDistinctData", value: profilesData },
      });
    }
    if (
      clickValue === "distinct" &&
      popupHeaderOne === "gauges" &&
      popupHeaderOneBadgeOne === "review"
    ) {
      getGuageDistinctApiCall(
        selectedAssociateInfo,
        secondaryOptionCheckValue,
        dispatch,
        clickValue
      );
      resetDataFunction();
    }
    if (
      clickValue === "distinct" &&
      (popupHeaderOne === "administrators" || popupHeaderOne === "managers" || popupHeaderOne === "assistants") &&
      popupHeaderOneBadgeOne === "review"
    ) {
      getAdminManagerDistinctApiCall(
        selectedAssociateInfo,
        secondaryOptionCheckValue,
        countPage,
        popupHeaderOne,
        dispatch
      );
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'nodeViewState', value: '' }
      });
    }
    // if (
    //   clickValue === "distinct" &&  popupHeaderOne === "assistants" &&
    //   popupHeaderOneBadgeOne === "review"
    // ) {
    //   dispatch({ type: POPUP_CLOSE });
    // }
    if (
      clickValue === "distinct" &&
      popupHeaderOne === "items" &&
      popupHeaderOneBadgeOne === "review"
    ) {
      if (selectedSectionPopup === 'paneoneSection3') {
        getItemsDistinctApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          popupHeaderOne,
          dispatch,
          "",
          true,
          [],
          'Shared-Item-List'
        );
      } else {
        getItemsDistinctApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          popupHeaderOne,
          dispatch,
          "",
          true
        );
      }

    }
    if (
      clickValue === "distinct" &&
      popupHeaderOne === "culture-profiles" &&
      popupHeaderOneBadgeOne === "review"
    ) {

      if (selectedSectionPopup === 'paneoneSection3') {
        getMarketplaceCultureProfilesDistinctApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          popupHeaderOne,
          dispatch
        );
        resetDataFunction("NoCard");

      } else {
        getCultureProfilesDistinctApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          popupHeaderOne,
          dispatch
        );
        resetDataFunction("NoCard");
      }

    }
    if (
      clickValue === "groups" &&
      popupHeaderOne === "culture-profiles" &&
      popupHeaderOneBadgeOne === "review"
    ) {

      if (selectedSectionPopup === 'paneoneSection3') {
        getMarketPlaceCultureProfileGroupApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          "groups",
          "NoCard",
          "",
          [],
          "hierarchy",
          true,
          assignmentCreateType,
          'Culture-Profile-Shared-Group'
        );
      } else {
        getCultureProfileGroupApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          "groups",
          "NoCard",
          "",
          [],
          "hierarchy",
          true,
          assignmentCreateType
        );
      }
    }
    if (
      clickValue === "types" &&
      popupHeaderOne === "culture-profiles" &&
      popupHeaderOneBadgeOne === "review"
    ) {
      if (selectedSectionPopup === 'paneoneSection3') {
        getMarketPlaceCultureProfileTypeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          "types",
          popupHeaderOne,
          "NoCard"
        );
        resetDataFunction('NoCard');
      } else {
        getCultureProfileTypeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          "types",
          popupHeaderOne,
          "NoCard"
        );
        resetDataFunction('NoCard');
      }
    }
    if (
      clickValue === "distinct" &&
      popupHeaderOne === "job-profiles" &&
      popupHeaderOneBadgeOne === "review"
    ) {
      if (selectedSectionPopup === 'paneoneSection3') {
        getJobProfilesDistinctApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          popupHeaderOne,
          dispatch,
          '',
          [],
          'Job-Profile-Shared-List'
        );
        resetDataFunction("NoCard");
      } else {
        getJobProfilesDistinctApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          popupHeaderOne,
          dispatch
        );
        resetDataFunction("NoCard");
      }
    }
    if (
      clickValue === "groups" &&
      popupHeaderOne === "job-profiles" &&
      popupHeaderOneBadgeOne === "review"
    ) {
      if (selectedSectionPopup === 'paneoneSection3') {
        getJobProfileGroupApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          "groups",
          "NoCard",
          "",
          [],
          "hierarchy",
          true,
          assignmentCreateType,
          'Job-Profile-Shared-Group'
        );
      } else {
        getJobProfileGroupApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          "groups",
          "NoCard",
          "",
          [],
          "hierarchy",
          true,
          assignmentCreateType
        );
      }
    }
    if (
      clickValue === "types" &&
      popupHeaderOne === "job-profiles" &&
      popupHeaderOneBadgeOne === "review"
    ) {
      if (selectedSectionPopup === 'paneoneSection3') {
        getJobProfileTypeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          "types",
          popupHeaderOne,
          "NoCard",
          '',
          [],
          false,
          'Shared-Job-Profile-Type'
        );
        resetDataFunction('NoCard');
      } else {
        getJobProfileTypeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          "types",
          popupHeaderOne,
          "NoCard"
        );
        resetDataFunction('NoCard');
      }
    }
    if (
      clickValue === "groups" &&
      popupHeaderOne === "items" &&
      popupHeaderOneBadgeOne === "review"
    ) {
      if (selectedSectionPopup === 'paneoneSection3') {
        getItemGroupDistinctApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          "groups",
          "noCard",
          "",
          "hierarchy",
          [],
          true,
          assignmentCreateType,
          'Item_Group_Shared_List'
        );
      } else {
        getItemGroupDistinctApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          "groups",
          "noCard",
          "",
          "hierarchy",
          [],
          true,
          assignmentCreateType
        );
      }
    }
    if (
      clickValue === "types" &&
      popupHeaderOne === "items" &&
      popupHeaderOneBadgeOne === "review"
    ) {

      if (selectedSectionPopup === 'paneoneSection3') {
        getItemsTypeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          "types",
          popupHeaderOne,
          "noCard",
          '',
          'Shared-Item'
        );
      } else {
        getItemsTypeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          "types",
          popupHeaderOne,
          "noCard"
        );
      }
    }
    if (
      clickValue === "distinct" &&
      popupHeaderOne === "assessees" &&
      popupHeaderOneBadgeOne === "roles"
    ) {
      resetDataFunction();
      getAssesseeRoleDistinctApiCall(
        selectedAssociateInfo,
        secondaryOptionCheckValue,
        countPage,
        "roles",
        dispatch,
        "Card"
      );
    }
    if (
      clickValue === "distinct" &&
      popupHeaderOne === "associates" &&
      popupHeaderOneBadgeOne === "roles"
    ) {
      resetDataFunction();
      let requestObj = makeAssociateRoleObj(
        selectedAssociateInfo,
        secondaryOptionCheckValue,
        0,
        countPage
      );
      dispatch({
        type: FILTERMODE,
        payload: {
          FilterMode: "associateRoleDistinct" + secondaryOptionCheckValue,
        },
      });
      dispatch({ type: SET_MOBILE_PANE_STATE, payload: "displayPaneTwo" });
      dispatch({ type: LOADER_START });
      dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
      dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
      dispatch({ type: SET_PAGE_COUNT, payload: 0 });
      dispatch({
        type: GET_ASSOCIATE_ROLE_REVIEW_LIST_SAGA,
        payload: {
          request: requestObj,
          BadgeOne: "roles",
          BadgeTwo: "distinct",
          BadgeThree: secondaryOptionCheckValue,
          isMiddlePaneList: true,
        },
      });
    }
    if (
      clickValue === "distinct" &&
      (popupHeaderOne === "assessees" ||
        popupHeaderOne === "assessments" ||
        popupHeaderOne === "assignments" ||
        popupHeaderOne === "associates" ||
        popupHeaderOne === "items" ||
        popupHeaderOne === "culture-profiles" ||
        popupHeaderOne === "job-profiles") &&
      popupHeaderOneBadgeOne === "groups"
    ) {
      let requestObj = {};
      dispatch({ type: SET_MOBILE_PANE_STATE, payload: "displayPaneTwo" });
      dispatch({ type: LOADER_START });
      resetDataFunction();
      if (popupHeaderOne === "assessees") {
        getAssesseeGroupDistinctApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          "groups",
          "Card",
          "",
          "hierarchy",
          [],
          assignmentCreateType
        );
      }
      if (popupHeaderOne === "associates") {
        getAssociateGroupDistinctApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          "groups",
          "Card",
          "",
          "hierarchy",
          [],
          assignmentCreateType
        );
      }
      if (popupHeaderOne === "assessments") {
        getAssessmentGroupApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          "groups",
          "Card",
          "",
          "hierarchy",
          [],
          assignmentCreateType
        );
      }
      if (popupHeaderOne === "assignments") {
        assignmentsGroupApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          "groups",
          "Card",
          "",
          "hierarchy",
          [],
          assignmentCreateType
        );
      }
      if (popupHeaderOne === "items") {
        getItemGroupDistinctApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          "groups",
          "Card",
          "",
          "hierarchy",
          [],
          false,
          assignmentCreateType
        );
      }
      if (popupHeaderOne === "culture-profiles") {
        getCultureProfileGroupApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          "groups",
          "Card",
          "",
          [],
          "hierarchy",
          true,
          assignmentCreateType
        );
      }
      if (popupHeaderOne === "job-profiles") {
        getJobProfileGroupApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          "groups",
          "Card",
          "",
          [],
          "hierarchy",
          true,
          assignmentCreateType
        );
      }
    }
    if (
      clickValue === "distinct" &&
      popupHeaderOne === "associates" &&
      popupHeaderOneBadgeOne === "nodes"//
    ) {
      getInternalNodeApiCall(
        selectedAssociateInfo,
        secondaryOptionCheckValue,
        countPage,
        dispatch,
        popupHeaderOneBadgeOne,
        "",
        "hierarchy",
        "associate",
        '',
        '',
        '',
        'associateNodeDistinct' + secondaryOptionCheckValue,
        "Card"
      );
      dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
      dispatch({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: "selectionValue", value: "" },
      });
    }
    if (
      clickValue === "nodes" &&
      popupHeaderOneBadgeOne !== "download" &&
      popupHeaderOneBadgeOne !== "upload" &&
      (popupHeaderOne === "administrators" ||
        popupHeaderOne === "managers" ||
        popupHeaderOne === "assistants" ||
        popupHeaderOne === "culture-profiles" ||
        popupHeaderOne === "job-profiles" ||
        popupHeaderOne === "items")
    ) {
      getInternalNodeApiCall(
        selectedAssociateInfo,
        secondaryOptionCheckValue,
        countPage,
        dispatch,
        "nodes",
        "",
        "hierarchy",
        popupHeaderOne,
        '',
        '',
        '',
        'associateNodeDistinct' + secondaryOptionCheckValue,
        'noCard'
      );
      dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
    }
    if (
      clickValue === "distinct" &&
      (popupHeaderOne === "assignments" ||
        popupHeaderOne === "associates" ||
        popupHeaderOne === "assessments" ||
        popupHeaderOne === "items" ||
        popupHeaderOne === "culture-profiles" ||
        popupHeaderOne === "job-profiles" ||
        popupHeaderOne === "assessees") &&
      popupHeaderOneBadgeOne === "types"
    ) {
      let requestObj = {};
      dispatch({ type: SET_MOBILE_PANE_STATE, payload: "displayPaneTwo" });
      dispatch({ type: LOADER_START });
      resetDataFunction();
      if (popupHeaderOne === "assessments") {
        getAssessmentTypeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          "types",
          "Card"
        );
      }
      if (popupHeaderOne === "assignments") {
        assignmentTypeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          "types",
          "Card"
        );
      }
      if (popupHeaderOne === "assessees") {
        getAssesseeTypeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          "types",
          "Card",
          popupHeaderOne
        );
      }
      if (popupHeaderOne === "associates") {
        getAssociatesTypeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          "types",
          popupHeaderOne,
          "Card"
        );
      }
      if (popupHeaderOne === "items") {
        getItemsTypeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          "types",
          popupHeaderOne,
          "Card"
        );
      }
      if (popupHeaderOne === "culture-profiles") {
        getCultureProfileTypeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          "types",
          popupHeaderOne,
          "Card"
        );
      }
      if (popupHeaderOne === "job-profiles") {
        getJobProfileTypeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          "types",
          popupHeaderOne,
          "Card"
        );
      }
    }
    if (
      (popupHeaderOne === "administrators" || popupHeaderOne === "managers" || popupHeaderOne === "assistants") &&
      clickValue === "types"
    ) {
      //call admin and manager type api
      getAssesseeTypeApiCall(
        selectedAssociateInfo,
        secondaryOptionCheckValue,
        countPage,
        dispatch,
        "types",
        "noCard",
        popupHeaderOne
      );
    }
    // if (popupHeaderOne === "assistants" && clickValue === "types") {
    //   dispatch({ type: POPUP_CLOSE });
    //   dispatch({
    //     type: SET_POPUP_SINGLE_STATE,
    //     payload: { stateName: "cardValue", value: "noCard" },
    //   });
    // }
    // if (popupHeaderOne === "assistants" && clickValue === "groups") {
    //   dispatch({ type: POPUP_CLOSE });
    //   dispatch({
    //     type: SET_POPUP_SINGLE_STATE,
    //     payload: { stateName: "cardValue", value: "noCard" },
    //   });
    // }
    // if (popupHeaderOne === "assistants" && clickValue === "nodes") {
    //   dispatch({ type: POPUP_CLOSE });
    //   dispatch({
    //     type: SET_POPUP_SINGLE_STATE,
    //     payload: { stateName: "cardValue", value: "noCard" },
    //   });
    // }
    if (
      (popupHeaderOne === "administrators" || popupHeaderOne === "managers" || popupHeaderOne === "assistants") &&
      clickValue === "groups"
    ) {
      //call admin and manager groups api
      getAssesseeGroupDistinctApiCall(
        selectedAssociateInfo,
        secondaryOptionCheckValue,
        countPage,
        dispatch,
        "groups",
        "noCard",
        "",
        "hierarchy",
        [],
        assignmentCreateType,
        popupHeaderOne
      );
    }
    if (clickValue === "notifications" || clickValue === "reports") {
      setDuplicateHeader(popupHeaderOne);
      setDuplicateBadgeOne(popupHeaderOneBadgeOne);
      setDuplicateBadgeTwo(popupHeaderOneBadgeTwo);
      setDuplicateValueArr(popupContentArrValue);
      setDuplicateSecondaryOpt(secondaryOptionCheckValue);
      revisePopupHeaderOne = clickValue;
      revisepopupHeaderOneBadgeOne = "review";
      reviseisPopUpValue = "ASSOCIATE_CARD_POPUP";
      revisePopupType = "secondary";
      valueArr = NOTIFICATION_REPORT_POPUP;
      reviseSecondaryOptionCheckValue = "unread";
    }
    if (
      clickValue === "information" &&
      (popupHeaderOne === "administrators" || popupHeaderOne === "managers" || popupHeaderOne === "assistants")
    ) {
      assesseeCreateApiCalls(
        selectedAssociateInfo,
        dispatch,
        secondaryOptionCheckValue,
        popupHeaderOne.slice(0, -1)
      );

      clearMiddlePaneInfo();
    }
    // else if (clickValue === "information" && popupHeaderOne === "assistants" ) {
    //   dispatch({type: POPUP_CLOSE })
    //   clearMiddlePaneInfo();
    // }
    else if (
      clickValue === "information" &&
      popupHeaderOneBadgeOne === "roles"
    ) {
      dispatch({ type: CLEAR_ROLE_REDUCER_STATE });
      // getRoleGroupReviewListApi(selectedAssociateInfo, dispatch, popupHeaderOne);
      dispatch({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: "NAMEPOPUP",
          popupMode: popupHeaderOne + "ROLECREATE",
        },
      });
      clearMiddlePaneInfo();
    } else if (
      clickValue === "information" &&
      popupHeaderOneBadgeOne === "create" &&
      (popupHeaderOne === "culture-profiles" ||
        popupHeaderOne === "job-profiles")
    ) {
      if (popupHeaderOne === "culture-profiles") {
        cultureProfileCreatePopup(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          dispatch,
          assignmentCreateType
        );
      } else {
        jobProfileCreatePopup(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          dispatch,
          assignmentCreateType
        );
      }

      clearMiddlePaneInfo();
    } else if (
      clickValue === "information" &&
      popupHeaderOne === "gauges" &&
      popupHeaderOneBadgeOne === "create"
    ) {
      dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
      dispatch({ type: CLEAR_GUAGE_REDUCER_STATE });
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: "NAMEPOPUP", popupMode: "GUAGECREATE" },
      });
      clearMiddlePaneInfo();
      resetDataFunction();
    } else if (
      clickValue === "information" &&
      popupHeaderOne === "items" &&
      popupHeaderOneBadgeOne === "create"
    ) {
      createItemPopupApiCall(
        selectedAssociateInfo,
        secondaryOptionCheckValue,
        dispatch,
        assignmentCreateType
      );
      clearMiddlePaneInfo();
    } else if (
      clickValue === "information" &&
      popupHeaderOneBadgeOne === "groups"
    ) {
      dispatch({ type: CLEAR_GROUP_REDUCER_STATE });
      // to do api call for seven groups
      if (popupHeaderOne === "assessees") {
        getAssesseeGroupCreateAPI(selectedAssociateInfo, dispatch, "assessees", assignmentCreateType);
      } else if (popupHeaderOne === "assessments") {
        getAssessmentGroupCreateAPI(
          selectedAssociateInfo,
          dispatch,
          "assessments",
          assignmentCreateType
        );
      } else if (popupHeaderOne === "assignments") {
        getAssignmentGroupCreateAPI(
          selectedAssociateInfo,
          dispatch,
          "assignments",
          assignmentCreateType
        );
      } else if (popupHeaderOne === "associates") {
        getAssociateGroupCreateAPI(
          selectedAssociateInfo,
          dispatch,
          "associates",
          assignmentCreateType
        );
      } else if (popupHeaderOne === "culture-profiles") {
        getCultureProfileGroupCreateAPI(
          selectedAssociateInfo,
          dispatch,
          "culture-profiles",
          assignmentCreateType
        );
      } else if (popupHeaderOne === "job-profiles") {
        getJobProfileGroupCreateAPI(
          selectedAssociateInfo,
          dispatch,
          "job-profiles",
          assignmentCreateType
        );
      } else if (popupHeaderOne === "items") {
        getItemGroupCreateAPI(selectedAssociateInfo, dispatch, "items", assignmentCreateType);
      }
      clearMiddlePaneInfo();
    } else if (
      clickValue === "information" &&
      popupHeaderOneBadgeOne === "types"
    ) {
      dispatch({ type: CLEAR_TYPE_REDUCER_STATE });
      dispatch({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: "NAMEPOPUP",
          popupMode: popupHeaderOne + "TYPECREATE",
        },
      });
      clearMiddlePaneInfo();
    } else if (
      clickValue === "information" &&
      popupHeaderOneBadgeOne === "nodes"
    ) {
      createAssociateNodeFunction(dispatch, selectedAssociateInfo, countPage);
      clearMiddlePaneInfo();
    }
    else if (
      (clickValue === 'groups' ||
        clickValue === 'nodes' ||
        clickValue === 'types') &&
      (popupHeaderOne === "education-profiles" ||
        popupHeaderOne === "personal-profiles" ||
        popupHeaderOne === "occupation-profiles") &&
      popupHeaderOneBadgeOne === 'review'
    ) {
      dispatch({ type: POPUP_CLOSE })
    }
    else {
      dispatch({
        type: SET_POPUP_STATE,
        payload: {
          popupHeaderOne: revisePopupHeaderOne,
          popupHeaderOneBadgeOne: revisepopupHeaderOneBadgeOne,
          popupHeaderOneBadgeTwo: revisepopupHeaderOneBadgeTwo,
          isPopUpValue: reviseisPopUpValue,
          popupOpenType: revisePopupType,
          secondaryOptionCheckValue: reviseSecondaryOptionCheckValue,
          tertiaryOptionCheckValue: reviseThiredOptionCheckValue,
          forthOptionCheckValue: reviseForthOptionCheckValue,
          popupContentArrValue: valueArr,
          originPrimaryArrOprion: originArr,
          currentPopUpOption: [],
        },
      });
      // setIsReviseMode(false);
    }
  };

  const BackHandlerEvent = (e) => {
    let revisePopupHeaderOne = "associate";
    let revisepopupHeaderOneBadgeOne = "self";
    let revisepopupHeaderOneBadgeTwo = "";
    let revisepopupHeaderOneBadgeThree = "";
    let reviseisPopUpValue = "ASSOCIATE_CARD_POPUP";
    let revisePopupType = "primary";
    let reviseSecondryOpt = "";
    let reviseSecondaryOptionCheckValue = "";
    let reviseThiredOptionCheckValue = "";
    let reviseForthOptionCheckValue = "";
    let valueArr = '';
    let obj = [...ASSOCIATE_CARD_POPUP_OPTION];
    let sectionTwoObj = {
      data: 'analytics',
      dataValue: 'analytics',
      dataKey: 'analytics',
      optionClass: 'optionPrimary',
      disabled: true,
      divider: 'dark',
      permissionArr: '',
      permission: 'iGuruAnalytic',
      permissionValue: 'all'
    };
    let sectionThreeObj = {
      data: 'marketplace',
      dataValue: 'marketplace',
      dataKey: 'marketplace',
      optionClass: 'optionPrimary',
      divider: 'dark',
      disabled: true,
      permissionArr: '',
      permission: '',
      permissionValue: ''
    };
    let sectionFourObj = {
      data: 'mine',
      dataValue: 'mine',
      dataKey: 'mine',
      optionClass: 'optionPrimary',
      divider: 'dark',
      disabled: true,
      permissionArr: '',
      permission: '',
      permissionValue: ''
    };
    if (paneOneSelectedSection === 'paneoneSection2') obj.splice(1, 0, sectionTwoObj);
    if (paneOneSelectedSection === 'paneoneSection3') obj.splice(1, 0, sectionTwoObj);
    if (paneOneSelectedSection === 'paneoneSection3') obj.splice(9, 0, sectionThreeObj);
    if (paneOneSelectedSection === 'paneoneSection4') obj.splice(8, 0, sectionFourObj);

    if (popupHeaderOneBadgeOne === '' || popupHeaderOneBadgeOne === 'self') {
      valueArr = setAssociateCardPermissionInJson(
        obj,
        signedAssesseePermission?.associateSelfPermission,
        "optionPrimary"
      );
    }
    // let valueArr = ASSOCIATE_CARD_POPUP_OPTION;
    if (popupOpenType === "primary") {
      dispatch({ type: POPUP_CLOSE });
      setexchageMode(false);
    } else {
      if (
        (popupHeaderOne === "administrators" ||
          popupHeaderOne === "managers" ||
          popupHeaderOne === "assistants" ||
          popupHeaderOne === "interviews") &&
        (popupHeaderOneBadgeOne === "review" ||
          popupHeaderOneBadgeOne === "create")
      ) {
        revisePopupHeaderOne = popupHeaderOne;
        revisepopupHeaderOneBadgeOne = "";
        valueArr = MODULE_POPUP_OPTION;
        revisePopupType = "secondary";
        reviseSecondryOpt = "";
      }
      if (
        (popupHeaderOne === "assessees" || popupHeaderOne === "associates") &&
        (popupHeaderOneBadgeOne === "roles" ||
          popupHeaderOneBadgeOne === "role")
      ) {
        revisePopupHeaderOne = "roles";
        revisepopupHeaderOneBadgeOne = "";
        valueArr = ROLE_POPUP_OPTION;
        reviseSecondryOpt = "";
        revisePopupType = "secondary";
      }
      if (
        (popupHeaderOne === "assessees" ||
          popupHeaderOne === "assessments" ||
          popupHeaderOne === "assignments" ||
          popupHeaderOne === "associates" ||
          popupHeaderOne === "assessment centres" ||
          popupHeaderOne === "culture-profiles" ||
          popupHeaderOne === "job-profiles" ||
          popupHeaderOne === "education-profiles" ||
          popupHeaderOne === "personal-profiles" ||
          popupHeaderOne === "occupation-profiles" ||
          popupHeaderOne === "interviews" ||
          popupHeaderOne === "profiler" ||
          popupHeaderOne === "items") &&
        (popupHeaderOneBadgeOne === "types" ||
          popupHeaderOneBadgeOne === "type")
      ) {
        revisePopupHeaderOne = "types";
        revisepopupHeaderOneBadgeOne = "";
        valueArr = TYPE_POPUP_OPTION;
        reviseSecondryOpt = "";
        revisePopupType = "secondary";
      }
      if (
        (popupHeaderOne === "assessees" ||
          popupHeaderOne === "assessments" ||
          popupHeaderOne === "assignments" ||
          popupHeaderOne === "associates" ||
          popupHeaderOne === "assessment centres" ||
          popupHeaderOne === "culture-profiles" ||
          popupHeaderOne === "job-profiles" ||
          popupHeaderOne === "education-profiles" ||
          popupHeaderOne === "personal-profiles" ||
          popupHeaderOne === "occupation-profiles" ||
          popupHeaderOne === "profiler" ||
          popupHeaderOne === "interviews" ||
          popupHeaderOne === "items") &&
        (popupHeaderOneBadgeOne === "groups" ||
          popupHeaderOneBadgeOne === "group")
      ) {
        revisePopupHeaderOne = "groups";
        revisepopupHeaderOneBadgeOne = "";
        valueArr = GROUP_POPUP_OPTION;
        reviseSecondryOpt = "";
        revisePopupType = "secondary";
      }
      if (
        popupHeaderOne === "associates" &&
        popupHeaderOneBadgeOne === "nodes"
      ) {
        revisePopupHeaderOne = "nodes";
        revisepopupHeaderOneBadgeOne = "";
        valueArr = NODE_POPUP_OPTION;
        reviseSecondryOpt = "associates";
        revisePopupType = "secondary";
      }
      if (
        (popupHeaderOne === "assessees" ||
          popupHeaderOne === "assessments" ||
          popupHeaderOne === "assignments" ||
          popupHeaderOne === "culture-profiles" ||
          popupHeaderOne === "job-profiles" ||
          popupHeaderOne === "education-profiles" ||
          popupHeaderOne === "personal-profiles" ||
          popupHeaderOne === "occupation-profiles" ||
          popupHeaderOne === "items" ||
          popupHeaderOne === "associates") &&
        (popupHeaderOneBadgeOne === "items" ||
          popupHeaderOneBadgeOne === "item")
      ) {
        revisePopupHeaderOne = "items";
        revisepopupHeaderOneBadgeOne = "";
        valueArr = GROUP_TYPE_POPUP_OPTION;
        reviseSecondryOpt = "";
        revisePopupType = "secondary";
      }
      if (
        (popupHeaderOne === "assessees" ||
          popupHeaderOne === "assignments" ||
          popupHeaderOne === "assessments" ||
          popupHeaderOne === "associates" ||
          popupHeaderOne === "assessment centres" ||
          popupHeaderOne === "culture-profiles" ||
          popupHeaderOne === "job-profiles" ||
          popupHeaderOne === "education-profiles" ||
          popupHeaderOne === "personal-profiles" ||
          popupHeaderOne === "occupation-profiles" ||
          popupHeaderOne === "interviews" ||
          popupHeaderOne === "items") &&
        (popupHeaderOneBadgeOne === "upload" ||
          popupHeaderOneBadgeOne === "download")
      ) {
        revisePopupHeaderOne = "exchange";
        revisepopupHeaderOneBadgeOne = "";
        valueArr = EXCHANGE_POPUP_OPTION;
        reviseSecondryOpt = "";
        revisePopupType = "secondary";
      }
      if (
        (popupHeaderOne === "assessees" ||
          popupHeaderOne === "associates" ||
          popupHeaderOne === "assessments" ||
          popupHeaderOne === "assignments" ||
          popupHeaderOne === "assessment centres" ||
          popupHeaderOne === "culture-profiles" ||
          popupHeaderOne === "job-profiles" ||
          popupHeaderOne === "education-profiles" ||
          popupHeaderOne === "personal-profiles" ||
          popupHeaderOne === "occupation-profiles" ||
          popupHeaderOne === "interviews" ||
          popupHeaderOne === "items") &&
        popupHeaderOneBadgeOne === "exchange" &&
        (popupHeaderOneBadgeTwo === "upload" ||
          popupHeaderOneBadgeTwo === "download")
      ) {
        revisePopupHeaderOne = popupHeaderOne;
        revisepopupHeaderOneBadgeOne = popupHeaderOneBadgeTwo;
        revisepopupHeaderOneBadgeTwo = '';
        reviseisPopUpValue = "ASSOCIATE_CARD_POPUP";
        revisePopupType = "secondary";
        reviseSecondaryOptionCheckValue = "active";
        let permissions =
          popupHeaderOne === 'assessees'
            ? signedAssesseePermission?.assesseeDistinctPermission
            : popupHeaderOne === 'associates'
              ? signedAssesseePermission?.associateDistinctPermission
              : popupHeaderOne === 'assessments'
                ? signedAssesseePermission?.assessmentDistictPermission
                : popupHeaderOne === 'assignments'
                  ? signedAssesseePermission?.assigmentDistictPermission
                  : popupHeaderOne === 'culture-profiles'
                    ? signedAssesseePermission?.iGuruAnalyticDistinctPermission
                    : popupHeaderOne === 'job-profiles'
                      ? signedAssesseePermission?.iGuruAnalyticDistinctPermission
                      : popupHeaderOne === 'items'
                        ? signedAssesseePermission?.itemDistictPermssion
                        : null;
        valueArr = setAssociateCardPermissionInJson(
          REVIEW_POPUP_OPTIONS,
          permissions,
          'optionPrimary'
        );
        reviseSecondryOpt = 'active';
        revisePopupType = 'secondary';
        setexchageMode(false);
      }
      if (
        (popupHeaderOne === "assessment centres" ||
          popupHeaderOne === "culture-profiles" ||
          popupHeaderOne === "profilers" ||
          popupHeaderOne === "gauges" ||
          popupHeaderOne === "education-profiles" ||
          popupHeaderOne === "personal-profiles" ||
          popupHeaderOne === "occupation-profiles" ||
          popupHeaderOne === "job-profiles") &&
        (popupHeaderOneBadgeOne === "create" ||
          popupHeaderOneBadgeOne === "review")
      ) {
        revisePopupHeaderOne = "analytics";
        revisepopupHeaderOneBadgeOne = "";
        valueArr = ANALYTICS_POPUP;
        reviseSecondryOpt = "";
        revisePopupType = "secondary";
      }
      if (popupHeaderOne === "notifications" || popupHeaderOne === "reports") {
        revisePopupHeaderOne = duplicateHeader;
        revisepopupHeaderOneBadgeOne = duplicateBadgeOne;
        revisepopupHeaderOneBadgeTwo = duplicateBadgeTwo;
        valueArr = duplicateValueArr;
        reviseSecondryOpt =
          popupHeaderOne === "associates" && popupHeaderOneBadgeOne === "nodes"
            ? duplicateSecondaryOpt
            : "";
      }
      if (
        (popupHeaderOne === "mine" ||
          popupHeaderOne === "marketplace" ||
          popupHeaderOne === "items") &&
        (popupHeaderOneBadgeOne === "review" ||
          popupHeaderOneBadgeOne === "create")
      ) {
        revisePopupHeaderOne = popupHeaderOne;
        revisepopupHeaderOneBadgeOne = "";
        valueArr = MODULE_POPUP_OPTION;
        reviseSecondryOpt = "";
        revisePopupType = "secondary";
      }
      dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
      dispatch({
        type: SET_POPUP_STATE,
        payload: {
          popupHeaderOne: revisePopupHeaderOne,
          popupHeaderOneBadgeOne: revisepopupHeaderOneBadgeOne,
          popupHeaderOneBadgeTwo: revisepopupHeaderOneBadgeTwo,
          popupHeaderOneBadgeThree: revisepopupHeaderOneBadgeThree,
          tertiaryOptionCheckValue: reviseThiredOptionCheckValue,
          forthOptionCheckValue: reviseForthOptionCheckValue,
          isPopUpValue: reviseisPopUpValue,
          popupOpenType: revisePopupType,
          secondaryOptionCheckValue: reviseSecondryOpt,
          popupContentArrValue: valueArr,
          originPrimaryArrOprion: JSON.parse(JSON.stringify(valueArr)),
          // popupContentArrValue: originArr,
          currentPopUpOption: [],
        },
      });
    }
  };
  const onClosePopUpEvent = () => {
    dispatch({ type: POPUP_CLOSE });
    setexchageMode(false);
  };
  const setSecondaryOptionValueTwo = (e) => {
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: {
        stateName: "tertiaryOptionCheckValue",
        value: e.currentTarget.getAttribute("data-value"),
      },
    });
  };
  const setSecondaryOptionValueThree = (e) => {
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: {
        stateName: "forthOptionCheckValue",
        value: e.currentTarget.getAttribute("data-value"),
      },
    });
  };
  return (
    <div>
      <Popup isActive={isActive}>
        <PopupHeader
          headerPanelColour={headerPanelColour + popupOpenType}
          headerOne={popupHeaderOne}
          headerOneBadgeOne={popupHeaderOneBadgeOne}
          headerOneBadgeTwo={popupHeaderOneBadgeTwo}
          headerOneBadgeThree={popupHeaderOneBadgeThree}
          onClosePopUpEvent={onClosePopUpEvent}
          onClick={BackHandlerEvent}
          mode={""}
        />
        <DialogContent className={["popupContent", "fixed05PadDim"].join(" ")}>
          <JsonRenderComponent
            setSecondaryOptionValue={setSecondaryOptionValue}
            setSecondaryOptionValueTwo={setSecondaryOptionValueTwo}
            setSecondaryOptionValueThree={setSecondaryOptionValueThree}
            ChangeOptionPopup={ChangeOptionPopup}
            secondaryOptionCheckValue={secondaryOptionCheckValue}
            tertiaryOptionCheckValue={tertiaryOptionCheckValue}
            forthOptionCheckValue={forthOptionCheckValue}
            currentPopUpOption={currentPopUpOption}
          />
          {exchageMode && (
            <Fragment>
              <FormControl style={{ width: "100%" }}>
                <SelectField
                  tag={"location"}
                  label={"location"}
                  // dataValue={'location'}
                  listSelect={[
                    { id: "", name: "" },
                    { id: "device", name: "device" },
                    { id: "google", name: "google" },
                    { id: "microsoft", name: "microsoft" },
                  ]}
                  onChange={onChangeExchangeLocation}
                  value={exchageLocation}
                  mappingValue={"id"}
                />
                <input
                  type="file"
                  id="file"
                  ref={inputFile}
                  style={{ display: "none" }}
                  onChange={fileUploadOnChange}
                />
                <InputFeild
                  id={"template"}
                  label={"template"}
                  value={fileName}
                  labelBadgeOne={""}
                />
              </FormControl>
              <IconButton
                Icon={
                  popupHeaderOneBadgeTwo === "upload"
                    ? UploadIcon
                    : DownloadIcon
                }
                className=""
                colour="genericOne"
                label={popupHeaderOneBadgeTwo}
                dataValue={popupHeaderOneBadgeTwo}
                onClick={fileUploadOnAWS}
              />
              <FormHelperText className={["helperTextCore"].join(" ")}>
                <span></span>
              </FormHelperText>
            </Fragment>
          )}
        </DialogContent>
      </Popup>
    </div>
  );
};

export default PopUpDisplayPanelAssociate;
