import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import Manuscript from '@material-ui/icons/Description';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, InputLabel, Paper } from '@material-ui/core';
import { convertToLocalTime, calculateTime, closeRelatedList } from '../../Actions/GenericActions';
import { Fragment } from 'react';
import ReviewList from '../ReviewList/ReviewList';
import {
  GET_ASSIGNMENT_REPORT_SAGA,
  LOADER_START,
  LOADER_STOP,
  POPUP_OPEN,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MIDDLEPANE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_POPUP_SINGLE_STATE,
  SET_POPUP_STATE,
  SET_POPUP_VALUE
} from '../../actionType';
import { ASSESSEE_REPORT_POPUP } from '../../PopUpConfig';
import { REPORT_URL } from '../../endpoints';
import Store from '../../store';

const DisplayPaneThreeSectionOneAssesseeReport = () => {
  // const [listExpand, setListExpand] = useState('');
  const [selectedBadge, setSelectedBadge] = useState('analysis');
  const { responseObject, headerOne, headerOneBadgeOne, createMode, responseObj } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  const { assesseeAssignment, assessee } = responseObject;
  const { assignmentReportsData,
    selectedAssociateInfo,
    currentRevisedAssignmentType,
    middlePaneSelectedValue,
    reviewListDistinctData,
    relatedReviewListDistinctData,
    middlePaneHeaderDuplicate,
    middlePaneHeaderBadgeOne,
    middlePaneHeaderBadgeTwo,
    middlePaneHeaderDuplicateBadgeOne,
    middlePaneHeaderDuplicateBadgeTwo,
    middlePaneHeaderDuplicateBadgeThree,
    middlePaneHeaderDuplicateBadgeFour,
    middlePaneHeaderDuplicateOne,
    middlePaneHeaderDuplicateOneBadgeOne,
    middlePaneHeaderDuplicateOneBadgeTwo,
    middlePaneHeaderDuplicateOneBadgeThree,
    middlePaneHeaderDuplicateOneBadgeFour,
    duplicateScanCount,
  } = useSelector(
    (state) => state.DisplayPaneTwoReducer
  );
  const dispatch = useDispatch();
  let assignmentTime = 0;
  responseObject?.assesseeAssignment?.assesseeAssignmentAssessmentDistinct?.forEach((ass) => {
    assignmentTime += ass.assesseeAssignmentAssessmentAttemptedTotalTime;
  });
  console.log('assignmentTime', assignmentTime);
  const OpenGaugePopupReport = (label) => {
    if (label === 'report' && headerOne === 'assessee') {

      // dispatch({
      //   type: SET_POPUP_STATE,
      //   payload: {
      //     popupMode: 'ASSIGNMENT_REPORT_REVIEW',
      //     popupHeaderOne: 'culture-profile',
      //     popupHeaderOneBadgeOne: '',
      //     popupHeaderOneBadgeTwo: '',
      //     isPopUpValue: 'REPORTPOPUPCP',
      //     popupOpenType: 'primary',
      //   }
      // })

      // return

      let previousMiddlePaneList = middlePaneHeaderDuplicateOne + 'DistinctReviewList'

      console.log(previousMiddlePaneList, '****')

      closeRelatedList(
        dispatch,
        createMode,
        responseObject,
        middlePaneHeaderDuplicate,
        middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderDuplicateBadgeTwo,
        middlePaneHeaderDuplicateBadgeThree,
        middlePaneHeaderDuplicateBadgeFour,
        previousMiddlePaneList,
        duplicateScanCount,
        '', //'assignmentsDistinctinactive',
        duplicateScanCount,
        middlePaneHeaderDuplicateOne,
        middlePaneHeaderDuplicateOneBadgeOne,
        middlePaneHeaderDuplicateOneBadgeTwo,
        middlePaneHeaderDuplicateOneBadgeThree,
        middlePaneHeaderDuplicateOneBadgeFour
      );

      dispatch({ type: LOADER_START });
      let assignmentReportsData2 = assignmentReportsData
      // if (currentRevisedAssignmentType === 'iGuru Analytics_Occupation') {

      assignmentReportsData2 = [
        {
          id: 1,
          reportName: 'Assignment Report',
          // reportDescription: 'Bespoke'
        },
      ]

      // }

      let response = {
        report: assignmentReportsData2,
        assingmentId: assessee?.id,
        assignmentName: assessee?.informationBasic?.assesseeNameFirst + assessee?.informationBasic?.assesseeNameLast,
        // assignmentDescription: assessee?.informationBasic?.assesseeNameFirst
      };
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'relatedReviewListDistinctData', value: [response] }
      });
      setTimeout(function () {
        dispatch({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: 'assessee',
            middlePaneHeaderBadgeOne: 'reports',
            middlePaneHeaderBadgeTwo: '',
            middlePaneHeaderBadgeThree: '',
            middlePaneHeaderBadgeFour: '',
            typeOfMiddlePaneList: 'AsseseeDistinctReportReviewList',
            scanCount: (assignmentReportsData2 && assignmentReportsData2.length) || 0,
            showMiddlePaneState: true,
            //
            middlePaneHeaderDuplicate:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
            middlePaneHeaderDuplicateBadgeOne:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            middlePaneHeaderDuplicateBadgeTwo:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            middlePaneHeaderDuplicateBadgeThree:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree, //
            middlePaneHeaderDuplicateBadgeFour:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour, //
            middlePaneHeaderDuplicateOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOne,
            middlePaneHeaderDuplicateOneBadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeOne,
            middlePaneHeaderDuplicateOneBadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeTwo,
            middlePaneHeaderDuplicateOneBadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeThree,
            middlePaneHeaderDuplicateOneBadgeFour: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeFour,
            duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
          }
        });
        dispatch({ type: LOADER_STOP });
      }, 2000);
      dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });

      // let tempArr = [];
      // ASSESSEE_REPORT_POPUP.forEach((element) => {
      //   if (
      //     responseObject?.assesseeAssignment?.assesseeAssignmentStatusReport === 'PUBLISHED' &&
      //     element.data === 'revise'
      //   ) {
      //     tempArr.push({ ...element, disabled: true });
      //   } else {
      //     tempArr.push(element);
      //   }
      // });
      // dispatch({
      //   type: SET_POPUP_STATE,
      //   payload: {
      //     popupHeaderOne: 'assessee',
      //     popupHeaderOneBadgeOne: 'report',
      //     isPopUpValue: '',
      //     popupOpenType: 'primary',
      //     popupContentArrValue: tempArr,
      //     selectedTagValue: responseObject?.assesseeAssignment?.assesseeAssignmentId,
      //     selectedTagStatus:
      //       responseObject?.assesseeAssignment?.assesseeAssignmentStatusReport || 'UNPUBLISHED'
      //   }
      // });
      // dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
      // dispatch({
      //   type: SET_DISPLAY_TWO_SINGLE_STATE,
      //   payload: {
      //     stateName: 'middlePaneListPopupOptions',
      //     value: ASSESSEE_REPORT_POPUP
      //   }
      // });

    }
    if ((label === 'report' || label === 'gauge') && headerOne !== 'assessee') {
      dispatch({ type: LOADER_START });

      let requestObj = {
        module: 'Assessee-Assignment',
        action: 'Merit-List',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          assignmentId: responseObject.assignmentId,
          resultResponse: 'Overall-Fit'
        }
      };
      dispatch({
        type: GET_ASSIGNMENT_REPORT_SAGA,
        payload: {
          request: requestObj,
          assignmentId: responseObject.assignmentId,
          assignmentName: responseObject.assignmentName,
          assignmentDescription: responseObject.assignmentDescription,
          isRightPaneReview: false,
          righpaneheaderOne: 'reports',
          panethree: true
          // reportId: selectedTagValue,
          // reportName: reportData[0].reportName,
          // reportDescription: reportData[0].reportDescription
        }
      });


      // dispatch({
      //   type: SET_POPUP_STATE,
      //   payload: {
      //     popupMode: 'ASSIGNMENT_REPORT_REVIEW',
      //     popupHeaderOne: 'assignment',
      //     popupHeaderOneBadgeOne: '',
      //     popupHeaderOneBadgeTwo: '',
      //     isPopUpValue: 'REPORTPOPUPCP',
      //     popupOpenType: 'primary',
      //   }
      // })


      return

      let assignmentReportsData2 = assignmentReportsData
      if (currentRevisedAssignmentType === 'iGuru Analytics_Occupation') {
        assignmentReportsData2 = [
          {
            id: 4,
            reportName: 'Merit List',
            reportDescription: 'Culture-Fit'
          },
          {
            id: 6,
            reportName: 'Merit List',
            reportDescription: 'Job-Fit'
          },
          {
            id: 9,
            reportName: 'Merit List',
            reportDescription: 'Work-Fit'
          },
        ]
      } else {
        assignmentReportsData2 = [
          {
            id: 1,
            reportName: 'Assignment Report',
            // reportDescription: 'Bespoke'
          },
        ]
      }

      let response = {
        report: assignmentReportsData2,
        assingmentId: responseObject?.assignmentId,
        assignmentName: responseObject?.assignmentName,
        assignmentDescription: responseObject?.assignmentDescription
      };
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'relatedReviewListDistinctData', value: [response] }
      });
      setTimeout(function () {
        dispatch({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: 'assignment',
            middlePaneHeaderBadgeOne: 'reports',
            middlePaneHeaderBadgeTwo: '',
            middlePaneHeaderBadgeThree: '',
            middlePaneHeaderBadgeFour: '',
            typeOfMiddlePaneList: 'assignmentReportReviewList',
            scanCount: (assignmentReportsData2 && assignmentReportsData2.length) || 0,
            showMiddlePaneState: true,
            middlePaneHeaderDuplicate:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
            middlePaneHeaderDuplicateBadgeOne:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            middlePaneHeaderDuplicateBadgeTwo:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            middlePaneHeaderDuplicateBadgeThree:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree, //
            middlePaneHeaderDuplicateBadgeFour:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour, //
            middlePaneHeaderDuplicateOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOne,
            middlePaneHeaderDuplicateOneBadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeOne,
            middlePaneHeaderDuplicateOneBadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeTwo,
            middlePaneHeaderDuplicateOneBadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeThree,
            middlePaneHeaderDuplicateOneBadgeFour: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeFour,
            duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
          }
        });
        dispatch({ type: LOADER_STOP });
      }, 2000);
      dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
    }
  };
  const openAssesseeReportPDF = () => {
    let signedAssesseeId = selectedAssociateInfo?.assesseeId;
    let signedAssociateId =
      selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary;
    let signedAssociateName = selectedAssociateInfo?.associate?.informationBasic.associateName;
    let reportUrl = '';
    if (headerOne === 'assessee') {
      if (signedAssociateName === 'Richard McConkey') {
        reportUrl = REPORT_URL + 'richReport';
      } else if (signedAssociateName === 'Prafulta') {
        reportUrl = REPORT_URL + 'reports';
      } else if (signedAssociateName === 'Boppo Technologies') {
        reportUrl = REPORT_URL + 'reports';
      } else {
        reportUrl = REPORT_URL + 'richReport';
      }
      let assesseeId = responseObject?.assesseeAssignment?.assesseeId;
      let assesseeAssignmentId = responseObject?.assesseeAssignment?.assesseeAssignmentId;
      let token =
        localStorage.getItem('token') + '@@@@' + signedAssesseeId + '@@@@' + signedAssociateId;
      window.open(
        reportUrl +
        '?assesseeId=' +
        assesseeId +
        '&assesseeAssignmentId=' +
        assesseeAssignmentId +
        '&token=' +
        token +
        ''
      );
    }
    if (headerOne === 'assignment') {
      reportUrl = REPORT_URL + 'meritlist/';
      let assesseeAssignmentId = responseObject?.assignmentId;
      let token =
        localStorage.getItem('token') + '@@@@' + signedAssesseeId + '@@@@' + signedAssociateId;
      window.open(reportUrl + '?assignmentId=' + assesseeAssignmentId + '&token=' + token + '');
    }
  };
  return (
    <div
      style={{
        height: 'calc(100vh - 355px)',
        overflow: 'overlay'
      }}
    >
      <>
        <div className={'containerPadding'}>
          <Paper className={'dossierContainerTop'}>
            <div>
              <div
                className={['containerPadding ', ''].join(' ')}
              //className={['containerPadding ', 'detailsContactContainer'].join(' ')}
              >
                <div className={'detsailsPadding'}>
                  <div //className="FormBox detailsHeight"
                    className={['FormBox', 'detailsHeight'].join(' ')}
                    style={{ height: '50px' }}
                  >
                    <div
                      className={[
                        'formControlReviewName',
                        'formControlRight',
                        'verticalScroll'
                      ].join(' ')}
                    >
                      <div style={{ width: '100%' }}>
                        <InputLabel
                          htmlFor="name-input"
                          className={['textForLabel', 'textForLabelRight', 'careerLabelRight'].join(
                            ' '
                          )}
                        >
                          <span
                            style={{
                              marginBottom: '5px',
                              display: 'inline-block'
                            }}
                            className={'reviewLabelClass'}
                            data-value={'assignment'}
                            data-key={'assignment' || ''}
                          >
                            {((headerOne === 'assignment' || headerOne === 'assessee') &&
                              headerOneBadgeOne === 'analytics' &&
                              'analytics') ||
                              'assessees' ||
                              'assignment'}
                          </span>
                          <sup
                            onClick={() => {
                              setSelectedBadge(
                                headerOneBadgeOne === 'analytics' ? 'audit' : 'analysis'
                              );
                            }}
                            style={{
                              backgroundColor:
                                selectedBadge === 'analysis' || selectedBadge === 'audit'
                                  ? 'rgb(242, 242, 242)'
                                  : ''
                            }}
                          >
                            {((headerOne === 'assignment' || headerOne === 'assessee') &&
                              headerOneBadgeOne === 'analytics' &&
                              'audit') ||
                              'analysis'}
                          </sup>
                          <sup
                            onClick={() => {
                              setSelectedBadge('certificate');
                            }}
                            style={{
                              backgroundColor:
                                selectedBadge === 'certificate' ? 'rgb(242, 242, 242)' : ''
                            }}
                          >
                            certificate
                          </sup>
                          <sup
                            onClick={() => {
                              setSelectedBadge('evaluation');
                            }}
                            style={{
                              backgroundColor:
                                selectedBadge === 'evaluation' ? 'rgb(242, 242, 242)' : ''
                            }}
                          >
                            evaluation
                          </sup>
                          {(headerOne === 'assignment' || headerOne === 'assessee') &&
                            headerOneBadgeOne === 'analytics' && (
                              <>
                                {/* <sup
                                  onClick={() => {
                                    setSelectedBadge('feedback');
                                  }}
                                  style={{
                                    backgroundColor:
                                      selectedBadge === 'feedback' ? 'rgb(242, 242, 242)' : ''
                                  }}
                                >
                                  feedback
                                </sup> */}
                                {headerOne === 'assessee' && (
                                  <sup
                                    onClick={() => {
                                      setSelectedBadge('gauges');
                                    }}
                                    style={{
                                      backgroundColor:
                                        selectedBadge === 'gauges' ? 'rgb(242, 242, 242)' : ''
                                    }}
                                  >
                                    gauge
                                  </sup>
                                )}
                                {/* <sup
                                  onClick={() => {
                                    setSelectedBadge('interview');
                                  }}
                                  style={{
                                    backgroundColor:
                                      selectedBadge === 'interview' ? 'rgb(242, 242, 242)' : ''
                                  }}
                                >
                                  interview
                                </sup> */}
                              </>
                            )}
                          {(headerOne === 'assignment' || headerOne === 'assessee') &&
                            headerOneBadgeOne === 'analytics' && (
                              <sup
                                onClick={() => {
                                  // setSelectedBadge('gauge');
                                  // OpenGaugePopupReport('gauge');
                                  setSelectedBadge(
                                    headerOneBadgeOne === 'analytics' ? 'report' : 'gauge'
                                  );
                                  OpenGaugePopupReport(
                                    headerOneBadgeOne === 'analytics' ? 'report' : 'gauge'
                                  );
                                }}
                                style={{
                                  backgroundColor:
                                    //selectedBadge === 'gauge' ? 'rgb(242, 242, 242)' : ''
                                    selectedBadge === 'report' || selectedBadge === 'gauge'
                                      ? 'rgb(242, 242, 242)'
                                      : ''
                                }}
                              >
                                {headerOneBadgeOne === 'analytics' ? 'report' : 'gauge'}
                              </sup>
                            )}
                        </InputLabel>
                      </div>
                    </div>
                    <div className={['unitFlex', 'unitFlexTop'].join(' ')}></div>
                    {/* {headerOne === 'assessee' && ( //certificate
                      <div className="unitFlex">
                        <IconButton onClick={openAssesseeReportPDF}>
                          <Manuscript />
                        </IconButton>
                      </div>)
                    } */}
                  </div>
                </div>
              </div>

              {(selectedBadge === 'analysis' || selectedBadge === 'audit') && (
                <div>
                  <div>
                    <div className={'containerPadding'}>
                      <div className={'detsailsPadding-one'} style={{ display: 'flex' }}>
                        <div
                          className={['measureBox', 'heightInherit'].join(' ')}
                          style={{ cursor: 'default', flex: 6 }}
                        >
                          <div
                            className={['unitFlex', 'unitFlexTop'].join(' ')}
                            style={{ flex: 2 }}
                          >
                            <div
                              className={['unitFlex', 'midPaneInformationScale'].join(' ')}
                            >
                              {convertToLocalTime(
                                assesseeAssignment?.assesseeAssignmentTimeline
                                  ?.assesseeAssignmentTimelineDateTimeStart
                              )}
                            </div>
                            <div className={['unitFlex', 'reports-center'].join(' ')}>start</div>
                          </div>
                          <div
                            className={['unitFlex', 'unitFlexTop'].join(' ')}
                            style={{ flex: 2 }}
                          >
                            <div
                              className={['unitFlex', 'midPaneInformationScale'].join(' ')}
                            >
                              {convertToLocalTime(
                                assesseeAssignment?.assesseeAssignmentTimeline
                                  ?.assesseeAssignmentTimelineDateTimeEnd
                              )}
                            </div>
                            <div className={['unitFlex', 'reports-center'].join(' ')}>end</div>
                          </div>
                          <div
                            className={['unitFlex', 'unitFlexTop'].join(' ')}
                            style={{ flex: 2 }}
                          >
                            <div
                              className={['unitFlex', 'midPaneInformationScale'].join(' ')}
                            >
                              {calculateTime(assignmentTime)}
                              {/* {calculateTime(
                                assesseeAssignment?.assesseeAssignmentAttemptedTotalTime
                              )} */}
                            </div>
                            <div className={['unitFlex', 'reports-center'].join(' ')}>time</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ display: headerOne === 'assignment' ? 'none' : 'block' }}>
                    {assesseeAssignment?.assesseeAssignmentAssessmentDistinct.length > 0 ? (
                      assesseeAssignment?.assesseeAssignmentAssessmentDistinct.map(
                        (assesmentResult, index) => (
                          <div key={index}>
                            <div className={'containerPadding'}>
                              <div
                                // disableFocusRipple={true}
                                // disableRipple={true}
                                className={['heightInherit', 'detsailsPadding-one'].join(' ')}
                                style={{ textTransform: 'none' }}
                              >
                                <div
                                  className={['measureBox', 'heightInherit'].join(' ')}
                                  style={{ cursor: 'default' }}
                                >
                                  <div style={{ flex: '4' }}>
                                    <div className={['midPaneInformation'].join(' ')}>
                                      {assesmentResult.assesseeAssignmentAssessmentName}
                                    </div>
                                    {assesmentResult.assesseeAssignmentAssessmentDescription ===
                                      '' ? null : (
                                      <div
                                        style={{ textAlign: 'left' }}
                                        className={['midPaneLabel', 'textOverflow'].join(' ')}
                                      >
                                        {assesmentResult.assesseeAssignmentAssessmentDescription}
                                      </div>
                                    )}
                                  </div>
                                  <div
                                    style={{ flex: '1', display: 'flex', alignItems: 'center' }}
                                    className="flex-center"
                                  ></div>
                                  <div
                                    style={{
                                      flex: '1',
                                      display: 'flex',
                                      alignItems: 'center',
                                      flexDirection: 'column'
                                    }}
                                    className="flex-center"
                                  ></div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div
                                style={{ display: headerOne === 'assignment' ? 'none' : 'block' }}
                                className={'containerPadding'}
                              >
                                <div className={'detsailsPadding-one'} style={{ display: 'flex' }}>
                                  <div className={['unitFlex', 'unitFlexTop'].join(' ')}>
                                    <div
                                      className={['unitFlex', 'midPaneInformationScale'].join(' ')}
                                      style={{ alignItems: 'flex-start' }}
                                    >
                                      {assesmentResult.assesseeAssignmentAssessmentItemResponseAttempted ||
                                        'n/a'}
                                    </div>
                                    <div className={['unitFlex', 'reports-center'].join(' ')}>
                                      attempted
                                    </div>
                                  </div>
                                  <div className={['unitFlex', 'unitFlexTop'].join(' ')}>
                                    <div
                                      className={['unitFlex', 'midPaneInformationScale'].join(' ')}
                                      style={{ alignItems: 'flex-start' }}
                                    >
                                      {assesmentResult.assesseeAssignmentAssessmentItemResponseCorrect ||
                                        'n/a'}
                                    </div>
                                    <div className={['unitFlex', 'reports-center'].join(' ')}>
                                      correct
                                    </div>
                                  </div>
                                  <div className={['unitFlex', 'unitFlexTop'].join(' ')}>
                                    <div
                                      className={['unitFlex', 'midPaneInformationScale'].join(' ')}
                                      style={{ alignItems: 'flex-start' }}
                                    >
                                      {assesmentResult.assesseeAssignmentAssessmentItemTotal || 'n/a'}
                                    </div>
                                    <div className={['unitFlex', 'reports-center'].join(' ')}>
                                      total
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className={'containerPadding'}>
                                <div className={'detsailsPadding-one'} style={{ display: 'flex' }}>
                                  <div
                                    className={['measureBox', 'heightInherit'].join(' ')}
                                    style={{ cursor: 'default', flex: 6 }}
                                  >
                                    <div
                                      className={['unitFlex', 'unitFlexTop'].join(' ')}
                                      style={{ flex: 2 }}
                                    >
                                      <div
                                        className={['unitFlex', 'midPaneInformationScale'].join(' ')}
                                      >
                                        {(assesmentResult?.assesseeAssignmentAssessmentTimeline
                                          ?.assesseeAssignmentAssessmentTimelineDateTimeStart &&
                                          convertToLocalTime(
                                            assesmentResult.assesseeAssignmentAssessmentTimeline
                                              ?.assesseeAssignmentAssessmentTimelineDateTimeStart
                                          )) ||
                                          'n/a'}
                                      </div>
                                      <div className={['unitFlex', 'reports-center'].join(' ')}>
                                        start
                                      </div>
                                    </div>
                                    <div
                                      className={['unitFlex', 'unitFlexTop'].join(' ')}
                                      style={{ flex: 2 }}
                                    >
                                      <div
                                        className={['unitFlex', 'midPaneInformationScale'].join(' ')}
                                      >
                                        {(assesmentResult?.assesseeAssignmentAssessmentTimeline
                                          ?.assesseeAssignmentAssessmentTimelineDateTimeEnd &&
                                          convertToLocalTime(
                                            assesmentResult.assesseeAssignmentAssessmentTimeline
                                              ?.assesseeAssignmentAssessmentTimelineDateTimeEnd
                                          )) ||
                                          'n/a'}
                                      </div>
                                      <div className={['unitFlex', 'reports-center'].join(' ')}>
                                        end
                                      </div>
                                    </div>
                                    <div
                                      className={['unitFlex', 'unitFlexTop'].join(' ')}
                                      style={{ flex: 2 }}
                                    >
                                      <div
                                        className={['unitFlex', 'midPaneInformationScale'].join(' ')}
                                      >
                                        {(assesmentResult?.assesseeAssignmentAssessmentAttemptedTotalTime &&
                                          calculateTime(
                                            assesmentResult.assesseeAssignmentAssessmentAttemptedTotalTime
                                          )) ||
                                          'n/a'}
                                      </div>
                                      <div className={['unitFlex', 'reports-center'].join(' ')}>
                                        time
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      )
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>
              )}
              {selectedBadge === 'gauges' && (
                <div>

                  {assesseeAssignment?.assesseeAssignmentiGaugeOccupationFrameworkOneOverallFit
                    ?.length > 0 &&
                    assesseeAssignment?.assesseeAssignmentiGaugeOccupationFrameworkOneOverallFit.map(
                      (fitment, index) => {
                        return (
                          <div className={'containerPadding'} style={{
                            borderBottom: '1px solid #bfbfbf',
                            borderRadius: '2px',

                          }} key={index}>
                            <div style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              textAlign: 'left'
                            }}>
                              <div style={{
                                width: '50%',
                                textOverflow: 'ellipsis',
                                height: '99px',
                              }}>

                                <div style={{ height: '50%', display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column' }}>
                                  <div className="midPaneInformation" style={{
                                    height: '25px'
                                  }}>
                                    {
                                      fitment?.cultureProfileDistinct ? fitment?.cultureProfileDistinct?.informationBasic
                                        ?.cultureProfileName : ''
                                    }
                                  </div>
                                  {fitment?.cultureProfileDistinct?.informationBasic
                                    ?.cultureProfileDescription && (<div className={'midPaneLabel'} style={{
                                      height: '25px'
                                    }}>
                                      {
                                        fitment?.cultureProfileDistinct ? fitment?.cultureProfileDistinct?.informationBasic
                                          ?.cultureProfileDescription : ''
                                      }
                                    </div>)}
                                </div>

                                <div style={{ height: '50%', display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column' }}>
                                  <div className="midPaneInformation" style={{
                                    height: '25px'
                                  }}>
                                    {
                                      fitment?.jobProfileDistinct ? fitment?.jobProfileDistinct?.informationBasic
                                        ?.jobProfileName : ''
                                    }
                                  </div>
                                  {fitment?.jobProfileDistinct?.informationBasic
                                    ?.jobProfileDescription && (<div className={'midPaneLabel'} style={{
                                      height: '25px'
                                    }}>
                                      {
                                        fitment?.jobProfileDistinct ? fitment?.jobProfileDistinct?.informationBasic
                                          ?.jobProfileDescription : ''
                                      }
                                    </div>)}
                                </div>
                              </div>
                              <div
                                className={['unitFlex', 'unitFlexTop'].join(' ')}
                                style={{ flex: 1 }}
                              >
                                <div style={{ fontSize: '12px' }} className={['unitFlex', 'midPaneInformation'].join(' ')}>
                                  {fitment?.cultureFit ? fitment?.cultureFit?.toFixed(2) || 'n/a' : 'n/a'}
                                </div>
                                <div className={['unitFlex', 'reports-center'].join(' ')}>
                                  culture-fit
                                </div>
                              </div>
                              <div
                                className={['unitFlex', 'unitFlexTop'].join(' ')}
                                style={{ flex: 1 }}
                              >
                                <div style={{ fontSize: '12px' }} className={['unitFlex', 'midPaneInformation'].join(' ')}>
                                  {fitment?.jobFit ? fitment?.jobFit?.toFixed(2) + '%' || 'n/a' : 'n/a'}
                                </div>
                                <div className={['unitFlex', 'reports-center'].join(' ')}>
                                  job-fit
                                </div>
                              </div>

                              <div
                                className={['unitFlex', 'unitFlexTop'].join(' ')}
                                style={{ flex: 1 }}
                              >
                                <div style={{ fontSize: '12px' }} className={['unitFlex', 'midPaneInformation'].join(' ')}>
                                  {fitment?.overallFit?.toFixed(2) + '%' || 'n/a'}
                                </div>
                                <div className={['unitFlex', 'reports-center'].join(' ')}>
                                  work-fit
                                </div>
                              </div>
                            </div>
                          </div>

                          // <div className={'containerPadding'} key={index}>
                          //   <div className={'detsailsPadding-one'} style={{ display: 'flex' }}>
                          //     <div
                          //       className={['measureBox', 'heightInherit'].join(' ')}
                          //       style={{ cursor: 'default', flex: 6 }}
                          //     >
                          //       <div
                          //         className={['unitFlex', 'unitFlexTop'].join(' ')}
                          //         style={{ flex: 3 }}
                          //       >
                          //         <div className={['unitFlex', 'midPaneInformation'].join(' ')}>
                          //           {
                          //             fitment?.cultureProfileDistinct ? fitment?.cultureProfileDistinct?.informationBasic
                          //               ?.cultureProfileName : ''
                          //           }{fitment?.jobProfileDistinct ? ' / ' + fitment?.jobProfileDistinct?.informationBasic
                          //             ?.jobProfileName : ''
                          //           }
                          //         </div>
                          //         <div className={['unitFlex', 'reports-center'].join(' ')}>
                          //           culture-profile / job-profile
                          //         </div>
                          //       </div>
                          //       <div
                          //         className={['unitFlex', 'unitFlexTop'].join(' ')}
                          //         style={{ flex: 1 }}
                          //       >
                          //         <div className={['unitFlex', 'midPaneInformation'].join(' ')}>
                          //           {fitment?.cultureFit ? fitment?.cultureFit?.toFixed(2) || 'n/a' : 'n/a'}
                          //         </div>
                          //         <div className={['unitFlex', 'reports-center'].join(' ')}>
                          //           culture-fit
                          //         </div>
                          //       </div>
                          //       <div
                          //         className={['unitFlex', 'unitFlexTop'].join(' ')}
                          //         style={{ flex: 1 }}
                          //       >
                          //         <div className={['unitFlex', 'midPaneInformation'].join(' ')}>
                          //           {fitment?.jobFit ? fitment?.jobFit?.toFixed(2) + '%' || 'n/a' : 'n/a'}
                          //         </div>
                          //         <div className={['unitFlex', 'reports-center'].join(' ')}>
                          //           job-fit
                          //         </div>
                          //       </div>
                          //       <div
                          //         className={['unitFlex', 'unitFlexTop'].join(' ')}
                          //         style={{ flex: 1 }}
                          //       >
                          //         <div className={['unitFlex', 'midPaneInformation'].join(' ')}>
                          //           {fitment?.overallFit?.toFixed(2) + '%' || 'n/a'}
                          //         </div>
                          //         <div className={['unitFlex', 'reports-center'].join(' ')}>
                          //           work-fit
                          //         </div>
                          //       </div>
                          //     </div>
                          //   </div>
                          // </div>
                        );
                      }
                    )}
                </div>
              )}
              {selectedBadge === 'evaluation' && headerOne === 'assessee' && (
                <>
                  <div style={{ display: headerOne === 'assignment' ? 'none' : 'block' }}>
                    {assesseeAssignment.assesseeAssignmentAssessmentDistinct?.length > 0 ? (
                      assesseeAssignment.assesseeAssignmentAssessmentDistinct.map(
                        (assesmentResult, index) => (
                          <div key={index}>
                            <div className={'containerPadding'}>
                              <div
                                // disableFocusRipple={true}
                                // disableRipple={true}
                                className={['heightInherit', 'detsailsPadding-one'].join(' ')}
                                //className={['heightInherit', 'detsailsPadding-one'].join(' ')}
                                style={{ textTransform: 'none' }}
                              >
                                <div
                                  className={['measureBox', 'heightInherit'].join(' ')}
                                  style={{ cursor: 'default' }}
                                >
                                  <div style={{ flex: '4' }}>
                                    <div className={['midPaneInformation'].join(' ')}>
                                      {assesmentResult.assesseeAssignmentAssessmentName}
                                    </div>
                                    {assesmentResult.assesseeAssignmentAssessmentDescription ===
                                      '' ? null : (
                                      <div
                                        style={{ textAlign: 'left' }}
                                        className={['midPaneLabel', 'textOverflow'].join(' ')}
                                      >
                                        {assesmentResult.assesseeAssignmentAssessmentDescription}
                                      </div>
                                    )}
                                  </div>
                                  <div
                                    //className={['flex-center', 'flex-center-one '].join(' ')}
                                    className={['unitFlex', 'unitFlexTop', 'font-12', 'line-height-15'].join(' ')} 
                                  >
                                    {assesmentResult?.assesseeAssignmentAssessmentScore
                                      ?.assesseeAssignmentAssessmentScoreExtremumMaximum || 'n/a'}
                                    <span style={{ fontSize: '1rem' }}>maximum</span>
                                  </div>{' '}
                                  <div
                                    //className={['flex-center', 'flex-center-one '].join(' ')}
                                    className={['unitFlex', 'unitFlexTop', 'font-12', 'line-height-15'].join(' ')} 
                                  >
                                    {assesmentResult?.assesseeAssignmentAssessmentScore
                                      ?.assesseeAssignmentAssessmentScoreExtremumMinimum || 'n/a'}
                                    <span style={{ fontSize: '1rem' }}>minimum</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={''}>
                              {(assesmentResult?.assesseeAssignmentAssessmentCluster?.length >
                                0 && (
                                  <Fragment>
                                    {assesmentResult?.assesseeAssignmentAssessmentCluster.map(
                                      (clust, index) => {
                                        return (
                                          <div className={'containerPadding'} key={index} >
                                            <ReviewList
                                              className=""
                                              id={clust?.assesseeAssignmentAssessmentClusterId}
                                              textOne={clust?.assesseeAssignmentAssessmentClusterName}
                                              textTwo={
                                                clust?.assesseeAssignmentAssessmentClusterDescription
                                              }
                                              status={
                                                (assesmentResult.assesseeAssignmentAssessmentName ===
                                                  'Career Search' &&
                                                  clust?.assesseeAssignmentAssessmentClusterScorePercentile >=
                                                  0 &&
                                                  'percentile') ||
                                                (clust?.assesseeAssignmentAssessmentClusterScoreStandardTen >=
                                                  0 &&
                                                  'sten') ||
                                                ''
                                              }
                                              isInformation={true}
                                              isInformationValue={
                                                (assesmentResult.assesseeAssignmentAssessmentName ===
                                                  'Career Search' &&
                                                  clust?.assesseeAssignmentAssessmentClusterScorePercentile >=
                                                  0 &&
                                                  clust?.assesseeAssignmentAssessmentClusterScorePercentile.toFixed(
                                                    2
                                                  )) ||
                                                (clust?.assesseeAssignmentAssessmentClusterScoreStandardTen >=
                                                  0 &&
                                                  clust?.assesseeAssignmentAssessmentClusterScoreStandardTen.toFixed(
                                                    2
                                                  )) ||
                                                '0'
                                              }
                                              isInformationTwo={true}
                                              isInformationTwoValue={
                                                clust?.assesseeAssignmentAssessmentClusterItemScoreTotal?.toFixed(
                                                  2
                                                ) || '0'
                                              }
                                              statusTwo={'score'}
                                            />
                                          </div>
                                        );
                                      }
                                    )}
                                  </Fragment>
                                )) ||
                                (assesmentResult.assesseeAssignmentAssessmentName ===
                                  'Business Judgement' && (
                                    <Fragment>
                                      <div className={'containerPadding'}>
                                        <ReviewList
                                          className=""
                                          id={2}
                                          textOne={'Action Orientation'}
                                          textTwo={''}
                                          status={'sten'}
                                          isInformation={true}
                                          isInformationValue={//
                                            (assesmentResult?.assesseeAssignmentAssessmentScore
                                              ?.assesseeAssignmentAssessmentActionOrientedScoreStandardTen &&
                                              assesmentResult?.assesseeAssignmentAssessmentScore?.assesseeAssignmentAssessmentActionOrientedScoreStandardTen.toFixed(
                                                2
                                              )) ||
                                            '0'
                                          }
                                          isInformationTwo={true}
                                          isInformationTwoValue={
                                            // assesmentResult?.assesseeAssignmentAssessmentScore
                                            //   ?.assesseeAssignmentAssesssmentActionOrientedScoreRaw ||
                                            // '0'
                                            'n/a'
                                          }
                                          statusTwo={'score'}
                                        />
                                        <ReviewList
                                          className=""
                                          id={1}
                                          textOne={'Quality Of Judgement'}
                                          textTwo={''}
                                          status={'sten'}
                                          isInformation={true}
                                          isInformationValue={
                                            (assesmentResult?.assesseeAssignmentAssessmentScore
                                              ?.assesseeAssignmentAssessmentQualityOfJudgementScoreStandardTen &&
                                              assesmentResult?.assesseeAssignmentAssessmentScore?.assesseeAssignmentAssessmentQualityOfJudgementScoreStandardTen.toFixed(
                                                2
                                              )) ||
                                            '0'
                                          }
                                          isInformationTwo={true}
                                          isInformationTwoValue={
                                            assesmentResult?.assesseeAssignmentAssessmentScore
                                              ?.assesseeAssignmentAssessmentScoreRaw || '0'
                                          }
                                          statusTwo={'score'}
                                        />
                                        <ReviewList
                                          className=""
                                          id={1}
                                          textOne={'Speed Of Judgement'}
                                          textTwo={''}
                                          status={'sten'}
                                          isInformation={true}
                                          isInformationValue={
                                            (assesmentResult?.assesseeAssignmentAssessmentScore
                                              ?.assesseeAssignmentAssessmentSpeedOfJudgementScoreStandardTen &&
                                              assesmentResult?.assesseeAssignmentAssessmentScore?.assesseeAssignmentAssessmentSpeedOfJudgementScoreStandardTen.toFixed(
                                                2
                                              )) ||
                                            '0'
                                          }
                                          isInformationTwo={true}
                                          isInformationTwoValue={
                                            Math.round(
                                              assesmentResult?.assesseeAssignmentAssessmentScore
                                                ?.assesseeAssignmentAssesssmentSpeedOfJudgementScoreRaw
                                            ) || '0'
                                          }
                                          statusTwo={'score'}
                                        />
                                      </div>
                                    </Fragment>
                                  )) || (
                                    <Fragment>
                                    <div className="containerPadding">
                                      <div
                                        className={['heightInherit'].join(
                                          ' '
                                        )}
                                        //className='detsailsPadding-one'
                                      >
                                        <div style={{ display: 'flex' }}>
                                          <div className={['unitFlex', 'unitFlexTop'].join(' ')}>
                                            <div
                                              className={[
                                                'unitFlex',
                                                'midPaneInformationScale'
                                              ].join(' ')}
                                              style={{ alignItems: 'flex-start' }}
                                            >
                                              {assesmentResult?.assesseeAssignmentAssessmentScore
                                                ?.assesseeAssignmentAssessmentScoreGrade || 'n/a'}
                                            </div>
                                            <div
                                              className={['unitFlex', 'reports-center'].join(' ')}
                                            >
                                              grade
                                            </div>
                                          </div>
                                          <div className={['unitFlex', 'unitFlexTop'].join(' ')}>
                                            <div
                                              className={[
                                                'unitFlex',
                                                'midPaneInformationScale'
                                              ].join(' ')}
                                              style={{ alignItems: 'flex-start' }}
                                            >
                                              {assesmentResult?.assesseeAssignmentAssessmentScore?.assesseeAssignmentAssessmentScorePercentage?.toFixed(
                                                2
                                              ) || 'n/a'}
                                            </div>
                                            <div
                                              className={['unitFlex', 'reports-center'].join(' ')}
                                            >
                                              percentage
                                            </div>
                                          </div>
                                          <div className={['unitFlex', 'unitFlexTop'].join(' ')}>
                                            <div
                                              className={[
                                                'unitFlex',
                                                'midPaneInformationScale'
                                              ].join(' ')}
                                              style={{ alignItems: 'flex-start' }}
                                            >
                                              {' '}
                                              {assesmentResult?.assesseeAssignmentAssessmentScore
                                                ?.assesseeAssignmentAssessmentScorePercentile ||
                                                'n/a'}
                                            </div>
                                            <div
                                              className={['unitFlex', 'reports-center'].join(' ')}
                                            >
                                              percentile
                                            </div>
                                          </div>
                                          <div className={['unitFlex', 'unitFlexTop'].join(' ')}>
                                            <div
                                              className={[
                                                'unitFlex',
                                                'midPaneInformationScale'
                                              ].join(' ')}
                                              style={{ alignItems: 'flex-start' }}
                                            >
                                              {assesmentResult?.assesseeAssignmentAssessmentScore
                                                ?.assesseeAssignmentAssessmentScoreRank || 'n/a'}
                                            </div>
                                            <div
                                              className={['unitFlex', 'reports-center'].join(' ')}
                                            >
                                              rank
                                            </div>
                                          </div>
                                          <div className={['unitFlex', 'unitFlexTop'].join(' ')}>
                                            <div
                                              className={[
                                                'unitFlex',
                                                'midPaneInformationScale'
                                              ].join(' ')}
                                              style={{ alignItems: 'flex-start' }}
                                            >
                                              {assesmentResult?.assesseeAssignmentAssessmentScore
                                                ?.assesseeAssignmentAssessmentScoreRaw || 'n/a'}
                                            </div>
                                            <div
                                              className={['unitFlex', 'reports-center'].join(' ')}
                                            >
                                              score
                                            </div>
                                          </div>
                                          <div className={['unitFlex', 'unitFlexTop'].join(' ')}>
                                            <div
                                              className={[
                                                'unitFlex',
                                                'midPaneInformationScale'
                                              ].join(' ')}
                                              style={{ alignItems: 'flex-start' }}
                                            >
                                              {assesmentResult?.assesseeAssignmentAssessmentScore
                                                ?.assesseeAssignmentAssessmentScoreStandardTen ||
                                                'n/a'}
                                            </div>
                                            <div
                                              className={['unitFlex', 'reports-center'].join(' ')}
                                            >
                                              sten
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Fragment>
                                )}
                            </div>
                          </div>
                        )
                      )
                    ) : (
                      <div></div>
                    )}
                  </div>
                </>
              )}
            </div>
          </Paper>
        </div>
      </>
      <></>
      {isMobile && (
        <div className={'containerPadding'} style={{ height: '55px' }}>
          {' '}
        </div>
      )}
    </div>
  );
};

export default DisplayPaneThreeSectionOneAssesseeReport;
