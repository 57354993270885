import { put, takeLatest, call, takeEvery } from 'redux-saga/effects';
import {
    LOADER_STOP,
    REVIEWLIST_DISTINCT_DATA,
    SET_MIDDLEPANE_STATE,
    RELATED_REVIEWLIST_DISTINCT_DATA,
    SET_POPUP_VALUE,
    ASSIGNMENT_MARKETPLACE_REVIEW_DISTINCT_SAGA,
    ASSIGNMENT_MARKETPLACE_REVIEW_GROUP_SAGA,
    ASSIGNMENT_MARKETPLACE_REVIEW_NODE_SAGA,
    ASSIGNMENT_MARKETPLACE_REVIEW_TYPE_SAGA,
    SET_POPUP_SINGLE_STATE,
    SET_CORE_GROUP_REVIEW_LIST_DATA
} from '../../actionType';
import { ASSIGNMENT_MODULE_URL } from '../../endpoints';
import { EXCEPTION_ERROR_MESSAGE } from '../../errorMessage';
import Store from '../../store';

const reviewListMarketplaceDistinctApi = async (requestObj) => {
    console.log({ demo: 'called' });
    // let URL = ASSESSEE_REVIEW_LIST_URL;
    const requestOptions = {
        method: 'POST',
        headers: new Headers({
            Authorization: localStorage.getItem('token')
        }),
        body: JSON.stringify(requestObj.data)
    };
    const response = await fetch(ASSIGNMENT_MODULE_URL, requestOptions);
    const json = await response.json();
    return json;
};

function* workerMarketplaceDistinctReviewListAssesseeSaga(data) {
    try {
        const userResponse = yield call(reviewListMarketplaceDistinctApi, {
            data: data.payload.request
        });
        // const userResponse ={responseCode:'000',countTotal:30}
        if (userResponse.responseCode === '000') {
            yield put({
                type: SET_MIDDLEPANE_STATE,
                payload: {
                    middlePaneHeader: "assignments",
                    middlePaneHeaderBadgeOne: data.payload.BadgeOne,
                    middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
                    middlePaneHeaderBadgeThree: '',
                    middlePaneHeaderBadgeFour: '',
                    middlePaneHeaderDuplicate: data.payload.HeaderOne,
                    middlePaneHeaderDuplicateBadgeOne: data.payload.BadgeOne,
                    middlePaneHeaderDuplicateBadgeTwo: data.payload.BadgeTwo,
                    middlePaneHeaderDuplicateBadgeThree: '',
                    middlePaneHeaderDuplicateBadgeFour: '',
                    middlePaneHeaderDuplicateOne: data.payload.HeaderOne,
                    middlePaneHeaderDuplicateOneBadgeOne: data.payload.BadgeOne,
                    middlePaneHeaderDuplicateOneBadgeTwo: data.payload.BadgeTwo,
                    middlePaneHeaderDuplicateOneBadgeThree: '',
                    middlePaneHeaderDuplicateOneBadgeFour: '',

                    typeOfMiddlePaneList: 'MarketplaceAssignmentDistinctReviewList',
                    scanCount: userResponse && userResponse.countTotal,
                    duplicateScanCount: userResponse && userResponse.countTotal,
                    showMiddlePaneState: true,
                    isSelectActive: data.payload.isSelectActive,
                    selectedTagsArray: data.payload.selectedTagsArray || [],
                    unselectedTagsArray: data.payload.unselectedTagsArray || [],
                    isListLoading: false
                }
            });
            yield put({
                type: REVIEWLIST_DISTINCT_DATA,
                payload: userResponse.responseObject?.length > 0 ? userResponse.responseObject : []
            });
        } else {
            yield put({
                type: SET_POPUP_VALUE,
                payload: {
                    isPopUpValue: userResponse.responseMessage,
                    popupMode: 'responseErrorMsg'
                }
            });
        }

        console.log('loading end');
        yield put({ type: LOADER_STOP });
    } catch (e) {
        console.log('ERROR==', e);
        yield put({
            type: SET_POPUP_VALUE,
            payload: {
                isPopUpValue: EXCEPTION_ERROR_MESSAGE,
                popupMode: 'responseErrorMsg'
            }
        });
        yield put({ type: LOADER_STOP });
    }
}

function* workerMarketplaceGroupReviewListAssesseeSaga(data) {
    try {
        const userResponse = yield call(reviewListMarketplaceDistinctApi, {
            data: data.payload.request,
        });
        // const userResponse ={responseCode:'000',countTotal:30}
        const { groupViewState = 'list' } = data.payload;
        if (userResponse.responseCode === '000') {
            if (data.payload.isMiddlePaneList) {
                yield put({
                    type: SET_MIDDLEPANE_STATE,
                    payload: {
                        middlePaneHeader: 'assignments',
                        middlePaneHeaderBadgeOne: data.payload.BadgeOne,
                        middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
                        middlePaneHeaderBadgeThree: data.payload.BadgeThree,
                        middlePaneHeaderBadgeFour: '',
                        middlePaneHeaderDuplicate: 'assignments',
                        middlePaneHeaderDuplicateBadgeOne: data.payload.BadgeOne,
                        middlePaneHeaderDuplicateBadgeTwo: data.payload.BadgeTwo,
                        middlePaneHeaderDuplicateBadgeThree: data.payload.BadgeThree,
                        middlePaneHeaderDuplicateOne: 'assignments',
                        middlePaneHeaderDuplicateOneBadgeOne: data.payload.BadgeOne,
                        middlePaneHeaderDuplicateOneBadgeTwo: data.payload.BadgeTwo,
                        middlePaneHeaderDuplicateOneBadgeThree: data.payload.BadgeThree,
                        middlePaneHeaderDuplicateOneBadgeFour: data.payload.BadgeFour,
                        typeOfMiddlePaneList: 'MarketplaceAssignmentGroupReviewList',
                        scanCount: userResponse && userResponse.countTotal,
                        duplicateScanCount: userResponse && userResponse.countTotal,
                        showMiddlePaneState: true,
                        isSelectActive: data.payload.isSelectActive,
                        selectedTagsArray: data.payload.selectedTagsArray
                    }
                });
                yield put({
                    type: SET_POPUP_SINGLE_STATE,
                    payload: { stateName: 'cardValue', value: data.payload.cardValue }
                });
            }
            let response = userResponse.responseObject;
            yield put({
                type: data.payload.isMiddlePaneList
                    ? REVIEWLIST_DISTINCT_DATA
                    : SET_CORE_GROUP_REVIEW_LIST_DATA,
                payload: response
            });
        } else {
            yield put({
                type: SET_POPUP_VALUE,
                payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
            });
        }
        console.log('loading end');
        yield put({ type: LOADER_STOP });
        // yield put({ type: CLEAR_ASSIGNMENT_INFO });
    } catch (e) {
        console.log('ERROR==', e);
        yield put({
            type: SET_POPUP_VALUE,
            payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
        });
        yield put({ type: LOADER_STOP });
    }
}

function* workerMarketplaceNodeReviewListAssesseeSaga(data) {
    try {
        const userResponse = yield call(reviewListMarketplaceDistinctApi, {
            data: data.payload.request
        });
        // const userResponse ={responseCode:'000',countTotal:30}
        if (userResponse.responseCode === '000') {
            yield put({
                type: SET_MIDDLEPANE_STATE,
                payload: {
                    middlePaneHeader: data.payload.HeaderOne,
                    middlePaneHeaderBadgeOne: data.payload.BadgeOne,
                    middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
                    middlePaneHeaderBadgeThree: '',
                    middlePaneHeaderBadgeFour: '',
                    middlePaneHeaderDuplicate: data.payload.HeaderOne,
                    middlePaneHeaderDuplicateBadgeOne: data.payload.BadgeOne,
                    middlePaneHeaderDuplicateBadgeTwo: data.payload.BadgeTwo,
                    middlePaneHeaderDuplicateBadgeThree: '',
                    middlePaneHeaderDuplicateBadgeFour: '',
                    middlePaneHeaderDuplicateOne: data.payload.HeaderOne,
                    middlePaneHeaderDuplicateOneBadgeOne: data.payload.BadgeOne,
                    middlePaneHeaderDuplicateOneBadgeTwo: data.payload.BadgeTwo,
                    middlePaneHeaderDuplicateOneBadgeThree: '',
                    middlePaneHeaderDuplicateOneBadgeFour: '',

                    typeOfMiddlePaneList: 'MarketplaceAssignmentNodeReviewList',
                    scanCount: userResponse && userResponse.countTotal,
                    duplicateScanCount: userResponse && userResponse.countTotal,
                    showMiddlePaneState: true,
                    isSelectActive: data.payload.isSelectActive,
                    selectedTagsArray: data.payload.selectedTagsArray || [],
                    unselectedTagsArray: data.payload.unselectedTagsArray || [],
                    isListLoading: false
                }
            });
            yield put({
                type: RELATED_REVIEWLIST_DISTINCT_DATA,
                payload: userResponse.responseObject?.length > 0 ? userResponse.responseObject : []
            });
        } else {
            yield put({
                type: SET_POPUP_VALUE,
                payload: {
                    isPopUpValue: userResponse.responseMessage,
                    popupMode: 'responseErrorMsg'
                }
            });
        }

        console.log('loading end');
        yield put({ type: LOADER_STOP });
    } catch (e) {
        console.log('ERROR==', e);
        yield put({
            type: SET_POPUP_VALUE,
            payload: {
                isPopUpValue: EXCEPTION_ERROR_MESSAGE,
                popupMode: 'responseErrorMsg'
            }
        });
        yield put({ type: LOADER_STOP });
    }
}

function* workerMarketplaceTypeReviewListAssesseeSaga(data) {
    try {
        const userResponse = yield call(reviewListMarketplaceDistinctApi, {
            data: data.payload.request
        });
        // const userResponse ={responseCode:'000',countTotal:30}
        if (userResponse.responseCode === '000') {
            yield put({
                type: SET_MIDDLEPANE_STATE,
                payload: {
                    middlePaneHeader: data.payload.HeaderOne,
                    middlePaneHeaderBadgeOne: data.payload.BadgeOne,
                    middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
                    middlePaneHeaderBadgeThree: '',
                    middlePaneHeaderBadgeFour: '',
                    middlePaneHeaderDuplicate: data.payload.HeaderOne,
                    middlePaneHeaderDuplicateBadgeOne: data.payload.BadgeOne,
                    middlePaneHeaderDuplicateBadgeTwo: data.payload.BadgeTwo,
                    middlePaneHeaderDuplicateBadgeThree: '',
                    middlePaneHeaderDuplicateBadgeFour: '',
                    middlePaneHeaderDuplicateOne: data.payload.HeaderOne,
                    middlePaneHeaderDuplicateOneBadgeOne: data.payload.BadgeOne,
                    middlePaneHeaderDuplicateOneBadgeTwo: data.payload.BadgeTwo,
                    middlePaneHeaderDuplicateOneBadgeThree: '',
                    middlePaneHeaderDuplicateOneBadgeFour: '',

                    typeOfMiddlePaneList: 'MarketplaceAssignmentTypeReviewList',
                    scanCount: userResponse && userResponse.countTotal,
                    duplicateScanCount: userResponse && userResponse.countTotal,
                    showMiddlePaneState: true,
                    isSelectActive: data.payload.isSelectActive,
                    selectedTagsArray: data.payload.selectedTagsArray || [],
                    unselectedTagsArray: data.payload.unselectedTagsArray || [],
                    isListLoading: false
                }
            });
            yield put({
                type: RELATED_REVIEWLIST_DISTINCT_DATA,
                payload: userResponse.responseObject?.length > 0 ? userResponse.responseObject : []
            });
        } else {
            yield put({
                type: SET_POPUP_VALUE,
                payload: {
                    isPopUpValue: userResponse.responseMessage,
                    popupMode: 'responseErrorMsg'
                }
            });
        }

        console.log('loading end');
        yield put({ type: LOADER_STOP });
    } catch (e) {
        console.log('ERROR==', e);
        yield put({
            type: SET_POPUP_VALUE,
            payload: {
                isPopUpValue: EXCEPTION_ERROR_MESSAGE,
                popupMode: 'responseErrorMsg'
            }
        });
        yield put({ type: LOADER_STOP });
    }
}

export default function* watchReviewListAssesseeSaga() {
    yield takeEvery(
        ASSIGNMENT_MARKETPLACE_REVIEW_DISTINCT_SAGA,
        workerMarketplaceDistinctReviewListAssesseeSaga
    );
    yield takeEvery(
        ASSIGNMENT_MARKETPLACE_REVIEW_GROUP_SAGA,
        workerMarketplaceGroupReviewListAssesseeSaga
    );
    yield takeEvery(
        ASSIGNMENT_MARKETPLACE_REVIEW_NODE_SAGA,
        workerMarketplaceNodeReviewListAssesseeSaga
    );
    yield takeEvery(
        ASSIGNMENT_MARKETPLACE_REVIEW_TYPE_SAGA,
        workerMarketplaceTypeReviewListAssesseeSaga
    );
}
