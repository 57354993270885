import React from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import DisplayPanelAccordianReviewListOne from '../Accordian/DisplayPanelAccordianReviewListOne';
import DisplayPanelAccordianInformation from '../Accordian/DisplayPanelAccordianInformation';
import { Paper } from '@material-ui/core';
import { SET_POPUP_VALUE } from '../../actionType';

const DisplayPaneThreeSectionTwoAssessmentScore = () => {
  const dispatch = useDispatch();
  const { responseObject, reviewMode } = useSelector((state) => state.DisplayPaneThreeReducer);
  console.log(responseObject, 'score');
  const frameworkAll = [
    {
      id: 'calculator',
      labelTextOneOne: 'calculator',
      labelTextOneOneBadges: [],
      innerAssociateList: [],
      innerInfo: 'assessment',
      isListCard: false,
      isReviewLink: false,
      isMultiInfoCard: true
    },
    {
      id: 'score',
      labelTextOneOne: 'score',
      labelTextOneOneBadges: [
        {
          labelTextTwoBadge: '1',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'description',
              innerLabelBadgeList: responseObject?.assessmentScoreOneDescription || 'No Information'
            },
            {
              labelTextTwoBadge: 'explanation',
              innerLabelBadgeList:
                responseObject?.assessmentScoreOneExplanation || 'No Information',
              multiline: true
            },
            {
              labelTextTwoBadge: 'label',
              innerLabelBadgeList: responseObject?.assessmentScoreOneLabel || 'No Information'
            }
            //   {
            //     labelTextTwoBadge: 'polarity',
            //     innerLabelBadgeList: responseObject?.assessmentClusterOnePolarity || 'No Information'
            //   }
          ]
        },
        {
          labelTextTwoBadge: '2',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'description',
              innerLabelBadgeList: responseObject?.assessmentScoreTwoDescription || 'No Information'
            },
            {
              labelTextTwoBadge: 'explanation',
              innerLabelBadgeList: responseObject?.assessmentScoreTwoExplanation || 'No Information'
            },
            {
              labelTextTwoBadge: 'label',
              innerLabelBadgeList: responseObject?.assessmentScoreTwoLabel || 'No Information'
            }
            //   {
            //     labelTextTwoBadge: 'polarity',
            //     innerLabelBadgeList: responseObject?.assessmentClusterTwoPolarity || 'No Information'
            //   }
          ]
        }
      ],
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false,
      isReviewLink: false,
      isMultiInfoCard: true
    }
    // comment by payal to create score
    // {
    //   id: 'classification',
    //   labelTextOneOne: 'classification',
    //   textOneOne:
    //     responseObject?.assessmentScoreClassification.assessmentScoreClassificationPrimary ||
    //     'No Information',
    //   labelTextOneOneBadges: [],
    //   innerAssociateList: [],
    //   innerInfo: 'assessment',
    //   isListCard: false,
    //   isReviewLink: false,
    //   isMultiInfoCard: true
    // }
  ];

  const onClickReview = (e) => {};
  // changes by payal 27/06/22
  // const onClickRevise = (e, selectedBadgeArray) => {
  //   const labelName = e.currentTarget.getAttribute('data-value');
  //   const selectedBadgeName = e.currentTarget.getAttribute('data-key');
  //   let badgeName = '';
  //   if (selectedBadgeArray) {
  //     if (selectedBadgeArray.length > 0) {
  //       selectedBadgeArray.forEach((element) => {
  //         badgeName = badgeName + element.labelTextTwoBadge;
  //       });
  //     }
  //   }
  //   console.log(badgeName);
  //   console.log(labelName + '  ' + selectedBadgeName);
  //   //if (labelName === 'score') {
  //   if (labelName === 'calculator') {
  //     dispatch({
  //       type: SET_POPUP_VALUE,
  //       payload: { isPopUpValue: 'SCORE_POPUP', popupMode: 'SCORECREATE' }
  //     });
  //   }
  //   if (labelName === 'score') {
  //     dispatch({
  //       type: SET_POPUP_VALUE,
  //       payload: { isPopUpValue: 'SCORE_POPUP', popupMode: 'SCORECREATE' }
  //     });
  //   }
  // };
  const onClickRevise = (e, selectedBadgeArray) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    let badgeName = '';
    if (selectedBadgeArray) {
      if (selectedBadgeArray.length > 0) {
        selectedBadgeArray.forEach((element) => {
          badgeName = badgeName + element.labelTextTwoBadge;
        });
      }
    }
    console.log(badgeName);
    console.log(labelName + '  ' + selectedBadgeName);
    // if (labelName === 'explanation') {
    //   dispatch({
    //     type: SET_POPUP_VALUE,
    //     payload: { isPopUpValue: 'SCOREEXPLANATIONPOPUP', popupMode: 'SCORECREATE' }
    //   });
    // }
    // if (labelName === 'sequence') {
    //   dispatch({
    //     type: SET_POPUP_VALUE,
    //     payload: { isPopUpValue: 'SEQUENCEPOPUP', popupMode: 'SCORECREATE' }
    //   });
    // }

    // if (labelName === 'score' && badgeName === '1' && selectedBadgeName === 'description') {
    //   debugger
    //   dispatch({
    //     type: SET_POPUP_VALUE,
    //     payload: { isPopUpValue: 'ONEONEDESCPOPUP', popupMode: 'SCORECREATE' }
    //   });
    // }

    // for badgeName === '1'
    // if (labelName === 'score' && badgeName === '1' && selectedBadgeName === 'description') {
    //   dispatch({
    //     type: SET_POPUP_VALUE,
    //     payload: { isPopUpValue: 'ONEONEDESCRIPTION', popupMode: 'SCORECREATE' }
    //   });
    // }
    if (labelName === 'score' && badgeName === '1' && selectedBadgeName === 'description') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'SCOREDESCRIPTION', popupMode: 'SCORECREATE' }
      });
    }
    if (labelName === 'score' && badgeName === '1' && selectedBadgeName === 'explanation') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ONEONEEXPLANATION', popupMode: 'SCORECREATE' }
      });
    }
    if (labelName === 'score' && badgeName === '1' && selectedBadgeName === 'label') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ONEONELABELPOPUP', popupMode: 'SCORECREATE' }
      });
    }

    // for badgeName === '2'
    // if (labelName === 'score' && badgeName === '2' && selectedBadgeName === 'description') {
    //   dispatch({
    //     type: SET_POPUP_VALUE,
    //     payload: { isPopUpValue: 'ONETWODESCPOPUP', popupMode: 'SCORECREATE' }
    //   });
    // }
    if (labelName === 'score' && badgeName === '2' && selectedBadgeName === 'description') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ONETWODESCRIPTION', popupMode: 'SCORECREATE' }
      });
    }
    if (labelName === 'score' && badgeName === '2' && selectedBadgeName === 'explanation') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ONETWOEXPLANATION', popupMode: 'SCORECREATE' }
      });
    }
    if (labelName === 'score' && badgeName === '2' && selectedBadgeName === 'label') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ONETWOLABELPOPUP', popupMode: 'SCORECREATE' }
      });
    }
    console.log('responseObject>>>', responseObject);
  };
  return (
    <div>
      <>
        <div className={'containerPadding'}>
          <Paper className={'dossierContainerTop'}>
            {frameworkAll.map((ob) => {
              return (
                <div key={ob.id}>
                  {ob.isListCard ? (
                    <DisplayPanelAccordianReviewListOne
                      className=""
                      accordianObject={ob}
                      mode={reviewMode}
                      onClickRevise={onClickRevise}
                      onClickReview={onClickReview}
                    />
                  ) : (
                    <DisplayPanelAccordianInformation
                      accordianObject={ob}
                      mode={reviewMode}
                      onClickRevise={onClickRevise}
                      onClickReview={onClickReview}
                    />
                  )}
                </div>
              );
            })}
          </Paper>
        </div>
      </>
      {isMobile && (
        <div className={'containerPadding'} style={{ height: '55px' }}>
          {' '}
        </div>
      )}
    </div>
  );
};

export default DisplayPaneThreeSectionTwoAssessmentScore;
