import { put, takeLatest, call } from 'redux-saga/effects';
import Store from '../../store';
import {
  CREATE_ASSESSMENT_SAGA,
  CREATE_ASSESSMENT_SECTION_SAGA,
  LOADER_STOP,
  POPUP_CLOSE,
  RELATED_REVIEWLIST_DISTINCT_DATA,
  SET_ASSESSMENT_BASIC_REDUCER_STATE,
  SET_DISPLAY_PANE_THREE_STATE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MIDDLEPANE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_POPUP_SINGLE_STATE,
  SET_POPUP_VALUE,
  UPDATE_ASSESSMENT_SETUP_PRIMARY_INFO
} from '../../actionType';
import { ASSESSMENT_MODULE_URL } from '../../endpoints';
import { EXCEPTION_ERROR_MESSAGE, INFORMATION_EXIST_ERROR_MESSAGE } from '../../errorMessage';

const createAssessmentApi = async (requestObj) => {
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      Authorization: localStorage.getItem('token')
    }),
    body: JSON.stringify(requestObj.data)
  };
  const response = await fetch(requestObj.URL, requestOptions);
  const json = await response.json();
  return json;
};
function* workerCreateAssessmentSectionSaga(data) {
  try {
    const apiResponse = yield call(createAssessmentApi, {
      data: data.payload.requestObj,
      URL: ASSESSMENT_MODULE_URL
    });
    if (apiResponse.responseCode === '000') {
      let assessmentInfo = apiResponse.responseObject[0];
      let BadgeOne, reviseResponseObj;
      if (data.payload.typeOfMiddlePaneList === 'assessmentgradesReviewList') {
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
        BadgeOne = 'grades';
        reviseResponseObj = {
          countTotal:
            assessmentInfo?.informationFramework?.assessmentScoreGradeFramework?.length || 0,
          responseObject: [
            {
              grades: assessmentInfo?.informationFramework?.assessmentScoreGradeFramework || [],
              assessmentName: assessmentInfo.informationBasic.assessmentName,
              assessmentDescription: assessmentInfo.informationBasic.assessmentDescription,
              assessmentStatus: assessmentInfo.informationEngagement.assessmentStatus,
              id: assessmentInfo.id,
              assessmentId: assessmentInfo.id
            }
          ]
        };
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: 'assessment',
            middlePaneHeaderBadgeOne: BadgeOne,
            middlePaneHeaderBadgeTwo: 'distinct',
            middlePaneHeaderBadgeThree: '',
            middlePaneHeaderBadgeFour: '',
            middlePaneHeaderDuplicate:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
            middlePaneHeaderDuplicateBadgeOne:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            middlePaneHeaderDuplicateBadgeTwo:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            middlePaneHeaderDuplicateBadgeThree:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
            middlePaneHeaderDuplicateBadgeFour:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour,
            typeOfMiddlePaneList: data.payload.typeOfMiddlePaneList,
            middlePaneSelectedValue: assessmentInfo.id,
            scanCount: reviseResponseObj && reviseResponseObj.countTotal,
            showMiddlePaneState: true
          }
        });
        yield put({
          type: RELATED_REVIEWLIST_DISTINCT_DATA,
          payload: reviseResponseObj.responseObject
        });
        yield put({ type: LOADER_STOP });
      }
      if (data.payload.typeOfMiddlePaneList === 'assessmentnormsReviewList') {
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
        BadgeOne = 'norms';
        reviseResponseObj = {
          countTotal: assessmentInfo?.informationFramework?.assessmentNormFramework?.length || 0,
          responseObject: [
            {
              norms: assessmentInfo?.informationFramework?.assessmentNormFramework || [],
              assessmentName: assessmentInfo.informationBasic.assessmentName,
              assessmentDescription: assessmentInfo.informationBasic.assessmentDescription,
              assessmentStatus: assessmentInfo.informationEngagement.assessmentStatus,
              id: assessmentInfo.id,
              assessmentId: assessmentInfo.id
            }
          ]
        };
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: 'assessment',
            middlePaneHeaderBadgeOne: BadgeOne,
            middlePaneHeaderBadgeTwo: 'distinct',
            middlePaneHeaderBadgeThree: '',
            middlePaneHeaderBadgeFour: '',
            middlePaneHeaderDuplicate:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
            middlePaneHeaderDuplicateBadgeOne:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            middlePaneHeaderDuplicateBadgeTwo:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            middlePaneHeaderDuplicateBadgeThree:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
            middlePaneHeaderDuplicateBadgeFour:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour,
            typeOfMiddlePaneList: data.payload.typeOfMiddlePaneList,
            middlePaneSelectedValue: assessmentInfo.id,
            scanCount: reviseResponseObj && reviseResponseObj.countTotal,
            showMiddlePaneState: true
          }
        });
        yield put({
          type: RELATED_REVIEWLIST_DISTINCT_DATA,
          payload: reviseResponseObj.responseObject
        });
        yield put({ type: LOADER_STOP });
      } else {
        yield put({ type: LOADER_STOP });
      }
    } else {
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: apiResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerCreateAssessmentSaga(data) {
  try {
    const apiResponse = yield call(createAssessmentApi, {
      data: data.payload,
      URL: ASSESSMENT_MODULE_URL
    });
    if (apiResponse.responseCode === '000') {
      console.log('loading end', data.payload.whichTypeCreate);
      yield put({
        type: SET_DISPLAY_PANE_THREE_STATE,
        payload: {
          headerOne: 'assessment',
          headerOneBadgeOne: 'distinct',
          headerOneBadgeTwo: 'information',
          headerOneBadgeThree: Store.getState().DisplayPaneTwoReducer.selectedInformationAllorKey,
          responseObject: apiResponse.responseObject[0],
          reviewMode: 'revise',
          createMode: 'assessment'
        }
      });
      yield put({
        type: SET_ASSESSMENT_BASIC_REDUCER_STATE,
        payload: apiResponse.responseObject[0].informationBasic
      });
      yield put({
        type: UPDATE_ASSESSMENT_SETUP_PRIMARY_INFO,
        payload: apiResponse.responseObject[0].informationSetup
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'middlePaneSelectedValue', value: apiResponse.responseObject[0].id }
      });
      yield put({
        type: SET_POPUP_SINGLE_STATE,
        payload: {
          stateName: 'selectedTagStatus',
          value: apiResponse.responseObject[0].informationEngagement?.assessmentStatus
        }
      });
      yield put({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'selectedTagValue', value: apiResponse.responseObject[0].id }
      });
      yield put({ type: LOADER_STOP });
      yield put({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
      yield put({ type: POPUP_CLOSE });
    } else if( apiResponse.responseCode ==='106'){
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'NAMEPOPUP',
          popupMode: `ASSESSMENTCREATE`,
          // popupMode: `JOBCREATE`,
          popupErrorMsg: INFORMATION_EXIST_ERROR_MESSAGE
        }
      });
      yield put({ type: LOADER_STOP });
    } else {
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: apiResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}

export default function* watchCreateAssessmentSaga() {
  yield takeLatest(CREATE_ASSESSMENT_SAGA, workerCreateAssessmentSaga);
  yield takeLatest(CREATE_ASSESSMENT_SECTION_SAGA, workerCreateAssessmentSectionSaga);
}
