import React, { Fragment, useEffect, useRef, useState } from 'react';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import ArrowRight from '@material-ui/icons/ChevronRight';
import ArrowLeft from '@material-ui/icons/ChevronLeft';
import HeaderCard from '../../Molecules/Header/HeaderCard';
import CodeIcon from '@material-ui/icons/Code';
import './DisplayPaneSeven.css';
import '../DisplayPaneFive/DisplayPaneFive.css';
import '../../Molecules/ReviewList/ReviewList.css';
import { useDispatch, useSelector } from 'react-redux';
import FooterIconTwo from '../../Molecules/FooterIcon/FooterIconTwo';
import PopUpAssessmentNavigator from '../../PopUpInformation/PopUpAssessmentNavigator';
import {
  POPUP_OPEN,
  SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
  SET_ASSESSEE_ASSESSMENT_SECTION_END_SAGA,
  SET_MOBILE_PANE_STATE,
  SET_POPUP_STATE,
  POPUP_CLOSE,
  SET_POPUP_SINGLE_STATE,
  SET_POPUP_VALUE
} from '../../actionType';
import { ASSESSMENT_FINISH_POPUP_OPTION, DELETE_POPUP } from '../../PopUpConfig';
import {
  assesseeAssessmentFinihed,
  setAssesseeAssessmentItemSaveResCall
} from '../../Actions/ActionAssesseeAssessment';
import DisplayPaneFiveAssessment from '../DisplayPaneFive/DisplayPaneFiveAssessment';
import { callApiFunctionLastAttempted } from '../../Actions/GenericActions';
import { SELECT_ANOTHER_RESPONSE, SELECT_RESPONSE_CHOICE } from '../../errorMessage';

export const DisplayPaneSeven = () => {
  const [isQuestionFlaged, setIsQuestionFlaged] = useState([]);
  const [currentQuestionIndex, setcurrentQuestionIndex] = useState(0);
  const [currentQuestionChoice, setcurrentQuestionChoice] = useState('0');
  const [assessmentItemSection, setAssessmentItemSection] = useState([]);
  const [itemTimeStart, setItemTimeStart] = useState(0);
  const [currentSectionIndex, setcurrentSectionIndex] = useState(0);
  const [itemObject, setItemObject] = useState(null);
  const [responseData, setResponceData] = useState(null);
  const [isShowReviewIcon, setisShowReviewIcon] = useState(true);
  const { isDisplayPaneSixShow } = useSelector((state) => state.AssessmentReducer);
  const { selectedAssociateInfo } = useSelector((state) => state.DisplayPaneTwoReducer);
  const dispatch = useDispatch();
  const { FilterMode } = useSelector((state) => state.FilterReducer);
  const { isPopUpValue } = useSelector((state) => state.PopUpReducer);
  const {
    assessmentsequenceObject,
    assesseeAssessmentStartData,
    currentSequenceIndex,
    assesseeAssignmentAssessmentData,
    questionIndex,
    assesseeAssignmentAssessmentSectionAttemptedDateTimeStart,
    isItemTrial,
    isAssessmentTimeRemain
  } = useSelector((state) => state.AssesseeAssignmentAssessmentReducer);
  const { selectedTagValue } = useSelector((state) => state.PopUpReducer);
  const instanceRef = useRef(null);
  useEffect(() => {
    console.log();
    setItemTimeStart(new Date().getTime());

    let sec = null;
    if (!isItemTrial && assesseeAssessmentStartData?.assessmentSectionTime > 0) {
      sec = assesseeAssessmentStartData?.assessmentSectionTime / 1000;
    } else {
      sec = assesseeAssignmentAssessmentData?.informationFramework?.assessmentTime / 1000;
    }
    if (!isItemTrial) {
      let tt = new Date();
      tt.setSeconds(tt.getSeconds() + sec);
      setTimer(tt);
    }

    console.log(isItemTrial, 'ITEMTRIAL');
    let selectedResponseChoice = isItemTrial
      ? ''
      : assesseeAssessmentStartData?.assessmentSectionItemDistinct[questionIndex]
          ?.assesseeAssignmentAssessmentItemResponseChoiceSelected;
    setcurrentQuestionIndex(questionIndex);
    console.log('THIS IS EXECUTING......', selectedResponseChoice);
    selectedResponseChoice
      ? setcurrentQuestionChoice(selectedResponseChoice)
      : setcurrentQuestionChoice('0');
  }, [assesseeAssessmentStartData]);

  const assessmentsequenceArr = (assessmentsequenceObject && assessmentsequenceObject) || [];
  const time = new Date();
  const [timer, setTimer] = useState(time);
  let navigatorIconObject = { ...assesseeAssessmentStartData?.assessmentSectionItemNavigation };

  const timerFinished = () => {
    dispatch({ type: POPUP_CLOSE });
    // response for this api call- if assessmet time-out assessment automaticly unfinished.. that why we call below api
    // but still popup open is open if user finish then only popup close ... no api call because of endtime wrong update
    if (
      selectedTagValue <
      assesseeAssignmentAssessmentData?.informationFramework?.assessmentSectionFramework.length - 1
    ) {
      let request = {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        assignmentId: assesseeAssignmentAssessmentData?.assignmentId,
        assessmentId: assesseeAssignmentAssessmentData?.assessmentId,
        assessmentSectionNumber: (selectedTagValue && selectedTagValue) || 0,
        assesseeAssignmentAssessmentSectionAttempted: {
          assesseeAssignmentAssessmentSectionAttemptedDateTimeEnd: new Date().getTime(),
          assesseeAssignmentAssessmentSectionAttemptedDateTimeStart:
            assesseeAssignmentAssessmentSectionAttemptedDateTimeStart
        }
      };
      dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneSix' });
      dispatch({
        type: SET_ASSESSEE_ASSESSMENT_SECTION_END_SAGA,
        payload: { request: request }
      });
      dispatch({
        type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
        payload: {
          stateName: 'isAssessmentTimeRemain',
          value: new Date().getTime()
        }
      });
      //new section start and qution index as 0
      dispatch({
        type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
        payload: {
          stateName: 'questionIndex',
          value: 0
        }
      });
      let tempArr = ASSESSMENT_FINISH_POPUP_OPTION;
      tempArr = [tempArr[0], { ...tempArr[1], disabled: true }];
      dispatch({
        type: SET_POPUP_STATE,
        payload: {
          popupHeaderOne: 'assessment',
          popupHeaderOneBadgeOne: 'section',
          popupHeaderOneBadgeTwo: 'time-out',
          isPopUpValue: '',
          popupOpenType: 'primary',
          popupContentArrValue: tempArr
        }
      });
      dispatch({ type: POPUP_OPEN, payload: 'paneSevenPopup' });
      //api call for last attemt
      callApiFunctionLastAttempted(
        selectedAssociateInfo,
        assesseeAssignmentAssessmentData,
        dispatch,
        assessmentsequenceObject[currentSequenceIndex + 1]?.originalValue
      );
      // dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneSix' });
    } else {

      let request = {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        assignmentId: assesseeAssignmentAssessmentData?.assignmentId,
        assessmentId: assesseeAssignmentAssessmentData?.assessmentId,
        assessmentSectionNumber: (selectedTagValue && selectedTagValue) || 0,
        assesseeAssignmentAssessmentSectionAttempted: {
          assesseeAssignmentAssessmentSectionAttemptedDateTimeEnd: new Date().getTime(),
          assesseeAssignmentAssessmentSectionAttemptedDateTimeStart:
            assesseeAssignmentAssessmentSectionAttemptedDateTimeStart
        }
      };

      dispatch({
        type: SET_ASSESSEE_ASSESSMENT_SECTION_END_SAGA,
        payload: { request: request }
      });

      assesseeAssessmentFinihed(
        dispatch,
        selectedAssociateInfo,
        assesseeAssignmentAssessmentData,
        'UNFINISHED',
        assesseeAssignmentAssessmentSectionAttemptedDateTimeStart
      );
      dispatch({
        type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
        payload: {
          stateName: 'questionIndex',
          value: currentQuestionIndex
        }
      });
      let tempArr = ASSESSMENT_FINISH_POPUP_OPTION;
      tempArr = [tempArr[0], { ...tempArr[1], disabled: true }];
      dispatch({
        type: SET_POPUP_STATE,
        payload: {
          popupHeaderOne: 'assessment',
          popupHeaderOneBadgeOne: 'time-out',
          popupHeaderOneBadgeTwo: '',
          isPopUpValue: '',
          popupOpenType: 'primary',
          popupContentArrValue: tempArr
        }
      });
      dispatch({ type: POPUP_OPEN, payload: 'paneSevenPopup' });
      dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneSix' });
    }
  };

  let assessmentItemTrial = (assesseeAssessmentStartData && assesseeAssessmentStartData) || [];
  let itemObect =
    (isItemTrial && assessmentItemTrial.length > 0 && assessmentItemTrial[currentQuestionIndex]) ||
    assesseeAssessmentStartData?.assessmentSectionItemDistinct[currentQuestionIndex];

  const onClickFooter = (e) => {
    let clickedval = e.currentTarget.getAttribute('data-value');
    console.log('clickedval', clickedval);
    dispatch({ type: POPUP_CLOSE });
    document.getElementById('containerItem').scrollTop = '0px'; //for scroll top
    if (isItemTrial) {
      if (clickedval === 'first') {
        if (currentQuestionIndex !== 0) {
          setcurrentQuestionIndex(0);
        }
      }
      if (clickedval === 'last') {
        if (currentQuestionIndex < assesseeAssessmentStartData?.length - 1) {
          let lastIndex = assesseeAssessmentStartData?.length - 1;
          setcurrentQuestionIndex(lastIndex);
        }
      }
      if (clickedval === 'next') {
        if (currentQuestionIndex < assesseeAssessmentStartData?.length - 1) {
          callApiFunctionLastAttempted(
            selectedAssociateInfo,
            assesseeAssignmentAssessmentData,
            dispatch,
            assessmentsequenceObject[currentSequenceIndex]?.originalValue.concat(
              ':',
              currentQuestionIndex + 1
            )
          );
          setcurrentQuestionIndex(currentQuestionIndex + 1);
        } else if (currentSequenceIndex < assessmentsequenceObject.length - 1) {
          callApiFunctionLastAttempted(
            selectedAssociateInfo,
            assesseeAssignmentAssessmentData,
            dispatch,
            assessmentsequenceObject[currentSequenceIndex + 1]?.originalValue.concat(':', -1)
          );
          dispatch({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: {
              stateName: 'currentSequenceIndex',
              value: currentSequenceIndex + 1
            }
          });
          dispatch({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: { stateName: 'assesseeAssessmentStartData', value: null }
          });
          dispatch({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: { stateName: 'isItemTrial', value: false }
          });
          dispatch({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: { stateName: 'isAssessmentStart', value: 'START' }
          });
          dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneSix' });
          setcurrentQuestionIndex(0);
          // let sequenceArr = (assessmentsequenceArr && assessmentsequenceArr) || [];
          // let currentSequenceVal = sequenceArr[currentSequenceIndex]?.originalValue;
          // let itemIndex = currentQuestionIndex;
          // let lastAttempted = currentSequenceVal.concat(':', itemIndex);
          // callApiFunctionLastAttempted(
          //   selectedAssociateInfo,
          //   assesseeAssignmentAssessmentData,
          //   dispatch,
          //   lastAttempted
          // );
        }
      }
      if (clickedval === 'previous') {
        let prevIndex = currentQuestionIndex - 1;
        if (currentQuestionIndex !== 0) {
          setcurrentQuestionIndex(prevIndex);
        }
      }
    } else {
      // debugger
      let questionIndex = 0;
      let item = assesseeAssessmentStartData?.assessmentSectionItemDistinct[currentQuestionIndex];
      let itemId = item?.itemId;
      let itemFlaged = item?.assesseeAssignmentAssessmentItemFlagged;
      let assesseeId = assesseeAssessmentStartData.assesseeId;
      let isValidate = false;
      if (clickedval === 'previous') {
        // debugger
        let prevIndex = currentQuestionIndex - 1;
        questionIndex = currentQuestionIndex - 1;
        setItemObject(null);
        let responseInLocal = JSON.parse(localStorage.getItem('navigationItem')) || [];
        if (currentQuestionIndex !== 0) {
          setcurrentQuestionIndex(prevIndex);
          let itemData = assesseeAssessmentStartData?.assessmentSectionItemDistinct[prevIndex];
          let item = responseInLocal.filter(function (ii) {
            return ii.assesseeAssignmentAssessmentItemId === itemData.itemId;
          });
          if (item.length > 0) {
            console.log(item[0].assesseeAssignmentAssessmentItemResponseChoiceSelected);
            setcurrentQuestionChoice(
              item[0].assesseeAssignmentAssessmentItemResponseChoiceSelected
            );
          } else {
            console.log(itemData?.assesseeAssignmentAssessmentItemResponseChoiceSelected);

            itemData?.assesseeAssignmentAssessmentItemResponseChoiceSelected
              ? setcurrentQuestionChoice(
                  itemData?.assesseeAssignmentAssessmentItemResponseChoiceSelected
                )
              : setcurrentQuestionChoice('0');
          }

          if (
            itemObect?.itemFrameworkOneType === '61090cace50cf61d5eb440c9' ||
            itemObect?.itemFrameworkOneType === '61161713f24e1fb765208e23'
          ) {
            itemObect.itemFrameworkOneSection.forEach((element) => {
              // if (element.itemFrameworkOneSectionSequence === parseInt(itemSectionSequence)) {
                element.itemFrameworkOne.itemFrameworkOneResponseCorrect = '0';
              // }
            });
          }

        } else {
          let prevIndex = selectedTagValue - 1;
          if (selectedTagValue !== 0 && selectedTagValue) {
            dispatch({
              type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
              payload: {
                stateName: 'assesseeAssessmentStartData',
                value:
                  assesseeAssignmentAssessmentData?.informationFramework
                    ?.assessmentSectionFramework[prevIndex]
              }
            });
          }
          // let prevSec = currentSectionIndex - 1;
          // if (currentSectionIndex != 0) {
          //   setcurrentSectionIndex(prevSec);
          //   setcurrentQuestionIndex(
          //     assesseeAssessmentStartData?.assessmentSection[prevSec]
          //       ?.assessmentSectionItemDistinct.length - 1
          //   );
          //   let itemData =
          //     assesseeAssessmentStartData?.assessmentSection[prevSec].assessmentSectionItemDistinct[
          //     assesseeAssessmentStartData?.assessmentSection[prevSec]
          //       ?.assessmentSectionItemDistinct.length - 1
          //     ];
          //   let item = responseInLocal.filter(function (ii) {
          //     return ii.assesseeAssignmentAssessmentItemId === itemData.itemId;
          //   });
          //   if (item.length > 0)
          //     setcurrentQuestionChoice(item[0].assesseeAssignmentAssessmentItemResponseChoiceSelected);
          // }
        }
      }
      if (clickedval === 'first') {
        questionIndex = 0;
        setItemObject(null);
        let responseInLocal = JSON.parse(localStorage.getItem('navigationItem')) || [];
        if (currentQuestionIndex !== 0) {
          setcurrentQuestionIndex(0);
          let itemData = assesseeAssessmentStartData.assessmentSectionItemDistinct[0];
          let item = responseInLocal.filter(function (ii) {
            return ii.assesseeAssignmentAssessmentItemId === itemData.itemId;
          });
          if (item.length > 0) {
            setcurrentQuestionChoice(
              item[0].assesseeAssignmentAssessmentItemResponseChoiceSelected
            );
          } else {
            setcurrentQuestionChoice(
              itemData?.assesseeAssignmentAssessmentItemResponseChoiceSelected
            );
          }
        } else {
          // if (currentSectionIndex !== 0) {
          //   setcurrentSectionIndex(currentSectionIndex - 1);
          //   setcurrentQuestionIndex(0);
          //   let itemData =
          //     assesseeAssessmentStartData.assessmentSection[currentSectionIndex - 1].assessmentSectionItemDistinct[0];
          //   let item = responseInLocal.filter(function (ii) {
          //     return ii.assesseeAssignmentAssessmentItemId === itemData.itemId;
          //   });
          //   if (item.length > 0)
          //     setcurrentQuestionChoice(item[0].assesseeAssignmentAssessmentItemResponseChoiceSelected);
          // }
        }
      }
      if (clickedval === 'last') {
        setItemObject(null);
        let responseInLocal = JSON.parse(localStorage.getItem('navigationItem')) || [];
        let lastIndex = assesseeAssessmentStartData.assessmentSectionItemDistinct.length - 1;
        questionIndex = assesseeAssessmentStartData.assessmentSectionItemDistinct.length - 1;
        if (lastIndex === currentQuestionIndex) {
          dispatch({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: {
              stateName: 'questionIndex',
              value: currentQuestionIndex
            }
          });
          dispatch({
            type: SET_POPUP_STATE,
            payload: {
              popupHeaderOne: 'assessment',
              popupHeaderOneBadgeOne: 'close',
              popupHeaderOneBadgeTwo: '',
              isPopUpValue: '',
              popupOpenType: 'primary',
              secondaryOptionCheckValue: 'assignment',
              popupContentArrValue: ASSESSMENT_FINISH_POPUP_OPTION,
              selectedTagValue: selectedTagValue,
              selectedTagStatus: 'status'
            }
          });
          dispatch({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: {
              stateName: 'assesseeAssessmentStartData',
              value: null
            }
          });
          dispatch({
            type: SET_MOBILE_PANE_STATE,
            payload: 'displayPaneSix'
          });
          dispatch({ type: POPUP_OPEN, payload: 'paneSevenPopup' });
          setcurrentQuestionIndex(currentQuestionIndex);
        }
        if (
          currentQuestionIndex <
            assesseeAssessmentStartData.assessmentSectionItemDistinct.length - 1 ||
          currentQuestionChoice !== ''
        ) {
          if (
            item?.itemFrameworkOneType === '61090cace50cf61d5eb440c9' ||
            item?.itemFrameworkOneType === '61161713f24e1fb765208e23'
          ) {
            let assessmentItemSectionArr = [...assessmentItemSection];
            if (assessmentItemSectionArr.length > 0) {
              if (assessmentItemSectionArr.length === item.itemFrameworkOneSection.length) {
                let assessmentItemSectionChoiceSelectedArr = assessmentItemSectionArr.map(
                  (x) =>
                    x.assesseeAssignmentAssessmentItem
                      .assesseeAssignmentAssessmentItemResponseChoiceSelected
                );
                if (assesseeAssessmentStartData?.assessmentSectionItemFrameworkOneResponseUnique) {
                  let isAssessmentItemSectionChoiceMultiplSelected =
                    assessmentItemSectionChoiceSelectedArr.some(function (data, idx) {
                      return assessmentItemSectionChoiceSelectedArr.indexOf(data) !== idx;
                    });
                  // console.log('testtttt', isAssessmentItemSectionChoiceMultiplSelected);
                  if (isAssessmentItemSectionChoiceMultiplSelected) {
                     // alert('select a different response-choice');
                     dispatch({
                      type: SET_POPUP_VALUE,
                      payload: {
                        isPopUpValue: SELECT_ANOTHER_RESPONSE,
                        popupMode: 'responseErrorMsg'
                      }
                    });
                    isValidate = false;
                  } else {
                    isValidate = true;
                    setcurrentQuestionIndex(lastIndex);
                  }
                } else {
                  isValidate = true;
                  setcurrentQuestionIndex(lastIndex);
                }
              } else {
                // alert('select a response-choice');
                dispatch({
                  type: SET_POPUP_VALUE,
                  payload: {
                    isPopUpValue: SELECT_RESPONSE_CHOICE,
                    popupMode: 'responseErrorMsg'
                  }
                });
                return;
              }
            }
          } else {
            let itemData = assesseeAssessmentStartData.assessmentSectionItemDistinct[lastIndex];
            item = responseInLocal.filter(function (ii) {
              return ii.assesseeAssignmentAssessmentItemId === itemData.itemId;
            });
            if (item.length > 0) {
              setAssessmentItemSection(item[0].assesseeAssignmentAssessmentItemSection);
              setcurrentQuestionChoice(
                item[0]?.assesseeAssignmentAssessmentItemResponseChoiceSelected
              );
            } else {
              if (itemData.assesseeAssignmentAssessmentItemSection) {
                setAssessmentItemSection(itemData.assesseeAssignmentAssessmentItemSection);
              }
              setcurrentQuestionChoice(
                itemData?.assesseeAssignmentAssessmentItemResponseChoiceSelected
              );
            }
            setcurrentQuestionIndex(lastIndex);
            //for clarification for this if click on last response gone or not
            //if response choice selected and click on last button then response send to backend
            // setAssesseeAssessmentItemSaveResCall(
            //   selectedAssociateInfo,
            //   dispatch,
            //   assesseeAssignmentAssessmentData,
            //   itemId,
            //   assesseeId,
            //   currentQuestionChoice,
            //   itemTimeStart,
            //   itemFlaged,
            //   assessmentItemSection
            // );
            //end comment
          }
          //remove last selected option
          // assesseeAssessmentStartData.assessmentSectionItemDistinct[
          //   currentQuestionIndex-1
          // ].assesseeAssignmentAssessmentItemResponseChoiceSelected = 0;
          // debugger
          setItemObject(null);
          setcurrentQuestionIndex(lastIndex);
        }
        // } else {
        //   if (currentSectionIndex < assesseeAssessmentStartData.assessmentSection.length - 1) {
        //     setcurrentSectionIndex(currentSectionIndex + 1);
        //     let lastIndex =
        //       assesseeAssessmentStartData.assessmentSection[currentSectionIndex + 1].assessmentSectionItemDistinct.length - 1;
        //     setcurrentQuestionIndex(lastIndex);
        //     //let responseInLocal = JSON.parse(localStorage.getItem('navigationItem')) || [];
        //     let itemData =
        //       assesseeAssessmentStartData.assessmentSection[currentSectionIndex + 1].assessmentSectionItemDistinct[lastIndex];
        //     let item = responseInLocal.filter(function (ii) {
        //       return ii.assesseeAssignmentAssessmentItemId === itemData.itemId;
        //     });
        //     if (item.length > 0)
        //       setcurrentQuestionChoice(
        //         item[lastIndex].assesseeAssignmentAssessmentItemResponseChoiceSelected
        //       );
        //   }
        // }
      }
      if (clickedval === 'next') {
        questionIndex = currentQuestionIndex + 1;
        let responseInLocal = JSON.parse(localStorage.getItem('navigationItem')) || [];
        let itemData =
          assesseeAssessmentStartData.assessmentSectionItemDistinct[currentQuestionIndex + 1];

        let itemArr = responseInLocal.filter(function (ii) {
          return ii.assesseeAssignmentAssessmentItemId === itemData?.itemId;
        });
        let assessmentItemSectionArr = [...assessmentItemSection];
        if (itemArr?.length > 0) {
          item = itemArr[0];
          assessmentItemSectionArr = itemArr[0]?.assesseeAssignmentAssessmentItemSection;
        } else {
          item = itemData;
        }
        itemData = { ...itemData, ...item };
        //update object
        // assesseeAssessmentStartData.assessmentSectionItemDistinct[currentQuestionIndex].assesseeAssignmentAssessmentItemResponseChoiceSelected=currentQuestionChoice;
        if (
          currentQuestionIndex <
          assesseeAssessmentStartData?.assessmentSectionItemDistinct.length - 1
        ) {
          //61090cace50cf61d5eb440c9 "Rating-Scale (Multiple-Select)"
          //61161713f24e1fb765208e23 "Template"
          if (
            itemData?.itemFrameworkOneType === '61090cace50cf61d5eb440c9' ||
            itemData?.itemFrameworkOneType === '61161713f24e1fb765208e23'
          ) {
            console.log('assessmentItemSectionArr', assessmentItemSectionArr);
            if (
              assessmentItemSectionArr.length > 0 ||
              !navigatorIconObject?.assessmentSectionItemNavigationSkip
            ) {
              if (assessmentItemSectionArr.length === itemData.itemFrameworkOneSection.length) {
                let assessmentItemSectionChoiceSelectedArr = assessmentItemSectionArr.map(
                  (x) =>
                    x.assesseeAssignmentAssessmentItem
                      .assesseeAssignmentAssessmentItemResponseChoiceSelected
                );
                if (assesseeAssessmentStartData?.assessmentSectionItemFrameworkOneResponseUnique) {
                  let isAssessmentItemSectionChoiceMultiplSelected =
                    assessmentItemSectionChoiceSelectedArr.some(function (data, idx) {
                      return assessmentItemSectionChoiceSelectedArr.indexOf(data) !== idx;
                    });
                  if (isAssessmentItemSectionChoiceMultiplSelected) {
                    // alert('select a different response-choice');
                    dispatch({
                      type: SET_POPUP_VALUE,
                      payload: {
                        isPopUpValue: SELECT_ANOTHER_RESPONSE,
                        popupMode: 'responseErrorMsg'
                      }
                    });
                    isValidate = false;
                  } else {
                    isValidate = true;
                  }
                  console.log("item",item);
                  console.log("itemData",itemData);
                  console.log("itemObject",itemObject);
                  console.log("assesseeAssessmentStartData",assesseeAssessmentStartData);
                  if(itemObject!=null)
                    assesseeAssessmentStartData.assessmentSectionItemDistinct[currentQuestionIndex]=itemObject;
                  console.log("assesseeAssessmentStartData",assesseeAssessmentStartData);

                } else {
                  if(itemObject!=null)
                    assesseeAssessmentStartData.assessmentSectionItemDistinct[currentQuestionIndex]=itemObject;
                  // debugger
                  isValidate = true;
                }
              } else {
                isValidate = false;
                // alert('select a response-choice');
                dispatch({
                  type: SET_POPUP_VALUE,
                  payload: {
                    isPopUpValue: SELECT_RESPONSE_CHOICE,
                    popupMode: 'responseErrorMsg'
                  }
                });
              }
            } else if (navigatorIconObject?.assessmentSectionItemNavigationSkip) {

              isValidate = true;
            } else {
              isValidate = false;
              // alert('select a response-choice');
              dispatch({
                type: SET_POPUP_VALUE,
                payload: {
                  isPopUpValue: SELECT_RESPONSE_CHOICE,
                  popupMode: 'responseErrorMsg'
                }
              });
            }
          } else {
            if (
              navigatorIconObject?.assessmentSectionItemNavigationSkip ||
              currentQuestionChoice !== '0'
            ) {
              isValidate = true;
              if (item.length > 0) {
                // debugger
                setAssessmentItemSection(item[0].assesseeAssignmentAssessmentItemSection);
                item[0]?.assesseeAssignmentAssessmentItemResponseChoiceSelected &&
                  setcurrentQuestionChoice(
                    item[0]?.assesseeAssignmentAssessmentItemResponseChoiceSelected
                  );
              } else {
                // debugger
                if (itemData.assesseeAssignmentAssessmentItemSection) {
                  // debugger
                  setAssessmentItemSection(itemData.assesseeAssignmentAssessmentItemSection);
                }
                if (itemData?.assesseeAssignmentAssessmentItemResponseChoiceSelected)
                  setcurrentQuestionChoice(
                    itemData?.assesseeAssignmentAssessmentItemResponseChoiceSelected
                  );
                else {
                  setcurrentQuestionChoice('0');
                }
              }
              setcurrentQuestionIndex(questionIndex);
              // setcurrentQuestionIndex(currentQuestionIndex + 1);
            } else {
              // setcurrentQuestionIndex(currentQuestionIndex);
              // alert('select a response-choice');
              dispatch({
                type: SET_POPUP_VALUE,
                payload: {
                  isPopUpValue: SELECT_RESPONSE_CHOICE,
                  popupMode: 'responseErrorMsg'
                }
              });
            }
          }
        } else {
          console.log(selectedTagValue, assesseeAssignmentAssessmentData?.informationFramework?.assessmentSectionFramework
            .length)
          if (
            selectedTagValue <
            assesseeAssignmentAssessmentData?.informationFramework?.assessmentSectionFramework
              .length - 1 && currentSequenceIndex < assessmentsequenceObject?.length -1
          ) {
            console.log('new section start');
            dispatch({
              type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
              payload: {
                stateName: 'questionIndex',
                value: currentQuestionIndex
              }
            });
            let tempArr2 = ASSESSMENT_FINISH_POPUP_OPTION;
            dispatch({
              type: SET_POPUP_STATE,
              payload: {
                popupHeaderOne: 'assessment',
                popupHeaderOneBadgeOne: 'section',
                popupHeaderOneBadgeTwo: 'end',
                isPopUpValue: '',
                popupOpenType: 'primary',
                popupContentArrValue: tempArr2,
                // selectedTagStatus: selectedTagStatus,
                selectedTagValue: selectedTagValue
              }
            });
            dispatch({ type: POPUP_OPEN, payload: 'paneSevenPopup' });
            dispatch({
              type: SET_POPUP_SINGLE_STATE,
              payload: { stateName: 'headerButtons', value: false }
            });
          } else if (currentSequenceIndex < assessmentsequenceObject.length - 1) {
            let splitedSequence = assessmentsequenceObject.slice(currentSequenceIndex + 1);
            let splitedSequenceIndex = splitedSequence.findIndex((o) => o.name === 'item distinct');
            console.log('assessmentsequenceObject', assessmentsequenceObject);
            console.log('currentSequenceIndex', currentSequenceIndex);
            console.log('splitedSequenceIndex', splitedSequenceIndex);
            if (splitedSequenceIndex !== -1) {
              dispatch({
                type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
                payload: {
                  stateName: 'questionIndex',
                  value: currentQuestionIndex
                }
              });
              let tempArr2 = ASSESSMENT_FINISH_POPUP_OPTION;
              dispatch({
                type: SET_POPUP_STATE,
                payload: {
                  popupHeaderOne: 'assessment',
                  popupHeaderOneBadgeOne: 'section',
                  popupHeaderOneBadgeTwo: 'end',
                  isPopUpValue: '',
                  popupOpenType: 'primary',
                  popupContentArrValue: tempArr2,
                  // selectedTagStatus: selectedTagStatus,
                  selectedTagValue: selectedTagValue
                }
              });
              setcurrentQuestionIndex(0);
              dispatch({ type: POPUP_OPEN, payload: 'paneSevenPopup' });
              dispatch({
                type: SET_POPUP_SINGLE_STATE,
                payload: { stateName: 'headerButtons', value: false }
              });
              // dispatch({
              //   type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
              //   payload: {
              //     stateName: 'currentSequenceIndex',
              //     value: currentSequenceIndex + 1
              //   }
              // });
              // dispatch({
              //   type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
              //   payload: {
              //     stateName: 'assesseeAssessmentStartData',
              //     value: null
              //   }
              // });
              // dispatch({
              //   type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
              //   payload: { stateName: 'isAssessmentStart', value: 'STOP' }
              // });
            }
            // if (nextsequenceValue === 'item distinct') {
            //   dispatch({
            //     type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            //     payload: { stateName: 'currentSequenceIndex', value: currentSequenceIndex + 1 }
            //   });
            //   dispatch({
            //     type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            //     payload: { stateName: 'assesseeAssessmentStartData', value: null }
            //   });
            //   dispatch({
            //     type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            //     payload: { stateName: 'isAssessmentStart', value: 'STOP' }
            //   });
            // }
            else if (
              navigatorIconObject?.assessmentSectionItemNavigationSkip ||
              currentQuestionChoice
            ) {

              console.log('assessmentItemSectionArr', assessmentItemSectionArr);
              let lastItem = {...itemData}
              if(currentQuestionIndex > assesseeAssessmentStartData?.assessmentSectionItemDistinct.length - 1 ){
                lastItem = assesseeAssessmentStartData?.assessmentSectionItemDistinct[assesseeAssessmentStartData?.assessmentSectionItemDistinct.length -1 ]
              }


              if (
                lastItem?.itemFrameworkOneType === '61090cace50cf61d5eb440c9' &&
                lastItem?.itemFrameworkOneType === '61161713f24e1fb765208e23'
              ) {
                if (
                  assessmentItemSectionArr.length > 0 ||
                  !navigatorIconObject?.assessmentSectionItemNavigationSkip
                ) {
                  if (assessmentItemSectionArr?.length === itemObject?.itemFrameworkOneSection?.length) {
                    let assessmentItemSectionChoiceSelectedArr = assessmentItemSectionArr.map(
                      (x) =>
                        x.assesseeAssignmentAssessmentItem
                          .assesseeAssignmentAssessmentItemResponseChoiceSelected
                    );
                    if (assesseeAssessmentStartData?.assessmentSectionItemFrameworkOneResponseUnique) {
                      let isAssessmentItemSectionChoiceMultiplSelected =
                        assessmentItemSectionChoiceSelectedArr.some(function (data, idx) {
                          return assessmentItemSectionChoiceSelectedArr.indexOf(data) !== idx;
                        });
                      if (isAssessmentItemSectionChoiceMultiplSelected) {
                        // alert('select a different response-choice');
                        dispatch({
                          type: SET_POPUP_VALUE,
                          payload: {
                            isPopUpValue: SELECT_ANOTHER_RESPONSE,
                            popupMode: 'responseErrorMsg'
                          }
                        });
                        isValidate = false;
                        return
                      } else {
                        isValidate = true;
                      }
                      console.log("item",item);
                      console.log("itemData",itemData);
                      console.log("itemObject",itemObject);
                      console.log("assesseeAssessmentStartData",assesseeAssessmentStartData);
                      if(itemObject!=null)
                        assesseeAssessmentStartData.assessmentSectionItemDistinct[currentQuestionIndex]=itemObject;
                      console.log("assesseeAssessmentStartData",assesseeAssessmentStartData);
    
                    } else {
                      if(itemObject!=null)
                        assesseeAssessmentStartData.assessmentSectionItemDistinct[currentQuestionIndex]=itemObject;
                      // debugger
                      isValidate = true;
                    }
                  } else {
                    isValidate = false;
                    // alert('select a response-choice');
                    dispatch({
                      type: SET_POPUP_VALUE,
                      payload: {
                        isPopUpValue: SELECT_RESPONSE_CHOICE,
                        popupMode: 'responseErrorMsg'
                      }
                    });
                    return
                  }
                  
                }  
  
                if (
                  currentQuestionIndex < assesseeAssessmentStartData?.assessmentSectionItemDistinct.length && (!navigatorIconObject?.assessmentSectionItemNavigationSkip && currentQuestionChoice === '0')
                ) {
                  // if (
                  //   navigatorIconObject?.assessmentSectionItemNavigationSkip ||
                  //   currentQuestionChoice
                  // ) {
                  // alert('select a response-choice');
                  dispatch({
                    type: SET_POPUP_VALUE,
                    payload: {
                      isPopUpValue: SELECT_RESPONSE_CHOICE,
                      popupMode: 'responseErrorMsg'
                    }
                  });
                  return;
                  // }
                }
              } else {
                if (
                  !navigatorIconObject?.assessmentSectionItemNavigationSkip &&
                  currentQuestionChoice === '0'
                ) {
                  isValidate = false;
                  // setcurrentQuestionIndex(currentQuestionIndex);
                  // alert('select a response-choice');
                  dispatch({
                    type: SET_POPUP_VALUE,
                    payload: {
                      isPopUpValue: SELECT_RESPONSE_CHOICE,
                      popupMode: 'responseErrorMsg'
                    }
                  });
                  return
                }
              }
              

              dispatch({
                type: SET_POPUP_STATE, ///////
                payload: {
                  popupHeaderOne: 'assessment',
                  popupHeaderOneBadgeOne:
                    assesseeAssignmentAssessmentData?.assessmentSectionFramework?.length > 1
                      ? 'section'
                      : 'end',
                  popupHeaderOneBadgeTwo:
                    assesseeAssignmentAssessmentData?.assessmentSectionFramework?.length > 1
                      ? 'end'
                      : '',
                  isPopUpValue: '',
                  popupOpenType: 'primary',
                  secondaryOptionCheckValue: 'assignment',
                  popupContentArrValue: ASSESSMENT_FINISH_POPUP_OPTION,
                  selectedTagValue: selectedTagValue,
                  selectedTagStatus: 'status'
                }
              });
              dispatch({
                type: SET_MOBILE_PANE_STATE,
                payload: 'displayPaneSix'
              });
              dispatch({ type: POPUP_OPEN, payload: 'paneSevenPopup' });
              dispatch({
                type: SET_POPUP_SINGLE_STATE,
                payload: { stateName: 'headerButtons', value: false }
              });
              setcurrentQuestionIndex(currentQuestionIndex);
              dispatch({
                type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
                payload: {
                  stateName: 'questionIndex',
                  value: currentQuestionIndex
                }
              });
            }
          } else {

            if (
              currentQuestionIndex < assesseeAssessmentStartData?.assessmentSectionItemDistinct.length && (!navigatorIconObject?.assessmentSectionItemNavigationSkip && currentQuestionChoice === '0')
            ) {
              // if (
              //   navigatorIconObject?.assessmentSectionItemNavigationSkip ||
              //   currentQuestionChoice
              // ) {
              // alert('select a response-choice');
              dispatch({
                type: SET_POPUP_VALUE,
                payload: {
                  isPopUpValue: SELECT_RESPONSE_CHOICE,
                  popupMode: 'responseErrorMsg'
                }
              });
              return;
              // }
            }

            if (navigatorIconObject?.assessmentSectionItemNavigationSkip || currentQuestionChoice) {
              dispatch({
                type: SET_POPUP_STATE,
                payload: {
                  popupHeaderOne: 'assessment',
                  popupHeaderOneBadgeOne: 'end',
                  popupHeaderOneBadgeTwo: '',
                  isPopUpValue: '',
                  popupOpenType: 'primary',
                  secondaryOptionCheckValue: 'assignment',
                  popupContentArrValue: ASSESSMENT_FINISH_POPUP_OPTION,
                  selectedTagValue: selectedTagValue,
                  selectedTagStatus: 'status'
                }
              });
              dispatch({
                type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
                payload: {
                  stateName: 'questionIndex',
                  value: currentQuestionIndex
                }
              });
              dispatch({ type: POPUP_OPEN, payload: 'paneSevenPopup' });
              dispatch({
                type: SET_POPUP_SINGLE_STATE,
                payload: { stateName: 'headerButtons', value: false }
              });
            }
          }
          if (navigatorIconObject?.assessmentSectionItemNavigationSkip || currentQuestionChoice) {
            //last response sent to
            setAssesseeAssessmentItemSaveResCall(
              //
              selectedAssociateInfo,
              dispatch,
              assesseeAssignmentAssessmentData,
              itemId,
              assesseeId,
              currentQuestionChoice,
              itemTimeStart,
              itemFlaged,
              assessmentItemSection
            );
            let sequenceArr = (assessmentsequenceArr && assessmentsequenceArr) || [];
            let currentSequenceVal = sequenceArr[currentSequenceIndex]?.originalValue;
            let lastAttempted = currentSequenceVal.concat(':', currentQuestionIndex - 1);
            currentQuestionIndex < assesseeAssessmentStartData?.assessmentSectionItemDistinct.length -1 && setItemObject(null);
            callApiFunctionLastAttempted(
              selectedAssociateInfo,
              assesseeAssignmentAssessmentData,
              dispatch,
              lastAttempted
            );
          } else if (
            currentQuestionIndex < assesseeAssessmentStartData?.assessmentSectionItemDistinct.length
          ) {
            // if (
            //   navigatorIconObject?.assessmentSectionItemNavigationSkip ||
            //   currentQuestionChoice
            // ) {
            // alert('select a response-choice');
            dispatch({
              type: SET_POPUP_VALUE,
              payload: {
                isPopUpValue: SELECT_RESPONSE_CHOICE,
                popupMode: 'responseErrorMsg'
              }
            });
            return;
            // }
          }
          let request = {
            assesseeId: selectedAssociateInfo?.assesseeId,
            associateId:
              selectedAssociateInfo?.associate?.informationEngagement.associateTag
                .associateTagPrimary,
            assignmentId: assesseeAssignmentAssessmentData?.assignmentId,
            assessmentId: assesseeAssignmentAssessmentData?.assessmentId,
            assessmentSectionNumber: (selectedTagValue && selectedTagValue) || 0,
            assesseeAssignmentAssessmentSectionAttempted: {
              assesseeAssignmentAssessmentSectionAttemptedDateTimeEnd: new Date().getTime(),
              assesseeAssignmentAssessmentSectionAttemptedDateTimeStart:
                assesseeAssignmentAssessmentSectionAttemptedDateTimeStart
            }
          };
          dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneSix' });
          dispatch({
            type: SET_ASSESSEE_ASSESSMENT_SECTION_END_SAGA,
            payload: { request: request }
          });
          dispatch({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: {
              stateName: 'isAssessmentTimeRemain',
              value: new Date().getTime()
            }
          });
          //new section start and qution index as 0
          dispatch({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: {
              stateName: 'questionIndex',
              value: currentQuestionIndex //prev value is 0 but for dashboad communique
            }
          });
          // console.log('2');debugger
        }
        if (
          itemData?.itemFrameworkOneType !== '61090cace50cf61d5eb440c9' &&
          itemData?.itemFrameworkOneType !== '61161713f24e1fb765208e23'
        ) {
          // save selected option in object
          assesseeAssessmentStartData.assessmentSectionItemDistinct[
            currentQuestionIndex
          ].assesseeAssignmentAssessmentItemResponseChoiceSelected = currentQuestionChoice;
        }
      }
      // if (assesseeAssessmentStartData?.assessmentSectionEvaluation) {
      let responseInLocal = JSON.parse(localStorage.getItem('navigationItem')) || [];
      let itemData =
        assesseeAssessmentStartData.assessmentSectionItemDistinct[currentQuestionIndex];
      let localitem = responseInLocal.filter(function (ii) {
        return ii.assesseeAssignmentAssessmentItemId === itemData?.itemId;
      });
      if (isValidate) {
        let itemFrameworkOneSection = item?.itemFrameworkOneSection || [];
        console.log(itemFrameworkOneSection);
        console.log(responseData, itemFlaged, currentQuestionChoice);
        if (itemFrameworkOneSection.length > 0) {
          console.log(assessmentItemSection, item.itemFrameworkOneSection);
          // if (assessmentItemSection.length === item.itemFrameworkOneSection.length) {
            setAssesseeAssessmentItemSaveResCall(
              selectedAssociateInfo,
              dispatch,
              assesseeAssignmentAssessmentData,
              itemId,
              assesseeId,
              currentQuestionChoice,
              itemTimeStart,
              itemFlaged,
              assessmentItemSection
            );
            let sequenceArr = (assessmentsequenceArr && assessmentsequenceArr) || [];
            let currentSequenceVal = sequenceArr[currentSequenceIndex]?.originalValue;
            let itemIndex = currentQuestionIndex;
            let lastAttempted = currentSequenceVal.concat(':', itemIndex);
            currentQuestionIndex < assesseeAssessmentStartData?.assessmentSectionItemDistinct.length -1 && setItemObject(null);
            callApiFunctionLastAttempted(
              selectedAssociateInfo,
              assesseeAssignmentAssessmentData,
              dispatch,
              lastAttempted
            );
            setcurrentQuestionChoice('0');
            if (item.assesseeAssignmentAssessmentItemSection) {
              let assesseeAssignmentAssessmentItemSection =
                item?.assesseeAssignmentAssessmentItemSection || [];
              setAssessmentItemSection(assesseeAssignmentAssessmentItemSection);
            } else {
              setAssessmentItemSection([]);
            }
            setItemTimeStart(new Date().getTime());
          // }
        } else if (currentQuestionChoice || itemFlaged || responseData) {
          setAssesseeAssessmentItemSaveResCall(
            selectedAssociateInfo,
            dispatch,
            assesseeAssignmentAssessmentData,
            itemId,
            assesseeId,
            currentQuestionChoice,
            itemTimeStart,
            itemFlaged,
            assessmentItemSection,
            responseData
          );
          // console.log('4');debugger
          let sequenceArr = (assessmentsequenceArr && assessmentsequenceArr) || [];
          let currentSequenceVal = sequenceArr[currentSequenceIndex]?.originalValue;
          let itemIndex = currentQuestionIndex;
          let lastAttempted = currentSequenceVal.concat(':', itemIndex);
          currentQuestionIndex < assesseeAssessmentStartData?.assessmentSectionItemDistinct.length -1 && setItemObject(null);
          callApiFunctionLastAttempted(
            //next when reset 000
            selectedAssociateInfo,
            assesseeAssignmentAssessmentData,
            dispatch,
            lastAttempted
          );
          setcurrentQuestionChoice('0');
          if (
            !item?.assesseeAssignmentAssessmentItemResponseChoiceSelected &&
            localitem.length === 0
          ) {
            setcurrentQuestionChoice('0');
          }
          setItemTimeStart(new Date().getTime());
          setResponceData(null);
        }
        setcurrentQuestionIndex(questionIndex);
      }
      // }
    }
    setisShowReviewIcon(true);
  };

  const onClickReview = () => {
    setisShowReviewIcon(false);
  };
  // let primaryIcon = [{ label: 'navigator', onClick: onClickReview, Icon: CodeIcon }];
  // let secondaryIcon = [
  //   { label: 'first', onClick: onClickFooter, Icon: FirstPage ,disabled:isItemTrial? false : !assesseeAssessmentStartData?.assessmentSectionItemNavigation?.assessmentSectionItemNavigationFirst},
  //   { label: 'previous', onClick: onClickFooter, Icon: ArrowLeft ,disabled: isItemTrial? false :!assesseeAssessmentStartData?.assessmentSectionItemNavigation?.assessmentSectionItemNavigationPrevious},
  //   { label: 'next', onClick: onClickFooter, Icon: ArrowRight ,disabled: isItemTrial? false : !assesseeAssessmentStartData?.assessmentSectionItemNavigation?.assessmentSectionItemNavigationNext},
  //   { label: 'last', onClick: onClickFooter, Icon: LastPage ,disabled:isItemTrial? false : !assesseeAssessmentStartData?.assessmentSectionItemNavigation?.assessmentSectionItemNavigationLast}
  // ];
  // let assessmentItemTrial = (assesseeAssessmentStartData && assesseeAssessmentStartData) || [];
  // let itemObect =
  //   (isItemTrial && assessmentItemTrial.length > 0 && assessmentItemTrial[currentQuestionIndex]) ||
  //   assesseeAssessmentStartData?.assessmentSectionItemDistinct[currentQuestionIndex];
  if (currentQuestionIndex === 0 && !isItemTrial) {
    navigatorIconObject.assessmentSectionItemNavigationFirst = false;
    navigatorIconObject.assessmentSectionItemNavigationPrevious = false;
  }
  if (currentQuestionIndex !== 0 && !isItemTrial) {
    navigatorIconObject.assessmentSectionItemNavigationFirst =
      assesseeAssessmentStartData?.assessmentSectionItemNavigation.assessmentSectionItemNavigationFirst;
    navigatorIconObject.assessmentSectionItemNavigationPrevious =
      assesseeAssessmentStartData?.assessmentSectionItemNavigation.assessmentSectionItemNavigationPrevious;
  }
  if (
    parseInt(currentQuestionIndex) ===
      parseInt(assesseeAssessmentStartData?.assessmentSectionItemTotal - 1) &&
    !isItemTrial
  ) {
    navigatorIconObject.assessmentSectionItemNavigationLast = false;
  }
  const primaryIcon = [{ label: 'navigator', onClick: onClickFooter, Icon: CodeIcon }];
  const secondaryIcon = [
    {
      label: 'first',
      onClick: onClickFooter,
      disabled: isItemTrial ? false : !navigatorIconObject?.assessmentSectionItemNavigationFirst,
      Icon: FirstPage
    },
    {
      label: 'previous',
      onClick: onClickFooter,
      disabled: isItemTrial ? false : !navigatorIconObject?.assessmentSectionItemNavigationPrevious,
      Icon: ArrowLeft
    },
    {
      label: 'next',
      onClick: onClickFooter,
      disabled: isItemTrial ? false : !navigatorIconObject?.assessmentSectionItemNavigationNext,
      Icon: ArrowRight
    },
    {
      label: 'last',
      onClick: onClickFooter,
      disabled: isItemTrial ? false : !navigatorIconObject?.assessmentSectionItemNavigationLast,
      Icon: LastPage
    }
  ];
  const handleRadioButton = (e, value, name) => {
    let optionValue = e.target.value || value;
    let optionName = e.target.name || name;
    console.log('optionValue', optionValue);
    console.log('optionName', optionName);
    let currentQuestionNumber = JSON.parse(JSON.stringify(assesseeAssessmentStartData?.assessmentSectionItemDistinct[currentQuestionIndex]));
    let itemObjectCopy = itemObject ===null ? JSON.parse(JSON.stringify(itemObect)):itemObject;
    console.log('currentQuestionNumbercurrentQuestionNumber',currentQuestionNumber);
    // let currentQuestionNumber =
    //   {...assesseeAssessmentStartData?.assessmentSectionItemDistinct[currentQuestionIndex] }
    // assesseeAssessmentStartData.assessmentSectionItemDistinct[currentQuestionIndex].assesseeAssignmentAssessmentItemResponseChoiceSelected=optionValue;
    // debugger
    if (
      itemObjectCopy?.itemFrameworkOneType === '61090cace50cf61d5eb440c9' ||
      itemObjectCopy?.itemFrameworkOneType === '61161713f24e1fb765208e23'
    ) {
      let itemSectionSequence = optionName.split('-')[1];
      let assessmentItemSectionObj = {
        itemId: currentQuestionNumber.itemId,
        assesseeAssignmentAssessmentItemSectionSequence: itemSectionSequence,
        assesseeAssignmentAssessmentItem: {
          assesseeAssignmentAssessmentItemResponseChoiceSelected: optionValue
        }
      };
      itemObjectCopy.itemFrameworkOneSection.forEach((element) => {
        if (element.itemFrameworkOneSectionSequence === parseInt(itemSectionSequence)) {
          element.itemFrameworkOne.itemFrameworkOneResponseCorrect = optionValue;
        }
      });
      let assessmentItemSectionArr = [...assessmentItemSection];
      var item = assessmentItemSectionArr.find(
        (x) => x.assesseeAssignmentAssessmentItemSectionSequence === itemSectionSequence
      );
      if (item) {
        item.assesseeAssignmentAssessmentItem.assesseeAssignmentAssessmentItemResponseChoiceSelected =
          optionValue;
      } else {
        assessmentItemSectionArr.push(assessmentItemSectionObj);
      }
      itemObjectCopy.assesseeAssignmentAssessmentItemResponseChoiceSelected = optionValue;
      console.log('itemObjectCopy',itemObjectCopy);
      setItemObject(itemObjectCopy)
      setAssessmentItemSection(assessmentItemSectionArr);
      setcurrentQuestionChoice(optionValue);
    } else {
      setcurrentQuestionChoice(optionValue);
    }
  };
  const flagQuestion = (itemId) => {
    const isSelected = isQuestionFlaged.includes(itemId);
    let flaggedArr = isQuestionFlaged;
    let currentQuestion =
      assesseeAssessmentStartData?.assessmentSectionItemDistinct[currentQuestionIndex];
    // if (isSelected) {
    //   setIsQuestionFlaged(isQuestionFlaged.filter(selectedId => selectedId !== itemId));
    //   currentQuestion['assesseeAssignmentAssessmentItemFlagged'] = false;
    // } else {
    //   currentQuestion['assesseeAssignmentAssessmentItemFlagged'] = true;
    //   flaggedArr.push(itemId);
    //   setIsQuestionFlaged([...flaggedArr]);
    // }

    if (currentQuestion?.assesseeAssignmentAssessmentItemFlagged) {
      currentQuestion.assesseeAssignmentAssessmentItemFlagged = false;
      setIsQuestionFlaged(isQuestionFlaged.filter((selectedId) => selectedId !== itemId));
    } else {
      currentQuestion.assesseeAssignmentAssessmentItemFlagged = true;
      flaggedArr.push(itemId);
      setIsQuestionFlaged([...flaggedArr]);
    }
    assesseeAssessmentStartData.assessmentSectionItemDistinct[currentQuestionIndex] =
      currentQuestion;
  };
  const onNavigationbtnClick = (itemNumber) => {
    let responseInLocal = JSON.parse(localStorage.getItem('navigationItem')) || [];
    let itemData = assesseeAssessmentStartData.assessmentSectionItemDistinct[itemNumber];
    let item = responseInLocal.filter(function (ii) {
      return ii.assesseeAssignmentAssessmentItemId === itemData.itemId;
    });
    // debugger
    if (item.length > 0) {
      setAssessmentItemSection(item[0].assesseeAssignmentAssessmentItemSection);
      // if (item[0].assesseeAssignmentAssessmentItemSection && item[0].assesseeAssignmentAssessmentItemSection.length > 0) {
      //   itemObect.itemFrameworkOneSection.forEach((element) => {
      //     item[0].assesseeAssignmentAssessmentItemSection.forEach((el) => {
      //       if (
      //         element.itemFrameworkOneSectionSequence ===
      //         parseInt(el.assesseeAssignmentAssessmentItemSectionSequence)
      //       ) {
      //         element.itemFrameworkOne.itemFrameworkOneResponseCorrect = el.assesseeAssignmentAssessmentItem.assesseeAssignmentAssessmentItemResponseChoiceSelected;
      //       }
      //     })
      //   });
      // }
      setcurrentQuestionChoice(item[0]?.assesseeAssignmentAssessmentItemResponseChoiceSelected);
    } else {
      if (itemData.assesseeAssignmentAssessmentItemSection) {
        setAssessmentItemSection(itemData.assesseeAssignmentAssessmentItemSection);
      }
      setcurrentQuestionChoice(itemData?.assesseeAssignmentAssessmentItemResponseChoiceSelected);
    }
    setItemObject(null);
    setcurrentQuestionIndex(itemNumber);
    dispatch({ type: POPUP_CLOSE });
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: { stateName: 'headerButtons', value: true }
    });
  };
  const onSaveHandle = async (data) => {
    // console.log(data);
    setResponceData(data);
  };

  // if(!isItemTrial){
  //   if(currentQuestionChoice && currentQuestionIndex === 0){
  //     assesseeAssessmentStartData.assessmentSectionItemNavigation.assessmentSectionItemNavigationNext=true;
  //     assesseeAssessmentStartData.assessmentSectionItemNavigation.assessmentSectionItemNavigationFirst=false;
  //     assesseeAssessmentStartData.assessmentSectionItemNavigation.assessmentSectionItemNavigationPrevious=false;
  //   }
  //  else if(currentQuestionChoice && currentQuestionIndex > 0 ){
  //     assesseeAssessmentStartData.assessmentSectionItemNavigation.assessmentSectionItemNavigationFirst=true;
  //     assesseeAssessmentStartData.assessmentSectionItemNavigation.assessmentSectionItemNavigationPrevious=true;
  //     assesseeAssessmentStartData.assessmentSectionItemNavigation.assessmentSectionItemNavigationNext=true;
  //   }
  //   else if(!currentQuestionChoice && assesseeAssessmentStartData?.assessmentSectionItemNavigation?.assessmentSectionItemNavigationSkip){
  //     assesseeAssessmentStartData.assessmentSectionItemNavigation.assessmentSectionItemNavigationFirst=true;
  //     assesseeAssessmentStartData.assessmentSectionItemNavigation.assessmentSectionItemNavigationPrevious=true;
  //     assesseeAssessmentStartData.assessmentSectionItemNavigation.assessmentSectionItemNavigationNext=true;
  //   }
  //   else{
  //       assesseeAssessmentStartData.assessmentSectionItemNavigation.assessmentSectionItemNavigationFirst=true;
  //       assesseeAssessmentStartData.assessmentSectionItemNavigation.assessmentSectionItemNavigationPrevious=true;
  //       assesseeAssessmentStartData.assessmentSectionItemNavigation.assessmentSectionItemNavigationNext=false;
  //   }
  // }

  //   console.log('assesseeAssessmentStartData',assesseeAssessmentStartData);
  //   console.log('currentSequenceIndex',currentSequenceIndex);
  //   console.log('currentQuestionChoice',currentQuestionChoice);
  //   console.log('isItemTrial',isItemTrial);
  return (
    <>
      <div>
        <HeaderCard
          displayPane="itemPreview"
          className=""
          headerOne={assesseeAssessmentStartData ? 'assessment' : null}
          headerOneBadgeOne={(isItemTrial && 'trial') || ''}
          headerPanelColour="green"
        />
      </div>
      {assesseeAssessmentStartData && (
        <>
          <DisplayPaneFiveAssessment
            instanceRef={instanceRef}
            headerOne={'assessment'}
            informationFramework={assesseeAssessmentStartData}
            currentItemIndex={currentQuestionIndex}
            currentSectionIndex={currentSectionIndex}
            itemObect={itemObject !== null ? itemObject:itemObect}
            reviewReviseMode={'revise'}
            responseAlignment={
              (assesseeAssignmentAssessmentData?.informationFramework
                ?.assessmentAdministrationTemplateAlignment
                ?.assessmentItemFrameworkOneResponseChoiceAlignment &&
                assesseeAssignmentAssessmentData?.informationFramework
                  ?.assessmentAdministrationTemplateAlignment
                  ?.assessmentItemFrameworkOneResponseChoiceAlignment) ||
              (itemObect?.itemFrameworkOneResponseChoiceAlignment &&
                itemObect?.itemFrameworkOneResponseChoiceAlignment) ||
              ''
            }
            timerFinished={isItemTrial ? '' : timerFinished}
            timer={isItemTrial ? '' : timer}
            handleRadioButton={isItemTrial ? null : handleRadioButton}
            currentQuestionChoice={currentQuestionChoice}
            assessmentItemSection={assessmentItemSection}
            flagQuestion={isItemTrial ? '' : flagQuestion}
            assessmentDescription={assesseeAssignmentAssessmentData?.assessmentDescription}
            assessmentName={assesseeAssignmentAssessmentData?.assessmentName}
            assessmentSectionItemFrameworkOneLabel={
              assesseeAssessmentStartData?.assessmentSectionItemFrameworkOneLabel
            }
            assessmentSectionItemFrameworkOneResponseLabel={
              assesseeAssessmentStartData?.assessmentSectionItemFrameworkOneResponseLabel
            }
            responseData={responseData}
            onSaveHandle={onSaveHandle}
          />
          {assesseeAssessmentStartData && (
            <FooterIconTwo
              className={isDisplayPaneSixShow ? 'widthDisplayPaneFive' : 'fullWidth'}
              FilterModeEnable={false}
              FilterMode={FilterMode}
              onClick={onClickReview}
              primaryIcon={primaryIcon}
              secondaryIcon={secondaryIcon}
              isAssessmentPreviewShow={true}
            />
          )}
        </>
      )}
      {(isItemTrial && null) ||
        (isPopUpValue === 'NavigatorPOPUP' && (
          <PopUpAssessmentNavigator
            isActive={true}
            currentQuestionIndex={currentQuestionIndex}
            navigatorIconObject={navigatorIconObject}
            itemData={assesseeAssessmentStartData?.assessmentSectionItemDistinct}
            sectionName={assesseeAssessmentStartData?.assessmentSectionName}
            sectionDescription={assesseeAssessmentStartData?.assessmentSectionDescription}
            onClickButton={onNavigationbtnClick}
            setcurrentQuestionIndex={setcurrentQuestionIndex}
          />
        ))}
    </>
  );
};

export default DisplayPaneSeven;
