import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    CLEAR_DISPLAY_PANE_THREE,
    FILTERMODE,
    FILTERMODE_ENABLE,
    GET_ALLOCATE_ASSESSMENT,
    GET_ALLOCATE_ASSIGNMENT,
    GET_NODE_ASSIGNMENTS_REVIEW_LIST_SAGA,
    POPUP_OPEN,
    SET_ASSESSEE_GROUP_ASSESSEE_ID_LIST,
    SET_ASSOCIATE_NODE_ASSESSEE_ID_LIST,
    SET_DISPLAY_TWO_SINGLE_STATE,
    SET_MIDDLEPANE_STATE,
    SET_MOBILE_PANE_STATE,
    SET_PAGE_COUNT,
    SET_POPUP_STATE,
    SET_UNSELECTED_ASSESSEE_GROUP_ASSESSEE_ID_LIST,
    SET_UNSELECTED_ASSOCIATE_NODE_ASSESSEE_ID_LIST
} from '../actionType';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import { FilterList } from '@material-ui/icons';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import { ASSOCIATE_REVIEW_LIST_POPUP_OPTION } from '../PopUpConfig';
import Card from '../Molecules/Card/Card';
import CrossIcon from '@material-ui/icons/Clear';
import {
    assesseeNodeReviseApiCall,
    onClickCheckBoxSelection
} from '../Actions/AssesseeModuleAction';
import ReviseIcon from '@material-ui/icons/RadioButtonChecked';
import Check from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { getNodeRelatedAssignmentsDistinctApiCall } from '../Actions/AssignmentModuleAction';
import { closeRelatedList, getPopUpOptionList } from '../Actions/GenericActions';
import ListLoader from '../Atoms/ListLoader/ListLoader';

const AssignmentNodeAssignmentDistinctReviewList = (props) => {
    const dispatch = useDispatch();
    const [isShowReviseIcon, setIsShowReviseIcon] = useState(true);
    const {
        relatedReviewListObjectCopy,
        numberPage,
        scanCount,
        middlePaneSelectedValue,
        reviewListDistinctData,
        selectedAssociateInfo,
        relatedReviewListDistinctData,
        middlePaneHeaderBadgeOne,
        middlePaneHeaderBadgeTwo,
        middlePaneHeaderDuplicate,
        middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderDuplicateBadgeTwo,
        middlePaneHeaderDuplicateBadgeThree,
        middlePaneHeaderDuplicateBadgeFour,
        duplicateScanCount,
        typeOfMiddlePaneList,
        isSelectActive,
        selectedTagsArray,
        isMiddlePaneList,
        middlePaneHeader,
        signedAssesseePermission,
        unselectedTagsArray,
        isListLoading,
        countPage,
        middlePaneHeaderDuplicateOne,
        middlePaneHeaderDuplicateOneBadgeOne,
        middlePaneHeaderDuplicateOneBadgeTwo,
        middlePaneHeaderDuplicateOneBadgeThree,
        middlePaneHeaderDuplicateOneBadgeFour,
    } = useSelector((state) => state.DisplayPaneTwoReducer);
    const { FilterModeEnable, FilterMode } = useSelector((state) => state.FilterReducer);

    const { reviewMode, responseObject, createMode, headerOneBadgeOne } = useSelector(
        (state) => state.DisplayPaneThreeReducer
    );

    // const [isFetching, setIsFetching] = useState(false);
    const { nodeInformation } = useSelector((state) => state.NodeCreateReducer);
    //// pagination code here
    let obj = {
        associateNodeAssesseeAllocate: selectedTagsArray,
        associateNodeAssesseeUnallocate: unselectedTagsArray
    };
    //  useEffect(() => {
    //   document.getElementById('middleComponentId').addEventListener('scroll', handleScroll);
    // }, []);
    // const handleScroll = (event) => {
    //   var targetPt = event.target;
    //   if (
    //     Math.ceil(targetPt.scrollHeight - targetPt.scrollTop) !== targetPt.clientHeight ||
    //     isFetching
    //   )
    //     return;
    //   setIsFetching(true);
    //   console.log(isFetching);
    // };
    const fetchData = async () => {
        console.log(relatedReviewListDistinctData);
        if (relatedReviewListDistinctData.length > 0) {
            if (relatedReviewListDistinctData[0].assignment.length < scanCount) {
                dispatch({
                    type: SET_DISPLAY_TWO_SINGLE_STATE,
                    payload: { stateName: 'isListLoading', value: true }
                })
                let obj = {
                    ...relatedReviewListObjectCopy.requestObject,
                    numberPage: numberPage + 1
                };
                // IF the selected mode is Revise and pagination
                if (reviewMode === 'revise') {
                    dispatch({
                        type: GET_ALLOCATE_ASSIGNMENT,
                        payload: {
                            existingAssignmentId: selectedTagsArray,
                            request: {
                                module: 'Assignment-Distinct',
                                action: 'Review-List',
                                requestObject: obj
                            },
                            HeaderOne: middlePaneHeader,
                            BadgeOne: 'distinct',
                            BadgeTwo: middlePaneHeaderBadgeTwo,
                            isMiddlePaneList: true,
                            typeOfMiddlePaneList: 'assignmentNodeAssignmentReviewList',
                            revisedGroupObject: {
                                id: responseObject.id,
                                associateNodeName: responseObject.informationBasic.associateNodeName,
                                associateNodeDescription: responseObject.informationBasic.associateNodeDescription,
                                associateNodeStatus: responseObject.informationEngagement.associateNodeStatus
                            }
                        }
                    });
                } else {
                    dispatch({
                        type: GET_NODE_ASSIGNMENTS_REVIEW_LIST_SAGA,
                        payload: {
                            request: {
                                module: 'Assignment-Distinct',
                                action: 'Review-List',
                                actionFilter: 'Associate-Node-Assignment',
                                requestObject: obj
                            },
                            HeaderOne: 'assessees',
                            BadgeOne: middlePaneHeaderBadgeOne,
                            BadgeTwo: middlePaneHeaderBadgeTwo,
                            BadgeThree: '',
                            isMiddlePaneList: isMiddlePaneList
                        }
                    });
                }

                dispatch({ type: SET_PAGE_COUNT, payload: numberPage + 1 });
            }
        }
    };
    // useEffect(() => {
    //   if (!isFetching) return;
    //   fetchMoreListItems();
    // }, [isFetching]);

    // const fetchMoreListItems = () => {
    //   fetchData();
    //   setIsFetching(false);
    // };

    const onClickRevise = () => {
        console.log('ON CLICK REVISE ICON');
        setIsShowReviseIcon(false);
    };
    const onClickReviseCancel = () => {
        console.log('ON CLICK cancel ICON');
        setIsShowReviseIcon(true);
        closeRelatedList(
            dispatch,
            createMode,
            responseObject,
            middlePaneHeaderDuplicate,
            middlePaneHeaderDuplicateBadgeOne,
            middlePaneHeaderDuplicateBadgeTwo,
            middlePaneHeaderDuplicateBadgeThree,
            middlePaneHeaderDuplicateBadgeFour,
            'associateNodeDistinctReviewList',
            duplicateScanCount,
            'associateNodeDistinct',
            duplicateScanCount,
            middlePaneHeaderDuplicateOne,
            middlePaneHeaderDuplicateOneBadgeOne,
            middlePaneHeaderDuplicateOneBadgeTwo,
            middlePaneHeaderDuplicateOneBadgeThree,
            middlePaneHeaderDuplicateOneBadgeFour,
        );
        dispatch({ type: SET_PAGE_COUNT, payload: Math.round(parseInt(reviewListDistinctData.length) / countPage) - 1 })
        if (headerOneBadgeOne !== 'node') {
            dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
        }
    };
    const onClickReviseFinish = () => {
        console.log('ON CLICK finish ICON', selectedTagsArray, unselectedTagsArray);
        setIsShowReviseIcon(true);
        // if (typeOfMiddlePaneList !== '') {
        //   // dispatch({
        //   //   type: SET_MIDDLEPANE_STATE,
        //   //   payload: {
        //   //     middlePaneHeader: 'assignments',
        //   //     middlePaneHeaderBadgeOne: 'node',
        //   //     middlePaneHeaderBadgeTwo: 'active',
        //   //     middlePaneHeaderBadgeThree: '',
        //   //     middlePaneHeaderBadgeFour: '',
        //   //     typeOfMiddlePaneList: 'associateNodeDistinctReviewList',
        //   //     scanCount: reviewListDistinctData.length,
        //   //     showMiddlePaneState: true
        //   //   }
        //   // });
        //   dispatch({
        //     type: FILTERMODE,
        //     payload: { FilterMode: '' }
        //   });
        // }
        if (typeOfMiddlePaneList !== '') {
            dispatch({
                type: FILTERMODE,
                payload: { FilterMode: '' }
            });
        }

        dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'isSelectActive', value: '' }
        });
        dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
        dispatch({ type: SET_ASSOCIATE_NODE_ASSESSEE_ID_LIST, payload: selectedTagsArray });
        dispatch({
            type: SET_UNSELECTED_ASSOCIATE_NODE_ASSESSEE_ID_LIST,
            payload: unselectedTagsArray
        });
        assesseeNodeReviseApiCall(
            middlePaneHeaderDuplicate, // 'assignments',
            responseObject,
            selectedAssociateInfo,
            nodeInformation,
            obj,
            dispatch,
            createMode,
            true,
            middlePaneHeader
        );
        // closeRelatedList();
        closeRelatedList(
            dispatch,
            createMode,
            responseObject,
            middlePaneHeaderDuplicate,
            middlePaneHeaderDuplicateBadgeOne,
            middlePaneHeaderDuplicateBadgeTwo,
            middlePaneHeaderDuplicateBadgeThree,
            middlePaneHeaderDuplicateBadgeFour,
            'associateNodeDistinctReviewList',
            duplicateScanCount,
            'associateNodeDistinct',
            duplicateScanCount
        );
    };
    const revisePrimaryIcon = [{ label: 'revise', onClick: onClickRevise, Icon: ReviseIcon }];

    const reviseSecondaryIcon = [
        { label: 'cancel', onClick: onClickReviseCancel, Icon: ClearIcon },
        { label: 'finish', onClick: onClickReviseFinish, Icon: Check }
    ];

    // const closeRelatedList = () => {
    //   if (createMode == ''){
    //     dispatch({
    //       type: SET_MIDDLEPANE_STATE,
    //       payload: {
    //         middlePaneHeader: middlePaneHeaderDuplicate,
    //         middlePaneHeaderBadgeOne: middlePaneHeaderDuplicateBadgeOne,
    //         middlePaneHeaderBadgeTwo: middlePaneHeaderDuplicateBadgeTwo,
    //         middlePaneHeaderBadgeThree: middlePaneHeaderDuplicateBadgeThree,
    //         middlePaneHeaderBadgeFour: middlePaneHeaderDuplicateBadgeThree,
    //         middlePaneHeaderDuplicate: middlePaneHeaderDuplicate,
    //         middlePaneHeaderDuplicateBadgeOne: middlePaneHeaderDuplicateBadgeOne,
    //         middlePaneHeaderDuplicateBadgeTwo: middlePaneHeaderDuplicateBadgeTwo,
    //         middlePaneHeaderDuplicateBadgeThree: middlePaneHeaderDuplicateBadgeThree,
    //         middlePaneHeaderDuplicateBadgeFour: middlePaneHeaderDuplicateBadgeFour,
    //         typeOfMiddlePaneList: 'associateNodeDistinctReviewList',
    //         scanCount: reviewListDistinctData.length,
    //         showMiddlePaneState: true
    //       }
    //     });
    //     if (!responseObject) dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
    //   }else {
    //     dispatch({
    //       type: SET_MIDDLEPANE_STATE,
    //       payload: {
    //         middlePaneHeader: '',
    //         middlePaneHeaderBadgeOne: '',
    //         middlePaneHeaderBadgeTwo: '',
    //         middlePaneHeaderBadgeThree: '',
    //         middlePaneHeaderBadgeFour: '',
    //         typeOfMiddlePaneList: ''
    //       }
    //     });
    //   }
    //   dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
    // };
    const listDistinctData = relatedReviewListDistinctData[0];

    const siftApiCall = (siftKey) => {
        getNodeRelatedAssignmentsDistinctApiCall(
            selectedAssociateInfo,
            siftKey,
            countPage,
            dispatch,
            middlePaneHeaderBadgeOne,
            listDistinctData.id,
            '',
            false,
            'assignments'
        );

        // document.getElementById('middleComponentId').scrollTop = '0px';
    };
    const onClickFooter = (e) => {
        let siftValue = e.currentTarget.getAttribute('data-value');
        if (
            siftValue === 'suspended' ||
            siftValue === 'terminated' ||
            siftValue === 'published' ||
            siftValue === 'unpublished' ||
            siftValue === 'administered'
        ) siftApiCall(siftValue);
        dispatch({ type: FILTERMODE_ENABLE });
    };
    /* for middle pane */
    const primaryIcon = [{ label: 'sift', onClick: onClickFooter, Icon: FilterList }];
    const activesecondaryIcon = [
        { label: 'administered', onClick: onClickFooter, Icon: FilterList },
        { label: 'published', onClick: onClickFooter, Icon: FilterList }
    ];
    const secondaryIcon = [
        { label: 'suspended', onClick: onClickFooter, Icon: FilterList },
        { label: 'terminated', onClick: onClickFooter, Icon: FilterList },
        { label: 'unpublished', onClick: onClickFooter, Icon: FilterList }
    ];

    const openListPopup = (e) => {
        console.log(e.currentTarget.getAttribute('tag'));
        let assesseeStatus = e.currentTarget.getAttribute('status');
        let isShared = e.currentTarget.getAttribute('data-shared') === 'true' && true;
        let associateId = e.currentTarget.getAttribute('associateid');
        let signedAssociateId =
            selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary;

        let assesseeDistinctPermission =
            signedAssesseePermission?.assesseeDistinctPermission?.assesseeAssesseeDistinctPermission;
        let popupContentArr = getPopUpOptionList(
            ASSOCIATE_REVIEW_LIST_POPUP_OPTION,
            assesseeDistinctPermission
        );
        let tempArr = [];
        popupContentArr.forEach((element) => {
            if (
                (assesseeStatus === 'UNCONFIRMED' ||
                    assesseeStatus === 'SUSPENDED' ||
                    assesseeStatus === 'TERMINATED') &&
                (element.data === 'approve' || element.data === 'share')
            ) {
                tempArr.push({ ...element, disabled: true });
            } else if (
                (assesseeStatus === 'TERMINATED' ||
                    assesseeStatus === 'SUSPENDED' ||
                    assesseeStatus === 'ARCHIVED') &&
                (element.data === 'share' ||
                    element.data === 'revise' ||
                    element.data === 'select' ||
                    element.data === 'allocate')
            ) {
                tempArr.push({ ...element, disabled: true });
            } else if (
                isShared &&
                associateId !== signedAssociateId &&
                (element.data === 'revise' ||
                    element.data === 'archive' ||
                    element.data === 'flag' ||
                    element.data === 'delete' ||
                    element.data === 'suspend' ||
                    element.data === 'move' ||
                    element.data === 'terminate')
            ) tempArr.push({ ...element, disabled: true });
            // else if (assesseeStatus !== 'CONFIRMED' && element.data === 'share')
            //   tempArr.push({ ...element, disabled: true });
            else {
                tempArr.push(element);
            }
        });
        dispatch({
            type: SET_POPUP_STATE,
            payload: {
                popupHeaderOne: 'assignment',
                popupHeaderOneBadgeOne: middlePaneHeaderBadgeOne,
                isPopUpValue: '',
                popupOpenType: 'primary',
                popupContentArrValue: tempArr, //ASSOCIATE_REVIEW_LIST_POPUP_OPTION,
                isShared: isShared,
                selectedTagValue: e.currentTarget.getAttribute('tag'),
                selectedTagStatus: e.currentTarget.getAttribute('status')
            }
        });
        dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
        dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: {
                stateName: 'middlePaneListPopupOptions',
                value: tempArr //ASSOCIATE_REVIEW_LIST_POPUP_OPTION
            }
        });
    };

    // infinite scroll
    const [hasMore, setHasMore] = useState(true);
    let elementRef = useRef(null);

    const onIntersection = (entries) => {
        const firstEntry = entries[0];
        listDistinctData.assignment.length >= scanCount ? setHasMore(false) : setHasMore(true)
        if (firstEntry.isIntersecting && hasMore && !isListLoading) {
            fetchData();
        }
    };

    useEffect(() => {
        // console.log('####MYREF######', elementRef)
        // console.log('observing.... AssignmentNodeAssignmentDistinctReviewList' )
        const observer = new IntersectionObserver(onIntersection, { threshold: 0.90 });

        if (observer && elementRef.current) {
            // console.log('here at observer...', elementRef.current)
            observer.observe(elementRef.current);
        }

        return () => {
            if (observer) observer.disconnect();
        };
    }, [isListLoading, hasMore, listDistinctData, selectedTagsArray, unselectedTagsArray, isSelectActive]);

    // infinite scroll end
    return (
        <div>
            {listDistinctData && (
                <Card
                    textOneOne={listDistinctData.associateNodeName}
                    textTwoOne={listDistinctData.associateNodeDescription}
                    IconOne={CrossIcon}
                    isIcon={true}
                    labelTwoTwo={'node'}
                    onClickIconOne={onClickReviseCancel}
                    isAlliance
                    relatedCardFixed={true}
                    className={'iguru-iconboxSVG'}
                />
            )}
            {listDistinctData &&
                listDistinctData.assignment.map((item, index) => {
                    return (
                        <div className="containerPadding" key={index} ref={elementRef}>
                            <ReviewList
                                className=""
                                id={index}
                                tag={item.id}
                                associateId={item?.associateId}
                                isSelectedReviewList={middlePaneSelectedValue === item.id}
                                status={item.informationEngagement.assignmentStatus}
                                actualStatus={item.informationEngagement.assignmentStatus}
                                textOne={item.informationBasic.assignmentName}
                                textTwo={item.informationBasic.assignmentDescription}
                                isTooltipActive={false}
                                // onClickEvent={openListPopup}
                                isSelectActive={isSelectActive}
                                isShared={item?.assignmentShared}
                                isSelected={selectedTagsArray.includes(item.id)}
                                onClickCheckBox={(event) => {
                                    !isListLoading &&
                                        onClickCheckBoxSelection(selectedTagsArray, unselectedTagsArray, event, dispatch);
                                }}
                            />
                        </div>
                    );
                })}
            {isListLoading ? <div> <ListLoader /> </div> : <div style={{ height: "60px", width: "100%" }}></div>}
            {/* {FilterMode === 'assignmentNodeAssignmentRevise' && (
                <FooterIconTwo
                    FilterModeEnable={isShowReviseIcon}
                    FilterMode={FilterMode}
                    onClick={onClickFooter}
                    primaryIcon={revisePrimaryIcon}
                    secondaryIcon={reviseSecondaryIcon}
                />
            )}
            {FilterMode === 'AssignmentNodeAssignmentReviewListDistinctinactive' && (
                <FooterIconTwo
                    FilterModeEnable={FilterModeEnable}
                    FilterMode={FilterMode}
                    onClick={onClickFooter}
                    primaryIcon={primaryIcon}
                    secondaryIcon={secondaryIcon}
                />
            )}
            {FilterMode === 'AssignmentNodeAssignmentReviewListDistinctactive' && (
                <FooterIconTwo
                    FilterModeEnable={FilterModeEnable}
                    FilterMode={FilterMode}
                    onClick={onClickFooter}
                    primaryIcon={primaryIcon}
                    secondaryIcon={activesecondaryIcon}
                />
            )} */}
        </div>
    );
};
export default AssignmentNodeAssignmentDistinctReviewList;
