import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ASSOCIATE_POPUP_CLOSE,
  FILTERMODE_ENABLE,
  GET_CULTUREPROFILE_TYPE_REVIEW_LIST_SAGA,
  POPUP_OPEN,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_PAGE_COUNT,
  SET_POPUP_STATE,
  LOADER_START,
  SET_REQUEST_OBJECT,
  CULTUREPROFILE_ALLOCATE_SAGA,
  FILTERMODE
} from '../actionType';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import { FilterList } from '@material-ui/icons';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import {
  ASSESSEE_NODE_ROLE_REVIEW_LIST_POPUP_OPTION,
  ANALYTICS_TYPE_REVIEW_LIST_POPUP_OPTION,
  NODE_ROLE_TYPE_REVIEW_LIST_POPUP_OPTION
} from '../PopUpConfig';
import { getCultureProfileTypeApiCall } from '../Actions/ActionCultureProfile';
import {
  assesseeRole,
  onClickCheckBoxSelection,
  onClickFlagSelection,
  setFlagedArray
} from '../Actions/AssesseeModuleAction';
import { getPopUpOptionList, makeCultureProfileTypeObj } from '../Actions/GenericActions';
import ReviseIcon from '@material-ui/icons/RadioButtonChecked';
import Check from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import ListLoader from '../Atoms/ListLoader/ListLoader';

const CultureProfileTypeReviewList = (props) => {
  const dispatch = useDispatch();
  // const { secondaryOptionCheckValue,  } = useSelector(
  //   (state) => state.AssesseeCreateReducer
  // );
  const {
    numberPage,
    countPage,
    scanCount,
    reviewListDistinctData,
    reviewListReqObj,
    middlePaneSelectedValue,
    selectedAssociateInfo,
    middlePaneHeader,
    isSelectActive,
    selectedTagsArray,
    unselectedTagsArray,
    middlePaneHeaderBadgeOne,
    middlePaneHeaderBadgeTwo,
    middlePaneHeaderBadgeThree,
    allocateStr,
    allocatedTagsArray,
    selectedFlagedArray,
    unselectedFlagedArray,
    flagedValue,
    signedAssesseePermission,
    isListLoading
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { cardValue } = useSelector((state) => state.PopUpReducer);
  const { FilterModeEnable, FilterMode } = useSelector((state) => state.FilterReducer);
  // const [isFetching, setIsFetching] = useState(false);
  // useEffect(() => {
  //   document.getElementById('middleComponentId').addEventListener('scroll', handleScroll);
  // }, []);
  // const handleScroll = (event) => {
  //   var targetPt = event.target;
  //   if (
  //     Math.ceil(targetPt.scrollHeight - targetPt.scrollTop) !== targetPt.clientHeight ||
  //     isFetching
  //   )
  //     return;
  //   setIsFetching(true);
  //   console.log(isFetching);
  // };
  const fetchData = async () => {
    if (reviewListDistinctData.length < scanCount) {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isListLoading', value: true }
      })
      let obj = {
        ...reviewListReqObj.requestObject,
        numberPage: numberPage + 1
      };
      dispatch({
        type: GET_CULTUREPROFILE_TYPE_REVIEW_LIST_SAGA,
        payload: {
          request: { module: 'Culture-Profile-Type', action: 'Review-List', requestObject: obj },
          BadgeOne: middlePaneHeaderBadgeOne,
          BadgeTwo: middlePaneHeaderBadgeTwo,
          BadgeThree: middlePaneHeaderBadgeThree,
          isMiddlePaneList: true,
          isSelectActive,
          selectedTagsArray
        }
      });
      dispatch({ type: SET_PAGE_COUNT, payload: numberPage + 1 });
    }
  };
  // useEffect(() => {
  //   console.log(reviewListDistinctData);
  //   if (!isFetching) return;
  //   fetchMoreListItems();
  // }, [isFetching]);

  // const fetchMoreListItems = () => {
  //   fetchData();
  //   setIsFetching(false);
  // };
  useEffect(() => {
    setFlagedArray(reviewListDistinctData, 'cultureProfileTypeFlag', dispatch);
  }, [reviewListDistinctData]);
  const siftApiCall = (siftKey) => {
    getCultureProfileTypeApiCall(
      selectedAssociateInfo,
      siftKey,
      countPage,
      dispatch,
      'types',
      'culture-profile',
      cardValue,
      isSelectActive,
      selectedTagsArray
    );
    dispatch({ type: ASSOCIATE_POPUP_CLOSE });
    document.getElementById('middleComponentId').scrollTop = '0px';
  };

  const siftApiFilterCall = (siftKey) => {
    //getCultureProfileTypeApiCall(selectedAssociateInfo, siftKey, countPage, dispatch, 'types');
    let requestObj = makeCultureProfileTypeObj(selectedAssociateInfo, siftKey, countPage, 0);
    dispatch({ type: LOADER_START });
    dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
    dispatch({ type: SET_PAGE_COUNT, payload: 0 });
    dispatch({
      type: FILTERMODE,
      payload: { FilterMode: 'cultureProfileTypeDistinctactive' + siftKey }
    });
    dispatch({
      type: GET_CULTUREPROFILE_TYPE_REVIEW_LIST_SAGA,
      payload: {
        middlePaneHeader: middlePaneHeader,
        request: requestObj,
        BadgeOne: middlePaneHeaderBadgeOne,
        BadgeTwo: middlePaneHeaderBadgeTwo === 'distinct' ? middlePaneHeaderBadgeTwo : siftKey,
        BadgeThree: middlePaneHeaderBadgeTwo === 'distinct' ? siftKey : middlePaneHeaderBadgeThree,
        isMiddlePaneList: true,
        selectedTagsArray: selectedTagsArray, // for select  check box
        isSelectActive: isSelectActive // is for selection of array like multuple and all and sect option wrk
      }
    });
    dispatch({ type: ASSOCIATE_POPUP_CLOSE });
    document.getElementById('middleComponentId').scrollTop = '0px';
  };
  const onClickFooter = (e) => {
    let siftValue = e.currentTarget.getAttribute('data-value');
    dispatch({ type: FILTERMODE_ENABLE });
    if (siftValue === 'suspended' || siftValue === 'terminated') siftApiCall(siftValue);
    if (siftValue === 'bespoke' || siftValue === 'generic') siftApiFilterCall(siftValue);
    if (siftValue === 'finish') {
      console.log('allocateStr', allocateStr);
      let distinctAllocateStr =
        allocateStr === 'culture-profilesdistinct'
          ? 'cultureProfileDistinct'
          : allocateStr === 'cultureprofilesdistinct'
          ? 'cultureProfileDistinct'
          : allocateStr === 'culture-profilesgroups'
          ? 'cultureProfileGroup'
          : allocateStr === 'culture-profilesnodes'
          ? 'cultureProfileNode'
          : allocateStr === 'culture-profilestypes'
          ? 'cultureProfileType'
          : allocateStr === 'job-profilesdistinct'
          ? 'jobProfileDistinct'
          : allocateStr === 'jobprofilesdistinct'
          ? 'jobProfileDistinct'
          : allocateStr === 'job-profilesgroups'
          ? 'jobProfileGroup'
          : allocateStr === 'assignmentsdistinct'
          ? 'assignmentDistinct'
          : allocateStr === 'assignmentsgroups'
          ? 'assignmentGroup'
          : allocateStr === 'assignmentstypes'
          ? 'assignmentType'
          : allocateStr === 'assignmentsnodes'
          ? 'assignmentNode'
          : '';
      if (distinctAllocateStr !== '' && selectedTagsArray.length !== 0) {
        // if (distinctAllocateStr === 'cultureProfileDistinct' || distinctAllocateStr === 'cultureProfileGroup' ||
        //   distinctAllocateStr === 'cultureProfileNode' || distinctAllocateStr === 'cultureProfileType') {
        if (
          distinctAllocateStr === 'cultureProfileDistinct' ||
          distinctAllocateStr === 'cultureProfileGroup' ||
          distinctAllocateStr === 'cultureProfileNode' ||
          distinctAllocateStr === 'cultureProfileType'
        ) {
          let request = {
            module: 'Culture-Profile-Distinct',
            action: FilterMode.toLowerCase().includes('unallocate') ? 'Unallocate' : 'Allocate',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              cultureProfileDistinctAllocate: {
                [distinctAllocateStr]: allocatedTagsArray
              },
              cultureProfileDistinctAllocateInformation: {
                cultureProfileType: selectedTagsArray
              }
            }
          };
          dispatch({ type: LOADER_START });
          dispatch({ type: CULTUREPROFILE_ALLOCATE_SAGA, payload: { request: request } });
        }
      }
    }
    if (siftValue === 'cancel') {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isSelectActive', value: '' }
      });
      dispatch({
        type: FILTERMODE,
        payload: { FilterMode: '' }
      });
    }
  };
  /* for middle pane */
  const primaryIcon = [{ label: 'sift', onClick: onClickFooter, Icon: FilterList }];
  const secondaryIcon = [
    { label: 'suspended', onClick: onClickFooter, Icon: FilterList },
    { label: 'terminated', onClick: onClickFooter, Icon: FilterList }
  ];
  const secondaryOneIcon = [
    { label: 'bespoke', onClick: onClickFooter, Icon: FilterList },
    { label: 'generic', onClick: onClickFooter, Icon: FilterList }
    // { label: 'shared', onClick: onClickFooter, Icon: FilterList },
    // { label: 'unshared', onClick: onClickFooter, Icon: FilterList }
  ];
  const openListPopup = (e) => {
    console.log(e.currentTarget.getAttribute('tag'));
    let optArr = [];
    let reviseHeader = 'culture-profiles';
    let popupArr =
      cardValue === 'Card'
        ? NODE_ROLE_TYPE_REVIEW_LIST_POPUP_OPTION
        : ANALYTICS_TYPE_REVIEW_LIST_POPUP_OPTION;
    let popupContentArrValue = popupArr.map((obj) =>
      obj.data === 'analytics'
        ? { ...obj, data: middlePaneHeader, dataValue: middlePaneHeader }
        : obj
    );
    let tempArr = [];
    let classification = e.currentTarget.getAttribute('data-classification');
    let isShared = e.currentTarget.getAttribute('data-shared');
    let associateId = e.currentTarget.getAttribute('associateid');
    let status = e.currentTarget.getAttribute('status');
    let signedAssociateId =
      selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary;
    let cultureProfileDistictPermssion =
      signedAssesseePermission?.iGuruAnalyticDistinctPermission
        ?.assesseeiGuruAnalyticTypePermission || {};
    let popupContentArr = getPopUpOptionList(popupContentArrValue, cultureProfileDistictPermssion);
    popupContentArr.map((element) => {
      if (classification === 'Bespoke' && element.data === 'share' && status === 'ACTIVE')
        tempArr.push({ ...element, disabled: true });
      else if (
        isShared === 'true' &&
        associateId !== signedAssociateId &&
        (element.data === 'archive' ||
          element.data === 'flag' ||
          element.data === 'delete' ||
          element.data === 'suspend' ||
          element.data === 'move' ||
          element.data === 'terminate')
      ) {
        tempArr.push({ ...element, disabled: true });
      } else if (
        (status === 'TERMINATED' || status === 'SUSPENDED' || status === 'ARCHIVED') &&
        (element.data === 'allocate' ||
          element.data === 'revise' ||
          element.data === 'select' ||
          element.data === 'share')
      ) {
        tempArr.push({ ...element, disabled: true });
      } else tempArr.push(element);
    });
    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: middlePaneHeader,
        popupHeaderOneBadgeOne: 'type',
        duplicateHeaderOneBadgeTwo: middlePaneHeaderBadgeTwo,
        popupHeaderOneBadgeTwo: '',
        isPopUpValue: '',
        popupOpenType: 'primary',
        popupContentArrValue: tempArr, //tempArr, //optArr,
        originPrimaryArrOprion: tempArr,
        selectedTagValue: e.currentTarget.getAttribute('tag'),
        selectedTagStatus: e.currentTarget.getAttribute('status'),
        selectedTagGroupId: e.currentTarget.getAttribute('data-value'),
        selectedTagShared: e.currentTarget.getAttribute('data-shared'),
        isFlaged: e.currentTarget.getAttribute('data-flag') === 'true' ? true : false
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: tempArr //optArr
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
  };
  const signedAssociateId =
    selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary;

  // infinite scroll
  const [hasMore, setHasMore] = useState(true);
  let elementRef = useRef(null);

  const onIntersection = (entries) => {
    const firstEntry = entries[0];
    reviewListDistinctData.length >= scanCount ? setHasMore(false) : setHasMore(true)
    if (firstEntry.isIntersecting && hasMore && !isListLoading) {
      fetchData();
    }
  };
  // console.log(reviewListDistinctData.length, scanCount , '##########')
  useEffect(() => {
    // console.log('####MYREF######', elementRef)

    const observer = new IntersectionObserver(onIntersection, { threshold: 0.90 });

    if (observer && elementRef.current) {
      // console.log('here at observer...', elementRef.current)
      observer.observe(elementRef.current);
    }
    // console.log(reviewListDistinctData.length, scanCount ,'##########')
    return () => {
      
      if (observer) observer.disconnect();
    };
  }, [isListLoading, hasMore, reviewListDistinctData, isSelectActive, selectedTagsArray, unselectedTagsArray]);

  // infinite scroll end

  return (
    <div>
      {reviewListDistinctData &&
        reviewListDistinctData.map((item, index) => {
          return (
            <div
              className="containerPadding"
              key={index}
              ref={elementRef}
            >
              <ReviewList
                className=""
                id={index}
                tag={item.id}
                associateId={item?.associateId}
                isSelectedReviewList={middlePaneSelectedValue === item.id}
                // status={item.informationEngagement.cultureProfileTypeStatus}
                // textOne={item.informationBasic.cultureProfileTypeName}
                textOne={assesseeRole(item.informationBasic.cultureProfileTypeName)}
                textTwo={item.informationBasic.cultureProfileTypeDescription}
                //status={associateSeftId === item.associateId ? 'bespoke' : 'generic'}
                status={
                  FilterMode === 'cultureProfileTypeDistinctactive' ||
                  FilterMode === 'cultureProfileTypeDistinctactivebespoke' ||
                  FilterMode === 'cultureProfileTypeDistinctbespoke' ||
                  FilterMode === 'cultureProfileTypeDistinctgeneric' ||
                  FilterMode === 'cultureProfileTypeDistinctactivegeneric'
                    ? item.informationSetup?.cultureProfileTypeClassification
                        ?.cultureProfileTypeClassificationPrimary
                    : item.informationEngagement.cultureProfileTypeStatus
                }
                actualStatus={item.informationEngagement.cultureProfileTypeStatus}
                //shared={item.cultureProfileTypeShared ? 'SHARED' : 'UNSHARED'}
                isTooltipActive={false}
                onClickEvent={openListPopup}
                // dataValue={item.informationAllocation.cultureProfileTypeGroup}
                isSelectActive={isSelectActive}
                isSelected={selectedTagsArray.includes(item.id)}
                onClickCheckBox={(event) => {
                  !isListLoading && onClickCheckBoxSelection(selectedTagsArray, unselectedTagsArray, event, dispatch);
                }}
                // disableonClickCheckBox={item.associateId !== signedAssociateId ? true : false}
                isShared={item?.cultureProfileTypeShared}
                shared={
                  item.informationSetup?.cultureProfileTypeClassification
                    ?.cultureProfileTypeClassificationPrimary
                }
                isFlagActive={selectedFlagedArray.includes(item.id)}
                flagedValue={flagedValue}
                onClickAddFladed={(event) => {
                  onClickFlagSelection(selectedFlagedArray, unselectedFlagedArray, event, dispatch);
                }}
              />
            </div>
          );
        })}
      {isListLoading ? <div> <ListLoader /> </div> : <div style={{ height: "60px", width: "100%" }}></div>}
      {(FilterMode === 'cultureProfileTypeDistinctinactive' ||
        FilterMode === 'cultureProfileTypeDistinctsuspended' ||
        FilterMode === 'cultureProfileTypeDistinctterminated') && (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={primaryIcon}
          secondaryIcon={secondaryIcon}
        />
      )}
      {(FilterMode === 'cultureProfileTypeDistinctactive' ||
        FilterMode === 'cultureProfileTypeDistinctactivebespoke' ||
        FilterMode === 'cultureProfileTypeDistinctbespoke' ||
        FilterMode === 'cultureProfileTypeDistinctgeneric' ||
        FilterMode === 'cultureProfileTypeDistinctactivegeneric') && (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={primaryIcon}
          secondaryIcon={secondaryOneIcon}
        />
      )}
      {( FilterMode === 'allocateToCultureProfileType' || FilterMode === 'unAllocateToCultureProfileType' ) && (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={[{ label: `${FilterMode === 'allocateToCultureProfileType' ?'allocate': 'unallocate'}`, onClick: onClickFooter, Icon: ReviseIcon }]}
          secondaryIcon={[
            { label: 'cancel', onClick: onClickFooter, Icon: ClearIcon },
            { label: 'finish', onClick: onClickFooter, Icon: Check }
          ]}
        />
      )}
    </div>
  );
};
export default CultureProfileTypeReviewList;
