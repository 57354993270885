import React from 'react';
import './Section.css';

const SectionLine = (props) => {
  // const { isSelected } = props;
  return (
    <div className={'middleTabItem'}>
      <div> </div>
    </div>
  );
};

export default SectionLine;
