import { SET_SCORE_REDUCER_STATE, CLEAR_SCORE_REDUCER_STATE } from '../actionType';
const initialState = {
  scoreInformation: {
    assessmentScoreName: '',
    assessmentScoreVerification: false,
    assessmentScoreDescription: '',
    assessmentScorePicture: '',
    assessmentScorePictureVerification: false,
    assessmentScoreTagPrimary:"",
    assessmentScoreClassification: {
      assessmentScoreClassificationPrimary: "",
      assessmentScoreClassificationSecondary: null
    },
    assessmentScoreComputation: '',
    assessmentScoreOneExplanation:'',
    assessmentScoreTwoExplation:'',
    assessmentScoreOneLable:'',
    assessmentScoreOneLabel:'',
    assessmentScoreTwoLable:'',
    assessmentScoreOneDescription:'',
    assessmentScoreTwoDescription:''

  }
};

const ScoreCreateReducer = (istate = JSON.parse(JSON.stringify(initialState)), action) => {
  switch (action.type) {
    case SET_SCORE_REDUCER_STATE:
      return {
        ...istate,
        scoreInformation: action.payload
      };
    case CLEAR_SCORE_REDUCER_STATE:
      return JSON.parse(JSON.stringify(initialState));
    default:
      return istate;
  }
};

export default ScoreCreateReducer;
