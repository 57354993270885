import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CLEAR_DISPLAY_PANE_THREE,
  POPUP_OPEN,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MIDDLEPANE_STATE,
  SET_POPUP_STATE
} from '../actionType';

import ReviewList from '../Molecules/ReviewList/ReviewList';
import { SECTION_SCALE_CLUSTER_REVIEW_LIST_POPUP_OPTION } from '../PopUpConfig';
import Card from '../Molecules/Card/Card';
import CrossIcon from '@material-ui/icons/Clear';
import { onClickCheckBoxSelection } from '../Actions/AssesseeModuleAction';
import { getPopUpOptionList } from '../Actions/GenericActions';

const AssignmentDistinctGaugeMetricsDistinct = (props) => {
  const dispatch = useDispatch();
  const {
    reviewListDistinctData,
    relatedReviewListDistinctData,
    selectedTagsArray,
    unselectedTagsArray,
    isSelectActive,
    signedAssesseePermission
  } = useSelector((state) => state.DisplayPaneTwoReducer);

  const closeRelatedList = () => {
    dispatch({
      type: SET_MIDDLEPANE_STATE,
      payload: {
        middlePaneHeader: 'assignment',
        middlePaneHeaderBadgeOne: 'distinct',
        middlePaneHeaderBadgeTwo: 'active',
        middlePaneHeaderBadgeThree: '',
        middlePaneHeaderBadgeFour: '',
        typeOfMiddlePaneList: 'assignmentDistinctReviewList',
        scanCount: reviewListDistinctData.length,
        showMiddlePaneState: true
      }
    });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  };
  const closeRelatedListTwo = () => {
    let listingData = relatedReviewListDistinctData[0];
    let reviseResponseObj = {
      countTotal: listingData?.guages.length || 0,
      responseObject: [
        {
          guages: listingData?.guages || [],
          assignmentName: listingData.assignmentName,
          assignmentDescription: listingData.assignmentDescription
        }
      ]
    };
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'relatedReviewListDistinctData',
        value: reviseResponseObj.responseObject
      }
    });
    dispatch({
      type: SET_MIDDLEPANE_STATE,
      payload: {
        middlePaneHeader: 'assignment',
        middlePaneHeaderBadgeOne: 'guages',
        middlePaneHeaderBadgeTwo: 'distinct',
        middlePaneHeaderBadgeThree: '',
        middlePaneHeaderBadgeFour: '',
        typeOfMiddlePaneList: 'assignmentgaugesReviewList',
        scanCount: listingData?.guages.length,
        showMiddlePaneState: true
      }
    });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  };
  const listDistinctData = relatedReviewListDistinctData[0];

  const openListPopup = (e) => {
    console.log(e.currentTarget.getAttribute('tag'));
    let tempArr = [];
    let stats = e.currentTarget.getAttribute('status');
    let permissionObject = signedAssesseePermission?.assigmentDistictPermission?.assesseeAssignmentDistinctPermission;
    let popupContentArr = getPopUpOptionList(SECTION_SCALE_CLUSTER_REVIEW_LIST_POPUP_OPTION, permissionObject);
    popupContentArr.map((element) => {
      if (stats === 'PUBLISHED' && element.data === 'revise')
        tempArr.push({ ...element, disabled: true });
      else tempArr.push(element);
    });
    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: 'assignment',
        popupHeaderOneBadgeOne: 'metrics',
        isPopUpValue: '',
        popupOpenType: 'primary',
        popupContentArrValue: tempArr,
        selectedTagValue: e.currentTarget.getAttribute('tag'),
        selectedTagStatus: stats
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: tempArr
      }
    });
  };
  console.log('listDistinctDatasss', listDistinctData);
  return (
    <div>
      {listDistinctData && (
        <React.Fragment>
          <Card
            textOneOne={listDistinctData?.assignmentName}
            textTwoOne={listDistinctData?.assignmentDescription}
            IconOne={CrossIcon}
            isIcon={true}
            labelTwoTwo={'distinct'}
            onClickIconOne={closeRelatedList}
            isAlliance
            relatedCardFixed={true}
            className={'iguru-iconboxSVG'}
          />
          <Card
            textOneOne={listDistinctData?.metrics?.iGaugeOccupationFrameworkOneGaugeName}
            textTwoOne={listDistinctData?.metrics?.iGaugeOccupationFrameworkOneGaugeDescription}
            IconOne={CrossIcon}
            isIcon={true}
            labelTwoTwo={'gauge'}
            onClickIconOne={closeRelatedListTwo}
            isAlliance
            relatedCardFixed={true}
            className={'iguru-iconboxSVG'}
          />
        </React.Fragment>
      )}
      {listDistinctData &&
        listDistinctData?.metrics?.iGaugeOccupationFrameworkOneGaugeMetric.map((item, index) => {
          return (
            <div className="containerPadding" key={index}>
              <ReviewList
                className=""
                id={index}
                tag={index}
                //isSelectedReviewList={middlePaneSelectedValue === item.id}
                associateId={item?.associateId}
                status={''}
                actualStatus={listDistinctData?.assignmentStatus}
                textOne={item?.iGaugeOccupationFrameworkOneGaugeMetricLabel}
                textTwo={item?.iGaugeOccupationFrameworkOneGaugeMetricExplanation}
                isTooltipActive={false}
                onClickEvent={openListPopup}
                isSelectActive={isSelectActive}
                isSelected={selectedTagsArray.includes(index.toString())}
                onClickCheckBox={(event) => {
                  onClickCheckBoxSelection(selectedTagsArray, unselectedTagsArray, event, dispatch);
                }}
              />
            </div>
          );
        })}
    </div>
  );
};
export default AssignmentDistinctGaugeMetricsDistinct;
