import React from 'react';
import ArrowRight from '@material-ui/icons/ChevronRight';
import Card from '../Card/Card';
import { useDispatch } from 'react-redux';
import {
  SECTION_POPUP_OPEN,
  SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
  SET_ASSESSEE_CREATE_SINGLE_STATE,
  SET_ASSOCIATE_CREATE_SINGLE_STATE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_SECTION_TWO_SECONDARY_OPTION_VALUE
} from '../../actionType';

const DisplayPaneOneSectionThree = () => {
  const dispatch = useDispatch();
  const openSectionPopUp = (e) => {
    dispatch({
      type: SECTION_POPUP_OPEN,
      payload: {
        headerOne: 'iGuru',
        headerOneBadgeOne: e.currentTarget.getAttribute('data-value')
      }
    });
    dispatch({ type: SET_SECTION_TWO_SECONDARY_OPTION_VALUE, payload: { clickValue: '' } });

    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'sectionCreateTypeSecondary',
        value: e.currentTarget.getAttribute('data-types-secondary')
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'sectionCreateType',
        value: e.currentTarget.getAttribute('data-id')
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'assignmentCreateTypeSecondary',
        value: e.currentTarget.getAttribute('data-types-secondary')
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'assignmentCreateType',
        value: e.currentTarget.getAttribute('data-id')
      }
    });
    dispatch({
      type: SET_ASSESSEE_CREATE_SINGLE_STATE,
      payload: {
        stateName: 'assesseesPopUpType',
        value: 'primary'
      }
    });
    dispatch({
      type: SET_ASSOCIATE_CREATE_SINGLE_STATE,
      payload: {
        stateName: 'associatesPopUpType',
        value: 'primary'
      }
    });
    dispatch({
      type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
      payload: { stateName: 'footerButtonMode', value: true }
    });
  };
  return (
    <>
      <div
        className="paddingCard"
        onClick={openSectionPopUp}
        data-value={'career'}
        data-id={'iGuru Marketplace_Career'}
        data-types-secondary={'iGuru Career'}
      >
        <Card isIcon IconOne={ArrowRight} textOneOne="iGuru" textTwoOne="career" />
      </div>
      <div
        className="paddingCard"
        onClick={openSectionPopUp}
        data-value={'education'}
        data-id={'iGuru Marketplace_Education'}
        data-types-secondary={'iGuru Education'}
      >
        <Card isIcon IconOne={ArrowRight} textOneOne="iGuru" textTwoOne="education" />
      </div>
      <div
        className="paddingCard"
        onClick={openSectionPopUp}
        data-value={'occupation'}
        data-id={'iGuru Marketplace_Occupation'}
        data-types-secondary={'iGuru Occupation'}
      >
        <Card isIcon IconOne={ArrowRight} textOneOne="iGuru" textTwoOne="occupation" />
      </div>
      <div
        className="paddingCard"
        onClick={openSectionPopUp}
        data-value={'pulse'}
        data-id={'iGuru Marketplace_Pulse'}
        data-types-secondary={'iGuru Pulse'}
      >
        <Card isIcon IconOne={ArrowRight} textOneOne="iGuru" textTwoOne="pulse" />
      </div>
    </>
  );
};

export default DisplayPaneOneSectionThree;
