import React from 'react';
import VideoIcon from '../../images/VideoIcon.svg';

function Videoinfo() {
  return (
    <>
      <img
        className={'icon-button-displayPaneLeft'}
        src={VideoIcon}
        alt="VideoIcon"
        style={{ width: '60%', marginTop: '6px' }}
      />
    </>
  );
}

export default Videoinfo;
