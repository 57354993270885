import {
  SET_JOB_REDUCER_STATE,
  CLEAR_JOB_REDUCER_STATE,
  SET_JOB_DYNAMIC_SINGLE_STATE,
  SET_JOB_DYNAMIC_ARRAY_STATE,
  SET_JOB_SIFTLIST_STATE,
  SET_WEIGHTAGE_JOB_PROFILE,
  SET_JOB_COMPETENCY_WEIGHTAGE_LIST,
  SET_JOB_COMPETENCY_RANGE_LIST,
  SET_JOB_SETUP_INFO_STATE
} from '../actionType';

const initialState = {
  jobProfileInformation: {
    informationBasic: {
      jobProfileName: '',
      jobProfileNameVerification: false,
      jobProfileDescription: '',
      jobProfilePicture: '',
      jobProfilePictureVerification: false
    },
    informationAllocation: {
      jobProfileGroup: {
        jobProfileGroupPrimary: [],
        jobProfileGroupSecondary: []
      },
      jobProfileNode: {
        jobProfileNodePrimary: [],
        jobProfileNodeSecondary: []
      },
      jobProfileType: {
        jobProfileTypePrimary: [],
        jobProfileTypeSecondary: []
      }
    },
    informationSetup: {
      jobProfileShareFee: 0.0,
      jobProfileSellFee: 0.0,
      jobProfileMoveFee: 0.0,
    },
    informationFramework: {
      jobProfileJobDomain: [],
      jobProfileJobFunction: [],
      jobProfileJobRole: [],
      jobProfileJobCompetencySelectList:[],
      jobProfileJobCompetencyCore: [],
      jobProfileJobCompetencyCoreObj: [],
      jobProfileJobCompetencyShortlisted: [],
      jobProfileJobCompetencyShortList: [],
      jobProfileJobCompetencySiftList: [],
      jobProfileJobCompetencySiftRemovable: [],
      jobProfileJobCompetencyRange: [],
      jobProfileJobCompetencyWeightage: []
    }
  }
};

const JobProfileCreateReducer = (istate = JSON.parse(JSON.stringify(initialState)), action) => {
  // console.log(action.type);
  switch (action.type) {
    case SET_JOB_REDUCER_STATE:
      return {
        ...istate,
        jobProfileInformation: {
          ...istate.jobProfileInformation,
          informationBasic: action.payload
        }
      };
      case SET_JOB_SETUP_INFO_STATE:
        return {
          ...istate,
          jobProfileInformation: {
            ...istate.jobProfileInformation,
            informationSetup: action.payload
          }
        };
    case SET_JOB_DYNAMIC_SINGLE_STATE:
      return {
        ...istate,
        jobProfileInformation: {
          ...istate.jobProfileInformation,
          [action.payload.objectName]: {
            ...istate.jobProfileInformation[action.payload.objectName],
            [action.payload.stateName]: {
              ...istate.jobProfileInformation[action.payload.objectName][action.payload.stateName],
              [action.payload.actualStateName]: action.payload.value
            }
          }
        }
      };
    case SET_JOB_DYNAMIC_ARRAY_STATE:
      return {
        ...istate,
        jobProfileInformation: {
          ...istate.jobProfileInformation,
          [action.payload.objectName]: {
            ...istate.jobProfileInformation[action.payload.objectName],
            [action.payload.stateName]: action.payload.value
          }
        }
      };
    case SET_JOB_SIFTLIST_STATE:
      return {
        ...istate,
        jobProfileInformation: {
          ...istate.jobProfileInformation,
          informationFramework: action.payload
        }
      };
    case SET_WEIGHTAGE_JOB_PROFILE:
      let tempArr =
        istate?.jobProfileInformation?.informationFramework?.jobProfileJobCompetencyCore || [];
      tempArr.forEach((element) => {
        if (element.id === action.payload.id) {
          element.iGaugeOccupationFrameworkOneWeightage =
            action.payload.iGaugeOccupationFrameworkOneWeightage;
        }
      });
      let tempArr2 =
        istate?.jobProfileInformation?.informationFramework?.jobProfileJobCompetencyCoreObj || [];
      tempArr2.forEach((element) => {
        if (element.iGaugeOccupationFrameworkOneTag === action.payload.id) {
          element.iGaugeOccupationFrameworkOneWeightage =
            action.payload.iGaugeOccupationFrameworkOneWeightage;
        }
      });
      return {
        ...istate,
        jobProfileInformation: {
          ...istate.jobProfileInformation,
          informationFramework: {
            ...istate.jobProfileInformation.informationFramework,
            jobProfileJobCompetencyCore: tempArr,
            jobProfileJobCompetencyCoreObjF: tempArr2
          }
        }
      };
    case SET_JOB_COMPETENCY_WEIGHTAGE_LIST:
      return {
        ...istate,
        jobProfileInformation: {
          ...istate.jobProfileInformation,
          informationFramework: {
            ...istate.jobProfileInformation.informationFramework,
            jobProfileJobCompetencyWeightage: action.payload
          }
        }
      };
    case SET_JOB_COMPETENCY_RANGE_LIST:
      return {
        ...istate,
        jobProfileInformation: {
          ...istate.jobProfileInformation,
          informationFramework: {
            ...istate.jobProfileInformation.informationFramework,
            jobProfileJobCompetencyRange: action.payload
          }
        }
      };
    case CLEAR_JOB_REDUCER_STATE:
      return JSON.parse(JSON.stringify(initialState));
    default:
      return istate;
  }
};

export default JobProfileCreateReducer;
