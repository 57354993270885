import { put, takeLatest, call } from 'redux-saga/effects';
import {
  GET_CULTUREPROFILE_REVIEW_LIST_SAGA,
  LOADER_STOP,
  REVIEWLIST_DISTINCT_DATA,
  RELATED_REVIEWLIST_DISTINCT_DATA,
  SET_MIDDLEPANE_STATE,
  SET_POPUP_VALUE,
  CULTURE_GROUP_CULTURE_REVIEWLIST_SAGA,
  CULTURE_TYPE_CULTURE_REVIEWLIST_SAGA,
  SET_REVIEW_LIST_RELATE_DATA,
  GET_ALLOCATE_CULTURE,
  GET_CULTURE_NODE_CULTURE_REVIEW_LIST_SAGA,
  GET_CULTURE_DIAMENTION_SAGA,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_NEXT_POPUP,
  GET_ALLOCATE_CULTURE_GROUP,
  SET_MOBILE_PANE_STATE,
  SET_DISPLAY_PANE_THREE_STATE,
  SET_JOB_CULTURE_PROFILER_CLUSTER_REDUCER_STATE
} from '../../actionType';
import { CULTURE_MODULE_URL, JOB_MODULE_URL } from '../../endpoints';
import { EXCEPTION_ERROR_MESSAGE } from '../../errorMessage';
import Store from '../../store';

const apiCallFunction = async (requestObj) => {
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      Authorization: localStorage.getItem('idToken')
    }),
    body: JSON.stringify(requestObj.data)
  };
  const response = await fetch(requestObj.URL, requestOptions);
  const json = await response.json();
  return json;
};

function* workerCultureProfileReviewListSaga(data) {
  try {
    const response = yield call(apiCallFunction, {
      data: data.payload.request,
      URL: CULTURE_MODULE_URL
    });
    if (response.responseCode === '000') {
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: 'culture-profiles',
            middlePaneHeaderBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderBadgeThree: '',
            middlePaneHeaderBadgeFour: '',
            middlePaneHeaderDuplicate: 'culture-profiles',
            middlePaneHeaderDuplicateBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderDuplicateBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderDuplicateBadgeThree: '',
            middlePaneHeaderDuplicateBadgeFour: '',
            typeOfMiddlePaneList: data.payload.request.actionFilter ? 'marketPlaceCultureProfilesDistinctReviewList' : 'cultureProfilesDistinctReviewList',
            scanCount: response && response.countTotal,
            duplicateScanCount: response && response.countTotal,
            showMiddlePaneState: true,
            isSelectActive: data.payload.isSelectActive,
            selectedTagsArray: data.payload.selectedTagsArray
          }
        });
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: "previousMiddlePaneState", value: data.payload.request.actionFilter ? 'marketPlaceCultureProfilesDistinctReviewList' : 'cultureProfilesDistinctReviewList',
          },
        })
      }
      yield put({
        type: data.payload.isMiddlePaneList
          ? REVIEWLIST_DISTINCT_DATA
          : RELATED_REVIEWLIST_DISTINCT_DATA,
        payload: response.responseObject
      });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: response.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({ type: LOADER_STOP });
  }
}
function* workeCultureGroupCultureReviewListSaga(data) {
  try {
    const response = yield call(apiCallFunction, {
      data: data.payload.request,
      URL: CULTURE_MODULE_URL
    });
    // const response ={responseCode:'000',countTotal:30}
    if (response.responseCode === '000') {
      let responseObj = null;
      if (Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData.length > 0) {
        responseObj = {
          ...Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData[0],
          cultureProfile: [
            ...Store.getState().DisplayPaneTwoReducer?.relatedReviewListDistinctData[0]
              ?.cultureProfile,
            ...response.responseObject[0]?.cultureProfile
          ]
        };
        //to add list more and adding all the record
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
      } else {
        responseObj = response.responseObject[0];
      }
      yield put({
        type: RELATED_REVIEWLIST_DISTINCT_DATA,
        payload: [responseObj]
      });
      yield put({
        type: RELATED_REVIEWLIST_DISTINCT_DATA,
        payload: [response.responseObject[0]]
      });
      yield put({
        type: SET_REVIEW_LIST_RELATE_DATA,
        payload: response.responseObject
      });
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: data.payload.HeaderOne,
            middlePaneHeaderBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderBadgeThree: '',
            middlePaneHeaderBadgeFour: '',
            middlePaneHeaderDuplicate:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
            middlePaneHeaderDuplicateBadgeOne:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            middlePaneHeaderDuplicateBadgeTwo:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            middlePaneHeaderDuplicateBadgeThree:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
            middlePaneHeaderDuplicateBadgeFour:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour,
            typeOfMiddlePaneList: 'cultureProfileGroupCultureProfileReviewList',
            scanCount: response && response.countTotal,
            duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
            showMiddlePaneState: true,
            isSelectActive: data.payload.isSelectActive
          }
        });
      }
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: response.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }

    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workeCultureTypeCultureReviewListSaga(data) {
  try {
    const response = yield call(apiCallFunction, {
      data: data.payload.request,
      URL: CULTURE_MODULE_URL
    });
    // const response ={responseCode:'000',countTotal:30}
    if (response.responseCode === '000') {
      let responseObj = null;
      if (Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData.length > 0) {
        responseObj = {
          ...Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData[0],
          cultureProfile: [
            ...Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData[0]
              .cultureProfile,
            ...response.responseObject[0].cultureProfile
          ]
        };
        //to add list more and adding all the record
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
      } else {
        responseObj = response.responseObject[0];
      }
      yield put({
        type: RELATED_REVIEWLIST_DISTINCT_DATA,
        payload: [responseObj]
      });
      yield put({
        type: RELATED_REVIEWLIST_DISTINCT_DATA,
        payload: [response.responseObject[0]]
      });
      yield put({
        type: SET_REVIEW_LIST_RELATE_DATA,
        payload: response.responseObject
      });
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: data.payload.HeaderOne,
            middlePaneHeaderBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderBadgeThree: data.payload.BadgeThree,
            middlePaneHeaderBadgeFour: data.payload.BadgeFour,
            middlePaneHeaderDuplicate:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
            middlePaneHeaderDuplicateBadgeOne:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            middlePaneHeaderDuplicateBadgeTwo:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            middlePaneHeaderDuplicateBadgeThree:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
            middlePaneHeaderDuplicateBadgeFour:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour,
            typeOfMiddlePaneList: 'cultureProfileTypeCultureProfileReviewList',
            scanCount: response && response.countTotal,
            duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
            showMiddlePaneState: true
          }
        });
      }
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: response.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }

    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workeCultureNodeCultureReviewListSaga(data) {
  try {
    const response = yield call(apiCallFunction, {
      data: data.payload.request,
      URL: CULTURE_MODULE_URL
    });
    // const response ={responseCode:'000',countTotal:30}
    if (response.responseCode === '000') {
      let responseObj = null;
      if (
        Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData.length > 0 &&
        data.payload.isMiddlePaneList
      ) {
        responseObj = {
          ...Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData[0],
          cultureProfile: [
            ...Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData[0]
              ?.cultureProfile,
            ...response.responseObject[0].cultureProfile
          ]
        };
        //to add list more and adding all the record
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
      } else {
        responseObj = response.responseObject[0];
      }
      yield put({
        type: RELATED_REVIEWLIST_DISTINCT_DATA,
        payload: [responseObj]
      });
      // yield put({
      //   type: RELATED_REVIEWLIST_DISTINCT_DATA,
      //   payload: [response.responseObject[0]]
      // });
      // NEED TO CHECK ABOVE LINES COMMENTED..
      // yield put({ type: SET_REVIEW_LIST_RELATE_DATA, payload: response.responseObject });
      let relatedReviewListPaneThree2 = {
        ...Store.getState().DisplayPaneThreeReducer.relatedReviewListPaneThree,
        cultureProfile: response.responseObject[0].cultureProfile
      };

      yield put({
        type: SET_REVIEW_LIST_RELATE_DATA,
        payload: relatedReviewListPaneThree2
      });
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: data.payload.HeaderOne,
            middlePaneHeaderBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderBadgeThree: '',
            middlePaneHeaderBadgeFour: '',
            middlePaneHeaderDuplicate:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
            middlePaneHeaderDuplicateBadgeOne:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            middlePaneHeaderDuplicateBadgeTwo:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            middlePaneHeaderDuplicateBadgeThree:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
            middlePaneHeaderDuplicateBadgeFour:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour,
            typeOfMiddlePaneList: data.payload.request?.actionFilter === 'Shared-Culture-Profile-Node' ? 'MarketPlaceCultureProfileNodeCultureProfileDistinctReviewList' : 'cultureProfileNodeCultureProfileReviewList',
            scanCount: response && response.countTotal,
            duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
            showMiddlePaneState: true
          }
        });
      }
    } else {
      if(data.payload.isMiddlePaneList){
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: response.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    }

    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerReviewListCultureDimentionSaga(data) {
  try {
    const response = yield call(apiCallFunction, {
      data: data.payload.request,
      URL: JOB_MODULE_URL
    });
    if (response.responseCode === '000') {
      const { reviseMode = false, headerOne, typeOfMiddlePaneList, request } = data.payload;
      if (reviseMode) {
        let responseObj = {};
        if (
          headerOne === 'job-function' ||
          headerOne === 'job-domain' ||
          headerOne === 'job-role'
        ) {
          if (typeOfMiddlePaneList === 'jobDomainDistinctReviewList') {
            yield put({
              type: SET_DISPLAY_TWO_SINGLE_STATE,
              payload: { stateName: 'typeOfMiddlePaneList', value: '' }
            });
            const jobDomain = [];
            response?.responseObject &&
              response?.responseObject?.jobDomain.forEach((data) => {
                let obj = {
                  id: data.id,
                  textOne: data.iGaugeOccupationFrameworkOneClusterName,
                  textTwo: data.iGaugeOccupationFrameworkOneClusterDescription,
                  status: '',
                  cluster: data.iGaugeOccupationFrameworkOneClusterName,
                  explanation: data.iGaugeOccupationFrameworkOneClusterOneExplanation,
                  sequence: data.iGaugeOccupationFrameworkOneClusterSequence,
                  polarity: data.iGaugeOccupationFrameworkOneClusterOnePolarity
                };
                jobDomain.push(obj);
              });
            let relatedData =
              Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData[0];
            let responseReviewListObj = {
              profilerName: relatedData.profilerName,
              profilerDescription: relatedData.profilerDescription,
              ...relatedData,
              jobDomain: jobDomain,
              jobProfilerList: relatedData.jobProfilerList
            };
            yield put({
              type: SET_DISPLAY_TWO_SINGLE_STATE,
              payload: {
                stateName: 'relatedReviewListDistinctData',
                value: [responseReviewListObj]
              }
            });
            yield put({
              type: SET_DISPLAY_TWO_SINGLE_STATE,
              payload: {
                stateName: 'relatedReviewListDistinctDataDuplicate',
                value: [responseReviewListObj]
              }
            });
            yield put({
              type: SET_MIDDLEPANE_STATE,
              payload: {
                middlePaneHeader: 'job-domains',
                middlePaneHeaderBadgeOne: 'distinct',
                middlePaneHeaderBadgeTwo: 'all',
                middlePaneHeaderDuplicate:
                  Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
                middlePaneHeaderDuplicateBadgeOne:
                  Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
                middlePaneHeaderDuplicateBadgeTwo:
                  Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
                middlePaneHeaderDuplicateBadgeThree:
                  Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
                middlePaneHeaderDuplicateBadgeFour:
                  Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour,
                typeOfMiddlePaneList: 'jobDomainDistinctReviewList',
                scanCount: jobDomain.length,
                duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
                showMiddlePaneState: true
              }
            });
            let customresponseObj = response?.responseObject?.jobDomain.filter((x) => {
              return x.id === request.requestObject?.iGaugeOccupationFrameworkOne.id;
            });
            console.log('data.payload', data.payload);
            let resObj = {
              cluster: customresponseObj[0]?.iGaugeOccupationFrameworkOneClusterName,
              explanation: customresponseObj[0]?.iGaugeOccupationFrameworkOneClusterOneExplanation,
              sequence: customresponseObj[0].iGaugeOccupationFrameworkOneClusterSequence,
              id: customresponseObj[0]?.id,
              polarity: customresponseObj[0]?.iGaugeOccupationFrameworkOneClusterOnePolarity,
              status: '',
              textOne: customresponseObj[0]?.iGaugeOccupationFrameworkOneClusterName,
              textTwo: customresponseObj[0]?.iGaugeOccupationFrameworkOneClusterDescription
            };
            responseObj = [resObj];
          }
          if (typeOfMiddlePaneList === 'jobFunctionDistinctReviewList') {
            yield put({
              type: SET_DISPLAY_TWO_SINGLE_STATE,
              payload: { stateName: 'typeOfMiddlePaneList', value: '' }
            });
            const jobFunction = [];
            response?.responseObject &&
              response?.responseObject?.jobFunction.forEach((data) => {
                let obj = {
                  id: data.id,
                  textOne: data.iGaugeOccupationFrameworkOneClusterName,
                  textTwo: data.iGaugeOccupationFrameworkOneClusterDescription,
                  status: '',
                  cluster: data.iGaugeOccupationFrameworkOneClusterName,
                  explanation: data.iGaugeOccupationFrameworkOneClusterOneExplanation,
                  sequence: data.iGaugeOccupationFrameworkOneClusterSequence,
                  polarity: data.iGaugeOccupationFrameworkOneClusterOnePolarity
                };
                jobFunction.push(obj);
              });
            let relatedData =
              Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData[0];
            let responseReviewListObj = {
              profilerName: relatedData.profilerName,
              profilerDescription: relatedData.profilerDescription,
              ...relatedData,
              jobFunction: jobFunction,
              jobProfilerList: relatedData.jobProfilerList
            };
            yield put({
              type: SET_DISPLAY_TWO_SINGLE_STATE,
              payload: {
                stateName: 'relatedReviewListDistinctData',
                value: [responseReviewListObj]
              }
            });
            yield put({
              type: SET_MIDDLEPANE_STATE,
              payload: {
                middlePaneHeader: 'job-functions',
                middlePaneHeaderBadgeOne: 'distinct',
                middlePaneHeaderBadgeTwo: 'all',
                middlePaneHeaderDuplicate:
                  Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
                middlePaneHeaderDuplicateBadgeOne:
                  Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
                middlePaneHeaderDuplicateBadgeTwo:
                  Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
                middlePaneHeaderDuplicateBadgeThree:
                  Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
                middlePaneHeaderDuplicateBadgeFour:
                  Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour,
                typeOfMiddlePaneList: 'jobFunctionDistinctReviewList',
                scanCount: jobFunction.length,
                showMiddlePaneState: true
              }
            });
            let customresponseObj = response?.responseObject?.jobFunction.filter((x) => {
              return x.id === request.requestObject?.iGaugeOccupationFrameworkOne.id;
            });
            console.log('data.payload', data.payload);
            let resObj = {
              cluster: customresponseObj[0]?.iGaugeOccupationFrameworkOneClusterName,
              explanation: customresponseObj[0]?.iGaugeOccupationFrameworkOneClusterOneExplanation,
              sequence: customresponseObj[0].iGaugeOccupationFrameworkOneClusterSequence,
              id: customresponseObj[0]?.id,
              polarity: customresponseObj[0]?.iGaugeOccupationFrameworkOneClusterOnePolarity,
              status: '',
              textOne: customresponseObj[0]?.iGaugeOccupationFrameworkOneClusterName,
              textTwo: customresponseObj[0]?.iGaugeOccupationFrameworkOneClusterDescription
            };
            responseObj = [resObj];
          }
          if (typeOfMiddlePaneList === 'jobRoleDistinctReviewList') {
            yield put({
              type: SET_DISPLAY_TWO_SINGLE_STATE,
              payload: { stateName: 'typeOfMiddlePaneList', value: '' }
            });
            const jobRole = [];
            response?.responseObject &&
              response?.responseObject?.jobRole.forEach((data) => {
                let obj = {
                  id: data.id,
                  textOne: data.iGaugeOccupationFrameworkOneClusterName,
                  textTwo: data.iGaugeOccupationFrameworkOneClusterDescription,
                  status: '',
                  cluster: data.iGaugeOccupationFrameworkOneClusterName,
                  explanation: data.iGaugeOccupationFrameworkOneClusterOneExplanation,
                  sequence: data.iGaugeOccupationFrameworkOneClusterSequence,
                  polarity: data.iGaugeOccupationFrameworkOneClusterOnePolarity
                };
                jobRole.push(obj);
              });
            let relatedData =
              Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData[0];
            let responseReviewListObj = {
              profilerName: relatedData.profilerName,
              profilerDescription: relatedData.profilerDescription,
              ...relatedData,
              jobRole: jobRole,
              jobProfilerList: relatedData.jobProfilerList
            };
            console.log('responseReviewListObj', responseReviewListObj);
            yield put({
              type: SET_DISPLAY_TWO_SINGLE_STATE,
              payload: {
                stateName: 'relatedReviewListDistinctData',
                value: [responseReviewListObj]
              }
            });
            yield put({
              type: SET_MIDDLEPANE_STATE,
              payload: {
                middlePaneHeader: 'job-Roles',
                middlePaneHeaderBadgeOne: 'distinct',
                middlePaneHeaderBadgeTwo: 'all',
                middlePaneHeaderDuplicate:
                  Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
                middlePaneHeaderDuplicateBadgeOne:
                  Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
                middlePaneHeaderDuplicateBadgeTwo:
                  Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
                middlePaneHeaderDuplicateBadgeThree:
                  Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
                middlePaneHeaderDuplicateBadgeFour:
                  Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour,
                typeOfMiddlePaneList: 'jobRoleDistinctReviewList',
                scanCount: jobRole.length,
                showMiddlePaneState: true
              }
            });
            let customresponseObj = response?.responseObject?.jobRole.filter((x) => {
              return x.id === request.requestObject?.iGaugeOccupationFrameworkOne.id;
            });
            console.log('data.payload', data.payload);
            let resObj = {
              cluster: customresponseObj[0]?.iGaugeOccupationFrameworkOneClusterName,
              explanation: customresponseObj[0]?.iGaugeOccupationFrameworkOneClusterOneExplanation,
              sequence: customresponseObj[0].iGaugeOccupationFrameworkOneClusterSequence,
              id: customresponseObj[0]?.id,
              polarity: customresponseObj[0]?.iGaugeOccupationFrameworkOneClusterOnePolarity,
              status: '',
              textOne: customresponseObj[0]?.iGaugeOccupationFrameworkOneClusterName,
              textTwo: customresponseObj[0]?.iGaugeOccupationFrameworkOneClusterDescription
            };
            responseObj = [resObj];
          }
        } else if (headerOne === 'culture-dimension') {
          // responseObj = {
          //   cluster: response.responseObject[0].iGaugeOccupationFrameworkOneClusterName,
          //   explanation:
          //     response.responseObject[0].iGaugeOccupationFrameworkOneClusterOneExplanation,
          //   sequence: response.responseObject[0].iGaugeOccupationFrameworkOneClusterSequence,
          //   id: response.responseObject[0].id,
          //   polarity: response.responseObject[0].iGaugeOccupationFrameworkOneClusterOnePolarity,
          //   status: '',
          //   name: response.responseObject[0].iGaugeOccupationFrameworkOneClusterName,
          //   description: response.responseObject[0].iGaugeOccupationFrameworkOneClusterDescription
          // };
          // responseObj = response.responseObject?.cultureDimension.filter((x) => {
          //   return (
          //     x.iGaugeOccupationFrameworkOneClusterOneLabel ===
          //       request.requestObject?.iGaugeOccupationFrameworkOne.id ||
          //     x.iGaugeOccupationFrameworkOneClusterTwoLabel ===
          //       request.requestObject?.iGaugeOccupationFrameworkOne.id
          //   );
          // });
          // yield put({
          //   type: SET_JOB_CULTURE_PROFILER_CLUSTER_REDUCER_STATE,
          //   payload: responseObj
          // });
          if (typeOfMiddlePaneList === 'cultureDimensionsDistinctReviewList') {
            yield put({
              type: SET_DISPLAY_TWO_SINGLE_STATE,
              payload: { stateName: 'typeOfMiddlePaneList', value: '' }
            });
            const culturedimension = [];
            response?.responseObject &&
              response?.responseObject?.cultureDimension.forEach((data) => {
                let obj = {
                  id: data.id,
                  textOne: data.iGaugeOccupationFrameworkOneClusterName,
                  textTwo: data.iGaugeOccupationFrameworkOneClusterDescription,
                  status: '',
                  cluster: data.iGaugeOccupationFrameworkOneClusterName,
                  explanation: data.iGaugeOccupationFrameworkOneClusterOneExplanation,
                  sequence: data.iGaugeOccupationFrameworkOneClusterSequence,
                  polarity: data.iGaugeOccupationFrameworkOneClusterOnePolarity,
                  originDBId: data.id
                };
                culturedimension.push(obj);
              });
            let relatedData =
              Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData[0];
            let responseReviewListObj = {
              profilerName: relatedData.profilerName,
              profilerDescription: relatedData.profilerDescription,
              ...relatedData,
              // clustersDimension: response.responseObject?.cultureDimension
              clustersDimension: culturedimension
            };
            console.log('responseReviewListObj', responseReviewListObj);
            yield put({
              type: SET_DISPLAY_TWO_SINGLE_STATE,
              payload: {
                stateName: 'relatedReviewListDistinctData',
                value: [responseReviewListObj]
              }
            });
            yield put({
              type: SET_MIDDLEPANE_STATE,
              payload: {
                middlePaneHeader: 'culture-dimensions',
                middlePaneHeaderBadgeOne: 'distinct',
                middlePaneHeaderBadgeTwo: 'all',
                middlePaneHeaderDuplicate:
                  Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
                middlePaneHeaderDuplicateBadgeOne:
                  Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
                middlePaneHeaderDuplicateBadgeTwo:
                  Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
                middlePaneHeaderDuplicateBadgeThree:
                  Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
                middlePaneHeaderDuplicateBadgeFour:
                  Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour,
                typeOfMiddlePaneList: 'cultureDimensionsDistinctReviewList',
                scanCount: response.responseObject?.cultureDimension.length,
                showMiddlePaneState: true
              }
            });
            let cultureDimensionsObj = response.responseObject?.cultureDimension.filter((x) => {
              return (
                x.iGaugeOccupationFrameworkOneClusterName ===
                request.requestObject?.iGaugeOccupationFrameworkOne
                  .iGaugeOccupationFrameworkOneClusterName
              );
            });
            console.log(
              'response.responseObject?.cultureDimension',
              response.responseObject?.cultureDimension
            );
            console.log('cultureDimensionsObj', cultureDimensionsObj);
            console.log('data.payload', data.payload);
            let id;
            let description;
            let polarity;
            let name;
            if (
              data.payload.selectedTag ===
              cultureDimensionsObj[0].iGaugeOccupationFrameworkOneClusterOneLabel
            ) {
              id = cultureDimensionsObj[0].iGaugeOccupationFrameworkOneClusterOneLabel;
              description =
                cultureDimensionsObj[0].iGaugeOccupationFrameworkOneClusterOneDescription;
              polarity = cultureDimensionsObj[0].iGaugeOccupationFrameworkOneClusterOnePolarity;
              name = cultureDimensionsObj[0].iGaugeOccupationFrameworkOneClusterOneLabel;
            }
            if (
              data.payload.selectedTag ===
              cultureDimensionsObj[0].iGaugeOccupationFrameworkOneClusterTwoLabel
            ) {
              id = cultureDimensionsObj[0].iGaugeOccupationFrameworkOneClusterTwoLabel;
              description =
                cultureDimensionsObj[0].iGaugeOccupationFrameworkOneClusterTwoDescription;
              polarity = cultureDimensionsObj[0].iGaugeOccupationFrameworkOneClusterTwoPolarity;
              name = cultureDimensionsObj[0].iGaugeOccupationFrameworkOneClusterTwoLabel;
            }
            let resObj = {
              cluster: cultureDimensionsObj[0].iGaugeOccupationFrameworkOneClusterName,
              explanation:
                cultureDimensionsObj[0].iGaugeOccupationFrameworkOneClusterOneExplanation,
              sequence: cultureDimensionsObj[0].iGaugeOccupationFrameworkOneClusterSequence,
              id: id,
              polarity: polarity,
              status: '',
              name: name,
              description: description
            };
            console.log('resObj', resObj);
            responseObj = [resObj];
            yield put({
              type: SET_JOB_CULTURE_PROFILER_CLUSTER_REDUCER_STATE,
              payload: responseObj
            });
          }
        } else if (headerOne === 'job-competency') {
          if (typeOfMiddlePaneList === 'jobCometancyDistinctReviewList') {
            yield put({
              type: SET_DISPLAY_TWO_SINGLE_STATE,
              payload: { stateName: 'typeOfMiddlePaneList', value: '' }
            });
            const jobCometancy = [];
            response.responseObject?.jobCompetency &&
              response.responseObject?.jobCompetency.forEach((data) => {
                let obj = {
                  id: data.id,
                  textOne: data.iGaugeOccupationFrameworkOneClusterName,
                  textTwo: data.iGaugeOccupationFrameworkOneClusterDescription,
                  status: '',
                  explanation: data.iGaugeOccupationFrameworkOneClusterOneExplanation,
                  sequence: data.iGaugeOccupationFrameworkOneClusterSequence,
                  cluster: data.iGaugeOccupationFrameworkOneClusterName,
                  polarity: data.iGaugeOccupationFrameworkOneClusterOnePolarity
                };
                jobCometancy.push(obj);
              });
            let relatedData =
              Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData[0];
            let responseReviewListObj = {
              profilerName: relatedData.profilerName,
              profilerDescription: relatedData.profilerDescription,
              ...relatedData,
              jobCometancy: jobCometancy,
              jobProfilerList: relatedData.jobProfilerList
            };
            yield put({
              type: SET_DISPLAY_TWO_SINGLE_STATE,
              payload: {
                stateName: 'relatedReviewListDistinctData',
                value: [responseReviewListObj]
              }
            });
            yield put({
              type: SET_DISPLAY_TWO_SINGLE_STATE,
              payload: {
                stateName: 'relatedReviewListDistinctDataDuplicate',
                value: [responseReviewListObj]
              }
            });
            yield put({
              type: SET_MIDDLEPANE_STATE,
              payload: {
                middlePaneHeader: 'job-competencies',
                middlePaneHeaderBadgeOne: 'distinct',
                middlePaneHeaderBadgeTwo: 'all',
                middlePaneHeaderDuplicate:
                  Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
                middlePaneHeaderDuplicateBadgeOne:
                  Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
                middlePaneHeaderDuplicateBadgeTwo:
                  Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
                middlePaneHeaderDuplicateBadgeThree:
                  Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
                middlePaneHeaderDuplicateBadgeFour:
                  Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour,
                typeOfMiddlePaneList: 'jobCometancyDistinctReviewList',
                scanCount: response.responseObject?.jobCompetency.length,
                showMiddlePaneState: true
              }
            });
            let customresponseObj = response?.responseObject?.jobCompetency.filter((x) => {
              return x.id === request.requestObject?.iGaugeOccupationFrameworkOne.id;
            });
            console.log('customresponseObj', customresponseObj);
            console.log('data.payload', data.payload);
            let resObj = {
              cluster: customresponseObj[0].iGaugeOccupationFrameworkOneClusterName,
              explanation: customresponseObj[0].iGaugeOccupationFrameworkOneClusterOneExplanation,
              id: customresponseObj[0].id,
              polarity: customresponseObj[0].iGaugeOccupationFrameworkOneClusterOnePolarity,
              sequence: customresponseObj[0].iGaugeOccupationFrameworkOneClusterSequence,
              status: '',
              textOne: customresponseObj[0].iGaugeOccupationFrameworkOneClusterName,
              textTwo: customresponseObj[0].iGaugeOccupationFrameworkOneClusterDescription
            };
            responseObj = [resObj];
            console.log('resObj', resObj);
            console.log('responseObj', responseObj);
          }
        } else {
          //responseObj = response.responseObject?.cultureDimension[selectedTag];
          responseObj = response.responseObject?.cultureDimension.filter((x) => {
            return x.id === request.requestObject?.iGaugeOccupationFrameworkOne.id;
          });
          console.log('data.payload', data.payload);
          console.log('responseObj', responseObj);
          yield put({
            type: SET_JOB_CULTURE_PROFILER_CLUSTER_REDUCER_STATE,
            payload: responseObj
          });
          if (typeOfMiddlePaneList === 'clusterDistinctReviewList') {
            yield put({
              type: SET_DISPLAY_TWO_SINGLE_STATE,
              payload: { stateName: 'typeOfMiddlePaneList', value: '' }
            });
            let relatedData =
              Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData[0];
            let responseObj = {
              profilerName: relatedData.profilerName,
              profilerDescription: relatedData.profilerDescription,
              ...relatedData,
              clustersDimension: response.responseObject?.cultureDimension
            };
            yield put({
              type: SET_DISPLAY_TWO_SINGLE_STATE,
              payload: { stateName: 'relatedReviewListDistinctData', value: [responseObj] }
            });
            yield put({
              type: SET_MIDDLEPANE_STATE,
              payload: {
                middlePaneHeader: 'clusters',
                middlePaneHeaderBadgeOne: 'distinct',
                middlePaneHeaderBadgeTwo: 'all',
                middlePaneHeaderDuplicate:
                  Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
                middlePaneHeaderDuplicateBadgeOne:
                  Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
                middlePaneHeaderDuplicateBadgeTwo:
                  Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
                middlePaneHeaderDuplicateBadgeThree:
                  Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
                middlePaneHeaderDuplicateBadgeFour:
                  Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour,
                typeOfMiddlePaneList: 'clusterDistinctReviewList',
                scanCount: response.responseObject?.cultureDimension.length,
                duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
                showMiddlePaneState: true
              }
            });
          }
        }
        console.log('responseObj1', responseObj);
        yield put({
          type: SET_DISPLAY_PANE_THREE_STATE,
          payload: {
            headerOne: headerOne,
            headerOneBadgeOne: 'distinct',
            headerOneBadgeTwo: 'information',
            headerOneBadgeThree: 'all',
            responseObject: responseObj[0],
            reviewMode: ''
          }
        });
      } else {
        let object = [];
        response.responseObject?.cultureDimension.forEach((ele) => {
          //
          let obj = {
            group: ele.id,
            cultureDimensions: [
              {
                id: ele?.iGaugeOccupationFrameworkOneClusterOneLabel,
                label: ele?.iGaugeOccupationFrameworkOneClusterOneLabel,
                description: ele?.iGaugeOccupationFrameworkOneClusterOneDescription,
                labelDescription: ele?.iGaugeOccupationFrameworkOneClusterOneDescription,
                labelExplanation: ele?.iGaugeOccupationFrameworkOneClusterOneExplanation,
                labelCluster: ele?.iGaugeOccupationFrameworkOneClusterName,
                // labelPolarity: ele?.iGaugeOccupationFrameworkOneClusterOnePolarity,
                tag: ele?.id
              },
              {
                id: ele?.iGaugeOccupationFrameworkOneClusterTwoLabel,
                label: ele?.iGaugeOccupationFrameworkOneClusterTwoLabel,
                description: ele?.iGaugeOccupationFrameworkOneClusterTwoDescription,
                labelDescription: ele?.iGaugeOccupationFrameworkOneClusterTwoDescription,
                labelExplanation: ele?.iGaugeOccupationFrameworkOneClusterTwoExplanation,
                labelCluster: ele?.iGaugeOccupationFrameworkOneClusterName,
                // labelPolarity: ele?.iGaugeOccupationFrameworkOneClusterTwoPolarity,
                tag: ele?.id
              }
            ]
          };
          object.push(obj);
        });
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'cultureProfileDiamentionReviewList',
            value: object
          }
        });
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'relatedReviewListDistinctDataDuplicate',
            value: [response.responseObject]
          }
        });
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'clusterProfiler',
            value: response.responseObject?.cultureDimension
          }
        });
        let templist = {
          jobDomain: response.responseObject?.jobDomain,
          jobFunction: response.responseObject?.jobFunction,
          jobCompetency: response.responseObject?.jobCompetency,
          jobRole: response.responseObject?.jobRole
        };
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'jobProfilerReviewList', value: templist }
        });
        yield put({ type: SET_NEXT_POPUP, payload: { isPopUpValue: 'POPUPDIAMENTIONMSG' } });
      }
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: response.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}

function* workerReviewListCultureProfileAllocateSaga(data) {
  try {
    const userResponse = yield call(apiCallFunction, {
      data: data.payload.request,
      URL: CULTURE_MODULE_URL
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === '000') {
      let responseObj = null;
      if (Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData.length > 0) {
        responseObj = {
          ...data.payload.revisedGroupObject,
          cultureProfile: [
            ...Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData[0]
              .cultureProfile,
            ...userResponse.responseObject
          ]
        };
        //to add list more and adding all the record
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
      } else {
        responseObj = {
          ...data.payload.revisedGroupObject,
          cultureProfile: userResponse.responseObject
        };
      }
      yield put({
        type: SET_MIDDLEPANE_STATE,
        payload: {
          middlePaneHeader: data.payload.headerOne || 'culture-profiles',
          middlePaneHeaderBadgeOne: 'distinct',
          middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
          middlePaneHeaderBadgeThree: '',
          middlePaneHeaderBadgeFour: '',
          middlePaneHeaderDuplicate:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
          middlePaneHeaderDuplicateBadgeOne:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
          middlePaneHeaderDuplicateBadgeTwo:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
          middlePaneHeaderDuplicateBadgeThree:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree, //
          middlePaneHeaderDuplicateBadgeFour:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour, //
          typeOfMiddlePaneList: data.payload.typeOfMiddlePaneList,
          scanCount: userResponse && userResponse.countTotal,
          duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
          showMiddlePaneState: true,
          isSelectActive: 'multiple',
          selectedTagsArray: data.payload.existingCultureProfileId,
          unselectedTagsArray: data.payload.unselectedTagsArray
        }
      });
      yield put({ type: RELATED_REVIEWLIST_DISTINCT_DATA, payload: [responseObj] });
      yield put({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({ type: LOADER_STOP });
  }
}
function* workerReviewListCultureProfileGroupAllocateSaga(data) {
  try {
    const userResponse = yield call(apiCallFunction, {
      data: data.payload.request,
      URL: CULTURE_MODULE_URL
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === '000') {
      let responseObj = {
        ...data.payload.revisedGroupObject,
        cultureProfileGroup: userResponse.responseObject
      };
      yield put({
        type: SET_MIDDLEPANE_STATE,
        payload: {
          middlePaneHeader: data.payload.headerOne || 'culture-profiles',
          middlePaneHeaderBadgeOne: 'group',
          middlePaneHeaderBadgeTwo: 'active',
          middlePaneHeaderBadgeThree: '',
          middlePaneHeaderBadgeFour: '',
          middlePaneHeaderDuplicateBadgeOne:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
          middlePaneHeaderDuplicateBadgeTwo:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
          middlePaneHeaderDuplicateBadgeThree: '',
          middlePaneHeaderDuplicateBadgeFour: '',
          typeOfMiddlePaneList: data.payload.typeOfMiddlePaneList,
          scanCount: userResponse && userResponse.countTotal,
          duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
          showMiddlePaneState: true,
          isSelectActive: 'multiple',
          selectedTagsArray: data.payload.existingCultureProfileId
        }
      });
      yield put({ type: RELATED_REVIEWLIST_DISTINCT_DATA, payload: [responseObj] });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({ type: LOADER_STOP });
  }
}

export default function* watchReviewListCultureProfileSaga() {
  yield takeLatest(GET_CULTUREPROFILE_REVIEW_LIST_SAGA, workerCultureProfileReviewListSaga);
  yield takeLatest(CULTURE_GROUP_CULTURE_REVIEWLIST_SAGA, workeCultureGroupCultureReviewListSaga);
  yield takeLatest(CULTURE_TYPE_CULTURE_REVIEWLIST_SAGA, workeCultureTypeCultureReviewListSaga);
  yield takeLatest(GET_ALLOCATE_CULTURE, workerReviewListCultureProfileAllocateSaga);
  yield takeLatest(GET_ALLOCATE_CULTURE_GROUP, workerReviewListCultureProfileGroupAllocateSaga);
  yield takeLatest(GET_CULTURE_DIAMENTION_SAGA, workerReviewListCultureDimentionSaga);
  yield takeLatest(
    GET_CULTURE_NODE_CULTURE_REVIEW_LIST_SAGA,
    workeCultureNodeCultureReviewListSaga
  );
}
