import React, { useContext, useEffect, useState } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import Popup from '../Molecules/PopUp/PopUp';
import PopupHeader from '../Molecules/PopUp/PopUpHeader';
import '../Molecules/PopUp/PopUp.css';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { LOADER_START, SET_ASSESSEE_REVISE_PASSWORD } from '../actionType';
import { FormControl } from '@material-ui/core';
import InputFeild from '../Atoms/InputField/InputField';
import { useHistory } from 'react-router-dom';
import {
  INCORRECT_INFORMATION_ERROR_MESSAGE,
  INFORMATION_MISMATCHED_ERROR_MESSAGE,
  INVALID_PASSWORD_ERROR_MESSAGE,
  LOWER_CHARACTER_REQUIRED_ERROR,
  MINIMUM_8_CHARACTER_ERROR,
  NUMBER_REQUIRED_ERROR,
  REQUIRED_ERROR_MESSAGE,
  SPECIAL_CHARACTER_REQUIRED_ERROR,
  UPPER_CHARACTER_REQUIRED_ERROR
} from '../errorMessage';
import { SIGN_IN_URL } from '../endpoints';
import InputFieldPassword from '../Atoms/InputField/InputFieldPassword';

const PopUpAssesseePassword = (props) => {
  const dispatch = useDispatch();
  const {
    isActive = false,
    headerPanelColour = 'genericOne',
    headerOne = 'assessee',
    headerOneBadgeOne = 'self',
    headerOneBadgeTwo = 'password',
    headerOneBadgeThree = 'revise'
  } = props;
  const [currentPassword, setCurrentPassword] = useState('');
  const [currentPasswordError, setCurrentPasswordError] = useState('');
  const [revisedPassword, setRevisedPassword] = useState('');
  const [revisedPasswordError, setRevisedPasswordError] = useState('');
  const [confirmRevisedPassword, setConfirmRevisedPassword] = useState('');
  const [confirmRevisedPasswordError, setConfirmRevisedPasswordError] = useState('');
  const history = useHistory();
  const { assesseeConfirmStatus } = useSelector((state) => state.UserReducer);
  useEffect(() => {
    if (assesseeConfirmStatus === 'passwordReviseSuccess') {
      let path = SIGN_IN_URL;
      history.push(path);
    }
    if (assesseeConfirmStatus === 'incorrectPassword') {
      setCurrentPasswordError(INCORRECT_INFORMATION_ERROR_MESSAGE);
    }
  }, [assesseeConfirmStatus, history]);
  const handleClick = () => {
    //according to creation mode popup sequence will change
    setConfirmRevisedPasswordError('');
    setRevisedPasswordError('');
    setCurrentPasswordError('');
    const passwordRegExp = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!{~:<-_?>}@#$%^&*])(?=.{8,})'
    );
    const numberRegExp = new RegExp('[0-9]');
    const capitalLetterRegExp = new RegExp('[A-Z]');
    const smallLetterRegExp = new RegExp('[a-z]');
    var symbolLetterRegExp = /[!"#$%&'()*+.\/:;<=>?@\[\\\]^_`{|}~-]/;
    let isValidation = true;
    console.log('revisedPassword', revisedPassword);
    console.log('confirmRevisedPassword', confirmRevisedPassword);
    if (currentPassword !== '' && revisedPassword !== '' && confirmRevisedPassword !== '') {
      // if (!passwordRegExp.test(revisedPassword)) {
      //   setRevisedPasswordError(INVALID_PASSWORD_ERROR_MESSAGE);
      //   setConfirmRevisedPasswordError(INVALID_PASSWORD_ERROR_MESSAGE);
      //   return;
      // }
      if (revisedPassword !== confirmRevisedPassword) {
        console.log('1');
        setRevisedPasswordError(INFORMATION_MISMATCHED_ERROR_MESSAGE);
        setConfirmRevisedPasswordError(INFORMATION_MISMATCHED_ERROR_MESSAGE);
      }
      else {
        //for revise passaword
        console.log('12');
        if (!numberRegExp.test(revisedPassword)) {
          setRevisedPasswordError(NUMBER_REQUIRED_ERROR);
          isValidation = false;
        }
        if (!smallLetterRegExp.test(revisedPassword)) {
          setRevisedPasswordError(LOWER_CHARACTER_REQUIRED_ERROR);
          isValidation = false;
        }
        if (!capitalLetterRegExp.test(revisedPassword)) {
          setRevisedPasswordError(UPPER_CHARACTER_REQUIRED_ERROR);
          isValidation = false;
        }
        if (!symbolLetterRegExp.test(revisedPassword)) {
          setRevisedPasswordError(SPECIAL_CHARACTER_REQUIRED_ERROR);
          isValidation = false;
        }
        if (revisedPassword.length < 8) {
          setRevisedPasswordError(MINIMUM_8_CHARACTER_ERROR);
          isValidation = false;
        }
        //for confirm pws
        if (!numberRegExp.test(confirmRevisedPassword)) {
          setConfirmRevisedPasswordError(NUMBER_REQUIRED_ERROR);
          isValidation = false;
        }
        if (!smallLetterRegExp.test(confirmRevisedPassword)) {
          setConfirmRevisedPasswordError(LOWER_CHARACTER_REQUIRED_ERROR);
          isValidation = false;
        }
        if (!capitalLetterRegExp.test(confirmRevisedPassword)) {
          setConfirmRevisedPasswordError(UPPER_CHARACTER_REQUIRED_ERROR);
          isValidation = false;
        }
        if (!symbolLetterRegExp.test(confirmRevisedPassword)) {
          setConfirmRevisedPasswordError(SPECIAL_CHARACTER_REQUIRED_ERROR);
          isValidation = false;
        }
        if (confirmRevisedPassword.length < 8) {
          setConfirmRevisedPasswordError(MINIMUM_8_CHARACTER_ERROR);
          isValidation = false;
        }
        if (isValidation) {
          setRevisedPasswordError('');
          setConfirmRevisedPasswordError('');
          // TODO: call change password method in aws cognito
          dispatch({ type: LOADER_START });
          dispatch({
            type: SET_ASSESSEE_REVISE_PASSWORD,
            payload: {
              reqObj: {
                prevPssword: currentPassword,
                newPassword: revisedPassword
              }
            }
          });
          console.log('========', currentPassword, revisedPassword, confirmRevisedPassword);
        }
      }

    }
    else {
      if (currentPassword === '') {
        setCurrentPasswordError(REQUIRED_ERROR_MESSAGE);
      }
      if (revisedPassword === '') {
        setRevisedPasswordError(REQUIRED_ERROR_MESSAGE);
      }
      if (confirmRevisedPassword === '') {
        setConfirmRevisedPasswordError(REQUIRED_ERROR_MESSAGE);
      }
      console.log('ALL Field requred');
    }
    // dispatch({ type: POPUP_CLOSE });
  };

  return (
    <div>
      <Popup isActive={isActive}>
        <PopupHeader
          headerPanelColour={headerPanelColour}
          headerOne={headerOne}
          headerOneBadgeOne={headerOneBadgeOne}
          headerOneBadgeTwo={headerOneBadgeTwo}
          headerOneBadgeThree={headerOneBadgeThree}
          onClick={handleClick}
        />
        <DialogContent
          className={['popupContent', 'fixed10PadDim', 'revisePopupContent'].join(' ')}
        >
          <FormControl style={{ width: '100%' }}>
            <InputFieldPassword
              id={'current password'}
              value={currentPassword}
              label={'password'}
              labelBadgeOne={'current'}
              type="password"
              errorMsg={currentPasswordError}
              onClick={(e) => {
                setCurrentPassword(e.target.value);
              }}
            />
            <InputFieldPassword
              id={'revised password'}
              value={revisedPassword}
              type="password"
              label={'password'}
              labelBadgeOne={'revised'}
              errorMsg={revisedPasswordError}
              onClick={(e) => {
                setRevisedPassword(e.target.value)
                setRevisedPasswordError('')
              }}
            />
            <InputFieldPassword
              id={'revised password'}
              value={confirmRevisedPassword}
              type="password"
              label={'password'}
              labelBadgeOne={'revised'}
              errorMsg={confirmRevisedPasswordError}
              onClick={(e) => {
                setConfirmRevisedPassword(e.target.value)
                setConfirmRevisedPasswordError('')
              }}
            />
          </FormControl>
        </DialogContent>
      </Popup>
    </div>
  );
};
PopUpAssesseePassword.propTypes = {
  className: PropTypes.string,
  headerPanelColour: PropTypes.oneOf([
    'displayPaneLeft',
    'displayPaneCentre',
    'displayPaneRight',
    'genericOne',
    'genericTwo'
  ]),
  headerOne: PropTypes.string,
  headerOneBadgeOne: PropTypes.string,
  headerOneBadgeTwo: PropTypes.string,
  headerOneBadgeThree: PropTypes.string,
  isActive: PropTypes.bool
};
export default PopUpAssesseePassword;
