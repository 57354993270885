import { put, takeLatest, call } from 'redux-saga/effects';
import {
  CREATE_TYPE_SAGA,
  LOADER_STOP,
  POPUP_CLOSE,
  SET_ASSESSEE_TYPE_REDUCER_STATE,
  SET_ASSESSMENT_TYPE_REDUCER_STATE,
  SET_ASSIGNMENT_TYPE_REDUCER_STATE,
  SET_ASSOCIATE_TYPE_REDUCER_STATE,
  SET_CULTURE_TYPE_REDUCER_STATE,
  SET_DISPLAY_PANE_THREE_STATE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_ITEM_TYPE_REDUCER_STATE,
  SET_JOB_TYPE_REDUCER_STATE,
  SET_MOBILE_PANE_STATE,
  SET_POPUP_SINGLE_STATE,
  SET_POPUP_VALUE
} from '../../actionType';
import {
  ASSESSEE_MODULE_URL,
  ASSESSMENT_MODULE_URL,
  ASSIGNMENT_MODULE_URL,
  ASSOCIATE_MODULE_URL,
  CULTURE_MODULE_URL,
  ITEM_MODULE_URL,
  JOB_MODULE_URL
} from '../../endpoints';
import { EXCEPTION_ERROR_MESSAGE, INFORMATION_EXIST_ERROR_MESSAGE } from '../../errorMessage';
import { NODE_ROLE_TYPE_REVIEW_LIST_POPUP_OPTION } from '../../PopUpConfig';

const createTypeApi = async (requestObj) => {
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      Authorization:
        requestObj.data.whichTypeCreate === 'culture-profiles' ||
        requestObj.data.whichTypeCreate === 'job-profiles'
          ? localStorage.getItem('idToken')
          : localStorage.getItem('token')
    }),
    body: JSON.stringify(requestObj.data)
  };
  let URL = '';
  if (requestObj.data.whichTypeCreate === 'assessees') {
    URL = ASSESSEE_MODULE_URL;
  }
  if (requestObj.data.whichTypeCreate === 'assessments') {
    URL = ASSESSMENT_MODULE_URL;
  }
  if (requestObj.data.whichTypeCreate === 'assignments') {
    URL = ASSIGNMENT_MODULE_URL;
  }
  if (requestObj.data.whichTypeCreate === 'associates') {
    URL = ASSOCIATE_MODULE_URL;
  }
  if (requestObj.data.whichTypeCreate === 'items') {
    URL = ITEM_MODULE_URL;
  }
  if (requestObj.data.whichTypeCreate === 'culture-profiles') {
    URL = CULTURE_MODULE_URL;
  }
  if (requestObj.data.whichTypeCreate === 'job-profiles') {
    URL = JOB_MODULE_URL;
  }

  const response = await fetch(URL, requestOptions);
  const json = await response.json();
  return json;
};

function* workerCreateTypeSaga(data) {
  try {
    const userResponse = yield call(createTypeApi, { data: data.payload });
    
    if (userResponse.responseCode === '000') {
      const { previousHeaderOne } = data.payload;
      console.log('loading end', data.payload.whichTypeCreate);
      yield put({
        type: SET_DISPLAY_PANE_THREE_STATE,
        payload: {
          headerOne: previousHeaderOne ? previousHeaderOne : data.payload.whichTypeCreate,
          headerOneBadgeOne: 'type',
          headerOneBadgeTwo: 'information',
          headerOneBadgeThree: 'key',
          responseObject: userResponse.responseObject[0],
          reviewMode: 'revise',
          createMode: `${data.payload.whichTypeCreate}Type`
        }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'middlePaneSelectedValue', value: userResponse.responseObject[0].id }
      });
      yield put({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'selectedTagValue', value: userResponse.responseObject[0].id }
      });
      if (data.payload.whichTypeCreate === 'assessees') {
        yield put({
          type: SET_ASSESSEE_TYPE_REDUCER_STATE,
          payload: userResponse.responseObject[0].informationBasic
        });
        yield put({
          type: SET_POPUP_SINGLE_STATE,
          payload: {
            stateName: 'selectedTagStatus',
            value: userResponse.responseObject[0].informationEngagement?.assesseeTypeStatus
          }
        });
      }
      if (data.payload.whichTypeCreate === 'assessments') {
        yield put({
          type: SET_ASSESSMENT_TYPE_REDUCER_STATE,
          payload: userResponse.responseObject[0].informationBasic
        });
        yield put({
          type: SET_POPUP_SINGLE_STATE,
          payload: {
            stateName: 'selectedTagStatus',
            value: userResponse.responseObject[0].informationEngagement?.assessmentTypeStatus
          }
        });
      }
      if (data.payload.whichTypeCreate === 'assignments') {
        yield put({
          type: SET_ASSIGNMENT_TYPE_REDUCER_STATE,
          payload: userResponse.responseObject[0].informationBasic
        });
        yield put({
          type: SET_POPUP_SINGLE_STATE,
          payload: {
            stateName: 'selectedTagStatus',
            value: userResponse.responseObject[0].informationEngagement?.assignmentTypeStatus
          }
        });
      }
      if (data.payload.whichTypeCreate === 'associates') {
        yield put({
          type: SET_ASSOCIATE_TYPE_REDUCER_STATE,
          payload: userResponse.responseObject[0].informationBasic
        });
        yield put({
          type: SET_POPUP_SINGLE_STATE,
          payload: {
            stateName: 'selectedTagStatus',
            value: userResponse.responseObject[0].informationEngagement?.associateTypeStatus
          }
        });
      }
      if (data.payload.whichTypeCreate === 'items') {
        yield put({
          type: SET_ITEM_TYPE_REDUCER_STATE,
          payload: userResponse.responseObject[0].informationBasic
        });
        yield put({
          type: SET_POPUP_SINGLE_STATE,
          payload: {
            stateName: 'selectedTagStatus',
            value: userResponse.responseObject[0].informationEngagement?.itemTypeStatus
          }
        });
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'middlePaneListPopupOptions',
            value: NODE_ROLE_TYPE_REVIEW_LIST_POPUP_OPTION
          }
        });
      }
      if (data.payload.whichTypeCreate === 'culture-profiles') {
        yield put({
          type: SET_CULTURE_TYPE_REDUCER_STATE,
          payload: userResponse.responseObject[0].informationBasic
        });
        yield put({
          type: SET_POPUP_SINGLE_STATE,
          payload: {
            stateName: 'selectedTagStatus',
            value: userResponse.responseObject[0].informationEngagement?.cultureProfileTypeStatus
          }
        });
      }
      if (data.payload.whichTypeCreate === 'job-profiles') {
        yield put({
          type: SET_JOB_TYPE_REDUCER_STATE,
          payload: userResponse.responseObject[0].informationBasic
        });
        yield put({
          type: SET_POPUP_SINGLE_STATE,
          payload: {
            stateName: 'selectedTagStatus',
            value: userResponse.responseObject[0].informationEngagement?.jobProfileTypeStatus
          }
        });
      }
      yield put({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
      // yield put({ type: CLEAR_TYPE_REDUCER_STATE });
      yield put({ type: POPUP_CLOSE });
    }else if( userResponse.responseCode ==='317'){
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'NAMEPOPUP', popupMode: `${data.payload.whichTypeCreate}TYPECREATE`, popupErrorMsg:  INFORMATION_EXIST_ERROR_MESSAGE }
      });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}

export default function* watchcreateTypeSaga() {
  yield takeLatest(CREATE_TYPE_SAGA, workerCreateTypeSaga);
}
