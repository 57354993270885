import { put, takeLatest, call } from 'redux-saga/effects';
import Store from '../../store';
import {
  CREATE_ASSESSEE_SAGA,
  LOADER_STOP,
  POPUP_CLOSE,
  SET_ASSESSEE_INFORMATION_DATA,
  SET_DISPLAY_PANE_THREE_STATE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_POPUP_SINGLE_STATE,
  SET_POPUP_VALUE,
  UPDATE_ASSESSEE_BASIC_INFO,
  UPDATE_ASSESSEE_CONTACT_INFO,
  UPDATE_ASSESSEE_ENGAGEMENT_INFO,
  UPDATE_ASSESSEE_PERSONAL_INFO,
  ASSESSEE_SIGN_ON_EMAILCHECK_SAGA,
  UPDATE_ASSESSEE_SETUP_PRIMARY_INFO
} from '../../actionType';
import { ASSESSEE_MODULE_URL } from '../../endpoints';
import { EXCEPTION_ERROR_MESSAGE, INFORMATION_EXIST_ERROR_MESSAGE } from '../../errorMessage';
const createAssesseeApi = async (requestObj) => {
  console.log(requestObj.data);
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      Authorization: localStorage.getItem('token')
    }),
    body: JSON.stringify(requestObj.data)
  };
  const response = await fetch(ASSESSEE_MODULE_URL, requestOptions);
  const json = await response.json();
  return json;
};

function* workerCreateAssesseeSaga(data) {
  try {
    const userResponse = yield call(createAssesseeApi, { data: data.payload });
    // console.log('IN WORKER ====>', userResponse);
    // console.log('IN WORKER ====>', JSON.stringify(userResponse));
    if (userResponse.responseCode === '000') {
      if (!data.payload.signOn) {
        yield put({ type: SET_ASSESSEE_INFORMATION_DATA, payload: userResponse.responseObject[0] });
        console.log('loading end');
        const {
          informationBasic,
          informationContact,
          informationEngagement,
          informationPersonal,
          informationSetup
        } = userResponse.responseObject[0];
        yield put({
          type: SET_DISPLAY_PANE_THREE_STATE,
          payload: {
            headerOne: Store.getState().DisplayPaneTwoReducer.typeOfAssesseeCreate,
            headerOneBadgeOne: 'distinct',
            headerOneBadgeTwo: 'information',
            headerOneBadgeThree: Store.getState().DisplayPaneTwoReducer.selectedInformationAllorKey,
            responseObject: userResponse.responseObject[0],
            reviewMode: 'revise',
            createMode: 'assessee'
          }
        });
        let PopupOptionArr = [...Store.getState().DisplayPaneTwoReducer.middlePaneListPopupOptions];
        let stats = userResponse.responseObject[0]?.informationEngagement?.assesseeStatus;
        let isFlaged = userResponse.responseObject[0]?.informationBasic?.assesseeFlag;
        let tempArr = [];
        PopupOptionArr.forEach((element) => {
          if ((stats === 'UNCONFIRMED' || stats === 'CONFIRMED') && element.data === 'approve') {
            console.log('in ',element.data);
            tempArr.push({ ...element, disabled: true });
          } else tempArr.push(element);
        });
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'middlePaneListPopupOptions',
            value: tempArr
          }
        });
        yield put({
          type: SET_POPUP_SINGLE_STATE,
          payload: {
            stateName: 'popupOptionArr',
            value: tempArr
          }
        });
        yield put({
          type: SET_POPUP_SINGLE_STATE,
          payload: {
            stateName: 'originPrimaryArrOprion',
            value: tempArr
          }
        });
        yield put({
          type: SET_POPUP_SINGLE_STATE,
          payload: {
            stateName: 'selectedTagStatus',
            value: stats
          }
        });
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'middlePaneSelectedValue',
            value: userResponse.responseObject[0].id
          }
        });
        yield put({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'selectedTagValue', value: userResponse.responseObject[0].id }
        });
        yield put({
          type: SET_POPUP_SINGLE_STATE,
          payload: {
            stateName: 'isFlaged',
            value: isFlaged
          }
        });
        yield put({ type: UPDATE_ASSESSEE_BASIC_INFO, payload: informationBasic });
        yield put({ type: UPDATE_ASSESSEE_PERSONAL_INFO, payload: informationPersonal });
        yield put({ type: UPDATE_ASSESSEE_ENGAGEMENT_INFO, payload: informationEngagement });
        yield put({ type: UPDATE_ASSESSEE_SETUP_PRIMARY_INFO, payload: informationSetup });
        yield put({ type: UPDATE_ASSESSEE_CONTACT_INFO, payload: informationContact });
        yield put({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
      }
      yield put({ type: LOADER_STOP });
      yield put({ type: POPUP_CLOSE });
    } else if (userResponse.responseCode === '251') {
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'EMAILPOPUP',
          popupMode: `ASSESSEE_SIGN_ON`,
          // popupMode: `JOBCREATE`,
          popupErrorMsg: INFORMATION_EXIST_ERROR_MESSAGE
        }
      });
    } else {
      yield put({ type: LOADER_STOP });
      yield put({ type: POPUP_CLOSE });
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }

    yield put({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'selectedTagsArray', value: [] }
    });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}

function* workerCreateAssesseeEmailCheckSaga(data) {
  try {
    const userResponse = yield call(createAssesseeApi, { data: data.payload });

    if (userResponse.responseCode === '000') {
      // console.log(userResponse, 'EMAILCHECK RESPONSE')
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'MOBILETELEPHONEPOPUP',
          popupMode: `ASSESSEE_SIGN_ON`
        }
      });
      yield put({ type: LOADER_STOP });
    } else if (userResponse.responseCode === '329') {
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'EMAILPOPUP',
          popupMode: `ASSESSEE_SIGN_ON`,
          // popupMode: `JOBCREATE`,
          popupErrorMsg: INFORMATION_EXIST_ERROR_MESSAGE
        }
      });
    } else {
      yield put({ type: LOADER_STOP });
      // yield put({ type: POPUP_CLOSE });
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
      });
    }
  } catch (err) {
    yield put({ type: LOADER_STOP });
    // yield put({ type: POPUP_CLOSE });
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
  }
}

export default function* watchcreateAssesseeSaga() {
  yield takeLatest(CREATE_ASSESSEE_SAGA, workerCreateAssesseeSaga);
  yield takeLatest(ASSESSEE_SIGN_ON_EMAILCHECK_SAGA, workerCreateAssesseeEmailCheckSaga);
}
