import { put, takeLatest, call } from 'redux-saga/effects';
import {
  SET_DISPLAY_PANE_THREE_STATE,
  LOADER_STOP,
  SET_JOB_REDUCER_STATE,
  GET_JOB_PROFILE_INFO_SAGA,
  JOB_PROFILE_INFO_REVISE_SAGA,
  SET_JOB_DYNAMIC_SINGLE_STATE,
  SET_POPUP_VALUE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  GET_JOBPROFILE_REVIEW_LIST_SAGA,
  SET_JOB_SIFTLIST_STATE,
  SET_RANGE_SELECTED,
  JOB_ASSESSMENTS_REVIEWLIST_SAGA,
  GET_JOBPROFILE_GROUP_REVIEW_LIST_SAGA,
  GET_JOBPROFILE_TYPE_REVIEW_LIST_SAGA,
  JOB_PROFILE_MULTI_STATUS_REVISE_SAGA,
  JOB_PROFILE_PUBLISHED_SAGA,
  SET_PAGE_COUNT,
  JOB_GROUP_JOB_REVIEWLIST_SAGA,
  LOADER_START,
  JOB_TYPE_JOB_REVIEWLIST_SAGA,
  SET_POPUP_SINGLE_STATE,
  SET_JOB_SETUP_INFO_STATE
} from '../../actionType';
import { JOB_MODULE_URL } from '../../endpoints';
import { EXCEPTION_ERROR_MESSAGE } from '../../errorMessage';
import Store from '../../store';

const jobProfileInfoApi = async (requestObj) => {
  console.log(requestObj.data);
  let URL = JOB_MODULE_URL;
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      Authorization: localStorage.getItem('idToken')
    }),
    body: JSON.stringify(requestObj.data)
  };
  const response = await fetch(URL, requestOptions);
  const json = await response.json();
  return json;
};

function* workerReviewInfoJobProfileSaga(data) {
  try {
    const userResponse = yield call(jobProfileInfoApi, {
      data: data.payload.reqBody
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === '000') {
      const { isReviseMode = false, jobProfileReqBody = null, createMode = '' } = data.payload;
      console.log('jobProfile=======>', userResponse);
      yield put({
        type: SET_DISPLAY_PANE_THREE_STATE,
        payload: {
          headerOne: 'job-profile',
          headerOneBadgeOne: 'distinct',
          headerOneBadgeTwo: 'information',
          headerOneBadgeThree: data.payload.secondaryOptionCheckValue,
          responseObject: userResponse.responseObject[0],
          reviewMode: isReviseMode ? 'revise' : '',
          createMode
        }
      });
      if (jobProfileReqBody !== null) {
        yield put({
          type: JOB_ASSESSMENTS_REVIEWLIST_SAGA,
          payload: {
            request: jobProfileReqBody,
            HeaderOne: 'assessments',
            BadgeOne: '',
            BadgeTwo: '',
            BadgeThree: '',
            isMiddlePaneList: false
          }
        });
      }
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: {
          stateName: 'responseObject',
          value: userResponse.responseObject[0]
        }
      });
      // if (isReviseMode) {
      const { informationBasic, informationAllocation, informationFramework, informationSetup } =
        userResponse.responseObject[0];
      yield put({
        type: SET_JOB_REDUCER_STATE,
        payload: informationBasic
      });
      yield put({
        type: SET_JOB_SETUP_INFO_STATE,
        payload: informationSetup
      });
      if (
        informationAllocation &&
        informationAllocation?.jobProfileGroup?.jobProfileGroupPrimary &&
        informationAllocation?.jobProfileGroup?.jobProfileGroupPrimary.length > 0
      ) {
        let tempArr = informationAllocation.jobProfileGroup.jobProfileGroupPrimary.map(
          (ob) => ob.id
        );
        yield put({
          type: SET_JOB_DYNAMIC_SINGLE_STATE,
          payload: {
            objectName: 'informationAllocation',
            stateName: 'jobProfileGroup',
            actualStateName: 'jobProfileGroupPrimary',
            value: tempArr
          }
        });
      } else {
        yield put({
          type: SET_JOB_DYNAMIC_SINGLE_STATE,
          payload: {
            objectName: 'informationAllocation',
            stateName: 'jobProfileGroup',
            actualStateName: 'jobProfileGroupPrimary',
            value: []
          }
        });
      }
      if (
        informationAllocation &&
        informationAllocation?.jobProfileGroup?.jobProfileGroupSecondary &&
        informationAllocation?.jobProfileGroup?.jobProfileGroupSecondary.length > 0
      ) {
        let tempArr = informationAllocation.jobProfileGroup.jobProfileGroupSecondary.map(
          (ob) => ob.id
        );
        yield put({
          type: SET_JOB_DYNAMIC_SINGLE_STATE,
          payload: {
            objectName: 'informationAllocation',
            stateName: 'jobProfileGroup',
            actualStateName: 'jobProfileGroupSecondary',
            value: tempArr
          }
        });
      } else {
        yield put({
          type: SET_JOB_DYNAMIC_SINGLE_STATE,
          payload: {
            objectName: 'informationAllocation',
            stateName: 'jobProfileGroup',
            actualStateName: 'jobProfileGroupSecondary',
            value: []
          }
        });
      }
      if (
        informationAllocation &&
        informationAllocation?.jobProfileNode?.jobProfileNodePrimary &&
        informationAllocation?.jobProfileNode?.jobProfileNodePrimary.length > 0
      ) {
        let tempArr = informationAllocation.jobProfileNode.jobProfileNodePrimary.map((ob) => ob.id);
        yield put({
          type: SET_JOB_DYNAMIC_SINGLE_STATE,
          payload: {
            objectName: 'informationAllocation',
            stateName: 'jobProfileNode',
            actualStateName: 'jobProfileNodePrimary',
            value: tempArr
          }
        });
      } else {
        yield put({
          type: SET_JOB_DYNAMIC_SINGLE_STATE,
          payload: {
            objectName: 'informationAllocation',
            stateName: 'jobProfileNode',
            actualStateName: 'jobProfileNodePrimary',
            value: []
          }
        });
      }
      if (
        informationAllocation &&
        informationAllocation?.jobProfileNode?.jobProfileNodeSecondary &&
        informationAllocation?.jobProfileNode?.jobProfileNodeSecondary.length > 0
      ) {
        let tempArr = informationAllocation.jobProfileNode.jobProfileNodeSecondary.map(
          (ob) => ob.id
        );
        yield put({
          type: SET_JOB_DYNAMIC_SINGLE_STATE,
          payload: {
            objectName: 'informationAllocation',
            stateName: 'jobProfileNode',
            actualStateName: 'jobProfileNodeSecondary',
            value: tempArr
          }
        });
      } else {
        yield put({
          type: SET_JOB_DYNAMIC_SINGLE_STATE,
          payload: {
            objectName: 'informationAllocation',
            stateName: 'jobProfileNode',
            actualStateName: 'jobProfileNodeSecondary',
            value: []
          }
        });
      }
      if (
        informationAllocation &&
        informationAllocation?.jobProfileType?.jobProfileTypePrimary &&
        informationAllocation?.jobProfileType?.jobProfileTypePrimary.length > 0
      ) {
        let tempArr = informationAllocation.jobProfileType.jobProfileTypePrimary.map((ob) => ob.id);
        yield put({
          type: SET_JOB_DYNAMIC_SINGLE_STATE,
          payload: {
            objectName: 'informationAllocation',
            stateName: 'jobProfileType',
            actualStateName: 'jobProfileTypePrimary',
            value: tempArr
          }
        });
      } else {
        yield put({
          type: SET_JOB_DYNAMIC_SINGLE_STATE,
          payload: {
            objectName: 'informationAllocation',
            stateName: 'jobProfileType',
            actualStateName: 'jobProfileTypePrimary',
            value: []
          }
        });
      }
      if (
        informationAllocation &&
        informationAllocation?.jobProfileType?.jobProfileTypeSecondary &&
        informationAllocation?.jobProfileType?.jobProfileTypeSecondary.length > 0
      ) {
        let tempArr = informationAllocation.jobProfileType.jobProfileTypeSecondary.map(
          (ob) => ob.id
        );
        yield put({
          type: SET_JOB_DYNAMIC_SINGLE_STATE,
          payload: {
            objectName: 'informationAllocation',
            stateName: 'jobProfileType',
            actualStateName: 'jobProfileTypeSecondary',
            value: tempArr
          }
        });
      } else {
        yield put({
          type: SET_JOB_DYNAMIC_SINGLE_STATE,
          payload: {
            objectName: 'informationAllocation',
            stateName: 'jobProfileType',
            actualStateName: 'jobProfileTypeSecondary',
            value: []
          }
        });
      }
      const tempJobDomainArray = informationFramework?.jobProfileJobDomain || [];
      const jobDomainIdList = tempJobDomainArray.map((ob) => {
        return ob.id;
      });
      const tempJobFunctionArray = informationFramework?.jobProfileJobFunction || [];
      const jobFunctionIdList = tempJobFunctionArray.map((ob) => {
        return ob.id;
      });
      const tempJobRoleArray = informationFramework?.jobProfileJobRole || [];
      const jobRoleIdList = tempJobRoleArray.map((ob) => {
        return ob.id;
      });
      let shortlistIds = informationFramework?.jobProfileJobCompetencySelectList?.map((ob) => {
        return ob.iGaugeOccupationFrameworkOneTag;
      });
      // let jobShortListIdList = [];
      let coreArr = [];
      let coreobject = [];
      let coreobMar = [];
      informationFramework?.jobProfileJobCompetencySelectList?.forEach((job) => {
        coreArr.push(job.iGaugeOccupationFrameworkOneTag);
        coreobject.push({
          ...job,
          id: job.iGaugeOccupationFrameworkOneTag
        });
      });
      console.log('coreobject1', coreobject);
      // arry marging frorm aboved one
      informationFramework?.jobProfileJobCompetencyShortList?.forEach((jobc) => {
        coreobject.forEach((core) => {
          if (jobc.iGaugeOccupationFrameworkOneTag === core.id) {
            coreobMar.push({
              ...core,
              iGaugeOccupationFrameworkOneSelected: jobc.iGaugeOccupationFrameworkOneSelected
            });
          }
        });
      });
      console.log('coreobject2', coreobject);
      console.log('coreobMar-', coreobMar);
      yield put({
        type: SET_JOB_SIFTLIST_STATE,
        payload: {
          jobProfileJobDomain: jobDomainIdList || [],
          jobProfileJobFunction: jobFunctionIdList || [],
          jobProfileJobRole: jobRoleIdList || [],
          jobProfileJobCompetencyCore: coreobMar || [],
          jobProfileJobCompetencyCoreObj: coreobMar || [],
          jobProfileJobCompetencyShortlisted: shortlistIds || [],
          jobProfileJobCompetencySelectList: [],
          jobProfileJobCompetencyShortList: [],
          jobProfileJobCompetencySiftList: [],
          jobProfileJobCompetencySiftRemovable: []
        }
      });
      yield put({
        type: SET_RANGE_SELECTED,
        payload: false
      });
      // }
    } else {
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: userResponse.responseMessage,
          popupMode: 'responseErrorMsg'
        }
      });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerReviseInfoJobProfileSaga(data) {
  try {
    const userResponse = yield call(jobProfileInfoApi, {
      data: data.payload.reqBody
    });
    if (userResponse.responseCode === '000') {
      const { createMode, isHideRightPane = false } = data.payload;
      if (!isHideRightPane) {
        yield put({
          type: SET_DISPLAY_PANE_THREE_STATE,
          payload: {
            headerOne: 'job-profile',
            headerOneBadgeOne: data.payload.headerOneBadgeOne || 'distinct',
            headerOneBadgeTwo: 'information',
            headerOneBadgeThree: data.payload.headerOneBadgeThree,
            responseObject: userResponse.responseObject[0],
            createMode
          }
        });
      }
      if (createMode === '') {
        if (data.payload.typeOfMiddlePaneList === 'jobProfileTypeJobProfileReviewList') {
          yield put({ type: LOADER_START });
          yield put({
            type: JOB_TYPE_JOB_REVIEWLIST_SAGA,
            payload: {
              HeaderOne: 'job-profiles',
              request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        } else if (data.payload.typeOfMiddlePaneList === 'jobProfileGroupJobProfileReviewList') {
          yield put({
            type: JOB_GROUP_JOB_REVIEWLIST_SAGA,
            payload: {
              HeaderOne: 'job-profiles',
              request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        } else {
          yield put({ type: SET_PAGE_COUNT, payload: 0 });
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          yield put({
            type: GET_JOBPROFILE_REVIEW_LIST_SAGA,
            payload: {
              HeaderOne: 'job-profiles',
              request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        }
      } else {
        console.log('loading end');
        yield put({ type: LOADER_STOP });
      }
      yield put({
        type: SET_RANGE_SELECTED,
        payload: false
      });
    } else {
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: userResponse.responseMessage,
          popupMode: 'responseErrorMsg'
        }
      });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerReviseMultiStatusjobProfileSaga(data) {
  try {
    const userResponse = yield call(jobProfileInfoApi, {
      data: data.payload.reqBody
    });
    if (userResponse.responseCode === '000') {
      yield put({ type: SET_PAGE_COUNT, payload: 0 });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'relatedReviewListDistinctData', value: [] }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'typeOfMiddlePaneList', value: '' }
      });
      yield put({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'selectionValue', value: '' }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isSelectActive', value: '' }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'selectedTagsArray', value: [] }
      });
      if (data.payload.createMode === '') {
        if (data.payload.typeOfMiddlePaneList === 'jobProfilesGroupDistinctReviewList') {
          yield put({ type: LOADER_START });
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
          yield put({
            type: GET_JOBPROFILE_GROUP_REVIEW_LIST_SAGA,
            payload: {
              HeaderOne: 'job-profiles',
              request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        } else if (data.payload.typeOfMiddlePaneList === 'jobProfilesTypeDistinctReviewList') {
          yield put({ type: LOADER_START });
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
          yield put({
            type: GET_JOBPROFILE_TYPE_REVIEW_LIST_SAGA,
            payload: {
              HeaderOne: 'job-profiles',
              request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        } else if (data.payload.typeOfMiddlePaneList === 'jobProfileTypeJobProfileReviewList') {
          yield put({ type: LOADER_START });
          // yield put({
          //   type: SET_DISPLAY_TWO_SINGLE_STATE,
          //   payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          // });
          // yield put({
          //   type: SET_DISPLAY_TWO_SINGLE_STATE,
          //   payload: { stateName: 'reviewListDistinctData', value: [] }
          // });
          yield put({
            type: JOB_TYPE_JOB_REVIEWLIST_SAGA,
            payload: {
              HeaderOne: 'job-profiles',
              request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        } else if (data.payload.typeOfMiddlePaneList === 'jobProfileGroupJobProfileReviewList') {
          yield put({
            type: JOB_GROUP_JOB_REVIEWLIST_SAGA,
            payload: {
              HeaderOne: 'job-profiles',
              request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        } else {
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          yield put({
            type: SET_PAGE_COUNT,
            payload: 0
          });
          yield put({
            type: GET_JOBPROFILE_REVIEW_LIST_SAGA,
            payload: {
              HeaderOne: 'job-profiles',
              request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        }
      } else yield put({ type: LOADER_STOP });
    } else {
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: userResponse.responseMessage,
          popupMode: 'responseErrorMsg'
        }
      });
    }
  } catch (e) {
    yield put({ type: LOADER_STOP });
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
  }
}
function* workerRevisePublishedjobProfileSaga(data) {
  try {
    const userResponse = yield call(jobProfileInfoApi, {
      data: data.payload.reqBody
    });
    if (userResponse.responseCode === '000') {
      if (data.payload.createMode === '') {
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'isSelectActive', value: '' }
        });
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'selectedTagsArray', value: [] }
        });
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'reviewListDistinctData', value: [] }
        });
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        yield put({
          type: GET_JOBPROFILE_REVIEW_LIST_SAGA,
          payload: {
            HeaderOne: 'job-profiles',
            request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
            BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
            isMiddlePaneList: true
          }
        });
      } else yield put({ type: LOADER_STOP });
    } else {
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: userResponse.responseMessage,
          popupMode: 'responseErrorMsg'
        }
      });
    }
  } catch (e) {
    yield put({ type: LOADER_STOP });
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
  }
}

export default function* watchReviewInfoJobProfileSaga() {
  yield takeLatest(GET_JOB_PROFILE_INFO_SAGA, workerReviewInfoJobProfileSaga);
  yield takeLatest(JOB_PROFILE_INFO_REVISE_SAGA, workerReviseInfoJobProfileSaga);
  yield takeLatest(JOB_PROFILE_MULTI_STATUS_REVISE_SAGA, workerReviseMultiStatusjobProfileSaga);
  yield takeLatest(JOB_PROFILE_PUBLISHED_SAGA, workerRevisePublishedjobProfileSaga);
}
