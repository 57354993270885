import React from 'react';
import { isMobile } from 'react-device-detect';
// import AllocationAccordian from '../Accordian/AllocationAccordian';
// import Manuscript from '@material-ui/icons/Description';
import { useDispatch, useSelector } from 'react-redux';
import DisplayPanelAccordianReviewListOne from '../Accordian/DisplayPanelAccordianReviewListOne';
import DisplayPanelAccordianInformation from '../Accordian/DisplayPanelAccordianInformation';
import { Paper } from '@material-ui/core';
import { makeItemObj } from '../../Actions/GenericActions';
import {
  FILTERMODE,
  GET_ALLOCATE_ITEM,
  LOADER_START,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MOBILE_PANE_STATE
} from '../../actionType';
import { getItemAllocateApi, getItemTypeItemDistinctApiCall } from '../../Actions/ItemModuleAction';

const DisplayPaneThreeSectionTwoItemType = () => {
  // const [listExpand, setListExpand] = useState('');
  const { reviewMode, relatedReviewListPaneThree, responseObject } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  const { selectedAssociateInfo, countPage, reviewListDistinctData } = useSelector(
    (state) => state.DisplayPaneTwoReducer
  );
  const dispatch = useDispatch();
  // const { informationEngagement, informationSetup } = responseObject;
  // function capitalizeFirstLetter(string) {
  //   if (!string) return '';
  //   return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  // }


  const onclickReviewItem = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    if (labelName === 'items' && selectedBadgeName === 'distinct') {
      console.log(
        'relatedReviewListPaneThree',
        relatedReviewListPaneThree.length,
        relatedReviewListPaneThree
      );
      getItemTypeItemDistinctApiCall(
        selectedAssociateInfo,
        '',
        -1,
        dispatch,
        'distinct',
        responseObject.id, //group id
        '',
        false,
        true
      );
    }
  };

  const onclickReviseItem = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    if (labelName === 'items' && selectedBadgeName === 'distinct') {
      console.log('item CLICK :::::::>>>>>>>', relatedReviewListPaneThree);
      let requestObect = makeItemObj(selectedAssociateInfo, '', countPage, 0);
      let revisedTypeObject = {
        id: responseObject.id,
        itemTypeName: responseObject.informationBasic.itemTypeName,
        itemTypeDescription: responseObject.informationBasic.itemTypeDescription,
        itemTypeStatus: responseObject.informationEngagement.itemTypeStatus
      };
      getItemAllocateApi(
        dispatch,
        requestObect,
        revisedTypeObject,
        relatedReviewListPaneThree,
        'itemTypeItemeRevise',
        'itemTypeItemReviewList',
        []
      );
    }
  };
  let itemArray = [];
  // if (relatedReviewListPaneThree?.length > 0) {
    // debugger
    let itemList = relatedReviewListPaneThree?.item || [];
    itemList &&
      itemList.forEach((ob) => {
        const { id, informationBasic, informationEngagement, associateId } = ob;
        itemArray.push({
          id,
          textOne: informationBasic?.itemName || '',
          textTwo: informationBasic?.itemDescription || '',
          status: informationEngagement?.itemStatus || '',
          isShared: ob?.itemShared,
      associateId
        });
      });
  // }
  
  const list2 = [
    {
      id: 'a1',
      labelTextOneOne: 'items',
      labelTextOneOneBadgeOne: '',
      labelTextOneOneBadgeTwo: '',
      labelTextOneOneBadgeThree: '',
      labelTextOneOneBadgeFour: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'distinct',
          innerList: itemArray
        }
      ],
      innerInfo: 'No Information',
      isListCard: true,
      isReviewLink: true
    }
  ];

  return (
    <div>
      <>
        <div className={'containerPadding'}>
          <Paper className={'dossierContainerTop'}>
            {list2.map((ob) => {
              return (
                <div key={ob.id}>
                  {ob.isListCard ? (
                    <DisplayPanelAccordianReviewListOne
                      onClickRevise={onclickReviseItem}
                      onClickReview={onclickReviewItem}
                      className=""
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  ) : (
                    <DisplayPanelAccordianInformation
                      onClickRevise={onclickReviseItem}
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  )}
                </div>
              );
            })}
          </Paper>
        </div>
      </>
      {isMobile && (
        <div className={'containerPadding'} style={{ height: '55px' }}>
          {' '}
        </div>
      )}
    </div>
  );
};

export default DisplayPaneThreeSectionTwoItemType;
