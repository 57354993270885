import React, { Fragment, useEffect, useState } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import PopUp from '../Molecules/PopUp/PopUp';
import PopupHeader from '../Molecules/PopUp/PopUpHeader';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import '../Molecules/PopUp/PopUp.css';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { POPUP_CLOSE, SET_GROUP_ALLOCATION_GROUP_ASCENDANT_STATE, SET_GROUP_ALLOCATION_STATE, SET_NEXT_POPUP } from '../actionType';
import InfoToolTip from '../Atoms/InfoToolTip/InfoToolTip';
import { CAN_NOT_REVISED_MESSAGE, REQUIRED_ERROR_MESSAGE } from '../errorMessage';
import { assesseeRole } from '../Actions/AssesseeModuleAction';
const PopUpReviewList = (props) => {
  const dispatch = useDispatch();
  // const { reviewMode } = useSelector((state) => state.DisplayPaneThreeReducer);

  const {
    isActive,
    isNotRevised = false,
    errorMsg = (isNotRevised && CAN_NOT_REVISED_MESSAGE) || '',
    setErrorMsg = null,
    isTooltipActive = false,
    tooltipActiveText = '',
    headerOneBadgeTwo = '',
    inputHeaderBadge = 'primary',
    inputHeaderBadgeTwo,
    inputHeader = 'node',
    headerPanelColour = 'genericOne',
    headerOne = 'assessees',
    headerOneBadgeOne = 'information',
    infoMsg = 'select one or more',
    ListData = [],
    textOne = 'name',
    textTwo = 'description',
    dataDefault = '',
    dataValue = '',
    nextPopUpValue,
    onClickEvent = null,
    handleClickOnCorrect = null,
    mode,
    isRequired = false,
    selectedList = [],
    minimumSelected = -1,
    prevPopUpValue = '',
    jobCompetencyValue = false,
    status='',
    useLocalState = false,
    objectName,
    stateName,
    actualStateName,
    updateState,
    statusTwo,
    isInformationTwo
  } = props;

  const [selectedTagsLocally, setSelectedTagsLocally] = useState([...selectedList])
  // useEffect(() => {
  //   setSelectedTagsLocally([...selectedList])
  // }, [])

  const handleSelectedTagsLocalState = (e) => {
    // console.log(e.currentTarget, 'CURRENTTARGET')
    let tagId = e.currentTarget.getAttribute('tag');
    setErrorMsg && setErrorMsg('');
    if (selectedTagsLocally.includes(tagId)) {
      let tempArr = [...selectedTagsLocally];
      let index = tempArr.indexOf(tagId)
      if (index > -1) { // only splice array when item is found
        tempArr.splice(index, 1); // 2nd parameter means remove one item only
      }
      document.getElementById(tagId).style.backgroundColor = 'white';
      setSelectedTagsLocally(tempArr)
    } else {
      document.getElementById(tagId).style.backgroundColor = '#F0F0F0';
      setSelectedTagsLocally((prevState) => {
        return [...prevState, tagId]
      })
    }
  }

  const handleClick = () => {
    /*according to creation mode popup sequence will change*/
    if (isRequired) {
      let condition = useLocalState
        ? selectedTagsLocally.length >= minimumSelected
        : selectedList.length >= minimumSelected;
      if (condition) {
        if (mode === 'revise') {
          dispatch({ type: POPUP_CLOSE });
        } else {
          dispatch({ type: SET_NEXT_POPUP, payload: { isPopUpValue: nextPopUpValue } });
        }
      } else {
        prevPopUpValue !== ''
          ? dispatch({ type: SET_NEXT_POPUP, payload: { isPopUpValue: prevPopUpValue } })
          : setErrorMsg(REQUIRED_ERROR_MESSAGE);
      }
    } else {
      if (mode === 'revise') {
        dispatch({ type: POPUP_CLOSE });
      } else {
        dispatch({ type: SET_NEXT_POPUP, payload: { isPopUpValue: nextPopUpValue } });
      }
    }
    if (handleClickOnCorrect) handleClickOnCorrect();

    if (useLocalState) {
      if (actualStateName) {
        dispatch({
          type: updateState,
          payload: {
            objectName: objectName,
            stateName: stateName,
            actualStateName: actualStateName,
            value: selectedTagsLocally
          }
        });
      } else {
        dispatch({
          type: updateState,
          payload: {
            objectName: objectName,
            stateName: stateName,
            // actualStateName: actualStateName,
            value: selectedTagsLocally
          }
        });
      }
    }

  };

  return (
    <div>
      <PopUp isActive={isActive}>
        <PopupHeader
          headerPanelColour={headerPanelColour}
          headerOne={headerOne}
          headerOneBadgeOne={headerOneBadgeOne}
          headerOneBadgeTwo={headerOneBadgeTwo}
          onClick={handleClick}
          mode={mode}
        />
        <DialogContent
          className={['popupContent', 'fixed10PadDim', 'revisePopupContent'].join(' ')}
        >
          <div className={'fitContent'}>
            <div className={['PopupFormBox', 'labelPopupBox', 'popupMinHei'].join(' ')}>
              <InputLabel htmlFor="name-input" className={'textForLabelPopup'}>
                <Fragment>
                  {inputHeader}&nbsp;
                  {inputHeaderBadge ? (
                    <span className={'headerBadge'}>{inputHeaderBadge}</span>
                  ) : null}
                  &nbsp;
                  {inputHeaderBadgeTwo ? (
                    <span className={'headerBadge'}>{inputHeaderBadgeTwo}</span>
                  ) : null}
                </Fragment>
              </InputLabel>
              <div className={'infoSymbol'}></div>
              <div className={'infoSymbol'}>
                <InfoToolTip message={infoMsg} />
              </div>
            </div>
          </div>
          {ListData.length > 0 &&
            ListData.map((index, option) => (
              <ReviewList
                textOne={
                  (jobCompetencyValue &&
                    //index?.textOne
                    index?.iGaugeOccupationFrameworkOneCluster
                      ?.iGaugeOccupationFrameworkOneClusterTwoName) ||
                  //index?.[textOne]
                  (index?.informationBasic && index.informationBasic[textOne]) ||
                  index[textOne]
                }
                textTwo={
                  (index?.informationBasic && index.informationBasic[textTwo]) || index[textTwo]
                }
                id={index.id}
                tag={index.id}
                dataValue={dataValue}
                dataDefault={index?.informationSetup && index?.informationSetup[dataDefault]}
                isAlertActive={false}
                isTooltipActive={isTooltipActive}
                tooltipActiveText={index[tooltipActiveText]}
                isFlagActive={false}
                isSelectActive={''}
                key={index.id}
                isSelectedReviewList={useLocalState ? selectedTagsLocally.includes(index.id) : selectedList.includes(index.id)}
                onClickEvent={isNotRevised ? null : useLocalState ? handleSelectedTagsLocalState : onClickEvent}
                className={'reviewListFixedWidth'}
                statusTwo={jobCompetencyValue && index[statusTwo]}
                isInformationTwo
                
              // isSelectedReviewList={selectedIdsArr.includes(index.id)}
              />
            ))}

          <FormHelperText
            className={['helperText', 'helptextmargin'].join(' ')}
            style={{ margin: '5px 0px 0px 5px' }}
          >
            <span>{errorMsg}</span>
          </FormHelperText>
        </DialogContent>
      </PopUp>
    </div>
  );
};

PopUpReviewList.propTypes = {
  className: PropTypes.string,
  headerPanelColour: PropTypes.oneOf([
    'displayPaneLeft',
    'displayPaneCentre',
    'displayPaneRight',
    'genericOne',
    'genericTwo'
  ]),
  headerOne: PropTypes.string,
  headerOneBadgeOne: PropTypes.string,
  headerOneBadgeTwo: PropTypes.string,
  headerOneBadgeThree: PropTypes.string,
  isActive: PropTypes.bool
};

export default PopUpReviewList;
