import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CLEAR_DISPLAY_PANE_THREE,
  FILTERMODE_ENABLE,
  POPUP_OPEN,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MIDDLEPANE_STATE,
  SET_POPUP_STATE,
  RELATED_REVIEWLIST_DISTINCT_DATA,
  GET_ASSESSEE_ASSIGNMENT_SAGA,
  LOADER_START,
  SET_PAGE_COUNT,
  SET_RELATED_REQUEST_OBJECT,
  SET_POPUP_SINGLE_STATE
} from '../actionType';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import { FilterList } from '@material-ui/icons';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import { ASSESSEE_RELATED_ASSIGNMENT_REVIEWLIST_POPUP } from '../PopUpConfig';
import Card from '../Molecules/Card/Card';
import CrossIcon from '@material-ui/icons/Clear';
import { onClickCheckBoxSelection } from '../Actions/AssesseeModuleAction';
import { closeRelatedList, getPopUpOptionList } from '../Actions/GenericActions';

const AssignmentDistinctAssesseeDistinctReviewList = (props) => {
  const dispatch = useDispatch();
  const { FilterModeEnable, FilterMode } = useSelector((state) => state.FilterReducer);

  const {
    middlePaneSelectedValue,
    reviewListDistinctData,
    selectedAssociateInfo,
    relatedReviewListDistinctData,
    middlePaneHeaderBadgeOne,
    middlePaneHeaderBadgeThree,
    middlePaneHeaderDuplicate,
    middlePaneHeaderDuplicateBadgeOne,
    middlePaneHeaderDuplicateBadgeTwo,
    middlePaneHeaderDuplicateBadgeThree,
    middlePaneHeaderDuplicateBadgeFour,
    middlePaneHeaderDuplicateOne,
    middlePaneHeaderDuplicateOneBadgeOne,
    middlePaneHeaderDuplicateOneBadgeTwo,
    middlePaneHeaderDuplicateOneBadgeThree,
    middlePaneHeaderDuplicateOneBadgeFour,
    selectedTagsArray,
    unselectedTagsArray,
    duplicateScanCount,
    countPage,
    isSelectActive,
    typeOfMiddlePaneList,
    assignmentCreateType,
    signedAssesseePermission
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { responseObject, createMode } = useSelector((state) => state.DisplayPaneThreeReducer);
  let previousMiddlePaneList = middlePaneHeaderDuplicateOne + 'DistinctReviewList';
  let previousFilterMode = middlePaneHeaderDuplicateOne.slice(0, -1) + 'Distinct';
  const onClickReviseCancel = () => {
    closeRelatedList(
      dispatch,
      createMode,
      responseObject,
      middlePaneHeaderDuplicateOne,
      middlePaneHeaderDuplicateOneBadgeOne,
      middlePaneHeaderDuplicateOneBadgeTwo,
      middlePaneHeaderDuplicateOneBadgeThree,
      middlePaneHeaderDuplicateOneBadgeFour,
      previousMiddlePaneList,
      duplicateScanCount,
      previousFilterMode,
      duplicateScanCount,
      middlePaneHeaderDuplicateOne,
      middlePaneHeaderDuplicateOneBadgeOne,
      middlePaneHeaderDuplicateOneBadgeTwo,
      middlePaneHeaderDuplicateOneBadgeThree,
      middlePaneHeaderDuplicateOneBadgeFour
    );
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: { stateName: 'selectionValue', value: '' }
    });
    dispatch({ type: RELATED_REVIEWLIST_DISTINCT_DATA, payload: [] });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  };
  const onClickFooter = (e) => {
    let siftValue = e.currentTarget.getAttribute('data-value');
    if (
      siftValue === 'suspended' ||
      siftValue === 'terminated' ||
      siftValue === 'started' ||
      siftValue === 'unstarted' ||
      siftValue === 'aborted' ||
      siftValue === 'finished' ||
      siftValue === 'timeout'
    ) {
      if (siftValue === 'timeout') siftValue = 'unfinished';
      console.log('siftValue----', siftValue);
      let statusArr = [siftValue.toUpperCase(), 'ADMINISTERED']; //'ADMINISTERED'
      let reqBody = {
        module: 'Assessee-Assignment',
        action: 'Review-List',
        requestObject: {
          assesseeId: relatedReviewListDistinctData[0].assesseeId, // selectedTagValue,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          filterTypeName: assignmentCreateType,
          assesseeAssignmentStatus: statusArr,
          countPage: -1,
          numberPage: 0,
          filter: true,
          orderBy: {
            columnName: '',
            order: 'asc'
          },
          searchCondition: 'AND',
          search: [
            {
              condition: 'and',
              searchBy: [
                {
                  dataType: 'string',
                  conditionColumn: 'assesseeAssignmentStatus',
                  conditionValue: {
                    condition: 'in',
                    value: {
                      in: [
                        'SUSPENDED',
                        'TERMINATED',
                        'STARTED',
                        'UNSTARTED',
                        'ABORTED',
                        'FINISHED',
                        'UNFINISHED'
                      ]
                    }
                  }
                }
              ]
            }
          ]
        }
      };
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'typeOfMiddlePaneList', value: '' }
      });
      dispatch({ type: SET_RELATED_REQUEST_OBJECT, payload: reqBody });
      dispatch({ type: SET_PAGE_COUNT, payload: 0 });
      dispatch({ type: LOADER_START });
      dispatch({
        type: GET_ASSESSEE_ASSIGNMENT_SAGA,
        payload: {
          request: reqBody,
          BadgeOne: middlePaneHeaderBadgeOne,
          typeOfMiddlePaneList,
          BadgeTwo: siftValue === 'unfinished' ? 'timeout' : siftValue,
          BadgeThree: middlePaneHeaderBadgeThree,
          isSelectActive,
          selectedTagsArray,
          unselectedTagsArray,
          relatedListCall: 'assesseeDistinct',
          responseObj: {
            assesseeId: relatedReviewListDistinctData[0].assesseeId,
            assesseeInfo: relatedReviewListDistinctData[0].assesseeInfo
          }
        }
      });
    }
    dispatch({ type: FILTERMODE_ENABLE });
  };
  const primaryIcon = [{ label: 'sift', onClick: onClickFooter, Icon: FilterList }];
  const secondaryIconOne = [
    { label: 'started', onClick: onClickFooter, Icon: FilterList },
    { label: 'unstarted', onClick: onClickFooter, Icon: FilterList }
  ];
  const secondaryIconTwo = [
    { label: 'aborted', onClick: onClickFooter, Icon: FilterList },
    { label: 'finished', onClick: onClickFooter, Icon: FilterList },
    { label: 'suspended', onClick: onClickFooter, Icon: FilterList },
    { label: 'terminated', onClick: onClickFooter, Icon: FilterList },
    { label: 'timeout', onClick: onClickFooter, Icon: FilterList }
  ];

  //const listDistinctData = relatedReviewListDistinctData[0];
  const openListPopup = (e) => {
    console.log(e.currentTarget.getAttribute('tag'));
    let tempArr = [];
    let assesseeDistinctPermission =
      signedAssesseePermission?.assesseeDistinctPermission?.assesseeAssesseeDistinctPermission;
    let popupContentArrValue = getPopUpOptionList(
      ASSESSEE_RELATED_ASSIGNMENT_REVIEWLIST_POPUP,
      assesseeDistinctPermission
    );
    popupContentArrValue.forEach((element) => {
      if (
        element.data === 'review' ||
        element.data === 'revise' ||
        element.data === 'share' ||
        element.data === 'allocate'
      ) {
        tempArr.push({ ...element, disabled: true });
      } else {
        tempArr.push(element);
      }
    });
    let status = e.currentTarget.getAttribute('status');
    let tempArr2 = [];
    tempArr.forEach((element) => {
      if (status !== 'FINISHED' && status !== 'UNFINISHED' && element.data === 'analytics') {
        tempArr2.push({ ...element, disabled: true });
      } else if (
        (status === 'TERMINATED' || status === 'SUSPENDED' || status === 'ARCHIVED') &&
        element.data === 'reset'
      ) {
        tempArr2.push({ ...element, disabled: true });
      } else tempArr2.push(element);
    });
    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: 'assignment',
        popupHeaderOneBadgeOne: '',
        popupHeaderOneBadgeTwo: '',
        isPopUpValue: '',
        popupOpenType: 'primary',
        popupContentArrValue: tempArr2,
        selectedTagValue: e.currentTarget.getAttribute('tag'),
        selectedTagStatus: e.currentTarget.getAttribute('status')
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: tempArr2
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
  };
  // const listDistinctData = reviewListDistinctData.filter((x) => x.id === selectedTagValue);
  const listDistinctData = relatedReviewListDistinctData[0];
  console.log('FilterMode', FilterMode);
  console.log('relatedReviewListDistinctData', relatedReviewListDistinctData);

  return (
    <div>
      {listDistinctData && (
        <Card
          textOneOne={
            listDistinctData?.assesseeInfo?.assesseeNameFirst +
            ' ' +
            listDistinctData?.assesseeInfo?.assesseeNameOther +
            ' ' +
            listDistinctData?.assesseeInfo?.assesseeNameLast +
            ' ' +
            listDistinctData?.assesseeInfo?.assesseeNameSuffix
          }
          textTwoOne={listDistinctData?.assesseeInfo?.assesseeAlias}
          IconOne={CrossIcon}
          isIcon={true}
          labelTwoTwo={'distinct'}
          onClickIconOne={onClickReviseCancel}
          isAlliance
          relatedCardFixed={true}
          className={'iguru-iconboxSVG'}
        />
      )}
      {listDistinctData?.assesseeAssignment.length > 0 &&
        listDistinctData?.assesseeAssignment.map((item, index) => {
          return (
            <div className="containerPadding" key={index}>
              <ReviewList
                className=""
                id={index}
                tag={item.assesseeAssignmentId}
                associateId={item?.associateId}
                isSelectedReviewList={middlePaneSelectedValue === item.assesseeAssignmentId}
                status={
                  item.assesseeAssignmentStatus === 'UNFINISHED'
                    ? 'TIMEOUT'
                    : item.assesseeAssignmentStatus
                }
                actualStatus={item.assesseeAssignmentStatus}
                textOne={item.assesseeAssignmentName}
                textTwo={item.assesseeAssignmentDescription}
                isTooltipActive={false}
                onClickEvent={openListPopup}
                isSelectActive={isSelectActive}
                isSelected={selectedTagsArray.includes(item.assesseeAssignmentId)}
                isDelivery={false}
                onClickCheckBox={(event) => {
                  onClickCheckBoxSelection(selectedTagsArray, unselectedTagsArray, event, dispatch);
                }}
              />
            </div>
          );
        })}

      {(FilterMode === 'assesseeAssignmentactive' ||
        FilterMode === 'assesseeAssignmentinactive') && (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={primaryIcon}
          // secondaryIcon={secondaryIcon}
          secondaryIcon={
            FilterMode === 'assesseeAssignmentactive' ? secondaryIconOne : secondaryIconTwo
          }
        />
      )}
    </div>
  );
};
export default AssignmentDistinctAssesseeDistinctReviewList;
