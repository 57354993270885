import { getPopUpOptionList, setAssociateCardPermissionInJson } from '../Actions/GenericActions';
import {
  SECTION_POPUP_OPEN,
  SECTION_POPUP_CLOSE,
  SET_SECTION_HEADER_ONE,
  CLEAR_IGAUGE_REDUCER,
  SET_SECTION_HEADER_ONE_BADGE_ONE,
  SET_SECTION_TWO_SECONDARY_OPTION_VALUE,
  SET_PREVIOUS_SECTION_POPUP,
  IGAUGE_SECTION_POPUP_CLOSE,
  SET_IGAUGE_SECTION_POPUP_SINGLE_STATE
} from '../actionType';
import { IGUAGE_POPUP_OPTION } from '../PopUpConfig';

const initialState = {
  sectionHeaderOne: '',
  sectionHeaderOneBadgeOne: '',
  sectionPopUpActive: false,
  currentPopUpOption: IGUAGE_POPUP_OPTION,
  sectionPopUpType: 'primary',
  secondaryOptionCheckValue: '',
  secondaryOptionCheckValueDuplicate: ''
};

const IgaugeReducer = (istate = initialState, action) => {
  switch (action.type) {
    case SECTION_POPUP_OPEN:
      return {
        ...istate,
        sectionPopUpActive: true,
        sectionHeaderOne: action.payload.headerOne,
        sectionHeaderOneBadgeOne: action.payload.headerOneBadgeOne
      };
    case SET_PREVIOUS_SECTION_POPUP:
      return {
        ...istate,
        sectionPopUpActive: true
      };
    case SECTION_POPUP_CLOSE:
      return {
        ...istate,
        sectionPopUpActive: false,
        secondaryOptionCheckValue: '',
        currentPopUpOption: IGUAGE_POPUP_OPTION
      };
    case IGAUGE_SECTION_POPUP_CLOSE:
      return {
        ...istate,
        sectionPopUpActive: false
      };
    case SET_SECTION_HEADER_ONE:
      return {
        ...istate,
        sectionHeaderOne: action.payload
      };
    case SET_SECTION_HEADER_ONE_BADGE_ONE:
      return {
        ...istate,
        sectionHeaderOneBadgeOne: action.payload
      };
    case SET_IGAUGE_SECTION_POPUP_SINGLE_STATE:
      return {
        ...istate,
        [action.payload.stateName]: action.payload.value
      };
    case SET_SECTION_TWO_SECONDARY_OPTION_VALUE:
      if (action.payload.clickValue === '') {
        return {
          ...istate,
          secondaryOptionCheckValue: action.payload.clickValue,
          currentPopUpOption: IGUAGE_POPUP_OPTION
        };
      } else {
        let tempArr = [];
        let optArr = [];
        IGUAGE_POPUP_OPTION.forEach((element) => {
          optArr.push({ ...element, permissionArr: action.payload.clickValue.slice(0, -1) });
        });
        let optionArr = getPopUpOptionList(optArr, action.payload.permissionObject);
        console.log('optionArr', optionArr);
        if (action.payload.clickValue === 'assessees' && istate.sectionHeaderOne === 'iGem') {
          optionArr.forEach((element) => {
            if (
              element.data === 'create' ||
              element.data === 'notifications' ||
              element.data === 'reports'
            ) {
              tempArr.push({ ...element, disabled: true });
            } else tempArr.push(element);
          });
        } else if (
          (action.payload.clickValue === 'assessees' ||
            action.payload.clickValue === 'assessments' ||
            action.payload.clickValue === 'assignments' ||
            action.payload.clickValue === 'associates') &&
          istate.sectionHeaderOne !== 'iGem'
        ) {
          optionArr.forEach((element) => {
            if (element.data === 'reports' || element.data === 'notifications')
              tempArr.push({ ...element, disabled: true });
            else tempArr.push(element);
          });
        } else if (
          (action.payload.clickValue === 'assessments' ||
            action.payload.clickValue === 'assignments' ||
            action.payload.clickValue === 'associates') &&
          istate.sectionHeaderOne === 'iGem'
        ) {
          optionArr.forEach((element) => {
            if (element.data === 'reports' || element.data === 'notifications')
              tempArr.push({ ...element, disabled: true });
            else tempArr.push(element);
          });
        } else
          IGUAGE_POPUP_OPTION.forEach((element) => {
            tempArr.push(element);
          });
        return {
          ...istate,
          secondaryOptionCheckValue: action.payload.clickValue,
          currentPopUpOption: tempArr
        };
      }
    case CLEAR_IGAUGE_REDUCER:
      return JSON.parse(JSON.stringify(initialState));
    default:
      return istate;
  }
};

export default IgaugeReducer;
