import {
  LOADER_START,
  SET_MOBILE_PANE_STATE,
  SET_RELATED_REQUEST_OBJECT,
  GET_ASSESSMENTGROUP_ASSESSMENT_REVIEWLIST_SAGA,
  GET_ASSESSMENTTYPE_ASSESSMENT_REVIEWLIST_SAGA,
  FILTERMODE,
  SET_REQUEST_OBJECT,
  ASSESSMENT_REVIEW_DISTINCT_SAGA,
  GET_ASSESSMENT_GROUP_REVIEW_LIST_SAGA,
  GET_ASSESSMENT_TYPE_REVIEW_LIST_SAGA,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_POPUP_VALUE,
  SET_MIDDLEPANE_STATE,
  SET_CORE_GROUP_REVIEW_LIST_REQ_OBJECT,
  SET_CORE_TYPE_REVIEW_LIST_REQ_OBJECT,
  SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT,
  CLEAR_ASSESSMENT_INFO,
  GET_NODE_ASSESSMENTS_REVIEW_LIST_SAGA,
  SET_PAGE_COUNT,
  ASSESSMENT_INFO_REVISE_SAGA,
  ASSESSMENT_GROUP_REVISE_INFO_SAGA,
  ASSESSMENT_TYPE_REVISE_INFO_SAGA,
  SET_ASSESSMENT_DYNAMIC_SINGLE_STATE,
  ASSESSMENT_PUBLISH_SAGA,
  GET_ASSESSMENT_ITEM_REVIEW_LIST_SAGA,
  CLEAR_DISPLAY_PANE_THREE,
  ASSESSMENT_REVISE_SAGA,
  GET_ALLOCATE_ASSESSMENT,
  CLEAR_GROUP_REDUCER_STATE,
  INTERNAL_NODE_LIST_WITH_PERMISSIONS_SAGA,
  ASSESSMENT_MARKETPLACE_REVIEW_DISTINCT_SAGA,
  ASSESSMENT_MARKETPLACE_REVIEW_GROUP_SAGA,
  ASSESSMENT_MARKETPLACE_REVIEW_NODE_SAGA,
  ASSESSMENT_MARKETPLACE_REVIEW_TYPE_SAGA
} from '../actionType';
import {
  getAssessmentGroupAssessmentReqObj,
  getAssessmentGroupAssessmentScanReqObj,
  getAssessmentItemReqObj,
  getAssessmentItemScanReqObj,
  getAssessmentTypeAssessmentReqObj,
  getAssessmentTypeAssessmentScanReqObj,
  getNodeAssessmentsReqObj,
  getNodeAssessmentsScanReqObj,
  makeAssessmentGroupAllocatedObj,
  makeAssessmentGroupObj,
  makeAssessmentReviewListRequestObject,
  makeAssessmentTypeAllocateObj,
  makeAssessmentTypeObj,
  makeInternalNodeObj,
  makeMarketplaceAssessmentDistinctReviewListRequestObject,
  makeMarketplaceAssessmentGroupReviewListRequestObject,
  makeMarketplaceAssessmentNodeReviewListRequestObject,
  makeMarketplaceAssessmentTypeReviewListRequestObject
} from './GenericActions';

export const createAssessmentPopup = (
  selectedAssociateInfo,
  dispatch,
  secondaryOptionCheckValue,
  targetValue,
  assignmentCreateType
) => {
  dispatch({ type: LOADER_START });
  dispatch({ type: CLEAR_ASSESSMENT_INFO });
  dispatch({ type: SET_DISPLAY_TWO_SINGLE_STATE, payload: { stateName: "reviewListDistinctData", value: [] } })
  dispatch({ type: SET_DISPLAY_TWO_SINGLE_STATE, payload: { stateName: "reviewListDistinctDataDuplicate", value: [] } })

  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'selectedInformationAllorKey', value: secondaryOptionCheckValue }
  });
  let nodeRequestObj = makeInternalNodeObj(
    selectedAssociateInfo,
    'active',
    0,
    -1,
    '',
    'Associate-Self-Node-List-Create'
  );
  dispatch({ type: SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT, payload: nodeRequestObj });
  dispatch({
    type: INTERNAL_NODE_LIST_WITH_PERMISSIONS_SAGA,
    payload: {
      request: nodeRequestObj,
      BadgeOne: '',
      BadgeTwo: '',
      BadgeThree: '',
      nodeViewState: 'list',
      isMiddlePaneList: false
    }
  });
  let roleRequestObj = makeAssessmentTypeObj(selectedAssociateInfo, 'active', 0, -1);
  dispatch({ type: SET_CORE_TYPE_REVIEW_LIST_REQ_OBJECT, payload: roleRequestObj });
  dispatch({
    type: GET_ASSESSMENT_TYPE_REVIEW_LIST_SAGA,
    payload: {
      request: roleRequestObj,
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      BadgeThree: '',
      isMiddlePaneList: false
    }
  });
  let requestObj = makeAssessmentGroupObj(
    selectedAssociateInfo,
    'active',
    0,
    -1,
    'list',
    assignmentCreateType
  );
  dispatch({ type: SET_CORE_GROUP_REVIEW_LIST_REQ_OBJECT, payload: requestObj });
  dispatch({
    type: GET_ASSESSMENT_GROUP_REVIEW_LIST_SAGA,
    payload: {
      request: requestObj,
      BadgeOne: '',
      BadgeTwo: '',
      BadgeThree: '',
      isMiddlePaneList: false
    }
  });
  dispatch({
    type: SET_ASSESSMENT_DYNAMIC_SINGLE_STATE,
    payload: {
      stateName: 'assessmentGroup',
      actualStateName: 'assessmentGroupPrimary',
      value: []
    }
  });
  dispatch({
    type: SET_ASSESSMENT_DYNAMIC_SINGLE_STATE,
    payload: {
      stateName: 'assessmentNode',
      actualStateName: 'assessmentNodePrimary',
      value: []
    }
  });
  dispatch({
    type: SET_ASSESSMENT_DYNAMIC_SINGLE_STATE,
    payload: {
      stateName: 'assessmentType',
      actualStateName: 'assessmentTypePrimary',
      value: []
    }
  });
  dispatch({
    type: SET_POPUP_VALUE,
    payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'ASSESSMENTCREATE' }
  });
  dispatch({
    type: SET_MIDDLEPANE_STATE,
    payload: {
      middlePaneHeader: '',
      middlePaneHeaderBadgeOne: '',
      middlePaneHeaderBadgeTwo: '',
      middlePaneHeaderBadgeThree: '',
      middlePaneHeaderBadgeFour: '',
      typeOfMiddlePaneList: '',
      scanCount: null,
      showMiddlePaneState: false
    }
  });
};
export const getAssessmentGroupAssessmentDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  selectedTagValue,
  searchStr,
  isScan
) => {
  let reqBody = getAssessmentGroupAssessmentReqObj(
    selectedAssociateInfo,
    selectedTagValue,
    secondaryOptionCheckValue,
    0,
    countPage
  );
  if (isScan) {
    reqBody = getAssessmentGroupAssessmentScanReqObj(
      selectedAssociateInfo,
      selectedTagValue,
      secondaryOptionCheckValue,
      0,
      countPage,
      searchStr
    );
  }
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'relatedReviewListObjectCopy',
      value: reqBody
    }
  });
  // dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({
    type: SET_RELATED_REQUEST_OBJECT,
    payload: reqBody
  });
  dispatch({ type: LOADER_START });
  // dispatch({ type: SET_REQUEST_OBJECT, payload: reqBody });

  dispatch({
    type: GET_ASSESSMENTGROUP_ASSESSMENT_REVIEWLIST_SAGA,
    payload: {
      request: reqBody,
      HeaderOne: 'items',
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      BadgeThree: '',
      isMiddlePaneList: true
    }
  });
};
export const getAssessmentItemDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  selectedTagValue,
  searchStr,
  isScan
) => {
  let reqBody = {
    action: 'Review-List',
    module: 'Assessment-Distinct',
    actionFilter: 'Item',
    requestObject: getAssessmentItemReqObj(
      selectedAssociateInfo,
      selectedTagValue,
      secondaryOptionCheckValue,
      0,
      countPage
    )
  };

  if (isScan) {
    reqBody = {
      action: 'Review-List',
      module: 'Assessment-Distinct',
      actionFilter: 'Item',
      requestObject: getAssessmentItemScanReqObj(
        selectedAssociateInfo,
        selectedTagValue,
        secondaryOptionCheckValue,
        0,
        countPage,
        searchStr
      )
    };
  }
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'relatedReviewListObjectCopy',
      value: reqBody
    }
  });
  // dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({
    type: SET_RELATED_REQUEST_OBJECT,
    payload: reqBody
  });
  dispatch({ type: LOADER_START });
  dispatch({
    type: GET_ASSESSMENT_ITEM_REVIEW_LIST_SAGA,
    payload: {
      request: reqBody,
      HeaderOne: 'items',
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      BadgeThree: '',
      isMiddlePaneList: true
    }
  });
};
export const getAssessmentTypeAssessmentDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  selectedTagValue,
  searchStr,
  isScan
) => {
  let reqBody = getAssessmentTypeAssessmentReqObj(
    selectedAssociateInfo,
    selectedTagValue,
    secondaryOptionCheckValue,
    0,
    countPage
  );
  if (isScan) {
    reqBody = getAssessmentTypeAssessmentScanReqObj(
      selectedAssociateInfo,
      selectedTagValue,
      secondaryOptionCheckValue,
      0,
      countPage,
      searchStr
    );
  }
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'relatedReviewListObjectCopy',
      value: reqBody
    }
  });

  // dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({
    type: SET_RELATED_REQUEST_OBJECT,
    payload: reqBody
  });
  dispatch({ type: LOADER_START });
  // dispatch({ type: SET_REQUEST_OBJECT, payload: reqBody });
  dispatch({
    type: GET_ASSESSMENTTYPE_ASSESSMENT_REVIEWLIST_SAGA,
    payload: {
      request: reqBody,
      HeaderOne: 'assessments',
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      BadgeThree: '',
      isMiddlePaneList: true
    }
  });
};
export const getAssessmentDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  cardValue = 'NoCard',
  isSelectActive = '',
  assignmentCreateType
) => {
  let requestObect = makeAssessmentReviewListRequestObject(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    countPage,
    0,
    assignmentCreateType
  );
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: 'assessmentsDistinct' + secondaryOptionCheckValue }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'typeOfMiddlePaneList', value: '' }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: ASSESSMENT_REVIEW_DISTINCT_SAGA,
    payload: {
      request: requestObect,
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      isSelectActive: isSelectActive
    }
  });
};
export const getAssessmentGroupApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  cardValue = 'noCard',
  isSelectActive = '',
  groupViewState,
  selectedTagsArray = '',
  assignmentCreateType
) => {
  if (secondaryOptionCheckValue !== 'active') {
    //|| isSelectActive === 'multiple' for allocte different function used
    groupViewState = 'list';
  }
  let requestObj = makeAssessmentGroupObj(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    countPage,
    0,
    groupViewState,
    assignmentCreateType
  );
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: 'assessmentGroupDistinct' + secondaryOptionCheckValue }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'nodeViewState', value: groupViewState }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: GET_ASSESSMENT_GROUP_REVIEW_LIST_SAGA,
    payload: {
      request: requestObj,
      BadgeOne: targetValue,
      BadgeTwo: cardValue === 'Card' ? 'distinct' : secondaryOptionCheckValue,
      BadgeThree: cardValue === 'Card' ? secondaryOptionCheckValue : '',
      isMiddlePaneList: true,
      isSelectActive: isSelectActive,
      groupViewState,
      cardValue: cardValue,
      selectedTagsArray: selectedTagsArray
    }
  });
};

export const getAssessmentGroupAllocateApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  cardValue = 'noCard',
  isSelectActive = '',
  groupViewState,
  selectedTagsArray = '',
  assignmentCreateType,
  allocatedTagsArray
) => {
  if (secondaryOptionCheckValue !== 'active' || isSelectActive === 'multiple') {
    groupViewState = 'list';
  }
  let requestObj = makeAssessmentGroupAllocatedObj(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    countPage,
    0,
    groupViewState,
    assignmentCreateType,
    allocatedTagsArray
  );
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: 'assessmentGroupDistinct' + secondaryOptionCheckValue }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'nodeViewState', value: groupViewState }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: GET_ASSESSMENT_GROUP_REVIEW_LIST_SAGA,
    payload: {
      request: requestObj,
      BadgeOne: targetValue,
      BadgeTwo: cardValue === 'Card' ? 'distinct' : secondaryOptionCheckValue,
      BadgeThree: cardValue === 'Card' ? secondaryOptionCheckValue : '',
      isMiddlePaneList: true,
      isSelectActive: isSelectActive,
      groupViewState,
      cardValue: cardValue,
      selectedTagsArray: selectedTagsArray
    }
  });
};
export const getAssessmentTypeApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  cardValue = 'noCard',
  isSelectActive = '',
  actionFilter = ''
) => {
  let requestObj = makeAssessmentTypeObj(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    countPage,
    0,
    actionFilter
  );
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: 'assessmentsTypeDistinct' + secondaryOptionCheckValue }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: GET_ASSESSMENT_TYPE_REVIEW_LIST_SAGA,
    payload: {
      request: requestObj,
      BadgeOne: targetValue,
      BadgeTwo: cardValue === 'Card' ? 'distinct' : secondaryOptionCheckValue,
      BadgeThree: cardValue === 'Card' ? secondaryOptionCheckValue : '',
      isMiddlePaneList: true,
      isSelectActive: isSelectActive
    }
  });
};
export const getAssessmentTypeAllocateApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  cardValue = 'noCard',
  isSelectActive = '',
  allocatedTagsArray
) => {
  let requestObj = makeAssessmentTypeAllocateObj(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    countPage,
    0,
    allocatedTagsArray
  );
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: 'assessmentsTypeDistinct' + secondaryOptionCheckValue }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: GET_ASSESSMENT_TYPE_REVIEW_LIST_SAGA,
    payload: {
      request: requestObj,
      BadgeOne: targetValue,
      BadgeTwo: cardValue === 'Card' ? 'distinct' : secondaryOptionCheckValue,
      BadgeThree: cardValue === 'Card' ? secondaryOptionCheckValue : '',
      isMiddlePaneList: true,
      isSelectActive: isSelectActive
    }
  });
};

export const getNodeRelatedAssessmentsDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  selectedTagValue,
  searchStr,
  isScan,
  middlePaneHeader,
  isMiddlePaneList = true
) => {
  let reqBody = {
    module: 'Assessment-Distinct',
    action: 'Review-List',
    actionFilter: 'Associate-Node-Assessment',
    requestObject: getNodeAssessmentsReqObj(
      selectedAssociateInfo,
      selectedTagValue,
      secondaryOptionCheckValue,
      0,
      countPage
    )
  };

  if (isScan) {
    reqBody = {
      module: 'Assessment-Distinct',
      action: 'Review-List',
      actionFilter: 'Associate-Node-Assessment',
      requestObject: getNodeAssessmentsScanReqObj(
        selectedAssociateInfo,
        selectedTagValue,
        secondaryOptionCheckValue,
        0,
        countPage,
        searchStr
      )
    };
  }
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'relatedReviewListObjectCopy',
      value: reqBody
    }
  });
  // dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  if (isMiddlePaneList) {
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'relatedReviewListDistinctData', value: [] }
    });
  }
  dispatch({ type: LOADER_START });
  // dispatch({ type: SET_REQUEST_OBJECT, payload: reqBody });
  dispatch({
    type: GET_NODE_ASSESSMENTS_REVIEW_LIST_SAGA,
    payload: {
      request: reqBody,
      HeaderOne: middlePaneHeader,
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      BadgeThree: '',
      isMiddlePaneList: isMiddlePaneList
    }
  });
};
export const updateAssessmentDistinctStatus = (
  selectedAssociateInfo,
  assessmentId,
  dispatch,
  reviseStatus,
  createMode,
  secondaryOptionCheckValue
) => {
  if (reviseStatus === 'PUBLISHED') {
    let reqBody = {
      module: 'Assessment-Publish',
      action: 'Publish',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        assessmentId: assessmentId
      }
    };
    dispatch({ type: LOADER_START });
    dispatch({
      type: ASSESSMENT_PUBLISH_SAGA,
      payload: {
        secondaryOptionCheckValue: '',
        hideRightpane: true,
        headerOne: '',
        reqBody
      }
    });
  } else {
    let reqBody = {
      module: 'Assessment-Distinct',
      action: 'Revise',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        assessment: {
          id: assessmentId,
          informationEngagement: {
            assessmentStatus:
              ((reviseStatus === 'UNSUSPENDED' ||
                reviseStatus === 'UNTERMINATED' ||
                reviseStatus === 'UNARCHIVED') &&
                'UNPUBLISHED') ||
              reviseStatus
          }
        }
      }
    };
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'typeOfMiddlePaneList', value: '' }
    });
    dispatch({ type: LOADER_START });
    dispatch({
      type: ASSESSMENT_INFO_REVISE_SAGA,
      payload: {
        secondaryOptionCheckValue: secondaryOptionCheckValue,
        // hideRightpane: true,
        headerOne: '',
        reqBody,
        assessmentStatus: reviseStatus,
        isStatusRevise: createMode !== '' ? false : true,
        createMode
      }
    });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  }
};
export const updateAssessmentTypeStatus = (
  selectedAssociateInfo,
  typeId,
  dispatch,
  reviseStatus,
  createMode = ''
) => {
  let reqBody = {
    action: 'Revise',
    module: 'Assessment-Type',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      assessmentType: {
        id: typeId,
        informationEngagement: {
          assessmentTypeStatus:
            reviseStatus === 'UNSUSPENDED' ||
              reviseStatus === 'UNTERMINATED' ||
              reviseStatus === 'UNARCHIVED'
              ? 'ACTIVE'
              : reviseStatus
        }
      }
    }
  };
  dispatch({ type: LOADER_START });
  dispatch({
    type: ASSESSMENT_TYPE_REVISE_INFO_SAGA,
    payload: {
      secondaryOptionCheckValue: '',
      assessmentTypeAssessmentReqBody: null,
      assessmentGroupAssessmentReqBody: null,
      headerOne: '',
      reqBody
    }
  });
};

export const updateAssessmentFlagUpdate = (
  selectedAssociateInfo,
  Id,
  dispatch,
  reviseStatus,
  assesseeType,
  flagVal,
  createMode,
  secondaryOptionCheckValue
) => {
  let reqBody;
  let obj = {
    assesseeId: selectedAssociateInfo?.assesseeId,
    associateId:
      selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
    [assesseeType]: {
      id: Id,
      informationBasic: {
        [flagVal]: reviseStatus
      }
    }
  };
  if (assesseeType === 'assessment') {
    reqBody = {
      module: 'Assessment-Distinct',
      action: 'Revise',
      requestObject: { ...obj }
    };
  } else if (assesseeType === 'assessmentGroup') {
    reqBody = {
      module: 'Assessment-Group',
      action: 'Revise',
      requestObject: { ...obj }
    };
  } else if (assesseeType === 'assessmentType') {
    reqBody = {
      module: 'Assessment-Type',
      action: 'Revise',
      requestObject: { ...obj }
    };
  } else {
    reqBody = { ...obj };
  }
  dispatch({ type: LOADER_START });
  dispatch({
    type:
      assesseeType === 'assessment'
        ? ASSESSMENT_INFO_REVISE_SAGA
        : assesseeType === 'assessmentGroup'
          ? ASSESSMENT_GROUP_REVISE_INFO_SAGA
          : assesseeType === 'assessmentType'
            ? ASSESSMENT_TYPE_REVISE_INFO_SAGA
            : '',
    payload: {
      secondaryOptionCheckValue: secondaryOptionCheckValue,
      headerOne: '',
      reqBody,
      assessmentTypeAssessmentReqBody: null,
      assessmentGroupAssessmentReqBody: null,
      hideRightpane: createMode !== '' ? true : false,
      createMode: createMode,
      isStatusRevise: true
    }
  });
};
export const reviseAssessmentInformation = (
  selectedAssociateInfo,
  dispatch,
  id,
  informationBasic,
  informationAllocation,
  informationFramework,
  informationSetup,
  headerOneBadgeTwo,
  createMode
) => {
  const reqBody = {
    module: 'Assessment-Distinct',
    action: 'Revise',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      assessment: {
        id,
        informationBasic: informationBasic,
        informationAllocation: informationAllocation,
        informationSetup: informationSetup,
        informationFramework:
          delete informationFramework.assessmentSectionItemDistinctRevise && informationFramework
      }
    }
  };
  console.log('ASSESSMENT REVISE fododd===', reqBody);
  dispatch({ type: LOADER_START });
  dispatch({
    type: ASSESSMENT_REVISE_SAGA,
    payload: {
      reqBody
    }
  });
};

export const assessmentGruopApiCall = (
  responseObject,
  selectedAssociateInfo,
  selectedTagsArray,
  unselectedTagsArray,
  assessmentGroup,
  dispatch,
  createMode,
  isReviseCallFromPaneTwo = false,
  cardValue = 'noCard'
) => {
  const { associateId, id } = responseObject;
  const reqBody = {
    module: 'Assessment-Group',
    action: 'Revise',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      assessmentGroupAssessment: {
        assessmentGroupAssessmentAllocate: selectedTagsArray || [],
        assessmentGroupAssessmentUnallocate: unselectedTagsArray || []
      },
      assessmentGroup: {
        id,
        informationBasic: assessmentGroup.informationBasic,
        informationSetup: assessmentGroup.informationSetup,
        informationAllocation: assessmentGroup.informationAllocation
      }
    }
  };
  dispatch({ type: LOADER_START });
  let assessmentGroupAssessmentReqBody = getAssessmentGroupAssessmentReqObj(
    selectedAssociateInfo,
    id,
    '', //'all',
    -1,
    -1
  );
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'relatedReviewListDistinctData', value: [] }
  });
  dispatch({
    type: ASSESSMENT_GROUP_REVISE_INFO_SAGA,
    payload: {
      headerOne: 'assessments',
      reqBody,
      assessmentGroupAssessmentReqBody,
      createMode,
      cardValue,
      isReviseCallFromPaneTwo: isReviseCallFromPaneTwo
    }
  });
};

export const assessmentTypeApiCall = (
  responseObject,
  selectedAssociateInfo,
  selectedTagsArray,
  unselectedTagsArray,
  assessmentType,
  dispatch,
  createMode,
  isReviseCallFromPaneTwo = false
) => {
  const { associateId, id } = responseObject;
  console.log(assessmentType);
  const reqBody = {
    module: 'Assessment-Type',
    action: 'Revise',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      assessmentTypeAssessment: {
        assessmentTypeAssessmentAllocate: selectedTagsArray || [],
        assessmentTypeAssessmentUnallocate: unselectedTagsArray || []
      },
      assessmentType: {
        id,
        informationBasic: assessmentType.informationBasic,
        informationAllocation: assessmentType.informationAllocation,
        informationSetup: assessmentType.informationSetup
      }
    }
  };
  dispatch({ type: LOADER_START });
  let assessmentTypeAssessmentReqBody = getAssessmentTypeAssessmentReqObj(
    selectedAssociateInfo,
    id,
    '',
    -1,
    -1
  );
  dispatch({
    type: ASSESSMENT_TYPE_REVISE_INFO_SAGA,
    payload: {
      headerOne: 'assessments',
      reqBody,
      assessmentTypeAssessmentReqBody,
      createMode,
      isReviseCallFromPaneTwo: isReviseCallFromPaneTwo
    }
  });
};
export const getAssessmentDistinctToAllocate = (
  revisedRelatedObject,
  requestObect,
  existingAssessmentId,
  dispatch,
  filterMode,
  typeOfMiddlePaneList,
  selectedTagsArray = [],
  unselectedTagsArray = []
) => {
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: filterMode }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'relatedReviewListDistinctData', value: [] }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  // this code for assessments type revise List
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'relatedReviewListObjectCopy',
      value: requestObect
    }
  });
  // this code for assessments type revise List
  dispatch({
    type: GET_ALLOCATE_ASSESSMENT,
    payload: {
      request: requestObect,
      //BadgeTwo: 'all',
      revisedGroupObject: revisedRelatedObject,
      existingAssessmentId: [...existingAssessmentId, ...selectedTagsArray],
      typeOfMiddlePaneList: typeOfMiddlePaneList,
      unselectedTagsArray: unselectedTagsArray
    }
  });
};
export const getAssessmentGroupCreateAPI = (
  selectedAssociateInfo,
  dispatch,
  moduleName,
  assignmentCreateType
) => {
  let requestObj = makeAssessmentGroupObj(
    selectedAssociateInfo,
    'active',
    0,
    -1,
    'list',
    assignmentCreateType
  );
  dispatch({ type: SET_CORE_GROUP_REVIEW_LIST_REQ_OBJECT, payload: requestObj });
  dispatch({
    type: GET_ASSESSMENT_GROUP_REVIEW_LIST_SAGA,
    payload: {
      request: requestObj,
      BadgeOne: '',
      BadgeTwo: '',
      BadgeThree: '',
      isMiddlePaneList: false
    }
  });
  let nodeRequestObj = makeInternalNodeObj(
    selectedAssociateInfo,
    'active',
    0,
    -1,
    '',
    'Associate-Self-Node-List-Create'
  );
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT, payload: nodeRequestObj });
  dispatch({
    type: INTERNAL_NODE_LIST_WITH_PERMISSIONS_SAGA,
    payload: {
      request: nodeRequestObj,
      BadgeOne: '',
      BadgeTwo: '',
      BadgeThree: '',
      nodeViewState: 'list',
      isMiddlePaneList: false
    }
  });
  let roleRequestObj = makeAssessmentTypeObj(selectedAssociateInfo, 'active', 0, -1);
  dispatch({ type: SET_CORE_TYPE_REVIEW_LIST_REQ_OBJECT, payload: roleRequestObj });
  dispatch({
    type: GET_ASSESSMENT_TYPE_REVIEW_LIST_SAGA,
    payload: {
      request: roleRequestObj,
      BadgeOne: '',
      BadgeTwo: '',
      BadgeThree: '',
      isMiddlePaneList: false
    }
  });
  dispatch({ type: CLEAR_GROUP_REDUCER_STATE });
  dispatch({
    type: SET_POPUP_VALUE,
    payload: { isPopUpValue: 'NAMEPOPUP', popupMode: moduleName + 'GROUPCREATE' }
  });
};

export const getMarketplaceAssessmentDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  cardValue = 'NoCard',
  isSelectActive = '',
  assignmentCreateType
) => {
  let requestObect = makeMarketplaceAssessmentDistinctReviewListRequestObject(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    countPage,
    0,
    assignmentCreateType
  );
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: 'assessmentsDistinct' + secondaryOptionCheckValue }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'typeOfMiddlePaneList', value: '' }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: ASSESSMENT_MARKETPLACE_REVIEW_DISTINCT_SAGA,
    payload: {
      HeaderOne: "assessments",
      request: requestObect,
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      isSelectActive: isSelectActive
    }
  });
};
export const getMarketplaceAssessmentGroupApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  cardValue = 'noCard',
  isSelectActive = '',
  groupViewState,
  selectedTagsArray = '',
  assignmentCreateType,
  searchStr
) => {
  if (secondaryOptionCheckValue !== 'active') {
    //|| isSelectActive === 'multiple' for allocte different function used
    groupViewState = 'list';
  }
  let requestObj = makeMarketplaceAssessmentGroupReviewListRequestObject(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    countPage,
    0,
    groupViewState,
    assignmentCreateType,
    searchStr
  );
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: 'assessmentGroupDistinct' + secondaryOptionCheckValue }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'nodeViewState', value: groupViewState }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: ASSESSMENT_MARKETPLACE_REVIEW_GROUP_SAGA,
    payload: {
      request: requestObj,
      BadgeOne: targetValue,
      BadgeTwo: cardValue === 'Card' ? 'distinct' : secondaryOptionCheckValue,
      BadgeThree: cardValue === 'Card' ? secondaryOptionCheckValue : '',
      isMiddlePaneList: true,
      isSelectActive: isSelectActive,
      groupViewState,
      cardValue: cardValue,
      selectedTagsArray: selectedTagsArray
    }
  });
};

export const getMarketplaceAssessmentNodeApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  selectedTagValue,
  searchStr,
  isScan,
  middlePaneHeader,
  isMiddlePaneList = true
) => {
  let reqBody = {
    "module": "Assessment-Distinct",
    "action": "Review-List",
    "actionFilter": "Node-Shared-Assessment",
    requestObject: makeMarketplaceAssessmentNodeReviewListRequestObject(
      selectedAssociateInfo,
      selectedTagValue,
      secondaryOptionCheckValue,
      0,
      countPage
    )
  };

  if (isScan) {
    reqBody = {
      "module": "Assessment-Distinct",
      "action": "Review-List",
      "actionFilter": "Node-Shared-Assessment",
      requestObject: makeMarketplaceAssessmentNodeReviewListRequestObject(
        selectedAssociateInfo,
        selectedTagValue,
        secondaryOptionCheckValue,
        0,
        countPage,
        searchStr
      )
    };
  }
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'relatedReviewListObjectCopy',
      value: reqBody
    }
  });
  // dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  if (isMiddlePaneList) {
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'relatedReviewListDistinctData', value: [] }
    });
  }
  dispatch({ type: LOADER_START });
  // dispatch({ type: SET_REQUEST_OBJECT, payload: reqBody });
  dispatch({
    type: ASSESSMENT_MARKETPLACE_REVIEW_GROUP_SAGA,
    payload: {
      request: reqBody,
      HeaderOne: middlePaneHeader,
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      BadgeThree: '',
      isMiddlePaneList: isMiddlePaneList
    }
  });
};

export const getMarketplaceAssessmentTypeApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  selectedTagValue,
  searchStr,
  isScan
) => {
  let reqBody = makeMarketplaceAssessmentTypeReviewListRequestObject(
    selectedAssociateInfo,
    selectedTagValue,
    secondaryOptionCheckValue,
    0,
    countPage,
    isScan ? searchStr : ""
  );

  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'relatedReviewListObjectCopy',
      value: reqBody
    }
  });

  // dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({
    type: SET_RELATED_REQUEST_OBJECT,
    payload: reqBody
  });
  dispatch({ type: LOADER_START });
  // dispatch({ type: SET_REQUEST_OBJECT, payload: reqBody });
  dispatch({
    type: ASSESSMENT_MARKETPLACE_REVIEW_TYPE_SAGA,
    payload: {
      request: reqBody,
      HeaderOne: 'assessments',
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      BadgeThree: '',
      isMiddlePaneList: true
    }
  });
};
