import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AssociateMineAssignmentList } from '../Actions/AssociateModuleAction'
import ReviewList from '../Molecules/ReviewList/ReviewList'
import PopUpPaymentInfo from '../PopUpInformation/PopUpPaymentInfo'
import { SET_POPUP_STATE, SET_NEXT_POPUP, LOADER_START, LOADER_STOP, POPUP_OPEN, SET_DISPLAY_TWO_SINGLE_STATE, SET_MIDDLEPANE_STATE, SET_PAGE_COUNT } from '../actionType';
import { MINE_ASSIGNMENT_POPUP } from '../PopUpConfig';
import ListLoader from '../Atoms/ListLoader/ListLoader';
import { makeMineAssignmentScanRequestObject } from '../Actions/GenericActions';

const AssesseeDistinctAssignmentMineDistinctReviewList = () => {
    const dispatch = useDispatch();

    const {
        reviewListDistinctData,
        isSelectActive,
        selectedTagsArray,
        unselectedTagsArray,
        selectedAssociateInfo,
        isListLoading,
        numberPage,
        scanCount,
        countPage,
    } = useSelector((state) => state.DisplayPaneTwoReducer);
    const { FilterModeEnable, FilterMode } = useSelector((state) => state.FilterReducer);
    const { popupMode, isPopUpValue } = useSelector((state) => state.PopUpReducer);

    // const { reviewListDistinctData, selectedTagsArray, unselectedTagsArray } = useSelector((state) => state.SignOnReducer);

    const onClickCheckBoxSelection = (selectedTagsArray, unselectedTagsArray, event, dispatch) => {
        let id = event.target.id;
        let checkedArr = [...selectedTagsArray];
        let unCheckArr = [...unselectedTagsArray];
        if (checkedArr.includes(id)) {
            checkedArr = checkedArr.filter(function (number) {
                return number !== id;
            });
            unCheckArr.push(id);
        } else {
            checkedArr.push(id);
            unCheckArr = unCheckArr.filter(function (number) {
                return number !== id;
            });
        }
        dispatch({
            type: 'SET_DISPLAY_TWO_SINGLE_STATE',
            payload: { stateName: 'selectedTagsArray', value: checkedArr }
        });
        dispatch({
            type: 'SET_DISPLAY_TWO_SINGLE_STATE',
            payload: { stateName: 'unselectedTagsArray', value: unCheckArr }
        });
    };

    const fetchData = async () => {
        if (reviewListDistinctData.length < scanCount) {

            dispatch({
                type: SET_DISPLAY_TWO_SINGLE_STATE,
                payload: { stateName: 'isListLoading', value: true }
            })

            let reqBody = makeMineAssignmentScanRequestObject(selectedAssociateInfo?.assesseeId, selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary, '', numberPage, countPage, '', '', false, false);
            AssociateMineAssignmentList(reqBody, dispatch)

            // dispatch({ type: SET_PAGE_COUNT, payload: Math.round(parseInt(reviewListDistinctData.length) / countPage) - 1 })
            dispatch({ type: SET_PAGE_COUNT, payload: numberPage + 1 });

        }
    };

    const openAssesseeListPopup = (e) => {

        let tempArr = MINE_ASSIGNMENT_POPUP
        dispatch({
            type: SET_POPUP_STATE,
            payload: {
                popupHeaderOne: 'assignments',
                duplicateHeaderOneBadgeTwo: 'mine',
                isPopUpValue: '',
                popupOpenType: 'primary',
                popupContentArrValue: tempArr,
                selectedTagValue: e.currentTarget.getAttribute('tag'),
                selectedTagStatus: e.currentTarget.getAttribute('status'),
                // isFlaged: e.currentTarget.getAttribute('data-flag') === 'true' ? true : false
            }
        });

        dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });

    };

    // infinite scroll
    const [hasMore, setHasMore] = useState(true);
    let elementRef = useRef(null);

    const onIntersection = (entries) => {
        const firstEntry = entries[0];
        reviewListDistinctData.length >= scanCount ? setHasMore(false) : setHasMore(true)
        if (firstEntry.isIntersecting && hasMore && !isListLoading) {
            fetchData();
        }
    };

    useEffect(() => {
        // console.log('####MYREF######', elementRef)
        const observer = new IntersectionObserver(onIntersection, { threshold: 0.90 });

        if (observer && elementRef.current) {
            // console.log('here at observer...', elementRef.current)
            observer.observe(elementRef.current);
        }

        return () => {
            if (observer) observer.disconnect();
        };
    }, [isListLoading, hasMore, reviewListDistinctData, isSelectActive, selectedTagsArray, unselectedTagsArray]);

    // infinite scroll end

    return (
        <div>
            {isPopUpValue === 'PAYMENTINFO' && (
                <PopUpPaymentInfo
                    isActive={true}
                    headerPanelColour={'genericOne'}
                    headerOne={'assignments'}
                    headerOneBadgeOne={'information'}
                    nextPopUpValue={'CONFIRMATIONPOPUP'}
                    isSignOn={false}
                />
            )}
            <div >
                {reviewListDistinctData.length > 0 &&
                    reviewListDistinctData.map((data, index) => (
                        <div className="containerPadding" key={index} ref={elementRef}>
                            <ReviewList
                                textOne={data?.informationBasic?.assignmentName}
                                textTwo={data?.informationBasic?.assignmentDescription}
                                isFlagActive={false}
                                onClickEvent={openAssesseeListPopup}
                                status={`₹${data?.informationSetup?.assignmentShareFee ? parseFloat(data?.informationSetup?.assignmentShareFee) : 0.0}`}
                                actualStatus={data?.informationSetup?.assignmentShareFee ? parseFloat(data?.informationSetup?.assignmentShareFee) : 0.0}
                                isSelectActive={isSelectActive}
                                // isSelectActive={'multiple'}
                                isTooltipActive={false}
                                isAlertActive={false}
                                key={data.id}
                                tag={data.id}
                                // isDelivery={true}
                                isSelected={selectedTagsArray.includes(data.id)}
                                className={'reviewListFixedWidth'}
                                // onClickEvent={calculateTotalAmountOfSelectedAssignments}
                                onClickCheckBox={(event) => {
                                    onClickCheckBoxSelection(selectedTagsArray, unselectedTagsArray, event, dispatch);
                                }}
                            ></ReviewList>
                        </div>
                    ))
                }
            </div>
            {isListLoading ? <div> <ListLoader /> </div> : <div style={{ height: "60px", width: "100%" }}></div>}


        </div>
    )
}

export default AssesseeDistinctAssignmentMineDistinctReviewList
