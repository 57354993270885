import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import Popup from '../Molecules/PopUp/PopUp';
import PopupHeader from '../Molecules/PopUp/PopUpHeader';
import { Button, InputLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { POPUP_CLOSE, CLEAR_ASSESSEE_INFO, SET_NEXT_POPUP, PREVIOUS_POPUP } from '../actionType';
import '../Molecules/PopUp/PopUp.css';

const PopUpConfirm = (props) => {
  const {
    isActive,
    headerPanelColour,
    headerOne,
    headerOneBadgeOne,
    headerOneBadgeTwo,
    headerOneBadgeThree,
    onClickYes = null,
    onClickNoFun = null,
    inputHeader = '',
    inputHeaderBadgeOne = '',
    inputHeaderBadgeTwo = '',
    inputHeaderBadgeThree = '',
    mode = 'confirm'
  } = props;
  const dispatch = useDispatch();
  const { popupMode, isPopUpValue, prevPopUpValue } = useSelector((state) => state.PopUpReducer);

  const onClickNo = () => {
    if (mode === 'cancel') {
      dispatch({ type: SET_NEXT_POPUP, payload: { isPopUpValue: prevPopUpValue } });
    } else {
      onClickNoFun();
      dispatch({ type: CLEAR_ASSESSEE_INFO });
      dispatch({ type: POPUP_CLOSE });
    }
  };
  const handleBack = () => {
    /*according manage back state*/
    console.log('popupMode', popupMode);
    if (
      popupMode === 'ASSESSEE_SIGN_ON' ||
      popupMode === 'ASSESSEE_CREATE' ||
      popupMode === 'ADMINISTRATOR_CREATE'
    ) {
      dispatch({ type: PREVIOUS_POPUP, payload: { prevPopUpValue: isPopUpValue } });
      dispatch({ type: SET_NEXT_POPUP, payload: { isPopUpValue: 'ASSESSEENAMEPOPUP' } });
    }
    if (popupMode === 'ASSOCIATE_SIGN_ON' || popupMode === 'ASSOCIATE_CREATE') {
      dispatch({ type: SET_NEXT_POPUP, payload: { isPopUpValue: 'NAMEALIASPOPUP' } });
    }
    if (popupMode === 'GUAGEMETRICCREATE') {
      dispatch({ type: SET_NEXT_POPUP, payload: { isPopUpValue: 'LABELPOPUP' } });
    }
    if (
      popupMode === 'assesseesROLECREATE' ||
      popupMode === 'administratorsROLECREATE' ||
      popupMode === 'assistantsROLECREATE' ||
      popupMode === 'managersROLECREATE' ||
      popupMode === 'associatesROLECREATE' ||
      popupMode === 'assesseesGROUPCREATE' ||
      popupMode === 'administratorsGROUPCREATE' ||
      popupMode === 'managersGROUPCREATE' ||
      popupMode === 'assistantsGROUPCREATE' ||
      popupMode === 'associatesGROUPCREATE' ||
      popupMode === 'assignmentsGROUPCREATE' ||
      popupMode === 'assessmentsGROUPCREATE' ||
      popupMode === 'culture-profilesGROUPCREATE' ||
      popupMode === 'job-profilesGROUPCREATE' ||
      popupMode === 'itemsGROUPCREATE' ||
      popupMode === 'assesseesTYPECREATE' ||
      popupMode === 'administratorsTYPECREATE' ||
      popupMode === 'managersTYPECREATE' ||
      popupMode === 'assistantsTYPECREATE' ||
      popupMode === 'assessmentsTYPECREATE' ||
      popupMode === 'assignmentsTYPECREATE' ||
      popupMode === 'associatesTYPECREATE' ||
      popupMode === 'culture profilesTYPECREATE' ||
      popupMode === 'job-profilesTYPECREATE' ||
      popupMode === 'itemsTYPECREATE' ||
      popupMode === 'ASSESSMENTCREATE' ||
      popupMode === 'ASSIGNMENTCREATE' ||
      popupMode === 'NODECREATE' ||
      popupMode === 'CULTURECREATE' ||
      popupMode === 'JOBCREATE' ||
      popupMode === 'SCALECREATE' ||
      popupMode === 'CLUSTERCREATE' ||
      popupMode === 'SECTIONCREATE' ||
      popupMode === 'VERSIONCREATE' ||
      popupMode === 'NORMCREATE' ||
      popupMode === 'GUAGECREATE' ||
      popupMode === 'ITEMCREATE' ||
      popupMode === 'SECTIONCREATE' ||
      popupMode === 'GRADECREATE' 
      
    ) {
      dispatch({
        type: SET_NEXT_POPUP,
        payload: { isPopUpValue: 'NAMEPOPUP' }
      });
    }
  };
  return (
    <div>
      <Popup isActive={isActive}>
        <PopupHeader
          headerPanelColour={headerPanelColour}
          headerOne={headerOne}
          headerOneBadgeOne={headerOneBadgeOne}
          headerOneBadgeTwo={headerOneBadgeTwo}
          headerOneBadgeThree={headerOneBadgeThree}
          mode={mode}
          onClick={handleBack}
        />
        <DialogContent
          className={['popupContent', 'fixed10PadDim', 'revisePopupContent'].join(' ')}
        >
          {inputHeader !== '' && (
            <div className={'fitContent'}>
              <div className={['PopupFormBox', 'labelPopupBox', 'popupMinHei'].join(' ')}>
                <InputLabel htmlFor="name-input" className={'textForLabelPopup'}>
                  <>
                    {inputHeader}&nbsp;
                    {inputHeaderBadgeOne ? (
                      <span className={'headerBadge'}>{inputHeaderBadgeOne}</span>
                    ) : null}
                    &nbsp;
                    {inputHeaderBadgeTwo ? (
                      <span className={'headerBadge'}>{inputHeaderBadgeTwo}</span>
                    ) : null}
                    &nbsp;
                    {inputHeaderBadgeThree ? (
                      <span className={'headerBadge'}>{inputHeaderBadgeThree}</span>
                    ) : null}
                  </>
                </InputLabel>
                {/* <div className={'infoSymbol'}></div> */}
                <div className={'infoSymbol'}>{/* <InfoToolTip message={infoMsg} /> */}</div>
              </div>
            </div>
          )}
          <div id="dialog-description">
            <div className="true">
              <div className={'tickOption'}>
                <div>
                  <Button className={'optionPrimary'} data-value="no" onClick={onClickNo}>
                    no
                  </Button>
                </div>
                <div>
                  <Button className={'optionPrimary'} data-value="yes" onClick={onClickYes}>
                    yes
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Popup>
    </div>
  );
};

PopUpConfirm.propTypes = {
  headerPanelColour: PropTypes.oneOf([
    'displayPaneLeft',
    'displayPaneCentre',
    'displayPaneRight',
    'genericOne',
    'genericTwo'
  ]), //new changes
  // displayPane: PropTypes.oneOf(['centre', 'core', 'left', 'right']), //old
  headerOne: PropTypes.string,
  // className: null,
  isActive: PropTypes.bool
};
export default PopUpConfirm;
