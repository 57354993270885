import React from 'react';
import { isMobile } from 'react-device-detect';
// import AllocationAccordian from '../Accordian/AllocationAccordian';
// import Manuscript from '@material-ui/icons/Description';
import { useDispatch, useSelector } from 'react-redux';
import DisplayPanelAccordianReviewListOne from '../Accordian/DisplayPanelAccordianReviewListOne';
import DisplayPanelAccordianInformation from '../Accordian/DisplayPanelAccordianInformation';
import { Paper } from '@material-ui/core';
import { makeAssessmentReviewListRequestObject } from '../../Actions/GenericActions';
import {
  FILTERMODE,
  GET_ALLOCATE_ASSESSMENT,
  LOADER_START,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_PAGE_COUNT,
  SET_REQUEST_OBJECT
} from '../../actionType';
import {
  getAssessmentDistinctToAllocate,
  getAssessmentGroupAssessmentDistinctApiCall
} from '../../Actions/AssessmentModuleAction';

const DisplayPaneThreeSectionTwoAssessmentGroup = () => {
  // const [listExpand, setListExpand] = useState('');
  const { reviewMode, relatedReviewListPaneThree, responseObject } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  const { selectedAssociateInfo, countPage, reviewListDistinctData,assignmentCreateType } = useSelector(
    (state) => state.DisplayPaneTwoReducer
  );
  const dispatch = useDispatch();
  // const { informationEngagement, informationSetup } = responseObject;
  // function capitalizeFirstLetter(string) {
  //   if (!string) return '';
  //   return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  // }
  let assessmentList = [];
  if (relatedReviewListPaneThree) {
    assessmentList = relatedReviewListPaneThree?.assessment;
  }
  let assessmentArray = [];
  assessmentList &&
    assessmentList.forEach((ob) => {
      const { id, informationBasic, informationEngagement, associateId } = ob;
      assessmentArray.push({
        id,
        textOne: informationBasic?.assessmentName || '',
        textTwo: informationBasic?.assessmentDescription || '',
        status: informationEngagement?.assessmentStatus || '',
        isShared: ob?.assessmentShared,
      associateId
      });
    });

  const list2 = [
    {
      id: 'a1',
      labelTextOneOne: 'assessments',
      labelTextOneOneBadgeOne: '',
      labelTextOneOneBadgeTwo: '',
      labelTextOneOneBadgeThree: '',
      labelTextOneOneBadgeFour: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'distinct',
          innerList: assessmentArray
        }
      ],
      innerInfo: 'No Information',
      isListCard: true,
      isReviewLink: true
    }
  ];
  const onclickReviewAssessment = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    if (labelName === 'assessments' && selectedBadgeName === 'distinct') {
      getAssessmentGroupAssessmentDistinctApiCall(
        selectedAssociateInfo,
        '',
        //'all',
        -1,
        dispatch,
        'distinct',
        responseObject.id, //group id
        '',
        false,
        true
      );
    }
  };

  const onclickReviseAssessment = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    if (labelName === 'assessments' && selectedBadgeName === 'distinct') {
      console.log('assessment CLICK :::::::>>>>>>>', relatedReviewListPaneThree);
      let requestObect = makeAssessmentReviewListRequestObject(
        selectedAssociateInfo,
        '',
        //'activeinactive',
        countPage,
        0,
        ''
      );
      dispatch({ type: SET_PAGE_COUNT, payload: 0 });
      let revisedGroupObject = {
        id: responseObject.id,
        assessmentGroupName: responseObject.informationBasic.assessmentGroupName,
        assessmentGroupDescription: responseObject.informationBasic.assessmentGroupDescription,
        assessmentGroupStatus: responseObject.informationEngagement.assessmentGroupStatus
      };
      console.log("relatedReviewListPaneThree[0]",relatedReviewListPaneThree);
      let existingAssessmentId = [];
      let tempArr = relatedReviewListPaneThree?.assessment || [];
      existingAssessmentId = tempArr.map((val) => {
        return val.id;
      });

      console.log('HelooE', existingAssessmentId);
      getAssessmentDistinctToAllocate(
        revisedGroupObject,
        requestObect,
        existingAssessmentId,
        dispatch,
        'assessmentGroupAssessmenteRevise',
        'assessmentGroupAssessmentReviewList'
      );
      dispatch({ type: SET_PAGE_COUNT, payload: 0 });
    }
  };
  
  return (
    <div>
      <>
        <div className={'containerPadding'}>
          <Paper className={'dossierContainerTop'}>
            {list2.map((ob) => {
              return (
                <div key={ob.id}>
                  {ob.isListCard ? (
                    <DisplayPanelAccordianReviewListOne
                      onClickReview={onclickReviewAssessment}
                      onClickRevise={onclickReviseAssessment}
                      className=""
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  ) : (
                    <DisplayPanelAccordianInformation
                      onClickRevise={onclickReviseAssessment}
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  )}
                </div>
              );
            })}
          </Paper>
        </div>
      </>
      {isMobile && (
        <div className={'containerPadding'} style={{ height: '55px' }}>
          {' '}
        </div>
      )}
    </div>
  );
};

export default DisplayPaneThreeSectionTwoAssessmentGroup;
