import { put, takeLatest, call } from 'redux-saga/effects';
import {
  CLEAR_DISPLAY_PANE_THREE,
  CREATE_GAUGE_SAGA,
  GET_GAUGE_REVISE_STATUS_SAGA,
  LOADER_STOP,
  RELATED_REVIEWLIST_DISTINCT_DATA,
  REVIEWLIST_DISTINCT_DATA,
  SET_DISPLAY_PANE_THREE_STATE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_GUAGE_REDUCER_STATE,
  SET_MIDDLEPANE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_POPUP_SINGLE_STATE,
  SET_POPUP_VALUE,
  SET_REQUEST_OBJECT
} from '../../actionType';
import { JOB_MODULE_URL } from '../../endpoints';
import { EXCEPTION_ERROR_MESSAGE } from '../../errorMessage';
import store from '../../store';
import Store from '../../store';

const apiCallFunction = async (requestObj) => {
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      Authorization: localStorage.getItem('idToken')
    }),
    body: JSON.stringify(requestObj.data)
  };
  const response = await fetch(requestObj.URL, requestOptions);
  const json = await response.json();
  return json;
};
function* workerGaugeCreateSaga(data) {
  try {
    const apiResponse = yield call(apiCallFunction, {
      data: data.payload.reqBody,
      URL: JOB_MODULE_URL
    });
    const {
      action,
      isMiddlePaneList = false,
      BadgeOne,
      BadgeTwo,
      afterAction,
      assignmentSelectedGuage,
      createMode,
      isSelectActive,
      selectedTagsArray,
      assessmentSector,
      subaction = ''
    } = data.payload;
    if (apiResponse.responseCode === '000') {
      if (action === 'create') {
        yield put({
          type: SET_DISPLAY_PANE_THREE_STATE,
          payload: {
            headerOne: 'gauge',
            headerOneBadgeOne: 'distinct',
            headerOneBadgeTwo: 'information',
           headerOneBadgeThree: data.payload.secondaryOptionCheckValue || 'key',
            responseObject: apiResponse.responseObject,
            reviewMode: 'revise',
            //reviewMode: data.payload.reviewMode,
            createMode: 'gauge'
          }
        });
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'reviewListDistinctData', value: [] }
        });
        yield put({
          type: SET_POPUP_SINGLE_STATE,
          payload: {
            stateName: 'selectedTagStatus',
            value: apiResponse.responseObject?.iGaugeOccupationFrameworkOneGaugeStatus
          }
        });
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'middlePaneSelectedValue', value: apiResponse.responseObject?.id }
        });
        yield put({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'selectedTagValue', value: apiResponse.responseObject?.id }
        });
        yield put({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
      }
      if (action === 'review') {
        yield put({
          type: SET_DISPLAY_PANE_THREE_STATE,
          payload: {
            headerOne: 'gauge',
            headerOneBadgeOne: 'distinct',
            headerOneBadgeTwo: 'information',
            headerOneBadgeThree: data.payload.secondaryOptionCheckValue || 'key',
            responseObject: apiResponse.responseObject,
            reviewMode: data.payload.reviewMode,
            createMode: createMode
          }
        });
        yield put({ type: SET_GUAGE_REDUCER_STATE, payload: apiResponse.responseObject });
        yield put({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
      }
      if (action === 'revise' && subaction !== 'metricsCreate') {
        // let response = apiResponse.responseObject?.length> 0 ?apiResponse.responseObject.map((gg)=> {return gg.id ===reqBody?.requestObject?.iGaugeOccupationFrameworkOneGauge?.id}) : apiResponse.responseObject
        if (assignmentSelectedGuage) {
          debugger;
          let response = {
            ...apiResponse.responseObject,
            iGaugeOccupationFrameworkOneGaugeMetric:
              apiResponse.responseObject.iGaugeOccupationFrameworkOneGaugeMetric[
                assignmentSelectedGuage
              ]
          };
          yield put({
            type: SET_DISPLAY_PANE_THREE_STATE,
            payload: {
              headerOne: 'gauge',
              headerOneBadgeOne: 'distinct',
              headerOneBadgeTwo: 'information',
             headerOneBadgeThree: data.payload.secondaryOptionCheckValue || 'key',
              responseObject: response,
              reviewMode: data.payload.reviewMode,
              createMode: createMode
            }
          });
        } else {
          if (createMode !== '') {
            yield put({
              type: SET_DISPLAY_PANE_THREE_STATE,
              payload: {
                headerOne: 'gauge',
                headerOneBadgeOne: 'distinct',
                headerOneBadgeTwo: 'information',
               headerOneBadgeThree: data.payload.secondaryOptionCheckValue || 'key',
                responseObject: apiResponse.responseObject,
                reviewMode: assessmentSector==='updategauge'?'revise':'',
                createMode: createMode
              }
            });
          } else yield put({ type: CLEAR_DISPLAY_PANE_THREE });
        }
        if (createMode == '') {
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
          yield put({
            type: CREATE_GAUGE_SAGA,
            payload: {
              reqBody: store.getState().DisplayPaneTwoReducer.reviewListReqObj,
              action: 'reviewList',
              isMiddlePaneList: true,
              BadgeOne: store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo
            }
          });
        }
        yield put({ type: SET_GUAGE_REDUCER_STATE, payload: apiResponse.responseObject });

        yield put({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
      }
      if (action === 'reviewList') {
        if (isMiddlePaneList) {
          yield put({
            type: SET_MIDDLEPANE_STATE,
            payload: {
              middlePaneHeader: 'gauges',
              middlePaneHeaderBadgeOne: BadgeOne,
              middlePaneHeaderBadgeTwo: BadgeTwo,
              middlePaneHeaderBadgeThree: '',
              middlePaneHeaderBadgeFour: '',
              middlePaneHeaderDuplicate: 'gauges',
              middlePaneHeaderDuplicateBadgeOne: BadgeOne,
              middlePaneHeaderDuplicateBadgeTwo: BadgeTwo,
              middlePaneHeaderDuplicateBadgeThree: '',
              middlePaneHeaderDuplicateBadgeFour: '',
              typeOfMiddlePaneList: 'gaugeDistinctReviewList',
              scanCount: apiResponse && apiResponse?.countTotal,
              duplicateScanCount: apiResponse && apiResponse?.countTotal,
              showMiddlePaneState: true,
              isSelectActive: isSelectActive,
              selectedTagsArray: selectedTagsArray
            }
          });
          let tempArr = apiResponse.responseObject.sort((a, b) =>
            a.iGaugeOccupationFrameworkOneGaugeName.toLowerCase() !==
            b.iGaugeOccupationFrameworkOneGaugeName.toLowerCase()
              ? a.iGaugeOccupationFrameworkOneGaugeName.toLowerCase() <
                b.iGaugeOccupationFrameworkOneGaugeName.toLowerCase()
                ? -1
                : 1
              : 0
          );

          yield put({ type: REVIEWLIST_DISTINCT_DATA, payload: tempArr });
        }
      }
      if (action === 'reviewMetricReviewList') {
        if (isMiddlePaneList) {
          yield put({
            type: SET_MIDDLEPANE_STATE,
            payload: {
              middlePaneHeader: 'metrics',
              middlePaneHeaderBadgeOne: 'distinct',
              middlePaneHeaderBadgeTwo: 'active',
              middlePaneHeaderBadgeThree: '',
              middlePaneHeaderBadgeFour: '',
              middlePaneHeaderDuplicate: 'gauges',
              middlePaneHeaderDuplicateBadgeOne:
                Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
              middlePaneHeaderDuplicateBadgeTwo:
                Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
              middlePaneHeaderDuplicateBadgeThree:
                Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
              middlePaneHeaderDuplicateBadgeFour:
                Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour,
              typeOfMiddlePaneList: 'gaugeDistinctMetricDistinctReviewList',
              scanCount:
                apiResponse &&
                apiResponse?.responseObject?.iGaugeOccupationFrameworkOneGaugeMetric?.length,
              duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
              showMiddlePaneState: true
            }
          });
          apiResponse?.responseObject.iGaugeOccupationFrameworkOneGaugeMetric?.sort((a, b) =>
            a.iGaugeOccupationFrameworkOneGaugeMetricLabel !==
            b.iGaugeOccupationFrameworkOneGaugeMetricLabel
              ? a.iGaugeOccupationFrameworkOneGaugeMetricLabel <
                b.iGaugeOccupationFrameworkOneGaugeMetricLabel
                ? -1
                : 1
              : 0
          );
          yield put({
            type: RELATED_REVIEWLIST_DISTINCT_DATA,
            payload: [apiResponse?.responseObject]
          });
        }
      }
      if (action === 'metricsCreate') {
        yield put({
          type: SET_DISPLAY_PANE_THREE_STATE,
          payload: {
            headerOne: 'metrics',
            headerOneBadgeOne: 'distinct',
            headerOneBadgeTwo: 'information',
           headerOneBadgeThree: data.payload.secondaryOptionCheckValue || 'key',
            responseObject: apiResponse.responseObject,
            // reviewMode: isReviseMode ? 'revise' : '',
            createMode: createMode
          }
        });
        yield put({ type: SET_GUAGE_REDUCER_STATE, payload: apiResponse.responseObject });
        yield put({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
      }
      yield put({ type: LOADER_STOP });
    } else {
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: apiResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerGaugereviseStatusSaga(data) {
  try {
    const apiResponse = yield call(apiCallFunction, {
      data: data.payload.reqBody,
      URL: JOB_MODULE_URL
    });
    const { action, isMiddlePaneList, selectedTagValue, createMode } = data.payload;
    if (apiResponse.responseCode === '000') {
      console.log('createMode>>>', createMode);
      if (createMode === 'gauge') {
        if (data.payload.reviseStatus === 'PUBLISHED') {
          yield put({ type: LOADER_STOP });
        } else {
          yield put({
            type: SET_DISPLAY_PANE_THREE_STATE,
            payload: {
              headerOne: 'gauge',
              headerOneBadgeOne: 'distinct',
              headerOneBadgeTwo: 'information',
             headerOneBadgeThree: data.payload.secondaryOptionCheckValue || 'key',
              responseObject: apiResponse.responseObject,
              reviewMode: 'revise',
              //reviewMode: data.payload.reviewMode,
              createMode: 'gauge'
            }
          });
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
          yield put({
            type: SET_POPUP_SINGLE_STATE,
            payload: {
              stateName: 'selectedTagStatus',
              value: apiResponse.responseObject?.iGaugeOccupationFrameworkOneGaugeStatus
            }
          });
          console.log(
            'staus>>>>',
            apiResponse.responseObject?.iGaugeOccupationFrameworkOneGaugeStatus
          );
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'middlePaneSelectedValue', value: apiResponse.responseObject?.id }
          });
          yield put({
            type: SET_POPUP_SINGLE_STATE,
            payload: { stateName: 'selectedTagValue', value: apiResponse.responseObject?.id }
          });
          yield put({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
          yield put({ type: LOADER_STOP });
        }
      }
      if (createMode === '') {
        yield put({
          type: CREATE_GAUGE_SAGA,
          payload: {
            reqBody: store.getState().DisplayPaneTwoReducer.reviewListReqObj,
            action: 'reviewList',
            isMiddlePaneList: true,
            BadgeOne: store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
            BadgeTwo: store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo
          }
        });

        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'reviewListDistinctData', value: [] }
        });
      }
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: apiResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
      yield put({ type: LOADER_STOP });
    }
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
export default function* watcherGaugeSaga() {
  yield takeLatest(CREATE_GAUGE_SAGA, workerGaugeCreateSaga);
  yield takeLatest(GET_GAUGE_REVISE_STATUS_SAGA, workerGaugereviseStatusSaga);
}
