import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ListLoader from '../Atoms/ListLoader/ListLoader';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import {
  ASSESSEE_REVIEW_DISTINCT_SAGA,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_PAGE_COUNT
} from '../actionType';

const AssesseeMarketPlaceDistinctReviewList = () => {
  const dispatch = useDispatch();
  // const { secondaryOptionCheckValue } = useSelector((state) => state.AssesseeCreateReducer);
  const {
    numberPage,
    scanCount,
    countPage,
    middlePaneHeader,
    middlePaneHeaderBadgeOne,
    middlePaneHeaderBadgeTwo,
    reviewListDistinctData,
    reviewListReqObj,
    middlePaneSelectedValue,
    typeOfMiddlePaneList,
    selectedAssociateInfo,
    isSelectActive,
    selectedTagsArray,
    unselectedTagsArray,
    selectedFlagedArray,
    unselectedFlagedArray,
    allocatedTagsArray,
    flagedValue,
    allocateStr,
    signedAssesseePermission,
    paneOneSelectedSection,
    isListLoading
  } = useSelector((state) => state.DisplayPaneTwoReducer);

  const fetchData = async () => {
    if (reviewListDistinctData.length < scanCount) {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isListLoading', value: true }
      });

      let obj = {
        ...reviewListReqObj.requestObject,
        numberPage: numberPage + 1
      };
      // setTimeout(() => {
      dispatch({
        type: ASSESSEE_REVIEW_DISTINCT_SAGA,
        payload: {
          request: { module: 'Assessee-Distinct', action: 'Review-List', requestObject: obj },
          HeaderOne: middlePaneHeader,
          BadgeOne: 'distinct',
          BadgeTwo: middlePaneHeaderBadgeTwo,
          isMiddlePaneList: true,
          isSelectActive: isSelectActive,
          selectedTagsArray: selectedTagsArray || [],
          unselectedTagsArray: unselectedTagsArray || []
        }
      });
      dispatch({ type: SET_PAGE_COUNT, payload: numberPage + 1 });
      // }, 3000);
    }
  };

  const [hasMore, setHasMore] = useState(true);
  let elementRef = useRef(null);

  const onIntersection = (entries) => {
    const firstEntry = entries[0];
    reviewListDistinctData.length >= scanCount ? setHasMore(false) : setHasMore(true);
    if (firstEntry.isIntersecting && hasMore && !isListLoading) {
      fetchData();
    }
  };

  useEffect(() => {
    // console.log('####MYREF######', elementRef)
    const observer = new IntersectionObserver(onIntersection, { threshold: 0.90 });

    if (observer && elementRef.current) {
      // console.log('here at observer...', elementRef.current)
      observer.observe(elementRef.current);
    }

    return () => {
      if (observer) observer.disconnect();
    };
  }, [
    isListLoading,
    hasMore,
    reviewListDistinctData,
    isSelectActive,
    selectedTagsArray,
    unselectedTagsArray
  ]);

  return (
    <div>
      {reviewListDistinctData &&
        reviewListDistinctData.map((item, index) => {
          return (
            <div className="containerPadding" key={index} ref={elementRef}>
              {
                <ReviewList
                  className=""
                  id={index}
                  tag={
                    item.informationEngagement.assesseeTag
                      ? item.informationEngagement.assesseeTag.assesseeTagPrimary
                      : item
                  }
                  associateId={item?.associateId}
                  //  status={assesseeStatus(
                  //    middlePaneHeaderBadgeTwo,
                  //   item.informationEngagement.assesseeStatus
                  // )}
                  status={item.informationEngagement.assesseeStatus}
                  actualStatus={item.informationEngagement.assesseeStatus}
                  isSelectedReviewList={
                    middlePaneSelectedValue ===
                    item.informationEngagement.assesseeTag?.assesseeTagPrimary
                      ? true
                      : false
                  }
                  textOne={
                    item.informationBasic.assesseeNameFirst +
                    ' ' +
                    item.informationBasic.assesseeNameOther +
                    ' ' +
                    item.informationBasic.assesseeNameLast +
                    ' ' +
                    item.informationBasic.assesseeNameSuffix
                  }
                  textTwo={item.informationBasic.assesseeAlias}
                  isTooltipActive={false}
                  // onClickEvent={openAssesseeListPopup}
                  isFlagActive={selectedFlagedArray.includes(
                    item.informationEngagement.assesseeTag?.assesseeTagPrimary
                  )}
                  isShared={item?.assesseeShared}
                  flagedValue={flagedValue}
                  isSelectActive={isSelectActive}
                  isSelected={selectedTagsArray.includes(
                    item.informationEngagement.assesseeTag?.assesseeTagPrimary
                  )}
                  // onClickCheckBox={(event) => {
                  //     !isListLoading &&
                  //         onClickCheckBoxSelection(
                  //             selectedTagsArray,
                  //             unselectedTagsArray,
                  //             event,
                  //             dispatch
                  //         );
                  // }}
                  // disableonClickCheckBox={item.associateId !== signedAssociateId ? true : false}
                  // onClickAddFladed={(event) => {
                  //     onClickFlagSelection(
                  //         selectedFlagedArray,
                  //         unselectedFlagedArray,
                  //         event,
                  //         dispatch
                  //     );
                  // }}
                />
              }
            </div>
          );
        })}
      {isListLoading ? (
        <div>
          {' '}
          <ListLoader />{' '}
        </div>
      ) : <div style={{ height: "60px", width: "100%" }}></div>}
    </div>
  );
};

export default AssesseeMarketPlaceDistinctReviewList;
