import { put, takeLatest, call } from 'redux-saga/effects';
import {
  GET_ASSESSEE_TYPE_REVIEW_LIST_SAGA,
  GET_ASSESSMENT_TYPE_REVIEW_LIST_SAGA,
  GET_ASSIGNMENT_TYPE_REVIEW_LIST_SAGA,
  GET_ASSOCIATE_TYPE_REVIEW_LIST_SAGA,
  GET_CULTUREPROFILE_TYPE_REVIEW_LIST_SAGA,
  GET_ITEM_TYPE_REVIEW_LIST_SAGA,
  GET_JOBPROFILE_TYPE_REVIEW_LIST_SAGA,
  LOADER_STOP,
  REVIEWLIST_DISTINCT_DATA,
  SET_CORE_TYPE_REVIEW_LIST_DATA,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MIDDLEPANE_STATE,
  SET_POPUP_VALUE
} from '../../actionType';
import {
  ASSIGNMENT_MODULE_URL,
  ASSESSMENT_MODULE_URL,
  ITEM_MODULE_URL,
  CULTURE_MODULE_URL,
  JOB_MODULE_URL,
  ASSOCIATE_MODULE_URL,
  ASSESSEE_MODULE_URL
} from '../../endpoints';
import { EXCEPTION_ERROR_MESSAGE } from '../../errorMessage';

const TypesReviewListDistinctApi = async (requestObj) => {
  console.log(requestObj.data);
  // let URL = ASSESSEE_GROUP_REVIEWLIST_URL;
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      Authorization: requestObj?.idIdToken
        ? localStorage.getItem('idToken')
        : localStorage.getItem('token')
    }),
    body: JSON.stringify(requestObj.data)
  };
  const response = await fetch(requestObj.URL, requestOptions);
  const json = await response.json();
  return json;
};

function* workerReviewAssesseeTypeListSaga(data) {
  try {
    const userResponse = yield call(TypesReviewListDistinctApi, {
      data: data.payload.request,
      URL: ASSESSEE_MODULE_URL
    });
    const { middlePaneHeader, HeaderOne, actionFilter } = data.payload;
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === '000') {
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: middlePaneHeader
              ? middlePaneHeader
              : HeaderOne
                ? HeaderOne
                : 'assessees',
            middlePaneHeaderBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderBadgeThree: data.payload.BadgeThree,
            middlePaneHeaderBadgeFour: '',
            middlePaneHeaderDuplicate: middlePaneHeader
              ? middlePaneHeader
              : HeaderOne
                ? HeaderOne
                : 'assessees',
            middlePaneHeaderDuplicateBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderDuplicateBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderDuplicateBadgeThree: data.payload.BadgeThree,
            middlePaneHeaderDuplicateBadgeFour: '',
            typeOfMiddlePaneList:
              actionFilter === 'Shared-Assessee'
                ? 'MarketplaceAssesseesTypeDistinctReviewList'
                : 'assesseesTypeDistinctReviewList',
            scanCount: userResponse && userResponse.countTotal,
            duplicateScanCount: userResponse && userResponse.countTotal,
            showMiddlePaneState: true,
            isSelectActive: data.payload.isSelectActive,
            selectedTagsArray: data.payload.selectedTagsArray
          }
        });
      }
      yield put({
        type: data.payload.isMiddlePaneList
          ? REVIEWLIST_DISTINCT_DATA
          : SET_CORE_TYPE_REVIEW_LIST_DATA,
        payload: userResponse.responseObject
      });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerReviewAssociateTypeListSaga(data) {
  try {
    const userResponse = yield call(TypesReviewListDistinctApi, {
      data: data.payload.request,
      URL: ASSOCIATE_MODULE_URL
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === '000') {
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: 'associates',
            middlePaneHeaderBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderBadgeThree: data.payload.BadgeThree,
            middlePaneHeaderBadgeFour: data.payload.BadgeFour,
            middlePaneHeaderDuplicate: 'associates',
            middlePaneHeaderDuplicateBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderDuplicateBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderDuplicateBadgeThree: data.payload.BadgeThree,
            middlePaneHeaderDuplicateBadgeFour: data.payload.BadgeFour,
            typeOfMiddlePaneList: 'associatesTypeDistinctReviewList',
            scanCount: userResponse && userResponse.countTotal,
            duplicateScanCount: userResponse && userResponse.countTotal,
            showMiddlePaneState: true,
            isSelectActive: data.payload.isSelectActive,
            selectedTagsArray: data.payload.selectedTagsArray // selected array should nt get remove from search
          }
        });
      }
      yield put({
        type: data.payload.isMiddlePaneList
          ? REVIEWLIST_DISTINCT_DATA
          : SET_CORE_TYPE_REVIEW_LIST_DATA,
        payload: userResponse.responseObject
      });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }

    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerReviewItemTypeListSaga(data) {
  try {
    const userResponse = yield call(TypesReviewListDistinctApi, {
      data: data.payload.request,
      URL: ITEM_MODULE_URL
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === '000') {
      console.log('data.payload.BadgeTwo', data.payload.BadgeTwo);
      console.log('data.payload.BadgeThree', data.payload.BadgeThree);
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: 'items',
            middlePaneHeaderBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderBadgeThree: data.payload.BadgeThree,
            middlePaneHeaderBadgeFour: data.payload.BadgeFour,
            middlePaneHeaderDuplicate: 'items',
            middlePaneHeaderDuplicateBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderDuplicateBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderDuplicateBadgeThree: data.payload.BadgeThree,
            middlePaneHeaderDuplicateBadgeFour: data.payload.BadgeFour,
            typeOfMiddlePaneList:
              data.payload.request.actionFilter === 'Shared-Item'
                ? 'marketplaceItemsTypeDistinctReviewList'
                : 'itemsTypeDistinctReviewList',
            scanCount: userResponse && userResponse.countTotal,
            duplicateScanCount: userResponse && userResponse.countTotal,
            showMiddlePaneState: true,
            isSelectActive: data.payload.isSelectActive,
            selectedTagsArray: data.payload.selectedTagsArray
          }
        });
      }
      yield put({
        type: data.payload.isMiddlePaneList
          ? REVIEWLIST_DISTINCT_DATA
          : SET_CORE_TYPE_REVIEW_LIST_DATA,
        payload: userResponse.responseObject
      });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }

    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerReviewCultureProfileTypeListSaga(data) {
  try {
    const userResponse = yield call(TypesReviewListDistinctApi, {
      data: data.payload.request,
      URL: CULTURE_MODULE_URL,
      idIdToken: true
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === '000') {
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: 'culture-profiles',
            middlePaneHeaderBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderBadgeThree: data.payload.BadgeThree,
            middlePaneHeaderBadgeFour: data.payload.BadgeFour,
            middlePaneHeaderDuplicate: 'culture-profiles',
            middlePaneHeaderDuplicateBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderDuplicateBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderDuplicateBadgeThree: data.payload.BadgeThree,
            middlePaneHeaderDuplicateBadgeFour: data.payload.BadgeFour,
            typeOfMiddlePaneList:
              data.payload.request.actionFilter === 'Shared-Culture-Profile-Type'
                ? 'MarketPlaceCultureProfileTypeReviewList'
                : 'cultureProfilesTypeDistinctReviewList',
            scanCount: userResponse && userResponse.countTotal,
            duplicateScanCount: userResponse && userResponse.countTotal,
            showMiddlePaneState: true,
            isSelectActive: data.payload.isSelectActive,
            selectedTagsArray: data.payload.selectedTagsArray
          }
        });
      }
      yield put({
        type: data.payload.isMiddlePaneList
          ? REVIEWLIST_DISTINCT_DATA
          : SET_CORE_TYPE_REVIEW_LIST_DATA,
        payload: userResponse.responseObject
      });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }

    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerReviewJobProfileTypeListSaga(data) {
  try {
    const userResponse = yield call(TypesReviewListDistinctApi, {
      data: data.payload.request,
      URL: JOB_MODULE_URL,
      idIdToken: true
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === '000') {
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: 'job-profiles',
            middlePaneHeaderBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderBadgeThree: data.payload.BadgeThree,
            middlePaneHeaderBadgeFour: data.payload.BadgeFour,
            middlePaneHeaderDuplicate: 'job-profiles',
            middlePaneHeaderDuplicateBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderDuplicateBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderDuplicateBadgeThree: data.payload.BadgeThree,
            middlePaneHeaderDuplicateBadgeFour: data.payload.BadgeFour,
            typeOfMiddlePaneList:
              data.payload.request.actionFilter === 'Shared-Job-Profile-Type'
                ? 'MarketplaceJobProfilesTypeDistinctReviewList'
                : 'jobProfilesTypeDistinctReviewList',
            scanCount: userResponse && userResponse.countTotal,
            duplicateScanCount: userResponse && userResponse.countTotal,
            showMiddlePaneState: true,
            isSelectActive: data.payload.isSelectActive,
            selectedTagsArray: data.payload.selectedTagsArray
          }
        });
      }
      yield put({
        type: data.payload.isMiddlePaneList
          ? REVIEWLIST_DISTINCT_DATA
          : SET_CORE_TYPE_REVIEW_LIST_DATA,
        payload: userResponse.responseObject
      });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }

    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerReviewAssessmentTypeListSaga(data) {
  try {
    const userResponse = yield call(TypesReviewListDistinctApi, {
      data: data.payload.request,
      URL: ASSESSMENT_MODULE_URL
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === '000') {
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: 'assessments',
            middlePaneHeaderBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderBadgeThree: data.payload.BadgeThree,
            middlePaneHeaderBadgeFour: data.payload.BadgeFour,
            middlePaneHeaderDuplicate: 'assessments',
            middlePaneHeaderDuplicateBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderDuplicateBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderDuplicateBadgeThree: data.payload.BadgeThree,
            middlePaneHeaderDuplicateBadgeFour: '',
            typeOfMiddlePaneList:
              data.payload.request.actionFilter === 'Shared-Assessment'
                ? 'MarketplaceAssessmentsTypeDistinctReviewList'
                : 'assessmentsTypeDistinctReviewList',
            scanCount: userResponse && userResponse.countTotal,
            duplicateScanCount: userResponse && userResponse.countTotal,
            showMiddlePaneState: true,
            isSelectActive: data.payload.isSelectActive,
            selectedTagsArray: data.payload.selectedTagsArray
          }
        });
      }
      yield put({
        type: data.payload.isMiddlePaneList
          ? REVIEWLIST_DISTINCT_DATA
          : SET_CORE_TYPE_REVIEW_LIST_DATA,
        payload: userResponse.responseObject
      });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }

    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerReviewAssignmentTypeListSaga(data) {
  try {
    const userResponse = yield call(TypesReviewListDistinctApi, {
      data: data.payload.request,
      URL: ASSIGNMENT_MODULE_URL
    });
    console.log('isMiddlePaneList', data.payload.isMiddlePaneList);
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === '000') {
      if (data.payload.isMiddlePaneList) {

        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: 'assignments',
            middlePaneHeaderBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderBadgeThree: data.payload.BadgeThree,
            middlePaneHeaderBadgeFour: data.payload.BadgeFour,
            middlePaneHeaderDuplicate: 'assignments',
            middlePaneHeaderDuplicateBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderDuplicateBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderDuplicateBadgeThree: data.payload.BadgeThree,
            middlePaneHeaderDuplicateBadgeFour: data.payload.BadgeFour,
            typeOfMiddlePaneList:
              data.payload.request.actionFilter === 'Assignment-Type-Shared-List'
                ? 'MarketplaceAssignmentsTypeDistinctReviewList'
                : 'assignmentsTypeDistinctReviewList',
            scanCount: userResponse && userResponse.countTotal,
            duplicateScanCount: userResponse && userResponse.countTotal,
            showMiddlePaneState: true,
            isSelectActive: data.payload.isSelectActive,
            selectedTagsArray: data.payload.selectedTagsArray
          }
        });
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: "previousMiddlePaneState", value: data.payload.request.actionFilter === 'Assignment-Type-Shared-List'
              ? 'MarketplaceAssignmentsTypeDistinctReviewList'
              : 'assignmentsTypeDistinctReviewList',
          },
        })
      }
      yield put({
        type: data.payload.isMiddlePaneList
          ? REVIEWLIST_DISTINCT_DATA
          : SET_CORE_TYPE_REVIEW_LIST_DATA,
        payload: userResponse.responseObject
      });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }

    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}

export default function* watchReviewTypesListSaga() {
  yield takeLatest(GET_ASSESSEE_TYPE_REVIEW_LIST_SAGA, workerReviewAssesseeTypeListSaga);
  yield takeLatest(GET_ASSESSMENT_TYPE_REVIEW_LIST_SAGA, workerReviewAssessmentTypeListSaga);
  yield takeLatest(GET_ASSIGNMENT_TYPE_REVIEW_LIST_SAGA, workerReviewAssignmentTypeListSaga);
  yield takeLatest(GET_ASSOCIATE_TYPE_REVIEW_LIST_SAGA, workerReviewAssociateTypeListSaga);
  yield takeLatest(
    GET_CULTUREPROFILE_TYPE_REVIEW_LIST_SAGA,
    workerReviewCultureProfileTypeListSaga
  );
  yield takeLatest(GET_JOBPROFILE_TYPE_REVIEW_LIST_SAGA, workerReviewJobProfileTypeListSaga);
  yield takeLatest(GET_ITEM_TYPE_REVIEW_LIST_SAGA, workerReviewItemTypeListSaga);
}
