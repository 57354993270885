import { put, takeLatest, call } from 'redux-saga/effects';
import {
  LOADER_STOP,
  REVIEWLIST_DISTINCT_DATA,
  ASSIGNMENT_REVIEW_LIST_DISTINCT_SAGA,
  SET_MIDDLEPANE_STATE,
  SET_POPUP_VALUE,
  GET_ASSIGNMENTTYPE_ASSIGNMENT_REVIEWLIST_SAGA,
  RELATED_REVIEWLIST_DISTINCT_DATA,
  SET_REVIEW_LIST_RELATE_DATA,
  GET_ASSIGNMENTGROUP_ASSIGNMENT_REVIEWLIST_SAGA,
  GET_NODE_ASSIGNMENTS_REVIEW_LIST_SAGA,
  GET_ALLOCATE_ASSIGNMENT,
  GET_ASSIGNMENTDISTINCT_ASSESSEES_REVIEWLIST_SAGA,
  GET_ASSIGNMENTDISTINCT_ASSESSMENT_REVIEWLIST_SAGA,
  GET_ASSIGNMENTDISTINCT_CULTURE_PROFILE_REVIEWLIST_SAGA,
  GET_ASSIGNMENTDISTINCT_JOB_PROFILE_REVIEWLIST_SAGA,
  SET_ASSIGNMENT_RELATED_REVIEW_LIST,
  GET_ASSIGNMENT_REFERENCE_LIST_SAGA,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_PAGE_COUNT,
  ASSESSEE_MINE_ASSIGNMENT_LIST,
  FILTERMODE,
  ASSESSEE_MINE_ASSIGNMENT_GROUP_LIST,
  SET_DISPLAY_THREE_SINGLE_STATE
} from '../../actionType';
import { ASSESSEE_MODULE_URL, ASSIGNMENT_MODULE_URL } from '../../endpoints';
import { EXCEPTION_ERROR_MESSAGE } from '../../errorMessage';
import Store from '../../store';

const apiCallFun = async (requestObj) => {
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      Authorization: localStorage.getItem('token')
    }),
    body: JSON.stringify(requestObj.data)
  };
  const response = await fetch(requestObj.URL, requestOptions);
  const json = await response.json();
  return json;
};

function* workerReviewListAssignmentSaga(data) {
  try {
    const response = yield call(apiCallFun, {
      data: data.payload.request,
      URL: ASSIGNMENT_MODULE_URL,
      type: ''
    });
    // const response ={responseCode:'000',countTotal:30}
    const { actionFilter } = data.payload;
    if (response.responseCode === '000') {
      yield put({
        type: SET_MIDDLEPANE_STATE,
        payload: {
          middlePaneHeader: 'assignments',
          middlePaneHeaderBadgeOne: data.payload.BadgeOne,
          middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
          middlePaneHeaderBadgeThree: '',
          middlePaneHeaderBadgeFour: '',
          middlePaneHeaderDuplicate: 'assignments',
          middlePaneHeaderDuplicateBadgeOne: data.payload.BadgeOne,
          middlePaneHeaderDuplicateBadgeTwo: data.payload.BadgeTwo,
          middlePaneHeaderDuplicateBadgeThree: '',
          middlePaneHeaderDuplicateBadgeFour: '',
          middlePaneHeaderDuplicateOne: 'assignments',
          middlePaneHeaderDuplicateOneBadgeOne: data.payload.BadgeOne,
          middlePaneHeaderDuplicateOneBadgeTwo: data.payload.BadgeTwo,
          middlePaneHeaderDuplicateOneBadgeThree: '',
          middlePaneHeaderDuplicateOneBadgeFour: '',
          typeOfMiddlePaneList:
            actionFilter === 'Shared-Assignment'
              ? 'MarketplaceAssignmentDistinctReviewList'
              : 'assignmentDistinctReviewList',
          scanCount: response && response.countTotal,
          duplicateScanCount: response && response.countTotal,
          duplicateScanCountOne: response && response.countTotal,
          showMiddlePaneState: true,
          isSelectActive: data.payload.isSelectActive,
          selectedTagsArray: data.payload.selectedTagsArray || [],
          unselectedTagsArray: data.payload.unselectedTagsArray || []
        }
      });

      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: {
          stateName: "previousMiddlePaneState", value:actionFilter === 'Shared-Assignment'
          ? 'MarketplaceAssignmentDistinctReviewList'
          : 'assignmentDistinctReviewList',
        },
      })
      yield put({ type: REVIEWLIST_DISTINCT_DATA, payload: response.responseObject });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: {
          stateName: 'currentRevisedAssignmentType',
          value: data.payload.request.requestObject.filterTypeName
        }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: {
          stateName: 'reviewListDistinctDataDuplicate',
          value: Store.getState().DisplayPaneTwoReducer.reviewListDistinctData
        }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: {
          stateName: 'relatedReviewListDistinctData',
          value: []
        }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: {
          stateName: 'relatedReviewListDistinctDataDuplicate',
          value: []
        }
      });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: response.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }

    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workeAssignmentTypeAssignment(data) {
  try {
    const response = yield call(apiCallFun, {
      data: data.payload.request,
      URL: ASSIGNMENT_MODULE_URL,
      type: ''
    });
    // const response ={responseCode:'000',countTotal:30}
    if (response.responseCode === '000') {
      let responseObj = null;
      if (Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData.length > 0) {
        responseObj = {
          ...Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData[0],
          assignment: [
            ...Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData[0]?.assignment,
            ...response.responseObject[0].assignment
          ]
        };
        //to add list more and adding all the record
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
      } else {
        responseObj = response.responseObject[0];
      }
      yield put({
        type: RELATED_REVIEWLIST_DISTINCT_DATA,
        payload: [responseObj]
      });

      yield put({ type: RELATED_REVIEWLIST_DISTINCT_DATA, payload: [response.responseObject[0]] });
      yield put({ type: SET_REVIEW_LIST_RELATE_DATA, payload: response.responseObject[0] });
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: 'assignments',
            middlePaneHeaderBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderDuplicate:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
            middlePaneHeaderDuplicateBadgeOne:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            middlePaneHeaderDuplicateBadgeTwo:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            middlePaneHeaderDuplicateBadgeThree:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
            typeOfMiddlePaneList: 'assignmentTypeAssignmentReviewList',
            scanCount: response && response.countTotal,
            duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
            // isSelectActive: Store.getState().DisplayPaneTwoReducer.isSelectActive,
            // selectedTagsArray: data.payload.selectedTagsArray,
            showMiddlePaneState: true,
            isListLoading: false
          }
        });
      }
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: response.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }

    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workeAssignmentGroupAssignment(data) {
  try {
    const response = yield call(apiCallFun, {
      data: data.payload.request,
      URL: ASSIGNMENT_MODULE_URL,
      type: ''
    });
    // const response ={responseCode:'000',countTotal:30}
    if (response.responseCode === '000') {
      let responseObj = null;
      if (Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData.length > 0) {
        responseObj = {
          ...Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData[0],
          assignment: [
            ...Store.getState().DisplayPaneTwoReducer?.relatedReviewListDistinctData[0]?.assignment,
            ...response.responseObject[0]?.assignment
          ]
        };
        //to add list more and adding all the record
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
      } else {
        responseObj = response.responseObject[0];
      }
      yield put({
        type: RELATED_REVIEWLIST_DISTINCT_DATA,
        payload: [responseObj]
      });

      // yield put({ type: RELATED_REVIEWLIST_DISTINCT_DATA, payload: [response.responseObject[0]] });
      // yield put({ type: SET_REVIEW_LIST_RELATE_DATA, payload: response.responseObject[0] });
      yield put({
        type: RELATED_REVIEWLIST_DISTINCT_DATA,
        payload: [response.responseObject[0]]
      });
      yield put({
        type: SET_REVIEW_LIST_RELATE_DATA,
        payload: response.responseObject[0]
      });
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: 'assignments',
            middlePaneHeaderBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderBadgeThree: '',
            middlePaneHeaderBadgeFour: '',
            middlePaneHeaderDuplicate:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
            middlePaneHeaderDuplicateBadgeOne:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            middlePaneHeaderDuplicateBadgeTwo:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            middlePaneHeaderDuplicateBadgeThree:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
            middlePaneHeaderDuplicateBadgeFour:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour,
            typeOfMiddlePaneList: 'assignmentGroupAssignmentReviewList',
            scanCount: response && response.countTotal,
            duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
            showMiddlePaneState: true
          }
        });
      }
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: response.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }

    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workeAssignmentDistictAssessees(data) {
  try {
    const response = yield call(apiCallFun, {
      data: data.payload.request,
      URL: ASSIGNMENT_MODULE_URL,
      type: 'assessee'
    });
    // const response ={responseCode:'000',countTotal:30}
    if (response.responseCode === '000') {
      // we kept in [] because the object was access by [0] posion
      let responseObj = null;
      console.log(Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData)

      if (
        Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData.length > 0 &&
        data.payload.isMiddlePaneList && !data.payload.siftFilter
      ) {
        responseObj = {
          ...response?.responseObject[0],
          assessee: [
            ...(Store?.getState()?.DisplayPaneTwoReducer?.relatedReviewListDistinctData[0]?.assessee || []),
            ...(response?.responseObject[0]?.assessee || [])
          ]
        };
        //to add list more and adding all the record
      } else {
        responseObj = response.responseObject[0];
      }
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
      }

      responseObj.assessee?.sort((a, b) =>
        (
          a.informationBasic?.assesseeNameFirst + a.informationBasic?.assesseeNameLast
        ).toLowerCase() !==
          (b.informationBasic?.assesseeNameFirst + b.informationBasic?.assesseeNameLast).toLowerCase()
          ? (
            a.informationBasic?.assesseeNameFirst + a.informationBasic?.assesseeNameLast
          ).toLowerCase() <
            (
              b.informationBasic?.assesseeNameFirst + b.informationBasic?.assesseeNameLast
            ).toLowerCase()
            ? -1
            : 1
          : 0
      );
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'numberPage', value: response?.numberPage }
      });
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: RELATED_REVIEWLIST_DISTINCT_DATA,
          payload: [responseObj]
        });
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: 'assessees',
            middlePaneHeaderBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderBadgeThree: '',
            middlePaneHeaderBadgeFour: '',
            middlePaneHeaderDuplicate:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
            middlePaneHeaderDuplicateBadgeOne:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            middlePaneHeaderDuplicateBadgeTwo:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            middlePaneHeaderDuplicateBadgeThree:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
            middlePaneHeaderDuplicateBadgeFour: '',
            middlePaneHeaderDuplicateOne:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOne,
            middlePaneHeaderDuplicateOneBadgeOne:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeOne,
            middlePaneHeaderDuplicateOneBadgeTwo:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeTwo,
            middlePaneHeaderDuplicateOneBadgeThree:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeThree,
            middlePaneHeaderDuplicateOneBadgeFour:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeFour,
            typeOfMiddlePaneList: 'assignmentDistinctAssesseeReviewList',
            // scanCount: (response && response?.responseObject[0]?.assessee?.length) || 0,
            scanCount: (response && response?.countTotal) || 0,
            duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
            showMiddlePaneState: true
          }
        });
      }
      yield put({
        type: SET_ASSIGNMENT_RELATED_REVIEW_LIST,
        payload: { assessee: response?.responseObject[0]?.assessee || [] }
      });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: response.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }

    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workeAssignmentDistictAssessment(data) {
  try {
    const response = yield call(apiCallFun, {
      data: data.payload.request,
      URL: ASSIGNMENT_MODULE_URL,
      type: 'assessment'
    });
    // const response ={responseCode:'000',countTotal:30}
    if (response.responseCode === '000') {
      const { BadgeTwo } = data.payload;
      // yield put({
      //   type: SET_ASSIGNMENT_RELATED_REVIEW_LIST,
      //   payload: { assessment: response?.responseObject[0]?.assessment || [] }
      // });
      // yield put({ type: RELATED_REVIEWLIST_DISTINCT_DATA, payload: response.responseObject });
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: response.responseObject }
        });
        yield put({
          type: SET_DISPLAY_THREE_SINGLE_STATE,
          payload: {stateName: "assignmentRelatedReviewListPaneThree", value: {...Store.getState().DisplayPaneThreeReducer.assignmentRelatedReviewListPaneThree, assessment: response?.responseObject[0]?.assessment || [] }}
        });
        let status = {
          suspended: 'status',
          terminated: 'status',
          unpublished: 'status',
          published: 'status'
        };
        let duplicateScanCount = status.hasOwnProperty(BadgeTwo)
          ? Store.getState().DisplayPaneTwoReducer.duplicateScanCount
          : Store.getState().DisplayPaneTwoReducer.scanCount;
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: 'assessments',
            middlePaneHeaderBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderBadgeThree: '',
            middlePaneHeaderBadgeFour: '',
            middlePaneHeaderDuplicate:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
            middlePaneHeaderDuplicateBadgeOne:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            middlePaneHeaderDuplicateBadgeTwo:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            middlePaneHeaderDuplicateBadgeThree:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
            middlePaneHeaderDuplicateBadgeFour: '',
            middlePaneHeaderDuplicateOne:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOne,
            middlePaneHeaderDuplicateOneBadgeOne:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeOne,
            middlePaneHeaderDuplicateOneBadgeTwo:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeTwo,
            middlePaneHeaderDuplicateOneBadgeThree:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeThree,
            middlePaneHeaderDuplicateOneBadgeFour:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeFour,
            typeOfMiddlePaneList: 'assignmentDistinctAssessmentReviewList',
            scanCount: response && response.countTotal,
            // duplicateScanCount: Store.getState().DisplayPaneTwoReducer.scanCount,
            duplicateScanCount: duplicateScanCount,
            duplicateScanCountOne: Store.getState().DisplayPaneTwoReducer.duplicateScanCountOne,
            showMiddlePaneState: true
          }
        });
      } else {
        yield put({
          type: SET_ASSIGNMENT_RELATED_REVIEW_LIST,
          payload: { assessment: response?.responseObject[0]?.assessment || [] }
        });
      }
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: response.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }

    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workeAssignmentDistictCultureProfile(data) {
  try {
    const response = yield call(apiCallFun, {
      data: data.payload.request,
      URL: ASSIGNMENT_MODULE_URL,
      type: 'culture-profile'
    });
    // const response ={responseCode:'000',countTotal:30}
    if (response.responseCode === '000') {
      // yield put({ type: RELATED_REVIEWLIST_DISTINCT_DATA, payload: [response.responseObject[0]] });
      yield put({
        type: SET_ASSIGNMENT_RELATED_REVIEW_LIST,
        payload: { cultureProfile: response?.responseObject[0]?.cultureProfile || [] }
      });
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: response.responseObject }
        });
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: 'culture-profiles',
            middlePaneHeaderBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderBadgeThree: '',
            middlePaneHeaderBadgeFour: '',
            middlePaneHeaderDuplicate:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
            middlePaneHeaderDuplicateBadgeOne:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            middlePaneHeaderDuplicateBadgeTwo:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            middlePaneHeaderDuplicateBadgeThree:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
            middlePaneHeaderDuplicateBadgeFour: '',
            middlePaneHeaderDuplicateOne:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOne,
            middlePaneHeaderDuplicateOneBadgeOne:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeOne,
            middlePaneHeaderDuplicateOneBadgeTwo:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeTwo,
            middlePaneHeaderDuplicateOneBadgeThree:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeThree,
            middlePaneHeaderDuplicateOneBadgeFour:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeFour,
            typeOfMiddlePaneList: 'assignmentDistinctCultureProfileReviewList',
            scanCount: response && response.countTotal,
            duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
            showMiddlePaneState: true
          }
        });
      }
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: response.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }

    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workeAssignmentDistictJobProfile(data) {
  try {
    const response = yield call(apiCallFun, {
      data: data.payload.request,
      URL: ASSIGNMENT_MODULE_URL,
      type: 'job-profile'
    });
    // const response ={responseCode:'000',countTotal:30}
    if (response.responseCode === '000') {
      // yield put({ type: RELATED_REVIEWLIST_DISTINCT_DATA, payload: [response.responseObject[0]] });
      yield put({
        type: SET_ASSIGNMENT_RELATED_REVIEW_LIST,
        payload: { jobProfile: response?.responseObject[0]?.jobProfile || [] }
      });
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: response.responseObject }
        });
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: 'job-profiles',
            middlePaneHeaderBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderBadgeThree: '',
            middlePaneHeaderBadgeFour: '',
            middlePaneHeaderDuplicate:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
            middlePaneHeaderDuplicateBadgeOne:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            middlePaneHeaderDuplicateBadgeTwo:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            middlePaneHeaderDuplicateBadgeThree:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
            middlePaneHeaderDuplicateBadgeFour: '',
            middlePaneHeaderDuplicateOne:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOne,
            middlePaneHeaderDuplicateOneBadgeOne:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeOne,
            middlePaneHeaderDuplicateOneBadgeTwo:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeTwo,
            middlePaneHeaderDuplicateOneBadgeThree:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeThree,
            middlePaneHeaderDuplicateOneBadgeFour:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeFour,
            typeOfMiddlePaneList: 'assignmentDistinctJobProfileReviewList',
            scanCount: response && response.countTotal,
            duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
            showMiddlePaneState: true
          }
        });
      }
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: response.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }

    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}

function* workerAssignmentNodeAssignment(data) {
  try {
    const response = yield call(apiCallFun, {
      data: data.payload.request,
      URL: ASSIGNMENT_MODULE_URL,
      type: ''
    });
    // const response ={responseCode:'000',countTotal:30}
    if (response.responseCode === '000') {
      let responseObj = null;
      if (
        Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData.length > 0 &&
        data.payload.isMiddlePaneList
      ) {
        responseObj = {
          ...Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData[0],
          assignment: [
            ...Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData[0]?.assignment,
            ...response.responseObject[0].assignment
          ]
        };
        //to add list more and adding all the record
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
      } else {
        responseObj = response.responseObject[0];
      }
      yield put({
        type: RELATED_REVIEWLIST_DISTINCT_DATA,
        payload: [responseObj]
      });
      yield put({ type: RELATED_REVIEWLIST_DISTINCT_DATA, payload: [response.responseObject[0]] });
      // yield put({ type: SET_REVIEW_LIST_RELATE_DATA, payload: response.responseObject });
      let relatedReviewListPaneThree2 = {
        ...Store.getState().DisplayPaneThreeReducer.relatedReviewListPaneThree,
        assignment: response.responseObject[0].assignment
      };

      yield put({
        type: SET_REVIEW_LIST_RELATE_DATA,
        payload: relatedReviewListPaneThree2
      });
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: 'assignments',
            middlePaneHeaderBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderBadgeThree: '',
            middlePaneHeaderBadgeFour: '',
            middlePaneHeaderDuplicate:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
            middlePaneHeaderDuplicateBadgeOne:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            middlePaneHeaderDuplicateBadgeTwo:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            middlePaneHeaderDuplicateBadgeThree:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
            middlePaneHeaderDuplicateBadgeFour:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour,
            typeOfMiddlePaneList: 'assignmentNodeAssignmentReviewList',
            scanCount: response && response.countTotal,
            duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
            showMiddlePaneState: true
          }
        });
      }
    } else {
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: response.responseMessage, popupMode: 'responseErrorMsg' }
        });
      }
    }

    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}

function* workerReviewListAssignmentAllocateSaga(data) {
  try {
    const userResponse = yield call(apiCallFun, {
      data: data.payload.request,
      URL: ASSIGNMENT_MODULE_URL,
      type: ''
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === '000') {
      let responseObj = null;
      if (Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData.length > 0) {
        responseObj = {
          ...data.payload.revisedGroupObject,
          assignment: [
            ...Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData[0].assignment,
            ...userResponse.responseObject
          ]
        };
        //to add list more and adding all the record
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
      } else {
        responseObj = {
          ...data.payload.revisedGroupObject,
          assignment: userResponse.responseObject
        };
      }
      yield put({
        type: SET_MIDDLEPANE_STATE,
        payload: {
          middlePaneHeader: data.payload.headerOne || 'assignments',
          middlePaneHeaderBadgeOne: 'distinct',
          middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
          middlePaneHeaderBadgeThree: '',
          middlePaneHeaderBadgeFour: '',
          middlePaneHeaderDuplicate:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
          middlePaneHeaderDuplicateBadgeOne:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
          middlePaneHeaderDuplicateBadgeTwo:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
          middlePaneHeaderDuplicateBadgeThree:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree, //
          middlePaneHeaderDuplicateBadgeFour:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour, //
          typeOfMiddlePaneList: data.payload.typeOfMiddlePaneList,
          scanCount: userResponse && userResponse.countTotal,
          duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
          showMiddlePaneState: true,
          isSelectActive: 'multiple',
          selectedTagsArray: data.payload.existingAssignmentId,
          unselectedTagsArray: data.payload.unselectedTagsArray
        }
      });
      yield put({ type: RELATED_REVIEWLIST_DISTINCT_DATA, payload: [responseObj] });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerReviewListAssignmentReferenceSaga(data) {
  try {
    const userResponse = yield call(apiCallFun, {
      data: data.payload.request,
      URL: ASSIGNMENT_MODULE_URL
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === '000') {
      let responseObj = {
        ...data.payload.revisedGroupObject,
        [data.payload.responseKey]: userResponse.responseObject
      };
      yield put({
        type: SET_MIDDLEPANE_STATE,
        payload: {
          middlePaneHeader: data.payload.headerOne || 'assignments',
          middlePaneHeaderBadgeOne: 'distinct',
          middlePaneHeaderBadgeTwo: 'active',
          middlePaneHeaderBadgeThree: '',
          middlePaneHeaderBadgeFour: '',
          middlePaneHeaderDuplicate:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
          middlePaneHeaderDuplicateBadgeOne:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
          middlePaneHeaderDuplicateBadgeTwo:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
          middlePaneHeaderDuplicateBadgeThree: '',
          middlePaneHeaderDuplicateBadgeFour: '',
          middlePaneHeaderDuplicateOne: 'assignments',
          middlePaneHeaderDuplicateOneBadgeOne: data.payload.BadgeOne,
          middlePaneHeaderDuplicateOneBadgeTwo: data.payload.BadgeTwo,
          middlePaneHeaderDuplicateOneBadgeThree: '',
          middlePaneHeaderDuplicateOneBadgeFour: '',
          typeOfMiddlePaneList: data.payload.typeOfMiddlePaneList,
          scanCount: (userResponse && userResponse.countTotal) || 0,
          duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount || 0,
          showMiddlePaneState: true,
          isSelectActive: 'multiple',
          selectedTagsArray: data.payload.existingRefId
        }
      });
      yield put({ type: RELATED_REVIEWLIST_DISTINCT_DATA, payload: [responseObj] });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}

function* workerAsseseeMineAssignmentListSaga(data) {
  try {
    const userResponse = yield call(apiCallFun, {
      data: data.payload.request,
      URL: ASSIGNMENT_MODULE_URL
    });

    if (userResponse.responseCode === '000') {
      let responseObj = null;
      if (Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData.length > 0) {
        responseObj = {
          ...Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData[0],
          assessee: [
            ...Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData[0]?.assessee,
            ...userResponse.responseObject[0].assessee
          ]
        };
        //to add list more and adding all the record
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
      } else {
        responseObj = userResponse.responseObject;
      }
      yield put({ type: RELATED_REVIEWLIST_DISTINCT_DATA, payload: [responseObj] });
      yield put({
        type: SET_MIDDLEPANE_STATE,
        payload: {
          middlePaneHeader: 'assessees',
          middlePaneHeaderBadgeOne: 'distinct',
          middlePaneHeaderBadgeTwo: '',
          middlePaneHeaderBadgeThree: '',
          middlePaneHeaderBadgeFour: '',
          middlePaneHeaderDuplicate:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
          middlePaneHeaderDuplicateBadgeOne:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
          middlePaneHeaderDuplicateBadgeTwo:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
          middlePaneHeaderDuplicateBadgeThree:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
          middlePaneHeaderDuplicateBadgeFour: '',
          middlePaneHeaderDuplicateOne:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOne,
          middlePaneHeaderDuplicateOneBadgeOne:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeOne,
          middlePaneHeaderDuplicateOneBadgeTwo:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeTwo,
          middlePaneHeaderDuplicateOneBadgeThree:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeThree,
          middlePaneHeaderDuplicateOneBadgeFour:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeFour,
          middlePaneHeaderDuplicateBadgeThree: '',
          middlePaneHeaderDuplicateBadgeFour: '',
          typeOfMiddlePaneList: 'assignmentDistinctMineAssesseeDistinctReviewList',
          scanCount: (userResponse && userResponse?.countTotal) || 0,
          duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
          showMiddlePaneState: true,
          isSelectActive: 'multiple',
          selectedTagsArray: data.payload.existingAssesseeId
          // relatedReviewListDistinctData: [userResponse.responseObject]
        }
      });

      yield put({
        type: FILTERMODE,
        payload: { FilterMode: 'assignmentDistinctMineAsseseeDistinct' }
      });

      yield put({ type: LOADER_STOP });
    } else {
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: userResponse?.responseMessage || EXCEPTION_ERROR_MESSAGE,
          popupMode: 'responseErrorMsg'
        }
      });
    }
  } catch (err) {
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}

function* workerAsseseeGroupMineAssignmentListSaga(data) {
  try {
    const userResponse = yield call(apiCallFun, {
      data: data.payload.request,
      URL: ASSESSEE_MODULE_URL
    });

    if (userResponse.responseCode === '000') {
      yield put({
        type: SET_MIDDLEPANE_STATE,
        payload: {
          middlePaneHeader: 'assessees',
          middlePaneHeaderBadgeOne: 'group',
          middlePaneHeaderBadgeTwo: '',
          middlePaneHeaderBadgeThree: '',
          middlePaneHeaderBadgeFour: '',
          middlePaneHeaderDuplicate:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
          middlePaneHeaderDuplicateBadgeOne:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
          middlePaneHeaderDuplicateBadgeTwo:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
          middlePaneHeaderDuplicateBadgeThree:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
          middlePaneHeaderDuplicateBadgeFour: '',
          middlePaneHeaderDuplicateOne:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOne,
          middlePaneHeaderDuplicateOneBadgeOne:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeOne,
          middlePaneHeaderDuplicateOneBadgeTwo:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeTwo,
          middlePaneHeaderDuplicateOneBadgeThree:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeThree,
          middlePaneHeaderDuplicateOneBadgeFour:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeFour,
          middlePaneHeaderDuplicateBadgeThree: '',
          middlePaneHeaderDuplicateBadgeFour: '',
          typeOfMiddlePaneList: 'AssesseeGroupMineReviewList',
          scanCount: (userResponse && userResponse.responseObject[0].length) || 0,
          duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
          showMiddlePaneState: true,
          isSelectActive: 'multiple',
          selectedTagsArray: data.payload.existingAssesseeId
          // relatedReviewListDistinctData: [userResponse.responseObject]
        }
      });

      yield put({
        type: FILTERMODE,
        payload: { FilterMode: 'assignmentDistinctMineGroupDistinct' }
      });

      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: {
          stateName: 'reviewListMineGroupDistinctData',
          value: userResponse.responseObject[0]
        }
      });

      yield put({ type: LOADER_STOP });
    } else {
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: userResponse?.responseMessage || EXCEPTION_ERROR_MESSAGE,
          popupMode: 'responseErrorMsg'
        }
      });
    }
  } catch (err) {
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
export default function* watchReviewListAssignmentSaga() {
  yield takeLatest(ASSIGNMENT_REVIEW_LIST_DISTINCT_SAGA, workerReviewListAssignmentSaga);
  yield takeLatest(GET_ASSIGNMENTTYPE_ASSIGNMENT_REVIEWLIST_SAGA, workeAssignmentTypeAssignment);
  yield takeLatest(GET_ASSIGNMENTGROUP_ASSIGNMENT_REVIEWLIST_SAGA, workeAssignmentGroupAssignment);
  yield takeLatest(
    GET_ASSIGNMENTDISTINCT_ASSESSEES_REVIEWLIST_SAGA,
    workeAssignmentDistictAssessees
  );
  yield takeLatest(
    GET_ASSIGNMENTDISTINCT_ASSESSMENT_REVIEWLIST_SAGA,
    workeAssignmentDistictAssessment
  );
  yield takeLatest(
    GET_ASSIGNMENTDISTINCT_CULTURE_PROFILE_REVIEWLIST_SAGA,
    workeAssignmentDistictCultureProfile
  );
  yield takeLatest(
    GET_ASSIGNMENTDISTINCT_JOB_PROFILE_REVIEWLIST_SAGA,
    workeAssignmentDistictJobProfile
  );
  yield takeLatest(GET_NODE_ASSIGNMENTS_REVIEW_LIST_SAGA, workerAssignmentNodeAssignment);
  yield takeLatest(GET_ALLOCATE_ASSIGNMENT, workerReviewListAssignmentAllocateSaga);
  yield takeLatest(GET_ASSIGNMENT_REFERENCE_LIST_SAGA, workerReviewListAssignmentReferenceSaga);
  yield takeLatest(ASSESSEE_MINE_ASSIGNMENT_LIST, workerAsseseeMineAssignmentListSaga);
  yield takeLatest(ASSESSEE_MINE_ASSIGNMENT_GROUP_LIST, workerAsseseeGroupMineAssignmentListSaga);
}
