import React, { useEffect, useRef, useState } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Popup from '../Molecules/PopUp/PopUp';
import PopupHeader from '../Molecules/PopUp/PopUpHeader';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import Person from '@material-ui/icons/Person';
import AssociateIcon from '@material-ui/icons/Stars';
import '../Molecules/PopUp/PopUp.css';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { POPUP_CLOSE, SET_NEXT_POPUP } from '../actionType';
import { CAN_NOT_REVISED_MESSAGE, INVALID_IMAGE_ERROR_MESSAGE } from '../errorMessage';
import { Avatar } from '@material-ui/core';
import { imageUploadMethod } from '../Actions/GenericActions';
import DefaultProfile from '@material-ui/icons/Pages';

const PopUpPicture = (props) => {
  const { popupMode } = useSelector((state) => state.PopUpReducer);
  const [imageSelected, setimageSelected] = useState('');
  const [imageError, setImageError] = useState('');
  const dispatch = useDispatch();
  const inputFile = useRef(null);

  const {
    isActive = false,
    headerPanelColour = 'genericOne',
    headerOne = 'assessee',
    inputHeader = 'picture',
    inputHeaderBadgeOne = '',
    inputHeaderBadgeTwo = '',
    headerOneBadgeOne = '',
    headerOneBadgeTwo = '',
    nextPopUpValue,
    handleNextPopupValue,
    mode,
    basicInfo = '',
    actualLableValue = '',
    typeOfSetObject,
    actualLableValueVerification,
    isNotRevised = false
  } = props;
  function validateFileType(fileName) {
    var idxDot = fileName.lastIndexOf('.') + 1;
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    if (extFile === 'jpg' || extFile === 'jpeg' || extFile === 'png') {
      return true;
    } else {
      return false;
    }
  }
  const onClickImageUpload = async (event) => {
    var file = event.target.files[0];
    console.log('file', file);
    if (validateFileType(file.name)) {
      let imagePath = await imageUploadMethod(file);
      console.log(imagePath);
      setimageSelected(imagePath.file.url);
      setImageError('');
    } else {
      setImageError(INVALID_IMAGE_ERROR_MESSAGE);
      setimageSelected('');
    }
  };
  useEffect(() => {
    setimageSelected(basicInfo[actualLableValue]);
    isNotRevised && setImageError(CAN_NOT_REVISED_MESSAGE);
  }, [basicInfo]);
  const handleClick = async () => {
    //according to creation mode popup sequence will change
    if (typeOfSetObject !== '' && basicInfo) {
      dispatch({
        type: typeOfSetObject,
        payload: { ...basicInfo, [actualLableValue]: imageSelected }
      });
    }
    if (mode === 'revise') {
      dispatch({ type: POPUP_CLOSE });
    } else {
      if (handleNextPopupValue) {
        handleNextPopupValue();
      } else {
        dispatch({ type: SET_NEXT_POPUP, payload: { isPopUpValue: nextPopUpValue } });
      }
    }
  };
  const handleCheckbox = (e) => {
    const { name, checked } = e.target;
    dispatch({
      type: typeOfSetObject,
      payload: { ...basicInfo, [name]: checked }
    });
  };
  return (
    <div>
      <Popup isActive={isActive}>
        <PopupHeader
          headerPanelColour={headerPanelColour}
          headerOne={headerOne}
          headerOneBadgeOne={headerOneBadgeOne}
          headerOneBadgeTwo={headerOneBadgeTwo}
          onClick={handleClick}
          mode={mode}
        />
        <DialogContent
          className={['popupContent', 'fixed10PadDim', 'revisePopupContent'].join(' ')}
        >
          <div className={'fitContent'}>
            <div className={['PopupFormBox', 'labelPopupBox', 'popupMinHei'].join(' ')}>
              {headerOne !== 'signature' && (
                <InputLabel htmlFor="name-input" className={'textForLabelPopup'}>
                  <>
                    {inputHeader}&nbsp;
                    {inputHeaderBadgeOne ? (
                      <span className={'headerBadge'}>{inputHeaderBadgeOne}</span>
                    ) : null}
                    &nbsp;
                    {inputHeaderBadgeTwo ? (
                      <span className={'headerBadge'}>{inputHeaderBadgeTwo}</span>
                    ) : null}
                  </>
                </InputLabel>
              )}
            </div>

            <div className={['dashboardImage', 'popupMargin'].join(' ')}>
              <input
                type="file"
                id="file"
                ref={inputFile}
                accept={'image/*'}
                style={{ display: 'none' }}
                onChange={onClickImageUpload}
              />
              <Button
                variant="text"
                disabled={isNotRevised}
                // mini
                className={[
                  'button',
                  'uploadImageWidthHeight',
                  'iconsFooterDefault',
                  'unAvailable',
                  !imageSelected && 'imageNA'
                ].join(' ')}
                onClick={() => {
                  !isNotRevised && inputFile.current.click();
                }}
              >
                {imageSelected ? (
                  <Avatar //
                    className={['svgRootSize', 'uploadImageWidthHeight'].join(' ')}
                    alt="Remy Sharp"
                    src={imageSelected}
                    style={{ backgroundColor: 'none' }}
                  />
                ) : headerOne === 'associate' || headerOne === 'associates' ? (
                  <AssociateIcon className={['svgRootSize', 'uploadImageWidthHeight'].join(' ')} />
                ) : headerOne === 'assessee' ||
                  headerOne === 'assessees' ||
                  headerOne === 'managers' ||
                  headerOne === 'manager' ||
                  headerOne === 'assistant' ||
                  headerOne === 'assistants' ||
                  headerOne === 'administrators' ||
                  headerOne === 'administrator' ? (
                  <Person className={['svgRootSize', 'uploadImageWidthHeight'].join(' ')} />
                ) : (
                  <DefaultProfile className={['svgRootSize', 'uploadImageWidthHeight'].join(' ')} />
                )}
              </Button>
            </div>
          </div>
          <FormHelperText
            style={{ marginLeft: '5px' }}
            className={[
              imageError !== '' ? 'helperText' : 'helperTextDefault',
              'helptextmargin'
            ].join(' ')}
          >
            {imageError}
          </FormHelperText>

          <div className={'fitContent'}>
            <div className={['PopupFormBox', 'popupMinHei0'].join(' ')} style={{ minHeight: 0 }}>
              <div className={'contFlex'}>
                <div
                  className={'f4'}
                  style={{
                    color:
                      popupMode === 'ASSESSEE_SIGN_ON' || popupMode === 'ASSOCIATE_SIGN_ON'
                        ? 'dimgray'
                        : ''
                  }}
                >
                  verification
                </div>
                <div className={'checkedFontNew'}>
                  <Checkbox
                    className={''}
                    name={actualLableValueVerification}
                    checked={basicInfo && basicInfo[actualLableValueVerification]}
                    color="default"
                    disableRipple={true}
                    onChange={handleCheckbox}
                    disabled={
                      popupMode === 'ASSESSEE_SIGN_ON' || popupMode === 'ASSOCIATE_SIGN_ON'
                        ? true
                        : false
                    }
                  // name={actualLableValue + 'Verification'}
                  />
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Popup>
    </div>
  );
};
PopUpPicture.propTypes = {
  className: PropTypes.string,
  headerPanelColour: PropTypes.oneOf([
    'displayPaneLeft',
    'displayPaneCentre',
    'displayPaneRight',
    'genericOne',
    'genericTwo'
  ]),
  headerOne: PropTypes.string,
  headerOneBadgeOne: PropTypes.string,
  headerOneBadgeTwo: PropTypes.string,
  headerOneBadgeThree: PropTypes.string,
  isActive: PropTypes.bool
};
export default PopUpPicture;
