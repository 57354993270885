import { put, takeLatest, call, takeEvery } from 'redux-saga/effects';
import {
  LOADER_STOP,
  REVIEWLIST_DISTINCT_DATA,
  SET_MIDDLEPANE_STATE,
  RELATED_REVIEWLIST_DISTINCT_DATA,
  SET_POPUP_VALUE,
  ASSESSMENT_MARKETPLACE_REVIEW_DISTINCT_SAGA,
  ASSESSMENT_MARKETPLACE_REVIEW_GROUP_SAGA,
  ASSESSMENT_MARKETPLACE_REVIEW_NODE_SAGA,
  ASSESSMENT_MARKETPLACE_REVIEW_TYPE_SAGA,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_REVIEW_LIST_RELATE_DATA
} from '../../actionType';
import { ASSESSMENT_MODULE_URL } from '../../endpoints';
import { EXCEPTION_ERROR_MESSAGE } from '../../errorMessage';
import Store from '../../store';

let assessments = "assessments"

const reviewListMarketplaceDistinctApi = async (requestObj) => {
  console.log({ demo: 'called' });
  // let URL = ASSESSEE_REVIEW_LIST_URL;
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      Authorization: localStorage.getItem('token')
    }),
    body: JSON.stringify(requestObj.data)
  };
  const response = await fetch(ASSESSMENT_MODULE_URL, requestOptions);
  const json = await response.json();
  return json;
};

function* workerMarketplaceDistinctReviewListAssesseeSaga(data) {
  try {
    const userResponse = yield call(reviewListMarketplaceDistinctApi, {
      data: data.payload.request
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === '000') {
      yield put({
        type: SET_MIDDLEPANE_STATE,
        payload: {
          middlePaneHeader: assessments,
          middlePaneHeaderBadgeOne: data.payload.BadgeOne,
          middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
          middlePaneHeaderBadgeThree: '',
          middlePaneHeaderBadgeFour: '',
          middlePaneHeaderDuplicate: data.payload.HeaderOne,
          middlePaneHeaderDuplicateBadgeOne: data.payload.BadgeOne,
          middlePaneHeaderDuplicateBadgeTwo: data.payload.BadgeTwo,
          middlePaneHeaderDuplicateBadgeThree: '',
          middlePaneHeaderDuplicateBadgeFour: '',
          middlePaneHeaderDuplicateOne: data.payload.HeaderOne,
          middlePaneHeaderDuplicateOneBadgeOne: data.payload.BadgeOne,
          middlePaneHeaderDuplicateOneBadgeTwo: data.payload.BadgeTwo,
          middlePaneHeaderDuplicateOneBadgeThree: '',
          middlePaneHeaderDuplicateOneBadgeFour: '',

          typeOfMiddlePaneList: 'MarketplaceAssessmentDistinctReviewList',
          scanCount: userResponse && userResponse.countTotal,
          duplicateScanCount: userResponse && userResponse.countTotal,
          showMiddlePaneState: true,
          isSelectActive: data.payload.isSelectActive,
          selectedTagsArray: data.payload.selectedTagsArray || [],
          unselectedTagsArray: data.payload.unselectedTagsArray || [],
          isListLoading: false
        }
      });
      yield put({
        type: REVIEWLIST_DISTINCT_DATA,
        payload: userResponse.responseObject?.length > 0 ? userResponse.responseObject : []
      });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: userResponse.responseMessage,
          popupMode: 'responseErrorMsg'
        }
      });
    }

    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
    yield put({ type: LOADER_STOP });
  }
}

function* workerMarketplaceGroupReviewListAssesseeSaga(data) {
  try {
    const userResponse = yield call(reviewListMarketplaceDistinctApi, {
      data: data.payload.request
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === '000') {
      yield put({
        type: SET_MIDDLEPANE_STATE,
        payload: {
          middlePaneHeader: assessments,
          middlePaneHeaderBadgeOne: data.payload.BadgeOne,
          middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
          middlePaneHeaderBadgeThree: '',
          middlePaneHeaderBadgeFour: '',
          middlePaneHeaderDuplicate: data.payload.HeaderOne,
          middlePaneHeaderDuplicateBadgeOne: data.payload.BadgeOne,
          middlePaneHeaderDuplicateBadgeTwo: data.payload.BadgeTwo,
          middlePaneHeaderDuplicateBadgeThree: '',
          middlePaneHeaderDuplicateBadgeFour: '',
          middlePaneHeaderDuplicateOne: data.payload.HeaderOne,
          middlePaneHeaderDuplicateOneBadgeOne: data.payload.BadgeOne,
          middlePaneHeaderDuplicateOneBadgeTwo: data.payload.BadgeTwo,
          middlePaneHeaderDuplicateOneBadgeThree: '',
          middlePaneHeaderDuplicateOneBadgeFour: '',

          typeOfMiddlePaneList: 'MarketplaceAssessmentGroupReviewList',
          scanCount: userResponse && userResponse.countTotal,
          duplicateScanCount: userResponse && userResponse.countTotal,
          showMiddlePaneState: true,
          isSelectActive: data.payload.isSelectActive,
          selectedTagsArray: data.payload.selectedTagsArray || [],
          unselectedTagsArray: data.payload.unselectedTagsArray || [],
          isListLoading: false
        }
      });
      yield put({
        type: REVIEWLIST_DISTINCT_DATA,
        payload: userResponse.responseObject?.length > 0 ? userResponse.responseObject : []
      });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: userResponse.responseMessage,
          popupMode: 'responseErrorMsg'
        }
      });
    }

    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
    yield put({ type: LOADER_STOP });
  }
}

function* workerMarketplaceNodeReviewListAssesseeSaga(data) {
  try {
    const userResponse = yield call(reviewListMarketplaceDistinctApi, {
      data: data.payload.request
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === '000') {
      yield put({
        type: SET_MIDDLEPANE_STATE,
        payload: {
          middlePaneHeader: assessments,
          middlePaneHeaderBadgeOne: data.payload.BadgeOne,
          middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
          middlePaneHeaderBadgeThree: '',
          middlePaneHeaderBadgeFour: '',
          middlePaneHeaderDuplicate: data.payload.HeaderOne,
          middlePaneHeaderDuplicateBadgeOne: data.payload.BadgeOne,
          middlePaneHeaderDuplicateBadgeTwo: data.payload.BadgeTwo,
          middlePaneHeaderDuplicateBadgeThree: '',
          middlePaneHeaderDuplicateBadgeFour: '',
          middlePaneHeaderDuplicateOne: data.payload.HeaderOne,
          middlePaneHeaderDuplicateOneBadgeOne: data.payload.BadgeOne,
          middlePaneHeaderDuplicateOneBadgeTwo: data.payload.BadgeTwo,
          middlePaneHeaderDuplicateOneBadgeThree: '',
          middlePaneHeaderDuplicateOneBadgeFour: '',

          typeOfMiddlePaneList: 'MarketplaceAssessmentNodeReviewList',
          scanCount: userResponse && userResponse.countTotal,
          duplicateScanCount: userResponse && userResponse.countTotal,
          showMiddlePaneState: true,
          isSelectActive: data.payload.isSelectActive,
          selectedTagsArray: data.payload.selectedTagsArray || [],
          unselectedTagsArray: data.payload.unselectedTagsArray || [],
          isListLoading: false
        }
      });
      yield put({
        type: RELATED_REVIEWLIST_DISTINCT_DATA,
        payload: userResponse.responseObject?.length > 0 ? userResponse.responseObject : []
      });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: userResponse.responseMessage,
          popupMode: 'responseErrorMsg'
        }
      });
    }

    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
    yield put({ type: LOADER_STOP });
  }
}

function* workerMarketplaceTypeReviewListAssesseeSaga(data) {
  try {
    const response = yield call(reviewListMarketplaceDistinctApi, {
      data: data.payload.request,
    });
    // const response ={responseCode:'000',countTotal:30}
    if (response.responseCode === '000') {
      let responseObj = null;
      if (Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData.length > 0) {
        responseObj = {
          ...Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData[0],
          assessment: [
            ...Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData[0]?.assessment,
            ...response.responseObject[0].assessment
          ]
        };
        //to add list more and adding all the record
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
      } else {
        responseObj = response.responseObject[0];
      }
      yield put({
        type: RELATED_REVIEWLIST_DISTINCT_DATA,
        payload: [responseObj]
      });

      yield put({ type: RELATED_REVIEWLIST_DISTINCT_DATA, payload: [response.responseObject[0]] });
      yield put({ type: SET_REVIEW_LIST_RELATE_DATA, payload: response.responseObject });
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: 'assessments',
            middlePaneHeaderBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderBadgeThree: data.payload.BadgeThre,
            middlePaneHeaderBadgeFour: data.payload.BadgeFour,
            middlePaneHeaderDuplicate:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
            middlePaneHeaderDuplicateBadgeOne:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            middlePaneHeaderDuplicateBadgeTwo:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            middlePaneHeaderDuplicateBadgeThree:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
            middlePaneHeaderDuplicateBadgeFour:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour,
            typeOfMiddlePaneList: 'MarketplaceAssessmentTypeReviewList',
            scanCount: response && response.countTotal,
            duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
            showMiddlePaneState: true
          }
        });
      }
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: response.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }

    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}

export default function* watchReviewListAssesseeSaga() {
  yield takeEvery(
    ASSESSMENT_MARKETPLACE_REVIEW_DISTINCT_SAGA,
    workerMarketplaceDistinctReviewListAssesseeSaga
  );
  yield takeEvery(
    ASSESSMENT_MARKETPLACE_REVIEW_GROUP_SAGA,
    workerMarketplaceGroupReviewListAssesseeSaga
  );
  yield takeEvery(
    ASSESSMENT_MARKETPLACE_REVIEW_NODE_SAGA,
    workerMarketplaceNodeReviewListAssesseeSaga
  );
  yield takeEvery(
    ASSESSMENT_MARKETPLACE_REVIEW_TYPE_SAGA,
    workerMarketplaceTypeReviewListAssesseeSaga
  );
}
