import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ASSIGNMENT_REVISE_SAGA,
  CLEAR_DISPLAY_PANE_THREE,
  FILTERMODE,
  FILTERMODE_ENABLE,
  GET_ALLOCATE_ASSESSMENT,
  LOADER_START,
  POPUP_OPEN,
  RESET_ASSIGNMENT_REVIEW_LIST_OBJECT,
  SET_ASSIGNMENT_RELATED_LIST,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MIDDLEPANE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_PAGE_COUNT,
  SET_POPUP_STATE
} from '../actionType';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import { FilterList } from '@material-ui/icons';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import { ASSIGNMENT_ASSESMENT_REVIEW_LIST_POPUP_OPTION } from '../PopUpConfig';
import Card from '../Molecules/Card/Card';
import CrossIcon from '@material-ui/icons/Clear';
import { onClickCheckBoxOneListSelection } from '../Actions/AssesseeModuleAction';
import ReviseIcon from '@material-ui/icons/RadioButtonChecked';
import Check from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { getAssignmnetAssessmentDistinctApiCall, getAssignmnetGroupAssignmnetDistinctApiCall } from '../Actions/AssignmentModuleAction';
import { closeRelatedList, getPopUpOptionList } from '../Actions/GenericActions';
import ListLoader from '../Atoms/ListLoader/ListLoader';

const AssignmentDistinctAssessmentDistinctReviewList = (props) => {
  const dispatch = useDispatch();
  const [isShowReviseIcon, setIsShowReviseIcon] = useState(true);
  const {
    middlePaneSelectedValue,
    reviewListDistinctData,
    selectedAssociateInfo,
    relatedReviewListDistinctData,
    middlePaneHeaderBadgeOne,
    middlePaneHeaderDuplicate,
    middlePaneHeaderDuplicateBadgeOne,
    middlePaneHeaderDuplicateBadgeTwo,
    middlePaneHeaderDuplicateBadgeThree,
    middlePaneHeaderDuplicateBadgeFour,
    typeOfMiddlePaneList,
    isSelectActive,
    selectedTagsArray,
    unselectedTagsArray,
    middlePaneHeaderBadgeTwo,
    middlePaneHeader,
    numberPage,
    scanCount,
    duplicateScanCount,
    isMiddlePaneList,
    relatedReviewListObjectCopy,
    isListLoading,
    countPage,
    signedAssesseePermission,
    middlePaneHeaderDuplicateOne,
    middlePaneHeaderDuplicateOneBadgeOne,
    middlePaneHeaderDuplicateOneBadgeTwo,
    middlePaneHeaderDuplicateOneBadgeThree,
    previousMiddlePaneState,
    middlePaneHeaderDuplicateOneBadgeFour
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const {
    headerOne,
    headerOneBadgeTwo,
    headerOneBadgeOne,
    createMode,
    responseObject,
    assignmentAssesseeList = [],
    assignmentAssessmentList = [],
    assignmentCultureProfileList = [],
    assignmentJobProfileList = [],
    assignmentAssesseeGroupList = [],
    assignmentAssessmentGroupList = [],
    assignmentCultureProfileGroupList = [],
    assignmentJobProfileGroupList = [],
    reviewMode
  } = useSelector((state) => state.DisplayPaneThreeReducer);
  const assignmentInfo = useSelector((state) => state.AssignmentReducer);
  const { FilterModeEnable, FilterMode } = useSelector((state) => state.FilterReducer);
  //paggination code start

  // const [isFetching, setIsFetching] = useState(false);

  // useEffect(() => {
  //   document.getElementById('middleComponentId').addEventListener('scroll', handleScroll);
  // }, []);
  // const handleScroll = (event) => {
  //   var targetPt = event.target;
  //   if (
  //     Math.ceil(targetPt.scrollHeight - targetPt.scrollTop) !== targetPt.clientHeight ||
  //     isFetching
  //   )
  //     return;
  //   setIsFetching(true);
  //   console.log(isFetching);
  // };

  const fetchData = async () => {
    if (relatedReviewListDistinctData.length > 0) {
      if (relatedReviewListDistinctData[0].assessment.length < scanCount) {
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'isListLoading', value: true }
        });
        let obj = {
          ...relatedReviewListObjectCopy.requestObject,
          numberPage: numberPage + 1
        };
        if (reviewMode === 'revise') {
          dispatch({
            type: GET_ALLOCATE_ASSESSMENT,
            payload: {
              existingAssessmentId: selectedTagsArray,
              request: {
                module: 'Assessment-Distinct',
                action: 'Review-List',
                requestObject: obj
              },
              HeaderOne: middlePaneHeader,
              BadgeOne: 'distinct',
              BadgeTwo: middlePaneHeaderBadgeTwo,
              isMiddlePaneList: true,
              typeOfMiddlePaneList: 'assignmentDistinctAssessmentReviewList',
              revisedGroupObject: {
                id: responseObject.id,
                assignmentName: responseObject.informationBasic.assignmentName,
                assignmentDescription: responseObject.informationBasic.assignmentDescription,
                assignmentStatus: responseObject.informationEngagement.assignmentStatus
              }
            }
          });
        }
        // else {
        //  this part for middle pane review liast
        // }

        dispatch({ type: SET_PAGE_COUNT, payload: numberPage + 1 });
      }
    }
  };

  // useEffect(() => {
  //   if (!isFetching) return;
  //   fetchMoreListItems();
  // }, [isFetching]);

  // const fetchMoreListItems = () => {
  //   fetchData();
  //   setIsFetching(false);
  // };

  //paggination code end
  const onClickRevise = () => {
    console.log('ON CLICK REVISE ICON');
    setIsShowReviseIcon(false);
  };
  const onClickReviseCancel = () => {
    if (isListLoading) {
      return
    }
    setIsShowReviseIcon(true);
    setPrevList();
    if (middlePaneHeaderDuplicate !== 'assignments') {
      // at last review corss / revise cancel call pane three reqiured to be cleared
      dispatch({
        type: SET_MIDDLEPANE_STATE,
        payload: {
          middlePaneHeader: '',
          middlePaneHeaderBadgeOne: '',
          middlePaneHeaderBadgeTwo: '',
          middlePaneHeaderBadgeThree: '',
          middlePaneHeaderBadgeFour: '',
          typeOfMiddlePaneList: '',
          showMiddlePaneState: false
        }
      });
    }
    // if (responseObject.id !== relatedReviewListDistinctData[0].id || reviewMode === 'review') {
    if (headerOne === 'assessment') {
      dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
    }
  };
  const setPrevList = () => {
    console.log('createMode', createMode);
    // console.log('reviewListDistinctData',reviewListDistinctData);
    if (createMode === '' && reviewListDistinctData?.length > 0) {
      // debugger
      let middlepanelistfallback = "assignmentDistinctReviewList"
      if(middlePaneHeaderDuplicateBadgeOne === "groups"){
        middlepanelistfallback = "assignmentsGroupDistinctReviewList"
      }else if(middlePaneHeaderDuplicateBadgeOne === "types"){
        middlepanelistfallback = "assignmentsTypeDistinctReviewList"
      }else if(middlePaneHeaderDuplicateBadgeOne === "nodes"){
        middlepanelistfallback = "associateNodeDistinctactive"
      }

      closeRelatedList(
        dispatch,
        createMode,
        responseObject,
        middlePaneHeaderDuplicate,
        middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderDuplicateBadgeTwo,
        middlePaneHeaderDuplicateBadgeThree,
        middlePaneHeaderDuplicateBadgeFour,
        previousMiddlePaneState,
        duplicateScanCount,
        'assignmentsDistinct', //'assignmentsDistinctinactive',
        duplicateScanCount,
        middlePaneHeaderDuplicateOne,
        middlePaneHeaderDuplicateOneBadgeOne,
        middlePaneHeaderDuplicateOneBadgeTwo,
        middlePaneHeaderDuplicateOneBadgeThree,
        middlePaneHeaderDuplicateOneBadgeFour
      );
    } else {
      // debugger
      // at last review corss / revise cancel call pane three reqiured to be cleared
      // if (reviewMode === 'review') {
      //   dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
      // }
      dispatch({
        type: SET_MIDDLEPANE_STATE,
        payload: {
          middlePaneHeader: '',
          middlePaneHeaderBadgeOne: '',
          middlePaneHeaderBadgeTwo: '',
          middlePaneHeaderBadgeThree: '',
          middlePaneHeaderBadgeFour: '',
          typeOfMiddlePaneList: '',
          // scanCount: duplicateScanCount,
          showMiddlePaneState: false
        }
      });
    }
  };
  const onClickReviseFinish = () => {
    console.log('ON CLICK finish ICON', selectedTagsArray, unselectedTagsArray);
    setIsShowReviseIcon(true);
    setPrevList();
    dispatch({
      type: FILTERMODE,
      payload: { FilterMode: '' }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'isSelectActive', value: '' }
    });
    dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
    dispatch({
      type: SET_ASSIGNMENT_RELATED_LIST,
      payload: {
        listName: 'assignmentAssessmentList',
        value: selectedTagsArray
      }
    });
    const { id } = responseObject;
    let frameworkObj = {
      ...assignmentInfo.informationFramework,
      assignmentAssesseeDistinct: assignmentAssesseeList || [],
      assignmentAssessmentDistinct: selectedTagsArray || [],
      assignmentCultureProfileDistinct: assignmentCultureProfileList || [],
      assignmentJobProfileDistinct: assignmentJobProfileList || [],
      assignmentAssesseeGroup: assignmentAssesseeGroupList || [],
      assignmentAssessmentGroup: assignmentAssessmentGroupList || [],
      assignmentCultureProfileGroup: assignmentCultureProfileGroupList || [],
      assignmentJobProfileGroup: assignmentJobProfileGroupList || []
    };
    const reqBody = {
      module: 'Assignment-Distinct',
      action: 'Revise',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        assignment: {
          id,
          informationBasic: assignmentInfo.informationBasic,
          informationAllocation: assignmentInfo.informationAllocation,
          informationFramework: frameworkObj,
          informationSetup: assignmentInfo.informationSetup
        }
      }
    };
    dispatch({ type: LOADER_START });
    dispatch({
      type: ASSIGNMENT_REVISE_SAGA,
      payload: {
        reqBody
      }
    });
    dispatch({ type: RESET_ASSIGNMENT_REVIEW_LIST_OBJECT });
    closeRelatedList(
      dispatch,
      createMode,
      responseObject,
      middlePaneHeaderDuplicate,
      middlePaneHeaderDuplicateBadgeOne,
      middlePaneHeaderDuplicateBadgeTwo,
      middlePaneHeaderDuplicateBadgeThree,
      middlePaneHeaderDuplicateBadgeFour,
      previousMiddlePaneState,
      duplicateScanCount,
      'assignmentsDistinct',
      duplicateScanCount,
      middlePaneHeaderDuplicateOne,
      middlePaneHeaderDuplicateOneBadgeOne,
      middlePaneHeaderDuplicateOneBadgeTwo,
      middlePaneHeaderDuplicateOneBadgeThree,
      middlePaneHeaderDuplicateOneBadgeFour
    );
    dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
  };
  const revisePrimaryIcon = [{ label: 'revise', onClick: onClickRevise, Icon: ReviseIcon }];

  const reviseSecondaryIcons = [
    { label: 'cancel', onClick: onClickReviseCancel, Icon: ClearIcon },
    { label: 'finish', onClick: onClickReviseFinish, Icon: Check }
  ];

  //  const closeRelatedList = () => {
  //     if (middlePaneHeaderDuplicate) {
  //       dispatch({
  //         type: SET_MIDDLEPANE_STATE,
  //         payload: {
  //           middlePaneHeader: "assignments",
  //           middlePaneHeaderBadgeOne: middlePaneHeaderDuplicateBadgeOne,
  //           middlePaneHeaderBadgeTwo: middlePaneHeaderDuplicateBadgeTwo,
  //           middlePaneHeaderBadgeThree: "",
  //           middlePaneHeaderBadgeFour: "",
  //           middlePaneHeaderDuplicate: middlePaneHeaderDuplicate,
  //           middlePaneHeaderDuplicateBadgeOne: middlePaneHeaderDuplicateBadgeOne,
  //           middlePaneHeaderDuplicateBadgeTwo: middlePaneHeaderDuplicateBadgeTwo,
  //           middlePaneHeaderDuplicateBadgeThree:
  //             middlePaneHeaderDuplicateBadgeThree,
  //           typeOfMiddlePaneList: "assignmentDistinctReviewList",
  //           scanCount: reviewListDistinctData.length,
  //           showMiddlePaneState: true,
  //         },
  //       });
  //       if (FilterMode !== "assignmentDistinctAssessmentRevise") {
  //         dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  //       }
  //     } else {
  //       dispatch({
  //         type: SET_MIDDLEPANE_STATE,
  //         payload: {
  //           middlePaneHeader: "",
  //           middlePaneHeaderBadgeOne: "",
  //           middlePaneHeaderBadgeTwo: "",
  //           middlePaneHeaderBadgeThree: "",
  //           middlePaneHeaderBadgeFour: "",
  //           typeOfMiddlePaneList: "",
  //           scanCount: "",
  //           showMiddlePaneState: false,
  //         },
  //       });
  //     }
  //   };
  const listDistinctData = relatedReviewListDistinctData[0];
  //const iGaugeOccupationFrameworkOneClusterAssessment = [];
  const iGaugeOccupationFrameworkOneClusterAssessment =
    (responseObject?.informationFramework?.assignmentAssessmentDistinct &&
      responseObject?.informationFramework?.assignmentAssessmentDistinct.map((ob) => {
        if (ob.iGaugeOccupationFrameworkOneClusterAssessment === true) {
          return ob.assessmentId;
        }
      })) ||
    [];

  const siftApiCall = (siftKey) => {
    getAssignmnetAssessmentDistinctApiCall(
      selectedAssociateInfo,
      siftKey,
      countPage,
      dispatch,
      middlePaneHeaderBadgeOne,
      listDistinctData.id,
      '',
      false
    );
    // document.getElementById('middleComponentId').scrollTop = '0px';
  };
  const onClickFooter = (e) => {
    let siftValue = e.currentTarget.getAttribute('data-value');
    if (siftValue === 'suspended' || siftValue === 'terminated' || siftValue === 'unpublished')
      siftApiCall(siftValue);
    dispatch({ type: FILTERMODE_ENABLE });
  };
  /* for middle pane */
  const primaryIcon = [{ label: 'sift', onClick: onClickFooter, Icon: FilterList }];
  const secondaryIcon = [
    { label: 'suspended', onClick: onClickFooter, Icon: FilterList },
    { label: 'terminated', onClick: onClickFooter, Icon: FilterList },
    { label: 'unpublished', onClick: onClickFooter, Icon: FilterList }
  ];

  const openListPopup = (e) => {
    console.log(e.currentTarget.getAttribute('tag'));
    let tempArr = [];
    let assigmentDistictPermission =
      signedAssesseePermission?.assigmentDistictPermission?.assesseeAssignmentDistinctPermission;
    let popupContentArrValue = getPopUpOptionList(
      ASSIGNMENT_ASSESMENT_REVIEW_LIST_POPUP_OPTION,
      assigmentDistictPermission
    );
    popupContentArrValue.map((element) => {
      if (
        element.data === 'archive' ||
        element.data === 'allocate' ||
        element.data === 'delete' ||
        element.data === 'suspend' ||
        element.data === 'share' ||
        element.data === 'select' ||
        element.data === 'flag' ||
        element.data === 'analytics' ||
        element.data === 'terminate'
      ) {
        tempArr.push({ ...element, disabled: true });
      } else if (
        (listDistinctData.assignmentStatus === 'ADMINISTERED' ||
          listDistinctData.assignmentStatus === 'PUBLISHED') &&
        element.data === 'revise'
      ) {
        tempArr.push({ ...element, disabled: true });
      } else if (listDistinctData.assignmentStatus !== 'ADMINISTERED' && element.data === 'reset') {
        tempArr.push({ ...element, disabled: true });
      } else tempArr.push(element);
    });
    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: 'assessment',
        popupHeaderOneBadgeOne: middlePaneHeaderBadgeOne,
        isPopUpValue: '',
        popupOpenType: 'primary',
        popupContentArrValue: tempArr,
        selectedTagValue: e.currentTarget.getAttribute('tag'),
        selectedTagStatus: e.currentTarget.getAttribute('status')
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: tempArr
      }
    });
  };

  // infinite scroll
  const [hasMore, setHasMore] = useState(true);
  let elementRef = useRef(null);

  const onIntersection = (entries) => {
    const firstEntry = entries[0];
    relatedReviewListDistinctData[0].assessment.length >= scanCount
      ? setHasMore(false)
      : setHasMore(true);
    if (firstEntry.isIntersecting && hasMore && !isListLoading) {
      fetchData();
    }
  };

  useEffect(() => {
    // console.log('####MYREF######', elementRef)
    const observer = new IntersectionObserver(onIntersection, { threshold: 0.90 });

    if (observer && elementRef.current) {
      // console.log('here at observer...', elementRef.current)
      observer.observe(elementRef.current);
    }

    return () => {
      if (observer) observer.disconnect();
    };
  }, [
    isListLoading,
    hasMore,
    relatedReviewListDistinctData,
    selectedTagsArray,
    unselectedTagsArray,
    isSelectActive
  ]);

  // infinite scroll end

  return (
    <div>
      {listDistinctData && (
        <Card
          textOneOne={listDistinctData.assignmentName}
          textTwoOne={listDistinctData.assignmentDescription}
          IconOne={CrossIcon}
          isIcon={true}
          labelTwoTwo={'distinct'}
          onClickIconOne={onClickReviseCancel}
          isAlliance
          relatedCardFixed={true}
          className={'iguru-iconboxSVG'}
        />
      )}
      {listDistinctData &&
        listDistinctData.assessment.map((item, index) => {
          return (
            <div className="containerPadding" key={index} ref={elementRef}>
              <ReviewList
                className=""
                id={index}
                tag={item.id}
                associateId={item?.associateId}
                isSelectedReviewList={middlePaneSelectedValue === item.id}
                status={item.informationEngagement.assessmentStatus}
                actualStatus={item.informationEngagement.assessmentStatus}
                textOne={item.informationBasic.assessmentName}
                textTwo={item.informationBasic.assessmentDescription}
                isTooltipActive={false}
                onClickEvent={openListPopup}
                isShared={item?.assessmentShared}
                isSelectActive={isSelectActive}
                isSelected={selectedTagsArray.includes(item.id)}
                onClickCheckBox={
                  iGaugeOccupationFrameworkOneClusterAssessment.includes(item.id)
                    ? null
                    : (event) => {
                        !isListLoading &&
                          onClickCheckBoxOneListSelection(selectedTagsArray, event, dispatch);
                      }
                }
                // onClickCheckBox={iGaugeOccupationFrameworkOneClusterAssessment.length > 0 ? null : (event) => {
                //   onClickCheckBoxOneListSelection(selectedTagsArray, event, dispatch);
                // }}
              />
            </div>
          );
        })}
      {isListLoading ? (
        <div>
          {' '}
          <ListLoader />{' '}
        </div>
      ) : <div style={{ height: "60px", width: "100%" }}></div>}
      {FilterMode === 'assignmentDistinctAssessmentRevise' && (
        <FooterIconTwo
          FilterModeEnable={isShowReviseIcon}
          FilterMode={FilterMode}
          onClick={onClickRevise}
          primaryIcon={revisePrimaryIcon}
          secondaryIcon={reviseSecondaryIcons}
        />
      )}
      {FilterMode === 'assignmentDistinctAssessmentinactive' && (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={primaryIcon}
          secondaryIcon={secondaryIcon}
        />
      )}
    </div>
  );
};
export default AssignmentDistinctAssessmentDistinctReviewList;
