import React from 'react';
import ArrowRight from '@material-ui/icons/ChevronRight';
import Card from '../Card/Card';
import {
  SECTION_POPUP_OPEN,
  SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
  SET_ASSESSEE_CREATE_SINGLE_STATE,
  SET_ASSOCIATE_CREATE_SINGLE_STATE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_SECTION_TWO_SECONDARY_OPTION_VALUE
} from '../../actionType';
import { useDispatch } from 'react-redux';

const DisplayPaneOneSectionTwo = () => {
  const dispatch = useDispatch();
  // const { assesseePermission = null } = useSelector((state) => state.UserReducer);
  const openSectionPopUp = (e) => {
    console.log("createType",e.currentTarget.getAttribute('data-id'));
    dispatch({
      type: SECTION_POPUP_OPEN,
      payload: {
        headerOne: 'iGauge',
        headerOneBadgeOne: e.currentTarget.getAttribute('data-value'),
      }
    });
    dispatch({ type: SET_SECTION_TWO_SECONDARY_OPTION_VALUE, payload: {clickValue:''} });

    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'sectionCreateTypeSecondary',
        value: e.currentTarget.getAttribute('data-types-secondary')
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'sectionCreateType',
        value: e.currentTarget.getAttribute('data-id')
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'assignmentCreateTypeSecondary',
        value: e.currentTarget.getAttribute('data-types-secondary')
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'assignmentCreateType',
        value: e.currentTarget.getAttribute('data-id')
      }
    });
    dispatch({
      type: SET_ASSESSEE_CREATE_SINGLE_STATE,
      payload: {
        stateName: 'assesseesPopUpType',
        value: 'primary'
      }
    });
    dispatch({
      type: SET_ASSOCIATE_CREATE_SINGLE_STATE,
      payload: {
        stateName: 'associatesPopUpType',
        value: 'primary'
      }
    });
    dispatch({
      type: SET_ASSOCIATE_CREATE_SINGLE_STATE,
      payload: {
        stateName: 'assessmentsPopUpType',
        value: 'primary'
      }
    });
    dispatch({
      type: SET_ASSOCIATE_CREATE_SINGLE_STATE,
      payload: {
        stateName: 'assignmentsPopUpType',
        value: 'primary'
      }
    });
    dispatch({
      type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
      payload: { stateName: 'footerButtonMode', value: true }
    });
  };
  // const openDisplayPaneOneSectionTwoPopUp = (e) => {
  //   let popupContentArrValue = [];
  //   let popupHeaderOne = '';
  //   let popupHeaderOneBadgeOne = '';
  //   let value = '';
  //   if (e.currentTarget.getAttribute('data-value') !== '') {
  //     if (e.currentTarget.getAttribute('data-value') === 'career') {
  //       popupHeaderOne = 'igauge';
  //       popupHeaderOneBadgeOne = 'career';
  //       popupContentArrValue = setAssesseeCardPermissionInJson(
  //         GROUP_NODE_ROLE_TYPE_POPUP_OPTION,
  //         assesseePermission
  //       );
  //       value = 'DISPLAY_PANE_ONE_SECTION_TWO_POPUP';
  //     }
  //     if (e.currentTarget.getAttribute('data-value') === 'education') {
  //       popupHeaderOne = 'igauge';
  //       popupHeaderOneBadgeOne = 'education';
  //       popupContentArrValue = setAssesseeCardPermissionInJson(
  //         GROUP_NODE_ROLE_TYPE_POPUP_OPTION,
  //         assesseePermission
  //       );
  //       value = 'DISPLAY_PANE_ONE_SECTION_TWO_POPUP';
  //     }
  //     if (e.currentTarget.getAttribute('data-value') === 'occupation') {
  //       popupHeaderOne = 'igauge';
  //       popupHeaderOneBadgeOne = 'occupation';
  //       popupContentArrValue = setAssesseeCardPermissionInJson(
  //         GROUP_NODE_ROLE_TYPE_POPUP_OPTION,
  //         assesseePermission
  //       );
  //       value = 'DISPLAY_PANE_ONE_SECTION_TWO_POPUP';
  //     }
  //     if (e.currentTarget.getAttribute('data-value') === 'pulse') {
  //       popupHeaderOne = 'igauge';
  //       popupHeaderOneBadgeOne = 'pulse';
  //       popupContentArrValue = setAssesseeCardPermissionInJson(
  //         GROUP_NODE_ROLE_TYPE_POPUP_OPTION,
  //         assesseePermission
  //       );
  //       value = 'DISPLAY_PANE_ONE_SECTION_TWO_POPUP';
  //     }
  //     dispatch({
  //       type: SET_POPUP_STATE,
  //       payload: {
  //         popupHeaderOne: popupHeaderOne,
  //         previousPopupHeaderOne: popupHeaderOne,
  //         popupHeaderOneBadgeOne: popupHeaderOneBadgeOne,
  //         isPopUpValue: value,
  //         popupOpenType: 'primary',
  //         popupContentArrValue: popupContentArrValue
  //       }
  //     });
  //   } else {
  //     console.log('openDisplayPaneOneSectionTwoPopUp in else ');
  //   }
  // };
  return (
    <>
      <div
        className="paddingCard"
        onClick={openSectionPopUp}
        data-value={'career'}
        data-id={'iGuru Analytics_Career'}
        data-types-secondary ={'iGuru Career'}
      >
        <Card isIcon IconOne={ArrowRight} textOneOne="iGauge" textTwoOne="career" />
      </div>
      <div
        className="paddingCard"
        onClick={openSectionPopUp}
        data-value={'education'}
        data-id={'iGuru Analytics_Education'}
        data-types-secondary ={'iGuru Education'}
      >
        <Card isIcon IconOne={ArrowRight} textOneOne="iGauge" textTwoOne="education" />
      </div>
      <div
        className="paddingCard"
        onClick={openSectionPopUp}
        data-value={'occupation'}
        data-id={'iGuru Analytics_Occupation'}
        data-types-secondary ={'iGuru Occupation'}
      >
        <Card isIcon IconOne={ArrowRight} textOneOne="iGauge" textTwoOne="occupation" />
      </div>
      <div
        className="paddingCard"
        onClick={openSectionPopUp}
        data-value={'pulse'}
        data-id={'iGuru Analytics_Pulse'}
        data-types-secondary ={'iGuru Pulse'}
      >
        <Card isIcon IconOne={ArrowRight} textOneOne="iGauge" textTwoOne="pulse" />
      </div>
    </>
  );
};

export default DisplayPaneOneSectionTwo;
