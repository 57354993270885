import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ASSOCIATE_POPUP_CLOSE,
  GET_ASSESSEE_ROLE_REVIEW_LIST_SAGA,
  FILTERMODE_ENABLE,
  LOADER_START,
  POPUP_OPEN,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_PAGE_COUNT,
  SET_POPUP_STATE,
  SET_REQUEST_OBJECT,
  ASSESSEE_ALLOCATE_GRP_SAGA,
  FILTERMODE
} from '../actionType';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import { FilterList } from '@material-ui/icons';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import {
  getPopUpOptionList,
  makeAdministratorRoleCreateObj,
  makeAssesseeRoleObj,
  makeAssistantsRoleCreateObj,
  makeManagerRoleCreateObj
} from '../Actions/GenericActions';
import {
  ASSESSEE_NODE_ROLE_REVIEW_LIST_POPUP_OPTION,
  ASSESSEES_SUBMODULE_REVIEW_LIST_POPUP_OPTION
} from '../PopUpConfig';
import {
  assesseeRole,
  onClickCheckBoxSelection,
  onClickFlagSelection,
  setFlagedArray
} from '../Actions/AssesseeModuleAction';
import ReviseIcon from '@material-ui/icons/RadioButtonChecked';
import Check from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import ListLoader from '../Atoms/ListLoader/ListLoader';

const AssesseeRoleDistinctReviewList = (props) => {
  const dispatch = useDispatch();
  // const { secondaryOptionCheckValue } = useSelector((state) => state.AssesseeCreateReducer);
  const { cardValue } = useSelector((state) => state.PopUpReducer);
  const {
    numberPage,
    scanCount,
    middlePaneHeaderBadgeOne,
    middlePaneHeaderBadgeTwo,
    middlePaneHeaderBadgeThree,
    reviewListDistinctData,
    reviewListReqObj,
    middlePaneSelectedValue,
    middlePaneHeader,
    selectedAssociateInfo,
    countPage,
    isSelectActive,
    unselectedTagsArray,
    selectedTagsArray,
    allocatedTagsArray,
    allocateStr,
    selectedFlagedArray,
    flagedValue,
    unselectedFlagedArray,
    signedAssesseePermission,
    isListLoading
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { FilterModeEnable, FilterMode } = useSelector((state) => state.FilterReducer);
  // const [isFetching, setIsFetching] = useState(false);
  // useEffect(() => {
  //   document.getElementById('middleComponentId').addEventListener('scroll', handleScroll);
  // }, []);

  useEffect(() => {
    setFlagedArray(reviewListDistinctData, 'assesseeRoleFlag', dispatch);
  }, [reviewListDistinctData]);
  // const handleScroll = (event) => {
  //   var targetPt = event.target;
  //   if (
  //     Math.ceil(targetPt.scrollHeight - targetPt.scrollTop) !== targetPt.clientHeight ||
  //     isFetching
  //   )
  //     return;
  //   setIsFetching(true);
  //   console.log(isFetching);
  // };
  const fetchData = async () => {
    console.log('numberPage', numberPage);
    if (reviewListDistinctData.length < scanCount) {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isListLoading', value: true }
      });

      let obj = {
        ...reviewListReqObj.requestObject,
        numberPage: numberPage + 1
      };
      dispatch({
        type: GET_ASSESSEE_ROLE_REVIEW_LIST_SAGA,
        payload: {
          request: {
            module: 'Assessee-Role',
            action: 'Review-List',
            requestObject: obj
          },
          BadgeOne: middlePaneHeaderBadgeOne,
          BadgeTwo: middlePaneHeaderBadgeTwo,
          BadgeThree: middlePaneHeaderBadgeThree,
          isMiddlePaneList: true,
          isSelectActive,
          selectedTagsArray
        }
      });
      dispatch({ type: SET_PAGE_COUNT, payload: numberPage + 1 });
    }
  };
  // useEffect(() => {
  //   console.log(reviewListDistinctData);
  //   if (!isFetching) return;
  //   fetchMoreListItems();
  // }, [isFetching]);

  // const fetchMoreListItems = () => {
  //   fetchData();
  //   setIsFetching(false);
  // };
  const siftApiCall = (siftKey) => {
    let requestObect = {};
    requestObect =
      middlePaneHeader === 'administrators'
        ? makeAdministratorRoleCreateObj(selectedAssociateInfo, siftKey, 0, countPage)
        : middlePaneHeader === 'assistants'
        ? makeAssistantsRoleCreateObj(selectedAssociateInfo, siftKey, 0, countPage)
        : middlePaneHeader === 'managers'
        ? makeManagerRoleCreateObj(selectedAssociateInfo, siftKey, 0, countPage)
        : makeAssesseeRoleObj(selectedAssociateInfo, siftKey, 0, countPage);
    dispatch({ type: LOADER_START });
    dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
    dispatch({
      type: GET_ASSESSEE_ROLE_REVIEW_LIST_SAGA,
      payload: {
        request: requestObect,
        middlePaneHeader: middlePaneHeader,
        BadgeOne: middlePaneHeaderBadgeOne,
        BadgeTwo: middlePaneHeaderBadgeTwo === 'distinct' ? middlePaneHeaderBadgeTwo : siftKey,
        BadgeThree: middlePaneHeaderBadgeTwo === 'distinct' ? siftKey : middlePaneHeaderBadgeThree,
        isMiddlePaneList: true,
        isSelectActive: isSelectActive,
        selectedTagsArray: selectedTagsArray
      }
    });
    dispatch({ type: ASSOCIATE_POPUP_CLOSE });
    // document.getElementById('middleComponentId').scrollTop = '0px';
  };
  const onClickFooter = (e) => {
    let siftValue = e.currentTarget.getAttribute('data-value');
    if (siftValue === 'suspended' || siftValue === 'terminated') siftApiCall(siftValue);
    if (siftValue === 'bespoke' || siftValue === 'generic') siftApiCall(siftValue);
    dispatch({ type: FILTERMODE_ENABLE });
    if (siftValue === 'finish') {
      console.log('allocateStr', allocateStr);
      let distinctAllocateStr =
        allocateStr === 'assesseesdistinct'
          ? 'assesseeDistinct'
          : allocateStr === 'assesseesgroups'
          ? 'assesseeGroup'
          : allocateStr === 'assesseestypes'
          ? 'assesseeType'
          : allocateStr === 'assesseesroles'
          ? 'assesseeRole'
          : allocateStr === 'assesseesnodes'
          ? 'assesseeNode'
          : allocateStr === 'administratorsgroups'
          ? 'assesseeGroup'
          : allocateStr === 'administratorsdistinct'
          ? 'assesseeDistinct'
          : allocateStr === 'administratorstypes'
          ? 'assesseeType'
          : allocateStr === 'administratorsroles'
          ? 'assesseeRole'
          : allocateStr === 'administratorsnodes'
          ? 'assesseeNode'
          : '';

      if (distinctAllocateStr !== '' && selectedTagsArray.length !== 0) {
        if (
          distinctAllocateStr === 'assesseeDistinct' ||
          distinctAllocateStr === 'assesseeGroup' ||
          distinctAllocateStr === 'assesseeType' ||
          distinctAllocateStr === 'assesseeRole' ||
          distinctAllocateStr === 'assesseeNode'
        ) {
          let request = {
            module: 'Assessee-Distinct',
            action: FilterMode.toLowerCase().includes('unallocate') ? 'unallocate' : 'allocate',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              [FilterMode.toLowerCase().includes('unallocate')
                ? 'assesseeDistinctUnallocate'
                : 'assesseeDistinctAllocate']: {
                [distinctAllocateStr]: allocatedTagsArray
              },
              [FilterMode.toLowerCase().includes('unallocate')
                ? 'assesseeDistinctUnallocateInformation'
                : 'assesseeDistinctAllocateInformation']: {
                assesseeRole: selectedTagsArray
              }
            }
          };
          dispatch({ type: LOADER_START });
          dispatch({
            type: ASSESSEE_ALLOCATE_GRP_SAGA,
            payload: { request: request }
          });
        }
      }
    }
    if (siftValue === 'cancel') {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isSelectActive', value: '' }
      });
      dispatch({
        type: FILTERMODE,
        payload: { FilterMode: '' }
      });
    }
  };
  /* for middle pane */
  const primaryIcon = [{ label: 'sift', onClick: onClickFooter, Icon: FilterList }];
  const secondaryIcon = [
    { label: 'suspended', onClick: onClickFooter, Icon: FilterList },
    { label: 'terminated', onClick: onClickFooter, Icon: FilterList }
  ];
  const secondaryOneIcon = [
    { label: 'bespoke', onClick: onClickFooter, Icon: FilterList },
    { label: 'generic', onClick: onClickFooter, Icon: FilterList }
    // { label: 'shared', onClick: onClickFooter, Icon: FilterList },
    // { label: 'unshared', onClick: onClickFooter, Icon: FilterList }
  ];
  let signedAssociateId =
    selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary;

  const openListPopup = (e) => {
    console.log(e.currentTarget.getAttribute('tag'));
    let isShared = e.currentTarget.getAttribute('data-shared');
    let isRoleDefault = e.currentTarget.getAttribute('data-default');
    let isRoleDefaultAdmin = e.currentTarget.getAttribute('data-admin');
    let dataFlag = e.currentTarget.getAttribute('data-flag') === 'true' ? true : false;
    let popupArr =
      cardValue === 'Card'
        ? ASSESSEES_SUBMODULE_REVIEW_LIST_POPUP_OPTION
        : ASSESSEE_NODE_ROLE_REVIEW_LIST_POPUP_OPTION;
    console.log('popupContentArrValue', popupArr);
    let popupContentArrValue = popupArr.map((obj) =>
      obj.data === 'assessees'
        ? { ...obj, data: middlePaneHeader, dataValue: middlePaneHeader }
        : obj
    );
    let tempArr = [];
    let classification = e.currentTarget.getAttribute('data-classification');
    let associateId = e.currentTarget.getAttribute('associateid');
    let status = e.currentTarget.getAttribute('status');
    let assesseeDistinctPermission =
      signedAssesseePermission?.assesseeDistinctPermission?.assesseeAssesseeRolePermission;
    let popupContentArr = getPopUpOptionList(popupContentArrValue, assesseeDistinctPermission);
    //popupContentArrValue.map((element) => {
    popupContentArr.map((element) => {
      if (classification === 'Bespoke' && element.data === 'share' && status === 'ACTIVE')
        tempArr.push({ ...element, disabled: true });
      else if (
        (status === 'TERMINATED' || status === 'SUSPENDED' || status === 'ARCHIVED') &&
        (element.data === 'allocate' ||
          element.data === 'revise' ||
          element.data === 'select' ||
          element.data === 'share')
      ) {
        tempArr.push({ ...element, disabled: true });
      } else if (
        isShared === 'true' &&
        associateId !== signedAssociateId &&
        (element.data === 'archive' ||
          element.data === 'flag' ||
          element.data === 'delete' ||
          element.data === 'suspend' ||
          element.data === 'move' ||
          element.data === 'terminate')
      ) {
        tempArr.push({ ...element, disabled: true });
      }
      // for making desable option "true i sthe default value is gaing from the detabase"
      else if (
        isRoleDefault === 'true' &&
        (element.data === 'archive' ||
          element.data === 'delete' ||
          element.data === 'suspend' ||
          element.data === 'terminate')
      ) {
        tempArr.push({ ...element, disabled: true });
      } else if (
        isRoleDefaultAdmin === 'true' &&
        (element.data === 'archive' ||
          element.data === 'delete' ||
          element.data === 'suspend' ||
          element.data === 'terminate')
      ) {
        tempArr.push({ ...element, disabled: true });
      } else tempArr.push(element);
    });
    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: middlePaneHeader,
        popupHeaderOneBadgeOne: 'role',
        popupHeaderOneBadgeTwo: '',
        duplicateHeaderOneBadgeTwo: middlePaneHeaderBadgeTwo,
        isPopUpValue: '',
        popupOpenType: 'primary',
        popupContentArrValue: tempArr, //popupContentArrValue,
        originPrimaryArrOprion: tempArr,
        selectedTagValue: e.currentTarget.getAttribute('tag'),
        selectedTagStatus: e.currentTarget.getAttribute('status'),
        selectedTagGroupId: e.currentTarget.getAttribute('data-value'),
        selectedTagShared: e.currentTarget.getAttribute('data-shared') === 'true' ? true : false,
        isFlaged: dataFlag
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: tempArr //popupContentArrValue
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
  };

  // infinite scroll
  const [hasMore, setHasMore] = useState(true);
  let elementRef = useRef(null);

  const onIntersection = (entries) => {
    const firstEntry = entries[0];
    reviewListDistinctData.length >= scanCount ? setHasMore(false) : setHasMore(true);
    if (firstEntry.isIntersecting && hasMore && !isListLoading) {
      fetchData();
    }
  };

  useEffect(() => {
    // console.log('####MYREF######', elementRef)
    const observer = new IntersectionObserver(onIntersection, { threshold: 0.90 });

    if (observer && elementRef.current) {
      // console.log('here at observer...', elementRef.current)
      observer.observe(elementRef.current);
    }

    return () => {
      if (observer) observer.disconnect();
    };
  }, [
    isListLoading,
    hasMore,
    reviewListDistinctData,
    selectedTagsArray,
    unselectedTagsArray,
    isSelectActive
  ]);

  // infinite scroll end

  const associateSeftId =
    selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary;

  return (
    <div>
      {reviewListDistinctData &&
        reviewListDistinctData.map((item, index) => {
          return (
            <div className="containerPadding" key={index} ref={elementRef}>
              <ReviewList
                className=""
                id={index}
                tag={item.id}
                associateId={item?.associateId}
                // dataValue={item.informationAllocation.assesseeRoleGroup}
                isSelectedReviewList={middlePaneSelectedValue === item.id}
                // status={associateSeftId === item.associateId ? 'bespoke' : 'generic'}
                //status={item.informationEngagement.assesseeRoleStatus}
                status={
                  FilterMode === 'assesseeRoleDistinctactive' ||
                  FilterMode === 'assesseeRoleDistinctgeneric' ||
                  FilterMode === 'assesseeRoleDistinctbespoke'
                    ? item.informationSetup?.assesseeRoleClassification
                        ?.assesseeRoleClassificationPrimary
                    : item.informationEngagement.assesseeRoleStatus
                }
                //shared={item.assesseeRoleShared ? 'SHARED' : 'UNSHARED'}
                actualStatus={item.informationEngagement.assesseeRoleStatus}
                textOne={assesseeRole(item.informationBasic.assesseeRoleName)}
                textTwo={item.informationBasic.assesseeRoleDescription}
                dataDefault={item.informationSetup?.assesseeRoleDefault} //
                dataDefaultRole={item.informationSetup.assesseeRoleAdmin} //
                isTooltipActive={false}
                onClickEvent={openListPopup}
                isSelectActive={isSelectActive}
                isSelected={selectedTagsArray.includes(item.id)}
                onClickCheckBox={(event) => {
                  onClickCheckBoxSelection(selectedTagsArray, unselectedTagsArray, event, dispatch);
                }}
                // disableonClickCheckBox={item.associateId !== signedAssociateId ? true : false}
                isShared={item?.assesseeRoleShared}
                shared={
                  item.informationSetup?.assesseeRoleClassification
                    ?.assesseeRoleClassificationPrimary
                }
                isFlagActive={selectedFlagedArray.includes(item.id)}
                flagedValue={flagedValue}
                onClickAddFladed={(event) => {
                  onClickFlagSelection(selectedFlagedArray, unselectedFlagedArray, event, dispatch);
                }}
              />
            </div>
          );
        })}
      {isListLoading ? (
        <div>
          {' '}
          <ListLoader />{' '}
        </div>
      ) : <div style={{ height: "60px", width: "100%" }}></div>}
      {FilterMode === 'assesseeRoleDistinctinactive' && (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={primaryIcon}
          secondaryIcon={secondaryIcon}
        />
      )}
      {(FilterMode === 'assesseeRoleDistinctactive' ||
        FilterMode === 'assesseeRoleDistinctgeneric' ||
        FilterMode === 'assesseeRoleDistinctbespoke') && (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={primaryIcon}
          secondaryIcon={secondaryOneIcon}
        />
      )}
      {(FilterMode === 'assesseeAllocateToRole' || FilterMode === 'assesseeUnAllocateToRole') && (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={[
            FilterMode === 'assesseeAllocateToRole'
              ? { label: 'allocate', onClick: onClickFooter, Icon: ReviseIcon }
              : { label: 'unallocate', onClick: onClickFooter, Icon: ReviseIcon }
          ]}
          secondaryIcon={[
            { label: 'cancel', onClick: onClickFooter, Icon: ClearIcon },
            { label: 'finish', onClick: onClickFooter, Icon: Check }
          ]}
        />
      )}
    </div>
  );
};
export default AssesseeRoleDistinctReviewList;
