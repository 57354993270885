import { put, takeLatest, call } from 'redux-saga/effects';
import {
  SET_DISPLAY_PANE_THREE_STATE,
  LOADER_STOP,
  SET_POPUP_VALUE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  RELATED_REVIEWLIST_DISTINCT_DATA,
  SET_MIDDLEPANE_STATE,
  GET_ASSIGNMENT_REPORT_INFO_SAGA,
  GET_ASSIGNMENT_REPORT_SAGA,
  SET_MOBILE_PANE_STATE,
  QRDATA_VERIFICATION_SAGA,
  QRURL_VERIFICATION_SAGA,
  POPUP_CLOSE,
  SET_DISPLAY_THREE_SINGLE_STATE,
  SET_POPUP_STATE,
  LOADER_START
} from '../../actionType';
import { ASSIGNMENT_MODULE_URL } from '../../endpoints';
import { EXCEPTION_ERROR_MESSAGE } from '../../errorMessage';
import store from '../../store';

const reviewInfoApi = async (requestObj) => {
  console.log(requestObj.data);
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      Authorization: localStorage.getItem('token')
    }),
    body: JSON.stringify(requestObj.data)
  };
  const response = await fetch(requestObj.URL, requestOptions);
  const json = await response.json();
  return json;
};

function* workerReviewInfoAssignmentSaga(data) {
  try {
    const userResponse = yield call(reviewInfoApi, {
      data: data.payload.reqBody,
      URL: ASSIGNMENT_MODULE_URL
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === '000') {
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'assignmentResponseObject', value: userResponse.responseObject[0] }
      });
      yield put({ type: LOADER_STOP });
    } else {
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerGetAssignmentReportSaga(data) {
  try {
    const userResponse = yield call(reviewInfoApi, {
      data: data.payload.request,
      URL: ASSIGNMENT_MODULE_URL
    });
    let { isRightPaneReview = false, righpaneheaderOne = '', panethree } = data.payload;
    if (userResponse.responseCode === '000') {
      // debugger

      if (panethree) {
        yield put({
          type: SET_DISPLAY_THREE_SINGLE_STATE, payload: {
            stateName: 'responseObj', value: userResponse?.responseObject?.result[0]
          }
        })

        if (userResponse?.responseObject?.result[0]?.assesseeAssignmentCultureJobProfileDistinct.length > 1) {
          yield put({
            type: SET_POPUP_STATE,
            payload: {
              popupMode: 'ASSIGNMENT_REPORT_REVIEW',
              popupHeaderOne: 'culture-profile',
              popupHeaderOneBadgeOne: '',
              popupHeaderOneBadgeTwo: '',
              isPopUpValue: 'REPORTPOPUPCP',
              popupOpenType: 'primary',
            }
          })
          yield put({ type: LOADER_STOP })
          return
        } else {

          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          yield put({ type: LOADER_START });

          let assignmentReportsData2 = store.getState().DisplayPaneTwoReducer.assignmentReportsData
          if (store.getState().DisplayPaneTwoReducer.sectionCreateType === 'iGuru Analytics_Occupation') {
            assignmentReportsData2 = [
              {
                id: 4,
                reportName: 'Merit List',
                reportDescription: 'Culture-Fit'
              },
              {
                id: 6,
                reportName: 'Merit List',
                reportDescription: 'Job-Fit'
              },
              {
                id: 9,
                reportName: 'Merit List',
                reportDescription: 'Work-Fit'
              },
            ]
          } else {
            assignmentReportsData2 = [
              {
                id: 1,
                reportName: 'Assignment Report',
                // reportDescription: 'Bespoke'
              },
            ]
          }

          let response = {
            report: assignmentReportsData2,
            assingmentId: store.getState().DisplayPaneThreeReducer?.responseObject?.assignmentId,
            assignmentName: store.getState().DisplayPaneThreeReducer?.responseObject?.assignmentName,
            assignmentDescription: store.getState().DisplayPaneThreeReducer?.responseObject?.assignmentDescription
          };
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'relatedReviewListDistinctData', value: [response] }
          });
          // setTimeout(function () {
          yield put({
            type: SET_MIDDLEPANE_STATE,
            payload: {
              middlePaneHeader: 'assignment',
              middlePaneHeaderBadgeOne: 'reports',
              middlePaneHeaderBadgeTwo: '',
              middlePaneHeaderBadgeThree: '',
              middlePaneHeaderBadgeFour: '',
              typeOfMiddlePaneList: 'assignmentReportReviewList',
              scanCount: (assignmentReportsData2 && assignmentReportsData2.length) || 0,
              showMiddlePaneState: true,
              middlePaneHeaderDuplicate:
                store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
              middlePaneHeaderDuplicateBadgeOne:
                store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
              middlePaneHeaderDuplicateBadgeTwo:
                store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
              middlePaneHeaderDuplicateBadgeThree:
                store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree, //
              middlePaneHeaderDuplicateBadgeFour:
                store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour, //
              middlePaneHeaderDuplicateOne: store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOne,
              middlePaneHeaderDuplicateOneBadgeOne: store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeOne,
              middlePaneHeaderDuplicateOneBadgeTwo: store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeTwo,
              middlePaneHeaderDuplicateOneBadgeThree: store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeThree,
              middlePaneHeaderDuplicateOneBadgeFour: store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeFour,
              duplicateScanCount: store.getState().DisplayPaneTwoReducer.duplicateScanCount,
            }
          });
          yield put({ type: LOADER_STOP });
          // }, 2000);
          yield put({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });

          return
        }

      }

      let response = userResponse.responseObject?.result;
      let x = ['Overall-Fit', 'Culture-Fit', 'Job-Fit']

      if (x.includes(data.payload.request.requestObject.resultResponse) && response[0]?.assesseeAssignmentCultureJobProfileDistinct?.length > 1) {
        let resp1 = []
        for (let i = 0; i < response.length; i++) {
          let tempResp = response[0];
          let tempArrx = tempResp.assesseeAssignmentCultureJobProfileDistinct.filter((data) => {
            return store.getState().PopUpReducer.reportCombinations.cultureProfile  === data.cultureProfileTag && store.getState().PopUpReducer.reportCombinations.jobProfile === data.jobProfileTag
          })
          tempResp.assesseeAssignmentCultureJobProfileDistinct = tempArrx;

          resp1.push(tempResp)
        }
        response = resp1
      }
      console.log(response)

      if (data.payload.request.requestObject.resultResponse === 'Overall-Fit') {
        response.sort(
          (a, b) =>
            b.assesseeAssignmentCultureJobProfileDistinct[0].overallFit -
            a.assesseeAssignmentCultureJobProfileDistinct[0].overallFit
        );
      }
      if (data.payload.request.requestObject.resultResponse === 'Culture-Fit') {
        response.sort(
          (a, b) =>
            b.assesseeAssignmentCultureJobProfileDistinct[0].overallFit -
            a.assesseeAssignmentCultureJobProfileDistinct[0].cultureFit
        );
      }
      if (data.payload.request.requestObject.resultResponse === 'Job-Fit') {
        response.sort(
          (a, b) =>
            b.assesseeAssignmentCultureJobProfileDistinct[0].overallFit -
            a.assesseeAssignmentCultureJobProfileDistinct[0].jobFit
        );
      }
      let responseObj = {
        assignmentId: data?.payload?.assignmentId,
        assignmentName: data?.payload?.assignmentName,
        assignmentDescription: data?.payload?.assignmentDescription,
        assignmentReport: response,
        reportId: data?.payload?.reportId,
        reportName: data?.payload?.reportName,
        reportDescription: data?.payload?.reportDescription
      };
      if (!isRightPaneReview) {
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: 'assignments',
            middlePaneHeaderBadgeOne: righpaneheaderOne,
            middlePaneHeaderBadgeTwo: data.payload.request.requestObject.resultResponse === 'siftLabel' ? data.payload.request.requestObject.siftLabel : '',
            middlePaneHeaderBadgeThree: '',
            middlePaneHeaderBadgeFour: '',
            typeOfMiddlePaneList: 'assignmentMeritListReportList',
            scanCount: userResponse && userResponse.responseObject.result.length,
            showMiddlePaneState: true,
            middlePaneHeaderDuplicate:
              store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
            middlePaneHeaderDuplicateBadgeOne:
              store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            middlePaneHeaderDuplicateBadgeTwo:
              store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            middlePaneHeaderDuplicateBadgeThree:
              store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree, //
            middlePaneHeaderDuplicateBadgeFour:
              store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour, //
            middlePaneHeaderDuplicateOne: store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOne,
            middlePaneHeaderDuplicateOneBadgeOne: store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeOne,
            middlePaneHeaderDuplicateOneBadgeTwo: store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeTwo,
            middlePaneHeaderDuplicateOneBadgeThree: store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeThree,
            middlePaneHeaderDuplicateOneBadgeFour: store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeFour,
            duplicateScanCount: store.getState().DisplayPaneTwoReducer.duplicateScanCount,
          }
        });
        yield put({ type: RELATED_REVIEWLIST_DISTINCT_DATA, payload: [responseObj] });
        yield put({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
      } else {
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'assignmentReportResponse', value: userResponse.responseObject }
        });
        yield put({
          type: SET_DISPLAY_PANE_THREE_STATE,
          payload: {
            headerOne: 'assignment',
            headerOneBadgeOne: righpaneheaderOne,
            headerOneBadgeTwo: '',
            headerOneBadgeThree: '',
            responseObject: responseObj,
            reviewMode: 'review'
          }
        });
        yield put({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
      }
      yield put({ type: LOADER_STOP });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}

function* workerQRCodeReviewInfoAssignmentSaga(data) {
  try {
    const userResponse = yield call(reviewInfoApi, {
      data: data.payload,
      URL: ASSIGNMENT_MODULE_URL
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === '000') {
      yield put({
        type: POPUP_CLOSE,
      });
      yield put({ type: LOADER_STOP });
    } else {
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}

function* workerQRUrlReviewInfoAssignmentSaga(data) {
  try {
    const userResponse = yield call(reviewInfoApi, {
      data: data.payload,
      URL: ASSIGNMENT_MODULE_URL
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === '000') {
      yield put({
        type: POPUP_CLOSE,
      });

      yield put({ type: LOADER_STOP });
    } else {
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    yield put({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'QRApisCallSuccessStatus', value: false }
    })
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'QRApisCallSuccessStatus', value: false }
    })
    yield put({ type: LOADER_STOP });
  }
}

export default function* watcherAssignmentReportSaga() {
  yield takeLatest(GET_ASSIGNMENT_REPORT_INFO_SAGA, workerReviewInfoAssignmentSaga);
  yield takeLatest(GET_ASSIGNMENT_REPORT_SAGA, workerGetAssignmentReportSaga);
  yield takeLatest(QRDATA_VERIFICATION_SAGA, workerQRCodeReviewInfoAssignmentSaga);
  yield takeLatest(QRURL_VERIFICATION_SAGA, workerQRUrlReviewInfoAssignmentSaga);
}
