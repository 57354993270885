import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import SendIcon from '@material-ui/icons/Send';
import iGuruLogo from '../../images/iglogo1.png';
import Store from '../../store';
import './DisplayPageConfirmUser.css';
import { InputLabel } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import InputField from '../../Atoms/InputField/InputField';
import PopUpAdministratorAssent from '../../PopUpDisplayPanel/PopUpAdministratorAssent';
import PopUpAssociateAssent from '../../PopUpDisplayPanel/PopUpAssociateAssent';
import PopUpAssentConfirmation from '../../PopUpDisplayPanel/PopUpAssentConfirmation';
import PopUpAdministratorSignInCredentialRevise from '../../PopUpDisplayPanel/PopUpAdministratorSignInCredentialRevise';
import PopUpAdministratorSignInPasswordRevise from '../../PopUpDisplayPanel/PopUpAdministratorSignInPasswordRevise';
import {
  INFORMATION_MISMATCHED_ERROR_MESSAGE,
  REQUIRED_ERROR_MESSAGE,
  INCORRECT_INFORMATION_ERROR_MESSAGE,
  INVALID_PASSWORD_ERROR_MESSAGE,
  INFORMATION_EXIST_ERROR_MESSAGE,
  MINIMUM_8_CHARACTER_ERROR,
  SPECIAL_CHARACTER_REQUIRED_ERROR,
  UPPER_CHARACTER_REQUIRED_ERROR,
  LOWER_CHARACTER_REQUIRED_ERROR,
  NUMBER_REQUIRED_ERROR
} from '../../errorMessage';
import {
  SET_SIGN_ON_SINGLE_STATE,
  CLEAR_SIGN_ON_SINGLE_STATE,
  ASSESSEE_CONFIRM_SAGA,
  LOADER_START,
  ASSESSEE_SIGN_IN_SAGA,
  SET_SIGN_IN_STATUS,
  SET_USER_STATE
} from '../../actionType';
import { useDispatch, useSelector } from 'react-redux';
import Label from '../../Atoms/Label/Label';
import LoadingComponent from '../../PopUpInformation/LoadingComponent';
import PopUpMessageGeneric from '../../PopUpGeneric/PopUpMessageGeneric';
const DisplayPageConfirmUser = () => {
  const bgImg = '/Image/bg.jpg';
  const { id } = useParams();
  const style = {
    backgroundPosition: '50% 50%',
    backgroundImage: `url(${bgImg})`,
    backgroundSize: 'cover'
  };
  const dispatch = useDispatch();
  const history = useHistory();
  const { assesseeSignInStatus, assesseeConfirmStatus, userData } = useSelector(
    (state) => state.UserReducer
  );
  const { signInRes } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { isLoading } = useSelector((state) => state.LoaderReducer);
  // const [isforgotPassword, setIsForgotPassword] = useState(false);
  const [isCredentialsInValid, setIsCredentialsInValid] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState('');
  const [password, setPassword] = useState('');
  const [stage, setStage] = useState(0); // index for popUpSequence
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [oldPasswordError, setOldPasswordError] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [signInInformationError, setSignInInformationError] = useState('');
  const [confirmRevisedPassword, setConfirmRevisedPassword] = useState('');
  const [confirmRevisedPasswordError, setConfirmRevisedPasswordError] = useState('');
  const { associateAssent, assesseeAssent, signInCredential } = useSelector(
    (state) => state.SignOnReducer
  );

  useEffect(() => {
    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refreshToken');
    if ((token || refreshToken) && signInRes?.signInResponse?.isAssesseeAssent && !signInRes?.signInResponse?.resetCredential && !signInRes?.signInResponse?.resetPassword) {
      let path = `/dashboard`;
      history.push(path);
    } else {
      if (assesseeSignInStatus === 'success') {
        if (signInRes.signInResponse.isAssesseeAssent && !signInRes?.signInResponse?.resetCredential && !signInRes?.signInResponse?.resetPassword) {
          let path = `/dashboard`;
          history.push(path);
        } else if (signInRes.signInResponse.length > 0) {
          let path = `/dashboard`;
          history.push(path);
        } else {
          if(signInRes?.signInResponse?.resetCredential){
            setStage(5);
          }
          else if(signInRes?.signInResponse?.resetPassword){
            setStage(6);
          }
          else{
            setStage(1);
          }
          setIsCredentialsInValid('');
          setNewPassword('');
          setOldPassword('');
          setConfirmRevisedPassword('');
          setOldPasswordError('');
          setNewPasswordError('');
          setConfirmRevisedPasswordError('');
        }
        dispatch({ type: SET_SIGN_IN_STATUS, payload: '' });
      }
      if (assesseeSignInStatus === 'error') {
        setIsCredentialsInValid(INCORRECT_INFORMATION_ERROR_MESSAGE);
      }
      if (assesseeSignInStatus === 'responseError') {
        let responseError = localStorage.getItem('responseError');
        setIsCredentialsInValid(responseError);
        dispatch({ type: SET_SIGN_IN_STATUS, payload: '' });
      }
      if (assesseeConfirmStatus === 'confirmSuccess') {
        let path = `/dashboard`;
        history.push(path);
        dispatch({
          type: SET_USER_STATE,
          payload: { stateName: 'assesseeConfirmStatus', value: '' }
        });
      }
      if (assesseeConfirmStatus === 'confirmErrorLinked') {
        setStage(7);
        dispatch({ type: SET_SIGN_IN_STATUS, payload: '' });
      }
      if (assesseeConfirmStatus === 'signInInformationExist') {
        setStage(5);
        dispatch({
          type: SET_USER_STATE,
          payload: { stateName: 'assesseeConfirmStatus', value: '' }
        });
        setSignInInformationError(INFORMATION_EXIST_ERROR_MESSAGE);
      }
      if (assesseeConfirmStatus === 'assesseeStatusIsInactive') {
        setStage(6);
        dispatch({
          type: SET_USER_STATE,
          payload: { stateName: 'assesseeConfirmStatus', value: '' }
        });
        console.log('msggggg', Store.getState().UserReducer.responseMessage);
        setIsCredentialsInValid(Store.getState().UserReducer.responseMessage);
      }
      if (assesseeConfirmStatus === 'incorrectInformation') {
        setStage(6);
        dispatch({
          type: SET_USER_STATE,
          payload: { stateName: 'assesseeConfirmStatus', value: '' }
        });
        setOldPasswordError(INCORRECT_INFORMATION_ERROR_MESSAGE);
        setIsCredentialsInValid(INCORRECT_INFORMATION_ERROR_MESSAGE);
      }
      if (assesseeConfirmStatus === 'assesseeStatusIsInactive') {
        setStage(6);
        dispatch({
          type: SET_USER_STATE,
          payload: { stateName: 'assesseeConfirmStatus', value: '' }
        });
        console.log('msggggg', Store.getState().UserReducer.responseMessage);
        setIsCredentialsInValid(Store.getState().UserReducer.responseMessage);
      }
    }
  }, [assesseeSignInStatus, assesseeConfirmStatus, history]);
  const onClickSignIn = () => {
    setIsCredentialsInValid('in progress');
    if (password) {
      setIsPasswordValid('');
      dispatch({
        type: ASSESSEE_SIGN_IN_SAGA,
        payload: {
          request: {
            module: 'Iguru-Iglobal',
            action: 'Sign-In',
            requestObject: {
              credential: id.trim(), // "607888f3226fc201a533a924",
              password: password.trim() //"NG36#:dqMO"
            }
          }
        }
      });
    } else {
      // validation set validation message if any
      setIsCredentialsInValid('');
      if (password === '') {
        setIsPasswordValid(REQUIRED_ERROR_MESSAGE);
      } else {
        setIsPasswordValid('');
      }
    }
  };
  const passwordRevise = () => {
    //according to creation mode popup sequence will change
    setConfirmRevisedPasswordError('');
    setNewPasswordError('');
    setOldPasswordError('');
    const passwordRegExp = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!{~:<-_?>}@#$%^&*])(?=.{8,})'
    );
    const numberRegExp = new RegExp('[0-9]');
    const capitalLetterRegExp = new RegExp('[A-Z]');
    const smallLetterRegExp = new RegExp('[a-z]');
    var symbolLetterRegExp = /[!"#$%&'()*+.\/:;<=>?@\[\\\]^_`{|}~-]/;

    let isValidation = true;
    let oldPassword1 = oldPassword.trim();
    let newPassword1 = newPassword.trim();
    let confirmRevisedPassword1 = confirmRevisedPassword.trim();

    if (oldPassword1 !== '' && newPassword1 !== '' && confirmRevisedPassword1 !== '') {
      //if both passwotrd is not eual
      if (newPassword1 !== confirmRevisedPassword1) {
        setNewPasswordError(INFORMATION_MISMATCHED_ERROR_MESSAGE);
        setConfirmRevisedPasswordError(INFORMATION_MISMATCHED_ERROR_MESSAGE);
      } else {
        //for password pwd
        // if (!passwordRegExp.test(newPassword1)) {
        if (!numberRegExp.test(newPassword1)) {
          setNewPasswordError(NUMBER_REQUIRED_ERROR);
          isValidation = false;
        }
        if (!smallLetterRegExp.test(newPassword1)) {
          setNewPasswordError(LOWER_CHARACTER_REQUIRED_ERROR);
          isValidation = false;
        }
        if (!capitalLetterRegExp.test(newPassword1)) {
          setNewPasswordError(UPPER_CHARACTER_REQUIRED_ERROR);
          isValidation = false;
        }
        if (!symbolLetterRegExp.test(newPassword1)) {
          setNewPasswordError(SPECIAL_CHARACTER_REQUIRED_ERROR);
          isValidation = false;
        }
        if (newPassword1.length < 8) {
          setNewPasswordError(MINIMUM_8_CHARACTER_ERROR);
          isValidation = false;
        }
        // }
        //for confirmed pwd
        // if (!passwordRegExp.test(confirmRevisedPassword1)) {
        if (!numberRegExp.test(confirmRevisedPassword1)) {
          setConfirmRevisedPasswordError(NUMBER_REQUIRED_ERROR);
          isValidation = false;
        }
        if (!smallLetterRegExp.test(confirmRevisedPassword1)) {
          setConfirmRevisedPasswordError(LOWER_CHARACTER_REQUIRED_ERROR);
          isValidation = false;
        }
        if (!capitalLetterRegExp.test(confirmRevisedPassword1)) {
          setConfirmRevisedPasswordError(UPPER_CHARACTER_REQUIRED_ERROR);
          isValidation = false;
        }
        if (!symbolLetterRegExp.test(confirmRevisedPassword1)) {
          setConfirmRevisedPasswordError(SPECIAL_CHARACTER_REQUIRED_ERROR);
          isValidation = false;
        }
        if (confirmRevisedPassword1.length < 8) {
          setConfirmRevisedPasswordError(MINIMUM_8_CHARACTER_ERROR);
          isValidation = false;
        }
        if (isValidation) {
          setNewPasswordError('');
          setConfirmRevisedPasswordError('');
          dispatch({
            type: SET_SIGN_ON_SINGLE_STATE,
            payload: {
              stateName: 'currentPassword',
              value: oldPassword1
            }
          });
          dispatch({
            type: SET_SIGN_ON_SINGLE_STATE,
            payload: {
              stateName: 'revisedPassword',
              value: newPassword1
            }
          });
          console.log(signInRes);
          console.log('signInRes=========');
          let reqObj = {
            action: 'Update-Sign-In',
            module: 'Iguru-Iglobal-Sign-In',
            requestObject: {
              assesseeId: signInRes?.signInResponse?.assesseeId,
              associateId: signInRes?.signInResponse?.associateId,
              associateAssent: signInRes?.signInResponse?.isAssociateAssent || associateAssent,
              assesseeAssent: assesseeAssent,
              signinId: signInCredential === '' ? id : signInCredential,
              currentPassword: oldPassword1,
              revisedPassword: newPassword1,
              accessToken: signInRes?.cognitoResponse?.accessToken,
              resetPassword:false,
              resetCredential:false
            }
          };
          dispatch({ type: LOADER_START });
          dispatch({
            type: ASSESSEE_CONFIRM_SAGA,
            payload: {
              requestObject: reqObj
            }
          });
        }
        // }
      }
      
    } else {
      if (oldPassword === '') {
        setOldPasswordError(REQUIRED_ERROR_MESSAGE);
      }
      if (newPassword === '') {
        setNewPasswordError(REQUIRED_ERROR_MESSAGE);
      }
      if (confirmRevisedPassword === '') {
        setConfirmRevisedPasswordError(REQUIRED_ERROR_MESSAGE);
      }
      console.log('ALL Field requred');
    }
  };

  console.log('msggggg>>>', stage);

  // administrator (assent) / associate (assent).

  // pop-up
  // administrator (assent)... text.
  // administrator (assent); no/yes.
  // associate (assent)... text.
  // associate (assent); no/yes.
  // administrator (primary)... sign-in credential... revise.
  // administrator (primary)... sign-in password... revise.

  // pop-up
  // assessee (assent)... text.
  // assessee (assent); no/yes.

  return (
    <div>
      <LoadingComponent isActive={isLoading} />
      <div style={style} className="signin-container">
        <div className="form-box">
          {stage === 0 && (
            <div className="form-header">
              <div className="inner-form-header">
                <div className="form-header-logo-container">
                  <img className="form-header-logo-img" src={iGuruLogo} alt="iGuru logo" />
                </div>
                <div>
                  <IconButton className="form-icon-style" onClick={onClickSignIn}>
                    <SendIcon style={{ height: 20, width: 20 }}  />
                  </IconButton>
                </div>
              </div>
            </div>
          )}
          <div style={{ padding: '5px' }}>
            <>
              {stage === 0 && (
                <>
                  <div className={'fitContent'}>
                    <div className={['PopupFormBox', 'labelPopupBox', 'popupMinHei'].join(' ')}>
                      <InputLabel htmlFor="name-input" className={'textForLabelPopup'}>
                        <span>{'sign-in'}&nbsp;</span>
                      </InputLabel>
                      {/* <div className={'infoSymbol'}></div>
              <div className={'infoSymbol'}>
                <InfoToolTip message="Click me, I will stay visible until you click outside." />
              </div> */}
                    </div>
                  </div>
                  <InputField className="" label="credential" type="text" value={id} />
                  <InputField
                    className=""
                    label="password"
                    type="password"
                    errorMsg={isPasswordValid}
                    onClick={(e) => {
                      setIsPasswordValid('');
                      // setIsCredentialsInValid('');
                      setPassword(e.target.value);
                    }}
                  />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      margin: '0 5px'
                    }}
                  >
                    <div
                      style={{ cursor: 'pointer', width: 'fit-content' }}
                      onClick={() => {
                        // setIsForgotPassword(true);
                        setStage('forgotPassword');
                      }}
                    ></div>
                    <div>
                      {isCredentialsInValid && (
                        <Label
                          text={isCredentialsInValid}
                          fontSize="1.2rem"
                          colour={
                            isCredentialsInValid === 'in progress' ? '#7DC832' : 'rgb(244, 67, 54)'
                          }
                        />
                      )}
                    </div>
                  </div>
                </>
              )}
            </>
          </div>
        </div>
        <PopUpAdministratorAssent
          isActive={stage === 1 && !cancelConfirm}
          headerPanelColour={''}
          assessentText={signInRes?.signInResponse?.assesseeAssent}
          assesseeAssent={true}
          associateAssent={false}
          headerOne={'assessee'}
          headerOneBadgeOne={'assent'}
          onClick={() => {
            setStage(2);
          }}
          onClose={() => {
            setCancelConfirm(true);
          }}
        />
        <PopUpAssentConfirmation
          isActive={stage === 2 && !cancelConfirm}
          headerPanelColour={''}
          headerOne={'assessee'}
          headerOneBadgeOne={'assent'}
          mode={'cancel'}
          onClickYes={() => {
            dispatch({
              type: SET_SIGN_ON_SINGLE_STATE,
              payload: {
                stateName: 'assesseeAssent',
                value: true
              }
            });
            signInRes?.signInResponse.isAssociateAssent ? setStage(5) : setStage(3);
          }}
          onClickNo={() => {
            setStage(0);
          }}
        />
        <PopUpAssentConfirmation
          isActive={cancelConfirm}
          headerPanelColour={''}
          headerOne={'cancel'}
          headerOneBadgeOne={''}
          mode={'cancel'}
          onClickYes={() => {
            setStage(0);
            setNewPassword('');
            setPassword('');
            dispatch({ type: CLEAR_SIGN_ON_SINGLE_STATE });
            setCancelConfirm(false);
          }}
          onClickNo={() => {
            setCancelConfirm(false);
          }}
        />
        <PopUpAssociateAssent
          isActive={stage === 3 && !cancelConfirm}
          headerPanelColour={''}
          headerOne={'associate'}
          headerOneBadgeOne={'assent'}
          // associateText={signInRes?.signInResponse?.associateAssent}
          assesseeAssent={true}
          associateAssent={false}
          onClick={() => {
            setStage(4);
          }}
          onClose={() => {
            setCancelConfirm(true);
          }}
        />
        <PopUpAssentConfirmation
          isActive={stage === 4 && !cancelConfirm}
          headerPanelColour={''}
          headerOne={'associate'}
          headerOneBadgeOne={'assent'}
          mode={'cancel'}
          onClickYes={() => {
            dispatch({
              type: SET_SIGN_ON_SINGLE_STATE,
              payload: {
                stateName: 'associateAssent',
                value: true
              }
            });
            setStage(5);
          }}
          onClickNo={() => {
            setStage(0);
          }}
        />
        <PopUpAdministratorSignInCredentialRevise
          isActive={stage === 5 && !cancelConfirm}
          headerPanelColour={''}
          headerOne={'assessee'}
          headerOneBadgeOne={''}
          signValue={signInCredential}
          // signInOptions={signInOptions}
          errorMsg={signInInformationError}
          signInOptions={signInRes?.signInResponse?.credential}
          onClick={() => {
            setStage(6);
          }}
          onClose={() => {
            setCancelConfirm(true);
          }}
        />
        <PopUpAdministratorSignInPasswordRevise
          isActive={stage === 6 && !cancelConfirm}
          headerPanelColour={''}
          headerOne={'assessee'}
          headerOneBadgeOne={'password'}
          headerOneBadgeTwo={'revise'}
          oldPassword={oldPassword}
          oldPasswordError={oldPasswordError}
          setOldPassword={setOldPassword}
          setOldPasswordError={setOldPasswordError}
          newPassword={newPassword}
          newPasswordError={newPasswordError}
          setNewPassword={setNewPassword}
          setNewPasswordError={setNewPasswordError}
          confirmRevisedPassword={confirmRevisedPassword}
          confirmRevisedPasswordError={confirmRevisedPasswordError}
          setConfirmRevisedPassword={setConfirmRevisedPassword}
          setConfirmRevisedPasswordError={setConfirmRevisedPasswordError}
          responseErr={ (!confirmRevisedPasswordError && !newPasswordError && !oldPasswordError) ? isCredentialsInValid : ""}
          onClick={passwordRevise}
          onClose={() => {
            setCancelConfirm(true);
          }}
        />
        {stage === 7 && (
          <PopUpMessageGeneric
            isActive={true}
            isConfirmPage={true}
            headerPanelColour={''}
            headerOne={'Confirm'}
            headerOneBadgeOne={''}
            nextPopUpValue={''}
            textOneOne={'Associate already linked'}
            textOneTwo={''}
            textOneThree={''}
            textOneFour={``}
            mode={'error'}
            onClose={() => {
              setCancelConfirm(true);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default DisplayPageConfirmUser;
