import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PopUpPicture from '../../PopUpInformation/PopUpPicture';
import PopUpTextField from '../../PopUpInformation/PopUpTextField';
import PopUpConfirm from '../../PopUpGeneric/PopUpConfirm';
import {
  POPUP_CLOSE,
  LOADER_START,
  SET_DISPLAY_THREE_SINGLE_STATE,
  SET_GUAGE_REDUCER_STATE,
  CLEAR_GUAGE_REDUCER_STATE,
  SET_GUAGE_INFO_REDUCER_STATE,
  CREATE_GAUGE_SAGA,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_POPUP_SINGLE_STATE,
  CLEAR_DISPLAY_PANE_THREE
} from '../../actionType';
import PopUpDropList from '../../PopUpInformation/PopUpDropList';
import PopUpTagSecondary from '../../PopUpInformation/PopUpTagSecondary';
import { getPopUpOptionList } from '../../Actions/GenericActions';
import { GAUGE_REVIEW_LIST_POPUP_OPTION } from '../../PopUpConfig';
import PopUpReviewList from '../../PopUpInformation/PopUpReviewList';

const PopUpGaugeCreate = (props) => {
  const { headerOne } = props;
  const { isPopUpValue, selectedTagValue } = useSelector((state) => state.PopUpReducer);
  const { gaugeInformation, gaugeInformationrmation, objectName } = useSelector(
    (state) => state.GaugeCreateReducer
  );
  console.log('gaugeInformation', gaugeInformation);
  console.log('gaugeInformationrmation', gaugeInformationrmation);
  const { responseObject, reviewMode, createMode } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  const { selectedAssociateInfo, typeOfMiddlePaneList, signedAssesseePermission, secondaryOptionCheckValue } = useSelector(
    (state) => state.DisplayPaneTwoReducer
  );
  const dispatch = useDispatch();
  const onClickCancelYes = () => {
    dispatch({
      type: SET_DISPLAY_THREE_SINGLE_STATE,
      payload: { stateName: 'createMode', value: '' }
    });
    dispatch({ type: CLEAR_GUAGE_REDUCER_STATE });
    dispatch({ type: POPUP_CLOSE });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  };
  const onClickMetricsCancelYes = () => {
    dispatch({ type: CLEAR_GUAGE_REDUCER_STATE });
    dispatch({ type: POPUP_CLOSE });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  };
  const onClickYes = () => {
    dispatch({ type: POPUP_CLOSE });
    let requestObj = {
      module: 'Analytics',
      action: 'Create-Gauge',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        iGaugeOccupationFrameworkOneGauge: gaugeInformation,
        action: 'create' //reviewId//reviewList
      }
    };
    console.log('requestObj', requestObj);
    dispatch({ type: POPUP_CLOSE });
    dispatch({ type: LOADER_START });
    dispatch({ type: CREATE_GAUGE_SAGA, payload: { reqBody: requestObj, action: 'create' } });
    let popupContentArrValue = [];
    let tempArr = [];
    popupContentArrValue = GAUGE_REVIEW_LIST_POPUP_OPTION.map((item) =>
      item.data === 'assessees' ? { ...item, data: 'metrics', dataValue: 'metrics' } : item
    );
    let analyticDistinctPermission =
      signedAssesseePermission?.iGuruAnalyticDistinctPermission
        ?.assesseeiGuruAnalyticDistinctPermission;
    let popupContentArr = getPopUpOptionList(popupContentArrValue, analyticDistinctPermission);
    popupContentArr.map((element) => {
      if (element.data === 'allocate') {
        tempArr.push({ ...element, disabled: true });
      } else tempArr.push(element);
    });
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: {
        stateName: 'popupOptionArr',
        value: tempArr
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: tempArr
      }
    });
  };
  const onClickMetricsYes = () => {
    let obj = {
      ...gaugeInformation,
      iGaugeOccupationFrameworkOneGaugeMetric: [
        ...gaugeInformation.iGaugeOccupationFrameworkOneGaugeMetric,
        gaugeInformationrmation
      ]
    };
    console.log('obj_', obj);
    let requestObj = {
      module: 'Analytics',
      action: 'Create-Gauge',
      requestObject: {
        action: 'revise',
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        iGaugeOccupationFrameworkOneGauge: obj
      }
    };
    console.log('requestObj', requestObj);
    dispatch({ type: POPUP_CLOSE });
    dispatch({ type: LOADER_START });
    dispatch({
      type: CREATE_GAUGE_SAGA,
      payload: {
        secondaryOptionCheckValue,
        headerOne: 'guage',
        reqBody: requestObj,
        assessmentSector: 'updategauge',
        action: 'revise',
        // subaction: 'metricsCreate',
        createMode,
        selectedSector: selectedTagValue
      }
    });
    // dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  };
  let tagPrimary = '';
  let tenureStartDate = 'mm/dd/yyyy --:-- --';
  let tenureEndDate = 'mm/dd/yyyy --:-- --';

  return (
    <div>
      {isPopUpValue === 'NAMEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'name'}
          actualLableValue={'iGaugeOccupationFrameworkOneGaugeName'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'gauge'} // gauge create popoup
          headerOneBadgeTwo={'information'}
          nextPopUpValue={'ALIASPOPUP'}
          basicInfo={gaugeInformation}
          typeOfSetObject={SET_GUAGE_REDUCER_STATE}
          isRequired={true}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctMetricsDistinct'}
        />
      )}
      {isPopUpValue === 'METRICSNAMEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'name'}
          actualLableValue={'iGaugeOccupationFrameworkOneGaugeMetricLabel'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'gauge'}
          headerOneBadgeTwo={'information'}
          nextPopUpValue={'METRICSALIASPOPUP'}
          basicInfo={gaugeInformationrmation}
          typeOfSetObject={SET_GUAGE_REDUCER_STATE}
          isRequired={true}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctMetricsDistinct'}
        />
      )}
      {isPopUpValue === 'ALIASPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'description'}
          actualLableValue={'iGaugeOccupationFrameworkOneGaugeDescription'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'gauge'}
          headerOneBadgeTwo={'information'}
          basicInfo={gaugeInformation}
          typeOfSetObject={SET_GUAGE_REDUCER_STATE}
          nextPopUpValue={'PICTUREPOPUP'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctMetricsDistinct'}
        />
      )}
      {isPopUpValue === 'METRICSALIASPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'description'}
          actualLableValue={'iGaugeOccupationFrameworkOneGaugeMetricDescription'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'gauge'}
          headerOneBadgeTwo={'information'}
          basicInfo={gaugeInformationrmation}
          typeOfSetObject={SET_GUAGE_REDUCER_STATE}
          nextPopUpValue={'PICTUREPOPUP'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctMetricsDistinct'}
        />
      )}
      {isPopUpValue === 'GAUGE_VALUE_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'iGaugeOccupationFrameworkOneGaugeValue'}
          label={'value'}
          listSelect={[
            { id: '', name: '' },
            { id: 'Overall-Fit', name: 'Work-Fit' }
          ]}
          mappingValue={'id'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'gauge'}
          headerOneBadgeTwo={'information'}
          basicInfo={gaugeInformation}
          nextPopUpValue={''}
          typeOfSetObject={SET_GUAGE_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'PICTUREPOPUP' && (
        <PopUpPicture
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'gauge'}
          headerOneBadgeTwo={'information'}
          nextPopUpValue={'CONFIRMATIONPOPUP'}
          actualLableValue={'iGaugeOccupationFrameworkOneGaugePicture'}
          actualLableValueVerification={'iGaugeOccupationFrameworkOneGaugePictureVerification'}
          basicInfo={gaugeInformation}
          typeOfSetObject={SET_GUAGE_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctMetricsDistinct'}
        />
      )}

      {isPopUpValue === 'LABELPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'label'}
          actualLableValue={'iGaugeOccupationFrameworkOneGaugeMetricLabel'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'gauge'}
          headerOneBadgeTwo={'metrics'}
          headerOneBadgeThree={'information'}
          nextPopUpValue={'DESCRIPTIONPOPUP'}
          basicInfo={gaugeInformationrmation}
          typeOfSetObject={SET_GUAGE_INFO_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRequired={true}
          isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctMetricsDistinct'}
          //mode={'core'}
        />
      )}
      {isPopUpValue === 'DESCRIPTIONPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'description'}
          actualLableValue={'iGaugeOccupationFrameworkOneGaugeMetricDescription'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'gauge'}
          headerOneBadgeTwo={'metrics'}
          headerOneBadgeThree={'information'}
          nextPopUpValue={'EXPLANATIONPOPUP'}
          basicInfo={gaugeInformationrmation}
          typeOfSetObject={SET_GUAGE_INFO_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctMetricsDistinct'}
          //mode={'core'}
        />
      )}
      {isPopUpValue === 'EXPLANATIONPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'explanation'}
          actualLableValue={'iGaugeOccupationFrameworkOneGaugeMetricExplanation'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'gauge'}
          headerOneBadgeTwo={'metrics'}
          headerOneBadgeThree={'information'}
          nextPopUpValue={'SCOREPOPUPEXTREMUMMAXIMUM'}
          basicInfo={gaugeInformationrmation}
          typeOfSetObject={SET_GUAGE_INFO_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctMetricsDistinct'}
          //mode={'core'}
        />
      )}
      {isPopUpValue === 'SCOREPOPUPEXTREMUMMAXIMUM' && (
        <PopUpTextField
          isActive={true}
          label={'extremum'}
          labelBadgeOne={'maximum'}
          type={'number'}
          actualLableValue={'iGaugeOccupationFrameworkOneGaugeMetricExtremumMaximum'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'gauge'}
          headerOneBadgeTwo={'metrics'}
          headerOneBadgeThree={'information'}
          nextPopUpValue={'SCOREPOPUPEXTREMMMINIMUM'}
          basicInfo={gaugeInformationrmation}
          typeOfSetObject={SET_GUAGE_INFO_REDUCER_STATE}
          minimumValue={0}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          //mode={'core'}
        />
      )}
      {isPopUpValue === 'SCOREPOPUPEXTREMMMINIMUM' && (
        <PopUpTextField
          isActive={true}
          label={'extremum'}
          labelBadgeOne={'minimum'}
          type={'number'}
          actualLableValue={'iGaugeOccupationFrameworkOneGaugeMetricExtremumMinimum'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'gauge'}
          headerOneBadgeTwo={'metrics'}
          headerOneBadgeThree={'information'}
          nextPopUpValue={'POPUPYES'}
          basicInfo={gaugeInformationrmation}
          typeOfSetObject={SET_GUAGE_INFO_REDUCER_STATE}
          minimumValue={0}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          //mode={'core'}
        />
      )}
      {isPopUpValue === 'POPUPYES' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'gauge'}
          headerOneBadgeTwo={'metrics'}
          headerOneBadgeThree={'create'}
          onClickYes={onClickMetricsYes}
          onClickNoFun={onClickMetricsCancelYes} // metrics created y n
        />
      )}
      {isPopUpValue === 'CANCELPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'cancel'}
          headerOneBadgeOne={'gauge'}// metrics / gague created on way cross y n
          mode={'cancel'}
          onClickYes={onClickCancelYes}
        />
      )}
      {isPopUpValue === 'CONFIRMATIONPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'gauge'} // gauge cretae ye no
          headerOneBadgeTwo={'create'}
          onClickYes={onClickYes}
          onClickNoFun={onClickCancelYes}
        />
      )}
      {isPopUpValue === 'TAGREADONLYPRIMARYPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tag'}
          labelBadgeOne={'primary'}
          actualLableValue={'assessmentTagPrimary'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'gauge'}
          headerOneBadgeTwo={'information'}
          basicInfo={tagPrimary}
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={SET_GUAGE_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TENURESATRTDATEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tenure'}
          labelBadgeOne={'start'}
          actualLableValue={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'gauge'}
          headerOneBadgeTwo={'information'}
          basicInfo={tenureStartDate}
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={SET_GUAGE_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TENUREENDDATEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tenure'}
          labelBadgeOne={'end'}
          actualLableValue={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'gauge'}
          headerOneBadgeTwo={'information'}
          basicInfo={tenureEndDate}
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={SET_GUAGE_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'STATUSPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentStatus'} // objectName+'Status'
          label={'status'}
          listSelect={[
            { id: 'ACTIVE', name: 'Active' },
            { id: 'ARCHIVED', name: 'Archived' },
            { id: 'CONFIRMED', name: 'Confirmed' },
            { id: 'SUSPENDED', name: 'Suspended' },
            { id: 'TERMINATED', name: 'Terminated' },
            { id: 'UNADMINISTER', name: 'Unverified' }
          ]}
          mappingValue={'id'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'gauge'}
          headerOneBadgeTwo={'information'}
          isRequired={true}
          basicInfo={''} //responseObject?.iGaugeOccupationFrameworkOneGaugeStatus
          nextPopUpValue={''}
          typeOfSetObject={SET_GUAGE_REDUCER_STATE}
          handleNextPopupValue={() => {}}
          isNotRevised={true}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TAGSECONDARYPOPUP' && (
        <PopUpTagSecondary
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'gauge'}
          headerOneBadgeTwo={'information'}
          tagSecondary={'' || {}}
          signInSetup={'' || {}}
          nextPopUpValue={'CONFIRMATIONPOPUP'}
          typeOfSetObject={SET_GUAGE_REDUCER_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'METRICS_LIST_POPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'gauge'}
          headerOneBadgeTwo={'information'}
          inputHeader={'metric'}
          inputHeaderBadge={'distinct'}
          infoMsg={''}
          ListData={gaugeInformation?.iGaugeOccupationFrameworkOneGaugeMetric}
          textOne={'iGaugeOccupationFrameworkOneGaugeMetricLabel'}
          textTwo={'iGaugeOccupationFrameworkOneGaugeMetricDescription'}
          selectedList={[]}
          onClickEvent={null}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={true}
        />
      )}
    </div>
  );
};

export default PopUpGaugeCreate;
