import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PopUpPicture from '../../PopUpInformation/PopUpPicture';
import PopUpTextField from '../../PopUpInformation/PopUpTextField';
import PopUpConfirm from '../../PopUpGeneric/PopUpConfirm';
import {
  POPUP_CLOSE,
  CREATE_ITEM_SAGA,
  LOADER_START,
  CLEAR_TYPE_REDUCER_STATE,
  SET_DISPLAY_THREE_SINGLE_STATE,
  SET_TYPE_REDUCER_STATE,
  SET_ITEM_DYNAMIC_SINGLE_STATE,
  SET_ITEM_FRAMEWORK_DYNAMIC_SINGLE_STATE,
  SET_ITEMFRAMEWORK_REDUCER_STATE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_POPUP_SINGLE_STATE,
  CLEAR_ITEM_REDUCER_STATE,
  SET_ITEM_SETUP_INFO_STATE
} from '../../actionType';
import PopUpReviewList from '../../PopUpInformation/PopUpReviewList';
import PopUpDropList from '../../PopUpInformation/PopUpDropList';
import { TramOutlined } from '@material-ui/icons';
import PopUpTagSecondary from '../../PopUpInformation/PopUpTagSecondary';
import { BASIC_PUBLISH_REVIEW_LIST_NOT_ITERBLE_POPUP } from '../../PopUpConfig';
import { getPopUpOptionList } from '../../Actions/GenericActions';
import PopUpItemConfig from '../../PopUpInformation/PopUpItemConfig';
import PopUpTimeFeild from '../../PopUpInformation/PopUpTimeFeild';

const PopUpItemCreate = (props) => {
  const { headerOne = 'item' } = props;
  const { isPopUpValue, popupErrorMsg } = useSelector((state) => state.PopUpReducer);
  const { itemInformation } = useSelector((state) => state.ItemCreateReducer);
  const { reviewMode, statusPopUpValue = '', responseObject, createMode } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  console.log('itemInformation', itemInformation);
  console.log('statusPopUpValue', statusPopUpValue);

  const {
    selectedAssociateInfo,
    coreGroupReviewListData,
    coreTypeReviewListData,
    coreNodeReviewListData,
    signedAssesseePermission,
    sectionCreateType,
    sectionCreateTypeSecondary
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const dispatch = useDispatch();
  const [requiredErrorMsg, setRequiredErrorMsg] = useState('');
  //set default type 
  useEffect(() => {
    let tempTypeArr = coreTypeReviewListData.filter((tt) => {
      return tt.informationSetup?.itemTypeDefault;
    });
    if (tempTypeArr.length > 0) {
      if(!itemInformation.informationAllocation.itemType.itemTypePrimary.includes(tempTypeArr[0].id)){
        itemInformation.informationAllocation.itemType.itemTypePrimary.push(
          tempTypeArr[0].id
        );
      }
      }
     
  }, [coreTypeReviewListData, createMode]);

  //items need to test..
  // useEffect(() => {
  //   if (sectionCreateTypeSecondary !== '' && sectionCreateType !== '') {
  //     let arrTypeSecondary = [];
      
  //     arrTypeSecondary.push(
  //       `${process.env[`REACT_APP_ITEM_${sectionCreateTypeSecondary.replace(' ', '_').toUpperCase()}`]}`
  //     );
  //     itemInformation.informationAllocation.itemType.itemTypeSecondary = arrTypeSecondary;
  //     // console.log(assesseeInfo.informationAllocation.assesseeType.assesseeTypeSecondary, assesseeInfo.informationAllocation)
  //   }
  // }, [sectionCreateType, sectionCreateTypeSecondary])

  const onClickCancelYes = () => {
    dispatch({
      type: SET_DISPLAY_THREE_SINGLE_STATE,
      payload: { stateName: 'createMode', value: '' }
    });
    dispatch({ type: CLEAR_ITEM_REDUCER_STATE });
    dispatch({ type: POPUP_CLOSE });
  };
  const onClickYes = () => {
    if (itemInformation?.informationAllocation.itemNode.itemNodePrimary.length === 0) {
      let rootNode = coreNodeReviewListData.filter((node) => {
        return node.informationFramework.associateNodeAscendantPrimary === null;
      });
      let rootNodeId = rootNode[0].id;
      itemInformation.informationAllocation.itemNode.itemNodePrimary = [
        ...itemInformation?.informationAllocation.itemNode.itemNodePrimary,
        rootNodeId
      ];
    }
    let reqBody = {
      module: 'Item-Distinct',
      action: 'Create',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        item: {
          informationBasic: itemInformation.informationBasic,
          informationAllocation: itemInformation.informationAllocation,
          informationFramework: itemInformation.informationFramework
        }
      }
    };
    console.log('CREATE item api', reqBody);
    let itemDistictPermssion =
      signedAssesseePermission?.itemDistictPermssion?.assesseeItemDistinctPermission;
    let popupContentArr = getPopUpOptionList(BASIC_PUBLISH_REVIEW_LIST_NOT_ITERBLE_POPUP, itemDistictPermssion);
    let finalPopupOptionArray = [];
      popupContentArr.forEach((element) => {
      if (element.data === 'share') {
        finalPopupOptionArray.push({ ...element, disabled: true });
      } else {
        finalPopupOptionArray.push(element);
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: finalPopupOptionArray
      }
    });
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: {
        stateName: 'originPrimaryArrOprion',
        value: finalPopupOptionArray
      }
    });
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: {
        stateName: 'popupOptionArr',
        value: finalPopupOptionArray
      }
    });
    dispatch({ type: LOADER_START });
    dispatch({ type: CREATE_ITEM_SAGA, payload: reqBody });
  };
  let selectedPrimaryGroup =
    itemInformation?.informationAllocation?.itemGroup.itemGroupPrimary || [];
  let selectedSecondaryGroup =
    itemInformation?.informationAllocation?.itemGroup.itemGroupSecondary || [];
  let filteredCoreGroupReviewListDataPrimary = [];
  if (coreGroupReviewListData && coreGroupReviewListData.length > 0) {
    coreGroupReviewListData.forEach((group) => {
      // for primary popup list
      if (!selectedSecondaryGroup.includes(group.id))
        filteredCoreGroupReviewListDataPrimary.push(group);
    });
  }
  let filteredCoreGroupReviewListDataSecondary = [];
  if (coreGroupReviewListData && coreGroupReviewListData.length > 0) {
    coreGroupReviewListData.forEach((group) => {
      // for Secondary popup list
      if (!selectedPrimaryGroup.includes(group.id))
        filteredCoreGroupReviewListDataSecondary.push(group);
    });
  }
  const updateGroup = (e, stateName, actualStateName) => {
    let groupid = e.currentTarget.getAttribute('tag');
    let groupArr = itemInformation.informationAllocation[stateName][actualStateName];
    setRequiredErrorMsg('');
    let isDefault = e.currentTarget.getAttribute('data-default');
    if (isDefault !== 'true') {
      if (groupArr.includes(groupid)) {
        document.getElementById(groupid).style.backgroundColor = 'white';
        groupArr = groupArr.filter(function (number) {
          return number !== groupid;
        });
      } else {
        groupArr.push(groupid);
        document.getElementById(groupid).style.backgroundColor = '#F0F0F0';
      }
      dispatch({
        type: SET_ITEM_DYNAMIC_SINGLE_STATE,
        payload: {
          objectName: 'informationAllocation',
          stateName: stateName,
          actualStateName: actualStateName,
          value: groupArr
        }
      });
    }
  };
  return (
    <div>
      {isPopUpValue === 'NAMEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'name'}
          actualLableValue={'itemName'}
          actualLableValueVerification={'itemNameVerification'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={''}
          headerOneBadgeTwo={'information'}
          nextPopUpValue={'ALIASPOPUP'}
          errorMsg={popupErrorMsg}
          basicInfo={itemInformation.informationBasic}
          typeOfSetObject={SET_TYPE_REDUCER_STATE}
          isRequired={true}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ALIASPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'description'}
          actualLableValue={'itemDescription'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={''}
          headerOneBadgeTwo={'information'}
          basicInfo={itemInformation.informationBasic}
          nextPopUpValue={'PICTUREPOPUP'}
          typeOfSetObject={SET_TYPE_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'PICTUREPOPUP' && (
        <PopUpPicture
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={''}
          headerOneBadgeTwo={'information'}
          actualLableValue={'itemPicture'}
          actualLableValueVerification={'itemPictureVerification'}
          basicInfo={itemInformation.informationBasic}
          typeOfSetObject={SET_TYPE_REDUCER_STATE}
          nextPopUpValue={'GROUPPOPUP'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'GROUPPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={''}
          headerOneBadgeTwo={'information'}
          nextPopUpValue={'NODEPOPUP'}
          inputHeader={'group'}
          isRequired={false}
          inputHeaderBadge={'primary'}
          infoMsg={'select a group'}
          setErrorMsg={setRequiredErrorMsg}
          ListData={filteredCoreGroupReviewListDataPrimary}
          textOne={'itemGroupName'}
          textTwo={'itemGroupDescription'}
          onClickEvent={(e) => {
            updateGroup(e, 'itemGroup', 'itemGroupPrimary');
          }}
          selectedList={itemInformation.informationAllocation.itemGroup.itemGroupPrimary}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          useLocalState={true}
          stateName={`itemGroup`}
          actualStateName={`itemGroupPrimary`}
          objectName={'informationAllocation'}
          updateState={SET_ITEM_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'GROUPSECONDARYPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={''}
          headerOneBadgeTwo={'information'}
          nextPopUpValue={'NODEPOPUP'}
          inputHeader={'group'}
          isRequired={false}
          inputHeaderBadge={'secondary'}
          infoMsg={'select a group'}
          setErrorMsg={setRequiredErrorMsg}
          ListData={filteredCoreGroupReviewListDataSecondary}
          textOne={'itemGroupName'}
          textTwo={'itemGroupDescription'}
          onClickEvent={(e) => {
            updateGroup(e, 'itemGroup', 'itemGroupSecondary');
          }}
          selectedList={itemInformation.informationAllocation.itemGroup.itemGroupSecondary}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          useLocalState={true}
          stateName={`itemGroup`}
          actualStateName={`itemGroupSecondary`}
          objectName={'informationAllocation'}
          updateState={SET_ITEM_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'NODEPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={''}
          headerOneBadgeTwo={'information'}
          nextPopUpValue={'TYPEPOPUP'}
          inputHeader={'node'}
          isRequired={true}
          minimumSelected={1}
          setErrorMsg={setRequiredErrorMsg}
          errorMsg={requiredErrorMsg}
          inputHeaderBadge={'primary'}
          infoMsg={'select a node'}
          ListData={coreNodeReviewListData}
          textOne={'associateNodeName'}
          textTwo={'associateNodeDescription'}
          onClickEvent={(e) => {
            updateGroup(e, 'itemNode', 'itemNodePrimary');
          }}
          selectedList={itemInformation.informationAllocation.itemNode.itemNodePrimary}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          useLocalState={true}
          stateName={`itemNode`}
          actualStateName={`itemNodePrimary`}
          objectName={'informationAllocation'}
          updateState={SET_ITEM_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'ITEM_TRIPLEDOT_CONFIGURE_POPUP' && (
        <PopUpItemConfig
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'item'}
          headerOneBadgeOne={'information'}
          nextPopUpValue={''}
          inputHeader={'item'}
          primaryheader={'configuration'}
          isItemFramework={'itemConfig'}
          mode={reviewMode}
          itemFrameworkOne={itemInformation?.informationFramework?.itemFrameworkOne || []}
        // itemSelectedTypeName = {handleCallback}
        />
      )}
      {isPopUpValue === 'NODESECONDARYPOPUP' && (
        <PopUpReviewList
          isActive={TramOutlined}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={''}
          headerOneBadgeTwo={'information'}
          nextPopUpValue={'TYPEPOPUP'}
          inputHeader={'node'}
          isRequired={false}
          inputHeaderBadge={'secondary'}
          infoMsg={'select a node'}
          ListData={coreNodeReviewListData}
          textOne={'associateNodeName'}
          textTwo={'associateNodeDescription'}
          onClickEvent={(e) => {
            updateGroup(e, 'itemNode', 'itemNodeSecondary');
          }}
          selectedList={itemInformation.informationAllocation.itemNode.itemNodeSecondary}
          setErrorMsg={setRequiredErrorMsg}
          errorMsg={requiredErrorMsg}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          useLocalState={true}
          stateName={`itemNode`}
          actualStateName={`itemNodeSecondary`}
          objectName={'informationAllocation'}
          updateState={SET_ITEM_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'TYPEPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={''}
          headerOneBadgeTwo={'information'}
          nextPopUpValue={'CONFIRMATIONPOPUP'}
          inputHeader={'type'}
          isRequired={false}
          inputHeaderBadge={'primary'}
          infoMsg={'select a type'}
          setErrorMsg={setRequiredErrorMsg}
          errorMsg={requiredErrorMsg}
          dataDefault={'itemTypeDefault'}
          ListData={coreTypeReviewListData.filter((tt) => {
            return tt.informationSetup.itemTypeDefault;
          })}
          textOne={'itemTypeName'}
          textTwo={'itemTypeDescription'}
          onClickEvent={(e) => {
            updateGroup(e, 'itemType', 'itemTypePrimary');
          }}
          selectedList={itemInformation.informationAllocation.itemType.itemTypePrimary}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          useLocalState={true}
          stateName={`itemType`}
          actualStateName={`itemTypePrimary`}
          objectName={'informationAllocation'}
          updateState={SET_ITEM_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'TYPESECONDARYPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={''}
          headerOneBadgeTwo={'information'}
          nextPopUpValue={'CONFIRMATIONPOPUP'}
          inputHeader={'type'}
          isRequired={false}
          inputHeaderBadge={'secondary'}
          infoMsg={'select a type'}
          setErrorMsg={setRequiredErrorMsg}
          errorMsg={requiredErrorMsg}
          ListData={coreTypeReviewListData.filter((tt) => {
            return !tt.informationSetup.itemTypeDefault;
          })}
          textOne={'itemTypeName'}
          textTwo={'itemTypeDescription'}
          onClickEvent={(e) => {
            updateGroup(e, 'itemType', 'itemTypeSecondary');
          }}
          selectedList={itemInformation.informationAllocation.itemType.itemTypeSecondary}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          useLocalState={true}
          stateName={`itemType`}
          actualStateName={`itemTypeSecondary`}
          objectName={'informationAllocation'}
          updateState={SET_ITEM_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'CANCELPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'cancel'}
          headerOneBadgeOne={''}
          mode={'cancel'}
          onClickYes={onClickCancelYes}
        />
      )}
      {isPopUpValue === 'ITEMSCOREPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'score'}
          type={'number'}
          actualLableValue={'itemFrameworkOneScore'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={''}
          headerOneBadgeTwo={'information'}
          basicInfo={itemInformation.informationFramework?.itemFrameworkOne || ''}
          nextPopUpValue={''}
          typeOfSetObject={SET_ITEMFRAMEWORK_REDUCER_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ITEMTIMEPOPUP' && (
        <PopUpTimeFeild
          isActive={true}
          label={'time'}
          type={'time'}
          actualLableValue={'itemFrameworkOneTime'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          headerOneBadgeTwo={''}
          basicInfo={itemInformation.informationFramework?.itemFrameworkOne}
          nextPopUpValue={''}
          typeOfSetObject={SET_ITEMFRAMEWORK_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          issetTime={true}
        />
        // <PopUpTextField
        //   isActive={true}
        //   label={'time'}
        //   type={'time'}
        //   actualLableValue={'itemFrameworkOneTime'}
        //   headerPanelColour={'genericOne'}
        //   headerOne={headerOne}
        //   headerOneBadgeOne={''}
        //   headerOneBadgeTwo={'information'}
        //   basicInfo={itemInformation.informationFramework?.itemFrameworkOne || ''}
        //   nextPopUpValue={''}
        //   typeOfSetObject={SET_ITEMFRAMEWORK_REDUCER_STATE}
        //   reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        //   mode={reviewMode === 'revise' ? 'revise' : 'core'}
        //   issetTime={true}
        // />
      )}
      {isPopUpValue === 'ITEMWEITAGEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'weightage'}
          actualLableValue={'itemFrameworkOneWeightage'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={''}
          headerOneBadgeTwo={'information'}
          basicInfo={itemInformation.informationFramework?.itemFrameworkOne || ''}
          nextPopUpValue={''}
          typeOfSetObject={SET_ITEMFRAMEWORK_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ITEMLEVELPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'itemFrameworkOneLevel'}
          label={'level'}
          listSelect={[
            { id: 'High', name: 'High' },
            { id: 'Low', name: 'Low' },
            { id: 'Medium', name: 'Medium' }
          ]}
          mappingValue={'id'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          isRequired={false}
          basicInfo={itemInformation.informationFramework?.itemFrameworkOne || ''}
          typeOfSetObject={SET_ITEMFRAMEWORK_REDUCER_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ITEMPOLARITYPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'itemFrameworkOnePolarity'}
          label={'polarity'}
          listSelect={[
            { id: 'Negative', name: 'Negative' },
            { id: 'Positive', name: 'Positive' }
          ]}
          mappingValue={'id'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          isRequired={false}
          basicInfo={itemInformation.informationFramework?.itemFrameworkOne || ''}
          typeOfSetObject={SET_ITEMFRAMEWORK_REDUCER_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ITEMSCALEPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={''}
          headerOneBadgeTwo={'information'}
          inputHeader={'type'}
          isRequired={false}
          inputHeaderBadge={''}
          infoMsg={'select a type'}
          setErrorMsg={setRequiredErrorMsg}
          errorMsg={requiredErrorMsg}
          ListData={itemInformation.informationFramework.itemFrameworkOne.itemFrameworkOneScale}
          textOne={'itemFrameworkOneScale'}
          textTwo={''}
          // onClickEvent={(e) => {
          //   updateGroup(e, 'itemType', 'itemTypeSecondary');
          // }}
          // selectedList={}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'FRAMEWORKONETYPEPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={''}
          headerOneBadgeTwo={'information'}
          inputHeader={'type'}
          isRequired={false}
          inputHeaderBadge={''}
          infoMsg={'select a type'}
          setErrorMsg={setRequiredErrorMsg}
          errorMsg={requiredErrorMsg}
          ListData={itemInformation.informationFramework.itemTypeList || []}
          textOne={'itemFrameworkOneTypeName'}
          textTwo={'itemFrameworkOneTypeDescription'}
          // onClickEvent={(e) => {
          //   updateGroup(e, 'itemType', 'itemTypeSecondary');
          // }}
          selectedList={[
            itemInformation.informationFramework.itemFrameworkOne.itemFrameworkOneType
          ]}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'CONFIRMATIONPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'create'}
          headerOneBadgeTwo={''}
          onClickYes={onClickYes}
          onClickNoFun={onClickCancelYes}
        />
      )}
      {isPopUpValue === 'STATUSPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'itemStatus'}
          label={'status'}
          listSelect={[
            { id: 'Active', name: 'Active' },
            { id: 'Suspended', name: 'Suspended' },
            { id: 'Terminated', name: 'Terminated' },
            { id: 'Unverified', name: 'Unverified' },
            { id: 'Confirmed', name: 'Confirmed' },
            { id: 'Unconfirmed', name: 'Unconfirmed' },
            { id: 'Unpublished', name: 'Unpublished' },
            { id: 'Published', name: 'Published' }
          ]}
          mappingValue={'id'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          isRequired={true}
          basicInfo={statusPopUpValue}
          nextPopUpValue={''}
          typeOfSetObject={SET_ITEM_DYNAMIC_SINGLE_STATE}
          isNotRevised={true}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TENURESATRTDATEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tenure'}
          labelBadgeOne={'start'}
          actualLableValue={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          basicInfo={
            responseObject?.informationEngagement?.itemTenure?.itemTenureDateTimeStart ||
            'mm/dd/yyyy --:-- --'
          }
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={''}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TENUREENDDATEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tenure'}
          labelBadgeOne={'end'}
          actualLableValue={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          basicInfo={
            responseObject?.informationEngagement?.itemTenure?.itemTenureDateTimeEnd ||
            'mm/dd/yyyy --:-- --'
          }
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={''}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TAGREADONLYPRIMARYPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tag'}
          labelBadgeOne={'primary'}
          actualLableValue={'associateTagPrimary'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          basicInfo={responseObject?.informationEngagement?.itemTag?.itemTagPrimary || ''}
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={''}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TAGSECONDARYPOPUP' && (
        <PopUpTagSecondary
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          tagSecondary={responseObject.informationEngagement}
          signInSetup={responseObject.informationSetup}
          nextPopUpValue={'CONFIRMATIONPOPUP'}
          //typeOfSetObject={UPDATE_ASSESSEE_ENGAGEMENT_INFO}
          typeOfSetObject={''}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ITEMCREATEFEEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'fee'}
          headerPanelColour={'genericOne'}
          inputHeader={headerOne}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'share'}
          type={'number'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          isRequired={false}
          actualLableValue={'itemShareFee'}
          basicInfo={itemInformation.informationSetup}
          typeOfSetObject={SET_ITEM_SETUP_INFO_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          currencySymbol={''}
        />
      )}
    </div>
  );
};

export default PopUpItemCreate;