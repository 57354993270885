export const SIGN_IN_URL = '/sign-in';
export const DASHBOARD_URL = '/dashboard';
export const MANAGER_ROLE_ID = ['64ddb58b5ff75a1a2bbe3f45', '64ddb5b65ff75a1a2bbe3f46'];
export const ASSISTANT_ROLE_ID = ['64ddb58b5ff75a1a2bbe3f45', '64ddb5b65ff75a1a2bbe3f46'];
// export const ADMIN_ROLE_ID = ['Administrator (primary)', 'Administrator (secondary)'];
export const ADMINISTRATOR_SECONDARY_ID = '614c95cdef7e687bf6eb4a96';
export const DEFAULT_ROLE_ID = '614c960fef7e687bf6eb4a97';
export const ADMIN_ROLE_ID = ['614c95cdef7e687bf6eb4a96', '60952ca11752c36c69365331'];
//Profuction URL
// export const BUCKET_NAME ="iguru-serverless-prod";
// export const DIR_NAME= "media";
// export const REGION= "ap-south-1";
// export const ACCESS_KEY= "AKIAXWYDRA6RD2WEZ2IJ";
// export const SECRET_ACCESS_KEY= "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";
// export const PUBLIC_URL = 'https://platform.insightguru.com/';
// export const REPORT_URL="https://reports.insightguru.com/";
// const PLATFORM_URL="https://qkj8dswso8.execute-api.ap-south-1.amazonaws.com/prod/insight-guru/api/";
// const ASSESSEES_URL="https://tbhwwme2aj.execute-api.ap-south-1.amazonaws.com/prod/insight-guru/api/";
// const ASSESSMENTS_URL="https://xervwtpdbb.execute-api.ap-south-1.amazonaws.com/prod/insight-guru/api/";
// const ASSIGNMENTS_URL="https://f8ck4akn1e.execute-api.ap-south-1.amazonaws.com/prod/insight-guru/api/";
// const ASSOCIATES_URL="https://ac3kc39vsd.execute-api.ap-south-1.amazonaws.com/prod/insight-guru/api/";
// const ANALYTICS="https://b3ra2kicjh.execute-api.ap-south-1.amazonaws.com/prod/insight-guru/api/";
// const ITEMS="https://3owisknnth.execute-api.ap-south-1.amazonaws.com/prod/insight-guru/api/";

//stage URL
// export const BUCKET_NAME ="iguru-serverless-stage";
// export const DIR_NAME= "media";
// export const REGION= "ap-southeast-1";
// export const ACCESS_KEY= "AKIAXWYDRA6RNRBPP6US";
// export const PUBLIC_URL = 'https://development.insightguru.com/';
// export const SECRET_ACCESS_KEY= "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";
// export const REPORT_URL="https://reports-stage.insightguru.com/";
// const PLATFORM_URL="https://wmj5b647zk.execute-api.ap-southeast-1.amazonaws.com/stage/insight-guru/api/";
// const ASSESSEES_URL="https://m1xpleu0ij.execute-api.ap-southeast-1.amazonaws.com/stage/insight-guru/api/";
// const ASSESSMENTS_URL="https://9olk0u3r57.execute-api.ap-southeast-1.amazonaws.com/stage/insight-guru/api/";
// const ASSIGNMENTS_URL="https://7tr2gnaa3e.execute-api.ap-southeast-1.amazonaws.com/stage/insight-guru/api/";
// const ASSOCIATES_URL="https://xtrly1d5rf.execute-api.ap-southeast-1.amazonaws.com/stage/insight-guru/api/";
// const ANALYTICS="https://4bcqfsl0wf.execute-api.ap-southeast-1.amazonaws.com/stage/insight-guru/api/";
// const ITEMS="https://q8kesmekrj.execute-api.ap-southeast-1.amazonaws.com/stage/insight-guru/api/";

// deve URL
// export const BUCKET_NAME ="iguru-serverless-stage";
// export const DIR_NAME= "media";
// export const REGION= "ap-southeast-1";
// export const ACCESS_KEY= "AKIAXWYDRA6RNRBPP6US";
// export const PUBLIC_URL = 'https://development.insightguru.com/';
// export const SECRET_ACCESS_KEY= "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";
// export const REPORT_URL="https://reports-stage.insightguru.com/";
// const PLATFORM_URL="https://yvtso0ed2h.execute-api.ap-southeast-1.amazonaws.com/dev/insight-guru/api/";
// const ASSESSEES_URL=" https://zvbvntqfli.execute-api.ap-southeast-1.amazonaws.com/dev/insight-guru/api/";
// const ASSESSMENTS_URL=" https://l5da42mcqe.execute-api.ap-southeast-1.amazonaws.com/dev/insight-guru/api/";
// const ASSIGNMENTS_URL=" https://isdl4c2gae.execute-api.ap-southeast-1.amazonaws.com/dev/insight-guru/api/";
// const ASSOCIATES_URL="https://ksj5q6v4pf.execute-api.ap-southeast-1.amazonaws.com/dev/insight-guru/api/";
// const ANALYTICS=" https://d99wi49gf1.execute-api.ap-southeast-1.amazonaws.com/dev/insight-guru/api/";
// const ITEMS=" https://xxmn5fdhd9.execute-api.ap-southeast-1.amazonaws.com/dev/insight-guru/api/";

// deve URL .env
export const BUCKET_NAME =`${process.env.REACT_APP_BUCKET_NAME}`;
export const DIR_NAME=`${process.env.REACT_APP_DIR_NAME}` ;
export const REGION=`${process.env.REACT_APP_REGION}` ;
export const ACCESS_KEY=`${process.env.REACT_APP_ACCESS_KEY}` ;
export const PUBLIC_URL =`${process.env.REACT_APP_PUBLIC_URL}`;
export const SECRET_ACCESS_KEY=`${process.env.REACT_APP_SECRET_ACCESS_KEY}` ;
export const REPORT_URL=`${process.env.REACT_APP_REPORT_URL}` ;
const PLATFORM_URL=`${process.env.REACT_APP_PLATFORM_URL}`;
const ASSESSEES_URL=`${process.env.REACT_APP_ASSESSEES_URL}`;
const ASSESSMENT_GET_URL=`${process.env.REACT_APP_ASSESSMENT_GET_URL}`;
const ASSESSMENTS_URL=`${process.env.REACT_APP_ASSESSMENTS_URL}`;
const ASSIGNMENTS_URL=`${process.env.REACT_APP_ASSIGNMENTS_URL}`;
const ASSOCIATES_URL=`${process.env.REACT_APP_ASSOCIATES_URL}`;
const ANALYTICS=`${process.env.REACT_APP_ANALYTICS}`;
const ITEMS=`${process.env.REACT_APP_ITEMS}`;
const NODE_URL=`${process.env.REACT_APP_NODE}`;
const ASSESSMENT_CLOUD_FRONT_URL= `${process.env.REACT_APP_CLOUDFRONT_URL}`

//platform
export const SEND_AUTH_CODE_URL = PLATFORM_URL + 'platform/forgot-password';
export const FORGOT_CREDENTIAL_URL = PLATFORM_URL + 'platform/forget-credential';
export const FORGOT_PASSWORD_URL = PLATFORM_URL + 'platform/confirm-password';
export const GET_COUNTRY_STATE_CITY_LIST_URL =
  PLATFORM_URL + 'platform/contry-state-city/review-list';
export const ASSESSEE_SIGN_IN_URL = PLATFORM_URL + 'platform/cognito-sign-in';
export const CONFIRM_ASSESSEE_URL = PLATFORM_URL + 'platform/update-sign-in-info';
export const ASSESSEE_SIGN_IN_INFO_URL = PLATFORM_URL + 'platform/refresh-signin-information';
export const ASSESSEE_CHANGE_PASSWORD_URL = PLATFORM_URL + 'platform/change-password';
export const ASSESSEE_LINK_URL = PLATFORM_URL + 'platform/assessee-distinct/associate/review-list';
export const LINKED_ASSOCIATE_URL =
  PLATFORM_URL + 'platform/assessee-distinct/associate/revise-list';
//assessee self
export const ASSESSEE_ASSESSMENT_START_URL = ASSIGNMENTS_URL + 'assessee-distinct/assessment/start';
export const ASSESSMENT_START_OLD_URL = ASSIGNMENTS_URL + 'assignment/assessee/assessment';

export const ASSESSMENT_START_URL = ASSESSMENT_CLOUD_FRONT_URL+'assignment/assessee/assessment/';
export const ASSESSMENT_ITEM_REVISE_URL =
  ASSIGNMENTS_URL + 'assessee-distinct/assessment-item/revise';
export const ASSESSEE_ASSESSMENT_FINISH_URL =
  ASSIGNMENTS_URL + 'assessee-distinct/assessment/revise';
export const ASSESSEE_ASSESSMENT_NODE_FINISH_URL = NODE_URL+"evaluvation"
export const ASSESSMENT_SECTION_END_URL =
  ASSIGNMENTS_URL + 'assessee-distinct/assessment-section/end';
//merge api
export const ASSESSEE_MODULE_URL = ASSESSEES_URL + 'assessee-distinct';
export const ASSESSMENT_MODULE_URL = ASSESSMENTS_URL + 'assessment-distinct';
export const ASSIGNMENT_MODULE_URL = ASSIGNMENTS_URL + 'assignment-distinct';
export const ASSIGNMENT_MINE_ALLOCATE_MODULE_URL = ASSIGNMENTS_URL + 'assignment/publish/administer'; //changes pending
export const ASSOCIATE_MODULE_URL = ASSOCIATES_URL + 'associate-distinct';
export const ITEM_MODULE_URL = ITEMS + 'item-distinct';
export const CULTURE_MODULE_URL = ANALYTICS + 'culture-profile-distinct';
export const JOB_MODULE_URL = ANALYTICS + 'job-profile-distinct';
export const PLATFORM_MODULE_URL = PLATFORM_URL + 'iguru-iglobal-distinct';
//setup
// export const SIGNED_ASSESSEE_PERMISSION_URL = ASSESSEES_URL + 'assessee/role-permission-list';
// export const ASS0CIATE_SETUP_URL = ASSOCIATES_URL + 'associate-setup/review';
// export const ITEM_SETUP_URL = ITEMS + 'item-associate-setup/review';
// export const NODE_SETUP_URL = ASSOCIATES_URL + 'associate-node-permission/review';
// export const ASSOCIATENODE_SETUP_REVISE_URL = ASSOCIATES_URL + 'associate-node-permission/revise';
// export const ASSIGNMENT_SETUP_URL = ASSIGNMENTS_URL + 'assignment-associate-setup/review';
// export const ASSESSEES_SETUP_URL = ASSESSEES_URL + 'assessee-associate-setup/review';
// export const ASSESSMENTS_SETUP_URL = ASSESSMENTS_URL + 'assessment-associate-setup/review';
// export const ANALYTIC_SETUP_URL = ANALYTICS + 'iguru-analytic-associate-setup/review';
// export const ASSESSEE_SETUP_REVISE_URL = ASSESSEES_URL + 'assessee/associate-setup/revise';
// export const ASSESSMENT_SETUP_REVISE_URL = ASSESSMENTS_URL + 'assessment-distinct/associate-setup/revise';
// export const ASSOCIATE_SETUP_REVISE_URL = ASSOCIATES_URL + 'associate-distinct/setup/revise';
// export const ITEM_SETUP_REVISE_URL = ITEMS + 'item-distinct/associate-setup/revise';
// export const ANALYTIC_SETUP_REVISE_URL = ITEMS + 'iguru-analytic-associate-setup/revise';
// export const UPDATE_ITEM_MULTI_STATUS_URL = ITEMS + 'item/status/revise';
// export const SIGNED_ASSESSEE_NOTIFICATION_URL = ASSIGNMENTS_URL + 'assessee-distinct/assignment/count';
// export const ASSESSEE_CREATE_URL = ASSESSEES_URL + 'assessee-distinct/create';
// export const ASSOCIATE_CREATE_URL =ASSOCIATES_URL + 'associate-sign-on';
// export const ASSOCIATE_CREATE_URL = ASSOCIATES_URL + 'associate-create-step';
// export const ASSESSEE_REVIEW_LIST_URL = ASSESSEES_URL + 'assessee-distinct/review-list';
// export const ASSESSEE_REVIEW_INFO_URL = ASSESSEES_URL + 'assessee-distinct/review';
// export const ASSESSEE_GROUP_CLASSIFICATION_URL = ASSESSEES_URL + 'assessee-group-classfication/review-list';
// export const ASSOCIATE_REVIEW_INFO_URL = ASSOCIATES_URL + 'associate-distinct/review';
// export const ASSESSEE_ROLE_REVIEW_LIST_URL = ASSESSEES_URL + 'assessee-role-distinct/review-list';
// export const ASSOCIATE_REVIEWDISTINCT_LIST_URL = ASSOCIATES_URL + 'associate-distinct/review-list';
// export const ASSESSEE_REVIEW_ROLE_URL = ASSESSEES_URL + 'assessee-role/review';
// export const ASSOCIATE_REVIEW_ROLE_URL = ASSOCIATES_URL + 'associate-role/review';
// export const ASSESSEE_ROLE_CREATE_URL = ASSESSEES_URL + 'assessee-role/create';
// export const ASSOCIATE_ROLE_CREATE_URL = ASSOCIATES_URL + 'associate-role/create';
// export const ASSOCIATE_ROLE_REVIEW_LIST_URL = ASSOCIATES_URL + 'associate-role/review-list';
// export const ASSESSEE_REVIEW_GROUP_URL = ASSESSEES_URL + 'assessee-group/review';
// export const ASSOCIATE_REVIEW_GROUP_URL = ASSOCIATES_URL + 'associate-group/review';
// export const ASSESSEE_GROUP_CREATE_URL = ASSESSEES_URL + 'assessee-group/create';
// export const ASSESSMENT_GROUP_CREATE_URL = ASSESSMENTS_URL + 'assessment-group/create';//delete
// export const ASSOCIATE_GROUP_CREATE_URL = ASSOCIATES_URL + 'associate-group/create';
// export const ASSESSEE_GROUP_REVIEWLIST_URL = ASSESSEES_URL + 'assessee-group-distinct/review-list';
// export const ASSOCIATE_GROUP_REVIEWLIST_URL = ASSOCIATES_URL + 'associate-group/review-list';
// export const ASSESSMENT_GROUP_REVIEWLIST_URL = ASSESSMENTS_URL + 'assessment-group/review-list';//delete
// export const ASSIGNMENT_REVIEW_GROUP_URL = ASSIGNMENTS_URL + 'assignment-group/review';
// export const ASSIGNMENT_TYPE_CREATE_URL = ASSIGNMENTS_URL + 'assignment-type/create';
// export const ASSESSMENT_TYPE_REVIEWLIST_URL = ASSESSMENTS_URL + 'assessment-type/review-list';
// export const ASSIGNMENT_TYPE_REVIEWLIST_URL = ASSIGNMENTS_URL + 'assignment-type/review-list';

// export const GAUGE_CREATE_URL = ANALYTICS + 'occupation/gauge/create';

// export const ASSESSMENT_SCALE_CLUSTER_REVISE_URL = ASSESSMENTS_URL + 'assessment-distinct/cluster-scale/revise';//delete
// export const ASSIGNMENT_REVIEW_TYPE_URL = ASSIGNMENTS_URL + 'assignment-type/review';
// export const ASSIGNMENT_REVISE_TYPE_URL = ASSIGNMENTS_URL + 'assignment-type/revise';
// export const ASSESSMENT_REVIEW_TYPE_URL = ASSESSMENTS_URL + 'assessment-type/review';
// export const ASSESSMENT_REVISE_TYPE_URL = ASSESSMENTS_URL + 'assessment-type/revise';
// export const ASSESSEE_REVIEW_TYPE_URL = ASSESSEES_URL + 'assessee-type/review';
// export const ASSESSEE_REVISE_TYPE_URL = ASSESSEES_URL + 'assessee-type/revise';
// export const ASSOCIATE_REVIEW_TYPE_URL = ASSOCIATES_URL + 'associate-type/review';
// export const ASSOCIATE_REVISE_TYPE_URL = ASSOCIATES_URL + 'associate-type/revise';

// export const ASSESSEE_INFO_REVISE_URL = ASSESSEES_URL + 'assessee-distinct/revise';
// export const ASSESSEE_GROUP_INFO_REVISE_URL = ASSESSEES_URL + 'assessee-group/revise';
// export const ASSESSEE_ROLE_INFO_REVISE_URL = ASSESSEES_URL + 'assessee-role/revise';
// export const ASSOCIATE_INFO_REVISE_URL = ASSOCIATES_URL + 'associate-distinct/revise';
// export const ASSESSEE_GROUP_ASSESSEE_URL = ASSESSEES_URL + 'assessee-group/assessee/review-list';
// export const ASSOCIATE_GROUP_ASSOCIATE_URL = ASSOCIATES_URL + 'associate-group/associate/review-list';
// export const ASSOCIATE_GROUP_INFO_REVISE_URL = ASSOCIATES_URL + 'associate-group/revise';
// export const ASSOCIATE_ROLE_INFO_REVISE_URL = ASSOCIATES_URL + 'associate-role/revise';
// export const ASSESSEE_ROLE_ASSESSEE_URL = ASSESSEES_URL + 'assessee-role/assessee/review-list';
// export const ASSESSEE_TYPE_ASSESSEE_URL = ASSESSEES_URL + 'assessee-type/assessee/review-list';
// export const ASSOCIATE_ROLE_ASSOCIATE_URL = ASSOCIATES_URL + 'associate-role/associate/review-list';
// export const ASSOCIATE_TYPE_ASSOCIATE_URL = ASSOCIATES_URL + 'associate-type/associate/review-list';
// export const NODE_CREATE_URL = ASSOCIATES_URL + 'associate-node/create';
//ITEMS

// export const ITEM_CREATE_URL = ITEMS + 'item-distinct/create';
// export const ITEM_REVIEWLIST_URL = ITEMS + 'item-distinct/review-list';
// export const ITEM_REVIEW_URL = ITEMS + 'item-distinct/review';
// export const ITEM_REVISE_URL = ITEMS + 'item-distinct/revise';
// export const ITEM_GROUP_CREATE_URL = ITEMS + 'item-group/create';
// export const ITEM_GROUP_REVIEWLIST_URL = ITEMS + 'item-group/review-list';
// export const ITEM_REVIEW_GROUP_URL = ITEMS + 'item-group/review';
// export const ITEM_REVISE_GROUP_URL = ITEMS + 'item-group/revise';
// export const ITEM_REVIEW_TYPE_URL = ITEMS + 'item-type/review';
// export const ITEM_REVISE_TYPE_URL = ITEMS + 'item-type/revise';
// export const ITEMGROUPITEM_REVIEWLIST_URL = ITEMS + 'item-group/item/review-list';
// export const ITEMTYPEPITEM_REVIEWLIST_URL = ITEMS + 'item-type/item/review-list';
// export const ITEMNODEITEM_REVIEWLIST_URL = ITEMS + 'associate-node/item/review-list';
// export const ITEM_TYPE_CREATE_URL = ITEMS + 'item-type/create';
// export const ITEM_TYPE_REVIEWLIST_URL = ITEMS + 'item-type/review-list';
//ITEMS
// export const EXTERNAL_NODE_TREE_URL = ASSOCIATES_URL + 'iguru-node/review-list-hierarchy';
// export const EXTERNAL_NODE_LIST_URL = ASSOCIATES_URL + 'iguru-node/review-list';
// export const INTERNAL_NODE_LIST_URL = ASSOCIATES_URL + 'associate-node/review-list';
// export const INTERNAL_NODE_TREE_URL = ASSOCIATES_URL + 'associate-node/review-list-hierarchy';
// export const ASSESSEE_ROLE_GROUP_URL = ASSESSEES_URL + 'assessee-role-group/review-list';
// export const ASSOCIATE_ROLE_GROUP_URL = ASSOCIATES_URL + 'associate-role-group/review-list';
// export const ASSOCIATE_NODE_REVIEW_URL = ASSOCIATES_URL + 'associate-node/review';
// export const ASSOCIATE_NODE_REVISE_URL = ASSOCIATES_URL + 'associate-node/revise';
// export const ASSESSEE_ROLE_SHARE_URL =ASSOCIATES_URL + 'assessee-role/share';
// export const ASSESSEE_ROLE_SHARE_URL = ASSESSEES_URL + 'assessee/share/role';
// export const ASSESSEE_ROLE_UNSHARE_URL =// ASSOCIATES_URL + 'assessee-role/unshare';
// export const ASSESSEE_ROLE_UNSHARE_URL = ASSESSEES_URL + 'assessee/unshare/role';
// export const ASSOCIATE_ROLE_SHARE_URL = ASSOCIATES_URL + 'associate-role/share';
// export const ASSOCIATE_ROLE_UNSHARE_URL = ASSOCIATES_URL + 'associate-role/unshare';
// export const ASSESSEE_NODE_ASSESSEE_URL = ASSESSEES_URL + 'associate-node/assessee/review-list';
// export const ASSESSEE_TYPE_CREATE_URL = ASSESSEES_URL + 'assessee-type/create';
// export const ASSOCIATE_TYPE_CREATE_URL = ASSOCIATES_URL + 'associate-type/create';
// export const ASSESSEE_TYPE_REVIEWLIST_URL = ASSESSEES_URL + 'assessee-type/review-list';
// export const ASSOCIATE_TYPE_REVIEWLIST_URL = ASSOCIATES_URL + 'associate-type/review-list';
// export const ASSESSEE_TYPE_GROUP_URL = ASSESSEES_URL + 'assessee-type-group/review-list';
// export const ASSOCIATE_TYPE_GROUP_URL = ASSOCIATES_URL + 'associate-type-group/review-list';
// export const ASSOCIATE_TYPE_SHARE_URL = ASSOCIATES_URL + 'associate-type/share';
// export const ASSOCIATE_TYPE_UNSHARE_URL = ASSOCIATES_URL + 'associate-type/unshare';
// export const ASSESSEE_TYPE_SHARE_URL =ASSOCIATES_URL + 'assessee-type/share';
// export const ASSESSEE_TYPE_SHARE_URL = ASSESSEES_URL + 'assessee/share/type';
// export const ASSESSEE_TYPE_UNSHARE_URL =// ASSOCIATES_URL + 'assessee-type/unshare';
// export const ASSESSEE_TYPE_UNSHARE_URL = ASSESSEES_URL + 'assessee/unshare/type';
// export const ITEM_ALLOCATE = ITEMS + 'item-distinct/allocate';
// export const ITEM_ALLOCATE_ASSESSMENT = ITEMS + 'item/allocate/assessment';
// export const ASSESSEE_ALLOCATE = ASSESSEES_URL + 'assessee-distinct/allocate';
// export const ASSESSEE_ALLOCATE_ASSIGNMENT = ASSESSEES_URL + 'assessee/allocate/assignment';
// export const ASSESSMENT_ALLOCATE = ASSESSMENTS_URL + 'assessment-distinct/allocate';//delete
// export const ASSESSMENT_ALLOCATE_ASSIGNMENT = ASSESSMENTS_URL + 'assessment/allocate/assignment';delete
// export const ASSOCIATE_NODE_ASSOCIATE_URL = ASSOCIATES_URL + 'associate-node/associate/review-list';
// export const ASSESSMENTGROUP_ASSESSMENT_REVIEWLIST_URL = ASSESSMENTS_URL + 'assessment-distinct';
// export const ASSESSMENTTYPE_ASSESSMENT_REVIEWLIST_URL = ASSESSMENTS_URL + 'assessment-distinct';
// export const ASSESSMENTNODE_ASSESSMENT_REVIEWLIST_URL = ASSESSMENTS_URL + 'associate-node/assessment/review-list';//delete
// export const ITEM_TYPE_SHARE_URL = ITEMS + 'item-types/share';
// export const ITEM_TYPE_UNSHARE_URL = ITEMS + 'item-types/unshare';
// export const ASSESSMENT_TYPE_SHARE_URL = ASSESSMENTS_URL + 'assessment-type/share';//delete
// export const ASSESSMENT_TYPE_UNSHARE_URL = ASSESSMENTS_URL + 'assessment-type/unshare';//detele
// export const ITEM_TYPE_GROUP_URL = ITEMS + 'item-type-group/review-list';
// export const ASSESSMENT_TYPE_GROUP_URL = ASSESSMENTS_URL + 'assessment-type-group/review-list';
// export const ASSIGNMENT_TYPE_GROUP_URL = ASSIGNMENTS_URL + 'assignment-type-group/review-list';
// export const CULTURE_CREATE_URL = ANALYTICS + 'culture-profile/create';
// export const CULTURE_PROFILE_REVIEW_INFO_URL = ANALYTICS + 'culture-profile/review';
// export const CULTURE_PROFILE_REVISE_INFO_URL = ANALYTICS + 'culture-profile/revise';
// export const JOB_PROFILE_REVIEW_INFO_URL = ANALYTICS + 'job-profile/review';
// export const JOB_PROFILE_REVISE_INFO_URL = ANALYTICS + 'job-profile/revise';
// export const CULTURE_REVIEWLIST_URL = ANALYTICS + 'culture-profile/review-list';
// export const CULTURE_GROUP_CREATE_URL = ANALYTICS + 'culture-profile-group/create';
// export const CULTURE_REVIEW_GROUP_URL = ANALYTICS + 'culture-profile-group/review';
// export const CULTURE_REVISE_GROUP_URL = ANALYTICS + 'culture-profile-group/revise';
// export const JOB_REVIEW_GROUP_URL = ANALYTICS + 'job-profile-group/review';
// export const JOB_REVISE_GROUP_URL = ANALYTICS + 'job-profile-group/revise';
// export const CULTURE_REVIEW_TYPE_URL = ANALYTICS + 'culture-profile-type/review';
// export const CULTURE_REVISE_TYPE_URL = ANALYTICS + 'culture-profile-type/revise';
// export const JOB_REVIEW_TYPE_URL = ANALYTICS + 'job-profile-type/review';
// export const JOB_REVISE_TYPE_URL = ANALYTICS + 'job-profile-type/revise';
// export const CULTURE_TYPE_CREATE_URL = ANALYTICS + 'culture-profile-type/create';
// export const CULTURE_GROUP_REVIEWLIST_URL = ANALYTICS + 'culture-profile-group/review-list';
// export const CULTURE_GROUP_CULTURE_REVIEWLIST_URL = ANALYTICS + 'culture-profile-group/culture-profile/review-list';
// export const CULTURE_TYPE_CULTURE_REVIEWLIST_URL = ANALYTICS + 'culture-profile-type/culture-profile/review-list';
// export const CULTURE_NODE_CULTURE_REVIEWLIST_URL = ANALYTICS + 'associate-node/culture-profile/review-list';
// export const CULTURE_TYPE_REVIEWLIST_URL = ANALYTICS + 'culture-profile-type/review-list';
// export const CULTURE_TYPE_GROUP_URL = ANALYTICS + 'culture-profile-type-group/review-list';
// export const CULTURE_TYPE_SHARE_URL = ANALYTICS + 'culture-profile-type/share';
// export const CULTURE_TYPE_UNSHARE_URL = ANALYTICS + 'culture-profile-type/unshare';
// export const CULTURE_DIAMENTION_URL = ANALYTICS + 'profiler/create';
// export const CULTURE_ASSESSMENT_REVIEWLIST_URL = ANALYTICS + 'culture-profile/assessment/review-list';
// export const CULTURE_PROFILE_PUBLISH_URL = ANALYTICS + 'culture-profile/publish';
//job
// export const JOB_REVIEWLIST_URL = ANALYTICS + 'job-profile/review-list';
// export const JOB_CREATE_URL = ANALYTICS + 'job-profile/create';
// export const JOB_GROUP_CREATE_URL = ANALYTICS + 'job-profile-group/create';
// export const JOB_PROFILE_PUBLISH_URL = ANALYTICS + 'job-profile/publish';
// export const JOB_GROUP_REVIEWLIST_URL = ANALYTICS + 'job-profile-group/review-list';
// export const JOB_TYPE_REVIEWLIST_URL = ANALYTICS + 'job-profile-type/review-list';
// export const JOB_TYPE_CREATE_URL = ANALYTICS + 'job-profile-type/create';
// export const JOB_TYPE_JOB_REVIEWLIST_URL = ANALYTICS + 'job-profile-type/job-profile/review-list';
// export const JOB_GROUP_JOB_REVIEWLIST_URL = ANALYTICS + 'job-profile-group/job-profie/review-list';
// export const JOB_TYPE_GROUP_URL = ANALYTICS + 'job-profile-type-group/review-list';
// export const JOB_NODE_JOB_REVIEWLIST_URL = ANALYTICS + 'associate-node/job-profile/review-list';
// export const JOB_TYPE_SHARE_URL = ANALYTICS + 'job-profile-type/share';
// export const JOB_TYPE_UNSHARE_URL = ANALYTICS + 'job-profile-type/unshare';
// export const JOBDOMAIN_REVIEWLIST_URL = ANALYTICS + 'job-domain/review-list';
// export const JOBPROFILER_LIST_URL =ANALYTICS + 'job-profiler/review-list';
// export const JOBPROFILER_LIST_URL =//   ANALYTICS + 'job-profiler/competency/review-list';
// export const JOBPROFILER_LIST_URL = ANALYTICS + 'profiler/create';
// export const JOBFUNCTION_REVIEWLIST_URL = ANALYTICS + 'job-function/review-list';
// export const JOBROLE_REVIEWLIST_URL = ANALYTICS + 'job-role/review-list';
// export const JOB_ASSESSMENT_REVIEWLIST_URL = ANALYTICS + 'job-profile/assessment/review-list';
// export const ASSOCIATE_ALLOCATE_URL = ASSOCIATES_URL + 'associate-distinct/allocate';
// export const JOBPROFILE_ALLOCATE_URL = ANALYTICS + 'job-profile-distinct/allocate';
// export const CULTURE_PROFILE_ALLOCATE_URL = ANALYTICS + 'culture-profile-distinct/allocate';
// export const CULTURE_PROFILE_ALLOCATE_ASSIGNMENT_URL = ANALYTICS + 'culture-profile/allocate/assignment';
// export const JOB_PROFILE_ALLOCATE_ASSIGNMENT_URL = ANALYTICS + 'job-profile/allocate/assignment';
//item_config - Item_TYPE
// export const CONFIG_ITEM_URL = ITEMS + 'item-framework-one-type/review-list';
//assessment preivew -revise
// export const ASSESSMENT_INFO_PREVIEW_REVISE_URL = ASSESSMENTS_URL + 'assessment-distinct/section/item/revise';//delete
// export const UPDATE_ASSESSMENT_MULTI_STATUS_URL = ASSESSMENTS_URL + 'assessment/status/revise';//delete
// export const UPDATE_ASSESSEES_MULTI_STATUS_URL = ASSESSEES_URL + 'assessee/status/revise';
// export const UPDATE_ASSOCIATE_MULTI_STATUS_URL = ASSOCIATES_URL + 'associate/status/revise';
// export const UPDATE_CULTURE_MULTI_STATUS_URL = ANALYTICS + 'culture-profile/status/revise';
// export const UPDATE_JOB_MULTI_STATUS_URL = ANALYTICS + 'job-profile/status/revise';
// export const ASSOCIATE_ASSESSEE_SETUP_RESET_URL = ASSESSEES_URL + 'assessee/assessee-associate-setup/reset';
// export const ASSOCIATE_ASSESSMENT_SETUP_RESET_URL = '';
// export const ASSOCIATE_ASSIGNMENT_SETUP_RESET_URL = '';
// export const ASSOCIATE_ASSOCIATE_SETUP_RESET_URL = '';
// export const ASSOCIATE_ITEM_SETUP_RESET_URL = '';
// export const ASSOCIATE_ANALYTIC_SETUP_RESET_URL = '';
// export const ASSOCIATE_EXCHANGE_UPLOAD_URL = ASSESSMENTS_URL + 'assessment-distinct/exchange';//delete

//group share/unshare api
// export const ASSESSEE_GROUP_SHARE_URL = ASSESSEES_URL + 'assessee/share/group';
// export const ASSESSEE_GROUP_UNSHARE_URL = ASSESSEES_URL + 'assessee/unshare/group';
// export const ASSESSMENT_GROUP_SHARE_URL = ASSESSMENTS_URL + 'assessment-group/share';delete
// export const ASSESSMENT_GROUP_UNSHARE_URL = ASSESSMENTS_URL + 'assessment-group/unshare';delete
// export const ASSOCIATE_GROUP_SHARE_URL = ASSOCIATES_URL + 'associate-group/share';
// export const ASSOCIATE_GROUP_UNSHARE_URL = ASSOCIATES_URL + 'associate-group/unshare';
// export const ITEM_GROUP_SHARE_URL = ITEMS + 'item-group/share';
// export const ITEM_GROUP_UNSHARE_URL = ITEMS + 'item-group/unshare';
// export const CULTURE_GROUP_SHARE_URL = ANALYTICS + 'culture-profile-group/share';
// export const CULTURE_GROUP_UNSHARE_URL = ANALYTICS + 'culture-profile-group/unshare';
// export const JOB_GROUP_SHARE_URL = ANALYTICS + 'job-profile-group/share';
// export const JOB_GROUP_UNSHARE_URL = ANALYTICS + 'job-profile-group/unshare';
// export const ASSESSEE_DISTINCT_SAHRE_URL = ASSESSEES_URL + 'assessee-distinct/share';
// export const ASSESSEE_DISTINCT_UNSAHRE_URL = ASSESSEES_URL + 'assessee-distinct/unshare';
// export const ASSESSMENT_DISTINCT_SAHRE_URL = ASSESSMENTS_URL + 'assessment-distinct/share';//delete
// export const ASSESSMENT_DISTINCT_UNSAHRE_URL = ASSESSMENTS_URL + 'assessment-distinct/unshare';
// export const ASSOCIATE_DISTINCT_SAHRE_URL = ASSOCIATES_URL + 'associate-node/share';
// export const ASSOCIATE_DISTINCT_UNSAHRE_URL = ASSOCIATES_URL + 'associate-node/unshare';
// export const CULTURE_DISTINCT_SAHRE_URL = ANALYTICS + 'culture-profile-distinct/share';
// export const CULTURE_DISTINCT_UNSAHRE_URL = ANALYTICS + 'culture-profile-distinct/unshare';
// export const JOB_DISTINCT_SAHRE_URL = ANALYTICS + 'job-profile-distinct/share';
// export const JOB_DISTINCT_UNSAHRE_URL = ANALYTICS + 'job-profile-distinct/unshare';
// export const ITEM_DISTINCT_SAHRE_URL = ITEMS + 'item-distinct/share';
// export const ITEM_DISTINCT_UNSAHRE_URL = ITEMS + 'item-distinct/unshare';
// export const ASSESSMENT_GUAGE_REVISE_URL = ASSIGNMENTS_URL + '';

export const STAGE_BRAND_LOGO_ONE = "https://iguru-serverless-stage.s3-ap-southeast-1.amazonaws.com/media/iGuruPlatformBrandOne.png"
export const STAGE_BRAND_LOGO_TWO = "https://iguru-serverless-stage.s3-ap-southeast-1.amazonaws.com/media/iGuruPlatformBrandTwo.png"

export const STAGE_LITE_URL = "https://platform-lite.insightguru.com"
// export const STAGE_LITE_URL = "http://localhost:3001"
