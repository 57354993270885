import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ASSESSMENT_ALLOCATE_SAGA,
  ASSOCIATE_POPUP_CLOSE,
  FILTERMODE,
  FILTERMODE_ENABLE,
  GET_ASSESSMENT_TYPE_REVIEW_LIST_SAGA,
  GET_ASSIGNMENT_TYPE_REVIEW_LIST_SAGA,
  GET_ASSOCIATE_GROUP_REVIEW_LIST_SAGA,
  LOADER_START,
  POPUP_OPEN,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_PAGE_COUNT,
  SET_POPUP_STATE,
  SET_REQUEST_OBJECT
} from '../actionType';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import { FilterList } from '@material-ui/icons';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import { getPopUpOptionList, makeAssessmentTypeObj } from '../Actions/GenericActions';
import {
  ASSESSMENT_NODE_TYPE_REVIEW_LIST_POPUP_OPTION,
  NODE_ROLE_TYPE_REVIEW_LIST_POPUP_OPTION
} from '../PopUpConfig';
import ReviseIcon from '@material-ui/icons/RadioButtonChecked';
import Check from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import {
  assesseeRole,
  onClickCheckBoxSelection,
  onClickFlagSelection,
  setFlagedArray
} from '../Actions/AssesseeModuleAction';
import ListLoader from '../Atoms/ListLoader/ListLoader';
const MarketplaceAssessmentsTypeDistinctReviewList = (props) => {
  const dispatch = useDispatch();
  // const { secondaryOptionCheckValue,  } = useSelector(
  //   (state) => state.AssesseeCreateReducer
  // );
  const {
    numberPage,
    countPage,
    scanCount,
    reviewListDistinctData,
    reviewListReqObj,
    middlePaneSelectedValue,
    selectedAssociateInfo,
    middlePaneHeaderBadgeOne,
    middlePaneHeaderBadgeTwo,
    middlePaneHeaderBadgeThree,
    isSelectActive,
    selectedTagsArray,
    unselectedTagsArray,
    allocatedTagsArray,
    allocateStr,
    selectedFlagedArray,
    unselectedFlagedArray,
    flagedValue,
    signedAssesseePermission,
    isListLoading
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { FilterModeEnable, FilterMode } = useSelector((state) => state.FilterReducer);
  const { cardValue } = useSelector((state) => state.PopUpReducer);
  // const [isFetching, setIsFetching] = useState(false);
  // useEffect(() => {
  //   document.getElementById('middleComponentId').addEventListener('scroll', handleScroll);
  // }, []);
  // const handleScroll = (event) => {
  //   var targetPt = event.target;
  //   if (
  //     Math.ceil(targetPt.scrollHeight - targetPt.scrollTop) !== targetPt.clientHeight ||
  //     isFetching
  //   )
  //     return;
  //   setIsFetching(true);
  // };
  const fetchData = async () => {
    if (reviewListDistinctData.length < scanCount) {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isListLoading', value: true }
      })
      let obj = {
        ...reviewListReqObj.requestObject,
        numberPage: numberPage + 1
      };
      dispatch({
        type: GET_ASSESSMENT_TYPE_REVIEW_LIST_SAGA,
        payload: {
          request: { module: 'Assessment-Type', action: 'Review-List', actionFilter: 'Shared-Assessment' , requestObject: obj },
          BadgeOne: middlePaneHeaderBadgeOne,
          BadgeTwo: middlePaneHeaderBadgeTwo,
          BadgeThree: middlePaneHeaderBadgeThree,
          isMiddlePaneList: true,
          isSelectActive,
          selectedTagsArray
        }
      });
      dispatch({ type: SET_PAGE_COUNT, payload: numberPage + 1 });
    }
  };
  // useEffect(() => {
  //   console.log(reviewListDistinctData);
  //   if (!isFetching) return;
  //   fetchMoreListItems();
  // }, [isFetching]);

  // const fetchMoreListItems = () => {
  //   fetchData();
  //   setIsFetching(false);
  // };
  useEffect(() => {
    setFlagedArray(reviewListDistinctData, 'assessmentTypeFlag', dispatch);
  }, [reviewListDistinctData]);

  const siftApiCall = (siftKey) => {
    console.log('siftKey', siftKey);
    let requestObect = makeAssessmentTypeObj(selectedAssociateInfo, siftKey, countPage, 0, 'Shared-Assessment');
    dispatch({ type: LOADER_START });
    dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
    dispatch({
      type: FILTERMODE,
      payload: { FilterMode: 'assessmentsTypeDistinctactive' + siftKey }
    });
    dispatch({
      type: GET_ASSESSMENT_TYPE_REVIEW_LIST_SAGA,
      payload: {
        request: requestObect,
        BadgeOne: middlePaneHeaderBadgeOne,
        BadgeTwo: middlePaneHeaderBadgeTwo === 'distinct' ? middlePaneHeaderBadgeTwo : siftKey,
        BadgeThree: middlePaneHeaderBadgeTwo === 'distinct' ? siftKey : middlePaneHeaderBadgeThree,
        isMiddlePaneList: true,
        isSelectActive: isSelectActive,
        selectedTagsArray: selectedTagsArray
      }
    });
    dispatch({ type: SET_PAGE_COUNT, payload: 0 });
    dispatch({ type: ASSOCIATE_POPUP_CLOSE });
    // document.getElementById('middleComponentId').scrollTop = '0px';
  };
  const onClickFooter = (e) => {
    let siftValue = e.currentTarget.getAttribute('data-value');
    dispatch({ type: FILTERMODE_ENABLE });
    if (siftValue === 'suspended' || siftValue === 'terminated') siftApiCall(siftValue);
    if (siftValue === 'bespoke' || siftValue === 'generic') siftApiCall(siftValue);

    if (siftValue === 'finish') {
      console.log('allocateStr', allocateStr);
      let distinctAllocateStr =
        allocateStr === 'assessmentsdistinct'
          ? 'assessmentDistinct'
          : allocateStr === 'assessmentsgroups'
            ? 'assessmentGroup'
            : allocateStr === 'assessmentstypes'
              ? 'assessmentType'
              : allocateStr === 'assessmentsnodes'
                ? 'assessmentNode'
                : '';
      if (distinctAllocateStr !== '' && selectedTagsArray.length !== 0) {
        if (
          distinctAllocateStr === 'assessmentGroup' ||
          distinctAllocateStr === 'assessmentType' ||
          distinctAllocateStr === 'assessmentDistinct' ||
          distinctAllocateStr === 'assessmentNode'
        ) {
          let request = {
            module: 'Assessment-Distinct',
            action: FilterMode.toLowerCase().includes('unallocate') ? 'Unallocate' : 'Allocate',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              [FilterMode.toLowerCase().includes('unallocate') ? 'assessmentDistinctUnallocate' : 'assessmentDistinctAllocate']: {
                [distinctAllocateStr]: allocatedTagsArray
                //assessmentGroup: allocatedTagsArray
              },
              [FilterMode.toLowerCase().includes('unallocate') ? 'assessmentDistinctUnallocateInformation' : 'assessmentDistinctAllocateInformation']: {
                assessmentType: selectedTagsArray
              }
            }
          };
          dispatch({ type: LOADER_START });
          dispatch({ type: ASSESSMENT_ALLOCATE_SAGA, payload: { request: request } });
        }
      }
    }
    if (siftValue === 'cancel') {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isSelectActive', value: '' }
      });
      dispatch({
        type: FILTERMODE,
        payload: { FilterMode: '' }
      });
    }
  };
  /* for middle pane */
  const primaryIcon = [{ label: 'sift', onClick: onClickFooter, Icon: FilterList }];
  const secondaryIcon = [
    { label: 'suspended', onClick: onClickFooter, Icon: FilterList },
    { label: 'terminated', onClick: onClickFooter, Icon: FilterList }
  ];
  const secondaryOneIcon = [
    { label: 'bespoke', onClick: onClickFooter, Icon: FilterList },
    { label: 'generic', onClick: onClickFooter, Icon: FilterList }
    // { label: 'shared', onClick: onClickFooter, Icon: FilterList },
    // { label: 'unshared', onClick: onClickFooter, Icon: FilterList }
  ];

  let signedAssociateId =
    selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary;

  const openListPopup = (e) => {
    console.log(e.currentTarget.getAttribute('tag'));
    let tempArr = [];
    let classification = e.currentTarget.getAttribute('data-classification');
    let isShared = e.currentTarget.getAttribute('data-shared');
    let associateId = e.currentTarget.getAttribute('associateid');
    let status = e.currentTarget.getAttribute('status');
    let assessmentDistinctPermission =
      signedAssesseePermission?.assessmentDistictPermission?.assesseeAssessmentTypePermission;
    let popupArr =
      cardValue === 'Card'
        ? NODE_ROLE_TYPE_REVIEW_LIST_POPUP_OPTION
        : ASSESSMENT_NODE_TYPE_REVIEW_LIST_POPUP_OPTION;
    let popupContentArrValue = getPopUpOptionList(popupArr, assessmentDistinctPermission);
    //ASSESSMENT_NODE_TYPE_REVIEW_LIST_POPUP_OPTION.map((element) => {
    popupContentArrValue.map((element) => {
      if (classification === 'Bespoke' && element.data === 'share' && status === 'ACTIVE')
        tempArr.push({ ...element, disabled: true });
      else if (
        isShared === 'true' &&
        associateId !== signedAssociateId &&
        (element.data === 'archive' ||
          element.data === 'flag' ||
          element.data === 'delete' ||
          element.data === 'suspend' ||
          element.data === 'move' ||
          element.data === 'terminate')
      ) {
        tempArr.push({ ...element, disabled: true });
      } else if (
        (status === 'TERMINATED' || status === 'SUSPENDED' || status === 'ARCHIVED') &&
        (element.data === 'allocate' ||
          element.data === 'revise' ||
          element.data === 'select' ||
          element.data === 'share')
      ) {
        tempArr.push({ ...element, disabled: true });
      } else tempArr.push(element);
    });
    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: 'assessments',
        popupHeaderOneBadgeOne: 'type',
        popupHeaderOneBadgeTwo: '',
        isPopUpValue: '',
        popupOpenType: 'primary',
        popupContentArrValue: tempArr, //ASSESSMENT_NODE_TYPE_REVIEW_LIST_POPUP_OPTION,
        originPrimaryArrOprion: tempArr,
        selectedTagValue: e.currentTarget.getAttribute('tag'),
        selectedTagStatus: e.currentTarget.getAttribute('status'),
        selectedTagGroupId: e.currentTarget.getAttribute('data-value'),
        selectedTagShared: e.currentTarget.getAttribute('data-shared')
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: tempArr
        // value: cardValue === 'Card' ? NODE_ROLE_TYPE_REVIEW_LIST_POPUP_OPTION : tempArr //ASSESSMENT_NODE_TYPE_REVIEW_LIST_POPUP_OPTION
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
  };

  // infinite scroll
  const [hasMore, setHasMore] = useState(true);
  let elementRef = useRef(null);

  const onIntersection = (entries) => {
    const firstEntry = entries[0];
    reviewListDistinctData.length >= scanCount ? setHasMore(false) : setHasMore(true)
    if (firstEntry.isIntersecting && hasMore && !isListLoading) {
      fetchData();
    }
  };

  useEffect(() => {
    // console.log('####MYREF######', elementRef)
    const observer = new IntersectionObserver(onIntersection, { threshold: 0.90 });

    if (observer && elementRef.current) {
      // console.log('here at observer...', elementRef.current)
      observer.observe(elementRef.current);
    }

    return () => {
      if (reviewListDistinctData.length >= scanCount) {
        setHasMore(false);
      }
      if (observer) observer.disconnect();
    };
  }, [isListLoading, hasMore, reviewListDistinctData, selectedTagsArray, unselectedTagsArray, isSelectActive]);

  // infinite scroll end
  // console.log(scanCount, reviewListDistinctData.length ,'################33')
  return (
    <div>
      {reviewListDistinctData &&
        reviewListDistinctData.map((item, index) => {
          return (
            <div
              className="containerPadding"
              key={index}
              ref={elementRef}
            >
              <ReviewList
                className=""
                id={index}
                tag={item.id}
                associateId={item?.associateId}
                isSelectedReviewList={middlePaneSelectedValue === item.id}
                // status={item.informationEngagement.assessmentTypeStatus}
                // textOne={item.informationBasic.assessmentTypeName}
                textOne={assesseeRole(item.informationBasic.assessmentTypeName)}
                textTwo={item.informationBasic.assessmentTypeDescription}
                //status={associateSeftId === item.associateId ? 'bespoke' : 'generic'}
                status={
                  FilterMode === 'assessmentsTypeDistinctactive' ||
                    FilterMode === 'assessmentsTypeDistinctactivebespoke' ||
                    FilterMode === 'assessmentsTypeDistinctactivegeneric'
                    ? item.informationSetup?.assessmentTypeClassification
                      ?.assessmentTypeClassificationPrimary
                    : item.informationEngagement.assessmentTypeStatus
                }
                actualStatus={item.informationEngagement.assessmentTypeStatus}
                //shared={item.assessmentTypeShared ? 'SHARED' : 'UNSHARED'}
                dataValue={item.informationAllocation?.assessmentTypeGroup}
                isTooltipActive={false}
                // onClickEvent={openListPopup}
                isSelectActive={isSelectActive}
                isSelected={selectedTagsArray.includes(item.id)}
                onClickCheckBox={(event) => {
                  !isListLoading && onClickCheckBoxSelection(selectedTagsArray, unselectedTagsArray, event, dispatch);
                }}
                // disableonClickCheckBox={item.associateId !== signedAssociateId ? true : false}
                isShared={item?.assessmentTypeShared}
                shared={
                  item.informationSetup?.assessmentTypeClassification
                    ?.assessmentTypeClassificationPrimary
                }
                isFlagActive={selectedFlagedArray.includes(item.id)}
                flagedValue={flagedValue}
                onClickAddFladed={(event) => {
                  onClickFlagSelection(selectedFlagedArray, unselectedFlagedArray, event, dispatch);
                }}
              />
            </div>
          );
        })}
      {isListLoading ? <div> <ListLoader /> </div> : <div style={{ height: "60px", width: "100%" }}></div>}
      {/* {(FilterMode === 'assessmentsTypeDistinctinactive' ||
        FilterMode === 'assessmentsTypeDistinctactiveterminated' ||
        FilterMode === 'assessmentsTypeDistinctactivesuspended') && (
          <FooterIconTwo
            FilterModeEnable={FilterModeEnable}
            FilterMode={FilterMode}
            onClick={onClickFooter}
            primaryIcon={primaryIcon}
            secondaryIcon={secondaryIcon}
          />
        )}
      {(FilterMode === 'assessmentsTypeDistinctactive' ||
        FilterMode === 'assessmentsTypeDistinctactivebespoke' ||
        FilterMode === 'assessmentsTypeDistinctactivegeneric') && (
          <FooterIconTwo
            FilterModeEnable={FilterModeEnable}
            FilterMode={FilterMode}
            onClick={onClickFooter}
            primaryIcon={primaryIcon}
            secondaryIcon={secondaryOneIcon}
          />
        )}
      {(FilterMode === 'assessmentAllocateToType' || FilterMode === 'assessmentUnAllocateToType') && (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={[{ label: `${FilterMode === 'assessmentAllocateToType' ? 'allocate' : 'unallocate'}`, onClick: onClickFooter, Icon: ReviseIcon }]}
          secondaryIcon={[
            { label: 'cancel', onClick: onClickFooter, Icon: ClearIcon },
            { label: 'finish', onClick: onClickFooter, Icon: Check }
          ]}
        />
      )} */}
    </div>
  );
};
export default MarketplaceAssessmentsTypeDistinctReviewList;
