import React from 'react';
import { useSelector } from 'react-redux';
import PopUpPicture from '../../PopUpInformation/PopUpPicture';
import PopUpTextField from '../../PopUpInformation/PopUpTextField';
import {
  SET_JOB_CULTURE_PROFILER_CLUSTER_REDUCER_STATE,
  SET_JOB_CULTURE_PROFILER_INFO_REDUCER_STATE,
  SET_JOB_CULTURE_PROFILER_REDUCER_STATE
} from '../../actionType';
import PopUpDropList from '../../PopUpInformation/PopUpDropList';

const PopUpCultureJobProfilerCreate = (props) => {
  const { isPopUpValue } = useSelector((state) => state.PopUpReducer);
  const { culturejobProfilerInformation, culturejobProfiler, clusterProfilerInformation } =
    useSelector((state) => state.CulturejobProfilerCreateReducer);
  const { reviewMode, responseObject, headerOne } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  console.log('clusterProfilerInformation', clusterProfilerInformation);
  return (
    <>
      <div>
        {isPopUpValue === 'NAMEPOPUP' && (
          <PopUpTextField
            isActive={true}
            label={'name'}
            actualLableValue={'textOne'}
            headerPanelColour={'genericOne'}
            headerOne={headerOne}
            headerOneBadgeOne={''}
            headerOneBadgeTwo={'information'}
            basicInfo={responseObject}
            typeOfSetObject={SET_JOB_CULTURE_PROFILER_REDUCER_STATE}
            isRequired={true}
            reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
            mode={reviewMode === 'revise' ? 'revise' : 'core'}
            isNotRevised={true}
          />
        )}
        {isPopUpValue === 'PROFILERNAMEPOPUP' && (
          <PopUpTextField
            isActive={true}
            label={'name'}
            actualLableValue={'profilerName'}
            headerPanelColour={'genericOne'}
            headerOne={headerOne}
            headerOneBadgeOne={''}
            headerOneBadgeTwo={'information'}
            basicInfo={responseObject}
            typeOfSetObject={SET_JOB_CULTURE_PROFILER_REDUCER_STATE}
            isRequired={true}
            reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
            mode={reviewMode === 'revise' ? 'revise' : 'core'}
            isNotRevised={true}
          />
        )}
        {isPopUpValue === 'ALIASPOPUP' && (
          <PopUpTextField
            isActive={true}
            label={'description'}
            actualLableValue={'culturejobProfilerDescription'}
            headerPanelColour={'genericOne'}
            headerOne={headerOne}
            headerOneBadgeOne={''}
            headerOneBadgeTwo={'information'}
            basicInfo={culturejobProfilerInformation}
            typeOfSetObject={SET_JOB_CULTURE_PROFILER_REDUCER_STATE}
            reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
            mode={reviewMode === 'revise' ? 'revise' : 'core'}
            isNotRevised={true}
          />
        )}
        {isPopUpValue === 'PROFILERALIASPOPUP' && (
          <PopUpTextField
            isActive={true}
            label={'description'}
            actualLableValue={'profilerDescription'}
            headerPanelColour={'genericOne'}
            headerOne={headerOne}
            headerOneBadgeOne={''}
            headerOneBadgeTwo={'information'}
            basicInfo={responseObject}
            typeOfSetObject={SET_JOB_CULTURE_PROFILER_REDUCER_STATE}
            reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
            mode={reviewMode === 'revise' ? 'revise' : 'core'}
            isNotRevised={true}
          />
        )}
        {isPopUpValue === 'COMPETENCYNAMEPOPUP' && (
          <PopUpTextField
            isActive={true}
            label={'name'}
            actualLableValue={'iGaugeOccupationFrameworkOneClusterName'}
            headerPanelColour={'genericOne'}
            headerOne={headerOne}
            headerOneBadgeOne={''}
            headerOneBadgeTwo={'information'}
            basicInfo={responseObject}
            typeOfSetObject={SET_JOB_CULTURE_PROFILER_REDUCER_STATE}
            isRequired={true}
            reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
            mode={reviewMode === 'revise' ? 'revise' : 'core'}
          />
        )}
        {isPopUpValue === 'COMPETENCYALIASPOPUP' && (
          <PopUpTextField
            isActive={true}
            label={'description'}
            actualLableValue={'iGaugeOccupationFrameworkOneClusterDescription'}
            headerPanelColour={'genericOne'}
            headerOne={headerOne}
            headerOneBadgeOne={''}
            headerOneBadgeTwo={'information'}
            basicInfo={responseObject}
            typeOfSetObject={SET_JOB_CULTURE_PROFILER_REDUCER_STATE}
            reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
            mode={reviewMode === 'revise' ? 'revise' : 'core'}
          />
        )}
        {isPopUpValue === 'PICTUREPOPUP' && (
          <PopUpPicture
            isActive={true}
            headerPanelColour={'genericOne'}
            headerOne={headerOne}
            headerOneBadgeOne={'information'}
            headerOneBadgeTwo={''}
            actualLableValue={'culturejobProfilerPicture'}
            actualLableValueVerification={'culturejobProfilerPictureVerification'}
            basicInfo={culturejobProfilerInformation}
            typeOfSetObject={SET_JOB_CULTURE_PROFILER_REDUCER_STATE}
            reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
            mode={reviewMode === 'revise' ? 'revise' : 'core'}
            isNotRevised={true} //
          />
        )}

        {isPopUpValue === 'CLUSTERPOPUP' && (
          <PopUpTextField
            isActive={true}
            label={'cluster'}
            actualLableValue={'cluster'}
            headerPanelColour={'genericOne'}
            headerOne={headerOne}
            headerOneBadgeOne={''}
            headerOneBadgeTwo={'information'}
            basicInfo={culturejobProfiler}
            typeOfSetObject={SET_JOB_CULTURE_PROFILER_INFO_REDUCER_STATE}
            reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
            mode={reviewMode === 'revise' ? 'revise' : 'core'}
          />
        )}
        {isPopUpValue === 'EXPLANATIONPOPUP' && (
          <PopUpTextField
            isActive={true}
            label={'explanation'}
            actualLableValue={'explanation'}
            headerPanelColour={'genericOne'}
            headerOne={headerOne}
            headerOneBadgeOne={''}
            headerOneBadgeTwo={'information'}
            nextPopUpValue={'SCOREPOPUP'}
            basicInfo={culturejobProfiler}
            typeOfSetObject={SET_JOB_CULTURE_PROFILER_INFO_REDUCER_STATE}
            reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
            mode={reviewMode === 'revise' ? 'revise' : 'core'}
          />
        )}
        {isPopUpValue === 'POLARITYPOPUP' && (
          <PopUpDropList
            isActive={true}
            tag={'polarity'}
            label={'polarity'}
            listSelect={[
              { id: 'Positive', name: 'Positive' },
              { id: 'Negative', name: 'Negative' }
            ]}
            mappingValue={'id'}
            inputHeaderBadgeTwo={''}
            headerPanelColour={'genericOne'}
            headerOne={headerOne}
            headerOneBadgeOne={'information'}
            isRequired={false}
            nextPopUpValue={''}
            basicInfo={culturejobProfiler}
            typeOfSetObject={SET_JOB_CULTURE_PROFILER_INFO_REDUCER_STATE}
            mode={reviewMode === 'revise' ? 'revise' : 'core'}
          />
        )}
        {isPopUpValue === 'CLUSTERNAMEPOPUP' && (
          <PopUpTextField
            isActive={true}
            label={'name'}
            actualLableValue={'iGaugeOccupationFrameworkOneClusterName'}
            headerPanelColour={'genericOne'}
            headerOne={headerOne}
            headerOneBadgeOne={''}
            headerOneBadgeTwo={'information'}
            basicInfo={responseObject}
            typeOfSetObject={SET_JOB_CULTURE_PROFILER_CLUSTER_REDUCER_STATE}
            isRequired={true}
            reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
            mode={reviewMode === 'revise' ? 'revise' : 'core'}
            // isNotRevised={true}
          />
        )}
        {isPopUpValue === 'CLUSTERALIASPOPUP' && (
          <PopUpTextField
            isActive={true}
            label={'description'}
            actualLableValue={'iGaugeOccupationFrameworkOneClusterDescription'}
            headerPanelColour={'genericOne'}
            headerOne={headerOne}
            headerOneBadgeOne={''}
            headerOneBadgeTwo={'information'}
            basicInfo={culturejobProfilerInformation}
            typeOfSetObject={SET_JOB_CULTURE_PROFILER_REDUCER_STATE}
            reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
            mode={reviewMode === 'revise' ? 'revise' : 'core'}
            // isNotRevised={true}
          />
        )}
        {isPopUpValue === 'ONEONELABELPOPUP' && (
          <PopUpTextField
            isActive={true}
            label={'label'}
            actualLableValue={'iGaugeOccupationFrameworkOneClusterOneLabel'}
            headerPanelColour={'genericOne'}
            headerOne={headerOne}
            headerOneBadgeOne={'information'}
            basicInfo={clusterProfilerInformation}
            nextPopUpValue={''}
            typeOfSetObject={SET_JOB_CULTURE_PROFILER_CLUSTER_REDUCER_STATE}
            mode={reviewMode === 'revise' ? 'revise' : 'core'}
            reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          />
        )}
        {isPopUpValue === 'ONETWOLABELPOPUP' && (
          <PopUpTextField
            isActive={true}
            label={'label'}
            actualLableValue={'iGaugeOccupationFrameworkOneClusterTwoLabel'}
            headerPanelColour={'genericOne'}
            headerOne={headerOne}
            headerOneBadgeOne={'information'}
            basicInfo={clusterProfilerInformation}
            nextPopUpValue={''}
            typeOfSetObject={SET_JOB_CULTURE_PROFILER_CLUSTER_REDUCER_STATE}
            mode={reviewMode === 'revise' ? 'revise' : 'core'}
            reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          />
        )}
        {isPopUpValue === 'ONEONEEXPLANATION' && (
          <PopUpTextField
            isActive={true}
            label={'explanation'}
            actualLableValue={'iGaugeOccupationFrameworkOneClusterOneExplanation'}
            headerPanelColour={'genericOne'}
            headerOne={headerOne}
            headerOneBadgeOne={'information'}
            basicInfo={clusterProfilerInformation}
            nextPopUpValue={''}
            typeOfSetObject={SET_JOB_CULTURE_PROFILER_CLUSTER_REDUCER_STATE}
            mode={reviewMode === 'revise' ? 'revise' : 'core'}
            reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          />
        )}
        {isPopUpValue === 'ONETWOEXPLANATION' && (
          <PopUpTextField
            isActive={true}
            label={'explanation'}
            actualLableValue={'iGaugeOccupationFrameworkOneClusterTwoExplanation'}
            headerPanelColour={'genericOne'}
            headerOne={headerOne}
            headerOneBadgeOne={'information'}
            basicInfo={clusterProfilerInformation}
            nextPopUpValue={''}
            typeOfSetObject={SET_JOB_CULTURE_PROFILER_CLUSTER_REDUCER_STATE}
            mode={reviewMode === 'revise' ? 'revise' : 'core'}
            reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          />
        )}
        {isPopUpValue === 'ONEONEPOLARITY' && (
          <PopUpDropList
            isActive={true}
            tag={'iGaugeOccupationFrameworkOneClusterOnePolarity'}
            label={'polarity'}
            listSelect={[
              { id: 'Positive', name: 'Positive' },
              { id: 'Negative', name: 'Negative' }
            ]}
            mappingValue={'id'}
            labelval={''}
            headerPanelColour={'genericOne'}
            headerOne={headerOne}
            headerOneBadgeOne={'information'}
            isRequired={false}
            nextPopUpValue={''}
            basicInfo={clusterProfilerInformation}
            typeOfSetObject={SET_JOB_CULTURE_PROFILER_CLUSTER_REDUCER_STATE}
            mode={reviewMode === 'revise' ? 'revise' : 'core'}
            reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          />
        )}
        {isPopUpValue === 'ONETWOPOLARITY' && (
          <PopUpDropList
            isActive={true}
            tag={'iGaugeOccupationFrameworkOneClusterTwoPolarity'}
            label={'polarity'}
            listSelect={[
              { id: 'Positive', name: 'Positive' },
              { id: 'Negative', name: 'Negative' }
            ]}
            mappingValue={'id'}
            headerPanelColour={'genericOne'}
            headerOne={headerOne}
            headerOneBadgeOne={'information'}
            isRequired={false}
            nextPopUpValue={''}
            basicInfo={clusterProfilerInformation}
            typeOfSetObject={SET_JOB_CULTURE_PROFILER_CLUSTER_REDUCER_STATE}
            mode={reviewMode === 'revise' ? 'revise' : 'core'}
            reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          />
        )}
        {isPopUpValue === 'SEQUENCEPOPUP' && (
          <PopUpTextField
            isActive={true}
            label={'sequence'}
            actualLableValue={'iGaugeOccupationFrameworkOneClusterSequence'}
            headerPanelColour={'genericOne'}
            headerOne={headerOne}
            headerOneBadgeOne={''}
            headerOneBadgeTwo={'information'}
            nextPopUpValue={''}
            type={'number'}
            basicInfo={clusterProfilerInformation}
            typeOfSetObject={SET_JOB_CULTURE_PROFILER_CLUSTER_REDUCER_STATE}
            reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
            mode={reviewMode === 'revise' ? 'revise' : 'core'}
          />
        )}
        {isPopUpValue === 'ONEONEDESCRIPTION' && (
          <PopUpTextField
            isActive={true}
            label={'description'}
            actualLableValue={'iGaugeOccupationFrameworkOneClusterOneDescription'}
            headerPanelColour={'genericOne'}
            headerOne={headerOne}
            headerOneBadgeOne={'information'}
            basicInfo={clusterProfilerInformation}
            nextPopUpValue={''}
            typeOfSetObject={SET_JOB_CULTURE_PROFILER_CLUSTER_REDUCER_STATE}
            mode={reviewMode === 'revise' ? 'revise' : 'core'}
            reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          />
        )}
        {isPopUpValue === 'ONETWODESCRIPTION' && (
          <PopUpTextField
            isActive={true}
            label={'description'}
            actualLableValue={'iGaugeOccupationFrameworkOneClusterTwoDescription'}
            headerPanelColour={'genericOne'}
            headerOne={headerOne}
            headerOneBadgeOne={'information'}
            basicInfo={clusterProfilerInformation}
            nextPopUpValue={''}
            typeOfSetObject={SET_JOB_CULTURE_PROFILER_CLUSTER_REDUCER_STATE}
            mode={reviewMode === 'revise' ? 'revise' : 'core'}
            reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          />
        )}
        {isPopUpValue === 'SEQUENCEPOPUP' && (
          <PopUpTextField
            isActive={true}
            label={'sequence'}
            actualLableValue={'sequence'}
            headerPanelColour={'genericOne'}
            headerOne={headerOne}
            headerOneBadgeOne={''}
            headerOneBadgeTwo={'information'}
            basicInfo={culturejobProfiler}
            typeOfSetObject={SET_JOB_CULTURE_PROFILER_INFO_REDUCER_STATE}
            reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
            mode={reviewMode === 'revise' ? 'revise' : 'core'}
          />
        )}
        {isPopUpValue === 'CLUSEREXPLANATIONPOPUP' && (
          <PopUpTextField
            isActive={true}
            label={'explanation'}
            actualLableValue={'iGaugeOccupationFrameworkOneClusterExplanation'}
            headerPanelColour={'genericOne'}
            headerOne={headerOne}
            headerOneBadgeOne={''}
            headerOneBadgeTwo={'information'}
            nextPopUpValue={''}
            basicInfo={clusterProfilerInformation}
            typeOfSetObject={SET_JOB_CULTURE_PROFILER_CLUSTER_REDUCER_STATE}
            reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
            mode={reviewMode === 'revise' ? 'revise' : 'core'}
          />
        )}
      </div>
    </>
  );
};
export default PopUpCultureJobProfilerCreate;
