import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CLEAR_DISPLAY_PANE_THREE,
  FILTERMODE,
  FILTERMODE_ENABLE,
  POPUP_OPEN,
  SET_ASSESSEE_GROUP_ASSESSEE_ID_LIST,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MIDDLEPANE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_POPUP_STATE,
  SET_UNSELECTED_ASSESSEE_GROUP_ASSESSEE_ID_LIST,
  GET_ALLOCATE_ASSESSMENT,
  SET_PAGE_COUNT,
  GET_ASSESSMENTTYPE_ASSESSMENT_REVIEWLIST_SAGA
} from '../actionType';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import { FilterList } from '@material-ui/icons';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import { ASSOCIATE_REVIEW_LIST_POPUP_OPTION } from '../PopUpConfig';
import Card from '../Molecules/Card/Card';
import CrossIcon from '@material-ui/icons/Clear';
import { onClickCheckBoxSelection } from '../Actions/AssesseeModuleAction';
import ReviseIcon from '@material-ui/icons/RadioButtonChecked';
import Check from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import {
  assessmentTypeApiCall,
  getAssessmentTypeAssessmentDistinctApiCall
} from '../Actions/AssessmentModuleAction';
import { closeRelatedList, getPopUpOptionList } from '../Actions/GenericActions';
import ListLoader from '../Atoms/ListLoader/ListLoader';

const AssessmentTypeAssessmentDistinctReviewList = (props) => {
  const dispatch = useDispatch();
  const [isShowReviseIcon, setIsShowReviseIcon] = useState(true);
  const {
    middlePaneSelectedValue,
    reviewListDistinctData,
    selectedAssociateInfo,
    relatedReviewListDistinctData,
    middlePaneHeaderBadgeOne,
    middlePaneHeaderDuplicate,
    middlePaneHeaderDuplicateBadgeOne,
    middlePaneHeaderDuplicateBadgeTwo,
    middlePaneHeaderDuplicateBadgeThree,
    middlePaneHeaderDuplicateBadgeFour,
    typeOfMiddlePaneList,
    isSelectActive,
    selectedTagsArray,
    unselectedTagsArray,
    middlePaneHeaderBadgeTwo,
    middlePaneHeader,
    numberPage,
    isMiddlePaneList,
    scanCount,
    duplicateScanCount,
    signedAssesseePermission,
    relatedReviewListObjectCopy,
    countPage,
    isListLoading,
    middlePaneHeaderDuplicateOne,
    middlePaneHeaderDuplicateOneBadgeOne,
    middlePaneHeaderDuplicateOneBadgeTwo,
    middlePaneHeaderDuplicateOneBadgeThree,
    middlePaneHeaderDuplicateOneBadgeFour
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { FilterModeEnable, FilterMode } = useSelector((state) => state.FilterReducer);
  const { originPrimaryArrOprion } = useSelector((state) => state.PopUpReducer);
  const { reviewMode, createMode, headerOneBadgeOne } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  const { assessmentType } = useSelector((state) => state.TypeCreateReducer);
  const onClickRevise = () => {
    console.log('ON CLICK REVISE ICON');
    setIsShowReviseIcon(false);
  };
  const onClickReviseCancel = () => {
    console.log('ON CLICK cancel ICON');
    setIsShowReviseIcon(true);
    closeRelatedList(
      dispatch,
      createMode,
      responseObject,
      middlePaneHeaderDuplicate,
      middlePaneHeaderDuplicateBadgeOne,
      middlePaneHeaderDuplicateBadgeTwo,
      middlePaneHeaderDuplicateBadgeThree,
      middlePaneHeaderDuplicateBadgeFour,
      'assessmentsTypeDistinctReviewList',
      duplicateScanCount,
      'assessmentsTypeDistinct',
      duplicateScanCount,
      middlePaneHeaderDuplicateOne,
      middlePaneHeaderDuplicateOneBadgeOne,
      middlePaneHeaderDuplicateOneBadgeTwo,
      middlePaneHeaderDuplicateOneBadgeThree,
      middlePaneHeaderDuplicateOneBadgeFour
    );
    dispatch({
      type: SET_PAGE_COUNT,
      payload: Math.round(parseInt(reviewListDistinctData.length) / countPage) - 1
    });
    if (headerOneBadgeOne !== 'type') {
      dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
    }
  };
  const onClickReviseFinish = () => {
    console.log('ON CLICK finish ICON', selectedTagsArray, unselectedTagsArray);
    setIsShowReviseIcon(true);
    // if (typeOfMiddlePaneList !== '')
    dispatch({
      type: SET_MIDDLEPANE_STATE,
      payload: {
        middlePaneHeader: 'assessments',
        middlePaneHeaderBadgeOne: 'type',
        middlePaneHeaderBadgeTwo: 'active',
        middlePaneHeaderBadgeThree: '',
        middlePaneHeaderBadgeFour: '',
        typeOfMiddlePaneList: 'assessmentsTypeDistinctReviewList',
        scanCount: reviewListDistinctData.length,
        showMiddlePaneState: true
      }
    });
    dispatch({
      type: FILTERMODE,
      payload: { FilterMode: '' }
    });

    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'isSelectActive', value: '' }
    });
    dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
    dispatch({ type: SET_ASSESSEE_GROUP_ASSESSEE_ID_LIST, payload: selectedTagsArray });
    dispatch({
      type: SET_UNSELECTED_ASSESSEE_GROUP_ASSESSEE_ID_LIST,
      payload: unselectedTagsArray
    });
    assessmentTypeApiCall(
      responseObject,
      selectedAssociateInfo,
      selectedTagsArray,
      unselectedTagsArray,
      assessmentType,
      dispatch,
      createMode,
      true
    );
    closeRelatedList(
      dispatch,
      createMode,
      responseObject,
      middlePaneHeaderDuplicate,
      middlePaneHeaderDuplicateBadgeOne,
      middlePaneHeaderDuplicateBadgeTwo,
      middlePaneHeaderDuplicateBadgeThree,
      middlePaneHeaderDuplicateBadgeFour,
      'assessmentsTypeDistinctReviewList',
      duplicateScanCount,
      'assessmentsTypeDistinct',
      duplicateScanCount,
      middlePaneHeaderDuplicateOne,
      middlePaneHeaderDuplicateOneBadgeOne,
      middlePaneHeaderDuplicateOneBadgeTwo,
      middlePaneHeaderDuplicateOneBadgeThree,
      middlePaneHeaderDuplicateOneBadgeFour
    );
  };
  const revisePrimaryIcon = [{ label: 'revise', onClick: onClickRevise, Icon: ReviseIcon }];

  const reviseSecondaryIcons = [
    { label: 'cancel', onClick: onClickReviseCancel, Icon: ClearIcon },
    { label: 'finish', onClick: onClickReviseFinish, Icon: Check }
  ];
  console.log('relatedReviewListDistinctData', relatedReviewListDistinctData);
  const listDistinctData = relatedReviewListDistinctData[0];

  const siftApiCall = (siftKey) => {
    getAssessmentTypeAssessmentDistinctApiCall(
      selectedAssociateInfo,
      siftKey,
      countPage,
      dispatch,
      middlePaneHeaderBadgeOne,
      listDistinctData.id,
      '',
      false
    );
    // document.getElementById('middleComponentId').scrollTop = '0px';
  };
  const onClickFooter = (e) => {
    let siftValue = e.currentTarget.getAttribute('data-value');
    if (siftValue === 'suspended' || siftValue === 'terminated' || siftValue === 'unpublished')
      siftApiCall(siftValue);
    dispatch({ type: FILTERMODE_ENABLE });
  };
  /* for middle pane */
  const primaryIcon = [{ label: 'sift', onClick: onClickFooter, Icon: FilterList }];
  const secondaryIcon = [
    { label: 'suspended', onClick: onClickFooter, Icon: FilterList },
    { label: 'terminated', onClick: onClickFooter, Icon: FilterList },
    { label: 'unpublished', onClick: onClickFooter, Icon: FilterList }
  ];

  const openListPopup = (e) => {
    console.log(e.currentTarget.getAttribute('tag'));
    let tempArr = [];
    let stats = e.currentTarget.getAttribute('status');
    let isShared = e.currentTarget.getAttribute('data-shared') === 'true' && true;
    let associateId = e.currentTarget.getAttribute('associateid');
    let signedAssociateId =
      selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary;

    let assessmentDistinctPermission =
      signedAssesseePermission?.assessmentDistictPermission?.assesseeAssessmentDistinctPermission;
    let popupContentArrValue = getPopUpOptionList(
      ASSOCIATE_REVIEW_LIST_POPUP_OPTION,
      assessmentDistinctPermission
    );
    popupContentArrValue.forEach((element) => {
      if (stats === 'UNPUBLISHED' && element.data === 'share') {
        tempArr.push({ ...element, disabled: true });
      } else if (
        (stats === 'ARCHIVED' || stats === 'TERMINATED' || stats === 'SUSPENDED') &&
        (element.data === 'allocate' ||
          element.data === 'revise' ||
          element.data === 'select' ||
          element.data === 'share')
      ) {
        tempArr.push({ ...element, disabled: true });
      } else if (
        isShared &&
        associateId !== signedAssociateId &&
        (element.data === 'revise' ||
          element.data === 'archive' ||
          element.data === 'flag' ||
          element.data === 'delete' ||
          element.data === 'suspend' ||
          element.data === 'move' ||
          element.data === 'terminate')
      )
        tempArr.push({ ...element, disabled: true });
      else tempArr.push(element);
    });
    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: 'assessment',
        popupHeaderOneBadgeOne: middlePaneHeaderBadgeOne,
        isPopUpValue: '',
        popupOpenType: 'primary',
        popupContentArrValue: tempArr,
        isShared: isShared,
        originPrimaryArrOprion: originPrimaryArrOprion,
        selectedTagValue: e.currentTarget.getAttribute('tag'),
        selectedTagStatus: e.currentTarget.getAttribute('status')
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: tempArr
      }
    });
  };
  // this code for assessment type list
  const { responseObject } = useSelector((state) => state.DisplayPaneThreeReducer);
  // const [isFetching, setIsFetching] = useState(false);

  // useEffect(() => {
  //   document.getElementById('middleComponentId').addEventListener('scroll', handleScroll);
  // }, []);
  // const handleScroll = (event) => {
  //   var targetPt = event.target;
  //   if (
  //     Math.ceil(targetPt.scrollHeight - targetPt.scrollTop) !== targetPt.clientHeight ||
  //     isFetching
  //   )
  //     return;
  //   setIsFetching(true);
  //   console.log(isFetching);
  // };
  const fetchData = async () => {
    if (relatedReviewListDistinctData.length > 0) {
      if (relatedReviewListDistinctData[0].assessment.length < scanCount) {
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'isListLoading', value: true }
        });
        let obj = {
          ...relatedReviewListObjectCopy.requestObject,
          numberPage: numberPage + 1
        };
        if (reviewMode === 'revise') {
          dispatch({
            type: GET_ALLOCATE_ASSESSMENT,
            payload: {
              existingAssessmentId: selectedTagsArray,
              request: {
                module: 'Assessment-Distinct',
                action: 'Review-List',
                requestObject: obj
              },
              HeaderOne: middlePaneHeader,
              BadgeOne: 'distinct',
              BadgeTwo: middlePaneHeaderBadgeTwo,
              isMiddlePaneList: true,
              typeOfMiddlePaneList: 'assessmentTypeAssessmentReviewList',
              revisedGroupObject: {
                id: responseObject.id,
                assessmentTypeName: responseObject.informationBasic.assessmentTypeName,
                assessmentTypeDescription:
                  responseObject.informationBasic.assessmentTypeDescription,
                assessmentTypeStatus: responseObject.informationEngagement.assessmentTypeStatus
              }
            }
          });
        } else {
          dispatch({
            type: GET_ASSESSMENTTYPE_ASSESSMENT_REVIEWLIST_SAGA,
            payload: {
              request: {
                module: 'Assessment-Type',
                action: 'Review-List',
                actionFilter: 'Assessment',
                requestObject: obj
              },
              HeaderOne: 'assessees',
              BadgeOne: middlePaneHeaderBadgeOne,
              BadgeTwo: middlePaneHeaderBadgeTwo,
              BadgeThree: '',
              isMiddlePaneList: isMiddlePaneList
            }
          });
        }
        dispatch({ type: SET_PAGE_COUNT, payload: numberPage + 1 });
      }
    }
  };

  // useEffect(() => {
  //   if (!isFetching) return;
  //   fetchMoreListItems();
  // }, [isFetching]);

  // const fetchMoreListItems = () => {
  //   fetchData();
  //   setIsFetching(false);
  // };

  // infinite scroll
  const [hasMore, setHasMore] = useState(true);
  let elementRef = useRef(null);

  const onIntersection = (entries) => {
    const firstEntry = entries[0];
    relatedReviewListDistinctData[0].assessment.length >= scanCount
      ? setHasMore(false)
      : setHasMore(true);
    if (firstEntry.isIntersecting && hasMore && !isListLoading) {
      fetchData();
    }
  };

  useEffect(() => {
    // console.log('####MYREF######', elementRef)
    const observer = new IntersectionObserver(onIntersection, { threshold: 0.90 });

    if (observer && elementRef.current) {
      // console.log('here at observer...', elementRef.current)
      observer.observe(elementRef.current);
    }

    return () => {
      if (observer) observer.disconnect();
    };
  }, [
    isListLoading,
    hasMore,
    relatedReviewListDistinctData,
    selectedTagsArray,
    unselectedTagsArray,
    isSelectActive
  ]);

  // infinite scroll end

  // this code for assessment type list
  return (
    <div>
      {listDistinctData && (
        <Card
          textOneOne={listDistinctData.assessmentTypeName}
          textTwoOne={listDistinctData.assessmentTypeDescription}
          IconOne={CrossIcon}
          isIcon={true}
          labelTwoTwo={'type'}
          onClickIconOne={onClickReviseCancel}
          isAlliance
          relatedCardFixed={true}
          className={'iguru-iconboxSVG'}
        />
      )}
      {listDistinctData &&
        listDistinctData?.assessment.length > 0 &&
        listDistinctData?.assessment.map((item, index) => {
          return (
            <div className="containerPadding" key={index} ref={elementRef}>
              <ReviewList
                className=""
                id={index}
                tag={item.id}
                associateId={item?.associateId}
                isSelectedReviewList={middlePaneSelectedValue === item.id}
                status={item.informationEngagement.assessmentStatus}
                actualStatus={item.informationEngagement.assessmentStatus}
                isShared={item?.assessmentShared}
                textOne={item.informationBasic.assessmentName}
                textTwo={item.informationBasic.assessmentDescription}
                isTooltipActive={false}
                // onClickEvent={openListPopup}
                isSelectActive={isSelectActive}
                isSelected={selectedTagsArray.includes(item.id)}
                onClickCheckBox={(event) => {
                  !isListLoading &&
                    onClickCheckBoxSelection(
                      selectedTagsArray,
                      unselectedTagsArray,
                      event,
                      dispatch
                    );
                }}
              />
            </div>
          );
        })}
      {isListLoading ? (
        <div>
          {' '}
          <ListLoader />{' '}
        </div>
      ) : <div style={{ height: "60px", width: "100%" }}></div>}
      {/* {FilterMode === 'assessmentTypeAssessmentRevise' && (
        <FooterIconTwo
          FilterModeEnable={isShowReviseIcon}
          FilterMode={FilterMode}
          onClick={onClickRevise}
          primaryIcon={revisePrimaryIcon}
          secondaryIcon={reviseSecondaryIcons}
        />
      )}
      {FilterMode === 'assessmentTypeAssessmentinactive' && (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={primaryIcon}
          secondaryIcon={secondaryIcon}
        />
      )} */}
    </div>
  );
};
export default AssessmentTypeAssessmentDistinctReviewList;
