import { put, takeLatest, call } from 'redux-saga/effects';
import {
  CREATE_JOB_SAGA,
  LOADER_STOP,
  SET_CREATE_MODE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_JOB_SETUP_INFO_STATE,
  SET_NEXT_POPUP,
  SET_POPUP_SINGLE_STATE,
  SET_POPUP_VALUE
} from '../../actionType';
import { JOB_MODULE_URL } from '../../endpoints';
import { BASIC_PUBLISH_REVIEW_LIST_NOT_ITERBLE_POPUP } from '../../PopUpConfig';
import { INFORMATION_EXIST_ERROR_MESSAGE } from '../../errorMessage';

const createApiCall = async (requestObj) => {
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      Authorization: localStorage.getItem('idToken')
    }),
    body: JSON.stringify(requestObj.data)
  };
  const response = await fetch(requestObj.URL, requestOptions);
  const json = await response.json();
  return json;
};

function* workerCreateJobProfileSaga(data) {
  try {
    const response = yield call(createApiCall, { data: data.payload, URL: JOB_MODULE_URL });
    if (response.responseCode === '000') {
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'responseObject', value: response.responseObject[0] }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'middlePaneSelectedValue', value: response.responseObject[0].id }
      });
      yield put({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'selectedTagValue', value: response.responseObject[0].id }
      });
      yield put({
        type: SET_POPUP_SINGLE_STATE,
        payload: {
          stateName: 'selectedTagStatus',
          value: response.responseObject[0].informationEngagement?.jobProfileStatus
        }
      });
      yield put({ type: SET_NEXT_POPUP, payload: { isPopUpValue: 'POPUPCONTINUE' } });
      yield put({ type: SET_CREATE_MODE, payload: 'jobProfile' });
      yield put({
        type: SET_JOB_SETUP_INFO_STATE,
        payload: response.responseObject[0].informationSetup,
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: {
          stateName: 'middlePaneListPopupOptions',
          value: BASIC_PUBLISH_REVIEW_LIST_NOT_ITERBLE_POPUP
        }
      });
    } else if (response.responseCode === '304') {
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'NAMEPOPUP',
          // popupMode: `CULTURECREATE`,
          popupMode: `JOBCREATE`,
          popupErrorMsg: INFORMATION_EXIST_ERROR_MESSAGE
        }
      });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: response.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({ type: LOADER_STOP });
  }
}

export default function* watchCreateJobProfileSaga() {
  yield takeLatest(CREATE_JOB_SAGA, workerCreateJobProfileSaga);
}
