import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CLEAR_DISPLAY_PANE_THREE,
  FILTERMODE,
  FILTERMODE_ENABLE,
  POPUP_CLOSE,
  POPUP_OPEN,
  SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MIDDLEPANE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_POPUP_STATE
} from '../actionType';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import { FilterList } from '@material-ui/icons';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import {
  ASSESSEE_ASSIGNMENT_ASSESSMENT_REVIEW_LIST_POPUP_OPTION,
  ASSESSEE_ASSIGNMENT_TRIPLE_POPUP_OPTION,
  RES_START_POPUP_OPTION
} from '../PopUpConfig';
import Card from '../Molecules/Card/Card';
import CrossIcon from '@material-ui/icons/Clear';
import { getAssesseeSelfAssignmentList } from '../Actions/GenericActions';
import ListLoader from '../Atoms/ListLoader/ListLoader';

const AssesseeDistinctAssessmentDistinctReviewList = (props) => {
  const dispatch = useDispatch();
  const [isShowReviseIcon, setIsShowReviseIcon] = useState(true);
  const {
    middlePaneSelectedValue,
    reviewListDistinctData,
    selectedAssociateInfo,
    relatedReviewListDistinctData,
    middlePaneHeaderDuplicateBadgeOne,
    middlePaneHeaderDuplicate,
    duplicateScanCount,
    middlePaneHeader,
    middlePaneHeaderBadgeOne,
    typeOfMiddlePaneList,
    selectedTagsArray,
    unselectedTagsArray,
    isSelectActive,
    isListLoading,
    countPage
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { FilterModeEnable, FilterMode } = useSelector((state) => state.FilterReducer);

  const closeRelatedList = () => {
    if (isListLoading) {
      return
    }
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'middlePaneSelectedValue', value: '' }
    });
    let statusArr = [
      'STARTED',
      'UNSTARTED',
      'ABORTED',
      'FINISHED',
      'UNFINISHED',
      'SUSPENDED',
      'TERMINATED',
      'ADMINISTERED'
    ];
    if (middlePaneHeaderDuplicateBadgeOne.toUpperCase() === 'ACTIVE') {
      statusArr = ['STARTED', 'UNSTARTED', 'ADMINISTERED'];
    }
    if (middlePaneHeaderDuplicateBadgeOne.toUpperCase() === 'INACTIVE') {
      statusArr = ['ABORTED', 'FINISHED', 'UNFINISHED', 'SUSPENDED', 'TERMINATED', 'ADMINISTERED'];
    }
    if (middlePaneHeaderDuplicateBadgeOne.toUpperCase() === 'ARCHIVED') {
      statusArr = ['ARCHIVED', 'ADMINISTERED'];
    }
    getAssesseeSelfAssignmentList(
      selectedAssociateInfo,
      countPage,
      statusArr,
      dispatch,
      middlePaneHeaderDuplicateBadgeOne
    );
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'relatedReviewListDistinctData', value: [] }
    });
    dispatch({
      type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
      payload: { stateName: 'assesseeAssignmentAssessmentData', value: null }
    });
    dispatch({
      type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
      payload: { stateName: 'assesseeAssessmentStartData', value: null }
    });
    dispatch({
      type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
      payload: { stateName: 'isAssessmentStart', value: '' }
    });
    dispatch({
      type: FILTERMODE,
      payload: { FilterMode: 'assesseeAssignmentDistinctactive' }
    });
    dispatch({ type: POPUP_CLOSE });
    dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
    dispatch({ type: FILTERMODE_ENABLE });
    dispatch({
      type: FILTERMODE,
      payload: { FilterMode: 'assesseeAssignmentDistinct' + middlePaneHeaderDuplicateBadgeOne }
    });
  };
  const listDistinctData = relatedReviewListDistinctData[0];

  const siftApiCall = (siftKey) => {
    document.getElementById('middleComponentId').scrollTop = '0px';
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'relatedReviewListDistinctData', value: [] }
    });
    if (siftKey === 'timeout') siftKey = 'unfinished';
    let selectedAssignment = reviewListDistinctData.filter((assignment) => {
      return (
        assignment.assesseeAssignmentId === relatedReviewListDistinctData[0].assesseeAssignmentId
      );
    });
    let siftValue = siftKey.toUpperCase();
    let selectedAssessment = selectedAssignment[0].assesseeAssignmentAssessmentDistinct.filter(
      (assessment) => {
        return siftValue.toUpperCase() === assessment.assesseeAssignmentAssessmentStatus;
      }
    );
    console.log('siftValue', siftValue);
    console.log('selectedAssessment', selectedAssessment);
    let obj = {
      ...selectedAssignment[0],
      assesseeAssignmentAssessmentDistinct: selectedAssessment
    };
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'relatedReviewListDistinctData', value: [obj] }
    });
    dispatch({
      type: SET_MIDDLEPANE_STATE,
      payload: {
        middlePaneHeader: middlePaneHeader,
        middlePaneHeaderBadgeOne: siftKey === 'unfinished' ? 'timeout' : siftKey,
        middlePaneHeaderBadgeTwo: '',
        middlePaneHeaderBadgeThree: '',
        middlePaneHeaderBadgeFour: '',
        middlePaneHeaderDuplicate: middlePaneHeaderDuplicate,
        middlePaneHeaderDuplicateBadgeOne: middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderDuplicateBadgeTwo: '',
        middlePaneHeaderDuplicateBadgeThree: '',
        middlePaneHeaderDuplicateBadgeFour: '',
        typeOfMiddlePaneList: typeOfMiddlePaneList,
        scanCount: selectedAssessment.length,
        duplicateScanCount: duplicateScanCount,
        showMiddlePaneState: true
      }
    });
    console.log('action performed');
    dispatch({ type: POPUP_CLOSE });
  };
  const onClickFooter = (e) => {
    let siftValue = e.currentTarget.getAttribute('data-value');
    console.log('siftValue', siftValue);
    if (
      siftValue === 'suspended' ||
      siftValue === 'terminated' ||
      siftValue === 'started' ||
      siftValue === 'unstarted' ||
      siftValue === 'finished' ||
      siftValue === 'timeout' ||
      siftValue === 'aborted'
    )
      siftApiCall(siftValue);
    dispatch({ type: FILTERMODE_ENABLE });
  };
  /* for middle pane */
  const primaryIcon = [{ label: 'sift', onClick: onClickFooter, Icon: FilterList }];
  const secondaryIconOne = [
    { label: 'started', onClick: onClickFooter, Icon: FilterList },
    { label: 'unstarted', onClick: onClickFooter, Icon: FilterList }
  ];
  const secondaryIconTwo = [
    { label: 'aborted', onClick: onClickFooter, Icon: FilterList },
    { label: 'finished', onClick: onClickFooter, Icon: FilterList },
    { label: 'suspended', onClick: onClickFooter, Icon: FilterList },
    { label: 'terminated', onClick: onClickFooter, Icon: FilterList },
    { label: 'timeout', onClick: onClickFooter, Icon: FilterList }
  ];

  const openListPopup = (e) => {
    console.log(e.currentTarget.getAttribute('tag'));
    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: 'assessment',
        popupHeaderOneBadgeOne: '',
        popupHeaderOneBadgeTwo: '',
        isPopUpValue: '',
        popupOpenType: 'primary',
        popupContentArrValue: ASSESSEE_ASSIGNMENT_ASSESSMENT_REVIEW_LIST_POPUP_OPTION,
        selectedTagValue: e.currentTarget.getAttribute('tag'),
        selectedTagStatus: e.currentTarget.getAttribute('status')
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: ASSESSEE_ASSIGNMENT_TRIPLE_POPUP_OPTION
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
  };
  const startAssessment = (e) => {
    let status = e.currentTarget.getAttribute('actualstatus');
    let tempArr = RES_START_POPUP_OPTION;
    if (status === 'UNSTARTED') {
      tempArr = [{ ...tempArr[0], disabled: true }, tempArr[1]];
    } else {
      tempArr = [tempArr[0], { ...tempArr[1], disabled: true }];
    }
    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: 'assessment',
        popupHeaderOneBadgeOne: '',
        popupHeaderOneBadgeTwo: '',
        isPopUpValue: '',
        popupOpenType: 'primary',
        popupContentArrValue: tempArr,
        selectedTagValue: e.currentTarget.getAttribute('assignmentid'),
        selectedTagStatus: status
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });

    // assignmentStart(e.currentTarget.getAttribute('index'));
    // setAssessmentList(reviewListDistinctData[e.currentTarget.getAttribute('index')]);
  };
  console.log('listDistinctData', listDistinctData);
  return (
    <div key={listDistinctData}>
      {listDistinctData && (
        <div className="containerPadding">
          <Card
            isIcon
            IconOne={CrossIcon}
            textOneOne={listDistinctData.assesseeAssignmentName}
            textTwoOne={listDistinctData.assesseeAssignmentDescription}
            labelTwoTwo={'assignment'}
            isAlliance
            onClickIconOne={closeRelatedList}
          />
        </div>
        // <Card
        //   textOneOne={listDistinctData.assesseeAssignmentName}
        //   textTwoOne={listDistinctData.assesseeAssignmentDescription}
        //   IconOne={CrossIcon}
        //   isIcon={true}
        //   labelTwoTwo={'assignment'}
        //   onClickIconOne={closeRelatedList}
        //   isAlliance
        //   relatedCardFixed={true}
        //   className={'iguru-iconboxSVG'}
        // />
      )}
      {listDistinctData &&
        listDistinctData.assesseeAssignmentAssessmentDistinct.map((item, index) => {
          return (
            <div className="containerPadding" key={index}>
              <ReviewList
                className=""
                id={index}
                tag={item.assesseeAssignmentAssessmentId}
                associateId={item?.associateId}
                isSelectedReviewList={
                  middlePaneSelectedValue === item.assesseeAssignmentAssessmentId
                }
                status={
                  item.assesseeAssignmentAssessmentStatus === 'UNFINISHED'
                    ? 'TIMEOUT'
                    : item.assesseeAssignmentAssessmentStatus
                }
                actualStatus={item.assesseeAssignmentAssessmentStatus}
                textOne={item.assesseeAssignmentAssessmentName}
                textTwo={item.assesseeAssignmentAssessmentDescription}
                isTooltipActive={false}
                onClickEvent={openListPopup}
                isSelectActive={isSelectActive}
                isSelected={selectedTagsArray.includes(item.assesseeAssignmentAssessmentId)}
                isDelivery={true}
                onClickArrow={
                  item.assesseeAssignmentAssessmentStatus === 'FINISHED' ||
                    item.assesseeAssignmentAssessmentStatus === 'UNFINISHED' ||
                    item.assesseeAssignmentAssessmentStatus === 'TERMINATED' ||
                    item.assesseeAssignmentAssessmentStatus === 'SUSPENDED' ||
                    item.assesseeAssignmentAssessmentStatus === 'ARCHIVED'
                    ? null
                    : startAssessment
                }
              />
            </div>
          );
        })}
      {isListLoading ? (
        <div>
          {' '}
          <ListLoader />{' '}
        </div>
      ) : <div style={{ height: "60px", width: "100%" }}></div>}
      {(FilterMode === 'assesseeAssignmentDistinctinactive' ||
        FilterMode === 'assesseeAssignmentDistinctactive' ||
        FilterMode === 'assesseeAssignmentDistinct') && (
          <FooterIconTwo
            FilterModeEnable={FilterModeEnable}
            FilterMode={FilterMode}
            onClick={onClickFooter}
            primaryIcon={primaryIcon}
            secondaryIcon={
              FilterMode === 'assesseeAssignmentDistinctactive' ? secondaryIconOne : secondaryIconTwo
            }
          />
        )}
    </div>
  );
};
export default AssesseeDistinctAssessmentDistinctReviewList;
