import React from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import DisplayPanelAccordianReviewListOne from '../Accordian/DisplayPanelAccordianReviewListOne';
import DisplayPanelAccordianInformation from '../Accordian/DisplayPanelAccordianInformation';
import { Paper } from '@material-ui/core';
import { SET_POPUP_VALUE } from '../../actionType';

const DisplayPaneThreeSectionTwoAssessmentScoreGrade = () => {
  const dispatch = useDispatch();
  const { responseObject, reviewMode } = useSelector((state) => state.DisplayPaneThreeReducer);
  const frameworkAll = [
    {
      id: 'score',
      labelTextOneOne: 'score',
      labelTextOneOneBadges: [
        {
          labelTextTwoBadge: 'extremum',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'maximum',
              innerLabelBadgeList:
                responseObject?.assessmentScoreGradeScore
                  ?.assessmentScoreGradeScoreExtremumMaximum || 'No Information'
            },
            {
              labelTextTwoBadge: 'minimum',
              innerLabelBadgeList:
                responseObject?.assessmentScoreGradeScore
                  ?.assessmentScoreGradeScoreExtremumMinimum || 'No Information'
            }
          ]
        }
      ],
      isListCard: false,
      isReviewLink: false,
      isMultiInfoCard: true
    }
  ];
  const onClickReview = (e) => {};
  const onClickRevise = (e, selectedBadgeArray) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    let badgeName = '';
    if (selectedBadgeArray) {
      if (selectedBadgeArray.length > 0) {
        selectedBadgeArray.forEach((element) => {
          badgeName = badgeName + element.labelTextTwoBadge;
        });
      }
    }
    console.log(badgeName);
    console.log(labelName + '  ' + selectedBadgeName);
    if (labelName === 'score') {
      if (badgeName === 'extremum' && selectedBadgeName === 'maximum') {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'MAXIMUMPOPUP', popupMode: 'GRADECREATE' }
        });
      }
      if (badgeName === 'extremum' && selectedBadgeName === 'minimum') {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'MINIMUMPOPUP', popupMode: 'GRADECREATE' }
        });
      }
    }
    if (labelName === 'calculator') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'SCORE_POPUP', popupMode: 'SCORECREATE' }
      });
    }
    if (labelName === 'classification') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'SCORE_POPUP', popupMode: 'SCORECREATE' }
      });
    }
  };
  return (
    <div>
      <>
        <div className={'containerPadding'}>
          <Paper className={'dossierContainerTop'}>
            {frameworkAll.map((ob) => {
              return (
                <div key={ob.id}>
                  {ob.isListCard ? (
                    <DisplayPanelAccordianReviewListOne
                      className=""
                      accordianObject={ob}
                      mode={reviewMode}
                      onClickRevise={onClickRevise}
                      onClickReview={onClickReview}
                    />
                  ) : (
                    <DisplayPanelAccordianInformation
                      accordianObject={ob}
                      mode={reviewMode}
                      onClickRevise={onClickRevise}
                      onClickReview={onClickReview}
                    />
                  )}
                </div>
              );
            })}
          </Paper>
        </div>
      </>
      {isMobile && (
        <div className={'containerPadding'} style={{ height: '55px' }}>
          {' '}
        </div>
      )}
    </div>
  );
};

export default DisplayPaneThreeSectionTwoAssessmentScoreGrade;
