import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PopUpPicture from '../../PopUpInformation/PopUpPicture';
import PopUpTextField from '../../PopUpInformation/PopUpTextField';
import PopUpConfirm from '../../PopUpGeneric/PopUpConfirm';
import {
  POPUP_CLOSE,
  LOADER_START,
  SET_DISPLAY_THREE_SINGLE_STATE,
  CLEAR_SCALE_REDUCER_STATE,
  SET_SCALE_REDUCER_STATE,
  SET_SCALE_INFO_REDUCER_STATE,
  CREATE_ASSESSMENT_SECTION_SAGA,
  ASSESSMENT_CLUSTER_REVISE_SAGA
} from '../../actionType';
import PopUpTagSecondary from '../../PopUpInformation/PopUpTagSecondary';
import PopUpDropList from '../../PopUpInformation/PopUpDropList';

const PopUpScaleCreate = (props) => {
  const { headerOne } = props;
  const { isPopUpValue, selectedTagValue } = useSelector((state) => state.PopUpReducer);
  const { scaleInformation, assessmentScaleMetricInformation } = useSelector(
    (state) => state.ScaleCreateReducer
  );
  console.log('scaleInformation', scaleInformation);
  console.log('assessmentScaleMetricInformation', assessmentScaleMetricInformation);
  const { reviewMode, responseObject, statusPopUpValue } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  const { selectedAssociateInfo, typeOfMiddlePaneList } = useSelector(
    (state) => state.DisplayPaneTwoReducer
  );

  const dispatch = useDispatch();
  const onClickCancelYes = () => {
    dispatch({
      type: SET_DISPLAY_THREE_SINGLE_STATE,
      payload: { stateName: 'createMode', value: '' }
    });
    dispatch({ type: CLEAR_SCALE_REDUCER_STATE });
    dispatch({ type: POPUP_CLOSE });
  };
  const onClickYes = () => {
    dispatch({ type: POPUP_CLOSE });
    let requestObj = {
      module: 'Assessment-Distinct',
      action: 'Create',
      actionFilter: 'Section-Cluster-Scale',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        assessmentId: selectedTagValue,
        assessmentScaleFramework: scaleInformation
      }
    };
    console.log('requestObj', requestObj);
    dispatch({ type: POPUP_CLOSE });
    dispatch({ type: LOADER_START });
    dispatch({ type: CREATE_ASSESSMENT_SECTION_SAGA, payload: { requestObj } });
    dispatch({ type: CLEAR_SCALE_REDUCER_STATE });
  };
  const onClickScaleYes = () => {
    // dispatch({ type: POPUP_CLOSE });
    console.log('scaleInformation', scaleInformation);
    let scaleMetic = [];
    if (scaleInformation.assessmentScaleMetric.length > 0) {
      scaleInformation.assessmentScaleMetric.forEach((element, ind) => {
        scaleMetic.push({
          ...element,
          assessmentScaleMetricNumber: ind + 1
        });
      });
    }
    scaleMetic.push({
      ...assessmentScaleMetricInformation,
      assessmentScaleMetricNumber: scaleInformation.assessmentScaleMetric.length + 1
    });

    let obj = {
      ...scaleInformation,
      assessmentScaleMetric: scaleMetic
    };
    let requestObj = {
      module: 'Assessment-Distinct',
      action: 'Revise',
      actionFilter: 'Section-Cluster-Scale',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        assessmentId: scaleInformation.assessmentId,
        assessmentScaleFramework: obj
      }
    };
    console.log('requestObj', requestObj);
    dispatch({ type: POPUP_CLOSE });
    dispatch({ type: LOADER_START });
    dispatch({
      type: ASSESSMENT_CLUSTER_REVISE_SAGA,
      payload: {
        secondaryOptionCheckValue: 'key',
        headerOne: 'assessment',
        reqBody: requestObj,
        assessmentSector: 'updatescale',
        selectedSector: selectedTagValue
      }
    });
    dispatch({ type: CLEAR_SCALE_REDUCER_STATE });
  };
  let tenureStartDate = 'mm/dd/yyyy --:-- --';
  let tenureEndDate = 'mm/dd/yyyy --:-- --';
  return (
    <div>
      {isPopUpValue === 'NAMEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'name'}
          actualLableValue={'assessmentScaleName'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'scale'}
          headerOneBadgeTwo={'information'}
          nextPopUpValue={'ALIASPOPUP'}
          basicInfo={scaleInformation}
          typeOfSetObject={SET_SCALE_REDUCER_STATE}
          isRequired={true}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assessmentDistinctSubScaleDistinct'}
        />
      )}
      {isPopUpValue === 'ALIASPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'description'}
          actualLableValue={'assessmentScaleDescription'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'scale'}
          headerOneBadgeTwo={'information'}
          basicInfo={scaleInformation}
          nextPopUpValue={'PICTUREPOPUP'}
          typeOfSetObject={SET_SCALE_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assessmentDistinctSubScaleDistinct'}
        />
      )}
      {isPopUpValue === 'PICTUREPOPUP' && (
        <PopUpPicture
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'scale'}
          headerOneBadgeTwo={'information'}
          nextPopUpValue={'CONFIRMATIONPOPUP'}
          actualLableValue={'assessmentScalePicture'}
          actualLableValueVerification={'assessmentScalePictureVerification'}
          basicInfo={scaleInformation}
          typeOfSetObject={SET_SCALE_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assessmentDistinctSubScaleDistinct'}
        />
      )}

      {isPopUpValue === 'LABELPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'label'}
          actualLableValue={'assessmentScaleMetricLabel'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'scale'}
          headerOneBadgeTwo={'metric'}
          headerOneBadgeThree={'information'}
          basicInfo={assessmentScaleMetricInformation}
          nextPopUpValue={'LABEL_DESC_POPUP'}
          typeOfSetObject={SET_SCALE_INFO_REDUCER_STATE}
          isRequired={true}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          //mode={'core'}
        />
      )}
      {isPopUpValue === 'LABEL_DESC_POPUP' && (
        <PopUpTextField
          isActive={true}
          label={'description'}
          actualLableValue={'assessmentScaleMetricDescription'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'scale'}
          headerOneBadgeTwo={'metric'}
          headerOneBadgeThree={'information'}
          basicInfo={assessmentScaleMetricInformation}
          nextPopUpValue={'EXPLANATIONPOPUP'}
          typeOfSetObject={SET_SCALE_INFO_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          //mode={'core'}
        />
      )}
      {isPopUpValue === 'EXPLANATIONPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'explanation'}
          actualLableValue={'assessmentScaleMetricExplanation'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'scale'}
          headerOneBadgeTwo={'metric'}
          headerOneBadgeThree={'information'}
          basicInfo={assessmentScaleMetricInformation}
          nextPopUpValue={'SCOREPOPUP'}
          typeOfSetObject={SET_SCALE_INFO_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          //mode={'core'}
        />
      )}
      {isPopUpValue === 'SCOREPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'score'}
          labelBadgeOne={''}
          type={'number'}
          actualLableValue={'assessmentScaleMetricScore'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'scale'}
          headerOneBadgeTwo={'metric'}
          headerOneBadgeThree={'information'}
          basicInfo={assessmentScaleMetricInformation}
          nextPopUpValue={'WEIGTAGEPOPUP'}
          typeOfSetObject={SET_SCALE_INFO_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          //mode={'core'}
        />
      )}
      {isPopUpValue === 'WEIGTAGEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'weightage'}
          labelBadgeOne={''}
          type={'number'}
          actualLableValue={'assessmentScaleMetricWeightage'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'scale'}
          headerOneBadgeTwo={'metric'}
          headerOneBadgeThree={'information'}
          basicInfo={assessmentScaleMetricInformation}
          nextPopUpValue={'POPUPYES'}
          typeOfSetObject={SET_SCALE_INFO_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          //mode={'core'}
        />
      )}
      {isPopUpValue === 'POPUPYES' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'scale'}
          headerOneBadgeTwo={'metric'}
          headerOneBadgeThree={'create'}
          onClickYes={onClickScaleYes}
        />
      )}
      {isPopUpValue === 'CANCELPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'cancel'}
          headerOneBadgeOne={''}
          mode={'cancel'}
          onClickYes={onClickCancelYes}
        />
      )}
      {isPopUpValue === 'CONFIRMATIONPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'scale'}
          headerOneBadgeTwo={'create'}
          onClickYes={onClickYes}
        />
      )}

{isPopUpValue === 'TENURESATRTDATEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tenure'}
          labelBadgeOne={'start'}
          actualLableValue={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'scale'}
          headerOneBadgeTwo={'information'}
          basicInfo={tenureStartDate}
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={SET_SCALE_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TENUREENDDATEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tenure'}
          labelBadgeOne={'end'}
          actualLableValue={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'scale'}
          headerOneBadgeTwo={'information'}
          basicInfo={tenureEndDate}
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={SET_SCALE_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'STATUSPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentStatus'}
          label={'status'}
          listSelect={[
            { id: 'Active', name: 'Active' },
            { id: 'Archived', name: 'Archived' },
            { id: 'Inactive', name: 'Inactive' },
            { id: 'Suspended', name: 'Suspended' },
            { id: 'Terminated', name: 'Terminated' }
          ]}
          mappingValue={'id'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'scale'}
          headerOneBadgeTwo={'information'}
          isRequired={true}
          basicInfo={''}
          nextPopUpValue={''}
          typeOfSetObject={SET_SCALE_REDUCER_STATE}
          handleNextPopupValue={() => {}}
          isNotRevised={true}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TAGREADONLYPRIMARYPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tag'}
          labelBadgeOne={'primary'}
          actualLableValue={'id'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'scale'}
          headerOneBadgeTwo={'information'}
          basicInfo={ responseObject}
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={SET_SCALE_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TAGSECONDARYPOPUP' && (
        <PopUpTagSecondary
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'scale'}
          headerOneBadgeTwo={'information'}
          tagSecondary={'' || {}}
          signInSetup={'' || {}}
          nextPopUpValue={''}
          typeOfSetObject={SET_SCALE_REDUCER_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
    </div>
  );
};

export default PopUpScaleCreate;
