import React from "react";
import { isMobile } from "react-device-detect";
// import AllocationAccordian from '../Accordian/AllocationAccordian';
import Manuscript from "@material-ui/icons/Description";
import { useDispatch, useSelector } from "react-redux";
import DisplayPanelAccordianReviewListOne from "../Accordian/DisplayPanelAccordianReviewListOne";
import DisplayPanelAccordianInformation from "../Accordian/DisplayPanelAccordianInformation";
import { Paper } from "@material-ui/core";
import {
  ASSESSEE_SIGN_ON,
  GET_ASSESSEE_GROUP_REVIEW_LIST_SAGA,
  GET_ASSESSEE_TYPE_REVIEW_LIST_SAGA,
  INTERNAL_NODE_LIST_WITH_PERMISSIONS_SAGA,
  LOADER_START,
  SET_CORE_GROUP_REVIEW_LIST_REQ_OBJECT,
  SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT,
  SET_CORE_TYPE_REVIEW_LIST_REQ_OBJECT,
  SET_POPUP_VALUE,
  SET_STATUS_POPUP_VALUE,
} from "../../actionType";
import DisplayPanelAccordianReviewListTwo from "../Accordian/DisplayPanelAccordianReviewListTwo";
import { makeAssesseeGroupObj, makeAssesseeTypeObj, makeInternalNodeObj } from "../../Actions/GenericActions";

const DisplayPaneThreeSectionOneAssesseeGroup = () => {
  // const [listExpand, setListExpand] = useState('');
  const { responseObject, reviewMode, headerOne } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  const { informationEngagement, informationSetup, informationAllocation } =
    responseObject;
  const dispatch = useDispatch();
  const { selectedAssociateInfo, assignmentCreateType } = useSelector(
    (state) => state.DisplayPaneTwoReducer
  );

  // ascendant group show list code start
  //ascendant
  let ascendantAll = [];
  // check in information allocation
  if (
    informationAllocation?.assesseeGroupAscendant?.assesseeGroupAscendantAll &&
    informationAllocation?.assesseeGroupAscendant?.assesseeGroupAscendantAll
      .length > 0
  ) {
    let tempArr =
      informationAllocation?.assesseeGroupAscendant?.assesseeGroupAscendantAll;
    tempArr.forEach((ob) => {
      ascendantAll.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.assesseeGroupName || "",
        textTwo: ob?.informationBasic?.assesseeGroupDescription || "",
        status: ob?.informationEngagement?.assesseeGroupStatus || "",
      });
    });
  }
  let ascendantPrimary = [];
  // check in information allocation
  if (
    informationAllocation?.assesseeGroupAscendant
      ?.assesseeGroupAscendantPrimary &&
    informationAllocation?.assesseeGroupAscendant?.assesseeGroupAscendantPrimary
      .length > 0
  ) {
    //alphabetical order sorting by assesseeGroupName
    let tempArr =
      informationAllocation?.assesseeGroupAscendant
        ?.assesseeGroupAscendantPrimary;
    // .sort(
    //   (a, b) =>
    //     a.informationBasic?.assesseeGroupName.toLowerCase() !==
    //     b.informationBasic?.assesseeGroupName.toLowerCase()
    //       ? a.informationBasic?.assesseeGroupName.toLowerCase() <
    //         b.informationBasic?.assesseeGroupName.toLowerCase()
    //         ? -1
    //         : 1
    //       : 0
    // );
    // informationAllocation?.assesseeGroupAscendant?.assesseeGroupAscendantPrimary?.sortingListInAsc(ascendantPrimary,'assesseeGroupName');
    tempArr.forEach((ob) => {
      ascendantPrimary.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.assesseeGroupName || "",
        textTwo: ob?.informationBasic?.assesseeGroupDescription || "",
        status: ob?.informationEngagement?.assesseeGroupStatus || "",
      });
    });
  }
  let ascendantSecondary = [];
  if (
    informationAllocation?.assesseeGroupAscendant
      ?.assesseeGroupAscendantSecondary &&
    informationAllocation?.assesseeGroupAscendant
      ?.assesseeGroupAscendantSecondary.length > 0
  ) {
    let tempArr =
      informationAllocation?.assesseeGroupAscendant
        ?.assesseeGroupAscendantSecondary;
    tempArr.forEach((ob) => {
      ascendantSecondary.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.assesseeGroupName || "",
        textTwo: ob?.informationBasic?.assesseeGroupDescription || "",
        status: ob?.informationEngagement?.assesseeGroupStatus || "",
      });
    });
  }
  // ascendant group show list code end

  // descendant group show list code start

  let descendantAll = [];
  if (
    informationAllocation?.assesseeGroupDescendant
      ?.assesseeGroupDescendantAll &&
    informationAllocation?.assesseeGroupDescendant?.assesseeGroupDescendantAll
      .length > 0
  ) {
    let tempArr =
      informationAllocation?.assesseeGroupDescendant
        ?.assesseeGroupDescendantAll;
    tempArr.forEach((ob) => {
      descendantAll.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.assesseeGroupName || "",
        textTwo: ob?.informationBasic?.assesseeGroupDescription || "",
        status: ob?.informationEngagement?.assesseeGroupStatus || "",
      });
    });
  }
  let descendantPrimary = [];
  if (
    informationAllocation?.assesseeGroupDescendant
      ?.assesseeGroupDescendantPrimary &&
    informationAllocation?.assesseeGroupDescendant
      ?.assesseeGroupDescendantPrimary.length > 0
  ) {
    let tempArr =
      informationAllocation?.assesseeGroupDescendant
        ?.assesseeGroupDescendantPrimary;
    tempArr.forEach((ob) => {
      descendantPrimary.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.assesseeGroupName || "",
        textTwo: ob?.informationBasic?.assesseeGroupDescription || "",
        status: ob?.informationEngagement?.assesseeGroupStatus || "",
      });
    });
  }

  let descendantSecondary = [];
  if (
    informationAllocation?.assesseeGroupDescendant
      ?.assesseeGroupDescendantSecondary &&
    informationAllocation?.assesseeGroupDescendant
      ?.assesseeGroupDescendantSecondary.length > 0
  ) {
    let tempArr =
      informationAllocation?.assesseeGroupDescendant
        ?.assesseeGroupDescendantSecondary;
    tempArr.forEach((ob) => {
      descendantSecondary.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.assesseeGroupName || "",
        textTwo: ob?.informationBasic?.assesseeGroupDescription || "",
        status: ob?.informationEngagement?.assesseeGroupStatus || "",
      });
    });
  }

  // descendant group show list code end

  // node primary list
  let nodePrimary = [];
  if (
    informationAllocation?.assesseeGroupNode?.assesseeGroupNodePrimary &&
    informationAllocation?.assesseeGroupNode?.assesseeGroupNodePrimary.length >
      0
  ) {
    let tempArr =
      informationAllocation?.assesseeGroupNode?.assesseeGroupNodePrimary;
    tempArr.forEach((ob) => {
      nodePrimary.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.associateNodeName || "",
        textTwo: ob?.informationBasic?.associateNodeDescription || "",
        status: ob?.informationEngagement?.associateNodeStatus || "",
      });
    });
  }

  // node secondary list
  let nodeSecondary = [];
  if (
    informationAllocation?.assesseeGroupNode?.assesseeGroupNodeSecondary &&
    informationAllocation?.assesseeGroupNode?.assesseeGroupNodeSecondary
      .length > 0
  ) {
    let tempArr =
      informationAllocation?.assesseeGroupNode?.assesseeGroupNodeSecondary;
    tempArr.forEach((ob) => {
      nodeSecondary.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.associateNodeName || "",
        textTwo: ob?.informationBasic?.associateNodeDescription || "",
        status: ob?.informationEngagement?.associateNodeStatus || "",
      });
    });
  }
  let assesseeGroupTypeListPrimary = [];
  if (
    informationAllocation?.assesseeGroupType?.assesseeGroupTypePrimary &&
    informationAllocation?.assesseeGroupType?.assesseeGroupTypePrimary.length > 0
  ) {
    const tempArr = informationAllocation?.assesseeGroupType?.assesseeGroupTypePrimary;
    tempArr.forEach((ob) => {
      assesseeGroupTypeListPrimary.push({
        id: ob?.id + '-' || '',
        textOne: ob?.informationBasic?.assesseeTypeName || '',
        textTwo: ob?.informationBasic?.assesseeTypeDescription || '',
        status: ob?.informationEngagement?.assesseeTypeStatus || ''
      });
    });
  }
  let assesseeGroupTypeListSecondary = [];
  if (
    informationAllocation?.assesseeGroupType?.assesseeGroupTypeSecondary &&
    informationAllocation?.assesseeGroupType?.assesseeGroupTypeSecondary.length > 0
  ) {
    const tempArr = informationAllocation?.assesseeGroupType?.assesseeGroupTypeSecondary;
    tempArr.forEach((ob) => {
      assesseeGroupTypeListSecondary.push({
        id: ob?.id + '-' || '',
        textOne: ob?.informationBasic?.assesseeTypeName || '',
        textTwo: ob?.informationBasic?.assesseeTypeDescription || '',
        status: ob?.informationEngagement?.assesseeTypeStatus || ''
      });
    });
  }

  function capitalizeFirstLetter(string) {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  const engagementList = [
    {
      id: "a1",
      labelTextOneOne: "log",
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: "all",
          textOne: "",
        },
        {
          labelTextOneOneBadge: "key",
          textOne: "",
        },
      ],
      innerAssociateList: [],
      innerInfo: "assessees",
      isListCard: false,
      IconOne: Manuscript,
    },
    {
      id: "a2",
      textOneOne:
        capitalizeFirstLetter(informationEngagement?.assesseeGroupStatus) ||
        "No Information",
      labelTextOneOne: "status",
      innerAssociateList: [],
      innerInfo: "No Information",
      isListCard: false,
    },
    {
      id: "a3",
      labelTextOneOne: "tag",
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: "primary",
          textOne:
            informationEngagement?.assesseeGroupTag?.assesseeGroupTagPrimary ||
            "No Information",
        },
        {
          labelTextOneOneBadge: "secondary",
          textOne:
            informationEngagement?.assesseeGroupTag
              ?.assesseeGroupTagSecondary || "No Information",
        },
      ],
      innerAssociateList: [],
      innerInfo: "No Information",
      isListCard: false,
    },
    {
      id: "a4",
      labelTextOneOne: "tenure",
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: "start",
          textOne:
            informationEngagement?.assesseeGroupTenure
              ?.assesseeGroupTenureDateTimeStart || "No Information",
        },
        {
          labelTextOneOneBadge: "end",
          textOne:
            informationEngagement?.assesseeGroupTenure
              ?.assesseeGroupTenureDateTimeEnd || "No Information",
        },
      ],
      innerAssociateList: [],
      innerInfo: "Assessee",
      isListCard: false,
    },
  ];
  const allocationList = [
    {
      id: "a1",
      labelTextOneOne: "group",
      labelTextOneOneBadgeOne: "primary",
      labelTextOneOneBadgeTwo: "secondary",
      labelTextOneOneBadgeThree: "",
      labelTextOneOneBadgeFour: "",
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: "ascendant",
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: "all",
              innerList: ascendantAll.sort((a, b) =>
                a.textOne.toLowerCase() !== b.textOne.toLowerCase()
                  ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
                    ? -1
                    : 1
                  : 0
              ),
            },
            {
              labelTextTwoBadge: "primary",
              // innerList:ascendantPrimary
              innerList: ascendantPrimary.sort((a, b) =>
                a.textOne.toLowerCase() !== b.textOne.toLowerCase()
                  ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
                    ? -1
                    : 1
                  : 0
              ),
            },
            {
              labelTextTwoBadge: "secondary",
              innerList: ascendantSecondary.sort((a, b) =>
                a.textOne.toLowerCase() !== b.textOne.toLowerCase()
                  ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
                    ? -1
                    : 1
                  : 0
              ),
            },
          ],
        },
        {
          labelTextOneOneBadge: "descendant",
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: "all",
              innerList: descendantAll.sort((a, b) =>
                a.textOne.toLowerCase() !== b.textOne.toLowerCase()
                  ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
                    ? -1
                    : 1
                  : 0
              ),
            },
            {
              labelTextTwoBadge: "primary",
              innerList: descendantPrimary.sort((a, b) =>
                a.textOne.toLowerCase() !== b.textOne.toLowerCase()
                  ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
                    ? -1
                    : 1
                  : 0
              ),
            },
            {
              labelTextTwoBadge: "secondary",
              innerList: descendantSecondary.sort((a, b) =>
                a.textOne.toLowerCase() !== b.textOne.toLowerCase()
                  ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
                    ? -1
                    : 1
                  : 0
              ),
            },
          ],
        },
      ],
      innerInfo: "No Information",
      isListCard: true,
      isReviewLink: false,
      isMultiList: true, //multi listoption on click labelTextOneOne
    },
    {
      id: "a3",
      labelTextOneOne: "node",
      labelTextOneOneBadgeOne: "primary",
      labelTextOneOneBadgeTwo: "secondary",
      labelTextOneOneBadgeThree: "",
      labelTextOneOneBadgeFour: "",
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'primary',
          innerList: nodePrimary.sort((a, b) =>
            a.textOne.toLowerCase() !== b.textOne.toLowerCase()
              ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
                ? -1
                : 1
              : 0
          )
        },
        {
          labelTextOneOneBadge: 'secondary',
          innerList: nodeSecondary.sort((a, b) =>
            a.textOne.toLowerCase() !== b.textOne.toLowerCase()
              ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
                ? -1
                : 1
              : 0
          )
        }
      ],
      innerInfo: "No Information",
      isListCard: true,
    },
    {
      id: "a4",
      labelTextOneOne: 'type',
      labelTextOneOneBadgeOne: "primary",
      labelTextOneOneBadgeTwo: "secondary",
      labelTextOneOneBadgeThree: "",
      labelTextOneOneBadgeFour: "",
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: "primary",
          innerList: assesseeGroupTypeListPrimary.sort((a, b) =>
          a.textOne.toLowerCase() !== b.textOne.toLowerCase()
            ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
              ? -1
              : 1
            : 0
        ),
        },
        {
          labelTextOneOneBadge: "secondary",
          innerList: assesseeGroupTypeListSecondary.sort((a, b) =>
          a.textOne.toLowerCase() !== b.textOne.toLowerCase()
            ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
              ? -1
              : 1
            : 0
        ),
        },
      ],
      innerInfo: "No Information",
      isListCard: true,
    },
  ];
  const setupList = [
    {
      id: "classificationa1",
      textOneOne:
        informationSetup?.assesseeGroupClassification
          ?.assesseeGroupClassificationPrimary || "No Information",
      labelTextOneOne: "classification",
      innerAssociateList: [],
      innerInfo: "No Information",
      isListCard: false,
    },
    {
      id: 'setupList1',
      labelTextOneOne: 'fee',
      labelTextOneOneBadges: 
      [
        {
          labelTextOneOneBadge: 'move',
          textOne: informationSetup?.assesseeGroupMoveFee || 0
        },
        {
          labelTextOneOneBadge: 'sell',
          textOne: informationSetup?.assesseeGroupSellFee || 0
        },
        {
          labelTextOneOneBadge: 'share',
          textOne: informationSetup?.assesseeGroupShareFee ||  0
        }
      ],
      innerInfo: 'No',
      isListCard: false,
      isReviewLink: false,
      isMultiInfoCard: false
    }
  ];
  const reviseAllocation = (e) => {
    const labelName = e.currentTarget.getAttribute("data-value");
    const selectedBadgeName = e.currentTarget.getAttribute("data-key");
    const innerSelectedBadgeName = e.currentTarget.getAttribute("id");
    console.log("=====>", labelName, selectedBadgeName, innerSelectedBadgeName);
    //group click open popup
    if (labelName === "group") {
      if (
        selectedBadgeName === "ascendant" &&
        innerSelectedBadgeName === "primary"
      ) {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: "GROUPLISTPOPUP",
            popupMode: "assesseesGROUPCREATE",
            popupHeaderOne: headerOne
          },
        });
        let requestObj = makeAssesseeGroupObj(
          selectedAssociateInfo,
          "active",
          0,
          -1,
          'list',
          assignmentCreateType
        );
        dispatch({
          type: SET_CORE_GROUP_REVIEW_LIST_REQ_OBJECT,
          payload: requestObj,
        });
        dispatch({ type: LOADER_START });
        dispatch({
          type: GET_ASSESSEE_GROUP_REVIEW_LIST_SAGA,
          payload: {
            request: requestObj,
            BadgeOne: "",
            BadgeTwo: "",
            BadgeThree: "",
            isMiddlePaneList: false,
          },
        });
      }
    }
    //
    if (labelName === "node") {
      if (selectedBadgeName === "primary") {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: "NODELISTPOPUP",
            popupMode: "assesseesGROUPCREATE",
            popupHeaderOne: headerOne
          },
        });
        let nodeRequestObj = makeInternalNodeObj(selectedAssociateInfo, 'active', 0, -1, '', 'Associate-Self-Node-List-Create');
        dispatch({ type: SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT, payload: nodeRequestObj });
        console.log('nodeRequestObj',nodeRequestObj);

        dispatch({ type: LOADER_START });
        dispatch({
          type: INTERNAL_NODE_LIST_WITH_PERMISSIONS_SAGA,
          payload: {
            request: nodeRequestObj,
            BadgeOne: '',
            BadgeTwo: '',
            BadgeThree: '',
            nodeViewState: 'list',
            isMiddlePaneList: false
          }
        });
      }
      if (selectedBadgeName === "secondary") {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: "NODESECONDARYLISTPOPUP",
            popupMode: "assesseesGROUPCREATE",
            popupHeaderOne: headerOne
          },
        });
        let nodeObj = makeInternalNodeObj(selectedAssociateInfo, 'active', 0, -1, '', 'Associate-Self-Node-List-Create');
        dispatch({ type: SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT, payload: nodeObj });
        console.log('nodeObj',nodeObj);
        dispatch({ type: LOADER_START });
        dispatch({
          type: INTERNAL_NODE_LIST_WITH_PERMISSIONS_SAGA,
          payload: {
            request: nodeObj,
            BadgeOne: '',
            BadgeTwo: '',
            BadgeThree: '',
            nodeViewState: 'list',
            isMiddlePaneList: false
          }
        });
      }
    }
    if (labelName === "type") {
      let typeRequestObj = makeAssesseeTypeObj(selectedAssociateInfo, 'all', 0, -1);
      dispatch({ type: SET_CORE_TYPE_REVIEW_LIST_REQ_OBJECT, payload: typeRequestObj });
      dispatch({ type: LOADER_START });
      dispatch({
        type: GET_ASSESSEE_TYPE_REVIEW_LIST_SAGA,
        payload: {
          request: typeRequestObj,
          BadgeOne: '',
          BadgeTwo: '',
          BadgeThree: '',
          isMiddlePaneList: false,
          isReviseMode: true
        }
      });
      if (selectedBadgeName === "primary") {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: "TYPELISTPOPUP",
            popupMode: "assesseesGROUPCREATE",
            popupHeaderOne: headerOne
          },
        });
      }
      if (selectedBadgeName === "secondary") {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: "TYPESECONDARYLISTPOPUP",
            popupMode: "assesseesGROUPCREATE",
            popupHeaderOne: headerOne
          },
        });
      }
    }
  };
  const reviseEngagement = (e) => {
    const labelName = e.currentTarget.getAttribute("data-value");
    const selectedBadgeName = e.currentTarget.getAttribute("data-key");
    console.log("=====>", labelName);
    if (labelName === "status") {
      dispatch({
        type: SET_STATUS_POPUP_VALUE,
        payload: capitalizeFirstLetter(
          informationEngagement?.assesseeGroupStatus
        ),
      });
      dispatch({
        type: ASSESSEE_SIGN_ON,
        payload: {
          isPopUpValue: "STATUSPOPUP",
          popupMode: "assesseesGROUPCREATE",
            popupHeaderOne: headerOne
        },
      });
    }
    if (labelName === "tag") {
      if (selectedBadgeName === "primary") {
        dispatch({
          type: ASSESSEE_SIGN_ON,
          payload: {
            isPopUpValue: "TAGREADONLYPRIMARYPOPUP",
            popupMode: "assesseesGROUPCREATE",
            popupHeaderOne: headerOne
          },
        });
      }
      if (selectedBadgeName === "secondary") {
        dispatch({
          type: ASSESSEE_SIGN_ON,
          payload: {
            isPopUpValue: "TAGSECONDARYPOPUP",
            popupMode: "assesseesGROUPCREATE",
            popupHeaderOne: headerOne
          },
        });
      }
    }
    if (labelName === "tenure") {
      if (selectedBadgeName === "start") {
        dispatch({
          type: ASSESSEE_SIGN_ON,
          payload: {
            isPopUpValue: "TENURESATRTDATEPOPUP",
            popupMode: "assesseesGROUPCREATE",
            popupHeaderOne: headerOne
          },
        });
      }
      if (selectedBadgeName === "end") {
        dispatch({
          type: ASSESSEE_SIGN_ON,
          payload: {
            isPopUpValue: "TENUREENDDATEPOPUP",
            popupMode: "assesseesGROUPCREATE",
            popupHeaderOne: headerOne
          },
        });
      }
    }
  };

  const reviseClassification = (e,selectedBadgeArray) => {
    const labelName = e.currentTarget.getAttribute("data-value");
    const selectedBadgeName = e.currentTarget.getAttribute("data-key");
    console.log('=====>', labelName);
    let badgeName = '';
    if (selectedBadgeArray) {
      if (selectedBadgeArray.length > 0) {
        selectedBadgeArray.forEach((element) => {
          badgeName = badgeName + element.labelTextTwoBadge;
        });
      }
    }
    console.log('=====>', badgeName);
    if (labelName === "classification") {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: "CLASSIFICATIONLISTPOPUP",
          popupMode: "assesseesGROUPCREATE",
            popupHeaderOne: headerOne
        },
      });
    }
    if (labelName ==='fee' && selectedBadgeName ==='move'){
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'GROUPSASSOCIATEMOVEFEEPOPUP', popupMode: 'assesseesGROUPCREATE' }
      });
    }
    if (labelName ==='fee' && selectedBadgeName ==='sell'){
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'GROUPSASSOCIATESELLFEEPOPUP', popupMode: 'assesseesGROUPCREATE' }
      });
    }
    if (labelName ==='fee' && selectedBadgeName ==='share'){
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'GROUPSASSOCIATESHAREFEEPOPUP', popupMode: 'assesseesGROUPCREATE' }
      });
    }
  };

  return (
    <div>
      <>
        <div className="containerPadding">
          <Paper className={"dossierContainerTop"}>
            {allocationList.map((ob) => {
              return (
                <div key={ob.id}>
                  {ob.isListCard ? (
                    <>
                      {ob.isMultiList ? (
                        <DisplayPanelAccordianReviewListTwo
                          onClickReview={null}
                          onClickRevise={reviseAllocation}
                          accordianObject={ob}
                          mode={reviewMode}
                        />
                      ) : (
                        <DisplayPanelAccordianReviewListOne
                          onClickRevise={reviseAllocation}
                          className=""
                          accordianObject={ob}
                          mode={reviewMode}
                        />
                      )}
                    </>
                  ) : (
                    <DisplayPanelAccordianInformation
                      onClickRevise={reviseAllocation}
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  )}
                </div>
              );
            })}
          </Paper>
        </div>
        <div className="containerPadding">
          <Paper className={"dossierContainerTop"}>
            {engagementList.map((ob) => {
              return (
                <div key={ob.id}>
                  {ob.isListCard ? (
                    <DisplayPanelAccordianReviewListOne
                      onClickRevise={reviseEngagement}
                      className=""
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  ) : (
                    <DisplayPanelAccordianInformation
                      onClickRevise={reviseEngagement}
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  )}
                </div>
              );
            })}
          </Paper>
        </div>
        <div className="containerPadding">
          <Paper className={"dossierContainerTop"}>
            {setupList.map((ob) => {
              return (
                <div key={ob.id}>
                  {ob.isListCard ? (
                    <DisplayPanelAccordianReviewListOne
                      onClickRevise={reviseClassification}
                      className=""
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  ) : (
                    <DisplayPanelAccordianInformation
                      onClickRevise={reviseClassification}
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  )}
                </div>
              );
            })}
          </Paper>
        </div>
      </>
      {isMobile && (
        <div className={"containerPadding"} style={{ height: "55px" }}>
          {" "}
        </div>
      )}
    </div>
  );
};

export default DisplayPaneThreeSectionOneAssesseeGroup;
