import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ASSESSEE_INFO_CREATE,
  ASSOCIATE_POPUP_CLOSE,
  CLEAR_DISPLAY_PANE_THREE,
  CULTUREPROFILE_ALLOCATE_ASSIGNMENT_SAGA,
  CULTUREPROFILE_ALLOCATE_SAGA,
  FILTERMODE,
  FILTERMODE_ENABLE,
  GET_CULTUREPROFILE_GROUP_REVIEW_LIST_SAGA,
  LOADER_START,
  POPUP_OPEN,
  SET_DISPLAY_PANE_THREE_STATE,
  SECTION_POPUP_CLOSE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_PAGE_COUNT,
  SET_POPUP_SINGLE_STATE,
  SET_POPUP_STATE,
  SET_REQUEST_OBJECT
} from '../actionType';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import { FilterList } from '@material-ui/icons';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import {
  ASSESSEE_GROUP_REVIEW_LIST_POPUP_OPTION,
  GROUP_REVIEW_LIST_POPUP_OPTION
} from '../PopUpConfig';
import { getCultureProfileGroupApiCall, getMarketPlaceCultureProfileGroupApiCall } from '../Actions/ActionCultureProfile';
import {
  onClickCheckBoxSelection,
  onClickFlagSelection,
  setFlagedArray
} from '../Actions/AssesseeModuleAction';
import ReviseIcon from '@material-ui/icons/RadioButtonChecked';
import Check from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { getPopUpOptionList, makeCultureProfileGroupObj } from '../Actions/GenericActions';
import { AccountTree } from '@material-ui/icons';
import ListIcon from '@material-ui/icons/FormatListBulleted';
import FileExplorerTheme from 'react-sortable-tree-theme-full-node-drag';
import SortableTree from 'react-sortable-tree';
import ListLoader from '../Atoms/ListLoader/ListLoader';

const MarketPlaceCultureProfileGroupReviewList = (props) => {
  const dispatch = useDispatch();
  // const { secondaryOptionCheckValue } = useSelector((state) => state.AssesseeCreateReducer);
  const { cardValue } = useSelector((state) => state.PopUpReducer);
  const {
    numberPage,
    scanCount,
    countPage,
    reviewListDistinctData,
    reviewListReqObj,
    middlePaneSelectedValue,
    selectedAssociateInfo,
    middlePaneHeader,
    isSelectActive,
    selectedTagsArray,
    unselectedTagsArray,
    allocateStr,
    scanString,
    searchFocusIndex,
    allocatedTagsArray,
    middlePaneHeaderBadgeOne,
    middlePaneHeaderBadgeTwo,
    middlePaneHeaderBadgeThree,
    selectedFlagedArray,
    unselectedFlagedArray,
    flagedValue,
    nodeViewState,
    signedAssesseePermission,
    assignmentCreateType,
    isListLoading
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { FilterModeEnable, FilterMode } = useSelector((state) => state.FilterReducer);
  // const [isFetching, setIsFetching] = useState(false);
  const [filterState, setFilterState] = useState('');
  // useEffect(() => {
  //   document.getElementById('middleComponentId').addEventListener('scroll', handleScroll);
  // }, []);
  // const handleScroll = (event) => {
  //   var targetPt = event.target;
  //   if (
  //     Math.ceil(targetPt.scrollHeight - targetPt.scrollTop) !== targetPt.clientHeight ||
  //     isFetching
  //   )
  //     return;
  //   setIsFetching(true);
  //   console.log(isFetching);
  // };
  const fetchData = async () => {
    if (reviewListDistinctData.length < scanCount && nodeViewState === 'list') {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isListLoading', value: true }
      });
      let obj = {
        ...reviewListReqObj.requestObject,
        numberPage: numberPage + 1
      };
      dispatch({
        type: GET_CULTUREPROFILE_GROUP_REVIEW_LIST_SAGA,
        payload: {
          request: { module: 'Culture-Profile-Group', action: 'Review-List', actionFilter: 'Culture-Profile-Shared-Group'  , requestObject: obj },
          //obj,
          // request: { module: 'Culture-Profile-Group', action: 'Review-List', requestObject: { obj } },
          BadgeOne: middlePaneHeaderBadgeOne,
          BadgeTwo: middlePaneHeaderBadgeTwo,
          BadgeThree: middlePaneHeaderBadgeThree,
          isMiddlePaneList: true,
          cardValue: cardValue,
          isSelectActive: isSelectActive,
          selectedTagsArray: selectedTagsArray
        }
      });
      dispatch({ type: SET_PAGE_COUNT, payload: numberPage + 1 });
    }
  };
  // useEffect(() => {
  //   console.log(reviewListDistinctData);
  //   if (!isFetching) return;
  //   fetchMoreListItems();
  // }, [isFetching]);

  // const fetchMoreListItems = () => {
  //   fetchData();
  //   setIsFetching(false);
  // };
  useEffect(() => {
    if (nodeViewState === 'list')
      setFlagedArray(reviewListDistinctData, 'cultureProfileGroupFlag', dispatch);
  }, [reviewListDistinctData]);

  // const siftApiCall = (siftKey) => {
  //   // getCultureProfileGroupApiCall(selectedAssociateInfo, siftKey, countPage,dispatch, cardValue,'groups',isSelectActive,selectedTagsArray);
  //   dispatch({ type: ASSOCIATE_POPUP_CLOSE });
  //   document.getElementById('middleComponentId').scrollTop = '0px';
  // };

  const siftApiFilterCall = (siftKey, requestObj) => {
    //getCultureProfileGroupApiCall(selectedAssociateInfo, siftKey, countPage, dispatch, 'groups');
    // let requestObj = makeCultureProfileGroupObj(selectedAssociateInfo, siftKey, 0, countPage);
    dispatch({ type: LOADER_START });
    // dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
    dispatch({
      type: FILTERMODE,
      payload: { FilterMode: 'cultureProfileGroup' + siftKey }
    });
    dispatch({
      type: GET_CULTUREPROFILE_GROUP_REVIEW_LIST_SAGA,
      payload: {
        request: requestObj,
        BadgeOne: middlePaneHeaderBadgeOne,
        BadgeTwo: middlePaneHeaderBadgeTwo === 'distinct' ? middlePaneHeaderBadgeTwo : siftKey,
        BadgeThree: middlePaneHeaderBadgeTwo === 'distinct' ? siftKey : middlePaneHeaderBadgeThree,
        isMiddlePaneList: true,
        cardValue: cardValue,
        isSelectActive: isSelectActive,
        selectedTagsArray: selectedTagsArray
      }
    });
    dispatch({
      type: SET_DISPLAY_PANE_THREE_STATE,
      payload: {
        isReviewRevise: false,
        headerOne: '',
        headerOneBadgeOne: '',
        headerOneBadgeTwo: '',
        headerOneBadgeThree: '',
        reviewMode: 'review'
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'typeOfMiddlePaneList', value: '' }
    });
    dispatch({ type: ASSOCIATE_POPUP_CLOSE });
    // document.getElementById('middleComponentId').scrollTop = '0px';
  };

  const onClickFooter = (e) => {
    let siftValue = e.currentTarget.getAttribute('data-value');
    dispatch({ type: FILTERMODE_ENABLE });
    setFilterState(siftValue);
    if (
      (siftValue === 'suspended' ||
        siftValue === 'terminated' ||
        siftValue === 'bespoke' ||
        siftValue === 'generic') &&
      nodeViewState === 'list'
    ) {
      let requestObect = makeCultureProfileGroupObj(
        selectedAssociateInfo,
        siftValue,
        countPage,
        0,
        'list',
        assignmentCreateType,
        'Culture-Profile-Shared-Group'
      );
      dispatch({ type: SET_PAGE_COUNT, payload: 0 });
      dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'scanString', value: '' }
      });
      siftApiFilterCall(siftValue, requestObect);
    }
    if ((siftValue === 'bespoke' || siftValue === 'generic') && nodeViewState === 'hierarchy') {
      let requestObect = makeCultureProfileGroupObj(
        selectedAssociateInfo,
        siftValue,
        countPage,
        0,
        nodeViewState,
        assignmentCreateType,
        'Culture-Profile-Shared-Group'
      );
      dispatch({ type: SET_PAGE_COUNT, payload: 0 });
      dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'scanString', value: '' }
      });
      siftApiFilterCall(siftValue, requestObect);
    }

    // if (siftValue === 'suspended' || siftValue === 'terminated') siftApiCall(siftValue);
    // if (siftValue === 'bespoke' || siftValue === 'generic') siftApiFilterCall(siftValue);

    if (siftValue === 'list' || siftValue === 'hierarchy') {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'scanString', value: '' }
      });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'nodeViewState', value: siftValue }
      });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'typeOfMiddlePaneList', value: '' }
      });
      let badgeTwo =
        middlePaneHeaderBadgeTwo === 'generic' || middlePaneHeaderBadgeTwo === 'bespoke'
          ? 'active'
          : middlePaneHeaderBadgeTwo;
      if (cardValue === 'Card') {
        badgeTwo =
          middlePaneHeaderBadgeThree === 'generic' || middlePaneHeaderBadgeThree === 'bespoke'
            ? 'active'
            : middlePaneHeaderBadgeThree;
      }
      dispatch({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'selectionValue', value: '' }
      });
      getMarketPlaceCultureProfileGroupApiCall(
        selectedAssociateInfo,
        badgeTwo,
        countPage,
        dispatch,
        'groups',
        cardValue, // 'noCard',
        '',
        [],
        siftValue,
        false,
        assignmentCreateType,
        'Culture-Profile-Shared-Group'
      );
    }

    if (siftValue === 'finish') {
      console.log('allocateStr', allocateStr);
      let distinctAllocateStr =
        allocateStr === 'culture-profilesdistinct'
          ? 'cultureProfileDistinct'
          : allocateStr === 'cultureprofilesdistinct'
          ? 'cultureProfileDistinct'
          : allocateStr === 'culture-profilesgroups'
          ? 'cultureProfileGroup'
          : allocateStr === 'culture-profilesnodes'
          ? 'cultureProfileNode'
          : allocateStr === 'culture-profilestypes'
          ? 'cultureProfileType'
          : allocateStr === 'job-profilesdistinct'
          ? 'jobProfileDistinct'
          : allocateStr === 'jobprofilesdistinct'
          ? 'jobProfileDistinct'
          : allocateStr === 'job-profilesgroups'
          ? 'jobProfileGroup'
          : allocateStr === 'assignmentsdistinct'
          ? 'assignmentDistinct'
          : allocateStr === 'assignmentsgroups'
          ? 'assignmentGroup'
          : allocateStr === 'assignmentstypes'
          ? 'assignmentType'
          : allocateStr === 'assignmentsnodes'
          ? 'assignmentNode'
          : '';
      if (distinctAllocateStr !== '' && selectedTagsArray.length !== 0) {
        if (
          distinctAllocateStr === 'assignmentDistinct' ||
          distinctAllocateStr === 'assignmentGroup' ||
          distinctAllocateStr === 'assignmentType' ||
          distinctAllocateStr === 'assignmentNode'
        ) {
          let request = {
            module: 'Culture-Profile-Distinct',
            action: FilterMode.toLowerCase().includes('unallocate') ? 'Unallocate' : 'Allocate',
            actionFilter: 'Culture-Profile-Assignment',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              cultureProfileDistinctAllocate: {
                cultureProfileGroup: selectedTagsArray
                //[distinctAllocateStr]: selectedTagsArray
              },
              cultureProfileDistinctAllocateInformation: {
                [distinctAllocateStr === 'assignmentDistinct' ? 'assignment' : distinctAllocateStr]:
                  allocatedTagsArray
              }
            }
          };
          dispatch({ type: LOADER_START });
          dispatch({
            type: CULTUREPROFILE_ALLOCATE_ASSIGNMENT_SAGA,
            payload: { request: request }
          });
        }
        if (
          distinctAllocateStr === 'cultureProfileDistinct' ||
          distinctAllocateStr === 'cultureProfileGroup' ||
          distinctAllocateStr === 'cultureProfileNode' ||
          distinctAllocateStr === 'cultureProfileType'
        ) {
          let request = {
            module: 'Culture-Profile-Distinct',
            action: FilterMode.toLowerCase().includes('unallocate') ? 'Unallocate' : 'Allocate',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              cultureProfileDistinctAllocate: {
                [distinctAllocateStr]: allocatedTagsArray
              },
              cultureProfileDistinctAllocateInformation: {
                cultureProfileGroup: selectedTagsArray
              }
            }
          };
          dispatch({ type: LOADER_START });
          dispatch({ type: CULTUREPROFILE_ALLOCATE_SAGA, payload: { request: request } });
        }
      }
      // let distinctAllocateStr = allocateStr === 'assesseesdistinct' ? 'assesseeDistinct' : '';
      // if (distinctAllocateStr !== '' && selectedTagsArray.length !== 0) {
      // if (distinctAllocateStr === 'assesseeDistinct') {
      // let request = {
      //   assesseeId: selectedAssociateInfo?.assesseeId,
      //   associateId:
      //     selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      //   cultureProfileDistinctAllocate: allocatedTagsArray,
      //   cultureProfileDistinctAllocateInformation: {
      //     cultureProfileGroup: selectedTagsArray
      //   }
      // };
      // dispatch({ type: LOADER_START });
      // dispatch({ type: CULTUREPROFILE_ALLOCATE_SAGA, payload: { request: request } });
      //   }
      // }
    }
    if (siftValue === 'cancel') {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isSelectActive', value: '' }
      });
      dispatch({
        type: FILTERMODE,
        payload: { FilterMode: '' }
      });
    }
  };
  /* for middle pane */
  const primaryIconOne = [{ label: 'sift', onClick: onClickFooter, Icon: FilterList }];
  const secondaryIconOne = [
    { label: 'suspended', onClick: onClickFooter, Icon: FilterList },
    { label: 'terminated', onClick: onClickFooter, Icon: FilterList }
  ];
  // const secondaryOneIcon = [
  //   { label: 'bespoke', onClick: onClickFooter, Icon: FilterList },
  //   { label: 'generic', onClick: onClickFooter, Icon: FilterList },
  //   // { label: 'shared', onClick: onClickFooter, Icon: FilterList },
  //   // { label: 'unshared', onClick: onClickFooter, Icon: FilterList }
  // ];
  const secondaryOneIconSift = [
    { label: 'bespoke', onClick: onClickFooter, Icon: FilterList },
    { label: 'generic', onClick: onClickFooter, Icon: FilterList }
  ];

  /* for middle pane */
  const primaryIcon = [
    // { label: 'sift', onClick: onClickFooter, Icon: FilterList },
    { label: 'view', onClick: onClickFooter, Icon: FilterList }
  ];
  const secondaryIcon = [
    { label: 'hierarchy', onClick: onClickFooter, Icon: AccountTree },
    { label: 'list', onClick: onClickFooter, Icon: ListIcon }
  ];

  let signedAssociateId =
    selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary;

  const openListPopup = (node, e, target, canUpdate) => {
    console.log(e.currentTarget.getAttribute('tag'));
    let tempArr = [];
    let classification = e.currentTarget.getAttribute('data-classification');
    let isShared = e.currentTarget.getAttribute('data-shared');
    let associateId = e.currentTarget.getAttribute('associateid');
    let status = e.currentTarget.getAttribute('status');
    let groupId = e.currentTarget.getAttribute('tag');
    let flaged = e.currentTarget.getAttribute('data-flag') === 'true' && true;
    console.log('node', node);
    if (target === 'hirarchy') {
      groupId = node.node.id;
      status = node.node.status;
      isShared = node.node.cultureProfileGroupShared;
      flaged = node.node.flag;
      classification = node.node.classification;
      associateId = node.node.associateId;
    }
    let reviseHeader = 'culture-profiles';
    let optArr = [];
    let popupContentArrValue = ASSESSEE_GROUP_REVIEW_LIST_POPUP_OPTION.map((obj) =>
      obj.data === 'assessees' ? { ...obj, data: middlePaneHeader, dataValue: reviseHeader } : obj
    );
    let cultureProfileDistictPermssion =
      signedAssesseePermission?.iGuruAnalyticDistinctPermission
        ?.assesseeiGuruAnalyticGroupPermission;
    let popupContentArr = getPopUpOptionList(
      cardValue === 'Card' ? GROUP_REVIEW_LIST_POPUP_OPTION : popupContentArrValue,
      cultureProfileDistictPermssion
    );
    popupContentArr.map((element) => {
      if (classification === 'Bespoke' && element.data === 'share' && status === 'ACTIVE')
        tempArr.push({ ...element, disabled: true });
      else if (
        (isShared === 'true' || isShared) &&
        associateId !== signedAssociateId &&
        (element.data === 'archive' ||
          element.data === 'delete' ||
          element.data === 'flag' ||
          element.data === 'publish' ||
          element.data === 'suspend' ||
          element.data === 'terminate')
      ) {
        tempArr.push({ ...element, disabled: true });
      } else if (
        (status === 'TERMINATED' || status === 'SUSPENDED' || status === 'ARCHIVED') &&
        (element.data === 'allocate' ||
          element.data === 'revise' ||
          element.data === 'select' ||
          element.data === 'share')
      ) {
        tempArr.push({ ...element, disabled: true });
      } else tempArr.push(element);
    });

    // dispatch({
    //   type: SET_POPUP_STATE,
    //   payload: {
    //     popupHeaderOne: middlePaneHeader,
    //     popupHeaderOneBadgeOne: 'group',
    //     popupHeaderOneBadgeTwo: '',
    //     duplicateHeaderOneBadgeTwo: middlePaneHeaderBadgeTwo,
    //     isPopUpValue: '',
    //     popupOpenType: 'primary',
    //     popupContentArrValue: tempArr,
    //     originPrimaryArrOprion: tempArr,
    //     //optArr,
    //     selectedTagValue: groupId, //e.currentTarget.getAttribute('tag'),
    //     selectedTagStatus: status, //e.currentTarget.getAttribute('status'),
    //     selectedTagGroupId: e.currentTarget.getAttribute('data-value'),
    //     isFlaged: flaged, //e.currentTarget.getAttribute('data-flag') === 'true' ? true : false,
    //     isShared: isShared,
    //     selectedTagInformationSetup: JSON.parse(e.currentTarget.getAttribute('informationsetup')),
    //   }
    // });
    // dispatch({
    //   type: SET_DISPLAY_TWO_SINGLE_STATE,
    //   payload: {
    //     stateName: 'middlePaneListPopupOptions',
    //     value: tempArr //tempArr
    //     //optArr
    //   }
    // });
    // dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
  };
  console.log('FilterMode', FilterMode);
  const changedNode = (node) => {
    console.log(node.id);
    let dragedNodeId = node.id;
    let dragedNodeParentId = '';
    reviewListDistinctData.map((nodeData) => {
      console.log('nodeData', nodeData);
      nodeData.children.map((chnode) => {
        console.log(chnode.id);
        if (dragedNodeId === chnode.id) {
          dragedNodeParentId = nodeData.id;
        }
      });
    });
  };
  const customSearchMethod = ({ node, searchQuery }) =>
    searchQuery &&
    (node.title?.toLowerCase().indexOf(searchQuery?.toLowerCase()) > -1 ||
      node.subtitle?.toLowerCase().indexOf(searchQuery?.toLowerCase()) > -1);

  // infinite scroll
  const [hasMore, setHasMore] = useState(true);
  let elementRef = useRef(null);

  const onIntersection = (entries) => {
    const firstEntry = entries[0];
    reviewListDistinctData.length >= scanCount ? setHasMore(false) : setHasMore(true);
    if (firstEntry.isIntersecting && hasMore && !isListLoading) {
      fetchData();
    }
  };

  useEffect(() => {
    // console.log('####MYREF######', elementRef)
    if (nodeViewState !== 'hierarchy') {
      const observer = new IntersectionObserver(onIntersection, { threshold: 0.90 });

      if (observer && elementRef.current) {
        // console.log('here at observer...', elementRef.current)
        observer.observe(elementRef.current);
      }
      // console.log(reviewListDistinctData.length, scanCount ,'##########')
      return () => {
        if (observer) observer.disconnect();
      };
    }
  }, [
    isListLoading,
    hasMore,
    reviewListDistinctData,
    isSelectActive,
    selectedTagsArray,
    unselectedTagsArray
  ]);

  // infinite scroll end

  return (
    <div>
      {reviewListDistinctData && nodeViewState === 'hierarchy' ? (
        <div style={{ minheight: 'calc(100vh - 135px)' }} key={scanString}>
          <SortableTree
            treeData={reviewListDistinctData}
            onChange={(treeData) => {
              treeData.length === 1 &&
                dispatch({
                  type: SET_DISPLAY_TWO_SINGLE_STATE,
                  payload: {
                    stateName: 'reviewListDistinctData',
                    value: treeData
                  }
                });
            }}
            searchMethod={customSearchMethod}
            searchQuery={scanString}
            searchFocusOffset={searchFocusIndex}
            canDrag={({ node }) => true && node.parentId !== null}
            onMoveNode={({ node }) => changedNode(node)}
            searchFinishCallback={(matches) => {
              console.log(matches);
              dispatch({
                type: SET_DISPLAY_TWO_SINGLE_STATE,
                payload: {
                  stateName: 'searchFocusIndex',
                  value: matches.length > 0 ? searchFocusIndex % matches.length : 0
                }
              });
            }}
            theme={FileExplorerTheme}
            isVirtualized={false}
            rowHeight={55}
            scaffoldBlockPxWidth={31}
            slideRegionSize={50}
            generateNodeProps={(node) => ({
              onClick: (event) => {
                if (event.target.type !== 'button' && node.node.title) {
                  openListPopup(node, event, 'hirarchy', true);
                  //  openListHierarchyPopup(node,event, 'hierarchy', true);
                }
              }
            })}
          />
        </div>
      ) : (
        reviewListDistinctData &&
        reviewListDistinctData.map((item, index) => {
          return (
            <div className="containerPadding" key={index} ref={elementRef}>
              <ReviewList
                className=""
                id={index}
                tag={item.id}
                associateId={item?.associateId}
                isSelectedReviewList={middlePaneSelectedValue === item.id}
                informationSetup={item?.informationSetup}
                //status={item.informationEngagement.cultureProfileGroupStatus}
                status={
                  FilterMode === 'cultureProfileGroupactive' ||
                  FilterMode === 'cultureProfileGroupbespoke' ||
                  FilterMode === 'cultureProfileGroupgeneric' ||
                  FilterMode === 'cultureProfileGroupactivebespoke' ||
                  FilterMode === 'cultureProfileGroupactivegeneric'
                    ? item.informationSetup?.cultureProfileGroupClassification
                        ?.cultureProfileGroupClassificationPrimary
                    : item.informationEngagement.cultureProfileGroupStatus
                }
                actualStatus={item.informationEngagement.cultureProfileGroupStatus}
                textOne={item.informationBasic.cultureProfileGroupName}
                textTwo={item.informationBasic.cultureProfileGroupDescription}
                isTooltipActive={false}
                onClickEvent={(event) => {
                  openListPopup(item.id, event, 'list', true);
                }}
                //onClickEvent={openListPopup}
                isSelectActive={isSelectActive}
                isSelected={selectedTagsArray.includes(item.id)}
                onClickCheckBox={(event) => {
                  !isListLoading &&
                    onClickCheckBoxSelection(
                      selectedTagsArray,
                      unselectedTagsArray,
                      event,
                      dispatch
                    );
                }}
                // disableonClickCheckBox={item.associateId !== signedAssociateId ? true : false}
                // dataValue={item.informationAllocation.cultureProfileGroup}
                isShared={item?.cultureProfileGroupShared}
                //shared={item.cultureProfileGroupShared ? 'SHARED' : 'UNSHARED'}
                shared={
                  item.informationSetup?.cultureProfileGroupClassification
                    ?.cultureProfileGroupClassificationPrimary
                }
                isFlagActive={selectedFlagedArray.includes(item.id)}
                flagedValue={flagedValue}
                onClickAddFladed={(event) => {
                  onClickFlagSelection(selectedFlagedArray, unselectedFlagedArray, event, dispatch);
                }}
              />
            </div>
          );
        })
      )}
      {isListLoading ? (
        <div>
          {' '}
          <ListLoader />{' '}
        </div>
      ) : <div style={{ height: "60px", width: "100%" }}></div>}
      {(FilterMode === 'cultureProfileGroupinactive' ||
        FilterMode === 'cultureProfileGroupsuspended' ||
        FilterMode === 'cultureProfileGroupterminated') && (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={primaryIconOne}
          secondaryIcon={secondaryIconOne}
        />
      )}
      {(FilterMode === 'cultureProfileGroupactive' ||
        FilterMode === 'cultureProfileGroupactivebespoke' ||
        FilterMode === 'cultureProfileGroupactivegeneric' ||
        FilterMode === 'cultureProfileGroupbespoke' ||
        FilterMode === 'cultureProfileGroupgeneric') && (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={primaryIcon}
          secondaryIcon={filterState === 'sift' ? secondaryOneIconSift : secondaryIcon}
        />
      )}
      {/* {(FilterMode === 'allocateToCultureProfileGroup' ||
        FilterMode === 'unAllocateToCultureProfileGroup') && (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={[
            {
              label: `${
                FilterMode === 'allocateToCultureProfileGroup' ? 'allocate' : 'unallocate'
              }`,
              onClick: onClickFooter,
              Icon: ReviseIcon
            }
          ]}
          secondaryIcon={[
            { label: 'cancel', onClick: onClickFooter, Icon: ClearIcon },
            { label: 'finish', onClick: onClickFooter, Icon: Check }
          ]}
        />
      )} */}
    </div>
  );
};
export default MarketPlaceCultureProfileGroupReviewList;
