import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PopupHeader from '../Molecules/PopUp/PopUpHeader';
import Popup from '../Molecules/PopUp/PopUp';
import '../Molecules/PopUp/PopUp.css';
import { DialogContent } from '@material-ui/core';
import JsonRenderComponent from '../Actions/JsonRenderComponent';
import {
  getAssesseeSelfAssignmentList,
  getAssociateDistinctListApiCall,
  getGuageDistinctApiCall,
  updateAssesseeAproveStatus,
  UpdateGaugeReviseStatus,
  updateReviseAssesseeAssignmentStatus
} from '../Actions/GenericActions';
import {
  POPUP_CLOSE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MIDDLEPANE_PREVIOUS_POPUP,
  SET_MIDDLEPANE_SECONDARY_OPTION,
  SET_SECONDARY_CREATE_OPTION_VALUE,
  FILTERMODE,
  ASSESSMENT_INFO_REVISE_SAGA,
  LOADER_START,
  SHARE_ROLES_TYPES_SAGA,
  CLEAR_TYPE_REDUCER_STATE,
  SET_POPUP_VALUE,
  SET_MIDDLEPANE_STATE,
  CLEAR_DISPLAY_PANE_THREE,
  CLEAR_ROLE_REDUCER_STATE,
  CLEAR_GROUP_REDUCER_STATE,
  SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT,
  INTERNAL_NODE_LIST_SAGA,
  CLEAR_NODE_REDUCER_STATE,
  SET_POPUP_SINGLE_STATE,
  CLEAR_ASSESSMENT_INFO,
  CLEAR_ASSIGNMENT_INFO,
  ASSOCIATE_POPUP_CLOSE,
  ASSOCIATE_CREATE_INFO,
  ASSESSEE_SIGN_ON,
  SHARE_GROUPS_ROLES_TYPES_SAGA,
  ASSESSMENT_CLUSTER_REVISE_SAGA,
  SHARE_DISTINCT_SAGA,
  SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
  SET_MOBILE_PANE_STATE,
  SET_REQUEST_OBJECT,
  SET_PAGE_COUNT,
  CREATE_GAUGE_SAGA,
  CLEAR_GRADE_REDUCER_STATE,
  CLEAR_NORM_REDUCER_STATE,
  CLEAR_CLUSTER_REDUCER_STATE,
  CLEAR_GUAGE_REDUCER_STATE,
  SET_POPUP_STATE,
  SET_MIDDLEPANE_REVIEW_LIST_PREVIOUS_POPUP,
  INTERNAL_NODE_LIST_WITH_PERMISSIONS_SAGA
} from '../actionType';
import {
  assesseeCreateApiCalls,
  getAdminManagerDistinctApiCall,
  getAdminManagerRoleApiCall,
  getAssesseeAllocateTypeApiCall,
  getAssesseeDistinctApiCall,
  getAssesseeGroupAllocateDistinctApiCall,
  getAssesseeGroupCreateAPI,
  getAssesseeGroupDistinctApiCall,
  getAssesseeRoleAllocateDistinctApiCall, //
  getAssesseeRoleDistinctApiCall,
  getAssesseeTypeApiCall,
  getMarketPlaceAssesseesDistinctApiCall,
  setFlagedArray
} from '../Actions/AssesseeModuleAction';
import {
  associateCreatePopup,
  getAssesseeSelfCertificates,
  getAssesseeSelfReports,
  getAssociateDistinctApiCall,
  getAssociateGroupCreateAPI,
  getAssociateGroupDistinctApiCall, //
  getAssociateNodeApiCall,
  getAssociateRoleAllocateDistinctApiCall,
  getAssociateRoleDistinctApiCall,
  getAssociatesTypeApiCall,
  getInternalNodeApiCall,
  updateAssociateDistinctStatus,
  updateAssociateNodeStatus
} from '../Actions/AssociateModuleAction';
import {
  createItemPopupApiCall,
  getItemGroupCreateAPI,
  getItemGroupDistinctAllocateApiCall,
  getItemGroupDistinctApiCall, //
  getItemsDistinctApiCall,
  getItemsTypeAllocateApiCall,
  getItemsTypeApiCall,
  updateItemDistinctStatus
} from '../Actions/ItemModuleAction';
import {
  createAssessmentPopup,
  getAssessmentDistinctApiCall,
  getAssessmentGroupAllocateApiCall,
  getAssessmentGroupApiCall, //
  getAssessmentGroupCreateAPI,
  getAssessmentTypeAllocateApiCall,
  getAssessmentTypeApiCall
} from '../Actions/AssessmentModuleAction';
import {
  assignmentsDistinctApiCall,
  assignmentsGroupAllocateApiCall,
  assignmentsGroupApiCall,
  assignmentTypeAllocateApiCall, //
  assignmentTypeApiCall,
  createAssignmentPopupApiCall,
  getAssignmentGroupCreateAPI
} from '../Actions/AssignmentModuleAction';
import {
  cultureProfileCreatePopup,
  getCultureProfileAllocateGroupApiCall,
  getCultureProfileAllocateTypeApiCall,
  getCultureProfileDiamentionList,
  getCultureProfileGroupApiCall, //
  getCultureProfileGroupCreateAPI,
  getCultureProfilesDistinctApiCall,
  getCultureProfileTypeApiCall
} from '../Actions/ActionCultureProfile';
import {
  getJobProfileGroupAllocateApiCall,
  getJobProfileGroupApiCall, //
  getJobProfileGroupCreateAPI,
  getJobProfilesDistinctApiCall,
  getJobProfileTypeApiAllcoateCall,
  getJobProfileTypeApiCall,
  jobProfileCreatePopup
} from '../Actions/ActionJobProfile';
import { makeInternalNodeObj, updateReviseStatus } from '../Actions/GenericActions';
import {
  SHARE_NEW_POPUP,
  ALLOCATE_ASSESSEE_DISTINCT,
  ALLOCATE_ASSESSEE_SUBMODULE_DISTINCT,
  ALLOCATE_ASSESSMENT_ACTIVE_DISTINCT,
  ALLOCATE_ASSESSMENT_SUBMODULE,
  ALLOCATE_ASSIGNMENT_DISTINCT_ANALYTICS,
  ALLOCATE_ASSIGNMENT_SUBMODULE,
  ALLOCATE_ASSOCIATE_DISTINCT,
  ALLOCATE_ASSOCIATE_NODE_DISTINCT,
  ALLOCATE_ASSOCIATE_SUBMODULE,
  ALLOCATE_CULTUREPROFILE_DISTINCT,
  ALLOCATE_CULTUREPROFILE_SUBMODULE_DISTINCT,
  ALLOCATE_ITEM_DISTINCT,
  ALLOCATE_ITEM_SUBMODULE_DISTINCT,
  ALLOCATE_JOB_DISTINCT,
  ALLOCATE_JOB_SUBMODULE,
  ALLOCATE_ASSESSMENT_INACTIVE_DISTINCT,
  ALLOCATE_ASSESSMENT_DISTINCT,
  ALLOCATE_ASSIGNMENT_DISTINCT,
  ALLOCATE_ASSIGNMENT_ACTIVE_DISTINCT,
  ALLOCATE_JOB_DISTINCT_INACTIVE,
  ALLOCATE_CULTUREPROFILE_DISTINCT_INACTIVE,
  ALLOCATE_ASSIGNMENT_INACTIVE_DISTINCT_ANALYTICS,
  ALLOCATE_ASSIGNMENT_ACTIVE_DISTINCT_ANALYTICS,
  ALLOCATE_ASSIGNMENT_SUBMODULE_NODE
} from '../PopUpConfig';
import { getAssociateGroupAllocateDistinctApiCall } from '../Actions/AssociateModuleAction';
const PopUpDisplayPaneTwoTripleDot = (props) => {
  const {
    popupHeaderOne,
    popupHeaderOneBadgeOne,
    popupHeaderOneBadgeTwo,
    popupOpenType,
    secondaryOptionCheckValue,
    selectedTagValue,
    cardValue,
    tertiaryOptionCheckValue = 'all',
    forthOptionCheckValue,
    popupContentArrValue
  } = useSelector((state) => state.PopUpReducer);
  const {
    selectedAssociateInfo,
    countPage,
    middlePaneHeader,
    middlePaneHeaderBadgeOne,
    middlePaneHeaderBadgeTwo,
    middlePaneHeaderBadgeThree,
    reviewListDistinctData,
    selectedTagsArray,
    typeOfMiddlePaneList,
    relatedReviewListDistinctData,
    relatedReviewListReqObj,
    signedAssesseePermission,
    assignmentCreateType,
    middlePaneHeaderDuplicate,
    middlePaneHeaderDuplicateBadgeOne,
    duplicateScanCount,
    paneOneSelectedSection,
    allocateStr,
    nodeViewState
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const assessmentInfo = useSelector((state) => state.AssessmentReducer);
  const { createMode } = useSelector((state) => state.DisplayPaneThreeReducer);
  const dispatch = useDispatch();
  const { headerPanelColour = 'displayPaneCentre', isActive } = props;
  const setSecondaryOptionValue = (e) => {
    if (
      popupHeaderOneBadgeTwo === 'allocate' &&
      secondaryOptionCheckValue !== e.currentTarget.getAttribute('data-value')
    ) {
      setEmabledDisableOptionForAllocate(
        e.currentTarget.getAttribute('data-value'),
        assignmentCreateType
      );
    }
    dispatch({
      type: SET_SECONDARY_CREATE_OPTION_VALUE,
      payload: e.currentTarget.getAttribute('data-value')
    });
  };
  const setEmabledDisableOptionForAllocate = (clickVal, assignmentCreateType) => {
    let arr = [];
    let arrayValue =
      (popupHeaderOne === 'assessees' &&
        middlePaneHeaderBadgeOne === 'distinct' &&
        ALLOCATE_ASSESSEE_DISTINCT) ||
      ((popupHeaderOne === 'administrators' ||
        popupHeaderOne === 'managers' ||
        popupHeaderOne === 'assistants') &&
        middlePaneHeaderBadgeOne === 'distinct' &&
        ALLOCATE_ASSESSEE_DISTINCT) ||
      (popupHeaderOne === 'assessees' && ALLOCATE_ASSESSEE_SUBMODULE_DISTINCT) ||
      (popupHeaderOne === 'assessments' &&
        middlePaneHeaderBadgeOne === 'distinct' &&
        middlePaneHeaderBadgeTwo === 'active' &&
        ALLOCATE_ASSESSMENT_ACTIVE_DISTINCT) ||
      (popupHeaderOne === 'assessments' &&
        middlePaneHeaderBadgeOne === 'distinct' &&
        middlePaneHeaderBadgeTwo === 'inactive' &&
        ALLOCATE_ASSESSMENT_INACTIVE_DISTINCT) ||
      (popupHeaderOne === 'assessments' &&
        middlePaneHeaderBadgeOne === 'distinct' &&
        middlePaneHeaderBadgeTwo === 'all' &&
        ALLOCATE_ASSESSMENT_DISTINCT) ||
      (popupHeaderOne === 'assessments' && ALLOCATE_ASSESSMENT_SUBMODULE) ||
      (popupHeaderOne === 'assignments' &&
        middlePaneHeaderBadgeOne === 'distinct' &&
        middlePaneHeaderBadgeTwo === 'active' &&
        assignmentCreateType !== 'iGuru Analytics_Occupation' &&
        ALLOCATE_ASSIGNMENT_ACTIVE_DISTINCT) ||
      (popupHeaderOne === 'assignments' &&
        middlePaneHeaderBadgeOne === 'distinct' &&
        assignmentCreateType !== 'iGuru Analytics_Occupation' &&
        ALLOCATE_ASSIGNMENT_DISTINCT) ||
      (popupHeaderOne === 'assignments' &&
        middlePaneHeaderBadgeOne === 'distinct' &&
        middlePaneHeaderBadgeTwo === 'active' &&
        assignmentCreateType === 'iGuru Analytics_Occupation' &&
        ALLOCATE_ASSIGNMENT_ACTIVE_DISTINCT_ANALYTICS) ||
      (popupHeaderOne === 'assignments' &&
        middlePaneHeaderBadgeTwo === 'inactive' &&
        assignmentCreateType === 'iGuru Analytics_Occupation' &&
        middlePaneHeaderBadgeOne === 'distinct' &&
        ALLOCATE_ASSIGNMENT_INACTIVE_DISTINCT_ANALYTICS) ||
      (popupHeaderOne === 'assignments' &&
        middlePaneHeaderBadgeOne === 'distinct' &&
        ALLOCATE_ASSIGNMENT_DISTINCT_ANALYTICS) ||
      (popupHeaderOne === 'assignments' && ALLOCATE_ASSIGNMENT_SUBMODULE) ||
      // (popupHeaderOne === 'assignments' && assignmentCreateType !== "iGuru Analytics_Occupation" && ALLOCATE_ASSIGNMENT_SUBMODULE_NODE) ||
      (popupHeaderOne === 'associates' &&
        middlePaneHeaderBadgeOne === 'distinct' &&
        ALLOCATE_ASSOCIATE_DISTINCT) ||
      (popupHeaderOne === 'associates' && ALLOCATE_ASSOCIATE_SUBMODULE) ||
      (popupHeaderOne === 'job-profiles' &&
        middlePaneHeaderBadgeOne === 'distinct' &&
        middlePaneHeaderBadgeTwo === 'inactive' &&
        ALLOCATE_JOB_DISTINCT_INACTIVE) ||
      (popupHeaderOne === 'job-profiles' &&
        middlePaneHeaderBadgeOne === 'distinct' && createMode === 'jobProfile' &&
        ALLOCATE_JOB_DISTINCT_INACTIVE) ||
      (popupHeaderOne === 'job-profiles' &&
        middlePaneHeaderBadgeOne === 'distinct' &&
        ALLOCATE_JOB_DISTINCT) ||
      (popupHeaderOne === 'job-profiles' && ALLOCATE_JOB_SUBMODULE) ||
      (popupHeaderOne === 'items' &&
        middlePaneHeaderBadgeOne === 'distinct' &&
        ALLOCATE_ITEM_DISTINCT) ||
      (popupHeaderOne === 'items' && ALLOCATE_ITEM_SUBMODULE_DISTINCT) ||
      (popupHeaderOne === 'culture-profiles' &&
        middlePaneHeaderBadgeOne === 'distinct' &&
        middlePaneHeaderBadgeTwo === 'inactive' &&
        ALLOCATE_CULTUREPROFILE_DISTINCT_INACTIVE) ||
      (popupHeaderOne === 'culture-profile' &&
        createMode === 'cultureProfile' &&
        ALLOCATE_CULTUREPROFILE_DISTINCT_INACTIVE) ||
      (popupHeaderOne === 'culture-profiles' &&
        middlePaneHeaderBadgeOne === 'distinct' &&
        ALLOCATE_CULTUREPROFILE_DISTINCT) ||
      (popupHeaderOne === 'culture-profiles' && ALLOCATE_CULTUREPROFILE_SUBMODULE_DISTINCT);
    if (arrayValue) {
      console.log('arrayValue', arrayValue);
      console.log('popupContentArrValue', popupContentArrValue);
      popupContentArrValue.map((element) => {
        arr.push({
          ...element,
          disabled: !arrayValue[clickVal.replace('-', '')].includes(element.data)
        });
      });
      if (
        tertiaryOptionCheckValue &&
        !arrayValue[clickVal.replace('-', '')]?.includes(tertiaryOptionCheckValue)
      ) {
        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'tertiaryOptionCheckValue', value: '' }
        });
      }
      if (
        secondaryOptionCheckValue &&
        !arrayValue[clickVal.replace('-', '')]?.includes(secondaryOptionCheckValue)
      ) {
        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'secondaryOptionCheckValue', value: '' }
        });
      }
      // if (
      //   (clickVal === 'distinct' ||
      //     clickVal === 'groups' ||
      //     clickVal === 'nodes' ||
      //     clickVal === 'roles' ||
      //     clickVal === 'types') &&
      //   secondaryOptionCheckValue !== 'all'
      // ) {
      //   dispatch({
      //     type: SET_POPUP_SINGLE_STATE,
      //     payload: { stateName: 'secondaryOptionCheckValue', value: '' }
      //   });
      // }
    }
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: {
        stateName: 'popupContentArrValue',
        value: arr.length > 0 ? arr : popupContentArrValue
      }
    });
  };
  const setSecondaryOptionValueTwo = (e) => {
    let arr = [];
    if (popupHeaderOneBadgeTwo === 'share') {
      if (e.currentTarget.getAttribute('data-value') === 'distinct') {
        SHARE_NEW_POPUP.forEach((element) => {
          if (popupHeaderOneBadgeOne === 'distinct') {
            if (
              element.data === 'ascendant' ||
              element.data === 'peer' ||
              element.data === 'descendant'
            ) {
              arr.push({ ...element, disabled: true });
            } else {
              arr.push({ ...element, disabled: false });
            }
          } else {
            if (
              element.data === 'ascendant' ||
              element.data === 'peer' ||
              element.data === 'descendant' ||
              element.data === 'node'
            ) {
              arr.push({ ...element, disabled: true });
            } else {
              arr.push({ ...element, disabled: false });
            }
          }
        });
        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: {
            stateName: 'popupContentArrValue',
            value: arr
          }
        });
        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: {
            stateName: 'forthOptionCheckValue',
            value: ''
          }
        });
      } else {
        SHARE_NEW_POPUP.forEach((element) => {
          if (popupHeaderOneBadgeOne === 'distinct') {
            arr.push({ ...element, disabled: false });
          } else {
            if (element.data === 'node') {
              arr.push({ ...element, disabled: true });
            } else {
              arr.push({ ...element, disabled: false });
            }
          }
          //arr.push({ ...element, disabled: false });
        });
        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: {
            stateName: 'popupContentArrValue',
            value: arr
          }
        });
        if (forthOptionCheckValue === '') {
          dispatch({
            type: SET_POPUP_SINGLE_STATE,
            payload: {
              stateName: 'forthOptionCheckValue',
              value: 'ascendant'
            }
          });
        }
      }
    }
    if (
      popupHeaderOneBadgeTwo === 'allocate'
      // tertiaryOptionCheckValue !== e.currentTarget.getAttribute('data-value')
    ) {
      setEmabledDisableOptionForAllocate(
        e.currentTarget.getAttribute('data-value'),
        assignmentCreateType
      );
    }
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: {
        stateName: 'tertiaryOptionCheckValue',
        value: e.currentTarget.getAttribute('data-value')
      }
    });
  };
  const setSecondaryOptionValueThree = (e) => {
    let arr = [];
    let valueThree = e.currentTarget.getAttribute('data-value');
    if (valueThree === 'peer') {
      // if (popupHeaderOneBadgeOne === 'share') {
      SHARE_NEW_POPUP.forEach((element) => {
        if (popupHeaderOneBadgeOne === 'distinct') {
          if (
            element.data === 'distinct' ||
            element.data === 'primary' ||
            element.data === 'secondary'
          ) {
            arr.push({ ...element, disabled: true });
          } else {
            arr.push({ ...element, disabled: false });
          }
        } else {
          if (
            element.data === 'distinct' ||
            element.data === 'primary' ||
            element.data === 'secondary' ||
            element.data === 'node'
          ) {
            arr.push({ ...element, disabled: true });
          } else {
            arr.push({ ...element, disabled: false });
          }
        }
      });
      dispatch({
        type: SET_POPUP_SINGLE_STATE,
        payload: {
          stateName: 'tertiaryOptionCheckValue',
          value: 'all'
        }
      });
      // }
    } else {
      SHARE_NEW_POPUP.forEach((element) => {
        if (popupHeaderOneBadgeOne === 'distinct') {
          if (element.data === 'distinct') {
            arr.push({ ...element, disabled: true });
          } else {
            arr.push({ ...element, disabled: false });
          }
        } else {
          if (element.data === 'distinct' || element.data === 'node') {
            arr.push({ ...element, disabled: true });
          } else {
            arr.push({ ...element, disabled: false });
          }
        }
      });
      dispatch({
        type: SET_POPUP_SINGLE_STATE,
        payload: {
          stateName: 'tertiaryOptionCheckValue',
          value: 'primary'
        }
      });
    }
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: {
        stateName: 'popupContentArrValue',
        value: arr
      }
    });

    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: {
        stateName: 'forthOptionCheckValue',
        value: e.currentTarget.getAttribute('data-value')
      }
    });
  };
  const clearMiddlePaneInfo = () => {
    dispatch({
      type: SET_MIDDLEPANE_STATE,
      payload: {
        middlePaneHeader: '',
        middlePaneHeaderBadgeOne: '',
        middlePaneHeaderBadgeTwo: '',
        middlePaneHeaderBadgeThree: '',
        middlePaneHeaderBadgeFour: '',
        typeOfMiddlePaneList: '',
        scanCount: '',
        showMiddlePaneState: false
      }
    });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  };
  const resetDataFunction = () => {
    // ;
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'allocateStr', value: '' }
    });
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: { stateName: 'cardValue', value: 'NoCard' }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'typeOfMiddlePaneList', value: '' }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'middlePaneSelectedValue', value: '' }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'selectedFlagedArray', value: [] }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'unselectedFlagedArray', value: [] }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'selectedTagsArray', value: [] }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'unselectedTagsArray', value: [] }
    });
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: { stateName: 'selectionValue', value: '' }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'flagedValue', value: '' }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'scanString', value: '' }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'allocatedTagsArray', value: [] }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'nodeViewState', value: "" }
    });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
    dispatch({ type: CLEAR_ASSESSMENT_INFO });
    dispatch({ type: CLEAR_ASSIGNMENT_INFO });
    dispatch({ type: ASSOCIATE_POPUP_CLOSE });
  };
  const ChangeOptionPopup = async (e) => {
    let keyVal = e.currentTarget.getAttribute('data-key');
    let dataVal = e.currentTarget.getAttribute('data-value');
    let isValidAllocationOrUnallocation = false;
    let filterModeKey = '';

    console.log('keyVal', keyVal);
    console.log('dataVal', dataVal);
    if (dataVal === 'information') {
      if (dataVal === 'information' && popupHeaderOneBadgeOne === 'groups') {
        if (popupHeaderOne === 'assessees') {
          getAssesseeGroupCreateAPI(
            selectedAssociateInfo,
            dispatch,
            'assessees',
            assignmentCreateType
          );
        } else if (
          popupHeaderOne === 'administrators' ||
          popupHeaderOne === 'managers' ||
          popupHeaderOne === 'assistants'
        ) {
          getAssesseeGroupCreateAPI(
            selectedAssociateInfo,
            dispatch,
            popupHeaderOne,
            assignmentCreateType
          );
        } else if (popupHeaderOne === 'assessments') {
          getAssessmentGroupCreateAPI(
            selectedAssociateInfo,
            dispatch,
            'assessments',
            assignmentCreateType
          );
        } else if (popupHeaderOne === 'assignments') {
          getAssignmentGroupCreateAPI(
            selectedAssociateInfo,
            dispatch,
            'assignments',
            assignmentCreateType
          );
        } else if (popupHeaderOne === 'associates') {
          getAssociateGroupCreateAPI(
            selectedAssociateInfo,
            dispatch,
            'associates',
            assignmentCreateType
          );
        } else if (popupHeaderOne === 'culture-profiles') {
          getCultureProfileGroupCreateAPI(
            selectedAssociateInfo,
            dispatch,
            'culture-profiles',
            assignmentCreateType
          );
        } else if (popupHeaderOne === 'job-profiles') {
          getJobProfileGroupCreateAPI(
            selectedAssociateInfo,
            dispatch,
            'job-profiles',
            assignmentCreateType
          );
        } else if (popupHeaderOne === 'items') {
          getItemGroupCreateAPI(selectedAssociateInfo, dispatch, 'items');
        }
        // dispatch({ type: CLEAR_GROUP_REDUCER_STATE });
        // let nodeRequestObj = makeInternalNodeObj(selectedAssociateInfo, 'active', 0, -1);
        // dispatch({ type: LOADER_START });
        // dispatch({ type: SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT, payload: nodeRequestObj });
        // dispatch({
        //   type: INTERNAL_NODE_LIST_SAGA,
        //   payload: {
        //     request: nodeRequestObj,
        //     BadgeOne: '',
        //     BadgeTwo: '',
        //     BadgeThree: '',
        //     nodeViewState: 'list',
        //     isMiddlePaneList: false
        //   }
        // });
        // dispatch({
        //   type: SET_POPUP_VALUE,
        //   payload: { isPopUpValue: 'NAMEPOPUP', popupMode: popupHeaderOne + 'GROUPCREATE' }
        // });
        clearMiddlePaneInfo();
      } else if (dataVal === 'information' && popupHeaderOneBadgeOne === 'types') {
        dispatch({ type: CLEAR_TYPE_REDUCER_STATE });
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: 'NAMEPOPUP',
            popupMode: popupHeaderOne + 'TYPECREATE'
          }
        });
        clearMiddlePaneInfo();
      } else if (dataVal === 'information' && popupHeaderOneBadgeOne === 'roles') {
        dispatch({ type: CLEAR_ROLE_REDUCER_STATE });
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'NAMEPOPUP', popupMode: popupHeaderOne + 'ROLECREATE' }
        });
        clearMiddlePaneInfo();
      } else if (dataVal === 'information' && popupHeaderOneBadgeOne === 'nodes') {
        let requestObj = makeInternalNodeObj(
          selectedAssociateInfo,
          'active',
          0,
          countPage,
          '',
          'Associate-Self-Node-List-Create'
        );
        dispatch({ type: CLEAR_NODE_REDUCER_STATE });
        dispatch({ type: LOADER_START });
        dispatch({ type: SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT, payload: requestObj });
        dispatch({
          type: INTERNAL_NODE_LIST_WITH_PERMISSIONS_SAGA,
          payload: { request: requestObj, nodeViewState: 'list', isMiddlePaneList: false }
        });
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'NODECREATE' }
        });
        clearMiddlePaneInfo();
      } else if (dataVal === 'information' && popupHeaderOne === 'assessees') {
        assesseeCreateApiCalls(
          selectedAssociateInfo,
          dispatch,
          secondaryOptionCheckValue,
          'assessee',
          assignmentCreateType
        );
        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'cardValue', value: 'Create' }
        });
        dispatch({
          type: ASSESSEE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEENAMEPOPUP',
            popupMode: 'ASSESSEE_CREATE',
            popupHeaderOne: popupHeaderOne
          }
        });
        clearMiddlePaneInfo();
      } else if (dataVal === 'information' && popupHeaderOne === 'assessments') {
        resetDataFunction();
        createAssessmentPopup(
          selectedAssociateInfo,
          dispatch,
          secondaryOptionCheckValue,
          dataVal,
          assignmentCreateType
        );
        clearMiddlePaneInfo();
      } else if (dataVal === 'information' && popupHeaderOne === 'gauges') {
        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
        dispatch({ type: CLEAR_GUAGE_REDUCER_STATE });
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'GUAGECREATE' }
        });
        clearMiddlePaneInfo();
      } else if (dataVal === 'information' && popupHeaderOne === 'assignments') {
        resetDataFunction();
        createAssignmentPopupApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          dispatch,
          assignmentCreateType
        );
        clearMiddlePaneInfo();
      } else if (dataVal === 'information' && popupHeaderOne === 'associates') {
        resetDataFunction();
        dispatch({ type: ASSOCIATE_CREATE_INFO });
        let parentAssociateId =
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary;
        associateCreatePopup(
          selectedAssociateInfo,
          dispatch,
          secondaryOptionCheckValue,
          dataVal,
          parentAssociateId
        );
        clearMiddlePaneInfo();
      } else if (dataVal === 'information' && popupHeaderOne === 'items') {
        createItemPopupApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          dispatch,
          assignmentCreateType
        );
        clearMiddlePaneInfo();
      } else if (
        dataVal === 'information' &&
        popupHeaderOne === 'assessment' &&
        popupHeaderOneBadgeOne === 'grades'
      ) {
        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
        dispatch({ type: CLEAR_GRADE_REDUCER_STATE });
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'GRADECREATE' }
        });
      } else if (
        dataVal === 'information' &&
        popupHeaderOne === 'assessment' &&
        popupHeaderOneBadgeOne === 'norms'
      ) {
        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
        dispatch({ type: CLEAR_NORM_REDUCER_STATE });
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'NORMCREATE' }
        });
      } else if (
        dataVal === 'information' &&
        popupHeaderOne === 'assessment' &&
        popupHeaderOneBadgeOne === 'clusters'
      ) {
        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
        dispatch({ type: CLEAR_CLUSTER_REDUCER_STATE });
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'CLUSTERCREATE' }
        });
      } else if (
        dataVal === 'information' &&
        (popupHeaderOne === 'culture-profiles' || popupHeaderOne === 'job-profiles')
      ) {
        if (popupHeaderOne === 'culture-profiles') {
          cultureProfileCreatePopup(
            selectedAssociateInfo,
            secondaryOptionCheckValue,
            dispatch,
            assignmentCreateType
          );
        } else {
          jobProfileCreatePopup(
            selectedAssociateInfo,
            secondaryOptionCheckValue,
            dispatch,
            assignmentCreateType
          );
        }
        clearMiddlePaneInfo();
      } else if (
        dataVal === 'information' &&
        (popupHeaderOne === 'administrators' ||
          popupHeaderOne === 'managers' ||
          popupHeaderOne === 'assistants')
      ) {
        assesseeCreateApiCalls(
          selectedAssociateInfo,
          dispatch,
          secondaryOptionCheckValue,
          popupHeaderOne.slice(0, -1)
        );
        clearMiddlePaneInfo();
      } else {
        dispatch({ type: POPUP_CLOSE });
      }
      //dispatch({ type: POPUP_CLOSE });
    } else if (
      dataVal === 'publishApiCall' ||
      dataVal === 'unpublishApiCall' ||
      dataVal === 'suspendApiCall' ||
      dataVal === 'unsuspendApiCall' ||
      dataVal === 'terminateApiCall' ||
      dataVal === 'unterminateApiCall' ||
      dataVal === 'archiveApiCall' ||
      dataVal === 'unarchiveApiCall' ||
      dataVal === 'approveApiCall' ||
      dataVal === 'unapproveApiCall' ||
      dataVal === 'yesApiCall'
    ) {
      //
      let selectedType = '';
      if (typeOfMiddlePaneList === 'itemsDistinctReviewList') {
        selectedType = 'itemDistinct';
        updateReviseStatus(
          selectedAssociateInfo,
          selectedTagsArray,
          dispatch,
          selectedType,
          keyVal,
          typeOfMiddlePaneList
        );
      }

      if (typeOfMiddlePaneList === 'gaugeDistinctReviewList') {
        dispatch({ type: LOADER_START });
        UpdateGaugeReviseStatus(
          selectedAssociateInfo,
          selectedTagsArray,
          dispatch,
          keyVal,
          typeOfMiddlePaneList,
          createMode
        );
        resetDataFunction(); //select / unselect option
      }

      if (typeOfMiddlePaneList === 'itemsGroupDistinctReviewList') {
        selectedType = 'itemGroup';
        // if (keyVal === 'UNSUSPENDED' || keyVal === 'UNTERMINATED' || keyVal === 'UNARCHIVED') {
        //          keyVal = 'ACTIVE';
        //        }
        updateReviseStatus(
          selectedAssociateInfo,
          selectedTagsArray,
          dispatch,
          selectedType,
          keyVal,
          typeOfMiddlePaneList
        );
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
      }
      if (typeOfMiddlePaneList === 'assesseeDistinctAssignmentDistinctReviewList') {
        updateReviseAssesseeAssignmentStatus(
          selectedAssociateInfo,
          selectedTagsArray,
          dispatch,
          keyVal,
          typeOfMiddlePaneList,
          relatedReviewListDistinctData,
          middlePaneHeaderBadgeTwo
        );
      }
      if (typeOfMiddlePaneList === 'itemsTypeDistinctReviewList') {
        selectedType = 'itemType';
        // if (keyVal === 'UNSUSPENDED' || keyVal === 'UNTERMINATED' || keyVal === 'UNARCHIVED') {
        //          keyVal = 'ACTIVE';
        //        }
        updateReviseStatus(
          selectedAssociateInfo,
          selectedTagsArray,
          dispatch,
          selectedType,
          keyVal,
          typeOfMiddlePaneList
        );
      }
      if (typeOfMiddlePaneList === 'assessmentDistinctReviewList') {
        selectedType = 'assessmentDistinct';
        updateReviseStatus(
          selectedAssociateInfo,
          selectedTagsArray,
          dispatch,
          selectedType,
          keyVal,
          typeOfMiddlePaneList
        );
      }
      if (typeOfMiddlePaneList === 'assessmentsTypeDistinctReviewList') {
        // if (keyVal === 'UNSUSPENDED' || keyVal === 'UNTERMINATED' || keyVal === 'UNARCHIVED') {
        //          keyVal = 'ACTIVE';
        //        }
        selectedType = 'assessmentType';
        updateReviseStatus(
          selectedAssociateInfo,
          selectedTagsArray,
          dispatch,
          selectedType,
          keyVal,
          typeOfMiddlePaneList
        );
      }
      if (typeOfMiddlePaneList === 'assessmentsGroupDistinctReviewList') {
        selectedType = 'assessmentGroup';
        // if (keyVal === 'UNSUSPENDED' || keyVal === 'UNTERMINATED' || keyVal === 'UNARCHIVED') {
        //   keyVal = 'ACTIVE';
        // }
        updateReviseStatus(
          selectedAssociateInfo,
          selectedTagsArray,
          dispatch,
          selectedType,
          keyVal,
          typeOfMiddlePaneList
        );
      }
      if (
        typeOfMiddlePaneList === 'assesseesDistinctReviewList' ||
        typeOfMiddlePaneList === 'assistantsDistinctReviewList' ||
        typeOfMiddlePaneList === 'managersDistinctReviewList' ||
        typeOfMiddlePaneList === 'administratorsDistinctReviewList'
      ) {
        selectedType = 'assesseeDistinct';
        if (keyVal === 'APPROVED' || keyVal === 'DISAPPROVED') {
          updateAssesseeAproveStatus(
            selectedAssociateInfo,
            selectedTagsArray,
            dispatch,
            keyVal,
            createMode
          );
        } else {
          updateReviseStatus(
            selectedAssociateInfo,
            selectedTagsArray,
            dispatch,
            selectedType,
            keyVal,
            typeOfMiddlePaneList
          );
        }
      }
      if (typeOfMiddlePaneList === 'assesseesGroupDistinctReviewList') {
        selectedType = 'assesseeGroup';
        // if (keyVal === 'UNSUSPENDED' || keyVal === 'UNTERMINATED' || keyVal === 'UNARCHIVED') {
        //          keyVal = 'ACTIVE';
        //        }
        updateReviseStatus(
          selectedAssociateInfo,
          selectedTagsArray,
          dispatch,
          selectedType,
          keyVal,
          typeOfMiddlePaneList
        );
      }
      if (typeOfMiddlePaneList === 'assesseesTypeDistinctReviewList') {
        selectedType = 'assesseeType';
        // if (keyVal === 'UNSUSPENDED' || keyVal === 'UNTERMINATED' || keyVal === 'UNARCHIVED') {
        //          keyVal = 'ACTIVE';
        //        }
        updateReviseStatus(
          selectedAssociateInfo,
          selectedTagsArray,
          dispatch,
          selectedType,
          keyVal,
          typeOfMiddlePaneList
        );
      }
      if (typeOfMiddlePaneList === 'assesseeRoleDistinctReviewList') {
        selectedType = 'assesseeRole';
        updateReviseStatus(
          selectedAssociateInfo,
          selectedTagsArray,
          dispatch,
          selectedType,
          keyVal,
          typeOfMiddlePaneList
        );
      }
      if (typeOfMiddlePaneList === 'assignmentDistinctReviewList') {
        selectedType = 'assignmentDistinct';
        updateReviseStatus(
          selectedAssociateInfo,
          selectedTagsArray,
          dispatch,
          selectedType,
          keyVal,
          typeOfMiddlePaneList
        );
      }
      if (typeOfMiddlePaneList === 'assignmentsGroupDistinctReviewList') {
        selectedType = 'assignmentGroup';
        // if (keyVal === 'UNSUSPENDED' || keyVal === 'UNTERMINATED' || keyVal === 'UNARCHIVED') {
        //          keyVal = 'ACTIVE';
        //        }
        updateReviseStatus(
          selectedAssociateInfo,
          selectedTagsArray,
          dispatch,
          selectedType,
          keyVal,
          typeOfMiddlePaneList
        );
      }
      if (typeOfMiddlePaneList === 'assignmentsTypeDistinctReviewList') {
        // if (keyVal === 'UNSUSPENDED' || keyVal === 'UNTERMINATED' || keyVal === 'UNARCHIVED') {
        //          keyVal = 'ACTIVE';
        //        }
        selectedType = 'assignmentType';
        updateReviseStatus(
          selectedAssociateInfo,
          selectedTagsArray,
          dispatch,
          selectedType,
          keyVal,
          typeOfMiddlePaneList
        );
      }
      if (
        typeOfMiddlePaneList === 'associateDistinctReviewList' ||
        typeOfMiddlePaneList === 'associatesNodeDistinctReviewList'
      ) {
        // if (keyVal === 'UNSUSPENDED' || keyVal === 'UNTERMINATED' || keyVal === 'UNARCHIVED') {
        //   keyVal = 'UNCONFIRMED';
        // }

        if (keyVal === 'UNAPPROVED' || keyVal === 'APPROVED' || keyVal === 'DISAPPROVED') {
          dispatch({ type: LOADER_START });
          updateAssociateDistinctStatus(
            selectedAssociateInfo,
            selectedTagsArray,
            dispatch,
            keyVal,
            createMode,
            typeOfMiddlePaneList
          );
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'relatedReviewListDistinctData', value: [] }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          dispatch({ type: POPUP_CLOSE })
        } else {
          let selectedType = 'associateDistinct';
          updateReviseStatus(
            selectedAssociateInfo,
            selectedTagsArray,
            dispatch,
            selectedType,
            keyVal,
            typeOfMiddlePaneList
          );
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
        }

      }
      if (typeOfMiddlePaneList === 'associatesGroupDistinctReviewList') {
        selectedType = 'associateGroup';
        // if (keyVal === 'UNSUSPENDED' || keyVal === 'UNTERMINATED' || keyVal === 'UNARCHIVED') {
        //          keyVal = 'ACTIVE';
        //        }
        updateReviseStatus(
          selectedAssociateInfo,
          selectedTagsArray,
          dispatch,
          selectedType,
          keyVal,
          typeOfMiddlePaneList
        );
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
      }
      if (typeOfMiddlePaneList === 'associatesTypeDistinctReviewList') {
        // if (keyVal === 'UNSUSPENDED' || keyVal === 'UNTERMINATED' || keyVal === 'UNARCHIVED') {
        //          keyVal = 'ACTIVE';
        //        }
        selectedType = 'associateType';
        updateReviseStatus(
          selectedAssociateInfo,
          selectedTagsArray,
          dispatch,
          selectedType,
          keyVal,
          typeOfMiddlePaneList
        );
      }
      if (
        typeOfMiddlePaneList === 'associateRoleDistinctReviewList' ||
        createMode === 'associatesRole'
      ) {
        selectedType = 'associateRole';
        // if (keyVal === 'UNSUSPENDED' || keyVal === 'UNTERMINATED' || keyVal === 'UNARCHIVED') {
        //   keyVal = 'ACTIVE'; //
        // }
        updateReviseStatus(
          selectedAssociateInfo,
          selectedTagsArray,
          dispatch,
          selectedType,
          keyVal,
          typeOfMiddlePaneList,
          createMode
        );
      }
      if (typeOfMiddlePaneList === 'associateNodeDistinctReviewList') {
        selectedType = 'associateNode';
        // if (keyVal === 'UNSUSPENDED' || keyVal === 'UNTERMINATED' || keyVal === 'UNARCHIVED') {
        //          keyVal = 'ACTIVE';
        //        }
        updateAssociateNodeStatus(
          selectedAssociateInfo,
          selectedTagsArray,
          dispatch,
          keyVal,
          createMode
        );
        dispatch({ type: POPUP_CLOSE });
      }
      if (typeOfMiddlePaneList === 'cultureProfilesDistinctReviewList') {
        selectedType = 'cultureProfileDistinct';
        updateReviseStatus(
          selectedAssociateInfo,
          selectedTagsArray,
          dispatch,
          selectedType,
          keyVal,
          typeOfMiddlePaneList
        );
      }
      if (typeOfMiddlePaneList === 'cultureProfilesGroupDistinctReviewList') {
        selectedType = 'cultureProfileGroup';
        // if (keyVal === 'UNSUSPENDED' || keyVal === 'UNTERMINATED' || keyVal === 'UNARCHIVED') {
        //          keyVal = 'ACTIVE';
        //        }
        updateReviseStatus(
          selectedAssociateInfo,
          selectedTagsArray,
          dispatch,
          selectedType,
          keyVal,
          typeOfMiddlePaneList
        );
      }
      if (typeOfMiddlePaneList === 'cultureProfilesTypeDistinctReviewList') {
        // if (keyVal === 'UNSUSPENDED' || keyVal === 'UNTERMINATED' || keyVal === 'UNARCHIVED') {
        //          keyVal = 'ACTIVE';
        //        }
        selectedType = 'cultureProfileType';
        updateReviseStatus(
          selectedAssociateInfo,
          selectedTagsArray,
          dispatch,
          selectedType,
          keyVal,
          typeOfMiddlePaneList
        );
      }
      if (typeOfMiddlePaneList === 'jobProfilesDistinctReviewList') {
        selectedType = 'jobProfileDistinct';
        updateReviseStatus(
          selectedAssociateInfo,
          selectedTagsArray,
          dispatch,
          selectedType,
          keyVal,
          typeOfMiddlePaneList
        );
      }
      if (typeOfMiddlePaneList === 'jobProfilesGroupDistinctReviewList') {
        selectedType = 'jobProfileGroup';
        // if (keyVal === 'UNSUSPENDED' || keyVal === 'UNTERMINATED' || keyVal === 'UNARCHIVED') {
        //          keyVal = 'ACTIVE';
        //        }
        updateReviseStatus(
          selectedAssociateInfo,
          selectedTagsArray,
          dispatch,
          selectedType,
          keyVal,
          typeOfMiddlePaneList
        );
      }
      if (typeOfMiddlePaneList === 'jobProfilesTypeDistinctReviewList') {
        // if (keyVal === 'UNSUSPENDED' || keyVal === 'UNTERMINATED' || keyVal === 'UNARCHIVED') {
        //          keyVal = 'ACTIVE';
        //        }
        selectedType = 'jobProfileType';
        updateReviseStatus(
          selectedAssociateInfo,
          selectedTagsArray,
          dispatch,
          selectedType,
          keyVal,
          typeOfMiddlePaneList
        );
      }
      if (typeOfMiddlePaneList === 'assessmentclustersReviewList') {
        if (keyVal === 'DELETED') {
          // let seleTag = selectedTagsArray.map(function (item) {
          //   return parseInt(item);
          // });
          let id = relatedReviewListDistinctData[0].id;
          const reqBody = {
            module: 'Assessment-Distinct',
            action: 'Revise',
            actionFilter: 'Section-Cluster-Scale',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              assessmentId: id,
              removeAssessmentClusters: selectedTagsArray,
              removeAssessmentClusterFramework: true
            }
          };

          dispatch({ type: LOADER_START });
          dispatch({
            type: ASSESSMENT_CLUSTER_REVISE_SAGA,
            payload: {
              secondaryOptionCheckValue: '',
              headerOne: 'assessment',
              reqBody,
              hideRightpane: true,
              assessmentSector: 'assessmentclustersReviewList'
            }
          });
          dispatch({ type: POPUP_CLOSE });
          dispatch({ type: POPUP_CLOSE });
        }
      }
      if (typeOfMiddlePaneList === 'assessmentsectionsReviewList') {
        if (keyVal === 'DELETED') {
          let seleTag = selectedTagsArray.map(function (item) {
            return parseInt(item);
          });
          let sectionObj = assessmentInfo.informationFramework.assessmentSection;
          let filteredArr = sectionObj.filter(function (number, index) {
            return seleTag.indexOf(index) == -1;
          });
          let id = relatedReviewListDistinctData[0].id;
          const reqBody = {
            module: 'Assessment-Distinct',
            action: 'Revise',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              assessment: {
                id,
                informationFramework: {
                  assessmentSection: filteredArr
                }
              }
            }
          };
          dispatch({ type: LOADER_START });
          dispatch({
            type: ASSESSMENT_INFO_REVISE_SAGA,
            payload: {
              secondaryOptionCheckValue: '',
              headerOne: 'assessment',
              reqBody,
              hideRightpane: true,
              assessmentSector: 'assessmentsectionsReviewList',
              reviseState: relatedReviewListReqObj
            }
          });
          dispatch({ type: POPUP_CLOSE });
        }
      }
      if (typeOfMiddlePaneList === 'assessmentversionsReviewList') {
        if (keyVal === 'DELETED') {
          let seleTag = selectedTagsArray.map(function (item) {
            return parseInt(item);
          });
          let sectionObj = assessmentInfo?.informationFramework?.assessmentSection || [];
          let versionObj = sectionObj[0]?.assessmentVersionFramework || [];
          let filteredArr = versionObj.filter(function (number, index) {
            return seleTag.indexOf(index) == -1;
          });
          sectionObj[0].assessmentVersionFramework = filteredArr;
          let id = relatedReviewListDistinctData[0].id;
          const reqBody = {
            module: 'Assessment-Distinct',
            action: 'Revise',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              assessment: {
                id,
                informationFramework: {
                  assessmentSection: sectionObj
                }
              }
            }
          };
          dispatch({ type: LOADER_START });
          dispatch({
            type: ASSESSMENT_INFO_REVISE_SAGA,
            payload: {
              secondaryOptionCheckValue: '',
              headerOne: 'assessment',
              reqBody,
              hideRightpane: true,
              assessmentSector: 'assessmentversionsReviewList',
              reviseState: relatedReviewListReqObj
            }
          });
          dispatch({ type: POPUP_CLOSE });
        }
      }

      if (typeOfMiddlePaneList === 'assessmentscalesReviewList') {
        if (keyVal === 'DELETED') {
          let seleTag = selectedTagsArray.map(function (item) {
            return parseInt(item);
          });
          let scaleObj = assessmentInfo?.informationFramework?.assessmentScale || [];
          let filteredArr = scaleObj.filter(function (number, index) {
            return seleTag.indexOf(index) == -1;
          });
          let id = relatedReviewListDistinctData[0].id;
          const reqBody = {
            module: 'Assessment-Distinct',
            action: 'Revise',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              assessment: {
                id,
                informationFramework: {
                  assessmentScale: filteredArr
                }
              }
            }
          };
          dispatch({ type: LOADER_START });
          dispatch({
            type: ASSESSMENT_INFO_REVISE_SAGA,
            payload: {
              secondaryOptionCheckValue: '',
              headerOne: 'assessment',
              reqBody,
              hideRightpane: true,
              assessmentSector: 'assessmentscalesReviewList',
              reviseState: relatedReviewListReqObj
            }
          });
          dispatch({ type: POPUP_CLOSE });
        }
      }
    } else if (dataVal === 'administerApiCall') {
      dispatch({ type: POPUP_CLOSE });
    } else if (dataVal === 'shareApiCall' || dataVal === 'unshareApiCall') {
      let shareVal = '';
      let typeShare =
        secondaryOptionCheckValue.toUpperCase() +
        '_' +
        forthOptionCheckValue.toUpperCase() +
        '_' +
        tertiaryOptionCheckValue.toUpperCase();
      let reqBody = '';
      if (typeOfMiddlePaneList === 'assesseesTypeDistinctReviewList') {
        let assesseeTypeSharedArr = [];
        selectedTagsArray.length > 0 &&
          selectedTagsArray.map((item, index) => {
            assesseeTypeSharedArr.push({
              assesseeTypeId: item
            });
          });
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'assesseeType' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'allocatedTagsArray', value: [...selectedTagsArray] }
          });
        } else {
          reqBody = {
            module: 'Assessee-Type',
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              assesseeTypeShare: typeShare,
              assesseeTypeShareInformation: [],
              assesseeTypeShared: assesseeTypeSharedArr
            }
          };
          shareVal = 'assesseeType';
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
        }
      }
      if (typeOfMiddlePaneList === 'assessmentsTypeDistinctReviewList') {
        let asseessmentTypeSharedArr = [];
        selectedTagsArray.length > 0 &&
          selectedTagsArray.map((item, index) => {
            asseessmentTypeSharedArr.push({
              assessmentTypeId: item
            });
          });
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'assessmentType' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'allocatedTagsArray', value: [...selectedTagsArray] }
          });
        } else {
          reqBody = {
            module: 'Assessment-Type',
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              assessmentTypeShare: typeShare,
              assessmentTypeShareInformation: [],
              assessmentTypeShared: asseessmentTypeSharedArr
            }
          };
          shareVal = 'assessmentType';
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
        }
        // if (reqBody) {
        //   dispatch({ type: LOADER_START });
        //   dispatch({
        //     type: SHARE_ROLES_TYPES_SAGA,
        //     payload: {
        //       secondaryOptionCheckValue: '',
        //       headerOne: '',
        //       request: reqBody,
        //       apiCall: dataVal,
        //       shareValue: shareVal
        //     }
        //   });
        // }
        // dispatch({ type: POPUP_CLOSE });
      }
      if (typeOfMiddlePaneList === 'assignmentsTypeDistinctReviewList') {
        let assignmentTypeSharedArr = [];
        selectedTagsArray.length > 0 &&
          selectedTagsArray.map((item, index) => {
            assignmentTypeSharedArr.push({
              assignmentTypeId: item
            });
          });
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'assignmentType' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'allocatedTagsArray', value: [...selectedTagsArray] }
          });
        } else {
          reqBody = {
            module: 'Assignment-Type',
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              assignmentTypeShare: typeShare,
              assignmentTypeShareInformation: [],
              assignmentTypeShared: assignmentTypeSharedArr
            }
          };
          shareVal = 'assignmentType';
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
        }
      }
      if (typeOfMiddlePaneList === 'associatesTypeDistinctReviewList') {
        let associateTypeSharedArr = [];
        selectedTagsArray.length > 0 &&
          selectedTagsArray.map((item, index) => {
            associateTypeSharedArr.push({
              associateTypeId: item
            });
          });
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'associateType' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'allocatedTagsArray', value: [...selectedTagsArray] }
          });
        } else {
          reqBody = {
            module: 'Associate-Type',
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              associateTypeShare: typeShare,
              associateTypeShareInformation: [],
              associateTypeShared: associateTypeSharedArr
            }
          };
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          shareVal = 'associateType';
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
        }
      }
      if (typeOfMiddlePaneList === 'itemsTypeDistinctReviewList') {
        let itemTypeSharedArr = [];
        selectedTagsArray.length > 0 &&
          selectedTagsArray.map((item, index) => {
            itemTypeSharedArr.push({
              itemTypeId: item
            });
          });
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'itemType' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'allocatedTagsArray', value: [...selectedTagsArray] }
          });
        } else {
          reqBody = {
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            module: 'Item-Type',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              itemTypeShare: typeShare,
              itemTypeShareInformation: [],
              itemTypeShared: itemTypeSharedArr
              // itemTypeShared: [
              //   {
              //     itemTypeId: selectedTagValue
              //     // itemTypeGroupId: selectedTagGroupId
              //   }
              // ]
            }
          };
          shareVal = 'itemType';
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
        }
      }
      if (typeOfMiddlePaneList === 'cultureProfilesTypeDistinctReviewList') {
        let cultureProfileTypeSharedArr = [];
        selectedTagsArray.length > 0 &&
          selectedTagsArray.map((item, index) => {
            cultureProfileTypeSharedArr.push({
              cultureProfileTypeId: item
            });
          });
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'cultureProfileType' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'allocatedTagsArray', value: [...selectedTagsArray] }
          });
        } else {
          reqBody = {
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            module: 'Culture-Profile-Type',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              cultureProfileTypeShare: typeShare,
              cultureProfileTypeShareInformation: [],
              cultureProfileTypeShared: cultureProfileTypeSharedArr
            }
          };
          shareVal = 'cultureProfileType';
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
        }
      }
      if (typeOfMiddlePaneList === 'jobProfilesTypeDistinctReviewList') {
        let jobProfileTypeSharedArr = [];
        selectedTagsArray.length > 0 &&
          selectedTagsArray.map((item, index) => {
            jobProfileTypeSharedArr.push({
              jobProfileTypeId: item
            });
          });
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'jobProfileType' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'allocatedTagsArray', value: [...selectedTagsArray] }
          });
        } else {
          reqBody = {
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            module: 'Job-Profile-Type',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              jobProfileTypeShare: typeShare,
              jobProfileTypeShareInformation: [],
              jobProfileTypeShared: jobProfileTypeSharedArr
            }
          };
          shareVal = 'jobProfileType';
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
        }
      }
      //type end

      if (typeOfMiddlePaneList === 'assesseeRoleDistinctReviewList') {
        let assesseeRoleSharedArr = [];
        selectedTagsArray.length > 0 &&
          selectedTagsArray.map((item, index) => {
            assesseeRoleSharedArr.push({
              assesseeRoleId: item
            });
          });
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'assesseeRole' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'allocatedTagsArray', value: [...selectedTagsArray] }
          });
        } else {
          reqBody = {
            module: 'Assessee-Role',
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              assesseeRoleShare: typeShare,
              assesseeRoleShareInformation: [],
              assesseeRoleShared: assesseeRoleSharedArr
              // assesseeRoleShared: [
              //   {
              //     assesseeRoleId: selectedTagValue
              //     // assesseeRoleGroupId: selectedTagGroupId
              //   }
              // ]
            }
          };
          shareVal = 'assesseeRole';
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
        }
      }
      if (typeOfMiddlePaneList === 'associateRoleDistinctReviewList') {
        let associateRoleSharedArr = [];
        selectedTagsArray.length > 0 &&
          selectedTagsArray.map((item, index) => {
            associateRoleSharedArr.push({
              associateRoleId: item
            });
          });
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'associateRole' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'allocatedTagsArray', value: [...selectedTagsArray] }
          });
        } else {
          reqBody = {
            module: 'Associate-Role',
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              associateRoleShare: typeShare,
              associateRoleShareInformation: [],
              associateRoleShared: associateRoleSharedArr
            }
          };
          shareVal = 'associateRole';
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
        }
      }
      //role end
      if (typeOfMiddlePaneList === 'assesseesGroupDistinctReviewList') {
        let assesseeGroupSharedArr = [];
        selectedTagsArray.length > 0 &&
          selectedTagsArray.map((item, index) => {
            assesseeGroupSharedArr.push({
              assesseeGroupId: item
            });
          });
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'assesseeGroup' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'allocatedTagsArray', value: [...selectedTagsArray] }
          });
        } else {
          reqBody = {
            module: 'Assessee-Group',
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              assesseeGroupShare: typeShare,
              assesseeGroupShareInformation: [],
              assesseeGroupShared: assesseeGroupSharedArr
            }
          };
          shareVal = 'assesseeGroup';
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
        }
      }
      if (typeOfMiddlePaneList === 'assessmentsGroupDistinctReviewList') {
        let assessmentGroupSharedArr = [];
        selectedTagsArray.length > 0 &&
          selectedTagsArray.map((item, index) => {
            assessmentGroupSharedArr.push({
              assessmentGroupId: item
            });
          });
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'assessmentGroup' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'allocatedTagsArray', value: [...selectedTagsArray] }
          });
        } else {
          reqBody = {
            module: 'Assessment-Group',
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              assessmentGroupShare: typeShare,
              assessmentGroupShareInformation: [],
              assessmentGroupShared: assessmentGroupSharedArr
            }
          };
          shareVal = 'assessmentGroup';
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
        }
      }
      if (typeOfMiddlePaneList === 'assignmentsGroupDistinctReviewList') {
        let assignmentGroupSharedArr = [];
        selectedTagsArray.length > 0 &&
          selectedTagsArray.map((item, index) => {
            assignmentGroupSharedArr.push({
              assignmentGroupId: item
            });
          });
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'assignmentGroup' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'allocatedTagsArray', value: [...selectedTagsArray] }
          });
        } else {
          reqBody = {
            module: 'Assignment-Group',
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              assignmentGroupShare: typeShare,
              assignmentGroupShareInformation: [],
              assignmentGroupShared: assignmentGroupSharedArr
            }
          };
          shareVal = 'assignmentGroup';
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
        }
      }
      if (typeOfMiddlePaneList === 'associatesGroupDistinctReviewList') {
        let associateGroupSharedArr = [];
        selectedTagsArray.length > 0 &&
          selectedTagsArray.map((item, index) => {
            associateGroupSharedArr.push({
              associateGroupId: item
            });
          });
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'associateGroup' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'allocatedTagsArray', value: [...selectedTagsArray] }
          });
        } else {
          reqBody = {
            module: 'Associate-Group',
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              associateGroupShare: typeShare,
              associateGroupShareInformation: [],
              associateGroupShared: associateGroupSharedArr
            }
          };
          shareVal = 'associateGroup';
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
        }
      }
      if (typeOfMiddlePaneList === 'cultureProfilesGroupDistinctReviewList') {
        let cultureProfileGroupSharedArr = [];
        selectedTagsArray.length > 0 &&
          selectedTagsArray.map((item, index) => {
            cultureProfileGroupSharedArr.push({
              cultureProfileGroupId: item
            });
          });
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'cultureProfileGroup' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'allocatedTagsArray', value: [...selectedTagsArray] }
          });
        } else {
          reqBody = {
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            module: 'Culture-Profile-Group',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              cultureProfileGroupShare: typeShare,
              cultureProfileGroupShareInformation: [],
              cultureProfileGroupShared: cultureProfileGroupSharedArr
            }
          };
          shareVal = 'cultureProfileGroup';
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
        }
      }
      if (typeOfMiddlePaneList === 'jobProfilesGroupDistinctReviewList') {
        let jobProfileGroupSharedArr = [];
        selectedTagsArray.length > 0 &&
          selectedTagsArray.map((item, index) => {
            jobProfileGroupSharedArr.push({
              jobProfileGroupId: item
            });
          });
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'jobProfileGroup' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'allocatedTagsArray', value: [...selectedTagsArray] }
          });
        } else {
          reqBody = {
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            module: 'Job-Profile-Group',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              jobProfileGroupShare: typeShare,
              jobProfileGroupShareInformation: [],
              jobProfileGroupShared: jobProfileGroupSharedArr
            }
          };
          shareVal = 'jobProfileGroup';
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
        }
      }
      if (typeOfMiddlePaneList === 'itemsGroupDistinctReviewList') {
        let itemGroupSharedArr = [];
        selectedTagsArray.length > 0 &&
          selectedTagsArray.map((item, index) => {
            itemGroupSharedArr.push({
              itemGroupId: item
            });
          });
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'itemGroup' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'allocatedTagsArray', value: [...selectedTagsArray] }
          });
        } else {
          reqBody = {
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            module: 'Item-Group',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              itemGroupShare: typeShare,
              itemGroupShareInformation: [],
              itemGroupShared: itemGroupSharedArr
            }
          };
          shareVal = 'itemGroup';
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
        }
      }
      //group end
      if (
        typeOfMiddlePaneList === 'assesseesDistinctReviewList' ||
        typeOfMiddlePaneList === 'assistantsDistinctReviewList' ||
        typeOfMiddlePaneList === 'managersDistinctReviewList' ||
        typeOfMiddlePaneList === 'administratorsDistinctReviewList'
      ) {
        let assesseeDistinctSharedArr = [];
        selectedTagsArray.length > 0 &&
          selectedTagsArray.map((item, index) => {
            assesseeDistinctSharedArr.push({
              assesseeId: item
            });
          });
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'assesseeDistinct' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'isDistinct', value: true }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'allocatedTagsArray', value: [...selectedTagsArray] }
          });
        } else {
          reqBody = {
            module: 'Assessee-Distinct',
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              assesseeDistinctShare: typeShare,
              assesseeDistinctShareInformation: [],
              assesseeDistinctShared: assesseeDistinctSharedArr
            }
          };
          shareVal = 'assesseeDistinct';
          console.log('reqBody>>>>', reqBody);
          resetDataFunction();
        }
      }
      if (typeOfMiddlePaneList === 'assessmentDistinctReviewList') {
        let assessmentDistinctSharedArr = [];
        selectedTagsArray.length > 0 &&
          selectedTagsArray.map((item, index) => {
            assessmentDistinctSharedArr.push({
              assessmentId: item
            });
          });
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'assessmentDistinct' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'isDistinct', value: true }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'allocatedTagsArray', value: [...selectedTagsArray] }
          });
        } else {
          reqBody = {
            module: 'Assessment-Distinct',
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              assessmentDistinctShare: typeShare,
              assessmentDistinctShareInformation: [],
              assessmentDistinctShared: assessmentDistinctSharedArr
            }
          };
          shareVal = 'assessmentDistinct';
          // resetDataFunction();
        }
      }
      if (typeOfMiddlePaneList === 'assignmentDistinctReviewList') {
        let assignmentDistinctSharedArr = [];
        selectedTagsArray.length > 0 &&
          selectedTagsArray.map((item, index) => {
            assignmentDistinctSharedArr.push({
              assignmentId: item
            });
          });
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'assignmentDistinct' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'isDistinct', value: true }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'allocatedTagsArray', value: [...selectedTagsArray] }
          });
        } else {
          reqBody = {
            module: 'Assignment-Distinct',
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              assignmentDistinctShare: typeShare,
              assignmentDistinctShareInformation: [],
              assignmentDistinctShared: assignmentDistinctSharedArr
            }
          };
          shareVal = 'assignmentDistinct';
          console.log('reqBody>>>>', reqBody);
          resetDataFunction();
        }
      }
      if (typeOfMiddlePaneList === 'cultureProfilesDistinctReviewList') {
        let cultureProfileDistinctSharedArr = [];
        selectedTagsArray.length > 0 &&
          selectedTagsArray.map((item, index) => {
            cultureProfileDistinctSharedArr.push({
              cultureProfileId: item
            });
          });
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'cultureProfileDistinct' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'isDistinct', value: true }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'allocatedTagsArray', value: [...selectedTagsArray] }
          });
        } else {
          reqBody = {
            module: 'Culture-Profile-Distinct',
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              cultureProfileDistinctShare: typeShare,
              cultureProfileDistinctShareInformation: [],
              cultureProfileDistinctShared: cultureProfileDistinctSharedArr
            }
          };
          shareVal = 'cultureProfileDistinct';
          resetDataFunction();
        }
      }
      if (typeOfMiddlePaneList === 'jobProfilesDistinctReviewList') {
        let jobProfileDistinctSharedArr = [];
        selectedTagsArray.length > 0 &&
          selectedTagsArray.map((item, index) => {
            jobProfileDistinctSharedArr.push({
              jobProfileId: item
            });
          });
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'jobProfileDistinct' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'isDistinct', value: true }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'allocatedTagsArray', value: [...selectedTagsArray] }
          });
        } else {
          reqBody = {
            module: 'Job-Profile-Distinct',
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              jobProfileDistinctShare: typeShare,
              jobProfileDistinctShareInformation: [],
              jobProfileDistinctShared: jobProfileDistinctSharedArr
            }
          };
          shareVal = 'jobProfileDistinct';
          resetDataFunction();
        }
      }
      if (typeOfMiddlePaneList === 'itemsDistinctReviewList') {
        let itemDistinctSharedArr = [];
        selectedTagsArray.length > 0 &&
          selectedTagsArray.map((item, index) => {
            itemDistinctSharedArr.push({
              itemId: item
            });
          });
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'itemDistinct' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'isDistinct', value: true }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'allocatedTagsArray', value: [...selectedTagsArray] }
          });
        } else {
          reqBody = {
            module: 'Item-Distinct',
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              itemDistinctShare: typeShare,
              itemDistinctShareInformation: [],
              itemDistinctShared: itemDistinctSharedArr
            }
          };
          shareVal = 'itemDistinct';
          resetDataFunction();
        }
      }
      if (reqBody) {
        dispatch({ type: LOADER_START });
        if (
          shareVal === 'assesseeDistinct' ||
          shareVal === 'assessmentDistinct' ||
          shareVal === 'assignmentDistinct' ||
          shareVal === 'cultureProfileDistinct' ||
          shareVal === 'jobProfileDistinct' ||
          shareVal === 'itemDistinct'
        ) {
          dispatch({
            type: SHARE_DISTINCT_SAGA,
            payload: {
              secondaryOptionCheckValue: '',
              headerOne: '',
              request: reqBody,
              apiCall: dataVal,
              shareValue: shareVal
            }
          });
        } else {
          dispatch({
            //type: SHARE_ROLES_TYPES_SAGA,
            type: SHARE_GROUPS_ROLES_TYPES_SAGA,
            payload: {
              secondaryOptionCheckValue: '',
              headerOne: '',
              request: reqBody,
              apiCall: dataVal,
              shareValue: shareVal,
              createMode: createMode,
              cardValue: cardValue
            }
          });
        }
      }
      dispatch({ type: POPUP_CLOSE });
    } else if (dataVal === 'select') {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isSelectActive', value: secondaryOptionCheckValue }
      });
      dispatch({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'selectionValue', value: secondaryOptionCheckValue }
      });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'allocateStr', value: middlePaneHeader + middlePaneHeaderBadgeOne }
      });
      dispatch({ type: POPUP_CLOSE });
    } else if (dataVal === 'unselect') {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isSelectActive', value: '' }
      });
      dispatch({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'selectionValue', value: '' }
      });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'allocateStr', value: '' }
      });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'selectedTagsArray', value: [] }
      });
      dispatch({ type: POPUP_CLOSE });
    } else if (dataVal === 'no') {
      dispatch({ type: POPUP_CLOSE });
    }
    // else if (dataVal === 'flagedApiCall' || dataVal === 'unflagedApiCall') {}
    else if (dataVal === 'flaged') {
      if (typeOfMiddlePaneList !== 'associateNodeDistinctReviewList') {
        await setFlagedArray(reviewListDistinctData, 'assesseeFlag', dispatch);
      }
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'flagedValue', value: secondaryOptionCheckValue + dataVal }
      });
      dispatch({
        type: FILTERMODE,
        payload: { FilterMode: 'assesseeFlag' }
      });
      dispatch({ type: POPUP_CLOSE });
    } else if (dataVal === 'unflaged') {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'flagedValue', value: '' }
      });
      dispatch({
        type: FILTERMODE,
        payload: { FilterMode: 'assesseeDistinct' + middlePaneHeaderBadgeTwo }
      });
      dispatch({ type: POPUP_CLOSE });
      // deleted multiple after selction arry
    } else if (dataVal === 'no') {
      dispatch({ type: POPUP_CLOSE });
    } else if (dataVal === 'create') {
      if (
        (middlePaneHeader === 'assessees' ||
          middlePaneHeader === 'administrators' ||
          middlePaneHeader === 'assistants' ||
          middlePaneHeader === 'managers') &&
        middlePaneHeaderBadgeOne === 'distinct'
      ) {
        keyVal = 'assesseeCreate';
        // debugger
        dispatch({
          type: SET_MIDDLEPANE_SECONDARY_OPTION,
          payload: {
            badgeValue: dataVal,
            keyValue: keyVal,
            secondaryCheckValue: secondaryOptionCheckValue
          }
        });
      } else if (middlePaneHeaderBadgeOne !== 'distinct' || middlePaneHeader === 'gauges') {
        keyVal = 'createKey';
        // debugger
        dispatch({
          type: SET_MIDDLEPANE_SECONDARY_OPTION,
          payload: {
            badgeValue: dataVal,
            keyValue: keyVal,
            secondaryCheckValue: secondaryOptionCheckValue
          }
        });
      } else {
        dispatch({
          type: SET_MIDDLEPANE_SECONDARY_OPTION,
          payload: {
            badgeValue: dataVal,
            keyValue: keyVal,
            secondaryCheckValue: secondaryOptionCheckValue
          }
        });
      }
    } else if (
      keyVal === 'distinctAPICall' &&
      middlePaneHeader === 'assessees' &&
      paneOneSelectedSection === 'paneoneSection3'
    ) {
      getMarketPlaceAssesseesDistinctApiCall(
        selectedAssociateInfo,
        secondaryOptionCheckValue,
        countPage,
        dispatch,
        dataVal
      );
      resetDataFunction();
      dispatch({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'selectionValue', value: '' }
      });
      dispatch({ type: POPUP_CLOSE });
    } else if (
      keyVal === 'distinctAPICall' &&
      middlePaneHeader === 'assessees' &&
      paneOneSelectedSection !== 'paneoneSection3'
    ) {
      getAssesseeDistinctApiCall(
        selectedAssociateInfo,
        secondaryOptionCheckValue,
        countPage,
        dispatch,
        dataVal,
        false,
        assignmentCreateType
      );
      resetDataFunction();
      dispatch({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'selectionValue', value: '' }
      });
      dispatch({ type: POPUP_CLOSE });
    } else if (
      keyVal === 'distinctAPICall' &&
      (middlePaneHeader === 'administrators' ||
        middlePaneHeader === 'managers' ||
        middlePaneHeader === 'assistants')
    ) {
      getAdminManagerDistinctApiCall(
        selectedAssociateInfo,
        secondaryOptionCheckValue,
        countPage,
        middlePaneHeader,
        dispatch
      );
      dispatch({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'selectionValue', value: '' }
      });
      dispatch({ type: POPUP_CLOSE });
    } else if (keyVal === 'distinctAPICall' && middlePaneHeader === 'gauges') {
      let requestObj = {
        module: 'Analytics',
        action: 'Create-Gauge',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          action: 'review-list' //reviewId//reviewList
        }
      };
      dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
      dispatch({ type: SET_PAGE_COUNT, payload: 1 });
      dispatch({ type: POPUP_CLOSE });
      dispatch({ type: LOADER_START });
      resetDataFunction();
      dispatch({
        type: CREATE_GAUGE_SAGA,
        payload: {
          reqBody: requestObj,
          action: 'reviewList',
          isMiddlePaneList: true,
          BadgeOne: 'distinct',
          BadgeTwo: secondaryOptionCheckValue
        }
      });
    } else if (keyVal === 'distinctAPICall' && middlePaneHeader === 'associates') {
      resetDataFunction();
      if (secondaryOptionCheckValue === 'active') {
        getAssociateNodeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          dataVal,
          'distinct',
          'hierarchy'
        );
      } else {
        getAssociateDistinctApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          dispatch,
          countPage,
          dataVal
        );
      }
      // getAssociateDistinctApiCall(
      //   selectedAssociateInfo,
      //   secondaryOptionCheckValue,
      //   dispatch,
      //   countPage,
      //   dataVal
      // );
      // getAssociateNodeApiCall(
      //   selectedAssociateInfo,
      //   secondaryOptionCheckValue,
      //   countPage,
      //   dispatch,
      //   'nodes',
      //   'distinct',
      //   'hierarchy'
      // );
      dispatch({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'selectionValue', value: '' }
      });
      dispatch({ type: POPUP_CLOSE });
    } else if (keyVal === 'distinctAPICall' && middlePaneHeader === 'items') {
      getItemsDistinctApiCall(
        selectedAssociateInfo,
        secondaryOptionCheckValue,
        countPage,
        'items',
        dispatch,
        '',
        true
      );
      dispatch({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'selectionValue', value: '' }
      });
      dispatch({ type: POPUP_CLOSE });
    } else if (keyVal === 'distinctAPICall' && middlePaneHeader === 'assessments') {
      getAssessmentDistinctApiCall(
        selectedAssociateInfo,
        secondaryOptionCheckValue,
        countPage,
        dispatch,
        'distinct'
      );
      dispatch({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'selectionValue', value: '' }
      });
      dispatch({ type: POPUP_CLOSE });
      dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
    } else if (keyVal === 'distinctAPICall' && middlePaneHeader === 'assignments') {
      if (cardValue === 'Card') {
        dispatch({ type: POPUP_CLOSE });
      } else {
        resetDataFunction();
        assignmentsDistinctApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          'distinct',
          '',
          assignmentCreateType
        );
        dispatch({ type: POPUP_CLOSE });
        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
      }
    } else if (keyVal === 'distinctAPICall' && middlePaneHeader === 'culture-profiles') {
      getCultureProfilesDistinctApiCall(
        selectedAssociateInfo,
        secondaryOptionCheckValue,
        countPage,
        middlePaneHeader,
        dispatch
      );
      dispatch({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'selectionValue', value: '' }
      });
      dispatch({ type: POPUP_CLOSE });
    } else if (keyVal === 'distinctAPICall' && middlePaneHeader === 'job-profiles') {
      getJobProfilesDistinctApiCall(
        selectedAssociateInfo,
        secondaryOptionCheckValue,
        countPage,
        middlePaneHeader,
        dispatch
      );
      dispatch({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'selectionValue', value: '' }
      });
      dispatch({ type: POPUP_CLOSE });
    } else if (
      middlePaneHeader === 'assessees' ||
      middlePaneHeader === 'administrators' ||
      middlePaneHeader === 'assistants' ||
      middlePaneHeader === 'managers'
    ) {
      if (keyVal === 'distinct' && popupHeaderOneBadgeOne === 'groups') {
        // triple dot group view to hyrache
        if (
          middlePaneHeader === 'assessees' ||
          middlePaneHeader === 'administrators' ||
          middlePaneHeader === 'managers' ||
          middlePaneHeader === 'assistants'
        ) {
          let badgeTwo =
            middlePaneHeaderBadgeTwo === 'generic' || middlePaneHeaderBadgeTwo === 'bespoke'
              ? 'active'
              : secondaryOptionCheckValue;
          getAssesseeGroupDistinctApiCall(
            selectedAssociateInfo,
            // cardValue === 'Card'? middlePaneHeaderBadgeThree : badgeTwo,
            badgeTwo,
            countPage,
            dispatch,
            'groups',
            cardValue,
            '',
            'hierarchy',
            [],
            assignmentCreateType,
            middlePaneHeader
          );
          resetDataFunction();
        }
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'scanString', value: '' }
        });
        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'selectionValue', value: '' }
        });
        dispatch({ type: POPUP_CLOSE });
        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
      } else if (keyVal === 'distinct' && popupHeaderOneBadgeOne === 'nodes') {
        getInternalNodeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          'nodes',
          '',
          'hierarchy',
          middlePaneHeader
        );
        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'selectionValue', value: '' }
        });
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'distinct' && popupHeaderOneBadgeOne === 'roles') {
        if (
          middlePaneHeader === 'administrators' ||
          middlePaneHeader === 'managers' ||
          middlePaneHeader === 'assistants'
        ) {
          getAdminManagerRoleApiCall(
            selectedAssociateInfo,
            secondaryOptionCheckValue,
            countPage,
            popupHeaderOne,
            dispatch,
            cardValue
          );
        } else {
          getAssesseeRoleDistinctApiCall(
            selectedAssociateInfo,
            secondaryOptionCheckValue,
            countPage,
            middlePaneHeaderBadgeOne,
            dispatch,
            cardValue
          );
        }

        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'selectionValue', value: '' }
        });
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'distinct' && popupHeaderOneBadgeOne === 'types') {
        getAssesseeTypeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          'types',
          cardValue,
          middlePaneHeader
        );
        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'selectionValue', value: '' }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'flag') {
        let permissionObj;
        permissionObj = signedAssesseePermission?.assesseeDistinctPermission;
        console.log(
          keyVal,
          secondaryOptionCheckValue,
          '----------------****************--------------'
        );
        // debugger
        dispatch({
          type: SET_MIDDLEPANE_SECONDARY_OPTION,
          payload: {
            badgeValue: dataVal,
            keyValue: keyVal,
            secondaryCheckValue: 'multiple',
            permissionObject: permissionObj
          }
        });
      } else if (keyVal === 'groups' && popupHeaderOneBadgeTwo === 'move') {
        // debugger;
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'nodes' && popupHeaderOneBadgeTwo === 'move') {
        // debugger;
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'roles' && popupHeaderOneBadgeTwo === 'move') {
        // debugger;
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'types' && popupHeaderOneBadgeTwo === 'move') {
        // debugger;
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'groups' && popupHeaderOneBadgeTwo !== 'move') {
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        if (middlePaneHeader === 'assessees') {
          // getAssesseeGroupDistinctApiCall(
          //   selectedAssociateInfo,
          //   secondaryOptionCheckValue,
          //   countPage,
          //   dispatch,
          //   dataVal,
          //   cardValue,
          //   popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
          // );
          getAssesseeGroupDistinctApiCall(
            selectedAssociateInfo,
            secondaryOptionCheckValue,
            countPage,
            dispatch,
            'groups',
            cardValue,
            '',
            'hierarchy',
            [],
            assignmentCreateType
          );
        }
        filterModeKey = 'assesseeAllocateToGroup';
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'roles') {
        if (
          middlePaneHeader === 'administrators' ||
          middlePaneHeader === 'managers' ||
          middlePaneHeader === 'assistants'
        ) {
          getAdminManagerRoleApiCall(
            selectedAssociateInfo,
            secondaryOptionCheckValue,
            countPage,
            popupHeaderOne,
            dispatch,
            cardValue
          );
        } else {
          getAssesseeRoleDistinctApiCall(
            selectedAssociateInfo,
            secondaryOptionCheckValue,
            countPage,
            "roles",
            dispatch,
            cardValue
          );
        }
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'nodes') {
        getInternalNodeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          dataVal,
          '',
          'hierarchy',
          middlePaneHeader
        );
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'types') {
        getAssesseeTypeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          'types',
          cardValue,
          middlePaneHeader
        );
        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'selectionValue', value: '' }
        });
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'assignments') {
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        assignmentsDistinctApiCall(
          selectedAssociateInfo,
          'inactive',
          countPage,
          dispatch,
          'distinct',
          popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
        );
        filterModeKey = 'allocateToAssignment';
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'information') {
        dispatch({ type: POPUP_CLOSE });
        if (
          (middlePaneHeader === 'assessees' ||
            middlePaneHeader === 'administrators' ||
            middlePaneHeader === 'assistants' ||
            middlePaneHeader === 'managers') &&
          middlePaneHeaderBadgeOne === 'distinct'
        ) {
          assesseeCreateApiCalls(
            selectedAssociateInfo,
            dispatch,
            secondaryOptionCheckValue,
            middlePaneHeader === 'administrators'
              ? 'administrator'
              : middlePaneHeader === 'managers'
                ? 'manager'
                : middlePaneHeader === 'assistants'
                  ? 'assistant'
                  : 'assessee'
          );
        }
      } else if (keyVal === 'allocateApi') {
        if (secondaryOptionCheckValue === 'assessees' && tertiaryOptionCheckValue === 'distinct') {
          getAssesseeDistinctApiCall(
            selectedAssociateInfo,
            '',
            countPage,
            dispatch,
            'distinct',
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'allocateToAssessee';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assessees' &&
          tertiaryOptionCheckValue === 'groups'
        ) {
          getAssesseeGroupAllocateDistinctApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'groups',
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            "list",
            [],
            assignmentCreateType,
            selectedTagsArray
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'assesseeAllocateToGroup';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assessees' &&
          tertiaryOptionCheckValue === 'types'
        ) {
          getAssesseeAllocateTypeApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'types',
            cardValue,
            'assessees',
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            selectedTagsArray
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'assesseeAllocateToType';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assessees' &&
          tertiaryOptionCheckValue === 'nodes'
        ) {
          getInternalNodeApiCall(
            selectedAssociateInfo,
            secondaryOptionCheckValue,
            countPage,
            dispatch,
            'nodes',
            '',
            'list',
            secondaryOptionCheckValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'assesseeAllocateToNode';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assessees' &&
          tertiaryOptionCheckValue === 'roles'
        ) {
          // // debugger
          getAssesseeRoleAllocateDistinctApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            'roles',
            dispatch,
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            selectedTagsArray
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'assesseeAllocateToRole';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assignments' &&
          tertiaryOptionCheckValue === 'distinct'
        ) {
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          assignmentsDistinctApiCall(
            selectedAssociateInfo,
            'activeinactive',
            countPage,
            dispatch,
            'distinct',
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            assignmentCreateType
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'allocateToAssignment';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assignments' &&
          tertiaryOptionCheckValue === 'groups'
        ) {
          assignmentsGroupApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'groups',
            'noCard',
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            "list",
            [],
            assignmentCreateType
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'assignmentAllocateToGroup';
          dispatch({ type: POPUP_CLOSE });
        }
      } else if (keyVal === 'unallocateApi') {
        if (secondaryOptionCheckValue === 'assessees') {
          switch (tertiaryOptionCheckValue) {
            case 'distinct': {
              getAssesseeDistinctApiCall(
                selectedAssociateInfo,
                '',
                countPage,
                dispatch,
                'distinct',
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
              );
              filterModeKey = 'unAllocateToAssessee';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
            case 'groups': {
              getAssesseeGroupAllocateDistinctApiCall(
                selectedAssociateInfo,
                'active',
                countPage,
                dispatch,
                'groups',
                cardValue,
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
                "list",
                [],
                assignmentCreateType,
                selectedTagsArray
              );
              filterModeKey = 'assesseeUnAllocateToGroup';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
            case 'nodes': {
              getInternalNodeApiCall(
                selectedAssociateInfo,
                secondaryOptionCheckValue,
                countPage,
                dispatch,
                'nodes',
                '',
                'list',
                secondaryOptionCheckValue,
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
              );
              filterModeKey = 'assesseeUnAllocateToNode';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
            case 'roles': {
              getAssesseeRoleAllocateDistinctApiCall(
                selectedAssociateInfo,
                'active',
                countPage,
                'roles',
                dispatch,
                cardValue,
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
                selectedTagsArray
              );
              filterModeKey = 'assesseeUnAllocateToRole';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
            case 'types': {
              getAssesseeAllocateTypeApiCall(
                selectedAssociateInfo,
                'active',
                countPage,
                dispatch,
                'types',
                cardValue,
                'assessees',
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
                selectedTagsArray
              );
              filterModeKey = 'assesseeUnAllocateToType';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
          }
        } else if (secondaryOptionCheckValue === 'assignments') {
          switch (tertiaryOptionCheckValue) {
            case 'groups': {
              assignmentsGroupApiCall(
                selectedAssociateInfo,
                'active',
                countPage,
                dispatch,
                'groups',
                cardValue,
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
                "list",
                [],
                assignmentCreateType
              );
              filterModeKey = 'assignmentUnAllocateToGroup';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
            case 'distinct': {
              dispatch({
                type: SET_DISPLAY_TWO_SINGLE_STATE,
                payload: { stateName: 'typeOfMiddlePaneList', value: '' }
              });
              assignmentsDistinctApiCall(
                selectedAssociateInfo,
                'activeinactive',
                countPage,
                dispatch,
                'distinct',
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
                assignmentCreateType
              );
              filterModeKey = 'unAllocateToAssignment';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
          }
        }
      } else {
        let permissionObj;
        // if (middlePaneHeaderBadgeOne === 'distinct') {
        permissionObj = signedAssesseePermission?.assesseeDistinctPermission;
        // }
        console.log(
          keyVal,
          dataVal,
          secondaryOptionCheckValue,
          permissionObj,
          '----------------****************--------------'
        );
        // debugger
        dispatch({
          type: SET_MIDDLEPANE_SECONDARY_OPTION,
          payload: {
            badgeValue: dataVal,
            keyValue: keyVal,
            secondaryCheckValue: secondaryOptionCheckValue,
            permissionObject: permissionObj
          }
        });
      }
    } else if (middlePaneHeader === 'gauges') {
      if (keyVal === 'groups' || keyVal === 'roles' || keyVal === 'types') {
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'distinct') {
        getGuageDistinctApiCall(selectedAssociateInfo, secondaryOptionCheckValue, dispatch, keyVal);
        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'selectionValue', value: '' }
        });
        dispatch({ type: POPUP_CLOSE });
        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
      } else if (keyVal === 'distinct' && popupHeaderOneBadgeOne === 'nodes') {
        getInternalNodeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          'nodes',
          '',
          'hierarchy',
          middlePaneHeader
        );
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'nodes') {
        getInternalNodeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          dataVal,
          '',
          'hierarchy',
          middlePaneHeader
        );
        dispatch({ type: POPUP_CLOSE });
      } else {
        // debugger
        dispatch({
          type: SET_MIDDLEPANE_SECONDARY_OPTION,
          payload: {
            badgeValue: dataVal,
            keyValue: keyVal,
            secondaryCheckValue: secondaryOptionCheckValue,
            permissionObject: signedAssesseePermission?.iGuruAnalyticDistinctPermission
          }
        });
      }
    } else if (middlePaneHeader === 'profilers') {
      if (keyVal === 'distinct') {
        resetDataFunction();
        let requestType = 'Review-List';
        let profilesData = [
          {
            id: 'iGauge Occupation (Culture-Profiler)',
            profilerName: 'iGauge Occupation',
            profilerDescription: 'Culture-Profiler'
          },
          {
            id: 'iGauge Occupation (Job-Profiler)',
            profilerName: ' iGauge Occupation',
            profilerDescription: 'Job-Profiler'
          }
        ];
        getCultureProfileDiamentionList(selectedAssociateInfo, requestType, dispatch);
        dispatch({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: 'profilers',
            middlePaneHeaderBadgeOne: 'distinct',
            // middlePaneHeaderBadgeTwo: secondaryOptionCheckValue, list is static so set active badge only
            middlePaneHeaderBadgeTwo: 'active',
            middlePaneHeaderBadgeThree: '',
            middlePaneHeaderBadgeFour: '',
            middlePaneHeaderDuplicate: 'profilers',
            middlePaneHeaderDuplicateBadgeOne: 'distinct',
            middlePaneHeaderDuplicateBadgeTwo: 'active',
            middlePaneHeaderDuplicateBadgeThree: '',
            middlePaneHeaderDuplicateBadgeFour: '',
            typeOfMiddlePaneList: 'profilerDistinctReviewList',
            scanCount: 2,
            duplicateScanCount: 2,
            showMiddlePaneState: true
          }
        });
        dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'reviewListDistinctData', value: profilesData }
        });
        dispatch({ type: POPUP_CLOSE });
        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
        dispatch({ type: LOADER_START });
      } else {
        // debugger
        dispatch({
          type: SET_MIDDLEPANE_SECONDARY_OPTION,
          payload: {
            badgeValue: dataVal,
            keyValue: keyVal === 'reviewDistinct' ? 'reviewDistinctKey' : keyVal,
            secondaryCheckValue: secondaryOptionCheckValue,
            permissionObject: signedAssesseePermission?.iGuruAnalyticDistinctPermission
          }
        });
      }
    } else if (middlePaneHeader === 'assessments') {
      if (keyVal === 'distinct' && popupHeaderOneBadgeOne === 'groups') {
        let badgeTwo =
          middlePaneHeaderBadgeTwo === 'generic' || middlePaneHeaderBadgeTwo === 'bespoke'
            ? 'active'
            : secondaryOptionCheckValue;
        getAssessmentGroupApiCall(
          selectedAssociateInfo,
          //cardValue === 'Card' ? middlePaneHeaderBadgeThree : middlePaneHeaderBadgeTwo,
          badgeTwo,
          countPage,
          dispatch,
          'groups',
          cardValue,
          '',
          'hierarchy', //siftValue
          [],
          assignmentCreateType
        );
        resetDataFunction();
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'scanString', value: '' }
        });
        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'selectionValue', value: '' }
        });
        dispatch({ type: POPUP_CLOSE });
        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
      } else if (keyVal === 'distinct' && popupHeaderOneBadgeOne === 'nodes') {
        getInternalNodeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          'nodes',
          '',
          'hierarchy',
          middlePaneHeader
        );
        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'selectionValue', value: '' }
        });
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'distinct' && popupHeaderOneBadgeOne === 'types') {
        getAssessmentTypeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          'types',
          cardValue
        );
        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'selectionValue', value: '' }
        });
        dispatch({ type: POPUP_CLOSE });
      } else if (
        keyVal === 'distinct' &&
        typeOfMiddlePaneList === 'assesseesAssginmentAssessmentReviewList'
      ) {
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
        let selectedAssignment = reviewListDistinctData.filter((assignment) => {
          return (
            assignment.assesseeAssignmentId ===
            relatedReviewListDistinctData[0].assesseeAssignmentId
          );
        });
        let statusArr = [
          'STARTED',
          'UNSTARTED',
          'ABORTED',
          'FINISHED',
          'UNFINISHED',
          'SUSPENDED',
          'TERMINATED'
        ];
        if (secondaryOptionCheckValue === 'active') statusArr = ['STARTED', 'UNSTARTED'];
        if (secondaryOptionCheckValue === 'inactive')
          statusArr = ['ABORTED', 'FINISHED', 'UNFINISHED', 'SUSPENDED', 'TERMINATED'];
        if (secondaryOptionCheckValue === 'archived') statusArr = ['ARCHIVED'];
        let selectedAssessment = selectedAssignment[0].assesseeAssignmentAssessmentDistinct.filter(
          (assessment) => {
            return statusArr.includes(assessment.assesseeAssignmentAssessmentStatus);
          }
        );
        let obj = {
          ...selectedAssignment[0],
          assesseeAssignmentAssessmentDistinct: selectedAssessment
        };
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [obj] }
        });
        dispatch({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: middlePaneHeader,
            middlePaneHeaderBadgeOne: secondaryOptionCheckValue,
            middlePaneHeaderBadgeTwo: middlePaneHeaderBadgeTwo,
            middlePaneHeaderBadgeThree: '',
            middlePaneHeaderBadgeFour: '',
            middlePaneHeaderDuplicate: middlePaneHeaderDuplicate,
            middlePaneHeaderDuplicateBadgeOne: middlePaneHeaderDuplicateBadgeOne,
            middlePaneHeaderDuplicateBadgeTwo: '',
            middlePaneHeaderDuplicateBadgeThree: '',
            middlePaneHeaderDuplicateBadgeFour: '',
            typeOfMiddlePaneList: typeOfMiddlePaneList,
            scanCount: selectedAssessment.length,
            duplicateScanCount: duplicateScanCount,
            showMiddlePaneState: true
          }
        });
        dispatch({
          type: FILTERMODE,
          payload: { FilterMode: 'assesseeAssignmentDistinct' + secondaryOptionCheckValue }
        });
        console.log('action performed');
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'flag') {
        let permissionObj;
        permissionObj = signedAssesseePermission?.assesseeDistinctPermission;
        console.log(
          keyVal,
          secondaryOptionCheckValue,
          '----------------****************--------------'
        );
        // debugger
        dispatch({
          type: SET_MIDDLEPANE_SECONDARY_OPTION,
          payload: {
            badgeValue: dataVal,
            keyValue: keyVal,
            secondaryCheckValue: 'multiple',
            permissionObject: permissionObj
          }
        });
      } else if (keyVal === 'groups' && popupHeaderOneBadgeTwo === 'move') {
        // debugger;
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'nodes' && popupHeaderOneBadgeTwo === 'move') {
        // debugger;
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'types' && popupHeaderOneBadgeTwo === 'move') {
        // debugger;
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'groups' && popupHeaderOneBadgeTwo !== 'move') {
        getAssessmentGroupApiCall(
          selectedAssociateInfo,
          // secondaryOptionCheckValue,
          cardValue === 'Card' ? middlePaneHeaderBadgeThree : middlePaneHeaderBadgeTwo,
          countPage,
          dispatch,
          'groups',
          cardValue,
          '',
          'hierarchy',
          [],
          assignmentCreateType
        );
        dispatch({ type: POPUP_CLOSE });
        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'selectionValue', value: '' }
        });
      } else if (keyVal === 'types') {
        getAssessmentTypeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          'types',
          cardValue
        );
        dispatch({ type: POPUP_CLOSE });
        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'selectionValue', value: '' }
        });
      } else if (keyVal === 'nodes') {
        getInternalNodeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          dataVal,
          '',
          'hierarchy',
          middlePaneHeader
        );
        dispatch({ type: POPUP_CLOSE });
        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'selectionValue', value: '' }
        });
      } else if (keyVal === 'allocateApi') {
        if (
          secondaryOptionCheckValue === 'assessments' &&
          tertiaryOptionCheckValue === 'distinct'
        ) {
          getAssessmentDistinctApiCall(
            selectedAssociateInfo,
            '',
            countPage,
            dispatch,
            'distinct',
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'allocateToAssessment';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assessments' &&
          tertiaryOptionCheckValue === 'groups'
        ) {
          getAssessmentGroupAllocateApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'groups',
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            "list",
            [],
            assignmentCreateType,
            selectedTagsArray
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'assessmentAllocateToGroup';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assessments' &&
          tertiaryOptionCheckValue === 'types'
        ) {
          getAssessmentTypeAllocateApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'types',
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            selectedTagsArray
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'assessmentAllocateToType';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assessments' &&
          tertiaryOptionCheckValue === 'nodes'
        ) {
          getInternalNodeApiCall(
            selectedAssociateInfo,
            secondaryOptionCheckValue,
            countPage,
            dispatch,
            'nodes',
            '',
            'list',
            secondaryOptionCheckValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'assessmentAllocateToNode';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assignments' &&
          tertiaryOptionCheckValue === 'distinct'
        ) {
          assignmentsDistinctApiCall(
            selectedAssociateInfo,
            'unpublished',
            countPage,
            dispatch,
            'distinct',
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            assignmentCreateType
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'allocateToAssignment';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assignments' &&
          tertiaryOptionCheckValue === 'groups'
        ) {
          assignmentsGroupApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'groups',
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            "list",
            [],
            assignmentCreateType
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'assignmentAllocateToGroup';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'items' &&
          tertiaryOptionCheckValue == 'distinct'
        ) {
          getItemsDistinctApiCall(
            selectedAssociateInfo,
            'published',
            countPage,
            'distinct',
            dispatch,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'allocateToItem';
          dispatch({ type: POPUP_CLOSE });
        } else if (secondaryOptionCheckValue === 'items' && tertiaryOptionCheckValue === 'groups') {
          getItemGroupDistinctApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'groups',
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            'list',
            [],
            false,
            assignmentCreateType
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'itemAllocateToGroup';
          dispatch({ type: POPUP_CLOSE });
        }
      } else if (keyVal === 'unallocateApi') {
        if (secondaryOptionCheckValue === 'assessments') {
          switch (tertiaryOptionCheckValue) {
            case 'distinct': {
              if (middlePaneHeaderBadgeOne !== 'distinct') {
                getAssessmentDistinctApiCall(
                  selectedAssociateInfo,
                  '',
                  countPage,
                  dispatch,
                  'distinct',
                  cardValue,
                  popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
                );
                filterModeKey = 'unAllocateToAssessment';
                dispatch({ type: POPUP_CLOSE });
                isValidAllocationOrUnallocation = true;
              }
              break;
            }
            case 'groups': {
              getAssessmentGroupAllocateApiCall(
                selectedAssociateInfo,
                'active',
                countPage,
                dispatch,
                'groups',
                cardValue,
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
                "list",
                [],
                assignmentCreateType,
                selectedTagsArray
              );
              filterModeKey = 'assessmentUnAllocateToGroup';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
            case 'nodes': {
              getInternalNodeApiCall(
                selectedAssociateInfo,
                secondaryOptionCheckValue,
                countPage,
                dispatch,
                'nodes',
                '',
                'list',
                secondaryOptionCheckValue,
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
              );
              filterModeKey = 'assessmentUnAllocateToNode';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
            case 'types': {
              getAssessmentTypeAllocateApiCall(
                selectedAssociateInfo,
                'active',
                countPage,
                dispatch,
                'types',
                cardValue,
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
                selectedTagsArray
              );
              filterModeKey = 'assessmentUnAllocateToType';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
          }
        } else if (secondaryOptionCheckValue === 'assignments') {
          switch (tertiaryOptionCheckValue) {
            case 'distinct': {
              dispatch({
                type: SET_DISPLAY_TWO_SINGLE_STATE,
                payload: { stateName: 'typeOfMiddlePaneList', value: '' }
              });
              assignmentsDistinctApiCall(
                selectedAssociateInfo,
                'unpublished',
                countPage,
                dispatch,
                'distinct',
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
                assignmentCreateType
              );
              filterModeKey = 'unAllocateToAssignment';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
            case 'groups': {
              assignmentsGroupApiCall(
                selectedAssociateInfo,
                'active',
                countPage,
                dispatch,
                'groups',
                cardValue,
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
                "list",
                [],
                assignmentCreateType
              );
              filterModeKey = 'assignmentUnAllocateToGroup';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
          }
        } else if (secondaryOptionCheckValue === 'items') {
          switch (tertiaryOptionCheckValue) {
            case 'distinct': {
              getItemsDistinctApiCall(
                selectedAssociateInfo,
                // 'all',
                'published',
                countPage,
                'distinct',
                dispatch,
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
              );
              filterModeKey = 'unAllocateToItem';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
            case 'groups': {
              getItemGroupDistinctApiCall(
                selectedAssociateInfo,
                'active',
                countPage,
                dispatch,
                'groups',
                cardValue,
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
                'list',
                [],
                false,
                assignmentCreateType
              );
              filterModeKey = 'itemUnAllocateToGroup';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
          }
        }
      } else {
        let permissionObj;
        // if (middlePaneHeaderBadgeOne === 'distinct') {
        permissionObj = signedAssesseePermission?.assessmentDistictPermission;
        // }
        // debugger
        dispatch({
          type: SET_MIDDLEPANE_SECONDARY_OPTION,
          payload: {
            badgeValue: dataVal,
            keyValue: keyVal,
            secondaryCheckValue: secondaryOptionCheckValue,
            permissionObject: permissionObj,
            middlePaneHeaderBadgeOne,
            middlePaneHeaderBadgeTwo
          }
        });
      }
    } else if (middlePaneHeader === 'items') {
      if (keyVal === 'distinct' && popupHeaderOneBadgeOne === 'types') {
        getItemsTypeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          'types',
          popupHeaderOneBadgeOne,
          cardValue
        );
        dispatch({ type: POPUP_CLOSE });
        resetDataFunction();
      }
      if (keyVal === 'distinct' && popupHeaderOneBadgeOne === 'groups') {
        getItemGroupDistinctApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          //cardValue === 'Card' ? middlePaneHeaderBadgeThree : middlePaneHeaderBadgeTwo,
          countPage,
          dispatch,
          'groups',
          cardValue,
          '',
          'hierarchy', //siftValue
          [],
          false,
          assignmentCreateType
        );
        resetDataFunction();
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'scanString', value: '' }
        });
        dispatch({ type: POPUP_CLOSE });
        dispatch({ type: POPUP_CLOSE });
        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
      } else if (keyVal === 'distinct' && popupHeaderOneBadgeOne === 'nodes') {
        getInternalNodeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          'nodes',
          '',
          'hierarchy',
          middlePaneHeader
        );
        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'selectionValue', value: '' }
        });
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'flag') {
        let permissionObj;
        permissionObj = signedAssesseePermission?.assesseeDistinctPermission;
        console.log(
          keyVal,
          secondaryOptionCheckValue,
          '----------------****************--------------'
        );
        // debugger
        dispatch({
          type: SET_MIDDLEPANE_SECONDARY_OPTION,
          payload: {
            badgeValue: dataVal,
            keyValue: keyVal,
            secondaryCheckValue: 'multiple',
            permissionObject: permissionObj
          }
        });
      } else if (keyVal === 'groups' && popupHeaderOneBadgeTwo === 'move') {
        // debugger;
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'nodes' && popupHeaderOneBadgeTwo === 'move') {
        // debugger;
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'types' && popupHeaderOneBadgeTwo === 'move') {
        // debugger;
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'groups' && popupHeaderOneBadgeTwo !== 'move') {
        //
        getItemGroupDistinctApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          'groups',
          cardValue,
          '',
          'hierarchy', //siftValue
          [],
          false,
          assignmentCreateType
        );
        dispatch({ type: POPUP_CLOSE });
        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'selectionValue', value: '' }
        });
      } else if (keyVal === 'nodes') {
        getInternalNodeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          dataVal,
          '',
          'hierarchy',
          middlePaneHeader
        );
        dispatch({ type: POPUP_CLOSE });
        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'selectionValue', value: '' }
        });
      } else if (keyVal === 'types') {
        getItemsTypeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          'types',
          popupHeaderOne,
          'noCard'
        );
        dispatch({ type: POPUP_CLOSE });
        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'selectionValue', value: '' }
        });
      } else if (keyVal === 'allocateApi') {
        if (secondaryOptionCheckValue === 'items' && tertiaryOptionCheckValue === 'distinct') {
          getItemsDistinctApiCall(
            selectedAssociateInfo,
            '',
            countPage,
            'distinct',
            dispatch,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'allocateToItem';
          dispatch({ type: POPUP_CLOSE });
        } else if (secondaryOptionCheckValue === 'items' && tertiaryOptionCheckValue === 'groups') {
          getItemGroupDistinctAllocateApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'groups',
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            'list',
            [],
            false,
            assignmentCreateType,
            selectedTagsArray
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'itemAllocateToGroup';
          dispatch({ type: POPUP_CLOSE });
        } else if (secondaryOptionCheckValue === 'items' && tertiaryOptionCheckValue === 'nodes') {
          getInternalNodeApiCall(
            selectedAssociateInfo,
            secondaryOptionCheckValue,
            countPage,
            dispatch,
            'nodes',
            '',
            'list',
            secondaryOptionCheckValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'itemAllocateToNode';
          dispatch({ type: POPUP_CLOSE });
        } else if (secondaryOptionCheckValue === 'items' && tertiaryOptionCheckValue === 'types') {
          getItemsTypeAllocateApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'types',
            middlePaneHeader,
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            selectedTagsArray
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'itemAllocateToType';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assessments' &&
          tertiaryOptionCheckValue === 'distinct'
        ) {
          getAssessmentDistinctApiCall(
            selectedAssociateInfo,
            'unpublished',
            countPage,
            dispatch,
            'distinct',
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'itemAllocateToAssessment';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assessments' &&
          tertiaryOptionCheckValue === 'groups'
        ) {
          getAssessmentGroupApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'groups',
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            "list",
            [],
            assignmentCreateType
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'assessmentAllocateToGroup';
          dispatch({ type: POPUP_CLOSE });
        }
      } else if (keyVal === 'unallocateApi') {
        if (secondaryOptionCheckValue === 'items') {
          switch (tertiaryOptionCheckValue) {
            case 'distinct': {
              if (middlePaneHeaderBadgeOne !== 'distinct') {
                getItemsDistinctApiCall(
                  selectedAssociateInfo,
                  '', // 'all',
                  countPage,
                  'distinct',
                  dispatch,
                  popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
                );
                isValidAllocationOrUnallocation = true;
                filterModeKey = 'unAllocateToItem';
                dispatch({ type: POPUP_CLOSE });
              }
              break;
            }
            case 'groups': {
              getItemGroupDistinctAllocateApiCall(
                selectedAssociateInfo,
                'active',
                countPage,
                dispatch,
                'groups',
                cardValue,
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
                'list',
                [],
                false,
                assignmentCreateType,
                selectedTagsArray
              );
              filterModeKey = 'itemUnAllocateToGroup';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
            case 'nodes': {
              getInternalNodeApiCall(
                selectedAssociateInfo,
                secondaryOptionCheckValue,
                countPage,
                dispatch,
                'nodes',
                '',
                'list',
                secondaryOptionCheckValue,
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
              );
              filterModeKey = 'itemUnAllocateToNode';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
            case 'types': {
              getItemsTypeAllocateApiCall(
                selectedAssociateInfo,
                'active',
                countPage,
                dispatch,
                'types',
                middlePaneHeader,
                cardValue,
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
                selectedTagsArray
              );
              filterModeKey = 'itemUnAllocateToType';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
          }
        } else if (secondaryOptionCheckValue === 'assessments') {
          switch (tertiaryOptionCheckValue) {
            case 'distinct': {
              getAssessmentDistinctApiCall(
                selectedAssociateInfo,
                'unpublished',
                countPage,
                dispatch,
                'distinct',
                cardValue,
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
              );
              filterModeKey = 'itemUnAllocateToAssessment';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
            case 'groups': {
              getAssessmentGroupApiCall(
                selectedAssociateInfo,
                'active',
                countPage,
                dispatch,
                'groups',
                cardValue,
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
                "list",
                [],
                assignmentCreateType
              );
              filterModeKey = 'assessmentUnAllocateToGroup';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
          }
        }
      } else {
        let permissionObj;
        // if (middlePaneHeaderBadgeOne === 'distinct') {
        permissionObj = signedAssesseePermission?.itemDistictPermssion;
        // }
        // debugger
        dispatch({
          type: SET_MIDDLEPANE_SECONDARY_OPTION,
          payload: {
            badgeValue: dataVal,
            keyValue: keyVal,
            secondaryCheckValue: secondaryOptionCheckValue,
            permissionObject: permissionObj
          }
        });
      }
    } else if (middlePaneHeader === 'assignments') {
      if (typeOfMiddlePaneList === 'assesseeAssignmentDistinctReviewList') {
        if (dataVal === 'distinct' && popupHeaderOne === 'assignments') {
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'middlePaneSelectedValue', value: '' }
          });
          let statusArr = [
            'STARTED',
            'UNSTARTED',
            'ABORTED',
            'FINISHED',
            'UNFINISHED',
            'SUSPENDED',
            'TERMINATED',
            'ADMINISTERED'
          ];
          if (secondaryOptionCheckValue.toUpperCase() === 'ACTIVE') {
            statusArr = ['STARTED', 'UNSTARTED', 'ADMINISTERED'];
          }
          if (secondaryOptionCheckValue.toUpperCase() === 'INACTIVE') {
            statusArr = [
              'ABORTED',
              'FINISHED',
              'UNFINISHED',
              'SUSPENDED',
              'TERMINATED',
              'ADMINISTERED'
            ];
          }
          if (secondaryOptionCheckValue.toUpperCase() === 'ARCHIVED') {
            statusArr = ['ARCHIVED', 'ADMINISTERED'];
          }
          getAssesseeSelfAssignmentList(
            selectedAssociateInfo,
            countPage,
            statusArr,
            dispatch,
            secondaryOptionCheckValue
          );
          dispatch({
            type: FILTERMODE,
            payload: { FilterMode: 'assesseeAssignmentDistinct' + secondaryOptionCheckValue }
          });
          dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'relatedReviewListDistinctData', value: [] }
          });
          dispatch({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: { stateName: 'assesseeAssignmentAssessmentData', value: null }
          });
          dispatch({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: { stateName: 'assesseeAssessmentStartData', value: null }
          });
          dispatch({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: { stateName: 'isAssessmentStart', value: '' }
          });
          dispatch({ type: POPUP_CLOSE });
          dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
        }
      }
      if (keyVal === 'distinct' && popupHeaderOneBadgeOne === 'groups') {
        let badgeTwo =
          middlePaneHeaderBadgeTwo === 'generic' || middlePaneHeaderBadgeTwo === 'bespoke'
            ? 'active'
            : secondaryOptionCheckValue;
        assignmentsGroupApiCall(
          selectedAssociateInfo,
          badgeTwo,
          countPage,
          dispatch,
          'groups',
          cardValue,
          '',
          'hierarchy',
          [],
          assignmentCreateType
        );
        resetDataFunction();
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'scanString', value: '' }
        });
        resetDataFunction();
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'distinct' && popupHeaderOneBadgeOne === 'nodes') {
        getInternalNodeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          'nodes',
          '',
          'hierarchy',
          middlePaneHeader
        );
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'distinct' && popupHeaderOneBadgeOne === 'types') {
        assignmentTypeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          'types',
          cardValue,
          ''
        );
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'flag') {
        let permissionObj;
        permissionObj = signedAssesseePermission?.assesseeDistinctPermission;
        console.log(
          keyVal,
          secondaryOptionCheckValue,
          '----------------****************--------------'
        );
        // debugger;
        dispatch({
          type: SET_MIDDLEPANE_SECONDARY_OPTION,
          payload: {
            badgeValue: dataVal,
            keyValue: keyVal,
            secondaryCheckValue: 'multiple',
            permissionObject: permissionObj
          }
        });
      } else if (keyVal === 'groups' && popupHeaderOneBadgeTwo === 'move') {
        // debugger;
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'nodes' && popupHeaderOneBadgeTwo === 'move') {
        // debugger;
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'types' && popupHeaderOneBadgeTwo === 'move') {
        // debugger;
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'groups' && popupHeaderOneBadgeTwo !== 'move') {
        assignmentsGroupApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          keyVal,
          cardValue,
          '',
          'hierarchy',
          [],
          assignmentCreateType
        );
        dispatch({ type: POPUP_CLOSE });
        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'selectionValue', value: '' }
        });
      } else if (keyVal === 'nodes') {
        getInternalNodeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          dataVal,
          '',
          'hierarchy',
          middlePaneHeader
        );
        dispatch({ type: POPUP_CLOSE });
        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'selectionValue', value: '' }
        });
      } else if (keyVal === 'types') {
        assignmentTypeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          keyVal,
          cardValue
        );
        dispatch({ type: POPUP_CLOSE });
        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'selectionValue', value: '' }
        });
      } else if (keyVal === 'allocateApi') {
        if (
          secondaryOptionCheckValue === 'assignments' &&
          tertiaryOptionCheckValue === 'distinct'
        ) {
          assignmentsDistinctApiCall(
            selectedAssociateInfo,
            '',
            countPage,
            dispatch,
            'distinct',
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            assignmentCreateType
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'allocateToAssignment';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assignments' &&
          tertiaryOptionCheckValue === 'groups'
        ) {
          assignmentsGroupAllocateApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'groups',
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            "list",
            [],
            assignmentCreateType,
            selectedTagsArray
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'assignmentAllocateToGroup';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assignments' &&
          tertiaryOptionCheckValue === 'types'
        ) {
          assignmentTypeAllocateApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'types',
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            selectedTagsArray
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'assignmentAllocateToType';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assignments' &&
          tertiaryOptionCheckValue === 'nodes'
        ) {
          getInternalNodeApiCall(
            selectedAssociateInfo,
            secondaryOptionCheckValue,
            countPage,
            dispatch,
            'nodes',
            '',
            'list',
            secondaryOptionCheckValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'assignmentAllocateToNode';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assessees' &&
          tertiaryOptionCheckValue === 'distinct'
        ) {
          getAssesseeDistinctApiCall(
            selectedAssociateInfo,
            'activeinactive',
            countPage,
            dispatch,
            'distinct',
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'allocateToAssessee';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assessees' &&
          tertiaryOptionCheckValue === 'groups'
        ) {
          getAssesseeGroupDistinctApiCall(
            selectedAssociateInfo,
            'all',
            countPage,
            dispatch,
            'groups',
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            "list",
            [],
            assignmentCreateType
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'assesseeAllocateToGroup';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assessments' &&
          tertiaryOptionCheckValue === 'distinct'
        ) {
          getAssessmentDistinctApiCall(
            selectedAssociateInfo,
            'published',
            countPage,
            dispatch,
            'distinct',
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'allocateToAssessment';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assessments' &&
          tertiaryOptionCheckValue === 'groups'
        ) {
          getAssessmentGroupApiCall(
            selectedAssociateInfo,
            'all',
            countPage,
            dispatch,
            'groups',
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            "list",
            [],
            assignmentCreateType
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'assessmentAllocateToGroup';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'culture-profiles' &&
          tertiaryOptionCheckValue === 'distinct'
        ) {
          getCultureProfilesDistinctApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            '',
            dispatch,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'allocateToCultureProfile';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'culture-profiles' &&
          tertiaryOptionCheckValue === 'groups'
        ) {
          getCultureProfileGroupApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'groups',
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            [],
            'list',
            false,
            ""
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'allocateToCultureProfileGroup';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'job-profiles' &&
          tertiaryOptionCheckValue === 'distinct'
        ) {
          getJobProfilesDistinctApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            '',
            dispatch,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'allocateToJobProfile';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'job-profiles' &&
          tertiaryOptionCheckValue == 'groups'
        ) {
          getJobProfileGroupApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'groups',
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            [],
            'list',
            false,
            // assignmentCreateType
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'jobProfileAllocateToGroup';
          dispatch({ type: POPUP_CLOSE });
        }
      } else if (keyVal === 'unallocateApi') {
        if (secondaryOptionCheckValue === 'assignments') {
          switch (tertiaryOptionCheckValue) {
            case 'distinct': {
              if (middlePaneHeaderBadgeOne !== 'distinct') {
                dispatch({
                  type: SET_DISPLAY_TWO_SINGLE_STATE,
                  payload: { stateName: 'typeOfMiddlePaneList', value: '' }
                });
                assignmentsDistinctApiCall(
                  selectedAssociateInfo,
                  '',
                  countPage,
                  dispatch,
                  'distinct',
                  popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
                  assignmentCreateType
                );
                filterModeKey = 'unAllocateToAssignment';
                dispatch({ type: POPUP_CLOSE });
              }
              isValidAllocationOrUnallocation = true;
              break;
            }
            case 'groups': {
              assignmentsGroupAllocateApiCall(
                selectedAssociateInfo,
                'active',
                countPage,
                dispatch,
                'groups',
                cardValue,
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
                "list",
                [],
                assignmentCreateType,
                selectedTagsArray
              );
              filterModeKey = 'assignmentUnAllocateToGroup';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
            case 'types': {
              assignmentTypeAllocateApiCall(
                selectedAssociateInfo,
                'active',
                countPage,
                dispatch,
                'types',
                cardValue,
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
                selectedTagsArray
              );
              filterModeKey = 'assignmentUnAllocateToType';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
            case 'nodes': {
              getInternalNodeApiCall(
                selectedAssociateInfo,
                secondaryOptionCheckValue,
                countPage,
                dispatch,
                'nodes',
                '',
                'list',
                secondaryOptionCheckValue,
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
              );
              filterModeKey = 'assignmentUnAllocateToNode';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
          }
        } else if (secondaryOptionCheckValue === 'assessees') {
          switch (tertiaryOptionCheckValue) {
            case 'distinct': {
              getAssesseeDistinctApiCall(
                selectedAssociateInfo,
                'activeinactive',
                countPage,
                dispatch,
                'distinct',
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
              );
              filterModeKey = 'unAllocateToAssessee';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
            case 'groups': {
              getAssesseeGroupDistinctApiCall(
                selectedAssociateInfo,
                'all',
                countPage,
                dispatch,
                'groups',
                cardValue,
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
                "list",
                [],
                assignmentCreateType
              );
              filterModeKey = 'assesseeUnAllocateToGroup';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
          }
        } else if (secondaryOptionCheckValue === 'assessments') {
          switch (tertiaryOptionCheckValue) {
            case 'distinct': {
              getAssessmentDistinctApiCall(
                selectedAssociateInfo,
                'published',
                countPage,
                dispatch,
                'distinct',
                cardValue,
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
              );
              filterModeKey = 'unAllocateToAssessment';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
            case 'groups': {
              getAssessmentGroupApiCall(
                selectedAssociateInfo,
                'all',
                countPage,
                dispatch,
                'groups',
                cardValue,
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
                "list",
                [],
                assignmentCreateType
              );
              filterModeKey = 'assessmentUnAllocateToGroup';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
          }
        } else if (secondaryOptionCheckValue === 'culture-profiles') {
          switch (tertiaryOptionCheckValue) {
            case 'distinct': {
              getCultureProfilesDistinctApiCall(
                selectedAssociateInfo,
                'active',
                countPage,
                '',
                dispatch,
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
              );
              filterModeKey = 'unAllocateToCultureProfile';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
            case 'groups': {
              getCultureProfileGroupApiCall(
                selectedAssociateInfo,
                'all',
                countPage,
                dispatch,
                'groups',
                cardValue,
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
                [],
                'list',
                false,
                ""
              );
              filterModeKey = 'unAllocateToCultureProfileGroup';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
          }
        } else if (secondaryOptionCheckValue === 'job-profiles') {
          switch (tertiaryOptionCheckValue) {
            case 'distinct': {
              getJobProfilesDistinctApiCall(
                selectedAssociateInfo,
                'active',
                countPage,
                '',
                dispatch,
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
              );
              filterModeKey = 'unAllocateToJobProfile';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
            case 'groups': {
              getJobProfileGroupApiCall(
                selectedAssociateInfo,
                'active',
                countPage,
                dispatch,
                'groups',
                cardValue,
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
                'list',
                [],
                false,
                // assignmentCreateType
              );
              filterModeKey = 'jobProfileUnAllocateToGroup';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
          }
        }
      } else if (keyVal === 'pay') {
        // if (selectedTagsArray.length === 0) {
        //   dispatch({
        //     type: SET_POPUP_VALUE,
        //     payload: { isPopUpValue: 'You must select assignments before proceeding to pay', popupMode: 'responseErrorMsg' }
        //   })
        //   return;
        // }

        dispatch({
          type: SET_POPUP_STATE,
          payload: {
            popupHeaderOne: 'assignments',
            popupHeaderOneBadgeOne: 'mine',
            popupHeaderOneBadgeTwo: '',
            isPopUpValue: 'PAYMENTINFO',
            popupOpenType: 'primary'
            // popupContentArrValue: tempArr,
          }
        });
      } else {
        let permissionObj;
        permissionObj = signedAssesseePermission?.assigmentDistictPermission;
        // debugger
        dispatch({
          type: SET_MIDDLEPANE_SECONDARY_OPTION,
          payload: {
            badgeValue: dataVal,
            keyValue: keyVal,
            secondaryCheckValue: secondaryOptionCheckValue,
            permissionObject: permissionObj
          }
        });
      }
    } else if (middlePaneHeader === 'associates') {
      if (keyVal === 'distinct' && popupHeaderOneBadgeOne === 'groups') {
        let badgeTwo =
          middlePaneHeaderBadgeTwo === 'generic' || middlePaneHeaderBadgeTwo === 'bespoke'
            ? 'active'
            : secondaryOptionCheckValue;
        getAssociateGroupDistinctApiCall(
          selectedAssociateInfo,
          //secondaryOptionCheckValue,
          badgeTwo,
          countPage,
          dispatch,
          'groups',
          cardValue,
          '',
          'hierarchy',
          [],
          assignmentCreateType
        );
        resetDataFunction();
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'scanString', value: '' }
        });
        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'selectionValue', value: '' }
        });
        dispatch({ type: POPUP_CLOSE });
        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
      } else if (keyVal === 'distinct' && popupHeaderOneBadgeOne === 'nodes') {
        getInternalNodeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          'nodes',
          '',
          'hierarchy',
          middlePaneHeader
        );
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'distinct' && popupHeaderOneBadgeOne === 'types') {
        getAssociatesTypeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          popupHeaderOneBadgeOne,
          middlePaneHeader,
          cardValue
        );
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'distinct' && popupHeaderOneBadgeOne === 'roles') {
        getAssociateRoleDistinctApiCall(
          selectedAssociateInfo,
          //badgeTwo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          cardValue,
          popupHeaderOneBadgeOne
        );

        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'flag') {
        let permissionObj;
        permissionObj = signedAssesseePermission?.assesseeDistinctPermission;
        console.log(
          keyVal,
          secondaryOptionCheckValue,
          '----------------****************--------------'
        );
        // debugger
        dispatch({
          type: SET_MIDDLEPANE_SECONDARY_OPTION,
          payload: {
            badgeValue: dataVal,
            keyValue: keyVal,
            secondaryCheckValue: 'multiple',
            permissionObject: permissionObj
          }
        });
      } else if (keyVal === 'groups' && popupHeaderOneBadgeTwo === 'move') {
        // debugger;
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'nodes' && popupHeaderOneBadgeTwo === 'move') {
        // debugger;
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'roles' && popupHeaderOneBadgeTwo === 'move') {
        // debugger;
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'types' && popupHeaderOneBadgeTwo === 'move') {
        // debugger;
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'groups' && popupHeaderOneBadgeTwo !== 'move') {
        getAssociateGroupDistinctApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          keyVal,
          cardValue,
          '',
          'hierarchy',
          [],
          assignmentCreateType
        );
        dispatch({ type: POPUP_CLOSE });
        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'selectionValue', value: '' }
        });
      } else if (keyVal === 'types') {
        getAssociatesTypeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          keyVal,
          middlePaneHeader,
          ''
        );
        dispatch({ type: POPUP_CLOSE });
        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'selectionValue', value: '' }
        });
      } else if (keyVal === 'nodes') {
        getInternalNodeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          dataVal,
          '',
          'hierarchy',
          middlePaneHeader
        );
        dispatch({ type: POPUP_CLOSE });
        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'selectionValue', value: '' }
        });
      } else if (keyVal === 'roles') {
        getAssociateRoleDistinctApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          keyVal
        );
        dispatch({ type: POPUP_CLOSE });
        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'selectionValue', value: '' }
        });
      } else if (keyVal === 'allocateApi') {
        if (secondaryOptionCheckValue === 'associates' && tertiaryOptionCheckValue == 'distinct') {
          getAssociateDistinctApiCall(
            selectedAssociateInfo,
            '',
            dispatch,
            countPage,
            'distinct',
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            'Card'
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'associatesAllocateToDistinct';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'associates' &&
          tertiaryOptionCheckValue == 'groups'
        ) {
          getAssociateGroupAllocateDistinctApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'groups',
            '',
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            "list",
            [],
            assignmentCreateType,
            selectedTagsArray
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'associateAllocateToGroup';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'associates' &&
          tertiaryOptionCheckValue == 'nodes'
        ) {
          getInternalNodeApiCall(
            selectedAssociateInfo,
            secondaryOptionCheckValue,
            countPage,
            dispatch,
            'nodes',
            '',
            'list',
            secondaryOptionCheckValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'associatesAllocateToNode';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'associates' &&
          tertiaryOptionCheckValue === 'roles'
        ) {
          getAssociateRoleAllocateDistinctApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            cardValue,
            'roles',
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            selectedTagsArray
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'associatesAllocateToRole';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'associates' &&
          tertiaryOptionCheckValue === 'types'
        ) {
          getAssociatesTypeApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'types',
            'associates',
            '',
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'associatesAllocateToType';
          dispatch({ type: POPUP_CLOSE });
        }
      } else if (keyVal === 'unallocateApi') {
        if (secondaryOptionCheckValue === 'associates') {
          switch (tertiaryOptionCheckValue) {
            case 'distinct': {
              if (middlePaneHeaderBadgeOne !== 'distinct') {
                getAssociateDistinctApiCall(
                  selectedAssociateInfo,
                  '',
                  dispatch,
                  countPage,
                  'distinct',
                  popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
                  'Card'
                );
                isValidAllocationOrUnallocation = true;
                filterModeKey = 'associatesUnAllocateToDistinct';
                dispatch({ type: POPUP_CLOSE });
              }
              break;
            }
            case 'groups': {
              getAssociateGroupAllocateDistinctApiCall(
                selectedAssociateInfo,
                'active',
                countPage,
                dispatch,
                'groups',
                '',
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
                "list",
                [],
                assignmentCreateType,
                selectedTagsArray
              );
              filterModeKey = 'associateUnAllocateToGroup';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
            case 'nodes': {
              getInternalNodeApiCall(
                selectedAssociateInfo,
                secondaryOptionCheckValue,
                countPage,
                dispatch,
                'nodes',
                '',
                'list',
                secondaryOptionCheckValue,
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
              );
              filterModeKey = 'associatesUnAllocateToNode';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
            case 'roles': {
              getAssociateRoleAllocateDistinctApiCall(
                selectedAssociateInfo,
                'active',
                countPage,
                dispatch,
                cardValue,
                'roles',
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
                selectedTagsArray
              );
              filterModeKey = 'associatesUnAllocateToRole';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
            case 'types': {
              getAssociatesTypeApiCall(
                selectedAssociateInfo,
                'active',
                countPage,
                dispatch,
                'types',
                'associates',
                '',
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
              );
              filterModeKey = 'associatesUnAllocateToType';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
          }
        }
      } else {
        let permissionObj;
        // if (middlePaneHeaderBadgeOne === 'distinct') {
        permissionObj = signedAssesseePermission?.associateDistinctPermission;
        // }
        // debugger
        dispatch({
          type: SET_MIDDLEPANE_SECONDARY_OPTION,
          payload: {
            badgeValue: dataVal,
            keyValue: keyVal,
            secondaryCheckValue: secondaryOptionCheckValue,
            permissionObject: permissionObj
          }
        });
      }
    } else if (middlePaneHeader === 'culture-profiles') {
      if (keyVal === 'distinct' && popupHeaderOneBadgeOne === 'groups') {
        let header = cardValue === 'Card' ? middlePaneHeaderBadgeThree : middlePaneHeaderBadgeTwo;
        let badgeTwo =
          header === 'generic' || header === 'bespoke' ? 'active' : secondaryOptionCheckValue;
        getCultureProfileGroupApiCall(
          selectedAssociateInfo,
          //cardValue==='Card'? middlePaneHeaderBadgeThree : middlePaneHeaderBadgeTwo,
          badgeTwo,
          countPage,
          dispatch,
          'groups',
          // 'noCard',
          cardValue,
          '',
          [],
          'hierarchy',
          false,
          assignmentCreateType
        );
        resetDataFunction();
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'scanString', value: '' }
        });
        dispatch({ type: POPUP_CLOSE });
        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
      } else if (keyVal === 'distinct' && popupHeaderOneBadgeOne === 'nodes') {
        getInternalNodeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          'nodes',
          '',
          'hierarchy',
          middlePaneHeader
        );
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'distinct' && popupHeaderOneBadgeOne === 'types') {
        let header = cardValue === 'Card' ? middlePaneHeaderBadgeThree : middlePaneHeaderBadgeTwo;
        let badgeTwo =
          header === 'generic' || header === 'bespoke' ? 'active' : secondaryOptionCheckValue;
        getCultureProfileTypeApiCall(
          selectedAssociateInfo,
          badgeTwo,
          countPage,
          dispatch,
          popupHeaderOneBadgeOne,
          middlePaneHeader,
          cardValue
        );
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'flag') {
        let permissionObj;
        permissionObj = signedAssesseePermission?.assesseeDistinctPermission;
        console.log(
          keyVal,
          secondaryOptionCheckValue,
          '----------------****************--------------'
        );
        // debugger
        dispatch({
          type: SET_MIDDLEPANE_SECONDARY_OPTION,
          payload: {
            badgeValue: dataVal,
            keyValue: keyVal,
            secondaryCheckValue: 'multiple',
            permissionObject: permissionObj
          }
        });
      } else if (keyVal === 'groups' && popupHeaderOneBadgeTwo === 'move') {
        // debugger;
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'nodes' && popupHeaderOneBadgeTwo === 'move') {
        // debugger;
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'types' && popupHeaderOneBadgeTwo === 'move') {
        // debugger;
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'groups' && popupHeaderOneBadgeTwo !== 'move') {
        let header = cardValue === 'Card' ? middlePaneHeaderBadgeThree : middlePaneHeaderBadgeTwo;
        let badgeTwo =
          header === 'generic' || header === 'bespoke' ? 'active' : secondaryOptionCheckValue;
        getCultureProfileGroupApiCall(
          selectedAssociateInfo,
          // cardValue === 'Card' ? middlePaneHeaderBadgeThree : middlePaneHeaderBadgeTwo,
          badgeTwo,
          countPage,
          dispatch,
          'groups',
          // 'noCard',
          cardValue,
          '',
          [],
          'hierarchy',
          false,
          assignmentCreateType
        );
        dispatch({ type: POPUP_CLOSE });
        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'selectionValue', value: '' }
        });
      } else if (keyVal === 'types') {
        let header = cardValue === 'Card' ? middlePaneHeaderBadgeThree : middlePaneHeaderBadgeTwo;
        let badgeTwo =
          header === 'generic' || header === 'bespoke' ? 'active' : secondaryOptionCheckValue;
        getCultureProfileTypeApiCall(
          selectedAssociateInfo,
          badgeTwo,
          countPage,
          dispatch,
          keyVal,
          middlePaneHeader,
          cardValue
        );
        dispatch({ type: POPUP_CLOSE });
        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'selectionValue', value: '' }
        });
      } else if (keyVal === 'nodes') {
        getInternalNodeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          dataVal,
          '',
          'hierarchy',
          middlePaneHeader
        );
        dispatch({ type: POPUP_CLOSE });
        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'selectionValue', value: '' }
        });
      } else if (keyVal === 'allocateApi') {
        if (
          secondaryOptionCheckValue === 'culture-profiles' &&
          tertiaryOptionCheckValue === 'distinct'
        ) {
          getCultureProfilesDistinctApiCall(
            selectedAssociateInfo,
            '',
            countPage,
            '',
            dispatch,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'allocateToCultureProfile';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'culture-profiles' &&
          tertiaryOptionCheckValue === 'groups'
        ) {
          getCultureProfileAllocateGroupApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'groups',
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            [],
            'list',
            false,
            assignmentCreateType,
            selectedTagsArray
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'allocateToCultureProfileGroup';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'culture-profiles' &&
          tertiaryOptionCheckValue === 'nodes'
        ) {
          getInternalNodeApiCall(
            selectedAssociateInfo,
            secondaryOptionCheckValue,
            countPage,
            dispatch,
            'nodes',
            '',
            'list',
            secondaryOptionCheckValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'cultureProfileAllocateToNode';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'culture-profiles' &&
          tertiaryOptionCheckValue === 'types'
        ) {
          getCultureProfileAllocateTypeApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'types',
            'culture-profiles',
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            [],
            false,
            selectedTagsArray
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'allocateToCultureProfileType';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assignments' &&
          tertiaryOptionCheckValue === 'distinct'
        ) {
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          assignmentsDistinctApiCall(
            selectedAssociateInfo,
            'unpublished',
            countPage,
            dispatch,
            'distinct',
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            'iGuru Analytics_Occupation'
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: {
              stateName: 'assignmentCreateType',
              value: 'iGuru Analytics_Occupation'
            }
          });
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'allocateToAssignment';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assignments' &&
          tertiaryOptionCheckValue === 'groups'
        ) {
          assignmentsGroupApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'groups',
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            "list",
            [],
            assignmentCreateType
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'assignmentAllocateToGroup';
          dispatch({ type: POPUP_CLOSE });
        }
      } else if (keyVal === 'unallocateApi') {
        if (secondaryOptionCheckValue === 'culture-profiles') {
          switch (tertiaryOptionCheckValue) {
            case 'distinct': {
              getCultureProfilesDistinctApiCall(
                selectedAssociateInfo,
                'all',
                countPage,
                '',
                dispatch,
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
              );
              filterModeKey = 'unAllocateToCultureProfile';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
            case 'groups': {
              getCultureProfileAllocateGroupApiCall(
                selectedAssociateInfo,
                'active',
                countPage,
                dispatch,
                'groups',
                cardValue,
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
                [],
                'list',
                false,
                assignmentCreateType,
                selectedTagsArray
              );
              filterModeKey = 'unAllocateToCultureProfileGroup';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
            case 'nodes': {
              getInternalNodeApiCall(
                selectedAssociateInfo,
                secondaryOptionCheckValue,
                countPage,
                dispatch,
                'nodes',
                '',
                'list',
                secondaryOptionCheckValue,
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
              );
              filterModeKey = 'cultureProfileUnAllocateToNode';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
            case 'types': {
              getCultureProfileAllocateTypeApiCall(
                selectedAssociateInfo,
                'active',
                countPage,
                dispatch,
                'types',
                secondaryOptionCheckValue,
                cardValue,
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
                [],
                false,
                selectedTagsArray
              );
              filterModeKey = 'unAllocateToCultureProfileType';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
          }
        } else if (secondaryOptionCheckValue === 'assignments') {
          switch (tertiaryOptionCheckValue) {
            case 'distinct': {
              dispatch({
                type: SET_DISPLAY_TWO_SINGLE_STATE,
                payload: { stateName: 'typeOfMiddlePaneList', value: '' }
              });
              assignmentsDistinctApiCall(
                selectedAssociateInfo,
                'unpublished',
                countPage,
                dispatch,
                'distinct',
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
                'iGuru Analytics_Occupation'
              );
              dispatch({
                type: SET_DISPLAY_TWO_SINGLE_STATE,
                payload: {
                  stateName: 'assignmentCreateType',
                  value: 'iGuru Analytics_Occupation'
                }
              });
              filterModeKey = 'unAllocateToAssignment';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
            case 'groups': {
              assignmentsGroupApiCall(
                selectedAssociateInfo,
                'active',
                countPage,
                dispatch,
                'groups',
                cardValue,
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
                "list",
                [],
                assignmentCreateType
              );
              filterModeKey = 'assignmentUnAllocateToGroup';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
          }
        }
      } else {
        let permissionObj;
        // if (middlePaneHeaderBadgeOne === 'distinct') {
        permissionObj = signedAssesseePermission?.iGuruAnalyticDistinctPermission;
        // }

        console.log(permissionObj, '**', dataVal, keyVal, '**', secondaryOptionCheckValue);
        // debugger
        dispatch({
          type: SET_MIDDLEPANE_SECONDARY_OPTION,
          payload: {
            badgeValue: dataVal,
            keyValue: keyVal,
            secondaryCheckValue: secondaryOptionCheckValue,
            permissionObject: permissionObj,
            middlePaneHeaderBadgeOne,
            middlePaneHeaderBadgeTwo
          }
        });
      }
    } else if (middlePaneHeader === 'job-profiles') {
      if (keyVal === 'distinct' && popupHeaderOneBadgeOne === 'groups') {
        // getJobProfileGroupApiCall(
        //   selectedAssociateInfo,
        //   secondaryOptionCheckValue,
        //   countPage,
        //   dispatch,
        //   popupHeaderOneBadgeOne,
        //   cardValue,
        //   popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
        // );
        let header = cardValue === 'Card' ? middlePaneHeaderBadgeThree : middlePaneHeaderBadgeTwo;
        let badgeTwo =
          header === 'generic' || header === 'bespoke' ? 'active' : secondaryOptionCheckValue;
        getJobProfileGroupApiCall(
          selectedAssociateInfo,
          badgeTwo,
          countPage,
          dispatch,
          'groups',
          // 'noCard',
          cardValue,
          '',
          [],
          'hierarchy',
          false,
          assignmentCreateType
        );
        resetDataFunction();
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'scanString', value: '' }
        });
        dispatch({ type: POPUP_CLOSE });
        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
      } else if (keyVal === 'distinct' && popupHeaderOneBadgeOne === 'nodes') {
        getInternalNodeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          'nodes',
          '',
          'hierarchy',
          middlePaneHeader
        );
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'distinct' && popupHeaderOneBadgeOne === 'types') {
        let header = cardValue === 'Card' ? middlePaneHeaderBadgeThree : middlePaneHeaderBadgeTwo;
        let badgeTwo =
          header === 'generic' || header === 'bespoke' ? 'active' : secondaryOptionCheckValue;
        getJobProfileTypeApiCall(
          selectedAssociateInfo,
          badgeTwo,
          countPage,
          dispatch,
          popupHeaderOneBadgeOne,
          middlePaneHeader,
          cardValue
        );
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'flag') {
        let permissionObj;
        permissionObj = signedAssesseePermission?.assesseeDistinctPermission;
        console.log(
          keyVal,
          secondaryOptionCheckValue,
          '----------------****************--------------'
        );
        // debugger
        dispatch({
          type: SET_MIDDLEPANE_SECONDARY_OPTION,
          payload: {
            badgeValue: dataVal,
            keyValue: keyVal,
            secondaryCheckValue: 'multiple',
            permissionObject: permissionObj
          }
        });
      } else if (keyVal === 'groups' && popupHeaderOneBadgeTwo === 'move') {
        // debugger;
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'nodes' && popupHeaderOneBadgeTwo === 'move') {
        // debugger;
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'types' && popupHeaderOneBadgeTwo === 'move') {
        // debugger;
        dispatch({ type: POPUP_CLOSE });
      } else if (keyVal === 'groups' && popupHeaderOneBadgeTwo !== 'move') {
        let header = cardValue === 'Card' ? middlePaneHeaderBadgeThree : middlePaneHeaderBadgeTwo;
        let badgeTwo =
          header === 'generic' || header === 'bespoke' ? 'active' : secondaryOptionCheckValue;
        getJobProfileGroupApiCall(
          selectedAssociateInfo,
          badgeTwo,
          countPage,
          dispatch,
          'groups',
          // 'noCard',
          cardValue,
          '',
          [],
          'hierarchy',
          false,
          assignmentCreateType
        );
        dispatch({ type: POPUP_CLOSE });
        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'selectionValue', value: '' }
        });
      } else if (keyVal === 'types') {
        let header = cardValue === 'Card' ? middlePaneHeaderBadgeThree : middlePaneHeaderBadgeTwo;
        let badgeTwo =
          header === 'generic' || header === 'bespoke' ? 'active' : secondaryOptionCheckValue;
        getJobProfileTypeApiCall(
          selectedAssociateInfo,
          badgeTwo,
          countPage,
          dispatch,
          keyVal,
          middlePaneHeader,
          cardValue
        );
        dispatch({ type: POPUP_CLOSE });
        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'selectionValue', value: '' }
        });
      } else if (keyVal === 'nodes') {
        getInternalNodeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          dataVal,
          '',
          'hierarchy',
          middlePaneHeader
        );
        dispatch({ type: POPUP_CLOSE });
        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'selectionValue', value: '' }
        });
      } else if (keyVal === 'allocateApi') {
        if (
          secondaryOptionCheckValue === 'job-profiles' &&
          tertiaryOptionCheckValue === 'distinct'
        ) {
          getJobProfilesDistinctApiCall(
            selectedAssociateInfo,
            '',
            countPage,
            '',
            dispatch,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'allocateToJobProfile';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'job-profiles' &&
          tertiaryOptionCheckValue === 'groups'
        ) {
          getJobProfileGroupAllocateApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'groups',
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            [],
            'list',
            false,
            assignmentCreateType,
            selectedTagsArray
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'jobProfileAllocateToGroup';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'job-profiles' &&
          tertiaryOptionCheckValue === 'nodes'
        ) {
          getInternalNodeApiCall(
            selectedAssociateInfo,
            secondaryOptionCheckValue,
            countPage,
            dispatch,
            'nodes',
            '',
            'list',
            secondaryOptionCheckValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'jobProfileAllocateToNode';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'job-profiles' &&
          tertiaryOptionCheckValue === 'types'
        ) {
          getJobProfileTypeApiAllcoateCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'types',
            secondaryOptionCheckValue,
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            [],
            false,
            selectedTagsArray
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'jobProfileAllocateToType';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assignments' &&
          tertiaryOptionCheckValue === 'distinct'
        ) {
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          assignmentsDistinctApiCall(
            selectedAssociateInfo,
            'unpublished',
            countPage,
            dispatch,
            'distinct',
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            'iGuru Analytics_Occupation'
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: {
              stateName: 'assignmentCreateType',
              value: 'iGuru Analytics_Occupation'
            }
          });
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'allocateToAssignment';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assignments' &&
          tertiaryOptionCheckValue === 'groups'
        ) {
          assignmentsGroupApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'groups',
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            "list",
            [],
            assignmentCreateType
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'assignmentAllocateToGroup';
          dispatch({ type: POPUP_CLOSE });
        }
      } else if (keyVal === 'unallocateApi') {
        if (secondaryOptionCheckValue === 'assignments') {
          switch (tertiaryOptionCheckValue) {
            case 'distinct': {
              assignmentsDistinctApiCall(
                selectedAssociateInfo,
                'unpublished',
                countPage,
                dispatch,
                'distinct',
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
                'iGuru Analytics_Occupation'
              );
              dispatch({
                type: SET_DISPLAY_TWO_SINGLE_STATE,
                payload: {
                  stateName: 'assignmentCreateType',
                  value: 'iGuru Analytics_Occupation'
                }
              });
              filterModeKey = 'unAllocateToAssignment';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
            case 'groups': {
              assignmentsGroupApiCall(
                selectedAssociateInfo,
                'active',
                countPage,
                dispatch,
                'groups',
                cardValue,
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
                "list",
                [],
                assignmentCreateType
              );
              filterModeKey = 'assignmentUnAllocateToGroup';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
          }
        } else if (secondaryOptionCheckValue === 'job-profiles') {
          switch (tertiaryOptionCheckValue) {
            case 'distinct': {
              if (middlePaneHeaderBadgeOne !== 'distinct') {
                getJobProfilesDistinctApiCall(
                  selectedAssociateInfo,
                  '',
                  countPage,
                  '',
                  dispatch,
                  popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
                );
                filterModeKey = 'unAllocateToJobProfile';
                dispatch({ type: POPUP_CLOSE });
                isValidAllocationOrUnallocation = true;
              }
              break;
            }
            case 'groups': {
              getJobProfileGroupAllocateApiCall(
                selectedAssociateInfo,
                'active',
                countPage,
                dispatch,
                'groups',
                cardValue,
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
                [],
                'list',
                false,
                assignmentCreateType,
                selectedTagsArray
              );
              filterModeKey = 'jobProfileUnAllocateToGroup';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
            case 'nodes': {
              getInternalNodeApiCall(
                selectedAssociateInfo,
                secondaryOptionCheckValue,
                countPage,
                dispatch,
                'nodes',
                '',
                'list',
                secondaryOptionCheckValue,
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
              );
              filterModeKey = 'jobProfileUnAllocateToNode';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
            case 'types': {
              getJobProfileTypeApiAllcoateCall(
                selectedAssociateInfo,
                'active',
                countPage,
                dispatch,
                'types',
                secondaryOptionCheckValue,
                cardValue,
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
                [],
                false,
                selectedTagsArray
              );
              filterModeKey = 'jobProfileUnAllocateToType';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
          }
        }
      } else {
        let permissionObj;
        // if (middlePaneHeaderBadgeOne === 'distinct') {
        permissionObj = signedAssesseePermission?.iGuruAnalyticDistinctPermission;
        // }
        // debugger
        dispatch({
          type: SET_MIDDLEPANE_SECONDARY_OPTION,
          payload: {
            badgeValue: dataVal,
            keyValue: keyVal,
            secondaryCheckValue: secondaryOptionCheckValue,
            permissionObject: permissionObj,
            middlePaneHeaderBadgeOne,
            middlePaneHeaderBadgeTwo
          }
        });
      }
    } else if (middlePaneHeader === 'associate') {
      if (keyVal === 'distinct' && popupHeaderOneBadgeOne === 'nodes') {
        getInternalNodeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          'nodes',
          '',
          'hierarchy',
          middlePaneHeader
        );
        dispatch({ type: POPUP_CLOSE });
        resetDataFunction();
      } else if (keyVal === 'allocateApi') {
        if (secondaryOptionCheckValue === 'associates' && tertiaryOptionCheckValue == 'distinct') {
          getAssociateDistinctApiCall(
            selectedAssociateInfo,
            'activeinactive',
            dispatch,
            countPage,
            'distinct',
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            'Card'
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'associatesAllocateToDistinct';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'associates' &&
          tertiaryOptionCheckValue == 'groups'
        ) {
          getAssociateGroupAllocateDistinctApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'groups',
            '',
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            "list",
            [],
            assignmentCreateType,
            selectedTagsArray
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'associateAllocateToGroup';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'associates' &&
          tertiaryOptionCheckValue == 'nodes'
        ) {
          getInternalNodeApiCall(
            selectedAssociateInfo,
            secondaryOptionCheckValue,
            countPage,
            dispatch,
            'nodes',
            '',
            'list',
            secondaryOptionCheckValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'associatesAllocateToNode';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'associates' &&
          tertiaryOptionCheckValue === 'roles'
        ) {
          getAssociateRoleAllocateDistinctApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'roles',
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            selectedTagsArray
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'associatesAllocateToRole';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'associates' &&
          tertiaryOptionCheckValue === 'types'
        ) {
          getAssociatesTypeApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'types',
            'associates',
            '',
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'associatesAllocateToType';
          dispatch({ type: POPUP_CLOSE });
        }
      } else if (keyVal === 'unallocateApi') {
        if (secondaryOptionCheckValue === 'associates') {
          switch (tertiaryOptionCheckValue) {
            case 'distinct': {
              if (middlePaneHeaderBadgeOne !== 'distinct') {
                getAssociateDistinctApiCall(
                  selectedAssociateInfo,
                  '',
                  dispatch,
                  countPage,
                  'distinct',
                  popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
                  'Card'
                );
                isValidAllocationOrUnallocation = true;
                filterModeKey = 'associatesUnAllocateToDistinct';
                dispatch({ type: POPUP_CLOSE });
              }
              break;
            }
            case 'groups': {
              getAssociateGroupAllocateDistinctApiCall(
                selectedAssociateInfo,
                'active',
                countPage,
                dispatch,
                'groups',
                '',
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
                "list",
                [],
                assignmentCreateType,
                selectedTagsArray
              );
              filterModeKey = 'associateUnAllocateToGroup';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
            case 'nodes': {
              getInternalNodeApiCall(
                selectedAssociateInfo,
                secondaryOptionCheckValue,
                countPage,
                dispatch,
                'nodes',
                '',
                'list',
                secondaryOptionCheckValue,
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
              );
              filterModeKey = 'associatesUnAllocateToNode';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
            case 'roles': {
              getAssociateRoleAllocateDistinctApiCall(
                selectedAssociateInfo,
                'active',
                countPage,
                dispatch,
                cardValue,
                'roles',
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
                selectedTagsArray
              );
              filterModeKey = 'associatesUnAllocateToRole';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
            case 'types': {
              getAssociatesTypeApiCall(
                selectedAssociateInfo,
                'active',
                countPage,
                dispatch,
                'types',
                'associates',
                '',
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
              );
              filterModeKey = 'associatesUnAllocateToType';
              dispatch({ type: POPUP_CLOSE });
              isValidAllocationOrUnallocation = true;
              break;
            }
          }
        }
      } else {
        let permissionObj = signedAssesseePermission?.associateDistinctPermission;
        // debugger
        dispatch({
          type: SET_MIDDLEPANE_SECONDARY_OPTION,
          payload: {
            badgeValue: dataVal,
            keyValue: keyVal,
            secondaryCheckValue: secondaryOptionCheckValue,
            permissionObject: permissionObj
          }
        });
      }
    } else if (dataVal === 'distinct' && popupHeaderOne === 'certificates') {
      getAssesseeSelfCertificates(selectedAssociateInfo, secondaryOptionCheckValue, countPage, dispatch)
      dispatch({ type: POPUP_CLOSE });
    } else if (dataVal === 'distinct' && popupHeaderOne === 'reports') {
      getAssesseeSelfReports(selectedAssociateInfo, secondaryOptionCheckValue, countPage, dispatch)
      dispatch({ type: POPUP_CLOSE });
    } else {
      let permissionObj;
      if (middlePaneHeader === 'assessees' && middlePaneHeaderBadgeOne === 'distinct') {
        permissionObj = signedAssesseePermission?.assesseeDistinctPermission;
      }
      if (middlePaneHeader === 'assignment' && middlePaneHeaderBadgeOne === 'distinct') {
        permissionObj = signedAssesseePermission?.assigmentDistictPermission;
      }
      console.log('permissionObj', permissionObj);
      // debugger
      dispatch({
        type: SET_MIDDLEPANE_SECONDARY_OPTION,
        payload: {
          badgeValue: dataVal,
          keyValue: keyVal,
          secondaryCheckValue: secondaryOptionCheckValue,
          permissionObject: permissionObj
        }
      });
    }

    if (
      popupHeaderOneBadgeTwo === 'allocate' &&
      tertiaryOptionCheckValue !== '' &&
      secondaryOptionCheckValue !== '' &&
      isValidAllocationOrUnallocation === true
    ) {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'allocatedTagsArray', value: [...selectedTagsArray] }
      });
      // console.log(filterModeKey, '****')
      dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
      dispatch({
        type: FILTERMODE,
        payload: { FilterMode: filterModeKey }
      });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isSelectActive', value: 'multiple' }
      });
    }
  };
  const BackHandlerEvent = (e) => {
    dispatch({ type: SET_MIDDLEPANE_PREVIOUS_POPUP });
  };
  return (
    <div>
      <Popup isActive={isActive}>
        <PopupHeader
          headerPanelColour={headerPanelColour + popupOpenType}
          headerOne={popupHeaderOne}
          headerOneBadgeOne={popupHeaderOneBadgeOne}
          headerOneBadgeTwo={popupHeaderOneBadgeTwo}
          onClick={BackHandlerEvent}
          mode={''}
        />
        <DialogContent className={['popupContent', 'fixed05PadDim'].join(' ')}>
          <JsonRenderComponent
            setSecondaryOptionValue={setSecondaryOptionValue}
            setSecondaryOptionValueTwo={setSecondaryOptionValueTwo}
            setSecondaryOptionValueThree={setSecondaryOptionValueThree}
            ChangeOptionPopup={ChangeOptionPopup}
            secondaryOptionCheckValue={secondaryOptionCheckValue}
            tertiaryOptionCheckValue={tertiaryOptionCheckValue}
            forthOptionCheckValue={forthOptionCheckValue}
          />
        </DialogContent>
      </Popup>
    </div>
  );
};

export default PopUpDisplayPaneTwoTripleDot;
