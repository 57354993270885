import {
    CLEAR_JOB_CULTURE_REDUCER_STATE, SET_JOB_CULTURE_PROFILER_INFO_REDUCER_STATE, SET_JOB_CULTURE_PROFILER_REDUCER_STATE,
    SET_JOB_CULTURE_PROFILER_CLUSTER_REDUCER_STATE
} from "../actionType";
const initialState = {
    culturejobProfilerInformation: {
        culturejobProfilerName: '',
        culturejobProfilerNameVerification: false,
        culturejobProfilerDescription: '',
        culturejobProfilerPicture: '',
        culturejobProfilerPictureVerification: false
    },
    culturejobProfiler: {
        Cluster: '',
        Explanation: '',
        polarity: null,
    },
    clusterProfilerInformation: {
        id: '',
        iGaugeOccupationFrameworkOneName: "",
        iGaugeOccupationFrameworkOneNameVerification: false,
        iGaugeOccupationFrameworkOneDescription: "",
        iGaugeOccupationFrameworkOneExplanation: null,
        iGaugeOccupationFrameworkOneClusterName: "",
        iGaugeOccupationFrameworkOneClusterNameVerification: false,
        iGaugeOccupationFrameworkOneClusterDescription: "",
        iGaugeOccupationFrameworkOneClusterExplanation: null,
        iGaugeOccupationFrameworkOneClusterSequence: 0,
        iGaugeOccupationFrameworkOneClusterOneLabel: "",
        iGaugeOccupationFrameworkOneClusterOneDescription: "",
        iGaugeOccupationFrameworkOneClusterOneExplanation: "",
        iGaugeOccupationFrameworkOneClusterOnePolarity: "",
        iGaugeOccupationFrameworkOneClusterTwoLabel: "",
        iGaugeOccupationFrameworkOneClusterTwoDescription: "",
        iGaugeOccupationFrameworkOneClusterTwoExplanation: "",
        iGaugeOccupationFrameworkOneClusterTwoPolarity: "",
        iGaugeOccupationFrameworkOneClusterAssessment: []
    }
};

const CulturejobProfilerCreateReducer = (istate = JSON.parse(JSON.stringify(initialState)), action) => {
    switch (action.type) {
        case SET_JOB_CULTURE_PROFILER_REDUCER_STATE:
            return {
                ...istate,
                culturejobProfilerInformation: action.payload
            };
        case SET_JOB_CULTURE_PROFILER_INFO_REDUCER_STATE:
            return {
                ...istate,
                culturejobProfiler: action.payload
            };
        case SET_JOB_CULTURE_PROFILER_CLUSTER_REDUCER_STATE:
            return {
                ...istate,
                clusterProfilerInformation: action.payload
            };
        case CLEAR_JOB_CULTURE_REDUCER_STATE:
            return JSON.parse(JSON.stringify(initialState));
        default:
            return istate;
    }
};

export default CulturejobProfilerCreateReducer;