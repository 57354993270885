import { put, takeLatest, call } from 'redux-saga/effects';
import Store from '../../store';
import {
  ASSESSEE_ASSIGNMENT_ASSESSMENT_RESET_SAGA,
  ASSESSEE_ASSIGNMENT_RESET_SAGA,
  ASSIGNEMT_ASSESEES_ASSESSMENT_RESET_SAGA,
  ASSIGNMENT_ASSESSMENT_RESET_SAGA,
  ASSIGNMENT_RESET_SAGA,
  ASSIGNMENT_REVIEW_LIST_DISTINCT_SAGA,
  GET_ASSESSEE_ASSIGNMENT_SAGA,
  GET_ASSIGNMENTDISTINCT_ASSESSEES_REVIEWLIST_SAGA,
  LOADER_STOP,
  POPUP_CLOSE,
  RESEND_ASSIGNMENT_NOTIFICATION,
  RESET_ASSESSEE_ASSIGNMENT_SAGA,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_PAGE_COUNT,
  SET_POPUP_VALUE
} from '../../actionType';
import { ASSIGNMENT_MODULE_URL } from '../../endpoints';
import { EXCEPTION_ERROR_MESSAGE } from '../../errorMessage';

const resetApiCall = async (requestObj) => {
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      Authorization: localStorage.getItem('token')
    }),
    body: JSON.stringify(requestObj.data)
  };
  const response = await fetch(requestObj.URL, requestOptions);
  const json = await response.json();
  return json;
};

function* workerAssesseeAssignmentResetSaga(data) {
  try {
    const apiResponse = yield call(resetApiCall, {
      URL: ASSIGNMENT_MODULE_URL,
      data: data.payload.request
    });
    if (apiResponse.responseCode === '000') {
      yield put({
        type: GET_ASSESSEE_ASSIGNMENT_SAGA,
        payload: {
          request: data.payload.refreshListReq.request,
          BadgeOne: 'distinct',
          BadgeTwo: data.payload.BadgeTwo, // 'active',
          BadgeThree: '',
          relatedListCall: 'assesseeDistinct',
          typeOfMiddlePaneList: data.payload.typeOfMiddlePaneList,
          responseObj: data.payload.refreshListReq.responseObj
        }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'typeOfMiddlePaneList', value: '' }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'relatedReviewListDistinctData', value: [] }
      });
    } else {
      yield put({ type: POPUP_CLOSE });
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: apiResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerResetAssesseeAssignmentSaga(data) {
  try {
    const apiResponse = yield call(resetApiCall, {
      URL: ASSIGNMENT_MODULE_URL,
      data: data.payload.request
    });
    if (apiResponse.responseCode === '000') {
      yield put({
        type: GET_ASSIGNMENTDISTINCT_ASSESSEES_REVIEWLIST_SAGA,
        payload: {
          request: data.payload.relatedReviewListObjectCopy,
          HeaderOne: 'assessees',
          BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
          BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
          BadgeThree: '',
          isMiddlePaneList: true
        }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'typeOfMiddlePaneList', value: '' }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'relatedReviewListDistinctData', value: [] }
      });
    } else {
      yield put({ type: POPUP_CLOSE });
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: apiResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerAssignmentResetSaga(data) {
  try {
    const apiResponse = yield call(resetApiCall, {
      URL: ASSIGNMENT_MODULE_URL,
      data: data.payload.request
    });
    if (apiResponse.responseCode === '000') {
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'reviewListDistinctData', value: [] }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'typeOfMiddlePaneList', value: '' }
      });
      console.log('piResponse.responseCode', apiResponse.responseCode);

      yield put({ type: SET_PAGE_COUNT, payload: 0 });
      yield put({
        type: ASSIGNMENT_REVIEW_LIST_DISTINCT_SAGA,
        payload: {
          HeaderOne: 'assignments',
          request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
          BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
          BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
          isMiddlePaneList: true,
          middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
        }
      });
    } else {
      console.log('piResponse.responseCode', apiResponse.responseCode);
      yield put({ type: POPUP_CLOSE });
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: apiResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}

function* workerAssignmentAssessmentResetSaga(data) {
  try {
    const apiResponse = yield call(resetApiCall, {
      URL: ASSIGNMENT_MODULE_URL,
      data: data.payload.request
    });
    if (apiResponse.responseCode === '000') {
      yield put({ type: LOADER_STOP });
    } else {
      yield put({ type: POPUP_CLOSE });
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: apiResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerAssesseeAssignmentAssessmentResetSaga(data) {
  try {
    const apiResponse = yield call(resetApiCall, {
      URL: ASSIGNMENT_MODULE_URL,
      data: data.payload.request
    });
    if (apiResponse.responseCode === '000') {
      const response = yield call(resetApiCall, {
        URL: ASSIGNMENT_MODULE_URL,
        data: data.payload.refreshListReq.request
      });
      console.log('response', response);
      let selectedAssignment = response.responseObject.filter((dd) => {
        return dd.assesseeAssignmentId === data.payload.request.requestObject.assesseeAssignmentId;
      });
      console.log('selectedAssignment', selectedAssignment);
      let exisitngRes = Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData;
      let assessmentList = selectedAssignment[0].assesseeAssignmentAssessmentDistinct.sort((a, b) =>
        a.assesseeAssignmentAssessmentName.toLowerCase() !==
          b.assesseeAssignmentAssessmentName.toLowerCase()
          ? a.assesseeAssignmentAssessmentName.toLowerCase() <
            b.assesseeAssignmentAssessmentName.toLowerCase()
            ? -1
            : 1
          : 0
      );
      let responseObj = {
        assesseeInfo: exisitngRes[0].assesseeInfo,
        assesseeId: exisitngRes[0].assesseeId,
        assesseeAssignment: response.responseObject,
        assesseeAssignmentInfo: {
          ...selectedAssignment[0],
          assesseeAssignmentAssessmentDistinct: assessmentList
        }
      };
      console.log('responseObj', responseObj);
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'relatedReviewListDistinctData', value: [responseObj] }
      });
      yield put({ type: LOADER_STOP });
    } else {
      yield put({ type: POPUP_CLOSE });
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: apiResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}

function* workerAssignmentAssesseeAssessmentDistinctResetSaga(data) {
  try {
    const apiResponse = yield call(resetApiCall, {
      URL: ASSIGNMENT_MODULE_URL,
      data: data.payload.request
    });
    if (apiResponse.responseCode === '000') {
      const response = yield call(resetApiCall, {
        URL: ASSIGNMENT_MODULE_URL,
        data: data.payload.refreshListReq.request
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'relatedReviewListDistinctData', value: [] }
      });
      console.log('assignmentAssesseeId', data.payload.request.requestObject.assignmentAssesseeId);
      let assignmentAssesseeId = data.payload.request.requestObject.assignmentAssesseeId;
      console.log('response', response);
      let selectedAssesseeInfo = response.responseObject[0].assessee.filter((dd) => {
        return dd.assesseeIdTwo === assignmentAssesseeId;
      });
      console.log('selectedAssesseeInfo', selectedAssesseeInfo);

      // let selectedAssignment = response.responseObject[0].assesee.filter((dd) => {
      //   return dd.assesseeId === data.payload.request.requestObject.assesseeId;
      // });

      // console.log('selectedAssignment', selectedAssignment);
      let exisitngRes = Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData;
      let assessmentList = selectedAssesseeInfo[0].assignmentData.assesseeAssignmentAssessmentDistinct.sort((a, b) =>
        a.assesseeAssignmentAssessmentName.toLowerCase() !==
          b.assesseeAssignmentAssessmentName.toLowerCase()
          ? a.assesseeAssignmentAssessmentName.toLowerCase() <
            b.assesseeAssignmentAssessmentName.toLowerCase()
            ? -1
            : 1
          : 0
      );
      let responseObj = {
        ...response.responseObject[0],
        assignmentAssesseeInfo: {
          ...selectedAssesseeInfo[0],
          assesseeAssignmentAssessmentDistinct: assessmentList
        }
      };
      console.log('responseObj', responseObj);
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'relatedReviewListDistinctData', value: [responseObj] }
      });
      yield put({ type: LOADER_STOP });
    } else {
      yield put({ type: POPUP_CLOSE });
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: apiResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}

function* workerAssignmentNotifcationResendSaga(data) {
  try {
    const apiResponse = yield call(resetApiCall, {
      URL: ASSIGNMENT_MODULE_URL,
      data: data.payload
    });


    if (apiResponse.responseCode === '000') {
      yield put({ type: LOADER_STOP });
    } else {
      yield put({ type: POPUP_CLOSE });
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: apiResponse?.responseMessage || EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
      });
    }

    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}

export default function* watcherAssignmentResetSaga() {
  yield takeLatest(RESET_ASSESSEE_ASSIGNMENT_SAGA, workerResetAssesseeAssignmentSaga);
  yield takeLatest(ASSESSEE_ASSIGNMENT_RESET_SAGA, workerAssesseeAssignmentResetSaga);
  yield takeLatest(ASSIGNMENT_RESET_SAGA, workerAssignmentResetSaga);
  yield takeLatest(ASSIGNMENT_ASSESSMENT_RESET_SAGA, workerAssignmentAssessmentResetSaga);
  yield takeLatest(
    ASSESSEE_ASSIGNMENT_ASSESSMENT_RESET_SAGA,
    workerAssesseeAssignmentAssessmentResetSaga
  );
  yield takeLatest(
    ASSIGNEMT_ASSESEES_ASSESSMENT_RESET_SAGA,
    workerAssignmentAssesseeAssessmentDistinctResetSaga
  );
  yield takeLatest(
    RESEND_ASSIGNMENT_NOTIFICATION,
    workerAssignmentNotifcationResendSaga
  );
}
