import React, { useState, Fragment, useEffect } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import Popup from '../Molecules/PopUp/PopUp';
import PopupHeader from '../Molecules/PopUp/PopUpHeader';
import Checkbox from '@material-ui/core/Checkbox';
import '../Molecules/PopUp/PopUp.css';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { POPUP_CLOSE, SET_NEXT_POPUP, UPDATE_ASSESSEE_SETUP_PRIMARY_INFO } from '../actionType';
import { FormHelperText, InputLabel } from '@material-ui/core';
import InfoToolTip from '../Atoms/InfoToolTip/InfoToolTip';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import { createNameWithBadge } from '../Actions/StatusAction';
import SelectField from '../Atoms/SelectField/SelectField';
import { Replay } from '@material-ui/icons';
import { CAN_NOT_REVISED_MESSAGE } from '../errorMessage';

const PopUpCheckboxRolePermission = (props) => {
  /*props*/
  const {
    isActive,
    headerPanelColour = 'genericOne',
    headerOne = 'assessees',
    headerOneBadgeOne = 'information',
    headerOneBadgeTwo = '',
    valueArr = [],
    nextPopUpValue,
    mode,
    inputHeader = '',
    inputHeaderBadge = '',
    inputHeaderBadgeTwo = '',
    inputHeaderBadgeThree = '',
    infoMsg = '',
    valueArrState = [],
    initArrState = [],
    informationArr = [],
    informationValue = '',
    typeOfSetObject,
    typeOfStateObj = '',
    objectName = '',
    stateName = '',
    stateArr = [],
    isNotRevised,
    errorMsg = isNotRevised ? CAN_NOT_REVISED_MESSAGE : ''
  } = props;
  const dispatch = useDispatch();
  const [localObject, setLocalObject] = useState(valueArrState);
  const [permissionInfo, setpermissionInfo] = useState('');
  let valueStateArrayPermission = valueArrState[typeOfStateObj] && valueArrState[typeOfStateObj] || { create: false, delete: false, review: false, revise: false, sell: false, share: false }
  useEffect(() => {
    if (headerOne === 'assessees') {
      setLocalObject(valueArrState);
      valueArr.forEach((arr) => {
        if (valueStateArrayPermission[arr.name]) {
          arr.checked = true;
        }
      });
      let initialstrArr =
        (typeOfStateObj.includes('assesseeAssessee') && 'assesseeAssessee') ||
        (typeOfStateObj.includes('assesseeAssessment') && 'assesseeAssessment') ||
        (typeOfStateObj.includes('assesseeAssignment') && 'assesseeAssignment') ||
        (typeOfStateObj.includes('assesseeAssociate') && 'assesseeAssociate') ||
        (typeOfStateObj.includes('assesseeItem') && 'assesseeItem') ||
        (typeOfStateObj.includes('assesseeiGuruAnalytic') && 'assesseeiGuruAnalytic') ||
        '';
      // let initialstrArr='assesseeAssessee';
      //if distinct create permission is true then review is disable until create false
      if (
        valueArrState[initialstrArr + 'DistinctPermission'].create &&
        (typeOfStateObj.includes(initialstrArr + 'GroupPermission') ||
          typeOfStateObj.includes(initialstrArr + 'NodePermission') ||
          typeOfStateObj.includes(initialstrArr + 'RolePermission') ||
          typeOfStateObj.includes(initialstrArr + 'TypePermission'))
      ) {
        valueArr.forEach((arr) => {
          if (valueStateArrayPermission[arr.name]) {
            arr.disabled = arr.name === 'review' ? true : arr.disabled;
          }
        });
      }
      informationValue !== '' && setpermissionInfo(valueStateArrayPermission[informationValue]);
    }
    // else if (headerOne === 'associate' && headerOneBadgeOne === 'distinct') {
    //   console.log('valueStateArrayPermission----',valueStateArrayPermission);
    //   setLocalObject(valueArrState);
    //   debugger
    //   valueArr.forEach((arr) => {
    //     if (valueStateArrayPermission[arr.name]) {
    //       arr.checked = true;
    //     }
    //   });
    //   informationValue !== '' && setpermissionInfo(localObject[informationValue]);
    // }
    if (headerOne === 'associate') {
      setLocalObject(valueArrState);
      valueArr.forEach((arr) => {
        if (valueStateArrayPermission[arr.name]) {
          arr.checked = true;
        }
      });
      let initialstrArr =
        (typeOfStateObj.includes('associateAssessee') && 'associateAssessee') ||
        (typeOfStateObj.includes('associateAssessment') && 'associateAssessment') ||
        (typeOfStateObj.includes('associateAssignment') && 'associateAssignment') ||
        (typeOfStateObj.includes('associateAssociate') && 'associateAssociate') ||
        (typeOfStateObj.includes('associateItem') && 'associateItem') ||
        (typeOfStateObj.includes('associateiGuruAnalytic') && 'associateiGuruAnalytic') ||
        '';
      //if distinct create permission is true then review is disable until create false
      console.log('initialstrArr', initialstrArr);
      // if (
      //   valueArrState[initialstrArr + 'DistinctPermission'].create &&
      //   (typeOfStateObj.includes(initialstrArr + 'GroupBespokePermission') ||
      //     typeOfStateObj.includes(initialstrArr + 'GroupGenericPermission') ||
      //     typeOfStateObj.includes(initialstrArr + 'NodeBespokePermission') ||
      //     typeOfStateObj.includes(initialstrArr + 'NodeGenericPermission') ||
      //     typeOfStateObj.includes(initialstrArr + 'RoleBespokePermission') ||
      //     typeOfStateObj.includes(initialstrArr + 'RoleGenericPermission') ||
      //     typeOfStateObj.includes(initialstrArr + 'TypeBespokePermission') ||
      //     typeOfStateObj.includes(initialstrArr + 'TypeGenericPermission'))
      // ) {
      //   debugger;
      //   valueArr.forEach((arr) => {
      //     if (valueStateArrayPermission[arr.name]) {
      //       arr.disabled = true;
      //     }
      //   });
      //   console.log('valueArr', valueArr);
      // }
      informationValue !== '' && setpermissionInfo(valueStateArrayPermission[informationValue]);
    }
  }, [!valueArrState]);
  /*handling the onchange event*/
  const handleSingleState = (event) => {
    console.log(event.target.value);
    console.log('valueStateArrayPermission', valueStateArrayPermission);
    valueArr.forEach((ee) => {
      if (ee.name === event.target.value) {
        ee.checked = event.target.checked;
        valueStateArrayPermission[ee.name] = event.target.checked;
      } else if (
        event.target.value === 'revise' &&
        ((ee.name === 'create' && ee.checked) || ee.name === 'review') &&
        event.target.checked
      ) {
        ee.checked = event.target.checked;
        valueStateArrayPermission.create = false;
        valueStateArrayPermission.review = event.target.checked;
      } else if (
        event.target.value === 'create' &&
        (ee.name === 'review' || ee.name === 'revise') &&
        event.target.checked
      ) {
        ee.checked = event.target.checked;
        valueStateArrayPermission.review = event.target.checked;
        valueStateArrayPermission.revise = event.target.checked;
      } else if (event.target.value === 'delete' && ee.name === 'review' && event.target.checked) {
        ee.checked = event.target.checked;
        valueStateArrayPermission.review = event.target.checked;
      } else if ((event.target.value === 'share' || event.target.value === 'move' || event.target.value === 'sell') && ee.name === 'review' && event.target.checked) {
        ee.checked = event.target.checked;
        valueStateArrayPermission.review = event.target.checked;
      } else if (
        event.target.value === 'review' &&
        ((ee.name === 'create' && ee.checked) ||
          (ee.name === 'revise' && ee.checked) ||
          (ee.name === 'delete' && ee.checked) ||
          (ee.name === 'share' && ee.checked) ||
          (ee.name === 'move' && ee.checked) ||
          (ee.name === 'sell' && ee.checked))
        // && event.target.checked
      ) {
        console.log('review permission');
        ee.checked = event.target.checked;
        valueStateArrayPermission.create = false;
        valueStateArrayPermission.delete = false;
        valueStateArrayPermission.revise = false;
        valueStateArrayPermission.share = false;
        valueStateArrayPermission.move = false;
        valueStateArrayPermission.sell = false;
      }
      // else null
    });
    let keyArr = Object.keys(valueArrState);
    if (typeOfStateObj.includes('DistinctPermission')) {
      let initialstrArr = typeOfStateObj.split('DistinctPermission');
      if (valueStateArrayPermission.create || event.target.value === 'create') {
        keyArr.forEach((kk) => {
          if (
            kk.includes(initialstrArr[0] + 'GroupPermission') ||
            kk.includes(initialstrArr[0] + 'GroupBespokePermission') ||
            kk.includes(initialstrArr[0] + 'GroupGenericPermission') ||
            kk.includes(initialstrArr[0] + 'RolePermission') ||
            kk.includes(initialstrArr[0] + 'RoleBespokePermission') ||
            kk.includes(initialstrArr[0] + 'RoleGenericPermission') ||
            kk.includes(initialstrArr[0] + 'TypePermission') ||
            kk.includes(initialstrArr[0] + 'TypeBespokePermission') ||
            kk.includes(initialstrArr[0] + 'TypeGenericPermission') ||
            kk.includes(initialstrArr[0] + 'NodePermission') ||
            kk.includes(initialstrArr[0] + 'NodeBespokePermission') ||
            kk.includes(initialstrArr[0] + 'NodeGenericPermission')
          ) {
            //when we give create permission to distinct it will give revie permission for rest sub-modules
            // but when we remove it review permission of other will not automatically remove
            // valueArrState[kk].review = event.target.checked;
            valueArrState[kk].review = true;
          }
        });
      }
    }
    setLocalObject({ ...localObject });
    console.log('localObject', localObject);
  };

  const handleDropDown = (e) => {
    setpermissionInfo(e.target.value);
  };
  const handleClick = (e) => {
    let reviseobj = null;
    console.log('ON CLICK');
    console.log('informationValue', informationValue);
    console.log('localObject', localObject);
    // if (informationValue !== '' && headerOne === 'assessees') {
    if (informationValue !== 'associateNodePermision') {
      let reviseObj = { ...localObject[typeOfStateObj], [informationValue]: permissionInfo };
      reviseobj = { ...localObject, [typeOfStateObj]: reviseObj };
    } else if (informationValue === 'associateNodePermision') {
      let reviseObj = { ...localObject[typeOfStateObj] };
      reviseobj = { ...localObject, [typeOfStateObj]: reviseObj };
    } else {
      reviseobj = { ...localObject };
    }
    dispatch({
      type: typeOfSetObject,
      payload: {
        objectName: objectName,
        stateName: stateName,
        // actualStateName: typeOfStateObj,
        value: reviseobj
      }
    });
    dispatch({ type: SET_NEXT_POPUP, payload: { isPopUpValue: nextPopUpValue } });
    setLocalObject(JSON.parse(JSON.stringify(valueArrState)));
  };
  const onClosePopUp = () => {
    setLocalObject(valueArrState);
    dispatch({ type: POPUP_CLOSE });
  };
  return (
    <div>
      <Popup isActive={isActive}>
        <PopupHeader
          headerPanelColour={headerPanelColour}
          headerOne={headerOne}
          headerOneBadgeOne={headerOneBadgeOne}
          headerOneBadgeTwo={headerOneBadgeTwo}
          onClick={handleClick}
          onClosePopUpEvent={onClosePopUp}
          mode={mode}
        />
        <DialogContent
          className={['popupContent', 'fixed10PadDim', 'revisePopupContent'].join(' ')}
        >
          <div className={'fitContent'}>
            <div className={['PopupFormBox', 'labelPopupBox', 'popupMinHei'].join(' ')}>
              <InputLabel
                htmlFor="name-input"
                className={['titleBox', 'textForLabelPopup'].join(' ')}
              //className={' textForLabelPopup'}
              >
                <Fragment>
                  {inputHeader}&nbsp;
                  {inputHeaderBadge ? (
                    <span className={'headerBadge'}>{inputHeaderBadge}</span>
                  ) : null}
                  &nbsp;
                  {inputHeaderBadgeTwo ? (
                    <span className={'headerBadge'}>{inputHeaderBadgeTwo}</span>
                  ) : null}
                  &nbsp;
                  {inputHeaderBadgeThree ? (
                    <span className={'headerBadge'}>{inputHeaderBadgeThree}</span>
                  ) : null}
                </Fragment>
              </InputLabel>
              <div className={'infoSymbol'}></div>
              <div className={'infoSymbol'}>
                <InfoToolTip message={infoMsg} />
              </div>
            </div>
          </div>
          {headerOne === 'assessees' && (
            <SelectField
              tag={'information'}
              label={'information'}
              dataValue={'information'}
              listSelect={informationArr}
              errorMsg={() => { }}
              onChange={handleDropDown}
              value={permissionInfo}
              mappingValue={'id'}
            />
          )}
          {valueArr.map((item, index) => (
            <div className={'fitContent'} key={`check${index}`}>
              <div className={['PopupFormBox', 'popupMinHei0'].join(' ')} style={{ minHeight: 0 }}>
                <div className={'contFlex'}>
                  <div className={'f4'}>{createNameWithBadge(item.name, stateName)}</div>
                  <div className={'checkedFontNew'}>
                    <Checkbox
                      color="default"
                      value={item.name}
                      //value={item}
                      key={index}
                      name={item.name}
                      disabled={item.disabled || isNotRevised}
                      checked={item.checked}
                      onChange={handleSingleState}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
          <FormHelperText
            className={['helperText', 'helptextmargin'].join(' ')}
            style={{ margin: '5px 0px 0px 5px' }}
          >
            <span>{errorMsg}</span>
          </FormHelperText>
        </DialogContent>
      </Popup>
    </div>
  );
};
PopUpCheckboxRolePermission.propTypes = {
  className: PropTypes.string,
  headerPanelColour: PropTypes.oneOf([
    'displayPaneLeft',
    'displayPaneCentre',
    'displayPaneRight',
    'genericOne',
    'genericTwo'
  ]),
  headerOne: PropTypes.string,
  headerOneBadgeOne: PropTypes.string,
  headerOneBadgeTwo: PropTypes.string,
  headerOneBadgeThree: PropTypes.string,
  isActive: PropTypes.bool
};
export default PopUpCheckboxRolePermission;