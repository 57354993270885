import React, { useEffect, useState } from 'react';
import PersonIcon from '@material-ui/icons/Person';
import AssociateIcon from '@material-ui/icons/Stars';
import ArrowRight from '@material-ui/icons/ChevronRight';
import Card from '../../Molecules/Card/Card';
import HeaderCard from '../../Molecules/Header/HeaderCard';
import './DisplayPaneOne.css';
import Sections from '../../Molecules/Section/Section';
import FooterIconOne from '../../Molecules/FooterIcon/FooterIconOne';
import { useDispatch, useSelector } from 'react-redux';
import {
  CLEAR_DISPLAY_PANE_THREE,
  FILTERMODE,
  SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MIDDLEPANE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_POPUP_SINGLE_STATE,
  SET_POPUP_STATE
} from '../../actionType';
import {
  ASSESSEE_CARD_POPUP_OPTIONS,
  ASSOCIATE_CARD_POPUP_OPTION,
  SELF_POPUP_OPTION
} from '../../PopUpConfig';
import PopUpTextEditor from '../../PopUpIcon/PopUpTextEditor';
import PopUpWorkSheet from '../../PopUpIcon/PopUpWorkSheet';
import PopUpAssesseePassword from '../../PopUpInformation/PopUpAssesseePassword';
import PopUpDisplayPanelAssessee from '../../PopUpDisplayPanel/PopUpDisplayPanelAssessee';
import PopUpDisplayPanelAssociate from '../../PopUpDisplayPanel/PopUpDisplayPanelAssociate';
import DisplayPaneOneSectionOne from '../../Molecules/DisplayPaneOne/DisplayPaneOneSectionOne';
import DisplayPaneOneSectionTwo from '../../Molecules/DisplayPaneOne/DisplayPaneOneSectionTwo';
import DisplayPaneOneSectionThree from '../../Molecules/DisplayPaneOne/DisplayPaneOneSectionThree';
import DisplayPaneOneSectionFour from '../../Molecules/DisplayPaneOne/DisplayPaneOneSectionFour';
import { setAssociateCardPermissionInJson } from '../../Actions/GenericActions';
import PopUpDisplayPaneOneSectionTwo from '../../PopUpDisplayPanel/PopUpDisplayPaneOneSectionTwo';
import PopUpAssesseesModule from '../../PopUpDisplayPanel/PopUpAssesseesModule';
import PopUpAssociatesModule from '../../PopUpDisplayPanel/PopUpAssociatesModule';
import PopupAssessmentsModule from '../../PopUpDisplayPanel/PopupAssessmentsModule';
import PopUpAssignmentModule from '../../PopUpDisplayPanel/PopUpAssignmentModule';
import PopUpIgaugeModule from '../../PopUpDisplayPanel/PopUpIgaugeModule';
// import PopUpScan from '../../PopUpInformation/PopUpScan';
import PopUpAssociateLink from '../../PopUpDisplayPanel/PopUpAssociateLink';
import DisplayPaneSixHeader from '../DisplayPaneSix/DisplayPaneSixHeader';
import { Paper } from '@material-ui/core';

export const DisplayPaneOne = () => {
  const leftPaneSections = [
    {
      id: 'paneoneSection1',
      sectionComponent: DisplayPaneOneSectionOne,
      displayPaneLeftHeaderText: 'dashboard',
      displayPaneLeftBadgeText: ''
    },
    {
      id: 'paneoneSection2',
      sectionComponent: DisplayPaneOneSectionTwo,

      displayPaneLeftHeaderText: 'iGuru analytics',
      displayPaneLeftBadgeText: ''
    },
    {
      id: 'paneoneSection3',
      sectionComponent: DisplayPaneOneSectionThree,
      displayPaneLeftHeaderText: 'iGuru marketplace',
      displayPaneLeftBadgeText: ''
    },
    {
      id: 'paneoneSection4',
      sectionComponent: DisplayPaneOneSectionFour,
      displayPaneLeftHeaderText: 'iGuru mine',
      displayPaneLeftBadgeText: ''
    }
  ];
  const [selectedSection, setSelectedSection] = useState(leftPaneSections[0]);
  const [selectedURL, setSelectedURL] = useState('https://www.youtube.com/watch?v=B8iRhyfu4DU');
  //const [selectedURL, setSelectedURL] = useState('/media/cc0-videos/flower.mp4');
  const dispatch = useDispatch();
  const { isPopUpValue } = useSelector((state) => state.PopUpReducer);
  const { userData } = useSelector((state) => state.UserReducer);
  const { isAssessmentStart, isExamMode } = useSelector(
    (state) => state.AssesseeAssignmentAssessmentReducer
  );

  // console.log(isExamMode, 'EXAMMODE');
  useEffect(() => {
    if (!isExamMode) {
      dispatch({
        type: 'STOP_COUNTDOWN'
      });
    }
  }, [isExamMode]);

  const { isDisplayPaneSixShow } = useSelector((state) => state.AssessmentReducer);
  const {
    selectedAssociateInfo,
    leftPaneAssesseeInfo,
    signedAssesseePermission,
    isListLoading,
    paneOneSelectedSection
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  useEffect(() => {
    if (paneOneSelectedSection === leftPaneSections[0].id) setSelectedSection(leftPaneSections[0]);
  }, [paneOneSelectedSection]);
  useEffect(() => {
    dispatch({
      type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
      payload: { stateName: 'footerButtonMode', value: true }
    });
  }, [paneOneSelectedSection]);

  useEffect(() => {
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'sectionCreateType', value: '' }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'assignmentCreateTypeSecondary', value: '' }
    });
  }, [selectedSection]);

  const { assignmentsPopUpActive } = useSelector((state) => state.AssignmentReducer);
  const { sectionPopUpActive } = useSelector((state) => state.IgaugeReducer);
  const associateName = selectedAssociateInfo
    ? selectedAssociateInfo?.associate.informationBasic.associateName
    : 'associates';
  const associatePicture = selectedAssociateInfo
    ? selectedAssociateInfo?.associate.informationBasic?.associatePicture
    : 'associates';
  const associateDescription = selectedAssociateInfo
    ? selectedAssociateInfo?.associate?.informationBasic?.associateDescription
    : '';
  const assesseeAlias = leftPaneAssesseeInfo?.assessee?.informationBasic?.assesseeAlias;
  const assesseePicture = leftPaneAssesseeInfo?.assessee?.informationBasic?.assesseePicture;
  const assesseeSignIN = localStorage.getItem('assesseeId');
  const assesseeName = leftPaneAssesseeInfo.assessee
    ? leftPaneAssesseeInfo.assessee.informationBasic.assesseeNameFirst.trim() +
      ' ' +
      leftPaneAssesseeInfo.assessee.informationBasic.assesseeNameLast.trim()
    : assesseeSignIN;
  const openCardPopup = (e) => {
    let popupContentArrValue = [];
    let popupHeaderOne = '';
    let popupHeaderOneBadgeOne = '';
    let value = '';
    if (e.currentTarget.getAttribute('data-value') !== '') {
      if (e.currentTarget.getAttribute('data-value') === 'assessee_card') {
        let associatePermission = signedAssesseePermission?.assesseeSelfPermission;
        let popupContentArr = setAssociateCardPermissionInJson(
          ASSESSEE_CARD_POPUP_OPTIONS,
          associatePermission
        );
        popupHeaderOne = 'assessee';
        popupHeaderOneBadgeOne = 'self';
        // link and password options disabled when alert is active
        let tempArr = [];
        popupContentArr.forEach((element) => {
          if (
            element.data === 'link' &&
            signedAssesseePermission?.assesseeDistinctPermission === null &&
            signedAssesseePermission?.assessmentDistictPermission === null &&
            signedAssesseePermission?.assigmentDistictPermission === null &&
            signedAssesseePermission?.associateDistinctPermission === null &&
            signedAssesseePermission?.iGuruAnalyticDistinctPermission === null &&
            leftPaneAssesseeInfo?.notifications?.alert
          ) {
            tempArr.push({ ...element, disabled: true });
          } else if (
            element.data === 'password' &&
            signedAssesseePermission?.assesseeDistinctPermission === null &&
            signedAssesseePermission?.assessmentDistictPermission === null &&
            signedAssesseePermission?.assigmentDistictPermission === null &&
            signedAssesseePermission?.associateDistinctPermission === null &&
            signedAssesseePermission?.iGuruAnalyticDistinctPermission === null &&
            leftPaneAssesseeInfo?.notifications?.alert
          ) {
            tempArr.push({ ...element, disabled: true });
          } else {
            tempArr.push(element);
          }
        });
        //popupContentArrValue = ASSESSEE_CARD_POPUP_OPTIONS;
        popupContentArrValue = tempArr;
        value = 'ASSESSEE_CARD_POPUP';
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'sectionCreateType',
            value: 'assesseeSelf'
          }
        });
      }
      dispatch({
        type: SET_MIDDLEPANE_STATE,
        payload: {
          middlePaneHeader: '',
          middlePaneHeaderBadgeOne: '',
          middlePaneHeaderBadgeTwo: '',
          middlePaneHeaderBadgeThree: '',
          middlePaneHeaderBadgeFour: '',
          typeOfMiddlePaneList: '',
          showMiddlePaneState: false
        }
      });
      dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
      let obj = [];
      if (e.currentTarget.getAttribute('data-value') === 'associate_card') {
        obj = [...ASSOCIATE_CARD_POPUP_OPTION];
        let sectionTwoObj = {
          data: 'analytics',
          dataValue: 'analytics',
          dataKey: 'analytics',
          optionClass: 'optionPrimary',
          disabled: true,
          divider: 'dark',
          permissionArr: '',
          permission: 'iGuruAnalytic',
          permissionValue: 'all'
        };
        let sectionThreeObj = {
          data: 'marketplace',
          dataValue: 'marketplace',
          dataKey: 'marketplace',
          optionClass: 'optionPrimary',
          divider: 'dark',
          disabled: true,
          permissionArr: '',
          permission: '',
          permissionValue: ''
        };
        let sectionFourObj = {
          data: 'mine',
          dataValue: 'mine',
          dataKey: 'mine',
          optionClass: 'optionPrimary',
          divider: 'dark',
          disabled: true,
          permissionArr: '',
          permission: '',
          permissionValue: ''
        };

        if (selectedSection.id === 'paneoneSection2') obj.splice(1, 0, sectionTwoObj);

        if (selectedSection.id === 'paneoneSection3') obj.splice(1, 0, sectionTwoObj);
        if (selectedSection.id === 'paneoneSection3') obj.splice(9, 0, sectionThreeObj);
        if (selectedSection.id === 'paneoneSection4') obj.splice(8, 0, sectionFourObj);
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'paneOneSelectedSection', value: selectedSection.id }
        });
        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'selectedSectionPopup', value: selectedSection.id }
        });
        console.log('selectedSection', selectedSection);
        let associatePermission = signedAssesseePermission?.associateSelfPermission;
        // let popupContentArr = ASSOCIATE_CARD_POPUP_OPTION;

        let popupContentArr = setAssociateCardPermissionInJson(obj, associatePermission);
        popupHeaderOne = 'associate';
        popupHeaderOneBadgeOne = 'self';
        popupContentArrValue = popupContentArr;
        value = 'ASSOCIATE_CARD_POPUP';
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'assignmentCreateType',
            value: 'iGuru_Assignment'
          }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'sectionCreateType',
            value: ''
          }
        });
      }
      if (e.currentTarget.getAttribute('data-value') === 'assessee') {
        popupHeaderOne = 'assessee';
        popupHeaderOneBadgeOne = 'self';
        popupContentArrValue = SELF_POPUP_OPTION;
        value = 'ASSESSEE_CARD_POPUP';
      }
      dispatch({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'headerButtons', value: true }
      });
      dispatch({
        type: SET_POPUP_STATE,
        payload: {
          popupHeaderOne: popupHeaderOne,
          popupHeaderOneBadgeOne: popupHeaderOneBadgeOne,
          isPopUpValue: value,
          popupOpenType: 'primary',
          popupContentArrValue: popupContentArrValue,
          currentPopUpOption: []
        }
      });
    } else {
      dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
      dispatch({
        type: SET_MIDDLEPANE_STATE,
        payload: {
          middlePaneHeader: 'associate',
          middlePaneHeaderBadgeOne: 'active',
          middlePaneHeaderBadgeTwo: '',
          middlePaneHeaderBadgeThree: '',
          middlePaneHeaderBadgeFour: '',
          typeOfMiddlePaneList: 'assesseeRelatedAssociate',
          scanCount: userData && userData.length,
          showMiddlePaneState: true
        }
      });
      dispatch({
        type: FILTERMODE,
        payload: { FilterMode: 'assesseeRelatedAssociate' }
      });
    }

    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: { stateName: 'selectionValue', value: '' }
    });
    dispatch({
      type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
      payload: { stateName: 'footerButtonMode', value: true }
    });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  };
  // console.log('PANE TWO +====>', isAssessmentStart);

  return (
    <>
      <div>
        {isAssessmentStart === 'PROGRESS' ? (
          <DisplayPaneSixHeader
            className=""
            headerOne={'dashboard'}
            headerOneBadgeOne={''}
            headerOneBadgeTwo={''}
            headerPanelColour="blue"
          />
        ) : (
          <HeaderCard
            className=""
            displayPane="left"
            headerOne={selectedSection.displayPaneLeftHeaderText}
            headerOneBadgeOne={selectedSection.displayPaneLeftBadgeText}
            headerPanelColour="blue"
          />
        )}
      </div>
      {isDisplayPaneSixShow && (
        <div className="containerPadding">
          <div className="containerPadding">
            <Card
              ImageOne={selectedAssociateInfo ? PersonIcon : null}
              textOneOne={assesseeName}
              textTwoOne={assesseeAlias}
              isImageActive={assesseePicture}
              imageOneOne={assesseePicture}
              onClick={(event) => {
                !isListLoading && openCardPopup(event);
              }}
              isAlertActive={leftPaneAssesseeInfo?.notifications?.alert} //.
              tag={selectedAssociateInfo ? 'assessee_card' : 'assessee'}
              isAssessmentStart={isAssessmentStart === 'PROGRESS'}
            />
          </div>
          <div className="containerPadding">
            {selectedAssociateInfo ? (
              <Card
                ImageOne={AssociateIcon}
                textOneOne={associateName}
                textTwoOne={associateDescription}
                onClick={(event) => {
                  !isListLoading && openCardPopup(event);
                }}
                isImageActive={associatePicture}
                imageOneOne={associatePicture}
                tag={'associate_card'}
                isAssessmentStart={isAssessmentStart === 'PROGRESS'}
              />
            ) : (
              <Card
                isIcon
                IconOne={ArrowRight}
                textOneOne="associates"
                onClick={(event) => {
                  !isListLoading && openCardPopup(event);
                }}
                tag={''}
                onClickIconOne={(event) => {
                  !isListLoading && openCardPopup(event);
                }}
              />
            )}
          </div>

          {(selectedAssociateInfo &&
            signedAssesseePermission &&
            isAssessmentStart !== 'PROGRESS' &&
            isAssessmentStart !== 'ReviewListResume' &&
            signedAssesseePermission?.assesseeDistictPermission !== null &&
            signedAssesseePermission?.assessmentDistictPermission !== null &&
            signedAssesseePermission?.assigmentDistictPermission !== null &&
            signedAssesseePermission?.associateDistictPermission !== null &&
            signedAssesseePermission?.iGuruAnalyticDistinctPermission !== null &&
            signedAssesseePermission?.itemDistictPermssion !== null && (
              <div>
                <Sections
                  listSections={leftPaneSections}
                  selectedSection={selectedSection}
                  setSelectedSection={setSelectedSection}
                  onClickFrom={'sectionOne'}
                />

                <FooterIconOne />
              </div>
            )) ||
            // (!leftPaneAssesseeInfo?.notifications?.alert &&
            //   signedAssesseePermission?.assesseeDistinctPermission === null &&
            //   signedAssesseePermission?.assessmentDistictPermission === null &&
            //   signedAssesseePermission?.assigmentDistictPermission === null &&
            //   signedAssesseePermission?.associateDistinctPermission === null &&
            //   signedAssesseePermission?.iGuruAnalyticDistinctPermission === null && (
            //     <>
            //       <div className={['containerPadding'].join(' ')} style={{ height: '50px' }}></div>
            //       <div className={['containerPadding'].join(' ')}>
            //         <Paper>
            //           <div>
            //             <video controls height={'100%'} width={'100%'}>
            //               <source src={selectedURL} type="video/mp4" />
            //             </video>
            //           </div>
            //         </Paper>
            //       </div>

            //       <FooterIconOne />
            //     </>
            //   )) ||
            ''}
          {/* {signedAssesseePermission &&
            signedAssesseePermission && (
              <>
              <div className={['containerPadding'].join(' ')} style={{height:'50px'}}>
              </div>
                <div className={['containerPadding'].join(' ')}>
                  <Paper>
                    <video //className="display-pane-container"
                     width="228" height="240" 
                     controls>
                      <source src={selectedURL} type="video/mp4" />
                    </video>
                  </Paper>
                </div>   
              </>        
            )} */}
          {isAssessmentStart === 'PROGRESS' ? (
            <div>
              {/* <DisplayPaneSixFooter /> */}
              <FooterIconOne />
            </div>
          ) : isAssessmentStart === 'ReviewListResume' ? (
            <FooterIconOne />
          ) : null}
        </div>
      )}{' '}
      {isPopUpValue === 'DISPLAY_PANE_ONE_SECTION_TWO_POPUP' && (
        <PopUpDisplayPaneOneSectionTwo isActive={true} />
      )}
      {sectionPopUpActive && <PopUpIgaugeModule />}
      {assignmentsPopUpActive && <PopUpAssignmentModule isActive={true} />}
      <PopUpAssesseesModule />
      <PopUpAssociatesModule />
      <PopupAssessmentsModule />
      {isPopUpValue === 'ASSESSEE_CARD_POPUP' && <PopUpDisplayPanelAssessee isActive={true} />}
      {isPopUpValue === 'ASSOCIATE_CARD_POPUP' && <PopUpDisplayPanelAssociate isActive={true} />}
      {isPopUpValue === 'TEXTSHEET_POPUP' && <PopUpTextEditor isActive={true} />}
      {isPopUpValue === 'SPREADSHEET_POPUP' && <PopUpWorkSheet isActive={true} />}
      {isPopUpValue === 'REVISE_PASSWORD_POPUP' && <PopUpAssesseePassword isActive={true} />}
      <PopUpAssociateLink />
    </>
  );
};

export default DisplayPaneOne;
