import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PopUpPicture from '../../PopUpInformation/PopUpPicture';
import PopUpDropList from '../../PopUpInformation/PopUpDropList';
import PopUpTextField from '../../PopUpInformation/PopUpTextField';
import PopUpTagSecondary from '../../PopUpInformation/PopUpTagSecondary';
import PopUpConfirm from '../../PopUpGeneric/PopUpConfirm';
import {
  POPUP_CLOSE,
  LOADER_START,
  SET_DISPLAY_THREE_SINGLE_STATE,
  CLEAR_VERSION_REDUCER_STATE,
  SET_VERSION_REDUCER_STATE,
  CREATE_ASSESSMENT_SECTION_SAGA
} from '../../actionType';

const PopUpVersionCreate = (props) => {
  const { headerOne } = props;
  const { isPopUpValue, selectedTagValue } = useSelector((state) => state.PopUpReducer);
  const { versionInformation } = useSelector((state) => state.VersionCreateReducer);
  console.log(versionInformation);
  const { reviewMode, responseObject } = useSelector((state) => state.DisplayPaneThreeReducer);
  const { selectedAssociateInfo } = useSelector((state) => state.DisplayPaneTwoReducer);

  const dispatch = useDispatch();
  const onClickCancelYes = () => {
    dispatch({
      type: SET_DISPLAY_THREE_SINGLE_STATE,
      payload: { stateName: 'createMode', value: '' }
    });
    dispatch({ type: CLEAR_VERSION_REDUCER_STATE });
    dispatch({ type: POPUP_CLOSE });
  };
  const onClickYes = () => {
    dispatch({ type: POPUP_CLOSE });
    let requestObj = {
      module: 'Assessment-Distinct',
      action: 'Create',
      actionFilter: 'Section-Cluster-Scale',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        assessmentId: selectedTagValue,
        assessmentVersionFramework: versionInformation
      }
    };
    console.log('requestObj', requestObj);
    dispatch({ type: POPUP_CLOSE });
    dispatch({ type: LOADER_START });
    dispatch({ type: CREATE_ASSESSMENT_SECTION_SAGA, payload: { requestObj } });
  };
  console.log('versionInformation', versionInformation);
  let tenureStartDate = 'mm/dd/yyyy --:-- --';
  let tenureEndDate = 'mm/dd/yyyy --:-- --';
  return (
    <div>
      {isPopUpValue === 'NAMEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'name'}
          actualLableValue={'assessmentVersionName'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'version'}
          headerOneBadgeTwo={'information'}
          nextPopUpValue={'ALIASPOPUP'}
          basicInfo={versionInformation}
          typeOfSetObject={SET_VERSION_REDUCER_STATE}
          isRequired={true}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ALIASPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'description'}
          actualLableValue={'assessmentVersionDescription'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'version'}
          headerOneBadgeTwo={'information'}
          basicInfo={versionInformation}
          nextPopUpValue={'PICTUREPOPUP'}
          typeOfSetObject={SET_VERSION_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'PICTUREPOPUP' && (
        <PopUpPicture
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'version'}
          headerOneBadgeTwo={'information'}
          nextPopUpValue={'CONFIRMATIONPOPUP'}
          actualLableValue={'assessmentVersionPicture'}
          actualLableValueVerification={'assessmentVersionPictureVerification'}
          basicInfo={versionInformation}
          typeOfSetObject={SET_VERSION_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'CANCELPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'cancel'}
          headerOneBadgeOne={''}
          mode={'cancel'}
          onClickYes={onClickCancelYes}
        />
      )}
      {isPopUpValue === 'CONFIRMATIONPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'version'}
          headerOneBadgeTwo={'create'}
          onClickYes={onClickYes}
        />
      )}
      {isPopUpValue === 'TENURESATRTDATEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tenure'}
          labelBadgeOne={'start'}
          actualLableValue={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'version'}
          headerOneBadgeTwo={'information'}
          basicInfo={tenureStartDate}
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={SET_VERSION_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TENUREENDDATEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tenure'}
          labelBadgeOne={'end'}
          actualLableValue={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'version'}
          headerOneBadgeTwo={'information'}
          basicInfo={tenureEndDate}
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={SET_VERSION_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'STATUSPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentStatus'}
          label={'status'}
          listSelect={[
            { id: 'Active', name: 'Active' },
            { id: 'Archived', name: 'Archived' },
            { id: 'Inactive', name: 'Inactive' },
            { id: 'Suspended', name: 'Suspended' },
            { id: 'Terminated', name: 'Terminated' }
          ]}
          mappingValue={'id'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'version'}
          headerOneBadgeTwo={'information'}
          isRequired={true}
          basicInfo={''}
          nextPopUpValue={''}
          typeOfSetObject={SET_VERSION_REDUCER_STATE}
          handleNextPopupValue={() => {}}
          isNotRevised={true}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TAGREADONLYPRIMARYPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tag'}
          labelBadgeOne={'primary'}
          actualLableValue={'id'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'version'}
          headerOneBadgeTwo={'information'}
          basicInfo={ responseObject}
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={SET_VERSION_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TAGSECONDARYPOPUP' && (
        <PopUpTagSecondary
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'version'}
          headerOneBadgeTwo={'information'}
          tagSecondary={'' || {}}
          signInSetup={'' || {}}
          nextPopUpValue={''}
          typeOfSetObject={SET_VERSION_REDUCER_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
    </div>
  );
};

export default PopUpVersionCreate;
