import React, { useState } from "react";
import { isMobile } from "react-device-detect";
// import AllocationAccordian from '../Accordian/AllocationAccordian';
import Manuscript from "@material-ui/icons/Description";
import { useDispatch, useSelector } from "react-redux";
import DisplayPanelAccordianReviewListOne from "../Accordian/DisplayPanelAccordianReviewListOne";
import DisplayPanelAccordianInformation from "../Accordian/DisplayPanelAccordianInformation";
import { Paper } from "@material-ui/core";
import {
  GET_ASSIGNMENT_GROUP_REVIEW_LIST_SAGA,
  GET_ASSIGNMENT_TYPE_REVIEW_LIST_SAGA,
  INTERNAL_NODE_LIST_WITH_PERMISSIONS_SAGA,
  LOADER_START,
  SET_CORE_GROUP_REVIEW_LIST_REQ_OBJECT,
  SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT,
  SET_CORE_TYPE_REVIEW_LIST_REQ_OBJECT,
  SET_POPUP_VALUE,
  SET_STATUS_POPUP_VALUE,
} from "../../actionType";
import DisplayPanelAccordianReviewListTwo from "../Accordian/DisplayPanelAccordianReviewListTwo";
import { makeAssignmentGroupObj, makeAssignmentTypeObj, makeInternalNodeObj } from "../../Actions/GenericActions";

const DisplayPaneThreeSectionOneAssignmentGroup = () => {
  // const [listExpand, setListExpand] = useState('');
  const { responseObject, reviewMode } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  const { selectedAssociateInfo,assignmentCreateType } = useSelector(
    (state) => state.DisplayPaneTwoReducer
  );
  const { informationEngagement, informationSetup, informationAllocation } =
    responseObject;
  const dispatch = useDispatch();
  //ascendant
  let ascendantAll = [];
  if (
    informationAllocation?.assignmentGroupAscendant
      ?.assignmentGroupAscendantAll &&
    informationAllocation?.assignmentGroupAscendant?.assignmentGroupAscendantAll
      .length > 0
  ) {
    const tempArr =
      informationAllocation?.assignmentGroupAscendant
        ?.assignmentGroupAscendantAll;
    tempArr.forEach((ob) => {
      ascendantAll.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.assignmentGroupName || "",
        textTwo: ob?.informationBasic?.assignmentGroupDescription || "",
        status: ob?.informationEngagement?.assignmentGroupStatus || "",
      });
    });
  }
  let ascendantPrimary = [];
  if (
    informationAllocation?.assignmentGroupAscendant
      ?.assignmentGroupAscendantPrimary &&
    informationAllocation?.assignmentGroupAscendant
      ?.assignmentGroupAscendantPrimary.length > 0
  ) {
    const tempArr =
      informationAllocation?.assignmentGroupAscendant
        ?.assignmentGroupAscendantPrimary;
    tempArr.forEach((ob) => {
      ascendantPrimary.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.assignmentGroupName || "",
        textTwo: ob?.informationBasic?.assignmentGroupDescription || "",
        status: ob?.informationEngagement?.assignmentGroupStatus || "",
      });
    });
  }
  let ascendantSecondary = [];
  if (
    informationAllocation?.assignmentGroupAscendant
      ?.assignmentGroupAscendantSecondary &&
    informationAllocation?.assignmentGroupAscendant
      ?.assignmentGroupAscendantSecondary.length > 0
  ) {
    const tempArr =
      informationAllocation?.assignmentGroupAscendant
        ?.assignmentGroupAscendantSecondary;
    tempArr.forEach((ob) => {
      ascendantSecondary.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.assignmentGroupName || "",
        textTwo: ob?.informationBasic?.assignmentGroupDescription || "",
        status: ob?.informationEngagement?.assignmentGroupStatus || "",
      });
    });
  }
  //decendent
  let descendantAll = [];
  if (
    informationAllocation?.assignmentGroupDescendant
      ?.assignmentGroupDescendantAll &&
    informationAllocation?.assignmentGroupDescendant
      ?.assignmentGroupDescendantAll.length > 0
  ) {
    const tempArr =
      informationAllocation?.assignmentGroupDescendant
        ?.assignmentGroupDescendantAll;
    tempArr.forEach((ob) => {
      descendantAll.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.assignmentGroupName || "",
        textTwo: ob?.informationBasic?.assignmentGroupDescription || "",
        status: ob?.informationEngagement?.assignmentGroupStatus || "",
      });
    });
  }
  let descendantPrimary = [];
  if (
    informationAllocation?.assignmentGroupDescendant
      ?.assignmentGroupDescendantPrimary &&
    informationAllocation?.assignmentGroupDescendant
      ?.assignmentGroupDescendantPrimary.length > 0
  ) {
    const tempArr =
      informationAllocation?.assignmentGroupDescendant
        ?.assignmentGroupDescendantPrimary;
    tempArr.forEach((ob) => {
      descendantPrimary.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.assignmentGroupName || "",
        textTwo: ob?.informationBasic?.assignmentGroupDescription || "",
        status: ob?.informationEngagement?.assignmentGroupStatus || "",
      });
    });
  }
  let descendantSecondary = [];
  if (
    informationAllocation?.assignmentGroupDescendant
      ?.assignmentGroupDescendantSecondary &&
    informationAllocation?.assignmentGroupDescendant
      ?.assignmentGroupDescendantSecondary.length > 0
  ) {
    const tempArr =
      informationAllocation?.assignmentGroupDescendant
        ?.assignmentGroupDescendantSecondary;
    tempArr.forEach((ob) => {
      descendantSecondary.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.assignmentGroupName || "",
        textTwo: ob?.informationBasic?.assignmentGroupDescription || "",
        status: ob?.informationEngagement?.assignmentGroupStatus || "",
      });
    });
  }
  // node primary list
  let nodePrimary = [];
  if (
    informationAllocation?.assignmentGroupNode?.assignmentGroupNodePrimary &&
    informationAllocation?.assignmentGroupNode?.assignmentGroupNodePrimary
      .length > 0
  ) {
    const tempArr =
      informationAllocation?.assignmentGroupNode?.assignmentGroupNodePrimary;
    tempArr.forEach((ob) => {
      nodePrimary.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.associateNodeName || "",
        textTwo: ob?.informationBasic?.associateNodeDescription || "",
        status: ob?.informationEngagement?.associateNodeStatus || "",
      });
    });
  }

  // node secondary list
  let nodeSecondary = [];
  if (
    informationAllocation?.assignmentGroupNode?.assignmentGroupNodeSecondary &&
    informationAllocation?.assignmentGroupNode?.assignmentGroupNodeSecondary
      .length > 0
  ) {
    const tempArr =
      informationAllocation?.assignmentGroupNode?.assignmentGroupNodeSecondary;
    tempArr.forEach((ob) => {
      nodeSecondary.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.associateNodeName || "",
        textTwo: ob?.informationBasic?.associateNodeDescription || "",
        status: ob?.informationEngagement?.associateNodeStatus || "",
      });
    });
  }
  let groupTypeListPrimary = [];
  if (
    informationAllocation?.assignmentGroupType?.assignmentGroupTypePrimary &&
    informationAllocation?.assignmentGroupType?.assignmentGroupTypePrimary.length > 0
  ) {
    const tempArr = informationAllocation?.assignmentGroupType?.assignmentGroupTypePrimary;
    tempArr.forEach((ob) => {
      groupTypeListPrimary.push({
        id: ob?.id + '-' || '',
        textOne: ob?.informationBasic?.assignmentTypeName || '',
        textTwo: ob?.informationBasic?.assignmentTypeDescription || '',
        status: ob?.informationEngagement?.assignmentTypeStatus || ''
      });
    });
  }
  let groupTypeListSecondary = [];
  if (
    informationAllocation?.assignmentGroupType?.assignmentGroupTypeSecondary &&
    informationAllocation?.assignmentGroupType?.assignmentGroupTypeSecondary.length > 0
  ) {
    const tempArr = informationAllocation?.assignmentGroupType?.assignmentGroupTypeSecondary;
    tempArr.forEach((ob) => {
      groupTypeListSecondary.push({
        id: ob?.id + '-' || '',
        textOne: ob?.informationBasic?.assignmentTypeName || '',
        textTwo: ob?.informationBasic?.assignmentTypeDescription || '',
        status: ob?.informationEngagement?.assignmentTypeStatus || ''
      });
    });
  }
  function capitalizeFirstLetter(string) {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  const allocationList = [
    {
      id: "a1",
      labelTextOneOne: "group",
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: "ascendant",
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: "all",
              innerList: ascendantAll.sort((a, b) =>
                a.textOne.toLowerCase() !== b.textOne.toLowerCase()
                  ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
                    ? -1
                    : 1
                  : 0
              ),
            },
            {
              labelTextTwoBadge: "primary",
              innerList: ascendantPrimary.sort((a, b) =>
                a.textOne.toLowerCase() !== b.textOne.toLowerCase()
                  ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
                    ? -1
                    : 1
                  : 0
              ),
            },
            {
              labelTextTwoBadge: "secondary",
              innerList: ascendantSecondary.sort((a, b) =>
                a.textOne.toLowerCase() !== b.textOne.toLowerCase()
                  ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
                    ? -1
                    : 1
                  : 0
              ),
            },
          ],
        },
        {
          labelTextOneOneBadge: "descendant",
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: "all",
              innerList: descendantAll.sort((a, b) =>
                a.textOne.toLowerCase() !== b.textOne.toLowerCase()
                  ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
                    ? -1
                    : 1
                  : 0
              ),
            },
            {
              labelTextTwoBadge: "primary",
              innerList: descendantPrimary.sort((a, b) =>
                a.textOne.toLowerCase() !== b.textOne.toLowerCase()
                  ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
                    ? -1
                    : 1
                  : 0
              ),
            },
            {
              labelTextTwoBadge: "secondary",
              innerList: descendantSecondary.sort((a, b) =>
                a.textOne.toLowerCase() !== b.textOne.toLowerCase()
                  ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
                    ? -1
                    : 1
                  : 0
              ),
            },
          ],
        },
      ],
      innerInfo: "No Information",
      isListCard: true,
      isReviewLink: false,
      isMultiList: true,
    },
    // {
    //   id: 'a2',
    //   labelTextOneOne: 'manager',
    //   labelTextOneOneBadgeOne: 'primary',
    //   labelTextOneOneBadgeTwo: 'secondary',
    //   labelTextOneOneBadgeThree: '',
    //   labelTextOneOneBadgeFour: '',
    //   labelTextOneOneBadges: [
    //     {
    //       labelTextOneOneBadge: 'primary',
    //       innerList: [
    //         {
    //           id: 'associate1',
    //           textOne: 'Simple Sample 01',
    //           textTwo: 'Manager',
    //           status: 'active'
    //         },
    //         {
    //           id: 'associate2',
    //           textOne: 'Simple Sample 02',
    //           textTwo: 'Manager',
    //           status: 'active'
    //         },
    //         {
    //           id: 'associate3',
    //           textOne: 'Simple Sample 03',
    //           textTwo: 'Manager',
    //           status: 'active'
    //         }
    //       ]
    //     },
    //     {
    //       labelTextOneOneBadge: 'secondary',
    //       innerList: []
    //     }
    //   ],
    //   innerInfo: 'No Information',
    //   isListCard: true
    // },
    {
      id: "a3",
      labelTextOneOne: "node",
      labelTextOneOneBadgeOne: "primary",
      labelTextOneOneBadgeTwo: "secondary",
      labelTextOneOneBadgeThree: "",
      labelTextOneOneBadgeFour: "",
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'primary',
          innerList: nodePrimary.sort((a, b) =>
            a.textOne.toLowerCase() !== b.textOne.toLowerCase()
              ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
                ? -1
                : 1
              : 0
          )
        },
        {
          labelTextOneOneBadge: 'secondary',
          innerList: nodeSecondary.sort((a, b) =>
            a.textOne.toLowerCase() !== b.textOne.toLowerCase()
              ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
                ? -1
                : 1
              : 0
          )
        }
      ],
      innerInfo: "No Information",
      isListCard: true,
    },
    {
      id: "a4",
      labelTextOneOne: "type",
      labelTextOneOneBadgeOne: "primary",
      labelTextOneOneBadgeTwo: "secondary",
      labelTextOneOneBadgeThree: "",
      labelTextOneOneBadgeFour: "",
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: "primary",
          innerList: groupTypeListPrimary.sort((a, b) =>
          a.textOne.toLowerCase() !== b.textOne.toLowerCase()
            ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
              ? -1
              : 1
            : 0
        ),
        },
        {
          labelTextOneOneBadge: "secondary",
          innerList: groupTypeListSecondary.sort((a, b) =>
          a.textOne.toLowerCase() !== b.textOne.toLowerCase()
            ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
              ? -1
              : 1
            : 0
        ),
        },
      ],
      innerInfo: "No Information",
      isListCard: true,
    },
  ];
  const engagementList = [
    {
      id: "a1",
      labelTextOneOne: "log",
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: "all",
          textOne: "",
        },
        {
          labelTextOneOneBadge: "key",
          textOne: "",
        },
      ],
      innerAssociateList: [],
      innerInfo: "assessees",
      isListCard: false,
      IconOne: Manuscript,
    },
    {
      id: "a2",
      textOneOne:
        capitalizeFirstLetter(informationEngagement?.assignmentGroupStatus) ||
        "No Information",
      labelTextOneOne: "status",
      innerAssociateList: [],
      innerInfo: "No Information",
      isListCard: false,
    },
    {
      id: "a3",
      labelTextOneOne: "tag",
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: "primary",
          textOne:
            informationEngagement?.assignmentGroupTag
              ?.assignmentGroupTagPrimary || "No Information",
        },
        {
          labelTextOneOneBadge: "secondary",
          textOne:
            informationEngagement?.assignmentGroupTag
              ?.assignmentGroupTagSecondary || "No Information",
        },
      ],
      innerAssociateList: [],
      innerInfo: "No Information",
      isListCard: false,
    },
    {
      id: "a4",
      labelTextOneOne: "tenure",
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: "start",
          textOne:
            informationEngagement?.assignmentGroupTenure
              ?.assignmentGroupTenureDateTimeStart || "No Information",
        },
        {
          labelTextOneOneBadge: "end",
          textOne:
            informationEngagement?.assignmentGroupTenure
              ?.assignmentGroupTenureDateTimeEnd || "No Information",
        },
      ],
      innerAssociateList: [],
      innerInfo: "Assessee",
      isListCard: false,
    },
  ];

  const classificationList = [
    {
      id: "a1",
      textOneOne:
        informationSetup?.assignmentGroupClassification
          ?.assignmentGroupClassificationPrimary || "No Information",
      labelTextOneOne: "classification",
      innerAssociateList: [],
      innerInfo: "No Information",
      isListCard: false,
    },
    {
      id: 'setupList1',
      labelTextOneOne: 'fee',
      labelTextOneOneBadges: 
      [
        {
          labelTextOneOneBadge: 'move',
          textOne: informationSetup?.assignmentGroupMoveFee || 0
        },
        {
          labelTextOneOneBadge: 'sell',
          textOne: informationSetup?.assignmentGroupSellFee || 0
        },
        {
          labelTextOneOneBadge: 'share',
          textOne: informationSetup?.assignmentGroupShareFee ||  0
        }
      ],
      innerInfo: 'No',
      isListCard: false,
      isReviewLink: false,
      isMultiInfoCard: false
    }
  ];

  const reviseAllocation = (e) => {
    const labelName = e.currentTarget.getAttribute("data-value");
    const selectedBadgeName = e.currentTarget.getAttribute("data-key");
    const innerSelectedBadgeName = e.currentTarget.getAttribute("id");
    console.log("=====>", labelName, selectedBadgeName);
    //group click open popup
    if (labelName === "group") {
      if (
        selectedBadgeName === "ascendant" &&
        innerSelectedBadgeName === "primary"
      ) {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: "GROUPLISTPOPUP",
            popupMode: "assignmentsGROUPCREATE",
          },
        });
        dispatch({ type: LOADER_START });
        let requestObj = {
          module: "Assignment-Group",
          action: "Review-List",
          requestObject: makeAssignmentGroupObj(
            selectedAssociateInfo,
            "active",
            0,
            -1,
            'list',
            assignmentCreateType
          ),
        };
        dispatch({
          type: SET_CORE_GROUP_REVIEW_LIST_REQ_OBJECT,
          payload: requestObj,
        });
        dispatch({ type: LOADER_START });
        dispatch({
          type: GET_ASSIGNMENT_GROUP_REVIEW_LIST_SAGA,
          payload: {
            request: requestObj,
            BadgeOne: "",
            BadgeTwo: "",
            BadgeThree: "",
            isMiddlePaneList: false,
          },
        });
      }
    }
    //
    if (labelName === "node") {
      if (selectedBadgeName === "primary") {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: "NODELISTPOPUP",
            popupMode: "assignmentsGROUPCREATE",
          },
        });
      dispatch({ type: LOADER_START });
      let nodeRequestObj = makeInternalNodeObj(selectedAssociateInfo, 'active', 0, -1, '', 'Associate-Self-Node-List-Create');
        dispatch({ type: SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT, payload: nodeRequestObj });
        console.log('nodeRequestObj',nodeRequestObj);
        dispatch({ type: LOADER_START });
        dispatch({
          type: INTERNAL_NODE_LIST_WITH_PERMISSIONS_SAGA,
          payload: {
            request: nodeRequestObj,
            BadgeOne: '',
            BadgeTwo: '',
            BadgeThree: '',
            nodeViewState: 'list',
            isMiddlePaneList: false
          }
        });
      }
      if (selectedBadgeName === "secondary") {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: "NODESECONDARYLISTPOPUP",
            popupMode: "assignmentsGROUPCREATE",
          },
        });
        dispatch({ type: LOADER_START });
        let nodeRequestObj = makeInternalNodeObj(selectedAssociateInfo, 'active', 0, -1, '', 'Associate-Self-Node-List-Create');
          dispatch({ type: SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT, payload: nodeRequestObj });
          console.log('nodeRequestObj',nodeRequestObj);
          dispatch({ type: LOADER_START });
          dispatch({
            type: INTERNAL_NODE_LIST_WITH_PERMISSIONS_SAGA,
            payload: {
              request: nodeRequestObj,
              BadgeOne: '',
              BadgeTwo: '',
              BadgeThree: '',
              nodeViewState: 'list',
              isMiddlePaneList: false
            }
          });
      }
    }
    if (labelName === "type") {
      dispatch({ type: LOADER_START });
      let roleRequestObj = {
        module: 'Assignment-Type',
        action: 'Review-List',
        requestObject: makeAssignmentTypeObj(selectedAssociateInfo, 'active', 0, -1)
      };
      dispatch({ type: SET_CORE_TYPE_REVIEW_LIST_REQ_OBJECT, payload: roleRequestObj });
      dispatch({
        type: GET_ASSIGNMENT_TYPE_REVIEW_LIST_SAGA,
        payload: {
          request: roleRequestObj,
          BadgeOne: '',
          BadgeTwo: '',
          BadgeThree: '',
          isMiddlePaneList: false
        }
      });
      if (selectedBadgeName === "primary") {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: "TYPELISTPOPUP",
            popupMode: "assignmentsGROUPCREATE",
          },
        });
      }
      if (selectedBadgeName === "secondary") {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: "TYPESECONDARYLISTPOPUP",
            popupMode: "assignmentsGROUPCREATE",
          },
        });
      }
    }
  };
  const reviseEngagement = (e) => {
    const labelName = e.currentTarget.getAttribute("data-value");
    const selectedBadgeName = e.currentTarget.getAttribute("data-key");
    console.log("=====>", labelName);
    if (labelName === "status") {
      dispatch({
        type: SET_STATUS_POPUP_VALUE,
        payload: capitalizeFirstLetter(
          informationEngagement?.assessmentGroupStatus
        ),
      });
      dispatch({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: "STATUSPOPUP",
          popupMode: "assignmentsGROUPCREATE",
        },
      });
    }
    if (labelName === "tag") {
      if (selectedBadgeName === "primary") {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: "TAGREADONLYPRIMARYPOPUP",
            popupMode: "assignmentsGROUPCREATE",
          },
        });
      }
      if (selectedBadgeName === "secondary") {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: "TAGSECONDARYPOPUP",
            popupMode: "assignmentsGROUPCREATE",
          },
        });
      }
    }
    if (labelName === "tenure") {
      if (selectedBadgeName === "start") {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: "TENURESATRTDATEPOPUP",
            popupMode: "assignmentsGROUPCREATE",
          },
        });
      }
      if (selectedBadgeName === "end") {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: "TENUREENDDATEPOPUP",
            popupMode: "assignmentsGROUPCREATE",
          },
        });
      }
    }
  };

  const reviseClassification = (e,selectedBadgeArray) => {
    const labelName = e.currentTarget.getAttribute("data-value");
    const selectedBadgeName = e.currentTarget.getAttribute("data-key");
    console.log('=====>', labelName);
    let badgeName = '';
    if (selectedBadgeArray) {
      if (selectedBadgeArray.length > 0) {
        selectedBadgeArray.forEach((element) => {
          badgeName = badgeName + element.labelTextTwoBadge;
        });
      }
    }
    console.log('=====>', badgeName);
    if (labelName === "classification") {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: "CLASSIFICATIONLISTPOPUP",
          popupMode: "assignmentsGROUPCREATE",
        },
      });
    }
    if (labelName === 'fee' && selectedBadgeName === 'move') {
      console.log('open share popup');
      dispatch({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'GROUPSASSOCIATEMOVEFEEPOPUP',
          popupMode: 'assignmentsGROUPCREATE'
        }
      });
    }
    if (labelName === 'fee' && selectedBadgeName === 'sell') {
      console.log('open share popup');
      dispatch({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'GROUPSASSOCIATESELLFEEPOPUP',
          popupMode: 'assignmentsGROUPCREATE'
        }
      });
    }
    if (labelName === 'fee' && selectedBadgeName === 'share') {
      console.log('open share popup');
      dispatch({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'GROUPSASSOCIATESHAREFEEPOPUP',
          popupMode: 'assignmentsGROUPCREATE'
        }
      });
    }
  };

  return (
    <div>
      <>
        <div className="containerPadding">
          <Paper className={"dossierContainerTop"}>
            {allocationList.map((ob) => {
              return (
                <div key={ob.id}>
                  {ob.isListCard ? (
                    <>
                      {ob.isMultiList ? (
                        <DisplayPanelAccordianReviewListTwo
                          onClickReview={null}
                          onClickRevise={reviseAllocation}
                          accordianObject={ob}
                          mode={reviewMode}
                        />
                      ) : (
                        <DisplayPanelAccordianReviewListOne
                          onClickRevise={reviseAllocation}
                          className=""
                          accordianObject={ob}
                          mode={reviewMode}
                        />
                      )}
                    </>
                  ) : (
                    <DisplayPanelAccordianInformation
                      onClickRevise={reviseAllocation}
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  )}
                  {/* {ob.isListCard ? (
                    <DisplayPanelAccordianReviewListOne
                      onClickRevise={reviseAllocation}
                      className=""
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  ) : (
                    <DisplayPanelAccordianInformation
                      onClickRevise={reviseAllocation}
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  )} */}
                </div>
              );
            })}
          </Paper>
        </div>
        <div className="containerPadding">
          <Paper className={"dossierContainerTop"}>
            {engagementList.map((ob) => {
              return (
                <div key={ob.id}>
                  {ob.isListCard ? (
                    <DisplayPanelAccordianReviewListOne
                      onClickRevise={reviseEngagement}
                      className=""
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  ) : (
                    <DisplayPanelAccordianInformation
                      onClickRevise={reviseEngagement}
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  )}
                </div>
              );
            })}
          </Paper>
        </div>

        <div className="containerPadding">
          <Paper className={"dossierContainerTop"}>
            {classificationList.map((ob) => {
              return (
                <div key={ob.id}>
                  {ob.isListCard ? (
                    <DisplayPanelAccordianReviewListOne
                      onClickRevise={reviseClassification}
                      className=""
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  ) : (
                    <DisplayPanelAccordianInformation
                      onClickRevise={reviseClassification}
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  )}
                </div>
              );
            })}
          </Paper>
        </div>
      </>
      {isMobile && (
        <div className={"containerPadding"} style={{ height: "55px" }}>
          {" "}
        </div>
      )}
    </div>
  );
};

export default DisplayPaneThreeSectionOneAssignmentGroup;
