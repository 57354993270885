import React from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import DisplayPanelAccordianReviewListOne from '../Accordian/DisplayPanelAccordianReviewListOne';
import DisplayPanelAccordianInformation from '../Accordian/DisplayPanelAccordianInformation';
import { Paper } from '@material-ui/core';
import {
  FILTERMODE,
  GET_ALLOCATE_ASSESSEE,
  LOADER_START,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_PAGE_COUNT
} from '../../actionType';
import {
  getAssesseeGroupAssesseeDistinctApiCall,
  getAssesseeeDistinctToAllocate
} from '../../Actions/AssesseeModuleAction';
import {
  makeAdministratorsReviewListRequestObject,
  makeAssesseeReviewListRequestObject
} from '../../Actions/GenericActions';

const DisplayPaneThreeSectionTwoAssesseeGroup = () => {
  const dispatch = useDispatch();
  const { reviewMode, relatedReviewListPaneThree, responseObject, headerOne } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  const { relatedReviewListDistinctData } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { selectedAssociateInfo, countPage, reviewListDistinctData, middlePaneHeader } =
    useSelector((state) => state.DisplayPaneTwoReducer);
  let assessee = [];
  if (relatedReviewListPaneThree && relatedReviewListPaneThree.length > 0) {
    assessee = relatedReviewListPaneThree[0].assessee;
  }
  let assesseeArray = [];
  assessee &&
    assessee.forEach((ob) => {
      const { id, informationBasic, informationEngagement, associateId } = ob;
      assesseeArray.push({
        id,
        textOne: `${informationBasic.assesseeNameFirst} ${informationBasic.assesseeNameOther} ${informationBasic.assesseeNameLast}`,
        textTwo: informationBasic.assesseeAlias || '',
        status: informationEngagement?.assesseeStatus,
        isShared: ob?.assesseeShared,
        associateId
      });
    });

  const list2 = [
    {
      id: 'a1',
      labelTextOneOne: headerOne || 'assessees',
      labelTextOneOneBadgeOne: '',
      labelTextOneOneBadgeTwo: '',
      labelTextOneOneBadgeThree: '',
      labelTextOneOneBadgeFour: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'distinct',
          innerList: assesseeArray
        }
      ],
      innerInfo: 'No Information',
      isListCard: true,
      isReviewLink: true
    }
  ];

  const onclickReviewAssessee = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    if (labelName === 'assessees' && selectedBadgeName === 'distinct') {
      getAssesseeGroupAssesseeDistinctApiCall(
        selectedAssociateInfo,
        '',
        countPage,
        dispatch,
        'distinct',
        responseObject.id, //group id
        '',
        false,
        true
      );
    }
    if (labelName === 'administrators' && selectedBadgeName === 'distinct') {
      getAssesseeGroupAssesseeDistinctApiCall(
        selectedAssociateInfo,
        '',
        countPage,
        dispatch,
        'distinct',
        responseObject.id, //group id
        '',
        false,
        true,
        '',
        [],
        middlePaneHeader
      );
    }
    // dispatch({
    //   type: SET_DISPLAY_THREE_SINGLE_STATE,
    //   payload: { stateName: 'createMode', value: '' }
    // });
  };
  const onclickReviseAssessee = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    if (labelName === 'assessees' && selectedBadgeName === 'distinct') {
      let requestObect = makeAssesseeReviewListRequestObject(
        selectedAssociateInfo,
        '',
        //'activeinactive',
        0,
        countPage
      );
      let revisedGroupObject = {
        id: responseObject.id,
        assesseeGroupName: responseObject.informationBasic.assesseeGroupName,
        assesseeGroupDescription: responseObject.informationBasic.assesseeGroupDescription,
        assesseeGroupStatus: responseObject.informationEngagement.assesseeGroupStatus
      };
      let existingAssesseeId = [];
      let tempArr = relatedReviewListPaneThree[0]?.assessee || [];
      existingAssesseeId = tempArr.map((val) => {
        return val.id;
      });
      console.log('relatedReviewListPaneThree', relatedReviewListPaneThree);
      console.log('existingAssesseeId', existingAssesseeId);
      getAssesseeeDistinctToAllocate(
        dispatch,
        revisedGroupObject,
        requestObect,
        existingAssesseeId,
        'assesseeGroupAssesseeRevise',
        'assesseesGroupAssesseeReviewList'
      );
      dispatch({ type: SET_PAGE_COUNT, payload: 0 });
    }
    if (labelName === 'administrators' && selectedBadgeName === 'distinct') {
      let requestObect = makeAdministratorsReviewListRequestObject(
        selectedAssociateInfo,
        'all',
        0,
        countPage
      );
      let revisedGroupObject = {
        id: responseObject.id,
        assesseeGroupName: responseObject.informationBasic.assesseeGroupName,
        assesseeGroupDescription: responseObject.informationBasic.assesseeGroupDescription,
        assesseeGroupStatus: responseObject.informationEngagement.assesseeGroupStatus
      };
      let existingAssesseeId = [];
      let tempArr = relatedReviewListPaneThree[0]?.assessee || [];
      existingAssesseeId = tempArr.map((val) => {
        return val.id;
      });
      console.log('relatedReviewListPaneThree', relatedReviewListPaneThree);
      console.log('existingAssesseeId', existingAssesseeId);
      getAssesseeeDistinctToAllocate(
        dispatch,
        revisedGroupObject,
        requestObect,
        existingAssesseeId,
        'assesseeGroupAssesseeRevise',
        'assesseesGroupAssesseeReviewList',
        [],
        [],
        middlePaneHeader
      );
      dispatch({ type: SET_PAGE_COUNT, payload: 0 });
    }
  };

  return (
    <div>
      <>
        <div className={'containerPadding'}>
          <Paper className={'dossierContainerTop'}>
            {list2.map((ob) => {
              return (
                <div key={ob.id}>
                  {ob.isListCard ? (
                    <DisplayPanelAccordianReviewListOne
                      onClickReview={onclickReviewAssessee}
                      onClickRevise={onclickReviseAssessee}
                      className=""
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  ) : (
                    <DisplayPanelAccordianInformation accordianObject={ob} mode={reviewMode} />
                  )}
                </div>
              );
            })}
          </Paper>
        </div>
      </>
      {isMobile && (
        <div className={'containerPadding'} style={{ height: '55px' }}>
          {' '}
        </div>
      )}
    </div>
  );
};

export default DisplayPaneThreeSectionTwoAssesseeGroup;
