import { put, takeLatest, call, takeEvery } from 'redux-saga/effects';
import {
  ASSESSEE_ASSESSMENT_FINISH_SAGA,
  ASSESSEE_ASSESSMENT_START_SAGA,
  ASSESSMENT_START_SAGA,
  CLEAR_ASSIGNMENT_INFO,
  GET_ASSESSEE_ASSIGNMENT_SAGA,
  LOADER_STOP,
  RELATED_REVIEWLIST_DISTINCT_DATA,
  REVIEWLIST_DISTINCT_DATA,
  SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
  SET_ASSESSEE_ASSESSMENT_ITEM_RES_SAGA,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MIDDLEPANE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_POPUP_VALUE,
  SET_ASSESSEE_ASSESSMENT_ITEM_LAST_ATTEMPT_SAGA,
  SET_ASSESSEE_ASSESSMENT_SECTION_END_SAGA,
  POPUP_CLOSE,
  LOADER_START,
  CLEAR_ASSESSEE_ASSESSMENT_REDUCER_STATE,
  ASSESSEE_SELF_REPORTS,
  GET_ASSESSEE_MANUSCRIPT_VIDEOS_REVIEW_LIST_SAGA
} from '../../actionType';
import {
  ASSESSMENT_START_URL,
  ASSESSEE_ASSESSMENT_START_URL,
  ASSESSMENT_ITEM_REVISE_URL,
  ASSESSEE_ASSESSMENT_FINISH_URL,
  ASSESSMENT_SECTION_END_URL,
  ASSIGNMENT_MODULE_URL,
  ASSESSMENT_START_OLD_URL,
  ASSESSEE_ASSESSMENT_NODE_FINISH_URL,
  ASSESSEE_MODULE_URL,
  PLATFORM_MODULE_URL
} from '../../endpoints';
import { EXCEPTION_ERROR_MESSAGE } from '../../errorMessage';
import store from '../../store';
import Store from '../../store';

const apiCallFun = async (requestObj) => {
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      Authorization: localStorage.getItem('token')
    }),
    body: JSON.stringify(requestObj.data)
  };
  const response = await fetch(requestObj.URL, requestOptions);
  const json = await response.json();
  return json;
};

const apiFinishFun = async (requestObj) => {
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      Authorization: localStorage.getItem('idToken').replace('Bearer ', '')
    }),
    body: JSON.stringify(requestObj.data)
  };
  const response = await fetch(requestObj.URL, requestOptions);
  const json = await response.json();
  return json;
};

const apiFun = async (requestObj) => {
  const requestOptions = {
    method: 'GET',
    headers: new Headers({
      Authorization: localStorage.getItem('token'),
      userId: requestObj.assesseeId
    })
  };
  console.log('URL', requestObj.URL);
  const response = await fetch(requestObj.URL, requestOptions);
  const json = await response.json();
  return json;
};
function* workerAssesseeAssignmentListSaga(data) {
  try {
    const response = yield call(apiCallFun, {
      data: data.payload.request,
      URL: ASSIGNMENT_MODULE_URL,
      type: ''
    });
    if (response.responseCode === '000') {
      console.log('payload', data.payload);
      const {
        relatedListCall = '',
        BadgeOne,
        BadgeTwo,
        responseObj,
        typeOfMiddlePaneList = ''
      } = data.payload;
      if (relatedListCall !== '') {
        console.log('scanCount--------------', Store.getState().DisplayPaneTwoReducer.scanCount);
        console.log(
          'duplicateScanCountOne',
          Store.getState().DisplayPaneTwoReducer.duplicateScanCountOne
        );
        console.log(
          'duplicateScanCount',
          Store.getState().DisplayPaneTwoReducer.duplicateScanCount
        );
        let assignmentSingleStatus = {
          suspended: 'status',
          terminated: 'status',
          started: 'status',
          unstarted: 'status',
          aborted: 'status',
          finished: 'status',
          unfinished: 'status'
        };
        let duplicateScanCount = assignmentSingleStatus.hasOwnProperty(BadgeTwo)
          ? Store.getState().DisplayPaneTwoReducer.duplicateScanCount
          : Store.getState().DisplayPaneTwoReducer.scanCount;
        let paneHeaderDuplicateBadgeOne = assignmentSingleStatus.hasOwnProperty(BadgeTwo)
          ? Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne
          : Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne;
        console.log(
          'true-----',
          Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne
        );
        console.log('false', Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne);
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: 'assignments',
            middlePaneHeaderBadgeOne: BadgeOne,
            middlePaneHeaderBadgeTwo: BadgeTwo,
            middlePaneHeaderBadgeThree: '',
            middlePaneHeaderBadgeFour: '',
            middlePaneHeaderDuplicate:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
            middlePaneHeaderDuplicateBadgeOne: paneHeaderDuplicateBadgeOne,
            middlePaneHeaderDuplicateBadgeTwo:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            middlePaneHeaderDuplicateBadgeThree:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
            middlePaneHeaderDuplicateBadgeFour: '',
            middlePaneHeaderDuplicateOne:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOne,
            middlePaneHeaderDuplicateOneBadgeOne:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeOne,
            middlePaneHeaderDuplicateOneBadgeTwo:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeTwo,
            middlePaneHeaderDuplicateOneBadgeThree:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeThree,
            middlePaneHeaderDuplicateOneBadgeFour:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeFour,
            typeOfMiddlePaneList: typeOfMiddlePaneList,
            scanCount: response && response.countTotal,
            duplicateScanCount: duplicateScanCount,
            duplicateScanCountOne: Store.getState().DisplayPaneTwoReducer.duplicateScanCountOne,
            isSelectActive: data.payload.isSelectActive,
            selectedTagsArray: data.payload.selectedTagsArray || [],
            unselectedTagsArray: data.payload.unselectedTagsArray || [],
            showMiddlePaneState: true,
            isListLoading: false
          }
        });
        response.responseObject?.sort((a, b) =>
          a.assesseeAssignmentName.localeCompare(b.assesseeAssignmentName)
        );
        yield put({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
        //yield put({ type: REVIEWLIST_DISTINCT_DATA, payload: response.responseObject });
        yield put({
          type: RELATED_REVIEWLIST_DISTINCT_DATA,
          payload: [
            {
              ...responseObj,
              assesseeAssignment: response.responseObject
            }
          ]
        });
      } else {
        if (data.payload.assessmentStarted) {
          console.log('response.responseObject ', response.responseObject);
          console.log('data.payload', data.payload);
          yield put({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
          // debugger;
          let assessmentList = response.responseObject.filter((list) => {
            return list.assesseeAssignmentId === data.payload.assignmentId;
          });
          yield put({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: { stateName: 'isExamMode', value: false }
          });
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'middlePaneSelectedValue', value: '' }
          });

          yield put({
            type: RELATED_REVIEWLIST_DISTINCT_DATA,
            payload: assessmentList
          });
          if (assessmentList.length > 0) {
            assessmentList[0].assesseeAssignmentAssessmentDistinct?.sort((a, b) =>
              a.assesseeAssignmentAssessmentName.localeCompare(b.assesseeAssignmentAssessmentName)
            );
            // debugger
            yield put({
              type: SET_MIDDLEPANE_STATE,
              payload: {
                middlePaneHeader: 'assessments',
                middlePaneHeaderBadgeOne: 'active',
                middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
                middlePaneHeaderBadgeThree: '',
                middlePaneHeaderBadgeFour: '',
                typeOfMiddlePaneList: 'assesseesAssginmentAssessmentReviewList',
                scanCount: assessmentList[0].assesseeAssignmentAssessmentDistinct.length,
                middlePaneHeaderDuplicate:
                  Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
                middlePaneHeaderDuplicateBadgeOne:
                  Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
                middlePaneHeaderDuplicateBadgeTwo: '',
                middlePaneHeaderDuplicateBadgeThree: '',
                middlePaneHeaderDuplicateBadgeFour: '',
                showMiddlePaneState: true,
                isListLoading: false
              }
            });
            yield put({
              type: SET_DISPLAY_TWO_SINGLE_STATE,
              payload: { stateName: 'reviewListDistinctData', value: response.responseObject }
            });
          } else {
            yield put({
              type: SET_MIDDLEPANE_STATE,
              payload: {
                middlePaneHeader: 'assignments',
                middlePaneHeaderBadgeOne: data.payload.BadgeOne,
                middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
                middlePaneHeaderBadgeThree: '',
                middlePaneHeaderBadgeFour: '',
                typeOfMiddlePaneList: 'assesseeAssignmentDistinctReviewList',
                scanCount: response && response.countTotal,
                showMiddlePaneState: true,
                isListLoading: false
              }
            });
            yield put({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
            yield put({
              type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
              payload: { stateName: 'isExamMode', value: false }
            });
            yield put({ type: REVIEWLIST_DISTINCT_DATA, payload: response.responseObject });
            yield put({ type: CLEAR_ASSIGNMENT_INFO });
          }
        } else {
          yield put({
            type: SET_MIDDLEPANE_STATE,
            payload: {
              middlePaneHeader: 'assignments',
              middlePaneHeaderBadgeOne: data.payload.BadgeOne,
              middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
              middlePaneHeaderBadgeThree: '',
              middlePaneHeaderBadgeFour: '',
              typeOfMiddlePaneList: 'assesseeAssignmentDistinctReviewList',
              scanCount: response && response.countTotal,
              middlePaneHeaderDuplicate: 'assignments',
              middlePaneHeaderDuplicateBadgeOne: data.payload.BadgeOne,
              middlePaneHeaderDuplicateBadgeTwo: data.payload.BadgeTwo,
              middlePaneHeaderDuplicateBadgeThree: '',
              middlePaneHeaderDuplicateBadgeFour: '',
              duplicateScanCount: response && response.countTotal,
              showMiddlePaneState: true,
              isListLoading: false
            }
          });
          yield put({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
          yield put({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: { stateName: 'isExamMode', value: false }
          });
          yield put({ type: REVIEWLIST_DISTINCT_DATA, payload: response.responseObject });
          yield put({ type: CLEAR_ASSIGNMENT_INFO });
        }
      }
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: response.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerAssessmentStartSaga(data) {
  try {
    let response;
    console.log('process.env.REACT_APP_ENVIRONMENT', process.env.REACT_APP_ENVIRONMENT);
    if (
      data.payload.assesseeAssignmentAssessmentItemAttemptedLast === null &&
      process.env.REACT_APP_ENVIRONMENT !== 'dev'
    ) {
      response = yield call(apiFun, {
        data: data.payload.request,
        URL:
          ASSESSMENT_START_URL +
          data.payload.request.associateId +
          '/' +
          data.payload.request.assessmentId +
          '/' +
          data.payload.request.assignmentId,
        assesseeId: data.payload.request.assesseeId
      });
    } else {
      //  {assesseeId}/{associateId}/{assessmentId}/{assignmentId}
      response = yield call(apiCallFun, {
        data: data.payload.request,
        URL: ASSESSMENT_START_OLD_URL,
        type: ''
      });
    }

    // const response ={responseCode:'000',countTotal:30}
    if (response.responseCode === '000') {
      let sequenceArr = [];
      let informationFramework = response.responseObject?.informationFramework;
      let assessmentAdministrationSequence =
        response.responseObject?.informationFramework?.assessmentAdministrationSequence;
      let LastAttempted =
        response?.responseObject?.assesseeAssignmentAssessmentItemAttemptedLast?.split(':');
      if (assessmentAdministrationSequence.length > 0) {
        for (let i = 0; i < assessmentAdministrationSequence.length; i++) {
          let currentValue = informationFramework?.assessmentAdministrationSequence[i];
          let lastIndexValue = parseInt(currentValue.substring(currentValue.length - 1));
          let matchValue = currentValue;
          matchValue = matchValue.substring(0, matchValue.length - 1);
          if (matchValue.trim() === 'assessment communiqué') {
            sequenceArr.push({
              name: matchValue.trim(),
              value: informationFramework?.assessmentCommunique[lastIndexValue - 1],
              originalValue: currentValue
            });
          } else if (matchValue.trim() === 'assessment synopsis') {
            sequenceArr.push({
              name: matchValue.trim(),
              value: informationFramework?.assessmentSynopsis[lastIndexValue - 1],
              originalValue: currentValue
            });
          } else if (matchValue.trim() === 'assessment manuscript') {
            sequenceArr.push({
              name: matchValue.trim(),
              value: informationFramework?.assessmentManuscript[lastIndexValue - 1],
              originalValue: currentValue
            });
          } else if (matchValue.trim() === 'assessment section') {
            let assessmentSectionFramework = informationFramework?.assessmentSectionFramework || [];
            let sectionObj = assessmentSectionFramework[lastIndexValue - 1];
            if (sectionObj?.assessmentSectionAdministrationSequence.length > 0) {
              for (let j = 0; j < sectionObj?.assessmentSectionAdministrationSequence.length; j++) {
                let currentSectionSequenceValue =
                  sectionObj?.assessmentSectionAdministrationSequence[j];
                let lastIndexValue = parseInt(
                  currentSectionSequenceValue.substring(currentSectionSequenceValue.length - 1)
                );
                let matchValue = currentSectionSequenceValue;
                matchValue = matchValue.substring(0, matchValue.length - 1);
                if (matchValue.trim() === 'section communiqué') {
                  sequenceArr.push({
                    name: matchValue.trim(),
                    value: sectionObj?.assessmentSectionCommunique[lastIndexValue - 1],
                    originalValue: currentSectionSequenceValue
                  });
                } else if (matchValue.trim() === 'section synopsis') {
                  sequenceArr.push({
                    name: matchValue.trim(),
                    value: sectionObj?.assessmentSectionSynopsis[lastIndexValue - 1],
                    originalValue: currentSectionSequenceValue
                  });
                } else if (matchValue.trim() === 'section manuscript') {
                  sequenceArr.push({
                    name: matchValue.trim(),
                    value: sectionObj?.assessmentSectionManuscript[lastIndexValue - 1],
                    originalValue: currentSectionSequenceValue
                  });
                } else if (currentSectionSequenceValue.trim() === 'item distinct') {
                  sequenceArr.push({
                    name: currentSectionSequenceValue.trim(),
                    value: sectionObj?.assessmentSectionItemDistinct,
                    originalValue: currentValue
                  });
                }
              }
            } else {
              sequenceArr.push({
                name: matchValue.trim(),
                value: sectionObj?.assessmentSectionItemDistinct,
                originalValue: currentValue
              });
            }
          } else if (currentValue.trim() === 'item trial') {
            sequenceArr.push({
              name: currentValue.trim(),
              value: informationFramework?.assessmentItemTrial,
              originalValue: currentValue.trim()
            });
          }
        }
        // console.log(sequenceArr);
        yield put({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: { stateName: 'assessmentsequenceObject', value: sequenceArr }
        });

        localStorage.setItem('assessmentItem', '[]');
        localStorage.setItem('navigationItem', '[]');
        console.log('LastAttempted', LastAttempted);
        let indexValue = 0;
        if (LastAttempted?.length > 0) {
          let assessmentCommunique = [];
          let assessmentMenuscript = [];
          let assessmentSynopsis = [];
          indexValue = sequenceArr.findIndex((obj) => obj.originalValue === LastAttempted[0]);
          sequenceArr.forEach((element, index) => {
            if (index < indexValue) {
              if (element.name === 'assessment communiqué')
                assessmentCommunique.push(element.value);
              if (element.name === 'assessment synopsis') assessmentSynopsis.push(element.value);
              if (element.name === 'assessment manuscript')
                assessmentMenuscript.push(element.value);
            }
          });
          yield put({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: { stateName: 'assessmentCommunique', value: assessmentCommunique }
          });
          yield put({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: {
              stateName: 'assessmentMenuscript',
              value: assessmentMenuscript
            }
          });
          yield put({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: {
              stateName: 'assessmentSynopsis',
              value: assessmentSynopsis
            }
          });
        }
        console.log('lastAttempted Value', indexValue);
        yield put({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: { stateName: 'currentSequenceIndex', value: indexValue }
        });
      }
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'typeOfMiddlePaneList', value: 'acutalAssessmentStart' }
      });
      yield put({
        type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
        payload: { stateName: 'assesseeAssignmentAssessmentData', value: response.responseObject }
      });
      yield put({
        type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
        payload: {
          stateName: 'apiResponseData',
          value: JSON.parse(JSON.stringify(response.responseObject))
        }
      });
      yield put({
        type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
        payload: { stateName: 'isExamMode', value: true }
      });
      yield put({
        type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
        payload: { stateName: 'isAssessmentStart', value: 'START' }
      });
      yield put({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneSix' });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: response.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerAssesseeAssessmentStartSaga(data) {
  try {
    const response = yield call(apiCallFun, {
      data: data.payload.request,
      URL: ASSESSEE_ASSESSMENT_START_URL,
      type: ''
    });
    // if (response.responseCode === '000') {
    //   yield put({
    //     type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
    //     payload: { stateName: 'assesseeAssessmentStartData', value: response.responseObject }
    //   });
    //   yield put({
    //     type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
    //     payload: { stateName: 'isAssessmentStart', value: 'PROGRESS' }
    //   });

    //   yield put({
    //     type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
    //     payload: { stateName: 'asssignmentStarted', value: 'PROGRESS' }
    //   });

    //   // yield put({
    //   //   type: SET_ASSESSEE_ASSESSMENT_ISASSESSMENTSTART_STATE,
    //   //   payload: 'PROGRESS'
    //   // });
    //   // yield put({
    //   //   type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
    //   //   payload: { stateName: 'isExamMode', value: false }
    //   // });
    //   yield put({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneSix' });
    // }
    const {
      isAssignmentStart = false,
      popupHeaderOneBadgeTwo,
      assessmentList,
      selectedTagValue
    } = data.payload;
    if (response.responseCode === '000') {
      if (isAssignmentStart) {
        if (popupHeaderOneBadgeTwo === 'resume') {
          if (assessmentList.length > 0) {
            let assignmentCommunique = assessmentList[0]?.assignmentCommunique || [];
            let assignmentSynopsis = assessmentList[0]?.assignmentSynopsis || [];
            let assignmentManuscript = assessmentList[0]?.assignmentManuscript || [];
            yield put({
              type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
              payload: { stateName: 'assignmentCommunique', value: assignmentCommunique }
            });
            yield put({
              type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
              payload: { stateName: 'assignmentManuscript', value: assignmentManuscript }
            });
            yield put({
              type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
              payload: { stateName: 'assignmentSynopsis', value: assignmentSynopsis }
            });
          }
          yield put({
            type: SET_MIDDLEPANE_STATE,
            payload: {
              middlePaneHeader: 'assessments',
              middlePaneHeaderBadgeOne: '',
              middlePaneHeaderBadgeTwo: '',
              middlePaneHeaderBadgeThree: '',
              middlePaneHeaderBadgeFour: '',
              typeOfMiddlePaneList: 'assesseesAssginmentAssessmentReviewList',
              middlePaneHeaderDuplicate:
                store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
              middlePaneHeaderDuplicateBadgeOne:
                store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
              middlePaneHeaderDuplicateBadgeTwo: '',
              middlePaneHeaderDuplicateBadgeThree: '',
              middlePaneHeaderDuplicateBadgeFour: '',
              scanCount: assessmentList[0].assesseeAssignmentAssessmentDistinct.length,
              middlePaneSelectedValue: selectedTagValue,
              showMiddlePaneState: true
            }
          });
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'indexPointer', value: 0 }
          });
          yield put({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
          yield put({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: { stateName: 'isAssessmentStart', value: 'ReviewListResume' }
          });
        }
        if (popupHeaderOneBadgeTwo === 'start') {
          yield put({
            type: SET_MIDDLEPANE_STATE,
            payload: {
              middlePaneHeader: 'assessments',
              middlePaneHeaderBadgeOne: 'active',
              middlePaneHeaderBadgeTwo: '',
              middlePaneHeaderBadgeThree: '',
              middlePaneHeaderBadgeFour: '',
              typeOfMiddlePaneList: 'assesseesAssginmentAssessmentReviewList',
              middlePaneHeaderDuplicate:
                store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
              middlePaneHeaderDuplicateBadgeOne:
                store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
              middlePaneHeaderDuplicateBadgeTwo: '',
              middlePaneHeaderDuplicateBadgeThree: '',
              middlePaneHeaderDuplicateBadgeFour: '',
              scanCount: assessmentList[0].assesseeAssignmentAssessmentDistinct.length,
              showMiddlePaneState: true,
              middlePaneSelectedValue:
                store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
            }
          });
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'indexPointer', value: 0 }
          });
          if (assessmentList[0].assignmentAdministrationSequence[0] === 'assignment assessment') {
            //assessmentList[0].assignmentAdministrationSequence[0]==='assignment assessment'
            yield put({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
          } else {
            //yield put({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
            yield put({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneSix' });
          }
          yield put({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: { stateName: 'isAssessmentStart', value: 'ReviewListStart' }
          });
        }
      }
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: response.responseMessage, popupMode: 'responseErrorMsg' }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'relatedReviewListDistinctData', value: [] }
      });
    }
    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerAssesseeAssessmentItemFinishSaga(data) {
  try {
    const response = yield call(apiCallFun, {
      data: data.payload.request,
      URL: ASSESSMENT_ITEM_REVISE_URL,
      type: ''
    });
    // const response ={responseCode:'000',countTotal:30}
    if (response.responseCode === '000') {
    } else {
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'errorResponseCode', value: response.responseCode }
      });
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: response.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    console.log('loading end');
    //yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerAssesseeAssessmentFinishSaga(data) {
  try {
    // const response = yield call(apiCallFun, {
    //   data: data.payload.request,
    //   URL: ASSESSEE_ASSESSMENT_FINISH_URL,
    //   type: ''
    // });
    const userResponse = yield call(apiFinishFun, {
      data: data.payload.request,
      URL:
        process.env.REACT_APP_ENVIRONMENT === 'dev'
          ? ASSESSEE_ASSESSMENT_FINISH_URL
          : ASSESSEE_ASSESSMENT_NODE_FINISH_URL,
      type: ''
    });
    let response = JSON.parse(userResponse.body);
    console.log('JSON.parse response', response);
    if (response.responseCode === '000' || response.responseCode === '200') {
      yield put({ type: LOADER_STOP });
      let currentSequenceIndex =
        store.getState().AssesseeAssignmentAssessmentReducer.currentSequenceIndex;
      let assessmentsequenceObject =
        store.getState().AssesseeAssignmentAssessmentReducer.assessmentsequenceObject;
      if (assessmentsequenceObject.length === currentSequenceIndex + 1) {
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
        yield put({ type: CLEAR_ASSESSEE_ASSESSMENT_REDUCER_STATE });
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'reviewListDistinctData', value: [] }
        });
        //
        yield put({
          type: GET_ASSESSEE_ASSIGNMENT_SAGA,
          payload: {
            request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
            BadgeOne: 'active',
            BadgeTwo: '',
            BadgeThree: '',
            assessmentStarted: true,
            assignmentId: data.payload.request.assignmentId
          }
        });
        yield put({ type: POPUP_CLOSE });
      } else {
        yield put({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneSix' });
        yield put({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: {
            stateName: 'currentSequenceIndex',
            value: currentSequenceIndex + 1
          }
        });
        yield put({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: { stateName: 'isAssessmentStart', value: 'FINISH' }
        });
        yield put({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: { stateName: 'assesseeAssessmentStartData', value: null }
        });
        yield put({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: { stateName: 'assesseeAssignmentAssessmentData', value: null }
        });
        localStorage.setItem('assessmentItem', '[]');
        localStorage.setItem('navigationItem', '[]');
      }
      if (data.payload.request.assesseeAssignmentAssessmentStatus === 'TERMINATED') {
        yield put({
          type: GET_ASSESSEE_ASSIGNMENT_SAGA,
          payload: {
            request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
            BadgeOne: 'active',
            BadgeTwo: '',
            BadgeThree: '',
            assessmentStarted: true,
            //assignmentId: Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData[0].assignmentId,
            assignmentId: data.payload.request.assignmentId
          }
        });
      }
      yield put({ type: POPUP_CLOSE });

      yield put({ type: LOADER_STOP });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: response.responseMessage, popupMode: 'responseErrorMsg' }
      });
      yield put({ type: LOADER_STOP });
    }
    console.log('loading end');
    //yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerAssesseeAssessmentLastAttemptItem(data) {
  try {
    const response = yield call(apiCallFun, {
      data: data.payload.request,
      URL: ASSIGNMENT_MODULE_URL,
      type: ''
    });
    if (response.responseCode === '000') {
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'copyResponseCode', value: response.responseCode }
      });
      console.log('code...', Store.getState().DisplayPaneTwoReducer.errorResponseCode);
    } else {
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'errorResponseCode', value: response.responseCode }
      });
      console.log('code...', Store.getState().DisplayPaneTwoReducer.errorResponseCode);
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: response.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }

    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerAssesseeAssessmentSectionEndSaga(data) {
  try {
    const response = yield call(apiCallFun, {
      data: data.payload.request,
      URL: ASSESSMENT_SECTION_END_URL,
      type: ''
    });
    if (response.responseCode === '000') {
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: response.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    console.log('loading end');
    //yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}

function* workerAssesseeSelfReports(data) {
  try {
    const response = yield call(apiCallFun, {
      data: data.payload.request,
      URL: ASSIGNMENT_MODULE_URL,
      type: ''
    });

    if (response.responseCode === '000') {
      yield put({
        type: SET_MIDDLEPANE_STATE,
        payload: {
          middlePaneHeader: data.payload.middlePaneHeader,
          middlePaneHeaderBadgeOne: 'distinct',
          middlePaneHeaderBadgeTwo: data.payload.middlePaneHeaderBadgeTwo,
          middlePaneHeaderBadgeThree: '',
          middlePaneHeaderBadgeFour: '',
          middlePaneHeaderDuplicate:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
          middlePaneHeaderDuplicateBadgeOne:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
          middlePaneHeaderDuplicateBadgeTwo:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
          middlePaneHeaderDuplicateBadgeThree:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree, //
          middlePaneHeaderDuplicateBadgeFour:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour, //
          middlePaneHeaderDuplicateOne:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOne,
          middlePaneHeaderDuplicateOneBadgeOne:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeOne,
          middlePaneHeaderDuplicateOneBadgeTwo:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeTwo,
          middlePaneHeaderDuplicateOneBadgeThree:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeThree,
          middlePaneHeaderDuplicateOneBadgeFour:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeFour,
          typeOfMiddlePaneList: data.payload.typeOfMiddlePaneList,
          scanCount: response.countTotal,
          duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
          showMiddlePaneState: true
        }
      });

      yield put({
        type: REVIEWLIST_DISTINCT_DATA,
        payload: response.responseObject
      });
      yield put({ type: LOADER_STOP });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: response.responseMessage, popupMode: 'responseErrorMsg' }
      });
      yield put({ type: LOADER_STOP });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}

function* workerAssesseeManuscriptVideosSaga(data) {
  try {
    const userResponse = yield call(apiCallFun, {
      data: data.payload.request,
      URL: PLATFORM_MODULE_URL,
      type: ''
    });
    if (userResponse.responseCode === '000') {
      const { HeaderOne, BadgeOne, BadgeTwo, BadgeThree, listType } = data.payload;
      yield put({
        type: SET_MIDDLEPANE_STATE,
        payload: {
          middlePaneHeader: HeaderOne,
          middlePaneHeaderBadgeOne: BadgeOne,
          middlePaneHeaderBadgeTwo: BadgeTwo,
          middlePaneHeaderBadgeThree: BadgeThree,
          middlePaneHeaderBadgeFour: '',
          middlePaneHeaderDuplicate: HeaderOne,
          middlePaneHeaderDuplicateBadgeOne: BadgeOne,
          middlePaneHeaderDuplicateBadgeTwo: BadgeTwo,
          middlePaneHeaderDuplicateBadgeThree: BadgeThree,
          middlePaneHeaderDuplicateBadgeFour: '',
          middlePaneHeaderDuplicateOne: HeaderOne,
          middlePaneHeaderDuplicateOneBadgeOne: BadgeOne,
          middlePaneHeaderDuplicateOneBadgeTwo: BadgeTwo,
          middlePaneHeaderDuplicateOneBadgeThree: BadgeThree,
          middlePaneHeaderDuplicateOneBadgeFour: '',
          typeOfMiddlePaneList:
            listType === 'video'
              ? 'assesseeDistinctVideosReviewList'
              : 'assesseeDistinctManuscriptReviewList',
          scanCount: userResponse && userResponse.countTotal,
          duplicateScanCount: userResponse && userResponse.countTotal,
          showMiddlePaneState: true,
          isSelectActive: data.payload.isSelectActive,
          selectedTagsArray: data.payload.selectedTagsArray || [],
          unselectedTagsArray: data.payload.unselectedTagsArray || [],
          isListLoading: false
        }
      });
      yield put({
        type: REVIEWLIST_DISTINCT_DATA,
        payload: userResponse.responseObject
      });
      yield put({ type: LOADER_STOP });
    } else {
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}

export default function* watchAssesseeSelfSaga() {
  yield takeLatest(GET_ASSESSEE_ASSIGNMENT_SAGA, workerAssesseeAssignmentListSaga);
  yield takeLatest(ASSESSMENT_START_SAGA, workerAssessmentStartSaga);
  yield takeLatest(ASSESSEE_ASSESSMENT_START_SAGA, workerAssesseeAssessmentStartSaga);
  yield takeEvery(SET_ASSESSEE_ASSESSMENT_ITEM_RES_SAGA, workerAssesseeAssessmentItemFinishSaga);
  yield takeLatest(ASSESSEE_ASSESSMENT_FINISH_SAGA, workerAssesseeAssessmentFinishSaga);
  yield takeEvery(
    SET_ASSESSEE_ASSESSMENT_ITEM_LAST_ATTEMPT_SAGA,
    workerAssesseeAssessmentLastAttemptItem
  );
  yield takeLatest(
    SET_ASSESSEE_ASSESSMENT_SECTION_END_SAGA,
    workerAssesseeAssessmentSectionEndSaga
  );
  yield takeLatest(ASSESSEE_SELF_REPORTS, workerAssesseeSelfReports);
  yield takeLatest(
    GET_ASSESSEE_MANUSCRIPT_VIDEOS_REVIEW_LIST_SAGA,
    workerAssesseeManuscriptVideosSaga
  );
}
