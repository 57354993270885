import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CLEAR_DISPLAY_PANE_THREE,
  FILTERMODE,
  FILTERMODE_ENABLE,
  ITEM_ALLOCATE_ASSESSMENT_SAGA,
  ITEM_ALLOCATE_SAGA,
  LOADER_START,
  POPUP_OPEN,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_POPUP_STATE
} from '../actionType';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import { FilterList } from '@material-ui/icons';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import { BASIC_PUBLISH_REVIEW_LIST_NOT_ITERBLE_POPUP } from '../PopUpConfig';
import {
  apiCallForItemDistinctPagination,
  getItemsDistinctApiCall
} from '../Actions/ItemModuleAction';
import {
  onClickCheckBoxSelection,
  onClickFlagSelection,
  setFlagedArray
} from '../Actions/AssesseeModuleAction';
import { getPopUpOptionList } from '../Actions/GenericActions';
import ReviseIcon from '@material-ui/icons/RadioButtonChecked';
import Check from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import ListLoader from '../Atoms/ListLoader/ListLoader';

const MarketplaceItemReviewList = (props) => {
  const dispatch = useDispatch();
  const {
    numberPage,
    scanCount,
    countPage,
    reviewListDistinctData,
    reviewListReqObj,
    middlePaneSelectedValue,
    selectedAssociateInfo,
    middlePaneHeader,
    middlePaneHeaderBadgeOne,
    middlePaneHeaderBadgeTwo,
    isSelectActive,
    selectedTagsArray,
    unselectedTagsArray,
    allocatedTagsArray,
    allocateStr,
    selectedFlagedArray,
    unselectedFlagedArray,
    flagedValue,
    signedAssesseePermission,
    isListLoading
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { FilterModeEnable, FilterMode } = useSelector((state) => state.FilterReducer);
  // const [isFetching, setIsFetching] = useState(false);
  // useEffect(() => {
  //   document.getElementById('middleComponentId').addEventListener('scroll', handleScroll);
  // }, []);
  // const handleScroll = (event) => {
  //   var targetPt = event.target;
  //   if (
  //     Math.ceil(targetPt.scrollHeight - targetPt.scrollTop) !== targetPt.clientHeight ||
  //     isFetching
  //   )
  //     return;
  //   setIsFetching(true);
  //   console.log(isFetching);
  // };
  const fetchData = async () => {

    if (reviewListDistinctData.length < scanCount) {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isListLoading', value: true }
      })
      apiCallForItemDistinctPagination(
        dispatch,
        reviewListReqObj,
        numberPage,
        middlePaneHeader,
        middlePaneHeaderBadgeOne,
        middlePaneHeaderBadgeTwo,
        isSelectActive,
        selectedTagsArray,
        unselectedTagsArray,
        'Shared-Item-List'
      );
    }
  };
  // useEffect(() => {
  //   if (!isFetching) return;
  //   fetchMoreListItems();
  // }, [isFetching]);

  // const fetchMoreListItems = () => {
  //   fetchData();
  //   setIsFetching(false);
  // };
  useEffect(() => {
    setFlagedArray(reviewListDistinctData, 'itemFlag', dispatch);
  }, [reviewListDistinctData]);
  const siftApiCall = (siftKey) => {
    // getItemsDistinctApiCall(selectedAssociateInfo, siftKey, countPage, 'items', dispatch,isSelectActive,false,
    // selectedTagsArray);
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'typeOfMiddlePaneList', value: '' }
    });
    getItemsDistinctApiCall(
      selectedAssociateInfo,
      siftKey,
      countPage,
      'items',
      dispatch,
      isSelectActive,
      false,
      selectedTagsArray
    );
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
    dispatch({ type: FILTERMODE_ENABLE });
    document.getElementById('middleComponentId').scrollTop = '0px';
  };
  const onClickFooter = (e) => {
    let siftValue = e.currentTarget.getAttribute('data-value');
    if (siftValue === 'suspended' || siftValue === 'terminated' || siftValue === 'unpublished')
      siftApiCall(siftValue);
    dispatch({ type: FILTERMODE_ENABLE });
    if (siftValue === 'finish') {
      console.log('allocateStr', allocateStr);
      let distinctAllocateStr =
        allocateStr === 'assessmentsdistinct'
          ? 'assessmentDistinct'
          : allocateStr === 'assessmentsgroups'
            ? 'assessmentGroup'
            : allocateStr === 'assessmentstypes'
              ? 'assessmentType'
              : allocateStr === 'assessmentsnodes'
                ? 'assessmentNode'
                : allocateStr === 'assignmentsdistinct'
                  ? 'assignmentDistinct'
                  : allocateStr === 'assignmentsgroups'
                    ? 'assignmentGroup'
                    : allocateStr === 'assignmentstypes'
                      ? 'assignmentType'
                      : allocateStr === 'itemsdistinct'
                        ? 'itemDistinct'
                        : allocateStr === 'itemsgroups'
                          ? 'itemGroup'
                          : allocateStr === 'itemsnodes'
                            ? 'itemNode'
                            : allocateStr === 'itemstypes'
                              ? 'itemType'
                              : allocateStr === 'assesseesdistinct'
                                ? 'assesseeDistinct'
                                : allocateStr === 'assesseesgroups'
                                  ? 'assesseeGroup'
                                  : allocateStr === 'assesseestypes'
                                    ? 'assesseeType'
                                    : allocateStr === 'assesseesroles'
                                      ? 'assesseeRole'
                                      : allocateStr === 'assesseesnodes'
                                        ? 'assesseeNode'
                                        : '';
      console.log('allocateStr', allocateStr);
      if (distinctAllocateStr !== '' && selectedTagsArray.length !== 0) {
        if (
          distinctAllocateStr === 'assessmentDistinct' ||
          distinctAllocateStr === 'assessmentGroup' ||
          distinctAllocateStr === 'assessmentType' ||
          distinctAllocateStr === 'assessmentNode'
        ) {
          let request = {
            module: 'Item-Distinct',
            action: FilterMode.toLowerCase().includes('unallocate') ? 'Unallocate' : 'Allocate',
            actionFilter: 'Allocate-Item-In-Assessment',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              itemDistinctAllocate: { itemDistinct: selectedTagsArray },
              itemDistinctAllocateInformation: {
                [distinctAllocateStr === 'assessmentDistinct' ? 'assessment' : distinctAllocateStr]: allocatedTagsArray
              }
            }
          };
          dispatch({ type: LOADER_START });
          dispatch({ type: ITEM_ALLOCATE_ASSESSMENT_SAGA, payload: { request: request } });
        }
        if (
          distinctAllocateStr === 'itemDistinct' ||
          distinctAllocateStr === 'itemGroup' ||
          distinctAllocateStr === 'itemNode' ||
          distinctAllocateStr === 'itemType'
        ) {
          let request = {
            module: 'Item-Distinct',
            action: FilterMode.toLowerCase().includes('unallocate') ? 'Unallocate' : 'Allocate',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              itemDistinctAllocate: {
                [distinctAllocateStr]: allocatedTagsArray
              },
              itemDistinctAllocateInformation: {
                itemDistinct: selectedTagsArray
              }
            }
          };
          dispatch({ type: LOADER_START });
          dispatch({ type: ITEM_ALLOCATE_SAGA, payload: { request: request } });
          // dispatch({
          //   type: FILTERMODE,
          //   payload: { FilterMode: '' }
          // });
        }
        console.log('allocateStrMultiple', allocateStr);
      }
    }
    if (siftValue === 'cancel') {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isSelectActive', value: '' }
      });
      dispatch({
        type: FILTERMODE,
        payload: { FilterMode: '' }
      });
    }
  };
  /* for middle pane */
  const primaryIcon = [{ label: 'sift', onClick: onClickFooter, Icon: FilterList }];
  const secondaryIcon = [
    { label: 'suspended', onClick: onClickFooter, Icon: FilterList },
    { label: 'terminated', onClick: onClickFooter, Icon: FilterList },
    { label: 'unpublished', onClick: onClickFooter, Icon: FilterList }
  ];
  const openListPopup = (e) => {
    console.log(e.currentTarget.getAttribute('tag'));
    let tempArr = [];
    let stats = e.currentTarget.getAttribute('status');
    let isShared = e.currentTarget.getAttribute('data-shared');
    let itemDistictPermssion =
      signedAssesseePermission?.itemDistictPermssion?.assesseeItemDistinctPermission;
    let popupContentArr = getPopUpOptionList(BASIC_PUBLISH_REVIEW_LIST_NOT_ITERBLE_POPUP, itemDistictPermssion);
    console.log('popupContentArr', popupContentArr);
    let associateId = e.currentTarget.getAttribute('associateid');

    popupContentArr.map((element) => {
      if (stats === 'PUBLISHED' && element.data === 'revise')
        tempArr.push({ ...element, disabled: true });
      else if (stats === 'UNPUBLISHED' && element.data === 'share')
        tempArr.push({ ...element, disabled: true });
      else if (
        isShared === 'true' &&
        associateId !== signedAssociateId &&
        (element.data === 'revise' ||
          element.data === 'archive' ||
          element.data === 'flag' ||
          element.data === 'delete' ||
          element.data === 'suspend' ||
          element.data === 'select' ||
          element.data === 'publish' ||
          element.data === 'terminate')
      )
        tempArr.push({ ...element, disabled: true });
      else if (
        (stats === 'TERMINATED' || stats === 'SUSPENDED' || stats === 'ARCHIVED') &&
        (element.data === 'share' ||
          element.data === 'revise' ||
          element.data === 'publish' ||
          element.data === 'select' ||
          element.data === 'allocate')
      ) {
        tempArr.push({ ...element, disabled: true });
      } else tempArr.push(element);
    });
    console.log('tempArr', tempArr);

    // dispatch({
    //   type: SET_POPUP_STATE,
    //   payload: {
    //     popupHeaderOne: 'item',
    //     popupHeaderOneBadgeOne: middlePaneHeaderBadgeOne,
    //     popupHeaderOneBadgeTwo: '',
    //     isPopUpValue: '',
    //     popupOpenType: 'primary',
    //     popupContentArrValue: tempArr,
    //     selectedTagValue: e.currentTarget.getAttribute('tag'),
    //     selectedTagStatus: stats,
    //     selectedTagGroupId: e.currentTarget.getAttribute('data-value'),
    //     isFlaged: e.currentTarget.getAttribute('data-flag') === 'true' ? true : false,
    //     selectedTagInformationSetup: JSON.parse(e.currentTarget.getAttribute('informationsetup')),
    //   }
    // });
    // dispatch({
    //   type: SET_DISPLAY_TWO_SINGLE_STATE,
    //   payload: {
    //     stateName: 'middlePaneListPopupOptions',
    //     value: tempArr
    //   }
    // });
    // dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
  };


  const signedAssociateId =
    selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary;


  // infinite scroll
  const [hasMore, setHasMore] = useState(true);
  let elementRef = useRef(null);

  const onIntersection = (entries) => {
    const firstEntry = entries[0];
    reviewListDistinctData.length >= scanCount ? setHasMore(false) : setHasMore(true)
    if (firstEntry.isIntersecting && hasMore && !isListLoading) {
      fetchData();
    }
  };
  // console.log(reviewListDistinctData.length, scanCount , '##########')
  useEffect(() => {
    // console.log('####MYREF######', elementRef)
    const observer = new IntersectionObserver(onIntersection, { threshold: 0.90 });

    if (observer && elementRef.current) {
      // console.log('here at observer...', elementRef.current)
      observer.observe(elementRef.current);
    }
    // console.log(reviewListDistinctData.length, scanCount ,'##########')
    return () => {
      
      if (observer) observer.disconnect();
    };
  }, [isListLoading, hasMore, reviewListDistinctData, isSelectActive, selectedTagsArray, unselectedTagsArray]);

  // infinite scroll end

  return (
    <div>
      {reviewListDistinctData &&
        reviewListDistinctData.map((item, index) => {
          return (
            <div
              className="containerPadding"
              key={index}
              ref={elementRef}
            >
              <ReviewList
                className=""
                id={index}
                tag={item.id}
                associateId={item.associateId}
                informationSetup={item?.informationSetup}
                isSelectedReviewList={middlePaneSelectedValue === item.id}
                status={item.informationEngagement.itemStatus}
                actualStatus={item.informationEngagement.itemStatus}
                textOne={item.informationBasic.itemName}
                textTwo={item.informationBasic.itemDescription}
                isTooltipActive={false}
                onClickEvent={openListPopup}
                isSelectActive={isSelectActive}
                isSelected={selectedTagsArray.includes(item.id)}
                onClickCheckBox={(event) => {
                  !isListLoading && onClickCheckBoxSelection(selectedTagsArray, unselectedTagsArray, event, dispatch);
                }}
                // disableonClickCheckBox={item.associateId !== signedAssociateId ? true : false}
                isShared={item?.itemShared}
                isFlagActive={selectedFlagedArray.includes(item.id)}
                flagedValue={flagedValue}
                onClickAddFladed={(event) => {
                  onClickFlagSelection(selectedFlagedArray, unselectedFlagedArray, event, dispatch);
                }}

              // dataValue={item.informationAllocation.itemGroup}
              />
            </div>
          );
        })}
      {isListLoading ? <div> <ListLoader /> </div> : <div style={{ height: "60px", width: "100%" }}></div>}
      {(FilterMode === 'itemDistinctinactive' ||
        FilterMode === 'itemDistinctsuspended' ||
        FilterMode === 'itemDistinctunpublished' ||
        FilterMode === 'itemDistinctterminated') && (
          <FooterIconTwo
            FilterModeEnable={FilterModeEnable}
            FilterMode={FilterMode}
            onClick={onClickFooter}
            primaryIcon={primaryIcon}
            secondaryIcon={secondaryIcon}
          />
        )}
      
    </div>
  );
};
export default MarketplaceItemReviewList;
