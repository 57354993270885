import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ASSESSMENT_ALLOCATE_ASSIGNMENT_SAGA,
  ASSESSMENT_REVIEW_DISTINCT_SAGA,
  ASSOCIATE_POPUP_CLOSE,
  FILTERMODE,
  FILTERMODE_ENABLE,
  ITEM_ALLOCATE_ASSESSMENT_SAGA,
  LOADER_START,
  POPUP_OPEN,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_PAGE_COUNT,
  SET_POPUP_STATE,
  SET_REQUEST_OBJECT,
  ASSESSMENT_ALLOCATE_SAGA,
  CLEAR_DISPLAY_PANE_THREE,
  ASSESSMENT_MARKETPLACE_REVIEW_DISTINCT_SAGA
} from '../actionType';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import { FilterList } from '@material-ui/icons';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import {
  getPopUpOptionList,
  makeAssessmentReviewListRequestObject
} from '../Actions/GenericActions';
import { ASSESSMENT_REVIEW_LIST_POPUP_OPTION } from '../PopUpConfig';
import {
  onClickCheckBoxSelection,
  onClickFlagSelection,
  setFlagedArray
} from '../Actions/AssesseeModuleAction';
import ReviseIcon from '@material-ui/icons/RadioButtonChecked';
import Check from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import ListLoader from '../Atoms/ListLoader/ListLoader';
const AssessmentDistinctReviewList = (props) => {
  const dispatch = useDispatch();
  // const { secondaryOptionCheckValue,  } = useSelector(
  //   (state) => state.AssesseeCreateReducer
  // );
  const {
    numberPage,
    scanCount,
    reviewListDistinctData,
    reviewListReqObj,
    middlePaneSelectedValue,
    selectedAssociateInfo,
    selectedTagsArray,
    unselectedTagsArray,
    isSelectActive,
    allocatedTagsArray,
    allocateStr,
    selectedFlagedArray,
    unselectedFlagedArray,
    flagedValue,
    countPage,
    signedAssesseePermission,
    middlePaneHeaderBadgeOne,
    middlePaneHeaderBadgeTwo,
    middlePaneHeaderBadgeThree,
    isListLoading,
    assignmentCreateType
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { FilterModeEnable, FilterMode } = useSelector((state) => state.FilterReducer);
  // const [isFetching, setIsFetching] = useState(false);
  // useEffect(() => {
  //   document.getElementById('middleComponentId').addEventListener('scroll', handleScroll);
  // }, []);
  // const handleScroll = (event) => {
  //   var targetPt = event.target;
  //   if (
  //     Math.ceil(targetPt.scrollHeight - targetPt.scrollTop) !== targetPt.clientHeight ||
  //     isFetching
  //   )
  //     return;
  //   setIsFetching(true);
  // };
  const fetchData = async () => {
    if (reviewListDistinctData.length < scanCount) {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isListLoading', value: true }
      });
      let obj = {
        ...reviewListReqObj.requestObject,
        numberPage: numberPage + 1
      };
      dispatch({
        type: ASSESSMENT_MARKETPLACE_REVIEW_DISTINCT_SAGA,
        payload: {
          request: {
            module: 'Assessment-Distinct',
            action: 'Review-List',
            actionFilter: 'Assessment-Shared-List',
            requestObject: obj
          },
          BadgeOne: middlePaneHeaderBadgeOne,
          BadgeTwo: middlePaneHeaderBadgeTwo,
          BadgeThree: middlePaneHeaderBadgeThree,
          isMiddlePaneList: true,
          isSelectActive: isSelectActive,
          selectedTagsArray: selectedTagsArray || [],
          unselectedTagsArray: unselectedTagsArray || []
        }
      });
      dispatch({ type: SET_PAGE_COUNT, payload: numberPage + 1 });
    }
  };
  // useEffect(() => {
  //   if (!isFetching) return;
  //   fetchMoreListItems();
  // }, [isFetching]);

  // const fetchMoreListItems = () => {
  //   fetchData();
  //   setIsFetching(false);
  // };
  useEffect(() => {
    setFlagedArray(reviewListDistinctData, 'assessmentFlag', dispatch);
  }, [reviewListDistinctData]);

  const siftApiCall = (siftKey) => {
    let requestObect = makeAssessmentReviewListRequestObject(
      selectedAssociateInfo,
      siftKey,
      countPage,
      0,
      assignmentCreateType
    );
    dispatch({ type: LOADER_START });
    dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
    dispatch({
      type: ASSESSMENT_REVIEW_DISTINCT_SAGA,
      payload: {
        request: requestObect,
        BadgeOne: 'distinct',
        BadgeTwo: siftKey,
        isSelectActive,
        selectedTagsArray
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'typeOfMiddlePaneList', value: '' }
    });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
    dispatch({ type: ASSOCIATE_POPUP_CLOSE });
    // document.getElementById('middleComponentId').scrollTop = '0px';
  };
  const onClickFooter = (e) => {
    let siftValue = e.currentTarget.getAttribute('data-value');
    console.log('allocateStr', allocateStr);
    if (siftValue === 'suspended' || siftValue === 'terminated' || siftValue === 'unpublished')
      siftApiCall(siftValue);
    if (siftValue === 'finish') {
      let distinctAllocateStr =
        allocateStr === 'assessmentsdistinct'
          ? 'assessmentDistinct'
          : allocateStr === 'assessmentsgroups'
            ? 'assessmentGroup'
            : allocateStr === 'assessmentstypes'
              ? 'assessmentType'
              : allocateStr === 'assessmentsnodes'
                ? 'assessmentNode'
                : allocateStr === 'assignmentsdistinct'
                  ? 'assignmentDistinct'
                  : allocateStr === 'assignmentsgroups'
                    ? 'assignmentGroup'
                    : allocateStr === 'assignmentstypes'
                      ? 'assignmentType'
                      : allocateStr === 'assignmentsnodes'
                        ? 'assignmentNode'
                        : allocateStr === 'itemsdistinct'
                          ? 'itemDistinct'
                          : allocateStr === 'itemsgroups' || allocateStr === 'itemsgroup'
                            ? 'itemGroup'
                            : allocateStr === 'itemsnodes'
                              ? 'itemNode'
                              : allocateStr === 'itemstypes'
                                ? 'itemType'
                                : '';
      if (distinctAllocateStr !== '' && selectedTagsArray.length !== 0) {
        if (
          distinctAllocateStr === 'assessmentGroup' ||
          distinctAllocateStr === 'assessmentType' ||
          distinctAllocateStr === 'assessmentDistinct' ||
          distinctAllocateStr === 'assessmentNode'
        ) {
          let request = {
            module: 'Assessment-Distinct',
            action: FilterMode.toLowerCase().includes('unallocate') ? 'Unallocate' : 'Allocate',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              [FilterMode.toLowerCase().includes('unallocate')
                ? 'assessmentDistinctUnallocate'
                : 'assessmentDistinctAllocate']: {
                [distinctAllocateStr]: allocatedTagsArray
                //assessmentGroup: allocatedTagsArray
              },
              [FilterMode.toLowerCase().includes('unallocate')
                ? 'assessmentDistinctUnallocateInformation'
                : 'assessmentDistinctAllocateInformation']: {
                assessmentDistinct: selectedTagsArray
              }
            }
          };
          dispatch({ type: LOADER_START });
          dispatch({ type: ASSESSMENT_ALLOCATE_SAGA, payload: { request: request } });
        }
        if (
          distinctAllocateStr === 'assignmentDistinct' ||
          distinctAllocateStr === 'assignmentGroup' ||
          distinctAllocateStr === 'assignmentType' ||
          distinctAllocateStr === 'assignmentNode'
        ) {
          let request = {
            module: 'Assessment-Distinct',
            action: FilterMode.toLowerCase().includes('unallocate') ? 'Unallocate' : 'Allocate',
            actionFilter: 'Assessment-In-Assignment',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              [FilterMode.toLowerCase().includes('unallocate')
                ? 'assessmentDistinctUnallocate'
                : 'assessmentDistinctAllocate']: {
                //[distinctAllocateStr]: allocatedTagsArray
                assessmentDistinct: selectedTagsArray
              },
              [FilterMode.toLowerCase().includes('unallocate')
                ? 'assessmentDistinctUnallocateInformation'
                : 'assessmentDistinctAllocateInformation']: {
                //assignment: selectedTagsArray
                [distinctAllocateStr === 'assignmentDistinct' ? 'assignment' : distinctAllocateStr]:
                  allocatedTagsArray
              }
            }
          };
          dispatch({ type: LOADER_START });
          dispatch({ type: ASSESSMENT_ALLOCATE_ASSIGNMENT_SAGA, payload: { request: request } });
        }
        if (
          distinctAllocateStr === 'itemDistinct' ||
          distinctAllocateStr === 'itemGroup' ||
          distinctAllocateStr === 'itemType' ||
          distinctAllocateStr === 'itemNode'
        ) {
          let request = {
            module: 'Item-Distinct',
            action: FilterMode.toLowerCase().includes('unallocate') ? 'Unallocate' : 'Allocate',
            actionFilter: 'Allocate-Item-In-Assessment',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              itemDistinctAllocate: {
                //  itemDistinct: allocatedTagsArray
                [distinctAllocateStr]: allocatedTagsArray
              },
              itemDistinctAllocateInformation: {
                assessment: selectedTagsArray
              }
            }
          };
          dispatch({ type: LOADER_START });
          dispatch({ type: ITEM_ALLOCATE_ASSESSMENT_SAGA, payload: { request: request } });
        }
      }
    }
    if (siftValue === 'cancel') {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isSelectActive', value: '' }
      });
      dispatch({
        type: FILTERMODE,
        payload: { FilterMode: '' }
      });
    }
    dispatch({ type: FILTERMODE_ENABLE });
  };
  /* for middle pane */
  const primaryIcon = [{ label: 'sift', onClick: onClickFooter, Icon: FilterList }];
  const secondaryIcon = [
    { label: 'suspended', onClick: onClickFooter, Icon: FilterList },
    { label: 'terminated', onClick: onClickFooter, Icon: FilterList },
    { label: 'unpublished', onClick: onClickFooter, Icon: FilterList }
  ];

  let signedAssociateId =
    selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary;

  const openListPopup = (e) => {
    console.log(e.currentTarget.getAttribute('tag'));
    let tempArr = [];
    let stats = e.currentTarget.getAttribute('status');
    let isShared = e.currentTarget.getAttribute('data-shared');

    let assessmentDistinctPermission =
      signedAssesseePermission?.assessmentDistictPermission?.assesseeAssessmentDistinctPermission;
    let popupContentArrValue = getPopUpOptionList(
      ASSESSMENT_REVIEW_LIST_POPUP_OPTION,
      assessmentDistinctPermission
    );
    let associateId = e.currentTarget.getAttribute('associateid');
    popupContentArrValue.map((element) => {
      if (stats === 'PUBLISHED' && element.data === 'revise')
        tempArr.push({ ...element, disabled: true });
      else if (stats === 'UNPUBLISHED' && element.data === 'share')
        tempArr.push({ ...element, disabled: true });
      else if (
        isShared === 'true' &&
        associateId !== signedAssociateId &&
        (element.data === 'revise' ||
          element.data === 'archive' ||
          element.data === 'flag' ||
          element.data === 'delete' ||
          element.data === 'suspend' ||
          element.data === 'select' ||
          element.data === 'publish' ||
          element.data === 'sections' ||
          element.data === 'versions' ||
          element.data === 'clusters' ||
          element.data === 'scales' ||
          element.data === 'grades' ||
          element.data === 'items' ||
          element.data === 'norms' ||
          element.data === 'scores' ||
          element.data === 'move' ||
          element.data === 'review' ||
          element.data === 'terminate')
      )
        tempArr.push({ ...element, disabled: true });
      else if (
        (stats === 'TERMINATED' || stats === 'SUSPENDED' || stats === 'ARCHIVED') &&
        (element.data === 'share' ||
          element.data === 'revise' ||
          element.data === 'publish' ||
          element.data === 'select' ||
          element.data === 'allocate' ||
          element.data === 'sections' ||
          element.data === 'versions' ||
          element.data === 'clusters' ||
          element.data === 'scales' ||
          element.data === 'grades' ||
          element.data === 'items' ||
          element.data === 'norms' ||
          element.data === 'scores')
      ) {
        tempArr.push({ ...element, disabled: true });
      } else tempArr.push(element);
    });
    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: 'assessment',
        popupHeaderOneBadgeOne: middlePaneHeaderBadgeOne,
        popupHeaderOneBadgeTwo: '',
        isPopUpValue: '',
        popupOpenType: 'primary',
        popupContentArrValue: tempArr,
        selectedTagValue: e.currentTarget.getAttribute('tag'),
        selectedTagStatus: stats,
        isFlaged: e.currentTarget.getAttribute('data-flag') === 'true' ? true : false
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: tempArr
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
  };

  // infinite scroll
  const [hasMore, setHasMore] = useState(true);
  let elementRef = useRef(null);

  const onIntersection = (entries) => {
    const firstEntry = entries[0];
    reviewListDistinctData.length >= scanCount ? setHasMore(false) : setHasMore(true);
    if (firstEntry.isIntersecting && hasMore && !isListLoading) {
      fetchData();
    }
  };

  useEffect(() => {
    // console.log('####MYREF######', elementRef)
    const observer = new IntersectionObserver(onIntersection, { threshold: 0.90 });

    if (observer && elementRef.current) {
      // console.log('here at observer...', elementRef.current)
      observer.observe(elementRef.current);
    }

    return () => {
      if (observer) observer.disconnect();
    };
  }, [
    isListLoading,
    hasMore,
    reviewListDistinctData,
    selectedTagsArray,
    unselectedTagsArray,
    isSelectActive
  ]);

  // infinite scroll end

  console.log('FilterMode', FilterMode);

  let filterModes = {
    allocateToAssessment: 'allocate',
    itemGroupAllocateToAssessment: 'allocate',
    itemTypeAllocateToAssessment: 'allocate',
    itemAllocateToAssessment: 'allocate',
    unAllocateToAssessment: 'unallocate',
    itemUnAllocateToAssessment: 'unallocate',
    itemGroupUnAllocateToAssessment: 'unallocate',
    itemTypeUnAllocateToAssessment: 'unallocate'
  };
  return (
    <div>
      {reviewListDistinctData &&
        reviewListDistinctData.map((item, index) => {
          return (
            <div className="containerPadding" key={index} ref={elementRef}>
              <ReviewList
                className=""
                id={index}
                tag={item.id}
                associateId={item?.associateId}
                isSelectedReviewList={middlePaneSelectedValue === item.id}
                status={item.informationEngagement.assessmentStatus}
                actualStatus={item.informationEngagement.assessmentStatus}
                textOne={item.informationBasic.assessmentName}
                textTwo={item.informationBasic.assessmentDescription}
                isTooltipActive={false}
                // onClickEvent={openListPopup}
                isSelectActive={isSelectActive}
                isSelected={selectedTagsArray.includes(item.id)}
                onClickCheckBox={(event) => {
                  !isListLoading &&
                    onClickCheckBoxSelection(
                      selectedTagsArray,
                      unselectedTagsArray,
                      event,
                      dispatch
                    );
                }}
                // disableonClickCheckBox={item.associateId !== signedAssociateId ? true : false}
                isShared={item?.assessmentShared}
                isFlagActive={selectedFlagedArray.includes(item.id)}
                flagedValue={flagedValue}
                onClickAddFladed={(event) => {
                  onClickFlagSelection(selectedFlagedArray, unselectedFlagedArray, event, dispatch);
                }}
              />
            </div>
          );
        })}
      {/* {FilterMode === 'assessmentsDistinctactive' && (
          <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={primaryIcon}
          secondaryIcon={secondaryIcon}
        />
        )} */}
      {isListLoading ? (
        <div>
          {' '}
          <ListLoader />{' '}
        </div>
      ) : <div style={{ height: "60px", width: "100%" }}></div>}
      {(FilterMode === 'assessmentsDistinctinactive' ||
        FilterMode === 'assessmentsDistinctinactivescores') && (
          <FooterIconTwo
            FilterModeEnable={FilterModeEnable}
            FilterMode={FilterMode}
            onClick={onClickFooter}
            primaryIcon={primaryIcon}
            secondaryIcon={secondaryIcon}
          />
        )}
      {filterModes.hasOwnProperty(FilterMode) && (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={[
            { label: `${filterModes[FilterMode]}`, onClick: onClickFooter, Icon: ReviseIcon }
          ]}
          secondaryIcon={[
            { label: 'cancel', onClick: onClickFooter, Icon: ClearIcon },
            { label: 'finish', onClick: onClickFooter, Icon: Check }
          ]}
        />
      )}
    </div>
  );
};
export default AssessmentDistinctReviewList;
