import { put, takeLatest, call, takeEvery } from "redux-saga/effects";
import {
  LOADER_STOP,
  REVIEWLIST_DISTINCT_DATA,
  ASSESSEE_REVIEW_DISTINCT_SAGA,
  SET_MIDDLEPANE_STATE,
  ASSESSEE_MARKETPLACE_REVIEW_DISTINCT_SAGA,
  GET_ASSESSEEGROUP_ASSESSEE_REVIEW_LIST,
  RELATED_REVIEWLIST_DISTINCT_DATA,
  GET_ASSESSEEROLE_ASSESSEE_REVIEW_LIST,
  SET_REVIEW_LIST_RELATE_DATA,
  GET_ALLOCATE_ASSESSEE,
  GET_ASSESSEENODE_ASSESSEE_REVIEW_LIST,
  SET_POPUP_VALUE,
  GET_ASSESSEETYPE_ASSESSEE_REVIEW_LIST,
  SET_ADMINISTRATOR_SECONDARY_LIST,
  SET_DISPLAY_TWO_SINGLE_STATE,
  GET_ALLOCATE_ASSESSEE_GROUP,
  GET_ALLOCATE_ADMINISTRATOR,
  ASSESSEE_MARKETPLACE_REVIEW_GROUP_SAGA,
  ASSESSEE_MARKETPLACE_REVIEW_NODE_SAGA,
  ASSESSEE_MARKETPLACE_REVIEW_ROLE_SAGA,
  ASSESSEE_MARKETPLACE_REVIEW_TYPE_SAGA
} from "../../actionType";
import { ASSESSEE_MODULE_URL } from "../../endpoints";
import { EXCEPTION_ERROR_MESSAGE } from "../../errorMessage";
import Store from "../../store";

const reviewListMarketplaceDistinctApi = async (requestObj) => {
  // let URL = ASSESSEE_REVIEW_LIST_URL;
  const requestOptions = {
    method: "POST",
    headers: new Headers({
      Authorization: localStorage.getItem("token"),
    }),
    body: JSON.stringify(requestObj.data),
  };
  const response = await fetch(ASSESSEE_MODULE_URL, requestOptions);
  const json = await response.json();
  return json;
};

function* workerMarketplaceDistinctReviewListAssesseeSaga(data) {
  try {
    const userResponse = yield call(reviewListMarketplaceDistinctApi, {
      data: data.payload.request,
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === "000") {
      yield put({
        type: SET_MIDDLEPANE_STATE,
        payload: {
          middlePaneHeader: data.payload.HeaderOne,
          middlePaneHeaderBadgeOne: data.payload.BadgeOne,
          middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
          middlePaneHeaderBadgeThree: "",
          middlePaneHeaderBadgeFour: "",
          middlePaneHeaderDuplicate: data.payload.HeaderOne,
          middlePaneHeaderDuplicateBadgeOne: data.payload.BadgeOne,
          middlePaneHeaderDuplicateBadgeTwo: data.payload.BadgeTwo,
          middlePaneHeaderDuplicateBadgeThree: "",
          middlePaneHeaderDuplicateBadgeFour: "",
          middlePaneHeaderDuplicateOne: data.payload.HeaderOne,
          middlePaneHeaderDuplicateOneBadgeOne: data.payload.BadgeOne,
          middlePaneHeaderDuplicateOneBadgeTwo: data.payload.BadgeTwo,
          middlePaneHeaderDuplicateOneBadgeThree: "",
          middlePaneHeaderDuplicateOneBadgeFour: "",
          typeOfMiddlePaneList: "MarketplaceAssesseeDistinctReviewList",
          scanCount: userResponse && userResponse.countTotal,
          duplicateScanCount: userResponse && userResponse.countTotal,
          showMiddlePaneState: true,
          isSelectActive: data.payload.isSelectActive,
          selectedTagsArray: data.payload.selectedTagsArray || [],
          unselectedTagsArray: data.payload.unselectedTagsArray || [],
          isListLoading: false,
        },
      });
      yield put({
        type: REVIEWLIST_DISTINCT_DATA,
        payload: userResponse.responseObject?.length > 0 ? userResponse.responseObject : [],
      });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: userResponse.responseMessage,
          popupMode: "responseErrorMsg",
        },
      });
    }

    console.log("loading end");
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log("ERROR==", e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: "responseErrorMsg",
      },
    });
    yield put({ type: LOADER_STOP });
  }
}


function* workerMarketplaceGroupReviewListAssesseeSaga(data) {
  try {
    const userResponse = yield call(reviewListMarketplaceDistinctApi, {
      data: data.payload.request,
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === "000") {
      yield put({
        type: SET_MIDDLEPANE_STATE,
        payload: {
          middlePaneHeader: data.payload.HeaderOne || 'assessees',
          middlePaneHeaderBadgeOne: data.payload.BadgeOne,
          middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
          middlePaneHeaderBadgeThree: '',
          middlePaneHeaderBadgeFour: '',
          middlePaneHeaderDuplicate:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
          middlePaneHeaderDuplicateBadgeOne:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
          middlePaneHeaderDuplicateBadgeTwo:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
          middlePaneHeaderDuplicateBadgeThree:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
          middlePaneHeaderDuplicateBadgeFour:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour,

          middlePaneHeaderDuplicateOne:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOne,
          middlePaneHeaderDuplicateOneBadgeOne:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeOne,
          middlePaneHeaderDuplicateOneBadgeTwo:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeTwo,
          middlePaneHeaderDuplicateOneBadgeThree:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeThree,
          middlePaneHeaderDuplicateOneBadgeFour:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeFour,

          // typeOfMiddlePaneList: data.payload.HeaderOne + 'GroupAssesseeReviewList',
          typeOfMiddlePaneList: 'MarketplaceAssesseeGroupReviewList',
          scanCount: userResponse && userResponse.countTotal,
          duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
          duplicateScanCountOne: Store.getState().DisplayPaneTwoReducer.duplicateScanCountOne,
          showMiddlePaneState: true,
          isSelectActive: data.payload.isSelectActive,
          selectedTagsArray: data.payload.selectedTagsArray,
          unselectedTagsArray: data.payload.unselectedTagsArray
        }
      });
      yield put({
        type: REVIEWLIST_DISTINCT_DATA,
        payload: userResponse.responseObject?.length > 0 ? userResponse.responseObject : [],
      });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: userResponse.responseMessage,
          popupMode: "responseErrorMsg",
        },
      });
    }

    console.log("loading end");
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log("ERROR==", e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: "responseErrorMsg",
      },
    });
    yield put({ type: LOADER_STOP });
  }
}

function* workerMarketplaceNodeReviewListAssesseeSaga(data) {
  try {
    const userResponse = yield call(reviewListMarketplaceDistinctApi, {
      data: data.payload.request,
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === "000") {
      yield put({
        type: SET_MIDDLEPANE_STATE,
        payload: {
          middlePaneHeader: data.payload.HeaderOne,
          middlePaneHeaderBadgeOne: data.payload.BadgeOne,
          middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
          middlePaneHeaderBadgeThree: "",
          middlePaneHeaderBadgeFour: "",
          middlePaneHeaderDuplicate:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
          middlePaneHeaderDuplicateBadgeOne:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
          middlePaneHeaderDuplicateBadgeTwo:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
          middlePaneHeaderDuplicateBadgeThree:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
          middlePaneHeaderDuplicateBadgeFour:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour,

          typeOfMiddlePaneList: "MarketplaceAssesseeNodeReviewList",
          scanCount: userResponse && userResponse.countTotal,
          duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
          showMiddlePaneState: true,
          isSelectActive: data.payload.isSelectActive,
          selectedTagsArray: data.payload.selectedTagsArray || [],
          unselectedTagsArray: data.payload.unselectedTagsArray || [],
          isListLoading: false,
        },
      });
      yield put({
        type: RELATED_REVIEWLIST_DISTINCT_DATA,
        payload: userResponse.responseObject?.length > 0 ? userResponse.responseObject : [],
      });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: userResponse.responseMessage,
          popupMode: "responseErrorMsg",
        },
      });
    }

    console.log("loading end");
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log("ERROR==", e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: "responseErrorMsg",
      },
    });
    yield put({ type: LOADER_STOP });
  }
}

function* workerMarketplaceRoleReviewListAssesseeSaga(data) {
  try {
    const userResponse = yield call(reviewListMarketplaceDistinctApi, {
      data: data.payload.request
    });
    const { isAdministratorSecondary = false } = data.payload;
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === '000') {
      let responseObj = null;
      if (Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData.length > 0) {
        responseObj = {
          ...Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData[0],
          assessee: [
            ...Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData[0].assessee,
            ...userResponse.responseObject[0].assessee
          ]
        };
        //to add list more and adding all the record
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
      } else {
        responseObj = userResponse.responseObject[0];
      }
      if (isAdministratorSecondary) {
        const { assessee = [] } = userResponse.responseObject[0];
        yield put({ type: SET_ADMINISTRATOR_SECONDARY_LIST, payload: assessee });
      } else {
        yield put({
          type: RELATED_REVIEWLIST_DISTINCT_DATA,
          payload: [responseObj]
        });
        yield put({
          type: SET_REVIEW_LIST_RELATE_DATA,
          payload: userResponse.responseObject
        });
        if (data.payload.isMiddlePaneList) {
          yield put({
            type: SET_MIDDLEPANE_STATE,
            payload: {
              middlePaneHeader: data.payload.HeaderOne,
              middlePaneHeaderBadgeOne: data.payload.BadgeOne,
              middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
              middlePaneHeaderBadgeThree: data.payload.BadgeThre,
              middlePaneHeaderBadgeFour: data.payload.BadgeFour,
              middlePaneHeaderDuplicate:
                Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
              middlePaneHeaderDuplicateBadgeOne:
                Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
              middlePaneHeaderDuplicateBadgeTwo:
                Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
              middlePaneHeaderDuplicateBadgeThree:
                Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
              middlePaneHeaderDuplicateBadgeFour:
                Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour,
              typeOfMiddlePaneList: 'MarketplaceAssesseeRoleReviewList',
              scanCount: userResponse && userResponse.countTotal,
              duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
              showMiddlePaneState: true
            }
          });
        }
      }
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    console.log('loading end');
    if (!isAdministratorSecondary) {
      yield put({ type: LOADER_STOP });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}

function* workerMarketplaceTypeReviewListAssesseeSaga(data) {
  try {
    const userResponse = yield call(reviewListMarketplaceDistinctApi, {
      data: data.payload.request
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === '000') {
      //pagination
      let responseObj = null;
      if (Store.getState().DisplayPaneTwoReducer?.relatedReviewListDistinctData?.length > 0) {
        responseObj = {
          ...Store.getState()?.DisplayPaneTwoReducer?.relatedReviewListDistinctData[0],
          assessee: [
            ...Store.getState()?.DisplayPaneTwoReducer?.relatedReviewListDistinctData[0]?.assessee,
            ...userResponse?.responseObject[0]?.assessee
          ]
        };
        //to add list more and adding all the record
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
      } else {
        responseObj = userResponse.responseObject[0];
      }
      yield put({
        type: RELATED_REVIEWLIST_DISTINCT_DATA,
        payload: [responseObj]
      });
      ///
      yield put({
        type: SET_REVIEW_LIST_RELATE_DATA,
        payload: userResponse.responseObject
      });
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: data.payload.HeaderOne,
            middlePaneHeaderBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderBadgeThree: data.payload.BadgeThre,
            middlePaneHeaderBadgeFour: data.payload.BadgeFour,
            middlePaneHeaderDuplicate:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
            middlePaneHeaderDuplicateBadgeOne:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            middlePaneHeaderDuplicateBadgeTwo:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            middlePaneHeaderDuplicateBadgeThree:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
            middlePaneHeaderDuplicateBadgeFour:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour,
            isSelectActive: Store.getState().DisplayPaneTwoReducer.isSelectActive,
            typeOfMiddlePaneList: 'MarketplaceAssesseeTypeassesseeReviewList',
            scanCount: userResponse && userResponse.countTotal,
            duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
            showMiddlePaneState: true
          }
        });
      }
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}


export default function* watchReviewListAssesseeSaga() {
  yield takeEvery(
    ASSESSEE_MARKETPLACE_REVIEW_DISTINCT_SAGA,
    workerMarketplaceDistinctReviewListAssesseeSaga
  );
  yield takeEvery(ASSESSEE_MARKETPLACE_REVIEW_GROUP_SAGA, workerMarketplaceGroupReviewListAssesseeSaga,);
  yield takeEvery(ASSESSEE_MARKETPLACE_REVIEW_NODE_SAGA, workerMarketplaceNodeReviewListAssesseeSaga,);
  yield takeEvery(ASSESSEE_MARKETPLACE_REVIEW_ROLE_SAGA, workerMarketplaceRoleReviewListAssesseeSaga,)
  yield takeEvery(ASSESSEE_MARKETPLACE_REVIEW_TYPE_SAGA, workerMarketplaceTypeReviewListAssesseeSaga,)
}
