import React from 'react';
import { isMobile } from 'react-device-detect';
// import AllocationAccordian from '../Accordian/AllocationAccordian';
// import Manuscript from '@material-ui/icons/Description';
import { useDispatch, useSelector } from 'react-redux';
import DisplayPanelAccordianReviewListOne from '../Accordian/DisplayPanelAccordianReviewListOne';
import DisplayPanelAccordianInformation from '../Accordian/DisplayPanelAccordianInformation';
import { Paper } from '@material-ui/core';
import {
  makeAssociateReviewListRequestObject
} from '../../Actions/GenericActions';
import {
  getAssociateDistinctToAllocate,
  getAssociateTypeAssociateDistinctApiCall
} from '../../Actions/AssociateModuleAction';

const DisplayPaneThreeSectionTwoAssociateType = () => {
  // const [listExpand, setListExpand] = useState('');
  const dispatch = useDispatch();
  const { reviewMode, responseObject, relatedReviewListPaneThree } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  const { selectedAssociateInfo, countPage } = useSelector((state) => state.DisplayPaneTwoReducer);
  // const { informationEngagement, informationSetup } = responseObject;
  // function capitalizeFirstLetter(string) {
  //   if (!string) return '';
  //   return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  // }
  console.log('relatedReviewListPaneThree', relatedReviewListPaneThree);

  let associateArray = [];
  if (relatedReviewListPaneThree.length > 0) {
    //relatedReviewListPaneThree[0]?.associate.forEach((ob) => {
    let associateList = relatedReviewListPaneThree[0]?.associate || [];
    associateList &&
      associateList.forEach((ob) => {
        const { id, informationBasic, informationEngagement, associateId } = ob;
        associateArray.push({
          id,
          textOne: informationBasic?.associateName || '',
          textTwo: informationBasic?.associateDescription || '',
          status: informationEngagement?.associateStatus,
          isShared: ob?.associateShared,
          associateId
        });
      });
  }

  const list2 = [
    {
      id: 'a1',
      labelTextOneOne: 'associates',
      labelTextOneOneBadgeOne: '',
      labelTextOneOneBadgeTwo: '',
      labelTextOneOneBadgeThree: '',
      labelTextOneOneBadgeFour: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'distinct',
          innerList: associateArray
        }
      ],
      innerInfo: 'No Information',
      isListCard: true,
      isReviewLink: true
    }
  ];
  const onclickReviewAssociate = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    if (labelName === 'associates' && selectedBadgeName === 'distinct') {
      console.log(
        'relatedReviewListPaneThree',
        relatedReviewListPaneThree.length,
        relatedReviewListPaneThree
      );
      getAssociateTypeAssociateDistinctApiCall(
        selectedAssociateInfo,
        '',
        -1,
        dispatch,
        'distinct',
        responseObject.id, //group id
        '',
        false,
        true
      );
    }
  };
  const onclickReviseAssociate = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    console.log('ASSESSEE CLICK :::::::>>>>>>>', labelName);
    if (labelName === 'associates' && selectedBadgeName === 'distinct') {
      let requestObect = makeAssociateReviewListRequestObject(
        selectedAssociateInfo,
        '',
        0,
        countPage
      );
      let revisedGroupObject = {
        id: responseObject.id,
        associateTypeName: responseObject.informationBasic.associateTypeName,
        associateTypeDescription: responseObject.informationBasic.associateTypeDescription,
        associateTypeStatus: responseObject.informationEngagement.associateTypeStatus
      };
      let existingAssesseeId = [];
      if (relatedReviewListPaneThree.length > 0) {
        existingAssesseeId =
          relatedReviewListPaneThree[0].associate?.length > 0 &&
          relatedReviewListPaneThree[0].associate?.map((val) => {
            return val.informationEngagement.associateTag.associateTagPrimary;
          });
      }
      console.log('existingAssesseeId', existingAssesseeId);
      getAssociateDistinctToAllocate(
        dispatch,
        requestObect,
        revisedGroupObject,
        (existingAssesseeId && existingAssesseeId) || [],
        'associateTypeAssociateRevise',
        'associatesTypeAssociateReviewList'
      );
    }
  };

  return (
    <div>
      <>
        <div className={'containerPadding'}>
          <Paper className={'dossierContainerTop'}>
            {list2.map((ob) => {
              return (
                <div key={ob.id}>
                  {ob.isListCard ? (
                    <DisplayPanelAccordianReviewListOne
                      onClickRevise={onclickReviseAssociate}
                      onClickReview={onclickReviewAssociate}
                      className=""
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  ) : (
                    <DisplayPanelAccordianInformation
                      onClickRevise={onclickReviseAssociate}
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  )}
                </div>
              );
            })}
          </Paper>
        </div>
      </>
      {isMobile && (
        <div className={'containerPadding'} style={{ height: '55px' }}>
          {' '}
        </div>
      )}
    </div>
  );
};

export default DisplayPaneThreeSectionTwoAssociateType;
