import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ClickAwayListener, InputLabel } from '@material-ui/core';
import Notifications from '@material-ui/icons/NotificationsActive';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined';
import FlagIcon from '@material-ui/icons/Flag';
import './ReviewList.css';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import IosShareIcon from '@material-ui/icons/Share';
import ShareIcon from '../../images/ios_share_black_24dp.svg';
import ArrowRight from '@material-ui/icons/ChevronRight';
import shareIn from '../../images/shareIn.svg'
import shareOut from '../../images/shareOut.svg'
import DummyIcon from '@material-ui/icons/Description';
import PictureAsPdfRoundedIcon from '@material-ui/icons/PictureAsPdfRounded';

const ReviewList = (props) => {
  const {
    textTwo = '',
    textOne,
    status,
    statusTwo,
    isAlertActive = false,
    isFlagActive = false,
    isSelectActive = '',
    isTooltipActive = false,
    isListIconActive = false,
    tooltipActiveText = '',
    isSelectedReviewList = false,
    onClickEvent = null,
    tag = '',
    associateId = '',
    id,
    actualStatus = '',
    informationSetup = null,
    onClickCheckBox = null,
    disableonClickCheckBox = false,
    onClickAddFladed = null,
    onClickArrow = null,
    isSelected = false,
    isDelivery = false,
    dataValue = '',
    dataDefault = '',
    dataDefaultRole = '',
    flagedValue = '',
    shared = '',
    className,
    isShared = false,
    isInformation = false,
    isInformationValue = '',
    isInformationTwo = false,
    isInformationTwoValue = '',
    onClickListIcon = null,
    customBackgroundColor,
    SelectedTagUrl,
    isReportIcon = false
  } = props;
  const { selectedAssociateInfo, isListLoading } = useSelector((state) => state.DisplayPaneTwoReducer);
  const [isShowTooltip, setIsShowTooltip] = useState(false);

  let signedAssociateId =
    selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary;

  return (
    <div key={id}>
      <div
        // disableFocusRipple={true} disableRipple={true} 
        style={{ backgroundColor: customBackgroundColor ? customBackgroundColor : '' }}
        className={'reviewListBorder'}>
        <div
          className={[customBackgroundColor ? 'cardButtonwithouttextTransform2' : 'cardButtonwithouttextTransform', 'heightInherit'].join(' ')}
          style={{ backgroundColor: isSelectedReviewList ? 'rgb(240, 240, 240)' : '' }}
        >
          <div
            className={['measureBox', 'heightInherit', 'componentinnerdiv-iguru', className].join(
              ' '
            )}
          >
            <div
              className={['iguru-cardContentMidPanel', 'heightInherit'].join(' ')}
              tag={tag}
              associateid={associateId}
              id={id}
              status={actualStatus}
              informationsetup={JSON.stringify(informationSetup)}
              data-value={dataValue}
              data-shared={isShared}
              data-classification={shared}
              data-flag={isFlagActive}
              data-default={dataDefault}
              data-admin={dataDefaultRole}
              onClick={isSelectActive !== '' ? null : onClickEvent}
              selectedtagurl={SelectedTagUrl}
              style={{
                backgroundColor: isSelectedReviewList ? 'rgb(240, 240, 240)' : '',
                height: '48px'
              }}
            >
              <div
                className={[
                  'midPaneInformation',
                  textTwo == null || textTwo === '' ? 'aliasmiddle' : null
                ].join(' ')}
                title={textOne}
              >
                {isTooltipActive && tooltipActiveText ? (
                  <ClickAwayListener
                    onClickAway={(event) => {
                      event.stopPropagation();
                      setIsShowTooltip(false);
                      // this.props.cls.setTemplateValue('culturetooltipstate', '');
                    }}
                  >
                    <div>
                      <span
                        onClick={(event) => {
                          event.stopPropagation();
                          setIsShowTooltip((state) => !state);
                          // this.props.cls.setTemplateValue('culturetooltipstate', textOne);
                        }}
                        className="midPaneInformation"
                      >
                        {textOne}
                      </span>
                      {isShowTooltip ? (
                        <div className="tooltip-container">
                          <p className="tooltip-text" style={{ margin: '0' }}>
                            {tooltipActiveText}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  </ClickAwayListener>
                ) : (
                  <span dangerouslySetInnerHTML={{ __html: textOne }}></span>
                )}
                {/* {textOne} */}
              </div>
              {textTwo != null ? <div className={'midPaneLabel'}>{textTwo}</div> : null}
            </div>
            <div
              className={'iguru-iconbox'}
            // className={'unitFlex'}
            >
              <span
                className={['', 'assessmenetStatusText', 'AssesseeNotifyStatus'].join(' ')}
                //className={['unitFlex', 'assessmenetStatusText', 'AssesseeNotifyStatus'].join(' ')}
                style={{ textAlign: 'center' }}
              >
                {isAlertActive ? <Notifications className={'selectionIcon'} /> : null}
                {isInformation ? <div style={{ fontSize: '1.2rem', color: 'rgba(0, 0, 0, 0.87)' }}>{isInformationValue}</div> : null}
                {isShared ? (
                  <img
                    alt={'shared'}
                    // src={ShareIcon}
                    data-shareicon={associateId === signedAssociateId}
                    src={associateId === signedAssociateId ? shareOut : shareIn}
                    className={'selectionIcon'}
                    style={{ height: '12px', width: '12pu ', color: 'rgba(0, 0, 0, 0.87)' }}
                  />
                ) : null}
                <InputLabel
                  className={['iconsFooterLabelDefault', 'AssesseeNotifyStatusLabel'].join(' ')}
                >
                  {status}
                </InputLabel>
              </span>
            </div>
            <div
              className={['iguru-iconbox', 'assessmenetStatusText'].join(' ')}
              //className={['unitFlex', 'unitFlexTop','assessmenetStatusText'].join(' ')}
              style={{ flexDirection: 'column' }}
            >
              {isDelivery && (
                <IconButton
                  onClick={onClickArrow}
                  assignmentid={tag}
                  actualstatus={actualStatus}
                  index={id}
                //className={'assesseeListiconSize'}
                >
                  <ArrowRight />
                  {/* <ArrowForwardIosIcon 
                  className={'arrowiconmargin'} 
                  /> */}
                </IconButton>
              )}
              {isFlagActive || flagedValue !== '' ? (
                <div className={['unitFlex', 'iconsBarDefaultFlag'].join(' ')}>
                  <IconButton
                    className={'assesseeListiconSize'}
                    id={tag}
                    onClick={flagedValue !== '' ? onClickAddFladed : null}
                  >
                    {/* */}
                    {isFlagActive ? (
                      <FlagIcon //className={'flagiconmargin'}
                        id={tag} />
                    ) : (
                      <FlagOutlinedIcon //className={'flagiconmargin'}
                        id={tag} />
                    )}
                  </IconButton>
                </div>
              ) : null}

              {isSelectActive !== '' ? (
                <div className={'unitFlex'}>
                  <div
                    className={[
                      'unitFlex',
                      isFlagActive ? 'iconsBarDefaultFlag' : 'selectedOption'
                    ].join(' ')}
                  >
                    <Checkbox
                      id={tag}
                      className={'assesseeListCheckBoxSelctedOut'}
                      color="default"
                      disabled={isListLoading}
                      onChange={disableonClickCheckBox ? null : onClickCheckBox}
                      checked={isSelected}
                    />
                  </div>
                </div>
              ) : null}

              {isListIconActive ? (
                <div className={'unitFlex'}>
                  <div
                    className={[
                      'unitFlex',
                      'selectedOptionList'
                    ].join(' ')}
                  >
                    <IconButton
                      className={'assesseeListiconSize'}
                      id={id}
                      tag={tag}
                      onClick={onClickListIcon}>
                      {isReportIcon ? <PictureAsPdfRoundedIcon id={id} tag={tag} /> : <DummyIcon
                        id={id} tag={tag} />}
                    </IconButton>
                  </div>
                </div>
              ) : null}
              {isInformationTwo ? <span style={{ fontSize: '1.2rem', color: 'rgba(0, 0, 0, 0.87)' }} dangerouslySetInnerHTML={{ __html: isInformationTwoValue }}></span> : null}
              {isInformationTwo && (
                <InputLabel
                  className={['iconsFooterLabelDefault', 'AssesseeNotifyStatusLabel'].join(' ')}
                >
                  {statusTwo}
                </InputLabel>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewList;
