import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { POPUP_OPEN, SET_DISPLAY_TWO_SINGLE_STATE, SET_POPUP_STATE } from '../actionType';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import { PROFILER_LIST_POPUP_OPTION } from '../PopUpConfig';
import { onClickCheckBoxSelection } from '../Actions/AssesseeModuleAction';

const ProfilerDistinctReviewList = (props) => {
  const dispatch = useDispatch();
  const {
    reviewListDistinctData,
    middlePaneSelectedValue,
    isSelectActive,
    selectedTagsArray,
    middlePaneHeaderBadgeOne,
    unselectedTagsArray
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const openListPopup = (e) => {
    console.log(e.currentTarget.getAttribute('tag'));
    // let selectedTag = e.currentTarget.getAttribute('tag');
    let popupContentArrValue = JSON.parse(JSON.stringify(PROFILER_LIST_POPUP_OPTION));
    let newObj = {};
    newObj = {
      data: 'framework',
      dataValue: 'framerwork',
      dataKey: 'review',
      optionClass: 'optionPrimary',
      divider: 'dark',
      disabled: false,
      permissionValue: 'revise'
    };
    popupContentArrValue.splice(11, 0, newObj);
    let temmmArr=[];
    popupContentArrValue.forEach(element => {
      if(element.data ==='revise'){
        temmmArr.push({...element,disabled:true})
      }
      else temmmArr.push(element)
    });
    // if (selectedTag === 'iGauge Occupation (Culture-Profiler)') {
    //   newObj = {
    //     data: 'framework',
    //     dataValue: 'framerwork',
    //     optionClass: 'optionPrimary',
    //     divider: 'dark',
    //     disabled: false,
    //     permissionValue: 'revise'
    //   };
    //   popupContentArrValue.splice(11, 0, newObj);
    // } else if (selectedTag === "iGauge Occupation (Job-Profiler)") {
    //   newObj = {
    //     data: 'framework',
    //     dataValue: 'framerwork',
    //     optionClass: 'optionPrimary',
    //     divider: 'dark',
    //     disabled: false,
    //     permissionValue: 'revise'
    //   };
    //   popupContentArrValue.splice(11, 0, newObj);
    //   // newObj = {
    //   //   data: 'job-competency',
    //   //   dataValue: 'jobcompetency',
    //   //   optionClass: 'optionPrimary',
    //   //   divider: 'dark',
    //   //   disabled: false,
    //   //   permissionValue: 'revise'
    //   // };
    //   // let newObj1 = {
    //   //   data: 'job-domain',
    //   //   dataValue: 'jobdomain',
    //   //   optionClass: 'optionPrimary',
    //   //   divider: 'dark',
    //   //   disabled: false,
    //   //   permissionValue: 'revise'
    //   // };
    //   // let newObj2 = {
    //   //   data: 'job-function',
    //   //   dataValue: 'jobfunction',
    //   //   optionClass: 'optionPrimary',
    //   //   divider: 'dark',
    //   //   disabled: false,
    //   //   permissionValue: 'revise'
    //   // };
    //   // let newObj3 = {
    //   //   data: 'job-role',
    //   //   dataValue: 'jobrole',
    //   //   optionClass: 'optionPrimary',
    //   //   divider: 'dark',
    //   //   disabled: false,
    //   //   permissionValue: 'revise'
    //   // };
    //   //popupContentArrValue.splice(11, 0, newObj, newObj1, newObj2, newObj3);
    // }
    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: 'profiler',
        popupHeaderOneBadgeOne: middlePaneHeaderBadgeOne,
        popupHeaderOneBadgeTwo: '',
        isPopUpValue: '',
        popupOpenType: 'primary',
        popupContentArrValue: temmmArr,
        selectedTagValue: e.currentTarget.getAttribute('tag'),
        selectedTagStatus: e.currentTarget.getAttribute('status'),
        selectedTagGroupId: e.currentTarget.getAttribute('data-value')
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: temmmArr
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
  };
  console.log('reviewListDistinctData', reviewListDistinctData);
  return (
    <div>
      {reviewListDistinctData &&
        reviewListDistinctData.map((item, index) => {
          return (
            <div className="containerPadding" key={index}>
              <ReviewList
                className=""
                id={index}
                tag={item.id}
                associateId={item?.associateId}
                isSelectedReviewList={middlePaneSelectedValue === item.id}
                textOne={item?.profilerName || ''}
                textTwo={item?.profilerDescription || ''}
                isTooltipActive={false}
                onClickEvent={openListPopup}
                isSelectActive={isSelectActive}
                isSelected={selectedTagsArray.includes(item.id)}
                onClickCheckBox={(event) => {
                  onClickCheckBoxSelection(selectedTagsArray, unselectedTagsArray, event, dispatch);
                }}
              />
            </div>
          );
        })}
    </div>
  );
};
export default ProfilerDistinctReviewList;
