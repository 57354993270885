import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ASSESSEE_INFO_CREATE,
  ASSOCIATE_ALLOCATE_SAGA,
  ASSOCIATE_POPUP_CLOSE,
  CLEAR_DISPLAY_PANE_THREE,
  FILTERMODE,
  FILTERMODE_ENABLE,
  GET_ASSOCIATE_GROUP_REVIEW_LIST_SAGA,
  LOADER_START,
  POPUP_OPEN,
  SECTION_POPUP_CLOSE,
  SET_DISPLAY_PANE_THREE_STATE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_PAGE_COUNT,
  SET_POPUP_SINGLE_STATE,
  SET_POPUP_STATE,
  SET_REQUEST_OBJECT
} from '../actionType';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import { FilterList } from '@material-ui/icons';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import { getPopUpOptionList, makeAssociateGroupObj } from '../Actions/GenericActions';
import {
  ASSESSEE_NODE_ROLE_REVIEW_LIST_POPUP_OPTION,
  NODE_ROLE_TYPE_REVIEW_LIST_POPUP_OPTION
} from '../PopUpConfig';
import {
  onClickCheckBoxSelection,
  onClickFlagSelection,
  setFlagedArray
} from '../Actions/AssesseeModuleAction';
import ReviseIcon from '@material-ui/icons/RadioButtonChecked';
import Check from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import FileExplorerTheme from 'react-sortable-tree-theme-full-node-drag';
import SortableTree from 'react-sortable-tree';
import { AccountTree } from '@material-ui/icons';
import ListIcon from '@material-ui/icons/FormatListBulleted';
import { getAssociateGroupDistinctApiCall } from '../Actions/AssociateModuleAction';
import ListLoader from '../Atoms/ListLoader/ListLoader';
const AssociateGroupReviewList = (props) => {
  const dispatch = useDispatch();
  // const { secondaryOptionCheckValue } = useSelector((state) => state.AssesseeCreateReducer);
  const {
    numberPage,
    scanCount,
    countPage,
    reviewListDistinctData,
    reviewListReqObj,
    middlePaneSelectedValue,
    selectedAssociateInfo,
    middlePaneHeader,
    middlePaneHeaderBadgeOne,
    middlePaneHeaderBadgeTwo,
    middlePaneHeaderBadgeThree,
    isSelectActive,
    unselectedTagsArray,
    selectedTagsArray,
    allocatedTagsArray,
    nodeViewState,
    scanString,
    searchFocusIndex,
    allocateStr,
    selectedFlagedArray,
    unselectedFlagedArray,
    flagedValue,
    signedAssesseePermission,
    assignmentCreateType,
    isListLoading
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { FilterModeEnable, FilterMode } = useSelector((state) => state.FilterReducer);
  const { cardValue, selectionValue } = useSelector((state) => state.PopUpReducer);
  // const [isFetching, setIsFetching] = useState(false);
  const [filterState, setFilterState] = useState('');
  // useEffect(() => {
  //   document.getElementById('middleComponentId').addEventListener('scroll', handleScroll);
  // }, []);
  // const handleScroll = (event) => {
  //   var targetPt = event.target;
  //   if (
  //     Math.ceil(targetPt.scrollHeight - targetPt.scrollTop) !== targetPt.clientHeight ||
  //     isFetching
  //   )
  //     return;
  //   setIsFetching(true);
  //   console.log(isFetching);
  // };
  const fetchData = async () => {
    if (reviewListDistinctData.length < scanCount && nodeViewState === 'list') {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isListLoading', value: true }
      });
      let obj = {
        ...reviewListReqObj.requestObject,
        numberPage: numberPage + 1
      };
      dispatch({
        type: GET_ASSOCIATE_GROUP_REVIEW_LIST_SAGA,
        payload: {
          request: { module: 'Associate-Group', action: 'Review-List', requestObject: obj },
          BadgeOne: middlePaneHeaderBadgeOne,
          BadgeTwo: middlePaneHeaderBadgeTwo,
          BadgeThree: middlePaneHeaderBadgeThree,
          isMiddlePaneList: true,
          cardValue: cardValue,
          isSelectActive: isSelectActive,
          selectedTagsArray: selectedTagsArray
        }
      });
      dispatch({ type: SET_PAGE_COUNT, payload: numberPage + 1 });
    }
  };
  // useEffect(() => {
  //   console.log(reviewListDistinctData);
  //   if (!isFetching) return;
  //   fetchMoreListItems();
  // }, [isFetching]);

  // const fetchMoreListItems = () => {
  //   fetchData();
  //   setIsFetching(false);
  // };
  useEffect(() => {
    if (nodeViewState !== 'hierarchy')
      setFlagedArray(reviewListDistinctData, 'associateGroupFlag', dispatch);
  }, [reviewListDistinctData]);

  const siftApiCall = (siftKey, requestObect) => {
    dispatch({ type: LOADER_START });
    dispatch({
      type: FILTERMODE,
      payload: { FilterMode: 'associateGroupDistinct' + siftKey }
    });
    dispatch({
      type: GET_ASSOCIATE_GROUP_REVIEW_LIST_SAGA,
      payload: {
        request: requestObect,
        BadgeOne: middlePaneHeaderBadgeOne,
        BadgeTwo: middlePaneHeaderBadgeTwo === 'distinct' ? middlePaneHeaderBadgeTwo : siftKey,
        BadgeThree: middlePaneHeaderBadgeTwo === 'distinct' ? siftKey : middlePaneHeaderBadgeThree,
        isMiddlePaneList: true,
        cardValue: cardValue,
        isSelectActive: isSelectActive,
        selectedTagsArray: selectedTagsArray
      }
    });
    dispatch({
      type: SET_DISPLAY_PANE_THREE_STATE,
      payload: {
        isReviewRevise: false,
        headerOne: '',
        headerOneBadgeOne: '',
        headerOneBadgeTwo: '',
        headerOneBadgeThree: '',
        reviewMode: 'review'
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'typeOfMiddlePaneList', value: '' }
    });
    dispatch({ type: ASSOCIATE_POPUP_CLOSE });
    document.getElementById('middleComponentId').scrollTop = '0px';
  };

  //resetdata function call

  const resetDataFunction = () => {
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'allocateStr', value: '' }
    });
    // document.getElementById('middleComponentId').scrollTop = '0px';
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: { stateName: 'cardValue', value: 'NoCard' }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'typeOfMiddlePaneList', value: '' }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'middlePaneSelectedValue', value: '' }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'selectedFlagedArray', value: [] }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'unselectedFlagedArray', value: [] }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'selectedTagsArray', value: [] }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'unselectedTagsArray', value: [] }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'flagedValue', value: '' }
    });
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: { stateName: 'selectionValue', value: '' }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'scanString', value: '' }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'allocatedTagsArray', value: [] }
    });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
    dispatch({ type: ASSESSEE_INFO_CREATE });
    dispatch({ type: SECTION_POPUP_CLOSE });
  };
  //

  const onClickFooter = (e) => {
    let siftValue = e.currentTarget.getAttribute('data-value');
    dispatch({ type: FILTERMODE_ENABLE });
    setFilterState(siftValue);
    if (
      (siftValue === 'suspended' ||
        siftValue === 'terminated' ||
        siftValue === 'bespoke' ||
        siftValue === 'generic') &&
      nodeViewState === 'list'
    ) {
      let requestObect = makeAssociateGroupObj(
        selectedAssociateInfo,
        siftValue,
        0,
        countPage,
        'list',
        assignmentCreateType
      );
      dispatch({ type: SET_PAGE_COUNT, payload: 0 });
      dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
      siftApiCall(siftValue, requestObect);
    }
    if ((siftValue === 'bespoke' || siftValue === 'generic') && nodeViewState === 'hierarchy') {
      let requestObect = makeAssociateGroupObj(
        selectedAssociateInfo,
        siftValue,
        0,
        countPage,
        'hierarchy',
        assignmentCreateType
      );
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'scanString', value: '' }
      });
      dispatch({ type: SET_PAGE_COUNT, payload: 0 });
      dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
      siftApiCall(siftValue, requestObect);
    }

    if (siftValue === 'list' || siftValue === 'hierarchy') {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'scanString', value: '' }
      });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'nodeViewState', value: siftValue }
      });
      // resetDataFunction();
      let badgeTwo =
        middlePaneHeaderBadgeTwo === 'generic' || middlePaneHeaderBadgeTwo === 'bespoke'
          ? 'active'
          : middlePaneHeaderBadgeTwo;

      if (cardValue === 'Card') {
        badgeTwo =
          middlePaneHeaderBadgeThree === 'generic' || middlePaneHeaderBadgeThree === 'bespoke'
            ? 'active'
            : middlePaneHeaderBadgeThree;
      }
      dispatch({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'selectionValue', value: '' }
      });
      getAssociateGroupDistinctApiCall(
        selectedAssociateInfo,
        badgeTwo,
        countPage,
        dispatch,
        'groups',
        cardValue,
        '',
        siftValue,
        [],
        assignmentCreateType
      );
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'typeOfMiddlePaneList', value: '' }
      });
    }

    if (siftValue === 'finish') {
      console.log('allocateStr', allocateStr);
      let distinctAllocateStr =
        allocateStr === 'associatesdistinct'
          ? 'associateDistinct'
          : allocateStr === 'associatesgroups'
          ? 'associateGroup'
          : allocateStr === 'associatesnodes'
          ? 'associateNode'
          : allocateStr === 'associatesroles'
          ? 'associateRole'
          : allocateStr === 'associatestypes'
          ? 'associateType'
          : '';
      if (distinctAllocateStr !== '' && selectedTagsArray.length !== 0) {
        if (
          distinctAllocateStr === 'associateDistinct' ||
          distinctAllocateStr === 'associateGroup' ||
          distinctAllocateStr === 'associateNode' ||
          distinctAllocateStr === 'associateRole' ||
          distinctAllocateStr === 'associateType'
        ) {
          let request = {
            module: 'Associate-Distinct',
            action: FilterMode.toLowerCase().includes('unallocate') ? 'unallocate' : 'Allocate',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              associateDistinctAllocate: {
                [distinctAllocateStr]: allocatedTagsArray
              },
              associateDistinctAllocateInformation: {
                associateGroup: selectedTagsArray
              }
            }
          };
          dispatch({ type: LOADER_START });
          dispatch({ type: ASSOCIATE_ALLOCATE_SAGA, payload: { request: request } });
        }
      }
      // let distinctAllocateStr = allocateStr === 'assesseesdistinct' ? 'assesseeDistinct' : '';
      // if (distinctAllocateStr !== '' && selectedTagsArray.length !== 0) {
      // if (distinctAllocateStr === 'assesseeDistinct') {
      // let request = {
      //   assesseeId: selectedAssociateInfo?.assesseeId,
      //   associateId:
      //     selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      //   associateDistinctAllocate: allocatedTagsArray,
      //   associateDistinctAllocateInformation: {
      //     associateGroup: selectedTagsArray
      //   }
      // };
      // dispatch({ type: LOADER_START });
      // dispatch({ type: ASSOCIATE_ALLOCATE_SAGA, payload: { request: request } });
      //   }
      // }
    }
    if (siftValue === 'cancel') {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isSelectActive', value: '' }
      });
      dispatch({
        type: FILTERMODE,
        payload: { FilterMode: '' }
      });
    }
  };
  // /* for middle pane */
  const primaryIconOne = [
    { label: 'sift', onClick: onClickFooter, Icon: FilterList },
    { label: 'view', onClick: onClickFooter, Icon: FilterList }
  ];

  const secondaryIcon = [
    { label: 'hierarchy', onClick: onClickFooter, Icon: AccountTree },
    { label: 'list', onClick: onClickFooter, Icon: ListIcon }
  ];

  const secondaryIconOne = [
    { label: 'suspended', onClick: onClickFooter, Icon: FilterList },
    { label: 'terminated', onClick: onClickFooter, Icon: FilterList }
  ];

  const primaryIcon = [{ label: 'sift', onClick: onClickFooter, Icon: FilterList }];

  const secondaryOneIconSift = [
    { label: 'bespoke', onClick: onClickFooter, Icon: FilterList },
    { label: 'generic', onClick: onClickFooter, Icon: FilterList }
  ];
  // const primaryIconOne = [{ label: 'sift', onClick: onClickFooter, Icon: FilterList }];
  // const secondaryIconOne = [
  //   { label: 'suspended', onClick: onClickFooter, Icon: FilterList },
  //   { label: 'terminated', onClick: onClickFooter, Icon: FilterList }
  // ];
  // const secondaryOneIcon = [
  //   { label: 'bespoke', onClick: onClickFooter, Icon: FilterList },
  //   { label: 'generic', onClick: onClickFooter, Icon: FilterList }
  //   // { label: 'shared', onClick: onClickFooter, Icon: FilterList },
  //   // { label: 'unshared', onClick: onClickFooter, Icon: FilterList }
  // ];
  /* for middle pane */
  //  const primaryIcon = [
  //   { label: "view", onClick: onClickFooter, Icon: FilterList },
  // ];
  // const secondaryIcon = [
  //   { label: "hierarchy", onClick: onClickFooter, Icon: AccountTree },
  //   { label: "list", onClick: onClickFooter, Icon: ListIcon },
  // ];
  let signedAssociateId =
    selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary;

  const openListPopup = (node, e, target, canUpdate) => {
    console.log(e.currentTarget.getAttribute('tag'));
    let tempArr = [];
    let classification = e.currentTarget.getAttribute('data-classification');
    let isShared = e.currentTarget.getAttribute('data-shared');
    let associateId = e.currentTarget.getAttribute('associateid');
    let status = e.currentTarget.getAttribute('status');
    let groupId = e.currentTarget.getAttribute('tag');
    let flaged = e.currentTarget.getAttribute('data-flag') === 'true' && true;
    console.log('node', node);
    if (target === 'hirarchy') {
      groupId = node.node.id;
      status = node.node.status;
      isShared = node.node.associateGroupShared;
      classification = node.node.classification;
      associateId = node.node.associateId;
      flaged = node.node.flag;
    }
    let reviseHeader = 'associates';
    let associateDistinctPermission =
      signedAssesseePermission?.associateDistinctPermission?.assesseeAssociateGroupPermission;
    let initialArr = ASSESSEE_NODE_ROLE_REVIEW_LIST_POPUP_OPTION.map((obj) =>
      obj.data === 'assessees' ? { ...obj, data: middlePaneHeader, dataValue: reviseHeader } : obj
    );
    let popupContentArrValue = getPopUpOptionList(
      cardValue === 'Card' ? NODE_ROLE_TYPE_REVIEW_LIST_POPUP_OPTION  : initialArr,
      associateDistinctPermission
    );
    popupContentArrValue.map((element) => {
      if (classification === 'Bespoke' && element.data === 'share' && status === 'ACTIVE')
        tempArr.push({ ...element, disabled: true });
      else if (
        (isShared === 'true' || isShared) &&
        associateId !== signedAssociateId &&
        (element.data === 'archive' ||
          element.data === 'delete' ||
          element.data === 'flag' ||
          element.data === 'publish' ||
          element.data === 'suspend' ||
          element.data === 'terminate')
      ) {
        tempArr.push({ ...element, disabled: true });
      } else if (
        (status === 'TERMINATED' || status === 'SUSPENDED' || status === 'ARCHIVED') &&
        (element.data === 'allocate' ||
          element.data === 'revise' ||
          element.data === 'select' ||
          element.data === 'share')
      ) {
        tempArr.push({ ...element, disabled: true });
      } else tempArr.push(element);
    });
    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: 'associates',
        popupHeaderOneBadgeOne: 'group',
        popupHeaderOneBadgeTwo: '',
        isPopUpValue: '',
        popupOpenType: 'primary',
        popupContentArrValue: tempArr,
        originPrimaryArrOprion: tempArr,
        selectedTagValue: groupId,
        selectedTagStatus: status,
        isShared: isShared
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: tempArr
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
  };
  const changedNode = (node) => {
    console.log(node.id);
    let dragedNodeId = node.id;
    let dragedNodeParentId = '';
    reviewListDistinctData.map((nodeData) => {
      console.log('nodeData', nodeData);
      nodeData.children.map((chnode) => {
        console.log(chnode.id);
        if (dragedNodeId === chnode.id) {
          dragedNodeParentId = nodeData.id;
        }
      });
    });
  };
  const customSearchMethod = ({ node, searchQuery }) =>
    searchQuery &&
    (node.title?.toLowerCase().indexOf(searchQuery?.toLowerCase()) > -1 ||
      node.subtitle?.toLowerCase().indexOf(searchQuery?.toLowerCase()) > -1);

  // infinite scroll
  const [hasMore, setHasMore] = useState(true);
  let elementRef = useRef(null);

  const onIntersection = (entries) => {
    const firstEntry = entries[0];
    reviewListDistinctData.length >= scanCount ? setHasMore(false) : setHasMore(true);
    if (firstEntry.isIntersecting && hasMore && !isListLoading) {
      fetchData();
    }
  };
  // console.log(reviewListDistinctData.length, scanCount , '##########')
  useEffect(() => {
    // console.log('####MYREF######', elementRef)
    if (nodeViewState !== 'hierarchy') {
      const observer = new IntersectionObserver(onIntersection, { threshold: 0.90 });

      if (observer && elementRef.current) {
        // console.log('here at observer...', elementRef.current)
        observer.observe(elementRef.current);
      }
      // console.log(reviewListDistinctData.length, scanCount ,'##########')
      return () => {
        if (observer) observer.disconnect();
      };
    }
  }, [
    isListLoading,
    hasMore,
    reviewListDistinctData,
    selectedTagsArray,
    unselectedTagsArray,
    isSelectActive
  ]);

  // infinite scroll end

  return (
    <div>
      {reviewListDistinctData && nodeViewState === 'hierarchy' ? (
        <div style={{ minheight: 'calc(100vh - 135px)' }} key={scanString}>
          <SortableTree
            treeData={reviewListDistinctData}
            onChange={(treeData) => {
              treeData.length === 1 &&
                dispatch({
                  type: SET_DISPLAY_TWO_SINGLE_STATE,
                  payload: {
                    stateName: 'reviewListDistinctData',
                    value: treeData
                  }
                });
            }}
            searchMethod={customSearchMethod}
            searchQuery={scanString}
            searchFocusOffset={searchFocusIndex}
            canDrag={({ node }) => true && node.parentId !== null}
            onMoveNode={({ node }) => changedNode(node)}
            searchFinishCallback={(matches) => {
              console.log(matches);
              dispatch({
                type: SET_DISPLAY_TWO_SINGLE_STATE,
                payload: {
                  stateName: 'searchFocusIndex',
                  value: matches.length > 0 ? searchFocusIndex % matches.length : 0
                }
              });
            }}
            theme={FileExplorerTheme}
            isVirtualized={false}
            rowHeight={55}
            scaffoldBlockPxWidth={31}
            slideRegionSize={50}
            generateNodeProps={(node) => ({
              onClick: (event) => {
                if (event.target.type !== 'button' && node.node.title) {
                  openListPopup(node, event, 'hirarchy', true);
                  //  openListHierarchyPopup(node,event, 'hierarchy', true);
                }
              }
            })}
          />
        </div>
      ) : (
        reviewListDistinctData &&
        reviewListDistinctData.map((item, index) => {
          return (
            <div className="containerPadding" key={index} ref={elementRef}>
              <ReviewList
                className=""
                id={index}
                tag={item.id}
                associateId={item?.associateId}
                isSelectedReviewList={middlePaneSelectedValue === item.id}
                //status={item.informationEngagement.associateGroupStatus}
                status={
                  FilterMode === 'associateGroupDistinctactive' ||
                  FilterMode === 'associateGroupDistinctbespoke' ||
                  FilterMode === 'associateGroupDistinctgeneric'
                    ? item.informationSetup?.associateGroupClassification
                        ?.associateGroupClassificationPrimary
                    : item.informationEngagement.associateGroupStatus
                }
                actualStatus={item.informationEngagement.associateGroupStatus}
                textOne={item.informationBasic.associateGroupName}
                textTwo={item.informationBasic.associateGroupDescription}
                isTooltipActive={false}
                onClickEvent={(event) => {
                  openListPopup(item.id, event, 'list', true);
                }}
                // disableonClickCheckBox={item.associateId !== signedAssociateId ? true : false}
                //onClickEvent={openListPopup}
                isSelectActive={isSelectActive}
                isSelected={selectedTagsArray.includes(item.id)}
                onClickCheckBox={(event) => {
                  !isListLoading &&
                    onClickCheckBoxSelection(
                      selectedTagsArray,
                      unselectedTagsArray,
                      event,
                      dispatch
                    );
                }}
                isShared={item?.associateGroupShared}
                //shared={item?.associateGroupShared ? 'SHARED' : 'UNSHARED'}
                shared={
                  item?.informationSetup?.associateGroupClassification
                    ?.associateGroupClassificationPrimary
                }
                isFlagActive={selectedFlagedArray.includes(item.id)}
                flagedValue={flagedValue}
                onClickAddFladed={(event) => {
                  onClickFlagSelection(selectedFlagedArray, unselectedFlagedArray, event, dispatch);
                }}
              />
            </div>
          );
        })
      )}
      {isListLoading ? (
        <div>
          {' '}
          <ListLoader />{' '}
        </div>
      ) : <div style={{ height: "60px", width: "100%" }}></div>}
      {(FilterMode === 'associateGroupDistinctinactive' ||
        FilterMode === 'associateGroupDistinctterminated' ||
        FilterMode === 'associateGroupDistinctsuspended') && (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={primaryIcon}
          secondaryIcon={secondaryIconOne}
        />
      )}
      {(FilterMode === 'associateGroupDistinctactive' ||
        FilterMode === 'associateGroupDistinctactivebespoke' ||
        FilterMode === 'associateGroupDistinctactivegeneric' ||
        FilterMode === 'associateGroupDistinctbespoke' ||
        FilterMode === 'associateGroupDistinctgeneric') && (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={primaryIconOne}
          secondaryIcon={filterState === 'sift' ? secondaryOneIconSift : secondaryIcon}
        />
      )}
      {(FilterMode === 'associateAllocateToGroup' ||
        FilterMode === 'associateUnAllocateToGroup') && (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={[
            {
              label: `${FilterMode === 'associateAllocateToGroup' ? 'allocate' : 'unallocate'}`,
              onClick: onClickFooter,
              Icon: ReviseIcon
            }
          ]}
          secondaryIcon={[
            { label: 'cancel', onClick: onClickFooter, Icon: ClearIcon },
            { label: 'finish', onClick: onClickFooter, Icon: Check }
          ]}
        />
      )}
    </div>
  );
};
export default AssociateGroupReviewList;
