import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PopUpPicture from '../../PopUpInformation/PopUpPicture';
import PopUpTextField from '../../PopUpInformation/PopUpTextField';
import PopUpConfirm from '../../PopUpGeneric/PopUpConfirm';
import {
  POPUP_CLOSE,
  CREATE_NODE_SAGA,
  SET_NODE_REDUCER_STATE,
  LOADER_START,
  CLEAR_NODE_REDUCER_STATE,
  SET_NODE_DYNAMIC_SINGLE_STATE,
  SET_DISPLAY_THREE_SINGLE_STATE,
  UPDATE_ASSESSEE_PERSONAL_INFO,
  UPDATE_ASSESSEE_ENGAGEMENT_INFO,
  SET_ASSOCIATE_NODE_PARENT_STATE,
  SET_ASSOCIATE_NODE_CLASSIFICAION_STATE,
  SET_POPUP_SINGLE_STATE,
  SET_DISPLAY_TWO_SINGLE_STATE
} from '../../actionType';
import PopUpReviewList from '../../PopUpInformation/PopUpReviewList';
import PopUpDropList from '../../PopUpInformation/PopUpDropList';
import PopUpTagSecondary from '../../PopUpInformation/PopUpTagSecondary';
import { NODE_ROLE_TYPE_REVIEW_LIST_POPUP_OPTION } from '../../PopUpConfig';
import { getPopUpOptionList } from '../../Actions/GenericActions';

const PopUpNodeCreate = (props) => {
  const { headerOne } = props;
  const { isPopUpValue, popupErrorMsg } = useSelector((state) => state.PopUpReducer);
  const { nodeInformation } = useSelector((state) => state.NodeCreateReducer);
  const { reviewMode, responseObject, statusPopUpValue } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  const { selectedAssociateInfo, coreNodeReviewListData, signedAssesseePermission } = useSelector(
    (state) => state.DisplayPaneTwoReducer
  );
  console.log(nodeInformation);
  const [roleSelectedError, setRoleSelectedError] = useState('');
  const dispatch = useDispatch();
  const onClickCancelYes = () => {
    dispatch({
      type: SET_DISPLAY_THREE_SINGLE_STATE,
      payload: { stateName: 'createMode', value: '' }
    });
    dispatch({ type: CLEAR_NODE_REDUCER_STATE });
    dispatch({ type: POPUP_CLOSE });
  };
  const onClickYes = () => {
    let reqBody = {
      module: 'Associate-Node',
      action: 'Create',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        associateNode: {
          informationBasic: nodeInformation.informationBasic,
          informationAllocation: nodeInformation.informationAllocation,
          informationFramework: nodeInformation.informationFramework,
          informationSetup: nodeInformation.informationSetup
        }
      }
    };
    console.log('CREATE node api', reqBody);
    let popupContentArrValue = getPopUpOptionList(
      NODE_ROLE_TYPE_REVIEW_LIST_POPUP_OPTION,
      signedAssesseePermission?.associateDistinctPermission?.assesseeAssociateNodePermission
    );
    // dispatch({
    //   type: SET_DISPLAY_THREE_SINGLE_STATE,
    //   payload: { stateName: 'selectedModule', value: 'associate' }
    // });
    let finalPopupOptionArray = [];
    let classification =
    nodeInformation?.informationSetup.associateNodeClassification
        .associateNodeClassificationPrimary;
    console.log('nodeInformation>>',nodeInformation);
    popupContentArrValue.forEach((element) => {
      if (classification === 'Bespoke' && element.data === 'share') {
        finalPopupOptionArray.push({ ...element, disabled: true });
      } else {
        finalPopupOptionArray.push(element);
      }
    });
    console.log('finalPopupOptionArray>>', finalPopupOptionArray);
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: finalPopupOptionArray
      }
    });
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: {
        stateName: 'popupOptionArr',
        value: finalPopupOptionArray
      }
    });
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: {
        stateName: 'originPrimaryArrOprion',
        value: finalPopupOptionArray
      }
    });
    dispatch({ type: LOADER_START });
    dispatch({ type: CREATE_NODE_SAGA, payload: { reqBody, popupContentArrValue } });
  };
  const updateParentNode = (e) => {
    console.log(e.currentTarget.getAttribute('tag'));
    let tagId = e.currentTarget.getAttribute('tag');
    document.getElementById(tagId).style.backgroundColor = 'white';
    setRoleSelectedError('');
    let tagIdArr = nodeInformation?.informationFramework?.associateNodeAscendantPrimary;
    // if (tagIdArr.includes(tagId)) {
    //   setRoleSelectedError('');
    //   // document.getElementById(tagId).style.backgroundColor = 'white';
    //   tagIdArr = tagIdArr.filter(function (number) {
    //     return number !== tagId;
    //   });
    // } else {
    //   var arr = [];
    //   tagIdArr = [...arr];
    //   tagIdArr.push(tagId);
    //   // document.getElementById(tagId).style.backgroundColor = '#F0F0F0';
    // }
    dispatch({
      type: SET_ASSOCIATE_NODE_PARENT_STATE,
      payload: tagIdArr === tagId ? '' : tagId
    });
  };
  let classificationArray = [];
  if (
    signedAssesseePermission?.associateDistinctPermission?.assesseeAssociateNodeBespokePermission
      ?.create
  ) {
    classificationArray.push({ id: 'Bespoke', name: 'Bespoke' });
  }
  if (
    signedAssesseePermission?.associateDistinctPermission?.assesseeAssociateNodeGenericPermission
      ?.create
  ) {
    classificationArray.push({ id: 'Generic', name: 'Generic' });
  }
  return (
    <div>
      {isPopUpValue === 'NAMEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'name'}
          actualLableValue={'associateNodeName'}
          actualLableValueVerification={'associateNodeNameVerification'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'node'}
          headerOneBadgeTwo={'information'}
          nextPopUpValue={'ALIASPOPUP'}
          basicInfo={nodeInformation.informationBasic}
          typeOfSetObject={SET_NODE_REDUCER_STATE}
          isRequired={true}
          errorMsg={popupErrorMsg}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ALIASPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'description'}
          actualLableValue={'associateNodeDescription'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'node'}
          headerOneBadgeTwo={'information'}
          basicInfo={nodeInformation.informationBasic}
          nextPopUpValue={'PICTUREPOPUP'}
          typeOfSetObject={SET_NODE_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'PICTUREPOPUP' && (
        <PopUpPicture
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'node'}
          headerOneBadgeTwo={'information'}
          nextPopUpValue={'PARENTLISTPOPUP'}
          actualLableValue={'associateNodePicture'}
          actualLableValueVerification={'associateNodePictureVerification'}
          basicInfo={nodeInformation.informationBasPARENTLISTPOPUPic}
          typeOfSetObject={SET_NODE_REDUCER_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'PARENTLISTPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'node'}
          headerOneBadgeTwo={'information'}
          nextPopUpValue={'CLASSIFICATIONLISTPOPUP'}
          inputHeader={'node'}
          inputHeaderBadge={'ascendant'}
          inputHeaderBadgeTwo={'primary'}
          infoMsg={'select a node'}
          ListData={coreNodeReviewListData}
          isRequired={true}
          minimumSelected={1}
          selectedList={
            nodeInformation.informationFramework.associateNodeAscendantPrimary === ''
              ? []
              : [nodeInformation.informationFramework.associateNodeAscendantPrimary]
          }
          setErrorMsg={setRoleSelectedError}
          errorMsg={roleSelectedError}
          textOne={'associateNodeName'}
          textTwo={'associateNodeDescription'}
          onClickEvent={updateParentNode}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'CLASSIFICATIONLISTPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'associateNodeClassificationPrimary'}
          label={'classification'}
          listSelect={classificationArray}
          mappingValue={'id'}
          inputHeader={''}
          inputHeaderBadgeOne={''}
          inputHeaderBadgeTwo={''}
          labelval={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'node'}
          headerOneBadgeTwo={'information'}
          isRequired={true}
          nextPopUpValue={reviewMode === 'revise' ? '' : 'CONFIRMATIONPOPUP'}
          basicInfo={nodeInformation.informationSetup.associateNodeClassification}
          typeOfSetObject={SET_ASSOCIATE_NODE_CLASSIFICAION_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'CANCELPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'cancel'}
          headerOneBadgeOne={''}
          mode={'cancel'}
          onClickYes={onClickCancelYes}
        />
      )}
      {isPopUpValue === 'CONFIRMATIONPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'node'}
          headerOneBadgeTwo={'create'}
          onClickYes={onClickYes}
        />
      )}
      {isPopUpValue === 'TAGREADONLYPRIMARYPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tag'}
          labelBadgeOne={'primary'}
          actualLableValue={'assesseeTagPrimary'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'node'}
          headerOneBadgeTwo={'information'}
          basicInfo={
            responseObject?.informationEngagement?.associateNodeTag?.associateNodeTagPrimary || ''
          }
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={''}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TENURESATRTDATEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tenure'}
          labelBadgeOne={'start'}
          actualLableValue={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'node'}
          headerOneBadgeTwo={'information'}
          basicInfo={
            responseObject?.informationEngagement?.associateNodeTenure
              ?.associateNodeTenureDateTimeStart || 'mm/dd/yyyy --:-- --'
          }
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={''}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TENUREENDDATEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tenure'}
          labelBadgeOne={'end'}
          actualLableValue={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'node'}
          headerOneBadgeTwo={'information'}
          basicInfo={
            responseObject?.informationEngagement?.associateNodeTenure
              ?.associateNodeTenureDateTimeEnd || 'mm/dd/yyyy --:-- --'
          }
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={''}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'STATUSPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assesseeStatus'}
          label={'status'}
          listSelect={[
            { id: 'Active', name: 'Active' },
            { id: 'Suspended', name: 'Suspended' },
            { id: 'Terminated', name: 'Terminated' },
            { id: 'Unverified', name: 'Unverified' },
            { id: 'Confirmed', name: 'Confirmed' }
          ]}
          mappingValue={'id'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'node'}
          headerOneBadgeTwo={'information'}
          isRequired={true}
          basicInfo={statusPopUpValue}
          nextPopUpValue={''}
          typeOfSetObject={UPDATE_ASSESSEE_PERSONAL_INFO}
          handleNextPopupValue={() => {}}
          isNotRevised={true}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TAGSECONDARYPOPUP' && (
        <PopUpTagSecondary
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'node'}
          headerOneBadgeTwo={'information'}
          tagSecondary={responseObject?.informationEngagement || {}}
          signInSetup={responseObject?.informationSetup || {}}
          nextPopUpValue={'CONFIRMATIONPOPUP'}
          typeOfSetObject={UPDATE_ASSESSEE_ENGAGEMENT_INFO}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
    </div>
  );
};

export default PopUpNodeCreate;
