import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FILTERMODE,
  FILTERMODE_ENABLE,
  POPUP_OPEN,
  SET_ASSESSEE_GROUP_ASSESSEE_ID_LIST,
  SET_UNSELECTED_ASSESSEE_GROUP_ASSESSEE_ID_LIST,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MOBILE_PANE_STATE,
  GET_ALLOCATE_ASSESSEE,
  SET_PAGE_COUNT,
  SET_POPUP_STATE,
  GET_ASSESSEEGROUP_ASSESSEE_REVIEW_LIST,
  CLEAR_DISPLAY_PANE_THREE
} from '../actionType';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import { FilterList } from '@material-ui/icons';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import { ASSESSEE_GROUP_ASSESSEE_REVIEW_LIST_POPUP_OPTION } from '../PopUpConfig';
import Card from '../Molecules/Card/Card';
import CrossIcon from '@material-ui/icons/Clear';
import {
  assesseeGropuReviseApicall,
  getAssesseeGroupAssesseeDistinctApiCall,
  onClickCheckBoxSelection
} from '../Actions/AssesseeModuleAction';
import ReviseIcon from '@material-ui/icons/RadioButtonChecked';
import Check from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { closeRelatedList, getPopUpOptionList } from '../Actions/GenericActions';
import ListLoader from '../Atoms/ListLoader/ListLoader';
const AssesseeGroupAssesseeDistinctReviewList = (props) => {
  const dispatch = useDispatch();
  const [isShowReviseIcon, setIsShowReviseIcon] = useState(true);
  // const [isFetching, setIsFetching] = useState(false);
  const {
    relatedReviewListObjectCopy,
    numberPage,
    scanCount,
    middlePaneHeader,
    middlePaneSelectedValue,
    reviewListDistinctData,
    selectedAssociateInfo,
    relatedReviewListDistinctData,
    middlePaneHeaderBadgeOne,
    middlePaneHeaderBadgeTwo,
    middlePaneHeaderDuplicate,
    middlePaneHeaderDuplicateBadgeOne,
    middlePaneHeaderDuplicateBadgeTwo,
    middlePaneHeaderDuplicateBadgeThree,
    middlePaneHeaderDuplicateBadgeFour,
    middlePaneHeaderDuplicateOne,
    middlePaneHeaderDuplicateOneBadgeOne,
    middlePaneHeaderDuplicateOneBadgeTwo,
    middlePaneHeaderDuplicateOneBadgeThree,
    middlePaneHeaderDuplicateOneBadgeFour,
    selectedTagsArray,
    unselectedTagsArray,
    isSelectActive,
    isMiddlePaneList,
    signedAssesseePermission,
    duplicateScanCount,
    countPage,
    isListLoading
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { reviewMode, responseObject, createMode, headerOneBadgeOne } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  const { originPrimaryArrOprion } = useSelector((state) => state.PopUpReducer);

  const { FilterModeEnable, FilterMode } = useSelector((state) => state.FilterReducer);
  const { assesseeGroup } = useSelector((state) => state.GroupCreateReducer);
  //// pagination code here
  // useEffect(() => {
  //   document.getElementById('middleComponentId').addEventListener('scroll', handleScroll);
  // }, []);
  // const handleScroll = (event) => {
  //   var targetPt = event.target;
  //   if (
  //     Math.ceil(targetPt.scrollHeight - targetPt.scrollTop) !== targetPt.clientHeight ||
  //     isFetching
  //   )
  //     return;
  //   setIsFetching(true);
  // };
  const fetchData = async () => {
    if (relatedReviewListDistinctData.length > 0) {
      if (relatedReviewListDistinctData[0].assessee.length < scanCount) {
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'isListLoading', value: true }
        })
        let obj = {
          ...relatedReviewListObjectCopy.requestObject,
          numberPage: numberPage + 1
        };
        // IF the selected mode is Revise and pagination
        if (reviewMode === 'revise') {
          dispatch({
            type: GET_ALLOCATE_ASSESSEE,
            payload: {
              existingAssesseeId: selectedTagsArray,
              unselectedTagsArray,
              request: {
                module: 'Assessee-Distinct',
                action: 'Review-List',
                requestObject: obj
              },
              HeaderOne: middlePaneHeader,
              BadgeOne: 'distinct',
              // BadgeTwo:
              //   FilterMode === 'assesseeGroupAssesseeRevise' ? 'all' : middlePaneHeaderBadgeTwo,
              BadgeTwo: middlePaneHeaderBadgeTwo,
              isMiddlePaneList: true,
              selectedTagsArray:selectedTagsArray,
              unselectedTagsArray:unselectedTagsArray,
              typeOfMiddlePaneList: 'assesseesGroupAssesseeReviewList',
              revisedGroupObject: {
                id: responseObject.id,
                assesseeGroupName: responseObject.informationBasic.assesseeGroupName,
                assesseeGroupDescription: responseObject.informationBasic.assesseeGroupDescription,
                assesseeGroupStatus: responseObject.informationEngagement.assesseeGroupStatus
              }
            }
          });
        } else {
          dispatch({
            type: GET_ASSESSEEGROUP_ASSESSEE_REVIEW_LIST,
            payload: {
              request: {
                module: 'Assessee-Group',
                action: 'Review-List',
                actionFilter: 'Assessee',
                requestObject: obj
              },
              HeaderOne: 'assessees',
              BadgeOne: middlePaneHeaderBadgeOne,
              BadgeTwo: middlePaneHeaderBadgeTwo,
              BadgeThree: '',
              isMiddlePaneList: isMiddlePaneList
            }
          });
        }

        dispatch({ type: SET_PAGE_COUNT, payload: numberPage + 1 });
      }
    }
  };

  // useEffect(() => {
  //   if (!isFetching) return;
  //   fetchMoreListItems();
  // }, [isFetching]);

  // const fetchMoreListItems = () => {
  //   fetchData();
  //   setIsFetching(false);
  // };
  ////
  const onClickRevise = () => {
    setIsShowReviseIcon(false);
  };
  const onClickReviseCancel = () => {
    if (isListLoading) {
      return
    }
    console.log('ON CLICK cancel ICON');
    setIsShowReviseIcon(true);
    closeRelatedList(
      dispatch,
      createMode,
      responseObject,
      middlePaneHeaderDuplicateOne,
      middlePaneHeaderDuplicateOneBadgeOne,
      middlePaneHeaderDuplicateOneBadgeTwo,
      middlePaneHeaderDuplicateOneBadgeThree,
      middlePaneHeaderDuplicateOneBadgeFour,
      'assesseesGroupDistinctReviewList',
      duplicateScanCount,
      'assesseesGroupDistinct',
      duplicateScanCount,
      middlePaneHeaderDuplicateOne,
      middlePaneHeaderDuplicateOneBadgeOne,
      middlePaneHeaderDuplicateOneBadgeTwo,
      middlePaneHeaderDuplicateOneBadgeThree,
      middlePaneHeaderDuplicateOneBadgeFour,
    );
    dispatch({type:SET_PAGE_COUNT,payload:Math.round(parseInt(reviewListDistinctData.length)/countPage)-1})
    if (headerOneBadgeOne !== 'group') {
      dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
    }
  };
  const onClickReviseFinish = () => {
    console.log('ON CLICK finish ICON', selectedTagsArray);
    setIsShowReviseIcon(true);
    dispatch({
      type: FILTERMODE,
      payload: { FilterMode: '' }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'isSelectActive', value: '' }
    });
    dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
    dispatch({
      type: SET_ASSESSEE_GROUP_ASSESSEE_ID_LIST,
      payload: selectedTagsArray
    });
    dispatch({
      type: SET_UNSELECTED_ASSESSEE_GROUP_ASSESSEE_ID_LIST,
      payload: unselectedTagsArray
    });
    // Do TO SAGA CALL "ASSESSEE_GROUP_REVISE_SAGA"  "API_CALL"
    console.log('selectedTagsArray', selectedTagsArray);
    console.log('unselectedTagsArray', unselectedTagsArray);
    assesseeGropuReviseApicall(
      responseObject,
      selectedAssociateInfo,
      selectedTagsArray,
      unselectedTagsArray,
      assesseeGroup,
      dispatch,
      createMode,
      true,
      middlePaneHeader
    );
    closeRelatedList(
      dispatch,
      createMode,
      responseObject,
      middlePaneHeaderDuplicate,
      middlePaneHeaderDuplicateBadgeOne,
      middlePaneHeaderDuplicateBadgeTwo,
      middlePaneHeaderDuplicateBadgeThree,
      middlePaneHeaderDuplicateBadgeFour,
      'assesseesGroupDistinctReviewList',
      duplicateScanCount,
      'assesseesGroupDistinct',
      duplicateScanCount,
      middlePaneHeaderDuplicateOne,
      middlePaneHeaderDuplicateOneBadgeOne,
      middlePaneHeaderDuplicateOneBadgeTwo,
      middlePaneHeaderDuplicateOneBadgeThree,
      middlePaneHeaderDuplicateOneBadgeFour
    );
  };
  const revisePrimaryIcon = [{ label: 'revise', onClick: onClickRevise, Icon: ReviseIcon }];

  const reviseSecondaryIcons = [
    { label: 'cancel', onClick: onClickReviseCancel, Icon: ClearIcon },
    { label: 'finish', onClick: onClickReviseFinish, Icon: Check }
  ];
  const listDistinctData = relatedReviewListDistinctData[0];

  const siftApiCall = (siftKey) => {
    getAssesseeGroupAssesseeDistinctApiCall(
      selectedAssociateInfo,
      siftKey,
      countPage,
      dispatch,
      middlePaneHeaderBadgeOne,
      listDistinctData.id,
      '',
      false,
      true,
      isSelectActive,
      selectedTagsArray,
      middlePaneHeader
    );
    // document.getElementById('middleComponentId').scrollTop = '0px';
  };
  const onClickFooter = (e) => {
    let siftValue = e.currentTarget.getAttribute('data-value');
    if (
      siftValue === 'suspended' ||
      siftValue === 'terminated' ||
      siftValue === 'disapproved' ||
      siftValue === 'unapproved' ||
      siftValue === 'unconfirmed'
    )
      siftApiCall(siftValue);
    dispatch({ type: FILTERMODE_ENABLE });
  };
  /* for middle pane */
  const primaryIcon = [{ label: 'sift', onClick: onClickFooter, Icon: FilterList }];
  const secondaryIcon = [
    { label: 'disapproved', onClick: onClickFooter, Icon: FilterList },
    { label: 'suspended', onClick: onClickFooter, Icon: FilterList },
    { label: 'terminated', onClick: onClickFooter, Icon: FilterList },
    { label: 'unapproved', onClick: onClickFooter, Icon: FilterList },
    { label: 'unconfirmed', onClick: onClickFooter, Icon: FilterList }
  ];

  const openListPopup = (e) => {
    console.log(e.currentTarget.getAttribute('tag'));
    let assesseeStatus = e.currentTarget.getAttribute('status');
    let isShared = e.currentTarget.getAttribute('data-shared') === 'true' && true;
    let associateId = e.currentTarget.getAttribute('associateid');
    let signedAssociateId =
    selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary;

    let popupContentArrValue = ASSESSEE_GROUP_ASSESSEE_REVIEW_LIST_POPUP_OPTION.map((obj) =>
      obj.data === 'assessees'
        ? { ...obj, data: 'assignments', dataValue: 'assignments' }
        : obj
    );
    let assesseeDistinctPermission =
      signedAssesseePermission?.assesseeDistinctPermission?.assesseeAssesseeDistinctPermission;
    let popupContentArr = getPopUpOptionList(
      popupContentArrValue,
      assesseeDistinctPermission
    );
    let tempArr = [];
    popupContentArr.forEach((element) => {
      if (
        (assesseeStatus === 'UNCONFIRMED' ||
          assesseeStatus === 'SUSPENDED' ||
          assesseeStatus === 'TERMINATED' ||
          assesseeStatus === 'ARCHIVED') &&
        (element.data === 'approve' || element.data === 'share')
      ) {
        tempArr.push({ ...element, disabled: true });
      } else if (
        (assesseeStatus === 'TERMINATED' ||
          assesseeStatus === 'SUSPENDED' ||
          assesseeStatus === 'ARCHIVED') &&
        (element.data === 'approve' ||
          element.data === 'share' ||
          element.data === 'revise' ||
          element.data === 'select' ||
          element.data === 'allocate')
      ) {
        tempArr.push({ ...element, disabled: true });
      } else if (
        isShared &&
        associateId !== signedAssociateId &&
        (element.data === 'revise' ||
          element.data === 'archive' ||
          element.data === 'flag' ||
          element.data === 'delete' ||
          element.data === 'suspend' ||
          element.data === 'move' ||
          element.data === 'terminate')
      ) tempArr.push({ ...element, disabled: true });
      // else if (assesseeStatus !== 'CONFIRMED' && element.data === 'share')
      //   tempArr.push({ ...element, disabled: true });
      else {
        tempArr.push(element);
      }
    });
    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: 'assessee',
       popupHeaderOneBadgeOne: middlePaneHeaderBadgeOne,
        duplicateHeaderOneBadgeTwo: middlePaneHeaderBadgeTwo,
        isPopUpValue: '',
        popupOpenType: 'primary',
        popupContentArrValue: tempArr,
        originPrimaryArrOprion: originPrimaryArrOprion,
        isShared: isShared === 'true' && true,
        selectedTagValue: e.currentTarget.getAttribute('tag'),
        selectedTagStatus: e.currentTarget.getAttribute('status')
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: tempArr
      }
    });
  };

  // infinite scroll
  const [hasMore, setHasMore] = useState(true);
  let elementRef = useRef(null);

  const onIntersection = (entries) => {
    const firstEntry = entries[0];
    relatedReviewListDistinctData[0].assessee.length >= scanCount ? setHasMore(false) : setHasMore(true)
    if (firstEntry.isIntersecting && hasMore && !isListLoading) {
      fetchData();
    }
  };

  useEffect(() => {
    // console.log('####MYREF######', elementRef)
    const observer = new IntersectionObserver(onIntersection, { threshold: 0.90 });

    if (observer && elementRef.current) {
      // console.log('here at observer...', elementRef.current)
      observer.observe(elementRef.current);
    }

    return () => {
      if (observer) observer.disconnect();
    };
  }, [isListLoading, hasMore, listDistinctData, selectedTagsArray, unselectedTagsArray, isSelectActive ]);

  // infinite scroll end
// NEED TO TEST SOMETHING IS WRONG HERE..
  console.log('relatedReviewListDistinctData', relatedReviewListDistinctData);
  return (
    <div>
      {listDistinctData && (
        <Card
          textOneOne={listDistinctData.assesseeGroupName}
          textTwoOne={listDistinctData.assesseeGroupDescription}
          IconOne={CrossIcon}
          isIcon={true}
          labelTwoTwo={'group'}
          onClickIconOne={onClickReviseCancel}
          isAlliance
          relatedCardFixed={true}
          className={'iguru-iconboxSVG'}
        />
      )}
      {listDistinctData &&
        listDistinctData?.assessee.map((item, index) => {
          return (
            <div
              className="containerPadding"
              key={index}
              ref={elementRef}
            >
              <ReviewList
                className=""
                id={index}
                associateId={item?.associateId}
                tag={item.informationEngagement.assesseeTag?.assesseeTagPrimary}
                isSelectedReviewList={middlePaneSelectedValue === item.id}
                status={item.informationEngagement.assesseeStatus}
                isShared={item?.assesseeShared}
                // status={assesseeStatus(
                //   middlePaneHeaderBadgeTwo,
                //   item.informationEngagement.assesseeStatus
                // )}
                actualStatus={item.informationEngagement.assesseeStatus}
                textOne={
                  item.informationBasic.assesseeNameFirst +
                  ' ' +
                  item.informationBasic.assesseeNameOther +
                  ' ' +
                  item.informationBasic.assesseeNameLast +
                  ' ' +
                  item.informationBasic.assesseeNameSuffix
                }
                textTwo={item.informationBasic.assesseeAlias}
                isTooltipActive={false}
                onClickEvent={openListPopup}
                isSelectActive={isSelectActive}
                isSelected={selectedTagsArray.includes(
                  item.informationEngagement.assesseeTag?.assesseeTagPrimary
                )}
                onClickCheckBox={(event) => { !isListLoading &&
                  onClickCheckBoxSelection(selectedTagsArray, unselectedTagsArray, event, dispatch);
                }}
              />
            </div>
          );
        })}
      {isListLoading ? <div> <ListLoader /> </div> : <div style={{ height: "60px", width: "100%" }}></div>}
      {FilterMode === 'assesseeGroupAssesseeRevise' && (
        <FooterIconTwo
          FilterModeEnable={isShowReviseIcon}
          FilterMode={FilterMode}
          onClick={onClickRevise}
          primaryIcon={revisePrimaryIcon}
          secondaryIcon={reviseSecondaryIcons}
        />
      )}
      {FilterMode === 'assesseeGroupAssesseeDistinctinactive' && (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={primaryIcon}
          secondaryIcon={secondaryIcon}
        />
      )}
    </div>
  );
};
export default AssesseeGroupAssesseeDistinctReviewList;
