import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CLEAR_DISPLAY_PANE_THREE,
  FILTERMODE_ENABLE,
  POPUP_OPEN,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MIDDLEPANE_STATE,
  SET_POPUP_STATE,
  RELATED_REVIEWLIST_DISTINCT_DATA,
  GET_ASSESSEE_ASSIGNMENT_SAGA,
  LOADER_START,
  SET_PAGE_COUNT,
  SET_RELATED_REQUEST_OBJECT,
  FILTERMODE
} from '../actionType';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import { FilterList } from '@material-ui/icons';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import {
  REVIEW_LIST_POPUP_OPTION
} from '../PopUpConfig';
import Card from '../Molecules/Card/Card';
import CrossIcon from '@material-ui/icons/Clear';
import { onClickCheckBoxSelection } from '../Actions/AssesseeModuleAction';
import { closeRelatedList } from '../Actions/GenericActions';

const AssignmentAssesseeAssessmentDistinctReviewList = (props) => {
  const dispatch = useDispatch();
  const { FilterModeEnable, FilterMode } = useSelector((state) => state.FilterReducer);

  const {
    middlePaneSelectedValue,
    reviewListDistinctData,
    selectedAssociateInfo,
    relatedReviewListDistinctData,
    middlePaneHeaderBadgeOne,
    middlePaneHeaderDuplicate,
    middlePaneHeaderDuplicateBadgeOne,
    middlePaneHeaderDuplicateBadgeTwo,
    middlePaneHeaderDuplicateBadgeThree,
    middlePaneHeaderDuplicateBadgeFour,
    middlePaneHeaderDuplicateOne,
    middlePaneHeaderDuplicateOneBadgeOne,
    middlePaneHeaderDuplicateOneBadgeTwo,
    middlePaneHeaderDuplicateOneBadgeThree,
    middlePaneHeaderDuplicateOneBadgeFour,
    selectedTagsArray,
    unselectedTagsArray,
    isSelectActive,
    countPage,
    duplicateScanCount
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { createMode, responseObject } = useSelector((state) => state.DisplayPaneThreeReducer);
  const closeRelatedListTwo = () => {
    closeRelatedList(
      dispatch,
      createMode,
      responseObject,
      middlePaneHeaderDuplicate,
      middlePaneHeaderDuplicateBadgeOne,
      middlePaneHeaderDuplicateBadgeTwo,
      middlePaneHeaderDuplicateBadgeThree,
      middlePaneHeaderDuplicateBadgeFour,
      'assignmentDistinctAssesseeReviewList',
      relatedReviewListDistinctData[0]?.assessee?.length,
      'assignmentDistinctAssesseeDistinct',
      duplicateScanCount,
      middlePaneHeaderDuplicateOne,
      middlePaneHeaderDuplicateOneBadgeOne,
      middlePaneHeaderDuplicateOneBadgeTwo,
      middlePaneHeaderDuplicateOneBadgeThree,
      middlePaneHeaderDuplicateOneBadgeFour
    );
    dispatch({ type: RELATED_REVIEWLIST_DISTINCT_DATA, payload: [] });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  };
  const closeList = () => {
    closeRelatedList(
      dispatch,
      createMode,
      responseObject,
      middlePaneHeaderDuplicateOne,
      middlePaneHeaderDuplicateOneBadgeOne,
      middlePaneHeaderDuplicateOneBadgeTwo,
      middlePaneHeaderDuplicateOneBadgeThree,
      middlePaneHeaderDuplicateOneBadgeFour,
      'assignmentDistinctReviewList',
      duplicateScanCount,
      'assignmentsDistinct',
      duplicateScanCount,
      middlePaneHeaderDuplicateOne,
      middlePaneHeaderDuplicateOneBadgeOne,
      middlePaneHeaderDuplicateOneBadgeTwo,
      middlePaneHeaderDuplicateOneBadgeThree,
      middlePaneHeaderDuplicateOneBadgeFour
    );
    // dispatch({
    //   type: SET_MIDDLEPANE_STATE,
    //   payload: {
    //     middlePaneHeader: 'assessees',
    //     middlePaneHeaderBadgeOne: 'distinct',
    //     middlePaneHeaderBadgeTwo: 'active',
    //     typeOfMiddlePaneList: 'assignmentDistinctAssesseeReviewList',
    //     scanCount: reviewListDistinctData.length,
    //     showMiddlePaneState: true
    //   }
    // });
    // dispatch({
    //   type: FILTERMODE,
    //   payload: { FilterMode: 'assignmentDistinctAssesseeDistinctactive'}
    // });
    // dispatch({ type: RELATED_REVIEWLIST_DISTINCT_DATA, payload: [] });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  };
  const onClickFooter = (e) => {
    let siftValue = e.currentTarget.getAttribute('data-value');
    if (
      siftValue === 'suspended' ||
      siftValue === 'terminated' ||
      siftValue === 'started' ||
      siftValue === 'unstarted' ||
      siftValue === 'aborted' ||
      siftValue === 'finished' ||
      siftValue === 'unfinished'
    ) {
      //todo
      console.log('siftValue', siftValue);
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'relatedReviewListDistinctData', value: [] }
      });

      let Listid = relatedReviewListDistinctData[0]?.assessee.filter((ddd) => {
        return (
          ddd.assesseeId === relatedReviewListDistinctData[0]?.assignmentAssesseeInfo.assesseeId
        );
      });
      let listData = Listid[0]?.assignmentData.assesseeAssignmentAssessmentDistinct;
      let selectedAssesseeSorted = listData?.filter((dd) => {
        console.log('dd', dd);
        return dd.assesseeAssignmentAssessmentStatus === siftValue?.toUpperCase();
      });
      let SortData = {
        ...relatedReviewListDistinctData[0],
        assignmentAssesseeInfo: {
          ...relatedReviewListDistinctData[0].assignmentAssesseeInfo,
          assesseeAssignmentAssessmentDistinct: selectedAssesseeSorted
        }
      };
      console.log('SortData', SortData);
      console.log('selectedAssesseeSorted', selectedAssesseeSorted);
      console.log('listData', listData);
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'relatedReviewListDistinctData', value: [SortData] }
      });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: {
          stateName: 'scanCount',
          value: selectedAssesseeSorted?.length
        }
      });
    }

    dispatch({ type: FILTERMODE_ENABLE });
  };
  const primaryIcon = [{ label: 'sift', onClick: onClickFooter, Icon: FilterList }];
  const secondaryIconOne = [
    { label: 'started', onClick: onClickFooter, Icon: FilterList },
    { label: 'unstarted', onClick: onClickFooter, Icon: FilterList }
  ];
  const secondaryIconTwo = [
    { label: 'aborted', onClick: onClickFooter, Icon: FilterList },
    { label: 'finished', onClick: onClickFooter, Icon: FilterList },
    { label: 'suspended', onClick: onClickFooter, Icon: FilterList },
    { label: 'terminated', onClick: onClickFooter, Icon: FilterList },
    { label: 'unfinished', onClick: onClickFooter, Icon: FilterList }
  ];

  //const listDistinctData = relatedReviewListDistinctData[0];
  const openListPopup = (e) => {
    console.log(e.currentTarget.getAttribute('tag'));
    let newObj3 = {
      data: 'reset',
      dataValue: 'reset',
      dataKey: 'reset',
      optionClass: 'optionPrimary',
      disabled: false,
      // permissionValue: 'revise'
    };

    let tempArr = [];
    let status = e.currentTarget.getAttribute('status');

    let tempSwap = REVIEW_LIST_POPUP_OPTION;

    if (tempSwap[5]?.data !== 'reset') {
      tempSwap.splice(5, 0, newObj3);
    }

    tempSwap.forEach((element) => {
      if (
        element.data === 'revise' ||
        element.data === 'share' ||
        element.data === 'allocate' ||
        element.data === 'archive' ||
        element.data === 'delete' ||
        element.data === 'flag' ||
        element.data === 'select' ||
        element.data === 'select' ||
        element.data === 'suspend' ||
        element.data === 'terminate' ||
        element.data === 'review'
      ) {
        tempArr.push({ ...element, disabled: true });
      } else if (element.data === 'reset' && status === 'UNSTARTED') {
        tempArr.push({ ...element, disabled: true });
      } else {
        tempArr.push(element);
      }
    });

    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: 'assessment',
        popupHeaderOneBadgeOne: middlePaneHeaderBadgeOne,
        popupHeaderOneBadgeTwo: '',
        isPopUpValue: '',
        popupOpenType: 'primary',
        popupContentArrValue: tempArr,
        selectedTagValue: e.currentTarget.getAttribute('tag'),
        selectedTagStatus: e.currentTarget.getAttribute('status')
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: tempArr
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
  };
  // const listDistinctData = reviewListDistinctData.filter((x) => x.id === selectedTagValue);
  const listDistinctData = relatedReviewListDistinctData[0];
  console.log('FilterMode', FilterMode);
  console.log('relatedReviewListDistinctData', relatedReviewListDistinctData);

  return (
    <div>
      {listDistinctData && (
        <Fragment>
          <Card
            textOneOne={listDistinctData?.assignmentName}
            textTwoOne={listDistinctData?.assignmentDescription}
            IconOne={CrossIcon}
            isIcon={true}
            labelTwoTwo={'distinct'}
            onClickIconOne={closeList}
            isAlliance
            relatedCardFixed={true}
            className={'iguru-iconboxSVG'}
          />
          <Card
            textOneOne={
              listDistinctData?.assignmentAssesseeInfo?.informationBasic?.assesseeNameFirst +
              ' ' +
              listDistinctData?.assignmentAssesseeInfo?.informationBasic?.assesseeNameLast
            }
            textTwoOne={listDistinctData?.assignmentAssesseeInfo?.informationBasic?.assesseeAlias}
            IconOne={CrossIcon}
            isIcon={true}
            labelTwoTwo={'distinct'}
            onClickIconOne={closeRelatedListTwo}
            isAlliance
            relatedCardFixed={true}
            className={'iguru-iconboxSVG'}
          />
        </Fragment>
      )}
      {listDistinctData?.assignmentAssesseeInfo?.assesseeAssignmentAssessmentDistinct?.length > 0 &&
        listDistinctData?.assignmentAssesseeInfo?.assesseeAssignmentAssessmentDistinct?.map(
          (item, index) => {
            return (
              <div className="containerPadding" key={index}>
                <ReviewList
                  className=""
                  id={index}
                  tag={item.assesseeAssignmentAssessmentId}
                  isSelectedReviewList={
                    middlePaneSelectedValue === item.assesseeAssignmentAssessmentId
                  }
                  status={
                    item.assesseeAssignmentAssessmentStatus === 'UNFINISHED'
                      ? 'TIMEOUT'
                      : item.assesseeAssignmentAssessmentStatus
                  }
                  actualStatus={item.assesseeAssignmentAssessmentStatus}
                  textOne={item.assesseeAssignmentAssessmentName}
                  textTwo={item.assesseeAssignmentAssessmentDescription}
                  isTooltipActive={false}
                  onClickEvent={openListPopup}
                  isSelectActive={isSelectActive}
                  isSelected={selectedTagsArray.includes(item.assesseeAssignmentAssessmentId)}
                  isDelivery={false}
                  onClickCheckBox={(event) => {
                    onClickCheckBoxSelection(
                      selectedTagsArray,
                      unselectedTagsArray,
                      event,
                      dispatch
                    );
                  }}
                />
              </div>
            );
          }
        )}
      {FilterMode === 'assesseeAssignmentAssessmentactive' && (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={primaryIcon}
          secondaryIcon={
            FilterMode === 'assesseeAssignmentAssessmentactive'
              ? secondaryIconOne
              : secondaryIconTwo
          }
        />
      )}
      {FilterMode === 'assesseeAssignmentAssessmentinactive' && (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={primaryIcon}
          secondaryIcon={
            FilterMode === 'assesseeAssignmentAssessmentactive'
              ? secondaryIconOne
              : secondaryIconTwo
          }
        />
      )}
    </div>
  );
};
export default AssignmentAssesseeAssessmentDistinctReviewList;
