import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    CLEAR_DISPLAY_PANE_THREE,
    FILTERMODE,
    FILTERMODE_ENABLE,
    GET_ALLOCATE_ASSIGNMENT,
    POPUP_OPEN,
    SET_ASSESSEE_GROUP_ASSESSEE_ID_LIST,
    SET_DISPLAY_TWO_SINGLE_STATE,
    SET_MIDDLEPANE_STATE,
    SET_MOBILE_PANE_STATE,
    SET_POPUP_STATE,
    SET_PAGE_COUNT,
    SET_UNSELECTED_ASSESSEE_GROUP_ASSESSEE_ID_LIST,
    GET_ASSIGNMENTTYPE_ASSIGNMENT_REVIEWLIST_SAGA
} from '../actionType';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import { FilterList } from '@material-ui/icons';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import ListLoader from '../Atoms/ListLoader/ListLoader';
import { ASSIGNMNET_GROUP_TYPE_NODE_REVIEW_LIST_POPUP_OPTION } from '../PopUpConfig';
import Card from '../Molecules/Card/Card';
import CrossIcon from '@material-ui/icons/Clear';
import { onClickCheckBoxSelection } from '../Actions/AssesseeModuleAction';
import ReviseIcon from '@material-ui/icons/RadioButtonChecked';
import Check from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import {
    assignmentTypeReviseApiCall,
    getAssignmnetTypeAssignmnetDistinctApiCall
} from '../Actions/AssignmentModuleAction';
import { closeRelatedList, getPopUpOptionList } from '../Actions/GenericActions';

const AssignmentTypeAssignmentDistinctReviewList = (props) => {
    const dispatch = useDispatch();
    const [isShowReviseIcon, setIsShowReviseIcon] = useState(true);
    const {
        middlePaneHeaderBadgeTwo,
        relatedReviewListObjectCopy,
        numberPage,
        scanCount,
        middlePaneHeader,
        middlePaneSelectedValue,
        reviewListDistinctData,
        selectedAssociateInfo,
        relatedReviewListDistinctData,
        middlePaneHeaderBadgeOne,
        middlePaneHeaderDuplicate,
        middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderDuplicateBadgeTwo,
        middlePaneHeaderDuplicateBadgeThree,
        middlePaneHeaderDuplicateBadgeFour,
        signedAssesseePermission,
        typeOfMiddlePaneList,
        isSelectActive,
        isMiddlePaneList,
        selectedTagsArray,
        duplicateScanCount,
        unselectedTagsArray,
        assignmentCreateType,
        isListLoading,
        countPage,
        middlePaneHeaderDuplicateOne,
        middlePaneHeaderDuplicateOneBadgeOne,
        middlePaneHeaderDuplicateOneBadgeTwo,
        middlePaneHeaderDuplicateOneBadgeThree,
        middlePaneHeaderDuplicateOneBadgeFour,
    } = useSelector((state) => state.DisplayPaneTwoReducer);
    const { FilterModeEnable, FilterMode } = useSelector((state) => state.FilterReducer);
    const { assignmentType } = useSelector((state) => state.TypeCreateReducer);
    const { responseObject, reviewMode, createMode, headerOneBadgeOne } = useSelector(
        (state) => state.DisplayPaneThreeReducer
    );
    const { originPrimaryArrOprion } = useSelector((state) => state.PopUpReducer);
    // const [isFetching, setIsFetching] = useState(false);
    //// pagination code here
    // useEffect(() => {
    //   document.getElementById('middleComponentId').addEventListener('scroll', handleScroll);
    // }, []);
    // const handleScroll = (event) => {
    //   var targetPt = event.target;
    //   if (
    //     Math.ceil(targetPt.scrollHeight - targetPt.scrollTop) !== targetPt.clientHeight ||
    //     isFetching
    //   )
    //     return;
    //   setIsFetching(true);
    //   console.log(isFetching);
    // };
    const fetchData = async () => {
        console.log(relatedReviewListDistinctData);
        if (relatedReviewListDistinctData.length > 0) {
            if (relatedReviewListDistinctData[0].assignment.length < scanCount) {
                dispatch({
                    type: SET_DISPLAY_TWO_SINGLE_STATE,
                    payload: { stateName: 'isListLoading', value: true }
                });

                let obj = {
                    ...relatedReviewListObjectCopy.requestObject,
                    numberPage: numberPage + 1
                };
                if (reviewMode === 'revise') {
                    dispatch({
                        type: GET_ALLOCATE_ASSIGNMENT,
                        payload: {
                            existingAssignmentId: selectedTagsArray,
                            request: {
                                module: 'Assignment-Distinct',
                                action: 'Review-List',
                                requestObject: obj
                            },
                            HeaderOne: middlePaneHeader,
                            BadgeOne: 'distinct',
                            BadgeTwo: middlePaneHeaderBadgeTwo,
                            isMiddlePaneList: true,
                            typeOfMiddlePaneList: 'assignmentTypeAssignmentReviewList',
                            revisedGroupObject: {
                                id: responseObject.id,
                                assignmentTypeName: responseObject.informationBasic.assignmentTypeName,
                                assignmentTypeDescription:
                                    responseObject.informationBasic.assignmentTypeDescription,
                                assignmentTypeStatus: responseObject.informationEngagement.assignmentTypeStatus
                            }
                        }
                    });
                } else {
                    dispatch({
                        type: GET_ASSIGNMENTTYPE_ASSIGNMENT_REVIEWLIST_SAGA,
                        payload: {
                            request: {
                                module: 'Assignment-Type',
                                action: 'Review-List',
                                actionFilter: 'Assignment-Type-Assignment',
                                requestObject: obj
                            },
                            HeaderOne: 'assessees',
                            BadgeOne: middlePaneHeaderBadgeOne,
                            BadgeTwo: middlePaneHeaderBadgeTwo,
                            BadgeThree: '',
                            isMiddlePaneList: isMiddlePaneList
                        }
                    });
                }

                dispatch({ type: SET_PAGE_COUNT, payload: numberPage + 1 });
            }
        }
    };

    // useEffect(() => {
    //   if (!isFetching) return;
    //   fetchMoreListItems();
    // }, [isFetching]);

    // const fetchMoreListItems = () => {
    //   fetchData();
    //   setIsFetching(false);
    // };
    //

    const onClickRevise = () => {
        console.log('ON CLICK REVISE ICON');
        setIsShowReviseIcon(false);
    };
    const onClickReviseCancel = () => {
        console.log('ON CLICK cancel ICON');
        setIsShowReviseIcon(true);
        closeRelatedList(
            dispatch,
            createMode,
            responseObject,
            middlePaneHeaderDuplicate,
            middlePaneHeaderDuplicateBadgeOne,
            middlePaneHeaderDuplicateBadgeTwo,
            middlePaneHeaderDuplicateBadgeThree,
            middlePaneHeaderDuplicateBadgeFour,
            'assignmentsTypeDistinctReviewList',
            duplicateScanCount,
            'assignmentsTypeDistinct',
            duplicateScanCount,
            middlePaneHeaderDuplicateOne,
            middlePaneHeaderDuplicateOneBadgeOne,
            middlePaneHeaderDuplicateOneBadgeTwo,
            middlePaneHeaderDuplicateOneBadgeThree,
            middlePaneHeaderDuplicateOneBadgeFour,
        );
        dispatch({
            type: SET_PAGE_COUNT,
            payload: Math.round(parseInt(reviewListDistinctData.length) / countPage) - 1
        });
        if (headerOneBadgeOne !== 'type') {
            dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
        }
        document.getElementById('middleComponentId').scrollTop = '0px';
    };
    const onClickReviseFinish = () => {
        console.log('ON CLICK finish ICON', selectedTagsArray, unselectedTagsArray);
        setIsShowReviseIcon(true);
        dispatch({
            type: FILTERMODE,
            payload: { FilterMode: '' }
        });
        dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'isSelectActive', value: '' }
        });
        dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
        dispatch({ type: SET_ASSESSEE_GROUP_ASSESSEE_ID_LIST, payload: selectedTagsArray });
        dispatch({
            type: SET_UNSELECTED_ASSESSEE_GROUP_ASSESSEE_ID_LIST,
            payload: unselectedTagsArray
        });
        assignmentTypeReviseApiCall(
            responseObject,
            selectedAssociateInfo,
            selectedTagsArray,
            unselectedTagsArray,
            assignmentType,
            dispatch,
            createMode,
            true,
            assignmentCreateType
        );
        closeRelatedList(
            dispatch,
            createMode,
            responseObject,
            middlePaneHeaderDuplicate,
            middlePaneHeaderDuplicateBadgeOne,
            middlePaneHeaderDuplicateBadgeTwo,
            middlePaneHeaderDuplicateBadgeThree,
            middlePaneHeaderDuplicateBadgeFour,
            'assignmentsTypeDistinctReviewList',
            duplicateScanCount,
            'assignmentsTypeDistinct',
            duplicateScanCount,
            middlePaneHeaderDuplicateOne,
            middlePaneHeaderDuplicateOneBadgeOne,
            middlePaneHeaderDuplicateOneBadgeTwo,
            middlePaneHeaderDuplicateOneBadgeThree,
            middlePaneHeaderDuplicateOneBadgeFour,
        );
    };
    const revisePrimaryIcon = [{ label: 'revise', onClick: onClickRevise, Icon: ReviseIcon }];

    const reviseSecondaryIcons = [
        { label: 'cancel', onClick: onClickReviseCancel, Icon: ClearIcon },
        { label: 'finish', onClick: onClickReviseFinish, Icon: Check }
    ];

    const listDistinctData = relatedReviewListDistinctData[0];

    const siftApiCall = (siftKey) => {
        getAssignmnetTypeAssignmnetDistinctApiCall(
            selectedAssociateInfo,
            siftKey,
            countPage,
            dispatch,
            middlePaneHeaderBadgeOne,
            listDistinctData.id,
            '',
            false,
            assignmentCreateType
        );
        document.getElementById('middleComponentId').scrollTop = '0px';
    };
    const onClickFooter = (e) => {
        let siftValue = e.currentTarget.getAttribute('data-value');
        if (
            siftValue === 'suspended' ||
            siftValue === 'terminated' ||
            siftValue === 'published' ||
            siftValue === 'unpublished' ||
            siftValue === 'administered'
        )
            siftApiCall(siftValue);
        dispatch({ type: FILTERMODE_ENABLE });
    };
    /* for middle pane */
    const primaryIcon = [{ label: 'sift', onClick: onClickFooter, Icon: FilterList }];
    const activesecondaryIcon = [
        { label: 'administered', onClick: onClickFooter, Icon: FilterList },
        { label: 'published', onClick: onClickFooter, Icon: FilterList }
    ];
    const secondaryIcon = [
        { label: 'suspended', onClick: onClickFooter, Icon: FilterList },
        { label: 'terminated', onClick: onClickFooter, Icon: FilterList },
        { label: 'unpublished', onClick: onClickFooter, Icon: FilterList }
    ];

    const openListPopup = (e) => {
        console.log(e.currentTarget.getAttribute('tag'));
        let tempArr = [];
        let stats = e.currentTarget.getAttribute('status');
        let isShared = e.currentTarget.getAttribute('data-shared') === 'true' && true;
        let associateId = e.currentTarget.getAttribute('associateid');
        let signedAssociateId =
            selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary;

        let assigmentDistictPermission =
            signedAssesseePermission?.assigmentDistictPermission?.assesseeAssignmentDistinctPermission;
        let popupContentArrValue = getPopUpOptionList(
            ASSIGNMNET_GROUP_TYPE_NODE_REVIEW_LIST_POPUP_OPTION,
            assigmentDistictPermission
        );
        popupContentArrValue.forEach((element) => {
            if (stats === 'UNPUBLISHED' && element.data === 'share') {
                tempArr.push({ ...element, disabled: true });
            } else if (
                (stats === 'ARCHIVED' || stats === 'TERMINATED' || stats === 'SUSPENDED') &&
                (element.data === 'allocate' ||
                    element.data === 'revise' ||
                    element.data === 'select' ||
                    element.data === 'share')
            ) {
                tempArr.push({ ...element, disabled: true });
            } else if (
                isShared &&
                associateId !== signedAssociateId &&
                (element.data === 'revise' ||
                    element.data === 'archive' ||
                    element.data === 'flag' ||
                    element.data === 'delete' ||
                    element.data === 'suspend' ||
                    element.data === 'move' ||
                    element.data === 'terminate')
            )
                tempArr.push({ ...element, disabled: true });
            else tempArr.push(element);
        });
        dispatch({
            type: SET_POPUP_STATE,
            payload: {
                popupHeaderOne: 'assignment',
                popupHeaderOneBadgeOne: middlePaneHeaderBadgeOne,
                isPopUpValue: '',
                popupOpenType: 'primary',
                popupContentArrValue: tempArr,
                isShared: isShared,
                originPrimaryArrOprion: originPrimaryArrOprion,
                selectedTagValue: e.currentTarget.getAttribute('tag'),
                selectedTagStatus: e.currentTarget.getAttribute('status')
            }
        });
        dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
        dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: {
                stateName: 'middlePaneListPopupOptions',
                value: tempArr
            }
        });
    };

    // infinite scroll
    const [hasMore, setHasMore] = useState(true);
    let elementRef = useRef(null);

    const onIntersection = (entries) => {
        const firstEntry = entries[0];
        listDistinctData.assignment.length >= scanCount ? setHasMore(false) : setHasMore(true);
        if (firstEntry.isIntersecting && hasMore && !isListLoading) {
            fetchData();
        }
    };

    useEffect(() => {
        // console.log('####MYREF######', elementRef)
        const observer = new IntersectionObserver(onIntersection, { threshold: 0.90 });

        if (observer && elementRef.current) {
            // console.log('here at observer...', elementRef.current)
            observer.observe(elementRef.current);
        }

        return () => {
            if (observer) observer.disconnect();
        };
    }, [
        isListLoading,
        hasMore,
        listDistinctData,
        selectedTagsArray,
        unselectedTagsArray,
        isSelectActive
    ]);

    // infinite scroll end

    return (
        <div>
            {listDistinctData && (
                <Card
                    textOneOne={listDistinctData.assignmentTypeName}
                    textTwoOne={listDistinctData.assignmentTypeDescription}
                    IconOne={CrossIcon}
                    isIcon={true}
                    labelTwoTwo={'type'}
                    onClickIconOne={onClickReviseCancel}
                    isAlliance
                    relatedCardFixed={true}
                    className={'iguru-iconboxSVG'}
                />
            )}
            {listDistinctData &&
                listDistinctData.assignment.map((item, index) => {
                    return (
                        <div className="containerPadding" key={index} ref={elementRef}>
                            <ReviewList
                                className=""
                                id={index}
                                tag={item.id}
                                associateId={item?.associateId}
                                isSelectedReviewList={middlePaneSelectedValue === item.id}
                                status={item.informationEngagement.assignmentStatus}
                                actualStatus={item.informationEngagement.assignmentStatus}
                                isShared={item?.assignmentShared}
                                textOne={item.informationBasic.assignmentName}
                                textTwo={item.informationBasic.assignmentDescription}
                                isTooltipActive={false}
                                // onClickEvent={openListPopup}
                                isSelectActive={isSelectActive}
                                isSelected={selectedTagsArray.includes(item.id)}
                                onClickCheckBox={(event) => {
                                    !isListLoading &&
                                        onClickCheckBoxSelection(
                                            selectedTagsArray,
                                            unselectedTagsArray,
                                            event,
                                            dispatch
                                        );
                                }}
                            />
                        </div>
                    );
                })}
            {isListLoading ? (
                <div>
                    {' '}
                    <ListLoader />{' '}
                </div>
            ) : <div style={{ height: "60px", width: "100%" }}></div>}
            {/* {FilterMode === 'assignmentTypeAssignmentRevise' && (
                <FooterIconTwo
                    FilterModeEnable={isShowReviseIcon}
                    FilterMode={FilterMode}
                    onClick={onClickRevise}
                    primaryIcon={revisePrimaryIcon}
                    secondaryIcon={reviseSecondaryIcons}
                />
            )}
            {FilterMode === 'assignmentTypeAssignmentinactive' && (
                <FooterIconTwo
                    FilterModeEnable={FilterModeEnable}
                    FilterMode={FilterMode}
                    onClick={onClickFooter}
                    primaryIcon={primaryIcon}
                    secondaryIcon={secondaryIcon}
                />
            )}
            {FilterMode === 'assignmentTypeAssignmentactive' && (
                <FooterIconTwo
                    FilterModeEnable={FilterModeEnable}
                    FilterMode={FilterMode}
                    onClick={onClickFooter}
                    primaryIcon={primaryIcon}
                    secondaryIcon={activesecondaryIcon}
                />
            )} */}
        </div>
    );
};
export default AssignmentTypeAssignmentDistinctReviewList;
