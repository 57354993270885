import { put, takeLatest, call } from 'redux-saga/effects';
import {
  GET_ASSOCIATES_NODE_REVIEW_LIST_SAGA,
  INTERNAL_NODE_LIST_SAGA,
  INTERNAL_NODE_LIST_WITH_PERMISSIONS_SAGA,
  LOADER_START,
  LOADER_STOP,
  REVIEWLIST_DISTINCT_DATA,
  SET_CORE_NODE_REVIEW_LIST_DATA,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MIDDLEPANE_STATE,
  SET_POPUP_SINGLE_STATE,
  SET_POPUP_VALUE
} from '../../actionType';
import { ASSOCIATE_MODULE_URL } from '../../endpoints';
import { EXCEPTION_ERROR_MESSAGE } from '../../errorMessage';
import Store from '../../store';

const nodeReviewListDistinctApi = async (requestObj) => {
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      Authorization: localStorage.getItem('token')
    }),
    body: JSON.stringify(requestObj.data)
  };
  const response = await fetch(requestObj.URL, requestOptions);
  const json = await response.json();
  return json;
};

function* workerReviewAssociatesNodeListSaga(data) {
  console.log(data.payload);
  try {
    const userResponse = yield call(nodeReviewListDistinctApi, {
      data: data.payload.request,
      URL: ASSOCIATE_MODULE_URL
      // data.payload.nodeViewState === 'hierarchy' ? EXTERNAL_NODE_TREE_URL : EXTERNAL_NODE_LIST_URL
    });
    const { isMiddlePaneList, isAllAssociates = false } = data.payload;
    yield put({ type: LOADER_START });
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === '000') {
      let reviseList = [];
      let listData = [];
      let scanCount = 0;
      let scanString = data.payload?.scanString?.toLowerCase();
      if (data.payload.isMiddlePaneList) {
        if (scanString && data.payload.nodeViewState === 'list') {
          listData = userResponse.responseObject[0]?.associateDescendantAll?.filter(function (
            value
          ) {
            let name = value.informationBasic.associateName.toLowerCase();
            let desc = value.informationBasic.associateDescription.toLowerCase();
            let tag = value?.id?.toLowerCase();
            return (
              name.includes(scanString) || desc.includes(scanString) || tag.includes(scanString)
            );
          });
          reviseList = [
            {
              associateDescendantAll: listData,
              associateRoot: userResponse.responseObject[0].associateRoot
            }
          ];
          scanCount = listData?.length || 0 + 1;
        } else {
          reviseList = userResponse.responseObject;
          scanCount = userResponse.countTotal;
        }
        console.log('reviseList', reviseList);
        if (data.payload.nodeViewState === 'list' && reviseList.length > 0) {
          reviseList[0]?.associateDescendantAll?.sort((a, b) =>
            a.informationBasic.associateName
              .toLowerCase()
              .localeCompare(b.informationBasic.associateName.toLowerCase())
          );
        }
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: 'associates',
            middlePaneHeaderBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderBadgeThree: data.payload.BadgeThree,
            middlePaneHeaderBadgeFour: '',
            typeOfMiddlePaneList: 'associatesNodeDistinctReviewList',
            scanCount: scanCount,
            showMiddlePaneState: true,
            isSelectActive: data.payload.isSelectActive,
            selectedTagsArray: data.payload.selectedTagsArray
          }
        });
      } else if (!isMiddlePaneList && isAllAssociates) {
        let temArr = [
          ...(userResponse.responseObject[0]?.associatesAscendantAll || []),
          ...(userResponse.responseObject[0]?.associatesDescendantAll || []),
          ...(userResponse.responseObject[0]?.associatesPeerAll || [])
        ];
        reviseList = temArr.sort((a, b) =>
          a.informationBasic.associateName
            .toLowerCase()
            .localeCompare(b.informationBasic.associateName.toLowerCase())
        );
      } else {
        reviseList = [...userResponse.responseObject[0]?.associateDescendantAll];
      }
      console.log('reviseListData', reviseList);
      yield put({
        type: data.payload.isMiddlePaneList
          ? REVIEWLIST_DISTINCT_DATA
          : SET_CORE_NODE_REVIEW_LIST_DATA,
        payload: reviseList
      });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }

    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerReviewInternalNodeListSaga(data) {
  console.log('000000', data.payload);
  try {
    const userResponse = yield call(nodeReviewListDistinctApi, {
      data:
        (data.payload.nodeViewState === 'hierarchy' && {
          ...data.payload.request,
          actionFilter: 'Associate-Node-Hierarchy-List'
          // actionFilter: 'Associate-Node-Hierarchy'
        }) ||
        data.payload.request,
      URL: ASSOCIATE_MODULE_URL
      // data.payload.nodeViewState === 'hierarchy' ? INTERNAL_NODE_TREE_URL : INTERNAL_NODE_LIST_URL
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === '000') {
      // yield put({
      //   type: SET_DISPLAY_TWO_SINGLE_STATE,
      //   payload: {
      //     stateName: 'reviewListDistinctData',
      //     value: []
      //   }
      // });
      let scanString = data.payload.scanValue;
      let listData = [];
      let reviseList = [];
      let tempVal = true;
      let scanCount = 0;
      if (data.payload.isMiddlePaneList) {
        if (scanString && data.payload.nodeViewState === 'list') {
          listData = userResponse.responseObject[0].associateNodeDescendantAll.filter(function (
            value
          ) {
            let name = value.associateNode.informationBasic.associateNodeName.toLowerCase();
            let desc = value.associateNode.informationBasic.associateNodeDescription.toLowerCase();
            return name.includes(scanString) || desc.includes(scanString);
          });
          reviseList = [
            {
              associateNodeDescendantAll: listData,
              associateNodeRoot: userResponse.responseObject[0].associateNodeRoot
            }
          ];
          scanCount = listData.length;
        } else {
          reviseList = userResponse.responseObject;
          scanCount = userResponse.countTotal;
        }
        console.log('reviseList', reviseList);
        if (data.payload.nodeViewState === 'list' && reviseList.length > 0) {
          let reviewListData = reviseList[0].associateNodeDescendantAll;
          tempVal =
            reviewListData.length > 0 ? reviewListData[0].hasOwnProperty('associateNode') : false;
          console.log('tempVal---', tempVal);
          tempVal
            ? reviseList[0].associateNodeDescendantAll.sort((a, b) =>
                a.associateNode.informationBasic.associateNodeName
                  .toLowerCase()
                  .localeCompare(b.associateNode.informationBasic.associateNodeName.toLowerCase())
              )
            : reviseList[0].associateNodeDescendantAll.sort((a, b) =>
                a.informationBasic.associateNodeName
                  .toLowerCase()
                  .localeCompare(b.informationBasic.associateNodeName.toLowerCase())
              );
        }
        yield put({
          type: REVIEWLIST_DISTINCT_DATA,
          payload: reviseList
        });
      } else {
        let Arr1 = userResponse.responseObject[0].associateNodeRoot;
        let Arr2 = userResponse.responseObject[0]?.associateNodeDescendantAll || [];
        Arr2.unshift(Arr1);
        yield put({ type: SET_CORE_NODE_REVIEW_LIST_DATA, payload: Arr2 });
      }
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: data.payload.paneHeader,
            middlePaneHeaderBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderBadgeTwo: Store.getState()
              .FilterReducer.FilterMode.toLowerCase()
              .includes('allocate')
              ? 'active'
              : data.payload.BadgeTwo,
            middlePaneHeaderBadgeThree: data.payload.BadgeThree,
            middlePaneHeaderBadgeFour: '',
            middlePaneHeaderDuplicate: data.payload.paneHeader,
            middlePaneHeaderDuplicateBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderDuplicateBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderDuplicateBadgeThree: data.payload.BadgeThree,
            typeOfMiddlePaneList: tempVal
              ? 'associateNodeDistinctReviewList'
              : 'associateNodeAllocateDistinctReviewList',
            scanCount: scanCount,
            showMiddlePaneState: true,
            isSelectActive: data.payload.isSelectActive,
            selectedTagsArray: data.payload.selectedTagsArray,
            duplicateScanCount: scanCount
          }
        });
        if (data.payload.cardValue) {
          yield put({
            type: SET_POPUP_SINGLE_STATE,
            payload: { stateName: 'cardValue', value: data.payload.cardValue }
          });
        }
      }
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }

    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}

function* workerReviewInternalNodeListWithPermissionsSaga(data) {
  try {
    yield put({ type: LOADER_START });
    const userResponse = yield call(nodeReviewListDistinctApi, {
      data: data.payload.request,
      URL: ASSOCIATE_MODULE_URL
    });

    if (userResponse.responseCode === '000') {
      let scanString = data.payload.scanValue;
      let listData = [];
      let reviseList = [];
      let scanCount = 0;
      if (data.payload.isMiddlePaneList) {
        if (scanString && data.payload.nodeViewState === 'list') {
          listData = userResponse.responseObject[0].associateNodeDescendantAll.filter(function (
            value
          ) {
            let name = value.associateNode.informationBasic.associateNodeName.toLowerCase();
            let desc = value.associateNode.informationBasic.associateNodeDescription.toLowerCase();
            return name.includes(scanString) || desc.includes(scanString);
          });
          reviseList = [
            {
              associateNodeDescendantAll: listData,
              associateNodeRoot: userResponse.responseObject[0].associateNodeRoot
            }
          ];
          scanCount = listData.length;
        } else {
          reviseList = [...userResponse.responseObject];
          scanCount = userResponse.countTotal;
        }
        console.log('reviseList', reviseList);
        if (data.payload.nodeViewState === 'list' && reviseList.length > 0) {
          reviseList[0].associateNodeDescendantAll.sort((a, b) =>
            a.associateNode.informationBasic.associateNodeName
              .toLowerCase()
              .localeCompare(b.associateNode.informationBasic.associateNodeName.toLowerCase())
          );
        }
        yield put({
          type: REVIEWLIST_DISTINCT_DATA,
          payload: reviseList
        });
      } else {
        let popupList = userResponse.responseObject.sort((a, b) =>
          a.informationBasic.associateNodeName
            .toLowerCase()
            .localeCompare(b.informationBasic.associateNodeName.toLowerCase())
        );
        yield put({
          type: SET_CORE_NODE_REVIEW_LIST_DATA,
          payload: [...popupList]
        });
      }
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: data.payload.paneHeader,
            middlePaneHeaderBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderBadgeTwo: Store.getState()
              .FilterReducer.FilterMode.toLowerCase()
              .includes('allocate')
              ? 'active'
              : data.payload.BadgeTwo,
            middlePaneHeaderBadgeThree: data.payload.BadgeThree,
            middlePaneHeaderBadgeFour: '',
            middlePaneHeaderDuplicate: data.payload.paneHeader,
            middlePaneHeaderDuplicateBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderDuplicateBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderDuplicateBadgeThree: data.payload.BadgeThree,
            typeOfMiddlePaneList: 'associateNodeDistinctReviewList',
            scanCount: scanCount,
            showMiddlePaneState: true,
            isSelectActive: data.payload.isSelectActive,
            selectedTagsArray: data.payload.selectedTagsArray,
            duplicateScanCount: scanCount
          }
        });

        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: "previousMiddlePaneState", value: 'associateNodeDistinctReviewList',
          },
        })

        if (data.payload.cardValue) {
          yield put({
            type: SET_POPUP_SINGLE_STATE,
            payload: { stateName: 'cardValue', value: data.payload.cardValue }
          });
        }
      }
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }

    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}

export default function* watchReviewAssociatesNodeListSaga() {
  yield takeLatest(GET_ASSOCIATES_NODE_REVIEW_LIST_SAGA, workerReviewAssociatesNodeListSaga);
  yield takeLatest(INTERNAL_NODE_LIST_SAGA, workerReviewInternalNodeListSaga);
  yield takeLatest(
    INTERNAL_NODE_LIST_WITH_PERMISSIONS_SAGA,
    workerReviewInternalNodeListWithPermissionsSaga
  );
}
