import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CLEAR_DISPLAY_PANE_THREE,
  FILTERMODE_ENABLE,
  POPUP_OPEN,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MIDDLEPANE_STATE,
  SET_POPUP_STATE,
  RELATED_REVIEWLIST_DISTINCT_DATA,
  GET_ASSESSEE_ASSIGNMENT_SAGA,
  LOADER_START,
  SET_PAGE_COUNT,
  SET_RELATED_REQUEST_OBJECT
} from '../actionType';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import { FilterList } from '@material-ui/icons';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import { ASSESSEE_ASSESSEE_ASSIGNMENT_REVIEW_LIST_POPUP_OPTION } from '../PopUpConfig';
import Card from '../Molecules/Card/Card';
import CrossIcon from '@material-ui/icons/Clear';
import {
  assesseeAssignmentReviewListApi,
  onClickCheckBoxSelection
} from '../Actions/AssesseeModuleAction';
import { closeRelatedList, getPopUpOptionList } from '../Actions/GenericActions';

const AssesseeGroupAssesseeAssignmentDistinctReviewList = (props) => {
  const dispatch = useDispatch();
  const { FilterModeEnable, FilterMode } = useSelector((state) => state.FilterReducer);

  const {
    middlePaneSelectedValue,
    reviewListDistinctData,
    selectedAssociateInfo,
    relatedReviewListDistinctData,
    middlePaneHeaderBadgeOne,
    middlePaneHeaderBadgeTwo,
    middlePaneHeaderBadgeThree,
    selectedTagsArray,
    unselectedTagsArray,
    isSelectActive,
    middlePaneHeaderDuplicate,
    middlePaneHeaderDuplicateBadgeOne,
    middlePaneHeaderDuplicateBadgeTwo,
    middlePaneHeaderDuplicateBadgeThree,
    middlePaneHeaderDuplicateBadgeFour,
    middlePaneHeaderDuplicateOne,
    middlePaneHeaderDuplicateOneBadgeOne,
    middlePaneHeaderDuplicateOneBadgeTwo,
    middlePaneHeaderDuplicateOneBadgeThree,
    middlePaneHeaderDuplicateOneBadgeFour,
    typeOfMiddlePaneList,
    signedAssesseePermission,
    countPage,
    duplicateScanCount,
    duplicateScanCountOne
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { createMode, responseObject } = useSelector((state) => state.DisplayPaneThreeReducer);
  const { selectedTagValue } = useSelector((state) => state.PopUpReducer);
  const closeRelatedListTwo = () => {

    closeRelatedList(
      dispatch,
      createMode,
      responseObject,
      middlePaneHeaderDuplicate,
      middlePaneHeaderDuplicateBadgeOne,
      middlePaneHeaderDuplicateBadgeTwo,
      middlePaneHeaderDuplicateBadgeThree,
      middlePaneHeaderDuplicateBadgeFour,
      'assesseesGroupAssesseeReviewList',
      duplicateScanCount,
      'assesseeGroupAssesseeDistinct',
      duplicateScanCountOne,
      middlePaneHeaderDuplicateOne,
      middlePaneHeaderDuplicateOneBadgeOne,
      middlePaneHeaderDuplicateOneBadgeTwo,
      middlePaneHeaderDuplicateOneBadgeThree,
      middlePaneHeaderDuplicateOneBadgeFour,
      duplicateScanCountOne
    );
    // dispatch({
    //   type: SET_MIDDLEPANE_STATE,
    //   payload: {
    //     middlePaneHeader: "assessees",
    //     middlePaneHeaderBadgeOne: "distinct",
    //     middlePaneHeaderBadgeTwo: "active",
    //     middlePaneHeaderDuplicate: "assessees",
    //     middlePaneHeaderDuplicateBadgeOne: middlePaneHeaderDuplicateBadgeOne,
    //     middlePaneHeaderDuplicateBadgeTwo: middlePaneHeaderDuplicateBadgeTwo,
    //     middlePaneHeaderDuplicateBadgeThree:
    //       middlePaneHeaderDuplicateBadgeThree,
    //     middlePaneHeaderDuplicateBadgeFour: middlePaneHeaderDuplicateBadgeFour,
    //     typeOfMiddlePaneList: "assesseesGroupAssesseeReviewList",
    //     scanCount:
    //       relatedReviewListDistinctData[0]?.assesseeInfo?.assessee?.length,
    //     duplicateScanCount:duplicateScanCount,
    //     showMiddlePaneState: true,
    //   },
    // });
    // dispatch({
    //   type: SET_DISPLAY_TWO_SINGLE_STATE,
    //   payload: {
    //     stateName: "relatedReviewListDistinctData",
    //     value: [relatedReviewListDistinctData[0]?.assesseeInfo],
    //   },
    // });
    // dispatch({ type: RELATED_REVIEWLIST_DISTINCT_DATA, payload: [] });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'isListLoading', value: false }
    });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  };
  const closeRelatedListOne = () => {
    // dispatch({
    //   type: SET_MIDDLEPANE_STATE,
    //   payload: {
    //     middlePaneHeader: "assessees",
    //     middlePaneHeaderBadgeOne: middlePaneHeaderDuplicateBadgeOne,
    //     middlePaneHeaderBadgeTwo: middlePaneHeaderDuplicateBadgeTwo,
    //     typeOfMiddlePaneList: "assesseesGroupDistinctReviewList",
    //     scanCount: reviewListDistinctData.length,
    //     showMiddlePaneState: true,
    //   },
    // });
    // dispatch({ type: RELATED_REVIEWLIST_DISTINCT_DATA, payload: [] });
    closeRelatedList(
      dispatch,
      createMode,
      responseObject,
      middlePaneHeaderDuplicateOne,
      middlePaneHeaderDuplicateOneBadgeOne,
      middlePaneHeaderDuplicateOneBadgeTwo,
      middlePaneHeaderDuplicateOneBadgeThree,
      middlePaneHeaderDuplicateOneBadgeFour,
      'assesseesGroupDistinctReviewList',
      duplicateScanCountOne,
      'assesseesGroupDistinct',
      duplicateScanCountOne,
      middlePaneHeaderDuplicateOne,
      middlePaneHeaderDuplicateOneBadgeOne,
      middlePaneHeaderDuplicateOneBadgeTwo,
      middlePaneHeaderDuplicateOneBadgeThree,
      middlePaneHeaderDuplicateOneBadgeFour,
      duplicateScanCountOne
    );
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  };

  const siftApiCall = (siftValue) => {
    //   let statusArr = [siftValue.toUpperCase()];
    //   let reqBody = {
    //     module: "Assessee-Assignment",
    //     action: "Review-List",
    //     requestObject: {
    //       assesseeId: selectedTagValue,
    //       associateId:
    //         selectedAssociateInfo?.associate?.informationEngagement.associateTag
    //           .associateTagPrimary,
    //       countPage: countPage,
    //       numberPage: 0,
    //       filter: true,
    //       orderBy: {
    //         columnName: "",
    //         order: "asc",
    //       },
    //       searchCondition: "AND",
    //       search: [
    //         {
    //           condition: "and",
    //           searchBy: [
    //             {
    //               dataType: "string",
    //               conditionColumn: "assesseeAssignmentStatus",
    //               conditionValue: {
    //                 condition: "in",
    //                 value: {
    //                   in: statusArr,
    //                 },
    //               },
    //             },
    //           ],
    //         },
    //       ],
    //     },
    //   };
    //   dispatch({
    //     type: SET_DISPLAY_TWO_SINGLE_STATE,
    //     payload: { stateName: "typeOfMiddlePaneList", value: "" },
    //   });
    //   dispatch({ type: SET_RELATED_REQUEST_OBJECT, payload: reqBody });
    //   dispatch({ type: SET_PAGE_COUNT, payload: 0 });
    //   dispatch({ type: LOADER_START });
    //   dispatch({
    //     type: GET_ASSESSEE_ASSIGNMENT_SAGA,
    //     payload: {
    //       request: reqBody,
    //       BadgeOne: middlePaneHeaderBadgeOne,
    //       typeOfMiddlePaneList,
    //       BadgeTwo: siftValue,
    //       BadgeThree: middlePaneHeaderBadgeThree,
    //       relatedListCall: "assesseeDistinct",
    //       responseObj: {
    //         assesseeId: relatedReviewListDistinctData[0].assesseeId,
    //         assesseeInfo: relatedReviewListDistinctData[0].assesseeInfo,
    //       },
    //     },
    //   });
    let slectedGroupObj = reviewListDistinctData?.filter(
      (x) => x.id === relatedReviewListDistinctData[0].id
    );
    console.log('slectedGroupObj', slectedGroupObj);

    let responseObj = relatedReviewListDistinctData[0]?.assessee.filter(
      (x) => x.id === selectedTagValue
    );
    let relatedObj = {
      ...relatedReviewListDistinctData[0],
      assesseeInfo: responseObj[0].informationBasic,
      assesseeGroupInfo: slectedGroupObj[0].informationBasic
    };
    assesseeAssignmentReviewListApi(
      selectedAssociateInfo,
      listDistinctData?.assessee[0]?.id,
      dispatch,
      responseObj[0].id,
      relatedObj,
      siftValue,
      '',
      'assesseeGroupAssesseeAssignmentDistinct'
    );
    dispatch({ type: FILTERMODE_ENABLE });
    // document.getElementById('middleComponentId').scrollTop = '0px';
  };
  const onClickFooter = (e) => {
    let siftValue = e.currentTarget.getAttribute('data-value');
    if (
      siftValue === 'suspended' ||
      siftValue === 'terminated' ||
      siftValue === 'started' ||
      siftValue === 'unstarted' ||
      siftValue === 'aborted' ||
      siftValue === 'finished' ||
      siftValue === 'unfinished'
    )
      siftApiCall(siftValue);
    dispatch({ type: FILTERMODE_ENABLE });
  };
  const primaryIcon = [{ label: 'sift', onClick: onClickFooter, Icon: FilterList }];
  const secondaryIcon = [
    { label: 'started', onClick: onClickFooter, Icon: FilterList },
    { label: 'unstarted', onClick: onClickFooter, Icon: FilterList }
  ];

  const secondaryIconOne = [
    { label: 'aborted', onClick: onClickFooter, Icon: FilterList },
    { label: 'finished', onClick: onClickFooter, Icon: FilterList },
    { label: 'suspended', onClick: onClickFooter, Icon: FilterList },
    { label: 'terminated', onClick: onClickFooter, Icon: FilterList },
    { label: 'unfinished', onClick: onClickFooter, Icon: FilterList }
  ];

  //const listDistinctData = relatedReviewListDistinctData[0];
  const openListPopup = (e) => {
    console.log(e.currentTarget.getAttribute('tag'));
    let status = e.currentTarget.getAttribute('status');
    let tempArr = [];
    let assesseeDistinctPermission =
      signedAssesseePermission?.assesseeDistinctPermission?.assesseeAssesseeDistinctPermission;
    let popupContentArr = getPopUpOptionList(
      ASSESSEE_ASSESSEE_ASSIGNMENT_REVIEW_LIST_POPUP_OPTION,
      assesseeDistinctPermission
    );
    popupContentArr.forEach((element) => {
      if (
        element.data === 'revise' ||
        element.data === 'share' ||
        element.data === 'allocate' ||
        element.data === 'archive' ||
        element.data === 'delete' ||
        element.data === 'flag' ||
        element.data === 'select' ||
        element.data === 'review' ||
        element.data === 'suspend' ||
        element.data === 'terminate'
      ) {
        tempArr.push({ ...element, disabled: true });
      } else if (element.data === 'analytics' && status !== 'FINISHED') {
        tempArr.push({ ...element, disabled: true });
      } else {
        tempArr.push(element);
      }
    });
    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: 'assessment',
        popupHeaderOneBadgeOne: middlePaneHeaderBadgeOne,
        popupHeaderOneBadgeTwo: '',
        isPopUpValue: '',
        popupOpenType: 'primary',
        popupContentArrValue: tempArr,
        selectedTagValue: e.currentTarget.getAttribute('tag'),
        selectedTagStatus: e.currentTarget.getAttribute('status')
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: tempArr
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
  };
  // const listDistinctData = reviewListDistinctData.filter((x) => x.id === selectedTagValue);
  const listDistinctData = relatedReviewListDistinctData[0];
  console.log('FilterMode', FilterMode);
  // console.log("relatedReviewListDistinctData", relatedReviewListDistinctData);
  console.log('listDistinctData', listDistinctData);
  console.log('listDistinctData?.assessee[0]', listDistinctData);

  return (
    <div>
      {listDistinctData && (
        <Fragment>
          <Card
            textOneOne={listDistinctData?.assesseeGroupName}
            textTwoOne={listDistinctData?.assesseeGroupDescription}
            IconOne={CrossIcon}
            isIcon={true}
            labelTwoTwo={'group'}
            onClickIconOne={closeRelatedListOne}
            isAlliance
            relatedCardFixed={true}
            className={'iguru-iconboxSVG'}
          />
          <Card
            textOneOne={
              listDistinctData?.assessee[listDistinctData.index]?.informationBasic?.assesseeNameFirst +
              ' ' +
              listDistinctData?.assessee[listDistinctData.index]?.informationBasic?.assesseeNameOther +
              ' ' +
              listDistinctData?.assessee[listDistinctData.index]?.informationBasic?.assesseeNameLast +
              ' ' +
              listDistinctData?.assessee[listDistinctData.index]?.informationBasic?.assesseeNameSuffix
            }
            textTwoOne={listDistinctData?.assessee[listDistinctData.index]?.informationBasic?.assesseeAlias}
            IconOne={CrossIcon}
            isIcon={true}
            labelTwoTwo={'assessee'}
            // onClickIconOne={closeRelatedListTwo}
            onClickIconOne={closeRelatedListOne}
            isAlliance
            relatedCardFixed={true}
            className={'iguru-iconboxSVG'}
          />
        </Fragment>
      )}
      {listDistinctData?.assesseeAssignment?.length > 0 &&
        listDistinctData?.assesseeAssignment?.map((item, index) => {
          return (
            <div className="containerPadding" key={index}>
              <ReviewList
                className=""
                id={index}
                tag={item.assesseeAssignmentId}
                isSelectedReviewList={middlePaneSelectedValue === item.assesseeAssignmentId}
                associateId={item?.associateId}
                status={item.assesseeAssignmentStatus}
                actualStatus={item.assesseeAssignmentStatus}
                textOne={item.assesseeAssignmentName}
                textTwo={item.assesseeAssignmentDescription}
                isTooltipActive={false}
                onClickEvent={openListPopup}
                isSelectActive={isSelectActive}
                isSelected={selectedTagsArray.includes(item.assesseeAssignmentId)}
                isDelivery={false}
                onClickCheckBox={(event) => {
                  onClickCheckBoxSelection(selectedTagsArray, unselectedTagsArray, event, dispatch);
                }}
              />
            </div>
          );
        })}
      {/* {FilterMode === 'assesseeAssignmentactive' && (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={primaryIcon}
          secondaryIcon={secondaryIcon}
        />
      )} */}

      {(FilterMode === 'assesseeGroupAssesseeAssignmentactive' ||
        FilterMode === 'assesseeGroupAssesseeAssignmentstarted' ||
        FilterMode === 'assesseeGroupAssesseeAssignmentunstarted') && (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={primaryIcon}
          secondaryIcon={secondaryIcon}
        />
      )}

      {(FilterMode === 'assesseeGroupAssesseeAssignmentinactive' ||
        FilterMode === 'assesseeGroupAssesseeAssignmentterminated' ||
        FilterMode === 'assesseeGroupAssesseeAssignmentaborted' ||
        FilterMode === 'assesseeGroupAssesseeAssignmentunfinished' ||
        FilterMode === 'assesseeGroupAssesseeAssignmentfinished' ||
        FilterMode === 'assesseeGroupAssesseeAssignmentsuspended') && (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={primaryIcon}
          secondaryIcon={secondaryIconOne}
        />
      )}
    </div>
  );
};
export default AssesseeGroupAssesseeAssignmentDistinctReviewList;
