import { put, takeLatest, call } from 'redux-saga/effects';
import {
  SET_DISPLAY_PANE_THREE_STATE,
  LOADER_STOP,
  SET_CULTURE_REDUCER_STATE,
  GET_CULTURE_PROFILE_INFO_SAGA,
  CULTURE_PROFILE_INFO_REVISE_SAGA,
  SET_CULTURE_DYNAMIC_SINGLE_STATE,
  SET_POPUP_VALUE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  GET_CULTUREPROFILE_REVIEW_LIST_SAGA,
  SET_CULTURE_DIMENTION_STATE,
  SET_WEIGHTAGE_SELECTED,
  CULTURE_ASSESSMENTS_REVIEWLIST_SAGA,
  CULTURE_PROFILE_MULTI_STATUS_REVISE_SAGA,
  GET_CULTUREPROFILE_GROUP_REVIEW_LIST_SAGA,
  GET_CULTUREPROFILE_TYPE_REVIEW_LIST_SAGA,
  CULTURE_PROFILE_PUBLISHED_SAGA,
  CULTURE_GROUP_CULTURE_REVIEWLIST_SAGA,
  LOADER_START,
  CULTURE_TYPE_CULTURE_REVIEWLIST_SAGA,
  SET_PAGE_COUNT,
  SET_POPUP_SINGLE_STATE,
  SET_CULTURE_SETUP_INFO_STATE
} from '../../actionType';
import { CULTURE_MODULE_URL } from '../../endpoints';
import { EXCEPTION_ERROR_MESSAGE } from '../../errorMessage';
import { CULTURE_REVIEW_LIST_POPUP_OPTION } from '../../PopUpConfig';
import Store from '../../store';

const cultureProfileInfoApi = async (requestObj) => {
  console.log(requestObj.data);
  let URL = CULTURE_MODULE_URL;
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      Authorization: localStorage.getItem('idToken')
    }),
    body: JSON.stringify(requestObj.data)
  };
  const response = await fetch(URL, requestOptions);
  const json = await response.json();
  return json;
};

function* workerReviewInfoCultureProfileSaga(data) {
  try {
    const userResponse = yield call(cultureProfileInfoApi, {
      data: data.payload.reqBody
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === '000') {
      const { createMode = '', isReviseMode = false, cultureAssessmentReqBody } = data.payload;
      yield put({
        type: SET_DISPLAY_PANE_THREE_STATE,
        payload: {
          headerOne: 'culture-profile',
          headerOneBadgeOne: 'distinct',
          headerOneBadgeTwo: 'information',
          headerOneBadgeThree: data.payload.secondaryOptionCheckValue,
          responseObject: userResponse.responseObject[0],
          reviewMode: isReviseMode ? 'revise' : '',
          createMode
        }
      });
      yield put({
        type: CULTURE_ASSESSMENTS_REVIEWLIST_SAGA,
        payload: {
          request: cultureAssessmentReqBody,
          HeaderOne: 'assessments',
          BadgeOne: '',
          BadgeTwo: '',
          BadgeThree: '',
          isMiddlePaneList: false
        }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: {
          stateName: 'responseObject',
          value: userResponse.responseObject[0]
        }
      });
      // if (isReviseMode) {
      const { informationBasic, informationAllocation, informationFramework, informationSetup  } =
        userResponse.responseObject[0];
      yield put({
        type: SET_CULTURE_REDUCER_STATE,
        payload: informationBasic
      });
      yield put({
        type: SET_CULTURE_SETUP_INFO_STATE,
        payload: informationSetup
      });
      if (
        informationAllocation &&
        informationAllocation?.cultureProfileGroup?.cultureProfileGroupPrimary &&
        informationAllocation?.cultureProfileGroup?.cultureProfileGroupPrimary.length > 0
      ) {
        let tempArr = informationAllocation.cultureProfileGroup.cultureProfileGroupPrimary.map(
          (ob) => ob.id
        );
        yield put({
          type: SET_CULTURE_DYNAMIC_SINGLE_STATE,
          payload: {
            objectName: 'informationAllocation',
            stateName: 'cultureProfileGroup',
            actualStateName: 'cultureProfileGroupPrimary',
            value: tempArr
          }
        });
      } else {
        yield put({
          type: SET_CULTURE_DYNAMIC_SINGLE_STATE,
          payload: {
            objectName: 'informationAllocation',
            stateName: 'cultureProfileGroup',
            actualStateName: 'cultureProfileGroupPrimary',
            value: []
          }
        });
      }
      if (
        informationAllocation &&
        informationAllocation?.cultureProfileGroup?.cultureProfileGroupSecondary &&
        informationAllocation?.cultureProfileGroup?.cultureProfileGroupSecondary.length > 0
      ) {
        let tempArr = informationAllocation.cultureProfileGroup.cultureProfileGroupSecondary.map(
          (ob) => ob.id
        );
        yield put({
          type: SET_CULTURE_DYNAMIC_SINGLE_STATE,
          payload: {
            objectName: 'informationAllocation',
            stateName: 'cultureProfileGroup',
            actualStateName: 'cultureProfileGroupSecondary',
            value: tempArr
          }
        });
      } else {
        yield put({
          type: SET_CULTURE_DYNAMIC_SINGLE_STATE,
          payload: {
            objectName: 'informationAllocation',
            stateName: 'cultureProfileGroup',
            actualStateName: 'cultureProfileGroupSecondary',
            value: []
          }
        });
      }
      if (
        informationAllocation &&
        informationAllocation?.cultureProfileNode?.cultureProfileNodePrimary &&
        informationAllocation?.cultureProfileNode?.cultureProfileNodePrimary.length > 0
      ) {
        let tempArr = informationAllocation.cultureProfileNode.cultureProfileNodePrimary.map(
          (ob) => ob.id
        );
        yield put({
          type: SET_CULTURE_DYNAMIC_SINGLE_STATE,
          payload: {
            objectName: 'informationAllocation',
            stateName: 'cultureProfileNode',
            actualStateName: 'cultureProfileNodePrimary',
            value: tempArr
          }
        });
      } else {
        yield put({
          type: SET_CULTURE_DYNAMIC_SINGLE_STATE,
          payload: {
            objectName: 'informationAllocation',
            stateName: 'cultureProfileNode',
            actualStateName: 'cultureProfileNodePrimary',
            value: []
          }
        });
      }
      if (
        informationAllocation &&
        informationAllocation?.cultureProfileNode?.cultureProfileNodeSecondary &&
        informationAllocation?.cultureProfileNode?.cultureProfileNodeSecondary.length > 0
      ) {
        let tempArr = informationAllocation.cultureProfileNode.cultureProfileNodeSecondary.map(
          (ob) => ob.id
        );
        yield put({
          type: SET_CULTURE_DYNAMIC_SINGLE_STATE,
          payload: {
            objectName: 'informationAllocation',
            stateName: 'cultureProfileNode',
            actualStateName: 'cultureProfileNodeSecondary',
            value: tempArr
          }
        });
      } else {
        yield put({
          type: SET_CULTURE_DYNAMIC_SINGLE_STATE,
          payload: {
            objectName: 'informationAllocation',
            stateName: 'cultureProfileNode',
            actualStateName: 'cultureProfileNodeSecondary',
            value: []
          }
        });
      }
      if (
        informationAllocation &&
        informationAllocation?.cultureProfileType?.cultureProfileTypePrimary &&
        informationAllocation?.cultureProfileType?.cultureProfileTypePrimary.length > 0
      ) {
        let tempArr = informationAllocation.cultureProfileType.cultureProfileTypePrimary.map(
          (ob) => ob.id
        );
        yield put({
          type: SET_CULTURE_DYNAMIC_SINGLE_STATE,
          payload: {
            objectName: 'informationAllocation',
            stateName: 'cultureProfileType',
            actualStateName: 'cultureProfileTypePrimary',
            value: tempArr
          }
        });
      } else {
        yield put({
          type: SET_CULTURE_DYNAMIC_SINGLE_STATE,
          payload: {
            objectName: 'informationAllocation',
            stateName: 'cultureProfileType',
            actualStateName: 'cultureProfileTypePrimary',
            value: []
          }
        });
      }
      if (
        informationAllocation &&
        informationAllocation?.cultureProfileType?.cultureProfileTypeSecondary &&
        informationAllocation?.cultureProfileType?.cultureProfileTypeSecondary.length > 0
      ) {
        let tempArr = informationAllocation.cultureProfileType.cultureProfileTypeSecondary.map(
          (ob) => ob.id
        );
        yield put({
          type: SET_CULTURE_DYNAMIC_SINGLE_STATE,
          payload: {
            objectName: 'informationAllocation',
            stateName: 'cultureProfileType',
            actualStateName: 'cultureProfileTypeSecondary',
            value: tempArr
          }
        });
      } else {
        yield put({
          type: SET_CULTURE_DYNAMIC_SINGLE_STATE,
          payload: {
            objectName: 'informationAllocation',
            stateName: 'cultureProfileType',
            actualStateName: 'cultureProfileTypeSecondary',
            value: []
          }
        });
      }
      let cultureCorelist = informationFramework?.cultureProfileCultureDimensionSelectList || [];
      let arr = [];
      cultureCorelist.forEach((ob) => {
        if (
          ob.iGaugeOccupationFrameworkOneClusterSelected ===
          ob.iGaugeOccupationFrameworkOneClusterOneLabel
        ) {
          arr.push({
            iGaugeOccupationFrameworkOneTag: ob.iGaugeOccupationFrameworkOneTag,
            iGaugeOccupationFrameworkOneClusterSelected:
              ob.iGaugeOccupationFrameworkOneClusterOneLabel,
            iGaugeOccupationFrameworkOneWeightage: ob.iGaugeOccupationFrameworkOneWeightage
          });
        }
        if (
          ob.iGaugeOccupationFrameworkOneClusterSelected ===
          ob.iGaugeOccupationFrameworkOneClusterTwoLabel
        ) {
          arr.push({
            iGaugeOccupationFrameworkOneTag: ob.iGaugeOccupationFrameworkOneTag,
            iGaugeOccupationFrameworkOneClusterSelected:
              ob.iGaugeOccupationFrameworkOneClusterTwoLabel,
            iGaugeOccupationFrameworkOneWeightage: ob.iGaugeOccupationFrameworkOneWeightage
          });
        }
      });

      let WeightArr = [];
      cultureCorelist.forEach((ob) => {
        if (
          ob.iGaugeOccupationFrameworkOneClusterSelected ===
          ob.iGaugeOccupationFrameworkOneClusterOneLabel
        ) {
          WeightArr.push({
            ...ob,
            iGaugeOccupationFrameworkOneTag: ob.iGaugeOccupationFrameworkOneTag,
            iGaugeOccupationFrameworkOneClusterLabel:
              ob.iGaugeOccupationFrameworkOneClusterOneLabel,
            iGaugeOccupationFrameworkOneClusterLabelDescription:
              ob.iGaugeOccupationFrameworkOneClusterOneDescription,
            iGaugeOccupationFrameworkOneClusterExplanation:
              ob.iGaugeOccupationFrameworkOneClusterOneExplanation,
            iGaugeOccupationFrameworkOneClusterSelected:
              ob.iGaugeOccupationFrameworkOneClusterOneLabel,
            iGaugeOccupationFrameworkOneWeightage: ob.iGaugeOccupationFrameworkOneWeightage
          });
        }
        if (
          ob.iGaugeOccupationFrameworkOneClusterSelected ===
          ob.iGaugeOccupationFrameworkOneClusterTwoLabel
        ) {
          WeightArr.push({
            ...ob,
            iGaugeOccupationFrameworkOneTag: ob.iGaugeOccupationFrameworkOneTag,
            iGaugeOccupationFrameworkOneClusterLabel:
              ob.iGaugeOccupationFrameworkOneClusterTwoLabel,
            iGaugeOccupationFrameworkOneClusterLabelDescription:
              ob.iGaugeOccupationFrameworkOneClusterTwoDescription,
            iGaugeOccupationFrameworkOneClusterExplanation:
              ob.iGaugeOccupationFrameworkOneClusterTwoExplanation,
            iGaugeOccupationFrameworkOneClusterSelected:
              ob.iGaugeOccupationFrameworkOneClusterTwoLabel,
            iGaugeOccupationFrameworkOneWeightage: ob.iGaugeOccupationFrameworkOneWeightage
          });
        }
      });
      yield put({
        type: SET_CULTURE_DIMENTION_STATE,
        payload: {
          cultureProfileCultureDimensionSelectList: arr || [],
          cultureProfileCultureDimensionCoreObj: WeightArr
          // informationFramework?.cultureProfileCultureDimensionSelectList || []
        }
      });

      yield put({
        type: SET_WEIGHTAGE_SELECTED,
        payload: false
      });
      // }
    } else {
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: userResponse.responseMessage,
          popupMode: 'responseErrorMsg'
        }
      });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
    yield put({ type: LOADER_STOP });
  }
}

function* workerReviseInfoCultureProfileSaga(data) {
  try {
    const userResponse = yield call(cultureProfileInfoApi, {
      data: data.payload.reqBody
    });
    if (userResponse.responseCode === '000') {
      const { createMode = '', isHideRightPane = false } = data.payload;
      if (!isHideRightPane) {
        yield put({
          type: SET_DISPLAY_PANE_THREE_STATE,
          payload: {
            headerOne: 'culture-profile',
            headerOneBadgeOne: data.payload.headerOneBadgeOne || 'distinct',
            headerOneBadgeThree: data.payload.headerOneBadgeThree,
            headerOneBadgeTwo: 'information',
            responseObject: userResponse.responseObject[0],
            createMode
          }
        });
        // yield put({
        //   type: SET_DISPLAY_TWO_SINGLE_STATE,
        //   payload: {
        //     stateName: 'middlePaneListPopupOptions',
        //     value: CULTURE_REVIEW_LIST_POPUP_OPTION
        //   }
        // });
      }
      let cultureCorelist =
      userResponse.responseObject[0].informationFramework
          ?.cultureProfileCultureDimensionSelectList || [];
      let arr = [];
      cultureCorelist.forEach((ob) => {
        if (
          ob.iGaugeOccupationFrameworkOneClusterSelected ===
          ob.iGaugeOccupationFrameworkOneClusterOneLabel
        ) {
          arr.push({
            iGaugeOccupationFrameworkOneTag: ob.iGaugeOccupationFrameworkOneTag,
            iGaugeOccupationFrameworkOneClusterSelected:
              ob.iGaugeOccupationFrameworkOneClusterOneLabel,
            iGaugeOccupationFrameworkOneWeightage: ob.iGaugeOccupationFrameworkOneWeightage
          });
        }
        if (
          ob.iGaugeOccupationFrameworkOneClusterSelected ===
          ob.iGaugeOccupationFrameworkOneClusterTwoLabel
        ) {
          arr.push({
            iGaugeOccupationFrameworkOneTag: ob.iGaugeOccupationFrameworkOneTag,
            iGaugeOccupationFrameworkOneClusterSelected:
              ob.iGaugeOccupationFrameworkOneClusterTwoLabel,
            iGaugeOccupationFrameworkOneWeightage: ob.iGaugeOccupationFrameworkOneWeightage
          });
        }
      });

      let WeightArr = [];
      cultureCorelist.forEach((ob) => {
        if (
          ob.iGaugeOccupationFrameworkOneClusterSelected ===
          ob.iGaugeOccupationFrameworkOneClusterOneLabel
        ) {
          WeightArr.push({
            iGaugeOccupationFrameworkOneTag: ob.iGaugeOccupationFrameworkOneTag,
            iGaugeOccupationFrameworkOneClusterLabel:
              ob.iGaugeOccupationFrameworkOneClusterOneLabel,
            iGaugeOccupationFrameworkOneClusterLabelDescription:
              ob.iGaugeOccupationFrameworkOneClusterOneLabelDescription,
            iGaugeOccupationFrameworkOneClusterExplanation:
              ob.iGaugeOccupationFrameworkOneClusterOneExplanation,
            iGaugeOccupationFrameworkOneClusterSelected:
              ob.iGaugeOccupationFrameworkOneClusterOneLabel,
            iGaugeOccupationFrameworkOneWeightage: ob.iGaugeOccupationFrameworkOneWeightage
          });
        }
        if (
          ob.iGaugeOccupationFrameworkOneClusterSelected ===
          ob.iGaugeOccupationFrameworkOneClusterTwoLabel
        ) {
          WeightArr.push({
            iGaugeOccupationFrameworkOneTag: ob.iGaugeOccupationFrameworkOneTag,
            iGaugeOccupationFrameworkOneClusterLabel:
              ob.iGaugeOccupationFrameworkOneClusterTwoLabel,
            iGaugeOccupationFrameworkOneClusterLabelDescription:
              ob.iGaugeOccupationFrameworkOneClusterTwoDescription,
            iGaugeOccupationFrameworkOneClusterExplanation:
              ob.iGaugeOccupationFrameworkOneClusterTwoExplanation,
            iGaugeOccupationFrameworkOneClusterSelected:
              ob.iGaugeOccupationFrameworkOneClusterTwoLabel,
            iGaugeOccupationFrameworkOneWeightage: ob.iGaugeOccupationFrameworkOneWeightage
          });
        }
      });
      yield put({
        type: SET_CULTURE_DIMENTION_STATE,
        payload: {
          cultureProfileCultureDimensionSelectList: arr || [],
          cultureProfileCultureDimensionCoreObj: cultureCorelist || []
        }
      });
      // yield put({
      //   type: SET_CULTURE_DIMENTION_STATE,
      //   payload: {
      //     cultureProfileCultureDimensionCore: [],
      //     cultureProfileCultureDimensionCoreObj: []
      //   }
      // });
      if (createMode === '') {
        if (data.payload.typeOfMiddlePaneList === 'cultureProfileTypeCultureProfileReviewList') {
          yield put({
            type: CULTURE_TYPE_CULTURE_REVIEWLIST_SAGA,
            payload: {
              HeaderOne: 'culture-profiles',
              request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        } else if (
          data.payload.typeOfMiddlePaneList === 'cultureProfileGroupCultureProfileReviewList'
        ) {
          yield put({
            type: CULTURE_GROUP_CULTURE_REVIEWLIST_SAGA,
            payload: {
              HeaderOne: 'culture-profiles',
              request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        } else {
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
          yield put({
            type: GET_CULTUREPROFILE_REVIEW_LIST_SAGA,
            payload: {
              HeaderOne: 'culture-profiles',
              request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        }
      } else {
        yield put({ type: LOADER_STOP });
      }
      yield put({
        type: SET_WEIGHTAGE_SELECTED,
        payload: false
      });
    } else {
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: userResponse.responseMessage,
          popupMode: 'responseErrorMsg'
        }
      });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerReviseMultiStatusAssociateSaga(data) {
  try {
    const userResponse = yield call(cultureProfileInfoApi, {
      data: data.payload.reqBody,
      URL: CULTURE_MODULE_URL
    });
    if (userResponse.responseCode === '000') {
      yield put({ type: SET_PAGE_COUNT, payload: 0 });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'relatedReviewListDistinctData', value: [] }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'typeOfMiddlePaneList', value: '' }
      });
      yield put({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'selectionValue', value: '' }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isSelectActive', value: '' }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'selectedTagsArray', value: [] }
      });
      if (data.payload.createMode === '') {
        if (data.payload.typeOfMiddlePaneList === 'cultureProfilesTypeDistinctReviewList') {
          yield put({ type: LOADER_START });
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
          yield put({
            type: GET_CULTUREPROFILE_TYPE_REVIEW_LIST_SAGA,
            payload: {
              HeaderOne: 'culture-profiles',
              request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        } else if (data.payload.typeOfMiddlePaneList === 'cultureProfilesGroupDistinctReviewList') {
          yield put({ type: LOADER_START });
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
          yield put({
            type: GET_CULTUREPROFILE_GROUP_REVIEW_LIST_SAGA,
            payload: {
              HeaderOne: 'associates',
              request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        } else if (
          data.payload.typeOfMiddlePaneList === 'cultureProfileTypeCultureProfileReviewList'
        ) {
          yield put({
            type: CULTURE_TYPE_CULTURE_REVIEWLIST_SAGA,
            payload: {
              HeaderOne: 'culture-profiles',
              request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        } else if (
          data.payload.typeOfMiddlePaneList === 'cultureProfileGroupCultureProfileReviewList'
        ) {
          yield put({
            type: CULTURE_GROUP_CULTURE_REVIEWLIST_SAGA,
            payload: {
              HeaderOne: 'culture-profiles',
              request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        } else {
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
          yield put({ type: SET_PAGE_COUNT, payload: 0 });
          yield put({
            type: GET_CULTUREPROFILE_REVIEW_LIST_SAGA,
            payload: {
              // HeaderOne: 'associates',
              HeaderOne: 'culture-profiles',
              request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        }
      } else yield put({ type: LOADER_STOP });
    } else {
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: userResponse.responseMessage,
          popupMode: 'responseErrorMsg'
        }
      });
    }
  } catch (e) {
    yield put({ type: LOADER_STOP });
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
  }
}
function* workerReviseCulturePublishedSaga(data) {
  try {
    const userResponse = yield call(cultureProfileInfoApi, {
      data: data.payload.reqBody,
      URL: CULTURE_MODULE_URL
    });
    if (userResponse.responseCode === '000') {
      if (data.payload.createMode === '') {
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'isSelectActive', value: '' }
        });
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'selectedTagsArray', value: [] }
        });
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'reviewListDistinctData', value: [] }
        });
        yield put({
          type: GET_CULTUREPROFILE_REVIEW_LIST_SAGA,
          payload: {
            HeaderOne: 'culture-profiles',
            request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
            BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
            isMiddlePaneList: true
          }
        });
      } else yield put({ type: LOADER_STOP });
    } else {
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: userResponse.responseMessage,
          popupMode: 'responseErrorMsg'
        }
      });
    }
  } catch (e) {
    yield put({ type: LOADER_STOP });
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
  }
}
export default function* watchReviewInfoCultureProfileSaga() {
  yield takeLatest(GET_CULTURE_PROFILE_INFO_SAGA, workerReviewInfoCultureProfileSaga);
  yield takeLatest(CULTURE_PROFILE_INFO_REVISE_SAGA, workerReviseInfoCultureProfileSaga);
  yield takeLatest(CULTURE_PROFILE_MULTI_STATUS_REVISE_SAGA, workerReviseMultiStatusAssociateSaga);
  yield takeLatest(CULTURE_PROFILE_PUBLISHED_SAGA, workerReviseCulturePublishedSaga);
}
