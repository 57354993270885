import React from 'react';
import { isMobile } from 'react-device-detect';
// import AllocationAccordian from '../Accordian/AllocationAccordian';
// import Manuscript from '@material-ui/icons/Description';
import { useDispatch, useSelector } from 'react-redux';
import DisplayPanelAccordianReviewListOne from '../Accordian/DisplayPanelAccordianReviewListOne';
import DisplayPanelAccordianInformation from '../Accordian/DisplayPanelAccordianInformation';
import { Paper } from '@material-ui/core';
import {
  FILTERMODE,
  GET_ALLOCATE_ASSOCIATE,
  LOADER_START,
  RELATED_REVIEWLIST_DISTINCT_DATA,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MIDDLEPANE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_PAGE_COUNT
} from '../../actionType';
import { makeAssociateReviewListRequestObject } from '../../Actions/GenericActions';
import { getAssociateDistinctToAllocate, getAssociateRoleAssociateDistinctApiCall } from '../../Actions/AssociateModuleAction';

const DisplayPaneThreeSectionTwoAssociateRole = () => {
  // const [listExpand, setListExpand] = useState('');
  const { reviewMode, relatedReviewListPaneThree = [], responseObject } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  const { selectedAssociateInfo, countPage } = useSelector((state) => state.DisplayPaneTwoReducer);
  const dispatch = useDispatch();
  // const { informationEngagement } = responseObject;
  // function capitalizeFirstLetter(string) {
  //   if (!string) return '';
  //   return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  // }
  let associate = [];
  if (relatedReviewListPaneThree) {
    associate = relatedReviewListPaneThree?.associate || [];
  }
  let associateArray = [];
  associate &&
    associate.forEach((ob) => {
      const { id, informationBasic,informationEngagement, associateId } = ob;
      associateArray.push({
        id,
        textOne: informationBasic?.associateName || '',
        textTwo: informationBasic?.associateDescription || '',
        status: informationEngagement?.associateStatus ||'',
        isShared: ob?.associateShared,
        associateId
      });
    });

  const onclickReviewAssociate = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    const innerSelectedBadgeName = e.currentTarget.getAttribute('id');
    console.log('ASSESSEE CLICK :::::::>>>>>>>', labelName);
    if (labelName === 'associates'  && selectedBadgeName === 'distinct') {
      getAssociateRoleAssociateDistinctApiCall(
        selectedAssociateInfo,
        '',
        countPage,
        dispatch,
        'distinct',
        responseObject.id,
        '',
        false
      );
    }
  };
  const onclickReviseAssociate = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    console.log('ASSESSEE CLICK :::::::>>>>>>>', labelName);
    if (labelName === 'associates' && selectedBadgeName === 'distinct') {


      let requestObect = makeAssociateReviewListRequestObject(
        selectedAssociateInfo,
        '',
        0,
        countPage
      );
      let revisedGroupObject = {
        id: responseObject.id,
        associateRoleName: responseObject.informationBasic.associateRoleName,
        associateRoleDescription: responseObject.informationBasic.associateRoleDescription,
        associateRoleStatus: responseObject.informationEngagement.associateRoleStatus
      };
      let existingAssesseeId = [];
      if (relatedReviewListPaneThree && relatedReviewListPaneThree?.associate) {
        existingAssesseeId =
          relatedReviewListPaneThree &&
          relatedReviewListPaneThree.associate.map((val) => {
            return val.informationEngagement.associateTag.associateTagPrimary; //id;
          });
      }

      dispatch({
        type: SET_PAGE_COUNT,
        payload: 0,
      })
      // set numberPage as 0
      //todo 
      
      getAssociateDistinctToAllocate(
        dispatch,
        requestObect,
        revisedGroupObject,
       (existingAssesseeId && existingAssesseeId) || [],
        'associateRoleAssociateRevise',
        'associatesRoleAssociateReviewList'
      );
    }
  };

  const list3 = [
    {
      id: 'a1',
      labelTextOneOne: 'associates',
      labelTextOneOneBadgeOne: '',
      labelTextOneOneBadgeTwo: '',
      labelTextOneOneBadgeThree: '',
      labelTextOneOneBadgeFour: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'distinct',
          innerList: associateArray
        }
      ],
      innerInfo: 'No Information',
      isListCard: true,
      isReviewLink: true
    }
  ];

  return (
    <div>
      <>
        <div className={'containerPadding'}>
          <Paper className={'dossierContainerTop'}>
            {list3.map((ob) => {
              return (
                <div key={ob.id}>
                  {ob.isListCard ? (
                    <DisplayPanelAccordianReviewListOne
                      onClickReview={onclickReviewAssociate}
                      onClickRevise={onclickReviseAssociate}
                      className=""
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  ) : (
                    <DisplayPanelAccordianInformation accordianObject={ob} mode={reviewMode} />
                  )}
                </div>
              );
            })}
          </Paper>
        </div>
      </>
      {isMobile && (
        <div className={'containerPadding'} style={{ height: '55px' }}>
          {' '}
        </div>
      )}
    </div>
  );
};

export default DisplayPaneThreeSectionTwoAssociateRole;
