import React, { useEffect, useState } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import Popup from '../Molecules/PopUp/PopUp';
import PopupHeader from '../Molecules/PopUp/PopUpHeader';
import '../Molecules/PopUp/PopUp.css';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { POPUP_CLOSE, SET_NEXT_POPUP, UPDATE_ASSOCIATE_CURRENCY_INFO } from '../actionType';
import FormControl from '@material-ui/core/FormControl';
import SelectField from '../Atoms/SelectField/SelectField';
import InputLabel from '@material-ui/core/InputLabel';
import { CAN_NOT_REVISED_MESSAGE, REQUIRED_ERROR_MESSAGE } from '../errorMessage';
import { Checkbox } from '@material-ui/core';

const PopUpDropList = (props) => {
  const dispatch = useDispatch();
  const { popupMode, isPopUpValue } = useSelector((state) => state.PopUpReducer);
  const { updateAssociateSetupFlag, informationSetupPlus } = useSelector(
    (state) => state.AssociateCreateReducer
  );
  const {
    setUpAssociateModule,
    analyticSetUpModule,
    itemSetUpModule,
    assessmentSetUpModule,
    assesseeSetUpModule,
    assignmentSetUpModule
  } = useSelector((state) => state.DisplayPaneThreeReducer);
  //props
  const {
    isActive = false,
    headerPanelColour,
    headerOne,
    headerOneBadgeOne,
    headerOneBadgeTwo,
    inputHeaderBadgeThree = null,
    listSelect,
    isRequired = false,
    basicInfo,
    tag,
    label,
    labelBadgeOne = '',
    typeOfSetObject,
    nextPopUpValue = '',
    mappingValue,
    handleNextPopupValue,
    mode,
    isNotRevised = false,
    inputHeader = '',
    inputHeaderBadgeOne = '',
    inputHeaderBadgeTwo = '',
    isMultiSelect = false,
    setDependantObj = '',
    isCheckboxState = '',
    isCheckboxLabel = '',
    isCheckboxDisplay = false,
    isRevisePermissionCheckbox = false,
    revisePermissionCheckboxLabel,
    revisePermissionField = '',
    setUpPlusRevisePermission = true
  } = props;
  //states
  console.log('props', props);
  console.log('inputHeader',inputHeader);
  let setUpModule = inputHeader.includes('assessee')
    ? assesseeSetUpModule
    : inputHeader.includes('assessment')
    ? assessmentSetUpModule
    : inputHeader.includes('assignment')
    ? assignmentSetUpModule
    : inputHeader.includes('analytic')
    ? analyticSetUpModule
    : inputHeader.includes('item')
    ? itemSetUpModule
    : setUpAssociateModule;

  console.log('setUpModule---', setUpModule);
  let isNotRevisedCheck = false
  if (revisePermissionField && setUpModule !== null) {
    console.log('setUpModule',setUpModule[revisePermissionField]);
    console.log('basicInfo',basicInfo[revisePermissionField]);
    isNotRevisedCheck = setUpModule[revisePermissionField] && basicInfo[revisePermissionField];}
  let errorMessage = isNotRevised || isNotRevisedCheck ? CAN_NOT_REVISED_MESSAGE : '';
  const [state, setState] = useState({ isError: errorMessage });
  const { reviewMode } = useSelector((state) => state.DisplayPaneThreeReducer);
  const [localObject, setLocalObject] = useState(basicInfo);
  const [checkboxState, setCheckboxState] = useState(false);
  const [checkBoxValue, setCheckBoxValue] = useState(localObject[revisePermissionField]);

  useEffect(() => {
    setLocalObject(basicInfo);
  }, [basicInfo]);
  // handling the onchange event
  // const unicodeToChar=(text)=>{
  //   return text.replace(/\\u[\dA-F]{4}/gi, function(match){
  //      return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));});
  // }
  const handleChange = (event) => {
    const { name, value } = event.target;
    setCheckboxState(false);
    if (!isNotRevised) {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
        isError: ''
      }));
      // dispatch({ type: typeOfSetObject, payload: { ...basicInfo, [name]: value } });
      if (setDependantObj === 'associateCountry') {
        let selectedCountry = listSelect.filter(function (el) {
          return el._id === value;
        });
        console.log('selectedCountry', selectedCountry);

        let localObj = {
          ...localObject,
          [name]: value,
          associateCountryName: selectedCountry[0].name,
          associateCountryFlag: selectedCountry[0].iso2
        };
        console.log('localObj', localObj);
        // let char = unicodeToChar(selectedCountry[0].emojiU);
        // //: "U+1F1EE U+1F1F3"
        setLocalObject(localObj);
        let currencyObj = {
          associateCurrencyName: selectedCountry[0].currency,
          associateCurrencySymbol: selectedCountry[0].currencySymbol,
          associateCurrencyTag: ''
        };
        dispatch({ type: UPDATE_ASSOCIATE_CURRENCY_INFO, payload: { ...currencyObj } });
      } else {
        setLocalObject({ ...localObject, [name]: value });
      }
    }
  };
  const handleCheckboxChange = (event) => {
    const { name, value, checked } = event.target;
    console.log('name, value', name, value, checked);
    if (checked) {
      if (!isNotRevised) {
        setState((prevState) => ({
          ...prevState,
          [name]: value,
          isError: ''
        }));
        setLocalObject({ ...localObject, [name]: '' });
        setCheckboxState(true);
      }
    } else {
      if (!isNotRevised) {
        setState((prevState) => ({
          ...prevState,
          [name]: value,
          isError: ''
        }));
        setCheckboxState(false);
        setLocalObject({ ...localObject, [name]: 'VERSION_STATIC' });
      }
    }
  };
  const onClickCheckbox = (event) => {
    const { name, value, checked } = event.target;
    console.log('taget', event.target);
    console.log('name, value', name, value, checked);
    if (checked) {
      if (!isNotRevised) {
        setState((prevState) => ({
          ...prevState,
          [name]: value,
          isError: ''
        }));
        setLocalObject({ ...localObject, [name]: true });
        setCheckBoxValue(true);
      }
    } else {
      if (!isNotRevised) {
        setState((prevState) => ({
          ...prevState,
          [name]: value,
          isError: ''
        }));
        setCheckBoxValue(false);
        setLocalObject({ ...localObject, [name]: false });
      }
    }
  };
  //this function for validate
  const validate = () => {
    let isValidate = true;
    if (isRequired) {
      if (localObject[tag] === '') {
        setState((prevState) => ({ ...prevState, isError: REQUIRED_ERROR_MESSAGE }));
        isValidate = false;
      }
      return isValidate;
    } else {
      return isValidate;
    }
  };
  //end

  const handleClick = () => {
    console.log('localObject----', localObject);
    dispatch({ type: typeOfSetObject, payload: { ...localObject } });
    if (validate()) {
      //according to creation mode popup sequence will change
      if (popupMode === 'ASSESSEE_CREATE') {
        if (reviewMode === 'revise') {
          dispatch({ type: POPUP_CLOSE });
        } else {
          handleNextPopupValue();
        }
      }
      if (popupMode === 'ASSESSMENTCREATE') {
        if (tag === 'assessmentAdministrationVersionType') {
          if (localObject?.assessmentAdministrationVersionType === 'VERSION_STATIC') {
            dispatch({
              type: SET_NEXT_POPUP,
              payload: { isPopUpValue: 'ADMINVERSIONNUMBERPOPUP' }
            });
          } else {
            dispatch({ type: SET_NEXT_POPUP, payload: { isPopUpValue: nextPopUpValue } });
          }
        } else {
          dispatch({ type: SET_NEXT_POPUP, payload: { isPopUpValue: nextPopUpValue } });
        }
      } else {
        dispatch({ type: SET_NEXT_POPUP, payload: { isPopUpValue: nextPopUpValue } });
      }
    }
  };

  console.log('localObject', localObject);
  console.log('revisePermissionField', revisePermissionField, localObject[revisePermissionField]); //
  console.log('isNotRevisedCheck', isNotRevisedCheck);
  return (
    <div>
      <Popup isActive={isActive}>
        <PopupHeader
          headerPanelColour={headerPanelColour}
          headerOne={headerOne}
          headerOneBadgeOne={headerOneBadgeOne}
          headerOneBadgeTwo={headerOneBadgeTwo}
          headerOneBadgeThree={''}
          onClick={handleClick}
          mode={mode}
          isNotRevised={isNotRevisedCheck === true ? true : isNotRevised}
        />
        <DialogContent
          className={['popupContent', 'fixed10PadDim', 'revisePopupContent'].join(' ')}
        >
          {inputHeader !== '' && (
            <div className={'fitContent'}>
              <div className={['PopupFormBox', 'labelPopupBox', 'popupMinHei'].join(' ')}>
                <InputLabel htmlFor="name-input" className={'textForLabelPopup'}>
                  <>
                    {inputHeader}&nbsp;
                    {inputHeaderBadgeOne ? (
                      <span className={'headerBadge'}>{inputHeaderBadgeOne}</span>
                    ) : null}
                    &nbsp;
                    {inputHeaderBadgeTwo ? (
                      <span className={'headerBadge'}>{inputHeaderBadgeTwo}</span>
                    ) : null}
                    &nbsp;
                    {inputHeaderBadgeThree ? (
                      <span className={'headerBadge'}>{inputHeaderBadgeThree}</span>
                    ) : null}
                  </>
                </InputLabel>
                {/* <div className={'infoSymbol'}></div> */}
                <div className={'infoSymbol'}>{/* <InfoToolTip message={infoMsg} /> */}</div>
              </div>
            </div>
          )}
          <FormControl style={{ width: '100%' }}>
            <SelectField
              tag={tag}
              label={label}
              listSelect={listSelect}
              errorMsg={state.isError}
              onChange={handleChange}
              value={localObject && localObject[tag]}
              mappingValue={mappingValue}
              labelBadgeOne={labelBadgeOne}
              isMultiSelect={isMultiSelect}
              isNotRevised={isNotRevised || isNotRevisedCheck}
              classNames={isNotRevised || isNotRevisedCheck ? 'selectMenuDisabled' : ''}
            />
            {isCheckboxDisplay && (
              <div className={'fitContent'}>
                <div className={['PopupFormBox', 'popupMinHei0'].join(' ')}>
                  <div className={'contFlex'}>
                    <div className={'f4'}>{isCheckboxLabel}</div>
                    <div className={'checkedFontNew'}>
                      <Checkbox
                        className={''}
                        color="default"
                        name={tag}
                        checked={checkboxState}
                        disableRipple={true}
                        onChange={handleCheckboxChange}
                        disabled={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {isRevisePermissionCheckbox && !updateAssociateSetupFlag && (
              <div className={'fitContent'}>
                <div className={['PopupFormBox', 'popupMinHei0'].join(' ')}>
                  <div className={'contFlex'}>
                    <div className={'f4'}>{revisePermissionCheckboxLabel}</div>
                    <div className={'checkedFontNew'}>
                      <Checkbox
                        className={''}
                        color="default"
                        name={revisePermissionField}
                        checked={checkBoxValue}
                        disableRipple={true}
                        onChange={onClickCheckbox}
                        disabled={isNotRevisedCheck}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </FormControl>
        </DialogContent>
      </Popup>
    </div>
  );
};
PopUpDropList.propTypes = {
  className: PropTypes.string,
  headerPanelColour: PropTypes.oneOf([
    'displayPaneLeft',
    'displayPaneCentre',
    'displayPaneRight',
    'genericOne',
    'genericTwo'
  ]),
  headerOne: PropTypes.string,
  headerOneBadgeOne: PropTypes.string,
  headerOneBadgeTwo: PropTypes.string,
  headerOneBadgeThree: PropTypes.string,
  isActive: PropTypes.bool
};
export default PopUpDropList;
