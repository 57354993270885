import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ASSOCIATE_POPUP_CLOSE,
  CREATE_GAUGE_SAGA,
  FILTERMODE,
  FILTERMODE_ENABLE,
  LOADER_START,
  POPUP_OPEN,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_POPUP_STATE,
  SET_REQUEST_OBJECT
} from '../actionType';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import {
  ASSESSEE_NODE_ROLE_REVIEW_LIST_POPUP_OPTION,
  ASSESSMENT_NODE_TYPE_REVIEW_LIST_POPUP_OPTION,
  GAUGE_REVIEW_LIST_POPUP_OPTION,
  NODE_ROLE_TYPE_REVIEW_LIST_POPUP_OPTION
} from '../PopUpConfig';
import { onClickCheckBoxSelection } from '../Actions/AssesseeModuleAction';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import { FilterList } from '@material-ui/icons';
import { getGuageDistinctApiCall, getPopUpOptionList } from '../Actions/GenericActions';
const GaugeDistinctReviewList = (props) => {
  const dispatch = useDispatch();
  const { cardValue } = useSelector((state) => state.PopUpReducer);
  const {
    reviewListDistinctData,
    middlePaneSelectedValue,
    middlePaneHeader,
    isSelectActive,
    middlePaneHeaderBadgeOne,
    middlePaneHeaderBadgeTwo,
    middlePaneHeaderBadgeThree,
    selectedAssociateInfo,
    selectedTagsArray,
    unselectedTagsArray,
    signedAssesseePermission
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { gaugeInformation, gaugeInformationrmation } = useSelector(
    (state) => state.GaugeCreateReducer
  );
  const { FilterModeEnable, FilterMode } = useSelector((state) => state.FilterReducer);
  const { secondaryOptionCheckValue } = useSelector((state) => state.PopUpReducer);
  const openListPopup = (e) => {
    console.log(e.currentTarget.getAttribute('tag'));
    let tempArr = [];
    let status = e.currentTarget.getAttribute('status');
    let classification = e.currentTarget.getAttribute('data-classification');
    let isShared = e.currentTarget.getAttribute('data-shared');
    let associateId = e.currentTarget.getAttribute('associateid');
    let signedAssociateId =
      selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary;
    let assessmentDistinctPermission =
      signedAssesseePermission?.assessmentDistictPermission?.assesseeAssessmentTypePermission;
    // let popupArr =
    //   cardValue === 'Card'
    //     ? NODE_ROLE_TYPE_REVIEW_LIST_POPUP_OPTION
    //     : ASSESSMENT_NODE_TYPE_REVIEW_LIST_POPUP_OPTION;
    // let popupContentArrValue = ASSESSEE_NODE_ROLE_REVIEW_LIST_POPUP_OPTION.map((obj) =>
    //   obj.data === 'assessees' ? { ...obj, data: 'metrics', dataValue: 'metrics' } : obj
    // );
    let popupContentArrValue = GAUGE_REVIEW_LIST_POPUP_OPTION.map((obj) =>
      obj.data === 'assessees' ? { ...obj, data: 'metrics', dataValue: 'metrics' } : obj
    );
    let cultureProfileDistictPermssion =
    signedAssesseePermission?.iGuruAnalyticDistinctPermission
      ?.assesseeiGuruAnalyticDistinctPermission;
    let popupContentArr = getPopUpOptionList(popupContentArrValue, cultureProfileDistictPermssion);
    popupContentArr.map((element) => {
      if (status === 'UNPUBLISHED' && element.data === 'allocate') {
        tempArr.push({ ...element, disabled: true });
      } else if (
        status === 'PUBLISHED' &&
        (element.data === 'allocate' || element.data === 'revise')
      ) {
        tempArr.push({ ...element, disabled: true });
      } else if (
        (status === 'TERMINATED' || status === 'SUSPENDED' || status === 'ARCHIVED') &&
        (element.data === 'allocate' ||
          element.data === 'revise' ||
          element.data === 'select' ||
          element.data === 'publish' ||
          element.data === 'metrics' ||
          element.data === 'share')
      ) {
        tempArr.push({ ...element, disabled: true });
      } else tempArr.push(element);
    });
    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: middlePaneHeader,
        popupHeaderOneBadgeOne: middlePaneHeaderBadgeOne,
        popupHeaderOneBadgeTwo: '',
        isPopUpValue: '',
        popupOpenType: 'primary',
        popupContentArrValue: tempArr,
        selectedTagValue: e.currentTarget.getAttribute('tag'),
        selectedTagStatus: e.currentTarget.getAttribute('status'),
        selectedTagGroupId: e.currentTarget.getAttribute('data-value'),
        selectedTagShared: e.currentTarget.getAttribute('data-shared')
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: tempArr
        // value:
        //   cardValue === "Card"
        //     ? ASSESSMENT_NODE_TYPE_REVIEW_LIST_POPUP_OPTION
        //     : tempArr,
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
  };
  const siftApiCall = (siftKey) => {
    getGuageDistinctApiCall(
      selectedAssociateInfo,
      siftKey,
      dispatch,
      middlePaneHeaderBadgeOne,
      '',
      isSelectActive,
      selectedTagsArray
    );
    document.getElementById('middleComponentId').scrollTop = '0px';
  };

  const onClickFooter = (e) => {
    let siftValue = e.currentTarget.getAttribute('data-value');
    dispatch({ type: FILTERMODE_ENABLE });
    if (siftValue === 'suspended' || siftValue === 'terminated' || siftValue === 'unpublished')
      siftApiCall(siftValue);
    if (siftValue === 'cancel') {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isSelectActive', value: '' }
      });
      dispatch({
        type: FILTERMODE,
        payload: { FilterMode: '' }
      });
    }
  };

  const primaryIcon = [{ label: 'sift', onClick: onClickFooter, Icon: FilterList }];
  const secondaryIcon = [
    { label: 'suspended', onClick: onClickFooter, Icon: FilterList },
    { label: 'terminated', onClick: onClickFooter, Icon: FilterList },
    { label: 'unpublished', onClick: onClickFooter, Icon: FilterList }
  ];
  //console.log('reviewListDistinctData', reviewListDistinctData);
  console.log('FilterMode', FilterMode);
  return (
    <div>
      {reviewListDistinctData &&
        reviewListDistinctData.map((item, index) => {
          return (
            <div className="containerPadding" key={index}>
              <ReviewList
                className=""
                id={index}
                tag={item.id}
                associateId={item?.associateId}
                isSelectedReviewList={middlePaneSelectedValue === item.id}
                status={item?.iGaugeOccupationFrameworkOneGaugeStatus}
                actualStatus={item?.iGaugeOccupationFrameworkOneGaugeStatus}
                textOne={item?.iGaugeOccupationFrameworkOneGaugeName || ''}
                textTwo={item?.iGaugeOccupationFrameworkOneGaugeDescription || ''}
                isTooltipActive={false}
                onClickEvent={openListPopup}
                isSelectActive={isSelectActive}
                isSelected={selectedTagsArray.includes(item.id)}
                onClickCheckBox={(event) => {
                  onClickCheckBoxSelection(selectedTagsArray, unselectedTagsArray, event, dispatch);
                }}
              />
            </div>
          );
        })}
      {FilterMode === 'Gaugeinactive' && (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={primaryIcon}
          secondaryIcon={secondaryIcon}
        />
      )}
      {(FilterMode === 'Gaugeunpublished' ||
        FilterMode === 'Gaugeterminated' ||
        FilterMode === 'Gaugesuspended') && (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={primaryIcon}
          secondaryIcon={secondaryIcon}
        />
      )}
    </div>
  );
};
export default GaugeDistinctReviewList;
