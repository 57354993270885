import {
  SET_GRADE_REDUCER_STATE,
  CLEAR_GRADE_REDUCER_STATE,
  SET_GRADE_SCORE_STATE
} from '../actionType';
const initialState = {
  gradeInformation: {
    assessmentScoreGradeName: '',
    assessmentScoreGradeNameVerification: false,
    assessmentScoreGradeDescription: '',
    assessmentScoreGradePicture: '',
    assessmentScoreGradePictureVerification: false,
    assessmentScoreGradeScore: {
      assessmentScoreGradeScoreExtremumMaximum: '',
      assessmentScoreGradeScoreExtremumMinimum: ''
    },
    assessmentId: ''
  }
};

const GradeCreateReducer = (istate = JSON.parse(JSON.stringify(initialState)), action) => {
  switch (action.type) {
    case SET_GRADE_REDUCER_STATE:
      return {
        ...istate,
        gradeInformation: action.payload
      };
    case SET_GRADE_SCORE_STATE:
      return {
        ...istate,
        gradeInformation: {
          ...istate.gradeInformation,
          assessmentScoreGradeScore: action.payload
        }
      };
    case CLEAR_GRADE_REDUCER_STATE:
      return JSON.parse(JSON.stringify(initialState));
    default:
      return istate;
  }
};

export default GradeCreateReducer;
