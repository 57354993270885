import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FILTERMODE,
  FILTERMODE_ENABLE,
  POPUP_OPEN,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MIDDLEPANE_STATE,
  SET_POPUP_STATE,
  SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE
} from '../actionType';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import { FilterList } from '@material-ui/icons';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import { ASSESSEES_SUBMODULE_REVIEW_LIST_POPUP_OPTION } from '../PopUpConfig';
import Card from '../Molecules/Card/Card';
import CrossIcon from '@material-ui/icons/Clear';
import {
  onClickCheckBoxSelection,
  onClickCheckBoxSelectionOnlyOne
} from '../Actions/AssesseeModuleAction';
import { assesseeStatus } from '../Actions/StatusAction';
import ReviseIcon from '@material-ui/icons/RadioButtonChecked';
import Check from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { getAssessmentGroupAssessmentDistinctApiCall } from '../Actions/AssessmentModuleAction';

const AssignmentAssessmentNormDistinctReviewList = (props) => {
  const dispatch = useDispatch();
  const [isShowReviseIcon, setIsShowReviseIcon] = useState(true);
  const {
    middlePaneSelectedValue,
    selectedAssociateInfo,
    relatedReviewListDistinctDataCopy,
    relatedReviewListDistinctData,
    middlePaneHeaderBadgeOne,
    middlePaneHeaderDuplicate,
    middlePaneHeaderDuplicateBadgeOne,
    middlePaneHeaderDuplicateBadgeTwo,
    middlePaneHeaderDuplicateBadgeThree,
    middlePaneHeaderDuplicateBadgeFour,
    isSelectActive,
    selectedTagsArray,
    duplicateScanCount,
    countPage,
    unselectedTagsArray
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { FilterModeEnable, FilterMode } = useSelector((state) => state.FilterReducer);
  const { reviewMode } = useSelector((state) => state.DisplayPaneThreeReducer);
  const setPreviousList = () => {
    dispatch({
      type: SET_MIDDLEPANE_STATE,
      payload: {
        middlePaneHeader: 'assessments ',
        middlePaneHeaderBadgeOne: 'distinct',
        middlePaneHeaderBadgeTwo: 'active',
        middlePaneHeaderBadgeThree: '',
        middlePaneHeaderDuplicate: middlePaneHeaderDuplicate,
        middlePaneHeaderDuplicateBadgeOne: middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderDuplicateBadgeTwo: middlePaneHeaderDuplicateBadgeTwo,
        middlePaneHeaderDuplicateBadgeThree: middlePaneHeaderDuplicateBadgeThree,
        middlePaneHeaderDuplicateBadgeFour: middlePaneHeaderDuplicateBadgeFour,
        typeOfMiddlePaneList: 'assignmentDistinctAssessmentReviewList',
        duplicateScanCount: duplicateScanCount,
        scanCount: relatedReviewListDistinctData[0].assessment.length,
        showMiddlePaneState: true
      }
    });
  };
  const onClickRevise = () => {
    console.log('ON CLICK REVISE ICON');
    setIsShowReviseIcon(false);
  };
  const onClickReviseCancel = () => {
    console.log('ON CLICK cancel ICON');
    setPreviousList();
    setIsShowReviseIcon(true);
  };
  const onClickReviseFinish = () => {
    console.log('ON CLICK finish ICON', selectedTagsArray, unselectedTagsArray);
    dispatch({
      type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
      payload: {
        stateName: 'assessmentNormFrameworkSelected',
        value: selectedTagsArray.length > 0 ? selectedTagsArray[0] : ''
      }
    });
    setIsShowReviseIcon(true);
    setPreviousList();
  };
  const revisePrimaryIcon = [{ label: 'revise', onClick: onClickRevise, Icon: ReviseIcon }];

  const reviseSecondaryIcons = [
    { label: 'cancel', onClick: onClickReviseCancel, Icon: ClearIcon },
    { label: 'finish', onClick: onClickReviseFinish, Icon: Check }
  ];

  const closeRelatedList = () => {
    setPreviousList();
  };
  const listDistinctData = relatedReviewListDistinctDataCopy[0];

  const siftApiCall = (siftKey) => {
    getAssessmentGroupAssessmentDistinctApiCall(
      selectedAssociateInfo,
      siftKey,
      countPage,
      dispatch,
      middlePaneHeaderBadgeOne,
      listDistinctData.id,
      '',
      false
    );
    document.getElementById('middleComponentId').scrollTop = '0px';
  };
  const onClickFooter = (e) => {
    let siftValue = e.currentTarget.getAttribute('data-value');
    if (siftValue === 'suspended' || siftValue === 'terminated') siftApiCall(siftValue);
    dispatch({ type: FILTERMODE_ENABLE });
  };
  /* for middle pane */
  const primaryIcon = [{ label: 'sift', onClick: onClickFooter, Icon: FilterList }];
  const secondaryIcon = [
    { label: 'suspended', onClick: onClickFooter, Icon: FilterList },
    { label: 'terminated', onClick: onClickFooter, Icon: FilterList }
  ];

  const openListPopup = (e) => {
    console.log(e.currentTarget.getAttribute('tag'));
    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: 'assessment',
        popupHeaderOneBadgeOne: '',
        isPopUpValue: '',
        popupOpenType: 'primary',
        popupContentArrValue: ASSESSEES_SUBMODULE_REVIEW_LIST_POPUP_OPTION,
        selectedTagValue: e.currentTarget.getAttribute('tag'),
        selectedTagStatus: e.currentTarget.getAttribute('status')
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: ASSESSEES_SUBMODULE_REVIEW_LIST_POPUP_OPTION
      }
    });
  };
  // this code for assessment group list
  const { responseObject } = useSelector((state) => state.DisplayPaneThreeReducer);
  console.log('listDistinctData', listDistinctData);
  // this code for assessment group list
  return (
    <div>
      {listDistinctData && (
        <Card
          textOneOne={listDistinctData.assessmentName}
          textTwoOne={listDistinctData.assessmentDescription}
          IconOne={CrossIcon}
          isIcon={true}
          labelTwoTwo={'group'}
          onClickIconOne={closeRelatedList}
          isAlliance
          relatedCardFixed={true}
          className={'iguru-iconboxSVG'}
        />
      )}
      {listDistinctData &&
        listDistinctData.norms.map((item, index) => {
          return (
            <div className="containerPadding" key={index}>
              <ReviewList
                className=""
                id={index}
                tag={item.id}
                isSelectedReviewList={middlePaneSelectedValue === item.id}
                status={''}
                actualStatus={''}
                textOne={item.assessmentNormName}
                textTwo={item.assessmentNormDescription}
                isTooltipActive={false}
                onClickEvent={openListPopup}
                isSelectActive={isSelectActive}
                isSelected={selectedTagsArray.includes(item.id)}
                onClickCheckBox={(event) => {
                  onClickCheckBoxSelectionOnlyOne(
                    selectedTagsArray,
                    unselectedTagsArray,
                    event,
                    dispatch
                  );
                }}
              />
            </div>
          );
        })}
      {FilterMode === 'assignmentAssessmentNormRevise' && (
        <FooterIconTwo
          FilterModeEnable={isShowReviseIcon}
          FilterMode={FilterMode}
          onClick={onClickRevise}
          primaryIcon={revisePrimaryIcon}
          secondaryIcon={reviseSecondaryIcons}
        />
      )}
    </div>
  );
};
export default AssignmentAssessmentNormDistinctReviewList;
