export const ASSESSEE_UNCONFIRM_ERROR_MESSAGE = 'this assessee is unconfirmed';
export const ASSESSEE_RESETPWD_ERROR_MESSAGE = 'this information is incorrect';
export const ASSESSEE_CREDENTIALRESET_ERROR_MESSAGE = 'this information is incorrect';
export const REQUIRED_ERROR_MESSAGE = 'this information is required';
export const INCORRECT_ERROR_MESSAGE = 'this information is incorrect';
export const INCORRECT_INFORMATION_ERROR_MESSAGE = 'this information is incorrect';
export const INFORMATION_EXIST_ERROR_MESSAGE = 'this information is existent';
export const INVALID_PASSWORD_ERROR_MESSAGE = 'this information is invalid';
export const INVALID_AUTH_CODE_ERROR_MESSAGE = 'this information is invalid';
export const EXCEPTION_ERROR_MESSAGE = 'the system has encountered an unexpected error; try again';
export const TIME_OUT_ERROR = 'the system has encountered a time-out error; try again';
export const INVALID_IMAGE_ERROR_MESSAGE = 'this information is invalid';
export const CAN_NOT_REVISED_MESSAGE = 'this information is unrevisable';
export const ASSESSMENT_PREIVEW_SEQUENCE_ERROR = 'the assessment sequence is undefined';
export const ASSESSMENT_SECTION_PREIVEW_SEQUENCE_ERROR = 'the section sequence is undefined';

//password error msg
export const MINIMUM_8_CHARACTER_ERROR = 'your password should contain eight or more characters';
export const NUMBER_REQUIRED_ERROR = 'your password should contain one or more numerals';
export const SPECIAL_CHARACTER_REQUIRED_ERROR = 'your password should contain one or more special-characters';
export const UPPER_CHARACTER_REQUIRED_ERROR = 'your password should contain one or more uppercase-alphabets';
export const LOWER_CHARACTER_REQUIRED_ERROR = ' your password should contain one or more lowercase-alphabets';
export const INFORMATION_MISMATCHED_ERROR_MESSAGE = 'your password should match';

export const CLOSE_MIDDLEPANLIST_ERROR = 'the middle-pane revise is unfinished';
export const VALID_PHONE_NUMBER = 'this information is invalid';
export const QR_INVALID = 'the code is invalid';

// assignment assessment error messages
export const SELECT_ANOTHER_RESPONSE = 'select another response-choice';
export const SELECT_RESPONSE_CHOICE = 'select a response-choice';