import React, { useEffect, useState } from 'react';
import iGuruLogo from '../../images/iglogo1.png';
import { useDispatch, useSelector } from 'react-redux';
import '../DisplayPageSignIn/DisplayPageSignIn.css';
// import bgImg from '../../images/bg.jpeg'; // old background Image
import IconButtonUI from '../../Molecules/IconButton/IconButton';
import PersonIcon from '@material-ui/icons/Person';
import AssociateIcon from '@material-ui/icons/Stars';
import {
  ASSESSEE_SIGN_ON,
  ASSOCIATE_SIGN_ON,
  CLEAR_ASSESSEE_INFO,
  CLEAR_ASSOCIATE_INFO,
  LOADER_START,
  LOADER_STOP,
  SET_REQUEST_OBJECT
} from '../../actionType';
import PopUpAssesseeCreateSignOn from '../../Molecules/PopUpCreate/PopUpAssesseeCreateSignOn';
import PopUpAssociateCreateSignOn from '../../Molecules/PopUpCreate/PopUpAssociateCreateSignOn';
import SendIcon from '@material-ui/icons/Send';
import { IconButton } from '@material-ui/core';
import { Fragment } from 'react';
import LoadingComponent from '../../PopUpInformation/LoadingComponent';
import { useHistory, useParams } from 'react-router-dom';
import { AssociateSignOnSetUpApiCalls, AssociateMineAssignmentList } from '../../Actions/AssociateModuleAction';
import { makeMineAssignmentScanRequestObject } from '../../Actions/GenericActions';

const DisplayPageSignOn = () => {
  const dispatch = useDispatch();
  const { selectedAssociateInfo, signOnAssociateSetupPermission, signOnAssesseeSetupPermission } =
    useSelector((state) => state.DisplayPaneTwoReducer);
  const { associateTagPrimary } = useParams();
  useEffect(() => {
    dispatch({type: LOADER_START})
    AssociateSignOnSetUpApiCalls(associateTagPrimary, dispatch);
    let reqBody = makeMineAssignmentScanRequestObject('12313' , associateTagPrimary , '' ,-1 ,-1 ,'', '', false, true);
    dispatch({type: SET_REQUEST_OBJECT, payload: reqBody})
    AssociateMineAssignmentList(reqBody, dispatch);
    dispatch({ type: LOADER_STOP });
    dispatch({
      type: 'SET_SIGN_ON_SINGLE_STATE', payload: {
        stateName: 'associateId',
        value: associateTagPrimary
      }
    })
  }, []);
  const history = useHistory();
  useEffect(() => {
    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refreshToken');
    if (token || refreshToken) {
      let path = `/dashboard`;
      history.push(path);
    }
  });
  const bgImg = '../Image/bg.jpg';
  const style = {
    backgroundPosition: '50% 50%',
    backgroundImage: `url(${bgImg})`,
    backgroundSize: 'cover'
  };
  const [selected, setSelected] = useState('');
  const handleClick = () => {
    let type = selected;
    if (type === 'assessee') {
      dispatch({
        type: ASSESSEE_SIGN_ON,
        payload: { isPopUpValue: 'ASSESSEENAMEPOPUP', popupMode: 'ASSESSEE_SIGN_ON' }
      });
      dispatch({ type: CLEAR_ASSESSEE_INFO });
    }
    if (type === 'associate') {
      dispatch({
        type: ASSOCIATE_SIGN_ON,
        payload: {
          isPopUpValue: 'NAMEALIASPOPUP',
          popupMode: 'ASSOCIATE_SIGN_ON'
        }
      });
      dispatch({ type: CLEAR_ASSESSEE_INFO });
      dispatch({ type: CLEAR_ASSOCIATE_INFO });
    }
  };
  const { isPopUpOpen, popupMode } = useSelector((state) => state.PopUpReducer);
  const { isLoading } = useSelector((state) => state.LoaderReducer);
  // console.log('signOnAssociateSetupPermission', signOnAssociateSetupPermission);
  // console.log('signOnAssesseeSetupPermission', signOnAssesseeSetupPermission);
  let signOnsignOnAssociatePermission =
    signOnAssociateSetupPermission.associateDistinctSignOnPermission;
  let signOnAssesseePermission = signOnAssesseeSetupPermission.assesseeDistinctSignOnPermission;

  return (
    <div style={style} className="signin-container">
      <div className="form-box">
        {isPopUpOpen === false && (
          <Fragment>
            {' '}
            <div className="form-header">
              <div className="form-header-logo-container">
                <img className="form-header-logo-img" src={iGuruLogo} alt="iGuru logo" />
              </div>
              <div>
                <IconButton className="form-icon-style" onClick={handleClick}>
                  <SendIcon style={{ height: 20, width: 20 }} />
                </IconButton>
              </div>
            </div>
          </Fragment>
        )}
        {isPopUpOpen === false && (
          <div className="form-inputs-signoncantainer">
            <div id="dialog-description">
              <div className="true">
                <div className={'footerPopupIcons'}>
                  <div className={'mbPager'}>
                    {signOnAssesseePermission ? (
                      <div>
                        <IconButtonUI
                          colour={'displayPaneLeft'}
                          Icon={PersonIcon}
                          label={'assessee'}
                          labelTwo={'sign-on'}
                          className={selected === 'assessee' ? 'imageNASelected' : 'imageNA'}
                          onClick={() => {
                            setSelected('assessee');
                          }}
                          disabled={!signOnAssesseePermission}
                        />
                      </div>
                    ) : null}
                    {signOnsignOnAssociatePermission ? (
                      <div>
                        <IconButtonUI
                          colour={'displayPaneLeft'}
                          Icon={AssociateIcon}
                          label={'associate'}
                          labelTwo={'sign-on'}
                          className={selected === 'associate' ? 'imageNASelected' : 'imageNA'}
                          onClick={() => {
                            setSelected('associate');
                          }}
                          disabled={!signOnsignOnAssociatePermission}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {popupMode === 'ASSESSEE_SIGN_ON' && <PopUpAssesseeCreateSignOn />}
      {popupMode === 'ASSOCIATE_SIGN_ON' && <PopUpAssociateCreateSignOn />}
      <LoadingComponent isActive={isLoading} />
    </div>
  );
};
export default DisplayPageSignOn;
