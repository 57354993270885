import { put, takeLatest, call } from 'redux-saga/effects';
import {
  SET_DISPLAY_PANE_THREE_STATE,
  LOADER_STOP,
  GET_ASSIGNMENT_INFO_SAGA,
  SET_ASSIGNMENT_BASIC_REDUCER_STATE,
  ASSIGNMENT_INFO_REVISE_SAGA,
  SET_ASSIGNMENT_DYNAMIC_SINGLE_STATE,
  SET_POPUP_VALUE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  ASSIGNMENT_REVIEW_LIST_DISTINCT_SAGA,
  SET_ASSIGNMENT_RELATED_LIST,
  RESET_ASSIGNMENT_REVIEW_LIST_OBJECT,
  ASSIGNMENT_PUBLISH_SAGA,
  ASSIGNMENT_ADMINISTER_SAGA,
  SET_ASSIGNMENT_FRAMEWORK_STATE,
  ASSIGNMENT_ASSESSMENT_INFO_REVISE_SAGA,
  SET_DISPLAY_PANE_THREE_REVIEW_MODE,
  ASSIGNMENT_MULTI_STATUS_REVISE_SAGA,
  LOADER_START,
  GET_ASSIGNMENT_TYPE_REVIEW_LIST_SAGA,
  GET_ASSIGNMENT_GROUP_REVIEW_LIST_SAGA,
  RELATED_REVIEWLIST_DISTINCT_DATA,
  SET_MIDDLEPANE_STATE,
  GET_ASSIGNMENT_GUAGE_INFO_SAGA,
  SET_ASSIGNMENT_DYNAMIC_FRAMEWORK_STATE,
  POPUP_CLOSE,
  ASSIGNMENT_REVISE_SAGA,
  SET_DISPLAY_THREE_SINGLE_STATE,
  CLEAR_DISPLAY_PANE_THREE,
  SET_CREATE_MODE,
  GET_ASSIGNMENTGROUP_ASSIGNMENT_REVIEWLIST_SAGA,
  GET_ASSIGNMENTTYPE_ASSIGNMENT_REVIEWLIST_SAGA,
  SET_POPUP_SINGLE_STATE,
  SET_PAGE_COUNT,
  UPDATE_ASSESSEE_MINE_ASSIGNMENTS,
  FILTERMODE,
  CLEAR_PANE_TWO_REDUCER,
  ASSOCIATE_SIGN_ON_ASSIGNMENT_LIST_SAGA,
  SET_ASSIGNMENT_DYNAMIC_SETUP_STATE
} from '../../actionType';
import { ASSIGNMENT_MODULE_URL, ASSIGNMENT_MINE_ALLOCATE_MODULE_URL } from '../../endpoints';
import { EXCEPTION_ERROR_MESSAGE } from '../../errorMessage';
import Store from '../../store';
import { makeMineAssignmentScanRequestObject } from '../../Actions/GenericActions';

const assignmentInfoApi = async (requestObj) => {
  console.log(requestObj.data);
  let URL = requestObj.URL;
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      Authorization: localStorage.getItem('token')
    }),
    body: JSON.stringify(requestObj.data)
  };
  const response = await fetch(URL, requestOptions);
  const json = await response.json();
  return json;
};
function* workerReviewInfoAssignmentSaga(data) {
  try {
    const userResponse = yield call(assignmentInfoApi, {
      data: data.payload.reqBody,
      URL: ASSIGNMENT_MODULE_URL
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === '000') {
      console.log('ASSIGNMENT_REVIEW_INFO=======>', userResponse);
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'assignmentResponseObject', value: userResponse.responseObject[0] }
      });
      const { isReviseMode = false } = data.payload;
      yield put({
        type: SET_DISPLAY_PANE_THREE_STATE,
        payload: {
          headerOne: 'assignment',
          headerOneBadgeOne: 'distinct',
          headerOneBadgeTwo: 'information',
          headerOneBadgeThree: data.payload.secondaryOptionCheckValue,
          responseObject: userResponse.responseObject[0],
          reviewMode: isReviseMode ? 'revise' : '',
          createMode: data.payload.createMode
        }
      });
      yield put({ type: RESET_ASSIGNMENT_REVIEW_LIST_OBJECT });
      if (isReviseMode) {
        const { informationAllocation, informationBasic,informationSetup } = userResponse.responseObject[0];
        yield put({
          type: SET_ASSIGNMENT_BASIC_REDUCER_STATE,
          payload: informationBasic
        });
        yield put({
          type: SET_ASSIGNMENT_DYNAMIC_SETUP_STATE,
          payload: informationSetup
        });
        if (
          informationAllocation &&
          informationAllocation?.assignmentGroup?.assignmentGroupPrimary &&
          informationAllocation?.assignmentGroup?.assignmentGroupPrimary.length > 0
        ) {
          let tempArr = informationAllocation.assignmentGroup.assignmentGroupPrimary.map(
            (ob) => ob.id
          );
          yield put({
            type: SET_ASSIGNMENT_DYNAMIC_SINGLE_STATE,
            payload: {
              stateName: 'assignmentGroup',
              actualStateName: 'assignmentGroupPrimary',
              value: tempArr
            }
          });
        } else {
          yield put({
            type: SET_ASSIGNMENT_DYNAMIC_SINGLE_STATE,
            payload: {
              stateName: 'assignmentGroup',
              actualStateName: 'assignmentGroupPrimary',
              value: []
            }
          });
        }
        if (
          informationAllocation &&
          informationAllocation?.assignmentGroup?.assignmentGroupSecondary &&
          informationAllocation?.assignmentGroup?.assignmentGroupSecondary.length > 0
        ) {
          let tempArr = informationAllocation.assignmentGroup.assignmentGroupSecondary.map(
            (ob) => ob.id
          );
          yield put({
            type: SET_ASSIGNMENT_DYNAMIC_SINGLE_STATE,
            payload: {
              stateName: 'assignmentGroup',
              actualStateName: 'assignmentGroupSecondary',
              value: tempArr
            }
          });
        } else {
          yield put({
            type: SET_ASSIGNMENT_DYNAMIC_SINGLE_STATE,
            payload: {
              stateName: 'assignmentGroup',
              actualStateName: 'assignmentGroupSecondary',
              value: []
            }
          });
        }
        if (
          informationAllocation &&
          informationAllocation?.assignmentNode?.assignmentNodePrimary &&
          informationAllocation?.assignmentNode?.assignmentNodePrimary.length > 0
        ) {
          let tempArr = informationAllocation.assignmentNode.assignmentNodePrimary.map(
            (ob) => ob.id
          );
          yield put({
            type: SET_ASSIGNMENT_DYNAMIC_SINGLE_STATE,
            payload: {
              stateName: 'assignmentNode',
              actualStateName: 'assignmentNodePrimary',
              value: tempArr
            }
          });
        } else {
          yield put({
            type: SET_ASSIGNMENT_DYNAMIC_SINGLE_STATE,
            payload: {
              stateName: 'assignmentNode',
              actualStateName: 'assignmentNodePrimary',
              value: []
            }
          });
        }
        if (
          informationAllocation &&
          informationAllocation?.assignmentNode?.assignmentNodeSecondary &&
          informationAllocation?.assignmentNode?.assignmentNodeSecondary.length > 0
        ) {
          let tempArr = informationAllocation.assignmentNode.assignmentNodeSecondary.map(
            (ob) => ob.id
          );
          yield put({
            type: SET_ASSIGNMENT_DYNAMIC_SINGLE_STATE,
            payload: {
              stateName: 'assignmentNode',
              actualStateName: 'assignmentNodeSecondary',
              value: tempArr
            }
          });
        } else {
          yield put({
            type: SET_ASSIGNMENT_DYNAMIC_SINGLE_STATE,
            payload: {
              stateName: 'assignmentNode',
              actualStateName: 'assignmentNodeSecondary',
              value: []
            }
          });
        }
        if (
          informationAllocation &&
          informationAllocation?.assignmentType?.assignmentTypePrimary &&
          informationAllocation?.assignmentType?.assignmentTypePrimary.length > 0
        ) {
          let tempArr = informationAllocation.assignmentType.assignmentTypePrimary.map(
            (ob) => ob.id
          );
          yield put({
            type: SET_ASSIGNMENT_DYNAMIC_SINGLE_STATE,
            payload: {
              stateName: 'assignmentType',
              actualStateName: 'assignmentTypePrimary',
              value: tempArr
            }
          });
        } else {
          yield put({
            type: SET_ASSIGNMENT_DYNAMIC_SINGLE_STATE,
            payload: {
              stateName: 'assignmentType',
              actualStateName: 'assignmentTypePrimary',
              value: []
            }
          });
        }
        if (
          informationAllocation &&
          informationAllocation?.assignmentType?.assignmentTypeSecondary &&
          informationAllocation?.assignmentType?.assignmentTypeSecondary.length > 0
        ) {
          let tempArr = informationAllocation.assignmentType.assignmentTypeSecondary.map(
            (ob) => ob.id
          );
          yield put({
            type: SET_ASSIGNMENT_DYNAMIC_SINGLE_STATE,
            payload: {
              stateName: 'assignmentType',
              actualStateName: 'assignmentTypeSecondary',
              value: tempArr
            }
          });
        } else {
          yield put({
            type: SET_ASSIGNMENT_DYNAMIC_SINGLE_STATE,
            payload: {
              stateName: 'assignmentType',
              actualStateName: 'assignmentTypeSecondary',
              value: []
            }
          });
        }

        let assignmentAssessee =
          userResponse.responseObject[0]?.informationFramework?.assignmentAssesseeDistinct || [];
        // let assignmentAssessment =
        // userResponse.responseObject[0]?.informationFramework?.assignmentAssessment || [];
        let assignmentAssessment =
          userResponse.responseObject[0]?.informationFramework?.assignmentAssessmentDistinct?.map(
            (ob) => ob.assessmentId
          );
        // let assignmentGauge = userResponse.responseObject[0]?.informationFramework?.assignmentGaugeFramework?.map(
        //   (ob) => ob.id
        // );
        // yield put({
        //   type: SET_ASSIGNMENT_DYNAMIC_FRAMEWORK_STATE,
        //   payload: { listName: 'assignmentGaugeFramework', value: assignmentGauge }
        // });
        let assignmentCultureProfile =
          userResponse.responseObject[0]?.informationFramework?.assignmentCultureProfileDistinct ||
          [];
        let assignmentJobProfile =
          userResponse.responseObject[0]?.informationFramework?.assignmentJobProfileDistinct || [];
        yield put({
          type: SET_ASSIGNMENT_RELATED_LIST,
          payload: { listName: 'assignmentAssesseeList', value: assignmentAssessee }
        });
        yield put({
          type: SET_ASSIGNMENT_RELATED_LIST,
          payload: { listName: 'assignmentAssessmentList', value: assignmentAssessment }
        });
        yield put({
          type: SET_ASSIGNMENT_RELATED_LIST,
          payload: { listName: 'assignmentCultureProfileList', value: assignmentCultureProfile }
        });
        yield put({
          type: SET_ASSIGNMENT_RELATED_LIST,
          payload: { listName: 'assignmentJobProfileList', value: assignmentJobProfile }
        });
        yield put({
          type: SET_ASSIGNMENT_FRAMEWORK_STATE,
          payload: userResponse.responseObject[0]?.informationFramework
        });
        yield put({
          type: SET_ASSIGNMENT_DYNAMIC_FRAMEWORK_STATE,
          payload: {
            stateName: 'assignmentAdministrationSequence',
            value:
              userResponse.responseObject[0]?.informationFramework
                ?.assignmentAdministrationSequence || []
          }
        });
      }
      yield put({ type: LOADER_STOP });
    } else {
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}

function* workerAssignmentReviseSaga(data) {
  try {
    const userResponse = yield call(assignmentInfoApi, {
      data: data.payload.reqBody,
      URL: ASSIGNMENT_MODULE_URL
    });
    if (userResponse.responseCode === '000') {
      let response = userResponse.responseObject[0];
      yield put({
        type: SET_DISPLAY_THREE_SINGLE_STATE,
        payload: { stateName: 'isFrameworkOpen', value: 'assignmentFramework' }
      });
      yield put({
        type: SET_DISPLAY_THREE_SINGLE_STATE,
        payload: { stateName: 'responseObject', value: response }
      });
      let assignmentAssessment = response?.informationFramework?.assignmentAssessmentDistinct?.map(
        (ob) => ob.assessmentId
      );
      let assignmentAssessee = response?.informationFramework?.assignmentAssesseeDistinct;
      let assignmentCultureProfile =
        response?.informationFramework?.assignmentCultureProfileDistinct;
      let assignmentJobProfile = response?.informationFramework?.assignmentJobProfileDistinct;
      yield put({
        type: SET_ASSIGNMENT_RELATED_LIST,
        payload: { listName: 'assignmentAssesseeList', value: assignmentAssessee }
      });
      yield put({
        type: SET_ASSIGNMENT_RELATED_LIST,
        payload: { listName: 'assignmentAssessmentList', value: assignmentAssessment }
      });
      yield put({
        type: SET_ASSIGNMENT_RELATED_LIST,
        payload: { listName: 'assignmentCultureProfileList', value: assignmentCultureProfile }
      });
      yield put({
        type: SET_ASSIGNMENT_RELATED_LIST,
        payload: { listName: 'assignmentJobProfileList', value: assignmentJobProfile }
      });
      yield put({ type: LOADER_STOP });
    } else {
      console.log('hereeee 4');
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse?.responseMessage, popupMode: 'responseErrorMsg' }
      });
      yield put({ type: LOADER_STOP });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerReviseInfoAssignmentSaga(data) {
  try {
    const userResponse = yield call(assignmentInfoApi, {
      data: data.payload.reqBody,
      URL: ASSIGNMENT_MODULE_URL
    });
    console.log(' data.payload=======>', data.payload);
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === '000') {
      console.log('Revise infor=======>', userResponse);
      const { createMode = '' } = data.payload;
      yield put({
        type: SET_DISPLAY_THREE_SINGLE_STATE,
        payload: { stateName: 'isFrameworkOpen', value: '' }
      });
      if (!data.payload.hideRightPane) {
        console.log('hereeee 1');
        yield put({
          type: SET_DISPLAY_PANE_THREE_STATE,
          payload: {
            headerOne: 'assignment',
            headerOneBadgeOne: data.payload.headerOneBadgeOne || 'distinct',
            headerOneBadgeThree: data.payload.headerOneBadgeThree,
            headerOneBadgeTwo: 'information',
            responseObject: userResponse.responseObject[0],
            createMode: createMode
          }
        });
      }
      if (createMode === '') {
        console.log('hereeee 2');
        if (data.payload.typeOfMiddlePaneList === 'assignmentTypeAssignmentReviewList') {
          yield put({ type: LOADER_START });
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'relatedReviewListDistinctData', value: [] }
          });
          yield put({
            type: GET_ASSIGNMENTTYPE_ASSIGNMENT_REVIEWLIST_SAGA,
            payload: {
              HeaderOne: 'assignments',
              request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        } else if (data.payload.typeOfMiddlePaneList === 'assignmentGroupAssignmentReviewList') {
          yield put({ type: LOADER_START });
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'relatedReviewListDistinctData', value: [] }
          });
          yield put({
            type: GET_ASSIGNMENTGROUP_ASSIGNMENT_REVIEWLIST_SAGA,
            payload: {
              HeaderOne: 'assignments',
              request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        } else {
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
          yield put({
            type: ASSIGNMENT_REVIEW_LIST_DISTINCT_SAGA,
            payload: {
              HeaderOne: 'assignments',
              request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        }
      } else {
        console.log('hereeee 3');
        yield put({ type: LOADER_STOP });
      }
      yield put({ type: SET_PAGE_COUNT, payload: 0 });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'typeOfMiddlePaneList', value: '' }
      });
    } else {
      console.log('hereeee 4');
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse?.responseMessage, popupMode: 'responseErrorMsg' }
      });
      yield put({ type: LOADER_STOP });
    }
    console.log('hereeee 5');
    yield put({
      type: SET_ASSIGNMENT_RELATED_LIST,
      payload: { listName: 'assignmentAssesseeList', value: [] }
    });
    yield put({
      type: SET_ASSIGNMENT_RELATED_LIST,
      payload: { listName: 'assignmentAssessmentList', value: [] }
    });
    yield put({
      type: SET_ASSIGNMENT_RELATED_LIST,
      payload: { listName: 'assignmentCultureProfileList', value: [] }
    });
    yield put({
      type: SET_ASSIGNMENT_RELATED_LIST,
      payload: { listName: 'assignmentJobProfileList', value: [] }
    });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerAssignmentPublishSaga(data) {
  try {
    const userResponse = yield call(assignmentInfoApi, {
      data: data.payload.reqBody,
      URL: ASSIGNMENT_MODULE_URL
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === '000') {
      console.log('ASSIGNMENT_PUBLISH_INFO=======>', userResponse);
      const { createMode = '' } = data.payload;
      if (!data.payload.hideRightPane) {
        yield put({
          type: SET_DISPLAY_PANE_THREE_STATE,
          payload: {
            headerOne: 'assignment',
            headerOneBadgeOne: 'distinct',
            headerOneBadgeTwo: 'information',
            headerOneBadgeThree: data.payload.secondaryOptionCheckValue,
            responseObject: userResponse.responseObject[0],
            createMode
          }
        });
      }

      if (data.payload.createMode === '') {
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'reviewListDistinctData', value: [] }
        });
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        yield put({ type: SET_PAGE_COUNT, payload: 0 });
        yield put({
          type: ASSIGNMENT_REVIEW_LIST_DISTINCT_SAGA,
          payload: {
            HeaderOne: 'assignments',
            request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
            BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
            isMiddlePaneList: true
          }
        });
      }
      yield put({ type: POPUP_CLOSE });
      yield put({ type: LOADER_STOP });
    } else if (userResponse.responseCode === '345') {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'FORCE_TO_PUBLISH_ASSIGNMENT', popupMode: 'ASSIGNMENTCREATE' }
      });
      yield put({ type: LOADER_STOP });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse?.responseMessage, popupMode: 'responseErrorMsg' }
      });
      yield put({ type: LOADER_STOP });
    }
    yield put({
      type: SET_ASSIGNMENT_RELATED_LIST,
      payload: { listName: 'assignmentAssesseeList', value: [] }
    });
    yield put({
      type: SET_ASSIGNMENT_RELATED_LIST,
      payload: { listName: 'assignmentAssessmentList', value: [] }
    });
    yield put({
      type: SET_ASSIGNMENT_RELATED_LIST,
      payload: { listName: 'assignmentCultureProfileList', value: [] }
    });
    yield put({
      type: SET_ASSIGNMENT_RELATED_LIST,
      payload: { listName: 'assignmentJobProfileList', value: [] }
    });
  } catch (e) {
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerAssignmentAdministerSaga(data) {
  try {
    const userResponse = yield call(assignmentInfoApi, {
      data: data.payload.reqBody,
      URL: ASSIGNMENT_MODULE_URL
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === '000') {
      const { createMode = '' } = data.payload;
      if (!data.payload.hideRightPane) {
        yield put({
          type: SET_DISPLAY_PANE_THREE_STATE,
          payload: {
            headerOne: 'assignment',
            headerOneBadgeOne: 'distinct',
            headerOneBadgeTwo: 'information',
            headerOneBadgeThree: data.payload.secondaryOptionCheckValue,
            responseObject: userResponse.responseObject[0],
            createMode
          }
        });
      }
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'typeOfMiddlePaneList', value: '' }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'reviewListDistinctData', value: [] }
      });
      yield put({
        type: ASSIGNMENT_REVIEW_LIST_DISTINCT_SAGA,
        payload: {
          HeaderOne: 'assignments',
          request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
          BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
          BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
          BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
          middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
          isMiddlePaneList: true
        }
      });
    } else {
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    yield put({
      type: SET_ASSIGNMENT_RELATED_LIST,
      payload: { listName: 'assignmentAssesseeList', value: [] }
    });
    yield put({
      type: SET_ASSIGNMENT_RELATED_LIST,
      payload: { listName: 'assignmentAssessmentList', value: [] }
    });
    yield put({
      type: SET_ASSIGNMENT_RELATED_LIST,
      payload: { listName: 'assignmentCultureProfileList', value: [] }
    });
    yield put({
      type: SET_ASSIGNMENT_RELATED_LIST,
      payload: { listName: 'assignmentJobProfileList', value: [] }
    });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}

function* workerReviseInfoAssignmentAssessmentSaga(data) {
  try {
    const userResponse = yield call(assignmentInfoApi, {
      data: data.payload.reqBody,
      URL: ASSIGNMENT_MODULE_URL
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === '000') {
      const { createMode = '' } = data.payload.reqBody;
      console.log('data.payload', data.payload.reqBody);
      let assessmentObj =
        userResponse.responseObject[0].informationFramework.assignmentAssessmentDistinct.filter(
          (test) => {
            return (
              test.assessmentId ===
              data.payload.reqBody.requestObject.assignmentAssessment.assessmentId
            );
          }
        );
      let assessmentReducerObj = Store.getState().AssessmentReducer;
      let responseObjectReducerObj = Store.getState().DisplayPaneThreeReducer.responseObject;
      let reviseResponseObj = {
        id: assessmentObj[0]?.assessmentId,
        informationBasic: assessmentReducerObj.informationBasic,
        informationAlliance: assessmentReducerObj.informationAlliance,
        informationAllocation: assessmentReducerObj.informationAllocation,
        informationEngagement: responseObjectReducerObj.informationEngagement,
        informationSetup: responseObjectReducerObj.informationSetup,
        informationFramework: {
          ...assessmentObj[0].informationFramework,
          assessmentNormFramework:
            assessmentReducerObj?.informationFramework?.assessmentNormFramework,
          assessmentItemDistinct:
            responseObjectReducerObj?.informationFramework?.assessmentItemDistinct,
          assessmentItemTrial: responseObjectReducerObj?.informationFramework?.assessmentItemTrial
        }
      };
      yield put({
        type: SET_DISPLAY_PANE_THREE_STATE,
        payload: {
          headerOne: 'assessment',
          headerOneBadgeOne: 'distinct',
          headerOneBadgeTwo: 'information',
          headerOneBadgeThree: data.payload.secondaryOptionCheckValue,
          responseObject: reviseResponseObj,
          createMode
        }
      });
      yield put({ type: LOADER_STOP });
      yield put({ type: SET_DISPLAY_PANE_THREE_REVIEW_MODE, payload: 'review' });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
      yield put({ type: LOADER_STOP });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerReviseMultiStatusAssignmentSaga(data) {
  try {
    const userResponse = yield call(assignmentInfoApi, {
      data: data.payload.reqBody,
      URL: ASSIGNMENT_MODULE_URL
    });
    if (userResponse.responseCode === '000') {
      yield put({ type: SET_PAGE_COUNT, payload: 0 });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'relatedReviewListDistinctData', value: [] }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'typeOfMiddlePaneList', value: '' }
      });
      yield put({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'selectionValue', value: '' }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isSelectActive', value: '' }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'selectedTagsArray', value: [] }
      });

      if (data.payload.reviseStatus === 'DELETED' && data.payload.createMode !== '') {
        yield put({ type: SET_CREATE_MODE, payload: '' });
        yield put({ type: CLEAR_DISPLAY_PANE_THREE });
        yield put({ type: LOADER_STOP });
      } else {
        if (data.payload.createMode === '') {
          if (data.payload.typeOfMiddlePaneList === 'assignmentsGroupDistinctReviewList') {
            yield put({ type: LOADER_START });
            yield put({
              type: SET_DISPLAY_TWO_SINGLE_STATE,
              payload: { stateName: 'typeOfMiddlePaneList', value: '' }
            });
            yield put({
              type: SET_DISPLAY_TWO_SINGLE_STATE,
              payload: { stateName: 'reviewListDistinctData', value: [] }
            });
            yield put({
              type: GET_ASSIGNMENT_GROUP_REVIEW_LIST_SAGA,
              payload: {
                HeaderOne: 'assignments',
                request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
                BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
                BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
                BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
                middlePaneSelectedValue:
                  Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
                isMiddlePaneList: true
              }
            });
          } else if (data.payload.typeOfMiddlePaneList === 'assignmentsTypeDistinctReviewList') {
            yield put({ type: LOADER_START });
            yield put({
              type: SET_DISPLAY_TWO_SINGLE_STATE,
              payload: { stateName: 'typeOfMiddlePaneList', value: '' }
            });
            yield put({
              type: SET_DISPLAY_TWO_SINGLE_STATE,
              payload: { stateName: 'reviewListDistinctData', value: [] }
            });
            yield put({
              type: GET_ASSIGNMENT_TYPE_REVIEW_LIST_SAGA,
              payload: {
                HeaderOne: 'assignments',
                request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
                BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
                BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
                BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
                middlePaneSelectedValue:
                  Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
                isMiddlePaneList: true
              }
            });
          } else if (data.payload.typeOfMiddlePaneList === 'assignmentGroupAssignmentReviewList') {
            yield put({ type: LOADER_START });
            yield put({
              type: GET_ASSIGNMENTGROUP_ASSIGNMENT_REVIEWLIST_SAGA,
              payload: {
                HeaderOne: 'assignments',
                request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
                BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
                BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
                BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
                middlePaneSelectedValue:
                  Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
                isMiddlePaneList: true
              }
            });
          } else if (data.payload.typeOfMiddlePaneList === 'assignmentsTypeDistinctReviewList') {
            yield put({ type: LOADER_START });
            yield put({
              type: SET_DISPLAY_TWO_SINGLE_STATE,
              payload: { stateName: 'typeOfMiddlePaneList', value: '' }
            });
            yield put({
              type: SET_DISPLAY_TWO_SINGLE_STATE,
              payload: { stateName: 'reviewListDistinctData', value: [] }
            });
            yield put({
              type: GET_ASSIGNMENT_TYPE_REVIEW_LIST_SAGA,
              payload: {
                HeaderOne: 'assignments',
                request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
                BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
                BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
                BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
                middlePaneSelectedValue:
                  Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
                isMiddlePaneList: true
              }
            });
          } else if (data.payload.typeOfMiddlePaneList === 'assignmentTypeAssignmentReviewList') {
            yield put({ type: LOADER_START });
            yield put({
              type: GET_ASSIGNMENTTYPE_ASSIGNMENT_REVIEWLIST_SAGA,
              payload: {
                HeaderOne: 'assignments',
                request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
                BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
                BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
                BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
                middlePaneSelectedValue:
                  Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
                isMiddlePaneList: true
              }
            });
          } else if (data.payload.typeOfMiddlePaneList === 'assignmentGroupAssignmentReviewList') {
            yield put({ type: LOADER_START });
            yield put({
              type: GET_ASSIGNMENTGROUP_ASSIGNMENT_REVIEWLIST_SAGA,
              payload: {
                HeaderOne: 'assignments',
                request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
                BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
                BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
                BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
                middlePaneSelectedValue:
                  Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
                isMiddlePaneList: true
              }
            });
          } else {
            if (data.payload.reviseStatus === 'DELETED') {
              yield put({ type: SET_CREATE_MODE, payload: '' });
              yield put({ type: CLEAR_DISPLAY_PANE_THREE });
              yield put({ type: LOADER_STOP });
            }
            yield put({
              type: SET_DISPLAY_TWO_SINGLE_STATE,
              payload: { stateName: 'reviewListDistinctData', value: [] }
            });
            yield put({
              type: ASSIGNMENT_REVIEW_LIST_DISTINCT_SAGA,
              payload: {
                HeaderOne: 'assignments',
                request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
                BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
                BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
                BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
                middlePaneSelectedValue:
                  Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
                isMiddlePaneList: true
              }
            });
          }
        } else yield put({ type: LOADER_STOP });
      }
    } else {
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerReviewInfoAssignmentGuagesSaga(data) {
  try {
    const userResponse = yield call(assignmentInfoApi, {
      data: data.payload.reqBody,
      URL: ASSIGNMENT_MODULE_URL
    });
    if (userResponse.responseCode === '000') {
      let assignmentInfo = userResponse.responseObject[0];
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'assignmentResponseObject', value: assignmentInfo }
      });
      let BadgeOne = 'gauges';
      let reviseResponseObj = {
        countTotal: assignmentInfo?.informationFramework?.assignmentGaugeFramework?.length || 0,
        responseObject: [
          {
            guages: assignmentInfo?.informationFramework?.assignmentGaugeFramework || [],
            assignmentName: assignmentInfo.informationBasic.assignmentName,
            assignmentDescription: assignmentInfo.informationBasic.assignmentDescription,
            assignmentStatus: assignmentInfo.informationEngagement.assignmentStatus,
            id: assignmentInfo.id,
            assignmentId: assignmentInfo.id
          }
        ]
      };
      if (data.payload.typeOfMiddlePaneList === 'assignmentDistinctMetricsDistinct') {
        BadgeOne = 'matrics';
        reviseResponseObj = {
          countTotal:
            assignmentInfo?.informationFramework?.assignmentGaugeFramework[0]?.assignmentGaugeMetric
              ?.length || 0,
          responseObject: [
            {
              guages: assignmentInfo?.informationFramework?.assignmentGaugeFramework || [],
              assignmentName: assignmentInfo.informationBasic.assignmentName,
              assignmentDescription: assignmentInfo.informationBasic.assignmentDescription,
              id: assignmentInfo.id,
              metrics: assignmentInfo?.informationFramework?.assignmentGaugeFramework[0]
            }
          ]
        };
      }
      yield put({
        type: RELATED_REVIEWLIST_DISTINCT_DATA,
        payload: reviseResponseObj.responseObject
      });
      yield put({
        type: SET_MIDDLEPANE_STATE,
        payload: {
          middlePaneHeader: 'assignment',
          middlePaneHeaderBadgeOne: BadgeOne,
          middlePaneHeaderBadgeTwo: 'distinct',
          middlePaneHeaderBadgeThree: '',
          middlePaneHeaderBadgeFour: '',
          typeOfMiddlePaneList: data.payload.typeOfMiddlePaneList,
          middlePaneSelectedValue: assignmentInfo.id,
          scanCount: reviseResponseObj && reviseResponseObj.countTotal,
          showMiddlePaneState: true
        }
      });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}

function* workerReviseAssignmentAssesse(data) {
  try {
    // console.log(ASSIGNMENT_MINE_ALLOCATE_MODULE_URL , 'ASSIGNMENT_MINE_ALLOCATE_MODULE_URL')
    yield put({ type: LOADER_START });
    const buyAssignmentApiCall = yield call(assignmentInfoApi, {
      data: data.payload.request,
      URL: ASSIGNMENT_MINE_ALLOCATE_MODULE_URL
    });

    if (buyAssignmentApiCall.responseCode === '000') {
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'reviewListDistinctData', value: [] }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'typeOfMiddlePaneList', value: '' }
      });
      // yield put({
      //   type: SET_MIDDLEPANE_STATE,
      //   payload: {
      //     middlePaneHeader: 'assignments',
      //     middlePaneHeaderBadgeOne: 'distinct',
      //     middlePaneHeaderBadgeTwo: 'mine',
      //     middlePaneHeaderBadgeThree: '',
      //     middlePaneHeaderBadgeFour: '',
      //     typeOfMiddlePaneList: 'associateMineAssignmentDistinctReviewList',
      //     scanCount: null,
      //     showMiddlePaneState: false,
      //     selectedTagsArray: [],
      //     unselectedTagsArray: [],
      //   }
      // });

      yield put({ type: SET_PAGE_COUNT, payload: 1 });

      let reqBody = makeMineAssignmentScanRequestObject(
        data.payload.request.assesseeId,
        data.payload.request.associateId,
        '',
        0,
        Store.getState().DisplayPaneTwoReducer.countPage,
        '',
        '',
        false,
        false
      );

      yield put({
        type: ASSOCIATE_SIGN_ON_ASSIGNMENT_LIST_SAGA,
        payload: {
          assignmentList: reqBody,
          url: ASSIGNMENT_MODULE_URL
        }
      });

      yield put({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'selectionValue', value: '' }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'nodeViewState', value: '' }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isSelectActive', value: '' }
      });

      yield put({
        type: FILTERMODE,
        payload: { FilterMode: 'associateMineAssignmentDistinct' }
      });

      yield put({ type: LOADER_STOP });
    } else {
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: buyAssignmentApiCall.responseMessage || EXCEPTION_ERROR_MESSAGE,
          popupMode: 'responseErrorMsg'
        }
      });
    }
  } catch (err) {
    console.log(err);
    yield put({ type: LOADER_STOP });
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
  }
}

export default function* watchReviewInfoAssignmentSaga() {
  yield takeLatest(GET_ASSIGNMENT_INFO_SAGA, workerReviewInfoAssignmentSaga);
  yield takeLatest(ASSIGNMENT_INFO_REVISE_SAGA, workerReviseInfoAssignmentSaga);
  yield takeLatest(ASSIGNMENT_REVISE_SAGA, workerAssignmentReviseSaga);
  yield takeLatest(ASSIGNMENT_PUBLISH_SAGA, workerAssignmentPublishSaga);
  yield takeLatest(ASSIGNMENT_ADMINISTER_SAGA, workerAssignmentAdministerSaga);
  yield takeLatest(
    ASSIGNMENT_ASSESSMENT_INFO_REVISE_SAGA,
    workerReviseInfoAssignmentAssessmentSaga
  );
  yield takeLatest(ASSIGNMENT_MULTI_STATUS_REVISE_SAGA, workerReviseMultiStatusAssignmentSaga);
  yield takeLatest(GET_ASSIGNMENT_GUAGE_INFO_SAGA, workerReviewInfoAssignmentGuagesSaga);
  yield takeLatest(UPDATE_ASSESSEE_MINE_ASSIGNMENTS, workerReviseAssignmentAssesse);
}
