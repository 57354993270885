import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import AllocationAccordian from '../Accordian/AllocationAccordian';
import Unverified from '../../images/unverified.svg';
import Verified from '../../images/verified2.svg';
import EditIcon from '@material-ui/icons/Edit';
import Fingerprint from '@material-ui/icons/Fingerprint';
import Manuscript from '@material-ui/icons/Description';
import DocumentScannerIcon from '@material-ui/icons/SettingsOverscan';
import MailOutline from '@material-ui/icons/MailOutline';
import MailIcon from '@material-ui/icons/Mail';
import TelephoneVerified from '@material-ui/icons/Call';
import TelephoneUnverified from '@material-ui/icons/CallOutlined';
import { useDispatch, useSelector } from 'react-redux';
import DisplayPanelAccordianReviewListOne from '../Accordian/DisplayPanelAccordianReviewListOne';
import DisplayPanelAccordianInformation from '../Accordian/DisplayPanelAccordianInformation';
import { Paper } from '@material-ui/core';
import { ASSESSEE_SIGN_ON, UPDATE_ASSESSEE_COMMUNICATION } from '../../actionType';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import { REPORT_URL } from '../../endpoints';

const DisplayPaneThreeSectionTwo = () => {
  const [listExpand, setListExpand] = useState('');
  const { responseObject, headerOneBadgeTwo, headerOneBadgeThree, reviewMode, headerOne } =
    useSelector((state) => state.DisplayPaneThreeReducer);
  const { selectedAssociateInfo } = useSelector((state) => state.DisplayPaneTwoReducer);
  const dispatch = useDispatch();
  const { informationContact, informationPersonal, informationCredential } = responseObject;

  // const longAddressForTest =
  //   '602 Silver Beliza, 48 St. Francis Avenue, SantaCruz West,  Mumbai, Maharashtra 400054, India';
  const {
    assesseeAddressCountryRegion = '',
    assesseeAddressProvinceState = '',
    assesseeAddressPostcode = '',
    assesseeAddressCity = '',
    assesseeAddress = ''
  } = informationContact?.assesseeAddressHomePrimary || {};
  // let homeAddressPrimary = `${assesseeAddress && assesseeAddress`, `} ${
  //   assesseeAddressCity && assesseeAddressCity`, `
  // } ${assesseeAddressProvinceState && assesseeAddressProvinceState`, `} ${
  //   assesseeAddressCountryRegion && assesseeAddressCountryRegion`, `
  // } ${assesseeAddressPostcode}`;
  let homeAddressPrimary =
    ((assesseeAddress && assesseeAddress + ', ') || '') +
    ((assesseeAddressCity && assesseeAddressCity + ', ') || '') +
    ((assesseeAddressProvinceState && assesseeAddressProvinceState + ' ') || '') +
    ((assesseeAddressPostcode && assesseeAddressPostcode + ', ') || '') +
    assesseeAddressCountryRegion;
  if (!homeAddressPrimary.trim()) {
    homeAddressPrimary = 'No Information';
  }
  const {
    assesseeAddressCountryRegion: addressCountryRegion = '',
    assesseeAddressProvinceState: addressProvinceState = '',
    assesseeAddressPostcode: addressPostcode = '',
    assesseeAddressCity: addressCity = '',
    assesseeAddress: address = ''
  } = informationContact?.assesseeAddressHomeSecondary || {};
  let homeAddressSecondary =
    ((address && address + ', ') || '') +
    ((addressCity && addressCity + ', ') || '') +
    ((addressProvinceState && addressProvinceState + ' ') || '') +
    ((addressPostcode && addressPostcode + ', ') || '') +
    addressCountryRegion;
  if (!homeAddressSecondary.trim()) {
    homeAddressSecondary = 'No Information';
  }
  const {
    assesseeAddressCountryRegion: workAddressPrimaryRegion = '',
    assesseeAddressProvinceState: workAddressPrimaryState = '',
    assesseeAddressPostcode: workAddressPrimaryPostcode = '',
    assesseeAddressCity: workAddressPrimaryCity = '',
    assesseeAddress: workAddressPrimaryAddress = ''
  } = informationContact?.assesseeAddressWorkPrimary || {};

  let workAddressPrimary =
    ((workAddressPrimaryAddress && workAddressPrimaryAddress + ', ') || '') +
    ((workAddressPrimaryCity && workAddressPrimaryCity + ', ') || '') +
    ((workAddressPrimaryState && workAddressPrimaryState + ' ') || '') +
    ((workAddressPrimaryPostcode && workAddressPrimaryPostcode + ', ') || '') +
    workAddressPrimaryRegion;

  if (!workAddressPrimary.trim()) {
    workAddressPrimary = 'No Information';
  }
  const {
    assesseeAddressCountryRegion: workAddressSecondaryCountryRegion = '',
    assesseeAddressProvinceState: workAddressSecondaryProvinceState = '',
    assesseeAddressPostcode: workAddressSecondaryPostcode = '',
    assesseeAddressCity: workAddressSecondaryCity = '',
    assesseeAddress: workAddressSecondaryAddress = ''
  } = informationContact?.assesseeAddressWorkSecondary || {};
  let workAddressSecondary =
    ((workAddressSecondaryAddress && workAddressSecondaryAddress + ', ') || '') +
    ((workAddressSecondaryCity && workAddressSecondaryCity + ', ') || '') +
    ((workAddressSecondaryProvinceState && workAddressSecondaryProvinceState + ' ') || '') +
    ((workAddressSecondaryPostcode && workAddressSecondaryPostcode + ', ') || '') +
    workAddressSecondaryCountryRegion;
  if (!workAddressSecondary.trim()) {
    workAddressSecondary = 'No Information';
  }
  // assesseeTelephoneHomePrimary: {
  //   assesseeTelephoneCountryRegion: '',
  //   assesseeTelephoneAreaCity: '',
  //   assesseeTelephoneNumber: '',
  //   assesseeTelephoneExtension: '',
  //   assesseeTelephoneCommunication: false,
  //   assesseeTelephoneVerification: false
  // },
  // +91 (citycode) number 'extension' extennumber
  // +91 (80) 786899 extension 987
  const {
    assesseeTelephoneAreaCity = '',
    assesseeTelephoneCountryRegion = '',
    assesseeTelephoneExtension = '',
    assesseeTelephoneNumber = '',
    assesseeTelephoneCommunication: primaryWorkTeleCommunication = false
  } = informationContact?.assesseeTelephoneWorkPrimary || {};
  let workTelephonePrimary = 'No Information';
  if (
    assesseeTelephoneAreaCity ||
    assesseeTelephoneCountryRegion ||
    assesseeTelephoneExtension ||
    assesseeTelephoneNumber
  ) {
    workTelephonePrimary = `+${assesseeTelephoneCountryRegion} (${assesseeTelephoneAreaCity}) ${assesseeTelephoneNumber} ${
      assesseeTelephoneExtension && 'extension ' + assesseeTelephoneExtension
    }`;
  }

  const {
    assesseeTelephoneAreaCity: assesseeTelephoneAreaCitySecondary = '',
    assesseeTelephoneCountryRegion: assesseeTelephoneCountryRegionSecondary = '',
    assesseeTelephoneExtension: assesseeTelephoneExtensionSecondary = '',
    assesseeTelephoneNumber: assesseeTelephoneNumberSecondary = '',
    assesseeTelephoneCommunication: secondaryWorkTeleCommunication = false
  } = informationContact?.assesseeTelephoneWorkSecondary || {};
  let workTelephoneSecondary = 'No Information';
  if (
    assesseeTelephoneAreaCitySecondary ||
    assesseeTelephoneCountryRegionSecondary ||
    assesseeTelephoneExtensionSecondary ||
    assesseeTelephoneNumberSecondary
  ) {
    workTelephoneSecondary = `+${assesseeTelephoneCountryRegionSecondary} (${assesseeTelephoneAreaCitySecondary}) ${assesseeTelephoneNumberSecondary} ${
      assesseeTelephoneExtensionSecondary && 'extension ' + assesseeTelephoneExtensionSecondary
    }`;
  }
  const {
    assesseeTelephoneAreaCity: assesseeHomeTelephoneCity = '',
    assesseeTelephoneCountryRegion: assesseeHomeTelephoneRegion = '',
    assesseeTelephoneExtension: assesseeHomeTelephoneExtension = '',
    assesseeTelephoneNumber: assesseeHomeTelephoneNumber = '',
    assesseeTelephoneCommunication: primaryHomeTeleCommunication = false
  } = informationContact?.assesseeTelephoneHomePrimary || {};
  let homeTelephonePrimary = 'No Information';
  if (
    assesseeHomeTelephoneCity ||
    assesseeHomeTelephoneRegion ||
    assesseeHomeTelephoneExtension ||
    assesseeHomeTelephoneNumber
  ) {
    homeTelephonePrimary = `+${assesseeHomeTelephoneRegion} (${assesseeHomeTelephoneCity}) ${assesseeHomeTelephoneNumber} ${
      assesseeHomeTelephoneExtension && 'extension ' + assesseeHomeTelephoneExtension
    }`;
  }
  const {
    assesseeTelephoneAreaCity: assesseeHomeTelephoneCitySecondary = '',
    assesseeTelephoneCountryRegion: assesseeHomeTelephoneRegionSecondary = '',
    assesseeTelephoneExtension: assesseeHomeTelephoneExtensionSecondary = '',
    assesseeTelephoneNumber: assesseeHomeTelephoneNumberSecondary = '',
    assesseeTelephoneCommunication: secondaryHomeTeleCommunication = false
  } = informationContact?.assesseeTelephoneHomeSecondary || {};
  let homeTelephoneSecondary = 'No Information';
  if (
    assesseeHomeTelephoneCitySecondary ||
    assesseeHomeTelephoneRegionSecondary ||
    assesseeHomeTelephoneExtensionSecondary ||
    assesseeHomeTelephoneNumberSecondary
  ) {
    homeTelephoneSecondary = `+${assesseeHomeTelephoneRegionSecondary} (${assesseeHomeTelephoneCitySecondary}) ${assesseeHomeTelephoneNumberSecondary} ${
      assesseeHomeTelephoneExtensionSecondary &&
      'extension ' + assesseeHomeTelephoneExtensionSecondary
    }`;
  }
  const onClickListIcon = (e) => {
    console.log('eeee', e);
    // console.log("eeee",e.currentTarget.getAttribute('id'));
    if (e === 'iGuru mine') {
      if (responseObject?.assesseeAssignmentId?.length > 0) {
        let signedAssesseeId = selectedAssociateInfo?.assesseeId;
        let signedAssociateId =
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary;
        let reportUrl = REPORT_URL + 'certificate';
        let assesseeId = responseObject?.id;
        let assesseeAssignmentId =
          responseObject?.assesseeAssignmentId[0] || '64b770e83519cc5b858fb942';
        let token =
          localStorage.getItem('token') + '@@@@' + signedAssesseeId + '@@@@' + signedAssociateId;
        window.open(
          reportUrl +
            '?assesseeId=' +
            assesseeId +
            '&assesseeAssignmentId=' +
            assesseeAssignmentId +
            '&token=' +
            token +
            ''
        );
      } else {
        alert('No certificate found.');
      }
    }

    // if(e === 'qrcodescaner')
    // {
    //   dispatch({
    //     type: ASSESSEE_SIGN_ON,
    //     payload: { isPopUpValue: 'PopUpQRScanner',  popupMode: 'ASSESSEE_CREATE', popupHeaderOne: headerOne }
    //   });
    // }
  };
  const {
    assesseeBirthplaceCity,
    assesseeBirthplaceCountryRegion,
    assesseeBirthplaceProvinceState,
    assesseeCommunitySocial,
    assesseeCommunitySpiritual
  } = informationPersonal || {};
  let assesseeBirthplaceStr = 'No Information';
  let assesseeCommunitySocialStr = 'No Information';
  let assesseeCommunitySpiritualStr = 'No Information';
  assesseeBirthplaceStr =
    (assesseeBirthplaceCity && assesseeBirthplaceCity + ', ') +
    (assesseeBirthplaceProvinceState && assesseeBirthplaceProvinceState + ', ') +
    (assesseeBirthplaceCountryRegion && assesseeBirthplaceCountryRegion + '.');
  assesseeCommunitySocialStr =
    (assesseeCommunitySocial.assesseeCommunity &&
      assesseeCommunitySocial.assesseeCommunity + ', ') +
    (assesseeCommunitySocial.assesseeCommunityCountryRegion &&
      assesseeCommunitySocial.assesseeCommunityCountryRegion + '.');
  assesseeCommunitySpiritualStr =
    (assesseeCommunitySpiritual.assesseeCommunity &&
      assesseeCommunitySpiritual.assesseeCommunity + ', ') +
    (assesseeCommunitySpiritual.assesseeCommunityCountryRegion &&
      assesseeCommunitySpiritual.assesseeCommunityCountryRegion + '.');

  const careerListAll = [
    {
      id: 'academia',
      labelTextOneOne: 'academia',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'education',
          textOne: ''
        },
        {
          labelTextOneOneBadge: 'license',
          textOne: ''
        }
      ],
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false
    },
    // {
    //   id: 'certificatea11',
    //   labelTextOneOne: 'iGuru mine',
    //   labelTextOneOneBadges: [
    //     {
    //       labelTextOneOneBadge: 'certificate',
    //       textOne: ''
    //     }
    //   ],
    //   innerAssociateList: [],
    //   innerInfo: 'assessees',
    //   isListCard: false,
    //   IconOne: Manuscript
    // },
    // {
    //   id: 'qrcodescaner',
    //   labelTextOneOne: 'qrcodescaner',
    //   labelTextOneOneBadges: [
    //     {
    //       labelTextOneOneBadge: 'iGuru',
    //       textOne: ''
    //     }
    //   ],
    //   innerAssociateList: [],
    //   innerInfo: 'assessees',
    //   isListCard: false,
    //   IconOne: DocumentScannerIcon
    // },
    {
      id: 'profile',
      labelTextOneOne: 'profile',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'primary',
          textOne: ''
        },
        {
          labelTextOneOneBadge: 'secondary',
          textOne: ''
        }
      ],
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false,
      IconOne: Manuscript
    },
    // {
    //   id: 'report',
    //   labelTextOneOne: 'report',
    //   labelTextOneOneBadges: [],
    //   innerAssociateList: [],
    //   innerInfo: 'assessees',
    //   isListCard: false,
    //   IconOne: Manuscript
    // },
    {
      id: 'tag',
      labelTextOneOne: 'tag',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'iGuru',
          textOne: ''
        }
      ],
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false
    }
  ];
  const emailPrimaryCommunication =
    informationContact?.assesseeAddressEmailPrimary?.assesseeAddressEmailCommunication || false;
  const emailSecondaryCommunication =
    informationContact?.assesseeAddressEmailSecondary?.assesseeAddressEmailCommunication || false;
  const emailPrimaryVerification =
    informationContact?.assesseeAddressEmailPrimary?.assesseeAddressEmailVerification || false;
  const emailSecondaryVerification =
    informationContact?.assesseeAddressEmailSecondary?.assesseeAddressEmailVerification || false;

  const homeAddressPrimaryCommunication =
    informationContact?.assesseeAddressHomePrimary?.assesseeAddressCommunication || false;
  const homeAddressSecondaryCommunication =
    informationContact?.assesseeAddressHomeSecondary?.assesseeAddressCommunication || false;
  const homeAddressPrimaryVerification =
    informationContact?.assesseeAddressHomePrimary?.assesseeAddressVerification || false;
  const homeAddressSecondaryVerification =
    informationContact?.assesseeAddressHomeSecondary?.assesseeAddressVerification || false;

  const workAddressPrimaryCommunication =
    informationContact?.assesseeAddressWorkPrimary?.assesseeAddressCommunication || false;
  const workAddressSecondaryCommunication =
    informationContact?.assesseeAddressWorkSecondary?.assesseeAddressCommunication || false;
  const workAddressPrimaryVerification =
    informationContact?.assesseeAddressWorkPrimary?.assesseeAddressVerification || false;
  const workAddressSecondaryVerification =
    informationContact?.assesseeAddressWorkSecondary?.assesseeAddressVerification || false;

  const primaryMobileCountry =
    informationContact.assesseeTelephoneMobilePrimary?.assesseeTelephoneCountryRegion || '';
  const primaryMobileNumber =
    informationContact.assesseeTelephoneMobilePrimary?.assesseeTelephoneNumber || '';
  const mobileTelephonePrimary =
    primaryMobileCountry && primaryMobileNumber
      ? '+' + primaryMobileCountry + ' ' + primaryMobileNumber
      : null;
  const primaryMobileCommunication =
    informationContact.assesseeTelephoneMobilePrimary?.assesseeTelephoneCommunication;

  const secondaryMobileCommunication =
    informationContact.assesseeTelephoneMobileSecondary?.assesseeTelephoneCommunication;

  const secondaryMobileCountry =
    informationContact.assesseeTelephoneMobileSecondary?.assesseeTelephoneCountryRegion || '';
  const secondaryMobileNumber =
    informationContact.assesseeTelephoneMobileSecondary?.assesseeTelephoneNumber || '';
  const mobileTelephoneSecondary =
    secondaryMobileCountry && secondaryMobileNumber
      ? '+' + secondaryMobileCountry + ' ' + secondaryMobileNumber
      : null;
  const signatureVerification = informationCredential?.assesseeSignatureVerification || false;
  const contactListAll = [
    {
      id: 'emailPrimary',
      labelTextOneOne: 'email address',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'primary',
          textOne:
            informationContact.assesseeAddressEmailPrimary.assesseeAddressEmail || 'No Information',
          IconOne: emailPrimaryCommunication ? MailIcon : MailOutline,
          IconTwo: () => {
            return (
              (emailPrimaryVerification && <VerifiedUserOutlinedIcon style={{fontSize: "20px"}} />) || (
                <img src={Unverified} alt="Unverified" />
              )
            );
          }
        },
        {
          labelTextOneOneBadge: 'secondary',
          textOne:
            informationContact.assesseeAddressEmailSecondary.assesseeAddressEmail ||
            'No Information',
          IconOne: emailSecondaryCommunication ? MailIcon : MailOutline,
          IconTwo: () => {
            return (
              (emailSecondaryVerification && <VerifiedUserOutlinedIcon style={{fontSize: "20px"}} />) || (
                <img src={Unverified} alt="Unverified" />
              )
            );
          }
        }
      ],
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false,
      IconOne: emailPrimaryCommunication ? MailIcon : MailOutline,
      IconTwo: () => {
        return <img src={Unverified} alt="Unverified" />;
      }
    },
    {
      id: 'a2homeadd',
      labelTextOneOne: 'home address',
      multiline: true,
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'primary',
          textOne: homeAddressPrimary, //longAddressForTest
          IconOne: homeAddressPrimaryCommunication ? MailIcon : MailOutline,
          IconTwo: () => {
            return (
              (homeAddressPrimaryVerification && <VerifiedUserOutlinedIcon style={{fontSize: "20px"}} />) || (
                <img src={Unverified} alt="Unverified" />
              )
            );
          }
        },
        {
          labelTextOneOneBadge: 'secondary',
          textOne: homeAddressSecondary,
          IconOne: homeAddressSecondaryCommunication ? MailIcon : MailOutline,
          IconTwo: () => {
            return (
              (homeAddressSecondaryVerification && <VerifiedUserOutlinedIcon style={{fontSize: "20px"}} />) || (
                <img src={Unverified} alt="Unverified" />
              )
            );
          }
        }
      ],
      labelTextOneOneBadgeThree: '',
      labelTextOneOneBadgeFour: '',
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false,
      IconOne: MailOutline,
      IconTwo: () => {
        return <img src={Unverified} alt="Unverified" />;
      }
    },
    {
      id: 'a3hometele',
      labelTextOneOne: 'home telephone',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'primary',
          textOne: homeTelephonePrimary,
          IconOne: (primaryHomeTeleCommunication && TelephoneVerified) || TelephoneUnverified,
          IconTwo: () => {
            return <img src={Unverified} alt="Unverified" />;
          }
        },
        {
          labelTextOneOneBadge: 'secondary',
          textOne: homeTelephoneSecondary,
          IconOne: (secondaryHomeTeleCommunication && TelephoneVerified) || TelephoneUnverified,
          IconTwo: () => {
            return <img src={Unverified} alt="Unverified" />;
          }
        }
      ],
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false
    },
    {
      id: 'mobile',
      labelTextOneOne: 'mobile telephone',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'primary',
          textOne: (mobileTelephonePrimary !== '+' && mobileTelephonePrimary) || 'No Information',
          IconOne: (primaryMobileCommunication && TelephoneVerified) || TelephoneUnverified,
          IconTwo: () => {
            return <img src={Unverified} alt="Unverified" />;
          }
        },
        {
          labelTextOneOneBadge: 'secondary',
          textOne:
            (mobileTelephoneSecondary !== '+' && mobileTelephoneSecondary) || 'No Information',
          IconOne: (secondaryMobileCommunication && TelephoneVerified) || TelephoneUnverified,
          IconTwo: () => {
            return <img src={Unverified} alt="Unverified" />;
          }
        }
      ],
      labelTextOneOneBadgeFour: '',
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false
    },
    {
      id: 'a5',
      labelTextOneOne: 'work address',
      multiline: true,
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'primary',
          textOne: workAddressPrimary,
          IconOne: workAddressPrimaryCommunication ? MailIcon : MailOutline,
          IconTwo: () => {
            return (
              (workAddressPrimaryVerification && <VerifiedUserOutlinedIcon style={{fontSize: "20px"}} />) || (
                <img src={Unverified} alt="Unverified" />
              )
            );
          }
        },
        {
          labelTextOneOneBadge: 'secondary',
          textOne: workAddressSecondary,
          IconOne: workAddressSecondaryCommunication ? MailIcon : MailOutline,
          IconTwo: () => {
            return (
              (workAddressSecondaryVerification && <VerifiedUserOutlinedIcon style={{fontSize: "20px"}} />) || (
                <img src={Unverified} alt="Unverified" />
              )
            );
          }
        }
      ],
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false,
      IconOne: MailOutline,
      IconTwo: () => {
        return <img src={Unverified} alt="Unverified" />;
      }
    },
    {
      id: 'a3workTele',
      labelTextOneOne: 'work telephone',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'primary',
          textOne: workTelephonePrimary,
          IconOne: (primaryWorkTeleCommunication && TelephoneVerified) || TelephoneUnverified,
          IconTwo: () => {
            return <img src={Unverified} alt="Unverified" />;
          }
        },
        {
          labelTextOneOneBadge: 'secondary',
          textOne: workTelephoneSecondary,
          IconOne: (secondaryWorkTeleCommunication && TelephoneVerified) || TelephoneUnverified,
          IconTwo: () => {
            return <img src={Unverified} alt="Unverified" />;
          }
        }
      ],
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false,
      IconOne: TelephoneVerified,
      IconTwo: () => {
        return <img src={Unverified} alt="Unverified" />;
      }
    }
  ];
  const credentialListAll = [
    {
      id: 'fingerprint',
      labelTextOneOne: 'fingerprint',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'left hand',
          textOne: ''
        },
        {
          labelTextOneOneBadge: 'right hand',
          textOne: ''
        }
      ],
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false,
      IconOne: Fingerprint
    },
    {
      id: 'a2sign',
      labelTextOneOne: 'signature',
      textOneOne: '',
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false,
      IconOne: EditIcon,
      IconTwo: () => {
        return (
          (signatureVerification && <VerifiedUserOutlinedIcon style={{fontSize: "20px"}} />) || (
            <img src={Unverified} alt="Unverified" />
          )
        );
      }
      // IconTwo: () => {
      //   return <img src={Unverified} alt="Unverified" />;
      // }
    },
    {
      id: 'tag',
      labelTextOneOne: 'tag',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'statutory',
          textOne: 'No Information'
        }
      ],
      labelTextOneOneBadgeTwo: '',
      labelTextOneOneBadgeThree: '',
      labelTextOneOneBadgeFour: '',
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false,
      IconTwo: () => {
        return <img src={Unverified} alt="Unverified" />;
      }
    }
  ];
  const personalListAll = [
    {
      id: 'birthdate',
      labelTextOneOne: 'birthdate',
      textOneOne: informationPersonal.assesseeBirthdate || 'No Information',
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false,
      IconOne: () => {
        return <img src={Unverified} alt="Unverified" />;
      }
    },
    {
      id: 'a2birthmark',
      labelTextOneOne: 'birthmark',
      textOneOne: informationPersonal.assesseeBirthmark || 'No Information',
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false
    },
    {
      id: 'a3birth',
      labelTextOneOne: 'birthplace',
      textOneOne: assesseeBirthplaceStr || 'No Information',
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false
    },
    {
      id: 'community',
      labelTextOneOne: 'community',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'social',
          textOne: assesseeCommunitySocialStr || 'No Information'
        },
        {
          labelTextOneOneBadge: 'spiritual',
          textOne: assesseeCommunitySpiritualStr || 'No Information'
        }
      ],
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false
    },
    {
      id: 'gender',
      labelTextOneOne: 'gender',
      textOneOne: informationPersonal.assesseeGender || 'No Information',
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false
    }
  ];
  const contactListKey = [
    {
      id: 'email address',
      labelTextOneOne: 'email address',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'primary',
          textOne:
            informationContact.assesseeAddressEmailPrimary.assesseeAddressEmail || 'No Information'
        },
        {
          labelTextOneOneBadge: 'secondary',
          textOne:
            informationContact.assesseeAddressEmailSecondary.assesseeAddressEmail ||
            'No Information'
        }
      ],
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false,
      IconOne: MailOutline,
      IconTwo: () => {
        return <img src={Unverified} alt="Unverified" />;
      }
    },
    {
      id: 'a4',
      labelTextOneOne: 'mobile telephone',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'primary',
          textOne:
            informationContact?.assesseeTelephoneMobilePrimary?.assesseeTelephoneNumber ||
            'No Information'
        },
        {
          labelTextOneOneBadge: 'secondary',
          textOne:
            informationContact?.assesseeTelephoneMobileSecondary?.assesseeTelephoneNumber ||
            'No Information'
        }
      ],
      labelTextOneOneBadgeFour: '',
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false,
      IconOne: TelephoneVerified,
      IconTwo: () => {
        return <img src={Unverified} alt="Unverified" />;
      }
    }
  ];

  const personalListKey = [
    {
      id: 'a4',
      labelTextOneOne: 'gender',
      textOneOne: informationPersonal.assesseeGender || 'No Information',
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false
    }
  ];
  const reviseCareer = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    console.log('=====>', labelName);
  };

  const reviseContact = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    console.log('=====>', labelName, selectedBadgeName);
    if (labelName === 'email address') {
      dispatch({
        type: UPDATE_ASSESSEE_COMMUNICATION, //
        payload: responseObject?.informationContact?.assesseeAddressEmailPrimary
          ?.assesseeAddressEmailCommunication
          ? 'email address (primary)'
          : responseObject?.informationContact?.assesseeAddressEmailSecondary
              ?.assesseeAddressEmailCommunication
          ? 'email address (secondary)'
          : ''
      });
      if (selectedBadgeName === 'primary') {
        dispatch({
          type: ASSESSEE_SIGN_ON,
          payload: {
            isPopUpValue: 'EMAILPOPUP',
            popupMode: 'ASSESSEE_CREATE',
            popupHeaderOne: headerOne
          }
        });
      }
      if (selectedBadgeName === 'secondary') {
        dispatch({
          type: ASSESSEE_SIGN_ON,
          payload: {
            isPopUpValue: 'EMAILSECONDARYPOPUP',
            popupMode: 'ASSESSEE_CREATE',
            popupHeaderOne: headerOne
          }
        });
      }
    }
    if (labelName === 'home address') {
      if (selectedBadgeName === 'primary') {
        dispatch({
          type: ASSESSEE_SIGN_ON,
          payload: {
            isPopUpValue: 'HOMEADDRESSPOPUP',
            popupMode: 'ASSESSEE_CREATE',
            popupHeaderOne: headerOne
          }
        });
      }
      if (selectedBadgeName === 'secondary') {
        dispatch({
          type: ASSESSEE_SIGN_ON,
          payload: {
            isPopUpValue: 'HOMEADDRESSSECONDARYPOPUP',
            popupMode: 'ASSESSEE_CREATE',
            popupHeaderOne: headerOne
          }
        });
      }
    }
    if (labelName === 'work address') {
      if (selectedBadgeName === 'primary') {
        dispatch({
          type: ASSESSEE_SIGN_ON,
          payload: {
            isPopUpValue: 'WORKADDRESSPOPUP',
            popupMode: 'ASSESSEE_CREATE',
            popupHeaderOne: headerOne
          }
        });
      }
      if (selectedBadgeName === 'secondary') {
        dispatch({
          type: ASSESSEE_SIGN_ON,
          payload: {
            isPopUpValue: 'WORKADDRESSSECONDARYPOPUP',
            popupMode: 'ASSESSEE_CREATE',
            popupHeaderOne: headerOne
          }
        });
      }
    }
    if (labelName === 'home telephone') {
      if (selectedBadgeName === 'primary') {
        dispatch({
          type: ASSESSEE_SIGN_ON,
          payload: {
            isPopUpValue: 'HOMETELEPHONEPOPUP',
            popupMode: 'ASSESSEE_CREATE',
            popupHeaderOne: headerOne
          }
        });
      }
      if (selectedBadgeName === 'secondary') {
        dispatch({
          type: ASSESSEE_SIGN_ON,
          payload: {
            isPopUpValue: 'HOMETELEPHONESECONDARYPOPUP',
            popupMode: 'ASSESSEE_CREATE',
            popupHeaderOne: headerOne
          }
        });
      }
    }
    if (labelName === 'work telephone') {
      if (selectedBadgeName === 'primary') {
        dispatch({
          type: ASSESSEE_SIGN_ON,
          payload: {
            isPopUpValue: 'WORKTELEPHONEPOPUP',
            popupMode: 'ASSESSEE_CREATE',
            popupHeaderOne: headerOne
          }
        });
      }
      if (selectedBadgeName === 'secondary') {
        dispatch({
          type: ASSESSEE_SIGN_ON,
          payload: {
            isPopUpValue: 'WORKTELEPHONESECONDARPOPUP',
            popupMode: 'ASSESSEE_CREATE',
            popupHeaderOne: headerOne
          }
        });
      }
    }
    if (labelName === 'mobile telephone') {
      if (selectedBadgeName === 'primary') {
        dispatch({
          type: ASSESSEE_SIGN_ON,
          payload: {
            isPopUpValue: 'MOBILETELEPHONEPOPUP',
            popupMode: 'ASSESSEE_CREATE',
            popupHeaderOne: headerOne
          }
        });
      }
      if (selectedBadgeName === 'secondary') {
        dispatch({
          type: ASSESSEE_SIGN_ON,
          payload: {
            isPopUpValue: 'MOBILETELEPHONESECONDARYPOPUP',
            popupMode: 'ASSESSEE_CREATE',
            popupHeaderOne: headerOne
          }
        });
      }
    }
  };

  const reviseCredential = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    console.log('=====>', labelName);
    if (labelName === 'fingerprint') {
      if (selectedBadgeName === 'left hand') {
        dispatch({
          type: ASSESSEE_SIGN_ON,
          payload: {
            isPopUpValue: 'LEFTFINGERPRINTPOPUP',
            popupMode: 'ASSESSEE_CREATE',
            popupHeaderOne: headerOne
          }
        });
      }
      if (selectedBadgeName === 'right hand') {
        dispatch({
          type: ASSESSEE_SIGN_ON,
          payload: {
            isPopUpValue: 'RIGHTFINGERPRINTPOPUP',
            popupMode: 'ASSESSEE_CREATE',
            popupHeaderOne: headerOne
          }
        });
      }
    }
    if (labelName === 'signature') {
      dispatch({
        type: ASSESSEE_SIGN_ON,
        payload: {
          isPopUpValue: 'SIGNATUREPOPUP',
          popupMode: 'ASSESSEE_CREATE',
          popupHeaderOne: headerOne
        }
      });
    }
    if (labelName === 'tag') {
      dispatch({
        type: ASSESSEE_SIGN_ON,
        payload: {
          isPopUpValue: 'TAGSTATUTORY',
          popupMode: 'ASSESSEE_CREATE',
          popupHeaderOne: headerOne
        }
      });
    }
  };
  const reviewCredential = (labelName, selectedBadgeName) => {
    // const labelName = e.currentTarget.getAttribute('data-value');
    // const selectedBadgeName = e.currentTarget.getAttribute('id');
    console.log('=====>', labelName);
    if (labelName === 'fingerprint') {
      if (selectedBadgeName === 'left hand') {
        dispatch({
          type: ASSESSEE_SIGN_ON,
          payload: {
            isPopUpValue: 'LEFTFINGERPRINTPOPUP',
            popupMode: 'ASSESSEE_CREATE',
            popupHeaderOne: headerOne
          }
        });
      }
      if (selectedBadgeName === 'right hand') {
        dispatch({
          type: ASSESSEE_SIGN_ON,
          payload: {
            isPopUpValue: 'RIGHTFINGERPRINTPOPUP',
            popupMode: 'ASSESSEE_CREATE',
            popupHeaderOne: headerOne
          }
        });
      }
    }
    if (labelName === 'signature') {
      dispatch({
        type: ASSESSEE_SIGN_ON,
        payload: {
          isPopUpValue: 'SIGNATUREPOPUP',
          popupMode: 'ASSESSEE_CREATE',
          popupHeaderOne: headerOne
        }
      });
    }
    // if (labelName === 'tag') {
    //   dispatch({
    //     type: ASSESSEE_SIGN_ON,
    //     payload: { isPopUpValue: 'TAGSTATUTORY',  popupMode: 'ASSESSEE_CREATE', popupHeaderOne: headerOne }
    //   });
    // }
  };

  const revisePersonal = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    console.log('=====>', labelName);
    if (labelName === 'birthdate') {
      dispatch({
        type: ASSESSEE_SIGN_ON,
        payload: {
          isPopUpValue: 'BIRTHDATEPOPUP',
          popupMode: 'ASSESSEE_CREATE',
          popupHeaderOne: headerOne
        }
      });
    }
    if (labelName === 'birthmark') {
      dispatch({
        type: ASSESSEE_SIGN_ON,
        payload: {
          isPopUpValue: 'BIRTHMARKPOPUP',
          popupMode: 'ASSESSEE_CREATE',
          popupHeaderOne: headerOne
        }
      });
    }
    if (labelName === 'birthplace') {
      dispatch({
        type: ASSESSEE_SIGN_ON,
        payload: {
          isPopUpValue: 'BIRTHPLACEPOPUP',
          popupMode: 'ASSESSEE_CREATE',
          popupHeaderOne: headerOne
        }
      });
    }
    if (labelName === 'community') {
      if (selectedBadgeName === 'social') {
        dispatch({
          type: ASSESSEE_SIGN_ON,
          payload: {
            isPopUpValue: 'COMMUNITYSOCIALPOPUP',
            popupMode: 'ASSESSEE_CREATE',
            popupHeaderOne: headerOne
          }
        });
      }
      if (selectedBadgeName === 'spiritual') {
        dispatch({
          type: ASSESSEE_SIGN_ON,
          payload: {
            isPopUpValue: 'COMMUNITYSPIRITUALPOPUP',
            popupMode: 'ASSESSEE_CREATE',
            popupHeaderOne: headerOne
          }
        });
      }
    }
    if (labelName === 'gender') {
      dispatch({
        type: ASSESSEE_SIGN_ON,
        payload: {
          isPopUpValue: 'SINGLEDROPDOWNPOPUP',
          popupMode: 'ASSESSEE_CREATE',
          popupHeaderOne: headerOne
        }
      });
    }
  };

  return (
    <div>
      {headerOneBadgeThree === 'all' ? (
        <>
          <div className="containerPadding">
            <AllocationAccordian
              headerOne="career"
              isDisplayCardExpanded={listExpand === 'career'}
              setListExpand={setListExpand}
              list={careerListAll}
              mode={reviewMode}
              onClickRevise={reviseCareer}
              onClickReviseIcon={onClickListIcon}
            />
          </div>
          <div className="containerPadding">
            <AllocationAccordian
              headerOne="contact"
              isDisplayCardExpanded={listExpand === 'contact'}
              setListExpand={setListExpand}
              list={contactListAll}
              mode={reviewMode}
              onClickRevise={reviseContact}
            />
          </div>
          <div className="containerPadding">
            <AllocationAccordian
              headerOne="credential"
              isDisplayCardExpanded={listExpand === 'credential'}
              setListExpand={setListExpand}
              list={credentialListAll}
              mode={reviewMode}
              onClickRevise={reviseCredential}
              onClickReview={reviewCredential}
            />
          </div>
          <div className="containerPadding">
            <AllocationAccordian
              headerOne="personal"
              isDisplayCardExpanded={listExpand === 'personal'}
              setListExpand={setListExpand}
              list={personalListAll}
              mode={reviewMode}
              onClickRevise={revisePersonal}
            />
          </div>
        </>
      ) : (
        <>
          <div className="containerPadding">
            <Paper className={'dossierContainerTop'}>
              {contactListKey.map((ob) => {
                return (
                  <div key={ob.id}>
                    {ob.isListCard ? (
                      <DisplayPanelAccordianReviewListOne
                        onClickRevise={reviseContact}
                        className=""
                        accordianObject={ob}
                        mode={reviewMode}
                      />
                    ) : (
                      <DisplayPanelAccordianInformation
                        onClickRevise={reviseContact}
                        accordianObject={ob}
                        mode={reviewMode}
                      />
                    )}
                  </div>
                );
              })}
            </Paper>
          </div>
          <div className="containerPadding">
            <Paper className={'dossierContainerTop'}>
              {personalListKey.map((ob) => {
                return (
                  <div key={ob.id}>
                    {ob.isListCard ? (
                      <DisplayPanelAccordianReviewListOne
                        onClickRevise={revisePersonal}
                        className=""
                        accordianObject={ob}
                        mode={reviewMode}
                      />
                    ) : (
                      <DisplayPanelAccordianInformation
                        onClickRevise={revisePersonal}
                        accordianObject={ob}
                        mode={reviewMode}
                      />
                    )}
                  </div>
                );
              })}
            </Paper>
          </div>
        </>
      )}
      {isMobile && (
        <div className={'containerPadding'} style={{ height: '55px' }}>
          {' '}
        </div>
      )}
    </div>
  );
};

export default DisplayPaneThreeSectionTwo;
