import { put, takeLatest, call } from 'redux-saga/effects';
import {
  GET_ASSESSEE_GROUP_REVIEW_LIST_SAGA,
  GET_ASSESSMENT_GROUP_REVIEW_LIST_SAGA,
  GET_ASSIGNMENT_GROUP_REVIEW_LIST_SAGA,
  GET_ASSOCIATE_GROUP_REVIEW_LIST_SAGA,
  GET_CULTUREPROFILE_GROUP_REVIEW_LIST_SAGA,
  GET_ITEM_GROUP_REVIEW_LIST_SAGA,
  GET_JOBPROFILE_GROUP_REVIEW_LIST_SAGA,
  LOADER_STOP,
  REVIEWLIST_DISTINCT_DATA,
  SET_CORE_GROUP_REVIEW_LIST_DATA,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MIDDLEPANE_STATE,
  SET_POPUP_SINGLE_STATE,
  SET_POPUP_VALUE
} from '../../actionType';
import {
  ASSIGNMENT_MODULE_URL,
  ASSESSMENT_MODULE_URL,
  ITEM_MODULE_URL,
  CULTURE_MODULE_URL,
  JOB_MODULE_URL,
  ASSOCIATE_MODULE_URL,
  ASSESSEE_MODULE_URL
} from '../../endpoints';
import { EXCEPTION_ERROR_MESSAGE } from '../../errorMessage';

const GroupsReviewListDistinctApi = async (requestObj) => {
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      Authorization: requestObj?.isIdToken
        ? localStorage.getItem('idToken')
        : localStorage.getItem('token')
    }),
    body: JSON.stringify(requestObj.data)
  };
  const response = await fetch(requestObj.URL, requestOptions);
  const json = await response.json();
  return json;
};

function* workerReviewGroupListSaga(data) {
  try {
    const userResponse = yield call(GroupsReviewListDistinctApi, {
      data: data.payload.request,
      URL: ASSESSEE_MODULE_URL
    });
    // console.log('00000000',data.payload);
    // const userResponse ={responseCode:'000',countTotal:30}
    const {
      groupViewState = 'list',
      isMiddlePaneList = false,
      middlePaneHeader,
      HeaderOne
    } = data.payload;
    if (userResponse.responseCode === '000') {
      if (isMiddlePaneList) {
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: middlePaneHeader
              ? middlePaneHeader
              : HeaderOne
                ? HeaderOne
                : 'assessees',
            middlePaneHeaderBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderBadgeThree: data.payload.BadgeThree,
            middlePaneHeaderBadgeFour: '',
            middlePaneHeaderDuplicate: middlePaneHeader
              ? middlePaneHeader
              : HeaderOne
                ? HeaderOne
                : 'assessees',
            middlePaneHeaderDuplicateBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderDuplicateBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderDuplicateBadgeThree: data.payload.BadgeThree,
            middlePaneHeaderDuplicateOne: middlePaneHeader
              ? middlePaneHeader
              : HeaderOne
                ? HeaderOne
                : 'assessees',
            middlePaneHeaderDuplicateOneBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderDuplicateOneBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderDuplicateOneBadgeThree: data.payload.BadgeThree,
            typeOfMiddlePaneList: 'assesseesGroupDistinctReviewList',
            scanCount: userResponse && userResponse.countTotal,
            duplicateScanCount: userResponse && userResponse.countTotal,
            duplicateScanCountOne: userResponse && userResponse.countTotal,
            showMiddlePaneState: true,
            isSelectActive: data.payload.isSelectActive,
            selectedTagsArray: data.payload.selectedTagsArray
          }
        });
        yield put({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'cardValue', value: data.payload.cardValue }
        });
      }
      let response = userResponse.responseObject;
      yield put({
        type: isMiddlePaneList ? REVIEWLIST_DISTINCT_DATA : SET_CORE_GROUP_REVIEW_LIST_DATA,
        payload: response
      });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}

function* workerReviewAssociateGroupListSaga(data) {
  try {
    const userResponse = yield call(GroupsReviewListDistinctApi, {
      data: data.payload.request,
      URL: ASSOCIATE_MODULE_URL
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    const { groupViewState = 'list' } = data.payload;
    if (userResponse.responseCode === '000') {
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: 'associates',
            middlePaneHeaderBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderBadgeThree: data.payload.BadgeThree,
            middlePaneHeaderBadgeFour: '',
            middlePaneHeaderDuplicate: 'associates',
            middlePaneHeaderDuplicateBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderDuplicateBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderDuplicateBadgeThree: data.payload.BadgeThree,
            middlePaneHeaderDuplicateOne: 'associates',
            middlePaneHeaderDuplicateOneBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderDuplicateOneBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderDuplicateOneBadgeThree: data.payload.BadgeThree,
            typeOfMiddlePaneList: 'associatesGroupDistinctReviewList',
            scanCount: userResponse && userResponse.countTotal,
            duplicateScanCount: userResponse && userResponse.countTotal,
            showMiddlePaneState: true,
            isSelectActive: data.payload.isSelectActive,
            selectedTagsArray: data.payload.selectedTagsArray
          }
        });
        yield put({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'cardValue', value: data.payload.cardValue }
        });
      }
      let response = userResponse.responseObject;
      yield put({
        type: data.payload.isMiddlePaneList
          ? REVIEWLIST_DISTINCT_DATA
          : SET_CORE_GROUP_REVIEW_LIST_DATA,
        payload: response
      });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerReviewAssessmentGroupListSaga(data) {
  try {
    const userResponse = yield call(GroupsReviewListDistinctApi, {
      data: data.payload.request,
      URL: ASSESSMENT_MODULE_URL
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    const { groupViewState = 'list' } = data.payload;
    if (userResponse.responseCode === '000') {
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: 'assessments',
            middlePaneHeaderBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderBadgeThree: data.payload.BadgeThree,
            middlePaneHeaderBadgeFour: '',
            middlePaneHeaderDuplicate: 'assessments',
            middlePaneHeaderDuplicateBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderDuplicateBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderDuplicateBadgeThree: data.payload.BadgeThree,
            middlePaneHeaderDuplicateOne: 'assessments',
            middlePaneHeaderDuplicateOneBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderDuplicateOneBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderDuplicateOneBadgeThree: data.payload.BadgeThree,
            typeOfMiddlePaneList: 'assessmentsGroupDistinctReviewList',
            scanCount: userResponse && userResponse.countTotal,
            duplicateScanCount: userResponse && userResponse.countTotal,
            showMiddlePaneState: true,
            isSelectActive: data.payload.isSelectActive,
            selectedTagsArray: data.payload.selectedTagsArray
          }
        });
        yield put({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'cardValue', value: data.payload.cardValue }
        });
      }
      let response = userResponse.responseObject;
      yield put({
        type: data.payload.isMiddlePaneList
          ? REVIEWLIST_DISTINCT_DATA
          : SET_CORE_GROUP_REVIEW_LIST_DATA,
        payload: response
      });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    console.log('loading end');
    yield put({ type: LOADER_STOP });
    // yield put({ type: CLEAR_ASSESSMENT_INFO });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerReviewAssignmentGroupListSaga(data) {
  try {
    const userResponse = yield call(GroupsReviewListDistinctApi, {
      data: data.payload.request,
      URL: ASSIGNMENT_MODULE_URL
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    const { groupViewState = 'list' } = data.payload;
    if (userResponse.responseCode === '000') {
      const { typeOfMiddlePaneList } = data.payload;
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: 'assignments',
            middlePaneHeaderBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderBadgeThree: data.payload.BadgeThree,
            middlePaneHeaderBadgeFour: '',
            middlePaneHeaderDuplicate: 'assignments',
            middlePaneHeaderDuplicateBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderDuplicateBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderDuplicateBadgeThree: data.payload.BadgeThree,
            middlePaneHeaderDuplicateOne: 'assignments',
            middlePaneHeaderDuplicateOneBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderDuplicateOneBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderDuplicateOneBadgeThree: data.payload.BadgeThree,
            middlePaneHeaderDuplicateOneBadgeFour: data.payload.BadgeFour,
            typeOfMiddlePaneList: typeOfMiddlePaneList
              ? typeOfMiddlePaneList
              : 'assignmentsGroupDistinctReviewList',
            scanCount: userResponse && userResponse.countTotal,
            duplicateScanCount: userResponse && userResponse.countTotal,
            showMiddlePaneState: true,
            isSelectActive: data.payload.isSelectActive,
            selectedTagsArray: data.payload.selectedTagsArray
          }
        });
        yield put({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'cardValue', value: data.payload.cardValue }
        });
      }
      let response = userResponse.responseObject;
      yield put({
        type: data.payload.isMiddlePaneList
          ? REVIEWLIST_DISTINCT_DATA
          : SET_CORE_GROUP_REVIEW_LIST_DATA,
        payload: response
      });
      yield put(
        {
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: "previousMiddlePaneState", value: typeOfMiddlePaneList
              ? typeOfMiddlePaneList
              : 'assignmentsGroupDistinctReviewList'
          },
        }
      )
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    console.log('loading end');
    yield put({ type: LOADER_STOP });
    // yield put({ type: CLEAR_ASSIGNMENT_INFO });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerReviewItemGroupListSaga(data) {
  try {
    const userResponse = yield call(GroupsReviewListDistinctApi, {
      data: data.payload.request,
      URL: ITEM_MODULE_URL
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    const { groupViewState = 'list' } = data.payload;
    if (userResponse.responseCode === '000') {
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: 'items',
            middlePaneHeaderBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderBadgeThree: data.payload.BadgeThree,
            middlePaneHeaderBadgeFour: '',
            middlePaneHeaderDuplicate: 'items',
            middlePaneHeaderDuplicateBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderDuplicateBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderDuplicateBadgeThree: data.payload.BadgeThree,
            middlePaneHeaderDuplicateOne: 'items',
            middlePaneHeaderDuplicateOneBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderDuplicateOneBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderDuplicateOneBadgeThree: data.payload.BadgeThree,
            middlePaneHeaderDuplicateOneBadgeFour: data.payload.BadgeFour,
            typeOfMiddlePaneList:
              data.payload.request.actionFilter === 'Item_Group_Shared_List' ||
                data.payload.request.actionFilter === 'Shared-Hierarchy'
                ? 'marketplaceItemsGroupDistinctReviewList'
                : 'itemsGroupDistinctReviewList',
            scanCount: userResponse && userResponse.countTotal,
            duplicateScanCount: userResponse && userResponse.countTotal,
            showMiddlePaneState: true,
            isSelectActive: data.payload.isSelectActive,
            selectedTagsArray: data.payload.selectedTagsArray
          }
        });
        yield put({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'cardValue', value: data.payload.cardValue }
        });
        console.log('loading end');
        yield put({ type: LOADER_STOP });
      }
      let response = userResponse.responseObject;
      yield put({
        type: data.payload.isMiddlePaneList
          ? REVIEWLIST_DISTINCT_DATA
          : SET_CORE_GROUP_REVIEW_LIST_DATA,
        payload: response
      });
      console.log('loading end');
      yield put({ type: LOADER_STOP });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
      console.log('loading end');
      yield put({ type: LOADER_STOP });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerReviewCulProfileGroupListSaga(data) {
  try {
    const userResponse = yield call(GroupsReviewListDistinctApi, {
      data: data.payload.request,
      URL: CULTURE_MODULE_URL,
      isIdToken: true
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    const { groupViewState = 'list' } = data.payload;
    if (userResponse.responseCode === '000') {
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: 'culture-profiles',
            middlePaneHeaderBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderBadgeThree: data.payload.BadgeThree,
            middlePaneHeaderBadgeFour: '',
            middlePaneHeaderDuplicate: 'culture-profiles',
            middlePaneHeaderDuplicateBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderDuplicateBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderDuplicateBadgeThree: data.payload.BadgeThree,
            middlePaneHeaderDuplicateOne: 'culture-profiles',
            middlePaneHeaderDuplicateOneBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderDuplicateOneBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderDuplicateOneBadgeThree: data.payload.BadgeThree,
            middlePaneHeaderDuplicateOneBadgeFour: data.payload.BadgeFour,
            typeOfMiddlePaneList:
              data.payload.request.actionFilter === 'Shared-Hierarchy' ||
                data.payload.request.actionFilter === 'Culture-Profile-Shared-Group'
                ? 'marketPlaceCultureProfilesGroupDistinctReviewList'
                : 'cultureProfilesGroupDistinctReviewList',
            scanCount: userResponse && userResponse.countTotal,
            duplicateScanCount: userResponse && userResponse.countTotal,
            showMiddlePaneState: true,
            isSelectActive: data.payload.isSelectActive,
            selectedTagsArray: data.payload.selectedTagsArray
          }
        });
        yield put({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'cardValue', value: data.payload.cardValue }
        });
        console.log('loading end');
        yield put({ type: LOADER_STOP });
      }
      let response = userResponse.responseObject;
      yield put({
        type: data.payload.isMiddlePaneList
          ? REVIEWLIST_DISTINCT_DATA
          : SET_CORE_GROUP_REVIEW_LIST_DATA,
        payload: response
      });
      console.log('loading end');
      yield put({ type: LOADER_STOP });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
      console.log('loading end');
      yield put({ type: LOADER_STOP });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerReviewJobProfileGroupListSaga(data) {
  try {
    const userResponse = yield call(GroupsReviewListDistinctApi, {
      data: data.payload.request,
      URL: JOB_MODULE_URL,
      isIdToken: true
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    const { groupViewState = 'list' } = data.payload;
    if (userResponse.responseCode === '000') {
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: 'job-profiles',
            middlePaneHeaderBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderBadgeThree: data.payload.BadgeThree,
            middlePaneHeaderBadgeFour: '',
            middlePaneHeaderDuplicate: 'job-profiles',
            middlePaneHeaderDuplicateBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderDuplicateBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderDuplicateBadgeThree: data.payload.BadgeThree,
            middlePaneHeaderDuplicateOne: 'job-profiles',
            middlePaneHeaderDuplicateOneBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderDuplicateOneBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderDuplicateOneBadgeThree: data.payload.BadgeThree,
            middlePaneHeaderDuplicateOneBadgeFour: data.payload.BadgeFour,
            typeOfMiddlePaneList:
              data.payload.request.actionFilter === 'Job-Profile-Shared-Group' ||
                data.payload.request.actionFilter === 'Shared-Hierarchy'
                ? 'MarketplaceJobProfileGroupReviewList'
                : 'jobProfilesGroupDistinctReviewList',
            scanCount: userResponse && userResponse.countTotal,
            duplicateScanCount: userResponse && userResponse.countTotal,
            showMiddlePaneState: true,
            isSelectActive: data.payload.isSelectActive,
            selectedTagsArray: data.payload.selectedTagsArray
          }
        });
        yield put({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'cardValue', value: data.payload.cardValue }
        });
        console.log('loading end');
        yield put({ type: LOADER_STOP });
      }
      let response = userResponse.responseObject;
      yield put({
        type: data.payload.isMiddlePaneList
          ? REVIEWLIST_DISTINCT_DATA
          : SET_CORE_GROUP_REVIEW_LIST_DATA,
        payload: response
      });
      console.log('loading end');
      yield put({ type: LOADER_STOP });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
      console.log('loading end');
      yield put({ type: LOADER_STOP });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
export default function* watchReviewGroupsListSaga() {
  yield takeLatest(GET_ASSESSEE_GROUP_REVIEW_LIST_SAGA, workerReviewGroupListSaga);
  yield takeLatest(GET_ASSOCIATE_GROUP_REVIEW_LIST_SAGA, workerReviewAssociateGroupListSaga);
  yield takeLatest(GET_ASSESSMENT_GROUP_REVIEW_LIST_SAGA, workerReviewAssessmentGroupListSaga);
  yield takeLatest(GET_ASSIGNMENT_GROUP_REVIEW_LIST_SAGA, workerReviewAssignmentGroupListSaga);
  yield takeLatest(GET_ITEM_GROUP_REVIEW_LIST_SAGA, workerReviewItemGroupListSaga);
  yield takeLatest(GET_CULTUREPROFILE_GROUP_REVIEW_LIST_SAGA, workerReviewCulProfileGroupListSaga);
  yield takeLatest(GET_JOBPROFILE_GROUP_REVIEW_LIST_SAGA, workerReviewJobProfileGroupListSaga);
}
