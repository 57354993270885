import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    ASSOCIATE_POPUP_CLOSE,
    FILTERMODE,
    FILTERMODE_ENABLE,
    ASSIGNMENT_REVIEW_LIST_DISTINCT_SAGA,
    LOADER_START,
    POPUP_OPEN,
    SET_DISPLAY_TWO_SINGLE_STATE,
    SET_PAGE_COUNT,
    SET_POPUP_STATE,
    ASSESSEE_ALLOCATE_ASSIGNMENT_SAGA,
    ASSESSMENT_ALLOCATE_ASSIGNMENT_SAGA,
    JOBPROFILE_ALLOCATE_ASSIGNMENT_SAGA,
    CULTUREPROFILE_ALLOCATE_ASSIGNMENT_SAGA,
    ASSIGNMENT_ALLOCATE_SAGA,
    CLEAR_DISPLAY_PANE_THREE,
    ASSIGNMENT_MARKETPLACE_REVIEW_DISTINCT_SAGA
} from '../actionType';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import { FilterList } from '@material-ui/icons';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import { ASSIGNMENT_REVIEW_LIST_POPUP_OPTION } from '../PopUpConfig';
import { assignmentsDistinctApiCall, getMarketplaceAssignmentDistinctApiCall } from '../Actions/AssignmentModuleAction';
import {
    onClickCheckBoxSelection,
    onClickFlagSelection,
    setFlagedArray
} from '../Actions/AssesseeModuleAction';
import { getPopUpOptionList } from '../Actions/GenericActions';
import ReviseIcon from '@material-ui/icons/RadioButtonChecked';
import Check from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import ListLoader from '../Atoms/ListLoader/ListLoader';

const AssignmentDistinctReviewList = (props) => {
    const dispatch = useDispatch();
    // const { secondaryOptionCheckValue } = useSelector(
    //   (state) => state.AssesseeCreateReducer
    // );
    const {
        numberPage,
        scanCount,
        reviewListDistinctData,
        reviewListReqObj,
        middlePaneSelectedValue,
        selectedAssociateInfo,
        isSelectActive,
        selectedTagsArray,
        unselectedTagsArray,
        allocatedTagsArray,
        allocateStr,
        selectedFlagedArray,
        unselectedFlagedArray,
        flagedValue,
        signedAssesseePermission,
        middlePaneHeaderBadgeOne,
        middlePaneHeaderBadgeTwo,
        middlePaneHeaderBadgeThree,
        assignmentCreateType,
        countPage,
        isListLoading
    } = useSelector((state) => state.DisplayPaneTwoReducer);
    const { FilterModeEnable, FilterMode } = useSelector((state) => state.FilterReducer);
    // const [isFetching, setIsFetching] = useState(false);
    // useEffect(() => {
    //   document.getElementById('middleComponentId').addEventListener('scroll', handleScroll);
    // }, []);
    // const handleScroll = (event) => {
    //   var targetPt = event.target;
    //   if (
    //     Math.ceil(targetPt.scrollHeight - targetPt.scrollTop) !== targetPt.clientHeight ||
    //     isFetching
    //   )
    //     return;
    //   setIsFetching(true);
    //   console.log('isFetching', isFetching);
    // };
    const fetchData = async () => {
        if (reviewListDistinctData.length < scanCount) {
            dispatch({
                type: SET_DISPLAY_TWO_SINGLE_STATE,
                payload: { stateName: 'isListLoading', value: true }
            });
            let obj = {
                ...reviewListReqObj.requestObject,
                numberPage: numberPage + 1
            };
            dispatch({
                type: ASSIGNMENT_MARKETPLACE_REVIEW_DISTINCT_SAGA,
                payload: {
                    request: {
                        "module": "Assignment-Distinct",
                        "action": "Review-List",
                        "actionFilter": "Shared-Assignment",
                        requestObject: obj
                    },
                    BadgeOne: 'distinct',
                    BadgeTwo: middlePaneHeaderBadgeTwo,
                    BadgeThree: middlePaneHeaderBadgeThree,
                    isMiddlePaneList: true,
                    isSelectActive: isSelectActive,
                    selectedTagsArray: selectedTagsArray || [],
                    unselectedTagsArray: unselectedTagsArray || []
                }
            });
            dispatch({ type: SET_PAGE_COUNT, payload: numberPage + 1 });
        }
    };
    // useEffect(() => {
    //   console.log(reviewListDistinctData);
    //   if (!isFetching) return;
    //   fetchMoreListItems();
    // }, [isFetching]);
    useEffect(() => {
        setFlagedArray(reviewListDistinctData, 'assignmentFlag', dispatch);
    }, [reviewListDistinctData]);
    // const fetchMoreListItems = () => {
    //   fetchData();
    //   setIsFetching(false);
    // };
    const siftApiCall = (siftKey) => {
        getMarketplaceAssignmentDistinctApiCall(
            selectedAssociateInfo,
            siftKey,
            countPage,
            dispatch,
            'distinct',
            '',
            assignmentCreateType
        );
        dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        dispatch({ type: ASSOCIATE_POPUP_CLOSE });
        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
        document.getElementById('middleComponentId').scrollTop = '0px';
    };
    const onClickFooter = (e) => {
        let siftValue = e.currentTarget.getAttribute('data-value');
        dispatch({ type: FILTERMODE_ENABLE });
        if (
            siftValue === 'suspended' ||
            siftValue === 'terminated' ||
            siftValue === 'unpublished' ||
            siftValue === 'published' ||
            siftValue === 'administered'
        )
            siftApiCall(siftValue);
        if (siftValue === 'finish') {
            console.log('allocateStr', allocateStr);
            let distinctAllocateStr =
                allocateStr === 'assesseesgroups'
                    ? 'assesseeGroup'
                    : allocateStr === 'assesseesdistinct'
                        ? 'assesseeDistinct'
                        : allocateStr === 'assesseestypes'
                            ? 'assesseeType'
                            : allocateStr === 'assesseesroles'
                                ? 'assesseeRole'
                                : allocateStr === 'assesseesnodes'
                                    ? 'assesseeNode'
                                    : allocateStr === 'assessmentsdistinct'
                                        ? 'assessmentDistinct'
                                        : allocateStr === 'assessmentsgroups'
                                            ? 'assessmentGroup'
                                            : allocateStr === 'assessmentstypes'
                                                ? 'assessmentType'
                                                : allocateStr === 'assessmentsnodes'
                                                    ? 'assessmentNode'
                                                    : allocateStr === 'culture-profilesdistinct'
                                                        ? 'cultureProfileDistinct'
                                                        : allocateStr === 'cultureprofilesdistinct'
                                                            ? 'cultureProfileDistinct'
                                                            : allocateStr === 'culture-profilesgroups'
                                                                ? 'cultureProfileGroup'
                                                                : allocateStr === 'culture-profilesnodes'
                                                                    ? 'cultureProfileNode'
                                                                    : allocateStr === 'culture-profilestypes'
                                                                        ? 'cultureProfileType'
                                                                        : allocateStr === 'job-profilesdistinct'
                                                                            ? 'jobProfileDistinct'
                                                                            : allocateStr === 'jobprofilesdistinct'
                                                                                ? 'jobProfileDistinct'
                                                                                : allocateStr === 'job-profilesgroups'
                                                                                    ? 'jobProfileGroup'
                                                                                    : allocateStr === 'job-profilesnodes'
                                                                                        ? 'jobProfileNode'
                                                                                        : allocateStr === 'job-profilestypes'
                                                                                            ? 'jobProfileType'
                                                                                            : allocateStr === 'assignmentsdistinct'
                                                                                                ? 'assignmentDistinct'
                                                                                                : allocateStr === 'assignmentsgroups'
                                                                                                    ? 'assignmentGroup'
                                                                                                    : allocateStr === 'assignmentstypes'
                                                                                                        ? 'assignmentType'
                                                                                                        : allocateStr === 'assignmentsnodes'
                                                                                                            ? 'assignmentNode'
                                                                                                            : '';
            if (distinctAllocateStr !== '' && selectedTagsArray.length !== 0) {
                if (
                    distinctAllocateStr === 'assesseeGroup' ||
                    distinctAllocateStr === 'assesseeDistinct' ||
                    distinctAllocateStr === 'assesseeType' ||
                    distinctAllocateStr === 'assesseeRole' ||
                    distinctAllocateStr === 'assesseeNode'
                ) {
                    let request = {
                        module: 'Assessee-Distinct',
                        action: FilterMode.toLowerCase().includes('unallocate') ? 'Unallocate' : 'Allocate',
                        actionFilter: 'Assignment',
                        requestObject: {
                            assesseeId: selectedAssociateInfo?.assesseeId,
                            associateId:
                                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                                    .associateTagPrimary,
                            [FilterMode.toLowerCase().includes('unallocate')
                                ? 'assesseeDistinctUnallocate'
                                : 'assesseeDistinctAllocate']: {
                                [distinctAllocateStr]: allocatedTagsArray
                            },
                            [FilterMode.toLowerCase().includes('unallocate')
                                ? 'assesseeDistinctUnallocateInformation'
                                : 'assesseeDistinctAllocateInformation']: {
                                assignment: selectedTagsArray
                            }
                        }
                    };
                    dispatch({ type: LOADER_START });
                    dispatch({
                        type: ASSESSEE_ALLOCATE_ASSIGNMENT_SAGA,
                        payload: { request: request }
                    });
                }
                if (
                    distinctAllocateStr === 'assessmentDistinct' ||
                    distinctAllocateStr === 'assessmentGroup' ||
                    distinctAllocateStr === 'assessmentType' ||
                    distinctAllocateStr === 'assessmentNode'
                ) {
                    let request = {
                        module: 'Assessment-Distinct',
                        action: FilterMode.toLowerCase().includes('unallocate') ? 'Unallocate' : 'Allocate',
                        actionFilter: 'Assessment-In-Assignment',
                        requestObject: {
                            assesseeId: selectedAssociateInfo?.assesseeId,
                            associateId:
                                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                                    .associateTagPrimary,
                            [FilterMode.toLowerCase().includes('unallocate')
                                ? 'assessmentDistinctUnallocate'
                                : 'assessmentDistinctAllocate']: {
                                [distinctAllocateStr]: allocatedTagsArray
                            },
                            // assessmentDistinctAllocate: {
                            //   [distinctAllocateStr]: allocatedTagsArray
                            // },
                            [FilterMode.toLowerCase().includes('unallocate')
                                ? 'assessmentDistinctUnallocateInformation'
                                : 'assessmentDistinctAllocateInformation']: {
                                assignment: selectedTagsArray
                            }
                        }
                    };
                    dispatch({ type: LOADER_START });
                    dispatch({
                        type: ASSESSMENT_ALLOCATE_ASSIGNMENT_SAGA,
                        payload: { request: request }
                    });
                }
                if (
                    distinctAllocateStr === 'assignmentDistinct' ||
                    distinctAllocateStr === 'assignmentGroup' ||
                    distinctAllocateStr === 'assignmentType' ||
                    distinctAllocateStr === 'assignmentNode'
                ) {
                    let request = {
                        module: 'Assignment-Distinct',
                        action: FilterMode.toLowerCase().includes('unallocate') ? 'Unallocate' : 'Allocate',
                        requestObject: {
                            assesseeId: selectedAssociateInfo?.assesseeId,
                            associateId:
                                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                                    .associateTagPrimary,
                            [FilterMode?.toLowerCase().includes('unallocate')
                                ? 'assignmentDistinctUnallocate'
                                : 'assignmentDistinctAllocate']: {
                                [distinctAllocateStr]: allocatedTagsArray
                            },
                            [FilterMode?.toLowerCase().includes('unallocate')
                                ? 'assignmentDistinctUnallocateInformation'
                                : 'assignmentDistinctAllocateInformation']: {
                                assignmentDistinct: selectedTagsArray
                            }
                        }
                    };
                    dispatch({ type: LOADER_START });
                    dispatch({
                        type: FILTERMODE,
                        payload: { FilterMode: '' }
                    });
                    dispatch({
                        type: ASSIGNMENT_ALLOCATE_SAGA,
                        payload: { request: request }
                    });
                }
                if (
                    distinctAllocateStr === 'cultureProfileDistinct' ||
                    distinctAllocateStr === 'cultureProfileGroup' ||
                    distinctAllocateStr === 'cultureProfileNode' ||
                    distinctAllocateStr === 'cultureProfileType'
                ) {
                    let request = {
                        module: 'Culture-Profile-Distinct',
                        action: FilterMode.toLowerCase().includes('unallocate') ? 'Unallocate' : 'Allocate',
                        actionFilter: 'Culture-Profile-Assignment',
                        requestObject: {
                            assesseeId: selectedAssociateInfo?.assesseeId,
                            associateId:
                                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                                    .associateTagPrimary,
                            cultureProfileDistinctAllocate: {
                                [distinctAllocateStr]: allocatedTagsArray
                            },
                            cultureProfileDistinctAllocateInformation: {
                                assignment: selectedTagsArray
                            }
                        }
                    };
                    dispatch({ type: LOADER_START });
                    dispatch({
                        type: CULTUREPROFILE_ALLOCATE_ASSIGNMENT_SAGA,
                        payload: { request: request }
                    });
                }
                if (
                    distinctAllocateStr === 'jobProfileDistinct' ||
                    distinctAllocateStr === 'jobProfileGroup' ||
                    distinctAllocateStr === 'jobProfileNode' ||
                    distinctAllocateStr === 'jobProfileType'
                ) {
                    let request = {
                        module: 'Job-Profile-Distinct',
                        action: FilterMode.toLowerCase().includes('unallocate') ? 'Unallocate' : 'Allocate',
                        actionFilter: 'Job-Profile-Assignment',
                        requestObject: {
                            assesseeId: selectedAssociateInfo?.assesseeId,
                            associateId:
                                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                                    .associateTagPrimary,
                            jobProfileDistinctAllocate: {
                                [distinctAllocateStr]: allocatedTagsArray
                            },
                            jobProfileDistinctAllocateInformation: {
                                assignment: selectedTagsArray
                            }
                        }
                    };
                    dispatch({ type: LOADER_START });
                    dispatch({
                        type: JOBPROFILE_ALLOCATE_ASSIGNMENT_SAGA,
                        payload: { request: request }
                    });
                }
            }
        }
        if (siftValue === 'cancel') {
            dispatch({
                type: SET_DISPLAY_TWO_SINGLE_STATE,
                payload: { stateName: 'isSelectActive', value: '' }
            });
            dispatch({
                type: FILTERMODE,
                payload: { FilterMode: '' }
            });
        }
    };
    /* for middle pane */
    const primaryIcon = [{ label: 'sift', onClick: onClickFooter, Icon: FilterList }];
    const secondaryIcon = [
        { label: 'suspended', onClick: onClickFooter, Icon: FilterList },
        { label: 'terminated', onClick: onClickFooter, Icon: FilterList },
        { label: 'unpublished', onClick: onClickFooter, Icon: FilterList }
    ];
    const activesecondaryIcon = [
        { label: 'administered', onClick: onClickFooter, Icon: FilterList },
        { label: 'published', onClick: onClickFooter, Icon: FilterList }
    ];
    let signedAssociateId =
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary;
    const openListPopup = (e) => {
        console.log(e.currentTarget.getAttribute('tag'));
        let tempArr = [];
        let stats = e.currentTarget.getAttribute('status');
        let isShared = e.currentTarget.getAttribute('data-shared');
        let associateId = e.currentTarget.getAttribute('associateid');
        let assigmentDistictPermission =
            signedAssesseePermission?.assigmentDistictPermission?.assesseeAssignmentDistinctPermission;
        let popupContentArrValue = getPopUpOptionList(
            ASSIGNMENT_REVIEW_LIST_POPUP_OPTION,
            assigmentDistictPermission
        );
        popupContentArrValue.forEach((element) => {
            if (stats === 'ADMINISTERED' && element.data === 'publish')
                tempArr.push({ ...element, disabled: true });
            else if (
                stats === 'UNPUBLISHED' &&
                (element.data === 'administer' || element.data === 'reset' || element.data === 'share')
            ) {
                tempArr.push({ ...element, disabled: true });
            }
            else if (
                isShared === 'true' &&
                associateId !== signedAssociateId &&
                (element.data === 'archive' ||
                    element.data === 'flag' ||
                    element.data === 'delete' ||
                    element.data === 'suspend' ||
                    element.data === 'select' ||
                    element.data === 'publish' ||
                    element.data === 'scores' ||
                    element.data === 'move' ||
                    element.data === 'reset' ||
                    element.data === 'analytics' ||
                    element.data === 'terminate')
            )
                tempArr.push({ ...element, disabled: true });
            else if (stats === 'PUBLISHED' && element.data === 'reset') {
                tempArr.push({ ...element, disabled: true });
            } else if (
                (stats === 'ARCHIVED' || stats === 'TERMINATED' || stats === 'SUSPENDED') &&
                (element.data === 'publish' ||
                    element.data === 'administer' ||
                    element.data === 'share' ||
                    element.data === 'revise' ||
                    element.data === 'reset' ||
                    element.data === 'select' ||
                    element.data === 'allocate')
            ) {
                tempArr.push({ ...element, disabled: true });
            } else tempArr.push(element);
        });
        if (assignmentCreateType !== 'iGuru Analytics_Occupation') {
            // delete tempArr[16];
            // delete tempArr[17];//
            // delete tempArr[18];
            tempArr.splice(16, 2);
        }
        dispatch({
            type: SET_POPUP_STATE,
            payload: {
                popupHeaderOne: 'assignment',
                popupHeaderOneBadgeOne: middlePaneHeaderBadgeOne,
                popupHeaderOneBadgeTwo: '',
                duplicateHeaderOneBadgeTwo: middlePaneHeaderBadgeTwo,
                isPopUpValue: '',
                popupOpenType: 'primary',
                popupContentArrValue: tempArr,
                selectedTagValue: e.currentTarget.getAttribute('tag'),
                cachedPreviousSelectedTagValue: e.currentTarget.getAttribute('tag'),
                selectedTagStatus: stats,
                selectedTagShared: e.currentTarget.getAttribute('data-shared'),
                isFlaged: e.currentTarget.getAttribute('data-flag') === 'true' ? true : false
            }
        });
        dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: {
                stateName: 'middlePaneListPopupOptions',
                value: tempArr
            }
        });
        dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
    };

    // infinite scroll
    const [hasMore, setHasMore] = useState(true);
    let elementRef = useRef(null);

    const onIntersection = (entries) => {
        const firstEntry = entries[0];
        reviewListDistinctData.length >= scanCount ? setHasMore(false) : setHasMore(true);
        if (firstEntry.isIntersecting && hasMore && !isListLoading) {
            fetchData();
        }
    };

    useEffect(() => {
        // console.log('####MYREF######', elementRef)
        const observer = new IntersectionObserver(onIntersection, { threshold: 0.90 });

        if (observer && elementRef.current) {
            // console.log('here at observer...', elementRef.current)
            observer.observe(elementRef.current);
        }

        return () => {
            if (observer) observer.disconnect();
        };
    }, [
        isListLoading,
        hasMore,
        reviewListDistinctData,
        selectedTagsArray,
        unselectedTagsArray,
        isSelectActive
    ]);
    // console.log('signedAssociateId', signedAssociateId);
    // console.log('id', reviewListDistinctData[0]);
    return (
        <div>
            {reviewListDistinctData &&
                reviewListDistinctData.map((item, index) => {
                    return (
                        <div className="containerPadding" key={index} ref={elementRef}>
                            <ReviewList
                                className=""
                                id={index}
                                tag={item.id}
                                associateId={item?.associateId}
                                isSelectedReviewList={middlePaneSelectedValue === item.id}
                                status={
                                    item.informationEngagement.assignmentStatus === 'UNFINISHED'
                                        ? 'TIMEOUT'
                                        : item.informationEngagement.assignmentStatus
                                }
                                actualStatus={item.informationEngagement.assignmentStatus}
                                textOne={item.informationBasic.assignmentName}
                                textTwo={item.informationBasic.assignmentDescription}
                                isTooltipActive={false}
                                // onClickEvent={openListPopup}
                                isSelectActive={isSelectActive}
                                isSelected={selectedTagsArray.includes(item.id)}
                                isShared={item?.assignmentShared}
                                onClickCheckBox={(event) => {
                                    onClickCheckBoxSelection(selectedTagsArray, unselectedTagsArray, event, dispatch);
                                }}
                                // disableonClickCheckBox={item.id !== signedAssociateId ? true : false}
                                isFlagActive={selectedFlagedArray.includes(item.id)}
                                flagedValue={flagedValue}
                                onClickAddFladed={(event) => {
                                    !isListLoading &&
                                        onClickFlagSelection(
                                            selectedFlagedArray,
                                            unselectedFlagedArray,
                                            event,
                                            dispatch
                                        );
                                }}
                            />
                        </div>
                    );
                })}
            {isListLoading ? (
                <div>
                    {' '}
                    <ListLoader />{' '}
                </div>
            ) : <div style={{ height: "60px", width: "100%" }}></div>}
            {(FilterMode === 'assignmentsDistinctinactive' ||
                FilterMode === 'assignmentsDistinctactive' ||
                FilterMode === 'assignmentsDistinctadministered' ||
                FilterMode === 'assignmentsDistinctsuspended' ||
                FilterMode === 'assignmentsDistinctunpublished' ||
                FilterMode === 'assignmentsDistinctpublished' ||
                FilterMode === 'assignmentsDistinctterminated') && (
                    <FooterIconTwo
                        FilterModeEnable={FilterModeEnable}
                        FilterMode={FilterMode}
                        onClick={onClickFooter}
                        primaryIcon={primaryIcon}
                        secondaryIcon={
                            ((FilterMode === 'assignmentsDistinctactive' ||
                                FilterMode === 'assignmentsDistinctpublished' ||
                                FilterMode === 'assignmentsDistinctadministered') &&
                                activesecondaryIcon) ||
                            secondaryIcon
                        }
                    />
                )}

            {(FilterMode === 'allocateToAssignment' || FilterMode === 'unAllocateToAssignment') && (
                <FooterIconTwo
                    FilterModeEnable={FilterModeEnable}
                    FilterMode={FilterMode}
                    onClick={onClickFooter}
                    primaryIcon={[
                        {
                            label: `${FilterMode === 'allocateToAssignment' ? 'allocate' : 'unallocate'}`,
                            onClick: onClickFooter,
                            Icon: ReviseIcon
                        }
                    ]}
                    secondaryIcon={[
                        { label: 'cancel', onClick: onClickFooter, Icon: ClearIcon },
                        { label: 'finish', onClick: onClickFooter, Icon: Check }
                    ]}
                />
            )}
        </div>
    );
};
export default AssignmentDistinctReviewList;
