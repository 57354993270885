import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ASSIGNMENT_ALLOCATE_SAGA,
  ASSOCIATE_POPUP_CLOSE,
  FILTERMODE,
  FILTERMODE_ENABLE,
  GET_ASSIGNMENT_TYPE_REVIEW_LIST_SAGA,
  LOADER_START,
  POPUP_OPEN,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_PAGE_COUNT,
  SET_POPUP_STATE,
  SET_REQUEST_OBJECT
} from '../actionType';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import { FilterList } from '@material-ui/icons';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import { getPopUpOptionList, makeAssignmentTypeObj } from '../Actions/GenericActions';
import {
  ASSIGNMENT_NODE_TYPE_REVIEW_LIST_POPUP_OPTION,
  NODE_ROLE_TYPE_REVIEW_LIST_POPUP_OPTION
} from '../PopUpConfig';
import {
  assesseeRole,
  onClickCheckBoxSelection,
  onClickFlagSelection,
  setFlagedArray
} from '../Actions/AssesseeModuleAction';
import ReviseIcon from '@material-ui/icons/RadioButtonChecked';
import Check from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import ListLoader from '../Atoms/ListLoader/ListLoader';
const AssignmentTypeReviewList = (props) => {
  const dispatch = useDispatch();
  // const { secondaryOptionCheckValue,  } = useSelector(
  //   (state) => state.AssesseeCreateReducer
  // );
  const {
    numberPage,
    scanCount,
    countPage,
    reviewListDistinctData,
    reviewListReqObj,
    middlePaneSelectedValue,
    selectedAssociateInfo,
    middlePaneHeaderBadgeOne,
    middlePaneHeaderBadgeTwo,
    middlePaneHeaderBadgeThree,
    middlePaneHeaderBadgeFour,
    isSelectActive,
    selectedTagsArray,
    unselectedTagsArray,
    allocateStr,
    allocatedTagsArray,
    selectedFlagedArray,
    unselectedFlagedArray,
    flagedValue,
    signedAssesseePermission,
    isListLoading
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { FilterModeEnable, FilterMode } = useSelector((state) => state.FilterReducer);
  const { cardValue } = useSelector((state) => state.PopUpReducer);
  const [isFetching, setIsFetching] = useState(false);
  // useEffect(() => {
  //   document.getElementById('middleComponentId').addEventListener('scroll', handleScroll);
  // }, []);
  // const handleScroll = (event) => {
  //   var targetPt = event.target;
  //   if (
  //     Math.ceil(targetPt.scrollHeight - targetPt.scrollTop) !== targetPt.clientHeight ||
  //     isFetching
  //   )
  //     return;
  //   setIsFetching(true);
  //   console.log(isFetching);
  // };
  const fetchData = async () => {
    if (reviewListDistinctData.length < scanCount) {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isListLoading', value: true }
      });
      let obj = {
        ...reviewListReqObj.requestObject,
        numberPage: numberPage + 1
      };
      dispatch({
        type: GET_ASSIGNMENT_TYPE_REVIEW_LIST_SAGA,
        payload: {
          request: {
            module: 'Assignment-Type',
            action: 'Review-List',
            requestObject: obj
          },
          BadgeOne: middlePaneHeaderBadgeOne,
          BadgeTwo: middlePaneHeaderBadgeTwo,
          BadgeThree: middlePaneHeaderBadgeThree,
          isMiddlePaneList: true,
          isSelectActive,
          selectedTagsArray
        }
      });
      dispatch({ type: SET_PAGE_COUNT, payload: numberPage + 1 });
    }
  };
  // useEffect(() => {
  //   console.log(reviewListDistinctData);
  //   if (!isFetching) return;
  //   fetchMoreListItems();
  // }, [isFetching]);

  // const fetchMoreListItems = () => {
  //   fetchData();
  //   setIsFetching(false);
  // };
  useEffect(() => {
    setFlagedArray(reviewListDistinctData, 'assignmentTypeFlag', dispatch);
  }, [reviewListDistinctData]);

  const siftApiCall = (siftKey) => {
    let requestObect = {
      module: 'Assignment-Type',
      action: 'Review-List',
      requestObject: makeAssignmentTypeObj(selectedAssociateInfo, siftKey, 0, countPage)
    };
    dispatch({ type: LOADER_START });
    dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
    dispatch({
      type: FILTERMODE,
      payload: { FilterMode: 'assignmentsTypeDistinctactive' + siftKey }
    });
    dispatch({
      type: GET_ASSIGNMENT_TYPE_REVIEW_LIST_SAGA,
      payload: {
        request: requestObect,
        BadgeOne: middlePaneHeaderBadgeOne,
        BadgeTwo: middlePaneHeaderBadgeTwo === 'distinct' ? middlePaneHeaderBadgeTwo : siftKey,
        BadgeThree: middlePaneHeaderBadgeTwo === 'distinct' ? siftKey : middlePaneHeaderBadgeThree,
        isMiddlePaneList: true,
        isSelectActive: isSelectActive,
        selectedTagsArray: selectedTagsArray
      }
    });
    dispatch({ type: SET_PAGE_COUNT, payload: 0 });
    dispatch({ type: ASSOCIATE_POPUP_CLOSE });
    document.getElementById('middleComponentId').scrollTop = '0px';
  };
  const onClickFooter = (e) => {
    let siftValue = e.currentTarget.getAttribute('data-value');
    dispatch({ type: FILTERMODE_ENABLE });
    if (siftValue === 'suspended' || siftValue === 'terminated') siftApiCall(siftValue);
    if (siftValue === 'bespoke' || siftValue === 'generic') siftApiCall(siftValue);

    if (siftValue === 'finish') {
      console.log('allocateStr', allocateStr);
      let distinctAllocateStr =
        allocateStr === 'assignmentsdistinct'
          ? 'assignmentDistinct'
          : allocateStr === 'assignmentsgroups'
          ? 'assignmentGroup'
          : allocateStr === 'assignmentstypes'
          ? 'assignmentType'
          : allocateStr === 'assignmentsnodes'
          ? 'assignmentNode'
          : '';
      if (distinctAllocateStr !== '' && selectedTagsArray.length !== 0) {
        let request = {};
        if (
          distinctAllocateStr === 'assignmentDistinct' ||
          distinctAllocateStr === 'assignmentGroup' ||
          distinctAllocateStr === 'assignmentType' ||
          distinctAllocateStr === 'assignmentNode'
        ) {
          request = {
            module: 'Assignment-Distinct',
            action: FilterMode.toLowerCase().includes('unallocate') ? 'Unallocate' : 'Allocate',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              [FilterMode?.toLowerCase().includes('unallocate')
                ? 'assignmentDistinctUnallocate'
                : 'assignmentDistinctAllocate']: {
                [distinctAllocateStr]: allocatedTagsArray
              },
              [FilterMode?.toLowerCase().includes('unallocate')
                ? 'assignmentDistinctUnallocateInformation'
                : 'assignmentDistinctAllocateInformation']: {
                assignmentType: selectedTagsArray
              }
            }
          };
          dispatch({ type: LOADER_START });
          dispatch({
            type: FILTERMODE,
            payload: { FilterMode: '' }
          });
          dispatch({
            type: ASSIGNMENT_ALLOCATE_SAGA,
            payload: { request: request }
          });
        }
      }
    }
    if (siftValue === 'cancel') {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isSelectActive', value: '' }
      });
      dispatch({
        type: FILTERMODE,
        payload: { FilterMode: '' }
      });
    }
  };
  /* for middle pane */
  const primaryIcon = [{ label: 'sift', onClick: onClickFooter, Icon: FilterList }];
  const secondaryIcon = [
    { label: 'suspended', onClick: onClickFooter, Icon: FilterList },
    { label: 'terminated', onClick: onClickFooter, Icon: FilterList }
  ];
  const secondaryOneIcon = [
    { label: 'bespoke', onClick: onClickFooter, Icon: FilterList },
    { label: 'generic', onClick: onClickFooter, Icon: FilterList }
    // { label: 'shared', onClick: onClickFooter, Icon: FilterList },
    // { label: 'unshared', onClick: onClickFooter, Icon: FilterList }
  ];

  let signedAssociateId =
    selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary;

  const openListPopup = (e) => {
    console.log(e.currentTarget.getAttribute('tag'));
    let tempArr = [];
    let isShared = e.currentTarget.getAttribute('data-shared');
    let classification = e.currentTarget.getAttribute('data-classification');
    let associateId = e.currentTarget.getAttribute('associateid');
    let status = e.currentTarget.getAttribute('status');
    let assigmentDistictPermission =
      signedAssesseePermission?.assigmentDistictPermission?.assesseeAssignmentTypePermission;
    let popupArr =
      cardValue === 'Card'
        ? NODE_ROLE_TYPE_REVIEW_LIST_POPUP_OPTION
        : ASSIGNMENT_NODE_TYPE_REVIEW_LIST_POPUP_OPTION;
    let popupContentArrValue = getPopUpOptionList(popupArr, assigmentDistictPermission);
    popupContentArrValue.map((element) => {
      //ASSIGNMENT_NODE_TYPE_REVIEW_LIST_POPUP_OPTION.map((element) => {
      if (classification === 'Bespoke' && element.data === 'share' && status === 'ACTIVE')
        tempArr.push({ ...element, disabled: true });
      else if (
        isShared === 'true' &&
        associateId != signedAssociateId &&
        (element.data === 'archive' ||
          element.data === 'flag' ||
          element.data === 'delete' ||
          element.data === 'suspend' ||
          element.data === 'move' ||
          element.data === 'terminate')
      ) {
        tempArr.push({ ...element, disabled: true });
      } else if (
        (status === 'TERMINATED' || status === 'SUSPENDED' || status === 'ARCHIVED') &&
        (element.data === 'allocate' ||
          element.data === 'revise' ||
          element.data === 'select' ||
          element.data === 'share')
      ) {
        tempArr.push({ ...element, disabled: true });
      } else tempArr.push(element);
    });
    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: 'assignments',
        popupHeaderOneBadgeOne: 'type',
        popupHeaderOneBadgeTwo: '',
        duplicateHeaderOneBadgeTwo: middlePaneHeaderBadgeTwo,
        isPopUpValue: '',
        popupOpenType: 'primary',
        popupContentArrValue: tempArr,
        originPrimaryArrOprion: tempArr,
        selectedTagValue: e.currentTarget.getAttribute('tag'),
        selectedTagStatus: e.currentTarget.getAttribute('status'),
        selectedTagGroupId: e.currentTarget.getAttribute('data-value'),
        selectedTagShared: e.currentTarget.getAttribute('data-shared'),
        isFlaged: e.currentTarget.getAttribute('data-flag') === 'true' ? true : false
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: tempArr
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
  };

  // infinite scroll
  const [hasMore, setHasMore] = useState(true);
  let elementRef = useRef(null);

  const onIntersection = (entries) => {
    const firstEntry = entries[0];
    reviewListDistinctData.length >= scanCount ? setHasMore(false) : setHasMore(true);
    if (firstEntry.isIntersecting && hasMore && !isListLoading) {
      fetchData();
    }
  };

  useEffect(() => {
    // console.log('####MYREF######', elementRef)
    const observer = new IntersectionObserver(onIntersection, { threshold: 0.90 });

    if (observer && elementRef.current) {
      // console.log('here at observer...', elementRef.current)
      observer.observe(elementRef.current);
    }

    return () => {
      if (observer) observer.disconnect();
    };
  }, [
    isListLoading,
    hasMore,
    reviewListDistinctData,
    selectedTagsArray,
    unselectedTagsArray,
    isSelectActive
  ]);

  // infinite scroll end

  return (
    <div>
      {reviewListDistinctData &&
        reviewListDistinctData.map((item, index) => {
          return (
            <div className="containerPadding" key={index} ref={elementRef}>
              <ReviewList
                className=""
                id={index}
                tag={item.id}
                associateId={item?.associateId}
                isSelectedReviewList={middlePaneSelectedValue === item.id}
                // textOne={item.informationBasic.assignmentTypeName}
                textOne={assesseeRole(item.informationBasic.assignmentTypeName)}
                textTwo={item.informationBasic.assignmentTypeDescription}
                //status={associateSeftId === item.associateId ? 'bespoke' : 'generic'}
                status={
                  FilterMode === 'assignmentsTypeDistinctactive' ||
                  FilterMode === 'assignmentsTypeDistinctactivebespoke' ||
                  FilterMode === 'assignmentsTypeDistinctbespoke' ||
                  FilterMode === 'assignmentsTypeDistinctgeneric' ||
                  FilterMode === 'assignmentsTypeDistinctactivegeneric'
                    ? item.informationSetup?.assignmentTypeClassification
                        ?.assignmentTypeClassificationPrimary
                    : item.informationEngagement.assignmentTypeStatus
                }
                actualStatus={item.informationEngagement.assignmentTypeStatus}
                //shared={item.assignmentTypeShared ? 'SHARED' : 'UNSHARED'}
                // dataValue={item.informationAllocation?.assignmentTypeGroup}
                isTooltipActive={false}
                onClickEvent={openListPopup}
                isSelectActive={isSelectActive}
                isSelected={selectedTagsArray.includes(item.id)}
                onClickCheckBox={(event) => {
                  !isListLoading &&
                    onClickCheckBoxSelection(
                      selectedTagsArray,
                      unselectedTagsArray,
                      event,
                      dispatch
                    );
                }}
                // disableonClickCheckBox={item.associateId !== signedAssociateId ? true : false}
                isShared={item?.assignmentTypeShared}
                shared={
                  item.informationSetup?.assignmentTypeClassification
                    ?.assignmentTypeClassificationPrimary
                }
                isFlagActive={selectedFlagedArray.includes(item.id)}
                flagedValue={flagedValue}
                onClickAddFladed={(event) => {
                  onClickFlagSelection(selectedFlagedArray, unselectedFlagedArray, event, dispatch);
                }}
              />
            </div>
          );
        })}
      {isListLoading ? (
        <div>
          {' '}
          <ListLoader />{' '}
        </div>
      ) : <div style={{ height: "60px", width: "100%" }}></div>}
      {(FilterMode === 'assignmentsTypeDistinctinactive' ||
        FilterMode === 'assignmentsTypeDistinctactiveterminated' ||
        FilterMode === 'assignmentsTypeDistinctactivesuspended') && (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={primaryIcon}
          secondaryIcon={secondaryIcon}
        />
      )}
      {(FilterMode === 'assignmentsTypeDistinctactive' ||
        FilterMode === 'assignmentsTypeDistinctactivebespoke' ||
        FilterMode === 'assignmentsTypeDistinctbespoke' ||
        FilterMode === 'assignmentsTypeDistinctgeneric' ||
        FilterMode === 'assignmentsTypeDistinctactivegeneric') && (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={primaryIcon}
          secondaryIcon={secondaryOneIcon}
        />
      )}
      {(FilterMode === 'assignmentAllocateToType' ||
        FilterMode === 'assignmentUnAllocateToType') && (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={[
            {
              label: `${FilterMode === 'assignmentAllocateToType' ? 'allocate' : 'unallocate'}`,
              onClick: onClickFooter,
              Icon: ReviseIcon
            }
          ]}
          secondaryIcon={[
            { label: 'cancel', onClick: onClickFooter, Icon: ClearIcon },
            { label: 'finish', onClick: onClickFooter, Icon: Check }
          ]}
        />
      )}
    </div>
  );
};
export default AssignmentTypeReviewList;
