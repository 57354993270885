import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './InputField.css';
import {
  Input,
  InputLabel,
  FormControl,
  FormHelperText,
  IconButton,
  ClickAwayListener
} from '@material-ui/core';

import InputAdornment from '@material-ui/core/InputAdornment';
import { Visibility, VisibilityOff } from '@material-ui/icons';
export const InputFieldPassword = (props) => {
  const [eyeButton, setEyeButton] = React.useState(false);
  const [values, setValues] = React.useState({
    showPassword: false
  });
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const {
    id,
    label,
    type = 'text',
    errorMsg = '',
    onClick,
    value,
    labelBadgeOne,
    isRequired = true,
    autoFocus = false,
    classNames = '',
    isErrorMsg = true
  } = props;

  const handleClickAway = () => {
    setEyeButton(false);
    // console.log('handleClickAway eyeButton', eyeButton);
  };

  useEffect(() => {
    setEyeButton(true);
    // console.log('value is changed eyeButton visibile', eyeButton);
  }, [value]);
  return (
    <div className="popup-form-box">
      <FormControl style={{ width: '100%' }}>
        <InputLabel
          htmlFor={id}
          style={{ fontSize: '1.6rem' }}
          {...(type === 'datetime-local' && { shrink: true })}
        >
          {label} &nbsp;
          {labelBadgeOne ? <span className={'labelheaderBadge1'}>{labelBadgeOne}</span> : null}
        </InputLabel>

        <ClickAwayListener
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
          onClickAway={handleClickAway}
        >
          <Input
            type={values.showPassword ? 'text' : 'password'}
            id={id}
            name={id}
            value={value}
            onChange={onClick}
            error={value < 0}
            autoComplete="off"
            autoFocus={autoFocus}
            className={['inputFields', classNames].join(' ')}
            endAdornment={
              <InputAdornment position="end">
                {value !== '' && (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                )}
              </InputAdornment>
            }
            //multiline={isMultiLine}
            //rows={5}
          />
        </ClickAwayListener>
      </FormControl>
      {isRequired && (
        <FormHelperText
          className={[isErrorMsg ? 'helperText' : 'helperTextDefault', 'helptextmargin'].join(' ')}
          style={{height: "auto"}}
        >
          <span>{errorMsg}</span>
        </FormHelperText>
      )}
    </div>
  );
};

InputFieldPassword.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.oneOf(['text', 'password', 'datetime-local', 'date', 'tel', 'number'])
};

export default InputFieldPassword;
