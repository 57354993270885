import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FILTERMODE,
  FILTERMODE_ENABLE,
  POPUP_OPEN,
  SET_ASSESSEE_GROUP_ASSESSEE_ID_LIST,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MIDDLEPANE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_POPUP_STATE,
  SET_UNSELECTED_ASSESSEE_GROUP_ASSESSEE_ID_LIST
} from '../actionType';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import { FilterList } from '@material-ui/icons';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import { REVIEW_LIST_POPUP_OPTION } from '../PopUpConfig';
import Card from '../Molecules/Card/Card';
import CrossIcon from '@material-ui/icons/Clear';
import { onClickCheckBoxSelection } from '../Actions/AssesseeModuleAction';
import { getCultureProfileAssessmentDistinctApiCall } from '../Actions/ActionCultureProfile';
import { closeRelatedList } from '../Actions/GenericActions';

const CultureProfileAssessmentDistinctReviewList = (props) => {
  const dispatch = useDispatch();
  const [isShowReviseIcon, setIsShowReviseIcon] = useState(true);
  const {
    middlePaneSelectedValue,
    reviewListDistinctData,
    relatedReviewListDistinctData,
    selectedTagsArray,
    isSelectActive,
    unselectedTagsArray,
    typeOfMiddlePaneList,
    selectedAssociateInfo,
    countPage,
    duplicateScanCount,
    middlePaneHeaderDuplicate,
    middlePaneHeaderBadgeOne,
    middlePaneHeaderDuplicateBadgeOne,
    middlePaneHeaderDuplicateBadgeTwo,
    middlePaneHeaderDuplicateBadgeThree,
    middlePaneHeaderDuplicateBadgeFour
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { FilterMode, FilterModeEnable } = useSelector((state) => state.FilterReducer);
  const { responseObject, createMode } = useSelector((state) => state.DisplayPaneThreeReducer);

  const onClickRevise = () => {
    console.log('ON CLICK REVISE ICON');
    setIsShowReviseIcon(false);
  };
  const onClickReviseCancel = () => {
    console.log('ON CLICK cancel ICON');
    setIsShowReviseIcon(true);
    closeRelatedList(
      dispatch,
      createMode,
      responseObject,
      middlePaneHeaderDuplicate,
      middlePaneHeaderDuplicateBadgeOne,
      middlePaneHeaderDuplicateBadgeTwo,
      middlePaneHeaderDuplicateBadgeThree,
      middlePaneHeaderDuplicateBadgeFour,
      'cultureProfilesDistinctReviewList',
      duplicateScanCount,
      'cultureProfileDistinct',
      duplicateScanCount,
      middlePaneHeaderDuplicate,
      middlePaneHeaderDuplicateBadgeOne,
      middlePaneHeaderDuplicateBadgeTwo,
      middlePaneHeaderDuplicateBadgeThree,
      middlePaneHeaderDuplicateBadgeFour,
    );
  };
  const onClickReviseFinish = () => {
    console.log('ON CLICK finish ICON', selectedTagsArray, unselectedTagsArray);
    setIsShowReviseIcon(true);
    if (typeOfMiddlePaneList !== '') {
      dispatch({
        type: SET_MIDDLEPANE_STATE,
        payload: {
          middlePaneHeader: middlePaneHeaderDuplicate,
          middlePaneHeaderBadgeOne: middlePaneHeaderDuplicateBadgeOne,
          middlePaneHeaderBadgeTwo: middlePaneHeaderDuplicateBadgeTwo,
          middlePaneHeaderBadgeThree: middlePaneHeaderDuplicateBadgeThree,
          middlePaneHeaderBadgeFour: middlePaneHeaderDuplicateBadgeFour,
          typeOfMiddlePaneList: 'cultureProfilesDistinctReviewList',
          scanCount: reviewListDistinctData.length,
          showMiddlePaneState: true
        }
      });
      dispatch({
        type: FILTERMODE,
        payload: { FilterMode: '' }
      });
    }

    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'isSelectActive', value: '' }
    });
    dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
    dispatch({ type: SET_ASSESSEE_GROUP_ASSESSEE_ID_LIST, payload: selectedTagsArray });
    dispatch({
      type: SET_UNSELECTED_ASSESSEE_GROUP_ASSESSEE_ID_LIST,
      payload: unselectedTagsArray
    });
  };
  const listDistinctData = relatedReviewListDistinctData[0];

  const siftApiCall = (siftKey) => {
    console.log('siftkey', siftKey);
    getCultureProfileAssessmentDistinctApiCall(
      selectedAssociateInfo,
      siftKey,
      countPage,
      dispatch,
      middlePaneHeaderBadgeOne,
      listDistinctData.id,
      '',
      false
    );
    document.getElementById('middleComponentId').scrollTop = '0px';
  };
  const onClickFooter = (e) => {
    let siftValue = e.currentTarget.getAttribute('data-value');
    if (siftValue === 'suspended' || siftValue === 'terminated' || siftValue === 'unpublished')
      siftApiCall(siftValue);
    dispatch({ type: FILTERMODE_ENABLE });
  };
  /* for middle pane */
  const primaryIcon = [{ label: 'sift', onClick: onClickFooter, Icon: FilterList }];
  const secondaryIcon = [
    { label: 'suspended', onClick: onClickFooter, Icon: FilterList },
    { label: 'terminated', onClick: onClickFooter, Icon: FilterList }
  ];

  const openListPopup = (e) => {
    console.log(e.currentTarget.getAttribute('tag'));
    let status = e.currentTarget.getAttribute('status');
    let tempArr = [];
    REVIEW_LIST_POPUP_OPTION.forEach((element) => {
      if (
        element.data === 'allocate' ||
        element.data === 'archive' ||
        element.data === 'delete' ||
        element.data === 'flag' ||
        element.data === 'revise' ||
        element.data === 'select' ||
        element.data === 'share' ||
        element.data === 'suspend' ||
        element.data === 'suspend' ||
        element.data === 'terminate'
      ) {
        tempArr.push({ ...element, disabled: true });
      } else {
        tempArr.push(element);
      }
    });
    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: 'culture-profile',
        popupHeaderOneBadgeOne: middlePaneHeaderBadgeOne,
        isPopUpValue: '',
        popupOpenType: 'primary',
        popupContentArrValue: tempArr,
        selectedTagValue: e.currentTarget.getAttribute('tag'),
        selectedTagStatus: status
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: tempArr
      }
    });
  };
  return (
    <div>
      {listDistinctData && (
        <Card
          textOneOne={listDistinctData.cultureProfileName}
          textTwoOne={listDistinctData.cultureProfileDescription}
          IconOne={CrossIcon}
          isIcon={true}
          //labelTwoTwo={'type'}
          labelTwoTwo={'distinct'}
          onClickIconOne={onClickReviseCancel}
          isAlliance
          relatedCardFixed={true}
          className={'iguru-iconboxSVG'}
        />
      )}
      {listDistinctData &&
        listDistinctData.assessment.map((item, index) => {
          return (
            <div className="containerPadding" key={index}>
              <ReviewList
                className=""
                id={index}
                tag={item.id}
                isSelectedReviewList={middlePaneSelectedValue === item.id}
                status={item.informationEngagement.assessmentStatus}
                actualStatus={item.informationEngagement.assessmentStatus}
                textOne={item.informationBasic.assessmentName}
                textTwo={item.informationBasic.assessmentDescription}
                isTooltipActive={false}
                onClickEvent={openListPopup}
                isSelectActive={isSelectActive}
                isSelected={selectedTagsArray.includes(item.id)}
                onClickCheckBox={(event) => {
                  onClickCheckBoxSelection(selectedTagsArray, unselectedTagsArray, event, dispatch);
                }}
              />
            </div>
          );
        })}
      {FilterMode === 'assessmentTypeCultureProfileRevise' && (
        <FooterIconTwo
          FilterModeEnable={isShowReviseIcon}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={primaryIcon}
          secondaryIcon={secondaryIcon}
        />
      )}
      {FilterMode === 'cultureProfileAssessmentDistinctinactive' && (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={primaryIcon}
          secondaryIcon={[
            { label: 'suspended', onClick: onClickFooter, Icon: FilterList },
            { label: 'terminated', onClick: onClickFooter, Icon: FilterList },
            { label: 'unpublished', onClick: onClickFooter, Icon: FilterList }
          ]}
        />
      )}
    </div>
  );
};
export default CultureProfileAssessmentDistinctReviewList;
