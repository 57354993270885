import React from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import DisplayPanelAccordianReviewListOne from '../Accordian/DisplayPanelAccordianReviewListOne';
import DisplayPanelAccordianInformation from '../Accordian/DisplayPanelAccordianInformation';
import { Paper } from '@material-ui/core';
import { makeItemObj } from '../../Actions/GenericActions';
import { getItemAllocateApi, getItemGroupItemDistinctApiCall } from '../../Actions/ItemModuleAction';
import { SET_PAGE_COUNT } from '../../actionType';

const DisplayPaneThreeSectionTwoItemGroup = () => {
  // const [listExpand, setListExpand] = useState('');
  const { reviewMode, relatedReviewListPaneThree, responseObject } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  const { selectedAssociateInfo, countPage } = useSelector((state) => state.DisplayPaneTwoReducer);
  const dispatch = useDispatch();
  // const { informationEngagement, informationSetup } = responseObject;
  // function capitalizeFirstLetter(string) {
  //   if (!string) return '';
  //   return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  // }

  let itemList = [];
  if (relatedReviewListPaneThree) {
    itemList = relatedReviewListPaneThree?.item || [];
  }
  let itemArray = [];
  console.log("relatedReviewListPaneThree-",relatedReviewListPaneThree);
  itemList.forEach((ob) => {
    const { id, informationBasic,informationEngagement, associateId } = ob;
    itemArray.push({
      id,
      textOne: informationBasic?.itemName || '',
      textTwo: informationBasic?.itemDescription || '',
      status: informationEngagement?.itemStatus ||'',
      isShared: ob?.itemShared,
      associateId
    });
  });
  const clusterList = [
    {
      id: 'a1',
      labelTextOneOne: 'items',
      labelTextOneOneBadgeOne: '',
      labelTextOneOneBadgeTwo: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'distinct',
          innerList: itemArray
        }
      ],
      innerInfo: 'No Information',
      isListCard: true,
      isReviewLink: true
    }
  ];

  const onclickReview = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    if (labelName === 'items' && selectedBadgeName === 'distinct') {
      getItemGroupItemDistinctApiCall(
        selectedAssociateInfo,
        '',
        //'all',
        -1,
        dispatch,
        'distinct',
        responseObject.id, //group id
        '',
        false,
        true
      );
    }
  };

  const onClickRevise = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    if (labelName === 'items' && selectedBadgeName === 'distinct') {
      console.log('item CLICK :::::::>>>>>>>', relatedReviewListPaneThree);
      let requestObect = makeItemObj(selectedAssociateInfo, '', countPage, 0);
      let revisedGroupObject = {
        id: responseObject.id,
        itemGroupName: responseObject.informationBasic.itemGroupName,
        itemGroupDescription: responseObject.informationBasic.itemGroupDescription,
        itemGroupStatus: responseObject.informationEngagement.itemGroupStatus
      };
      getItemAllocateApi(
        dispatch,
        requestObect,
        revisedGroupObject,
        relatedReviewListPaneThree,
        'itemGroupItemeRevise',
        'itemGroupItemReviewList'
      );
      dispatch({ type: SET_PAGE_COUNT, payload: 0 });
    }
  };

  return (
    <div>
      <>
        <div className={'containerPadding'}>
          <Paper className={'dossierContainerTop'}>
            {clusterList.map((ob) => {
              return (
                <div key={ob.id}>
                  {ob.isListCard ? (
                    <DisplayPanelAccordianReviewListOne
                      onClickReview={onclickReview}
                      onClickRevise={onClickRevise}
                      className=""
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  ) : (
                    <DisplayPanelAccordianInformation
                      onClickRevise={onClickRevise}
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  )}
                </div>
              );
            })}
          </Paper>
        </div>
      </>
      {isMobile && (
        <div className={'containerPadding'} style={{ height: '55px' }}>
          {' '}
        </div>
      )}
    </div>
  );
};

export default DisplayPaneThreeSectionTwoItemGroup;
