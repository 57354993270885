import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PopUpPicture from '../../PopUpInformation/PopUpPicture';
import PopUpTextField from '../../PopUpInformation/PopUpTextField';
import PopUpConfirm from '../../PopUpGeneric/PopUpConfirm';
import {
  CLEAR_ROLE_REDUCER_STATE,
  POPUP_CLOSE,
  CREATE_ASSOCIATE_ROLE_SAGA,
  SET_ASSOCIATE_ROLE_REDUCER_STATE,
  LOADER_START,
  SET_ROLE_DYNAMIC_STATE,
  SET_DISPLAY_THREE_SINGLE_STATE,
  SET_SETUP_PERMISSION_NEW,
  SET_ASSOCIATE_ROLE_CLASSIFICAION_STATE,
  SET_ROLE_REDUCER_STATE,
  UPDATE_ASSESSEE_ENGAGEMENT_INFO,
  SET_POPUP_SINGLE_STATE,
  SET_DISPLAY_TWO_SINGLE_STATE
} from '../../actionType';
import PopUpReviewList from '../../PopUpInformation/PopUpReviewList';
import PopUpDropList from '../../PopUpInformation/PopUpDropList';
import PopUpTagSecondary from '../../PopUpInformation/PopUpTagSecondary';
import { NODE_ROLE_TYPE_REVIEW_LIST_POPUP_OPTION } from '../../PopUpConfig';
import { getPopUpOptionList } from '../../Actions/GenericActions';
import PopUpCheckboxRolePermission from '../../PopUpInformation/PopUpCheckboxRolePermission';

const PopUpAssociateRoleCreate = () => {
  const { isPopUpValue, headerOne, reducerObeject, objectName, popupErrorMsg } = useSelector(
    (state) => state.PopUpReducer
  );
  const { associateRole } = useSelector((state) => state.RoleCreateReducer);
  const {
    selectedAssociateInfo,
    coreRoleReviewListData,
    permissionStateOne,
    permissionStateTwo,
    permissionStateThree,
    signedAssesseePermission
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { reviewMode } = useSelector((state) => state.DisplayPaneThreeReducer);
  const { responseObject, statusPopUpValue } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  const [roleSelectedError, setRoleSelectedError] = useState('');

  const dispatch = useDispatch();
  const onClickCancelYes = () => {
    dispatch({
      type: SET_DISPLAY_THREE_SINGLE_STATE,
      payload: { stateName: 'createMode', value: '' }
    });
    dispatch({ type: CLEAR_ROLE_REDUCER_STATE });
    dispatch({ type: POPUP_CLOSE });
  };
  const onClickYes = () => {
    var requestObj = {
      module: 'Associate-Role',
      action: 'Create',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        associateRole: {
          informationBasic: associateRole.informationBasic,
          informationSetup:{
            associateRoleClassification: associateRole.informationSetup.associateRoleClassification
          }
          // informationSetup: associateRole.informationSetup
        }
      }
    };

    let assesseeDistinctPermission =
      signedAssesseePermission?.associateDistinctPermission?.assesseeAssociateRolePermission;
    let popupContentArr = getPopUpOptionList(
      NODE_ROLE_TYPE_REVIEW_LIST_POPUP_OPTION,
      assesseeDistinctPermission
    );
    let finalPopupOptionArray = []; //chagnes
    let classification =
      associateRole?.informationSetup?.associateRoleClassification
        ?.associateRoleClassificationPrimary;
    console.log('classification>>', classification);
    popupContentArr.forEach((element) => {
      if (classification === 'Bespoke' && element.data === 'share') {
        finalPopupOptionArray.push({ ...element, disabled: true });
      } else {
        finalPopupOptionArray.push(element);
      }
    });
    console.log('finalPopupOptionArray>>', finalPopupOptionArray);
    dispatch({ type: LOADER_START });
    dispatch({
      type: CREATE_ASSOCIATE_ROLE_SAGA,
      payload: { request: requestObj, popupContentArr: finalPopupOptionArray }
    });
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: {
        stateName: 'popupOptionArr',
        value: finalPopupOptionArray
      }
    });
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: {
        stateName: 'originPrimaryArrOprion',
        value: finalPopupOptionArray
      }
    });
  };
  const updateRoleGroup = (e) => {
    console.log(e.currentTarget.getAttribute('tag'));
    let tagId = e.currentTarget.getAttribute('tag');
    setRoleSelectedError('');
    let tagIdArr = associateRole.informationAllocation.associateRoleGroup;
    if (tagIdArr.includes(tagId)) {
      document.getElementById(tagId).style.backgroundColor = 'white';
      tagIdArr = tagIdArr.filter(function (number) {
        return number !== tagId;
      });
    } else {
      var arr = [];
      tagIdArr = [...arr];
      tagIdArr.push(tagId);
      document.getElementById(tagId).style.backgroundColor = '#F0F0F0';
    }
    dispatch({
      type: SET_ROLE_DYNAMIC_STATE,
      payload: {
        objectName: 'associateRole',
        stateName: 'informationAllocation',
        actualStateName: 'associateRoleGroup',
        value: tagIdArr
      }
    });
  };
  const associateId = responseObject.associateId;
  const signedAssociateId =
    selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary;
  console.log('associateRole', associateRole);
  console.log('permissionStateOne', permissionStateOne);
  console.log('permissionStateOtwo', permissionStateTwo);
  console.log('permissionStatethree', permissionStateThree);
  // console.log('associateId',associateId);
  // console.log('signedAssociateId',signedAssociateId);
  let classificationArray = [];
  if (
    signedAssesseePermission?.associateDistinctPermission?.assesseeAssociateRoleBespokePermission
      ?.create
  ) {
    classificationArray.push({ id: 'Bespoke', name: 'Bespoke' });
  }
  if (
    signedAssesseePermission?.associateDistinctPermission?.assesseeAssociateRoleGenericPermission
      ?.create
  ) {
    classificationArray.push({ id: 'Generic', name: 'Generic' });
  }

  console.log('classificationArray>>>', classificationArray);

  return (
    <div>
      {isPopUpValue === 'NAMEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'name'}
          actualLableValue={'associateRoleName'}
          actualLableValueVerification={'associateRoleNameVerification'}
          headerPanelColour={'genericOne'}
          headerOne={'associates'}
          headerOneBadgeOne={'role'}
          headerOneBadgeTwo={'information'}
          nextPopUpValue={'ALIASPOPUP'}
          basicInfo={associateRole.informationBasic}
          typeOfSetObject={SET_ASSOCIATE_ROLE_REDUCER_STATE}
          isRequired={true}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          errorMsg= {popupErrorMsg}
          isNotRevised={reviewMode === 'revise' && associateId !== signedAssociateId}
        />
      )}
      {isPopUpValue === 'ALIASPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'description'}
          actualLableValue={'associateRoleDescription'}
          headerPanelColour={'genericOne'}
          headerOne={'associates'}
          headerOneBadgeOne={'role'}
          headerOneBadgeTwo={'information'}
          basicInfo={associateRole.informationBasic}
          nextPopUpValue={'PICTUREPOPUP'}
          typeOfSetObject={SET_ASSOCIATE_ROLE_REDUCER_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={reviewMode === 'revise' && associateId !== signedAssociateId}
        />
      )}
      {isPopUpValue === 'PICTUREPOPUP' && (
        <PopUpPicture
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'associates'}
          headerOneBadgeOne={'role'}
          headerOneBadgeTwo={'information'}
          actualLableValue={'associateRolePicture'}
          actualLableValueVerification={'associateRolePictureVerification'}
          basicInfo={associateRole.informationBasic}
          typeOfSetObject={SET_ASSOCIATE_ROLE_REDUCER_STATE}
          nextPopUpValue={'ROLEGROUPPOPUP'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={reviewMode === 'revise' && associateId !== signedAssociateId}
        />
      )}
      {isPopUpValue === 'ROLEGROUPPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'associateRoleClassificationPrimary'}
          label={'classification'}
          listSelect={classificationArray}
          // listSelect={[
          //   { id: 'Bespoke', name: 'Bespoke' },
          //   { id: 'Generic', name: 'Generic' }
          // ]}
          mappingValue={'id'}
          inputHeader={''}
          inputHeaderBadgeOne={''}
          inputHeaderBadgeTwo={''}
          labelval={''}
          headerPanelColour={'genericOne'}
          headerOne={'associates'}
          headerOneBadgeOne={'role'}
          headerOneBadgeTwo={'information'}
          isRequired={true}
          nextPopUpValue={reviewMode === 'revise' ? '' : 'CONFIRMATIONPOPUP'}
          basicInfo={associateRole?.informationSetup?.associateRoleClassification}
          typeOfSetObject={SET_ASSOCIATE_ROLE_CLASSIFICAION_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={reviewMode === 'revise' && associateId !== signedAssociateId}
        />
      )}
      {isPopUpValue === 'CANCELPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'cancel'}
          headerOneBadgeOne={''}
          mode={'cancel'}
          onClickYes={onClickCancelYes}
        />
      )}
      {isPopUpValue === 'CONFIRMATIONPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'associates'}
          headerOneBadgeOne={'role'}
          headerOneBadgeTwo={'create'}
          onClickYes={onClickYes}
          onClickNoFun={onClickCancelYes}
        />
      )}
      {isPopUpValue === 'PERMISSIONPOPUP' && (
        <PopUpCheckboxRolePermission
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'role'}
          headerOneBadgeTwo={'information'}
          inputHeader={permissionStateOne}
          inputHeaderBadge={permissionStateTwo}
          typeOfStateObj={permissionStateThree}
          objectName={'associateRole'}
          stateName={'associateRolePermission'}
          informationArr={
            permissionStateTwo === 'distinct'
              ? [
                  { id: 'all', name: 'all' },
                  { id: 'key', name: 'key' }
                ]
              : [{ id: 'key', name: 'key' }]
          }
          informationValue={'associatePermissionInformation'}
          isRolePermission
          valueArr={[
            { name: 'create', disabled: false, checked: false },
            { name: 'delete', disabled: false, checked: false },
            { name: 'move', disabled: false, checked: false },
            { name: 'review', disabled: false, checked: false },
            { name: 'revise', disabled: false, checked: false },
            { name: 'sell', disabled: false, checked: false },
            { name: 'share', disabled: permissionStateThree.includes("Bespoke")?true:false, checked: false }
          ]}
          valueArrState={JSON.parse(
            JSON.stringify(
              associateRole?.informationSetup?.associateRolePermission
                ? associateRole?.informationSetup?.associateRolePermission
                : []
            )
          )}
          nextPopUpValue={''}
          typeOfSetObject={SET_SETUP_PERMISSION_NEW}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          // reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={reviewMode === 'revise' && responseObject.associateId !== signedAssociateId}
        />
      )}
      {isPopUpValue === 'STATUSPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'associateRoleStatus'}
          label={'status'}
          listSelect={[
            { id: 'ACTIVE', name: 'Active' },
            { id: 'SUSPENDED', name: 'Suspended' },
            { id: 'TERMINATED', name: 'Terminated' },
            { id: 'UNVERIFIED', name: 'Unverified' },
            { id: 'CONFIRMED', name: 'Confirmed' }
          ]}
          mappingValue={'id'}
          headerPanelColour={'genericOne'}
          headerOne={'associates'}
          headerOneBadgeOne={'role'}
          headerOneBadgeTwo={'information'}
          isRequired={true}
          basicInfo={statusPopUpValue}
          nextPopUpValue={''}
          typeOfSetObject={SET_ROLE_REDUCER_STATE}
          handleNextPopupValue={() => {}}
          isNotRevised={true}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TAGREADONLYPRIMARYPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tag'}
          labelBadgeOne={'primary'}
          actualLableValue={'associateRoleTagPrimary'}
          headerPanelColour={'genericOne'}
          headerOne={'associates'}
          headerOneBadgeOne={'role'}
          headerOneBadgeTwo={'information'}
          basicInfo={responseObject?.informationEngagement?.associateRoleTag || ''}
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={''}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TAGSECONDARYPOPUP' && (
        <PopUpTagSecondary
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'associates'}
          headerOneBadgeOne={'role'}
          headerOneBadgeTwo={'information'}
          tagSecondary={associateRole?.informationEngagement || {}}
          signInSetup={associateRole?.informationSetup || {}}
          nextPopUpValue={'CONFIRMATIONPOPUP'}
          typeOfSetObject={UPDATE_ASSESSEE_ENGAGEMENT_INFO}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TENURESATRTDATEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tenure'}
          labelBadgeOne={'start'}
          actualLableValue={'associateRoleTenureDateTimeStart'}
          headerPanelColour={'genericOne'}
          headerOne={'associates'}
          headerOneBadgeOne={'role'}
          headerOneBadgeTwo={'information'}
          basicInfo={
            responseObject?.informationEngagement?.associateRoleTenure || 'mm/dd/yyyy --:-- --'
          }
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={''}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TENUREENDDATEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tenure'}
          labelBadgeOne={'end'}
          actualLableValue={'associateRoleTenureDateTimeEnd'}
          headerPanelColour={'genericOne'}
          headerOne={'associates'}
          headerOneBadgeOne={'role'}
          headerOneBadgeTwo={'information'}
          basicInfo={
            responseObject?.informationEngagement?.associateRoleTenure || 'mm/dd/yyyy --:-- --'
          }
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={''}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
    </div>
  );
};

export default PopUpAssociateRoleCreate;
