import { put, takeLatest, takeEvery, call } from 'redux-saga/effects';
import Store from '../../store';
import {
  CREATE_ASSOCIATE_SAGA,
  SET_ASSOCIATE_INFORMATION,
  LOADER_STOP,
  SET_DISPLAY_PANE_THREE_STATE,
  POPUP_CLOSE,
  SET_MOBILE_PANE_STATE,
  GET_COUNTRY_STATE_CITY_LIST_SAGA,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_POPUP_VALUE,
  LOADER_START,
  SET_POPUP_SINGLE_STATE,
  SET_ASSESSEE_INFORMATION_DATA
} from '../../actionType';
import { ASSOCIATE_MODULE_URL, PLATFORM_MODULE_URL } from '../../endpoints';
import { INFORMATION_EXIST_ERROR_MESSAGE } from '../../errorMessage'
const createAssociateApi = async (requestObj) => {
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      Authorization: localStorage.getItem('token')
    }),
    body: JSON.stringify(requestObj.data)
  };
  const response = await fetch(requestObj.URL, requestOptions);
  const json = await response.json();
  return json;
  // return axios
  //   .post(URL,
  //     {
  //       data: requestObj.data,
  //       // headers: headers
  //     }
  //   )
  //   .then((res) => {
  //     console.log('-----res----');
  //     console.log(res.data);
  //     return res.data;
  //   })
  //   .catch(function (error) {
  //     console.log('-----error is given below----');
  //     console.log(error);
  //   });
};

function* workerCountryStateCityListSaga(data) {
  try {
    yield put({ type: LOADER_START });
    const response = yield call(createAssociateApi, {
      data: data.payload.request,
      // URL: GET_COUNTRY_STATE_CITY_LIST_URL
      URL: PLATFORM_MODULE_URL
    });
    let arr = [{_id:'', name: ' ' ,iso3:'',iso2:'',phone_code:'',capital:'',currency:'',currencySymbol:'',native:'',region:'',subregion:'',emoji:'',emojiU:''}];
    if (data.payload.listName === 'country') {
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: {
          stateName: 'allCountryList',
          value: [...arr, ...response.responseObject]
        }
      });
    }
    if (data.payload.listName === 'states') {
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: {
          stateName: 'allProvinceList',
          value: response.responseObject
        }
      });
    }
    if (data.payload.listName === 'city') {
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: {
          stateName: 'allCityList',
          value: response.responseObject
        }
      });
    }
    if (data.payload.listName === 'countryCity') {
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: {
          stateName: 'allCountryCityList',
          value: response.responseObject
        }
      });
    }
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({ type: LOADER_STOP });
  }
}
function* workerCreateAssociateSaga(data) {
  try {
    const userResponse = yield call(createAssociateApi, {
      data: data.payload,
      URL: ASSOCIATE_MODULE_URL
    });
    if (userResponse.responseCode === '000') {
      if(!data.payload.signOn){
        yield put({
          type: SET_ASSOCIATE_INFORMATION,
          payload: userResponse.responseObject[0].associate
        });
        yield put({
          type: SET_DISPLAY_PANE_THREE_STATE,
          payload: {
            headerOne: 'associate',
            headerOneBadgeOne: 'distinct',
            headerOneBadgeTwo: 'information',
            headerOneBadgeThree: 'all',
            responseObject: userResponse.responseObject[0].associate,
            createMode: 'associate',
            reviewMode: 'revise'
          }
        });
        let PopupOptionArr = [...Store.getState().DisplayPaneTwoReducer.middlePaneListPopupOptions];
      let stats = userResponse.responseObject[0]?.associate?.informationEngagement?.associateStatus;
      let isFlaged = userResponse.responseObject[0]?.associate?.informationBasic?.associateFlag;
      let associateId = userResponse.responseObject[0]?.associate?.id;
      let tempArr = [];
      PopupOptionArr.forEach((element) => {
        if ((stats === 'UNCONFIRMED' || stats === 'CONFIRMED') && element.data === 'approve') {
          tempArr.push({ ...element, disabled: true });
        } else tempArr.push(element);
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: {
          stateName: 'middlePaneListPopupOptions',
          value: tempArr
        }
      });
      yield put({
        type: SET_POPUP_SINGLE_STATE,
        payload: {
          stateName: 'selectedTagStatus',
          value: stats
        }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'middlePaneSelectedValue', value: associateId }
      });
      yield put({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'selectedTagValue', value: associateId }
      });
      yield put({
        type: SET_POPUP_SINGLE_STATE,
        payload: {
          stateName: 'isFlaged',
          value: isFlaged
        }
      });
        yield put({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
      }
      yield put({ type: LOADER_STOP });
      yield put({ type: POPUP_CLOSE });
    }else if(userResponse.responseCode === '017' || userResponse.responseCode ==='018'){
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'NAMEALIASPOPUP',
          popupMode: `ASSOCIATE_CREATE`,
          // popupMode: `JOBCREATE`,
          popupErrorMsg: INFORMATION_EXIST_ERROR_MESSAGE
        }
      });
    } else {
      if(data.payload.signOn && userResponse.responseCode ==='504'){
        yield put({
          type:SET_ASSESSEE_INFORMATION_DATA,
          payload:'success'
        })
      }
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
  } catch (e) {
    console.log('ERROR==', e);
    // yield put({
    //   type: SET_POPUP_VALUE,
    //   payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    // });
    yield put({ type: LOADER_STOP });
  }
}

export default function* watchcreateAssociateSaga() {
  yield takeLatest(CREATE_ASSOCIATE_SAGA, workerCreateAssociateSaga);
  yield takeEvery(GET_COUNTRY_STATE_CITY_LIST_SAGA, workerCountryStateCityListSaga);
}
