import { SET_NORM_REDUCER_STATE, CLEAR_NORM_REDUCER_STATE } from '../actionType';
const initialState = {
  normInformation: {
    assessmentNormName: '',
    assessmentNormNameVerification: false,
    assessmentNormDescription: '',
    assessmentNormPicture: '',
    assessmentNormPictureVerification: false

  }
};

const NormCreateReducer = (istate = JSON.parse(JSON.stringify(initialState)), action) => {
  switch (action.type) {
    case SET_NORM_REDUCER_STATE:
      return {
        ...istate,
        normInformation: action.payload
      };
    case CLEAR_NORM_REDUCER_STATE:
      return JSON.parse(JSON.stringify(initialState));
    default:
      return istate;
  }
};

export default NormCreateReducer;
