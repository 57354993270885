import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    CLEAR_DISPLAY_PANE_THREE,
    POPUP_OPEN,
    SET_DISPLAY_TWO_SINGLE_STATE,
    SET_MIDDLEPANE_STATE,
    SET_MOBILE_PANE_STATE,
    SET_POPUP_STATE
} from '../actionType';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import { ASSESSEE_REPORT_POPUP } from '../PopUpConfig';
import Card from '../Molecules/Card/Card';
import CrossIcon from '@material-ui/icons/Clear';
import { REPORT_URL } from '../endpoints';
import { closeRelatedList } from '../Actions/GenericActions';

const AsseseeDistinctReportReviewList = (props) => {
    const dispatch = useDispatch();
    const {
        sectionCreateType,
        middlePaneSelectedValue,
        reviewListDistinctData,
        relatedReviewListDistinctData,
        selectedAssociateInfo,
        assignmentReportsData,
        currentRevisedAssignmentType,
        middlePaneHeaderDuplicate,
        middlePaneHeaderBadgeOne,
        middlePaneHeaderBadgeTwo,
        middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderDuplicateBadgeTwo,
        middlePaneHeaderDuplicateBadgeThree,
        middlePaneHeaderDuplicateBadgeFour,
        middlePaneHeaderDuplicateOne,
        middlePaneHeaderDuplicateOneBadgeOne,
        middlePaneHeaderDuplicateOneBadgeTwo,
        middlePaneHeaderDuplicateOneBadgeThree,
        middlePaneHeaderDuplicateOneBadgeFour,
        duplicateScanCount,
    } = useSelector((state) => state.DisplayPaneTwoReducer);
    const { responseObject, headerOne, headerOneBadgeOne, createMode } = useSelector(
        (state) => state.DisplayPaneThreeReducer
    );
    let tempValidationObj = {
        "iGuru Analytics_Occupation": "iGuru Analytics_Occupation",
        "iGuru Analytics_Career": "iGuru Analytics_Career",
        "iGuru Mine_Occupation": "iGuru Mine_Occupation",
        "iGuru Marketplace_Career": "iGuru Marketplace_Career",
        "iGuru Marketplace_Occupation": "iGuru Marketplace_Occupation",
        "assessees": "assessees",
        "assignments": "assignments",
        // "assesseeSelf" : "assesseeSelf"
    }
    const { assesseeAssignment } = responseObject;
    const closeRelatedListClick = () => {
        // dispatch({
        //     type: SET_MIDDLEPANE_STATE,
        //     payload: {
        //         middlePaneHeader: 'assignments',
        //         middlePaneHeaderBadgeOne: 'distinct',
        //         middlePaneHeaderBadgeTwo: 'active',
        //         middlePaneHeaderBadgeThree: '',
        //         middlePaneHeaderBadgeFour: '',
        //         typeOfMiddlePaneList: 'assignmentDistinctReviewList',
        //         scanCount: reviewListDistinctData.length,
        //         showMiddlePaneState: true
        //     }
        // });

        let previousMiddlePaneList = middlePaneHeaderDuplicateOne.includes('assignment') ? 'assignmentDistinctReviewList' : middlePaneHeaderDuplicateOne + 'DistinctReviewList';

        if (sectionCreateType === 'assesseeSelf') {
            previousMiddlePaneList = 'assesseeAssignmentDistinctReviewList'
        }

        closeRelatedList(
            dispatch,
            createMode,
            responseObject,
            middlePaneHeaderDuplicate,
            middlePaneHeaderDuplicateBadgeOne,
            middlePaneHeaderDuplicateBadgeTwo,
            middlePaneHeaderDuplicateBadgeThree,
            middlePaneHeaderDuplicateBadgeFour,
            previousMiddlePaneList,
            duplicateScanCount,
            '', //'assignmentsDistinctinactive',
            duplicateScanCount,
            middlePaneHeaderDuplicateOne,
            middlePaneHeaderDuplicateOneBadgeOne,
            middlePaneHeaderDuplicateOneBadgeTwo,
            middlePaneHeaderDuplicateOneBadgeThree,
            middlePaneHeaderDuplicateOneBadgeFour
        );

        dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
    };


    const listDistinctData = relatedReviewListDistinctData[0];
    const openListPopup = (e) => {
        console.log(e.currentTarget.getAttribute('tag'));

        // debugger
        if (tempValidationObj.hasOwnProperty(sectionCreateType)) {

            let tempArr = [];
            ASSESSEE_REPORT_POPUP.forEach((element) => {
                if (
                    responseObject?.assesseeAssignment?.assesseeAssignmentStatusReport === 'PUBLISHED' &&
                    element.data === 'revise'
                ) {
                    tempArr.push({ ...element, disabled: true });
                } else {
                    tempArr.push(element);
                }
            });
            dispatch({
                type: SET_POPUP_STATE,
                payload: {
                    popupHeaderOne: 'assignment',
                    popupHeaderOneBadgeOne: 'report',
                    isPopUpValue: '',
                    popupOpenType: 'primary',
                    popupContentArrValue: tempArr,
                    selectedTagValue: responseObject?.assesseeAssignment?.assesseeAssignmentId,
                    selectedTagStatus:
                        responseObject?.assesseeAssignment?.assesseeAssignmentStatusReport || 'UNPUBLISHED'
                }
            });
            dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
            dispatch({
                type: SET_DISPLAY_TWO_SINGLE_STATE,
                payload: {
                    stateName: 'middlePaneListPopupOptions',
                    value: ASSESSEE_REPORT_POPUP
                }
            });

            //   let optArr = ASSESSEE_REPORT_POPUP;
            //   let tempArr = [];
            //   optArr.map((element) => {
            //     if (element.data === 'review') {
            //       tempArr.push(element);
            //     }
            //   });
            //   tempArr = [
            //     { ...tempArr[0], data: 'assessees', dataValue: 'reviewReport', dataKey: 'reviewReport', permissionValue: 'review' }
            //   ];

            //   dispatch({
            //     type: SET_POPUP_STATE,
            //     payload: {
            //       popupHeaderOne: 'assignment',
            //       popupHeaderOneBadgeOne: 'report',
            //       isPopUpValue: '',
            //       popupOpenType: 'primary',
            //       popupContentArrValue: tempArr,
            //       selectedTagValue: e.currentTarget.getAttribute('tag'),
            //       selectedTagStatus: e.currentTarget.getAttribute('status')
            //     }
            //   });
            //   dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
            //   dispatch({
            //     type: SET_DISPLAY_TWO_SINGLE_STATE,
            //     payload: {
            //       stateName: 'middlePaneListPopupOptions',
            //       value: tempArr
            //     }
            //   });
        }
    };

    const openAssesseeReportPDF = () => {
        let signedAssesseeId = selectedAssociateInfo?.assesseeId;
        let signedAssociateId =
            selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary;
        let signedAssociateName = selectedAssociateInfo?.associate?.informationBasic.associateName;
        let reportUrl = '';
        if (headerOne === 'assessee') {
            if (signedAssociateName === 'Richard McConkey') {
                reportUrl = REPORT_URL + 'richReport';
                // } else if (signedAssociateName === 'Prafulta') {
                //     reportUrl = REPORT_URL + 'reports';
            } else if (signedAssociateName === 'Boppo Technologies' || signedAssociateName === 'Prafulta') {
                reportUrl = REPORT_URL + 'reports';
            } else {
                reportUrl = REPORT_URL + 'richReport';
            }
            let assesseeId = responseObject?.assesseeAssignment?.assesseeId;
            let assesseeAssignmentId = responseObject?.assesseeAssignment?.assesseeAssignmentId;
            let token =
                localStorage.getItem('token') + '@@@@' + signedAssesseeId + '@@@@' + signedAssociateId;
            window.open(
                reportUrl +
                '?assesseeId=' +
                assesseeId +
                '&assesseeAssignmentId=' +
                assesseeAssignmentId +
                '&token=' +
                token +
                ''
            );
        }
        if (headerOne === 'assignment') {
            reportUrl = REPORT_URL + 'meritlist/';
            let assesseeAssignmentId = responseObject?.assignmentId;
            let token =
                localStorage.getItem('token') + '@@@@' + signedAssesseeId + '@@@@' + signedAssociateId;
            window.open(reportUrl + '?assignmentId=' + assesseeAssignmentId + '&token=' + token + '');
        }
    };
    return (
        <div>
            {listDistinctData && (
                <Card
                    textOneOne={listDistinctData?.assignmentName}
                    textTwoOne={listDistinctData?.assignmentDescription}
                    IconOne={CrossIcon}
                    isIcon={true}
                    labelTwoTwo={'report'}
                    onClickIconOne={closeRelatedListClick}
                    isAlliance
                    relatedCardFixed={true}
                    className={'iguru-iconboxSVG'}
                />
            )}
            {listDistinctData &&
                listDistinctData.report.map((item, index) => {
                    return (
                        <div className="containerPadding" key={index}>
                            <ReviewList
                                className=""
                                id={index}
                                tag={item.id}
                                isSelectedReviewList={middlePaneSelectedValue === item.id}
                                status={''}
                                actualStatus={''}
                                isReportIcon={true}
                                textOne={item.reportName}
                                textTwo={item.reportDescription}
                                isTooltipActive={false}
                                onClickEvent={openListPopup}
                                isListIconActive={true}
                                onClickListIcon={tempValidationObj.hasOwnProperty(sectionCreateType) || sectionCreateType === 'assesseeSelf' ? openAssesseeReportPDF : () => { }}
                            />
                        </div>
                    );
                })}
        </div>
    );
};
export default AsseseeDistinctReportReviewList;
