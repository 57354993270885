import React, { Fragment } from 'react';

import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import { Clear, KeyboardTab } from '@material-ui/icons';
import NextIcon from '@material-ui/icons/ArrowForward';
import Check from '@material-ui/icons/Check';
import Previous from '@material-ui/icons/ArrowBack';
import './PopUp.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  POPUP_CLOSE,
  SET_NEXT_POPUP,
  CLEAR_ASSESSEE_INFO,
  PREVIOUS_POPUP,
  CLEAR_ASSOCIATE_INFO,
  CLEAR_ASSIGNMENT_INFO,
  CLEAR_IGAUGE_REDUCER,
  CLEAR_ROLE_REDUCER_STATE,
  CLOSE_ASSESSMENT_POPUP_OPEN,
  SET_POPUP_VALUE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  ASSESSEE_INFO_CREATE,
  SET_ASSIGNMENT_PREVIOUS_POPUP,
  ASSIGNMENT_POPUP_CLOSE,
  ASSOCIATE_CREATE_INFO,
  IGAUGE_SECTION_POPUP_CLOSE,
  SET_POPUP_SINGLE_STATE
} from '../../actionType';

const PopupHeader = (props) => {
  const {
    headerPanelColour,
    headerOne,
    headerOneBadgeOne,
    headerOneBadgeTwo,
    headerOneBadgeThree,
    onClick,
    itemRoleType,
    mode = 'core',
    isNotRevised = false,
    onClosePopUpEvent = null,
    setexchageMode = null,
    forwardArrow =false,
    forwardArrowFn = ()=>{}
  } = props;
  const dispatch = useDispatch();
  const { isPopUpValue, originPrimaryArrOprion, headerButtons } = useSelector((state) => state.PopUpReducer);
  const { isForceToOpenNextPopup } = useSelector((state) => state.DisplayPaneTwoReducer);

  // console.log('mode',mode);
  const onClose = () => {
    if (onClosePopUpEvent !== null) {
      onClosePopUpEvent();
    } else {
      if (mode === 'cancel' || mode === 'core' || mode === 'confirm') {
        dispatch({ type: PREVIOUS_POPUP, payload: { prevPopUpValue: isPopUpValue } });
        dispatch({ type: SET_NEXT_POPUP, payload: { isPopUpValue: 'CANCELPOPUP' } });
      } else if (mode === 'revise') {
        dispatch({ type: POPUP_CLOSE });
      } else if (mode === 'error' && isForceToOpenNextPopup !== '') {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: isForceToOpenNextPopup, popupMode: 'ASSESSMENTCREATE' }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'isForceToOpenNextPopup', value: '' }
        });
        console.log('Open force popup');
      } else if (mode === 'error') {
        dispatch({ type: POPUP_CLOSE });
        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'headerButtons', value: true }
        });
      } else {
        dispatch({ type: ASSESSEE_INFO_CREATE });
        dispatch({ type: CLOSE_ASSESSMENT_POPUP_OPEN });
        dispatch({ type: POPUP_CLOSE });
        dispatch({ type: ASSIGNMENT_POPUP_CLOSE });
        dispatch({ type: ASSOCIATE_CREATE_INFO });
        dispatch({ type: IGAUGE_SECTION_POPUP_CLOSE });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'middlePaneListPopupOptions',
            value: originPrimaryArrOprion
          }
        });
        // dispatch({ type: CLEAR_ROLE_REDUCER_STATE });
        //dispatch({ type: CLEAR_ASSESSMENT_INFO });
        // setexchageMode();
      }
    }
  };

  return (
    <DialogTitle id="dialog-title" className={'popupHeaderTitle'}>
      <Paper
        className={['popupMainHeader', 'iguru-box-shadow', `titleSolid-${headerPanelColour}`].join(
          ' '
        )}
      >
        <div
          style={{ padding: '0, 5px' }}
          className={['componentInnerDiv', 'popupMainHeader'].join(' ')}
        >
          <div className={'titleBox'}>
            <span>{headerOne}</span>&nbsp;
            {headerOneBadgeOne ? (
              <Fragment>
                <span className={'iguru-header-badge1_0'}>{headerOneBadgeOne === 'selection' ? 'select' : headerOneBadgeOne}</span>
                &nbsp;
              </Fragment>
            ) : null}
            {headerOneBadgeTwo ? (
              <Fragment>
                <span className={'iguru-header-badge1_0'}>{headerOneBadgeTwo === 'selection' ? 'select' : headerOneBadgeTwo}</span>
                &nbsp;
              </Fragment>
            ) : null}
            {headerOneBadgeThree ? (
              <Fragment>
                <span className={'iguru-header-badge1_0'}>{headerOneBadgeThree}</span>
                &nbsp;
              </Fragment>
            ) : null}
          </div>
          <div className={'backArrow'}>
            {!isNotRevised && headerButtons && mode !== 'cancel' && mode !== 'error' && (
              <IconButton className="MuiIconButton-root-1602" onClick={onClick}>
                {mode === 'core' || mode === 'search' || mode === 'revise' ? (
                  <Check className={'popupClose'} />
                ) : mode === 'confirm' ? (
                  <KeyboardTab
                    className={['popupClose', 'previousToLast'].join(' ')}

                  />
                ) : mode === 'error' || mode === 'cancel' ? null : mode === 'next' ? (
                  <NextIcon className={'popupClose'} />
                ) : (
                  <Previous className={'popupClose'} />
                )}
                {/* {headerPanelColour === 'genericOne' ? (
                <Check className={'popupClose'} onClick={onClick} />
              ) : headerPanelColour === 'genericTwo' ? null : (
                <Previous className={'popupClose'} />
              )} */}
              </IconButton>
            )}
          </div>
          {forwardArrow ?
            <div className={'backArrow'}>
              {mode !== 'cancel' && (
                <IconButton onClick={forwardArrowFn} className="MuiIconButton-root-1602">
                  <Check className={'popupClose'} />
                </IconButton>
              )}
            </div> :
            <div className={'backArrow'}>
              {mode !== 'cancel' && (
                <IconButton onClick={onClose} className="MuiIconButton-root-1602">
                  <Clear className={'popupClose'} />
                </IconButton>
              )}
            </div>
          }
        </div>
      </Paper>
    </DialogTitle>
  );
};
export default PopupHeader;
