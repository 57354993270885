import { put, takeLatest, call } from 'redux-saga/effects';
import {
  GROUP_CLASSIFICATION_LIST_SAGA,
  ASSESSEE_GROUP_INFO_REVISE_SAGA,
  GET_ASSESSEEGROUP_ASSESSEE_REVIEW_LIST,
  GET_ASSESSEE_GROUP_REVIEW_INFO_SAGA,
  GET_ASSESSEE_GROUP_REVIEW_LIST_SAGA,
  LOADER_STOP,
  SET_ASSESSEE_GROUP_ASSESSEE_ID_LIST,
  SET_ASSESSEE_GROUP_REDUCER_STATE,
  SET_CORE_ROLE_REVIEW_LIST_DATA,
  SET_DISPLAY_PANE_THREE_STATE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_POPUP_VALUE,
  SET_UNSELECTED_ASSESSEE_GROUP_ASSESSEE_ID_LIST,
  SET_GROUP_SETUP_STATE,
  SET_DISPLAY_THREE_SINGLE_STATE,
  SET_GROUP_ALLOCATION_GROUP_ASCENDANT_STATE,
  SET_GROUP_ALLOCATION_STATE
} from '../../actionType';
import { ASSESSEE_MODULE_URL } from '../../endpoints';
import { EXCEPTION_ERROR_MESSAGE } from '../../errorMessage';
import Store from '../../store';
const assesseeGroupInfoApi = async (requestObj) => {
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      Authorization: localStorage.getItem('token')
    }),
    body: JSON.stringify(requestObj.data)
  };
  const response = await fetch(requestObj.URL, requestOptions);
  const json = await response.json();
  return json;
};

function* workerReviewAssesseeGroupInfoSaga(data) {
  try {
    const userResponse = yield call(assesseeGroupInfoApi, {
      data: data.payload.reqBody,
      URL: ASSESSEE_MODULE_URL
    });
    if (userResponse.responseCode === '000') {
      const {
        isReviseMode = false,
        assesseeGroupAssesseeReqBody = null,
        middlePaneHeader = 'assessees',
        createMode = ''
      } = data.payload;
      console.log('IN GROUP REVIEW+++++', userResponse);
      if (assesseeGroupAssesseeReqBody !== null) {
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
        yield put({
          type: GET_ASSESSEEGROUP_ASSESSEE_REVIEW_LIST,
          payload: {
            request: assesseeGroupAssesseeReqBody,
            HeaderOne: middlePaneHeader,
            BadgeOne: '',
            BadgeTwo: '',
            BadgeThree: '',
            isMiddlePaneList: false
          }
        });
      }
      yield put({
        type: SET_DISPLAY_PANE_THREE_STATE,
        payload: {
          headerOne: data.payload.middlePaneHeader ? data.payload.middlePaneHeader : 'assessees',
          headerOneBadgeOne: 'group',
          headerOneBadgeTwo: 'information',
          headerOneBadgeThree: 'key',
          createMode: createMode,
          responseObject: userResponse.responseObject[0],
          reviewMode: isReviseMode ? 'revise' : ''
        }
      });
      if (isReviseMode) {
        yield put({
          type: SET_ASSESSEE_GROUP_REDUCER_STATE,
          payload: userResponse.responseObject[0].informationBasic
        });
        yield put({
          type: SET_GROUP_SETUP_STATE,
          payload: {
            objectName: 'assesseeGroup',
            value: userResponse.responseObject[0].informationSetup
          }
        });
        let tempArr = [];
        if (
          userResponse.responseObject[0].informationAllocation &&
          userResponse.responseObject[0].informationAllocation?.assesseeGroupAscendant
            ?.assesseeGroupAscendantPrimary &&
          userResponse.responseObject[0].informationAllocation?.assesseeGroupAscendant
            ?.assesseeGroupAscendantPrimary?.length > 0
        ) {
          tempArr =
            userResponse.responseObject[0].informationAllocation?.assesseeGroupAscendant?.assesseeGroupAscendantPrimary.map(
              (ob) => ob.id
            );
        }
        yield put({
          type: SET_GROUP_ALLOCATION_GROUP_ASCENDANT_STATE,
          payload: {
            objectName: 'assesseeGroup',
            stateName: 'assesseeGroupAscendantPrimary',
            value: tempArr
          }
        });
        if (
          userResponse.responseObject[0].informationAllocation &&
          userResponse.responseObject[0].informationAllocation?.assesseeGroupNode
            ?.assesseeGroupNodePrimary &&
          userResponse.responseObject[0].informationAllocation?.assesseeGroupNode
            ?.assesseeGroupNodePrimary?.length > 0
        ) {
          let tempArr =
            userResponse.responseObject[0].informationAllocation?.assesseeGroupNode?.assesseeGroupNodePrimary.map(
              (ob) => ob.id
            );
          yield put({
            type: SET_GROUP_ALLOCATION_STATE,
            payload: {
              objectName: 'assesseeGroup',
              stateName: 'assesseeGroupNode',
              actualStateName: 'assesseeGroupNodePrimary',
              value: tempArr
            }
          });
        }
        if (
          userResponse.responseObject[0].informationAllocation &&
          userResponse.responseObject[0].informationAllocation?.assesseeGroupNode
            ?.assesseeGroupNodeSecondary &&
          userResponse.responseObject[0].informationAllocation?.assesseeGroupNode
            ?.assesseeGroupNodeSecondary?.length > 0
        ) {
          let tempArr =
            userResponse.responseObject[0].informationAllocation?.assesseeGroupNode?.assesseeGroupNodeSecondary.map(
              (ob) => ob.id
            );
          yield put({
            type: SET_GROUP_ALLOCATION_STATE,
            payload: {
              objectName: 'assesseeGroup',
              stateName: 'assesseeGroupNode',
              actualStateName: 'assesseeGroupNodeSecondary',
              value: tempArr
            }
          });
        }

        if (
          userResponse.responseObject[0].informationAllocation &&
          userResponse.responseObject[0].informationAllocation?.assesseeGroupType
            ?.assesseeGroupTypeSecondary &&
          userResponse.responseObject[0].informationAllocation?.assesseeGroupType
            ?.assesseeGroupTypeSecondary?.length > 0
        ) {
          let tempArr =
            userResponse.responseObject[0].informationAllocation?.assesseeGroupType?.assesseeGroupTypeSecondary.map(
              (ob) => ob.id
            );
          yield put({
            type: SET_GROUP_ALLOCATION_STATE,
            payload: {
              objectName: 'assesseeGroup',
              stateName: 'assesseeGroupType',
              actualStateName: 'assesseeGroupTypeSecondary',
              value: tempArr
            }
          });
        }

      }
    } else {
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: userResponse.responseMessage,
          popupMode: 'responseErrorMsg'
        }
      });
    }

    console.log('loading end');
    // yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
    yield put({ type: LOADER_STOP });
  }
}

function* workerReviseAssesseeGroupInfoSaga(data) {
  try {
    const userResponse = yield call(assesseeGroupInfoApi, {
      data: data.payload.reqBody,
      URL: ASSESSEE_MODULE_URL
    });
    if (userResponse.responseCode === '000') {
      // add object isReviseCallFromPaneTwo=false

      const {
        assesseeGroupAssesseeReqBody = null,
        createMode = '',
        isReviseCallFromPaneTwo = false,
        cardValue = ''
      } = data.payload;
      if (assesseeGroupAssesseeReqBody !== null) {
        yield put({
          type: GET_ASSESSEEGROUP_ASSESSEE_REVIEW_LIST,
          payload: {
            request: assesseeGroupAssesseeReqBody,
            HeaderOne: data.payload.headerOne
            ? data.payload.headerOne
            :'assessees',
            BadgeOne: '',
            BadgeTwo: '',
            BadgeThree: '',
            isMiddlePaneList: false
          }
        });
        console.log('IN GROUP REVIEW+++++', userResponse);

        if (isReviseCallFromPaneTwo) {
          yield put({
            type: SET_DISPLAY_THREE_SINGLE_STATE,
            payload: { stateName: 'isFrameworkOpen', value: 'assesseeGroup' }
          });
        } else {
          yield put({
            type: SET_DISPLAY_THREE_SINGLE_STATE,
            payload: { stateName: 'isFrameworkOpen', value: '' }
          });
        }
        console.log(
          'class>>>>>>>',
          userResponse.responseObject[0].informationSetup?.assesseeGroupClassification
            ?.assesseeGroupClassificationPrimary
        );
        let tempArr = [];
        Store.getState().DisplayPaneTwoReducer.middlePaneListPopupOptions.forEach((element) => {
          if (
            element.data === 'share' &&
            userResponse.responseObject[0].informationSetup?.assesseeGroupClassification
              ?.assesseeGroupClassificationPrimary === 'Bespoke'
          ) {
            tempArr.push({ ...element, disabled: true });
          } else if (
            element.data === 'share' &&
            userResponse.responseObject[0].informationSetup?.assesseeGroupClassification
              ?.assesseeGroupClassificationPrimary === 'Generic'
          ) {
            tempArr.push({ ...element, disabled: false });
          } else {
            tempArr.push(element);
          }
        });
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'middlePaneListPopupOptions',
            value: tempArr
          }
        });
        yield put({
          type: SET_DISPLAY_PANE_THREE_STATE,
          payload: {
            headerOne: data.payload.headerOne ? data.payload.headerOne : 'assessees',
            headerOneBadgeOne: 'group',
            headerOneBadgeTwo: 'information',
            headerOneBadgeThree: 'key',
            responseObject: userResponse.responseObject[0],
            createMode,
            // add one

            reviewMode: isReviseCallFromPaneTwo ? 'revise' : ''
          }
        });
      }

      // chnages else do no chnage
      if (createMode === '') {
        yield put({ type: SET_ASSESSEE_GROUP_ASSESSEE_ID_LIST, payload: [] });
        yield put({
          type: SET_UNSELECTED_ASSESSEE_GROUP_ASSESSEE_ID_LIST,
          payload: []
        });
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'reviewListDistinctData', value: [] }
        });
        yield put({
          type: GET_ASSESSEE_GROUP_REVIEW_LIST_SAGA,
          payload: {
            middlePaneHeader: data.payload.headerOne ? data.payload.headerOne : 'assessees',
            request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
            BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
            isMiddlePaneList: true,
            cardValue
          }
        });
      } else {
        yield put({ type: LOADER_STOP });
      }
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: userResponse.responseMessage || EXCEPTION_ERROR_MESSAGE,
          popupMode: 'responseErrorMsg'
        }
      });
      yield put({ type: LOADER_STOP });
    }

    console.log('loading end');
    // yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerGroupClassificationListSaga(data) {
  try {
    const userResponse = yield call(assesseeGroupInfoApi, {
      data: data.payload.request,
      URL: ASSESSEE_MODULE_URL
    });
    if (userResponse.responseCode === '000') {
      yield put({
        type: SET_CORE_ROLE_REVIEW_LIST_DATA,
        payload: userResponse.responseObject
      });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: userResponse.responseMessage,
          popupMode: 'responseErrorMsg'
        }
      });
    }
    yield put({ type: LOADER_STOP });
    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
    yield put({ type: LOADER_STOP });
  }
}
export default function* watchReviewAssesseeGroupInfoSaga() {
  yield takeLatest(GET_ASSESSEE_GROUP_REVIEW_INFO_SAGA, workerReviewAssesseeGroupInfoSaga);
  yield takeLatest(ASSESSEE_GROUP_INFO_REVISE_SAGA, workerReviseAssesseeGroupInfoSaga);
  yield takeLatest(GROUP_CLASSIFICATION_LIST_SAGA, workerGroupClassificationListSaga);
}
