import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
// import AllocationAccordian from '../Accordian/AllocationAccordian';
import Manuscript from '@material-ui/icons/Description';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, InputLabel, Paper } from '@material-ui/core';
import { convertToLocalTime, calculateTime } from '../../Actions/GenericActions';
import { Fragment } from 'react';
import ReviewList from '../ReviewList/ReviewList';
import { SET_POPUP_VALUE } from '../../actionType';

const DisplayPaneThreeSectionOneAssignmentReport = () => {
  const [selectedBadge, setSelectedBadge] = useState('analysis');
  const { responseObject } = useSelector((state) => state.DisplayPaneThreeReducer);
  const { assesseeAssignment } = responseObject;
  const dispatch = useDispatch();
  const OpenGaugePopupReport = () => {
    if (selectedBadge === 'gauge') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'GAUGELISTPOPUP', popupMode: 'ASSIGNMENT_REPORT_REVIEW' }
      });
    }
  };
  return (
    <div
      style={{
        height: 'calc(100vh - 355px)',
        overflow: 'overlay'
      }}
    >
      <>
        <div className={'containerPadding'}>
          <Paper className={'dossierContainerTop'}>
            <div className={'detailsContactContainer'}>
              <div className={['FormBox'].join(' ')}>
                <div className={'containerPadding'} style={{ width: '100%' }}>
                  <div className={'detsailsPadding-one'}>
                    <div
                      // disableFocusRipple={true}
                      // disableRipple={true}
                      className={['heightInherit'].join(' ')}
                    >
                      <div
                        className={['measureBox', 'heightInherit'].join(' ')}
                        style={{ cursor: 'default' }}
                      >
                        <div style={{ flex: '4' }}>
                          <InputLabel
                            htmlFor="name-input"
                            className={[
                              'textForLabel',
                              'textForLabelRight',
                              'careerLabelRight'
                            ].join(' ')}
                          >
                            <span
                              style={{
                                marginBottom: '5px',
                                display: 'inline-block'
                              }}
                              className={['reviewLabelClass','reviewLinkText'].join(' ')}
                              data-value={'assessees'}
                              data-key={'assessees' || ''}
                              onClick={OpenGaugePopupReport}
                            >
                              assessees
                            </span>
                            <sup
                              onClick={() => {
                                setSelectedBadge('analysis');
                              }}
                              style={{
                                backgroundColor:
                                  selectedBadge === 'analysis' ? 'rgb(242, 242, 242)' : ''
                              }}
                            >
                              analysis
                            </sup>
                            <sup
                              onClick={() => {
                                setSelectedBadge('evaluation');
                              }}
                              style={{
                                backgroundColor:
                                  selectedBadge === 'evaluation' ? 'rgb(242, 242, 242)' : ''
                              }}
                            >
                              evaluation
                            </sup>
                            <sup
                              onClick={() => {
                                setSelectedBadge('gauge');
                              }}
                              style={{
                                backgroundColor:
                                  selectedBadge === 'gauge' ? 'rgb(242, 242, 242)' : ''
                              }}
                            >
                              gauge
                            </sup>
                          </InputLabel>
                        </div>
                        <div className={['flex-center', 'flex-center-one'].join(' ')}></div>
                        <div className={['flex-center', 'flex-center-one'].join(' ')}>
                          <IconButton>
                            <Manuscript />
                          </IconButton>
                          {/* <span style={{ fontSize: '1rem' }}>sheet</span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* {selectedBadge === 'analysis' && (
                <div>
                  {assesseeAssignment.assesseeAssignmentAssessmentDistinct.length > 0 ? (
                    assesseeAssignment.assesseeAssignmentAssessmentDistinct.map(
                      (assesmentResult, index) => (
                        <div key={index}>
                          <div className={'containerPadding'}>
                            <div
                              disableFocusRipple={true}
                              disableRipple={true}
                              className={['heightInherit', 'detsailsPadding-one'].join(' ')}
                              style={{ textTransform: 'none' }}
                            >
                              <div
                                className={['measureBox', 'heightInherit'].join(' ')}
                                style={{ cursor: 'default' }}
                              >
                                <div style={{ flex: '4' }}>
                                  <div className={['midPaneInformation'].join(' ')}>
                                    {assesmentResult.assesseeAssignmentAssessmentName}
                                  </div>
                                  {assesseeAssignment.assesseeAssignmentAssessmentDescription ===
                                  '' ? null : (
                                    <div
                                      style={{ textAlign: 'left' }}
                                      className={['midPaneLabel', 'textOverflow'].join(' ')}
                                    >
                                      {assesseeAssignment.assesseeAssignmentAssessmentDescription}
                                    </div>
                                  )}
                                </div>
                                <div
                                  style={{ flex: '1', display: 'flex', alignItems: 'center' }}
                                  className="flex-center"
                                ></div>
                                <div
                                  style={{
                                    flex: '1',
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column'
                                  }}
                                  className="flex-center"
                                ></div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className={'containerPadding'}>
                              <div className={'detsailsPadding-one'} style={{ display: 'flex' }}>
                                <div className={['unitFlex', 'unitFlexTop'].join(' ')}>
                                  <div
                                    className={['unitFlex', 'midPaneInformation'].join(' ')}
                                    style={{ alignItems: 'flex-start' }}
                                  >
                                    {assesmentResult.assesseeAssignmentAssessmentItemResponseAttempted ||
                                      '-'}
                                  </div>
                                  <div className={['unitFlex', 'reports-center'].join(' ')}>
                                    attempted
                                  </div>
                                </div>
                                <div className={['unitFlex', 'unitFlexTop'].join(' ')}>
                                  <div
                                    className={['unitFlex', 'midPaneInformation'].join(' ')}
                                    style={{ alignItems: 'flex-start' }}
                                  >
                                    {assesmentResult.assesseeAssignmentAssessmentItemResponseCorrect ||
                                      '-'}
                                  </div>
                                  <div className={['unitFlex', 'reports-center'].join(' ')}>
                                    correct
                                  </div>
                                </div>
                                <div className={['unitFlex', 'unitFlexTop'].join(' ')}>
                                  <div
                                    className={['unitFlex', 'midPaneInformation'].join(' ')}
                                    style={{ alignItems: 'flex-start' }}
                                  >
                                    {assesmentResult.assesseeAssignmentAssessmentItemTotal || '-'}
                                  </div>
                                  <div className={['unitFlex', 'reports-center'].join(' ')}>
                                    total
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={'containerPadding'}>
                              <div className={'detsailsPadding-one'} style={{ display: 'flex' }}>
                                <div
                                  className={['measureBox', 'heightInherit'].join(' ')}
                                  style={{ cursor: 'default', flex: 6 }}
                                >
                                  <div
                                    className={['unitFlex', 'unitFlexTop'].join(' ')}
                                    style={{ flex: 2 }}
                                  >
                                    <div
                                      className={['unitFlex', 'midPaneInformation'].join(' ')}
                                      style={{ fontSize: '1rem' }}
                                    >
                                      {convertToLocalTime(
                                        assesmentResult.assesseeAssignmentAssessmentTimeline
                                          ?.assesseeAssignmentAssessmentTimelineDateTimeStart
                                      )}
                                    </div>
                                    <div className={['unitFlex', 'reports-center'].join(' ')}>
                                      start
                                    </div>
                                  </div>
                                  <div
                                    className={['unitFlex', 'unitFlexTop'].join(' ')}
                                    style={{ flex: 2 }}
                                  >
                                    <div
                                      className={['unitFlex', 'midPaneInformation'].join(' ')}
                                      style={{ fontSize: '1rem' }}
                                    >
                                      {convertToLocalTime(
                                        assesmentResult.assesseeAssignmentAssessmentTimeline
                                          ?.assesseeAssignmentAssessmentTimelineDateTimeEnd
                                      )}
                                    </div>
                                    <div className={['unitFlex', 'reports-center'].join(' ')}>
                                      end
                                    </div>
                                  </div>
                                  <div
                                    className={['unitFlex', 'unitFlexTop'].join(' ')}
                                    style={{ flex: 2 }}
                                  >
                                    <div
                                      className={['unitFlex', 'midPaneInformation'].join(' ')}
                                      style={{ fontSize: '1rem' }}
                                    >
                                      {calculateTime(
                                        assesmentResult.assesseeAssignmentAssessmentAttemptedTotalTime
                                      )}
                                    </div>
                                    <div className={['unitFlex', 'reports-center'].join(' ')}>
                                      time
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    )
                  ) : (
                    <div></div>
                  )}
                </div>
              )}
              {selectedBadge === 'evaluation' && (
                <>
                  {assesseeAssignment.assesseeAssignmentAssessmentDistinct.length > 0 ? (
                    assesseeAssignment.assesseeAssignmentAssessmentDistinct.map(
                      (assesmentResult, index) => (
                        <div key={index}>
                        <div className={'containerPadding'}>
                            <div
                              disableFocusRipple={true}
                              disableRipple={true}
                              className={['heightInherit','detsailsPadding-one'].join(' ')}
                              style={{ textTransform: 'none' }}
                            >
                              <div
                                className={['measureBox', 'heightInherit'].join(' ')}
                                style={{ cursor: 'default' }}
                              >
                                <div style={{ flex: '4' }}>
                                  <div className={['midPaneInformation'].join(' ')}>
                                    {assesmentResult.assesseeAssignmentAssessmentName}
                                  </div>
                                  {assesmentResult.assesseeAssignmentAssessmentDescription ===
                                  '' ? null : (
                                    <div
                                      style={{ textAlign: 'left' }}
                                      className={['midPaneLabel', 'textOverflow'].join(' ')}
                                    >
                                      {assesmentResult.assesseeAssignmentAssessmentDescription}
                                    </div>
                                  )}
                                </div>
                                <div className={['flex-center', 'flex-center-one '].join(' ')}>
                                  {assesmentResult?.assesseeAssignmentAssessmentScore
                                    ?.assesseeAssignmentAssessmentScoreExtremumMaximum || '-'}
                                  <span style={{ fontSize: '1rem' }}>maximum</span>
                                </div>{' '}
                                <div className={['flex-center', 'flex-center-one '].join(' ')}>
                                  {assesmentResult?.assesseeAssignmentAssessmentScore
                                    ?.assesseeAssignmentAssessmentScoreExtremumMinimum || '-'}
                                  <span style={{ fontSize: '1rem' }}>minimum</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={''}>
                            {(assesmentResult?.assesseeAssignmentAssessmentCluster?.length > 0 && (
                              <Fragment>
                                {assesmentResult?.assesseeAssignmentAssessmentCluster.map(
                                  (clust) => {
                                    return (
                                      <div className={'containerPadding'}>
                                        <ReviewList
                                          className=""
                                          id={clust?.assesseeAssignmentAssessmentClusterId}
                                          textOne={clust?.assesseeAssignmentAssessmentClusterName}
                                          textTwo={
                                            clust?.assesseeAssignmentAssessmentClusterDescription
                                          }
                                          status={'percentile'}
                                          isInformation={true}
                                          isInformationValue={
                                            clust?.assesseeAssignmentAssessmentClusterScorePercentile
                                          }
                                          isInformationTwo={true}
                                          isInformationTwoValue={
                                            clust?.assesseeAssignmentAssessmentClusterItemScoreTotal
                                          }
                                          statusTwo={'score'}
                                        />
                                      </div>
                                    );
                                  }
                                )}
                              </Fragment>
                            )) || (
                              <Fragment>
                                <div style={{ display: 'flex' }}>
                                  <div className={['unitFlex', 'unitFlexTop'].join(' ')}>
                                    <div
                                      className={['unitFlex', 'midPaneInformation'].join(' ')}
                                      style={{ alignItems: 'flex-start' }}
                                    >
                                      {assesmentResult?.assesseeAssignmentAssessmentScore
                                        ?.assesseeAssignmentAssessmentScoreGrade || '-'}
                                    </div>
                                    <div className={['unitFlex', 'reports-center'].join(' ')}>
                                      grade
                                    </div>
                                  </div>
                                  <div className={['unitFlex', 'unitFlexTop'].join(' ')}>
                                    <div
                                      className={['unitFlex', 'midPaneInformation'].join(' ')}
                                      style={{ alignItems: 'flex-start' }}
                                    >
                                      {assesmentResult?.assesseeAssignmentAssessmentScore?.assesseeAssignmentAssessmentScorePercentage.toFixed(
                                        2
                                      ) || '-'}
                                    </div>
                                    <div className={['unitFlex', 'reports-center'].join(' ')}>
                                      percentage
                                    </div>
                                  </div>
                                  <div className={['unitFlex', 'unitFlexTop'].join(' ')}>
                                    <div
                                      className={['unitFlex', 'midPaneInformation'].join(' ')}
                                      style={{ alignItems: 'flex-start' }}
                                    >
                                      {' '}
                                      {assesmentResult?.assesseeAssignmentAssessmentScore
                                        ?.assesseeAssignmentAssessmentScorePercentile || '-'}
                                    </div>
                                    <div className={['unitFlex', 'reports-center'].join(' ')}>
                                      percentile
                                    </div>
                                  </div>
                                  <div className={['unitFlex', 'unitFlexTop'].join(' ')}>
                                    <div
                                      className={['unitFlex', 'midPaneInformation'].join(' ')}
                                      style={{ alignItems: 'flex-start' }}
                                    >
                                      {assesmentResult?.assesseeAssignmentAssessmentScore
                                        ?.assesseeAssignmentAssessmentScoreRank || '-'}
                                    </div>
                                    <div className={['unitFlex', 'reports-center'].join(' ')}>
                                      rank
                                    </div>
                                  </div>
                                  <div className={['unitFlex', 'unitFlexTop'].join(' ')}>
                                    <div
                                      className={['unitFlex', 'midPaneInformation'].join(' ')}
                                      style={{ alignItems: 'flex-start' }}
                                    >
                                      {assesmentResult?.assesseeAssignmentAssessmentScore
                                        ?.assesseeAssignmentAssessmentScoreRaw || '-'}
                                    </div>
                                    <div className={['unitFlex', 'reports-center'].join(' ')}>
                                      score
                                    </div>
                                  </div>
                                  <div className={['unitFlex', 'unitFlexTop'].join(' ')}>
                                    <div
                                      className={['unitFlex', 'midPaneInformation'].join(' ')}
                                      style={{ alignItems: 'flex-start' }}
                                    >
                                      {assesmentResult?.assesseeAssignmentAssessmentScore
                                        ?.assesseeAssignmentAssessmentScoreSten || '-'}
                                    </div>
                                    <div className={['unitFlex', 'reports-center'].join(' ')}>
                                      sten
                                    </div>
                                  </div>
                                </div>
                              </Fragment>
                            )}
                          </div>
                        </div>
                      )
                    )
                  ) : (
                    <div></div>
                  )}
                </>
              )} */}
            </div>
          </Paper>
        </div>
      </>
      <></>
      {isMobile && (
        <div className={'containerPadding'} style={{ height: '55px' }}>
          {' '}
        </div>
      )}
    </div>
  );
};

export default DisplayPaneThreeSectionOneAssignmentReport;
