// import React from 'react';
// import { isMobile } from 'react-device-detect';
// import { useDispatch, useSelector } from 'react-redux';
// import DisplayPanelAccordianReviewListOne from '../Accordian/DisplayPanelAccordianReviewListOne';
// import DisplayPanelAccordianInformation from '../Accordian/DisplayPanelAccordianInformation';
// import { Paper } from '@material-ui/core';
// import {
//   LOADER_START,
//   LOADER_STOP,
//   SET_DISPLAY_TWO_SINGLE_STATE,
//   SET_MIDDLEPANE_STATE,
//   SET_MOBILE_PANE_STATE,
//   SET_POPUP_VALUE
// } from '../../actionType';

// const DisplayPaneThreeSectionOneGaugeDistinct = () => {
//   const dispatch = useDispatch();
//   const { responseObject, reviewMode } = useSelector((state) => state.DisplayPaneThreeReducer);
//   const { typeOfMiddlePaneList, relatedReviewListDistinctData } = useSelector(
//     (state) => state.DisplayPaneTwoReducer
//   );
//   const { selectedTagValue } = useSelector((state) => state.PopUpReducer);
//   let frameworkList = [];
//   if (
//     responseObject.iGaugeOccupationFrameworkOneGaugeMetric &&
//     responseObject.iGaugeOccupationFrameworkOneGaugeMetric.length > 0
//   ) {
//     const tempArr = responseObject?.iGaugeOccupationFrameworkOneGaugeMetric || [];
//     tempArr.forEach((ob) => {
//       frameworkList.push({
//         id: ob?.id + '-',
//         textOne: ob?.iGaugeOccupationFrameworkOneGaugeMetricLabel || '',
//         textTwo: ob?.iGaugeOccupationFrameworkOneGaugeMetricDescription || '',
//         status: ''
//       });
//     });
//   }
//   let frameworkAll = [];
//   if (
//     typeOfMiddlePaneList === 'assignmentDistinctMetricsDistinct' ||
//     typeOfMiddlePaneList === 'gaugeDistinctMetricDistinctReviewList'
//   ) {
//     frameworkAll = [
//       {
//         id: 'a3',
//         labelTextOneOne: 'metrics',
//         labelTextOneOneBadges: [
//           {
//             labelTextTwoBadge: 'description',
//             innerLabelBadgeList:
//               responseObject.iGaugeOccupationFrameworkOneGaugeMetric
//                 ?.iGaugeOccupationFrameworkOneGaugeMetricDescription || 'No Information'
//           },
//           {
//             labelTextTwoBadge: 'explanation',
//             innerLabelBadgeList:
//               responseObject.iGaugeOccupationFrameworkOneGaugeMetric
//                 ?.iGaugeOccupationFrameworkOneGaugeMetricExplanation || 'No Information'
//           },
//           {
//             labelTextTwoBadge: 'extremum',
//             innerLabelBadgeList: [
//               {
//                 labelTextTwoBadge: 'maximum',
//                 innerLabelBadgeList:
//                   responseObject?.iGaugeOccupationFrameworkOneGaugeMetric
//                     ?.iGaugeOccupationFrameworkOneGaugeMetricExtremumMaximum || 'No Information'
//               },
//               {
//                 labelTextTwoBadge: 'minimum',
//                 innerLabelBadgeList:
//                   responseObject?.iGaugeOccupationFrameworkOneGaugeMetric
//                     ?.iGaugeOccupationFrameworkOneGaugeMetricExtremumMinimum || 'No Information'
//               }
//             ]
//           },
//           {
//             labelTextTwoBadge: 'label',
//             innerLabelBadgeList:
//               responseObject.iGaugeOccupationFrameworkOneGaugeMetric
//                 ?.iGaugeOccupationFrameworkOneGaugeMetricLabel || 'No Information'
//           }
//         ],
//         innerAssociateList: [],
//         innerInfo: 'No Information',
//         isListCard: false,
//         isReviewLink: false,
//         isMultiInfoCard: true
//       }
//     ];
//   } else {
//     frameworkAll = [
//       {
//         id: 'a3',
//         labelTextOneOne: 'metrics',
//         labelTextOneOneBadgeOne: 'distinct',
//         labelTextOneOneBadgeTwo: '',
//         labelTextOneOneBadgeThree: '',
//         labelTextOneOneBadgeFour: '',
//         labelTextOneOneBadges: [
//           {
//             labelTextOneOneBadge: 'distinct',
//             innerList: frameworkList
//           }
//         ],
//         innerInfo: 'No Information',
//         isListCard: true
//       },
//       {
//         id: 'notation',
//         textOneOne: responseObject?.iGaugeOccupationFrameworkOneGaugeNotation || 'No Information',
//         labelTextOneOne: 'notation',
//         innerAssociateList: [],
//         innerInfo: 'No Information',
//         isListCard: false
//       },
//       {
//         id: 'astau2',
//         textOneOne: responseObject?.iGaugeOccupationFrameworkOneGaugeValue || 'No Information',
//         labelTextOneOne: 'value',
//         innerAssociateList: [],
//         innerInfo: 'No Information',
//         isListCard: false
//       }
//     ];
//   }
//   const onClickRevise = (e) => {
//     const labelName = e.currentTarget.getAttribute('data-value');
//     const selectedBadgeName = e.currentTarget.getAttribute('data-key');
//     // console.log('labelName', labelName);
//     // console.log('selectedBadgeName', selectedBadgeName);
//     dispatch({
//       type: SET_DISPLAY_TWO_SINGLE_STATE,
//       payload: { stateName: 'assignmentSelectedGuage', value: selectedTagValue }
//     });
//     if (labelName === 'metrics' && selectedBadgeName === 'description') {
//       dispatch({
//         type: SET_POPUP_VALUE,
//         payload: { isPopUpValue: 'DESCRIPTIONPOPUP', popupMode: 'GUAGEMETRICCREATE' }
//       });
//     }
//     if (labelName === 'metrics' && selectedBadgeName === 'label') {
//       dispatch({
//         type: SET_POPUP_VALUE,
//         payload: { isPopUpValue: 'LABELPOPUP', popupMode: 'GUAGEMETRICCREATE' }
//       });
//     }
//     if (labelName === 'metrics' && selectedBadgeName === 'explanation') {
//       dispatch({
//         type: SET_POPUP_VALUE,
//         payload: { isPopUpValue: 'EXPLANATIONPOPUP', popupMode: 'GUAGEMETRICCREATE' }
//       });
//     }
//     if (labelName === 'metrics' && selectedBadgeName === 'maximum') {
//       dispatch({
//         type: SET_POPUP_VALUE,
//         payload: { isPopUpValue: 'SCOREPOPUPEXTREMUMMAXIMUM', popupMode: 'GUAGEMETRICCREATE' }
//       });
//     }
//     if (labelName === 'metrics' && selectedBadgeName === 'minimum') {
//       dispatch({
//         type: SET_POPUP_VALUE,
//         payload: { isPopUpValue: 'SCOREPOPUPEXTREMMMINIMUM', popupMode: 'GUAGEMETRICCREATE' }
//       });
//     }
//     if (labelName === 'metrics' && selectedBadgeName === 'distinct') {
//       dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
//       dispatch({ type: LOADER_START });
//       let relatedData = relatedReviewListDistinctData[0];
//       let responseObj = relatedData.guages.filter((dd) => {
//         return dd.id === selectedTagValue;
//       });
//       let response = { ...relatedData, metrics: responseObj[0] };
//       dispatch({
//         type: SET_DISPLAY_TWO_SINGLE_STATE,
//         payload: { stateName: 'assessmentSelectedGuage', value: selectedTagValue }
//       });
//       dispatch({
//         type: SET_DISPLAY_TWO_SINGLE_STATE,
//         payload: { stateName: 'relatedReviewListDistinctData', value: [response] }
//       });
//       setTimeout(function () {
//         dispatch({
//           type: SET_MIDDLEPANE_STATE,
//           payload: {
//             middlePaneHeader: 'assignment',
//             middlePaneHeaderBadgeOne: 'metrics',
//             middlePaneHeaderBadgeTwo: 'distinct',
//             middlePaneHeaderBadgeThree: '',
//             middlePaneHeaderBadgeFour: '',
//             typeOfMiddlePaneList: 'assignmentDistinctMetricsDistinct',
//             scanCount: responseObj[0].assignmentGaugeMetric.length,
//             showMiddlePaneState: true
//           }
//         });
//         dispatch({ type: LOADER_STOP });
//       }, 2000);
//     }
//     if(labelName ==='value'){
//       dispatch({
//         type: SET_POPUP_VALUE,
//         payload: { isPopUpValue: 'GAUGE_VALUE_POPUP', popupMode: 'GUAGEMETRICCREATE' }
//       });
//     }
//   };
//   return (
//     <div>
//       <>
//         <div className={'containerPadding'}>
//           <Paper className={'dossierContainerTop'}>
//             {frameworkAll.map((ob) => {
//               return (
//                 <div key={ob.id}>
//                   {ob.isListCard ? (
//                     <DisplayPanelAccordianReviewListOne
//                       className=""
//                       accordianObject={ob}
//                       mode={reviewMode}
//                     />
//                   ) : (
//                     <DisplayPanelAccordianInformation
//                       accordianObject={ob}
//                       mode={reviewMode}
//                       onClickRevise={onClickRevise}
//                     />
//                   )}
//                 </div>
//               );
//             })}
//           </Paper>
//         </div>
//       </>
//       {isMobile && (
//         <div className={'containerPadding'} style={{ height: '55px' }}>
//           {' '}
//         </div>
//       )}
//     </div>
//   );
// };

// export default DisplayPaneThreeSectionOneGaugeDistinct;

// by payal 28/06/22

import React from 'react';
import { isMobile } from 'react-device-detect';
import Manuscript from '@material-ui/icons/Description';
import { useDispatch, useSelector } from 'react-redux';
import DisplayPanelAccordianReviewListOne from '../Accordian/DisplayPanelAccordianReviewListOne';
import DisplayPanelAccordianInformation from '../Accordian/DisplayPanelAccordianInformation';
import { Paper } from '@material-ui/core';
import { SET_POPUP_VALUE, SET_STATUS_POPUP_VALUE } from '../../actionType';
import DisplayPanelAccordianReviewListTwo from '../Accordian/DisplayPanelAccordianReviewListTwo';

//ascendant
let ascendantAll = [];
let ascendantPrimary = [];
let ascendantSecondary = [];
//decendent
let descendantAll = [];
let descendantPrimary = [];
let descendantSecondary = [];

const DisplayPaneThreeSectionOneGaugeDistinct = () => {
  // const [listExpand, setListExpand] = useState('');
  const { responseObject, reviewMode } = useSelector((state) => state.DisplayPaneThreeReducer);
  const dispatch = useDispatch();
  console.log(responseObject);
  function capitalizeFirstLetter(string) {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  const engagementList = [
    {
      id: 'a1',
      labelTextOneOne: 'log',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'all',
          textOne: ''
        },
        {
          labelTextOneOneBadge: 'key',
          textOne: ''
        }
      ],
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false,
      IconOne: Manuscript
    },
    {
      id: 'a2',
      textOneOne:
        capitalizeFirstLetter(responseObject?.iGaugeOccupationFrameworkOneGaugeStatus  ) || 'No Information',
      labelTextOneOne: 'status',
      innerAssociateList: [],
      innerInfo: 'No Information',
      isListCard: false
    },
    {
      id: 'a3',
      labelTextOneOne: 'tag',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'primary',
          textOne:
          responseObject?.iGaugeOccupationFrameworkOneGaugeTag?.igaugeOccupationFrameworkOneGaugeTagPrimary || 'No Information'
        },
        {
          labelTextOneOneBadge: 'secondary',
          textOne:
          responseObject?.iGaugeOccupationFrameworkOneGaugeTag?.igaugeOccupationFrameworkOneGaugeTagSecondary||
            'No Information'
        }
      ],
      innerAssociateList: [],
      innerInfo: 'No Information',
      isListCard: false
    },
    {
      id: 'a4',
      labelTextOneOne: 'tenure',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'start',
          textOne:
          responseObject?.iGaugeOccupationFrameworkOneGaugeTenure?.igaugeOccupationFrameworkOneGaugeTenureDateTimeStart ||
            'No Information'
        },
        {
          labelTextOneOneBadge: 'end',
          textOne:
          responseObject?.iGaugeOccupationFrameworkOneGaugeTenure?.igaugeOccupationFrameworkOneGaugeTenureDateTimeEnd
          ||
            'No Information'
        }
      ],
      innerAssociateList: [],
      innerInfo: 'Assessee',
      isListCard: false
    }
  ];

  const reviseEngagement = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    console.log('=====>', labelName);
    if (labelName === 'status') {
      dispatch({
        type: SET_STATUS_POPUP_VALUE,
        payload: capitalizeFirstLetter(responseObject?.assessmentScoreStatus)
       // responseObject?.assessmentScoreStatus
       
      });
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'STATUSPOPUP', popupMode: 'GUAGEMETRICCREATE' }
      });
    }
    if (labelName === 'tag') {
      if (selectedBadgeName === 'primary') {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'TAGREADONLYPRIMARYPOPUP', popupMode: 'GUAGEMETRICCREATE' }
        });
      }
      if (selectedBadgeName === 'secondary') {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'TAGSECONDARYPOPUP', popupMode: 'GUAGEMETRICCREATE' }
        });
      }
    }
    if (labelName === 'tenure') {
      if (selectedBadgeName === 'start') {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'TENURESATRTDATEPOPUP', popupMode: 'GUAGEMETRICCREATE' }
        });
      }
      if (selectedBadgeName === 'end') {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'TENUREENDDATEPOPUP', popupMode: 'GUAGEMETRICCREATE' }
        });
      }
    }
  };
  return (
    <div>
      <>
        
        <div className="containerPadding">
          <Paper className={'dossierContainerTop'}>
            {engagementList.map((ob) => {
              return (
                <div key={ob.id}>
                  {ob.isListCard ? (
                    <DisplayPanelAccordianReviewListOne
                      onClickRevise={reviseEngagement}
                      className=""
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  ) : (
                    <DisplayPanelAccordianInformation
                      onClickRevise={reviseEngagement}
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  )}
                </div>
              );
            })}
          </Paper>
        </div>

        
      </>
      {isMobile && (
        <div className={'containerPadding'} style={{ height: '55px' }}>
          {' '}
        </div>
      )}
    </div>
  );
};

export default DisplayPaneThreeSectionOneGaugeDistinct;
