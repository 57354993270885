import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FILTERMODE_ENABLE,
  GET_CULTUREPROFILE_REVIEW_LIST_SAGA,
  LOADER_START,
  POPUP_OPEN,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_PAGE_COUNT,
  SET_POPUP_STATE,
  FILTERMODE,
  CULTUREPROFILE_ALLOCATE_ASSIGNMENT_SAGA,
  CULTUREPROFILE_ALLOCATE_SAGA,
  CLEAR_DISPLAY_PANE_THREE
} from '../actionType';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import { FilterList } from '@material-ui/icons';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import { CULTURE_REVIEW_LIST_POPUP_OPTION } from '../PopUpConfig';
import { getCultureProfilesDistinctApiCall } from '../Actions/ActionCultureProfile';
import {
  onClickCheckBoxSelection,
  onClickFlagSelection,
  setFlagedArray
} from '../Actions/AssesseeModuleAction';
import ReviseIcon from '@material-ui/icons/RadioButtonChecked';
import Check from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { getPopUpOptionList } from '../Actions/GenericActions';
import ListLoader from '../Atoms/ListLoader/ListLoader';
const CultureProfileDistinctReviewList = (props) => {
  const dispatch = useDispatch();
  const { secondaryOptionCheckValue, countPage } = useSelector(
    (state) => state.AssesseeCreateReducer
  );
  const {
    numberPage,
    scanCount,
    reviewListDistinctData,
    reviewListReqObj,
    middlePaneSelectedValue,
    selectedAssociateInfo,
    middlePaneHeader,
    middlePaneHeaderBadgeOne,
    middlePaneHeaderBadgeTwo,
    middlePaneHeaderBadgeThree,
    isSelectActive,
    selectedTagsArray,
    unselectedTagsArray,
    allocateStr,
    allocatedTagsArray,
    selectedFlagedArray,
    unselectedFlagedArray,
    flagedValue,
    signedAssesseePermission,
    isListLoading
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { FilterModeEnable, FilterMode } = useSelector((state) => state.FilterReducer);
  const { isPopUpValue, selectedTagValue } = useSelector((state) => state.PopUpReducer);
  // const [isFetching, setIsFetching] = useState(false);
  // useEffect(() => {
  //   document.getElementById('middleComponentId').addEventListener('scroll', handleScroll);
  // }, []);
  // const handleScroll = (event) => {
  //   var targetPt = event.target;
  //   if (
  //     Math.ceil(targetPt.scrollHeight - targetPt.scrollTop) !== targetPt.clientHeight ||
  //     isFetching
  //   )
  //     return;
  //   setIsFetching(true);
  //   console.log(isFetching);
  // };
  const fetchData = async () => {
    if (reviewListDistinctData.length < scanCount) {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isListLoading', value: true }
      })
      // dispatch({ type: LOADER_START });
      let obj = {
        ...reviewListReqObj.requestObject,
        numberPage: numberPage + 1
      };
      dispatch({
        type: GET_CULTUREPROFILE_REVIEW_LIST_SAGA,
        payload: {
          request: {
            module: 'Culture-Profile-Distinct',
            action: 'Review-List',
            requestObject: obj
          },
          middlePaneHeader: middlePaneHeader,
          BadgeOne: middlePaneHeaderBadgeOne,
          BadgeTwo: middlePaneHeaderBadgeTwo,
          isMiddlePaneList: true,
          isSelectActive: isSelectActive,
          selectedTagsArray: selectedTagsArray || [],
          unselectedTagsArray: unselectedTagsArray || []
        }
      });
      dispatch({ type: SET_PAGE_COUNT, payload: numberPage + 1 });
    }
  };
  // useEffect(() => {
  //   if (!isFetching) return;
  //   fetchMoreListItems();
  // }, [isFetching]);

  // const fetchMoreListItems = () => {
  //   fetchData();
  //   setIsFetching(false);
  // };
  useEffect(() => {
    setFlagedArray(reviewListDistinctData, 'cultureProfileFlag', dispatch);
  }, [reviewListDistinctData]);

  const siftApiCall = (siftKey) => {
    getCultureProfilesDistinctApiCall(
      selectedAssociateInfo,
      siftKey,
      countPage,
      'culture-profiles',
      dispatch,
      isSelectActive,
      selectedTagsArray
    );
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'typeOfMiddlePaneList', value: '' }
    });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
    dispatch({ type: FILTERMODE_ENABLE });
    // document.getElementById('middleComponentId').scrollTop = '0px';
  };
  const onClickFooter = (e) => {
    let siftValue = e.currentTarget.getAttribute('data-value');
    if (siftValue === 'suspended' || siftValue === 'terminated' || siftValue === 'unpublished')
      siftApiCall(siftValue);
    dispatch({ type: FILTERMODE_ENABLE });
    if (siftValue === 'finish') {
      console.log('allocateStr', allocateStr);
      //culture-profilesdistinct
      let distinctAllocateStr =
        allocateStr === 'culture-profilesdistinct'
          ? 'cultureProfileDistinct'
          : allocateStr === 'cultureprofilesdistinct'
          ? 'cultureProfileDistinct'
          : allocateStr === 'culture-profilesgroups'
          ? 'cultureProfileGroup'
          : allocateStr === 'culture-profilestypes'
          ? 'cultureProfileType'
          : allocateStr === 'culture-profilesnodes'
          ? 'cultureProfileNode'
          : allocateStr === 'job-profilesdistinct'
          ? 'jobProfileDistinct'
          : allocateStr === 'jobprofilesdistinct'
          ? 'jobProfileDistinct'
          : allocateStr === 'job-profilesgroups'
          ? 'jobProfileGroup'
          : allocateStr === 'assignmentsdistinct'
          ? 'assignmentDistinct'
          : allocateStr === 'assignmentsgroups'
          ? 'assignmentGroup'
          : allocateStr === 'assignmentstypes'
          ? 'assignmentType'
          : allocateStr === 'assignmentsnodes'
          ? 'assignmentNode'
          : '';
      if (distinctAllocateStr !== '' && selectedTagsArray.length !== 0) {
        if (
          distinctAllocateStr === 'assignmentDistinct' ||
          distinctAllocateStr === 'assignmentGroup' ||
          distinctAllocateStr === 'assignmentType' ||
          distinctAllocateStr === 'assignmentNode'
        ) {
          let request = {
            module: 'Culture-Profile-Distinct',
            action: FilterMode.toLowerCase().includes('unallocate') ? 'Unallocate' : 'Allocate',
            actionFilter: 'Culture-Profile-Assignment',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              cultureProfileDistinctAllocate: {
                cultureProfileDistinct: selectedTagsArray
                //[distinctAllocateStr]: selectedTagsArray
              },
              cultureProfileDistinctAllocateInformation: {
                //assignment: selectedTagsArray
                [distinctAllocateStr === 'assignmentDistinct' ? 'assignment' : distinctAllocateStr]:
                  allocatedTagsArray
              }
            }
          };
          dispatch({ type: LOADER_START });
          dispatch({
            type: CULTUREPROFILE_ALLOCATE_ASSIGNMENT_SAGA,
            payload: { request: request }
          });
        }
        if (
          distinctAllocateStr === 'cultureProfileDistinct' ||
          distinctAllocateStr === 'cultureProfileGroup' ||
          distinctAllocateStr === 'cultureProfileNode' ||
          distinctAllocateStr === 'cultureProfileType'
        ) {
          let request = {
            module: 'Culture-Profile-Distinct',
            action: FilterMode.toLowerCase().includes('unallocate') ? 'Unallocate' : 'Allocate',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              cultureProfileDistinctAllocate: {
                [distinctAllocateStr]: allocatedTagsArray
              },
              cultureProfileDistinctAllocateInformation: {
                cultureProfileDistinct: selectedTagsArray
              }
            }
          };
          dispatch({ type: LOADER_START });
          dispatch({ type: CULTUREPROFILE_ALLOCATE_SAGA, payload: { request: request } });
        }
      }
    }
    if (siftValue === 'cancel') {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isSelectActive', value: '' }
      });
      dispatch({
        type: FILTERMODE,
        payload: { FilterMode: '' }
      });
    }
  };
  /* for middle pane */
  const primaryIcon = [{ label: 'sift', onClick: onClickFooter, Icon: FilterList }];
  const secondaryIcon = [
    { label: 'suspended', onClick: onClickFooter, Icon: FilterList },
    { label: 'terminated', onClick: onClickFooter, Icon: FilterList },
    { label: 'unpublished', onClick: onClickFooter, Icon: FilterList }
  ];
  const openListPopup = (e) => {
    console.log(e.currentTarget.getAttribute('tag'));
    let stats = e.currentTarget.getAttribute('status');
    let isShared = e.currentTarget.getAttribute('data-shared');
    let tempArr = [];
    let popupContentArrValue = CULTURE_REVIEW_LIST_POPUP_OPTION;

    let cultureProfileDistictPermssion =
      signedAssesseePermission?.iGuruAnalyticDistinctPermission
        ?.assesseeiGuruAnalyticDistinctPermission;
    let popupContentArr = getPopUpOptionList(popupContentArrValue, cultureProfileDistictPermssion);
    let associateId = e.currentTarget.getAttribute('associateid');
    popupContentArr.map((element) => {
      if (stats === 'PUBLISHED' && element.data === 'revise')
        tempArr.push({ ...element, disabled: true });
      else if (stats === 'UNPUBLISHED' && element.data === 'share')
        tempArr.push({ ...element, disabled: true });
      else if (
        isShared === 'true' &&
        associateId !== signedAssociateId &&
        (element.data === 'revise' ||
          element.data === 'archive' ||
          element.data === 'flag' ||
          element.data === 'delete' ||
          element.data === 'suspend' ||
          element.data === 'select' ||
          element.data === 'publish' ||
          element.data === 'terminate')
      )
        tempArr.push({ ...element, disabled: true });
      else if (
        (stats === 'TERMINATED' || stats === 'SUSPENDED' || stats === 'ARCHIVED') &&
        (element.data === 'share' ||
          element.data === 'revise' ||
          element.data === 'publish' ||
          element.data === 'select' ||
          element.data === 'allocate')
      ) {
        tempArr.push({ ...element, disabled: true });
      } else tempArr.push(element);
    });
    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: 'culture-profile',
        popupHeaderOneBadgeOne: middlePaneHeaderBadgeOne,
        duplicateHeaderOneBadgeTwo: middlePaneHeaderBadgeTwo,
        popupHeaderOneBadgeTwo: '',
        isPopUpValue: '',
        popupOpenType: 'primary',
        popupContentArrValue: tempArr,
        selectedTagValue: e.currentTarget.getAttribute('tag'),
        selectedTagStatus: e.currentTarget.getAttribute('status'),
        selectedTagGroupId: e.currentTarget.getAttribute('data-value'),
        selectedTagShared: e.currentTarget.getAttribute('data-shared'),
        isFlaged: e.currentTarget.getAttribute('data-flag') === 'true' ? true : false,
        selectedTagInformationSetup: JSON.parse(e.currentTarget.getAttribute('informationsetup')),
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: tempArr
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
  };
  // console.log(reviewListDistinctData);
  // console.log(reviewListDistinctData.length, scanCount, '#####CULTUREPROFILE######');
  console.log(isSelectActive);

  // infinite scroll
  const [hasMore, setHasMore] = useState(true);
  let elementRef = useRef(null);

  const onIntersection = (entries) => {
    const firstEntry = entries[0];
    reviewListDistinctData.length >= scanCount ? setHasMore(false) : setHasMore(true)
    if (firstEntry.isIntersecting && hasMore && !isListLoading) {
      fetchData();
    }
  };
  // console.log(reviewListDistinctData.length, scanCount , '##########')
  useEffect(() => {
    // console.log('####MYREF######', elementRef)

    const observer = new IntersectionObserver(onIntersection, { threshold: 0.90 });

    if (observer && elementRef.current) {
      // console.log('here at observer...', elementRef.current)
      observer.observe(elementRef.current);
    }
    // console.log(reviewListDistinctData.length, scanCount ,'##########')
    return () => {
       
      if (observer) observer.disconnect();
    };
  }, [isListLoading, hasMore, reviewListDistinctData, selectedTagsArray, unselectedTagsArray, isSelectActive]);

  // infinite scroll end

  const signedAssociateId =
    selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary;
  return (
    <div>
      {reviewListDistinctData &&
        reviewListDistinctData.map((item, index) => {
          return (
            <div
              className="containerPadding"
              key={index}
              ref={elementRef}
            >
              <ReviewList
                className=""
                id={index}
                tag={item.id}
                associateId={item.associateId}
                isSelectedReviewList={middlePaneSelectedValue === item.id}
                status={item.informationEngagement.cultureProfileStatus}
                actualStatus={item.informationEngagement.cultureProfileStatus}
                textOne={item.informationBasic.cultureProfileName}
                textTwo={item.informationBasic.cultureProfileDescription}
                informationSetup={item?.informationSetup}
                isTooltipActive={false}
                onClickEvent={openListPopup}
                isSelectActive={isSelectActive}
                isSelected={selectedTagsArray.includes(item.id)}
                onClickCheckBox={(event) => {
                  !isListLoading && onClickCheckBoxSelection(selectedTagsArray, unselectedTagsArray, event, dispatch);
                }}
                // disableonClickCheckBox={item.associateId !== signedAssociateId ? true : false}
                isShared={item?.cultureProfileShared}
                isFlagActive={selectedFlagedArray.includes(item.id)}
                flagedValue={flagedValue}
                onClickAddFladed={(event) => {
                  onClickFlagSelection(selectedFlagedArray, unselectedFlagedArray, event, dispatch);
                }}

                // dataValue={item.informationAllocation.cultureProfileGroup}
              />
            </div>
          );
        })}
      {isListLoading ? <div> <ListLoader /> </div> : <div style={{ height: "60px", width: "100%" }}></div>}
      {(FilterMode === 'cultureProfileDistinctinactive' ||
        FilterMode === 'cultureProfileDistinctsuspended' ||
        FilterMode === 'cultureProfileDistinctunpublished' ||
        FilterMode === 'cultureProfileDistinctterminated') && (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={primaryIcon}
          secondaryIcon={secondaryIcon}
        />
      )}
      {(FilterMode === 'allocateToCultureProfile' || FilterMode === 'unAllocateToCultureProfile') && (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={[{ label: `${FilterMode === 'allocateToCultureProfile' ? 'allocate' : 'unallocate' }`, onClick: onClickFooter, Icon: ReviseIcon }]}
          secondaryIcon={[
            { label: 'cancel', onClick: onClickFooter, Icon: ClearIcon },
            { label: 'finish', onClick: onClickFooter, Icon: Check }
          ]}
        />
      )}
    </div>
  );
};
export default CultureProfileDistinctReviewList;
