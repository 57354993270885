import React, { Fragment, useEffect, useRef, useState } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import Popup from '../Molecules/PopUp/PopUp';
import PopupHeader from '../Molecules/PopUp/PopUpHeader';
import FormHelperText from '@material-ui/core/FormHelperText';
import '../Molecules/PopUp/PopUp.css';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { ASSESSEE_SIGN_ON, LOADER_START, LOADER_STOP, POPUP_CLOSE, QRDATA_VERIFICATION_SAGA, QRURL_VERIFICATION_SAGA, SET_DISPLAY_TWO_SINGLE_STATE, SET_NEXT_POPUP, SET_POPUP_VALUE } from '../actionType';
import { isMobile } from 'react-device-detect';
import FlipCameraIosIcon from '@material-ui/icons/FlipCameraIos';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { scanFile } from "@openhealthnz-credentials/pdf-image-qr-scanner";

import '../index.css'
import { QrReader } from 'react-qr-reader'
import { FormControl, IconButton, Input, InputLabel, TextField, Typography } from '@material-ui/core';
import { QR_INVALID } from '../errorMessage';
const PopUpQRScanner = (props) => {
  const dispatch = useDispatch();

  const {
    isActive = false,
    headerPanelColour = 'genericOne',
    headerOne = 'assessee',
    inputHeader = 'picture',
    inputHeaderBadgeOne = '',
    inputHeaderBadgeTwo = '',
    headerOneBadgeOne = '',
    headerOneBadgeTwo = '',
    nextPopUpValue,
    handleNextPopupValue,
    mode,
    basicInfo = '',
    actualLableValue = '',
    typeOfSetObject,
    actualLableValueVerification,
    isNotRevised = false,

  } = props;

  const {
    selectedAssociateInfo,
    QRApisCallSuccessStatus
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { responseObject, } =
    useSelector((state) => state.DisplayPaneThreeReducer);

  const QRViewFinder = () => {
    return (
      <div className='QRViewFinder'>

      </div>
    )
  }
  const [qrData, setQRData] = useState('');
  const [inputData, setInputData] = useState('');
  const [btnState, setBtnState] = useState(false);

  const supportedFiles = ["application/pdf", "image/png", "image/jpeg"];
  const supportedFileEnds = supportedFiles.map((file) => file.split("/")[1]).join(", ");
  const [fileName, setFileName] = useState("");
  const [resultText, setResultText] = useState("");
  const [onFileSelectError, setOnFileSelectError] = useState({})


  async function processFile(selectedFile) {
    setResultText("");
    try {
      const qrCode = await scanFile(selectedFile);
      // It returns null if no QR code is found

      if (qrCode) handleApiCall(qrCode)
      else setResultText(QR_INVALID);
    } catch (e) {
      // Example Error Handling
      if (e?.name === "InvalidPDFException") {
        setResultText("invalid pdf");
      } else if (e instanceof Event) {
        setResultText("invalid image");
      } else {
        console.log(e);
        setResultText("unknown error");
      }
    }
  }

  const handleFileInput = (e) => {
    // Makes sure it's the correct file type.
    const file = e.target.files[0];
    if (supportedFiles.includes(file.type)) {
      setFileName(file.name);
      processFile(file);
    } else {
      setOnFileSelectError({ error: "file must be a pdf/image" });
    }
  };

  const handleApiCall = (result) => {
    dispatch({ type: LOADER_START })
    dispatch({ type: POPUP_CLOSE })

    let qrResp = JSONparser(result);

    if (qrResp.error) {
      dispatch({ type: LOADER_STOP });
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: qrResp.message, popupMode: 'responseErrorMsg' }
      });
      return;
    }
    dispatch({
      type: QRDATA_VERIFICATION_SAGA,
      payload: {
        "module": "Assessee-Assignment",
        "action": "QR-Code-Validate",
        "requestObject": {
          "associateId": selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
          "assesseeId": selectedAssociateInfo?.assesseeId,
          "assignmentId": responseObject.id,
          "qrCodeResponse": qrResp.result
        }
      }
    })
  }

  const handleInputurlApiCall = () => {

    if (inputData === '') {
      return;
    }
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'QRApisCallSuccessStatus', value: true }
    })

    dispatch({
      type: QRURL_VERIFICATION_SAGA,
      payload: {
        "module": "Assessee-Assignment",
        "action": "URL-Validate",
        "requestObject": {
          "assesseeId": selectedAssociateInfo?.assesseeId,
          "associateId": selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
          "assignmentId": responseObject.id,
          "certificateUrl": inputData
        }
      }
    })
  }
  const JSONparser = (result) => {
    try {
      let parsedResult = JSON.parse(result);
      return {
        error: false,
        result: parsedResult,
        message: ""
      }
    } catch (err) {
      return {
        error: true,
        result: null,
        message: QR_INVALID
      }
    }
  }

  const onResult = (result, error) => {
    if (!!result) {
      handleApiCall(result?.text)
      setQRData(result?.text);
    }

    if (!!error) {
      // console.info(error);
    }
  }

  const handleClick = async () => {
    dispatch({ type: POPUP_CLOSE });
  };


  return (
    <div>
      <Popup isActive={isActive}>
        <PopupHeader
          headerPanelColour={headerPanelColour}
          headerOne={headerOne}
          headerOneBadgeOne={headerOneBadgeOne}
          headerOneBadgeTwo={headerOneBadgeTwo}
          onClick={handleClick}
          mode={'search'}
        />
        {
          <DialogContent
            className={[ 'fixed10PadDim', 'revisePopupContent', 'qrscanner'].join(' ')}
          >
            <div style={{height: '5px'}}></div>
            <div className={['fitContent', 'qrcontainer'].join(' ')}>
              <div className='scan'></div>
              {/* {camMode === 'user' ? ( */}
              <QrReader
                onResult={onResult}
                ViewFinder={QRViewFinder}
                constraints={{ facingMode: mode }}
                style={{ height: '210px', width: '100%', display: 'flex', justifyContent: 'center', border: '2px solid white' }}
                legacyMode={true}
              />
            </div>

            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around'
            }}>

              {
                <div style={{ height: '2rem', width: '100%' }}>
                  {isMobile && (
                    <IconButton onClick={() => {
                      dispatch({ type: POPUP_CLOSE })
                      dispatch({
                        type: ASSESSEE_SIGN_ON,
                        payload: { isPopUpValue: mode === 'environment' ? 'PopUpQRScanner_user' : 'PopUpQRScanner_environment', popupMode: 'ASSESSEE_CREATE', popupHeaderOne: headerOne }
                      });
                    }}>
                      <FlipCameraIosIcon style={{ fontSize: '2rem' }} />
                    </IconButton>
                  )}
                </div>
              }
            </div>

            {/* <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            width: '95%',
            minHeight: '22px',
            margin: '1rem 0 0 1rem',
          }}>
            <input style={{ textOverflow: 'ellipsis', border: '1px solid rgba(0,0,0,0.55)', borderRadius:'2px', flexGrow:1 }} type='url' onChange={(e) => setInputData(e.target.value)} value={inputData}></input>
            <button style={{ border: '1px solid #808080', margin: '0 0.5rem 0 0.5rem', borderRadius:'2px', }} type='submit' onClick={handleInputurlApiCall} disabled={btnState}>submit</button>
          </div> */}

            {/* { 
              <IconButton
                variant="contained"
                component="label"
                className='upload-file-btn'
              >
                <Typography style={{ fontSize: '10px', fontWeight: 500 }} color='inherit' >{'Upload from files'}</Typography>
                <AttachFileIcon />
                <input
                  style={{ display: "none" }}
                  type="file"
                  hidden
                  onChange={handleFileInput}
                  accept={supportedFiles.join(",")}
                />
              </IconButton>

            } */}

          </DialogContent>}
      </Popup>
    </div>
  );
};
PopUpQRScanner.propTypes = {
  className: PropTypes.string,
  headerPanelColour: PropTypes.oneOf([
    'displayPaneLeft',
    'displayPaneCentre',
    'displayPaneRight',
    'genericOne',
    'genericTwo'
  ]),
  headerOne: PropTypes.string,
  headerOneBadgeOne: PropTypes.string,
  headerOneBadgeTwo: PropTypes.string,
  headerOneBadgeThree: PropTypes.string,
  isActive: PropTypes.bool,
  mode: PropTypes.string
};
export default PopUpQRScanner;
