import React, { useState, Fragment, useEffect } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import Popup from '../Molecules/PopUp/PopUp';
import PopupHeader from '../Molecules/PopUp/PopUpHeader';
import Checkbox from '@material-ui/core/Checkbox';
import '../Molecules/PopUp/PopUp.css';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  POPUP_CLOSE,
  SET_NEXT_POPUP,
  UPDATE_ASSESSEE_SETUP_PRIMARY_INFO,
  UPDATE_ASSESSEE_COMMUNICATION
} from '../actionType';
import { InputLabel } from '@material-ui/core';
import InfoToolTip from '../Atoms/InfoToolTip/InfoToolTip';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import { createNameWithBadge } from '../Actions/StatusAction';
import SelectField from '../Atoms/SelectField/SelectField';
import { Replay } from '@material-ui/icons';
import FormHelperText from '@material-ui/core/FormHelperText';
import { CAN_NOT_REVISED_MESSAGE, REQUIRED_ERROR_MESSAGE } from '../errorMessage';
const PopUpCheckbox = (props) => {
  /*props*/
  const {
    isActive,
    headerPanelColour = 'genericOne',
    headerOne = 'assessees',
    headerOneBadgeOne = 'information',
    headerOneBadgeTwo = '',
    valueArr = [],
    EmailPrimaryCommunication,
    EmailSecondaCommunication,
    typeOfPrimarySetObject,
    typeOfSecondaSetObject,
    nextPopUpValue,
    forceToSelect = '',
    mode,
    inputHeader = '',
    inputHeaderBadge = '',
    inputHeaderBadgeTwo = '',
    inputHeaderBadgeThree = '',
    infoMsg = '',
    isJobProfileList = false,
    removeString = false,
    textOne = '',
    textTwo = null,
    onClickNext = null,
    id,
    isChecked = '',
    availableSignInCredentialList = [],
    isRolePermission = false,
    isObjectUpdate = false,
    forceToSelectSign = false,
    valueArrState = [],
    informationArr = [],
    informationValue = '',
    typeOfSetObject,
    typeOfStateObj = '',
    objectName = '',
    stateName = '',
    stateArr = [],
    checkedValue = '',
    isNotRevised,
    errorMsg = isNotRevised ? CAN_NOT_REVISED_MESSAGE : ''
  } = props;
  const dispatch = useDispatch();
  const [localObject, setLocalObject] = useState(valueArrState);
  const [permissionInfo, setpermissionInfo] = useState('');
  const [communation, setCommunicationErr] = useState('');
  useEffect(() => {
    setLocalObject(valueArrState);
    informationValue !== '' && setpermissionInfo(valueArrState[informationValue]);
    checkedValue && setState({ isChecked: checkedValue });
  }, [!valueArrState]);
  /*handling the onchange event*/
  const handleChange = (event) => {
    console.log(event.target.checked);
    setCommunicationErr('');
    setState({ isChecked: event.target.value });
  };
  /*handling the onchange event*/
  const handleSingleState = (event) => {
    console.log(event.target.value);
    if (
      event.target.value === 'assessmentSectionItemNavigationFirst' ||
      event.target.value === 'assessmentSectionItemNavigationPrevious'
    ) {
      localObject.assessmentSectionItemNavigationFirst = event.target.checked;
      localObject.assessmentSectionItemNavigationPrevious = event.target.checked;
    }
    if (
      event.target.value === 'assessmentSectionItemNavigationLast' ||
      event.target.value === 'assessmentSectionItemNavigationSkip'
    ) {
      localObject.assessmentSectionItemNavigationLast = event.target.checked;
      localObject.assessmentSectionItemNavigationSkip = event.target.checked;
    }
    if (
      event.target.value === 'assessmentSectionItemNavigationLast' ||
      event.target.value === 'assessmentSectionItemNavigationSkip'
    ) {
      localObject.assessmentSectionItemNavigationLast = event.target.checked;
      localObject.assessmentSectionItemNavigationSkip = event.target.checked;
    }
    console.log(localObject);
    setLocalObject({ ...localObject, [event.target.value]: event.target.checked });
    console.log(localObject);
    // setState({ isChecked: event.target.value });
  };

  const [state, setState] = useState({
    isChecked: isChecked
  });
  const handleDropDown = (e) => {
    setpermissionInfo(e.target.value);
  };
  const handleClick = (e) => {
    console.log(e.checked);
    if (forceToSelect === 'signIn') {
      dispatch({
        type: UPDATE_ASSESSEE_SETUP_PRIMARY_INFO,
        payload: { assesseeSignInCredential: state.isChecked }
      });
      dispatch({ type: POPUP_CLOSE });
    } else if (forceToSelect === 'assessmentRevise') {
      dispatch({
        type: typeOfSetObject,
        payload: { ...localObject }
      });
      dispatch({ type: POPUP_CLOSE });
    } else if (forceToSelectSign) {
      if (state.isChecked === 'email address (primary)') {
        dispatch({
          type: UPDATE_ASSESSEE_COMMUNICATION,
          payload: state.isChecked
        });
        dispatch({
          type: typeOfPrimarySetObject,
          payload: {
            ...EmailPrimaryCommunication,
            assesseeAddressEmailCommunication: true
          }
        });
        dispatch({ type: SET_NEXT_POPUP, payload: { isPopUpValue: nextPopUpValue } });
        if (mode === 'revise') {
          dispatch({ type: POPUP_CLOSE });
        }
        setCommunicationErr('')
      }
      else if (state.isChecked === 'email address (secondary)') {
        dispatch({
          type: UPDATE_ASSESSEE_COMMUNICATION,
          payload: state.isChecked
        });
        dispatch({
          type: typeOfSecondaSetObject,
          payload: {
            ...EmailSecondaCommunication,
            assesseeAddressEmailCommunication: true
          }
        });
        dispatch({ type: SET_NEXT_POPUP, payload: { isPopUpValue: nextPopUpValue } });
        setCommunicationErr('')
        if (mode === 'revise') {
          dispatch({ type: POPUP_CLOSE });
        }
      }
      else{
        setCommunicationErr(REQUIRED_ERROR_MESSAGE)
        return
      }
    } else if (isRolePermission) {
      let reviseobj = null;
      if (informationValue !== '' && headerOne === 'assessees') {
        reviseobj = { ...localObject, [informationValue]: permissionInfo };
      } else {
        reviseobj = { ...localObject };
      }
      dispatch({
        type: typeOfSetObject,
        payload: {
          objectName: objectName,
          stateName: stateName,
          actualStateName: typeOfStateObj,
          value: reviseobj
        }
      });
    } else if (isObjectUpdate) {
      console.log('updateObj');
      dispatch({
        type: typeOfSetObject,
        payload: {
          stateName: stateName,
          value: localObject
        }
      });
    } else {
      onClickNext(id, state.isChecked);
      dispatch({ type: SET_NEXT_POPUP, payload: { isPopUpValue: nextPopUpValue } });
    }
    dispatch({ type: SET_NEXT_POPUP, payload: { isPopUpValue: nextPopUpValue } });
  setLocalObject(JSON.parse(JSON.stringify(valueArrState)));
};
// console.log('localObject', localObject);
// const valueArr = ['email address (primary)', 'email address (secondary)'];
// console.log('stateArr[index]', stateArr);
return (
  <div>
    <Popup isActive={isActive}>
      <PopupHeader
        headerPanelColour={headerPanelColour}
        headerOne={headerOne}
        headerOneBadgeOne={headerOneBadgeOne}
        headerOneBadgeTwo={headerOneBadgeTwo}
        onClick={handleClick}
        mode={mode}
      />
      <DialogContent
        className={['popupContent', 'fixed10PadDim', 'revisePopupContent'].join(' ')}
      >
        <div className={'fitContent'}>
          <div className={['PopupFormBox', 'labelPopupBox', 'popupMinHei'].join(' ')}>
            <InputLabel
              htmlFor="name-input"
              className={['titleBox', 'textForLabelPopup'].join(' ')}
            //className={' textForLabelPopup'}
            >
              <Fragment>
                {inputHeader}&nbsp;
                {inputHeaderBadge ? (
                  <span className={'headerBadge'}>{inputHeaderBadge}</span>
                ) : null}
                &nbsp;
                {inputHeaderBadgeTwo ? (
                  <span className={'headerBadge'}>{inputHeaderBadgeTwo}</span>
                ) : null}
                &nbsp;
                {inputHeaderBadgeThree ? (
                  <span className={'headerBadge'}>{inputHeaderBadgeThree}</span>
                ) : null}
              </Fragment>
            </InputLabel>
            <div className={'infoSymbol'}></div>
            <div className={'infoSymbol'}>
              <InfoToolTip message={infoMsg} />
            </div>
          </div>
        </div>
        {isJobProfileList && (
          <div className={'containerPadding'}>
            <div
              // disableFocusRipple={true}
              // disableRipple={true}
              className={['cardButton', 'heightInherit', 'reviewListFixedWidth'].join(' ')}
              style={{ borderBottom: '0px' }}
            >
              <div className={['measureBox', 'heightInherit', 'componentInnerDiv'].join(' ')}>
                <div
                  className={['iguru-cardContentMidPanel', 'heightInherit'].join(' ')}
                  style={{ textTransform: 'none' }}
                >
                  <div
                    className={[
                      'midPaneInformation',
                      textTwo == null || textTwo === '' ? 'aliasmiddle' : null
                    ].join(' ')}
                    title={textOne}
                  >
                    <span>{textOne}</span>
                  </div>
                  {textTwo != null ? <div className={'midPaneLabel'}>{textTwo}</div> : null}
                </div>
              </div>
            </div>
          </div>
        )}
        {isRolePermission && headerOne === 'assessees' && (
          <SelectField
            tag={'information'}
            label={'information'}
            dataValue={'information'}
            listSelect={informationArr}
            errorMsg={() => { }}
            onChange={handleDropDown}
            value={permissionInfo}
            mappingValue={'id'}
          />
        )}
        {valueArr.map((item, index) => (
          <div className={'fitContent'} key={`check${index}`}>
            <div className={['PopupFormBox', 'popupMinHei0'].join(' ')} style={{ minHeight: 0 }}>
              <div className={'contFlex'}>
                <div className={'f4'}>{createNameWithBadge(item, stateName)}</div>
                <div className={'checkedFontNew'}>
                  <Checkbox
                    color="default"
                    value={(stateArr && stateArr[index]) || item}
                    //value={item}
                    key={index}
                    name={item}
                    disabled={
                      isRolePermission && item === 'assessmentSectionItemNavigationNext'
                        ? true
                        : isNotRevised
                          ? true
                          : isJobProfileList ||
                            isRolePermission ||
                            // forceToSelectSign ||
                            isObjectUpdate
                            ? false
                            : !availableSignInCredentialList.includes(item)
                    }
                    checked={
                      (isRolePermission && localObject[item]) ||
                      (isObjectUpdate && localObject[stateArr[index]]) ||
                      state.isChecked === item
                    }
                    onChange={
                      isRolePermission || isObjectUpdate
                        ? (e) => {
                          handleSingleState(e);
                        }
                        : isJobProfileList
                          ? (e) => {
                            setState({ isChecked: e.target.value });
                            onClickNext(id, e.target.value);
                          }
                          : (e) => {
                            handleChange(e);
                          }
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
        <FormHelperText
          className={['helperText', 'helptextmargin'].join(' ')}
          style={{ margin: '5px 0px 0px 5px' }}
        >
          <span>{errorMsg || communation}</span>
        </FormHelperText>
      </DialogContent>
    </Popup>
  </div>
);
};
PopUpCheckbox.propTypes = {
  className: PropTypes.string,
  headerPanelColour: PropTypes.oneOf([
    'displayPaneLeft',
    'displayPaneCentre',
    'displayPaneRight',
    'genericOne',
    'genericTwo'
  ]),
  headerOne: PropTypes.string,
  headerOneBadgeOne: PropTypes.string,
  headerOneBadgeTwo: PropTypes.string,
  headerOneBadgeThree: PropTypes.string,
  isActive: PropTypes.bool
};
export default PopUpCheckbox;