import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import AllocationAccordian from '../Accordian/AllocationAccordian';
import Manuscript from '@material-ui/icons/Description';
import { useDispatch, useSelector } from 'react-redux';
import DisplayPanelAccordianReviewListOne from '../Accordian/DisplayPanelAccordianReviewListOne';
import DisplayPanelAccordianInformation from '../Accordian/DisplayPanelAccordianInformation';
import { Paper } from '@material-ui/core';
import FlipCameraAndroidIcon from '@material-ui/icons/FlipCameraAndroid';
import BlurOnIcon from '@material-ui/icons/BlurOn';
import Icon from '@material-ui/core/Icon';

import '../../../src/index.css';
import {
  ASSOCIATE_SIGN_ON,
  GET_ASSOCIATE_ROLE_REVIEW_LIST_SAGA,
  LOADER_START,
  SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT,
  SET_CORE_ROLE_REVIEW_LIST_REQ_OBJECT,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_STATUS_POPUP_VALUE,
  UPDATE_ASSOCIATE_SETUP_ANALYTIC_INFO,
  UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO,
  UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO,
  UPDATE_ASSOCIATE_SETUP_ASSIGNMENT_INFO,
  UPDATE_ASSOCIATE_SETUP_ASSOCIATENODE_INFO,
  UPDATE_ASSOCIATE_SETUP_INFO,
  UPDATE_ASSOCIATE_SETUP_ITEM_INFO,
  UPDATE_ASSOCIATE_COUNTRY_INFO,
  UPDATE_ASSOCIATE_CURRENCY_INFO,
  UPDATE_ASSOCIATE_LANGUAGE_INFO,
  UPDATE_ASSOCIATE_SETUPPLUS_ASSESSEE_INFO,
  UPDATE_SETUPSETUPPLUSFLAG,
  UPDATE_ASSOCIATE_SETUPPLUS_ASSESSMENT_INFO,
  UPDATE_ASSOCIATE_SETUPPLUS_ASSIGNMENT_INFO,
  UPDATE_ASSOCIATE_SETUPPLUS_ITEM_INFO,
  UPDATE_ASSOCIATE_SETUPPLUS_ANALYTIC_INFO,
  UPDATE_ASSOCIATE_SETUPPLUS_INFO,
  SET_SELECTED_BADGEVALUE,
  SET_CORE_TYPE_REVIEW_LIST_REQ_OBJECT,
  GET_ASSOCIATE_TYPE_REVIEW_LIST_SAGA,
  SET_CORE_GROUP_REVIEW_LIST_REQ_OBJECT,
  GET_ASSOCIATE_GROUP_REVIEW_LIST_SAGA,
  INTERNAL_NODE_LIST_WITH_PERMISSIONS_SAGA
} from '../../actionType';
import {
  getPermissionStr,
  makeAssociateGroupObj,
  makeAssociateRoleObj,
  makeAssociateTypeObj,
  makeInternalNodeObj
} from '../../Actions/GenericActions';
const CountryFlagIcon = (props) => {
  const { setUpAssociateModule } = useSelector((state) => state.DisplayPaneThreeReducer);
  return (
    <React.Fragment>
      <img
        src={
          './Image/country_flags/' +
          setUpAssociateModule?.associateCountry?.associateCountryFlag.toLowerCase() +
          '.png'
        }
        alt={'flag'}
        style={{ height: '16px', width: '16px', marginTop: '0px' }}
      />
    </React.Fragment>
  );
};
const CurrencySymbolIcon = (props) => {
  const { setUpAssociateModule } = useSelector((state) => state.DisplayPaneThreeReducer);
  return (
    <React.Fragment>
      <Icon>{setUpAssociateModule?.associateCurrency?.associateCurrencySymbol}</Icon>
    </React.Fragment>
  );
};

const DisplayPaneThreeSectionOneAssociate = () => {
  const [listExpand, setListExpand] = useState('');
  const [languageData, setLanguageData] = useState('No Information');
  const {
    responseObject,
    headerOneBadgeThree,
    reviewMode,
    administratorSecondary,
    setUpAssociateModule,
    associateNodeSetUpModule,
    analyticSetUpModule,
    itemSetUpModule,
    assessmentSetUpModule,
    assesseeSetUpModule,
    assignmentSetUpModule,
    assessmentSetUpModuleGeneric,
    assessmentSetUpModuleBespoke,
    assesseeSetUpModuleGeneric, // pane
    assignmentSetUpModuleGeneric,
    itemSetUpModuleGeneric,
    analyticSetUpModuleGeneric,
    setUpAssociateModuleGeneric
  } = useSelector((state) => state.DisplayPaneThreeReducer);
  const {
    middlePaneHeader = '',
    associateLanguages,
    selectedAssociateInfo,
    signedAssesseePermission,
    assignmentCreateType
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const associateInfo = useSelector((state) => state.AssociateCreateReducer);
  const { informationEngagement, informationAllocation, informationAlliance } = responseObject;

  function capitalizeFirstLetter(string) {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  function informationBasicStr(string) {
    // console.log('string----',string);
    if (string) {
      return string === 'NAME_UNIQUE'
        ? 'Unique Name Required'
        : string === 'NAME_DESCRIPTION_UNIQUE'
        ? 'Unique Name + Description Required'
        : 'Unique Name & Description Unrequired';
    } else return 'No Information';
  }
  useEffect(() => {
    setListExpand('');
    const language =
      setUpAssociateModule?.associateLanguage?.associateLanguageTag &&
      associateLanguages.filter(
        (x) => x.id === setUpAssociateModule?.associateLanguage?.associateLanguageTag
      )[0].name;
    setLanguageData(language);
  }, [administratorSecondary, setUpAssociateModule, responseObject]);

  const dispatch = useDispatch();
  let administratorPrimaryList = [];
  if (informationAlliance?.associateAdministratorPrimary) {
    const ob = informationAlliance?.associateAdministratorPrimary || {};
    const administratorName = `${
      ob?.informationBasic.assesseeNamePrefix
    } ${ob?.informationBasic.assesseeNameFirst.trim()} ${ob?.informationBasic.assesseeNameOther.trim()} ${ob?.informationBasic.assesseeNameLast.trim()} ${ob?.informationBasic.assesseeNameSuffix.trim()}`.trim();
    administratorPrimaryList.push({
      id: ob?.id + '-',
      textOne: administratorName || '',
      textTwo: ob?.informationBasic?.assesseeAlias || '',
      status: ob?.informationEngagement?.assesseeStatus || ''
    });
  }
  let administratorSecondaryList = [];
  if (administratorSecondary && administratorSecondary?.length > 0) {
    administratorSecondary.map((ob) => {
      const administratorSecondaryName = `${
        ob?.informationBasic?.assesseeNamePrefix
      } ${ob?.informationBasic?.assesseeNameFirst?.trim()} ${ob?.informationBasic?.assesseeNameOther?.trim()} ${ob?.informationBasic?.assesseeNameLast?.trim()} ${ob?.informationBasic?.assesseeNameSuffix?.trim()}`?.trim();
      administratorSecondaryList?.push({
        id: ob?.id + '-',
        textOne: administratorSecondaryName || '',
        textTwo: ob?.informationBasic?.assesseeAlias || '',
        status: ob?.informationEngagement?.assesseeStatus || ''
      });
    });
  }

  const allianceListAll = [
    {
      id: 'a2',
      labelTextOneOne: 'administrator',
      labelTextOneOneBadgeOne: 'primary',
      labelTextOneOneBadgeTwo: 'secondary',
      labelTextOneOneBadgeThree: '',
      labelTextOneOneBadgeFour: '',
      labelTextOneOneBadges:
        middlePaneHeader === ''
          ? [
              {
                labelTextOneOneBadge: 'primary',
                innerList: administratorPrimaryList
              },
              {
                labelTextOneOneBadge: 'secondary',
                innerList: administratorSecondaryList
              }
            ]
          : [
              {
                labelTextOneOneBadge: 'primary',
                innerList: administratorPrimaryList
              }
            ],
      innerInfo: 'No Information',
      isListCard: true
    }
  ];
  let associateRoleListPrimary = [];
  if (
    informationAllocation?.associateRole.associateRolePrimary &&
    informationAllocation?.associateRole.associateRolePrimary?.length > 0
  ) {
    const tempArr = informationAllocation?.associateRole?.associateRolePrimary;
    tempArr.forEach((ob) => {
      associateRoleListPrimary.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.associateRoleName || '',
        textTwo: ob?.informationBasic?.associateRoleDescription || '',
        status: ob?.informationEngagement?.associateRoleStatus
      });
    });
  }
  let associateRoleListSecondary = [];
  if (
    informationAllocation?.associateRole.associateRoleSecondary &&
    informationAllocation?.associateRole.associateRoleSecondary?.length > 0
  ) {
    const tempArr = informationAllocation?.associateRole?.associateRoleSecondary;
    tempArr.forEach((ob) => {
      associateRoleListSecondary.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.associateRoleName || '',
        textTwo: ob?.informationBasic?.associateRoleDescription || '',
        status: ob?.informationEngagement?.associateRoleStatus || ''
      });
    });
  }
  let associateGroupListPrimary = [];
  if (
    informationAllocation?.associateGroup?.associateGroupPrimary &&
    informationAllocation?.associateGroup?.associateGroupPrimary?.length > 0
  ) {
    const tempArr = informationAllocation?.associateGroup?.associateGroupPrimary;
    tempArr.forEach((ob) => {
      if (ob) {
        associateGroupListPrimary.push({
          id: ob?.id + '-',
          textOne: ob?.informationBasic?.associateGroupName || '',
          textTwo: ob?.informationBasic?.associateGroupDescription || '',
          status: ob?.informationEngagement?.associateGroupStatus || ''
        });
      }
    });
  }
  let associateGroupListSecondary = [];
  if (
    informationAllocation?.associateGroup?.associateGroupSecondary &&
    informationAllocation?.associateGroup?.associateGroupSecondary?.length > 0
  ) {
    const tempArr = informationAllocation?.associateGroup?.associateGroupSecondary;
    tempArr.forEach((ob) => {
      associateGroupListSecondary.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.associateGroupName || '',
        textTwo: ob?.informationBasic?.associateGroupDescription || '',
        status: ob?.informationEngagement?.associateGroupStatus || ''
      });
    });
  }
  let associateNodeListPrimary = [];
  if (
    informationAllocation?.associateNode?.associateNodePrimary &&
    informationAllocation?.associateNode?.associateNodePrimary?.length > 0
  ) {
    const tempArr = informationAllocation?.associateNode?.associateNodePrimary;
    tempArr.forEach((ob) => {
      associateNodeListPrimary.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.associateNodeName || '',
        textTwo: ob?.informationBasic?.associateNodeDescription || '',
        status: ob?.informationEngagement?.associateNodeStatus || ''
      });
    });
  }

  let associateNodeListSecondary = [];
  if (
    informationAllocation?.associateNode?.associateNodeSecondary &&
    informationAllocation?.associateNode?.associateNodeSecondary?.length > 0
  ) {
    const tempArr = informationAllocation?.associateNode?.associateNodeSecondary;
    tempArr.forEach((ob) => {
      associateNodeListSecondary.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.associateNodeName || '',
        textTwo: ob?.informationBasic?.associateNodeDescription || '',
        status: ob?.informationEngagement?.associateNodeStatus || ''
      });
    });
  }

  let associateTypeListPrimary = [];
  if (
    informationAllocation?.associateType?.associateTypePrimary &&
    informationAllocation?.associateType?.associateTypePrimary?.length > 0
  ) {
    const tempArr = informationAllocation?.associateType?.associateTypePrimary;
    tempArr.forEach((ob) => {
      associateTypeListPrimary.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.associateTypeName || '',
        textTwo: ob?.informationBasic?.associateTypeDescription || '',
        status: ob?.informationEngagement?.associateTypeStatus || ''
      });
    });
  }

  let associateTypeListSecondary = [];
  if (
    informationAllocation?.associateType?.associateTypeSecondary &&
    informationAllocation?.associateType?.associateTypeSecondary?.length > 0
  ) {
    const tempArr = informationAllocation?.associateType?.associateTypeSecondary;
    tempArr.forEach((ob) => {
      associateTypeListSecondary.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.associateTypeName || '',
        textTwo: ob?.informationBasic?.associateTypeDescription || '',
        status: ob?.informationEngagement?.associateTypeStatus || ''
      });
    });
  }

  const allocationList = [
    {
      id: 'a1',
      labelTextOneOne: 'group',
      labelTextOneOneBadgeOne: 'primary',
      labelTextOneOneBadgeTwo: 'secondary',
      labelTextOneOneBadgeThree: '',
      labelTextOneOneBadgeFour: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'primary',
          innerList: associateGroupListPrimary.sort((a, b) =>
            a.textOne.toLowerCase().localeCompare(b.textOne.toLowerCase())
          )
        },
        {
          labelTextOneOneBadge: 'secondary',
          innerList: associateGroupListSecondary.sort((a, b) =>
            a.textOne.toLowerCase().localeCompare(b.textOne.toLowerCase())
          )
        }
      ],
      innerInfo: 'No Information',
      isListCard: true
    },
    {
      id: 'a3',
      labelTextOneOne: 'node',
      labelTextOneOneBadgeOne: 'primary',
      labelTextOneOneBadgeTwo: 'secondary',
      labelTextOneOneBadgeThree: '',
      labelTextOneOneBadgeFour: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'primary',
          innerList: associateNodeListPrimary.sort((a, b) =>
            a.textOne.toLowerCase().localeCompare(b.textOne.toLowerCase())
          )
        },
        {
          labelTextOneOneBadge: 'secondary',
          innerList: associateNodeListSecondary.sort((a, b) =>
            a.textOne.toLowerCase().localeCompare(b.textOne.toLowerCase())
          )
        }
      ],
      innerInfo: 'No Information',
      isListCard: true
    },
    {
      id: 'a4',
      labelTextOneOne: 'role',
      labelTextOneOneBadgeOne: 'primary',
      labelTextOneOneBadgeTwo: 'secondary',
      labelTextOneOneBadgeThree: '',
      labelTextOneOneBadgeFour: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'primary',
          innerList: associateRoleListPrimary.sort((a, b) =>
            a.textOne.toLowerCase().localeCompare(b.textOne.toLowerCase())
          )
        },
        {
          labelTextOneOneBadge: 'secondary',
          innerList: associateRoleListSecondary.sort((a, b) =>
            a.textOne.toLowerCase().localeCompare(b.textOne.toLowerCase())
          )
        }
      ],
      innerInfo: 'No Information',
      isListCard: true
    },
    {
      id: 'typ2',
      labelTextOneOne: 'type',
      labelTextOneOneBadgeOne: 'primary',
      labelTextOneOneBadgeTwo: 'secondary',
      labelTextOneOneBadgeThree: '',
      labelTextOneOneBadgeFour: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'primary',
          innerList: associateTypeListPrimary.sort((a, b) =>
            a.textOne.toLowerCase().localeCompare(b.textOne.toLowerCase())
          )
        },
        {
          labelTextOneOneBadge: 'secondary',
          innerList: associateTypeListSecondary.sort((a, b) =>
            a.textOne.toLowerCase().localeCompare(b.textOne.toLowerCase())
          )
        }
      ],
      innerInfo: 'No Information',
      isListCard: true
    }
  ];
  const engagementListAll = [
    {
      id: 'a1',
      labelTextOneOne: 'log',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'all',
          textOne: ''
        },
        {
          labelTextOneOneBadge: 'key',
          textOne: ''
        }
      ],
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false,
      IconOne: Manuscript
    },
    {
      id: 'a2',
      textOneOne: capitalizeFirstLetter(informationEngagement.associateStatus) || 'No Information',
      labelTextOneOne: 'status',
      innerAssociateList: [],
      innerInfo: 'No Information',
      isListCard: false
    },
    {
      id: 'a3',
      labelTextOneOne: 'tag',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'primary',
          textOne: informationEngagement?.associateTag?.associateTagPrimary || 'No Information'
        },
        {
          labelTextOneOneBadge: 'secondary',
          textOne: informationEngagement?.associateTag?.associateTagSecondary || 'No Information'
        }
      ],
      innerAssociateList: [],
      innerInfo: 'No Information',
      isListCard: false
    },
    {
      id: 'a4',
      labelTextOneOne: 'tenure',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'start',
          textOne:
            informationEngagement.associateTenureDate?.associateTenureDateTimeStart ||
            'No Information'
        },
        {
          labelTextOneOneBadge: 'end',
          textOne:
            informationEngagement.associateTenureDate?.associateTenureDateTimeEnd ||
            'No Information'
        }
      ],
      innerAssociateList: [],
      innerInfo: 'Assessee',
      isListCard: false
    }
  ];
  const setUpListAll = [
    {
      id: 'a1',
      labelTextOneOne: 'assessees',
      labelTextOneOneBadges: [
        {
          labelTextTwoBadge: 'distinct',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'create',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: assesseeSetUpModule?.assesseeDistinctCreateApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  innerLabelBadgeList:
                    (assesseeSetUpModule?.assesseeDistinctCreateFee &&
                      setUpAssociateModule?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(assesseeSetUpModule?.assesseeDistinctCreateFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assesseeSetUpModule?.assesseeDistinctCreatePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'information',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'basic',
                  innerLabelBadgeList:
                    assesseeSetUpModule?.assesseeDistinctInformationBasic === 'NAME_UNIQUE'
                      ? 'Unique Name Required'
                      : assesseeSetUpModule?.assesseeDistinctInformationBasic ===
                        'NAME_DESCRIPTION_UNIQUE'
                      ? 'Unique Name + Alias Required'
                      : 'Unique Name & Alias Unrequired'
                },
                {
                  //'NAME_DESCRIPTION_UNIQUE'
                  labelTextTwoBadge: 'name',
                  innerLabelBadgeList:
                    assesseeSetUpModule?.assesseeDistinctNameFormat === 'NAME_DESCRIPTION_UNIQUE'
                      ? 'Last-Name First-Name Other-Name'
                      : 'First-Name Other-Name Last-Name' || 'No'
                }
              ]
            },
            {
              labelTextTwoBadge: 'sell',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: assesseeSetUpModule?.assesseeDistinctSellApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  //innerLabelBadgeList: assesseeSetUpModule?.assesseeDistinctSellFee && (setUpAssociateModule?.associateCurrency?.associateCurrencySymbol+' '+ assesseeSetUpModule?.assesseeDistinctSellFee) || setUpAssociateModule?.associateCurrency?.associateCurrencySymbol +' 0.00'
                  innerLabelBadgeList:
                    (assesseeSetUpModule?.assesseeDistinctSellFee &&
                      setUpAssociateModule?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(assesseeSetUpModule?.assesseeDistinctSellFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assesseeSetUpModule?.assesseeDistinctSellPermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'share',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: assesseeSetUpModule?.assesseeDistinctShareApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  //innerLabelBadgeList: assesseeSetUpModule?.assesseeDistinctShareFee && (setUpAssociateModule?.associateCurrency?.associateCurrencySymbol+' '+ assesseeSetUpModule?.assesseeDistinctShareFee) || setUpAssociateModule?.associateCurrency?.associateCurrencySymbol +' 0.00'
                  innerLabelBadgeList:
                    (assesseeSetUpModule?.assesseeDistinctShareFee &&
                      setUpAssociateModule?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(assesseeSetUpModule?.assesseeDistinctShareFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assesseeSetUpModule?.assesseeDistinctSharePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'sign-on',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: assesseeSetUpModule?.assesseeDistinctSignOnApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  //innerLabelBadgeList: assesseeSetUpModule?.assesseeDistinctSignOnFee && (setUpAssociateModule?.associateCurrency?.associateCurrencySymbol+ ' ' + assesseeSetUpModule?.assesseeDistinctSignOnFee) || setUpAssociateModule?.associateCurrency?.associateCurrencySymbol+' 0.00'
                  innerLabelBadgeList:
                    (assesseeSetUpModule?.assesseeDistinctSignOnFee &&
                      setUpAssociateModule?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(assesseeSetUpModule?.assesseeDistinctSignOnFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assesseeSetUpModule?.assesseeDistinctSignOnPermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            }
          ]
        },
        {
          labelTextTwoBadge: 'groups',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'create',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assesseeSetUpModule?.assesseeGroupCreatePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'information',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'basic',
                  innerLabelBadgeList: informationBasicStr(
                    assesseeSetUpModule?.assesseeGroupInformationBasic
                  )
                }
              ]
            },
            {
              labelTextTwoBadge: 'sell',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: assesseeSetUpModule?.assesseeGroupSellApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  //innerLabelBadgeList: assesseeSetUpModule?.assesseeGroupSellFee && (setUpAssociateModule?.associateCurrency?.associateCurrencySymbol+' '+assesseeSetUpModule?.assesseeGroupSellFee) || setUpAssociateModule?.associateCurrency?.associateCurrencySymbol+' 0.00'
                  innerLabelBadgeList:
                    (assesseeSetUpModule?.assesseeGroupSellFee &&
                      setUpAssociateModule?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(assesseeSetUpModule?.assesseeGroupSellFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assesseeSetUpModule?.assesseeGroupSellPermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'share',
              //innerLabelBadgeList: assesseeSetUpModule?.assesseeGroupShare ? 'Yes' : 'No'
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: assesseeSetUpModule?.assesseeGroupShareApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  //innerLabelBadgeList: assesseeSetUpModule?.assesseeGroupShareFee && (setUpAssociateModule?.associateCurrency?.associateCurrencySymbol+' '+assesseeSetUpModule?.assesseeGroupShareFee)  || setUpAssociateModule?.associateCurrency?.associateCurrencySymbol+' 0.00'
                  innerLabelBadgeList:
                    (assesseeSetUpModule?.assesseeGroupShareFee &&
                      setUpAssociateModule?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(assesseeSetUpModule?.assesseeGroupShareFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assesseeSetUpModule?.assesseeGroupSharePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            }
          ]
        },
        {
          labelTextTwoBadge: 'roles',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'create',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assesseeSetUpModule?.assesseeRoleCreatePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'information',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'basic',
                  innerLabelBadgeList: informationBasicStr(
                    assesseeSetUpModule?.assesseeRoleInformationBasic
                  )
                }
              ]
            },
            {
              labelTextTwoBadge: 'share',
              innerLabelBadgeList: assesseeSetUpModule?.assesseeRoleSharePermission
                ? 'Permitted'
                : 'Unpermitted'
            }
          ]
        },
        {
          labelTextTwoBadge: 'types',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'create',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assesseeSetUpModule?.assesseeTypeCreatePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'information',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'basic',
                  innerLabelBadgeList: informationBasicStr(
                    assesseeSetUpModule?.assesseeTypeInformationBasic
                  )
                }
              ]
            },
            {
              labelTextTwoBadge: 'share',
              innerLabelBadgeList: assesseeSetUpModule?.assesseeTypeSharePermission
                ? 'Permitted'
                : 'Unpermitted'
            }
          ]
        },
        {
          labelTextTwoBadge: '+',
          innerLabelBadgeList: 'No'
        }
      ],
      innerInfo: 'No',
      isListCard: false,
      isReviewLink: false,
      isMultiInfoCard: true,
      //IconOne: SyncIcon,
      isResetIcon: true,
      //IconReset: assesseeSetUpModule?.assesseeAssociateSetupReset && FlipCameraAndroidIcon,
      IconReset: FlipCameraAndroidIcon,
      IconResetEnable: assesseeSetUpModule?.assesseeAssociateSetupReset
    },
    {
      id: 'a-112',
      labelTextOneOne: 'assessments',
      labelTextOneOneBadges: [
        {
          labelTextTwoBadge: 'distinct',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'create',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList:
                    assessmentSetUpModuleBespoke?.assessmentDistinctCreateApproval
                      ? 'Required'
                      : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  //innerLabelBadgeList: assessmentSetUpModuleBespoke?.assessmentDistinctCreateFee && (setUpAssociateModule?.associateCurrency?.associateCurrencySymbol + " " + assessmentSetUpModuleBespoke?.assessmentDistinctCreateFee) || setUpAssociateModule?.associateCurrency?.associateCurrencySymbol+' 0.00'
                  innerLabelBadgeList:
                    (assessmentSetUpModuleBespoke?.assessmentDistinctCreateFee &&
                      setUpAssociateModule?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(assessmentSetUpModuleBespoke?.assessmentDistinctCreateFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList:
                  assessmentSetUpModule?.assessmentDistinctCreatePermission
                      ? 'Permitted'
                      : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'information',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'basic',
                  innerLabelBadgeList: informationBasicStr(
                    assessmentSetUpModuleBespoke?.assessmentDistinctInformationBasic
                  )
                }
              ]
            },
            {
              labelTextTwoBadge: 'sell',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: assessmentSetUpModuleBespoke?.assessmentDistinctSellApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  innerLabelBadgeList:
                    (assessmentSetUpModuleBespoke?.assessmentDistinctSellFee &&
                      setUpAssociateModule?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(assessmentSetUpModuleBespoke?.assessmentDistinctSellFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList:
                    assessmentSetUpModuleBespoke?.assessmentDistinctSellPermission
                      ? 'Permitted'
                      : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'share',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: assessmentSetUpModuleBespoke?.assessmentDistinctShareApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  innerLabelBadgeList:
                    (assessmentSetUpModuleBespoke?.assessmentDistinctShareFee &&
                      setUpAssociateModule?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(assessmentSetUpModuleBespoke?.assessmentDistinctShareFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList:
                    assessmentSetUpModuleBespoke?.assessmentDistinctSharePermission
                      ? 'Permitted'
                      : 'Unpermitted'
                }
              ]
            }
          ]
        },
        {
          labelTextTwoBadge: 'groups',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'create',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assessmentSetUpModuleBespoke?.assessmentGroupCreatePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'information',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'basic',
                  innerLabelBadgeList: informationBasicStr(
                    assessmentSetUpModuleBespoke?.assessmentGroupInformationBasic
                  )
                }
              ]
            },
            {
              labelTextTwoBadge: 'sell',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: assessmentSetUpModuleBespoke?.assessmentGroupSellApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  innerLabelBadgeList:
                    (assessmentSetUpModuleBespoke?.assessmentGroupSellFee &&
                      setUpAssociateModule?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(assessmentSetUpModuleBespoke?.assessmentGroupSellFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assessmentSetUpModuleBespoke?.assessmentGroupSellPermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'share',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: assessmentSetUpModuleBespoke?.assessmentGroupShareApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  innerLabelBadgeList:
                    (assessmentSetUpModuleBespoke?.assessmentGroupShareFee &&
                      setUpAssociateModule?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(assessmentSetUpModuleBespoke?.assessmentGroupShareFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assessmentSetUpModuleBespoke?.assessmentGroupSharePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            }
          ]
        },
        {
          labelTextTwoBadge: 'types',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'create',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assessmentSetUpModuleBespoke?.assessmentTypeCreatePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'information',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'basic',
                  innerLabelBadgeList: informationBasicStr(
                    assessmentSetUpModuleBespoke?.assessmentTypeInformationBasic
                  )
                }
              ]
            },
            {
              labelTextTwoBadge: 'share',
              innerLabelBadgeList: assessmentSetUpModuleBespoke?.assessmentTypeSharePermission
                ? 'Permitted'
                : 'Unpermitted'
            }
          ]
        },
        {
          labelTextTwoBadge: '+',
          innerLabelBadgeList: 'No'
        }
      ],
      innerInfo: 'No',
      isListCard: false,
      isReviewLink: false,
      isMultiInfoCard: true,
      //IconOne: SyncIcon,
      isResetIcon: true,
      //IconReset: assessmentSetUpModuleBespoke?.assessmentAssociateSetupReset && FlipCameraAndroidIcon,
      IconReset: FlipCameraAndroidIcon,
      IconResetEnable: assessmentSetUpModule?.assessmentAssociateSetupReset
    },
    {
      id: 'assignment-232',
      labelTextOneOne: 'assignments',
      labelTextOneOneBadges: [
        {
          labelTextTwoBadge: 'distinct',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'create',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: assignmentSetUpModule?.assignmentDistinctCreateApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  innerLabelBadgeList:
                    (assignmentSetUpModule?.assignmentDistinctCreateFee &&
                      setUpAssociateModule?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(assignmentSetUpModule?.assignmentDistinctCreateFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assignmentSetUpModule?.assignmentDistinctCreatePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'information',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'basic',
                  innerLabelBadgeList: informationBasicStr(
                    assignmentSetUpModule?.assignmentDistinctInformationBasic
                  )
                }
              ]
            },
            {
              labelTextTwoBadge: 'sell',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: assignmentSetUpModule?.assignmentDistinctSellApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  innerLabelBadgeList:
                    (assignmentSetUpModule?.assignmentDistinctSellFee &&
                      setUpAssociateModule?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(assignmentSetUpModule?.assignmentDistinctSellFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assignmentSetUpModule?.assignmentDistinctSellPermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'share',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: assignmentSetUpModule?.assignmentDistinctShareApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  innerLabelBadgeList:
                    (assignmentSetUpModule?.assignmentDistinctShareFee &&
                      setUpAssociateModule?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(assignmentSetUpModule?.assignmentDistinctShareFee).toFixed()) ||
                    setUpAssociateModule?.associateCurrency?.associateCurrencySymbol + ' 0.00'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assignmentSetUpModule?.assignmentDistinctSharePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            }
          ]
        },
        {
          labelTextTwoBadge: 'groups',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'create',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assignmentSetUpModule?.assignmentGroupCreatePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'information',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'basic',
                  innerLabelBadgeList: informationBasicStr(
                    assignmentSetUpModule?.assignmentGroupInformationBasic
                  )
                }
              ]
            },
            {
              labelTextTwoBadge: 'sell',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: assignmentSetUpModule?.assignmentGroupSellApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  //innerLabelBadgeList: assignmentSetUpModule?.assignmentGroupSellFee && (setUpAssociateModule?.associateCurrency?.associateCurrencySymbol+' '+assignmentSetUpModule?.assignmentGroupSellFee) || setUpAssociateModule?.associateCurrency?.associateCurrencySymbol+' 0.00'
                  innerLabelBadgeList:
                    (assignmentSetUpModule?.assignmentGroupSellFee &&
                      setUpAssociateModule?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(assignmentSetUpModule?.assignmentGroupSellFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assignmentSetUpModule?.assignmentGroupSellPermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'share',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: assignmentSetUpModule?.assignmentGroupShareApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  //innerLabelBadgeList: assignmentSetUpModule?.assignmentGroupShareFee&&(setUpAssociateModule?.associateCurrency?.associateCurrencySymbol+ ' ' +assignmentSetUpModule?.assignmentGroupShareFee)  || setUpAssociateModule?.associateCurrency?.associateCurrencySymbol+' 0.00'
                  innerLabelBadgeList:
                    (assignmentSetUpModule?.assignmentGroupShareFee &&
                      setUpAssociateModule?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(assignmentSetUpModule?.assignmentGroupShareFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assignmentSetUpModule?.assignmentGroupSharePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            }
          ]
        },
        {
          labelTextTwoBadge: 'types',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'create',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assignmentSetUpModule?.assignmentTypeCreatePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'information',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'basic',
                  innerLabelBadgeList: informationBasicStr(
                    assignmentSetUpModule?.assignmentTypeInformationBasic
                  )
                }
              ]
            },
            {
              labelTextTwoBadge: 'share',
              innerLabelBadgeList: assignmentSetUpModule?.assignmentTypeSharePermission
                ? 'Permitted'
                : 'Unpermitted'
            }
          ]
        },
        {
          labelTextTwoBadge: '+',
          innerLabelBadgeList: 'No'
        }
      ],
      innerInfo: 'No',
      isListCard: false,
      isReviewLink: false,
      isMultiInfoCard: true,
      //IconOne: SyncIcon,
      isResetIcon: true,
      //IconReset: assignmentSetUpModule?.assignmentAssociateSetupReset && FlipCameraAndroidIcon,
      IconReset: FlipCameraAndroidIcon,
      IconResetEnable: assignmentSetUpModule?.assignmentAssociateSetupReset
    },
    {
      id: 'associate-213',
      labelTextOneOne: 'associates', //
      labelTextOneOneBadges: [
        {
          labelTextTwoBadge: 'distinct',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'create',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: setUpAssociateModule?.associateDistinctCreateApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  //innerLabelBadgeList: setUpAssociateModule?.associateDistinctCreateFee&&(setUpAssociateModule?.associateCurrency?.associateCurrencySymbol + ' '+ setUpAssociateModule?.associateDistinctCreateFee) || setUpAssociateModule?.associateCurrency?.associateCurrencySymbol+' 0.00'
                  innerLabelBadgeList:
                    (setUpAssociateModule?.associateDistinctCreateFee &&
                      setUpAssociateModule?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(setUpAssociateModule?.associateDistinctCreateFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: setUpAssociateModule?.associateDistinctCreatePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'information',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'basic',
                  innerLabelBadgeList: informationBasicStr(
                    setUpAssociateModule?.associateDistinctInformationBasic
                  )
                }
              ]
            },
            {
              labelTextTwoBadge: 'sell',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: setUpAssociateModule?.associateDistinctSellApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  //innerLabelBadgeList: setUpAssociateModule?.associateDistinctSellFee && (setUpAssociateModule?.associateCurrency?.associateCurrencySymbol+' '+setUpAssociateModule?.associateDistinctSellFee) || setUpAssociateModule?.associateCurrency?.associateCurrencySymbol+' 0.00'
                  innerLabelBadgeList:
                    (setUpAssociateModule?.associateDistinctSellFee &&
                      setUpAssociateModule?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(setUpAssociateModule?.associateDistinctSellFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: setUpAssociateModule?.associateDistinctSellPermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'share',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: setUpAssociateModule?.associateDistinctShareApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  //innerLabelBadgeList: setUpAssociateModule?.associateDistinctShareFee && (setUpAssociateModule?.associateCurrency?.associateCurrencySymbol+' ' +setUpAssociateModule?.associateDistinctShareFee) || setUpAssociateModule?.associateCurrency?.associateCurrencySymbol+' 0.00'
                  innerLabelBadgeList:
                    (setUpAssociateModule?.associateDistinctShareFee &&
                      setUpAssociateModule?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(setUpAssociateModule?.associateDistinctShareFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: setUpAssociateModule?.associateDistinctSharePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'sign-on',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: setUpAssociateModule?.associateDistinctSignOnApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  //innerLabelBadgeList:  setUpAssociateModule?.associateDistinctSignOnFee&&(setUpAssociateModule?.associateCurrency?.associateCurrencySymbol + ' ' + setUpAssociateModule?.associateDistinctSignOnFee) || setUpAssociateModule?.associateCurrency?.associateCurrencySymbol+' 0.00'
                  innerLabelBadgeList:
                    (setUpAssociateModule?.associateDistinctSignOnFee &&
                      setUpAssociateModule?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(setUpAssociateModule?.associateDistinctSignOnFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: setUpAssociateModule?.associateDistinctSignOnPermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            }
          ]
        },
        {
          labelTextTwoBadge: 'groups',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'create',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: setUpAssociateModule?.associateGroupCreatePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'information',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'basic',
                  innerLabelBadgeList: informationBasicStr(
                    setUpAssociateModule?.associateGroupInformationBasic
                  )
                }
              ]
            },
            {
              labelTextTwoBadge: 'sell',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: setUpAssociateModule?.associateGroupSellApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  //innerLabelBadgeList: setUpAssociateModule?.associateGroupSellFee && (setUpAssociateModule?.associateCurrency?.associateCurrencySymbol + ' ' + setUpAssociateModule?.associateGroupSellFee) || setUpAssociateModule?.associateCurrency?.associateCurrencySymbol+' 0.00'
                  innerLabelBadgeList:
                    (setUpAssociateModule?.associateGroupSellFee &&
                      setUpAssociateModule?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(setUpAssociateModule?.associateGroupSellFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: setUpAssociateModule?.associateGroupSellPermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'share',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: setUpAssociateModule?.associateGroupShareApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  //innerLabelBadgeList: setUpAssociateModule?.associateGroupShareFee&&(setUpAssociateModule?.associateCurrency?.associateCurrencySymbol+' '+setUpAssociateModule?.associateGroupShareFee) || setUpAssociateModule?.associateCurrency?.associateCurrencySymbol+' 0.00'
                  innerLabelBadgeList:
                    (setUpAssociateModule?.associateGroupShareFee &&
                      setUpAssociateModule?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(setUpAssociateModule?.associateGroupShareFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: setUpAssociateModule?.associateGroupSharePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            }
          ]
        },
        {
          labelTextTwoBadge: 'nodes',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'create',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: setUpAssociateModule?.associateNodeCreatePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'information',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'basic',
                  innerLabelBadgeList: informationBasicStr(
                    setUpAssociateModule?.associateNodeInformationBasic
                  )
                }
              ]
            },
            {
              labelTextTwoBadge: 'permission',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'ascendant',
                  innerLabelBadgeList: [
                    {
                      labelTextTwoBadge: 'all',
                      innerLabelBadgeList:
                        getPermissionStr(
                          associateNodeSetUpModule?.informationSetup?.associateNodeAscendantAll
                        ) || 'No Information'
                    },
                    {
                      labelTextTwoBadge: 'primary',
                      innerLabelBadgeList:
                        getPermissionStr(
                          associateNodeSetUpModule?.informationSetup?.associateNodeAscendantPrimary
                        ) || 'No Information'
                    },
                    {
                      labelTextTwoBadge: 'secondary',
                      innerLabelBadgeList:
                        getPermissionStr(
                          associateNodeSetUpModule?.informationSetup
                            ?.associateNodeAscendantSecondary
                        ) || 'No Information'
                    }
                  ]
                },
                {
                  labelTextTwoBadge: 'descendant',
                  innerLabelBadgeList: [
                    {
                      labelTextTwoBadge: 'all',
                      innerLabelBadgeList:
                        getPermissionStr(
                          associateNodeSetUpModule?.informationSetup?.associateNodeDescendantAll
                        ) || 'No Information'
                    },
                    {
                      labelTextTwoBadge: 'primary',
                      innerLabelBadgeList:
                        getPermissionStr(
                          associateNodeSetUpModule?.informationSetup?.associateNodeDescendantPrimary
                        ) || 'No Information'
                    },
                    {
                      labelTextTwoBadge: 'secondary',
                      innerLabelBadgeList:
                        getPermissionStr(
                          associateNodeSetUpModule?.informationSetup
                            ?.associateNodeDescendantSecondary
                        ) || 'No Information'
                    }
                  ]
                }
                // {
                //   labelTextTwoBadge: 'peer',
                //   innerLabelBadgeList: [
                //     {
                //       labelTextTwoBadge: 'all',
                //       innerLabelBadgeList: getPermissionStr(
                //         associateNodeSetUpModule?.informationSetup?.associateNodePeerAll
                //       )
                //     }
                //   ]
                // },
                // {
                //   labelTextTwoBadge: 'root',
                //   innerLabelBadgeList: getPermissionStr(
                //     associateNodeSetUpModule?.informationSetup?.associateNodeRoot
                //   )
                // },
                // {
                //   labelTextTwoBadge: 'self',
                //   innerLabelBadgeList: getPermissionStr(
                //     associateNodeSetUpModule?.informationSetup?.associateNodeSelf
                //   )
                // }
              ]
            },
            {
              labelTextTwoBadge: 'share',
              innerLabelBadgeList: setUpAssociateModule?.associateNodeSharePermission
                ? 'Permitted'
                : 'Unpermitted'
            }
          ]
        },
        {
          labelTextTwoBadge: 'roles',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'create',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: setUpAssociateModule?.associateRoleCreatePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'information',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'basic',
                  innerLabelBadgeList: informationBasicStr(
                    setUpAssociateModule?.associateRoleInformationBasic
                  )
                }
              ]
            },
            {
              labelTextTwoBadge: 'share',
              innerLabelBadgeList: setUpAssociateModule?.associateRoleSharePermission
                ? 'Permitted'
                : 'Unpermitted'
            }
          ]
        },
        {
          labelTextTwoBadge: 'types',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'create',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: setUpAssociateModule?.associateTypeCreatePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'information',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'basic',
                  innerLabelBadgeList: informationBasicStr(
                    setUpAssociateModule?.associateTypeInformationBasic
                  )
                }
              ]
            },
            {
              labelTextTwoBadge: 'share',
              innerLabelBadgeList: setUpAssociateModule?.associateTypeSharePermission
                ? 'Permitted'
                : 'Unpermitted'
            }
          ]
        },
        {
          labelTextTwoBadge: '+',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'brand',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: setUpAssociateModule?.iGuruPlatformBrandApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'choice',
                  innerLabelBadgeList:
                    setUpAssociateModule?.iGuruPlatformBrandChoice || 'No Information',
                  IconOne: BlurOnIcon
                },
                {
                  labelTextTwoBadge: 'fee',
                  //innerLabelBadgeList:setUpAssociateModule?.iGuruPlatformBrandFee&& (setUpAssociateModule?.associateCurrency?.associateCurrencySymbol+ ' ' +setUpAssociateModule?.iGuruPlatformBrandFee) || setUpAssociateModule?.associateCurrency?.associateCurrencySymbol+' 0.00'
                  innerLabelBadgeList:
                    (setUpAssociateModule?.iGuruPlatformBrandFee &&
                      setUpAssociateModule?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(setUpAssociateModule?.iGuruPlatformBrandFee).toFixed()) || //
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: setUpAssociateModule?.iGuruPlatformBrandPermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
                // {
                //   labelTextTwoBadge: 'picture',
                //   innerLabelBadgeList:
                //     setUpAssociateModule?.iGuruPlatformBrandPicture || 'No Information',
                //   IconOne: BlurOnIcon
                // }
              ]
            },
            {
              labelTextTwoBadge: 'country',
              innerLabelBadgeList:
                setUpAssociateModule?.associateCountry?.associateCountryName || 'No Information',
              IconOne: setUpAssociateModule?.associateCountry?.associateCountryFlag
                ? CountryFlagIcon
                : BlurOnIcon
            },
            {
              labelTextTwoBadge: 'currency',
              //innerLabelBadgeList: currencyData || 'No Information',
              innerLabelBadgeList:
                setUpAssociateModule?.associateCurrency?.associateCurrencyName || 'No Information',
              IconOne: setUpAssociateModule?.associateCurrency?.associateCurrencySymbol
                ? CurrencySymbolIcon
                : BlurOnIcon
            },
            {
              labelTextTwoBadge: 'date',
              innerLabelBadgeList: setUpAssociateModule?.associateDateFormat || 'No Information'
            },
            {
              labelTextTwoBadge: 'domain',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'primary',
                  innerLabelBadgeList:
                    setUpAssociateModule?.associateDomainPrimary || 'No Information'
                },
                {
                  labelTextTwoBadge: 'secondary',
                  innerLabelBadgeList:
                    setUpAssociateModule?.associateDomainSecondary || 'No Information'
                }
              ]
            },
            {
              labelTextTwoBadge: 'information',
              innerLabelBadgeList: setUpAssociateModule?.associateInformation || 'No Information'
            },
            {
              labelTextTwoBadge: 'language',
              innerLabelBadgeList: languageData || 'No Information'
            },
            {
              labelTextTwoBadge: 'service',
              innerLabelBadgeList: setUpAssociateModule?.associateService || 'No Information'
            },
            {
              labelTextTwoBadge: 'rating',
              innerLabelBadgeList: setUpAssociateModule?.associateRating || 'No Information'
            },
            {
              labelTextTwoBadge: 'theme',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: setUpAssociateModule?.iGuruPlatformThemeApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'choice',
                  innerLabelBadgeList:
                    setUpAssociateModule?.iGuruPlatformThemeChoice || 'No Information'
                },
                {
                  labelTextTwoBadge: 'fee',
                  //innerLabelBadgeList: setUpAssociateModule?.associateCurrency?.associateCurrencySymbol + ' ' + setUpAssociateModule?.iGuruPlatformThemeFee || '0'
                  innerLabelBadgeList:
                    (setUpAssociateModule?.iGuruPlatformThemeFee &&
                      setUpAssociateModule?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(setUpAssociateModule?.iGuruPlatformThemeFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: setUpAssociateModule?.iGuruPlatformThemePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'time',
              innerLabelBadgeList: setUpAssociateModule?.associateTimeFormat || 'No Information'
            }
          ]
        }
      ],
      innerInfo: 'No',
      isListCard: false,
      isReviewLink: false,
      isMultiInfoCard: true,
      isResetIcon: true,
      //IconReset: setUpAssociateModule?.associateAssociateSetupReset && FlipCameraAndroidIcon,
      IconReset: FlipCameraAndroidIcon,
      IconResetEnable: setUpAssociateModule?.associateAssociateSetupReset
    },
    {
      id: 'iguru-11',
      labelTextOneOne: 'iGuru analytics',
      labelTextOneOneBadges: [
        {
          labelTextTwoBadge: 'distinct',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'create',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: analyticSetUpModule?.iGuruAnalyticDistinctCreateApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  //innerLabelBadgeList: analyticSetUpModule?.iGuruAnalyticDistinctCreateFee && (setUpAssociateModule?.associateCurrency?.associateCurrencySymbol+' '+analyticSetUpModule?.iGuruAnalyticDistinctCreateFee) || setUpAssociateModule?.associateCurrency?.associateCurrencySymbol+' 0.00'
                  innerLabelBadgeList:
                    (analyticSetUpModule?.iGuruAnalyticDistinctCreateFee &&
                      setUpAssociateModule?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(analyticSetUpModule?.iGuruAnalyticDistinctCreateFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: analyticSetUpModule?.iGuruAnalyticDistinctCreatePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'information',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'basic',
                  innerLabelBadgeList: informationBasicStr(
                    analyticSetUpModule?.iGuruAnalyticTypeInformationBasic
                  )
                }
              ]
            },
            {
              labelTextTwoBadge: 'sell',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: analyticSetUpModule?.iGuruAnalyticDistinctSellApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  //innerLabelBadgeList: analyticSetUpModule?.iGuruAnalyticDistinctSellFee && (setUpAssociateModule?.associateCurrency?.associateCurrencySymbol+' '+analyticSetUpModule?.iGuruAnalyticDistinctSellFee) || setUpAssociateModule?.associateCurrency?.associateCurrencySymbol+' 0.00'
                  innerLabelBadgeList:
                    (analyticSetUpModule?.iGuruAnalyticDistinctSellFee &&
                      setUpAssociateModule?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(analyticSetUpModule?.iGuruAnalyticDistinctSellFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: analyticSetUpModule?.iGuruAnalyticDistinctSellPermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'share',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: analyticSetUpModule?.iGuruAnalyticDistinctShareApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  //innerLabelBadgeList: analyticSetUpModule?.iGuruAnalyticDistinctShareFee&&(setUpAssociateModule?.associateCurrency?.associateCurrencySymbol+' '+analyticSetUpModule?.iGuruAnalyticDistinctShareFee) || setUpAssociateModule?.associateCurrency?.associateCurrencySymbol+' 0.00'
                  innerLabelBadgeList:
                    (analyticSetUpModule?.iGuruAnalyticDistinctShareFee &&
                      setUpAssociateModule?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(analyticSetUpModule?.iGuruAnalyticDistinctShareFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: analyticSetUpModule?.iGuruAnalyticDistinctSharePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            }
          ]
        },
        {
          labelTextTwoBadge: 'groups',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'create',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: analyticSetUpModule?.iGuruAnalyticGroupCreatePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'information',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'basic',
                  innerLabelBadgeList: informationBasicStr(
                    analyticSetUpModule?.iGuruAnalyticGroupInformationBasic
                  )
                }
              ]
            },
            {
              labelTextTwoBadge: 'sell',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: analyticSetUpModule?.iGuruAnalyticGroupSellApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  //innerLabelBadgeList: analyticSetUpModule?.iGuruAnalyticGroupSellFee&&(setUpAssociateModule?.associateCurrency?.associateCurrencySymbol+' '+analyticSetUpModule?.iGuruAnalyticGroupSellFee) || setUpAssociateModule?.associateCurrency?.associateCurrencySymbol+' 0.00'
                  innerLabelBadgeList:
                    (analyticSetUpModule?.iGuruAnalyticGroupSellFee &&
                      setUpAssociateModule?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(analyticSetUpModule?.iGuruAnalyticGroupSellFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: analyticSetUpModule?.iGuruAnalyticGroupSellPermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'share',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: analyticSetUpModule?.iGuruAnalyticGroupShareApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  //innerLabelBadgeList: analyticSetUpModule?.iGuruAnalyticGroupShareFee&&(setUpAssociateModule?.associateCurrency?.associateCurrencySymbol+' '+analyticSetUpModule?.iGuruAnalyticGroupShareFee) || setUpAssociateModule?.associateCurrency?.associateCurrencySymbol+' 0.00'
                  innerLabelBadgeList:
                    (analyticSetUpModule?.iGuruAnalyticGroupShareFee &&
                      setUpAssociateModule?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(analyticSetUpModule?.iGuruAnalyticGroupShareFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: analyticSetUpModule?.iGuruAnalyticGroupSharePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            }
          ]
        },
        {
          labelTextTwoBadge: 'types',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'create',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: analyticSetUpModule?.iGuruAnalyticTypeCreatePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'information',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'basic',
                  innerLabelBadgeList: informationBasicStr(
                    analyticSetUpModule?.iGuruAnalyticTypeInformationBasic
                  )
                }
              ]
            },
            {
              labelTextTwoBadge: 'share',
              innerLabelBadgeList: analyticSetUpModule?.iGuruAnalyticTypeSharePermission
                ? 'Permitted'
                : 'Unpermitted'
            }
          ]
        },
        {
          labelTextTwoBadge: '+',
          innerLabelBadgeList: 'No'
        }
      ],
      innerInfo: 'No',
      isListCard: false,
      isReviewLink: false,
      isMultiInfoCard: true,
      //IconOne: SyncIcon,
      isResetIcon: true,
      //IconReset: analyticSetUpModule?.iGuruAnalyticAssociateSetupReset && FlipCameraAndroidIcon,
      IconReset: FlipCameraAndroidIcon,
      IconResetEnable: analyticSetUpModule?.iGuruAnalyticAssociateSetupReset
    },
    {
      id: 'iguru-market-11',
      labelTextOneOne: 'iGuru marketplace',
      labelTextOneOneBadges: [
        {
          labelTextTwoBadge: 'distinct',
          innerLabelBadgeList: 'No'
        },
        {
          labelTextTwoBadge: 'groups',
          innerLabelBadgeList: 'No'
        },
        {
          labelTextTwoBadge: 'types',
          innerLabelBadgeList: 'No'
        },
        {
          labelTextTwoBadge: '+',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'rating',
              innerLabelBadgeList: 'No'
            }
          ]
        }
      ],
      innerInfo: 'No',
      isListCard: false,
      isReviewLink: false,
      isMultiInfoCard: true,
      //IconOne: SyncIcon,
      isResetIcon: true,
      IconReset: FlipCameraAndroidIcon,
      IconResetEnable: false
    },
    {
      id: 'iguru-mine-11',
      labelTextOneOne: 'iGuru mine',
      labelTextOneOneBadges: [
        {
          labelTextTwoBadge: 'distinct',
          innerLabelBadgeList: 'No'
        },
        {
          labelTextTwoBadge: 'groups',
          innerLabelBadgeList: 'No'
        },
        {
          labelTextTwoBadge: 'types',
          innerLabelBadgeList: 'No'
        },
        {
          labelTextTwoBadge: '+',
          innerLabelBadgeList: 'No'
        }
      ],
      innerInfo: 'No',
      isListCard: false,
      isReviewLink: false,
      isMultiInfoCard: true,
      //IconOne: SyncIcon,
      isResetIcon: true,
      IconReset: FlipCameraAndroidIcon,
      IconResetEnable: false
    },
    {
      id: 'iguru-item-11',
      labelTextOneOne: 'items',
      labelTextOneOneBadges: [
        {
          labelTextTwoBadge: 'distinct',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'create',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: itemSetUpModule?.itemDistinctCreateApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  //innerLabelBadgeList: itemSetUpModule?.itemDistinctCreateFee&&(setUpAssociateModule?.associateCurrency?.associateCurrencySymbol+' '+itemSetUpModule?.itemDistinctCreateFee) || setUpAssociateModule?.associateCurrency?.associateCurrencySymbol+' 0.00'
                  innerLabelBadgeList:
                    (itemSetUpModule?.itemDistinctCreateFee &&
                      setUpAssociateModule?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(itemSetUpModule?.itemDistinctCreateFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: itemSetUpModule?.itemDistinctCreatePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'information',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'basic',
                  innerLabelBadgeList: informationBasicStr(
                    itemSetUpModule?.itemDistinctInformationBasic
                  )
                }
              ]
            },
            {
              labelTextTwoBadge: 'sell',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: itemSetUpModule?.itemDistinctSellApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  //innerLabelBadgeList: itemSetUpModule?.itemDistinctSellFee&&(setUpAssociateModule?.associateCurrency?.associateCurrencySymbol+' '+itemSetUpModule?.itemDistinctSellFee) || setUpAssociateModule?.associateCurrency?.associateCurrencySymbol+' 0.00'
                  innerLabelBadgeList:
                    (itemSetUpModule?.itemDistinctSellFee &&
                      setUpAssociateModule?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(itemSetUpModule?.itemDistinctSellFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: itemSetUpModule?.itemDistinctSellPermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'share',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: itemSetUpModule?.itemDistinctShareApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  //innerLabelBadgeList: itemSetUpModule?.itemDistinctShareFee&&(setUpAssociateModule?.associateCurrency?.associateCurrencySymbol+' '+itemSetUpModule?.itemDistinctShareFee) || setUpAssociateModule?.associateCurrency?.associateCurrencySymbol+' 0.00'
                  innerLabelBadgeList:
                    (itemSetUpModule?.itemDistinctShareFee &&
                      setUpAssociateModule?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(itemSetUpModule?.itemDistinctShareFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: itemSetUpModule?.itemDistinctSharePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            }
          ]
        },
        {
          labelTextTwoBadge: 'groups',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'create',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: itemSetUpModule?.itemGroupCreatePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'information',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'basic',
                  innerLabelBadgeList: informationBasicStr(
                    itemSetUpModule?.itemGroupInformationBasic
                  )
                }
              ]
            },
            {
              labelTextTwoBadge: 'sell',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: itemSetUpModule?.itemGroupSellApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  //innerLabelBadgeList: itemSetUpModule?.itemGroupSellFee&&(setUpAssociateModule?.associateCurrency?.associateCurrencySymbol+' '+itemSetUpModule?.itemGroupSellFee) || setUpAssociateModule?.associateCurrency?.associateCurrencySymbol+' 0.00'
                  innerLabelBadgeList:
                    (itemSetUpModule?.itemGroupSellFee &&
                      setUpAssociateModule?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(itemSetUpModule?.itemGroupSellFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: itemSetUpModule?.itemGroupSellPermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'share',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: itemSetUpModule?.itemGroupShareApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  //innerLabelBadgeList: itemSetUpModule?.itemGroupShareFee&&(setUpAssociateModule?.associateCurrency?.associateCurrencySymbol+' '+itemSetUpModule?.itemGroupShareFee) || setUpAssociateModule?.associateCurrency?.associateCurrencySymbol+' 0.00'
                  innerLabelBadgeList:
                    (itemSetUpModule?.itemGroupShareFee &&
                      setUpAssociateModule?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(itemSetUpModule?.itemGroupShareFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: itemSetUpModule?.itemGroupSharePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            }
          ]
        },
        {
          labelTextTwoBadge: 'types',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'create',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: itemSetUpModule?.itemTypeCreatePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'information',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'basic',
                  innerLabelBadgeList: informationBasicStr(
                    itemSetUpModule?.itemTypeInformationBasic
                  )
                }
              ]
            },
            {
              labelTextTwoBadge: 'share',
              innerLabelBadgeList: itemSetUpModule?.itemTypeSharePermission
                ? 'Permitted'
                : 'Unpermitted'
            }
          ]
        },
        {
          labelTextTwoBadge: '+',
          innerLabelBadgeList: 'No'
        }
      ],
      innerInfo: 'No',
      isListCard: false,
      isReviewLink: false,
      isMultiInfoCard: true,
      isResetIcon: true,
      IconReset: FlipCameraAndroidIcon,
      IconResetEnable: itemSetUpModule?.itemAssociateSetupReset
    }
  ];
  const allianceListKey = [
    {
      id: 'a1',
      labelTextOneOne: 'administrator',
      labelTextOneOneBadgeOne: 'primary',
      labelTextOneOneBadgeTwo: 'secondary',
      isListCard: true,
      labelTextOneOneBadges:
        middlePaneHeader === ''
          ? [
              {
                labelTextOneOneBadge: 'primary',
                innerList: administratorPrimaryList
              },
              {
                labelTextOneOneBadge: 'secondary',
                innerList: administratorSecondaryList
              }
            ]
          : [
              {
                labelTextOneOneBadge: 'primary',
                innerList: administratorPrimaryList
              }
            ],
      innerInfo: 'No Information'
    }
  ];
  const engagementListKey = [
    {
      id: 'a2',
      textOneOne: capitalizeFirstLetter(informationEngagement.associateStatus) || 'No Information',
      labelTextOneOne: 'status',
      innerAssociateList: [],
      innerInfo: 'No Information',
      isListCard: false
    },
    {
      id: 'a3',
      labelTextOneOne: 'tag',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'primary',
          textOne: informationEngagement.associateTag.associateTagPrimary || 'No Information'
        },
        {
          labelTextOneOneBadge: 'secondary',
          textOne: informationEngagement.associateTag.associateTagSecondary || 'No Information'
        }
      ],
      innerAssociateList: [],
      innerInfo: 'No Information',
      isListCard: false
    },
    {
      id: 'a4',
      labelTextOneOne: 'tenure',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'start',
          textOne:
            informationEngagement.associateTenureDate?.associateTenureDateTimeStart ||
            'No Information'
        },
        {
          labelTextOneOneBadge: 'end',
          textOne:
            informationEngagement.associateTenureDate?.associateTenureDateTimeEnd ||
            'No Information'
        }
      ],
      innerAssociateList: [],
      innerInfo: 'Assessee',
      isListCard: false
    }
  ];
  // console.log('assessmentSetUpModule', assessmentSetUpModule);
  const setUpPlusListAll = [
    {
      id: 'a1',
      labelTextOneOne: 'assessees',
      labelTextOneOneBadges: [
        {
          labelTextTwoBadge: 'distinct',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'create',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: assesseeSetUpModuleGeneric?.assesseeDistinctCreateApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  innerLabelBadgeList:
                    (assesseeSetUpModuleGeneric?.assesseeDistinctCreateFee &&
                      setUpAssociateModuleGeneric?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(assesseeSetUpModuleGeneric?.assesseeDistinctCreateFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assesseeSetUpModuleGeneric?.assesseeDistinctCreatePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'information',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'basic',
                  innerLabelBadgeList:
                    assesseeSetUpModuleGeneric?.assesseeDistinctInformationBasic === 'NAME_UNIQUE'
                      ? 'Unique Name Required'
                      : assesseeSetUpModuleGeneric?.assesseeDistinctInformationBasic ===
                        'NAME_DESCRIPTION_UNIQUE'
                      ? 'Unique Name + Alias Required'
                      : 'Unique Name & Alias Unrequired'
                },
                {
                  labelTextTwoBadge: 'name',
                  innerLabelBadgeList:
                    assesseeSetUpModuleGeneric?.assesseeDistinctNameFormat ===
                    'NAME_DESCRIPTION_UNIQUE'
                      ? 'Last-Name First-Name Other-Name'
                      : 'First-Name Other-Name Last-Name' || 'No'
                }
              ]
            },
            {
              labelTextTwoBadge: 'sell',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: assesseeSetUpModuleGeneric?.assesseeDistinctSellApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  innerLabelBadgeList:
                    (assesseeSetUpModuleGeneric?.assesseeDistinctSellFee &&
                      setUpAssociateModuleGeneric?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(assesseeSetUpModuleGeneric?.assesseeDistinctSellFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assesseeSetUpModuleGeneric?.assesseeDistinctSellPermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'share',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: assesseeSetUpModuleGeneric?.assesseeDistinctShareApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  innerLabelBadgeList:
                    (assesseeSetUpModuleGeneric?.assesseeDistinctShareFee &&
                      setUpAssociateModuleGeneric?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(assesseeSetUpModuleGeneric?.assesseeDistinctShareFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assesseeSetUpModuleGeneric?.assesseeDistinctSharePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'sign-on',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: assesseeSetUpModuleGeneric?.assesseeDistinctSignOnApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  innerLabelBadgeList:
                    (assesseeSetUpModuleGeneric?.assesseeDistinctSignOnFee &&
                      setUpAssociateModuleGeneric?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(assesseeSetUpModuleGeneric?.assesseeDistinctSignOnFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assesseeSetUpModuleGeneric?.assesseeDistinctSignOnPermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            }
          ]
        },
        {
          labelTextTwoBadge: 'groups',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'create',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assesseeSetUpModuleGeneric?.assesseeGroupCreatePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'information',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'basic',
                  innerLabelBadgeList: informationBasicStr(
                    assesseeSetUpModuleGeneric?.assesseeGroupInformationBasic
                  )
                }
              ]
            },
            {
              labelTextTwoBadge: 'sell',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: assesseeSetUpModuleGeneric?.assesseeGroupSellApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  innerLabelBadgeList:
                    (assesseeSetUpModuleGeneric?.assesseeGroupSellFee &&
                      setUpAssociateModuleGeneric?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(assesseeSetUpModuleGeneric?.assesseeGroupSellFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assesseeSetUpModuleGeneric?.assesseeGroupSellPermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'share',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: assesseeSetUpModuleGeneric?.assesseeGroupShareApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  innerLabelBadgeList:
                    (assesseeSetUpModuleGeneric?.assesseeGroupShareFee &&
                      setUpAssociateModuleGeneric?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(assesseeSetUpModuleGeneric?.assesseeGroupShareFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assesseeSetUpModuleGeneric?.assesseeGroupSharePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            }
          ]
        },
        {
          labelTextTwoBadge: 'roles',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'create',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assesseeSetUpModuleGeneric?.assesseeRoleCreatePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'information',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'basic',
                  innerLabelBadgeList: informationBasicStr(
                    assesseeSetUpModuleGeneric?.assesseeRoleInformationBasic
                  )
                }
              ]
            },
            {
              labelTextTwoBadge: 'share',
              innerLabelBadgeList: assesseeSetUpModuleGeneric?.assesseeRoleSharePermission
                ? 'Permitted'
                : 'Unpermitted'
            }
          ]
        },
        {
          labelTextTwoBadge: 'types',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'create',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assesseeSetUpModuleGeneric?.assesseeTypeCreatePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'information',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'basic',
                  innerLabelBadgeList: informationBasicStr(
                    assesseeSetUpModuleGeneric?.assesseeTypeInformationBasic
                  )
                }
              ]
            },
            {
              labelTextTwoBadge: 'share',
              innerLabelBadgeList: assesseeSetUpModuleGeneric?.assesseeTypeSharePermission
                ? 'Permitted'
                : 'Unpermitted'
            }
          ]
        },
        {
          labelTextTwoBadge: '+',
          innerLabelBadgeList: 'No'
        }
      ],
      innerInfo: 'No',
      isListCard: false,
      isReviewLink: false,
      isMultiInfoCard: true
    },
    {
      id: 'a-112',
      labelTextOneOne: 'assessments',
      labelTextOneOneBadges: [
        {
          labelTextTwoBadge: 'distinct',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'create',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList:
                    assessmentSetUpModuleGeneric?.assessmentDistinctCreateApproval
                      ? 'Required'
                      : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  innerLabelBadgeList:
                    (assessmentSetUpModuleGeneric?.assessmentDistinctCreateFee &&
                      setUpAssociateModuleGeneric?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(assessmentSetUpModuleGeneric?.assessmentDistinctCreateFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList:
                    assessmentSetUpModuleGeneric?.assessmentDistinctCreatePermission
                      ? 'Permitted'
                      : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'information',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'basic',
                  innerLabelBadgeList: informationBasicStr(
                    assessmentSetUpModuleGeneric?.assessmentDistinctInformationBasic
                  )
                }
              ]
            },
            {
              labelTextTwoBadge: 'sell',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: assessmentSetUpModuleGeneric?.assessmentDistinctSellApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  innerLabelBadgeList:
                    (assessmentSetUpModuleGeneric?.assessmentDistinctSellFee &&
                      setUpAssociateModuleGeneric?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(assessmentSetUpModuleGeneric?.assessmentDistinctSellFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList:
                    assessmentSetUpModuleGeneric?.assessmentDistinctSellPermission
                      ? 'Permitted'
                      : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'share',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: assessmentSetUpModuleGeneric?.assessmentDistinctShareApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  innerLabelBadgeList:
                    ( assessmentSetUpModuleGeneric?.assessmentDistinctShareFee &&
                      setUpAssociateModuleGeneric?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(assessmentSetUpModuleGeneric?.assessmentDistinctShareFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList:
                    assessmentSetUpModuleGeneric?.assessmentDistinctSharePermission
                      ? 'Permitted'
                      : 'Unpermitted'
                }
              ]
            }
          ]
        },
        {
          labelTextTwoBadge: 'groups',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'create',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assessmentSetUpModuleGeneric?.assessmentGroupCreatePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'information',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'basic',
                  innerLabelBadgeList: informationBasicStr(
                    assessmentSetUpModuleGeneric?.assessmentGroupInformationBasic
                  )
                }
              ]
            },
            {
              labelTextTwoBadge: 'sell',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: assessmentSetUpModuleGeneric?.assessmentGroupSellApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  innerLabelBadgeList:
                    (assessmentSetUpModuleGeneric?.assessmentGroupSellFee &&
                      setUpAssociateModuleGeneric?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(assessmentSetUpModuleGeneric?.assessmentGroupSellFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assessmentSetUpModuleGeneric?.assessmentGroupSellPermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'share',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: assessmentSetUpModuleGeneric?.assessmentGroupShareApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  innerLabelBadgeList:
                    (assessmentSetUpModuleGeneric?.assessmentGroupShareFee &&
                      setUpAssociateModuleGeneric?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(assessmentSetUpModuleGeneric?.assessmentGroupShareFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assessmentSetUpModuleGeneric?.assessmentGroupSharePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            }
          ]
        },
        {
          labelTextTwoBadge: 'types',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'create',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assessmentSetUpModuleGeneric?.assessmentTypeCreatePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'information',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'basic',
                  innerLabelBadgeList: informationBasicStr(
                    assessmentSetUpModuleGeneric?.assessmentTypeInformationBasic
                  )
                }
              ]
            },
            {
              labelTextTwoBadge: 'share',
              innerLabelBadgeList: assessmentSetUpModuleGeneric?.assessmentTypeSharePermission
                ? 'Permitted'
                : 'Unpermitted'
            }
          ]
        },
        {
          labelTextTwoBadge: '+',
          innerLabelBadgeList: 'No'
        }
      ],
      innerInfo: 'No',
      isListCard: false,
      isReviewLink: false,
      isMultiInfoCard: true
    },
    {
      id: 'assignment-232',
      labelTextOneOne: 'assignments',
      labelTextOneOneBadges: [
        {
          labelTextTwoBadge: 'distinct',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'create',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList:
                    assignmentSetUpModuleGeneric?.assignmentDistinctCreateApproval
                      ? 'Required'
                      : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  innerLabelBadgeList:
                    (assignmentSetUpModuleGeneric?.assignmentDistinctCreateFee &&
                      setUpAssociateModuleGeneric?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(assignmentSetUpModuleGeneric?.assignmentDistinctCreateFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList:
                    assignmentSetUpModuleGeneric?.assignmentDistinctCreatePermission
                      ? 'Permitted'
                      : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'information',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'basic',
                  innerLabelBadgeList: informationBasicStr(
                    assignmentSetUpModuleGeneric?.assignmentDistinctInformationBasic
                  )
                }
              ]
            },
            {
              labelTextTwoBadge: 'sell',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: assignmentSetUpModuleGeneric?.assignmentDistinctSellApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  innerLabelBadgeList:
                    (assignmentSetUpModuleGeneric?.assignmentDistinctSellFee &&
                      setUpAssociateModuleGeneric?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(assignmentSetUpModuleGeneric?.assignmentDistinctSellFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList:
                    assignmentSetUpModuleGeneric?.assignmentDistinctSellPermission
                      ? 'Permitted'
                      : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'share',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: assignmentSetUpModuleGeneric?.assignmentDistinctShareApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  innerLabelBadgeList:
                    ( assignmentSetUpModuleGeneric?.assignmentDistinctShareFee &&
                      setUpAssociateModuleGeneric?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(assignmentSetUpModuleGeneric?.assignmentDistinctShareFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList:
                    assignmentSetUpModuleGeneric?.assignmentDistinctSharePermission
                      ? 'Permitted'
                      : 'Unpermitted'
                }
              ]
            }
          ]
        },
        {
          labelTextTwoBadge: 'groups',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'create',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assignmentSetUpModuleGeneric?.assignmentGroupCreatePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'information',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'basic',
                  innerLabelBadgeList: informationBasicStr(
                    assignmentSetUpModuleGeneric?.assignmentGroupInformationBasic
                  )
                }
              ]
            },
            {
              labelTextTwoBadge: 'sell',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: assignmentSetUpModuleGeneric?.assignmentGroupSellApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  innerLabelBadgeList:
                    (assignmentSetUpModuleGeneric?.assignmentGroupSellFee &&
                      setUpAssociateModuleGeneric?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(assignmentSetUpModuleGeneric?.assignmentGroupSellFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assignmentSetUpModuleGeneric?.assignmentGroupSellPermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'share',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: assignmentSetUpModuleGeneric?.assignmentGroupShareApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  innerLabelBadgeList:
                    (assignmentSetUpModuleGeneric?.assignmentGroupShareFee &&
                      setUpAssociateModuleGeneric?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(assignmentSetUpModuleGeneric?.assignmentGroupShareFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assignmentSetUpModuleGeneric?.assignmentGroupSharePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            }
          ]
        },
        {
          labelTextTwoBadge: 'types',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'create',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: assignmentSetUpModuleGeneric?.assignmentTypeCreatePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'information',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'basic',
                  innerLabelBadgeList: informationBasicStr(
                    assignmentSetUpModuleGeneric?.assignmentTypeInformationBasic
                  )
                }
              ]
            },
            {
              labelTextTwoBadge: 'share',
              innerLabelBadgeList: assignmentSetUpModuleGeneric?.assignmentTypeSharePermission
                ? 'Permitted'
                : 'Unpermitted'
            }
          ]
        },
        {
          labelTextTwoBadge: '+',
          innerLabelBadgeList: 'No'
        }
      ],
      innerInfo: 'No',
      isListCard: false,
      isReviewLink: false,
      isMultiInfoCard: true
    },
    {
      id: 'associate-213',
      labelTextOneOne: 'associates', //
      labelTextOneOneBadges: [
        {
          labelTextTwoBadge: 'distinct',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'create',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: setUpAssociateModuleGeneric?.associateDistinctCreateApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  innerLabelBadgeList:
                    (setUpAssociateModuleGeneric?.associateDistinctCreateFee &&
                      setUpAssociateModuleGeneric?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(setUpAssociateModuleGeneric?.associateDistinctCreateFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList:
                    setUpAssociateModuleGeneric?.associateDistinctCreatePermission
                      ? 'Permitted'
                      : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'information',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'basic',
                  innerLabelBadgeList: informationBasicStr(
                    setUpAssociateModuleGeneric?.associateDistinctInformationBasic
                  )
                }
              ]
            },
            {
              labelTextTwoBadge: 'sell',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: setUpAssociateModuleGeneric?.associateDistinctSellApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  innerLabelBadgeList:
                    (setUpAssociateModuleGeneric?.associateDistinctSellFee &&
                      setUpAssociateModuleGeneric?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(setUpAssociateModuleGeneric?.associateDistinctSellFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: setUpAssociateModuleGeneric?.associateDistinctSellPermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'share',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: setUpAssociateModuleGeneric?.associateDistinctShareApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  innerLabelBadgeList:
                    (setUpAssociateModuleGeneric?.associateDistinctShareFee &&
                      setUpAssociateModuleGeneric?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(setUpAssociateModuleGeneric?.associateDistinctShareFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: setUpAssociateModuleGeneric?.associateDistinctSharePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'sign-on',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: setUpAssociateModuleGeneric?.associateDistinctSignOnApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  innerLabelBadgeList:
                    (setUpAssociateModuleGeneric?.associateDistinctSignOnFee &&
                      setUpAssociateModuleGeneric?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(setUpAssociateModuleGeneric?.associateDistinctSignOnFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList:
                    setUpAssociateModuleGeneric?.associateDistinctSignOnPermission
                      ? 'Permitted'
                      : 'Unpermitted'
                }
              ]
            }
          ]
        },
        {
          labelTextTwoBadge: 'groups',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'create',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: setUpAssociateModuleGeneric?.associateGroupCreatePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'information',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'basic',
                  innerLabelBadgeList: informationBasicStr(
                    setUpAssociateModuleGeneric?.associateGroupInformationBasic
                  )
                }
              ]
            },
            {
              labelTextTwoBadge: 'sell',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: setUpAssociateModuleGeneric?.associateGroupSellApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  innerLabelBadgeList:
                    (setUpAssociateModuleGeneric?.associateGroupSellFee &&
                      setUpAssociateModuleGeneric?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(setUpAssociateModuleGeneric?.associateGroupSellFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: setUpAssociateModuleGeneric?.associateGroupSellPermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'share',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: setUpAssociateModuleGeneric?.associateGroupShareApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  innerLabelBadgeList:
                    (setUpAssociateModuleGeneric?.associateGroupShareFee &&
                      setUpAssociateModuleGeneric?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(setUpAssociateModuleGeneric?.associateGroupShareFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: setUpAssociateModuleGeneric?.associateGroupSharePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            }
          ]
        },
        {
          labelTextTwoBadge: 'nodes',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'create',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: setUpAssociateModuleGeneric?.associateNodeCreatePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'information',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'basic',
                  innerLabelBadgeList: informationBasicStr(
                    setUpAssociateModuleGeneric?.associateNodeInformationBasic
                  )
                }
              ]
            },
            {
              labelTextTwoBadge: 'permission',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'ascendant',
                  innerLabelBadgeList: [
                    {
                      labelTextTwoBadge: 'all',
                      innerLabelBadgeList:
                        getPermissionStr(
                          associateNodeSetUpModule?.informationSetup?.associateNodeAscendantAll
                        ) || 'No Information'
                    },
                    {
                      labelTextTwoBadge: 'primary',
                      innerLabelBadgeList:
                        getPermissionStr(
                          associateNodeSetUpModule?.informationSetup?.associateNodeAscendantPrimary
                        ) || 'No Information'
                    },
                    {
                      labelTextTwoBadge: 'secondary',
                      innerLabelBadgeList:
                        getPermissionStr(
                          associateNodeSetUpModule?.informationSetup
                            ?.associateNodeAscendantSecondary
                        ) || 'No Information'
                    }
                  ]
                },
                {
                  labelTextTwoBadge: 'descendant',
                  innerLabelBadgeList: [
                    {
                      labelTextTwoBadge: 'all',
                      innerLabelBadgeList:
                        getPermissionStr(
                          associateNodeSetUpModule?.informationSetup?.associateNodeDescendantAll
                        ) || 'No Information'
                    },
                    {
                      labelTextTwoBadge: 'primary',
                      innerLabelBadgeList:
                        getPermissionStr(
                          associateNodeSetUpModule?.informationSetup?.associateNodeDescendantPrimary
                        ) || 'No Information'
                    },
                    {
                      labelTextTwoBadge: 'secondary',
                      innerLabelBadgeList:
                        getPermissionStr(
                          associateNodeSetUpModule?.informationSetup
                            ?.associateNodeDescendantSecondary
                        ) || 'No Information'
                    }
                  ]
                },
                {
                  labelTextTwoBadge: 'peer',
                  innerLabelBadgeList: [
                    {
                      labelTextTwoBadge: 'all',
                      innerLabelBadgeList:
                        getPermissionStr(
                          associateNodeSetUpModule?.informationSetup?.associateNodePeerAll
                        ) || 'No Information'
                    }
                  ]
                },
                {
                  labelTextTwoBadge: 'root',
                  innerLabelBadgeList:
                    getPermissionStr(
                      associateNodeSetUpModule?.informationSetup?.associateNodeRoot
                    ) || 'No Information'
                },
                {
                  labelTextTwoBadge: 'self',
                  innerLabelBadgeList:
                    getPermissionStr(
                      associateNodeSetUpModule?.informationSetup?.associateNodeSelf
                    ) || 'No Information'
                }
              ]
            },
            {
              labelTextTwoBadge: 'share',
              innerLabelBadgeList: setUpAssociateModuleGeneric?.associateNodeSharePermission
                ? 'Permitted'
                : 'Unpermitted'
            }
          ]
        },
        {
          labelTextTwoBadge: 'roles',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'create',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: setUpAssociateModuleGeneric?.associateRoleCreatePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'information',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'basic',
                  innerLabelBadgeList: informationBasicStr(
                    setUpAssociateModuleGeneric?.associateRoleInformationBasic
                  )
                }
              ]
            },
            {
              labelTextTwoBadge: 'share',
              innerLabelBadgeList: setUpAssociateModuleGeneric?.associateRoleSharePermission
                ? 'Permitted'
                : 'Unpermitted'
            }
          ]
        },
        {
          labelTextTwoBadge: 'types',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'create',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: setUpAssociateModuleGeneric?.associateTypeCreatePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'information',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'basic',
                  innerLabelBadgeList: informationBasicStr(
                    setUpAssociateModuleGeneric?.associateTypeInformationBasic
                  )
                }
              ]
            },
            {
              labelTextTwoBadge: 'share',
              innerLabelBadgeList: setUpAssociateModuleGeneric?.associateTypeSharePermission
                ? 'Permitted'
                : 'Unpermitted'
            }
          ]
        },
        {
          labelTextTwoBadge: '+',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'brand',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: setUpAssociateModuleGeneric?.iGuruPlatformBrandApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'choice',
                  innerLabelBadgeList:
                    setUpAssociateModuleGeneric?.iGuruPlatformBrandChoice || 'No Information'
                },
                {
                  labelTextTwoBadge: 'fee',
                  innerLabelBadgeList:
                    (setUpAssociateModuleGeneric?.iGuruPlatformBrandFee &&
                      setUpAssociateModuleGeneric?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(setUpAssociateModuleGeneric?.iGuruPlatformBrandFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: setUpAssociateModuleGeneric?.iGuruPlatformBrandPermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
                // {
                //   labelTextTwoBadge: 'picture',
                //   innerLabelBadgeList:
                //     setUpAssociateModuleGeneric?.iGuruPlatformBrandPicture || 'No Information'
                // }
              ]
            },
            {
              labelTextTwoBadge: 'country',
              innerLabelBadgeList:
                setUpAssociateModuleGeneric?.associateCountry?.associateCountryName ||
                'No Information'
            },
            {
              labelTextTwoBadge: 'currency',
              innerLabelBadgeList:
                setUpAssociateModuleGeneric?.associateCurrencyName || 'No Information'
            },
            {
              labelTextTwoBadge: 'date',
              innerLabelBadgeList:
                setUpAssociateModuleGeneric?.associateDateFormat || 'No Information'
            },
            {
              labelTextTwoBadge: 'domain',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'primary',
                  innerLabelBadgeList:
                    setUpAssociateModuleGeneric?.associateDomainPrimary || 'No Information'
                },
                {
                  labelTextTwoBadge: 'secondary',
                  innerLabelBadgeList:
                    setUpAssociateModuleGeneric?.associateDomainSecondary || 'No Information'
                }
              ]
            },
            {
              labelTextTwoBadge: 'information',
              innerLabelBadgeList:
                setUpAssociateModuleGeneric?.associateInformation || 'No Information'
            },
            {
              labelTextTwoBadge: 'language',
              innerLabelBadgeList:
                setUpAssociateModuleGeneric?.associateLanguage || 'No Information'
              // innerLabelBadgeList: [
              //   {
              //     labelTextTwoBadge: 'name',
              //     innerLabelBadgeList: setUpAssociateModule?.associateLanguageName || 'No'
              //   },
              //   {
              //     labelTextTwoBadge: 'tag',
              //     innerLabelBadgeList: setUpAssociateModule?.associateLanguageTag || 'No'
              //   }
              // ]
            },
            {
              labelTextTwoBadge: 'service',
              innerLabelBadgeList: setUpAssociateModuleGeneric?.associateService || 'No Information'
            },
            {
              labelTextTwoBadge: 'rating',
              innerLabelBadgeList: setUpAssociateModuleGeneric?.associateRating || 'No Information'
            },
            {
              labelTextTwoBadge: 'theme',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: setUpAssociateModuleGeneric?.iGuruPlatformThemeApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'choice',
                  innerLabelBadgeList:
                    setUpAssociateModuleGeneric?.iGuruPlatformThemeChoice || 'No Information'
                },
                {
                  labelTextTwoBadge: 'fee',
                  innerLabelBadgeList:
                    (setUpAssociateModuleGeneric?.iGuruPlatformThemeFee &&
                      setUpAssociateModuleGeneric?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(setUpAssociateModuleGeneric?.iGuruPlatformThemeFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: setUpAssociateModuleGeneric?.iGuruPlatformThemePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'time',
              innerLabelBadgeList:
                setUpAssociateModuleGeneric?.associateTimeFormat || 'No Information'
            }
          ]
        }
      ],
      innerInfo: 'No',
      isListCard: false,
      isReviewLink: false,
      isMultiInfoCard: true
    },
    {
      id: 'iguru-11',
      labelTextOneOne: 'iGuru analytics',
      labelTextOneOneBadges: [
        {
          labelTextTwoBadge: 'distinct',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'create',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList:
                    analyticSetUpModuleGeneric?.iGuruAnalyticDistinctCreateApproval
                      ? 'Required'
                      : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  innerLabelBadgeList:
                    (analyticSetUpModuleGeneric?.iGuruAnalyticDistinctCreateFee &&
                      setUpAssociateModuleGeneric?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(analyticSetUpModuleGeneric?.iGuruAnalyticDistinctCreateFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList:
                    analyticSetUpModuleGeneric?.iGuruAnalyticDistinctCreatePermission
                      ? 'Permitted'
                      : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'information',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'basic',
                  innerLabelBadgeList: informationBasicStr(
                    analyticSetUpModuleGeneric?.iGuruAnalyticDistinctInformationBasic
                  )
                }
              ]
            },
            {
              labelTextTwoBadge: 'sell',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: analyticSetUpModuleGeneric?.iGuruAnalyticDistinctSellApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  innerLabelBadgeList:
                    (analyticSetUpModuleGeneric?.iGuruAnalyticDistinctSellFee &&
                      setUpAssociateModuleGeneric?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(analyticSetUpModuleGeneric?.iGuruAnalyticDistinctSellFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList:
                    analyticSetUpModuleGeneric?.iGuruAnalyticDistinctSellPermission
                      ? 'Permitted'
                      : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'share',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList:
                    analyticSetUpModuleGeneric?.iGuruAnalyticDistinctShareApproval
                      ? 'Required'
                      : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  innerLabelBadgeList:
                    (analyticSetUpModuleGeneric?.iGuruAnalyticDistinctShareFee &&
                      setUpAssociateModuleGeneric?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(analyticSetUpModuleGeneric?.iGuruAnalyticDistinctShareFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList:
                    analyticSetUpModuleGeneric?.iGuruAnalyticDistinctSharePermission
                      ? 'Permitted'
                      : 'Unpermitted'
                }
              ]
            }
          ]
        },
        {
          labelTextTwoBadge: 'groups',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'create',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList:
                    analyticSetUpModuleGeneric?.iGuruAnalyticGroupCreatePermission
                      ? 'Permitted'
                      : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'information',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'basic',
                  innerLabelBadgeList: informationBasicStr(
                    analyticSetUpModuleGeneric?.iGuruAnalyticGroupInformationBasic
                  )
                }
              ]
            },
            {
              labelTextTwoBadge: 'sell',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: analyticSetUpModuleGeneric?.iGuruAnalyticGroupSellApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  innerLabelBadgeList:
                    (analyticSetUpModuleGeneric?.iGuruAnalyticGroupSellFee &&
                      setUpAssociateModuleGeneric?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(analyticSetUpModuleGeneric?.iGuruAnalyticGroupSellFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: analyticSetUpModuleGeneric?.iGuruAnalyticGroupSellPermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'share',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: analyticSetUpModuleGeneric?.iGuruAnalyticGroupShareApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  innerLabelBadgeList:
                    (analyticSetUpModuleGeneric?.iGuruAnalyticGroupShareFee &&
                      setUpAssociateModuleGeneric?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(analyticSetUpModuleGeneric?.iGuruAnalyticGroupShareFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: analyticSetUpModuleGeneric?.iGuruAnalyticGroupSharePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            }
          ]
        },
        {
          labelTextTwoBadge: 'types',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'create',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: analyticSetUpModuleGeneric?.iGuruAnalyticTypeCreatePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'information',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'basic',
                  innerLabelBadgeList: informationBasicStr(
                    analyticSetUpModuleGeneric?.iGuruAnalyticTypeInformationBasic
                  )
                }
              ]
            },
            {
              labelTextTwoBadge: 'share',
              innerLabelBadgeList: analyticSetUpModuleGeneric?.iGuruAnalyticTypeSharePermission
                ? 'Permitted'
                : 'Unpermitted'
            }
          ]
        },
        {
          labelTextTwoBadge: '+',
          innerLabelBadgeList: 'No'
        }
      ],
      innerInfo: 'No',
      isListCard: false,
      isReviewLink: false,
      isMultiInfoCard: true
    },
    {
      id: 'iguru-market-11',
      labelTextOneOne: 'iGuru marketplace',
      labelTextOneOneBadges: [
        {
          labelTextTwoBadge: 'distinct',
          innerLabelBadgeList: 'No'
        },
        {
          labelTextTwoBadge: 'groups',
          innerLabelBadgeList: 'No'
        },
        {
          labelTextTwoBadge: 'types',
          innerLabelBadgeList: 'No'
        },
        {
          labelTextTwoBadge: '+',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'rating',
              innerLabelBadgeList: 'No'
            }
          ]
        }
      ],
      innerInfo: 'No',
      isListCard: false,
      isReviewLink: false,
      isMultiInfoCard: true
    },
    {
      id: 'iguru-mine-11',
      labelTextOneOne: 'iGuru mine',
      labelTextOneOneBadges: [
        {
          labelTextTwoBadge: 'distinct',
          innerLabelBadgeList: 'No'
        },
        {
          labelTextTwoBadge: 'groups',
          innerLabelBadgeList: 'No'
        },
        {
          labelTextTwoBadge: 'types',
          innerLabelBadgeList: 'No'
        },
        {
          labelTextTwoBadge: '+',
          innerLabelBadgeList: 'No'
        }
      ],
      innerInfo: 'No',
      isListCard: false,
      isReviewLink: false,
      isMultiInfoCard: true
    },
    {
      id: 'iguru-item-11',
      labelTextOneOne: 'items',
      labelTextOneOneBadges: [
        {
          labelTextTwoBadge: 'distinct',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'create',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: itemSetUpModuleGeneric?.itemDistinctCreateApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  innerLabelBadgeList:
                    (itemSetUpModuleGeneric?.itemDistinctCreateFee &&
                      setUpAssociateModuleGeneric?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(itemSetUpModuleGeneric?.itemDistinctCreateFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: itemSetUpModuleGeneric?.itemDistinctCreatePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'information',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'basic',
                  innerLabelBadgeList: informationBasicStr(itemSetUpModuleGeneric?.
                    itemDistinctInformationBasic
                  )
                }
              ]
            },
            {
              labelTextTwoBadge: 'sell',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: itemSetUpModuleGeneric?.itemDistinctSellApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  innerLabelBadgeList:
                    (itemSetUpModuleGeneric?.itemDistinctSellFee &&
                      setUpAssociateModuleGeneric?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(itemSetUpModuleGeneric?.itemDistinctSellFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: itemSetUpModuleGeneric?.itemDistinctSellPermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'share',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: itemSetUpModuleGeneric?.itemDistinctShareApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  innerLabelBadgeList:
                    (itemSetUpModuleGeneric?.itemDistinctShareFee &&
                      setUpAssociateModuleGeneric?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(itemSetUpModuleGeneric?.itemDistinctShareFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: itemSetUpModuleGeneric?.itemDistinctSharePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            }
          ]
        },
        {
          labelTextTwoBadge: 'groups',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'create',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: itemSetUpModuleGeneric?.itemGroupCreatePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'information',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'basic',
                  innerLabelBadgeList: informationBasicStr(
                    itemSetUpModuleGeneric?.itemGroupInformationBasic
                  )
                }
              ]
            },
            {
              labelTextTwoBadge: 'sell',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: itemSetUpModuleGeneric?.itemGroupSellApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  innerLabelBadgeList:
                    (itemSetUpModuleGeneric?.itemGroupSellFee &&
                      setUpAssociateModuleGeneric?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(itemSetUpModuleGeneric?.itemGroupSellFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: itemSetUpModuleGeneric?.itemGroupSellPermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'share',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'approval',
                  innerLabelBadgeList: itemSetUpModuleGeneric?.itemGroupShareApproval
                    ? 'Required'
                    : 'Unrequired'
                },
                {
                  labelTextTwoBadge: 'fee',
                  innerLabelBadgeList:
                    (itemSetUpModuleGeneric?.itemGroupShareFee &&
                      setUpAssociateModuleGeneric?.associateCurrency?.associateCurrencySymbol +
                        ' ' +
                        Number(itemSetUpModuleGeneric?.itemGroupShareFee).toFixed()) ||
                    '0'
                },
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: itemSetUpModuleGeneric?.itemGroupSharePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            }
          ]
        },
        {
          labelTextTwoBadge: 'types',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'create',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'permission',
                  innerLabelBadgeList: itemSetUpModuleGeneric?.itemTypeCreatePermission
                    ? 'Permitted'
                    : 'Unpermitted'
                }
              ]
            },
            {
              labelTextTwoBadge: 'information',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'basic',
                  innerLabelBadgeList: informationBasicStr(
                    itemSetUpModuleGeneric?.itemTypeInformationBasic
                  )
                }
              ]
            },
            {
              labelTextTwoBadge: 'share',
              innerLabelBadgeList: itemSetUpModuleGeneric?.itemTypeSharePermission
                ? 'Permitted'
                : 'Unpermitted'
            }
          ]
        },
        {
          labelTextTwoBadge: '+',
          innerLabelBadgeList: 'No'
        }
      ],
      innerInfo: 'No',
      isListCard: false,
      isReviewLink: false,
      isMultiInfoCard: true
    }
  ];
  const reviseAlliance = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
  };
  const reviseAllocation = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    if (labelName === 'group') {
      dispatch({ type: LOADER_START });
      let requestObj = makeAssociateGroupObj(
        selectedAssociateInfo,
        'active',
        0,
        -1,
        'list',
        assignmentCreateType
      );
      dispatch({ type: SET_CORE_GROUP_REVIEW_LIST_REQ_OBJECT, payload: requestObj });
      dispatch({
        type: GET_ASSOCIATE_GROUP_REVIEW_LIST_SAGA,
        payload: {
          request: requestObj,
          BadgeOne: '',
          BadgeTwo: '',
          BadgeThree: '',
          isMiddlePaneList: false
        }
      });

      if (selectedBadgeName === 'primary') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'GROUPPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (selectedBadgeName === 'secondary') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'GROUPSECONDARYPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
    }
    if (labelName === 'node') {
      let nodeRequestObj = makeInternalNodeObj(
        selectedAssociateInfo,
        'active',
        0,
        -1,
        '',
        'Associate-Self-Node-List-Create'
      );
      dispatch({
        type: SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT,
        payload: nodeRequestObj
      });
      dispatch({
        type: INTERNAL_NODE_LIST_WITH_PERMISSIONS_SAGA,
        payload: {
          request: nodeRequestObj,
          BadgeOne: '',
          BadgeTwo: '',
          BadgeThree: '',
          nodeViewState: 'list',
          isMiddlePaneList: false
        }
      });
      if (selectedBadgeName === 'primary') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: { isPopUpValue: 'NODEPOPUP', popupMode: 'ASSOCIATE_CREATE' }
        });
      }
      if (selectedBadgeName === 'secondary') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'NODESECONDARYPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
    }
    if (labelName === 'role') {
      let roleRequestObj = makeAssociateRoleObj(selectedAssociateInfo, 'active', 0, -1);
      dispatch({ type: LOADER_START });
      dispatch({
        type: SET_CORE_ROLE_REVIEW_LIST_REQ_OBJECT,
        payload: roleRequestObj
      });
      dispatch({
        type: GET_ASSOCIATE_ROLE_REVIEW_LIST_SAGA,
        payload: {
          request: roleRequestObj,
          BadgeOne: '',
          BadgeTwo: '',
          BadgeThree: '',
          isMiddlePaneList: false
        }
      });
      if (selectedBadgeName === 'primary') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: { isPopUpValue: 'ROLEPOPUP', popupMode: 'ASSOCIATE_CREATE' }
        });
      }
      if (selectedBadgeName === 'secondary') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ROLESECONDARYPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
    }
    if (labelName === 'type') {
      let typeRequestObj = makeAssociateTypeObj(selectedAssociateInfo, 'active', 0, -1);
      dispatch({ type: LOADER_START });
      dispatch({
        type: SET_CORE_TYPE_REVIEW_LIST_REQ_OBJECT,
        payload: typeRequestObj
      });
      dispatch({
        type: GET_ASSOCIATE_TYPE_REVIEW_LIST_SAGA,
        payload: {
          request: typeRequestObj,
          BadgeOne: '',
          BadgeTwo: '',
          BadgeThree: '',
          isMiddlePaneList: false
        }
      });
      if (selectedBadgeName === 'primary') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'TYPELISTPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (selectedBadgeName === 'secondary') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'TYPELISTPOPUPSECONDARY',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
    }
  };
  const reviseEngagement = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    if (labelName === 'status') {
      dispatch({
        type: SET_STATUS_POPUP_VALUE,
        payload: capitalizeFirstLetter(informationEngagement?.associateStatus)
      });
      dispatch({
        type: ASSOCIATE_SIGN_ON,
        payload: { isPopUpValue: 'STATUSPOPUP', popupMode: 'ASSOCIATE_CREATE' }
      });
    }
    if (labelName === 'tag') {
      if (selectedBadgeName === 'primary') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'TAGREADONLYPRIMARYPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (selectedBadgeName === 'secondary') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'TAGSECONDARYPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
    }
    if (labelName === 'tenure') {
      if (selectedBadgeName === 'start') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'TENURESATRTDATEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (selectedBadgeName === 'end') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'TENUREENDDATEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
    }
  };
  const reviseSetupPlus = (e, selectedBadgeArray) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    let badgeName = '';
    if (selectedBadgeArray?.length > 0) {
      selectedBadgeArray.forEach((element) => {
        badgeName = badgeName + element.labelTextTwoBadge;
      });
    }
    dispatch({
      type: UPDATE_SETUPSETUPPLUSFLAG,
      payload: false
    });
    if (labelName === 'assessees') {
      if (!associateInfo?.informationSetupPlus.assessee) {
        dispatch({
          type: UPDATE_ASSOCIATE_SETUPPLUS_ASSESSEE_INFO,
          payload: assesseeSetUpModuleGeneric
        });
      }

      if (badgeName === 'distinctcreate' && selectedBadgeName === 'approval') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEECREATEAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctcreate' && selectedBadgeName === 'fee') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEECREATEFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctcreate' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEECREATEPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctinformation' && selectedBadgeName === 'basic') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEEINFODISTINCTBASICPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctinformation' && selectedBadgeName === 'name') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'PEOPLEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsell' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEESELLAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsell' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEESELLFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsell' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEESELLPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctshare' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEESHAREAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctshare' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEESHAREFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctshare' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEESHAREPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinct' && selectedBadgeName === 'share') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEE_DIST_SHARE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsign-on' && selectedBadgeName === 'approval') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEE_SIGNON_APPROVAL_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsign-on' && selectedBadgeName === 'fee') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEE_SIGNON_FEE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsign-on' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEE_SIGNON_PERMISSION_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groups' && selectedBadgeName === 'share') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEE_GROUP_SHARE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'types' && selectedBadgeName === 'share') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEE_TYPE_SHARE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'roles' && selectedBadgeName === 'share') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEE_ROLE_SHARE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupscreate' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEEGROUPCREATEPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsinformation' && selectedBadgeName === 'basic') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEEBASICINFOGROUPPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupssell' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEESELLAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupssell' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEESELLFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupssell' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEESELLPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsshare' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEESHAREAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsshare' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEESHAREFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsshare' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEESHAREPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'rolescreate' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEEROLECREATEPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'rolesinformation' && selectedBadgeName === 'basic') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEEBASICINFOROLEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'typescreate' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEETYPECREATEPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'typesinformation' && selectedBadgeName === 'basic') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEEBASICINFOTYPEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (labelName === 'assessees' && selectedBadgeName === 'reset') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEERESETPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
    }
    if (labelName === 'assessments') {
      if (!associateInfo?.informationSetupPlus.assessment) {
        dispatch({
          type: UPDATE_ASSOCIATE_SETUPPLUS_ASSESSMENT_INFO,
          payload: assessmentSetUpModuleGeneric
        });
      }

      if (labelName === 'assessments' && selectedBadgeName === 'reset') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENTRESETPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctcreate' && selectedBadgeName === 'approval') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENTCREATEAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctcreate' && selectedBadgeName === 'fee') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENTCREATEFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctcreate' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENTCREATEPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctinformation' && selectedBadgeName === 'basic') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENTINFODISTINCTBASICPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsell' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENTSELLAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsell' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENTSELLFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsell' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENTSELLPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctshare' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENTSHAREAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctshare' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENTSHAREFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctshare' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENTSHAREPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctshare' && selectedBadgeName === 'nodes') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENT_NODE_SHARE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctshare' && selectedBadgeName === 'associate') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENT_ASSOCIATE_SHARE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groups' && selectedBadgeName === 'share') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENT_GROUP_SHARE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'types' && selectedBadgeName === 'share') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENT_TYPE_SHARE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupscreate' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENTGROUPCREATEPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsinformation' && selectedBadgeName === 'basic') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENTBASICINFOGROUPPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupssell' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENTSELLAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupssell' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENTSELLFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupssell' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENTSELLPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsshare' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENTSHAREAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsshare' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENTSHAREFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsshare' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENTSHAREPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'typescreate' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENTTYPECREATEPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'typesinformation' && selectedBadgeName === 'basic') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENTBASICINFOTYPEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
    }
    if (labelName === 'assignments') {
      if (!associateInfo?.informationSetupPlus.assignment) {
        dispatch({
          type: UPDATE_ASSOCIATE_SETUPPLUS_ASSIGNMENT_INFO,
          payload: assignmentSetUpModuleGeneric
        });
      }
      if (labelName === 'assignments' && selectedBadgeName === 'reset') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENTRESETPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctcreate' && selectedBadgeName === 'approval') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENTCREATEAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctcreate' && selectedBadgeName === 'fee') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENTCREATEFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctcreate' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENTCREATEPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctinformation' && selectedBadgeName === 'basic') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENTINFODISTINCTBASICPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsell' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENTSELLAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsell' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENTSELLFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsell' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENTSELLPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupssell' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENTSELLAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupssell' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENTSELLFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupssell' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENTSELLPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctshare' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENTSHAREAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctshare' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENTSHAREFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctshare' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENTSHAREPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsshare' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENTSHAREAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsshare' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENTSHAREFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsshare' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENTSHAREPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupscreate' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENTGROUPCREATEPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsinformation' && selectedBadgeName === 'basic') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENTBASICINFOGROUPPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'typescreate' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENTTYPECREATEPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'typesinformation' && selectedBadgeName === 'basic') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENTBASICINFOTYPEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinct' && selectedBadgeName === 'share') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENT_SHARE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groups' && selectedBadgeName === 'share') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENT_GROUP_SHARE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'types' && selectedBadgeName === 'share') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENT_TYPE_SHARE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
    }
    if (labelName === 'items') {
      if (!associateInfo?.informationSetupPlus.item) {
        dispatch({
          type: UPDATE_ASSOCIATE_SETUPPLUS_ITEM_INFO,
          payload: itemSetUpModuleGeneric
        });
      }
      if (labelName === 'items' && selectedBadgeName === 'reset') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEMRESETPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctcreate' && selectedBadgeName === 'approval') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEMCREATEAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctcreate' && selectedBadgeName === 'fee') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEMCREATEFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctcreate' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEMCREATEPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctinformation' && selectedBadgeName === 'basic') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEMINFODISTINCTBASICPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsell' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEMSELLAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsell' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEMSELLFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsell' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEMSELLPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupssell' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEMSELLAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupssell' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEMSELLFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupssell' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEMSELLPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctshare' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEMSHAREAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctshare' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEMSHAREFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctshare' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEMSHAREPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsshare' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEMSHAREAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsshare' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEMSHAREFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsshare' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEMSHAREPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupscreate' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEMGROUPCREATEPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsinformation' && selectedBadgeName === 'basic') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEMBASICINFOGROUPPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'typescreate' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEMTYPECREATEPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'typesinformation' && selectedBadgeName === 'basic') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEMBASICINFOTYPEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }

      if (badgeName === 'distinct' && selectedBadgeName === 'share') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEM_SHARE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groups' && selectedBadgeName === 'share') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEM_GROUP_SHARE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'types' && selectedBadgeName === 'share') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEM_TYPE_SHARE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
    }
    if (labelName === 'iGuru analytics') {
      if (!associateInfo?.informationSetupPlus.analytic) {
        dispatch({
          type: UPDATE_ASSOCIATE_SETUPPLUS_ANALYTIC_INFO,
          payload: analyticSetUpModuleGeneric
        });
      }
      if (labelName === 'iGuru analytics' && selectedBadgeName === 'reset') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'IGURUANALYTICSRESETPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctcreate' && selectedBadgeName === 'approval') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'IGURUANALYTICSCREATEAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctcreate' && selectedBadgeName === 'fee') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'IGURUANALYTICSCREATEFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctcreate' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'IGURUANALYTICSCREATEPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctinformation' && selectedBadgeName === 'basic') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'IGURUANALYTICSINFODISTINCTBASICPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsell' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'IGURUANALYTICSSELLAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsell' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'IGURUANALYTICSSELLFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsell' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'IGURUANALYTICSSELLPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupssell' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'IGURUANALYTICSSELLAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupssell' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'IGURUANALYTICSSELLFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupssell' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'IGURUANALYTICSSELLPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctshare' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'IGURUANALYTICSSHAREAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctshare' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'IGURUANALYTICSSHAREFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctshare' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'IGURUANALYTICSSHAREPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsshare' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'IGURUANALYTICSSHAREAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsshare' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'IGURUANALYTICSSHAREFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsshare' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'IGURUANALYTICSSHAREPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupscreate' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'IGURUANALYTICSGROUPCREATEPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsinformation' && selectedBadgeName === 'basic') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'IGURUANALYTICSBASICINFOGROUPPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'typescreate' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'IGURUANALYTICSTYPECREATEPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'typesinformation' && selectedBadgeName === 'basic') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'IGURUANALYTICSBASICINFOTYPEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinct' && selectedBadgeName === 'share') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ANALYTIC_SHARE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groups' && selectedBadgeName === 'share') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ANALYTIC_GROUP_SHARE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'types' && selectedBadgeName === 'share') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ANALYTIC_TYPE_SHARE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
    }
    if (labelName === 'associates') {
      if (!associateInfo?.informationSetupPlus.associate) {
        dispatch({
          type: UPDATE_ASSOCIATE_SETUPPLUS_INFO,
          payload: setUpAssociateModuleGeneric
        });

        dispatch({
          type: UPDATE_ASSOCIATE_COUNTRY_INFO,
          payload: setUpAssociateModule?.associateCountry
        });

        dispatch({
          type: UPDATE_ASSOCIATE_CURRENCY_INFO,
          payload: setUpAssociateModule?.associateCurrency
        });
        dispatch({
          type: UPDATE_ASSOCIATE_LANGUAGE_INFO,
          payload: setUpAssociateModule?.associateLanguage
        });
      }
      if (
        badgeName === 'nodespermissiondescendant' ||
        badgeName === 'nodespermissionascendant' ||
        badgeName === 'nodespermissionpeer' ||
        badgeName === 'nodespermission'
      ) {
        if (!associateInfo?.informationSetup.associateNode) {
          dispatch({
            type: UPDATE_ASSOCIATE_SETUP_ASSOCIATENODE_INFO,
            payload: associateNodeSetUpModule
          });
        }
      }

      if (labelName === 'associates' && selectedBadgeName === 'reset') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATERESETPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctcreate' && selectedBadgeName === 'approval') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATECREATEAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctcreate' && selectedBadgeName === 'fee') {
        // need to node found
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATECREATEFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctcreate' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATECREATEPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctinformation' && selectedBadgeName === 'basic') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATEINFODISTINCTBASICPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctinformation' && selectedBadgeName === 'name') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATEPEOPLEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsell' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATESELLAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsell' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATESELLFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsell' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATESELLPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupssell' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATESELLAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupssell' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATESELLFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupssell' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATESELLPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctshare' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATESHAREAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctshare' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        // dispatch({
        //   type: ASSOCIATE_SIGN_ON,
        //   payload: {
        //     isPopUpValue: 'ASSOCIATESHAREFEEPOPUP',
        //     popupMode: 'ASSOCIATE_CREATE'
        //   }
        // });
      }
      if (badgeName === 'distinctshare' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATESHAREPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsshare' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATESHAREAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsshare' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        // dispatch({
        //   type: ASSOCIATE_SIGN_ON,
        //   payload: {
        //     isPopUpValue: 'ASSOCIATESHAREFEEPOPUP',
        //     popupMode: 'ASSOCIATE_CREATE'
        //   }
        // });
      }
      if (badgeName === 'groupsshare' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATESHAREPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupscreate' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATEGROUPCREATEPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsinformation' && selectedBadgeName === 'basic') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATEBASICINFOGROUPPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'rolescreate' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATEROLECREATEPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'rolesinformation' && selectedBadgeName === 'basic') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATEBASICINFOROLEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'typescreate' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATETYPECREATEPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'typesinformation' && selectedBadgeName === 'basic') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATEBASICINFOTYPEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'nodescreate' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATENODECREATEPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'nodesinformation' && selectedBadgeName === 'basic') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATEBASICINFONODEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === '+' && selectedBadgeName === 'date') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'DATEFORMATPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === '+' && selectedBadgeName === 'language') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'LANGUAGEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === '+' && selectedBadgeName === 'time') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: { isPopUpValue: 'TIMEPOPUP', popupMode: 'ASSOCIATE_CREATE' }
        });
      }
      if (badgeName === 'distinct' && selectedBadgeName === 'share') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_DIST_SHARE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsign-on' && selectedBadgeName === 'approval') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_SIGNON_APPROVAL_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsign-on' && selectedBadgeName === 'fee') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_SIGNON_FEE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsign-on' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_SIGNON_PERMISSION_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groups' && selectedBadgeName === 'share') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_GROUP_SHARE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'nodes' && selectedBadgeName === 'share') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_NODE_SHARE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'roles' && selectedBadgeName === 'share') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_ROLE_SHARE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'types' && selectedBadgeName === 'share') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_TYPE_SHARE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === '+brand' && selectedBadgeName === 'approval') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_BRAND_APPROVAL_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === '+brand' && selectedBadgeName === 'fee') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_BRAND_FEE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === '+brand' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_BRAND_PERMISSION_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === '+brand' && selectedBadgeName === 'choice') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_BRAND_CHOICE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === '+brand' && selectedBadgeName === 'picture') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_BRAND_PICTURE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      // if (badgeName === '+domain' && selectedBadgeName === 'primary') {
      //   dispatch({
      //     type: ASSOCIATE_SIGN_ON,
      //     payload: {
      //       isPopUpValue: 'ASSOCIATE_DOMAIN_PRIMARY_POPUP',
      //       popupMode: 'ASSOCIATE_CREATE'
      //     }
      //   });
      // }
      // if (badgeName === '+domain' && selectedBadgeName === 'secondary') {
      //   dispatch({
      //     type: ASSOCIATE_SIGN_ON,
      //     payload: {
      //       isPopUpValue: 'ASSOCIATE_DOMAIN_SECONDARY_POPUP',
      //       popupMode: 'ASSOCIATE_CREATE'
      //     }
      //   });
      // }
      if (badgeName === '+theme' && selectedBadgeName === 'approval') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_THEME_APPROVAL_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === '+theme' && selectedBadgeName === 'fee') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_THEME_FEE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === '+theme' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_THEME_PERMISSION_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'nodespermissiondescendant') {
        if (selectedBadgeName === 'all') {
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: {
              stateName: 'permissionStateThree',
              value: 'associateNodeDescendantAll'
            }
          });
        }
        if (selectedBadgeName === 'primary') {
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: {
              stateName: 'permissionStateThree',
              value: 'associateNodeDescendantPrimary'
            }
          });
        }
        if (selectedBadgeName === 'secondary') {
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: {
              stateName: 'permissionStateThree',
              value: 'associateNodeDescendantSecondary'
            }
          });
        }
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'permissionStateOne', value: 'descendant' }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'permissionStateTwo',
            value: selectedBadgeName
          }
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_NODE_PERMISSION_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'nodespermissionascendant') {
        if (selectedBadgeName === 'all') {
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: {
              stateName: 'permissionStateThree',
              value: 'associateNodeAscendantAll'
            }
          });
        }
        if (selectedBadgeName === 'primary') {
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: {
              stateName: 'permissionStateThree',
              value: 'associateNodeAscendantPrimary'
            }
          });
        }
        if (selectedBadgeName === 'secondary') {
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: {
              stateName: 'permissionStateThree',
              value: 'associateNodeAscendantSecondary'
            }
          });
        }
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'permissionStateOne', value: 'ascendant' }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'permissionStateTwo',
            value: selectedBadgeName
          }
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_NODE_PERMISSION_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'nodespermissionpeer') {
        if (selectedBadgeName === 'all') {
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: {
              stateName: 'permissionStateThree',
              value: 'associateNodePeerAll'
            }
          });
        }
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'permissionStateOne', value: 'peer' }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'permissionStateTwo',
            value: selectedBadgeName
          }
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_NODE_PERMISSION_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'nodespermission') {
        if (selectedBadgeName === 'root') {
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: {
              stateName: 'permissionStateThree',
              value: 'associateNodeRoot'
            }
          });
        }
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'permissionStateOne', value: 'root' }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'permissionStateTwo',
            value: selectedBadgeName
          }
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_NODE_PERMISSION_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === '+' && selectedBadgeName === 'country') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_COUNTRY_NAME_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === '+' && selectedBadgeName === 'currency') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_CURRENCY_NAME_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
    }
  };

  const reviseSetup = (e, selectedBadgeArray) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    let badgeName = '';
    if (selectedBadgeArray?.length > 0) {
      selectedBadgeArray.forEach((element) => {
        badgeName = badgeName + element.labelTextTwoBadge;
      });
    }
    dispatch({
      type: UPDATE_SETUPSETUPPLUSFLAG,
      payload: true
    });
    // console.warn('in revise setup');
    if (labelName === 'assessees') {
      if (!associateInfo?.informationSetup.assessee) {
        dispatch({
          type: UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO,
          payload: assesseeSetUpModule
        });
      }
      if (badgeName === 'distinctcreate' && selectedBadgeName === 'approval') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEECREATEAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctcreate' && selectedBadgeName === 'fee') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEECREATEFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctcreate' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEECREATEPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctinformation' && selectedBadgeName === 'basic') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEEINFODISTINCTBASICPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctinformation' && selectedBadgeName === 'name') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'PEOPLEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsell' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEESELLAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsell' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEESELLFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsell' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEESELLPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctshare' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEESHAREAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctshare' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEESHAREFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctshare' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEESHAREPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      // if (badgeName === 'distinct' && selectedBadgeName === 'share') {
      //   dispatch({
      //     type: ASSOCIATE_SIGN_ON,
      //     payload: {
      //       isPopUpValue: 'ASSESSEE_DIST_SHARE_POPUP',
      //       popupMode: 'ASSOCIATE_CREATE'
      //     }
      //   });
      // }
      if (badgeName === 'distinctsign-on' && selectedBadgeName === 'approval') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEE_SIGNON_APPROVAL_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsign-on' && selectedBadgeName === 'fee') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEE_SIGNON_FEE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsign-on' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEE_SIGNON_PERMISSION_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      // if (badgeName === 'groups' && selectedBadgeName === 'share') {
      //   dispatch({
      //     type: ASSOCIATE_SIGN_ON,
      //     payload: {
      //       isPopUpValue: 'ASSESSEE_GROUP_SHARE_POPUP',
      //       popupMode: 'ASSOCIATE_CREATE'
      //     }
      //   });
      // }
      if (badgeName === 'types' && selectedBadgeName === 'share') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEE_TYPE_SHARE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'roles' && selectedBadgeName === 'share') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEE_ROLE_SHARE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupscreate' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEEGROUPCREATEPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupssell' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEESELLAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupssell' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEESELLFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupssell' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEESELLPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsshare' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEESHAREAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsshare' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEESHAREFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsshare' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEESHAREPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsinformation' && selectedBadgeName === 'basic') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEEBASICINFOGROUPPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'rolescreate' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEEROLECREATEPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'rolesinformation' && selectedBadgeName === 'basic') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEEBASICINFOROLEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'typescreate' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEETYPECREATEPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'typesinformation' && selectedBadgeName === 'basic') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEEBASICINFOTYPEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (labelName === 'assessees' && selectedBadgeName === 'reset') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSEERESETPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
    }
    if (labelName === 'assessments') {
      if (!associateInfo?.informationSetup.assessment) {
        dispatch({
          type: UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO,
          payload: assessmentSetUpModule
        });
      }

      if (labelName === 'assessments' && selectedBadgeName === 'reset') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENTRESETPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctcreate' && selectedBadgeName === 'approval') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENTCREATEAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctcreate' && selectedBadgeName === 'fee') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENTCREATEFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctcreate' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENTCREATEPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctinformation' && selectedBadgeName === 'basic') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENTINFODISTINCTBASICPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsell' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENTSELLAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsell' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENTSELLFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsell' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENTSELLPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctshare' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENTSHAREAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctshare' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENTSHAREFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctshare' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENTSHAREPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctshare' && selectedBadgeName === 'nodes') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENT_NODE_SHARE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctshare' && selectedBadgeName === 'associate') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENT_ASSOCIATE_SHARE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groups' && selectedBadgeName === 'share') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENT_GROUP_SHARE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'types' && selectedBadgeName === 'share') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENT_TYPE_SHARE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupscreate' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENTGROUPCREATEPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsinformation' && selectedBadgeName === 'basic') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENTBASICINFOGROUPPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupssell' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENTSELLAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupssell' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENTSELLFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupssell' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENTSELLPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsshare' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENTSHAREAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsshare' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENTSHAREFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsshare' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENTSHAREPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }

      if (badgeName === 'typescreate' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENTTYPECREATEPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'typesinformation' && selectedBadgeName === 'basic') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSESSMENTBASICINFOTYPEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
    }
    if (labelName === 'assignments') {
      if (!associateInfo?.informationSetup.assignment) {
        dispatch({
          type: UPDATE_ASSOCIATE_SETUP_ASSIGNMENT_INFO,
          payload: assignmentSetUpModule
        });
      }
      if (labelName === 'assignments' && selectedBadgeName === 'reset') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENTRESETPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctcreate' && selectedBadgeName === 'approval') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENTCREATEAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctcreate' && selectedBadgeName === 'fee') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENTCREATEFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctcreate' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENTCREATEPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsell' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENTSELLAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsell' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENTSELLFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsell' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENTSELLPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupssell' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENTSELLAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupssell' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENTSELLFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupssell' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENTSELLPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctshare' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENTSHAREAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctshare' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENTSHAREFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctshare' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENTSHAREPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsshare' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENTSHAREAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsshare' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENTSHAREFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsshare' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENTSHAREPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctinformation' && selectedBadgeName === 'basic') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENTINFODISTINCTBASICPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupscreate' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENTGROUPCREATEPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsinformation' && selectedBadgeName === 'basic') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENTBASICINFOGROUPPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'typescreate' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENTTYPECREATEPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'typesinformation' && selectedBadgeName === 'basic') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENTBASICINFOTYPEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinct' && selectedBadgeName === 'share') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENT_SHARE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groups' && selectedBadgeName === 'share') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENT_GROUP_SHARE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'types' && selectedBadgeName === 'share') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSIGNMENT_TYPE_SHARE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
    }
    if (labelName === 'items') {
      if (!associateInfo?.informationSetup.item) {
        dispatch({
          type: UPDATE_ASSOCIATE_SETUP_ITEM_INFO,
          payload: itemSetUpModule
        });
      }
      if (labelName === 'items' && selectedBadgeName === 'reset') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEMRESETPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctcreate' && selectedBadgeName === 'approval') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEMCREATEAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctcreate' && selectedBadgeName === 'fee') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEMCREATEFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctcreate' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEMCREATEPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsell' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEMSELLAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsell' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEMSELLFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsell' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEMSELLPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupssell' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEMSELLAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupssell' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEMSELLFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupssell' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEMSELLPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctshare' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEMSHAREAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctshare' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEMSHAREFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctshare' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEMSHAREPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsshare' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEMSHAREAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsshare' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEMSHAREFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsshare' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEMSHAREPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctinformation' && selectedBadgeName === 'basic') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEMINFODISTINCTBASICPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupscreate' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEMGROUPCREATEPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsinformation' && selectedBadgeName === 'basic') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEMBASICINFOGROUPPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'typescreate' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEMTYPECREATEPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'typesinformation' && selectedBadgeName === 'basic') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEMBASICINFOTYPEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }

      if (badgeName === 'distinct' && selectedBadgeName === 'share') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEM_SHARE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groups' && selectedBadgeName === 'share') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEM_GROUP_SHARE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'types' && selectedBadgeName === 'share') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ITEM_TYPE_SHARE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
    }
    if (labelName === 'iGuru analytics') {
      if (!associateInfo?.informationSetup.analytic) {
        dispatch({
          type: UPDATE_ASSOCIATE_SETUP_ANALYTIC_INFO,
          payload: analyticSetUpModule
        });
      }
      if (labelName === 'iGuru analytics' && selectedBadgeName === 'reset') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'IGURUANALYTICSRESETPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctcreate' && selectedBadgeName === 'approval') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'IGURUANALYTICSCREATEAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctcreate' && selectedBadgeName === 'fee') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'IGURUANALYTICSCREATEFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctcreate' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'IGURUANALYTICSCREATEPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsell' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'IGURUANALYTICSSELLAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsell' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'IGURUANALYTICSSELLFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsell' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'IGURUANALYTICSSELLPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupssell' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'IGURUANALYTICSSELLAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupssell' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'IGURUANALYTICSSELLFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupssell' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'IGURUANALYTICSSELLPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctshare' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'IGURUANALYTICSSHAREAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctshare' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'IGURUANALYTICSSHAREFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctshare' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'IGURUANALYTICSSHAREPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsshare' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'IGURUANALYTICSSHAREAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsshare' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'IGURUANALYTICSSHAREFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsshare' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'IGURUANALYTICSSHAREPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctinformation' && selectedBadgeName === 'basic') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'IGURUANALYTICSINFODISTINCTBASICPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupscreate' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'IGURUANALYTICSGROUPCREATEPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsinformation' && selectedBadgeName === 'basic') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'IGURUANALYTICSBASICINFOGROUPPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'typescreate' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'IGURUANALYTICSTYPECREATEPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'typesinformation' && selectedBadgeName === 'basic') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'IGURUANALYTICSBASICINFOTYPEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinct' && selectedBadgeName === 'share') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ANALYTIC_SHARE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groups' && selectedBadgeName === 'share') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ANALYTIC_GROUP_SHARE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'types' && selectedBadgeName === 'share') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ANALYTIC_TYPE_SHARE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
    }
    if (labelName === 'associates') {
      if (!associateInfo?.informationSetup.associate) {
        dispatch({
          type: UPDATE_ASSOCIATE_SETUP_INFO,
          payload: setUpAssociateModule
        });

        dispatch({
          type: UPDATE_ASSOCIATE_COUNTRY_INFO,
          payload: setUpAssociateModule?.associateCountry
        });

        dispatch({
          type: UPDATE_ASSOCIATE_CURRENCY_INFO,
          payload: setUpAssociateModule?.associateCurrency
        });
        dispatch({
          type: UPDATE_ASSOCIATE_LANGUAGE_INFO,
          payload: setUpAssociateModule?.associateLanguage
        });
      }
      if (
        badgeName === 'nodespermissiondescendant' ||
        badgeName === 'nodespermissionascendant' ||
        badgeName === 'nodespermissionpeer' ||
        badgeName === 'nodespermission'
      ) {
        if (!associateInfo?.informationSetup.associateNode) {
          dispatch({
            type: UPDATE_ASSOCIATE_SETUP_ASSOCIATENODE_INFO,
            payload: associateNodeSetUpModule
          });
        }
      }

      if (labelName === 'associates' && selectedBadgeName === 'reset') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATERESETPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctcreate' && selectedBadgeName === 'approval') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATECREATEAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctcreate' && selectedBadgeName === 'fee') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATECREATEFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctcreate' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATECREATEPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsell' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATESELLAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsell' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATESELLFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsell' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATESELLPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupssell' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATESELLAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupssell' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATESELLFEEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupssell' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATESELLPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctshare' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATESHAREAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctshare' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        // dispatch({
        //   type: ASSOCIATE_SIGN_ON,
        //   payload: {
        //     isPopUpValue: 'ASSOCIATESHAREFEEPOPUP',
        //     popupMode: 'ASSOCIATE_CREATE'
        //   }
        // });
      }
      if (badgeName === 'distinctshare' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'distinct'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATESHAREPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsshare' && selectedBadgeName === 'approval') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATESHAREAPPROVALPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsshare' && selectedBadgeName === 'fee') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        // dispatch({
        //   type: ASSOCIATE_SIGN_ON,
        //   payload: {
        //     isPopUpValue: 'ASSOCIATESHAREFEEPOPUP',
        //     popupMode: 'ASSOCIATE_CREATE'
        //   }
        // });
      }
      if (badgeName === 'groupsshare' && selectedBadgeName === 'permission') {
        dispatch({
          type: SET_SELECTED_BADGEVALUE,
          payload: 'groups'
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATESHAREPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctinformation' && selectedBadgeName === 'basic') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATEINFODISTINCTBASICPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctinformation' && selectedBadgeName === 'name') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATEPEOPLEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupscreate' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATEGROUPCREATEPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groupsinformation' && selectedBadgeName === 'basic') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATEBASICINFOGROUPPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'rolescreate' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATEROLECREATEPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'rolesinformation' && selectedBadgeName === 'basic') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATEBASICINFOROLEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'typescreate' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATETYPECREATEPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'typesinformation' && selectedBadgeName === 'basic') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATEBASICINFOTYPEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'nodescreate' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATENODECREATEPERMISSIONPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'nodesinformation' && selectedBadgeName === 'basic') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATEBASICINFONODEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === '+' && selectedBadgeName === 'date') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'DATEFORMATPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === '+' && selectedBadgeName === 'language') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'LANGUAGEPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === '+' && selectedBadgeName === 'time') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: { isPopUpValue: 'TIMEPOPUP', popupMode: 'ASSOCIATE_CREATE' }
        });
      }
      if (badgeName === 'distinct' && selectedBadgeName === 'share') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_DIST_SHARE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsign-on' && selectedBadgeName === 'approval') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_SIGNON_APPROVAL_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsign-on' && selectedBadgeName === 'fee') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_SIGNON_FEE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'distinctsign-on' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_SIGNON_PERMISSION_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'groups' && selectedBadgeName === 'share') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_GROUP_SHARE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'nodes' && selectedBadgeName === 'share') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_NODE_SHARE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'roles' && selectedBadgeName === 'share') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_ROLE_SHARE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'types' && selectedBadgeName === 'share') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_TYPE_SHARE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === '+brand' && selectedBadgeName === 'approval') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_BRAND_APPROVAL_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === '+brand' && selectedBadgeName === 'fee') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_BRAND_FEE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === '+brand' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_BRAND_PERMISSION_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === '+brand' && selectedBadgeName === 'choice') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_BRAND_CHOICE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === '+brand' && selectedBadgeName === 'picture') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_BRAND_PICTURE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      // if (badgeName === '+domain' && selectedBadgeName === 'primary') {
      //   dispatch({
      //     type: ASSOCIATE_SIGN_ON,
      //     payload: {
      //       isPopUpValue: 'ASSOCIATE_DOMAIN_PRIMARY_POPUP',
      //       popupMode: 'ASSOCIATE_CREATE'
      //     }
      //   });
      // }
      // if (badgeName === '+domain' && selectedBadgeName === 'secondary') {
      //   dispatch({
      //     type: ASSOCIATE_SIGN_ON,
      //     payload: {
      //       isPopUpValue: 'ASSOCIATE_DOMAIN_SECONDARY_POPUP',
      //       popupMode: 'ASSOCIATE_CREATE'
      //     }
      //   });
      // }
      if (badgeName === '+theme' && selectedBadgeName === 'approval') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_THEME_APPROVAL_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === '+theme' && selectedBadgeName === 'fee') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_THEME_FEE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === '+theme' && selectedBadgeName === 'permission') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_THEME_PERMISSION_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'nodespermissiondescendant') {
        if (selectedBadgeName === 'all') {
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: {
              stateName: 'permissionStateThree',
              value: 'associateNodeDescendantAll'
            }
          });
        }
        if (selectedBadgeName === 'primary') {
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: {
              stateName: 'permissionStateThree',
              value: 'associateNodeDescendantPrimary'
            }
          });
        }
        if (selectedBadgeName === 'secondary') {
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: {
              stateName: 'permissionStateThree',
              value: 'associateNodeDescendantSecondary'
            }
          });
        }
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'permissionStateOne', value: 'descendant' }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'permissionStateTwo',
            value: selectedBadgeName
          }
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_NODE_PERMISSION_POPUP', //
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'nodespermissionascendant') {
        if (selectedBadgeName === 'all') {
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: {
              stateName: 'permissionStateThree',
              value: 'associateNodeAscendantAll'
            }
          });
        }
        if (selectedBadgeName === 'primary') {
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: {
              stateName: 'permissionStateThree',
              value: 'associateNodeAscendantPrimary'
            }
          });
        }
        if (selectedBadgeName === 'secondary') {
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: {
              stateName: 'permissionStateThree',
              value: 'associateNodeAscendantSecondary'
            }
          });
        }
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'permissionStateOne', value: 'ascendant' }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'permissionStateTwo',
            value: selectedBadgeName
          }
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_NODE_PERMISSION_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'nodespermissionpeer') {
        if (selectedBadgeName === 'all') {
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: {
              stateName: 'permissionStateThree',
              value: 'associateNodePeerAll'
            }
          });
        }
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'permissionStateOne', value: 'peer' }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'permissionStateTwo',
            value: selectedBadgeName
          }
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_NODE_PERMISSION_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === 'nodespermission') {
        if (selectedBadgeName === 'root') {
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: {
              stateName: 'permissionStateThree',
              value: 'associateNodeRoot'
            }
          });
        }
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'permissionStateOne', value: 'root' }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'permissionStateTwo',
            value: selectedBadgeName
          }
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_NODE_PERMISSION_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === '+' && selectedBadgeName === 'country') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_COUNTRY_NAME_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (badgeName === '+' && selectedBadgeName === 'currency') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_CURRENCY_NAME_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
    }
  };

  const reviewSetup = (labelName, selectedBadgeName) => {
    if (labelName === 'assessees') {
      dispatch({
        type: ASSOCIATE_SIGN_ON,
        payload: {
          isPopUpValue: 'ASSESSEERESETPOPUP',
          popupMode: 'ASSOCIATE_CREATE'
        }
      });
    }
    if (labelName === 'assessments') {
      dispatch({
        type: ASSOCIATE_SIGN_ON,
        payload: {
          isPopUpValue: 'ASSESSMENTRESETPOPUP',
          popupMode: 'ASSOCIATE_CREATE'
        }
      });
    }
    if (labelName === 'assignments') {
      dispatch({
        type: ASSOCIATE_SIGN_ON,
        payload: {
          isPopUpValue: 'ASSIGNMENTRESETPOPUP',
          popupMode: 'ASSOCIATE_CREATE'
        }
      });
    }
    if (labelName === 'associates') {
      if (!associateInfo?.informationSetup.associate) {
        // dispatch({
        //   type: UPDATE_ASSOCIATE_SETUP_INFO,
        //   payload: setUpAssociateModule
        // });

        dispatch({
          type: UPDATE_ASSOCIATE_COUNTRY_INFO,
          payload: setUpAssociateModule?.associateCountry
        });

        dispatch({
          type: UPDATE_ASSOCIATE_CURRENCY_INFO,
          payload: setUpAssociateModule?.associateCurrency
        });
        dispatch({
          type: UPDATE_ASSOCIATE_LANGUAGE_INFO,
          payload: setUpAssociateModule?.associateLanguage
        });
      }
      if (labelName === 'associates' && selectedBadgeName === '') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATERESETPOPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (labelName === 'associates' && selectedBadgeName === 'country') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_COUNTRY_PICTURE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (labelName === 'associates' && selectedBadgeName === 'currency') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_CURRENCY_PICTURE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (labelName === 'associates' && selectedBadgeName === 'picture') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_BRAND_PICTURE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
      if (labelName === 'associates' && selectedBadgeName === 'choice') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: {
            isPopUpValue: 'ASSOCIATE_BRAND_PICTURE_POPUP',
            popupMode: 'ASSOCIATE_CREATE'
          }
        });
      }
    }

    if (labelName === 'items') {
      dispatch({
        type: ASSOCIATE_SIGN_ON,
        payload: {
          isPopUpValue: 'ITEMRESETPOPUP',
          popupMode: 'ASSOCIATE_CREATE'
        }
      });
    }
    if (labelName === 'iGuru analytics') {
      dispatch({
        type: ASSOCIATE_SIGN_ON,
        payload: {
          isPopUpValue: 'IGURUANALYTICSRESETPOPUP',
          popupMode: 'ASSOCIATE_CREATE'
        }
      });
    }
    if (labelName === 'iGuru marketplace') {
      dispatch({
        type: ASSOCIATE_SIGN_ON,
        payload: {
          isPopUpValue: 'IGURUMARKETPLACERESETPOPUP',
          popupMode: 'ASSOCIATE_CREATE'
        }
      });
    }
    if (labelName === 'iGuru mine') {
      dispatch({
        type: ASSOCIATE_SIGN_ON,
        payload: {
          isPopUpValue: 'IGURUMINERESETPOPUP',
          popupMode: 'ASSOCIATE_CREATE'
        }
      });
    }
  };
  // console.log('associateInfo', associateInfo);
  return (
    <div>
      {headerOneBadgeThree === 'all' ? (
        <>
          <div className="containerPadding">
            <AllocationAccordian
              headerOne="alliance"
              isDisplayCardExpanded={listExpand === 'alliance'}
              setListExpand={setListExpand}
              list={allianceListAll}
              mode={reviewMode}
              onClickRevise={reviseAlliance}
            />
          </div>
          <div className="containerPadding">
            <AllocationAccordian
              headerOne="allocation"
              isDisplayCardExpanded={listExpand === 'allocation'}
              setListExpand={setListExpand}
              list={allocationList}
              mode={reviewMode}
              onClickRevise={reviseAllocation}
            />
          </div>
          <div className="containerPadding">
            <AllocationAccordian
              headerOne="engagement"
              isDisplayCardExpanded={listExpand === 'engagement'}
              setListExpand={setListExpand}
              list={engagementListAll}
              mode={reviewMode}
              onClickRevise={reviseEngagement}
            />
          </div>
          <div className="containerPadding">
            <AllocationAccordian
              headerOne="setup"
              isDisplayCardExpanded={listExpand === 'setup'}
              setListExpand={setListExpand}
              list={setUpListAll}
              mode={reviewMode}
              onClickRevise={reviseSetup}
              onClickReview={reviewSetup}
            />
          </div>
          {signedAssesseePermission?.associateDistinctPermission
            ?.associateAssociateSetupGenericPermission?.review && (
            <div className="containerPadding">
              <AllocationAccordian
                headerOne="setup+"
                isDisplayCardExpanded={listExpand === 'setup+'}
                setListExpand={setListExpand}
                list={setUpPlusListAll}
                mode={reviewMode}
                onClickRevise={reviseSetupPlus}
              />
            </div>
          )}

          {/* <div className='containerPadding'style={{ height: '50px' }}>
          </div> */}
        </>
      ) : (
        <>
          <div className="containerPadding">
            <Paper className={'dossierContainerTop'}>
              {allocationList.map((ob) => {
                return (
                  <div key={ob.id}>
                    {ob.isListCard ? (
                      <DisplayPanelAccordianReviewListOne
                        onClickRevise={reviseAllocation}
                        className=""
                        accordianObject={ob}
                        mode={reviewMode}
                      />
                    ) : (
                      <DisplayPanelAccordianInformation
                        onClickRevise={reviseAllocation}
                        accordianObject={ob}
                        mode={reviewMode}
                      />
                    )}
                  </div>
                );
              })}
            </Paper>
          </div>
          <div className="containerPadding">
            <Paper className={'dossierContainerTop'}>
              {engagementListAll.map((ob) => {
                return (
                  <div key={ob.id}>
                    {ob.isListCard ? (
                      <DisplayPanelAccordianReviewListOne
                        onClickRevise={reviseEngagement}
                        className=""
                        accordianObject={ob}
                        mode={reviewMode}
                      />
                    ) : (
                      <DisplayPanelAccordianInformation
                        onClickRevise={reviseEngagement}
                        accordianObject={ob}
                        mode={reviewMode}
                      />
                    )}
                  </div>
                );
              })}
            </Paper>
          </div>
          {/* <div className='containerPadding' style={{ height: '50px' }}>
          </div> */}
        </>
      )}
      {isMobile && (
        <div className={'containerPadding'} style={{ height: '55px' }}>
          {' '}
        </div>
      )}
    </div>
  );
};

export default DisplayPaneThreeSectionOneAssociate;
