import { put, takeLatest, call } from 'redux-saga/effects';
import {
  LOADER_STOP,
  SET_ASSESSEE_GROUP_ASSESSEE_ID_LIST,
  SET_DISPLAY_PANE_THREE_STATE,
  SET_UNSELECTED_ASSESSEE_GROUP_ASSESSEE_ID_LIST,
  GET_JOB_GROUP_REVIEW_INFO_SAGA,
  JOB_GROUP_REVISE_INFO_SAGA,
  SET_JOB_GROUP_REDUCER_STATE,
  JOB_GROUP_JOB_REVIEWLIST_SAGA,
  SET_POPUP_VALUE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  GET_JOBPROFILE_GROUP_REVIEW_LIST_SAGA,
  SET_GROUP_SETUP_STATE,
  SET_DISPLAY_THREE_SINGLE_STATE,
  SET_GROUP_ALLOCATION_GROUP_ASCENDANT_STATE,
  SET_GROUP_ALLOCATION_STATE
} from '../../actionType';
import { JOB_MODULE_URL } from '../../endpoints';
import { EXCEPTION_ERROR_MESSAGE } from '../../errorMessage';
import Store from '../../store';

const jobProfileGroupInfoApi = async (requestObj) => {
  console.log(requestObj.data);
  let URL = JOB_MODULE_URL;
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      Authorization: localStorage.getItem('idToken')
    }),
    body: JSON.stringify(requestObj.data)
  };
  const response = await fetch(URL, requestOptions);
  const json = await response.json();
  return json;
};

function* workerReviewJobProfileGroupInfoSaga(data) {
  try {
    const userResponse = yield call(jobProfileGroupInfoApi, {
      data: data.payload.reqBody
    });
    if (userResponse.responseCode === '000') {
      const { isReviseMode = false, jobGroupJobReqBody = null, createMode = '' } = data.payload;
      if (jobGroupJobReqBody !== null) {
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
        yield put({
          type: JOB_GROUP_JOB_REVIEWLIST_SAGA,
          payload: {
            request: jobGroupJobReqBody,
            HeaderOne: 'job-profiles',
            BadgeOne: '',
            BadgeTwo: '',
            BadgeThree: '',
            isMiddlePaneList: false
          }
        });
      }
      yield put({
        type: SET_DISPLAY_PANE_THREE_STATE,
        payload: {
          headerOne: 'job-profiles',
          headerOneBadgeOne: 'group',
          headerOneBadgeTwo: 'information',
          headerOneBadgeThree: 'key',
          createMode: createMode,
          responseObject: userResponse.responseObject[0],
          reviewMode: isReviseMode ? 'revise' : ''
        }
      });
      if (isReviseMode) {
        yield put({
          type: SET_JOB_GROUP_REDUCER_STATE,
          payload: userResponse.responseObject[0].informationBasic
        });
        yield put({
          type: SET_GROUP_SETUP_STATE,
          payload: {
            objectName: 'jobProfileGroup',
            value: userResponse.responseObject[0].informationSetup
          }
        });
        let tempArr = [];
        if (
          userResponse.responseObject[0].informationAllocation &&
          userResponse.responseObject[0].informationAllocation?.jobProfileGroupAscendant
            ?.jobProfileGroupAscendantPrimary &&
          userResponse.responseObject[0].informationAllocation?.jobProfileGroupAscendant
            ?.jobProfileGroupAscendantPrimary?.length > 0
        ) {
          tempArr =
            userResponse.responseObject[0].informationAllocation?.jobProfileGroupAscendant?.jobProfileGroupAscendantPrimary.map(
              (ob) => ob.id
            );
        }
        yield put({
          type: SET_GROUP_ALLOCATION_GROUP_ASCENDANT_STATE,
          payload: {
            objectName: 'jobProfileGroup',
            stateName: 'jobProfileGroupAscendantPrimary',
            value: tempArr
          }
        });
        if (
          userResponse.responseObject[0].informationAllocation &&
          userResponse.responseObject[0].informationAllocation?.jobProfileGroupNode
            ?.jobProfileGroupNodePrimary &&
          userResponse.responseObject[0].informationAllocation?.jobProfileGroupNode
            ?.jobProfileGroupNodePrimary?.length > 0
        ) {
          let tempArr =
            userResponse.responseObject[0].informationAllocation?.jobProfileGroupNode?.jobProfileGroupNodePrimary.map(
              (ob) => ob.id
            );
          yield put({
            type: SET_GROUP_ALLOCATION_STATE,
            payload: {
              objectName: 'jobProfileGroup',
              stateName: 'jobProfileGroupNode',
              actualStateName: 'jobProfileGroupNodePrimary',
              value: tempArr
            }
          });
        }
        if (
          userResponse.responseObject[0].informationAllocation &&
          userResponse.responseObject[0].informationAllocation?.jobProfileGroupNode
            ?.jobProfileGroupNodeSecondary &&
          userResponse.responseObject[0].informationAllocation?.jobProfileGroupNode
            ?.jobProfileGroupNodeSecondary?.length > 0
        ) {
          let tempArr =
            userResponse.responseObject[0].informationAllocation?.jobProfileGroupNode?.jobProfileGroupNodeSecondary.map(
              (ob) => ob.id
            );
          yield put({
            type: SET_GROUP_ALLOCATION_STATE,
            payload: {
              objectName: 'jobProfileGroup',
              stateName: 'jobProfileGroupNode',
              actualStateName: 'jobProfileGroupNodeSecondary',
              value: tempArr
            }
          });
        }

        if (
          userResponse.responseObject[0].informationAllocation &&
          userResponse.responseObject[0].informationAllocation?.jobProfileGroupType
            ?.jobProfileGroupTypeSecondary &&
          userResponse.responseObject[0].informationAllocation?.jobProfileGroupType
            ?.jobProfileGroupTypeSecondary?.length > 0
        ) {
          let tempArr =
            userResponse.responseObject[0].informationAllocation?.jobProfileGroupType?.jobProfileGroupTypeSecondary.map(
              (ob) => ob.id
            );
          yield put({
            type: SET_GROUP_ALLOCATION_STATE,
            payload: {
              objectName: 'jobProfileGroup',
              stateName: 'jobProfileGroupType',
              actualStateName: 'jobProfileGroupTypeSecondary',
              value: tempArr
            }
          });
        }

      }
    } else {
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: userResponse.responseMessage,
          popupMode: 'responseErrorMsg'
        }
      });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
    yield put({ type: LOADER_STOP });
  }
}

function* workerReviseJobProfileGroupInfoSaga(data) {
  try {
    const userResponse = yield call(jobProfileGroupInfoApi, {
      data: data.payload.reqBody
    });
    if (userResponse.responseCode === '000') {
      const {
        createMode = '',
        jobGroupJobReqBody = null,
        cardValue = '',
        isReviseCallFromPaneTwo = false
      } = data.payload;
      if (jobGroupJobReqBody !== null) {
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
        yield put({
          type: JOB_GROUP_JOB_REVIEWLIST_SAGA,
          payload: {
            request: jobGroupJobReqBody,
            HeaderOne: 'job-profiles',
            BadgeOne: '',
            BadgeTwo: '',
            BadgeThree: '',
            isMiddlePaneList: false
          }
        });
        if (isReviseCallFromPaneTwo) {
          // yield put({
          //   type: SET_DISPLAY_THREE_SINGLE_STATE,
          //   payload: { stateName: "isFrameworkOpen", value: "jobProfileGroup" },
          // });
          yield put({
            type: SET_DISPLAY_THREE_SINGLE_STATE,
            payload: {
              stateName: 'isFrameworkOpen',
              value: 'jobProfileGroup'
            }
          });
        } else {
          yield put({
            type: SET_DISPLAY_THREE_SINGLE_STATE,
            payload: { stateName: 'isFrameworkOpen', value: '' }
          });
        }
        let tempArr = [];
        Store.getState().DisplayPaneTwoReducer.middlePaneListPopupOptions.forEach((element) => {
          if (
            element.data === 'share' &&
            userResponse.responseObject[0].informationSetup?.jobProfileGroupClassification
              ?.jobProfileGroupClassificationPrimary === 'Bespoke'
          )
            tempArr.push({ ...element, disabled: true });
          else if (
            element.data === 'share' &&
            userResponse.responseObject[0].informationSetup?.jobProfileGroupClassification
              ?.jobProfileGroupClassificationPrimary === 'Generic'
          ) {
            tempArr.push({ ...element, disabled: false });
          } else tempArr.push(element);
        });
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'middlePaneListPopupOptions',
            value: tempArr
          }
        });
        yield put({
          type: SET_DISPLAY_PANE_THREE_STATE,
          payload: {
            headerOne: 'job-profiles',
            headerOneBadgeOne: 'group',
            headerOneBadgeTwo: 'information',
            headerOneBadgeThree: 'key',
            responseObject: userResponse.responseObject[0],
            createMode,
            reviewMode: isReviseCallFromPaneTwo ? 'revise' : ''
          }
        });
      }
      if (createMode === '') {
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'reviewListDistinctData', value: [] }
        });
        yield put({
          type: GET_JOBPROFILE_GROUP_REVIEW_LIST_SAGA,
          payload: {
            HeaderOne: 'job-profiles',
            request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
            BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
            cardValue,
            isMiddlePaneList: true
          }
        });
        yield put({ type: SET_ASSESSEE_GROUP_ASSESSEE_ID_LIST, payload: [] });
        yield put({
          type: SET_UNSELECTED_ASSESSEE_GROUP_ASSESSEE_ID_LIST,
          payload: []
        });
      } else {
        yield put({ type: LOADER_STOP });
      }
    } else {
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: userResponse.responseMessage,
          popupMode: 'responseErrorMsg'
        }
      });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
    yield put({ type: LOADER_STOP });
  }
}

export default function* watchReviewJobProfileGroupInfoSaga() {
  yield takeLatest(GET_JOB_GROUP_REVIEW_INFO_SAGA, workerReviewJobProfileGroupInfoSaga);
  yield takeLatest(JOB_GROUP_REVISE_INFO_SAGA, workerReviseJobProfileGroupInfoSaga);
}
