import {
  CLEAR_DISPLAY_PANE_THREE,
  FILTERMODE,
  GET_CULTUREPROFILE_REVIEW_LIST_SAGA,
  GET_CULTUREPROFILE_GROUP_REVIEW_LIST_SAGA,
  GET_CULTUREPROFILE_TYPE_REVIEW_LIST_SAGA,
  CULTURE_GROUP_CULTURE_REVIEWLIST_SAGA,
  CULTURE_TYPE_CULTURE_REVIEWLIST_SAGA,
  GET_CULTURE_NODE_CULTURE_REVIEW_LIST_SAGA,
  LOADER_START,
  SET_MOBILE_PANE_STATE,
  SET_REQUEST_OBJECT,
  CLEAR_CULTURE_REDUCER_STATE,
  SET_POPUP_VALUE,
  SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_CORE_GROUP_REVIEW_LIST_REQ_OBJECT,
  SET_CORE_TYPE_REVIEW_LIST_REQ_OBJECT,
  SET_PAGE_COUNT,
  SET_RELATED_REQUEST_OBJECT,
  CULTURE_PROFILE_INFO_REVISE_SAGA,
  CULTURE_PROFILE_PUBLISHED_SAGA,
  CULTURE_GROUP_REVISE_INFO_SAGA,
  CULTURE_TYPE_REVISE_INFO_SAGA,
  CULTURE_ASSESSMENTS_REVIEWLIST_SAGA,
  GET_ASSIGNMENTDISTINCT_CULTURE_PROFILE_REVIEWLIST_SAGA,
  SET_POPUP_SINGLE_STATE,
  GET_CULTURE_DIAMENTION_SAGA,
  GET_ALLOCATE_CULTURE,
  CLEAR_GROUP_REDUCER_STATE,
  CULTURE_PROFILE_MULTI_STATUS_REVISE_SAGA,
  INTERNAL_NODE_LIST_WITH_PERMISSIONS_SAGA
} from '../actionType';
import {
  getCultureGroupCultureReqObj,
  getCultureGroupCultureScanReqObj,
  makeCultureProfileGroupObj,
  makeCultureProfileObj,
  makeCultureProfileTypeObj,
  makeInternalNodeObj,
  getCultureTypeCultureReqObj,
  getCultureTypeCultureScanReqObj,
  getNodeCultureProfileReqObj,
  getNodeCultureProfileScanReqObj,
  makeCultureProfileAllocateGroupObj,
  makeCultureProfileAllocateTypeObj
} from './GenericActions';

const resetDataFunction = (dispatch, cardValue = 'NoCard') => {
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'allocateStr', value: '' }
  });
  dispatch({
    type: SET_POPUP_SINGLE_STATE,
    payload: { stateName: 'cardValue', value: cardValue }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'typeOfMiddlePaneList', value: '' }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'middlePaneSelectedValue', value: '' }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'selectedFlagedArray', value: [] }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'unselectedFlagedArray', value: [] }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'selectedTagsArray', value: [] }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'unselectedTagsArray', value: [] }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'flagedValue', value: '' }
  });
  dispatch({
    type: SET_POPUP_SINGLE_STATE,
    payload: { stateName: 'selectionValue', value: '' }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'scanString', value: '' }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'allocatedTagsArray', value: [] }
  });
  dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
};
export const cultureProfileCreatePopup = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  dispatch,
  assignmentCreateType
) => {
  dispatch({ type: CLEAR_CULTURE_REDUCER_STATE });
  dispatch({ type: LOADER_START });
  let requestObj = makeCultureProfileGroupObj(selectedAssociateInfo, 'active', 0, -1, 'list', assignmentCreateType);
  dispatch({ type: SET_CORE_GROUP_REVIEW_LIST_REQ_OBJECT, payload: requestObj });
  dispatch({
    type: GET_CULTUREPROFILE_GROUP_REVIEW_LIST_SAGA,
    payload: {
      request: requestObj,
      BadgeOne: '',
      BadgeTwo: '',
      BadgeThree: '',
      isMiddlePaneList: false
    }
  });
  let nodeRequestObj = makeInternalNodeObj(selectedAssociateInfo, 'active', 0, -1, '', 'Associate-Self-Node-List-Create');
  dispatch({ type: SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT, payload: nodeRequestObj });
  dispatch({
    type: INTERNAL_NODE_LIST_WITH_PERMISSIONS_SAGA,
    payload: {
      request: nodeRequestObj,
      BadgeOne: '',
      BadgeTwo: '',
      BadgeThree: '',
      nodeViewState: 'list',
      isMiddlePaneList: false
    }
  });
  let typeRequestObj = makeCultureProfileTypeObj(selectedAssociateInfo, 'active', 0, -1);
  dispatch({ type: SET_CORE_TYPE_REVIEW_LIST_REQ_OBJECT, payload: typeRequestObj });
  dispatch({
    type: GET_CULTUREPROFILE_TYPE_REVIEW_LIST_SAGA,
    payload: {
      request: typeRequestObj,
      BadgeOne: '',
      BadgeTwo: '',
      BadgeThree: '',
      isMiddlePaneList: false
    }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'selectedInformationAllorKey',
      value: secondaryOptionCheckValue
    }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'responseObject',
      value: null
    }
  });
  resetDataFunction(dispatch, 'NoCard');
  dispatch({
    type: SET_POPUP_VALUE,
    payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'CULTURECREATE' }
    // payload: { isPopUpValue: 'POPUPCONTINUE', popupMode: 'CULTURECREATE' }
  });
};
export const getCultureProfilesDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  popupHeaderOne,
  dispatch,
  isSelectActive = ''
) => {
  let requestObj = makeCultureProfileObj(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    countPage,
    0
  );

  dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: 'cultureProfileDistinct' + secondaryOptionCheckValue }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'nodeViewState', value: '' }
  });
  // resetDataFunction(dispatch, 'NoCard');
  dispatch({
    type: GET_CULTUREPROFILE_REVIEW_LIST_SAGA,
    payload: {
      middlePaneHeader: 'culture-profiles',
      request: requestObj,
      BadgeOne: 'distinct',
      BadgeTwo: secondaryOptionCheckValue,
      BadgeThree: '',
      isMiddlePaneList: true,
      isSelectActive: isSelectActive
    }
  });
};

export const getMarketplaceCultureProfilesDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  popupHeaderOne,
  dispatch,
  isSelectActive = ''
) => {
  let requestObj = makeCultureProfileObj(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    countPage,
    0,
    'Shared-Culture-Profile-List'
  );

  dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: 'cultureProfileDistinct' + secondaryOptionCheckValue }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'nodeViewState', value: '' }
  });
  // resetDataFunction(dispatch, 'NoCard');
  dispatch({
    type: GET_CULTUREPROFILE_REVIEW_LIST_SAGA,
    payload: {
      middlePaneHeader: 'culture-profiles',
      request: requestObj,
      BadgeOne: 'distinct',
      BadgeTwo: secondaryOptionCheckValue,
      BadgeThree: '',
      isMiddlePaneList: true,
      isSelectActive: isSelectActive
    }
  });
};

export const getCultureProfileGroupApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  cardValue = 'noCard',
  isSelectActive = '',
  selectedTagsArray = '',
  groupViewState,
  isReset = false,
  assignmentCreateType
) => {
  if (secondaryOptionCheckValue !== 'active') {
    groupViewState = 'list';
  }
  let requestObj = makeCultureProfileGroupObj(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    countPage,
    0,
    groupViewState,
    assignmentCreateType
  );
  dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: 'cultureProfileGroup' + secondaryOptionCheckValue }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'nodeViewState', value: groupViewState }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  isReset && resetDataFunction(dispatch, cardValue);
  dispatch({
    type: GET_CULTUREPROFILE_GROUP_REVIEW_LIST_SAGA,
    payload: {
      request: requestObj,
      BadgeOne: targetValue,
      BadgeTwo: cardValue === 'Card' ? 'distinct' : secondaryOptionCheckValue,
      BadgeThree: cardValue === 'Card' ? secondaryOptionCheckValue : '',
      isMiddlePaneList: true,
      isSelectActive: isSelectActive,
      selectedTagsArray: selectedTagsArray,
      cardValue: cardValue,
      groupViewState
    }
  });
};
export const getMarketPlaceCultureProfileGroupApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  cardValue = 'noCard',
  isSelectActive = '',
  selectedTagsArray = '',
  groupViewState,
  isReset = false,
  assignmentCreateType,
  actionFilter=''
) => {
  if (secondaryOptionCheckValue !== 'active') {
    groupViewState = 'list';
  }
  let requestObj = makeCultureProfileGroupObj(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    countPage,
    0,
    groupViewState,
    assignmentCreateType,
    'Culture-Profile-Shared-Group'
  );
  dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: 'cultureProfileGroup' + secondaryOptionCheckValue }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'nodeViewState', value: groupViewState }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  isReset && resetDataFunction(dispatch, cardValue);
  dispatch({
    type: GET_CULTUREPROFILE_GROUP_REVIEW_LIST_SAGA,
    payload: {
      request: requestObj,
      BadgeOne: targetValue,
      BadgeTwo: cardValue === 'Card' ? 'distinct' : secondaryOptionCheckValue,
      BadgeThree: cardValue === 'Card' ? secondaryOptionCheckValue : '',
      isMiddlePaneList: true,
      isSelectActive: isSelectActive,
      selectedTagsArray: selectedTagsArray,
      cardValue: cardValue,
      groupViewState
    }
  });
};
export const getCultureProfileAllocateGroupApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  cardValue = 'noCard',
  isSelectActive = '',
  selectedTagsArray = '',
  groupViewState,
  isReset = false,
  assignmentCreateType,
  allocatedTagsArray
) => {
  if (secondaryOptionCheckValue !== 'active' || isSelectActive === 'multiple') {
    groupViewState = 'list';
  }
  let requestObj = makeCultureProfileAllocateGroupObj(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    countPage,
    0,
    groupViewState,
    assignmentCreateType,
    allocatedTagsArray
  );
  dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: 'cultureProfileGroup' + secondaryOptionCheckValue }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'nodeViewState', value: groupViewState }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  isReset && resetDataFunction(dispatch, cardValue);
  dispatch({
    type: GET_CULTUREPROFILE_GROUP_REVIEW_LIST_SAGA,
    payload: {
      request: requestObj,
      BadgeOne: targetValue,
      BadgeTwo: cardValue === 'Card' ? 'distinct' : secondaryOptionCheckValue,
      BadgeThree: cardValue === 'Card' ? secondaryOptionCheckValue : '',
      isMiddlePaneList: true,
      isSelectActive: isSelectActive,
      selectedTagsArray: selectedTagsArray,
      cardValue: cardValue,
      groupViewState
    }
  });
};
export const getCultureProfileTypeApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  middlePaneHeader = 'culture-profile',
  cardValue = 'noCard',
  isSelectActive = '',
  selectedTagsArray = [],
  isResetFun = true
) => {
  let requestObj = makeCultureProfileTypeObj(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    countPage,
    0
  );

  dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: 'cultureProfileTypeDistinct' + secondaryOptionCheckValue }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'nodeViewState', value: '' }
  });
  isResetFun && resetDataFunction(dispatch, cardValue);
  dispatch({
    type: GET_CULTUREPROFILE_TYPE_REVIEW_LIST_SAGA,
    payload: {
      middlePaneHeader: middlePaneHeader,
      request: requestObj,
      BadgeOne: targetValue,
      BadgeTwo: cardValue === 'Card' ? 'distinct' : secondaryOptionCheckValue,
      BadgeThree: cardValue === 'Card' ? secondaryOptionCheckValue : '',
      isMiddlePaneList: true,
      isSelectActive: isSelectActive,
      cardValue: cardValue,
      selectedTagsArray: selectedTagsArray
    }
  });
};
export const getMarketPlaceCultureProfileTypeApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  middlePaneHeader = 'culture-profile',
  cardValue = 'noCard',
  isSelectActive = '',
  selectedTagsArray = [],
  isResetFun = true
) => {
  let requestObj = makeCultureProfileTypeObj(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    countPage,
    0,
    'Shared-Culture-Profile-Type'
  );

  dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: 'cultureProfileTypeDistinct' + secondaryOptionCheckValue }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'nodeViewState', value: '' }
  });
  isResetFun && resetDataFunction(dispatch, cardValue);
  dispatch({
    type: GET_CULTUREPROFILE_TYPE_REVIEW_LIST_SAGA,
    payload: {
      middlePaneHeader: middlePaneHeader,
      request: requestObj,
      BadgeOne: targetValue,
      BadgeTwo: cardValue === 'Card' ? 'distinct' : secondaryOptionCheckValue,
      BadgeThree: cardValue === 'Card' ? secondaryOptionCheckValue : '',
      isMiddlePaneList: true,
      isSelectActive: isSelectActive,
      cardValue: cardValue,
      selectedTagsArray: selectedTagsArray
    }
  });
};
export const getCultureProfileAllocateTypeApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  middlePaneHeader = 'culture-profile',
  cardValue = 'noCard',
  isSelectActive = '',
  selectedTagsArray = [],
  isResetFun = true,
  allocatedTagsArray
) => {
  let requestObj = makeCultureProfileAllocateTypeObj(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    countPage,
    0,
    allocatedTagsArray
  );

  dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: 'cultureProfileTypeDistinct' + secondaryOptionCheckValue }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'nodeViewState', value: '' }
  });
  isResetFun && resetDataFunction(dispatch, cardValue);
  dispatch({
    type: GET_CULTUREPROFILE_TYPE_REVIEW_LIST_SAGA,
    payload: {
      middlePaneHeader: middlePaneHeader,
      request: requestObj,
      BadgeOne: targetValue,
      BadgeTwo: cardValue === 'Card' ? 'distinct' : secondaryOptionCheckValue,
      BadgeThree: cardValue === 'Card' ? secondaryOptionCheckValue : '',
      isMiddlePaneList: true,
      isSelectActive: isSelectActive,
      selectedTagsArray: selectedTagsArray
    }
  });
};
export const getCultureGroupCultureDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  selectedTagValue,
  searchStr,
  isScan
) => {
  let reqBody = getCultureGroupCultureReqObj(
    selectedAssociateInfo,
    selectedTagValue,
    secondaryOptionCheckValue,
    0,
    countPage
  );
  if (isScan) {
    reqBody = getCultureGroupCultureScanReqObj(
      selectedAssociateInfo,
      selectedTagValue,
      secondaryOptionCheckValue,
      0,
      countPage,
      searchStr
    );
  }
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'relatedReviewListObjectCopy',
      value: reqBody
    }
  });
  // dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({
    type: SET_RELATED_REQUEST_OBJECT,
    payload: reqBody
  });
  dispatch({ type: LOADER_START });
  // dispatch({ type: SET_REQUEST_OBJECT, payload: reqBody });
  dispatch({
    type: CULTURE_GROUP_CULTURE_REVIEWLIST_SAGA,
    payload: {
      request: reqBody,
      HeaderOne: 'culture-profiles',
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      BadgeThree: '',
      isMiddlePaneList: true
    }
  });
};
export const getCultureTypeCultureDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  selectedTagValue,
  searchStr,
  isScan
) => {
  let reqBody = getCultureTypeCultureReqObj(
    selectedAssociateInfo,
    selectedTagValue,
    secondaryOptionCheckValue,
    0,
    countPage
  );
  if (isScan) {
    reqBody = getCultureTypeCultureScanReqObj(
      selectedAssociateInfo,
      selectedTagValue,
      secondaryOptionCheckValue,
      0,
      countPage,
      searchStr
    );
  }
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'relatedReviewListObjectCopy',
      value: reqBody
    }
  });

  // dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({
    type: SET_RELATED_REQUEST_OBJECT,
    payload: reqBody
  });
  dispatch({ type: LOADER_START });
  // dispatch({ type: SET_REQUEST_OBJECT, payload: reqBody });
  dispatch({
    type: CULTURE_TYPE_CULTURE_REVIEWLIST_SAGA,
    payload: {
      request: reqBody,
      HeaderOne: 'culture-profiles',
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      BadgeThree: '',
      isMiddlePaneList: true
    }
  });
};

export const getCultureProfileNodeCultureProfileApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  selectedTagValue,
  searchStr,
  isScan,
  middlePaneHeader,
  isMiddlePaneList = true,
  actionFilter = "",
) => {
  let reqBody = getNodeCultureProfileReqObj(
    selectedAssociateInfo,
    selectedTagValue,
    secondaryOptionCheckValue,
    0,
    countPage,
    actionFilter
  );
  if (isScan) {
    reqBody = getNodeCultureProfileScanReqObj(
      selectedAssociateInfo,
      selectedTagValue,
      secondaryOptionCheckValue,
      0,
      countPage,
      searchStr,
      actionFilter
    );
  }
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'relatedReviewListObjectCopy',
      value: reqBody
    }
  });
  // dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  if (isMiddlePaneList) {
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'relatedReviewListDistinctData', value: [] }
    });
  }
  dispatch({ type: LOADER_START });
  // dispatch({ type: SET_REQUEST_OBJECT, payload: reqBody });
  dispatch({
    type: GET_CULTURE_NODE_CULTURE_REVIEW_LIST_SAGA,
    payload: {
      request: reqBody,
      HeaderOne: middlePaneHeader,
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      BadgeThree: '',
      isMiddlePaneList: isMiddlePaneList
    }
  });
};

export const updateCultureProfileDistinctStatus = (
  selectedAssociateInfo,
  cultureProfileId,
  dispatch,
  reviseStatus,
  createMode,
  typeOfMiddlePaneList
) => {
  let reqBody;
  if (reviseStatus === 'PUBLISHED') {
    reqBody = {
      module: 'Culture-Profile-Distinct',
      action: 'Publish',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        cultureProfileId: cultureProfileId
      }
    };
    dispatch({ type: LOADER_START });
    dispatch({
      type: CULTURE_PROFILE_MULTI_STATUS_REVISE_SAGA,
      payload: {
        secondaryOptionCheckValue: '',
        isHideRightPane: true,
        headerOne: '',
        createMode,
        reqBody
      }
    });
  } else {
    reqBody = {
      module: 'Culture-Profile-Distinct',
      action: 'Revise',
      actionFilter: 'Status',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        cultureProfileDistinct: [cultureProfileId],
        reviseStatus: reviseStatus
      },
      createMode: createMode
    };
    dispatch({ type: LOADER_START });
    dispatch({
      type: CULTURE_PROFILE_MULTI_STATUS_REVISE_SAGA,
      payload: {
        secondaryOptionCheckValue: '',
        isHideRightPane: true,
        headerOne: '',
        createMode,
        reqBody,
        typeOfMiddlePaneList
      }
    });
  }
};
export const updateCultureProfileGroupStatus = (
  selectedAssociateInfo,
  groupId,
  dispatch,
  reviseStatus
) => {
  let reqBody = {
    module: 'Culture-Profile-Group',
    action: 'Revise',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      cultureProfileGroup: {
        id: groupId,
        informationEngagement: {
          cultureProfileGroupStatus:
            reviseStatus === 'UNSUSPENDED' ||
              reviseStatus === 'UNTERMINATED' ||
              reviseStatus === 'UNARCHIVED'
              ? 'ACTIVE'
              : reviseStatus
        }
      }
    }
  };
  dispatch({ type: LOADER_START });
  dispatch({
    type: CULTURE_GROUP_REVISE_INFO_SAGA,
    payload: {
      secondaryOptionCheckValue: '',
      cultureGroupCultureReqBody: null,
      headerOne: '',
      reqBody
    }
  });
};
export const updateCultureProfileTypeStatus = (
  selectedAssociateInfo,
  typeId,
  dispatch,
  reviseStatus
) => {
  let reqBody = {
    module: 'Culture-Profile-Type',
    action: 'Revise',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      cultureProfileType: {
        id: typeId,
        informationEngagement: {
          cultureProfileTypeStatus:
            reviseStatus === 'UNSUSPENDED' ||
              reviseStatus === 'UNTERMINATED' ||
              reviseStatus === 'UNARCHIVED'
              ? 'ACTIVE'
              : reviseStatus
        }
      }
    }
  };
  dispatch({ type: LOADER_START });
  dispatch({
    type: CULTURE_TYPE_REVISE_INFO_SAGA,
    payload: {
      secondaryOptionCheckValue: '',
      cultureTypeCultureReqBody: null,
      headerOne: '',
      reqBody
    }
  });
};
export const getAssignmneCultureProfileDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  selectedTagValue,
  searchStr,
  isScan,
  reviewMode
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: reviewMode === 'review' ? 'ACTIVE' : secondaryOptionCheckValue.toUpperCase(),
    }
  };
  if (secondaryOptionCheckValue === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED']
      }
    };
  }
  let reqBody = null;
  if (isScan) {
    reqBody = {
      module: 'Assignment-Distinct',
      action: 'Review-List',
      actionFilter: 'Assignment-Culture-Profile',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        assignmentId: selectedTagValue,
        numberPage: 0,
        countPage: countPage,
        searchCondition: 'AND',
        filter: true,
        orderBy: {
          columnName:
            'informationBasic.cultureProfileName,informationBasic.cultureProfileDescription',
          order: 'asc'
        },
        search: [
          {
            condition: 'or',
            searchBy: [
              {
                dataType: 'string',
                conditionColumn: 'informationBasic.cultureProfileName',
                conditionValue: {
                  condition: 'ct',
                  value: {
                    from: searchStr
                  }
                }
              },
              {
                dataType: 'string',
                conditionColumn: 'informationBasic.cultureProfileDescription',
                conditionValue: {
                  condition: 'ct',
                  value: {
                    from: searchStr
                  }
                }
              },
              {
                dataType: 'string',
                conditionColumn: 'informationEngagement.cultureProfileTag.cultureProfileTagPrimary',
                conditionValue: {
                  condition: 'ct',
                  value: {
                    from: searchStr
                  }
                }
              }
            ]
          },
          {
            condition: 'or',
            searchBy: [
              {
                dataType: 'string',
                conditionColumn: 'informationEngagement.cultureProfileStatus',
                conditionValue: searchObj
              }
            ]
          }
        ]
      }
    };
  }
  else {
    reqBody = {
      module: 'Assignment-Distinct',
      action: 'Review-List',
      actionFilter: 'Assignment-Culture-Profile',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        assignmentId: selectedTagValue,
        numberPage: 0,
        countPage: countPage,
        searchCondition: 'AND',
        filter: true,
        orderBy: {
          columnName:
            'informationBasic.cultureProfileName,informationBasic.cultureProfileDescription',
          order: 'asc'
        },
        search: [
          {
            condition: 'or',
            searchBy: [
              {
                dataType: 'string',
                conditionColumn: 'informationEngagement.cultureProfileStatus',
                conditionValue: searchObj
              }
            ]
          }
        ]
      }
    };
  }

  // dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({
    type: SET_RELATED_REQUEST_OBJECT,
    payload: reqBody
  });
  dispatch({ type: LOADER_START });
  // dispatch({ type: SET_REQUEST_OBJECT, payload: reqBody });
  dispatch({
    type: GET_ASSIGNMENTDISTINCT_CULTURE_PROFILE_REVIEWLIST_SAGA,
    payload: {
      request: reqBody,
      HeaderOne: 'culture-profiles',
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      BadgeThree: '',
      isMiddlePaneList: true
    }
  });
};
export const getCultureProfileAssessmentDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  selectedTagValue,
  isSelectActive,
  isScan
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: secondaryOptionCheckValue.toUpperCase()
    }
  };
  if (secondaryOptionCheckValue === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'ARCHIVED', 'UNPUBLISHED']
      }
    };
  }
  let reqBody = {
    module: 'Culture-Profile-Distinct',
    action: 'Review-List',
    actionFilter: 'Culture-Profile-Assessment',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      numberPage: 0,
      countPage: countPage,
      searchCondition: 'AND',
      cultureProfileId: selectedTagValue,
      orderBy: {
        columnName: 'informationBasic.assessmentName,informationBasic.assessmentDescription',
        order: 'asc'
      },
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assessmentStatus',
              conditionValue: searchObj
            }
          ]
        }
      ]
    }
  };

  // dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({
    type: SET_RELATED_REQUEST_OBJECT,
    payload: reqBody
  });
  dispatch({ type: LOADER_START });
  // dispatch({ type: SET_REQUEST_OBJECT, payload: reqBody });
  dispatch({
    type: CULTURE_ASSESSMENTS_REVIEWLIST_SAGA,
    payload: {
      request: reqBody,
      HeaderOne: 'assessments',
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      BadgeThree: '',
      isMiddlePaneList: true,
      isSelectActive: isSelectActive
    }
  });
};
export const getCultureProfileDiamentionList = (selectedAssociateInfo, requestType, dispatch) => {
  dispatch({ type: LOADER_START });
  let diamentionReqBody = {
    module: 'Profiler',
    action: 'Create',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      action: requestType,
      sorted: true
    }
  };
  dispatch({ type: GET_CULTURE_DIAMENTION_SAGA, payload: { request: diamentionReqBody } });
};
export const updateCultureProfileFlagUpdate = (
  selectedAssociateInfo,
  Id,
  dispatch,
  reviseStatus,
  type,
  flagVal
) => {
  let reqBody = {
    module:
      (type === 'cultureProfileType' && 'Culture-Profile-Type') ||
      (type === 'cultureProfileGroup' && 'Culture-Profile-Group') ||
      (type === 'cultureProfile' && 'Culture-Profile-Distinct'),
    action: 'Revise',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      [type]: {
        id: Id,
        informationBasic: {
          [flagVal]: reviseStatus
        }
      }
    }
  };
  dispatch({ type: LOADER_START });
  dispatch({
    type:
      type === 'cultureProfileType'
        ? CULTURE_TYPE_REVISE_INFO_SAGA
        : type === 'cultureProfileGroup'
          ? CULTURE_GROUP_REVISE_INFO_SAGA
          : type === 'cultureProfile'
            ? CULTURE_PROFILE_INFO_REVISE_SAGA
            : '',
    payload: {
      secondaryOptionCheckValue: '',
      isHideRightPane: true,
      headerOne: '',
      reqBody,
      createMode: '',
      cultureGroupCultureReqBody: null,
      cultureTypeCultureReqBody: null
    }
  });
};

export const cultureProfileGroupReviseApicall = (
  responseObject,
  selectedAssociateInfo,
  selectedTagsArray,
  unselectedTagsArray,
  cultureProfileGroup,
  dispatch,
  createMode,
  isReviseCallFromPaneTwo = false,
  cardValue = 'noCard',
) => {
  const { associateId, id } = responseObject;
  const reqBody = {
    module: 'Culture-Profile-Group',
    action: 'Revise',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      cultureProfileGroupCultureProfile: {
        cultureProfileGroupCultureProfileAllocate: selectedTagsArray || [],
        cultureProfileGroupCultureProfileUnallocate: unselectedTagsArray || []
      },
      cultureProfileGroup: {
        id,
        informationBasic: cultureProfileGroup.informationBasic,
        informationSetup: cultureProfileGroup.informationSetup,
        informationAllocation: cultureProfileGroup.informationAllocation
      }
    }
  };
  dispatch({ type: LOADER_START });
  let cultureGroupCultureReqBody = getCultureGroupCultureReqObj(
    selectedAssociateInfo,
    id,
    '', //'all',
    -1,
    -1
  );
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'relatedReviewListDistinctData', value: [] }
  });
  dispatch({
    type: CULTURE_GROUP_REVISE_INFO_SAGA,
    payload: {
      headerOne: 'culture-profiles',
      reqBody,
      cultureGroupCultureReqBody,
      createMode,
      cardValue,
      isReviseCallFromPaneTwo: isReviseCallFromPaneTwo
    }
  });
};
export const cultureProfileTypeReviseApicall = (
  responseObject,
  selectedAssociateInfo,
  selectedTagsArray,
  unselectedTagsArray,
  cultureProfileType,
  dispatch,
  createMode,
  isReviseCallFromPaneTwo = false
) => {
  const { associateId, id } = responseObject;
  const reqBody = {
    module: 'Culture-Profile-Type',
    action: 'Revise',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId,
      cultureProfileTypeCultureProfile: {
        cultureProfileTypeCultureProfileAllocate: selectedTagsArray || [],
        cultureProfileTypeCultureProfileUnallocate: unselectedTagsArray || []
      },
      cultureProfileType: {
        id,
        informationBasic: cultureProfileType.informationBasic,
        informationAllocation: cultureProfileType.informationAllocation,
        informationSetup: cultureProfileType.informationSetup
      }
    }
  };
  dispatch({ type: LOADER_START });
  let cultureTypeCultureReqBody = getCultureTypeCultureReqObj(
    selectedAssociateInfo,
    id,
    '',
    -1,
    -1
  );
  dispatch({
    type: CULTURE_TYPE_REVISE_INFO_SAGA,
    payload: {
      headerOne: 'culture-profiles',
      reqBody,
      cultureTypeCultureReqBody,
      createMode,
      isReviseCallFromPaneTwo: isReviseCallFromPaneTwo
    }
  });
};
export const getCultureProfileAllocate = (
  dispatch,
  requestObect,
  revisedTypeObject,
  relatedReviewListPaneThree,
  filterMode,
  typeOfMiddlePaneList,
  selectedTagsArray = []
) => {
  let existingCultureProfileId = [];
  if (relatedReviewListPaneThree?.length > 0) {
    existingCultureProfileId =
      (relatedReviewListPaneThree[0].cultureProfile?.length > 0 &&
        relatedReviewListPaneThree[0].cultureProfile.map((val) => {
          return val.id;
        })) ||
      [];
  }
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: filterMode }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'relatedReviewListDistinctData', value: [] }
  });
  dispatch({ type: LOADER_START });
  //set requestObject
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'relatedReviewListObjectCopy',
      value: requestObect
    }
  });
  // Get list for pagination
  dispatch({
    type: GET_ALLOCATE_CULTURE,
    payload: {
      request: requestObect,
      //BadgeTwo: 'all',
      revisedGroupObject: revisedTypeObject,
      existingCultureProfileId: [...existingCultureProfileId, ...selectedTagsArray],
      typeOfMiddlePaneList: typeOfMiddlePaneList
    }
  });
};
export const getCultureProfileGroupCreateAPI = (selectedAssociateInfo, dispatch, moduleName, assignmentCreateType) => {
  let requestObj = makeCultureProfileGroupObj(selectedAssociateInfo, 'active', 0, -1, 'list', assignmentCreateType);
  dispatch({ type: SET_CORE_GROUP_REVIEW_LIST_REQ_OBJECT, payload: requestObj });
  dispatch({
    type: GET_CULTUREPROFILE_GROUP_REVIEW_LIST_SAGA,
    payload: {
      request: requestObj,
      BadgeOne: '',
      BadgeTwo: '',
      BadgeThree: '',
      isMiddlePaneList: false
    }
  });
  let nodeRequestObj = makeInternalNodeObj(selectedAssociateInfo, 'active', 0, -1, '', 'Associate-Self-Node-List-Create');
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT, payload: nodeRequestObj });
  dispatch({
    type: INTERNAL_NODE_LIST_WITH_PERMISSIONS_SAGA,
    payload: {
      request: nodeRequestObj,
      BadgeOne: '',
      BadgeTwo: '',
      BadgeThree: '',
      nodeViewState: 'list',
      isMiddlePaneList: false
    }
  });
  let typeRequestObj = makeCultureProfileTypeObj(selectedAssociateInfo, 'active', 0, -1);
  dispatch({ type: SET_CORE_TYPE_REVIEW_LIST_REQ_OBJECT, payload: typeRequestObj });
  dispatch({
    type: GET_CULTUREPROFILE_TYPE_REVIEW_LIST_SAGA,
    payload: {
      request: typeRequestObj,
      BadgeOne: '',
      BadgeTwo: '',
      BadgeThree: '',
      isMiddlePaneList: false
    }
  });
  dispatch({ type: CLEAR_GROUP_REDUCER_STATE });
  dispatch({
    type: SET_POPUP_VALUE,
    payload: { isPopUpValue: 'NAMEPOPUP', popupMode: moduleName + 'GROUPCREATE' }
  });
};
