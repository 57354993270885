import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import AllocationAccordian from '../Accordian/AllocationAccordian';
import { useDispatch, useSelector } from 'react-redux';
import { Paper } from '@material-ui/core';
import DisplayPanelAccordianReviewListOne from '../Accordian/DisplayPanelAccordianReviewListOne';
import DisplayPanelAccordianInformation from '../Accordian/DisplayPanelAccordianInformation';
import CultureWeightageTableTemplate from '../Accordian/CultureWeightageTableTemplate';
import { SET_POPUP_VALUE } from '../../actionType';
import {
  getCultureProfileAssessmentDistinctApiCall,
  getCultureProfileDiamentionList
} from '../../Actions/ActionCultureProfile';
import DisplayPanelAccordianReviewListTwo from '../Accordian/DisplayPanelAccordianReviewListTwo';

const DisplayPaneThreeSectionTwoCultureProfileDistinct = () => {
  const {
    headerOneBadgeTwo,
    headerOneBadgeThree,
    reviewMode,
    isWeightageSelected = false,
    responseObject,
    relatedReviewListPaneThree = [],
    countPage
  } = useSelector((state) => state.DisplayPaneThreeReducer);
  const { selectedAssociateInfo } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { selectedTagValue } = useSelector((state) => state.PopUpReducer);
  const dispatch = useDispatch();
  let selectedList = isWeightageSelected ? 'framework' : '';
  const [listExpand, setListExpand] = useState(selectedList);
  const { cultureProfileInformation } = useSelector((state) => state.CultureProfileCreateReducer);

  useEffect(() => {
    if (isWeightageSelected) {
      setListExpand('framework');
    }
  }, [responseObject, isWeightageSelected]);

  let assessmentList =
    relatedReviewListPaneThree?.assessment ||
    responseObject?.informationFramework?.cultureProfileAssessment ||
    [];
  let assessmentArray = [];
  assessmentList.forEach((ob) => {
    const { id, informationBasic } = ob;
    assessmentArray.push({
      id,
      textOne: informationBasic?.assessmentName || '',
      textTwo: informationBasic?.assessmentDescription || '',
      status: ''
    });
  });

  let cultureCoreList = [];
  const tempCoreList =
    responseObject?.informationFramework?.cultureProfileCultureDimensionSelectList || [];
  if (tempCoreList) {
    tempCoreList.forEach((ob) => {
      if (
        ob.iGaugeOccupationFrameworkOneClusterSelected ===
        ob.iGaugeOccupationFrameworkOneClusterOneLabel
      ) {
        cultureCoreList.push({
          id: ob.iGaugeOccupationFrameworkOneTag,
          textOne: ob?.iGaugeOccupationFrameworkOneClusterOneLabel || '',
          textTwo: ob?.iGaugeOccupationFrameworkOneClusterOneDescription,
          status: ''
        });
      }
      if (
        ob.iGaugeOccupationFrameworkOneClusterSelected ===
        ob.iGaugeOccupationFrameworkOneClusterTwoLabel
      ) {
        cultureCoreList.push({
          id: ob.iGaugeOccupationFrameworkOneTag,
          textOne: ob?.iGaugeOccupationFrameworkOneClusterTwoLabel || '',
          textTwo: ob?.iGaugeOccupationFrameworkOneClusterTwoDescription,
          status: ''
        });
      }
    });
  }

  const frameworkAll = [
    {
      id: 'CP-Assessment001',
      labelTextOneOne: 'assessments',
      isListCard: true,
      labelTextOneOneBadgeOne: 'distinct',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'distinct',
          innerList: assessmentArray
        }
      ],
      innerAssociateList: [],
      innerInfo: 'No Information',
      IconOne: null
    },
    {
      id: 'a1',
      labelTextOneOne: 'culture-dimensions',
      labelTextOneOneBadgeOne: 'select-list',
      labelTextOneOneBadgeTwo: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'select-list',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'distinct',
              innerList: cultureCoreList.sort((a, b) =>
                a.textOne !== b.textOne ? (a.textOne < b.textOne ? -1 : 1) : 0
              )
            },
            {
              labelTextTwoBadge: 'weightage',
              innerList: []
            }
          ]
        }
      ],
      isListCard: true,
      isMultiList: true,
      innerAssociateList: [],
      innerInfo: 'No Information',
      IconOne: null
    }
  ];
  const reviseFramework = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    const selectedInnerBadgeName = e.currentTarget.getAttribute('id');
    console.log('labelName', labelName);
    console.log('selectedBadgeName', selectedBadgeName);
    if (
      labelName === 'culture-dimensions' &&
      selectedBadgeName === 'select-list' &&
      selectedInnerBadgeName === 'distinct'
    ) {
      getCultureProfileDiamentionList(selectedAssociateInfo, 'Review-List', dispatch);
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'POPUPDIAMENTIONMSG', popupMode: 'CULTURECREATE' }
      });
    }
    if (labelName === 'assessments' && selectedBadgeName === 'distinct') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'POPUPASSESSMENTPOPUP', popupMode: 'CULTURECREATE' }
      });
      // let existingAssessmentId = [];
      // existingAssessmentId =
      //   (relatedReviewListPaneThree &&
      //     relatedReviewListPaneThree?.assessment?.length > 0 &&
      //     relatedReviewListPaneThree.assessment.map((val) => {
      //       return val.id;
      //     })) ||
      //   [];
      // getCultureProfileAssessmentDistinctApiCall(
      //   selectedAssociateInfo,
      //   'all',
      //   countPage,
      //   dispatch,
      //   'distinct',
      //   selectedTagValue,
      //   '',
      //   false
      // );
    }
  };
  console.log('INFORMATION FRAMEWORK', cultureProfileInformation.informationFramework);
  return (
    <div>
      {headerOneBadgeThree === 'all' ? (
        <>
          <div className={'containerPadding'}>
            <AllocationAccordian
              headerOne="framework"
              isDisplayCardExpanded={listExpand === 'framework'}
              setListExpand={setListExpand}
              onClickRevise={reviseFramework}
              list={frameworkAll}
              mode={reviewMode}
            />
          </div>
        </>
      ) : (
        <>
          <div className={'containerPadding'}>
            <Paper className={'dossierContainerTop'}>
              {frameworkAll.map((ob) => {
                return (
                  <div key={ob.id}>
                    {ob.isListCard ? (
                      <>
                        {ob.isMultiList ? (
                          <DisplayPanelAccordianReviewListTwo
                            onClickReview={null}
                            onClickRevise={reviseFramework}
                            accordianObject={ob}
                            mode={reviewMode}
                          />
                        ) : (
                          <DisplayPanelAccordianReviewListOne
                            onClickRevise={reviseFramework}
                            className=""
                            accordianObject={ob}
                            mode={reviewMode}
                          />
                        )}
                      </>
                    ) : (
                      // <DisplayPanelAccordianReviewListOne
                      //   onClickRevise={reviseFramework}
                      //   className=""
                      //   accordianObject={ob}
                      //   mode={reviewMode}
                      // />
                      <DisplayPanelAccordianInformation
                        onClickRevise={reviseFramework}
                        accordianObject={ob}
                        mode={reviewMode}
                      />
                    )}
                  </div>
                );
              })}
            </Paper>
          </div>
        </>
      )}
      {isMobile && (
        <div className={'containerPadding'} style={{ height: '55px' }}>
          {' '}
        </div>
      )}
    </div>
  );
};

export default DisplayPaneThreeSectionTwoCultureProfileDistinct;