import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import AllocationAccordian from '../Accordian/AllocationAccordian';
import { useDispatch, useSelector } from 'react-redux';
import { Paper } from '@material-ui/core';
import DisplayPanelAccordianReviewListOne from '../Accordian/DisplayPanelAccordianReviewListOne';
import DisplayPanelAccordianInformation from '../Accordian/DisplayPanelAccordianInformation';
import {
  GET_FRAMWORK_TYPE_REVIEW_LIST_SAGA,
  LOADER_START,
  SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
  SET_MOBILE_PANE_STATE,
  SET_PANE_THREE_ITEM_PREVIEW_MODE,
  SET_POPUP_VALUE
} from '../../actionType';
import { convertSecondsToHMmSs, setItemTypeConfigState } from '../../Actions/GenericActions';

const DisplayPaneThreeSectionTwoItem = () => {
  const [listExpand, setListExpand] = useState('');
  const { responseObject,headerOneBadgeTwo, headerOneBadgeThree, reviewMode } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  const { selectedAssociateInfo } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { itemTypeList } = useSelector((state) => state.ItemCreateReducer);
  const itemFrameworkOne = responseObject?.informationFramework?.itemFrameworkOne;
  const dispatch = useDispatch();
  // const { informationContact, informationCredential, informationFramework } = responseObject;
  const datatypeItem = itemTypeList.find(
    (item) => item.id === responseObject.informationFramework?.itemFrameworkOne.itemFrameworkOneType
  );
  let clusterObj = [];
  if (itemFrameworkOne) {
    const tempArr = itemFrameworkOne?.itemFrameworkOneCluster || [];
    tempArr.forEach((ob) => {
      clusterObj.push({
        textOne: ob.itemFrameworkOneClusterPrimary || '',
        textTwo: '',
        status: ''
      });
    });
  }
  let scaleObj = [];
  if (itemFrameworkOne) {
    const tempArr = itemFrameworkOne?.itemFrameworkOneScale || [];
    tempArr.forEach((ob) => {
      scaleObj.push({
        textOne: ob.itemFrameworkOneScale || '',
        textTwo: '',
        status: ob.itemFrameworkOneScaleWeightage
      });
    });
  }
  const frameworkList = [
    // {
    //   id: 'a22',
    //   labelTextOneOne: 'cluster',
    //   labelTextOneOneBadgeOne: 'distinct',
    //   labelTextOneOneBadgeTwo: '',
    //   labelTextOneOneBadgeThree: '',
    //   labelTextOneOneBadgeFour: '',
    //   labelTextOneOneBadges: [
    //     {
    //       labelTextOneOneBadge: 'distinct',
    //       innerList: clusterObj
    //     }
    //   ],
    //   innerInfo: 'No Information',
    //   isListCard: true
    // },
    {
      id: 'a-level',
      labelTextOneOne: 'level',
      isListCard: false,
      textOneOne: itemFrameworkOne?.itemFrameworkOneLevel || 'No Information',
      innerAssociateList: [],
      innerInfo: 'No Information',
      IconOne: null
    },
    {
      id: 'a1-preview',
      labelTextOneOne: 'preview',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: '',
          textOne: ''
        }
      ],
      innerAssociateList: [],
      innerInfo: 'No Information',
      isListCard: false,
      IconOne: null,
      IconTwo: null,
      isReviewLink: true
    },
    {
      id: 'a3score',
      labelTextOneOne: 'score',
      isListCard: false,
      textOneOne: itemFrameworkOne?.itemFrameworkOneScore || 'No Information',
      innerAssociateList: [],
      innerInfo: 'No Information',
      IconOne: null
    },
    {
      id: 'a4time',
      labelTextOneOne: 'time',
      isListCard: false,
      textOneOne:  convertSecondsToHMmSs(itemFrameworkOne?.itemFrameworkOneTime ) || 'No Information',
      innerAssociateList: [],
      innerInfo: 'No Information',
      IconOne: null
    },
    {
      id: 'a5type',
      labelTextOneOne: 'type',
      isListCard: false,
      textOneOne: datatypeItem?.itemFrameworkOneTypeNameReference || 'No Information',
      innerAssociateList: [],
      innerInfo: 'No Information',
      IconOne: null
    },
    {
      id: 'a6weightage',
      labelTextOneOne: 'weightage',
      isListCard: false,
      textOneOne: itemFrameworkOne?.itemFrameworkOneWeightage || 'No Information',
      innerAssociateList: [],
      innerInfo: 'No Information',
      IconOne: null
    }
  ];

  const reviseFramework = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    console.log(labelName);
    console.log(selectedBadgeName);
    if (labelName === 'cluster' && selectedBadgeName === 'distinct') {
    }
    if (labelName === 'preview') {
      if (responseObject?.informationFramework?.itemFrameworkOne?.itemFrameworkOneType === '') {
        // if(reviewMode==='create'){
        dispatch({ type: LOADER_START });
        dispatch({
          type: GET_FRAMWORK_TYPE_REVIEW_LIST_SAGA,
          payload: {
            request: {
              module: 'Item-Distinct',
              action: 'Review-List',
              actionFilter: 'Item-Framework-One',
              requestObject: {
                assesseeId: selectedAssociateInfo?.assesseeId,
                associateId:
                  selectedAssociateInfo?.associate?.informationEngagement.associateTag
                    .associateTagPrimary
              }
            }
          }
        });
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'ITEM_TRIPLEDOT_CONFIGURE_POPUP', popupMode: 'ITEMCREATE' }
        });
        // }
        // dispatch({
        //   type: SET_POPUP_VALUE,
        //   payload: {
        //     isPopUpValue: 'ITEM_TRIPLEDOT_CONFIGURE_POPUP',
        //     popupMode: ''
        //   }
        // });

      } else {
        setItemTypeConfigState(
          responseObject?.informationFramework?.itemFrameworkOne?.itemFrameworkOneType,
          dispatch,
          true
        );
        dispatch({ type: SET_PANE_THREE_ITEM_PREVIEW_MODE, payload: true });
        dispatch({
        type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
        payload: { stateName: 'isAssessmentStart', value: 'PROGRESS' }
      });
      dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneFive' });
      }
     
    }
    if (labelName === 'score') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ITEMSCOREPOPUP', popupMode: 'ITEMCREATE' }
      });
    }
    if (labelName === 'level') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ITEMLEVELPOPUP', popupMode: 'ITEMCREATE' }
      });
    }
    if (labelName === 'time') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ITEMTIMEPOPUP', popupMode: 'ITEMCREATE' }
      });
    }
    if (labelName === 'weightage') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ITEMWEITAGEPOPUP', popupMode: 'ITEMCREATE' }
      });
    }
    if (labelName === 'polarity') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ITEMPOLARITYPOPUP', popupMode: 'ITEMCREATE' }
      });
    }
    if (labelName === 'scale') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ITEMSCALEPOPUP', popupMode: 'ITEMCREATE' }
      });
    }
    if (labelName === 'type') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'FRAMEWORKONETYPEPOPUP', popupMode: 'ITEMCREATE' }
      });
    }
  };
  const reviewFramework = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    if (labelName === 'preview') {
      dispatch({
        type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
        payload: { stateName: 'isAssessmentStart', value: 'PROGRESS' }
      });
      dispatch({ type: SET_PANE_THREE_ITEM_PREVIEW_MODE, payload: true });
    }
  };

  return (
    <div>
      {headerOneBadgeThree === 'all' ? (
        <>
          <div className="containerPadding">
            <AllocationAccordian
              headerOne="framework"
              isDisplayCardExpanded={listExpand === 'framework'}
              setListExpand={setListExpand}
              list={frameworkList}
              mode={reviewMode}
              onClickRevise={reviseFramework}
              onClickReview={reviewFramework}
            />
          </div>
        </>
      ) : (
        <>
          <div className="containerPadding">
            <Paper className={'dossierContainerTop'}>
              {frameworkList.map((ob) => {
                return (
                  <div key={ob.id}>
                    {ob.isListCard ? (
                      <DisplayPanelAccordianReviewListOne
                        onClickRevise={reviseFramework}
                        className=""
                        accordianObject={ob}
                        mode={reviewMode}
                        onClickReview={reviewFramework}
                      />
                    ) : (
                      <DisplayPanelAccordianInformation
                        onClickRevise={reviseFramework}
                        accordianObject={ob}
                        mode={reviewMode}
                        onClickReview={reviewFramework}
                      />
                    )}
                  </div>
                );
              })}
            </Paper>
          </div>
        </>
      )}
      {isMobile && (
        <div className={'containerPadding'} style={{ height: '55px' }}>
          {' '}
        </div>
      )}
    </div>
  );
};

export default DisplayPaneThreeSectionTwoItem;
