import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CLEAR_DISPLAY_PANE_THREE,
  POPUP_OPEN,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MIDDLEPANE_STATE,
  SET_POPUP_STATE
} from '../actionType';

import ReviewList from '../Molecules/ReviewList/ReviewList';
import { PROFILER_REVIEW_LIST_POPUP_OPTION } from '../PopUpConfig';
import Card from '../Molecules/Card/Card';
import CrossIcon from '@material-ui/icons/Clear';
import { onClickCheckBoxSelection } from '../Actions/AssesseeModuleAction';
import { closeRelatedList } from '../Actions/GenericActions';

const JobProfilerDistinctReviewList = (props) => {
  const dispatch = useDispatch();
  const {
    reviewListDistinctData,
    relatedReviewListDistinctData,
    selectedTagsArray,
    unselectedTagsArray,
    middlePaneHeaderDuplicate,
    middlePaneHeaderDuplicateBadgeOne,
    middlePaneHeaderDuplicateBadgeTwo,
    middlePaneHeaderDuplicateBadgeThree,
    middlePaneHeaderDuplicateBadgeFour,
    duplicateScanCount,
    isSelectActive,
    middlePaneHeaderDuplicateOne,
    middlePaneHeaderDuplicateOneBadgeOne,
    middlePaneHeaderDuplicateOneBadgeTwo,
    middlePaneHeaderDuplicateOneBadgeThree,
    middlePaneHeaderDuplicateOneBadgeFour,
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { createMode, responseObject } = useSelector((state) => state.DisplayPaneThreeReducer);

  const closeRelated = () => {
    // dispatch({
    //   type: SET_MIDDLEPANE_STATE,
    //   payload: {
    //     middlePaneHeader: 'profilers',
    //     middlePaneHeaderBadgeOne: 'distinct',
    //     middlePaneHeaderBadgeTwo: 'active',
    //     middlePaneHeaderBadgeThree: '',
    //     middlePaneHeaderBadgeFour: '',
    //     typeOfMiddlePaneList: 'profilerDistinctReviewList',
    //     scanCount: reviewListDistinctData.length,
    //     showMiddlePaneState: true
    //   }
    // });
    closeRelatedList(
      dispatch,
      createMode,
      responseObject,
      middlePaneHeaderDuplicate,
      middlePaneHeaderDuplicateBadgeOne,
      'active',
      middlePaneHeaderDuplicateBadgeThree,
      middlePaneHeaderDuplicateBadgeFour,
      'profilerDistinctReviewList',
      reviewListDistinctData.length,
      'profilerDistinct',
      2,
      middlePaneHeaderDuplicateOne,
      middlePaneHeaderDuplicateOneBadgeOne,
      middlePaneHeaderDuplicateOneBadgeTwo,
      middlePaneHeaderDuplicateOneBadgeThree,
      middlePaneHeaderDuplicateOneBadgeFour,
    );
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  };
  const listDistinctData = relatedReviewListDistinctData[0];

  const openListPopup = (e) => {
    console.log(e.currentTarget.getAttribute('tag'));
    let tag = parseInt(e.currentTarget.getAttribute('tag'));
    let tempArr = [];
    let stats = e.currentTarget.getAttribute('status');
    tempArr = [...PROFILER_REVIEW_LIST_POPUP_OPTION];
    let newObj = {};
    let popupheader = '';
    if (tag === 0) {
      popupheader = 'job-competencies';
      newObj = {
        data: 'job-competencies',
        dataValue: 'jobcompetencies',
        optionClass: 'optionPrimary',
        divider: 'dark',
        disabled: false,
        permissionValue: 'revise'
      };
    }
    if (tag === 1) {
      popupheader = 'job-domains';
      newObj = {
        data: 'job-domains',
        dataValue: 'jobdomains',
        optionClass: 'optionPrimary',
        divider: 'dark',
        disabled: false,
        permissionValue: 'revise'
      };
    }
    if (tag === 2) {
      popupheader = 'job-functions';
      newObj = {
        data: 'job-functions',
        dataValue: 'jobfunctions',
        optionClass: 'optionPrimary',
        divider: 'dark',
        disabled: false,
        permissionValue: 'revise'
      };
    }
    if (tag === 3) {
      popupheader = 'job-roles';
      newObj = {
        data: 'job-roles',
        dataValue: 'jobroles',
        optionClass: 'optionPrimary',
        divider: 'dark',
        disabled: false,
        permissionValue: 'revise'
      };
    }
    tempArr.splice(9, 0, newObj);

    let temmmArr = [];
    tempArr.forEach(element => {
      if (element.data === 'revise') {
        temmmArr.push({ ...element, disabled: true })
      }
      else temmmArr.push(element)
    });
    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: popupheader,
        popupHeaderOneBadgeOne: '',
        isPopUpValue: '',
        popupOpenType: 'primary',
        popupContentArrValue: temmmArr,
        selectedTagValue: e.currentTarget.getAttribute('tag'),
        selectedTagStatus: stats,
        selectedTagInformationSetup: JSON.parse(e.currentTarget.getAttribute('informationsetup')),
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: temmmArr
      }
    });
  };
  console.log('listDistinctData', listDistinctData);
  return (
    <div>
      {listDistinctData && (
        <React.Fragment>
          <Card
            textOneOne={listDistinctData?.profilerName}
            textTwoOne={listDistinctData?.profilerDescription}
            IconOne={CrossIcon}
            isIcon={true}
            labelTwoTwo={'distinct'}
            onClickIconOne={closeRelated}
            isAlliance
            relatedCardFixed={true}
            className={'iguru-iconboxSVG'}
          />
        </React.Fragment>
      )}
      {listDistinctData &&
        listDistinctData?.jobProfilerList.map((item, index) => {
          return (
            <div className="containerPadding" key={index}>
              <ReviewList
                className=""
                id={index}
                tag={index}
                status={''}
                actualStatus={''}
                informationSetup={item?.informationSetup}
                associateId={item?.associateId}
                textOne={item?.textOne || ''}
                textTwo={item?.textTwo || ''}
                isTooltipActive={false}
                onClickEvent={openListPopup}
                isSelectActive={isSelectActive}
                isSelected={selectedTagsArray.includes(index.toString())}
                onClickCheckBox={(event) => {
                  onClickCheckBoxSelection(selectedTagsArray, unselectedTagsArray, event, dispatch);
                }}
              />
            </div>
          );
        })}
    </div>
  );
};
export default JobProfilerDistinctReviewList;
