import { getPopUpOptionList, setAssociateCardPermissionInJson } from '../Actions/GenericActions';
import {
  ASSESSMENT_POPUP_OPEN,
  CLEAR_ASSESSMENT_INFO,
  SET_ASSESSMENT_NEXT_POPUP,
  SET_ASSESSMENT_PREVIOUS_POPUP,
  SET_ASSESSMENT_SECONDARY_OPTION_VALUE,
  SET_DISPLAY_PANE_FOUR_SHOW,
  SET_ASSESSMENT_SECONDARY_POPUP,
  SET_ASSESSMENT_BASIC_REDUCER_STATE,
  SET_ASSESSMENT_DYNAMIC_SINGLE_STATE,
  SET_ASSESSMENT_FRAMEWORK_STATE,
  SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
  SET_ASSESSMENT_COMMUNIQUE_FRAMEWORK_STATE,
  SET_ASSESSMENT_SCORE_FRAMEWORK_STATE,
  SET_ASSESSMENT_MANUSCRIPT_FRAMEWORK_STATE,
  SET_ASSESSMENT_AID_FRAMEWORK_STATE,
  SET_ASSESSMENT_EVALUATION_FRAMEWORK_STATE,
  SET_ASSESSMENT_FRAMEWORK_INNER_SINGLE_STATE,
  SET_ASSESSMENT_FRAMEWORK_DYNAMIC_SINGLE_STATE,
  SET_ASSESSMENT_REVISE_DYNAMIC_SINGLE_STATE,
  SET_ASSESSMENT_SYNOPSIS_FRAMEWORK_STATE,
  SET_ASSESSMENT_SINGLE_STATE,
  SET_ASSESSMENT_TEMPLATE_FRAMEWORK_STATE,
  CLOSE_ASSESSMENT_POPUP_OPEN,
  SET_ASSESSMENT_ADMINISTRATOR_FRAMEWORK_STATE,
  SET_ASSESSMENT_FRAMEWORK_TIMELINE_STATE,
  SET_ASSESSMENT_FRAMEWORK_TEMPLATE_ALIGNMENT_STATE,
  UPDATE_ASSESSMENT_SETUP_PRIMARY_INFO
} from '../actionType';
import {
  MODULE_POPUP_OPTION,
  NOTIFICATION_REPORT_POPUP,
  REVIEW_POPUP_OPTIONS,
  REVIEW_REVISE_POPUP
} from '../PopUpConfig';

const initialState = {
  isDisplayPaneSixShow: true,
  assessmentsHeaderOne: '',
  assessmentsPopUpType: 'primary',
  currentPopUpOption: '',
  assessmentsPopUpActive: false,
  isBackToSectionPopUp: false,
  primaryPopUpOptions: MODULE_POPUP_OPTION,
  secondaryPopUpOptions: {
    create: REVIEW_REVISE_POPUP,
    review: REVIEW_POPUP_OPTIONS,
    notifications: NOTIFICATION_REPORT_POPUP,
    reports: NOTIFICATION_REPORT_POPUP
  },
  assessmentAdminSequence: [],
  secondaryOptionCheckValue: '',
  informationBasic: {
    assessmentName: '',
    assessmentNameVerification: false,
    assessmentDescription: '',
    assessmentPicture: '',
    assessmentPictureVerification: false,
    assessmentFlag: false,
    assessmentNotation: null
  },
  informationAlliance: {
    assessmentAuthor: {
      assessmentAuthorPrimary: [],
      assessmentAuthorSecondary: []
    }
  },
  informationAllocation: {
    assessmentGroup: {
      assessmentGroupPrimary: [],
      assessmentGroupSecondary: []
    },
    assessmentNode: {
      assessmentNodePrimary: [],
      assessmentNodeSecondary: []
    },
    assessmentType: {
      assessmentTypePrimary: [],
      assessmentTypeSecondary: []
    }
  },
  informationFramework: {
    assessmentAdministrationProctor: false,
    assessmentAdministrationSequence: [],
    assessmentAdministrationSupervise: false,
    assessmentAdministrationShuffle: {
      assessmentAdministrationShuffleItem: false,
      assessmentAdministrationShuffleResponseChoice: false
    },
    assessmentCommunique: [],
    assessmentItemDistinct: [],
    //assessmentItemFrameworkOneTemplate: null,
    assessmentAdministrationTemplateMedia: null,
    assessmentItemTotal: null,
    assessmentItemTrial: [],
    assessmentManuscript: [],
    assessmentScore: {
      assessmentScoreExtremumMaximum: 0,
      assessmentScoreExtremumMinimum: 0,
      assessmentScoreMean: null,
      assessmentScoreMedian: null,
      assessmentScoreNegative: 0,
      assessmentScoreStandardDeviation: null
    },
    assessmentSectionFramework: [
      {
        assessmentSectionName: 'Assessment (section)',
        assessmentSectionNameVerification: false,
        assessmentSectionDescription: 'Generic',
        assessmentSectionAdministrationRepeat: false,
        assessmentSectionAdministrationReset: false,
        assessmentSectionAid: {
          assessmentSectionAidCalculatorPermission: false,
          assessmentSectionAidCalculatorType: '',
          assessmentSectionAidSpreadsheetPermission: false,
          assessmentSectionAidSpreadsheetType: '',
          assessmentSectionAidTextsheetPermission: false,
          assessmentSectionAidTextsheetType: ''
        },
        assessmentSectionCommunique: [],
        assessmentSectionEvaluation: true,
        assessmentSectionItemDistinct: [],
        assessmentSectionItemFrameworkOneLabel: null,
        assessmentSectionItemNavigation: {
          assessmentSectionItemNavigationFirst: true,
          assessmentSectionItemNavigationLast: true,
          assessmentSectionItemNavigationNext: true,
          assessmentSectionItemNavigationPrevious: true,
          assessmentSectionItemNavigationSkip: true
        },
        assessmentSectionItemTotal: 0,
        assessmentSectionManuscript: [],
        assessmentSectionResponse: {
          assessmentSectionResponseExtremumMaximum: null,
          assessmentSectionResponseExtremumMinimum: null
        },
        assessmentSectionItemFrameworkOneResponseLabel: null,
        assessmentSectionItemFrameworkOneResponseRevise: false,
        assessmentSectionItemFrameworkOneResponseUnique: false,
        assessmentSectionScore: {
          assessmentSectionScoreExtremumMaximum: null,
          assessmentSectionScoreExtremumMinimum: null
        },
        assessmentSectionSequence: 0,
        assessmentSectionSynopsis: [],
        assessmentSectionTime: null,
        assessmentVersionFramework: [
          {
            assessmentVersionName: 'Assessment (version)',
            assessmentVersionNameVerification: false,
            assessmentVersionDescription: 'Generic',
            assessmentVersionPicture: '',
            assessmentVersionPictureVerification: false,
            assessmentSectionVersionItemFrameworkOne: [],
            assessmentVersionItemDistinct: []
          }
        ]
      }
    ],
    assessmentSynopsis: [],
    assessmentTime: 0,
    assessmentTimeAlert: 0,
    assessmentAdministrationVersion: {
      assessmentAdministrationVersionType: '',
      assessmentAdministrationVersionNumber: ''
    },
    assessmentTimeline: {
      assessmentTimelineDateTimeStart: '',
      assessmentTimelineDateTimeEnd: '',
      assessmentTimelineType: ''
    },
    assessmentAdministrationTemplateAlignment: {
      assessmentItemFrameworkOneResponseChoiceAlignment: 'vertical'
    },
    assessmentSectionItemDistinctRevise: null,
    assessmentAdministrationTemplateMediaHtml:
      '<p><span style="font-family: Roboto; font-size: 16px;"><strong>item (label)</strong></span></p><p><span style="font-family: Roboto; font-size: 16px;">item (passage)</span></p><p><span style="font-family: Roboto; font-size: 16px;">item</span></p><p><span style="font-family: Roboto; font-size: 10px;">item (explanation)</span></p><p><span style="font-family: Roboto; font-size: 16px;"><strong>response (label)</strong></span><strong></strong></p><p><span style="font-family: Roboto; font-size: 16px;">response</span></p><p><span style="font-family: Roboto; font-size: 10px;">response (explanation)</span></p><p><span style="font-family: Roboto; font-size: 16px;">response-choice</span></p><p><span style="font-family: Roboto; font-size: 10px;">response-choice (explanation)</span></p>',
    assessmentNormFrameworkSelected: ''
  },
  informationSetup: {
    assessmentMoveFee: 0.0,
    assessmentSellFee: 0.0,
    assessmentShareFee: 0.0
  }
};

const AssessmentReducer = (istate = JSON.parse(JSON.stringify(initialState)), action) => {
  // console.log(action.type);
  switch (action.type) {
    case SET_ASSESSMENT_SINGLE_STATE:
      return {
        ...istate,
        [action.payload.stateName]: action.payload.value
      };
    case ASSESSMENT_POPUP_OPEN:
      return {
        ...istate,
        assessmentsHeaderOne: 'assessments',
        assessmentsPopUpType: 'primary',
        //currentPopUpOption: istate.primaryPopUpOptions,
        currentPopUpOption: action.payload.popupContentArrValue,
        assessmentsPopUpActive: true
      };
    case CLOSE_ASSESSMENT_POPUP_OPEN:
      return {
        ...istate,
        assessmentsPopUpActive: false
      };
    case SET_ASSESSMENT_NEXT_POPUP:
      if (istate.assessmentsPopUpType === 'primary') {
        if (
          action.payload.targetValue === 'notifications' ||
          action.payload.targetValue === 'reports'
        ) {
          return {
            ...istate,
            assessmentsPopUpActive: true,
            assessmentsHeaderOne: action.payload.targetValue,
            assessmentsHeaderOneBadgeOne: 'review',
            assessmentsPopUpType: 'secondary',
            currentPopUpOption: istate.secondaryPopUpOptions[action.payload.targetValue],
            secondaryOptionCheckValue: 'unread'
          };
        } else {
          return {
            ...istate,
            assessmentsPopUpActive: true,
            assessmentsHeaderOne: 'assessments',
            assessmentsHeaderOneBadgeOne: action.payload.targetValue,
            assessmentsPopUpType: 'secondary',
            // currentPopUpOption: istate.secondaryPopUpOptions[action.payload.targetValue],
            currentPopUpOption: setAssociateCardPermissionInJson(
              istate.secondaryPopUpOptions[action.payload.targetValue],
              action.payload.permissionObject,
              'optionPrimary'
            ),
            secondaryOptionCheckValue: action.payload.targetValue === 'create' ? 'all' : 'active'
          };
        }
      } else {
        return istate;
      }
    case SET_ASSESSMENT_SECONDARY_POPUP:
      console.log('istate.assessmentsPopUpType', istate.assessmentsPopUpType);
      if (istate.assessmentsPopUpType === 'primary') {
        if (
          action.payload.clickValue === 'notifications' ||
          action.payload.clickValue === 'reports'
        ) {
          return {
            ...istate,
            assessmentsPopUpActive: true,
            assessmentsHeaderOne: action.payload.clickValue,
            assessmentsHeaderOneBadgeOne: 'review',
            assessmentsPopUpType: 'secondary',
            currentPopUpOption: istate.secondaryPopUpOptions[action.payload.clickValue],
            secondaryOptionCheckValue: 'unread',
            isBackToSectionPopUp: true
          };
        } else {
          return {
            ...istate,
            assessmentsPopUpActive: true,
            assessmentsHeaderOne: 'assessments',
            assessmentsHeaderOneBadgeOne: action.payload.clickValue,
            assessmentsPopUpType: 'secondary',
            currentPopUpOption: setAssociateCardPermissionInJson(
              istate.secondaryPopUpOptions[action.payload.clickValue],
              action.payload.permissionObject,
              'optionPrimary',
              '',
              'assessments',
              '',
              action.payload.paneOneSelectedSection
            ),
            secondaryOptionCheckValue: action.payload.clickValue === 'create' ? 'all' : 'active',
            isBackToSectionPopUp: true
          };
        }
      } else if (istate.assessmentsPopUpType === 'secondary') {
        if (action.payload === 'notifications' || action.payload === 'reports') {
          return {
            ...istate,
            assessmentsPopUpActive: true,
            assessmentsHeaderOne: action.payload.clickValue,
            assessmentsHeaderOneBadgeOne: 'review',
            assessmentsPopUpType: 'secondary',
            currentPopUpOption: istate.secondaryPopUpOptions[action.payload.clickValue],
            secondaryOptionCheckValue: 'unread',
            isBackToSectionPopUp: true
          };
        } else {
          return {
            ...istate,
            assessmentsPopUpActive: true,
            assessmentsHeaderOne: 'assessments',
            assessmentsHeaderOneBadgeOne: action.payload.clickValue,
            assessmentsPopUpType: 'secondary',
            currentPopUpOption: istate.secondaryPopUpOptions[action.payload.clickValue],
            secondaryOptionCheckValue: action.payload === 'create' ? 'all' : 'active',
            isBackToSectionPopUp: true
          };
        }
      } else {
        return istate;
      }
    case SET_ASSESSMENT_BASIC_REDUCER_STATE:
      return {
        ...istate,
        informationBasic: action.payload
      };
    case SET_ASSESSMENT_PREVIOUS_POPUP:
      if (istate.assessmentsPopUpType === 'primary') {
        return {
          ...istate,
          currentPopUpOption: [],
          assessmentsPopUpActive: false
        };
      } else if (istate.assessmentsPopUpType === 'secondary') {
        return {
          ...istate,
          currentPopUpOption: getPopUpOptionList(
            istate.primaryPopUpOptions,
            action.payload.permissionObject
          ),
          assessmentsHeaderOne: 'assessments',
          assessmentsHeaderOneBadgeOne: '',
          assessmentsPopUpType: 'primary',
          secondaryOptionCheckValue: 'active'
        };
      } else {
        return istate;
      }
    case SET_ASSESSMENT_SECONDARY_OPTION_VALUE:
      return {
        ...istate,
        secondaryOptionCheckValue: action.payload
      };
    case SET_DISPLAY_PANE_FOUR_SHOW:
      return {
        isDisplayPaneSixShow: action.payload
      };
    case SET_ASSESSMENT_DYNAMIC_SINGLE_STATE:
      return {
        ...istate,
        informationAllocation: {
          ...istate.informationAllocation,
          [action.payload.stateName]: {
            ...istate.informationAllocation[action.payload.stateName],
            [action.payload.actualStateName]: action.payload.value
          }
        }
      };
    case SET_ASSESSMENT_FRAMEWORK_STATE:
      return {
        ...istate,
        informationFramework: action.payload
      };
    case SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE:
      return {
        ...istate,
        informationFramework: {
          ...istate.informationFramework,
          [action.payload.stateName]: action.payload.value
        }
      };
    case SET_ASSESSMENT_COMMUNIQUE_FRAMEWORK_STATE:
      return {
        ...istate,
        informationFramework: {
          ...istate.informationFramework,
          assessmentCommunique: [
            ...istate.informationFramework.assessmentCommunique,
            action.payload
          ]
        }
      };
    case SET_ASSESSMENT_MANUSCRIPT_FRAMEWORK_STATE:
      return {
        ...istate,
        informationFramework: {
          ...istate.informationFramework,
          assessmentManuscript: [
            ...istate.informationFramework.assessmentManuscript,
            action.payload
          ]
        }
      };
    case SET_ASSESSMENT_SYNOPSIS_FRAMEWORK_STATE:
      return {
        ...istate,
        informationFramework: {
          ...istate.informationFramework,
          assessmentSynopsis: [...istate.informationFramework.assessmentSynopsis, action.payload]
        }
      };
    case SET_ASSESSMENT_SCORE_FRAMEWORK_STATE:
      return {
        ...istate,
        informationFramework: {
          ...istate.informationFramework,
          assessmentScore: action.payload
        }
      };
    case SET_ASSESSMENT_ADMINISTRATOR_FRAMEWORK_STATE:
      return {
        ...istate,
        informationFramework: {
          ...istate.informationFramework,
          assessmentAdministrationVersion: action.payload
        }
      };

    case SET_ASSESSMENT_AID_FRAMEWORK_STATE:
      return {
        ...istate,
        informationFramework: {
          ...istate.informationFramework,
          assessmentAid: action.payload
        }
      };
    case SET_ASSESSMENT_EVALUATION_FRAMEWORK_STATE:
      return {
        ...istate,
        informationFramework: {
          ...istate.informationFramework,
          assessmentEvaluation: action.payload
        }
      };
    case SET_ASSESSMENT_FRAMEWORK_INNER_SINGLE_STATE:
      return {
        ...istate,
        informationFramework: {
          ...istate.informationFramework,
          assessmentSectionItemDistinctRevise: {
            ...istate.informationFramework.assessmentSectionItemDistinctRevise,
            itemFrameworkOne: {
              ...istate.informationFramework.assessmentSectionItemDistinctRevise.itemFrameworkOne,
              [action.payload.objectName]: {
                ...istate.informationFramework.assessmentSectionItemDistinctRevise.itemFrameworkOne[
                  action.payload.objectName
                ],
                [action.payload.actualStateName]: action.payload.value
              }
            }
          }
        }
      };
    case SET_ASSESSMENT_FRAMEWORK_DYNAMIC_SINGLE_STATE:
      return {
        ...istate,
        informationFramework: {
          ...istate.informationFramework,
          assessmentSectionItemDistinctRevise: {
            ...istate.informationFramework.assessmentSectionItemDistinctRevise,
            itemFrameworkOne: {
              ...istate.informationFramework.assessmentSectionItemDistinctRevise.itemFrameworkOne,
              [action.payload.stateName]: action.payload.value
            }
          }
        }
      };
    case SET_ASSESSMENT_REVISE_DYNAMIC_SINGLE_STATE:
      return {
        ...istate,
        informationFramework: {
          ...istate.informationFramework,
          [action.payload.stateName]: {
            ...istate.informationFramework[action.payload.stateName],
            [action.payload.actualStateName]: action.payload.value
          }
        }
      };
    case SET_ASSESSMENT_TEMPLATE_FRAMEWORK_STATE:
      return {
        ...istate,
        informationFramework: {
          ...istate.informationFramework,
          assessmentEvaluation: action.payload
        }
      };
    case SET_ASSESSMENT_FRAMEWORK_TIMELINE_STATE:
      return {
        ...istate,
        informationFramework: {
          ...istate.informationFramework,
          assessmentTimeline: action.payload
        }
      };
    case SET_ASSESSMENT_FRAMEWORK_TEMPLATE_ALIGNMENT_STATE:
      return {
        ...istate,
        informationFramework: {
          ...istate.informationFramework,
          assessmentAdministrationTemplateAlignment: action.payload
        }
      };
    case UPDATE_ASSESSMENT_SETUP_PRIMARY_INFO:
      return {
        ...istate,
        informationSetup: action.payload
      };
    case CLEAR_ASSESSMENT_INFO:
      return JSON.parse(JSON.stringify(initialState));
    default:
      return istate;
  }
};

export default AssessmentReducer;
