import React, { useEffect, useState } from 'react';
import NavigatorIcon from '@material-ui/icons/OpenWith';
import CodeIcon from '@material-ui/icons/Code';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import ArrowRight from '@material-ui/icons/ChevronRight';
import ArrowLeft from '@material-ui/icons/ChevronLeft';
import BasicCard from '../../Molecules/DisplayPanelInformationBasic/DisplayPanelInformationBasic';
import HeaderCard from '../../Molecules/Header/HeaderCard';
import Sections from '../../Molecules/Section/Section';
import { useDispatch, useSelector } from 'react-redux';

import {
  ASSESSEE_INFO_CREATE,
  ASSESSEE_INFO_REVISE_SAGA,
  ASSESSEE_SIGN_ON,
  ASSOCIATE_INFO_REVISE_SAGA,
  ASSOCIATE_SIGN_ON,
  ASSOCIATE_ASSESSEESETUP_REVISE_SAGA,
  CLEAR_ASSESSMENT_INFO,
  CLEAR_ASSIGNMENT_INFO,
  CLEAR_DISPLAY_PANE_THREE,
  LOADER_START,
  NAVIGATOR_MODE,
  SET_DISPLAY_PANE_THREE_REVIEW_MODE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_POPUP_VALUE,
  CLEAR_GROUP_REDUCER_STATE,
  CLEAR_ROLE_REDUCER_STATE,
  CLEAR_TYPE_REDUCER_STATE,
  ASSESSMENT_INFO_REVISE_SAGA,
  ASSIGNMENT_INFO_REVISE_SAGA,
  CLEAR_ITEM_REDUCER_STATE,
  ITEM_INFO_REVISE_SAGA,
  CLEAR_CULTURE_REDUCER_STATE,
  CULTURE_PROFILE_INFO_REVISE_SAGA,
  JOB_PROFILE_INFO_REVISE_SAGA,
  LOADER_STOP,
  ASSOCIATE_ASSOCIATESETUP_REVISE_SAGA,
  ASSOCIATE_ASSOCIATENODE_SETUP_REVISE_SAGA,
  ASSOCIATE_ASSESSMENTSETUP_REVISE_SAGA,
  ASSOCIATE_ITEMSETUP_REVISE_SAGA,
  ASSOCIATE_ANALYTICSETUP_REVISE_SAGA,
  ASSOCIATE_ASSIGNMENTSETUP_REVISE_SAGA,
  CLEAR_VERSION_REDUCER_STATE,
  ASSIGNMENT_ASSESSMENT_INFO_REVISE_SAGA,
  ASSESSMENT_CLUSTER_REVISE_SAGA,
  SET_RANGE_SELECTED,
  SETJOB_WEIGHTAGE_SELECTED,
  SET_JOB_SIFTLIST_STATE,
  CREATE_GAUGE_SAGA,
  SET_DISPLAY_PANE_THREE_STATE,
  GET_CULTURE_DIAMENTION_SAGA,
  SET_WEIGHTAGE_SELECTED,
  CLEAR_JOB_REDUCER_STATE,
  SET_MIDDLEPANE_STATE,
  SET_FILTER_SINGLE_STATE,
  CLEAR_GUAGE_REDUCER_STATE,
  SET_PAGE_COUNT,
  FILTERMODE,
  FILTERMODE_ENABLE,
  ASSOCIATE_CREATE_INFO,
  RESET_ALL_REDUCER,
  CLEAR_NODE_REDUCER_STATE
} from '../../actionType';
import FooterIconTwo from '../../Molecules/FooterIcon/FooterIconTwo';
import ReviseIcon from '@material-ui/icons/RadioButtonChecked';
import Check from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import './DisplayPaneThree.css';
import DisplayPaneThreeSectionOne from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionOneAssesseeDistinct';
import DisplayPaneThreeSectionTwo from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionTwoAssesseeDistinct';
import DisplayPaneThreeSectionOneAssociate from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionOneAssociateDistinct';
import DisplayPaneThreeSectionTwoAssociate from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionTwoAssociateDistinct';
import DisplayPaneThreeSectionOneAssesseeRole from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionOneAssesseeRole';
import DisplayPaneThreeSectionTwoAssesseeRole from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionTwoAssesseeRole';
import DisplayPaneThreeSectionOneAssociateRole from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionOneAssociateRole';
import DisplayPaneThreeSectionTwoAssociateRole from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionTwoAssociateRole';
import DisplayPaneThreeSectionOneAssesseeGroup from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionOneAssesseeGroup';
import DisplayPaneThreeSectionTwoAssesseeGroup from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionTwoAssesseeGroup';
import DisplayPaneThreeSectionOneAssociateGroup from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionOneAssociateGroup';
import DisplayPaneThreeSectionTwoAssociateGroup from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionTwoAssociateGroup';
import DisplayPaneThreeSectionOneAssessmentGroup from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionOneAssessmentGroup';
import DisplayPaneThreeSectionTwoAssessmentGroup from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionTwoAssessmentGroup';
import DisplayPaneThreeSectionOneAssignmentGroup from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionOneAssignmentGroup';
import DisplayPaneThreeSectionTwoAssignmentGroup from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionTwoAssignmentGroup';
import DisplayPaneThreeSectionOneAssessment from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionOneAssessmentDistinct';
import DisplayPaneThreeSectionTwoAssessment from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionTwoAssessmentDistinct';
import DisplayPaneThreeSectionOneAssignmentType from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionOneAssignmentType';
import DisplayPaneThreeSectionTwoAssignmentType from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionTwoAssignmentType';
import DisplayPaneThreeSectionOneAssessmentType from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionOneAssessmentType';
import DisplayPaneThreeSectionTwoAssessmentType from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionTwoAssessmentType';
import DisplayPaneThreeSectionOneAssignment from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionOneAssignmentDistinct';
import DisplayPaneThreeSectionTwoAssignment from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionTwoAssignmentDistinct';
import DisplayPaneThreeSectionOneAssociateNode from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionOneAssociateNode';
import DisplayPaneThreeSectionTwoAssociateNode from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionTwoAssociateNode';
import {
  assesseeGropuReviseApicall,
  assesseeNodeReviseApiCall,
  assesseeRollReviseApiCall,
  assesseeTypeReviseApicall,
  getAssesseeGroupCreateAPI
} from '../../Actions/AssesseeModuleAction';
import {
  associateCreatePopup,
  associateGropuReviseApicall,
  associateRoleReviseApiCall,
  associateTypeReviseApicall,
  createAssociateNodeFunction,
  getAssociateGroupCreateAPI
} from '../../Actions/AssociateModuleAction';
import DisplayPaneThreeSectionOneAssesseeType from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionOneAssesseeType';
import DisplayPaneThreeSectionOneAssociateType from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionOneAssociateType';
import DisplayPaneThreeSectionTwoAssesseeType from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionTwoAssesseeType';
import DisplayPaneThreeSectionTwoAssociateType from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionTwoAssociateType';
import DisplayPaneThreeSectionOneItem from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionOneItemDistinct';
import DisplayPaneThreeSectionTwoItem from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionTwoItemDistinct';
import DisplayPaneThreeSectionOneItemGroup from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionOneItemGroup';
import DisplayPaneThreeSectionTwoItemGroup from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionTwoItemGroup';
import DisplayPaneThreeSectionTwoItemType from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionTwoItemType';
import DisplayPaneThreeSectionOneItemType from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionOneItemType';
import {
  onClickFirst,
  onClickPrevious,
  onClickLast,
  onClickNext,
  converTimeToMiliseconds,
  closeRelatedList
} from '../../Actions/GenericActions';
import DisplayPaneThreeSectionOneCultureProfileDistinct from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionOneCultureProfileDistinct';
import DisplayPaneThreeSectionTwoCultureProfileDistinct from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionTwoCultureProfileDistinct';
import DisplayPaneThreeSectionOneJobProfileDistinct from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionOneJobProfileDistinct';
import DisplayPaneThreeSectionTwoJobProfileDistinct from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionTwoJobProfileDistinct';
import DisplayPaneThreeSectionOneCultureProfileGroup from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionOneCultureProfileGroup';
import DisplayPaneThreeSectionTwoCultureProfileGroup from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionTwoCultureProfileGroup';
import DisplayPaneThreeSectionOneJobProfileGroup from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionOneJobProfileGroup';
import DisplayPaneThreeSectionTwoJobProfileGroup from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionTwoJobProfileGroup';
import DisplayPaneThreeSectionOneCultureProfileType from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionOneCultureProfileType';
import DisplayPaneThreeSectionOneJobProfileType from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionOneJobProfileType';
import DisplayPaneThreeSectionTwoCultureProfileType from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionTwoCultureProfileType';
import DisplayPaneThreeSectionTwoJobProfileType from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionTwoJobProfileType';
import {
  getItemGroupCreateAPI,
  itemGroupReviseApicall,
  itemTypeReviseApicall,
  setResponseToReducerObj
} from '../../Actions/ItemModuleAction';
import { callApiFunction } from '../../Actions/GenericActions';
import { BottomNavigation, Grid } from '@material-ui/core';
import DisplayPaneThreeSectionOneAssesseeReport from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionOneAssesseeReport';
import DisplayPaneThreeSectionOneAssessmentSection from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionOneAssessmentSection';
import DisplayPaneThreeSectionOneAssessmentScale from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionOneAssessmentScale';
import DisplayPaneThreeSectionOneAssessmentCluster from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionOneAssessmentCluster';
import DisplayPaneThreeSectionOneAssessmentVersion from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionOneAssessmentVersion';

import DisplayPaneThreeSectionOneAssessmentNorm from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionOneAssessmentNorm';
import DisplayPaneThreeSectionOneAssessmentScore from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionOneAssessmentScore';
import DisplayPaneThreeSectionTwoAssessmentScore from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionTwoAssessmentScore';
import DisplayPaneThreeSectionOneAssessmentScoreGrade from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionOneAssessmentScoreGrade';
import DisplayPaneThreeSectionOneAssignmentReport from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionOneAssignmentReport';
import DisplayPaneThreeSectionOneGaugeDistinct from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionOneGaugeDistinct';
import DisplayPaneThreeSectionTwoGaugeDistinct from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionTwoGaugeDistinct';
import DisplayPaneThreeSectionOneProfiler from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionOneProfiler';
import {
  assessmentGruopApiCall,
  assessmentTypeApiCall,
  getAssessmentGroupCreateAPI
} from '../../Actions/AssessmentModuleAction';
import {
  assignmentGroupReviseApiCall,
  assignmentTypeReviseApiCall,
  getAssignmentGroupCreateAPI
} from '../../Actions/AssignmentModuleAction';
import {
  cultureProfileGroupReviseApicall,
  cultureProfileTypeReviseApicall,
  getCultureProfileGroupCreateAPI
} from '../../Actions/ActionCultureProfile';
import {
  getJobProfileGroupCreateAPI,
  jobProfileGroupReviseApiCall,
  jobProfileTypeReviseApiCall
} from '../../Actions/ActionJobProfile';
import { CLOSE_MIDDLEPANLIST_ERROR } from '../../errorMessage';
import DisplayPaneThreeSectionTwoAssessmentCluster from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionTwoAssessmentCluster';
import DisplayPaneThreeSectionTwoAssessmentScoreGrade from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionTwoAssessmentScoreGrade';
import DisplayPaneThreeSectionTwoAssessmentNorm from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionTwoAssessmentNorm';
import DisplayPaneThreeSectionTwoAssessmentScale from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionTwoAssessmentScale';
import DisplayPaneThreeSectionTwoAssessmentSection from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionTwoAssessmentSection';
import DisplayPaneThreeSectionTwoAssessmentVersion from '../../Molecules/DisplayPaneThree/DisplayPaneThreeSectionTwoAssessmentVersion';

export const DisplayPaneThree = () => {
  const dispatch = useDispatch();
  const {
    isReviewRevise = false,
    headerOne,
    headerOneBadgeOne,
    headerOneBadgeTwo,
    headerOneBadgeThree,
    originResponseObj,
    responseObject,
    reviewMode,
    createMode,
    assesseeGroupAssessee,
    assesseeRoleAssessee,
    associateNodeAssessee,
    assignmentAssesseeList = [],
    assignmentAssessmentList = [],
    assignmentAssesseeMineDistinct = [],
    assignmentCultureProfileList = [],
    assignmentJobProfileList = [],
    assignmentAssesseeGroupList = [],
    assignmentAssessmentGroupList = [],
    assignmentCultureProfileGroupList = [],
    assignmentJobProfileGroupList = [],
    assignmentAssesseeMineGroup = [],
    isWeightageSelected = false,
    isJobWeightageSelected = false,
    isRangeSelected = false,
    assesseeSetUpModule,
    assessmentSetUpModule,
    assignmentSetUpModule,
    setUpAssociateModule,
    analyticSetUpModule,
    itemSetUpModule,
    assesseeSetUpModuleGeneric,
    assessmentSetUpModuleGeneric,
    assignmentSetUpModuleGeneric,
    setUpAssociateModuleGeneric,
    analyticSetUpModuleGeneric,
    itemSetUpModuleGeneric,
    isFrameworkOpen
  } = useSelector((state) => state.DisplayPaneThreeReducer);
  const {
    typeOfMiddlePaneList,
    countPage,
    assessmentResponseObject,
    selectedAssociateInfo,
    reviewListDistinctData,
    reviewListReqObj,
    numberPage,
    middlePaneHeader,
    middlePaneHeaderBadgeOne,
    middlePaneHeaderBadgeTwo,
    relatedReviewListDistinctData,
    scanCount,
    duplicateScanCount,
    assessmentSelecedSection,
    assessmentSelecedVersion,
    assessmentSelecedSectionVersionData,
    assessmentSelectedMetric,
    assignmentSelectedGuage,
    assesseeAssignmentReportData,
    assignmentResponseObject,
    middlePaneHeaderDuplicate,
    middlePaneHeaderDuplicateBadgeOne,
    middlePaneHeaderDuplicateBadgeTwo,
    middlePaneHeaderDuplicateBadgeThree,
    middlePaneHeaderDuplicateBadgeFour,
    assignmentCreateType,
    paneThreeSelectedSection
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { selectedTagValue, cardValue, cachedPreviousSelectedTagValue } = useSelector(
    (state) => state.PopUpReducer
  );
  const assessmentInfo = useSelector((state) => state.AssessmentReducer);
  const scaleInfo = useSelector((state) => state.ScaleCreateReducer);
  const clusterInfo = useSelector((state) => state.ClusterCreateReducer);
  const normInformation = useSelector((state) => state.NormCreateReducer);
  const versionInfo = useSelector((state) => state.VersionCreateReducer);
  const assignmentInfo = useSelector((state) => state.AssignmentReducer);
  const { itemInformation } = useSelector((state) => state.ItemCreateReducer);
  const { sectionInformation } = useSelector((state) => state.SectionCreateReducer);
  const scoreInfo = useSelector((state) => state.ScoreCreateReducer);
  const gradeInfo = useSelector((state) => state.GradeCreateReducer);
  const guageInfo = useSelector((state) => state.GaugeCreateReducer);
  const rolereducerinfo = useSelector((state) => state.RoleCreateReducer);
  const { assesseeAssessmentStartData } = useSelector(
    (state) => state.AssesseeAssignmentAssessmentReducer
  );
  const { culturejobProfilerInformation, culturejobProfiler, clusterProfilerInformation } =
    useSelector((state) => state.CulturejobProfilerCreateReducer);
  const { informationBasic, assessee } = responseObject;
  // console.log('assessee', assessee);
  const rightPaneSectionsAssessee = [
    {
      id: 'section1',
      sectionComponent: DisplayPaneThreeSectionOne,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    },
    {
      id: 'section2',
      sectionComponent: DisplayPaneThreeSectionTwo,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    }
  ];
  const rightPaneSectionsAssesseeRole = [
    {
      id: 'section1',
      sectionComponent: DisplayPaneThreeSectionOneAssesseeRole,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    },
    {
      id: 'section2',
      sectionComponent: DisplayPaneThreeSectionTwoAssesseeRole,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    }
  ];
  const rightPaneSectionsAssesseeGroup = [
    {
      id: 'section1',
      sectionComponent: DisplayPaneThreeSectionOneAssesseeGroup,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    },
    {
      id: 'section2',
      sectionComponent: DisplayPaneThreeSectionTwoAssesseeGroup,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    }
  ];
  const rightPaneSectionsAssociateRole = [
    {
      id: 'section1',
      sectionComponent: DisplayPaneThreeSectionOneAssociateRole,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    },
    {
      id: 'section2',
      sectionComponent: DisplayPaneThreeSectionTwoAssociateRole,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    }
  ];
  const rightPaneSectionsAssociateNode = [
    {
      id: 'section1',
      sectionComponent: DisplayPaneThreeSectionOneAssociateNode,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    },
    {
      id: 'section2',
      sectionComponent: DisplayPaneThreeSectionTwoAssociateNode,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    }
  ];
  const rightPaneSectionsAssociateGroup = [
    {
      id: 'section1',
      sectionComponent: DisplayPaneThreeSectionOneAssociateGroup,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    },
    {
      id: 'section2',
      sectionComponent: DisplayPaneThreeSectionTwoAssociateGroup,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    }
  ];
  const rightPaneSectionsAssessmentGroup = [
    {
      id: 'section1',
      sectionComponent: DisplayPaneThreeSectionOneAssessmentGroup,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    },
    {
      id: 'section2',
      sectionComponent: DisplayPaneThreeSectionTwoAssessmentGroup,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    }
  ];
  const rightPaneSectionsCultureProfileGroup = [
    {
      id: 'section1',
      sectionComponent: DisplayPaneThreeSectionOneCultureProfileGroup,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    },
    {
      id: 'section2',
      sectionComponent: DisplayPaneThreeSectionTwoCultureProfileGroup,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    }
  ];
  const rightPaneSectionsJobProfileGroup = [
    {
      id: 'section1',
      sectionComponent: DisplayPaneThreeSectionOneJobProfileGroup,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    },
    {
      id: 'section2',
      sectionComponent: DisplayPaneThreeSectionTwoJobProfileGroup,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    }
  ];
  const rightPaneSectionsCultureProfileType = [
    {
      id: 'section1',
      sectionComponent: DisplayPaneThreeSectionOneCultureProfileType,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    },
    {
      id: 'section2',
      sectionComponent: DisplayPaneThreeSectionTwoCultureProfileType,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    }
  ];
  const rightPaneSectionsJobProfileType = [
    {
      id: 'section1',
      sectionComponent: DisplayPaneThreeSectionOneJobProfileType,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    },
    {
      id: 'section2',
      sectionComponent: DisplayPaneThreeSectionTwoJobProfileType,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    }
  ];
  const rightPaneSectionsItemGroup = [
    {
      id: 'section1',
      sectionComponent: DisplayPaneThreeSectionOneItemGroup,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    },
    {
      id: 'section2',
      sectionComponent: DisplayPaneThreeSectionTwoItemGroup,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    }
  ];
  const rightPaneSectionsAssignmentGroup = [
    {
      id: 'section1',
      sectionComponent: DisplayPaneThreeSectionOneAssignmentGroup,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    },
    {
      id: 'section2',
      sectionComponent: DisplayPaneThreeSectionTwoAssignmentGroup,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    }
  ];
  const rightPaneSectionsAssignmentType = [
    {
      id: 'section1',
      sectionComponent: DisplayPaneThreeSectionOneAssignmentType,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    },
    {
      id: 'section2',
      sectionComponent: DisplayPaneThreeSectionTwoAssignmentType,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    }
  ];
  const rightPaneSectionsAssessmentType = [
    {
      id: 'section1',
      sectionComponent: DisplayPaneThreeSectionOneAssessmentType,
      // sectionComponent: DisplayPaneThreeSectionOneAssesseeReport,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    },
    {
      id: 'section2',
      sectionComponent: DisplayPaneThreeSectionTwoAssessmentType,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    }
  ];
  const rightPaneSectionsAssessmentScales = [
    {
      id: 'section1',
      sectionComponent: DisplayPaneThreeSectionOneAssessmentScale,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    },
    {
      id: 'section2',
      sectionComponent: DisplayPaneThreeSectionTwoAssessmentScale,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    }
  ];
  const rightPaneSectionsAssignmentGuages = [
    {
      id: 'section1',
      sectionComponent: DisplayPaneThreeSectionOneGaugeDistinct,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    },
    {
      id: 'section2',
      sectionComponent: DisplayPaneThreeSectionTwoGaugeDistinct,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    }
  ];
  const rightPaneSectionsAssessmentCluster = [
    {
      id: 'section1',
      sectionComponent: DisplayPaneThreeSectionOneAssessmentCluster,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    },
    {
      id: 'section2',
      sectionComponent: DisplayPaneThreeSectionTwoAssessmentCluster,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    }
  ];
  const rightPaneSectionsAssessmentNorm = [
    {
      id: 'section1',
      sectionComponent: DisplayPaneThreeSectionOneAssessmentNorm,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    },
    {
      id: 'section2',
      sectionComponent: DisplayPaneThreeSectionTwoAssessmentNorm,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    }
  ];
  const rightPaneSectionsAssessmentScore = [
    {
      id: 'section1',
      sectionComponent: DisplayPaneThreeSectionOneAssessmentScore,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    },
    {
      id: 'section2',
      sectionComponent: DisplayPaneThreeSectionTwoAssessmentScore,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    }
  ];
  const rightPaneSectionsAssessmentScoreGrade = [
    {
      id: 'section1',
      sectionComponent: DisplayPaneThreeSectionOneAssessmentScoreGrade,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    },
    {
      id: 'section2',
      sectionComponent: DisplayPaneThreeSectionTwoAssessmentScoreGrade,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    }
  ];
  const rightPaneSectionsAssessmentSection = [
    {
      id: 'section1',
      sectionComponent: DisplayPaneThreeSectionOneAssessmentSection,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    },
    {
      id: 'section2',
      sectionComponent: DisplayPaneThreeSectionTwoAssessmentSection,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    }
  ];
  const rightPaneSectionsAssessmentVersion = [
    {
      id: 'section1',
      sectionComponent: DisplayPaneThreeSectionOneAssessmentVersion,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    },
    {
      id: 'section2',
      sectionComponent: DisplayPaneThreeSectionTwoAssessmentVersion,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    }
  ];
  const rightPaneSectionsAssesseeReport = [
    {
      id: 'section1',
      sectionComponent: DisplayPaneThreeSectionOneAssesseeReport,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    },
    {
      id: 'section2',
      sectionComponent: DisplayPaneThreeSectionOneAssesseeReport,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    }
  ];
  const rightPaneSectionsAssignmentReport = [
    {
      id: 'section1',
      sectionComponent: DisplayPaneThreeSectionOneAssignmentReport,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    },
    {
      id: 'section2',
      sectionComponent: DisplayPaneThreeSectionOneAssignmentReport,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    }
  ];
  const rightPaneSectionsAssesseeType = [
    {
      id: 'section1',
      sectionComponent: DisplayPaneThreeSectionOneAssesseeType,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    },
    {
      id: 'section2',
      sectionComponent: DisplayPaneThreeSectionTwoAssesseeType,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    }
  ];
  const rightPaneSectionsAssociateType = [
    {
      id: 'section1',
      sectionComponent: DisplayPaneThreeSectionOneAssociateType,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    },
    {
      id: 'section2',
      sectionComponent: DisplayPaneThreeSectionTwoAssociateType,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    }
  ];
  const rightPaneSectionsItemType = [
    {
      id: 'section1',
      sectionComponent: DisplayPaneThreeSectionOneItemType,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    },
    {
      id: 'section2',
      sectionComponent: DisplayPaneThreeSectionTwoItemType,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    }
  ];
  const rightPaneSectionsAssociate = [
    {
      id: 'section1',
      sectionComponent: DisplayPaneThreeSectionOneAssociate,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    },
    {
      id: 'section2',
      sectionComponent: DisplayPaneThreeSectionTwoAssociate,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    }
  ];
  const rightPaneSectionsItem = [
    {
      id: 'section1',
      sectionComponent: DisplayPaneThreeSectionOneItem,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    },
    {
      id: 'section2',
      sectionComponent: DisplayPaneThreeSectionTwoItem,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    }
  ];
  const rightPaneSectionsAssessment = [
    {
      id: 'section1',
      sectionComponent: DisplayPaneThreeSectionOneAssessment,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    },
    {
      id: 'section2',
      sectionComponent: DisplayPaneThreeSectionTwoAssessment,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    }
  ];
  const rightPaneSectionsCultureProfile = [
    {
      id: 'section1',
      sectionComponent: DisplayPaneThreeSectionOneCultureProfileDistinct,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    },
    {
      id: 'section2',
      sectionComponent: DisplayPaneThreeSectionTwoCultureProfileDistinct,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    }
  ];
  const rightPaneSectionsJobProfile = [
    {
      id: 'section1',
      sectionComponent: DisplayPaneThreeSectionOneJobProfileDistinct,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    },
    {
      id: 'section2',
      sectionComponent: DisplayPaneThreeSectionTwoJobProfileDistinct,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    }
  ];
  const rightPaneSectionsAssignment = [
    {
      id: 'section1',
      sectionComponent: DisplayPaneThreeSectionOneAssignment,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    },
    {
      id: 'section2',
      sectionComponent: DisplayPaneThreeSectionTwoAssignment,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    }
  ];
  const rightPaneSectionsProfiler = [
    {
      id: 'section1',
      sectionComponent: DisplayPaneThreeSectionOneProfiler,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    },
    {
      id: 'section2',
      sectionComponent: DisplayPaneThreeSectionOneProfiler,
      displayPaneLeftHeaderText: '',
      displayPaneLeftBadgeText: ''
    }
  ];
  const [selectedSection, setSelectedSection] = useState(rightPaneSectionsAssessee[0]);
  const [selectedSectionAssesseeRole, setSelectedSectionAssesseeRole] = useState(
    rightPaneSectionsAssesseeRole[0]
  );
  const [selectedSectionAssesseeGroup, setSelectedSectionAssesseeGroup] = useState(
    rightPaneSectionsAssesseeGroup[0]
  );
  const [selectedSectionAssociateGroup, setSelectedSectionAssociateGroup] = useState(
    rightPaneSectionsAssociateGroup[0]
  );
  const [selectedSectionAssessmentGroup, setSelectedSectionAssessmentGroup] = useState(
    rightPaneSectionsAssessmentGroup[0]
  );
  const [selectedSectionCultureProfileGroup, setSelectedSectionCultureProfileGroup] = useState(
    rightPaneSectionsCultureProfileGroup[0]
  );
  const [selectedSectionJobProfileGroup, setSelectedSectionJobProfileGroup] = useState(
    rightPaneSectionsJobProfileGroup[0]
  );
  const [selectedSectionCultureProfileType, setSelectedSectionCultureProfileType] = useState(
    rightPaneSectionsCultureProfileType[0]
  );
  const [selectedSectionJobProfileType, setSelectedSectionJobProfileType] = useState(
    rightPaneSectionsJobProfileType[0]
  );
  const [selectedSectionItemGroup, setSelectedSectionItemGroup] = useState(
    rightPaneSectionsItemGroup[0]
  );
  const [selectedSectionAssignmentGroup, setSelectedSectionAssignmentGroup] = useState(
    rightPaneSectionsAssignmentGroup[0]
  );
  const [selectedSectionAssignmentType, setSelectedSectionAssignmentType] = useState(
    rightPaneSectionsAssignmentType[0]
  );
  const [selectedSectionAssessmentType, setSelectedSectionAssessmentType] = useState(
    rightPaneSectionsAssessmentType[0]
  );
  const [selectedSectionAssessmentSection, setSelectedSectionAssessmentSection] = useState(
    rightPaneSectionsAssessmentSection[0]
  );
  const [selectedSectionAssessmentVersion, setSelectedSectionAssessmentVersion] = useState(
    rightPaneSectionsAssessmentVersion[0]
  );
  const [selectedSectionAssessmentScale, setSelectedSectionAssessmentScale] = useState(
    rightPaneSectionsAssessmentScales[0]
  );
  const [selectedSectionAssignmentGuage, setSelectedSectionAssignmentGuage] = useState(
    rightPaneSectionsAssignmentGuages[0]
  );
  const [selectedSectionAssessmentCluster, setSelectedSectionAssessmentCluster] = useState(
    rightPaneSectionsAssessmentCluster[0]
  );
  const [selectedSectionAssessmentNorm, setSelectedSectionAssessmentNorm] = useState(
    rightPaneSectionsAssessmentNorm[0]
  );
  const [selectedSectionAssessmentScore, setSelectedSectionAssessmentScore] = useState(
    rightPaneSectionsAssessmentScore[0]
  );
  const [selectedSectionAssessmentScoreGrade, setSelectedSectionAssessmentScoreGrade] = useState(
    rightPaneSectionsAssessmentScoreGrade[0]
  );
  const [selectedSectionAssesseeReport, setSelectedSectionAssesseeReport] = useState(
    rightPaneSectionsAssesseeReport[0]
  );
  const [selectedSectionAssignmentReport, setSelectedSectionAssignmentReport] = useState(
    rightPaneSectionsAssignmentReport[0]
  );
  const [selectedSectionAssesseeType, setSelectedSectionAssesseeType] = useState(
    rightPaneSectionsAssesseeType[0]
  );
  const [selectedSectionAssociateType, setSelectedSectionAssociateType] = useState(
    rightPaneSectionsAssociateType[0]
  );
  const [selectedSectionItemType, setSelectedSectionItemType] = useState(
    rightPaneSectionsItemType[0]
  );
  const [selectedSectionAssessment, setSelectedSectionAssessment] = useState(
    rightPaneSectionsAssessment[0]
  );
  const [selectedSectionCultureProfile, setSelectedSectionCultureProfile] = useState(
    rightPaneSectionsCultureProfile[0]
  );
  const [selectedSectionJobProfile, setSelectedSectionJobProfile] = useState(
    rightPaneSectionsJobProfile[0]
  );
  const [selectedSectionAssignment, setSelectedSectionAssignment] = useState(
    rightPaneSectionsAssignment[0]
  );
  const [selectedSectionAssociateRole, setSelectedSectionAssociateRole] = useState(
    rightPaneSectionsAssociateRole[0]
  );
  const [selectedSectionAssociateNode, setSelectedSectionAssociateNode] = useState(
    rightPaneSectionsAssociateNode[0]
  );
  const [selectedSectionAssociate, setSelectedSectionAssociate] = useState(
    rightPaneSectionsAssociate[0]
  );
  const [selectedSectionProfiler, setSelectedSectionProfiler] = useState(
    rightPaneSectionsProfiler[0]
  );
  const [selectedSectionItem, setSelectedSectionItem] = useState(rightPaneSectionsItem[0]);
  useEffect(() => {
    setSelectedSection(rightPaneSectionsAssessee[0]);
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'paneThreeSelectedSection', value: 'section1' }
    });
    if (isFrameworkOpen === 'assesseeRole') {
      setSelectedSectionAssesseeRole(rightPaneSectionsAssesseeRole[1]);
    } else {
      setSelectedSectionAssesseeRole(rightPaneSectionsAssesseeRole[0]);
    }
    if (isFrameworkOpen === 'assesseeGroup') {
      setSelectedSectionAssesseeGroup(rightPaneSectionsAssesseeGroup[1]);
    } else {
      setSelectedSectionAssesseeGroup(rightPaneSectionsAssesseeGroup[0]);
    }
    if (isFrameworkOpen === 'associateGroup') {
      setSelectedSectionAssociateGroup(rightPaneSectionsAssociateGroup[1]);
    } else {
      setSelectedSectionAssociateGroup(rightPaneSectionsAssociateGroup[0]);
    }
    if (isFrameworkOpen === 'assessmentGroup') {
      setSelectedSectionAssessmentGroup(rightPaneSectionsAssessmentGroup[1]);
    } else {
      setSelectedSectionAssessmentGroup(rightPaneSectionsAssessmentGroup[0]);
    }
    if (isFrameworkOpen === 'cultureProfileGroup') {
      setSelectedSectionCultureProfileGroup(rightPaneSectionsCultureProfileGroup[1]);
    } else {
      setSelectedSectionCultureProfileGroup(rightPaneSectionsCultureProfileGroup[0]);
    }
    if (isFrameworkOpen === 'jobProfileGroup') {
      setSelectedSectionJobProfileGroup(rightPaneSectionsJobProfileGroup[1]);
    } else {
      setSelectedSectionJobProfileGroup(rightPaneSectionsJobProfileGroup[0]);
    }
    if (isFrameworkOpen === 'cultureProfileType') {
      setSelectedSectionCultureProfileType(rightPaneSectionsCultureProfileType[1]);
    } else {
      setSelectedSectionCultureProfileType(rightPaneSectionsCultureProfileType[0]);
    }
    if (isFrameworkOpen === 'jobProfileType') {
      setSelectedSectionJobProfileType(rightPaneSectionsJobProfileType[1]);
    } else {
      setSelectedSectionJobProfileType(rightPaneSectionsJobProfileType[0]);
    }
    if (isFrameworkOpen === 'itemGroup') {
      setSelectedSectionItemGroup(rightPaneSectionsItemGroup[1]);
    } else {
      setSelectedSectionItemGroup(rightPaneSectionsItemGroup[0]);
    }
    if (isFrameworkOpen === 'assignmentGroup') {
      setSelectedSectionAssignmentGroup(rightPaneSectionsAssignmentGroup[1]);
    } else {
      setSelectedSectionAssignmentGroup(rightPaneSectionsAssignmentGroup[0]);
    }
    if (isFrameworkOpen === 'assignmentType') {
      setSelectedSectionAssignmentType(rightPaneSectionsAssignmentType[1]);
    } else {
      setSelectedSectionAssignmentType(rightPaneSectionsAssignmentType[0]);
    }
    if (isFrameworkOpen === 'assessmentType') {
      setSelectedSectionAssessmentType(rightPaneSectionsAssessmentType[1]);
    } else {
      setSelectedSectionAssessmentType(rightPaneSectionsAssessmentType[0]);
    }
    setSelectedSectionAssessmentSection(rightPaneSectionsAssessmentSection[0]);
    setSelectedSectionAssessmentVersion(rightPaneSectionsAssessmentVersion[0]);
    setSelectedSectionAssessmentScale(rightPaneSectionsAssessmentScales[0]);
    setSelectedSectionAssessmentCluster(rightPaneSectionsAssessmentCluster[0]);
    setSelectedSectionAssessmentNorm(rightPaneSectionsAssessmentNorm[0]);
    setSelectedSectionAssesseeReport(rightPaneSectionsAssesseeReport[0]);
    setSelectedSectionAssignmentReport(rightPaneSectionsAssignmentReport[0]);
    if (isFrameworkOpen === 'assesseeType') {
      setSelectedSectionAssesseeType(rightPaneSectionsAssesseeType[1]);
    } else {
      setSelectedSectionAssesseeType(rightPaneSectionsAssesseeType[0]);
    }
    if (isFrameworkOpen === 'associateType') {
      setSelectedSectionAssociateType(rightPaneSectionsAssociateType[1]);
    } else {
      setSelectedSectionAssociateType(rightPaneSectionsAssociateType[0]);
    }
    if (isFrameworkOpen === 'itemType') {
      setSelectedSectionItemType(rightPaneSectionsItemType[1]);
    } else {
      setSelectedSectionItemType(rightPaneSectionsItemType[0]);
    }
    if (isFrameworkOpen === 'assessmentFramework') {
      setSelectedSectionAssessment(rightPaneSectionsAssessment[1]);
    } else {
      setSelectedSectionAssessment(rightPaneSectionsAssessment[0]);
    }
    if (isWeightageSelected) {
      setSelectedSectionCultureProfile(rightPaneSectionsCultureProfile[1]);
    } else {
      setSelectedSectionCultureProfile(rightPaneSectionsCultureProfile[0]);
    }
    if (isRangeSelected) {
      setSelectedSectionJobProfile(rightPaneSectionsJobProfile[1]);
    } else if (isJobWeightageSelected) {
      setSelectedSectionJobProfile(rightPaneSectionsJobProfile[1]);
    } else {
      setSelectedSectionJobProfile(rightPaneSectionsJobProfile[0]);
    }
    if (isFrameworkOpen === 'assignmentFramework') {
      setSelectedSectionAssignment(rightPaneSectionsAssignment[1]);
    } else {
      setSelectedSectionAssignment(rightPaneSectionsAssignment[0]);
    }
    setSelectedSectionAssociateRole(rightPaneSectionsAssociateRole[0]);
    if (isFrameworkOpen === 'associateNode') {
      setSelectedSectionAssociateNode(rightPaneSectionsAssociateNode[1]);
    } else {
      setSelectedSectionAssociateNode(rightPaneSectionsAssociateNode[0]);
    }
    setSelectedSectionAssociate(rightPaneSectionsAssociate[0]);
    setSelectedSectionItem(rightPaneSectionsItem[0]);
    setSelectedSectionAssignmentGuage(rightPaneSectionsAssignmentGuages[0]);
    setSelectedSectionProfiler(rightPaneSectionsProfiler[0]);
    setIsShowReviseIcon(true);
    dispatch({
      type: SET_FILTER_SINGLE_STATE,
      payload: { stateName: 'navigatorIcon', value: true }
    });
  }, [
    responseObject,
    isWeightageSelected,
    isJobWeightageSelected,
    isRangeSelected,
    assesseeSetUpModule,
    assessmentSetUpModule,
    assignmentSetUpModule,
    setUpAssociateModule,
    analyticSetUpModule,
    itemSetUpModule,
    isFrameworkOpen,
    assesseeSetUpModuleGeneric,
    assessmentSetUpModuleGeneric,
    assignmentSetUpModuleGeneric,
    setUpAssociateModuleGeneric,
    analyticSetUpModuleGeneric,
    itemSetUpModuleGeneric
  ]);

  const { navigatorIcon, FilterMode, FilterModeEnable } = useSelector(
    (state) => state.FilterReducer
  );
  const onClickFooter = (e) => {
    let siftValue = e.currentTarget.getAttribute('data-value');
    console.log(siftValue);
    dispatch({ type: NAVIGATOR_MODE });
    if (siftValue === 'next') {
      onClickNext(
        reviewListDistinctData,
        responseObject.id,
        typeOfMiddlePaneList,
        selectedAssociateInfo,
        dispatch,
        headerOneBadgeTwo,
        reviewListReqObj,
        numberPage,
        middlePaneHeader,
        middlePaneHeaderBadgeOne,
        middlePaneHeaderBadgeTwo
      );
    }
    if (siftValue === 'previous') {
      onClickPrevious(
        reviewListDistinctData,
        responseObject.id,
        typeOfMiddlePaneList,
        selectedAssociateInfo,
        dispatch,
        headerOneBadgeTwo
      );
    }
    if (siftValue === 'first') {
      onClickFirst(
        reviewListDistinctData,
        responseObject.id,
        typeOfMiddlePaneList,
        selectedAssociateInfo,
        dispatch,
        headerOneBadgeTwo
      );
    }
    if (siftValue === 'last') {
      onClickLast(
        reviewListDistinctData,
        typeOfMiddlePaneList,
        selectedAssociateInfo,
        dispatch,
        scanCount,
        reviewListReqObj,
        numberPage,
        middlePaneHeader,
        middlePaneHeaderBadgeOne,
        middlePaneHeaderBadgeTwo
      );
    }
  };
  const [isShowReviseIcon, setIsShowReviseIcon] = useState(true);
  const assesseeInfo = useSelector((state) => state.AssesseeCreateReducer);
  const associateInfo = useSelector((state) => state.AssociateCreateReducer);
  const { cultureProfileInformation } = useSelector((state) => state.CultureProfileCreateReducer);
  const { jobProfileInformation } = useSelector((state) => state.JobProfileCreateReducer);
  const {
    assesseeGroup,
    assessmentGroup,
    assignmentGroup,
    associateGroup,
    itemGroup,
    cultureProfileGroup,
    jobProfileGroup
  } = useSelector((state) => state.GroupCreateReducer);
  const {
    assesseeType,
    assessmentType,
    assignmentType,
    associateType,
    itemType,
    cultureProfileType,
    jobProfileType
  } = useSelector((state) => state.TypeCreateReducer);
  const { associateRole, assesseeRole } = useSelector((state) => state.RoleCreateReducer);
  const { nodeInformation } = useSelector((state) => state.NodeCreateReducer);
  //const primaryIcon = [{ label: 'navigator', onClick: onClickFooter, Icon: NavigatorIcon }];
  const primaryIcon = [{ label: 'navigator', onClick: onClickFooter, Icon: CodeIcon }];
  const secondaryIcon = [
    { label: 'first', onClick: onClickFooter, Icon: FirstPage },
    { label: 'previous', onClick: onClickFooter, Icon: ArrowLeft },
    { label: 'next', onClick: onClickFooter, Icon: ArrowRight },
    { label: 'last', onClick: onClickFooter, Icon: LastPage }
  ];
  const clearMiddlePaneInfo = () => {
    dispatch({
      type: SET_MIDDLEPANE_STATE,
      payload: {
        middlePaneHeader: '',
        middlePaneHeaderBadgeOne: '',
        middlePaneHeaderBadgeTwo: '',
        middlePaneHeaderBadgeThree: '',
        middlePaneHeaderBadgeFour: '',
        typeOfMiddlePaneList: '',
        scanCount: '',
        showMiddlePaneState: false
      }
    });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  };
  const onClickRevise = () => {
    console.log('ON CLICK REVISE ICON');
    setIsShowReviseIcon(false);
  };
  const onClickReviseCancel = () => {
    console.log('ON CLICK CANCEL ICON');
    if (headerOneBadgeOne === 'distinct' && headerOne === 'item') {
      setResponseToReducerObj(JSON.parse(originResponseObj), dispatch);
    }
    dispatch({ type: SET_DISPLAY_PANE_THREE_REVIEW_MODE, payload: 'review' });
    setIsShowReviseIcon(true);
  };
  const onClickReviseFinish = () => {
    console.log('ON CLICK FINISH ICON');
    if (
      (headerOneBadgeOne === 'distinct' || headerOneBadgeOne === 'self') &&
      (headerOne === 'assessee' ||
        headerOne === 'administrator' ||
        headerOne === 'assistant' ||
        headerOne === 'manager')
    ) {
      const {
        informationBasic,
        informationContact,
        informationCredential,
        informationPersonal,
        informationAllocation,
        informationSetup
      } = assesseeInfo;
      if (assesseeInfo.tempCommunication === '') {
        dispatch({
          type: ASSESSEE_SIGN_ON,
          payload: {
            isPopUpValue: 'FORCETOSELECTCOMMUNICATION',
            popupMode: 'ASSESSEE_CREATE',
            popupHeaderOne: headerOne
          }
        });
      } else {
        let selectedSignInCredential = '';
        if (informationSetup?.assesseeSignInCredential === 'email address (primary)') {
          selectedSignInCredential =
            responseObject.informationContact?.assesseeAddressEmailPrimary?.assesseeAddressEmail;
        } else if (informationSetup?.assesseeSignInCredential === 'email address (secondary)') {
          selectedSignInCredential =
            responseObject.informationContact?.assesseeAddressEmailSecondary?.assesseeAddressEmail;
        } else if (informationSetup?.assesseeSignInCredential === 'tag (primary)') {
          selectedSignInCredential =
            responseObject.informationEngagement?.assesseeTag?.assesseeTagPrimary;
        } else if (informationSetup?.assesseeSignInCredential === 'tag (secondary)') {
          selectedSignInCredential =
            responseObject.informationEngagement?.assesseeTag?.assesseeTagSecondary;
        } else if (informationSetup?.assesseeSignInCredential === 'mobile telephone (primary)') {
          selectedSignInCredential =
            responseObject.informationContact?.assesseeTelephoneMobilePrimary
              ?.assesseeTelephoneNumber;
        } else if (informationSetup?.assesseeSignInCredential === 'mobile telephone (secondary)') {
          selectedSignInCredential =
            responseObject.informationContact?.assesseeTelephoneMobileSecondary
              ?.assesseeTelephoneNumber;
        } else {
          selectedSignInCredential = responseObject?.informationSetup?.assesseeSignInCredential;
        }
        informationContact.assesseeAddressEmailPrimary.assesseeAddressEmailCommunication = false;
        if (assesseeInfo.tempCommunication === 'email address (primary)') {
          informationContact.assesseeAddressEmailPrimary.assesseeAddressEmailCommunication = true;
        }
        informationContact.assesseeAddressEmailSecondary.assesseeAddressEmailCommunication = false;
        if (assesseeInfo.tempCommunication === 'email address (secondary)') {
          informationContact.assesseeAddressEmailSecondary.assesseeAddressEmailCommunication = true;
        }
        //address communication
        informationContact.assesseeAddressHomePrimary.assesseeAddressCommunication = false;
        informationContact.assesseeAddressHomeSecondary.assesseeAddressCommunication = false;
        informationContact.assesseeAddressWorkPrimary.assesseeAddressCommunication = false;
        informationContact.assesseeAddressWorkSecondary.assesseeAddressCommunication = false;
        if (assesseeInfo.tempAddressCommunication === 'home address primary') {
          informationContact.assesseeAddressHomePrimary.assesseeAddressCommunication = true;
        }
        if (assesseeInfo.tempAddressCommunication === 'home address secondary') {
          informationContact.assesseeAddressHomeSecondary.assesseeAddressCommunication = true;
        }
        if (assesseeInfo.tempAddressCommunication === 'work address primary') {
          informationContact.assesseeAddressWorkPrimary.assesseeAddressCommunication = true;
        }
        if (assesseeInfo.tempAddressCommunication === 'work address secondary') {
          informationContact.assesseeAddressWorkSecondary.assesseeAddressCommunication = true;
        }
        // telephone communication
        informationContact.assesseeTelephoneHomePrimary.assesseeTelephoneCommunication = false;
        informationContact.assesseeTelephoneHomeSecondary.assesseeTelephoneCommunication = false;
        informationContact.assesseeTelephoneWorkPrimary.assesseeTelephoneCommunication = false;
        informationContact.assesseeTelephoneWorkSecondary.assesseeTelephoneCommunication = false;
        informationContact.assesseeTelephoneMobilePrimary.assesseeTelephoneCommunication = false;
        informationContact.assesseeTelephoneMobileSecondary.assesseeTelephoneCommunication = false;
        if (assesseeInfo.tempTelephoneCommunication === 'home telephone primary') {
          informationContact.assesseeTelephoneHomePrimary.assesseeTelephoneCommunication = true;
        }
        if (assesseeInfo.tempTelephoneCommunication === 'home telephone secondary') {
          informationContact.assesseeTelephoneHomeSecondary.assesseeTelephoneCommunication = true;
        }
        if (assesseeInfo.tempTelephoneCommunication === 'work telephone primary') {
          informationContact.assesseeTelephoneWorkPrimary.assesseeTelephoneCommunication = true;
        }
        if (assesseeInfo.tempTelephoneCommunication === 'work telephone secondary') {
          informationContact.assesseeTelephoneWorkSecondary.assesseeTelephoneCommunication = true;
        }
        if (assesseeInfo.tempTelephoneCommunication === 'mobile telephone primary') {
          informationContact.assesseeTelephoneMobilePrimary.assesseeTelephoneCommunication = true;
        }
        if (assesseeInfo.tempTelephoneCommunication === 'mobile telephone secondary') {
          informationContact.assesseeTelephoneMobileSecondary.assesseeTelephoneCommunication = true;
        }
        const { associateId, id } = responseObject;
        let informationSetup12 = {
          assesseeSignInCredential: selectedSignInCredential
        };
        let isPageRefresh = false;
        let body = {
          id,
          informationAllocation,
          informationBasic,
          informationContact,
          informationSetup,
          informationCredential,
          informationPersonal
        };
        if (selectedAssociateInfo?.assesseeId === id) {
          body = { ...body, informationSetup: informationSetup12 };
          if (informationSetup.assesseeSignInCredential !== selectedSignInCredential) {
            isPageRefresh = true;
          }
        }
        if (
          !informationContact?.assesseeAddressEmailPrimary?.assesseeAddressEmailCommunication &&
          !informationContact?.assesseeAddressEmailSecondary?.assesseeAddressEmailCommunication
        ) {
          dispatch({
            type: ASSESSEE_SIGN_ON,
            payload: {
              isPopUpValue: 'FORCETOSELECTCOMMUNICATION',
              popupMode: 'ASSESSEE_CREATE',
              popupHeaderOne: headerOne
            }
          });
          return;
        } else {
          const reqBody = {
            module: 'Assessee-Distinct',
            action: 'Revise',
            requestObject: {
              updateSignInCredentials: isPageRefresh,
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId,
              assessee: body
            }
          };
          console.log('reqBody', reqBody);
          dispatch({ type: LOADER_START });
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: ASSESSEE_INFO_REVISE_SAGA,
            payload: {
              secondaryOptionCheckValue: headerOneBadgeTwo,
              headerOne,
              headerOneBadgeOne,
              headerOneBadgeThree,
              reqBody,
              createMode,
              isPageRefresh,
              cachedPreviousSelectedTagValue,
              typeOfMiddlePaneList
            }
          });
        }
      }
    } else if (
      headerOneBadgeOne === 'node' &&
      (headerOne === 'associate' ||
        headerOne === 'administrators' ||
        headerOne === 'managers' ||
        headerOne === 'assistants' ||
        headerOne === 'assessees' ||
        headerOne === 'associates' ||
        headerOne === 'items' ||
        headerOne === 'culture-profiles' ||
        headerOne === 'job-profiles' ||
        headerOne === 'assessments' ||
        headerOne === 'assignments')
    ) {
      assesseeNodeReviseApiCall(
        headerOne,
        responseObject,
        selectedAssociateInfo,
        nodeInformation,
        associateNodeAssessee,
        dispatch,
        createMode,
        false,
        'associate'
      );
      closeRelatedList(
        dispatch,
        createMode,
        responseObject,
        middlePaneHeaderDuplicate,
        middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderDuplicateBadgeTwo,
        middlePaneHeaderDuplicateBadgeThree,
        middlePaneHeaderDuplicateBadgeFour,
        'associateNodeDistinctReviewList',
        duplicateScanCount,
        'associateNodeDistinct'
      );
      dispatch({
        type: FILTERMODE,
        payload: { FilterMode: 'associateNodeDistinctactive' }
      });
    } else if (
      headerOneBadgeOne === 'role' &&
      (headerOne === 'assessees' ||
        headerOne === 'managers' ||
        headerOne === 'administrators' ||
        headerOne === 'assistants')
    ) {
      assesseeRollReviseApiCall(
        responseObject,
        selectedAssociateInfo,
        assesseeRoleAssessee?.assesseeRoleAssesseeAllocate,
        assesseeRoleAssessee?.assesseeRoleAssesseeUnallocate,
        assesseeRole,
        dispatch,
        createMode,
        false,
        false,
        headerOne
      );
      closeRelatedList(
        dispatch,
        createMode,
        responseObject,
        middlePaneHeaderDuplicate,
        middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderDuplicateBadgeTwo,
        middlePaneHeaderDuplicateBadgeThree,
        middlePaneHeaderDuplicateBadgeFour,
        'assesseeRoleDistinctReviewList',
        duplicateScanCount,
        'assesseeRoleDistinct',
        duplicateScanCount
      );
    } else if (headerOneBadgeOne === 'role' && headerOne === 'associates') {
      associateRoleReviseApiCall(
        responseObject,
        selectedAssociateInfo,
        assesseeRoleAssessee?.assesseeRoleAssesseeAllocate,
        assesseeRoleAssessee?.assesseeRoleAssesseeUnallocate,
        associateRole,
        dispatch,
        createMode
      );
      closeRelatedList(
        dispatch,
        createMode,
        responseObject,
        middlePaneHeaderDuplicate,
        middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderDuplicateBadgeTwo,
        middlePaneHeaderDuplicateBadgeThree,
        middlePaneHeaderDuplicateBadgeFour,
        'associateRoleDistinctReviewList',
        duplicateScanCount,
        'associateRoleDistinct'
      );
    } else if (headerOneBadgeOne === 'group' && headerOne === 'associates') {
      console.log('numberPage', numberPage);
      associateGropuReviseApicall(
        responseObject,
        selectedAssociateInfo,
        assesseeGroupAssessee?.assesseeGroupAssesseeAllocate,
        assesseeGroupAssessee?.assesseeGroupAssesseeUnallocate,
        associateGroup,
        dispatch,
        createMode,
        false,
        cardValue
      );
      closeRelatedList(
        dispatch,
        createMode,
        responseObject,
        middlePaneHeaderDuplicate,
        middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderDuplicateBadgeTwo,
        middlePaneHeaderDuplicateBadgeThree,
        middlePaneHeaderDuplicateBadgeFour,
        'associatesGroupDistinctReviewList',
        duplicateScanCount,
        'associateGroupDistinct'
      );
      // const { associateId, id } = responseObject;
      // const reqBody = {
      //   module: 'Associate-Group',
      //   action: 'Revise',
      //   requestObject: {
      //     assesseeId: selectedAssociateInfo?.assesseeId,
      //     associateId,
      //     associateGroupAssociate: {
      //       associateGroupAssociateAllocate:
      //         assesseeGroupAssessee?.assesseeGroupAssesseeAllocate || [],
      //       associateGroupAssociateUnallocate:
      //         assesseeGroupAssessee?.assesseeGroupAssesseeUnallocate || []
      //     },
      //     associateGroup: {
      //       id,
      //       informationBasic: associateGroup.informationBasic,
      //       informationSetup: associateGroup.informationSetup
      //     }
      //   }
      // };
      // dispatch({ type: LOADER_START });
      // let associateGroupAssociateReqBody = getAssociateGroupAssociateReqObj(
      //   selectedAssociateInfo,
      //   id,
      //   'active',
      //   0,
      //   countPage
      // );
      // dispatch({
      //   type: ASSOCIATE_GROUP_REVISE_INFO_SAGA,
      //   payload: { headerOne: 'associates', associateGroupAssociateReqBody, reqBody, createMode }
      // });
    } else if (headerOneBadgeOne === 'group' && headerOne === 'assessments') {
      assessmentGruopApiCall(
        responseObject,
        selectedAssociateInfo,
        assesseeGroupAssessee?.assesseeGroupAssesseeAllocate,
        assesseeGroupAssessee?.assesseeGroupAssesseeUnallocate,
        assessmentGroup,
        dispatch,
        createMode,
        false,
        cardValue
      );
      closeRelatedList(
        dispatch,
        createMode,
        responseObject,
        middlePaneHeaderDuplicate,
        middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderDuplicateBadgeTwo,
        middlePaneHeaderDuplicateBadgeThree,
        middlePaneHeaderDuplicateBadgeFour,
        'assessmentsGroupDistinctReviewList',
        duplicateScanCount,
        'assessmentGroupDistinct'
      );
      // const { associateId, id } = responseObject;
      // const reqBody = {
      //   module: 'Assessment-Group',
      //   action: 'Revise',
      //   requestObject: {
      //     assesseeId: selectedAssociateInfo?.assesseeId,
      //     associateId:
      //       selectedAssociateInfo?.associate?.informationEngagement.associateTag
      //         .associateTagPrimary,
      //     assessmentGroupAssessment: {
      //       assessmentGroupAssessmentAllocate:
      //         assesseeGroupAssessee?.assesseeGroupAssesseeAllocate || [],
      //       assessmentGroupAssessmentUnallocate:
      //         assesseeGroupAssessee?.assesseeGroupAssesseeUnallocate || []
      //     },
      //     assessmentGroup: {
      //       id,
      //       informationBasic: assessmentGroup.informationBasic,
      //       informationSetup: assessmentGroup.informationSetup
      //     }
      //   }
      // };
      // dispatch({ type: LOADER_START });
      // let assessmentGroupAssessmentReqBody = getAssessmentGroupAssessmentReqObj(
      //   selectedAssociateInfo,
      //   id,
      //   'active',
      //   0,
      //   countPage
      // );
      // dispatch({
      //   type: ASSESSMENT_GROUP_REVISE_INFO_SAGA,
      //   payload: { headerOne: 'assessments', reqBody, assessmentGroupAssessmentReqBody, createMode }
      // });
    } else if (headerOneBadgeOne === 'group' && headerOne === 'culture-profiles') {
      cultureProfileGroupReviseApicall(
        responseObject,
        selectedAssociateInfo,
        assesseeGroupAssessee?.assesseeGroupAssesseeAllocate,
        assesseeGroupAssessee?.assesseeGroupAssesseeUnallocate,
        cultureProfileGroup,
        dispatch,
        createMode,
        false,
        cardValue
      );
      closeRelatedList(
        dispatch,
        createMode,
        responseObject,
        middlePaneHeaderDuplicate,
        middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderDuplicateBadgeTwo,
        middlePaneHeaderDuplicateBadgeThree,
        middlePaneHeaderDuplicateBadgeFour,
        'cultureProfilesGroupDistinctReviewList',
        duplicateScanCount,
        'cultureProfileGroup'
      );
      // const { associateId, id } = responseObject;
      // const reqBody = {
      //   module: 'Culture-Profile-Group',
      //   action: 'Revise',
      //   requestObject: {
      //     assesseeId: selectedAssociateInfo?.assesseeId,
      //     associateId:
      //       selectedAssociateInfo?.associate?.informationEngagement.associateTag
      //         .associateTagPrimary,
      //     cultureProfileGroupCultureProfile: {
      //       cultureProfileGroupCultureProfileAllocate:
      //         assesseeGroupAssessee?.assesseeGroupAssesseeAllocate || [],
      //       cultureProfileGroupCultureProfileUnallocate:
      //         assesseeGroupAssessee?.assesseeGroupAssesseeUnallocate || []
      //     },
      //     cultureProfileGroup: {
      //       id,
      //       informationBasic: cultureProfileGroup.informationBasic,
      //       informationSetup: cultureProfileGroup.informationSetup
      //     }
      //   }
      // };
      // dispatch({ type: LOADER_START });
      // let cultureGroupCultureReqBody = getCultureGroupCultureReqObj(
      //   selectedAssociateInfo,
      //   id,
      //   'active',
      //   0,
      //   countPage
      // );
      // dispatch({
      //   type: CULTURE_GROUP_REVISE_INFO_SAGA,
      //   payload: { headerOne: 'culture-profiles', reqBody, cultureGroupCultureReqBody, createMode }
      // });
    } else if (headerOneBadgeOne === 'group' && headerOne === 'job-profiles') {
      jobProfileGroupReviseApiCall(
        responseObject,
        selectedAssociateInfo,
        assesseeGroupAssessee?.assesseeGroupAssesseeAllocate,
        assesseeGroupAssessee?.assesseeGroupAssesseeUnallocate,
        jobProfileGroup,
        dispatch,
        createMode,
        false,
        cardValue
      );
      closeRelatedList(
        dispatch,
        createMode,
        responseObject,
        middlePaneHeaderDuplicate,
        middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderDuplicateBadgeTwo,
        middlePaneHeaderDuplicateBadgeThree,
        middlePaneHeaderDuplicateBadgeFour,
        'jobProfilesGroupDistinctReviewList',
        duplicateScanCount,
        'jobProfileGroup'
      );
      // const { associateId, id } = responseObject;
      // const reqBody = {
      //   module: 'Job-Profile-Group',
      //   action: 'Revise',
      //   requestObject: {
      //     assesseeId: selectedAssociateInfo?.assesseeId,
      //     associateId:
      //       selectedAssociateInfo?.associate?.informationEngagement.associateTag
      //         .associateTagPrimary,
      //     jobProfileGroupJobProfile: {
      //       jobProfileGroupJobProfileAllocate:
      //         assesseeGroupAssessee?.assesseeGroupAssesseeAllocate || [],
      //       jobProfileGroupJobProfileUnallocate:
      //         assesseeGroupAssessee?.assesseeGroupAssesseeUnallocate || []
      //     },
      //     jobProfileGroup: {
      //       id,
      //       informationBasic: jobProfileGroup.informationBasic,
      //       informationSetup: jobProfileGroup.informationSetup
      //     }
      //   }
      // };
      // dispatch({ type: LOADER_START });
      // let jobGroupJobReqBody = getJobProfileGroupJobProfileReqObj(
      //   selectedAssociateInfo,
      //   id,
      //   'active',
      //   0,
      //   countPage
      // );
      // dispatch({
      //   type: JOB_GROUP_REVISE_INFO_SAGA,
      //   payload: { headerOne: 'job-profiles', reqBody, jobGroupJobReqBody, createMode }
      // });
    } else if (headerOneBadgeOne === 'group' && headerOne === 'items') {
      itemGroupReviseApicall(
        responseObject,
        selectedAssociateInfo,
        assesseeGroupAssessee?.assesseeGroupAssesseeAllocate,
        assesseeGroupAssessee?.assesseeGroupAssesseeUnallocate,
        itemGroup,
        dispatch,
        createMode,
        false,
        cardValue
      );
      closeRelatedList(
        dispatch,
        createMode,
        responseObject,
        middlePaneHeaderDuplicate,
        middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderDuplicateBadgeTwo,
        middlePaneHeaderDuplicateBadgeThree,
        middlePaneHeaderDuplicateBadgeFour,
        'itemsGroupDistinctReviewList',
        duplicateScanCount,
        'itemGroupDistinct'
      );
      // const { associateId, id } = responseObject;
      // const reqBody = {
      //   module: 'Item-Group',
      //   action: 'Revise',
      //   requestObject: {
      //     assesseeId: selectedAssociateInfo?.assesseeId,
      //     associateId:
      //       selectedAssociateInfo?.associate?.informationEngagement.associateTag
      //         .associateTagPrimary,
      //     itemGroupItem: {
      //       itemGroupItemAllocate: assesseeGroupAssessee?.assesseeGroupAssesseeAllocate || [],
      //       itemGroupItemUnallocate: assesseeGroupAssessee?.assesseeGroupAssesseeUnallocate || []
      //     },
      //     itemGroup: {
      //       id,
      //       informationBasic: itemGroup.informationBasic,
      //       informationSetup: itemGroup.informationSetup
      //     }
      //   }
      // };
      // dispatch({ type: LOADER_START });
      // let itemGroupItemReqBody = getItemGroupItemReqObj(
      //   selectedAssociateInfo,
      //   id,
      //   'active',
      //   0,
      //   countPage
      // );
      // dispatch({
      //   type: ITEM_GROUP_REVISE_INFO_SAGA,
      //   payload: { headerOne: 'items', reqBody, itemGroupItemReqBody, createMode }
      // });
    } else if (headerOneBadgeOne === 'group' && headerOne === 'assignments') {
      assignmentGroupReviseApiCall(
        responseObject,
        selectedAssociateInfo,
        assesseeGroupAssessee?.assesseeGroupAssesseeAllocate,
        assesseeGroupAssessee?.assesseeGroupAssesseeUnallocate,
        assignmentGroup,
        dispatch,
        createMode,
        false,
        cardValue
      );
      closeRelatedList(
        dispatch,
        createMode,
        responseObject,
        middlePaneHeaderDuplicate,
        middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderDuplicateBadgeTwo,
        middlePaneHeaderDuplicateBadgeThree,
        middlePaneHeaderDuplicateBadgeFour,
        'assignmentsGroupDistinctReviewList',
        duplicateScanCount,
        'assignmentsGroupDistinct'
      );
     
    } else if (headerOneBadgeOne === 'group' && headerOne === 'assessees') {
      assesseeGropuReviseApicall(
        responseObject,
        selectedAssociateInfo,
        assesseeGroupAssessee?.assesseeGroupAssesseeAllocate,
        assesseeGroupAssessee?.assesseeGroupAssesseeUnallocate,
        assesseeGroup,
        dispatch,
        createMode,
        false,
        middlePaneHeader,
        cardValue
      );
      closeRelatedList(
        dispatch,
        createMode,
        responseObject,
        middlePaneHeaderDuplicate,
        middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderDuplicateBadgeTwo,
        middlePaneHeaderDuplicateBadgeThree,
        middlePaneHeaderDuplicateBadgeFour,
        'assesseesGroupDistinctReviewList',
        duplicateScanCount,
        'assesseesGroupDistinct'
      );
      // const { associateId, id } = responseObject;
      // const reqBody = {
      //   module: 'Assessee-Group',
      //   action: 'Revise',
      //   requestObject: {
      //     assesseeId: selectedAssociateInfo?.assesseeId,
      //     associateId,
      //     assesseeGroupAssessee: {
      //       assesseeGroupAssesseeAllocate:
      //         assesseeGroupAssessee?.assesseeGroupAssesseeAllocate || [],
      //       assesseeGroupAssesseeUnallocate:
      //         assesseeGroupAssessee?.assesseeGroupAssesseeUnallocate || []
      //     },
      //     assesseeGroup: {
      //       id,
      //       informationBasic: assesseeGroup.informationBasic,
      //       informationSetup: assesseeGroup.informationSetup
      //     }
      //   }
      // };
      // dispatch({ type: LOADER_START });
      // let assesseeGroupAssesseeReqBody = getAssesseeGroupAssesseeReqObj(
      //   selectedAssociateInfo,
      //   id,
      //   'active',
      //   0,
      //   countPage
      // );
      // dispatch({
      //   type: ASSESSEE_GROUP_INFO_REVISE_SAGA,
      //   payload: { headerOne: 'assessees', assesseeGroupAssesseeReqBody, reqBody, createMode }
      // });
    } else if (
      headerOneBadgeOne === 'group' &&
      (headerOne === 'managers' || headerOne === 'administrators' || headerOne === 'assistants')
    ) {
      assesseeGropuReviseApicall(
        responseObject,
        selectedAssociateInfo,
        assesseeGroupAssessee?.assesseeGroupAssesseeAllocate,
        assesseeGroupAssessee?.assesseeGroupAssesseeUnallocate,
        assesseeGroup,
        dispatch,
        createMode,
        false,
        headerOne,
        cardValue
      );
      closeRelatedList(
        dispatch,
        createMode,
        responseObject,
        middlePaneHeaderDuplicate,
        middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderDuplicateBadgeTwo,
        middlePaneHeaderDuplicateBadgeThree,
        middlePaneHeaderDuplicateBadgeFour,
        'assesseesGroupDistinctReviewList',
        duplicateScanCount,
        'assesseesGroupDistinct'
      );
    } else if (
      headerOneBadgeOne === 'type' &&
      (headerOne === 'managers' || headerOne === 'administrators' || headerOne === 'assistants')
    ) {
      assesseeTypeReviseApicall(
        responseObject,
        selectedAssociateInfo,
        assesseeRoleAssessee?.assesseeRoleAssesseeAllocate,
        assesseeRoleAssessee?.assesseeRoleAssesseeUnallocate,
        assesseeType,
        dispatch,
        createMode,
        false,
        headerOne
      );
      closeRelatedList(
        dispatch,
        createMode,
        responseObject,
        middlePaneHeaderDuplicate,
        middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderDuplicateBadgeTwo,
        middlePaneHeaderDuplicateBadgeThree,
        middlePaneHeaderDuplicateBadgeFour,
        'assesseesTypeDistinctReviewList',
        duplicateScanCount,
        'assesseesTypeDistinct'
      );
    } else if (headerOneBadgeOne === 'type' && headerOne === 'assessees') {
      assesseeTypeReviseApicall(
        responseObject,
        selectedAssociateInfo,
        assesseeRoleAssessee?.assesseeRoleAssesseeAllocate,
        assesseeRoleAssessee?.assesseeRoleAssesseeUnallocate,
        assesseeType,
        dispatch,
        createMode
      );
      closeRelatedList(
        dispatch,
        createMode,
        responseObject,
        middlePaneHeaderDuplicate,
        middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderDuplicateBadgeTwo,
        middlePaneHeaderDuplicateBadgeThree,
        middlePaneHeaderDuplicateBadgeFour,
        'assesseesTypeDistinctReviewList',
        duplicateScanCount,
        'assesseesTypeDistinct'
      );
      // const { associateId, id } = responseObject;
      // console.log(assesseeType);
      // const reqBody = {
      //   module: 'Assessee-Type',
      //   action: 'Revise',
      //   requestObject: {
      //     assesseeId: selectedAssociateInfo?.assesseeId,
      //     associateId,
      //     assesseeTypeAssessee: {
      //       assesseeTypeAssesseeAllocate: assesseeRoleAssessee?.assesseeRoleAssesseeAllocate || [],
      //       assesseeTypeAssesseeUnallocate:
      //         assesseeRoleAssessee?.assesseeRoleAssesseeUnallocate || []
      //     },
      //     assesseeType: {
      //       id,
      //       informationBasic: assesseeType.informationBasic,
      //       informationAllocation: assesseeType.informationAllocation,
      //       informationSetup: assesseeType.informationSetup
      //     }
      //   }
      // };
      // dispatch({ type: LOADER_START });
      // let assesseeTypeAssesseeReqBody = getAssesseeTypeAssesseeReqObj(
      //   selectedAssociateInfo,
      //   id,
      //   'active',
      //   0,
      //   countPage
      // );
      // dispatch({
      //   type: ASSESSEE_TYPE_INFO_REVISE_SAGA,
      //   payload: { headerOne: 'assessees', reqBody, assesseeTypeAssesseeReqBody, createMode }
      // });
    } else if (headerOneBadgeOne === 'type' && headerOne === 'assessments') {
      assessmentTypeApiCall(
        responseObject,
        selectedAssociateInfo,
        assesseeGroupAssessee?.assesseeGroupAssesseeAllocate,
        assesseeGroupAssessee?.assesseeGroupAssesseeUnallocate,
        assessmentType,
        dispatch,
        createMode
      );
      closeRelatedList(
        dispatch,
        createMode,
        responseObject,
        middlePaneHeaderDuplicate,
        middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderDuplicateBadgeTwo,
        middlePaneHeaderDuplicateBadgeThree,
        middlePaneHeaderDuplicateBadgeFour,
        'assessmentsTypeDistinctReviewList',
        duplicateScanCount,
        'assessmentsTypeDistinct'
      );
      // const { associateId, id } = responseObject;
      // console.log(assessmentType);
      // const reqBody = {
      //   module: 'Assessment-Type',
      //   action: 'Revise',
      //   requestObject: {
      //     assesseeId: selectedAssociateInfo?.assesseeId,
      //     associateId:
      //       selectedAssociateInfo?.associate?.informationEngagement.associateTag
      //         .associateTagPrimary,
      //     assessmentTypeAssessment: {
      //       assessmentTypeAssessmentAllocate:
      //         assesseeGroupAssessee?.assesseeGroupAssesseeAllocate || [],
      //       assessmentTypeAssessmentUnallocate:
      //         assesseeGroupAssessee?.assesseeGroupAssesseeUnallocate || []
      //     },
      //     assessmentType: {
      //       id,
      //       informationBasic: assessmentType.informationBasic,
      //       informationAllocation: assessmentType.informationAllocation,
      //       informationSetup: assessmentType.informationSetup
      //     }
      //   }
      // };
      // dispatch({ type: LOADER_START });
      // let assessmentTypeAssessmentReqBody = getAssessmentTypeAssessmentReqObj(
      //   selectedAssociateInfo,
      //   id,
      //   'active',
      //   0,
      //   countPage
      // );
      // dispatch({
      //   type: ASSESSMENT_TYPE_REVISE_INFO_SAGA,
      //   payload: { headerOne: 'assessments', reqBody, assessmentTypeAssessmentReqBody, createMode }
      // });
    } else if (headerOneBadgeOne === 'type' && headerOne === 'assignments') {
      // debugger
      assignmentTypeReviseApiCall(
        responseObject,
        selectedAssociateInfo,
        assesseeGroupAssessee?.assesseeGroupAssesseeAllocate,
        assesseeGroupAssessee?.assesseeGroupAssesseeUnallocate,
        assignmentType,
        dispatch,
        createMode,
        false,
        assignmentCreateType
      );
      closeRelatedList(
        dispatch,
        createMode,
        responseObject,
        middlePaneHeaderDuplicate,
        middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderDuplicateBadgeTwo,
        middlePaneHeaderDuplicateBadgeThree,
        middlePaneHeaderDuplicateBadgeFour,
        'assignmentsTypeDistinctReviewList',
        duplicateScanCount,
        'assignmentsTypeDistinct'
      );
      // const { associateId, id } = responseObject;
      // console.log(assignmentType);
      // const reqBody = {
      //   module: 'Assignment-Type',
      //   action: 'Revise',
      //   requestObject: {
      //     assesseeId: selectedAssociateInfo?.assesseeId,
      //     associateId:
      //       selectedAssociateInfo?.associate?.informationEngagement.associateTag
      //         .associateTagPrimary,
      //     assignmentTypeAssignment: {
      //       assignmentTypeAssignmentAllocate:
      //         assesseeGroupAssessee?.assesseeGroupAssesseeAllocate || [],
      //       assignmentTypeAssignmentUnallocate:
      //         assesseeGroupAssessee?.assesseeGroupAssesseeUnallocate || []
      //     },
      //     assignmentType: {
      //       id,
      //       informationBasic: assignmentType.informationBasic,
      //       informationAllocation: assignmentType.informationAllocation,
      //       informationSetup: assignmentType.informationSetup
      //     }
      //   }
      // };
      // dispatch({ type: LOADER_START });
      // let assignmentTypeAssignmentReqBody = {
      //   module: 'Assignment-Type',
      //   action: 'Review-List',
      //   actionFilter: 'Assignment-Type-Assignment',
      //   requestObject: getAssignmentTypeAssignmentReqObj(
      //     selectedAssociateInfo,
      //     id,
      //     'active',
      //     0,
      //     countPage
      //   )
      // };
      // dispatch({
      //   type: ASSIGNMENT_TYPE_REVISE_INFO_SAGA,
      //   payload: { headerOne: 'assignment', reqBody, assignmentTypeAssignmentReqBody, createMode }
      // });
    } else if (headerOneBadgeOne === 'type' && headerOne === 'culture-profiles') {
      cultureProfileTypeReviseApicall(
        responseObject,
        selectedAssociateInfo,
        assesseeGroupAssessee?.assesseeGroupAssesseeAllocate,
        assesseeGroupAssessee?.assesseeGroupAssesseeUnallocate,
        cultureProfileType,
        dispatch,
        createMode
      );
      closeRelatedList(
        dispatch,
        createMode,
        responseObject,
        middlePaneHeaderDuplicate,
        middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderDuplicateBadgeTwo,
        middlePaneHeaderDuplicateBadgeThree,
        middlePaneHeaderDuplicateBadgeFour,
        'cultureProfilesTypeDistinctReviewList',
        duplicateScanCount,
        'cultureProfileTypeDistinct'
      );
      // const { associateId, id } = responseObject;
      // const reqBody = {
      //   module: 'Culture-Profile-Type',
      //   action: 'Revise',
      //   requestObject: {
      //     assesseeId: selectedAssociateInfo?.assesseeId,
      //     associateId,
      //     cultureProfileTypeCultureProfile: {
      //       cultureProfileTypeCultureProfileAllocate:
      //         assesseeGroupAssessee?.assesseeGroupAssesseeAllocate || [],
      //       cultureProfileTypeCultureProfileUnallocate:
      //         assesseeGroupAssessee?.assesseeGroupAssesseeUnallocate || []
      //     },
      //     cultureProfileType: {
      //       id,
      //       informationBasic: cultureProfileType.informationBasic,
      //       informationAllocation: cultureProfileType.informationAllocation,
      //       informationSetup: cultureProfileType.informationSetup
      //     }
      //   }
      // };
      // dispatch({ type: LOADER_START });
      // let cultureTypeCultureReqBody = getCultureTypeCultureReqObj(
      //   selectedAssociateInfo,
      //   id,
      //   'active',
      //   0,
      //   countPage
      // );
      // dispatch({
      //   type: CULTURE_TYPE_REVISE_INFO_SAGA,
      //   payload: { headerOne: 'culture-profiles', reqBody, cultureTypeCultureReqBody, createMode }
      // });
    } else if (headerOneBadgeOne === 'type' && headerOne === 'job-profiles') {
      jobProfileTypeReviseApiCall(
        responseObject,
        selectedAssociateInfo,
        assesseeGroupAssessee?.assesseeGroupAssesseeAllocate,
        assesseeGroupAssessee?.assesseeGroupAssesseeUnallocate,
        jobProfileType,
        dispatch,
        createMode
      );
      closeRelatedList(
        dispatch,
        createMode,
        responseObject,
        middlePaneHeaderDuplicate,
        middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderDuplicateBadgeTwo,
        middlePaneHeaderDuplicateBadgeThree,
        middlePaneHeaderDuplicateBadgeFour,
        'jobProfilesTypeDistinctReviewList',
        duplicateScanCount,
        'jobProfileTypeDistinct'
      );
      // const { associateId, id } = responseObject;
      // const reqBody = {
      //   module: 'Job-Profile-Type',
      //   action: 'Revise',
      //   requestObject: {
      //     assesseeId: selectedAssociateInfo?.assesseeId,
      //     associateId,
      //     jobProfileTypeJobProfile: {
      //       jobProfileTypeJobProfileAllocate:
      //         assesseeGroupAssessee?.assesseeGroupAssesseeAllocate || [],
      //       jobProfileTypeJobProfileUnallocate:
      //         assesseeGroupAssessee?.assesseeGroupAssesseeUnallocate || []
      //     },
      //     jobProfileType: {
      //       id,
      //       informationBasic: jobProfileType.informationBasic,
      //       informationAllocation: jobProfileType.informationAllocation,
      //       informationSetup: jobProfileType.informationSetup
      //     }
      //   }
      // };
      // dispatch({ type: LOADER_START });
      // let jobTypeJobReqBody = getJobProfileTypeJobProfileReqObj(
      //   selectedAssociateInfo,
      //   id,
      //   'active',
      //   0,
      //   countPage
      // );
      // dispatch({
      //   type: JOB_TYPE_REVISE_INFO_SAGA,
      //   payload: { headerOne: 'job-profiles', reqBody, jobTypeJobReqBody, createMode }
      // });
    } else if (headerOneBadgeOne === 'type' && headerOne === 'items') {
      itemTypeReviseApicall(
        responseObject,
        selectedAssociateInfo,
        assesseeGroupAssessee?.assesseeGroupAssesseeAllocate,
        assesseeGroupAssessee?.assesseeGroupAssesseeUnallocate,
        itemType,
        dispatch,
        createMode
      );
      closeRelatedList(
        dispatch,
        createMode,
        responseObject,
        middlePaneHeaderDuplicate,
        middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderDuplicateBadgeTwo,
        middlePaneHeaderDuplicateBadgeThree,
        middlePaneHeaderDuplicateBadgeFour,
        'itemsTypeDistinctReviewList',
        duplicateScanCount,
        'itemsTypeDistinct'
      );
      // const { associateId, id } = responseObject;
      // console.log(itemType);
      // const reqBody = {
      //   action: 'Revise',
      //   module: 'Item-Type',
      //   requestObject: {
      //     assesseeId: selectedAssociateInfo?.assesseeId,
      //     associateId,
      //     itemTypeItem: {
      //       itemTypeItemAllocate: assesseeGroupAssessee?.assesseeGroupAssesseeAllocate || [],
      //       itemTypeItemUnallocate: assesseeGroupAssessee?.assesseeGroupAssesseeUnallocate || []
      //     },
      //     itemType: {
      //       id,
      //       informationBasic: itemType.informationBasic,
      //       informationSetup: itemType.informationSetup
      //     }
      //   }
      // };
      // dispatch({ type: LOADER_START });
      // let itemTypeItemReqBody = getItemTypeItemReqObj(
      //   selectedAssociateInfo,
      //   id,
      //   'active',
      //   0,
      //   countPage
      // );
      // dispatch({
      //   type: ITEM_TYPE_REVISE_INFO_SAGA,
      //   payload: { headerOne: 'items', reqBody, itemTypeItemReqBody, createMode }
      // });
    } else if (headerOneBadgeOne === 'type' && headerOne === 'associates') {
      associateTypeReviseApicall(
        responseObject,
        selectedAssociateInfo,
        assesseeRoleAssessee?.assesseeRoleAssesseeAllocate,
        assesseeRoleAssessee?.assesseeRoleAssesseeUnallocate,
        associateType,
        dispatch,
        createMode
      );
      closeRelatedList(
        dispatch,
        createMode,
        responseObject,
        middlePaneHeaderDuplicate,
        middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderDuplicateBadgeTwo,
        middlePaneHeaderDuplicateBadgeThree,
        middlePaneHeaderDuplicateBadgeFour,
        'associatesTypeDistinctReviewList',
        duplicateScanCount,
        'associatesTypeDistinct'
      );
      // const { associateId, id } = responseObject;
      // console.log(associateType);
      // const reqBody = {
      //   module: 'Associate-Type',
      //   action: 'Revise',
      //   requestObject: {
      //     assesseeId: selectedAssociateInfo?.assesseeId,
      //     associateId,
      //     associateTypeAssociate: {
      //       associateTypeAssociateAllocate:
      //         assesseeRoleAssessee?.assesseeRoleAssesseeAllocate || [],
      //       associateTypeAssociateUnallocate:
      //         assesseeRoleAssessee?.assesseeRoleAssesseeUnallocate || []
      //     },
      //     associateType: {
      //       id,
      //       informationBasic: associateType.informationBasic,
      //       informationAllocation: associateType.informationAllocation,
      //       informationSetup: associateType.informationSetup
      //     }
      //   }
      // };
      // dispatch({ type: LOADER_START });
      // let associateTypeAssociateReqBody = getAssociateTypeAssociateReqObj(
      //   selectedAssociateInfo,
      //   id,
      //   'active',
      //   0,
      //   countPage
      // );
      // dispatch({
      //   type: ASSOCIATE_TYPE_INFO_REVISE_SAGA,
      //   payload: { headerOne: 'associates', reqBody, associateTypeAssociateReqBody, createMode }
      // });
    } else if (headerOneBadgeOne === 'distinct' && headerOne === 'job-profile') {
      const { informationBasic, informationAllocation, informationFramework, informationSetup } =
        jobProfileInformation;
      const { id } = responseObject;
      let isValidate = true;
      let rangeCount = 0;
      let weightageCount = 0;
      let coreIdArr = [];
      let coreArrList = informationFramework?.jobProfileJobCompetencyCore || [];
      let CoreObjArrList = informationFramework?.jobProfileJobCompetencyCoreObj || [];
      coreArrList.forEach((element) => {
        if (
          element.iGaugeOccupationFrameworkOneSelected !== 'removable' &&
          element.iGaugeOccupationFrameworkOneSelected !== ''
        ) {
          const rangeMax = element?.iGaugeOccupationFrameworkOneRangeExtremumMaximum || 0;
          if (rangeMax < 1) {
            rangeCount++;
          }
        }
        coreIdArr.push(element.id);
      });
      coreArrList.forEach((element) => {
        if (
          element.iGaugeOccupationFrameworkOneSelected !== 'removable' &&
          element.iGaugeOccupationFrameworkOneSelected !== ''
        ) {
          const weightageNum = element?.iGaugeOccupationFrameworkOneWeightage || 0;
          if (weightageNum < 1) {
            weightageCount++;
          }
        }
      });
      if (rangeCount > 0) {
        setIsShowReviseIcon(true);
        isValidate = false;
        let obj = {
          ...informationFramework,
          jobProfileJobCompetencyCore: coreIdArr
        };
        dispatch({
          type: SET_JOB_SIFTLIST_STATE,
          payload: obj
        });
        dispatch({ type: LOADER_STOP });
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'POPUPRANGEMSG', popupMode: 'JOBCREATE' }
        });
        return;
      }
      if (weightageCount > 0) {
        setIsShowReviseIcon(true);
        isValidate = false;
        dispatch({ type: LOADER_STOP });
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'POPUPWEIGHTEMSG', popupMode: 'JOBCREATE' }
        });
        return;
      }
      if (isValidate) {
        let selectList = [];
        let shortList = [];
        coreArrList.forEach((element) => {
          selectList.push({
            iGaugeOccupationFrameworkOneTag: element.id,
            iGaugeOccupationFrameworkOneRangeExtremumMinimum:
              element.iGaugeOccupationFrameworkOneRangeExtremumMinimum,
            iGaugeOccupationFrameworkOneRangeExtremumMaximum:
              element.iGaugeOccupationFrameworkOneRangeExtremumMaximum,
            iGaugeOccupationFrameworkOneWeightage: element.iGaugeOccupationFrameworkOneWeightage
          });
        });
        CoreObjArrList.forEach((element) => {
          shortList.push({
            iGaugeOccupationFrameworkOneTag: element.id,
            iGaugeOccupationFrameworkOneSelected: element.iGaugeOccupationFrameworkOneSelected
          });
        });
        console.log('CoreObjArrList-', CoreObjArrList);
        dispatch({ type: SETJOB_WEIGHTAGE_SELECTED, payload: false });
        dispatch({ type: SET_RANGE_SELECTED, payload: false });
        const reqBody = {
          module: 'Job-Profile-Distinct',
          action: 'Revise',
          requestObject: {
            assesseeId: selectedAssociateInfo?.assesseeId,
            associateId:
              selectedAssociateInfo?.associate?.informationEngagement.associateTag
                .associateTagPrimary,
            jobProfile: {
              id,
              informationBasic,
              informationAllocation,
              informationSetup,
              informationFramework: {
                jobProfileJobDomain: informationFramework?.jobProfileJobDomain || [],
                jobProfileJobFunction: informationFramework?.jobProfileJobFunction || [],
                jobProfileJobRole: informationFramework?.jobProfileJobRole || [],
                jobProfileJobCompetencySelectList: selectList,
                jobProfileJobCompetencyShortList: shortList
              }
            }
          }
        };

        console.log('reqBody', reqBody);
        dispatch({ type: LOADER_START });
        dispatch({
          type: JOB_PROFILE_INFO_REVISE_SAGA,
          payload: {
            secondaryOptionCheckValue: headerOneBadgeTwo,
            headerOne: 'job-profile',
            reqBody,
            headerOneBadgeOne,
            headerOneBadgeThree,
            createMode,
            typeOfMiddlePaneList
          }
        });
      }
    } else if (headerOneBadgeOne === 'distinct' && headerOne === 'culture-profile') {

      const { informationBasic, informationAllocation, informationFramework, informationSetup } =
        cultureProfileInformation;
      const { id } = responseObject;
      let cultureDimensionCore = informationFramework?.cultureProfileCultureDimensionCoreObj || [];
      let count = 0;
      cultureDimensionCore.forEach((element) => {
        const num = element?.iGaugeOccupationFrameworkOneWeightage || 0;
        if (num < 1) {
          count++;
        }
      });
      if (count > 0) {
        setIsShowReviseIcon(true);
        dispatch({ type: LOADER_STOP });
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'POPUPWEITAGENMSG', popupMode: 'CULTURECREATE' }
        });
        return;
      }
      let selectList = [];
      // informationFramework?.cultureProfileCultureDimensionCoreObj.forEach((ee) => {
      //   selectList.push({
      //     iGaugeOccupationFrameworkOneTag: ee.iGaugeOccupationFrameworkOneTag,
      //     iGaugeOccupationFrameworkOneClusterSelected: ee.iGaugeOccupationFrameworkOneClusterSelected,
      //     iGaugeOccupationFrameworkOneWeightage: ee.iGaugeOccupationFrameworkOneWeightage
      //   });
      // });
      const reqBody = {
        module: 'Culture-Profile-Distinct',
        action: 'Revise',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          cultureProfile: {
            id,
            informationBasic,
            informationAllocation,
            informationSetup,
            informationFramework: {
              cultureProfileCultureDimensionSelectList:
                informationFramework?.cultureProfileCultureDimensionSelectList || []
            }
          }
        }
      };
      console.log('reqBody', reqBody);
      dispatch({
        type: SET_WEIGHTAGE_SELECTED,
        payload: false
      });
      dispatch({ type: LOADER_START });
      dispatch({
        type: CULTURE_PROFILE_INFO_REVISE_SAGA,
        payload: {
          secondaryOptionCheckValue: headerOneBadgeTwo,
          headerOne: 'culture-profile',
          reqBody,
          headerOneBadgeOne,
          headerOneBadgeThree,
          createMode,
          typeOfMiddlePaneList
        }
      });
    } else if (headerOneBadgeOne === 'distinct' && headerOne === 'item') {
      const { informationBasic, informationAllocation, informationFramework, informationSetup } = itemInformation;
      const { id } = responseObject;
      let infoFrame = {
        ...informationFramework.itemFrameworkOne,
        itemFrameworkOneTime: converTimeToMiliseconds(
          informationFramework.itemFrameworkOne.itemFrameworkOneTime
        )
      };
      const reqBody = {
        module: 'Item-Distinct',
        action: 'Revise',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          item: {
            id,
            informationBasic: informationBasic,
            informationAllocation: informationAllocation,
            informationSetup: informationSetup,
            informationFramework: {
              itemFrameworkOne: infoFrame
            }
          }
        }
      };
      dispatch({ type: LOADER_START });
      dispatch({
        type: ITEM_INFO_REVISE_SAGA,
        payload: {
          secondaryOptionCheckValue: headerOneBadgeTwo,
          headerOne: 'item',
          reqBody,
          headerOneBadgeOne,
          headerOneBadgeThree,
          createMode,
          typeOfMiddlePaneList
        }
      });
    } else if (headerOneBadgeOne === 'distinct' && headerOne === 'assessment') {
      const { informationBasic, informationAllocation, informationFramework, informationSetup } =
        assessmentInfo;
      if (typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList') {
        let assignmentId = relatedReviewListDistinctData[0].id;
        let assessmentId = responseObject.id;
        let infoFrame = {
          ...informationFramework,
          assessmentTime: converTimeToMiliseconds(informationFramework.assessmentTime)
        };
        const reqBody = {
          module: 'Assignment-Distinct',
          action: 'Revise',
          actionFilter: 'Assignment-Assessment',
          requestObject: {
            assesseeId: selectedAssociateInfo?.assesseeId,
            associateId:
              selectedAssociateInfo?.associate?.informationEngagement.associateTag
                .associateTagPrimary,
            assignmentId: assignmentId,
            assignmentAssessment: {
              assessmentId: assessmentId,
              informationFramework: infoFrame
            }
          }
        };
        dispatch({ type: LOADER_START });
        dispatch({
          type: ASSIGNMENT_ASSESSMENT_INFO_REVISE_SAGA,
          payload: {
            secondaryOptionCheckValue: headerOneBadgeThree,
            headerOne: 'assessment',
            reqBody,
            createMode
          }
        });
      } else if (
        typeOfMiddlePaneList === 'assessmentDistinctReviewList' ||
        typeOfMiddlePaneList === 'assessmentTypeAssessmentReviewList' ||
        typeOfMiddlePaneList === 'assessmentGroupAssessmentReviewList' ||
        typeOfMiddlePaneList === 'assessmentNodeAssessmentReviewList' ||
        createMode === 'assessment'
      ) {
        if (informationFramework?.assessmentTime !== 0) {
          // let assessmentTimeConvesion=informationFramework?.assessmentTime.split(':');
          // let assessmentTimeMillisec=(Number(assessmentTimeConvesion[0])*60*60 +Number(assessmentTimeConvesion[1])*60)*1000;
          let assessmentTimeMillisec = converTimeToMiliseconds(
            informationFramework?.assessmentTime
          );
          console.log('assessmentTimeMillisec', assessmentTimeMillisec);
          informationFramework.assessmentTime = assessmentTimeMillisec;
        }
        informationFramework.assessmentNormFramework = [];
        informationFramework.assessmentSectionFramework.forEach((element) => {
          delete element.assessmentVersion;
          delete element.assessmentSectionItemDistinctRevise;
        });

        // console.log('informationFrameworkupdate', informationFramework);

        const { id } = responseObject;
        const reqBody = {
          module: 'Assessment-Distinct',
          action: 'Revise',
          requestObject: {
            assesseeId: selectedAssociateInfo?.assesseeId,
            associateId:
              selectedAssociateInfo?.associate?.informationEngagement.associateTag
                .associateTagPrimary,
            assessment: {
              id,
              informationBasic,
              informationAllocation,
              informationSetup,
              informationFramework:
                delete informationFramework.assessmentSectionItemDistinctRevise &&
                informationFramework
            }
          }
        };
        console.log('ASSESSMENT REVISE fododd===', reqBody);
        dispatch({ type: LOADER_START });
        dispatch({
          type: ASSESSMENT_INFO_REVISE_SAGA,
          payload: {
            secondaryOptionCheckValue: headerOneBadgeThree,
            headerOne: 'assessment',
            headerOneBadgeOne,
            headerOneBadgeThree,
            reqBody,
            createMode,
            typeOfMiddlePaneList
          }
        });
      } else {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: CLOSE_MIDDLEPANLIST_ERROR, popupMode: 'responseErrorMsg' }
        });
      }
    } else if (headerOneBadgeOne === 'distinct' && headerOne === 'assignment') {
      const { informationBasic, informationAllocation, informationFramework, informationSetup } =
        assignmentInfo;
      console.log('assignmentInfo', assignmentInfo);
      const { id } = responseObject;
      let frameworkObj = {
        ...informationFramework,
        assignmentAssesseeMineDistinct: assignmentAssesseeMineDistinct || [],
        assignmentAssesseeDistinct: assignmentAssesseeList || [],
        assignmentAssessmentDistinct: assignmentAssessmentList || [],
        assignmentCultureProfileDistinct: assignmentCultureProfileList || [],
        assignmentJobProfileDistinct: assignmentJobProfileList || [],
        assignmentAssesseeGroup: assignmentAssesseeGroupList || [],
        assignmentAssessmentGroup: assignmentAssessmentGroupList || [],
        assignmentCultureProfileGroup: assignmentCultureProfileGroupList || [],
        assignmentJobProfileGroup: assignmentJobProfileGroupList || [],
        assignmentAssesseeMineGroup: assignmentAssesseeMineGroup || []
      };
      const reqBody = {
        module: 'Assignment-Distinct',
        action: 'Revise',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          assignment: {
            id,
            informationBasic,
            informationAllocation,
            informationFramework: frameworkObj,
            assignmentDistinctEvaluationRef: assignmentInfo?.assignmentDistinctEvaluationRef,
            assignmentDistinctEvaluationRefId: responseObject?.assignmentDistinctEvaluationRefId,
            informationSetup: informationSetup
          }
        }
      };
      if (createMode === 'assignment') {
        dispatch({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: '',
            middlePaneHeaderBadgeOne: '',
            middlePaneHeaderBadgeTwo: '',
            middlePaneHeaderBadgeThree: '',
            middlePaneHeaderBadgeFour: '',
            typeOfMiddlePaneList: '',
            scanCount: '',
            showMiddlePaneState: false
          }
        });
      }
      dispatch({ type: LOADER_START });
      dispatch({
        type: ASSIGNMENT_INFO_REVISE_SAGA,
        payload: {
          secondaryOptionCheckValue: headerOneBadgeTwo,
          headerOne: 'assignment',
          headerOneBadgeOne,
          headerOneBadgeThree,
          reqBody,
          createMode,
          typeOfMiddlePaneList
        }
      });
    } else if (
      (headerOneBadgeOne === 'distinct' || headerOneBadgeOne === 'self') &&
      headerOne === 'associate'
    ) {
      const {
        informationBasic,
        informationAllocation,
        informationContact,
        informationSetup,
        associateCountry,
        associateCurrency,
        associateLanguage,
        informationSetupPlus
      } = associateInfo;
      const updatedinformationSetup = {
        ...informationSetup.associate,
        associateAssociateSetupReset: false,
        associateCountry: {
          associateCountryTag: associateCountry.associateCountryTag,
          associateCountryName: associateCountry.associateCountryName,
          associateCountryFlag: associateCountry.associateCountryFlag
        },
        associateCurrency: {
          associateCurrencyName: associateCurrency.associateCurrencyName,
          associateCurrencySymbol: associateCurrency.associateCurrencySymbol,
          associateCurrencyTag: ''
        },
        associateLanguage
      };
      const updatedinformationSetupPlus = {
        ...informationSetupPlus.associate,
        associateAssociateSetupReset: true,
        associateCountry: {
          associateCountryTag: associateCountry.associateCountryTag,
          associateCountryName: associateCountry.associateCountryName,
          associateCountryFlag: associateCountry.associateCountryFlag
        },
        associateCurrency: {
          associateCurrencyName: associateCountry.associateCurrencyName,
          associateCurrencySymbol: associateCountry.associateCurrencySymbol,
          associateCurrencyTag: ''
        },
        associateLanguage
      };
      const { id, associateAssent } = responseObject;
      const reqBody = {
        module: 'Associate-Distinct',
        action: 'Revise',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          isAssociateSelf: headerOneBadgeOne === 'self',
          associate: {
            id,
            associateAssent,
            informationBasic,
            informationAllocation,
            informationContact: {
              associateAddressWebsitePrimary: informationContact.associateAddressWebsitePrimary,
              associateAddressWebsiteSecondary: informationContact.associateAddressWebsiteSecondary,
              associateAddressWorkPrimary: informationContact.associateAddressWorkPrimary,
              associateAddressWorkSecondary: informationContact.associateAddressWorkSecondary,
              associateTelephoneWorkPrimary:
                delete informationContact.associateTelephoneWorkPrimary
                  .associateTelephoneAreaCityTag &&
                informationContact.associateTelephoneWorkPrimary,
              associateTelephoneWorkSecondary:
                delete informationContact.associateTelephoneWorkSecondary
                  .associateTelephoneAreaCityTag &&
                informationContact.associateTelephoneWorkSecondary
            },
            informationFramework: {
              associateAscendantPrimary:
                associateInfo.informationFramework.associateAscendant.associateAscendantPrimary[0]
            }
          }
        }
      };
      // console.log(JSON.stringify(reqBody));
      dispatch({ type: LOADER_START });
      dispatch({
        type: ASSOCIATE_INFO_REVISE_SAGA,
        payload: {
          secondaryOptionCheckValue: headerOneBadgeTwo,
          headerOne: 'associate',
          headerOneBadgeOne,
          headerOneBadgeThree,
          reqBody,
          createMode,
          typeOfMiddlePaneList
        }
      });
      if (informationSetup.assessee) {
        let setupObj = {
          module: 'Assessee-Setup',
          action: 'Revise',
          requestObject: {
            assesseeId: selectedAssociateInfo?.assesseeId,
            associateId: id,
            assesseeAssociateSetup: {
              ...informationSetup.assessee,
              assesseeAssociateSetupReset: informationSetup.assessee.assesseeAssociateSetupReset
            }
          }
        };
        dispatch({
          type: ASSOCIATE_ASSESSEESETUP_REVISE_SAGA,
          payload: { reqBody: setupObj, reviseModule: 'bespoke' }
        });
      }
      if (informationSetup.assessment) {
        let setupObj = {
          module: 'Assessment-Setup',
          action: 'Revise',
          requestObject: {
            assesseeId: selectedAssociateInfo?.assesseeId,
            associateId: id,
            assessmentAssociateSetup: {
              ...informationSetup.assessment,
              assessmentAssociateSetupReset:
                informationSetup.assessment.assessmentAssociateSetupReset
            }
          }
        };
        dispatch({
          type: ASSOCIATE_ASSESSMENTSETUP_REVISE_SAGA,
          payload: { reqBody: setupObj, reviseModule: 'bespoke' }
        });
      }
      if (informationSetup.assignment) {
        let setupObj = {
          module: 'Assignment-Setup',
          action: 'Revise',
          requestObject: {
            assesseeId: selectedAssociateInfo?.assesseeId,
            associateId: id,
            assignmentAssociateSetup: {
              ...informationSetup.assignment,
              assignmentAssociateSetupReset:
                informationSetup.assignment.assignmentAssociateSetupReset
            }
          }
        };
        dispatch({
          type: ASSOCIATE_ASSIGNMENTSETUP_REVISE_SAGA,
          payload: { reqBody: setupObj, reviseModule: 'bespoke' }
        });
      }
      if (informationSetup.associate) {
        let setupObj = {
          module: 'Associate-Setup',
          action: 'Revise',
          requestObject: {
            assesseeId: selectedAssociateInfo?.assesseeId,
            associateId: id,
            //associateSetup: informationSetup.associate
            associateSetup: updatedinformationSetup
          }
        };
        dispatch({
          type: ASSOCIATE_ASSOCIATESETUP_REVISE_SAGA,
          payload: { reqBody: setupObj, reviseModule: 'bespoke' }
        });
      }
      if (informationSetup.associateNode) {
        let setupObj = {
          module: 'Associate-Node-Permission',
          action: 'Revise',
          requestObject: {
            assesseeId: selectedAssociateInfo?.assesseeId,
            associateId: id,
            associateNodePermission: informationSetup.associateNode
          }
        };
        dispatch({
          type: ASSOCIATE_ASSOCIATENODE_SETUP_REVISE_SAGA,
          payload: { reqBody: setupObj, reviseModule: 'bespoke' }
        });
      }
      if (informationSetup.item) {
        let setupObj = {
          module: 'Item-Setup',
          action: 'Revise',
          requestObject: {
            assesseeId: selectedAssociateInfo?.assesseeId,
            associateId: id,
            itemAssociateSetup: {
              ...informationSetup.item,
              itemAssociateSetupReset: informationSetup.item.itemAssociateSetupReset
            }
          }
        };
        dispatch({
          type: ASSOCIATE_ITEMSETUP_REVISE_SAGA,
          payload: { reqBody: setupObj, reviseModule: 'bespoke' }
        });
      }
      if (informationSetup.analytic) {
        let setupObj = {
          module: 'Job-Profile-Setup',
          action: 'Revise',
          requestObject: {
            assesseeId: selectedAssociateInfo?.assesseeId,
            associateId: id,
            iguruAnalyticAssociateSetup: {
              ...informationSetup.analytic,
              iGuruAnalyticAssociateSetupReset: informationSetup.analytic.iGuruAnalyticAssociateSetupReset
            }
          }
        };
        dispatch({
          type: ASSOCIATE_ANALYTICSETUP_REVISE_SAGA,
          payload: { reqBody: setupObj, reviseModule: 'bespoke' }
        });
      }
      if (informationSetupPlus.assessee) {
        let setupObj = {
          module: 'Assessee-Setup',
          action: 'Revise',
          requestObject: {
            assesseeId: selectedAssociateInfo?.assesseeId,
            associateId: id,
            assesseeAssociateSetup: {
              ...informationSetupPlus.assessee,
              assesseeAssociateSetupReset: true
            }
          }
        };
        dispatch({
          type: ASSOCIATE_ASSESSEESETUP_REVISE_SAGA,
          payload: { reqBody: setupObj, reviseModule: 'generic' }
        });
      }
      if (informationSetupPlus.assessment) {
        let setupObj = {
          module: 'Assessment-Setup',
          action: 'Revise',
          requestObject: {
            assesseeId: selectedAssociateInfo?.assesseeId,
            associateId: id,
            assessmentAssociateSetup: {
              ...informationSetupPlus.assessment,
              assessmentAssociateSetupReset: true
            }
          }
        };
        dispatch({
          type: ASSOCIATE_ASSESSMENTSETUP_REVISE_SAGA,
          payload: { reqBody: setupObj, reviseModule: 'generic' }
        });
      }
      if (informationSetupPlus.assignment) {
        let setupObj = {
          module: 'Assignment-Setup',
          action: 'Revise',
          requestObject: {
            assesseeId: selectedAssociateInfo?.assesseeId,
            associateId: id,
            assignmentAssociateSetup: {
              ...informationSetupPlus.assignment,
              assignmentAssociateSetupReset: true
            }
          }
        };
        dispatch({
          type: ASSOCIATE_ASSIGNMENTSETUP_REVISE_SAGA,
          payload: { reqBody: setupObj, reviseModule: 'generic' }
        });
      }
      if (informationSetupPlus.associate) {
        let setupObj = {
          module: 'Associate-Setup',
          action: 'Revise',
          requestObject: {
            assesseeId: selectedAssociateInfo?.assesseeId,
            associateId: id,
            //associateSetup: informationSetup.associate
            associateSetup: updatedinformationSetupPlus
          }
        };
        dispatch({
          type: ASSOCIATE_ASSOCIATESETUP_REVISE_SAGA,
          payload: { reqBody: setupObj, reviseModule: 'generic' }
        });
      }
      if (informationSetupPlus.associateNode) {
        let setupObj = {
          module: 'Associate-Node-Permission',
          action: 'Revise',
          requestObject: {
            assesseeId: selectedAssociateInfo?.assesseeId,
            associateId: id,
            associateNodePermission: informationSetup.associateNode
          }
        };
        dispatch({
          type: ASSOCIATE_ASSOCIATENODE_SETUP_REVISE_SAGA,
          payload: { reqBody: setupObj, reviseModule: 'generic' }
        });
      }
      if (informationSetupPlus.item) {
        let setupObj = {
          module: 'Item-Setup',
          action: 'Revise',
          requestObject: {
            assesseeId: selectedAssociateInfo?.assesseeId,
            associateId: id,
            itemAssociateSetup: { ...informationSetupPlus.item, itemAssociateSetupReset: true }
          }
        };
        dispatch({
          type: ASSOCIATE_ITEMSETUP_REVISE_SAGA,
          payload: { reqBody: setupObj, reviseModule: 'generic' }
        });
      }
      if (informationSetupPlus.analytic) {
        let setupObj = {
          module: 'Job-Profile-Setup',
          action: 'Revise',
          requestObject: {
            assesseeId: selectedAssociateInfo?.assesseeId,
            associateId: id,
            iguruAnalyticAssociateSetup: {
              ...informationSetupPlus.analytic,
              iGuruAnalyticAssociateSetupReset: true
            }
          }
        };
        dispatch({
          type: ASSOCIATE_ANALYTICSETUP_REVISE_SAGA,
          payload: { reqBody: setupObj, reviseModule: 'generic' }
        });
      }
    } else if (headerOneBadgeOne === 'scale' && headerOne === 'assessment') {
      let id = relatedReviewListDistinctData[0]?.id || scaleInfo?.scaleInformation?.assessmentId;
      if (typeOfMiddlePaneList === 'assessmentDistinctSubScaleDistinct') {
        scaleInfo.scaleInformation.assessmentScaleMetric[assessmentSelectedMetric] =
          scaleInfo?.assessmentScaleMetricInformation;
      }
      const reqBody = {
        module: 'Assessment-Distinct',
        action: 'Revise',
        actionFilter: 'Section-Cluster-Scale',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          assessmentId: id,
          assessmentScaleFramework: scaleInfo.scaleInformation
        }
      };
      dispatch({ type: LOADER_START });
      dispatch({
        type: ASSESSMENT_CLUSTER_REVISE_SAGA,
        payload: {
          secondaryOptionCheckValue: headerOneBadgeTwo,
          headerOne: 'assessment',
          reqBody,
          createMode,
          assessmentSector: 'scale',
          // selectedSector: parseInt(selectedTagValue),
          selectedSector: selectedTagValue,
          typeOfMiddlePaneList: typeOfMiddlePaneList
        }
      });
    } else if (headerOneBadgeOne === 'cluster' && headerOne === 'assessment') {
      let id = relatedReviewListDistinctData[0].id;
      const reqBody = {
        module: 'Assessment-Distinct',
        action: 'Revise',
        actionFilter: 'Section-Cluster-Scale',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          assessmentId: responseObject.assessmentId,
          assessmentClusterFramework: clusterInfo.clusterInformation,
          removeAssessmentClusterFramework: false
        }
      };
      dispatch({ type: LOADER_START });
      dispatch({
        type: ASSESSMENT_CLUSTER_REVISE_SAGA,
        payload: {
          secondaryOptionCheckValue: headerOneBadgeTwo,
          headerOne: 'assessment',
          reqBody,
          createMode,
          assessmentSector: 'cluster',
          selectedSector: selectedTagValue
        }
      });
    } else if (headerOneBadgeOne === 'norm' && headerOne === 'assessment') {
      let id = relatedReviewListDistinctData[0].id;
      const reqBody = {
        module: 'Assessment-Distinct',
        action: 'Revise',
        actionFilter: 'Section-Cluster-Scale',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          assessmentId: responseObject.assessmentId,
          assessmentNormFramework: {
            ...normInformation.normInformation,
            id: responseObject.id,
            assessmentId: responseObject.assessmentId
          },
          removeAssessmentNormFramework: false
        }
      };
      dispatch({ type: LOADER_START });
      dispatch({
        type: ASSESSMENT_CLUSTER_REVISE_SAGA,
        payload: {
          secondaryOptionCheckValue: headerOneBadgeTwo,
          headerOne: 'assessment',
          reqBody,
          createMode,
          assessmentSector: 'norm',
          selectedSector: selectedTagValue
        }
      });
    } else if (headerOneBadgeOne === 'score' && headerOne === 'assessment') {
      let id = relatedReviewListDistinctData[0].id;
      console.log('assessmentInfo--', assessmentInfo);

      let tempArr = [];
      assessmentResponseObject?.informationFramework?.assessmentScoreFramework?.forEach((ob) => {
        if (ob.id === scoreInfo.scoreInformation.id) {
          console.log('ob.id', ob.id);
          tempArr.push(scoreInfo.scoreInformation);
        } else {
          tempArr.push(ob);
        }
      });
      console.log('tempArr: ', tempArr);
      const reqBody = {
        module: 'Assessment-Distinct',
        action: 'Revise',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          //assessmentId: id,
          assessment: {
            id,
            informationBasic: assessmentInfo.informationBasic,
            informationAllocation: assessmentInfo.informationAllocation,
            informationSetup: assessmentInfo.informationSetup,
            informationFramework: {
              ...assessmentInfo.informationFramework,
              // assessmentScoreFramework: [scoreInfo.scoreInformation]
              assessmentScoreFramework: tempArr
            }
          }
        }
      };

      console.log('scoreInfo', scoreInfo);
      console.log('ASSESSMENT REVISE ===', reqBody);
      dispatch({ type: LOADER_START });
      dispatch({
        type: ASSESSMENT_INFO_REVISE_SAGA,
        payload: {
          secondaryOptionCheckValue: headerOneBadgeThree,
          headerOne: 'assessment',
          headerOneBadgeOne,
          headerOneBadgeThree,
          reqBody,
          createMode,
          assessmentSector: 'score',
          selectedSector: selectedTagValue
        }
      });
    } else if (headerOneBadgeOne === 'grade' && headerOne === 'assessment') {
      let id = relatedReviewListDistinctData[0].id;
      const reqBody = {
        module: 'Assessment-Distinct',
        action: 'Revise',
        actionFilter: 'Section-Cluster-Scale',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          assessmentId: id,
          assessmentScoreGradeFramework: gradeInfo.gradeInformation,
          removeAssessmentScoreGradeFramework: false
        }
      };
      dispatch({ type: LOADER_START });
      dispatch({
        type: ASSESSMENT_CLUSTER_REVISE_SAGA,
        payload: {
          secondaryOptionCheckValue: headerOneBadgeTwo,
          headerOne: 'assessment',
          reqBody,
          createMode,
          assessmentSector: 'grade',
          selectedSector: selectedTagValue
        }
      });
    } else if (headerOneBadgeOne === 'version' && headerOne === 'assessment') {
      let sectionObj = assessmentInfo.informationFramework.assessmentSectionFramework;
      let itemArr = [];
      let assessmentVersionItemDistinct =
        assesseeAssessmentStartData?.assessmentVersionItemDistinct || [];
      assessmentVersionItemDistinct.length > 0 &&
        assessmentVersionItemDistinct.map((dd) => {
          itemArr.push({ itemSequence: dd.itemSequence, itemTagPrimary: dd.itemTagPrimary });
        });
      let dddd = {
        assessmentVersionName: versionInfo.versionInformation.assessmentVersionName,
        assessmentVersionVerification: versionInfo.versionInformation.assessmentVersionVerification,
        assessmentVersionDescription: versionInfo.versionInformation.assessmentVersionDescription,
        assessmentVersionItemDistinct: versionInfo.versionInformation.assessmentVersionItemDistinct
      };
      sectionObj[assessmentSelecedSection].assessmentVersionFramework[assessmentSelecedVersion] =
        dddd;
      let id = relatedReviewListDistinctData[0].id;
      const reqBody = {
        module: 'Assessment-Distinct',
        action: 'Revise',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          assessment: {
            id,
            informationBasic: assessmentInfo.informationBasic,
            informationAllocation: assessmentInfo.informationAllocation,
            informationSetup: assessmentInfo.informationSetup,
            informationFramework: {
              ...assessmentInfo.informationFramework,
              assessmentSectionFramework: delete sectionObj.assessmentVersion && sectionObj
            }
          }
        }
      };
      console.log('ASSESSMENT REVISE version===', reqBody);
      dispatch({ type: LOADER_START });
      dispatch({ type: CLEAR_VERSION_REDUCER_STATE });
      dispatch({
        type: ASSESSMENT_INFO_REVISE_SAGA,
        payload: {
          secondaryOptionCheckValue: headerOneBadgeThree,
          headerOne: 'assessment',
          headerOneBadgeOne,
          headerOneBadgeThree,
          reqBody,
          createMode,
          assessmentSector: 'version',
          selectedSector: assessmentSelecedSection,
          selectedVersion: assessmentSelecedVersion
        }
      });
    } else if (headerOneBadgeOne === 'section' && headerOne === 'assessment') {
      let sectionObj = assessmentInfo.informationFramework.assessmentSectionFramework;
      if (sectionInformation?.assessmentSectionTime !== 0) {
        let assessmentSectionTimeMillisec = converTimeToMiliseconds(
          sectionInformation?.assessmentSectionTime
        );
        sectionInformation.assessmentSectionTime = assessmentSectionTimeMillisec;
      }
      sectionObj[selectedTagValue] = sectionInformation;

      let id = relatedReviewListDistinctData[0].id;
      const reqBody = {
        module: 'Assessment-Distinct',
        action: 'Revise',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          assessment: {
            id,
            informationBasic: assessmentInfo.informationBasic,
            informationAllocation: assessmentInfo.informationAllocation,
            informationSetup: assessmentInfo.informationSetup,
            informationFramework: {
              ...assessmentInfo.informationFramework,
              assessmentSectionFramework: delete sectionObj.assessmentVersion && sectionObj
            }
          }
        }
      };
      console.log('ASSESSMENT REVISE sectijnn===', reqBody);
      dispatch({ type: LOADER_START });
      dispatch({
        type: ASSESSMENT_INFO_REVISE_SAGA,
        payload: {
          secondaryOptionCheckValue: headerOneBadgeTwo,
          headerOne: 'assessment',
          headerOneBadgeOne,
          headerOneBadgeThree,
          reqBody,
          createMode,
          assessmentSector: 'section',
          selectedSector: selectedTagValue
        }
      });
    } else if (headerOneBadgeOne === 'distinct' && headerOne === 'gauge') {
      let gaugeInformationrmation = guageInfo?.gaugeInformationrmation;
      let gaugeInfoData = guageInfo?.gaugeInformation;
      const { id } = responseObject;
      console.log('responseObject', responseObject);
      if (assignmentSelectedGuage) {
        gaugeInfoData.iGaugeOccupationFrameworkOneGaugeMetric[assignmentSelectedGuage] =
          gaugeInformationrmation;
      }
      const reqBody = {
        module: 'Analytics',
        action: 'Create-Gauge',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          iGaugeOccupationFrameworkOneGauge: { ...gaugeInfoData, id },
          action: 'revise'
        }
      };
      console.log('gauge REVISE ===', reqBody);
      dispatch({ type: LOADER_START });
      dispatch({
        type: CREATE_GAUGE_SAGA,
        payload: {
          reqBody,
          createMode,
          action: 'revise',
          afterAction: assignmentSelectedGuage ? 'reviewMetricReviewList' : 'reviewList',
          assignmentSelectedGuage: parseInt(assignmentSelectedGuage)
        }
      });
    } else if (headerOneBadgeOne === 'distinct' && headerOne === 'metrics') {
      let gaugeInformationrmation = guageInfo?.gaugeInformationrmation;
      let gaugeInfoData = guageInfo?.gaugeInformation;
      console.log('gaugeInformationrmation===', gaugeInformationrmation);
      console.log('gaugeInfoData ===', gaugeInfoData);
      console.log('SelectedGuageID ===', assignmentSelectedGuage);
      if (assignmentSelectedGuage) {
        gaugeInfoData.iGaugeOccupationFrameworkOneGaugeMetric[assignmentSelectedGuage] =
          gaugeInformationrmation;
      }
      const reqBody = {
        module: 'Analytics',
        action: 'Create-Gauge',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          iGaugeOccupationFrameworkOneGauge: gaugeInfoData,
          action: 'revise'
        }
      };
      console.log('gauge REVISE ===', reqBody);
      dispatch({ type: LOADER_START });
      dispatch({
        type: CREATE_GAUGE_SAGA,
        payload: {
          reqBody,
          createMode,
          action: 'revise',
          afterAction: assignmentSelectedGuage ? 'reviewMetricReviewList' : 'reviewList',
          assignmentSelectedGuage: parseInt(assignmentSelectedGuage)
        }
      });
    } else if (headerOneBadgeOne === 'gauge' && headerOne === 'assignment') {
      let gaugeInformationrmation = guageInfo?.gaugeInformationrmation;
      let gaugeInfoData = guageInfo?.gaugeInformation;
      if (assignmentSelectedGuage) {
        gaugeInfoData.iGaugeOccupationFrameworkOneGaugeMetric[assignmentSelectedGuage] =
          gaugeInformationrmation;
      }
      const reqBody = {
        module: 'Assignment-Distinct',
        action: 'Revise',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          assignment: {
            id: responseObject?.assignmentId,
            informationFramework: assignmentResponseObject?.informationFramework
          }
        }
      };
      console.log('assignment gauge REVISE ===', assignmentResponseObject);
      dispatch({ type: LOADER_START });
      dispatch({
        type: ASSIGNMENT_INFO_REVISE_SAGA,
        payload: {
          secondaryOptionCheckValue: headerOneBadgeTwo,
          headerOne: 'assignment',
          headerOneBadgeOne,
          headerOneBadgeThree,
          reqBody,
          createMode
        }
      });
    } else if (
      headerOneBadgeOne === 'distinct' &&
      (headerOne === 'job-function' ||
        headerOne === 'culture-dimension' ||
        headerOne === 'job-domain' ||
        headerOne === 'job-role')
    ) {
      const profilerInformation = culturejobProfilerInformation;
      const information = culturejobProfiler;
      // console.log('profilerInformation', profilerInformation);
      console.log('information', information);
      console.log('clusterProfilerInformation', clusterProfilerInformation);
      let requestBody = {
        module: 'Profiler',
        action: 'Create',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          action: 'Revise',
          iGaugeOccupationFrameworkOne: {
            id: information.id,
            iGaugeOccupationFrameworkOneClusterName: information.cluster,
            iGaugeOccupationFrameworkOneClusterDescription:
              culturejobProfilerInformation.culturejobProfilerDescription,
            iGaugeOccupationFrameworkOneClusterSequence: parseInt(information.sequence),
            iGaugeOccupationFrameworkOneClusterExplanation: information.explanation
            // iGaugeOccupationFrameworkOneClusterOneLabel: culturejobProfilerInformation.culturejobProfilerName,
            // iGaugeOccupationFrameworkOneClusterOnePolarity: information.polarity
          },
          sorted: true
        }
      };
      console.log('requestBody', requestBody);
      dispatch({ type: LOADER_START });
      dispatch({
        type: GET_CULTURE_DIAMENTION_SAGA,

        payload: {
          request: requestBody,
          headerOne,
          typeOfMiddlePaneList,
          reviseMode: true,
          selectedTag: information.id
        }
      });
    } else if (
      headerOneBadgeOne === 'distinct' &&
      (headerOne === 'cluster' || headerOne === 'job-competency')
    ) {
      const clusterProfilerInfo = clusterProfilerInformation;
      console.log('clusterProfilerInfo', clusterProfilerInfo);
      let requestBody = {
        module: 'Profiler',
        action: 'Create',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          action: 'Revise',
          iGaugeOccupationFrameworkOne: clusterProfilerInfo,
          sorted: true
        }
      };
      dispatch({ type: LOADER_START });
      dispatch({
        type: GET_CULTURE_DIAMENTION_SAGA,
        payload: {
          request: requestBody,
          headerOne,
          typeOfMiddlePaneList,
          reviseMode: true,
          selectedTag: selectedTagValue
        }
      });
    } else {
      dispatch({ type: SET_DISPLAY_PANE_THREE_REVIEW_MODE, payload: 'review' });
    }
    setIsShowReviseIcon(true);
  };
  const onClickCreateAssessee = () => {
    console.log('ON CLICK CREATE ASSESSEE');
    dispatch({ type: ASSESSEE_INFO_CREATE });
    dispatch({
      type: ASSESSEE_SIGN_ON,
      payload: {
        isPopUpValue: 'ASSESSEENAMEPOPUP',
        popupMode: 'ASSESSEE_CREATE',
        popupHeaderOne: headerOne
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'selectedInformationAllorKey', value: headerOneBadgeThree }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'typeOfAssesseeCreate',
        value: headerOne
      }
    });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  };
  const onClickCreateAssociate = () => {
    console.log('ON CLICK CREATE ASSOCIATE');
    dispatch({ type: ASSOCIATE_CREATE_INFO });
    let parentAssociateId =
      selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary;
    associateCreatePopup(selectedAssociateInfo, dispatch, '', '', parentAssociateId);
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  };
  const onClickCreateAssessment = () => {
    console.log('ON CLICK CREATE ASSESSMENT');
    dispatch({ type: CLEAR_ASSESSMENT_INFO });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'selectedInformationAllorKey', value: headerOneBadgeThree }
    });
    dispatch({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'ASSESSMENTCREATE' }
    });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  };

  const onClickCreateCultureProfile = () => {
    console.log('ON CLICK CREATE CultureProfile');
    dispatch({ type: CLEAR_CULTURE_REDUCER_STATE });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'selectedInformationAllorKey', value: headerOneBadgeThree }
    });
    dispatch({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'CULTURECREATE' }
    });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  };
  const onClickCreateJobProfile = () => {
    console.log('ON CLICK CREATE CultureProfile');
    dispatch({ type: CLEAR_JOB_REDUCER_STATE });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'selectedInformationAllorKey', value: headerOneBadgeThree }
    });
    dispatch({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'JOBCREATE' }
    });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  };

  const onClickCreateAssignment = () => {
    console.log('ON CLICK CREATE ASSIGNMENT');
    dispatch({ type: CLEAR_ASSIGNMENT_INFO });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'selectedInformationAllorKey', value: headerOneBadgeThree }
    });
    dispatch({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'ASSIGNMENTCREATE' }
    });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  };

  const onClickCreateAssesseeGroup = () => {
    console.log('ON CLICK CREATE ASSESSEE GROUP');
    getAssesseeGroupCreateAPI(selectedAssociateInfo, dispatch, 'assessees', assignmentCreateType);
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
    clearMiddlePaneInfo();
  };
  const onClickCreateAssessmentGroup = () => {
    console.log('ON CLICK CREATE ASSESSMENT GROUP');
    // dispatch({ type: CLEAR_GROUP_REDUCER_STATE });
    // dispatch({
    //   type: SET_POPUP_VALUE,
    //   payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'assessmentsGROUPCREATE' }
    // });
    getAssessmentGroupCreateAPI(
      selectedAssociateInfo,
      dispatch,
      'assessments',
      assignmentCreateType
    );
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
    clearMiddlePaneInfo();
  };
  const onClickCreateAssignmentGroup = () => {
    console.log('ON CLICK CREATE ASSIGNMENT GROUP');
    getAssignmentGroupCreateAPI(
      selectedAssociateInfo,
      dispatch,
      'assignments',
      assignmentCreateType
    );
    // dispatch({ type: CLEAR_GROUP_REDUCER_STATE });
    // dispatch({
    //   type: SET_POPUP_VALUE,
    //   payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'assignmentsGROUPCREATE' }
    // });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
    clearMiddlePaneInfo();
  };
  const onClickCreateAssociateGroup = () => {
    console.log('ON CLICK CREATE ASSOCIATE GROUP');
    getAssociateGroupCreateAPI(selectedAssociateInfo, dispatch, 'associates', assignmentCreateType);
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
    clearMiddlePaneInfo();
  };
  const onClickCreateGauge = () => {
    console.log('ON CLICK CREATE Gauge ');
    dispatch({ type: CLEAR_GUAGE_REDUCER_STATE });
    dispatch({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'GUAGECREATE' }
    });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
    clearMiddlePaneInfo();
  };

  const onClickCreateCultureProfileGroup = () => {
    console.log('ON CLICK CREATE culture GROUP');
    getCultureProfileGroupCreateAPI(
      selectedAssociateInfo,
      dispatch,
      'culture-profiles',
      assignmentCreateType
    );
    // dispatch({ type: CLEAR_GROUP_REDUCER_STATE });
    // dispatch({
    //   type: SET_POPUP_VALUE,
    //   payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'culture-profilesGROUPCREATE' }
    // });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
    clearMiddlePaneInfo();
  };
  const onClickCreateJobProfileGroup = () => {
    console.log('ON CLICK CREATE Job GROUP');
    getJobProfileGroupCreateAPI(
      selectedAssociateInfo,
      dispatch,
      'job-profiles',
      assignmentCreateType
    );
    // dispatch({ type: CLEAR_GROUP_REDUCER_STATE });
    // dispatch({
    //   type: SET_POPUP_VALUE,
    //   payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'job-profilesTYPECREATE' }
    // });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
    clearMiddlePaneInfo();
  };

  const onClickCreateItemGroup = () => {
    console.log('ON CLICK CREATE ITEM GROUP');
    getItemGroupCreateAPI(selectedAssociateInfo, dispatch, 'items', assignmentCreateType);
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
    clearMiddlePaneInfo();
  };
  const onClickCreateCultureProfileType = () => {
    console.log('ON CLICK CREATE Culture Type');
    // getItemGroupCreateAPI(selectedAssociateInfo, dispatch, 'items');
    dispatch({ type: CLEAR_TYPE_REDUCER_STATE });
    dispatch({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'culture-profilesTYPECREATE' }
    });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
    clearMiddlePaneInfo();
  };
  const onClickCreateJobProfileType = () => {
    console.log('ON CLICK CREATE Job Type');
    dispatch({ type: CLEAR_TYPE_REDUCER_STATE });
    dispatch({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'job-profilesTYPECREATE' }
    });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
    clearMiddlePaneInfo();
  };

  const onClickCreateAssignmentType = () => {
    console.log('ON CLICK CREATE ASSIGNMENT TYPE');
    dispatch({ type: CLEAR_TYPE_REDUCER_STATE });
    dispatch({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'assignmentsTYPECREATE' }
    });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
    clearMiddlePaneInfo();
  };
  const onClickCreateAssessmentType = () => {
    console.log('ON CLICK CREATE ASSESSMENT TYPE');
    dispatch({ type: CLEAR_TYPE_REDUCER_STATE });
    dispatch({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'assessmentsTYPECREATE' }
    });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
    clearMiddlePaneInfo();
  };
  const onClickCreateAssesseeType = () => {
    console.log('ON CLICK CREATE ASSESSEE TYPE');
    dispatch({ type: CLEAR_TYPE_REDUCER_STATE });
    dispatch({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: 'NAMEPOPUP',
        popupMode: 'assesseesTYPECREATE',
        popupHeaderOne: headerOne
      }
    });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
    clearMiddlePaneInfo();
  };
  const onClickCreateAssociateType = () => {
    console.log('ON CLICK CREATE ASSOCIATE TYPE');
    dispatch({ type: CLEAR_TYPE_REDUCER_STATE });
    dispatch({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'associatesTYPECREATE' }
    });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
    clearMiddlePaneInfo();
  };
  const onClickCreateItemType = () => {
    console.log('ON CLICK CREATE ASSOCIATE TYPE');
    dispatch({ type: CLEAR_TYPE_REDUCER_STATE });
    dispatch({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'itemsTYPECREATE' }
    });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
    clearMiddlePaneInfo();
  };

  const onClickCreateAssesseeRole = () => {
    console.log('ON CLICK CREATE ASSESSEE ROLE');
    dispatch({ type: CLEAR_ROLE_REDUCER_STATE });
    dispatch({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: 'NAMEPOPUP',
        popupMode: 'assesseesROLECREATE',
        popupHeaderOne: headerOne
      }
    });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
    clearMiddlePaneInfo();
  };
  const onClickCreateItem = () => {
    console.log('ON CLICK CREATE ITEM');
    dispatch({ type: CLEAR_ITEM_REDUCER_STATE });
    dispatch({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'ITEMCREATE' }
    });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
    clearMiddlePaneInfo();
  };
  const onClickCreateAssociateRole = () => {
    console.log('ON CLICK CREATE ASSOCIATE ROLE');
    dispatch({ type: CLEAR_ROLE_REDUCER_STATE });
    dispatch({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'associatesROLECREATE' }
    });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
    clearMiddlePaneInfo();
  };
  const onClickCreateAssociateNode = () => {
    console.log('ON CLICK CREATE ASSOCIATE Node');
    createAssociateNodeFunction(dispatch, selectedAssociateInfo, countPage);
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
    dispatch({ type: CLEAR_NODE_REDUCER_STATE });
    clearMiddlePaneInfo();
  };

  const revisePrimaryIcon = [{ label: 'revise', onClick: onClickRevise, Icon: ReviseIcon }];
  const createAssesseePrimaryIcon = [
    { label: 'create', onClick: onClickCreateAssessee, Icon: AddIcon }
  ];
  const createAssociatePrimaryIcon = [
    { label: 'create', onClick: onClickCreateAssociate, Icon: AddIcon }
  ];
  const createItemPrimaryIcon = [{ label: 'create', onClick: onClickCreateItem, Icon: AddIcon }];
  const createAssessmentPrimaryIcon = [
    { label: 'create', onClick: onClickCreateAssessment, Icon: AddIcon }
  ];
  const createCultureProfilePrimaryIcon = [
    { label: 'create', onClick: onClickCreateCultureProfile, Icon: AddIcon }
  ];
  const createJobProfilePrimaryIcon = [
    { label: 'create', onClick: onClickCreateJobProfile, Icon: AddIcon }
  ];
  const createAssignmentPrimaryIcon = [
    { label: 'create', onClick: onClickCreateAssignment, Icon: AddIcon }
  ];

  const createAssesseeGroupPrimaryIcon = [
    { label: 'create', onClick: onClickCreateAssesseeGroup, Icon: AddIcon }
  ];
  const createAssociateGroupPrimaryIcon = [
    { label: 'create', onClick: onClickCreateAssociateGroup, Icon: AddIcon }
  ];
  const createGaugeIcon = [{ label: 'create', onClick: onClickCreateGauge, Icon: AddIcon }];
  const createAssessmentGroupPrimaryIcon = [
    { label: 'create', onClick: onClickCreateAssessmentGroup, Icon: AddIcon }
  ];
  const createCultureProfileGroupPrimaryIcon = [
    { label: 'create', onClick: onClickCreateCultureProfileGroup, Icon: AddIcon }
  ];
  const createJobProfileGroupPrimaryIcon = [
    { label: 'create', onClick: onClickCreateJobProfileGroup, Icon: AddIcon }
  ];
  const createCultureProfileTypePrimaryIcon = [
    { label: 'create', onClick: onClickCreateCultureProfileType, Icon: AddIcon }
  ];
  const createJobProfileTypePrimaryIcon = [
    { label: 'create', onClick: onClickCreateJobProfileType, Icon: AddIcon }
  ];
  const createItemGroupPrimaryIcon = [
    { label: 'create', onClick: onClickCreateItemGroup, Icon: AddIcon }
  ];
  const createAssignmentGroupPrimaryIcon = [
    { label: 'create', onClick: onClickCreateAssignmentGroup, Icon: AddIcon }
  ];
  const createAssignmentTypePrimaryIcon = [
    { label: 'create', onClick: onClickCreateAssignmentType, Icon: AddIcon }
  ];
  const createAssessmentTypePrimaryIcon = [
    { label: 'create', onClick: onClickCreateAssessmentType, Icon: AddIcon }
  ];
  const createAssesseeTypePrimaryIcon = [
    { label: 'create', onClick: onClickCreateAssesseeType, Icon: AddIcon }
  ];
  const createAssociateTypePrimaryIcon = [
    { label: 'create', onClick: onClickCreateAssociateType, Icon: AddIcon }
  ];
  const createItemTypePrimaryIcon = [
    { label: 'create', onClick: onClickCreateItemType, Icon: AddIcon }
  ];

  const createAssesseeRolePrimaryIcon = [
    { label: 'create', onClick: onClickCreateAssesseeRole, Icon: AddIcon }
  ];
  const createAssociateRolePrimaryIcon = [
    { label: 'create', onClick: onClickCreateAssociateRole, Icon: AddIcon }
  ];
  const createAssociateNodePrimaryIcon = [
    { label: 'create', onClick: onClickCreateAssociateNode, Icon: AddIcon }
  ];

  const reviseSecondaryIcons = [
    { label: 'cancel', onClick: onClickReviseCancel, Icon: ClearIcon },
    { label: 'finish', onClick: onClickReviseFinish, Icon: Check }
  ];

  const onClickClearInfo = () => {
    if (typeOfMiddlePaneList === 'assignmentMeritListReportList' && headerOne === 'assessee') {
      dispatch({
        type: SET_DISPLAY_PANE_THREE_STATE,
        payload: {
          headerOne: 'assignment',
          headerOneBadgeOne: 'analytics',
          headerOneBadgeTwo: '',
          headerOneBadgeThree: '',
          responseObject: assesseeAssignmentReportData,
          reviewMode: 'review'
        }
      });
    } else {
      dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
      dispatch({
        type: SET_MOBILE_PANE_STATE,
        payload:
          typeOfMiddlePaneList === '' ||
          typeOfMiddlePaneList === 'assesseesSelfReview' ||
          typeOfMiddlePaneList === 'associateSelfReview'
            ? 'displayPaneOne'
            : 'displayPaneTwo'
      });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'middlePaneSelectedValue', value: '' }
      });
    }
  };
  // console.log('DISPLAY PANE THREE++++++>', responseObject, headerOneBadgeThree);
  const reviseAssesseeBasicInformation = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const profileId = e.currentTarget.getAttribute('id');
    console.log('====>', profileId);
    console.log('====>', labelName, informationBasic);
    if (profileId === 'profile-icon') {
      dispatch({
        type: ASSESSEE_SIGN_ON,
        payload: {
          isPopUpValue: 'PICTUREPOPUP',
          popupMode: 'ASSESSEE_CREATE',
          popupHeaderOne: headerOne
        }
      });
    }
    if (labelName === 'name') {
      dispatch({
        type: ASSESSEE_SIGN_ON,
        payload: {
          isPopUpValue: 'ASSESSEENAMEPOPUP',
          popupMode: 'ASSESSEE_CREATE',
          popupHeaderOne: headerOne
        }
      });
    }
    if (labelName === 'alias') {
      dispatch({
        type: ASSESSEE_SIGN_ON,
        payload: {
          isPopUpValue: 'ALIASPOPUP',
          popupMode: 'ASSESSEE_CREATE',
          popupHeaderOne: headerOne
        }
      });
    }
  };
  const reviseAssesseeRoleBasicInformation = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const profileId = e.currentTarget.getAttribute('id');
    console.log('====>', profileId);
    console.log('====>', labelName, informationBasic);
    if (profileId === 'profile-icon') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'PICTUREPOPUP',
          popupMode: 'assesseesROLECREATE',
          popupHeaderOne: headerOne
        }
      });
    }
    if (labelName === 'name') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'NAMEPOPUP',
          popupMode: 'assesseesROLECREATE',
          popupHeaderOne: headerOne
        }
      });
    }
    if (labelName === 'description') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'ALIASPOPUP',
          popupMode: 'assesseesROLECREATE',
          popupHeaderOne: headerOne
        }
      });
    }
  };
  const reviseAssesseeGroupBasicInformation = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const profileId = e.currentTarget.getAttribute('id');
    console.log('====>', profileId);
    console.log('====>', labelName, informationBasic);
    if (profileId === 'profile-icon') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'PICTUREPOPUP',
          popupMode: 'assesseesGROUPCREATE',
          popupHeaderOne: headerOne
        }
      });
    }
    if (labelName === 'name') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'NAMEPOPUP',
          popupMode: 'assesseesGROUPCREATE',
          popupHeaderOne: headerOne
        }
      });
    }
    if (labelName === 'description') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'ALIASPOPUP',
          popupMode: 'assesseesGROUPCREATE',
          popupHeaderOne: headerOne
        }
      });
    }
  };
  const reviseAssociateGroupBasicInformation = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const profileId = e.currentTarget.getAttribute('id');
    console.log('====>', profileId);
    console.log('====>', labelName, informationBasic);
    if (profileId === 'profile-icon') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'PICTUREPOPUP', popupMode: 'associatesGROUPCREATE' }
      });
    }
    if (labelName === 'name') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'associatesGROUPCREATE' }
      });
    }
    if (labelName === 'description') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ALIASPOPUP', popupMode: 'associatesGROUPCREATE' }
      });
    }
  };
  const reviseProfilerBasicInformation = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const profileId = e.currentTarget.getAttribute('id');
    console.log('====>', profileId);
    console.log('====>', labelName, informationBasic);
    if (profileId === 'profile-icon') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'PICTUREPOPUP', popupMode: 'CULTUREJOBPROFILERCREATE' }
      });
    }
    if (labelName === 'name') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'CULTUREJOBPROFILERCREATE' } //
      });
    }
    if (labelName === 'description') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ALIASPOPUP', popupMode: 'CULTUREJOBPROFILERCREATE' }
      });
    }
  };
  const reviseCultureJobProfilerBasicInformation = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const profileId = e.currentTarget.getAttribute('id');
    console.log('====>', profileId);
    console.log('====>', labelName, informationBasic);
    if (profileId === 'profile-icon') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'PICTUREPOPUP', popupMode: 'CULTUREJOBPROFILERCREATE' }
      });
    }
    if (labelName === 'name') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'PROFILERNAMEPOPUP', popupMode: 'CULTUREJOBPROFILERCREATE' } //
      });
    }
    if (labelName === 'description') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'PROFILERALIASPOPUP', popupMode: 'CULTUREJOBPROFILERCREATE' }
      });
    }
  };
  const competencyreviseProfilerBasicInformation = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const profileId = e.currentTarget.getAttribute('id');
    console.log('====>', profileId);
    console.log('====>', labelName, informationBasic);
    if (profileId === 'profile-icon') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'PICTUREPOPUP', popupMode: 'CULTUREJOBPROFILERCREATE' }
      });
    }
    if (labelName === 'name') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'COMPETENCYNAMEPOPUP', popupMode: 'CULTUREJOBPROFILERCREATE' }
      });
    }
    if (labelName === 'description') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'COMPETENCYALIASPOPUP', popupMode: 'CULTUREJOBPROFILERCREATE' }
      });
    }
  };
  const reviseClusterProfilerBasicInformation = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const profileId = e.currentTarget.getAttribute('id');
    console.log('====>', profileId);
    console.log('====>', labelName, informationBasic);
    if (profileId === 'profile-icon') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'PICTUREPOPUP', popupMode: 'CULTUREJOBPROFILERCREATE' }
      });
    }
    if (labelName === 'name') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'CLUSTERNAMEPOPUP', popupMode: 'CULTUREJOBPROFILERCREATE' }
      });
    }
    if (labelName === 'description') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'CLUSTERALIASPOPUP', popupMode: 'CULTUREJOBPROFILERCREATE' }
      });
    }
  };
  const reviseAssessmentGroupBasicInformation = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const profileId = e.currentTarget.getAttribute('id');
    console.log('====>', profileId);
    console.log('====>', labelName, informationBasic);
    if (profileId === 'profile-icon') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'PICTUREPOPUP', popupMode: 'assessmentsGROUPCREATE' }
      });
    }
    if (labelName === 'name') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'assessmentsGROUPCREATE' }
      });
    }
    if (labelName === 'description') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ALIASPOPUP', popupMode: 'assessmentsGROUPCREATE' }
      });
    }
  };
  const reviseCultureProfileGroupBasicInformation = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const profileId = e.currentTarget.getAttribute('id');
    console.log('====>', profileId);
    console.log('====>', labelName, informationBasic);
    if (profileId === 'profile-icon') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'PICTUREPOPUP', popupMode: 'culture-profilesGROUPCREATE' }
      });
    }
    if (labelName === 'name') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'culture-profilesGROUPCREATE' }
      });
    }
    if (labelName === 'description') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ALIASPOPUP', popupMode: 'culture-profilesGROUPCREATE' }
      });
    }
  };
  const reviseJobProfileGroupBasicInformation = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const profileId = e.currentTarget.getAttribute('id');
    console.log('====>', profileId);
    console.log('====>', labelName, informationBasic);
    if (profileId === 'profile-icon') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'PICTUREPOPUP', popupMode: 'job-profilesGROUPCREATE' }
      });
    }
    if (labelName === 'name') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'job-profilesGROUPCREATE' }
      });
    }
    if (labelName === 'description') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ALIASPOPUP', popupMode: 'job-profilesGROUPCREATE' }
      });
    }
  };
  const reviseCultureProfileTypeBasicInformation = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const profileId = e.currentTarget.getAttribute('id');
    console.log('====>', profileId);
    console.log('====>', labelName, informationBasic);
    if (profileId === 'profile-icon') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'PICTUREPOPUP', popupMode: 'culture-profilesTYPECREATE' }
      });
    }
    if (labelName === 'name') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'culture-profilesTYPECREATE' }
      });
    }
    if (labelName === 'description') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ALIASPOPUP', popupMode: 'culture-profilesTYPECREATE' }
      });
    }
  };
  const reviseJobProfileTypeBasicInformation = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const profileId = e.currentTarget.getAttribute('id');
    console.log('====>', profileId);
    console.log('====>', labelName, informationBasic);
    if (profileId === 'profile-icon') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'PICTUREPOPUP', popupMode: 'job-profilesTYPECREATE' }
      });
    }
    if (labelName === 'name') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'job-profilesTYPECREATE' }
      });
    }
    if (labelName === 'description') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ALIASPOPUP', popupMode: 'job-profilesTYPECREATE' }
      });
    }
  };
  const reviseAssignmentGroupBasicInformation = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const profileId = e.currentTarget.getAttribute('id');
    console.log('====>', profileId);
    console.log('====>', labelName, informationBasic);
    if (profileId === 'profile-icon') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'PICTUREPOPUP', popupMode: 'assignmentsGROUPCREATE' }
      });
    }
    if (labelName === 'name') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'assignmentsGROUPCREATE' }
      });
    }
    if (labelName === 'description') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ALIASPOPUP', popupMode: 'assignmentsGROUPCREATE' }
      });
    }
  };
  const reviseItemsGroupBasicInformation = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const profileId = e.currentTarget.getAttribute('id');
    console.log('====>', profileId);
    console.log('====>', labelName, informationBasic);
    if (profileId === 'profile-icon') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'PICTUREPOPUP', popupMode: 'itemsGROUPCREATE' }
      });
    }
    if (labelName === 'name') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'itemsGROUPCREATE' }
      });
    }
    if (labelName === 'description') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ALIASPOPUP', popupMode: 'itemsGROUPCREATE' }
      });
    }
  };
  const reviseAssociateNodeBasicInformation = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const profileId = e.currentTarget.getAttribute('id');
    console.log('====>', profileId);
    console.log('====>', labelName, informationBasic);
    if (profileId === 'profile-icon') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'PICTUREPOPUP', popupMode: 'NODECREATE' }
      });
    }
    if (labelName === 'name') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'NODECREATE' }
      });
    }
    if (labelName === 'description') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ALIASPOPUP', popupMode: 'NODECREATE' }
      });
    }
  };
  const reviseAssociateRoleBasicInformation = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const profileId = e.currentTarget.getAttribute('id');
    console.log('====>', profileId);
    console.log('====>', labelName, informationBasic);
    if (profileId === 'profile-icon') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'PICTUREPOPUP', popupMode: 'associatesROLECREATE' }
      });
    }
    if (labelName === 'name') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'associatesROLECREATE' }
      });
    }
    if (labelName === 'description') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ALIASPOPUP', popupMode: 'associatesROLECREATE' }
      });
    }
  };

  const reviseAssociateBasicInformation = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    console.log('====>', labelName);
    const profileId = e.currentTarget.getAttribute('id');
    if (profileId === 'profile-icon') {
      dispatch({
        type: ASSOCIATE_SIGN_ON,
        payload: { isPopUpValue: 'ASSOCIATEPICTUREPOPUP', popupMode: 'ASSOCIATE_CREATE' }
      });
    }
    if (labelName === 'name') {
      dispatch({
        type: ASSOCIATE_SIGN_ON,
        payload: { isPopUpValue: 'NAMEALIASPOPUP', popupMode: 'ASSOCIATE_CREATE' }
      });
    }
    if (labelName === 'description') {
      dispatch({
        type: ASSOCIATE_SIGN_ON,
        payload: { isPopUpValue: 'DESCRIPTIONPOPUP', popupMode: 'ASSOCIATE_CREATE' }
      });
    }
  };
  const reviseAssessmentBasicInformation = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    console.log('====>', labelName);
    const profileId = e.currentTarget.getAttribute('id');
    if (profileId === 'profile-icon') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'PICTUREPOPUP', popupMode: 'ASSESSMENTCREATE' }
      });
    }
    if (labelName === 'name') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'ASSESSMENTCREATE' }
      });
    }
    if (labelName === 'description') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ALIASPOPUP', popupMode: 'ASSESSMENTCREATE' }
      });
    }
  };
  const reviseCultureProfileBasicInformation = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    console.log('====>', labelName);
    const profileId = e.currentTarget.getAttribute('id');
    if (profileId === 'profile-icon') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'PICTUREPOPUP', popupMode: 'CULTURECREATE' }
      });
    }
    if (labelName === 'name') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'CULTURECREATE' }
      });
    }
    if (labelName === 'description') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ALIASPOPUP', popupMode: 'CULTURECREATE' }
      });
    }
  };
  const reviseJobProfileBasicInformation = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    console.log('====>', labelName);
    const profileId = e.currentTarget.getAttribute('id');
    if (profileId === 'profile-icon') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'PICTUREPOPUP', popupMode: 'JOBCREATE' }
      });
    }
    if (labelName === 'name') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'JOBCREATE' }
      });
    }
    if (labelName === 'description') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ALIASPOPUP', popupMode: 'JOBCREATE' }
      });
    }
  };

  const reviseAssignmentBasicInformation = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    console.log('====>', labelName);
    const profileId = e.currentTarget.getAttribute('id');
    if (profileId === 'profile-icon') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'PICTUREPOPUP', popupMode: 'ASSIGNMENTCREATE' }
      });
    }
    if (labelName === 'name') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'ASSIGNMENTCREATE' }
      });
    }
    if (labelName === 'description') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ALIASPOPUP', popupMode: 'ASSIGNMENTCREATE' }
      });
    }
  };

  const reviseItemBasicInformation = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    console.log('====>', labelName);
    const profileId = e.currentTarget.getAttribute('id');
    if (profileId === 'profile-icon') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'PICTUREPOPUP', popupMode: 'ITEMCREATE' }
      });
    }
    if (labelName === 'name') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'ITEMCREATE' }
      });
    }
    if (labelName === 'description') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ALIASPOPUP', popupMode: 'ITEMCREATE' }
      });
    }
  };

  const reviseAssociateTypeBasicInformation = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    console.log('====>', labelName);
    const profileId = e.currentTarget.getAttribute('id');
    if (profileId === 'profile-icon') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'PICTUREPOPUP', popupMode: 'associatesTYPECREATE' }
      });
    }
    if (labelName === 'name') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'associatesTYPECREATE' }
      });
    }
    if (labelName === 'description') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ALIASPOPUP', popupMode: 'associatesTYPECREATE' }
      });
    }
  };
  const reviseItemTypeBasicInformation = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    console.log('====>', labelName);
    const profileId = e.currentTarget.getAttribute('id');
    if (profileId === 'profile-icon') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'PICTUREPOPUP', popupMode: 'itemsTYPECREATE' }
      });
    }
    if (labelName === 'name') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'itemsTYPECREATE' }
      });
    }
    if (labelName === 'description') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ALIASPOPUP', popupMode: 'itemsTYPECREATE' }
      });
    }
  };
  const reviseAssesseeTypeBasicInformation = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    console.log('====>', labelName);
    const profileId = e.currentTarget.getAttribute('id');
    if (profileId === 'profile-icon') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'PICTUREPOPUP',
          popupMode: 'assesseesTYPECREATE',
          popupHeaderOne: headerOne
        }
      });
    }
    if (labelName === 'name') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'NAMEPOPUP',
          popupMode: 'assesseesTYPECREATE',
          popupHeaderOne: headerOne
        }
      });
    }
    if (labelName === 'description') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'ALIASPOPUP',
          popupMode: 'assesseesTYPECREATE',
          popupHeaderOne: headerOne
        }
      });
    }
  };
  const reviseAssessmentTypeBasicInformation = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    console.log('====>', labelName);
    const profileId = e.currentTarget.getAttribute('id');
    if (profileId === 'profile-icon') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'PICTUREPOPUP', popupMode: 'assessmentsTYPECREATE' }
      });
    }
    if (labelName === 'name') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'assessmentsTYPECREATE' }
      });
    }
    if (labelName === 'description') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ALIASPOPUP', popupMode: 'assessmentsTYPECREATE' }
      });
    }
  };
  const reviseAssignmentTypeBasicInformation = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    console.log('====>', labelName);
    const profileId = e.currentTarget.getAttribute('id');
    if (profileId === 'profile-icon') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'PICTUREPOPUP', popupMode: 'assignmentsTYPECREATE' }
      });
    }
    if (labelName === 'name') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'assignmentsTYPECREATE' }
      });
    }
    if (labelName === 'description') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ALIASPOPUP', popupMode: 'assignmentsTYPECREATE' }
      });
    }
  };
  const reviseAssessmentScaleBasicInformation = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const profileId = e.currentTarget.getAttribute('id');
    if (profileId === 'profile-icon') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'PICTUREPOPUP', popupMode: 'SCALECREATE' }
      });
    }
    if (labelName === 'name') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'SCALECREATE' }
      });
    }
    if (labelName === 'description') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ALIASPOPUP', popupMode: 'SCALECREATE' }
      });
    }
  };
  const reviseAssessmentClusterBasicInformation = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const profileId = e.currentTarget.getAttribute('id');
    if (profileId === 'profile-icon') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'PICTUREPOPUP', popupMode: 'CLUSTERCREATE' }
      });
    }
    if (labelName === 'name') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'CLUSTERCREATE' }
      });
    }
    if (labelName === 'description') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ALIASPOPUP', popupMode: 'CLUSTERCREATE' }
      });
    }
  };
  const reviseAssessmentNormBasicInformation = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const profileId = e.currentTarget.getAttribute('id');
    if (profileId === 'profile-icon') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'PICTUREPOPUP', popupMode: 'NORMCREATE' }
      });
    }
    if (labelName === 'name') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'NORMCREATE' }
      });
    }
    if (labelName === 'description') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ALIASPOPUP', popupMode: 'NORMCREATE' }
      });
    }
  };
  const reviseAssessmentScoreBasicInformation = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const profileId = e.currentTarget.getAttribute('id');
    if (profileId === 'profile-icon') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'PICTUREPOPUP', popupMode: 'SCORECREATE' }
      });
    }
    if (labelName === 'name') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'SCORECREATE' }
      });
    }
    if (labelName === 'description') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ALIASPOPUP', popupMode: 'SCORECREATE' }
      });
    }
  };
  const reviseAssessmentGradeBasicInformation = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const profileId = e.currentTarget.getAttribute('id');
    if (profileId === 'profile-icon') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'PICTUREPOPUP', popupMode: 'GRADECREATE' }
      });
    }
    if (labelName === 'name') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'GRADECREATE' }
      });
    }
    if (labelName === 'description') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ALIASPOPUP', popupMode: 'GRADECREATE' }
      });
    }
  };
  const reviseAssessmentSectionBasicInformation = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const profileId = e.currentTarget.getAttribute('id');
    if (profileId === 'profile-icon') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'PICTUREPOPUP', popupMode: 'SECTIONCREATE' }
      });
    }
    if (labelName === 'name') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'SECTIONCREATE' }
      });
    }
    if (labelName === 'description') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ALIASPOPUP', popupMode: 'SECTIONCREATE' }
      });
    }
  };
  const reviseAssessmentVersionBasicInformation = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const profileId = e.currentTarget.getAttribute('id');
    if (profileId === 'profile-icon') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'PICTUREPOPUP', popupMode: 'VERSIONCREATE' }
      });
    }
    if (labelName === 'name') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'VERSIONCREATE' }
      });
    }
    if (labelName === 'description') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ALIASPOPUP', popupMode: 'VERSIONCREATE' }
      });
    }
  };
  const reviseAssignmentGuageBasicInformation = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const profileId = e.currentTarget.getAttribute('id');
    if (profileId === 'profile-icon') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'PICTUREPOPUP', popupMode: 'GUAGECREATE' }
      });
    }
    if (labelName === 'name') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'GUAGECREATE' }
      });
    }
    if (labelName === 'description') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ALIASPOPUP', popupMode: 'GUAGECREATE' }
      });
    }
  };
  const reviseAssignmentmetricsBasicInformation = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const profileId = e.currentTarget.getAttribute('id');
    if (profileId === 'profile-icon') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'PICTUREPOPUP', popupMode: 'GUAGECREATE' }
      });
    }
    if (labelName === 'name') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'METRICSNAMEPOPUP', popupMode: 'GUAGECREATE' }
      });
    }
    if (labelName === 'description') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'METRICSALIASPOPUP', popupMode: 'GUAGECREATE' }
      });
    }
  };
  return (
    <>
      <div>
        <HeaderCard
          className=""
          displayPane="right"
          headerOne={headerOne}
          headerOneBadgeOne={headerOneBadgeOne}
          headerOneBadgeTwo={headerOneBadgeTwo}
          headerOneBadgeThree={headerOneBadgeThree}
          headerPanelColour="green"
          onClickClearInfo={onClickClearInfo}
        />
      </div>
      {isReviewRevise &&
        responseObject &&
        ((headerOne === 'assessee' &&
          headerOneBadgeOne !== 'role' &&
          headerOneBadgeOne !== 'analytics' &&
          headerOneBadgeOne !== 'report') ||
          headerOne === 'administrator' ||
          headerOne === 'assistant' ||
          headerOne === 'manager') && (
          <>
            <div style={{ padding: '2.5px' }}>
              <div style={{ padding: '2.5px' }}>
                <BasicCard
                  isAlertActive
                  isFlagActive={informationBasic?.assesseeFlag || false}
                  className=""
                  labelTextOneOne="name"
                  labelTextOneTwo="alias"
                  textOneOne={`${
                    informationBasic?.assesseeNamePrefix
                  } ${informationBasic?.assesseeNameFirst?.trim()} ${informationBasic?.assesseeNameOther?.trim()} ${informationBasic?.assesseeNameLast?.trim()} ${informationBasic.assesseeNameSuffix.trim()}`
                    .replace(/\s\s+/g, ' ')
                    .trim()}
                  textOneTwo={informationBasic.assesseeAlias || 'No Information'}
                  isVerifiedActiveName={informationBasic?.assesseeNameVerification || false}
                  isVerifiedActivePicture={informationBasic?.assesseePictureVerification || false}
                  mode={reviewMode}
                  isImageActive={informationBasic.assesseePicture ? true : false}
                  imageOne={informationBasic.assesseePicture}
                  onClickRevise={reviseAssesseeBasicInformation}
                />
              </div>
              <Sections
                listSections={rightPaneSectionsAssessee}
                selectedSection={selectedSection}
                setSelectedSection={setSelectedSection}
              />
            </div>
            {reviewMode === 'revise' && (
              <FooterIconTwo
                FilterModeEnable={isShowReviseIcon}
                FilterMode={FilterMode}
                onClick={onClickRevise}
                primaryIcon={revisePrimaryIcon}
                secondaryIcon={reviseSecondaryIcons}
              />
            )}

            {createMode === 'assessee' && reviewMode !== 'revise' && (
              <FooterIconTwo
                FilterModeEnable={true}
                FilterMode={FilterMode}
                onClick={onClickCreateAssessee}
                primaryIcon={createAssesseePrimaryIcon}
                secondaryIcon={[]}
              />
            )}
          </>
        )}

      {isReviewRevise &&
        responseObject &&
        ((headerOne === 'assessees' && headerOneBadgeOne === 'role') ||
          (headerOne === 'administrators' && headerOneBadgeOne === 'role') ||
          (headerOne === 'assistants' && headerOneBadgeOne === 'role') ||
          (headerOne === 'managers' && headerOneBadgeOne === 'role')) && (
          <>
            <div style={{ padding: '2.5px' }}>
              <div style={{ padding: '2.5px' }}>
                <BasicCard
                  isAlertActive
                  isFlagActive={informationBasic?.assesseeRoleFlag || false}
                  className=""
                  labelTextOneOne="name"
                  labelTextOneTwo="description"
                  textOneOne={informationBasic?.assesseeRoleName || 'No Information'}
                  textOneTwo={informationBasic?.assesseeRoleDescription || 'No Information'}
                  isVerifiedActiveName={informationBasic?.assesseeRoleNameVerification || false}
                  isVerifiedActivePicture={
                    informationBasic?.assesseeRolePictureVerification || false
                  }
                  // isVerifiedActiveName={false}
                  // isVerifiedActivePicture={false}
                  mode={reviewMode}
                  isImageActive={informationBasic?.assesseeRolePicture ? true : false}
                  imageOne={informationBasic?.assesseeRolePicture}
                  onClickRevise={reviseAssesseeRoleBasicInformation}
                />
              </div>
              <Sections
                listSections={rightPaneSectionsAssesseeRole}
                selectedSection={selectedSectionAssesseeRole}
                setSelectedSection={setSelectedSectionAssesseeRole}
              />
            </div>
            {reviewMode === 'revise' && (
              <FooterIconTwo
                FilterModeEnable={isShowReviseIcon}
                FilterMode={FilterMode}
                onClick={onClickRevise}
                primaryIcon={revisePrimaryIcon}
                secondaryIcon={reviseSecondaryIcons}
              />
            )}
            {createMode === 'assesseesRole' && reviewMode !== 'revise' && (
              <FooterIconTwo
                FilterModeEnable={true}
                FilterMode={FilterMode}
                onClick={onClickCreateAssesseeRole}
                primaryIcon={createAssesseeRolePrimaryIcon}
                secondaryIcon={[]}
              />
            )}
          </>
        )}
      {isReviewRevise &&
        responseObject &&
        headerOne === 'assessees' &&
        headerOneBadgeOne === 'group' && (
          <>
            <div style={{ padding: '2.5px' }}>
              <div style={{ padding: '2.5px' }}>
                <BasicCard
                  isAlertActive
                  isFlagActive={informationBasic?.assesseeGroupFlag || false}
                  className=""
                  labelTextOneOne="name"
                  labelTextOneTwo="description"
                  textOneOne={informationBasic?.assesseeGroupName || 'No Information'}
                  textOneTwo={informationBasic?.assesseeGroupDescription || 'No Information'}
                  isVerifiedActiveName={informationBasic?.assesseeGroupNameVerification || false}
                  isVerifiedActivePicture={
                    informationBasic?.assesseeGroupPictureVerification || false
                  }
                  mode={reviewMode}
                  isImageActive={informationBasic.assesseeGroupPicture ? true : false}
                  imageOne={informationBasic.assesseeGroupPicture}
                  onClickRevise={reviseAssesseeGroupBasicInformation}
                />
              </div>
              <Sections
                listSections={rightPaneSectionsAssesseeGroup}
                selectedSection={selectedSectionAssesseeGroup}
                setSelectedSection={setSelectedSectionAssesseeGroup}
              />
            </div>
            {reviewMode === 'revise' && (
              <FooterIconTwo
                FilterModeEnable={isShowReviseIcon}
                FilterMode={FilterMode}
                onClick={onClickRevise}
                primaryIcon={revisePrimaryIcon}
                secondaryIcon={reviseSecondaryIcons}
              />
            )}
            {createMode === 'assesseesGroup' && reviewMode !== 'revise' && (
              <FooterIconTwo
                FilterModeEnable={true}
                FilterMode={FilterMode}
                onClick={onClickCreateAssesseeGroup}
                primaryIcon={createAssesseeGroupPrimaryIcon}
                secondaryIcon={[]}
              />
            )}
          </>
        )}
      {isReviewRevise &&
        responseObject &&
        (headerOne === 'administrators' ||
          headerOne === 'assistants' ||
          headerOne === 'managers') && //
        headerOneBadgeOne === 'group' && (
          <>
            <div style={{ padding: '2.5px' }}>
              <div style={{ padding: '2.5px' }}>
                <BasicCard
                  isAlertActive
                  isFlagActive={informationBasic?.assesseeGroupFlag || false}
                  className=""
                  labelTextOneOne="name"
                  labelTextOneTwo="description"
                  textOneOne={informationBasic?.assesseeGroupName || 'No Information'}
                  textOneTwo={informationBasic?.assesseeGroupDescription || 'No Information'}
                  isVerifiedActiveName={informationBasic?.assesseeGroupNameVerification || false}
                  isVerifiedActivePicture={
                    informationBasic?.assesseeGroupPictureVerification || false
                  }
                  mode={reviewMode}
                  isImageActive={informationBasic.assesseeGroupPicture ? true : false}
                  imageOne={informationBasic.assesseeGroupPicture}
                  onClickRevise={reviseAssesseeGroupBasicInformation}
                />
              </div>
              <Sections
                listSections={rightPaneSectionsAssesseeGroup}
                selectedSection={selectedSectionAssesseeGroup}
                setSelectedSection={setSelectedSectionAssesseeGroup}
              />
            </div>
            {reviewMode === 'revise' && (
              <FooterIconTwo
                FilterModeEnable={isShowReviseIcon}
                FilterMode={FilterMode}
                onClick={onClickRevise}
                primaryIcon={revisePrimaryIcon}
                secondaryIcon={reviseSecondaryIcons}
              />
            )}
            {createMode === 'assesseesGroup' && reviewMode !== 'revise' && (
              <FooterIconTwo
                FilterModeEnable={true}
                FilterMode={FilterMode}
                onClick={onClickCreateAssesseeGroup}
                primaryIcon={createAssesseeGroupPrimaryIcon}
                secondaryIcon={[]}
              />
            )}
          </>
        )}
      {isReviewRevise &&
        responseObject &&
        headerOne === 'associates' &&
        headerOneBadgeOne === 'group' && (
          <>
            <div style={{ padding: '2.5px' }}>
              <div style={{ padding: '2.5px' }}>
                <BasicCard
                  isAlertActive
                  isFlagActive={informationBasic?.associateGroupFlag || false}
                  className=""
                  labelTextOneOne="name"
                  labelTextOneTwo="description"
                  textOneOne={informationBasic.associateGroupName || 'No Information'}
                  textOneTwo={informationBasic.associateGroupDescription || 'No Information'}
                  isVerifiedActiveName={informationBasic.associateGroupNameVerification || false}
                  isVerifiedActivePicture={
                    informationBasic.associateGroupPictureVerification || false
                  }
                  mode={reviewMode}
                  isImageActive={informationBasic.associateGroupPicture ? true : false}
                  imageOne={informationBasic.associateGroupPicture}
                  onClickRevise={reviseAssociateGroupBasicInformation}
                />
              </div>
              <Sections
                listSections={rightPaneSectionsAssociateGroup}
                selectedSection={selectedSectionAssociateGroup}
                setSelectedSection={setSelectedSectionAssociateGroup}
              />
            </div>
            {reviewMode === 'revise' && (
              <FooterIconTwo
                FilterModeEnable={isShowReviseIcon}
                FilterMode={FilterMode}
                onClick={onClickRevise}
                primaryIcon={revisePrimaryIcon}
                secondaryIcon={reviseSecondaryIcons}
              />
            )}
            {createMode === 'associatesGroup' && reviewMode !== 'revise' && (
              <FooterIconTwo
                FilterModeEnable={true}
                FilterMode={FilterMode}
                onClick={onClickCreateAssociateGroup}
                primaryIcon={createAssociateGroupPrimaryIcon}
                secondaryIcon={[]}
              />
            )}
          </>
        )}
      {isReviewRevise &&
        responseObject &&
        headerOne === 'assessments' &&
        headerOneBadgeOne === 'group' && (
          <>
            <div style={{ padding: '2.5px' }}>
              <div style={{ padding: '2.5px' }}>
                <BasicCard
                  isAlertActive
                  isFlagActive={informationBasic?.assessmentGroupFlag || false}
                  className=""
                  labelTextOneOne="name"
                  labelTextOneTwo="description"
                  textOneOne={informationBasic?.assessmentGroupName || 'No Information'}
                  textOneTwo={informationBasic?.assessmentGroupDescription || 'No Information'}
                  isVerifiedActiveName={informationBasic?.assessmentGroupNameVerification || false}
                  isVerifiedActivePicture={
                    informationBasic?.assessmentGroupPictureVerification || false
                  }
                  mode={reviewMode}
                  onClickRevise={reviseAssessmentGroupBasicInformation}
                  isImageActive={informationBasic?.assessmentGroupPicture ? true : false}
                  imageOne={informationBasic?.assessmentGroupPicture}
                />
              </div>
              <Sections
                listSections={rightPaneSectionsAssessmentGroup}
                selectedSection={selectedSectionAssessmentGroup}
                setSelectedSection={setSelectedSectionAssessmentGroup}
              />
            </div>
            {reviewMode === 'revise' && (
              <FooterIconTwo
                FilterModeEnable={isShowReviseIcon}
                FilterMode={FilterMode}
                onClick={onClickRevise}
                primaryIcon={revisePrimaryIcon}
                secondaryIcon={reviseSecondaryIcons}
              />
            )}
            {createMode === 'assessmentsGroup' && reviewMode !== 'revise' && (
              <FooterIconTwo
                FilterModeEnable={true}
                FilterMode={FilterMode}
                onClick={onClickCreateAssessmentGroup}
                primaryIcon={createAssessmentGroupPrimaryIcon}
                secondaryIcon={[]}
              />
            )}
          </>
        )}
      {isReviewRevise &&
        responseObject &&
        headerOne === 'culture-profiles' &&
        headerOneBadgeOne === 'group' && (
          <>
            <div style={{ padding: '2.5px' }}>
              <div style={{ padding: '2.5px' }}>
                <BasicCard
                  isAlertActive
                  isFlagActive={informationBasic?.cultureProfileGroupFlag || false}
                  className=""
                  labelTextOneOne="name"
                  labelTextOneTwo="description"
                  textOneOne={informationBasic?.cultureProfileGroupName || 'No Information'}
                  textOneTwo={informationBasic?.cultureProfileGroupDescription || 'No Information'}
                  isVerifiedActiveName={
                    informationBasic?.cultureProfileGroupNameVerification || false
                  }
                  isVerifiedActivePicture={
                    informationBasic?.cultureProfileGroupPictureVerification || false
                  }
                  mode={reviewMode}
                  onClickRevise={reviseCultureProfileGroupBasicInformation}
                  isImageActive={informationBasic?.cultureProfileGroupPicture ? true : false}
                  imageOne={informationBasic?.cultureProfileGroupPicture}
                />
              </div>
              <Sections
                listSections={rightPaneSectionsCultureProfileGroup}
                selectedSection={selectedSectionCultureProfileGroup}
                setSelectedSection={setSelectedSectionCultureProfileGroup}
              />
            </div>
            {reviewMode === 'revise' && (
              <FooterIconTwo
                FilterModeEnable={isShowReviseIcon}
                FilterMode={FilterMode}
                onClick={onClickRevise}
                primaryIcon={revisePrimaryIcon}
                secondaryIcon={reviseSecondaryIcons}
              />
            )}
            {createMode === 'culture-profilesGroup' && reviewMode !== 'revise' && (
              <FooterIconTwo
                FilterModeEnable={true}
                FilterMode={FilterMode}
                onClick={onClickCreateCultureProfileGroup}
                primaryIcon={createCultureProfileGroupPrimaryIcon}
                secondaryIcon={[]}
              />
            )}
          </>
        )}
      {isReviewRevise &&
        responseObject &&
        headerOne === 'job-profiles' &&
        headerOneBadgeOne === 'group' && (
          <>
            <div style={{ padding: '2.5px' }}>
              <div style={{ padding: '2.5px' }}>
                <BasicCard
                  isAlertActive
                  isFlagActive={informationBasic?.jobProfileGroupFlag || false}
                  className=""
                  labelTextOneOne="name"
                  labelTextOneTwo="description"
                  textOneOne={informationBasic.jobProfileGroupName || 'No Information'}
                  textOneTwo={informationBasic.jobProfileGroupDescription || 'No Information'}
                  isVerifiedActiveName={informationBasic.jobProfileGroupNameVerification || false}
                  isVerifiedActivePicture={
                    informationBasic.jobProfileGroupPictureVerification || false
                  }
                  mode={reviewMode}
                  onClickRevise={reviseJobProfileGroupBasicInformation}
                  isImageActive={informationBasic.jobProfileGroupPicture ? true : false}
                  imageOne={informationBasic.jobProfileGroupPicture}
                />
              </div>
              <Sections
                listSections={rightPaneSectionsJobProfileGroup}
                selectedSection={selectedSectionJobProfileGroup}
                setSelectedSection={setSelectedSectionJobProfileGroup}
              />
            </div>
            {reviewMode === 'revise' && (
              <FooterIconTwo
                FilterModeEnable={isShowReviseIcon}
                FilterMode={FilterMode}
                onClick={onClickRevise}
                primaryIcon={revisePrimaryIcon}
                secondaryIcon={reviseSecondaryIcons}
              />
            )}
            {createMode === 'job-profilesGroup' && reviewMode !== 'revise' && (
              <FooterIconTwo
                FilterModeEnable={true}
                FilterMode={FilterMode}
                onClick={onClickCreateJobProfileGroup}
                primaryIcon={createJobProfileGroupPrimaryIcon}
                secondaryIcon={[]}
              />
            )}
          </>
        )}
      {isReviewRevise &&
        responseObject &&
        headerOne === 'culture-profiles' &&
        headerOneBadgeOne === 'type' && (
          <>
            <div style={{ padding: '2.5px' }}>
              <div style={{ padding: '2.5px' }}>
                <BasicCard
                  isAlertActive
                  isFlagActive={informationBasic?.cultureProfileTypeFlag || false}
                  className=""
                  labelTextOneOne="name"
                  labelTextOneTwo="description"
                  textOneOne={informationBasic.cultureProfileTypeName || 'No Information'}
                  textOneTwo={informationBasic.cultureProfileTypeDescription || 'No Information'}
                  isVerifiedActiveName={
                    informationBasic.cultureProfileTypeNameVerification || false
                  }
                  isVerifiedActivePicture={
                    informationBasic.cultureProfileTypePictureVerification || false
                  }
                  mode={reviewMode}
                  onClickRevise={reviseCultureProfileTypeBasicInformation}
                  isImageActive={informationBasic.cultureProfileTypePicture ? true : false}
                  imageOne={informationBasic.cultureProfileTypePicture}
                />
              </div>
              <Sections
                listSections={rightPaneSectionsCultureProfileType}
                selectedSection={selectedSectionCultureProfileType}
                setSelectedSection={setSelectedSectionCultureProfileType}
              />
            </div>
            {reviewMode === 'revise' && (
              <FooterIconTwo
                FilterModeEnable={isShowReviseIcon}
                FilterMode={FilterMode}
                onClick={onClickRevise}
                primaryIcon={revisePrimaryIcon}
                secondaryIcon={reviseSecondaryIcons}
              />
            )}
            {createMode === 'culture-profilesType' && reviewMode !== 'revise' && (
              <FooterIconTwo
                FilterModeEnable={true}
                FilterMode={FilterMode}
                onClick={onClickCreateCultureProfileType}
                primaryIcon={createCultureProfileTypePrimaryIcon}
                secondaryIcon={[]}
              />
            )}
          </>
        )}
      {isReviewRevise &&
        responseObject &&
        headerOne === 'job-profiles' &&
        headerOneBadgeOne === 'type' && (
          <>
            <div style={{ padding: '2.5px' }}>
              <div style={{ padding: '2.5px' }}>
                <BasicCard
                  isAlertActive
                  isFlagActive={informationBasic?.jobProfileTypeFlag || false}
                  className=""
                  labelTextOneOne="name"
                  labelTextOneTwo="description"
                  textOneOne={informationBasic.jobProfileTypeName || 'No Information'}
                  textOneTwo={informationBasic.jobProfileTypeDescription || 'No Information'}
                  isVerifiedActiveName={informationBasic.jobProfileTypeNameVerification || false}
                  isVerifiedActivePicture={
                    informationBasic.jobProfileTypePictureVerification || false
                  }
                  mode={reviewMode}
                  onClickRevise={reviseJobProfileTypeBasicInformation}
                  isImageActive={informationBasic.jobProfileTypePicture ? true : false}
                  imageOne={informationBasic.jobProfileTypePicture}
                />
              </div>
              <Sections
                listSections={rightPaneSectionsJobProfileType}
                selectedSection={selectedSectionJobProfileType}
                setSelectedSection={setSelectedSectionJobProfileType}
              />
            </div>
            {reviewMode === 'revise' && (
              <FooterIconTwo
                FilterModeEnable={isShowReviseIcon}
                FilterMode={FilterMode}
                onClick={onClickRevise}
                primaryIcon={revisePrimaryIcon}
                secondaryIcon={reviseSecondaryIcons}
              />
            )}
            {createMode === 'job-profilesType' && reviewMode !== 'revise' && (
              <FooterIconTwo
                FilterModeEnable={true}
                FilterMode={FilterMode}
                onClick={onClickCreateJobProfileType}
                primaryIcon={createJobProfileTypePrimaryIcon}
                secondaryIcon={[]}
              />
            )}
          </>
        )}
      {isReviewRevise &&
        responseObject &&
        headerOne === 'items' &&
        headerOneBadgeOne === 'group' && (
          <>
            <div style={{ padding: '2.5px' }}>
              <div style={{ padding: '2.5px' }}>
                <BasicCard
                  isAlertActive
                  isFlagActive={informationBasic?.itemGroupFlag || false}
                  className=""
                  labelTextOneOne="name"
                  labelTextOneTwo="description"
                  textOneOne={informationBasic?.itemGroupName || 'No Information'}
                  textOneTwo={informationBasic?.itemGroupDescription || 'No Information'}
                  isVerifiedActiveName={informationBasic?.itemGroupNameVerification || false}
                  isVerifiedActivePicture={informationBasic?.itemGroupPictureVerification || false}
                  mode={reviewMode}
                  onClickRevise={reviseItemsGroupBasicInformation}
                  isImageActive={informationBasic?.itemGroupPicture ? true : false}
                  imageOne={informationBasic?.itemGroupPicture}
                />
              </div>
              <Sections
                listSections={rightPaneSectionsItemGroup}
                selectedSection={selectedSectionItemGroup}
                setSelectedSection={setSelectedSectionItemGroup}
              />
            </div>
            {reviewMode === 'revise' && (
              <FooterIconTwo
                FilterModeEnable={isShowReviseIcon}
                FilterMode={FilterMode}
                onClick={onClickRevise}
                primaryIcon={revisePrimaryIcon}
                secondaryIcon={reviseSecondaryIcons}
              />
            )}
            {createMode === 'itemsGroup' && reviewMode !== 'revise' && (
              <FooterIconTwo
                FilterModeEnable={true}
                FilterMode={FilterMode}
                onClick={onClickCreateItemGroup}
                primaryIcon={createItemGroupPrimaryIcon}
                secondaryIcon={[]}
              />
            )}
          </>
        )}
      {isReviewRevise &&
        responseObject &&
        headerOne === 'assessment' &&
        headerOneBadgeOne === 'distinct' && (
          <>
            <div style={{ padding: '2.5px' }}>
              <div style={{ padding: '2.5px' }}>
                <BasicCard
                  isAlertActive
                  isFlagActive={informationBasic?.assessmentFlag || false}
                  className=""
                  labelTextOneOne="name"
                  labelTextOneTwo="description"
                  textOneOne={informationBasic?.assessmentName || 'No Information'}
                  textOneTwo={informationBasic?.assessmentDescription || 'No Information'}
                  isVerifiedActiveName={informationBasic?.assessmentNameVerification || false}
                  isVerifiedActivePicture={informationBasic?.assessmentPictureVerification || false}
                  mode={reviewMode}
                  onClickRevise={reviseAssessmentBasicInformation}
                  isImageActive={informationBasic?.assessmentPicture ? true : false}
                  imageOne={informationBasic?.assessmentPicture}
                />
              </div>
              <Sections
                listSections={rightPaneSectionsAssessment}
                selectedSection={selectedSectionAssessment}
                setSelectedSection={setSelectedSectionAssessment}
              />
            </div>
            {reviewMode === 'revise' && (
              <FooterIconTwo
                FilterModeEnable={isShowReviseIcon}
                FilterMode={FilterMode}
                onClick={onClickRevise}
                primaryIcon={revisePrimaryIcon}
                secondaryIcon={reviseSecondaryIcons}
              />
            )}
            {createMode === 'assessment' && reviewMode !== 'revise' && (
              <FooterIconTwo
                FilterModeEnable={true}
                FilterMode={FilterMode}
                onClick={onClickCreateAssessment}
                primaryIcon={createAssessmentPrimaryIcon}
                secondaryIcon={[]}
              />
            )}
          </>
        )}
      {isReviewRevise &&
        responseObject &&
        headerOne === 'culture-profile' &&
        headerOneBadgeOne === 'distinct' && (
          <>
            <div style={{ padding: '2.5px' }}>
              <div style={{ padding: '2.5px' }}>
                <BasicCard
                  isAlertActive
                  isFlagActive={informationBasic?.cultureProfileFlag || false}
                  className=""
                  labelTextOneOne="name"
                  labelTextOneTwo="description"
                  textOneOne={informationBasic?.cultureProfileName || 'No Information'}
                  textOneTwo={informationBasic?.cultureProfileDescription || 'No Information'}
                  isVerifiedActiveName={informationBasic?.cultureProfileNameVerification || false}
                  isVerifiedActivePicture={
                    informationBasic?.cultureProfilePictureVerification || false
                  }
                  mode={reviewMode}
                  onClickRevise={reviseCultureProfileBasicInformation}
                  isImageActive={informationBasic?.cultureProfilePicture ? true : false}
                  imageOne={informationBasic?.cultureProfilePicture}
                />
              </div>
              <Sections
                listSections={rightPaneSectionsCultureProfile}
                selectedSection={selectedSectionCultureProfile}
                setSelectedSection={setSelectedSectionCultureProfile}
              />
            </div>
            {reviewMode === 'revise' && (
              <FooterIconTwo
                FilterModeEnable={isShowReviseIcon}
                FilterMode={FilterMode}
                onClick={onClickRevise}
                primaryIcon={revisePrimaryIcon}
                secondaryIcon={reviseSecondaryIcons}
              />
            )}
            {createMode === 'cultureProfile' && reviewMode !== 'revise' && (
              <FooterIconTwo
                FilterModeEnable={true}
                FilterMode={FilterMode}
                onClick={onClickCreateCultureProfile}
                primaryIcon={createCultureProfilePrimaryIcon}
                secondaryIcon={[]}
              />
            )}
          </>
        )}
      {isReviewRevise &&
        responseObject &&
        headerOne === 'job-profile' &&
        headerOneBadgeOne === 'distinct' && (
          <>
            <div style={{ padding: '2.5px' }}>
              <div style={{ padding: '2.5px' }}>
                <BasicCard
                  isAlertActive
                  isFlagActive={informationBasic?.jobProfileFlag || false}
                  className=""
                  labelTextOneOne="name"
                  labelTextOneTwo="description"
                  textOneOne={informationBasic?.jobProfileName || 'No Information'}
                  textOneTwo={informationBasic?.jobProfileDescription || 'No Information'}
                  isVerifiedActiveName={informationBasic?.jobProfileNameVerification || false}
                  isVerifiedActivePicture={informationBasic?.jobProfilePictureVerification || false}
                  mode={reviewMode}
                  onClickRevise={reviseJobProfileBasicInformation}
                  isImageActive={informationBasic?.jobProfilePicture ? true : false}
                  imageOne={informationBasic?.jobProfilePicture}
                />
              </div>
              <Sections
                listSections={rightPaneSectionsJobProfile}
                selectedSection={selectedSectionJobProfile}
                setSelectedSection={setSelectedSectionJobProfile}
              />
            </div>
            {reviewMode === 'revise' && (
              <FooterIconTwo
                FilterModeEnable={isShowReviseIcon}
                FilterMode={FilterMode}
                onClick={onClickRevise}
                primaryIcon={revisePrimaryIcon}
                secondaryIcon={reviseSecondaryIcons}
              />
            )}
            {createMode === 'jobProfile' && reviewMode !== 'revise' && (
              <FooterIconTwo
                FilterModeEnable={true}
                FilterMode={FilterMode}
                onClick={onClickCreateJobProfile}
                primaryIcon={createJobProfilePrimaryIcon}
                secondaryIcon={[]}
              />
            )}
          </>
        )}
      {isReviewRevise &&
        responseObject &&
        headerOne === 'assignment' &&
        headerOneBadgeOne === 'distinct' && (
          <>
            <div style={{ padding: '2.5px' }}>
              <div style={{ padding: '2.5px' }}>
                <BasicCard
                  isAlertActive
                  isFlagActive={informationBasic?.assignmentFlag || false}
                  className=""
                  labelTextOneOne="name"
                  labelTextOneTwo="description"
                  textOneOne={informationBasic?.assignmentName || 'No Information'}
                  textOneTwo={informationBasic?.assignmentDescription || 'No Information'}
                  isVerifiedActiveName={informationBasic?.assignmentNameVerification || false}
                  isVerifiedActivePicture={informationBasic?.assignmentPictureVerification || false}
                  mode={reviewMode}
                  onClickRevise={reviseAssignmentBasicInformation}
                  isImageActive={informationBasic?.assignmentPicture ? true : false}
                  imageOne={informationBasic?.assignmentPicture}
                />
              </div>
              <Sections
                listSections={rightPaneSectionsAssignment}
                selectedSection={selectedSectionAssignment}
                setSelectedSection={setSelectedSectionAssignment}
              />
            </div>
            {reviewMode === 'revise' && (
              <FooterIconTwo
                FilterModeEnable={isShowReviseIcon}
                FilterMode={FilterMode}
                onClick={onClickRevise}
                primaryIcon={revisePrimaryIcon}
                secondaryIcon={reviseSecondaryIcons}
              />
            )}
            {createMode === 'assignment' && reviewMode !== 'revise' && (
              <FooterIconTwo
                FilterModeEnable={true}
                FilterMode={FilterMode}
                onClick={onClickCreateAssignment}
                primaryIcon={createAssignmentPrimaryIcon}
                secondaryIcon={[]}
              />
            )}
          </>
        )}
      {isReviewRevise &&
        responseObject &&
        headerOne === 'assignments' &&
        headerOneBadgeOne === 'group' && (
          <>
            <div style={{ padding: '2.5px' }}>
              <div style={{ padding: '2.5px' }}>
                <BasicCard
                  isAlertActive
                  isFlagActive={informationBasic?.assignmentGroupFlag || false}
                  className=""
                  labelTextOneOne="name"
                  labelTextOneTwo="description"
                  textOneOne={informationBasic.assignmentGroupName || 'No Information'}
                  textOneTwo={informationBasic.assignmentGroupDescription || 'No Information'}
                  isVerifiedActiveName={informationBasic.assignmentGroupNameVerification || false}
                  isVerifiedActivePicture={
                    informationBasic.assignmentGroupPictureVerification || false
                  }
                  mode={reviewMode}
                  onClickRevise={reviseAssignmentGroupBasicInformation}
                  isImageActive={informationBasic.assignmentGroupPicture ? true : false}
                  imageOne={informationBasic.assignmentGroupPicture}
                />
              </div>
              <Sections
                listSections={rightPaneSectionsAssignmentGroup}
                selectedSection={selectedSectionAssignmentGroup}
                setSelectedSection={setSelectedSectionAssignmentGroup}
              />
            </div>
            {reviewMode === 'revise' && (
              <FooterIconTwo
                FilterModeEnable={isShowReviseIcon}
                FilterMode={FilterMode}
                onClick={onClickRevise}
                primaryIcon={revisePrimaryIcon}
                secondaryIcon={reviseSecondaryIcons}
              />
            )}
            {createMode === 'assignmentsGroup' && reviewMode !== 'revise' && (
              <FooterIconTwo
                FilterModeEnable={true}
                FilterMode={FilterMode}
                onClick={onClickCreateAssignmentGroup}
                primaryIcon={createAssignmentGroupPrimaryIcon}
                secondaryIcon={[]}
              />
            )}
          </>
        )}
      {isReviewRevise &&
        responseObject &&
        headerOne === 'assignments' &&
        headerOneBadgeOne === 'type' && (
          <>
            <div style={{ padding: '2.5px' }}>
              <div style={{ padding: '2.5px' }}>
                <BasicCard
                  isAlertActive
                  isFlagActive={informationBasic?.assignmentTypeFlag || false}
                  className=""
                  labelTextOneOne="name"
                  labelTextOneTwo="description"
                  textOneOne={informationBasic.assignmentTypeName || 'No Information'}
                  textOneTwo={informationBasic.assignmentTypeDescription || 'No Information'}
                  isVerifiedActiveName={informationBasic.assignmentTypeNameVerification || false}
                  isVerifiedActivePicture={
                    informationBasic.assignmentTypePictureVerification || false
                  }
                  mode={reviewMode}
                  onClickRevise={reviseAssignmentTypeBasicInformation}
                  isImageActive={informationBasic.assignmentTypePicture ? true : false}
                  imageOne={informationBasic.assignmentTypePicture}
                />
              </div>
              <Sections
                listSections={rightPaneSectionsAssignmentType}
                selectedSection={selectedSectionAssignmentType}
                setSelectedSection={setSelectedSectionAssignmentType}
              />
            </div>
            {reviewMode === 'revise' && (
              <FooterIconTwo
                FilterModeEnable={isShowReviseIcon}
                FilterMode={FilterMode}
                onClick={onClickRevise}
                primaryIcon={revisePrimaryIcon}
                secondaryIcon={reviseSecondaryIcons}
              />
            )}
            {createMode === 'assignmentsType' && reviewMode !== 'revise' && (
              <FooterIconTwo
                FilterModeEnable={true}
                FilterMode={FilterMode}
                onClick={onClickCreateAssignmentType}
                primaryIcon={createAssignmentTypePrimaryIcon}
                secondaryIcon={[]}
              />
            )}
          </>
        )}
      {isReviewRevise &&
        responseObject &&
        headerOne === 'assessment' &&
        headerOneBadgeOne === 'norm' && (
          <>
            <div style={{ padding: '2.5px' }}>
              <div style={{ padding: '2.5px' }}>
                <BasicCard
                  isAlertActive
                  isFlagActive={responseObject?.assessmentNormFlag || false}
                  className=""
                  labelTextOneOne="name"
                  labelTextOneTwo="description"
                  textOneOne={responseObject?.assessmentNormName || 'No Information'}
                  textOneTwo={responseObject?.assessmentNormDescription || 'No Information'}
                  isVerifiedActiveName={responseObject?.assessmentNormNameVerification || false}
                  isVerifiedActivePicture={
                    responseObject?.assessmentNormPictureVerification || false
                  }
                  mode={reviewMode}
                  isImageActive={responseObject?.assessmentNormPicture ? true : false}
                  imageOne={responseObject?.assessmentNormPicture}
                  onClickRevise={reviseAssessmentNormBasicInformation}
                />
              </div>
              <Sections
                listSections={rightPaneSectionsAssessmentNorm}
                selectedSection={selectedSectionAssessmentNorm}
                setSelectedSection={setSelectedSectionAssessmentNorm}
              />
            </div>
            {reviewMode === 'revise' && (
              <FooterIconTwo
                FilterModeEnable={isShowReviseIcon}
                FilterMode={FilterMode}
                onClick={onClickRevise}
                primaryIcon={revisePrimaryIcon}
                secondaryIcon={reviseSecondaryIcons}
              />
            )}
          </>
        )}
      {isReviewRevise &&
        responseObject &&
        headerOne === 'assessment' &&
        headerOneBadgeOne === 'score' && (
          <>
            <div style={{ padding: '2.5px' }}>
              <div style={{ padding: '2.5px' }}>
                <BasicCard
                  isAlertActive
                  isFlagActive={responseObject?.assessmentScoreFlag || false}
                  className=""
                  labelTextOneOne="name"
                  labelTextOneTwo="description"
                  textOneOne={responseObject?.assessmentScoreName || 'No Information'}
                  textOneTwo={responseObject?.assessmentScoreDescription || 'No Information'}
                  isVerifiedActiveName={responseObject?.assessmentScoreNameVerification || false}
                  isVerifiedActivePicture={
                    responseObject?.assessmentScorePictureVerification || false
                  }
                  mode={reviewMode}
                  isImageActive={responseObject?.assessmentScorePicture ? true : false}
                  imageOne={responseObject?.assessmentScorePicture}
                  onClickRevise={reviseAssessmentScoreBasicInformation}
                />
              </div>
              <Sections
                listSections={rightPaneSectionsAssessmentScore}
                selectedSection={selectedSectionAssessmentScore}
                setSelectedSection={setSelectedSectionAssessmentScore}
              />
            </div>
            {reviewMode === 'revise' && (
              <FooterIconTwo
                FilterModeEnable={isShowReviseIcon}
                FilterMode={FilterMode}
                onClick={onClickRevise}
                primaryIcon={revisePrimaryIcon}
                secondaryIcon={reviseSecondaryIcons}
              />
            )}
          </>
        )}
      {isReviewRevise &&
        responseObject &&
        headerOne === 'assessment' &&
        headerOneBadgeOne === 'grade' && (
          <>
            <div style={{ padding: '2.5px' }}>
              <div style={{ padding: '2.5px' }}>
                <BasicCard
                  isAlertActive
                  isFlagActive={false}
                  className=""
                  labelTextOneOne="name"
                  labelTextOneTwo="description"
                  textOneOne={responseObject?.assessmentScoreGradeName || 'No Information'}
                  textOneTwo={responseObject?.assessmentScoreGradeDescription || 'No Information'}
                  isVerifiedActiveName={
                    responseObject?.assessmentScoreGradeNameVerification || false
                  }
                  isVerifiedActivePicture={
                    responseObject?.assessmentScorePictureVerification || false
                  }
                  mode={reviewMode}
                  isImageActive={responseObject?.assessmentScoreGradePicture ? true : false}
                  imageOne={responseObject?.assessmentScoreGradePicture}
                  onClickRevise={reviseAssessmentGradeBasicInformation}
                />
              </div>
              <Sections
                listSections={rightPaneSectionsAssessmentScoreGrade}
                selectedSection={selectedSectionAssessmentScoreGrade}
                setSelectedSection={setSelectedSectionAssessmentScoreGrade}
              />
            </div>
            {reviewMode === 'revise' && (
              <FooterIconTwo
                FilterModeEnable={isShowReviseIcon}
                FilterMode={FilterMode}
                onClick={onClickRevise}
                primaryIcon={revisePrimaryIcon}
                secondaryIcon={reviseSecondaryIcons}
              />
            )}
          </>
        )}
      {isReviewRevise &&
        responseObject &&
        headerOne === 'assessment' &&
        headerOneBadgeOne === 'cluster' && (
          <>
            <div style={{ padding: '2.5px' }}>
              <div style={{ padding: '2.5px' }}>
                <BasicCard
                  isAlertActive
                  isFlagActive={responseObject?.assessmentClusterFlag || false}
                  className=""
                  labelTextOneOne="name"
                  labelTextOneTwo="description"
                  textOneOne={responseObject?.assessmentClusterName || 'No Information'}
                  textOneTwo={responseObject?.assessmentClusterDescription || 'No Information'}
                  isVerifiedActiveName={responseObject?.assessmentClusterNameVerification || false}
                  isVerifiedActivePicture={
                    responseObject?.assessmentClusterPictureVerification || false
                  }
                  mode={reviewMode}
                  isImageActive={responseObject?.assessmentClusterPicture ? true : false}
                  imageOne={responseObject?.assessmentClusterPicture}
                  onClickRevise={reviseAssessmentClusterBasicInformation}
                />
              </div>
              <Sections
                listSections={rightPaneSectionsAssessmentCluster}
                selectedSection={selectedSectionAssessmentCluster}
                setSelectedSection={setSelectedSectionAssessmentCluster}
              />
            </div>
            {reviewMode === 'revise' && (
              <FooterIconTwo
                FilterModeEnable={isShowReviseIcon}
                FilterMode={FilterMode}
                onClick={onClickRevise}
                primaryIcon={revisePrimaryIcon}
                secondaryIcon={reviseSecondaryIcons}
              />
            )}
          </>
        )}
      {isReviewRevise &&
        responseObject &&
        headerOne === 'assessment' &&
        (headerOneBadgeOne === 'scale' || headerOneBadgeOne === 'metrics') && (
          <>
            <div style={{ padding: '2.5px' }}>
              <div style={{ padding: '2.5px' }}>
                <BasicCard
                  isAlertActive
                  isFlagActive={responseObject?.assessmentScaleFlag || false}
                  className=""
                  labelTextOneOne="name"
                  labelTextOneTwo="description"
                  textOneOne={responseObject?.assessmentScaleName || 'No Information'}
                  textOneTwo={responseObject?.assessmentScaleDescription || 'No Information'}
                  isVerifiedActiveName={responseObject?.assessmentScaleNameVerification || false}
                  isVerifiedActivePicture={
                    responseObject?.assessmentScalePictureVerification || false
                  }
                  mode={reviewMode}
                  isImageActive={responseObject?.assessmentScalePicture ? true : false}
                  imageOne={responseObject?.assessmentScalePicture}
                  onClickRevise={reviseAssessmentScaleBasicInformation}
                />
              </div>
              <Sections
                listSections={rightPaneSectionsAssessmentScales}
                selectedSection={selectedSectionAssessmentScale}
                setSelectedSection={setSelectedSectionAssessmentScale}
              />
            </div>
            {reviewMode === 'revise' && (
              <FooterIconTwo
                FilterModeEnable={isShowReviseIcon}
                FilterMode={FilterMode}
                onClick={onClickRevise}
                primaryIcon={revisePrimaryIcon}
                secondaryIcon={reviseSecondaryIcons}
              />
            )}
          </>
        )}
      {isReviewRevise &&
        responseObject &&
        headerOne === 'assessment' &&
        headerOneBadgeOne === 'section' && (
          <>
            <div style={{ padding: '2.5px' }}>
              <div style={{ padding: '2.5px' }}>
                <BasicCard
                  isAlertActive
                  isFlagActive={responseObject?.assessmentSectionFlag || false}
                  className=""
                  labelTextOneOne="name"
                  labelTextOneTwo="description"
                  textOneOne={responseObject?.assessmentSectionName || 'No Information'}
                  textOneTwo={responseObject?.assessmentSectionDescription || 'No Information'}
                  isVerifiedActiveName={responseObject?.assessmentSectionNameVerification || false}
                  isVerifiedActivePicture={
                    responseObject?.assessmentSectionPictureVerification || false
                  }
                  mode={reviewMode}
                  // onClickRevise={reviseAssessmentSectionBasicInformation}
                  isImageActive={responseObject?.assessmentSectionPicture ? true : false}
                  imageOne={responseObject?.assessmentSectionPicture}
                  onClickRevise={reviseAssessmentSectionBasicInformation}
                />
              </div>
              <Sections
                listSections={rightPaneSectionsAssessmentSection}
                selectedSection={selectedSectionAssessmentSection}
                setSelectedSection={setSelectedSectionAssessmentSection}
              />
            </div>
            {reviewMode === 'revise' && (
              <FooterIconTwo
                FilterModeEnable={isShowReviseIcon}
                FilterMode={FilterMode}
                onClick={onClickRevise}
                primaryIcon={revisePrimaryIcon}
                secondaryIcon={reviseSecondaryIcons}
              />
            )}
          </>
        )}
      {isReviewRevise &&
        responseObject &&
        headerOne === 'assessment' &&
        headerOneBadgeOne === 'version' && (
          <>
            <div style={{ padding: '2.5px' }}>
              <div style={{ padding: '2.5px' }}>
                <BasicCard
                  isAlertActive
                  isFlagActive={responseObject?.assessmentVersionFlag || false}
                  className=""
                  labelTextOneOne="name"
                  labelTextOneTwo="description"
                  textOneOne={responseObject?.assessmentVersionName || 'No Information'}
                  textOneTwo={responseObject?.assessmentVersionDescription || 'No Information'}
                  isVerifiedActiveName={responseObject?.assessmentVersionNameVerification || false}
                  isVerifiedActivePicture={
                    responseObject?.assessmentVersionPictureVerification || false
                  }
                  mode={reviewMode}
                  isImageActive={responseObject?.assessmentVersionPicture ? true : false}
                  imageOne={responseObject?.assessmentVersionPicture}
                  onClickRevise={reviseAssessmentVersionBasicInformation}
                />
              </div>
              <Sections
                listSections={rightPaneSectionsAssessmentVersion}
                selectedSection={selectedSectionAssessmentVersion}
                setSelectedSection={setSelectedSectionAssessmentVersion}
              />
            </div>
            {reviewMode === 'revise' && (
              <FooterIconTwo
                FilterModeEnable={isShowReviseIcon}
                FilterMode={FilterMode}
                onClick={onClickRevise}
                primaryIcon={revisePrimaryIcon}
                secondaryIcon={reviseSecondaryIcons}
              />
            )}
          </>
        )}
      {isReviewRevise &&
        responseObject &&
        headerOne === 'assessments' &&
        headerOneBadgeOne === 'type' && (
          <>
            <div style={{ padding: '2.5px' }}>
              <div style={{ padding: '2.5px' }}>
                <BasicCard
                  isAlertActive
                  isFlagActive={informationBasic?.assessmentTypeFlag || false}
                  className=""
                  labelTextOneOne="name"
                  labelTextOneTwo="description"
                  textOneOne={informationBasic?.assessmentTypeName || 'No Information'}
                  textOneTwo={informationBasic?.assessmentTypeDescription || 'No Information'}
                  isVerifiedActiveName={informationBasic?.assessmentTypeNameVerification || false}
                  isVerifiedActivePicture={
                    informationBasic?.assessmentTypePictureVerification || false
                  }
                  mode={reviewMode}
                  onClickRevise={reviseAssessmentTypeBasicInformation}
                  isImageActive={informationBasic?.assessmentTypePicture ? true : false}
                  imageOne={informationBasic?.assessmentTypePicture}
                />
              </div>
              <Sections
                listSections={rightPaneSectionsAssessmentType}
                selectedSection={selectedSectionAssessmentType}
                setSelectedSection={setSelectedSectionAssessmentType}
              />
            </div>
            {reviewMode === 'revise' && (
              <FooterIconTwo
                FilterModeEnable={isShowReviseIcon}
                FilterMode={FilterMode}
                onClick={onClickRevise}
                primaryIcon={revisePrimaryIcon}
                secondaryIcon={reviseSecondaryIcons}
              />
            )}
            {createMode === 'assessmentsType' && reviewMode !== 'revise' && (
              <FooterIconTwo
                FilterModeEnable={true}
                FilterMode={FilterMode}
                onClick={onClickCreateAssessmentType}
                primaryIcon={createAssessmentTypePrimaryIcon}
                secondaryIcon={[]}
              />
            )}
          </>
        )}

      {isReviewRevise && headerOne === 'assessee' && headerOneBadgeOne === 'analytics' && (
        <>
          <div style={{ padding: '2.5px' }}>
            <div style={{ padding: '2.5px' }}>
              <BasicCard
                isAlertActive
                isFlagActive={assessee?.informationBasic?.assesseeFlag}
                className=""
                labelTextOneOne="name"
                labelTextOneTwo="alias"
                textOneOne={
                  assessee?.informationBasic?.assesseeNameFirst +
                  ' ' +
                  assessee?.informationBasic?.assesseeNameLast
                }
                textOneTwo={
                  assessee?.informationBasic?.assesseeAlias != ''
                    ? assessee?.informationBasic?.assesseeAlias
                    : 'No Information'
                }
                isVerifiedActiveName={false}
                isVerifiedActivePicture={false}
                mode={reviewMode}
              />
            </div>
            <Sections
              listSections={rightPaneSectionsAssesseeReport}
              selectedSection={selectedSectionAssesseeReport}
              setSelectedSection={setSelectedSectionAssesseeReport}
            />
          </div>
        </>
      )}
      {isReviewRevise && headerOne === 'assignment' && headerOneBadgeOne === 'analytics' && (
        <>
          <div style={{ padding: '2.5px' }}>
            <div style={{ padding: '2.5px' }}>
              <BasicCard
                isAlertActive
                isFlagActive={assessee?.assesseeFlag}
                className=""
                labelTextOneOne="name"
                labelTextOneTwo="description"
                textOneOne={responseObject?.assignmentName || 'No Information'}
                textOneTwo={responseObject?.assignmentDescription || 'No Information'}
                isVerifiedActiveName={false}
                isVerifiedActivePicture={false}
                mode={reviewMode}
              />
            </div>
            <Sections
              listSections={rightPaneSectionsAssesseeReport}
              selectedSection={selectedSectionAssesseeReport}
              setSelectedSection={setSelectedSectionAssesseeReport}
            />
          </div>
          {/* <div style={{ padding: '2.5px' }}>
            <div style={{ padding: '2.5px' }}>
              <BasicCard
                isAlertActive
                isFlagActive={informationBasic?.assignmentFlag || false}
                className=""
                labelTextOneOne="name"
                labelTextOneTwo="description"
                textOneOne={informationBasic?.assignmentName || 'No Information'}
                textOneTwo={informationBasic?.assignmentDescription || 'No Information'}
                isVerifiedActiveName={false}
                isVerifiedActivePicture={false}
                mode={reviewMode}
              />
            </div>
            <Sections
              listSections={rightPaneSectionsAssignmentReport}
              selectedSection={selectedSectionAssignmentReport}
              setSelectedSection={DisplayPaneThreeSectionOneAssesseeReport}
            />
          </div> */}
        </>
      )}
      {isReviewRevise &&
        responseObject &&
        headerOne === 'assessees' &&
        headerOneBadgeOne === 'type' && (
          <>
            <div style={{ padding: '2.5px' }}>
              <div style={{ padding: '2.5px' }}>
                <BasicCard
                  isAlertActive
                  isFlagActive={informationBasic?.assesseeTypeFlag || false}
                  className=""
                  labelTextOneOne="name"
                  labelTextOneTwo="description"
                  textOneOne={informationBasic?.assesseeTypeName || 'No Information'}
                  textOneTwo={informationBasic?.assesseeTypeDescription || 'No Information'}
                  isVerifiedActiveName={informationBasic?.assesseeTypeNameVerification || false}
                  isVerifiedActivePicture={
                    informationBasic?.assesseeTypePictureVerification || false
                  }
                  mode={reviewMode}
                  onClickRevise={reviseAssesseeTypeBasicInformation}
                  isImageActive={informationBasic?.assesseeTypePicture ? true : false}
                  imageOne={informationBasic?.assesseeTypePicture}
                />
              </div>
              <Sections
                listSections={rightPaneSectionsAssesseeType}
                selectedSection={selectedSectionAssesseeType}
                setSelectedSection={setSelectedSectionAssesseeType}
              />
            </div>
            {reviewMode === 'revise' && (
              <FooterIconTwo
                FilterModeEnable={isShowReviseIcon}
                FilterMode={FilterMode}
                onClick={onClickRevise}
                primaryIcon={revisePrimaryIcon}
                secondaryIcon={reviseSecondaryIcons}
              />
            )}
            {createMode === 'assesseesType' && reviewMode !== 'revise' && (
              <FooterIconTwo
                FilterModeEnable={true}
                FilterMode={FilterMode}
                onClick={onClickCreateAssesseeType}
                primaryIcon={createAssesseeTypePrimaryIcon}
                secondaryIcon={[]}
              />
            )}
          </>
        )}
      {isReviewRevise &&
        responseObject &&
        (headerOne === 'administrators' ||
          headerOne === 'assistants' ||
          headerOne === 'managers') &&
        headerOneBadgeOne === 'type' && (
          <>
            <div style={{ padding: '2.5px' }}>
              <div style={{ padding: '2.5px' }}>
                <BasicCard
                  isAlertActive
                  isFlagActive={informationBasic?.assesseeTypeFlag || false}
                  className=""
                  labelTextOneOne="name"
                  labelTextOneTwo="description"
                  textOneOne={informationBasic?.assesseeTypeName || 'No Information'}
                  textOneTwo={informationBasic?.assesseeTypeDescription || 'No Information'}
                  isVerifiedActiveName={informationBasic?.assesseeTypeNameVerification || false}
                  isVerifiedActivePicture={
                    informationBasic?.assesseeTypePictureVerification || false
                  }
                  mode={reviewMode}
                  onClickRevise={reviseAssesseeTypeBasicInformation}
                  isImageActive={informationBasic?.assesseeTypePicture ? true : false}
                  imageOne={informationBasic?.assesseeTypePicture}
                />
              </div>
              <Sections
                listSections={rightPaneSectionsAssesseeType}
                selectedSection={selectedSectionAssesseeType}
                setSelectedSection={setSelectedSectionAssesseeType}
              />
            </div>
            {reviewMode === 'revise' && (
              <FooterIconTwo
                FilterModeEnable={isShowReviseIcon}
                FilterMode={FilterMode}
                onClick={onClickRevise}
                primaryIcon={revisePrimaryIcon}
                secondaryIcon={reviseSecondaryIcons}
              />
            )}
            {createMode === 'assesseesType' && reviewMode !== 'revise' && (
              <FooterIconTwo
                FilterModeEnable={true}
                FilterMode={FilterMode}
                onClick={onClickCreateAssesseeType}
                primaryIcon={createAssesseeTypePrimaryIcon}
                secondaryIcon={[]}
              />
            )}
          </>
        )}
      {isReviewRevise &&
        responseObject &&
        headerOne === 'associates' &&
        headerOneBadgeOne === 'type' && (
          <>
            <div style={{ padding: '2.5px' }}>
              <div style={{ padding: '2.5px' }}>
                <BasicCard
                  isAlertActive
                  isFlagActive={informationBasic?.associateTypeFlag || false}
                  className=""
                  labelTextOneOne="name"
                  labelTextOneTwo="description"
                  textOneOne={informationBasic.associateTypeName || 'No Information'}
                  textOneTwo={informationBasic.associateTypeDescription || 'No Information'}
                  isVerifiedActiveName={informationBasic.associateTypeNameVerification || false}
                  isVerifiedActivePicture={
                    informationBasic.associateTypePictureVerification || false
                  }
                  mode={reviewMode}
                  onClickRevise={reviseAssociateTypeBasicInformation}
                  isImageActive={informationBasic.associateTypePicture ? true : false}
                  imageOne={informationBasic.associateTypePicture}
                />
              </div>
              <Sections
                listSections={rightPaneSectionsAssociateType}
                selectedSection={selectedSectionAssociateType}
                setSelectedSection={setSelectedSectionAssociateType}
              />
            </div>
            {reviewMode === 'revise' && (
              <FooterIconTwo
                FilterModeEnable={isShowReviseIcon}
                FilterMode={FilterMode}
                onClick={onClickRevise}
                primaryIcon={revisePrimaryIcon}
                secondaryIcon={reviseSecondaryIcons}
              />
            )}
            {createMode === 'associatesType' && reviewMode !== 'revise' && (
              <FooterIconTwo
                FilterModeEnable={true}
                FilterMode={FilterMode}
                onClick={onClickCreateAssociateType}
                primaryIcon={createAssociateTypePrimaryIcon}
                secondaryIcon={[]}
              />
            )}
          </>
        )}
      {isReviewRevise &&
        responseObject &&
        headerOne === 'items' &&
        headerOneBadgeOne === 'type' && (
          <>
            <div style={{ padding: '2.5px' }}>
              <div style={{ padding: '2.5px' }}>
                <BasicCard
                  isAlertActive
                  isFlagActive={informationBasic?.itemTypeFlag || false}
                  className=""
                  labelTextOneOne="name"
                  labelTextOneTwo="description"
                  textOneOne={informationBasic.itemTypeName || 'No Information'}
                  textOneTwo={informationBasic.itemTypeDescription || 'No Information'}
                  isVerifiedActiveName={informationBasic.itemTypeNameVerification || false}
                  isVerifiedActivePicture={informationBasic.itemTypePictureVerification || false}
                  mode={reviewMode}
                  onClickRevise={reviseItemTypeBasicInformation}
                  isImageActive={informationBasic.itemTypePicture ? true : false}
                  imageOne={informationBasic.itemTypePicture}
                />
              </div>
              <Sections
                listSections={rightPaneSectionsItemType}
                selectedSection={selectedSectionItemType}
                setSelectedSection={setSelectedSectionItemType}
              />
            </div>
            {reviewMode === 'revise' && (
              <FooterIconTwo
                FilterModeEnable={isShowReviseIcon}
                FilterMode={FilterMode}
                onClick={onClickRevise}
                primaryIcon={revisePrimaryIcon}
                secondaryIcon={reviseSecondaryIcons}
              />
            )}
            {createMode === 'itemsType' && reviewMode !== 'revise' && (
              <FooterIconTwo
                FilterModeEnable={true}
                FilterMode={FilterMode}
                onClick={onClickCreateItemType}
                primaryIcon={createItemTypePrimaryIcon}
                secondaryIcon={[]}
              />
            )}
          </>
        )}
      {isReviewRevise &&
        responseObject &&
        headerOne === 'associates' &&
        headerOneBadgeOne === 'role' && (
          <>
            <div style={{ padding: '2.5px' }}>
              <div style={{ padding: '2.5px' }}>
                <BasicCard
                  isAlertActive
                  isFlagActive={informationBasic?.associateRoleFlag || false}
                  className=""
                  labelTextOneOne="name"
                  labelTextOneTwo="description"
                  textOneOne={informationBasic.associateRoleName || 'No Information'}
                  textOneTwo={informationBasic.associateRoleDescription || 'No Information'}
                  isVerifiedActiveName={informationBasic.associateRoleNameVerification || false}
                  isVerifiedActivePicture={
                    informationBasic.associateRolePictureVerification || false
                  }
                  mode={reviewMode}
                  onClickRevise={reviseAssociateRoleBasicInformation}
                  isImageActive={informationBasic.associateRolePicture ? true : false}
                  imageOne={informationBasic.associateRolePicture}
                />
              </div>
              <Sections
                listSections={rightPaneSectionsAssociateRole}
                selectedSection={selectedSectionAssociateRole}
                setSelectedSection={setSelectedSectionAssociateRole}
              />
            </div>
            {reviewMode === 'revise' && (
              <FooterIconTwo
                FilterModeEnable={isShowReviseIcon}
                FilterMode={FilterMode}
                onClick={onClickRevise}
                primaryIcon={revisePrimaryIcon}
                secondaryIcon={reviseSecondaryIcons}
              />
            )}
            {createMode === 'associatesRole' && reviewMode !== 'revise' && (
              <FooterIconTwo
                FilterModeEnable={true}
                FilterMode={FilterMode}
                onClick={onClickCreateAssociateRole}
                primaryIcon={createAssociateRolePrimaryIcon}
                secondaryIcon={[]}
              />
            )}
          </>
        )}
      {isReviewRevise &&
        responseObject &&
        (headerOne === 'associate' ||
          headerOne === 'associates' ||
          headerOne === 'assessees' ||
          headerOne === 'administrators' ||
          headerOne === 'assistants' ||
          headerOne === 'managers' ||
          headerOne === 'assessments' ||
          headerOne === 'assignments' ||
          headerOne === 'culture-profiles' ||
          headerOne === 'job-profiles' ||
          headerOne === 'items') &&
        headerOneBadgeOne === 'node' && (
          <>
            <div style={{ padding: '2.5px' }}>
              <div style={{ padding: '2.5px' }}>
                <BasicCard
                  isAlertActive
                  isFlagActive={informationBasic?.associateNodeFlag || false}
                  className=""
                  labelTextOneOne="name"
                  labelTextOneTwo="description"
                  textOneOne={informationBasic.associateNodeName || 'No Information'}
                  textOneTwo={informationBasic.associateNodeDescription || 'No Information'}
                  isVerifiedActiveName={informationBasic.associateNodeNameVerification || false}
                  isVerifiedActivePicture={
                    informationBasic.associateNodePictureVerification || false
                  }
                  mode={reviewMode}
                  onClickRevise={reviseAssociateNodeBasicInformation}
                  isImageActive={informationBasic.associateNodePicture ? true : false}
                  imageOne={informationBasic.associateNodePicture}
                />
              </div>
              <Sections
                listSections={rightPaneSectionsAssociateNode}
                selectedSection={selectedSectionAssociateNode}
                setSelectedSection={setSelectedSectionAssociateNode}
              />
            </div>
            {reviewMode === 'revise' && (
              <FooterIconTwo
                FilterModeEnable={isShowReviseIcon}
                FilterMode={FilterMode}
                onClick={onClickRevise}
                primaryIcon={revisePrimaryIcon}
                secondaryIcon={reviseSecondaryIcons}
              />
            )}
            {createMode === 'associatesNode' && reviewMode !== 'revise' && (
              <FooterIconTwo
                FilterModeEnable={true}
                FilterMode={FilterMode}
                onClick={onClickCreateAssociateNode}
                primaryIcon={createAssociateNodePrimaryIcon}
                secondaryIcon={[]}
              />
            )}
          </>
        )}
      {isReviewRevise &&
        responseObject &&
        headerOne === 'associate' &&
        (headerOneBadgeOne === 'distinct' || headerOneBadgeOne === 'self') && (
          <>
            <div style={{ padding: '2.5px' }}>
              <div style={{ padding: '2.5px' }}>
                <BasicCard
                  isAlertActive
                  isFlagActive={informationBasic?.associateFlag || false}
                  className=""
                  labelTextOneOne="name"
                  labelTextOneTwo="description"
                  textOneOne={informationBasic?.associateName || 'No Information'}
                  textOneTwo={informationBasic?.associateDescription || 'No Information'}
                  isVerifiedActiveName={informationBasic?.associateNameVerification || false}
                  isVerifiedActivePicture={informationBasic?.associatePictureVerification || false}
                  mode={reviewMode}
                  isAssociateIcon={true}
                  onClickRevise={reviseAssociateBasicInformation}
                  isImageActive={informationBasic?.associatePicture ? true : false}
                  imageOne={informationBasic?.associatePicture}
                />
              </div>
              <Sections
                listSections={rightPaneSectionsAssociate}
                selectedSection={selectedSectionAssociate}
                setSelectedSection={setSelectedSectionAssociate}
              />
            </div>
            {reviewMode === 'revise' && (
              <FooterIconTwo
                FilterModeEnable={isShowReviseIcon}
                FilterMode={FilterMode}
                onClick={onClickRevise}
                primaryIcon={revisePrimaryIcon}
                secondaryIcon={reviseSecondaryIcons}
              />
            )}
            {createMode === 'associate' && reviewMode !== 'revise' && (
              <FooterIconTwo
                FilterModeEnable={true}
                FilterMode={FilterMode}
                onClick={onClickCreateAssociate}
                primaryIcon={createAssociatePrimaryIcon}
                secondaryIcon={[]}
              />
            )}
          </>
        )}
      {isReviewRevise &&
        responseObject &&
        headerOne === 'item' &&
        headerOneBadgeOne === 'distinct' && (
          <>
            <div style={{ padding: '2.5px' }}>
              <div style={{ padding: '2.5px' }}>
                <BasicCard
                  isAlertActive
                  isFlagActive={informationBasic?.itemFlag || false}
                  className=""
                  labelTextOneOne="name"
                  labelTextOneTwo="description"
                  textOneOne={informationBasic?.itemName || 'No Information'}
                  textOneTwo={informationBasic?.itemDescription || 'No Information'}
                  isVerifiedActiveName={informationBasic?.itemNameVerification || false}
                  isVerifiedActivePicture={informationBasic?.itemPictureVerification || false}
                  mode={reviewMode}
                  onClickRevise={reviseItemBasicInformation}
                  isImageActive={informationBasic?.itemPicture ? true : false}
                  imageOne={informationBasic?.itemPicture}
                />
              </div>
              <Sections
                listSections={rightPaneSectionsItem}
                selectedSection={selectedSectionItem}
                setSelectedSection={setSelectedSectionItem}
              />
            </div>
            {reviewMode === 'revise' && (
              <FooterIconTwo
                FilterModeEnable={isShowReviseIcon}
                FilterMode={FilterMode}
                onClick={onClickRevise}
                primaryIcon={revisePrimaryIcon}
                secondaryIcon={reviseSecondaryIcons}
              />
            )}

            {createMode === 'item' && reviewMode !== 'revise' && (
              <FooterIconTwo
                FilterModeEnable={true}
                FilterMode={FilterMode}
                onClick={onClickCreateItem}
                primaryIcon={createItemPrimaryIcon}
                secondaryIcon={[]}
              />
            )}
          </>
        )}
      {isReviewRevise &&
        responseObject &&
        headerOne === 'gauge' &&
        (headerOneBadgeOne === 'distinct' ||
          headerOneBadgeOne === 'gauge' ||
          headerOneBadgeOne === 'metrics') && (
          <>
            <div style={{ padding: '2.5px' }}>
              <div style={{ padding: '2.5px' }}>
                <BasicCard
                  isAlertActive
                  isFlagActive={responseObject?.iGaugeOccupationFrameworkOneGaugeFlag || false}
                  className=""
                  labelTextOneOne="name"
                  labelTextOneTwo="description"
                  textOneOne={
                    responseObject?.iGaugeOccupationFrameworkOneGaugeName || 'No Information'
                  }
                  textOneTwo={
                    responseObject?.iGaugeOccupationFrameworkOneGaugeDescription || 'No Information'
                  }
                  isVerifiedActiveName={
                    responseObject?.iGaugeOccupationFrameworkOneGaugeNameVerification || false
                  }
                  isVerifiedActivePicture={
                    responseObject?.iGaugeOccupationFrameworkOneGaugePictureVerification || false
                  }
                  mode={reviewMode}
                  isImageActive={
                    responseObject?.iGaugeOccupationFrameworkOneGaugePicture ? true : false
                  }
                  imageOne={responseObject?.iGaugeOccupationFrameworkOneGaugePicture}
                  onClickRevise={reviseAssignmentGuageBasicInformation}
                />
              </div>
              <Sections
                listSections={rightPaneSectionsAssignmentGuages}
                selectedSection={selectedSectionAssignmentGuage}
                setSelectedSection={setSelectedSectionAssignmentGuage}
              />
            </div>
            {reviewMode === 'revise' && (
              <FooterIconTwo
                FilterModeEnable={isShowReviseIcon}
                FilterMode={FilterMode}
                onClick={onClickRevise}
                primaryIcon={revisePrimaryIcon}
                secondaryIcon={reviseSecondaryIcons}
              />
            )}
            {createMode === 'gauge' && reviewMode !== 'revise' && (
              <FooterIconTwo
                FilterModeEnable={true}
                FilterMode={FilterMode}
                onClick={onClickCreateGauge}
                primaryIcon={createGaugeIcon}
                secondaryIcon={[]}
              />
            )}
          </>
        )}
      {isReviewRevise &&
        responseObject &&
        headerOne === 'metrics' &&
        headerOneBadgeOne === 'distinct' && (
          <>
            <div style={{ padding: '2.5px' }}>
              <div style={{ padding: '2.5px' }}>
                <BasicCard
                  isAlertActive
                  isFlagActive={responseObject?.iGaugeOccupationFrameworkOneGaugeFlag || false}
                  className=""
                  labelTextOneOne="name"
                  labelTextOneTwo="description"
                  textOneOne={
                    responseObject?.iGaugeOccupationFrameworkOneGaugeMetric
                      .iGaugeOccupationFrameworkOneGaugeMetricLabel || 'No Information'
                  }
                  textOneTwo={
                    responseObject?.iGaugeOccupationFrameworkOneGaugeMetric
                      .iGaugeOccupationFrameworkOneGaugeMetricDescription || 'No Information'
                  }
                  isVerifiedActiveName={
                    responseObject?.iGaugeOccupationFrameworkOneGaugeNameVerification || false
                  }
                  isVerifiedActivePicture={
                    responseObject?.iGaugeOccupationFrameworkOneGaugePictureVerification || false
                  }
                  mode={reviewMode}
                  isImageActive={
                    responseObject?.iGaugeOccupationFrameworkOneGaugePicture ? true : false
                  }
                  imageOne={responseObject?.iGaugeOccupationFrameworkOneGaugePicture}
                  onClickRevise={reviseAssignmentmetricsBasicInformation}
                />
              </div>
              <Sections
                listSections={rightPaneSectionsAssignmentGuages}
                selectedSection={selectedSectionAssignmentGuage}
                setSelectedSection={setSelectedSectionAssignmentGuage}
              />
            </div>
            {reviewMode === 'revise' && (
              <FooterIconTwo
                FilterModeEnable={isShowReviseIcon}
                FilterMode={FilterMode}
                onClick={onClickRevise}
                primaryIcon={revisePrimaryIcon}
                secondaryIcon={reviseSecondaryIcons}
              />
            )}
            {createMode === 'gauge' && reviewMode !== 'revise' && (
              <FooterIconTwo
                FilterModeEnable={true}
                FilterMode={FilterMode}
                onClick={onClickCreateGauge}
                primaryIcon={createGaugeIcon}
                secondaryIcon={[]}
              />
            )}
          </>
        )}
      {isReviewRevise &&
        responseObject &&
        headerOne === 'profiler' &&
        headerOneBadgeOne === 'distinct' && (
          <>
            <div style={{ padding: '2.5px' }}>
              <div style={{ padding: '2.5px' }}>
                <BasicCard
                  isAlertActive
                  isFlagActive={responseObject?.profilerFlag || false}
                  className=""
                  labelTextOneOne="name"
                  labelTextOneTwo="description"
                  textOneOne={
                    responseObject?.profilerName ||
                    responseObject?.name ||
                    responseObject?.textOne ||
                    responseObject?.iGaugeOccupationFrameworkOneClusterName ||
                    'No Information'
                  }
                  textOneTwo={
                    responseObject?.profilerDescription ||
                    responseObject?.description ||
                    responseObject?.textTwo ||
                    responseObject?.iGaugeOccupationFrameworkOneClusterDescription ||
                    'No Information'
                  }
                  isVerifiedActiveName={responseObject?.profilerNameVerification || false}
                  isVerifiedActivePicture={responseObject?.profilerNamePictureVerification || false}
                  mode={reviewMode}
                  isImageActive={responseObject?.profilerNamePicture ? true : false}
                  imageOne={responseObject?.profilerNamePicture || false}
                  onClickRevise={reviseCultureJobProfilerBasicInformation}
                />
              </div>
              <Sections
                listSections={rightPaneSectionsProfiler}
                selectedSection={selectedSectionProfiler}
                setSelectedSection={setSelectedSectionProfiler}
              />
            </div>
            {reviewMode === 'revise' && (
              <FooterIconTwo
                FilterModeEnable={isShowReviseIcon}
                FilterMode={FilterMode}
                onClick={onClickRevise}
                primaryIcon={revisePrimaryIcon}
                secondaryIcon={reviseSecondaryIcons}
              />
            )}
            {createMode === 'associatesGroup' && reviewMode !== 'revise' && (
              <FooterIconTwo
                FilterModeEnable={true}
                FilterMode={FilterMode}
                onClick={onClickCreateAssociateGroup}
                primaryIcon={createAssociateGroupPrimaryIcon}
                secondaryIcon={[]}
              />
            )}
          </>
        )}
      {isReviewRevise &&
        responseObject && //headerOne === 'profiler' ||
        (headerOne === 'culture-dimension' ||
          headerOne === 'job-function' ||
          headerOne === 'job-domain' ||
          headerOne === 'job-role') &&
        headerOneBadgeOne === 'distinct' && (
          <>
            <div style={{ padding: '2.5px' }}>
              <div style={{ padding: '2.5px' }}>
                <BasicCard
                  isAlertActive
                  isFlagActive={responseObject?.profilerFlag || false}
                  className=""
                  labelTextOneOne="name"
                  labelTextOneTwo="description"
                  textOneOne={
                    responseObject?.profilerName ||
                    responseObject?.name ||
                    responseObject?.textOne ||
                    responseObject?.iGaugeOccupationFrameworkOneClusterName ||
                    'No Information'
                  }
                  textOneTwo={
                    responseObject?.profilerDescription ||
                    responseObject?.description ||
                    responseObject?.textTwo ||
                    responseObject?.iGaugeOccupationFrameworkOneClusterDescription ||
                    'No Information'
                  }
                  isVerifiedActiveName={responseObject?.profilerNameVerification || false}
                  isVerifiedActivePicture={responseObject?.profilerNamePictureVerification || false}
                  mode={reviewMode}
                  isImageActive={responseObject?.profilerNamePicture ? true : false}
                  imageOne={responseObject?.profilerNamePicture || false}
                  onClickRevise={reviseProfilerBasicInformation}
                />
              </div>
              <Sections
                listSections={rightPaneSectionsProfiler}
                selectedSection={selectedSectionProfiler}
                setSelectedSection={setSelectedSectionProfiler}
              />
            </div>
            {reviewMode === 'revise' && (
              <FooterIconTwo
                FilterModeEnable={isShowReviseIcon}
                FilterMode={FilterMode}
                onClick={onClickRevise}
                primaryIcon={revisePrimaryIcon}
                secondaryIcon={reviseSecondaryIcons}
              />
            )}
            {createMode === 'associatesGroup' && reviewMode !== 'revise' && (
              <FooterIconTwo
                FilterModeEnable={true}
                FilterMode={FilterMode}
                onClick={onClickCreateAssociateGroup}
                primaryIcon={createAssociateGroupPrimaryIcon}
                secondaryIcon={[]}
              />
            )}
          </>
        )}
      {isReviewRevise &&
        responseObject &&
        headerOne === 'job-competency' &&
        headerOneBadgeOne === 'distinct' && (
          <>
            <div style={{ padding: '2.5px' }}>
              <div style={{ padding: '2.5px' }}>
                <BasicCard
                  isAlertActive
                  isFlagActive={responseObject?.profilerFlag || false}
                  className=""
                  labelTextOneOne="name"
                  labelTextOneTwo="description"
                  textOneOne={
                    responseObject?.profilerName ||
                    responseObject?.name ||
                    responseObject?.textOne ||
                    responseObject?.iGaugeOccupationFrameworkOneClusterName ||
                    'No Information'
                  }
                  textOneTwo={
                    responseObject?.profilerDescription ||
                    responseObject?.description ||
                    responseObject?.textTwo ||
                    responseObject?.iGaugeOccupationFrameworkOneClusterDescription ||
                    'No Information'
                  }
                  isVerifiedActiveName={responseObject?.profilerNameVerification || false}
                  isVerifiedActivePicture={responseObject?.profilerNamePictureVerification || false}
                  mode={reviewMode}
                  isImageActive={responseObject?.profilerNamePicture ? true : false}
                  imageOne={responseObject?.profilerNamePicture || false}
                  onClickRevise={competencyreviseProfilerBasicInformation}
                />
              </div>
              <Sections
                listSections={rightPaneSectionsProfiler}
                selectedSection={selectedSectionProfiler}
                setSelectedSection={setSelectedSectionProfiler}
              />
            </div>
            {reviewMode === 'revise' && (
              <FooterIconTwo
                FilterModeEnable={isShowReviseIcon}
                FilterMode={FilterMode}
                onClick={onClickRevise}
                primaryIcon={revisePrimaryIcon}
                secondaryIcon={reviseSecondaryIcons}
              />
            )}
            {createMode === 'associatesGroup' && reviewMode !== 'revise' && (
              <FooterIconTwo
                FilterModeEnable={true}
                FilterMode={FilterMode}
                onClick={onClickCreateAssociateGroup}
                primaryIcon={createAssociateGroupPrimaryIcon}
                secondaryIcon={[]}
              />
            )}
          </>
        )}
      {isReviewRevise &&
        responseObject &&
        headerOne === 'cluster' &&
        headerOneBadgeOne === 'distinct' && (
          <>
            <div style={{ padding: '2.5px' }}>
              <div style={{ padding: '2.5px' }}>
                <BasicCard
                  isAlertActive
                  isFlagActive={responseObject?.profilerFlag || false}
                  className=""
                  labelTextOneOne="name"
                  labelTextOneTwo="description"
                  textOneOne={
                    responseObject?.iGaugeOccupationFrameworkOneClusterName || 'No Information'
                  }
                  textOneTwo={
                    responseObject?.iGaugeOccupationFrameworkOneClusterDescription ||
                    'No Information'
                  }
                  isVerifiedActiveName={false}
                  isVerifiedActivePicture={false}
                  mode={reviewMode}
                  isImageActive={false}
                  imageOne={false}
                  onClickRevise={reviseClusterProfilerBasicInformation}
                />
              </div>
              <Sections
                listSections={rightPaneSectionsProfiler}
                selectedSection={selectedSectionProfiler}
                setSelectedSection={setSelectedSectionProfiler}
              />
            </div>
            {reviewMode === 'revise' && (
              <FooterIconTwo
                FilterModeEnable={isShowReviseIcon}
                FilterMode={FilterMode}
                onClick={onClickRevise}
                primaryIcon={revisePrimaryIcon}
                secondaryIcon={reviseSecondaryIcons}
              />
            )}
            {createMode === 'associatesGroup' && reviewMode !== 'revise' && (
              <FooterIconTwo
                FilterModeEnable={true}
                FilterMode={FilterMode}
                onClick={onClickCreateAssociateGroup}
                primaryIcon={createAssociateGroupPrimaryIcon}
                secondaryIcon={[]}
              />
            )}
          </>
        )}
      {reviewMode === 'review' && responseObject && headerOne !== '' && createMode === '' && (
        <FooterIconTwo
          FilterModeEnable={navigatorIcon}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={primaryIcon}
          secondaryIcon={secondaryIcon}
        />
      )}
    </>
  );
};

export default DisplayPaneThree;
