import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import AllocationAccordian from '../Accordian/AllocationAccordian';
import { useDispatch, useSelector } from 'react-redux';
import { Paper } from '@material-ui/core';
import DisplayPanelAccordianReviewListOne from '../Accordian/DisplayPanelAccordianReviewListOne';
import DisplayPanelAccordianInformation from '../Accordian/DisplayPanelAccordianInformation';
import DocumentScannerIcon from '@material-ui/icons/SettingsOverscan';
import {
  makeAssesseeGroupObj,
  makeAssesseeReviewListRequestObject,
  makeAssessmentGroupObj,
  makeAssessmentReviewListRequestObject,
  makeAssignmentReferenceListReqObj,
  makeCultureProfileGroupObj,
  makeCultureProfileObj,
  makeJobProfileGroupObj,
  makeJobProfileObj,
  DisplayDate,
  closeRelatedList
} from '../../Actions/GenericActions';
import Manuscript from '@material-ui/icons/Description';
import AddIcon from '@material-ui/icons/Add';
import {
  FILTERMODE,
  GET_ALLOCATE_ASSESSEE,
  GET_ALLOCATE_ASSESSEE_GROUP,
  GET_ALLOCATE_ASSESSMENT,
  GET_ALLOCATE_ASSESSMENT_GROUP,
  GET_ALLOCATE_CULTURE,
  GET_ALLOCATE_CULTURE_GROUP,
  GET_ALLOCATE_JOB,
  GET_ALLOCATE_JOB_GROUP,
  GET_ASSIGNMENTDISTINCT_ASSESSEES_REVIEWLIST_SAGA,
  GET_ASSIGNMENT_REFERENCE_LIST_SAGA,
  LOADER_START,
  RELATED_REVIEWLIST_DISTINCT_DATA,
  SET_ASSIGNMENT_SINGLE_STATE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_PAGE_COUNT,
  SET_PANE_THREE_PREVIEW_MODE,
  SET_POPUP_VALUE,
  SET_REQUEST_OBJECT,
  ASSESSEE_MINE_ASSIGNMENT_LIST,
  SET_MIDDLEPANE_STATE,
  CLEAR_PANE_TWO_REDUCER,
  ASSESSEE_SIGN_ON,
  ASSESSEE_MINE_ASSIGNMENT_GROUP_LIST
} from '../../actionType';
import {
  getAssignmentRelatedAssessment,
  getAssignmentRelatedCultureProfile,
  getAssignmentRelatedJobProfile,
  getAssignmnetAssesseeDistinctApiCall,
  getAssignmnetAssessmentDistinctApiCall
} from '../../Actions/AssignmentModuleAction';

const DisplayPaneThreeSectionTwoAssignment = () => {
  const [listExpand, setListExpand] = useState('');
  const dispatch = useDispatch();
  const {
    createMode,
    headerOneBadgeTwo,
    headerOneBadgeThree,
    reviewMode,
    responseObject,
    assignmentRelatedReviewListPaneThree,
    headerOne
  } = useSelector((state) => state.DisplayPaneThreeReducer);
  const {
    selectedAssociateInfo,
    duplicateScanCount,
    scanCount,
    countPage,
    assignmentCreateType,
    typeOfMiddlePaneList,
    relatedReviewListDistinctData,
    middlePaneHeaderDuplicate,
    middlePaneHeaderBadgeOne,
    middlePaneHeaderBadgeTwo,
    middlePaneHeaderDuplicateBadgeOne,
    middlePaneHeaderDuplicateBadgeTwo,
    middlePaneHeaderDuplicateBadgeThree,
    middlePaneHeaderDuplicateBadgeFour,
    middlePaneHeaderDuplicateOne,
    middlePaneHeaderDuplicateOneBadgeOne,
    middlePaneHeaderDuplicateOneBadgeTwo,
    middlePaneHeaderDuplicateOneBadgeThree,
    middlePaneHeaderDuplicateOneBadgeFour,
    previousMiddlePaneState
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { informationFramework, informationAllocation } = responseObject;
  const AssignmentReducerInfo = useSelector((state) => state.AssignmentReducer);
  let assessee = assignmentRelatedReviewListPaneThree?.assessee || [];
  useEffect(() => {
    //  setListExpand('');
  }, [JSON.stringify(assignmentRelatedReviewListPaneThree)]);

  let isOccupationType = assignmentCreateType;
  if (
    AssignmentReducerInfo?.informationAllocation?.assignmentType?.assignmentTypeSecondary.includes(
      process.env.REACT_APP_IGURU_ANALYTICS_OCCUPATION
    )
  ) {
    isOccupationType = 'iGuru Analytics_Occupation';
  }

  // console.log('assignmentRelatedReviewListPaneThree', assignmentRelatedReviewListPaneThree);
  let assesseeArray = [];
  let assignmentAssesseeMineGroup = [];
  assessee.forEach((ob) => {
    const { id, informationBasic, associateId } = ob;
    assesseeArray.push({
      id,
      textOne: `${informationBasic.assesseeNameFirst} ${informationBasic.assesseeNameOther} ${informationBasic.assesseeNameLast}`,
      textTwo: informationBasic.assesseeAlias,
      status: ob?.assignmentData?.assesseeAssignmentStatus && ob?.assignmentData?.assesseeAssignmentStatus === 'UNFINISHED'
        ? 'TIMEOUT'
        : ob?.assignmentData?.assesseeAssignmentStatus || ob.informationEngagement.assesseeStatus,
      isShared: ob?.assesseeShared,
      associateId
    });
  });
  let adminSequenseArr = ['assignment assessment'];
  AssignmentReducerInfo?.informationFramework?.assignmentCommunique?.map((comm, ind) => {
    adminSequenseArr.push(`assignment communiqué ${ind + 1}`);
  });
  AssignmentReducerInfo?.informationFramework?.assignmentSynopsis?.map((com, ind) => {
    adminSequenseArr.push(`assignment synopsis ${ind + 1}`);
  });
  let assessment = assignmentRelatedReviewListPaneThree?.assessment || [];
  let communiqueArr = [];
  AssignmentReducerInfo?.informationFramework?.assignmentCommunique?.map((comm, index) => {
    communiqueArr.push({ labelTextOneOneBadge: index + 1, textOne: '' });
  });
  let synopsisArr = [];
  AssignmentReducerInfo?.informationFramework?.assignmentSynopsis?.map((comm, index) => {
    synopsisArr.push({ labelTextOneOneBadge: index + 1, textOne: '' });
  });
  let assessmentArray = [];
  assessment.forEach((ob) => {
    const { id, informationBasic, informationEngagement, associateId } = ob;
    assessmentArray.push({
      id,
      textOne: informationBasic.assessmentName,
      textTwo: informationBasic.assessmentDescription,
      status: informationEngagement.assessmentStatus,
      isShared: ob?.assessmentShared,
      associateId
    });
  });
  let isNotRevised =
    responseObject?.informationEngagement?.assignmentStatus === 'ADMINISTERED' ||
    responseObject?.informationEngagement?.assignmentStatus === 'PUBLISHED';
  let cultureProfile = assignmentRelatedReviewListPaneThree?.cultureProfile || [];
  let cultureProfileArray = [];
  cultureProfile.forEach((ob) => {
    const { id, informationBasic, informationEngagement, associateId } = ob;
    cultureProfileArray.push({
      id,
      textOne: informationBasic.cultureProfileName,
      textTwo: informationBasic.cultureProfileDescription,
      status: informationEngagement.cultureProfileStatus,
      isShared: ob?.cultureProfileShared,
      associateId
    });
  });

  let jobProfile = assignmentRelatedReviewListPaneThree?.jobProfile || [];
  let jobProfileArray = [];
  jobProfile.forEach((ob) => {
    const { id, informationBasic, informationEngagement, associateId } = ob;
    jobProfileArray.push({
      id,
      textOne: informationBasic.jobProfileName,
      textTwo: informationBasic.jobProfileDescription,
      status: informationEngagement.jobProfileStatus,
      isShared: ob?.jobProfileShared,
      associateId
    });
  });
  let assignmentGaugeFrameworkArray = [];
  if (informationFramework?.assignmentGaugeFramework?.length > 0) {
    informationFramework?.assignmentGaugeFramework.forEach((ob) => {
      assignmentGaugeFrameworkArray.push({
        id: ob?.id + '-',
        textOne: ob?.iGaugeOccupationFrameworkOneGaugeName,
        textTwo: ob?.iGaugeOccupationFrameworkOneGaugeDescription,
        status: ''
      });
    });
  } else {
    informationFramework?.igaugeOccupationFrameworkOneGaugeList.forEach((ob) => {
      if (informationFramework?.assignmentGaugeDistinct?.includes(ob?.id)) {
        assignmentGaugeFrameworkArray.push({
          id: ob?.id + '-',
          textOne: ob?.iGaugeOccupationFrameworkOneGaugeName,
          textTwo: ob?.iGaugeOccupationFrameworkOneGaugeDescription,
          status: ''
        });
      }
    });
  }

  let assignmentMineArray = [];
  responseObject?.assignmentDistinctEvaluationRefInfo?.forEach((ob) => {
    assignmentMineArray.push({
      id: ob?.id + '-',
      textOne: ob?.informationBasic?.assignmentName,
      textTwo: ob?.informationBasic?.assignmentDescription,
      status: ''
    });
  });

  const frameworkList = [
    {
      id: 'administration',
      labelTextOneOne: 'administration',
      labelTextOneOneBadges: [
        {
          labelTextTwoBadge: 'proctor',
          // labelTextOneOneBadge: 'proctor',
          innerLabelBadgeList: informationFramework?.assignmentAdministrationRepeat ? 'Yes' : 'No'
        },
        {
          //labelTextOneOneBadge: 'repeat',
          labelTextTwoBadge: 'repeat',
          innerLabelBadgeList: informationFramework?.assignmentAdministrationRepeat ? 'Yes' : 'No'
        },
        {
          //labelTextOneOneBadge: 'reset',
          labelTextTwoBadge: 'reset',
          innerLabelBadgeList: informationFramework?.assignmentAdministrationReset ? 'Yes' : 'No'
        },
        {
          //labelTextOneOneBadge: 'sequence',
          labelTextTwoBadge: 'sequence',
          innerLabelBadgeList:
            informationFramework?.assignmentAdministrationSequence?.toString() || 'No Information'
        },
        {
          //labelTextOneOneBadge: 'shuffle',
          labelTextTwoBadge: 'shuffle',
          innerLabelBadgeList: informationFramework?.assignmentAdministrationShuffle ? 'Yes' : 'No'
        },
        {
          //labelTextOneOneBadge: 'supervise',
          labelTextTwoBadge: 'supervise',
          innerLabelBadgeList: informationFramework?.assignmentAdministrationSupervise
            ? 'Yes'
            : 'No'
        },
        {
          //labelTextOneOneBadge: 'template',
          labelTextTwoBadge: 'template',
          //innerLabelBadgeList: informationFramework?.assignmentAdministrationTemplate ? 'Yes' : 'No'
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'alignment',
              innerLabelBadgeList:
                informationFramework?.assessmentScore?.assessmentScoreExtremumMaximum ||
                'No Information'
            },
            {
              labelTextTwoBadge: 'media',
              innerLabelBadgeList:
                informationFramework?.assessmentScore?.assessmentScoreExtremumMinimum ||
                'No Information'
            }
          ]
        }
        // {
        //   labelTextOneOneBadge: 'version',
        //   textOne: informationFramework?.assignmentAdministrationVersion ? 'Yes' : 'No'
        // }
      ],
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false,
      isMultiInfoCard: true
      // IconOne: Manuscript
    },
    {
      id: 'a1assessees',
      labelTextOneOne: 'assessees',
      isListCard: true,
      isReviewLink: true,
      labelTextOneOneBadgeOne: '',
      labelTextOneOneBadgeTwo: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'distinct',
          innerList: assesseeArray
        },
        {
          labelTextOneOneBadge: 'group',
          innerList: []
        }
      ],
      innerAssociateList: [],
      innerInfo: 'No Information',
      IconOne: null
    },
    {
      id: 'a20',
      labelTextOneOne: 'assessments',
      isListCard: true,
      isReviewLink: true,
      labelTextOneOneBadgeOne: '',
      labelTextOneOneBadgeTwo: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'distinct',
          innerList: assessmentArray.sort((a, b) =>
            a.testOne !== b.testOne ? (a.testOne < b.testOne ? -1 : 1) : 0
          )
        },
        {
          labelTextOneOneBadge: 'group',
          innerList: []
        }
      ],
      innerAssociateList: [],
      innerInfo: 'No Information',
      IconOne: null
    },
    {
      id: 'a1communiqué',
      labelTextOneOne: 'communiqué',
      labelTextOneOneBadges: communiqueArr,
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false,
      IconOne: reviewMode === 'revise' && !isNotRevised ? AddIcon : Manuscript,
      isAddIcon: reviewMode === 'revise' && !isNotRevised ? true : false
    },
    {
      id: 'marketplace00988',
      labelTextOneOne: 'iGuru marketplace',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'assessees',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'distinct',
              innerList: []
            },
            {
              labelTextTwoBadge: 'group',
              innerList: []
            }
          ]
        },
        {
          labelTextOneOneBadge: 'assessments',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'distinct',
              innerList: []
            },
            {
              labelTextTwoBadge: 'group',
              innerList: []
            }
          ]
        }
      ],
      innerInfo: 'No Information',
      isListCard: true,
      isReviewLink: true,
      isMultiList: true
    },
    {
      id: 'preview-assignment',
      labelTextOneOne: 'preview',
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false,
      IconOne: null,
      isReviewLink: true
    },
    {
      id: 'a4',
      labelTextOneOne: 'report',
      isListCard: false,
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: '',
          textOne: ''
        }
      ],
      innerAssociateList: [],
      innerInfo: 'No Information',
      IconOne: null
    },
    {
      id: 'synopsis',
      labelTextOneOne: 'synopsis',
      labelTextOneOneBadges: synopsisArr,
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false,
      IconOne: reviewMode === 'revise' && !isNotRevised ? AddIcon : Manuscript,
      isAddIcon: reviewMode === 'revise' && !isNotRevised ? true : false
    },
    {
      id: 'a2',
      labelTextOneOne: 'timeline',
      isListCard: false,
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'start',
          textOne: informationFramework?.assignmentTimeline?.assignmentTimelineDateTimeStart
            ? DisplayDate(informationFramework?.assignmentTimeline?.assignmentTimelineDateTimeStart)
            : 'No Information'
        },
        {
          labelTextOneOneBadge: 'end',
          textOne: informationFramework?.assignmentTimeline?.assignmentTimelineDateTimeEnd
            ? DisplayDate(informationFramework?.assignmentTimeline?.assignmentTimelineDateTimeEnd)
            : 'No Information'
        }
      ],
      labelTextOneOneBadgeTwo: '',
      innerAssociateList: [],
      innerInfo: '',
      IconOne: null
    }
  ];
  if (isOccupationType === 'iGuru Analytics_Occupation') {
    let marketplace_ana_obj = [
      {
        labelTextOneOneBadge: 'culture-profiles',
        innerLabelBadgeList: [
          {
            labelTextTwoBadge: 'distinct',
            innerList: []
          },
          {
            labelTextTwoBadge: 'group',
            innerList: []
          }
        ]
      },
      {
        labelTextOneOneBadge: 'job-profiles',
        innerLabelBadgeList: [
          {
            labelTextTwoBadge: 'distinct',
            innerList: jobProfileArray
          },
          {
            labelTextTwoBadge: 'group',
            innerList: []
          }
        ]
      }
    ]
    let analytic_Obj = {
      id: 'a61',
      labelTextOneOne: 'iGuru analytics',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'culture-profiles',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'distinct',
              innerList: cultureProfileArray
            },
            {
              labelTextTwoBadge: 'group',
              innerList: []
            }
          ]
        },
        {
          labelTextOneOneBadge: 'job-profiles',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'distinct',
              innerList: jobProfileArray
            },
            {
              labelTextTwoBadge: 'group',
              innerList: []
            }
          ]
        }
      ],
      innerInfo: 'No Information',
      isListCard: true,
      isReviewLink: true,
      isMultiList: true
    }
    let gaugeObj = {
      id: 'JG-001',
      labelTextOneOne: 'gauge',
      isListCard: true,
      isReviewLink: false,
      labelTextOneOneBadgeOne: '',
      labelTextOneOneBadgeTwo: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'distinct',
          innerList: assignmentGaugeFrameworkArray
        }
      ],
      innerAssociateList: [],
      innerInfo: 'No Information',
      IconOne: null
    };
    frameworkList.splice(4, 0, gaugeObj);
    frameworkList.splice(5, 0, analytic_Obj);
  }
  if (isOccupationType === 'iGuru Analytics_Occupation') {
    console.log('frameworkList', frameworkList);
    let market_cul_obj = {
      labelTextOneOneBadge: 'culture-profiles',
      innerLabelBadgeList: [
        {
          labelTextTwoBadge: 'distinct',
          innerList: []
        },
        {
          labelTextTwoBadge: 'group',
          innerList: []
        }
      ]
    }
    let market_job_obj = {
      labelTextOneOneBadge: 'job-profiles',
      innerLabelBadgeList: [
        {
          labelTextTwoBadge: 'distinct',
          innerList: []
        },
        {
          labelTextTwoBadge: 'group',
          innerList: []
        }
      ]
    }
    frameworkList[6].labelTextOneOneBadges.splice(2, 0, market_cul_obj);
    frameworkList[6].labelTextOneOneBadges.splice(3, 0, market_job_obj);
  }
  if (
    (responseObject?.informationEngagement?.assignmentStatus === 'ADMINISTERED') && isOccupationType === 'iGuru Analytics_Occupation') {
    if (responseObject?.informationEngagement?.assignmentStatus === 'ADMINISTERED') {
      let refAssignmentObj = {
        id: 'a31',
        labelTextOneOne: 'assignments',
        isListCard: true,
        isReviewLink: true,
        labelTextOneOneBadgeOne: '',
        labelTextOneOneBadgeTwo: '',
        labelTextOneOneBadges: [
          {
            labelTextOneOneBadge: 'distinct',
            innerList: []
          },
          {
            labelTextOneOneBadge: 'group',
            innerList: []
          }
        ],
        innerAssociateList: [],
        innerInfo: 'No Information',
        IconOne: null
      }
      frameworkList.splice(3, 0, refAssignmentObj);
    }
    // let analytic_Obj_mine = {
    //   id: 'a68',
    //   labelTextOneOne: 'iGuru mine',
    //   labelTextOneOneBadges: [
    //     {
    //       labelTextOneOneBadge: 'assessees',
    //       innerLabelBadgeList: [
    //         {
    //           labelTextTwoBadge: 'certificate',
    //           innerList: []
    //         },
    //         {
    //           labelTextTwoBadge: 'distinct',
    //           innerList: []
    //         },
    //         {
    //           labelTextTwoBadge: 'group',
    //           innerList: []
    //         }
    //       ]
    //     }
    //   ],
    //   innerInfo: 'No Information',
    //   isListCard: true,
    //   isReviewLink: false,
    //   isMultiList: true
    // }


    let analytic_Obj_mine = {
      id: 'mine_gg',
      labelTextOneOne: 'iGuru mine',
      labelTextOneOneBadges: [
        {
          labelTextTwoBadge: 'assessees',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'certificate',
              innerLabelBadgeList: [
                {
                  labelTextTwoBadge: 'code',
                  innerLabelBadgeList: []
                },
                {
                  labelTextTwoBadge: 'link',
                  innerLabelBadgeList: []
                },
              ]
            },
            {
              labelTextTwoBadge: 'distinct',
              innerList: []
            },
            {
              labelTextTwoBadge: 'group',
              innerList: []
            }
          ]
        }
      ],
      innerInfo: 'No Information',
      isListCard: false,
      isReviewLink: false,
      // isMultiList: false,
      isMultiInfoCard: true,
    }

    frameworkList.splice(8, 0, analytic_Obj_mine);
  }
  const onclickReviewFramework = (e, badge) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    const innerSelectedBadgeName = e.currentTarget.getAttribute("id");
    console.log('labelName', labelName);
    console.log('selectedBadgeName', selectedBadgeName);
    console.log('innerSelectedBadgeName', innerSelectedBadgeName);
    if (labelName === 'assessees' && selectedBadgeName === 'distinct') {
      closeRelatedList(
        dispatch,
        createMode,
        responseObject,
        middlePaneHeaderDuplicate,
        middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderDuplicateBadgeTwo,
        middlePaneHeaderDuplicateBadgeThree,
        middlePaneHeaderDuplicateBadgeFour,
        previousMiddlePaneState,
        duplicateScanCount,
        'assignmentsDistinct', //'assignmentsDistinctinactive',
        duplicateScanCount,
        middlePaneHeaderDuplicateOne,
        middlePaneHeaderDuplicateOneBadgeOne,
        middlePaneHeaderDuplicateOneBadgeTwo,
        middlePaneHeaderDuplicateOneBadgeThree,
        middlePaneHeaderDuplicateOneBadgeFour
      );
      dispatch({ type: RELATED_REVIEWLIST_DISTINCT_DATA, payload: [] });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: {
          stateName: 'typeOfMiddlePaneList',
          value: ''
        }
      });
      getAssignmnetAssesseeDistinctApiCall(
        selectedAssociateInfo,
        '',
        countPage,
        dispatch,
        'distinct',
        responseObject.id,
        '',
        false,
        'all',
        true
      );
      dispatch({
        type: FILTERMODE,
        payload: {
          FilterMode: 'assignmentDistinctAssesseeDistinct' + 'all'
        }
      });
    }

    if (labelName === 'assessments' && selectedBadgeName === 'distinct') {
      closeRelatedList(
        dispatch,
        createMode,
        responseObject,
        middlePaneHeaderDuplicate,
        middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderDuplicateBadgeTwo,
        middlePaneHeaderDuplicateBadgeThree,
        middlePaneHeaderDuplicateBadgeFour,
        previousMiddlePaneState,
        duplicateScanCount,
        'assignmentsDistinct', //'assignmentsDistinctinactive',
        duplicateScanCount,
        middlePaneHeaderDuplicateOne,
        middlePaneHeaderDuplicateOneBadgeOne,
        middlePaneHeaderDuplicateOneBadgeTwo,
        middlePaneHeaderDuplicateOneBadgeThree,
        middlePaneHeaderDuplicateOneBadgeFour
      );
      dispatch({ type: RELATED_REVIEWLIST_DISTINCT_DATA, payload: [] });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: {
          stateName: 'typeOfMiddlePaneList',
          value: ''
        }
      });
      getAssignmnetAssessmentDistinctApiCall(
        selectedAssociateInfo,
        'active',
        countPage,
        dispatch,
        'distinct',
        responseObject.id,
        '',
        false
      );
      dispatch({
        type: FILTERMODE,
        payload: { FilterMode: 'assignmentDistinctAssessment' + 'all' }
      });
    }
    if (labelName === 'iGuru analytics' && selectedBadgeName === 'culture-profiles' && innerSelectedBadgeName === 'distinct') {
      closeRelatedList(
        dispatch,
        createMode,
        responseObject,
        middlePaneHeaderDuplicate,
        middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderDuplicateBadgeTwo,
        middlePaneHeaderDuplicateBadgeThree,
        middlePaneHeaderDuplicateBadgeFour,
        previousMiddlePaneState,
        duplicateScanCount,
        'assignmentsDistinct', //'assignmentsDistinctinactive',
        duplicateScanCount,
        middlePaneHeaderDuplicateOne,
        middlePaneHeaderDuplicateOneBadgeOne,
        middlePaneHeaderDuplicateOneBadgeTwo,
        middlePaneHeaderDuplicateOneBadgeThree,
        middlePaneHeaderDuplicateOneBadgeFour
      );
      dispatch({ type: RELATED_REVIEWLIST_DISTINCT_DATA, payload: [] });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: {
          stateName: 'typeOfMiddlePaneList',
          value: ''
        }
      });
      dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
      getAssignmentRelatedCultureProfile(
        dispatch,
        selectedAssociateInfo,
        responseObject,
        countPage
      );
    }
    if (labelName === 'iGuru analytics' && selectedBadgeName === 'job-profiles' && innerSelectedBadgeName === 'distinct') {
      closeRelatedList(
        dispatch,
        createMode,
        responseObject,
        middlePaneHeaderDuplicate,
        middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderDuplicateBadgeTwo,
        middlePaneHeaderDuplicateBadgeThree,
        middlePaneHeaderDuplicateBadgeFour,
        previousMiddlePaneState,
        duplicateScanCount,
        'assignmentsDistinct', //'assignmentsDistinctinactive',
        duplicateScanCount,
        middlePaneHeaderDuplicateOne,
        middlePaneHeaderDuplicateOneBadgeOne,
        middlePaneHeaderDuplicateOneBadgeTwo,
        middlePaneHeaderDuplicateOneBadgeThree,
        middlePaneHeaderDuplicateOneBadgeFour
      );
      dispatch({ type: RELATED_REVIEWLIST_DISTINCT_DATA, payload: [] });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: {
          stateName: 'typeOfMiddlePaneList',
          value: ''
        }
      });
      dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
      getAssignmentRelatedJobProfile(dispatch, selectedAssociateInfo, responseObject, countPage);
    }
  };
  const onClickReviseIcon = (e, badge) => {
    if (typeof e === 'object') {
      const headerOne = e.currentTarget.getAttribute('data-value');
      const badgeOne = e.currentTarget.getAttribute('data-key');
      if (headerOne === 'preview') {
        // dispatch({ type: SET_PANE_THREE_ASSESSMENT_PREVIEW_MODE, payload: true });
        // dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneFive' });
      }
    }
    if (e === 'qrcodescaner') {
      dispatch({
        type: ASSESSEE_SIGN_ON,
        payload: { isPopUpValue: 'PopUpQRScanner_environment', popupMode: 'ASSESSEE_CREATE', popupHeaderOne: headerOne }
      });
    }

    if (reviewMode === 'review') {
      if (e === 'communiqué' && badge !== '') {
        dispatch({
          type: SET_PANE_THREE_PREVIEW_MODE,
          payload: {
            isPreviewShow: true,
            previewHeaderOne: 'assignment',
            previewHeaderOneBadgeOne: 'communiqué',
            previewHeaderOneBadgeTwo: badge,
            previewHeaderOneBadgeThree: '',
            previewInnerHTML: informationFramework?.assignmentCommunique[badge - 1] || ''
          }
        });
        dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
      }
      if (e === 'synopsis' && badge !== '') {
        dispatch({
          type: SET_PANE_THREE_PREVIEW_MODE,
          payload: {
            isPreviewShow: true,
            previewHeaderOne: 'assignment',
            previewHeaderOneBadgeOne: 'synopsis',
            previewHeaderOneBadgeTwo: badge,
            previewHeaderOneBadgeThree: '',
            previewInnerHTML: informationFramework?.assignmentSynopsis[badge - 1] || ''
          }
        });
        dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
      }
    }
    if (reviewMode === 'revise') {
      if (e === 'communiqué' && badge === '') {
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'indexPointer', value: 0 }
        });
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: 'COMMUNIQUE_TEXTSHEET_POPUP',
            popupMode: 'ASSIGNMENTCREATE'
          }
        });
      }
      if (e === 'synopsis' && badge === '') {
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'indexPointer', value: 0 }
        });
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: 'SYNOPSIS_TEXTSHEET_POPUP',
            popupMode: 'ASSIGNMENTCREATE'
          }
        });
      }
    }
  };

  const onclickReviseFramework = (e, selectedBadgeArray = []) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    const innerSelectedBadgeName = e.currentTarget.getAttribute("id");
    console.log('labelName', labelName);
    console.log('selectedBadgeName', selectedBadgeName);
    console.log('innerSelectedBadgeName', innerSelectedBadgeName, selectedBadgeArray)

    let badgeName = '';
    if (selectedBadgeArray?.length > 0) {
      selectedBadgeArray.forEach((element) => {
        badgeName = badgeName + element.labelTextTwoBadge;
      });
    }
    console.log(badgeName, labelName, selectedBadgeName, innerSelectedBadgeName)

    if (badgeName === 'assesseescertificatecode' && labelName === 'iGuru mine') {
      dispatch({
        type: ASSESSEE_SIGN_ON,
        payload: { isPopUpValue: 'PopUpQRScanner_environment', popupMode: 'ASSESSEE_CREATE', popupHeaderOne: headerOne }
      });
    }
    if (badgeName === 'assesseescertificatelink' && labelName === 'iGuru mine') {
      dispatch({
        type: ASSESSEE_SIGN_ON,
        payload: { isPopUpValue: 'PopUpQRScanner_link', popupMode: 'ASSESSEE_CREATE', popupHeaderOne: headerOne }
      });
    }

    if (labelName === 'communiqué' && selectedBadgeName !== '') {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'indexPointer', value: parseInt(selectedBadgeName) }
      });
      dispatch({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'COMMUNIQUE_TEXTSHEET_POPUP',
          popupMode: 'ASSIGNMENTCREATE'
        }
      });
    }
    if (labelName === 'synopsis' && selectedBadgeName !== '') {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'indexPointer', value: parseInt(selectedBadgeName) }
      });
      dispatch({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'SYNOPSIS_TEXTSHEET_POPUP',
          popupMode: 'ASSIGNMENTCREATE'
        }
      });
    }
    if (labelName === 'iGuru mine' && badgeName === 'assessees' && selectedBadgeName === 'distinct') {
      dispatch({ type: LOADER_START });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'relatedReviewListDistinctData', value: [] }
      });
      let mineAssessees = informationFramework?.assignmentAssesseeMineDistinct || [];
      let existingAssesseeId = mineAssessees.map((ele) => {
        return ele?.assesseeId;
      });
      dispatch({
        type: ASSESSEE_MINE_ASSIGNMENT_LIST,
        payload: {
          request: {
            module: 'Assignment-Distinct',
            action: 'reviewList',
            actionFilter: 'mineAssessees',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              assignmentId: responseObject.id,
              countPage: countPage,
              numberPage: 0
            }
          },
          existingAssesseeId: existingAssesseeId
        }
      });
    }
    if (labelName === 'iGuru mine' && badgeName === 'assessees' && selectedBadgeName === 'group') {
      dispatch({ type: LOADER_START });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'relatedReviewListDistinctData', value: [] }
      });
      let mineAssessees = informationFramework?.assignmentAssesseeMineDistinct || [];
      let existingAssesseeId = mineAssessees.map((ele) => {
        return ele.assesseeId;
      });
      dispatch({
        type: ASSESSEE_MINE_ASSIGNMENT_GROUP_LIST,
        payload: {
          request: {
            module: 'Assessee-Group',
            action: 'Review-List',
            actionFilter: 'Mine-AssesseeGroup',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              // assesseeId: "625d381e56904407696f6d38",
              // associateId: "624299c48a65d80f4fd08f1d",
              filterTypeName: "",
              status: "ACTIVE",
              countPage: countPage,
              numberPage: 0
            }
          },
          // existingAssesseeId: existingAssesseeId
        }
      });
    }
    if (labelName === 'assignments' && selectedBadgeName === 'distinct') {
      let requestObject = makeAssignmentReferenceListReqObj(
        selectedAssociateInfo,
        responseObject.id,
        countPage
      );
      let reviseResObject = {
        id: responseObject.id,
        assignmentId: responseObject.id,
        assignmentName: responseObject.informationBasic.assignmentName,
        assignmentDescription: responseObject.informationBasic.assignmentDescription,
        assignmentStatus: responseObject.informationEngagement.assignmentStatus
      };
      dispatch({
        type: FILTERMODE,
        payload: { FilterMode: 'assignmentDistinctAssignmentReferenceRevise' }
      });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'relatedReviewListDistinctData', value: [] }
      });
      dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
      dispatch({ type: LOADER_START });
      dispatch({
        type: GET_ASSIGNMENT_REFERENCE_LIST_SAGA,
        payload: {
          existingRefId: responseObject?.assignmentDistinctEvaluationRefId,
          request: requestObject,
          revisedGroupObject: reviseResObject,
          typeOfMiddlePaneList: 'assignmentDistinctAssignmentReference',
          responseKey: 'assignment',
          headerOne: 'assignment'
        }
      });
    }

    if (labelName === 'assessees' && selectedBadgeName === 'distinct') {
      let requestObect = makeAssesseeReviewListRequestObject(
        selectedAssociateInfo,
        'confirmed',
        0,
        countPage
      );
      // if (
      //   responseObject?.assignmentDistinctEvaluationRefId !== null &&
      //   responseObject?.assignmentDistinctEvaluationRefId?.length > 0
      // ) {
      //   requestObect = {
      //     module: 'Iguru-Mine',
      //     action: 'Assignment-Ref-Assessee',
      //     requestObject: {
      //       assesseeId: selectedAssociateInfo?.assesseeId,
      //       associateId:
      //         selectedAssociateInfo?.associate?.informationEngagement.associateTag
      //           .associateTagPrimary,
      //       assignmentId: responseObject?.assignmentDistinctEvaluationRefId[0]
      //     }
      //   };
      // }
      let revisedGroupObject = {
        id: responseObject.id,
        assignmentName: responseObject.informationBasic.assignmentName,
        assignmentDescription: responseObject.informationBasic.assignmentDescription,
        assignmentStatus: responseObject.informationEngagement.assignmentStatus
      };
      let existingAssesseeId = informationFramework?.assignmentAssesseeDistinct || [];
      dispatch({
        type: FILTERMODE,
        payload: { FilterMode: 'assignmentDistinctAssesseeRevise' }
      });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'relatedReviewListDistinctData', value: [] }
      });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'relatedReviewListObjectCopy', value: requestObect }
      });
      dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
      dispatch({ type: LOADER_START });
      // if (
      //   responseObject?.assignmentDistinctEvaluationRefId !== null &&
      //   responseObject?.assignmentDistinctEvaluationRefId.length > 0
      // ) {
      //   dispatch({
      //     type: GET_ASSIGNMENT_REFERENCE_LIST_SAGA,
      //     payload: {
      //       request: requestObect,
      //       revisedGroupObject: revisedGroupObject,
      //       existingRefId: existingAssesseeId,
      //       typeOfMiddlePaneList: 'assignmentDistinctAssesseeReviewList',
      //       responseKey: 'assessee',
      //       headerOne: 'assessees'
      //     }
      //   });
      // } else {
      dispatch({
        type: GET_ALLOCATE_ASSESSEE,
        payload: {
          request: requestObect,
          revisedGroupObject: revisedGroupObject,
          existingAssesseeId: existingAssesseeId,
          typeOfMiddlePaneList: 'assignmentDistinctAssesseeReviewList',
          BadgeTwo: ''
        }
      });
      // }
      dispatch({ type: SET_PAGE_COUNT, payload: 0 });
    }
    if (labelName === 'assessees' && selectedBadgeName === 'group') {
      let requestObect = makeAssesseeGroupObj(
        selectedAssociateInfo,
        'active',
        -1,
        -1,
        'list',
        // assignmentCreateType
        ''
      );

      let revisedGroupObject = {
        id: responseObject.id,
        assignmentName: responseObject.informationBasic.assignmentName,
        assignmentDescription: responseObject.informationBasic.assignmentDescription,
        assignmentStatus: responseObject.informationEngagement.assignmentStatus
      };
      let existingAssesseeId = [];
      dispatch({
        type: FILTERMODE,
        payload: { FilterMode: 'assignmentDistinctAssesseeGroupRevise' }
      });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'relatedReviewListDistinctData', value: [] }
      });
      dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
      dispatch({ type: LOADER_START });
      dispatch({
        type: GET_ALLOCATE_ASSESSEE_GROUP,
        payload: {
          request: requestObect,
          revisedGroupObject: revisedGroupObject,
          existingAssesseeId: existingAssesseeId,
          typeOfMiddlePaneList: 'assignmentDistinctAssesseeGroupReviewList'
        }
      });
    }
    if (labelName === 'assessments' && selectedBadgeName === 'distinct') {
      // if you do want to click on the badge, when some doncdion is true and false chekc the blcok. take any key from the response objct
      console.log('responseObjectGroup', responseObject);
      if (!responseObject?.assignmentDistinctEvaluationRefId?.length > 0) {
        if (isNotRevised) {
          if (!assignmentRelatedReviewListPaneThree.assessment) {
            getAssignmentRelatedAssessment(
              dispatch,
              selectedAssociateInfo,
              responseObject,
              countPage
            );
          }
          dispatch({
            type: SET_POPUP_VALUE,
            payload: { isPopUpValue: 'ASSESSMENT_POPUP', popupMode: 'ASSIGNMENTCREATE' }
          });
        } else {
          let requestObect = makeAssessmentReviewListRequestObject(
            selectedAssociateInfo,
            'active',
            countPage,
            0,
            ''
          );
          let revisedGroupObject = {
            id: responseObject.id,
            assignmentName: responseObject.informationBasic.assignmentName,
            assignmentDescription: responseObject.informationBasic.assignmentDescription,
            assignmentStatus: responseObject.informationEngagement.assignmentStatus
          };
          // let existingAssesseeId = informationFramework?.assignmentAssessment || [];
          let existingAssessmentId =
            informationFramework?.assignmentAssessmentDistinct &&
            informationFramework?.assignmentAssessmentDistinct.map((ob) => ob.assessmentId);

          console.log('existingAssessmentId', existingAssessmentId);
          dispatch({
            type: FILTERMODE,
            payload: { FilterMode: 'assignmentDistinctAssessmentRevise' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'relatedReviewListDistinctData', value: [] }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'relatedReviewListObjectCopy', value: requestObect }
          });

          dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
          dispatch({ type: LOADER_START });
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: GET_ALLOCATE_ASSESSMENT,
            payload: {
              request: requestObect,
              revisedGroupObject: revisedGroupObject,
              existingAssessmentId: existingAssessmentId,
              typeOfMiddlePaneList: 'assignmentDistinctAssessmentReviewList'
            }
          });
        }
      }
    }
    if (labelName === 'assessments' && selectedBadgeName === 'group') {
      console.log('responseObject-', responseObject);
      // if you do want to click on the badge, when some doncdion is true and false chekc the blcok. take any key from the response objct
      if (!responseObject?.assignmentDistinctEvaluationRefId?.length > 0) {
        if (isNotRevised) {
          dispatch({
            type: SET_POPUP_VALUE,
            payload: { isPopUpValue: 'ASSESSMENT_GROUP_POPUP', popupMode: 'ASSIGNMENTCREATE' }
          });
        } else {
          let requestObect = makeAssessmentGroupObj(
            selectedAssociateInfo,
            'active',
            -1,
            -1,
            'list',
            // assignmentCreateType
            ''
          );
          let revisedGroupObject = {
            id: responseObject.id,
            assignmentName: responseObject.informationBasic.assignmentName,
            assignmentDescription: responseObject.informationBasic.assignmentDescription,
            assignmentStatus: responseObject.informationEngagement.assignmentStatus
          };
          let existingAssessmentId = [];
          dispatch({
            type: FILTERMODE,
            payload: { FilterMode: 'assignmentDistinctAssessmentGroupRevise' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'relatedReviewListDistinctData', value: [] }
          });
          dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
          dispatch({ type: LOADER_START });
          dispatch({
            type: GET_ALLOCATE_ASSESSMENT_GROUP,
            payload: {
              request: requestObect,
              revisedGroupObject: revisedGroupObject,
              existingAssessmentId: existingAssessmentId,
              typeOfMiddlePaneList: 'assignmentDistinctAssessmentGroupReviewList'
            }
          });
        }
      }
    }
    if (labelName === 'iGuru analytics' && selectedBadgeName === 'culture-profiles' && innerSelectedBadgeName === 'distinct') {
      if (isNotRevised) {
        if (!assignmentRelatedReviewListPaneThree.cultureProfile) {
          getAssignmentRelatedCultureProfile(
            dispatch,
            selectedAssociateInfo,
            responseObject,
            countPage,
            false
          );
        }
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'CULTUREPROFILE_POPUP', popupMode: 'ASSIGNMENTCREATE' }
        });
      } else {
        let requestObect = makeCultureProfileObj(selectedAssociateInfo, 'active', 0, countPage);
        let revisedGroupObject = {
          id: responseObject.id,
          assignmentName: responseObject.informationBasic.assignmentName,
          assignmentDescription: responseObject.informationBasic.assignmentDescription,
          assignmentStatus: responseObject.informationEngagement.assignmentStatus
        };
        let existingCultureProfileId = informationFramework?.assignmentCultureProfileDistinct || [];
        // let tempArr = relatedReviewListPaneThree[0]?.cultureProfile || [];
        // existingCultureProfileId = tempArr.map((val) => {
        //   return val.id;
        // });
        dispatch({
          type: FILTERMODE,
          payload: { FilterMode: 'assignmentDistinctCultureProfileRevise' }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
        dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
        dispatch({ type: LOADER_START });
        dispatch({
          type: GET_ALLOCATE_CULTURE,
          payload: {
            request: requestObect,
            revisedGroupObject: revisedGroupObject,
            existingCultureProfileId: existingCultureProfileId,
            typeOfMiddlePaneList: 'assignmentDistinctCultureProfileReviewList'
          }
        });
      }
    }
    if (labelName === 'iGuru analytics' && selectedBadgeName === 'culture-profiles' && innerSelectedBadgeName === 'group') {
      if (isNotRevised) {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'CULTURE_GROUP_POPUP', popupMode: 'ASSIGNMENTCREATE' }
        });
      } else {
        let requestObect = makeCultureProfileGroupObj(
          selectedAssociateInfo,
          'active',
          -1,
          -1,
          'list',
          // assignmentCreateType
          ''
        );
        let revisedGroupObject = {
          id: responseObject.id,
          assignmentName: responseObject.informationBasic.assignmentName,
          assignmentDescription: responseObject.informationBasic.assignmentDescription,
          assignmentStatus: responseObject.informationEngagement.assignmentStatus
        };
        let existingCultureProfileId = [];
        dispatch({
          type: FILTERMODE,
          payload: { FilterMode: 'assignmentDistinctCultureProfileGroupRevise' }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
        dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
        dispatch({ type: LOADER_START });
        dispatch({
          type: GET_ALLOCATE_CULTURE_GROUP,
          payload: {
            request: requestObect,
            revisedGroupObject: revisedGroupObject,
            existingCultureProfileId: existingCultureProfileId,
            typeOfMiddlePaneList: 'assignmentDistinctCultureProfileGroupReviewList'
          }
        });
      }
    }
    if (labelName === 'iGuru analytics' && selectedBadgeName === 'job-profiles' && innerSelectedBadgeName === 'distinct') {
      if (isNotRevised) {
        if (!assignmentRelatedReviewListPaneThree.jobProfile) {
          getAssignmentRelatedJobProfile(
            dispatch,
            selectedAssociateInfo,
            responseObject,
            countPage,
            false
          );
        }
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'JOBPROFILE_POPUP', popupMode: 'ASSIGNMENTCREATE' }
        });
      } else {
        let requestObect = makeJobProfileObj(selectedAssociateInfo, 'active', 0, countPage);
        let revisedGroupObject = {
          id: responseObject.id,
          assignmentName: responseObject.informationBasic.assignmentName,
          assignmentDescription: responseObject.informationBasic.assignmentDescription,
          assignmentStatus: responseObject.informationEngagement.assignmentStatus
        };
        let existingJobProfileId = informationFramework?.assignmentJobProfileDistinct || [];
        // let tempArr = relatedReviewListPaneThree[0]?.cultureProfile || [];
        // existingJobProfileId = tempArr.map((val) => {
        //   return val.id;
        // });
        dispatch({
          type: FILTERMODE,
          payload: { FilterMode: 'assignmentDistinctJobProfileRevise' }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
        dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
        dispatch({ type: LOADER_START });
        dispatch({
          type: GET_ALLOCATE_JOB,
          payload: {
            request: requestObect,
            revisedGroupObject: revisedGroupObject,
            existingJobProfileId: existingJobProfileId,
            typeOfMiddlePaneList: 'assignmentDistinctJobProfileReviewList'
          }
        });
      }
    }
    if (labelName === 'iGuru analytics' && selectedBadgeName === 'job-profiles' && innerSelectedBadgeName === 'group') {
      if (isNotRevised) {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'JOB_GROUP_POPUP', popupMode: 'ASSIGNMENTCREATE' }
        });
      } else {
        let requestObect = makeJobProfileGroupObj(
          selectedAssociateInfo,
          'active',
          -1,
          -1,
          'list',
          // assignmentCreateType
          ''
        );
        let revisedGroupObject = {
          id: responseObject.id,
          assignmentName: responseObject.informationBasic.assignmentName,
          assignmentDescription: responseObject.informationBasic.assignmentDescription,
          assignmentStatus: responseObject.informationEngagement.assignmentStatus
        };
        let existingJobProfileId = [];
        // let tempArr = relatedReviewListPaneThree[0]?.cultureProfile || [];
        // existingJobProfileId = tempArr.map((val) => {
        //   return val.id;
        // });
        dispatch({
          type: FILTERMODE,
          payload: { FilterMode: 'assignmentDistinctJobProfileGroupRevise' }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
        dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
        dispatch({ type: LOADER_START });
        dispatch({
          type: GET_ALLOCATE_JOB_GROUP,
          payload: {
            request: requestObect,
            revisedGroupObject: revisedGroupObject,
            existingJobProfileId: existingJobProfileId,
            typeOfMiddlePaneList: 'assignmentDistinctJobProfileGroupReviewList'
          }
        });
      }
    }
    if (labelName === 'administration' && selectedBadgeName === 'proctor') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ADMINPROCTORPOPUP', popupMode: 'ASSIGNMENTCREATE' }
      });
    }
    if (labelName === 'administration' && selectedBadgeName === 'proctor') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ADMINPROCTORPOPUP', popupMode: 'ASSIGNMENTCREATE' }
      });
    }
    if (labelName === 'administration' && selectedBadgeName === 'repeat') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ADMINREPEATPOPUP', popupMode: 'ASSIGNMENTCREATE' }
      });
    }
    if (labelName === 'administration' && selectedBadgeName === 'reset') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ADMINRESETPOPUP', popupMode: 'ASSIGNMENTCREATE' }
      });
    }
    if (labelName === 'administration' && selectedBadgeName === 'shuffle') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ADMINSHUFFLEPOPUP', popupMode: 'ASSIGNMENTCREATE' }
      });
    }
    if (labelName === 'administration' && selectedBadgeName === 'supervise') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ADMINSUPERVISEPOPUP', popupMode: 'ASSIGNMENTCREATE' }
      });
    }
    if (labelName === 'administration' && selectedBadgeName === 'alignment') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ASSESSMENTMEDIA', popupMode: 'ASSIGNMENTCREATE' }
      });
    }
    if (labelName === 'administration' && selectedBadgeName === 'media') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'TEMPLATEPOPUP', popupMode: 'ASSIGNMENTCREATE' }
      });
    }
    if (labelName === 'administration' && selectedBadgeName === 'sequence') {
      dispatch({
        type: SET_ASSIGNMENT_SINGLE_STATE,
        payload: { stateName: 'assignmentAdminSequence', value: adminSequenseArr }
      });
      setTimeout(() => {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'ADMINSEQUENCEPOPUP', popupMode: 'ASSIGNMENTCREATE' }
        });
      }, 10);
    }
    if (labelName === 'items' && selectedBadgeName === 'template') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'TEMPLATEPOPUP', popupMode: 'ASSIGNMENTCREATE' }
      });
    }
    if (labelName === 'gauge' && selectedBadgeName === 'distinct') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'GAUGEPOPUP', popupMode: 'ASSIGNMENTCREATE' }
      });
    }
    if (labelName === 'timeline' && selectedBadgeName === 'start') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'TIMELINE_STAR_TPOPUP', popupMode: 'ASSIGNMENTCREATE' }
      });
    }
    if (labelName === 'timeline' && selectedBadgeName === 'end') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'TIMELINE_END_TPOPUP', popupMode: 'ASSIGNMENTCREATE' }
      });
    }
    // if (labelName === 'iGuru mine' && selectedBadgeName === 'assessees' && innerSelectedBadgeName === 'certificate') {
    //   dispatch({
    //     type: ASSESSEE_SIGN_ON,
    //     payload: { isPopUpValue: 'PopUpQRScanner_environment', popupMode: 'ASSESSEE_CREATE', popupHeaderOne: headerOne }
    //   });
    // }
  };

  const getRelatedReviewList = (labelTextOneOne, labelTextOneOneBadge) => {
    console.log('Selected tag Object', labelTextOneOne, labelTextOneOneBadge);
    if (labelTextOneOne === 'assessees' && labelTextOneOneBadge === 'distinct') {
      if (!assignmentRelatedReviewListPaneThree.assessee) {
        
        getAssignmnetAssesseeDistinctApiCall(
          selectedAssociateInfo,
          'all',
          countPage,
          dispatch,
          'distinct',
          responseObject.id,
          '',
          false,
          'all',
          false,
          'displayPaneThree'
        );
      }
    }
    if (labelTextOneOne === 'assessments' && labelTextOneOneBadge === 'distinct') {
      if (!assignmentRelatedReviewListPaneThree.assessment) {
        
        getAssignmentRelatedAssessment(dispatch, selectedAssociateInfo, responseObject, countPage);
      }
    }
    if (labelTextOneOne === 'culture-profiles' && labelTextOneOneBadge === 'distinct') {
      if (!assignmentRelatedReviewListPaneThree.cultureProfile) {
        
        getAssignmentRelatedCultureProfile(
          dispatch,
          selectedAssociateInfo,
          responseObject,
          countPage,
          false
        );
      }
    }
    if (labelTextOneOne === 'job-profiles' && labelTextOneOneBadge === 'distinct') {
      if (!assignmentRelatedReviewListPaneThree.jobProfile) {
        
        getAssignmentRelatedJobProfile(
          dispatch,
          selectedAssociateInfo,
          responseObject,
          countPage,
          false
        );
      }
    }
  };

  return (
    <div>
      {headerOneBadgeThree === 'all' ? (
        <>
          <div className="containerPadding">
            <AllocationAccordian
              headerOne="framework"
              isDisplayCardExpanded={listExpand === 'framework'}
              setListExpand={setListExpand}
              list={frameworkList}
              mode={reviewMode}
              onClickRevise={onclickReviseFramework}
              onClickReview={onclickReviewFramework}
              getReviewList={getRelatedReviewList}
              onClickReviseIcon={onClickReviseIcon}
            />
          </div>
        </>
      ) : (
        <>
          <div className="containerPadding">
            <Paper className={'dossierContainerTop'}>
              {frameworkList.map((ob) => {
                return (
                  <div key={ob.id}>
                    {ob.isListCard ? (
                      <DisplayPanelAccordianReviewListOne
                        onClickRevise={onclickReviseFramework}
                        className=""
                        accordianObject={ob}
                        mode={reviewMode}
                        getReviewList={getRelatedReviewList}
                        onClickReviseIcon={onClickReviseIcon}
                      />
                    ) : (

                      <DisplayPanelAccordianInformation
                        onClickRevise={onclickReviseFramework}
                        accordianObject={ob}
                        mode={reviewMode}
                        getReviewList={getRelatedReviewList}
                        onClickReviseIcon={onClickReviseIcon}
                      />
                    )}
                  </div>
                );
              })}
            </Paper>
          </div>
        </>
      )}
      {isMobile && (
        <div className={'containerPadding'} style={{ height: '55px' }}>
          {' '}
        </div>
      )}
    </div>
  );
};

export default DisplayPaneThreeSectionTwoAssignment;