import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PopupHeader from '../Molecules/PopUp/PopUpHeader';
import Popup from '../Molecules/PopUp/PopUp';
import '../Molecules/PopUp/PopUp.css';
import { DialogContent } from '@material-ui/core';
import JsonRenderComponent from '../Actions/JsonRenderComponent';
import {
  GET_ASSESSEE_ASSIGNMENT_SAGA,
  POPUP_CLOSE,
  SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_SECONDARY_CREATE_OPTION_VALUE,
  LOADER_START,
  SET_ASSESSEE_ASSESSMENT_ITEM_RES_SAGA,
  ASSESSEE_ASSESSMENT_FINISH_SAGA,
  SET_POPUP_STATE,
  CLEAR_PANE_TWO_REDUCER,
  RESET_ALL_REDUCER,
  POPUP_OPEN,
  SET_MOBILE_PANE_STATE,
  CLEAR_ASSESSEE_ASSESSMENT_REDUCER_STATE,
  SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
  SET_MIDDLEPANE_SECONDARY_OPTION,
  SET_MIDDLEPANE_STATE,
  SET_PANE_THREE_ASSESSMENT_SECTION_PREVIEW_MODE,
  SET_PANE_THREE_ASSESSMENT_PREVIEW_MODE,
  SET_PANE_THREE_ASSESSMENT_VERSION_PREVIEW_MODE,
  CLEAR_DISPLAY_PANE_THREE,
  SET_POPUP_SINGLE_STATE
} from '../actionType';
import { SIGN_IN_URL } from '../endpoints';
import { useHistory } from 'react-router-dom';
import { assesseeAssessmentFinihed } from '../Actions/ActionAssesseeAssessment';
import {
  callApiFunctionLastAttempted,
  getAssesseeSelfAssignmentList,
  setItemTypeConfigState
} from '../Actions/GenericActions';
const PopUpDisplayPanelAssesseeAssessment = (props) => {
  const {
    popupHeaderOne,
    popupHeaderOneBadgeOne,
    popupHeaderOneBadgeTwo,
    popupHeaderOneBadgeThree,
    popupOpenType,
    secondaryOptionCheckValue,
    tertiaryOptionCheckValue = 'all',
    forthOptionCheckValue,
    middlePaneHeaderBadgeOne
  } = useSelector((state) => state.PopUpReducer);
  const { selectedAssociateInfo, relatedReviewListDistinctData, reviewListReqObj } = useSelector(
    (state) => state.DisplayPaneTwoReducer
  );

  const { isAssessmentPreviewShow, isAssessmentVersionShow, isAssessmentSectionShow } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  const {
    assesseeAssignmentAssessmentData,
    assesseeAssignmentAssessmentSectionAttemptedDateTimeStart,
    assessmentsequenceObject,
    currentSequenceIndex,
    isAssessmentStart,
    isItemTrial
  } = useSelector((state) => state.AssesseeAssignmentAssessmentReducer);
  const [isReviseMode, setIsReviseMode] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    headerPanelColour = 'displayPaneCentre',
    isActive,
    popupAllClose,
    typeOfMiddlePaneList
  } = props;

  const setSecondaryOptionValue = (e) => {
    dispatch({
      type: SET_SECONDARY_CREATE_OPTION_VALUE,
      payload: e.currentTarget.getAttribute('data-value')
    });
  };
  const setSecondaryOptionValueTwo = (e) => {};
  const setSecondaryOptionValueThree = (e) => {};
  const ChangeOptionPopup = (e) => {
    let keyVal = e.currentTarget.getAttribute('data-key');
    let dataVal = e.currentTarget.getAttribute('data-value');
    console.log(dataVal);
    if (dataVal === 'review') {
      dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneSeven' });
      dispatch({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'secondaryOptionCheckValue', value: 'all' }
      });
      dispatch({ type: POPUP_OPEN, payload: 'NavigatorPOPUP' });
      dispatch({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'headerButtons', value: false }
      });
    }
    if (dataVal === 'finish') {
      // debugger
      if (popupHeaderOneBadgeOne === 'time-out') {
        dispatch({ type: POPUP_CLOSE });
      } else if (popupHeaderOneBadgeOne === 'section' && popupHeaderOneBadgeTwo === 'time-out') {
        dispatch({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: {
            stateName: 'currentSequenceIndex',
            value: currentSequenceIndex + 1
          }
        });
        dispatch({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: { stateName: 'isAssessmentStart', value: 'STOP' }
        });
        dispatch({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: {
            stateName: 'assesseeAssessmentStartData',
            value: null
          }
        });
        dispatch({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: { stateName: 'sectionSynopsis', value: [] }
        });
        dispatch({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: { stateName: 'sectionCommunique', value: [] }
        });
        dispatch({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: { stateName: 'sectionMenuscript', value: [] }
        });
        dispatch({ type: POPUP_CLOSE });
      } else if (popupHeaderOneBadgeOne === 'section' && popupHeaderOneBadgeTwo === 'end') {
        if (isAssessmentPreviewShow) {
          dispatch({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: { stateName: 'currentSequenceIndex', value: currentSequenceIndex + 1 }
          });
          dispatch({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: { stateName: 'assesseeAssessmentStartData', value: null }
          });
          dispatch({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: { stateName: 'isAssessmentStart', value: 'PREVIEW' }
          });
          dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneSix' });
        } else {
          dispatch({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: {
              stateName: 'currentSequenceIndex',
              value: currentSequenceIndex + 1
            }
          });
          dispatch({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: { stateName: 'isAssessmentStart', value: 'STOP' }
          });
          dispatch({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: {
              stateName: 'assesseeAssessmentStartData',
              value: null
            }
          });
          dispatch({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: {
              stateName: 'questionIndex',
              value: 0
            }
          });
          dispatch({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: { stateName: 'sectionSynopsis', value: [] }
          });
          dispatch({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: { stateName: 'sectionCommunique', value: [] }
          });
          dispatch({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: { stateName: 'sectionMenuscript', value: [] }
          });
        }
        dispatch({ type: POPUP_CLOSE });
      } else {
        if (isAssessmentPreviewShow) {
          dispatch({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: { stateName: 'currentSequenceIndex', value: currentSequenceIndex + 1 }
          });
          dispatch({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: { stateName: 'assesseeAssessmentStartData', value: null }
          });
          dispatch({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: { stateName: 'isAssessmentStart', value: 'PREVIEW' }
          });
          dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneSix' });
          dispatch({ type: POPUP_CLOSE });
        } else {
          let assessmentStatus = popupHeaderOneBadgeOne === 'time-out' ? 'UNFINISHED' : 'FINISHED';
          dispatch({ type: LOADER_START });
          assesseeAssessmentFinihed(
            dispatch,
            selectedAssociateInfo,
            assesseeAssignmentAssessmentData,
            assessmentStatus,
            assesseeAssignmentAssessmentSectionAttemptedDateTimeStart
          );
          dispatch({ type: POPUP_CLOSE });
        }
      }
      dispatch({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'headerButtons', value: true }
      });
      // dispatch({ type: LOADER_START });
      // if (JSON.parse(localStorage.getItem('assessmentItem'))?.length > 0) {
      //   let reqObj = {
      //     assesseeId: selectedAssociateInfo?.assesseeId,
      //     associateId:
      //       selectedAssociateInfo?.associate?.informationEngagement.associateTag
      //         .associateTagPrimary,
      //     assessmentItem: JSON.parse(localStorage.getItem('assessmentItem'))
      //   };
      //   console.log(JSON.parse(localStorage.getItem('assessmentItem')));
      //   dispatch({
      //     type: SET_ASSESSEE_ASSESSMENT_ITEM_RES_SAGA,
      //     payload: { request: reqObj }
      //   });
      // }
      // let attemptEndTime = new Date().getTime();
      // let reqObject = {
      //   assesseeId: selectedAssociateInfo?.assesseeId,
      //   associateId:
      //     selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      //   assignmentId: assesseeAssignmentAssessmentData.assignmentId,
      //   assessmentId: assesseeAssignmentAssessmentData.assessmentId,
      //   assesseeAssignmentAssessmentStatus:
      //     popupHeaderOneBadgeOne === 'time-out' ? 'UNFINISHED' : 'FINISHED',
      //   attemptEndTime: attemptEndTime,
      //   assesseeAssignmentAssessmentAttempted: {
      //     assesseeAssignmentAssessmentAttemptedDateTimeEnd: attemptEndTime,
      //     assesseeAssignmentAssessmentAttemptedDateTimeStart:  assesseeAssignmentAssessmentSectionAttemptedDateTimeStart
      //   }
      // };
      // dispatch({
      //   type: ASSESSEE_ASSESSMENT_FINISH_SAGA,
      //   payload: { request: reqObject }
      // });
    }
    if (dataVal === 'yes' && secondaryOptionCheckValue === 'assignment') {
      dispatch({ type: LOADER_START });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'typeOfMiddlePaneList', value: '' }
      });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'relatedReviewListDistinctData', value: [] }
      });
      dispatch({ type: CLEAR_ASSESSEE_ASSESSMENT_REDUCER_STATE });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'relatedReviewListDistinctData', value: [] }
      });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'reviewListDistinctData', value: [] }
      });
      dispatch({
        type: GET_ASSESSEE_ASSIGNMENT_SAGA,
        payload: {
          request: reviewListReqObj,
          BadgeOne: 'active',
          BadgeTwo: '',
          BadgeThree: '',
          assessmentStarted: true,
          assignmentId: relatedReviewListDistinctData[0].assesseeAssignmentId
        }
      });
      dispatch({ type: POPUP_CLOSE });
      dispatch({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'headerButtons', value: true }
      });
    }
    if (dataVal === 'yes' && secondaryOptionCheckValue === 'sign-out') {
      
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('parentId');
      localStorage.removeItem('assesseeId');
      localStorage.clear();
      dispatch({ type: RESET_ALL_REDUCER });
      dispatch({ type: CLEAR_PANE_TWO_REDUCER });
      dispatch({ type: CLEAR_ASSESSEE_ASSESSMENT_REDUCER_STATE });
      history.push(SIGN_IN_URL);
      // window.location.replace()
    }
    if (dataVal === 'yes' && secondaryOptionCheckValue === 'dashboard') {
      dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneOne' });
      dispatch({ type: SET_MIDDLEPANE_STATE , payload: {
        typeOfMiddlePaneList: '',
        middlePaneHeader: '',
        middlePaneHeaderBadgeOne: '',
        middlePaneHeaderBadgeTwo: '',
        middlePaneHeaderBadgeThree: '',
        middlePaneHeaderBadgeFour: '',
        middlePaneHeaderDuplicate: '',
        middlePaneHeaderDuplicateBadgeOne: '',
        middlePaneHeaderDuplicateBadgeTwo: '',
        middlePaneHeaderDuplicateBadgeThree: '',
        middlePaneHeaderDuplicateBadgeFour: '',
        middlePaneHeaderDuplicateOne: '',
        middlePaneHeaderDuplicateOneBadgeOne: '',
        middlePaneHeaderDuplicateOneBadgeTwo: '',
        middlePaneHeaderDuplicateOneBadgeThree: '',
        middlePaneHeaderDuplicateOneBadgeFour: '',
        scanCount: '',
        duplicateScanCount: '',
        showMiddlePaneState: '',
        middlePaneSelectedValue: '',
        isSelectActive:  '',
        selectedTagsArray: '',
        unselectedTagsArray: '',
        isFlaged: false,
        isListLoading :  false
      }});
      dispatch({ type: CLEAR_ASSESSEE_ASSESSMENT_REDUCER_STATE });
      localStorage.setItem('assessmentItem', '[]');
      localStorage.setItem('navigationItem', '[]');
      dispatch({ type: POPUP_CLOSE });
      dispatch({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'headerButtons', value: true }
      });
    }
    if (dataVal === 'yesApiCall') {
      if (popupHeaderOneBadgeOne === 'trial') {
        if (!isAssessmentPreviewShow) {
          callApiFunctionLastAttempted(
            selectedAssociateInfo,
            assesseeAssignmentAssessmentData,
            dispatch,
            assessmentsequenceObject[currentSequenceIndex]?.originalValue.concat(':', -1)
          );
        }
        dispatch({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: { stateName: 'currentSequenceIndex', value: currentSequenceIndex }
        });
        dispatch({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: {
            stateName: 'assesseeAssessmentStartData',
            value: assessmentsequenceObject[currentSequenceIndex]?.value
          }
        });
        dispatch({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: { stateName: 'isItemTrial', value: true }
        });
        dispatch({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: { stateName: 'isAssessmentStart', value: 'PROGRESS' }
        });
        dispatch({
          type: SET_MOBILE_PANE_STATE,
          payload: isAssessmentPreviewShow ? 'displayPaneFive' : 'displayPaneSeven'
        });
        dispatch({ type: POPUP_CLOSE });
      } else {
        let currentValue = assessmentsequenceObject[currentSequenceIndex]?.originalValue;
        let lastIndexValue = parseInt(currentValue.substring(currentValue.length - 1));
        if (isAssessmentPreviewShow) {
          if (isItemTrial) {
            dispatch({
              type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
              payload: { stateName: 'currentSequenceIndex', value: currentSequenceIndex + 1 }
            });
            dispatch({
              type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
              payload: { stateName: 'assesseeAssessmentStartData', value: null }
            });
            dispatch({
              type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
              payload: { stateName: 'isItemTrial', value: false }
            });
            dispatch({
              type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
              payload: { stateName: 'isAssessmentStart', value: 'PREVIEW' }
            });
            dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneSix' }); //
          } else {
            if (assessmentsequenceObject[currentSequenceIndex].name === 'item distinct') {
              dispatch({
                type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
                payload: {
                  stateName: 'assesseeAssessmentStartData',
                  value:
                    assesseeAssignmentAssessmentData?.informationFramework
                      ?.assessmentSectionFramework[lastIndexValue - 1]
                }
              });
              dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneFive' });
              dispatch({
                type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
                payload: { stateName: 'isAssessmentStart', value: 'PROGRESS' }
              });
            } else {
              dispatch({
                type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
                payload: {
                  stateName: 'assesseeAssessmentStartData',
                  value: null
                }
              });
              dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneSix' });
              dispatch({
                type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
                payload: { stateName: 'isAssessmentStart', value: 'PREVIEW' }
              });
            }
            setItemTypeConfigState(
              assesseeAssignmentAssessmentData?.informationFramework?.assessmentSectionFramework[
                lastIndexValue - 1
              ]?.assessmentVersionFramework[0].assessmentVersionItemDistinct[0].itemFrameworkOne
                .itemFrameworkOneType,
              dispatch
            );
            dispatch({
              type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
              payload: {
                stateName: 'assessmentSectionItemDistinctRevise',
                value:
                  assesseeAssignmentAssessmentData?.informationFramework
                    ?.assessmentSectionFramework[lastIndexValue - 1]?.assessmentVersionFramework[0]
                    .assessmentVersionItemDistinct[0]
              }
            });
          }
        } else {
          dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneFive' });
          dispatch({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: {
              stateName: 'assesseeAssessmentStartData',
              value:
                assesseeAssignmentAssessmentData?.informationFramework?.assessmentSectionFramework[
                  lastIndexValue - 1
                ]
            }
          });
          setItemTypeConfigState(
            assesseeAssignmentAssessmentData?.informationFramework?.assessmentSectionFramework[
              lastIndexValue - 1
            ]?.assessmentVersionFramework[0].assessmentVersionItemDistinct[0].itemFrameworkOne
              .itemFrameworkOneType,
            dispatch
          );
          dispatch({
            type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
            payload: {
              stateName: 'assessmentSectionItemDistinctRevise',
              value:
                assesseeAssignmentAssessmentData?.informationFramework?.assessmentSectionFramework[
                  lastIndexValue - 1
                ]?.assessmentVersionFramework[0].assessmentVersionItemDistinct[0]
            }
          });
          dispatch({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: { stateName: 'isAssessmentStart', value: 'PROGRESS' }
          });
        }
        dispatch({ type: POPUP_CLOSE });
      }
      dispatch({ type: POPUP_CLOSE });
    }
    if (dataVal === 'cancelNoApiCall') {
      dispatch({ type: POPUP_CLOSE });
      dispatch({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'headerButtons', value: true }
      });
    }
    if (dataVal === 'cancelYesApiCall') {
      dispatch({ type: LOADER_START });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'typeOfMiddlePaneList', value: '' }
      });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'relatedReviewListDistinctData', value: [] }
      });
      dispatch({ type: CLEAR_ASSESSEE_ASSESSMENT_REDUCER_STATE });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'relatedReviewListDistinctData', value: [] }
      });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'reviewListDistinctData', value: [] }
      });
      dispatch({
        type: GET_ASSESSEE_ASSIGNMENT_SAGA,
        payload: {
          request: reviewListReqObj,
          BadgeOne: 'active',
          BadgeTwo: '',
          BadgeThree: '',
          assessmentStarted: true,
          assignmentId: relatedReviewListDistinctData[0].assesseeAssignmentId
        }
      });
      dispatch({ type: POPUP_CLOSE });
      dispatch({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'headerButtons', value: true }
      });
    }
    // if (dataVal === 'no' && secondaryOptionCheckValue ==='ass')
    if (dataVal === 'no') {
      if (isAssessmentSectionShow) {
        dispatch({ type: SET_PANE_THREE_ASSESSMENT_SECTION_PREVIEW_MODE, payload: false });
      } else if (isAssessmentPreviewShow) {
        dispatch({ type: SET_PANE_THREE_ASSESSMENT_PREVIEW_MODE, payload: false });
      } else if (isAssessmentVersionShow) {
        dispatch({ type: SET_PANE_THREE_ASSESSMENT_VERSION_PREVIEW_MODE, payload: false });
      } else if (popupHeaderOneBadgeOne === 'trial') {
        dispatch({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: { stateName: 'isAssessmentStart', value: '' }
        });
        dispatch({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: { stateName: 'isExamMode', value: false }
        });
        dispatch({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: 'assessments',
            middlePaneHeaderBadgeOne: 'active',
            middlePaneHeaderBadgeTwo: '',
            middlePaneHeaderBadgeThree: '',
            middlePaneHeaderBadgeFour: '',
            typeOfMiddlePaneList: 'assesseesAssginmentAssessmentReviewList',
            scanCount:
              relatedReviewListDistinctData[0]?.assesseeAssignmentAssessmentDistinct?.length || 0,
            showMiddlePaneState: true
          }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'relatedReviewListDistinctData',
            value: relatedReviewListDistinctData
          }
        });
        dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
        dispatch({ type: POPUP_CLOSE });
      }
      // dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
      // dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
      dispatch({ type: CLEAR_ASSESSEE_ASSESSMENT_REDUCER_STATE });
      dispatch({ type: POPUP_CLOSE });
    } else {
      dispatch({
        type: SET_MIDDLEPANE_SECONDARY_OPTION,
        payload: { badgeValue: dataVal, keyValue: keyVal }
      });
    }
  };
  return (
    <div>
      <Popup isActive={isActive}>
        <PopupHeader
          headerPanelColour={headerPanelColour + popupOpenType}
          headerOne={popupHeaderOne}
          headerOneBadgeOne={popupHeaderOneBadgeOne}
          headerOneBadgeTwo={popupHeaderOneBadgeTwo}
          headerOneBadgeThree={popupHeaderOneBadgeThree}
          //onClick={BackHandlerEvent}
          mode={'cancel'}
        />
        <DialogContent className={['popupContent', 'fixed05PadDim'].join(' ')}>
          <JsonRenderComponent
            setSecondaryOptionValue={setSecondaryOptionValue}
            setSecondaryOptionValueTwo={setSecondaryOptionValueTwo}
            setSecondaryOptionValueThree={setSecondaryOptionValueThree}
            ChangeOptionPopup={ChangeOptionPopup}
            secondaryOptionCheckValue={secondaryOptionCheckValue}
            tertiaryOptionCheckValue={tertiaryOptionCheckValue}
            forthOptionCheckValue={forthOptionCheckValue}
          />
          {/* )} */}
        </DialogContent>
      </Popup>
    </div>
  );
};

export default PopUpDisplayPanelAssesseeAssessment;
