import {
  CLEAR_DISPLAY_PANE_THREE,
  CLEAR_GROUP_REDUCER_STATE,
  CLEAR_JOB_REDUCER_STATE,
  FILTERMODE,
  GET_ALLOCATE_JOB,
  GET_ASSIGNMENTDISTINCT_JOB_PROFILE_REVIEWLIST_SAGA,
  GET_JOBPROFILE_GROUP_REVIEW_LIST_SAGA,
  GET_JOBPROFILE_REVIEW_LIST_SAGA,
  GET_JOBPROFILE_TYPE_REVIEW_LIST_SAGA,
  GET_JOB_NODE_JOB_REVIEW_LIST_SAGA,
  INTERNAL_NODE_LIST_WITH_PERMISSIONS_SAGA,
  JOB_ASSESSMENTS_REVIEWLIST_SAGA,
  JOB_GROUP_JOB_REVIEWLIST_SAGA,
  JOB_GROUP_REVISE_INFO_SAGA,
  JOB_PROFILE_INFO_REVISE_SAGA,
  JOB_PROFILE_MULTI_STATUS_REVISE_SAGA,
  JOB_PROFILE_PUBLISHED_SAGA,
  JOB_TYPE_JOB_REVIEWLIST_SAGA,
  JOB_TYPE_REVISE_INFO_SAGA,
  LOADER_START,
  SET_CORE_GROUP_REVIEW_LIST_REQ_OBJECT,
  SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT,
  SET_CORE_TYPE_REVIEW_LIST_REQ_OBJECT,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_PAGE_COUNT,
  SET_POPUP_SINGLE_STATE,
  SET_POPUP_VALUE,
  SET_RELATED_REQUEST_OBJECT,
  SET_REQUEST_OBJECT,
} from "../actionType";
import {
  makeInternalNodeObj,
  makeJobProfileObj,
  makeJobProfileGroupObj,
  makeJobProfileTypeObj,
  getJobProfileGroupJobProfileReqObj,
  getJobProfileGroupJobProfileScanReqObj,
  getJobProfileTypeJobProfileReqObj,
  getJobProfileTypeJobProfileScanReqObj,
  getNodeJobProfileReqObj,
  getNodeJobProfileScanReqObj,
  makeJobProfileGroupAllocateObj,
  makeJobProfileTypeAllocateObj,
} from "./GenericActions";
const resetDataFunction = (dispatch, cardValue = "NoCard") => {
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: "allocateStr", value: "" },
  });
  dispatch({
    type: SET_POPUP_SINGLE_STATE,
    payload: { stateName: "cardValue", value: cardValue },
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: "typeOfMiddlePaneList", value: "" },
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: "middlePaneSelectedValue", value: "" },
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: "selectedFlagedArray", value: [] },
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: "unselectedFlagedArray", value: [] },
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: "selectedTagsArray", value: [] },
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: "unselectedTagsArray", value: [] },
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: "flagedValue", value: "" },
  });
  dispatch({
    type: SET_POPUP_SINGLE_STATE,
    payload: { stateName: "selectionValue", value: "" },
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: "scanString", value: "" },
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'allocatedTagsArray', value: [] }
  });
  dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
};
export const jobProfileCreatePopup = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  dispatch,
  assignmentCreateType
) => {
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: "responseStatus", value: "" },
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: "responseObject", value: "" },
  });
  resetDataFunction(dispatch, "NoCard");
  dispatch({ type: CLEAR_JOB_REDUCER_STATE });
  dispatch({ type: LOADER_START });
  let requestObj = makeJobProfileGroupObj(
    selectedAssociateInfo,
    "active",
    0,
    -1,
    'list',
    assignmentCreateType
  );
  dispatch({
    type: SET_CORE_GROUP_REVIEW_LIST_REQ_OBJECT,
    payload: requestObj,
  });
  dispatch({
    type: GET_JOBPROFILE_GROUP_REVIEW_LIST_SAGA,
    payload: {
      request: requestObj,
      BadgeOne: "",
      BadgeTwo: "",
      BadgeThree: "",
      isMiddlePaneList: false,
    },
  });
  let nodeRequestObj = makeInternalNodeObj(
    selectedAssociateInfo,
    "active",
    0,
    -1,
    '', 'Associate-Self-Node-List-Create'
  );
  dispatch({
    type: SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT,
    payload: nodeRequestObj,
  });
  dispatch({
    type: INTERNAL_NODE_LIST_WITH_PERMISSIONS_SAGA,
    payload: {
      request: nodeRequestObj,
      BadgeOne: "",
      BadgeTwo: "",
      BadgeThree: "",
      nodeViewState: "list",
      isMiddlePaneList: false,
    },
  });
  let typeRequestObj = makeJobProfileTypeObj(
    selectedAssociateInfo,
    "active",
    0,
    -1
  );
  dispatch({
    type: SET_CORE_TYPE_REVIEW_LIST_REQ_OBJECT,
    payload: typeRequestObj,
  });
  dispatch({
    type: GET_JOBPROFILE_TYPE_REVIEW_LIST_SAGA,
    payload: {
      request: typeRequestObj,
      BadgeOne: "",
      BadgeTwo: "",
      BadgeThree: "",
      isMiddlePaneList: false,
    },
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: "selectedInformationAllorKey",
      value: secondaryOptionCheckValue,
    },
  });
  dispatch({
    type: SET_POPUP_VALUE,
    payload: { isPopUpValue: "NAMEPOPUP", popupMode: "JOBCREATE" },
    // payload: { isPopUpValue: 'POPUPCONTINUE', popupMode: 'JOBCREATE' }
  });
};
export const getJobProfilesDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  popupHeaderOne,
  dispatch,
  isSelectActive = "",
  selectedTagsArray,
  actionFilter=''
) => {
  let requestObj = makeJobProfileObj(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    countPage,
    0,
    actionFilter
  );

  dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: "jobProfileDistinct" + secondaryOptionCheckValue },
  });
  // resetDataFunction(dispatch, 'NoCard');
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: "displayPaneTwo" });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: GET_JOBPROFILE_REVIEW_LIST_SAGA,
    payload: {
      middlePaneHeader: "job-profiles",
      request: requestObj,
      BadgeOne: "distinct",
      BadgeTwo: secondaryOptionCheckValue,
      BadgeThree: "",
      isMiddlePaneList: true,
      isSelectActive: isSelectActive,
      selectedTagsArray: selectedTagsArray,
    },
  });
};
export const getJobProfileGroupApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  cardValue = 'noCard',
  isSelectActive = '',
  selectedTagsArray = [],
  groupViewState,
  isReset = false,
  assignmentCreateType,
  actionFilter=''
) => {
  if (secondaryOptionCheckValue !== 'active') {
    groupViewState = 'list';
  }
  let requestObj = makeJobProfileGroupObj(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    countPage,
    0,
    groupViewState,
    assignmentCreateType,
    actionFilter
  );
  dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: 'jobProfileGroup' + secondaryOptionCheckValue },
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'nodeViewState', value: groupViewState },
  });

  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  isReset && resetDataFunction(dispatch, cardValue);
  dispatch({
    type: GET_JOBPROFILE_GROUP_REVIEW_LIST_SAGA,
    payload: {
      request: requestObj,
      BadgeOne: targetValue,
      BadgeTwo: cardValue === 'Card' ? 'distinct' : secondaryOptionCheckValue,
      BadgeThree: cardValue === 'Card' ? secondaryOptionCheckValue : '',
      isMiddlePaneList: true,
      isSelectActive: isSelectActive,
      selectedTagsArray: selectedTagsArray,
      groupViewState,
      cardValue: cardValue,
    },
  });
};
export const getJobProfileGroupAllocateApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  cardValue = 'noCard',
  isSelectActive = '',
  selectedTagsArray = [],
  groupViewState,
  isReset = false,
  assignmentCreateType,
  allocatedTagsArray
) => {
  if (secondaryOptionCheckValue !== 'active' || isSelectActive === 'multiple') {
    groupViewState = 'list';
  }
  let requestObj = makeJobProfileGroupAllocateObj(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    countPage,
    0,
    groupViewState,
    assignmentCreateType,
    allocatedTagsArray
  );
  dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: 'jobProfileGroup' + secondaryOptionCheckValue },
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'nodeViewState', value: groupViewState },
  });

  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  isReset && resetDataFunction(dispatch, cardValue);
  dispatch({
    type: GET_JOBPROFILE_GROUP_REVIEW_LIST_SAGA,
    payload: {
      request: requestObj,
      BadgeOne: targetValue,
      BadgeTwo: cardValue === 'Card' ? 'distinct' : secondaryOptionCheckValue,
      BadgeThree: cardValue === 'Card' ? secondaryOptionCheckValue : '',
      isMiddlePaneList: true,
      isSelectActive: isSelectActive,
      selectedTagsArray: selectedTagsArray,
      groupViewState,
      cardValue: cardValue,
    },
  });
};
export const getJobProfileTypeApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  middlePaneHeader = "job-profiles",
  cardValue = "noCard",
  isSelectActive = "",
  selectedTagsArray = [],
  isReset = false,
  actionFilter=''
) => {
  let requestObj = makeJobProfileTypeObj(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    countPage,
    0,
    actionFilter
  );

  dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({
    type: FILTERMODE,
    payload: {
      FilterMode: "jobProfileTypeDistinct" + secondaryOptionCheckValue,
    },
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: "nodeViewState", value: "" },
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: "displayPaneTwo" });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  isReset && resetDataFunction(dispatch, cardValue);
  dispatch({
    type: GET_JOBPROFILE_TYPE_REVIEW_LIST_SAGA,
    payload: {
      middlePaneHeader: middlePaneHeader,
      request: requestObj,
      BadgeOne: targetValue,
      BadgeTwo: cardValue === "Card" ? "distinct" : secondaryOptionCheckValue,
      BadgeThree: cardValue === "Card" ? secondaryOptionCheckValue : "",
      isMiddlePaneList: true,
      isSelectActive: isSelectActive,
      selectedTagsArray: selectedTagsArray,
      cardValue: cardValue,
    },
  });
};
export const getJobProfileTypeApiAllcoateCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  middlePaneHeader = "job-profiles",
  cardValue = "noCard",
  isSelectActive = "",
  selectedTagsArray = [],
  isReset = false,
  allocatedTagsArray
) => {
  let requestObj = makeJobProfileTypeAllocateObj(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    countPage,
    0,
    allocatedTagsArray
  );

  dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({
    type: FILTERMODE,
    payload: {
      FilterMode: "jobProfileTypeDistinct" + secondaryOptionCheckValue,
    },
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: "nodeViewState", value: "" },
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: "displayPaneTwo" });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  isReset && resetDataFunction(dispatch, cardValue);
  dispatch({
    type: GET_JOBPROFILE_TYPE_REVIEW_LIST_SAGA,
    payload: {
      middlePaneHeader: middlePaneHeader,
      request: requestObj,
      BadgeOne: targetValue,
      BadgeTwo: cardValue === "Card" ? "distinct" : secondaryOptionCheckValue,
      BadgeThree: cardValue === "Card" ? secondaryOptionCheckValue : "",
      isMiddlePaneList: true,
      isSelectActive: isSelectActive,
      selectedTagsArray: selectedTagsArray,
    },
  });
};

export const getJobProfileGroupJobProfileDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  selectedTagValue,
  searchStr,
  isScan
) => {
  let reqBody = getJobProfileGroupJobProfileReqObj(
    selectedAssociateInfo,
    selectedTagValue,
    secondaryOptionCheckValue,
    0,
    countPage
  );
  if (isScan) {
    reqBody = getJobProfileGroupJobProfileScanReqObj(
      selectedAssociateInfo,
      selectedTagValue,
      secondaryOptionCheckValue,
      0,
      countPage,
      searchStr
    );
  }
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: "relatedReviewListObjectCopy",
      value: reqBody,
    },
  });

  // dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: "displayPaneTwo" });
  dispatch({
    type: SET_RELATED_REQUEST_OBJECT,
    payload: reqBody,
  });
  dispatch({ type: LOADER_START });
  // dispatch({ type: SET_REQUEST_OBJECT, payload: reqBody });
  dispatch({
    type: JOB_GROUP_JOB_REVIEWLIST_SAGA,
    payload: {
      request: reqBody,
      HeaderOne: "job-profiles",
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      BadgeThree: "",
      isMiddlePaneList: true,
    },
  });
};
export const getJobProfileTypeJobProfileDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  selectedTagValue,
  searchStr,
  isScan
) => {
  let reqBody = getJobProfileTypeJobProfileReqObj(
    selectedAssociateInfo,
    selectedTagValue,
    secondaryOptionCheckValue,
    0,
    countPage
  );
  if (isScan) {
    reqBody = getJobProfileTypeJobProfileScanReqObj(
      selectedAssociateInfo,
      selectedTagValue,
      secondaryOptionCheckValue,
      0,
      countPage,
      searchStr
    );
  }
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: "relatedReviewListObjectCopy",
      value: reqBody,
    },
  });

  // dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: "displayPaneTwo" });
  dispatch({
    type: SET_RELATED_REQUEST_OBJECT,
    payload: reqBody,
  });
  dispatch({ type: LOADER_START });
  // dispatch({ type: SET_REQUEST_OBJECT, payload: reqBody });
  dispatch({
    type: JOB_TYPE_JOB_REVIEWLIST_SAGA,
    payload: {
      request: reqBody,
      HeaderOne: "job-profiles",
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      BadgeThree: "",
      isMiddlePaneList: true,
    },
  });
};
export const getJobProfileNodeJobProfileApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  selectedTagValue,
  searchStr,
  isScan,
  middlePaneHeader,
  isMiddlePaneList = true,
  actionFilter=''
) => {
  let reqBody = getNodeJobProfileReqObj(
    selectedAssociateInfo,
    selectedTagValue,
    secondaryOptionCheckValue,
    0,
    countPage,
    actionFilter
  );
  if (isScan) {
    reqBody = getNodeJobProfileScanReqObj(
      selectedAssociateInfo,
      selectedTagValue,
      secondaryOptionCheckValue,
      0,
      countPage,
      searchStr,
      actionFilter
    );
  }
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: "relatedReviewListObjectCopy",
      value: reqBody,
    },
  });

  // dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: "displayPaneTwo" });
  if (isMiddlePaneList) {
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: "relatedReviewListDistinctData", value: [] },
    });
  }
  dispatch({ type: LOADER_START });
  // dispatch({ type: SET_REQUEST_OBJECT, payload: reqBody });
  dispatch({
    type: GET_JOB_NODE_JOB_REVIEW_LIST_SAGA,
    payload: {
      request: reqBody,
      HeaderOne: middlePaneHeader,
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      BadgeThree: "",
      isMiddlePaneList: isMiddlePaneList,
    },
  });
};
export const updateJobProfileDistinctStatus = (
  selectedAssociateInfo,
  jobProfileId,
  dispatch,
  reviseStatus,
  createMode,
  typeOfMiddlePaneList
) => {
  let reqBody;
  if (reviseStatus === "PUBLISHED") {
    reqBody = {
      module: "Job-Profile-Distinct",
      action: "Publish",
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag
            .associateTagPrimary,
        jobProfileId: jobProfileId,
      },
    };
    dispatch({ type: LOADER_START });
    dispatch({
      type: JOB_PROFILE_PUBLISHED_SAGA,
      payload: {
        secondaryOptionCheckValue: "",
        isHideRightPane: true,
        headerOne: "",
        reqBody,
        createMode,
        typeOfMiddlePaneList,
      },
    });
  } else {
    reqBody = {
      module: "Job-Profile-Distinct",
      action: "Revise",
      actionFilter: "Status",
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag
            .associateTagPrimary,
        jobProfileDistinct: [jobProfileId],
        reviseStatus: reviseStatus,
      },
      createMode: createMode,
    };
    dispatch({ type: LOADER_START });
    dispatch({
      type: JOB_PROFILE_MULTI_STATUS_REVISE_SAGA,
      payload: {
        secondaryOptionCheckValue: "",
        isHideRightPane: true,
        headerOne: "",
        reqBody,
        createMode,
        typeOfMiddlePaneList,
      },
    });
  }
};
export const updateJobProfileTypeStatus = (
  selectedAssociateInfo,
  typeId,
  dispatch,
  reviseStatus,
  createMode
) => {
  let reqBody = {
    assesseeId: selectedAssociateInfo?.assesseeId,
    associateId:
      selectedAssociateInfo?.associate?.informationEngagement.associateTag
        .associateTagPrimary,
    jobProfileType: {
      id: typeId,
      informationEngagement: {
        jobProfileTypeStatus:
          reviseStatus === "UNSUSPENDED" ||
            reviseStatus === "UNTERMINATED" ||
            reviseStatus === "UNARCHIVED"
            ? "ACTIVE"
            : reviseStatus,
      },
    },
  };
  dispatch({ type: LOADER_START });
  dispatch({
    type: JOB_TYPE_REVISE_INFO_SAGA,
    payload: {
      secondaryOptionCheckValue: "",
      jobTypeJobReqBody: null,
      headerOne: "",
      reqBody,
      createMode,
    },
  });
};
export const getAssignmneJobProfileDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  selectedTagValue,
  searchStr,
  isScan,
  reviewMode
) => {
  let searchObj = {
    condition: "eq",
    value: {
      from: reviewMode === 'review'? 'ACTIVE' : secondaryOptionCheckValue.toUpperCase(),
    },
  };
  if (secondaryOptionCheckValue === "all") {
    searchObj = {
      condition: "in",
      value: {
        in: ["SUSPENDED", "TERMINATED", "PUBLISHED", "UNPUBLISHED"],
      },
    };
  }
  let reqBody=null;
  if(isScan){
    reqBody = {
      module: "Assignment-Distinct",
      action: "Review-List",
      actionFilter: "Assignment-Job-Profile",
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag
            .associateTagPrimary,
        assignmentId: selectedTagValue,
        numberPage: 0,
        countPage: countPage,
        searchCondition: "AND",
        orderBy: {
          columnName:
            'informationBasic.jobProfileName,informationBasic.jobProfileDescription',
          order: 'asc'
        },
        filter: true,
        search: [
          {
            condition: 'or',
            searchBy: [
              {
                dataType: 'string',
                conditionColumn: 'informationBasic.jobProfileName',
                conditionValue: {
                  condition: 'ct',
                  value: {
                    from: searchStr
                  }
                }
              },
              {
                dataType: 'string',
                conditionColumn: 'informationBasic.jobProfileDescription',
                conditionValue: {
                  condition: 'ct',
                  value: {
                    from: searchStr
                  }
                }
              },
              {
                dataType: 'string',
                conditionColumn: 'informationEngagement.jobProfileTag.jobProfileTagPrimary',
                conditionValue: {
                  condition: 'ct',
                  value: {
                    from: searchStr
                  }
                }
              }
            ]
          },
          {
            condition: "or",
            searchBy: [
              {
                dataType: "string",
                conditionColumn: "informationEngagement.jobProfileStatus",
                conditionValue: searchObj,
              },
            ],
          },
        ],
      },
    };
  }else{
    reqBody = {
      module: "Assignment-Distinct",
      action: "Review-List",
      actionFilter: "Assignment-Job-Profile",
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag
            .associateTagPrimary,
        assignmentId: selectedTagValue,
        numberPage: 0,
        countPage: countPage,
        searchCondition: "AND",
        orderBy: {
          columnName:
            'informationBasic.jobProfileName,informationBasic.jobProfileDescription',
          order: 'asc'
        },
        filter: true,
        search: [
          {
            condition: "or",
            searchBy: [
              {
                dataType: "string",
                conditionColumn: "informationEngagement.jobProfileStatus",
                conditionValue: searchObj,
              },
            ],
          },
        ],
      },
    };
  }
 
  // dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: "displayPaneTwo" });
  dispatch({
    type: SET_RELATED_REQUEST_OBJECT,
    payload: reqBody,
  });
  dispatch({ type: LOADER_START });
  // dispatch({ type: SET_REQUEST_OBJECT, payload: reqBody });
  dispatch({
    type: GET_ASSIGNMENTDISTINCT_JOB_PROFILE_REVIEWLIST_SAGA,
    payload: {
      request: reqBody,
      HeaderOne: "job-profiles",
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      BadgeThree: "",
      isMiddlePaneList: true,
    },
  });
};
export const getJobProfileAssessmentDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  selectedTagValue,
  searchStr,
  isScan
) => {
  let searchObj = {
    condition: "eq",
    value: {
      from: secondaryOptionCheckValue.toUpperCase(),
    },
  };
  if (secondaryOptionCheckValue === "all") {
    searchObj = {
      condition: "in",
      value: {
        in: ["SUSPENDED", "TERMINATED", "PUBLISHED", "ARCHIVED", "UNPUBLISHED"],
      },
    };
  }
  let reqBody = {
    module: "Job-Profile-Distinct",
    action: "Review-List",
    actionFilter: "Job-Profile-Assessment",
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag
          .associateTagPrimary,
      filter: "true",
      numberPage: 0,
      countPage: countPage,
      searchCondition: "AND",
      jobProfileId: selectedTagValue,
      orderBy: {
        columnName: 'informationBasic.assessmentName,informationBasic.assessmentDescription',
        order: 'asc'
      },
      search: [
        {
          condition: "and",
          searchBy: [
            {
              dataType: "string",
              conditionColumn: "informationEngagement.assessmentStatus",
              conditionValue: searchObj,
            },
          ],
        },
      ],
    },
  };

  // dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: "displayPaneTwo" });
  dispatch({
    type: SET_RELATED_REQUEST_OBJECT,
    payload: reqBody,
  });
  dispatch({ type: LOADER_START });
  // dispatch({ type: SET_REQUEST_OBJECT, payload: reqBody });
  dispatch({
    type: JOB_ASSESSMENTS_REVIEWLIST_SAGA,
    payload: {
      request: reqBody,
      HeaderOne: "assessments",
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      BadgeThree: "",
      isMiddlePaneList: true,
    },
  });
};
export const updateJobProfileFlagUpdate = (
  selectedAssociateInfo,
  Id,
  dispatch,
  reviseStatus,
  type,
  flagVal,
  createMode
) => {
  let reqBody = {
    module:
      (type === "jobProfileType" && "Job-Profile-Type") ||
      (type === "jobProfileGroup" && "Job-Profile-Group") ||
      (type === "jobProfile" && "Job-Profile-Distinct"),
    action: "Revise",
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag
          .associateTagPrimary,
      [type]: {
        id: Id,
        informationBasic: {
          [flagVal]: reviseStatus,
        },
      },
    },
  };
  dispatch({ type: LOADER_START });
  dispatch({
    type:
      type === "jobProfileType"
        ? JOB_TYPE_REVISE_INFO_SAGA
        : type === "jobProfileGroup"
          ? JOB_GROUP_REVISE_INFO_SAGA
          : type === "jobProfile"
            ? JOB_PROFILE_INFO_REVISE_SAGA
            : "",
    payload: {
      secondaryOptionCheckValue: "",
      jobGroupJobReqBody: null,
      isHideRightPane: true,
      headerOne: "",
      reqBody,
      createMode: createMode,
      jobTypeJobReqBody: null,
    },
  });
};
export const jobProfileGroupReviseApiCall = (
  responseObject,
  selectedAssociateInfo,
  selectedTagsArray,
  unselectedTagsArray,
  jobProfileGroup,
  dispatch,
  createMode,
  isReviseCallFromPaneTwo = false,
  cardValue = 'noCard',
) => {
  const { associateId, id } = responseObject;
  const reqBody = {
    module: "Job-Profile-Group",
    action: "Revise",
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag
          .associateTagPrimary,
      jobProfileGroupJobProfile: {
        jobProfileGroupJobProfileAllocate: selectedTagsArray || [],
        jobProfileGroupJobProfileUnallocate: unselectedTagsArray || [],
      },
      jobProfileGroup: {
        id,
        informationBasic: jobProfileGroup.informationBasic,
        informationSetup: jobProfileGroup.informationSetup,
        informationAllocation: jobProfileGroup.informationAllocation,
      },
    },
  };
  dispatch({ type: LOADER_START });
  let jobGroupJobReqBody = getJobProfileGroupJobProfileReqObj(
    selectedAssociateInfo,
    id,
    "",
    -1,
    -1
  );
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: "relatedReviewListDistinctData", value: [] },
  });
  dispatch({
    type: JOB_GROUP_REVISE_INFO_SAGA,
    payload: {
      headerOne: "job-profiles",
      reqBody,
      jobGroupJobReqBody,
      createMode,
      cardValue,
      isReviseCallFromPaneTwo: isReviseCallFromPaneTwo,
    },
  });
};
export const jobProfileTypeReviseApiCall = (
  responseObject,
  selectedAssociateInfo,
  selectedTagsArray,
  unselectedTagsArray,
  jobProfileType,
  dispatch,
  createMode,
  isReviseCallFromPaneTwo = false
) => {
  const { associateId, id } = responseObject;
  const reqBody = {
    module: "Job-Profile-Type",
    action: "Revise",
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId,
      jobProfileTypeJobProfile: {
        jobProfileTypeJobProfileAllocate: selectedTagsArray || [],
        jobProfileTypeJobProfileUnallocate: unselectedTagsArray || [],
      },
      jobProfileType: {
        id,
        informationBasic: jobProfileType.informationBasic,
        informationAllocation: jobProfileType.informationAllocation,
        informationSetup: jobProfileType.informationSetup,
      },
    },
  };
  dispatch({ type: LOADER_START });
  let jobTypeJobReqBody = getJobProfileTypeJobProfileReqObj(
    selectedAssociateInfo,
    id,
    "",
    -1,
    -1
  );
  dispatch({
    type: JOB_TYPE_REVISE_INFO_SAGA,
    payload: {
      headerOne: "job-profiles",
      reqBody,
      jobTypeJobReqBody,
      createMode,
      isReviseCallFromPaneTwo: isReviseCallFromPaneTwo,
    },
  });
};
export const getJobProfileAllocateApi = (
  dispatch,
  requestObect,
  relatedReviewListPaneThree,
  revisedTypeObject,
  filterMode,
  typeOfMiddlePaneList,
  selectedTagsArray = []
) => {
  let existingJobProfileId = [];
  if (relatedReviewListPaneThree?.length > 0) {
    existingJobProfileId = relatedReviewListPaneThree[0]?.jobProfile?.map(
      (val) => {
        return val.id;
      }
    );
  }
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: filterMode },
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: "relatedReviewListDistinctData", value: [] },
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: "displayPaneTwo" });
  dispatch({ type: LOADER_START });
  // dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });

  //set requestObject
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: "relatedReviewListObjectCopy",
      value: requestObect,
    },
  });
  console.log("existingJobProfileId_", existingJobProfileId);
  existingJobProfileId = existingJobProfileId ? existingJobProfileId : [];
  // Get list for pagination
  dispatch({
    type: GET_ALLOCATE_JOB,
    payload: {
      request: requestObect,
      // BadgeTwo: 'all',
      revisedGroupObject: revisedTypeObject,
      existingJobProfileId: [...existingJobProfileId, ...selectedTagsArray],
      typeOfMiddlePaneList: typeOfMiddlePaneList
    },
  });
};
export const getJobProfileGroupCreateAPI = (
  selectedAssociateInfo,
  dispatch,
  moduleName,
  assignmentCreateType
) => {
  let requestObj = makeJobProfileGroupObj(
    selectedAssociateInfo,
    "active",
    0,
    -1,
    'list',
    assignmentCreateType
  );
  dispatch({
    type: SET_CORE_GROUP_REVIEW_LIST_REQ_OBJECT,
    payload: requestObj,
  });
  dispatch({
    type: GET_JOBPROFILE_GROUP_REVIEW_LIST_SAGA,
    payload: {
      request: requestObj,
      BadgeOne: "",
      BadgeTwo: "",
      BadgeThree: "",
      isMiddlePaneList: false,
    },
  });
  let nodeRequestObj = makeInternalNodeObj(
    selectedAssociateInfo,
    "active",
    0,
    -1,
    '', 'Associate-Self-Node-List-Create'
  );
  dispatch({ type: LOADER_START });
  dispatch({
    type: SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT,
    payload: nodeRequestObj,
  });
  dispatch({
    type: INTERNAL_NODE_LIST_WITH_PERMISSIONS_SAGA,
    payload: {
      request: nodeRequestObj,
      BadgeOne: "",
      BadgeTwo: "",
      BadgeThree: "",
      nodeViewState: "list",
      isMiddlePaneList: false,
    },
  });
  let typeRequestObj = makeJobProfileTypeObj(
    selectedAssociateInfo,
    "active",
    0,
    -1
  );
  dispatch({
    type: SET_CORE_TYPE_REVIEW_LIST_REQ_OBJECT,
    payload: typeRequestObj,
  });
  dispatch({
    type: GET_JOBPROFILE_TYPE_REVIEW_LIST_SAGA,
    payload: {
      request: typeRequestObj,
      BadgeOne: "",
      BadgeTwo: "",
      BadgeThree: "",
      isMiddlePaneList: false,
    },
  });
  dispatch({ type: CLEAR_GROUP_REDUCER_STATE });
  dispatch({
    type: SET_POPUP_VALUE,
    payload: {
      isPopUpValue: "NAMEPOPUP",
      popupMode: moduleName + "GROUPCREATE",
    },
  });
};
