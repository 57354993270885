import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CLEAR_DISPLAY_PANE_THREE,
  FILTERMODE,
  FILTERMODE_ENABLE,
  GET_ALLOCATE_ITEM,
  SET_PAGE_COUNT,
  POPUP_OPEN,
  SET_ASSESSEE_GROUP_ASSESSEE_ID_LIST,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MIDDLEPANE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_POPUP_STATE,
  SET_UNSELECTED_ASSESSEE_GROUP_ASSESSEE_ID_LIST,
  GET_ITEMGROUPITEM_REVIEW_LIST_SAGA
} from '../actionType';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import { FilterList } from '@material-ui/icons';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import { ASSOCIATE_REVIEW_LIST_POPUP_OPTION } from '../PopUpConfig';
import Card from '../Molecules/Card/Card';
import CrossIcon from '@material-ui/icons/Clear';
import { onClickCheckBoxSelection } from '../Actions/AssesseeModuleAction';
import ReviseIcon from '@material-ui/icons/RadioButtonChecked';
import Check from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import {
  getItemGroupItemDistinctApiCall,
  itemGroupReviseApicall
} from '../Actions/ItemModuleAction';
import { closeRelatedList, getPopUpOptionList } from '../Actions/GenericActions';
import ListLoader from '../Atoms/ListLoader/ListLoader';

const ItemGroupItemDistinctReviewList = (props) => {
  const dispatch = useDispatch();
  const [isShowReviseIcon, setIsShowReviseIcon] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const {
    middlePaneSelectedValue,
    reviewListDistinctData,
    selectedAssociateInfo,
    relatedReviewListDistinctData,
    middlePaneHeaderBadgeOne,
    middlePaneHeaderDuplicate,
    middlePaneHeaderDuplicateBadgeOne,
    middlePaneHeaderDuplicateBadgeTwo,
    middlePaneHeaderDuplicateBadgeThree,
    middlePaneHeaderDuplicateBadgeFour,
    middlePaneHeaderDuplicateOne,
    middlePaneHeaderDuplicateOneBadgeOne,
    middlePaneHeaderDuplicateOneBadgeTwo,
    middlePaneHeaderDuplicateOneBadgeThree,
    middlePaneHeaderDuplicateOneBadgeFour,
    selectedTagsArray,
    isSelectActive,
    unselectedTagsArray,
    typeOfMiddlePaneList,
    middlePaneHeaderBadgeTwo,
    middlePaneHeader,
    isMiddlePaneList,
    numberPage,
    scanCount,
    duplicateScanCount,
    signedAssesseePermission,
    relatedReviewListObjectCopy,
    isListLoading,
    countPage
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { FilterMode, FilterModeEnable } = useSelector((state) => state.FilterReducer);
  const { originPrimaryArrOprion } = useSelector((state) => state.PopUpReducer);
  const { reviewMode, createMode, headerOneBadgeOne } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  const { itemGroup } = useSelector((state) => state.GroupCreateReducer);
  const onClickRevise = () => {
    console.log('ON CLICK REVISE ICON');
    setIsShowReviseIcon(false);
  };
  const onClickReviseCancel = () => {
    if (isListLoading) {
      return
    }
    console.log('ON CLICK cancel ICON');
    setIsShowReviseIcon(true);
    closeRelatedList(
      dispatch,
      createMode,
      responseObject,
      middlePaneHeaderDuplicate,
      middlePaneHeaderDuplicateBadgeOne,
      middlePaneHeaderDuplicateBadgeTwo,
      middlePaneHeaderDuplicateBadgeThree,
      middlePaneHeaderDuplicateBadgeFour,
      'itemsGroupDistinctReviewList',
      duplicateScanCount,
      'itemGroupDistinct',
      duplicateScanCount,
      middlePaneHeaderDuplicateOne,
      middlePaneHeaderDuplicateOneBadgeOne,
      middlePaneHeaderDuplicateOneBadgeTwo,
      middlePaneHeaderDuplicateOneBadgeThree,
      middlePaneHeaderDuplicateOneBadgeFour,
    );
    dispatch({type:SET_PAGE_COUNT,payload:Math.round(parseInt(reviewListDistinctData.length)/countPage)-1})
    if (headerOneBadgeOne !== 'group') {
      dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
    }
  };
  const onClickReviseFinish = () => {
    console.log('ON CLICK finish ICON', selectedTagsArray, unselectedTagsArray);
    setIsShowReviseIcon(true);
    // if (typeOfMiddlePaneList !== "")
    dispatch({
      type: FILTERMODE,
      payload: { FilterMode: '' }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'isSelectActive', value: '' }
    });
    dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
    dispatch({
      type: SET_ASSESSEE_GROUP_ASSESSEE_ID_LIST,
      payload: selectedTagsArray
    });
    dispatch({
      type: SET_UNSELECTED_ASSESSEE_GROUP_ASSESSEE_ID_LIST,
      payload: unselectedTagsArray
    });
    itemGroupReviseApicall(
      responseObject,
      selectedAssociateInfo,
      selectedTagsArray,
      unselectedTagsArray,
      itemGroup,
      dispatch,
      createMode,
      true
    );
    closeRelatedList(
      dispatch,
      createMode,
      responseObject,
      middlePaneHeaderDuplicate,
      middlePaneHeaderDuplicateBadgeOne,
      middlePaneHeaderDuplicateBadgeTwo,
      middlePaneHeaderDuplicateBadgeThree,
      middlePaneHeaderDuplicateBadgeFour,
      'itemsGroupDistinctReviewList',
      duplicateScanCount,
      'itemGroupDistinct',
      duplicateScanCount,
      middlePaneHeaderDuplicateOne,
      middlePaneHeaderDuplicateOneBadgeOne,
      middlePaneHeaderDuplicateOneBadgeTwo,
      middlePaneHeaderDuplicateOneBadgeThree,
      middlePaneHeaderDuplicateOneBadgeFour,
    );
  };
  const revisePrimaryIcon = [{ label: 'revise', onClick: onClickRevise, Icon: ReviseIcon }];

  const reviseSecondaryIcons = [
    { label: 'cancel', onClick: onClickReviseCancel, Icon: ClearIcon },
    { label: 'finish', onClick: onClickReviseFinish, Icon: Check }
  ];

  const listDistinctData = relatedReviewListDistinctData[0];

  const siftApiCall = (siftKey) => {
    getItemGroupItemDistinctApiCall(
      selectedAssociateInfo,
      siftKey,
      countPage,
      dispatch,
      middlePaneHeaderBadgeOne,
      listDistinctData.id,
      '',
      false
    );
    document.getElementById('middleComponentId').scrollTop = '0px';
  };
  const onClickFooter = (e) => {
    let siftValue = e.currentTarget.getAttribute('data-value');
    if (siftValue === 'suspended' || siftValue === 'terminated' || siftValue === 'unpublished') siftApiCall(siftValue);
    dispatch({ type: FILTERMODE_ENABLE });
  };
  /* for middle pane */
  const primaryIcon = [{ label: 'sift', onClick: onClickFooter, Icon: FilterList }];
  const secondaryIcon = [
    { label: 'suspended', onClick: onClickFooter, Icon: FilterList },
    { label: 'terminated', onClick: onClickFooter, Icon: FilterList },
    { label: 'unpublished', onClick: onClickFooter, Icon: FilterList }
  ];

  const openListPopup = (e) => {
    console.log(e.currentTarget.getAttribute('tag'));
    let tempArr = [];
    let stats = e.currentTarget.getAttribute('status');
    let isShared = e.currentTarget.getAttribute('data-shared') === 'true' && true;
    let associateId = e.currentTarget.getAttribute('associateid');
    let signedAssociateId =
    selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary;
    
    let itemDistictPermssion =
      signedAssesseePermission?.itemDistictPermssion?.assesseeItemDistinctPermission;
    let popupContentArr = getPopUpOptionList(
      ASSOCIATE_REVIEW_LIST_POPUP_OPTION,
      itemDistictPermssion
    );
    popupContentArr.map((element) => {
      if (stats === 'PUBLISHED' && element.data === 'revise')
        tempArr.push({ ...element, disabled: true });
      else if (stats !== 'PUBLISHED' && element.data === 'share')
        tempArr.push({ ...element, disabled: true });
      else if (
        (stats === 'ARCHIVED' || stats === 'TERMINATED' || stats === 'SUSPENDED') &&
        (element.data === 'allocate' ||
          element.data === 'publish' ||
          element.data === 'revise' ||
          element.data === 'share')
      ) {
        tempArr.push({ ...element, disabled: true });
      } else if (
        isShared &&
        associateId !== signedAssociateId &&
        (element.data === 'revise' ||
          element.data === 'archive' ||
          element.data === 'flag' ||
          element.data === 'delete' ||
          element.data === 'suspend' ||
          element.data === 'move' ||
          element.data === 'terminate')
      ) tempArr.push({ ...element, disabled: true });
      else tempArr.push(element);
    });
    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: 'item',
       popupHeaderOneBadgeOne: middlePaneHeaderBadgeOne,
        isPopUpValue: '',
        popupOpenType: 'primary',
        popupContentArrValue: tempArr,
        isShared: isShared === 'true' && true,
        originPrimaryArrOprion: originPrimaryArrOprion,
        selectedTagValue: e.currentTarget.getAttribute('tag'),
        selectedTagStatus: e.currentTarget.getAttribute('status')
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: tempArr
      }
    });
  };

  // this code for item type list
  const { responseObject } = useSelector((state) => state.DisplayPaneThreeReducer);
  // const [isFetching, setIsFetching] = useState(false);

  // useEffect(() => {
  //   document.getElementById('middleComponentId').addEventListener('scroll', handleScroll);
  // }, []);
  // const handleScroll = (event) => {
  //   var targetPt = event.target;
  //   if (
  //     Math.ceil(targetPt.scrollHeight - targetPt.scrollTop) !== targetPt.clientHeight ||
  //     isFetching
  //   )
  //     return;
  //   setIsFetching(true);
  //   console.log(isFetching);
  // };
  const fetchData = async () => {
    if (relatedReviewListDistinctData.length > 0) {
      if (relatedReviewListDistinctData[0].item.length < scanCount) {
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'isListLoading', value: true }
        })
        let obj = {
          ...relatedReviewListObjectCopy.requestObject,
          numberPage: numberPage + 1
        };
        if (reviewMode === 'revise') {
          dispatch({
            type: GET_ALLOCATE_ITEM,
            payload: {
              existingItemId: selectedTagsArray,
              unselectedTagsArray,
              request: {
                module: 'Item-Distinct',
                action: 'Review-List',
                requestObject: obj
              },
              HeaderOne: middlePaneHeader,
              BadgeOne: 'distinct',
              BadgeTwo: middlePaneHeaderBadgeTwo,
              isMiddlePaneList: true,
              typeOfMiddlePaneList: 'itemGroupItemReviewList',
              revisedGroupObject: {
                id: responseObject.id,
                itemGroupName: responseObject.informationBasic.itemGroupName,
                itemGroupDescription: responseObject.informationBasic.itemGroupDescription,
                itemGroupStatus: responseObject.informationEngagement.itemGroupStatus
              }
            }
          });
        } else {
          dispatch({
            type: GET_ITEMGROUPITEM_REVIEW_LIST_SAGA,
            payload: {
              request: {
                module: 'Item-Group',
                action: 'Review-List',
                actionFilter: 'Item',
                requestObject: obj
              },
              HeaderOne: 'assessees',
              BadgeOne: middlePaneHeaderBadgeOne,
              BadgeTwo: middlePaneHeaderBadgeTwo,
              BadgeThree: '',
              isMiddlePaneList: isMiddlePaneList
            }
          });
        }

        dispatch({ type: SET_PAGE_COUNT, payload: numberPage + 1 });
      }else{
        setHasMore(false)
      }
    }
  };

  // useEffect(() => {
  //   if (!isFetching) return;
  //   fetchMoreListItems();
  // }, [isFetching]);

  // const fetchMoreListItems = () => {
  //   fetchData();
  //   setIsFetching(false);
  // };

  // infinite scroll

  let elementRef = useRef(null);

  const onIntersection = (entries) => {
    const firstEntry = entries[0];
    relatedReviewListDistinctData[0].item.length >= scanCount ? setHasMore(false) : setHasMore(true)
    if (firstEntry.isIntersecting && hasMore && !isListLoading) {
      fetchData();
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(onIntersection, { threshold: 0.90 });

    if (observer && elementRef.current) {
      // console.log('here at observer...', elementRef.current);
      observer.observe(elementRef.current);
    }

    return () => {
      if (observer) observer.disconnect();
    };
  },  [isListLoading, hasMore, relatedReviewListDistinctData, isSelectActive, selectedTagsArray, unselectedTagsArray]);

  // infinite scroll end

  // this code for item type list
  return (
    <div>
      {listDistinctData && (
        <Card
          textOneOne={listDistinctData.itemGroupName}
          textTwoOne={listDistinctData.itemGroupDescription}
          IconOne={CrossIcon}
          isIcon={true}
          labelTwoTwo={'group'}
          onClickIconOne={onClickReviseCancel}
          isAlliance
          relatedCardFixed={true}
          className={'iguru-iconboxSVG'}
        />
      )}
      {listDistinctData &&
        listDistinctData.item.map((item, index) => {
          return (
            <div
              className="containerPadding"
              key={index}
              ref={elementRef}
            >
              <ReviewList
                className=""
                id={index}
                tag={item.id}
                associateId={item?.associateId}
                isSelectedReviewList={middlePaneSelectedValue === item.id}
                status={item.informationEngagement.itemStatus}
                isShared={item?.itemShared}
                actualStatus={item.informationEngagement.itemStatus}
                textOne={item.informationBasic.itemName}
                textTwo={item.informationBasic.itemDescription}
                isTooltipActive={false}
                onClickEvent={openListPopup}
                isSelectActive={isSelectActive}
                isSelected={selectedTagsArray.includes(item.id)}
                onClickCheckBox={(event) => {
                  !isListLoading && onClickCheckBoxSelection(selectedTagsArray, unselectedTagsArray, event, dispatch);
                }}
              />
            </div>
          );
        })}
      {isListLoading ? <div> <ListLoader /> </div> : <div style={{ height: "60px", width: "100%" }}></div>}
      {FilterMode === 'itemGroupItemeRevise' && (
        <FooterIconTwo
          FilterModeEnable={isShowReviseIcon}
          FilterMode={FilterMode}
          onClick={onClickRevise}
          primaryIcon={revisePrimaryIcon}
          secondaryIcon={reviseSecondaryIcons}
        />
      )}
      {FilterMode === 'itemGroupItemDistinctinactive' && (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={primaryIcon}
          secondaryIcon={secondaryIcon}
        />
      )}
    </div>
  );
};
export default ItemGroupItemDistinctReviewList;
