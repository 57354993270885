import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    ASSOCIATE_POPUP_CLOSE,
    FILTERMODE,
    FILTERMODE_ENABLE,
    GET_ASSESSEE_GROUP_REVIEW_LIST_SAGA,
    LOADER_START,
    POPUP_OPEN,
    SET_DISPLAY_TWO_SINGLE_STATE,
    SET_PAGE_COUNT,
    SET_POPUP_STATE,
    SET_REQUEST_OBJECT,
    ASSESSEE_ALLOCATE_GRP_SAGA,
    ASSESSEE_ALLOCATE_ASSIGNMENT_SAGA,
    SET_POPUP_SINGLE_STATE,
    CLEAR_DISPLAY_PANE_THREE,
    ASSESSEE_INFO_CREATE,
    SECTION_POPUP_CLOSE,
    SET_DISPLAY_PANE_THREE_STATE,
    ASSESSEE_MARKETPLACE_REVIEW_GROUP_SAGA
} from '../actionType';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import { FilterList } from '@material-ui/icons';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import { getPopUpOptionList, makeAssesseeGroupObj, makeAssesseeMarketplaceGroupReviewListRequestObject } from '../Actions/GenericActions';
import {
    ASSESSEE_GROUP_NODE_ROLE_REVIEW_LIST_POPUP_OPTION,
    ASSESSEES_SUBMODULE_REVIEW_LIST_POPUP_OPTION
} from '../PopUpConfig';
import {
    getAssesseeMarketplaceGroupApiCall,
    onClickCheckBoxSelection,
    onClickFlagSelection,
    setFlagedArray
} from '../Actions/AssesseeModuleAction';
import ReviseIcon from '@material-ui/icons/RadioButtonChecked';
import Check from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import SortableTree from 'react-sortable-tree';
import { AccountTree } from '@material-ui/icons';
import ListIcon from '@material-ui/icons/FormatListBulleted';
import 'react-sortable-tree/style.css';
import FileExplorerTheme from 'react-sortable-tree-theme-full-node-drag';
import ListLoader from '../Atoms/ListLoader/ListLoader';
const MarketplaceAssesseeGroupReviewList = (props) => {
    const dispatch = useDispatch();
    // const { secondaryOptionCheckValue } = useSelector((state) => state.AssesseeCreateReducer);
    const { cardValue } = useSelector((state) => state.PopUpReducer);
    const {
        numberPage,
        scanCount,
        countPage,
        reviewListDistinctData,
        reviewListReqObj,
        middlePaneSelectedValue,
        isSelectActive,
        selectedTagsArray,
        unselectedTagsArray,
        middlePaneHeader,
        middlePaneHeaderBadgeOne,
        middlePaneHeaderBadgeTwo,
        middlePaneHeaderBadgeThree,
        selectedAssociateInfo,
        allocatedTagsArray,
        allocateStr,
        selectedFlagedArray,
        unselectedFlagedArray,
        flagedValue,
        signedAssesseePermission,
        nodeViewState,
        scanString,
        searchFocusIndex,
        assignmentCreateType,
        isListLoading
    } = useSelector((state) => state.DisplayPaneTwoReducer);
    const { FilterModeEnable, FilterMode } = useSelector((state) => state.FilterReducer);
    // const [isFetching, setIsFetching] = useState(false);
    const [filterState, setFilterState] = useState('');
    const fetchData = async () => {
        if (reviewListDistinctData.length < scanCount && nodeViewState !== 'hierarchy') {
            dispatch({
                type: SET_DISPLAY_TWO_SINGLE_STATE,
                payload: { stateName: 'isListLoading', value: true }
            });
            let obj = {
                ...reviewListReqObj.requestObject,
                numberPage: numberPage + 1
            };
            dispatch({
                type: ASSESSEE_MARKETPLACE_REVIEW_GROUP_SAGA,
                payload: {
                    request: {
                        module: 'Assessee-Group',
                        action: 'Review-List',
                        actionFilter: 'AssesseeGroup-Shared-List',
                        requestObject: obj
                    },
                    BadgeOne: middlePaneHeaderBadgeOne,
                    BadgeTwo: middlePaneHeaderBadgeTwo,
                    BadgeThree: middlePaneHeaderBadgeThree,
                    isMiddlePaneList: true,
                    cardValue: cardValue,
                    isSelectActive: isSelectActive,
                    selectedTagsArray: selectedTagsArray
                }
            });
            dispatch({ type: SET_PAGE_COUNT, payload: numberPage + 1 });
        }
    };

    // useEffect(() => {
    //   console.log(reviewListDistinctData);
    //   if (!isFetching) return;
    //   fetchMoreListItems();
    // }, [isFetching]);

    // const fetchMoreListItems = () => {
    //   fetchData();
    //   setIsFetching(false);
    // };
    useEffect(() => {
        if (nodeViewState !== 'hierarchy')
            setFlagedArray(reviewListDistinctData, 'assesseeGroupFlag', dispatch);
    }, [reviewListDistinctData]);
    const siftApiCall = (siftKey, requestObect) => {
        // let requestObect = makeAssesseeGroupObj(selectedAssociateInfo, siftKey, 0, countPage);
        dispatch({ type: LOADER_START });
        dispatch({
            type: FILTERMODE,
            payload: { FilterMode: 'assesseesGroupDistinct' + siftKey }
        });
        dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        dispatch({
            type: ASSESSEE_MARKETPLACE_REVIEW_GROUP_SAGA,
            payload: {
                middlePaneHeader,
                request: requestObect,
                BadgeOne: middlePaneHeaderBadgeOne,
                BadgeTwo: middlePaneHeaderBadgeTwo === 'distinct' ? middlePaneHeaderBadgeTwo : siftKey,
                BadgeThree: middlePaneHeaderBadgeTwo === 'distinct' ? siftKey : middlePaneHeaderBadgeThree,
                isMiddlePaneList: true,
                cardValue: cardValue,
                isSelectActive: isSelectActive,
                selectedTagsArray: selectedTagsArray
            }
        });
        dispatch({
            type: SET_DISPLAY_PANE_THREE_STATE,
            payload: {
                isReviewRevise: false,
                headerOne: '',
                headerOneBadgeOne: '',
                headerOneBadgeTwo: '',
                headerOneBadgeThree: '',
                reviewMode: 'review'
            }
        });
        dispatch({ type: ASSOCIATE_POPUP_CLOSE });
        // document.getElementById('middleComponentId').scrollTop = '0px';
    };
    const onClickFooter = (e) => {
        let siftValue = e.currentTarget.getAttribute('data-value');
        dispatch({ type: FILTERMODE_ENABLE });
        setFilterState(siftValue);
        if (
            (siftValue === 'suspended' ||
                siftValue === 'terminated' ||
                siftValue === 'bespoke' ||
                siftValue === 'generic') &&
            nodeViewState === 'list'
        ) {
            let requestObect = makeAssesseeMarketplaceGroupReviewListRequestObject(
                selectedAssociateInfo,
                siftValue,
                0,
                countPage,
                assignmentCreateType,
                'list',
            );
            // setTimeout(function () {
            dispatch({ type: SET_PAGE_COUNT, payload: 0 });
            dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
            // }, 1000);
            siftApiCall(siftValue, requestObect);
        }
        if ((siftValue === 'bespoke' || siftValue === 'generic') && nodeViewState === 'hierarchy') {
            let requestObect = makeAssesseeMarketplaceGroupReviewListRequestObject(
                selectedAssociateInfo,
                siftValue,
                0,
                countPage,
                assignmentCreateType,
                'hierarchy',
            );
            dispatch({
                type: SET_DISPLAY_TWO_SINGLE_STATE,
                payload: { stateName: 'scanString', value: '' }
            });
            // setTimeout(function () {
            dispatch({ type: SET_PAGE_COUNT, payload: 0 });
            dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
            // }, 1000);
            siftApiCall(siftValue, requestObect);
        }

        if (siftValue === 'list' || siftValue === 'hierarchy') {
            dispatch({
                type: SET_DISPLAY_TWO_SINGLE_STATE,
                payload: { stateName: 'scanString', value: '' }
            });
            dispatch({
                type: SET_DISPLAY_TWO_SINGLE_STATE,
                payload: { stateName: 'nodeViewState', value: siftValue }
            });
            let badgeTwo =
                middlePaneHeaderBadgeTwo === 'generic' || middlePaneHeaderBadgeTwo === 'bespoke'
                    ? 'active'
                    : middlePaneHeaderBadgeTwo;

            if (cardValue === 'Card') {
                badgeTwo =
                    middlePaneHeaderBadgeThree === 'generic' || middlePaneHeaderBadgeThree === 'bespoke'
                        ? 'active'
                        : middlePaneHeaderBadgeThree;
            }
            dispatch({
                type: SET_POPUP_SINGLE_STATE,
                payload: { stateName: 'selectionValue', value: '' }
            });
            getAssesseeMarketplaceGroupApiCall(
                selectedAssociateInfo,
                badgeTwo,
                countPage,
                dispatch,
                'groups',
                cardValue,
                '',
                siftValue,
                [],
                assignmentCreateType,
                middlePaneHeader
            );
            dispatch({
                type: SET_DISPLAY_TWO_SINGLE_STATE,
                payload: { stateName: 'typeOfMiddlePaneList', value: '' }
            });
        }

        if (siftValue === 'finish') {
            // console.log('allocateStr', allocateStr);
            let distinctAllocateStr =
                allocateStr === 'assesseesdistinct'
                    ? 'assesseeDistinct'
                    : allocateStr === 'assesseesgroups'
                        ? 'assesseeGroup'
                        : allocateStr === 'assesseestypes'
                            ? 'assesseeType'
                            : allocateStr === 'assesseesroles'
                                ? 'assesseeRole'
                                : allocateStr === 'assesseesnodes'
                                    ? 'assesseeNode'
                                    : allocateStr === 'assignmentsdistinct'
                                        ? 'assignmentDistinct'
                                        : allocateStr === 'assignmentsgroups'
                                            ? 'assignmentGroup'
                                            : allocateStr === 'assignmentstypes'
                                                ? 'assignmentType'
                                                : allocateStr === 'assignmentsnodes'
                                                    ? 'assignmentNode'
                                                    : '';

            if (distinctAllocateStr !== '' && selectedTagsArray.length !== 0) {
                if (
                    distinctAllocateStr === 'assesseeDistinct' ||
                    distinctAllocateStr === 'assesseeGroup' ||
                    distinctAllocateStr === 'assesseeType' ||
                    distinctAllocateStr === 'assesseeRole' ||
                    distinctAllocateStr === 'assesseeNode'
                ) {
                    let request = {
                        module: 'Assessee-Distinct',
                        action: FilterMode.toLowerCase().includes('unallocate') ? 'unallocate' : 'allocate',
                        requestObject: {
                            assesseeId: selectedAssociateInfo?.assesseeId,
                            associateId:
                                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                                    .associateTagPrimary,
                            [FilterMode.toLowerCase().includes('unallocate')
                                ? 'assesseeDistinctUnallocate'
                                : 'assesseeDistinctAllocate']: {
                                [distinctAllocateStr]: allocatedTagsArray
                            },
                            [FilterMode.toLowerCase().includes('unallocate')
                                ? 'assesseeDistinctUnallocateInformation'
                                : 'assesseeDistinctAllocateInformation']: {
                                assesseeGroup: selectedTagsArray
                            }
                        }
                    };
                    dispatch({ type: LOADER_START });
                    dispatch({ type: ASSESSEE_ALLOCATE_GRP_SAGA, payload: { request: request } });
                }
                if (
                    distinctAllocateStr === 'assignmentDistinct' ||
                    distinctAllocateStr === 'assignmentGroup' ||
                    distinctAllocateStr === 'assignmentType' ||
                    distinctAllocateStr === 'assignmentNode'
                ) {
                    let request = {
                        module: 'Assessee-Distinct',
                        action: FilterMode.toLowerCase().includes('unallocate') ? 'unallocate' : 'allocate',
                        actionFilter: 'Assignment',
                        requestObject: {
                            assesseeId: selectedAssociateInfo?.assesseeId,
                            associateId:
                                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                                    .associateTagPrimary,
                            [FilterMode.toLowerCase().includes('unallocate')
                                ? 'assesseeDistinctUnallocate'
                                : 'assesseeDistinctAllocate']: {
                                //[distinctAllocateStr]: allocatedTagsArray
                                assesseeGroup: selectedTagsArray
                            },
                            [FilterMode.toLowerCase().includes('unallocate')
                                ? 'assesseeDistinctUnallocateInformation'
                                : 'assesseeDistinctAllocateInformation']: {
                                //assignmentGroup: selectedTagsArray
                                [distinctAllocateStr === 'assignmentDistinct' ? 'assignment' : distinctAllocateStr]:
                                    allocatedTagsArray
                            }
                        }
                    };
                    dispatch({ type: LOADER_START });
                    dispatch({ type: ASSESSEE_ALLOCATE_ASSIGNMENT_SAGA, payload: { request: request } });
                }
            }
        }
        if (siftValue === 'cancel') {
            dispatch({
                type: SET_DISPLAY_TWO_SINGLE_STATE,
                payload: { stateName: 'isSelectActive', value: '' }
            });
            dispatch({
                type: FILTERMODE,
                payload: { FilterMode: '' }
            });
        }
    };
    /* for middle pane */
    const secondaryIconOne = [
        { label: 'suspended', onClick: onClickFooter, Icon: FilterList },
        { label: 'terminated', onClick: onClickFooter, Icon: FilterList }
    ];
    const secondaryOneIconSift = [
        { label: 'bespoke', onClick: onClickFooter, Icon: FilterList },
        { label: 'generic', onClick: onClickFooter, Icon: FilterList }
    ];

    /* for middle pane */
    const primaryIconOne = [
        // { label: 'sift', onClick: onClickFooter, Icon: FilterList },
        { label: 'view', onClick: onClickFooter, Icon: FilterList }
    ];
    /* for middle pane */
    const primaryIcon = [{ label: 'sift', onClick: onClickFooter, Icon: FilterList }];
    const secondaryIcon = [
        { label: 'hierarchy', onClick: onClickFooter, Icon: AccountTree },
        { label: 'list', onClick: onClickFooter, Icon: ListIcon }
    ];

    let signedAssociateId =
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary;

    const openListPopup = (node, e, target, canUpdate) => {
        let tempArr = [];

        let classification = e.currentTarget.getAttribute('data-classification');
        let isShared = e.currentTarget.getAttribute('data-shared');
        let associateId = e.currentTarget.getAttribute('associateid');
        let groupId = e.currentTarget.getAttribute('tag');
        let status = e.currentTarget.getAttribute('status');
        let flaged = e.currentTarget.getAttribute('data-flag') === 'true' && true;
        console.log('node', node);
        if (target === 'hierarchy') {
            groupId = node.node.id;
            status = node.node.status;
            flaged = node.node.flag;
            isShared = node.node.assesseeGroupShared;
            classification = node.node.classification;
            associateId = node.node.associateId;
        }
        // console.log('********', node);
        let assesseeDistinctPermission =
            signedAssesseePermission?.assesseeDistinctPermission?.assesseeAssesseeGroupPermission;
        let popupArr = getPopUpOptionList(
            cardValue === 'Card'
                ? ASSESSEES_SUBMODULE_REVIEW_LIST_POPUP_OPTION
                : ASSESSEE_GROUP_NODE_ROLE_REVIEW_LIST_POPUP_OPTION,
            assesseeDistinctPermission
        );
        let popupContentArrValue = popupArr.map((obj) =>
            obj.data === 'assessees'
                ? { ...obj, data: middlePaneHeader, dataValue: middlePaneHeader }
                : obj
        );
        //ASSESSEE_GROUP_NODE_ROLE_REVIEW_LIST_POPUP_OPTION.map((element) => {
        popupContentArrValue.map((element) => {
            if (classification === 'Bespoke' && element.data === 'share' && status === 'ACTIVE')
                tempArr.push({ ...element, disabled: true });
            else if (
                (isShared === 'true' || isShared) &&
                associateId !== signedAssociateId &&
                (element.data === 'archive' ||
                    element.data === 'delete' ||
                    element.data === 'flag' ||
                    element.data === 'publish' ||
                    element.data === 'suspend' ||
                    element.data === 'terminate')
            ) {
                tempArr.push({ ...element, disabled: true });
            } else if (
                (status === 'TERMINATED' || status === 'SUSPENDED' || status === 'ARCHIVED') &&
                (element.data === 'allocate' ||
                    element.data === 'revise' ||
                    element.data === 'select' ||
                    element.data === 'share')
            ) {
                tempArr.push({ ...element, disabled: true });
            } else tempArr.push(element);
        });
        dispatch({
            type: SET_POPUP_STATE,
            payload: {
                popupHeaderOne: middlePaneHeader || 'assessees',
                popupHeaderOneBadgeOne: 'group',
                duplicateHeaderOneBadgeTwo: middlePaneHeaderBadgeTwo,
                popupHeaderOneBadgeTwo: '',
                isPopUpValue: '',
                popupOpenType: 'primary',
                popupContentArrValue: tempArr,
                originPrimaryArrOprion: tempArr,
                selectedTagValue: groupId,
                selectedTagStatus: status,
                isShared: isShared,
                isFlaged: flaged === 'true' ? true : false
            }
        });
        dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: {
                stateName: 'middlePaneListPopupOptions',
                value: tempArr
            }
        });
        dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
    };
    const changedNode = (node) => {
        // console.log('********', node.id);
        let dragedNodeId = node.id;
        let dragedNodeParentId = '';
        reviewListDistinctData.map((nodeData) => {
            // console.log('nodeData', nodeData);
            nodeData.children.map((chnode) => {
                // console.log(chnode.id);
                if (dragedNodeId === chnode.id) {
                    dragedNodeParentId = nodeData.id;
                }
            });
        });
    };

    // infinite scroll
    const [hasMore, setHasMore] = useState(true);
    let elementRef = useRef(null);

    const onIntersection = (entries) => {
        const firstEntry = entries[0];
        reviewListDistinctData.length >= scanCount ? setHasMore(false) : setHasMore(true);
        if (firstEntry.isIntersecting && hasMore && !isListLoading) {
            fetchData();
        }
    };

    useEffect(() => {
        // console.log('####MYREF######', elementRef, scanCount)
        if (nodeViewState !== 'hierarchy') {
            const observer = new IntersectionObserver(onIntersection, { threshold: 0.90 });

            if (observer && elementRef.current) {
                // console.log('here at observer...', elementRef.current)
                observer.observe(elementRef.current);
            }

            return () => {
                if (observer) observer.disconnect();
            };
        }
    }, [
        isListLoading,
        hasMore,
        reviewListDistinctData,
        selectedTagsArray,
        unselectedTagsArray,
        isSelectActive
    ]);

    // infinite scroll end
    console.debug({ nodeViewState })

    // console.log('nodeViewState', nodeViewState);
    const customSearchMethod = ({ node, searchQuery }) =>
        searchQuery &&
        (node.title?.toLowerCase().indexOf(searchQuery?.toLowerCase()) > -1 ||
            node.subtitle?.toLowerCase().indexOf(searchQuery?.toLowerCase()) > -1);
    return (
        <div>
            {reviewListDistinctData && nodeViewState === 'hierarchy' ? (
                <div style={{ minheight: 'calc(100vh - 135px)' }} key={scanString}>
                    <SortableTree
                        treeData={reviewListDistinctData}
                        onChange={(treeData) => {
                            treeData.length === 1 &&
                                dispatch({
                                    type: SET_DISPLAY_TWO_SINGLE_STATE,
                                    payload: { stateName: 'reviewListDistinctData', value: treeData }
                                });
                        }}
                        searchMethod={customSearchMethod}
                        searchQuery={scanString}
                        searchFocusOffset={searchFocusIndex}
                        // canDrag={({ node }) => true && node.parentId !== null}
                        onMoveNode={({ node }) => changedNode(node)}
                        searchFinishCallback={(matches) => {
                            // console.log(matches);
                            dispatch({
                                type: SET_DISPLAY_TWO_SINGLE_STATE,
                                payload: {
                                    stateName: 'searchFocusIndex',
                                    value: matches.length > 0 ? searchFocusIndex % matches.length : 0
                                }
                            });
                        }}
                        theme={FileExplorerTheme}
                        isVirtualized={false}
                        rowHeight={55}
                        scaffoldBlockPxWidth={31}
                        slideRegionSize={50}
                        generateNodeProps={(node) => ({
                            onClick: (event) => {
                                if (event.target.type !== 'button' && node.node.title) {
                                    // openListPopup(node, event, 'hierarchy', true);
                                    //  openListHierarchyPopup(node,event, 'hierarchy', true);
                                }
                            }
                        })}
                    />
                </div>
            ) : (
                reviewListDistinctData.map((item, index) => {
                    return (
                        <div className="containerPadding" key={index} ref={elementRef}>
                            <ReviewList
                                className=""
                                id={index}
                                tag={item.id}
                                associateId={item?.associateId}
                                isSelectedReviewList={middlePaneSelectedValue === item.id}
                                //status={item.informationEngagement.assesseeGroupStatus}
                                status={
                                    FilterMode === 'assesseesGroupDistinctactive' ||
                                        FilterMode === 'assesseesGroupDistinctbespoke' ||
                                        FilterMode === 'assesseesGroupDistinctgeneric'
                                        ? item.informationSetup?.assesseeGroupClassification
                                            ?.assesseeGroupClassificationPrimary
                                        : item.informationEngagement?.assesseeGroupStatus
                                }
                                actualStatus={item.informationEngagement?.assesseeGroupStatus}
                                textOne={item.informationBasic?.assesseeGroupName}
                                textTwo={item.informationBasic?.assesseeGroupDescription}
                                isTooltipActive={false}
                                isAlertActive={false}
                                // onClickEvent={(event) => {
                                //     openListPopup(item.id, event, 'list', true);
                                // }}
                                // disableonClickCheckBox={item.associateId !== signedAssociateId ? true : false}
                                // onClickEvent={openListPopup}
                                isSelectActive={isSelectActive}
                                isSelected={selectedTagsArray.includes(item.id)}
                                onClickCheckBox={(event) => {
                                    !isListLoading &&
                                        onClickCheckBoxSelection(
                                            selectedTagsArray,
                                            unselectedTagsArray,
                                            event,
                                            dispatch
                                        );
                                }}
                                isShared={item?.assesseeGroupShared}
                                //shared={item?.assesseeGroupShared ? 'SHARED' : 'UNSHARED'}
                                shared={
                                    item?.informationSetup?.assesseeGroupClassification
                                        ?.assesseeGroupClassificationPrimary
                                }
                                isFlagActive={selectedFlagedArray.includes(item.id)}
                                flagedValue={flagedValue}
                                onClickAddFladed={(event) => {
                                    onClickFlagSelection(selectedFlagedArray, unselectedFlagedArray, event, dispatch);
                                }}
                            />
                        </div>
                    );
                })
            )}
            {isListLoading ? (
                <div>
                    {' '}
                    <ListLoader />{' '}
                </div>
            ) : <div style={{ height: "60px", width: "100%" }}></div>}
            {(FilterMode === 'assesseesGroupDistinctinactive' ||
                FilterMode === 'assesseesGroupDistinctterminated' ||
                FilterMode === 'assesseesGroupDistinctsuspended') && (
                    <FooterIconTwo
                        FilterModeEnable={FilterModeEnable}
                        FilterMode={FilterMode}
                        onClick={onClickFooter}
                        primaryIcon={primaryIcon}
                        secondaryIcon={secondaryIconOne}
                    />
                )}
            {(FilterMode === 'assesseesGroupDistinctactive' ||
                FilterMode === 'assesseesGroupDistinctactivegeneric' ||
                FilterMode === 'assesseesGroupDistinctactivebespoke' ||
                FilterMode === 'assesseesGroupDistinctgeneric' ||
                FilterMode === 'assesseesGroupDistinctbespoke') && (
                    <FooterIconTwo
                        FilterModeEnable={FilterModeEnable}
                        FilterMode={FilterMode}
                        onClick={onClickFooter}
                        primaryIcon={primaryIconOne}
                        secondaryIcon={filterState === 'sift' ? secondaryOneIconSift : secondaryIcon}
                    />
                )}
            {/* {(FilterMode === 'assesseeAllocateToGroup' || FilterMode === 'assesseeUnAllocateToGroup') && (
                <FooterIconTwo
                    FilterModeEnable={FilterModeEnable}
                    FilterMode={FilterMode}
                    onClick={onClickFooter}
                    primaryIcon={[
                        FilterMode === 'assesseeAllocateToGroup'
                            ? { label: 'allocate', onClick: onClickFooter, Icon: ReviseIcon }
                            : { label: 'unallocate', onClick: onClickFooter, Icon: ReviseIcon }
                    ]}
                    secondaryIcon={[
                        { label: 'cancel', onClick: onClickFooter, Icon: ClearIcon },
                        { label: 'finish', onClick: onClickFooter, Icon: Check }
                    ]}
                />
            )} */}
        </div>
    );
};
export default MarketplaceAssesseeGroupReviewList;