import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import SendIcon from '@material-ui/icons/Send';
import iGuruLogo from '../../images/iglogo1.png';
import './DisplayPageSignIn.css';
import { FormControl, IconButton, InputLabel } from '@material-ui/core';
import InputField from '../../Atoms/InputField/InputField';
import Label from '../../Atoms/Label/Label';
import {
  INCORRECT_INFORMATION_ERROR_MESSAGE,
  INFORMATION_MISMATCHED_ERROR_MESSAGE,
  INVALID_PASSWORD_ERROR_MESSAGE,
  LOWER_CHARACTER_REQUIRED_ERROR,
  MINIMUM_8_CHARACTER_ERROR,
  NUMBER_REQUIRED_ERROR,
  REQUIRED_ERROR_MESSAGE,
  SPECIAL_CHARACTER_REQUIRED_ERROR,
  UPPER_CHARACTER_REQUIRED_ERROR
} from '../../errorMessage';
import { useDispatch, useSelector } from 'react-redux';
import {
  ASSESSEE_SIGN_IN_SAGA,
  RESET_ALL_REDUCER,
  SET_SIGN_IN_STATUS,
  SEND_AUTH_CODE_FORGOT_PASS,
  FORGOT_PASSWORD_SAGA,
  SEND_FORGOT_CREDENTIAL_SAGA,
  LOADER_START,
  SET_DISPLAY_TWO_SINGLE_STATE,
  CLEAR_PANE_TWO_REDUCER
} from '../../actionType';
import LoadingComponent from '../../PopUpInformation/LoadingComponent';
import { Fragment } from 'react';
import { SIGN_IN_URL } from '../../endpoints';
import SelectField from '../../Atoms/SelectField/SelectField';
import ReviewList from '../../Molecules/ReviewList/ReviewList';
import InputFieldPassword from '../../Atoms/InputField/InputFieldPassword';

const DisplayPageSignIn = () => {
  const bgImg = './Image/bg.jpg';
  const style = {
    backgroundPosition: '50% 50%',
    backgroundImage: `url(${bgImg})`,
    backgroundSize: 'cover'
  };
  const history = useHistory();
  
  const [stage, setStage] = useState('signIn'); // 'forgotPassword' 'confirmPassword'
  const [isCredentialsInValid, setIsCredentialsInValid] = useState('');
  const [isUserNameValid, setIsUserNameValid] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState('');
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [forgotCredential, setForgotCredential] = useState('');
  const [forgotCredentialError, setForgotCredentialError] = useState('');
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState('');
  const [revisedPassword, setRevisedPassword] = useState('');
  const [revisedPasswordError, setRevisedPasswordError] = useState('');
  const [confirmRevisedPassword, setConfirmRevisedPassword] = useState('');
  const [confirmRevisedPasswordError, setConfirmRevisedPasswordError] = useState('');
  const [credentialOptionError, setCredentialOptionError] = useState('');
  const [credentialOption, setCredentialOption] = useState('');
  const dispatch = useDispatch();
  const { assesseeSignInStatus, credentialOptionArr, redirect_url } = useSelector((state) => state.UserReducer);
  const { middlePaneSelectedValue, selectedAssociateInfo } = useSelector(
    (state) => state.DisplayPaneTwoReducer
  );
  const { isLoading } = useSelector((state) => state.LoaderReducer);
  // console.log(history, assesseeSignInStatus,' #######');


  useEffect(()=>{
    if(redirect_url !== ""){
      // window.location.replace(redirect_url.replaceAll("https://lite.insightguru.com/sign-in", "http://localhost:3001/sign-in"))
      window.location.replace(redirect_url)
    }
  }, [redirect_url])

  useEffect(() => {
    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refreshToken');
    const userConfirmed = localStorage.getItem('userConfirmed');
    console.log('credentialOptionArr', credentialOptionArr);
    console.log('assesseeSignInStatus', assesseeSignInStatus);
    if (token && refreshToken && userConfirmed === 'true') {
      // console.log('this is EXEXUTUING 70', token, '**', refreshToken, '*', userConfirmed, )
      // if (token && refreshToken) {
      let path = `/dashboard`;
      history.push(path);
    } else {
      // console.log('here...75')
      if (assesseeSignInStatus === 'success') {
        setIsCredentialsInValid('');
        let path = `/dashboard`;
        history.push(path);
        window.location.reload();
        dispatch({ type: RESET_ALL_REDUCER });
        dispatch({ type: SET_SIGN_IN_STATUS, payload: '' });
      }
      if (assesseeSignInStatus === 'confirmUSer') {
        setIsCredentialsInValid('');
        let path = '/assessee/' + userName;
        history.push(path);
        dispatch({ type: SET_SIGN_IN_STATUS, payload: '' });
      }
      if (assesseeSignInStatus === 'error') {
        setIsCredentialsInValid(INCORRECT_INFORMATION_ERROR_MESSAGE);
        dispatch({ type: SET_SIGN_IN_STATUS, payload: '' });
      }
      if (assesseeSignInStatus === 'AUTH_CODE_SEND') {
        setStage('confirmPassword');
        dispatch({ type: SET_SIGN_IN_STATUS, payload: '' });
        setIsCredentialsInValid('');
      }
      if (assesseeSignInStatus === 'CREDENTIAL_SEND') {
        if (credentialOptionArr.length > 0) {
          setStage('credentialOptions');
          dispatch({ type: SET_SIGN_IN_STATUS, payload: '' });
          setIsCredentialsInValid('');
        } else {
          setIsCredentialsInValid(INCORRECT_INFORMATION_ERROR_MESSAGE);
        }
      }
      if (assesseeSignInStatus === 'CODE 400') {
        setCodeError(INCORRECT_INFORMATION_ERROR_MESSAGE);
        dispatch({ type: SET_SIGN_IN_STATUS, payload: '' });
        setIsCredentialsInValid('');
      }
      if (assesseeSignInStatus === 'CODE 001') {
        setForgotCredentialError(INCORRECT_INFORMATION_ERROR_MESSAGE);
        dispatch({ type: SET_SIGN_IN_STATUS, payload: '' });
        setIsCredentialsInValid('');
      }
      if (assesseeSignInStatus === 'PASSWORD_UPDATED') {
        let path = SIGN_IN_URL;
        history.push(path);
        window.location.reload();
      }
      if (assesseeSignInStatus === 'responseError') {
        let responseError = localStorage.getItem('responseError');
        setIsCredentialsInValid(responseError);
        dispatch({ type: SET_SIGN_IN_STATUS, payload: '' });
      }
    }
  }, [assesseeSignInStatus, credentialOptionArr]);

  const validatePassword = () => {
    const passwordRegExp = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!{~:<-_?>}@#$%^&*])(?=.{8,})'
    );
    const numberRegExp = new RegExp('[0-9]');
    const capitalLetterRegExp = new RegExp('[A-Z]');
    const smallLetterRegExp = new RegExp('[a-z]');
    const symbolLetterRegExp = /[!"#$%&'()*+.\/:;<=>?@\[\\\]^_`{|}~-]/;
    let isValidation = true;
    if (revisedPassword !== '' && confirmRevisedPassword !== '') {
      setRevisedPasswordError("");
      setConfirmRevisedPasswordError("");

      //if both passwotrd is not eual
      if (revisedPassword !== confirmRevisedPassword) {
        setRevisedPasswordError(INFORMATION_MISMATCHED_ERROR_MESSAGE);
        setConfirmRevisedPasswordError(INFORMATION_MISMATCHED_ERROR_MESSAGE);
        isValidation = false;
      } else {
        //for password pwd
        // if (!passwordRegExp.test(newPassword1)) {
        if (!numberRegExp.test(revisedPassword)) {
          setRevisedPasswordError(NUMBER_REQUIRED_ERROR);
          isValidation = false;
        }
        if (!smallLetterRegExp.test(revisedPassword)) {
          setRevisedPasswordError(LOWER_CHARACTER_REQUIRED_ERROR);
          isValidation = false;
        }
        if (!capitalLetterRegExp.test(revisedPassword)) {
          setRevisedPasswordError(UPPER_CHARACTER_REQUIRED_ERROR);
          isValidation = false;
        }
        if (!symbolLetterRegExp.test(revisedPassword)) {
          setRevisedPasswordError(SPECIAL_CHARACTER_REQUIRED_ERROR);
          isValidation = false;
        }
        if (revisedPassword.length < 8) {
          setRevisedPasswordError(MINIMUM_8_CHARACTER_ERROR);
          isValidation = false;
        }
        // }
        //for confirmed pwd
        // if (!passwordRegExp.test(confirmRevisedPassword1)) {
        if (!numberRegExp.test(confirmRevisedPassword)) {
          setConfirmRevisedPasswordError(NUMBER_REQUIRED_ERROR);
          isValidation = false;
        }
        if (!smallLetterRegExp.test(confirmRevisedPassword)) {
          setConfirmRevisedPasswordError(LOWER_CHARACTER_REQUIRED_ERROR);
          isValidation = false;
        }
        if (!capitalLetterRegExp.test(confirmRevisedPassword)) {
          setConfirmRevisedPasswordError(UPPER_CHARACTER_REQUIRED_ERROR);
          isValidation = false;
        }
        if (!symbolLetterRegExp.test(confirmRevisedPassword)) {
          setConfirmRevisedPasswordError(SPECIAL_CHARACTER_REQUIRED_ERROR);
          isValidation = false;
        }
        if (confirmRevisedPassword.length < 8) {
          setConfirmRevisedPasswordError(MINIMUM_8_CHARACTER_ERROR);
          isValidation = false;
        }
        // }
      }

      return isValidation;

    } else {
      setRevisedPasswordError(REQUIRED_ERROR_MESSAGE);
      setConfirmRevisedPasswordError(REQUIRED_ERROR_MESSAGE);
      isValidation = false;
      return isValidation;
    }

  }

  const onClickSignIn = () => {
    setIsCredentialsInValid('in progress');
    dispatch({ type: CLEAR_PANE_TWO_REDUCER });
    if (userName && password) {
      setIsPasswordValid('');
      setIsUserNameValid('');
      localStorage.clear();
      dispatch({
        type: ASSESSEE_SIGN_IN_SAGA,
        payload: {
          request: {
            module: 'Iguru-Iglobal',
            // action: 'Sign-In',
            action: 'redirect-sign-in',
            requestObject: {
              credential: userName.trim(), // "607888f3226fc201a533a924",
              password: password.trim() //"NG36#:dqMO",
            }
          },
          isfromSignPage: true
        }
      });
      // authenticate(userName, password)
      //   .then((data) => {
      //     setIsCredentialsInValid('');
      //     console.log('OnSuccess===>', data);
      //     let path = `/dashboard`;
      //     history.push(path);
      //   })
      //   .catch((err) => {
      //     setIsCredentialsInValid(INCORRECT_INFORMATION_ERROR_MESSAGE);
      //     console.log('onFailure===>', err);
      //   });
    } else {
      setIsCredentialsInValid('');
      if (userName === '') {
        setIsUserNameValid(REQUIRED_ERROR_MESSAGE);
      } else {
        setIsUserNameValid('');
      }
      if (password === '') {
        setIsPasswordValid(REQUIRED_ERROR_MESSAGE);
      } else {
        setIsPasswordValid('');
      }
    }
  };
  const sendCode = () => {
    console.log('sendCode+++++++', forgotCredential, credentialOption);
    if (forgotCredential === '') {
      setForgotCredentialError(REQUIRED_ERROR_MESSAGE);
      // return;
    }
    if (credentialOption === '') {
      setCredentialOptionError(REQUIRED_ERROR_MESSAGE);
      // return;
    }
    if (credentialOption === 'password' && forgotCredential !== '' && credentialOption !== '') {
      // dispatch({ type: LOADER_START });
      setIsCredentialsInValid('in progress');
      dispatch({
        type: SEND_AUTH_CODE_FORGOT_PASS,
        payload: {
          request: {
            module: 'Iguru-Iglobal',
            action: 'Forget',
            requestObject: {
              assesseeSignInCredential: forgotCredential
            }
          }
        }
      });
      setForgotCredentialError('');
    }
    if (credentialOption === 'credential' && forgotCredential !== '' && credentialOption !== '') {
      // dispatch({ type: LOADER_START });
      setIsCredentialsInValid('in progress');
      dispatch({ type: SEND_FORGOT_CREDENTIAL_SAGA, payload: { assesseeEmail: forgotCredential } });
    }
  };

  const resetPassword = () => {
    console.log('resetPassword+++++++');
    const passwordRegExp = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!{~:<-_?>}@#$%^&*])(?=.{8,})'
    );
    if (code !== '' && revisedPassword !== '' && confirmRevisedPassword !== '') {

      // if (!passwordRegExp.test(revisedPassword)) {
      //   setRevisedPasswordError(INVALID_PASSWORD_ERROR_MESSAGE);
      //   setConfirmRevisedPasswordError(INVALID_PASSWORD_ERROR_MESSAGE);
      //   return;
      // }

      if(!validatePassword()){
        return
      }

      if (revisedPassword === confirmRevisedPassword) {
        setRevisedPasswordError('');
        setConfirmRevisedPasswordError('');
        setCodeError('');
        //code for forgot password
        dispatch({ type: LOADER_START });
        dispatch({
          type: FORGOT_PASSWORD_SAGA,
          payload: {
            request: {
              module: 'Iguru-Iglobal',
              action: 'Confirm',
              requestObject: {
                assesseeSignInCredential: forgotCredential,
                assesseeSignInPassword: confirmRevisedPassword,
                assesseeAuthenticationCode: code
              }
            }
          }
        });
        console.log('========', code, revisedPassword, confirmRevisedPassword);
      } else {
        setRevisedPasswordError(INFORMATION_MISMATCHED_ERROR_MESSAGE);
        setConfirmRevisedPasswordError(INFORMATION_MISMATCHED_ERROR_MESSAGE);
      }
    } else {
      if (code === '') {
        setCodeError(REQUIRED_ERROR_MESSAGE);
      }
      if (revisedPassword === '') {
        setRevisedPasswordError(REQUIRED_ERROR_MESSAGE);
      }
      if (confirmRevisedPassword === '') {
        setConfirmRevisedPasswordError(REQUIRED_ERROR_MESSAGE);
      }
      console.log('ALL Field requred');
    }
  };
  const resetCredential = () => {
    setStage('signIn');
    setUserName(middlePaneSelectedValue);
    setPassword('');
    setCredentialOption('');
    setForgotCredential('');
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'middlePaneSelectedValue', value: '' }
    });
  };
  const onClickList = (e) => {
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'middlePaneSelectedValue', value: e.currentTarget.getAttribute('tag') }
    });
  };
  return (
    <Fragment>
      {isLoading && <LoadingComponent isActive={true} />}

      <div style={style} className="signin-container">
        <div className="form-box">
          <div className="form-header">
            <div className="inner-form-header">
              <div className="form-header-logo-container">
                <img className="form-header-logo-img" src={iGuruLogo} alt="iGuru logo" />
              </div>
              <div>
                {stage === 'signIn' && (
                  <IconButton className="form-icon-style" onClick={onClickSignIn}>
                    <SendIcon style={{ height: 20, width: 20 }} />
                  </IconButton>
                )}
                {stage === 'forgotPassword' && (
                  <>
                    <IconButton
                      onClick={() => {
                        setStage('signIn');
                        setIsUserNameValid('');
                        setIsPasswordValid('');
                        setIsCredentialsInValid('');
                      }}
                      className="form-icon-style rotate-icon"
                    >
                      <SendIcon style={{ height: 20, width: 20 }} />
                    </IconButton>
                    <IconButton className="form-icon-style" onClick={sendCode}>
                      <SendIcon style={{ height: 20, width: 20 }} />
                    </IconButton>
                  </>
                )}
                {stage === 'confirmPassword' && (
                  <>
                    <IconButton
                      onClick={() => {
                        setStage('forgotPassword');
                      }}
                      className="form-icon-style rotate-icon"
                    >
                      <SendIcon style={{ height: 20, width: 20 }} />
                    </IconButton>
                    <IconButton className="form-icon-style" onClick={resetPassword}>
                      <SendIcon style={{ height: 20, width: 20 }} />
                    </IconButton>
                  </>
                )}
                {stage === 'credentialOptions' && (
                  <>
                    <IconButton
                      onClick={() => {
                        setStage('forgotPassword');
                      }}
                      className="form-icon-style rotate-icon"
                    >
                      <SendIcon style={{ height: 20, width: 20 }} />
                    </IconButton>
                    <IconButton className="form-icon-style" onClick={resetCredential}>
                      <SendIcon style={{ height: 20, width: 20 }} />
                    </IconButton>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="sign-in-form-inputs-cantainer">
            {stage === 'signIn' && (
              <>
                <div className={'fitContent'}>
                  <div className={['PopupFormBox', 'labelPopupBox', 'popupMinHei'].join(' ')}>
                    <InputLabel htmlFor="name-input" className={'textForLabelPopup'}>
                      <span>{'sign-in'}&nbsp;</span>
                    </InputLabel>
                    {/* <div className={'infoSymbol'}></div>
              <div className={'infoSymbol'}>
                <InfoToolTip message="Click me, I will stay visible until you click outside." />
              </div> */}
                  </div>
                </div>
                <InputField
                  className=""
                  label="credential"
                  type="text"
                  errorMsg={isUserNameValid}
                  value={userName}
                  onClick={(e) => {
                    setIsUserNameValid('');
                    setIsCredentialsInValid('');
                    setUserName(e.target.value);
                  }}
                />
                <InputFieldPassword
                  className=""
                  label="password"
                  type="password"
                  errorMsg={isPasswordValid}
                  value={password}
                  onClick={(e) => {
                    setIsPasswordValid('');
                    setIsCredentialsInValid('');
                    setPassword(e.target.value);
                  }}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    margin: '0 5px'
                  }}
                >
                  <div
                    style={{ cursor: 'pointer', width: 'fit-content' }}
                    onClick={() => {
                      // setIsForgotPassword(true);
                      setStage('forgotPassword');
                      setCredentialOptionError('');
                      setForgotCredentialError('');
                      setIsCredentialsInValid('');
                    }}
                  >
                    <Label text="forgot information" fontSize="1.2rem" colour="#0000008a" />
                  </div>
                  <div>
                    {isCredentialsInValid && (
                      <Label
                        text={isCredentialsInValid}
                        fontSize="1.2rem"
                        colour={
                          isCredentialsInValid === 'in progress' ? '#7DC832' : 'rgb(244, 67, 54)'
                        }
                      />
                    )}
                  </div>
                </div>
              </>
            )}
            {stage === 'forgotPassword' && (
              <>
                <div className={'fitContent'}>
                  <div className={['PopupFormBox', 'labelPopupBox', 'popupMinHei'].join(' ')}>
                    <InputLabel htmlFor="name-input" className={'textForLabelPopup'}>
                      <span>{'information'}&nbsp;</span>
                    </InputLabel>
                    {/* <div className={'infoSymbol'}></div>
              <div className={'infoSymbol'}>
                <InfoToolTip message="Click me, I will stay visible until you click outside." />
              </div> */}
                  </div>
                </div>
                <FormControl style={{ width: '100%' }}>
                  <div style={{ height: '70.5px' }}>
                    <SelectField
                      tag={'assesseeSignInInformation'}
                      label={'forgotten'}
                      listSelect={[
                        { id: '', name: '' },
                        { id: 'credential', name: 'credential' },
                        { id: 'password', name: 'password' }
                      ]}
                      mappingValue={'id'}
                      errorMsg={credentialOptionError}
                      onChange={(e) => {
                        setCredentialOption(e.target.value);
                        setCredentialOptionError('');
                        setForgotCredentialError('');
                        setIsCredentialsInValid('');
                      }}
                      value={credentialOption}
                    />
                  </div>
                  <InputField
                    id={'forgotCredential'}
                    value={forgotCredential}
                    label={'unforgotten'}
                    type="text"
                    errorMsg={
                      forgotCredentialError !== ''
                        ? forgotCredentialError
                        : credentialOption === 'credential'
                          ? 'email address'
                          : credentialOption === 'password'
                            ? 'email address, mobile telephone, tag'
                            : ''
                    }
                    isErrorMsg={
                      forgotCredentialError ? true : credentialOption !== '' ? false : false
                    }
                    onClick={(e) => {
                      setForgotCredentialError('');
                      setForgotCredential(e.target.value);
                    }}
                  />
                  <div className={'forgot-and-progress-flex'}>
                    <div></div>
                    <div>
                      {/* {isCredentialsInValid && ( */}
                      <Label
                        text={isCredentialsInValid}
                        fontSize="1.2rem"
                        colour={
                          isCredentialsInValid === 'in progress' ? '#7DC832' : 'rgb(244, 67, 54)'
                        }
                      />
                      {/* )} */}
                    </div>
                  </div>
                </FormControl>
              </>
            )}
            {stage === 'confirmPassword' && (
              <FormControl style={{ width: '100%' }}>
                <InputField
                  id={'code'}
                  value={code}
                  label={'authentication code'}
                  type="text"
                  errorMsg={codeError}
                  onClick={(e) => {
                    setCode(e.target.value);
                    setCodeError('');
                  }}
                />
                <InputFieldPassword
                  id={'revisedpassword'}
                  value={revisedPassword}
                  type="password"
                  label={'revised password'}
                  errorMsg={revisedPasswordError}
                  onClick={(e) => {
                    setRevisedPassword(e.target.value);
                    setRevisedPasswordError('');
                  }}
                />
                <InputFieldPassword
                  id={'revised_password'}
                  value={confirmRevisedPassword}
                  type="password"
                  label={'revised password'}
                  errorMsg={confirmRevisedPasswordError}
                  onClick={(e) => {
                    setConfirmRevisedPassword(e.target.value);
                    setConfirmRevisedPasswordError('');
                  }}
                />
              </FormControl>
            )}
            {stage === 'credentialOptions' && (
              <>
                <FormControl style={{ width: '100%' }}>
                  <div className='credentialList' >
                    {credentialOptionArr.map((item, index) => {
                      return (
                        <div className="containerPadding" key={index}>
                          <ReviewList
                            className=""
                            id={index}
                            tag={item.username}
                            isSelectedReviewList={middlePaneSelectedValue === item.username}
                            // status={item.informationEngagement.itemStatus}
                            // actualStatus={item.informationEngagement.itemStatus}
                            textOne={item.username}
                            textTwo={''}
                            isTooltipActive={false}
                            onClickEvent={onClickList}
                          />
                        </div>
                      );
                    })}
                  </div>
                </FormControl>
              </>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DisplayPageSignIn;
