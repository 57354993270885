import React, { useState } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import Popup from '../Molecules/PopUp/PopUp';
import PopupHeader from '../Molecules/PopUp/PopUpHeader';
import FormControl from '@material-ui/core/FormControl';
import InputFeild from '../Atoms/InputField/InputField';
import '../Molecules/PopUp/PopUp.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  ASSESSEE_INFO_CREATE,
  ASSESSEE_MINE_ASSIGNMENT_LIST,
  ASSESSEE_REVIEW_DISTINCT_SAGA,
  ASSESSMENT_REVIEW_DISTINCT_SAGA,
  ASSIGNMENT_REVIEW_LIST_DISTINCT_SAGA,
  ASSOCIATE_POPUP_CLOSE,
  ASSOCIATE_REVIEW_DISTINCT_SAGA,
  ASSOCIATE_REVIEW_DISTINCT_SHARE_SAGA,
  ASSOCIATE_SIGN_ON_ASSIGNMENT_LIST_SAGA,
  FILTERMODE,
  GET_ALLOCATE_ASSESSEE_GROUP,
  GET_ALLOCATE_ASSESSMENT,
  GET_ALLOCATE_ASSESSMENT_GROUP,
  GET_ASSESSEE_GROUP_REVIEW_LIST_SAGA,
  GET_ASSESSEE_ROLE_REVIEW_LIST_SAGA,
  GET_ASSESSEE_TYPE_REVIEW_LIST_SAGA,
  GET_ASSESSMENT_GROUP_REVIEW_LIST_SAGA,
  GET_ASSESSMENT_TYPE_REVIEW_LIST_SAGA,
  GET_ASSIGNMENTDISTINCT_ASSESSEES_REVIEWLIST_SAGA,
  GET_ASSIGNMENT_GROUP_REVIEW_LIST_SAGA,
  GET_ASSIGNMENT_REFERENCE_LIST_SAGA,
  GET_ASSIGNMENT_TYPE_REVIEW_LIST_SAGA,
  GET_ASSOCIATE_GROUP_REVIEW_LIST_SAGA,
  GET_ASSOCIATE_ROLE_REVIEW_LIST_SAGA,
  GET_ASSOCIATE_TYPE_REVIEW_LIST_SAGA,
  GET_CULTUREPROFILE_GROUP_REVIEW_LIST_SAGA,
  GET_CULTUREPROFILE_REVIEW_LIST_SAGA,
  GET_CULTUREPROFILE_TYPE_REVIEW_LIST_SAGA,
  GET_ITEM_GROUP_REVIEW_LIST_SAGA,
  GET_ITEM_REVIEW_LIST_SAGA,
  GET_ITEM_TYPE_REVIEW_LIST_SAGA,
  GET_JOBPROFILE_GROUP_REVIEW_LIST_SAGA,
  GET_JOBPROFILE_REVIEW_LIST_SAGA,
  GET_JOBPROFILE_TYPE_REVIEW_LIST_SAGA,
  LOADER_START,
  POPUP_CLOSE,
  RELATED_REVIEWLIST_DISTINCT_DATA,
  REVIEWLIST_DISTINCT_DATA,
  SET_DISPLAY_PANE_THREE_STATE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MIDDLEPANE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_PAGE_COUNT,
  SET_REQUEST_OBJECT,
  SET_USER,
  ASSESSEE_MARKETPLACE_REVIEW_DISTINCT_SAGA,
  ASSESSEE_MARKETPLACE_REVIEW_GROUP_SAGA,
  ASSESSMENT_MARKETPLACE_REVIEW_DISTINCT_SAGA,
  ASSIGNMENT_MARKETPLACE_REVIEW_DISTINCT_SAGA
} from '../actionType';
import PropTypes from 'prop-types';
import { FormHelperText } from '@material-ui/core';
import {
  makeAssesseeRoleScanRequestObject,
  makeAssesseeScanRequestObject,
  makeAssociateRoleScanRequestObject,
  makeAssociateScanRequestObject,
  makeAssesseeGroupScanRequestObject,
  makeAssessmentGroupScanRequestObject,
  makeAssignmentGroupScanRequestObject,
  makeAssociateGroupScanRequestObject,
  makeAssignmentTypeScanRequestObject,
  makeAssessmentTypeScanRequestObject,
  makeAssignmentScanRequestObject,
  makeAssessmentScanRequestObject,
  makeAdminmManagerRoleScanRequestObject,
  makeAssesseeTypeScanRequestObject,
  makeAssociateTypeScanRequestObject,
  makeItemGroupScanObj,
  makeItemScanObj,
  makeItemTypeScanObj,
  makeCultureProfileScanObj,
  makeCultureProfileGroupScanObj,
  makeCultureProfileTypeScanObj,
  makeJobProfileScanObj,
  makeJobProfileGroupScanObj,
  getAssesseeSelfAssignmentScanList,
  makeJobProfileTypeScanObj,
  makeCultureProfileGroupObj,
  makeAssesseeReviewListRequestObject,
  getGuageDistinctApiCall,
  makeAdminScanRequestObject,
  makeAssesseeGroupAllocateScanRequestObject,
  makeAssesseeRoleAllocateScanRequestObject,
  makeAdminmManagerRoleAllocateScanRequestObject,
  makeAssociateGroupAllocateScanRequestObject,
  makeAssociateRoleAllocateScanRequestObject,
  makeAssignmentGroupAllocateScanRequestObject,
  makeAssignmentTypeAllocateScanRequestObject,
  makeAssessmentGroupAllocateScanRequestObject,
  makeAssessmentTypeAllocateScanRequestObject,
  makeItemGroupAllocateScanObj,
  makeItemTypeAllocateScanObj,
  makeCultureProfileGroupAllocateScanObj,
  makeJobProfileTypeAllocateScanObj,
  makeCultureProfileTypeAllocateScanObj,
  makeJobProfileGroupAllocateScanObj,
  makeMineAssignmentScanRequestObject,
  makeAssesseeMarketplaceDistinctReviewListRequestObject,
  makeAssesseeMarketplaceGroupReviewListRequestObject,
  makeMarketplaceAssessmentDistinctReviewListRequestObject,
  makeMarketplaceAssessmentGroupReviewListRequestObject,
  makeAssignmentReferenceListReqObj,
  makeScanAssignmentReferenceListReqObj,
  makeMarketplaceAssignmentDistinctReviewListRequestObject,
  getManuscriptVideosListApiCall
} from '../Actions/GenericActions';
import { ADMIN_ROLE_ID, ASSIGNMENT_MODULE_URL, MANAGER_ROLE_ID } from '../endpoints';
import {
  getAssociateDistinctToAllocate,
  getAssociateGroupAssociateDistinctApiCall,
  getAssociateNodeApiCall,
  getAssociateRoleAssociateDistinctApiCall,
  getAssociateTypeAssociateDistinctApiCall,
  getInternalNodeApiCall,
  getNodeRelatedAssociateDistinctApiCall
} from '../Actions/AssociateModuleAction';
import {
  assesseeAssignmentReviewListApi,
  getAssesseeeDistinctToAllocate,
  getAssesseeGroupAssesseeDistinctApiCall,
  getAssesseeNodeAssesseeDistinctApiCall,
  getAssesseeRoleAssesseeDistinctApiCall,
  getAssesseeTypeAssesseeDistinctApiCall,
  getAssesseeMarketplaceNodeApiCall,
  getAssesseeMarketplaceRoleApiCall,
  getAssesseeMarketplaceTypeApiCall
} from '../Actions/AssesseeModuleAction';
import {
  getItemAllocateApi,
  getItemGroupItemDistinctApiCall,
  getItemTypeItemDistinctApiCall,
  getNodeRelatedItemsDistinctApiCall
} from '../Actions/ItemModuleAction';
import {
  getAssessmentDistinctToAllocate,
  getAssessmentGroupAssessmentDistinctApiCall,
  getAssessmentItemDistinctApiCall,
  getAssessmentTypeAssessmentDistinctApiCall,
  getNodeRelatedAssessmentsDistinctApiCall,
  getMarketplaceAssessmentGroupApiCall,
  getMarketplaceAssessmentNodeApiCall,
  getMarketplaceAssessmentTypeApiCall
} from '../Actions/AssessmentModuleAction';
import {
  getAssignmentDistinctToAllocate,
  getAssignmnetGroupAssignmnetDistinctApiCall,
  getAssignmnetTypeAssignmnetDistinctApiCall,
  getNodeRelatedAssignmentsDistinctApiCall,
  getScanAssignmnetAssesseeDistinctApiCall
} from '../Actions/AssignmentModuleAction';
import {
  getCultureProfileNodeCultureProfileApiCall,
  getAssignmneCultureProfileDistinctApiCall,
  getCultureGroupCultureDistinctApiCall,
  getCultureProfileAllocate,
  getCultureTypeCultureDistinctApiCall
} from '../Actions/ActionCultureProfile';
import {
  getAssignmneJobProfileDistinctApiCall,
  getJobProfileAllocateApi,
  getJobProfileGroupJobProfileDistinctApiCall,
  getJobProfileNodeJobProfileApiCall,
  getJobProfileTypeJobProfileDistinctApiCall
} from '../Actions/ActionJobProfile';

const PopUpScan = (props) => {
  const dispatch = useDispatch();
  const { isPopUpValue, selectedTagValue, cardValue, cachedPreviousSelectedTagValue } = useSelector((state) => state.PopUpReducer);
  const { FilterMode } = useSelector((state) => state.FilterReducer);
  const {
    scanHeader,
    scanHeaderBadgeOne,
    scanHeaderBadgeTwo,
    typeOfMiddlePaneList,
    countPage,
    numberPage,
    middlePaneHeader,
    middlePaneHeaderBadgeOne,
    middlePaneHeaderBadgeTwo,
    middlePaneHeaderBadgeThree,
    selectedAssociateInfo,
    reviewListDistinctData,
    nodeViewState,
    isSelectActive,
    relatedReviewListDistinctData,
    relatedReviewListDistinctDataDuplicate,
    assignmentCreateType,
    selectedTagsArray,
    unselectedTagsArray,
    signInRes,
    allocateStr,
    assessmentResponseObject,
    allocatedTagsArray,
    isListLoading,
    reviewListDistinctDataDuplicate
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const {
    responseObject,
    reviewMode,
    relatedReviewListPaneThree,
    assignmentRelatedReviewListPaneThree,
    headerOneBadgeOne,
    headerOne,
    isReviewRevise
  } = useSelector((state) => state.DisplayPaneThreeReducer);
  const { isActive = true } = props;
  const [state, setState] = useState({
    scanValue: ''
  });

  const handleChange = (event) => {
    setState((prevState) => ({
      ...prevState,
      scanValue: event.target.value
    }));
  };
  const hideDisplayPaneThree = () => {
    //hide display pane three
    dispatch({
      type: SET_DISPLAY_PANE_THREE_STATE,
      payload: {
        isReviewRevise: false,
        headerOne: '',
        headerOneBadgeOne: '',
        headerOneBadgeTwo: '',
        headerOneBadgeThree: '',
        reviewMode: 'review'
      }
    });
  };
  const handleClick = () => {
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'middlePaneSelectedValue', value: '' }
    });
    /*according to seacrh will change*/
    if (state.scanValue !== '') {
      if (typeOfMiddlePaneList === 'MarketplaceAssesseeDistinctReviewList') {
        console.debug({ popupSearch: state.scanValue.trim() });
        let requestObect = makeAssesseeMarketplaceDistinctReviewListRequestObject(
          selectedAssociateInfo,
          middlePaneHeaderBadgeTwo,
          0,
          countPage,
          assignmentCreateType,
          state.scanValue.trim()
        );
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        dispatch({ type: LOADER_START });
        dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        dispatch({
          type: ASSESSEE_MARKETPLACE_REVIEW_DISTINCT_SAGA,
          payload: {
            request: requestObect,
            HeaderOne: middlePaneHeader,
            BadgeOne: 'distinct',
            BadgeTwo: middlePaneHeaderBadgeTwo,
            BadgeThree: middlePaneHeaderBadgeThree,
            isMiddlePaneList: true,
            isSelectActive: isSelectActive,
            selectedTagsArray: selectedTagsArray
          }
        });
        dispatch({ type: ASSESSEE_INFO_CREATE });
        document.getElementById('middleComponentId').scrollTop = '0px';
      }
      if (typeOfMiddlePaneList === 'MarketplaceAssignmentDistinctReviewList') {
        let requestObject = makeMarketplaceAssignmentDistinctReviewListRequestObject(
          selectedAssociateInfo,
          middlePaneHeaderBadgeTwo,
          countPage,
          0,
          '',
          state.scanValue.trim()
        );

        dispatch({ type: LOADER_START });
        dispatch({ type: SET_REQUEST_OBJECT, payload: requestObject });
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        dispatch({
          type: ASSIGNMENT_MARKETPLACE_REVIEW_DISTINCT_SAGA,
          payload: {
            request: requestObject,
            BadgeOne: middlePaneHeaderBadgeOne,
            BadgeTwo: middlePaneHeaderBadgeTwo,
            BadgeThree: middlePaneHeaderBadgeThree
          }
        });
        hideDisplayPaneThree();
        dispatch({ type: ASSOCIATE_POPUP_CLOSE });
        document.getElementById('middleComponentId').scrollTop = '0px';
      }

      if (
        typeOfMiddlePaneList === 'assesseesDistinctReviewList' ||
        typeOfMiddlePaneList === 'administratorsDistinctReviewList' ||
        typeOfMiddlePaneList === 'assistantsDistinctReviewList' ||
        typeOfMiddlePaneList === 'managersDistinctReviewList' ||
        typeOfMiddlePaneList === 'managersDistinctReviewList'
      ) {
        let requestObect = makeAssesseeScanRequestObject(
          selectedAssociateInfo,
          middlePaneHeaderBadgeTwo,
          0,
          countPage,
          state.scanValue.trim(),
          assignmentCreateType
        );
        if (
          typeOfMiddlePaneList === 'administratorsDistinctReviewList' ||
          typeOfMiddlePaneList === 'assistantsDistinctReviewList' ||
          typeOfMiddlePaneList === 'managersDistinctReviewList'
        ) {
          requestObect = makeAdminScanRequestObject(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo,
            0,
            countPage,
            state.scanValue.trim()
          );
        }

        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        dispatch({ type: LOADER_START });
        dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        dispatch({
          type: ASSESSEE_REVIEW_DISTINCT_SAGA,
          payload: {
            request: requestObect,
            HeaderOne: middlePaneHeader,
            BadgeOne: 'distinct',
            BadgeTwo: middlePaneHeaderBadgeTwo,
            BadgeThree: middlePaneHeaderBadgeThree,
            isMiddlePaneList: true,
            isSelectActive: isSelectActive,
            selectedTagsArray: selectedTagsArray
          }
        });
        dispatch({ type: ASSESSEE_INFO_CREATE });
        document.getElementById('middleComponentId').scrollTop = '0px';
      }

      if (typeOfMiddlePaneList === 'associateMineAssignmentDistinctReviewList') {
        let requestObject = makeMineAssignmentScanRequestObject(
          selectedAssociateInfo?.assesseeId,
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
          middlePaneHeaderBadgeTwo,
          0,
          countPage,
          state.scanValue.trim(),
          assignmentCreateType,
          true,
          false
        );

        dispatch({ type: LOADER_START });
        dispatch({ type: SET_REQUEST_OBJECT, payload: requestObject });
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON_ASSIGNMENT_LIST_SAGA,
          payload: {
            assignmentList: requestObject,
            url: ASSIGNMENT_MODULE_URL
          }
        });
        hideDisplayPaneThree();
        dispatch({ type: ASSOCIATE_POPUP_CLOSE });
        document.getElementById('middleComponentId').scrollTop = '0px';
      }

      if (typeOfMiddlePaneList === 'assesseeRoleDistinctReviewList') {
        let requestObect = {};
        if (
          middlePaneHeader === 'administrators' ||
          middlePaneHeader === 'managers' ||
          middlePaneHeader === 'assistants'
        ) {
          if (FilterMode.includes('Allocate')) {
            requestObect = makeAdminmManagerRoleAllocateScanRequestObject(
              selectedAssociateInfo,
              middlePaneHeaderBadgeTwo === 'distinct'
                ? middlePaneHeaderBadgeThree
                : middlePaneHeaderBadgeTwo,
              0,
              countPage,
              state.scanValue.trim(),
              allocatedTagsArray
            );
          } else {
            requestObect = makeAdminmManagerRoleScanRequestObject(
              selectedAssociateInfo,
              middlePaneHeaderBadgeTwo === 'distinct'
                ? middlePaneHeaderBadgeThree
                : middlePaneHeaderBadgeTwo,
              0,
              countPage,
              state.scanValue.trim(),
              middlePaneHeader === 'administrators' ? ADMIN_ROLE_ID : MANAGER_ROLE_ID
            );
          }
        } else {
          if (FilterMode.includes('Allocate')) {
            requestObect = makeAssesseeRoleAllocateScanRequestObject(
              selectedAssociateInfo,
              middlePaneHeaderBadgeTwo === 'distinct'
                ? middlePaneHeaderBadgeThree
                : middlePaneHeaderBadgeTwo,
              0,
              countPage,
              state.scanValue.trim(),
              allocatedTagsArray
            );
          } else {
            requestObect = makeAssesseeRoleScanRequestObject(
              selectedAssociateInfo,
              middlePaneHeaderBadgeTwo === 'distinct'
                ? middlePaneHeaderBadgeThree
                : middlePaneHeaderBadgeTwo,
              0,
              countPage,
              state.scanValue.trim()
            );
          }
        }
        dispatch({ type: LOADER_START });
        dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        dispatch({
          type: GET_ASSESSEE_ROLE_REVIEW_LIST_SAGA,
          payload: {
            request: requestObect,
            BadgeOne: middlePaneHeaderBadgeOne,
            BadgeTwo: middlePaneHeaderBadgeTwo,
            BadgeThree: middlePaneHeaderBadgeThree,
            isMiddlePaneList: true,
            isSelectActive: isSelectActive,
            selectedTagsArray: selectedTagsArray
          }
        });
        dispatch({ type: ASSESSEE_INFO_CREATE });
        document.getElementById('middleComponentId').scrollTop = '0px';
        hideDisplayPaneThree();
      }
      if (typeOfMiddlePaneList === 'marketplaceAssesseeRoleDistinctReviewList') {
        let requestObect = {};
        requestObect = makeAssesseeRoleScanRequestObject(
          selectedAssociateInfo,
          middlePaneHeaderBadgeTwo === 'distinct'
            ? middlePaneHeaderBadgeThree
            : middlePaneHeaderBadgeTwo,
          0,
          countPage,
          state.scanValue.trim(),
          'Shared-Assessee-List'
        );
        dispatch({ type: LOADER_START });
        dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        dispatch({
          type: GET_ASSESSEE_ROLE_REVIEW_LIST_SAGA,
          payload: {
            request: requestObect,
            BadgeOne: middlePaneHeaderBadgeOne,
            BadgeTwo: middlePaneHeaderBadgeTwo,
            BadgeThree: middlePaneHeaderBadgeThree,
            isMiddlePaneList: true,
            isSelectActive: isSelectActive,
            selectedTagsArray: selectedTagsArray,
            actionFilter: 'Shared-Assessee-List'
          }
        });
        dispatch({ type: ASSESSEE_INFO_CREATE });
        document.getElementById('middleComponentId').scrollTop = '0px';
        hideDisplayPaneThree();
      }
      //
      if (typeOfMiddlePaneList === 'assesseesAssginmentAssessmentReviewList') {
        //2
        console.log('relatedReviewListDistinctData', relatedReviewListDistinctData);
        console.log('reviewListDistinctData', reviewListDistinctData);
        let searchValue = state.scanValue.trim().toLocaleLowerCase();
        let selectedAssignment = reviewListDistinctData.filter(
          (x) => x.assesseeAssignmentId === relatedReviewListDistinctData[0].assesseeAssignmentId
        );
        console.log('selectedAssignment', selectedAssignment);
        let reviseassessment = [];
        selectedAssignment[0].assesseeAssignmentAssessmentDistinct.forEach((assessment) => {
          let assessmentName =
            assessment.assesseeAssignmentAssessmentName.toLowerCase() +
            assessment.assesseeAssignmentAssessmentDescription.toLowerCase() +
            assessment.assesseeAssignmentAssessmentId;
          assessmentName.includes(searchValue) && reviseassessment.push(assessment);
        });
        let revisedRelatedObject = {
          ...relatedReviewListDistinctData[0],
          assesseeAssignmentAssessmentDistinct: reviseassessment
        };
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'relatedReviewListDistinctData',
            value: [revisedRelatedObject]
          }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'scanCount', value: reviseassessment.length }
        });
        console.log('reviseassessment', reviseassessment);
      }
      // assignment > assessment search//.
      if (typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList') {
        if (
          reviewMode === 'revise' &&
          headerOne === 'assignment' &&
          headerOneBadgeOne === 'distinct'
        ) {
          let requestObect = makeAssessmentScanRequestObject(
            selectedAssociateInfo,
            'active',
            -1,
            -1,
            state.scanValue
          );
          let revisedGroupObject = {
            id: responseObject.id,
            assignmentName: responseObject.informationBasic.assignmentName,
            assignmentDescription: responseObject.informationBasic.assignmentDescription,
            assignmentStatus: responseObject.informationEngagement.assignmentStatus
          };
          dispatch({
            type: FILTERMODE,
            payload: { FilterMode: 'assignmentDistinctAssessmentRevise' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'relatedReviewListDistinctData', value: [] }
          });
          dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
          dispatch({ type: LOADER_START });
          dispatch({
            type: GET_ALLOCATE_ASSESSMENT,
            payload: {
              request: requestObect,
              revisedGroupObject: revisedGroupObject,
              existingAssessmentId: selectedTagsArray,
              typeOfMiddlePaneList: 'assignmentDistinctAssessmentReviewList'
            }
          });
        } else {
          let searchValue = state.scanValue.toLocaleLowerCase().trim();
          let selectedAssignment = reviewListDistinctData.filter(
            (x) => x.id === relatedReviewListDistinctData[0].id
          );
          console.log('relatedReviewListDistinctData', relatedReviewListDistinctData);
          console.log('assignmentRelatedReviewListPaneThree', assignmentRelatedReviewListPaneThree);

          let reviseassessment = [];
          assignmentRelatedReviewListPaneThree.assessment.forEach((assessment) => {
            let assessmentList =
              assessment.informationBasic.assessmentName?.toLowerCase() +
              assessment.informationBasic.assessmentDescription?.toLowerCase();
            assessmentList.includes(searchValue) && reviseassessment.push(assessment);
          });

          let revisedRelatedObject = {
            ...relatedReviewListDistinctData[0],
            assessment: reviseassessment
          };
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: {
              stateName: 'relatedReviewListDistinctData',
              value: [revisedRelatedObject]
            }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'scanCount', value: reviseassessment.length }
          });
          console.log('reviseassessment', reviseassessment);
          // hideDisplayPaneThree();
        }
      }

      // jobProfilep > Assessment search
      if (typeOfMiddlePaneList === 'jobProfilepAssessmentReviewList') {
        let searchValue = state.scanValue.trim().toLocaleLowerCase();
        console.log('relatedReviewListDistinctData', relatedReviewListDistinctData);
        let reviseassessment = [];
        relatedReviewListDistinctData[0].assessment.forEach((assessment) => {
          let assessmentList =
            assessment.informationBasic.assessmentName?.toLowerCase() +
            assessment.informationBasic.assessmentDescription?.toLowerCase();
          assessmentList.includes(searchValue) && reviseassessment.push(assessment);
        });
        console.log('reviseassessment', reviseassessment);
        let revisedRelatedObject = {
          ...relatedReviewListDistinctData[0],
          assessment: reviseassessment
        };
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'relatedReviewListDistinctData',
            value: [revisedRelatedObject]
          }
        });
        console.log('reviseassessment', reviseassessment);
      }

      //

      // assignment assessees disting revire list
      if (typeOfMiddlePaneList === 'assignmentDistinctAssesseeReviewList') {
        // debugger
        if (
          reviewMode === 'revise' &&
          headerOne === 'assignment' &&
          headerOneBadgeOne === 'distinct' &&
          responseObject?.assignmentDistinctEvaluationRefId === null
        ) {
          let requestObect = makeAssesseeScanRequestObject(
            selectedAssociateInfo,
            'active',
            0,
            countPage,
            state.scanValue.trim()
          );
          let revisedGroupObject = {
            id: responseObject.id,
            assignmentName: responseObject.informationBasic.assignmentName,
            assignmentDescription: responseObject.informationBasic.assignmentDescription,
            assignmentStatus: responseObject.informationEngagement.assignmentStatus
          };
          let existingAssesseeId =
            responseObject.informationFramework?.assignmentAssesseeDistinct || [];
          getAssesseeeDistinctToAllocate(
            dispatch,
            revisedGroupObject,
            requestObect,
            [],
            'assignmentDistinctAssesseeRevise',
            'assignmentDistinctAssesseeReviewList',
            selectedTagsArray,
            unselectedTagsArray
          );
        } else {
          // debugger
          getScanAssignmnetAssesseeDistinctApiCall(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo,
            countPage,
            dispatch,
            middlePaneHeaderBadgeOne,
            relatedReviewListDistinctData[0].id,
            state.scanValue.trim(),
            true,
            '',
            true
          );
        }

        // else {
        //   hideDisplayPaneThree();
        //   let searchValue = state.scanValue.toLocaleLowerCase();
        //   let selectedAssignment = reviewListDistinctData.filter(
        //     (x) => x.id === relatedReviewListDistinctData[0].id
        //   );
        //   let reviseassessment = [];
        //   assignmentRelatedReviewListPaneThree.assessee.forEach((assessee) => {
        //     let assesseeFullName =
        //       assessee.informationBasic.assesseeNameFirst?.toLowerCase() +
        //       assessee.informationBasic.assesseeNameLast?.toLowerCase() +
        //       assessee.informationBasic.assesseeAlias?.toLowerCase() +
        //       assessee.informationContact.assesseeAddressEmailPrimary.assesseeAddressEmail?.toLowerCase() +
        //       assessee.informationContact.assesseeAddressEmailSecondary.assesseeAddressEmail?.toLowerCase() +
        //       assessee.informationContact.assesseeTelephoneMobilePrimary.assesseeTelephoneNumber?.toLowerCase() +
        //       assessee.informationContact.assesseeTelephoneMobileSecondary.assesseeTelephoneNumber?.toLowerCase() +
        //       assessee.id?.toLowerCase();
        //     assesseeFullName.includes(searchValue) && reviseassessment.push(assessee);
        //   });
        //   let revisedRelatedObject = {
        //     ...relatedReviewListDistinctData[0],
        //     assessee: reviseassessment
        //   };
        //   dispatch({
        //     type: SET_DISPLAY_TWO_SINGLE_STATE,
        //     payload: {
        //       stateName: 'relatedReviewListDistinctData',
        //       value: [revisedRelatedObject]
        //     }
        //   });
        //   dispatch({
        //     type: SET_DISPLAY_TWO_SINGLE_STATE,
        //     payload: { stateName: 'scanCount', value: reviseassessment.length }
        //   });
        //   console.log('reviseassessment', reviseassessment);
        // }
      }

      if (typeOfMiddlePaneList === 'assignmentDistinctMineAssesseeDistinctReviewList') {
        if (state.scanValue.trim() === '') {
          return;
        }
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });

        let reqOBj = {
          module: 'Assignment-Distinct',
          action: 'reviewList',
          actionFilter: 'mineAssessees',
          requestObject: {
            assesseeId: selectedAssociateInfo?.assesseeId,
            associateId:
              selectedAssociateInfo?.associate?.informationEngagement.associateTag
                .associateTagPrimary,
            assignmentId: responseObject.id,
            countPage: countPage,
            numberPage: 0,
            searchKey: state.scanValue.trim()
          }
        };
        dispatch({ type: LOADER_START });
        const { informationFramework, informationAllocation } = responseObject;

        let mineAssessees = informationFramework?.assignmentAssesseeMineDistinct || [];
        let existingAssesseeId = mineAssessees.map((ele) => {
          return ele?.assesseeId;
        });

        dispatch({
          type: ASSESSEE_MINE_ASSIGNMENT_LIST,
          payload: {
            request: reqOBj,
            existingAssesseeId: existingAssesseeId
          }
        });
      }

      if (typeOfMiddlePaneList === 'assignmentDistinctAssesseeGroupReviewList') {
        if (reviewMode === 'revise' && headerOne === 'assignment') {
          let requestObject = makeAssesseeGroupScanRequestObject(
            selectedAssociateInfo,
            'active',
            countPage,
            0,
            state.scanValue.trim()
          );

          let revisedGroupObject = {
            id: responseObject.id,
            assignmentName: responseObject.informationBasic.assignmentName,
            assignmentDescription: responseObject.informationBasic.assignmentDescription,
            assignmentStatus: responseObject.informationEngagement.assignmentStatus
          };

          let existingAssesseeId = [];
          dispatch({
            type: FILTERMODE,
            payload: { FilterMode: 'assignmentDistinctAssesseeGroupRevise' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'relatedReviewListDistinctData', value: [] }
          });
          dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
          dispatch({ type: LOADER_START });
          dispatch({
            type: GET_ALLOCATE_ASSESSEE_GROUP,
            payload: {
              request: requestObject,
              revisedGroupObject: revisedGroupObject,
              existingAssesseeId: selectedTagsArray,
              typeOfMiddlePaneList: 'assignmentDistinctAssesseeGroupReviewList'
            }
          });
        }
      }

      if (typeOfMiddlePaneList === 'assignmentDistinctAssessmentGroupReviewList') {
        // console.log(headerOne, reviewMode)
        if (reviewMode === 'revise' && headerOne === 'assignment') {
          let requestObject = makeAssessmentGroupScanRequestObject(
            selectedAssociateInfo,
            'active',
            countPage,
            0,
            state.scanValue.trim()
          );

          let revisedGroupObject = {
            id: responseObject.id,
            assignmentName: responseObject.informationBasic.assignmentName,
            assignmentDescription: responseObject.informationBasic.assignmentDescription,
            assignmentStatus: responseObject.informationEngagement.assignmentStatus
          };
          let existingAssessmentId = [];
          dispatch({
            type: FILTERMODE,
            payload: { FilterMode: 'assignmentDistinctAssessmentGroupRevise' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'relatedReviewListDistinctData', value: [] }
          });
          dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
          dispatch({ type: LOADER_START });
          dispatch({
            type: GET_ALLOCATE_ASSESSMENT_GROUP,
            payload: {
              request: requestObject,
              revisedGroupObject: revisedGroupObject,
              existingAssessmentId: selectedTagsArray,
              typeOfMiddlePaneList: 'assignmentDistinctAssessmentGroupReviewList'
            }
          });
        }
      }
      // //
      if (typeOfMiddlePaneList === 'assignmentDistinctAssignmentReference') {
        console.log('assignmentRelatedReviewListPaneThree', assignmentRelatedReviewListPaneThree);
        if (
          reviewMode === 'revise' &&
          headerOne === 'assignment' &&
          headerOneBadgeOne === 'distinct'
        ) {
          let requestObject = makeScanAssignmentReferenceListReqObj(
            selectedAssociateInfo,
            responseObject.id,
            countPage,
            state.scanValue.trim()
          );
          let reviseResObject = {
            id: responseObject.id,
            assignmentId: responseObject.id,
            assignmentName: responseObject.informationBasic.assignmentName,
            assignmentDescription: responseObject.informationBasic.assignmentDescription,
            assignmentStatus: responseObject.informationEngagement.assignmentStatus
          };
          dispatch({
            type: FILTERMODE,
            payload: { FilterMode: 'assignmentDistinctAssignmentReferenceRevise' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'relatedReviewListDistinctData', value: [] }
          });
          dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
          dispatch({ type: LOADER_START });
          dispatch({
            type: GET_ASSIGNMENT_REFERENCE_LIST_SAGA,
            payload: {
              existingRefId: responseObject?.assignmentDistinctEvaluationRefId,
              request: requestObject,
              revisedGroupObject: reviseResObject,
              typeOfMiddlePaneList: 'assignmentDistinctAssignmentReference',
              responseKey: 'assignment',
              headerOne: 'assignment'
            }
          });
        }
      }
      // cultureProfile assessees disting revire list
      if (typeOfMiddlePaneList === 'cultureProfileAssessmentReviewList') {
        let searchValue = state.scanValue.trim().toLocaleLowerCase();
        let selectedAssignment = reviewListDistinctData.filter(
          (x) => x.id === relatedReviewListDistinctData[0].id
        );
        let reviseassessment = [];
        console.log('relatedReviewListPaneThree----', relatedReviewListPaneThree[0]);
        relatedReviewListPaneThree[0].assessment.forEach((assessment) => {
          let assessmentFullName =
            assessment.informationBasic.assessmentName?.toLowerCase() +
            assessment.informationBasic.assessmentDescription?.toLowerCase();
          assessmentFullName.includes(searchValue) && reviseassessment.push(assessment);
        });
        console.log('relatedReviewListPaneThree----', relatedReviewListPaneThree);
        let revisedRelatedObject = {
          ...relatedReviewListDistinctData[0],
          assessment: reviseassessment
        };
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'relatedReviewListDistinctData',
            value: [revisedRelatedObject]
          }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'scanCount', value: reviseassessment.length }
        });
        console.log('reviseassessment', reviseassessment);
      }
      // //

      // assesseeAssignmentAssessment disting revire list

      if (typeOfMiddlePaneList === 'assesseeAssignmentAssessmentReviewList') {
        let selectedAssignment = relatedReviewListDistinctData[0].assesseeAssignment.filter(
          (x) =>
            x.assesseeAssignmentId ===
            relatedReviewListDistinctData[0].assesseeAssignmentInfo?.assesseeAssignmentId
        );
        let searchValue = state.scanValue.trim().toLocaleLowerCase();
        let reviseassessment = [];
        console.log('relatedReviewListDistinctData----Up', relatedReviewListDistinctData[0]);

        selectedAssignment[0]?.assesseeAssignmentAssessmentDistinct.forEach((assessment) => {
          console.log('IN FOR LOOP', assessment);
          let assessmentFullName =
            assessment.assesseeAssignmentAssessmentName?.toLowerCase() +
            assessment.assesseeAssignmentAssessmentDescription?.toLowerCase();
          assessmentFullName.includes(searchValue) && reviseassessment.push(assessment);
        });
        // let revisedRelatedObject = {
        //   ...relatedReviewListDistinctData[0],
        //   assessment: reviseassessment
        // };
        // dispatch({
        //   type: SET_DISPLAY_TWO_SINGLE_STATE,
        //   payload: { stateName: 'relatedReviewListDistinctData', value: [revisedRelatedObject] }
        // });
        let revisedRelatedObject = {
          ...relatedReviewListDistinctData[0].assesseeAssignmentInfo,
          assesseeAssignmentAssessmentDistinct: reviseassessment
        };
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'relatedReviewListDistinctData',
            value: [
              {
                ...relatedReviewListDistinctData[0],
                assesseeAssignmentInfo: revisedRelatedObject
              }
            ]
          }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'scanCount', value: reviseassessment.length }
        });
        console.log('reviseassessment', reviseassessment);
      }
      // //
      if (typeOfMiddlePaneList === 'assignmentAssesseeAssessmentDistinctReviewList') {
        let selectedAssessee = relatedReviewListDistinctData[0].assessee.filter(
          (x) =>
            x.assesseeId === relatedReviewListDistinctData[0].assignmentAssesseeInfo?.assesseeId
        );
        console.log('selectedAssessee', selectedAssessee);
        let searchValue = state.scanValue.trim().toLocaleLowerCase();
        let reviseassessment = [];
        selectedAssessee[0]?.assesseeAssignmentAssessmentDistinct.forEach((assessment) => {
          console.log('all assessment selectd assessee', assessment);
          let assessmentFullName =
            assessment.assesseeAssignmentAssessmentName?.toLowerCase() +
            assessment.assesseeAssignmentAssessmentDescription?.toLowerCase();
          assessmentFullName.includes(searchValue) && reviseassessment.push(assessment);
        });
        let revisedRelatedObject = {
          ...relatedReviewListDistinctData[0].assignmentAssesseeInfo,
          assesseeAssignmentAssessmentDistinct: reviseassessment
        };
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'relatedReviewListDistinctData',
            value: [
              {
                ...relatedReviewListDistinctData[0],
                assignmentAssesseeInfo: revisedRelatedObject
              }
            ]
          }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'scanCount', value: reviseassessment.length }
        });
        console.log('reviseassessment', reviseassessment);
      }

      if (typeOfMiddlePaneList === 'associateRoleDistinctReviewList') {
        let requestObect = makeAssociateRoleScanRequestObject(
          selectedAssociateInfo,
          middlePaneHeaderBadgeTwo === 'distinct'
            ? middlePaneHeaderBadgeThree
            : middlePaneHeaderBadgeTwo,
          0,
          countPage,
          state.scanValue.trim()
        );
        if (FilterMode.includes('Allocate')) {
          requestObect = makeAssociateRoleAllocateScanRequestObject(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo === 'distinct'
              ? middlePaneHeaderBadgeThree
              : middlePaneHeaderBadgeTwo,
            0,
            countPage,
            state.scanValue.trim(),
            allocatedTagsArray
          );
        }
        dispatch({ type: LOADER_START });
        dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        dispatch({
          type: GET_ASSOCIATE_ROLE_REVIEW_LIST_SAGA,
          payload: {
            request: requestObect,
            BadgeOne: middlePaneHeaderBadgeOne,
            BadgeTwo: middlePaneHeaderBadgeTwo,
            BadgeThree: middlePaneHeaderBadgeThree,
            isMiddlePaneList: true,
            isSelectActive: isSelectActive,
            selectedTagsArray: selectedTagsArray
          }
        });
        hideDisplayPaneThree();
        dispatch({ type: ASSOCIATE_POPUP_CLOSE });
        document.getElementById('middleComponentId').scrollTop = '0px';
      }
      if (typeOfMiddlePaneList === 'associateDistinctReviewList') {
        let requestObect = makeAssociateScanRequestObject(
          selectedAssociateInfo,
          middlePaneHeaderBadgeTwo,
          0,
          countPage,
          state.scanValue.trim()
        );
        dispatch({ type: LOADER_START });
        dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        dispatch({
          type: ASSOCIATE_REVIEW_DISTINCT_SAGA,
          payload: {
            request: requestObect,
            BadgeOne: middlePaneHeaderBadgeOne,
            BadgeTwo: middlePaneHeaderBadgeTwo,
            BadgeThree: middlePaneHeaderBadgeThree,
            typeOfMiddlePaneList: typeOfMiddlePaneList,
            isMiddlePaneList: true,
            isSelectActive: isSelectActive,
            selectedTagsArray: selectedTagsArray
          }
        });
        hideDisplayPaneThree();
        dispatch({ type: ASSOCIATE_POPUP_CLOSE });
        document.getElementById('middleComponentId').scrollTop = '0px';
      }
      if (typeOfMiddlePaneList === 'associateDistinctShareReviewList') {
        // let requestObect = makeAssociateScanRequestObject(
        //   selectedAssociateInfo,
        //   middlePaneHeaderBadgeTwo,
        //   0,
        //   countPage,
        //   state.scanValue.trim()
        // );
        // dispatch({ type: LOADER_START });
        // dispatch({
        //   type: SET_DISPLAY_TWO_SINGLE_STATE,
        //   payload: {
        //     stateName: 'relatedReviewListObjectCopy',
        //     value: requestObect
        //   }
        // });
        // dispatch({ type: SET_PAGE_COUNT, payload: 0 });
        // dispatch({
        //   type: SET_DISPLAY_TWO_SINGLE_STATE,
        //   payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        // });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'reviewListDistinctData', value: [] }
        });
        let filterItem = reviewListDistinctDataDuplicate.filter((dd) => {
          return (
            dd.informationBasic.associateName
              .toLowerCase()
              .includes(state.scanValue.toLowerCase()) ||
            dd.informationBasic.associateDescription
              .toLowerCase()
              .includes(state.scanValue.toLowerCase()) ||
            dd.id.includes(state.scanValue)
          );
        });
        console.log('filterItemfilterItem', filterItem);
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'reviewListDistinctData', value: filterItem }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'scanCount', value: filterItem.length }
        });
        // dispatch({
        //   type: ASSOCIATE_REVIEW_DISTINCT_SAGA,
        //   payload: {
        //     request: requestObect,
        //     BadgeOne: middlePaneHeaderBadgeOne,
        //     BadgeTwo: middlePaneHeaderBadgeTwo,
        //     BadgeThree: middlePaneHeaderBadgeThree,
        //     typeOfMiddlePaneList: typeOfMiddlePaneList,
        //     isMiddlePaneList: true,
        //     isSelectActive: isSelectActive,
        //     selectedTagsArray: selectedTagsArray
        //   }
        // });
        // hideDisplayPaneThree();
        dispatch({ type: ASSOCIATE_POPUP_CLOSE });
        document.getElementById('middleComponentId').scrollTop = '0px';
      }
      if (typeOfMiddlePaneList === 'assesseesGroupDistinctReviewList') {
        if (nodeViewState === 'list') {
          let requestObect = makeAssesseeGroupScanRequestObject(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo === 'distinct'
              ? middlePaneHeaderBadgeThree
              : middlePaneHeaderBadgeTwo,
            countPage,
            0,
            state.scanValue.trim(),
            assignmentCreateType
          );
          if (FilterMode.includes('Allocate')) {
            requestObect = makeAssesseeGroupAllocateScanRequestObject(
              selectedAssociateInfo,
              middlePaneHeaderBadgeTwo === 'distinct'
                ? middlePaneHeaderBadgeThree
                : middlePaneHeaderBadgeTwo,
              0,
              countPage,
              state.scanValue.trim(),
              allocatedTagsArray,
              assignmentCreateType
            );
          }
          dispatch({ type: LOADER_START });
          dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          dispatch({
            type: GET_ASSESSEE_GROUP_REVIEW_LIST_SAGA,
            payload: {
              request: requestObect,
              BadgeOne: middlePaneHeaderBadgeOne,
              BadgeTwo: middlePaneHeaderBadgeTwo,
              BadgeThree: middlePaneHeaderBadgeThree,
              isMiddlePaneList: true,
              isSelectActive: isSelectActive,
              selectedTagsArray: selectedTagsArray,
              cardValue: cardValue
            }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          hideDisplayPaneThree();
          dispatch({ type: ASSOCIATE_POPUP_CLOSE });
          document.getElementById('middleComponentId').scrollTop = '0px';
        } else hideDisplayPaneThree();
      }
      if (typeOfMiddlePaneList === 'MarketplaceAssesseeGroupReviewList') {
        if (nodeViewState === 'list') {
          let requestObect = makeAssesseeMarketplaceGroupReviewListRequestObject(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo === 'distinct'
              ? middlePaneHeaderBadgeThree
              : middlePaneHeaderBadgeTwo,
            countPage,
            0,
            assignmentCreateType,
            'list',
            state.scanValue.trim()
          );

          dispatch({ type: LOADER_START });
          dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          dispatch({
            type: ASSESSEE_MARKETPLACE_REVIEW_GROUP_SAGA,
            payload: {
              request: requestObect,
              BadgeOne: middlePaneHeaderBadgeOne,
              BadgeTwo: middlePaneHeaderBadgeTwo,
              BadgeThree: middlePaneHeaderBadgeThree,
              isMiddlePaneList: true,
              isSelectActive: isSelectActive,
              selectedTagsArray: selectedTagsArray,
              cardValue: cardValue
            }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          hideDisplayPaneThree();
          dispatch({ type: ASSOCIATE_POPUP_CLOSE });
          document.getElementById('middleComponentId').scrollTop = '0px';
        } else hideDisplayPaneThree();
      }
      if (typeOfMiddlePaneList === 'assessmentsGroupDistinctReviewList') {
        if (nodeViewState === 'list') {
          let requestObect = makeAssessmentGroupScanRequestObject(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo === 'distinct'
              ? middlePaneHeaderBadgeThree
              : middlePaneHeaderBadgeTwo,
            countPage,
            0,
            state.scanValue.trim(),
            assignmentCreateType
          );
          if (FilterMode.includes('Allocate')) {
            requestObect = makeAssessmentGroupAllocateScanRequestObject(
              selectedAssociateInfo,
              middlePaneHeaderBadgeTwo === 'distinct'
                ? middlePaneHeaderBadgeThree
                : middlePaneHeaderBadgeTwo,
              0,
              countPage,
              state.scanValue.trim(),
              allocatedTagsArray
            );
          }
          dispatch({ type: LOADER_START });
          dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          dispatch({
            type: GET_ASSESSMENT_GROUP_REVIEW_LIST_SAGA,
            payload: {
              request: requestObect,
              BadgeOne: middlePaneHeaderBadgeOne,
              BadgeTwo: middlePaneHeaderBadgeTwo,
              BadgeThree: middlePaneHeaderBadgeThree,
              isMiddlePaneList: true,
              isSelectActive: isSelectActive,
              selectedTagsArray: selectedTagsArray,
              cardValue: cardValue
            }
          });
          hideDisplayPaneThree();
          dispatch({ type: ASSOCIATE_POPUP_CLOSE });
          document.getElementById('middleComponentId').scrollTop = '0px';
        } else hideDisplayPaneThree();
      }
      if (typeOfMiddlePaneList === 'MarketplaceAssessmentGroupReviewList') {
        if (nodeViewState === 'list')
          getMarketplaceAssessmentGroupApiCall(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo,
            countPage,
            dispatch,
            middlePaneHeaderBadgeOne,
            null,
            null,
            'list',
            selectedTagsArray,
            assignmentCreateType,
            //selectedTagValue,
            state.scanValue.trim(),
            true
          );
      }
      if (typeOfMiddlePaneList === 'assessmentGroupAssessmentReviewList') {
        if (
          reviewMode === 'revise' &&
          headerOne === 'assessments' &&
          headerOneBadgeOne === 'group'
        ) {
          let revisedRelatedObject = {
            id: responseObject.id,
            assessmentGroupName: responseObject.informationBasic.assessmentGroupName,
            assessmentGroupDescription: responseObject.informationBasic.assessmentGroupDescription,
            assessmentGroupStatus: responseObject.informationEngagement.assessmentGroupStatus
          };
          let requestObect = makeAssessmentScanRequestObject(
            selectedAssociateInfo,
            '', //'activeinactive',
            0,
            countPage,
            state.scanValue
          );
          let existingAssessmentId = [];
          let tempArr = relatedReviewListPaneThree[0]?.assessment || [];
          existingAssessmentId = tempArr.map((val) => {
            return val.id;
          });
          getAssessmentDistinctToAllocate(
            revisedRelatedObject,
            requestObect,
            selectedTagsArray,
            dispatch,
            'assessmentGroupAssessmenteRevise',
            'assessmentGroupAssessmentReviewList',
            selectedTagsArray,
            unselectedTagsArray
          );
        } else {
          getAssessmentGroupAssessmentDistinctApiCall(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo,
            countPage,
            dispatch,
            'distinct',
            relatedReviewListDistinctData[0].id,
            //selectedTagValue,
            state.scanValue.trim(),
            true
          );
        }
        // hideDisplayPaneThree();
        dispatch({ type: ASSOCIATE_POPUP_CLOSE });
        document.getElementById('middleComponentId').scrollTop = '0px';
      }
      if (typeOfMiddlePaneList === 'assessmentTypeAssessmentReviewList') {
        if (
          reviewMode === 'revise' &&
          headerOne === 'assessments' &&
          headerOneBadgeOne === 'type'
        ) {
          //revise mode with check box
          let revisedRelatedObject = {
            id: responseObject.id,
            assessmentTypeName: responseObject.informationBasic.assessmentTypeName,
            assessmentTypeDescription: responseObject.informationBasic.assessmentTypeDescription,
            assessmentTypeStatus: responseObject.informationEngagement.assessmentTypeStatus
          };
          let requestObect = makeAssessmentScanRequestObject(
            selectedAssociateInfo,
            '',
            0,
            countPage,
            state.scanValue
          );
          getAssessmentDistinctToAllocate(
            revisedRelatedObject,
            requestObect,
            selectedTagsArray,
            dispatch,
            'assessmentTypeAssessmentRevise',
            'assessmentTypeAssessmentReviewList'
          );
        } else {
          getAssessmentTypeAssessmentDistinctApiCall(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo,
            countPage,
            dispatch,
            'distinct',
            relatedReviewListDistinctData[0].id,
            state.scanValue.trim(),
            true
          );
        }
        // hideDisplayPaneThree();
        dispatch({ type: ASSOCIATE_POPUP_CLOSE });
        document.getElementById('middleComponentId').scrollTop = '0px';
      }
      if (typeOfMiddlePaneList === 'assignmentsGroupDistinctReviewList') {
        if (nodeViewState === 'list') {
          let requestObect = {
            module: 'Assignment-Group',
            action: 'Review-List',
            requestObject: makeAssignmentGroupScanRequestObject(
              selectedAssociateInfo,
              middlePaneHeaderBadgeTwo === 'distinct'
                ? middlePaneHeaderBadgeThree
                : middlePaneHeaderBadgeTwo,
              countPage,
              0,
              state.scanValue.trim(),
              assignmentCreateType
            )
          };
          if (FilterMode.includes('Allocate')) {
            requestObect = {
              module: 'Assignment-Group',
              action: 'Review-List',
              requestObject: makeAssignmentGroupAllocateScanRequestObject(
                selectedAssociateInfo,
                middlePaneHeaderBadgeTwo === 'distinct'
                  ? middlePaneHeaderBadgeThree
                  : middlePaneHeaderBadgeTwo,
                0,
                countPage,
                state.scanValue.trim(),
                assignmentCreateType,
                allocatedTagsArray.length === 0 ? [selectedTagValue] : allocatedTagsArray
              )
            };
          }
          dispatch({ type: LOADER_START });
          dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          dispatch({
            type: GET_ASSIGNMENT_GROUP_REVIEW_LIST_SAGA,
            payload: {
              request: requestObect,
              BadgeOne: middlePaneHeaderBadgeOne,
              BadgeTwo: middlePaneHeaderBadgeTwo,
              BadgeThree: middlePaneHeaderBadgeThree,
              isMiddlePaneList: true,
              isSelectActive: isSelectActive,
              selectedTagsArray: selectedTagsArray,
              cardValue: cardValue
            }
          });
          hideDisplayPaneThree();
          dispatch({ type: ASSOCIATE_POPUP_CLOSE });
          document.getElementById('middleComponentId').scrollTop = '0px';
        } else hideDisplayPaneThree();
      }
      if (typeOfMiddlePaneList === 'MarketplaceAssignmentGroupReviewList') {
        if (nodeViewState === 'list') {
          let requestObect = {
            module: 'Assignment-Group',
            action: 'Review-List',
            actionFilter: 'Shared-Assignment-groupList',
            requestObject: makeAssignmentGroupScanRequestObject(
              selectedAssociateInfo,
              middlePaneHeaderBadgeTwo,
              countPage,
              0,
              state.scanValue.trim(),
              assignmentCreateType
            )
          };
          dispatch({ type: LOADER_START });
          dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          dispatch({
            type: GET_ASSIGNMENT_GROUP_REVIEW_LIST_SAGA,
            payload: {
              request: requestObect,
              BadgeOne: middlePaneHeaderBadgeOne,
              BadgeTwo: middlePaneHeaderBadgeTwo,
              BadgeThree: middlePaneHeaderBadgeThree,
              isMiddlePaneList: true,
              isSelectActive: isSelectActive,
              selectedTagsArray: selectedTagsArray,
              cardValue: cardValue,
              typeOfMiddlePaneList: 'MarketplaceAssignmentGroupReviewList'
            }
          });
          dispatch({ type: ASSOCIATE_POPUP_CLOSE });
          document.getElementById('middleComponentId').scrollTop = '0px';
        } else hideDisplayPaneThree();
      }
      if (typeOfMiddlePaneList === 'assesseesTypeDistinctReviewList') {
        let requestObect = makeAssesseeTypeScanRequestObject(
          selectedAssociateInfo,
          middlePaneHeaderBadgeTwo === 'distinct'
            ? middlePaneHeaderBadgeThree
            : middlePaneHeaderBadgeTwo,
          0,
          countPage,
          state.scanValue.trim()
        );
        if (FilterMode.includes('Allocate')) {
          requestObect = makeAssesseeTypeScanRequestObject(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo === 'distinct'
              ? middlePaneHeaderBadgeThree
              : middlePaneHeaderBadgeTwo,
            0,
            countPage,
            state.scanValue.trim(),
            allocatedTagsArray
          );
        }
        dispatch({ type: LOADER_START });
        dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        dispatch({
          type: GET_ASSESSEE_TYPE_REVIEW_LIST_SAGA,
          payload: {
            request: requestObect,
            middlePaneHeader: middlePaneHeader,
            BadgeOne: middlePaneHeaderBadgeOne,
            BadgeTwo: middlePaneHeaderBadgeTwo,
            BadgeThree: middlePaneHeaderBadgeThree,
            isMiddlePaneList: true,
            isSelectActive: isSelectActive,
            selectedTagsArray: selectedTagsArray
          }
        });
        hideDisplayPaneThree();
        dispatch({ type: ASSOCIATE_POPUP_CLOSE });
        document.getElementById('middleComponentId').scrollTop = '0px';
      }
      if (typeOfMiddlePaneList === 'MarketplaceAssesseesTypeDistinctReviewList') {
        let requestObect = makeAssesseeTypeScanRequestObject(
          selectedAssociateInfo,
          middlePaneHeaderBadgeTwo === 'distinct'
            ? middlePaneHeaderBadgeThree
            : middlePaneHeaderBadgeTwo,
          0,
          countPage,
          state.scanValue.trim(),
          'Shared-Assessee'
        );
        dispatch({ type: LOADER_START });
        dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        dispatch({
          type: GET_ASSESSEE_TYPE_REVIEW_LIST_SAGA,
          payload: {
            request: requestObect,
            middlePaneHeader: middlePaneHeader,
            BadgeOne: middlePaneHeaderBadgeOne,
            BadgeTwo: middlePaneHeaderBadgeTwo,
            BadgeThree: middlePaneHeaderBadgeThree,
            isMiddlePaneList: true,
            isSelectActive: isSelectActive,
            selectedTagsArray: selectedTagsArray,
            actionFilter: 'Shared-Assessee'
          }
        });
        hideDisplayPaneThree();
        dispatch({ type: ASSOCIATE_POPUP_CLOSE });
        document.getElementById('middleComponentId').scrollTop = '0px';
      }
      if (typeOfMiddlePaneList === 'associatesTypeDistinctReviewList') {
        let requestObect = makeAssociateTypeScanRequestObject(
          selectedAssociateInfo,
          middlePaneHeaderBadgeTwo === 'distinct'
            ? middlePaneHeaderBadgeThree
            : middlePaneHeaderBadgeTwo,
          0,
          countPage,
          state.scanValue.trim()
        );
        dispatch({ type: LOADER_START });
        dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        dispatch({
          type: GET_ASSOCIATE_TYPE_REVIEW_LIST_SAGA,
          payload: {
            request: requestObect,
            middlePaneHeader: middlePaneHeader,
            BadgeOne: middlePaneHeaderBadgeOne,
            BadgeTwo: middlePaneHeaderBadgeTwo,
            BadgeThree: middlePaneHeaderBadgeThree,
            isMiddlePaneList: true,
            isSelectActive: isSelectActive,
            selectedTagsArray: selectedTagsArray // to get the selected tag array in search option
          }
        });
        hideDisplayPaneThree();
        dispatch({ type: ASSOCIATE_POPUP_CLOSE });
        document.getElementById('middleComponentId').scrollTop = '0px';
      }
      if (typeOfMiddlePaneList === 'itemsDistinctReviewList') {
        let requestObect = makeItemScanObj(
          selectedAssociateInfo,
          middlePaneHeaderBadgeTwo === 'distinct'
            ? middlePaneHeaderBadgeThree
            : middlePaneHeaderBadgeTwo,
          0,
          countPage,
          state.scanValue.trim()
        );
        dispatch({ type: LOADER_START });
        dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        dispatch({
          type: GET_ITEM_REVIEW_LIST_SAGA,
          payload: {
            request: requestObect,
            middlePaneHeader: middlePaneHeader,
            BadgeOne: middlePaneHeaderBadgeOne,
            BadgeTwo: middlePaneHeaderBadgeTwo,
            BadgeThree: middlePaneHeaderBadgeThree,
            isMiddlePaneList: true,
            isSelectActive: isSelectActive,
            selectedTagsArray: selectedTagsArray
          }
        });
        hideDisplayPaneThree();
        dispatch({ type: ASSOCIATE_POPUP_CLOSE });
      }

      if (typeOfMiddlePaneList === 'marketplaceItemsDistinctReviewList') {
        let requestObect = makeItemScanObj(
          selectedAssociateInfo,
          middlePaneHeaderBadgeTwo === 'distinct'
            ? middlePaneHeaderBadgeThree
            : middlePaneHeaderBadgeTwo,
          0,
          countPage,
          state.scanValue.trim(),
          'Shared-Item-List'
        );
        dispatch({ type: LOADER_START });
        dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        dispatch({
          type: GET_ITEM_REVIEW_LIST_SAGA,
          payload: {
            request: requestObect,
            middlePaneHeader: middlePaneHeader,
            BadgeOne: middlePaneHeaderBadgeOne,
            BadgeTwo: middlePaneHeaderBadgeTwo,
            BadgeThree: middlePaneHeaderBadgeThree,
            isMiddlePaneList: true,
            isSelectActive: isSelectActive,
            selectedTagsArray: selectedTagsArray
          }
        });
        hideDisplayPaneThree();
        dispatch({ type: ASSOCIATE_POPUP_CLOSE });
      }

      if (typeOfMiddlePaneList === 'itemsGroupDistinctReviewList') {
        if (nodeViewState === 'list') {
          let requestObect = makeItemGroupScanObj(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo === 'distinct'
              ? middlePaneHeaderBadgeThree
              : middlePaneHeaderBadgeTwo,
            0,
            countPage,
            state.scanValue.trim()
          );
          if (FilterMode.includes('Allocate')) {
            requestObect = makeItemGroupAllocateScanObj(
              selectedAssociateInfo,
              middlePaneHeaderBadgeTwo === 'distinct'
                ? middlePaneHeaderBadgeThree
                : middlePaneHeaderBadgeTwo,
              0,
              countPage,
              state.scanValue.trim(),
              allocatedTagsArray
            );
          }
          dispatch({ type: LOADER_START });
          dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          dispatch({
            type: GET_ITEM_GROUP_REVIEW_LIST_SAGA,
            payload: {
              request: requestObect,
              middlePaneHeader: middlePaneHeader,
              BadgeOne: middlePaneHeaderBadgeOne,
              BadgeTwo: middlePaneHeaderBadgeTwo,
              BadgeThree: middlePaneHeaderBadgeThree,
              isMiddlePaneList: true,
              cardValue: cardValue,
              isSelectActive: isSelectActive,
              selectedTagsArray: selectedTagsArray
            }
          });
          hideDisplayPaneThree();
          dispatch({ type: ASSOCIATE_POPUP_CLOSE });
        } else hideDisplayPaneThree();
      }
      if (typeOfMiddlePaneList === 'marketplaceItemsGroupDistinctReviewList') {
        if (nodeViewState === 'list') {
          let requestObect = makeItemGroupScanObj(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo === 'distinct'
              ? middlePaneHeaderBadgeThree
              : middlePaneHeaderBadgeTwo,
            0,
            countPage,
            state.scanValue.trim(),
            'Item_Group_Shared_List'
          );
          if (FilterMode.includes('Allocate')) {
            requestObect = makeItemGroupAllocateScanObj(
              selectedAssociateInfo,
              middlePaneHeaderBadgeTwo === 'distinct'
                ? middlePaneHeaderBadgeThree
                : middlePaneHeaderBadgeTwo,
              0,
              countPage,
              state.scanValue.trim(),
              allocatedTagsArray
            );
          }
          dispatch({ type: LOADER_START });
          dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          dispatch({
            type: GET_ITEM_GROUP_REVIEW_LIST_SAGA,
            payload: {
              request: requestObect,
              middlePaneHeader: middlePaneHeader,
              BadgeOne: middlePaneHeaderBadgeOne,
              BadgeTwo: middlePaneHeaderBadgeTwo,
              BadgeThree: middlePaneHeaderBadgeThree,
              isMiddlePaneList: true,
              cardValue: cardValue,
              isSelectActive: isSelectActive,
              selectedTagsArray: selectedTagsArray
            }
          });
          hideDisplayPaneThree();
          dispatch({ type: ASSOCIATE_POPUP_CLOSE });
        } else hideDisplayPaneThree();
      }
      if (typeOfMiddlePaneList === 'marketplaceItemsTypeDistinctReviewList') {
        let requestObect = makeItemTypeScanObj(
          selectedAssociateInfo,
          middlePaneHeaderBadgeTwo === 'distinct'
            ? middlePaneHeaderBadgeThree
            : middlePaneHeaderBadgeTwo,
          0,
          countPage,
          state.scanValue.trim(),
          'Shared-Item'
        );
        if (FilterMode.includes('Allocate')) {
          requestObect = makeItemTypeAllocateScanObj(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo === 'distinct'
              ? middlePaneHeaderBadgeThree
              : middlePaneHeaderBadgeTwo,
            0,
            countPage,
            state.scanValue.trim(),
            allocatedTagsArray
          );
        }
        dispatch({ type: LOADER_START });
        dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        dispatch({
          type: GET_ITEM_TYPE_REVIEW_LIST_SAGA,
          payload: {
            request: requestObect,
            middlePaneHeader: middlePaneHeader,
            BadgeOne: middlePaneHeaderBadgeOne,
            BadgeTwo: middlePaneHeaderBadgeTwo,
            BadgeThree: middlePaneHeaderBadgeThree,
            isMiddlePaneList: true,
            isSelectActive: isSelectActive,
            selectedTagsArray: selectedTagsArray
          }
        });
        hideDisplayPaneThree();
        dispatch({ type: ASSOCIATE_POPUP_CLOSE });
      }
      if (typeOfMiddlePaneList === 'itemsTypeDistinctReviewList') {
        let requestObect = makeItemTypeScanObj(
          selectedAssociateInfo,
          middlePaneHeaderBadgeTwo === 'distinct'
            ? middlePaneHeaderBadgeThree
            : middlePaneHeaderBadgeTwo,
          0,
          countPage,
          state.scanValue.trim()
        );
        if (FilterMode.includes('Allocate')) {
          requestObect = makeItemTypeAllocateScanObj(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo === 'distinct'
              ? middlePaneHeaderBadgeThree
              : middlePaneHeaderBadgeTwo,
            0,
            countPage,
            state.scanValue.trim(),
            allocatedTagsArray
          );
        }
        dispatch({ type: LOADER_START });
        dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        dispatch({
          type: GET_ITEM_TYPE_REVIEW_LIST_SAGA,
          payload: {
            request: requestObect,
            middlePaneHeader: middlePaneHeader,
            BadgeOne: middlePaneHeaderBadgeOne,
            BadgeTwo: middlePaneHeaderBadgeTwo,
            BadgeThree: middlePaneHeaderBadgeThree,
            isMiddlePaneList: true,
            isSelectActive: isSelectActive,
            selectedTagsArray: selectedTagsArray
          }
        });
        hideDisplayPaneThree();
        dispatch({ type: ASSOCIATE_POPUP_CLOSE });
      }

      if (typeOfMiddlePaneList === 'marketplaceItemNodeItemReviewList') {
        if (
          reviewMode === 'revise' &&
          (headerOne === 'associate' || headerOne === 'items') &&
          headerOneBadgeOne === 'node'
        ) {
          let requestObect = makeItemScanObj(
            selectedAssociateInfo,
            '',
            countPage,
            0,
            state.scanValue.trim(),
            'Shared-Item-Node'
          );
          let revisedRoleObject = {
            id: responseObject.id,
            associateNodeName: responseObject.informationBasic.associateNodeName,
            associateNodeDescription: responseObject.informationBasic.associateNodeDescription,
            associateNodeStatus: responseObject.informationEngagement.associateNodeStatus
          };
          getItemAllocateApi(
            dispatch,
            requestObect,
            revisedRoleObject,
            [],
            'itemNodeItemRevise',
            'itemNodeItemReviewList',
            selectedTagsArray
          );
        } else {
          getNodeRelatedItemsDistinctApiCall(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo,
            countPage,
            dispatch,
            'distinct',
            relatedReviewListDistinctData[0].id, //selectedTagValue,
            state.scanValue.trim(),
            true,
            middlePaneHeader,
            true,
            'Shared-Item-Node'
          );
        }
      }
      if (typeOfMiddlePaneList === 'assignmentsTypeDistinctReviewList') {
        let requestObect = {
          module: 'Assignment-Type',
          action: 'Review-List',
          requestObject: makeAssignmentTypeScanRequestObject(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo === 'distinct'
              ? middlePaneHeaderBadgeThree
              : middlePaneHeaderBadgeTwo,
            0,
            countPage,
            state.scanValue.trim()
          )
        };
        if (FilterMode.includes('Allocate')) {
          requestObect = {
            module: 'Assignment-Type',
            action: 'Review-List',
            requestObject: makeAssignmentTypeAllocateScanRequestObject(
              selectedAssociateInfo,
              middlePaneHeaderBadgeTwo === 'distinct'
                ? middlePaneHeaderBadgeThree
                : middlePaneHeaderBadgeTwo,
              countPage,
              0,
              state.scanValue.trim(),
              allocatedTagsArray.length === 0 ? [selectedTagValue] : allocatedTagsArray
            )
          };
        }
        dispatch({ type: LOADER_START });
        dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        dispatch({
          type: GET_ASSIGNMENT_TYPE_REVIEW_LIST_SAGA,
          payload: {
            request: requestObect,
            BadgeOne: middlePaneHeaderBadgeOne,
            BadgeTwo: middlePaneHeaderBadgeTwo,
            BadgeThree: middlePaneHeaderBadgeThree,
            isMiddlePaneList: true,
            isSelectActive: isSelectActive,
            selectedTagsArray: selectedTagsArray
          }
        });
        hideDisplayPaneThree();
        dispatch({ type: ASSOCIATE_POPUP_CLOSE });
        document.getElementById('middleComponentId').scrollTop = '0px';
      }

      if (typeOfMiddlePaneList === 'MarketplaceAssignmentsTypeDistinctReviewList') {
        let requestObect = {
          module: 'Assignment-Type',
          action: 'Review-List',
          actionFilter: 'Assignment-Type-Shared-List',
          requestObject: makeAssignmentTypeScanRequestObject(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo === 'distinct'
              ? middlePaneHeaderBadgeThree
              : middlePaneHeaderBadgeTwo,
            0,
            countPage,
            state.scanValue.trim()
          )
        };
        if (FilterMode.includes('Allocate')) {
          requestObect = {
            module: 'Assignment-Type',
            action: 'Review-List',
            requestObject: makeAssignmentTypeAllocateScanRequestObject(
              selectedAssociateInfo,
              middlePaneHeaderBadgeTwo === 'distinct'
                ? middlePaneHeaderBadgeThree
                : middlePaneHeaderBadgeTwo,
              countPage,
              0,
              state.scanValue.trim(),
              allocatedTagsArray.length === 0 ? [selectedTagValue] : allocatedTagsArray
            )
          };
        }
        dispatch({ type: LOADER_START });
        dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        dispatch({
          type: GET_ASSIGNMENT_TYPE_REVIEW_LIST_SAGA,
          payload: {
            request: requestObect,
            BadgeOne: middlePaneHeaderBadgeOne,
            BadgeTwo: middlePaneHeaderBadgeTwo,
            BadgeThree: middlePaneHeaderBadgeThree,
            isMiddlePaneList: true,
            isSelectActive: isSelectActive,
            selectedTagsArray: selectedTagsArray
          }
        });
        hideDisplayPaneThree();
        dispatch({ type: ASSOCIATE_POPUP_CLOSE });
        document.getElementById('middleComponentId').scrollTop = '0px';
      }

      if (typeOfMiddlePaneList === 'assessmentsTypeDistinctReviewList') {
        let requestObect = makeAssessmentTypeScanRequestObject(
          selectedAssociateInfo,
          middlePaneHeaderBadgeTwo === 'distinct'
            ? middlePaneHeaderBadgeThree
            : middlePaneHeaderBadgeTwo,
          countPage,
          0,
          state.scanValue.trim()
        );
        if (FilterMode.includes('Allocate')) {
          requestObect = makeAssessmentTypeAllocateScanRequestObject(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo === 'distinct'
              ? middlePaneHeaderBadgeThree
              : middlePaneHeaderBadgeTwo,
            countPage,
            0,
            state.scanValue.trim(),
            allocatedTagsArray
          );
        }
        dispatch({ type: LOADER_START });
        dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        dispatch({
          type: GET_ASSESSMENT_TYPE_REVIEW_LIST_SAGA,
          payload: {
            request: requestObect,
            BadgeOne: middlePaneHeaderBadgeOne,
            BadgeTwo: middlePaneHeaderBadgeTwo,
            BadgeThree: middlePaneHeaderBadgeThree,
            isMiddlePaneList: true,
            isSelectActive: isSelectActive,
            selectedTagsArray: selectedTagsArray
          }
        });
        hideDisplayPaneThree();
        dispatch({ type: ASSOCIATE_POPUP_CLOSE });
        document.getElementById('middleComponentId').scrollTop = '0px';
      }
      if (typeOfMiddlePaneList === 'MarketplaceAssessmentsTypeDistinctReviewList') {
        let requestObect = makeAssessmentTypeScanRequestObject(
          selectedAssociateInfo,
          middlePaneHeaderBadgeTwo === 'distinct'
            ? middlePaneHeaderBadgeThree
            : middlePaneHeaderBadgeTwo,
          countPage,
          0,
          state.scanValue.trim(),
          'Shared-Assessment'
        );
        if (FilterMode.includes('Allocate')) {
          requestObect = makeAssessmentTypeAllocateScanRequestObject(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo === 'distinct'
              ? middlePaneHeaderBadgeThree
              : middlePaneHeaderBadgeTwo,
            countPage,
            0,
            state.scanValue.trim(),
            allocatedTagsArray
          );
        }
        dispatch({ type: LOADER_START });
        dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        dispatch({
          type: GET_ASSESSMENT_TYPE_REVIEW_LIST_SAGA,
          payload: {
            request: requestObect,
            BadgeOne: middlePaneHeaderBadgeOne,
            BadgeTwo: middlePaneHeaderBadgeTwo,
            BadgeThree: middlePaneHeaderBadgeThree,
            isMiddlePaneList: true,
            isSelectActive: isSelectActive,
            selectedTagsArray: selectedTagsArray
          }
        });
        hideDisplayPaneThree();
        dispatch({ type: ASSOCIATE_POPUP_CLOSE });
        document.getElementById('middleComponentId').scrollTop = '0px';
      }
      if (typeOfMiddlePaneList === 'associatesGroupDistinctReviewList') {
        if (nodeViewState === 'list') {
          let requestObect = makeAssociateGroupScanRequestObject(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo === 'distinct'
              ? middlePaneHeaderBadgeThree
              : middlePaneHeaderBadgeTwo,
            countPage,
            0,
            state.scanValue.trim(),
            assignmentCreateType
          );
          if (FilterMode.includes('Allocate')) {
            requestObect = makeAssociateGroupAllocateScanRequestObject(
              selectedAssociateInfo,
              middlePaneHeaderBadgeTwo === 'distinct'
                ? middlePaneHeaderBadgeThree
                : middlePaneHeaderBadgeTwo,
              0,
              countPage,
              state.scanValue.trim(),
              allocatedTagsArray
            );
          }
          dispatch({ type: LOADER_START });
          dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          dispatch({
            type: GET_ASSOCIATE_GROUP_REVIEW_LIST_SAGA,
            payload: {
              request: requestObect,
              BadgeOne: middlePaneHeaderBadgeOne,
              BadgeTwo: middlePaneHeaderBadgeTwo,
              BadgeThree: middlePaneHeaderBadgeThree,
              isMiddlePaneList: true,
              isSelectActive: isSelectActive,
              selectedTagsArray: selectedTagsArray,
              cardValue: cardValue
            }
          });
          // hideDisplayPaneThree();
          dispatch({ type: ASSOCIATE_POPUP_CLOSE });
          document.getElementById('middleComponentId').scrollTop = '0px';
        } else hideDisplayPaneThree();
      }
      if (typeOfMiddlePaneList === 'assignmentDistinctReviewList') {
        let requestObect = makeAssignmentScanRequestObject(
          selectedAssociateInfo,
          // allocateStr !== '' ? 'activeinactive' : middlePaneHeaderBadgeTwo, // allocate only publish unpublish and administer assignment from backend
          middlePaneHeaderBadgeTwo,
          0,
          countPage,
          state.scanValue.trim(),
          assignmentCreateType
        );

        dispatch({ type: LOADER_START });
        dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        dispatch({
          type: ASSIGNMENT_REVIEW_LIST_DISTINCT_SAGA,
          payload: {
            request: requestObect,
            BadgeOne: middlePaneHeaderBadgeOne,
            BadgeTwo: middlePaneHeaderBadgeTwo,
            BadgeThree: middlePaneHeaderBadgeThree,
            isMiddlePaneList: true,
            isSelectActive: isSelectActive,
            selectedTagsArray: selectedTagsArray
          }
        });
        hideDisplayPaneThree();
        dispatch({ type: ASSOCIATE_POPUP_CLOSE });
        document.getElementById('middleComponentId').scrollTop = '0px';
      }
      if (typeOfMiddlePaneList === 'MarketplaceAssessmentDistinctReviewList') {
        let requestObect = makeMarketplaceAssessmentDistinctReviewListRequestObject(
          selectedAssociateInfo,
          middlePaneHeaderBadgeTwo,
          0,
          countPage,
          '',
          state.scanValue.trim()
        );
        dispatch({ type: LOADER_START });
        dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        dispatch({
          type: ASSESSMENT_MARKETPLACE_REVIEW_DISTINCT_SAGA,
          payload: {
            request: requestObect,
            BadgeOne: middlePaneHeaderBadgeOne,
            BadgeTwo: middlePaneHeaderBadgeTwo,
            BadgeThree: middlePaneHeaderBadgeThree,
            isMiddlePaneList: true,
            isSelectActive: isSelectActive,
            selectedTagsArray: selectedTagsArray
          }
        });
        hideDisplayPaneThree();
        dispatch({ type: ASSOCIATE_POPUP_CLOSE });
        document.getElementById('middleComponentId').scrollTop = '0px';
      }
      if (typeOfMiddlePaneList === 'assessmentDistinctReviewList') {
        let requestObect = makeAssessmentScanRequestObject(
          selectedAssociateInfo,
          middlePaneHeaderBadgeTwo,
          0,
          countPage,
          state.scanValue.trim()
        );
        dispatch({ type: LOADER_START });
        dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        dispatch({
          type: ASSESSMENT_REVIEW_DISTINCT_SAGA,
          payload: {
            request: requestObect,
            BadgeOne: middlePaneHeaderBadgeOne,
            BadgeTwo: middlePaneHeaderBadgeTwo,
            BadgeThree: middlePaneHeaderBadgeThree,
            isMiddlePaneList: true,
            isSelectActive: isSelectActive,
            selectedTagsArray: selectedTagsArray
          }
        });
        hideDisplayPaneThree();
        dispatch({ type: ASSOCIATE_POPUP_CLOSE });
        document.getElementById('middleComponentId').scrollTop = '0px';
      }
      if (typeOfMiddlePaneList === 'jobProfilesDistinctReviewList') {
        let requestObect = makeJobProfileScanObj(
          selectedAssociateInfo,
          middlePaneHeaderBadgeTwo,
          0,
          countPage,
          state.scanValue.trim()
        );
        dispatch({ type: LOADER_START });
        dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        dispatch({
          type: GET_JOBPROFILE_REVIEW_LIST_SAGA,
          payload: {
            request: requestObect,
            BadgeOne: middlePaneHeaderBadgeOne,
            BadgeTwo: middlePaneHeaderBadgeTwo,
            BadgeThree: middlePaneHeaderBadgeThree,
            isMiddlePaneList: true,
            isSelectActive: isSelectActive,
            selectedTagsArray: selectedTagsArray
          }
        });
        hideDisplayPaneThree();
        dispatch({ type: ASSOCIATE_POPUP_CLOSE });
        document.getElementById('middleComponentId').scrollTop = '0px';
      }

      if (typeOfMiddlePaneList === 'MarketplaceJobProfilesDistinctReviewList') {
        let requestObect = makeJobProfileScanObj(
          selectedAssociateInfo,
          middlePaneHeaderBadgeTwo,
          0,
          countPage,
          state.scanValue.trim(),
          'Job-Profile-Shared-List'
        );
        dispatch({ type: LOADER_START });
        dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        dispatch({
          type: GET_JOBPROFILE_REVIEW_LIST_SAGA,
          payload: {
            request: requestObect,
            BadgeOne: middlePaneHeaderBadgeOne,
            BadgeTwo: middlePaneHeaderBadgeTwo,
            BadgeThree: middlePaneHeaderBadgeThree,
            isMiddlePaneList: true,
            isSelectActive: isSelectActive,
            selectedTagsArray: selectedTagsArray
          }
        });
        hideDisplayPaneThree();
        dispatch({ type: ASSOCIATE_POPUP_CLOSE });
        document.getElementById('middleComponentId').scrollTop = '0px';
      }

      if (typeOfMiddlePaneList === 'jobProfilesGroupDistinctReviewList') {
        if (nodeViewState === 'list') {
          let requestObect = makeJobProfileGroupScanObj(
            selectedAssociateInfo,
            // middlePaneHeaderBadgeTwo,
            cardValue === 'Card' ? middlePaneHeaderBadgeThree : middlePaneHeaderBadgeTwo,
            0,
            countPage,
            state.scanValue.trim()
          );
          if (FilterMode.includes('allocate') || FilterMode.includes('Allocate')) {
            requestObect = makeJobProfileGroupAllocateScanObj(
              selectedAssociateInfo,
              // middlePaneHeaderBadgeTwo,
              cardValue === 'Card' ? middlePaneHeaderBadgeThree : middlePaneHeaderBadgeTwo,
              0,
              countPage,
              state.scanValue.trim(),
              allocatedTagsArray
            );
          }
          dispatch({ type: LOADER_START });
          dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          dispatch({
            type: GET_JOBPROFILE_GROUP_REVIEW_LIST_SAGA,
            payload: {
              request: requestObect,
              BadgeOne: middlePaneHeaderBadgeOne,
              BadgeTwo: middlePaneHeaderBadgeTwo,
              BadgeThree: middlePaneHeaderBadgeThree,
              isMiddlePaneList: true,
              cardValue: cardValue,
              isSelectActive: isSelectActive,
              selectedTagsArray: selectedTagsArray
            }
          });
          hideDisplayPaneThree();
          dispatch({ type: ASSOCIATE_POPUP_CLOSE });
          document.getElementById('middleComponentId').scrollTop = '0px';
        } else hideDisplayPaneThree();
      }
      if (typeOfMiddlePaneList === 'MarketplaceJobProfileGroupReviewList') {
        if (nodeViewState === 'list') {
          let requestObect = makeJobProfileGroupScanObj(
            selectedAssociateInfo,
            // middlePaneHeaderBadgeTwo,
            cardValue === 'Card' ? middlePaneHeaderBadgeThree : middlePaneHeaderBadgeTwo,
            0,
            countPage,
            state.scanValue.trim(),
            'Job-Profile-Shared-Group'
          );
          if (FilterMode.includes('allocate') || FilterMode.includes('Allocate')) {
            requestObect = makeJobProfileGroupAllocateScanObj(
              selectedAssociateInfo,
              // middlePaneHeaderBadgeTwo,
              cardValue === 'Card' ? middlePaneHeaderBadgeThree : middlePaneHeaderBadgeTwo,
              0,
              countPage,
              state.scanValue.trim(),
              allocatedTagsArray
            );
          }
          dispatch({ type: LOADER_START });
          dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          dispatch({
            type: GET_JOBPROFILE_GROUP_REVIEW_LIST_SAGA,
            payload: {
              request: requestObect,
              BadgeOne: middlePaneHeaderBadgeOne,
              BadgeTwo: middlePaneHeaderBadgeTwo,
              BadgeThree: middlePaneHeaderBadgeThree,
              isMiddlePaneList: true,
              cardValue: cardValue,
              isSelectActive: isSelectActive,
              selectedTagsArray: selectedTagsArray
            }
          });
          hideDisplayPaneThree();
          dispatch({ type: ASSOCIATE_POPUP_CLOSE });
          document.getElementById('middleComponentId').scrollTop = '0px';
        } else hideDisplayPaneThree();
      }
      if (typeOfMiddlePaneList === 'cultureProfilesDistinctReviewList') {
        let requestObect = makeCultureProfileScanObj(
          selectedAssociateInfo,
          middlePaneHeaderBadgeTwo,
          0,
          countPage,
          state.scanValue.trim()
        );
        dispatch({ type: LOADER_START });
        dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        dispatch({
          type: GET_CULTUREPROFILE_REVIEW_LIST_SAGA,
          payload: {
            request: requestObect,
            BadgeOne: middlePaneHeaderBadgeOne,
            BadgeTwo: middlePaneHeaderBadgeTwo,
            BadgeThree: middlePaneHeaderBadgeThree,
            isMiddlePaneList: true,
            isSelectActive: isSelectActive,
            selectedTagsArray: selectedTagsArray
          }
        });
        hideDisplayPaneThree();
        dispatch({ type: ASSOCIATE_POPUP_CLOSE });
        document.getElementById('middleComponentId').scrollTop = '0px';
      }
      if (typeOfMiddlePaneList === 'marketPlaceCultureProfilesDistinctReviewList') {
        let requestObect = makeCultureProfileScanObj(
          selectedAssociateInfo,
          middlePaneHeaderBadgeTwo,
          0,
          countPage,
          state.scanValue.trim(),
          'Shared-Culture-Profile-List'
        );
        dispatch({ type: LOADER_START });
        dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        dispatch({
          type: GET_CULTUREPROFILE_REVIEW_LIST_SAGA,
          payload: {
            request: requestObect,
            BadgeOne: middlePaneHeaderBadgeOne,
            BadgeTwo: middlePaneHeaderBadgeTwo,
            BadgeThree: middlePaneHeaderBadgeThree,
            isMiddlePaneList: true,
            isSelectActive: isSelectActive,
            selectedTagsArray: selectedTagsArray
          }
        });
        hideDisplayPaneThree();
        dispatch({ type: ASSOCIATE_POPUP_CLOSE });
        document.getElementById('middleComponentId').scrollTop = '0px';
      }
      if (typeOfMiddlePaneList === 'cultureProfilesGroupDistinctReviewList') {
        if (nodeViewState === 'list') {
          let requestObect = makeCultureProfileGroupScanObj(
            selectedAssociateInfo,
            cardValue === 'Card' ? middlePaneHeaderBadgeThree : middlePaneHeaderBadgeTwo,
            0,
            countPage,
            state.scanValue.trim()
          );
          if (FilterMode.includes('allocate') || FilterMode.includes('Allocate')) {
            requestObect = makeCultureProfileGroupAllocateScanObj(
              selectedAssociateInfo,
              cardValue === 'Card' ? middlePaneHeaderBadgeThree : middlePaneHeaderBadgeTwo,
              0,
              countPage,
              state.scanValue.trim(),
              allocatedTagsArray
            );
          }
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          dispatch({ type: LOADER_START });
          dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          dispatch({
            type: GET_CULTUREPROFILE_GROUP_REVIEW_LIST_SAGA,
            payload: {
              request: requestObect,
              BadgeOne: middlePaneHeaderBadgeOne,
              BadgeTwo: middlePaneHeaderBadgeTwo,
              BadgeThree: middlePaneHeaderBadgeThree,
              isMiddlePaneList: true,
              isSelectActive: isSelectActive,
              selectedTagsArray: selectedTagsArray,
              cardValue: cardValue
            }
          });
          hideDisplayPaneThree();
          dispatch({ type: ASSOCIATE_POPUP_CLOSE });
          document.getElementById('middleComponentId').scrollTop = '0px';
        } else hideDisplayPaneThree();
      }
      if (typeOfMiddlePaneList === 'marketPlaceCultureProfilesGroupDistinctReviewList') {
        if (nodeViewState === 'list') {
          let requestObect = makeCultureProfileGroupScanObj(
            selectedAssociateInfo,
            cardValue === 'Card' ? middlePaneHeaderBadgeThree : middlePaneHeaderBadgeTwo,
            0,
            countPage,
            state.scanValue.trim(),
            'Culture-Profile-Shared-Group'
          );
          if (FilterMode.includes('allocate') || FilterMode.includes('Allocate')) {
            requestObect = makeCultureProfileGroupAllocateScanObj(
              selectedAssociateInfo,
              cardValue === 'Card' ? middlePaneHeaderBadgeThree : middlePaneHeaderBadgeTwo,
              0,
              countPage,
              state.scanValue.trim(),
              allocatedTagsArray
            );
          }
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          dispatch({ type: LOADER_START });
          dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          dispatch({
            type: GET_CULTUREPROFILE_GROUP_REVIEW_LIST_SAGA,
            payload: {
              request: requestObect,
              BadgeOne: middlePaneHeaderBadgeOne,
              BadgeTwo: middlePaneHeaderBadgeTwo,
              BadgeThree: middlePaneHeaderBadgeThree,
              isMiddlePaneList: true,
              isSelectActive: isSelectActive,
              selectedTagsArray: selectedTagsArray,
              cardValue: cardValue
            }
          });
          hideDisplayPaneThree();
          dispatch({ type: ASSOCIATE_POPUP_CLOSE });
          document.getElementById('middleComponentId').scrollTop = '0px';
        } else hideDisplayPaneThree();
      }

      if (typeOfMiddlePaneList === 'jobProfilesTypeDistinctReviewList') {
        let requestObect = makeJobProfileTypeScanObj(
          selectedAssociateInfo,
          cardValue === 'Card' ? middlePaneHeaderBadgeThree : middlePaneHeaderBadgeTwo,
          countPage,
          0,
          state.scanValue
        );
        if (FilterMode.includes('allocate') || FilterMode.includes('Allocate')) {
          requestObect = makeJobProfileTypeAllocateScanObj(
            selectedAssociateInfo,
            cardValue === 'Card' ? middlePaneHeaderBadgeThree : middlePaneHeaderBadgeTwo,
            countPage,
            0,
            state.scanValue.trim(),
            allocatedTagsArray
          );
        }
        dispatch({ type: LOADER_START });
        dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        dispatch({
          type: GET_JOBPROFILE_TYPE_REVIEW_LIST_SAGA,
          payload: {
            request: requestObect,
            BadgeOne: middlePaneHeaderBadgeOne,
            BadgeTwo: middlePaneHeaderBadgeTwo,
            BadgeThree: middlePaneHeaderBadgeThree,
            isMiddlePaneList: true,
            isSelectActive: isSelectActive,
            selectedTagsArray: selectedTagsArray
          }
        });
        hideDisplayPaneThree();
        dispatch({ type: ASSOCIATE_POPUP_CLOSE });
        document.getElementById('middleComponentId').scrollTop = '0px';
      }
      if (typeOfMiddlePaneList === 'MarketplaceJobProfilesTypeDistinctReviewList') {
        let requestObect = makeJobProfileTypeScanObj(
          selectedAssociateInfo,
          cardValue === 'Card' ? middlePaneHeaderBadgeThree : middlePaneHeaderBadgeTwo,
          countPage,
          0,
          state.scanValue.trim(),
          'Shared-Job-Profile-Type'
        );
        if (FilterMode.includes('allocate') || FilterMode.includes('Allocate')) {
          requestObect = makeJobProfileTypeAllocateScanObj(
            selectedAssociateInfo,
            cardValue === 'Card' ? middlePaneHeaderBadgeThree : middlePaneHeaderBadgeTwo,
            countPage,
            0,
            state.scanValue.trim(),
            allocatedTagsArray
          );
        }
        dispatch({ type: LOADER_START });
        dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        dispatch({
          type: GET_JOBPROFILE_TYPE_REVIEW_LIST_SAGA,
          payload: {
            request: requestObect,
            BadgeOne: middlePaneHeaderBadgeOne,
            BadgeTwo: middlePaneHeaderBadgeTwo,
            BadgeThree: middlePaneHeaderBadgeThree,
            isMiddlePaneList: true,
            isSelectActive: isSelectActive,
            selectedTagsArray: selectedTagsArray
          }
        });
        hideDisplayPaneThree();
        dispatch({ type: ASSOCIATE_POPUP_CLOSE });
        document.getElementById('middleComponentId').scrollTop = '0px';
      }
      if (typeOfMiddlePaneList === 'cultureProfilesTypeDistinctReviewList') {
        let requestObect = makeCultureProfileTypeScanObj(
          selectedAssociateInfo,
          cardValue === 'Card' ? middlePaneHeaderBadgeThree : middlePaneHeaderBadgeTwo, //if access through module card value is "noCard" and through self then "Crad" card
          0,
          countPage,
          state.scanValue.trim()
        );
        if (FilterMode.includes('allocate') || FilterMode.includes('Allocate')) {
          requestObect = makeCultureProfileTypeAllocateScanObj(
            selectedAssociateInfo,
            cardValue === 'Card' ? middlePaneHeaderBadgeThree : middlePaneHeaderBadgeTwo, //if access through module card value is "noCard" and through self then "Crad" card
            0,
            countPage,
            state.scanValue.trim(),
            allocatedTagsArray
          );
        }
        dispatch({ type: LOADER_START });
        dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        dispatch({
          type: GET_CULTUREPROFILE_TYPE_REVIEW_LIST_SAGA,
          payload: {
            request: requestObect,
            BadgeOne: middlePaneHeaderBadgeOne,
            BadgeTwo: middlePaneHeaderBadgeTwo,
            BadgeThree: middlePaneHeaderBadgeThree,
            isMiddlePaneList: true,
            isSelectActive: isSelectActive,
            selectedTagsArray: selectedTagsArray
          }
        });
        hideDisplayPaneThree();
        dispatch({ type: ASSOCIATE_POPUP_CLOSE });
        document.getElementById('middleComponentId').scrollTop = '0px';
      }
      if (typeOfMiddlePaneList === 'MarketPlaceCultureProfileTypeReviewList') {
        let requestObect = makeCultureProfileTypeScanObj(
          selectedAssociateInfo,
          cardValue === 'Card' ? middlePaneHeaderBadgeThree : middlePaneHeaderBadgeTwo, //if access through module card value is "noCard" and through self then "Crad" card
          0,
          countPage,
          state.scanValue.trim(),
          'Shared-Culture-Profile-Type'
        );
        if (FilterMode.includes('allocate') || FilterMode.includes('Allocate')) {
          requestObect = makeCultureProfileTypeAllocateScanObj(
            selectedAssociateInfo,
            cardValue === 'Card' ? middlePaneHeaderBadgeThree : middlePaneHeaderBadgeTwo, //if access through module card value is "noCard" and through self then "Crad" card
            0,
            countPage,
            state.scanValue.trim(),
            allocatedTagsArray
          );
        }
        dispatch({ type: LOADER_START });
        dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        dispatch({
          type: GET_CULTUREPROFILE_TYPE_REVIEW_LIST_SAGA,
          payload: {
            request: requestObect,
            BadgeOne: middlePaneHeaderBadgeOne,
            BadgeTwo: middlePaneHeaderBadgeTwo,
            BadgeThree: middlePaneHeaderBadgeThree,
            isMiddlePaneList: true,
            isSelectActive: isSelectActive,
            selectedTagsArray: selectedTagsArray
          }
        });
        hideDisplayPaneThree();
        dispatch({ type: ASSOCIATE_POPUP_CLOSE });
        document.getElementById('middleComponentId').scrollTop = '0px';
      }

      if (typeOfMiddlePaneList === 'MarketplaceAssesseeRoleReviewList') {
        getAssesseeMarketplaceRoleApiCall(
          selectedAssociateInfo,
          middlePaneHeaderBadgeTwo,
          countPage,
          dispatch,
          middlePaneHeaderBadgeOne,
          relatedReviewListDistinctData[0].id,
          state.scanValue.trim(),
          true,
          middlePaneHeader
        );
      }
      if (typeOfMiddlePaneList === 'MarketplaceAssessmentRoleReviewList') {
      }

      if (typeOfMiddlePaneList === 'MarketplaceAssignmentRoleReviewList') {
      }
      if (typeOfMiddlePaneList === 'assesseesRoleAssesseeReviewList') {
        if (reviewMode === 'revise' && headerOne === 'assessees' && headerOneBadgeOne === 'role') {
          let requestObect = makeAssesseeScanRequestObject(
            selectedAssociateInfo,
            '',
            0,
            countPage,
            state.scanValue.trim()
          );
          let revisedGroupObject = {
            id: responseObject.id,
            assesseeRoleName: responseObject.informationBasic.assesseeRoleName,
            assesseeRoleDescription: responseObject.informationBasic.assesseeRoleDescription,
            assesseeRoleStatus: responseObject.informationEngagement.assesseeRoleStatus
          };
          let existingAssesseeId = [];
          let tempArr = relatedReviewListPaneThree[0]?.assessee || [];
          existingAssesseeId = tempArr.map((val) => {
            return val.id;
          });
          // console.log('unselectedTagsArray',unselectedTagsArray);
          getAssesseeeDistinctToAllocate(
            dispatch,
            revisedGroupObject,
            requestObect,
            [],
            'assesseeRoleAssesseeRevise',
            'assesseesRoleAssesseeReviewList',
            selectedTagsArray,
            unselectedTagsArray
          );
        } else {
          getAssesseeRoleAssesseeDistinctApiCall(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo,
            countPage,
            dispatch,
            'distinct',
            relatedReviewListDistinctData[0].id,
            state.scanValue.trim(),
            true,
            middlePaneHeader
          );
        }
      }
      if (typeOfMiddlePaneList === 'cultureProfileTypeCultureProfileReviewList') {
        if (
          reviewMode === 'revise' &&
          headerOne === 'culture-profiles' &&
          headerOneBadgeOne === 'type'
        ) {
          let requestObect = makeCultureProfileScanObj(
            selectedAssociateInfo,
            '',
            countPage,
            0,
            state.scanValue
          );
          let revisedTypeObject = {
            id: responseObject.id,
            cultureProfileTypeName: responseObject.informationBasic.cultureProfileTypeName,
            cultureProfileTypeDescription:
              responseObject.informationBasic.cultureProfileTypeDescription,
            cultureProfileTypeStatus: responseObject.informationEngagement.cultureProfileTypeStatus
          };
          getCultureProfileAllocate(
            dispatch,
            requestObect,
            revisedTypeObject,
            relatedReviewListPaneThree,
            'cultureProfileTypeCultureProfileRevise',
            'cultureProfileTypeCultureProfileReviewList',
            selectedTagsArray
          );
        } else {
          getCultureTypeCultureDistinctApiCall(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo,
            countPage,
            dispatch,
            'distinct',
            relatedReviewListDistinctData[0].id,
            state.scanValue.trim(),
            true,
            true
          );
        }
      }
      if (typeOfMiddlePaneList === 'jobProfileGroupJobProfileReviewList') {
        if (
          reviewMode === 'revise' &&
          headerOne === 'job-profiles' &&
          headerOneBadgeOne === 'group'
        ) {
          let requestObect = makeJobProfileScanObj(
            selectedAssociateInfo,
            '',
            countPage,
            0,
            state.scanValue
          );
          let revisedTypeObject = {
            id: responseObject.id,
            jobProfileGroupName: responseObject.informationBasic.jobProfileGroupName,
            jobProfileGroupDescription: responseObject.informationBasic.jobProfileGroupDescription,
            jobProfileGroupStatus: responseObject.informationEngagement.jobProfileGroupStatus
          };
          getJobProfileAllocateApi(
            dispatch,
            requestObect,
            relatedReviewListPaneThree,
            revisedTypeObject,
            'jobProfileGroupJobProfileRevise',
            'jobProfileGroupJobProfileReviewList',
            selectedTagsArray
          );
        } else {
          getJobProfileGroupJobProfileDistinctApiCall(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo,
            countPage,
            dispatch,
            'distinct',
            relatedReviewListDistinctData[0].id,
            //'',
            state.scanValue.trim(),
            true,
            true
          );
        }
      }
      if (typeOfMiddlePaneList === 'jobProfileTypeJobProfileReviewList') {
        if (
          reviewMode === 'revise' &&
          headerOne === 'job-profiles' &&
          headerOneBadgeOne === 'type'
        ) {
          let requestObect = makeJobProfileScanObj(
            selectedAssociateInfo,
            '',
            countPage,
            0,
            state.scanValue
          );
          let revisedTypeObject = {
            id: responseObject.id,
            jobProfileTypeName: responseObject.informationBasic.jobProfileTypeName,
            jobProfileTypeDescription: responseObject.informationBasic.jobProfileTypeDescription,
            jobProfileTypeStatus: responseObject.informationEngagement.jobProfileTypeStatus
          };
          getJobProfileAllocateApi(
            dispatch,
            requestObect,
            relatedReviewListPaneThree,
            revisedTypeObject,
            'jobProfileTypeJobProfileRevise',
            'jobProfileTypeJobProfileReviewList',
            selectedTagsArray
          );
        } else {
          getJobProfileTypeJobProfileDistinctApiCall(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo,
            countPage,
            dispatch,
            'distinct',
            relatedReviewListDistinctData[0].id,
            state.scanValue.trim(),
            true,
            true
          );
        }
      }
      if (typeOfMiddlePaneList === 'assesseesGroupAssesseeReviewList') {
        if (reviewMode === 'revise' && headerOne === 'assessees' && headerOneBadgeOne === 'group') {
          let requestObect = makeAssesseeScanRequestObject(
            selectedAssociateInfo,
            '', //'activeinactive',
            0,
            countPage,
            state.scanValue.trim()
          );
          let revisedGroupObject = {
            id: responseObject.id,
            assesseeGroupName: responseObject.informationBasic.assesseeGroupName,
            assesseeGroupDescription: responseObject.informationBasic.assesseeGroupDescription,
            assesseeGroupStatus: responseObject.informationEngagement.assesseeGroupStatus
          };
          let existingAssesseeId = [];
          let tempArr = relatedReviewListPaneThree[0]?.assessee || [];
          existingAssesseeId = tempArr.map((val) => {
            return val.id;
          });
          getAssesseeeDistinctToAllocate(
            dispatch,
            revisedGroupObject,
            requestObect,
            [],
            'assesseeGroupAssesseeRevise',
            'assesseesGroupAssesseeReviewList',
            selectedTagsArray,
            unselectedTagsArray
          );
        } else {
          // hideDisplayPaneThree();
          getAssesseeGroupAssesseeDistinctApiCall(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo,
            countPage,
            dispatch,
            'distinct',
            relatedReviewListDistinctData[0].id,
            state.scanValue.trim(),
            true,
            true,
            isSelectActive,
            selectedTagsArray
          );
        }
      }
      if (typeOfMiddlePaneList === 'assignmentGroupAssignmentReviewList') {
        if (
          reviewMode === 'revise' &&
          headerOne === 'assignments' &&
          headerOneBadgeOne === 'group'
        ) {
          let requestObect = makeAssignmentScanRequestObject(
            selectedAssociateInfo,
            '',
            0,
            countPage,
            state.scanValue.trim(),
            'iGuru_Assignment'
          );
          let revisedGroupObject = {
            id: responseObject.id,
            assignmentGroupName: responseObject.informationBasic.assignmentGroupName,
            assignmentGroupDescription: responseObject.informationBasic.assignmentGroupDescription,
            assignmentGroupStatus: responseObject.informationEngagement.assignmentGroupStatus
          };
          getAssignmentDistinctToAllocate(
            dispatch,
            requestObect,
            revisedGroupObject,
            selectedTagsArray,
            'assignmentGroupAssignmentRevise',
            'assignmentGroupAssignmentReviewList'
          );
        } else {
          getAssignmnetGroupAssignmnetDistinctApiCall(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo,
            countPage,
            dispatch,
            'distinct',
            relatedReviewListDistinctData[0].id,
            //selectedTagValue,
            state.scanValue.trim(),
            true
          );
        }
      }
      if (typeOfMiddlePaneList === 'associatesGroupAssociateReviewList') {
        if (
          reviewMode === 'revise' &&
          headerOne === 'associates' &&
          headerOneBadgeOne === 'group'
        ) {
          let requestObect = makeAssociateScanRequestObject(
            selectedAssociateInfo,
            '',
            0,
            countPage,
            state.scanValue
          );
          let revisedGroupObject = {
            id: responseObject.id,
            associateGroupName: responseObject.informationBasic.associateGroupName,
            associateGroupDescription: responseObject.informationBasic.associateGroupDescription,
            associateGroupStatus: responseObject.informationEngagement.associateGroupStatus
          };
          getAssociateDistinctToAllocate(
            dispatch,
            requestObect,
            revisedGroupObject,
            selectedTagsArray,
            'associateGroupAssociateRevise',
            'associatesGroupAssociateReviewList'
          );
        } else {
          getAssociateGroupAssociateDistinctApiCall(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo,
            countPage,
            dispatch,
            'distinct',
            relatedReviewListDistinctData[0].id,
            state.scanValue.trim(),
            true
          );
        }
      }
      if (typeOfMiddlePaneList === 'MarketplaceAssesseeNodeReviewList') {
        // alert("defnwi")
        getAssesseeMarketplaceNodeApiCall(
          selectedAssociateInfo,
          middlePaneHeaderBadgeTwo,
          countPage,
          dispatch,
          middlePaneHeaderBadgeOne,
          relatedReviewListDistinctData[0].id,
          state.scanValue.trim(),
          true,
          'assessees'
        );
      }

      if (typeOfMiddlePaneList === 'MarketplaceAssessmentNodeReviewList') {
        if (nodeViewState === 'list') {
          getMarketplaceAssessmentNodeApiCall(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo,
            countPage,
            dispatch,
            middlePaneHeaderBadgeOne,
            relatedReviewListDistinctData[0].id,
            state.scanValue.trim(),
            true,
            'assessees'
          );
        }
      }

      if (typeOfMiddlePaneList === 'MarketplaceAssignmentNodeReviewList') {
      }

      if (typeOfMiddlePaneList === 'assesseesNodeAssesseeReviewList') {
        if (
          reviewMode === 'revise' &&
          (headerOne === 'associate' || headerOne === 'assessees') &&
          headerOneBadgeOne === 'node'
        ) {
          let requestObect = makeAssesseeScanRequestObject(
            selectedAssociateInfo,
            '',
            0,
            countPage,
            state.scanValue.trim()
          );
          let revisedRoleObject = {
            id: responseObject.id,
            associateNodeName: responseObject.informationBasic.associateNodeName,
            associateNodeDescription: responseObject.informationBasic.associateNodeDescription,
            associateNodeStatus: responseObject.informationEngagement.associateNodeStatus
          };
          getAssesseeeDistinctToAllocate(
            dispatch,
            revisedRoleObject,
            requestObect,
            [],
            'assesseeNodeAssesseeRevise',
            'assesseesNodeAssesseeReviewList',
            selectedTagsArray
          );
        } else {
          getAssesseeNodeAssesseeDistinctApiCall(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo,
            countPage,
            dispatch,
            'distinct',
            relatedReviewListDistinctData[0].id,
            state.scanValue.trim(),
            true,
            'assessees' //true
          );
        }
      }

      if (typeOfMiddlePaneList === 'nodeAssociatesReviewList') {
        if (reviewMode === 'revise' && headerOne === 'associate' && headerOneBadgeOne === 'node') {
          let requestObect = makeAssociateScanRequestObject(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo,
            0,
            countPage,
            state.scanValue.trim()
          );
          let revisedGroupObject = {
            id: responseObject.id,
            associateNodeName: responseObject.informationBasic.associateNodeName,
            associateNodeDescription: responseObject.informationBasic.associateNodeDescription,
            associateNodeStatus: responseObject.informationEngagement.associateNodeStatus
          };
          getAssociateDistinctToAllocate(
            dispatch,
            requestObect,
            revisedGroupObject,
            [],
            'associateNodeAssociateRevise',
            'nodeAssociatesReviewList',
            selectedTagsArray
          );
        } else {
          getNodeRelatedAssociateDistinctApiCall(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo,
            countPage,
            dispatch,
            'distinct',
            relatedReviewListDistinctData[0].id,
            state.scanValue.trim(),
            true,
            'associates'
          );
        }
      }

      // if (typeOfMiddlePaneList === 'assessmentNodeAssessmentReviewList') {
      //   getNodeRelatedAssessmentsDistinctApiCall(
      //     selectedAssociateInfo,
      //     middlePaneHeaderBadgeTwo,
      //     countPage,
      //     dispatch,
      //     'distinct',
      //     selectedTagValue,
      //     state.scanValue.trim(),
      //     true,
      //     'assessments'
      //   );
      // }
      if (typeOfMiddlePaneList === 'assignmentNodeAssignmentReviewList') {
        if (
          reviewMode === 'revise' &&
          (headerOne === 'associate' || headerOne === 'assignments') &&
          headerOneBadgeOne === 'node'
        ) {
          let requestObect = makeAssignmentScanRequestObject(
            selectedAssociateInfo,
            '',
            0,
            countPage,
            state.scanValue.trim(),
            'iGuru_Assignment'
          );
          let revisedRoleObject = {
            id: responseObject.id,
            associateNodeName: responseObject.informationBasic.associateNodeName,
            associateNodeDescription: responseObject.informationBasic.associateNodeDescription,
            associateNodeStatus: responseObject.informationEngagement.associateNodeStatus
          };

          getAssignmentDistinctToAllocate(
            dispatch,
            requestObect,
            revisedRoleObject,
            [],
            'assignmentNodeAssignmentRevise',
            'assignmentNodeAssignmentReviewList',
            selectedTagsArray
          );
        } else {
          getNodeRelatedAssignmentsDistinctApiCall(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo,
            countPage,
            dispatch,
            'distinct',
            relatedReviewListDistinctData[0].id, //selectedTagValue,
            state.scanValue.trim(),
            true,
            'assignments'
          );
        }
      }
      if (
        typeOfMiddlePaneList === 'MarketPlaceCultureProfileNodeCultureProfileDistinctReviewList'
      ) {
        if (
          reviewMode === 'revise' &&
          (headerOne === 'associate' || headerOne === 'culture-profiles') &&
          headerOneBadgeOne === 'node'
        ) {
          let requestObect = makeCultureProfileScanObj(
            selectedAssociateInfo,
            '',
            0,
            countPage,
            state.scanValue.trim(),
            'Shared-Culture-Profile-Node'
          );
          let revisedRoleObject = {
            id: responseObject.id,
            associateNodeName: responseObject.informationBasic.associateNodeName,
            associateNodeDescription: responseObject.informationBasic.associateNodeDescription,
            associateNodeStatus: responseObject.informationEngagement.associateNodeStatus
          };

          getCultureProfileAllocate(
            dispatch,
            requestObect,
            revisedRoleObject,
            [],
            'cultureProfileNodeCultureProfileRevise',
            'cultureProfileNodeCultureProfileReviewList',
            selectedTagsArray
          );
        } else {
          getCultureProfileNodeCultureProfileApiCall(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo,
            countPage,
            dispatch,
            'distinct',
            relatedReviewListDistinctData[0].id, //selectedTagValue,
            state.scanValue.trim(),
            true,
            'cultureProfiles',
            true,
            'Shared-Culture-Profile-Node'
          );
        }
      }
      if (typeOfMiddlePaneList === 'cultureProfileNodeCultureProfileReviewList') {
        if (
          reviewMode === 'revise' &&
          (headerOne === 'associate' || headerOne === 'culture-profiles') &&
          headerOneBadgeOne === 'node'
        ) {
          let requestObect = makeCultureProfileScanObj(
            selectedAssociateInfo,
            '',
            0,
            countPage,
            state.scanValue.trim()
          );
          let revisedRoleObject = {
            id: responseObject.id,
            associateNodeName: responseObject.informationBasic.associateNodeName,
            associateNodeDescription: responseObject.informationBasic.associateNodeDescription,
            associateNodeStatus: responseObject.informationEngagement.associateNodeStatus
          };

          getCultureProfileAllocate(
            dispatch,
            requestObect,
            revisedRoleObject,
            [],
            'cultureProfileNodeCultureProfileRevise',
            'cultureProfileNodeCultureProfileReviewList',
            selectedTagsArray
          );
        } else {
          getCultureProfileNodeCultureProfileApiCall(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo,
            countPage,
            dispatch,
            'distinct',
            relatedReviewListDistinctData[0].id, //selectedTagValue,
            state.scanValue.trim(),
            true,
            'cultureProfiles'
          );
        }
      }
      if (typeOfMiddlePaneList === 'jobProfileNodeJobProfileReviewList') {
        if (
          reviewMode === 'revise' &&
          (headerOne === 'associate' || headerOne === 'job-profiles') &&
          headerOneBadgeOne === 'node'
        ) {
          let requestObect = makeJobProfileScanObj(
            selectedAssociateInfo,
            '',
            0,
            countPage,
            state.scanValue.trim()
          );
          let revisedRoleObject = {
            id: responseObject.id,
            associateNodeName: responseObject.informationBasic.associateNodeName,
            associateNodeDescription: responseObject.informationBasic.associateNodeDescription,
            associateNodeStatus: responseObject.informationEngagement.associateNodeStatus
          };

          getJobProfileAllocateApi(
            dispatch,
            requestObect,
            [],
            revisedRoleObject,
            'jobProfileNodeJobProfileRevise',
            'jobProfileNodeJobProfileReviewList',
            selectedTagsArray
          );
        } else {
          getJobProfileNodeJobProfileApiCall(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo,
            countPage,
            dispatch,
            'distinct',
            relatedReviewListDistinctData[0].id, //selectedTagValue,
            state.scanValue.trim(),
            true,
            'jobProfiles'
          );
        }
      }
      if (typeOfMiddlePaneList === 'assessmentItemReviewList') {
        if (isSelectActive !== '') {
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'relatedReviewListDistinctData', value: [] }
          });
          let filterItem = relatedReviewListDistinctData[0].item.filter((dd) => {
            return (
              dd.informationBasic.itemName.includes(state.scanValue) ||
              dd.informationBasic.itemDescription.includes(state.scanValue)
            );
          });
          let existingItemId = responseObject?.informationFramework?.assessmentItemDistinct?.map(
            (val) => {
              return val.itemTagPrimary;
            }
          );
          let responseOb = [
            {
              assessmentDescription: responseObject?.informationBasic?.assessmentDescription,
              assessmentName: responseObject?.informationBasic?.assessmentName,
              assessmentStatus: responseObject?.informationEngagement?.assessmentStatus,
              id: responseObject?.id,
              item: filterItem
            }
          ];
          dispatch({
            type: RELATED_REVIEWLIST_DISTINCT_DATA,
            payload: responseOb
          });
          dispatch({
            type: SET_MIDDLEPANE_STATE,
            payload: {
              middlePaneHeader: middlePaneHeader,
              middlePaneHeaderBadgeOne: middlePaneHeaderBadgeOne,
              middlePaneHeaderBadgeTwo: middlePaneHeaderBadgeTwo,
              middlePaneHeaderBadgeThree: middlePaneHeaderBadgeThree,
              middlePaneHeaderBadgeFour: '',
              typeOfMiddlePaneList: 'assessmentItemReviewList',
              scanCount: filterItem.length,
              showMiddlePaneState: true,
              isSelectActive: 'multiple',
              selectedTagsArray: existingItemId
            }
          });
        } else {
          getAssessmentItemDistinctApiCall(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo,
            countPage,
            dispatch,
            'distinct',
            selectedTagValue,
            state.scanValue.trim(),
            true
          );
        }
      }
      //group asseseee of assessee

      if (typeOfMiddlePaneList === 'cultureProfileGroupCultureProfileReviewList') {
        if (
          reviewMode === 'revise' &&
          headerOne === 'culture-profiles' &&
          headerOneBadgeOne === 'group'
        ) {
          let requestObect = makeCultureProfileScanObj(
            selectedAssociateInfo,
            '',
            0,
            countPage,
            state.scanValue
          );
          let revisedTypeObject = {
            id: responseObject.id,
            cultureProfileGroupName: responseObject.informationBasic.cultureProfileGroupName,
            cultureProfileGroupDescription:
              responseObject.informationBasic.cultureProfileGroupDescription,
            cultureProfileGroupStatus:
              responseObject.informationEngagement.cultureProfileGroupStatus
          };

          getCultureProfileAllocate(
            dispatch,
            requestObect,
            revisedTypeObject,
            relatedReviewListPaneThree,
            'cultureProfileGroupCultureProfileRevise',
            'cultureProfileGroupCultureProfileReviewList',
            selectedTagsArray
          );
        } else {
          getCultureGroupCultureDistinctApiCall(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo,
            countPage,
            dispatch,
            'distinct',
            relatedReviewListDistinctData[0].id,
            //'',
            state.scanValue.trim(),
            true,
            true
          );
        }
      }

      if (typeOfMiddlePaneList === 'assignmentDistinctCultureProfileReviewList') {
        if (
          reviewMode === 'revise' &&
          headerOne === 'assignment' &&
          headerOneBadgeOne === 'distinct'
        ) {
          let requestObect = makeCultureProfileScanObj(
            selectedAssociateInfo,
            'published',
            countPage,
            0,
            state.scanValue.trim()
          );
          let revisedTypeObject = {
            id: responseObject.id,
            assignmentName: responseObject.informationBasic.assignmentName,
            assignmentDescription: responseObject.informationBasic.assignmentDescription,
            assignmentStatus: responseObject.informationEngagement.assignmentStatus
          };
          getCultureProfileAllocate(
            dispatch,
            requestObect,
            revisedTypeObject,
            relatedReviewListPaneThree,
            'assignmentDistinctCultureProfileRevise',
            'assignmentDistinctCultureProfileReviewList',
            selectedTagsArray
          );
        } else {
          getAssignmneCultureProfileDistinctApiCall(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo,
            countPage,
            dispatch,
            'distinct',
            cachedPreviousSelectedTagValue,
            state.scanValue.trim(),
            true,
            reviewMode
          );
        }
      }

      if (typeOfMiddlePaneList === 'assignmentDistinctJobProfileReviewList') {
        if (
          reviewMode === 'revise' &&
          headerOne === 'assignment' &&
          headerOneBadgeOne === 'distinct'
        ) {
          let requestObect = makeJobProfileScanObj(
            selectedAssociateInfo,
            'published',
            countPage,
            0,
            state.scanValue.trim()
          );
          let revisedTypeObject = {
            id: responseObject.id,
            assignmentName: responseObject.informationBasic.assignmentName,
            assignmentDescription: responseObject.informationBasic.assignmentDescription,
            assignmentStatus: responseObject.informationEngagement.assignmentStatus
          };
          getJobProfileAllocateApi(
            dispatch,
            requestObect,
            relatedReviewListPaneThree,
            revisedTypeObject,
            'assignmentDistinctJobProfileRevise',
            'assignmentDistinctJobProfileReviewList',
            selectedTagsArray
          );
          // } else if (reviewMode === 'review' && middlePaneHeader === 'job-profiles' && middlePaneHeaderBadgeOne === 'distinct') {
          // let requestObect = makeJobProfileScanObj(
          //   selectedAssociateInfo,
          //   middlePaneHeaderBadgeTwo,
          //   0,
          //   countPage,
          //   state.scanValue.trim()
          // );
          // getJobProfileAllocateApi(
          //   dispatch,
          //   requestObect,
          //   relatedReviewListDistinctData,
          //   relatedReviewListDistinctData[0],
          //   '',
          //   'assignmentDistinctJobProfileReviewList',
          //   selectedTagsArray
          // );
          // console.log(requestObect, '**')
        } else {
          debugger
          // getAssignmneCultureProfileDistinctApiCall(
          getAssignmneJobProfileDistinctApiCall(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo,
            countPage,
            dispatch,
            'distinct',
            cachedPreviousSelectedTagValue || selectedTagValue,
            state.scanValue.trim(),
            true,
            reviewMode
          );
        }
      }

      // type of assesse
      if (typeOfMiddlePaneList === 'MarketplaceAssesseeTypeassesseeReviewList') {
        getAssesseeMarketplaceTypeApiCall(
          selectedAssociateInfo,
          middlePaneHeaderBadgeTwo,
          countPage,
          dispatch,
          'distinct',
          relatedReviewListDistinctData[0].id,
          state.scanValue.trim(),
          true,
          'assessees'
        );
      }

      if (typeOfMiddlePaneList === 'MarketplaceAssessmentTypeReviewList') {
        getMarketplaceAssessmentTypeApiCall(
          selectedAssociateInfo,
          middlePaneHeaderBadgeTwo,
          countPage,
          dispatch,
          middlePaneHeaderBadgeOne,
          relatedReviewListDistinctData[0].id,
          state.scanValue.trim(),
          true,
          'assessments'
        );
      }

      if (typeOfMiddlePaneList === 'assesseesTypeAssesseeReviewList') {
        if (reviewMode === 'revise' && headerOne === 'assessees' && headerOneBadgeOne === 'type') {
          let requestObect = makeAssesseeScanRequestObject(
            selectedAssociateInfo,
            '',
            0,
            countPage,
            state.scanValue.trim()
          );
          let revisedGroupObject = {
            id: responseObject.id,
            assesseeTypeName: responseObject.informationBasic.assesseeTypeName,
            assesseeTypeDescription: responseObject.informationBasic.assesseeTypeDescription,
            assesseeTypeStatus: responseObject.informationEngagement.assesseeTypeStatus
          };
          let existingAssesseeId = [];
          let tempArr = relatedReviewListPaneThree[0]?.assessee || [];
          existingAssesseeId = tempArr.map((val) => {
            return val.id;
          });
          getAssesseeeDistinctToAllocate(
            dispatch,
            revisedGroupObject,
            requestObect,
            [],
            'assesseeTypeAssesseeRevise',
            'assesseesTypeAssesseeReviewList',
            selectedTagsArray,
            unselectedTagsArray
          );
        } else {
          getAssesseeTypeAssesseeDistinctApiCall(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo,
            countPage,
            dispatch,
            'distinct',
            relatedReviewListDistinctData[0].id,
            state.scanValue.trim(),
            true,
            'assessees'
          );
        }
      }

      if (typeOfMiddlePaneList === 'associatesTypeAssociateReviewList') {
        if (reviewMode === 'revise' && headerOne === 'associates' && headerOneBadgeOne === 'type') {
          let requestObect = makeAssociateScanRequestObject(
            selectedAssociateInfo,
            '',
            0,
            countPage,
            state.scanValue
          );
          let revisedGroupObject = {
            id: responseObject.id,
            associateTypeName: responseObject.informationBasic.associateTypeName,
            associateTypeDescription: responseObject.informationBasic.associateTypeDescription,
            associateTypeStatus: responseObject.informationEngagement.associateTypeStatus
          };
          getAssociateDistinctToAllocate(
            dispatch,
            requestObect,
            revisedGroupObject,
            selectedTagsArray,
            'associateTypeAssociateRevise',
            'associatesTypeAssociateReviewList'
          );
        } else {
          getAssociateTypeAssociateDistinctApiCall(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo,
            countPage,
            dispatch,
            'distinct',
            relatedReviewListDistinctData[0].id,
            state.scanValue.trim(),
            true,
            'associates'
          );
        }
      }

      if (typeOfMiddlePaneList === 'itemTypeItemReviewList') {
        if (reviewMode === 'revise' && headerOne === 'items' && headerOneBadgeOne === 'type') {
          let requestObect = makeItemScanObj(
            selectedAssociateInfo,
            '',
            countPage,
            0,
            state.scanValue
          );
          let revisedGroupObject = {
            id: responseObject.id,
            itemTypeName: responseObject.informationBasic.itemTypeName,
            itemTypeDescription: responseObject.informationBasic.itemTypeDescription,
            itemTypeStatus: responseObject.informationEngagement.itemTypeStatus
          };
          getItemAllocateApi(
            dispatch,
            requestObect,
            revisedGroupObject,
            relatedReviewListPaneThree,
            'itemTypeItemeRevise',
            'itemTypeItemReviewList',
            selectedTagsArray
          );
        } else {
          getItemTypeItemDistinctApiCall(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo,
            countPage,
            dispatch,
            'distinct',
            relatedReviewListDistinctData[0].id,
            state.scanValue.trim(),
            true
          );
        }
      }
      if (typeOfMiddlePaneList === 'itemGroupItemReviewList') {
        if (reviewMode === 'revise' && headerOne === 'items' && headerOneBadgeOne === 'group') {
          let requestObect = makeItemScanObj(
            selectedAssociateInfo,
            '',
            0,
            countPage,
            state.scanValue
          );
          let revisedGroupObject = {
            id: responseObject.id,
            itemGroupName: responseObject.informationBasic.itemGroupName,
            itemGroupDescription: responseObject.informationBasic.itemGroupDescription,
            itemGroupStatus: responseObject.informationEngagement.itemGroupStatus
          };
          getItemAllocateApi(
            dispatch,
            requestObect,
            revisedGroupObject,
            relatedReviewListPaneThree,
            'itemGroupItemeRevise',
            'itemGroupItemReviewList',
            selectedTagsArray
          );
        } else {
          getItemGroupItemDistinctApiCall(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo,
            countPage,
            dispatch,
            'distinct',
            relatedReviewListDistinctData[0].id,
            state.scanValue.trim(),
            true
          );
        }
      }

      if (typeOfMiddlePaneList === 'associatesRoleAssociateReviewList') {
        if (reviewMode === 'revise' && headerOne === 'associates' && headerOneBadgeOne === 'role') {
          let requestObect = makeAssociateScanRequestObject(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo,
            0,
            countPage,
            state.scanValue
          );
          let revisedGroupObject = {
            id: responseObject.id,
            associateRoleName: responseObject.informationBasic.associateRoleName,
            associateRoleDescription: responseObject.informationBasic.associateRoleDescription,
            associateRoleStatus: responseObject.informationEngagement.associateRoleStatus
          };
          getAssociateDistinctToAllocate(
            dispatch,
            requestObect,
            revisedGroupObject,
            selectedTagsArray,
            'associateRoleAssociateRevise',
            'associatesRoleAssociateReviewList'
          );
        } else {
          getAssociateRoleAssociateDistinctApiCall(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo,
            countPage,
            dispatch,
            'distinct',
            relatedReviewListDistinctData[0].id,
            state.scanValue.trim(),
            true
          );
        }
      }
      if (typeOfMiddlePaneList === 'assessmentNodeAssessmentReviewList') {
        if (
          reviewMode === 'revise' &&
          (headerOne === 'associate' || headerOne === 'assessments') &&
          headerOneBadgeOne === 'node'
        ) {
          let requestObect = makeAssessmentScanRequestObject(
            selectedAssociateInfo,
            '',
            0,
            countPage,
            state.scanValue.trim()
          );
          let revisedRoleObject = {
            id: responseObject.id,
            associateNodeName: responseObject.informationBasic.associateNodeName,
            associateNodeDescription: responseObject.informationBasic.associateNodeDescription,
            associateNodeStatus: responseObject.informationEngagement.associateNodeStatus
          };

          // getAssessmentDistinctToAllocate(
          //   dispatch,
          //   revisedRoleObject,
          //   requestObect,
          //   [],
          //   'assessmentGroupAssessmenteRevise',
          //   'assessmentGroupAssessmentReviewList',
          //   selectedTagsArray
          // );
          getAssessmentDistinctToAllocate(
            revisedRoleObject,
            requestObect,
            [],
            dispatch,
            'assessmentNodeAssessmentRevise',
            'assessmentNodeAssessmentReviewList',
            selectedTagsArray
          );
        } else {
          getNodeRelatedAssessmentsDistinctApiCall(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo,
            countPage,
            dispatch,
            'distinct',
            relatedReviewListDistinctData[0].id, //selectedTagValue,
            state.scanValue.trim(),
            true,
            middlePaneHeader
          );
        }
      }
      if (typeOfMiddlePaneList === 'itemNodeItemReviewList') {
        if (
          reviewMode === 'revise' &&
          (headerOne === 'associate' || headerOne === 'items') &&
          headerOneBadgeOne === 'node'
        ) {
          let requestObect = makeItemScanObj(
            selectedAssociateInfo,
            '',
            countPage,
            0,
            state.scanValue
          );
          let revisedRoleObject = {
            id: responseObject.id,
            associateNodeName: responseObject.informationBasic.associateNodeName,
            associateNodeDescription: responseObject.informationBasic.associateNodeDescription,
            associateNodeStatus: responseObject.informationEngagement.associateNodeStatus
          };
          getItemAllocateApi(
            dispatch,
            requestObect,
            revisedRoleObject,
            [],
            'itemNodeItemRevise',
            'itemNodeItemReviewList',
            selectedTagsArray
          );
        } else {
          getNodeRelatedItemsDistinctApiCall(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo,
            countPage,
            dispatch,
            'distinct',
            relatedReviewListDistinctData[0].id, //selectedTagValue,
            state.scanValue.trim(),
            true,
            middlePaneHeader
          );
        }
      }
      if (typeOfMiddlePaneList === 'assignmentTypeAssignmentReviewList') {
        if (
          reviewMode === 'revise' &&
          headerOne === 'assignments' &&
          headerOneBadgeOne === 'type'
        ) {
          let requestObect = makeAssignmentScanRequestObject(
            selectedAssociateInfo,
            '',
            0,
            countPage,
            state.scanValue.trim(),
            'iGuru_Assignment'
          );
          let revisedGroupObject = {
            id: responseObject.id,
            assignmentTypeName: responseObject.informationBasic.assignmentTypeName,
            assignmentTypeDescription: responseObject.informationBasic.assignmentTypeDescription,
            assignmentTypeStatus: responseObject.informationEngagement.assignmentTypeStatus
          };
          getAssignmentDistinctToAllocate(
            dispatch,
            requestObect,
            revisedGroupObject,
            selectedTagsArray,
            'assignmentTypeAssignmentRevise',
            'assignmentTypeAssignmentReviewList'
          );
        } else {
          getAssignmnetTypeAssignmnetDistinctApiCall(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo,
            countPage,
            dispatch,
            'distinct',
            relatedReviewListDistinctData[0].id,
            state.scanValue.trim(),
            true,
            assignmentCreateType
          );
        }
      }
      if (
        (typeOfMiddlePaneList === 'associatesNodeDistinctReviewList' ||
          typeOfMiddlePaneList === 'associateNodeDistinctReviewList') &&
        nodeViewState === 'list'
      ) {
        console.log('reviewListDistinctData', reviewListDistinctData);
        let listData = [];
        let reviseList = [];
        if (typeOfMiddlePaneList === 'associateNodeDistinctReviewList') {
          getInternalNodeApiCall(
            selectedAssociateInfo,
            middlePaneHeaderBadgeThree || middlePaneHeaderBadgeTwo,
            countPage,
            dispatch,
            middlePaneHeaderBadgeOne,
            middlePaneHeaderBadgeThree,
            nodeViewState,
            middlePaneHeader,
            isSelectActive,
            selectedTagsArray,
            state.scanValue.toLowerCase(),
            FilterMode
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          // listData = reviewListDistinctData[0].associateNodeDescendantAll.filter(function (value) {
          //   let name = value.informationBasic.associateNodeName.toLowerCase();
          //   let desc = value.informationBasic.associateNodeDescription.toLowerCase();
          //   return name.includes(state.scanValue) || desc.includes(state.scanValue);
          // });
          // // let reviseList = listData;
          // reviseList = [
          //   {
          //     associateDescendantAll: listData,
          //     associateRoot: reviewListDistinctData[0].associateRoot
          //   }
          // ];
          // dispatch({
          //   type: SET_DISPLAY_TWO_SINGLE_STATE,
          //   payload: { stateName: 'reviewListDistinctData', value: reviseList }
          // });
          // dispatch({
          //   type: SET_DISPLAY_TWO_SINGLE_STATE,
          //   payload: { stateName: 'scanCount', value: listData.length + 1 }
          // });
        }
        if (typeOfMiddlePaneList === 'associatesNodeDistinctReviewList') {
          // listData = reviewListDistinctData[0].associateDescendantAll.filter(function (value) {
          //   let name = value.informationBasic.associateName.toLowerCase();
          //   let desc = value.informationBasic.associateDescription.toLowerCase();
          //   return name.includes(state.scanValue) || desc.includes(state.scanValue);
          // });
          // reviseList = [
          //   {
          //     associateDescendantAll: listData,
          //     associateRoot: reviewListDistinctData[0].associateRoot
          //   }
          // ];
          getAssociateNodeApiCall(
            selectedAssociateInfo,
            middlePaneHeaderBadgeTwo,
            countPage,
            dispatch,
            middlePaneHeaderBadgeOne,
            middlePaneHeaderBadgeOne,
            nodeViewState,
            state.scanValue.trim(),
            isSelectActive,
            selectedTagsArray
          );
        }

        // dispatch({
        //   type: SET_DISPLAY_TWO_SINGLE_STATE,
        //   payload: { stateName: 'reviewListDistinctData', value: reviseList }
        // });
        // dispatch({
        //   type: SET_DISPLAY_TWO_SINGLE_STATE,
        //   payload: { stateName: 'scanCount', value: listData.length+1 }
        // });
      }
      if (typeOfMiddlePaneList === 'assesseeAssignmentDistinctReviewList') {
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'middlePaneSelectedValue', value: '' }
        });
        getAssesseeSelfAssignmentScanList(
          selectedAssociateInfo,
          countPage,
          dispatch,
          middlePaneHeaderBadgeOne,
          '',
          state.scanValue.trim(),
          middlePaneHeaderBadgeTwo
        );
      }
      // extra condition if (typeOfMiddlePaneList === 'assesseesAssginmentAssessmentReviewList') {
      //   let searchValue = state.scanValue.toLocaleLowerCase();
      //   let selectedAssignment = reviewListDistinctData.filter(
      //     (x) => x.assesseeAssignmentId === relatedReviewListDistinctData[0].assesseeAssignmentId
      //   );
      //   let reviseassessment = [];
      //   selectedAssignment[0].assesseeAssignmentAssessmentDistinct.forEach((assessment) => {
      //     let assessmentName =
      //       assessment.assesseeAssignmentAssessmentName.toLowerCase() +
      //       assessment.assesseeAssignmentAssessmentDescription.toLowerCase();
      //     assessmentName.includes(searchValue) && reviseassessment.push(assessment);
      //   });
      //   let revisedRelatedObject = {
      //     ...relatedReviewListDistinctData[0],
      //     assesseeAssignmentAssessmentDistinct: reviseassessment
      //   };
      //   dispatch({
      //     type: SET_DISPLAY_TWO_SINGLE_STATE,
      //     payload: {
      //       stateName: 'relatedReviewListDistinctData',
      //       value: [revisedRelatedObject]
      //     }
      //   });
      //   console.log('reviseassessment', reviseassessment);
      // }
      if (typeOfMiddlePaneList === 'assesseeRelatedAssociate') {
        console.log('signInRes', signInRes);
        let searchValue = state.scanValue.toLocaleLowerCase();
        let reviseassociate = [];
        signInRes?.signInResponse.forEach((asso) => {
          let associateName =
            asso.associate?.informationBasic.associateName.toLowerCase() +
            asso.associate?.informationBasic.associateDescription.toLowerCase();
          associateName.includes(searchValue) && reviseassociate.push(asso);
        });
        dispatch({ type: SET_USER, payload: reviseassociate });
      }

      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'scanString', value: state.scanValue.trim() }
      });
      if (typeOfMiddlePaneList === 'assesseeRelatedAssociate') {
        console.log('signInRes', signInRes);
        let searchValue = state.scanValue.toLocaleLowerCase();
        let reviseassociate = [];
        signInRes?.signInResponse.forEach((asso) => {
          let associateName =
            asso.associate?.informationBasic.associateName?.toLowerCase() +
            asso.associate?.informationContact.associateAddressWebsite.associateAddressWebsitePrimary?.toLowerCase() +
            asso.associate?.informationContact.associateAddressWebsite.associateAddressWebsiteSecondary?.toLowerCase() +
            asso.associate?.informationEngagement.associateTag.associateTagPrimary?.toLowerCase() +
            asso.associate?.informationBasic.associateDescription.toLowerCase();
          associateName.includes(searchValue) && reviseassociate.push(asso);
        });
        dispatch({ type: SET_USER, payload: reviseassociate });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'scanCount', value: reviseassociate.length }
        });
      }
      if (typeOfMiddlePaneList === 'assessmentversionsReviewList') {
        console.log('assessmentResponseObject', assessmentResponseObject);
        let searchValue = state.scanValue.toLocaleLowerCase();
        let reviseMetrics = [];
        assessmentResponseObject?.informationFramework?.assessmentSectionFramework[0].assessmentVersionFramework.forEach(
          (item) => {
            let versionName =
              item.assessmentVersionName.toLowerCase() +
              item.assessmentVersionDescription.toLowerCase();
            versionName.includes(searchValue) && reviseMetrics.push(item);
          }
        );
        let revisedRelatedObject = {
          ...relatedReviewListDistinctData[0],
          versions: reviseMetrics
        };
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'relatedReviewListDistinctData',
            value: [revisedRelatedObject]
          }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'scanCount',
            value: reviseMetrics.length
          }
        });
      }
      if (typeOfMiddlePaneList === 'assessmentscoresReviewList') {
        console.log('assessmentResponseObject', assessmentResponseObject);
        let searchValue = state.scanValue.toLocaleLowerCase();
        let reviseMetrics = [];
        assessmentResponseObject?.informationFramework?.assessmentScoreFramework.forEach((item) => {
          let scoreName =
            item.assessmentScoreName.toLowerCase() + item.assessmentScoreDescription.toLowerCase();
          scoreName.includes(searchValue) && reviseMetrics.push(item);
        });
        let revisedRelatedObject = {
          ...relatedReviewListDistinctData[0],
          scores: reviseMetrics
        };
        // console.log('searched revisedRelatedObject', revisedRelatedObject);
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'relatedReviewListDistinctData',
            value: [revisedRelatedObject]
          }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'scanCount',
            value: reviseMetrics.length
          }
        });
      }
      if (typeOfMiddlePaneList === 'gaugeDistinctMetricDistinctReviewList') {
        //for second time / always search on original array list in all element
        console.log('reviewListDistinctData', reviewListDistinctData);
        console.log('relatedReviewListDistinctData', relatedReviewListDistinctData);
        const listDistinctData = reviewListDistinctData.filter((item) => {
          return item.id === relatedReviewListDistinctData[0].id;
        });
        console.log('original arrey listDistinctData', listDistinctData);
        const gaugeMetricList = listDistinctData[0]?.iGaugeOccupationFrameworkOneGaugeMetric;
        console.log('relatedReviewListDistinctData', relatedReviewListDistinctData);
        console.log('gaugeMetricList', gaugeMetricList);
        let searchValue = state.scanValue.toLocaleLowerCase();
        let reviseMetrics = [];
        gaugeMetricList.forEach((item) => {
          let matricsName =
            item.iGaugeOccupationFrameworkOneGaugeMetricLabel.toLowerCase() +
            item.iGaugeOccupationFrameworkOneGaugeMetricDescription.toLowerCase();
          matricsName.includes(searchValue) && reviseMetrics.push(item);
        });
        // console.log('searched reviseMetrics', reviseMetrics);
        dispatch({ type: SET_USER, payload: reviseMetrics });
        let revisedRelatedObject = {
          ...relatedReviewListDistinctData[0],
          iGaugeOccupationFrameworkOneGaugeMetric: reviseMetrics
        };
        // console.log('searched revisedRelatedObject', revisedRelatedObject);
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'relatedReviewListDistinctData',
            value: [revisedRelatedObject]
          }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'scanCount',
            value: reviseMetrics.length
          }
        });
      }
      if (typeOfMiddlePaneList === 'assessmentSectionItemDistinctReviewList') {
        console.log('reviewListDistinctData', reviewListDistinctData);
        console.log('relatedReviewListDistinctData', relatedReviewListDistinctData);
        //for second time / always search on original array list in all element
        const listDistinctData = relatedReviewListDistinctData;

        console.log('selected assesment', listDistinctData);
        const versionList = listDistinctData[0]?.item;
        console.log('versionList', versionList);
        let searchValue = state.scanValue.toLocaleLowerCase();
        let scanedVersion = [];
        versionList.forEach((el) => {
          let versionName =
            el.informationBasic.itemName.toLowerCase() +
            el.informationBasic.itemDescription.toLowerCase();
          versionName.includes(searchValue) && scanedVersion.push(el);
        });
        let revisedRelatedObject = {
          ...relatedReviewListDistinctData[0],
          item: scanedVersion
        };
        console.log('searched revisedRelatedObject', revisedRelatedObject);
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'relatedReviewListDistinctData',
            value: [revisedRelatedObject]
          }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'scanCount',
            value: scanedVersion.length
          }
        });
      }
      // if (typeOfMiddlePaneList === 'cultureProfilerDistinctReviewList') {
      //   console.log('reviewListDistinctData', reviewListDistinctData);
      //   console.log('relatedReviewListDistinctData', relatedReviewListDistinctData);
      // }
      // if (typeOfMiddlePaneList === 'jobProfilerDistinctReviewList') {
      //   console.log('reviewListDistinctData', reviewListDistinctData);
      //   console.log('relatedReviewListDistinctData', relatedReviewListDistinctData);
      // }
      if (typeOfMiddlePaneList === 'jobCometancyDistinctReviewList') {
        console.log('reviewListDistinctData', reviewListDistinctData);
        console.log('relatedReviewListDistinctData', relatedReviewListDistinctData);
        // dispatch({
        //   type: SET_DISPLAY_TWO_SINGLE_STATE,
        //   payload: {
        //     stateName: 'relatedReviewListDistinctDataDuplicate',
        //     value: [relatedReviewListDistinctData]
        //   }
        // });
        //for second time / always search on original array list in all element
        console.log(
          'relatedReviewListDistinctDataDuplicate',
          relatedReviewListDistinctDataDuplicate.length
        );
        const cometancyList = relatedReviewListDistinctDataDuplicate[0]?.jobCompetency;
        // console.log('orignal cometancyList', cometancyList.length);
        let searchValue = state.scanValue.toLocaleLowerCase();
        let scanedjobCometancy = [];
        cometancyList.forEach((el) => {
          let cometancyName =
            el.iGaugeOccupationFrameworkOneClusterName.toLowerCase() +
            el.iGaugeOccupationFrameworkOneClusterDescription.toLowerCase();
          cometancyName.includes(searchValue) && scanedjobCometancy.push(el);
        });
        // console.log('scanedjobCometancy',scanedjobCometancy.length);
        let revisedRelatedObject = {
          ...relatedReviewListDistinctData[0],
          jobCometancy: scanedjobCometancy
        };
        console.log('searched revisedRelatedObject', revisedRelatedObject);
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'relatedReviewListDistinctData',
            value: [revisedRelatedObject]
          }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'scanCount',
            value: scanedjobCometancy.length
          }
        });
        hideDisplayPaneThree();
      }
      if (typeOfMiddlePaneList === 'jobDomainDistinctReviewList') {
        console.log('reviewListDistinctData', reviewListDistinctData);
        console.log('relatedReviewListDistinctData', relatedReviewListDistinctData);
        // dispatch({
        //   type: SET_DISPLAY_TWO_SINGLE_STATE,
        //   payload: {
        //     stateName: 'relatedReviewListDistinctDataDuplicate',
        //     value: [relatedReviewListDistinctData]
        //   }
        // });
        //for second time / always search on original array list in all element
        console.log(
          'relatedReviewListDistinctDataDuplicate',
          relatedReviewListDistinctDataDuplicate.length
        );
        const jobDomainList = relatedReviewListDistinctDataDuplicate[0]?.jobDomain;
        console.log('orignal jobDomainList', jobDomainList.length);
        let searchValue = state.scanValue.toLocaleLowerCase();
        let scanedjobDomain = [];
        jobDomainList.forEach((el) => {
          let cometancyName =
            el.iGaugeOccupationFrameworkOneClusterName.toLowerCase() +
            el.iGaugeOccupationFrameworkOneClusterDescription.toLowerCase();
          cometancyName.includes(searchValue) && scanedjobDomain.push(el);
        });
        console.log('scanedjobDomain', scanedjobDomain.length);
        let revisedRelatedObject = {
          ...relatedReviewListDistinctData[0],
          jobDomain: scanedjobDomain
        };
        console.log('searched revisedRelatedObject', revisedRelatedObject);
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'relatedReviewListDistinctData',
            value: [revisedRelatedObject]
          }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'scanCount',
            value: scanedjobDomain.length
          }
        });
        hideDisplayPaneThree();
      }
      if (typeOfMiddlePaneList === 'jobFunctionDistinctReviewList') {
        console.log('reviewListDistinctData', reviewListDistinctData);
        console.log('relatedReviewListDistinctData', relatedReviewListDistinctData);
        // dispatch({
        //   type: SET_DISPLAY_TWO_SINGLE_STATE,
        //   payload: {
        //     stateName: 'relatedReviewListDistinctDataDuplicate',
        //     value: [relatedReviewListDistinctData]
        //   }
        // });
        //for second time / always search on original array list in all element
        console.log(
          'relatedReviewListDistinctDataDuplicate',
          relatedReviewListDistinctDataDuplicate.length
        );
        const jobFunctionList = relatedReviewListDistinctDataDuplicate[0]?.jobFunction;
        console.log('orignal jobFunctionList', jobFunctionList.length);
        let searchValue = state.scanValue.toLocaleLowerCase();
        let scanedjobFunction = [];
        jobFunctionList.forEach((el) => {
          let cometancyName =
            el.iGaugeOccupationFrameworkOneClusterName.toLowerCase() +
            el.iGaugeOccupationFrameworkOneClusterDescription.toLowerCase();
          cometancyName.includes(searchValue) && scanedjobFunction.push(el);
        });
        console.log('scanedjobFunction', scanedjobFunction.length);
        let revisedRelatedObject = {
          ...relatedReviewListDistinctData[0],
          jobFunction: scanedjobFunction
        };
        console.log('searched revisedRelatedObject', revisedRelatedObject);
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'relatedReviewListDistinctData',
            value: [revisedRelatedObject]
          }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'scanCount',
            value: scanedjobFunction.length
          }
        });
        hideDisplayPaneThree();
      }
      if (typeOfMiddlePaneList === 'jobRoleDistinctReviewList') {
        console.log('reviewListDistinctData', reviewListDistinctData);
        console.log('relatedReviewListDistinctData', relatedReviewListDistinctData);
        // dispatch({
        //   type: SET_DISPLAY_TWO_SINGLE_STATE,
        //   payload: {
        //     stateName: 'relatedReviewListDistinctDataDuplicate',
        //     value: [relatedReviewListDistinctData]
        //   }
        // });
        //for second time / always search on original array list in all element
        console.log(
          'relatedReviewListDistinctDataDuplicate',
          relatedReviewListDistinctDataDuplicate.length
        );
        const jobRoleList = relatedReviewListDistinctDataDuplicate[0]?.jobRole;
        console.log('orignal jobRoleList', jobRoleList.length);
        let searchValue = state.scanValue.toLocaleLowerCase();
        let scanedjobRole = [];
        jobRoleList.forEach((el) => {
          let cometancyName =
            el.iGaugeOccupationFrameworkOneClusterName.toLowerCase() +
            el.iGaugeOccupationFrameworkOneClusterDescription.toLowerCase();
          cometancyName.includes(searchValue) && scanedjobRole.push(el);
        });
        console.log('scanedjobRole', scanedjobRole.length);
        let revisedRelatedObject = {
          ...relatedReviewListDistinctData[0],
          jobRole: scanedjobRole
        };
        console.log('searched revisedRelatedObject', revisedRelatedObject);
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'relatedReviewListDistinctData',
            value: [revisedRelatedObject]
          }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'scanCount',
            value: scanedjobRole.length
          }
        });
        hideDisplayPaneThree();
      }
      if (typeOfMiddlePaneList === 'cultureDimensionsDistinctReviewList') {
        console.log('reviewListDistinctData', reviewListDistinctData);
        console.log('relatedReviewListDistinctData', relatedReviewListDistinctData);
        // dispatch({
        //   type: SET_DISPLAY_TWO_SINGLE_STATE,
        //   payload: {
        //     stateName: 'relatedReviewListDistinctDataDuplicate',
        //     value: [relatedReviewListDistinctData]
        //   }
        // });
        //for second time / always search on original array list in all element
        console.log(
          'relatedReviewListDistinctDataDuplicate',
          relatedReviewListDistinctDataDuplicate.length
        );
        const cultureDimensionList = relatedReviewListDistinctDataDuplicate[0]?.cultureDimension;
        console.log('orignal cultureDimensionList', cultureDimensionList.length);
        let searchValue = state.scanValue.toLocaleLowerCase();
        let scanedcultureDimension = [];
        cultureDimensionList.forEach((el) => {
          let cometancyName =
            el.iGaugeOccupationFrameworkOneClusterName.toLowerCase() +
            el.iGaugeOccupationFrameworkOneClusterDescription.toLowerCase();
          cometancyName.includes(searchValue) && scanedcultureDimension.push(el);
        });
        console.log('scanedcultureDimension', scanedcultureDimension.length);
        let revisedRelatedObject = {
          ...relatedReviewListDistinctData[0],
          cultureDimensions: scanedcultureDimension
        };
        console.log('searched revisedRelatedObject', revisedRelatedObject);
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'relatedReviewListDistinctData',
            value: [revisedRelatedObject]
          }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'scanCount',
            value: scanedcultureDimension.length
          }
        });
        hideDisplayPaneThree();
      }
      if (typeOfMiddlePaneList === 'clusterDistinctReviewList') {
        console.log('reviewListDistinctData', reviewListDistinctData);
        console.log('relatedReviewListDistinctData', relatedReviewListDistinctData);
        // dispatch({
        //   type: SET_DISPLAY_TWO_SINGLE_STATE,
        //   payload: {
        //     stateName: 'relatedReviewListDistinctDataDuplicate',
        //     value: [relatedReviewListDistinctData]
        //   }
        // });
        //for second time / always search on original array list in all element
        console.log(
          'relatedReviewListDistinctDataDuplicate',
          relatedReviewListDistinctDataDuplicate.length
        );
        const cultureDimensionList = relatedReviewListDistinctDataDuplicate[0]?.cultureDimension;
        console.log('orignal cultureDimensionList', cultureDimensionList.length);
        let searchValue = state.scanValue.toLocaleLowerCase();
        let scanedcultureDimension = [];
        cultureDimensionList.forEach((el) => {
          let cometancyName =
            el.iGaugeOccupationFrameworkOneClusterName.toLowerCase() +
            el.iGaugeOccupationFrameworkOneClusterDescription.toLowerCase();
          cometancyName.includes(searchValue) && scanedcultureDimension.push(el);
        });
        console.log('scanedcultureDimension', scanedcultureDimension.length);
        let revisedRelatedObject = {
          ...relatedReviewListDistinctData[0],
          clustersDimension: scanedcultureDimension
        };
        console.log('searched revisedRelatedObject', revisedRelatedObject);
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'relatedReviewListDistinctData',
            value: [revisedRelatedObject]
          }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'scanCount',
            value: scanedcultureDimension.length
          }
        });
        hideDisplayPaneThree();
      }
      if (
        typeOfMiddlePaneList === 'assesseeDistinctAssignmentDistinctReviewList' ||
        typeOfMiddlePaneList === 'assesseeGroupAssesseeAssignmentDistinct'
      ) {

        let slectedGroupObj = reviewListDistinctData?.filter(
          (x) => x.id === relatedReviewListDistinctData[0].id
        );
        console.log('slectedGroupObj', slectedGroupObj);

        let responseObj = relatedReviewListDistinctData[0]?.assessee.filter(
          (x) => x.id === selectedTagValue
        );
        let relatedObj = {
          ...relatedReviewListDistinctData[0],
          assesseeInfo: responseObj[0].informationBasic,
          assesseeGroupInfo: slectedGroupObj[0].informationBasic
        };

        assesseeAssignmentReviewListApi(
          selectedAssociateInfo,
          selectedTagValue,
          dispatch,
          responseObj[0].id,
          relatedObj,
          middlePaneHeaderBadgeTwo,
          state.scanValue.trim(),
          typeOfMiddlePaneList,
          isSelectActive,
          selectedTagsArray,
          assignmentCreateType
        );
      }
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'scanString', value: state.scanValue.trim() }
      });
    }
    dispatch({ type: POPUP_CLOSE });

    if (typeOfMiddlePaneList === 'gaugeDistinctReviewList') {
      getGuageDistinctApiCall(
        selectedAssociateInfo,
        middlePaneHeaderBadgeTwo,
        dispatch,
        middlePaneHeaderBadgeOne,
        state.scanValue.trim(),
        isSelectActive,
        selectedTagsArray
      );
    }
    // if (typeOfMiddlePaneList === 'associateDistinctShareReviewList') {
    //   let searchObj = {
    //     condition: 'in',
    //     value: {
    //       in: ['UNCONFIRMED', 'CONFIRMED']
    //     }
    //   };
    //   let requestObect = {
    //     module: 'Associate-Distinct',
    //     action: 'associateSharedReviewList',
    //     requestObject: {
    //       assesseeId: selectedAssociateInfo?.assesseeId,
    //       associateId:
    //         selectedAssociateInfo?.associate?.informationEngagement.associateTag
    //           .associateTagPrimary,
    //       countPage: countPage,
    //       numberPage: 0,
    //       filter: true,
    //       orderBy: {
    //         columnName: 'informationBasic.associateName,informationBasic.associateDescription',
    //         order: 'asc'
    //       },
    //       searchCondition: 'AND',
    //       search: [
    //         {
    //           condition: 'or',
    //           searchBy: [
    //             {
    //               dataType: 'string',
    //               conditionColumn: 'informationEngagement.associateStatus',
    //               conditionValue: searchObj
    //             }
    //           ]
    //         },
    //         {
    //           condition: 'or',
    //           searchBy: [
    //             {
    //               dataType: 'string',
    //               conditionColumn: 'informationBasic.associateName',
    //               conditionValue: {
    //                 condition: 'ct',
    //                 value: {
    //                   from: state.scanValue.trim()
    //                 }
    //               }
    //             },
    //             {
    //               dataType: 'string',
    //               conditionColumn: 'informationBasic.associateDescription',
    //               conditionValue: {
    //                 condition: 'ct',
    //                 value: {
    //                   from: state.scanValue.trim()
    //                 }
    //               }
    //             },
    //             {
    //               dataType: 'string',
    //               conditionColumn:
    //                 'informationContact.associateAddressWebsitePrimary.associateAddressWebsite',
    //               conditionValue: {
    //                 condition: 'ct',
    //                 value: {
    //                   from: state.scanValue.trim()
    //                 }
    //               }
    //             },
    //             {
    //               dataType: 'string',
    //               conditionColumn:
    //                 'informationContact.associateAddressWebsiteSecondary.associateAddressWebsite',
    //               conditionValue: {
    //                 condition: 'ct',
    //                 value: {
    //                   from: state.scanValue.trim()
    //                 }
    //               }
    //             },
    //             {
    //               dataType: 'string',
    //               conditionColumn: 'informationEngagement.associateTag.associateTagPrimary',
    //               conditionValue: {
    //                 condition: 'ct',
    //                 value: {
    //                   from: state.scanValue.trim()
    //                 }
    //               }
    //             },
    //             {
    //               dataType: 'string',
    //               conditionColumn: 'informationEngagement.associateTag.associateTagSecondary',
    //               conditionValue: {
    //                 condition: 'ct',
    //                 value: {
    //                   from: state.scanValue.trim()
    //                 }
    //               }
    //             }
    //           ]
    //         }
    //       ]
    //       // search: [
    //       //   {
    //       //     condition: 'and',
    //       //     searchBy: [
    //       //       {
    //       //         dataType: 'String',
    //       //         conditionColumn: 'id',
    //       //         conditionValue: {
    //       //           condition: 'eq',
    //       //           value: {
    //       //             from: selectedAssociateInfo?.associate?.informationEngagement.associateTag
    //       //               .associateTagPrimary
    //       //           }
    //       //         }
    //       //       }
    //       //     ]
    //       //   }
    //       // ]
    //     }
    //   };
    //   dispatch({ type: SET_PAGE_COUNT, payload: 0 });
    //   dispatch({
    //     type: ASSOCIATE_REVIEW_DISTINCT_SHARE_SAGA,
    //     payload: {
    //       request: requestObect,
    //       BadgeOne: 'distinct',
    //       BadgeTwo: '',
    //       BadgeThree: '',
    //       isSelectActive: isSelectActive,
    //       selectedTagsArray: selectedTagsArray
    //     }
    //   });
    // }
    // if (reviewMode) {
    //   hideDisplayPaneThree();
    // }
    if (typeOfMiddlePaneList === 'assesseeDistinctManuscriptReviewList') {
      getManuscriptVideosListApiCall(
        selectedAssociateInfo,
        dispatch,
        middlePaneHeader,
        'manuscripts',
        isSelectActive,
        state.scanValue.trim()
      );
    }
    if (typeOfMiddlePaneList === 'assesseeDistinctVideosReviewList') {
      getManuscriptVideosListApiCall(
        selectedAssociateInfo,
        dispatch,
        middlePaneHeader,
        'video',
        isSelectActive,
        state.scanValue.trim(),
        middlePaneHeaderBadgeTwo
      );
    }
  };

  let marketPlaceObj = {
    marketplaceItemsTypeDistinctReviewList: true,
    marketplaceItemsGroupDistinctReviewList: true,
    marketplaceItemNodeItemReviewList: true,
    marketPlaceCultureProfilesGroupDistinctReviewList: true,
    MarketPlaceCultureProfileTypeReviewList: true,
    MarketPlaceCultureProfileNodeCultureProfileDistinctReviewList: true,

    MarketplaceJobProfilesTypeDistinctReviewList: true,
    jobProfileNodeMarketPlaceJobProfileReviewList: true,
    MarketplaceAssesseeGroupReviewList: true,
    MarketplaceAssesseeNodeReviewList: true,
    MarketplaceAssesseeRoleReviewList: true,
    MarketplaceAssesseeTypeassesseeReviewList: true,

    MarketplaceAssessmentGroupReviewList: true,
    MarketplaceAssessmentNodeReviewList: true,
    MarketplaceAssessmentTypeReviewList: true,
    MarketplaceAssignmentGroupReviewList: true,
    MarketplaceAssignmentNodeReviewList: true,
    MarketplaceAssignmentsTypeDistinctReviewList: true,
    MarketplaceAssessmentsTypeDistinctReviewList: true,
    MarketplaceJobProfileGroupReviewList: true
  };

  let marketplaceDistinctObj = {
    marketplaceItemsDistinctReviewList: true,
    marketPlaceCultureProfilesDistinctReviewList: true,
    MarketplaceJobProfilesDistinctReviewList: true,
    MarketplaceAssessmentDistinctReviewList: true,
    MarketplaceAssignmentDistinctReviewList: true,
  }

  return (
    <div>
      <Popup isActive={isActive}>
        <PopupHeader
          headerPanelColour={'displayPaneCentre'}
          headerOne={scanHeader}
          headerOneBadgeOne={scanHeaderBadgeOne}
          headerOneBadgeTwo={scanHeaderBadgeTwo}
          headerOneBadgeThree={''}
          mode={'search'}
          onClick={(e) => {
            !isListLoading && handleClick(e);
          }}
        />

        <DialogContent
          className={['popupContent', 'fixed10PadDim', 'revisePopupContent'].join(' ')}
        >
          <FormControl style={{ width: '100%' }}>
            <InputFeild
              id={'scan'}
              label={'scan'}
              labelBadgeOne={''}
              isRequired={false}
              value={state.scanValue}
              classNames={'scanInputFields'}
              onClick={handleChange}
            />
            <FormHelperText
              className={['aliasName', 'helptextmargin'].join(' ')}
              style={{ paddingLeft: '5px' }}
            >
              {(isPopUpValue === 'assesseeRoleDistinctReviewList' ||
                isPopUpValue === 'marketplaceAssesseeRoleDistinctReviewList' ||
                isPopUpValue === 'assesseesGroupDistinctReviewList' ||
                isPopUpValue === 'assessmentsGroupDistinctReviewList' ||
                isPopUpValue === 'assignmentsGroupDistinctReviewList' ||
                isPopUpValue === 'associatesGroupDistinctReviewList' ||
                isPopUpValue === 'assignmentsTypeDistinctReviewList' ||
                isPopUpValue === 'assessmentsTypeDistinctReviewList' ||
                isPopUpValue === 'associateNodeDistinctReviewList' ||
                isPopUpValue === 'assesseesTypeDistinctReviewList' ||
                isPopUpValue === 'MarketplaceAssesseesTypeDistinctReviewList' ||
                isPopUpValue === 'associatesTypeDistinctReviewList' ||
                isPopUpValue === 'itemsGroupDistinctReviewList' ||
                isPopUpValue === 'itemsTypeDistinctReviewList' ||
                isPopUpValue === 'cultureProfilesGroupDistinctReviewList' ||
                isPopUpValue === 'jobProfilesTypeDistinctReviewList' ||
                isPopUpValue === 'assignmentDistinctAssessmentReviewList' ||
                isPopUpValue === 'cultureProfileAssessmentReviewList' ||
                isPopUpValue === 'jobProfileAssessmentReviewList' ||
                isPopUpValue === 'assessmentItemReviewList' ||
                isPopUpValue === 'assesseeDistinctAssignmentDistinctReviewList' ||
                //  isPopUpValue === 'cultureProfileGroupCultureProfileReviewList' ||
                //  isPopUpValue === 'cultureProfileTypeCultureProfileReviewList' ||
                isPopUpValue === 'cultureProfilesTypeDistinctReviewList' ||
                isPopUpValue === 'jobProfilesGroupDistinctReviewList' ||
                //isPopUpValue === 'assessmentNodeAssessmentReviewList' ||
                //  isPopUpValue === 'jobProfilesTypeDistinctReviewList' ||
                isPopUpValue === 'assesseeGroupAssesseeAssignmentDistinct' ||
                isPopUpValue === 'assesseeAssignmentAssessmentReviewList' ||
                isPopUpValue === 'assignmentAssesseeAssessmentDistinctReviewList' ||
                isPopUpValue === 'jobProfilepAssessmentReviewList' ||
                isPopUpValue === 'assignmentDistinctAssignmentReference' ||
                isPopUpValue === 'gaugeDistinctReviewList' ||
                isPopUpValue === 'gaugeDistinctMetricDistinctReviewList' ||
                isPopUpValue === 'assessmentscoresReviewList' ||
                isPopUpValue === 'assessmentversionsReviewList' ||
                isPopUpValue === 'assessmentSectionItemDistinctReviewList' ||
                isPopUpValue === 'jobCometancyDistinctReviewList' ||
                isPopUpValue === 'jobDomainDistinctReviewList' ||
                isPopUpValue === 'jobFunctionDistinctReviewList' ||
                isPopUpValue === 'jobRoleDistinctReviewList' ||
                isPopUpValue === 'cultureDimensionsDistinctReviewList' ||
                isPopUpValue === 'clusterDistinctReviewList' ||
                isPopUpValue === 'associateMineAssignmentDistinctReviewList' ||
                isPopUpValue === 'associateRoleDistinctReviewList' ||
                isPopUpValue === 'AssesseeGroupMineReviewList' ||
                isPopUpValue === 'assignmentDistinctAssesseeGroupReviewList' ||
                isPopUpValue === 'assignmentDistinctCultureProfileGroupReviewList' ||
                isPopUpValue === 'assignmentDistinctJobProfileGroupReviewList' ||
                isPopUpValue === 'assignmentDistinctMineAssesseeDistinctReviewList' ||
                marketPlaceObj.hasOwnProperty(isPopUpValue) ||
                isPopUpValue === 'assignmentDistinctAssessmentGroupReviewList') && (
                  <span>name, description.</span>
                )}
              {(isPopUpValue === 'assignmentDistinctReviewList' ||
                isPopUpValue === 'cultureProfilesDistinctReviewList' ||
                isPopUpValue === 'jobProfilesDistinctReviewList' ||
                isPopUpValue === 'cultureProfileTypeCultureProfileReviewList' ||
                isPopUpValue === 'assessmentTypeAssessmentReviewList' ||
                isPopUpValue === 'itemTypeItemReviewList' ||
                isPopUpValue === 'assignmentTypeAssignmentReviewList' ||
                isPopUpValue === 'jobProfileTypeJobProfileReviewList' ||
                isPopUpValue === 'jobProfileNodeJobProfileReviewList' ||
                isPopUpValue === 'assessmentNodeAssessmentReviewList' ||
                isPopUpValue === 'assignmentNodeAssignmentReviewList' ||
                isPopUpValue === 'itemNodeItemReviewList' ||
                isPopUpValue === 'assignmentGroupAssignmentReviewList' ||
                isPopUpValue === 'assessmentGroupAssessmentReviewList' ||
                isPopUpValue === 'itemGroupItemReviewList' ||
                isPopUpValue === 'cultureProfileNodeCultureProfileReviewList' ||
                isPopUpValue === 'jobProfileGroupJobProfileReviewList' ||
                isPopUpValue === 'cultureProfileGroupCultureProfileReviewList' ||
                isPopUpValue === 'associateDistinctShareReviewList' ||
                isPopUpValue === 'assignmentDistinctCultureProfileReviewList' ||
                isPopUpValue === 'assignmentDistinctJobProfileReviewList' ||
                isPopUpValue === 'assesseeAssignmentDistinctReviewList' ||
                isPopUpValue === 'itemsDistinctReviewList' ||
                isPopUpValue === 'assesseesAssginmentAssessmentReviewList' ||
                isPopUpValue === 'assesseeDistinctManuscriptReviewList' ||
                isPopUpValue === 'assesseeDistinctVideosReviewList' ||
                marketplaceDistinctObj.hasOwnProperty(isPopUpValue) ||
                isPopUpValue === 'assessmentDistinctReviewList') && (
                  <span>name, description, tag.</span>
                )}
              {(isPopUpValue === 'assesseesDistinctReviewList' ||
                isPopUpValue === 'assignmentDistinctMineAssesseeDistinctReviewList' ||
                isPopUpValue === 'administratorsDistinctReviewList' ||
                isPopUpValue === 'assistantsDistinctReviewList' ||
                isPopUpValue === 'managersDistinctReviewList' ||
                isPopUpValue === 'assesseesGroupAssesseeReviewList' ||
                isPopUpValue === 'assesseesNodeAssesseeReviewList' || // added
                isPopUpValue === 'assesseesRoleAssesseeReviewList' ||
                isPopUpValue === 'assignmentDistinctAssesseeReviewList' ||
                isPopUpValue === 'assesseesTypeAssesseeReviewList' ||
                isPopUpValue === 'MarketplaceAssesseeDistinctReviewList' ||
                isPopUpValue === 'AssesseeMarketPlaceDistinctReviewList') && (
                  <span>name, alias, email address, mobile telephone, tag.</span>
                )}
              {(isPopUpValue === 'assesseeRelatedAssociate' ||
                isPopUpValue === 'associateDistinctReviewList' ||
                isPopUpValue === 'associatesNodeDistinctReviewList' ||
                isPopUpValue === 'nodeAssociatesReviewList' ||
                isPopUpValue === 'associatesGroupAssociateReviewList' ||
                isPopUpValue === 'associatesTypeAssociateReviewList' ||
                isPopUpValue === 'associatesRoleAssociateReviewList') && (
                  <span>name, description, website address, tag.</span>
                )}
            </FormHelperText>
          </FormControl>
        </DialogContent>
      </Popup>
    </div>
  );
};

PopUpScan.propTypes = {
  className: PropTypes.string,
  headerPanelColour: PropTypes.oneOf([
    'displayPaneLeft',
    'displayPaneCentre',
    'displayPaneRight',
    'genericOne',
    'genericTwo'
  ]),
  headerOne: PropTypes.string,
  headerOneBadgeOne: PropTypes.string,
  headerOneBadgeTwo: PropTypes.string,
  headerOneBadgeThree: PropTypes.string,
  isActive: PropTypes.bool
};
export default PopUpScan;
