import React from 'react';
import ArrowRight from '@material-ui/icons/ChevronRight';
import Card from '../Card/Card';
import { useDispatch, useSelector } from 'react-redux';
// import { setAssesseeCardPermissionInJson } from '../../Actions/GenericActions';
import {
  ASSESSEE_POPUP_OPEN,
  ASSOCIATE_POPUP_OPEN,
  ASSESSMENT_POPUP_OPEN,
  ASSIGNMENT_POPUP_OPEN,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_ASSESSEE_CREATE_SINGLE_STATE,
  SET_ASSOCIATE_CREATE_SINGLE_STATE,
  SET_ASSESSMENT_SINGLE_STATE,
  SET_ASSIGNMENT_SINGLE_STATE,
  SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE
} from '../../actionType';
import { getPopUpOptionList } from '../../Actions/GenericActions';
import { MODULE_POPUP_OPTION } from '../../PopUpConfig';

const DisplayPaneOneSectionOne = () => {
  const dispatch = useDispatch();
  // const { assesseePermission = null } = useSelector((state) => state.UserReducer);
  const { signedAssesseePermission } = useSelector((state) => state.DisplayPaneTwoReducer);

  const openAssesseesPopUp = (e) => {
    let assesseeDistinctPermission =
      signedAssesseePermission?.assesseeDistinctPermission?.assesseeAssesseeDistinctPermission;
    let popupContentArrValue = getPopUpOptionList(MODULE_POPUP_OPTION, assesseeDistinctPermission);
    dispatch({
      type: ASSESSEE_POPUP_OPEN,
      payload: {
        popupContentArrValue: popupContentArrValue
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'assignmentCreateType',
        value: 'iGuru_Assignment'
      }
    });

    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'sectionCreateType',
        value: e.currentTarget.getAttribute('data-id')
      }
    });

    dispatch({
      type: SET_ASSESSEE_CREATE_SINGLE_STATE,
      payload: {
        stateName: 'assesseesHeaderOneBadgeOne',
        value: ''
      }
    });
    dispatch({
      type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
      payload: { stateName: 'footerButtonMode', value: true }
    });
  };
  const openAssociatesPopUp = () => {
    let associateDistinctPermission =
      signedAssesseePermission?.associateDistinctPermission?.assesseeAssociateDistinctPermission;
    let popupContentArrValue = getPopUpOptionList(MODULE_POPUP_OPTION, associateDistinctPermission);
    dispatch({
      type: ASSOCIATE_POPUP_OPEN,
      payload: {
        popupContentArrValue: popupContentArrValue
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'assignmentCreateType',
        value: 'iGuru_Assignment'
      }
    });
    dispatch({
      type: SET_ASSOCIATE_CREATE_SINGLE_STATE,
      payload: {
        stateName: 'associatesHeaderOneBadgeOne',
        value: ''
      }
    });
    dispatch({
      type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
      payload: { stateName: 'footerButtonMode', value: true }
    });
  };
  const openAssessmentPopUp = () => {
    let assessmentDistinctPermission =
      signedAssesseePermission?.assessmentDistictPermission?.assesseeAssessmentDistinctPermission;
    let popupContentArrValue = getPopUpOptionList(
      MODULE_POPUP_OPTION,
      assessmentDistinctPermission
    );
    dispatch({
      type: ASSESSMENT_POPUP_OPEN,
      payload: {
        popupContentArrValue: popupContentArrValue
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'assignmentCreateType',
        value: 'iGuru_Assignment'
      }
    });
    dispatch({
      type: SET_ASSESSMENT_SINGLE_STATE,
      payload: {
        stateName: 'assessmentsHeaderOneBadgeOne',
        value: ''
      }
    });
    dispatch({
      type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
      payload: { stateName: 'footerButtonMode', value: true }
    });
  };
  const openAssignmentPopUp = (e) => {
    let assigmentDistictPermission =
      signedAssesseePermission?.assigmentDistictPermission?.assesseeAssignmentDistinctPermission;
    let popupContentArrValue = getPopUpOptionList(MODULE_POPUP_OPTION, assigmentDistictPermission);
    dispatch({
      type: ASSIGNMENT_POPUP_OPEN,
      payload: {
        popupContentArrValue: popupContentArrValue
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'assignmentCreateType',
        value: 'iGuru_Assignment'
      }
    });

    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'sectionCreateType',
        value: e.currentTarget.getAttribute('data-id')
      }
    });

    dispatch({
      type: SET_ASSIGNMENT_SINGLE_STATE,
      payload: {
        stateName: 'assignmentsHeaderOneBadgeOne',
        value: ''
      }
    });
    dispatch({
      type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
      payload: { stateName: 'footerButtonMode', value: true }
    });
  };
  // const openDisplayPaneOneSectionOnePopUp = (e) => {
  //   let popupContentArrValue = [];
  //   let popupHeaderOne = '';
  //   let value = '';
  //   if (e.currentTarget.getAttribute('data-value') !== '') {
  //     if (e.currentTarget.getAttribute('data-value') === 'assessees') {
  //       popupHeaderOne = 'assessees';
  //       popupContentArrValue = setAssesseeCardPermissionInJson(
  //         MODULE_POPUP_OPTION,
  //         assesseePermission
  //       );
  //       value = 'DISPLAY_PANE_ONE_SECTION_ONE_POPUP';
  //     }
  //     if (e.currentTarget.getAttribute('data-value') === 'assessments') {
  //       popupHeaderOne = 'assessments';
  //       popupContentArrValue = setAssesseeCardPermissionInJson(
  //         MODULE_POPUP_OPTION,
  //         assesseePermission
  //       );
  //       value = 'DISPLAY_PANE_ONE_SECTION_ONE_POPUP';
  //     }
  //     if (e.currentTarget.getAttribute('data-value') === 'assignments') {
  //       popupHeaderOne = 'assignments';
  //       popupContentArrValue = setAssesseeCardPermissionInJson(
  //         MODULE_POPUP_OPTION,
  //         assesseePermission
  //       );
  //       value = 'DISPLAY_PANE_ONE_SECTION_ONE_POPUP';
  //     }
  //     if (e.currentTarget.getAttribute('data-value') === 'associates') {
  //       popupHeaderOne = 'associates';
  //       popupContentArrValue = setAssesseeCardPermissionInJson(
  //         MODULE_POPUP_OPTION,
  //         assesseePermission
  //       );
  //       value = 'DISPLAY_PANE_ONE_SECTION_ONE_POPUP';
  //     }
  //     dispatch({
  //       type: SET_POPUP_STATE,
  //       payload: {
  //         popupHeaderOne: popupHeaderOne,
  //         previousPopupHeaderOne: popupHeaderOne,
  //         popupHeaderOneBadgeOne: '',
  //         isPopUpValue: value,
  //         popupOpenType: 'primary',
  //         popupContentArrValue: popupContentArrValue
  //       }
  //     });
  //   } else {
  //     console.log('openDisplayPaneOneSectionOnePopUp in else ');
  //   }
  // };
  return (
    <>
      <div
        className="paddingCard"
        onClick={openAssesseesPopUp}
        data-value={'assessees'}
        data-id={'assessees'}
      >
        <Card isIcon IconOne={ArrowRight} textOneOne="assessees" />
      </div>
      <div
        className="paddingCard"
        onClick={openAssessmentPopUp}
        data-value={'assessments'}
        data-id={''}
      >
        <Card isIcon IconOne={ArrowRight} textOneOne="assessments" />
      </div>
      <div
        className="paddingCard"
        onClick={openAssignmentPopUp}
        data-value={'assignments'}
        data-id={'assignments'}
      >
        <Card isIcon IconOne={ArrowRight} textOneOne="assignments" />
      </div>
      <div
        className="paddingCard"
        onClick={openAssociatesPopUp}
        data-value={'associates'}
        data-id={''}
      >
        <Card isIcon IconOne={ArrowRight} textOneOne="associates" />
      </div>
    </>
  );
};

export default DisplayPaneOneSectionOne;
