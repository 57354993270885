import React, { Fragment, useState } from 'react'
import PopUp from '../Molecules/PopUp/PopUp'
import PopupHeader from '../Molecules/PopUp/PopUpHeader';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import { useDispatch, useSelector } from 'react-redux';
import { LOADER_START, LOADER_STOP, POPUP_CLOSE, SET_DISPLAY_TWO_SINGLE_STATE, SET_MIDDLEPANE_STATE, SET_MOBILE_PANE_STATE, SET_POPUP_SINGLE_STATE, SET_POPUP_STATE } from '../actionType';
import PropTypes from 'prop-types';
import { ClickAwayListener, DialogContent, InputLabel } from '@material-ui/core';
import InfoToolTip from '../Atoms/InfoToolTip/InfoToolTip';
import Store from '../store/index'

const PopUpReport = (props) => {
    const dispatch = useDispatch()
    const {
        isActive = false,
        headerPanelColour,
        errorMsg = '',
        headerOne = '',
        headerOneBadgeOne = '',
        headerOneBadgeTwo = '',
        mode,
        nextPopUpValue,
        inputHeader,
        inputHeaderBadge,
        inputHeaderBadgeTwo,
        infoMsg = 'select one',
        list = []
    } = props;

    const {
        // reviewListDistinctData,
        sectionCreateType,
        middlePaneSelectedValue,
        isSelectActive,
        selectedTagsArray,
        unselectedTagsArray,
        selectedAssociateInfo,
        selectedMineTotalAmount,
        relatedReviewListDistinctData,
        currentRevisedAssignmentType,
        typeOfMiddlePaneList
    } = useSelector((state) => state.DisplayPaneTwoReducer);
    const { responseObject, createMode, assignmentReportsData } = useSelector(
        (state) => state.DisplayPaneThreeReducer
    );

    const { isPopUpValue, selectedTagValue, popupHeaderOne, reportCombinations } = useSelector((state) => state.PopUpReducer);

    const { assesseeAssignment, assessee } = responseObject;

    const [selectedValues, setSelectedValues] = useState([])

    const closeRelatedList2 = () => {
        dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        dispatch({ type: LOADER_START });
        let assignmentReportsData2 = assignmentReportsData
        if (currentRevisedAssignmentType === 'iGuru Analytics_Occupation') {
            assignmentReportsData2 = [
                {
                    id: 4,
                    reportName: 'Merit List',
                    reportDescription: 'Culture-Fit'
                },
                {
                    id: 6,
                    reportName: 'Merit List',
                    reportDescription: 'Job-Fit'
                },
                {
                    id: 9,
                    reportName: 'Merit List',
                    reportDescription: 'Work-Fit'
                },
            ]
        } else {
            assignmentReportsData2 = [
                {
                    id: 1,
                    reportName: 'Assignment Report',
                    // reportDescription: 'Bespoke'
                },
            ]
        }

        let response = {
            report: assignmentReportsData2,
            assingmentId: relatedReviewListDistinctData[0]?.assignmentId,
            assignmentName: relatedReviewListDistinctData[0]?.assignmentName,
            assignmentDescription: relatedReviewListDistinctData[0]?.assignmentDescription
        };
        setTimeout(function () {
            dispatch({
                type: SET_DISPLAY_TWO_SINGLE_STATE,
                payload: { stateName: 'relatedReviewListDistinctData', value: [response] }
            });
            dispatch({
                type: SET_MIDDLEPANE_STATE,
                payload: {
                    middlePaneHeader: 'assignment',
                    middlePaneHeaderBadgeOne: 'reports',
                    middlePaneHeaderBadgeTwo: '',
                    middlePaneHeaderBadgeThree: '',
                    middlePaneHeaderBadgeFour: '',
                    typeOfMiddlePaneList: 'assignmentReportReviewList',
                    scanCount: (assignmentReportsData2 && assignmentReportsData2.length) || 0,
                    showMiddlePaneState: true,
                    middlePaneHeaderDuplicate:
                        Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
                    middlePaneHeaderDuplicateBadgeOne:
                        Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
                    middlePaneHeaderDuplicateBadgeTwo:
                        Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
                    middlePaneHeaderDuplicateBadgeThree:
                        Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree, //
                    middlePaneHeaderDuplicateBadgeFour:
                        Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour, //
                    middlePaneHeaderDuplicateOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOne,
                    middlePaneHeaderDuplicateOneBadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeOne,
                    middlePaneHeaderDuplicateOneBadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeTwo,
                    middlePaneHeaderDuplicateOneBadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeThree,
                    middlePaneHeaderDuplicateOneBadgeFour: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeFour,
                    duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
                }
            });

            dispatch({ type: LOADER_STOP });
        }, 1000);
        dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
    };

    const handleClick = () => {

        if (!selectedValues || selectedValues.length === 0) {
            return
        }

        if (inputHeader === 'culture-profile' && inputHeaderBadge === 'distinct') {
            dispatch({
                type: SET_POPUP_STATE,
                payload: {
                    popupMode: 'ASSIGNMENT_REPORT_REVIEW',
                    popupHeaderOne: 'job-profile',
                    isPopUpValue: 'REPORTPOPUPJP',
                    popupOpenType: 'primary',
                }
            })

            dispatch({
                type: SET_POPUP_SINGLE_STATE,
                payload: { stateName: 'reportCombinations', value: { ...reportCombinations, cultureProfile: selectedValues[0] } }
            })

        } else if (inputHeader === 'job-profile' && inputHeaderBadge === 'distinct') {


            if (typeOfMiddlePaneList === "assignmentMeritListReportList") {
                closeRelatedList2();
                dispatch({ type: POPUP_CLOSE })
            } else {

                dispatch({
                    type: SET_POPUP_SINGLE_STATE,
                    payload: { stateName: 'reportCombinations', value: { ...reportCombinations, jobProfile: selectedValues[0] } }
                })

                dispatch({ type: POPUP_CLOSE })
                dispatch({ type: LOADER_START });
                let assignmentReportsData2 = assignmentReportsData
                if (sectionCreateType === 'iGuru Analytics_Occupation') {
                    assignmentReportsData2 = [
                        {
                            id: 4,
                            reportName: 'Merit List',
                            reportDescription: 'Culture-Fit'
                        },
                        {
                            id: 6,
                            reportName: 'Merit List',
                            reportDescription: 'Job-Fit'
                        },
                        {
                            id: 9,
                            reportName: 'Merit List',
                            reportDescription: 'Work-Fit'
                        },
                    ]
                } else {
                    assignmentReportsData2 = [
                        {
                            id: 1,
                            reportName: 'Assignment Report',
                            // reportDescription: 'Bespoke'
                        },
                    ]
                }

                let response = {
                    report: assignmentReportsData2,
                    assingmentId: responseObject?.assignmentId,
                    assignmentName: responseObject?.assignmentName,
                    assignmentDescription: responseObject?.assignmentDescription
                };
                dispatch({
                    type: SET_DISPLAY_TWO_SINGLE_STATE,
                    payload: { stateName: 'relatedReviewListDistinctData', value: [response] }
                });
                setTimeout(function () {
                    dispatch({
                        type: SET_MIDDLEPANE_STATE,
                        payload: {
                            middlePaneHeader: 'assignment',
                            middlePaneHeaderBadgeOne: 'reports',
                            middlePaneHeaderBadgeTwo: '',
                            middlePaneHeaderBadgeThree: '',
                            middlePaneHeaderBadgeFour: '',
                            typeOfMiddlePaneList: 'assignmentReportReviewList',
                            scanCount: (assignmentReportsData2 && assignmentReportsData2.length) || 0,
                            showMiddlePaneState: true,
                            middlePaneHeaderDuplicate:
                                Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
                            middlePaneHeaderDuplicateBadgeOne:
                                Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
                            middlePaneHeaderDuplicateBadgeTwo:
                                Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
                            middlePaneHeaderDuplicateBadgeThree:
                                Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree, //
                            middlePaneHeaderDuplicateBadgeFour:
                                Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour, //
                            middlePaneHeaderDuplicateOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOne,
                            middlePaneHeaderDuplicateOneBadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeOne,
                            middlePaneHeaderDuplicateOneBadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeTwo,
                            middlePaneHeaderDuplicateOneBadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeThree,
                            middlePaneHeaderDuplicateOneBadgeFour: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeFour,
                            duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
                        }
                    });
                    dispatch({ type: LOADER_STOP });
                }, 2000);
                dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
                dispatch({ type: POPUP_CLOSE })
            }
        } else {
            dispatch({ type: POPUP_CLOSE })
        }

    }


    const selectionHandler = (e) => {
        let tag = e.currentTarget.getAttribute('tag');


        if (selectedValues.includes(tag)) {
            setSelectedValues([])
        } else {
            setSelectedValues([tag])
        }

        // if (selectedValues.includes(tag)) {
        //     let tempArr = [...selectedValues];
        //     let index = tempArr.indexOf(tag)
        //     if (index > -1) { // only splice array when item is found
        //         tempArr.splice(index, 1); // 2nd parameter means remove one item only
        //     }
        //     document.getElementById(tag).style.backgroundColor = 'white';
        //     setSelectedValues(tempArr)
        // } else {
        //     document.getElementById(tag).style.backgroundColor = '#F0F0F0';
        //     setSelectedValues([tag])
        // }
    }

    return (
        <PopUp isActive={isActive}>
            <PopupHeader
                headerPanelColour={headerPanelColour}
                headerOne={headerOne}
                headerOneBadgeOne={headerOneBadgeOne}
                onClick={handleClick}
                mode={mode}
            />
            <DialogContent className={['popupContent', 'fixed10PadDim', 'revisePopupContent'].join(' ')}>

                <div className={'fitContent'}>
                    <div className={['PopupFormBox', 'labelPopupBox', 'popupMinHei'].join(' ')}>
                        <InputLabel htmlFor="name-input" className={'textForLabelPopup'}>
                            <Fragment>
                                {inputHeader}&nbsp;
                                {inputHeaderBadge ? (
                                    <span className={'headerBadge'}>{inputHeaderBadge}</span>
                                ) : null}
                                &nbsp;
                                {inputHeaderBadgeTwo ? (
                                    <span className={'headerBadge'}>{inputHeaderBadgeTwo}</span>
                                ) : null}
                            </Fragment>
                        </InputLabel>
                        <div className={'infoSymbol'}></div>
                        <div className={'infoSymbol'}>
                            <InfoToolTip message={infoMsg} />
                        </div>
                    </div>
                </div>

                {list.length > 0 &&
                    list.map((data) => (
                        <div key={data.id}>
                            <ReviewList
                                textOne={data.name}
                                textTwo={data.description}
                                isFlagActive={false}
                                status={``}
                                isSelectActive={''}
                                isAlertActive={false}
                                key={data.id}
                                tag={data.id}
                                id={data.id}
                                className={'reviewListFixedWidth'}
                                onClickEvent={selectionHandler}
                                isSelectedReviewList={selectedValues.includes(data.id)}
                            // onClickCheckBox={(event) => {
                            // onClickCheckBoxSelection(selectedTagsArray, unselectedTagsArray, event, dispatch);
                            // }}
                            ></ReviewList>
                        </div>
                    ))
                }
            </DialogContent>

        </PopUp>
    )
}
PopUpReport.propTypes = {
    className: PropTypes.string,
    headerPanelColour: PropTypes.oneOf([
        'displayPaneLeft',
        'displayPaneCentre',
        'displayPaneRight',
        'genericOne',
        'genericTwo'
    ]),
    headerOne: PropTypes.string,
    headerOneBadgeOne: PropTypes.string,
    headerOneBadgeTwo: PropTypes.string,
    headerOneBadgeThree: PropTypes.string,
    isActive: PropTypes.bool
};

export default PopUpReport