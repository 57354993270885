import React, { Fragment, useEffect, useState } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import Popup from '../Molecules/PopUp/PopUp';
import PopupHeader from '../Molecules/PopUp/PopUpHeader';
import { InputLabel, FormControl, Checkbox } from '@material-ui/core';
import InputFeild from '../Atoms/InputField/InputField';
import SelectField from '../Atoms/SelectField/SelectField';
import '../Molecules/PopUp/PopUp.css';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { POPUP_CLOSE, SET_NEXT_POPUP } from '../actionType';
import InfoToolTip from '../Atoms/InfoToolTip/InfoToolTip';
import { REQUIRED_ERROR_MESSAGE } from '../errorMessage';
import { getCitiesByCountryId } from '../Actions/GenericActions';

const PopUpCommunity = (props) => {
  const { popupMode } = useSelector((state) => state.PopUpReducer);
  const dispatch = useDispatch();
  const {
    isActive,
    primaryheader,
    inputHeader,
    headerPanelColour,
    headerOne,
    headerOneBadgeOne,
    nextPopUpValue,
    typeOfSetObject,
    basicInfo,
    isRequired = false,
    assesseeCommunityArr,
    mappingValue,
    mode
  } = props;
  const objectKeys = Object.keys(basicInfo);
  const [countryErr, setCountryErr] = useState('');
  const [socialCommunityErr, setSocialCommunityErr] = useState('');
  const [localObject, setLocalObject] = useState(basicInfo);
  const { reviewMode } = useSelector((state) => state.DisplayPaneThreeReducer);
  const { allCountryList } = useSelector((state) => state.DisplayPaneTwoReducer);
  useEffect(() => {
    setLocalObject(basicInfo);
  }, []);
  const handleChange = (e) => {
    let tag = e.target.value;
    let tagName = e.currentTarget.getAttribute('data-name');
    let currentTargetId = e.currentTarget.id;
    console.log('currentTargetId', currentTargetId);
    console.log('tag', tag);
    console.log('tagName', tagName);
    const { name, value } = e.target;
    console.log('name', name);
    console.log('value', value);
    if (
      currentTargetId === 'country' ||
      currentTargetId === 'province' ||
      currentTargetId === 'city'
    ) {
      setLocalObject({ ...localObject, [name]: tag, [name.slice(0, -3)]: tagName });
      getCitiesByCountryId(dispatch, value);
    } else {
      setLocalObject({ ...localObject, [name]: value });
    }
    console.log('localObject', localObject);
    //dispatch({ type: typeOfSetObject, payload: { ...basicInfo, [name]: value } });
  };
  const validateFun = () => {
    let isValid = true;
    if (localObject.assesseeCommunity === '') {
      isValid = false;
      setSocialCommunityErr(REQUIRED_ERROR_MESSAGE);
    }
    if (localObject.assesseeCommunityCountryRegionTag === '') {
      isValid = false;
      setCountryErr(REQUIRED_ERROR_MESSAGE);
    }
    return isValid;
  };
  const handleClick = () => {
    dispatch({ type: typeOfSetObject, payload: { ...localObject } });
    if (
      localObject.assesseeCommunity === '' &&
      localObject.assesseeCommunityCountryRegionTag === ''
    ) {
      console.log('nothing');
      dispatch({ type: POPUP_CLOSE });
    } else {
      console.log('has something', validateFun());
      if (validateFun()) {
        dispatch({ type: POPUP_CLOSE });
      }
    }
  };
  return (
    <div>
      <Popup isActive={isActive}>
        <PopupHeader
          headerPanelColour={headerPanelColour}
          headerOne={headerOne}
          headerOneBadgeOne={headerOneBadgeOne}
          onClick={handleClick}
          mode={mode}
        />
        <DialogContent
          className={['popupContent', 'fixed10PadDim', 'revisePopupContent'].join(' ')}
        >
          <div className={'fitContent'}>
            <div className={['PopupFormBox', 'labelPopupBox', 'popupMinHei'].join(' ')}>
              <InputLabel htmlFor="name-input" className={'textForLabelPopup'}>
                <Fragment>
                  {inputHeader}&nbsp;
                  {primaryheader ? <span className={'headerBadge'}>{primaryheader}</span> : null}
                </Fragment>
              </InputLabel>
              <div className={'infoSymbol'}></div>
              <div className={'infoSymbol'}>
                <InfoToolTip message="Click me, I will stay visible until you click outside." />
              </div>
            </div>
          </div>
          <FormControl style={{ width: '100%' }}>
            <SelectField
              tag={objectKeys[1]}
              label={'country / region'}
              dataValue={'country'}
              listSelect={allCountryList}
              errorMsg={countryErr}
              onChange={(e) => {
                setCountryErr('');
                handleChange(e);
              }}
              //value={localObject && localObject[objectKeys[1]]}
              value={localObject && localObject[objectKeys[1]]}
              mappingValue={'_id'}
            />
            {/* <SelectField
              tag={objectKeys[0]}
              label={'country / region'}
              dataValue={'country'}
              listSelect={[
                { countryCode: '', name: '' },
                { countryCode: '91', name: 'India' },
                { countryCode: '22', name: 'USA' }
              ]}
              onChange={handleChange}
              value={basicInfo && basicInfo[objectKeys[0]]}
              mappingValue={'countryCode'}
            /> */}
            <SelectField
              tag={objectKeys[2]}
              label={`${primaryheader} community`}
              listSelect={assesseeCommunityArr}
              onChange={(e) => {
                setSocialCommunityErr('');
                handleChange(e);
              }}
              mappingValue={mappingValue}
              errorMsg={socialCommunityErr}
              value={localObject && localObject[objectKeys[2]]}
            />
          </FormControl>
        </DialogContent>
      </Popup>
    </div>
  );
};

PopUpCommunity.propTypes = {
  className: PropTypes.string,
  headerPanelColour: PropTypes.oneOf([
    'displayPaneLeft',
    'displayPaneCentre',
    'displayPaneRight',
    'genericOne',
    'genericTwo'
  ]),
  headerOne: PropTypes.string,
  headerOneBadgeOne: PropTypes.string,
  headerOneBadgeTwo: PropTypes.string,
  headerOneBadgeThree: PropTypes.string,
  isActive: PropTypes.bool
};

export default PopUpCommunity;
