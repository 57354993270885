import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  POPUP_OPEN,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MIDDLEPANE_STATE,
  SET_POPUP_STATE
} from '../actionType';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import { SECTION_SCALE_CLUSTER_REVIEW_LIST_POPUP_OPTION } from '../PopUpConfig';
import Card from '../Molecules/Card/Card';
import CrossIcon from '@material-ui/icons/Clear';
import { onClickCheckBoxSelection } from '../Actions/AssesseeModuleAction';
import { getPopUpOptionList } from '../Actions/GenericActions';

const AssignmentDistinctGuageDistinctReviewList = (props) => {
  const dispatch = useDispatch();
  const {
    reviewListDistinctData,
    relatedReviewListDistinctData,
    selectedTagsArray,
    isSelectActive,
    unselectedTagsArray,
    signedAssesseePermission
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const closeRelatedList = () => {
    dispatch({
      type: SET_MIDDLEPANE_STATE,
      payload: {
        middlePaneHeader: 'assignment',
        middlePaneHeaderBadgeOne: 'distinct',
        middlePaneHeaderBadgeTwo: 'active',
        middlePaneHeaderBadgeThree: '',
        middlePaneHeaderBadgeFour: '',
        typeOfMiddlePaneList: 'assignmentDistinctReviewList',
        scanCount: reviewListDistinctData.length,
        showMiddlePaneState: true
      }
    });
    //dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  };
  const listDistinctData = relatedReviewListDistinctData[0];
  console.log('listDistinctData', listDistinctData);
  const openListPopup = (e) => {
    console.log(e.currentTarget.getAttribute('tag'));
    console.log(e.currentTarget.getAttribute('status'));
    let tempArr = [];
    let stats = e.currentTarget.getAttribute('status');
    let permissionObject = signedAssesseePermission?.assigmentDistictPermission?.assesseeAssignmentDistinctPermission;
    let popupContentArr = getPopUpOptionList(SECTION_SCALE_CLUSTER_REVIEW_LIST_POPUP_OPTION, permissionObject);
    popupContentArr.map((element) => {
      if (element.data === 'revise') tempArr.push({ ...element, disabled: true });
      else tempArr.push(element);
    });
    let metrics = {
      data: 'metrics',
      dataValue: 'metrics',
      optionClass: 'optionPrimary',
      divider: 'dark',
      disabled: false
    };
    tempArr.splice(9, 0, metrics);
    console.log(tempArr);
    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: 'assignment',
        popupHeaderOneBadgeOne: 'gauge',
        isPopUpValue: '',
        popupOpenType: 'primary',
        popupContentArrValue: tempArr,
        selectedTagValue: e.currentTarget.getAttribute('tag'),
        selectedTagStatus: e.currentTarget.getAttribute('status')
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: tempArr
      }
    });
  };
  return (
    <div>
      {listDistinctData && (
        <Card
          textOneOne={listDistinctData.assignmentName}
          textTwoOne={listDistinctData.assignmentDescription}
          IconOne={CrossIcon}
          isIcon={true}
          labelTwoTwo={'assignment'}
          onClickIconOne={closeRelatedList}
          isAlliance
          relatedCardFixed={true}
          className={'iguru-iconboxSVG'}
        />
      )}
      {listDistinctData &&
        listDistinctData.guages.map((item, index) => {
          return (
            <div className="containerPadding" key={index}>
              <ReviewList
                className=""
                // id={index}
                // tag={index}
                id={item.id}
                tag={item.id}
                associateId={item?.associateId}
                // isSelectedReviewList={middlePaneSelectedValue === item.id}
                //isInformationValue={item.assessmentScaleWeightage}
                status={''}
                isInformation
                actualStatus={listDistinctData?.assignmentStatus}
                textOne={item.iGaugeOccupationFrameworkOneGaugeName}
                textTwo={item.iGaugeOccupationFrameworkOneGaugeDescription}
                isTooltipActive={false}
                onClickEvent={openListPopup}
                isSelectActive={isSelectActive}
                // isSelected={selectedTagsArray.includes(item.id.toString())}
                isSelected={selectedTagsArray.includes(item.id)}
                onClickCheckBox={(event) => {
                  onClickCheckBoxSelection(selectedTagsArray, unselectedTagsArray, event, dispatch);
                }}
              />
            </div>
          );
        })}
    </div>
  );
};
export default AssignmentDistinctGuageDistinctReviewList;
