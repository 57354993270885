import {
  ASSIGNMENT_REVIEW_LIST_DISTINCT_SAGA,
  FILTERMODE,
  GET_ASSIGNMENT_GROUP_REVIEW_LIST_SAGA,
  GET_ASSIGNMENT_TYPE_REVIEW_LIST_SAGA,
  LOADER_START,
  SET_CORE_GROUP_REVIEW_LIST_REQ_OBJECT,
  SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT,
  SET_CORE_TYPE_REVIEW_LIST_REQ_OBJECT,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_POPUP_VALUE,
  SET_RELATED_REQUEST_OBJECT,
  SET_REQUEST_OBJECT,
  GET_ASSIGNMENTTYPE_ASSIGNMENT_REVIEWLIST_SAGA,
  GET_ASSIGNMENTGROUP_ASSIGNMENT_REVIEWLIST_SAGA,
  GET_NODE_ASSIGNMENTS_REVIEW_LIST_SAGA,
  SET_PAGE_COUNT,
  ASSIGNMENT_INFO_REVISE_SAGA,
  ASSIGNMENT_GROUP_REVISE_INFO_SAGA,
  ASSIGNMENT_TYPE_REVISE_INFO_SAGA,
  GET_ASSIGNMENTDISTINCT_ASSESSEES_REVIEWLIST_SAGA,
  GET_ASSIGNMENTDISTINCT_ASSESSMENT_REVIEWLIST_SAGA,
  ASSIGNMENT_PUBLISH_SAGA,
  ASSIGNMENT_ADMINISTER_SAGA,
  SET_POPUP_SINGLE_STATE,
  CLEAR_DISPLAY_PANE_THREE,
  CLEAR_ASSIGNMENT_INFO,
  GET_ASSIGNMENTDISTINCT_CULTURE_PROFILE_REVIEWLIST_SAGA,
  GET_ASSIGNMENTDISTINCT_JOB_PROFILE_REVIEWLIST_SAGA,
  ASSIGNMENT_RESET_SAGA,
  ASSIGNMENT_ASSESSMENT_RESET_SAGA,
  GET_ALLOCATE_ASSIGNMENT,
  CLEAR_GROUP_REDUCER_STATE,
  RELATED_REVIEWLIST_DISTINCT_DATA,
  INTERNAL_NODE_LIST_WITH_PERMISSIONS_SAGA,
  ASSIGNMENT_MARKETPLACE_REVIEW_DISTINCT_SAGA,
  ASSIGNMENT_MARKETPLACE_REVIEW_GROUP_SAGA,
  ASSIGNMENT_MARKETPLACE_REVIEW_NODE_SAGA,
  ASSIGNMENT_MARKETPLACE_REVIEW_TYPE_SAGA
} from '../actionType';
import {
  getAssignmentGroupAssignmentReqObj,
  getAssignmentTypeAssignmentReqObj,
  getAssignmentTypeAssignmentScanReqObj,
  makeAssignmentGroupObj,
  makeAssignmentReviewListRequestObject,
  makeAssignmentTypeObj,
  makeInternalNodeObj,
  getAssignmentGroupAssignmentScanReqObj,
  getNodeAssignmentsReqObj,
  getNodeAssignmentsScanReqObj,
  updateReviseStatus,
  makeAssignmentGroupAllocateObj,
  makeAssignmentTypeAllocateObj,
  makeMarketplaceAssignmentDistinctReviewListRequestObject,
  makeMarketplaceAssignmentGroupReviewListRequestObject,
  makeMarketplaceAssignmenNodeReviewListRequestObject,
  makeMarketplaceAssignmentTypeReviewListRequestObject
} from './GenericActions';
function resetAssignmentDataFunction(dispatch, cardValue) {
  dispatch({
    type: SET_POPUP_SINGLE_STATE,
    payload: { stateName: 'cardValue', value: cardValue }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'middlePaneSelectedValue', value: '' }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'selectedFlagedArray', value: [] }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'unselectedFlagedArray', value: [] }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'typeOfMiddlePaneList', value: '' }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'selectedTagsArray', value: [] }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'typeOfMiddlePaneList', value: '' }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'unselectedTagsArray', value: [] }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'flagedValue', value: '' }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'isSelectActive', value: '' }
  });
  dispatch({
    type: SET_POPUP_SINGLE_STATE,
    payload: { stateName: 'selectionValue', value: '' }
  });
  dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({ type: CLEAR_ASSIGNMENT_INFO });
}
export const createAssignmentPopupApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  dispatch,
  assignmentCreateType
) => {
  dispatch({ type: LOADER_START });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'selectedInformationAllorKey', value: secondaryOptionCheckValue }
  });

  let nodeRequestObj = makeInternalNodeObj(
    selectedAssociateInfo,
    'active',
    0,
    -1,
    '',
    'Associate-Self-Node-List-Create'
  );
  dispatch({ type: SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT, payload: nodeRequestObj });
  dispatch({
    type: INTERNAL_NODE_LIST_WITH_PERMISSIONS_SAGA,
    payload: {
      request: nodeRequestObj,
      BadgeOne: '',
      BadgeTwo: '',
      BadgeThree: '',
      nodeViewState: 'list',
      isMiddlePaneList: false
    }
  });
  let requestObj = {
    module: 'Assignment-Group',
    action: 'Review-List',
    requestObject: makeAssignmentGroupObj(
      selectedAssociateInfo,
      'active',
      0,
      -1,
      'list',
      assignmentCreateType
    )
  };
  dispatch({ type: SET_CORE_GROUP_REVIEW_LIST_REQ_OBJECT, payload: requestObj });
  dispatch({
    type: GET_ASSIGNMENT_GROUP_REVIEW_LIST_SAGA,
    payload: {
      request: requestObj,
      BadgeOne: '',
      BadgeTwo: '',
      BadgeThree: '',
      isMiddlePaneList: false
    }
  });
  let typeRequestObj = {
    module: 'Assignment-Type',
    action: 'Review-List',
    requestObject: makeAssignmentTypeObj(selectedAssociateInfo, 'active', -1, -1)
  };
  dispatch({ type: SET_CORE_TYPE_REVIEW_LIST_REQ_OBJECT, payload: typeRequestObj });
  dispatch({
    type: GET_ASSIGNMENT_TYPE_REVIEW_LIST_SAGA,
    payload: {
      request: typeRequestObj,
      BadgeOne: '',
      BadgeTwo: '',
      BadgeThree: '',
      isMiddlePaneList: false
    }
  });
  dispatch({
    type: SET_POPUP_VALUE,
    payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'ASSIGNMENTCREATE' }
  });
  resetAssignmentDataFunction(dispatch, 'NoCard');
};
export const assignmentsDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  isSelectActive = '',
  assignmentCreateType,
  selectedTagsArray,
  restFunction = false,
  actionFilter = ''
) => {
  let requestObect = makeAssignmentReviewListRequestObject(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    countPage,
    0,
    assignmentCreateType,
    actionFilter
  );
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: 'assignmentsDistinct' + secondaryOptionCheckValue }
  });
  if (secondaryOptionCheckValue === 'activeinactive') {
    secondaryOptionCheckValue = 'all';
  }
  dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: ASSIGNMENT_REVIEW_LIST_DISTINCT_SAGA,
    payload: {
      request: requestObect,
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      isSelectActive: isSelectActive,
      selectedTagsArray: selectedTagsArray,
      actionFilter
    }
  });
  if (restFunction) {
    resetAssignmentDataFunction(dispatch, 'NoCard');
  }
};
export const assignmentsGroupApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  cardValue = 'noCard',
  isSelectActive = '',
  groupViewState,
  selectedTagsArray = [],
  assignmentCreateType = ''
) => {
  if (secondaryOptionCheckValue !== 'active') {
    groupViewState = 'list';
  }
  // resetAssignmentDataFunction(dispatch, cardValue);
  let requestObj = {
    module: 'Assignment-Group',
    action: 'Review-List',
    actionFilter: (groupViewState === 'hierarchy' && 'Hierarchy') || '',
    requestObject: makeAssignmentGroupObj(
      selectedAssociateInfo,
      secondaryOptionCheckValue,
      countPage,
      0,
      groupViewState,
      assignmentCreateType
    )
  };
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: 'assignmentsGroupDistinct' + secondaryOptionCheckValue }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'nodeViewState', value: groupViewState }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: GET_ASSIGNMENT_GROUP_REVIEW_LIST_SAGA,
    payload: {
      request: requestObj,
      BadgeOne: targetValue,
      BadgeTwo: cardValue === 'Card' ? 'distinct' : secondaryOptionCheckValue,
      BadgeThree: cardValue === 'Card' ? secondaryOptionCheckValue : '',
      isMiddlePaneList: true,
      isSelectActive: isSelectActive,
      groupViewState,
      cardValue: cardValue,
      selectedTagsArray: selectedTagsArray
    }
  });
};

export const assignmentsGroupAllocateApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  cardValue = 'noCard',
  isSelectActive = '',
  groupViewState,
  selectedTagsArray = [],
  assignmentCreateType = '',
  allocatedTagsArray
) => {
  if (secondaryOptionCheckValue !== 'active' || isSelectActive === 'multiple') {
    groupViewState = 'list';
  }
  // resetAssignmentDataFunction(dispatch, cardValue);
  let requestObj = {
    module: 'Assignment-Group',
    action: 'Review-List',
    actionFilter: (groupViewState === 'hierarchy' && 'Hierarchy') || '',
    requestObject: makeAssignmentGroupAllocateObj(
      selectedAssociateInfo,
      secondaryOptionCheckValue,
      countPage,
      0,
      groupViewState,
      assignmentCreateType,
      allocatedTagsArray
    )
  };
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: 'assignmentsGroupDistinct' + secondaryOptionCheckValue }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'nodeViewState', value: groupViewState }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: GET_ASSIGNMENT_GROUP_REVIEW_LIST_SAGA,
    payload: {
      request: requestObj,
      BadgeOne: targetValue,
      BadgeTwo: cardValue === 'Card' ? 'distinct' : secondaryOptionCheckValue,
      BadgeThree: cardValue === 'Card' ? secondaryOptionCheckValue : '',
      isMiddlePaneList: true,
      isSelectActive: isSelectActive,
      groupViewState,
      cardValue: cardValue,
      selectedTagsArray: selectedTagsArray
    }
  });
};

export const assignmentTypeApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  cardValue = 'NoCard',
  isSelectActive = '',
  actionFilter = ''
) => {
  let requestObj = {
    module: 'Assignment-Type',
    action: 'Review-List',
    requestObject: makeAssignmentTypeObj(
      selectedAssociateInfo,
      secondaryOptionCheckValue,
      countPage,
      0,
    )
  };

  if (actionFilter) requestObj['actionFilter'] = actionFilter

  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: 'assignmentsTypeDistinct' + secondaryOptionCheckValue }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: GET_ASSIGNMENT_TYPE_REVIEW_LIST_SAGA,
    payload: {
      request: requestObj,
      BadgeOne: targetValue,
      BadgeTwo: cardValue === 'Card' ? 'distinct' : secondaryOptionCheckValue,
      BadgeThree: cardValue === 'Card' ? secondaryOptionCheckValue : '',
      isMiddlePaneList: true,
      isSelectActive: isSelectActive
    }
  });
  resetAssignmentDataFunction(dispatch, cardValue);
};
export const assignmentTypeAllocateApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  cardValue = 'NoCard',
  isSelectActive = '',
  allocatedTagsArray
) => {
  let requestObj = {
    module: 'Assignment-Type',
    action: 'Review-List',
    requestObject: makeAssignmentTypeAllocateObj(
      selectedAssociateInfo,
      secondaryOptionCheckValue,
      countPage,
      0,
      allocatedTagsArray
    )
  };

  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: 'assignmentsTypeDistinct' + secondaryOptionCheckValue }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: GET_ASSIGNMENT_TYPE_REVIEW_LIST_SAGA,
    payload: {
      request: requestObj,
      BadgeOne: targetValue,
      BadgeTwo: cardValue === 'Card' ? 'distinct' : secondaryOptionCheckValue,
      BadgeThree: cardValue === 'Card' ? secondaryOptionCheckValue : '',
      isMiddlePaneList: true,
      isSelectActive: isSelectActive
    }
  });
  resetAssignmentDataFunction(dispatch, cardValue);
};
export const getAssignmnetTypeAssignmnetDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  selectedTagValue,
  searchStr,
  isScan,
  assignmentCreateType = ''
) => {
  let reqBody = {
    module: 'Assignment-Type',
    action: 'Review-List',
    actionFilter: 'Assignment-Type-Assignment',
    requestObject: getAssignmentTypeAssignmentReqObj(
      selectedAssociateInfo,
      selectedTagValue,
      secondaryOptionCheckValue,
      0,
      countPage,
      assignmentCreateType
    )
  };
  if (isScan) {
    reqBody = {
      module: 'Assignment-Type',
      action: 'Review-List',
      actionFilter: 'Assignment-Type-Assignment',
      requestObject: getAssignmentTypeAssignmentScanReqObj(
        selectedAssociateInfo,
        selectedTagValue,
        secondaryOptionCheckValue,
        0,
        countPage,
        searchStr
      )
    };
  }
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'relatedReviewListObjectCopy',
      value: reqBody
    }
  });
  // dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({
    type: SET_RELATED_REQUEST_OBJECT,
    payload: reqBody
  });
  dispatch({ type: LOADER_START });
  // dispatch({ type: SET_REQUEST_OBJECT, payload: reqBody });
  dispatch({
    type: GET_ASSIGNMENTTYPE_ASSIGNMENT_REVIEWLIST_SAGA,
    payload: {
      request: reqBody,
      HeaderOne: 'items',
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      BadgeThree: '',
      // isSelectActive,
      // selectedTagsArray,
      isMiddlePaneList: true
    }
  });
};
export const getAssignmnetGroupAssignmnetDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  selectedTagValue,
  searchStr,
  isScan,
  assignmentCreateType
) => {
  let reqBody = {
    module: 'Assignment-Group',
    action: 'Review-List',
    actionFilter: 'Assignment-Group-Assignment',
    requestObject: getAssignmentGroupAssignmentReqObj(
      selectedAssociateInfo,
      selectedTagValue,
      secondaryOptionCheckValue,
      0,
      countPage,
      assignmentCreateType
    )
  };
  if (isScan) {
    reqBody = {
      module: 'Assignment-Group',
      action: 'Review-List',
      actionFilter: 'Assignment-Group-Assignment',
      requestObject: getAssignmentGroupAssignmentScanReqObj(
        selectedAssociateInfo,
        selectedTagValue,
        secondaryOptionCheckValue,
        0,
        countPage,
        searchStr,
        assignmentCreateType
      )
    };
  }
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'relatedReviewListObjectCopy',
      value: reqBody
    }
  });
  // dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({
    type: SET_RELATED_REQUEST_OBJECT,
    payload: reqBody
  });
  dispatch({ type: LOADER_START });
  // dispatch({ type: SET_REQUEST_OBJECT, payload: reqBody });
  dispatch({
    type: GET_ASSIGNMENTGROUP_ASSIGNMENT_REVIEWLIST_SAGA,
    payload: {
      request: reqBody,
      HeaderOne: 'assignments',
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      BadgeThree: '',
      isMiddlePaneList: true
    }
  });
};
export const getNodeRelatedAssignmentsDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  selectedTagValue,
  searchStr,
  isScan,
  middlePaneHeader,
  isMiddlePaneList = true
) => {
  let reqBody = {
    module: 'Assignment-Distinct',
    action: 'Review-List',
    actionFilter: 'Associate-Node-Assignment',
    requestObject: getNodeAssignmentsReqObj(
      selectedAssociateInfo,
      selectedTagValue,
      secondaryOptionCheckValue,
      0,
      countPage
    )
  };
  if (isScan) {
    reqBody = {
      module: 'Assignment-Distinct',
      action: 'Review-List',
      actionFilter: 'Associate-Node-Assignment',
      requestObject: getNodeAssignmentsScanReqObj(
        selectedAssociateInfo,
        selectedTagValue,
        secondaryOptionCheckValue,
        0,
        countPage,
        searchStr
      )
    };
  }
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'relatedReviewListObjectCopy',
      value: reqBody
    }
  });
  // dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  if (isMiddlePaneList) {
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'relatedReviewListDistinctData', value: [] }
    });
  }
  dispatch({ type: LOADER_START });
  // dispatch({ type: SET_REQUEST_OBJECT, payload: reqBody });
  dispatch({
    type: GET_NODE_ASSIGNMENTS_REVIEW_LIST_SAGA,
    payload: {
      request: reqBody,
      HeaderOne: middlePaneHeader,
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      BadgeThree: '',
      isMiddlePaneList: isMiddlePaneList
    }
  });
};
export const updateAssignmentDistinctStatus = (
  selectedAssociateInfo,
  assignmentId,
  dispatch,
  reviseStatus,
  createMode,
  secondaryOptionCheckValue,
  typeOfMiddlePaneList,
  assesseeId,
  associateId
) => {
  if (reviseStatus === 'PUBLISHED' || reviseStatus === 'UNPUBLISHED') {
    let reqBody = {
      module: 'Assignment-Publish',
      action: reviseStatus === 'PUBLISHED' ? 'Publish' : 'Unpublish',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        assignmentId: assignmentId
      }
    };
    dispatch({ type: LOADER_START });
    dispatch({
      type: ASSIGNMENT_PUBLISH_SAGA,
      payload: {
        secondaryOptionCheckValue: '',
        hideRightPane: true,
        headerOne: '',
        reqBody,
        createMode
      }
    });
  } else {
    // let reviseStatusNw =
    //   reviseStatus === 'UNSUSPENDED' ||
    //   reviseStatus === 'UNTERMINATED' ||
    //   reviseStatus === 'UNARCHIVED'
    //     ? 'UNPUBLISHED'
    //     : reviseStatus;
    let selectedType = 'assignmentDistinct';
    updateReviseStatus(
      selectedAssociateInfo,
      [assignmentId],
      dispatch,
      selectedType,
      reviseStatus,
      typeOfMiddlePaneList,
      createMode,
      assesseeId,
      associateId
    );
    // let reqBody = {
    //   module: 'Assignment-Distinct',
    //   action: 'Revise',
    //   requestObject: {
    //     assesseeId: selectedAssociateInfo?.assesseeId,
    //     associateId:
    //       selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
    //     assignment: {
    //       id: assignmentId,
    //       informationEngagement: {
    //         assignmentStatus:
    //           reviseStatus === 'UNSUSPENDED' ||
    //           reviseStatus === 'UNTERMINATED' ||
    //           reviseStatus === 'UNARCHIVED'
    //             ? 'UNPUBLISHED'
    //             : reviseStatus
    //       }
    //     }
    //   }
    // };
    // dispatch({ type: LOADER_START });
    // dispatch({
    //   type: ASSIGNMENT_INFO_REVISE_SAGA,
    //   payload: {
    //     secondaryOptionCheckValue: secondaryOptionCheckValue,
    //     hideRightPane: createMode !== '' ? false : true,
    //     headerOne: '',
    //     reqBody
    //   }
    // });
  }
};
export const updateAssignmentGroupStatus = (
  selectedAssociateInfo,
  groupId,
  dispatch,
  reviseStatus
) => {
  let reqBody = {
    module: ' Assignment-Group',
    action: 'Revise',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      assignmentGroup: {
        id: groupId,
        informationEngagement: {
          assignmentGroupStatus:
            reviseStatus === 'UNSUSPENDED' ||
              reviseStatus === 'UNTERMINATED' ||
              reviseStatus === 'UNARCHIVED'
              ? 'ACTIVE'
              : reviseStatus
        }
      }
    }
  };
  dispatch({ type: LOADER_START });
  dispatch({
    type: ASSIGNMENT_GROUP_REVISE_INFO_SAGA,
    payload: {
      secondaryOptionCheckValue: '',
      assignmentGroupAssignmentReqBody: null,
      headerOne: '',
      reqBody
    }
  });
};

export const updateAssignmentTypeStatus = (
  selectedAssociateInfo,
  typeId,
  dispatch,
  reviseStatus
) => {
  let reqBody = {
    module: 'Assignment-Type',
    action: 'Revise',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      assignmentType: {
        id: typeId,
        informationEngagement: {
          assignmentTypeStatus:
            reviseStatus === 'UNSUSPENDED' ||
              reviseStatus === 'UNTERMINATED' ||
              reviseStatus === 'UNARCHIVED'
              ? 'ACTIVE'
              : reviseStatus
        }
      }
    }
  };
  dispatch({ type: LOADER_START });
  dispatch({
    type: ASSIGNMENT_TYPE_REVISE_INFO_SAGA,
    payload: {
      secondaryOptionCheckValue: '',
      assignmentTypeAssignmentReqBody: null,
      headerOne: '',
      reqBody
    }
  });
};
export const getAssignmnetAssesseeDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  selectedTagValue,
  searchStr,
  isScan,
  assesseeStatus,
  isMiddlePaneList = true,
  mobilepanestate = 'displayPaneTwo',
  siftFilter = false
) => {
  let assesseeStatusArr = [
    'CONFIRMED',
    'DISAPPROVED',
    'SUSPENDED',
    'TERMINATED',
    'UNAPPROVED',
    'UNCONFIRMED',
    'ARCHIVED'
  ];
  let statusArr = [
    'ABORTED',
    'FINISHED',
    'STARTED',
    'UNFINISHED',
    'UNSTARTED',
    'ARCHIVED',
    'DELETED'
  ];
  let allStatusArr = [
    'ABORTED',
    'FINISHED',
    'STARTED',
    'UNFINISHED',
    'UNSTARTED',
    'ARCHIVED',
    'SUSPENDED',
    'TERMINATED',
    'DELETED'
  ];
  if (allStatusArr.includes(secondaryOptionCheckValue.toUpperCase())) {
    statusArr = [secondaryOptionCheckValue.toUpperCase()];
  }
  if (assesseeStatus === 'archived') assesseeStatusArr = ['ARCHIVED'];
  if (assesseeStatus === 'active') assesseeStatusArr = ['CONFIRMED'];
  if (assesseeStatus === 'inactive')
    assesseeStatusArr = ['DISAPPROVED', 'SUSPENDED', 'TERMINATED', 'UNAPPROVED', 'UNCONFIRMED'];

  let reqBody = {
    module: 'Assignment-Distinct',
    action: 'Review-List',
    actionFilter: 'Assignment-Assessee',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      assignmentId: selectedTagValue,
      assesseeAssignmentStatus: statusArr,
      assesseeStatus: assesseeStatusArr,
      countPage: countPage,
      numberPage: 0,
      orderBy: {
        columnName: 'informationBasic.assesseeNameFirst,informationBasic.assesseeNameLast',
        order: 'asc'
      },
      filter: 'true',
      searchCondition: 'AND',
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assesseeStatus',
              conditionValue: {
                condition: 'in',
                value: {
                  in: assesseeStatusArr
                }
              }
            }
          ]
        }
      ]
    }
  };

  // dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: mobilepanestate });
  isMiddlePaneList && dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'relatedReviewListObjectCopy',
      value: reqBody
    }
  });
  // dispatch({
  //   type: SET_DISPLAY_TWO_SINGLE_STATE,
  //   payload: { stateName: 'relatedReviewListDistinctData', value: [] }
  // });
  dispatch({ type: LOADER_START });
  dispatch({
    type: GET_ASSIGNMENTDISTINCT_ASSESSEES_REVIEWLIST_SAGA, //
    payload: {
      siftFilter,
      request: reqBody,
      HeaderOne: 'assessees',
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue === 'unfinished' ? 'timeout' : secondaryOptionCheckValue,
      BadgeThree: '',
      isMiddlePaneList: isMiddlePaneList
    }
  });
};

export const getScanAssignmnetAssesseeDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  selectedTagValue,
  searchStr,
  isScan,
  assesseeStatus,
  isMiddlePaneList = true,
  mobilepanestate = 'displayPaneTwo'
) => {
  let assesseeStatusArr = [
    'CONFIRMED',
    'DISAPPROVED',
    'SUSPENDED',
    'TERMINATED',
    'UNAPPROVED',
    'UNCONFIRMED',
    'ARCHIVED'
  ];
  let statusArr = [
    'ABORTED',
    'FINISHED',
    'STARTED',
    'UNFINISHED',
    'UNSTARTED',
    'ARCHIVED',
    'DELETED'
  ];
  let allStatusArr = [
    'ABORTED',
    'FINISHED',
    'STARTED',
    'UNFINISHED',
    'UNSTARTED',
    'ARCHIVED',
    'SUSPENDED',
    'TERMINATED',
    'DELETED'
  ];
  if (allStatusArr.includes(secondaryOptionCheckValue.toUpperCase())) {
    statusArr = [secondaryOptionCheckValue.toUpperCase()];
  }
  if (assesseeStatus === 'archived') assesseeStatusArr = ['ARCHIVED'];
  if (assesseeStatus === 'active') assesseeStatusArr = ['CONFIRMED'];
  if (assesseeStatus === 'inactive')
    assesseeStatusArr = ['DISAPPROVED', 'SUSPENDED', 'TERMINATED', 'UNAPPROVED', 'UNCONFIRMED'];

  let reqBody = {
    module: 'Assignment-Distinct',
    action: 'Review-List',
    actionFilter: 'Assignment-Assessee',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      assignmentId: selectedTagValue,
      assesseeAssignmentStatus: statusArr,
      assesseeStatus: assesseeStatusArr,
      countPage: countPage,
      numberPage: 0,
      orderBy: {
        columnName: 'informationBasic.assesseeNameFirst,informationBasic.assesseeNameLast',
        order: 'asc'
      },
      searchKey: searchStr
    }
  };

  // dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: mobilepanestate });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'relatedReviewListObjectCopy',
      value: reqBody
    }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'relatedReviewListDistinctData', value: [] }
  });
  dispatch({ type: LOADER_START });
  dispatch({
    type: GET_ASSIGNMENTDISTINCT_ASSESSEES_REVIEWLIST_SAGA, //
    payload: {
      request: reqBody,
      HeaderOne: 'assessees',
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue === 'unfinished' ? 'timeout' : secondaryOptionCheckValue,
      BadgeThree: '',
      isMiddlePaneList: isMiddlePaneList
    }
  });
};

export const getAssignmnetAssessmentDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  selectedTagValue,
  searchStr,
  isScan,
  createMode
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: secondaryOptionCheckValue.toUpperCase()
    }
  };
  if (secondaryOptionCheckValue === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED', 'SUSPENDED', 'TERMINATED']
      }
    };
  }
  let reqBody = {
    module: 'Assignment-Distinct',
    action: 'Review-List',
    actionFilter: 'Assignment-Assessment',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      assignmentId: selectedTagValue,
      numberPage: 0,
      countPage: countPage,
      searchCondition: 'AND',
      filter: true,
      orderBy: {
        columnName: 'informationBasic.assessmentName,informationBasic.assessmentDescription',
        order: 'asc'
      },
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assessmentStatus',
              conditionValue: searchObj
            }
          ]
        }
      ]
    }
  };
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({
    type: SET_RELATED_REQUEST_OBJECT,
    payload: reqBody
  });
  dispatch({ type: LOADER_START });
  dispatch({
    type: GET_ASSIGNMENTDISTINCT_ASSESSMENT_REVIEWLIST_SAGA,
    payload: {
      request: reqBody,
      HeaderOne: 'assessments',
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      BadgeThree: '',
      isMiddlePaneList: true,
      createMode
    }
  });
};
export const updateAssignmentAdminister = (
  selectedAssociateInfo,
  assignmentId,
  dispatch,
  reviseStatus
) => {
  let reqBody = {
    module: 'Assignment-Publish',
    action: 'Administer',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      assignmentId: assignmentId
    }
  };
  dispatch({ type: LOADER_START });
  dispatch({
    type: ASSIGNMENT_ADMINISTER_SAGA,
    payload: {
      secondaryOptionCheckValue: '',
      hideRightPane: true,
      headerOne: '',
      reqBody
    }
  });
};
export const updateAssignmentFlagStatus = (
  selectedAssociateInfo,
  assignmentId,
  dispatch,
  reviseStatus,
  keyVal,
  flagVal,
  createMode
) => {
  let reqBody = {
    module:
      (keyVal === 'assignment' && 'Assignment-Distinct') ||
      (keyVal === 'assignmentGroup' && 'Assignment-Group') ||
      (keyVal === 'assignmentType' && 'Assignment-Type'),
    action: 'Revise',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      [keyVal]: {
        id: assignmentId,
        informationBasic: {
          [flagVal]: reviseStatus
        }
      }
    }
  };
  dispatch({ type: LOADER_START });

  dispatch({
    type:
      keyVal === 'assignment'
        ? ASSIGNMENT_INFO_REVISE_SAGA
        : keyVal === 'assignmentGroup'
          ? ASSIGNMENT_GROUP_REVISE_INFO_SAGA
          : keyVal === 'assignmentType'
            ? ASSIGNMENT_TYPE_REVISE_INFO_SAGA
            : '',
    payload: {
      secondaryOptionCheckValue: '',
      headerOne: '',
      reqBody,
      hideRightPane: true,
      assignmentGroupAssignmentReqBody: null,
      assignmentTypeAssignmentReqBody: null,
      createMode
    }
  });
};

export const getAssignmentRelatedAssessee = (
  dispatch,
  selectedAssociateInfo,
  responseObject,
  countPage
) => {
  let relatedReqObj = {
    module: 'Assignment-Distinct',
    action: 'Review-List',
    actionFilter: 'Assignment-Assessee',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      assignmentId:
        responseObject?.informationEngagement?.assignmentTag?.assignmentTagPrimary || '',
      numberPage: 0,
      countPage: countPage,
      searchCondition: 'AND',
      filter: true,
      orderBy: {
        columnName:
          'informationBasic.assesseeNameFirst,informationBasic.assesseeNameOther,informationBasic.assesseeNameLast',
        order: 'asc'
      },
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assesseeStatus',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: 'ACTIVE'
                }
              }
            }
          ]
        }
      ]
    }
  };
  dispatch({
    type: GET_ASSIGNMENTDISTINCT_ASSESSEES_REVIEWLIST_SAGA,
    payload: {
      request: relatedReqObj,
      HeaderOne: 'assignments',
      BadgeOne: '',
      BadgeTwo: '',
      BadgeThree: '',
      isMiddlePaneList: false
    }
  });
};

export const getAssignmentRelatedAssessment = (
  dispatch,
  selectedAssociateInfo,
  responseObject,
  countPage
) => {
  dispatch({ type: LOADER_START });
  let relatedReqObj = {
    module: 'Assignment-Distinct',
    action: 'Review-List',
    actionFilter: 'Assignment-Assessment',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      assignmentId:
        responseObject?.informationEngagement?.assignmentTag?.assignmentTagPrimary || '',
      numberPage: 0,
      countPage: countPage,
      searchCondition: 'AND',
      filter: true,
      orderBy: {
        columnName: 'informationBasic.assessmentName,informationBasic.assessmentDescripton',
        order: 'asc'
      },
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assessmentStatus',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: 'ACTIVE'
                }
              }
            }
          ]
        }
      ]
    }
  };
  dispatch({
    type: GET_ASSIGNMENTDISTINCT_ASSESSMENT_REVIEWLIST_SAGA,
    payload: {
      request: relatedReqObj,
      HeaderOne: 'assignments',
      BadgeOne: '',
      BadgeTwo: '',
      BadgeThree: '',
      isMiddlePaneList: false
    }
  });
};
export const getAssignmentRelatedCultureProfile = (
  dispatch,
  selectedAssociateInfo,
  responseObject,
  countPage,
  isMiddlePaneList = true
) => {
  dispatch({ type: LOADER_START });
  let relatedReqObj = {
    module: 'Assignment-Distinct',
    action: 'Review-List',
    actionFilter: 'Assignment-Culture-Profile',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      assignmentId:
        responseObject?.informationEngagement?.assignmentTag?.assignmentTagPrimary || '',
      numberPage: 0,
      countPage: countPage,
      searchCondition: 'AND',
      filter: true,
      orderBy: {
        columnName: 'informationBasic.cultureProfileName,informationBasic.cultureProfileDescripton',
        order: 'asc'
      },
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.cultureProfileStatus',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: 'ACTIVE'
                }
              }
            }
          ]
        }
      ]
    }
  };
  dispatch({
    type: GET_ASSIGNMENTDISTINCT_CULTURE_PROFILE_REVIEWLIST_SAGA,
    payload: {
      request: relatedReqObj,
      HeaderOne: 'assignments',
      BadgeOne: '',
      BadgeTwo: '',
      BadgeThree: '',
      isMiddlePaneList: isMiddlePaneList
    }
  });
};
export const getAssignmentRelatedJobProfile = (
  dispatch,
  selectedAssociateInfo,
  responseObject,
  countPage,
  isMiddlePaneList = true
) => {
  dispatch({ type: LOADER_START });
  let relatedReqObj = {
    module: 'Assignment-Distinct',
    action: 'Review-List',
    actionFilter: 'Assignment-Job-Profile',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      assignmentId:
        responseObject?.informationEngagement?.assignmentTag?.assignmentTagPrimary || '',
      numberPage: 0,
      countPage: countPage,
      searchCondition: 'AND',
      filter: true,
      orderBy: {
        columnName: 'informationBasic.jobProfileName,informationBasic.jobProfileDescripton',
        order: 'asc'
      },
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.jobProfileStatus',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: 'ACTIVE'
                }
              }
            }
          ]
        }
      ]
    }
  };
  dispatch({
    type: GET_ASSIGNMENTDISTINCT_JOB_PROFILE_REVIEWLIST_SAGA,
    payload: {
      request: relatedReqObj,
      HeaderOne: 'assignments',
      BadgeOne: '',
      BadgeTwo: '',
      BadgeThree: '',
      isMiddlePaneList: isMiddlePaneList
    }
  });
};
export const assignmentResetApiCall = (selectedAssociateInfo, assesseeAssignmentId, dispatch) => {
  let request = {
    module: 'Assessee-Assignment',
    action: 'Reset',
    actionFilter: 'Assignment',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      assesseeAssignmentId: assesseeAssignmentId
    }
  };
  dispatch({ type: LOADER_START });
  dispatch({
    type: ASSIGNMENT_RESET_SAGA,
    payload: {
      request: request
    }
  });
};
export const assignmentAssessmentResetApiCall = (
  selectedAssociateInfo,
  assesseeAssignmentId,
  assesseeAssignmentAssessmentId,
  dispatch
) => {
  let reqBody = {
    module: 'Assessee-Assignment',
    action: 'Reset',
    actionFilter: 'Assignment-Assessment',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      assesseeAssignmentId: assesseeAssignmentId,
      assesseeAssignmentAssessmentId: assesseeAssignmentAssessmentId
    }
  };
  dispatch({ type: LOADER_START });
  dispatch({
    type: ASSIGNMENT_ASSESSMENT_RESET_SAGA,
    payload: {
      request: reqBody
    }
  });
};

export const assignmentGroupReviseApiCall = (
  responseObject,
  selectedAssociateInfo,
  selectedTagsArray,
  unselectedTagsArray,
  assignmentGroup,
  dispatch,
  createMode,
  isReviseCallFromPaneTwo = false,
  cardValue = 'noCard'
) => {
  const { associateId, id } = responseObject;
  const reqBody = {
    module: 'Assignment-Group',
    action: 'Revise',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      assignmentGroupAssignment: {
        assignmentGroupAssignmentAllocate: selectedTagsArray || [],
        assignmentGroupAssignmentUnallocate: unselectedTagsArray || []
      },
      assignmentGroup: {
        id,
        informationBasic: assignmentGroup.informationBasic,
        informationSetup: assignmentGroup.informationSetup,
        informationAllocation: assignmentGroup.informationAllocation
      }
    }
  };
  dispatch({ type: LOADER_START });
  let assignmentGroupAssignmentReqBody = {
    module: 'Assignment-Group',
    action: 'Review-List',
    actionFilter: 'Assignment-Group-Assignment',
    requestObject: getAssignmentGroupAssignmentReqObj(selectedAssociateInfo, id, '', -1, -1)
  };
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'relatedReviewListDistinctData', value: [] }
  });
  dispatch({
    type: ASSIGNMENT_GROUP_REVISE_INFO_SAGA,
    payload: {
      headerOne: 'assignments',
      reqBody,
      assignmentGroupAssignmentReqBody,
      createMode,
      cardValue,
      isReviseCallFromPaneTwo: isReviseCallFromPaneTwo
    }
  });
};

export const assignmentTypeReviseApiCall = (
  responseObject,
  selectedAssociateInfo,
  selectedTagsArray,
  unselectedTagsArray,
  assignmentType,
  dispatch,
  createMode,
  isReviseCallFromPaneTwo = false,
  assignmentCreateType
) => {
  const { associateId, id } = responseObject;
  console.log(assignmentType);
  const reqBody = {
    module: 'Assignment-Type',
    action: 'Revise',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      assignmentTypeAssignment: {
        assignmentTypeAssignmentAllocate: selectedTagsArray || [],
        assignmentTypeAssignmentUnallocate: unselectedTagsArray || []
      },
      assignmentType: {
        id,
        informationBasic: assignmentType.informationBasic,
        informationAllocation: assignmentType.informationAllocation,
        informationSetup: assignmentType.informationSetup
      }
    }
  };
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({ type: LOADER_START });
  let assignmentTypeAssignmentReqBody = {
    module: 'Assignment-Type',
    action: 'Review-List',
    actionFilter: 'Assignment-Type-Assignment',
    requestObject: getAssignmentTypeAssignmentReqObj(
      selectedAssociateInfo,
      id,
      '',
      -1,
      -1,
      assignmentCreateType
    )
  };
  dispatch({
    type: ASSIGNMENT_TYPE_REVISE_INFO_SAGA,
    payload: {
      headerOne: 'assignment',
      reqBody,
      assignmentTypeAssignmentReqBody,
      createMode,
      isReviseCallFromPaneTwo: isReviseCallFromPaneTwo
    }
  });
};
export const getAssignmentDistinctToAllocate = (
  dispatch,
  requestObect,
  revisedGroupObject,
  existingAssignmentId,
  filterMode,
  typeOfMiddlePaneList,
  selectedTagsArray = []
) => {
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: filterMode }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'relatedReviewListDistinctData', value: [] }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  //set requestObject
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'relatedReviewListObjectCopy',
      value: requestObect
    }
  });

  // Get list for pagination
  dispatch({
    type: GET_ALLOCATE_ASSIGNMENT,
    payload: {
      request: requestObect,
      //BadgeTwo: 'all',
      revisedGroupObject: revisedGroupObject,
      existingAssignmentId: [...existingAssignmentId, ...selectedTagsArray],
      typeOfMiddlePaneList: typeOfMiddlePaneList
    }
  });
};
export const getAssignmentGroupCreateAPI = (
  selectedAssociateInfo,
  dispatch,
  moduleName,
  assignmentCreateType
) => {
  let requestObj = {
    module: 'Assignment-Group',
    action: 'Review-List',
    requestObject: makeAssignmentGroupObj(
      selectedAssociateInfo,
      'active',
      0,
      -1,
      'list',
      assignmentCreateType
    )
  };
  dispatch({ type: SET_CORE_GROUP_REVIEW_LIST_REQ_OBJECT, payload: requestObj });
  dispatch({
    type: GET_ASSIGNMENT_GROUP_REVIEW_LIST_SAGA,
    payload: {
      request: requestObj,
      BadgeOne: '',
      BadgeTwo: '',
      BadgeThree: '',
      isMiddlePaneList: false
    }
  });
  let nodeRequestObj = makeInternalNodeObj(
    selectedAssociateInfo,
    'active',
    0,
    -1,
    '',
    'Associate-Self-Node-List-Create'
  );
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT, payload: nodeRequestObj });
  dispatch({
    type: INTERNAL_NODE_LIST_WITH_PERMISSIONS_SAGA,
    payload: {
      request: nodeRequestObj,
      BadgeOne: '',
      BadgeTwo: '',
      BadgeThree: '',
      nodeViewState: 'list',
      isMiddlePaneList: false
    }
  });
  let typeRequestObj = {
    module: 'Assignment-Type',
    action: 'Review-List',
    requestObject: makeAssignmentTypeObj(selectedAssociateInfo, 'active', 0, -1)
  };
  dispatch({ type: SET_CORE_TYPE_REVIEW_LIST_REQ_OBJECT, payload: typeRequestObj });
  dispatch({
    type: GET_ASSIGNMENT_TYPE_REVIEW_LIST_SAGA,
    payload: {
      request: typeRequestObj,
      BadgeOne: '',
      BadgeTwo: '',
      BadgeThree: '',
      isMiddlePaneList: false
    }
  });
  dispatch({ type: CLEAR_GROUP_REDUCER_STATE });
  dispatch({
    type: SET_POPUP_VALUE,
    payload: { isPopUpValue: 'NAMEPOPUP', popupMode: moduleName + 'GROUPCREATE' }
  });
};

export const getMarketplaceAssignmentDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  isSelectActive = '',
  assignmentCreateType,
  selectedTagsArray,
  restFunction = false
) => {
  let requestObect = makeMarketplaceAssignmentDistinctReviewListRequestObject(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    countPage,
    0,
    assignmentCreateType
  );
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: 'assignmentsDistinct' + secondaryOptionCheckValue }
  });
  if (secondaryOptionCheckValue === 'activeinactive') {
    secondaryOptionCheckValue = 'all';
  }
  dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: ASSIGNMENT_MARKETPLACE_REVIEW_DISTINCT_SAGA,
    payload: {
      request: requestObect,
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      isSelectActive: isSelectActive,
      selectedTagsArray: selectedTagsArray
    }
  });
  if (restFunction) {
    resetAssignmentDataFunction(dispatch, 'NoCard');
  }
};

export const getMarketplaceAssignmentGroupApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  cardValue = 'noCard',
  isSelectActive = '',
  groupViewState,
  selectedTagsArray = [],
  assignmentCreateType = ''
) => {
  if (secondaryOptionCheckValue !== 'active') {
    groupViewState = 'list';
  }
  // resetAssignmentDataFunction(dispatch, cardValue);
  let requestObj = {
    module: 'Assignment-Group',
    action: 'Review-List',
    actionFilter:
      groupViewState === 'hierarchy' ? 'Shared-Hierarchy' : 'Shared-Assignment-groupList',
    requestObject: makeMarketplaceAssignmentGroupReviewListRequestObject(
      selectedAssociateInfo,
      secondaryOptionCheckValue,
      countPage,
      0,
      groupViewState,
      assignmentCreateType
    )
  };
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: 'assignmentsGroupDistinct' + secondaryOptionCheckValue }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'nodeViewState', value: groupViewState }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: ASSIGNMENT_MARKETPLACE_REVIEW_GROUP_SAGA,
    payload: {
      request: requestObj,
      BadgeOne: targetValue,
      BadgeTwo: cardValue === 'Card' ? 'distinct' : secondaryOptionCheckValue,
      BadgeThree: cardValue === 'Card' ? secondaryOptionCheckValue : '',
      isMiddlePaneList: true,
      isSelectActive: isSelectActive,
      groupViewState,
      cardValue: cardValue,
      selectedTagsArray: selectedTagsArray
    }
  });
};

export const getMarketplaceAssignmentNodeApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  cardValue = 'NoCard',
  isSelectActive = '',
  assignmentCreateType
) => {
  let requestObect = makeMarketplaceAssignmenNodeReviewListRequestObject(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    countPage,
    0,
    assignmentCreateType
  );
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: 'assessmentsDistinct' + secondaryOptionCheckValue }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'typeOfMiddlePaneList', value: '' }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: ASSIGNMENT_MARKETPLACE_REVIEW_NODE_SAGA,
    payload: {
      request: requestObect,
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      isSelectActive: isSelectActive
    }
  });
};

export const getMarketplaceAssignmentTypeApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  selectedTagValue,
  searchStr,
  isScan,
  assignmentCreateType = ''
) => {
  let reqBody = {
    module: 'Assignment-Type',
    action: 'Review-List',
    actionFilter: 'Assignment-Type-Assignment',
    requestObject: makeMarketplaceAssignmentTypeReviewListRequestObject(
      selectedAssociateInfo,
      selectedTagValue,
      secondaryOptionCheckValue,
      0,
      countPage,
      assignmentCreateType
    )
  };
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'relatedReviewListObjectCopy',
      value: reqBody
    }
  });
  // dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({
    type: SET_RELATED_REQUEST_OBJECT,
    payload: reqBody
  });
  dispatch({ type: LOADER_START });
  // dispatch({ type: SET_REQUEST_OBJECT, payload: reqBody });
  dispatch({
    type: ASSIGNMENT_MARKETPLACE_REVIEW_TYPE_SAGA,
    payload: {
      request: reqBody,
      HeaderOne: 'items',
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      BadgeThree: '',
      // isSelectActive,
      // selectedTagsArray,
      isMiddlePaneList: true
    }
  });
};
