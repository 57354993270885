import {
  SET_SCALE_REDUCER_STATE,
  CLEAR_SCALE_REDUCER_STATE,
  SET_SCALE_INFO_REDUCER_STATE
} from '../actionType';
const initialState = {
  scaleInformation: {
    assessmentScaleName: '',
    assessmentScaleNameVerification: false,
    assessmentScaleDescription: '',
    assessmentScalePicture: '',
    assessmentScalePictureVerification: false,
    assessmentScaleMetric: []
  },
  assessmentScaleMetricInformation: {
    assessmentScaleMetricLabel: '',
    assessmentScaleMetricNumber:null,
    assessmentScaleMetricExplanation: '',
    assessmentScaleMetricScore: null,
    assessmentScaleMetricWeightage: null
  }
};

const ScaleCreateReducer = (istate = JSON.parse(JSON.stringify(initialState)), action) => {
  switch (action.type) {
    case SET_SCALE_REDUCER_STATE:
      return {
        ...istate,
        scaleInformation: action.payload
      };
    case SET_SCALE_INFO_REDUCER_STATE:
      return {
        ...istate,
        assessmentScaleMetricInformation: action.payload
      };
    case CLEAR_SCALE_REDUCER_STATE:
      return JSON.parse(JSON.stringify(initialState));
    default:
      return istate;
  }
};

export default ScaleCreateReducer;
