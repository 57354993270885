import { useSelector } from 'react-redux';

import {
  ASSESSEE_INFO_CREATE,
  ASSESSEE_REVIEW_DISTINCT_SAGA,
  ASSESSEE_SIGN_ON,
  CLEAR_ASSESSEE_INFO,
  CLEAR_DISPLAY_PANE_THREE,
  FILTERMODE,
  GET_ASSESSEEGROUP_ASSESSEE_REVIEW_LIST,
  GET_ASSESSEENODE_ASSESSEE_REVIEW_LIST,
  GET_ASSESSEEROLE_ASSESSEE_REVIEW_LIST,
  GET_ASSESSEETYPE_ASSESSEE_REVIEW_LIST,
  GET_ASSESSEE_GROUP_REVIEW_LIST_SAGA,
  GET_ASSESSEE_TYPE_REVIEW_LIST_SAGA,
  GET_ASSESSEE_ROLE_GROUP_REVIEW_LIST_SAGA,
  GET_ASSESSEE_ROLE_REVIEW_LIST_SAGA,
  LOADER_START,
  SET_CORE_GROUP_REVIEW_LIST_REQ_OBJECT,
  SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT,
  SET_CORE_ROLE_REVIEW_LIST_REQ_OBJECT,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_PAGE_COUNT,
  SET_REQUEST_OBJECT,
  SET_CORE_TYPE_REVIEW_LIST_REQ_OBJECT,
  ASSESSEE_INFO_REVISE_SAGA,
  ASSESSEE_GROUP_INFO_REVISE_SAGA,
  ASSESSEE_ROLE_INFO_REVISE_SAGA,
  ASSESSEE_TYPE_INFO_REVISE_SAGA,
  GET_ASSESSEE_INFO_SAGA,
  GET_ASSESSEE_ROLE_REVIEW_INFO_SAGA,
  GROUP_CLASSIFICATION_LIST_SAGA,
  ASSESSEE_ASSIGNMENT_RESET_SAGA,
  SET_RELATED_REQUEST_OBJECT,
  GET_ASSESSEE_ASSIGNMENT_SAGA,
  ASSESSEE_ASSIGNMENT_ASSESSMENT_RESET_SAGA,
  GET_NODE_ITEMS_REVIEW_LIST_SAGA,
  GET_JOB_NODE_JOB_REVIEW_LIST_SAGA,
  GET_NODE_ASSIGNMENTS_REVIEW_LIST_SAGA,
  GET_NODE_ASSESSMENTS_REVIEW_LIST_SAGA,
  GET_NODE_ASSOCIATE_REVIEW_LIST,
  GET_CULTURE_NODE_CULTURE_REVIEW_LIST_SAGA,
  ASSOCIATE_NODE_INFO_REVISE_SAGA,
  SET_POPUP_SINGLE_STATE,
  SECTION_POPUP_CLOSE,
  GET_ALLOCATE_ASSESSEE,
  CLEAR_GROUP_REDUCER_STATE,
  SET_POPUP_VALUE,
  ASSIGNEMT_ASSESEES_ASSESSMENT_RESET_SAGA,
  ASSESSEE_SIGN_ON_EMAILCHECK_SAGA,
  ASSESSEE_MULTI_STATUS_REVISE_SAGA,
  INTERNAL_NODE_LIST_WITH_PERMISSIONS_SAGA,
  RESET_ASSESSEE_ASSIGNMENT_SAGA,
  ASSESSEE_MARKETPLACE_REVIEW_DISTINCT_SAGA,
  ASSESSEE_MARKETPLACE_REVIEW_GROUP_SAGA,
  ASSESSEE_MARKETPLACE_REVIEW_NODE_SAGA,
  ASSESSEE_MARKETPLACE_REVIEW_ROLE_SAGA,
  ASSESSEE_MARKETPLACE_REVIEW_TYPE_SAGA,
  MARKET_PLACE_ASSESSEE_REVIEW_DISTINCT_SAGA,
} from '../actionType';
import {
  getAssesseeRelatedAssigmentReqObj,
  getAssesseeTypeAssesseeReqObj,
  getAssesseeTypeAssesseeScanReqObj,
  getNodeAssessmentsReqObj,
  getNodeAssignmentsReqObj,
  getNodeAssociatesReqObj,
  getNodeCultureProfileReqObj,
  getNodeItemsReqObj,
  getNodeJobProfileReqObj,
  makeAdministratorRoleCreateObj,
  makeAdministratorsReviewListRequestObject,
  makeAssesseeAllocateGroupObj,
  makeAssesseeAllocateTypeObj,
  makeAssesseeAssignmentRequestObj,
  makeAssesseeGroupClassificationObj,
  makeAssesseeGroupObj,
  makeAssesseeReviewListRequestObject,
  makeAssesseeMarketplaceDistinctReviewListRequestObject,
  makeAssesseeMarketplaceNodeReviewListRequestObject,
  makeAssesseeMarketplaceGroupReviewListRequestObject,
  makeAssesseeRoleAllocateObj,
  makeAssesseeRoleCreateObj,
  makeAssesseeRoleObj,
  makeAssesseeTypeObj,
  makeAssesseeWithoutmineTypeObj,
  makeAssistantsReviewListRequestObject,
  makeAssistantsRoleCreateObj,
  makeInternalNodeObj,
  makeManagerRoleCreateObj,
  makeManagersReviewListRequestObject,
  makeAssesseeMarketplaceRoleReviewListRequestObject,
  makeAssesseeMarketplaceTypeReviewListRequestObject,
  makeMarketPlaceAssesseeReviewListRequestObject
} from './GenericActions';
const resetDataFunction = (dispatch) => {
  dispatch({
    type: SET_POPUP_SINGLE_STATE,
    payload: { stateName: 'cardValue', value: 'NoCard' }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'typeOfMiddlePaneList', value: '' }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'middlePaneSelectedValue', value: '' }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'selectedFlagedArray', value: [] }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'unselectedFlagedArray', value: [] }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'selectedTagsArray', value: [] }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'unselectedTagsArray', value: [] }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'flagedValue', value: '' }
  });
  dispatch({
    type: SET_POPUP_SINGLE_STATE,
    payload: { stateName: 'selectionValue', value: '' }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'scanString', value: '' }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'allocatedTagsArray', value: [] }
  });
  dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({ type: ASSESSEE_INFO_CREATE });
  dispatch({ type: SECTION_POPUP_CLOSE });
};
export const getAssesseeDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  isSelectActive = '',
  assignmentCreateType
) => {
  let requestObect = makeAssesseeReviewListRequestObject(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    0,
    countPage,
    assignmentCreateType
  );
  dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  secondaryOptionCheckValue =
    secondaryOptionCheckValue === 'activeinactive' ? 'all' : secondaryOptionCheckValue;
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: 'assesseeDistinct' + secondaryOptionCheckValue }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: ASSESSEE_REVIEW_DISTINCT_SAGA,
    payload: {
      HeaderOne: 'assessees',
      request: requestObect,
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      isSelectActive: isSelectActive
    }
  });
};

export const getMarketPlaceAssesseesDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  isSelectActive = ''
) =>{
  console.log('markrtpalce--------------' );
  let requestObject = makeMarketPlaceAssesseeReviewListRequestObject(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    0,
    countPage
  );

  dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  secondaryOptionCheckValue =
    secondaryOptionCheckValue === 'activeinactive' ? 'all' : secondaryOptionCheckValue;
  dispatch({
    type: FILTERMODE,
    payload:       { FilterMode: 'assesseeDistinct' + secondaryOptionCheckValue }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObject });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: MARKET_PLACE_ASSESSEE_REVIEW_DISTINCT_SAGA,
    payload: {
      HeaderOne: 'assessees',
      request: requestObject,
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      isSelectActive: isSelectActive
    }
  });

};

export const getAssesseeRoleAssesseeReqObj = (
  selectedAssociateInfo,
  roleId,
  filterKey,
  numberPage,
  countPage
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'activeinactive') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['CONFIRMED', 'UNCONFIRMED']
      }
    };
  }
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['CONFIRMED', 'DISAPPROVED', 'SUSPENDED', 'TERMINATED', 'UNAPPROVED', 'UNCONFIRMED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: [
          'CONFIRMED',
          'DISAPPROVED',
          'SUSPENDED',
          'TERMINATED',
          'UNAPPROVED',
          'UNCONFIRMED',
          'ARCHIVED'
        ]
      }
    };
  }
  return {
    module: 'Assessee-Role',
    action: 'Review-List',
    actionFilter: 'Assessee',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      roleId: roleId,
      orderBy: {
        columnName:
          'informationBasic.assesseeNameFirst,informationBasic.assesseeNameOther,informationBasic.assesseeNameLast,informationBasic.assesseeAlias',
        order: 'asc'
      },
      filter: 'true',
      searchCondition: 'AND',
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.assesseeRole.assesseeRolePrimary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: roleId
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.assesseeRole.assesseeRoleSecondary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: roleId
                }
              }
            }
          ]
        },
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assesseeStatus',
              conditionValue: searchObj
            }
          ]
        }
      ]
    }
  };
};
export const getAssesseeRoleAssesseeScanReqObj = (
  selectedAssociateInfo,
  roleId,
  filterKey,
  numberPage,
  countPage,
  searchStr = ''
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['CONFIRMED', 'DISAPPROVED', 'SUSPENDED', 'TERMINATED', 'UNAPPROVED', 'UNCONFIRMED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: [
          'CONFIRMED',
          'DISAPPROVED',
          'SUSPENDED',
          'TERMINATED',
          'UNAPPROVED',
          'UNCONFIRMED',
          'ARCHIVED'
        ]
      }
    };
  }
  return {
    module: 'Assessee-Role',
    action: 'Review-List',
    actionFilter: 'Assessee',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      roleId: roleId,
      orderBy: {
        columnName:
          'informationBasic.assesseeNameFirst,informationBasic.assesseeNameOther,informationBasic.assesseeNameLast,informationBasic.assesseeAlias',
        order: 'asc'
      },
      filter: 'true',
      searchCondition: 'AND',
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assesseeNameFirst',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assesseeNameOther',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assesseeNameLast',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assesseeAlias',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn:
                'informationContact.assesseeAddressEmailPrimary.assesseeAddressEmail',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationContact.assesseeAddressEmailSecondary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn:
                'informationContact.assesseeTelephoneMobilePrimary.assesseeTelephoneNumber',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn:
                'informationContact.assesseeTelephoneMobileSecondary.assesseeTelephoneNumber',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assesseeTag.assesseeTagPrimary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assesseeTag.assesseeTagSecondary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            }
          ]
        },
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assesseeStatus',
              conditionValue: searchObj
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.assesseeRole.assesseeRolePrimary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: roleId
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.assesseeRole.assesseeRoleSecondary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: roleId
                }
              }
            }
          ]
        }
      ]
    }
  };
};

export const getAssesseeNodeAssesseeReqObj = (
  selectedAssociateInfo,
  nodeId,
  filterKey,
  numberPage,
  countPage
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['CONFIRMED', 'DISAPPROVED', 'SUSPENDED', 'TERMINATED', 'UNAPPROVED', 'UNCONFIRMED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: [
          'CONFIRMED',
          'DISAPPROVED',
          'SUSPENDED',
          'TERMINATED',
          'UNAPPROVED',
          'UNCONFIRMED',
          'ARCHIVED'
        ]
      }
    };
  }
  return {
    module: 'Assessee-Distinct',
    action: 'Review-List',
    actionFilter: 'Associate-Node-Assessee',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      nodeId: nodeId,
      filter: 'true',
      searchCondition: 'AND',
      orderBy: {
        columnName:
          'informationBasic.assesseeNameFirst,informationBasic.assesseeNameOther,informationBasic.assesseeNameLast,informationBasic.assesseeAlias',
        order: 'asc'
      },
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.assesseeNode.assesseeNodePrimary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: nodeId
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.assesseeNode.assesseeNodeSecondary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: nodeId
                }
              }
            }
          ]
        },
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assesseeStatus',
              conditionValue: searchObj
            }
          ]
        }
      ]
    }
  };
};
export const getAssesseeNodeAssesseeScanReqObj = (
  selectedAssociateInfo,
  nodeId,
  filterKey,
  numberPage,
  countPage,
  searchStr
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['CONFIRMED', 'DISAPPROVED', 'SUSPENDED', 'TERMINATED', 'UNAPPROVED', 'UNCONFIRMED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: [
          'CONFIRMED',
          'DISAPPROVED',
          'SUSPENDED',
          'TERMINATED',
          'UNAPPROVED',
          'UNCONFIRMED',
          'ARCHIVED'
        ]
      }
    };
  }
  return {
    module: 'Assessee-Distinct',
    action: 'Review-List',
    actionFilter: 'Associate-Node-Assessee',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      nodeId: nodeId,
      filter: 'true',
      searchCondition: 'AND',
      orderBy: {
        columnName:
          'informationBasic.assesseeNameFirst,informationBasic.assesseeNameOther,informationBasic.assesseeNameLast,informationBasic.assesseeAlias',
        order: 'asc'
      },
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.assesseeNode.assesseeNodePrimary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: nodeId
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.assesseeNode.assesseeNodeSecondary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: nodeId
                }
              }
            }
          ]
        },
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assesseeStatus',
              conditionValue: searchObj
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assesseeNameFirst',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assesseeNameOther',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assesseeNameLast',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assesseeAlias',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn:
                'informationContact.assesseeAddressEmailPrimary.assesseeAddressEmail',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn:
                'informationContact.assesseeAddressEmailSecondary.assesseeAddressEmail',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn:
                'informationContact.assesseeTelephoneMobilePrimary.assesseeTelephoneNumber',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn:
                'informationContact.assesseeTelephoneMobileSecondary.assesseeTelephoneNumber',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: '_id',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            }
          ]
        }
      ]
    }
  };
};

export const getAssesseeGroupAssesseeReqObj = (
  selectedAssociateInfo,
  groupId,
  filterKey,
  numberPage,
  countPage
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['CONFIRMED', 'DISAPPROVED', 'SUSPENDED', 'TERMINATED', 'UNAPPROVED', 'UNCONFIRMED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: [
          'CONFIRMED',
          'DISAPPROVED',
          'SUSPENDED',
          'TERMINATED',
          'UNAPPROVED',
          'UNCONFIRMED',
          'ARCHIVED'
        ]
      }
    };
  }
  return {
    module: 'Assessee-Group',
    action: 'Review-List',
    actionFilter: 'Assessee',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      groupId: groupId,
      filter: 'true',
      orderBy: {
        columnName:
          'informationBasic.assesseeNameFirst,informationBasic.assesseeNameOther,informationBasic.assesseeNameLast,informationBasic.assesseeAlias',
        order: 'asc'
      },
      searchCondition: 'AND',
      orderBy: {
        columnName:
          'informationBasic.assesseeNameFirst,informationBasic.assesseeNameOther,informationBasic.assesseeNameLast,informationBasic.assesseeAlias',
        order: 'asc'
      },
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.assesseeGroup.assesseeGroupPrimary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: groupId
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.assesseeGroup.assesseeGroupSecondary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: groupId
                }
              }
            }
          ]
        },
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assesseeStatus',
              conditionValue: searchObj
            }
          ]
        }
      ]
    }
  };
};
export const getAssesseeGroupAssesseeScanReqObj = (
  selectedAssociateInfo,
  groupId,
  filterKey,
  numberPage,
  countPage,
  searchStr
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['CONFIRMED', 'DISAPPROVED', 'SUSPENDED', 'TERMINATED', 'UNAPPROVED', 'UNCONFIRMED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: [
          'CONFIRMED',
          'DISAPPROVED',
          'SUSPENDED',
          'TERMINATED',
          'UNAPPROVED',
          'UNCONFIRMED',
          'ARCHIVED'
        ]
      }
    };
  }
  return {
    module: 'Assessee-Group',
    action: 'Review-List',
    actionFilter: 'Assessee',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      groupId: groupId,
      filter: 'true',
      orderBy: {
        columnName:
          'informationBasic.assesseeNameFirst,informationBasic.assesseeNameOther,informationBasic.assesseeNameLast,informationBasic.assesseeAlias',
        order: 'asc'
      },
      searchCondition: 'AND',
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assesseeNameFirst',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assesseeNameOther',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assesseeNameLast',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assesseeAlias',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assesseeTag.assesseeTagPrimary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn:
                'informationContact.assesseeAddressEmailPrimary.assesseeAddressEmail',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn:
                'informationContact.assesseeAddressEmailSecondary.assesseeAddressEmail',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn:
                'informationContact.assesseeTelephoneMobilePrimary.assesseeTelephoneNumber',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn:
                'informationContact.assesseeTelephoneMobileSecondary.assesseeTelephoneNumber',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            }
          ]
        },
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assesseeStatus',
              conditionValue: searchObj
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.assesseeGroup.assesseeGroupPrimary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: groupId
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.assesseeGroup.assesseeGroupSecondary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: groupId
                }
              }
            }
          ]
        }
      ]
    }
  };
};

export const getAssesseeGroupAssesseeDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  selectedTagValue,
  searchStr,
  isScan,
  isMiddlePaneList,
  isSelectActive = '',
  selectedTagsArray = [],
  middlePaneHeader = 'assessees'
) => {
  let reqBody = getAssesseeGroupAssesseeReqObj(
    selectedAssociateInfo,
    selectedTagValue,
    secondaryOptionCheckValue,
    0,
    countPage
  );
  if (isScan) {
    reqBody = getAssesseeGroupAssesseeScanReqObj(
      selectedAssociateInfo,
      selectedTagValue,
      secondaryOptionCheckValue,
      0,
      countPage,
      searchStr
    );
  }
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'relatedReviewListObjectCopy',
      value: reqBody
    }
  });

  // dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'relatedReviewListDistinctData', value: [] }
  });
  dispatch({ type: LOADER_START });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'relatedReviewListObjectCopy',
      value: reqBody
    }
  });
  // dispatch({ type: SET_REQUEST_OBJECT, payload: reqBody });//
  dispatch({
    type: GET_ASSESSEEGROUP_ASSESSEE_REVIEW_LIST,
    payload: {
      request: reqBody,
      HeaderOne: middlePaneHeader,
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      BadgeThree: '',
      isMiddlePaneList: isMiddlePaneList,
      isSelectActive: isSelectActive,
      selectedTagsArray: selectedTagsArray
    }
  });
};
export const getAssesseeRoleAssesseeDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  selectedTagValue,
  searchStr,
  isScan,
  middlePaneHeader
) => {
  let reqBody = getAssesseeRoleAssesseeReqObj(
    selectedAssociateInfo,
    selectedTagValue,
    secondaryOptionCheckValue,
    0,
    countPage
  );
  if (isScan) {
    reqBody = getAssesseeRoleAssesseeScanReqObj(
      selectedAssociateInfo,
      selectedTagValue,
      secondaryOptionCheckValue,
      0,
      countPage,
      searchStr
    );
  }

  // dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'relatedReviewListDistinctData', value: [] }
  });
  dispatch({ type: LOADER_START });
  // dispatch({ type: SET_REQUEST_OBJECT, payload: reqBody });

  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'relatedReviewListObjectCopy',
      value: reqBody
    }
  });
  dispatch({
    type: GET_ASSESSEEROLE_ASSESSEE_REVIEW_LIST,
    payload: {
      request: reqBody,
      HeaderOne: middlePaneHeader,
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      BadgeThree: '',
      isMiddlePaneList: true
    }
  });
};
//
export const getAssesseeTypeAssesseeDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  selectedTagValue,
  searchStr,
  isScan,
  middlePaneHeader = 'assessees'
) => {
  let reqBody = getAssesseeTypeAssesseeReqObj(
    selectedAssociateInfo,
    selectedTagValue,
    secondaryOptionCheckValue,
    0,
    countPage
  );
  if (isScan) {
    reqBody = getAssesseeTypeAssesseeScanReqObj(
      selectedAssociateInfo,
      selectedTagValue,
      secondaryOptionCheckValue,
      0,
      countPage,
      searchStr
    );
  }
  // Object copy to send data to pagination
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'relatedReviewListObjectCopy',
      value: reqBody
    }
  });

  // dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'relatedReviewListDistinctData', value: [] }
  });
  dispatch({ type: LOADER_START });
  // dispatch({ type: SET_REQUEST_OBJECT, payload: reqBody });
  dispatch({
    type: GET_ASSESSEETYPE_ASSESSEE_REVIEW_LIST,
    payload: {
      request: reqBody,
      HeaderOne: middlePaneHeader,
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      BadgeThree: '',
      isMiddlePaneList: true
    }
  });
};
export const getAssesseeNodeAssesseeDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  selectedTagValue,
  searchStr,
  isScan,
  middlePaneHeader = 'assessees',
  isMiddlePaneList = true
) => {
  let reqBody = getAssesseeNodeAssesseeReqObj(
    selectedAssociateInfo,
    selectedTagValue,
    secondaryOptionCheckValue,
    0,
    countPage
  );
  if (isScan) {
    reqBody = getAssesseeNodeAssesseeScanReqObj(
      selectedAssociateInfo,
      selectedTagValue,
      secondaryOptionCheckValue,
      0,
      countPage,
      searchStr
    );
  }
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'relatedReviewListObjectCopy',
      value: reqBody
    }
  });
  // dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  if (isMiddlePaneList) {
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'relatedReviewListDistinctData', value: [] }
    });
  }
  dispatch({ type: LOADER_START });
  // dispatch({ type: SET_REQUEST_OBJECT, payload: reqBody });
  dispatch({
    type: GET_ASSESSEENODE_ASSESSEE_REVIEW_LIST,
    payload: {
      request: reqBody,
      HeaderOne: middlePaneHeader,
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      BadgeThree: '',
      isMiddlePaneList: isMiddlePaneList
    }
  });
};

export const assesseeRole = (roleName) => {
  let txt = roleName;
  var arr = [];
  if (roleName && roleName.includes(' (')) {
    let newRoles = roleName.split('(');
    let word = '';
    for (var j = 1; j < newRoles.length; j++) {
      word = newRoles[j].split(')')[0];
      let newwrd = word.replace(' ', '||');
      txt = txt.replace('(' + word + ')', '{' + newwrd + '}');
    }
    let finlastr = txt;
    var finalsplit = finlastr.split(' ');
    let str = '';
    for (var i = 0; i < finalsplit.length; i++) {
      if (finalsplit[i].charAt(0) === '{') {
        let nobadge = finalsplit[i];
        let finalentry = nobadge.replace('{', '').replace('}', '').replace('||', ' ');
        str =
          str +
          "<span class='headerBadge font1_0' style='top:2px'>" +
          finalentry +
          '</span><span>&nbsp;</span>';
        arr.push(str);
      }

      if (finalsplit[i].charAt(0) !== '{') {
        str = str + '<span>' + finalsplit[i] + '</span><span>&nbsp;</span>';
        arr.push(str);
      }
    }
    return str;
  } else {
    return roleName;
  }
};

export const getAssesseeRoleDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  targetValue,
  dispatch,
  cardValue = 'noCard',
  isSelectActive = '',
  actionFilter = ''
) => {
  let requestObj = makeAssesseeRoleObj(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    0,
    countPage,
    actionFilter
  );
  dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: 'assesseeRoleDistinct' + secondaryOptionCheckValue }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: GET_ASSESSEE_ROLE_REVIEW_LIST_SAGA,
    payload: {
      request: requestObj,
      BadgeOne: targetValue,
      BadgeTwo: cardValue === 'Card' ? 'distinct' : secondaryOptionCheckValue,
      BadgeThree: cardValue === 'Card' ? secondaryOptionCheckValue : '',
      isMiddlePaneList: true,
      isSelectActive: isSelectActive,
      actionFilter: actionFilter
    }
  });
};

export const getAssesseeRoleAllocateDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  targetValue,
  dispatch,
  cardValue = 'noCard',
  isSelectActive = '',
  allocatedTagsArray = []
) => {
  let requestObj = makeAssesseeRoleAllocateObj(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    0,
    countPage,
    allocatedTagsArray
  );
  dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: 'assesseeRoleDistinct' + secondaryOptionCheckValue }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: GET_ASSESSEE_ROLE_REVIEW_LIST_SAGA,
    payload: {
      request: requestObj,
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      // BadgeThree: cardValue === 'Card' ? secondaryOptionCheckValue : '',
      isMiddlePaneList: true,
      isSelectActive: isSelectActive
    }
  });
};

export const getAssesseeGroupDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  cardValue = 'noCard',
  isSelectActive = '',
  groupViewState,
  selectedTagsArray = '',
  assignmentCreateType,
  middlePaneHeader
) => {
  if (secondaryOptionCheckValue !== 'active') {
    groupViewState = 'list';
  }
  let requestObj = makeAssesseeGroupObj(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    countPage,
    0,
    groupViewState,
    assignmentCreateType
  );

  dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({
    type: FILTERMODE,
    payload: {
      FilterMode: 'assesseesGroupDistinct' + secondaryOptionCheckValue
    }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'nodeViewState', value: groupViewState }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: GET_ASSESSEE_GROUP_REVIEW_LIST_SAGA,
    payload: {
      request: requestObj,
      middlePaneHeader: middlePaneHeader,
      BadgeOne: targetValue,
      BadgeTwo: cardValue === 'Card' ? 'distinct' : secondaryOptionCheckValue,
      BadgeThree: cardValue === 'Card' ? secondaryOptionCheckValue : '',
      isMiddlePaneList: true,
      cardValue: cardValue,
      isSelectActive: isSelectActive,
      groupViewState: groupViewState,
      selectedTagsArray: selectedTagsArray
    }
  });
};
export const getAssesseeGroupAllocateDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  cardValue = 'noCard',
  isSelectActive = '',
  groupViewState,
  selectedTagsArray = '',
  assignmentCreateType,
  allocatedTagsArray
) => {
  if (secondaryOptionCheckValue !== 'active' || isSelectActive === 'multiple') {
    groupViewState = 'list';
  }
  let requestObj = makeAssesseeAllocateGroupObj(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    countPage,
    0,
    groupViewState,
    assignmentCreateType,
    allocatedTagsArray
  );

  dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({
    type: FILTERMODE,
    payload: {
      FilterMode: 'assesseesGroupDistinct' + secondaryOptionCheckValue
    }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'nodeViewState', value: groupViewState }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: GET_ASSESSEE_GROUP_REVIEW_LIST_SAGA,
    payload: {
      request: requestObj,
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      // BadgeThree: cardValue === 'Card' ? secondaryOptionCheckValue : '',
      isMiddlePaneList: true,
      cardValue: cardValue,
      isSelectActive: isSelectActive,
      groupViewState: groupViewState,
      selectedTagsArray: selectedTagsArray
    }
  });
};
export const onClickCheckBoxSelectionForVersion = (
  selectedTagsArray,
  unselectedTagsArray,
  event,
  dispatch,
  maximumArrayLen
) => {
  let id = event.target.id;
  let checkedArr = [...selectedTagsArray];
  let unCheckArr = [...unselectedTagsArray];
  if (maximumArrayLen) {
    if (checkedArr.includes(id) && checkedArr.length < maximumArrayLen) {
      checkedArr = checkedArr.filter(function (number) {
        return number !== id;
      });
      unCheckArr.push(id);
    } else if (checkedArr.includes(id) === false && checkedArr.length < maximumArrayLen) {
      checkedArr.push(id);
      unCheckArr = unCheckArr.filter(function (number) {
        return number !== id;
      });
    } else {
      checkedArr = checkedArr.filter(function (number) {
        return number !== id;
      });
      unCheckArr.push(id);
    }
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'selectedTagsArray', value: checkedArr }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'unselectedTagsArray', value: unCheckArr }
    });
  }
};
export const onClickCheckBoxSelection = (
  selectedTagsArray,
  unselectedTagsArray,
  event,
  dispatch
) => {
  let id = event.target.id;
  let checkedArr = [...selectedTagsArray];
  let unCheckArr = [...unselectedTagsArray];
  if (checkedArr.includes(id)) {
    checkedArr = checkedArr.filter(function (number) {
      return number !== id;
    });
    unCheckArr.push(id);
  } else {
    checkedArr.push(id);
    unCheckArr = unCheckArr.filter(function (number) {
      return number !== id;
    });
  }
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'selectedTagsArray', value: checkedArr }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'unselectedTagsArray', value: unCheckArr }
  });
};

export const onClickCheckBoxSelectionOnlyOne = (
  selectedTagsArray,
  unselectedTagsArray,
  event,
  dispatch
) => {
  let checkedArr = [];
  let id = event.target.id;
  if (event.target.checked) {
    checkedArr[0] = id;
  } else {
    checkedArr = [];
  }
  // let checkedArr = [...selectedTagsArray];
  // let unCheckArr = [...unselectedTagsArray];
  // if (checkedArr.includes(id)) {
  //   checkedArr = checkedArr.filter(function (number) {
  //     return number !== id;
  //   });
  //   unCheckArr.push(id);
  // } else {
  //   checkedArr.push(id);
  //   unCheckArr = unCheckArr.filter(function (number) {
  //     return number !== id;
  //   });
  // }
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'selectedTagsArray', value: checkedArr }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'unselectedTagsArray', value: [] }
  });
};

export const onClickCheckBoxOneListSelection = (selectedTagsArray, event, dispatch) => {
  let id = event.target.id;
  let checkedArr = [...selectedTagsArray];
  if (checkedArr.includes(id)) {
    checkedArr = checkedArr.filter(function (number) {
      return number !== id;
    });
  } else {
    checkedArr.push(id);
  }
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'selectedTagsArray', value: checkedArr }
  });
};

export const onClickCheckBoxOneListforAssignmentAssessee = (
  selectedTagsArray,
  exstingTagIds,
  event,
  dispatch
) => {
  let id = event.target.id;
  let checkedArr = [...selectedTagsArray];
  if (!exstingTagIds.includes(id)) {
    if (checkedArr.includes(id)) {
      checkedArr = checkedArr.filter(function (number) {
        return number !== id;
      });
    } else {
      checkedArr.push(id);
    }
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'selectedTagsArray', value: checkedArr }
    });
  }
};
export const onClickFlagSelection = (
  selectedFlagedArray,
  unselectedFlagedArray,
  event,
  dispatch
) => {
  let id = event.target.id;
  let flagedArr = [...selectedFlagedArray];
  let unFlagedArr = [...unselectedFlagedArray];
  if (flagedArr.includes(id)) {
    flagedArr = flagedArr.filter(function (number) {
      return number !== id;
    });
    unFlagedArr.push(id);
  } else {
    flagedArr.push(id);
    unFlagedArr = unFlagedArr.filter(function (number) {
      return number !== id;
    });
  }
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'selectedFlagedArray', value: flagedArr }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'unselectedFlagedArray', value: unFlagedArr }
  });
};

export const setFlagedArray = (reviewListDistinctData, key, dispatch) => {
  let flagedArr = [];
  reviewListDistinctData.forEach((data) => {
    if (data.informationBasic[key]) {
      flagedArr.push(data.id);
    }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'selectedFlagedArray', value: flagedArr }
  });
};
export const setFlagedArrayAssociateNode = (reviewListDistinctData, key, dispatch) => {
  let flagedArr = [];
  reviewListDistinctData.forEach((data) => {
    if (data.associateNode.informationBasic[key]) {
      flagedArr.push(data.id);
    }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'selectedFlagedArray', value: flagedArr }
  });
};

export const getRoleGroupReviewListApi = (selectedAssociateInfo, dispatch, popupHeaderOne) => {
  dispatch({ type: LOADER_START });
  let requestObj = {
    assesseeId: selectedAssociateInfo?.assesseeId,
    associateId:
      selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
    associateAscendantPrimary:
      localStorage.getItem('parentId') === 'null' ? null : localStorage.getItem('parentId')
  };
  dispatch({ type: SET_CORE_ROLE_REVIEW_LIST_REQ_OBJECT, payload: requestObj });
  dispatch({
    type: GET_ASSESSEE_ROLE_GROUP_REVIEW_LIST_SAGA,
    payload: { request: requestObj, typeGroup: popupHeaderOne }
  });
};
export const getClassificationReviewListApi = (selectedAssociateInfo, dispatch, popupHeaderOne) => {
  let classRequestObj = makeAssesseeGroupClassificationObj(selectedAssociateInfo);
  dispatch({ type: LOADER_START });
  dispatch({
    type: SET_CORE_ROLE_REVIEW_LIST_REQ_OBJECT,
    payload: classRequestObj
  });
  dispatch({
    type: GROUP_CLASSIFICATION_LIST_SAGA,
    payload: {
      request: classRequestObj,
      BadgeOne: '',
      BadgeTwo: '',
      BadgeThree: '',
      classificationType: popupHeaderOne,
      isMiddlePaneList: false
    }
  });
};

export const assesseeCreateApiCalls = (
  selectedAssociateInfo,
  dispatch,
  secondaryOptionCheckValue,
  typeOfAssesseeCreate,
  assignmentCreateType
) => {
  dispatch({ type: ASSESSEE_INFO_CREATE });
  dispatch({ type: CLEAR_ASSESSEE_INFO });
  dispatch({ type: LOADER_START });
  let requestObj = makeAssesseeGroupObj(
    selectedAssociateInfo,
    'active',
    0,
    -1,
    'list',
    assignmentCreateType
  );
  dispatch({
    type: GET_ASSESSEE_GROUP_REVIEW_LIST_SAGA,
    payload: {
      request: requestObj,
      BadgeOne: '',
      BadgeTwo: '',
      BadgeThree: '',
      isMiddlePaneList: false
    }
  });
  dispatch({
    type: SET_CORE_GROUP_REVIEW_LIST_REQ_OBJECT,
    payload: requestObj
  });
  let roleRequestObj = makeAssesseeRoleCreateObj(selectedAssociateInfo, 'active', 0, -1);
  if (typeOfAssesseeCreate === 'administrator')
    roleRequestObj = makeAdministratorRoleCreateObj(selectedAssociateInfo, 'active', 0, -1);
  if (typeOfAssesseeCreate === 'manager' || typeOfAssesseeCreate === 'manager')
    roleRequestObj = makeManagerRoleCreateObj(selectedAssociateInfo, 'active', 0, -1);
  if (typeOfAssesseeCreate === 'assistants' || typeOfAssesseeCreate === 'assistant')
    roleRequestObj = makeAssistantsRoleCreateObj(selectedAssociateInfo, 'active', 0, -1);
  dispatch({
    type: SET_CORE_ROLE_REVIEW_LIST_REQ_OBJECT,
    payload: roleRequestObj
  });
  dispatch({
    type: GET_ASSESSEE_ROLE_REVIEW_LIST_SAGA,
    payload: {
      request: roleRequestObj,
      BadgeOne: '',
      BadgeTwo: '',
      BadgeThree: '',
      isMiddlePaneList: false
    }
  });
  let nodeRequestObj = makeInternalNodeObj(
    selectedAssociateInfo,
    'active',
    0,
    -1,
    '',
    'Associate-Self-Node-List-Create'
  );
  dispatch({
    type: SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT,
    payload: nodeRequestObj
  });
  dispatch({
    type: INTERNAL_NODE_LIST_WITH_PERMISSIONS_SAGA,
    payload: {
      request: nodeRequestObj,
      BadgeOne: '',
      BadgeTwo: '',
      BadgeThree: '',
      nodeViewState: 'list',
      isMiddlePaneList: false
    }
  });
  let typeRequestObj = makeAssesseeWithoutmineTypeObj(selectedAssociateInfo, 'all', 0, -1);
  dispatch({
    type: SET_CORE_TYPE_REVIEW_LIST_REQ_OBJECT,
    payload: typeRequestObj
  });
  dispatch({
    type: GET_ASSESSEE_TYPE_REVIEW_LIST_SAGA,
    payload: {
      middlePaneHeader: '',
      request: typeRequestObj,
      BadgeOne: '',
      BadgeTwo: '',
      BadgeThree: '',
      isMiddlePaneList: false
    }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'selectedInformationAllorKey',
      value: secondaryOptionCheckValue
    }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'typeOfAssesseeCreate',
      value: typeOfAssesseeCreate
    }
  });
  let popupMode = 'ASSESSEE_CREATE';
  if (typeOfAssesseeCreate === 'administrator') {
    popupMode = 'ADMINISTRATOR_CREATE';
  }
  if (typeOfAssesseeCreate === 'managers' || typeOfAssesseeCreate === 'manager') {
    popupMode = 'MANAGER_CREATE';
  }
  if (typeOfAssesseeCreate === 'assistants' || typeOfAssesseeCreate === 'assistant') {
    popupMode = 'ASSISTANT_CREATE';
  }
  dispatch({
    type: ASSESSEE_SIGN_ON,
    payload: { isPopUpValue: 'ASSESSEENAMEPOPUP', popupMode: popupMode }
  });
};

export const getAdminManagerRoleApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  popupHeaderOne,
  dispatch,
  cardValue = 'noCard'
) => {
  dispatch({ type: LOADER_START });
  let roleRequestObj =
    popupHeaderOne === 'administrators'
      ? makeAdministratorRoleCreateObj(
        selectedAssociateInfo,
        secondaryOptionCheckValue,
        0,
        countPage
      )
      : popupHeaderOne === 'assistants'
        ? makeAssistantsRoleCreateObj(selectedAssociateInfo, secondaryOptionCheckValue, 0, countPage)
        : makeManagerRoleCreateObj(selectedAssociateInfo, secondaryOptionCheckValue, 0, countPage);
  dispatch({ type: SET_REQUEST_OBJECT, payload: roleRequestObj });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'nodeViewState', value: '' }
  });
  dispatch({
    type: FILTERMODE,
    payload: {
      FilterMode: 'assesseeRoleDistinct' + secondaryOptionCheckValue
    }
  });
  dispatch({
    type: GET_ASSESSEE_ROLE_REVIEW_LIST_SAGA,
    payload: {
      request: roleRequestObj,
      middlePaneHeader: popupHeaderOne,
      BadgeOne: 'roles',
      BadgeTwo: cardValue === 'Card' ? 'distinct' : secondaryOptionCheckValue,
      BadgeThree: cardValue === 'Card' ? secondaryOptionCheckValue : '',
      isMiddlePaneList: true
    }
  });
};

export const getAdminManagerDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  popupHeaderOne,
  dispatch
) => {
  let requestObj = {};
  if (popupHeaderOne === 'managers') {
    requestObj = makeManagersReviewListRequestObject(
      selectedAssociateInfo,
      secondaryOptionCheckValue,
      0,
      countPage
    );
  } else if (popupHeaderOne === 'assistants') {
    requestObj = makeAssistantsReviewListRequestObject(
      selectedAssociateInfo,
      secondaryOptionCheckValue,
      0,
      countPage
    );
  } else {
    requestObj = makeAdministratorsReviewListRequestObject(
      selectedAssociateInfo,
      secondaryOptionCheckValue,
      0,
      countPage
    );
  }
  dispatch({
    type: FILTERMODE,
    payload: {
      FilterMode: popupHeaderOne.slice(0, -1) + 'Distinct' + secondaryOptionCheckValue
    }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: ASSESSEE_REVIEW_DISTINCT_SAGA,
    payload: {
      request: requestObj,
      HeaderOne: popupHeaderOne,
      BadgeOne: 'distinct',
      BadgeTwo: secondaryOptionCheckValue,
      BadgeThree: '',
      isMiddlePaneList: true
    }
  });
  dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
};

export const getAssesseeTypeApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  cardValue = 'noCard',
  middlePaneHeader = 'assessees',
  isSelectActive = '',
  actionFilter = ''
) => {
  let requestObj = makeAssesseeTypeObj(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    countPage,
    0,
    actionFilter
  );

  dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({
    type: FILTERMODE,
    payload: {
      FilterMode: 'assesseesTypeDistinct' + secondaryOptionCheckValue
    }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'nodeViewState', value: '' }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: GET_ASSESSEE_TYPE_REVIEW_LIST_SAGA,
    payload: {
      middlePaneHeader: middlePaneHeader,
      request: requestObj,
      BadgeOne: targetValue,
      BadgeTwo: cardValue === 'Card' ? 'distinct' : secondaryOptionCheckValue,
      BadgeThree: cardValue === 'Card' ? secondaryOptionCheckValue : '',
      isMiddlePaneList: true,
      isSelectActive: isSelectActive,
      actionFilter: actionFilter
    }
  });
};

export const getAssesseeAllocateTypeApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  cardValue = 'noCard',
  middlePaneHeader = 'assessees',
  isSelectActive = '',
  allocatedTagsArray
) => {
  let requestObj = makeAssesseeAllocateTypeObj(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    countPage,
    0,
    allocatedTagsArray
  );

  dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({
    type: FILTERMODE,
    payload: {
      FilterMode: 'assesseesTypeDistinct' + secondaryOptionCheckValue
    }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: GET_ASSESSEE_TYPE_REVIEW_LIST_SAGA,
    payload: {
      middlePaneHeader: middlePaneHeader,
      request: requestObj,
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      // BadgeThree: cardValue === 'Card' ? secondaryOptionCheckValue : '',
      isMiddlePaneList: true,
      isSelectActive: isSelectActive
    }
  });
};

export const updateAssesseeDistinctStatus = (
  selectedAssociateInfo,
  assesseeId,
  dispatch,
  reviseStatus,
  createMode,
  typeOfMiddlePaneList,
  cachedPreviousSelectedTagValue,
  listType = ''
) => {
  let reqBody;
  if (
    reviseStatus === 'UNAPPROVED' ||
    reviseStatus === 'APPROVED' ||
    reviseStatus === 'DISAPPROVED'
  ) {
    reqBody = {
      module: 'Assessee-Distinct',
      action: 'Approval',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        assesseeApprovalList: [assesseeId],
        approval: reviseStatus === 'APPROVED'
      }
    };
  } else {
    reqBody = {
      module: 'Assessee-Distinct',
      action: 'Revise',
      actionFilter: 'Status',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        assesseeDistinct: [assesseeId],
        reviseStatus: reviseStatus
      }
    };
  }
  dispatch({ type: LOADER_START });
  dispatch({
    type: ASSESSEE_MULTI_STATUS_REVISE_SAGA,
    payload: {
      secondaryOptionCheckValue: '',
      headerOne: '',
      reqBody,
      createMode,
      typeOfMiddlePaneList,
      cachedPreviousSelectedTagValue,
      listType
    }
  });
};
export const updateAssesseeGroupStatus = (
  selectedAssociateInfo,
  groupId,
  dispatch,
  reviseStatus
) => {
  let reqBody = {
    module: 'Assessee-Group',
    action: 'Revise', //
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      assesseeGroup: {
        id: groupId,
        informationEngagement: {
          assesseeGroupStatus:
            reviseStatus === 'UNSUSPENDED' ||
              reviseStatus === 'UNTERMINATED' ||
              reviseStatus === 'UNARCHIVED'
              ? 'ACTIVE'
              : reviseStatus
        }
      }
    }
  };
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'typeOfMiddlePaneList', value: '' }
  });
  dispatch({ type: LOADER_START });
  dispatch({
    type: ASSESSEE_GROUP_INFO_REVISE_SAGA,
    payload: { secondaryOptionCheckValue: '', headerOne: '', reqBody }
  });
};
export const assesseeRoleReviewInformation = (
  selectedAssociateInfo,
  dispatch,
  secondaryOptionCheckValue,
  isReviseMode,
  typeOfMiddlePaneList,
  selectedTagValue,
  countPage,
  createMode,
  headerOne
) => {
  let assesseeRoleAssesseeReqBody = getAssesseeRoleAssesseeReqObj(
    selectedAssociateInfo,
    selectedTagValue,
    '',
    -1,
    -1
  );
  dispatch({
    type: GET_ASSESSEE_ROLE_REVIEW_INFO_SAGA,
    payload: {
      headerOne,
      secondaryOptionCheckValue,
      assesseeRoleAssesseeReqBody,
      isReviseMode,
      createMode,
      reqBody: {
        module: 'Assessee-Role',
        action: 'Review',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          filter: 'true',
          searchCondition: 'AND',
          search: [
            {
              condition: 'and',
              searchBy: [
                {
                  dataType: 'string',
                  conditionColumn: 'id',
                  conditionValue: {
                    condition: 'eq',
                    value: {
                      from: selectedTagValue
                    }
                  }
                }
              ]
            }
          ]
        }
      }
    }
  });
};
export const assesseeReviewInformation = (
  selectedAssociateInfo,
  dispatch,
  secondaryOptionCheckValue,
  isReviseMode,
  typeOfMiddlePaneList,
  selectedTagValue,
  createMode,
  popupHeaderOne
) => {
  dispatch({
    type: GET_ASSESSEE_INFO_SAGA,
    payload: {
      secondaryOptionCheckValue,
      isReviseMode,
      createMode,
      headerOne:
        typeOfMiddlePaneList === 'administratorsDistinctReviewList'
          ? 'administrator'
          : typeOfMiddlePaneList === 'managersDistinctReviewList'
            ? 'manager'
            : typeOfMiddlePaneList === 'assistantsDistinctReviewList'
              ? 'assistant'
              : createMode === 'assessee'
                ? popupHeaderOne
                : 'assessee',
      reqBody: {
        module: 'Assessee-Distinct',
        action: 'Review',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          filter: 'true',
          searchCondition: 'AND',
          search: [
            {
              condition: 'and',
              searchBy: [
                {
                  dataType: 'string',
                  conditionColumn: 'id',
                  conditionValue: {
                    condition: 'eq',
                    value: {
                      from: selectedTagValue
                    }
                  }
                }
              ]
            }
          ]
        }
      }
    }
  });
};
export const setPermissionToDefault = (roleObj) => {
  if (roleObj.assesseeRolePermission.assesseeAssesseeDistinctPermission.create) {
    roleObj.assesseeRolePermission.assesseeAssesseeGroupPermission.review = true;
    roleObj.assesseeRolePermission.assesseeAssesseeRolePermission.review = true;
    roleObj.assesseeRolePermission.assesseeAssesseeTypePermission.review = true;
  }
  if (roleObj.assesseeRolePermission.assesseeAssessmentDistinctPermission.create) {
    roleObj.assesseeRolePermission.assesseeAssessmentGroupPermission.review = true;
    roleObj.assesseeRolePermission.assesseeAssessmentTypePermission.review = true;
    roleObj.assesseeRolePermission.assesseeAssociateNodePermission.review = true;
  }
  if (roleObj.assesseeRolePermission.assesseeAssignmentDistinctPermission.create) {
    roleObj.assesseeRolePermission.assesseeAssignmentGroupPermission.review = true;
    roleObj.assesseeRolePermission.assesseeAssignmentTypePermission.review = true;
    roleObj.assesseeRolePermission.assesseeAssociateNodePermission.review = true;
  }
  if (roleObj.assesseeRolePermission.assesseeAssociateDistinctPermission.create) {
    roleObj.assesseeRolePermission.assesseeAssociateGroupPermission.review = true;
    roleObj.assesseeRolePermission.assesseeAssociateRolePermission.review = true;
    roleObj.assesseeRolePermission.assesseeAssociateTypePermission.review = true;
    roleObj.assesseeRolePermission.assesseeAssociateNodePermission.review = true;
  }
  if (roleObj.assesseeRolePermission.assesseeiGuruAnalyticDistinctPermission.create) {
    roleObj.assesseeRolePermission.assesseeiGuruAnalyticGroupPermission.review = true;
    roleObj.assesseeRolePermission.assesseeiGuruAnalyticTypePermission.review = true;
    roleObj.assesseeRolePermission.assesseeAssociateNodePermission.review = true;
  }
  if (roleObj.assesseeRolePermission.assesseeItemDistinctPermission.create) {
    roleObj.assesseeRolePermission.assesseeItemGroupPermission.review = true;
    roleObj.assesseeRolePermission.assesseeItemTypePermission.review = true;
    roleObj.assesseeRolePermission.assesseeAssociateNodePermission.review = true;
  }
  return roleObj;
};

export const updateAssesseeFlagUpdate = (
  selectedAssociateInfo,
  Id,
  dispatch,
  reviseStatus,
  assesseeType,
  flagVal
) => {
  let reqBody = {
    module: 'Assessee-Distinct',
    action: 'Revise',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      [assesseeType]: {
        id: Id,
        informationBasic: {
          [flagVal]: reviseStatus
        }
      }
    }
  };
  dispatch({ type: LOADER_START });
  dispatch({
    //type: ASSESSEE_TYPE_INFO_REVISE_SAGA,
    type:
      assesseeType === 'assessee'
        ? ASSESSEE_INFO_REVISE_SAGA
        : assesseeType === 'assesseeGroup'
          ? ASSESSEE_GROUP_INFO_REVISE_SAGA
          : assesseeType === 'assesseeRole'
            ? ASSESSEE_ROLE_INFO_REVISE_SAGA
            : assesseeType === 'assesseeType'
              ? ASSESSEE_TYPE_INFO_REVISE_SAGA
              : '',
    payload: {
      secondaryOptionCheckValue: '',
      headerOne: '',
      reqBody,
      assesseeRoleAssesseeReqBody: null,
      assesseeTypeAssesseeReqBody: null,
      isMiddlePaneList: true
    }
  });
};
// export const assesseeAssignmentReviewListApi = (
//   selectedAssociateInfo,
//   selectedTagValue,
//   dispatch,
//   selectedAssesseeId,
//   selectedAssesseeInfo,
//   secondaryOptionCheckValue,
//   scanString
// ) => {
//   let reqBody = makeAssesseeAssignmentRequestObj(selectedTagValue, selectedAssociateInfo);
//   if(scanString!==''){
//     reqBody=getAssesseeRelatedAssigmentReqObj(selectedAssociateInfo,selectedTagValue,secondaryOptionCheckValue,-1,scanString)
//   }
//   dispatch({
//     type: SET_DISPLAY_TWO_SINGLE_STATE,
//     payload: { stateName: 'typeOfMiddlePaneList', value: '' }
//   });
//   dispatch({ type: SET_RELATED_REQUEST_OBJECT, payload: reqBody });
//   dispatch({ type: SET_PAGE_COUNT, payload: 0 });
//   dispatch({ type: LOADER_START });
//   dispatch({
//     type: FILTERMODE,
//     payload: { FilterMode: 'assesseeAssignment' + secondaryOptionCheckValue }
//   });

//   dispatch({
//     type: GET_ASSESSEE_ASSIGNMENT_SAGA,
//     payload: {
//       request: reqBody,
//       BadgeOne: 'distinct',
//       BadgeTwo: secondaryOptionCheckValue,
//       BadgeThree: '',
//       relatedListCall: 'assesseeDistinct',
//       responseObj: {
//         assesseeId: selectedAssesseeId,
//         assesseeInfo: selectedAssesseeInfo
//       }
//     }
//   });
// };
export const assesseeAssignmentReviewListApi = (
  selectedAssociateInfo,
  selectedTagValue,
  dispatch,
  selectedAssesseeId,
  selectedAssesseeInfo,
  secondaryOptionCheckValue,
  scanString,
  typeOfMiddlePaneList,
  isSelectActive,
  selectedTagsArray,
  assignmentCreateType = '' 
) => {
  console.log('selectedAssesseeInfo----- ', selectedAssesseeInfo);
  let reqBody = makeAssesseeAssignmentRequestObj(
    selectedTagValue,
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    assignmentCreateType
  );
  if (scanString !== '') {
    reqBody = getAssesseeRelatedAssigmentReqObj(
      selectedAssociateInfo,
      selectedTagValue,
      secondaryOptionCheckValue,
      -1,
      scanString,
      '',
      assignmentCreateType
    );
  }
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'typeOfMiddlePaneList', value: '' }
  });
  dispatch({ type: SET_RELATED_REQUEST_OBJECT, payload: reqBody });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({ type: LOADER_START });
  if (typeOfMiddlePaneList === 'assesseeGroupAssesseeAssignmentDistinct') {
    dispatch({
      type: FILTERMODE,
      payload: {
        FilterMode: 'assesseeGroupAssesseeAssignment' + secondaryOptionCheckValue
      } // to check filtermode for shift kay functionality
    });
  } else {
    dispatch({
      type: FILTERMODE,
      payload: { FilterMode: 'assesseeAssignment' + secondaryOptionCheckValue }
    });
  }
  dispatch({
    type: GET_ASSESSEE_ASSIGNMENT_SAGA,
    payload: {
      request: reqBody,
      BadgeOne: 'distinct',
      BadgeTwo: secondaryOptionCheckValue,
      typeOfMiddlePaneList,
      BadgeThree: '',
      isSelectActive: isSelectActive,
      selectedTagsArray: selectedTagsArray,
      relatedListCall: 'assesseeDistinct',
      responseObj: {
        assesseeId: selectedAssesseeId,
        assesseeInfo: selectedAssesseeInfo,
        ...selectedAssesseeInfo
      }
    }
  });
};

export const assignmentAssesseeResetApiCall = (
  selectedAssociateInfo,
  assesseeAssignmentId,
  dispatch,
  assignmentAssesseeId,
  typeOfMiddlePaneList,
  relatedReviewListObjectCopy
) => {
  let reqBody = {
    module: 'Assessee-Assignment',
    action: 'Reset',
    actionFilter: 'Assessee-Assignment',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      assesseeAssignmentId: assesseeAssignmentId,
      assignmentAssesseeId: assignmentAssesseeId
    }
  };
  dispatch({ type: LOADER_START });
  dispatch({
    type: RESET_ASSESSEE_ASSIGNMENT_SAGA,
    payload: {
      request: reqBody,
      typeOfMiddlePaneList: typeOfMiddlePaneList,
      relatedReviewListObjectCopy: relatedReviewListObjectCopy
    }
  });
};
export const assesseeAssignmentResetApiCall = (
  selectedAssociateInfo,
  assesseeAssignmentId,
  dispatch,
  assignmentAssesseeId,
  selectedAssesseeInfo,
  typeOfMiddlePaneList,
  secondaryOptionCheckValue
) => {
  let reqBody = {
    module: 'Assessee-Assignment',
    action: 'Reset',
    actionFilter: 'Assessee-Assignment',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      assesseeAssignmentId: assesseeAssignmentId,
      assignmentAssesseeId: assignmentAssesseeId
    }
  };
  dispatch({ type: LOADER_START });
  let requestBody = makeAssesseeAssignmentRequestObj(
    assignmentAssesseeId,
    selectedAssociateInfo,
    secondaryOptionCheckValue
  );
  dispatch({
    type: ASSESSEE_ASSIGNMENT_RESET_SAGA,
    payload: {
      request: reqBody,
      typeOfMiddlePaneList,
      BadgeTwo: secondaryOptionCheckValue,
      refreshListReq: {
        request: requestBody,
        relatedListCall: 'assesseeDistinct',
        responseObj: {
          assesseeId: assignmentAssesseeId,
          assesseeInfo: selectedAssesseeInfo
        }
      }
    }
  });
};

export const assesseeAssignmentAssessmentResetApiCall = (
  selectedAssociateInfo,
  assesseeAssignmentId,
  dispatch,
  assesseeAssignmentAssessmentId,
  assignmentAssesseeId,
  selectedAssesseeInfo
) => {
  let reqBody = {
    module: 'Assessee-Assignment',
    action: 'Reset',
    actionFilter: 'Assessee-Assignment-Assessment',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      assesseeAssignmentId: assesseeAssignmentId,
      assesseeAssignmentAssessmentId: assesseeAssignmentAssessmentId,
      assignmentAssesseeId: assignmentAssesseeId
    }
  };
  dispatch({ type: LOADER_START });
  let requestBody = makeAssesseeAssignmentRequestObj(assignmentAssesseeId, selectedAssociateInfo);
  dispatch({
    type: ASSESSEE_ASSIGNMENT_ASSESSMENT_RESET_SAGA,
    payload: {
      request: reqBody,
      refreshListReq: {
        request: requestBody,
        relatedListCall: 'assesseeDistinct',
        responseObj: {
          assesseeId: assignmentAssesseeId,
          assesseeInfo: selectedAssesseeInfo
        }
      }
    }
  });
};

export const assignemtAsseseeAssessmentResetApiCall = (
  selectedAssociateInfo,
  assesseeAssignmentId,
  dispatch,
  assesseeAssignmentAssessmentId,
  assignmentAssesseeId,
  selectedAssesseeInfo,
  middlePaneHeaderBadgeTwo
) => {
  let statusArr = [middlePaneHeaderBadgeTwo.toUpperCase()];
  let assesseeStatusArr = ['CONFIRMED'];
  if (middlePaneHeaderBadgeTwo === 'active')
    statusArr = ['ABORTED', 'FINISHED', 'STARTED', 'UNFINISHED', 'UNSTARTED', 'ARCHIVED'];
  // if (middlePaneHeaderBadgeTwo === 'active') assesseeStatusArr = ['CONFIRMED'];
  let reqBody = {
    module: 'Assessee-Assignment',
    action: 'Reset',
    actionFilter: 'Assessee-Assignment-Assessment',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      assesseeAssignmentId: assesseeAssignmentId,
      assesseeAssignmentAssessmentId: assesseeAssignmentAssessmentId,
      assignmentAssesseeId: assignmentAssesseeId
    }
  };
  dispatch({ type: LOADER_START });
  let requestBody = {
    module: 'Assignment-Distinct',
    action: 'Review-List',
    actionFilter: 'Assignment-Assessee',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      assignmentId: assesseeAssignmentId,
      assesseeAssignmentStatus: statusArr,
      assesseeStatus: assesseeStatusArr,
      countPage: 25,
      numberPage: 0,
      orderBy: {
        columnName: 'informationBasic.assesseeNameFirst,informationBasic.assesseeNameLast',
        order: 'asc'
      },
      filter: 'true',
      searchCondition: 'AND',
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assesseeStatus',
              conditionValue: {
                condition: 'in',
                value: {
                  in: assesseeStatusArr
                }
              }
            }
          ]
        }
      ]
    }
  };
  dispatch({
    type: ASSIGNEMT_ASSESEES_ASSESSMENT_RESET_SAGA,
    payload: {
      request: reqBody,
      refreshListReq: {
        request: requestBody,
        relatedListCall: 'assesseeDistinct',
        responseObj: {
          assesseeId: assignmentAssesseeId,
          assesseeInfo: selectedAssesseeInfo
        }
      }
    }
  });
};

export const assesseeTypeReviseApicall = (
  responseObject,
  selectedAssociateInfo,
  selectedTagsArray,
  unselectedTagsArray,
  assesseeType,
  dispatch,
  createMode,
  isReviseCallFromPaneTwo = false,
  headerOne = 'assessees'
) => {
  const { associateId, id } = responseObject;
  console.log(assesseeType);
  const reqBody = {
    module: 'Assessee-Type',
    action: 'Revise',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId,
      assesseeTypeAssessee: {
        assesseeTypeAssesseeAllocate: selectedTagsArray || [],
        assesseeTypeAssesseeUnallocate: unselectedTagsArray || []
      },
      assesseeType: {
        id,
        informationBasic: assesseeType.informationBasic,
        informationAllocation: assesseeType.informationAllocation,
        informationSetup: assesseeType.informationSetup
      }
    }
  };
  dispatch({ type: LOADER_START });
  let assesseeTypeAssesseeReqBody = getAssesseeTypeAssesseeReqObj(
    selectedAssociateInfo,
    id,
    '',
    -1,
    -1
  );
  dispatch({
    type: ASSESSEE_TYPE_INFO_REVISE_SAGA,
    payload: {
      HeaderOne: headerOne,
      reqBody,
      assesseeTypeAssesseeReqBody,
      createMode,
      isReviseCallFromPaneTwo: isReviseCallFromPaneTwo
    }
  });
};

export const assesseeGropuReviseApicall = (
  responseObject,
  selectedAssociateInfo,
  selectedTagsArray,
  unselectedTagsArray,
  assesseeGroup,
  dispatch,
  createMode,
  isReviseCallFromPaneTwo = false,
  headerOne = 'assessees',
  cardValue = 'noCard'
) => {
  const { associateId, id } = responseObject;
  const reqBody = {
    module: 'Assessee-Group',
    action: 'Revise',
    // action: 'revise',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      assesseeGroupAssessee: {
        assesseeGroupAssesseeAllocate: selectedTagsArray || [],
        assesseeGroupAssesseeUnallocate: unselectedTagsArray || []
      },
      assesseeGroup: {
        id,
        informationBasic: assesseeGroup.informationBasic,
        informationSetup: assesseeGroup.informationSetup,
        informationAllocation: assesseeGroup.informationAllocation
      }
    }
  };
  dispatch({ type: LOADER_START });
  let assesseeGroupAssesseeReqBody = getAssesseeGroupAssesseeReqObj(
    selectedAssociateInfo,
    id,
    '', //'all',
    -1,
    -1
  );
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'relatedReviewListDistinctData', value: [] }
  });
  dispatch({
    type: ASSESSEE_GROUP_INFO_REVISE_SAGA,
    payload: {
      headerOne: headerOne,
      assesseeGroupAssesseeReqBody,
      reqBody,
      createMode,
      cardValue,
      isReviseCallFromPaneTwo: isReviseCallFromPaneTwo
    }
  });
};
export const assesseeRollReviseApiCall = (
  responseObject,
  selectedAssociateInfo,
  selectedTagsArray,
  unselectedTagsArray,
  assesseeRole,
  dispatch,
  createMode,
  isReviseCallFromPaneTwo = false,
  isAllocateAssessee = false,
  headerOne = 'assessees'
) => {
  console.log('ASSESSEES ROLE REVISE');
  const { id } = responseObject;
  let reviseSetupObj = setPermissionToDefault(assesseeRole.informationSetup);
  const reqBody = {
    module: 'Assessee-Role',
    action: 'Revise',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      isAllocateAssessee,
      assesseeRoleAssessee: {
        assesseeRoleAssesseeAllocate: selectedTagsArray || [],
        assesseeRoleAssesseeUnallocate: unselectedTagsArray || []
      },
      assesseeRole: {
        id,
        informationBasic: assesseeRole.informationBasic,
        informationSetup: reviseSetupObj,
        informationEngagement: assesseeRole?.informationEngagement
      }
    }
  };
  dispatch({ type: LOADER_START });
  let assesseeRoleAssesseeReqBody = getAssesseeRoleAssesseeReqObj(
    selectedAssociateInfo,
    id,
    '',
    -1,
    -1
  );
  dispatch({
    type: ASSESSEE_ROLE_INFO_REVISE_SAGA,
    payload: {
      headerOne: headerOne,
      reqBody,
      assesseeRoleAssesseeReqBody,
      createMode,
      isReviseCallFromPaneTwo: isReviseCallFromPaneTwo
    }
  });
};
export const assesseeNodeReviseApiCall = (
  headerOne,
  responseObject,
  selectedAssociateInfo,
  nodeInformation,
  associateNodeAssessee,
  dispatch,
  createMode,
  isReviseCallFromPaneTwo = false,
  middlePaneHeader
) => {
  console.log('IN NODE REVISE');
  const { associateId, id } = responseObject;
  let reqBody = {
    assesseeId: selectedAssociateInfo?.assesseeId,
    associateId,
    associateNode: {
      id,
      informationBasic: nodeInformation.informationBasic,
      // informationFramework: {
      //   associateNodeAscendant: {
      //     associateNodeAscendantPrimary:
      //       nodeInformation.informationFramework.associateNodeAscendant
      //         .associateNodeAscendantPrimary.id
      //   }
      // }
      informationFramework: nodeInformation.informationFramework,
      informationSetup: nodeInformation.informationSetup
    }
  };
  let associateNodeReqBody = null;
  let isShowAllModule = false;
  let sagaCall = '';
  if (headerOne === 'assessees' || middlePaneHeader === 'assessees') {
    reqBody = {
      ...reqBody,
      associateNodeAssessee: {
        associateNodeAssesseeAllocate: associateNodeAssessee?.associateNodeAssesseeAllocate || [],
        associateNodeAssesseeUnallocate:
          associateNodeAssessee?.associateNodeAssesseeUnallocate || []
      }
    };
    associateNodeReqBody = getAssesseeNodeAssesseeReqObj(selectedAssociateInfo, id, '', -1, -1);
    isShowAllModule = false;
    sagaCall = GET_ASSESSEENODE_ASSESSEE_REVIEW_LIST;
  }
  if (
    middlePaneHeader === 'administrators' ||
    middlePaneHeader === 'managers' ||
    middlePaneHeader === 'assistants' ||
    headerOne === 'administrator' ||
    headerOne === 'manager' ||
    headerOne === 'assistant'
  ) {
    reqBody = {
      ...reqBody,
      associateNodeAssessee: {
        associateNodeAssesseeAllocate: associateNodeAssessee?.associateNodeAssesseeAllocate || [],
        associateNodeAssesseeUnallocate:
          associateNodeAssessee?.associateNodeAssesseeUnallocate || []
      }
    };
    associateNodeReqBody = getAssesseeNodeAssesseeReqObj(selectedAssociateInfo, id, '', -1, -1);
    isShowAllModule = false;
    sagaCall = GET_ASSESSEENODE_ASSESSEE_REVIEW_LIST;
  }
  if (headerOne === 'assessments' || middlePaneHeader === 'assessments') {
    reqBody = {
      ...reqBody,
      associateNodeAssessment: {
        associateNodeAssessmentAllocate: associateNodeAssessee?.associateNodeAssesseeAllocate || [],
        associateNodeAssessmentUnallocate:
          associateNodeAssessee?.associateNodeAssesseeUnallocate || []
      }
    };
    associateNodeReqBody = {
      module: 'Assessment-Distinct',
      action: 'Review-List',
      actionFilter: 'Associate-Node-Assessment',
      requestObject: getNodeAssessmentsReqObj(selectedAssociateInfo, id, '', -1, -1)
    };
    isShowAllModule = false;
    sagaCall = GET_NODE_ASSESSMENTS_REVIEW_LIST_SAGA;
  }
  if (headerOne === 'assignments' || middlePaneHeader === 'assignments') {
    reqBody = {
      ...reqBody,
      associateNodeAssignment: {
        associateNodeAssignmentAllocate: associateNodeAssessee?.associateNodeAssesseeAllocate || [],
        associateNodeAssignmentUnallocate:
          associateNodeAssessee?.associateNodeAssesseeUnallocate || []
      }
    };
    associateNodeReqBody = {
      module: 'Assignment-Distinct',
      action: 'Review-List',
      actionFilter: 'Associate-Node-Assignment',
      requestObject: getNodeAssignmentsReqObj(selectedAssociateInfo, id, '', -1, -1)
    };
    isShowAllModule = false;
    sagaCall = GET_NODE_ASSIGNMENTS_REVIEW_LIST_SAGA;
  }
  if (headerOne === 'associate') {
    isShowAllModule = true;
  }
  if (headerOne === 'associates' || middlePaneHeader === 'associates') {
    reqBody = {
      ...reqBody,
      associateNodeAssociate: {
        associateNodeAssociateAllocate: associateNodeAssessee?.associateNodeAssesseeAllocate || [],
        associateNodeAssociateUnallocate:
          associateNodeAssessee?.associateNodeAssesseeUnallocate || []
      }
    };
    associateNodeReqBody = getNodeAssociatesReqObj(selectedAssociateInfo, id, '', -1, -1);
    isShowAllModule = false;
    sagaCall = GET_NODE_ASSOCIATE_REVIEW_LIST;
  }
  if (headerOne === 'culture-profiles' || middlePaneHeader === 'culture-profiles') {
    reqBody = {
      ...reqBody,
      associateNodeCultureProfile: {
        associateNodeCultureProfileAllocate:
          associateNodeAssessee?.associateNodeAssesseeAllocate || [],
        associateNodeCultureProfileUnallocate:
          associateNodeAssessee?.associateNodeAssesseeUnallocate || []
      }
    };
    associateNodeReqBody = getNodeCultureProfileReqObj(selectedAssociateInfo, id, '', -1, -1);
    isShowAllModule = false;
    sagaCall = GET_CULTURE_NODE_CULTURE_REVIEW_LIST_SAGA;
  }
  if (headerOne === 'job-profiles' || middlePaneHeader === 'job-profiles') {
    reqBody = {
      ...reqBody,
      associateNodeJobProfile: {
        associateNodeJobProfileAllocate: associateNodeAssessee?.associateNodeAssesseeAllocate || [],
        associateNodeJobProfileUnallocate:
          associateNodeAssessee?.associateNodeAssesseeUnallocate || []
      }
    };
    associateNodeReqBody = getNodeJobProfileReqObj(selectedAssociateInfo, id, '', -1, -1);
    isShowAllModule = false;
    sagaCall = GET_JOB_NODE_JOB_REVIEW_LIST_SAGA;
  }
  if (headerOne === 'items' || (middlePaneHeader === 'items' && headerOne === 'associate')) {
    debugger
    reqBody = {
      ...reqBody,
      associateNodeItem: {
        associateNodeItemAllocate: associateNodeAssessee?.associateNodeAssesseeAllocate || [],
        associateNodeItemUnallocate: associateNodeAssessee?.associateNodeAssesseeUnallocate || []
      }
    };
    associateNodeReqBody = getNodeItemsReqObj(selectedAssociateInfo, id, '', -1, -1);
    isShowAllModule = false;
    sagaCall = GET_NODE_ITEMS_REVIEW_LIST_SAGA;
  }
  debugger
  dispatch({ type: LOADER_START });
  dispatch({
    type: ASSOCIATE_NODE_INFO_REVISE_SAGA,
    payload: {
      selectedModule: headerOne,
      getReviewListSaga: sagaCall,
      isShowAllModule,
      middlePaneHeader,
      reqBody: {
        module: 'Associate-Node',
        action: 'Revise',
        requestObject: reqBody
      },
      associateNodeReqBody,
      createMode,
      secondaryOptionCheckValue: 'revise',
      isReviseCallFromPaneTwo: isReviseCallFromPaneTwo
    }
  });
};
export const getAssesseeeDistinctToAllocate = (
  dispatch,
  revisedRelatedObject,
  requestObect,
  existingAssesseeId,
  filterMode,
  typeOfMiddlePaneList,
  selectedTagsArray = [],
  unselectedTagsArray = [],
  headerOne,
  sagaCall
) => {
  console.debug({ sagaCall })
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: filterMode }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'relatedReviewListDistinctData', value: [] }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  //set requestObject
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'relatedReviewListObjectCopy',
      value: requestObect
    }
  });
  // Get list for pagination
  dispatch({
    type: sagaCall || GET_ALLOCATE_ASSESSEE,
    payload: {
      request: requestObect,
      revisedGroupObject: revisedRelatedObject,
      existingAssesseeId: [...selectedTagsArray, ...existingAssesseeId],
      typeOfMiddlePaneList: typeOfMiddlePaneList,
      unselectedTagsArray: unselectedTagsArray,
      headerOne: headerOne
    }
  });
};

export const getAssesseeGroupCreateAPI = (
  selectedAssociateInfo,
  dispatch,
  moduleName,
  assignmentCreateType
) => {
  let requestObj = makeAssesseeGroupObj(
    selectedAssociateInfo,
    'active',
    0,
    -1,
    'list',
    assignmentCreateType
  );
  dispatch({ type: SET_CORE_GROUP_REVIEW_LIST_REQ_OBJECT, payload: requestObj });
  dispatch({
    type: GET_ASSESSEE_GROUP_REVIEW_LIST_SAGA,
    payload: {
      request: requestObj,
      BadgeOne: '',
      BadgeTwo: '',
      BadgeThree: '',
      isMiddlePaneList: false
    }
  });
  let nodeRequestObj = makeInternalNodeObj(
    selectedAssociateInfo,
    'active',
    0,
    -1,
    '',
    'Associate-Self-Node-List-Create'
  );
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT, payload: nodeRequestObj });
  dispatch({
    type: INTERNAL_NODE_LIST_WITH_PERMISSIONS_SAGA,
    payload: {
      request: nodeRequestObj,
      BadgeOne: '',
      BadgeTwo: '',
      BadgeThree: '',
      nodeViewState: 'list',
      isMiddlePaneList: false
    }
  });
  let typeRequestObj = makeAssesseeTypeObj(selectedAssociateInfo, 'all', 0, -1);
  dispatch({
    type: SET_CORE_TYPE_REVIEW_LIST_REQ_OBJECT,
    payload: typeRequestObj
  });
  dispatch({
    type: GET_ASSESSEE_TYPE_REVIEW_LIST_SAGA,
    payload: {
      middlePaneHeader: '',
      request: typeRequestObj,
      BadgeOne: '',
      BadgeTwo: '',
      BadgeThree: '',
      isMiddlePaneList: false
    }
  });
  dispatch({ type: CLEAR_GROUP_REDUCER_STATE });
  dispatch({
    type: SET_POPUP_VALUE,
    payload: { isPopUpValue: 'NAMEPOPUP', popupMode: moduleName + 'GROUPCREATE' }
  });
};

export const checkAssesseeSignOnEmail = (email, associateId, isSignOn, dispatch) => {
  dispatch({
    type: ASSESSEE_SIGN_ON_EMAILCHECK_SAGA,
    payload: {
      module: 'Assessee-Distinct',
      action: 'existingEmail',
      signOn: isSignOn,
      requestObject: {
        associateId: associateId,
        assesseeEmailId: email
      }
    }
  });
  dispatch({
    type: SET_POPUP_VALUE,
    payload: {
      isPopUpValue: 'EMAILPOPUP',
      popupMode: ``
    }
  });
};

export const getAssesseeMarketplaceDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  isSelectActive = '',
  assignmentCreateType
) => {
  let requestObject = makeAssesseeMarketplaceDistinctReviewListRequestObject(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    0,
    countPage,
    assignmentCreateType
  );
  dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  secondaryOptionCheckValue =
    secondaryOptionCheckValue === 'activeinactive' ? 'all' : secondaryOptionCheckValue;
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: 'assesseeDistinct' + secondaryOptionCheckValue }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObject });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: ASSESSEE_MARKETPLACE_REVIEW_DISTINCT_SAGA,
    payload: {
      HeaderOne: 'assessees',
      request: requestObject,
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      isSelectActive: isSelectActive
    }
  });
};

export const getAssesseeMarketplaceGroupApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  cardValue = 'noCard',
  isSelectActive = '',
  groupViewState,
  selectedTagsArray = '',
  assignmentCreateType,
  middlePaneHeader
) => {
  if (secondaryOptionCheckValue !== 'active') {
    groupViewState = 'list';
  }
  let requestObj = makeAssesseeMarketplaceGroupReviewListRequestObject(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    0,
    countPage,
    assignmentCreateType,
    groupViewState,
  );

  dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({
    type: FILTERMODE,
    payload: {
      FilterMode: 'assesseesGroupDistinct' + secondaryOptionCheckValue
    }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'nodeViewState', value: groupViewState }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: ASSESSEE_MARKETPLACE_REVIEW_GROUP_SAGA,
    payload: {
      request: requestObj,
      HeaderOne: middlePaneHeader,
      BadgeOne: targetValue,
      BadgeTwo: cardValue === 'Card' ? 'distinct' : secondaryOptionCheckValue,
      BadgeThree: cardValue === 'Card' ? secondaryOptionCheckValue : '',
      isMiddlePaneList: true,
      cardValue: cardValue,
      isSelectActive: isSelectActive,
      groupViewState: groupViewState,
      selectedTagsArray: selectedTagsArray
    }
  });
};

export const getAssesseeMarketplaceGroupApiReqObject = (
  selectedAssociateInfo,
  nodeId,
  filterKey,
  numberPage,
  countPage
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['CONFIRMED', 'DISAPPROVED', 'SUSPENDED', 'TERMINATED', 'UNAPPROVED', 'UNCONFIRMED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: [
          'CONFIRMED',
          'DISAPPROVED',
          'SUSPENDED',
          'TERMINATED',
          'UNAPPROVED',
          'UNCONFIRMED',
          'ARCHIVED'
        ]
      }
    };
  }
  return {
    module: 'Assessee-Distinct',
    action: 'Review-List',
    actionFilter: 'Associate-Node-Shared-Assessee',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      nodeId: nodeId,
      filter: 'true',
      searchCondition: 'AND',
      orderBy: {
        columnName:
          'informationBasic.assesseeNameFirst,informationBasic.assesseeNameOther,informationBasic.assesseeNameLast,informationBasic.assesseeAlias',
        order: 'asc'
      },
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.assesseeNode.assesseeNodePrimary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: nodeId
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.assesseeNode.assesseeNodeSecondary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: nodeId
                }
              }
            }
          ]
        },
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assesseeStatus',
              conditionValue: searchObj
            }
          ]
        }
      ]
    }
  };
};

export const getAssesseeMarketplaceNodeApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  selectedTagValue,
  searchStr,
  isScan,
  middlePaneHeader = 'assessees',
  isMiddlePaneList = true
) => {
  let reqBody = makeAssesseeMarketplaceNodeReviewListRequestObject(
    selectedAssociateInfo,
    selectedTagValue,
    secondaryOptionCheckValue,
    0,
    countPage,
    searchStr
  );
  if (isScan) {
    reqBody = makeAssesseeMarketplaceNodeReviewListRequestObject(
      selectedAssociateInfo,
      selectedTagValue,
      secondaryOptionCheckValue,
      0,
      countPage,
      searchStr
    );
  }
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'relatedReviewListObjectCopy',
      value: reqBody
    }
  });
  // dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  if (isMiddlePaneList) {
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'relatedReviewListDistinctData', value: [] }
    });
  }
  dispatch({ type: LOADER_START });
  // dispatch({ type: SET_REQUEST_OBJECT, payload: reqBody });
  dispatch({
    type: ASSESSEE_MARKETPLACE_REVIEW_NODE_SAGA,
    payload: {
      request: reqBody,
      HeaderOne: middlePaneHeader,
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      BadgeThree: '',
      isMiddlePaneList: isMiddlePaneList
    }
  });
};

export const getAssesseeMarketplaceRoleApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  selectedTagValue,
  searchStr,
  isScan,
  middlePaneHeader
) => {
  let reqBody = makeAssesseeMarketplaceRoleReviewListRequestObject(
    selectedAssociateInfo,
    selectedTagValue,
    secondaryOptionCheckValue,
    0,
    countPage
  );
  if (isScan) {
    reqBody = makeAssesseeMarketplaceRoleReviewListRequestObject(
      selectedAssociateInfo,
      selectedTagValue,
      secondaryOptionCheckValue,
      0,
      countPage,
      searchStr
    );
  }

  // dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'relatedReviewListDistinctData', value: [] }
  });
  dispatch({ type: LOADER_START });
  // dispatch({ type: SET_REQUEST_OBJECT, payload: reqBody });

  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'relatedReviewListObjectCopy',
      value: reqBody
    }
  });
  dispatch({
    type: ASSESSEE_MARKETPLACE_REVIEW_ROLE_SAGA,
    payload: {
      request: reqBody,
      HeaderOne: middlePaneHeader,
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      BadgeThree: '',
      isMiddlePaneList: true
    }
  });
};

export const getAssesseeMarketplaceTypeApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  selectedTagValue,
  searchStr,
  isScan,
  middlePaneHeader = 'assessees'
) => {
  let reqBody = makeAssesseeMarketplaceTypeReviewListRequestObject(
    selectedAssociateInfo,
    selectedTagValue,
    secondaryOptionCheckValue,
    0,
    countPage
  );
  if (isScan) {
    reqBody = makeAssesseeMarketplaceTypeReviewListRequestObject(
      selectedAssociateInfo,
      selectedTagValue,
      secondaryOptionCheckValue,
      0,
      countPage,
      searchStr
    );
  }
  // Object copy to send data to pagination
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'relatedReviewListObjectCopy',
      value: reqBody
    }
  });

  // dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'relatedReviewListDistinctData', value: [] }
  });
  dispatch({ type: LOADER_START });
  // dispatch({ type: SET_REQUEST_OBJECT, payload: reqBody });
  dispatch({
    type: ASSESSEE_MARKETPLACE_REVIEW_TYPE_SAGA,
    payload: {
      request: reqBody,
      HeaderOne: middlePaneHeader,
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      BadgeThree: '',
      isMiddlePaneList: true
    }
  });
};
// export const getAssesseeMarketplaceRoleDistinctApiCall = (
//   selectedAssociateInfo,
//   secondaryOptionCheckValue,
//   countPage,
//   targetValue,
//   dispatch,
//   cardValue = 'noCard',
//   isSelectActive = ''
// ) => {
//   let requestObj = makeAssesseeRoleObj(
//     selectedAssociateInfo,
//     secondaryOptionCheckValue,
//     0,
//     countPage
//   );
//   dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
//   dispatch({
//     type: FILTERMODE,
//     payload: { FilterMode: 'assesseeRoleDistinct' + secondaryOptionCheckValue }
//   });
//   dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
//   dispatch({ type: LOADER_START });
//   dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
//   dispatch({ type: SET_PAGE_COUNT, payload: 0 });
//   dispatch({
//     type: GET_ASSESSEE_ROLE_REVIEW_LIST_SAGA,
//     payload: {
//       request: requestObj,
//       BadgeOne: targetValue,
//       BadgeTwo: cardValue === 'Card' ? 'distinct' : secondaryOptionCheckValue,
//       BadgeThree: cardValue === 'Card' ? secondaryOptionCheckValue : '',
//       isMiddlePaneList: true,
//       isSelectActive: isSelectActive
//     }
//   });
// };

