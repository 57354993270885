import {
  ASSOCIATE_REVIEW_DISTINCT_SAGA,
  ASSOCIATE_SIGN_ON,
  CLEAR_ASSESSEE_INFO,
  CLEAR_ASSOCIATE_INFO,
  CLEAR_DISPLAY_PANE_THREE,
  FILTERMODE,
  GET_ASSOCIATEGROUP_ASSOCIATE_REVIEW_LIST_SAGA,
  GET_ASSOCIATEROLE_ASSOCIATE_REVIEW_LIST_SAGA,
  GET_ASSOCIATETYPE_ASSOCIATE_REVIEW_LIST_SAGA,
  GET_ASSOCIATES_NODE_REVIEW_LIST_SAGA,
  GET_ASSOCIATE_GROUP_REVIEW_LIST_SAGA,
  GET_ASSOCIATE_ROLE_REVIEW_LIST_SAGA,
  GET_ASSOCIATE_TYPE_REVIEW_LIST_SAGA,
  INTERNAL_NODE_LIST_SAGA,
  LOADER_START,
  SET_CORE_GROUP_REVIEW_LIST_REQ_OBJECT,
  SET_CORE_ROLE_REVIEW_LIST_REQ_OBJECT,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_PAGE_COUNT,
  SET_POPUP_SINGLE_STATE,
  SET_REQUEST_OBJECT,
  SET_SINGLE_ASSOCIATE_INFORMATION,
  GET_NODE_ASSOCIATE_REVIEW_LIST,
  ASSOCIATE_INFO_REVISE_SAGA,
  ASSOCIATE_GROUP_REVISE_INFO_SAGA,
  ASSOCIATE_TYPE_INFO_REVISE_SAGA,
  ASSOCIATE_ROLE_REVISE_INFO_SAGA,
  ASSOCIATE_NODE_INFO_REVISE_SAGA,
  ASSOCIATE_SETUP_SAGA,
  ASSOCIATE_ANALYTIC_SETUP_SAGA,
  ASSOCIATE_ITEM_SETUP_SAGA,
  ASSOCIATE_ASSESSMENT_SETUP_SAGA,
  ASSOCIATE_ASSESSESS_SETUP_SAGA,
  ASSOCIATE_ASSIGNMENT_SETUP_SAGA,
  ASSOCIATE_NODE_SETUP_SAGA,
  SET_CORE_TYPE_REVIEW_LIST_REQ_OBJECT,
  SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT,
  GET_ALLOCATE_ASSOCIATE,
  CLEAR_NODE_REDUCER_STATE,
  SET_POPUP_VALUE,
  CLEAR_GROUP_REDUCER_STATE,
  ASSOCIATE_ASSESSESS_SIGNON_SETUP_SAGA,
  ASSOCIATE_SIGN_ON_ASSIGNMENT_LIST_SAGA,
  INTERNAL_NODE_LIST_WITH_PERMISSIONS_SAGA,
  ASSOCIATE_MARKETPLACE_REVIEW_DISTINCT_SAGA,
ASSOCIATE_MARKETPLACE_REVIEW_GROUP_SAGA,
ASSOCIATE_MARKETPLACE_REVIEW_NODE_SAGA,
ASSOCIATE_MARKETPLACE_REVIEW_ROLE_SAGA,
ASSOCIATE_MARKETPLACE_REVIEW_TYPE_SAGA,
ASSESSEE_SELF_REPORTS,
SET_MIDDLEPANE_STATE,
LOADER_STOP
} from '../actionType';
import {
  ASSIGNMENT_MODULE_URL,
  ASSESSMENT_MODULE_URL,
  ITEM_MODULE_URL,
  JOB_MODULE_URL,
  ASSOCIATE_MODULE_URL,
  ASSESSEE_MODULE_URL
} from '../endpoints';
import {
  getAssociateTypeAssociateReqObj,
  getAssociateTypeAssociateScanReqObj,
  getNodeAssociatesReqObj,
  makeAssociateGroupObj,
  makeAssociateNodeObj,
  makeAssociateReviewListRequestObject,
  makeAssociateRoleObj,
  makeAssociateTypeObj,
  makeInternalNodeObj,
  getNodeAssociatesScanReqObj,
  makeInternalAllocateNodeObj,
  makeAssociateAllocateGroupObj,
  makeAssociateRoleAllocateObj,
  makeAssociateAllocateTypeObj,
  getAllCountryList,
  makeMarketplaceAssociateDistinctReviewListRequestObject,
makeMarketplaceAssociateGroupReviewListRequestObject,
makeMarketplaceAssociateNodeReviewListRequestObject,
makeMarketplaceAssociateRoleReviewListRequestObject,
makeMarketplaceAssociateTypeReviewListRequestObject,  
} from './GenericActions';
const resetDataFunction = (dispatch, cardValue) => {
  dispatch({
    type: SET_POPUP_SINGLE_STATE,
    payload: { stateName: 'cardValue', value: cardValue }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'typeOfMiddlePaneList', value: '' }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'middlePaneSelectedValue', value: '' }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'selectedFlagedArray', value: [] }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'unselectedFlagedArray', value: [] }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'selectedTagsArray', value: [] }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'unselectedTagsArray', value: [] }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'flagedValue', value: '' }
  });
  dispatch({
    type: SET_POPUP_SINGLE_STATE,
    payload: { stateName: 'selectionValue', value: '' }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'scanString', value: '' }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'allocatedTagsArray', value: [] }
  });
  dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
};
export const getAssociateGroupAssociateReqObj = (
  selectedAssociateInfo,
  groupId,
  filterKey,
  numberPage,
  countPage
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['CONFIRMED', 'DISAPPROVED', 'SUSPENDED', 'TERMINATED', 'UNAPPROVED', 'UNCONFIRMED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: [
          'CONFIRMED',
          'DISAPPROVED',
          'SUSPENDED',
          'TERMINATED',
          'UNAPPROVED',
          'UNCONFIRMED',
          'ARCHIVED'
        ]
      }
    };
  }
  return {
    module: 'Associate-Group',
    action: 'Review-List',
    actionFilter: 'Associate-Group-Associate',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      groupId: groupId,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.associateName,informationBasic.associateDescription',
        order: 'asc'
      },
      searchCondition: 'AND',
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.associateGroup.associateGroupPrimary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: groupId
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.associateGroup.associateGroupSecondary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: groupId
                }
              }
            }
          ]
        },
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.associateStatus',
              conditionValue: searchObj
            }
          ]
        }
      ]
    }
  };
};
export const getAssociateGroupAssociateScanReqObj = (
  selectedAssociateInfo,
  groupId,
  filterKey,
  numberPage,
  countPage,
  searchStr
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['CONFIRMED', 'DISAPPROVED', 'SUSPENDED', 'TERMINATED', 'UNAPPROVED', 'UNCONFIRMED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: [
          'CONFIRMED',
          'DISAPPROVED',
          'SUSPENDED',
          'TERMINATED',
          'UNAPPROVED',
          'UNCONFIRMED',
          'ARCHIVED'
        ]
      }
    };
  }
  return {
    module: 'Associate-Group',
    action: 'Review-List',
    actionFilter: 'Associate-Group-Associate',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      groupId: groupId,
      orderBy: {
        columnName: 'informationBasic.associateName,informationBasic.associateDescription',
        order: 'asc'
      },
      filter: 'true',
      searchCondition: 'AND',
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.associateName',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.associateDescription',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn:
                'informationContact.associateAddressWebsitePrimary.associateAddressWebsite.',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn:
                'informationContact.associateAddressWebsiteSecondary.associateAddressWebsite.',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.associateTag.associateTagPrimary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.associateTag.associateTagSecondary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            }
          ]
        },
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.associateStatus',
              conditionValue: searchObj
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.associateGroup.associateGroupPrimary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: groupId
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.associateGroup.associateGroupSecondary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: groupId
                }
              }
            }
          ]
        }
      ]
    }
  };
};

export const getAssociateRoleAssociateReqObj = (
  selectedAssociateInfo,
  roleId,
  filterKey,
  numberPage,
  countPage
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['CONFIRMED', 'DISAPPROVED', 'SUSPENDED', 'TERMINATED', 'UNAPPROVED', 'UNCONFIRMED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: [
          'CONFIRMED',
          'DISAPPROVED',
          'SUSPENDED',
          'TERMINATED',
          'UNAPPROVED',
          'UNCONFIRMED',
          'ARCHIVED'
        ]
      }
    };
  }
  return {
    module: 'Associate-Role',
    action: 'Review-List',
    actionFilter: 'Associate-Role-Associate',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      roleId: roleId,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.associateName,informationBasic.associateDescription',
        order: 'asc'
      },
      searchCondition: 'AND',
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.associateRole.associateRolePrimary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: roleId
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.associateRole.associateRoleSecondary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: roleId
                }
              }
            }
          ]
        },
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.associateStatus',
              conditionValue: searchObj
            }
          ]
        }
      ]
    }
  };
};
export const getAssociateRoleAssociateScanReqObj = (
  selectedAssociateInfo,
  roleId,
  filterKey,
  numberPage,
  countPage,
  searchStr
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: [
          'CONFIRMED',
          'DISAPPROVED',
          'SUSPENDED',
          'TERMINATED',
          'UNAPPROVED',
          'UNCONFIRMED',
          'ARCHIVED'
        ]
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: [
          'CONFIRMED',
          'DISAPPROVED',
          'SUSPENDED',
          'TERMINATED',
          'UNAPPROVED',
          'UNCONFIRMED',
          'ARCHIVED'
        ]
      }
    };
  }
  return {
    module: 'Associate-Role',
    action: 'Review-List',
    actionFilter: 'Associate-Role-Associate',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      roleId: roleId,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.associateName,informationBasic.associateDescription',
        order: 'asc'
      },
      searchCondition: 'AND',
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.associateName',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.associateDescription',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.associateTag.associateTagPrimary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn:
                'informationContact.associateAddressWebsitePrimary.associateAddressWebsite',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn:
                'informationContact.associateAddressWebsiteSecondary.associateAddressWebsite',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            }
          ]
        },
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.associateStatus',
              conditionValue: searchObj
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.associateRole.associateRolePrimary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: roleId
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.associateRole.associateRoleSecondary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: roleId
                }
              }
            }
          ]
        }
      ]
    }
  };
};

export const getAssociateGroupAssociateDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  selectedTagValue,
  searchStr,
  isScan
) => {
  let reqBody = getAssociateGroupAssociateReqObj(
    selectedAssociateInfo,
    selectedTagValue,
    secondaryOptionCheckValue,
    0,
    countPage
  );
  if (isScan) {
    reqBody = getAssociateGroupAssociateScanReqObj(
      selectedAssociateInfo,
      selectedTagValue,
      secondaryOptionCheckValue,
      0,
      countPage,
      searchStr
    );
  }
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'relatedReviewListObjectCopy',
      value: reqBody
    }
  });

  // dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'relatedReviewListDistinctData', value: [] }
  });
  dispatch({ type: LOADER_START });
  // dispatch({ type: SET_REQUEST_OBJECT, payload: reqBody });
  dispatch({
    type: GET_ASSOCIATEGROUP_ASSOCIATE_REVIEW_LIST_SAGA,
    payload: {
      request: reqBody,
      HeaderOne: 'assessees',
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      BadgeThree: '',
      isMiddlePaneList: true
    }
  });
};
export const getAssociateRoleAssociateDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  selectedTagValue,
  searchStr,
  isScan
) => {
  let reqBody = getAssociateRoleAssociateReqObj(
    selectedAssociateInfo,
    selectedTagValue,
    secondaryOptionCheckValue,
    0,
    countPage
  );
  if (isScan) {
    reqBody = getAssociateRoleAssociateScanReqObj(
      selectedAssociateInfo,
      selectedTagValue,
      secondaryOptionCheckValue,
      0,
      countPage,
      searchStr
    );
  }
  // dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'relatedReviewListDistinctData', value: [] }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'relatedReviewListObjectCopy',
      value: reqBody
    }
  });
  dispatch({ type: LOADER_START });
  // dispatch({ type: SET_REQUEST_OBJECT, payload: reqBody });
  dispatch({
    type: GET_ASSOCIATEROLE_ASSOCIATE_REVIEW_LIST_SAGA,
    payload: {
      request: reqBody,
      HeaderOne: 'assessees',
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      BadgeThree: '',
      isMiddlePaneList: true
    }
  });
};

export const getAssociateTypeAssociateDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  selectedTagValue,
  searchStr,
  isScan
) => {
  let reqBody = getAssociateTypeAssociateReqObj(
    selectedAssociateInfo,
    selectedTagValue,
    secondaryOptionCheckValue,
    0,
    countPage
  );
  if (isScan) {
    reqBody = getAssociateTypeAssociateScanReqObj(
      selectedAssociateInfo,
      selectedTagValue,
      secondaryOptionCheckValue,
      0,
      countPage,
      searchStr
    );
  }
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'relatedReviewListObjectCopy',
      value: reqBody
    }
  });

  // dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'relatedReviewListDistinctData', value: [] }
  });
  dispatch({ type: LOADER_START });
  // dispatch({ type: SET_REQUEST_OBJECT, payload: reqBody });
  dispatch({
    type: GET_ASSOCIATETYPE_ASSOCIATE_REVIEW_LIST_SAGA,
    payload: {
      request: reqBody,
      HeaderOne: 'associates',
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      BadgeThree: '',
      isMiddlePaneList: true
    }
  });
};
export const getAssociateNodeApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  badgeValue,
  nodeViewState,
  scanString = '',
  isSelectActive = '',
  selectedTagsArray = ''
) => {
  let requestObj = makeAssociateNodeObj(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    0,
    countPage,
    nodeViewState
  );
  dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'nodeViewState', value: nodeViewState }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'middlePaneSelectedValue', value: '' }
  });
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: 'associatesNodeDistinct' }
  });

  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: GET_ASSOCIATES_NODE_REVIEW_LIST_SAGA,
    payload: {
      request: requestObj,
      BadgeOne: badgeValue,
      BadgeTwo: secondaryOptionCheckValue,
      BadgeThree: '',
      nodeViewState: nodeViewState,
      isMiddlePaneList: true,
      scanString,
      isSelectActive: isSelectActive,
      selectedTagsArray: selectedTagsArray
    }
  });
};
export const getInternalNodeApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  BadgeThree,
  nodeViewState,
  paneheader,
  isSelectActive = '',
  selectedTagsArray = '',
  scanValue = '',
  filterMode,
  cardValue,
) => {
  console.log('in call',cardValue);
  if (
    secondaryOptionCheckValue !== 'active' &&
    secondaryOptionCheckValue !== 'bespoke' &&
    secondaryOptionCheckValue !== 'generic' &&
    nodeViewState === 'hierarchy'
  ) {
    nodeViewState = 'list';
  }

  let requestObj = makeInternalNodeObj(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    0,
    countPage,
    nodeViewState,
    nodeViewState === 'hierarchy' ? 'Associate-Node-Hierarchy-List': 'Associate-Node-List'
  );

  // nodeViewState = secondaryOptionCheckValue === 'active' ? 'hierarchy' : 'list';
  dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'nodeViewState', value: nodeViewState }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'typeOfMiddlePaneList', value: '' }
  });
  if (filterMode) {
    dispatch({
      type: FILTERMODE,
      payload: { FilterMode: filterMode }
    });
  } else {
    dispatch({
      type: FILTERMODE,
      payload: { FilterMode: 'associateNodeDistinct' + secondaryOptionCheckValue }
    });
  }
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'scanString',
      value: ''
    }
  });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: INTERNAL_NODE_LIST_WITH_PERMISSIONS_SAGA,
    payload: {
      request: requestObj,
      paneHeader: (paneheader && paneheader) || 'associate',
      BadgeOne: targetValue,
      BadgeTwo: paneheader === 'associate' ? 'distinct' : secondaryOptionCheckValue, // self : module 
      BadgeThree: paneheader === 'associate' ? secondaryOptionCheckValue : BadgeThree, // self : module 
      nodeViewState: nodeViewState,
      isMiddlePaneList: true,
      isSelectActive: isSelectActive,
      selectedTagsArray: selectedTagsArray,
      cardValue: cardValue,
      scanValue
    }
  });
};

export const getInternalNodeWithPermissionsApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  middlePaneHeaderBadgeThree,
  nodeViewState,
  paneheader,
  isSelectActive = '',
  selectedTagsArray = '',
  scanValue = '',
  filterMode
) => {
  if (
    secondaryOptionCheckValue !== 'active' &&
    secondaryOptionCheckValue !== 'bespoke' &&
    secondaryOptionCheckValue !== 'generic' &&
    nodeViewState === 'hierarchy'
  ) {
    nodeViewState = 'list';
  }

  let requestObj = makeInternalNodeObj(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    0,
    countPage,
    nodeViewState,
    'Associate-Node-Hierarchy-List'
  );
  console.log(requestObj)

  // nodeViewState = secondaryOptionCheckValue === 'active' ? 'hierarchy' : 'list';
  dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'nodeViewState', value: nodeViewState }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'typeOfMiddlePaneList', value: '' }
  });
  if (filterMode) {
    dispatch({
      type: FILTERMODE,
      payload: { FilterMode: filterMode }
    });
  } else {
    dispatch({
      type: FILTERMODE,
      payload: { FilterMode: 'associateNodeDistinct' + secondaryOptionCheckValue }
    });
  }
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'scanString',
      value: ''
    }
  });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: INTERNAL_NODE_LIST_WITH_PERMISSIONS_SAGA,
    payload: {
      request: requestObj,
      paneHeader: (paneheader && paneheader) || 'associate',
      BadgeOne: targetValue,
      BadgeTwo: paneheader === 'associate' ? 'distinct' : secondaryOptionCheckValue,
      BadgeThree:
        paneheader === 'associate' ? secondaryOptionCheckValue : middlePaneHeaderBadgeThree,
      nodeViewState: nodeViewState,
      isMiddlePaneList: true,
      isSelectActive: isSelectActive,
      selectedTagsArray: selectedTagsArray,
      scanValue
    }
  });
};

export const getInternalNodeAllocateApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  middlePaneHeaderBadgeThree,
  nodeViewState,
  paneheader,
  isSelectActive = '',
  selectedTagsArray = [],
  scanValue = '',
  filterMode,
  allocatedTagsArray
) => {
  if (
    secondaryOptionCheckValue !== 'active' &&
    secondaryOptionCheckValue !== 'bespoke' &&
    secondaryOptionCheckValue !== 'generic' &&
    nodeViewState === 'hierarchy'
  ) {
    nodeViewState = 'list';
  }

  let requestObj = makeInternalAllocateNodeObj(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    0,
    countPage,
    nodeViewState,
    allocatedTagsArray
  );

  // nodeViewState = secondaryOptionCheckValue === 'active' ? 'hierarchy' : 'list';
  dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'nodeViewState', value: nodeViewState }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'typeOfMiddlePaneList', value: '' }
  });
  if (filterMode) {
    dispatch({
      type: FILTERMODE,
      payload: { FilterMode: filterMode }
    });
  } else {
    dispatch({
      type: FILTERMODE,
      payload: { FilterMode: 'associateNodeDistinct' + secondaryOptionCheckValue }
    });
  }
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'scanString',
      value: ''
    }
  });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: INTERNAL_NODE_LIST_SAGA,
    payload: {
      request: requestObj,
      paneHeader: (paneheader && paneheader) || 'associate',
      BadgeOne: targetValue,
      // BadgeTwo: 'distinct', // self paneheader === 'associate'
      // BadgeThree: secondaryOptionCheckValue, // self
      // BadgeTwo: secondaryOptionCheckValue, // module
      // BadgeThree: middlePaneHeaderBadgeThree, // module paneheader === 'associates'
      BadgeTwo: paneheader === 'associate' ? 'distinct' : secondaryOptionCheckValue,
      BadgeThree:
        paneheader === 'associate' ? secondaryOptionCheckValue : middlePaneHeaderBadgeThree,
      nodeViewState: nodeViewState,
      isMiddlePaneList: true,
      isSelectActive: isSelectActive,
      selectedTagsArray: selectedTagsArray,
      scanValue
    }
  });
};

export function sortingListInAsc(array, key) {
  return array.sort(function (a, b) {
    if (a.informationBasic[key] && b.informationBasic[key]) {
      var x = a.informationBasic[key].toLowerCase().replace(/[()]/g, '');
      var y = b.informationBasic[key].toLowerCase().replace(/[()]/g, '');
      return x < y ? -1 : x > y ? 1 : 0;
    } else {
      return array;
    }
  });
}

export const getAssociateDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  dispatch,
  countPage,
  targetValue,
  isSelectActive = '',
  cardValue = 'noCard'
) => {
  let requestObect = makeAssociateReviewListRequestObject(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    0,
    countPage
  );
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: 'associateDistinct' + secondaryOptionCheckValue }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'typeOfMiddlePaneList', value: '' }
  });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: ASSOCIATE_REVIEW_DISTINCT_SAGA,
    payload: {
      request: requestObect,
      BadgeOne: targetValue,
      BadgeTwo: cardValue === 'Card' ? 'all' : secondaryOptionCheckValue,
      isSelectActive: isSelectActive
    }
  });
};

export const getAssociateRoleDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  cardValue = 'noCard',
  targetValue,
  isSelectActive = ''
) => {
  let requestObj = makeAssociateRoleObj(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    0,
    countPage
  );
  dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: 'associateRoleDistinct' + secondaryOptionCheckValue }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'typeOfMiddlePaneList', value: '' }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'reviewListDistinctData', value: [] }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: GET_ASSOCIATE_ROLE_REVIEW_LIST_SAGA,
    payload: {
      request: requestObj,
      BadgeOne: targetValue,
      BadgeTwo: cardValue === 'Card' ? 'distinct' : secondaryOptionCheckValue,
      BadgeThree: cardValue === 'Card' ? secondaryOptionCheckValue : '',
      isMiddlePaneList: true,
      isSelectActive: isSelectActive
    }
  });
};
export const getAssociateRoleAllocateDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  cardValue = 'noCard',
  targetValue,
  isSelectActive = '',
  allocatedTagsArray
) => {
  let requestObj = makeAssociateRoleAllocateObj(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    0,
    countPage,
    allocatedTagsArray
  );
  dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: 'associateRoleDistinct' + secondaryOptionCheckValue }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'typeOfMiddlePaneList', value: '' }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'reviewListDistinctData', value: [] }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: GET_ASSOCIATE_ROLE_REVIEW_LIST_SAGA,
    payload: {
      request: requestObj,
      BadgeOne: targetValue,
      BadgeTwo: cardValue === 'Card' ? 'distinct' : secondaryOptionCheckValue,
      BadgeThree: cardValue === 'Card' ? secondaryOptionCheckValue : '',
      isMiddlePaneList: true,
      isSelectActive: isSelectActive
    }
  });
};

export const getAssociateGroupDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  cardValue = 'noCard',
  isSelectActive = '',
  groupViewState,
  selectedTagsArray = '',
  assignmentCreateType
) => {
  if (secondaryOptionCheckValue !== 'active') {
    groupViewState = 'list';
  }
  let requestObj = makeAssociateGroupObj(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    countPage,
    0,
    groupViewState,
    assignmentCreateType
  );
  dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: 'associateGroupDistinct' + secondaryOptionCheckValue }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'nodeViewState', value: groupViewState }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: GET_ASSOCIATE_GROUP_REVIEW_LIST_SAGA,
    payload: {
      request: requestObj,
      BadgeOne: targetValue,
      BadgeTwo: cardValue === 'Card' ? 'distinct' : secondaryOptionCheckValue,
      BadgeThree: cardValue === 'Card' ? secondaryOptionCheckValue : '',
      isMiddlePaneList: true,
      isSelectActive: isSelectActive,
      groupViewState,
      cardValue: cardValue,
      selectedTagsArray: selectedTagsArray
    }
  });
};

export const getAssociateGroupAllocateDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  cardValue = 'noCard',
  isSelectActive = '',
  groupViewState,
  selectedTagsArray = '',
  assignmentCreateType,
  allocatedTagsArray
) => {
  if (secondaryOptionCheckValue !== 'active' || isSelectActive === 'multiple') {
    groupViewState = 'list';
  }
  let requestObj = makeAssociateAllocateGroupObj(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    countPage,
    0,
    groupViewState,
    assignmentCreateType,
    allocatedTagsArray
  );
  dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: 'associateGroupDistinct' + secondaryOptionCheckValue }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'nodeViewState', value: groupViewState }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: GET_ASSOCIATE_GROUP_REVIEW_LIST_SAGA,
    payload: {
      request: requestObj,
      BadgeOne: targetValue,
      BadgeTwo: cardValue === 'Card' ? 'distinct' : secondaryOptionCheckValue,
      BadgeThree: cardValue === 'Card' ? secondaryOptionCheckValue : '',
      isMiddlePaneList: true,
      isSelectActive: isSelectActive,
      groupViewState,
      cardValue: cardValue,
      selectedTagsArray: selectedTagsArray
    }
  });
};

export const associateCreatePopup = (
  selectedAssociateInfo,
  dispatch,
  secondaryOptionCheckValue,
  targetValue,
  parentAssociateId,
  assignmentCreateType
) => {
  dispatch({ type: CLEAR_ASSOCIATE_INFO });
  dispatch({ type: CLEAR_ASSESSEE_INFO });
  dispatch({ type: LOADER_START });
  dispatch({
    type: SET_POPUP_SINGLE_STATE,
    payload: { stateName: 'cardValue', value: 'Create' }
  });
  let requestObj = makeAssociateGroupObj(selectedAssociateInfo, 'active', 0, -1, 'list', assignmentCreateType);
  dispatch({ type: SET_CORE_GROUP_REVIEW_LIST_REQ_OBJECT, payload: requestObj });
  dispatch({
    type: GET_ASSOCIATE_GROUP_REVIEW_LIST_SAGA,
    payload: {
      request: requestObj,
      BadgeOne: '',
      BadgeTwo: '',
      BadgeThree: '',
      isMiddlePaneList: false
    }
  });
  let typerequestObj = makeAssociateTypeObj(selectedAssociateInfo, 'active', 0, -1);
  dispatch({
    type: GET_ASSOCIATE_TYPE_REVIEW_LIST_SAGA,
    payload: {
      request: typerequestObj,
      BadgeOne: '',
      BadgeTwo: '',
      BadgeThree: '',
      isMiddlePaneList: false
    }
  });
  let nodeRequestObj = makeInternalNodeObj(selectedAssociateInfo, 'active', 0, -1, '', 'Associate-Self-Node-List-Create');
  dispatch({ type: SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT, payload: nodeRequestObj });
  dispatch({
    type: INTERNAL_NODE_LIST_WITH_PERMISSIONS_SAGA,
    payload: {
      request: nodeRequestObj,
      BadgeOne: '',
      BadgeTwo: '',
      BadgeThree: '',
      nodeViewState: 'list',
      isMiddlePaneList: false
    }
  });
  dispatch({ type: SET_CORE_TYPE_REVIEW_LIST_REQ_OBJECT, payload: typerequestObj });
  dispatch({ type: SET_CORE_GROUP_REVIEW_LIST_REQ_OBJECT, payload: requestObj });
  let roleRequestObj = makeAssociateRoleObj(selectedAssociateInfo, 'active', -1, 0);
  dispatch({ type: SET_CORE_ROLE_REVIEW_LIST_REQ_OBJECT, payload: roleRequestObj });
  dispatch({
    type: SET_SINGLE_ASSOCIATE_INFORMATION,
    payload: {
      stateName: 'parentAssociateId',
      value: parentAssociateId
    }
  });
  dispatch({
    type: ASSOCIATE_SIGN_ON,
    payload: { isPopUpValue: 'NAMEALIASPOPUP', popupMode: 'ASSOCIATE_CREATE' }
  });
  dispatch({
    type: GET_ASSOCIATE_ROLE_REVIEW_LIST_SAGA,
    payload: {
      request: roleRequestObj,
      BadgeOne: '',
      BadgeTwo: '',
      BadgeThree: '',
      isMiddlePaneList: false
    }
  });
};
export const getAssociatesTypeApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  middlePaneHeader = 'associates',
  cardValue = 'noCard',
  isSelectActive = ''
) => {
  let requestObj = makeAssociateTypeObj(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    countPage,
    0
  );

  dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: 'associatesTypeDistinct' + secondaryOptionCheckValue }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: GET_ASSOCIATE_TYPE_REVIEW_LIST_SAGA,
    payload: {
      middlePaneHeader: middlePaneHeader,
      request: requestObj,
      BadgeOne: targetValue,
      BadgeTwo: cardValue === 'Card' ? 'distinct' : secondaryOptionCheckValue,
      BadgeThree: cardValue === 'Card' ? secondaryOptionCheckValue : '',
      isMiddlePaneList: true,
      isSelectActive: isSelectActive
    }
  });
  resetDataFunction(dispatch, cardValue);
};

export const getAssociatesAllocateTypeApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  middlePaneHeader = 'associates',
  cardValue = 'noCard',
  isSelectActive = '',
  allocatedTagsArray
) => {
  let requestObj = makeAssociateAllocateTypeObj(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    countPage,
    0,
    allocatedTagsArray
  );

  dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: 'associatesTypeDistinct' + secondaryOptionCheckValue }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: GET_ASSOCIATE_TYPE_REVIEW_LIST_SAGA,
    payload: {
      middlePaneHeader: middlePaneHeader,
      request: requestObj,
      BadgeOne: targetValue,
      BadgeTwo: cardValue === 'Card' ? 'distinct' : secondaryOptionCheckValue,
      BadgeThree: cardValue === 'Card' ? secondaryOptionCheckValue : '',
      isMiddlePaneList: true,
      isSelectActive: isSelectActive
    }
  });
  resetDataFunction(dispatch, cardValue);
};

export const getNodeRelatedAssociateDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  selectedTagValue,
  searchStr,
  isScan,
  middlePaneHeader,
  isMiddlePaneList = true
) => {
  let reqBody = getNodeAssociatesReqObj(
    selectedAssociateInfo,
    selectedTagValue,
    secondaryOptionCheckValue,
    0,
    countPage
  );
  if (isScan) {
    reqBody = getNodeAssociatesScanReqObj(
      selectedAssociateInfo,
      selectedTagValue,
      secondaryOptionCheckValue,
      0,
      countPage,
      searchStr
    );
  }
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'relatedReviewListObjectCopy',
      value: reqBody
    }
  });
  // dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  if (isMiddlePaneList) {
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'relatedReviewListDistinctData', value: [] }
    });
  }
  dispatch({ type: LOADER_START });
  // dispatch({ type: SET_REQUEST_OBJECT, payload: reqBody });
  dispatch({
    type: GET_NODE_ASSOCIATE_REVIEW_LIST,
    payload: {
      request: reqBody,
      HeaderOne: middlePaneHeader,
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      BadgeThree: '',
      isMiddlePaneList: isMiddlePaneList
    }
  });
};

export const updateAssociateDistinctStatus = (
  selectedAssociateInfo,
  associatereviseId,
  dispatch,
  reviseStatus,
  createMode,
  typeOfMiddlePaneList
) => {
  let reqBody;
  if (
    reviseStatus === 'UNAPPROVED' ||
    reviseStatus === 'APPROVED' ||
    reviseStatus === 'DISAPPROVED'
  ) {
    reqBody = {
      module: 'Associate-Distinct',
      action: 'Approval',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        associateApprovalList: associatereviseId,
        approval: reviseStatus === 'APPROVED'
      }
    };
  } else {
    // reqBody = {
    //   module: 'Associate-Distinct',
    //   action: 'Revise',
    //   requestObject: {
    //     assesseeId: selectedAssociateInfo?.assesseeId,
    //     associateId:
    //       selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
    //     associate: {
    //       id: associatereviseId,
    //       informationEngagement: {
    //         associateStatus: reviseStatus
    //       }
    //     }
    //   }
    // };
    reqBody = {
      module: 'Associate-Distinct',
      action: 'Revise',
      actionFilter: 'Status',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        associateDistinct: associatereviseId,
        reviseStatus: reviseStatus
      }
    };
  }
  // dispatch({
  //   type: SET_DISPLAY_TWO_SINGLE_STATE,
  //   payload: {
  //     stateName: 'typeOfMiddlePaneList',
  //     value: ''
  //   }
  // });
  dispatch({ type: LOADER_START });
  dispatch({
    type: ASSOCIATE_INFO_REVISE_SAGA,
    payload: {
      secondaryOptionCheckValue: '',
      headerOne: '',
      reqBody,
      createMode,
      typeOfMiddlePaneList
    }
  });
};

export const updateAssociateGroupStatus = (
  selectedAssociateInfo,
  groupId,
  dispatch,
  reviseStatus
) => {
  let reqBody = {
    module: 'Associate-Group',
    action: 'Revise',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      associateGroup: {
        id: groupId,
        informationEngagement: {
          associateGroupStatus:
            reviseStatus === 'UNSUSPENDED' ||
              reviseStatus === 'UNTERMINATED' ||
              reviseStatus === 'UNARCHIVED'
              ? 'ACTIVE'
              : reviseStatus
        }
      }
    }
  };
  dispatch({ type: LOADER_START });
  dispatch({
    type: ASSOCIATE_GROUP_REVISE_INFO_SAGA,
    payload: {
      secondaryOptionCheckValue: '',
      associateGroupAssociateReqBody: null,
      headerOne: '',
      reqBody
    }
  });
};
export const updateAssociateTypeStatus = (
  selectedAssociateInfo,
  typeId,
  dispatch,
  reviseStatus
) => {
  let reqBody = {
    module: 'Associate-Type',
    action: 'Revise',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      associateType: {
        id: typeId,
        informationEngagement: {
          associateTypeStatus:
            reviseStatus === 'UNSUSPENDED' ||
              reviseStatus === 'UNTERMINATED' ||
              reviseStatus === 'UNARCHIVED'
              ? 'ACTIVE'
              : reviseStatus
        }
      }
    }
  };
  dispatch({ type: LOADER_START });
  dispatch({
    type: ASSOCIATE_TYPE_INFO_REVISE_SAGA,
    payload: {
      secondaryOptionCheckValue: '',
      associateTypeAssociateReqBody: null,
      headerOne: '',
      reqBody
    }
  });
};
export const updateAssociateNodeStatus = (
  selectedAssociateInfo,
  nodeId,
  dispatch,
  reviseStatus,
  createMode
) => {
  let reqBody = {
    module: 'Associate-Distinct',
    action: 'Revise',
    actionFilter: 'Status',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      associateNode: nodeId,
      reviseStatus: reviseStatus
    }
  };
  dispatch({
    type: SET_POPUP_SINGLE_STATE,
    payload: { stateName: 'selectionValue', value: '' }
  });
  // let reqBody = {
  //   module: 'Associate-Node',
  //   action: 'Revise',
  //   requestObject: {
  //     assesseeId: selectedAssociateInfo?.assesseeId,
  //     associateId:
  //       selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
  //     associateNode: {
  //       id: nodeId,
  //       informationEngagement: {
  //         associateNodeStatus:
  //           reviseStatus === 'UNSUSPENDED' ||
  //             reviseStatus === 'UNTERMINATED' ||
  //             reviseStatus === 'UNARCHIVED'
  //             ? 'ACTIVE'
  //             : reviseStatus
  //       }
  //     }
  //   }
  // };
  dispatch({ type: LOADER_START });
  dispatch({
    type: ASSOCIATE_NODE_INFO_REVISE_SAGA,
    payload: {
      secondaryOptionCheckValue: '',
      associateNodeReqBody: null,
      createMode,
      headerOne: '',
      reqBody
    }
  });
};

export const AssociateSetUpApiCalls = (selectedAssociateInfo, associateId, dispatch) => {
  let reqBody = {
    assesseeId: selectedAssociateInfo?.assesseeId,
    associateId:
      selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
    filter: 'true',
    searchCondition: 'AND',
    search: [
      {
        condition: 'and',
        searchBy: [
          {
            dataType: 'string',
            conditionColumn: 'associateId',
            conditionValue: {
              condition: 'eq',
              value: {
                from: associateId
              }
            }
          }
        ]
      }
    ]
  };
  dispatch({
    type: ASSOCIATE_NODE_SETUP_SAGA,
    payload: {
      reqBody: { module: 'Associate-Node-Permission', action: 'Review', requestObject: reqBody },
      reqUrl: ASSOCIATE_MODULE_URL
    }
  });
  // 'Assessee-Setup'
  dispatch({
    type: ASSOCIATE_ASSESSESS_SETUP_SAGA,
    payload: {
      reqBody: { module: 'Assessee-Setup', action: 'Review', requestObject: reqBody },
      reqUrl: ASSESSEE_MODULE_URL
    }
  });
  // 'Assessment-Setup'
  dispatch({
    type: ASSOCIATE_ASSESSMENT_SETUP_SAGA,
    payload: {
      reqBody: { module: 'Assessment-Setup', action: 'Review', requestObject: reqBody },
      reqUrl: ASSESSMENT_MODULE_URL
    }
  });
  // 'Assessment-Setup'
  dispatch({
    type: ASSOCIATE_ASSIGNMENT_SETUP_SAGA,
    payload: {
      reqBody: {
        module: 'Assignment-Setup',
        action: 'Review',
        requestObject: reqBody
      },
      reqUrl: ASSIGNMENT_MODULE_URL
    }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'relatedReviewListDistinctData', value: [] }
  });
  // 'Associate-Setup'
  dispatch({
    type: ASSOCIATE_SETUP_SAGA,
    payload: {
      reqBody: {
        module: 'Associate-Setup',
        action: 'Review',
        requestObject: reqBody
      },
      reqUrl: ASSOCIATE_MODULE_URL
    }
  });
  dispatch({
    type: ASSOCIATE_ANALYTIC_SETUP_SAGA,
    payload: {
      reqBody: { module: 'Job-Profile-Setup', action: 'Review', requestObject: reqBody },
      reqUrl: JOB_MODULE_URL,
      isIdToken: true
    }
  });
  dispatch({
    type: ASSOCIATE_ITEM_SETUP_SAGA,
    payload: {
      reqBody: { module: 'Item-Setup', action: 'Review', requestObject: reqBody },
      reqUrl: ITEM_MODULE_URL
    }
  });
  getAllCountryList(dispatch);
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'relatedReviewListDistinctData', value: [] }
  });
  dispatch({ type: CLEAR_ASSOCIATE_INFO });
};
export const AssociateSignOnSetUpApiCalls = (associateTagPrimary, dispatch) => {
  // 'Assessee-Setup'
  dispatch({
    type: ASSOCIATE_ASSESSESS_SIGNON_SETUP_SAGA,
    payload: {
      reqBodyAssociate: {
        module: 'Associate-Setup',
        action: 'SignOn-Setup',
        signOn: true,
        requestObject: {
          assesseeId: '0123456',
          associateId: associateTagPrimary,
          setupAssociateId: associateTagPrimary
        }
      },
      reqBodyAssessee: {
        module: 'Assessee-Setup',
        action: 'SignOn-Setup',
        signOn: true,
        requestObject: {
          assesseeId: '0123456',
          associateId: associateTagPrimary,
          setupAssociateId: associateTagPrimary
        }
      }
    }
  });
};

export const AssociateMineAssignmentList = (requestObj, dispatch) => {
  // dispatch({ type: LOADER_START })

  dispatch({
    type: ASSOCIATE_SIGN_ON_ASSIGNMENT_LIST_SAGA,
    payload: {
      "assignmentList": requestObj,
      "url": ASSIGNMENT_MODULE_URL
    }
  })

}

export const updateAssociateFlagUpdate = (
  selectedAssociateInfo,
  Id,
  dispatch,
  reviseStatus,
  associateType,
  flagVal,
  createMode
) => {
  let reqBody = {
    module:
      (associateType === 'associateDistinct' && 'Associate-Distinct') ||
      (associateType === 'associateGroup' && 'Associate-Group') ||
      (associateType === 'associateType' && 'Associate-Type') ||
      (associateType === 'associateNode' && 'Associate-Node') ||
      (associateType === 'associateRole' && 'Associate-Role'),
    action: 'Revise',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      [associateType]: {
        id: Id,
        informationBasic: {
          [flagVal]: reviseStatus
        }
      }
    }
  };
  dispatch({ type: LOADER_START });
  dispatch({
    type:
      associateType === 'associateDistinct'
        ? ASSOCIATE_INFO_REVISE_SAGA
        : associateType === 'associateGroup'
          ? ASSOCIATE_GROUP_REVISE_INFO_SAGA
          : associateType === 'associateRole'
            ? ASSOCIATE_ROLE_REVISE_INFO_SAGA
            : associateType === 'associateType'
              ? ASSOCIATE_TYPE_INFO_REVISE_SAGA
              : associateType === 'associateNode'
                ? ASSOCIATE_NODE_INFO_REVISE_SAGA
                : '',
    payload: {
      secondaryOptionCheckValue: '',
      headerOne: '',
      reqBody,
      associateNodeReqBody: null,
      associateGroupAssociateReqBody: null,
      associateRoleAssociateReqBody: null,
      associateTypeAssociateReqBody: null,
      createMode
    }
  });
};

export const associateGropuReviseApicall = (
  responseObject,
  selectedAssociateInfo,
  selectedTagsArray,
  unselectedTagsArray,
  associateGroup,
  dispatch,
  createMode,
  isReviseCallFromPaneTwo = false,
  cardValue = 'noCard'
) => {
  const { associateId, id } = responseObject;
  const reqBody = {
    module: 'Associate-Group',
    action: 'Revise',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId,
      associateGroupAssociate: {
        associateGroupAssociateAllocate: selectedTagsArray || [],
        associateGroupAssociateUnallocate: unselectedTagsArray || []
      },
      associateGroup: {
        id,
        informationBasic: associateGroup.informationBasic,
        informationSetup: associateGroup.informationSetup,
        informationAllocation: associateGroup.informationAllocation
      }
    }
  };
  dispatch({ type: LOADER_START });
  let associateGroupAssociateReqBody = getAssociateGroupAssociateReqObj(
    selectedAssociateInfo,
    id,
    '',
    -1,
    -1
  );
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'relatedReviewListDistinctData', value: [] }
  });
  dispatch({
    type: ASSOCIATE_GROUP_REVISE_INFO_SAGA,
    payload: {
      headerOne: 'associates',
      associateGroupAssociateReqBody,
      reqBody,
      createMode,
      cardValue,
      isReviseCallFromPaneTwo: isReviseCallFromPaneTwo
    }
  });
};

export const associateTypeReviseApicall = (
  responseObject,
  selectedAssociateInfo,
  selectedTagsArray,
  unselectedTagsArray,
  associateType,
  dispatch,
  createMode,
  isReviseCallFromPaneTwo = false
) => {
  const { associateId, id } = responseObject;
  console.log(associateType);
  const reqBody = {
    module: 'Associate-Type',
    action: 'Revise',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId,
      associateTypeAssociate: {
        associateTypeAssociateAllocate: selectedTagsArray || [],
        associateTypeAssociateUnallocate: unselectedTagsArray || []
      },
      associateType: {
        id,
        informationBasic: associateType.informationBasic,
        informationAllocation: associateType.informationAllocation,
        informationSetup: associateType.informationSetup
      }
    }
  };
  dispatch({ type: LOADER_START });
  let associateTypeAssociateReqBody = getAssociateTypeAssociateReqObj(
    selectedAssociateInfo,
    id,
    '',
    -1,
    -1
  );
  dispatch({
    type: ASSOCIATE_TYPE_INFO_REVISE_SAGA,
    payload: {
      headerOne: 'associates',
      reqBody,
      associateTypeAssociateReqBody,
      createMode,
      isReviseCallFromPaneTwo
    }
  });
};

export const associateRoleReviseApiCall = (
  responseObject,
  selectedAssociateInfo,
  selectedTagsArray,
  unselectedTagsArray,
  associateRole,
  dispatch,
  createMode,
  isReviseCallFromPaneTwo = false,
  isAllocateAssociate = false
) => {
  console.log('ASS0CIATE ROLE REVISE');
  const { associateId, id } = responseObject;
  const reqBody = {
    module: 'Associate-Role',
    action: 'Revise',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      isAllocateAssociate,
      associateRoleAssociate: {
        associateRoleAssociateAllocate: selectedTagsArray || [],
        associateRoleAssociateUnallocate: unselectedTagsArray || []
      },
      associateRole: {
        id,
        informationBasic: associateRole.informationBasic,
        informationSetup: associateRole.informationSetup
      }
    }
  };
  dispatch({ type: LOADER_START });
  let associateRoleAssociateReqBody = getAssociateRoleAssociateReqObj(
    selectedAssociateInfo,
    id,
    '',
    -1,
    -1
  );
  dispatch({
    type: ASSOCIATE_ROLE_REVISE_INFO_SAGA,
    payload: {
      headerOne: 'associates',
      reqBody,
      associateRoleAssociateReqBody,
      createMode,
      isReviseCallFromPaneTwo: isReviseCallFromPaneTwo
    }
  });
};
export const getAssociateDistinctToAllocate = (
  dispatch,
  requestObect,
  revisedGroupObject,
  existingAssesseeId,
  filterMode,
  typeOfMiddlePaneList,
  selectedTagsArray = []
) => {
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: filterMode }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'relatedReviewListDistinctData', value: [] }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  //set requestObject
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'relatedReviewListObjectCopy',
      value: requestObect
    }
  });
  // Get list for pagination
  dispatch({
    type: GET_ALLOCATE_ASSOCIATE,
    payload: {
      request: requestObect,
      BadgeTwo: '',
      headerOne: 'associates',
      revisedGroupObject: revisedGroupObject,
      existingAssesseeId: [...existingAssesseeId, ...selectedTagsArray],
      typeOfMiddlePaneList: typeOfMiddlePaneList
    }
  });
};

export const createAssociateNodeFunction = (dispatch, selectedAssociateInfo, countPage) => {
  let requestObj = makeInternalNodeObj(selectedAssociateInfo, 'active', 0, countPage, '', 'Associate-Self-Node-List-Create');
  // dispatch({ type: CLEAR_NODE_REDUCER_STATE });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT, payload: requestObj });
  dispatch({
    type: INTERNAL_NODE_LIST_WITH_PERMISSIONS_SAGA,
    payload: {
      request: requestObj,
      BadgeOne: '',
      BadgeTwo: '',
      BadgeThree: '',
      nodeViewState: 'list',
      isMiddlePaneList: false
    }
  });
  dispatch({
    type: SET_POPUP_VALUE,
    payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'NODECREATE' }
  });
};
export const getAssociateGroupCreateAPI = (selectedAssociateInfo, dispatch, moduleName, assignmentCreateType) => {
  let requestObj = makeAssociateGroupObj(selectedAssociateInfo, 'active', 0, -1, 'list', assignmentCreateType);
  dispatch({ type: SET_CORE_GROUP_REVIEW_LIST_REQ_OBJECT, payload: requestObj });
  dispatch({
    type: GET_ASSOCIATE_GROUP_REVIEW_LIST_SAGA,
    payload: {
      request: requestObj,
      BadgeOne: '',
      BadgeTwo: '',
      BadgeThree: '',
      isMiddlePaneList: false
    }
  });
  let nodeRequestObj = makeInternalNodeObj(selectedAssociateInfo, 'active', 0, -1, '', 'Associate-Self-Node-List-Create');
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT, payload: nodeRequestObj });
  dispatch({
    type: INTERNAL_NODE_LIST_WITH_PERMISSIONS_SAGA,
    payload: {
      request: nodeRequestObj,
      BadgeOne: '',
      BadgeTwo: '',
      BadgeThree: '',
      nodeViewState: 'list',
      isMiddlePaneList: false
    }
  });
  let typerequestObj = makeAssociateTypeObj(selectedAssociateInfo, 'active', 0, -1);
  dispatch({ type: SET_CORE_TYPE_REVIEW_LIST_REQ_OBJECT, payload: typerequestObj }); //type primary popup list
  dispatch({
    type: GET_ASSOCIATE_TYPE_REVIEW_LIST_SAGA,
    payload: {
      request: typerequestObj,
      BadgeOne: '',
      BadgeTwo: '',
      BadgeThree: '',
      isMiddlePaneList: false
    }
  });
  dispatch({ type: CLEAR_GROUP_REDUCER_STATE });
  dispatch({
    type: SET_POPUP_VALUE,
    payload: { isPopUpValue: 'NAMEPOPUP', popupMode: moduleName + 'GROUPCREATE' }
  });
};

export const getMarketplaceAssociateDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  dispatch,
  countPage,
  targetValue,
  isSelectActive = '',
  cardValue = 'noCard'
) => {
  let requestObect = makeAssociateReviewListRequestObject(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    0,
    countPage
  );
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: 'associateDistinct' + secondaryOptionCheckValue }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'typeOfMiddlePaneList', value: '' }
  });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: ASSOCIATE_MARKETPLACE_REVIEW_DISTINCT_SAGA,
    payload: {
      request: requestObect,
      BadgeOne: targetValue,
      BadgeTwo: cardValue === 'Card' ? 'all' : secondaryOptionCheckValue,
      isSelectActive: isSelectActive
    }
  });
}

export const getMarketplaceAssociateGroupApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  cardValue = 'NoCard',
  isSelectActive = '',
  assignmentCreateType
) => {
  let requestObect = makeMarketplaceAssociateGroupReviewListRequestObject(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    countPage,
    0,
    assignmentCreateType
  );
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: 'assessmentsDistinct' + secondaryOptionCheckValue }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'typeOfMiddlePaneList', value: '' }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: ASSOCIATE_MARKETPLACE_REVIEW_GROUP_SAGA,
    payload: {
      request: requestObect,
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      isSelectActive: isSelectActive
    }
  });
};

export const getMarketplaceAssociateNodeApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  cardValue = 'NoCard',
  isSelectActive = '',
  assignmentCreateType
) => {
  let requestObect = makeMarketplaceAssociateNodeReviewListRequestObject(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    countPage,
    0,
    assignmentCreateType
  );
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: 'assessmentsDistinct' + secondaryOptionCheckValue }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'typeOfMiddlePaneList', value: '' }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: ASSOCIATE_MARKETPLACE_REVIEW_NODE_SAGA,
    payload: {
      request: requestObect,
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      isSelectActive: isSelectActive
    }
  });
};

export const getMarketplaceAssociateRoleApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  cardValue = 'NoCard',
  isSelectActive = '',
  assignmentCreateType
) => {
  let requestObect = makeMarketplaceAssociateRoleReviewListRequestObject(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    countPage,
    0,
    assignmentCreateType
  );
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: 'assessmentsDistinct' + secondaryOptionCheckValue }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'typeOfMiddlePaneList', value: '' }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: ASSOCIATE_MARKETPLACE_REVIEW_ROLE_SAGA,
    payload: {
      request: requestObect,
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      isSelectActive: isSelectActive
    }
  });
};


export const getMarketplaceAssociateTypeApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  countPage,
  dispatch,
  targetValue,
  cardValue = 'NoCard',
  isSelectActive = '',
  assignmentCreateType
) => {
  let requestObect = makeMarketplaceAssociateTypeReviewListRequestObject(
    selectedAssociateInfo,
    secondaryOptionCheckValue,
    countPage,
    0,
    assignmentCreateType
  );
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: 'assessmentsDistinct' + secondaryOptionCheckValue }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'typeOfMiddlePaneList', value: '' }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: ASSOCIATE_MARKETPLACE_REVIEW_TYPE_SAGA,
    payload: {
      request: requestObect,
      BadgeOne: targetValue,
      BadgeTwo: secondaryOptionCheckValue,
      isSelectActive: isSelectActive
    }
  });
};


export const getAssesseeSelfCertificates = (selectedAssociateInfo, secondaryOptionCheckValue, countPage, dispatch) => {
  if (secondaryOptionCheckValue === 'inactive' || secondaryOptionCheckValue === 'archived') {
    dispatch({
      type: SET_MIDDLEPANE_STATE,
      payload: {
        middlePaneHeader: 'certificates',
        middlePaneHeaderBadgeOne: 'distinct',
        middlePaneHeaderBadgeTwo: secondaryOptionCheckValue,
        middlePaneHeaderBadgeThree: '',
        middlePaneHeaderBadgeFour: '',
        typeOfMiddlePaneList: 'assesseeSelfReportsReviewList',
        scanCount: 0,
        duplicateScanCount: 0,
        showMiddlePaneState: true,

      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE, payload: {
        stateName: 'reviewListDistinctData', value: []
      }
    })
    dispatch({
      type: FILTERMODE,
      payload: { FilterMode: 'assesseeReportsDistinct' + secondaryOptionCheckValue }
    });
    dispatch({ type: LOADER_STOP });
  } else {

    let reqBody = {
      "module": "Assessee-Assignment",
      "action": "Certificates-All",
      "requestObject": {
        "assesseeId": selectedAssociateInfo?.assesseeId,
        "associateId":
          selectedAssociateInfo?.associate?.informationEngagement.associateTag
            .associateTagPrimary,
        "assesseeAssignmentStatus": [
          "STARTED",
          "UNSTARTED",
          "ADMINISTERED"
        ],
        "countPage": countPage,
        "numberPage": 0,
        "filter": true,
        "orderBy": {
          "columnName": "assesseeAssignmentName",
          "order": "asc"
        }
      }
    }
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE, payload: {
        stateName: 'reviewListDistinctData', value: []
      }
    })
    dispatch({
      type: FILTERMODE,
      payload: { FilterMode: 'assesseeReportsDistinct' + secondaryOptionCheckValue }
    });
    dispatch({ type: LOADER_START })
    dispatch({
      type: ASSESSEE_SELF_REPORTS,
      payload: {
        typeOfMiddlePaneList: 'assesseeSelfReportsReviewList',
        middlePaneHeaderBadgeTwo: secondaryOptionCheckValue,
        middlePaneHeader: 'certificates',
        request: reqBody,
      }
    })
  }
}

export const getAssesseeSelfReports = (selectedAssociateInfo, secondaryOptionCheckValue, countPage, dispatch) => {
  if (secondaryOptionCheckValue === 'inactive' || secondaryOptionCheckValue === 'archived') {
    dispatch({
      type: SET_MIDDLEPANE_STATE,
      payload: {
        middlePaneHeader: 'reports',
        middlePaneHeaderBadgeOne: 'distinct',
        middlePaneHeaderBadgeTwo: secondaryOptionCheckValue,
        middlePaneHeaderBadgeThree: '',
        middlePaneHeaderBadgeFour: '',
        typeOfMiddlePaneList: 'assesseeSelfReportsReviewList',
        scanCount: 0,
        duplicateScanCount: 0,
        showMiddlePaneState: true,

      }
    });

    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE, payload: {
        stateName: 'reviewListDistinctData', value: []
      }
    })
    dispatch({
      type: FILTERMODE,
      payload: { FilterMode: 'assesseeReportsDistinct' + secondaryOptionCheckValue }
    });
    dispatch({ type: LOADER_STOP });

  } else {

    let reqBody = {
      "module": "Assessee-Assignment",
      "action": "Reports-All",
      "requestObject": {
        "assesseeId": selectedAssociateInfo?.assesseeId,
        "associateId":
          selectedAssociateInfo?.associate?.informationEngagement.associateTag
            .associateTagPrimary,
        "assesseeAssignmentStatus": [
          "STARTED",
          "UNSTARTED",
          "ADMINISTERED"
        ],
        "countPage": countPage,
        "numberPage": 0,
        "filter": true,
        "orderBy": {
          "columnName": "assesseeAssignmentName",
          "order": "asc"
        }
      }
    }
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE, payload: {
        stateName: 'reviewListDistinctData', value: []
      }
    })
    dispatch({
      type: FILTERMODE,
      payload: { FilterMode: 'assesseeReportsDistinct' + secondaryOptionCheckValue }
    });
    dispatch({ type: LOADER_START })
    dispatch({
      type: ASSESSEE_SELF_REPORTS,
      payload: {
        typeOfMiddlePaneList: 'assesseeSelfReportsReviewList',
        middlePaneHeaderBadgeTwo: secondaryOptionCheckValue,
        middlePaneHeader: 'reports',
        request: reqBody,
      }
    })
  }
}
