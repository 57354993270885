import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './DisplayPaneFive.css';
import PopupHeader from '../../Molecules/PopUp/PopUpHeader';
import EditorJs from 'react-editor-js';
import Paragraph from '@editorjs/paragraph';
import Popup from '../../Molecules/PopUp/PopUp';
import JsonRenderComponent from '../../Actions/JsonRenderComponent';
import { DialogContent, FormControl, Input } from '@material-ui/core';
import PopUpItemFramework from '../../PopUpInformation/PopUpItemFramework';
import ReactHTMLParser from 'react-html-parser';
import {
  SET_POPUP_VALUE,
  SET_DISPLAY_PANE_THREE_REVIEW_MODE,
  POPUP_CLOSE,
  SET_ASSESSMENT_SECTION_FRAMEWORK_DYNAMIC_SINGLE_STATE,
  LOADER_START,
  LOADER_STOP,
  SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE
} from '../../actionType';

import { Paper, IconButton } from '@material-ui/core';
import EditorTemplate from './EditorTemplate';
import { useTimer } from 'react-timer-hook';
import PopUpItemConfig from '../../PopUpInformation/PopUpItemConfig';
import PopUpItemScaleFramework from '../../PopUpInformation/PopUpItemScaleFramework';
import { getPopUpOptionList } from '../../Actions/GenericActions';
const SubSectionItemPreview = (props) => {
  const {
    ob,
    reviewReviseMode,
    dispatch,
    handleRadioButton,
    isAssessmentPreviewShow,
    isAssessmentSectionShow,
    keys,
    handleRadioLikertButton,
    clusterKey = '',
    clusterInfo = null,
    itemObect = '',
    currentQuestionChoice,
    responseAlignment = '',
    itemFrameworkOneType = '',
    assessmentScale = []
  } = props;
  let itemFrameworkOneResponseChoiceLength =
    itemObect[0]?.itemFrameworkOne?.itemFrameworkOneResponseChoice?.length;
  return (
    <div>
      {itemFrameworkOneType === '61090cace50cf61d5eb440c9' ? (
        <div>
          <div style={{ display: responseAlignment === 'horizontal' ? 'block' : 'none' }}>
            <div
              className={[
                'innerpadding-update',
                'innerheightAdjustOnly',
                'rating-scale-container'
              ].join(' ')}
            >
              <Fragment>
                <div
                  key={`sub-${keys}`}
                  style={{
                    gridColumn: `span ${
                      12 -
                      (itemFrameworkOneResponseChoiceLength <= 3
                        ? 6
                        : itemFrameworkOneResponseChoiceLength * 2)
                    } / span ${
                      12 -
                      (itemFrameworkOneResponseChoiceLength <= 3
                        ? 6
                        : itemFrameworkOneResponseChoiceLength * 2)
                    }`,
                    flex: '6'
                  }}
                >
                  <div className={['', 'ex_container'].join(' ')}>
                    <div
                      className={['item', 'assessmentScale'].join(' ')}
                      subquestionid={ob.itemFrameworkOneSectionSequence}
                      style={{
                        cursor: reviewReviseMode === 'revise' ? 'pointer' : ''
                      }}
                      onClick={
                        reviewReviseMode === 'revise'
                          ? () => {
                              dispatch({
                                type: SET_POPUP_VALUE,
                                payload: {
                                  isPopUpValue: 'ASSESSMENT_SUB_ITEM_PRIMARY_POPUP',
                                  popupMode: `LIKERT_ITEM_MEDIA_TEXT_${keys}`
                                }
                              });
                            }
                          : null
                      }
                    >
                      <EditorTemplate
                        label={'sub item'}
                        jsonData={ob.itemFrameworkOne?.itemFrameworkOneMedia}
                      />
                    </div>
                  </div>
                  {ob.itemFrameworkOne?.itemFrameworkOneExplanation
                    ?.itemFrameworkOneExplanationMedia && (
                    <div
                      className={['', 'ex_container'].join(' ')}
                      //className={['innerheightAdjust', 'ex_container'].join(' ')}
                    >
                      <div
                        className={['ig-explanation', 'item-explanation', 'ex_container-One'].join(
                          ' '
                        )}
                      >
                        {ob.itemFrameworkOne?.itemFrameworkOneExplanation
                          ?.itemFrameworkOneExplanationMedia && (
                          <div>
                            <div className={['ig-explanation', 'item-explanation'].join(' ')}>
                              <EditorTemplate
                                jsonData={
                                  ob.itemFrameworkOne?.itemFrameworkOneExplanation
                                    ?.itemFrameworkOneExplanationMedia
                                }
                                label={'itemFrameworkOneExplanationMedia'}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                {ob.itemFrameworkOne?.itemFrameworkOneResponseChoice.map((opt, key) => {
                  return (
                    <Fragment key={key}>
                      <div
                        style={{
                          //gridTemplateColumns: `repeat(${itemFrameworkOneResponseChoiceLength === 2 || itemFrameworkOneResponseChoiceLength === 4 || itemFrameworkOneResponseChoiceLength === 6 ? itemFrameworkOneResponseChoiceLength + 2 : itemFrameworkOneResponseChoiceLength + 1},minmax(0,1fr))`
                          gridColumn: `span ${2} / span ${2}`
                        }}
                      >
                        <div
                          id={`id`}
                          key={`op-${key}`}
                          style={{ cursor: 'pointer' }}
                          //className={'likert_choice-sclae'}
                          className={[
                            'likert_choice-sclae',
                            'ex_container-One-response-choice'
                          ].join(' ')}
                          //style={{ display: 'inline-table' }}
                        >
                          <input
                            type="radio"
                            data-key="444"
                            name={`option1-${clusterKey}${ob.itemFrameworkOneSectionSequence}`}
                            value={`${opt.itemFrameworkOneResponseChoiceNumber}`}
                            style={{
                              backgroundColor:
                                ((isAssessmentPreviewShow || isAssessmentSectionShow) &&
                                  clusterInfo?.assessmentClusterResponseChoiceCorrect?.length > 0 &&
                                  clusterInfo?.assessmentClusterResponseChoiceCorrect[keys] ===
                                    opt.itemFrameworkOneResponseChoiceNumber &&
                                  '#7dc832') ||
                                (opt.itemFrameworkOneResponseChoiceNumber ===
                                  ob?.itemFrameworkOne.itemFrameworkOneResponseCorrect &&
                                  '#7dc832'),
                              cursor: reviewReviseMode === 'revise' ? 'pointer' : ''
                            }}
                            onChange={
                              reviewReviseMode === 'revise' &&
                              clusterInfo &&
                              isAssessmentSectionShow
                                ? () => {
                                    handleRadioLikertButton(
                                      ob.itemFrameworkOneSectionSequence,
                                      clusterInfo?.id,
                                      opt.itemFrameworkOneResponseChoiceNumber
                                    );
                                  }
                                : (e) => {
                                    handleRadioButton(e);
                                  }
                            }
                            data-value={opt.itemFrameworkOneResponseChoiceNumber + 'text'}
                            checked={
                              (isAssessmentSectionShow &&
                                clusterInfo?.assessmentClusterResponseChoiceCorrect?.length > 0 &&
                                clusterInfo?.assessmentClusterResponseChoiceCorrect[keys] ===
                                  opt.itemFrameworkOneResponseChoiceNumber) ||
                              opt.itemFrameworkOneResponseChoiceNumber ===
                                ob?.itemFrameworkOne.itemFrameworkOneResponseCorrect
                            }
                          />
                          <div
                            className={'likert-choice-font'}
                            style={{
                              cursor: 'pointer'
                              // cursor: reviewReviseMode === 'revise' ? 'pointer' : ''
                            }}
                          >
                            <EditorTemplate
                              label={'subitemchoice'}
                              jsonData={opt.itemFrameworkOneResponseChoiceMedia}
                            />
                          </div>
                        </div>
                        {opt.itemFrameworkOneResponseChoiceExplanation
                          ?.itemFrameworkOneResponseChoiceExplanationMedia && (
                          <div className={'likert_choice-sclae'}>
                            <div
                              className={[
                                'likert-choice-font-Two',
                                'ig-explanation',
                                'ex_container'
                              ].join(' ')}
                              style={{
                                cursor: reviewReviseMode === 'revise' ? 'pointer' : ''
                              }}
                            >
                              <EditorTemplate
                                jsonData={
                                  opt.itemFrameworkOneResponseChoiceExplanation
                                    ?.itemFrameworkOneResponseChoiceExplanationMedia
                                }
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </Fragment>
                  );
                })}
              </Fragment>
            </div>
          </div>
          <div
            style={{
              display: responseAlignment !== 'horizontal' ? 'block' : 'none'
            }}
          >
            <div className={['innerpadding-update', 'innerheightAdjustOnly'].join(' ')}>
              <div
                className={['ex_container', 'ig-itemGeneric'].join(' ')}
                subquestionid={ob.itemFrameworkOneSectionSequence}
                style={{
                  cursor: reviewReviseMode === 'revise' ? 'pointer' : ''
                }}
                onClick={
                  reviewReviseMode === 'revise'
                    ? () => {
                        dispatch({
                          type: SET_POPUP_VALUE,
                          payload: {
                            isPopUpValue: 'ASSESSMENT_SUB_ITEM_PRIMARY_POPUP',
                            popupMode: `LIKERT_ITEM_MEDIA_TEXT_${keys}`
                          }
                        });
                      }
                    : null
                }
              >
                <EditorTemplate
                  label={'sub item'}
                  jsonData={ob.itemFrameworkOne?.itemFrameworkOneMedia}
                />
              </div>
            </div>
            {ob.itemFrameworkOne?.itemFrameworkOneExplanation?.itemFrameworkOneExplanationMedia && (
              <div className={['innerpadding', 'innerheightAdjust'].join(' ')}>
                <div className={['ex_container', 'ig-explanation'].join(' ')}>
                  {ob.itemFrameworkOne?.itemFrameworkOneExplanation
                    ?.itemFrameworkOneExplanationMedia && (
                    <div>
                      <div className={['ig-explanation', 'item-explanation'].join(' ')}>
                        <EditorTemplate
                          jsonData={
                            ob.itemFrameworkOne?.itemFrameworkOneExplanation
                              ?.itemFrameworkOneExplanationMedia
                          }
                          label={'itemFrameworkOneExplanationMedia'}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            <div
            //className={['rating-scale-container-row'].join(' ')}
            // style={{
            //   display: responseAlignment === 'vertical' ? 'grid' : 'grid',
            //   gridTemplateColumns: `repeat(12,minmax(0,1fr))`
            // }}
            >
              {ob.itemFrameworkOne?.itemFrameworkOneResponseChoice.map((opt, key) => {
                return (
                  <Fragment key={key}>
                    {(opt.itemFrameworkOneResponseChoiceMedia && (
                      <div
                        style={{
                          display: responseAlignment === 'vertical' ? 'grid' : 'grid',
                          gridTemplateColumns: `repeat(12,minmax(0,1fr))`
                        }}
                      >
                        <div
                          style={{
                            gridColumn: `span ${6} / span ${6}`
                          }}
                          key={`op-${key}`}
                          className={['innerpadding', 'innerheightAdjust'].join(' ')}
                        >
                          <div className="option-container ex_container" key={`option-${key}`}>
                            <div
                              style={{
                                paddingRight: '5px',
                                height: '20px',
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer'
                              }}
                            >
                              <input
                                type="radio"
                                data-key="555"
                                name={`option1-${clusterKey}${ob.itemFrameworkOneSectionSequence}`}
                                value={`${opt.itemFrameworkOneResponseChoiceNumber}`}
                                style={{
                                  backgroundColor:
                                    ((isAssessmentPreviewShow || isAssessmentSectionShow) &&
                                      clusterInfo?.assessmentClusterResponseChoiceCorrect?.length >
                                        0 &&
                                      clusterInfo?.assessmentClusterResponseChoiceCorrect[keys] ===
                                        opt.itemFrameworkOneResponseChoiceNumber &&
                                      '#7dc832') ||
                                    (opt.itemFrameworkOneResponseChoiceNumber ===
                                      ob?.itemFrameworkOne.itemFrameworkOneResponseCorrect &&
                                      '#7dc832') ||
                                    '',

                                  cursor:
                                    !isAssessmentPreviewShow ||
                                    !isAssessmentSectionShow ||
                                    reviewReviseMode === 'revise'
                                      ? 'pointer'
                                      : ''
                                }}
                                onChange={
                                  reviewReviseMode === 'revise' &&
                                  clusterInfo &&
                                  isAssessmentSectionShow
                                    ? () => {
                                        handleRadioLikertButton(
                                          ob.itemFrameworkOneSectionSequence,
                                          clusterInfo?.id,
                                          opt.itemFrameworkOneResponseChoiceNumber
                                        );
                                      }
                                    : (e) => {
                                        handleRadioButton(e);
                                      }
                                }
                                checked={
                                  (isAssessmentSectionShow &&
                                    clusterInfo?.assessmentClusterResponseChoiceCorrect?.length >
                                      0 &&
                                    clusterInfo?.assessmentClusterResponseChoiceCorrect[keys] ===
                                      opt.itemFrameworkOneResponseChoiceNumber) ||
                                  opt.itemFrameworkOneResponseChoiceNumber ===
                                    ob?.itemFrameworkOne.itemFrameworkOneResponseCorrect
                                }
                              />
                            </div>
                            <div
                              className={'ig-itemGeneric'}
                              style={{
                                paddingLeft: '5px'
                              }}
                            >
                              <EditorTemplate
                                // jsonData={
                                //   opt.itemFrameworkOneResponseChoiceExplanation
                                //     ?.itemFrameworkOneResponseChoiceExplanationMedia
                                // }
                                jsonData={opt.itemFrameworkOneResponseChoiceMedia}
                                label={'itemFrameworkOneResponseChoiceMedia'}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className={['innerpadding', 'innerheightAdjust'].join(' ')}
                          style={{
                            gridColumn: `span ${6} / span ${6}`
                          }}
                          onClick={
                            isAssessmentPreviewShow || isAssessmentSectionShow
                              ? (e) => {
                                  dispatch({
                                    type: SET_POPUP_VALUE,
                                    payload: {
                                      isPopUpValue: 'ASSESSMENT_SUB_ITEM_SCALE_POPUP',
                                      popupMode: `SCALE_METIC_${key}`
                                    }
                                  });
                                }
                              : null
                          }
                        >
                          <div className={['assessmentScale', 'ex_container'].join(' ')}>
                            <div className={['midPaneInformationScale_Vertical'].join(' ')}>
                              {assessmentScale[0]?.assessmentScale[key]?.assessmentScaleMetricLabel}
                            </div>
                          </div>
                        </div>
                        {opt.itemFrameworkOneResponseChoiceExplanation
                          ?.itemFrameworkOneResponseChoiceExplanationMedia && (
                          <div
                            key={`op-${key}`}
                            style={{
                              gridColumn: `span ${6} / span ${6}`
                            }}
                            className={['innerpadding', 'innerheightAdjust'].join(' ')}
                          >
                            <div
                              className={['ex_container', 'ig-explanation'].join(' ')}
                              style={{
                                paddingLeft: '35px',
                                display: 'inline-block'
                              }}
                            >
                              <div
                                className={['', 'ig-explanation'].join(' ')}
                                style={{
                                  cursor: reviewReviseMode === 'revise' ? 'pointer' : ''
                                }}
                              >
                                <EditorTemplate
                                  jsonData={
                                    opt.itemFrameworkOneResponseChoiceExplanation
                                      ?.itemFrameworkOneResponseChoiceExplanationMedia
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )) || (
                      <div
                        style={{ width: '100%', display: 'flex' }}
                        className={['innerpadding', 'innerheightAdjust'].join(' ')}
                      >
                        <div
                          //className={["option-container ex_container"].join(' ') }
                          className={['ex_container'].join(' ')}
                          key={`option-${key}`}
                        >
                          <div
                            style={{
                              paddingRight: '5px',
                              height: '20px',
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            <input
                              type="radio"
                              data-key="111"
                              name={`option1-${clusterKey}${ob.itemFrameworkOneSectionSequence}`}
                              value={`${opt.itemFrameworkOneResponseChoiceNumber}`}
                              style={{
                                backgroundColor:
                                  ((isAssessmentPreviewShow || isAssessmentSectionShow) &&
                                    clusterInfo?.assessmentClusterResponseChoiceCorrect?.length >
                                      0 &&
                                    clusterInfo?.assessmentClusterResponseChoiceCorrect[keys] ===
                                      opt.itemFrameworkOneResponseChoiceNumber &&
                                    '#7dc832') ||
                                  (opt.itemFrameworkOneResponseChoiceNumber ===
                                    ob?.itemFrameworkOne.itemFrameworkOneResponseCorrect &&
                                    '#7dc832') ||
                                  '',
                                cursor:
                                  !isAssessmentPreviewShow ||
                                  !isAssessmentSectionShow ||
                                  reviewReviseMode === 'revise'
                                    ? 'pointer'
                                    : ''
                              }}
                              onChange={
                                reviewReviseMode === 'revise' &&
                                clusterInfo &&
                                (isAssessmentPreviewShow || isAssessmentSectionShow)
                                  ? () => {
                                      handleRadioLikertButton(
                                        ob.itemFrameworkOneSectionSequence,
                                        clusterInfo?.id,
                                        opt.itemFrameworkOneResponseChoiceNumber
                                      );
                                    }
                                  : (e) => {
                                      handleRadioButton(
                                        e,
                                        opt.itemFrameworkOneResponseChoiceNumber,
                                        `option1-${clusterKey}${ob.itemFrameworkOneSectionSequence}`
                                      );
                                    }
                              }
                              checked={
                                ((isAssessmentPreviewShow || isAssessmentSectionShow) &&
                                  clusterInfo?.assessmentClusterResponseChoiceCorrect?.length > 0 &&
                                  clusterInfo?.assessmentClusterResponseChoiceCorrect[keys] ===
                                    opt.itemFrameworkOneResponseChoiceNumber) ||
                                opt.itemFrameworkOneResponseChoiceNumber ===
                                  ob?.itemFrameworkOne.itemFrameworkOneResponseCorrect
                              }
                            />
                          </div>
                          <div
                            className={'ig-itemGeneric'}
                            style={{
                              paddingLeft: '5px',
                              paddingTop: '7px'
                            }}
                          >
                            <EditorTemplate
                              jsonData={
                                opt.itemFrameworkOneResponseChoiceExplanation
                                  ?.itemFrameworkOneResponseChoiceExplanationMedia
                              }
                            />
                          </div>
                        </div>
                        <div className={['assessmentScale', 'ex_container'].join(' ')}>
                          <div
                            className={['midPaneInformationScale_Vertical'].join(' ')}
                            onClick={
                              isAssessmentPreviewShow || isAssessmentSectionShow
                                ? () => {
                                    dispatch({
                                      type: SET_POPUP_VALUE,
                                      payload: {
                                        isPopUpValue: 'ASSESSMENT_SUB_ITEM_SCALE_POPUP',
                                        popupMode: `SCALE_METIC_${key}`
                                      }
                                    });
                                  }
                                : (e) => {
                                    handleRadioButton(
                                      e,
                                      opt.itemFrameworkOneResponseChoiceNumber,
                                      `option1-${clusterKey}${ob.itemFrameworkOneSectionSequence}`
                                    );
                                  }
                            }
                          >
                            {
                              assessmentScale[0]?.assessmentScaleMetric[key]
                                ?.assessmentScaleMetricLabel
                            }
                          </div>
                        </div>
                      </div>
                    )}
                  </Fragment>
                );
              })}
            </div>
          </div>
        </div>
      ) : itemFrameworkOneType === '61161713f24e1fb765208e23' ? (
        <div>
          <div>
            <div className={['innerpadding', 'innerheightAdjust'].join(' ')}>
              <div
                className={['ex_container', 'ig-itemGeneric'].join(' ')}
                subquestionid={ob.itemFrameworkOneSectionSequence}
                style={{
                  cursor: reviewReviseMode === 'revise' ? 'pointer' : ''
                }}
                onClick={
                  reviewReviseMode === 'revise'
                    ? () => {
                        dispatch({
                          type: SET_POPUP_VALUE,
                          payload: {
                            isPopUpValue: 'ASSESSMENT_SUB_ITEM_PRIMARY_POPUP',
                            popupMode: `LIKERT_ITEM_MEDIA_TEXT_${keys}`
                          }
                        });
                      }
                    : null
                }
              >
                <EditorTemplate
                  label={'sub item'}
                  jsonData={ob.itemFrameworkOne?.itemFrameworkOneMedia}
                />
              </div>
            </div>
            <div className={['innerpadding', 'innerheightAdjust'].join(' ')}>
              <div className={['ex_container', 'ig-explanation'].join(' ')}>
                {ob.itemFrameworkOne?.itemFrameworkOneExplanation
                  ?.itemFrameworkOneExplanationMedia && (
                  <div>
                    <div className={['ig-explanation', 'item-explanation'].join(' ')}>
                      <EditorTemplate
                        jsonData={
                          ob.itemFrameworkOne?.itemFrameworkOneExplanation
                            ?.itemFrameworkOneExplanationMedia
                        }
                        label={'itemFrameworkOneExplanationMedia'}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              className={['', 'rating-scale-container'].join(' ')}
              style={{ display: responseAlignment === 'vertical' ? 'block' : 'grid' }}
            >
              <div style={{ gridColumn: `span ${2} / span ${2}` }}></div>
              {ob.itemFrameworkOne?.itemFrameworkOneResponseChoice.map((opt, key) => {
                return (
                  <Fragment key={key}>
                    <div
                      key={`op-${key}`}
                      style={{
                        gridColumn: `span ${2} / span ${2}`
                      }}
                      className={['innerpadding', 'innerheightAdjust'].join(' ')}
                    >
                      <div className="option-container ex_container" key={`option-${key}`}>
                        <div
                          style={{
                            paddingRight: '5px',
                            height: '20px',
                            display: 'flex',
                            alignItems: 'center',
                            paddingTop: '7px'
                          }}
                        >
                          <input
                            type="radio"
                            data-key="222"
                            name={`option1-${clusterKey}${ob.itemFrameworkOneSectionSequence}`}
                            value={`${opt.itemFrameworkOneResponseChoiceNumber}`}
                            style={{
                              cursor: reviewReviseMode === 'revise' ? 'pointer' : ''
                            }}
                            onChange={
                              reviewReviseMode === 'revise' &&
                              clusterInfo &&
                              (isAssessmentPreviewShow || isAssessmentSectionShow)
                                ? () => {
                                    handleRadioLikertButton(
                                      ob.itemFrameworkOneSectionSequence,
                                      clusterInfo?.id,
                                      opt.itemFrameworkOneResponseChoiceNumber
                                    );
                                  }
                                : (e) => {
                                    handleRadioButton(e);
                                  }
                            }
                            checked={
                              ((isAssessmentPreviewShow || isAssessmentSectionShow) &&
                                clusterInfo?.assessmentClusterResponseChoiceCorrect?.length > 0 &&
                                clusterInfo?.assessmentClusterResponseChoiceCorrect[keys] ===
                                  opt.itemFrameworkOneResponseChoiceNumber) ||
                              opt.itemFrameworkOneResponseChoiceNumber ===
                                ob?.itemFrameworkOne.itemFrameworkOneResponseCorrect
                            }
                          />
                        </div>
                        <div
                          className={'ig-itemGeneric'}
                          style={{
                            paddingLeft: '5px',
                            paddingTop: '7px'
                          }}
                        >
                          <EditorTemplate
                            jsonData={
                              opt.itemFrameworkOneResponseChoiceExplanation
                                ?.itemFrameworkOneResponseChoiceExplanationMedia
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      key={`op-${key}`}
                      className={['innerpadding', 'innerheightAdjust'].join(' ')}
                    >
                      <div
                        className={['ex_container', 'ig-explanation'].join(' ')}
                        style={{
                          paddingLeft: '35px',
                          display: 'inline-block'
                        }}
                      >
                        <div
                          className={['', 'ig-explanation'].join(' ')}
                          style={{
                            cursor: reviewReviseMode === 'revise' ? 'pointer' : ''
                          }}
                        >
                          <EditorTemplate
                            jsonData={
                              opt.itemFrameworkOneResponseChoiceExplanation
                                ?.itemFrameworkOneResponseChoiceExplanationMedia
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Fragment>
                );
              })}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
const AssessmentTimer = ({ expiryTimestamp, timerFinished, dispatch }) => {
  // const { seconds, minutes, hours } = useTimer({
  //   expiryTimestamp,
  //   onExpire: timerFinished
  //   // onExpire: () => {
  //   //   console.warn('onExpire called');
  //   // }
  // });

  const { hours, minutes, seconds, completed } = useSelector((state) => state.CountDownReducer);
  
  useEffect(()=>{
    if(completed){
      timerFinished()
    }
  },[completed])

  return (
    <div>
      {/* <span>{hours < 10 ? '0' + hours : hours}</span>:
      <span>{minutes < 10 ? '0' + minutes : minutes}</span>:
      <span>{seconds < 10 ? '0' + seconds : seconds}</span> */}
      {`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}
    </div>
  );
};
const AssessmentHeader = (props) => {
  return (
    <>
      <Fragment>
        <Paper
          style={{
            boxShadow:
              '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)'
          }}
          // className={'containerPadding'}
        >
          <div
          // className="sticky-header"
          >
            <div style={{ height: '50px', padding: '0 5px', display: 'flex' }} className={''}>
              <div className={['five-assessment-header-flex4'].join(' ')}>
                <div
                  className={[
                    'midPaneInformation',
                    props.assessmentDesc !== '' ? null : 'aliasmiddle'
                  ].join(' ')}
                >
                  {props.assessmentName}
                </div>
                <div className={['midPaneLabel', 'textOverflow'].join(' ')}>
                  {props.assessmentDesc}
                </div>
              </div>
              <div className={['five-assessment-header-flex4'].join(' ')}>
                {props.assessmentSectionName + props.assessmentSectionDescription !==
                'SectionGeneric' ? (
                  <div>
                    <div
                      className={[
                        'midPaneInformation',
                        props.assessmentSectionDescription !== '' ? null : 'aliasmiddle'
                      ].join(' ')}
                    >
                      {props.assessmentSectionName}
                    </div>
                    <div className={['midPaneLabel', 'textOverflow'].join(' ')}>
                      {props.assessmentSectionDescription}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className={['five-assessment-header-flex1'].join(' ')}>
                <div
                  style={{ display: 'block !important' }}
                  className={[
                    'midPaneInformationMergeHeader',
                    props.isItemSequenceLabel ? null : 'descriptionMiddle'
                  ].join(' ')}
                >
                  {props.currentQuestion + '/' + props.totalQuestion}
                </div>

                <div className={['assessmentheader', 'textOverflow'].join(' ')}>
                  {props.isItemSequenceLabel}
                </div>
              </div>
              <div className={['five-assessment-header-flex1'].join(' ')}>
                <div
                  style={{ display: 'block !important' }}
                  className={['midPaneInformationMergeHeader'].join(' ')}
                >
                  {props.score}
                </div>

                <div className={['assessmentheader', 'textOverflow'].join(' ')}>{'score'}</div>
              </div>
              <div className={['five-assessment-header-flex1'].join(' ')}>
                <div
                  className={[
                    'midPaneInformationMergeHeader',
                    ''
                    //props.assessmentSectionDescription !== '' ? null : 'aliasmiddle'
                  ].join(' ')}
                >
                  {props.timer && (
                    <span style={{}}>
                      <AssessmentTimer
                        expiryTimestamp={props.timer}
                        dispatch={props.dispatch}
                        key={props.timer}
                        timerFinished={props.timerFinished}
                      />
                    </span>
                  )}
                </div>
                <div className={['assessmentheader', 'textOverflow'].join(' ')}>
                  {props.timer && 'section'}
                </div>
              </div>
              <div className={['five-assessment-header-flex1', 'assessmentFlagButton'].join(' ')}>
                <IconButton
                  onClick={(e) => {
                    props.onClickFlag && props.onClickFlag(props.id);
                  }}
                >
                  {props.isQuestionFlaged ? (
                    <i className="fa fa-flag" style={{ color: '#ff6464' }}></i>
                  ) : (
                    <i className="far fa-flag"></i>
                  )}
                </IconButton>
              </div>
            </div>
          </div>
        </Paper>
        {/* <hr className={'assessmentHeaderHr'} /> */}
      </Fragment>
    </>
  );
};

export const DisplayPaneFiveAssessment = (props) => {
  const {
    headerOne,
    closePreview,
    data,
    typeMode = true,
    informationFramework,
    currentItemIndex,
    flagQuestion,
    isQuestionFlaged,
    currentSectionIndex,
    currentVersionIndex = 0,
    itemObect,
    timerFinished,
    timer,
    handleRadioButton,
    currentQuestionChoice,
    assessmentName,
    assessmentDescription,
    currentItemResponse,
    itemListArray,
    responseAlignment = 'horizontal',
    itemFrameworkOneResponseChoiceSequenceAdministration = [],
    assessmentSectionItemFrameworkOneLabel = null,
    assessmentSectionItemFrameworkOneResponseLabel = '',
    onSaveHandle = null,
    responseData = ''
  } = props;
  const dispatch = useDispatch();
  const { reviewMode, responseObject } = useSelector((state) => state.DisplayPaneThreeReducer);
  const { itemConfigStates, assessmentResponseObject, assessmentSelecedSection,signedAssesseePermission } = useSelector(
    (state) => state.DisplayPaneTwoReducer
  );
  const instanceRef = useRef(null);
  const { isPopUpValue, popupMode } = useSelector((state) => state.PopUpReducer);
  const assessmentInfo = useSelector((state) => state.AssessmentReducer);
  const { sectionInformation } = useSelector((state) => state.SectionCreateReducer);
  const { assesseeAssessmentStartData, isItemTrial } = useSelector(
    (state) => state.AssesseeAssignmentAssessmentReducer
  );
  const {
    isAssessmentPreviewShow = false,
    isAssessmentSectionShow = false,
    isAssessmentVersionShow = false
  } = useSelector((state) => state.DisplayPaneThreeReducer);
  const [isShowReviseIcon, setIsShowReviseIcon] = useState(true);
  const [selectedChoiceObject, setSelectedChoiceObject] = useState('');
  const [subQuestionId, setSubQuestionId] = useState('');
  const [scaleMetricId, setScaleMetricId] = useState('');
  const [innerContent, setInnerContent] = useState('');

  const responseText = '<p><span>response</span></p>';
  // console.log('assessmentResponseObject', assessmentResponseObject);
  let node = React.createRef();
  useEffect(() => {
    dispatch({ type: LOADER_START });
    setTimeout(() => {
      let allClasses = document.getElementsByClassName('innerheightAdjustOnly');
      if (allClasses.length > 0) {
        for (let i = 0; i < allClasses.length; i++) {
          let currentDivHeight = allClasses[i].clientHeight;
          if (currentDivHeight > 110) {
            allClasses[i].style.height = '';
            //allClasses[i].removeAttr('height');
          }
        }
      }
      if (allClasses.length > 0) {
        for (let i = 0; i < allClasses.length; i++) {
          let currentDivHeight = allClasses[i].clientHeight;
          if (currentDivHeight > 110) {
            let reminder = currentDivHeight / 55;
            allClasses[i].style.height = 50 + 55 * Math.floor(reminder) + 'px';
          }
        }
      }
      window.MathJax.Hub.Config({
        extensions: ['tex2jax.js'],
        jax: ['input/TeX', 'output/HTML-CSS'],
        tex2jax: {
          inlineMath: [
            ['$', '$'],
            ['\\(', '\\)']
          ],
          displayMath: [
            ['$$', '$$'],
            ['\\[', '\\]']
          ],
          processEscapes: true
        }, //used for  other ['$','$'],
        showMathMenu: false,
        messageStyle: 'none',
        errorSettings: { message: ['[Math Processing Error]'] },
        MathEvents: {
          hover: 'none'
        },
        'HTML-CSS': {
          scale: 90,
          imageFont: null,
          preferredFont: null,
          availableFonts: ['Roboto'],
          webFont: 'STIX-Web'
        },
        SVG: { font: 'STIX-Web' }
      });

      window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub, node.current]);
    }, 50);
    dispatch({ type: LOADER_STOP });
    console.log('selected', itemObect?.assesseeAssignmentAssessmentItemResponseChoiceSelected);
  }, [itemObect]);
  useEffect(() => {
    setInnerContent(responseData);
  }, [responseData]);
  useEffect(() => {
    let assessmentAdministrationTemplateMedia =
      informationFramework?.assessmentAdministrationTemplateMedia || [];
    var style = document.createElement('style');
    style.type = 'text/css';
    if (assessmentAdministrationTemplateMedia.length > 0) {
      for (let i = 0; i < assessmentAdministrationTemplateMedia.length; i++) {
        let assessmentItemFrameworkOneStyle = assessmentAdministrationTemplateMedia[
          i
        ].assessmentItemFrameworkOneStyle?.replace(',', '');
        style.innerHTML += '.cssClass' + [i] + ' { ' + assessmentItemFrameworkOneStyle + ' }';
      }
      document.getElementsByTagName('head')[0].appendChild(style);
    }
  }, [informationFramework?.assessmentItemFrameworkOneTemplate]);

  const assessmentScaleArr =
    assessmentResponseObject?.informationFramework?.assessmentScaleFramework || [];
  const assessmentScale = itemObect?.itemFrameworkOneScale || [];
  const assessmentCluster =
    assessmentResponseObject?.informationFramework?.assessmentClusterFramework || [];
  const BackHandlerEvent = (e) => {};
  const setSecondaryOptionValue = (e) => {
    //TODO: set secondary option in ite
  };
  let getObj = isAssessmentSectionShow
    ? sectionInformation?.assessmentSectionItemDistinctReviseObject?.itemFrameworkOne
    : itemObect;
  let reviseClusterItemFram = getObj?.itemFrameworkOneCluster || [];
  let clusterObj = getObj?.itemFrameworkOneClusterNumber || [];
  const reviewReviseMode = isAssessmentVersionShow ? 'review' : reviewMode;

  const itemPrimaryPopupOption = [
    {
      data: 'configure',
      dataValue: 'configure',
      dataKey: 'configureAPICall',
      optionClass: 'optionPrimary',
      divider: '',
      disabled: false
    },
    {
      data: 'revise',
      dataValue: 'revise',
      dataKey: 'reviseAPICall',
      optionClass: 'optionPrimary',
      divider: '',
      disabled: true
    }
  ];
  const ChangeOptionPopup = (e) => {
    let targetValue = e.currentTarget.getAttribute('data-value');
    // setSubQuestionId(e.currentTarget.getAttribute('subquestionid'))
    setSubQuestionId(popupMode.split('_'));
    if (targetValue === 'configure') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'SUB_ITEM_FRAMEWORK_POPUP',
          popupMode: ''
        }
      });
    }
  };
  const ChangeScaleConfigurePopup = (e) => {
    let targetValue = e.currentTarget.getAttribute('data-value');
    // setSubQuestionId(e.currentTarget.getAttribute('subquestionid'))
    setScaleMetricId(popupMode.split('_'));
    if (targetValue === 'configure') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'SCALE_FRAMEWORK_POPUP',
          popupMode: ''
        }
      });
    }
  };
  const ChangeResponseOptionPopup = (e) => {
    let targetValue = e.currentTarget.getAttribute('data-value');
    // setSubQuestionId(e.currentTarget.getAttribute('subquestionid'))
    setSubQuestionId(popupMode.split('_'));
    if (targetValue === 'configure') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'ASSESSMENT_SUB_ITEM_FRAMEWORK_POPUP',
          popupMode: ''
        }
      });
    }
  };
  const ChangeItemOptionPopup = (e) => {
    let targetValue = e.currentTarget.getAttribute('data-value');
    if (targetValue === 'configure') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'ASSESSMENT_ITEM_FRAMEWORK_POPUP',
          popupMode: ''
        }
      });
    }
  };
  const ChangeTripleDotOptionPopup = (e) => {
    let targetValue = e.currentTarget.getAttribute('data-value');
    if (targetValue === 'configure') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'ASSESSMENT_CONFIGURE_POPUP',
          popupMode: ''
        }
      });
    }
    if (targetValue === 'revise') {
      dispatch({
        type: SET_DISPLAY_PANE_THREE_REVIEW_MODE,
        payload: 'revise'
      });
      dispatch({ type: POPUP_CLOSE });
    }
    if (targetValue === 'review') {
      dispatch({
        type: SET_DISPLAY_PANE_THREE_REVIEW_MODE,
        payload: 'review'
      });
      dispatch({ type: POPUP_CLOSE });
    }
  };
  const itemPrimaryTriplePopupOption = [
    {
      data: 'configure',
      dataValue: 'configure',
      dataKey: 'configureAPICall',
      optionClass: 'optionPrimary',
      divider: '',
      permissionValue:'revise',
      // disabled: isAssessmentVersionShow && reviewMode === 'revise' ? false : true
      disabled: isAssessmentVersionShow && reviewMode === 'revise' ? false : true
    },
    {
      data: 'review',
      dataValue: 'review',
      dataKey: 'reviewAPICall',
      optionClass: 'optionPrimary',
      divider: '',
      permissionValue:'review',
      disabled:
        (isAssessmentVersionShow || isAssessmentSectionShow) && reviewMode === 'revise'
          ? false
          : true
    },
    {
      data: 'revise',
      dataValue: 'revise',
      dataKey: 'reviseAPICall',
      optionClass: 'optionPrimary',
      divider: '',
      permissionValue:'revise',
      disabled:
        ((isAssessmentVersionShow || isAssessmentSectionShow) && reviewMode === 'revise') ||
        assessmentResponseObject?.informationEngagement?.assessmentStatus === 'PUBLISHED'
          ? true
          : false
    }
  ];
  const ChangeResponsePopup = (e) => {
    let targetValue = e.currentTarget.getAttribute('data-value');
    if (targetValue === 'configure') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'ASSESSMENT_RESPONSE_CONFIGURE_POPUP',
          popupMode: ''
        }
      });
    }
  };
  let informationFrameworkObj = isAssessmentSectionShow
    ? informationFramework?.assessmentVersionFramework[currentVersionIndex]
        .assessmentVersionItemDistinct
    : isAssessmentPreviewShow
    ? isItemTrial
      ? itemListArray
      : assesseeAssessmentStartData?.assessmentVersionFramework[currentVersionIndex]
          ?.assessmentVersionItemDistinct
    : // : informationFramework?.assessmentSectionFramework[currentSectionIndex]
    //   ?.assessmentVersionFramework[currentVersionIndex].assessmentVersionItemDistinct
    isAssessmentVersionShow
    ? informationFramework?.assessmentVersionItemDistinct
    : isItemTrial
    ? informationFramework
    : informationFramework?.assessmentSectionItemDistinct;
  // (isAssessmentSectionShow &&
  //   informationFramework?.assessmentVersionFramework[currentVersionIndex]
  //     ?.assessmentVersionItemDistinct) ||
  // (isAssessmentPreviewShow &&
  //   (isItemTrial &&
  //     itemListArray) ||
  //   informationFramework?.assessmentSectionFramework[currentSectionIndex]
  //     ?.assessmentVersionFramework[currentVersionIndex].assessmentVersionItemDistinct) ||
  // (isAssessmentVersionShow && informationFramework?.assessmentVersionItemDistinct) || informationFramework?.assessmentSectionItemDistinct;

  const handleRadioNormalButton = (sequence, choiceNumber) => {
    itemObect?.itemFrameworkOneSection.map((ele) => {
      if (ele.itemFrameworkOneSectionSequence === sequence) {
        ele.itemFrameworkOne.itemFrameworkOneResponseCorrect = choiceNumber;
      }
    });
  };
  const handleRadioLikertButton = (sequence, clusterId, choiceNumber) => {
    clusterObj.forEach((cc) => {
      if (cc.assessmentClusterId === clusterId) {
        cc.assessmentClusterResponseChoiceCorrect[sequence - 1] = choiceNumber;
      }
    });
    reviseClusterItemFram.forEach((clust) => {
      let clusterId = clust?.assessmentClusterId || clust?.id;
      if (clusterId === clusterId) {
        clust.assessmentClusterResponseChoiceCorrect[sequence - 1] = choiceNumber;
      }
    });
    dispatch({
      type: SET_ASSESSMENT_SECTION_FRAMEWORK_DYNAMIC_SINGLE_STATE,
      payload: { stateName: 'itemFrameworkOneClusterNumber', value: clusterObj }
    });
  };
  const handleEditorChange = async () => {
    let savedData = await instanceRef.current.save();
    let editorData = '';
    // console.log(savedData);
    if (savedData.blocks.length > 0) {
      editorData = { ...savedData };
    }
    if (onSaveHandle) {
      onSaveHandle(editorData);
    }
  };
  return (
    <>
      <div className="containerPadding">
        {itemObect && informationFrameworkObj?.length > 0 && (
          <div className="containerPadding-assessment-header">
            <AssessmentHeader
              dispatch={dispatch}
              assessmentName={
                (isAssessmentSectionShow &&
                  assessmentResponseObject?.informationBasic?.assessmentName) ||
                (isAssessmentPreviewShow &&
                  assessmentResponseObject?.informationBasic?.assessmentName) ||
                (isAssessmentVersionShow &&
                  assessmentResponseObject?.informationFramework?.assessmentSectionFramework[
                    assessmentSelecedSection
                  ].assessmentSectionName) ||
                assessmentName
              }
              assessmentDesc={
                (isAssessmentSectionShow &&
                  assessmentResponseObject?.informationBasic?.assessmentDescription) ||
                (isAssessmentPreviewShow &&
                  assessmentResponseObject?.informationBasic?.assessmentDescription) ||
                (isAssessmentVersionShow &&
                  assessmentResponseObject?.informationFramework?.assessmentSectionFramework[
                    assessmentSelecedSection
                  ].assessmentSectionDescription) ||
                assessmentDescription ||
                ''
              }
              assessmentSectionName={
                isAssessmentSectionShow
                  ? informationFramework?.assessmentSectionName
                  : isAssessmentPreviewShow
                  ? isItemTrial
                    ? ''
                    : assesseeAssessmentStartData?.assessmentSectionName
                  : // : informationFramework?.assessmentSectionFramework[currentSectionIndex]
                  //   .assessmentSectionName
                  isAssessmentVersionShow
                  ? informationFramework?.assessmentVersionName
                  : informationFramework?.assessmentSectionName

                //   (isAssessmentSectionShow && informationFramework?.assessmentSectionName) ||
                // (isAssessmentPreviewShow &&
                //   isItemTrial && ''
                //   || informationFramework?.assessmentSectionFramework[currentSectionIndex]
                //     .assessmentSectionName
                // )
                // ||
                // informationFramework?.assessmentSectionName ||
                // (isAssessmentVersionShow && informationFramework?.assessmentVersionName) ||
                // ''
              }
              assessmentSectionDescription={
                isAssessmentSectionShow
                  ? informationFramework?.assessmentSectionDescription
                  : isAssessmentPreviewShow
                  ? isItemTrial
                    ? ''
                    : assesseeAssessmentStartData?.assessmentSectionDescription
                  : // : informationFramework?.assessmentSectionFramework[currentSectionIndex]
                  //   .assessmentSectionDescription
                  isAssessmentVersionShow
                  ? informationFramework?.assessmentVersionDescription
                  : informationFramework?.assessmentSectionDescription

                //   (isAssessmentSectionShow && informationFramework?.assessmentSectionDescription) ||
                // (isAssessmentPreviewShow &&
                //   (isItemTrial && '') ||
                //   informationFramework?.assessmentSectionFramework[currentSectionIndex]
                //     .assessmentSectionDescription) ||
                // informationFramework?.assessmentSectionDescription ||
                // (isAssessmentVersionShow && informationFramework?.assessmentVersionDescription) ||
                // ''
              }
              id={
                (informationFrameworkObj.length > 0 &&
                  informationFrameworkObj[currentItemIndex]?.itemId) ||
                informationFrameworkObj[currentItemIndex]?.itemTagPrimary ||
                ''
              }
              score={
                (informationFrameworkObj.length > 0 &&
                  informationFrameworkObj[currentItemIndex]?.itemFrameworkOne
                    ?.itemFrameworkOneScore) ||
                (informationFrameworkObj.length > 0 &&
                  informationFrameworkObj[currentItemIndex]?.itemFrameworkOneScore) ||
                ''
              }
              onClickFlag={flagQuestion}
              isQuestionFlaged={
                (informationFrameworkObj.length > 0 &&
                  informationFrameworkObj[currentItemIndex]
                    ?.assesseeAssignmentAssessmentItemFlagged) ||
                false
              }
              timerFinished={timerFinished}
              timer={timer}
              totalQuestion={informationFrameworkObj.length}
              isItemSequenceLabel={'item'}
              currentQuestion={
                isAssessmentVersionShow
                  ? informationFrameworkObj.length > 0 &&
                    informationFrameworkObj[currentItemIndex].itemSequence
                  : currentItemIndex + 1
              }
            />
          </div>
        )}
        <div
          ref={node}
          key={clusterObj}
          className=""
          style={{ height: 'calc(100vh - 200px)', overflow: 'overlay' }}
          id={'containerItem'}
        >
          <div
            className={'containerPadding'}
            style={{
              display:
                ((itemObect?.itemFrameworkOneLabel?.itemFrameworkOneLabelMedia ||
                  itemObect?.itemFrameworkOnePassage?.itemFrameworkOnePassageMedia ||
                  (itemObect?.itemFrameworkOneCluster?.length > 0 && isAssessmentSectionShow) ||
                  (assessmentScale.length > 0 && responseAlignment === 'horizontal') ||
                  (assessmentSectionItemFrameworkOneLabel &&
                    assessmentSectionItemFrameworkOneLabel)) &&
                  'none') ||
                (assessmentScale.length > 0 &&
                  responseAlignment &&
                  isItemTrial === 'verical' &&
                  'block') ||
                'block',
              height: '50px'
            }}
          >
            {' '}
          </div>
          {itemObect && (
            <Fragment>
              {/* item label */}
              {(assessmentSectionItemFrameworkOneLabel && (
                <div className={['innerpadding', 'innerheightAdjust-new'].join(' ')}>
                  <div
                    className={['ex_container', 'ig-label', 'cssClass0', 'assessmentScale'].join(
                      ' '
                    )}
                  >
                    <EditorTemplate
                      label={'itemFrameworkOneLabel'}
                      jsonData={assessmentSectionItemFrameworkOneLabel}
                    />
                  </div>
                </div>
              )) ||
                (itemObect?.itemFrameworkOneLabel?.itemFrameworkOneLabelMedia && (
                  <div className={['innerpadding', 'innerheightAdjust'].join(' ')}>
                    <div
                      className={['ex_container', 'ig-label', 'cssClass0', 'assessmentScale'].join(
                        ' '
                      )}
                    >
                      <EditorTemplate
                        label={'itemFrameworkOneLabel'}
                        jsonData={itemObect?.itemFrameworkOneLabel?.itemFrameworkOneLabelMedia}
                      />
                    </div>
                  </div>
                ))}
              {/* {itemObect?.itemFrameworkOneLabel?.itemFrameworkOneLabelMedia && (
                <div className={['innerpadding', 'innerheightAdjust'].join(' ')}>
                  <div className={['ex_container', 'ig-label', 'cssClass0'].join(' ')}>
                    <EditorTemplate
                      label={'itemFrameworkOneLabel'}
                      jsonData={itemObect?.itemFrameworkOneLabel?.itemFrameworkOneLabelMedia}
                    />
                  </div>
                </div>
              )} */}

              {/* Passage */}
              {itemObect?.itemFrameworkOnePassage?.itemFrameworkOnePassageMedia && (
                <div className={['innerpadding', 'innerheightAdjust'].join(' ')}>
                  <div
                    className={[
                      'ex_container',
                      'ig-itemGeneric',
                      'cssClass1',
                      'assessmentScale'
                    ].join(' ')}
                  >
                    {!itemObect?.itemFrameworkOnePassage?.itemFrameworkOnePassageMedia && (
                      <div>
                        <EditorTemplate
                          jsonData={
                            itemObect?.itemFrameworkOnePassage?.itemFrameworkOnePassageMedia
                          }
                          label={'passage'}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}

              {/* item */}

              {reviseClusterItemFram.length > 0 &&
                !itemObect?.itemFrameworkOneSection &&
                isAssessmentSectionShow &&
                reviseClusterItemFram.map((ff, clusterKey) => {
                  return (
                    <Fragment key={clusterKey}>
                      <div className={['innerpadding', 'innerheightAdjust'].join(' ')}>
                        <div className={['ex_container', 'itemcluster'].join(' ')}>
                          {ff.assessmentClusterName}
                        </div>
                      </div>
                    </Fragment>
                  );
                })}

              {(itemObect?.itemFrameworkOneMedia || reviewReviseMode === 'revise') && (
                <div
                  className={['innerpadding-update', 'innerheightAdjustOnly'].join(' ')}
                  style={{
                    cursor: reviewReviseMode === 'revise' ? 'pointer' : ''
                  }}
                  onClick={
                    (isAssessmentSectionShow || isItemTrial) && reviewReviseMode === 'revise'
                      ? () => {
                          dispatch({
                            type: SET_POPUP_VALUE,
                            payload: {
                              isPopUpValue: 'ITEM_PRIMARY_POPUP',
                              popupMode: popupMode
                            }
                          });
                        }
                      : null
                  }
                >
                  <div
                    className={[
                      'ex_container',
                      'ig-itemGeneric',
                      'cssClass2',
                      'assessmentScale'
                    ].join(' ')}
                  >
                    {(itemObect?.itemFrameworkOneMedia && (
                      <div>
                        <EditorTemplate
                          label={'itemFrameworkOneMedia'}
                          jsonData={itemObect?.itemFrameworkOneMedia}
                        />
                      </div>
                    )) ||
                      ReactHTMLParser('<span>item</span>')}
                  </div>
                </div>
              )}

              {/* item explanation */}
              {itemObect?.itemFrameworkOneExplanation?.itemFrameworkOneExplanationMedia && (
                <div className={['innerpadding', 'innerheightAdjust'].join(' ')}>
                  <div
                    className={[
                      'ex_container',
                      'ig-explanation',
                      'cssClass3',
                      'assessmentScale'
                    ].join(' ')}
                  >
                    <EditorTemplate
                      label={'itemFrameworkOneExplanation'}
                      jsonData={
                        itemObect?.itemFrameworkOneExplanation?.itemFrameworkOneExplanationMedia
                      }
                    />
                  </div>
                </div>
              )}

              {/* for sub item  */}
              {(itemObect?.itemFrameworkOneType === '61090cace50cf61d5eb440c9' ||
                itemObect?.itemFrameworkOneType === '61161713f24e1fb765208e23') && (
                <div className="innerpadding-old">
                  <FormControl component="fieldset" style={{ width: '100%', overflowX: 'auto' }}>
                    {assessmentScale.length > 0 && (
                      <div
                        className={['innerheightAdjust', 'rating-scale-container'].join(' ')}
                        style={{
                          textAlign: 'center',
                          height: '50px',
                          display: responseAlignment === 'vertical' ? 'none' : 'grid'
                        }}
                      >
                        <div
                          className="item"
                          style={{
                            gridColumn: `span ${
                              12 -
                              (assessmentScale[0]?.assessmentScaleMetric.length <= 3
                                ? 6
                                : assessmentScale[0]?.assessmentScaleMetric.length * 2)
                            } / span ${
                              12 -
                              (assessmentScale[0]?.assessmentScaleMetric.length <= 3
                                ? 6
                                : assessmentScale[0]?.assessmentScaleMetric.length * 2)
                            }`
                          }}
                        ></div>

                        {assessmentScale[0].assessmentScaleMetric.map((ob, key) => {
                          return (
                            <Fragment key={key}>
                              <div
                                style={{
                                  gridColumn: `span ${2} / span ${2}`
                                }}
                                onClick={
                                  isAssessmentPreviewShow || isAssessmentSectionShow
                                    ? (e) => {
                                        dispatch({
                                          type: SET_POPUP_VALUE,
                                          payload: {
                                            isPopUpValue: 'ASSESSMENT_SUB_ITEM_SCALE_POPUP',
                                            popupMode: `SCALE_METIC_${key}`
                                          }
                                        });
                                      }
                                    : null
                                }
                              >
                                <div className={['assessmentScale'].join(' ')}>
                                  <div className={['midPaneInformationScale_One'].join(' ')}>
                                    {ob.assessmentScaleMetricLabel}
                                  </div>
                                  <div className={['midPaneLabelScale', 'textOverflow'].join(' ')}>
                                    {ob.assessmentScaleMetricDescription}
                                  </div>
                                </div>
                              </div>
                            </Fragment>
                          );
                        })}
                      </div>
                    )}
                    {reviseClusterItemFram.length > 0 && isAssessmentSectionShow
                      ? reviseClusterItemFram.map((ff, clusterKey) => {
                          return (
                            <Fragment key={clusterKey}>
                              <div className={['innerpadding-old', 'innerheightAdjust'].join(' ')}>
                                <div className={['ex_container', 'itemcluster'].join(' ')}>
                                  {ff.assessmentClusterName}
                                </div>
                              </div>
                              <div>
                                {itemObect?.itemFrameworkOneSection.map((ob, keys) => {
                                  return (
                                    <Fragment key={keys}>
                                      <SubSectionItemPreview
                                        // ref={node}
                                        keys={keys}
                                        ob={ob}
                                        sleeee={
                                          itemObect?.assesseeAssignmentAssessmentItemResponseChoiceSelected
                                        }
                                        reviewReviseMode={reviewReviseMode}
                                        dispatch={dispatch}
                                        handleRadioButton={handleRadioButton}
                                        isAssessmentPreviewShow={isAssessmentPreviewShow}
                                        isAssessmentSectionShow={isAssessmentSectionShow}
                                        handleRadioLikertButton={handleRadioLikertButton}
                                        clusterInfo={ff}
                                        clusterKey={clusterKey}
                                        itemObect={itemObect?.itemFrameworkOneSection}
                                        responseAlignment={responseAlignment}
                                        itemFrameworkOneType={itemObect?.itemFrameworkOneType}
                                        assessmentScale={
                                          assessmentScale.length > 0 ? assessmentScale : []
                                        }
                                      />
                                    </Fragment>
                                  );
                                })}
                              </div>
                            </Fragment>
                          );
                        })
                      : itemObect?.itemFrameworkOneSection.map((ob, keys) => {
                          if (
                            itemObect?.assesseeAssignmentAssessmentItemResponseChoiceSelected &&
                            itemObect?.itemFrameworkOneSection.length === 1
                          ) {
                            ob.itemFrameworkOne.itemFrameworkOneResponseCorrect =
                              itemObect?.assesseeAssignmentAssessmentItemResponseChoiceSelected;
                          }
                          return (
                            <Fragment key={keys}>
                              <SubSectionItemPreview
                                // ref={node}
                                keys={keys}
                                ob={ob}
                                reviewReviseMode={reviewReviseMode}
                                dispatch={dispatch}
                                handleRadioButton={handleRadioButton}
                                isAssessmentPreviewShow={isAssessmentPreviewShow}
                                isAssessmentSectionShow={isAssessmentSectionShow}
                                itemObect={itemObect?.itemFrameworkOneSection}
                                responseAlignment={responseAlignment}
                                itemFrameworkOneType={itemObect?.itemFrameworkOneType}
                                assessmentScale={assessmentScale.length > 0 ? assessmentScale : []}
                              />
                            </Fragment>
                          );
                        })}
                    <Fragment></Fragment>
                  </FormControl>
                </div>
              )}

              {/* response label */}
              {(assessmentSectionItemFrameworkOneResponseLabel &&
                assessmentSectionItemFrameworkOneResponseLabel && (
                  <div className={['innerpadding', 'innerheightAdjust'].join(' ')}>
                    <div
                      className={['ex_container', 'ig-label', 'cssClass4', 'assessmentScale'].join(
                        ' '
                      )}
                    >
                      <EditorTemplate
                        label={'itemFrameworkOneResponseLabel'}
                        jsonData={assessmentSectionItemFrameworkOneResponseLabel}
                      />
                    </div>
                  </div>
                )) ||
                (itemObect?.itemFrameworkOneResponseLabel?.itemFrameworkOneResponseLabelMedia &&
                  itemObect?.itemFrameworkOneResponseLabel?.itemFrameworkOneResponseLabelMedia && (
                    <div className={['innerpadding', 'innerheightAdjust'].join(' ')}>
                      <div
                        className={[
                          'ex_container',
                          'ig-label',
                          'cssClass4',
                          'assessmentScale'
                        ].join(' ')}
                      >
                        <EditorTemplate
                          label={'itemFrameworkOneResponseLabel'}
                          jsonData={
                            itemObect?.itemFrameworkOneResponseLabel
                              ?.itemFrameworkOneResponseLabelMedia
                          }
                        />
                      </div>
                    </div>
                  )) ||
                ''}
              {/* {itemObect?.itemFrameworkOneResponseLabel?.itemFrameworkOneResponseLabelMedia && (
                <div className={['innerpadding', 'innerheightAdjust'].join(' ')}>
                  <div className={['ex_container', 'ig-label', 'cssClass4'].join(' ')}>
                    <EditorTemplate
                      label={'itemFrameworkOneResponseLabel'}
                      jsonData={
                        itemObect?.itemFrameworkOneResponseLabel?.itemFrameworkOneResponseLabelMedia
                      }
                    />
                  </div>
                </div>
              )} */}

              {/* response */}
              {(itemObect?.itemFrameworkOneType === '61090cace50cf61d5eb440cd' ||
                itemObect?.itemFrameworkOneType === '61090cace50cf61d5eb440cc' ||
                itemObect?.itemFrameworkOneType === '61161713f24e1fb765208e23') && (
                <div className={['innerpadding', 'innerheightAdjust'].join(' ')}>
                  <div
                    className={['ex_container', 'cssClass5', 'assessmentScale'].join(' ')}
                    style={{
                      cursor: reviewReviseMode === 'revise' ? 'pointer' : ''
                    }}
                    onClick={
                      reviewReviseMode === 'revise'
                        ? () => {
                            dispatch({
                              type: SET_POPUP_VALUE,
                              payload: {
                                isPopUpValue: 'ASSESSMENT_RESPONSE_PRIMARY_POPUP',
                                popupMode: 'RESPONSE_SECONDARY_POPUP'
                              }
                            });
                          }
                        : null
                    }
                  >
                    {(!itemObect?.itemFrameworkOnePassage?.itemFrameworkOnePassageMedia &&
                      reviewMode === 'revise' &&
                      ReactHTMLParser(responseText)) || (
                      <Fragment>
                        <div className={[`iguru-editor`].join(' ')}>
                          <EditorJs
                            key={innerContent}
                            instanceRef={(instance) => (instanceRef.current = instance)}
                            data={innerContent}
                            onChange={handleEditorChange}
                            tools={{
                              paragraph: {
                                config: {
                                  placeholder: 'Type here...'
                                },
                                className: Paragraph
                              }
                            }}
                          />
                        </div>
                      </Fragment>

                      // <Input
                      //   row={4}
                      //   rowsMax={8}
                      //   // className={'inputText'}
                      //   id="name-dn-input"
                      //   defaultValue={''}
                      // />
                    )}
                  </div>
                </div>
              )}

              {/* responce explanation */}
              {itemObect?.itemFrameworkOneResponseExplanation
                ?.itemFrameworkOneResponseExplanationMedia && (
                <div className={['innerpadding', 'innerheightAdjust'].join(' ')}>
                  <div
                    className={[
                      'ex_container',
                      'ig-explanation ',
                      'cssClass6',
                      'assessmentScale'
                    ].join(' ')}
                  >
                    <EditorTemplate
                      jsonData={
                        itemObect?.itemFrameworkOneResponseExplanation
                          ?.itemFrameworkOneResponseExplanationMedia
                      }
                      label={'itemFrameworkOneResponseExplanationMedia'}
                    />
                  </div>
                </div>
              )}

              {/* response choices */}
              {(itemObect?.itemFrameworkOneType === '61090cace50cf61d5eb440ce' ||
                itemObect?.itemFrameworkOneType === '61161713f24e1fb765208e23') &&
                itemObect?.itemFrameworkOneResponseChoice && (
                  <div
                    className={['innerheightAdjust', 'rating-scale-container'].join(' ')}
                    style={{ display: responseAlignment === 'vertical' ? 'block' : 'grid' }}
                  >
                    <div style={{ gridColumn: `span ${2} / span ${2}` }}></div>
                    {itemObect?.itemFrameworkOneResponseChoice &&
                      itemObect?.itemFrameworkOneResponseChoice.map((op, key) => {
                        return (
                          <Fragment key={key}>
                            {op.itemFrameworkOneResponseChoiceMedia !== '' && (
                              <div
                                style={{
                                  gridColumn: `span ${2} / span ${2}`
                                }}
                              >
                                <div
                                  key={`op-${key}`}
                                  className={['innerpadding', 'innerheightAdjust'].join(' ')}
                                >
                                  <div
                                    className={[
                                      'option-container',
                                      'ex_container',
                                      'ig-itemGeneric',
                                      'cssClass7'
                                    ].join(' ')}
                                    style={{
                                      alignItems: 'flex-start'
                                    }}
                                    key={`option-${key}`}
                                  >
                                    <div className={'radio-option-right'}>
                                      <input
                                        type="radio"
                                        data-key="333"
                                        name="option1"
                                        style={{ cursor: 'pointer' }}
                                        value={`${op.itemFrameworkOneResponseChoiceNumber}`}
                                        onChange={
                                          isAssessmentPreviewShow ||
                                          isAssessmentSectionShow ||
                                          isItemTrial
                                            ? null
                                            : (e) => {
                                                handleRadioButton(e);
                                              }
                                        }
                                        checked={
                                          ((isAssessmentPreviewShow ||
                                            isAssessmentSectionShow ||
                                            isAssessmentVersionShow) &&
                                            itemObect?.itemFrameworkOneResponseCorrect?.length >
                                              0 &&
                                            itemObect?.itemFrameworkOneResponseCorrect.includes(
                                              op.itemFrameworkOneResponseChoiceNumber
                                            )) ||
                                          // (isItemTrial && false) ||
                                          currentQuestionChoice ===
                                            op.itemFrameworkOneResponseChoiceNumber
                                        }
                                      />
                                      {(itemObect?.itemFrameworkOneResponseChoiceSequenceAdministration && (
                                        <span className={'responselabel'}>
                                          {
                                            itemObect
                                              ?.itemFrameworkOneResponseChoiceSequenceAdministration[
                                              key
                                            ]
                                          }
                                        </span>
                                      )) || (
                                        <span className={'responselabel'}>
                                          {
                                            itemFrameworkOneResponseChoiceSequenceAdministration[
                                              key
                                            ]
                                          }
                                        </span>
                                      )}
                                      {/* {itemObect?.itemFrameworkOneResponseChoiceSequenceAdministration && (
                                        <span className={'responselabel'}>
                                          {
                                            itemObect
                                              ?.itemFrameworkOneResponseChoiceSequenceAdministration[
                                            key
                                            ]
                                          }
                                        </span>
                                      )}                                       */}
                                    </div>

                                    <div
                                      className={['', 'assessmentScale'].join(' ')}
                                      style={{
                                        paddingLeft: '5px',
                                        cursor: 'pointer'
                                      }}
                                      onClick={
                                        reviewReviseMode === 'revise'
                                          ? () => {
                                              dispatch({
                                                type: SET_POPUP_VALUE,
                                                payload: {
                                                  isPopUpValue:
                                                    'ASSESSMENTITEM_OPTION_PRIMARY_POPUP',
                                                  popupMode: `OPTION_${key}`
                                                }
                                              });
                                              setSelectedChoiceObject(op);
                                            }
                                          : isAssessmentPreviewShow ||
                                            isAssessmentSectionShow ||
                                            isItemTrial
                                          ? null
                                          : (e) => {
                                              handleRadioButton(
                                                e,
                                                op.itemFrameworkOneResponseChoiceNumber,
                                                'option1'
                                              );
                                            }
                                      }
                                    >
                                      <EditorTemplate
                                        jsonData={op.itemFrameworkOneResponseChoiceMedia}
                                        label={'itemFrameworkOneResponseChoiceMedia'}
                                      />
                                    </div>
                                  </div>
                                </div>
                                {op.itemFrameworkOneResponseChoiceExplanation
                                  ?.itemFrameworkOneResponseChoiceExplanationMedia && (
                                  <div
                                    key={`op-${key}`}
                                    className={['innerpadding', 'innerheightAdjust'].join(' ')}
                                  >
                                    <div
                                      className={['', 'assessmentScale'].join(' ')}
                                      style={{
                                        paddingLeft: '35px',
                                        display: 'inline-block'
                                      }}
                                    >
                                      {op.itemFrameworkOneResponseChoiceExplanation
                                        ?.itemFrameworkOneResponseChoiceExplanationMedia && (
                                        <div
                                          style={{ paddingLeft: '25px', display: 'inline-block' }}
                                        >
                                          <div
                                            className={[
                                              'ex_container',
                                              'ig-explanation ,cssClass8'
                                            ].join(' ')}
                                          >
                                            <EditorTemplate
                                              jsonData={
                                                op.itemFrameworkOneResponseChoiceExplanation
                                                  ?.itemFrameworkOneResponseChoiceExplanationMedia
                                              }
                                              label={
                                                'itemFrameworkOneResponseChoiceExplanationMedia'
                                              }
                                            />
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </Fragment>
                        );
                      })}
                  </div>
                )}
              <div>
                <div
                  style={{
                    height: '55px'
                  }}
                ></div>
              </div>
            </Fragment>
          )}
        </div>
      </div>

      {isPopUpValue === 'ITEM_TRIPLE_DOT_PRIMARY_POPUP' && (
        <Popup isActive={true}>
          <PopupHeader
            headerPanelColour={'genericOne'}
            headerOne={'item'}
            headerOneBadgeOne={''}
            onClick={BackHandlerEvent}
            mode={'revise'}
          />
          <DialogContent className={['popupContent', 'fixed05PadDim'].join(' ')}>
            <JsonRenderComponent
              setSecondaryOptionValue={setSecondaryOptionValue}
              ChangeOptionPopup={ChangeTripleDotOptionPopup}
              currentPopUpOption={getPopUpOptionList(itemPrimaryTriplePopupOption , signedAssesseePermission?.assessmentDistictPermission?.assesseeAssessmentDistinctPermission)}
              secondaryOptionCheckValue={''}
            />
          </DialogContent>
        </Popup>
      )}
      {isPopUpValue === 'ASSESSMENT_SUB_ITEM_SCALE_POPUP' && (
        <Popup isActive={true}>
          <PopupHeader
            headerPanelColour={'genericOne'}
            headerOne={'item'}
            headerOneBadgeOne={'scale'}
            onClick={BackHandlerEvent}
            mode={'revise'}
          />
          <DialogContent className={['popupContent', 'fixed05PadDim'].join(' ')}>
            <JsonRenderComponent
              setSecondaryOptionValue={setSecondaryOptionValue}
              ChangeOptionPopup={ChangeScaleConfigurePopup}
              currentPopUpOption={itemPrimaryPopupOption}
              secondaryOptionCheckValue={''}
            />
          </DialogContent>
        </Popup>
      )}
      {(isPopUpValue === 'ITEM_PRIMARY_POPUP' ||
        isPopUpValue === 'ASSESSMENT_SUB_ITEM_PRIMARY_POPUP') && (
        <Popup isActive={true}>
          <PopupHeader
            headerPanelColour={'genericOne'}
            headerOne={'item'}
            headerOneBadgeOne={''}
            onClick={BackHandlerEvent}
            mode={'revise'}
          />
          <DialogContent className={['popupContent', 'fixed05PadDim'].join(' ')}>
            <JsonRenderComponent
              setSecondaryOptionValue={setSecondaryOptionValue}
              ChangeOptionPopup={
                isPopUpValue === 'ASSESSMENT_SUB_ITEM_PRIMARY_POPUP'
                  ? ChangeOptionPopup
                  : ChangeItemOptionPopup
              }
              currentPopUpOption={itemPrimaryPopupOption}
              secondaryOptionCheckValue={''}
            />
          </DialogContent>
        </Popup>
      )}

      {isPopUpValue === 'ASSESSMENT_ITEM_FRAMEWORK_POPUP' && (
        <PopUpItemFramework
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'item'}
          headerOneBadgeOne={'configuration'}
          nextPopUpValue={''}
          // inputHeader={'item'}
          // primaryheader={'configuration'}
          isItemFramework={true}
          mode={'revise'}
          itemFrameworkOne={itemObect}
          itemFrameworkOneResponseChoice={itemObect?.itemFrameworkOneResponseChoice}
          assessmentClusterArr={assessmentCluster && assessmentCluster}
          assessmentScaleArr={assessmentScaleArr && assessmentScaleArr}
          itemFrameworkOneResponseChoiceSequenceAdministrationArr={
            itemFrameworkOneResponseChoiceSequenceAdministration
          }
        />
      )}
      {isPopUpValue === 'ASSESSMENTITEM_OPTION_PRIMARY_POPUP' && (
        <Popup isActive={true}>
          <PopupHeader
            headerPanelColour={'genericOne'}
            headerOne={'response-choice'}
            //headerOneBadgeOne={'choice'}
            onClick={BackHandlerEvent}
            mode={'revise'}
          />
          <DialogContent className={['popupContent', 'fixed05PadDim'].join(' ')}>
            <JsonRenderComponent
              setSecondaryOptionValue={setSecondaryOptionValue}
              ChangeOptionPopup={ChangeResponseOptionPopup}
              currentPopUpOption={itemPrimaryPopupOption}
              secondaryOptionCheckValue={''}
            />
          </DialogContent>
        </Popup>
      )}
      {isPopUpValue === 'SCALE_FRAMEWORK_POPUP' && (
        <PopUpItemScaleFramework
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'item'}
          headerOneBadgeOne={'scale'}
          headerOneBadgeTwo={'configuration'}
          choiceOb={selectedChoiceObject}
          inputHeader={''}
          primaryheader={''}
          primaryheaderTwo={''}
          nextPopUpValue={''}
          mode={'revise'}
          scaleMetricId={parseInt(scaleMetricId[2])}
          isItemFramework={true}
          itemConfigStates={itemConfigStates}
          itemFrameworkOne={itemObect}
          assessmentClusterArr={assessmentCluster && assessmentCluster}
        />
      )}
      {isPopUpValue === 'SUB_ITEM_FRAMEWORK_POPUP' && (
        <PopUpItemFramework
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'item'}
          headerOneBadgeOne={''}
          headerOneBadgeTwo={'configuration'}
          choiceOb={selectedChoiceObject}
          inputHeader={''}
          primaryheader={''}
          primaryheaderTwo={''}
          nextPopUpValue={''}
          mode={'revise'}
          subQuestionId={
            data?.itemFrameworkOneTypeNameReference === 'Rating-Scale (Multiple-Select)' &&
            parseInt(subQuestionId[4]) + 1
          }
          subQuestionResponseChoiceId={
            data?.itemFrameworkOneTypeNameReference === 'Rating-Scale (Multiple-Select)' &&
            parseInt(subQuestionId[3]) + 1
          }
          isItemFramework={false}
          itemConfigStates={itemConfigStates}
          itemFrameworkOneResponseChoice={itemObect?.itemFrameworkOneResponseChoice || []}
          itemFrameworkOne={itemObect}
          assessmentClusterArr={assessmentCluster && assessmentCluster}
        />
      )}
      {isPopUpValue === 'ASSESSMENT_SUB_ITEM_FRAMEWORK_POPUP' && (
        <PopUpItemFramework
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'response-choice'}
          headerOneBadgeTwo={'configuration'}
          choiceOb={selectedChoiceObject}
          inputHeader={''}
          primaryheader={''}
          primaryheaderTwo={''}
          nextPopUpValue={''}
          mode={'revise'}
          subQuestionId={
            data?.itemFrameworkOneTypeNameReference === 'Rating-Scale (Multiple-Select)' &&
            parseInt(subQuestionId[4]) + 1
          }
          subQuestionResponseChoiceId={
            data?.itemFrameworkOneTypeNameReference === 'Rating-Scale (Multiple-Select)' &&
            parseInt(subQuestionId[3]) + 1
          }
          responseChoiceId={parseInt(subQuestionId[1])}
          isItemFramework={false}
          itemConfigStates={itemConfigStates}
          itemFrameworkOneResponseChoice={itemObect?.itemFrameworkOneResponseChoice || []}
          itemFrameworkOne={itemObect}
          assessmentClusterArr={assessmentCluster && assessmentCluster}
        />
      )}
      {isPopUpValue === 'ASSESSMENT_RESPONSE_PRIMARY_POPUP' && (
        <Popup isActive={true}>
          <PopupHeader
            headerPanelColour={'genericOne'}
            headerOne={'response'}
            headerOneBadgeOne={''}
            onClick={BackHandlerEvent}
            mode={'revise'}
          />
          <DialogContent className={['popupContent', 'fixed05PadDim'].join(' ')}>
            <JsonRenderComponent
              setSecondaryOptionValue={setSecondaryOptionValue}
              ChangeOptionPopup={ChangeResponsePopup}
              currentPopUpOption={[
                {
                  data: 'configure',
                  dataValue: 'configure',
                  dataKey: 'configureAPICall',
                  optionClass: 'optionPrimary',
                  divider: '',
                  disabled: false
                },
                {
                  data: 'revise',
                  dataValue: 'revise',
                  dataKey: 'reviseAPICall',
                  optionClass: 'optionPrimary',
                  divider: '',
                  disabled: true
                }
              ]}
              secondaryOptionCheckValue={''}
            />
          </DialogContent>
        </Popup>
      )}
      {isPopUpValue === 'ASSESSMENT_RESPONSE_CONFIGURE_POPUP' && (
        <PopUpItemConfig
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'response'}
          headerOneBadgeOne={''}
          nextPopUpValue={''}
          inputHeader={''}
          primaryheader={'configuration'}
          isItemFramework={'responseConfig'}
          mode={reviewMode}
          itemFrameworkOne={itemObect}
          // itemSelectedTypeName = {handleCallback}
        />
      )}
      {isPopUpValue === 'ASSESSMENT_CONFIGURE_POPUP' && (
        <PopUpItemConfig
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'item'}
          inputHeader={''}
          primaryheader={'information'}
          headerOneBadgeOne={''}
          isItemFramework={
            isAssessmentPreviewShow
              ? 'alignmentConfig'
              : isAssessmentVersionShow
              ? 'sequenceConfig'
              : ''
          }
          assessmentItemSequence={true}
          mode={reviewMode}
          itemFrameworkOne={itemObect}
          currentItemIndex={currentItemIndex}
          currentVersionIndex={currentVersionIndex}
          currentItemSequence={(currentItemResponse && currentItemResponse?.itemSequence) || 0}
        />
      )}
    </>
  );
};
export default DisplayPaneFiveAssessment;