import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import {
  CLEAR_ASSESSEE_INFO,
  CLEAR_ASSIGNMENT_INFO,
  CLEAR_ASSOCIATE_INFO,
  CLEAR_IGAUGE_REDUCER,
  GET_ASSESSEE_SIGN_IN_INFO,
  LOADER_START,
  POPUP_CLOSE,
  REFRESH_TOKENS,
  SET_ASSESSEE_GROUP_REDUCER_STATE,
  SET_ASSESSEE_TYPE_REDUCER_STATE,
  SET_ASSESSMENT_GROUP_REDUCER_STATE,
  SET_ASSESSMENT_TYPE_REDUCER_STATE,
  SET_ASSIGNMEMT_GROUP_REDUCER_STATE,
  SET_ASSIGNMENT_TYPE_REDUCER_STATE,
  SET_ASSOCIATE_GROUP_REDUCER_STATE,
  SET_ASSOCIATE_TYPE_REDUCER_STATE,
  SET_CULTURE_GROUP_REDUCER_STATE,
  SET_CULTURE_TYPE_REDUCER_STATE,
  SET_ITEM_GROUP_REDUCER_STATE,
  SET_ITEM_TYPE_REDUCER_STATE,
  SET_JOB_GROUP_REDUCER_STATE,
  SET_JOB_TYPE_REDUCER_STATE
} from '../../actionType';
import HeaderZero from '../../Molecules/HeaderZero/HeaderZero';
import './DisplayPageOne.css';
import DisplayPaneOne from '../../Organisms/DisplayPaneOne/DisplayPaneOne';
import DisplayPaneThree from '../../Organisms/DisplayPaneThree/DisplayPaneThree';
import DisplayPaneTwo from '../../Organisms/DisplayPaneTwo/DisplayPaneTwo';
import GridColumn from '../../Molecules/GridColumn/GridColumn';
import DisplayPaneSix from '../../Organisms/DisplayPaneSix/DisplayPaneSix';
import DisplayPaneSeven from '../../Organisms/DisplayPaneSeven/DisplayPaneSeven';
import LoadingComponent from '../../PopUpInformation/LoadingComponent';
import PicturePreview from '../../PopUpInformation/PicturePreview';
import PopUpDisplayPaneTwoReviewList from '../../PopUpDisplayPanel/PopUpDisplayPaneTwoReviewList';
import PopUpAssesseeCreateSignOn from '../../Molecules/PopUpCreate/PopUpAssesseeCreateSignOn';
import PopUpAssociateCreateSignOn from '../../Molecules/PopUpCreate/PopUpAssociateCreateSignOn';
import PopUpAssesseeRoleCreate from '../../Molecules/PopUpCreate/PopUpAssesseeRoleCreate';
import PopUpAssociateRoleCreate from '../../Molecules/PopUpCreate/PopUpAssociateRoleCreate';
import PopUpGroupCreate from '../../Molecules/PopUpCreate/PopUpGroupCreate';
import PopUpTypeCreate from '../../Molecules/PopUpCreate/PopUpTypeCreate';
import PopUpCultureProfileCreate from '../../Molecules/PopUpCreate/PopUpCultureProfileCreate';
import PopUpAssignmentCreate from '../../Molecules/PopUpCreate/PopUpAssignmentCreate';
import PopUpAssessmentCreate from '../../Molecules/PopUpCreate/PopUpAssessmentCreate';
import PopUpMessageError from '../../PopUpGeneric/PopUpMessageError';
import { useHistory, Redirect } from 'react-router-dom';
import PopUpNodeCreate from '../../Molecules/PopUpCreate/PopUpNodeCreate';
import PopUpDisplayPaneTwoTripleDot from '../../PopUpDisplayPanel/PopUpDisplayPaneTwoTripleDot';
import PopUpItemCreate from '../../Molecules/PopUpCreate/PopUpItemCreate';
import PopUpJobProfileCreate from '../../Molecules/PopUpCreate/PopUpJobProfileCreate';
import DisplayPaneFour from '../../Organisms/DisplayPaneFour/DisplayPaneFour';
import DisplayPaneFive from '../../Organisms/DisplayPaneFive/DisplayPaneFive';
import PopUpDisplayPanelAssesseeAssessment from '../../PopUpDisplayPanel/PopUpDisplayPanelAssesseeAssessment';
import PopUpItemFramework from '../../PopUpInformation/PopUpItemFramework';
import PopUpClusterCreate from '../../Molecules/PopUpCreate/PopUpClusterCreate';
import PopUpScaleCreate from '../../Molecules/PopUpCreate/PopUpScaleCreate';
import PopUpVersionCreate from '../../Molecules/PopUpCreate/PopUpVersionCreate';
import PopUpSectionCreate from '../../Molecules/PopUpCreate/PopUpSectionCreate';
//import { MathComponent } from 'mathjax-react'
import PopUpNormCreate from '../../Molecules/PopUpCreate/PopUpNormCreate';
import PopUpScoreCreate from '../../Molecules/PopUpCreate/PopUpScoreCreate';
import PopUpGradeCreate from '../../Molecules/PopUpCreate/PopUpGradeCreate';
import PopUpGaugeCreate from '../../Molecules/PopUpCreate/PopUpGaugeCreate';
import PopUpAssignmentReport from '../../Molecules/PopUpCreate/PopUpAssignmentReport';
import PopUpCultureJobProfilerCreate from '../../Molecules/PopUpCreate/PopUpCultureJobProfilerCreate';
import PopUpAssesseeReport from '../../Molecules/PopUpCreate/PopUpAssesseeReport';
import PopUpDisplayPaneThreePanelAssessee from '../../PopUpDisplayPanel/PopUpDisplayPaneThreePanelAssessee';
import { SIGN_IN_URL, STAGE_LITE_URL } from '../../endpoints';

// import { useHistory } from 'react-router-dom';

const DisplayPageOne = () => {
  const { gridColumnCountValue } = useSelector((state) => state.PopUpReducer);
  const { isDisplayPaneSixShow } = useSelector((state) => state.AssessmentReducer);
  const { isLoading } = useSelector((state) => state.LoaderReducer);
  const { mobilePanestate } = useSelector((state) => state.DisplayPaneTwoReducer);
  const {
    isItemPreviewShow = false,
    isAssessmentPreviewShow = false,
    isAssessmentSectionShow = false,
    isAssessmentVersionShow = false
  } = useSelector((state) => state.DisplayPaneThreeReducer);

  const { isExamMode, isAssessmentStart } = useSelector(
    (state) => state.AssesseeAssignmentAssessmentReducer
  );
  const dispatch = useDispatch();

  const history = useHistory();
  const { assesseeConfirmStatus, assesseePermissionLoaded } = useSelector((state) => state.UserReducer);
  useEffect(() => {
    if (assesseeConfirmStatus === 'passwordReviseSuccess') {
      let path = SIGN_IN_URL;
      history.push(path);
    }
  }, [assesseeConfirmStatus]);

  const assesseeId = localStorage.getItem('assesseeId');
  const accessToken = localStorage.getItem('token');
  const refreshToken = localStorage.getItem('refreshToken');
  const {
    assesseeGroup,
    assessmentGroup,
    assignmentGroup,
    associateGroup,
    itemGroup,
    cultureProfileGroup,
    jobProfileGroup
  } = useSelector((state) => state.GroupCreateReducer);
  const {
    assesseeType,
    assessmentType,
    assignmentType,
    associateType,
    itemType,
    cultureProfileType,
    jobProfileType
  } = useSelector((state) => state.TypeCreateReducer);

  useEffect(() => {
    dispatch({ type: LOADER_START });
    dispatch({
      type: GET_ASSESSEE_SIGN_IN_INFO,
      payload: {
        request: {
          module: 'Iguru-Iglobal-Sign-In',
          action: 'Refresh',
          requestObject: {
            accessToken,
            assesseeId,
            refreshToken,
            credential: assesseeId
          }
        }
      }
    });
  }, []);
  // useEffect(() => {
  //   let minutes = 1000 * 60
  //   setInterval(() => {
  //     dispatch({
  //       type: GET_ASSESSEE_SIGN_IN_INFO,
  //       payload: {
  //         request: {
  //           module: 'Iguru-Iglobal-Sign-In',
  //           action: 'Refresh',
  //           requestObject: {
  //             assesseeId,
  //             refreshToken,
  //             credential: assesseeId
  //           }
  //         },
  //         getRefreshToken: true,
  //       }
  //     });
  //   }, minutes * 1.2);
  // }, []);

  useEffect(() => {
    let minutes = 1000 * 60;
    setInterval(() => {
      dispatch({ type: LOADER_START });
      dispatch({
        type: REFRESH_TOKENS,
        payload: {
          request: {
            module: 'Iguru-Iglobal-Sign-In',
            action: 'Refresh',
            requestObject: {
              assesseeId,
              refreshToken,
              credential: assesseeId
            }
          },
          getRefreshToken: true
        }
      });
    }, minutes * 19);
  }, []);

  const { leftPaneAssesseeInfo, signedAssesseePermission, selectedAssociateInfo
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { isPopUpValue, popupMode, popupHeaderOne } = useSelector((state) => state.PopUpReducer);
  const userName = leftPaneAssesseeInfo
    ? leftPaneAssesseeInfo?.assessee?.informationBasic?.assesseeNameFirst.trim() +
    ' ' +
    leftPaneAssesseeInfo?.assessee?.informationBasic?.assesseeNameLast.trim()
    : '';
  const userEmail = assesseeId || '';

  useEffect(() => {

    if (signedAssesseePermission?.hasOwnProperty("assesseeRoles")) {

      const assesseeId = selectedAssociateInfo.assesseeId;
      const cred = localStorage.getItem('assesseeId');
      const associateId = selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary;

      const assesseeAssistantRole = signedAssesseePermission?.assesseeRoles?.assesseeAssistantRole
      const assesseeMangerRole = signedAssesseePermission?.assesseeRoles?.assesseeMangerRole
      const assesseeAdminRole = signedAssesseePermission?.assesseeRoles?.assesseeAdminRole

      if (!assesseeAssistantRole && !assesseeMangerRole && !assesseeAdminRole) {
        window.location.replace(`${STAGE_LITE_URL}/nopopup?token=${refreshToken}&aseeId=${assesseeId}&assoId=${associateId}&cred=${cred}`);
      }
    }

  }, [JSON.stringify(signedAssesseePermission)])

  const popupAllClose = () => {
    dispatch({ type: CLEAR_ASSESSEE_INFO });
    // dispatch({ type: CLEAR_ASSESSMENT_INFO });
    dispatch({ type: POPUP_CLOSE });
    dispatch({ type: CLEAR_ASSOCIATE_INFO });
    dispatch({ type: CLEAR_ASSIGNMENT_INFO });
    dispatch({ type: CLEAR_IGAUGE_REDUCER });
  };
  const { typeOfMiddlePaneList } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { isPreviewShow = false } = useSelector((state) => state.DisplayPaneThreeReducer);
  const { assesseeAssessmentStartData } = useSelector(
    (state) => state.AssesseeAssignmentAssessmentReducer
  );
  const assesseePicture = leftPaneAssesseeInfo?.assessee?.informationBasic?.assesseePicture;

  // console.log('isExamMode', isExamMode);
  // console.log('isMobile Mode', isMobile);
  // console.log('asssignmentStarted', asssignmentStarted);
  // console.log('print inside PageOne isAssessmentStart', isAssessmentStart);

  return (
    <div id="main-container">
      <HeaderZero
        userName={userName}
        userEmail={userEmail}
        imageOne={assesseePicture}
        isImageActive={assesseePicture ? true : false}
      />

      {gridColumnCountValue !== 0 ? (
        <GridColumn isExamMode={isExamMode} columnCount={gridColumnCountValue} />
      ) : null}
      <div className="main-container">
        {isMobile ? (
          <div className="display-pane-container">
            {mobilePanestate === 'displayPaneOne' && <DisplayPaneOne />}
            {mobilePanestate === 'displayPaneTwo' && (
              <>
                {isPreviewShow ? (
                  <>
                    <DisplayPaneFour />
                  </>
                ) : (
                  <DisplayPaneTwo popupAllClose={popupAllClose} />
                )}
              </>
            )}
            {mobilePanestate === 'displayPaneThree' && <DisplayPaneThree />}
            {mobilePanestate === 'displayPaneFour' && <DisplayPaneFour />}
            {mobilePanestate === 'displayPaneFive' && <DisplayPaneFive />}
            {mobilePanestate === 'displayPaneSix' && <DisplayPaneSix />}
            {mobilePanestate === 'displayPaneSeven' && <DisplayPaneSeven />}
          </div>
        ) : (
          <>
            {isExamMode ? (
              <>
                {/* {(asssignmentStarted === 'PROGRESS' && ( */}
                {(isAssessmentStart === 'PROGRESS' && (
                  <div style={{ width: isDisplayPaneSixShow ? '33.33%' : '4%' }}>
                    <DisplayPaneOne />
                  </div>
                )) || (
                    <div style={{ width: isDisplayPaneSixShow ? '33.33%' : '' }}>
                      <DisplayPaneSix />
                    </div>
                  )}
                <div
                  style={{
                    width: isDisplayPaneSixShow ? '66.66%' : '100%',
                    borderLeft: '1px solid rgba(0, 0, 0, 0.12)'
                  }}
                >
                  {(assesseeAssessmentStartData && <DisplayPaneSeven />) || null}
                </div>
                {/* } */}
              </>
            ) : isItemPreviewShow ||
              isAssessmentPreviewShow ||
              isAssessmentSectionShow ||
              isAssessmentVersionShow ? (
              <>
                {(isAssessmentStart === 'PROGRESS' && (
                  <div
                    style={{ width: isDisplayPaneSixShow ? '33.33%' : '4.5%' }}
                    className="main-container-vertical-right"
                  >
                    <DisplayPaneOne />
                  </div>
                )) || (
                    <div
                      style={{ width: isDisplayPaneSixShow ? '33.33%' : '' }}
                      className="main-container-vertical-right"
                    >
                      <DisplayPaneSix />
                    </div>
                  )}
                {/* <div style={{ width: isDisplayPaneSixShow ? '33.33%' : '4%' }}>
                  <DisplayPaneThree />
                  <DisplayPaneSix />
                </div>                 */}
                <div style={{ width: isDisplayPaneSixShow ? '66.66%' : '95.5%' }}>
                  <DisplayPaneFive />
                </div>
              </>
            ) : (
              <>
                <div className="display-pane-container">
                  {/* <DisplayPaneOne /> */}
                  {isAssessmentStart === '' || isAssessmentStart === 'ReviewListResume' ? (
                    <div>
                      <DisplayPaneOne />
                    </div>
                  ) : (
                    <div className="main-container-vertical-right">
                      <DisplayPaneSix />
                    </div>
                  )}
                  {/* <button onClick={changeUserName}>Change Username</button> */}
                </div>
                <span className="main-container-vertical-right"></span>
                <div className="display-pane-container">
                  <>
                    {isPreviewShow ? (
                      <>
                        <DisplayPaneFour />
                      </>
                    ) : (
                      <DisplayPaneTwo popupAllClose={popupAllClose} />
                    )}
                  </>
                </div>
                <span className="main-container-vertical-left"></span>
                <div className="display-pane-container">
                  <DisplayPaneThree />
                  {/* <>
                    {isPreviewShow ? (
                      <>
                        <DisplayPaneFour />
                      </>
                    ) : (
                      <DisplayPaneThree />
                    )}
                  </> */}
                </div>
              </>
            )}
          </>
        )}
      </div>

      {isPopUpValue === 'assesseeSelfPopup' && (
        <PopUpDisplayPaneThreePanelAssessee
          isActive={true}
          typeOfMiddlePaneList={typeOfMiddlePaneList}
          popupAllClose={popupAllClose}
        />
      )}
      {isPopUpValue === 'middlePaneListPopup' && (
        <PopUpDisplayPaneTwoReviewList
          isActive={true}
          typeOfMiddlePaneList={typeOfMiddlePaneList}
          popupAllClose={popupAllClose}
        />
      )}
      {isPopUpValue === 'paneSevenPopup' && (
        <PopUpDisplayPanelAssesseeAssessment
          isActive={true}
          typeOfMiddlePaneList={typeOfMiddlePaneList}
          popupAllClose={popupAllClose}
        />
      )}
      {isPopUpValue === 'middlePaneTrippleDotPopup' && (
        <PopUpDisplayPaneTwoTripleDot
          isActive={true}
          typeOfMiddlePaneList={typeOfMiddlePaneList}
          popupAllClose={popupAllClose}
        />
      )}
      {isPopUpValue === 'leftPaneTrippleDotPopup' && (
        <PopUpDisplayPaneTwoReviewList
          isActive={true}
          typeOfMiddlePaneList={typeOfMiddlePaneList}
          popupAllClose={popupAllClose}
          headerPanelColour={'displayPaneLeft'}
        />
      )}
      {popupMode === 'ASSESSEE_CREATE' && (
        <PopUpAssesseeCreateSignOn headerOne={popupHeaderOne ? popupHeaderOne : 'assessee'} />
      )}
      {popupMode === 'ADMINISTRATOR_CREATE' && (
        <PopUpAssesseeCreateSignOn headerOne={'administrator'} />
      )}
      {popupMode === 'MANAGER_CREATE' && <PopUpAssesseeCreateSignOn headerOne={'manager'} />}
      {popupMode === 'ASSISTANT_CREATE' && <PopUpAssesseeCreateSignOn headerOne={'assistant'} />}
      {popupMode === 'ASSOCIATE_CREATE' && <PopUpAssociateCreateSignOn />}
      {popupMode === 'assesseesROLECREATE' && (
        <PopUpAssesseeRoleCreate headerOne={popupHeaderOne ? popupHeaderOne : 'assessees'} />
      )}
      {popupMode === 'administratorsROLECREATE' && (
        <PopUpAssesseeRoleCreate headerOne={'administrators'} />
      )}
      {popupMode === 'managersROLECREATE' && <PopUpAssesseeRoleCreate headerOne={'managers'} />}
      {popupMode === 'assistantsROLECREATE' && <PopUpAssesseeRoleCreate headerOne={'assistants'} />}
      {popupMode === 'associatesROLECREATE' && <PopUpAssociateRoleCreate />}
      {popupMode === 'responseErrorMsg' && (
        <PopUpMessageError isActive errorMessage={isPopUpValue} />
      )}
      {popupMode === 'assesseesGROUPCREATE' && (
        <PopUpGroupCreate
          headerOne={popupHeaderOne ? popupHeaderOne : 'assessees'}
          reducerObeject={assesseeGroup}
          groupName={'assesseeGroupName'}
          groupNameVerification={'assesseeGroupNameVerification'}
          groupDescription={'assesseeGroupDescription'}
          groupPicture={'assesseeGroupPicture'}
          groupPictureVerification={'assesseeGroupPictureVerification'}
          setReducerObject={SET_ASSESSEE_GROUP_REDUCER_STATE}
          objectName={'assesseeGroup'}
          typeName={'assesseeTypeName'}
          typeDescription={'assesseeTypeDescription'}
          typeDefaultKey={'assesseeTypeDefault'}
        />
      )}
      {popupMode === 'administratorsGROUPCREATE' && (
        <PopUpGroupCreate
          headerOne={'administrators'}
          reducerObeject={assesseeGroup}
          groupName={'assesseeGroupName'}
          groupNameVerification={'assesseeGroupNameVerification'}
          groupDescription={'assesseeGroupDescription'}
          groupPicture={'assesseeGroupPicture'}
          groupPictureVerification={'assesseeGroupPictureVerification'}
          setReducerObject={SET_ASSESSEE_GROUP_REDUCER_STATE}
          objectName={'assesseeGroup'}
          typeName={'assesseeTypeName'}
          typeDescription={'assesseeTypeDescription'}
          typeDefaultKey={'assesseeTypeDefault'}
        />
      )}
      {popupMode === 'managersGROUPCREATE' && (
        <PopUpGroupCreate
          headerOne={'managers'}
          reducerObeject={assesseeGroup}
          groupName={'assesseeGroupName'}
          groupNameVerification={'assesseeGroupNameVerification'}
          groupDescription={'assesseeGroupDescription'}
          groupPicture={'assesseeGroupPicture'}
          groupPictureVerification={'assesseeGroupPictureVerification'}
          setReducerObject={SET_ASSESSEE_GROUP_REDUCER_STATE}
          objectName={'assesseeGroup'}
          typeName={'assesseeTypeName'}
          typeDescription={'assesseeTypeDescription'}
          typeDefaultKey={'assesseeTypeDefault'}
        />
      )}
      {popupMode === 'assistantsGROUPCREATE' && (
        <PopUpGroupCreate
          headerOne={'assistants'}
          reducerObeject={assesseeGroup}
          groupName={'assesseeGroupName'}
          groupNameVerification={'assesseeGroupNameVerification'}
          groupDescription={'assesseeGroupDescription'}
          groupPicture={'assesseeGroupPicture'}
          groupPictureVerification={'assesseeGroupPictureVerification'}
          setReducerObject={SET_ASSESSEE_GROUP_REDUCER_STATE}
          objectName={'assesseeGroup'}
          typeName={'assesseeTypeName'}
          typeDescription={'assesseeTypeDescription'}
          typeDefaultKey={'assesseeTypeDefault'}
        />
      )}
      {popupMode === 'assessmentsGROUPCREATE' && (
        <PopUpGroupCreate
          headerOne={'assessments'}
          reducerObeject={assessmentGroup}
          groupName={'assessmentGroupName'}
          groupDescription={'assessmentGroupDescription'}
          groupNameVerification={'assessmentGroupNameVerification'}
          groupPicture={'assessmentGroupPicture'}
          groupPictureVerification={'assessmentGroupPictureVerification'}
          setReducerObject={SET_ASSESSMENT_GROUP_REDUCER_STATE}
          objectName={'assessmentGroup'}
          typeName={'assessmentTypeName'}
          typeDescription={'assessmentTypeDescription'}
          typeDefaultKey={'assessmentTypeDefault'}
        />
      )}
      {popupMode === 'assignmentsGROUPCREATE' && (
        <PopUpGroupCreate
          headerOne={'assignments'}
          reducerObeject={assignmentGroup}
          groupName={'assignmentGroupName'}
          groupDescription={'assignmentGroupDescription'}
          groupNameVerification={'assignmentGroupNameVerification'}
          groupPicture={'assignmentGroupPicture'}
          groupPictureVerification={'assignmentGroupPictureVerification'}
          setReducerObject={SET_ASSIGNMEMT_GROUP_REDUCER_STATE}
          objectName={'assignmentGroup'}
          typeName={'assignmentTypeName'}
          typeDescription={'assignmentTypeDescription'}
          typeDefaultKey={'assignmentTypeDefault'}
        />
      )}
      {popupMode === 'associatesGROUPCREATE' && (
        <PopUpGroupCreate
          headerOne={'associates'}
          reducerObeject={associateGroup}
          groupName={'associateGroupName'}
          groupDescription={'associateGroupDescription'}
          groupNameVerification={'associateGroupNameVerification'}
          groupPicture={'associateGroupPicture'}
          groupPictureVerification={'associateGroupPictureVerification'}
          setReducerObject={SET_ASSOCIATE_GROUP_REDUCER_STATE}
          objectName={'associateGroup'}
          typeName={'associateTypeName'}
          typeDescription={'associateTypeDescription'}
          typeDefaultKey={'associateTypeDefault'}
        />
      )}
      {popupMode === 'itemsGROUPCREATE' && (
        <PopUpGroupCreate
          headerOne={'items'}
          reducerObeject={itemGroup}
          groupName={'itemGroupName'}
          groupDescription={'itemGroupDescription'}
          groupNameVerification={'itemGroupNameVerification'}
          groupPicture={'itemGroupPicture'}
          groupPictureVerification={'itemGroupPictureVerification'}
          setReducerObject={SET_ITEM_GROUP_REDUCER_STATE}
          objectName={'itemGroup'}
          typeName={'itemTypeName'}
          typeDescription={'itemTypeDescription'}
          typeDefaultKey={'itemTypeDefault'}
        />
      )}
      {popupMode === 'culture-profilesGROUPCREATE' && (
        <PopUpGroupCreate
          headerOne={'culture-profiles'}
          reducerObeject={cultureProfileGroup}
          groupName={'cultureProfileGroupName'}
          groupDescription={'cultureProfileGroupDescription'}
          groupNameVerification={'cultureProfileGroupNameVerification'}
          groupPicture={'cultureProfileGroupPicture'}
          groupPictureVerification={'cultureProfileGroupPictureVerification'}
          setReducerObject={SET_CULTURE_GROUP_REDUCER_STATE}
          objectName={'cultureProfileGroup'}
          typeName={'cultureProfileTypeName'}
          typeDescription={'cultureProfileTypeDescription'}
          typeDefaultKey={'cultureProfileTypeDefault'}
        />
      )}
      {popupMode === 'job-profilesGROUPCREATE' && (
        <PopUpGroupCreate
          headerOne={'job-profiles'}
          reducerObeject={jobProfileGroup}
          groupName={'jobProfileGroupName'}
          groupDescription={'jobProfileGroupDescription'}
          groupNameVerification={'jobProfileGroupNameVerification'}
          groupPicture={'jobProfileGroupPicture'}
          groupPictureVerification={'jobProfileGroupPictureVerification'}
          setReducerObject={SET_JOB_GROUP_REDUCER_STATE}
          objectName={'jobProfileGroup'}
          typeName={'jobProfileTypeName'}
          typeDescription={'jobProfileTypeDescription'}
          typeDefaultKey={'jobProfileTypeDefault'}
        />
      )}
      {popupMode === 'assesseesTYPECREATE' && (
        <PopUpTypeCreate
          headerOne={popupHeaderOne ? popupHeaderOne : 'assessees'}
          reducerObeject={assesseeType}
          typeName={'assesseeTypeName'}
          typeNameVerification={'assesseeTypeNameVerification'}
          typeDescription={'assesseeTypeDescription'}
          setReducerObject={SET_ASSESSEE_TYPE_REDUCER_STATE}
          objectName={'assesseeType'}
          allocationObj={'assesseeTypeGroup'}
          typePicture={'assesseeTypePicture'}
          typePictureVerification={'assesseeTypePictureVerification'}
        />
      )}
      {popupMode === 'administratorsTYPECREATE' && (
        <PopUpTypeCreate
          headerOne={'administrators'}
          reducerObeject={assesseeType}
          typeName={'assesseeTypeName'}
          typeNameVerification={'assesseeTypeNameVerification'}
          typeDescription={'assesseeTypeDescription'}
          setReducerObject={SET_ASSESSEE_TYPE_REDUCER_STATE}
          objectName={'assesseeType'}
          allocationObj={'assesseeTypeGroup'}
          typePicture={'assesseeTypePicture'}
          typePictureVerification={'assesseeTypePictureVerification'}
        />
      )}
      {popupMode === 'managersTYPECREATE' && (
        <PopUpTypeCreate
          headerOne={'managers'}
          reducerObeject={assesseeType}
          typeName={'assesseeTypeName'}
          typeNameVerification={'assesseeTypeNameVerification'}
          typeDescription={'assesseeTypeDescription'}
          setReducerObject={SET_ASSESSEE_TYPE_REDUCER_STATE}
          objectName={'assesseeType'}
          allocationObj={'assesseeTypeGroup'}
          typePicture={'assesseeTypePicture'}
          typePictureVerification={'assesseeTypePictureVerification'}
        />
      )}
      {popupMode === 'assistantsTYPECREATE' && (
        <PopUpTypeCreate
          headerOne={'assistants'}
          reducerObeject={assesseeType}
          typeName={'assesseeTypeName'}
          typeNameVerification={'assesseeTypeNameVerification'}
          typeDescription={'assesseeTypeDescription'}
          setReducerObject={SET_ASSESSEE_TYPE_REDUCER_STATE}
          objectName={'assesseeType'}
          allocationObj={'assesseeTypeGroup'}
          typePicture={'assesseeTypePicture'}
          typePictureVerification={'assesseeTypePictureVerification'}
        />
      )}
      {popupMode === 'assessmentsTYPECREATE' && (
        <PopUpTypeCreate
          headerOne={'assessments'}
          reducerObeject={assessmentType}
          typeName={'assessmentTypeName'}
          typeDescription={'assessmentTypeDescription'}
          setReducerObject={SET_ASSESSMENT_TYPE_REDUCER_STATE}
          objectName={'assessmentType'}
          allocationObj={'assessmentTypeGroup'}
          groupName={'assessmentTypeGroupName'}
          groupDescription={'assessmentTypeGroupDescription'}
          typeNameVerification={'assessmentTypeNameVerification'}
          typePicture={'assessmentTypePicture'}
          typePictureVerification={'assessmentTypePictureVerification'}
        />
      )}
      {popupMode === 'assignmentsTYPECREATE' && (
        <PopUpTypeCreate
          headerOne={'assignments'}
          reducerObeject={assignmentType}
          typeName={'assignmentTypeName'}
          typeDescription={'assignmentTypeDescription'}
          setReducerObject={SET_ASSIGNMENT_TYPE_REDUCER_STATE}
          objectName={'assignmentType'}
          allocationObj={'assignmentTypeGroup'}
          groupName={'assignmentTypeGroupName'}
          groupDescription={'assignmentTypeGroupDescription'}
          typeNameVerification={'assignmentTypeNameVerification'}
          typePicture={'assignmentTypePicture'}
          typePictureVerification={'assignmentTypePictureVerification'}
        />
      )}
      {popupMode === 'associatesTYPECREATE' && (
        <PopUpTypeCreate
          headerOne={'associates'}
          reducerObeject={associateType}
          typeName={'associateTypeName'}
          typeDescription={'associateTypeDescription'}
          setReducerObject={SET_ASSOCIATE_TYPE_REDUCER_STATE}
          objectName={'associateType'}
          allocationObj={'associateTypeGroup'}
          groupName={'associateTypeGroupName'}
          groupDescription={'associateTypeGroupDescription'}
          typeNameVerification={'associateTypeNameVerification'}
          typePicture={'associateTypePicture'}
          typeStatus={'associateTypeStatus'}
          typePictureVerification={'associateTypePictureVerification'}
        />
      )}
      {popupMode === 'itemsTYPECREATE' && (
        <PopUpTypeCreate
          headerOne={'items'}
          reducerObeject={itemType}
          typeName={'itemTypeName'}
          typeDescription={'itemTypeDescription'}
          setReducerObject={SET_ITEM_TYPE_REDUCER_STATE}
          objectName={'itemType'}
          allocationObj={'itemTypeGroup'}
          groupName={'itemTypeGroupName'}
          groupDescription={'itemTypeGroupDescription'}
          typeNameVerification={'itemTypeNameVerification'}
          typePicture={'itemTypePicture'}
          typePictureVerification={'itemTypePictureVerification'}
        />
      )}
      {popupMode === 'culture-profilesTYPECREATE' && (
        <PopUpTypeCreate
          headerOne={'culture-profiles'}
          reducerObeject={cultureProfileType}
          typeName={'cultureProfileTypeName'}
          typeDescription={'cultureProfileTypeDescription'}
          setReducerObject={SET_CULTURE_TYPE_REDUCER_STATE}
          objectName={'cultureProfileType'}
          allocationObj={'cultureProfileTypeGroup'}
          groupName={'cultureProfileTypeGroupName'}
          groupDescription={'cultureProfileTypeGroupDescription'}
          typeNameVerification={'cultureProfileTypeNameVerification'}
          typePicture={'cultureProfileTypePicture'}
          typePictureVerification={'cultureProfileTypePictureVerification'}
        />
      )}
      {popupMode === 'job-profilesTYPECREATE' && (
        <PopUpTypeCreate
          headerOne={'job-profiles'}
          reducerObeject={jobProfileType}
          typeName={'jobProfileTypeName'}
          typeDescription={'jobProfileTypeDescription'}
          setReducerObject={SET_JOB_TYPE_REDUCER_STATE}
          objectName={'jobProfileType'}
          allocationObj={'jobProfileTypeGroup'}
          groupName={'jobProfileTypeGroupName'}
          groupDescription={'jobProfileTypeGroupDescription'}
          typeNameVerification={'jobProfileTypeNameVerification'}
          typePicture={'jobProfileTypePicture'}
          typePictureVerification={'jobProfileTypePictureVerification'}
        />
      )}
      {popupMode === 'CULTURECREATE' && <PopUpCultureProfileCreate headerOne={'culture-profile'} />}
      {popupMode === 'JOBCREATE' && <PopUpJobProfileCreate headerOne={'job-profile'} />}
      {popupMode === 'NODECREATE' && <PopUpNodeCreate headerOne={'associate'} />}
      {popupMode === 'ASSIGNMENTCREATE' && <PopUpAssignmentCreate headerOne={'assignment'} />}
      {popupMode === 'ASSESSMENTCREATE' && <PopUpAssessmentCreate headerOne={'assessment'} />}
      {popupMode === 'ITEMCREATE' && <PopUpItemCreate />}
      {popupMode === 'CLUSTERCREATE' && <PopUpClusterCreate headerOne={'assessment'} />}
      {popupMode === 'SECTIONCREATE' && <PopUpSectionCreate headerOne={'assessment'} />}
      {popupMode === 'SCALECREATE' && <PopUpScaleCreate headerOne={'assessment'} />}
      {popupMode === 'VERSIONCREATE' && <PopUpVersionCreate headerOne={'assessment'} />}
      {popupMode === 'NORMCREATE' && <PopUpNormCreate headerOne={'assessment'} />}
      {popupMode === 'SCORECREATE' && <PopUpScoreCreate headerOne={'assessment'} />}
      {popupMode === 'GRADECREATE' && <PopUpGradeCreate headerOne={'assessment'} />}
      {popupMode === 'GUAGECREATE' && <PopUpGaugeCreate headerOne={'iGauge Occupation'} />}
      {popupMode === 'GUAGEMETRICCREATE' && <PopUpGaugeCreate headerOne={'iGauge Occupation'} />}
      {popupMode === 'ASSIGNMENT_REPORT_REVIEW' && <PopUpAssignmentReport />}
      {popupMode === 'ASSESSEE_REPORT_REVISE' && <PopUpAssesseeReport />}
      {popupMode === 'CULTUREJOBPROFILERCREATE' && <PopUpCultureJobProfilerCreate />}
      {isPopUpValue === 'ITEM_FRAMEWORK_POPUP' && (
        <PopUpItemFramework
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'item'}
          headerOneBadgeOne={'configuration'}
          nextPopUpValue={''}
          // inputHeader={'item'}
          // primaryheader={'configuration'}
          isItemFramework={true}
          mode={'revise'}
        />
      )}
      <LoadingComponent isActive={isLoading} />
      {popupMode === 'IMAGEPREVIEW' && (
        <PicturePreview isActive={true} imageOne={isPopUpValue} dispatch={dispatch} />
      )}
    </div>
  );
};

export default DisplayPageOne;
