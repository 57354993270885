import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReviewList from '../Molecules/ReviewList/ReviewList';
import ListLoader from '../Atoms/ListLoader/ListLoader';
import { REPORT_URL } from '../endpoints';
import { FilterList } from '@material-ui/icons';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import { ASSESSEE_SELF_REPORTS, FILTERMODE, LOADER_START, SET_DISPLAY_TWO_SINGLE_STATE, SET_PAGE_COUNT } from '../actionType';
const AssesseeSelfReportReviewList = (props) => {


    const dispatch = useDispatch()
    const {
        sectionCreateType,
        middlePaneSelectedValue,
        reviewListDistinctData,
        relatedReviewListDistinctData,
        selectedAssociateInfo,
        assignmentReportsData,
        currentRevisedAssignmentType,
        middlePaneHeaderDuplicate,
        middlePaneHeaderBadgeOne,
        middlePaneHeaderBadgeTwo,
        middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderDuplicateBadgeTwo,
        middlePaneHeaderDuplicateBadgeThree,
        middlePaneHeaderDuplicateBadgeFour,
        middlePaneHeaderDuplicateOne,
        middlePaneHeaderDuplicateOneBadgeOne,
        middlePaneHeaderDuplicateOneBadgeTwo,
        middlePaneHeaderDuplicateOneBadgeThree,
        middlePaneHeaderDuplicateOneBadgeFour,
        duplicateScanCount,
        numberPage,
        scanCount,
        countPage,
        middlePaneHeader,
        reviewListReqObj,
        typeOfMiddlePaneList,
        isSelectActive,
        selectedTagsArray,
        unselectedTagsArray,
        selectedFlagedArray,
        unselectedFlagedArray,
        allocatedTagsArray,
        flagedValue,
        allocateStr,
        signedAssesseePermission,
        paneOneSelectedSection,
        isListLoading
    } = useSelector((state) => state.DisplayPaneTwoReducer);
    const { responseObject, headerOne, headerOneBadgeOne, createMode } = useSelector(
        (state) => state.DisplayPaneThreeReducer
    );
    const { FilterModeEnable, FilterMode } = useSelector((state) => state.FilterReducer);

    const fetchData = () => {
        if (middlePaneHeader === 'reports') {
            let reqBody = {
                "module": "Assessee-Assignment",
                "action": "Reports-All",
                "requestObject": {
                    "assesseeId": selectedAssociateInfo?.assesseeId,
                    "associateId":
                        selectedAssociateInfo?.associate?.informationEngagement.associateTag
                            .associateTagPrimary,
                    "assesseeAssignmentStatus": [
                        "STARTED",
                        "UNSTARTED",
                        "ADMINISTERED"
                    ],
                    "countPage": countPage,
                    "numberPage": numberPage,
                    "filter": true,
                    "orderBy": {
                        "columnName": "assesseeAssignmentName",
                        "order": "asc"
                    }
                }
            }
            dispatch({
                type: SET_DISPLAY_TWO_SINGLE_STATE, payload: {
                    stateName: 'reviewListDistinctData', value: []
                }
            })
            dispatch({ type: SET_PAGE_COUNT, payload: numberPage + 1 });
            dispatch({
                type: FILTERMODE,
                payload: { FilterMode: 'assesseeReportsDistinct' + middlePaneHeaderBadgeTwo }
            });
            dispatch({ type: LOADER_START })
            dispatch({
                type: ASSESSEE_SELF_REPORTS,
                payload: {
                    typeOfMiddlePaneList: 'assesseeSelfReportsReviewList',
                    middlePaneHeaderBadgeTwo: middlePaneHeaderBadgeTwo,
                    middlePaneHeader: 'reports',
                    request: reqBody,
                }
            })
        } else {

            let reqBody = {
                "module": "Assessee-Assignment",
                "action": "Certificates-All",
                "requestObject": {
                    "assesseeId": selectedAssociateInfo?.assesseeId,
                    "associateId":
                        selectedAssociateInfo?.associate?.informationEngagement.associateTag
                            .associateTagPrimary,
                    "assesseeAssignmentStatus": [
                        "STARTED",
                        "UNSTARTED",
                        "ADMINISTERED"
                    ],
                    "countPage": countPage,
                    "numberPage": numberPage,
                    "filter": true,
                    "orderBy": {
                        "columnName": "assesseeAssignmentName",
                        "order": "asc"
                    }
                }
            }
            dispatch({
                type: SET_DISPLAY_TWO_SINGLE_STATE, payload: {
                    stateName: 'reviewListDistinctData', value: []
                }
            })
            dispatch({ type: SET_PAGE_COUNT, payload: numberPage + 1 });
            dispatch({
                type: FILTERMODE,
                payload: { FilterMode: 'assesseeReportsDistinct' + middlePaneHeaderBadgeTwo }
            });
            dispatch({ type: LOADER_START })
            dispatch({
                type: ASSESSEE_SELF_REPORTS,
                payload: {
                    typeOfMiddlePaneList: 'assesseeSelfReportsReviewList',
                    middlePaneHeaderBadgeTwo: middlePaneHeaderBadgeTwo,
                    middlePaneHeader: 'certificates',
                    request: reqBody,
                }
            })

        }
    }

    const openListPopup = (e) => {

        let selectedTag = e.currentTarget.getAttribute('tag');

        let signedAssesseeId = selectedAssociateInfo?.assesseeId;
        let signedAssociateId =
            selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary;
        let signedAssociateName = selectedAssociateInfo?.associate?.informationBasic.associateName;
        if (middlePaneHeader === 'certificates') {

            let reportUrl = REPORT_URL + 'certificate';
            let assesseeId = signedAssesseeId;
            let assesseeAssignmentId = selectedTag;
            let token =
                localStorage.getItem('token') + '@@@@' + signedAssesseeId + '@@@@' + signedAssociateId;
            window.open(
                reportUrl +
                '?assesseeId=' +
                assesseeId +
                '&assesseeAssignmentId=' +
                assesseeAssignmentId +
                '&token=' +
                token +
                ''
            );
        } else {
            let reportUrl = '';
            if (signedAssociateName === 'Richard McConkey') {
                reportUrl = REPORT_URL + 'richReport';
            } else if (signedAssociateName === 'Prafulta') {
                reportUrl = REPORT_URL + 'reports';
            } else if (signedAssociateName === 'Boppo Technologies') {
                reportUrl = REPORT_URL + 'reports';
            } else {
                reportUrl = REPORT_URL + 'richReport';
            }
            let assesseeId = signedAssesseeId
            let assesseeAssignmentId = selectedTag
            let token =
                localStorage.getItem('token') + '@@@@' + signedAssesseeId + '@@@@' + signedAssociateId;
            window.open(
                reportUrl +
                '?assesseeId=' +
                assesseeId +
                '&assesseeAssignmentId=' +
                assesseeAssignmentId +
                '&token=' +
                token +
                ''
            );
        }

    }
    const primaryIcon = [{ label: 'sift', onClick: onClickFooter, Icon: FilterList }];
    const secondaryIconOne = [];

    const onClickFooter = (e) => {

    }
    // // infinite scroll
    // const [hasMore, setHasMore] = useState(true);
    // let elementRef = useRef(null);

    // const onIntersection = (entries) => {
    //     const firstEntry = entries[0];
    //     reviewListDistinctData.length >= scanCount ? setHasMore(false) : setHasMore(true);
    //     if (firstEntry.isIntersecting && hasMore && !isListLoading) {
    //         // fetchData();
    //     }
    // };

    // useEffect(() => {
    //     // console.log('####MYREF######', elementRef)
    //     const observer = new IntersectionObserver(onIntersection, { threshold: 0.90 });

    //     if (observer && elementRef.current) {
    //         // console.log('here at observer...', elementRef.current)
    //         observer.observe(elementRef.current);
    //     }

    //     return () => {
    //         if (observer) observer.disconnect();
    //     };
    // }, [
    //     isListLoading,
    //     hasMore,
    //     reviewListDistinctData
    // ]);

    // // infinite scroll end

    return (
        <div>
            {reviewListDistinctData &&
                reviewListDistinctData.map((item, index) => {
                    return (
                        // <div className="containerPadding" key={index} ref={elementRef}>
                        <div className="containerPadding" key={index} >
                            {
                                <ReviewList
                                    className=""
                                    id={index}
                                    tag={
                                        item?.assesseeAssignmentId
                                    }
                                    // informationSetup={item?.informationSetup}
                                    associateId={item?.associateId}

                                    textOne={
                                        item?.reportName
                                    }
                                    // onClickEvent={openListPopup}
                                    textTwo={item?.assesseeAssignmentDescription}
                                    isTooltipActive={false}
                                    status={item.assesseeAssignmentStatus === 'FINISHED' && 'active'}
                                    actualStatus={item.assesseeAssignmentStatus === 'FINISHED' && 'active'}
                                    isListIconActive={true}
                                    isReportIcon={true}
                                    onClickListIcon={openListPopup}
                                />
                            }
                        </div>
                    );
                })}
            {isListLoading ? (
                <div>
                    <ListLoader />
                </div>
            ) : <div style={{ height: "60px", width: "100%" }}></div>}
            {FilterMode === 'assesseeReportsDistinctinactive' && (
                <FooterIconTwo
                    FilterModeEnable={FilterModeEnable}
                    FilterMode={FilterMode}
                    onClick={onClickFooter}
                    primaryIcon={primaryIcon}
                    secondaryIcon={
                        secondaryIconOne
                    }
                />
            )}
        </div>
    )
}

export default AssesseeSelfReportReviewList