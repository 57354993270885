import React, { Fragment, useEffect, useState } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import Popup from '../Molecules/PopUp/PopUp';
import PopupHeader from '../Molecules/PopUp/PopUpHeader';
import { InputLabel, FormControl, Checkbox } from '@material-ui/core';
import InputFeild from '../Atoms/InputField/InputField';
import SelectField from '../Atoms/SelectField/SelectField';
import '../Molecules/PopUp/PopUp.css';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  POPUP_CLOSE,
  SET_ITEM_FRAMEWORK_DYNAMIC_SINGLE_STATE,
  SET_ASSESSMENT_FRAMEWORK_DYNAMIC_SINGLE_STATE,
  SET_ASSESSMENT_SECTION_FRAMEWORK_DYNAMIC_SINGLE_STATE
} from '../actionType';
import InfoToolTip from '../Atoms/InfoToolTip/InfoToolTip';

const PopUpItemScaleFramework = (props) => {
  const {
    isActive,
    primaryheader,
    primaryheaderTwo = '',
    inputHeader,
    headerPanelColour,
    headerOne,
    headerOneBadgeOne,
    headerOneBadgeTwo = '',
    choiceOb = null,
    mode,
    isItemFramework = false,
    scaleMetricId,
    itemFrameworkOne,
  } = props;
  const dispatch = useDispatch();
  const { isAssessmentSectionShow = false } = useSelector((state) => state.DisplayPaneThreeReducer);
  const [score, setscore] = useState(0);
  const [weightage, setweightage] = useState('');
  let scaleObj = itemFrameworkOne.itemFrameworkOneScale[0];
  useEffect(() => {
    setscore(scaleObj.assessmentScaleMetric[scaleMetricId].assessmentScaleMetricScore || 0);
    setweightage(scaleObj.assessmentScaleMetric[scaleMetricId].assessmentScaleMetricWeightage || 0)
  }, [itemFrameworkOne]);
  
  const handleClick = () => {
    console.log(
      score,
      weightage,
    );
    if (isItemFramework) {
      if (isAssessmentSectionShow) {
          console.log('itemFrameworkOne',itemFrameworkOne);
          scaleObj.assessmentScaleMetric[scaleMetricId].assessmentScaleMetricScore=parseInt(score);
          scaleObj.assessmentScaleMetric[scaleMetricId].assessmentScaleMetricWeightage=parseInt(weightage);
          dispatch({ type: POPUP_CLOSE });
      } else{
        dispatch({ type: POPUP_CLOSE });
      }
    } else{
        dispatch({ type: POPUP_CLOSE });
    }
  };
  return (
    <div>
      <Popup isActive={isActive}>
        <PopupHeader
          headerPanelColour={headerPanelColour}
          headerOne={headerOne}
          headerOneBadgeOne={headerOneBadgeOne}
          headerOneBadgeTwo={headerOneBadgeTwo}
          onClick={handleClick}
          mode={mode}
        />
        <DialogContent
          className={['popupContent', 'fixed10PadDim', 'revisePopupContent'].join(' ')}
        >
          <div className={'fitContent'}>
            <div className={['PopupFormBox', 'labelPopupBox', 'popupMinHei'].join(' ')}>
              <InputLabel htmlFor="name-input" className={'textForLabelPopup'}>
                <Fragment>
                  {inputHeader}&nbsp;
                  {primaryheader ? (
                    <>
                      <span className={'headerBadge'}>{primaryheader}</span>&nbsp;
                    </>
                  ) : null}
                  {primaryheaderTwo ? (
                    <span className={'headerBadge'}>{primaryheaderTwo}</span>
                  ) : null}
                </Fragment>
              </InputLabel>
              <div className={'infoSymbol'}></div>
              <div className={'infoSymbol'}>
                <InfoToolTip message="Click me, I will stay visible until you click outside." />
              </div>
            </div>
          </div>
          <FormControl style={{ width: '100%' }}>
            
              <InputFeild
                id={'score'}
                label={'score'}
                value={score}
                errorMsg={''}
                type={'number'}
                onClick={(e) => {
                  setscore(e.target.value);
                }}
              />
              <InputFeild
                id={'weightage'}
                label={'weightage'}
                value={weightage}
                type={'number'}
                errorMsg={''}
                onClick={(e) => {
                  setweightage(e.target.value);
                }}
              />
              
          </FormControl>
        </DialogContent>
      </Popup>
    </div>
  );
};

PopUpItemScaleFramework.propTypes = {
  className: PropTypes.string,
  headerPanelColour: PropTypes.oneOf([
    'displayPaneLeft',
    'displayPaneCentre',
    'displayPaneRight',
    'genericOne',
    'genericTwo'
  ]),
  headerOne: PropTypes.string,
  headerOneBadgeOne: PropTypes.string,
  headerOneBadgeTwo: PropTypes.string,
  headerOneBadgeThree: PropTypes.string,
  isActive: PropTypes.bool
};

export default PopUpItemScaleFramework;
