import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PopUpTextField from '../../PopUpInformation/PopUpTextField';
import PopUpConfirm from '../../PopUpGeneric/PopUpConfirm';
import {
  POPUP_CLOSE,
  LOADER_START,
  SET_DISPLAY_THREE_SINGLE_STATE,
  CLEAR_SCORE_REDUCER_STATE,
  SET_SCORE_REDUCER_STATE,
  CREATE_ASSESSMENT_SECTION_SAGA
} from '../../actionType';
import PopUpTextFieldFormula from '../../PopUpInformation/PopUpTextFieldFormula';
import PopUpDropList from '../../PopUpInformation/PopUpDropList';
import PopUpPicture from '../../PopUpInformation/PopUpPicture';
import PopUpTagSecondary from '../../PopUpInformation/PopUpTagSecondary';

const PopUpScoreCreate = (props) => {
  const { headerOne } = props;
  const { isPopUpValue, selectedTagValue } = useSelector((state) => state.PopUpReducer);
  const { scoreInformation } = useSelector((state) => state.ScoreCreateReducer);
  console.log(scoreInformation);
  const { reviewMode,responseObject } = useSelector((state) => state.DisplayPaneThreeReducer);
  const { selectedAssociateInfo } = useSelector((state) => state.DisplayPaneTwoReducer);

  const dispatch = useDispatch();
  const onClickCancelYes = () => {
    dispatch({
      type: SET_DISPLAY_THREE_SINGLE_STATE,
      payload: { stateName: 'createMode', value: '' }
    });
    dispatch({ type: CLEAR_SCORE_REDUCER_STATE });
    dispatch({ type: POPUP_CLOSE });
  };
  const onClickYes = () => {
    dispatch({ type: POPUP_CLOSE });
    let requestObj = {
      module: 'Assessment-Distinct',
      action: 'Create',
      actionFilter: 'Section-Cluster-Scale',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        assessmentId: selectedTagValue,
        assessmentScoreFramework: scoreInformation
      }
    };
    console.log('requestObj', requestObj);
    dispatch({ type: POPUP_CLOSE });
    dispatch({ type: LOADER_START });
    dispatch({ type: CREATE_ASSESSMENT_SECTION_SAGA, payload: requestObj });
  };
  console.log('scoreInformation', scoreInformation);
  let tagPrimary = '';
  let tenureStartDate = 'mm/dd/yyyy --:-- --';
  let tenureEndDate = 'mm/dd/yyyy --:-- --';
  

  return (
    <div>
      {isPopUpValue === 'NAMEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'name'}
          actualLableValue={'assessmentScoreName'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={''}
          headerOneBadgeTwo={'information'}
          nextPopUpValue={'ALIASPOPUP'}
          basicInfo={scoreInformation}
          typeOfSetObject={SET_SCORE_REDUCER_STATE}
          isRequired={true}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ALIASPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'description'}
          actualLableValue={'assessmentScoreDescription'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={''}
          headerOneBadgeTwo={'information'}
          basicInfo={scoreInformation}
          nextPopUpValue={'PICTUREPOPUP'}
          typeOfSetObject={SET_SCORE_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'PICTUREPOPUP' && (
        <PopUpPicture
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          headerOneBadgeTwo={''}
          nextPopUpValue={'CONFIRMATIONPOPUP'}
          actualLableValue={'assessmentScorePicture'}
          actualLableValueVerification={'assessmentScorePictureVerification'}
          basicInfo={scoreInformation}
          typeOfSetObject={SET_SCORE_REDUCER_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'CANCELPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'cancel'}
          headerOneBadgeOne={''}
          mode={'cancel'}
          onClickYes={onClickCancelYes}
        />
      )}
      {isPopUpValue === 'CONFIRMATIONPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={''}
          headerOneBadgeTwo={'create'}
          onClickYes={onClickYes}
        />
      )}
      {isPopUpValue === 'SCORE_POPUP' && (
        <PopUpTextFieldFormula
          isActive={true}
          label={'calculator(score)'}
          actualLableValue={'assessmentScoreCalculator'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={''}
          headerOneBadgeTwo={'information'}
          basicInfo={scoreInformation}
          mappingValue={'id'}
          nextPopUpValue={''}
          listSelect={[
            {
              id: '{assesseeAssignmentAssessmentClusterItemScoreTotal}',
              name: 'ClusterItemScoreTotal'
            },
            { id: '{assesseeAssignmentAssessmentClusterItemTotal}', name: 'ClusterItemTotal' },
            { id: '{assesseeAssignmenAssessmentScoreRaw}', name: 'Raw-Score' },
            { id: '{assessmentScoreExtremumMaximum}', name: 'ScoreExtremumMaximum' },
            { id: '{assessmentScoreZ}', name: 'Z-Score' },
            { id: '{assessmentScoreMean}', name: 'Mean' },
            { id: '{assessmentScoreStandardDeviation}', name: 'Standard-Deviation' }
          ]}
          typeOfSetObject={SET_SCORE_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ROLEGROUPPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentScoreClassificationPrimary'}
          label={'classification'}
          listSelect={[
            { id: 'Bespoke', name: 'Bespoke' },
            { id: 'Generic', name: 'Generic' }
          ]}
          mappingValue={'id'}
          inputHeader={''}
          inputHeaderBadgeOne={''}
          inputHeaderBadgeTwo={''}
          labelval={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={''}
          headerOneBadgeTwo={'information'}
          isRequired={true}
          nextPopUpValue={reviewMode === 'revise' ? '' : ''}
          basicInfo={scoreInformation}
          typeOfSetObject={SET_SCORE_REDUCER_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ONEONELABELPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'label'}
          actualLableValue={'assessmentScoreOneLabel'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'score'}
          headerOneBadgeTwo={'information'}
          basicInfo={scoreInformation}
          nextPopUpValue={''}
          typeOfSetObject={SET_SCORE_REDUCER_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ONETWOLABELPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'label'}
          actualLableValue={'assessmentScoreTwoLabel'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'score'}
          headerOneBadgeTwo={'information'}
          basicInfo={scoreInformation}
          nextPopUpValue={''}
          typeOfSetObject={SET_SCORE_REDUCER_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ONEONEEXPLANATION' && (
        <PopUpTextField
          isActive={true}
          label={'explanation'}
          actualLableValue={'assessmentScoreOneExplanation'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'score'}
          headerOneBadgeTwo={'information'}
          basicInfo={scoreInformation}
          nextPopUpValue={''}
          typeOfSetObject={SET_SCORE_REDUCER_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ONETWOEXPLANATION' && (
        <PopUpTextField
          isActive={true}
          label={'explanation'}
          actualLableValue={'assessmentScoreTwoExplanation'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'score'}
          headerOneBadgeTwo={'information'}
          basicInfo={scoreInformation}
          nextPopUpValue={''}
          typeOfSetObject={SET_SCORE_REDUCER_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      
      {isPopUpValue === 'CANCELPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'cancel'}
          headerOneBadgeOne={''}
          mode={'cancel'}
          onClickYes={onClickCancelYes}
        />
      )}
      {isPopUpValue === 'CONFIRMATIONPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={''}
          headerOneBadgeTwo={'create'}
          onClickYes={onClickYes}
        />
      )}
      
      {isPopUpValue === 'ONEONEDESCRIPTION' && (
        <PopUpTextField
          isActive={true}
          label={'description'}
          actualLableValue={'assessmentScoreOneDescription'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'score'}
          headerOneBadgeTwo={'information'}
          basicInfo={scoreInformation}
          nextPopUpValue={''}
          typeOfSetObject={SET_SCORE_REDUCER_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
        {isPopUpValue === 'SCOREDESCRIPTION' && (
        <PopUpTextField
          isActive={true}
          label={'description'}
          actualLableValue={'assessmentScoreOneDescription'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'score'}
          headerOneBadgeTwo={'information'}
          basicInfo={scoreInformation}
          nextPopUpValue={''}
          typeOfSetObject={SET_SCORE_REDUCER_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ONETWODESCRIPTION' && (
        <PopUpTextField
          isActive={true}
          label={'description'}
          actualLableValue={'assessmentScoreTwoDescription'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'score'}
          headerOneBadgeTwo={'information'}
          basicInfo={scoreInformation}
          nextPopUpValue={''}
          typeOfSetObject={SET_SCORE_REDUCER_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
       {isPopUpValue === 'TAGREADONLYPRIMARYPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tag'}
          labelBadgeOne={'primary'}
          actualLableValue={'id'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'score'}
          headerOneBadgeTwo={'information'}
          basicInfo={ responseObject}
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={SET_SCORE_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TENURESATRTDATEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tenure'}
          labelBadgeOne={'start'}
          actualLableValue={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'score'}
          headerOneBadgeTwo={'information'}
          basicInfo={tenureStartDate}
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={SET_SCORE_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TENUREENDDATEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tenure'}
          labelBadgeOne={'end'}
          actualLableValue={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'score'}
          headerOneBadgeTwo={'information'}
          basicInfo={tenureEndDate}
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={SET_SCORE_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'STATUSPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentStatus'}
          label={'status'}
          listSelect={[
            { id: 'Active', name: 'Active' },
            { id: 'Archived', name: 'Archived' },
            { id: 'Confirmed', name: 'Confirmed' },
            { id: 'Suspended', name: 'Suspended' },
            { id: 'Terminated', name: 'Terminated' },
            { id: 'Unverified', name: 'Unverified' }
          ]}
          mappingValue={'id'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'score'}
          headerOneBadgeTwo={'information'}
          isRequired={true}
          basicInfo={''}
          nextPopUpValue={''}
          typeOfSetObject={SET_SCORE_REDUCER_STATE}
          handleNextPopupValue={() => {}}
          isNotRevised={true}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TAGSECONDARYPOPUP' && (
        <PopUpTagSecondary
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'score'}
          headerOneBadgeTwo={'information'}
          tagSecondary={'' || {}}
          signInSetup={'' || {}}
          nextPopUpValue={'CONFIRMATIONPOPUP'}
          typeOfSetObject={SET_SCORE_REDUCER_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
    </div>
  );
};

export default PopUpScoreCreate;
