import { put, takeLatest, call } from 'redux-saga/effects';
import {
  CREATE_CULTURE_SAGA,
  LOADER_STOP,
  SET_CREATE_MODE,
  SET_CULTURE_SETUP_INFO_STATE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_POPUP_SINGLE_STATE,
  SET_POPUP_VALUE
} from '../../actionType';
import { CULTURE_MODULE_URL } from '../../endpoints';
import {INFORMATION_EXIST_ERROR_MESSAGE} from '../../errorMessage'
const createApiCall = async (requestObj) => {
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      Authorization: localStorage.getItem('idToken')
    }),
    body: JSON.stringify(requestObj.data)
  };
  const response = await fetch(requestObj.URL, requestOptions);
  const json = await response.json();
  return json;
};

function* workerCreateCultureProfileSaga(data) {
  try {
    const response = yield call(createApiCall, { data: data.payload, URL: CULTURE_MODULE_URL });
    // const response = { responseCode: '000', responseObject: [{}] };
    if (response.responseCode === '000') {
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'responseObject', value: response.responseObject[0] }
      });
      yield put({ type: SET_CREATE_MODE, payload: 'cultureProfile' });
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'POPUPCONTINUE', popupMode: 'CULTURECREATE' }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'middlePaneSelectedValue', value: response.responseObject[0].id }
      });
      yield put({
        type: SET_POPUP_SINGLE_STATE,
        payload: {
          stateName: 'selectedTagStatus',
          value: response.responseObject[0].informationEngagement.cultureProfileStatus
        }
      });
      yield put({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'selectedTagValue', value: response.responseObject[0].id }
      });
      yield put({
        type: SET_CULTURE_SETUP_INFO_STATE,
        payload: response.responseObject[0].informationSetup,
      });
      // yield put({
      //   type: SET_DISPLAY_PANE_THREE_STATE,
      //   payload: {
      //     headerOne: 'culture-profile',
            // headerOneBadgeOne: 'distinct',
            // headerOneBadgeTwo: 'information',
            // headerOneBadgeThree: Store.getState().DisplayPaneTwoReducer.selectedInformationAllorKey,
      //     responseObject: response.responseObject[0],
      //     reviewMode: 'revise',
      //     createMode: 'cultureProfile'
      //   }
      // });
    }else if (response.responseCode === '108') {
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'NAMEPOPUP',
          popupMode: `CULTURECREATE`,
          // popupMode: `JOBCREATE`,
          popupErrorMsg: INFORMATION_EXIST_ERROR_MESSAGE
        }
      });
    }  else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: response.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    // yield put({ type: POPUP_CLOSE });
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({ type: LOADER_STOP });
  }
}

export default function* watchcreateCultureProfileSaga() {
  yield takeLatest(CREATE_CULTURE_SAGA, workerCreateCultureProfileSaga);
}
