import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ASSOCIATE_REVIEW_DISTINCT_SAGA,
  ASSOCIATE_REVIEW_DISTINCT_SHARE_SAGA,
  FILTERMODE,
  FILTERMODE_ENABLE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_PAGE_COUNT,
} from '../actionType';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import Check from '@material-ui/icons/Check';
import CrossIcon from '@material-ui/icons/Clear';
import ReviseIcon from '@material-ui/icons/RadioButtonChecked';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import {
  getDistinctShareApicall,
  getDistinctShareDistinctApicall,
} from '../Actions/GenericActions';
import {
  onClickCheckBoxSelection,
  onClickFlagSelection,
  setFlagedArray
} from '../Actions/AssesseeModuleAction';
import ListLoader from '../Atoms/ListLoader/ListLoader';
const AssociateDistinctShareReviewList = (props) => {
  const dispatch = useDispatch();
  const {
    numberPage,
    scanCount,
    middlePaneHeader,
    middlePaneHeaderBadgeTwo,
    reviewListDistinctData,
    reviewListReqObj,
    middlePaneSelectedValue,
    selectedAssociateInfo,
    isSelectActive,
    selectedTagsArray,
    unselectedTagsArray,
    selectedFlagedArray,
    unselectedFlagedArray,
    allocatedTagsArray,
    flagedValue,
    previousList,
    isDistinct,
    tempStr,
    isListLoading,
    relatedReviewListObjectCopy
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { FilterModeEnable, FilterMode } = useSelector((state) => state.FilterReducer);
  const { selectedTagValue, shareFeePopupValue } = useSelector((state) => state.PopUpReducer);
  const fetchData = async () => {
    if (reviewListDistinctData.length < scanCount) {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isListLoading', value: true }
      })
      let obj = {
        ...relatedReviewListObjectCopy.requestObject,
        numberPage: numberPage + 1
      };
      dispatch({
        type: ASSOCIATE_REVIEW_DISTINCT_SHARE_SAGA,
        payload: {
          request: { module: 'Associate-Distinct', action: 'Review-List', requestObject: obj },
          HeaderOne: middlePaneHeader,
          BadgeOne: 'distinct',
          BadgeTwo: middlePaneHeaderBadgeTwo,
          isMiddlePaneList: true,
          isSelectActive,
          selectedTagsArray
        }
      });
      dispatch({ type: SET_PAGE_COUNT, payload: numberPage + 1 });
    }
  };
  useEffect(() => {
    setFlagedArray(reviewListDistinctData, 'associateFlag', dispatch);
  }, [reviewListDistinctData]);

  const onClickFooter = (e) => {
    let siftValue = e.currentTarget.getAttribute('data-value');
    if (siftValue === 'no') {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isSelectActive', value: '' }
      });
      dispatch({
        type: FILTERMODE,
        payload: { FilterMode: '' }
      });
    } else if (siftValue === 'yes') {
      let module = '';
      console.log('previousList', previousList);
      // debugger
      if (selectedTagsArray.length <= 0) {
        dispatch({
          type: FILTERMODE,
          payload: { FilterMode: '' }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'isSelectActive', value: '' }
        });
        return;
      }

      if (isDistinct) {
        if (previousList === 'assesseeDistinct') {
          module = 'Assessee-Distinct';
        }
        if (previousList === 'assessmentDistinct') {
          module = 'Assessment-Distinct';
        }
        if (previousList === 'assignmentDistinct') {
          module = 'Assignment-Distinct';
        }
        if (previousList === 'jobProfileDistinct') {
          module = 'Job-Profile-Distinct';
        }
        if (previousList === 'cultureProfileDistinct') {
          module = 'Culture-Profile-Distinct';
        }
        if (previousList === 'itemDistinct') {
          module = 'Item-Distinct';
        }
        getDistinctShareDistinctApicall(
          selectedAssociateInfo,
          dispatch,
          module,
          tempStr,
          previousList,
          previousList,
          selectedTagValue,
          selectedTagsArray,
          allocatedTagsArray,
          shareFeePopupValue
        );
      } else {
        if (previousList === 'assesseeRole') {
          module = 'Assessee-Role';
        }
        if (previousList === 'assesseeType') {
          module = 'Assessee-Type';
        }
        if (previousList === 'assesseeGroup') {
          module = 'Assessee-Group';
        }
        if (previousList === 'assessmentType') {
          module = 'Assessment-Type';
        }
        if (previousList === 'assessmentGroup') {
          module = 'Assessment-Group';
        }
        if (previousList === 'assignmentGroup') {
          module = 'Assignment-Group';
        }
        if (previousList === 'assignmentType') {
          module = 'Assignment-Type';
        }
        if (previousList === 'associateGroup') {
          module = 'Associate-Group';
        }
        if (previousList === 'associateRole') {
          module = 'Associate-Role';
        }
        if (previousList === 'associateType') {
          module = 'Associate-Type';
        }
        if (previousList === 'itemGroup') {
          module = 'Item-Group';
        }
        if (previousList === 'itemType') {
          module = 'Item-Type';
        }
        if (previousList === 'cultureProfileGroup') {
          module = 'Culture-Profile-Group';
        }
        if (previousList === 'cultureProfileType') {
          module = 'Culture-Profile-Type';
        }
        if (previousList === 'jobProfileGroup') {
          module = 'Job-Profile-Group';
        }
        if (previousList === 'jobProfileType') {
          module = 'Job-Profile-Type';
        }
        getDistinctShareApicall(
          selectedAssociateInfo,
          dispatch,
          module,
          tempStr,
          previousList,
          previousList,
          selectedTagValue,
          selectedTagsArray,
          allocatedTagsArray,
          shareFeePopupValue
        );
      }
    } else {
      console.log(siftValue);
    }

    dispatch({ type: FILTERMODE_ENABLE });
  };
  /* for middle pane */

  // infinite scroll
  const [hasMore, setHasMore] = useState(true);
  let elementRef = useRef(null);

  const onIntersection = (entries) => {
    const firstEntry = entries[0];
    reviewListDistinctData.length >= scanCount ? setHasMore(false) : setHasMore(true)
    if (firstEntry.isIntersecting && hasMore && !isListLoading) {
      fetchData();
    }
  };

  useEffect(() => {
    // console.log('####MYREF######', elementRef)
    const observer = new IntersectionObserver(onIntersection, { threshold: 0.90 });

    if (observer && elementRef.current) {
      // console.log('here at observer...', elementRef.current)
      observer.observe(elementRef.current);
    }

    return () => {
      if (observer) observer.disconnect();
    };
  }, [isListLoading, hasMore, reviewListDistinctData, selectedTagsArray, unselectedTagsArray, isSelectActive]);

  // infinite scroll end

  let signedAssociateId =
    selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary;
  return (
    <div>
      {reviewListDistinctData &&
        reviewListDistinctData.map((item, index) => {
          return (
            <div className="containerPadding" key={index} ref={elementRef}>
              <ReviewList
                className=""
                id={index}
                tag={
                  item?.informationEngagement?.associateTag
                    ? item?.informationEngagement?.associateTag?.associateTagPrimary
                    : item
                }
                isSelectedReviewList={
                  middlePaneSelectedValue ===
                    item.informationEngagement?.associateTag?.associateTagPrimary
                    ? true
                    : false
                }
                status={item.informationEngagement?.associateStatus}
                actualStatus={item.informationEngagement?.associateStatus}
                textOne={item.informationBasic?.associateName}
                textTwo={item.informationBasic?.associateDescription}
                isTooltipActive={false}
                // onClickEvent={openListPopup}
                isSelectActive={isSelectActive}
                isSelected={selectedTagsArray.includes(
                  item.informationEngagement?.associateTag?.associateTagPrimary
                )}
                onClickCheckBox={(event) => {
                  !isListLoading && onClickCheckBoxSelection(selectedTagsArray, unselectedTagsArray, event, dispatch);
                }}
                isFlagActive={selectedFlagedArray.includes(item.id)}
                flagedValue={flagedValue}
                onClickAddFladed={(event) => {
                  onClickFlagSelection(selectedFlagedArray, unselectedFlagedArray, event, dispatch);
                }}
              />
            </div>
          );
        })}
      {isListLoading ? <div> <ListLoader /> </div> : <div style={{ height: "60px", width: "100%" }}></div>}
      {FilterMode === 'AssociateDistinctShareReviewList' && (<FooterIconTwo
        FilterModeEnable={FilterModeEnable}
        FilterMode={FilterMode}
        onClick={onClickFooter}
        primaryIcon={[{ label: tempStr, onClick: onClickFooter, Icon: ReviseIcon }]}
        secondaryIcon={[
          { label: 'no', onClick: onClickFooter, Icon: CrossIcon },
          { label: 'yes', onClick: onClickFooter, Icon: Check }
        ]}
      />)}
    </div>
  );
};
export default AssociateDistinctShareReviewList;
