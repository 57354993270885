import CalculatorAdvancedIcon from '@material-ui/icons/KeyboardHide';
import CalculatorIcon from '@material-ui/icons/Keyboard';
import ManuscriptIcon from '@material-ui/icons/Description';
import Worksheet from '@material-ui/icons/InsertDriveFile';
import GaugeIcon from '@material-ui/icons/Dashboard';
import TemplateIcon from '@material-ui/icons/BorderClear';

// export const ALLOCATE_ASSESSEE_DISTINCT = {
//   assessees: [
//     'allocate',
//     'unallocate',
//     'assessees',
//     'assignments',
//     'groups',
//     'nodes',
//     'roles',
//     'types'
//   ],
//   assignments: ['allocate', 'unallocate', 'assessees', 'assignments', 'distinct', 'groups', 'nodes', 'roles', 'types' ],
//   distinct: ['allocate', 'unallocate', 'assignments', 'distinct', 'groups', 'nodes', 'roles', 'types' ],
//   groups: [
//     'allocate',
//     'unallocate',
//     'assessees',
//     'assignments',
//     'distinct',
//     'groups',
//     'nodes',
//     'roles',
//     'types'
//   ],
//   nodes: ['allocate', 'unallocate', 'assessees', 'groups', 'nodes', 'roles', 'types'],
//   roles: ['allocate', 'unallocate', 'assessees', 'groups', 'nodes', 'roles', 'types'],
//   types: ['allocate', 'unallocate', 'assessees', 'groups', 'nodes', 'roles', 'types']
// };
export const ALLOCATE_ASSESSEE_DISTINCT = {
  assessees: [
    'allocate',
    'unallocate',
    'assessees',
    'assignments',
    'groups',
    'nodes',
    'roles',
    'types'
  ],
  assignments: ['allocate', 'unallocate', 'assessees', 'assignments', 'distinct', 'groups'],
  distinct: ['allocate', 'unallocate', 'assignments', 'distinct', 'groups'],
  groups: [
    'allocate',
    'unallocate',
    'assessees',
    'assignments',
    'distinct',
    'groups',
    'nodes',
    'roles',
    'types'
  ],
  nodes: ['allocate', 'unallocate', 'assessees', 'groups', 'nodes', 'roles', 'types'],
  roles: ['allocate', 'unallocate', 'assessees', 'groups', 'nodes', 'roles', 'types'],
  types: ['allocate', 'unallocate', 'assessees', 'groups', 'nodes', 'roles', 'types']
};
export const ALLOCATE_ASSESSEE_SUBMODULE_DISTINCT = {
  assessees: [
    'allocate',
    'unallocate',
    'assessees',
    'assignments',
    'distinct',
    'groups',
    'nodes',
    'roles',
    'types'
  ],
  assignments: ['allocate', 'unallocate', 'assessees', 'assignments', 'distinct', 'groups'],
  distinct: [
    'allocate',
    'unallocate',
    'assessees',
    'assignments',
    'distinct',
    'groups',
    'nodes',
    'roles',
    'types'
  ],
  groups: [
    'allocate',
    'unallocate',
    'assessees',
    'assignments',
    'distinct',
    'groups',
    'nodes',
    'roles',
    'types'
  ],
  nodes: ['allocate', 'unallocate', 'assessees', 'distinct', 'groups', 'nodes', 'roles', 'types'],
  roles: ['allocate', 'unallocate', 'assessees', 'distinct', 'groups', 'nodes', 'roles', 'types'],
  types: ['allocate', 'unallocate', 'assessees', 'distinct', 'groups', 'nodes', 'roles', 'types']
};
export const ALLOCATE_CULTUREPROFILE_DISTINCT = {
  cultureprofiles: [
    'allocate',
    'unallocate',
    'assignments',
    'culture-profiles',
    'groups',
    'nodes',
    'types'
  ],
  assignments: ['allocate', 'unallocate', 'assignments', 'culture-profiles', 'distinct', 'groups'],
  distinct: ['allocate', 'unallocate', 'assignments', 'distinct', 'groups'],
  groups: ['allocate', 'unallocate', 'assignments', 'culture-profiles', 'distinct', 'groups', 'nodes', 'types'],
  nodes: ['allocate', 'unallocate', 'culture-profiles', 'groups', 'nodes', 'types'],
  types: ['allocate', 'unallocate', 'culture-profiles', 'groups', 'nodes', 'types']
};
export const ALLOCATE_CULTUREPROFILE_DISTINCT_INACTIVE = {
  cultureprofiles: [
    'allocate',
    'unallocate',
    'culture-profiles',
    'groups',
    'nodes',
    'types'
  ],
  // assignments: ['allocate', 'unallocate', 'culture-profiles', 'distinct', 'groups'],
  distinct: ['allocate', 'unallocate', 'distinct', 'groups'],
  groups: ['allocate', 'unallocate', 'culture-profiles', 'distinct', 'groups', 'nodes', 'types'],
  nodes: ['allocate', 'unallocate', 'culture-profiles', 'groups', 'nodes', 'types'],
  types: ['allocate', 'unallocate', 'culture-profiles', 'groups', 'nodes', 'types']
};
export const ALLOCATE_CULTUREPROFILE_SUBMODULE_DISTINCT = {
  cultureprofiles: [
    'allocate',
    'unallocate',
    'assignments',
    'culture-profiles',
    'distinct',
    'groups',
    'nodes',
    'types'
  ],
  assignments: ['allocate', 'unallocate', 'assignments', 'culture-profiles', 'distinct', 'groups'],
  distinct: ['allocate', 'unallocate', 'assignments', 'culture-profiles', 'distinct', 'groups', 'nodes', 'types'],
  groups: ['allocate', 'unallocate', 'assignments', 'culture-profiles', 'distinct', 'groups', 'nodes', 'types'],
  nodes: ['allocate', 'unallocate', 'culture-profiles', 'distinct', 'groups', 'nodes', 'types'],
  types: ['allocate', 'unallocate', 'culture-profiles', 'distinct', 'groups', 'nodes', 'types']
};
export const ALLOCATE_ASSESSMENT_DISTINCT = {
  assessments: [
    'allocate',
    'unallocate',
    'assessments',
    'assignments',
    'items',
    'groups',
    'nodes',
    'types'
  ],
  assignments: [
    'allocate',
    'unallocate',
    'assessments',
    'assignments',
    'items',
    'distinct',
    'groups'
  ],
  items: ['allocate', 'unallocate', 'assessments', 'assignments', 'items', 'distinct', 'groups'],
  distinct: [
    'allocate',
    'unallocate',
    'assignments',
    'items',
    'distinct',
    'groups',
    'nodes',
    'types'
  ],
  groups: [
    'allocate',
    'unallocate',
    'assessments',
    'assignments',
    'items',
    'distinct',
    'groups',
    'nodes',
    'types'
  ],
  nodes: ['allocate', 'unallocate', 'assessments', 'groups', 'nodes', 'types'],
  types: ['allocate', 'unallocate', 'assessments', 'groups', 'nodes', 'types']
};
export const ALLOCATE_ASSESSMENT_ACTIVE_DISTINCT = {
  assessments: [
    'allocate',
    'unallocate',
    'assessments',
    'assignments',
    // 'items',
    'groups',
    'nodes',
    'types'
  ],
  assignments: [
    'allocate',
    'unallocate',
    'assessments',
    'assignments',
    // 'items',
    'distinct',
    'groups'
  ],
  // items: ['allocate', 'unallocate', 'assessments', 'assignments', 'items', 'distinct', 'groups'],
  distinct: [
    'allocate',
    'unallocate',
    'assignments',
    // 'items',
    'distinct',
    'groups',
    'nodes',
    'types'
  ],
  groups: [
    'allocate',
    'unallocate',
    'assessments',
    'assignments',
    // 'items',
    'distinct',
    'groups',
    'nodes',
    'types'
  ],
  nodes: ['allocate', 'unallocate', 'assessments', 'groups', 'nodes', 'types'],
  types: ['allocate', 'unallocate', 'assessments', 'groups', 'nodes', 'types']
};
export const ALLOCATE_ASSESSMENT_INACTIVE_DISTINCT = {
  assessments: [
    'allocate',
    'unallocate',
    'assessments',
    // 'assignments',
    'items',
    'groups',
    'nodes',
    'types'
  ],
  // assignments: [
  //   'allocate',
  //   'unallocate',
  //   'assessments',
  //   'assignments',
  //   'items',
  //   'distinct',
  //   'groups'
  // ],
  items: ['allocate', 'unallocate', 'assessments', 'items', 'distinct', 'groups'],
  distinct: [
    'allocate',
    'unallocate',
    // 'assignments',
    'items',
    'distinct',
    'groups',
    'nodes',
    'types'
  ],
  groups: [
    'allocate',
    'unallocate',
    'assessments',
    // 'assignments',
    'items',
    'distinct',
    'groups',
    'nodes',
    'types'
  ],
  nodes: ['allocate', 'unallocate', 'assessments', 'groups', 'nodes', 'types'],
  types: ['allocate', 'unallocate', 'assessments', 'groups', 'nodes', 'types']
};
export const ALLOCATE_ASSESSMENT_SUBMODULE = {
  assessments: [
    'allocate',
    'unallocate',
    'assessments',
    'assignments',
    'items',
    'distinct',
    'groups',
    'nodes',
    'types'
  ],
  assignments: ['allocate', 'unallocate', 'assessments', 'assignments', 'items', 'distinct', 'groups'],
  items: ['allocate', 'unallocate', 'assessments', 'assignments', 'items', 'distinct', 'groups'],
  distinct: [
    'allocate',
    'unallocate',
    'assessments',
    'assignments',
    'items',
    'distinct',
    'groups',
    'nodes',
    'types'
  ],
  groups: [
    'allocate',
    'unallocate',
    'assessments',
    'assignments',
    'items',
    'distinct',
    'groups',
    'nodes',
    'types'
  ],
  nodes: ['allocate', 'unallocate', 'assessments', 'distinct', 'groups', 'nodes', 'types'],
  types: ['allocate', 'unallocate', 'assessments', 'distinct', 'groups', 'nodes', 'types']
};
export const ALLOCATE_ASSIGNMENT_DISTINCT = {
  assignments: [
    'allocate',
    'unallocate',
    'assessees',
    'assessments',
    'assignments',
    'groups',
    'nodes',
    'types'
  ],
  assessees: [
    'allocate',
    'unallocate',
    'assessees',
    'assessments',
    'assignments',
    'distinct',
    'groups'
  ],
  assessments: [
    'allocate',
    'unallocate',
    'assessees',
    'assessments',
    'assignments',
    'distinct',
    'groups'
  ],
  distinct: [
    'allocate',
    'unallocate',
    'assessees',
    'assessments',
    'distinct',
    'groups'
  ],
  groups: [
    'allocate',
    'unallocate',
    'assessees',
    'assessments',
    'assignments',
    'distinct',
    'groups',
    'nodes',
    'types'
  ],
  nodes: ['allocate', 'unallocate', 'assignments', 'groups', 'nodes', 'types'],
  types: ['allocate', 'unallocate', 'assignments', 'groups', 'nodes', 'types']
};
export const ALLOCATE_ASSIGNMENT_ACTIVE_DISTINCT = {
  assignments: [
    'allocate',
    'unallocate',
    'assessees',
    'assignments',
    'groups',
    'nodes',
    'types'
  ],
  assessees: [
    'allocate',
    'unallocate',
    'assessees',
    'assignments',
    'distinct',
    'groups'
  ],
  assessments: [
    'allocate',
    'unallocate',
    'assessees',
    'assessments',
    'assignments',
    'distinct',
    'groups'
  ],
  distinct: [
    'allocate',
    'unallocate',
    'assessees',
    'distinct',
    'groups'
  ],
  groups: [
    'allocate',
    'unallocate',
    'assessees',
    'assignments',
    'distinct',
    'groups',
    'nodes',
    'types'
  ],
  nodes: ['allocate', 'unallocate', 'assignments', 'groups', 'nodes', 'types'],
  types: ['allocate', 'unallocate', 'assignments', 'groups', 'nodes', 'types']
};
export const ALLOCATE_ASSIGNMENT_ACTIVE_DISTINCT_ANALYTICS = {
  assignments: [
    'allocate',
    'unallocate',
    'assessees',
    'assignments',
    // 'distinct',
    'groups',
    'nodes',
    'types'
  ],
  assessees: [
    'allocate',
    'unallocate',
    'assessees',
    'assignments',
    'distinct',
    'groups'
  ],
  distinct: [
    'allocate',
    'unallocate',
    'assessees',
    'distinct',
    'groups'
  ],
  groups: [
    'allocate',
    'unallocate',
    'assessees',
    'assignments',
    'distinct',
    'groups',
    'nodes',
    'types'
  ],
  nodes: ['allocate', 'unallocate', 'assignments', 'groups', 'nodes', 'types'],
  types: ['allocate', 'unallocate', 'assignments', 'groups', 'nodes', 'types']
};
export const ALLOCATE_ASSIGNMENT_INACTIVE_DISTINCT_ANALYTICS = {
  assignments: [
    'allocate',
    'unallocate',
    'assessments',
    'assignments',
    'culture-profiles',
    'job-profiles',
    'distinct',
    'groups',
    'nodes',
    'types'
  ],

  assessees: [
    'allocate',
    'unallocate',
    'assessees',
    // 'assignments',
    'distinct',
    'groups'
  ],

  assessments: [
    'allocate',
    'unallocate',
    'assessments',
    'assignments',
    'culture-profiles',
    'job-profiles',
    'distinct',
    'groups'
  ],
  cultureprofiles: [
    'allocate',
    'unallocate',
    'assessments',
    'assignments',
    'culture-profiles',
    'job-profiles',
    'distinct',
    'groups'
  ],
  jobprofiles: [
    'allocate',
    'unallocate',
    'assessments',
    'assignments',
    'culture-profiles',
    'job-profiles',
    'distinct',
    'groups'
  ],
  distinct: [
    'allocate',
    'unallocate',
    'assessees',
    'assessments',
    'culture-profiles',
    'job-profiles',
    'distinct',
    'groups'
  ],
  groups: [
    'allocate',
    'unallocate',
    'assessees',
    'assessments',
    'assignments',
    'culture-profiles',
    'job-profiles',
    'distinct',
    'groups',
    'nodes',
    'types'
  ],
  nodes: ['allocate', 'unallocate', 'assignments', 'groups', 'nodes', 'types'],
  types: ['allocate', 'unallocate', 'assignments', 'groups', 'nodes', 'types']
};
export const ALLOCATE_ASSIGNMENT_DISTINCT_ANALYTICS = {
  assignments: [
    'allocate',
    'unallocate',
    'assessees',
    'assessments',
    'assignments',
    'culture-profiles',
    'job-profiles',
    'distinct',
    'groups',
    'nodes',
    'types'
  ],
  assessees: [
    'allocate',
    'unallocate',
    'assessees',
    'assessments',
    'assignments',
    'culture-profiles',
    'job-profiles',
    'distinct',
    'groups'
  ],
  assessments: [
    'allocate',
    'unallocate',
    'assessees',
    'assessments',
    'assignments',
    'culture-profiles',
    'job-profiles',
    'distinct',
    'groups'
  ],
  cultureprofiles: [
    'allocate',
    'unallocate',
    'assessees',
    'assessments',
    'assignments',
    'culture-profiles',
    'job-profiles',
    'distinct',
    'groups'
  ],
  jobprofiles: [
    'allocate',
    'unallocate',
    'assessees',
    'assessments',
    'assignments',
    'culture-profiles',
    'job-profiles',
    'distinct',
    'groups'
  ],
  distinct: [
    'allocate',
    'unallocate',
    'assessees',
    'assessments',
    'culture-profiles',
    'job-profiles',
    'distinct',
    'groups'
  ],
  groups: [
    'allocate',
    'unallocate',
    'assessees',
    'assessments',
    'assignments',
    'culture-profiles',
    'job-profiles',
    'distinct',
    'groups',
    'nodes',
    'types'
  ],
  nodes: ['allocate', 'unallocate', 'assignments', 'groups', 'nodes', 'types'],
  types: ['allocate', 'unallocate', 'assignments', 'groups', 'nodes', 'types']
};
// allocate assignments submodule = group , nodes , type
export const ALLOCATE_ASSIGNMENT_SUBMODULE = {
  assignments: [
    'allocate',
    'unallocate',
    'assessees',
    'assessments',
    'assignments',
    // 'culture-profiles',
    // 'job-profiles',
    'distinct',
    'groups',
    'nodes',
    'roles',
    'types'
  ],
  assessees: [
    'allocate',
    'unallocate',
    'assessees',
    'assessments',
    'assignments',
    // 'culture-profiles',
    // 'job-profiles',
    'distinct',
    'groups',
    'nodes',
    'roles',
    'types'
  ],
  assessments: [
    'allocate',
    'unallocate',
    'assessees',
    'assessments',
    'assignments',
    // 'culture-profiles',
    // 'job-profiles',
    'distinct',
    'groups',
    'nodes',
    'roles',
    'types'
  ],
  cultureprofiles: [
    'allocate',
    'unallocate',
    'assessees',
    'assessments',
    'assignments',
    // 'culture-profiles',
    // 'job-profiles',
    'distinct',
    'groups',
    'nodes',
    'roles',
    'types'
  ],
  jobprofiles: [
    'allocate',
    'unallocate',
    'assessees',
    'assessments',
    'assignments',
    // 'culture-profiles',
    // 'job-profiles',
    'distinct',
    'groups',
    'nodes',
    'roles',
    'types'
  ],
  distinct: [
    'allocate',
    'unallocate',
    'assessees',
    'assessments',
    'assignments',
    // 'culture-profiles',
    // 'job-profiles',
    'distinct',
    'groups',
    'nodes',
    'roles',
    'types'
  ],
  groups: [
    'allocate',
    'unallocate',
    'assessees',
    'assessments',
    'assignments',
    // 'culture-profiles',
    // 'job-profiles',
    'distinct',
    'groups',
    'nodes',
    'roles',
    'types'
  ],
  roles: ['allocate', 'unallocate', 'distinct', 'groups', 'nodes', 'roles', 'types'],
  nodes: ['allocate', 'unallocate', 'assignments', 'distinct', 'groups', 'nodes', 'roles', 'types'],
  types: ['allocate', 'unallocate', 'assignments', 'distinct', 'groups', 'nodes', 'roles', 'types']
};
export const ALLOCATE_ASSOCIATE_DISTINCT = {
  associates: ['allocate', 'unallocate', 'associates', 'groups', 'nodes', 'roles', 'types'],
  distinct: ['allocate', 'unallocate', 'distinct', 'groups', 'nodes', 'roles', 'types'],
  groups: ['allocate', 'unallocate', 'associates', 'distinct', 'groups', 'nodes', 'roles', 'types'],
  nodes: ['allocate', 'unallocate', 'associates', 'distinct', 'groups', 'nodes', 'roles', 'types'],
  roles: ['allocate', 'unallocate', 'associates', 'distinct', 'groups', 'nodes', 'roles', 'types'],
  types: ['allocate', 'unallocate', 'associates', 'distinct', 'groups', 'nodes', 'roles', 'types'],
};
export const ALLOCATE_ASSOCIATE_SUBMODULE = {
  associates: ['allocate', 'unallocate', 'associates', 'distinct', 'groups', 'nodes', 'roles', 'types'],
  groups: ['allocate', 'unallocate', 'associates', 'distinct', 'groups', 'nodes', 'roles', 'types'],
  distinct: ['allocate', 'unallocate', 'associates', 'distinct', 'groups', 'nodes', 'roles', 'types'],
  nodes: ['allocate', 'unallocate', 'associates', 'distinct', 'groups', 'nodes', 'roles', 'types'],
  roles: ['allocate', 'unallocate', 'associates', 'distinct', 'groups', 'nodes', 'roles', 'types'],
  types: ['allocate', 'unallocate', 'associates', 'distinct', 'groups', 'nodes', 'roles', 'types']
};
export const ALLOCATE_ASSOCIATE_NODE_DISTINCT = {
  associates: [
    'allocate',
    'unallocate',
    'associates',
    'distinct',
    'groups',
    'nodes',
    'roles',
    'types'
  ],
  distinct: [
    'allocate',
    'unallocate',
    'associates',
    'distinct',
    'groups',
    'nodes',
    'roles',
    'types'
  ],
  groups: ['allocate', 'unallocate', 'associates', 'distinct', 'groups', 'nodes', 'roles', 'types'],
  nodes: ['allocate', 'unallocate', 'associates', 'distinct', 'groups', 'nodes', 'roles', 'types'],
  roles: ['allocate', 'unallocate', 'associates', 'distinct', 'groups', 'nodes', 'roles', 'types'],
  types: ['allocate', 'unallocate', 'associates', 'distinct', 'groups', 'nodes', 'roles', 'types']
};
export const ALLOCATE_JOB_DISTINCT = {
  jobprofiles: [
    'allocate',
    'unallocate',
    'job-profiles',
    'assignments',
    'groups',
    'nodes',
    'types'
  ],
  assignments: ['allocate', 'unallocate', 'assignments', 'distinct', 'groups'],
  distinct: ['allocate', 'unallocate', 'assignments', 'distinct', 'groups'],
  groups: ['allocate', 'unallocate', 'job-profiles', 'assignments', 'distinct', 'groups', 'nodes', 'types'],
  nodes: ['allocate', 'unallocate', 'job-profiles', 'groups', 'nodes', 'types'],
  types: ['allocate', 'unallocate', 'job-profiles', 'groups', 'nodes', 'types']
};
export const ALLOCATE_JOB_DISTINCT_INACTIVE = {
  jobprofiles: [
    'allocate',
    'unallocate',
    'job-profiles',
    'groups',
    'nodes',
    'types'
  ],
  // assignments: ['allocate', 'unallocate', 'assignments', 'distinct', 'groups'],
  distinct: ['allocate', 'unallocate', 'distinct', 'groups'],
  groups: ['allocate', 'unallocate', 'job-profiles', 'distinct', 'groups', 'nodes', 'types'],
  nodes: ['allocate', 'unallocate', 'job-profiles', 'groups', 'nodes', 'types'],
  types: ['allocate', 'unallocate', 'job-profiles', 'groups', 'nodes', 'types']
};
export const ALLOCATE_JOB_SUBMODULE = {
  jobprofiles: [
    'allocate',
    'unallocate',
    'distinct',
    'job-profiles',
    'assignments',
    'groups',
    'nodes',
    'types'
  ],
  assignments: ['allocate', 'unallocate', 'assignments', 'distinct', 'groups'],
  distinct: ['allocate', 'unallocate', 'assignments', 'distinct', 'job-profiles', 'groups'],
  groups: ['allocate', 'unallocate', 'job-profiles', 'distinct', 'assignments', 'groups', 'nodes', 'types'],
  nodes: ['allocate', 'unallocate', 'job-profiles', 'distinct', 'groups', 'nodes', 'types'],
  types: ['allocate', 'unallocate', 'job-profiles', 'distinct', 'groups', 'nodes', 'types']
};
export const ALLOCATE_ITEM_DISTINCT = {
  items: ['allocate', 'unallocate', 'assessments', 'items', 'groups', 'nodes', 'types'],
  assessments: ['allocate', 'unallocate', 'assessments', 'items', 'distinct', 'groups'],
  distinct: ['allocate', 'unallocate', 'assessments', 'distinct', 'groups'],
  groups: ['allocate', 'unallocate', 'assessments', 'items', 'distinct', 'groups', 'nodes', 'types'],
  nodes: ['allocate', 'unallocate', 'items', 'groups', 'nodes', 'types'],
  types: ['allocate', 'unallocate', 'items', 'groups', 'nodes', 'types']
};
export const ALLOCATE_ITEM_SUBMODULE_DISTINCT = {
  assessments: ['allocate', 'unallocate', 'assessments', 'items', 'distinct', 'groups'],
  distinct: ['allocate', 'unallocate', 'assessments', 'items', 'distinct', 'groups', 'nodes', 'types'],
  items: ['allocate', 'unallocate', 'assessments', 'items', 'distinct', 'groups', 'nodes', 'types'],
  groups: [
    'allocate',
    'unallocate',
    'assessments',
    'items',
    'distinct',
    'groups',
    'nodes',
    'types'
  ],
  nodes: ['allocate', 'unallocate', 'items', 'distinct', 'groups', 'nodes', 'types'],
  types: ['allocate', 'unallocate', 'items', 'distinct', 'groups', 'nodes', 'types']
};

export const ASSESSEE_REPORT_POPUP = [
  {
    data: 'publish',
    dataValue: 'publish',
    dataKey: 'publish',
    optionClass: 'optionPrimary'
  },
  {
    data: 'review',
    dataValue: 'reviewReport',
    dataKey: 'reviewReport',
    optionClass: 'optionPrimary'
  },
  {
    data: 'revise',
    dataValue: 'reviseReport',
    dataKey: 'reviseReport',
    optionClass: 'optionPrimary'
  }
];
export const ASSIGNMENT_DISTINCT_POPUP = [
  {
    data: 'distinct',
    dataValue: 'distinct',
    dataKey: 'distinct',
    optionClass: 'optionPrimary',
    divider: 'dark'
  },
  {
    data: 'active',
    dataValue: 'active',
    dataKey: 'active',
    optionClass: 'optionSecondary'
  },
  {
    data: 'inactive',
    dataValue: 'inactive',
    dataKey: 'inactive',
    optionClass: 'optionSecondary',
    divider: 'light'
  },
  {
    data: 'all',
    dataValue: 'all',
    dataKey: 'all',
    optionClass: 'optionSecondary',
    divider: 'light'
  },
  {
    data: 'archived',
    dataValue: 'archived',
    dataKey: 'archived',
    optionClass: 'optionSecondary',
    divider: ''
  }
];
export const ASSESSEE_SELF_ASSIGNMENT_DISTINCT_POPUP = [
  {
    data: 'distinct',
    dataValue: 'distinct',
    dataKey: 'distinct',
    optionClass: 'optionPrimary',
    divider: 'dark'
  },
  {
    data: 'active',
    dataValue: 'active',
    dataKey: 'active',
    optionClass: 'optionSecondary'
  },
  {
    data: 'inactive',
    dataValue: 'inactive',
    dataKey: 'inactive',
    optionClass: 'optionSecondary',
    divider: 'light'
  },
  {
    data: 'all',
    dataValue: 'all',
    dataKey: 'all',
    optionClass: 'optionSecondary',
    divider: 'light'
  },
  {
    data: 'archived',
    dataValue: 'archived',
    dataKey: 'archived',
    optionClass: 'optionSecondary',
    divider: 'light'
  },
  {
    data: 'upcoming',
    dataValue: 'upcoming',
    dataKey: 'upcoming',
    disabled: true,
    optionClass: 'optionSecondary'
  }
];
export const ASSESSEE_SELF_ASSIGNMENT_ASSESSMENT_DISTINCT_POPUP = [
  {
    data: 'distinct',
    dataValue: 'distinct',
    dataKey: 'distinct',
    optionClass: 'optionPrimary',
    divider: 'dark'
  },
  {
    data: 'active',
    dataValue: 'active',
    dataKey: 'active',
    optionClass: 'optionSecondary'
  },
  {
    data: 'inactive',
    dataValue: 'inactive',
    dataKey: 'inactive',
    optionClass: 'optionSecondary',
    divider: 'light'
  },
  {
    data: 'all',
    dataValue: 'all',
    dataKey: 'all',
    optionClass: 'optionSecondary',
    divider: 'light'
  },
  {
    data: 'archived',
    dataValue: 'archived',
    dataKey: 'archived',
    optionClass: 'optionSecondary'
  }
];
export const NOTIFICATION_REPORT_POPUP = [
  {
    data: 'distinct',
    dataValue: 'distinct',
    dataKey: 'distinct',
    optionClass: 'optionPrimary',
    divider: 'dark'
  },
  {
    data: 'all',
    dataValue: 'all',
    dataKey: 'all',
    optionClass: 'optionSecondary',
    divider: 'light'
  },
  {
    data: 'archived',
    dataValue: 'archived',
    dataKey: 'archived',
    optionClass: 'optionSecondary',
    divider: 'light'
  },
  {
    data: 'flagged',
    dataValue: 'flagged',
    dataKey: 'flagged',
    optionClass: 'optionSecondary'
  },
  {
    data: 'unflagged',
    dataValue: 'unflagged',
    dataKey: 'unflagged',
    optionClass: 'optionSecondary',
    divider: 'light'
  },
  {
    data: 'read',
    dataValue: 'read',
    dataKey: 'read',
    optionClass: 'optionSecondary'
  },
  {
    data: 'unread',
    dataValue: 'unread',
    dataKey: 'unread',
    optionClass: 'optionSecondary'
  }
];
export const ASSESSEE_CARD_POPUP_OPTIONS = [
  {
    data: 'assignments',
    dataValue: 'assesseeselfassignments',
    dataKey: 'assesseeselfassignments',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: false,
    permissionArr: '',
    permission: 'AssignmentDistinct',
    permissionValue: 'all'
  },
  {
    data: 'certificates',
    dataValue: 'certificates',
    dataKey: 'certificates',
    optionClass: 'optionPrimary',
    // divider: 'dark',
    disabled: false,
    permissionArr: '',
    permission: '',
    permissionValue: ''
  },
  {
    data: 'reports',
    dataValue: 'reports',
    dataKey: 'reports',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: false,
    permissionArr: '',
    permission: '',
    permissionValue: ''
  },
  {
    data: 'interviews',
    dataValue: 'interviews',
    dataKey: 'interviews',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: true,
    permissionArr: '',
    permission: '',
    permissionValue: ''
  },
  {
    data: 'link',
    dataValue: 'link',
    dataKey: 'link',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionArr: '',
    permission: 'No',
    permissionValue: ''
  },
  {
    data: 'password',
    dataValue: 'password',
    dataKey: 'password',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: false,
    permissionArr: '',
    permission: 'No'
  },
  {
    data: 'notifications',
    dataValue: 'notifications',
    dataKey: 'notifications',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionArr: '',
    permission: ''
  },
  {
    data: 'profile',
    dataValue: 'profile',
    dataKey: 'profile',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: true,
    permissionArr: '',
    permission: '',
    permissionValue: ''
  },
  {
    data: 'review',
    dataValue: 'review',
    dataKey: 'review',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionArr: '',
    permission: 'AssesseeSelf',
    permissionValue: 'review'
  },
  {
    data: 'revise',
    dataValue: 'revise',
    dataKey: 'revise',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: false,
    permissionArr: '',
    permission: 'AssesseeSelf',
    permissionValue: 'revise'
  },
  {
    data: 'sign-out',
    dataValue: 'sign-out',
    dataKey: 'sign-out',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionArr: '',
    permission: 'No',
    permissionValue: ''
  }
];
export const REVIEW_POPUP_OPTIONS = [
  {
    data: 'distinct',
    dataValue: 'distinct',
    dataKey: 'distinctAPICall',
    optionClass: 'optionPrimary',
    divider: 'light',
    disabled: true,
    permissionArr: '',
    permission: 'Distinct',
    permissionValue: 'review'
  },
  {
    data: 'groups',
    dataValue: 'groups',
    dataKey: 'groups',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionArr: '',
    permission: 'Group',
    permissionValue: 'review'
  },
  {
    data: 'nodes',
    dataValue: 'nodes',
    dataKey: 'nodes',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionArr: '',
    permission: 'Node',
    permissionValue: 'review'
  },
  {
    data: 'roles',
    dataValue: 'roles',
    dataKey: 'roles',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionArr: '',
    permission: 'RolePer',
    permissionValue: 'review'
  },
  {
    data: 'types',
    dataValue: 'types',
    dataKey: 'types',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: true,
    permissionArr: '',
    permission: 'Type',
    permissionValue: 'review'
  },
  {
    data: 'active',
    dataValue: 'active',
    dataKey: 'active',
    optionClass: 'optionSecondary',
    disabled: false
  },
  {
    data: 'inactive',
    dataValue: 'inactive',
    dataKey: 'inactive',
    optionClass: 'optionSecondary',
    divider: 'light',
    disabled: false
  },
  {
    data: 'all',
    dataValue: 'all',
    dataKey: 'all',
    optionClass: 'optionSecondary',
    divider: 'light',
    disabled: false
  },
  {
    data: 'archived',
    dataValue: 'archived',
    dataKey: 'archived',
    optionClass: 'optionSecondary',
    disabled: false
  }
];
export const ASSESSEES_REVIEW_POPUP_OPTIONS = [
  {
    data: 'distinct',
    dataValue: 'distinct',
    dataKey: 'distinctAPICall',
    optionClass: 'optionPrimary',
    divider: 'light',
    disabled: true,
    permissionArr: '',
    permission: 'Distinct',
    permissionValue: 'review'
  },
  {
    data: 'groups',
    dataValue: 'groups',
    dataKey: 'groups',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionArr: '',
    permission: 'Group',
    permissionValue: 'review'
  },
  {
    data: 'nodes',
    dataValue: 'nodes',
    dataKey: 'nodes',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionArr: '',
    permission: 'Node',
    permissionValue: 'review'
  },
  {
    data: 'roles',
    dataValue: 'roles',
    dataKey: 'roles',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionArr: '',
    permission: 'RolePer',
    permissionValue: 'review'
  },
  {
    data: 'types',
    dataValue: 'types',
    dataKey: 'types',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: true,
    permissionArr: '',
    permission: 'Type',
    permissionValue: 'review'
  },
  {
    data: 'active',
    dataValue: 'active',
    dataKey: 'active',
    optionClass: 'optionSecondary',
    disabled: false
  },
  {
    data: 'inactive',
    dataValue: 'inactive',
    dataKey: 'inactive',
    optionClass: 'optionSecondary',
    divider: 'light',
    disabled: false
  },
  {
    data: 'all',
    dataValue: 'all',
    dataKey: 'all',
    optionClass: 'optionSecondary',
    divider: 'light',
    disabled: false
  },
  {
    data: 'archived',
    dataValue: 'archived',
    dataKey: 'archived',
    optionClass: 'optionSecondary',
    disabled: false
  }
];
export const ASSOCIATE_CARD_POPUP_OPTION = [
  {
    data: 'administrators',
    dataValue: 'administrators',
    dataKey: 'administrators',
    optionClass: 'optionPrimary',
    disabled: false,
    divider: 'dark',
    permissionArr: '',
    permission: 'AdminDistinct',
    permissionValue: 'all'
  },
  // {
  //   data: 'assistants',
  //   dataValue: 'assistants',
  //   optionClass: 'optionPrimary',
  //   disabled: true,
  //   permissionArr: '',
  //   permission: 'AssistantDistinct',
  //   permissionValue: 'all'
  // },
  // {
  //   data: 'managers',
  //   dataValue: 'managers',
  //   optionClass: 'optionPrimary',
  //   disabled: false,
  //   divider: 'dark',
  //   permissionArr: '',
  //   permission: 'ManagerDistinct',
  //   permissionValue: 'all'
  // },
  // {
  //   data: 'analytics',
  //   dataValue: 'analytics',
  //   dataKey: 'analytics',
  //   optionClass: 'optionPrimary',
  //   disabled: true,
  //   permissionArr: '',
  //   permission: 'iGuruAnalytic',
  //   permissionValue: 'all'
  // },
  // {
  //   data: 'mine',
  //   dataValue: 'mine',
  //   dataKey: 'mine',
  //   optionClass: 'optionPrimary',
  //   divider: 'dark',
  //   disabled: true,
  //   permissionArr: '',
  //   permission: '',
  //   permissionValue: ''
  // },managers
  {
    data: 'exchange',
    dataValue: 'exchange',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: true,
    permissionArr: '',
    permission: 'DistinctPermission',
    permissionValue: 'create'
  },
  {
    data: 'groups',
    dataValue: 'groups',
    dataKey: 'groups',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionArr: '',
    permission: 'Group',
    permissionValue: 'all'
  },
  {
    data: 'nodes',
    dataValue: 'nodes',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionArr: '',
    permission: 'Node',
    permissionValue: 'all'
  },
  {
    data: 'roles',
    dataValue: 'roles',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionArr: '',
    permission: 'RolePermission',
    permissionValue: 'all'
  },
  {
    data: 'types',
    dataValue: 'types',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: true,
    permissionArr: '',
    permission: 'Type',
    permissionValue: 'all'
  },
  {
    data: 'interviews',
    dataValue: 'interviews',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: true,
    permissionArr: '',
    permission: '',
    permissionValue: ''
  },
  {
    data: 'items',
    dataValue: 'items',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: true,
    permissionArr: '',
    permission: 'itemDistinct',
    permissionValue: 'all'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   optionClass: 'optionPrimary',
  //   disabled: true,
  //   permissionArr: '',
  //   permission: '',
  //   permissionValue: ''
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   optionClass: 'optionPrimary',
  //   divider: 'dark',
  //   disabled: true,
  //   permissionArr: '',
  //   permission: '',
  //   permissionValue: ''
  // },
  {
    data: 'review',
    dataValue: 'associatereview',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionArr: '',
    permission: 'AssociateSelf',
    permissionValue: 'review'
  },
  {
    data: 'revise',
    dataValue: 'associaterevise',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: true,
    permissionArr: '',
    permission: 'AssociateSelf',
    permissionValue: 'revise'
  },
  {
    data: 'switch',
    dataValue: 'switch',
    optionClass: 'optionPrimary',
    disabled: 'no'
  }
];
export const ASSESSEE_PERMISSION = {
  assessee: ['archive', 'create', 'delete', 'review', 'revise'],
  assessment: ['archive', 'create', 'delete', 'review', 'revise'],
  assessment_item: ['archive', 'create', 'delete', 'review', 'revise'],
  assignment: ['archive', 'create', 'delete', 'review', 'revise'],
  assignment_dictionary: ['archive', 'create', 'delete', 'review', 'revise'],
  associate_hierarchy: ['archive', 'create', 'delete', 'review', 'revise'],
  iGauge_culture_profile: ['archive', 'create', 'delete', 'review', 'revise'],
  iGauge_job_profile: ['archive', 'create', 'delete', 'review', 'revise']
};
//LEFT FOOTER ICON ARRAY
export const CALCULATOR_POPUP_ARR = [
  { lable: 'basic', dataValue: 'basic', Icon: CalculatorIcon },
  { lable: 'Business', dataValue: 'Business', Icon: CalculatorIcon },
  { lable: 'financial', dataValue: 'financial', Icon: CalculatorAdvancedIcon },
  { lable: 'scientific', dataValue: 'scientific', Icon: CalculatorAdvancedIcon }
];
export const MANUSCRIPT_POPUP_ARR = [
  { lable: 'assessment', dataValue: 'assessment', Icon: ManuscriptIcon }
];
export const WORKSHEET_POPUP_ARR = [
  { lable: 'google', labelTwo: 'spreadsheet', dataValue: 'google spreadsheet', Icon: Worksheet },
  { lable: 'google', labelTwo: 'textsheet', dataValue: 'google textsheet', Icon: Worksheet },
  {
    lable: 'microsoft',
    labelTwo: 'spreadsheet',
    dataValue: 'microsoft spreadsheet',
    Icon: Worksheet
  },
  { lable: 'microsoft', labelTwo: 'textsheet', dataValue: 'microsoft textsheet', Icon: Worksheet },
  { lable: 'spreadsheet', dataValue: 'spreadsheet', Icon: Worksheet },
  { lable: 'textsheet', dataValue: 'textsheet', Icon: Worksheet }
];
export const GAUGE_POPUP_ARR = [{ lable: 'internet', dataValue: 'internet', Icon: GaugeIcon }];
export const INTERNET_POPUP_ARR = [
  { lable: 'ookla speedtest', dataValue: 'ooklaspeedtest', Icon: GaugeIcon }
];
export const TOOLKIT_POPUP_ARR = [
  { lable: 'gauge', dataValue: 'gauge', Icon: GaugeIcon },
  {
    lable: 'template',
    dataValue: 'template',
    Icon: TemplateIcon
  }
];
export const TEMPLATE_POPUP_ARR = [
  {
    lable: 'alignment',
    dataValue: 'alignment',
    Icon: TemplateIcon
  }
];
export const ALIGNMENT_POPUP_ARR = [
  {
    id: 1,
    lable: 'one',
    labelTwo: 'column',
    dataValue: 'oneColumn',
    Icon: TemplateIcon
  },
  {
    id: 2,
    lable: 'two',
    labelTwo: 'column',
    dataValue: 'twoColumn',
    Icon: TemplateIcon
  },
  {
    id: 3,
    lable: 'three',
    labelTwo: 'column',
    dataValue: 'threeColumn',
    Icon: TemplateIcon
  },
  {
    id: 4,
    lable: 'four',
    labelTwo: 'column',
    dataValue: 'fourColumn',
    Icon: TemplateIcon
  },
  {
    id: 5,
    lable: 'five',
    labelTwo: 'column',
    dataValue: 'fiveColumn',
    Icon: TemplateIcon
  },
  {
    id: 6,
    lable: 'six',
    labelTwo: 'column',
    dataValue: 'sixColumn',
    Icon: TemplateIcon
  }
];
//review information popup option
export const SELF_POPUP = [
  {
    data: 'review',
    dataValue: 'review',
    dataKey: 'review',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionArr: '',
    permission: 'AssesseeDistinct',
    permissionValue: 'review'
  },
  {
    data: 'revise',
    dataValue: 'revise',
    dataKey: 'revise',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionArr: '',
    permission: 'AssesseeDistinct',
    permissionValue: 'revise'
  }
];
export const ASSOCIATE_SELF_POPUP = [
  {
    data: 'review',
    dataValue: 'review',
    dataKey: 'review',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionArr: '',
    permission: 'AssociateDistinct',
    permissionValue: 'review'
  },
  {
    data: 'revise',
    dataValue: 'revise',
    dataKey: 'revise',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionArr: '',
    permission: 'AssociateDistinct',
    permissionValue: 'revise'
  }
];
export const ANALYTICS_POPUP = [
  {
    data: 'create',
    dataValue: 'create',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionArr: '',
    permission: 'iGuruAnalytic',
    permissionValue: 'create'
  },
  {
    data: 'review',
    dataValue: 'review',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: true,
    permissionArr: '',
    permission: 'iGuruAnalytic',
    permissionValue: 'review'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   optionClass: 'optionPrimary',
  //   disabled: true,
  //   permissionArr: '',
  //   permission: '',
  //   permissionValue: ''
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   optionClass: 'optionPrimary',
  //   divider: 'dark',
  //   disabled: true,
  //   permissionArr: '',
  //   permission: '',
  //   permissionValue: ''
  // },
  {
    data: 'assessment centres',
    dataValue: 'assessment centres',
    optionClass: 'optionSecondary',
    divider: 'light',
    disabled: true
  },
  {
    data: 'culture-profiles',
    dataValue: 'culture-profiles',
    optionClass: 'optionSecondary',
    disabled: false,
    permissionArr: '',
    permission: 'iGuruAnalytic',
    permissionValue: 'all'
  },
  {
    data: 'job-profiles',
    dataValue: 'job-profiles',
    optionClass: 'optionSecondary',
    disabled: false,
    divider: 'light',
    permissionArr: '',
    permission: 'iGuruAnalytic',
    permissionValue: 'all'
  },
  // {
  //   data: 'education-profiles',
  //   dataValue: 'education-profiles',
  //   optionClass: 'optionSecondary',
  //   disabled: true,
  //   permissionArr: '',
  //   permission: '',
  //   permissionValue: ''
  // },
  // {
  //   data: 'occupation-profiles',
  //   dataValue: 'occupation-profiles',
  //   optionClass: 'optionSecondary',
  //   disabled: true,
  //   divider: 'light',
  //   permissionArr: '',
  //   permission: '',
  //   permissionValue: ''
  // },
  // {
  //   data: 'personal-profiles',
  //   dataValue: 'personal-profiles',
  //   optionClass: 'optionSecondary',
  //   disabled: true,
  //   divider: 'light',
  //   permissionArr: '',
  //   permission: '',
  //   permissionValue: 'all'
  // },
  {
    data: 'gauges',
    dataValue: 'gauges',
    optionClass: 'optionSecondary',
    disabled: false,
    divider: 'light',
    permissionArr: '',
    permission: 'iGuruAnalytic',
    permissionValue: 'all'
  },
  // {
  //   data: 'interviews',
  //   dataValue: 'interviews',
  //   optionClass: 'optionSecondary',
  //   disabled: true,
  //   divider: 'light',
  //   permissionArr: '',
  //   permission: '',
  //   permissionValue: ''
  // },
  {
    data: 'profilers',
    dataValue: 'profilers',
    optionClass: 'optionSecondary',
    disabled: false,
    permissionArr: '',
    permission: 'iGuruAnalytic',
    permissionValue: 'all'
  }
];

export const MINE_REVIEW = [
  {
    data: 'ascendants',
    dataValue: 'ascendants',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: false
  },
  {
    data: 'active',
    dataValue: 'active',
    optionClass: 'optionSecondary',
    disabled: false
  }
];
export const REVIEW_REVISE_POPUP = [
  {
    data: 'information',
    dataValue: 'information',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: false
  },
  { data: 'all', dataValue: 'all', optionClass: 'optionSecondary' },
  { data: 'key', dataValue: 'key', optionClass: 'optionSecondary' }
];
export const ASSESSEE_REVIEW_REVISE_POPUP = [
  {
    data: 'information',
    dataValue: 'information',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: false
  },
  { data: 'all', dataValue: 'all', optionClass: 'optionSecondary' },
  { data: 'key', dataValue: 'key', optionClass: 'optionSecondary', divider: 'light' },
  { data: 'anonymous', dataValue: 'anonymous', optionClass: 'optionSecondary', disabled: false },
  { data: 'blank', dataValue: 'blank', optionClass: 'optionSecondary', disabled: false }
];
//
export const SIGN_OUT_POPUP = [
  { data: 'no', dataValue: 'no', optionClass: 'optionPrimary', disabled: false },
  { data: 'yes', dataValue: 'yes', optionClass: 'optionPrimary', disabled: false }
];
export const MODULE_POPUP_OPTION = [
  {
    data: 'create',
    dataValue: 'create',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionArr: 'assessee',
    permission: 'create',
    permissionValue: 'create'
  },
  {
    data: 'review',
    dataValue: 'review',
    optionClass: 'optionPrimary',
    // divider: 'dark',
    disabled: false,
    permissionArr: 'assessee',
    permission: 'review',
    permissionValue: 'review'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   optionClass: 'optionPrimary',
  //   disabled: true,
  //   permissionArr: '',
  //   permission: '',
  //   permissionValue: ''
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   optionClass: 'optionPrimary',
  //   disabled: true,
  //   permissionArr: '',
  //   permission: '',
  //   permissionValue: ''
  // }
];
export const CLUSTER_SCALE_POPUP_OPTION = [
  {
    data: 'create',
    dataValue: 'create',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionArr: 'assessee',
    permission: 'create',
    dataKey: 'createKey',
    permissionValue: 'create'
  },
  {
    data: 'review',
    dataValue: 'review',
    optionClass: 'optionPrimary',
    // divider: 'dark',
    disabled: false,
    permissionArr: 'assessee',
    permission: 'review',
    dataKey: 'reviewDistinctKey',
    permissionValue: 'review'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   optionClass: 'optionPrimary',
  //   disabled: true,
  //   permissionArr: '',
  //   permission: '',
  //   permissionValue: ''
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   optionClass: 'optionPrimary',
  //   disabled: true,
  //   permissionArr: '',
  //   permission: '',
  //   permissionValue: ''
  // }
];
export const SCORE_POPUP_OPTION = [
  {
    data: 'create',
    dataValue: 'create',
    optionClass: 'optionPrimary',
    disabled: true,
    //permissionArr: 'assessee',
    permission: 'create',
    dataKey: 'createKey'
  },
  {
    data: 'review',
    dataValue: 'review',
    optionClass: 'optionPrimary',
    // divider: 'dark',
    disabled: false,
    //permissionArr: 'assessee',
    permission: 'review',
    dataKey: 'reviewDistinctKey'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   optionClass: 'optionPrimary',
  //   disabled: true,
  //   permissionArr: '',
  //   permission: '',
  //   permissionValue: ''
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   optionClass: 'optionPrimary',
  //   disabled: true,
  //   permissionArr: '',
  //   permission: '',
  //   permissionValue: ''
  // }
];
export const IGUAGE_POPUP_OPTION = [
  {
    data: 'create',
    dataValue: 'create',
    optionClass: 'optionPrimary',
    disabled: true,
    permission: 'create',
    permissionValue: 'create'
  },
  {
    data: 'review',
    dataValue: 'review',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: true,
    permission: 'review',
    permissionValue: 'review'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   optionClass: 'optionPrimary',
  //   divider: 'dark',
  //   disabled: true
  // },
  { data: 'assessees', dataValue: 'assessees', optionClass: 'optionSecondary' },
  {
    data: 'assessments',
    dataValue: 'assessments',
    optionClass: 'optionSecondary',
    disabled: false
  },
  {
    data: 'assignments',
    dataValue: 'assignments',
    optionClass: 'optionSecondary',
    disabled: false
  },
  {
    data: 'associates',
    dataValue: 'associates',
    optionClass: 'optionSecondary',
    disabled: false
  }
  // {
  //   data: 'assessment centres',
  //   dataValue: 'assessment centres',
  //   optionClass: 'optionSecondary',
  //   divider: 'light',
  //   disabled: true
  // },
  // {
  //   data: 'culture-profiles',
  //   dataValue: 'culture-profiles',
  //   optionClass: 'optionSecondary',
  //   disabled: false
  // },
  // {
  //   data: 'job-profiles',
  //   dataValue: 'job-profiles',
  //   optionClass: 'optionSecondary',
  //   disabled: false
  // }
];
export const GROUP_NODE_ROLE_TYPE_POPUP_OPTION = [
  { data: 'create', dataValue: 'create', optionClass: 'optionPrimary', disabled: true },
  {
    data: 'review',
    dataValue: 'review',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: true
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   optionClass: 'optionPrimary',
  //   disabled: true,
  //   permissionArr: '',
  //   permission: '',
  //   permissionValue: ''
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   optionClass: 'optionPrimary',
  //   divider: 'dark',
  //   disabled: true,
  //   permissionArr: '',
  //   permission: '',
  //   permissionValue: ''
  // },
  { data: 'assessees', dataValue: 'assessees', optionClass: 'optionSecondary' },
  {
    data: 'assessments',
    dataValue: 'assessments',
    optionClass: 'optionSecondary',
    disabled: false
  },
  {
    data: 'assignments',
    dataValue: 'assignments',
    optionClass: 'optionSecondary',
    disabled: false
  },
  {
    data: 'associates',
    dataValue: 'associates',
    optionClass: 'optionSecondary',
    divider: 'light',
    disabled: false
  },
  {
    data: 'assessment centres',
    dataValue: 'assessment centres',
    optionClass: 'optionSecondary',
    divider: 'light',
    disabled: true
  },
  {
    data: 'culture-profiles',
    dataValue: 'culture-profiles',
    optionClass: 'optionSecondary',
    disabled: false
  },
  {
    data: 'job-profiles',
    dataValue: 'job-profiles',
    optionClass: 'optionSecondary',
    divider: 'light',
    disabled: false
  },
  {
    data: 'interviews',
    dataValue: 'interviews',
    optionClass: 'optionSecondary',
    divider: 'light',
    disabled: false
  },
  {
    data: 'items',
    dataValue: 'items',
    optionClass: 'optionSecondary',
    disabled: false
  }
];
export const GROUP_POPUP_OPTION = [
  {
    data: 'create',
    dataValue: 'create',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionArr: '',
    permission: '',
    permissionValue: 'create'
  },
  {
    data: 'review',
    dataValue: 'review',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: true,
    permissionArr: '',
    permission: '',
    permissionValue: 'review'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   optionClass: 'optionPrimary',
  //   disabled: true,
  //   permissionArr: '',
  //   permission: '',
  //   permissionValue: 'create'
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   optionClass: 'optionPrimary',
  //   divider: 'dark',
  //   disabled: true,
  //   permissionArr: '',
  //   permission: '',
  //   permissionValue: 'create'
  // },
  {
    data: 'assessees',
    dataValue: 'assessees',
    optionClass: 'optionSecondary',
    permissionArr: '',
    permission: 'AssesseeGroup',
    permissionValue: 'review'
  },
  {
    data: 'assessments',
    dataValue: 'assessments',
    optionClass: 'optionSecondary',
    disabled: false,
    permissionArr: '',
    permission: 'AssessmentGroup',
    permissionValue: 'review'
  },
  {
    data: 'assignments',
    dataValue: 'assignments',
    optionClass: 'optionSecondary',
    disabled: false,
    permissionArr: '',
    permission: 'AssignmentGroup',
    permissionValue: 'review'
  },
  {
    data: 'associates',
    dataValue: 'associates',
    optionClass: 'optionSecondary',
    divider: 'light',
    disabled: false,
    permissionArr: '',
    permission: 'AssociateGroup',
    permissionValue: 'review'
  },
  {
    data: 'assessment centres',
    dataValue: 'assessment centres',
    optionClass: 'optionSecondary',
    divider: 'light',
    disabled: true,
    permissionArr: '',
    permission: '',
    permissionValue: ''
  },
  {
    data: 'culture-profiles',
    dataValue: 'culture-profiles',
    optionClass: 'optionSecondary',
    disabled: false,
    permissionArr: '',
    permission: 'iGuruAnalyticGroup',
    permissionValue: 'review'
  },
  {
    data: 'job-profiles',
    dataValue: 'job-profiles',
    optionClass: 'optionSecondary',
    divider: 'light',
    disabled: false,
    permissionArr: '',
    permission: 'iGuruAnalyticGroup',
    permissionValue: 'review'
  },
  {
    data: 'interviews',
    dataValue: 'interviews',
    optionClass: 'optionSecondary',
    divider: 'light',
    disabled: true,
    permissionArr: '',
    permission: '',
    permissionValue: 'review'
  },
  {
    data: 'items',
    dataValue: 'items',
    optionClass: 'optionSecondary',
    disabled: false,
    permissionArr: '',
    permission: 'ItemGroup',
    permissionValue: 'review'
  }
];
export const TYPE_POPUP_OPTION = [
  {
    data: 'create',
    dataValue: 'create',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionArr: '',
    permission: '',
    permissionValue: 'create'
  },
  {
    data: 'review',
    dataValue: 'review',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: true,
    permissionArr: '',
    permission: '',
    permissionValue: 'review'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   optionClass: 'optionPrimary',
  //   disabled: true,
  //   permissionArr: '',
  //   permission: '',
  //   permissionValue: ''
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   optionClass: 'optionPrimary',
  //   divider: 'dark',
  //   disabled: true,
  //   permissionArr: '',
  //   permission: '',
  //   permissionValue: ''
  // },
  {
    data: 'assessees',
    dataValue: 'assessees',
    optionClass: 'optionSecondary',
    permissionArr: '',
    permission: 'AssesseeType',
    permissionValue: 'review'
  },
  {
    data: 'assessments',
    dataValue: 'assessments',
    optionClass: 'optionSecondary',
    disabled: false,
    permissionArr: '',
    permission: 'AssessmentType',
    permissionValue: 'review'
  },
  {
    data: 'assignments',
    dataValue: 'assignments',
    optionClass: 'optionSecondary',
    disabled: false,
    permissionArr: '',
    permission: 'AssignmentType',
    permissionValue: 'review'
  },
  {
    data: 'associates',
    dataValue: 'associates',
    optionClass: 'optionSecondary',
    divider: 'light',
    disabled: false,
    permissionArr: '',
    permission: 'AssociateType',
    permissionValue: 'review'
  },
  {
    data: 'assessment centres',
    dataValue: 'assessment centres',
    optionClass: 'optionSecondary',
    divider: 'light',
    disabled: true,
    permissionArr: '',
    permission: '',
    permissionValue: ''
  },
  {
    data: 'culture-profiles',
    dataValue: 'culture-profiles',
    optionClass: 'optionSecondary',
    disabled: false,
    permissionArr: '',
    permission: 'iGuruAnalyticType',
    permissionValue: 'review'
  },
  {
    data: 'job-profiles',
    dataValue: 'job-profiles',
    optionClass: 'optionSecondary',
    divider: 'light',
    disabled: false,
    permissionArr: '',
    permission: 'iGuruAnalyticType',
    permissionValue: 'review'
  },
  {
    data: 'interviews',
    dataValue: 'interviews',
    optionClass: 'optionSecondary',
    divider: 'light',
    disabled: true,
    permissionArr: '',
    permission: '',
    permissionValue: 'review'
  },
  {
    data: 'items',
    dataValue: 'items',
    optionClass: 'optionSecondary',
    disabled: false,
    permissionArr: '',
    permission: 'ItemType',
    permissionValue: 'review'
  }
];
export const ROLE_POPUP_OPTION = [
  {
    data: 'create',
    dataValue: 'create',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionArr: '',
    permission: '',
    permissionValue: 'create'
  },
  {
    data: 'review',
    dataValue: 'review',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: true,
    permissionArr: '',
    permission: '',
    permissionValue: 'review'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   optionClass: 'optionPrimary',
  //   disabled: true,
  //   permissionArr: '',
  //   permission: '',
  //   permissionValue: ''
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   optionClass: 'optionPrimary',
  //   divider: 'dark',
  //   disabled: true,
  //   permissionArr: '',
  //   permission: '',
  //   permissionValue: ''
  // },
  {
    data: 'assessees',
    dataValue: 'assessees',
    optionClass: 'optionSecondary',
    disabled: false,
    permissionArr: '',
    permission: 'AssesseeRole',
    permissionValue: 'review'
  },
  {
    data: 'assessments',
    dataValue: 'assessments',
    optionClass: 'optionSecondary',
    disabled: true,
    permissionArr: '',
    permission: '',
    permissionValue: ''
  },
  {
    data: 'assignments',
    dataValue: 'assignments',
    optionClass: 'optionSecondary',
    disabled: true,
    permissionArr: '',
    permission: '',
    permissionValue: ''
  },
  {
    data: 'associates',
    dataValue: 'associates',
    optionClass: 'optionSecondary',
    divider: 'light',
    disabled: false,
    permissionArr: '',
    permission: 'AssociateRole',
    permissionValue: 'review'
  },
  {
    data: 'assessment centres',
    dataValue: 'assessment centres',
    optionClass: 'optionSecondary',
    divider: 'light',
    disabled: true,
    permissionArr: '',
    permission: '',
    permissionValue: ''
  },
  {
    data: 'culture-profiles',
    dataValue: 'culture-profiles',
    optionClass: 'optionSecondary',
    disabled: true,
    permissionArr: '',
    permission: '',
    permissionValue: ''
  },
  {
    data: 'job-profiles',
    dataValue: 'job-profiles',
    optionClass: 'optionSecondary',
    divider: 'light',
    disabled: true,
    permissionArr: '',
    permission: '',
    permissionValue: ''
  },
  {
    data: 'interviews',
    dataValue: 'interviews',
    optionClass: 'optionSecondary',
    divider: 'light',
    disabled: true,
    permissionArr: '',
    permission: '',
    permissionValue: 'review'
  },
  {
    data: 'items',
    dataValue: 'items',
    optionClass: 'optionSecondary',
    disabled: true,
    permissionArr: '',
    permission: '',
    permissionValue: ''
  }
];
export const GROUP_TYPE_POPUP_OPTION = [
  {
    data: 'create',
    dataValue: 'create',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionArr: '',
    permission: '',
    permissionValue: 'create'
  },
  {
    data: 'review',
    dataValue: 'review',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: true,
    permissionArr: '',
    permission: '',
    permissionValue: 'review'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   optionClass: 'optionPrimary',
  //   disabled: true,
  //   permissionArr: '',
  //   permission: '',
  //   permissionValue: ''
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   optionClass: 'optionPrimary',
  //   divider: 'dark',
  //   disabled: true,
  //   permissionArr: '',
  //   permission: '',
  //   permissionValue: ''
  // },
  {
    data: 'assessees',
    dataValue: 'assessees',
    optionClass: 'optionSecondary',
    permissionArr: '',
    permission: 'assesseeAssessee',
    permissionValue: 'review'
  },
  {
    data: 'assessments',
    dataValue: 'assessments',
    optionClass: 'optionSecondary',
    disabled: false,
    permissionArr: '',
    permission: 'assesseeAssessment',
    permissionValue: 'review'
  },
  {
    data: 'assignments',
    dataValue: 'assignments',
    optionClass: 'optionSecondary',
    disabled: false,
    permissionArr: '',
    permission: 'assesseeAssignment',
    permissionValue: 'review'
  },
  {
    data: 'associates',
    dataValue: 'associates',
    optionClass: 'optionSecondary',
    divider: 'light',
    disabled: false,
    permissionArr: '',
    permission: 'assesseeAssociate',
    permissionValue: 'review'
  },
  {
    data: 'assessment centres',
    dataValue: 'assessment centres',
    optionClass: 'optionSecondary',
    divider: 'light',
    disabled: true,
    permissionArr: '',
    permission: '',
    permissionValue: ''
  },
  {
    data: 'culture-profiles',
    dataValue: 'culture-profiles',
    optionClass: 'optionSecondary',
    disabled: false,
    permissionArr: '',
    permission: 'assesseeiGuruAnalytic',
    permissionValue: 'review'
  },
  {
    data: 'job-profiles',
    dataValue: 'job-profiles',
    optionClass: 'optionSecondary',
    divider: 'light',
    disabled: false,
    permissionArr: '',
    permission: 'assesseeiGuruAnalytic',
    permissionValue: 'review'
  },
  {
    data: 'interviews',
    dataValue: 'interviews',
    optionClass: 'optionSecondary',
    divider: 'light',
    disabled: false,
    permissionArr: '',
    permission: '',
    permissionValue: 'review'
  },
  {
    data: 'items',
    dataValue: 'items',
    optionClass: 'optionSecondary',
    disabled: false,
    permissionArr: '',
    permission: 'assesseeItem',
    permissionValue: 'review'
  }
];
export const NODE_POPUP_OPTION = [
  {
    data: 'create',
    dataValue: 'create',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionArr: '',
    permission: 'AssociateNode',
    permissionValue: 'create'
  },
  {
    data: 'review',
    dataValue: 'review',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: false,
    permissionArr: '',
    permission: 'AssociateNode',
    permissionValue: 'review'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   optionClass: 'optionPrimary',
  //   divider: 'dark',
  //   disabled: true
  // },
  {
    data: 'assessees',
    dataValue: 'assessees',
    optionClass: 'optionSecondary',
    disabled: true,
    permissionArr: '',
    permission: '',
    permissionValue: ''
  },
  {
    data: 'assessments',
    dataValue: 'assessments',
    optionClass: 'optionSecondary',
    disabled: true,
    permissionArr: '',
    permission: '',
    permissionValue: ''
  },
  {
    data: 'assignments',
    dataValue: 'assignments',
    optionClass: 'optionSecondary',
    disabled: true,
    permissionArr: '',
    permission: '',
    permissionValue: ''
  },
  {
    data: 'associates',
    dataValue: 'associates',
    optionClass: 'optionSecondary',
    divider: 'light',
    disabled: false,
    permissionArr: '',
    permission: 'AssociateNode',
    permissionValue: 'all'
  },
  {
    data: 'assessment centres',
    dataValue: 'assessment centres',
    optionClass: 'optionSecondary',
    divider: 'light',
    disabled: true,
    permissionArr: '',
    permission: '',
    permissionValue: ''
  },
  {
    data: 'culture-profiles',
    dataValue: 'culture-profiles',
    optionClass: 'optionSecondary',
    disabled: true,
    permissionArr: '',
    permission: '',
    permissionValue: ''
  },
  {
    data: 'job-profiles',
    dataValue: 'job-profiles',
    optionClass: 'optionSecondary',
    divider: 'light',
    disabled: true,
    permissionArr: '',
    permission: '',
    permissionValue: ''
  },
  {
    data: 'interviews',
    dataValue: 'interviews',
    optionClass: 'optionSecondary',
    divider: 'light',
    disabled: true,
    permissionArr: '',
    permission: '',
    permissionValue: ''
  },
  {
    data: 'items',
    dataValue: 'items',
    optionClass: 'optionSecondary',
    disabled: true,
    permissionArr: '',
    permission: '',
    permissionValue: ''
  }
];
export const EXCHANGE_POPUP_OPTION = [
  { data: 'download', dataValue: 'download', optionClass: 'optionPrimary', disabled: true },
  {
    data: 'upload',
    dataValue: 'upload',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: true
  },
  {
    data: 'assessees',
    dataValue: 'assessees',
    optionClass: 'optionSecondary',
    disabled: false,
    permission: 'assesseeAssessee',
    permissionValue: 'review'
  },
  {
    data: 'assessments',
    dataValue: 'assessments',
    optionClass: 'optionSecondary',
    disabled: false,
    permission: 'assesseeAssessment',
    permissionValue: 'review'
  },
  {
    data: 'assignments',
    dataValue: 'assignments',
    optionClass: 'optionSecondary',
    disabled: false,
    permission: 'assesseeAssignment',
    permissionValue: 'review'
  },
  {
    data: 'associates',
    dataValue: 'associates',
    divider: 'light',
    optionClass: 'optionSecondary',
    disabled: false,
    permission: 'assesseeAssociate',
    permissionValue: 'review'
  },
  {
    data: 'assessment centres',
    dataValue: 'assessment centres',
    divider: 'light',
    optionClass: 'optionSecondary',
    disabled: true
  },
  {
    data: 'culture-profiles',
    dataValue: 'culture-profiles',
    optionClass: 'optionSecondary',
    disabled: false,
    permission: 'assesseeiGuruAnalytic',
    permissionValue: 'review'
  },
  {
    data: 'job-profiles',
    dataValue: 'job-profiles',
    divider: 'light',
    optionClass: 'optionSecondary',
    disabled: false,
    permission: 'assesseeiGuruAnalytic',
    permissionValue: 'review'
  },
  {
    data: 'items',
    dataValue: 'items',
    optionClass: 'optionSecondary',
    disabled: false,
    permission: 'assesseeiGuruAnalytic',
    permissionValue: 'review'
  }
];
export const UPLOAD_DOWNLOAD_POPUP = [
  {
    data: 'information',
    dataValue: 'information',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: false
  },
  {
    data: 'all',
    dataValue: 'all',
    optionClass: 'optionSecondary',
    disabled: false
  },
  {
    data: 'key',
    dataValue: 'key',
    optionClass: 'optionSecondary',
    divider: 'light',
    disabled: false
  },
  {
    data: 'template',
    dataValue: 'template',
    optionClass: 'optionForth',
    disabled: false
  },
  {
    data: 'worksheet',
    dataValue: 'worksheet',
    optionClass: 'optionForth',
    divider: 'light',
    disabled: false
  }
];
export const UPLOAD_DOWNLOAD_SUBMODULE_POPUP = [
  {
    data: 'information',
    dataValue: 'information',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: false
  },
  {
    data: 'key',
    dataValue: 'key',
    optionClass: 'optionSecondary',
    divider: 'light',
    disabled: false
  },
  {
    data: 'template',
    dataValue: 'template',
    optionClass: 'optionForth',
    disabled: false
  },
  {
    data: 'worksheet',
    dataValue: 'worksheet',
    optionClass: 'optionForth',
    divider: 'light',
    disabled: false
  }
];
export const UPLOAD_DOWNLOAD_ASSESSEE_POPUP = [
  {
    data: 'information',
    dataValue: 'information',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: false
  },
  {
    data: 'all',
    dataValue: 'all',
    optionClass: 'optionSecondary',
    disabled: false
  },
  {
    data: 'key',
    dataValue: 'key',
    optionClass: 'optionSecondary',
    divider: 'light',
    disabled: false
  },
  {
    data: 'anonymous',
    dataValue: 'anonymous',
    optionClass: 'optionSecondary',
    disabled: false
  },
  {
    data: 'blank',
    dataValue: 'blank',
    optionClass: 'optionSecondary',
    divider: 'light',
    disabled: false
  },
  {
    data: 'first-name other-name last-name',
    dataValue: 'first-name other-name last-name',
    optionClass: 'optionTertiary',
    disabled: false
  },
  {
    data: 'last-name first-name other-name',
    dataValue: 'last-name first-name other-name',
    optionClass: 'optionTertiary',
    divider: 'light',
    disabled: false
  },
  {
    data: 'template',
    dataValue: 'template',
    optionClass: 'optionForth',
    disabled: false
  },
  {
    data: 'worksheet',
    dataValue: 'worksheet',
    optionClass: 'optionForth',
    divider: 'light',
    disabled: false
  }
];
export const UPLOAD_DOWNLOAD_ASSESSEE_SUBMODULE_POPUP = [
  {
    data: 'information',
    dataValue: 'information',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: false
  },
  {
    data: 'key',
    dataValue: 'key',
    optionClass: 'optionSecondary',
    divider: 'light',
    disabled: false
  },
  {
    data: 'template',
    dataValue: 'template',
    optionClass: 'optionForth',
    disabled: false
  },
  {
    data: 'worksheet',
    dataValue: 'worksheet',
    optionClass: 'optionForth',
    divider: 'light',
    disabled: false
  }
];
export const MARKETPLACE_POPUP_OPTION = [
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   optionClass: 'optionPrimary',
  //   divider: 'dark',
  //   disabled: true
  // },
  { data: 'review', dataValue: 'marketplacereview', optionClass: 'optionPrimary', disabled: false }
];
export const CREATE_INFORMATION_POPUP = [
  {
    data: 'information',
    dataValue: 'information',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: false
  },
  {
    data: 'key',
    dataValue: 'key',
    optionClass: 'optionSecondary',
    disabled: false
  }
];
export const REVIEW_DISTINCT_POPUP_OPTION = [
  {
    data: 'distinct',
    dataValue: 'distinct',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: false
  },
  { data: 'active', dataValue: 'active', optionClass: 'optionSecondary' },
  {
    data: 'inactive',
    dataValue: 'inactive',
    optionClass: 'optionSecondary',
    divider: 'light',
    disabled: false
  },
  {
    data: 'all',
    dataValue: 'all',
    optionClass: 'optionSecondary',
    divider: 'light',
    disabled: false
  },
  { data: 'archived', dataValue: 'archived', optionClass: 'optionSecondary', disabled: false }
];

export const REVIEW_LIST_POPUP_OPTION = [
  {
    data: 'allocate',
    dataValue: 'allocate',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'archive',
    dataValue: 'archive',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'delete',
    dataValue: 'delete',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'delete'
  },
  {
    data: 'flag',
    dataValue: 'flagKey',
    dataKey: 'flagKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'move',
    dataValue: 'move',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: ''
  },
  {
    data: 'review',
    dataValue: 'review',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'revise',
    dataValue: 'revise',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'select',
    dataValue: 'select',
    optionClass: 'optionPrimary',
    disabled: false,
  },
  {
    data: 'share',
    dataValue: 'shareFee',
    dataKey: 'share',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'share'
  },
  {
    data: 'suspend',
    dataValue: 'suspend',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'terminate',
    dataValue: 'terminate',
    optionClass: 'optionPrimary',
    // divider: 'dark',
    disabled: false,
    permissionValue: 'revise'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // }
];
export const ASSESSEE_RELATED_ASSIGNMENT_REVIEWLIST_POPUP = [
  {
    data: 'allocate',
    dataValue: 'allocate',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'archive',
    dataValue: 'archive',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'delete',
    dataValue: 'delete',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'delete'
  },
  {
    data: 'flag',
    dataValue: 'flagKey',
    dataKey: 'flagKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'move',
    dataValue: 'move',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: ''
  },
  {
    data: 'reset',
    dataValue: 'reset',
    dataKey: 'reset',
    optionClass: 'optionPrimary',
    disabled: false,
    // permissionValue: 'revise'
  },
  {
    data: 'review',
    dataValue: 'review',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'revise',
    dataValue: 'revise',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'share',
    dataValue: 'shareFee',
    dataKey: 'share',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'share'
  },
  {
    data: 'suspend',
    dataValue: 'suspend',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'terminate',
    dataValue: 'terminate',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'analytics',
    dataValue: 'analytics',
    dataKey: 'review',
    optionClass: 'optionPrimary',
    disabled: false,
    divider: 'dark',
    permissionValue: 'review'
  },
  {
    data: 'assessments',
    dataValue: 'assessments',
    dataKey: 'review',
    optionClass: 'optionPrimary',
    disabled: false,
    // divider: 'dark',
    permissionValue: 'review'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // }
];
export const ASSESSEE_REVIEW_LIST_POPUP_OPTION = [
  {
    data: 'allocate',
    dataValue: 'allocate',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'approve',
    dataValue: 'approve',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'create'
  },
  {
    data: 'archive',
    dataValue: 'archive',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'delete',
    dataValue: 'delete',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'delete'
  },
  {
    data: 'flag',
    dataValue: 'flagKey',
    dataKey: 'flagKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'move',
    dataValue: 'move',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: ''
  },
  {
    data: 'reset',
    dataValue: 'resetAdminister',
    dataKey: 'resetAdminister',
    optionClass: 'optionPrimary',
    disabled: false,
    // permissionValue: 'revise'
  },
  {
    data: 'review',
    dataValue: 'review',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'revise',
    dataValue: 'revise',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'share',
    dataValue: 'shareFee',
    dataKey: 'share',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'share'
  },
  {
    data: 'suspend',
    dataValue: 'suspend',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'terminate',
    dataValue: 'terminate',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'assignments',
    dataValue: 'assignments',
    dataKey: 'review',
    optionClass: 'optionPrimary',
    // divider: 'dark',
    disabled: false,
    permissionValue: 'review'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // }
];
export const ASSESSMENT_REVIEW_LIST_POPUP_OPTION = [
  {
    data: 'allocate',
    dataValue: 'allocate',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'archive',
    dataValue: 'archive',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'delete',
    dataValue: 'delete',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'delete'
  },
  {
    data: 'flag',
    dataValue: 'flagKey',
    dataKey: 'flagKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'move',
    dataValue: 'move',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: ''
  },
  {
    data: 'publish',
    dataValue: 'publish',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'review',
    dataValue: 'review',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'revise',
    dataValue: 'revise',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'share',
    dataValue: 'shareFee',
    dataKey: 'share',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'share'
  },
  {
    data: 'suspend',
    dataValue: 'suspend',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'terminate',
    dataValue: 'terminate',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'clusters',
    dataValue: 'clusters',
    //dataKey: 'review',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'grades',
    dataValue: 'grades',
    //dataKey: 'review',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'norms',
    dataValue: 'norms',
    //dataKey: 'review',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'scales',
    dataValue: 'scales',
    //dataKey: 'review',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'scores',
    dataValue: 'scores',
    //dataKey: 'review',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'sections',
    dataValue: 'sections',
    //dataKey: 'review',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'versions',
    dataValue: 'versions',
    //dataKey: 'review',
    optionClass: 'optionPrimary',
    disabled: false,
    divider: 'dark',
    permissionValue: 'review'
  },
  {
    data: 'items',
    dataValue: 'items',
    dataKey: 'review',
    optionClass: 'optionPrimary',
    disabled: false,
    // divider: 'dark',
    permissionValue: 'review'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // }
];
export const JOB_DISTINCT_REVIEW_LIST_POPUP_ITERBLE_OPTION = [
  {
    data: 'allocate',
    dataValue: 'allocate',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'archive',
    dataValue: 'archive',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'delete',
    dataValue: 'delete',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'delete'
  },
  {
    data: 'flag',
    dataValue: 'flagKey',
    dataKey: 'flagKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'move',
    dataValue: 'move',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: ''
  },
  {
    data: 'publish',
    dataValue: 'publish',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'review',
    dataValue: 'review',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'revise',
    dataValue: 'revise',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'share',
    dataValue: 'shareFee',
    dataKey: 'share',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'share'
  },
  {
    data: 'suspend',
    dataValue: 'suspend',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'terminate',
    dataValue: 'terminate',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: false,
    permissionValue: 'revise'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // }
];
export const BASIC_PUBLISH_REVIEW_LIST_NOT_ITERBLE_POPUP = [
  {
    data: 'allocate',
    dataValue: 'allocate',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'archive',
    dataValue: 'archive',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'delete',
    dataValue: 'delete',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'delete'
  },
  {
    data: 'flag',
    dataValue: 'flagKey',
    dataKey: 'flagKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'move',
    dataValue: 'move',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: ''
  },
  {
    data: 'publish',
    dataValue: 'publish',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'review',
    dataValue: 'review',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'revise',
    dataValue: 'revise',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'share',
    dataValue: 'shareFee',
    dataKey: 'share',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'share'
  },
  {
    data: 'suspend',
    dataValue: 'suspend',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'terminate',
    dataValue: 'terminate',
    optionClass: 'optionPrimary',
    // divider: 'dark',
    disabled: false,
    permissionValue: 'revise'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // }
];
export const CULTURE_REVIEW_LIST_POPUP_OPTION = [
  {
    data: 'allocate',
    dataValue: 'allocate',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'archive',
    dataValue: 'archive',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'delete',
    dataValue: 'delete',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'delete'
  },
  {
    data: 'flag',
    dataValue: 'flagKey',
    dataKey: 'flagKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'move',
    dataValue: 'move',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: ''
  },
  {
    data: 'publish',
    dataValue: 'publish',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'review',
    dataValue: 'review',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'revise',
    dataValue: 'revise',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'share',
    dataValue: 'shareFee',
    dataKey: 'share',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'share'
  },
  {
    data: 'suspend',
    dataValue: 'suspend',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'terminate',
    dataValue: 'terminate',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'assessments',
    dataValue: 'assessments',
    dataKey: 'review',
    optionClass: 'optionPrimary',
    // divider: 'dark',
    disabled: false,
    permissionValue: 'review'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // }
];
export const ASSOCIATE_REVIEW_LIST_POPUP_OPTION = [
  {
    data: 'allocate',
    dataValue: 'allocate',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'archive',
    dataValue: 'archive',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'delete',
    dataValue: 'delete',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'delete'
  },
  {
    data: 'flag',
    dataValue: 'flagKey',
    dataKey: 'flagKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'review',
    dataValue: 'review',
    dataKey: 'reviewKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'revise',
    dataValue: 'revise',
    dataKey: 'reviseKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'share',
    dataValue: 'shareFee',
    dataKey: 'share',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'share'
  },
  {
    data: 'suspend',
    dataValue: 'suspend',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'terminate',
    dataValue: 'terminate',
    optionClass: 'optionPrimary',
    // divider: 'dark',
    disabled: false,
    permissionValue: 'revise'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // }
];
export const ASSIGNMNET_TYPE_ASSIGNMENT_REVIEW_LIST_POPUP_OPTION = [
  {
    data: 'allocate',
    dataValue: 'allocate',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'archive',
    dataValue: 'archive',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'delete',
    dataValue: 'delete',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'delete'
  },
  {
    data: 'flag',
    dataValue: 'flagKey',
    dataKey: 'flagKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'review',
    dataValue: 'review',
    dataKey: 'review',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'revise',
    dataValue: 'revise',
    dataKey: 'revise',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'share',
    dataValue: 'share',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'share'
  },
  {
    data: 'suspend',
    dataValue: 'suspend',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'terminate',
    dataValue: 'terminate',
    optionClass: 'optionPrimary',
    // divider: 'dark',
    disabled: false,
    permissionValue: 'revise'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // }
];
export const GROUP_REVIEW_DISTINCT_REVIEW_LIST_POPUP_OPTION = [
  {
    data: 'allocate',
    dataValue: 'allocate',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'archive',
    dataValue: 'archive',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'delete',
    dataValue: 'delete',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'delete'
  },
  {
    data: 'flag',
    dataValue: 'flagKey',
    dataKey: 'flagKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'review',
    dataValue: 'review',
    dataKey: 'review',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'revise',
    dataValue: 'revise',
    dataKey: 'revise',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'share',
    dataValue: 'shareFee',
    dataKey: 'share',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'share'
  },
  {
    data: 'suspend',
    dataValue: 'suspend',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'terminate',
    dataValue: 'terminate',
    optionClass: 'optionPrimary',
    // divider: 'dark',
    disabled: false,
    permissionValue: 'revise'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // }
];
export const ASSIGNMENT_ASSESMENT_REVIEW_LIST_POPUP_OPTION = [
  {
    data: 'allocate',
    dataValue: 'allocate',
    optionClass: 'optionPrimary',
    disabled: false
  },
  {
    data: 'archive',
    dataValue: 'archive',
    optionClass: 'optionPrimary',
    disabled: false
  },
  {
    data: 'delete',
    dataValue: 'delete',
    optionClass: 'optionPrimary',
    disabled: false
  },
  {
    data: 'flag',
    dataValue: 'flagKey',
    dataKey: 'flagKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'reset',
    dataValue: 'reset',
    dataKey: 'reset',
    optionClass: 'optionPrimary',
    disabled: false,
    // permissionValue: 'revise'
  },
  {
    data: 'review',
    dataValue: 'review',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'revise',
    dataValue: 'revise',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'share',
    dataValue: 'shareFee',
    dataKey: 'share',
    optionClass: 'optionPrimary',
    disabled: false
  },
  {
    data: 'suspend',
    dataValue: 'suspend',
    optionClass: 'optionPrimary',
    disabled: false
  },
  {
    data: 'terminate',
    dataValue: 'terminate',
    optionClass: 'optionPrimary',
    // divider: 'dark',
    disabled: false
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // }
];
export const ASSIGNMENT_ASSESSEE_REVIEW_LIST_POPUP_OPTION = [
  {
    data: 'allocate',
    dataValue: 'allocate',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: 'review'
  },
  {
    data: 'archive',
    dataValue: 'archive',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: 'revise'
  },
  {
    data: 'delete',
    dataValue: 'delete',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'delete'
  },
  {
    data: 'flag',
    dataValue: 'flagKey',
    dataKey: 'flagKey',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: 'revise'
  },
  {
    data: 'resend',
    dataValue: 'resend',
    dataKey: 'resend',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: 'review'
  },
  {
    data: 'reset',
    dataValue: 'reset',
    dataKey: 'reset',
    optionClass: 'optionPrimary',
    disabled: false,
    // permissionValue: 'revise'
  },
  {
    data: 'review',
    dataValue: 'review',
    dataKey: 'review',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'revise',
    dataValue: 'revise',
    dataKey: 'revise',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: 'revise'
  },
  {
    data: 'share',
    dataValue: 'shareFee',
    dataKey: 'share',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: 'share'
  },
  {
    data: 'suspend',
    dataValue: 'suspend',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'terminate',
    dataValue: 'terminate',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'analytics',
    dataValue: 'analytics',
    dataKey: 'review',
    optionClass: 'optionPrimary',
    disabled: false,
    divider: 'dark',
    permissionValue: 'review'
  },
  {
    data: 'assessments',
    dataValue: 'assessments',
    dataKey: 'review',
    optionClass: 'optionPrimary',
    // divider: 'dark',
    disabled: false,
    permissionValue: 'review'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // }
];
export const SECTION_SCALE_CLUSTER_REVIEW_LIST_POPUP_OPTION = [
  {
    data: 'allocate',
    dataValue: 'allocate',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'archive',
    dataValue: 'archive',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'delete',
    dataValue: 'delete',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'delete'
  },
  {
    data: 'flag',
    dataValue: 'flagKey',
    dataKey: 'flagKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'review',
    dataValue: 'review',
    dataKey: 'reviewKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'

  },
  {
    data: 'revise',
    dataValue: 'revise',
    dataKey: 'reviseKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'suspend',
    dataValue: 'suspend',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'terminate',
    dataValue: 'terminate',
    optionClass: 'optionPrimary',
    // divider: 'dark',
    disabled: false,
    permissionValue: 'revise'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // }
];
export const MATRICS_REVIEW_LIST_POPUP_OPTION = [
  {
    data: 'allocate',
    dataValue: 'allocate',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: 'review'
  },
  {
    data: 'archive',
    dataValue: 'archive',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: 'revise'
  },
  {
    data: 'delete',
    dataValue: 'delete',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'delete'
  },
  {
    data: 'flag',
    dataValue: 'flagKey',
    dataKey: 'flagKey',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: 'revise'
  },
  {
    data: 'review',
    dataValue: 'review',
    dataKey: 'reviewKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'revise',
    dataValue: 'revise',
    dataKey: 'reviseKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'suspend',
    dataValue: 'suspend',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: 'revise'
  },
  {
    data: 'terminate',
    dataValue: 'terminate',
    optionClass: 'optionPrimary',
    // divider: 'dark',
    disabled: true,
    permissionValue: 'revise'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // }
];
export const PROFILER_REVIEW_LIST_POPUP_OPTION = [
  {
    data: 'allocate',
    dataValue: 'allocate',
    optionClass: 'optionPrimary',
    disabled: true
  },
  {
    data: 'archive',
    dataValue: 'archive',
    optionClass: 'optionPrimary',
    disabled: true
  },
  {
    data: 'delete',
    dataValue: 'delete',
    optionClass: 'optionPrimary',
    disabled: true
  },
  {
    data: 'flag',
    dataValue: 'flagKey',
    dataKey: 'flagKey',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: 'revise'
  },
  {
    data: 'review',
    dataValue: 'review',
    dataKey: 'reviewKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'revise',
    dataValue: 'revise',
    dataKey: 'reviseKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'

  },
  {
    data: 'suspend',
    dataValue: 'suspend',
    optionClass: 'optionPrimary',
    disabled: true
  },
  {
    data: 'terminate',
    dataValue: 'terminate',
    optionClass: 'optionPrimary',
    // divider: 'dark',
    disabled: true
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // }
];
export const PROFILER_LIST_POPUP_OPTION = [
  {
    data: 'allocate',
    dataValue: 'allocate',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: 'review'
  },
  {
    data: 'archive',
    dataValue: 'archive',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: 'revise'
  },
  {
    data: 'delete',
    dataValue: 'delete',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: 'delete'
  },
  {
    data: 'flag',
    dataValue: 'flagKey',
    dataKey: 'flagKey',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: 'revise'
  },
  {
    data: 'publish',
    dataValue: 'publish',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: 'revise'
  },
  {
    data: 'review',
    dataValue: 'review',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'revise',
    dataValue: 'revise',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'select',
    dataValue: 'selection',
    dataKey: 'select',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review & other'
  },
  {
    data: 'share',
    dataValue: 'share',
    dataKey: 'share',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: 'share'
  },
  {
    data: 'suspend',
    dataValue: 'suspend',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: 'revise'
  },
  {
    data: 'terminate',
    dataValue: 'terminate',
    optionClass: 'optionPrimary',
    // divider: 'dark',
    disabled: true,
    permissionValue: 'revise'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // }
];
export const ASSIGNMENT_REVIEW_LIST_POPUP_OPTION = [
  {
    data: 'administer', //lable FRP
    dataValue: 'administer', //badgeName of 2nd popup
    dataKey: 'administer',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'allocate',
    dataValue: 'allocate',
    dataKey: 'allocate',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'archive',
    dataValue: 'archive',
    dataKey: 'archive',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'delete',
    dataValue: 'delete',
    dataKey: 'delete',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'delete'
  },
  {
    data: 'flag',
    dataValue: 'flagKey',
    dataKey: 'flagKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'move',
    dataValue: 'move',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: ''
  },
  {
    data: 'publish',
    dataValue: 'publish',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'reset',
    dataValue: 'reset',
    optionClass: 'optionPrimary',
    disabled: false,
    // permissionValue: 'revise'
  },
  {
    data: 'review',
    dataValue: 'review',
    dataKey: 'review',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'revise',
    dataValue: 'revise',
    dataKey: 'revise',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'share',
    dataValue: 'shareFee',
    dataKey: 'share',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'share'
  },
  {
    data: 'suspend',
    dataValue: 'suspend',
    dataKey: 'suspend',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'terminate',
    dataValue: 'terminate',
    dataKey: 'terminate',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'analytics',
    dataValue: 'analytics',
    dataKey: 'review',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'assessees',
    dataValue: 'assessees',
    dataKey: 'review',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'assessments',
    dataValue: 'assessments',
    dataKey: 'review',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'culture-profiles',
    //dataValue: 'culture-profiles',
    dataValue: 'culture-profiles',
    dataKey: 'review',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'job-profiles',
    dataValue: 'job-profiles',
    dataKey: 'review',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: false,
    permissionValue: 'review'
  },
  // {
  //   data: 'gauges',
  //   dataValue: 'gauges',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: false,
  //   permissionValue: 'review' changes
  // },
  {
    data: 'scores',
    dataValue: 'scores',
    dataKey: 'scores',
    optionClass: 'optionPrimary',
    // divider: 'dark',
    disabled: false,
    permissionValue: 'review'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // }
];
export const GAUGE_REVIEW_LIST_POPUP_OPTION = [
  {
    data: 'allocate',
    dataValue: 'allocate',
    dataKey: 'allocate',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'archive',
    dataValue: 'archive',
    dataKey: 'archive',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'delete',
    dataValue: 'delete',
    dataKey: 'delete',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'delete'
  },
  {
    data: 'flag',
    dataValue: 'flagKey',
    dataKey: 'flagKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'move',
    dataValue: 'move',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: ''
  },
  {
    data: 'publish',
    dataValue: 'publish',
    dataKey: 'publish',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'review',
    dataValue: 'review',
    dataKey: 'review',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'revise',
    dataValue: 'revise',
    dataKey: 'revise',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'suspend',
    dataValue: 'suspend',
    dataKey: 'suspend',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'terminate',
    dataValue: 'terminate',
    dataKey: 'terminate',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'assessees',
    dataValue: 'assessees',
    dataKey: 'review',
    optionClass: 'optionPrimary',
    // divider: 'dark',
    disabled: false,
    permissionValue: 'review'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // }
];
export const ASSESSEE_NODE_ROLE_REVIEW_LIST_POPUP_OPTION = [
  {
    data: 'allocate',
    dataValue: 'allocate',
    dataKey: 'allocate',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'archive',
    dataValue: 'archive',
    dataKey: 'archive',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'delete',
    dataValue: 'delete',
    dataKey: 'delete',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'delete'
  },
  {
    data: 'flag',
    dataValue: 'flagKey',
    dataKey: 'flagKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'move',
    dataValue: 'move',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: ''
  },
  {
    data: 'review',
    dataValue: 'review',
    dataKey: 'reviewKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'revise',
    dataValue: 'revise',
    dataKey: 'reviseKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'share',
    dataValue: 'share',//to br change
    dataKey: 'share',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'share'
  },
  {
    data: 'suspend',
    dataValue: 'suspend',
    dataKey: 'suspend',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'terminate',
    dataValue: 'terminate',
    dataKey: 'terminate',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'assessees',
    dataValue: 'assessees',
    dataKey: 'review',
    optionClass: 'optionPrimary',
    // divider: 'dark',
    disabled: false,
    permissionValue: 'review'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // }
];
export const ASSESSEE_GROUP_REVIEW_LIST_POPUP_OPTION = [
  {
    data: 'allocate',
    dataValue: 'allocate',
    dataKey: 'allocate',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'archive',
    dataValue: 'archive',
    dataKey: 'archive',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'delete',
    dataValue: 'delete',
    dataKey: 'delete',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'delete'
  },
  {
    data: 'flag',
    dataValue: 'flagKey',
    dataKey: 'flagKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'move',
    dataValue: 'move',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: ''
  },
  {
    data: 'review',
    dataValue: 'review',
    dataKey: 'reviewKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'revise',
    dataValue: 'revise',
    dataKey: 'reviseKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'share',
    dataValue: 'shareFee',
    dataKey: 'share',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'share'
  },
  {
    data: 'suspend',
    dataValue: 'suspend',
    dataKey: 'suspend',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'terminate',
    dataValue: 'terminate',
    dataKey: 'terminate',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'assessees',
    dataValue: 'assessees',
    dataKey: 'review',
    optionClass: 'optionPrimary',
    // divider: 'dark',
    disabled: false,
    permissionValue: 'review'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // }
];
export const ASSESSEE_GROUP_ASSESSEE_REVIEW_LIST_POPUP_OPTION = [
  {
    data: 'allocate',
    dataValue: 'allocate',
    dataKey: 'allocate',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'archive',
    dataValue: 'archive',
    dataKey: 'archive',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'delete',
    dataValue: 'delete',
    dataKey: 'delete',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'delete'
  },
  {
    data: 'flag',
    dataValue: 'flagKey',
    dataKey: 'flagKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'move',
    dataValue: 'move',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: ''
  },
  {
    data: 'review',
    dataValue: 'review',
    dataKey: 'reviewKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'revise',
    dataValue: 'revise',
    dataKey: 'reviseKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'share',
    dataValue: 'shareFee',
    dataKey: 'share',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'share'
  },
  {
    data: 'suspend',
    dataValue: 'suspend',
    dataKey: 'suspend',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'terminate',
    dataValue: 'terminate',
    dataKey: 'terminate',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'assessees',
    dataValue: 'assessees',
    dataKey: 'review',
    optionClass: 'optionPrimary',
    // divider: 'dark',
    disabled: false,
    permissionValue: 'review'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // }
];
export const ASSESSMENT_NODE_TYPE_REVIEW_LIST_POPUP_OPTION = [
  {
    data: 'allocate',
    dataValue: 'allocate',
    dataKey: 'allocate',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'archive',
    dataValue: 'archive',
    dataKey: 'archive',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'delete',
    dataValue: 'delete',
    dataKey: 'delete',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'delete'
  },
  {
    data: 'flag',
    dataValue: 'flagKey',
    dataKey: 'flagKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'move',
    dataValue: 'move',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: ''
  },
  {
    data: 'review',
    dataValue: 'review',
    dataKey: 'reviewKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'revise',
    dataValue: 'revise',
    dataKey: 'reviseKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'share',
    dataValue: 'share',
    dataKey: 'share',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'share'
  },
  {
    data: 'suspend',
    dataValue: 'suspend',
    dataKey: 'suspend',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'terminate',
    dataValue: 'terminate',
    dataKey: 'terminate',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'assessments',
    dataValue: 'assessments',
    dataKey: 'review',
    optionClass: 'optionPrimary',
    // divider: 'dark',
    disabled: false,
    permissionValue: 'review'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // }
];
export const ASSIGNMENT_NODE_TYPE_REVIEW_LIST_POPUP_OPTION = [
  {
    data: 'allocate',
    dataValue: 'allocate',
    dataKey: 'allocate',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'archive',
    dataValue: 'archive',
    dataKey: 'archive',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'delete',
    dataValue: 'delete',
    dataKey: 'delete',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'delete'
  },
  {
    data: 'flag',
    dataValue: 'flagKey',
    dataKey: 'flagKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'move',
    dataValue: 'move',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: ''
  },
  {
    data: 'review',
    dataValue: 'review',
    dataKey: 'reviewKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'revise',
    dataValue: 'revise',
    dataKey: 'reviseKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'share',
    dataValue: 'share',
    dataKey: 'share',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'share'
  },
  {
    data: 'suspend',
    dataValue: 'suspend',
    dataKey: 'suspend',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'terminate',
    dataValue: 'terminate',
    dataKey: 'terminate',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'assignments',
    dataValue: 'assignments',
    dataKey: 'review',
    optionClass: 'optionPrimary',
    // divider: 'dark',
    disabled: false,
    permissionValue: 'review'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // }
];
export const ASSIGNMENT_GROUP_REVIEW_LIST_POPUP_OPTION = [
  {
    data: 'allocate',
    dataValue: 'allocate',
    dataKey: 'allocate',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'archive',
    dataValue: 'archive',
    dataKey: 'archive',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'delete',
    dataValue: 'delete',
    dataKey: 'delete',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'delete'
  },
  {
    data: 'flag',
    dataValue: 'flagKey',
    dataKey: 'flagKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'move',
    dataValue: 'move',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: ''
  },
  {
    data: 'review',
    dataValue: 'review',
    dataKey: 'reviewKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'revise',
    dataValue: 'revise',
    dataKey: 'reviseKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'share',
    dataValue: 'shareFee',
    dataKey: 'share',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'share'
  },
  {
    data: 'suspend',
    dataValue: 'suspend',
    dataKey: 'suspend',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'terminate',
    dataValue: 'terminate',
    dataKey: 'terminate',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'assignments',
    dataValue: 'assignments',
    dataKey: 'review',
    optionClass: 'optionPrimary',
    // divider: 'dark',
    disabled: false,
    permissionValue: 'review'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // }
];
export const ANALYTICS_TYPE_REVIEW_LIST_POPUP_OPTION = [
  {
    data: 'allocate',
    dataValue: 'allocate',
    dataKey: 'allocate',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'archive',
    dataValue: 'archive',
    dataKey: 'archive',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'delete',
    dataValue: 'delete',
    dataKey: 'delete',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'delete'
  },
  {
    data: 'flag',
    dataValue: 'flagKey',
    dataKey: 'flagKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'move',
    dataValue: 'move',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: ''
  },
  {
    data: 'review',
    dataValue: 'review',
    dataKey: 'reviewKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'revise',
    dataValue: 'revise',
    dataKey: 'reviseKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'share',
    dataValue: 'share',
    dataKey: 'share',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'share'
  },
  {
    data: 'suspend',
    dataValue: 'suspend',
    dataKey: 'suspend',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'terminate',
    dataValue: 'terminate',
    dataKey: 'terminate',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'analytics',
    dataValue: 'analytics',
    dataKey: 'review',
    optionClass: 'optionPrimary',
    // divider: 'dark',
    disabled: false,
    permissionValue: 'review'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // }
];
export const ASSESSEE_ASSESSEE_ASSIGNMENT_REVIEW_LIST_POPUP_OPTION = [
  {
    data: 'allocate',
    dataValue: 'allocate',
    dataKey: 'allocate',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'archive',
    dataValue: 'archive',
    dataKey: 'archive',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'delete',
    dataValue: 'delete',
    dataKey: 'delete',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'delete'
  },
  {
    data: 'flag',
    dataValue: 'flagKey',
    dataKey: 'flagKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'move',
    dataValue: 'move',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: ''
  },
  {
    data: 'review',
    dataValue: 'review',
    dataKey: 'reviewKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'revise',
    dataValue: 'revise',
    dataKey: 'reviseKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'share',
    dataValue: 'shareFee',
    dataKey: 'share',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'share'
  },
  {
    data: 'suspend',
    dataValue: 'suspend',
    dataKey: 'suspend',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'terminate',
    dataValue: 'terminate',
    dataKey: 'terminate',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'analytics',
    dataValue: 'analytics',
    dataKey: 'review',
    optionClass: 'optionPrimary',
    // divider: 'dark',
    disabled: false,
    permissionValue: 'review'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // }
];
export const ASSOCIATE_NODE_ROLE_REVIEW_LIST_POPUP_OPTION = [
  {
    data: 'allocate',
    dataValue: 'allocate',
    dataKey: 'allocate',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'archive',
    dataValue: 'archive',
    dataKey: 'archive',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'delete',
    dataValue: 'delete',
    dataKey: 'delete',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'delete'
  },
  {
    data: 'flag',
    dataValue: 'flagKey',
    dataKey: 'flagKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'move',
    dataValue: 'move',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: 'revise'
  },
  {
    data: 'review',
    dataValue: 'review',
    dataKey: 'reviewKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'revise',
    dataValue: 'revise',
    dataKey: 'reviseKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'share',
    dataValue: 'share',
    dataKey: 'share',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'share'
  },
  {
    data: 'suspend',
    dataValue: 'suspend',
    dataKey: 'suspend',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'terminate',
    dataValue: 'terminate',
    dataKey: 'terminate',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'associates',
    dataValue: 'associates',
    dataKey: 'review',
    optionClass: 'optionPrimary',
    // divider: 'dark',
    disabled: false,
    permissionValue: 'review'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // }
];
export const NODE_ROLE_TYPE_REVIEW_LIST_POPUP_OPTION = [
  {
    data: 'allocate',
    dataValue: 'allocate',
    dataKey: 'allocate',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'archive',
    dataValue: 'archive',
    dataKey: 'archive',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'delete',
    dataValue: 'delete',
    dataKey: 'delete',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'delete'
  },
  {
    data: 'flag',
    dataValue: 'flagKey',
    dataKey: 'flagKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'review',
    dataValue: 'review',
    dataKey: 'reviewKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'revise',
    dataValue: 'revise',
    dataKey: 'reviseKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'share',
    dataValue: 'share',
    dataKey: 'share',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'share'
  },
  {
    data: 'suspend',
    dataValue: 'suspend',
    dataKey: 'suspend',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'terminate',
    dataValue: 'terminate',
    dataKey: 'terminate',
    optionClass: 'optionPrimary',
    // divider: 'dark',
    disabled: false,
    permissionValue: 'revise'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // }
];
export const GROUP_REVIEW_LIST_POPUP_OPTION = [
  {
    data: 'allocate',
    dataValue: 'allocate',
    dataKey: 'allocate',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'archive',
    dataValue: 'archive',
    dataKey: 'archive',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'delete',
    dataValue: 'delete',
    dataKey: 'delete',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'delete'
  },
  {
    data: 'flag',
    dataValue: 'flagKey',
    dataKey: 'flagKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'review',
    dataValue: 'review',
    dataKey: 'reviewKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'revise',
    dataValue: 'revise',
    dataKey: 'reviseKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'share',
    dataValue: 'shareFee',
    dataKey: 'share',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'share'
  },
  {
    data: 'suspend',
    dataValue: 'suspend',
    dataKey: 'suspend',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'terminate',
    dataValue: 'terminate',
    dataKey: 'terminate',
    optionClass: 'optionPrimary',
    // divider: 'dark',
    disabled: false,
    permissionValue: 'revise'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // }
];
export const ASSESSEES_SUBMODULE_REVIEW_LIST_POPUP_OPTION = [
  {
    data: 'allocate',
    dataValue: 'allocate',
    dataKey: 'allocate',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'archive',
    dataValue: 'archive',
    dataKey: 'archive',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'delete',
    dataValue: 'delete',
    dataKey: 'delete',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'delete'
  },
  {
    data: 'flag',
    dataValue: 'flagKey',
    dataKey: 'flagKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'review',
    dataValue: 'review',
    dataKey: 'reviewKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'revise',
    dataValue: 'revise',
    dataKey: 'reviseKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'share',
    dataValue: 'share',
    dataKey: 'share',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'share'
  },
  {
    data: 'suspend',
    dataValue: 'suspend',
    dataKey: 'suspend',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'terminate',
    dataValue: 'terminate',
    dataKey: 'terminate',
    optionClass: 'optionPrimary',
    // divider: 'dark',
    disabled: false,
    permissionValue: 'revise'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // }
];
export const ASSESSEES_GROUP_REVIEW_LIST_POPUP_OPTION = [
  {
    data: 'allocate',
    dataValue: 'allocate',
    dataKey: 'allocate',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'archive',
    dataValue: 'archive',
    dataKey: 'archive',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'delete',
    dataValue: 'delete',
    dataKey: 'delete',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'delete'
  },
  {
    data: 'flag',
    dataValue: 'flagKey',
    dataKey: 'flagKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'review',
    dataValue: 'review',
    dataKey: 'reviewKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'revise',
    dataValue: 'revise',
    dataKey: 'reviseKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'share',
    dataValue: 'shareFee',
    dataKey: 'share',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'share'
  },
  {
    data: 'suspend',
    dataValue: 'suspend',
    dataKey: 'suspend',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'terminate',
    dataValue: 'terminate',
    dataKey: 'terminate',
    optionClass: 'optionPrimary',
    // divider: 'dark',
    disabled: false,
    permissionValue: 'revise'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // }
];
export const ASSOCIATE_DISTINCT_REVIEW_LIST_POPUP_OPTION = [
  {
    data: 'allocate',
    dataValue: 'allocate',
    dataKey: 'allocate',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'approve',
    dataValue: 'approve',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'create'
  },
  {
    data: 'archive',
    dataValue: 'archive',
    dataKey: 'archive',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'delete',
    dataValue: 'delete',
    dataKey: 'delete',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'delete'
  },
  {
    data: 'flag',
    dataValue: 'flagKey',
    dataKey: 'flagKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'move',
    dataValue: 'move',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: ''
  },
  {
    data: 'review',
    dataValue: 'review',
    // dataKey: 'reviewKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'revise',
    dataValue: 'revise',
    // dataKey: 'reviseKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'share',
    dataValue: 'shareApiCall',
    dataKey: 'SHARED',
    optionClass: 'optionPrimary',
    disabled: true
  },
  {
    data: 'suspend',
    dataValue: 'suspend',
    dataKey: 'suspend',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'terminate',
    dataValue: 'terminate',
    dataKey: 'terminate',
    optionClass: 'optionPrimary',
    // divider: 'dark',
    disabled: false,
    permissionValue: 'revise'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // }
];
export const ASSESSEE_ASSIGNMENT_REVIEW_LIST_POPUP_OPTION = [
  {
    data: 'flag',
    dataValue: 'flagKey',
    dataKey: 'flagKey',
    optionClass: 'optionPrimary',
    disabled: true
  },
  {
    data: 'review',
    dataValue: 'review',
    dataKey: 'review',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: true
  },
  {
    data: 'analytics',
    dataValue: 'analytics',
    dataKey: 'review',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // }
];
export const ASSESSEE_ASSIGNMENT_ASSESSMENT_REVIEW_LIST_POPUP_OPTION = [
  {
    data: 'flag',
    dataValue: 'flagKey',
    dataKey: 'flagKey',
    optionClass: 'optionPrimary',
    disabled: true
  },
  {
    data: 'review',
    dataValue: 'review',
    dataKey: 'review',
    optionClass: 'optionPrimary',
    // divider: 'dark',
    disabled: true
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // }
];
export const ASSESSEE_ASSIGNMENT_TRIPLE_POPUP_OPTION = [
  {
    data: 'flag',
    dataValue: 'flag',
    dataKey: 'flag',
    optionClass: 'optionPrimary',
    disabled: true
  },
  {
    data: 'review',
    dataValue: 'review',
    dataKey: 'reviewSelf',
    optionClass: 'optionPrimary',
    disabled: false
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // }
];
export const ASSESSEE_ASSIGNMENT_ASSESSMENT_TRIPLE_POPUP_OPTION = [
  {
    data: 'flag',
    dataValue: 'flag',
    dataKey: 'flag',
    optionClass: 'optionPrimary',
    disabled: true
  },
  {
    data: 'review',
    dataValue: 'review',
    dataKey: 'reviewSelfAssessment',
    optionClass: 'optionPrimary',
    disabled: false
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // }
];
// export const ALLOCATE_POPUP = [
//   {
//     data: 'assessees',
//     dataValue: 'assessees',
//     optionClass: 'optionPrimary',
//     disabled: false
//   },
//   {
//     data: 'assessments',
//     dataValue: 'assessments',
//     optionClass: 'optionPrimary',
//     disabled: false
//   },
//   {
//     data: 'assignments',
//     dataValue: 'assignments',
//     optionClass: 'optionPrimary',
//     disabled: false
//   },
//   {
//     data: 'associates',
//     dataValue: 'associates',
//     optionClass: 'optionPrimary',
//     divider: 'dark',
//     disabled: false
//   },
//   {
//     data: 'groups',
//     dataValue: 'groups',
//     optionClass: 'optionPrimary',
//     disabled: false
//   },
//   {
//     data: 'managers',
//     dataValue: 'managers',
//     optionClass: 'optionPrimary',
//     disabled: false
//   },
//   {
//     data: 'nodes',
//     dataValue: 'nodes',
//     optionClass: 'optionPrimary',
//     disabled: false
//   },
//   {
//     data: 'roles',
//     dataValue: 'roles',
//     optionClass: 'optionPrimary',
//     disabled: false
//   },
//   {
//     data: 'types',
//     dataValue: 'types',
//     optionClass: 'optionPrimary',
//     disabled: false
//   }
// ];

export const ALLOCATE_POPUP = [
  {
    data: 'allocate',
    dataValue: 'allocateApi',
    dataKey: 'allocateApi',
    optionClass: 'optionPrimary',
    disabled: false
  },
  {
    data: 'unallocate',
    dataValue: 'unallocateApi',
    dataKey: 'unallocateApi',
    optionClass: 'optionPrimary',
    disabled: false,
    divider: 'dark'
  },
  {
    data: 'assessees',
    dataValue: 'assessees',
    //optionClass: 'optionPrimary',
    optionClass: 'optionSecondary',
    disabled: false
  },
  {
    data: 'assessments',
    dataValue: 'assessments',
    //optionClass: 'optionPrimary',
    optionClass: 'optionSecondary',
    disabled: false
  },
  {
    data: 'assignments',
    dataValue: 'assignments',
    //optionClass: 'optionPrimary',
    optionClass: 'optionSecondary',
    disabled: false
  },
  {
    data: 'associates',
    dataValue: 'associates',
    //optionClass: 'optionPrimary',
    optionClass: 'optionSecondary',
    divider: 'light',
    disabled: false
  },
  {
    data: 'culture-profiles',
    dataValue: 'culture-profiles',
    //optionClass: 'optionPrimary',
    optionClass: 'optionSecondary',
    //divider: 'dark',
    disabled: false
  },
  {
    data: 'job-profiles',
    dataValue: 'job-profiles',
    //optionClass: 'optionPrimary',
    optionClass: 'optionSecondary',
    divider: 'light',
    disabled: false
  },
  {
    data: 'items',
    dataValue: 'items',
    //optionClass: 'optionPrimary',
    optionClass: 'optionSecondary',
    divider: 'light',
    disabled: false
  },
  {
    data: 'distinct',
    dataValue: 'distinct',
    //optionClass: 'optionPrimary',
    optionClass: 'optionTertiary',
    divider: 'light',
    disabled: false
  },
  {
    data: 'groups',
    dataValue: 'groups',
    //optionClass: 'optionPrimary',
    optionClass: 'optionTertiary',
    disabled: false
  },
  // {
  //   data: 'managers',
  //   dataValue: 'managers',
  //   //optionClass: 'optionPrimary',
  //   optionClass: 'optionTertiary',
  //   disabled: false
  // },
  {
    data: 'nodes',
    dataValue: 'nodes',
    //optionClass: 'optionPrimary',
    optionClass: 'optionTertiary',
    disabled: false
  },
  {
    data: 'roles',
    dataValue: 'roles',
    //optionClass: 'optionPrimary',
    optionClass: 'optionTertiary',
    disabled: false
  },
  {
    data: 'types',
    dataValue: 'types',
    //optionClass: 'optionPrimary',
    optionClass: 'optionTertiary',
    disabled: false
  }
];

// export const ADMINISTRAION_POPUP = [  //
//   {
//     data: 'administer',
//     dataValue:'administerApiCall',// 'administer'
//     dataKey:'administerApiCall',//'administer',
//     optionClass: 'optionPrimary',
//     disabled: false
//   },
//   {
//     data: 'reset',
//     dataValue: 'reset',//'unadministerApiCall',
//     dataKey: 'reset',//'unadministerApiCall',
//     optionClass: 'optionPrimary',
//     disabled:false
//   }
// ];
export const ADMINISTRAION_POPUP = [
  //
  {
    data: 'administer',
    dataValue: 'administer', // 'administerApiCall',
    dataKey: 'administer', // 'administerApiCall',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'reset',
    dataValue: 'reset', //'unadministerApiCall',
    dataKey: 'reset', //'unadministerApiCall',
    optionClass: 'optionPrimary',
    disabled: false,
    // permissionValue: 'revise'
  }
];

export const ADMINISTER_POPUP = [
  {
    data: 'no',
    dataValue: 'no', //'unadministerApiCall',
    dataKey: 'no', //unadministerApiCall',
    optionClass: 'optionPrimary',
    disabled: false
  },
  {
    data: 'yes',
    dataValue: 'administerApiCall',
    dataKey: 'administerApiCall',
    optionClass: 'optionPrimary',
    disabled: false
  }
];
export const APPROVE_POPUP = [
  {
    data: 'approve',
    dataValue: 'approveApiCall',
    dataKey: 'APPROVED',
    optionClass: 'optionPrimary',
    disabled: false
  },
  {
    data: 'unapprove',
    dataValue: 'unapproveApiCall',
    dataKey: 'DISAPPROVED',
    optionClass: 'optionPrimary',
    disabled: false
  }
];
export const ARCHIVE_POPUP = [
  {
    data: 'archive',
    dataValue: 'archiveApiCall',
    dataKey: 'ARCHIVED',
    optionClass: 'optionPrimary',
    disabled: false
  },
  {
    data: 'unarchive',
    dataValue: 'unarchiveApiCall',
    dataKey: 'UNARCHIVED',
    optionClass: 'optionPrimary',
    disabled: false
  }
];
export const DELETE_POPUP = [
  {
    data: 'no',
    dataValue: 'no',
    optionClass: 'optionPrimary',
    disabled: false
  },
  {
    data: 'yes',
    dataValue: 'yesApiCall',
    dataKey: 'DELETED',
    optionClass: 'optionPrimary',
    disabled: false
  }
];
export const RESET_POPUP = [
  {
    data: 'no',
    dataValue: 'no',
    optionClass: 'optionPrimary',
    disabled: false
  },
  {
    data: 'yes',
    dataValue: 'resetYesApiCall',
    dataKey: 'DELETED',
    optionClass: 'optionPrimary',
    disabled: false
  }
];
export const CANCEL_POPUP = [
  {
    data: 'no',
    dataValue: 'cancelNoApiCall',
    optionClass: 'optionPrimary',
    disabled: false
  },
  {
    data: 'yes',
    dataValue: 'cancelYesApiCall',
    dataKey: 'DELETED',
    optionClass: 'optionPrimary',
    disabled: false
  }
];
export const FORCE_POPUP = [
  {
    data: 'no',
    dataValue: 'no',
    optionClass: 'optionPrimary',
    disabled: false
  },
  {
    data: 'yes',
    dataValue: 'forceYesApiCall',
    dataKey: 'force',
    optionClass: 'optionPrimary',
    disabled: false
  }
];
export const FLAG_TRIPLE_DOT_PUPUP = [
  {
    data: 'flag',
    dataValue: 'flagedApiCall',
    optionClass: 'optionPrimary',
    disabled: false
  },
  {
    data: 'unflag',
    dataValue: 'unflagedApiCall',
    optionClass: 'optionPrimary',
    disabled: false,
    divider: 'dark'
  },
  {
    data: 'all',
    dataValue: 'all',
    dataKey: 'all',
    optionClass: 'optionSecondary',
    divider: 'light'
  },
  {
    data: 'multiple',
    dataValue: 'multiple',
    dataKey: 'multiple',
    optionClass: 'optionSecondary',
  },
  {
    data: 'selected',
    dataValue: 'selected',
    dataKey: 'selected',
    optionClass: 'optionSecondary',
    divider: 'dark'
  },
  {
    data: 'hide',
    dataValue: 'hide',
    dataKey: 'hide',
    optionClass: 'optionSecondary',
  },
  {
    data: 'unhide',
    dataValue: 'unhide',
    dataKey: 'unhide',
    optionClass: 'optionSecondary',
  }
];
export const SELECT_PUPUP = [
  {
    data: 'select',
    dataValue: 'select',
    optionClass: 'optionPrimary',
    disabled: false
  },
  {
    data: 'unselect',
    dataValue: 'unselect',
    optionClass: 'optionPrimary',
    disabled: false
  }
];
export const SELECT_OPTION_PUPUP = [
  {
    data: 'select',
    dataValue: 'select',
    optionClass: 'optionPrimary',
    disabled: false
  },
  {
    data: 'unselect',
    dataValue: 'unselect',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: false
  },
  {
    data: 'all',
    dataValue: 'all',
    optionClass: 'optionSecondary',
    divider: 'light',
    disabled: false
  },
  { data: 'flaged', dataValue: 'flaged', optionClass: 'optionSecondary', disabled: false },
  {
    data: 'multiple',
    dataValue: 'multiple',
    optionClass: 'optionSecondary',
    divider: 'dark',
    disabled: false
  },
  { data: 'hide', dataValue: 'hide', optionClass: 'optionSecondary', disabled: false },
  { data: 'unhide', dataValue: 'unhide', optionClass: 'optionSecondary', disabled: false }
];
export const SUSPEND_PUPUP = [
  {
    data: 'suspend',
    dataValue: 'suspendApiCall',
    dataKey: 'SUSPENDED',
    optionClass: 'optionPrimary',
    disabled: false
  },
  {
    data: 'unsuspend',
    dataValue: 'unsuspendApiCall',
    dataKey: 'UNSUSPENDED',
    optionClass: 'optionPrimary',
    disabled: false
  }
];
export const TERMINATE_PUPUP = [
  {
    data: 'terminate',
    dataValue: 'terminateApiCall',
    dataKey: 'TERMINATED',
    optionClass: 'optionPrimary',
    disabled: false
  },
  {
    data: 'unterminate',
    dataValue: 'unterminateApiCall',
    dataKey: 'UNTERMINATED',
    optionClass: 'optionPrimary',
    disabled: false
  }
];
export const PUBLISH_PUPUP = [
  {
    data: 'publish',
    dataValue: 'publishApiCall',
    dataKey: 'PUBLISHED',
    optionClass: 'optionPrimary',
    disabled: false
  },
  {
    data: 'unpublish',
    dataValue: 'unpublishApiCall',
    dataKey: 'UNPUBLISHED',
    optionClass: 'optionPrimary',
    disabled: false
  }
];
export const ASSESSEE_ASSOCIATE_TRIPPLE_DOT_POPUP_OPTION = [
  {
    data: 'allocate',
    dataValue: 'allocate',
    dataKey: 'allocate',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'archive',
    dataValue: 'archive',
    dataKey: 'archive',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'create',
    dataValue: 'create',
    dataKey: 'create',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'create'
  },
  {
    data: 'delete',
    dataValue: 'delete',
    dataKey: 'delete',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'delete'
  },
  {
    data: 'flag',
    dataValue: 'flag',
    dataKey: 'flag',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'move',
    dataValue: 'move',
    optionClass: 'optionPrimary',
    disabled: true
    // permissionValue:''
  },
  {
    data: 'review',
    dataValue: 'review',
    dataKey: 'reviewDistinct',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'select',
    dataValue: 'selection',
    dataKey: 'select',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review & other'
  },
  {
    data: 'share',
    dataValue: 'share',
    dataKey: 'share',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'share'
  },
  {
    data: 'suspend',
    dataValue: 'suspend',
    dataKey: 'suspend',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'terminate',
    dataValue: 'terminate',
    dataKey: 'terminate',
    optionClass: 'optionPrimary',
    // divider: 'dark',
    disabled: false,
    permissionValue: 'revise'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  //   // permissionValue:''
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  //   // permissionValue:''
  // }
];
export const SUBMODULE_SELF_TRIPPLE_DOT_POPUP_OPTION = [
  {
    data: 'allocate',
    dataValue: 'allocate',
    dataKey: 'allocate',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'archive',
    dataValue: 'archive',
    dataKey: 'archive',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'create',
    dataValue: 'create',
    dataKey: 'create',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'create'
  },
  {
    data: 'delete',
    dataValue: 'delete',
    dataKey: 'delete',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'delete'
  },
  {
    data: 'flag',
    dataValue: 'flag',
    dataKey: 'flag',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'move',
    dataValue: 'move',
    optionClass: 'optionPrimary',
    disabled: true
    // permissionValue:''
  },
  {
    data: 'review',
    dataValue: 'review',
    dataKey: 'reviewDistinctKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'select',
    dataValue: 'selection',
    dataKey: 'select',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review & other'
  },
  {
    data: 'share',
    dataValue: 'share',
    dataKey: 'share',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'share'
  },
  {
    data: 'suspend',
    dataValue: 'suspend',
    dataKey: 'suspend',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'terminate',
    dataValue: 'terminate',
    dataKey: 'terminate',
    optionClass: 'optionPrimary',
    // divider: 'dark',
    disabled: false,
    permissionValue: 'revise'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  //   // permissionValue:''
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  //   // permissionValue:''
  // }
];
export const ASSESSMENT_ASSIGNMENT_TRIPPLE_DOT_POPUP_OPTION = [
  {
    data: 'allocate',
    dataValue: 'allocate',
    dataKey: 'allocate',
    optionClass: 'optionPrimary',
    disabled: false
  },
  {
    data: 'archive',
    dataValue: 'archive',
    dataKey: 'archive',
    optionClass: 'optionPrimary',
    disabled: false
  },
  {
    data: 'create',
    dataValue: 'create',
    dataKey: 'create',
    optionClass: 'optionPrimary',
    disabled: false
  },
  {
    data: 'delete',
    dataValue: 'delete',
    dataKey: 'delete',
    optionClass: 'optionPrimary',
    disabled: false
  },
  {
    data: 'flag',
    dataValue: 'flag',
    dataKey: 'flaged',
    optionClass: 'optionPrimary',
    disabled: false
  },
  {
    data: 'publish',
    dataValue: 'publish',
    dataKey: 'publish',
    optionClass: 'optionPrimary',
    disabled: false
  },
  {
    data: 'review',
    dataValue: 'review',
    dataKey: 'reviewDistinct',
    optionClass: 'optionPrimary',
    disabled: false
  },
  {
    data: 'select',
    dataValue: 'selection',
    dataKey: 'select',
    optionClass: 'optionPrimary',
    disabled: false
  },
  {
    data: 'share',
    dataValue: 'share',
    dataKey: 'share',
    optionClass: 'optionPrimary',
    disabled: false
  },
  {
    data: 'suspend',
    dataValue: 'suspend',
    dataKey: 'suspend',
    optionClass: 'optionPrimary',
    disabled: false
  },
  {
    data: 'terminate',
    dataValue: 'terminate',
    dataKey: 'terminate',
    optionClass: 'optionPrimary',
    // divider: 'dark',
    disabled: false
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // }
];
export const TRIPPLE_DOT_POPUP_OPTION = [
  {
    data: 'allocate',
    dataValue: 'allocate',
    dataKey: 'allocate',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'archive',
    dataValue: 'archive',
    dataKey: 'archive',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: 'revise'
  },
  {
    data: 'create',
    dataValue: 'create',
    dataKey: 'create',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: 'create'
  },
  {
    data: 'delete',
    dataValue: 'delete',
    dataKey: 'delete',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: 'delete'
  },
  {
    data: 'flag',
    dataValue: 'flag',
    dataKey: 'flag',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: 'revise'
  },
  {
    data: 'move',
    dataValue: 'move',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: ''
  },
  {
    data: 'review',
    dataValue: 'review',
    dataKey: 'reviewDistinctKey',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: 'review'
  },
  {
    data: 'select',
    dataValue: 'selection',
    dataKey: 'select',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review & other'
  },
  {
    data: 'share',
    dataValue: 'share',
    dataKey: 'share',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: 'share'
  },
  {
    data: 'suspend',
    dataValue: 'suspend',
    dataKey: 'suspend',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: 'revise'
  },
  {
    data: 'terminate',
    dataValue: 'terminate',
    dataKey: 'terminate',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: true,
    permissionValue: 'revise'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // }
];
export const LEFT_TRIPPLE_DOT_POPUP_OPTION = [
  {
    data: 'assent',
    dataValue: 'assent',
    optionClass: 'optionPrimary',
    disabled: true
  },
  {
    data: 'assistance',
    dataValue: 'assistance',
    optionClass: 'optionPrimary',
    disabled: true
  },
  {
    data: 'assumption',
    dataValue: 'assumption',
    optionClass: 'optionPrimary',
    disabled: true
  },
  {
    data: 'assurance',
    dataValue: 'assurance',
    optionClass: 'optionPrimary',
    disabled: true
  }
];

export const SELF_POPUP_OPTION = [
  {
    data: 'password',
    dataValue: 'password',
    dataKey: 'password',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: false,
    permissionArr: 'assessee',
    permission: 'review'
  },
  {
    data: 'sign-out',
    dataValue: 'sign-out',
    dataKey: 'sign-out',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionArr: 'assessee',
    permission: 'review'
  }
];
export const SHARE_NEW_POPUP = [
  {
    data: 'share',
    dataValue: 'shareApiCall',
    dataKey: 'shareunshareTertiary',
    optionClass: 'optionPrimary',
    disabled: false
  },
  {
    data: 'unshare',
    dataValue: 'unshareApiCall',
    dataKey: 'shareunshareTertiary',
    optionClass: 'optionPrimary',
    disabled: false,
    divider: 'dark'
  },
  {
    data: 'associate',
    dataValue: 'associate',
    dataKey: 'associate',
    optionClass: 'optionSecondary',
    disabled: false
  },
  {
    data: 'node',
    dataValue: 'node',
    dataKey: 'node',
    optionClass: 'optionSecondary',
    disabled: true,
    divider: 'light'
  },
  {
    data: 'all',
    dataValue: 'all',
    dataKey: 'all',
    optionClass: 'optionTertiary',
    disabled: false
  },
  {
    data: 'distinct',
    dataValue: 'distinct',
    dataKey: 'distinct',
    optionClass: 'optionTertiary',
    disabled: false
  },
  {
    data: 'primary',
    dataValue: 'primary',
    dataKey: 'primary',
    optionClass: 'optionTertiary',
    disabled: false
  },
  {
    data: 'secondary',
    dataValue: 'secondary',
    dataKey: 'secondary',
    optionClass: 'optionTertiary',
    disabled: false,
    divider: 'light'
  },
  {
    data: 'ascendant',
    dataValue: 'ascendant',
    dataKey: 'ascendant',
    optionClass: 'optionForth',
    disabled: false
  },
  {
    data: 'descendant',
    dataValue: 'descendant',
    dataKey: 'descendant',
    optionClass: 'optionForth',
    disabled: false
  },
  {
    data: 'peer',
    dataValue: 'peer',
    dataKey: 'peer',
    optionClass: 'optionForth',
    disabled: false
  }
];
export const SHARE_POPUP_FEE_POPUP = [
  {
    data: 'share',
    dataValue: 'shareValue',
    dataKey: 'shareunshareTertiary',
    optionClass: 'optionPrimary',
    disabled: false
  },
  {
    data: 'unshare',
    dataValue: 'unshareApiCall',
    dataKey: 'shareunshareTertiary',
    optionClass: 'optionPrimary',
    disabled: false,
    divider: 'dark'
  },
  {
    data: 'associate',
    dataValue: 'associate',
    dataKey: 'associate',
    optionClass: 'optionSecondary',
    disabled: false
  },
  {
    data: 'node',
    dataValue: 'node',
    dataKey: 'node',
    optionClass: 'optionSecondary',
    disabled: true,
    divider: 'light'
  },
  {
    data: 'all',
    dataValue: 'all',
    dataKey: 'all',
    optionClass: 'optionTertiary',
    disabled: false
  },
  {
    data: 'distinct',
    dataValue: 'distinct',
    dataKey: 'distinct',
    optionClass: 'optionTertiary',
    disabled: false
  },
  {
    data: 'primary',
    dataValue: 'primary',
    dataKey: 'primary',
    optionClass: 'optionTertiary',
    disabled: false
  },
  {
    data: 'secondary',
    dataValue: 'secondary',
    dataKey: 'secondary',
    optionClass: 'optionTertiary',
    disabled: false,
    divider: 'light'
  },
  {
    data: 'ascendant',
    dataValue: 'ascendant',
    dataKey: 'ascendant',
    optionClass: 'optionForth',
    disabled: false
  },
  {
    data: 'descendant',
    dataValue: 'descendant',
    dataKey: 'descendant',
    optionClass: 'optionForth',
    disabled: false
  },
  {
    data: 'peer',
    dataValue: 'peer',
    dataKey: 'peer',
    optionClass: 'optionForth',
    disabled: false
  }
];
export const RES_START_POPUP_OPTION = [
  {
    data: 'resume',
    dataValue: 'resume',
    dataKey: 'resume',
    optionClass: 'optionPrimary',
    disabled: false
  },
  {
    data: 'start',
    dataValue: 'start',
    dataKey: 'start',
    optionClass: 'optionPrimary',
    disabled: false
  }
];
export const ASSESSMENT_FINISH_POPUP_OPTION = [
  {
    data: 'finish',
    dataValue: 'finish',
    dataKey: 'finish',
    optionClass: 'optionPrimary',
    disabled: false
  },
  {
    data: 'review',
    dataValue: 'review',
    dataKey: 'review',
    optionClass: 'optionPrimary',
    disabled: false
  }
];
export const ASSESSMENT_CLOSED_POPUP_OPTION = [
  {
    data: 'no',
    dataValue: 'no',
    dataKey: 'no',
    optionClass: 'optionPrimary',
    disabled: true
  },
  {
    data: 'yes',
    dataValue: 'yes',
    dataKey: 'yes',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: false
  },
  {
    data: 'assignment',
    dataValue: 'assignment',
    dataKey: 'assignment',
    optionClass: 'optionSecondary',
    disabled: false
  },
  {
    data: 'dashboard',
    dataValue: 'dashboard',
    dataKey: 'dashboard',
    optionClass: 'optionSecondary',
    divider: 'light',
    disabled: false
  },
  {
    data: 'sign-out',
    dataValue: 'sign-out',
    dataKey: 'sign-out',
    optionClass: 'optionSecondary',
    disabled: false
  }
];
export const MOVE_PUPUP = [
  {
    data: 'managers',
    data: 'groups',
    dataValue: 'groups',
    optionClass: 'optionPrimary',
    disabled: false
  },
  // {
  //   
  //   dataValue: 'managers',
  //   optionClass: 'optionPrimary',
  //   disabled: false
  // },
  {
    data: 'nodes',
    dataValue: 'nodes',
    optionClass: 'optionPrimary',
    disabled: false
  },
  {
    data: 'roles',
    dataValue: 'roles',
    optionClass: 'optionPrimary',
    disabled: false
  },
  {
    data: 'types',
    dataValue: 'types',
    optionClass: 'optionPrimary',
    disabled: false
  }
];

export const MINE_ASSIGNMENT_POPUP = [
  // {
  //   data: 'select',
  //   dataValue: 'selection',
  //   optionClass: 'optionPrimary',
  //   disabled: false
  // },
  {
    data: 'pay',
    dataValue: 'pay',
    optionClass: 'optionPrimary',
    disabled: false
  }
];
export const SELF_PROFILE_POPUP = [
  {
    data: 'create',
    dataValue: 'create',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionArr: '',
    permissionValue: 'create'
  },
  {
    data: 'review',
    dataValue: 'review',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: false,
    permissionArr: '',
    permissionValue: 'review'
  }
]
export const REVIEW_LIST_FLAG_POPUP = [
  {
    data: 'flag',
    dataValue: 'flagedApiCall',
    optionClass: 'optionPrimary',
    disabled: false
  },
  {
    data: 'unflag',
    dataValue: 'unflagedApiCall',
    optionClass: 'optionPrimary',
    disabled: false
  },
];
export const ADMINISTRATOR_RESET_POPUP = [
  {
    data: 'activation',
    dataValue: 'activation',
    dataKey: 'activation',
    optionClass: 'optionPrimary',
    disabled: true
  },
  {
    data: 'password',
    dataValue: 'password',
    dataKey: 'password',
    optionClass: 'optionPrimary',
    disabled: false
  }
];
export const ASSIGNMENT_MODULE_REVIEW_POPUP_OPTIONS = [
  {
    data: 'distinct',
    dataValue: 'distinct',
    dataKey: 'distinctAPICall',
    optionClass: 'optionPrimary',
    divider: 'light',
    disabled: true,
    permissionArr: '',
    permission: 'Distinct',
    permissionValue: 'review'
  },
  {
    data: 'groups',
    dataValue: 'groups',
    dataKey: 'groups',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionArr: '',
    permission: 'Group',
    permissionValue: 'review'
  },
  {
    data: 'nodes',
    dataValue: 'nodes',
    dataKey: 'nodes',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionArr: '',
    permission: 'Node',
    permissionValue: 'review'
  },
  {
    data: 'roles',
    dataValue: 'roles',
    dataKey: 'roles',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionArr: '',
    permission: 'Role',
    permissionValue: 'review'
  },
  {
    data: 'types',
    dataValue: 'types',
    dataKey: 'types',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: true,
    permissionArr: '',
    permission: 'Type',
    permissionValue: 'review'
  },
  {
    data: 'active',
    dataValue: 'active',
    dataKey: 'active',
    optionClass: 'optionSecondary',
    disabled: false
  },
  {
    data: 'inactive',
    dataValue: 'inactive',
    dataKey: 'inactive',
    optionClass: 'optionSecondary',
    divider: 'light',
    disabled: false
  },
  {
    data: 'all',
    dataValue: 'all',
    dataKey: 'all',
    optionClass: 'optionSecondary',
    divider: 'light',
    disabled: false
  },
  {
    data: 'archived',
    dataValue: 'archived',
    dataKey: 'archived',
    optionClass: 'optionSecondary',
    divider: '',
    disabled: false
  }
];
export const SHARE_ASSESSMENT_ASSIGNMENT_ASSOCIATE_POPUP = [
  {
    data: 'share',
    dataValue: 'shareApiCall',
    dataKey: 'shareunshareTertiary',
    optionClass: 'optionPrimary',
    disabled: false
  },
  {
    data: 'unshare',
    dataValue: 'unshareApiCall',
    dataKey: 'shareunshareTertiary',
    optionClass: 'optionPrimary',
    disabled: false,
    divider: 'dark'
  },
  {
    data: 'associate',
    dataValue: 'associate',
    dataKey: 'associate',
    optionClass: 'optionSecondary',
    disabled: false
  },
  {
    data: 'marketplace',
    dataValue: 'marketplace',
    dataKey: 'marketplace',
    optionClass: 'optionSecondary',
    disabled: true,
  },
  {
    data: 'node',
    dataValue: 'node',
    dataKey: 'node',
    optionClass: 'optionSecondary',
    disabled: true,
    divider: 'light'
  },
  {
    data: 'assessments',
    dataValue: 'assessments',
    dataKey: 'assessments',
    optionClass: 'optionSecondary',
    disabled: false,
  },
  {
    data: 'all',
    dataValue: 'all',
    dataKey: 'all',
    optionClass: 'optionTertiary',
    disabled: false
  },
  {
    data: 'distinct',
    dataValue: 'distinct',
    dataKey: 'distinct',
    optionClass: 'optionTertiary',
    disabled: false
  },
  {
    data: 'primary',
    dataValue: 'primary',
    dataKey: 'primary',
    optionClass: 'optionTertiary',
    disabled: false
  },
  {
    data: 'secondary',
    dataValue: 'secondary',
    dataKey: 'secondary',
    optionClass: 'optionTertiary',
    disabled: false,
    divider: 'light'
  },
  {
    data: 'ascendant',
    dataValue: 'ascendant',
    dataKey: 'ascendant',
    optionClass: 'optionForth',
    disabled: false
  },
  {
    data: 'descendant',
    dataValue: 'descendant',
    dataKey: 'descendant',
    optionClass: 'optionForth',
    disabled: false
  },
  {
    data: 'peer',
    dataValue: 'peer',
    dataKey: 'peer',
    optionClass: 'optionForth',
    disabled: false
  },
  {
    data: 'root',
    dataValue: 'root',
    dataKey: 'root',
    optionClass: 'optionForth',
    disabled: true
  },
  {
    data: 'self',
    dataValue: 'self',
    dataKey: 'self',
    optionClass: 'optionForth',
    disabled: true
  }
];

export const TRIPPLE_DOT_ASSESSMENT_ASSIGNMENT_ASSOCIATE_POPUP_OPTION = [
  {
    data: 'allocate',
    dataValue: 'allocate',
    dataKey: 'allocate',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'archive',
    dataValue: 'archive',
    dataKey: 'archive',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: 'revise'
  },
  {
    data: 'create',
    dataValue: 'create',
    dataKey: 'create',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: 'create'
  },
  {
    data: 'delete',
    dataValue: 'delete',
    dataKey: 'delete',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: 'delete'
  },
  {
    data: 'flag',
    dataValue: 'flag',
    dataKey: 'flag',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: 'revise'
  },
  {
    data: 'move',
    dataValue: 'move',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: ''
  },
  {
    data: 'review',
    dataValue: 'review',
    dataKey: 'reviewDistinctKey',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: 'review'
  },
  {
    data: 'select',
    dataValue: 'selection',
    dataKey: 'select',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review & other'
  },
  {
    data: 'share',
    dataValue: 'share',
    dataKey: 'share',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: 'share'
  },
  {
    data: 'suspend',
    dataValue: 'suspend',
    dataKey: 'suspend',
    optionClass: 'optionPrimary',
    disabled: true,
    permissionValue: 'revise'
  },
  {
    data: 'terminate',
    dataValue: 'terminate',
    dataKey: 'terminate',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: true,
    permissionValue: 'revise'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // }
];

export const ASSESSEE_GROUP_NODE_ROLE_REVIEW_LIST_POPUP_OPTION = [
  {
    data: 'allocate',
    dataValue: 'allocate',
    dataKey: 'allocate',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'archive',
    dataValue: 'archive',
    dataKey: 'archive',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'delete',
    dataValue: 'delete',
    dataKey: 'delete',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'delete'
  },
  {
    data: 'flag',
    dataValue: 'flagKey',
    dataKey: 'flagKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'move',
    dataValue: 'move',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: ''
  },
  {
    data: 'review',
    dataValue: 'review',
    dataKey: 'reviewKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'revise',
    dataValue: 'revise',
    dataKey: 'reviseKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'share',
    dataValue: 'share',
    dataKey: 'share',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'share'
  },
  {
    data: 'suspend',
    dataValue: 'suspend',
    dataKey: 'suspend',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'terminate',
    dataValue: 'terminate',
    dataKey: 'terminate',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'assessees',
    dataValue: 'assessees',
    dataKey: 'review',
    optionClass: 'optionPrimary',
    // divider: 'dark',
    disabled: false,
    permissionValue: 'review'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // }
];
export const ASSESSMENT_GROUP_NODE_TYPE_REVIEW_LIST_POPUP_OPTION = [
  {
    data: 'allocate',
    dataValue: 'allocate',
    dataKey: 'allocate',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'archive',
    dataValue: 'archive',
    dataKey: 'archive',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'delete',
    dataValue: 'delete',
    dataKey: 'delete',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'delete'
  },
  {
    data: 'flag',
    dataValue: 'flagKey',
    dataKey: 'flagKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'move',
    dataValue: 'move',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: ''
  },
  {
    data: 'review',
    dataValue: 'review',
    dataKey: 'reviewKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'revise',
    dataValue: 'revise',
    dataKey: 'reviseKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'share',
    dataValue: 'share',
    dataKey: 'share',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'share'
  },
  {
    data: 'suspend',
    dataValue: 'suspend',
    dataKey: 'suspend',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'terminate',
    dataValue: 'terminate',
    dataKey: 'terminate',
    optionClass: 'optionPrimary',
    divider: 'dark',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'assessments',
    dataValue: 'assessments',
    dataKey: 'review',
    optionClass: 'optionPrimary',
    // divider: 'dark',
    disabled: false,
    permissionValue: 'review'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // }
];
export const ASSIGNMNET_GROUP_TYPE_NODE_REVIEW_LIST_POPUP_OPTION = [
  {
    data: 'allocate',
    dataValue: 'allocate',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'archive',
    dataValue: 'archive',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'delete',
    dataValue: 'delete',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'delete'
  },
  {
    data: 'flag',
    dataValue: 'flagKey',
    dataKey: 'flagKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'review',
    dataValue: 'review',
    dataKey: 'review',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'revise',
    dataValue: 'revise',
    dataKey: 'revise',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'share',
    dataValue: 'share',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'share'
  },
  {
    data: 'suspend',
    dataValue: 'suspend',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'terminate',
    dataValue: 'terminate',
    optionClass: 'optionPrimary',
    // divider: 'dark',
    disabled: false,
    permissionValue: 'revise'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // }
];

export const GROUP_NODE_ROLE_TYPE_REVIEW_LIST_POPUP_OPTION = [
  {
    data: 'allocate',
    dataValue: 'allocate',
    dataKey: 'allocate',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'archive',
    dataValue: 'archive',
    dataKey: 'archive',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'delete',
    dataValue: 'delete',
    dataKey: 'delete',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'delete'
  },
  {
    data: 'flag',
    dataValue: 'flagKey',
    dataKey: 'flagKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'review',
    dataValue: 'review',
    dataKey: 'reviewKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'review'
  },
  {
    data: 'revise',
    dataValue: 'revise',
    dataKey: 'reviseKey',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'share',
    dataValue: 'share',
    dataKey: 'share',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'share'
  },
  {
    data: 'suspend',
    dataValue: 'suspend',
    dataKey: 'suspend',
    optionClass: 'optionPrimary',
    disabled: false,
    permissionValue: 'revise'
  },
  {
    data: 'terminate',
    dataValue: 'terminate',
    dataKey: 'terminate',
    optionClass: 'optionPrimary',
    // divider: 'dark',
    disabled: false,
    permissionValue: 'revise'
  },
  // {
  //   data: 'notifications',
  //   dataValue: 'notifications',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // },
  // {
  //   data: 'reports',
  //   dataValue: 'reports',
  //   dataKey: 'review',
  //   optionClass: 'optionPrimary',
  //   disabled: true
  // }
];
