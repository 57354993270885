import React, { Fragment, useEffect, useState } from 'react';
import ReviewList from '../ReviewList/ReviewList';
import { FormControl, InputLabel } from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import './DisplayPanelAccordian.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  SET_BRAND_LOGO_TYPE,
  SET_JOB_SIFTLIST_STATE,
  SET_WEIGHTAGE_CULTURE_PROFILE,
  SET_WEIGHTAGE_JOB_PROFILE
} from '../../actionType';
import CultureWeightageTableTemplate from './CultureWeightageTableTemplate';
import JobWeightageTableTemplate from './JobWeightageTableTemplate';
import JobRangeTableTemplate from './jobRangeTableTemplate';

const DisplayPanelAccordianReviewListTwo = (props) => {
  const { accordianObject, mode = '', onClickRevise, onClickReview = null, getReviewList } = props;
  const {
    labelTextOneOne = '',
    innerInfo = 'No Information',
    labelTextOneOneBadges,
    isReviewLink = false
  } = accordianObject;
  const dispatch = useDispatch();
  const {
    reviewMode,
    responseObject,
    isWeightageSelected = false,
    isJobWeightageSelected = false,
    isRangeSelected = false,
    assignmentRelatedReviewListPaneThree
  } = useSelector((state) => state.DisplayPaneThreeReducer);
  const { cultureProfileInformation } = useSelector((state) => state.CultureProfileCreateReducer);
  const { jobProfileInformation } = useSelector((state) => state.JobProfileCreateReducer);
  const [isListSelectExpanded, setIsListSelectExpanded] = useState(false);
  const [selectedBadge, setSelectedBadge] = useState('');
  const [innerSelectedBadge, setInnerSelectedBadge] = useState('');
  const reviewLabelClass = isReviewLink ? 'reviewLinkText' : '';
  useEffect(() => {
    // console.log('useeffect');
    setInnerSelectedBadge('');
    setSelectedBadge('');
    setIsListSelectExpanded(false);
  }, [responseObject, JSON.stringify(assignmentRelatedReviewListPaneThree)]);
  useEffect(() => {
    if (selectedBadge === '') {
      setInnerSelectedBadge('');
    } else {
      if (labelTextOneOneBadges[0]?.labelTextOneOneBadge === 'primary') {
        setInnerSelectedBadge(selectedBadge.innerLabelBadgeList[0]);
      }
    }
    if (isWeightageSelected && labelTextOneOne === 'culture-dimensions') {
      setSelectedBadge(labelTextOneOneBadges[0]);
      setInnerSelectedBadge(labelTextOneOneBadges[0].innerLabelBadgeList[1]);
      setIsListSelectExpanded(true);
    }
    if (isRangeSelected && labelTextOneOne === 'job-competencies') {
      setSelectedBadge(labelTextOneOneBadges[0]);
      setInnerSelectedBadge(labelTextOneOneBadges[0].innerLabelBadgeList[1]);
      setIsListSelectExpanded(true);
    }
    if (isJobWeightageSelected && labelTextOneOne === 'job-competencies') {
      setSelectedBadge(labelTextOneOneBadges[0]);
      setInnerSelectedBadge(labelTextOneOneBadges[0].innerLabelBadgeList[2]);
      setIsListSelectExpanded(true);
    }
  }, [selectedBadge, isRangeSelected, isJobWeightageSelected, isWeightageSelected]);
  let tempListData =
    cultureProfileInformation?.informationFramework?.cultureProfileCultureDimensionCoreObj ||
    responseObject?.informationFramework?.cultureProfileCultureDimensionSelectList;
  let tempJobListData =
    jobProfileInformation?.informationFramework?.jobProfileJobCompetencyCore ||
    responseObject?.informationFramework?.jobProfileJobCompetencySelectList;

  let tempRangeListData =
    jobProfileInformation?.informationFramework?.jobProfileJobCompetencyCore ||
    responseObject?.informationFramework?.jobProfileJobCompetencySelectList;

  let selectedList =
    responseObject?.informationFramework?.jobProfileJobCompetencySelectList ||
    jobProfileInformation?.informationFramework?.jobProfileJobCompetencySelectList;
  const setRangeData = (selectedIndex, id, max) => {
    let ob = tempRangeListData.filter((ob) => ob.id === id)[0];
    if (ob.iGaugeOccupationFrameworkOneRangeExtremumMinimum === 0) {
      ob = { ...ob, iGaugeOccupationFrameworkOneRangeExtremumMinimum: selectedIndex };
      // min set
    } else if (ob.iGaugeOccupationFrameworkOneRangeExtremumMinimum > selectedIndex) {
      ob = {
        ...ob,
        iGaugeOccupationFrameworkOneRangeExtremumMaximum:
          ob.iGaugeOccupationFrameworkOneRangeExtremumMinimum
      };
      ob = { ...ob, iGaugeOccupationFrameworkOneRangeExtremumMinimum: selectedIndex };
    } else if (ob.iGaugeOccupationFrameworkOneRangeExtremumMaximum > selectedIndex) {
      ob = { ...ob, iGaugeOccupationFrameworkOneRangeExtremumMinimum: selectedIndex };
    } else {
      ob = { ...ob, iGaugeOccupationFrameworkOneRangeExtremumMaximum: selectedIndex };
    }

    tempRangeListData.forEach((element) => {
      if (element.id === id) {
        element.iGaugeOccupationFrameworkOneRangeExtremumMinimum =
          ob.iGaugeOccupationFrameworkOneRangeExtremumMinimum;
        element.iGaugeOccupationFrameworkOneRangeExtremumMaximum =
          ob.iGaugeOccupationFrameworkOneRangeExtremumMaximum;
      }
    });

    selectedList.forEach((ele) => {
      if (ele.iGaugeOccupationFrameworkOneTag === id) {
        ele.iGaugeOccupationFrameworkOneRangeExtremumMaximum =
          ob.iGaugeOccupationFrameworkOneRangeExtremumMaximum;
        ele.iGaugeOccupationFrameworkOneRangeExtremumMinimum =
          ob.iGaugeOccupationFrameworkOneRangeExtremumMinimum;
      }
    });
    let obj = {
      ...jobProfileInformation.informationFramework,
      // jobProfileJobCompetencyRange: tempRangeListData,
      // jobProfileJobCompetencySelectList: selectedList,
      jobProfileJobCompetencyCore: tempRangeListData
    };
    dispatch({
      type: SET_JOB_SIFTLIST_STATE,
      payload: obj
    });
  };
  const setBrandLogo = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    if (reviewMode === 'revise') {
      if (labelName === 'Associate') {
        dispatch({ type: SET_BRAND_LOGO_TYPE, payload: 'Associate' });
      }
      if (labelName === 'Associate & iGuru') {
        dispatch({ type: SET_BRAND_LOGO_TYPE, payload: 'Associate & iGuru' });
      }
      if (labelName === 'iGuru') {
        dispatch({ type: SET_BRAND_LOGO_TYPE, payload: 'iGuru' });
      }
    }
  };
  tempJobListData.sort((a, b) =>
    a.iGaugeOccupationFrameworkOneClusterName !== b.iGaugeOccupationFrameworkOneClusterName
      ? a.iGaugeOccupationFrameworkOneClusterName < b.iGaugeOccupationFrameworkOneClusterName
        ? -1
        : 1
      : 0
  );
  tempRangeListData.sort((a, b) =>
    a.iGaugeOccupationFrameworkOneClusterName !== b.iGaugeOccupationFrameworkOneClusterName
      ? a.iGaugeOccupationFrameworkOneClusterName < b.iGaugeOccupationFrameworkOneClusterName
        ? -1
        : 1
      : 0
  );
  return (
    <>
      <div className={'detailsContactContainer'} style={{ paddingTop: isListSelectExpanded ? '2.5px' : '' }}>
        <div className={'detsailsPadding'}>
          <div className={['FormBox', 'detailsHeight'].join(' ')}>
            <FormControl className={['formControlReviewName', 'formControlRight'].join(' ')}>
              <InputLabel
                htmlFor="name-input"
                className={[
                  'textForLabel',
                  'textForLabelRight',
                  'careerLabelRight',
                  'careerLabelRighttransform'
                ].join(' ')}
              >
                <span
                  onClick={mode === 'revise' ? onClickRevise : onClickReview}
                  data-value={labelTextOneOne}
                  data-key={selectedBadge?.labelTextOneOneBadge || ''}
                  id={innerSelectedBadge?.labelTextTwoBadge || ''}
                  className={mode === 'revise' ? 'linkText' : reviewLabelClass}
                >
                  {labelTextOneOne}
                </span>
                {labelTextOneOneBadges.map((ob, key) => {
                  return (
                    <Fragment key={key}>
                      {selectedBadge === '' ||
                        selectedBadge.labelTextOneOneBadge === ob.labelTextOneOneBadge ? (
                        <sup
                          key={`badge-${key}`}
                          style={{
                            backgroundColor:
                              selectedBadge &&
                                selectedBadge.labelTextOneOneBadge === ob.labelTextOneOneBadge
                                ? '#F2F2F2'
                                : '#ffffff'
                          }}
                          onClick={() => {
                            if (ob.innerLabelBadgeList[0]?.labelTextTwoBadge !== '') {
                              setSelectedBadge((state) => {
                                if (state.labelTextOneOneBadge === ob.labelTextOneOneBadge) {
                                  return '';
                                }
                                return ob;
                              });
                            }
                          }}
                        >
                          {ob.labelTextOneOneBadge}
                        </sup>
                      ) : (
                        <></>
                      )}
                    </Fragment>
                  );
                })}
                {selectedBadge !== '' &&
                  selectedBadge.innerLabelBadgeList.map((ob, key) => {
                    return (
                      <Fragment key={key}>
                        {true ? (
                          <sup
                            key={`badge-${key}`}
                            style={{
                              backgroundColor:
                                innerSelectedBadge &&
                                  innerSelectedBadge.labelTextTwoBadge === ob.labelTextTwoBadge
                                  ? '#F2F2F2'
                                  : '#ffffff'
                            }}
                            onClick={() => {
                              setInnerSelectedBadge(ob);
                            }}
                          >
                            {ob.labelTextTwoBadge}
                          </sup>
                        ) : (
                          <></>
                        )}
                      </Fragment>
                    );
                  })}
                {/* {labelTextOneOneBadgeOne ? <sup>{labelTextOneOneBadgeOne}</sup> : null}
                {labelTextOneOneBadgeTwo ? <sup>{labelTextOneOneBadgeTwo}</sup> : null}
                {labelTextOneOneBadgeThree ? <sup>{labelTextOneOneBadgeThree}</sup> : null}
                {labelTextOneOneBadgeFour ? <sup>{labelTextOneOneBadgeFour}</sup> : null} */}
              </InputLabel>
            </FormControl>
            <div className={'unitFlex'}></div>
            <div
              onClick={() => {
                if (selectedBadge && innerSelectedBadge && !isListSelectExpanded) {
                  getReviewList && getReviewList(selectedBadge.labelTextOneOneBadge, innerSelectedBadge.labelTextTwoBadge);
                }
                setIsListSelectExpanded((state) => !state)
              }}
              className={['unitFlex', 'careerLabelRight', 'showLessMoreList'].join(' ')}
            >
              {isListSelectExpanded && innerSelectedBadge !== '' ? (
                <ExpandLess className={'showLessMoreListIcon'} />
              ) : (
                <ExpandMore className={'showLessMoreListIcon'} />
              )}
            </div>
          </div>
        </div>
        {isListSelectExpanded &&
          innerSelectedBadge !== '' &&
          innerSelectedBadge?.labelTextTwoBadge !== 'range' &&
          innerSelectedBadge?.labelTextTwoBadge !== 'weightage' && (
            <div>
              {innerSelectedBadge.innerList.length > 0 ? (
                <>
                  {innerSelectedBadge.innerList.map((associate, index) => {
                    return (
                      <div style={{ padding: '2.5px 0' }} key={index}>
                        <ReviewList
                          className=""
                          id={associate.id}
                          associateId={associate?.associateId}
                          isShared={associate?.isShared}
                          status={associate.status}
                          textOne={associate.textOne}
                          textTwo={associate.textTwo}
                          onClickEvent={setBrandLogo}
                          dataValue={associate.textOne}
                        />
                      </div>
                    );
                  })}
                </>
              ) : (
                <div
                  style={{
                    height: '50px',
                    padding: '2.5px 5px',
                    alignItems: 'center',
                    display: 'flex'
                  }}
                >
                  {innerInfo}
                </div>
              )}
            </div>
          )}
        {isListSelectExpanded &&
          selectedBadge.labelTextOneOneBadge === 'select-list' &&
          (innerSelectedBadge.labelTextTwoBadge === 'weightage' ||
            innerSelectedBadge.labelTextTwoBadge === 'range') && (
            <div>
              <div className={'containerPadding'}>
                {innerSelectedBadge.labelTextTwoBadge === 'weightage' &&
                  labelTextOneOne === 'culture-dimensions' && (
                    <CultureWeightageTableTemplate
                      headerrowcount={3}
                      title="weightage"
                      radiocount={3}
                      row1={['low', 'medium', 'high']}
                      // culturedimensionselected={cultureProfilerItems}
                      culturetooltipstate=""
                      cultureprofilemode="review"
                      listData={
                        tempListData.sort((a, b) =>
                          a.iGaugeOccupationFrameworkOneClusterLabel !==
                            b.iGaugeOccupationFrameworkOneClusterLabel
                            ? a.iGaugeOccupationFrameworkOneClusterLabel <
                              b.iGaugeOccupationFrameworkOneClusterLabel
                              ? -1
                              : 1
                            : 0
                        ) || []
                      }
                      setWeightage={(ob) => {
                        if (reviewMode === 'revise') {
                          dispatch({ type: SET_WEIGHTAGE_CULTURE_PROFILE, payload: ob });
                        }
                      }}
                    />
                  )}
                {innerSelectedBadge.labelTextTwoBadge === 'weightage' &&
                  labelTextOneOne === 'job-competencies' && (
                    <JobWeightageTableTemplate
                      headerrowcount={3}
                      title="weightage"
                      radiocount={3}
                      row1={['low', 'medium', 'high']}
                      // culturedimensionselected={cultureProfilerItems}
                      culturetooltipstate=""
                      cultureprofilemode="review"
                      // listData={tempJobListData || []}
                      listData={tempJobListData.filter((job) => {
                        return (
                          job.iGaugeOccupationFrameworkOneSelected !== '' &&
                          job.iGaugeOccupationFrameworkOneSelected !== 'removable'
                        );
                      })}
                      setWeightage={(ob) => {
                        if (reviewMode === 'revise') {
                          dispatch({ type: SET_WEIGHTAGE_JOB_PROFILE, payload: ob });
                        }
                      }}
                    />
                  )}
                {innerSelectedBadge.labelTextTwoBadge === 'range' && (
                  <JobRangeTableTemplate
                    headerrowcount={4}
                    title="range"
                    radiocount={10}
                    row1={['51 - 60', '61 - 70', '71 - 80', '81 - 90', '91 - 100']}
                    rangeheadcolumnhead2={{
                      '51 - 60': 'average',
                      '61 - 70': 'average +',
                      '71 - 80': 'effective',
                      '81 - 90': 'strong',
                      '91 - 100': 'exceptional'
                    }}
                    // culturedimensionselected={cultureProfilerItems}
                    listData={tempRangeListData.filter((job) => {
                      return (
                        job.iGaugeOccupationFrameworkOneSelected !== '' &&
                        job.iGaugeOccupationFrameworkOneSelected !== 'removable'
                      );
                    })}
                    culturetooltipstate=""
                    cultureprofilemode="review"
                    saveselected={reviewMode === 'revise' ? setRangeData : () => { }}
                  />
                )}
              </div>
            </div>
          )}
      </div>
    </>
  );
};

export default DisplayPanelAccordianReviewListTwo;
