import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PopUpTextField from '../../PopUpInformation/PopUpTextField';
import PopUpConfirm from '../../PopUpGeneric/PopUpConfirm';
import {
  POPUP_CLOSE,
  LOADER_START,
  SET_DISPLAY_THREE_SINGLE_STATE,
  CLEAR_GRADE_REDUCER_STATE,
  SET_GRADE_REDUCER_STATE,
  CREATE_ASSESSMENT_SECTION_SAGA,
  SET_GRADE_SCORE_STATE
} from '../../actionType';
import PopUpPicture from '../../PopUpInformation/PopUpPicture';
import PopUpTagSecondary from '../../PopUpInformation/PopUpTagSecondary';
import PopUpDropList from '../../PopUpInformation/PopUpDropList';

const PopUpGradeCreate = (props) => {
  const { headerOne } = props;
  const { isPopUpValue, selectedTagValue } = useSelector((state) => state.PopUpReducer);
  const { gradeInformation } = useSelector((state) => state.GradeCreateReducer);
  const { reviewMode, responseObject } = useSelector((state) => state.DisplayPaneThreeReducer);
  const { selectedAssociateInfo, relatedReviewListDistinctData,typeOfMiddlePaneList } = useSelector(
    (state) => state.DisplayPaneTwoReducer
  );

  const dispatch = useDispatch();
  const onClickCancelYes = () => {
    dispatch({
      type: SET_DISPLAY_THREE_SINGLE_STATE,
      payload: { stateName: 'createMode', value: '' }
    });
    dispatch({ type: CLEAR_GRADE_REDUCER_STATE });
    dispatch({ type: POPUP_CLOSE });
  };
  const onClickYes = () => {
    dispatch({ type: POPUP_CLOSE });
    let requestObj = {
      module: 'Assessment-Distinct',
      action: 'Create',
      actionFilter: 'Section-Cluster-Scale',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        assessmentId:
          (selectedTagValue && selectedTagValue) || relatedReviewListDistinctData[0]?.assessmentId,
        assessmentScoreGradeFramework: gradeInformation
      }
    };
    dispatch({ type: POPUP_CLOSE });
    dispatch({ type: LOADER_START });
    dispatch({
      type: CREATE_ASSESSMENT_SECTION_SAGA,
      payload: { requestObj: requestObj, typeOfMiddlePaneList: typeOfMiddlePaneList }
    });
  };
  let tenureStartDate = 'mm/dd/yyyy --:-- --';
  let tenureEndDate = 'mm/dd/yyyy --:-- --';
  return (
    <div>
      {isPopUpValue === 'NAMEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'name'}
          actualLableValue={'assessmentScoreGradeName'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'grade'}
          headerOneBadgeTwo={'information'}
          nextPopUpValue={'ALIASPOPUP'}
          basicInfo={gradeInformation}
          typeOfSetObject={SET_GRADE_REDUCER_STATE}
          isRequired={true}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ALIASPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'description'}
          actualLableValue={'assessmentScoreGradeDescription'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'grade'}
          headerOneBadgeTwo={'information'}
          basicInfo={gradeInformation}
          nextPopUpValue={'PICTUREPOPUP'}
          typeOfSetObject={SET_GRADE_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'PICTUREPOPUP' && (
        <PopUpPicture
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'grade'}
          headerOneBadgeTwo={'information'}
          nextPopUpValue={'CONFIRMATIONPOPUP'}
          actualLableValue={'assessmentScoreGradePicture'}
          actualLableValueVerification={'assessmentScoreGradePictureVerification'}
          basicInfo={gradeInformation}
          typeOfSetObject={SET_GRADE_REDUCER_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'MAXIMUMPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'score'}
          labelBadgeOne={'maximum'}
          type={'number'}
          actualLableValue={'assessmentScoreGradeScoreExtremumMaximum'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'grade'}
          headerOneBadgeTwo={'information'}
          basicInfo={gradeInformation?.assessmentScoreGradeScore}
          nextPopUpValue={''}
          typeOfSetObject={SET_GRADE_SCORE_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'MINIMUMPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'score'}
          labelBadgeOne={'minimum'}
          type={'number'}
          actualLableValue={'assessmentScoreGradeScoreExtremumMinimum'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'grade'}
          headerOneBadgeTwo={'information'}
          basicInfo={gradeInformation?.assessmentScoreGradeScore}
          nextPopUpValue={''}
          typeOfSetObject={SET_GRADE_SCORE_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'CANCELPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'cancel'}
          headerOneBadgeOne={''}
          mode={'cancel'}
          onClickYes={onClickCancelYes}
        />
      )}
      {isPopUpValue === 'CONFIRMATIONPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'grade'}
          headerOneBadgeTwo={'create'}
          onClickYes={onClickYes}
        />
      )}

      {isPopUpValue === 'TENURESATRTDATEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tenure'}
          labelBadgeOne={'start'}
          actualLableValue={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'grade'}
          headerOneBadgeTwo={'information'}
          basicInfo={tenureStartDate}
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={SET_GRADE_SCORE_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TENUREENDDATEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tenure'}
          labelBadgeOne={'end'}
          actualLableValue={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'grade'}
          headerOneBadgeTwo={'information'}
          basicInfo={tenureEndDate}
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={SET_GRADE_SCORE_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'STATUSPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentStatus'}
          label={'status'}
          listSelect={[
            { id: 'Active', name: 'Active' },
            { id: 'Archived', name: 'Archived' },
            { id: 'Inactive', name: 'Inactive' },
            { id: 'Suspended', name: 'Suspended' },
            { id: 'Terminated', name: 'Terminated' }
          ]}
          mappingValue={'id'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'grade'}
          headerOneBadgeTwo={'information'}
          isRequired={true}
          basicInfo={''}
          nextPopUpValue={''}
          typeOfSetObject={SET_GRADE_SCORE_STATE}
          handleNextPopupValue={() => {}}
          isNotRevised={true}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TAGREADONLYPRIMARYPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tag'}
          labelBadgeOne={'primary'}
          actualLableValue={'id'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'grade'}
          headerOneBadgeTwo={'information'}
          basicInfo={ responseObject}
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={SET_GRADE_SCORE_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TAGSECONDARYPOPUP' && (
        <PopUpTagSecondary
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'grade'}
          headerOneBadgeTwo={'information'}
          tagSecondary={'' || {}}
          signInSetup={'' || {}}
          nextPopUpValue={''}
          typeOfSetObject={SET_GRADE_SCORE_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}

    </div>
  );
};

export default PopUpGradeCreate;
