import React from 'react';
import { isMobile } from 'react-device-detect';
// import AllocationAccordian from '../Accordian/AllocationAccordian';
// import Manuscript from '@material-ui/icons/Description';
import { useDispatch, useSelector } from 'react-redux';
import DisplayPanelAccordianReviewListOne from '../Accordian/DisplayPanelAccordianReviewListOne';
import DisplayPanelAccordianInformation from '../Accordian/DisplayPanelAccordianInformation';
import { Paper } from '@material-ui/core';
import {
  makeAssessmentReviewListRequestObject,
  makeJobProfileObj
} from '../../Actions/GenericActions';
import {
  FILTERMODE,
  GET_ALLOCATE_ASSESSMENT,
  GET_ALLOCATE_JOB,
  LOADER_START,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_PAGE_COUNT,
  SET_REQUEST_OBJECT
} from '../../actionType';
import {
  getJobProfileAllocateApi,
  getJobProfileGroupJobProfileDistinctApiCall
} from '../../Actions/ActionJobProfile';

const DisplayPaneThreeSectionTwoJobProfileGroup = () => {
  // const [listExpand, setListExpand] = useState('');
  const { reviewMode, relatedReviewListPaneThree, responseObject } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  const { selectedAssociateInfo, countPage, reviewListDistinctData } = useSelector(
    (state) => state.DisplayPaneTwoReducer
  );
  const dispatch = useDispatch();
  // const { informationEngagement, informationSetup } = responseObject;
  // function capitalizeFirstLetter(string) {
  //   if (!string) return '';
  //   return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  // }
  let cultureProfileList = [];
  if (relatedReviewListPaneThree?.length > 0) {
    cultureProfileList = relatedReviewListPaneThree[0]?.jobProfile || [];
  }

  let cultureProfileArray = [];
  cultureProfileList.forEach((ob) => {
    const { id, informationBasic, informationEngagement, associateId } = ob;
    cultureProfileArray.push({
      id,
      textOne: informationBasic?.jobProfileName || '',
      textTwo: informationBasic?.jobProfileDescription || '',
      status: informationEngagement?.jobProfileStatus || '',
      isShared: ob?.jobProfileShared,
      associateId
    });
  });

  const list2 = [
    {
      id: 'a1',
      labelTextOneOne: 'job-profiles',
      labelTextOneOneBadgeOne: '',
      labelTextOneOneBadgeTwo: '',
      labelTextOneOneBadgeThree: '',
      labelTextOneOneBadgeFour: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'distinct',
          innerList: cultureProfileArray
        }
      ],
      innerInfo: 'No Information',
      isListCard: true,
      isReviewLink: true
    }
  ];
  const onclickReviewJobProfile = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    if (labelName === 'job-profiles' && selectedBadgeName === 'distinct') {
      getJobProfileGroupJobProfileDistinctApiCall(
        selectedAssociateInfo,
        '',
        -1,
        dispatch,
        'distinct',
        responseObject.id, //group id
        '',
        false,
        true
      );
    }
  };
  const onclickReviseJobProfile = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    if (labelName === 'job-profiles' && selectedBadgeName === 'distinct') {
      console.log('job-profile CLICK :::::::>>>>>>>', relatedReviewListPaneThree);
      let requestObect = makeJobProfileObj(selectedAssociateInfo, '', countPage, 0);
      let revisedGroupObject = {
        id: responseObject.id,
        jobProfileGroupName: responseObject.informationBasic.jobProfileGroupName,
        jobProfileGroupDescription: responseObject.informationBasic.jobProfileGroupDescription,
        jobProfileGroupStatus: responseObject.informationEngagement.jobProfileGroupStatus
      };
      getJobProfileAllocateApi(
        dispatch,
        requestObect,
        relatedReviewListPaneThree,
        revisedGroupObject,
        'jobProfileGroupJobProfileRevise',
        'jobProfileGroupJobProfileReviewList'
      );
      dispatch({ type: SET_PAGE_COUNT, payload: 0 });
    }
  };

  return (
    <div>
      <>
        <div className={'containerPadding'}>
          <Paper className={'dossierContainerTop'}>
            {list2.map((ob) => {
              return (
                <div key={ob.id}>
                  {ob.isListCard ? (
                    <DisplayPanelAccordianReviewListOne
                      onClickReview={onclickReviewJobProfile}
                      onClickRevise={onclickReviseJobProfile}
                      className=""
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  ) : (
                    <DisplayPanelAccordianInformation
                      onClickRevise={onclickReviseJobProfile}
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  )}
                </div>
              );
            })}
          </Paper>
        </div>
      </>
      {isMobile && (
        <div className={'containerPadding'} style={{ height: '55px' }}>
          {' '}
        </div>
      )}
    </div>
  );
};

export default DisplayPaneThreeSectionTwoJobProfileGroup;
