import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PopUpPicture from '../../PopUpInformation/PopUpPicture';
import PopUpTextField from '../../PopUpInformation/PopUpTextField';
import PopUpConfirm from '../../PopUpGeneric/PopUpConfirm';
import {
  CLEAR_ROLE_REDUCER_STATE,
  POPUP_CLOSE,
  CREATE_ASSESSEE_ROLE_SAGA,
  SET_ASSESSEE_ROLE_REDUCER_STATE,
  LOADER_START,
  SET_ROLE_DYNAMIC_STATE,
  UPDATE_ASSESSEE_PERSONAL_INFO,
  UPDATE_ASSESSEE_ENGAGEMENT_INFO,
  SET_DISPLAY_THREE_SINGLE_STATE,
  SET_SETUP_PERMISSION,
  SET_ASSESSEE_ROLE_CLASSIFICAION_STATE,
  SET_SETUP_PERMISSION_NEW,
  SET_POPUP_SINGLE_STATE,
  SET_DISPLAY_TWO_SINGLE_STATE
} from '../../actionType';
import PopUpReviewList from '../../PopUpInformation/PopUpReviewList';
import PopUpTagSecondary from '../../PopUpInformation/PopUpTagSecondary';
import PopUpDropList from '../../PopUpInformation/PopUpDropList';
import PopUpCheckbox from '../../PopUpInformation/PopUpCheckbox';
import PopUpCheckboxRolePermission from '../../PopUpInformation/PopUpCheckboxRolePermission';
import { getPopUpOptionList } from '../../Actions/GenericActions';
import { ASSESSEE_NODE_ROLE_REVIEW_LIST_POPUP_OPTION } from '../../PopUpConfig';

const PopUpAssesseeRoleCreate = (props) => {
  const { headerOne = 'assessees' } = props;
  const { isPopUpValue, reducerObeject, objectName, popupErrorMsg } = useSelector(
    (state) => state.PopUpReducer
  );
  const { assesseeRole } = useSelector((state) => state.RoleCreateReducer);
  const {
    selectedAssociateInfo,
    coreRoleReviewListData,
    permissionStateOne,
    permissionStateTwo,
    permissionStateThree,
    signedAssesseePermission
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { reviewMode, responseObject, statusPopUpValue } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  const [roleSelectedError, setRoleSelectedError] = useState('');
  const dispatch = useDispatch();
  const onClickCancelYes = () => {
    dispatch({
      type: SET_DISPLAY_THREE_SINGLE_STATE,
      payload: { stateName: 'createMode', value: '' }
    });
    dispatch({ type: CLEAR_ROLE_REDUCER_STATE });
    dispatch({ type: POPUP_CLOSE });
  };
  const onClickYes = () => {
    var requestObj = {
      module: 'Assessee-Role',
      action: 'Create',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        assesseeRole: {
          informationBasic: assesseeRole.informationBasic,
          informationSetup:{
            assesseeRoleClassification: assesseeRole.informationSetup.assesseeRoleClassification
          }
        }
      }
    };
    let assesseeDistinctPermission =
      signedAssesseePermission?.assesseeDistinctPermission?.assesseeAssesseeRolePermission;
    let popupContentArr = getPopUpOptionList(
      ASSESSEE_NODE_ROLE_REVIEW_LIST_POPUP_OPTION,
      assesseeDistinctPermission
    );
    let finalPopupOptionArray = [];
    let classification = [];
    if (Object.keys(responseObject).length === 0) {
      classification = assesseeRole?.informationSetup?.assesseeRoleClassification?.assesseeRoleClassificationPrimary;
      
    } if (Object.keys(responseObject).length !== 0) {
      classification = responseObject?.informationSetup?.assesseeRoleClassification?.assesseeRoleClassificationPrimary;
    }
    console.log('classification>>', classification);
    popupContentArr.forEach((element) => {
      if (classification === 'Bespoke' && element.data === 'share') {
        finalPopupOptionArray.push({ ...element, disabled: true });
      } else {
        finalPopupOptionArray.push(element);
      }
    });
    console.log('finalPopupOptionArray>>', finalPopupOptionArray);
    dispatch({ type: LOADER_START });
    dispatch({
      type: CREATE_ASSESSEE_ROLE_SAGA,
      payload: { request: requestObj, popupContentArr: finalPopupOptionArray, headerOne: headerOne }
    });
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: {
        stateName: 'popupOptionArr',
        value: finalPopupOptionArray
      }
    });
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: {
        stateName: 'originPrimaryArrOprion',
        value: finalPopupOptionArray
      }
    });
  };
  const updateRoleGroup = (e) => {
    setRoleSelectedError('');
    let tagId = e.currentTarget.getAttribute('tag');
    let tagIdArr =
      assesseeRole.informationSetup.assesseeRoleClassification.assesseeRoleClassificationPrimary;
    if (tagIdArr.includes(tagId)) {
      document.getElementById(tagId).style.backgroundColor = 'white';
      tagIdArr = tagIdArr.filter(function (number) {
        return number !== tagId;
      });
    } else {
      var arr = [];
      tagIdArr = [...arr];
      tagIdArr.push(tagId);
      document.getElementById(tagId).style.backgroundColor = '#F0F0F0';
    }
    dispatch({
      type: SET_SETUP_PERMISSION,
      payload: {
        objectName: 'assesseeRole',
        stateName: 'assesseeRoleClassification',
        actualStateName: 'assesseeRoleClassificationPrimary',
        value: tagIdArr
      }
    });
  };
  let classificationArray = [];
  if (
    signedAssesseePermission?.assesseeDistinctPermission?.assesseeAssesseeRoleBespokePermission
      ?.create
  ) {
    classificationArray.push({ id: 'Bespoke', name: 'Bespoke' });
  }
  if (
    signedAssesseePermission?.assesseeDistinctPermission?.assesseeAssesseeRoleGenericPermission
      ?.create
  ) {
    classificationArray.push({ id: 'Generic', name: 'Generic' });
  }
  console.log(
    'bespoke permission',
    signedAssesseePermission?.associateDistinctPermission?.assesseeAssociateRoleBespokePermission
      ?.create
  );
  console.log(
    'genric permission',
    signedAssesseePermission?.associateDistinctPermission?.assesseeAssociateRoleGenericPermission
      ?.create
  );
  console.log('classificationArray>>>', classificationArray);
  const signedAssociateId =
    selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary;
    console.log('assesseeRole.informationSetup?.assesseeRolePermission----------', assesseeRole.informationSetup?.assesseeRolePermission);
    console.log('permissionStatethree', permissionStateThree);
    console.log('headerOne', headerOne);
  return (
    <div>
      {isPopUpValue === 'NAMEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'name'}
          actualLableValue={'assesseeRoleName'}
          actualLableValueVerification={'assesseeRoleNameVerification'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'role'}
          headerOneBadgeTwo={'information'}
          nextPopUpValue={'ALIASPOPUP'}
          basicInfo={assesseeRole.informationBasic}
          typeOfSetObject={SET_ASSESSEE_ROLE_REDUCER_STATE}
          isRequired={true}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          errorMsg={popupErrorMsg}
          isNotRevised={reviewMode === 'revise' && responseObject.associateId !== signedAssociateId}
        />
      )}
      {isPopUpValue === 'ALIASPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'description'}
          actualLableValue={'assesseeRoleDescription'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'role'}
          headerOneBadgeTwo={'information'}
          basicInfo={assesseeRole.informationBasic}
          nextPopUpValue={'PICTUREPOPUP'}
          typeOfSetObject={SET_ASSESSEE_ROLE_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={reviewMode === 'revise' && responseObject.associateId !== signedAssociateId}
        />
      )}
      {isPopUpValue === 'PICTUREPOPUP' && (
        <PopUpPicture
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'role'}
          headerOneBadgeTwo={'information'}
          actualLableValue={'assesseeRolePicture'}
          actualLableValueVerification={'assesseeRolePictureVerification'}
          basicInfo={assesseeRole.informationBasic}
          typeOfSetObject={SET_ASSESSEE_ROLE_REDUCER_STATE}
          nextPopUpValue={'ROLEGROUPPOPUP'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={reviewMode === 'revise' && responseObject.associateId !== signedAssociateId}
        />
      )}
      {isPopUpValue === 'ROLEGROUPPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assesseeRoleClassificationPrimary'}
          label={'classification'}
          listSelect={classificationArray}
          // listSelect={[
          //   { id: 'Bespoke', name: 'Bespoke' },
          //   { id: 'Generic', name: 'Generic' }
          // ]}
          mappingValue={'id'}
          inputHeader={''}
          inputHeaderBadgeOne={''}
          inputHeaderBadgeTwo={''}
          labelval={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'role'}
          headerOneBadgeTwo={'information'}
          isRequired={true}
          nextPopUpValue={reviewMode === 'revise' ? '' : 'CONFIRMATIONPOPUP'}
          basicInfo={assesseeRole?.informationSetup?.assesseeRoleClassification}
          typeOfSetObject={SET_ASSESSEE_ROLE_CLASSIFICAION_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={reviewMode === 'revise' && responseObject.associateId !== signedAssociateId}
        />
      )}
      {isPopUpValue === 'CANCELPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'cancel'}
          headerOneBadgeOne={''}
          mode={'cancel'}
          onClickYes={onClickCancelYes}
        />
      )}
      {isPopUpValue === 'CONFIRMATIONPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'role'}
          headerOneBadgeTwo={'create'}
          onClickYes={onClickYes}
          onClickNoFun={onClickCancelYes}
        />
      )}
      {isPopUpValue === 'TAGREADONLYPRIMARYPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tag'}
          labelBadgeOne={'primary'}
          actualLableValue={'assesseeRoleTagPrimary'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'role'}
          headerOneBadgeTwo={'information'}
          basicInfo={responseObject?.informationEngagement?.assesseeRoleTag || ''}
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={''}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TENURESATRTDATEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tenure'}
          labelBadgeOne={'start'}
          actualLableValue={'assesseeRoleTenureDateTimeStart'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'role'}
          headerOneBadgeTwo={'information'}
          basicInfo={
            responseObject?.informationEngagement?.assesseeRoleTenure || 'mm/dd/yyyy --:-- --'
          }
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={''}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TENUREENDDATEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tenure'}
          labelBadgeOne={'end'}
          actualLableValue={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'role'}
          headerOneBadgeTwo={'information'}
          basicInfo={
            responseObject?.informationEngagement?.assesseeRoleTenure
              ?.assesseeRoleTenureDateTimeEnd || 'mm/dd/yyyy --:-- --'
          }
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={''}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'STATUSPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assesseeRoleStatus'}
          label={'status'}
          listSelect={[
            { id: 'ACTIVE', name: 'Active' },
            { id: 'SUSPENDED', name: 'Suspended' },
            { id: 'TERMINATED', name: 'Terminated' },
            { id: 'UNVERIFIED', name: 'Unverified' },
            { id: 'CONFIRMED', name: 'Confirmed' }
          ]}
          mappingValue={'id'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'role'}
          headerOneBadgeTwo={'information'}
          isRequired={true}
          basicInfo={statusPopUpValue}
          nextPopUpValue={''}
          typeOfSetObject={UPDATE_ASSESSEE_PERSONAL_INFO}
          handleNextPopupValue={() => {}}
          isNotRevised={true}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TAGSECONDARYPOPUP' && (
        <PopUpTagSecondary
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'role'}
          headerOneBadgeTwo={'information'}
          tagSecondary={assesseeRole?.informationEngagement || {}}
          signInSetup={assesseeRole?.informationSetup || {}}
          nextPopUpValue={'CONFIRMATIONPOPUP'}
          typeOfSetObject={UPDATE_ASSESSEE_ENGAGEMENT_INFO}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={reviewMode === 'revise' && responseObject.associateId !== signedAssociateId}
        />
      )}
      {isPopUpValue === 'PERMISSIONPOPUP' && (
        <PopUpCheckboxRolePermission
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'role'}
          headerOneBadgeTwo={'information'}
          inputHeader={permissionStateOne}
          inputHeaderBadge={permissionStateTwo}
          typeOfStateObj={permissionStateThree}
          objectName={'assesseeRole'}
          stateName={'assesseeRolePermission'}
          informationArr={
            permissionStateTwo === 'distinct' || permissionStateTwo === 'self'
              ? [
                  { id: 'all', name: 'all' },
                  { id: 'key', name: 'key' }
                ]
              : [{ id: 'key', name: 'key' }]
          }
          informationValue={'assesseePermissionInformation'}
          isRolePermission
          valueArr={[
            { name: 'create', disabled: false, checked: false },
            { name: 'delete', disabled: false, checked: false },
            { name: 'move', disabled: false, checked: false },
            { name: 'review', disabled: false, checked: false },
            { name: 'revise', disabled: false, checked: false },
            { name: 'sell', disabled: false, checked: false },
            { name: 'share', disabled: false, checked: false }
          ]}
          valueArrState={JSON.parse(
            JSON.stringify(assesseeRole.informationSetup?.assesseeRolePermission)
          )}
          nextPopUpValue={''}
          typeOfSetObject={SET_SETUP_PERMISSION_NEW}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={reviewMode === 'revise' && responseObject.associateId !== signedAssociateId}
        />
      )}
    </div>
  );
};

export default PopUpAssesseeRoleCreate;
