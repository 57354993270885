import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
// import AllocationAccordian from '../Accordian/AllocationAccordian';
import Manuscript from '@material-ui/icons/Description';
import { useDispatch, useSelector } from 'react-redux';
import DisplayPanelAccordianReviewListOne from '../Accordian/DisplayPanelAccordianReviewListOne';
import DisplayPanelAccordianInformation from '../Accordian/DisplayPanelAccordianInformation';
import { Paper } from '@material-ui/core';
import {
  GET_JOBPROFILE_GROUP_REVIEW_LIST_SAGA,
  GET_JOBPROFILE_TYPE_REVIEW_LIST_SAGA,
  INTERNAL_NODE_LIST_WITH_PERMISSIONS_SAGA,
  LOADER_START,
  SET_CORE_GROUP_REVIEW_LIST_REQ_OBJECT,
  SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT,
  SET_CORE_TYPE_REVIEW_LIST_REQ_OBJECT,
  SET_POPUP_VALUE,
  SET_STATUS_POPUP_VALUE
} from '../../actionType';
import DisplayPanelAccordianReviewListTwo from '../Accordian/DisplayPanelAccordianReviewListTwo';
import {
  makeInternalNodeObj,
  makeJobProfileGroupObj,
  makeJobProfileTypeObj
} from '../../Actions/GenericActions';

const DisplayPaneThreeSectionOneJobProfileGroup = () => {
  // const [listExpand, setListExpand] = useState('');
  const { responseObject, reviewMode } = useSelector((state) => state.DisplayPaneThreeReducer);
  const { selectedAssociateInfo, assignmentCreateType } = useSelector(
    (state) => state.DisplayPaneTwoReducer
  );
  const { informationEngagement, informationSetup, informationAllocation } = responseObject;
  const dispatch = useDispatch();
  //ascendant
  let ascendantAll = [];
  if (
    informationAllocation?.jobProfileGroupAscendant?.jobProfileGroupAscendantAll &&
    informationAllocation?.jobProfileGroupAscendant?.jobProfileGroupAscendantAll.length > 0
  ) {
    const tempArr = informationAllocation?.jobProfileGroupAscendant?.jobProfileGroupAscendantAll;
    tempArr.forEach((ob) => {
      ascendantAll.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.jobProfileGroupName || '',
        textTwo: ob?.informationBasic?.jobProfileGroupDescription || '',
        status: ob?.informationEngagement?.jobProfileGroupStatus || ''
      });
    });
  }
  let ascendantPrimary = [];
  if (
    informationAllocation?.jobProfileGroupAscendant?.jobProfileGroupAscendantPrimary &&
    informationAllocation?.jobProfileGroupAscendant?.jobProfileGroupAscendantPrimary.length > 0
  ) {
    const tempArr =
      informationAllocation?.jobProfileGroupAscendant?.jobProfileGroupAscendantPrimary;
    tempArr.forEach((ob) => {
      ascendantPrimary.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.jobProfileGroupName || '',
        textTwo: ob?.informationBasic?.jobProfileGroupDescription || '',
        status: ob?.informationEngagement?.jobProfileGroupStatus || ''
      });
    });
  }
  let ascendantSecondary = [];
  if (
    informationAllocation?.jobProfileGroupAscendant?.jobProfileGroupAscendantSecondary &&
    informationAllocation?.jobProfileGroupAscendant?.jobProfileGroupAscendantSecondary.length > 0
  ) {
    const tempArr =
      informationAllocation?.jobProfileGroupAscendant?.jobProfileGroupAscendantSecondary;
    tempArr.forEach((ob) => {
      ascendantSecondary.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.jobProfileGroupName || '',
        textTwo: ob?.informationBasic?.jobProfileGroupDescription || '',
        status: ob?.informationEngagement?.jobProfileGroupStatus || ''
      });
    });
  }
  //decendent
  let descendantAll = [];
  if (
    informationAllocation?.jobProfileGroupDescendant?.jobProfileGroupDescendantAll &&
    informationAllocation?.jobProfileGroupDescendant?.jobProfileGroupDescendantAll.length > 0
  ) {
    const tempArr = informationAllocation?.jobProfileGroupDescendant?.jobProfileGroupDescendantAll;
    tempArr.forEach((ob) => {
      descendantAll.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.jobProfileGroupName || '',
        textTwo: ob?.informationBasic?.jobProfileGroupDescription || '',
        status: ob?.informationEngagement?.jobProfileGroupStatus || ''
      });
    });
  }
  let descendantPrimary = [];
  if (
    informationAllocation?.jobProfileGroupDescendant?.jobProfileGroupDescendantPrimary &&
    informationAllocation?.jobProfileGroupDescendant?.jobProfileGroupDescendantPrimary.length > 0
  ) {
    const tempArr =
      informationAllocation?.jobProfileGroupDescendant?.jobProfileGroupDescendantPrimary;
    tempArr.forEach((ob) => {
      descendantPrimary.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.jobProfileGroupName || '',
        textTwo: ob?.informationBasic?.jobProfileGroupDescription || '',
        status: ob?.informationEngagement?.jobProfileGroupStatus || ''
      });
    });
  }
  let descendantSecondary = [];
  if (
    informationAllocation?.jobProfileGroupDescendant?.jobProfileGroupDescendantSecondary &&
    informationAllocation?.jobProfileGroupDescendant?.jobProfileGroupDescendantSecondary.length > 0
  ) {
    const tempArr =
      informationAllocation?.jobProfileGroupDescendant?.jobProfileGroupDescendantSecondary;
    tempArr.forEach((ob) => {
      descendantSecondary.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.jobProfileGroupName || '',
        textTwo: ob?.informationBasic?.jobProfileGroupDescription || '',
        status: ob?.informationEngagement?.jobProfileGroupStatus || ''
      });
    });
  }
  // node primary list
  let nodePrimary = [];
  if (
    informationAllocation?.jobProfileGroupNode?.jobProfileGroupNodePrimary &&
    informationAllocation?.jobProfileGroupNode?.jobProfileGroupNodePrimary.length > 0
  ) {
    const tempArr = informationAllocation?.jobProfileGroupNode?.jobProfileGroupNodePrimary;
    tempArr.forEach((ob) => {
      nodePrimary.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.associateNodeName || '',
        textTwo: ob?.informationBasic?.associateNodeDescription || '',
        status: ob?.informationEngagement?.associateNodeStatus || ''
      });
    });
  }

  // node secondary list
  let nodeSecondary = [];
  if (
    informationAllocation?.jobProfileGroupNode?.jobProfileGroupNodeSecondary &&
    informationAllocation?.jobProfileGroupNode?.jobProfileGroupNodeSecondary.length > 0
  ) {
    const tempArr = informationAllocation?.jobProfileGroupNode?.jobProfileGroupNodeSecondary;
    tempArr.forEach((ob) => {
      nodeSecondary.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.associateNodeName || '',
        textTwo: ob?.informationBasic?.associateNodeDescription || '',
        status: ob?.informationEngagement?.associateNodeStatus || ''
      });
    });
  }
  let groupTypeListPrimary = [];
  if (
    informationAllocation?.jobProfileGroupType?.jobProfileGroupTypePrimary &&
    informationAllocation?.jobProfileGroupType?.jobProfileGroupTypePrimary.length > 0
  ) {
    const tempArr = informationAllocation?.jobProfileGroupType?.jobProfileGroupTypePrimary;
    tempArr.forEach((ob) => {
      groupTypeListPrimary.push({
        id: ob?.id + '-' || '',
        textOne: ob?.informationBasic?.jobProfileTypeName || '',
        textTwo: ob?.informationBasic?.jobProfileTypeDescription || '',
        status: ob?.informationEngagement?.jobProfileTypeStatus || ''
      });
    });
  }
  let groupTypeListSecondary = [];
  if (
    informationAllocation?.jobProfileGroupType?.jobProfileGroupTypeSecondary &&
    informationAllocation?.jobProfileGroupType?.jobProfileGroupTypeSecondary.length > 0
  ) {
    const tempArr = informationAllocation?.jobProfileGroupType?.jobProfileGroupTypeSecondary;
    tempArr.forEach((ob) => {
      groupTypeListSecondary.push({
        id: ob?.id + '-' || '',
        textOne: ob?.informationBasic?.jobProfileTypeName || '',
        textTwo: ob?.informationBasic?.jobProfileTypeDescription || '',
        status: ob?.informationEngagement?.jobProfileTypeStatus || ''
      });
    });
  }
  function capitalizeFirstLetter(string) {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  const allocationList = [
    {
      id: 'a1',
      labelTextOneOne: 'group',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'ascendant',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'all',
              innerList: ascendantAll.sort((a, b) =>
                a.textOne.toLowerCase() !== b.textOne.toLowerCase()
                  ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
                    ? -1
                    : 1
                  : 0
              )
            },
            {
              labelTextTwoBadge: 'primary',
              innerList: ascendantPrimary.sort((a, b) =>
                a.textOne.toLowerCase() !== b.textOne.toLowerCase()
                  ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
                    ? -1
                    : 1
                  : 0
              )
            },
            {
              labelTextTwoBadge: 'secondary',
              innerList: ascendantSecondary.sort((a, b) =>
                a.textOne.toLowerCase() !== b.textOne.toLowerCase()
                  ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
                    ? -1
                    : 1
                  : 0
              )
            }
          ]
        },
        {
          labelTextOneOneBadge: 'descendant',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'all',
              innerList: descendantAll.sort((a, b) =>
                a.textOne.toLowerCase() !== b.textOne.toLowerCase()
                  ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
                    ? -1
                    : 1
                  : 0
              )
            },
            {
              labelTextTwoBadge: 'primary',
              innerList: descendantPrimary.sort((a, b) =>
                a.textOne.toLowerCase() !== b.textOne.toLowerCase()
                  ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
                    ? -1
                    : 1
                  : 0
              )
            },
            {
              labelTextTwoBadge: 'secondary',
              innerList: descendantSecondary.sort((a, b) =>
                a.textOne.toLowerCase() !== b.textOne.toLowerCase()
                  ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
                    ? -1
                    : 1
                  : 0
              )
            }
          ]
        }
      ],
      innerInfo: 'No Information',
      isListCard: true,
      isReviewLink: false,
      isMultiList: true
    },
    // {
    //   id: 'a2',
    //   labelTextOneOne: 'manager',
    //   labelTextOneOneBadgeOne: 'primary',
    //   labelTextOneOneBadgeTwo: 'secondary',
    //   labelTextOneOneBadgeThree: '',
    //   labelTextOneOneBadgeFour: '',
    //   labelTextOneOneBadges: [
    //     {
    //       labelTextOneOneBadge: 'primary',
    //       innerList: [
    //         {
    //           id: 'associate1',
    //           textOne: 'Simple Sample 01',
    //           textTwo: 'Manager',
    //           status: 'active'
    //         },
    //         {
    //           id: 'associate2',
    //           textOne: 'Simple Sample 02',
    //           textTwo: 'Manager',
    //           status: 'active'
    //         },
    //         {
    //           id: 'associate3',
    //           textOne: 'Simple Sample 03',
    //           textTwo: 'Manager',
    //           status: 'active'
    //         }
    //       ]
    //     },
    //     {
    //       labelTextOneOneBadge: 'secondary',
    //       innerList: []
    //     }
    //   ],
    //   innerInfo: 'No Information',
    //   isListCard: true
    // },
    {
      id: 'a3',
      labelTextOneOne: 'node',
      labelTextOneOneBadgeOne: 'primary',
      labelTextOneOneBadgeTwo: 'secondary',
      labelTextOneOneBadgeThree: '',
      labelTextOneOneBadgeFour: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'primary',
          innerList: nodePrimary.sort((a, b) =>
            a.textOne.toLowerCase() !== b.textOne.toLowerCase()
              ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
                ? -1
                : 1
              : 0
          )
        },
        {
          labelTextOneOneBadge: 'secondary',
          innerList: nodeSecondary.sort((a, b) =>
            a.textOne.toLowerCase() !== b.textOne.toLowerCase()
              ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
                ? -1
                : 1
              : 0
          )
        }
      ],
      innerInfo: 'No Information',
      isListCard: true
    },
    {
      id: 'a4',
      labelTextOneOne: 'type',
      labelTextOneOneBadgeOne: 'primary',
      labelTextOneOneBadgeTwo: 'secondary',
      labelTextOneOneBadgeThree: '',
      labelTextOneOneBadgeFour: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'primary',
          innerList: groupTypeListPrimary.sort((a, b) =>
            a.textOne.toLowerCase() !== b.textOne.toLowerCase()
              ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
                ? -1
                : 1
              : 0
          )
        },
        {
          labelTextOneOneBadge: 'secondary',
          innerList: groupTypeListSecondary.sort((a, b) =>
            a.textOne.toLowerCase() !== b.textOne.toLowerCase()
              ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
                ? -1
                : 1
              : 0
          )
        }
      ],
      innerInfo: 'No Information',
      isListCard: true
    }
  ];
  const engagementList = [
    {
      id: 'a1',
      labelTextOneOne: 'log',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'all',
          textOne: ''
        },
        {
          labelTextOneOneBadge: 'key',
          textOne: ''
        }
      ],
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false,
      IconOne: Manuscript
    },
    {
      id: 'a2',
      textOneOne:
        capitalizeFirstLetter(informationEngagement?.jobProfileGroupStatus) || 'No Information',
      labelTextOneOne: 'status',
      innerAssociateList: [],
      innerInfo: 'No Information',
      isListCard: false
    },
    {
      id: 'a3',
      labelTextOneOne: 'tag',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'primary',
          textOne:
            informationEngagement?.jobProfileGroupTag?.jobProfileGroupTagPrimary || 'No Information'
        },
        {
          labelTextOneOneBadge: 'secondary',
          textOne:
            informationEngagement?.jobProfileGroupTag?.jobProfileGroupTagSecondary ||
            'No Information'
        }
      ],
      innerAssociateList: [],
      innerInfo: 'No Information',
      isListCard: false
    },
    {
      id: 'a4',
      labelTextOneOne: 'tenure',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'start',
          textOne:
            informationEngagement?.jobProfileGroupTenure?.jobProfileGroupTenureDateTimeStart ||
            'No Information'
        },
        {
          labelTextOneOneBadge: 'end',
          textOne:
            informationEngagement?.jobProfileGroupTenure?.jobProfileGroupTenureDateTimeEnd ||
            'No Information'
        }
      ],
      innerAssociateList: [],
      innerInfo: 'Assessee',
      isListCard: false
    }
  ];

  const classificationList = [
    {
      id: 'a1',
      textOneOne:
        informationSetup?.jobProfileGroupClassification.jobProfileGroupClassificationPrimary ||
        'No Information',
      labelTextOneOne: 'classification',
      innerAssociateList: [],
      innerInfo: 'No Information',
      isListCard: false
    },
    {
      id: 'setupList1',
      labelTextOneOne: 'fee',
      labelTextOneOneBadges: 
      [
        {
          labelTextOneOneBadge: 'move',
          textOne: informationSetup?.jobProfileGroupMoveFee || 0
        },
        {
          labelTextOneOneBadge: 'sell',
          textOne: informationSetup?.jobProfileGroupSellFee || 0
        },
        {
          labelTextOneOneBadge: 'share',
          textOne: informationSetup?.jobProfileGroupShareFee ||  0
        }
      ],
      innerInfo: 'No',
      isListCard: false,
      isReviewLink: false,
      isMultiInfoCard: false
    },

    // {
    //   id: 'a2',
    //   labelTextOneOne: 'classification',
    //   labelTextOneOneBadgeOne: '',
    //   labelTextOneOneBadgeTwo: '',
    //   labelTextOneOneBadges: [
    //     {
    //       labelTextOneOneBadge: '',
    //       innerList: [
    //         {
    //           id: '001',
    //           textOne:
    //             informationSetup?.cultureProfileGroupClassification
    //               .cultureProfileGroupClassificationPrimary,
    //           textTwo: '',
    //           status: ''
    //         }
    //       ]
    //     }
    //   ],
    //   innerInfo: 'No Information',
    //   isListCard: true
    // }
  ];
  const reviseAllocation = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    const innerSelectedBadgeName = e.currentTarget.getAttribute('id');
    console.log('=====>', labelName, selectedBadgeName);
    if (labelName === 'group') {
      if (selectedBadgeName === 'ascendant' && innerSelectedBadgeName === 'primary') {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: 'GROUPLISTPOPUP',
            popupMode: 'job-profilesGROUPCREATE'
          }
        });
        dispatch({ type: LOADER_START });
        let requestObj = makeJobProfileGroupObj(
          selectedAssociateInfo,
          'active',
          0,
          -1,
          'list',
          assignmentCreateType
        );
        dispatch({
          type: SET_CORE_GROUP_REVIEW_LIST_REQ_OBJECT,
          payload: requestObj
        });
        dispatch({ type: LOADER_START });
        dispatch({
          type: GET_JOBPROFILE_GROUP_REVIEW_LIST_SAGA,
          payload: {
            request: requestObj,
            BadgeOne: '',
            BadgeTwo: '',
            BadgeThree: '',
            isMiddlePaneList: false
          }
        });
      }
    }
    if (labelName === 'node') {
      dispatch({ type: LOADER_START });
      let nodeRequestObj = makeInternalNodeObj(
        selectedAssociateInfo,
        'active',
        0,
        -1,
        '',
        'Associate-Self-Node-List-Create'
      );
      dispatch({
        type: SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT,
        payload: nodeRequestObj
      });
      dispatch({
        type: INTERNAL_NODE_LIST_WITH_PERMISSIONS_SAGA,
        payload: {
          request: nodeRequestObj,
          BadgeOne: '',
          BadgeTwo: '',
          BadgeThree: '',
          nodeViewState: 'list',
          isMiddlePaneList: false
        }
      });
      if (selectedBadgeName === 'primary') {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: 'NODELISTPOPUP',
            popupMode: 'job-profilesGROUPCREATE'
          }
        });
      }
      if (selectedBadgeName === 'secondary') {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: 'NODESECONDARYLISTPOPUP',
            popupMode: 'job-profilesGROUPCREATE'
          }
        });
      }
    }
    if (labelName === 'type') {
      dispatch({ type: LOADER_START });
      let roleRequestObj = makeJobProfileTypeObj(selectedAssociateInfo, 'active', 0, -1);
      dispatch({ type: SET_CORE_TYPE_REVIEW_LIST_REQ_OBJECT, payload: roleRequestObj });
      dispatch({
        type: GET_JOBPROFILE_TYPE_REVIEW_LIST_SAGA,
        payload: {
          request: roleRequestObj,
          BadgeOne: '',
          BadgeTwo: '',
          BadgeThree: '',
          isMiddlePaneList: false
        }
      });

      if (selectedBadgeName === 'primary') {
        dispatch({ type: LOADER_START });
        let roleRequestObj = makeJobProfileTypeObj(selectedAssociateInfo, 'active', 0, -1);
        dispatch({ type: SET_CORE_TYPE_REVIEW_LIST_REQ_OBJECT, payload: roleRequestObj });
        dispatch({
          type: GET_JOBPROFILE_TYPE_REVIEW_LIST_SAGA,
          payload: {
            request: roleRequestObj,
            BadgeOne: '',
            BadgeTwo: '',
            BadgeThree: '',
            isMiddlePaneList: false
          }
        });
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: 'TYPELISTPOPUP',
            popupMode: 'job-profilesGROUPCREATE'
          }
        });
      }
      if (selectedBadgeName === 'secondary') {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: 'TYPESECONDARYLISTPOPUP',
            popupMode: 'job-profilesGROUPCREATE'
          }
        });
      }
    }
  };
  const reviseEngagement = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    console.log('=====>', labelName);
    if (labelName === 'status') {
      dispatch({
        type: SET_STATUS_POPUP_VALUE,
        payload: capitalizeFirstLetter(informationEngagement?.jobProfileGroupStatus)
      });
      dispatch({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'STATUSPOPUP',
          popupMode: 'job-profilesGROUPCREATE'
        }
      });
    }
    if (labelName === 'tag') {
      if (selectedBadgeName === 'primary') {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: 'TAGREADONLYPRIMARYPOPUP',
            popupMode: 'job-profilesGROUPCREATE'
          }
        });
      }
      if (selectedBadgeName === 'secondary') {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: 'TAGSECONDARYPOPUP',
            popupMode: 'job-profilesGROUPCREATE'
          }
        });
      }
    }
    if (labelName === 'tenure') {
      if (selectedBadgeName === 'start') {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: 'TENURESATRTDATEPOPUP',
            popupMode: 'job-profilesGROUPCREATE'
          }
        });
      }
      if (selectedBadgeName === 'end') {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: 'TENUREENDDATEPOPUP',
            popupMode: 'job-profilesGROUPCREATE'
          }
        });
      }
    }
  };

  const reviseClassification = (e, selectedBadgeArray) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    console.log('=====>', labelName);
    let badgeName = '';
    if (selectedBadgeArray) {
      if (selectedBadgeArray.length > 0) {
        selectedBadgeArray.forEach((element) => {
          badgeName = badgeName + element.labelTextTwoBadge;
        });
      }
    }
    console.log('=====>', badgeName);
    if (labelName === 'classification') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'CLASSIFICATIONLISTPOPUP',
          popupMode: 'job-profilesGROUPCREATE'
        }
      });
    }
    if (labelName === 'fee' && selectedBadgeName === 'move') {
      console.log('open share popup');
      dispatch({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'GROUPSASSOCIATEMOVEFEEPOPUP',
          popupMode: 'job-profilesGROUPCREATE'
        }
      });
    }
    if (labelName === 'fee' && selectedBadgeName === 'sell') {
      console.log('open share popup');
      dispatch({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'GROUPSASSOCIATESELLFEEPOPUP',
          popupMode: 'job-profilesGROUPCREATE'
        }
      });
    }
    if (labelName === 'fee' &&  selectedBadgeName === 'share') {
      console.log('open share popup');
      dispatch({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'GROUPSASSOCIATESHAREFEEPOPUP',
          popupMode: 'job-profilesGROUPCREATE'
        }
      });
    }
  };

  return (
    <div>
      <>
        <div className="containerPadding">
          <Paper className={'dossierContainerTop'}>
            {allocationList.map((ob) => {
              return (
                <div key={ob.id}>
                  {ob.isListCard ? (
                    <>
                      {ob.isMultiList ? (
                        <DisplayPanelAccordianReviewListTwo
                          onClickReview={null}
                          onClickRevise={reviseAllocation}
                          accordianObject={ob}
                          mode={reviewMode}
                        />
                      ) : (
                        <DisplayPanelAccordianReviewListOne
                          onClickRevise={reviseAllocation}
                          className=""
                          accordianObject={ob}
                          mode={reviewMode}
                        />
                      )}
                    </>
                  ) : (
                    <DisplayPanelAccordianInformation
                      onClickRevise={reviseAllocation}
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  )}
                  {/* {ob.isListCard ? (
                    <DisplayPanelAccordianReviewListOne
                      onClickRevise={reviseAllocation}
                      className=""
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  ) : (
                    <DisplayPanelAccordianInformation
                      onClickRevise={reviseAllocation}
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  )} */}
                </div>
              );
            })}
          </Paper>
        </div>
        <div className="containerPadding">
          <Paper className={'dossierContainerTop'}>
            {engagementList.map((ob) => {
              return (
                <div key={ob.id}>
                  {ob.isListCard ? (
                    <DisplayPanelAccordianReviewListOne
                      onClickRevise={reviseEngagement}
                      className=""
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  ) : (
                    <DisplayPanelAccordianInformation
                      onClickRevise={reviseEngagement}
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  )}
                </div>
              );
            })}
          </Paper>
        </div>
        <div className="containerPadding">
          <Paper className={'dossierContainerTop'}>
            {classificationList.map((ob) => {
              return (
                <div key={ob.id}>
                  {ob.isListCard ? (
                    <DisplayPanelAccordianReviewListOne
                      onClickRevise={reviseClassification}
                      className=""
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  ) : (
                    <DisplayPanelAccordianInformation
                      onClickRevise={reviseClassification}
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  )}
                </div>
              );
            })}
          </Paper>
        </div>
      </>
      {isMobile && (
        <div className={'containerPadding'} style={{ height: '55px' }}>
          {' '}
        </div>
      )}
    </div>
  );
};

export default DisplayPaneThreeSectionOneJobProfileGroup;
