import { put, takeLatest, call, takeEvery } from 'redux-saga/effects';
import {
  SET_DISPLAY_PANE_THREE_STATE,
  LOADER_STOP,
  GET_ASSOCIATE_INFO_SAGA,
  UPDATE_ASSOCIATE_BASIC_INFO,
  SET_IGURU_NODE_DYNAMIC_SINGLE_STATE,
  ASSOCIATE_INFO_REVISE_SAGA,
  SET_DISPLAY_PANE_THREE_REVIEW_MODE,
  SET_POPUP_VALUE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  ASSOCIATE_REVIEW_DISTINCT_SAGA,
  SET_DISPLAY_THREE_SINGLE_STATE,
  ASSOCIATE_SETUP_SAGA,
  ASSOCIATE_ITEM_SETUP_SAGA,
  ASSOCIATE_ANALYTIC_SETUP_SAGA,
  ASSOCIATE_ASSESSMENT_SETUP_SAGA,
  ASSOCIATE_ASSESSESS_SETUP_SAGA,
  ASSOCIATE_ASSIGNMENT_SETUP_SAGA,
  UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO,
  UPDATE_ASSOCIATE_SETUP_INFO,
  UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO,
  UPDATE_ASSOCIATE_SETUP_ASSIGNMENT_INFO,
  UPDATE_ASSOCIATE_SETUP_ITEM_INFO,
  UPDATE_ASSOCIATE_SETUP_ANALYTIC_INFO,
  ASSOCIATE_ASSESSEESETUP_REVISE_SAGA,
  ASSOCIATE_ASSOCIATESETUP_REVISE_SAGA,
  ASSOCIATE_ASSESSMENTSETUP_REVISE_SAGA,
  ASSOCIATE_ASSIGNMENTSETUP_REVISE_SAGA,
  ASSOCIATE_ITEMSETUP_REVISE_SAGA,
  ASSOCIATE_ANALYTICSETUP_REVISE_SAGA,
  ASSOCIATE_NODE_SETUP_SAGA,
  ASSOCIATE_ASSOCIATENODE_SETUP_REVISE_SAGA,
  SET_ASSOCIATE_DYNAMIC_SINGLE_STATE,
  ASSOCIATE_MULTI_STATUS_REVISE_SAGA,
  GET_ASSOCIATE_GROUP_REVIEW_LIST_SAGA,
  GET_ASSOCIATE_TYPE_REVIEW_LIST_SAGA,
  GET_ASSOCIATE_ROLE_REVIEW_LIST_SAGA,
  INTERNAL_NODE_LIST_SAGA,
  UPDATE_ASSOCIATE_SETUP_RESET_SAGA,
  UPDATE_ASSOCIATE_SETUPPLUS_ASSESSEE_INFO,
  UPDATE_ASSOCIATE_SETUPPLUS_ASSESSMENT_INFO,
  UPDATE_ASSOCIATE_SETUPPLUS_ASSIGNMENT_INFO,
  UPDATE_ASSOCIATE_SETUPPLUS_INFO,
  UPDATE_ASSOCIATE_SETUPPLUS_ITEM_INFO,
  UPDATE_ASSOCIATE_SETUPPLUS_ANALYTIC_INFO,
  ASSOCIATE_EXCHANGE_REVISE_SAGA,
  UPDATE_ASSOCIATE_INFO_CONTACT_INFO,
  UPDATE_ASSOCIATE_WORKTELEPHONE_SECONDARY_INFO,
  UPDATE_ASSOCIATE_WORKTELEPHONE_INFO,
  ASSOCIATE_ASSESSESS_SIGNON_SETUP_SAGA,
  SET_ASSOCIATE_CREATE_SINGLE_STATE,
  GET_ASSOCIATES_NODE_REVIEW_LIST_SAGA,
  SET_PAGE_COUNT,
  GET_ASSOCIATEGROUP_ASSOCIATE_REVIEW_LIST_SAGA,
  LOADER_START,
  GET_ASSOCIATETYPE_ASSOCIATE_REVIEW_LIST_SAGA,
  GET_ASSOCIATEROLE_ASSOCIATE_REVIEW_LIST_SAGA,
  SET_POPUP_SINGLE_STATE,
  ASSOCIATE_SIGN_ON_ASSIGNMENT_LIST_SAGA,
  SET_MIDDLEPANE_STATE,
  REVIEWLIST_DISTINCT_DATA,
  SET_SINGLE_ASSOCIATE_INFORMATION,
  SET_SINGLE_OBJECT_ASSOCIATE_INFORMATION
} from '../../actionType';
import {
  ASSIGNMENT_MODULE_URL,
  ASSESSMENT_MODULE_URL,
  ITEM_MODULE_URL,
  JOB_MODULE_URL,
  ASSOCIATE_MODULE_URL,
  ASSESSEE_MODULE_URL
} from '../../endpoints';
import { EXCEPTION_ERROR_MESSAGE } from '../../errorMessage';
import Store from '../../store';

const associateReviewInfoApi = async (requestObj) => {
  let URL = requestObj.URL;
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      Authorization: requestObj.isIdToken
        ? localStorage.getItem('idToken')
        : localStorage.getItem('token')
    }),
    body: JSON.stringify(requestObj.data)
  };
  const response = await fetch(URL, requestOptions);
  const json = await response.json();
  return json;
};

function* workerReviewInfoAssociateSaga(data) {
  try {
    const userResponse = yield call(associateReviewInfoApi, {
      data: data.payload.reqBody,
      URL: ASSOCIATE_MODULE_URL
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === '000') {
      const { isReviseMode = false, popupHeaderOneBadgeOne } = data.payload;
      yield put({
        type: SET_DISPLAY_PANE_THREE_STATE,
        payload: {
          headerOne: 'associate',
          headerOneBadgeOne: popupHeaderOneBadgeOne || 'distinct',
          headerOneBadgeTwo: 'information',
          headerOneBadgeThree: data.payload.secondaryOptionCheckValue,
          responseObject: userResponse.responseObject[0],
          reviewMode: isReviseMode ? 'revise' : '',
          createMode: data.payload.createMode
        }
      });
      if (userResponse.responseObject[0].id === data.payload.reqBody.associateId) {
        Store.getState().DisplayPaneTwoReducer.selectedAssociateInfo.associate.informationBasic.associateName =
          userResponse.responseObject[0].informationBasic.associateName;
        Store.getState().DisplayPaneTwoReducer.selectedAssociateInfo.associate.informationBasic.associatePicture =
          userResponse.responseObject[0].informationBasic.associatePicture;
        Store.getState().DisplayPaneTwoReducer.selectedAssociateInfo.associate.informationBasic.associateDescription =
          userResponse.responseObject[0].informationBasic.associateDescription;
      }
      const { informationBasic, informationContact, informationAllocation, informationFramework } =
        userResponse.responseObject[0];
      yield put({
        type: UPDATE_ASSOCIATE_BASIC_INFO,
        payload: informationBasic
      });
      yield put({
        type: UPDATE_ASSOCIATE_INFO_CONTACT_INFO,
        payload: informationContact
      });
      yield put({
        type: UPDATE_ASSOCIATE_WORKTELEPHONE_INFO,
        payload: informationContact?.associateTelephoneWorkPrimary
      });
      yield put({
        type: UPDATE_ASSOCIATE_WORKTELEPHONE_SECONDARY_INFO,
        payload: informationContact?.associateTelephoneWorkSecondary
      });

      let groupPrimary =
        informationAllocation?.associateGroup?.associateGroupPrimary.map((ob) => ob?.id) || [];
      yield put({
        type: SET_ASSOCIATE_DYNAMIC_SINGLE_STATE,
        payload: {
          stateName: 'associateGroup',
          actualStateName: 'associateGroupPrimary',
          value: groupPrimary
        }
      });
      let grpSecond =
        informationAllocation?.associateGroup?.associateGroupSecondary.map((ob) => ob.id) || [];
      yield put({
        type: SET_ASSOCIATE_DYNAMIC_SINGLE_STATE,
        payload: {
          stateName: 'associateGroup',
          actualStateName: 'associateGroupSecondary',
          value: grpSecond
        }
      });
      let ndpri =
        informationAllocation?.associateNode?.associateNodePrimary.map((ob) => ob.id) || [];
      yield put({
        type: SET_ASSOCIATE_DYNAMIC_SINGLE_STATE,
        payload: {
          stateName: 'associateNode',
          actualStateName: 'associateNodePrimary',
          value: ndpri
        }
      });
      let ndSecond =
        informationAllocation?.associateNode?.associateNodeSecondary.map((ob) => ob.id) || [];
      yield put({
        type: SET_ASSOCIATE_DYNAMIC_SINGLE_STATE,
        payload: {
          stateName: 'associateNode',
          actualStateName: 'associateNodeSecondary',
          value: ndSecond
        }
      });
      let tyPri =
        informationAllocation?.associateType?.associateTypePrimary?.map((ob) => ob.id) || [];
      yield put({
        type: SET_ASSOCIATE_DYNAMIC_SINGLE_STATE,
        payload: {
          stateName: 'associateType',
          actualStateName: 'associateTypePrimary',
          value: tyPri
        }
      });
      let typeSecond =
        informationAllocation?.associateType?.associateTypeSecondary?.map((ob) => ob.id) || [];
      yield put({
        type: SET_ASSOCIATE_DYNAMIC_SINGLE_STATE,
        payload: {
          stateName: 'associateType',
          actualStateName: 'associateTypeSecondary',
          value: typeSecond
        }
      });
      let rolePri =
        informationAllocation?.associateRole?.associateRolePrimary?.map((ob) => ob.id) || [];
      yield put({
        type: SET_ASSOCIATE_DYNAMIC_SINGLE_STATE,
        payload: {
          stateName: 'associateRole',
          actualStateName: 'associateRolePrimary',
          value: rolePri
        }
      });
      let roleSecond =
        informationAllocation?.associateRole?.associateRoleSecondary.map((ob) => ob.id) || [];
      yield put({
        type: SET_ASSOCIATE_DYNAMIC_SINGLE_STATE,
        payload: {
          stateName: 'associateRole',
          actualStateName: 'associateRoleSecondary',
          value: roleSecond
        }
      });
      //end allocation obj
      let ascendantPrimaryList = [];
      if (informationFramework?.associateAscendant?.associateAscendantPrimary) {
        let ascendantPrimaryId =
          informationFramework?.associateAscendant?.associateAscendantPrimary[0]?.id || '';
        ascendantPrimaryList.push(ascendantPrimaryId);
      }
      yield put({
        type: SET_IGURU_NODE_DYNAMIC_SINGLE_STATE,
        payload: {
          objectName: 'informationFramework',
          stateName: 'associateAscendant',
          actualStateName: 'associateAscendantPrimary',
          value: ascendantPrimaryList
        }
      });
      yield put({ type: LOADER_STOP });
    } else {
      console.log('responseErrorMsg11');
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: userResponse.responseMessage,
          popupMode: 'responseErrorMsg'
        }
      });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
    yield put({ type: LOADER_STOP });
  }
}

const associateReviseInfoApi = async (requestObj) => {
  let URL = ASSOCIATE_MODULE_URL;
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      Authorization: localStorage.getItem('token')
    }),
    body: JSON.stringify(requestObj.data)
  };
  const response = await fetch(URL, requestOptions);
  const json = await response.json();
  return json;
};

function* workerReviseInfoAssociateSaga(data) {
  try {
    const response = yield call(associateReviseInfoApi, {
      data: data.payload.reqBody
    });
    if (response.responseCode === '000') {
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isSelectActive', value: false }
      });
      if (data.payload.secondaryOptionCheckValue !== '') {
        yield put({
          type: SET_DISPLAY_PANE_THREE_STATE,
          payload: {
            headerOne: data.payload.headerOne,
            headerOneBadgeOne: data.payload.headerOneBadgeOne || 'distinct',
            headerOneBadgeThree: data.payload.headerOneBadgeThree,
            headerOneBadgeTwo: 'information',
            responseObject: response.responseObject[0],
            createMode: data.payload.createMode
          }
        });
        yield put({
          type: SET_DISPLAY_PANE_THREE_REVIEW_MODE,
          payload: 'review'
        });
      }
      if (
        Store.getState().PopUpReducer.cardValue === 'NoCard' ||
        Store.getState().PopUpReducer.cardValue === 'noCard'
      ) {
        // setTimeout(() => {
        yield put({
          type: SET_PAGE_COUNT,
          payload: 0
        });
        // }, 1000);
        if (data.payload.typeOfMiddlePaneList === 'associatesGroupAssociateReviewList') {
          console.log('data.payload>>>', data.payload);
          yield put({
            type: GET_ASSOCIATEGROUP_ASSOCIATE_REVIEW_LIST_SAGA,
            payload: {
              HeaderOne: 'associates',
              request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        } else if (data.payload.typeOfMiddlePaneList === 'associatesTypeAssociateReviewList') {
          console.log('data.payload>>>', data.payload);
          yield put({
            type: GET_ASSOCIATETYPE_ASSOCIATE_REVIEW_LIST_SAGA,
            payload: {
              HeaderOne: 'associates',
              request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        } else if (data.payload.typeOfMiddlePaneList === 'associatesRoleAssociateReviewList') {
          console.log('data.payload>>>', data.payload);
          yield put({
            type: GET_ASSOCIATEROLE_ASSOCIATE_REVIEW_LIST_SAGA,
            payload: {
              HeaderOne: 'associates',
              request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        } else {
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          yield put({
            type: ASSOCIATE_REVIEW_DISTINCT_SAGA,
            payload: {
              HeaderOne: 'associates',
              request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              typeOfMiddlePaneList:
                data.payload.typeOfMiddlePaneList ||
                Store.getState().DisplayPaneTwoReducer.typeOfMiddlePaneList,
              isMiddlePaneList: true
            }
          });
        }
      }

      yield put({ type: LOADER_STOP });
    } else if (response.responseCode === '104') {
      //You cant revise associate role because associate has descendant
      let primaryRoleList = Store.getState().DisplayPaneThreeReducer.responseObject.informationAllocation.associateRole.associateRolePrimary.map(item => item.id)
      let secondaryRoleList = Store.getState().DisplayPaneThreeReducer.responseObject.informationAllocation.associateRole.associateRoleSecondary.map(item => item.id)
      yield put({
        type: SET_ASSOCIATE_DYNAMIC_SINGLE_STATE,
        payload: {
          stateName: 'associateRole',
          actualStateName: 'associateRolePrimary',
          value: primaryRoleList
        }
      });
      yield put({
        type: SET_ASSOCIATE_DYNAMIC_SINGLE_STATE,
        payload: {
          stateName: 'associateRole',
          actualStateName: 'associateRoleSecondary',
          value: secondaryRoleList
        }
      });
      if (
        Store.getState().PopUpReducer.cardValue === 'NoCard' ||
        Store.getState().PopUpReducer.cardValue === 'noCard'
      ) {
        yield put({
          type: SET_PAGE_COUNT,
          payload: 0
        });
        if (data.payload.typeOfMiddlePaneList === 'associatesGroupAssociateReviewList') {
          console.log('data.payload>>>', data.payload);
          yield put({
            type: GET_ASSOCIATEGROUP_ASSOCIATE_REVIEW_LIST_SAGA,
            payload: {
              HeaderOne: 'associates',
              request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        } else if (data.payload.typeOfMiddlePaneList === 'associatesTypeAssociateReviewList') {
          console.log('data.payload>>>', data.payload);
          yield put({
            type: GET_ASSOCIATETYPE_ASSOCIATE_REVIEW_LIST_SAGA,
            payload: {
              HeaderOne: 'associates',
              request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        } else if (data.payload.typeOfMiddlePaneList === 'associatesRoleAssociateReviewList') {
          console.log('data.payload>>>', data.payload);
          yield put({
            type: GET_ASSOCIATEROLE_ASSOCIATE_REVIEW_LIST_SAGA,
            payload: {
              HeaderOne: 'associates',
              request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        } else {
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          yield put({
            type: ASSOCIATE_REVIEW_DISTINCT_SAGA,
            payload: {
              HeaderOne: 'associates',
              request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              typeOfMiddlePaneList:
                data.payload.typeOfMiddlePaneList ||
                Store.getState().DisplayPaneTwoReducer.typeOfMiddlePaneList,
              isMiddlePaneList: true
            }
          });
        }
      }
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: response?.responseMessage,
          popupMode: 'responseErrorMsg'
        }
      });
      yield put({ type: LOADER_STOP });
    } else {
      if (
        Store.getState().PopUpReducer.cardValue === 'NoCard' ||
        Store.getState().PopUpReducer.cardValue === 'noCard'
      ) {
        // setTimeout(() => {
        yield put({
          type: SET_PAGE_COUNT,
          payload: 0
        });
        // }, 1000);
        if (data.payload.typeOfMiddlePaneList === 'associatesGroupAssociateReviewList') {
          console.log('data.payload>>>', data.payload);
          yield put({
            type: GET_ASSOCIATEGROUP_ASSOCIATE_REVIEW_LIST_SAGA,
            payload: {
              HeaderOne: 'associates',
              request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        } else if (data.payload.typeOfMiddlePaneList === 'associatesTypeAssociateReviewList') {
          console.log('data.payload>>>', data.payload);
          yield put({
            type: GET_ASSOCIATETYPE_ASSOCIATE_REVIEW_LIST_SAGA,
            payload: {
              HeaderOne: 'associates',
              request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        } else if (data.payload.typeOfMiddlePaneList === 'associatesRoleAssociateReviewList') {
          console.log('data.payload>>>', data.payload);
          yield put({
            type: GET_ASSOCIATEROLE_ASSOCIATE_REVIEW_LIST_SAGA,
            payload: {
              HeaderOne: 'associates',
              request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        } else {
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          yield put({
            type: ASSOCIATE_REVIEW_DISTINCT_SAGA,
            payload: {
              HeaderOne: 'associates',
              request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              typeOfMiddlePaneList:
                data.payload.typeOfMiddlePaneList ||
                Store.getState().DisplayPaneTwoReducer.typeOfMiddlePaneList,
              isMiddlePaneList: true
            }
          });
        }
      }
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: response?.responseMessage,
          popupMode: 'responseErrorMsg'
        }
      });
      yield put({ type: LOADER_STOP });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerAssociateSetUpSaga(data) {
  try {
    const response = yield call(associateReviewInfoApi, {
      data: data.payload.reqBody,
      URL: data.payload.reqUrl
    });
    if (response.responseCode === '000') {
      if (response.responseObject.length === 1) {
        yield put({
          type: SET_DISPLAY_THREE_SINGLE_STATE,
          payload: {
            stateName: 'setUpAssociateModule',
            value: response.responseObject[0].Bespoke
          }
        });
      }
      if (response.responseObject.length === 2) {
        yield put({
          type: SET_DISPLAY_THREE_SINGLE_STATE,
          payload: {
            stateName: 'setUpAssociateModule',
            value: response.responseObject[0].Bespoke
          }
        });
        yield put({
          type: SET_DISPLAY_THREE_SINGLE_STATE,
          payload: {
            stateName: 'setUpAssociateModuleGeneric',
            value: response.responseObject[1].Generic
          }
        });
      }

      // yield put({
      //   type: UPDATE_ASSOCIATE_SETUP_INFO,
      //   payload: response.responseObject[0]
      // });
    } else {
    }
  } catch (e) {
    console.log('responseErrorMsg14');
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
  }
}
function* workerAssociateNodeSetUpSaga(data) {
  try {
    const response = yield call(associateReviewInfoApi, {
      data: data.payload.reqBody,
      URL: data.payload.reqUrl
    });
    if (response.responseCode === '000') {
      yield put({
        type: SET_DISPLAY_THREE_SINGLE_STATE,
        payload: {
          stateName: 'associateNodeSetUpModule',
          value: response.responseObject[0]
        }
      });
      // yield put({
      //   type: UPDATE_ASSOCIATE_SETUP_INFO,
      //   payload: response.responseObject[0]
      // });
    } else {
    }
  } catch (e) {
    console.log('responseErrorMsg15');
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
  }
}
function* workerAssociateAnalyticSetUpSaga(data) {
  try {
    const { isIdToken = false } = data.payload;
    const response = yield call(associateReviewInfoApi, {
      data: data.payload.reqBody,
      URL: data.payload.reqUrl,
      isIdToken
    });
    if (response.responseCode === '000') {
      yield put({
        type: SET_DISPLAY_THREE_SINGLE_STATE,
        payload: {
          stateName: 'analyticSetUpModule',
          value: response.responseObject.Bespoke
        }
      });

      yield put({
        type: SET_DISPLAY_THREE_SINGLE_STATE,
        payload: {
          stateName: 'analyticSetUpModuleGeneric',
          value: response.responseObject.Generic
        }
      });
    } else {
    }
  } catch (e) {
    console.log('responseErrorMsg16');
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
  }
}
function* workerAssociateItemSetUpSaga(data) {
  try {
    const response = yield call(associateReviewInfoApi, {
      data: data.payload.reqBody,
      URL: data.payload.reqUrl
    });
    if (response.responseCode === '000') {
      yield put({
        type: SET_DISPLAY_THREE_SINGLE_STATE,
        //payload: { stateName: 'itemSetUpModule', value: response.responseObject }
        payload: {
          stateName: 'itemSetUpModule',
          value: response.responseObject.Bespoke
        }
      });

      yield put({
        type: SET_DISPLAY_THREE_SINGLE_STATE,
        payload: {
          stateName: 'itemSetUpModuleGeneric',
          value: response.responseObject.Generic
        }
      });
    } else {
    }
  } catch (e) {
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
  }
}
function* workerAssociateAssessmentSetUpSaga(data) {
  try {
    const response = yield call(associateReviewInfoApi, {
      data: data.payload.reqBody,
      URL: data.payload.reqUrl
    });
    if (response.responseCode === '000') {
      yield put({
        type: SET_DISPLAY_THREE_SINGLE_STATE,
        payload: {
          stateName: 'assessmentSetUpModule',
          value: response.responseObject.Bespoke
        }
      });
      yield put({
        type: SET_DISPLAY_THREE_SINGLE_STATE,
        payload: {
          stateName: 'assessmentSetUpModuleBespoke',
          value: response.responseObject.Bespoke
        }
      });

      yield put({
        type: SET_DISPLAY_THREE_SINGLE_STATE,
        payload: {
          stateName: 'assessmentSetUpModuleGeneric',
          value: response.responseObject.Generic
        }
      });
      // yield put({
      //   type: UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO,
      //   payload: response.responseObject[0]
      // });
    } else {
    }
  } catch (e) {
    console.log('responseErrorMsg18');
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
  }
}
function* workerAssociateAssesseeSetUpSaga(data) {
  try {
    const response = yield call(associateReviewInfoApi, {
      data: data.payload.reqBody,
      URL: data.payload.reqUrl
    });
    if (response.responseCode === '000') {
      if (response.responseObject.length === 1) {
        yield put({
          type: SET_DISPLAY_THREE_SINGLE_STATE,
          payload: {
            stateName: 'assesseeSetUpModule',
            value: response.responseObject[0].Bespoke
          }
        });
      }
      if (response.responseObject.length === 2) {
        yield put({
          type: SET_DISPLAY_THREE_SINGLE_STATE,
          payload: {
            stateName: 'assesseeSetUpModule',
            value: response.responseObject[0].Bespoke
          }
        });
        yield put({
          type: SET_DISPLAY_THREE_SINGLE_STATE,
          payload: {
            stateName: 'assesseeSetUpModuleGeneric',
            value: response.responseObject[1].Generic
          }
        });
      }
      // yield put({
      //   type: UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO,
      //   payload: response.responseObject[0]
      // });
    } else {
    }
  } catch (e) {
    console.log('responseErrorMsg19');
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
  }
}
function* workerAssociateAssesseeSignOnSetUpSaga(data) {
  try {
    const responseAssociate = yield call(associateReviewInfoApi, {
      data: data.payload.reqBodyAssociate,
      URL: ASSOCIATE_MODULE_URL
    });
    if (responseAssociate.responseCode === '000') {
      const responseAssessee = yield call(associateReviewInfoApi, {
        data: data.payload.reqBodyAssessee,
        URL: ASSESSEE_MODULE_URL
      });
      console.log('responseAssociate', responseAssociate);
      console.log('responseAssessee', responseAssessee);
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: {
          stateName: 'signOnAssociateSetupPermission',
          value: responseAssociate.responseObject[0][0]
        }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: {
          stateName: 'signOnAssesseeSetupPermission',
          value: responseAssessee.responseObject[0][0]
        }
      });
    } else {
    }
  } catch (e) {
    console.log('responseErrorMsg19');
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
  }
}
function* workerAssociateAssignmentSetUpSaga(data) {
  try {
    const response = yield call(associateReviewInfoApi, {
      data: data.payload.reqBody,
      URL: data.payload.reqUrl
    });
    if (response.responseCode === '000') {
      yield put({
        type: SET_DISPLAY_THREE_SINGLE_STATE,
        //payload: { stateName: 'assignmentSetUpModule', value: response.responseObject }
        payload: {
          stateName: 'assignmentSetUpModule',
          value: response.responseObject.Bespoke ? response.responseObject.Bespoke : {}
        }
      });

      yield put({
        type: SET_DISPLAY_THREE_SINGLE_STATE,
        payload: {
          stateName: 'assignmentSetUpModuleGeneric',
          value: response.responseObject.Generic ? response.responseObject.Generic : {}
        }
      });

      // yield put({
      //   type: UPDATE_ASSOCIATE_SETUP_ASSIGNMENT_INFO,
      //   payload: response.responseObject
      // });
    } else {
    }
  } catch (e) {
    console.log('responseErrorMsg20');
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
  }
}
function* workerAssociateItemSetUpReviseSaga(data) {
  try {
    const response = yield call(associateReviewInfoApi, {
      data: data.payload.reqBody,
      URL: ITEM_MODULE_URL
    });
    if (response.responseCode === '000') {
      const { reviseModule = '' } = data.payload;
      if (reviseModule === 'bespoke') {
        console.log('in module', reviseModule);
        yield put({
          type: SET_DISPLAY_THREE_SINGLE_STATE,
          payload: {
            stateName: 'itemSetUpModule',
            value: response.responseObject
          }
        });
        yield put({
          type: UPDATE_ASSOCIATE_SETUP_ITEM_INFO,
          payload: response.responseObject
        });
      }
      if (reviseModule === 'generic') {
        console.log('in module', reviseModule);
        yield put({
          type: SET_DISPLAY_THREE_SINGLE_STATE,
          payload: {
            stateName: 'itemSetUpModuleGeneric',
            value: response.responseObject
          }
        });
        yield put({
          type: UPDATE_ASSOCIATE_SETUPPLUS_ITEM_INFO,
          payload: response.responseObject
        });
      }
    } else {
      console.log('responseErrorMsg2');
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: response.responseMessage,
          popupMode: 'responseErrorMsg'
        }
      });
    }
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('responseErrorMsg21');
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
  }
}
function* workerAssociateAnalyticSetUpReviseSaga(data) {
  try {
    const response = yield call(associateReviewInfoApi, {
      data: data.payload.reqBody,
      URL: JOB_MODULE_URL,
      isIdToken: true
    });
    if (response.responseCode === '000') {
      const { reviseModule = '' } = data.payload;

      if (reviseModule === 'bespoke') {
        console.log('in module', reviseModule);
        yield put({
          type: SET_DISPLAY_THREE_SINGLE_STATE,
          payload: {
            stateName: 'analyticSetUpModule',
            value: response.responseObject
          }
        });
        yield put({
          type: UPDATE_ASSOCIATE_SETUP_ANALYTIC_INFO,
          payload: response.responseObject
        });
      }
      if (reviseModule === 'generic') {
        console.log('in module', reviseModule);
        yield put({
          type: SET_DISPLAY_THREE_SINGLE_STATE,
          payload: {
            stateName: 'analyticSetUpModuleGeneric',
            value: response.responseObject
          }
        });
        yield put({
          type: UPDATE_ASSOCIATE_SETUPPLUS_ANALYTIC_INFO,
          payload: response.responseObject
        });
      }
    } else {
      console.log('responseErrorMsg3');
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: response.responseMessage,
          popupMode: 'responseErrorMsg'
        }
      });
    }
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('responseErrorMsg22');
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
  }
}
function* workerAssociateNodeSetUpReviseSaga(data) {
  try {
    const response = yield call(associateReviewInfoApi, {
      data: data.payload.reqBody,
      URL: ASSOCIATE_MODULE_URL
    });
    if (response.responseCode === '000') {
      yield put({
        type: SET_DISPLAY_THREE_SINGLE_STATE,
        payload: {
          stateName: 'associateNodeSetUpModule',
          value: response.responseObject[0]
        }
      });
    } else {
      console.log('responseErrorMsg4');
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: response.responseMessage,
          popupMode: 'responseErrorMsg'
        }
      });
    }
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('responseErrorMsg23');
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
  }
}
function* workerAssociateAssignmentSetUpReviseSaga(data) {
  try {
    const response = yield call(associateReviewInfoApi, {
      data: data.payload.reqBody,
      URL: ASSIGNMENT_MODULE_URL
    });
    if (response.responseCode === '000') {
      const { reviseModule = '' } = data.payload;

      if (reviseModule === 'bespoke') {
        console.log('in module', reviseModule);
        // console.log('b------',Store.getState().DisplayPaneThreeReducer.assignmentSetUpModule);
        yield put({
          type: SET_DISPLAY_THREE_SINGLE_STATE,
          payload: {
            stateName: 'assignmentSetUpModule',
            value: response.responseObject
          }
        });
        yield put({
          type: UPDATE_ASSOCIATE_SETUP_ASSIGNMENT_INFO,
          payload: response.responseObject
        });
        // console.log('a------', Store.getState().DisplayPaneThreeReducer.assignmentSetUpModule);
      }
      if (reviseModule === 'generic') {
        console.log('in module', reviseModule);
        yield put({
          type: SET_DISPLAY_THREE_SINGLE_STATE,
          payload: {
            stateName: 'assignmentSetUpModuleGeneric',
            value: response.responseObject
          }
        });
        yield put({
          type: UPDATE_ASSOCIATE_SETUPPLUS_ASSIGNMENT_INFO,
          payload: response.responseObject
        });
      }
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: response.responseMessage,
          popupMode: 'responseErrorMsg'
        }
      });
    }
    yield put({ type: LOADER_STOP });
  } catch (e) {
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
  }
}
function* workerAssociateAssessmentSetUpReviseSaga(data) {
  try {
    const response = yield call(associateReviewInfoApi, {
      data: data.payload.reqBody,
      URL: ASSESSMENT_MODULE_URL
    });
    if (response.responseCode === '000') {
      const { reviseModule = '' } = data.payload;
      if (reviseModule === 'bespoke') {
        console.log('in module', reviseModule);
        // console.log('b------',Store.getState().DisplayPaneThreeReducer.assessmentSetUpModule);
        yield put({
          type: UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO,
          payload: response.responseObject
        });
        yield put({
          type: SET_DISPLAY_THREE_SINGLE_STATE,
          payload: {
            stateName: 'assessmentSetUpModule',
            value: response.responseObject
          }
        });
        yield put({
          type: SET_DISPLAY_THREE_SINGLE_STATE,
          payload: {
            stateName: 'assessmentSetUpModuleBespoke',
            value: response.responseObject
          }
        });
        // console.log('b------',Store.getState().DisplayPaneThreeReducer.assessmentSetUpModule);
      }
      if (reviseModule === 'generic') {
        console.log('in module', reviseModule);
        // console.log('b------',Store.getState().DisplayPaneThreeReducer.assessmentSetUpModuleGeneric);
        yield put({
          type: SET_DISPLAY_THREE_SINGLE_STATE,
          payload: {
            stateName: 'assessmentSetUpModuleGeneric',
            value: response.responseObject
          }
        });
        yield put({
          type: UPDATE_ASSOCIATE_SETUPPLUS_ASSESSMENT_INFO,
          payload: response.responseObject
        });
        // console.log('a------',Store.getState().DisplayPaneThreeReducer.assessmentSetUpModuleGeneric);
      }
    } else {
      console.log('responseErrorMsg6');
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: response.responseMessage,
          popupMode: 'responseErrorMsg'
        }
      });
    }
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('responseErrorMsg25');
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
  }
}
function* workerAssociateAssociateSetUpReviseSaga(data) {
  try {
    const response = yield call(associateReviewInfoApi, {
      data: data.payload.reqBody,
      URL: ASSOCIATE_MODULE_URL
    });
    if (response.responseCode === '000') {
      const { reviseModule = '' } = data.payload;
      if (reviseModule === 'bespoke') {
        console.log('in module', reviseModule);
        yield put({
          type: SET_DISPLAY_THREE_SINGLE_STATE,
          payload: {
            stateName: 'setUpAssociateModule',
            value: response.responseObject[0]
          }
        });
        yield put({
          type: UPDATE_ASSOCIATE_SETUP_INFO,
          payload: response.responseObject[0]
        });
      }
      if (reviseModule === 'generic') {
        console.log('in module', reviseModule);
        yield put({
          type: SET_DISPLAY_THREE_SINGLE_STATE,
          payload: {
            stateName: 'setUpAssociateModuleGeneric',
            value: response.responseObject[0]
          }
        });
        yield put({
          type: UPDATE_ASSOCIATE_SETUPPLUS_INFO,
          payload: response.responseObject[0]
        });
      }
    } else {
      console.log('responseErrorMsg8');
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: response.responseMessage,
          popupMode: 'responseErrorMsg'
        }
      });
    }
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('responseErrorMsg26');
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
  }
}
function* workerAssociateAssesseeSetUpReviseSaga(data) {
  try {
    const response = yield call(associateReviewInfoApi, {
      data: data.payload.reqBody,
      URL: ASSESSEE_MODULE_URL
    });
    if (response.responseCode === '000') {
      const { reviseModule = '' } = data.payload;
      if (reviseModule === 'bespoke') {
        console.log('in module', reviseModule);
        yield put({
          type: SET_DISPLAY_THREE_SINGLE_STATE,
          payload: {
            stateName: 'assesseeSetUpModule',
            value: response.responseObject[0]
          }
        });
        yield put({
          type: UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO,
          payload: response.responseObject[0]
        });
      }
      if (reviseModule === 'generic') {
        console.log('in module', reviseModule);
        yield put({
          type: SET_DISPLAY_THREE_SINGLE_STATE,
          payload: {
            stateName: 'assesseeSetUpModuleGeneric',
            value: response.responseObject[0]
          }
        });
        yield put({
          type: UPDATE_ASSOCIATE_SETUPPLUS_ASSESSEE_INFO,
          payload: response.responseObject[0]
        });
      }
      //yield put({ type: LOADER_STOP });
    } else {
      console.log('responseErrorMsg9');
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: response.responseMessage,
          popupMode: 'responseErrorMsg'
        }
      });
    }
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('responseErrorMsg27');
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
  }
}
function* workerReviseMultiStatusAssociateSaga(data) {
  try {
    const userResponse = yield call(associateReviewInfoApi, {
      data: data.payload.reqBody,
      URL: ASSOCIATE_MODULE_URL
    });
    if (userResponse.responseCode === '000') {
      yield put({ type: SET_PAGE_COUNT, payload: 0 });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'relatedReviewListDistinctData', value: [] }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'typeOfMiddlePaneList', value: '' }
      });
      yield put({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'selectionValue', value: '' }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isSelectActive', value: '' }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'selectedTagsArray', value: [] }
      });
      if (data.payload.createMode === '') {
        if (data.payload.typeOfMiddlePaneList === 'associatesGroupDistinctReviewList') {
          yield put({ type: LOADER_START });
          yield {
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          };
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
          yield put({
            type: GET_ASSOCIATE_GROUP_REVIEW_LIST_SAGA,
            payload: {
              HeaderOne: 'associates',
              request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        } else if (data.payload.typeOfMiddlePaneList === 'associatesTypeDistinctReviewList') {
          yield put({ type: LOADER_START });
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
          yield put({
            type: GET_ASSOCIATE_TYPE_REVIEW_LIST_SAGA,
            payload: {
              HeaderOne: 'associates',
              request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        } else if (data.payload.typeOfMiddlePaneList === 'associatesGroupAssociateReviewList') {
          yield put({
            type: GET_ASSOCIATEGROUP_ASSOCIATE_REVIEW_LIST_SAGA,
            payload: {
              HeaderOne: 'associates',
              request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        } else if (data.payload.typeOfMiddlePaneList === 'associateRoleDistinctReviewList') {
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
          yield put({
            type: GET_ASSOCIATE_ROLE_REVIEW_LIST_SAGA,
            payload: {
              HeaderOne: 'associates',
              request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        } else if (data.payload.typeOfMiddlePaneList === 'associateNodeDistinctReviewList') {
          yield put({
            type: INTERNAL_NODE_LIST_SAGA,
            payload: {
              paneHeader: Store.getState().DisplayPaneTwoReducer.middlePaneHeader,
              request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              isMiddlePaneList: true,
              nodeViewState: Store.getState().DisplayPaneTwoReducer.nodeViewState,
              scanString: '',
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
            }
          });
        } else {
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
          yield put({
            type:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo === 'active'
                ? GET_ASSOCIATES_NODE_REVIEW_LIST_SAGA
                : ASSOCIATE_REVIEW_DISTINCT_SAGA,
            payload: {
              HeaderOne: 'associates',
              request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
              nodeViewState: Store.getState().DisplayPaneTwoReducer.nodeViewState,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        }
      } else yield put({ type: LOADER_STOP });
    } else {
      console.log('responseErrorMsg10');
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: userResponse.responseMessage,
          popupMode: 'responseErrorMsg'
        }
      });
    }
  } catch (e) {
    console.log('responseErrorMsg28');
    yield put({ type: LOADER_STOP });
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
  }
}
function* workerAssociateSetupResetSaga(data) {
  try {
    let URL = '';
    if (data.payload.reviseModule === 'assessees') URL = ASSESSEE_MODULE_URL;
    if (data.payload.reviseModule === 'assessments') URL = ASSESSMENT_MODULE_URL;
    if (data.payload.reviseModule === 'assignments') URL = ASSIGNMENT_MODULE_URL;
    if (data.payload.reviseModule === 'associates') URL = ASSOCIATE_MODULE_URL;
    if (data.payload.reviseModule === 'analytics') URL = JOB_MODULE_URL;
    if (data.payload.reviseModule === 'items') URL = ITEM_MODULE_URL;
    const userResponse = yield call(associateReviewInfoApi, {
      data: data.payload.request,
      URL: URL,
      isIdToken: data.payload.reviseModule === 'analytics'
    });
    if (userResponse.responseCode === '000') {
      if (data.payload.reviseModule === 'assessees') {
        yield put({
          type: UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO,// update reducer for revise finish changes 
          payload: userResponse.responseObject[0]
        });
        yield put({
          type: SET_DISPLAY_THREE_SINGLE_STATE,
          payload: { stateName: 'assesseeSetUpModule', value: userResponse.responseObject[0] }
        });
      }
      if (data.payload.reviseModule === 'assessments') {
        yield put({
          type: SET_DISPLAY_THREE_SINGLE_STATE,
          payload: { stateName: 'assessmentSetUpModule', value: userResponse.responseObject }
        });
        yield put({
          type: UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO,
          payload: userResponse.responseObject
        });
        yield put({
          type: SET_DISPLAY_THREE_SINGLE_STATE,
          payload: {
            stateName: 'assessmentSetUpModuleBespoke',
            value: userResponse.responseObject
          }
        });
        yield put({
          type: SET_DISPLAY_THREE_SINGLE_STATE,
          payload: {
            stateName: 'assessmentSetUpModuleGeneric',
            value: userResponse.responseObject
          }
        });
      }
      if (data.payload.reviseModule === 'assignments') {
        yield put({
          type: UPDATE_ASSOCIATE_SETUP_ASSIGNMENT_INFO,
          payload: userResponse.responseObject
        });
        yield put({
          type: SET_DISPLAY_THREE_SINGLE_STATE,
          payload: { stateName: 'assignmentSetUpModule', value: userResponse.responseObject }
        });
      }
      if (data.payload.reviseModule === 'associates') {
        yield put({
          type: UPDATE_ASSOCIATE_SETUP_INFO,
          payload: userResponse.responseObject[0]
        });
        yield put({
          type: SET_DISPLAY_THREE_SINGLE_STATE,
          payload: { stateName: 'setUpAssociateModule', value: userResponse.responseObject[0] }
        });
      }
      if (data.payload.reviseModule === 'analytics') {
        yield put({
          type: UPDATE_ASSOCIATE_SETUP_ANALYTIC_INFO,
          payload: userResponse.responseObject
        });
        yield put({
          type: SET_DISPLAY_THREE_SINGLE_STATE,
          payload: { stateName: 'analyticSetUpModule', value: userResponse.responseObject }
        });
      }
      if (data.payload.reviseModule === 'items') {
        yield put({
          type: UPDATE_ASSOCIATE_SETUP_ITEM_INFO,
          payload: userResponse.responseObject
        });
        yield put({
          type: SET_DISPLAY_THREE_SINGLE_STATE,
          payload: { stateName: 'itemSetUpModule', value: userResponse.responseObject }
        });
      }
    } else {
      console.log('responseErrorMsg11');
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: userResponse.responseMessage,
          popupMode: 'responseErrorMsg'
        }
      });
    }
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('responseErrorMsg12');
    yield put({ type: LOADER_STOP });
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
  }
}
function* workerAssociateExchangeSaga(data) {
  try {
    const userResponse = yield call(associateReviewInfoApi, {
      data: data.payload.reqBody,
      URL: ASSESSMENT_MODULE_URL
    });
    if (userResponse.responseCode === '000') {
      yield put({ type: LOADER_STOP });
    } else {
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: userResponse.responseMessage,
          popupMode: 'responseErrorMsg'
        }
      });
    }
  } catch (e) {
    console.log('responseErrorMsg13');
    yield put({ type: LOADER_STOP });
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
  }
}

function* workerAssociateAssessmentList(data) {
  try {
    const responseAssignments = yield call(associateReviewInfoApi, {
      data: data.payload.assignmentList,
      URL: data.payload.url
    });
    if (responseAssignments.responseCode === '000') {
      yield put({ type: REVIEWLIST_DISTINCT_DATA, payload: responseAssignments.responseObject });
      yield put({
        type: SET_MIDDLEPANE_STATE,
        payload: {
          middlePaneHeader: 'assignments',
          middlePaneHeaderBadgeOne: 'distinct',
          middlePaneHeaderBadgeTwo: 'mine',
          middlePaneHeaderBadgeThree: '',
          middlePaneHeaderBadgeFour: '',
          typeOfMiddlePaneList: 'associateMineAssignmentDistinctReviewList',
          scanCount: responseAssignments.countTotal,
          duplicateScanCount: responseAssignments.countTotal,
          showMiddlePaneState: true,
          isSelectActive: Store.getState().DisplayPaneTwoReducer.isSelectActive,
          isListLoading: false
          // reviewListDistinctData: responseAssignments.responseObject,
          // scanCount:responseAssignments.countTotal
        }
      });

      yield put({ type: LOADER_STOP });
    } else {
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: EXCEPTION_ERROR_MESSAGE,
          popupMode: 'responseErrorMsg'
        }
      });
    }
  } catch (e) {
    console.log(e, 'workerAssociateAssessmentList');
    yield put({ type: LOADER_STOP });
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
  }
}

export default function* watchReviewInfoAssociateSaga() {
  yield takeLatest(GET_ASSOCIATE_INFO_SAGA, workerReviewInfoAssociateSaga);
  yield takeLatest(ASSOCIATE_INFO_REVISE_SAGA, workerReviseInfoAssociateSaga);
  yield takeLatest(ASSOCIATE_ASSESSESS_SETUP_SAGA, workerAssociateAssesseeSetUpSaga);
  yield takeLatest(ASSOCIATE_ASSESSESS_SIGNON_SETUP_SAGA, workerAssociateAssesseeSignOnSetUpSaga);
  yield takeLatest(ASSOCIATE_SIGN_ON_ASSIGNMENT_LIST_SAGA, workerAssociateAssessmentList);
  yield takeLatest(ASSOCIATE_ASSESSMENT_SETUP_SAGA, workerAssociateAssessmentSetUpSaga);
  yield takeLatest(ASSOCIATE_ASSIGNMENT_SETUP_SAGA, workerAssociateAssignmentSetUpSaga);
  yield takeLatest(ASSOCIATE_SETUP_SAGA, workerAssociateSetUpSaga);
  yield takeLatest(ASSOCIATE_NODE_SETUP_SAGA, workerAssociateNodeSetUpSaga);
  yield takeLatest(ASSOCIATE_ANALYTIC_SETUP_SAGA, workerAssociateAnalyticSetUpSaga);
  yield takeLatest(ASSOCIATE_ITEM_SETUP_SAGA, workerAssociateItemSetUpSaga);
  yield takeEvery(ASSOCIATE_ASSESSEESETUP_REVISE_SAGA, workerAssociateAssesseeSetUpReviseSaga);
  yield takeEvery(ASSOCIATE_ASSESSMENTSETUP_REVISE_SAGA, workerAssociateAssessmentSetUpReviseSaga);
  yield takeEvery(ASSOCIATE_ASSIGNMENTSETUP_REVISE_SAGA, workerAssociateAssignmentSetUpReviseSaga);
  yield takeEvery(ASSOCIATE_ASSOCIATESETUP_REVISE_SAGA, workerAssociateAssociateSetUpReviseSaga);
  yield takeEvery(ASSOCIATE_ITEMSETUP_REVISE_SAGA, workerAssociateItemSetUpReviseSaga);
  yield takeEvery(ASSOCIATE_ANALYTICSETUP_REVISE_SAGA, workerAssociateAnalyticSetUpReviseSaga);
  yield takeEvery(ASSOCIATE_ASSOCIATENODE_SETUP_REVISE_SAGA, workerAssociateNodeSetUpReviseSaga);
  yield takeLatest(ASSOCIATE_MULTI_STATUS_REVISE_SAGA, workerReviseMultiStatusAssociateSaga);
  //reset setup
  yield takeLatest(UPDATE_ASSOCIATE_SETUP_RESET_SAGA, workerAssociateSetupResetSaga);
  yield takeLatest(ASSOCIATE_EXCHANGE_REVISE_SAGA, workerAssociateExchangeSaga);
}
