import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import '../Accordian/DisplayPanelAccordian.css';

const FourRowHeader = (props) => {
  const { title, row1 = [], rangeheadcolumnhead2 } = props;
  console.log('Four', props);
  return (
    <div className={'containerPadding'}>
      <Paper
        className={'assesseesContainerGreen'}
        style={{ padding: '0px', backgroundColor: '#F2F2F2' }}
      >
        <div className={'siftComponentInnerDiv'}>
          <div className={'siftHeaderDiv'} style={{ height: '30px' }}>
            <span style={{ color: 'rgba(0, 0, 0, 0.87)' }}>{title}</span>
          </div>
          <div
            className={'sifSecondtHeaderDiv'}
            style={{
              borderTop: '1px solid #BFBFBF',
              display: 'block !important',
              height: '20px'
            }}
          >
            {row1.map((lis, index) => {
              return (
                <span
                  className={'sifSecondHeaderInner'}
                  style={
                    row1.length === index + 6
                      ? {
                          borderRight: '0px',
                          height: '20px',
                          display: 'block !important'
                        }
                      : index === '0'
                      ? {
                          borderLeft: '0px',
                          height: '20px',
                          display: 'block !important'
                        }
                      : { height: '20px' }
                  }
                >
                  <span
                    style={{
                      color: 'rgba(0, 0, 0, 0.54)',
                      fontSize: '1rem',
                      height: '10px',
                      alignItems: 'center',
                      display: 'flex',
                      textAlign: 'center'
                    }}
                  >
                    <span>{lis}</span>
                  </span>
                  {Object.keys(rangeheadcolumnhead2).map((val) => {
                    return val === lis ? (
                      <span
                        style={{
                          color: 'rgba(0, 0, 0, 0.54)',
                          fontSize: '1rem',
                          height: '10px',
                          alignItems: 'center',
                          display: 'flex',
                          textAlign: 'center'
                        }}
                      >
                        <span>{rangeheadcolumnhead2[val]}</span>
                      </span>
                    ) : null;
                  })}
                  {/* {Object.keys(this.props.cls.rangeheadcolumnhead3).map((val)=>{
                                return  val==lis?<span style={{color:'rgba(0, 0, 0, 0.87)',fontSize: '1rem',height: '10px',
                               alignItems:'center',
                               display: 'flex',
                               textAlign: 'center'}}>{this.props.cls.rangeheadcolumnhead3[val].map((data)=>{
                                 return <span>{data}</span>   
                               })}</span>
                               :null
                            })} */}
                </span>
              );
            })}
          </div>
        </div>
      </Paper>
    </div>
    // <div className={'containerPadding'}>
    //   <Paper className={'assesseesContainerGreen'} style={{ padding: '0px' }}>
    //     <div className={'siftComponentInnerDiv'}>
    //       <div className={'siftHeaderDiv'} style={{ height: '20px' }}>
    //         <span style={{ color: 'rgba(0, 0, 0, 0.87)' }}>{title}</span>
    //       </div>
    //       <div
    //         className={'sifSecondtHeaderDiv'}
    //         style={{ borderTop: '1px solid white', display: 'block !important', height: '30px' }}
    //       >
    //         {row1.map((lis, index) => {
    //           return (
    //             <span
    //               className={'sifSecondHeaderInner'}
    //               style={
    //                 row1.length === index + 1
    //                   ? { borderRight: '0px', height: '30px', display: 'block !important' }
    //                   : index === '0'
    //                   ? { borderLeft: '0px', height: '30px', display: 'block !important' }
    //                   : { height: '30px' }
    //               }
    //             >
    //               <span
    //                 style={{
    //                   color: 'rgba(0, 0, 0, 0.87)',
    //                   fontSize: '1rem',
    //                   height: '10px',
    //                   alignItems: 'center',
    //                   display: 'flex',
    //                   textAlign: 'center'
    //                 }}
    //               >
    //                 <span>{lis}</span>
    //               </span>
    //               <span
    //                 style={{
    //                   color: 'rgba(0, 0, 0, 0.87)',
    //                   fontSize: '1rem',
    //                   height: '10px',
    //                   alignItems: 'center',
    //                   display: 'flex',
    //                   textAlign: 'center'
    //                 }}
    //               >
    //                 <span>average</span>
    //               </span>
    //               <span
    //                 style={{
    //                   color: 'rgba(0, 0, 0, 0.87)',
    //                   fontSize: '1rem',
    //                   height: '10px',
    //                   alignItems: 'center',
    //                   display: 'flex',
    //                   textAlign: 'center'
    //                 }}
    //               >
    //                 <span>{index}</span>
    //               </span>
    //             </span>
    //           );
    //         })}
    //       </div>
    //     </div>
    //   </Paper>
    // </div>
  );
};

class JobRangeTableTemplate extends Component {
  constructor() {
    super();
    this.state = {
      radioarray: [],
      radioarraylist: ['6', '7', '8', '9', '10'],
      selectedval: {}
    };
    this.selecttarr = {};
    this.arr = [];
    this.selected = {};
    this.isShowTooltipId = false;
    this.saveselected = this.saveselected.bind(this);
  }
  componentDidMount() {
    var arr = [];
    for (let i = 1; i <= this.props.radiocount; i++) {
      arr.push(i);
    }
    this.setState({
      radioarray: arr
    });
    // this.selecttarr=this.props.cls.weightselectedval;
  }
  saveselected(d, id) {
    this.props.culturedimensionselected.forEach((value) => {
      if (id === value.competencyId) {
        value['weightage'] = d;
      }
    });
    this.props.cls.setTemplateValue(
      'culturedimensionselected',
      this.props.culturedimensionselected
    );
    console.log(this.props.culturedimensionselected);
  }
  render() {
    // var listData = ['1', '2', '3'];
    const { listData = [], saveselected } = this.props;
    console.log('listData', listData);
    return (
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'userCardHeaderContainer'}>
        <FourRowHeader
          rangeheadcolumnhead2={this.props.rangeheadcolumnhead2}
          title={this.props.title}
          row1={this.props.row1}
        />
        {listData.map((value) => {
          return (
            <div className={'containerPadding'}>
              <Paper className={['contentMaindivGray'].join()}>
                <div className={'siftComponentInnerDiv'}>
                  <ClickAwayListener
                    onClickAway={(event) => {
                      // event.stopPropagation();
                      // this.setState({ ...this.state, isShowTooltipId: '' });
                    }}
                  >
                    <Tooltip
                      id="tooltip-icon"
                      onClick={(event) => {
                        // event.stopPropagation();
                        // this.setState({ ...this.state, isShowTooltipId: '' });
                      }}
                      open={false}
                      // open={value.id === this.state.isShowTooltipId ? true : false}
                      title={
                        <Typography
                          color="inherit"
                          className={'tooltipWidth'}
                          style={{ fontSize: '15px', textAlign: 'center' }}
                        >
                          <div style={{ display: 'block' }}>{'description'}</div>
                        </Typography>
                      }
                      style={{ fontSize: '12px' }}
                    >
                      <div
                        className={['siftheaderdivgray contentHeaderGray'].join()}
                        style={{
                          borderLeft: '1px solid #BFBFBF',
                          borderRight: '1px solid #BFBFBF'
                        }}
                        onClick={(event) => {
                          // event.stopPropagation();
                          // this.setState({
                          //   ...this.state,
                          //   isShowTooltipId: value.id
                          // });
                        }}
                      >
                        <span
                          style={{
                            cursor: 'pointer',
                            fontSize: '1.2rem',
                            margin:
                              (value?.iGaugeOccupationFrameworkOneClusterDescription &&
                                '1px 0 1px 0') ||
                              'auto'
                          }}
                        >
                          {value.iGaugeOccupationFrameworkOneClusterName}
                        </span>
                        {value?.iGaugeOccupationFrameworkOneClusterDescription && (
                          <span style={{ cursor: 'pointer', fontSize: '1rem' }}>
                            {value?.iGaugeOccupationFrameworkOneClusterDescription}
                          </span>
                        )}
                      </div>
                    </Tooltip>
                  </ClickAwayListener>

                  <div className={['sifSecondtHeaderDivGray contentDatadivTopborderGray'].join()}>
                    {this.state.radioarraylist.map((lis, index) => {
                      return (
                        <span key={index}
                          // className={'contentDatadivGray'}
                          className={[
                            'contentDatadivGray',
                            value.id
                              ? value.iGaugeOccupationFrameworkOneRangeExtremumMinimum === index + 6
                                ? 'thirdGselected'
                                : value.iGaugeOccupationFrameworkOneRangeExtremumMaximum ===
                                  index + 6
                                ? 'thirdGselected'
                                : value.iGaugeOccupationFrameworkOneRangeExtremumMinimum <
                                    index + 6 &&
                                  value.iGaugeOccupationFrameworkOneRangeExtremumMaximum > index + 6
                                ? 'thirdGselected'
                                : null
                              : null
                          ].join(' ')}
                          onClick={() => {
                            saveselected(index + 6, value.id, index + 6);
                          }}
                          style={{
                            cursor: 'pointer',
                            borderLeft: '1px solid #BFBFBF',
                            borderRight: '1px solid #BFBFBF'
                          }}
                        >
                          <span style={{ color: 'rgba(0, 0, 0, 0.87)' }}>{lis}</span>
                        </span>
                      );
                    })}
                  </div>
                </div>
              </Paper>
            </div>
          );
        })}
      </Grid>
    );
  }
}

export default JobRangeTableTemplate;
