import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FILTERMODE,
  POPUP_OPEN,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MIDDLEPANE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_POPUP_STATE,
  SET_ASSIGNMENT_RELATED_LIST,
  LOADER_START,
  ASSIGNMENT_REVISE_SAGA
} from '../actionType';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import { ASSESSEES_GROUP_REVIEW_LIST_POPUP_OPTION } from '../PopUpConfig';
import Card from '../Molecules/Card/Card';
import CrossIcon from '@material-ui/icons/Clear';
import { onClickCheckBoxOneListSelection } from '../Actions/AssesseeModuleAction';
import ReviseIcon from '@material-ui/icons/RadioButtonChecked';
import Check from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import ListLoader from '../Atoms/ListLoader/ListLoader';

const AssignmentDistinctCultureProfileGroupReviewList = (props) => {
  const dispatch = useDispatch();
  const [isShowReviseIcon, setIsShowReviseIcon] = useState(true);
  const {
    middlePaneSelectedValue,
    reviewListDistinctData,
    relatedReviewListDistinctData,
    middlePaneHeaderDuplicateBadgeOne,
    middlePaneHeaderDuplicateBadgeTwo,
    typeOfMiddlePaneList,
    selectedTagsArray,
    unselectedTagsArray,
    isSelectActive,
    selectedAssociateInfo,
    isListLoading,
    previousMiddlePaneState
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const {
    responseObject,
    assignmentAssesseeList = [],
    assignmentAssessmentList = [],
    assignmentCultureProfileList = [],
    assignmentJobProfileList = [],
    assignmentAssesseeGroupList = [],
    assignmentAssessmentGroupList = [],
    assignmentJobProfileGroupList = []
  } = useSelector((state) => state.DisplayPaneThreeReducer);
  const assignmentInfo = useSelector((state) => state.AssignmentReducer);
  const { FilterMode } = useSelector((state) => state.FilterReducer);
  const onClickRevise = () => {
    console.log('ON CLICK REVISE ICON');
    setIsShowReviseIcon(false);
  };
  const onClickReviseCancel = () => {
    console.log('ON CLICK cancel ICON');
    dispatch({
      type: SET_MIDDLEPANE_STATE,
      payload: {
        middlePaneHeader: 'assignments',
        middlePaneHeaderBadgeOne: middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderBadgeTwo: middlePaneHeaderDuplicateBadgeTwo,
        middlePaneHeaderBadgeThree: '',
        middlePaneHeaderBadgeFour: '',
        typeOfMiddlePaneList: previousMiddlePaneState,
        scanCount: reviewListDistinctData.length,
        showMiddlePaneState: true
      }
    });
    setIsShowReviseIcon(true);
  };
  const onClickReviseFinish = () => {
    console.log('ON CLICK finish ICON', selectedTagsArray, unselectedTagsArray);
    setIsShowReviseIcon(true);
    if (typeOfMiddlePaneList !== '') {
      dispatch({
        type: SET_MIDDLEPANE_STATE,
        payload: {
          middlePaneHeader: 'assignments',
          middlePaneHeaderBadgeOne: middlePaneHeaderDuplicateBadgeOne,
          middlePaneHeaderBadgeTwo: middlePaneHeaderDuplicateBadgeTwo,
          middlePaneHeaderBadgeThree: '',
          middlePaneHeaderBadgeFour: '',
          typeOfMiddlePaneList: previousMiddlePaneState,
          scanCount: reviewListDistinctData.length,
          showMiddlePaneState: true
        }
      });
      dispatch({
        type: FILTERMODE,
        payload: { FilterMode: '' }
      });
    }

    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'isSelectActive', value: '' }
    });
    dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
    dispatch({
      type: SET_ASSIGNMENT_RELATED_LIST,
      payload: { listName: 'assignmentCultureProfileGroupList', value: selectedTagsArray }
    });
    const { id } = responseObject;
    let frameworkObj = {
      ...assignmentInfo.informationFramework,
      assignmentAssesseeDistinct: assignmentAssesseeList || [],
      assignmentAssessmentDistinct: assignmentAssessmentList || [],
      assignmentCultureProfileDistinct: assignmentCultureProfileList || [],
      assignmentJobProfileDistinct: assignmentJobProfileList || [],
      assignmentAssesseeGroup: assignmentAssesseeGroupList || [],
      assignmentAssessmentGroup: assignmentAssessmentGroupList || [],
      assignmentCultureProfileGroup: selectedTagsArray || [],
      assignmentJobProfileGroup: assignmentJobProfileGroupList || []
    };
    const reqBody = {
      module: 'Assignment-Distinct',
      action: 'Revise',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        assignment: {
          id,
          informationBasic: assignmentInfo.informationBasic,
          informationAllocation: assignmentInfo.informationAllocation,
          informationFramework: frameworkObj,
          informationSetup: assignmentInfo.informationSetup
        }
      }
    };
    dispatch({ type: LOADER_START });
    dispatch({
      type: ASSIGNMENT_REVISE_SAGA,
      payload: {
        reqBody
      }
    });
    dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
  };
  const revisePrimaryIcon = [{ label: 'revise', onClick: onClickRevise, Icon: ReviseIcon }];

  const reviseSecondaryIcons = [
    { label: 'cancel', onClick: onClickReviseCancel, Icon: ClearIcon },
    { label: 'finish', onClick: onClickReviseFinish, Icon: Check }
  ];

  const closeRelatedList = () => {
    if (isListLoading) {
      return
    }
    dispatch({
      type: SET_MIDDLEPANE_STATE,
      payload: {
        middlePaneHeader: 'assignments',
        middlePaneHeaderBadgeOne: middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderBadgeTwo: middlePaneHeaderDuplicateBadgeTwo,
        middlePaneHeaderBadgeThree: '',
        middlePaneHeaderBadgeFour: '',
        typeOfMiddlePaneList: previousMiddlePaneState,
        scanCount: reviewListDistinctData.length,
        showMiddlePaneState: true
      }
    });
  };
  const listDistinctData = relatedReviewListDistinctData[0];

  /* for middle pane */

  const openListPopup = (e) => {
    console.log(e.currentTarget.getAttribute('tag'));
    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: 'culture-profile',
        popupHeaderOneBadgeOne: '',
        isPopUpValue: '',
        popupOpenType: 'primary',
        popupContentArrValue: ASSESSEES_GROUP_REVIEW_LIST_POPUP_OPTION,
        selectedTagValue: e.currentTarget.getAttribute('tag'),
        selectedTagStatus: e.currentTarget.getAttribute('status')
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: ASSESSEES_GROUP_REVIEW_LIST_POPUP_OPTION
      }
    });
  };
  return (
    <div>
      {listDistinctData && (
        <Card
          textOneOne={listDistinctData.assignmentName}
          textTwoOne={listDistinctData.assignmentDescription}
          IconOne={CrossIcon}
          isIcon={true}
          labelTwoTwo={'distinct'}
          onClickIconOne={closeRelatedList}
          isAlliance
          relatedCardFixed={true}
          className={'iguru-iconboxSVG'}
        />
      )}
      {listDistinctData &&
        listDistinctData.cultureProfileGroup.map((item, index) => {
          return (
            <div className="containerPadding" key={index}>
              <ReviewList
                className=""
                id={index}
                tag={item.id}
                isSelectedReviewList={middlePaneSelectedValue === item.id}
                status={item.informationEngagement.cultureProfileGroupStatus}
                actualStatus={item.informationEngagement.cultureProfileGroupStatus}
                textOne={item.informationBasic.cultureProfileGroupName}
                textTwo={item.informationBasic.cultureProfileGroupDescription}
                isTooltipActive={false}
                onClickEvent={openListPopup}
                isSelectActive={isSelectActive}
                isSelected={selectedTagsArray.includes(item.id)}
                onClickCheckBox={(event) => {
                  !isListLoading &&
                    onClickCheckBoxOneListSelection(selectedTagsArray, event, dispatch);
                }}
              />
            </div>
          );
        })}
      {isListLoading ? <div> <ListLoader /> </div> : <div style={{ height: "60px", width: "100%" }}></div>}
      {FilterMode === 'assignmentDistinctCultureProfileGroupRevise' && (
        <FooterIconTwo
          FilterModeEnable={isShowReviseIcon}
          FilterMode={FilterMode}
          onClick={onClickRevise}
          primaryIcon={revisePrimaryIcon}
          secondaryIcon={reviseSecondaryIcons}
        />
      )}
    </div>
  );
};
export default AssignmentDistinctCultureProfileGroupReviewList;
