import React, { useEffect, useRef, useState } from 'react';
import './DisplayPaneFive.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  LOADER_START,
  NAVIGATOR_MODE,
  SET_DISPLAY_PANE_THREE_REVIEW_MODE,
  SET_MOBILE_PANE_STATE,
  SET_PANE_THREE_ASSESSMENT_PREVIEW_MODE,
  SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
  ASSESSMENT_INFO_PREVIEW_SAGA,
  SET_PANE_THREE_ASSESSMENT_SECTION_PREVIEW_MODE,
  SET_ASSESSMENT_SECTION_DYNAMIC_FRAMEWORK_STATE,
  SET_PANE_THREE_ASSESSMENT_VERSION_PREVIEW_MODE,
  ASSESSMENT_INFO_REVISE_SAGA,
  SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
  CLEAR_ASSESSEE_ASSESSMENT_REDUCER_STATE,
  SET_POPUP_STATE,
  POPUP_OPEN
} from '../../actionType';
import '../../Molecules/ReviewList/ReviewList.css';
import FooterIconTwo from '../../Molecules/FooterIcon/FooterIconTwo';
import NavigatorIcon from '@material-ui/icons/OpenWith';
import CodeIcon from '@material-ui/icons/Code';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import ArrowRight from '@material-ui/icons/ChevronRight';
import ArrowLeft from '@material-ui/icons/ChevronLeft';
import ReviseIcon from '@material-ui/icons/RadioButtonChecked';
import Check from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import DisplayPaneFiveItem from '../../Organisms/DisplayPaneFive/DisplayPaneFiveItem';
import DisplayPaneFiveAssessment from '../../Organisms/DisplayPaneFive/DisplayPaneFiveAssessment';
import { setItemTypeConfigState } from '../../Actions/GenericActions';
import HeaderCard from '../../Molecules/Header/HeaderCard';
import {
  ASSESSMENT_FINISH_POPUP_OPTION,
  RES_START_POPUP_OPTION,
  DELETE_POPUP
} from '../../PopUpConfig';
import { isMobile } from 'react-device-detect';
export const DisplayPaneFive = () => {
  const dispatch = useDispatch();
  const [currentItemIndex, setcurrentItemIndex] = useState(0);
  const [isShowReviseIcon, setIsShowReviseIcon] = useState(true);
  const [currentSectionIndex, setcurrentSectionIndex] = useState(0);
  const [currentVersionIndex, setcurrentVersionIndex] = useState(0);
  const {
    selectedAssociateInfo,
    relatedReviewListDistinctData,
    assessmentSelecedSection,
    assessmentSelecedVersion,
    assessmentResponseObject
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { selectedTagStatus } = useSelector((state) => state.PopUpReducer);
  const {
    headerOne,
    headerOneBadgeOne,
    isAssessmentPreviewShow = false,
    isItemPreviewShow = false,
    reviewMode,
    createMode,
    headerOneBadgeTwo,
    headerOneBadgeThree,
    responseObject,
    isAssessmentSectionShow = false,
    isAssessmentVersionShow = false
  } = useSelector((state) => state.DisplayPaneThreeReducer);
  const { informationFramework, isDisplayPaneSixShow, informationBasic, informationAllocation } =
    useSelector((state) => state.AssessmentReducer);
  const {
    assesseeAssessmentStartData,
    currentSequenceIndex,
    assessmentsequenceObject,
    isItemTrial,
    isAssessmentStart
  } = useSelector((state) => state.AssesseeAssignmentAssessmentReducer);
  let itemObect;
  let navigatorIconObject = {
    assessmentSectionItemNavigationFirst: true,
    assessmentSectionItemNavigationNext: true,
    assessmentSectionItemNavigationPrevious: true,
    assessmentSectionItemNavigationSkip: true,
    assessmentSectionItemNavigationLast: true
  };
  if (isAssessmentPreviewShow || isAssessmentSectionShow) {
    navigatorIconObject = { ...assesseeAssessmentStartData?.assessmentSectionItemNavigation };
  }
  let node = React.createRef();
  const { selectedTagValue } = useSelector((state) => state.PopUpReducer);
  const assessmentInfo = useSelector((state) => state.AssessmentReducer);
  const { itemInformation } = useSelector((state) => state.ItemCreateReducer);
  const versionInfo = useSelector((state) => state.VersionCreateReducer);
  const { FilterMode, navigatorIcon } = useSelector((state) => state.FilterReducer);
  const { sectionInformation } = useSelector((state) => state.SectionCreateReducer);
  const closePreview = () => {
    if (isAssessmentSectionShow) {
      dispatch({ type: SET_PANE_THREE_ASSESSMENT_SECTION_PREVIEW_MODE, payload: false });
    } else if (isAssessmentPreviewShow) {
      dispatch({ type: SET_PANE_THREE_ASSESSMENT_PREVIEW_MODE, payload: false });
    } else if (isAssessmentVersionShow) {
      dispatch({ type: SET_PANE_THREE_ASSESSMENT_VERSION_PREVIEW_MODE, payload: false });
    }
    dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
    dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
    dispatch({ type: CLEAR_ASSESSEE_ASSESSMENT_REDUCER_STATE });
  };
  useEffect(() => {
    window.MathJax.Hub.Config({
      extensions: ['tex2jax.js'],
      jax: ['input/TeX', 'output/HTML-CSS'],
      tex2jax: {
        inlineMath: [
          ['$', '$'],
          ['\\(', '\\)']
        ],
        displayMath: [
          ['$$', '$$'],
          ['\\[', '\\]']
        ],
        processEscapes: true
      }, //used for  other ['$','$'],
      showMathMenu: false,
      messageStyle: 'none',
      errorSettings: { message: ['[Math Processing Error]'] },
      MathEvents: {
        hover: 'none'
      },
      'HTML-CSS': {
        scale: 90,
        imageFont: null,
        preferredFont: null,
        availableFonts: ['Roboto'],
        webFont: 'STIX-Web'
      },
      SVG: { font: 'STIX-Web' }
    });
    window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub, node.current]);
  }, [itemObect]);
  useEffect(() => {}, [assesseeAssessmentStartData]);
  const onClickSequenceUpdate = () => {};
  const onClickFooter = (e) => {
    let clickedval = e.currentTarget.getAttribute('data-value');
    document.getElementById('containerItem').scrollTop = '0px'; //for scroll top
    //dispatch({ type: NAVIGATOR_MODE });
    let tempArr = RES_START_POPUP_OPTION;
    if (isAssessmentPreviewShow) {
      if (isItemTrial) {
        if (clickedval === 'first') {
          if (currentItemIndex !== 0) {
            setcurrentItemIndex(0);
          }
        }
        if (clickedval === 'next') {
          if (currentItemIndex < assesseeAssessmentStartData?.length - 1) {
            setcurrentItemIndex(currentItemIndex + 1);
          } else if (currentSequenceIndex < assessmentsequenceObject.length - 1) {
            if (assessmentsequenceObject[currentSequenceIndex + 1]?.name === 'item distinct') {
              let currentValue = assessmentsequenceObject[currentSequenceIndex + 1]?.originalValue;
              let lastIndexValue = parseInt(currentValue.substring(currentValue.length - 1));
              tempArr = [{ ...tempArr[0], disabled: true }, tempArr[1]];
              dispatch({
                type: SET_POPUP_STATE,
                payload: {
                  popupHeaderOne: 'assessment',
                  popupHeaderOneBadgeOne: '',
                  popupHeaderOneBadgeTwo: '',
                  isPopUpValue: '',
                  popupOpenType: 'primary',
                  popupContentArrValue: tempArr,
                  selectedTagStatus: selectedTagStatus,
                  selectedTagValue: lastIndexValue - 1
                }
              });
             dispatch({ type: POPUP_OPEN, payload: 'paneSevenPopup' });
              setcurrentItemIndex(0);
              setcurrentSectionIndex(0);
              setcurrentVersionIndex(0);
            }
            // dispatch({
            //   type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            //   payload: { stateName: 'currentSequenceIndex', value: currentSequenceIndex + 1 }
            // });
            // dispatch({
            //   type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            //   payload: { stateName: 'assesseeAssessmentStartData', value: null }
            // });
            // dispatch({
            //   type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            //   payload: { stateName: 'isItemTrial', value: false }
            // });
            // dispatch({
            //   type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            //   payload: { stateName: 'isAssessmentStart', value: 'PREVIEW' }
            // });
            // dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneSix' });
          }
        }
        if (clickedval === 'previous') {
          let prevIndex = currentItemIndex - 1;
          if (currentItemIndex !== 0) {
            setcurrentItemIndex(prevIndex);
          } else {
            dispatch({
              type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
              payload: { stateName: 'isItemTrial', value: false }
            });
            dispatch({
              type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
              payload: { stateName: 'currentSequenceIndex', value: currentSequenceIndex - 1 }
            });
            dispatch({
              type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
              payload: {
                stateName: 'assesseeAssessmentStartData',
                value: null // assessmentsequenceObject[currentSequenceIndex - 1]?.value
              }
            });
            isMobile && dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneSix' });
            dispatch({
              type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
              payload: { stateName: 'isAssessmentStart', value: 'PREVIEW' }
            });
          }
        }
        if (clickedval === 'last') {
          if (currentItemIndex < assesseeAssessmentStartData?.length - 1) {
            let lastIndex = assesseeAssessmentStartData?.length - 1;
            setcurrentItemIndex(lastIndex);
          }
        }
      } else {
        if (clickedval === 'previous') {
          let prevIndex = currentItemIndex - 1;
          if (currentItemIndex !== 0) {
            setcurrentItemIndex(prevIndex);
            setItemTypeConfigState(
              assesseeAssessmentStartData?.assessmentVersionFramework[currentVersionIndex]
                .assessmentVersionItemDistinct[prevIndex].itemFrameworkOne.itemFrameworkOneType,
              dispatch
            );
            dispatch({
              type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
              payload: {
                stateName: 'assessmentSectionItemDistinctRevise',
                value:
                  assesseeAssessmentStartData?.assessmentVersionFramework[currentVersionIndex]
                    .assessmentVersionItemDistinct[prevIndex]
              }
            });
          } else {
            if (currentSequenceIndex != 0) {
              if (assessmentsequenceObject[currentSequenceIndex - 1]?.name === 'item trial') {
                dispatch({
                  type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
                  payload: { stateName: 'isItemTrial', value: true }
                });
                setcurrentItemIndex(
                  assessmentsequenceObject[currentSequenceIndex - 1]?.value?.length - 1
                );
                dispatch({
                  type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
                  payload: {
                    stateName: 'assesseeAssessmentStartData',
                    value: assessmentsequenceObject[currentSequenceIndex - 1]?.value
                  }
                });
              } else {
                dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneSix' });
                dispatch({
                  type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
                  payload: {
                    stateName: 'assesseeAssessmentStartData',
                    value: null
                  }
                });
              }
              dispatch({
                type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
                payload: { stateName: 'currentSequenceIndex', value: currentSequenceIndex - 1 }
              });

              dispatch({
                type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
                payload: { stateName: 'isAssessmentStart', value: 'PREVIEW' }
              });
            }

            // if (currentSectionIndex !== 0) {
            //   setcurrentSectionIndex(currentSectionIndex - 1);
            //   setcurrentItemIndex(
            //     responseObject?.informationFramework?.assessmentSection[currentSectionIndex - 1]
            //       ?.assessmentVersionFramework[currentVersionIndex].assessmentVersionItemDistinct.length - 1
            //   );
            //   setItemTypeConfigState(
            //     responseObject?.informationFramework?.assessmentSection[currentSectionIndex - 1]
            //       ?.assessmentVersionFramework[currentVersionIndex].assessmentVersionItemDistinct[
            //       responseObject?.informationFramework?.assessmentSection[currentSectionIndex - 1]
            //         ?.assessmentVersionFramework[currentVersionIndex].assessmentVersionItemDistinct.length - 1
            //     ].itemFrameworkOne.itemFrameworkOneType,
            //     dispatch
            //   );
            //   dispatch({
            //     type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
            //     payload: {
            //       stateName: 'assessmentSectionItemDistinctRevise',
            //       value:
            //         responseObject?.informationFramework?.assessmentSection[currentSectionIndex - 1]
            //           ?.assessmentVersionFramework[currentVersionIndex].assessmentVersionItemDistinct[
            //         responseObject?.informationFramework?.assessmentSection[currentSectionIndex - 1]
            //           ?.assessmentVersionFramework[currentVersionIndex].assessmentVersionItemDistinct
            //           .length - 1
            //         ]
            //     }
            //   });
            // }
            else if (currentVersionIndex !== 0) {
              setcurrentVersionIndex(currentVersionIndex - 1);
              setcurrentItemIndex(
                assesseeAssessmentStartData?.assessmentVersionFramework[currentVersionIndex]
                  .assessmentVersionItemDistinct.length - 1
              );
            }
            // if (currentSectionIndex === informationFramework?.assessmentSection.length - 1) {
            //   setcurrentSectionIndex(currentSectionIndex - 1);
            //   setcurrentItemIndex(informationFramework.assessmentSection[currentSectionIndex - 1]?.assessmentSectionItemDistinct.length - 1);
            //   //informationFramework.assessmentSectionFramework[currentSectionIndex]?.assessmentSectionItemDistinct.length - 1
            // }
          }
        }
        if (clickedval === 'first') {
          if (currentItemIndex !== 0) {
            setcurrentSectionIndex(currentSectionIndex);
            setcurrentItemIndex(0);
            setItemTypeConfigState(
              assesseeAssessmentStartData?.assessmentVersionFramework[currentVersionIndex]
                .assessmentVersionItemDistinct[0].itemFrameworkOne.itemFrameworkOneType,
              dispatch
            );
            dispatch({
              type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
              payload: {
                stateName: 'assessmentSectionItemDistinctRevise',
                value:
                  assesseeAssessmentStartData?.assessmentVersionFramework[currentVersionIndex]
                    .assessmentVersionItemDistinct[0]
              }
            });
          } else {
            if (currentSequenceIndex != 0) {
              dispatch({
                type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
                payload: { stateName: 'currentSequenceIndex', value: currentSequenceIndex - 1 }
              });
              dispatch({
                type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
                payload: { stateName: 'assesseeAssessmentStartData', value: null }
              });
              dispatch({
                type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
                payload: { stateName: 'isAssessmentStart', value: 'PREVIEW' }
              });
            }
            // if (currentSectionIndex !== 0) {
            //   setcurrentSectionIndex(currentSectionIndex - 1);
            //   setcurrentItemIndex(0);
            //   setItemTypeConfigState(
            //     responseObject?.informationFramework?.assessmentSection[currentSectionIndex - 1]
            //       ?.assessmentVersionFramework[currentVersionIndex].assessmentVersionItemDistinct[0]
            //       .itemFrameworkOne.itemFrameworkOneType,
            //     dispatch
            //   );
            //   dispatch({
            //     type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
            //     payload: {
            //       stateName: 'assessmentSectionItemDistinctRevise',
            //       value:
            //         responseObject?.informationFramework?.assessmentSection[currentSectionIndex - 1]
            //           ?.assessmentVersionFramework[currentVersionIndex].assessmentVersionItemDistinct[0]
            //     }
            //   });
            // }
          }
        }
        if (clickedval === 'next') {
          console.log('currentSequenceIndex', currentSequenceIndex);
          console.log('assessmentsequenceObject', assessmentsequenceObject);
          if (
            currentItemIndex <
            assesseeAssessmentStartData?.assessmentVersionFramework[currentVersionIndex]
              .assessmentVersionItemDistinct.length -
              1
          ) {
            setcurrentItemIndex(currentItemIndex + 1);
            setItemTypeConfigState(
              assesseeAssessmentStartData?.assessmentVersionFramework[currentVersionIndex]
                .assessmentVersionItemDistinct[currentItemIndex + 1].itemFrameworkOne
                .itemFrameworkOneType,
              dispatch
            );
            dispatch({
              type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
              payload: {
                stateName: 'assessmentSectionItemDistinctRevise',
                value:
                  assesseeAssessmentStartData?.assessmentVersionFramework[currentVersionIndex]
                    .assessmentVersionItemDistinct[currentItemIndex + 1]
              }
            });
          } else if (
            currentVersionIndex <
            responseObject.informationFramework?.assessmentSectionFramework[currentSectionIndex]
              ?.assessmentVersionFramework.length -
              1
          ) {
            setcurrentVersionIndex(currentVersionIndex + 1);
            setcurrentItemIndex(0);
          } else if (currentSequenceIndex < assessmentsequenceObject.length - 1) {
            let tempArr2 = ASSESSMENT_FINISH_POPUP_OPTION;
            tempArr2 = [tempArr2[0], { ...tempArr2[1], disabled: true }];
            dispatch({
              type: SET_POPUP_STATE,
              payload: {
                popupHeaderOne: 'assessment',
                popupHeaderOneBadgeOne:
                  responseObject.informationFramework?.assessmentSectionFramework.length > 1
                    ? 'section'
                    : 'end',
                popupHeaderOneBadgeTwo:
                  responseObject.informationFramework?.assessmentSectionFramework.length > 1
                    ? 'end'
                    : '',
                isPopUpValue: '',
                popupOpenType: 'primary',
                popupContentArrValue: tempArr2
                // selectedTagStatus: selectedTagStatus,
                // selectedTagValue: lastIndexValue - 1
              }
            });
           dispatch({ type: POPUP_OPEN, payload: 'paneSevenPopup' });
            setcurrentItemIndex(0);
            setcurrentSectionIndex(currentSectionIndex + 1);
            // dispatch({
            //   type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            //   payload: { stateName: 'assesseeAssessmentStartData', value: null }
            // });
            dispatch({
              type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
              payload: { stateName: 'isAssessmentStart', value: 'PREVIEW' }
            });
            // dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneSix' });
          } else if (
            currentSectionIndex <
            responseObject?.informationFramework?.assessmentSectionFramework.length - 1
          ) {
            setcurrentSectionIndex(currentSectionIndex + 1);
            setcurrentItemIndex(0);
            setcurrentVersionIndex(0);
            dispatch({
              type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
              payload: {
                stateName: 'assessmentSectionItemDistinctRevise',
                value:
                  responseObject?.informationFramework?.assessmentSection[currentSectionIndex + 1]
                    ?.assessmentVersionFramework[currentVersionIndex]
                    .assessmentVersionItemDistinct[0]
              }
            });
          } else {
            setcurrentItemIndex(0);
            setcurrentSectionIndex(0);
            setcurrentVersionIndex(0);
            closePreview();
          }
        }
        if (clickedval === 'last') {
          if (
            currentItemIndex <
            assesseeAssessmentStartData?.assessmentVersionFramework[currentVersionIndex]
              .assessmentVersionItemDistinct.length -
              1
          ) {
            let lastIndex =
              assesseeAssessmentStartData?.assessmentVersionFramework[currentVersionIndex]
                .assessmentVersionItemDistinct.length - 1;
            setcurrentItemIndex(lastIndex);
            setItemTypeConfigState(
              assesseeAssessmentStartData?.assessmentVersionFramework[currentVersionIndex]
                .assessmentVersionItemDistinct[lastIndex].itemFrameworkOne.itemFrameworkOneType,
              dispatch
            );
            dispatch({
              type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
              payload: {
                stateName: 'assessmentSectionItemDistinctRevise',
                value:
                  assesseeAssessmentStartData?.assessmentVersionFramework[currentVersionIndex]
                    .assessmentVersionItemDistinct[lastIndex]
              }
            });
          } else {
            // if (
            //   currentSectionIndex <
            //   responseObject?.informationFramework?.assessmentSection.length - 1
            // ) {
            //   setcurrentSectionIndex(currentSectionIndex + 1);
            //   let lastIndex =
            //     responseObject?.informationFramework.assessmentSection[currentSectionIndex + 1]
            //       ?.assessmentSectionItemDistinct.length - 1;
            //   setcurrentItemIndex(lastIndex);
            //   setItemTypeConfigState(
            //     responseObject?.informationFramework?.assessmentSectionFramework[currentSectionIndex]
            //       ?.assessmentSectionItemDistinct[lastIndex].itemFrameworkOne.itemFrameworkOneType,
            //     dispatch
            //   );
            //   dispatch({
            //     type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
            //     payload: {
            //       stateName: 'assessmentSectionItemDistinctRevise',
            //       value:
            //         responseObject?.informationFramework?.assessmentSection[currentSectionIndex + 1]
            //           ?.assessmentSectionItemDistinct[lastIndex]
            //     }
            //   });
            // }
            closePreview();
          }
        }
      }
    } else if (isAssessmentSectionShow) {
      if (clickedval === 'first') {
        if (currentItemIndex != 0) {
          setcurrentItemIndex(0);
          setItemTypeConfigState(
            assesseeAssessmentStartData?.assessmentVersionFramework[currentVersionIndex]
              ?.assessmentVersionItemDistinct[0]?.itemFrameworkOne.itemFrameworkOneType,
            dispatch
          );
          dispatch({
            type: SET_ASSESSMENT_SECTION_DYNAMIC_FRAMEWORK_STATE,
            payload: {
              stateName: 'assessmentSectionItemDistinctReviseObject',
              value:
                assesseeAssessmentStartData?.assessmentVersionFramework[currentVersionIndex]
                  ?.assessmentVersionItemDistinct[0]
            }
          });
        } else {
          if (currentVersionIndex !== 0) {
            setcurrentVersionIndex(currentVersionIndex - 1);
            setcurrentItemIndex(0);
            setItemTypeConfigState(
              assesseeAssessmentStartData?.assessmentVersionFramework[currentVersionIndex]
                ?.assessmentVersionItemDistinct[0]?.itemFrameworkOne.itemFrameworkOneType,
              dispatch
            );
            dispatch({
              type: SET_ASSESSMENT_SECTION_DYNAMIC_FRAMEWORK_STATE,
              payload: {
                stateName: 'assessmentSectionItemDistinctReviseObject',
                value:
                  assesseeAssessmentStartData?.assessmentVersionFramework[currentVersionIndex]
                    ?.assessmentVersionItemDistinct[0]
              }
            });
          }
        }
      }
      if (clickedval === 'previous') {
        let prevIndex = currentItemIndex - 1;
        if (currentItemIndex != 0) {
          setcurrentItemIndex(prevIndex);
          setItemTypeConfigState(
            assesseeAssessmentStartData?.assessmentVersionFramework[currentVersionIndex]
              ?.assessmentVersionItemDistinct[prevIndex]?.itemFrameworkOne.itemFrameworkOneType,
            dispatch
          );
          dispatch({
            type: SET_ASSESSMENT_SECTION_DYNAMIC_FRAMEWORK_STATE,
            payload: {
              stateName: 'assessmentSectionItemDistinctReviseObject',
              value:
                assesseeAssessmentStartData?.assessmentVersionFramework[currentVersionIndex]
                  ?.assessmentVersionItemDistinct[prevIndex]
            }
          });
        } else {
          if (currentVersionIndex !== 0) {
            setcurrentVersionIndex(currentVersionIndex - 1);
            setcurrentItemIndex(
              assesseeAssessmentStartData?.assessmentVersionFramework[currentVersionIndex - 1]
                .assessmentVersionItemDistinct.length - 1
            );
            setItemTypeConfigState(
              assesseeAssessmentStartData?.assessmentVersionFramework[currentVersionIndex - 1]
                ?.assessmentVersionItemDistinct[
                assesseeAssessmentStartData?.assessmentVersionFramework[currentVersionIndex - 1]
                  .assessmentVersionItemDistinct.length - 1
              ]?.itemFrameworkOne.itemFrameworkOneType,
              dispatch
            );
            dispatch({
              type: SET_ASSESSMENT_SECTION_DYNAMIC_FRAMEWORK_STATE,
              payload: {
                stateName: 'assessmentSectionItemDistinctReviseObject',
                value:
                  assesseeAssessmentStartData?.assessmentVersionFramework[currentVersionIndex]
                    ?.assessmentVersionItemDistinct[prevIndex]
              }
            });
          }
        }
      }
      if (clickedval === 'next') {
        console.log('currentVersionIndex', currentVersionIndex);
        console.log('lenght', assesseeAssessmentStartData.assessmentVersionFramework.length);
        if (
          currentItemIndex <
          assesseeAssessmentStartData.assessmentVersionFramework[currentVersionIndex]
            ?.assessmentVersionItemDistinct.length -
            1
        ) {
          setcurrentItemIndex(currentItemIndex + 1);
          setItemTypeConfigState(
            assesseeAssessmentStartData?.assessmentVersionFramework[currentVersionIndex]
              ?.assessmentVersionItemDistinct[currentItemIndex + 1]?.itemFrameworkOne
              .itemFrameworkOneType,
            dispatch
          );
          dispatch({
            type: SET_ASSESSMENT_SECTION_DYNAMIC_FRAMEWORK_STATE,
            payload: {
              stateName: 'assessmentSectionItemDistinctReviseObject',
              value:
                assesseeAssessmentStartData?.assessmentVersionFramework[currentVersionIndex]
                  ?.assessmentVersionItemDistinct[currentItemIndex + 1]
            }
          });
        } else {
          if (
            currentVersionIndex <
            assesseeAssessmentStartData?.assessmentVersionFramework.length - 1
          ) {
            setcurrentVersionIndex(currentVersionIndex + 1);
            setcurrentItemIndex(0);
            setItemTypeConfigState(
              assesseeAssessmentStartData?.assessmentVersionFramework[currentVersionIndex + 1]
                ?.assessmentVersionItemDistinct[0]?.itemFrameworkOne.itemFrameworkOneType,
              dispatch
            );
            dispatch({
              type: SET_ASSESSMENT_SECTION_DYNAMIC_FRAMEWORK_STATE,
              payload: {
                stateName: 'assessmentSectionItemDistinctReviseObject',
                value:
                  assesseeAssessmentStartData?.assessmentVersionFramework[currentVersionIndex + 1]
                    ?.assessmentVersionItemDistinct[0]
              }
            });
          } else if (currentSequenceIndex < assessmentsequenceObject.length - 1) {
            dispatch({
              type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
              payload: { stateName: 'currentSequenceIndex', value: currentSequenceIndex + 1 }
            });
            dispatch({
              type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
              payload: { stateName: 'assesseeAssessmentStartData', value: null }
            });
            dispatch({
              type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
              payload: { stateName: 'isAssessmentStart', value: 'SECTIONPREVIEW' }
            });
            dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneSix' });
          }
        }
      }
      if (clickedval === 'last') {
        if (
          currentItemIndex <
          assesseeAssessmentStartData.assessmentVersionFramework[currentVersionIndex]
            ?.assessmentVersionItemDistinct.length -
            1
        ) {
          let lastIndex =
            assesseeAssessmentStartData.assessmentVersionFramework[currentVersionIndex]
              ?.assessmentVersionItemDistinct.length - 1;
          setcurrentItemIndex(lastIndex);
          setItemTypeConfigState(
            assesseeAssessmentStartData?.assessmentVersionFramework[currentVersionIndex]
              ?.assessmentVersionItemDistinct[lastIndex]?.itemFrameworkOne.itemFrameworkOneType,
            dispatch
          );
          dispatch({
            type: SET_ASSESSMENT_SECTION_DYNAMIC_FRAMEWORK_STATE,
            payload: {
              stateName: 'assessmentSectionItemDistinctReviseObject',
              value:
                assesseeAssessmentStartData?.assessmentVersionFramework[currentVersionIndex]
                  ?.assessmentVersionItemDistinct[lastIndex]
            }
          });
        } else {
          if (currentVersionIndex < assesseeAssessmentStartData?.assessmentVersion.length - 1) {
            setcurrentVersionIndex(currentVersionIndex + 1);
            let lastIndex =
              assesseeAssessmentStartData.assessmentVersionFramework[currentVersionIndex]
                ?.assessmentVersionItemDistinct.length - 1;
            setcurrentItemIndex(lastIndex);
            setItemTypeConfigState(
              assesseeAssessmentStartData?.assessmentVersion[currentVersionIndex + 1]
                ?.assessmentVersionItemDistinct[lastIndex]?.itemFrameworkOne.itemFrameworkOneType,
              dispatch
            );
            dispatch({
              type: SET_ASSESSMENT_SECTION_DYNAMIC_FRAMEWORK_STATE,
              payload: {
                stateName: 'assessmentSectionItemDistinctReviseObject',
                value:
                  assesseeAssessmentStartData?.assessmentVersion[currentVersionIndex + 1]
                    ?.assessmentVersionItemDistinct[lastIndex]
              }
            });
          }
        }
      }
    } else if (isAssessmentVersionShow) {
      if (clickedval === 'first') {
        setcurrentItemIndex(0);
        setItemTypeConfigState(
          assesseeAssessmentStartData?.assessmentVersionItemDistinct[0]?.itemFrameworkOne
            .itemFrameworkOneType,
          dispatch
        );
        dispatch({
          type: SET_ASSESSMENT_SECTION_DYNAMIC_FRAMEWORK_STATE,
          payload: {
            stateName: 'assessmentVersionItemDistinctReviseObject',
            value: assesseeAssessmentStartData?.assessmentVersionItemDistinct[0]
          }
        });
      }
      if (clickedval === 'previous') {
        let prevIndex = currentItemIndex - 1;
        if (currentItemIndex != 0) {
          setcurrentItemIndex(prevIndex);
          setItemTypeConfigState(
            assesseeAssessmentStartData?.assessmentVersionItemDistinct[prevIndex]?.itemFrameworkOne
              .itemFrameworkOneType,
            dispatch
          );
          dispatch({
            type: SET_ASSESSMENT_SECTION_DYNAMIC_FRAMEWORK_STATE,
            payload: {
              stateName: 'assessmentVersionItemDistinctReviseObject',
              value: assesseeAssessmentStartData?.assessmentVersionItemDistinct[prevIndex]
            }
          });
        }
      }
      if (clickedval === 'next') {
        if (
          currentItemIndex <
          assesseeAssessmentStartData.assessmentVersionItemDistinct.length - 1
        ) {
          setcurrentItemIndex(currentItemIndex + 1);
          setItemTypeConfigState(
            assesseeAssessmentStartData?.assessmentVersionItemDistinct[currentItemIndex + 1]
              ?.itemFrameworkOne.itemFrameworkOneType,
            dispatch
          );
          dispatch({
            type: SET_ASSESSMENT_SECTION_DYNAMIC_FRAMEWORK_STATE,
            payload: {
              stateName: 'assessmentVersionItemDistinctReviseObject',
              value:
                assesseeAssessmentStartData?.assessmentVersionItemDistinct[currentItemIndex + 1]
            }
          });
        }
      }
      if (clickedval === 'last') {
        if (
          currentItemIndex <
          assesseeAssessmentStartData.assessmentVersionItemDistinct.length - 1
        ) {
          let lastIndex = assesseeAssessmentStartData.assessmentVersionItemDistinct.length - 1;
          setcurrentItemIndex(lastIndex);
          setItemTypeConfigState(
            assesseeAssessmentStartData?.assessmentVersionItemDistinct[lastIndex]?.itemFrameworkOne
              .itemFrameworkOneType,
            dispatch
          );
          dispatch({
            type: SET_ASSESSMENT_SECTION_DYNAMIC_FRAMEWORK_STATE,
            payload: {
              stateName: 'assessmentVersionItemDistinctReviseObject',
              value: assesseeAssessmentStartData?.assessmentVersionItemDistinct[lastIndex]
            }
          });
        }
      }
    } else {
    }
  };

  const onClickReviseFinish = async () => {
    setIsShowReviseIcon(true);
    if (isAssessmentSectionShow) {
      //dispatch({ type: SET_DISPLAY_PANE_THREE_REVIEW_MODE, payload: 'review' });
      let itemDistinctReviseObj = sectionInformation?.assessmentSectionItemFrameworkOneDistinct;
      itemDistinctReviseObj[currentItemIndex].itemFrameworkOne =
        sectionInformation?.assessmentSectionItemDistinctReviseObject?.itemFrameworkOne;
      dispatch({
        type: SET_ASSESSMENT_SECTION_DYNAMIC_FRAMEWORK_STATE,
        payload: {
          stateName: 'assessmentSectionItemFrameworkOneDistinct',
          value: itemDistinctReviseObj
        }
      });
      //const { id } = responseObject;
      const id = relatedReviewListDistinctData[0].id;
      const reqBody = {
        module: 'Assessment-Distinct',
        action: 'Revise',
        actionFilter: 'Section-Item',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          assessment: {
            id: id,
            informationBasic,
            informationAllocation,
            informationFramework: {
              assessmentSectionFramework: [
                {
                  //sectionIndex: currentSectionIndex,
                  sectionIndex: parseInt(selectedTagValue),
                  versionIndex: currentVersionIndex,
                  itemSequence:
                    sectionInformation?.assessmentSectionItemDistinctReviseObject?.itemSequence,
                  assessmentSectionItemFrameworkOneDistinct: [
                    {
                      itemTagPrimary:
                        sectionInformation?.assessmentSectionItemDistinctReviseObject
                          ?.itemTagPrimary,
                      itemFrameworkOne:
                        sectionInformation?.assessmentSectionItemDistinctReviseObject
                          ?.itemFrameworkOne
                    }
                  ]
                }
              ]
            }
          }
        }
      };
      dispatch({ type: LOADER_START });
      dispatch({
        type: ASSESSMENT_INFO_PREVIEW_SAGA,
        payload: {
          secondaryOptionCheckValue: headerOneBadgeTwo,
          headerOne: 'assessment',
          reqBody,
          createMode,
          selectedSector: selectedTagValue,
          assessmentSector: 'section'
        }
      });
    } else if (isAssessmentVersionShow) {
      let sectionObj = assessmentInfo.informationFramework.assessmentSectionFramework;
      let itemArr = [];
      assesseeAssessmentStartData.assessmentVersionItemDistinct.map((dd) => {
        itemArr.push({ itemSequence: dd.itemSequence, itemTagPrimary: dd.itemTagPrimary });
      });
      let dddd = {
        assessmentVersionName: versionInfo.versionInformation.assessmentVersionName,
        assessmentVersionVerification: versionInfo.versionInformation.assessmentVersionVerification,
        assessmentVersionDescription: versionInfo.versionInformation.assessmentVersionDescription,
        assessmentVersionItemDistinct: itemArr
      };
      // sectionObj[assessmentSelecedSection].assessmentVersion[assessmentSelecedVersion] =
      //   versionInfo.versionInformation;
      sectionObj[assessmentSelecedSection].assessmentVersionFramework[assessmentSelecedVersion] =
        dddd;
      let id = relatedReviewListDistinctData[0].id;
      const reqBody = {
        module: 'Assessment-Distinct',
        action: 'Revise',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          assessment: {
            id,
            informationBasic,
            informationAllocation,
            informationFramework: {
              assessmentSectionFramework: sectionObj
            }
          }
        }
      };
      dispatch({ type: LOADER_START });
      dispatch({
        type: ASSESSMENT_INFO_REVISE_SAGA,
        payload: {
          secondaryOptionCheckValue: headerOneBadgeThree,
          headerOne: 'assessment',
          reqBody,
          createMode,
          assessmentSector: 'version',
          selectedSector: assessmentSelecedSection,
          selectedVersion: assessmentSelecedVersion
        }
      });
    } else if (isItemTrial) {
      const { id } = responseObject;
      let reviseTrialItem = {};
      assesseeAssessmentStartData.forEach((ii, ind) => {
        if (ind === currentItemIndex) {
          reviseTrialItem = {
            itemTagPrimary: ii.itemTagPrimary,
            itemFrameworkOne: {
              ...itemObect,
              itemFrameworkOneScore:
                itemInformation.informationFramework.itemFrameworkOne.itemFrameworkOneScore,
              itemFrameworkOneScaleNumber:
                itemInformation.informationFramework.itemFrameworkOne.itemFrameworkOneScaleNumber,
              itemFrameworkOneScale: [
                itemInformation.informationFramework.itemFrameworkOne.itemFrameworkOneScale
              ]
            }
          };
        }
      });
      const reqBody = {
        module: 'Assessment-Distinct',
        action: 'Revise',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          assessment: {
            id,
            informationBasic,
            informationAllocation,
            informationFramework: {
              assessmentItemTrialItemFrameworkOne: [reviseTrialItem]
            }
          }
        }
      };
      console.log('reqBody', reqBody);
      dispatch({ type: LOADER_START });
      dispatch({
        type: ASSESSMENT_INFO_REVISE_SAGA,
        payload: {
          secondaryOptionCheckValue: headerOneBadgeThree,
          headerOne: 'assessment',
          reqBody,
          createMode,
          // assessmentSector: '',
          // selectedSector: assessmentSelecedSection,
          // selectedVersion: assessmentSelecedVersion
          isItemTrial
        }
      });
    } else {
      dispatch({ type: SET_DISPLAY_PANE_THREE_REVIEW_MODE, payload: 'review' });
      //const { informationFramework } = informationFramework;
      const { id } = responseObject;
      const reqBody = {
        module: 'Assessment-Distinct',
        action: 'Revise',
        actionFilter: 'Section-Item',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          assessment: {
            id: id,
            informationBasic,
            informationAllocation,
            informationFramework: {
              assessmentSectionFramework: [
                {
                  sectionIndex: currentSectionIndex,
                  versionIndex: currentVersionIndex,
                  assessmentSectionItemFrameworkOneDistinct: [
                    informationFramework?.assessmentSectionItemDistinctRevise
                  ]
                }
              ]
            }
          }
        }
      };
      dispatch({ type: LOADER_START });
      dispatch({
        type: ASSESSMENT_INFO_PREVIEW_SAGA,
        payload: {
          secondaryOptionCheckValue: headerOneBadgeTwo,
          headerOne: 'assessment',
          reqBody,
          createMode,
          assessmentSector: 'assessment'
        }
      });
    }
  };
  const onClickRevise = () => {
    setIsShowReviseIcon(false);
  };
  const onClickReviseCancel = () => {
    //setResponseToReducerObj(JSON.parse(originResponseObj), dispatch);
    setIsShowReviseIcon(true);
    dispatch({ type: SET_DISPLAY_PANE_THREE_REVIEW_MODE, payload: 'review' });
  };
  const revisePrimaryIcon = [{ label: 'revise', onClick: onClickRevise, Icon: ReviseIcon }];
  const reviseSecondaryIcons = [
    { label: 'cancel', onClick: onClickReviseCancel, Icon: ClearIcon },
    { label: 'finish', onClick: onClickReviseFinish, Icon: Check }
  ];

  //  console.log('responseObject', responseObject);
  let assessmentVersionFramework = assesseeAssessmentStartData?.assessmentVersionFramework || [];
  let assessmentItemTrial = (assesseeAssessmentStartData && assesseeAssessmentStartData) || [];
  var itemListArray = isAssessmentSectionShow
    ? assessmentVersionFramework &&
      assessmentVersionFramework[currentVersionIndex]?.assessmentVersionItemDistinct
    : isAssessmentPreviewShow
    ? isItemTrial
      ? assessmentItemTrial
      : assessmentVersionFramework &&
        assessmentVersionFramework[currentVersionIndex]?.assessmentVersionItemDistinct
    : isAssessmentVersionShow
    ? assesseeAssessmentStartData?.assessmentVersionItemDistinct
    : [];
  itemObect = itemListArray?.length > 0 && itemListArray[currentItemIndex].itemFrameworkOne;
  const handleRadioButton = (e) => {
    let optionValue = e.target.value;
    let optionName = e.target.name;
    let itemSectionSequence = optionName.split('-')[1];
    itemObect.itemFrameworkOneSection.forEach((element) => {
      if (element.itemFrameworkOneSectionSequence === parseInt(itemSectionSequence)) {
        element.itemFrameworkOne.itemFrameworkOneResponseCorrect = optionValue;
      }
    });
  };

  if (
    currentItemIndex === 0 &&
    !isItemTrial &&
    (isAssessmentPreviewShow || isAssessmentSectionShow)
  ) {
    navigatorIconObject.assessmentSectionItemNavigationFirst = false;
    navigatorIconObject.assessmentSectionItemNavigationPrevious = false;
  }
  if (
    currentItemIndex !== 0 &&
    !isItemTrial &&
    (isAssessmentPreviewShow || isAssessmentSectionShow)
  ) {
    navigatorIconObject.assessmentSectionItemNavigationFirst =
      assesseeAssessmentStartData?.assessmentSectionItemNavigation.assessmentSectionItemNavigationFirst;
    navigatorIconObject.assessmentSectionItemNavigationPrevious =
      assesseeAssessmentStartData?.assessmentSectionItemNavigation.assessmentSectionItemNavigationPrevious;
  }
  if (
    parseInt(currentItemIndex) ===
      parseInt(assesseeAssessmentStartData?.assessmentSectionItemTotal - 1) &&
    !isItemTrial &&
    (isAssessmentPreviewShow || isAssessmentSectionShow)
  ) {
    navigatorIconObject.assessmentSectionItemNavigationLast = false;
  }
  const primaryIcon = [{ label: 'navigator', onClick: onClickFooter, Icon: CodeIcon }];
  const secondaryIcon = [
    {
      label: 'first',
      onClick: onClickFooter,
      disabled: isItemTrial ? false : !navigatorIconObject?.assessmentSectionItemNavigationFirst,
      Icon: FirstPage
    },
    {
      label: 'previous',
      onClick: onClickFooter,
      disabled: isItemTrial ? false : !navigatorIconObject?.assessmentSectionItemNavigationPrevious,
      Icon: ArrowLeft
    },
    {
      label: 'next',
      onClick: onClickFooter,
      disabled: isItemTrial ? false : !navigatorIconObject?.assessmentSectionItemNavigationNext,
      Icon: ArrowRight
    },
    {
      label: 'last',
      onClick: onClickFooter,
      disabled: isItemTrial ? false : !navigatorIconObject?.assessmentSectionItemNavigationLast,
      Icon: LastPage
    }
  ];
  return (
    <>
      <div ref={node} key={itemObect}>
        {isAssessmentPreviewShow ? (
          <>
            <HeaderCard
              className=""
              displayPane="itemPreview"
              showClearIcon
              headerOne={headerOne}
              headerOneBadgeOne={(isItemTrial && 'trial') || ''}
              headerOneBadgeTwo=""
              headerPanelColour="green"
              onClickClearInfo={closePreview}
            />
            {isAssessmentStart === 'PROGRESS' && (
              <DisplayPaneFiveAssessment
                headerOne={headerOne}
                //closePreview={closePreview}
                informationFramework={responseObject?.informationFramework}
                currentItemIndex={currentItemIndex}
                currentSectionIndex={currentSectionIndex}
                currentVersionIndex={currentVersionIndex}
                handleRadioButton={handleRadioButton}
                itemObect={itemObect}
                itemListArray={itemListArray}
                //responseAlignment={'horizontal'}
                responseAlignment={
                  (assessmentResponseObject?.informationFramework
                    ?.assessmentAdministrationTemplateAlignment
                    ?.assessmentItemFrameworkOneResponseChoiceAlignment &&
                    assessmentResponseObject?.informationFramework
                      ?.assessmentAdministrationTemplateAlignment
                      ?.assessmentItemFrameworkOneResponseChoiceAlignment) ||
                  (assessmentInfo?.informationFramework?.assessmentAdministrationTemplateAlignment
                    ?.assessmentItemFrameworkOneResponseChoiceAlignment &&
                    assessmentInfo?.informationFramework?.assessmentAdministrationTemplateAlignment
                      ?.assessmentItemFrameworkOneResponseChoiceAlignment) ||
                  (itemObect?.itemFrameworkOneResponseChoiceAlignment &&
                    itemObect?.itemFrameworkOneResponseChoiceAlignment) ||
                  ''
                }
                itemFrameworkOneResponseChoiceSequenceAdministration={
                  informationFramework?.assessmentSectionItemDistinctRevise?.itemFrameworkOne
                    ?.itemFrameworkOneResponseChoiceSequenceAdministration || []
                }
                assessmentSectionItemFrameworkOneLabel={
                  assesseeAssessmentStartData?.assessmentSectionItemFrameworkOneLabel
                }
                assessmentSectionItemFrameworkOneResponseLabel={
                  assesseeAssessmentStartData?.assessmentSectionItemFrameworkOneResponseLabel
                }
                flagQuestion={''}
              />
            )}
            {(reviewMode === 'revise' && isItemTrial && assesseeAssessmentStartData && (
              <FooterIconTwo
                className={'widthDisplayPaneFive'}
                //FilterModeEnable={isShowReviseIcon}
                FilterModeEnable={false}
                FilterMode={FilterMode}
                onClick={onClickRevise}
                primaryIcon={reviseSecondaryIcons}
                secondaryIcon={reviseSecondaryIcons}
                //isAssessmentPreviewShow={isAssessmentPreviewShow}
                //isAssessmentPreviewShow={false}
                isAssessmentPreviewShow={true}
              />
            )) ||
              (assesseeAssessmentStartData && (
                <FooterIconTwo
                  className={isDisplayPaneSixShow ? 'widthDisplayPaneFive' : 'fullWidth'}
                  //FilterModeEnable={navigatorIcon}
                  FilterModeEnable={false}
                  FilterMode={FilterMode}
                  onClick={onClickFooter}
                  primaryIcon={secondaryIcon}
                  secondaryIcon={secondaryIcon}
                  //isAssessmentPreviewShow={isAssessmentPreviewShow}
                  isAssessmentPreviewShow={true}
                />
              ))}
          </>
        ) : isAssessmentSectionShow ? (
          <>
            <HeaderCard
              className=""
              displayPane="itemPreview"
              showClearIcon
              headerOne={headerOne}
              headerOneBadgeOne={'section'}
              headerOneBadgeTwo=""
              //headerPanelColour="blue"
              headerPanelColour="green"
              onClickClearInfo={closePreview}
            />
            <DisplayPaneFiveAssessment
              headerOne={headerOne}
              //closePreview={closePreview}
              handleRadioButton={handleRadioButton}
              informationFramework={responseObject}
              currentItemIndex={currentItemIndex}
              currentSectionIndex={currentSectionIndex}
              currentVersionIndex={currentVersionIndex}
              itemObect={itemObect}
              responseAlignment={
                (assessmentResponseObject?.informationFramework
                  ?.assessmentAdministrationTemplateAlignment
                  ?.assessmentItemFrameworkOneResponseChoiceAlignment &&
                  assessmentResponseObject?.informationFramework
                    ?.assessmentAdministrationTemplateAlignment
                    ?.assessmentItemFrameworkOneResponseChoiceAlignment) ||
                (assessmentInfo?.informationFramework?.assessmentAdministrationTemplateAlignment
                  ?.assessmentItemFrameworkOneResponseChoiceAlignment &&
                  assessmentInfo?.informationFramework?.assessmentAdministrationTemplateAlignment
                    ?.assessmentItemFrameworkOneResponseChoiceAlignment) ||
                (itemObect?.itemFrameworkOneResponseChoiceAlignment &&
                  itemObect?.itemFrameworkOneResponseChoiceAlignment) ||
                ''
              }
              itemFrameworkOneResponseChoiceSequenceAdministration={
                sectionInformation?.assessmentSectionItemDistinctReviseObject?.itemFrameworkOne
                  ?.itemFrameworkOneResponseChoiceSequenceAdministration || []
              }
              assessmentSectionItemFrameworkOneLabel={
                assesseeAssessmentStartData?.assessmentSectionItemFrameworkOneLabel
              }
              assessmentSectionItemFrameworkOneResponseLabel={
                assesseeAssessmentStartData?.assessmentSectionItemFrameworkOneResponseLabel
              }
            />
            {(reviewMode === 'revise' && assesseeAssessmentStartData && (
              <FooterIconTwo
                className={'widthDisplayPaneFive'}
                //FilterModeEnable={isShowReviseIcon}
                FilterModeEnable={false}
                FilterMode={FilterMode}
                onClick={onClickRevise}
                primaryIcon={revisePrimaryIcon}
                secondaryIcon={reviseSecondaryIcons}
                isAssessmentPreviewShow={true}
              />
            )) ||
              (assesseeAssessmentStartData && (
                <FooterIconTwo
                  className={isDisplayPaneSixShow ? 'widthDisplayPaneFive' : 'fullWidth'}
                  FilterModeEnable={false}
                  //FilterModeEnable={navigatorIcon}
                  FilterMode={FilterMode}
                  onClick={onClickFooter}
                  primaryIcon={primaryIcon}
                  secondaryIcon={secondaryIcon}
                  isAssessmentPreviewShow={true}
                />
              ))}
          </>
        ) : isItemPreviewShow ? (
          <DisplayPaneFiveItem />
        ) : isAssessmentVersionShow ? (
          <>
            <HeaderCard
              className=""
              displayPane="itemPreview"
              showClearIcon
              headerOne={'assessment'}
              headerOneBadgeOne={'version'}
              headerOneBadgeTwo=""
              headerPanelColour="green"
              onClickClearInfo={closePreview}
            />
            <DisplayPaneFiveAssessment
              headerOne={'assessment'}
              handleRadioButton={handleRadioButton}
              informationFramework={responseObject}
              currentItemIndex={currentItemIndex}
              currentSectionIndex={currentSectionIndex}
              currentItemResponse={itemListArray?.length > 0 && itemListArray[currentItemIndex]}
              itemObect={itemObect}
              responseAlignment={
                (assessmentResponseObject?.informationFramework
                  ?.assessmentAdministrationTemplateAlignment
                  ?.assessmentItemFrameworkOneResponseChoiceAlignment &&
                  assessmentResponseObject?.informationFramework
                    ?.assessmentAdministrationTemplateAlignment
                    ?.assessmentItemFrameworkOneResponseChoiceAlignment) ||
                (assessmentInfo?.informationFramework?.assessmentAdministrationTemplateAlignment
                  ?.assessmentItemFrameworkOneResponseChoiceAlignment &&
                  assessmentInfo?.informationFramework?.assessmentAdministrationTemplateAlignment
                    ?.assessmentItemFrameworkOneResponseChoiceAlignment) ||
                (itemObect?.itemFrameworkOneResponseChoiceAlignment &&
                  itemObect?.itemFrameworkOneResponseChoiceAlignment) ||
                ''
              }
              assessmentSectionItemFrameworkOneLabel={
                assessmentResponseObject?.informationFramework?.assessmentSectionFramework[
                  assessmentSelecedSection
                ]?.assessmentSectionItemFrameworkOneLabel
              }
              assessmentSectionItemFrameworkOneResponseLabel={
                assessmentResponseObject?.informationFramework?.assessmentSectionFramework[
                  assessmentSelecedSection
                ]?.assessmentSectionItemFrameworkOneResponseLabel
              }
            />
            {reviewMode === 'revise' ? (
              <FooterIconTwo
                className={isDisplayPaneSixShow ? 'widthDisplayPaneFive' : 'fullWidth'}
                FilterModeEnable={false}
                //FilterModeEnable={isShowReviseIcon}
                FilterMode={FilterMode}
                onClick={onClickRevise}
                primaryIcon={revisePrimaryIcon}
                secondaryIcon={reviseSecondaryIcons}
                //isAssessmentPreviewShow={isAssessmentPreviewShow}
                isAssessmentPreviewShow={true}
              />
            ) : (
              <FooterIconTwo
                className={isDisplayPaneSixShow ? 'widthDisplayPaneFive' : 'fullWidth'}
                FilterModeEnable={false}
                //FilterModeEnable={navigatorIcon}
                FilterMode={FilterMode}
                onClick={onClickFooter}
                primaryIcon={primaryIcon}
                secondaryIcon={secondaryIcon}
                //isAssessmentPreviewShow={isAssessmentPreviewShow}
                isAssessmentPreviewShow={true}
              />
            )}
          </>
        ) : null}
        <div className={'containerPadding'} style={{ height: '55px' }}>
          {' '}
        </div>
      </div>
    </>
  );
};

export default DisplayPaneFive;
