import React from 'react';
import { isMobile } from 'react-device-detect';
// import AllocationAccordian from '../Accordian/AllocationAccordian';
// import Manuscript from '@material-ui/icons/Description';
import { useDispatch, useSelector } from 'react-redux';
import DisplayPanelAccordianReviewListOne from '../Accordian/DisplayPanelAccordianReviewListOne';
import DisplayPanelAccordianInformation from '../Accordian/DisplayPanelAccordianInformation';
import { Paper } from '@material-ui/core';
import {
  FILTERMODE,
  LOADER_START,
  RELATED_REVIEWLIST_DISTINCT_DATA,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MIDDLEPANE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_PAGE_COUNT,
  GET_ALLOCATE_ASSOCIATE
} from '../../actionType';
import {
  getAssociateDistinctApiCall,
  getAssociateDistinctToAllocate,
  getAssociateGroupAssociateDistinctApiCall
} from '../../Actions/AssociateModuleAction';
import {
  makeAssociateReviewListRequestObject
} from '../../Actions/GenericActions';

const DisplayPaneThreeSectionTwoAssociateGroup = () => {
  // const [listExpand, setListExpand] = useState('');
  const { reviewMode, relatedReviewListPaneThree, responseObject } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  const {
    countPage,
    selectedAssociateInfo
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const dispatch = useDispatch();
  console.log('ASSOCIATE LIST ::::::::::::>', relatedReviewListPaneThree);
  // const { informationEngagement, informationSetup } = responseObject;
  // function capitalizeFirstLetter(string) {
  //   if (!string) return '';
  //   return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  // }
  let associate = [];
  if (relatedReviewListPaneThree) {
    associate = relatedReviewListPaneThree?.associate;
  }
  console.log('relatedReviewListPaneThreeAS', relatedReviewListPaneThree);
  let associateArray = [];
  associate &&
    associate.forEach((ob) => {
      const { id, informationBasic, informationEngagement, associateId } = ob;
      associateArray.push({
        id,
        textOne: informationBasic?.associateName || '',
        textTwo: informationBasic?.associateDescription || '',
        status: informationEngagement?.associateStatus || '',
        isShared: ob?.associateShared,
        associateId
      });
    });

  const list2 = [
    {
      id: 'a1',
      labelTextOneOne: 'associates',
      labelTextOneOneBadgeOne: '',
      labelTextOneOneBadgeTwo: '',
      labelTextOneOneBadgeThree: '',
      labelTextOneOneBadgeFour: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'distinct',
          innerList: associateArray
        }
      ],
      innerInfo: 'No Information',
      isListCard: true,
      isReviewLink: true
    }
  ];

  const onclickReviewAssessee = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    console.log('Associate review CLICK :::::::>>>>>>>', labelName);
    if (labelName === 'associates' && selectedBadgeName === 'distinct') {
      // dispatch({
      //   type: RELATED_REVIEWLIST_DISTINCT_DATA,
      //   payload: relatedReviewListPaneThree
      // });
      // dispatch({
      //   type: SET_MIDDLEPANE_STATE,
      //   payload: {
      //     middlePaneHeader: middlePaneHeaderDuplicate,
      //     middlePaneHeaderBadgeOne: middlePaneHeaderDuplicateBadgeOne,
      //     middlePaneHeaderBadgeTwo: middlePaneHeaderDuplicateBadgeTwo,
      //     middlePaneHeaderBadgeThree: middlePaneHeaderDuplicateBadgeThree,
      //     middlePaneHeaderDuplicate: middlePaneHeaderDuplicate,
      //     middlePaneHeaderDuplicateBadgeOne: middlePaneHeaderDuplicateBadgeOne,
      //     middlePaneHeaderDuplicateBadgeTwo: middlePaneHeaderDuplicateBadgeTwo,
      //     middlePaneHeaderDuplicateBadgeThree: middlePaneHeaderDuplicateBadgeThree,
      //     middlePaneHeaderDuplicateBadgeFour: middlePaneHeaderDuplicateBadgeFour,
      //     typeOfMiddlePaneList: 'associatesGroupAssociateReviewList',
      //     scanCount: relatedReviewListPaneThree.length>0 && relatedReviewListPaneThree[0].associate.length,
      //     showMiddlePaneState: true
      //   }
      // });
      getAssociateGroupAssociateDistinctApiCall(
        selectedAssociateInfo,
        '',
        //'all',
        -1,
        dispatch,
        'distinct',
        responseObject.id, //group id
        '',
        false,
        true
      );
    }
  };
  const onclickReviseAssessee = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    if (labelName === 'associates' && selectedBadgeName === 'distinct') {

      let requestObect = makeAssociateReviewListRequestObject(
        selectedAssociateInfo,
        '',
        //'activeinactive',
        0,
        countPage
      );
      let revisedGroupObject = {
        id: responseObject.id,
        associateGroupName: responseObject.informationBasic.associateGroupName,
        associateGroupDescription: responseObject.informationBasic.associateGroupDescription,
        associateGroupStatus: responseObject.informationEngagement.associateGroupStatus
      };
      let existingAssesseeId = [];
      if (relatedReviewListPaneThree) {
        existingAssesseeId =
          relatedReviewListPaneThree?.associate?.length > 0 &&
          relatedReviewListPaneThree?.associate?.map((val) => {
            return val.informationEngagement.associateTag.associateTagPrimary;
          });
      }
      console.log('existingAssesseeId', existingAssesseeId);
      getAssociateDistinctToAllocate(
        dispatch,
        requestObect,
        revisedGroupObject,
        (existingAssesseeId && existingAssesseeId) || [],
        'associateGroupAssociateRevise',
        'associatesGroupAssociateReviewList'
      );
      dispatch({ type: SET_PAGE_COUNT, payload: 0 });
    }
  };

  return (
    <div>
      <>
        <div className={'containerPadding'}>
          <Paper className={'dossierContainerTop'}>
            {list2.map((ob) => {
              return (
                <div key={ob.id}>
                  {ob.isListCard ? (
                    <DisplayPanelAccordianReviewListOne
                      onClickReview={onclickReviewAssessee}
                      onClickRevise={onclickReviseAssessee}
                      className=""
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  ) : (
                    <DisplayPanelAccordianInformation accordianObject={ob} mode={reviewMode} />
                  )}
                </div>
              );
            })}
          </Paper>
        </div>
      </>
      {isMobile && (
        <div className={'containerPadding'} style={{ height: '55px' }}>
          {' '}
        </div>
      )}
    </div>
  );
};

export default DisplayPaneThreeSectionTwoAssociateGroup;
