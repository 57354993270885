const CountDownReducer = (state = { hours: 0, minutes: 0, seconds: 0, completed: false, running: false, intervalId: null }, action) => {
  // console.log(action, 'ACTION')
  switch (action.type) {
    case 'START_COUNTDOWN':
      return {
        ...state,
        // hours: Math.floor(action.payload / 3600),
        // minutes: Math.floor((action.payload % 3600) / 60),
        // seconds: Math.floor((action.payload % 3600) % 60),
        hours: parseInt(action.payload / 3600),
        minutes: parseInt(action.payload / 60, 10),
        seconds: parseInt(action.payload % 60, 10),
        running: true,
        completed: false,
      };
    case 'STOP_COUNTDOWN':
      // console.log(state.intervalId, 'INTERVALID17')
      clearInterval(state.intervalId)
      return {
        ...state,
        running: false,
      };
    case 'SET_COMPLETED':
      // console.log(state.intervalId, 'INTERVALID')
      clearInterval(state.intervalId)
      return {
        ...state,
        running: false,
        completed: true,
      };
    case 'UPDATE_COUNTDOWN':
      if (!state.running) {
        return state; // Don't update the countdown if it's not running
      }
      const { hours, minutes, seconds } = state;
      let updatedSeconds = seconds - 1;
      let updatedMinutes = minutes;
      let updatedHours = hours;

      if (updatedSeconds < 0) {
        updatedSeconds = 59;
        updatedMinutes -= 1;
      }
      if (updatedMinutes < 0) {
        updatedMinutes = 59;
        updatedHours -= 1;
      }

      return { ...state, hours: updatedHours, minutes: updatedMinutes, seconds: updatedSeconds };
    case 'SET_INTERVAL_ID':
      return { ...state, intervalId: action.payload }
    default:
      return state;
  }
};

export default CountDownReducer;
