import React from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import DisplayPanelAccordianReviewListOne from '../Accordian/DisplayPanelAccordianReviewListOne';
import DisplayPanelAccordianInformation from '../Accordian/DisplayPanelAccordianInformation';
import { Paper } from '@material-ui/core';
import { SET_ASSESSEE_ROLE_ASSESSEE_ID_LIST, SET_MIDDLEPANE_STATE } from '../../actionType';
import {
  getAssesseeeDistinctToAllocate,
  getAssesseeRoleAssesseeDistinctApiCall,
  getAssesseeRoleAssesseeReqObj
} from '../../Actions/AssesseeModuleAction';
import { makeAssesseeReviewListRequestObject } from '../../Actions/GenericActions';

const DisplayPaneThreeSectionTwoAssesseeRole = () => {
  const dispatch = useDispatch();
  const {
    headerOne,
    reviewMode,
    relatedReviewListPaneThree = null,
    responseObject
  } = useSelector((state) => state.DisplayPaneThreeReducer);
  const {
    selectedAssociateInfo,
    countPage,
    middlePaneHeaderDuplicate,
    middlePaneHeaderDuplicateBadgeOne,
    middlePaneHeaderDuplicateBadgeTwo,
    middlePaneHeaderDuplicateBadgeThree,
    middlePaneHeaderDuplicateBadgeFour
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  console.log('ASSESSEE LIST ::::::::::::>', relatedReviewListPaneThree);
  let assessee = [];
  if (relatedReviewListPaneThree && relatedReviewListPaneThree.length > 0) {
    assessee = relatedReviewListPaneThree[0]?.assessee || [];
  }
  let assesseeArray = [];
  assessee.forEach((ob) => {
    const { id, informationBasic, informationEngagement, associateId } = ob;
    assesseeArray.push({
      id,
      textOne: `${informationBasic.assesseeNameFirst} ${informationBasic.assesseeNameOther} ${informationBasic.assesseeNameLast}`,
      textTwo: informationBasic.assesseeAlias || '',
      status: informationEngagement.assesseeStatus || '',
      isShared: ob?.assesseeShared,
      associateId
    });
  });

  const list2 = [
    {
      id: 'Rolea1',
      labelTextOneOne: headerOne,
      labelTextOneOneBadgeOne: '',
      labelTextOneOneBadgeTwo: '',
      labelTextOneOneBadgeThree: '',
      labelTextOneOneBadgeFour: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'distinct',
          innerList: assesseeArray
        }
      ],
      innerInfo: 'No Information',
      isListCard: true,
      isReviewLink: true
    }
  ];

  const onclickReviseAssessee = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    if (
      (labelName === 'assessees' || labelName === 'administrators' || labelName === 'managers') &&
      selectedBadgeName === 'distinct'
    ) {
      console.log('ASSESSEE CLICK :::::::>>>>>>>', relatedReviewListPaneThree);
      let roleRequestObj
      if (reviewMode === 'revise') {
        roleRequestObj = makeAssesseeReviewListRequestObject(
          selectedAssociateInfo,
          '',
          0,
          countPage
        );
      } else {
        roleRequestObj = getAssesseeRoleAssesseeReqObj(
          selectedAssociateInfo,
          responseObject.id,
          '',
          0,
          countPage
        )

      }
      let revisedRoleObject = {
        id: responseObject.id,
        assesseeRoleName: responseObject.informationBasic.assesseeRoleName,
        assesseeRoleDescription: responseObject.informationBasic.assesseeRoleDescription,
        assesseeRoleStatus: responseObject.informationEngagement.assesseeRoleStatus
      };
      let existingAssesseeId = [];
      let tempArr = relatedReviewListPaneThree[0]?.assessee || [];
      existingAssesseeId = tempArr.map((val) => {
        return val.id;
      });
      getAssesseeeDistinctToAllocate(
        dispatch,
        revisedRoleObject,
        roleRequestObj,
        existingAssesseeId,
        'assesseeRoleAssesseeRevise',
        'assesseesRoleAssesseeReviewList'
      );
    }
  };
  const onclickReviewAssessee = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    console.log('ASSESSEE CLICK :::::::>>>>>>>', labelName, selectedBadgeName);
    if (
      (labelName === 'assessees' || labelName === 'administrators' || labelName === 'managers') &&
      selectedBadgeName === 'distinct') {
      getAssesseeRoleAssesseeDistinctApiCall(
        selectedAssociateInfo,
        '',
        countPage,
        dispatch,
        'distinct',
        responseObject.id,
        '',
        false,
        labelName
      );
      // let result = assesseeArray.map((a) => a.id);
      // console.log('RESULT++++', result);
      // dispatch({ type: SET_ASSESSEE_ROLE_ASSESSEE_ID_LIST, payload: result });
      // dispatch({
      //   type: SET_MIDDLEPANE_STATE,
      //   payload: {
      //     middlePaneHeader: labelName,
      //     middlePaneHeaderBadgeOne: 'distinct',
      //     middlePaneHeaderBadgeTwo: 'all',
      //     middlePaneHeaderBadgeThree: '',
      //     middlePaneHeaderBadgeFour: '',
      //     middlePaneHeaderDuplicate: middlePaneHeaderDuplicate,
      //     middlePaneHeaderDuplicateBadgeOne: middlePaneHeaderDuplicateBadgeOne,
      //     middlePaneHeaderDuplicateBadgeTwo: middlePaneHeaderDuplicateBadgeTwo,
      //     middlePaneHeaderDuplicateBadgeThree: middlePaneHeaderDuplicateBadgeThree,
      //     middlePaneHeaderDuplicateBadgeFour: middlePaneHeaderDuplicateBadgeFour,
      //     typeOfMiddlePaneList: 'assesseesRoleAssesseeReviewList',
      //     scanCount: 5,
      //     showMiddlePaneState: true
      //   }
      // });
    }
  };

  return (
    <div>
      <>
        <div className={'containerPadding'}>
          <Paper className={'dossierContainerTop'}>
            {list2.map((ob) => {
              return (
                <div key={ob.id}>
                  {ob.isListCard ? (
                    <DisplayPanelAccordianReviewListOne
                      onClickReview={onclickReviewAssessee}
                      onClickRevise={onclickReviseAssessee}
                      className=""
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  ) : (
                    <DisplayPanelAccordianInformation accordianObject={ob} mode={reviewMode} />
                  )}
                </div>
              );
            })}
          </Paper>
        </div>
      </>
      {isMobile && (
        <div className={'containerPadding'} style={{ height: '55px' }}>
          {' '}
        </div>
      )}
    </div>
  );
};

export default DisplayPaneThreeSectionTwoAssesseeRole;
