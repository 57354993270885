import {
  ASSESSEE_ASSESSMENT_FINISH_SAGA,
  LOADER_START,
  SET_ASSESSEE_ASSESSMENT_ITEM_RES_SAGA,
  SET_POPUP_SINGLE_STATE
} from '../actionType';

export const setAssesseeAssessmentItemSaveResCall = (
  selectedAssociateInfo,
  dispatch,
  assesseeAssessmentStartData,
  itemId,
  assesseeId,
  currentQuestionChoice,
  itemTimeStart,
  itemFlaged,
  currentSectionItem,
  responseData
) => {
  let ItemObj = {
    assesseeId: selectedAssociateInfo?.assesseeId,
    assesseeAssignmentId: assesseeAssessmentStartData.assignmentId,
    assesseeAssignmentAssessmentId: assesseeAssessmentStartData.assessmentId,
    assesseeAssignmentAssessmentItemId: itemId,
    assesseeAssignmentAssessmentItemExplanation: '',
    assesseeAssignmentAssessmentItemResponseChoiceSelected: currentQuestionChoice,
    assesseeAssignmentAssessmentItemStatus: '',
    assesseeAssignmentAssessmentItemTimeline: {
      assesseeAssignmentAssessmentItemTimelineDateTimeStart: itemTimeStart,
      assesseeAssignmentAssessmentItemTimelineDateTimeEnd: new Date().getTime()
    },
    assesseeAssignmentAssessmentItemFlagged: itemFlaged,
    assesseeAssignmentAssessmentItemSection: currentSectionItem,
    assesseeAssignmentAssessmentItemResponse: responseData
  };
  if (navigator.onLine) {
    console.log('Became online');
    let reqObj;
    if (JSON.parse(localStorage.getItem('assessmentItem'))?.length === 0) {
      reqObj = {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        assessmentItem: [ItemObj]
      };
    } else {
      let ans = JSON.parse(localStorage.getItem('assessmentItem'));
      console.log('ans', ans);
      ans.push(ItemObj);
      reqObj = {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        assessmentItem: ans
      };
    }
    dispatch({
      type: SET_ASSESSEE_ASSESSMENT_ITEM_RES_SAGA,
      payload: { request: reqObj }
    });
    localStorage.setItem('assessmentItem', '[]');
  } else {
    console.log('Became offline');
    let ans = JSON.parse(localStorage.getItem('assessmentItem'));
    ans.push(ItemObj);
    localStorage.setItem('assessmentItem', JSON.stringify(ans));
  }
  let responseSetInLocal = JSON.parse(localStorage.getItem('navigationItem')) || [];
  console.log(responseSetInLocal);
  if (responseSetInLocal.length > 0) {
    let find = responseSetInLocal.find((v) => v.assesseeAssignmentAssessmentItemId === itemId);
    find
      ? (find.assesseeAssignmentAssessmentItemResponseChoiceSelected = currentQuestionChoice)
      : responseSetInLocal.push(ItemObj);

    console.log('find', find);
  } else {
    responseSetInLocal.push(ItemObj);
  }

  localStorage.setItem('navigationItem', JSON.stringify(responseSetInLocal));
};

export const assesseeAssessmentFinihed = (
  dispatch,
  selectedAssociateInfo,
  assesseeAssignmentAssessmentData,
  assessmentStatus,
  assesseeAssignmentAssessmentSectionAttemptedDateTimeStart
) => {
  dispatch({ type: LOADER_START });
  if (JSON.parse(localStorage.getItem('assessmentItem'))?.length > 0) {
    let reqObj = {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      assessmentItem: JSON.parse(localStorage.getItem('assessmentItem'))
    };
    console.log(JSON.parse(localStorage.getItem('assessmentItem')));
    dispatch({
      type: SET_ASSESSEE_ASSESSMENT_ITEM_RES_SAGA,
      payload: { request: reqObj }
    });
  }
  let attemptEndTime = new Date().getTime();
  let reqObject = {
    assesseeId: selectedAssociateInfo?.assesseeId,
    associateId:
      selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
    assignmentId: assesseeAssignmentAssessmentData.assignmentId,
    assessmentId: assesseeAssignmentAssessmentData.assessmentId,
    assesseeAssignmentAssessmentStatus: assessmentStatus,
    attemptEndTime: attemptEndTime,
    assesseeAssignmentAssessmentAttempted: {
      assesseeAssignmentAssessmentAttemptedDateTimeEnd: attemptEndTime,
      assesseeAssignmentAssessmentAttemptedDateTimeStart:
        assesseeAssignmentAssessmentSectionAttemptedDateTimeStart
    }
  };
  dispatch({
    type: SET_POPUP_SINGLE_STATE,
    payload: { stateName: 'headerButtons', value: true }
  });
  dispatch({
    type: ASSESSEE_ASSESSMENT_FINISH_SAGA,
    payload: { request: reqObject }
  });
};
