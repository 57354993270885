import React from 'react';
import PropTypes from 'prop-types';
import './InputField.css';
import { Input, InputLabel, FormControl, FormHelperText } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
export const InputFieldEndAdornment = (props) => {
  const {
    id,
    label,
    type = 'text',
    errorMsg = '',
    onClick,
    value,
    labelBadgeOne,
    isRequired = true,
    autoFocus = false,
    classNames = '',
    isErrorMsg = true,
    currencySymbol = '',
    endAdornment = 'empty',
    issetTime = false,
    isEndAdornment = false,
    endText = ''
  } = props;
  let attributes = {
    ...(type === 'datetime-local' && { min: new Date().toISOString().slice(0, 16) })
  };
  console.log('endAdornment', endAdornment);
  return (
    <div className="popup-form-box">
      <FormControl style={{ width: '100%' }}>
        <InputLabel
          htmlFor={id}
          style={{ fontSize: '1.6rem' }}
          {...(type === 'datetime-local' && { shrink: true })}
        >
          {label} &nbsp;
          {labelBadgeOne ? <span className={'labelheaderBadge'}>{labelBadgeOne}</span> : null}
        </InputLabel>
        <Input
          type={type}
          id={id}
          name={id}
          value={value}
          onChange={onClick}
          autoComplete="off"
          autoFocus={autoFocus}
          error={value < 0 || (endAdornment.toLowerCase().includes('month') && !value.toString().match(/^[1-9]\d*$/))}
          className={['inputFields', classNames].join(' ')}
          endAdornment={
            <InputAdornment
              position="end"
              style={{ paddingRight: '5px',paddingLeft: '5px' }}
              className={'inputFieldsAdorment'}
            >
              {endAdornment}
            </InputAdornment>
          }
          inputProps = {(endAdornment?.toLowerCase()?.includes('month') && type ==='number' && {min:0, max: 24, onKeyDown: e => ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault()
        })}
        />
      </FormControl>
      {isRequired && (
        <FormHelperText
          className={[isErrorMsg ? 'helperText' : 'helperTextDefault', 'helptextmargin'].join(' ')}
        >
          <span>{errorMsg}</span>
        </FormHelperText>
      )}
    </div>
  );
};

InputFieldEndAdornment.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.oneOf(['text', 'password', 'datetime-local', 'date', 'tel', 'number'])
};

export default InputFieldEndAdornment;
