import { put, takeLatest, call } from 'redux-saga/effects';
import Store from '../../store';
import {
  CREATE_ASSIGNMENT_SAGA,
  LOADER_STOP,
  POPUP_CLOSE,
  SET_ASSIGNMENT_BASIC_REDUCER_STATE,
  SET_ASSIGNMENT_DYNAMIC_SETUP_STATE,
  SET_ASSIGNMENT_DYNAMIC_SINGLE_STATE,
  SET_ASSIGNMENT_FRAMEWORK_STATE,
  SET_ASSIGNMENT_RELATED_LIST,
  SET_DISPLAY_PANE_THREE_STATE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_POPUP_SINGLE_STATE,
  SET_POPUP_VALUE
} from '../../actionType';
import { ASSIGNMENT_MODULE_URL } from '../../endpoints';
import { EXCEPTION_ERROR_MESSAGE, INFORMATION_EXIST_ERROR_MESSAGE } from '../../errorMessage';

const createAssignmentApi = async (requestObj) => {
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      Authorization: localStorage.getItem('token')
    }),
    body: JSON.stringify(requestObj.data)
  };
  const response = await fetch(requestObj.URL, requestOptions);
  const json = await response.json();
  return json;
};

function* workerCreateAssignmentSaga(data) {
  try {
    const apiResponse = yield call(createAssignmentApi, {
      URL: ASSIGNMENT_MODULE_URL,
      data: data.payload.reqBody
    });
    if (apiResponse.responseCode === '000') {
      yield put({
        type: SET_DISPLAY_PANE_THREE_STATE,
        payload: {
          headerOne: 'assignment',
          headerOneBadgeOne: 'distinct',
          headerOneBadgeTwo: 'information',
          headerOneBadgeThree: Store.getState().DisplayPaneTwoReducer.selectedInformationAllorKey,
          responseObject: apiResponse.responseObject[0],
          reviewMode: 'revise',
          createMode: 'assignment'
        }
      });
      let PopupOptionArr = [...Store.getState().DisplayPaneTwoReducer.middlePaneListPopupOptions];
      let stats = apiResponse.responseObject[0]?.informationEngagement?.assignmentStatus;
      let isFlaged = apiResponse.responseObject[0]?.informationBasic?.assignmentFlag;
      let tempArr = [];
      PopupOptionArr.forEach((element) => {
        if (
          stats === 'UNPUBLISHED' &&
          (element.data === 'administer' || element.data === 'reset')
        ) {
          tempArr.push({ ...element, disabled: true });
        } else tempArr.push(element);
      });
      if (data.payload.assignmentCreateType !== 'iGauge Occupation') {
        delete tempArr[17];
        delete tempArr[18];
      }
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: {
          stateName: 'middlePaneListPopupOptions',
          value: tempArr
        }
      });
      yield put({
        type: SET_POPUP_SINGLE_STATE,
        payload: {
          stateName: 'originPrimaryArrOprion',
          value: tempArr
        }
      });
      yield put({
        type: SET_POPUP_SINGLE_STATE,
        payload: {
          stateName: 'selectedTagStatus',
          value: stats
        }
      });
      yield put({
        type: SET_POPUP_SINGLE_STATE,
        payload: {
          stateName: 'isFlaged',
          value: isFlaged
        }
      });

      const { informationAllocation, informationBasic, informationFramework, informationSetup } =
        apiResponse.responseObject[0];
      yield put({
        type: SET_ASSIGNMENT_FRAMEWORK_STATE,
        payload: informationFramework
      });
      yield put({
        type: SET_ASSIGNMENT_DYNAMIC_SETUP_STATE,
        payload: informationFramework
      });
      yield put({
        type: SET_ASSIGNMENT_DYNAMIC_SETUP_STATE,
        payload: informationSetup
      });
      if (
        informationAllocation &&
        informationAllocation?.assignmentGroup?.assignmentGroupPrimary &&
        informationAllocation?.assignmentGroup?.assignmentGroupPrimary.length > 0
      ) {
        let tempArr = informationAllocation.assignmentGroup.assignmentGroupPrimary.map(
          (ob) => ob.id
        );
        yield put({
          type: SET_ASSIGNMENT_DYNAMIC_SINGLE_STATE,
          payload: {
            stateName: 'assignmentGroup',
            actualStateName: 'assignmentGroupPrimary',
            value: tempArr
          }
        });
      } else {
        yield put({
          type: SET_ASSIGNMENT_DYNAMIC_SINGLE_STATE,
          payload: {
            stateName: 'assignmentGroup',
            actualStateName: 'assignmentGroupPrimary',
            value: []
          }
        });
      }
      if (
        informationAllocation &&
        informationAllocation?.assignmentGroup?.assignmentGroupSecondary &&
        informationAllocation?.assignmentGroup?.assignmentGroupSecondary.length > 0
      ) {
        let tempArr = informationAllocation.assignmentGroup.assignmentGroupSecondary.map(
          (ob) => ob.id
        );
        yield put({
          type: SET_ASSIGNMENT_DYNAMIC_SINGLE_STATE,
          payload: {
            stateName: 'assignmentGroup',
            actualStateName: 'assignmentGroupSecondary',
            value: tempArr
          }
        });
      } else {
        yield put({
          type: SET_ASSIGNMENT_DYNAMIC_SINGLE_STATE,
          payload: {
            stateName: 'assignmentGroup',
            actualStateName: 'assignmentGroupSecondary',
            value: []
          }
        });
      }
      if (
        informationAllocation &&
        informationAllocation?.assignmentNode?.assignmentNodePrimary &&
        informationAllocation?.assignmentNode?.assignmentNodePrimary.length > 0
      ) {
        let tempArr = informationAllocation.assignmentNode.assignmentNodePrimary.map((ob) => ob.id);
        yield put({
          type: SET_ASSIGNMENT_DYNAMIC_SINGLE_STATE,
          payload: {
            stateName: 'assignmentNode',
            actualStateName: 'assignmentNodePrimary',
            value: tempArr
          }
        });
      } else {
        yield put({
          type: SET_ASSIGNMENT_DYNAMIC_SINGLE_STATE,
          payload: {
            stateName: 'assignmentNode',
            actualStateName: 'assignmentNodePrimary',
            value: []
          }
        });
      }
      if (
        informationAllocation &&
        informationAllocation?.assignmentNode?.assignmentNodeSecondary &&
        informationAllocation?.assignmentNode?.assignmentNodeSecondary.length > 0
      ) {
        let tempArr = informationAllocation.assignmentNode.assignmentNodeSecondary.map(
          (ob) => ob.id
        );
        yield put({
          type: SET_ASSIGNMENT_DYNAMIC_SINGLE_STATE,
          payload: {
            stateName: 'assignmentNode',
            actualStateName: 'assignmentNodeSecondary',
            value: tempArr
          }
        });
      } else {
        yield put({
          type: SET_ASSIGNMENT_DYNAMIC_SINGLE_STATE,
          payload: {
            stateName: 'assignmentNode',
            actualStateName: 'assignmentNodeSecondary',
            value: []
          }
        });
      }
      if (
        informationAllocation &&
        informationAllocation?.assignmentType?.assignmentTypePrimary &&
        informationAllocation?.assignmentType?.assignmentTypePrimary.length > 0
      ) {
        let tempArr = informationAllocation.assignmentType.assignmentTypePrimary.map((ob) => ob.id);
        yield put({
          type: SET_ASSIGNMENT_DYNAMIC_SINGLE_STATE,
          payload: {
            stateName: 'assignmentType',
            actualStateName: 'assignmentTypePrimary',
            value: tempArr
          }
        });
      } else {
        yield put({
          type: SET_ASSIGNMENT_DYNAMIC_SINGLE_STATE,
          payload: {
            stateName: 'assignmentType',
            actualStateName: 'assignmentTypePrimary',
            value: []
          }
        });
      }
      if (
        informationAllocation &&
        informationAllocation?.assignmentType?.assignmentTypeSecondary &&
        informationAllocation?.assignmentType?.assignmentTypeSecondary.length > 0
      ) {
        let tempArr = informationAllocation.assignmentType.assignmentTypeSecondary.map(
          (ob) => ob.id
        );
        yield put({
          type: SET_ASSIGNMENT_DYNAMIC_SINGLE_STATE,
          payload: {
            stateName: 'assignmentType',
            actualStateName: 'assignmentTypeSecondary',
            value: tempArr
          }
        });
      } else {
        yield put({
          type: SET_ASSIGNMENT_DYNAMIC_SINGLE_STATE,
          payload: {
            stateName: 'assignmentType',
            actualStateName: 'assignmentTypeSecondary',
            value: []
          }
        });
      }
      let assignmentAssessee =
        apiResponse.responseObject[0]?.informationFramework?.assignmentAssessee || [];
      let assignmentAssessment =
        apiResponse.responseObject[0]?.informationFramework?.assignmentAssessment || [];
      let assignmentCultureProfile =
        apiResponse.responseObject[0]?.informationFramework?.assignmentCultureProfile || [];
      let assignmentJobProfile =
        apiResponse.responseObject[0]?.informationFramework?.assignmentJobProfile || [];
      yield put({
        type: SET_ASSIGNMENT_RELATED_LIST,
        payload: { listName: 'assignmentAssesseeList', value: assignmentAssessee }
      });
      yield put({
        type: SET_ASSIGNMENT_RELATED_LIST,
        payload: { listName: 'assignmentAssessmentList', value: assignmentAssessment }
      });
      yield put({
        type: SET_ASSIGNMENT_RELATED_LIST,
        payload: { listName: 'assignmentCultureProfileList', value: assignmentCultureProfile }
      });
      yield put({
        type: SET_ASSIGNMENT_RELATED_LIST,
        payload: { listName: 'assignmentJobProfileList', value: assignmentJobProfile }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'middlePaneSelectedValue', value: apiResponse.responseObject[0].id }
      });
      yield put({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'selectedTagValue', value: apiResponse.responseObject[0].id }
      });
      yield put({ type: LOADER_STOP });
      yield put({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
      yield put({ type: POPUP_CLOSE });
    } else if (apiResponse.responseCode === '018') {
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'NAMEPOPUP',
          popupMode: `ASSIGNMENTCREATE`,
          // popupMode: `JOBCREATE`,
          popupErrorMsg: INFORMATION_EXIST_ERROR_MESSAGE
        }
      });
    } else {
      yield put({ type: POPUP_CLOSE });
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: apiResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}

export default function* watchCreateAssignmentSaga() {
  yield takeLatest(CREATE_ASSIGNMENT_SAGA, workerCreateAssignmentSaga);
}
