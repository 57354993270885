import React, { useEffect, useMemo, useState } from 'react';
import CrossIcon from '@material-ui/icons/Clear';
import './DisplayPaneSix.css';
import DisplayPaneSixHeader from './DisplayPaneSixHeader';
import { useDispatch, useSelector } from 'react-redux';
import FooterIconTwo from '../../Molecules/FooterIcon/FooterIconTwo';
import ArrowRight from '@material-ui/icons/ChevronRight';
import ArrowLeft from '@material-ui/icons/ChevronLeft';
import CodeIcon from '@material-ui/icons/Code';
import {
  POPUP_OPEN,
  SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
  SET_POPUP_STATE,
  SET_ASSESSEE_ASSESSMENT_COMMUNIQUE_STATE,
  SET_ASSESSEE_ASSESSMENT_MENUSCRIPT_STATE,
  SET_ASSESSEE_ASSESSMENT_SYNOPSIS_STATE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_ASSESSEE_ASSESSMENT_SECTION_COMMUNIQUE_STATE,
  SET_ASSESSEE_ASSESSMENT_SECTION_MENUSCRIPT_STATE,
  SET_ASSESSEE_ASSESSMENT_SECTION_SYNOPSIS_STATE,
  SET_MIDDLEPANE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
  POPUP_CLOSE,
  SET_PANE_THREE_ASSESSMENT_SECTION_PREVIEW_MODE,
  SET_PANE_THREE_ASSESSMENT_PREVIEW_MODE,
  SET_PANE_THREE_ASSESSMENT_VERSION_PREVIEW_MODE,
  CLEAR_ASSESSEE_ASSESSMENT_REDUCER_STATE,
  SET_POPUP_SINGLE_STATE
} from '../../actionType';
import {
  RES_START_POPUP_OPTION,
  ASSESSMENT_CLOSED_POPUP_OPTION,
  ASSESSMENT_FINISH_POPUP_OPTION
} from '../../PopUpConfig';
import EditorTemplate from '../DisplayPaneFive/EditorTemplate';
import { callApiFunctionLastAttempted, setItemTypeConfigState } from '../../Actions/GenericActions';

export const DisplayPaneSix = () => {
  const { isDisplayPaneSixShow } = useSelector((state) => state.AssessmentReducer);
  const {
    assesseeAssignmentAssessmentData,
    isAssessmentStart,
    asssignmentStarted,
    currentSequenceIndex,
    assessmentsequenceObject,
    sectionMenuscript,
    sectionSynopsis,
    sectionCommunique,
    assessmentCommunique,
    assessmentMenuscript,
    assessmentSynopsis,
    assignmentsequenceObject,
    assignmentCommunique,
    assignmentManuscript,
    assignmentSynopsis
  } = useSelector((state) => state.AssesseeAssignmentAssessmentReducer);
  const {
    isAssessmentSectionShow = false,
    isAssessmentVersionShow = false,
    isAssessmentPreviewShow = false
  } = useSelector((state) => state.DisplayPaneThreeReducer);
  const {
    indexPointer,
    selectedAssociateInfo,
    relatedReviewListDistinctData,
    middlePaneHeaderDuplicate,
    middlePaneHeaderDuplicateBadgeOne
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { selectedTagStatus, selectedTagValue } = useSelector((state) => state.PopUpReducer);
  const { FilterMode } = useSelector((state) => state.FilterReducer);
  const dispatch = useDispatch();
  const [currentSequenceValue, setCurrentSequenceValue] = useState('');
  const [headerValue, setHeader] = useState('');
  const [isShowReviewIcon, setisShowReviewIcon] = useState(true);
  let communique = [];
  let menuscript = [];
  let synopsis = [];
  if (assessmentCommunique && assessmentCommunique.length > 0) {
    if (sectionCommunique && sectionCommunique.length > 0) {
      communique = [...assessmentCommunique, ...sectionCommunique];
    } else {
      communique = [...assessmentCommunique];
    }
  }
  if (assessmentMenuscript && assessmentMenuscript.length > 0) {
    if (sectionMenuscript && sectionMenuscript.length > 0) {
      menuscript = [...assessmentMenuscript, ...sectionMenuscript];
    } else {
      menuscript = [...assessmentMenuscript];
    }
  }
  if (assessmentSynopsis && assessmentSynopsis.length > 0) {
    if (sectionSynopsis && sectionSynopsis.length > 0) {
      synopsis = [...assessmentSynopsis, ...sectionSynopsis];
    } else {
      synopsis = [...assessmentSynopsis];
    }
  }

  const closePreview = () => {
    if (isAssessmentSectionShow) {
      dispatch({ type: SET_PANE_THREE_ASSESSMENT_SECTION_PREVIEW_MODE, payload: false });
    } else if (isAssessmentPreviewShow) {
      dispatch({ type: SET_PANE_THREE_ASSESSMENT_PREVIEW_MODE, payload: false });
    } else if (isAssessmentVersionShow) {
      dispatch({ type: SET_PANE_THREE_ASSESSMENT_VERSION_PREVIEW_MODE, payload: false });
    }
    dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
    dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
    dispatch({ type: CLEAR_ASSESSEE_ASSESSMENT_REDUCER_STATE });
  };
  const displayProgressUI = () => {
    dispatch({
      type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
      payload: { stateName: 'isAssessmentStart', value: 'PROGRESS' }
    });
    dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneSeven' });

    dispatch({ type: POPUP_CLOSE });
  };
  const onClickFooter = (e) => {
    let clickedval = e.currentTarget.getAttribute('data-value');
    let tempArr = RES_START_POPUP_OPTION;
    console.log('isAssessmentStart', isAssessmentStart);
    if (isAssessmentStart === 'COMMUNIQUE') {
      if (clickedval === 'next') {
        document.getElementById('displaypaneSix').scrollTop = '0px'; //for scroll top
        if (indexPointer < communique.length - 1) {
          //setCurrentSequenceValue(communique[indexPointer]);
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'indexPointer', value: indexPointer + 1 }
          });
        } else {
          displayProgressUI();
        }
      }
      if (clickedval === 'previous') {
        document.getElementById('displaypaneSix').scrollTop = '0px'; //for scroll top
        if (indexPointer != 0) {
          //setCurrentSequenceValue(communique[indexPointer - 1]);
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'indexPointer', value: indexPointer - 1 }
          });
        } else {
          displayProgressUI();
        }
      }
      if (clickedval === 'close') {
        displayProgressUI();
      }
    }
    if (isAssessmentStart === 'SYNOPSIS') {
      if (clickedval === 'next') {
        document.getElementById('displaypaneSix').scrollTop = '0px'; //for scroll top
        if (indexPointer < synopsis.length - 1) {
          //setCurrentSequenceValue(synopsis[indexPointer]);
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'indexPointer', value: indexPointer + 1 }
          });
        } else {
          displayProgressUI();
        }
      }
      if (clickedval === 'previous') {
        document.getElementById('displaypaneSix').scrollTop = '0px'; //for scroll top
        if (indexPointer != 0) {
          //setCurrentSequenceValue(synopsis[indexPointer - 1]);
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'indexPointer', value: indexPointer - 1 }
          });
        }
      }
      if (clickedval === 'close') {
        displayProgressUI();
      }
    }
    if (isAssessmentStart === 'MENUSCRIPT') {
      if (clickedval === 'next') {
        document.getElementById('displaypaneSix').scrollTop = '0px'; //for scroll top
        if (indexPointer < menuscript.length - 1) {
          setCurrentSequenceValue(menuscript[indexPointer]);
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'indexPointer', value: indexPointer + 1 }
          });
        } else {
          displayProgressUI();
        }
      }
      if (clickedval === 'previous') {
        document.getElementById('displaypaneSix').scrollTop = '0px'; //for scroll top
        if (indexPointer != 0) {
          setCurrentSequenceValue(menuscript[indexPointer - 1]);
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'indexPointer', value: indexPointer - 1 }
          });
        }
      }
      if (clickedval === 'close') {
        displayProgressUI();
      }
    }
    if (isAssessmentStart === 'ASSIGNMENTCOMMUNIQUE') {
      if (clickedval === 'next') {
        document.getElementById('displaypaneSix').scrollTop = '0px'; //for scroll top
        if (indexPointer < assignmentCommunique.length - 1) {
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'indexPointer', value: indexPointer + 1 }
          });
        }
      }
      if (clickedval === 'previous') {
        document.getElementById('displaypaneSix').scrollTop = '0px'; //for scroll top
        if (indexPointer != 0) {
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'indexPointer', value: indexPointer - 1 }
          });
        }
      }
      if (clickedval === 'close') {
        dispatch({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: { stateName: 'isAssessmentStart', value: 'ReviewListResume' }
        });
      }
    }
    if (isAssessmentStart === 'ASSIGNMENTMENUSCRIPT') {
      if (clickedval === 'next') {
        document.getElementById('displaypaneSix').scrollTop = '0px'; //for scroll top
        if (indexPointer < assignmentManuscript.length - 1) {
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'indexPointer', value: indexPointer + 1 }
          });
        }
      }
      if (clickedval === 'previous') {
        document.getElementById('displaypaneSix').scrollTop = '0px'; //for scroll top
        if (indexPointer != 0) {
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'indexPointer', value: indexPointer - 1 }
          });
        }
      }
      if (clickedval === 'close') {
        dispatch({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: { stateName: 'isAssessmentStart', value: 'ReviewListResume' }
        });
      }
    }
    if (isAssessmentStart === 'ASSIGNMENTSYNOPSIS') {
      if (clickedval === 'next') {
        document.getElementById('displaypaneSix').scrollTop = '0px'; //for scroll top
        if (indexPointer < assignmentSynopsis.length - 1) {
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'indexPointer', value: indexPointer + 1 }
          });
        }
      }
      if (clickedval === 'previous') {
        document.getElementById('displaypaneSix').scrollTop = '0px'; //for scroll top
        if (indexPointer != 0) {
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'indexPointer', value: indexPointer - 1 }
          });
        }
      }
      if (clickedval === 'close') {
        dispatch({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: { stateName: 'isAssessmentStart', value: 'ReviewListResume' }
        });
      }
    }
    if (selectedTagStatus === 'UNSTARTED') {
      tempArr = [{ ...tempArr[0], disabled: true }, tempArr[1]];
    }
    if (isAssessmentStart === 'FINISH') {
      if (clickedval === 'close') {
        dispatch({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: { stateName: 'assesseeAssessmentStartData', value: null }
        });
        console.log('logger4');
        dispatch({
          type: SET_POPUP_STATE,
          payload: {
            popupHeaderOne: 'assessment',
            popupHeaderOneBadgeOne: 'close',
            popupHeaderOneBadgeTwo: '',
            isPopUpValue: '',
            popupOpenType: 'primary',
            secondaryOptionCheckValue: 'assignment',
            popupContentArrValue: ASSESSMENT_CLOSED_POPUP_OPTION,
            selectedTagValue: e.currentTarget.getAttribute('assignmentid'),
            selectedTagStatus: 'status'
          }
        });
        dispatch({ type: POPUP_OPEN, payload: 'paneSevenPopup' });
        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'headerButtons', value: false }
        });
      }
    }
    if (
      isAssessmentStart === 'START' ||
      isAssessmentStart === 'STOP' ||
      isAssessmentStart === 'PREVIEW' ||
      isAssessmentStart === 'SECTIONPREVIEW' ||
      isAssessmentStart === 'VERSIONPREVIEW'
    ) {
      if (clickedval === 'next') {
        document.getElementById('displaypaneSix').scrollTop = '0px'; //for scroll top
        if (currentSequenceIndex < assessmentsequenceObject.length - 1) {
          dispatch({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: { stateName: 'currentSequenceIndex', value: currentSequenceIndex + 1 }
          });
          let currentValue = assessmentsequenceObject[currentSequenceIndex + 1]?.originalValue;
          let lastIndexValue = parseInt(currentValue.substring(currentValue.length - 1));
          if (assessmentsequenceObject[currentSequenceIndex + 1]?.name === 'item distinct') {
            //let tempArr = RES_START_POPUP_OPTION;
            if (isAssessmentStart === 'START') {
              tempArr = [{ ...tempArr[0], disabled: true }, tempArr[1]];
              console.log('logger5');
              dispatch({
                type: SET_POPUP_STATE,
                payload: {
                  popupHeaderOne: 'assessment',
                  popupHeaderOneBadgeOne: '',
                  popupHeaderOneBadgeTwo: '',
                  isPopUpValue: '',
                  popupOpenType: 'primary',
                  popupContentArrValue: tempArr,
                  selectedTagStatus: selectedTagStatus,
                  selectedTagValue: lastIndexValue - 1
                }
              });
              dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
              dispatch({
                type: SET_POPUP_SINGLE_STATE,
                payload: { stateName: 'headerButtons', value: false }
              });
            } else if (isAssessmentStart === 'PREVIEW') {
              if (lastIndexValue === 1) {
                tempArr = [{ ...tempArr[0], disabled: true }, tempArr[1]];
                console.log('logger6');
                dispatch({
                  type: SET_POPUP_STATE,
                  payload: {
                    popupHeaderOne: 'assessment',
                    popupHeaderOneBadgeOne: '',
                    popupHeaderOneBadgeTwo: '',
                    isPopUpValue: '',
                    popupOpenType: 'primary',
                    popupContentArrValue: tempArr,
                    selectedTagStatus: selectedTagStatus,
                    selectedTagValue: lastIndexValue - 1
                  }
                });
                dispatch({ type: POPUP_OPEN, payload: 'paneSevenPopup' });
              } else {
                dispatch({
                  type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
                  payload: {
                    stateName: 'assesseeAssessmentStartData',
                    value:
                      assesseeAssignmentAssessmentData?.informationFramework
                        ?.assessmentSectionFramework[lastIndexValue - 1]
                  }
                });
                dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneFive' });
                dispatch({
                  type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
                  payload: { stateName: 'isAssessmentStart', value: 'PROGRESS' }
                });
              }
              // debugger;
            } else if (isAssessmentStart === 'SECTIONPREVIEW') {
              dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneFive' });
              dispatch({
                type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
                payload: {
                  stateName: 'assesseeAssessmentStartData',
                  value: assesseeAssignmentAssessmentData
                }
              });
              setItemTypeConfigState(
                assesseeAssignmentAssessmentData?.assessmentVersionFramework[0]
                  .assessmentVersionItemDistinct[0].itemFrameworkOne.itemFrameworkOneType,
                dispatch
              );
              dispatch({
                type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
                payload: {
                  stateName: 'assessmentSectionItemDistinctRevise',
                  value:
                    assesseeAssignmentAssessmentData?.assessmentVersionFramework[0]
                      .assessmentVersionItemDistinct[0]
                }
              });
              console.log('debugger4');

              dispatch({
                type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
                payload: { stateName: 'isAssessmentStart', value: 'PROGRESS' }
              });
            } else if (isAssessmentStart === 'VERSIONPREVIEW') {
              dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneFive' });
              dispatch({
                type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
                payload: {
                  stateName: 'assesseeAssessmentStartData',
                  value:
                    assesseeAssignmentAssessmentData?.assessmentVersionFramework[selectedTagValue]
                }
              });
              dispatch({
                type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
                payload: { stateName: 'isAssessmentStart', value: 'PROGRESS' }
              });
            } else {
              console.log('logger7');
              dispatch({
                type: SET_POPUP_STATE,
                payload: {
                  popupHeaderOne: 'assessment',
                  popupHeaderOneBadgeOne: '',
                  popupHeaderOneBadgeTwo: '',
                  isPopUpValue: '',
                  popupOpenType: 'primary',
                  popupContentArrValue: tempArr,
                  selectedTagStatus: selectedTagStatus,
                  selectedTagValue: lastIndexValue - 1
                }
              });
              dispatch({
                type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
                payload: {
                  stateName: 'assesseeAssessmentStartData',
                  value:
                    assesseeAssignmentAssessmentData?.informationFramework
                      ?.assessmentSectionFramework[lastIndexValue - 1]
                }
              });
              console.log('debugger6');
              dispatch({
                type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
                payload: { stateName: 'isAssessmentStart', value: 'PROGRESS' }
              });
              dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneSeven' });
            }
          }
          if (assessmentsequenceObject[currentSequenceIndex + 1]?.name === 'item trial') {
            tempArr = [{ ...tempArr[0], disabled: true }, tempArr[1]];
            console.log('logger8');
            dispatch({
              type: SET_POPUP_STATE,
              payload: {
                popupHeaderOne: 'assessment',
                popupHeaderOneBadgeOne: 'trial',
                popupHeaderOneBadgeTwo: '',
                isPopUpValue: '',
                popupOpenType: 'primary',
                secondaryOptionCheckValue: 'assignment',
                popupContentArrValue: tempArr,
                selectedTagStatus: 'status'
              }
            });
            // dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneSix' });
            dispatch({ type: POPUP_OPEN, payload: 'paneSevenPopup' });
            // dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneFive' });
            // dispatch({
            //   type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            //   payload: {
            //     stateName: 'assesseeAssessmentStartData',
            //     value: assessmentsequenceObject[currentSequenceIndex + 1]?.value
            //   }
            // });
            // dispatch({
            //   type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            //   payload: { stateName: 'isItemTrial', value: true }
            // });
            // dispatch({
            //   type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            //   payload: { stateName: 'isAssessmentStart', value: 'PROGRESS' }
            // });
          }
        } else {
          if (isAssessmentStart === 'PREVIEW' || isAssessmentStart === 'SECTIONPREVIEW') {
            dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneFive' });
          } else {
            console.log('logger9');
            dispatch({
              type: SET_POPUP_STATE,
              payload: {
                popupHeaderOne: 'assessment',
                popupHeaderOneBadgeOne: 'close',
                popupHeaderOneBadgeTwo: '',
                isPopUpValue: '',
                popupOpenType: 'primary',
                secondaryOptionCheckValue: 'assignment',
                popupContentArrValue: ASSESSMENT_FINISH_POPUP_OPTION,
                selectedTagValue: relatedReviewListDistinctData[0].assesseeAssignmentId,
                //selectedTagValue: e.currentTarget.getAttribute('assignmentid'),
                selectedTagStatus: 'status'
              }
            });
            dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneSix' });
            dispatch({ type: POPUP_OPEN, payload: 'paneSevenPopup' });
          }
        }
        if (currentSequenceIndex < assessmentsequenceObject.length - 1) {
          if (assessmentsequenceObject[currentSequenceIndex]?.name === 'assessment section') {
          }
          if (assessmentsequenceObject[currentSequenceIndex]?.name === 'assessment communiqué') {
            dispatch({
              type: SET_ASSESSEE_ASSESSMENT_COMMUNIQUE_STATE,
              payload: assessmentsequenceObject[currentSequenceIndex]?.value
            });
          }
          if (assessmentsequenceObject[currentSequenceIndex]?.name === 'assessment synopsis') {
            dispatch({
              type: SET_ASSESSEE_ASSESSMENT_SYNOPSIS_STATE,
              payload: assessmentsequenceObject[currentSequenceIndex]?.value
            });
          }
          if (assessmentsequenceObject[currentSequenceIndex]?.name === 'assessment manuscript') {
            dispatch({
              type: SET_ASSESSEE_ASSESSMENT_MENUSCRIPT_STATE,
              payload: assessmentsequenceObject[currentSequenceIndex]?.value
            });
          }
          if (assessmentsequenceObject[currentSequenceIndex]?.name === 'section communiqué') {
            dispatch({
              type: SET_ASSESSEE_ASSESSMENT_SECTION_COMMUNIQUE_STATE,
              payload: assessmentsequenceObject[currentSequenceIndex]?.value
            });
          }
          if (assessmentsequenceObject[currentSequenceIndex]?.name === 'section synopsis') {
            dispatch({
              type: SET_ASSESSEE_ASSESSMENT_SECTION_SYNOPSIS_STATE,
              payload: assessmentsequenceObject[currentSequenceIndex]?.value
            });
          }
          if (assessmentsequenceObject[currentSequenceIndex]?.name === 'section manuscript') {
            dispatch({
              type: SET_ASSESSEE_ASSESSMENT_SECTION_MENUSCRIPT_STATE,
              payload: assessmentsequenceObject[currentSequenceIndex]?.value
            });
          }
          if (isAssessmentStart === 'START' || isAssessmentStart === 'STOP') {
            callApiFunctionLastAttempted(
              selectedAssociateInfo,
              assesseeAssignmentAssessmentData,
              dispatch,
              assessmentsequenceObject[currentSequenceIndex]?.originalValue
            );
          }
        } else {
          closePreview();
        }
      }
      if (clickedval === 'previous') {
        document.getElementById('displaypaneSix').scrollTop = '0px'; //for scroll top
        if (currentSequenceIndex != 0) {
          dispatch({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: { stateName: 'currentSequenceIndex', value: currentSequenceIndex - 1 }
          });
        }
      }
    }
    if (isAssessmentStart === 'ReviewListStart') {
      if (clickedval === 'next') {
        document.getElementById('displaypaneSix').scrollTop = '0px'; //for scroll top
        if (indexPointer < assignmentsequenceObject.length - 1) {
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'indexPointer', value: indexPointer + 1 }
          });
          if (assignmentsequenceObject[indexPointer + 1]?.name === 'assignment assessment') {
            dispatch({
              type: SET_MIDDLEPANE_STATE,
              payload: {
                middlePaneHeader: 'assessments',
                middlePaneHeaderBadgeOne: 'active',
                middlePaneHeaderBadgeTwo: '',
                middlePaneHeaderBadgeThree: '',
                middlePaneHeaderBadgeFour: '',
                typeOfMiddlePaneList: 'assesseesAssginmentAssessmentReviewList',
                middlePaneHeaderDuplicate: middlePaneHeaderDuplicate,
                middlePaneHeaderDuplicateBadgeOne: middlePaneHeaderDuplicateBadgeOne,
                middlePaneHeaderDuplicateBadgeTwo: '',
                middlePaneHeaderDuplicateBadgeThree: '',
                middlePaneHeaderDuplicateBadgeFour: '',
                scanCount: assignmentsequenceObject[indexPointer + 1].value.length,
                showMiddlePaneState: true
              }
            });
            dispatch({
              type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
              payload: { stateName: 'isAssessmentStart', value: 'ReviewListResume' }
            });
            dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
          }
        } else {
          dispatch({
            type: SET_MIDDLEPANE_STATE,
            payload: {
              middlePaneHeader: 'assessments',
              middlePaneHeaderBadgeOne: 'active',
              middlePaneHeaderBadgeTwo: '',
              middlePaneHeaderBadgeThree: '',
              middlePaneHeaderBadgeFour: '',
              typeOfMiddlePaneList: 'assesseesAssginmentAssessmentReviewList',
              middlePaneHeaderDuplicate: middlePaneHeaderDuplicate,
              middlePaneHeaderDuplicateBadgeOne: middlePaneHeaderDuplicateBadgeOne,
              middlePaneHeaderDuplicateBadgeTwo: '',
              middlePaneHeaderDuplicateBadgeThree: '',
              middlePaneHeaderDuplicateBadgeFour: '',
              //scanCount: assessmentList[0].assesseeAssignmentAssessmentDistinct.length,
              showMiddlePaneState: true
            }
          });
          dispatch({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: { stateName: 'isAssessmentStart', value: 'ReviewListResume' }
          });
          dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
        }
      }
      if (clickedval === 'previous') {
        document.getElementById('displaypaneSix').scrollTop = '0px'; //for scroll top
        if (indexPointer != 0) {
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'indexPointer', value: indexPointer - 1 }
          });
        }
      }
    }
    setisShowReviewIcon(true);
  };
  console.log('assessmentsequenceObject', assessmentsequenceObject);
  console.log('assesseeAssignmentAssessmentData', assesseeAssignmentAssessmentData);
  console.log('current isAssessmentStart value', isAssessmentStart);
  console.log('assignmentsequenceObject', assignmentsequenceObject);
  console.log(currentSequenceIndex, 'currentSequenceIndex')

  const onClickNavigator = () => {
    setisShowReviewIcon(false);
  };
  return (
    <>
      <div>
        <DisplayPaneSixHeader
          className=""
          headerOne={
            isAssessmentStart === 'ReviewListStart' ||
            isAssessmentStart === 'ASSIGNMENTCOMMUNIQUE' ||
            isAssessmentStart === 'ASSIGNMENTSYNOPSIS' ||
            isAssessmentStart === 'ASSIGNMENTMENUSCRIPT'
              ? 'assignment'
              : 'assessment'
          }
          headerOneBadgeOne={
            isAssessmentStart === 'START' ||
            isAssessmentStart === 'STOP' ||
            isAssessmentStart === 'FINISH' ||
            isAssessmentStart === 'PREVIEW' ||
            isAssessmentStart === 'SECTIONPREVIEW' ||
            isAssessmentStart === 'VERSIONPREVIEW'
              ? assessmentsequenceObject[currentSequenceIndex]?.name === 'assessment communiqué'
                ? 'communiqué'
                : assessmentsequenceObject[currentSequenceIndex]?.name === 'assessment manuscript'
                ? 'manuscript'
                : assessmentsequenceObject[currentSequenceIndex]?.name === 'assessment synopsis'
                ? 'synopsis'
                : assessmentsequenceObject[currentSequenceIndex]?.name === 'section communiqué'
                ? 'section'
                : assessmentsequenceObject[currentSequenceIndex]?.name === 'section manuscript'
                ? 'section'
                : assessmentsequenceObject[currentSequenceIndex]?.name === 'section synopsis'
                ? 'section'
                : ''
              : isAssessmentStart === 'COMMUNIQUE'
              ? 'communique'
              : isAssessmentStart === 'SYNOPSIS'
              ? 'synopsis'
              : isAssessmentStart === 'MENUSCRIPT'
              ? 'manuscript'
              : isAssessmentStart === 'ReviewListStart'
              ? assignmentsequenceObject[indexPointer]?.name === 'assignment communiqué'
                ? 'communiqué'
                : assignmentsequenceObject[indexPointer]?.name === 'assignment manuscript'
                ? 'manuscript'
                : assignmentsequenceObject[indexPointer]?.name === 'assignment synopsis'
                ? 'synopsis'
                : ''
              : ''
          }
          headerOneBadgeTwo={
            assessmentsequenceObject[currentSequenceIndex]?.name === 'section communiqué'
              ? 'communiqué'
              : assessmentsequenceObject[currentSequenceIndex]?.name === 'section manuscript'
              ? 'manuscript'
              : assessmentsequenceObject[currentSequenceIndex]?.name === 'section synopsis'
              ? 'synopsis'
              : ''
          }
          headerPanelColour="blue"
        />
      </div>
      <div className="containerPadding">
        {isDisplayPaneSixShow && (
          <>
            <div className="containerPadding">
              <div
                style={{
                  padding: '2.5px 5px',
                  alignItems: 'center',
                  height: 'calc(100vh - 190px)',
                  overflow: 'overlay'
                }}
                id={'displaypaneSix'}
              >
                {isAssessmentStart === 'START' ||
                isAssessmentStart === 'STOP' ||
                isAssessmentStart === 'PREVIEW' ||
                isAssessmentStart === 'FINISH' ? (
                  <DisplayPaneSixAssignmentAssessment />
                ) : isAssessmentStart === 'ReviewListStart' ? (
                  <DisplayPaneSixAssignment
                    relatedReviewListDistinctData={relatedReviewListDistinctData}
                  />
                ) : isAssessmentStart === 'SECTIONPREVIEW' ||
                  isAssessmentStart === 'VERSIONPREVIEW' ? (
                  <DisplayPaneSixAssignmentAssessmentSection />
                ) : (
                  <EditorTemplate
                    previewAs={true}
                    previewDisable={true}
                    label={'itemLabel'}
                    jsonData={
                      // isAssessmentStart === 'START' || isAssessmentStart === 'STOP'
                      //   ? assessmentsequenceObject &&
                      //   assessmentsequenceObject[currentSequenceIndex]?.value :
                      isAssessmentStart === 'SYNOPSIS'
                        ? synopsis[indexPointer]
                        : isAssessmentStart === 'COMMUNIQUE'
                        ? communique[indexPointer]
                        : isAssessmentStart === 'MENUSCRIPT'
                        ? menuscript[indexPointer]
                        : isAssessmentStart === 'ASSIGNMENTSYNOPSIS'
                        ? assignmentSynopsis[indexPointer]
                        : isAssessmentStart === 'ASSIGNMENTCOMMUNIQUE'
                        ? assignmentCommunique[indexPointer]
                        : isAssessmentStart === 'ASSIGNMENTMENUSCRIPT'
                        ? assignmentManuscript[indexPointer]
                        : ''
                    }
                  />
                )}
              </div>
            </div>

            {isAssessmentStart === 'COMMUNIQUE' ||
            isAssessmentStart === 'SYNOPSIS' ||
            isAssessmentStart === 'MENUSCRIPT' ||
            isAssessmentStart === 'ASSIGNMENTCOMMUNIQUE' ||
            isAssessmentStart === 'ASSIGNMENTSYNOPSIS' ||
            isAssessmentStart === 'ASSIGNMENTMENUSCRIPT' ? (
              <FooterIconTwo
                FilterModeEnable={false}
                FilterMode={FilterMode}
                onClick={onClickNavigator}
                backColour={'displayPaneLeft'}
                primaryIcon={[
                  {
                    label: 'navigator',
                    onClick: onClickNavigator,
                    Icon: CodeIcon
                  }
                ]}
                secondaryIcon={[
                  {
                    label: 'previous',
                    onClick: onClickFooter,
                    Icon: ArrowLeft,
                    disabled: false
                  },
                  { label: 'next', onClick: onClickFooter, Icon: ArrowRight }
                  // { label: 'close', onClick: onClickFooter, Icon: CrossIcon }
                ]}
              />
            ) : isAssessmentStart === 'START' ||
              isAssessmentStart === 'STOP' ||
              isAssessmentStart === 'PREVIEW' ||
              isAssessmentStart === 'ReviewListStart' ? (
              <FooterIconTwo
                FilterModeEnable={false}
                FilterMode={FilterMode}
                onClick={onClickNavigator}
                backColour={'displayPaneLeft'}
                primaryIcon={[
                  {
                    label: 'navigator',
                    onClick: onClickNavigator,
                    Icon: CodeIcon
                  }
                ]}
                secondaryIcon={[
                  {
                    label: 'previous',
                    onClick: onClickFooter,
                    Icon: ArrowLeft,
                    disabled:
                      isAssessmentStart === 'START' && currentSequenceIndex !== 0 ? false : true
                  },
                  { label: 'next', onClick: onClickFooter, Icon: ArrowRight }
                ]}
              />
            ) : isAssessmentStart === 'FINISH' ? (
              <FooterIconTwo
                FilterModeEnable={true}
                FilterMode={FilterMode}
                onClick={onClickNavigator}
                backColour={'displayPaneLeft'}
                primaryIcon={[{ label: 'close', onClick: onClickFooter, Icon: CrossIcon }]}
                secondaryIcon={[{ label: 'close', onClick: onClickFooter, Icon: CrossIcon }]}
              />
            ) : null}
            {/* {isAssessmentStart === 'FINISH' ? (
              <FooterIconTwo
                FilterModeEnable={false}
                FilterMode={FilterMode}
                onClick={onClickFooter}
                backColour={'displayPaneLeft'}
                primaryIcon={[{ label: 'close', onClick: onClickFooter, Icon: CrossIcon }]}
                secondaryIcon={[]}
              />
            ) : null} */}
          </>
        )}
      </div>
    </>
  );
};

//DisplayPaneSixAssignment assignment sequence obect
export const DisplayPaneSixAssignment = (props) => {
  const { isDisplayPaneSixShow } = useSelector((state) => state.AssessmentReducer);
  const {
    indexPointer,
    relatedReviewListDistinctData,
    middlePaneHeaderDuplicate,
    middlePaneHeaderDuplicateBadgeOne
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { assignmentsequenceObject } = useSelector(
    (state) => state.AssesseeAssignmentAssessmentReducer
  );
  const dispatch = useDispatch();
  //const { relatedReviewListDistinctData } = props;
  useEffect(() => {
    if (relatedReviewListDistinctData.length > 0) {
      let assignmentAdministrationSequence =
        relatedReviewListDistinctData[0]?.assignmentAdministrationSequence || [];
      let assignmentCommunique = relatedReviewListDistinctData[0]?.assignmentCommunique || [];
      let assignmentSynopsis = relatedReviewListDistinctData[0]?.assignmentSynopsis || [];
      let assignmentManuscript = relatedReviewListDistinctData[0]?.assignmentManuscript || [];
      let assignmentAssessmentDistinct =
        relatedReviewListDistinctData[0]?.assesseeAssignmentAssessmentDistinct || [];
      let sequenceArr = [];
      let assignmentSynArr = [];
      let assignmentComArr = [];
      let assignmentManArr = [];
      if (assignmentAdministrationSequence.length > 0) {
        for (let i = 0; i < assignmentAdministrationSequence.length; i++) {
          let currentValue = assignmentAdministrationSequence[i];
          let lastIndexValue = parseInt(currentValue.substring(currentValue.length - 1));
          let matchValue = currentValue;
          matchValue = matchValue.substring(0, matchValue.length - 1);
          if (matchValue.trim() === 'assignment synopsis') {
            sequenceArr.push({
              name: matchValue.trim(),
              value: assignmentSynopsis[lastIndexValue - 1],
              originalValue: currentValue
            });
            assignmentSynArr.push(assignmentSynopsis[lastIndexValue - 1]);
          } else if (matchValue.trim() === 'assignment communiqué') {
            assignmentComArr.push(assignmentCommunique[lastIndexValue - 1]);
            sequenceArr.push({
              name: matchValue.trim(),
              value: assignmentCommunique[lastIndexValue - 1],
              originalValue: currentValue
            });
          } else if (matchValue.trim() === 'assignment manuscript') {
            assignmentManArr.push(assignmentManuscript[lastIndexValue - 1]);
            sequenceArr.push({
              name: matchValue.trim(),
              value: assignmentManuscript[lastIndexValue - 1],
              originalValue: currentValue
            });
          } else if (currentValue.trim() === 'assignment assessment') {
            sequenceArr.push({
              name: currentValue.trim(),
              value: assignmentAssessmentDistinct,
              originalValue: currentValue
            });
          }
        }
        dispatch({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: { stateName: 'assignmentsequenceObject', value: sequenceArr }
        });
        dispatch({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: { stateName: 'assignmentCommunique', value: assignmentComArr }
        });
        dispatch({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: { stateName: 'assignmentManuscript', value: assignmentManArr }
        });
        dispatch({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: { stateName: 'assignmentSynopsis', value: assignmentSynArr }
        });
      }
    }
  }, [relatedReviewListDistinctData]);

  useEffect(() => {
    if (assignmentsequenceObject.length > 0) {
      if (assignmentsequenceObject[indexPointer]?.name === 'assignment assessment') {
        dispatch({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: 'assessments',
            middlePaneHeaderBadgeOne: 'active',
            middlePaneHeaderBadgeTwo: '',
            middlePaneHeaderBadgeThree: '',
            middlePaneHeaderBadgeFour: '',
            typeOfMiddlePaneList: 'assesseesAssginmentAssessmentReviewList',
            scanCount: assignmentsequenceObject[indexPointer].value.length,
            middlePaneHeaderDuplicate: middlePaneHeaderDuplicate,
            middlePaneHeaderDuplicateBadgeOne: middlePaneHeaderDuplicateBadgeOne,
            middlePaneHeaderDuplicateBadgeTwo: '',
            middlePaneHeaderDuplicateBadgeThree: '',
            middlePaneHeaderDuplicateBadgeFour: '',
            showMiddlePaneState: true
          }
        });
        dispatch({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: { stateName: 'isAssessmentStart', value: 'ReviewListResume' }
        });
      }
    }
  }, [assignmentsequenceObject]);

  return (
    <>
      <EditorTemplate
        previewAs={true}
        previewDisable={true}
        label={'itemLabel'}
        jsonData={
          assignmentsequenceObject.length > 0 ? assignmentsequenceObject[indexPointer].value : []
        }

        //jsonData={(assignmentsequenceObject && assignmentsequenceObject) || ''}
      />
    </>
  );
};

//DisplayPaneSixAssignment assessment assignment sequence obect
export const DisplayPaneSixAssignmentAssessment = (props) => {
  const [sequenceObject, setSequenceObject] = useState([]);
  const { selectedTagStatus } = useSelector((state) => state.PopUpReducer);
  const {
    assesseeAssignmentAssessmentData,
    isAssessmentStart,
    currentSequenceIndex,
    assessmentsequenceObject
  } = useSelector((state) => state.AssesseeAssignmentAssessmentReducer);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   handleSequenceArrange();
  // }, [assesseeAssignmentAssessmentData]);

  // const handleSequenceArrange = () => {
  //   let assessmentAdministrationSequence =
  //     assesseeAssignmentAssessmentData?.informationFramework?.assessmentAdministrationSequence ||
  //     [];
  //   let sequenceArr = [];

  //   let informationFramework = assesseeAssignmentAssessmentData?.informationFramework;
  //   if (assessmentAdministrationSequence.length > 0) {
  //     for (let i = 0; i < assessmentAdministrationSequence.length; i++) {
  //       let currentValue = informationFramework?.assessmentAdministrationSequence[i];
  //       let lastIndexValue = parseInt(currentValue.substring(currentValue.length - 1));
  //       let matchValue = currentValue;
  //       matchValue = matchValue.substring(0, matchValue.length - 1);
  //       if (matchValue.trim() === 'assessment communiqué') {
  //         sequenceArr.push({
  //           name: matchValue.trim(),
  //           value: informationFramework?.assessmentCommunique[lastIndexValue - 1],
  //           originalValue: currentValue
  //         });
  //         setSequenceObject((old) => [
  //           ...old,
  //           {
  //             name: matchValue.trim(),
  //             value: informationFramework?.assessmentCommunique[lastIndexValue - 1],
  //             originalValue: currentValue
  //           }
  //         ]);
  //       } else if (matchValue.trim() === 'assessment synopsis') {
  //         setSequenceObject((old) => [
  //           ...old,
  //           {
  //             name: matchValue.trim(),
  //             value: informationFramework?.assessmentSynopsis[lastIndexValue - 1],
  //             originalValue: currentValue
  //           }
  //         ]);
  //         sequenceArr.push({
  //           name: matchValue.trim(),
  //           value: informationFramework?.assessmentSynopsis[lastIndexValue - 1],
  //           originalValue: currentValue
  //         });
  //       } else if (matchValue.trim() === 'assessment manuscript') {
  //         setSequenceObject((old) => [
  //           ...old,
  //           {
  //             name: matchValue.trim(),
  //             value: informationFramework?.assessmentManuscript[lastIndexValue - 1],
  //             originalValue: currentValue
  //           }
  //         ]);
  //         sequenceArr.push({
  //           name: matchValue.trim(),
  //           value: informationFramework?.assessmentManuscript[lastIndexValue - 1],
  //           originalValue: currentValue
  //         });
  //       } else if (matchValue.trim() === 'assessment section') {
  //         let assessmentSectionFramework = informationFramework?.assessmentSectionFramework || [];
  //         let sectionObj = assessmentSectionFramework[lastIndexValue - 1];
  //         if (sectionObj?.assessmentSectionAdministrationSequence.length > 0) {
  //           for (let j = 0; j < sectionObj?.assessmentSectionAdministrationSequence.length; j++) {
  //             let currentSectionSequenceValue =
  //               sectionObj?.assessmentSectionAdministrationSequence[j];
  //             let lastIndexValue = parseInt(
  //               currentSectionSequenceValue.substring(currentSectionSequenceValue.length - 1)
  //             );
  //             let matchValue = currentSectionSequenceValue;
  //             matchValue = matchValue.substring(0, matchValue.length - 1);
  //             if (matchValue.trim() === 'section communiqué') {
  //               setSequenceObject((old) => [
  //                 ...old,
  //                 {
  //                   name: matchValue.trim(),
  //                   value: sectionObj?.assessmentSectionCommunique[lastIndexValue - 1],
  //                   originalValue: currentSectionSequenceValue
  //                 }
  //               ]);
  //               sequenceArr.push({
  //                 name: matchValue.trim(),
  //                 value: sectionObj?.assessmentSectionCommunique[lastIndexValue - 1],
  //                 originalValue: currentSectionSequenceValue
  //               });
  //             } else if (matchValue.trim() === 'section synopsis') {
  //               setSequenceObject((old) => [
  //                 ...old,
  //                 {
  //                   name: matchValue.trim(),
  //                   value: sectionObj?.assessmentSectionSynopsis[lastIndexValue - 1],
  //                   originalValue: currentSectionSequenceValue
  //                 }
  //               ]);
  //               sequenceArr.push({
  //                 name: matchValue.trim(),
  //                 value: sectionObj?.assessmentSectionSynopsis[lastIndexValue - 1],
  //                 originalValue: currentSectionSequenceValue
  //               });
  //             } else if (matchValue.trim() === 'section manuscript') {
  //               setSequenceObject((old) => [
  //                 ...old,
  //                 {
  //                   name: matchValue.trim(),
  //                   value: sectionObj?.assessmentSectionManuscript[lastIndexValue - 1],
  //                   originalValue: currentSectionSequenceValue
  //                 }
  //               ]);
  //               sequenceArr.push({
  //                 name: matchValue.trim(),
  //                 value: sectionObj?.assessmentSectionManuscript[lastIndexValue - 1],
  //                 originalValue: currentSectionSequenceValue
  //               });
  //             } else if (currentSectionSequenceValue.trim() === 'item distinct') {
  //               setSequenceObject((old) => [
  //                 ...old,
  //                 {
  //                   name: currentSectionSequenceValue.trim(),
  //                   value: sectionObj?.assessmentSectionItemDistinct,
  //                   originalValue: currentValue
  //                 }
  //               ]);
  //               sequenceArr.push({
  //                 name: currentSectionSequenceValue.trim(),
  //                 value: sectionObj?.assessmentSectionItemDistinct,
  //                 originalValue: currentValue
  //               });
  //             }
  //           }
  //         } else {
  //           setSequenceObject((old) => [
  //             ...old,
  //             {
  //               name: matchValue.trim(),
  //               value: sectionObj?.assessmentSectionItemDistinct,
  //               originalValue: currentValue
  //             }
  //           ]);
  //           sequenceArr.push({
  //             name: matchValue.trim(),
  //             value: sectionObj?.assessmentSectionItemDistinct,
  //             originalValue: currentValue
  //           });
  //         }
  //       } else if (currentValue.trim() === 'item trial') {
  //         sequenceArr.push({
  //           name: currentValue.trim(),
  //           value: informationFramework?.assessmentItemTrial,
  //           originalValue: currentValue.trim()
  //         });
  //       }
  //     }
  //     // console.log(sequenceArr);
  //     dispatch({
  //       type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
  //       payload: { stateName: 'assessmentsequenceObject', value: sequenceArr }
  //     });
  //     debugger
  //   }
  // };

  useEffect(() => {
    console.log('assessmentsequenceObject', assessmentsequenceObject);
    console.log('currentSequenceIndex', currentSequenceIndex);
    console.log('useEffect');
    let assesseeAssignmentAssessmentItemLastAttempted =
      assesseeAssignmentAssessmentData?.assesseeAssignmentAssessmentItemAttemptedLast || '';
    if (assessmentsequenceObject.length > 0 && assesseeAssignmentAssessmentItemLastAttempted) {
      if (isAssessmentStart === 'START') {
        let isPresent = assesseeAssignmentAssessmentItemLastAttempted.indexOf(':') !== -1;
        if (isPresent) {
          let tempArr = RES_START_POPUP_OPTION;
          tempArr = [{ ...tempArr[0], disabled: true }, tempArr[1]];
          if (assesseeAssignmentAssessmentItemLastAttempted) {
            tempArr = [tempArr[0], { ...tempArr[1], disabled: true }];
          } else {
            tempArr = [{ ...tempArr[0], disabled: true }, tempArr[1]];
          }
          let arr = assesseeAssignmentAssessmentItemLastAttempted.split(':');
          let currentValue = arr[0];
          let lastIndexValue = parseInt(currentValue.substring(currentValue.length - 1));
          console.log('logger10');
          dispatch({
            type: SET_POPUP_STATE,
            payload: {
              popupHeaderOne: 'assessment',
              popupHeaderOneBadgeOne: '',
              popupHeaderOneBadgeTwo: '',
              isPopUpValue: '',
              popupOpenType: 'primary',
              popupContentArrValue: tempArr,
              selectedTagStatus: selectedTagStatus,
              selectedTagValue: lastIndexValue - 1
            }
          });
          dispatch({
            type: SET_POPUP_SINGLE_STATE,
            payload: { stateName: 'headerButtons', value: false }
          });
          let indexValue = assessmentsequenceObject.findIndex(
            (obj) => obj.originalValue === currentValue
          );
          console.log('lastAttempted Value', indexValue);
          dispatch({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: { stateName: 'currentSequenceIndex', value: indexValue }
          });
          dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
          // dispatch({ type: POPUP_OPEN, payload: 'paneSevenPopup' });
        }
        // else {
        //   let indexValue = assessmentsequenceObject.findIndex(
        //     (obj) => obj.originalValue === assesseeAssignmentAssessmentItemLastAttempted
        //   );
        //   console.log('lastAttempted Value', indexValue);
        //   dispatch({
        //     type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
        //     payload: { stateName: 'currentSequenceIndex', value: indexValue }
        //   });
        // }
      } else {
        if (isAssessmentStart !== 'STOP') {
          let indexValue = assessmentsequenceObject.findIndex(
            (obj) => obj.originalValue === assesseeAssignmentAssessmentItemLastAttempted.split(':')[0]
          );
          console.log('lastAttempted Value', indexValue);
          dispatch({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: { stateName: 'currentSequenceIndex', value: indexValue + 1 }
          });
        }
      }
    }
    if (assessmentsequenceObject[currentSequenceIndex]?.name === 'item trial') {
      let tempArr = RES_START_POPUP_OPTION;
      tempArr = [{ ...tempArr[0], disabled: true }, tempArr[1]];
      console.log('logger1');
      dispatch({
        type: SET_POPUP_STATE,
        payload: {
          popupHeaderOne: 'assessment',
          popupHeaderOneBadgeOne: 'trial',
          popupHeaderOneBadgeTwo: '',
          isPopUpValue: '',
          popupOpenType: 'primary',
          secondaryOptionCheckValue: 'assignment',
          popupContentArrValue: tempArr,
          selectedTagStatus: 'status'
        }
      });
      // dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneSix' });
      dispatch({ type: POPUP_OPEN, payload: 'paneSevenPopup' });
    }
    if (
      assessmentsequenceObject?.length > 0 &&
      currentSequenceIndex < assessmentsequenceObject?.length
    ) {
      let currentValue = assessmentsequenceObject[currentSequenceIndex]?.originalValue;
      let lastIndexValue = parseInt(currentValue.substring(currentValue.length - 1));
      let tempArr = RES_START_POPUP_OPTION;
      if (assessmentsequenceObject[currentSequenceIndex]?.name === 'item distinct') {
        if (isAssessmentStart === 'START') {
          // tempArr = [{ ...tempArr[0], disabled: true }, tempArr[1]];
          tempArr = [tempArr[0], { ...tempArr[1], disabled: true }];
          console.log('logger2');
          dispatch({
            type: SET_POPUP_STATE,
            payload: {
              popupHeaderOne: 'assessment',
              popupHeaderOneBadgeOne: '',
              popupHeaderOneBadgeTwo: '',
              isPopUpValue: '',
              popupOpenType: 'primary',
              popupContentArrValue: tempArr,
              selectedTagStatus: selectedTagStatus,
              selectedTagValue: lastIndexValue - 1
            }
          });
          dispatch({
            type: SET_POPUP_SINGLE_STATE,
            payload: { stateName: 'headerButtons', value: false }
          });
          dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
          dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneSix' });
        } else if (isAssessmentStart === 'PREVIEW') {
          let assessmentVersionItemDistinct =
            assesseeAssignmentAssessmentData?.informationFramework?.assessmentSectionFramework[
              lastIndexValue - 1
            ]?.assessmentVersionFramework[0]?.assessmentVersionItemDistinct[0] || {};
          dispatch({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: {
              stateName: 'assesseeAssessmentStartData',
              value:
                assesseeAssignmentAssessmentData?.informationFramework?.assessmentSectionFramework[
                  lastIndexValue - 1
                ]
            }
          });
          setItemTypeConfigState(
            assessmentVersionItemDistinct?.itemFrameworkOne?.itemFrameworkOneType,
            // assesseeAssignmentAssessmentData?.informationFramework?.assessmentSectionFramework[
            //   lastIndexValue - 1
            // ]?.assessmentVersionFramework[0].assessmentVersionItemDistinct[0].itemFrameworkOne
            //   .itemFrameworkOneType,
            dispatch
          );
          dispatch({
            type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
            payload: {
              stateName: 'assessmentSectionItemDistinctRevise',
              value: assessmentVersionItemDistinct
              // assesseeAssignmentAssessmentData?.informationFramework?.assessmentSectionFramework[
              //   lastIndexValue - 1
              // ]?.assessmentVersionFramework[0]?.assessmentVersionItemDistinct[0]
            }
          });
          dispatch({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: { stateName: 'isAssessmentStart', value: 'PROGRESS' }
          });
          dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneFive' });
        } else {
          console.log('logger3');
          dispatch({
            type: SET_POPUP_STATE,
            payload: {
              popupHeaderOne: 'assessment',
              popupHeaderOneBadgeOne: '',
              popupHeaderOneBadgeTwo: '',
              isPopUpValue: '',
              popupOpenType: 'primary',
              popupContentArrValue: tempArr,
              selectedTagStatus: selectedTagStatus,
              selectedTagValue: lastIndexValue - 1
            }
          });
          dispatch({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: {
              stateName: 'assesseeAssessmentStartData',
              value:
                assesseeAssignmentAssessmentData?.informationFramework?.assessmentSectionFramework[
                  lastIndexValue - 1
                ]
            }
          });
          dispatch({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: { stateName: 'isAssessmentStart', value: 'PROGRESS' }
          });
          dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneSeven' });
        }
      }
    }
  }, [assessmentsequenceObject]);

  // console.log('currentSequenceIndex', currentSequenceIndex);
  return (
    <>
      <EditorTemplate
        previewAs={true}
        previewDisable={true}
        label={'itemLabel'}
        jsonData={assessmentsequenceObject && assessmentsequenceObject[currentSequenceIndex]?.value}
      />
    </>
  );
};

export const DisplayPaneSixAssignmentAssessmentSection = (props) => {
  const { selectedTagStatus, selectedTagValue } = useSelector((state) => state.PopUpReducer);
  const {
    assesseeAssignmentAssessmentData,
    isAssessmentStart,
    currentSequenceIndex,
    assessmentsequenceObject
  } = useSelector((state) => state.AssesseeAssignmentAssessmentReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    handleSequenceArrange();
  }, [assesseeAssignmentAssessmentData]);

  const handleSequenceArrange = () => {
    let assessmentSectionAdministrationSequence =
      assesseeAssignmentAssessmentData?.assessmentSectionAdministrationSequence || [];
    let sequenceArr = [];
    if (assessmentSectionAdministrationSequence.length > 0) {
      for (let i = 0; i < assessmentSectionAdministrationSequence.length; i++) {
        let currentValue = assessmentSectionAdministrationSequence[i];
        let lastIndexValue = parseInt(currentValue.substring(currentValue.length - 1));
        let matchValue = currentValue;
        matchValue = matchValue.substring(0, matchValue.length - 1);
        if (matchValue.trim() === 'section communiqué') {
          sequenceArr.push({
            name: matchValue.trim(),
            value:
              assesseeAssignmentAssessmentData?.assessmentSectionCommunique[lastIndexValue - 1],
            originalValue: currentValue
          });
        } else if (matchValue.trim() === 'section synopsis') {
          sequenceArr.push({
            name: matchValue.trim(),
            value: assesseeAssignmentAssessmentData?.assessmentSectionSynopsis[lastIndexValue - 1],
            originalValue: currentValue
          });
        } else if (matchValue.trim() === 'section manuscript') {
          sequenceArr.push({
            name: matchValue.trim(),
            value:
              assesseeAssignmentAssessmentData?.assessmentSectionManuscript[lastIndexValue - 1],
            originalValue: currentValue
          });
        } else if (currentValue.trim() === 'item distinct') {
          sequenceArr.push({
            name: currentValue.trim(),
            value: assesseeAssignmentAssessmentData?.assessmentSectionItemDistinct,
            originalValue: currentValue
          });
        }
      }
      console.log(sequenceArr);
      dispatch({
        type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
        payload: { stateName: 'assessmentsequenceObject', value: sequenceArr }
      });
    }
  };

  useEffect(() => {
    let assesseeAssignmentAssessmentItemLastAttempted =
      assesseeAssignmentAssessmentData?.assesseeAssignmentAssessmentItemAttemptedLast || '';
    if (assessmentsequenceObject.length > 0 && assesseeAssignmentAssessmentItemLastAttempted) {
    }
    if (assessmentsequenceObject.length > 0) {
      let currentValue = assessmentsequenceObject[currentSequenceIndex]?.originalValue;
      let lastIndexValue = parseInt(currentValue.substring(currentValue.length - 1));
      let tempArr = RES_START_POPUP_OPTION;
      if (assessmentsequenceObject[currentSequenceIndex]?.name === 'item distinct') {
        if (isAssessmentStart === 'VERSIONPREVIEW') {
          dispatch({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: {
              stateName: 'assesseeAssessmentStartData',
              value: assesseeAssignmentAssessmentData?.assessmentVersionFramework[selectedTagValue]
            }
          });
          dispatch({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: { stateName: 'isAssessmentStart', value: 'PROGRESS' }
          });
        } else {
          dispatch({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: {
              stateName: 'assesseeAssessmentStartData',
              value: assesseeAssignmentAssessmentData
            }
          });
          if (assesseeAssignmentAssessmentData?.assessmentVersionFramework.length > 0) {
            console.log('assesseeAssignmentAssessmentData', assesseeAssignmentAssessmentData);
            setItemTypeConfigState(
              assesseeAssignmentAssessmentData?.assessmentVersionFramework[0]
                .assessmentVersionItemDistinct[0]?.itemFrameworkOne?.itemFrameworkOneType,
              dispatch
            );
            dispatch({
              type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
              payload: {
                stateName: 'assessmentSectionItemDistinctRevise',
                value:
                  assesseeAssignmentAssessmentData?.assessmentVersionFramework[0]
                    .assessmentVersionItemDistinct[0]
              }
            });
          }
          console.log('debugger3');
          dispatch({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: { stateName: 'isAssessmentStart', value: 'PROGRESS' }
          });
          dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneFive' });
        }
      }
    }
  }, [assessmentsequenceObject]);

  console.log('currentSequenceIndex', currentSequenceIndex);
  return (
    <>
      <EditorTemplate
        previewAs={true}
        previewDisable={true}
        label={'itemLabel'}
        jsonData={assessmentsequenceObject && assessmentsequenceObject[currentSequenceIndex]?.value}
      />
    </>
  );
};
export default DisplayPaneSix;
