import React, { useState } from 'react';
import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import '../DisplayPaneFive/DisplayPaneFive.css';
import { isMobile } from 'react-device-detect';
import LoaderImage from '../../images/loading.gif';
import PdfViewer from './PdfViewer';
import DocumentViewer from './DocumentViewer';

const EditorTemplate = (props) => {
  const dispatch = useDispatch();
  const [imgLoader, setimgLoader] = useState(false);
  const { jsonData, previewDisable = false, label } = props;
  function checkExtension(file) {
    var extension = file.substr(file.lastIndexOf('.') + 1);
    switch (extension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return 'image';
      case 'mp4':
      case 'mp3':
      case 'ogg':
      case 'mov':
      case 'MOV':
        return 'video';
      default:
        return -1;
    }
  }
  function onLoadImg(e) {
    setimgLoader(true);
  }
  return (
    <>
      {jsonData?.blocks &&
        jsonData?.blocks.map((dd, index) => {
          return (
            <Fragment key={index}>
              {/* {dd.type === 'paragraph' && <p id={dd.id}>{ReactHTMLParser(dd.data.text)}</p>} */}
              {dd.type === 'paragraph' && (
                <span
                  className={'editor-text-para'}
                  dangerouslySetInnerHTML={{ __html: dd.data.text }}
                ></span>
              )}
              {dd.type === 'embed' && (
                <iframe title={dd.id} width="420" height="315" src={dd.data.embed}></iframe>
              )}
              {dd.type === 'image' && (
                <>
                  {checkExtension(dd.data.file.url) === 'image' && dd.type === 'image' && (
                    <div
                      style={{
                        textAlign: isMobile && label === 'itemFrameworkOneMedia' && 'center'
                      }}
                    >
                      <img
                        id={dd.id}
                        src={imgLoader ? dd.data.file.url : LoaderImage}
                        alt={'img'}
                        className={'imageLoad'}
                        style={{ display: 'inline-flex' }}
                        onLoad={onLoadImg}
                      />
                      {dd.data.caption && <span>{dd.data.caption}</span>}
                    </div>
                  )}
                  {checkExtension(dd.data.file.url) === 'video' && dd.type === 'image' && (
                    <Fragment>
                      <video width="320" height="240" controls>
                        <source src={dd.data.file.url} type="video/mp4" />
                      </video>
                    </Fragment>
                  )}
                </>
              )}
              {dd.type === 'video' && (
                <Fragment>
                  <Fragment>
                    <video width="320" height="240" controls>
                      <source src={dd.data.file.url} type="video/mp4" />
                    </video>
                  </Fragment>
                </Fragment>
              )}
              {dd.type === 'audio' && (
                <audio controls>
                  <source src={dd.data.url} type="audio/mpeg" />
                </audio>
              )}
              {dd.type === 'CheckList' && (
                <Fragment>
                  {dd.data.items.map((chk, i) => {
                    return (
                      <div className={'editor-radio'} key={i}>
                        <input
                          type="radio"
                          id={`html${i}`}
                          name={dd.id}
                          value={chk.text}
                          checked={chk.checked}
                          disabled={previewDisable}
                        />
                        <label
                          className={'editor-radio-label'}
                          for={`html${i}`}
                          dangerouslySetInnerHTML={{ __html: chk.text }}
                        ></label>
                        <br></br>
                      </div>
                    );
                  })}
                </Fragment>
              )}
              {dd.type === 'table' && (
                <Fragment>
                  <table className="editor-table" style={{ width: '100%' }}>
                    {dd.data.content.map((chk, i) => {
                      return (
                        <tr key={i}>
                          {chk.map((ele, key) => {
                            return (
                              <td key={key}>
                                <span dangerouslySetInnerHTML={{ __html: ele }}></span>
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </table>
                </Fragment>
              )}
              {dd.type === 'list' && (
                <Fragment>
                  {dd.data.style === 'unordered' && (
                    <ul className="editor-list">
                      {dd.data.items.map((chk, i) => {
                        return (
                          <li key={i}>
                            <span dangerouslySetInnerHTML={{ __html: chk }}></span>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                  {dd.data.style === 'ordered' && (
                    <ol className="editor-list">
                      {dd.data.items.map((chk, i) => {
                        return (
                          <li key={i}>
                            <span dangerouslySetInnerHTML={{ __html: chk }}></span>
                          </li>
                        );
                      })}
                    </ol>
                  )}
                </Fragment>
              )}
              {dd.type === 'attaches' && (
                <Fragment>
                  {(dd.data.file.url.split('.').pop() === 'pdf' && (
                    <PdfViewer url={dd.data.file.url} />
                  )) ||
                    // (dd.data.file.url.split('.').pop() === 'doc' && (
                    //   <DocumentViewer url={dd.data.file.url} />
                    // )) || 
                    (
                      <embed
                        className={'editor-embed'}
                        width="100%"
                        height="100%"
                        src={
                          'https://docs.google.com/viewer?url=' +
                          dd.data.file.url +
                          '&embedded=true'
                        }
                      />
                    )}
                </Fragment>
              )}
            </Fragment>
          );
        })}
    </>
  );
};

export default EditorTemplate;
