import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, InputLabel, Paper } from '@material-ui/core';
import { Fragment } from 'react';
import ReviewList from '../ReviewList/ReviewList';
import { SET_POPUP_VALUE } from '../../actionType';
import AllocationAccordian from '../Accordian/AllocationAccordian';
import DisplayPanelAccordianReviewListOne from '../Accordian/DisplayPanelAccordianReviewListOne';
import DisplayPanelAccordianInformation from '../Accordian/DisplayPanelAccordianInformation';
import { label } from 'aws-amplify';

const DisplayPaneThreeSectionOneProfiler = () => {
  const dispatch = useDispatch();
  const { cultureProfileDiamentionReviewList, jobProfilerReviewList } = useSelector(
    (state) => state.DisplayPaneTwoReducer
  );
  const { headerOne, reviewMode, responseObject } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  console.log('jobProfilerReviewList', jobProfilerReviewList);
  let frameworkAll = [];
  if (responseObject?.id === 'iGauge Occupation (Job-Profiler)') {
    const jobCometancy = [];
    jobProfilerReviewList?.jobCompetency.map((element) => {
      // element.jobCompetency.map((data) => {
      let obj = {
        id: element.id,
        textOne: element.iGaugeOccupationFrameworkOneClusterName,
        textTwo: element.iGaugeOccupationFrameworkOneClusterDescription,
        status: ''
      };
      jobCometancy.push(obj);
      // })
    });
    const jobDomain = [];
    jobProfilerReviewList?.jobDomain.map((data) => {
      let obj = {
        id: data.id,
        textOne: data.iGaugeOccupationFrameworkOneClusterName,
        textTwo: data.iGaugeOccupationFrameworkOneClusterDescription,
        status: ''
      };
      jobDomain.push(obj);
    });
    const jobFunction = [];
    jobProfilerReviewList?.jobFunction.map((data) => {
      let obj = {
        id: data.id,
        textOne: data.iGaugeOccupationFrameworkOneClusterName,
        textTwo: data.iGaugeOccupationFrameworkOneClusterDescription,
        status: ''
      };
      jobFunction.push(obj);
    });

    const jobRole = [];
    jobProfilerReviewList?.jobRole.map((data) => {
      let obj = {
        id: data.id,
        textOne: data.iGaugeOccupationFrameworkOneClusterName,
        textTwo: data.iGaugeOccupationFrameworkOneClusterDescription,
        status: ''
      };
      jobRole.push(obj);
    });
    frameworkAll.push({
      id: 'job',
      labelTextOneOne: 'job',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'competency',
          innerList: jobCometancy
        },
        {
          labelTextOneOneBadge: 'domain',
          innerList: jobDomain
        },
        {
          labelTextOneOneBadge: 'function',
          innerList: jobFunction
        },
        {
          labelTextOneOneBadge: 'role',
          innerList: jobRole
        }
      ],
      innerAssociateList: [],
      innerInfo: 'profiler',
      isListCard: true
    });
  } else if (responseObject?.id === 'iGauge Occupation (Culture-Profiler)') {
    const cultureDiamentionArr = [];
    cultureProfileDiamentionReviewList.map((element) => {
      element.cultureDimensions.map((data) => {
        let obj = {
          id: data.id,
          textOne: data.label,
          textTwo: data.labelDescription,
          status: ''
        };
        cultureDiamentionArr.push(obj);
      });
    });
    cultureDiamentionArr?.sort((a, b) => a.textOne.localeCompare(b.textOne));
    frameworkAll.push({
      id: 'a3',
      labelTextOneOne: 'culture',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'dimension',
          innerList: cultureDiamentionArr
        }
      ],
      innerInfo: 'No Information',
      isListCard: true
    });
  } else if (headerOne === 'culture-dimension') {
    frameworkAll.push({
      id: 'a3',
      labelTextOneOne: 'culture-dimension',
      multiline: responseObject?.explanation?.length > 40,
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'cluster',
          textOne: responseObject.cluster || 'No Information'
        },
        {
          labelTextOneOneBadge: 'explanation',
          textOne: responseObject.explanation || 'No Information'
        },
        {
          labelTextOneOneBadge: 'polarity',
          textOne: responseObject?.polarity || 'No Information'
        }
      ],
      innerAssociateList: [],
      innerInfo: 'No Information',
      isListCard: false
    });
  } else if (headerOne === 'cluster') {
    frameworkAll.push(
      {
        id: 'explanation1',
        labelTextOneOne: 'explanation',
        labelTextOneOneBadges: [],
        multiline: true,
        textOneOne:
          responseObject?.iGaugeOccupationFrameworkOneClusterExplanation || 'No Information',
        innerAssociateList: [],
        innerInfo: 'No Information',
        isListCard: false
      },
      {
        id: 'sequence1',
        labelTextOneOne: 'sequence',
        isListCard: false,
        textOneOne: responseObject?.iGaugeOccupationFrameworkOneClusterSequence || 'No Information',
        innerAssociateList: [],
        innerInfo: 'No Information',
        IconOne: null
      },
      {
        id: 'cluster',
        labelTextOneOne: 'cluster',
        multiline: false,
        // multiline:
        //   responseObject?.iGaugeOccupationFrameworkOneClusterOneExplanation &&
        //   responseObject?.iGaugeOccupationFrameworkOneClusterOneExplanation?.length > 40,
        labelTextOneOneBadges: [
          {
            //labelTextOneOneBadge: 'primary',
            //textOne: workAddressPrimary,
            labelTextTwoBadge: 'one',
            innerLabelBadgeList: [
              {
                labelTextTwoBadge: 'description',
                innerLabelBadgeList:
                  responseObject?.iGaugeOccupationFrameworkOneClusterOneDescription ||
                  'No Information'
              },
              {
                labelTextTwoBadge: 'explanation',
                innerLabelBadgeList:
                  responseObject?.iGaugeOccupationFrameworkOneClusterOneExplanation ||
                  'No Information'
              },
              {
                labelTextTwoBadge: 'label',
                innerLabelBadgeList:
                  responseObject?.iGaugeOccupationFrameworkOneClusterOneLabel || 'No Information'
              },

              {
                labelTextTwoBadge: 'polarity',
                innerLabelBadgeList:
                  responseObject?.iGaugeOccupationFrameworkOneClusterOnePolarity || 'No Information'
              }
            ]
          },
          {
            labelTextTwoBadge: 'two',
            innerLabelBadgeList: [
              {
                labelTextTwoBadge: 'description',
                innerLabelBadgeList:
                  responseObject?.iGaugeOccupationFrameworkOneClusterTwoDescription ||
                  'No Information'
              },
              {
                labelTextTwoBadge: 'explanation',
                innerLabelBadgeList:
                  responseObject?.iGaugeOccupationFrameworkOneClusterTwoExplanation ||
                  'No Information'
              },
              {
                labelTextTwoBadge: 'label',
                innerLabelBadgeList:
                  responseObject?.iGaugeOccupationFrameworkOneClusterTwoLabel || 'No Information'
              },

              {
                labelTextTwoBadge: 'polarity',
                innerLabelBadgeList:
                  responseObject?.iGaugeOccupationFrameworkOneClusterTwoPolarity || 'No Information'
              }
            ]
          }
        ],
        innerAssociateList: [],
        isListCard: false,
        isReviewLink: false,
        isMultiInfoCard: true
      }
    );
  } else if (headerOne === 'job-competency') {
    frameworkAll.push({
      id: 'a3',
      labelTextOneOne: 'job-competency',
      multiline: true,
      labelTextOneOneBadges: [
        {
          labelTextTwoBadge: 'cluster',
          innerLabelBadgeList:
            responseObject.iGaugeOccupationFrameworkOneClusterName || 'No Information'
        },
        {
          labelTextTwoBadge: 'explanation',
          innerLabelBadgeList:
            responseObject.iGaugeOccupationFrameworkOneExplanation || 'No Information'
        },
        {
          labelTextTwoBadge: 'one',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'description',
              innerLabelBadgeList:
                responseObject?.iGaugeOccupationFrameworkOneClusterOneDescription ||
                'No Information'
            },
            {
              labelTextTwoBadge: 'explanation',
              innerLabelBadgeList:
                responseObject?.iGaugeOccupationFrameworkOneClusterOneExplanation ||
                'No Information'
            },
            {
              labelTextTwoBadge: 'label',
              innerLabelBadgeList:
                responseObject?.iGaugeOccupationFrameworkOneClusterOneLabel || 'No Information'
            },

            {
              labelTextTwoBadge: 'polarity',
              innerLabelBadgeList:
                responseObject?.iGaugeOccupationFrameworkOneClusterOnePolarity || 'No Information'
            }
          ]
        },
        {
          labelTextTwoBadge: 'sequence', //iGaugeOccupationFrameworkOneClusterSequence
          innerLabelBadgeList:
            responseObject?.iGaugeOccupationFrameworkOneClusterSequence || 'No Information'
        },
        {
          labelTextTwoBadge: 'two',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'description',
              innerLabelBadgeList:
                responseObject?.iGaugeOccupationFrameworkOneClusterTwoDescription ||
                'No Information'
            },
            {
              labelTextTwoBadge: 'explanation',
              innerLabelBadgeList:
                responseObject?.iGaugeOccupationFrameworkOneClusterTwoExplanation ||
                'No Information'
            },
            {
              labelTextTwoBadge: 'label',
              innerLabelBadgeList:
                responseObject?.iGaugeOccupationFrameworkOneClusterTwoLabel || 'No Information'
            },

            {
              labelTextTwoBadge: 'polarity',
              innerLabelBadgeList:
                responseObject?.iGaugeOccupationFrameworkOneClusterTwoPolarity || 'No Information'
            }
          ]
        }
      ],
      innerAssociateList: [],
      innerInfo: 'No Information',
      isListCard: false,
      isReviewLink: false,
      isMultiInfoCard: true
    });
  } else if (headerOne === 'job-domain') {
    frameworkAll.push({
      id: 'a3',
      labelTextOneOne: 'job-domain',
      multiline: responseObject.explanation && responseObject.explanation?.length > 40,
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'cluster',
          textOne: responseObject.cluster || 'No Information'
        },
        {
          labelTextOneOneBadge: 'explanation',
          textOne: responseObject.explanation || 'No Information'
        },
        {
          labelTextOneOneBadge: 'polarity',
          textOne: responseObject?.polarity || 'No Information'
        }
      ],
      innerAssociateList: [],
      innerInfo: 'No Information',
      isListCard: false
    });
  } else if (headerOne === 'job-function') {
    frameworkAll.push({
      id: 'a3',
      labelTextOneOne: 'job-function',
      multiline: responseObject?.explanation && responseObject.explanation?.length > 40,
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'cluster',
          textOne: responseObject.cluster || 'No Information'
        },
        {
          labelTextOneOneBadge: 'explanation',
          textOne: responseObject.explanation || 'No Information'
        },
        {
          labelTextOneOneBadge: 'polarity',
          textOne: responseObject?.polarity || 'No Information'
        }
      ],
      innerAssociateList: [],
      innerInfo: 'No Information',
      isListCard: false
    });
  } else if (headerOne === 'job-role') {
    frameworkAll.push({
      id: 'a3',
      labelTextOneOne: 'job-role',
      multiline: responseObject?.explanation && responseObject.explanation?.length > 40,
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'cluster',
          textOne: responseObject.cluster || 'No Information'
        },
        {
          labelTextOneOneBadge: 'explanation',
          textOne: responseObject.explanation || 'No Information'
        },
        {
          labelTextOneOneBadge: 'polarity',
          textOne: responseObject?.polarity || 'No Information'
        }
      ],
      innerAssociateList: [],
      innerInfo: 'No Information',
      isListCard: false
    });
  }
  const reviseFramework = (e, selectedBadgeArray) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    console.log('=====>', labelName);
    console.log('=====>', selectedBadgeName);
    let badgeName = '';
    if (selectedBadgeArray) {
      if (selectedBadgeArray.length > 0) {
        selectedBadgeArray.forEach((element) => {
          badgeName = badgeName + element.labelTextTwoBadge;
        });
      }
    }
    if (headerOne === 'cluster' || labelName === 'job-competency') {
      if (labelName === 'sequence') {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'SEQUENCEPOPUP', popupMode: 'CULTUREJOBPROFILERCREATE' }
        });
      }
      if (labelName === 'explanation') {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'CLUSEREXPLANATIONPOPUP', popupMode: 'CULTUREJOBPROFILERCREATE' }
        });
      }
      if (
        (labelName === 'cluster' || labelName === 'job-competency') &&
        badgeName === 'one' &&
        selectedBadgeName === 'label'
      ) {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'ONEONELABELPOPUP', popupMode: 'CULTUREJOBPROFILERCREATE' }
        });
      }
      if (
        (labelName === 'cluster' || labelName === 'job-competency') &&
        badgeName === 'two' &&
        selectedBadgeName === 'label'
      ) {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'ONETWOLABELPOPUP', popupMode: 'CULTUREJOBPROFILERCREATE' }
        });
      }
      if (
        (labelName === 'cluster' || labelName === 'job-competency') &&
        badgeName === 'one' &&
        selectedBadgeName === 'description'
      ) {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'ONEONEDESCRIPTION', popupMode: 'CULTUREJOBPROFILERCREATE' }
        });
      }
      if (
        (labelName === 'cluster' || labelName === 'job-competency') &&
        badgeName === 'two' &&
        selectedBadgeName === 'description'
      ) {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'ONETWODESCRIPTION', popupMode: 'CULTUREJOBPROFILERCREATE' }
        });
      }
      if (
        (labelName === 'cluster' || labelName === 'job-competency') &&
        badgeName === 'one' &&
        selectedBadgeName === 'explanation'
      ) {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'ONEONEEXPLANATION', popupMode: 'CULTUREJOBPROFILERCREATE' }
        });
      }
      if (
        (labelName === 'cluster' || labelName === 'job-competency') &&
        badgeName === 'two' &&
        selectedBadgeName === 'explanation'
      ) {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'ONETWOEXPLANATION', popupMode: 'CULTUREJOBPROFILERCREATE' }
        });
      }
      if (
        (labelName === 'cluster' || labelName === 'job-competency') &&
        badgeName === 'one' &&
        selectedBadgeName === 'polarity'
      ) {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'ONEONEPOLARITY', popupMode: 'CULTUREJOBPROFILERCREATE' }
        });
      }
      if (
        (labelName === 'cluster' || labelName === 'job-competency') &&
        badgeName === 'two' &&
        selectedBadgeName === 'polarity'
      ) {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'ONETWOPOLARITY', popupMode: 'CULTUREJOBPROFILERCREATE' }
        });
      }
    } else {
      if (
        (labelName === 'culture-dimension' ||
          labelName === 'job-role' ||
          labelName === 'job-function' ||
          labelName === 'job-domain') &&
        selectedBadgeName === 'cluster'
      ) {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'CLUSTERPOPUP', popupMode: 'CULTUREJOBPROFILERCREATE' }
        });
      }
      if (
        (labelName === 'culture-dimension' ||
          labelName === 'job-role' ||
          labelName === 'job-competency' ||
          labelName === 'job-function' ||
          labelName === 'job-domain') &&
        selectedBadgeName === 'sequence'
      ) {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'SEQUENCEPOPUP', popupMode: 'CULTUREJOBPROFILERCREATE' }
        });
      }
      if (
        (labelName === 'culture-dimension' ||
          labelName === 'job-role' ||
          labelName === 'job-competency' ||
          labelName === 'job-function' ||
          labelName === 'job-domain') &&
        selectedBadgeName === 'explanation'
      ) {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'EXPLANATIONPOPUP', popupMode: 'CULTUREJOBPROFILERCREATE' }
        });
      }
      if (
        (labelName === 'culture-dimension' ||
          labelName === 'job-role' ||
          labelName === 'job-competency' ||
          labelName === 'job-function' ||
          labelName === 'job-domain') &&
        selectedBadgeName === 'polarity'
      ) {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'POLARITYPOPUP', popupMode: 'CULTUREJOBPROFILERCREATE' }
        });
      }
    }
  };
  return (
    <>
      <div className="containerPadding">
        <Paper className={'dossierContainerTop'}>
          {frameworkAll.map((ob) => {
            return (
              <div key={ob.id}>
                {ob.isListCard ? (
                  <DisplayPanelAccordianReviewListOne
                    onClickRevise={reviseFramework}
                    className=""
                    accordianObject={ob}
                    mode={reviewMode}
                  />
                ) : (
                  <DisplayPanelAccordianInformation
                    onClickRevise={reviseFramework}
                    accordianObject={ob}
                    mode={reviewMode}
                  />
                )}
              </div>
            );
          })}
        </Paper>
      </div>
    </>
  );
};

export default DisplayPaneThreeSectionOneProfiler;
