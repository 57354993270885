import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import AllocationAccordian from '../Accordian/AllocationAccordian';
import { useDispatch, useSelector } from 'react-redux';
import { Paper } from '@material-ui/core';
import DisplayPanelAccordianReviewListOne from '../Accordian/DisplayPanelAccordianReviewListOne';
import DisplayPanelAccordianInformation from '../Accordian/DisplayPanelAccordianInformation';
import Manuscript from '@material-ui/icons/Description';
import AddIcon from '@material-ui/icons/Add';
import {
  convertSecondsToHMmSs,
  makeAssessmentPreviewSequenceObj,
  makeItemGroupObj,
  makeItemObj,
  DisplayDate
} from '../../Actions/GenericActions';
import {
  FILTERMODE,
  GET_ALLOCATE_ITEM,
  LOADER_START,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_PANE_THREE_PREVIEW_MODE,
  SET_POPUP_VALUE,
  SET_PANE_THREE_ASSESSMENT_PREVIEW_MODE,
  SET_ASSESSMENT_SINGLE_STATE,
  GET_ALLOCATE_ITEM_GROUP,
  SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
  SET_MIDDLEPANE_STATE,
  RELATED_REVIEWLIST_DISTINCT_DATA,
  SET_RELATED_REQUEST_OBJECT,
  GET_ASSESSMENT_SEC_INFO_SAGA,
  CLEAR_DISPLAY_PANE_THREE
} from '../../actionType';
import { ASSESSMENT_PREIVEW_SEQUENCE_ERROR } from '../../errorMessage.js';

const DisplayPaneThreeSectionTwoAssessment = () => {
  const [listExpand, setListExpand] = useState('');
  const {headerOneBadgeTwo, headerOneBadgeThree, reviewMode, responseObject } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  const {
    selectedAssociateInfo,
    typeOfMiddlePaneList,
    middlePaneHeaderDuplicate,
    middlePaneHeaderDuplicateBadgeOne,
    middlePaneHeaderDuplicateBadgeTwo,
    relatedReviewListDistinctData,
    duplicateScanCount,
    countPage,
    assignmentCreateType
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const assessmentReducerInfo = useSelector((state) => state.AssessmentReducer);
  const { informationFramework } = responseObject;
  const dispatch = useDispatch();
  let assessmentItemArr = [];
  let assessmentGroupArr = [];
  let assessmentItemTrailArr = [];
  let versionArr = [];
  if (informationFramework?.assessmentSectionFramework?.length > 0) {
    if (
      informationFramework?.assessmentSectionFramework[0]?.assessmentVersionFramework?.length > 0
    ) {
      informationFramework?.assessmentSectionFramework[0]?.assessmentVersionFramework?.map(
        (comm, index) => {
          versionArr.push({ labelTextOneOneBadge: index + 1, textOne: '' });
        }
      );
    }
  }
  let communiqueArr = [];
  informationFramework?.assessmentCommunique.map((comm, index) => {
    communiqueArr.push({ labelTextOneOneBadge: index + 1, textOne: '' });
  });
  let manuscriptArr = [];
  informationFramework?.assessmentManuscript.map((comm, index) => {
    manuscriptArr.push({ labelTextOneOneBadge: index + 1, textOne: '' });
  });
  let synopsisArr = [];
  informationFramework?.assessmentSynopsis.map((comm, index) => {
    synopsisArr.push({ labelTextOneOneBadge: index + 1, textOne: '' });
  });
  let sectionArr = [];
  if (informationFramework?.assessmentSectionFramework?.length > 0) {
    informationFramework?.assessmentSectionFramework?.map((comm, index) => {
      sectionArr.push({ labelTextOneOneBadge: index + 1, textOne: '' });
    });
  }
  let adminSequenseArr = [];
  assessmentReducerInfo?.informationFramework?.assessmentCommunique?.map((comm, ind) => {
    // adminSequenseArr.push({id:`assessment communiqué ${ind + 1}`,name:'assessment (communiqué) (${ind + 1})'});
    adminSequenseArr.push({
      id: `assessment communiqué ${ind + 1}`,
      name: `assessment (communiqué) (${ind + 1})`
    });
  });
  assessmentReducerInfo?.informationFramework?.assessmentItemTrial?.length > 0 &&
    adminSequenseArr.push({ id: `item trial`, name: `item (trial)` });

  assessmentReducerInfo?.informationFramework?.assessmentSectionFramework.map((comm, ind) => {
    // adminSequenseArr.push(`assessment (section) (${ind + 1})`);
    adminSequenseArr.push({
      id: `assessment section ${ind + 1}`,
      name: `assessment (section) (${ind + 1})`
    });
  });
  assessmentReducerInfo?.informationFramework?.assessmentManuscript.map((com, ind) => {
    // adminSequenseArr.push(`assessment (manuscript) (${ind + 1})`);
    adminSequenseArr.push({
      id: `assessment manuscript ${ind + 1}`,
      name: `assessment (manuscript) (${ind + 1})`
    });
  });
  assessmentReducerInfo?.informationFramework?.assessmentSynopsis.map((com, ind) => {
    // adminSequenseArr.push(`assessment (synopsis) (${ind + 1})`);
    adminSequenseArr.push({
      id: `assessment synopsis ${ind + 1}`,
      name: `assessment (synopsis) (${ind + 1})`
    });
  });
  let clusterObj = informationFramework?.assessmentClusterFramework || [];
  let scaleObj = informationFramework?.assessmentScaleFramework || [];
  let gradeObj = informationFramework?.assessmentScoreGradeFramework || [];
  let normObj = informationFramework?.assessmentNormFramework || [];
  let itemDistinctObj = informationFramework?.assessmentItemDistinct || [];
  let itemTrialObj = informationFramework?.assessmentItemTrial || [];

  let clusterArray = [];
  clusterObj.forEach((ob) => {
    clusterArray.push({
      textOne: ob?.assessmentClusterName || '',
      textTwo: ob?.assessmentClusterDescription,
      status: ob?.clusterQuestionCount || 0
    });
  });
  itemDistinctObj.forEach((ob) => {
    assessmentItemArr.push({
      id: ob?.itemTagPrimary,
      textOne: ob?.informationBasic?.itemName || '',
      textTwo: ob?.informationBasic?.itemDescription,
      status: ob?.informationEngagement?.itemStatus || '',
      isShared: ob?.itemShared,
      associateId: ob.associateId
    });
  });
  itemTrialObj.forEach((ob) => {
    assessmentItemTrailArr.push({
      id: ob?.itemTagPrimary,
      textOne: ob?.informationBasic?.itemName || '',
      textTwo: ob?.informationBasic?.itemDescription,
      status: ob?.informationEngagement?.itemStatus || '',
      isShared: ob?.itemShared,
      associateId: ob.associateId
    });
  });

  let scaleArray = [];
  scaleObj.forEach((ob) => {
    scaleArray.push({
      id: ob?.id + '-',
      textOne: ob?.assessmentScaleName || '',
      textTwo: ob?.assessmentScaleDescription || '',
      status: ''
    });
  });
  let gradeArray = [];
  gradeObj.forEach((ob) => {
    gradeArray.push({
      id: ob?.id + '-',
      textOne: ob?.assessmentScoreGradeName || '',
      textTwo: ob?.assessmentScoreGradeDescription || ''
    });
  });
  let normArray = [];
  normObj.forEach((ob) => {
    normArray.push({
      id: ob?.id + '-',
      textOne: ob?.assessmentNormName || '',
      textTwo: ob?.assessmentNormDescription || '',
      status: ''
    });
  });
  let selectNormArray = [];
  normObj.forEach((ob) => {
    // if (ob.id === responseObject?.informationFramework?.assessmentNormFrameworkSelected) {
    if (
      relatedReviewListDistinctData.length > 0 &&
      (relatedReviewListDistinctData[0].assignmentStatus === 'ADMINISTERED' ||
        relatedReviewListDistinctData[0].assignmentStatus === 'PUBLISHED')
    ) {
      selectNormArray.push({
        id: ob?.id + '-',
        textOne: ob?.assessmentNormName || '',
        textTwo: ob?.assessmentNormDescription || '',
        status: ob?.assessmentStatus || ''
      });
    } else {
      if (ob.id === responseObject?.informationFramework?.assessmentNormFrameworkSelected) {
        selectNormArray.push({
          id: ob?.id + '-',
          textOne: ob?.assessmentNormName || '',
          textTwo: ob?.assessmentNormDescription || '',
          status: ob?.assessmentStatus || ''
        });
      }
    }

    // }
  });

  let shuffleString =
    (responseObject?.informationFramework?.assessmentAdministrationShuffle
      ?.assessmentAdministrationShuffleItem &&
      responseObject?.informationFramework?.assessmentAdministrationShuffle
        ?.assessmentAdministrationShuffleResponseChoice &&
      'Item & Response-Choice') ||
    (responseObject?.informationFramework?.assessmentAdministrationShuffle
      ?.assessmentAdministrationShuffleItem &&
      'Item') ||
    (responseObject?.informationFramework?.assessmentAdministrationShuffle
      ?.assessmentAdministrationShuffleResponseChoice &&
      'Response-Choice') ||
    'Disabled';
  const frameworkAll = [
    {
      id: 'administration',
      labelTextOneOne: 'administration',
      labelTextOneOneBadges: [
        {
          //labelTextOneOneBadge: 'proctor',
          labelTextTwoBadge: 'proctor',
          innerLabelBadgeList: informationFramework?.assessmentAdministrationProctor ? 'Yes' : 'No'
          //textOne: informationFramework?.assessmentAdministrationProctor ? 'Yes' : 'No'
        },
        {
          //labelTextOneOneBadge: 'sequence',
          labelTextTwoBadge: 'sequence',
          innerLabelBadgeList:
            informationFramework?.assessmentAdministrationSequence?.toString() || 'No Information'
          //labelTextOneOne: informationFramework?.assessmentAdministrationSequence || 'No Information'
          //textOne: informationFramework?.assessmentAdministrationSequence || 'No Information'
        },
        {
          //labelTextOneOneBadge: 'shuffle',
          labelTextTwoBadge: 'shuffle',
          innerLabelBadgeList: shuffleString
          //textOne: shuffleString
        },
        {
          //labelTextOneOneBadge: 'supervise',
          labelTextTwoBadge: 'supervise',
          innerLabelBadgeList: informationFramework?.assessmentAdministrationSupervise
            ? 'Yes'
            : 'No'
          //textOne: informationFramework?.assessmentAdministrationSupervise ? 'Yes' : 'No'
        },
        {
          labelTextTwoBadge: 'template',
          //textOne: informationFramework?.assessmentAdministrationTemplate
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'alignment',
              innerLabelBadgeList:
                informationFramework?.assessmentAdministrationTemplateAlignment
                  ?.assessmentItemFrameworkOneResponseChoiceAlignment || 'No Information'
            },
            {
              labelTextTwoBadge: 'media',
              innerLabelBadgeList:
                informationFramework?.assessmentItemFrameworkOneTemplateMedia || 'No Information'
            }
          ]
        }
      ],
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false,
      isMultiInfoCard: true
    },
    {
      id: 'clustera1',
      labelTextOneOne: 'cluster',
      labelTextOneOneBadgeOne: 'distinct',
      labelTextOneOneBadgeTwo: 'polarity',
      labelTextOneOneBadgeThree: '',
      labelTextOneOneBadgeFour: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'distinct',
          innerList: clusterArray
        },
        {
          labelTextOneOneBadge: 'polarity',
          innerList: [
            {
              id: 'associate1',
              textOne: 'positive',
              textTwo: '',
              status: ''
            },
            {
              id: 'associate1',
              textOne: 'negative',
              textTwo: '',
              status: ''
            }
          ]
        }
      ],
      innerInfo: 'No Information',
      isListCard: true
    },
    {
      id: 'communiquéa1',
      labelTextOneOne: 'communiqué',
      labelTextOneOneBadges: communiqueArr,
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false,
      IconOne: reviewMode === 'revise' ? AddIcon : Manuscript,
      isAddIcon: reviewMode === 'revise' ? true : false,
      isReviewLink: typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList'
    },
    {
      id: 'gradea1',
      labelTextOneOne: 'grade',
      labelTextOneOneBadgeTwo: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'distinct',
          innerList: gradeArray
        }
      ],
      innerInfo: 'No Information',
      isListCard: true
    },
    {
      id: 'a4',
      labelTextOneOne: 'items',
      labelTextOneOneBadgeTwo: 'items',
      isListCard: true,
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'distinct',
          // innerList: assessmentItemArr.sort((a, b) => a.textOne.localeCompare(b.textOne)).reverse()
          innerList: assessmentItemArr.sort((a, b) => a.textOne.localeCompare(b.textOne.toLowerCase()))
        },
        {
          labelTextOneOneBadge: 'group',
          innerList: assessmentGroupArr || 'No Information'
        },
        {
          labelTextOneOneBadge: 'total',
          innerList:
            [
              {
                id: 'total1',
                textOne: informationFramework?.assessmentItemTotal || 'No Information',
                textTwo: null
              }
            ] || 'No Information'
        },
        {
          labelTextOneOneBadge: 'trial',
          innerList: assessmentItemTrailArr
        }
      ],
      innerAssociateList: [],
      innerInfo: 'No Information',
      IconOne: null,
      isMultiInfoCard: false
    },
    {
      id: 'm1',
      labelTextOneOne: 'manuscript',
      labelTextOneOneBadges: manuscriptArr,
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false,
      IconOne: reviewMode === 'revise' ? AddIcon : Manuscript,
      isAddIcon: reviewMode === 'revise' ? true : false
    },
    {
      id: 'm21',
      labelTextOneOne: 'norm',
      labelTextOneOneBadgeTwo: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'distinct',
          innerList:
            typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList'
              ? selectNormArray
              : normArray
        }
      ],
      innerInfo: 'No Information',
      isListCard: true
    },
    {
      id: 'preview-assessment',
      labelTextOneOne: 'preview',
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false,
      IconOne: null,
      isReviewLink: true
    },
    {
      id: 'scalea1',
      labelTextOneOne: 'scale',
      labelTextOneOneBadgeTwo: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'distinct',
          innerList: scaleArray
        },
        {
          labelTextOneOneBadge: '',
          innerList: []
        }
      ],
      innerInfo: 'No Information',
      isListCard: true
    },
    {
      id: 'score',
      labelTextOneOne: 'score',
      labelTextOneOneBadges: [
        {
          labelTextTwoBadge: 'distinct',
          innerList: gradeArray
        },
        {
          labelTextTwoBadge: 'extremum',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'maximum',
              innerLabelBadgeList:
                informationFramework?.assessmentScore?.assessmentScoreExtremumMaximum ||
                'No Information'
            },
            {
              labelTextTwoBadge: 'minimum',
              innerLabelBadgeList:
                informationFramework?.assessmentScore?.assessmentScoreExtremumMinimum ||
                'No Information'
            }
          ]
        },
        {
          labelTextTwoBadge: 'mean',
          innerLabelBadgeList:
            informationFramework?.assessmentScore?.assessmentScoreMean || 'No Information'
        },
        {
          labelTextTwoBadge: 'median',
          innerLabelBadgeList:
            informationFramework?.assessmentScore?.assessmentScoreMedian || 'No Information'
        },
        {
          labelTextTwoBadge: 'negative',
          innerLabelBadgeList:
            informationFramework?.assessmentScore?.assessmentScoreNegative || 'No Information'
        },
        {
          labelTextTwoBadge: 'standard-deviation',
          innerLabelBadgeList:
            informationFramework?.assessmentScore?.assessmentScoreStandardDeviation ||
            'No Information'
        }
      ],
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false,
      isReviewLink: false,
      isMultiInfoCard: true
    },
    {
      id: 'a5',
      labelTextOneOne: 'section',
      isListCard: false,
      labelTextOneOneBadges: sectionArr,
      innerAssociateList: [],
      innerInfo: 'assessment',
      IconOne: null
    },
    {
      id: 'synopsis',
      labelTextOneOne: 'synopsis',
      labelTextOneOneBadges: synopsisArr,
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false,
      IconOne: reviewMode === 'revise' ? AddIcon : Manuscript,
      isAddIcon: reviewMode === 'revise' ? true : false
    },
    {
      id: 'a6',
      labelTextOneOne: 'time',
      isListCard: false,
      textOneOne: convertSecondsToHMmSs(informationFramework?.assessmentTime) || 'No Information',
      innerAssociateList: [],
      innerInfo: 'No Information',
      IconOne: null
    },
    {
      id: 'versiona1',
      labelTextOneOne: 'version',
      labelTextOneOneBadges: versionArr,
      innerAssociateList: [],
      innerInfo: '',
      isListCard: false
    }
  ];
  if (
    typeOfMiddlePaneList !== 'assessmentDistinctReviewList' &&
    typeOfMiddlePaneList !== 'assessmentItemReviewList' &&
    typeOfMiddlePaneList !== 'assessmentSectionItemGroupDistinctReviewList' &&
    typeOfMiddlePaneList !== 'assessmentSectionTrialDistinctReviewList' &&
    typeOfMiddlePaneList !== ''
  ) {
    let newObj = {
      id: 'a7',
      labelTextOneOne: 'timeline',
      isListCard: false,
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'start',
          textOne:
            informationFramework?.assessmentTimeline?.assessmentTimelineDateTimeStart ? DisplayDate(informationFramework?.assessmentTimeline?.assessmentTimelineDateTimeStart) :
              'No Information'
        },
        {
          labelTextOneOneBadge: 'end',
          textOne:
            informationFramework?.assessmentTimeline?.assessmentTimelineDateTimeEnd ? DisplayDate(informationFramework?.assessmentTimeline?.assessmentTimelineDateTimeEnd) :
              'No Information'
        },
        {
          labelTextOneOneBadge: '+',
          textOne:
            (informationFramework?.assessmentTimeline?.assessmentTimelineType &&
              informationFramework?.assessmentTimeline?.assessmentTimelineType.toLowerCase()) ||
            'No Information'
        }
      ],
      labelTextOneOneBadgeTwo: '',
      innerAssociateList: [],
      innerInfo: '',
      IconOne: null
    };
    let newObj2 = {
      labelTextTwoBadge: 'version',
      innerLabelBadgeList:
        (informationFramework?.assessmentAdministrationVersion
          ?.assessmentAdministrationVersionType === 'VERSION_HYBRID' &&
          'assessment (version) (hybrid)') ||
        (informationFramework?.assessmentAdministrationVersion
          ?.assessmentAdministrationVersionType === 'VERSION_STATIC' &&
          'assessment (version) (static) (single)') ||
        (informationFramework?.assessmentAdministrationVersion
          ?.assessmentAdministrationVersionType === 'VERSION_STATIC_RANDOM' &&
          'assessment (version) (static) (multiple)') ||
        'No Information'
    };
    // let normSeclectedObj = {
    //   labelTextOneOneBadge: 'selected',
    //   innerList: selectNormArray
    // };
    // if (informationFramework?.assessmentNormFramework?.length > 0) {
    //   frameworkAll[6].labelTextOneOneBadges.splice(1, 0, normSeclectedObj);
    // }
    frameworkAll.splice(12, 0, newObj);
    frameworkAll[0].labelTextOneOneBadges.splice(4, 0, newObj2);
  }
  const frameworkKey = [
    {
      id: 'administration',
      labelTextOneOne: 'administration',
      labelTextOneOneBadges: [
        {
          //labelTextOneOneBadge: 'proctor',
          labelTextTwoBadge: 'proctor',
          innerLabelBadgeList: informationFramework?.assessmentAdministrationProctor ? 'Yes' : 'No'
          //textOne: informationFramework?.assessmentAdministrationProctor ? 'Yes' : 'No'
        },
        {
          //labelTextOneOneBadge: 'sequence',
          labelTextTwoBadge: 'sequence',
          innerLabelBadgeList:
            informationFramework?.assessmentAdministrationSequence?.toString() || 'No Information'
          //labelTextOneOne: informationFramework?.assessmentAdministrationSequence || 'No Information'
          //textOne: informationFramework?.assessmentAdministrationSequence || 'No Information'
        },
        {
          //labelTextOneOneBadge: 'shuffle',
          labelTextTwoBadge: 'shuffle',
          innerLabelBadgeList: shuffleString
          //textOne: shuffleString
        },
        {
          //labelTextOneOneBadge: 'supervise',
          labelTextTwoBadge: 'supervise',
          innerLabelBadgeList: informationFramework?.assessmentAdministrationSupervise
            ? 'Yes'
            : 'No'
          //textOne: informationFramework?.assessmentAdministrationSupervise ? 'Yes' : 'No'
        },
        {
          labelTextTwoBadge: 'template',
          //textOne: informationFramework?.assessmentAdministrationTemplate
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'alignment',
              innerLabelBadgeList:
                informationFramework?.assessmentAdministrationTemplateAlignment
                  ?.assessmentItemFrameworkOneResponseChoiceAlignment || 'No Information'
            },
            {
              labelTextTwoBadge: 'media',
              innerLabelBadgeList:
                informationFramework?.assessmentItemFrameworkOneTemplateMedia || 'No Information'
            }
          ]
        }
      ],
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false,
      isMultiInfoCard: true
    },
    {
      id: 'a4',
      labelTextOneOne: 'items',
      labelTextOneOneBadgeTwo: 'items',
      isListCard: true,
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'distinct',
          // innerList: assessmentItemArr.sort((a, b) => a.textOne.localeCompare(b.textOne)).reverse()
          innerList: assessmentItemArr.sort((a, b) => a.textOne.localeCompare(b.textOne.toLowerCase()))
        },
        {
          labelTextOneOneBadge: 'group',
          innerList: assessmentGroupArr || 'No Information'
        },
        {
          labelTextOneOneBadge: 'total',
          innerList:
            [
              {
                id: 'total1',
                textOne: informationFramework?.assessmentItemTotal || 'No Information',
                textTwo: null
              }
            ] || 'No Information'
        },
        {
          labelTextOneOneBadge: 'trial',
          innerList: assessmentItemTrailArr
        }
      ],
      innerAssociateList: [],
      innerInfo: 'No Information',
      IconOne: null,
      isMultiInfoCard: false
    },
    {
      id: 'preview-assessment',
      labelTextOneOne: 'preview',
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false,
      IconOne: null,
      isReviewLink: true
    },
    {
      id: 'score',
      labelTextOneOne: 'score',
      labelTextOneOneBadges: [
        {
          labelTextTwoBadge: 'distinct',
          innerList: gradeArray
        },
        {
          labelTextTwoBadge: 'extremum',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'maximum',
              innerLabelBadgeList:
                informationFramework?.assessmentScore?.assessmentScoreExtremumMaximum ||
                'No Information'
            },
            {
              labelTextTwoBadge: 'minimum',
              innerLabelBadgeList:
                informationFramework?.assessmentScore?.assessmentScoreExtremumMinimum ||
                'No Information'
            }
          ]
        },
        {
          labelTextTwoBadge: 'mean',
          innerLabelBadgeList:
            informationFramework?.assessmentScore?.assessmentScoreMean || 'No Information'
        },
        {
          labelTextTwoBadge: 'median',
          innerLabelBadgeList:
            informationFramework?.assessmentScore?.assessmentScoreMedian || 'No Information'
        },
        {
          labelTextTwoBadge: 'negative',
          innerLabelBadgeList:
            informationFramework?.assessmentScore?.assessmentScoreNegative || 'No Information'
        },
        {
          labelTextTwoBadge: 'standard-deviation',
          innerLabelBadgeList:
            informationFramework?.assessmentScore?.assessmentScoreStandardDeviation ||
            'No Information'
        }
      ],
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false,
      isReviewLink: false,
      isMultiInfoCard: true
    },
    {
      id: 'a6',
      labelTextOneOne: 'time',
      isListCard: false,
      textOneOne: convertSecondsToHMmSs(informationFramework?.assessmentTime) || 'No Information',
      innerAssociateList: [],
      innerInfo: 'No Information',
      IconOne: null
    },
  ];
  const frameworkPlusAll = [
    //   {
    //     id: 'a2',
    //     labelTextOneOne: 'timeline',
    //     isListCard: false,
    //     labelTextOneOneBadges: [
    //       {
    //         labelTextOneOneBadge: 'start',
    //         textOne: 'No Information'
    //       },
    //       {
    //         labelTextOneOneBadge: 'end',
    //         textOne: 'No Information'
    //       },
    //       {
    //         labelTextOneOneBadge: '+',
    //         innerLabelBadgeList: 'No'
    //       }
    //     ],
    //     labelTextOneOneBadgeTwo: '',
    //     innerAssociateList: [],
    //     innerInfo: '',
    //     IconOne: null
    //   }
  ];
  const onClickReview = (e)=>{
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    console.log('selectedBadgeName',selectedBadgeName);
    if (labelName === 'preview') {
      dispatch({
        type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
        payload: { stateName: 'assessmentsequenceObject', value: [] }
      });
      dispatch({
        type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
        payload: { stateName: 'currentSequenceIndex', value: 0 }
      });
      dispatch({ type: SET_PANE_THREE_ASSESSMENT_PREVIEW_MODE, payload: true });
      dispatch({
        type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
        payload: { stateName: 'assesseeAssignmentAssessmentData', value: responseObject }
      });
      dispatch({
        type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
        payload: {
          stateName: 'assesseeAssessmentStartData',
          value: null
        }
      });
      dispatch({
        type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
        payload: { stateName: 'isAssessmentStart', value: 'PREVIEW' }
      });
      dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneSix' });
      makeAssessmentPreviewSequenceObj(responseObject, dispatch);
    }
    if (labelName === 'communiqué' && selectedBadgeName !== '') {
      dispatch({
        type: SET_PANE_THREE_PREVIEW_MODE,
        payload: {
          isPreviewShow: true,
          previewHeaderOne: 'assessment',
          previewHeaderOneBadgeOne: 'communiqué',
          previewHeaderOneBadgeTwo: selectedBadgeName,
          previewHeaderOneBadgeThree: '',
          previewInnerHTML: informationFramework?.assessmentCommunique[selectedBadgeName - 1] || ''
        }
      });
      // document.getElementById('displayPaneFourDiv').scrollTop = '0px'; //for scroll top
      dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
    }
    if (labelName === 'manuscript' && selectedBadgeName !== '') {
      dispatch({
        type: SET_PANE_THREE_PREVIEW_MODE,
        payload: {
          isPreviewShow: true,
          previewHeaderOne: 'assessment',
          previewHeaderOneBadgeOne: 'manuscript',
          previewHeaderOneBadgeTwo: selectedBadgeName,
          previewHeaderOneBadgeThree: '',
          previewInnerHTML: informationFramework?.assessmentManuscript[selectedBadgeName - 1] || ''
        }
      });
      // document.getElementById('displayPaneFourDiv').scrollTop = '0px'; //for scroll top
      dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
    }
    if (labelName === 'synopsis' && selectedBadgeName !== '') {
      dispatch({
        type: SET_PANE_THREE_PREVIEW_MODE,
        payload: {
          isPreviewShow: true,
          previewHeaderOne: 'assessment',
          previewHeaderOneBadgeOne: 'synopsis',
          previewHeaderOneBadgeTwo: selectedBadgeName,
          previewHeaderOneBadgeThree: '',
          previewInnerHTML: informationFramework?.assessmentSynopsis[selectedBadgeName - 1] || ''
        }
      });
      document.getElementById('displayPaneFourDiv').scrollTop = '0px'; //for scroll top
      dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
    }
  }
  const onClickReviseIcon = (e, badge) => {
    if (typeof e === 'object') {
      const headerOne = e.currentTarget.getAttribute('data-value');
      const badgeOne = e.currentTarget.getAttribute('data-key');
      if (headerOne === 'preview') {
        dispatch({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: { stateName: 'assessmentsequenceObject', value: [] }
        });
        dispatch({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: { stateName: 'currentSequenceIndex', value: 0 }
        });
        dispatch({ type: SET_PANE_THREE_ASSESSMENT_PREVIEW_MODE, payload: true });
        dispatch({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: { stateName: 'assesseeAssignmentAssessmentData', value: responseObject }
        });
        dispatch({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: {
            stateName: 'assesseeAssessmentStartData',
            value: null
          }
        });
        dispatch({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: { stateName: 'isAssessmentStart', value: 'PREVIEW' }
        });
        dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneSix' });
        makeAssessmentPreviewSequenceObj(responseObject, dispatch);
      }
    }
    if (reviewMode === 'review') {
      if (e === 'communiqué' && badge !== '') {
        dispatch({
          type: SET_PANE_THREE_PREVIEW_MODE,
          payload: {
            isPreviewShow: true,
            previewHeaderOne: 'assessment',
            previewHeaderOneBadgeOne: 'communiqué',
            previewHeaderOneBadgeTwo: badge,
            previewHeaderOneBadgeThree: '',
            previewInnerHTML: informationFramework?.assessmentCommunique[badge - 1] || ''
          }
        });
        // document.getElementById('displayPaneFourDiv').scrollTop = '0px'; //for scroll top
        dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
      }
      if (e === 'manuscript' && badge !== '') {
        dispatch({
          type: SET_PANE_THREE_PREVIEW_MODE,
          payload: {
            isPreviewShow: true,
            previewHeaderOne: 'assessment',
            previewHeaderOneBadgeOne: 'manuscript',
            previewHeaderOneBadgeTwo: badge,
            previewHeaderOneBadgeThree: '',
            previewInnerHTML: informationFramework?.assessmentManuscript[badge - 1] || ''
          }
        });
        // document.getElementById('displayPaneFourDiv').scrollTop = '0px'; //for scroll top
        dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
      }
      if (e === 'synopsis' && badge !== '') {
        dispatch({
          type: SET_PANE_THREE_PREVIEW_MODE,
          payload: {
            isPreviewShow: true,
            previewHeaderOne: 'assessment',
            previewHeaderOneBadgeOne: 'synopsis',
            previewHeaderOneBadgeTwo: badge,
            previewHeaderOneBadgeThree: '',
            previewInnerHTML: informationFramework?.assessmentSynopsis[badge - 1] || ''
          }
        });
        document.getElementById('displayPaneFourDiv').scrollTop = '0px'; //for scroll top
        dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
      }
    }
    if (reviewMode === 'revise') {
      if (e === 'communiqué' && badge === '') {
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'indexPointer', value: 0 }
        });
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: 'ASSESSMENT_COMMUNIQUE_PRIMARY_TEXTSHEET_POPUP',
            popupMode: 'ASSESSMENTCREATE'
          }
        });
      }

      if (e === 'manuscript' && badge === '') {
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'indexPointer', value: 0 }
        });
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: 'ASSESSMENT_MANUSCRIPT_PRIMARY_TEXTSHEET_POPUP',
            popupMode: 'ASSESSMENTCREATE'
          }
        });
      }
      if (e === 'synopsis' && badge === '') {
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'indexPointer', value: 0 }
        });
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: 'ASSESSMENT_SYNOPSIS_TEXTSHEET_POPUP',
            popupMode: 'ASSESSMENTCREATE'
          }
        });
      }
    }
  };
  const reviseFramework = (e, selectedBadgeArray) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    let badgeName = '';
    if (selectedBadgeArray) {
      if (selectedBadgeArray.length > 0) {
        selectedBadgeArray.forEach((element) => {
          badgeName = badgeName + element.labelTextTwoBadge;
        });
      }
    }
    if (labelName === 'communiqué' && selectedBadgeName !== '') {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'indexPointer', value: parseInt(selectedBadgeName) }
      });
      dispatch({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'ASSESSMENT_COMMUNIQUE_PRIMARY_TEXTSHEET_POPUP',
          popupMode: 'ASSESSMENTCREATE'
        }
      });
    }

    if (labelName === 'manuscript' && selectedBadgeName !== '') {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'indexPointer', value: parseInt(selectedBadgeName) }
      });
      dispatch({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'ASSESSMENT_MANUSCRIPT_PRIMARY_TEXTSHEET_POPUP',
          popupMode: 'ASSESSMENTCREATE'
        }
      });
    }
    if (labelName === 'synopsis' && selectedBadgeName !== '') {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'indexPointer', value: parseInt(selectedBadgeName) }
      });
      dispatch({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'ASSESSMENT_SYNOPSIS_TEXTSHEET_POPUP',
          popupMode: 'ASSESSMENTCREATE'
        }
      });
    }
    if (labelName === 'section' || labelName === 'version') {
      if (typeOfMiddlePaneList === 'assessmentDistinctReviewList') {
        let reqBody = {
          module: 'Assessment-Distinct',
          action: 'Review',
          requestObject: {
            assesseeId: selectedAssociateInfo?.assesseeId,
            associateId:
              selectedAssociateInfo?.associate?.informationEngagement.associateTag
                .associateTagPrimary, //605255729d3c823d3964e0ec
            filter: true,
            search: [
              {
                condition: 'and',
                searchBy: [
                  {
                    dataType: 'String',
                    conditionColumn: 'id',
                    conditionValue: {
                      condition: 'eq',
                      value: {
                        from: responseObject.id
                      }
                    }
                  }
                ]
              }
            ]
          }
        };
        dispatch({ type: LOADER_START });
        dispatch({
          type: SET_RELATED_REQUEST_OBJECT,
          payload: reqBody
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
        dispatch({
          type: GET_ASSESSMENT_SEC_INFO_SAGA,
          payload: {
            reqBody: reqBody,
            typeOfMiddlePaneList: 'assessment' + labelName + 'sReviewList'
          }
        });
        dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
      } else {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'INFO_NOT_REVISE_POPUP', popupMode: 'ASSESSMENTCREATE' }
        });
      }
    }
    if (labelName === 'administration' && badgeName === '' && selectedBadgeName === 'proctor') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ADMINPROCTORPOPUP', popupMode: 'ASSESSMENTCREATE' }
      });
    }
    if (labelName === 'administration' && badgeName === '' && selectedBadgeName === 'supervise') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ADMINSUPERVISEPOPUP', popupMode: 'ASSESSMENTCREATE' }
      });
    }
    if (
      labelName === 'administration' &&
      badgeName === 'template' &&
      selectedBadgeName === 'alignment'
    ) {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ASSESSMENTMEDIA', popupMode: 'ASSESSMENTCREATE' }
      });
    }
    if (
      labelName === 'administration' &&
      badgeName === 'template' &&
      selectedBadgeName === 'media'
    ) {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'TEMPLATEPOPUP', popupMode: 'ASSESSMENTCREATE' }
      });
    }
    if (labelName === 'administration' && badgeName === '' && selectedBadgeName === 'sequence') {
      dispatch({
        type: SET_ASSESSMENT_SINGLE_STATE,
        payload: { stateName: 'assessmentAdminSequence', value: adminSequenseArr }
      });
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ADMINSEWUENCEPOPUP', popupMode: 'ASSESSMENTCREATE' }
      });
    }
    if (labelName === 'administration' && badgeName === '' && selectedBadgeName === 'version') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ADMINVERSIONPOPUP', popupMode: 'ASSESSMENTCREATE' }
      });
    }
    if (labelName === 'administration' && badgeName === '' && selectedBadgeName === 'versionNew') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ADMINVERSIONNEWPOPUP', popupMode: 'ASSESSMENTCREATE' }
      });
    }
    if (labelName === 'administration' && badgeName === '' && selectedBadgeName === 'shuffle') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ADMIN_SHUFFLE_POPUP', popupMode: 'ASSESSMENTCREATE' }
      });
    }
    if (labelName === 'score' && selectedBadgeName === 'maximum') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'SCOREMAXIMUMPOPUP', popupMode: 'ASSESSMENTCREATE' }
      });
    }
    if (labelName === 'score' && selectedBadgeName === 'minimum') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'SCOREMINIMUMPOPUP', popupMode: 'ASSESSMENTCREATE' }
      });
    }
    if (labelName === 'score' && selectedBadgeName === 'mean') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'SCOREMEANPOPUP', popupMode: 'ASSESSMENTCREATE' }
      });
    }
    if (labelName === 'score' && selectedBadgeName === 'median') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'SCOREMEDIANPOPUP', popupMode: 'ASSESSMENTCREATE' }
      });
    }
    if (labelName === 'score' && selectedBadgeName === 'negative') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'SCORENEGATIVEPOPUP', popupMode: 'ASSESSMENTCREATE' }
      });
    }
    if (labelName === 'score' && selectedBadgeName === 'standard-deviation') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'SCORESTDDEVIATIONPOPUP', popupMode: 'ASSESSMENTCREATE' }
      });
    }
    if (labelName === 'time') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'TIMEASSESSMENTPOPUP', popupMode: 'ASSESSMENTCREATE' }
      });
    }
    if (labelName === 'evaluation' && selectedBadgeName === 'model') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'EVALUATIONPOPUP', popupMode: 'ASSESSMENTCREATE' }
      });
    }
    if (labelName === 'timeline' && selectedBadgeName === 'start') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'TIMELINESTARTPOPUP', popupMode: 'ASSESSMENTCREATE' }
      });
    }
    if (labelName === 'timeline' && selectedBadgeName === 'end') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'TIMELINEENDPOPUP', popupMode: 'ASSESSMENTCREATE' }
      });
    }
    if (labelName === 'timeline' && selectedBadgeName === '+') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'TIMELINEPLUSPOPUP', popupMode: 'ASSESSMENTCREATE' }
      });
    }
    if (labelName === 'items' && selectedBadgeName === 'distinct') {
      if (typeOfMiddlePaneList !== 'assignmentDistinctAssessmentReviewList') {
        let requestObect = makeItemObj(selectedAssociateInfo, 'published', countPage, 0);
        let revisedGroupObject = {
          id: responseObject.id,
          assessmentName: responseObject.informationBasic.assessmentName,
          assessmentDescription: responseObject.informationBasic.assessmentDescription,
          assessmentStatus: responseObject.informationEngagement.assessmentStatus
        };
        let existingItemId = informationFramework?.assessmentItemDistinct?.map((val) => {
          return val.itemTagPrimary;
        });
        dispatch({
          type: FILTERMODE,
          payload: { FilterMode: 'assessmentItemRevise' }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
        //set requestObject
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'relatedReviewListObjectCopy',
            value: requestObect
          }
        });
        dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
        dispatch({ type: LOADER_START });
        dispatch({
          type: GET_ALLOCATE_ITEM,
          payload: {
            request: requestObect,
            revisedGroupObject: revisedGroupObject,
            existingItemId: existingItemId,
            typeOfMiddlePaneList: 'assessmentItemReviewList'
          }
        });
      } else {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'INFO_NOT_REVISE_POPUP', popupMode: 'ASSESSMENTCREATE' }
        });
      }
    }
    if (labelName === 'items' && selectedBadgeName === 'group') {
      if (typeOfMiddlePaneList !== 'assignmentDistinctAssessmentReviewList') {
        let requestObect = makeItemGroupObj(selectedAssociateInfo, 'active', -1, -1, 'list', assignmentCreateType);
        let revisedGroupObject = {
          id: responseObject.id,
          assessmentName: responseObject?.informationBasic?.assessmentName,
          assessmentDescription: responseObject?.informationBasic?.assessmentDescription,
          assessmentStatus: responseObject?.informationEngagement?.assessmentStatus
        };
        let existingItemId = [];
        dispatch({
          type: FILTERMODE,
          payload: { FilterMode: 'assessmentSectionItemGroupRevise' }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
        dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
        dispatch({ type: LOADER_START });
        dispatch({
          type: GET_ALLOCATE_ITEM_GROUP,
          payload: {
            request: requestObect,
            revisedGroupObject: revisedGroupObject,
            existingItemId: existingItemId,
            typeOfMiddlePaneList: 'assessmentSectionItemGroupDistinctReviewList'
          }
        });
      } else {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'INFO_NOT_REVISE_POPUP', popupMode: 'ASSESSMENTCREATE' }
        });
      }
    }
    if (labelName === 'items' && selectedBadgeName === 'total') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ITEMTOTALPOPUP', popupMode: 'ASSESSMENTCREATE' }
      });
    }
    if (labelName === 'response' && selectedBadgeName === 'revision') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'REVISIONPOPUP', popupMode: 'ASSESSMENTCREATE' }
      });
    }
    if (labelName === 'items' && selectedBadgeName === 'trial') {
      if (
        typeOfMiddlePaneList === 'assessmentDistinctReviewList' ||
        typeOfMiddlePaneList === '' ||
        typeOfMiddlePaneList === 'assessmentSectionTrialDistinctReviewList'
      ) {
        let revisedGroupObject = {
          assessmentName: responseObject.informationBasic.assessmentName,
          assessmentDescription: responseObject.informationBasic.assessmentDescription,
          assessmentStatus: responseObject.informationEngagement.assessmentStatus,
          item: responseObject?.informationFramework?.assessmentItemDistinct
        };
        // let existingItemId=[];
        let existingItemId = responseObject?.informationFramework?.assessmentItemTrial?.map(
          (val) => {
            return val?.itemTagPrimary;
          }
        );
        dispatch({
          type: FILTERMODE,
          payload: { FilterMode: 'assessmentItemRevise' }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
        dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
        dispatch({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: 'items',
            middlePaneHeaderBadgeOne: 'distinct',
            middlePaneHeaderBadgeTwo: 'active',
            middlePaneHeaderBadgeThree: '',
            middlePaneHeaderBadgeFour: '',
            typeOfMiddlePaneList: 'assessmentSectionTrialDistinctReviewList',
            scanCount: responseObject.informationFramework?.assessmentItemDistinct.length,
            showMiddlePaneState: true,
            isSelectActive: 'multiple',
            selectedTagsArray: existingItemId
          }
        });
        dispatch({ type: RELATED_REVIEWLIST_DISTINCT_DATA, payload: [revisedGroupObject] });
      } else {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'INFO_NOT_REVISE_POPUP', popupMode: 'ASSESSMENTCREATE' }
        });
      }
    }
    if (badgeName === 'calculator' && selectedBadgeName === 'permission') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'AID_CAL_POPUP', popupMode: 'ASSESSMENTCREATE' }
      });
    }
    if (badgeName === 'calculator' && selectedBadgeName === 'type') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'AID_CALCULATOR_TYPE_POPUP', popupMode: 'ASSESSMENTCREATE' }
      });
    }
    if (badgeName === 'spredsheet' && selectedBadgeName === 'permission') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'AID_SHEET_POPUP', popupMode: 'ASSESSMENTCREATE' }
      });
    }
    if (badgeName === 'spredsheet' && selectedBadgeName === 'type') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'AID_SPREADSHEET_TYPE_POPUP', popupMode: 'ASSESSMENTCREATE' }
      });
    }
    if (badgeName === 'textsheet' && selectedBadgeName === 'permission') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'AID_TEXT_POPUP', popupMode: 'ASSESSMENTCREATE' }
      });
    }
    if (badgeName === 'textsheet' && selectedBadgeName === 'type') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'AID_TEXTSHEET_TYPE_POPUP', popupMode: 'ASSESSMENTCREATE' }
      });
    }
    if (labelName === 'preview') {
      if (responseObject.informationFramework.assessmentAdministrationSequence.length > 0) {
        dispatch({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: { stateName: 'assessmentsequenceObject', value: [] }
        });
        dispatch({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: { stateName: 'currentSequenceIndex', value: 0 }
        });
        dispatch({ type: SET_PANE_THREE_ASSESSMENT_PREVIEW_MODE, payload: true });
        dispatch({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: { stateName: 'assesseeAssignmentAssessmentData', value: responseObject }
        });
        dispatch({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: {
            stateName: 'assesseeAssessmentStartData',
            value: null
          }
        });
        dispatch({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: { stateName: 'isAssessmentStart', value: 'PREVIEW' }
        });
        dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneSix' });
        //dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneFive' });
        makeAssessmentPreviewSequenceObj(responseObject, dispatch);
      } else {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: ASSESSMENT_PREIVEW_SEQUENCE_ERROR,
            popupMode: 'responseErrorMsg'
          }
        });
      }
    }
    if (labelName === 'norm' && selectedBadgeName === 'distinct') {//
      if (typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList' || typeOfMiddlePaneList === 'assignmentAssessmentNormReviewList') {
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctDataCopy', value: [] }
        });
        let revisedGroupObject = {
          id: responseObject.id,
          assessmentName: responseObject.informationBasic.assessmentName,
          assessmentDescription: responseObject.informationBasic.assessmentDescription,
          norms: informationFramework?.assessmentNormFramework
        };
        let existingItemId = [
          assessmentReducerInfo?.informationFramework?.assessmentNormFrameworkSelected
        ];
        dispatch({
          type: FILTERMODE,
          payload: { FilterMode: 'assignmentAssessmentNormRevise' }
        });
        dispatch({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: 'assessment',
            middlePaneHeaderBadgeOne: 'norms',
            middlePaneHeaderBadgeTwo: 'active',
            middlePaneHeaderBadgeThree: '',
            middlePaneHeaderBadgeFour: '',
            middlePaneHeaderDuplicate: middlePaneHeaderDuplicate,
            middlePaneHeaderDuplicateBadgeOne: middlePaneHeaderDuplicateBadgeOne,
            middlePaneHeaderDuplicateBadgeTwo: middlePaneHeaderDuplicateBadgeTwo,
            middlePaneHeaderDuplicateBadgeThree: '',
            middlePaneHeaderDuplicateBadgeFour: '',
            typeOfMiddlePaneList: 'assignmentAssessmentNormReviewList',
            scanCount: informationFramework?.assessmentNormFramework?.length,
            duplicateScanCount: duplicateScanCount,
            showMiddlePaneState: true,
            isSelectActive: 'multiple',
            selectedTagsArray: existingItemId
          }
        });
        // dispatch({ type: RELATED_REVIEWLIST_DISTINCT_DATA, payload: [revisedGroupObject] });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctDataCopy', value: [revisedGroupObject] }
        });
        dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
      } else {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'NORMPOPUP', popupMode: 'ASSESSMENTCREATE' }
        });
      }
    }
    if (labelName === 'norm' && selectedBadgeName === 'selected') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'NORM_SELECTED_POPUP', popupMode: 'ASSESSMENTCREATE' }
      });
    }
    if (
      (labelName === 'cluster' || labelName === 'grade' || labelName === 'scale') &&
      selectedBadgeName === 'distinct'
    ) {
      if (typeOfMiddlePaneList === 'assessmentDistinctReviewList') {
        let popupHeaderOne =
          labelName === 'scale'
            ? 'scales'
            : labelName === 'cluster'
              ? 'clusters'
              : labelName === 'norm'
                ? 'norms'
                : labelName === 'grade'
                  ? 'grades'
                  : '';

        dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
        let reqBody = {
          module: 'Assessment-Distinct',
          action: 'Review',
          requestObject: {
            assesseeId: selectedAssociateInfo?.assesseeId,
            associateId:
              selectedAssociateInfo?.associate?.informationEngagement.associateTag
                .associateTagPrimary,
            filter: true,
            search: [
              {
                condition: 'and',
                searchBy: [
                  {
                    dataType: 'String',
                    conditionColumn: 'id',
                    conditionValue: {
                      condition: 'eq',
                      value: {
                        from: responseObject?.id
                      }
                    }
                  }
                ]
              }
            ]
          }
        };

        dispatch({ type: LOADER_START });
        dispatch({
          type: SET_RELATED_REQUEST_OBJECT,
          payload: reqBody
        });
        dispatch({
          type: FILTERMODE,
          payload: { FilterMode: 'assessment' + popupHeaderOne + 'Revise' }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
        dispatch({
          type: GET_ASSESSMENT_SEC_INFO_SAGA,
          payload: {
            reqBody: reqBody,
            typeOfMiddlePaneList: 'assessment' + popupHeaderOne + 'ReviewList'
          }
        });
      } else {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: 'INFO_' + labelName + '_NOT_REVISE_POPUP',
            popupMode: 'ASSESSMENTCREATE'
          }
        });
      }
    }
    if (labelName === 'cluster' && selectedBadgeName === 'polarity') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'POLARITY_NOT_REVISE_POPUP', popupMode: 'ASSESSMENTCREATE' }
      });
    }
    if (labelName === 'notation') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'NOTATIONPOPUP', popupMode: 'ASSESSMENTCREATE' }
      });
    }
  };

  return (
    <div>
      {headerOneBadgeThree === 'all' ? (
        <>
          <div className={'containerPadding'}>
            <AllocationAccordian
              headerOne="framework"
              isDisplayCardExpanded={listExpand === 'framework'}
              setListExpand={setListExpand}
              list={frameworkAll}
              mode={reviewMode}
              onClickRevise={reviseFramework}
              onClickReview={onClickReview}
              onClickReviseIcon={onClickReviseIcon}

            />
          </div>
          {/* {typeOfMiddlePaneList !== 'assessmentDistinctReviewList' && (
            <div className={'containerPadding'}>
              <AllocationAccordian
                headerOne="framework+"
                isDisplayCardExpanded={listExpand === 'framework+'}
                setListExpand={setListExpand}
                list={frameworkPlusAll}
                mode={reviewMode}
                onClickRevise={reviseFramework}
              />
            </div>
          )} */}
        </>
      ) : (
        <>
          <div className={'containerPadding'}>
            <Paper className={'dossierContainerTop'}>
              {frameworkKey.map((ob) => {
                return (
                  <div key={ob.id}>
                    {ob.isListCard ? (
                      <DisplayPanelAccordianReviewListOne
                        onClickRevise={reviseFramework}
                        className=""
                        accordianObject={ob}
                        mode={reviewMode}
                        onClickReview={onClickReview}
                        onClickReviseIcon={onClickReviseIcon}
                      />
                    ) : (
                      <DisplayPanelAccordianInformation
                        onClickRevise={reviseFramework}
                        accordianObject={ob}
                        mode={reviewMode}
                        onClickReview={onClickReview}
                        onClickReviseIcon={onClickReviseIcon}
                      />
                    )}
                  </div>
                );
              })}
              {frameworkPlusAll.map((ob) => {
                return (
                  <div key={ob.id}>
                    {ob.isListCard ? (
                      <DisplayPanelAccordianReviewListOne
                        onClickRevise={reviseFramework}
                        className=""
                        accordianObject={ob}
                        mode={reviewMode}
                        onClickReview={null}
                        onClickReviseIcon={onClickReviseIcon}

                      />
                    ) : (
                      <DisplayPanelAccordianInformation
                        onClickRevise={reviseFramework}
                        accordianObject={ob}
                        mode={reviewMode}
                        onClickReview={null}
                        onClickReviseIcon={onClickReviseIcon}

                      />
                    )}
                  </div>
                );
              })}
            </Paper>
          </div>
        </>
      )}
      {isMobile && (
        <div className={'containerPadding'} style={{ height: '55px' }}>
          {' '}
        </div>
      )}
    </div>
  );
};

export default DisplayPaneThreeSectionTwoAssessment;
