import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PopUpPicture from '../../PopUpInformation/PopUpPicture';
import PopUpTextField from '../../PopUpInformation/PopUpTextField';
import PopUpConfirm from '../../PopUpGeneric/PopUpConfirm';
import {
  POPUP_CLOSE,
  LOADER_START,
  SET_DISPLAY_THREE_SINGLE_STATE,
  SET_CLUSTER_REDUCER_STATE,
  CLEAR_CLUSTER_REDUCER_STATE,
  CREATE_ASSESSMENT_SECTION_SAGA
} from '../../actionType';
import PopUpTextEditor from '../../PopUpIcon/PopUpTextEditor';
import PopUpDropList from '../../PopUpInformation/PopUpDropList';
import PopUpTagSecondary from '../../PopUpInformation/PopUpTagSecondary';

const PopUpClusterCreate = (props) => {
  const { headerOne } = props;
  const { isPopUpValue, selectedTagValue } = useSelector((state) => state.PopUpReducer);
  const { clusterInformation } = useSelector((state) => state.ClusterCreateReducer);
  const { reviewMode, responseObject, statusPopUpValue } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  const { selectedAssociateInfo, coreNodeReviewListData } = useSelector(
    (state) => state.DisplayPaneTwoReducer
  );

  const dispatch = useDispatch();
  const onClickCancelYes = () => {
    dispatch({
      type: SET_DISPLAY_THREE_SINGLE_STATE,
      payload: { stateName: 'createMode', value: '' }
    });
    dispatch({ type: CLEAR_CLUSTER_REDUCER_STATE });
    dispatch({ type: POPUP_CLOSE });
  };
  const onClickYes = () => {
    dispatch({ type: POPUP_CLOSE });
    let requestObj = {
      module: 'Assessment-Distinct',
      action: 'Create',
      actionFilter: 'Section-Cluster-Scale',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        assessmentId: selectedTagValue,
        assessmentClusterFramework: clusterInformation
      }
    };
    console.log('requestObj', requestObj);
    dispatch({ type: POPUP_CLOSE });
    dispatch({ type: LOADER_START });
    dispatch({ type: CREATE_ASSESSMENT_SECTION_SAGA, payload: { requestObj } });
  };
  console.log('clusterInformation', clusterInformation);
  let tenureStartDate = 'mm/dd/yyyy --:-- --';
  let tenureEndDate = 'mm/dd/yyyy --:-- --';
  return (
    <div>
      {isPopUpValue === 'NAMEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'name'}
          actualLableValue={'assessmentClusterName'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'cluster'}
          headerOneBadgeTwo={'information'}
          nextPopUpValue={'ALIASPOPUP'}
          basicInfo={clusterInformation}
          typeOfSetObject={SET_CLUSTER_REDUCER_STATE}
          isRequired={true}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ALIASPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'description'}
          actualLableValue={'assessmentClusterDescription'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'cluster'}
          headerOneBadgeTwo={'information'}
          basicInfo={clusterInformation}
          nextPopUpValue={'PICTUREPOPUP'}
          typeOfSetObject={SET_CLUSTER_REDUCER_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'PICTUREPOPUP' && (
        <PopUpPicture
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'cluster'}
          headerOneBadgeTwo={'information'}
          nextPopUpValue={'CONFIRMATIONPOPUP'}
          actualLableValue={'assessmentClusterPicture'}
          actualLableValueVerification={'assessmentClusterPictureVerification'}
          basicInfo={clusterInformation}
          typeOfSetObject={SET_CLUSTER_REDUCER_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ONEONELABELPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'label'}
          actualLableValue={'assessmentClusterOneLabel'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'cluster'}
          headerOneBadgeTwo={'information'}
          basicInfo={clusterInformation}
          nextPopUpValue={''}
          typeOfSetObject={SET_CLUSTER_REDUCER_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ONETWOLABELPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'label'}
          actualLableValue={'assessmentClusterTwoLabel'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'cluster'}
          headerOneBadgeTwo={'information'}
          basicInfo={clusterInformation}
          nextPopUpValue={''}
          typeOfSetObject={SET_CLUSTER_REDUCER_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ONEONEEXPLANATION' && (
        <PopUpTextField
          isActive={true}
          label={'explanation'}
          actualLableValue={'assessmentClusterOneExplanation'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'cluster'}
          headerOneBadgeTwo={'information'}
          basicInfo={clusterInformation}
          nextPopUpValue={''}
          typeOfSetObject={SET_CLUSTER_REDUCER_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ONETWOEXPLANATION' && (
        <PopUpTextField
          isActive={true}
          label={'explanation'}
          actualLableValue={'assessmentClusterTwoExplanation'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'cluster'}
          headerOneBadgeTwo={'information'}
          basicInfo={clusterInformation}
          nextPopUpValue={''}
          typeOfSetObject={SET_CLUSTER_REDUCER_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ONEONEPOLARITY' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentClusterOnePolarity'}
          label={'polarity'}
          listSelect={[
            { id: 'Bespoke', name: 'Bespoke' },
            { id: 'Generic', name: 'Generic' }
          ]}
          mappingValue={'id'}
          labelval={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'cluster'}
          headerOneBadgeTwo={'information'}
          isRequired={false}
          nextPopUpValue={''}
          basicInfo={clusterInformation}
          typeOfSetObject={SET_CLUSTER_REDUCER_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ONETWOPOLARITY' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentClusterTwoPolarity'}
          label={'polarity'}
          listSelect={[
            { id: 'Bespoke', name: 'Bespoke' },
            { id: 'Generic', name: 'Generic' }
          ]}
          mappingValue={'id'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'cluster'}
          headerOneBadgeTwo={'information'}
          isRequired={false}
          nextPopUpValue={''}
          basicInfo={clusterInformation}
          typeOfSetObject={SET_CLUSTER_REDUCER_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'CANCELPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'cancel'}
          headerOneBadgeOne={''}
          mode={'cancel'}
          onClickYes={onClickCancelYes}
        />
      )}
      {isPopUpValue === 'CONFIRMATIONPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={''}
          headerOneBadgeTwo={'create'}
          onClickYes={onClickYes}
        />
      )}
      {isPopUpValue === 'SEQUENCEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'sequence'}
          actualLableValue={'assessmentClusterSequence'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'cluster'}
          headerOneBadgeTwo={'information'}
          nextPopUpValue={''}
          type={'number'}
          basicInfo={clusterInformation}
          typeOfSetObject={SET_CLUSTER_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ONEONEDESCRIPTION' && (
        <PopUpTextField
          isActive={true}
          label={'description'}
          actualLableValue={'assessmentClusterOneDescription'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'cluster'}
          headerOneBadgeTwo={'information'}
          basicInfo={clusterInformation}
          nextPopUpValue={''}
          typeOfSetObject={SET_CLUSTER_REDUCER_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ONETWODESCRIPTION' && (
        <PopUpTextField
          isActive={true}
          label={'description'}
          actualLableValue={'assessmentClusterTwoDescription'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'cluster'}
          headerOneBadgeTwo={'information'}
          basicInfo={clusterInformation}
          nextPopUpValue={''}
          typeOfSetObject={SET_CLUSTER_REDUCER_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'CLUSEREXPLANATIONPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'explanation'}
          actualLableValue={'assessmentClusterExplanation'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'cluster'}
          headerOneBadgeTwo={'information'}
          nextPopUpValue={''}
          basicInfo={clusterInformation}
          typeOfSetObject={SET_CLUSTER_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TENURESATRTDATEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tenure'}
          labelBadgeOne={'start'}
          actualLableValue={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'cluster'}
          headerOneBadgeTwo={'information'}
          basicInfo={tenureStartDate}
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={SET_CLUSTER_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TENUREENDDATEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tenure'}
          labelBadgeOne={'end'}
          actualLableValue={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'cluster'}
          headerOneBadgeTwo={'information'}
          basicInfo={tenureEndDate}
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={SET_CLUSTER_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'STATUSPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentStatus'}
          label={'status'}
          listSelect={[
            { id: 'Active', name: 'Active' },
            { id: 'Archived', name: 'Archived' },
            { id: 'Inactive', name: 'Inactive' },
            { id: 'Suspended', name: 'Suspended' },
            { id: 'Terminated', name: 'Terminated' }
          ]}
          mappingValue={'id'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'cluster'}
          headerOneBadgeTwo={'information'}
          isRequired={true}
          basicInfo={''}
          nextPopUpValue={''}
          typeOfSetObject={SET_CLUSTER_REDUCER_STATE}
          handleNextPopupValue={() => {}}
          isNotRevised={true}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TAGREADONLYPRIMARYPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tag'}
          labelBadgeOne={'primary'}
          actualLableValue={'id'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'cluster'}
          headerOneBadgeTwo={'information'}
          basicInfo={ responseObject}
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={SET_CLUSTER_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TAGSECONDARYPOPUP' && (
        <PopUpTagSecondary
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'cluster'}
          headerOneBadgeTwo={'information'}
          tagSecondary={'' || {}}
          signInSetup={'' || {}}
          nextPopUpValue={''}
          typeOfSetObject={SET_CLUSTER_REDUCER_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
    </div>
  );
};

export default PopUpClusterCreate;
