import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PopupHeader from '../Molecules/PopUp/PopUpHeader';
import Popup from '../Molecules/PopUp/PopUp';
import '../Molecules/PopUp/PopUp.css';
import { DialogContent } from '@material-ui/core';
import JsonRenderComponent from '../Actions/JsonRenderComponent';
import {
  SET_SECONDARY_CREATE_OPTION_VALUE,
  POPUP_CLOSE,
  SET_POPUP_STATE,
  LOADER_START,
  GET_ASSESSEE_INFO_SAGA,
  SET_POPUP_SINGLE_STATE
} from '../actionType';
import { REVIEW_REVISE_POPUP, SELF_POPUP } from '../PopUpConfig';
import { setAssociateCardPermissionInJson } from '../Actions/GenericActions';
const PopUpDisplayPaneThreePanelAssessee = (props) => {
  const {
    popupHeaderOne,
    popupHeaderOneBadgeOne,
    popupHeaderOneBadgeTwo,
    popupHeaderOneBadgeThree,
    popupOpenType,
    secondaryOptionCheckValue,
    tertiaryOptionCheckValue = 'all',
    forthOptionCheckValue
  } = useSelector((state) => state.PopUpReducer);
  const { selectedAssociateInfo, countPage, leftPaneAssesseeInfo, signedAssesseePermission } =
    useSelector((state) => state.DisplayPaneTwoReducer);
  const { headerOneBadgeOne } = useSelector((state) => state.DisplayPaneThreeReducer);
  const dispatch = useDispatch();
  const { headerPanelColour = 'displayPaneCentre', isActive } = props;
  const [isReviseMode, setIsReviseMode] = useState(false);

  const setSecondaryOptionValue = (e) => {
    dispatch({
      type: SET_SECONDARY_CREATE_OPTION_VALUE,
      payload: e.currentTarget.getAttribute('data-value')
    });
  };
  const setSecondaryOptionValueTwo = (e) => {};
  const setSecondaryOptionValueThree = (e) => {};
  const ChangeOptionPopup = (e) => {
    let keyVal = e.currentTarget.getAttribute('data-key');
    let dataVal = e.currentTarget.getAttribute('data-value');
    let revisePopupHeaderOne = '';
    let revisepopupHeaderOneBadgeOne = '';
    let revisepopupHeaderOneBadgeTwo = '';
    let reviseisPopUpValue = '';
    let revisePopupType = '';
    let reviseSecondaryOptionCheckValue = '';
    let valueArr = [];
    let revisePopupMode = '';
    console.log(dataVal);
    if (dataVal === 'review') {
      revisePopupHeaderOne = 'assessee';
      revisepopupHeaderOneBadgeOne = headerOneBadgeOne;
      revisepopupHeaderOneBadgeTwo = dataVal;
      reviseisPopUpValue = 'assesseeSelfPopup';
      revisePopupType = 'secondary';
      reviseSecondaryOptionCheckValue =
        signedAssesseePermission?.associateSelfPermission?.assesseeAssesseeDistinctPermission
          ?.assesseePermissionInformation || 'all';
      REVIEW_REVISE_POPUP.forEach((ele) => {
        if (ele.dataValue === 'all' && reviseSecondaryOptionCheckValue === 'key') {
          ele.disabled = true;
        }
        valueArr.push(ele);
      });
    } else if (dataVal === 'information' && popupHeaderOne === 'assessee') {
      dispatch({ type: LOADER_START });
      dispatch({
        type: GET_ASSESSEE_INFO_SAGA,
        payload: {
          secondaryOptionCheckValue,
          popupBadgeOne: headerOneBadgeOne,
          headerOne: 'assessee',
          isReviseMode,
          reqBody: {
            module: 'Assessee-Distinct',
            action: 'Review',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              filter: 'true',
              isAssesseeSelf:'true',
              searchCondition: 'AND',
              search: [
                {
                  condition: 'and',
                  searchBy: [
                    {
                      dataType: 'string',
                      conditionColumn: 'id',
                      conditionValue: {
                        condition: 'eq',
                        value: {
                          from: selectedAssociateInfo?.assesseeId
                        }
                      }
                    }
                  ]
                }
              ]
            }
          }
        }
      });
      setIsReviseMode(false);
      dispatch({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'cardValue', value: 'Card' }
      });
    } else if (dataVal === 'revise') {
      setIsReviseMode(true);
      revisePopupHeaderOne = 'assessee';
      revisepopupHeaderOneBadgeOne = headerOneBadgeOne;
      revisepopupHeaderOneBadgeTwo = dataVal;
      reviseisPopUpValue = 'assesseeSelfPopup';
      revisePopupType = 'secondary';
      reviseSecondaryOptionCheckValue =
        signedAssesseePermission?.associateSelfPermission?.assesseeAssesseeDistinctPermission
          ?.assesseePermissionInformation || 'all';
      REVIEW_REVISE_POPUP.forEach((ele) => {
        if (ele.dataValue === 'all' && reviseSecondaryOptionCheckValue === 'key') {
          ele.disabled = true;
        }
        valueArr.push(ele);
      });
    } else {
      dispatch({
        type: POPUP_CLOSE
      });
    }
    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: revisePopupHeaderOne,
        popupHeaderOneBadgeOne: revisepopupHeaderOneBadgeOne,
        popupHeaderOneBadgeTwo: revisepopupHeaderOneBadgeTwo,
        isPopUpValue: reviseisPopUpValue,
        popupOpenType: revisePopupType,
        secondaryOptionCheckValue: reviseSecondaryOptionCheckValue,
        popupContentArrValue: valueArr,
        selectedTagValue: selectedAssociateInfo?.assesseeId,
        popupMode: ''
      }
    });
  };
  const BackHandlerEvent = () => {
    if (popupOpenType === 'primary') {
      dispatch({
        type: POPUP_CLOSE
      });
    } else {
      let associatePermission = signedAssesseePermission?.associateSelfPermission;
      let popupContentArr = setAssociateCardPermissionInJson(SELF_POPUP, associatePermission);
      dispatch({
        type: SET_POPUP_STATE,
        payload: {
          popupHeaderOne: 'assessee',
          popupHeaderOneBadgeOne: headerOneBadgeOne,
          popupHeaderOneBadgeTwo: '',
          isPopUpValue: 'assesseeSelfPopup',
          popupOpenType: 'primary',
          secondaryOptionCheckValue: 'all',
          popupContentArrValue: popupContentArr,
          selectedTagValue: selectedAssociateInfo?.assesseeId,
          popupMode: ''
        }
      });
    }
  };
  return (
    <div>
      <Popup isActive={isActive}>
        <PopupHeader
          headerPanelColour={headerPanelColour + popupOpenType}
          headerOne={popupHeaderOne}
          headerOneBadgeOne={popupHeaderOneBadgeOne}
          headerOneBadgeTwo={popupHeaderOneBadgeTwo}
          headerOneBadgeThree={popupHeaderOneBadgeThree}
          onClick={BackHandlerEvent}
          mode={''}
        />
        <DialogContent className={['popupContent', 'fixed05PadDim'].join(' ')}>
          <JsonRenderComponent
            setSecondaryOptionValue={setSecondaryOptionValue}
            setSecondaryOptionValueTwo={setSecondaryOptionValueTwo}
            setSecondaryOptionValueThree={setSecondaryOptionValueThree}
            ChangeOptionPopup={ChangeOptionPopup}
            secondaryOptionCheckValue={secondaryOptionCheckValue}
            tertiaryOptionCheckValue={tertiaryOptionCheckValue}
            forthOptionCheckValue={forthOptionCheckValue}
          />
          {/* )} */}
        </DialogContent>
      </Popup>
    </div>
  );
};

export default PopUpDisplayPaneThreePanelAssessee;
