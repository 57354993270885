import { getPopUpOptionList, setAssociateCardPermissionInJson } from '../Actions/GenericActions';
import {
  ASSIGNMENT_POPUP_OPEN,
  CLEAR_ASSIGNMENT_INFO,
  SET_ASSIGNMENT_BASIC_REDUCER_STATE,
  SET_ASSIGNMENT_COMMUNIQUE_FRAMEWORK_STATE,
  SET_ASSIGNMENT_SYNOPSIS_FRAMEWORK_STATE,
  SET_ASSIGNMENT_DYNAMIC_SINGLE_STATE,
  SET_ASSIGNMENT_FRAMEWORK_STATE,
  SET_ASSIGNMENT_NEXT_POPUP,
  SET_ASSIGNMENT_PREVIOUS_POPUP,
  SET_ASSIGNMENT_SECONDARY_OPTION_VALUE,
  SET_ASSIGNMENT_SECONDARY_POPUP,
  SET_ASSIGNMENT_SINGLE_STATE,
  SET_ASSIGNMENT_DYNAMIC_FRAMEWORK_STATE,
  SET_ASSIGNMENT_FRAMEWORK_TEMPLATE_ALIGNMENT_STATE,
  SET_ASSIGNMENT_GAUGE_STATE,
  SET_ASSIGNMENT_TIMELINE_STATE,
  SET_ASSIGNMENT_ALL_STATE,
  ASSIGNMENT_POPUP_CLOSE,
  SET_ASSIGNMENT_DYNAMIC_SETUP_STATE
} from '../actionType';
import {
  MODULE_POPUP_OPTION,
  NOTIFICATION_REPORT_POPUP,
  ASSIGNMENT_MODULE_REVIEW_POPUP_OPTIONS,
  REVIEW_REVISE_POPUP
} from '../PopUpConfig';

const initialState = {
  assignmentsHeaderOne: '',
  assignmentsPopUpType: 'primary',
  currentPopUpOption: '',
  assignmentsPopUpActive: false,
  isBackToSectionPopUp: false,
  assignmentsHeaderOneBadgeOne: '',
  primaryPopUpOptions: MODULE_POPUP_OPTION,
  secondaryPopUpOptions: {
    create: REVIEW_REVISE_POPUP,
    review: ASSIGNMENT_MODULE_REVIEW_POPUP_OPTIONS,
    notifications: NOTIFICATION_REPORT_POPUP,
    reports: NOTIFICATION_REPORT_POPUP
  },
  assignmentAdminSequence: [],
  secondaryOptionCheckValue: '',
  assignmentDistinctEvalutionRef: false,
  informationBasic: {
    assignmentName: '',
    assignmentNameVerification: false,
    assignmentDescription: '',
    assignmentPicture: '',
    assignmentPictureVerification: false,
    assignmentFlag: false
  },
  informationAlliance: {
    assignmentCollaborator: {
      assignmentCollaboratorPrimary: [],
      assignmentCollaboratorSecondary: []
    }
  },
  informationAllocation: {
    assignmentGroup: {
      assignmentGroupPrimary: [],
      assignmentGroupSecondary: []
    },
    assignmentNode: {
      assignmentNodePrimary: [],
      assignmentNodeSecondary: []
    },
    assignmentType: {
      assignmentTypePrimary: [],
      assignmentTypeSecondary: []
    }
  },
  informationFramework: {
    assignmentAdministrationProctor: false,
    assignmentAdministrationRepeat: false,
    assignmentAdministrationReset: false,
    assignmentAdministrationSequence: [],
    assignmentAdministrationShuffle: false,
    assignmentAdministrationSupervise: false,
    assignmentAssesseeDistinct: [],
    assignmentAssesseeGroup: [],
    assignmentAssesseeNorm: '',
    assignmentAssessmentDistinct: [],
    assignmentAssessmentGroup: [],
    assignmentCommunique: [],
    assignmentCultureProfileDistinct: [],
    assignmentCultureProfileGroup: [],
    assignmentGaugeDistinct: [],
    assignmentGaugeFramework: [],
    assignmentJobProfileDistinct: [],
    assignmentJobProfileGroup: [],
    assignmentSynopsis: [],
    assignmentTimeline: {
      assignmentTimelineDateTimeStart: null,
      assignmentTimelineDateTimeEnd: null
    },
    assignmentAdministrationTemplateMedia: [],
    assignmentAdministrationTemplateAlignment: {
      assignmentItemFrameworkOneResponseChoiceAlignment: 'vertical'
    },
    assignmentValidity:1,
    assignmentAdministrationTemplateMediaHtml:
      '<p><span style="font-family: Roboto; font-size: 16px;"><strong>item (label)</strong></span></p><p><span style="font-family: Roboto; font-size: 16px;">item (passage)</span></p><p><span style="font-family: Roboto; font-size: 16px;">item</span></p><p><span style="font-family: Roboto; font-size: 10px;">item (explanation)</span></p><p><span style="font-family: Roboto; font-size: 16px;"><strong>response (label)</strong></span><strong></strong></p><p><span style="font-family: Roboto; font-size: 16px;">response</span></p><p><span style="font-family: Roboto; font-size: 10px;">response (explanation)</span></p><p><span style="font-family: Roboto; font-size: 16px;">response-choice</span></p><p><span style="font-family: Roboto; font-size: 10px;">response-choice (explanation)</span></p>'
  },
  informationSetup: {
    assignmentShareFee: 0.0,
    assignmentSellFee: 0.0,
    assignmentMoveFee: 0.0,
  }
};

const AssignmentReducer = (istate = JSON.parse(JSON.stringify(initialState)), action) => {
  // console.log(action)
  switch (action.type) {
    case ASSIGNMENT_POPUP_CLOSE:
      return {
        ...istate,
        assignmentsPopUpActive: false
      };
    case SET_ASSIGNMENT_SINGLE_STATE:
      return {
        ...istate,
        [action.payload.stateName]: action.payload.value
      };
    case ASSIGNMENT_POPUP_OPEN:
      return {
        ...istate,
        assignmentsHeaderOne: 'assignments',
        assignmentsPopUpType: 'primary',
        //currentPopUpOption: istate.primaryPopUpOptions,
        currentPopUpOption: action.payload.popupContentArrValue,
        assignmentsPopUpActive: true
      };
    case SET_ASSIGNMENT_NEXT_POPUP:
      if (istate.assignmentsPopUpType === 'primary') {
        if (action.payload.targetValue === 'notifications' || action.payload.targetValue === 'reports') {
          return {
            ...istate,
            assignmentsPopUpActive: true,
            assignmentsHeaderOne: action.payload.targetValue,
            assignmentsHeaderOneBadgeOne: 'review',
            assignmentsPopUpType: 'secondary',
            currentPopUpOption: istate.secondaryPopUpOptions[action.payload.targetValue],
            secondaryOptionCheckValue: 'unread'
          };
        } else {
          return {
            ...istate,
            assignmentsPopUpActive: true,
            assignmentsHeaderOne: 'assignments',
            assignmentsHeaderOneBadgeOne: action.payload.targetValue,
            assignmentsPopUpType: 'secondary',
            currentPopUpOption: setAssociateCardPermissionInJson(
              istate.secondaryPopUpOptions[action.payload.targetValue],
              action.payload.permissionObject,
              'optionPrimary'
            ),
            secondaryOptionCheckValue: action.payload.targetValue === 'create' ? 'all' : 'active'
          };
        }
      } else {
        return istate;
      }
    case SET_ASSIGNMENT_SECONDARY_POPUP:
      console.log('istate.assignmentsPopUpType',istate.assignmentsPopUpType);
      if (istate.assignmentsPopUpType === 'primary') {
        if (action.payload.clickValue === 'notifications' || action.payload.clickValue === 'reports') {
          return {
            ...istate,
            assignmentsPopUpActive: true,
            isBackToSectionPopUp: true,
            assignmentsHeaderOne: action.payload.clickValue,
            assignmentsHeaderOneBadgeOne: 'review',
            assignmentsPopUpType: 'secondary',
            currentPopUpOption: istate.secondaryPopUpOptions[action.payload.clickValue],
            secondaryOptionCheckValue: action.payload.clickValue === 'create' ? 'all' : 'unread'
          };
        } else {
          return {
            ...istate,
            assignmentsPopUpActive: true,
            isBackToSectionPopUp: true,
            assignmentsHeaderOne: 'assignments',
            assignmentsHeaderOneBadgeOne: action.payload.clickValue,
            assignmentsPopUpType: 'secondary',
            currentPopUpOption: setAssociateCardPermissionInJson(
              istate.secondaryPopUpOptions[action.payload.clickValue],
              action.payload.permissionObject,
              'optionPrimary',
              '',
              'assignments',
              '',
              action.payload.paneOneSelectedSection
            ),
            secondaryOptionCheckValue: action.payload.clickValue === 'create' ? 'all' : 'active'
          };
        }
      } else if (istate.assignmentsPopUpType === 'secondary') {
        if (action.payload === 'notifications' || action.payload === 'reports') {
          return {
            ...istate,
            assignmentsPopUpActive: true,
            isBackToSectionPopUp: true,
            assignmentsHeaderOne: action.payload,
            assignmentsHeaderOneBadgeOne: 'review',
            assignmentsPopUpType: 'secondary',
            currentPopUpOption: istate.secondaryPopUpOptions[action.payload],
            secondaryOptionCheckValue: action.payload === 'create' ? 'all' : 'unread'
          };
        } else {
          return {
            ...istate,
            assignmentsPopUpActive: true,
            isBackToSectionPopUp: true,
            assignmentsHeaderOne: 'assignments',
            assignmentsHeaderOneBadgeOne: action.payload.clickValue,
            assignmentsPopUpType: 'secondary',
            currentPopUpOption: istate.secondaryPopUpOptions[action.payload.clickValue],
            secondaryOptionCheckValue: action.payload.clickValue === 'create' ? 'all' : 'active'
          };
        }
      } else {
        return istate;
      }
      
    case SET_ASSIGNMENT_PREVIOUS_POPUP:
      if (istate.assignmentsPopUpType === 'primary') {
        return {
          ...istate,
          currentPopUpOption: [],
          assignmentsPopUpActive: false
        };
      } else if (istate.assignmentsPopUpType === 'secondary') {
        return {
          ...istate,
          currentPopUpOption:  getPopUpOptionList(
            istate.primaryPopUpOptions,
            action.payload.permissionObject
          ),
          assignmentsHeaderOne: 'assignments',
          assignmentsHeaderOneBadgeOne: '',
          assignmentsPopUpType: 'primary',
          secondaryOptionCheckValue: 'active'
        };
      } else {
        return istate;
      }
    case SET_ASSIGNMENT_SECONDARY_OPTION_VALUE:
      return {
        ...istate,
        secondaryOptionCheckValue: action.payload
      };
    case SET_ASSIGNMENT_BASIC_REDUCER_STATE:
      return {
        ...istate,
        informationBasic: action.payload
      };
    case SET_ASSIGNMENT_DYNAMIC_SINGLE_STATE:
      return {
        ...istate,
        informationAllocation: {
          ...istate.informationAllocation,
          [action.payload.stateName]: {
            ...istate.informationAllocation[action.payload.stateName],
            [action.payload.actualStateName]: action.payload.value
          }
        }
      };
    case SET_ASSIGNMENT_FRAMEWORK_STATE:
      return {
        ...istate,
        informationFramework: action.payload
      };
    case SET_ASSIGNMENT_ALL_STATE:
      return {
        ...istate,
        assignmentDistinctEvaluationRef: action.payload.assignmentDistinctEvaluationRef
      };
    case SET_ASSIGNMENT_COMMUNIQUE_FRAMEWORK_STATE:
      return {
        ...istate,
        informationFramework: {
          ...istate.informationFramework,
          assignmentCommunique: [
            ...istate.informationFramework.assignmentCommunique,
            action.payload
          ]
        }
      };
    case SET_ASSIGNMENT_SYNOPSIS_FRAMEWORK_STATE:
      return {
        ...istate,
        informationFramework: {
          ...istate.informationFramework,
          assignmentSynopsis: [...istate.informationFramework.assignmentSynopsis, action.payload]
        }
      };
    case SET_ASSIGNMENT_DYNAMIC_FRAMEWORK_STATE:
      return {
        ...istate,
        informationFramework: {
          ...istate.informationFramework,
          [action.payload.stateName]: action.payload.value
        }
      };
    case SET_ASSIGNMENT_DYNAMIC_SETUP_STATE:
      return {
        ...istate,
        informationSetup: action.payload
      };
    case SET_ASSIGNMENT_FRAMEWORK_TEMPLATE_ALIGNMENT_STATE:
      return {
        ...istate,
        informationFramework: {
          ...istate.informationFramework,
          assignmentAdministrationTemplateAlignment: action.payload
        }
      };
    case SET_ASSIGNMENT_GAUGE_STATE:
      return {
        ...istate,
        informationFramework: {
          ...istate.informationFramework,
          assignmentGaugeDistinct: action.payload
        }
      };
    case SET_ASSIGNMENT_TIMELINE_STATE:
      return {
        ...istate,
        informationFramework: {
          ...istate.informationFramework,
          assignmentTimeline: action.payload
        }
      };
    case CLEAR_ASSIGNMENT_INFO:
      return JSON.parse(JSON.stringify(initialState));
    default:
      return istate;
  }
};

export default AssignmentReducer;
