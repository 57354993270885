import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PopUpPicture from '../../PopUpInformation/PopUpPicture';
import PopUpTextField from '../../PopUpInformation/PopUpTextField';
import PopUpTelephone from '../../PopUpInformation/PopUpTelephone';
import PopUpAddress from '../../PopUpInformation/PopUpAddress';
import PopUpReviewList from '../../PopUpInformation/PopUpReviewList';
import PopUpAssesseeName from '../../PopUpInformation/PopUpAssesseeName';
import PopUpAddressEmail from '../../PopUpInformation/PopUpAddressEmail';
import PopUpConfirm from '../../PopUpGeneric/PopUpConfirm';
import PopUpDropList from '../../PopUpInformation/PopUpDropList';
import {
  SET_NEXT_POPUP,
  CLEAR_ASSOCIATE_INFO,
  POPUP_CLOSE,
  UPDATE_ASSOCIATE_BASIC_INFO,
  UPDATE_ASSOCIATE_WORKADDRESS_INFO,
  UPDATE_ASSOCIATE_WORKTELEPHONE_INFO,
  UPDATE_ASSOCIATE_ADMIN_BASIC_INFO,
  UPDATE_ASSESSEE_BASIC_INFO,
  UPDATE_ASSESSEE_ADDRESS_EMAIL_PRIMARY_INFO,
  UPDATE_ASSESSEE_MOBILE_INFO,
  UPDATE_ASSESSEE_PERSONAL_INFO,
  CREATE_ASSOCIATE_SAGA,
  LOADER_START,
  SET_ASSOCIATE_DYNAMIC_SINGLE_STATE,
  UPDATE_ASSOCIATE_SETUP_INFO,
  UPDATE_ASSOCIATE_WORKTELEPHONE_SECONDARY_INFO,
  UPDATE_ASSOCIATE_WORKADDRESS_SECONDARY_INFO,
  UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO,
  UPDATE_ASSESSEE_ENGAGEMENT_INFO,
  SET_IGURU_NODE_DYNAMIC_SINGLE_STATE,
  RESET_ALL_REDUCER,
  UPDATE_ASSOCIATE_WEBSITE_PRIMARY_INFO,
  UPDATE_ASSOCIATE_WEBSITE_SECONDARY_INFO,
  SET_DISPLAY_THREE_SINGLE_STATE,
  UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO,
  UPDATE_ASSOCIATE_SETUP_ASSIGNMENT_INFO,
  UPDATE_ASSOCIATE_SETUP_ITEM_INFO,
  UPDATE_ASSOCIATE_SETUP_ANALYTIC_INFO,
  UPDATE_ASSOCIATE_ASSOCIATENODE_INFO,
  UPDATE_ASSOCIATE_COUNTRY_INFO,
  UPDATE_ASSOCIATE_LANGUAGE_INFO,
  UPDATE_ASSOCIATE_CURRENCY_INFO,
  SET_SINGLE_ASSOCIATE_INFORMATION,
  UPDATE_ASSOCIATE_SETUP_RESET_SAGA,
  UPDATE_ASSOCIATE_SETUPPLUS_ASSESSEE_INFO,
  UPDATE_ASSOCIATE_SETUPPLUS_INFO,
  UPDATE_ASSOCIATE_SETUPPLUS_ASSESSMENT_INFO,
  UPDATE_ASSOCIATE_SETUPPLUS_ITEM_INFO,
  UPDATE_ASSOCIATE_SETUPPLUS_ANALYTIC_INFO,
  UPDATE_ASSOCIATE_SETUPPLUS_ASSIGNMENT_INFO,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_POPUP_SINGLE_STATE
} from '../../actionType';
import PopUpTagSecondary from '../../PopUpInformation/PopUpTagSecondary';
import { SIGN_IN_URL } from '../../endpoints';
import PopUpCheckbox from '../../PopUpInformation/PopUpCheckbox';
import { getAllCountryList, getPopUpOptionList } from '../../Actions/GenericActions';
import { TrafficOutlined } from '@material-ui/icons';
import { ASSOCIATE_DISTINCT_REVIEW_LIST_POPUP_OPTION } from '../../PopUpConfig';
import PopUpCheckboxRolePermission from '../../PopUpInformation/PopUpCheckboxRolePermission';
const PopUpSignOnAssociate = () => {
  const { popupMode, isPopUpValue, popupErrorMsg } = useSelector((state) => state.PopUpReducer);
  const associateInfo = useSelector((state) => state.AssociateCreateReducer);
  const assesseeInfo = useSelector((state) => state.AssesseeCreateReducer);
  const { reviewMode, responseObject, statusPopUpValue, createMode, headerOne } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  const informationContact = assesseeInfo.informationContact;
  const { associateTagPrimary } = useParams();
  const {
    coreGroupReviewListData,
    selectedAssociateInfo,
    coreRoleReviewListData,
    coreNodeReviewListData,
    permissionStateOne,
    permissionStateTwo,
    permissionStateThree,
    associateLanguages,
    associateCurrencyMaster,
    coreTypeReviewListData,
    allCountryList,
    sectionCreateType,
    sectionCreateTypeSecondary,
    signedAssesseePermission
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const [roleSelectedError, setRoleSelectedError] = useState('');
  const [typeSelectedError, setTypeSelectedError] = useState('');
  const history = useHistory();
  console.log('associateCurrencyMaster', associateCurrencyMaster);
  const dispatch = useDispatch();
  useEffect(() => {
    if (assesseeInfo.assesseeInformationData) {
      if (popupMode === 'ASSOCIATE_SIGN_ON') {
        let path = SIGN_IN_URL;
        history.push(path);
      } else {
        // window.location.reload();
        dispatch({ type: RESET_ALL_REDUCER });
      }
    }
  }, [assesseeInfo.assesseeInformationData, history]);
  useEffect(() => {
    if (allCountryList.length === 0) {
      dispatch({ type: LOADER_START });
      getAllCountryList(dispatch);
    }
  }, []);
  useEffect(() => {
    let tempTypeArr = coreTypeReviewListData.filter((tt) => {
      return tt.informationSetup?.associateTypeDefault;
    });
    if (tempTypeArr.length > 0) {
      let arr = tempTypeArr.map((ob) => ob.id);
      associateInfo.informationAllocation.associateType.associateTypePrimary = arr;
    }
    console.log('arr---', tempTypeArr);
  }, [coreTypeReviewListData, createMode]);
  useEffect(() => {
    if (sectionCreateType !== '') {
      let secondaryTypeValue = sectionCreateType + '_ASSOCIATES';
      console.log(
        'secondaryTypeValue--',
        `REACT_APP_${secondaryTypeValue.replace(' ', '_').toUpperCase()}`,
        '=',
        `${process.env[`REACT_APP_${secondaryTypeValue.replace(' ', '_').toUpperCase()}`]}`
      );
      let arrTypeSecondary = [];
      arrTypeSecondary.push(
        `${process.env[`REACT_APP_${secondaryTypeValue.replace(' ', '_').toUpperCase()}`]}`
      );
      associateInfo.informationAllocation.associateType.associateTypeSecondary = arrTypeSecondary;
    }
  }, [coreTypeReviewListData, createMode, sectionCreateTypeSecondary, sectionCreateType]);
  // useEffect(() => {

  //   if (sectionCreateTypeSecondary !== '' && sectionCreateType !== '') {
  //     let arrTypeSecondary = [];

  //     arrTypeSecondary.push(
  //       `${process.env[`REACT_APP_ASSOCIATE_${sectionCreateTypeSecondary.replace(' ', '_').toUpperCase()}`]}`
  //     );
  //     arrTypeSecondary.push(
  //       `${process.env[`REACT_APP_ASSOCIATE_ANALYTICS_${sectionCreateTypeSecondary.replace(' ', '_').toUpperCase()}`]}`
  //     );

  //     associateInfo.informationAllocation.associateType.associateTypeSecondary = arrTypeSecondary;
  //     // console.log(assesseeInfo.informationAllocation.assesseeType.assesseeTypeSecondary, assesseeInfo.informationAllocation)
  //   }
  // }, [sectionCreateType, sectionCreateTypeSecondary])
  useEffect(() => {
    if (reviewMode !== 'revise') {
      let tempTypeArr = coreRoleReviewListData.filter((tt) => {
        return tt.informationSetup?.associateRoleDefault;
      });
      if (tempTypeArr.length > 0) {
        let arr = tempTypeArr.map((ob) => ob.id);
        associateInfo.informationAllocation.associateRole.associateRolePrimary = arr;
      }
    }
  }, [coreRoleReviewListData, createMode]);
  //set default type
  useEffect(() => {
    let tempTypeArr = coreTypeReviewListData.filter((tt) => {
      return tt.informationSetup?.associateTypeDefault;
    });
    if (tempTypeArr.length > 0) {
      associateInfo.informationAllocation.associateType.associateTypePrimary.push(
        tempTypeArr[0].id
      );
    }
  }, [coreTypeReviewListData, createMode]);

  const coreNodeReviewListDataRemoveSelf = [];
  if (coreNodeReviewListData.length > 0) {
    // const { associateDescendantAll, associateRoot } = coreNodeReviewListData[0];
    // let tempArr = [...associateDescendantAll, associateRoot];
    let tempArr = [...coreNodeReviewListData];
    tempArr.forEach((ob) => {
      if (responseObject.id !== ob.id) {
        coreNodeReviewListDataRemoveSelf.push(ob);
      }
    });
  }
  const CreateApi = () => {
    const {
      informationBasic,
      informationAllocation,
      informationContact,
      informationPersonal,
      tempCommunication
    } = assesseeInfo;
    let assesseeContactObj = informationContact;

    if (tempCommunication === 'email address (primary)') {
      assesseeContactObj.assesseeAddressEmailPrimary.assesseeAddressEmailCommunication = true;
    }
    if (tempCommunication === 'email address (secondary)') {
      assesseeContactObj.assesseeAddressEmailSecondary.assesseeAddressEmailCommunication = true;
    }
    // add default root node in allocation if node not selected
    if (
      associateInfo.informationAllocation.associateNode.associateNodePrimary.length === 0 &&
      coreNodeReviewListData.length !== 0
    ) {
      let rootNode = coreNodeReviewListData.filter((node) => {
        return node.informationFramework.associateNodeAscendantPrimary === null;
      });
      let rootNodeId = rootNode[0].id;
      associateInfo.informationAllocation.associateNode.associateNodePrimary = [
        ...associateInfo.informationAllocation.associateNode.associateNodePrimary,
        rootNodeId
      ];
    }

    let requestObect = {
      module: 'Associate-Distinct',
      action: 'Create',
      signOn: popupMode === 'ASSOCIATE_SIGN_ON',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId || '0123456',
        associateId:
          associateInfo.parentAssociateId === ''
            ? associateTagPrimary
            : associateInfo.parentAssociateId,
        associate: {
          informationBasic: associateInfo.informationBasic,
          informationAllocation: associateInfo.informationAllocation,
          informationContact: {
            associateAddressWebsitePrimary:
              associateInfo.informationContact.associateAddressWebsitePrimary,
            associateAddressWebsiteSecondary:
              associateInfo.informationContact.associateAddressWebsiteSecondary,
            associateAddressWorkPrimary:
              associateInfo.informationContact.associateAddressWorkPrimary,
            associateAddressWorkSecondary:
              associateInfo.informationContact.associateAddressWorkSecondary,
            associateTelephoneWorkPrimary:
              delete associateInfo?.informationContact.associateTelephoneWorkPrimary
                .associateTelephoneAreaCityTag &&
              associateInfo.informationContact.associateTelephoneWorkPrimary,
            associateTelephoneWorkSecondary:
              delete associateInfo?.informationContact.associateTelephoneWorkSecondary
                .associateTelephoneAreaCityTag &&
              associateInfo.informationContact.associateTelephoneWorkSecondary
          }
          // informationSetup: associateInfo.informationSetup
        },
        assessee: {
          informationBasic: informationBasic,
          informationAllocation: informationAllocation,
          informationContact: informationContact,
          // informationEngagement: informationEngagement,
          informationPersonal: informationPersonal
          // informationSetup: informationSetup
        }
      }
    };

    dispatch({ type: LOADER_START });
    dispatch({ type: POPUP_CLOSE });
    dispatch({ type: CREATE_ASSOCIATE_SAGA, payload: requestObect });
    let assesseeDistinctPermission =
      signedAssesseePermission?.associateDistinctPermission?.assesseeAssociateDistinctPermission;
    let popupContentArrValue = getPopUpOptionList(
      ASSOCIATE_DISTINCT_REVIEW_LIST_POPUP_OPTION,
      assesseeDistinctPermission
    );
    let finalPopupOptionArray = [];
    popupContentArrValue.forEach((element) => {
      if (element.data === 'share') {
        finalPopupOptionArray.push({ ...element, disabled: true });
      } else {
        finalPopupOptionArray.push(element);
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: finalPopupOptionArray
      }
    });
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: {
        stateName: 'popupOptionArr',
        value: finalPopupOptionArray
      }
    });
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: {
        stateName: 'originPrimaryArrOprion',
        value: finalPopupOptionArray
      }
    });
  };
  const handleNextPopupValue = () => {
    let tempCommunication = assesseeInfo.tempCommunication;
    let secondemail = informationContact.assesseeAddressEmailSecondary.assesseeAddressEmail;
    if (isPopUpValue === 'EMAILPOPUP') {
      if (tempCommunication === '' || secondemail !== '') {
        dispatch({ type: SET_NEXT_POPUP, payload: { isPopUpValue: 'EMAILSECONDARYPOPUP' } });
      } else {
        dispatch({ type: SET_NEXT_POPUP, payload: { isPopUpValue: 'MOBILETELEPHONEPOPUP' } });
      }
    } else if (isPopUpValue === 'EMAILSECONDARYPOPUP') {
      if (tempCommunication === '') {
        dispatch({ type: SET_NEXT_POPUP, payload: { isPopUpValue: 'FORCETOSELECTCOMMUNICATION' } });
      } else {
        dispatch({ type: SET_NEXT_POPUP, payload: { isPopUpValue: 'MOBILETELEPHONEPOPUP' } });
      }
    } else if (isPopUpValue === 'PICTUREPOPUP') {
      // if (popupMode === 'ASSOCIATE_SIGN_ON') {
      dispatch({ type: SET_NEXT_POPUP, payload: { isPopUpValue: 'EMAILPOPUP' } });
    } else {
      dispatch({ type: SET_NEXT_POPUP, payload: { isPopUpValue: 'CONFIRMATIONPOPUP' } });
    }
  };
  const onClickCancelYes = () => {
    dispatch({
      type: SET_DISPLAY_THREE_SINGLE_STATE,
      payload: { stateName: 'createMode', value: '' }
    });
    dispatch({ type: CLEAR_ASSOCIATE_INFO });
    dispatch({ type: POPUP_CLOSE });
  };
  const onClickSetupReset = (reviseModule) => {
    let reqBody = {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'id',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: responseObject.id
                }
              }
            }
          ]
        }
      ]
    };
    if (reviseModule === 'assessees') {
      reqBody = {
        module: 'Assessee-Setup',
        action: 'Reset',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          filter: 'true',
          search: [
            {
              condition: 'and',
              searchBy: [
                {
                  dataType: 'string',
                  conditionColumn: 'associateId',
                  conditionValue: {
                    condition: 'eq',
                    value: {
                      from: responseObject.id
                    }
                  }
                }
              ]
            }
          ]
        }
      };
    }
    if (reviseModule === 'assessments') {
      reqBody = {
        module: 'Assessment-Setup',
        action: 'Reset',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          filter: 'true',
          search: [
            {
              condition: 'and',
              searchBy: [
                {
                  dataType: 'string',
                  conditionColumn: 'associateId',
                  conditionValue: {
                    condition: 'eq',
                    value: {
                      from: responseObject.id
                    }
                  }
                }
              ]
            }
          ]
        }
      };
    }
    if (reviseModule === 'assignments') {
      reqBody = {
        module: 'Assignment-Setup',
        action: 'Reset',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          filter: 'true',
          search: [
            {
              condition: 'and',
              searchBy: [
                {
                  dataType: 'string',
                  conditionColumn: 'associateId',
                  conditionValue: {
                    condition: 'eq',
                    value: {
                      from: responseObject.id
                    }
                  }
                }
              ]
            }
          ]
        }
      };
    }
    if (reviseModule === 'associates') {
      reqBody = {
        module: 'Associate-Setup',
        action: 'Reset',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          filter: 'true',
          search: [
            {
              condition: 'and',
              searchBy: [
                {
                  dataType: 'string',
                  conditionColumn: 'associateId',
                  conditionValue: {
                    condition: 'eq',
                    value: {
                      from: responseObject.id
                    }
                  }
                }
              ]
            }
          ]
        }
      };
    }
    if (reviseModule === 'items') {
      reqBody = {
        module: 'Item-Setup',
        action: 'Reset',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          filter: 'true',
          search: [
            {
              condition: 'and',
              searchBy: [
                {
                  dataType: 'string',
                  conditionColumn: 'associateId',
                  conditionValue: {
                    condition: 'eq',
                    value: {
                      from: responseObject.id
                    }
                  }
                }
              ]
            }
          ]
        }
      };
    }
    if (reviseModule === 'analytics') {
      reqBody = {
        module: 'Job-Profile-Setup',
        action: 'Reset',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          filter: 'true',
          search: [
            {
              condition: 'and',
              searchBy: [
                {
                  dataType: 'string',
                  conditionColumn: 'associateId',
                  conditionValue: {
                    condition: 'eq',
                    value: {
                      from: responseObject.id
                    }
                  }
                }
              ]
            }
          ]
        }
      };
    }
    dispatch({ type: LOADER_START });
    dispatch({
      type: UPDATE_ASSOCIATE_SETUP_RESET_SAGA,
      payload: { request: reqBody, reviseModule: reviseModule }
    });
    dispatch({ type: POPUP_CLOSE });
  };
  const onClickYes = () => {
    if (popupMode === 'ASSOCIATE_SIGN_ON' || popupMode === 'ASSOCIATE_CREATE') {
      dispatch({
        type: SET_NEXT_POPUP,
        payload: { isPopUpValue: 'NAMEPOPUP' }
      });
    }
  };
  const onClickNo = () => {
    dispatch({
      type: SET_DISPLAY_THREE_SINGLE_STATE,
      payload: { stateName: 'createMode', value: '' }
    });
    dispatch({ type: CLEAR_ASSOCIATE_INFO });
    dispatch({ type: POPUP_CLOSE });
  };
  const updateAssociateAllocation = (e, stateName, actualStateName) => {
    setRoleSelectedError('');
    setTypeSelectedError('');
    let id = e.currentTarget.getAttribute('tag');
    let arr = associateInfo.informationAllocation[stateName][actualStateName];
    let isDefault = e.currentTarget.getAttribute('data-default');
    if (isDefault !== 'true') {
      if (arr.includes(id)) {
        document.getElementById(id).style.backgroundColor = 'white';
        arr = arr.filter(function (number) {
          return number !== id;
        });
      } else {
        arr.push(id);
        document.getElementById(id).style.backgroundColor = '#F0F0F0';
      }
      dispatch({
        type: SET_ASSOCIATE_DYNAMIC_SINGLE_STATE,
        payload: {
          stateName: stateName,
          actualStateName: actualStateName,
          value: arr
        }
      });
    }
  };
  const onClickCheckbox = (e, inputHeader, primaryheader) => {
    let val = inputHeader + ' ' + primaryheader;
    if (val === 'work address primary') {
      associateInfo.informationContact.associateAddressWorkPrimary.associateAddressCommunication = true;
      dispatch({
        type: SET_SINGLE_ASSOCIATE_INFORMATION,
        payload: { stateName: 'tempAssociateCommunication', value: e.target.checked && val }
      });
    }
    if (val === 'work telephone primary') {
      associateInfo.informationContact.associateAddressWorkPrimary.associateAddressCommunication = true;
      dispatch({
        type: SET_SINGLE_ASSOCIATE_INFORMATION,
        payload: { stateName: 'tempAssociateTeleCommunication', value: e.target.checked && val }
      });
    }
  };
  const updateParentNode = (e) => {
    let tagId = e.currentTarget.getAttribute('tag');
    let tagIdArr = associateInfo.informationFramework.associateAscendant.associateAscendantPrimary;
    if (tagIdArr.includes(tagId)) {
      setRoleSelectedError('');
      document.getElementById(tagId).style.backgroundColor = 'white';
      tagIdArr = tagIdArr.filter(function (number) {
        return number !== tagId;
      });
    } else {
      var arr = [];
      tagIdArr = [...arr];
      tagIdArr.push(tagId);
      document.getElementById(tagId).style.backgroundColor = '#F0F0F0';
    }
    dispatch({
      type: SET_IGURU_NODE_DYNAMIC_SINGLE_STATE,
      payload: {
        objectName: 'informationFramework',
        stateName: 'associateAscendant',
        actualStateName: 'associateAscendantPrimary',
        value: tagIdArr
      }
    });
  };
  let selectedBadge = associateInfo?.selectedBadgeValue;
  // setUpPlusRevisePermission from role
  let setUpPlusPermissions =
    signedAssesseePermission?.associateDistinctPermission?.associateAssociateSetupGenericPermission
      ?.revise || false;
  return (
    <div>
      {isPopUpValue === 'NAMEALIASPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'name'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={'DESCRIPTIONPOPUP'}
          actualLableValue={'associateName'}
          errorMsg={popupErrorMsg}
          actualLableValueVerification={'associateNameVerification'}
          basicInfo={associateInfo.informationBasic}
          typeOfSetObject={UPDATE_ASSOCIATE_BASIC_INFO}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'DESCRIPTIONPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'description'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={false}
          actualLableValue={'associateDescription'}
          basicInfo={associateInfo.informationBasic}
          typeOfSetObject={UPDATE_ASSOCIATE_BASIC_INFO}
          nextPopUpValue={'ASSOCIATEPICTUREPOPUP'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ASSOCIATEPICTUREPOPUP' && (
        <PopUpPicture
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          basicInfo={associateInfo.informationBasic}
          actualLableValue={'associatePicture'}
          actualLableValueVerification={'associatePictureVerification'}
          typeOfSetObject={UPDATE_ASSOCIATE_BASIC_INFO}
          nextPopUpValue={popupMode === 'ASSOCIATE_SIGN_ON' ? 'WORKADDRESSPOPUP' : 'GROUPPOPUP'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}

      {isPopUpValue === 'GROUPPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'NODEPOPUP'}
          inputHeader={'group'}
          inputHeaderBadge={'primary'}
          infoMsg={'select a group'}
          ListData={coreGroupReviewListData}
          textOne={'associateGroupName'}
          textTwo={'associateGroupDescription'}
          selectedList={associateInfo?.informationAllocation?.associateGroup.associateGroupPrimary}
          // onClickEvent={updateAssociateGroups}
          onClickEvent={(e) => {
            updateAssociateAllocation(e, 'associateGroup', 'associateGroupPrimary');
          }}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          useLocalState={true}
          stateName={`associateGroup`}
          actualStateName={`associateGroupPrimary`}
          objectName={'informationAllocation'}
          updateState={SET_ASSOCIATE_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'GROUPSECONDARYPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'NODEPOPUP'}
          inputHeader={'group'}
          inputHeaderBadge={'secondary'}
          infoMsg={'select a group'}
          ListData={coreGroupReviewListData}
          textOne={'associateGroupName'}
          textTwo={'associateGroupDescription'}
          selectedList={
            associateInfo?.informationAllocation?.associateGroup.associateGroupSecondary
          }
          // onClickEvent={updateAssociateGroups}
          onClickEvent={(e) => {
            updateAssociateAllocation(e, 'associateGroup', 'associateGroupSecondary');
          }}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          useLocalState={true}
          stateName={`associateGroup`}
          actualStateName={`associateGroupSecondary`}
          objectName={'informationAllocation'}
          updateState={SET_ASSOCIATE_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'NODEPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'ROLEPOPUP'}
          inputHeader={'node'}
          inputHeaderBadge={'primary'}
          infoMsg={'select a node'}
          isRequired={true}
          minimumSelected={1}
          setErrorMsg={setRoleSelectedError}
          errorMsg={roleSelectedError}
          ListData={coreNodeReviewListData}
          textOne={'associateNodeName'}
          textTwo={'associateNodeDescription'}
          onClickEvent={(e) => {
            updateAssociateAllocation(e, 'associateNode', 'associateNodePrimary');
          }}
          selectedList={associateInfo?.informationAllocation?.associateNode.associateNodePrimary}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          useLocalState={true}
          stateName={`associateNode`}
          actualStateName={`associateNodePrimary`}
          objectName={'informationAllocation'}
          updateState={SET_ASSOCIATE_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'NODESECONDARYPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          nextPopUpValue={''}
          inputHeader={'node'}
          inputHeaderBadge={'secondary'}
          infoMsg={'select a node'}
          onClickEvent={(e) => {
            updateAssociateAllocation(e, 'associateNode', 'associateNodeSecondary');
          }}
          ListData={coreNodeReviewListData}
          selectedList={associateInfo?.informationAllocation?.associateNode.associateNodeSecondary}
          textOne={'associateNodeName'}
          textTwo={'associateNodeDescription'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          useLocalState={true}
          stateName={`associateNode`}
          actualStateName={`associateNodeSecondary`}
          objectName={'informationAllocation'}
          updateState={SET_ASSOCIATE_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'ROLEPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'TYPELISTPOPUP'}
          inputHeader={'role'}
          inputHeaderBadge={'primary'}
          infoMsg={'select a role'}
          ListData={coreRoleReviewListData.filter((tt) => {
            return tt.informationSetup.associateRoleDefault;
          })}
          textOne={'associateRoleName'}
          textTwo={'associateRoleDescription'}
          dataDefault={'associateRoleDefault'}
          // onClickEvent={updateAssociateRoles}
          onClickEvent={(e) => {
            updateAssociateAllocation(e, 'associateRole', 'associateRolePrimary');
          }}
          setErrorMsg={setRoleSelectedError}
          errorMsg={roleSelectedError}
          isRequired={true}
          minimumSelected={1}
          selectedList={associateInfo?.informationAllocation?.associateRole.associateRolePrimary}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          useLocalState={true}
          stateName={`associateRole`}
          actualStateName={`associateRolePrimary`}
          objectName={'informationAllocation'}
          updateState={SET_ASSOCIATE_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'ROLESECONDARYPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'WORKADDRESSPOPUP'}
          inputHeader={'role'}
          inputHeaderBadge={'secondary'}
          infoMsg={'select a role'}
          ListData={coreRoleReviewListData}
          textOne={'associateRoleName'}
          textTwo={'associateRoleDescription'}
          // onClickEvent={updateAssociateRoles}
          onClickEvent={(e) => {
            updateAssociateAllocation(e, 'associateRole', 'associateRoleSecondary');
          }}
          setErrorMsg={setRoleSelectedError}
          errorMsg={roleSelectedError}
          isRequired={true}
          selectedList={associateInfo?.informationAllocation?.associateRole.associateRoleSecondary}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          useLocalState={true}
          stateName={`associateRole`}
          actualStateName={`associateRoleSecondary`}
          objectName={'informationAllocation'}
          updateState={SET_ASSOCIATE_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'TYPELISTPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'WORKADDRESSPOPUP'}
          inputHeader={'type'}
          inputHeaderBadge={'primary'}
          infoMsg={'select a type'}
          ListData={coreTypeReviewListData.filter((tt) => {
            return tt.informationSetup.associateTypeDefault;
          })}
          textOne={'associateTypeName'}
          textTwo={'associateTypeDescription'}
          onClickEvent={(e) => {
            updateAssociateAllocation(e, 'associateType', 'associateTypePrimary');
          }}
          setErrorMsg={setTypeSelectedError}
          errorMsg={typeSelectedError}
          // isRequired={true}
          // minimumSelected={1}
          selectedList={associateInfo?.informationAllocation?.associateType.associateTypePrimary}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          useLocalState={true}
          stateName={`associateType`}
          actualStateName={`associateTypePrimary`}
          objectName={'informationAllocation'}
          updateState={SET_ASSOCIATE_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'TYPELISTPOPUPSECONDARY' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'WORKADDRESSPOPUP'}
          inputHeader={'type'}
          inputHeaderBadge={'secondary'}
          infoMsg={'select a type'}
          ListData={coreTypeReviewListData.filter((tt) => {
            return !tt.informationSetup.associateTypeDefault;
          })}
          textOne={'associateTypeName'}
          textTwo={'associateTypeDescription'}
          onClickEvent={(e) => {
            updateAssociateAllocation(e, 'associateType', 'associateTypeSecondary');
          }}
          setErrorMsg={setTypeSelectedError}
          errorMsg={typeSelectedError}
          isRequired={true}
          minimumSelected={1}
          selectedList={associateInfo?.informationAllocation?.associateType.associateTypeSecondary}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          useLocalState={true}
          stateName={`associateType`}
          actualStateName={`associateTypeSecondary`}
          objectName={'informationAllocation'}
          updateState={SET_ASSOCIATE_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'ASSOCIATESPARENTLISTPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'associates'}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'CONFIRMATIONPOPUP'}
          inputHeader={'associate'}
          inputHeaderBadge={'ascendant'}
          inputHeaderBadgeTwo={'primary'}
          infoMsg={'select a associate'}
          ListData={coreNodeReviewListDataRemoveSelf}
          isRequired={true}
          selectedList={
            associateInfo.informationFramework.associateAscendant.associateAscendantPrimary
          }
          setErrorMsg={setRoleSelectedError}
          errorMsg={roleSelectedError}
          textOne={'associateName'}
          textTwo={'associateDescription'}
          onClickEvent={updateParentNode}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'WORKADDRESSPOPUP' && (
        <PopUpAddress
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          inputHeader={'work address'}
          primaryheader={'primary'}
          nextPopUpValue={'WORKTELEPHONE'}
          isRequired={true}
          onClickCheckbox={onClickCheckbox}
          addressCommunication={associateInfo.tempAssociateCommunication}
          basicInfo={associateInfo.informationContact.associateAddressWorkPrimary}
          countryCode={'associateTelephoneCountryRegion'}
          typeOfSetObject={UPDATE_ASSOCIATE_WORKADDRESS_INFO}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'WORKADDRESSSECONDARYPOPUP' && (
        <PopUpAddress
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          inputHeader={'work address'}
          primaryheader={'secondary'}
          nextPopUpValue={''}
          onClickCheckbox={onClickCheckbox}
          addressCommunication={associateInfo.tempAssociateCommunication}
          isRequired={true}
          basicInfo={associateInfo.informationContact.associateAddressWorkSecondary}
          countryCode={'associateAddressWorkSecondary'}
          typeOfSetObject={UPDATE_ASSOCIATE_WORKADDRESS_SECONDARY_INFO}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'WORKTELEPHONE' && (
        <PopUpTelephone
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          inputHeader={'work telephone'}
          primaryheader={'primary'}
          onClickCheckbox={onClickCheckbox}
          tempTelephoneCommunication={associateInfo.tempAssociateTeleCommunication}
          basicInfo={associateInfo.informationContact.associateTelephoneWorkPrimary}
          isMobileState={false}
          typeOfSetObject={UPDATE_ASSOCIATE_WORKTELEPHONE_INFO}
          nextPopUpValue={'ASSOCIATECONFIRMATIONPOPUP'}
          isRequired={false}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'WORKTELEPHONESECONDARY' && (
        <PopUpTelephone
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          inputHeader={'work telephone'}
          primaryheader={'secondary'}
          onClickCheckbox={onClickCheckbox}
          tempTelephoneCommunication={associateInfo.tempAssociateTeleCommunication}
          basicInfo={associateInfo.informationContact.associateTelephoneWorkSecondary}
          isMobileState={false}
          typeOfSetObject={UPDATE_ASSOCIATE_WORKTELEPHONE_SECONDARY_INFO}
          nextPopUpValue={''}
          isRequired={true}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'DATEFORMATPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'associateDateFormat'}
          label={'date'}
          listSelect={[
            { id: 'dd/mm/yyyy', name: 'dd/mm/yyyy' },
            { id: 'mm/dd/yyyy', name: 'mm/dd/yyyy' }
          ]}
          mappingValue={'id'}
          labelval={'date'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo.informationSetup.associate || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'associateDateFormatRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'DICTIONARYPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'associateDictionary'}
          label={'dictionary'}
          listSelect={[
            { id: 'Generic', name: 'Generic' },
            { id: 'iGuru Career', name: 'iGuru Career' },
            { id: 'iGuru Education', name: 'iGuru Education' },
            { id: 'iGuru Occupation', name: 'iGuru Occupation' },
            { id: 'iGuru Pulse', name: 'iGuru Pulse' }
          ]}
          mappingValue={'id'}
          labelval={'dictionary'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={associateInfo.informationSetup.associate || {}}
          typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'associateDictionaryRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'LANGUAGEPOPUP' && (
        <PopUpDropList
          isActive={true}
          //tag={'associateLanguage'}
          tag={'associateLanguageTag'}
          label={'language'}
          //listSelect={[{ id: 'English (India)', name: 'English (India)' }]}
          listSelect={associateLanguages}
          mappingValue={'id'}
          labelval={'language'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          //basicInfo={associateInfo.informationSetup.associate || {}}
          basicInfo={associateInfo?.associateLanguage || {}}
          //typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          typeOfSetObject={UPDATE_ASSOCIATE_LANGUAGE_INFO}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'associateLanguageRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'PEOPLEPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assesseeDistinctNameFormat'}
          label={'name'}
          listSelect={[
            { id: 'First-Name Other-Name Last-Name', name: 'First-Name Other-Name Last-Name' },
            { id: 'Last-Name First-Name Other-Name', name: 'Last-Name First-Name Other-Name' }
          ]}
          mappingValue={'id'}
          labelval={'people'}
          inputHeader={'assessees'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'information'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo.informationSetup.assessee || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessee || {}
              : associateInfo?.informationSetupPlus?.assessee
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSEE_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'assesseeDistinctNameFormatRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSOCIATEPEOPLEPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assesseeNameFormat'}
          label={'name'}
          listSelect={[
            { id: 'First-Name Other-Name Last-Name', name: 'First-Name Other-Name Last-Name' },
            { id: 'Last-Name First-Name Other-Name', name: 'Last-Name First-Name Other-Name' }
          ]}
          mappingValue={'id'}
          labelval={'people'}
          inputHeader={'associates'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'information'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo.informationSetup.assessee || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'assesseeNameFormatRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'TIMEPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'associateTimeFormat'}
          label={'time'}
          listSelect={[
            { id: '12 Hours', name: '12 Hours' },
            { id: '24 Hours', name: '24 Hours' }
          ]}
          mappingValue={'id'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo.informationSetup.associate || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'associateTimeFormatRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSESSEECREATEAPPROVALPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assesseeDistinctCreateApproval'}
          label={'approval'}
          listSelect={[
            { id: true, name: 'Required' },
            { id: false, name: 'Unrequired' }
          ]}
          mappingValue={'id'}
          inputHeader={'assessees'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'create'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessee || {}
              : associateInfo?.informationSetupPlus?.assessee || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSEE_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'assesseeDistinctCreateApprovalRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSESSMENTCREATEAPPROVALPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentDistinctCreateApproval'}
          label={'approval'}
          listSelect={[
            { id: true, name: 'Required' },
            { id: false, name: 'Unrequired' }
          ]}
          mappingValue={'id'}
          inputHeader={'assessments'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'create'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assessment || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessment || {}
              : associateInfo?.informationSetupPlus?.assessment || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSMENT_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'assessmentDistinctCreateApprovalRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSIGNMENTCREATEAPPROVALPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assignmentDistinctCreateApproval'}
          label={'approval'}
          listSelect={[
            { id: true, name: 'Required' },
            { id: false, name: 'Unrequired' }
          ]}
          mappingValue={'id'}
          inputHeader={'assignments'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'create'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assignment || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSIGNMENT_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assignment || {}
              : associateInfo?.informationSetupPlus?.assignment || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSIGNMENT_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSIGNMENT_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'assignmentDistinctCreateApprovalRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSIGNMENT_SHARE_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assignmentDistinctSharePermission'}
          label={'share'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'assignments'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={''}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assignment || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSIGNMENT_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assignment || {}
              : associateInfo?.informationSetupPlus?.assignment || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSIGNMENT_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSIGNMENT_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'assignmentDistinctSharePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSIGNMENT_GROUP_SHARE_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assignmentGroupSharePermission'}
          label={'share'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'assignments'}
          inputHeaderBadgeOne={'groups'}
          inputHeaderBadgeTwo={''}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assignment || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSIGNMENT_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assignment || {}
              : associateInfo?.informationSetupPlus?.assignment || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSIGNMENT_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSIGNMENT_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'assignmentGroupSharePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSIGNMENT_TYPE_SHARE_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assignmentTypeSharePermission'}
          label={'share'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'assignments'}
          inputHeaderBadgeOne={'types'}
          inputHeaderBadgeTwo={''}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assignment || {}
              : associateInfo?.informationSetupPlus?.assignment || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSIGNMENT_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSIGNMENT_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'assignmentTypeSharePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}

      {isPopUpValue === 'ITEM_SHARE_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'itemDistinctSharePermission'}
          label={'share'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'items'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={''}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.item || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ITEM_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.item || {}
              : associateInfo?.informationSetupPlus?.item || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ITEM_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ITEM_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'itemDistinctSharePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ITEM_GROUP_SHARE_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'itemGroupSharePermission'}
          label={'share'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'items'}
          inputHeaderBadgeOne={'groups'}
          inputHeaderBadgeTwo={''}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.item || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ITEM_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.item || {}
              : associateInfo?.informationSetupPlus?.item || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ITEM_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ITEM_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'itemGroupSharePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ITEM_TYPE_SHARE_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'itemTypeSharePermission'}
          label={'share'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'items'}
          inputHeaderBadgeOne={'types'}
          inputHeaderBadgeTwo={''}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.item || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ITEM_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.item || {}
              : associateInfo?.informationSetupPlus?.item || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ITEM_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ITEM_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'itemTypeSharePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ANALYTIC_SHARE_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'iGuruAnalyticDistinctSharePermission'}
          label={'share'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'iGuru analytics'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={''}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.analytic || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ANALYTIC_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.analytic || {}
              : associateInfo?.informationSetupPlus?.analytic || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ANALYTIC_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ANALYTIC_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'iGuruAnalyticDistinctSharePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ANALYTIC_GROUP_SHARE_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'iGuruAnalyticGroupSharePermission'}
          label={'share'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'iGuru analytics'}
          inputHeaderBadgeOne={'groups'}
          inputHeaderBadgeTwo={''}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.analytic || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ANALYTIC_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.analytic || {}
              : associateInfo?.informationSetupPlus?.analytic || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ANALYTIC_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ANALYTIC_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'iGuruAnalyticGroupSharePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ANALYTIC_TYPE_SHARE_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'iGuruAnalyticTypeSharePermission'}
          label={'share'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'iGuru analytics'}
          inputHeaderBadgeOne={'types'}
          inputHeaderBadgeTwo={''}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.analytic || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ANALYTIC_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.analytic || {}
              : associateInfo?.informationSetupPlus?.analytic || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ANALYTIC_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ANALYTIC_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'iGuruAnalyticTypeSharePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ITEMCREATEAPPROVALPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'itemDistinctCreateApproval'}
          label={'approval'}
          listSelect={[
            { id: true, name: 'Required' },
            { id: false, name: 'Unrequired' }
          ]}
          mappingValue={'id'}
          inputHeader={'items'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'create'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.item || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ITEM_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.item || {}
              : associateInfo?.informationSetupPlus?.item || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ITEM_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ITEM_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'itemDistinctCreateApprovalRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'IGURUANALYTICSCREATEAPPROVALPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'iGuruAnalyticDistinctCreateApproval'}
          label={'approval'}
          listSelect={[
            { id: true, name: 'Required' },
            { id: false, name: 'Unrequired' }
          ]}
          mappingValue={'id'}
          inputHeader={'iGuru analytics'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'create'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.analytic || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ANALYTIC_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.analytic || {}
              : associateInfo?.informationSetupPlus?.analytic || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ANALYTIC_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ANALYTIC_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'iGuruAnalyticDistinctCreateApprovalRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSOCIATECREATEAPPROVALPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'associateDistinctCreateApproval'}
          label={'approval'}
          listSelect={[
            { id: true, name: 'Required' },
            { id: false, name: 'Unrequired' }
          ]}
          mappingValue={'id'}
          inputHeader={'associates'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'create'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.associate || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'associateDistinctCreateApprovalRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSESSEECREATEPERMISSIONPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assesseeDistinctCreatePermission'}
          label={'permission'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'assessees'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'create'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assessee || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessee || {}
              : associateInfo?.informationSetupPlus?.assessee
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSEE_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'assesseeDistinctCreatePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSESSMENTCREATEPERMISSIONPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentDistinctCreatePermission'}
          label={'permission'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'assessments'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'create'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assessment || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessment || {}
              : associateInfo?.informationSetupPlus?.assessment || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSMENT_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'assessmentDistinctCreatePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSIGNMENTCREATEPERMISSIONPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assignmentDistinctCreatePermission'}
          label={'permission'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'assignments'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'create'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assignment || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSIGNMENT_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assignment || {}
              : associateInfo?.informationSetupPlus?.assignment || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSIGNMENT_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSIGNMENT_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'assignmentDistinctCreatePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ITEMCREATEPERMISSIONPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'itemDistinctCreatePermission'}
          label={'permission'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'items'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'create'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.item || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ITEM_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.item || {}
              : associateInfo?.informationSetupPlus?.item || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ITEM_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ITEM_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'itemDistinctCreatePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'IGURUANALYTICSCREATEPERMISSIONPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'iGuruAnalyticDistinctCreatePermission'}
          label={'permission'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'iGuru analytics'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'create'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.analytic || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ANALYTIC_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.analytic || {}
              : associateInfo?.informationSetupPlus?.analytic || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ANALYTIC_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ANALYTIC_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'iGuruAnalyticDistinctCreatePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSOCIATECREATEPERMISSIONPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'associateDistinctCreatePermission'}
          label={'permission'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'associates'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'create'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.associate || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'associateDistinctCreatePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSESSEEGROUPCREATEPERMISSIONPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assesseeGroupCreatePermission'}
          label={'permission'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'assessees'}
          inputHeaderBadgeOne={'groups'}
          inputHeaderBadgeTwo={'create'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assessee || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessee || {}
              : associateInfo?.informationSetupPlus?.assessee
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSEE_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'assesseeGroupCreatePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSESSMENTGROUPCREATEPERMISSIONPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentGroupCreatePermission'}
          label={'permission'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'assessments'}
          inputHeaderBadgeOne={'groups'}
          inputHeaderBadgeTwo={'create'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assessment || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessment || {}
              : associateInfo?.informationSetupPlus?.assessment || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSMENT_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'assessmentGroupCreatePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSIGNMENTGROUPCREATEPERMISSIONPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assignmentGroupCreatePermission'}
          label={'permission'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'assignments'}
          inputHeaderBadgeOne={'groups'}
          inputHeaderBadgeTwo={'create'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assignment || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSIGNMENT_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assignment || {}
              : associateInfo?.informationSetupPlus?.assignment || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSIGNMENT_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSIGNMENT_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'assignmentGroupCreatePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ITEMGROUPCREATEPERMISSIONPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'itemGroupCreatePermission'}
          label={'permission'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'items'}
          inputHeaderBadgeOne={'groups'}
          inputHeaderBadgeTwo={'create'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.item || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ITEM_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.item || {}
              : associateInfo?.informationSetupPlus?.item || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ITEM_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ITEM_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'itemGroupCreatePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'IGURUANALYTICSGROUPCREATEPERMISSIONPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'iGuruAnalyticGroupCreatePermission'}
          label={'permission'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'iGuru analytics'}
          inputHeaderBadgeOne={'groups'}
          inputHeaderBadgeTwo={'create'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.analytic || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ANALYTIC_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.analytic || {}
              : associateInfo?.informationSetupPlus?.analytic || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ANALYTIC_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ANALYTIC_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'iGuruAnalyticGroupCreatePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSOCIATEGROUPCREATEPERMISSIONPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'associateGroupCreatePermission'}
          label={'permission'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'associates'}
          inputHeaderBadgeOne={'groups'}
          inputHeaderBadgeTwo={'create'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.associate || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'associateGroupCreatePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSESSEEROLECREATEPERMISSIONPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assesseeRoleCreatePermission'}
          label={'permission'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'assessees'}
          inputHeaderBadgeOne={'roles'}
          inputHeaderBadgeTwo={'create'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assessee || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessee || {}
              : associateInfo?.informationSetupPlus?.assessee
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSEE_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'assesseeRoleCreatePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSOCIATEROLECREATEPERMISSIONPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'associateRoleCreatePermission'}
          label={'permission'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'associates'}
          inputHeaderBadgeOne={'roles'}
          inputHeaderBadgeTwo={'create'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.associate || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'associateRoleCreatePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSESSEETYPECREATEPERMISSIONPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assesseeTypeCreatePermission'}
          label={'permission'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'assessees'}
          inputHeaderBadgeOne={'types'}
          inputHeaderBadgeTwo={'create'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assessee || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessee || {}
              : associateInfo?.informationSetupPlus?.assessee
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSEE_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'assesseeTypeCreatePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSESSMENTTYPECREATEPERMISSIONPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentTypeCreatePermission'}
          label={'permission'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'assessments'}
          inputHeaderBadgeOne={'types'}
          inputHeaderBadgeTwo={'create'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assessment || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessment || {}
              : associateInfo?.informationSetupPlus?.assessment || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSMENT_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'assessmentTypeCreatePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSIGNMENTTYPECREATEPERMISSIONPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assignmentTypeCreatePermission'}
          label={'permission'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'assignments'}
          inputHeaderBadgeOne={'types'}
          inputHeaderBadgeTwo={'create'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assignment || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSIGNMENT_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assignment || {}
              : associateInfo?.informationSetupPlus?.assignment || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSIGNMENT_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSIGNMENT_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'assignmentTypeCreatePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ITEMTYPECREATEPERMISSIONPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'itemTypeCreatePermission'}
          label={'permission'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'items'}
          inputHeaderBadgeOne={'types'}
          inputHeaderBadgeTwo={'create'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.item || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ITEM_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.item || {}
              : associateInfo?.informationSetupPlus?.item || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ITEM_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ITEM_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'itemTypeCreatePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'IGURUANALYTICSTYPECREATEPERMISSIONPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'iGuruAnalyticTypeCreatePermission'}
          label={'permission'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'iGuru analytics'}
          inputHeaderBadgeOne={'types'}
          inputHeaderBadgeTwo={'create'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.analytic || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ANALYTIC_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.analytic || {}
              : associateInfo?.informationSetupPlus?.analytic || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ANALYTIC_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ANALYTIC_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'iGuruAnalyticTypeCreatePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSOCIATETYPECREATEPERMISSIONPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'associateTypeCreatePermission'}
          label={'permission'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'associates'}
          inputHeaderBadgeOne={'types'}
          inputHeaderBadgeTwo={'create'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.associate || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'associateTypeCreatePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSOCIATENODECREATEPERMISSIONPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'associateNodeCreatePermission'}
          label={'permission'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'associates'}
          inputHeaderBadgeOne={'nodes'}
          inputHeaderBadgeTwo={'create'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.associate || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'associateNodeCreatePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSESSEECREATEFEEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'fee'}
          headerPanelColour={'genericOne'}
          inputHeader={headerOne? headerOne:'assessees'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'create'}
          type={'number'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={false}
          actualLableValue={'assesseeDistinctCreateFee'}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessee || {}
              : associateInfo?.informationSetupPlus?.assessee
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSEE_INFO
          }
          nextPopUpValue={'ASSOCIATEPICTUREPOPUP'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          currencySymbol={associateInfo?.associateCurrency?.associateCurrencySymbol || ''}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'assesseeDistinctCreateFeeRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSESSEE_SIGNON_FEE_POPUP' && (
        <PopUpTextField
          isActive={true}
          label={'fee'}
          headerPanelColour={'genericOne'}
          inputHeader={'assessees'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'sign-on'}
          type={'number'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={false}
          actualLableValue={'assesseeDistinctSignOnFee'}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessee || {}
              : associateInfo?.informationSetupPlus?.assessee
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSEE_INFO
          }
          nextPopUpValue={'ASSOCIATEPICTUREPOPUP'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'assesseeDistinctSignOnFeeRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSESSMENTCREATEFEEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'fee'}
          headerPanelColour={'genericOne'}
          inputHeader={headerOne? headerOne:'assessments'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'create'}
          type={'number'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={false}
          actualLableValue={'assessmentDistinctCreateFee'}
          // basicInfo={associateInfo?.informationSetup?.assessment || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessment || {}
              : associateInfo?.informationSetupPlus?.assessment || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSMENT_INFO
          }
          nextPopUpValue={'ASSOCIATEPICTUREPOPUP'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          currencySymbol={associateInfo?.associateCurrency?.associateCurrencySymbol || ''}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'assessmentDistinctCreateFeeRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSIGNMENTCREATEFEEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'fee'}
          headerPanelColour={'genericOne'}
          inputHeader={headerOne? headerOne:'assignments'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'create'}
          type={'number'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={false}
          actualLableValue={'assignmentDistinctCreateFee'}
          // basicInfo={associateInfo?.informationSetup?.assignment || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSIGNMENT_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assignment || {}
              : associateInfo?.informationSetupPlus?.assignment || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSIGNMENT_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSIGNMENT_INFO
          }
          nextPopUpValue={'ASSOCIATEPICTUREPOPUP'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          currencySymbol={associateInfo?.associateCurrency?.associateCurrencySymbol || ''}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'assignmentDistinctCreateFeeRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ITEMCREATEFEEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'fee'}
          headerPanelColour={'genericOne'}
          inputHeader={headerOne? headerOne:'items'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'create'}
          type={'number'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={false}
          actualLableValue={'itemDistinctCreateFee'}
          // basicInfo={associateInfo?.informationSetup?.item || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ITEM_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.item || {}
              : associateInfo?.informationSetupPlus?.item || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ITEM_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ITEM_INFO
          }
          nextPopUpValue={'ASSOCIATEPICTUREPOPUP'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          currencySymbol={associateInfo?.associateCurrency?.associateCurrencySymbol || ''}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'itemDistinctCreateFeeRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'IGURUANALYTICSCREATEFEEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'fee'}
          headerPanelColour={'genericOne'}
          inputHeader={'iGuru analytics'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'create'}
          type={'number'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={false}
          actualLableValue={'iGuruAnalyticDistinctCreateFee'}
          // basicInfo={associateInfo?.informationSetup?.analytic || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ANALYTIC_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.analytic || {}
              : associateInfo?.informationSetupPlus?.analytic || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ANALYTIC_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ANALYTIC_INFO
          }
          nextPopUpValue={'ASSOCIATEPICTUREPOPUP'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          currencySymbol={associateInfo?.associateCurrency?.associateCurrencySymbol || ''}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'iGuruAnalyticDistinctCreateFeeRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {/* extra condition 
      {isPopUpValue === 'IGURUANALYTICSCREATEFEEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'fee'}
          headerPanelColour={'genericOne'}
          inputHeader={'associates'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'create'}
          type={'number'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={false}
          actualLableValue={'associateDistinctCreateFee'}
          // basicInfo={associateInfo?.informationSetup?.associate || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          nextPopUpValue={'ASSOCIATEPICTUREPOPUP'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          currencySymbol={associateInfo?.associateCurrency?.associateCurrencySymbol || ''}
        />
      )} */}
      {isPopUpValue === 'ASSESSEEINFODISTINCTBASICPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assesseeDistinctInformationBasic'}
          label={'information'}
          labelBadgeOne={'basic'}
          listSelect={[
            { id: 'NOT_REQUIRED', name: 'Unique Name & Alias Unrequired' }
            // { id: 'NAME_DESCRIPTION_UNIQUE', name: 'Unique Name + Alias Required' },
            // { id: 'NAME_UNIQUE', name: 'Unique Name Required' }
          ]}
          mappingValue={'id'}
          inputHeader={'assessees'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'information'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assessee || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessee || {}
              : associateInfo?.informationSetupPlus?.assessee
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSEE_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'assesseeDistinctInformationBasicRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSESSMENTINFODISTINCTBASICPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentDistinctInformationBasic'}
          label={'information'}
          labelBadgeOne={'basic'}
          listSelect={[
            { id: 'NOT_REQUIRED', name: 'Unique Name & Description Unrequired' },
            { id: 'NAME_DESCRIPTION_UNIQUE', name: 'Unique Name + Description Required' },
            { id: 'NAME_UNIQUE', name: 'Unique Name Required' }
          ]}
          mappingValue={'id'}
          inputHeader={'assessments'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'information'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assessment || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessment || {}
              : associateInfo?.informationSetupPlus?.assessment || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSMENT_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'assessmentDistinctInformationBasicRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSIGNMENTINFODISTINCTBASICPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assignmentDistinctInformationBasic'}
          label={'information'}
          labelBadgeOne={'basic'}
          listSelect={[
            { id: 'NOT_REQUIRED', name: 'Unique Name & Description Unrequired' },
            { id: 'NAME_DESCRIPTION_UNIQUE', name: 'Unique Name + Description Required' },
            { id: 'NAME_UNIQUE', name: 'Unique Name Required' }
          ]}
          mappingValue={'id'}
          inputHeader={'assignments'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'information'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assignment || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSIGNMENT_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assignment || {}
              : associateInfo?.informationSetupPlus?.assignment || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSIGNMENT_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSIGNMENT_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'assignmentDistinctInformationBasicRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ITEMINFODISTINCTBASICPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'itemDistinctInformationBasic'}
          label={'information'}
          labelBadgeOne={'basic'}
          listSelect={[
            { id: 'NOT_REQUIRED', name: 'Unique Name & Description Unrequired' },
            { id: 'NAME_DESCRIPTION_UNIQUE', name: 'Unique Name + Description Required' },
            { id: 'NAME_UNIQUE', name: 'Unique Name Required' }
          ]}
          mappingValue={'id'}
          inputHeader={'items'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'information'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.item || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ITEM_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.item || {}
              : associateInfo?.informationSetupPlus?.item || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ITEM_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ITEM_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'itemDistinctInformationBasicRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'IGURUANALYTICSINFODISTINCTBASICPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'iGuruAnalyticDistinctInformationBasic'}
          label={'information'}
          labelBadgeOne={'basic'}
          listSelect={[
            { id: 'NOT_REQUIRED', name: 'Unique Name & Description Unrequired' },
            { id: 'NAME_DESCRIPTION_UNIQUE', name: 'Unique Name + Description Required' },
            { id: 'NAME_UNIQUE', name: 'Unique Name Required' }
          ]}
          mappingValue={'id'}
          inputHeader={'iGuru analytics'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'information'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.analytic || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ANALYTIC_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.analytic || {}
              : associateInfo?.informationSetupPlus?.analytic || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ANALYTIC_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ANALYTIC_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'iGuruAnalyticDistinctInformationBasicRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSOCIATEINFODISTINCTBASICPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'associateDistinctInformationBasic'}
          label={'information'}
          labelBadgeOne={'basic'}
          listSelect={[
            { id: 'NOT_REQUIRED', name: 'Unique Name & Description Unrequired' },
            { id: 'NAME_DESCRIPTION_UNIQUE', name: 'Unique Name + Description Required' },
            { id: 'NAME_UNIQUE', name: 'Unique Name Required' }
          ]}
          mappingValue={'id'}
          inputHeader={'associates'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'information'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.associate || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'associateDistinctInformationBasicRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSESSEEBASICINFOGROUPPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assesseeGroupInformationBasic'}
          label={'information'}
          labelBadgeOne={'basic'}
          listSelect={[
            { id: 'NOT_REQUIRED', name: 'Unique Name & Description Unrequired' },
            { id: 'NAME_DESCRIPTION_UNIQUE', name: 'Unique Name + Description Required' },
            { id: 'NAME_UNIQUE', name: 'Unique Name Required' }
          ]}
          mappingValue={'id'}
          inputHeader={'assessees'}
          inputHeaderBadgeOne={'groups'}
          inputHeaderBadgeTwo={'information'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assessee || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessee || {}
              : associateInfo?.informationSetupPlus?.assessee
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSEE_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'assesseeGroupInformationBasicRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSESSMENTBASICINFOGROUPPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentGroupInformationBasic'}
          label={'information'}
          labelBadgeOne={'basic'}
          listSelect={[
            { id: 'NOT_REQUIRED', name: 'Unique Name & Description Unrequired' },
            { id: 'NAME_DESCRIPTION_UNIQUE', name: 'Unique Name + Description Required' },
            { id: 'NAME_UNIQUE', name: 'Unique Name Required' }
          ]}
          mappingValue={'id'}
          inputHeader={'assessments'}
          inputHeaderBadgeOne={'groups'}
          inputHeaderBadgeTwo={'information'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assessment || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessment || {}
              : associateInfo?.informationSetupPlus?.assessment || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSMENT_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'assessmentGroupInformationBasicRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSIGNMENTBASICINFOGROUPPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assignmentGroupInformationBasic'}
          label={'information'}
          labelBadgeOne={'basic'}
          listSelect={[
            { id: 'NOT_REQUIRED', name: 'Unique Name & Description Unrequired' },
            { id: 'NAME_DESCRIPTION_UNIQUE', name: 'Unique Name + Description Required' },
            { id: 'NAME_UNIQUE', name: 'Unique Name Required' }
          ]}
          mappingValue={'id'}
          inputHeader={'assignments'}
          inputHeaderBadgeOne={'groups'}
          inputHeaderBadgeTwo={'information'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assignment || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSIGNMENT_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assignment || {}
              : associateInfo?.informationSetupPlus?.assignment || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSIGNMENT_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSIGNMENT_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'assignmentGroupInformationBasicRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ITEMBASICINFOGROUPPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'itemGroupInformationBasic'}
          label={'information'}
          labelBadgeOne={'basic'}
          listSelect={[
            { id: 'NOT_REQUIRED', name: 'Unique Name & Description Unrequired' },
            { id: 'NAME_DESCRIPTION_UNIQUE', name: 'Unique Name + Description Required' },
            { id: 'NAME_UNIQUE', name: 'Unique Name Required' }
          ]}
          mappingValue={'id'}
          inputHeader={'items'}
          inputHeaderBadgeOne={'groups'}
          inputHeaderBadgeTwo={'information'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assessee || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.item || {}
              : associateInfo?.informationSetupPlus?.item || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ITEM_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ITEM_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'itemGroupInformationBasicRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'IGURUANALYTICSBASICINFOGROUPPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'iGuruAnalyticGroupInformationBasic'}
          label={'information'}
          labelBadgeOne={'basic'}
          listSelect={[
            { id: 'NOT_REQUIRED', name: 'Unique Name & Description Unrequired' },
            { id: 'NAME_DESCRIPTION_UNIQUE', name: 'Unique Name + Description Required' },
            { id: 'NAME_UNIQUE', name: 'Unique Name Required' }
          ]}
          mappingValue={'id'}
          inputHeader={'iGuru analytics'}
          inputHeaderBadgeOne={'groups'}
          inputHeaderBadgeTwo={'information'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.analytic || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ANALYTIC_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.analytic || {}
              : associateInfo?.informationSetupPlus?.analytic || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ANALYTIC_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ANALYTIC_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'iGuruAnalyticGroupInformationBasicRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSOCIATEBASICINFOGROUPPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'associateGroupInformationBasic'}
          label={'information'}
          labelBadgeOne={'basic'}
          listSelect={[
            { id: 'NOT_REQUIRED', name: 'Unique Name & Description Unrequired' },
            { id: 'NAME_DESCRIPTION_UNIQUE', name: 'Unique Name + Description Required' },
            { id: 'NAME_UNIQUE', name: 'Unique Name Required' }
          ]}
          mappingValue={'id'}
          inputHeader={'associates'}
          inputHeaderBadgeOne={'groups'}
          inputHeaderBadgeTwo={'information'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.associate || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'associateGroupInformationBasicRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSESSEEBASICINFOROLEPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assesseeRoleInformationBasic'}
          label={'information'}
          labelBadgeOne={'basic'}
          listSelect={[
            { id: 'NOT_REQUIRED', name: 'Unique Name & Description Unrequired' },
            { id: 'NAME_DESCRIPTION_UNIQUE', name: 'Unique Name + Description Required' },
            { id: 'NAME_UNIQUE', name: 'Unique Name Required' }
          ]}
          mappingValue={'id'}
          inputHeader={'assessees'}
          inputHeaderBadgeOne={'roles'}
          inputHeaderBadgeTwo={'information'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assessee || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessee || {}
              : associateInfo?.informationSetupPlus?.assessee
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSEE_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'assesseeRoleInformationBasicRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSOCIATEBASICINFOROLEPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'associateRoleInformationBasic'}
          label={'information'}
          labelBadgeOne={'basic'}
          listSelect={[
            { id: 'NOT_REQUIRED', name: 'Unique Name & Description Unrequired' },
            { id: 'NAME_DESCRIPTION_UNIQUE', name: 'Unique Name + Description Required' },
            { id: 'NAME_UNIQUE', name: 'Unique Name Required' }
          ]}
          mappingValue={'id'}
          inputHeader={'associates'}
          inputHeaderBadgeOne={'roles'}
          inputHeaderBadgeTwo={'information'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.associate || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'associateRoleInformationBasicRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSESSEEBASICINFOTYPEPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assesseeTypeInformationBasic'}
          label={'information'}
          labelBadgeOne={'basic'}
          listSelect={[
            { id: 'NOT_REQUIRED', name: 'Unique Name & Description Unrequired' },
            { id: 'NAME_DESCRIPTION_UNIQUE', name: 'Unique Name + Description Required' },
            { id: 'NAME_UNIQUE', name: 'Unique Name Required' }
          ]}
          mappingValue={'id'}
          inputHeader={'assessees'}
          inputHeaderBadgeOne={'types'}
          inputHeaderBadgeTwo={'information'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assessee || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessee || {}
              : associateInfo?.informationSetupPlus?.assessee
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSEE_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'assesseeTypeInformationBasicRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSESSMENTBASICINFOTYPEPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentTypeInformationBasic'}
          label={'information'}
          labelBadgeOne={'basic'}
          listSelect={[
            { id: 'NOT_REQUIRED', name: 'Unique Name & Description Unrequired' },
            { id: 'NAME_DESCRIPTION_UNIQUE', name: 'Unique Name + Description Required' },
            { id: 'NAME_UNIQUE', name: 'Unique Name Required' }
          ]}
          mappingValue={'id'}
          inputHeader={'assessments'}
          inputHeaderBadgeOne={'types'}
          inputHeaderBadgeTwo={'information'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assessment || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessment || {}
              : associateInfo?.informationSetupPlus?.assessment || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSMENT_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'assessmentTypeInformationBasicRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSIGNMENTBASICINFOTYPEPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assignmentTypeInformationBasic'}
          label={'information'}
          labelBadgeOne={'basic'}
          listSelect={[
            { id: 'NOT_REQUIRED', name: 'Unique Name & Description Unrequired' },
            { id: 'NAME_DESCRIPTION_UNIQUE', name: 'Unique Name + Description Required' },
            { id: 'NAME_UNIQUE', name: 'Unique Name Required' }
          ]}
          mappingValue={'id'}
          inputHeader={'assignments'}
          inputHeaderBadgeOne={'types'}
          inputHeaderBadgeTwo={'information'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assignment || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSIGNMENT_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assignment || {}
              : associateInfo?.informationSetupPlus?.assignment || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSIGNMENT_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSIGNMENT_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'assignmentTypeInformationBasicRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ITEMBASICINFOTYPEPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'itemTypeInformationBasic'}
          label={'information'}
          labelBadgeOne={'basic'}
          listSelect={[
            { id: 'NOT_REQUIRED', name: 'Unique Name & Description Unrequired' },
            { id: 'NAME_DESCRIPTION_UNIQUE', name: 'Unique Name + Description Required' },
            { id: 'NAME_UNIQUE', name: 'Unique Name Required' }
          ]}
          mappingValue={'id'}
          inputHeader={'items'}
          inputHeaderBadgeOne={'types'}
          inputHeaderBadgeTwo={'information'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assessee || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.item || {}
              : associateInfo?.informationSetupPlus?.item || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ITEM_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ITEM_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'itemTypeInformationBasicRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'IGURUANALYTICSBASICINFOTYPEPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'iGuruAnalyticTypeInformationBasic'}
          label={'information'}
          labelBadgeOne={'basic'}
          listSelect={[
            { id: 'NOT_REQUIRED', name: 'Unique Name & Description Unrequired' },
            { id: 'NAME_DESCRIPTION_UNIQUE', name: 'Unique Name + Description Required' },
            { id: 'NAME_UNIQUE', name: 'Unique Name Required' }
          ]}
          mappingValue={'id'}
          inputHeader={'iGuru analytics'}
          inputHeaderBadgeOne={'types'}
          inputHeaderBadgeTwo={'information'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assessee || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.analytic || {}
              : associateInfo?.informationSetupPlus?.analytic || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ANALYTIC_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ANALYTIC_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'iGuruAnalyticTypeInformationBasicRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSOCIATEBASICINFOTYPEPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'associateTypeInformationBasic'}
          label={'information'}
          labelBadgeOne={'basic'}
          listSelect={[
            { id: 'NOT_REQUIRED', name: 'Unique Name & Description Unrequired' },
            { id: 'NAME_DESCRIPTION_UNIQUE', name: 'Unique Name + Description Required' },
            { id: 'NAME_UNIQUE', name: 'Unique Name Required' }
          ]}
          mappingValue={'id'}
          inputHeader={'associates'}
          inputHeaderBadgeOne={'types'}
          inputHeaderBadgeTwo={'information'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.associate || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'associateTypeInformationBasicRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSOCIATEBASICINFONODEPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'associateNodeInformationBasic'}
          label={'information'}
          labelBadgeOne={'basic'}
          listSelect={[
            { id: 'NOT_REQUIRED', name: 'Unique Name & Description Unrequired' },
            { id: 'NAME_DESCRIPTION_UNIQUE', name: 'Unique Name + Description Required' },
            { id: 'NAME_UNIQUE', name: 'Unique Name Required' }
          ]}
          mappingValue={'id'}
          inputHeader={'associates'}
          inputHeaderBadgeOne={'nodes'}
          inputHeaderBadgeTwo={'information'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.associate || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'associateNodeInformationBasicRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'WEBSITEADDRESSPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'website address'}
          labelBadgeOne={'primary'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={false}
          actualLableValue={'associateAddressWebsite'}
          basicInfo={associateInfo.informationContact.associateAddressWebsitePrimary}
          typeOfSetObject={UPDATE_ASSOCIATE_WEBSITE_PRIMARY_INFO}
          nextPopUpValue={''}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'WEBSITEADDRESSSECONDARYPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'website address'}
          labelBadgeOne={'secondary'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={false}
          actualLableValue={'associateAddressWebsite'}
          basicInfo={associateInfo.informationContact.associateAddressWebsiteSecondary}
          typeOfSetObject={UPDATE_ASSOCIATE_WEBSITE_SECONDARY_INFO}
          nextPopUpValue={''}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TAGSTATUTORYPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tag'}
          labelBadgeOne={'statutory'}
          actualLableValue={'associateTagStatutory'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          basicInfo={assesseeInfo.informationBasic}
          nextPopUpValue={''}
          typeOfSetObject={UPDATE_ASSOCIATE_BASIC_INFO}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={true}
        />
      )}

      {isPopUpValue === 'ASSOCIATECONFIRMATIONPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'create'}
          onClickYes={onClickYes}
          onClickNoFun={onClickNo}
        />
      )}
      {isPopUpValue === 'NAMEPOPUP' && (
        <PopUpAssesseeName
          isActive={true}
          inputHeader={'name'}
          headerPanelColour={'genericOne'}
          headerOne={'administrator'}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'ALIASPOPUP'}
          basicInfo={assesseeInfo.informationBasic}
          typeOfSetObject={UPDATE_ASSESSEE_BASIC_INFO}
        />
      )}
      {isPopUpValue === 'ALIASPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'alias'}
          actualLableValue={'assesseeAlias'}
          headerPanelColour={'genericOne'}
          headerOne={'administrator'}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'PICTUREPOPUP'}
          basicInfo={assesseeInfo.informationBasic}
          typeOfSetObject={UPDATE_ASSESSEE_BASIC_INFO}
        />
      )}
      {isPopUpValue === 'PICTUREPOPUP' && (
        <PopUpPicture
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'administrator'}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'EMAILPOPUP'}
          actualLableValue={'assesseePicture'}
          basicInfo={assesseeInfo.informationBasic}
          typeOfSetObject={UPDATE_ASSESSEE_BASIC_INFO}
          handleNextPopupValue={handleNextPopupValue}
        />
      )}
      {isPopUpValue === 'EMAILPOPUP' && (
        <PopUpAddressEmail
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'administrator'}
          headerOneBadgeOne={'information'}
          primaryLabel={'email address'}
          // nextPopUpValue={'MOBILETELEPHONEPOPUP'}
          tag={'assesseeAddressEmail'}
          basicInfo={assesseeInfo.informationContact.assesseeAddressEmailPrimary}
          typeOfSetObject={UPDATE_ASSESSEE_ADDRESS_EMAIL_PRIMARY_INFO}
          tempCommunication={assesseeInfo.tempCommunication}
          handleNextPopupValue={handleNextPopupValue}
        />
      )}
      {isPopUpValue === 'MOBILETELEPHONEPOPUP' && (
        <PopUpTelephone
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'administrator'}
          headerOneBadgeOne={'information'}
          inputHeader={'mobile telephone'}
          primaryheader={'primary'}
          nextPopUpValue={'SINGLEDROPDOWNPOPUP'}
          tempTelephoneCommunication={assesseeInfo.tempTelephoneCommunication}
          basicInfo={assesseeInfo.informationContact.assesseeTelephoneMobilePrimary}
          isMobileState={true}
          signInSetup={assesseeInfo.informationSetup}
          typeOfSetObject={UPDATE_ASSESSEE_MOBILE_INFO}
          // handleNextPopupValue={handleNextPopupValue}
        />
      )}
      {isPopUpValue === 'SINGLEDROPDOWNPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assesseeGender'}
          label={'gender'}
          listSelect={[
            { id: '', name: '' },
            { id: 'Female', name: 'Female' },
            { id: 'Male', name: 'Male' },
            { id: 'Unlisted', name: 'Unlisted' }
          ]}
          mappingValue={'id'}
          labelval={'gender'}
          headerPanelColour={'genericOne'}
          headerOne={'administrator'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={'CONFIRMATIONPOPUP'}
          basicInfo={assesseeInfo.informationPersonal}
          typeOfSetObject={UPDATE_ASSESSEE_PERSONAL_INFO}
        />
      )}
      {isPopUpValue === 'CONFIRMATIONPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'administrator'}
          headerOneBadgeOne={'create'}
          onClickYes={CreateApi}
          // onClickCancelYes={onClickCancelYes}
          onClickNoFun={onClickNo}
        />
      )}
      {isPopUpValue === 'CANCELPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'cancel'}
          headerOneBadgeOne={''}
          mode={'cancel'}
          onClickYes={onClickCancelYes}
        />
      )}
      {isPopUpValue === 'TAGREADONLYPRIMARYPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tag'}
          labelBadgeOne={'primary'}
          actualLableValue={'associateTagPrimary'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          basicInfo={responseObject?.informationEngagement?.associateTag?.associateTagPrimary || ''}
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={''}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TAGSECONDARYPOPUP' && (
        <PopUpTagSecondary
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          tagSecondary={assesseeInfo.informationEngagement}
          signInSetup={assesseeInfo.informationSetup}
          nextPopUpValue={'CONFIRMATIONPOPUP'}
          typeOfSetObject={UPDATE_ASSESSEE_ENGAGEMENT_INFO}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'STATUSPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'associateStatus'}
          label={'status'}
          listSelect={[
            { id: 'Active', name: 'Active' },
            { id: 'Suspended', name: 'Suspended' },
            { id: 'Terminated', name: 'Terminated' },
            { id: 'Unverified', name: 'Unverified' },
            { id: 'Confirmed', name: 'Confirmed' }
          ]}
          mappingValue={'id'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          basicInfo={statusPopUpValue}
          nextPopUpValue={''}
          typeOfSetObject={UPDATE_ASSESSEE_PERSONAL_INFO}
          handleNextPopupValue={handleNextPopupValue}
          isNotRevised={true}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TENURESATRTDATEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tenure'}
          labelBadgeOne={'start'}
          actualLableValue={''}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          basicInfo={
            responseObject?.informationEngagement?.associateTenureDate
              ?.associateTenureDateTimeStart || 'mm/dd/yyyy --:-- --'
          }
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={''}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TENUREENDDATEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tenure'}
          labelBadgeOne={'end'}
          actualLableValue={''}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          basicInfo={
            responseObject?.informationEngagement?.associateTenureDate
              ?.associateTenureDateTimeEnd || 'mm/dd/yyyy --:-- --'
          }
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={''}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ASSESSEE_DIST_SHARE_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assesseeDistinctSharePermission'}
          label={'share'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'assessees'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'share'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assessee || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessee || {}
              : associateInfo?.informationSetupPlus?.assessee
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSEE_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'assesseeDistinctSharePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSOCIATE_DIST_SHARE_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'associateDistinctSharePermission'}
          label={'share'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'associates'}
          inputHeaderBadgeOne={'distinct'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.associate || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'associateDistinctSharePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSESSEE_SIGNON_APPROVAL_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assesseeDistinctSignOnApproval'}
          label={'approval'}
          listSelect={[
            { id: true, name: 'Required' },
            { id: false, name: 'Unrequired' }
          ]}
          mappingValue={'id'}
          inputHeader={'assessees'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'sign-on'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assessee || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessee || {}
              : associateInfo?.informationSetupPlus?.assessee
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSEE_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'assesseeDistinctSignOnApprovalRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSOCIATE_SIGNON_APPROVAL_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'associateDistinctSignOnApproval'}
          label={'approval'}
          listSelect={[
            { id: true, name: 'Required' },
            { id: false, name: 'Unrequired' }
          ]}
          mappingValue={'id'}
          inputHeader={'associates'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'sign-on'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.associate || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'associateDistinctSignOnApprovalRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSOCIATE_SIGNON_FEE_POPUP' && (
        <PopUpTextField
          isActive={true}
          label={'fee'}
          headerPanelColour={'genericOne'}
          inputHeader={'associates'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'sign-on'}
          type={'number'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={false}
          actualLableValue={'associateDistinctSignOnFee'}
          // basicInfo={associateInfo?.informationSetup?.associate || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          nextPopUpValue={'ASSOCIATEPICTUREPOPUP'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          currencySymbol={associateInfo?.associateCurrency?.associateCurrencySymbol || ''}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'associateDistinctSignOnFeeRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSOCIATE_SIGNON_PERMISSION_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'associateDistinctSignOnPermission'}
          label={'permission'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'associates'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'sign-on'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.associate || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'associateDistinctSignOnPermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSESSEE_SIGNON_PERMISSION_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assesseeDistinctSignOnPermission'}
          label={'permission'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'assessees'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'sign-on'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assessee || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessee || {}
              : associateInfo?.informationSetupPlus?.assessee
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSEE_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'assesseeDistinctSignOnPermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSESSEE_GROUP_SHARE_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assesseeGroupSharePermission'}
          label={'share'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'assessees'}
          inputHeaderBadgeOne={'groups'}
          inputHeaderBadgeTwo={''}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assessee || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessee || {}
              : associateInfo?.informationSetupPlus?.assessee
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSEE_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'assesseeGroupSharePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSOCIATE_GROUP_SHARE_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'associateGroupSharePermission'}
          label={'share'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'associates'}
          inputHeaderBadgeOne={'groups'}
          inputHeaderBadgeTwo={''}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.associate || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'associateGroupSharePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSOCIATE_NODE_SHARE_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'associateNodeSharePermission'}
          label={'share'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'associates'}
          inputHeaderBadgeOne={'nodes'}
          inputHeaderBadgeTwo={''}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.associate || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'associateNodeSharePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSOCIATE_ROLE_SHARE_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'associateRoleSharePermission'}
          label={'share'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'associates'}
          inputHeaderBadgeOne={'roles'}
          inputHeaderBadgeTwo={''}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.associate || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'associateRoleSharePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSOCIATE_TYPE_SHARE_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'associateTypeSharePermission'}
          label={'share'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'associates'}
          inputHeaderBadgeOne={'types'}
          inputHeaderBadgeTwo={''}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.associate || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'associateTypeSharePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSESSEE_TYPE_SHARE_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assesseeTypeSharePermission'}
          label={'share'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'assessees'}
          inputHeaderBadgeOne={'types'}
          inputHeaderBadgeTwo={''}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assessee || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessee || {}
              : associateInfo?.informationSetupPlus?.assessee
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSEE_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'assesseeTypeSharePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSESSEE_ROLE_SHARE_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assesseeRoleSharePermission'}
          label={'share'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'assessees'}
          inputHeaderBadgeOne={'roles'}
          inputHeaderBadgeTwo={''}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assessee || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessee || {}
              : associateInfo?.informationSetupPlus?.assessee
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSEE_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'assesseeRoleSharePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSESSMENT_NODE_SHARE_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentDistinctSharedNodePermission'}
          label={'node'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'assessments'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'share'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assessment || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessment || {}
              : associateInfo?.informationSetupPlus?.assessment || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSMENT_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'assessmentDistinctSharedNodePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSESSMENT_ASSOCIATE_SHARE_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentDistinctSharedAssociatePermission'}
          label={'associate'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'assessments'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'share'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assessment || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessment || {}
              : associateInfo?.informationSetupPlus?.assessment || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSMENT_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'assessmentDistinctSharedAssociatePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSESSMENT_GROUP_SHARE_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentGroupSharePermission'}
          label={'share'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'assessments'}
          inputHeaderBadgeOne={'groups'}
          inputHeaderBadgeTwo={''}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assessment || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessment || {}
              : associateInfo?.informationSetupPlus?.assessment || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSMENT_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'assessmentGroupSharePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSESSMENT_TYPE_SHARE_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentTypeSharePermission'}
          label={'share'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'assessments'}
          inputHeaderBadgeOne={'types'}
          inputHeaderBadgeTwo={''}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assessment || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessment || {}
              : associateInfo?.informationSetupPlus?.assessment || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSMENT_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'assessmentTypeSharePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {/* extra fields
      {isPopUpValue === 'ASSOCIATE_BRAND_APPROVAL_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'iGuruPlatformBrandApproval'}
          label={'approval'}
          listSelect={[
            { id: true, name: 'Required' },
            { id: false, name: 'Unrequired' }
          ]}
          mappingValue={'id'}
          inputHeader={'associates'}
          inputHeaderBadgeOne={'brand'}
          inputHeaderBadgeTwo={''}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.associate || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ASSOCIATE_BRAND_PERMISSION_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'iGuruPlatformBrandPermission'}
          label={'permission'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'associates'}
          inputHeaderBadgeOne={'brand'}
          inputHeaderBadgeTwo={''}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.associate || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )} 
      {isPopUpValue === 'ASSOCIATE_BRAND_CHOICE_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'iGuruPlatformBrandChoice'}
          label={'choice'}
          listSelect={[
            { id: 'Associate', name: 'Associate' },
            { id: 'Associate & iGuru', name: 'Associate & iGuru' },
            { id: 'iGuru', name: 'iGuru' }
          ]}
          mappingValue={'id'}
          inputHeader={'associates'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'sign-on'}
          type={'number'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={false}
          actualLableValue={'associateDistinctSignOnFee'}
          // basicInfo={associateInfo?.informationSetup?.associate || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          currencySymbol={associateInfo?.associateCurrency?.associateCurrencySymbol || ''}
        />
      )}
      {isPopUpValue === 'ASSOCIATE_SIGNON_PERMISSION_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'associateDistinctSignOnPermission'}
          label={'permission'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'associates'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'sign-on'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.associate || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ASSESSEE_SIGNON_PERMISSION_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assesseeDistinctSignOnPermission'}
          label={'permission'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'assessees'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'sign-on'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assessee || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessee || {}
              : associateInfo?.informationSetupPlus?.assessee
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSEE_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ASSESSEE_GROUP_SHARE_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assesseeGroupSharePermission'}
          label={'share'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'assessees'}
          inputHeaderBadgeOne={'group'}
          inputHeaderBadgeTwo={''}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assessee || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessee || {}
              : associateInfo?.informationSetupPlus?.assessee
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSEE_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ASSOCIATE_GROUP_SHARE_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'associateGroupSharePermission'}
          label={'share'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'associates'}
          inputHeaderBadgeOne={'group'}
          inputHeaderBadgeTwo={''}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.associate || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )} */}
      {isPopUpValue === 'ASSOCIATE_NODE_SHARE_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'associateNodeSharePermission'}
          label={'share'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'associates'}
          inputHeaderBadgeOne={'nodes'}
          inputHeaderBadgeTwo={''}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.associate || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'associateNodeSharePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {/* extra condtion
      {isPopUpValue === 'ASSOCIATE_ROLE_SHARE_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'associateRoleSharePermission'}
          label={'share'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'associates'}
          inputHeaderBadgeOne={'role'}
          inputHeaderBadgeTwo={''}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ASSOCIATE_TYPE_SHARE_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'associateTypeSharePermission'}
          label={'share'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'associates'}
          inputHeaderBadgeOne={'type'}
          inputHeaderBadgeTwo={''}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.associate || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ASSESSEE_TYPE_SHARE_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assesseeTypeSharePermission'}
          label={'share'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'assessees'}
          inputHeaderBadgeOne={'type'}
          inputHeaderBadgeTwo={''}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assessee || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessee || {}
              : associateInfo?.informationSetupPlus?.assessee
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSEE_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )} 
      {isPopUpValue === 'ASSESSEE_ROLE_SHARE_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assesseeRoleSharePermission'}
          label={'share'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'assessees'}
          inputHeaderBadgeOne={'role'}
          inputHeaderBadgeTwo={''}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assessee || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessee || {}
              : associateInfo?.informationSetupPlus?.assessee
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSEE_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      */}
      {isPopUpValue === 'ASSESSMENT_NODE_SHARE_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentDistinctSharedNode'}
          label={'node'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'assessments'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'share'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assessment || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessment || {}
              : associateInfo?.informationSetupPlus?.assessment || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSMENT_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ASSESSMENT_ASSOCIATE_SHARE_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentDistinctSharedAssociate'}
          label={'associate'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'assessments'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'share'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assessment || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessment || {}
              : associateInfo?.informationSetupPlus?.assessment || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSMENT_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ASSESSMENT_GROUP_SHARE_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentGroupSharePermission'}
          label={'share'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'assessments'}
          inputHeaderBadgeOne={'groups'}
          inputHeaderBadgeTwo={''}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assessment || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessment || {}
              : associateInfo?.informationSetupPlus?.assessment || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSMENT_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {/* extra 
       {isPopUpValue === 'ASSESSMENT_TYPE_SHARE_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentTypeSharePermission'}
          label={'share'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'assessments'}
          inputHeaderBadgeOne={'types'}
          inputHeaderBadgeTwo={''}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.assessment || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessment || {}
              : associateInfo?.informationSetupPlus?.assessment || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSMENT_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )} */}
      {isPopUpValue === 'ASSOCIATE_BRAND_APPROVAL_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'iGuruPlatformBrandApproval'}
          label={'approval'}
          listSelect={[
            { id: true, name: 'Required' },
            { id: false, name: 'Unrequired' }
          ]}
          mappingValue={'id'}
          inputHeader={'associates'}
          inputHeaderBadgeOne={'brand'}
          inputHeaderBadgeTwo={''}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.associate || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'iGuruPlatformBrandApprovalRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSOCIATE_BRAND_PERMISSION_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'iGuruPlatformBrandPermission'}
          label={'permission'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'associates'}
          inputHeaderBadgeOne={'brand'}
          inputHeaderBadgeTwo={''}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.associate || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ASSOCIATE_BRAND_CHOICE_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'iGuruPlatformBrandChoice'}
          label={'choice'}
          listSelect={[
            { id: 'Associate', name: 'Associate' },
            { id: 'Associate & iGuru', name: 'Associate & iGuru' },
            { id: 'iGuru', name: 'iGuru' }
          ]}
          mappingValue={'id'}
          inputHeader={'associates'}
          inputHeaderBadgeOne={'brand'}
          inputHeaderBadgeTwo={''}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.associate || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'iGuruPlatformBrandChoiceRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSOCIATE_BRAND_PICTURE_POPUP' && (
        <PopUpPicture
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          inputHeader={'associates'}
          inputHeaderBadgeOne={'brand'}
          //basicInfo={associateInfo?.informationSetup?.associate || {}}
          actualLableValue={'iGuruPlatformBrandPicture'}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          nextPopUpValue={''}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'iGuruPlatformBrandPictureRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSOCIATE_BRAND_FEE_POPUP' && (
        <PopUpTextField
          isActive={true}
          label={'fee'}
          headerPanelColour={'genericOne'}
          inputHeader={'associates'}
          inputHeaderBadgeOne={'brand'}
          inputHeaderBadgeTwo={''}
          type={'number'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={false}
          actualLableValue={'iGuruPlatformBrandFee'}
          // basicInfo={associateInfo?.informationSetup?.associate || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          nextPopUpValue={'ASSOCIATEPICTUREPOPUP'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'iGuruPlatformBrandFeeRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSOCIATE_DOMAIN_PRIMARY_POPUP' && (
        <PopUpTextField
          isActive={true}
          label={'primary'}
          headerPanelColour={'genericOne'}
          inputHeader={'associates'}
          inputHeaderBadgeOne={'domain'}
          inputHeaderBadgeTwo={''}
          type={'text'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={false}
          actualLableValue={'associateDomainPrimary'}
          basicInfo={associateInfo?.informationSetup?.associate || {}}
          typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          nextPopUpValue={'ASSOCIATEPICTUREPOPUP'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ASSOCIATE_DOMAIN_SECONDARY_POPUP' && (
        <PopUpTextField
          isActive={true}
          label={'secondary'}
          headerPanelColour={'genericOne'}
          inputHeader={'associates'}
          inputHeaderBadgeOne={'domain'}
          inputHeaderBadgeTwo={''}
          type={'text'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={false}
          actualLableValue={'associateDomainSecondary'}
          basicInfo={associateInfo?.informationSetup?.associate || {}}
          typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          nextPopUpValue={'ASSOCIATEPICTUREPOPUP'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ASSOCIATE_THEME_APPROVAL_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'iGuruPlatformThemeApproval'}
          label={'approval'}
          listSelect={[
            { id: true, name: 'Required' },
            { id: false, name: 'Unrequired' }
          ]}
          mappingValue={'id'}
          inputHeader={'associates'}
          inputHeaderBadgeOne={'theme'}
          inputHeaderBadgeTwo={''}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          // basicInfo={associateInfo?.informationSetup?.associate || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'iGuruPlatformThemeApprovalRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSOCIATE_THEME_PERMISSION_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'iGuruPlatformThemePermission'}
          label={'permission'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'associates'}
          inputHeaderBadgeOne={'theme'}
          inputHeaderBadgeTwo={''}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'iGuruPlatformThemePermissionRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}

      {isPopUpValue === 'ASSOCIATE_THEME_FEE_POPUP' && (
        <PopUpTextField
          isActive={true}
          label={'fee'}
          headerPanelColour={'genericOne'}
          inputHeader={'associates'}
          inputHeaderBadgeOne={'theme'}
          inputHeaderBadgeTwo={''}
          type={'number'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={false}
          actualLableValue={'iGuruPlatformThemeFee'}
          // basicInfo={associateInfo?.informationSetup?.associate || {}}
          // typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          nextPopUpValue={'ASSOCIATEPICTUREPOPUP'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'iGuruPlatformThemeFeeRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSOCIATE_NODE_PERMISSION_POPUP' && (
        // <PopUpCheckbox
        <PopUpCheckboxRolePermission
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          headerOneBadgeTwo={''}
          inputHeader={'nodes'}
          inputHeaderBadge={'permission'}
          inputHeaderBadgeTwo={permissionStateOne}
          inputHeaderBadgeThree={permissionStateTwo}
          typeOfStateObj={permissionStateThree}
          isRolePermission
          objectName={'associateNode'}
          stateName={'informationSetup'}
          informationValue={'associateNodePermision'}
          valueArr={[
            { name: 'create', disabled: false, checked: false },
            { name: 'delete', disabled: false, checked: false },
            { name: 'review', disabled: false, checked: false },
            { name: 'revise', disabled: false, checked: false },
            { name: 'share', disabled: false, checked: false }
          ]}
          valueArrState={JSON.parse(
            JSON.stringify(
              associateInfo?.informationSetup?.associateNode?.informationSetup
                ? associateInfo?.informationSetup?.associateNode?.informationSetup
                : []
            )
          )}
          infoMsg={'set permission'}
          nextPopUpValue={''}
          typeOfSetObject={UPDATE_ASSOCIATE_ASSOCIATENODE_INFO}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ASSESSEERESETPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'assessees'}
          headerOneBadgeOne={'information'}
          headerOneBadgeTwo={'reset'}
          mode={'cancel'}
          onClickYes={() => {
            onClickSetupReset('assessees');
          }}
        />
      )}

      {isPopUpValue === 'ASSESSMENTRESETPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'assessments'}
          headerOneBadgeOne={'information'}
          headerOneBadgeTwo={'reset'}
          mode={'cancel'}
          onClickYes={() => {
            onClickSetupReset('assessments');
          }}
        />
      )}

      {isPopUpValue === 'ASSIGNMENTRESETPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'assignments'}
          headerOneBadgeOne={'information'}
          headerOneBadgeTwo={'reset'}
          mode={'cancel'}
          onClickYes={() => {
            onClickSetupReset('assignments');
          }}
        />
      )}
      {isPopUpValue === 'ITEMRESETPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'items'}
          headerOneBadgeOne={'information'}
          headerOneBadgeTwo={'reset'}
          mode={'cancel'}
          onClickYes={() => {
            onClickSetupReset('items');
          }}
        />
      )}

      {isPopUpValue === 'ASSOCIATERESETPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'associates'}
          headerOneBadgeOne={'information'}
          headerOneBadgeTwo={'reset'}
          mode={'cancel'}
          onClickYes={() => {
            onClickSetupReset('associates');
          }}
        />
      )}

      {isPopUpValue === 'IGURUANALYTICSRESETPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'iGuru analytics'}
          headerOneBadgeOne={'information'}
          headerOneBadgeTwo={'reset'}
          mode={'cancel'}
          onClickYes={() => {
            onClickSetupReset('analytics');
          }}
        />
      )}

      {isPopUpValue === 'IGURUMARKETPLACERESETPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'iGuru marketplace'}
          headerOneBadgeOne={'information'}
          headerOneBadgeTwo={'reset'}
          mode={'cancel'}
          onClickYes={null}
        />
      )}
      {isPopUpValue === 'IGURUMINERESETPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'iGuru mine'}
          headerOneBadgeOne={'information'}
          headerOneBadgeTwo={'reset'}
          mode={'cancel'}
          onClickYes={null}
        />
      )}

      {isPopUpValue === 'ASSOCIATE_COUNTRY_PICTURE_POPUP' && (
        <PopUpPicture
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          inputHeader={'associates'}
          inputHeaderBadgeOne={'country'}
          basicInfo={{
            ...associateInfo?.associateCountry,
            associateCountryFlag:
              './Image/country_flags/' +
              associateInfo?.associateCountry.associateCountryFlag.toLowerCase() +
              '.png'
          }}
          actualLableValue={'associateCountryFlag'}
          typeOfSetObject={UPDATE_ASSOCIATE_COUNTRY_INFO}
          nextPopUpValue={''}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={true}
        />
      )}
      {isPopUpValue === 'ASSOCIATE_COUNTRY_NAME_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'associateCountryTag'}
          label={'country'}
          // listSelect={associateCountryName}
          listSelect={allCountryList}
          mappingValue={'_id'}
          labelval={'country'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          setDependantObj={'associateCountry'}
          basicInfo={associateInfo?.associateCountry}
          typeOfSetObject={UPDATE_ASSOCIATE_COUNTRY_INFO}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'associateCountryRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSOCIATE_SETUPPLUS_COUNTRY_NAME_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'associateCountryTag'}
          label={'country'}
          listSelect={allCountryList}
          mappingValue={'_id'}
          labelval={'country'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'setup+'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={associateInfo.associateCountry || {}}
          typeOfSetObject={UPDATE_ASSOCIATE_COUNTRY_INFO}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}

      {isPopUpValue === 'ASSOCIATE_CURRENCY_NAME_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'associateCurrencyName'}
          label={'currency'}
          // listSelect={associateCurrencyMaster}
          listSelect={[
            {
              id: associateInfo?.associateCurrency?.associateCurrencyName,
              name: associateInfo?.associateCurrency?.associateCurrencyName
            }
          ]}
          mappingValue={'id'}
          labelval={'currency'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          basicInfo={associateInfo?.associateCurrency?.associateCurrencyName || {}}
          typeOfSetObject={UPDATE_ASSOCIATE_CURRENCY_INFO}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={true} // need to check
          isRevisePermissionCheckbox={true}
          revisePermissionField={'associateCurrencyRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
        />
      )}
      {isPopUpValue === 'ASSOCIATE_SETUPPLUS_CURRENCY_NAME_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'associateCurrencyTag'}
          label={'currency'}
          listSelect={associateCurrencyMaster}
          mappingValue={'id'}
          labelval={'currency'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'setup+'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={associateInfo.associateCurrency || {}}
          typeOfSetObject={UPDATE_ASSOCIATE_CURRENCY_INFO}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}

      {isPopUpValue === 'LANGUAGE_SETUP_PLUS_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'associateLanguageTag'}
          label={'language'}
          listSelect={associateLanguages}
          mappingValue={'id'}
          labelval={'language'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={associateInfo?.associateLanguage || {}}
          typeOfSetObject={UPDATE_ASSOCIATE_LANGUAGE_INFO}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ASSOCIATE_CURRENCY_PICTURE_POPUP' && (
        <PopUpTextField
          isActive={true}
          label={'currency'}
          headerPanelColour={'genericOne'}
          inputHeader={''}
          inputHeaderBadgeOne={''}
          inputHeaderBadgeTwo={''}
          type={'text'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={false}
          actualLableValue={'associateCurrencySymbol'}
          basicInfo={associateInfo?.associateCurrency?.associateCurrencySymbol || {}}
          typeOfSetObject={UPDATE_ASSOCIATE_CURRENCY_INFO}
          nextPopUpValue={''}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={true}
        />
      )}
      {isPopUpValue === 'ASSESSEESELLAPPROVALPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={
            selectedBadge === 'distinct'
              ? 'assesseeDistinctSellApproval'
              : 'assesseeGroupSellApproval'
          }
          label={'approval'}
          listSelect={[
            { id: true, name: 'Required' },
            { id: false, name: 'Unrequired' }
          ]}
          mappingValue={'id'}
          inputHeader={'assessees'}
          //inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeOne={
            selectedBadge === 'distinct' ? 'distinct' : 'groups' //need to check
          }
          inputHeaderBadgeTwo={'sell'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={
            selectedBadge === 'distinct'
              ? 'assesseeDistinctSellApprovalRevise'
              : 'assesseeGroupSellApprovalRevise'
          }
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessee || {}
              : associateInfo?.informationSetupPlus?.assessee || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSEE_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={
            selectedBadge === 'distinct'
              ? 'assesseeDistinctSellApprovalRevise'
              : 'assesseeGroupSellApprovalRevise'
          }
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSESSEESELLFEEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'fee'}
          headerPanelColour={'genericOne'}
          inputHeader={headerOne? headerOne:'assessees'}
          inputHeaderBadgeOne={
            selectedBadge === 'distinct' ? 'distinct' : 'groups' //need to check
          }
          inputHeaderBadgeTwo={'sell'}
          type={'number'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={false}
          actualLableValue={
            selectedBadge === 'distinct' ? 'assesseeDistinctSellFee' : 'assesseeGroupSellFee'
          }
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessee || {}
              : associateInfo?.informationSetupPlus?.assessee
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSEE_INFO
          }
          nextPopUpValue={'ASSOCIATEPICTUREPOPUP'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          currencySymbol={associateInfo?.associateCurrency?.associateCurrencySymbol || ''}
          isRevisePermissionCheckbox={true}
          revisePermissionField={
            selectedBadge === 'distinct'
              ? 'assesseeDistinctSellFeeRevise'
              : 'assesseeGroupSellFeeRevise'
          }
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSESSEESELLPERMISSIONPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={
            selectedBadge === 'distinct'
              ? 'assesseeDistinctSellPermission'
              : 'assesseeGroupSellPermission'
          }
          label={'permission'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'assessees'}
          inputHeaderBadgeOne={
            selectedBadge === 'distinct' ? 'distinct' : 'groups' // need to check
          }
          inputHeaderBadgeTwo={'sell'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessee || {}
              : associateInfo?.informationSetupPlus?.assessee
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSEE_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={
            selectedBadge === 'distinct'
              ? 'assesseeDistinctSellPermissionRevise'
              : 'assesseeGroupSellPermissionRevise'
          }
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSESSEESHAREAPPROVALPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={
            selectedBadge === 'distinct'
              ? 'assesseeDistinctShareApproval'
              : 'assesseeGroupShareApproval'
          }
          label={'approval'}
          listSelect={[
            { id: true, name: 'Required' },
            { id: false, name: 'Unrequired' }
          ]}
          mappingValue={'id'}
          inputHeader={'assessees'}
          //inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeOne={selectedBadge === 'distinct' ? 'distinct' : 'groups'}
          inputHeaderBadgeTwo={'share'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessee || {}
              : associateInfo?.informationSetupPlus?.assessee || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSEE_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={
            selectedBadge === 'distinct'
              ? 'assesseeDistinctShareApprovalRevise'
              : 'assesseeGroupShareApprovalRevise'
          }
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSESSEESHAREFEEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'fee'}
          headerPanelColour={'genericOne'}
          inputHeader={headerOne? headerOne:'assessees'}
          //inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeOne={selectedBadge === 'distinct' ? 'distinct' : 'groups'}
          inputHeaderBadgeTwo={'share'}
          type={'number'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={false}
          actualLableValue={
            selectedBadge === 'distinct' ? 'assesseeDistinctShareFee' : 'assesseeGroupShareFee'
          }
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessee || {}
              : associateInfo?.informationSetupPlus?.assessee
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSEE_INFO
          }
          nextPopUpValue={'ASSOCIATEPICTUREPOPUP'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          currencySymbol={associateInfo?.associateCurrency?.associateCurrencySymbol || ''}
          isRevisePermissionCheckbox={true}
          revisePermissionField={
            selectedBadge === 'distinct'
              ? 'assesseeDistinctShareFeeRevise'
              : 'assesseeGroupShareFeeRevise' //
          }
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSESSEESHAREPERMISSIONPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={
            selectedBadge === 'distinct'
              ? 'assesseeDistinctSharePermission'
              : 'assesseeGroupSharePermission'
          }
          label={'permission'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'assessees'}
          inputHeaderBadgeOne={selectedBadge === 'distinct' ? 'distinct' : 'groups'}
          inputHeaderBadgeTwo={'share'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessee || {}
              : associateInfo?.informationSetupPlus?.assessee
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSEE_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSEE_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={
            selectedBadge === 'distinct'
              ? 'assesseeDistinctSharePermissionRevise'
              : 'assesseeGroupSharePermissionRevise'
          }
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSESSMENTSELLAPPROVALPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={
            selectedBadge === 'distinct'
              ? 'assessmentDistinctSellApproval'
              : 'assessmentGroupSellApproval'
          }
          label={'approval'}
          listSelect={[
            { id: true, name: 'Required' },
            { id: false, name: 'Unrequired' }
          ]}
          mappingValue={'id'}
          inputHeader={'assessments'}
          //inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeOne={selectedBadge === 'distinct' ? 'distinct' : 'groups'}
          inputHeaderBadgeTwo={'sell'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessment || {}
              : associateInfo?.informationSetupPlus?.assessment || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSMENT_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={
            selectedBadge === 'distinct'
              ? 'assessmentDistinctSellApprovalRevise'
              : 'assessmentGroupSellApprovalRevise'
          }
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSESSMENTSELLFEEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'fee'}
          headerPanelColour={'genericOne'}
          inputHeader={headerOne? headerOne:'assessments'}
          //inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeOne={selectedBadge === 'distinct' ? 'distinct' : 'groups'}
          inputHeaderBadgeTwo={'sell'}
          type={'number'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={false}
          actualLableValue={
            selectedBadge === 'distinct' ? 'assessmentDistinctSellFee' : 'assessmentGroupSellFee'
          }
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessment || {}
              : associateInfo?.informationSetupPlus?.assessment || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSMENT_INFO
          }
          nextPopUpValue={'ASSOCIATEPICTUREPOPUP'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          currencySymbol={associateInfo?.associateCurrency?.associateCurrencySymbol || ''}
          isRevisePermissionCheckbox={true}
          revisePermissionField={
            selectedBadge === 'distinct'
              ? 'assessmentDistinctSellFeeRevise'
              : 'assessmentGroupSellFeeRevise'
          }
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSESSMENTSELLPERMISSIONPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={
            selectedBadge === 'distinct'
              ? 'assessmentDistinctSellPermission'
              : 'assessmentGroupSellPermission'
          }
          label={'permission'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'assessments'}
          //inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeOne={selectedBadge === 'distinct' ? 'distinct' : 'groups'}
          inputHeaderBadgeTwo={'sell'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessment || {}
              : associateInfo?.informationSetupPlus?.assessment || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSMENT_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={
            selectedBadge === 'distinct'
              ? 'assessmentDistinctSellPermissionRevise'
              : 'assessmentGroupSellPermissionRevise'
          }
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSESSMENTSHAREAPPROVALPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={
            selectedBadge === 'distinct'
              ? 'assessmentDistinctShareApproval'
              : 'assessmentGroupShareApproval'
          }
          label={'approval'}
          listSelect={[
            { id: true, name: 'Required' },
            { id: false, name: 'Unrequired' }
          ]}
          mappingValue={'id'}
          inputHeader={'assessments'}
          inputHeaderBadgeOne={selectedBadge === 'distinct' ? 'distinct' : 'groups'}
          inputHeaderBadgeTwo={'share'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessment || {}
              : associateInfo?.informationSetupPlus?.assessment || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSMENT_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={
            selectedBadge === 'distinct'
              ? 'assessmentDistinctShareApprovalRevise'
              : 'assessmentGroupShareApprovalRevise'
          }
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSESSMENTSHAREFEEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'fee'}
          headerPanelColour={'genericOne'}
          inputHeader={headerOne? headerOne:'assessments'}
          //inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeOne={selectedBadge === 'distinct' ? 'distinct' : 'groups'}
          inputHeaderBadgeTwo={'share'}
          type={'number'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={false}
          actualLableValue={
            selectedBadge === 'distinct' ? 'assessmentDistinctShareFee' : 'assessmentGroupShareFee'
          }
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessment || {}
              : associateInfo?.informationSetupPlus?.assessment || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSMENT_INFO
          }
          nextPopUpValue={'ASSOCIATEPICTUREPOPUP'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          currencySymbol={associateInfo?.associateCurrency?.associateCurrencySymbol || ''}
          isRevisePermissionCheckbox={true}
          revisePermissionField={
            selectedBadge === 'distinct'
              ? 'assessmentDistinctShareFeeRevise'
              : 'assessmentGroupShareFeeRevise'
          }
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSESSMENTSHAREPERMISSIONPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={
            selectedBadge === 'distinct'
              ? 'assessmentDistinctSharePermission'
              : 'assessmentGroupSharePermission'
          }
          label={'permission'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'assessments'}
          //inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeOne={selectedBadge === 'distinct' ? 'distinct' : 'groups'}
          inputHeaderBadgeTwo={'share'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assessment || {}
              : associateInfo?.informationSetupPlus?.assessment || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSESSMENT_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSESSMENT_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={
            selectedBadge === 'distinct'
              ? 'assessmentDistinctSharePermissionRevise'
              : 'assessmentGroupSharePermissionRevise'
          }
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSIGNMENTSELLAPPROVALPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={
            selectedBadge === 'distinct'
              ? 'assignmentDistinctSellApproval'
              : 'assignmentGroupSellApproval'
          }
          label={'approval'}
          listSelect={[
            { id: true, name: 'Required' },
            { id: false, name: 'Unrequired' }
          ]}
          mappingValue={'id'}
          inputHeader={'assignments'}
          inputHeaderBadgeOne={selectedBadge === 'distinct' ? 'distinct' : 'groups'}
          inputHeaderBadgeTwo={'sell'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assignment || {}
              : associateInfo?.informationSetupPlus?.assignment || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSIGNMENT_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSIGNMENT_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={
            selectedBadge === 'distinct'
              ? 'assignmentDistinctSellApprovalRevise'
              : 'assignmentGroupSellApprovalRevise'
          }
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSIGNMENTSELLFEEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'fee'}
          headerPanelColour={'genericOne'}
          inputHeader={headerOne? headerOne:'assignments'}
          //inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeOne={selectedBadge === 'distinct' ? 'distinct' : 'groups'}
          inputHeaderBadgeTwo={'sell'}
          type={'number'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={false}
          actualLableValue={
            selectedBadge === 'distinct' ? 'assignmentDistinctSellFee' : 'assignmentGroupSellFee'
          }
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assignment || {}
              : associateInfo?.informationSetupPlus?.assignment || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSIGNMENT_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSIGNMENT_INFO
          }
          nextPopUpValue={'ASSOCIATEPICTUREPOPUP'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          currencySymbol={associateInfo?.associateCurrency?.associateCurrencySymbol || ''}
          isRevisePermissionCheckbox={true}
          revisePermissionField={
            selectedBadge === 'distinct'
              ? 'assignmentDistinctSellFeeRevise'
              : 'assignmentGroupSellFeeRevise'
          }
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSIGNMENTSELLPERMISSIONPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={
            selectedBadge === 'distinct'
              ? 'assignmentDistinctSellPermission'
              : 'assignmentGroupSellPermission'
          }
          label={'permission'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'assignments'}
          //inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeOne={selectedBadge === 'distinct' ? 'distinct' : 'groups'}
          inputHeaderBadgeTwo={'sell'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assignment || {}
              : associateInfo?.informationSetupPlus?.assignment || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSIGNMENT_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSIGNMENT_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={
            selectedBadge === 'distinct'
              ? 'assignmentDistinctSellPermissionRevise'
              : 'assignmentGroupSellPermissionRevise'
          }
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSIGNMENTSHAREAPPROVALPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={
            selectedBadge === 'distinct'
              ? 'assignmentDistinctShareApproval'
              : 'assignmentGroupShareApproval'
          }
          label={'approval'}
          listSelect={[
            { id: true, name: 'Required' },
            { id: false, name: 'Unrequired' }
          ]}
          mappingValue={'id'}
          inputHeader={'assignments'}
          //inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeOne={selectedBadge === 'distinct' ? 'distinct' : 'groups'}
          inputHeaderBadgeTwo={'share'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assignment || {}
              : associateInfo?.informationSetupPlus?.assignment || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSIGNMENT_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSIGNMENT_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={
            selectedBadge === 'distinct'
              ? 'assignmentDistinctShareApprovalRevise'
              : 'assignmentGroupShareApprovalRevise'
          }
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSIGNMENTSHAREFEEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'fee'}
          headerPanelColour={'genericOne'}
          inputHeader={headerOne? headerOne:'assignments'}
          //inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeOne={selectedBadge === 'distinct' ? 'distinct' : 'groups'}
          inputHeaderBadgeTwo={'share'}
          type={'number'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={false}
          actualLableValue={
            selectedBadge === 'distinct' ? 'assignmentDistinctShareFee' : 'assignmentGroupShareFee'
          }
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assignment || {}
              : associateInfo?.informationSetupPlus?.assignment || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSIGNMENT_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSIGNMENT_INFO
          }
          nextPopUpValue={'ASSOCIATEPICTUREPOPUP'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          currencySymbol={associateInfo?.associateCurrency?.associateCurrencySymbol || ''}
          revisePermissionField={
            selectedBadge === 'distinct'
              ? 'assignmentDistinctShareFeeRevise'
              : 'assignmentGroupShareFeeRevise'
          }
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
          isRevisePermissionCheckbox={true}
        />
      )}
      {isPopUpValue === 'ASSIGNMENTSHAREPERMISSIONPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={
            selectedBadge === 'distinct'
              ? 'assignmentDistinctSharePermission'
              : 'assignmentGroupSharePermission'
          }
          label={'permission'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'assignments'}
          //inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeOne={selectedBadge === 'distinct' ? 'distinct' : 'groups'}
          inputHeaderBadgeTwo={'share'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.assignment || {}
              : associateInfo?.informationSetupPlus?.assignment || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ASSIGNMENT_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ASSIGNMENT_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={
            selectedBadge === 'distinct'
              ? 'assignmentDistinctSharePermissionRevise'
              : 'assignmentGroupSharePermissionRevise'
          }
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ITEMSELLAPPROVALPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={selectedBadge === 'distinct' ? 'itemDistinctSellApproval' : 'itemGroupSellApproval'}
          label={'approval'}
          listSelect={[
            { id: true, name: 'Required' },
            { id: false, name: 'Unrequired' }
          ]}
          mappingValue={'id'}
          inputHeader={'items'}
          //inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeOne={selectedBadge === 'distinct' ? 'distinct' : 'groups'}
          inputHeaderBadgeTwo={'sell'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.item || {}
              : associateInfo?.informationSetupPlus?.item || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ITEM_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ITEM_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={
            selectedBadge === 'distinct'
              ? 'itemDistinctSellApprovalRevise'
              : 'itemGroupSellApprovalRevise'
          }
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ITEMSELLFEEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'fee'}
          headerPanelColour={'genericOne'}
          inputHeader={headerOne? headerOne:'items'}
          //inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeOne={selectedBadge === 'distinct' ? 'distinct' : 'groups'}
          inputHeaderBadgeTwo={'sell'}
          type={'number'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={false}
          actualLableValue={
            selectedBadge === 'distinct' ? 'itemDistinctSellFee' : 'itemGroupSellFee'
          }
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.item || {}
              : associateInfo?.informationSetupPlus?.item || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ITEM_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ITEM_INFO
          }
          nextPopUpValue={'ASSOCIATEPICTUREPOPUP'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          currencySymbol={associateInfo?.associateCurrency?.associateCurrencySymbol || ''}
          isRevisePermissionCheckbox={true}
          revisePermissionField={
            selectedBadge === 'distinct' ? 'itemDistinctSellFeeRevise' : 'itemGroupSellFeeRevise'
          }
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ITEMSELLPERMISSIONPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={
            selectedBadge === 'distinct' ? 'itemDistinctSellPermission' : 'itemGroupSellPermission'
          }
          label={'permission'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'items'}
          //inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeOne={selectedBadge === 'distinct' ? 'distinct' : 'groups'}
          inputHeaderBadgeTwo={'sell'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.item || {}
              : associateInfo?.informationSetupPlus?.item || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ITEM_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ITEM_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={
            selectedBadge === 'distinct'
              ? 'itemDistinctSellPermissionRevise'
              : 'itemGroupSellPermissionRevise'
          }
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ITEMSHAREAPPROVALPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={
            selectedBadge === 'distinct' ? 'itemDistinctShareApproval' : 'itemGroupShareApproval'
          }
          label={'approval'}
          listSelect={[
            { id: true, name: 'Required' },
            { id: false, name: 'Unrequired' }
          ]}
          mappingValue={'id'}
          inputHeader={'items'}
          //inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeOne={selectedBadge === 'distinct' ? 'distinct' : 'groups'}
          inputHeaderBadgeTwo={'share'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.item || {}
              : associateInfo?.informationSetupPlus?.item || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ITEM_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ITEM_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={
            selectedBadge === 'distinct'
              ? 'itemDistinctShareApprovalRevise'
              : 'itemGroupShareApprovalRevise'
          }
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ITEMSHAREFEEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'fee'}
          headerPanelColour={'genericOne'}
          inputHeader={headerOne? headerOne:'items'}
          //inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeOne={selectedBadge === 'distinct' ? 'distinct' : 'groups'}
          inputHeaderBadgeTwo={'share'}
          type={'number'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={false}
          actualLableValue={
            selectedBadge === 'distinct' ? 'itemDistinctShareFee' : 'itemGroupShareFee'
          }
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.item || {}
              : associateInfo?.informationSetupPlus?.item || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ITEM_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ITEM_INFO
          }
          nextPopUpValue={'ASSOCIATEPICTUREPOPUP'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          currencySymbol={associateInfo?.associateCurrency?.associateCurrencySymbol || ''}
          isRevisePermissionCheckbox={true}
          revisePermissionField={
            selectedBadge === 'distinct' ? 'itemDistinctShareFeeRevise' : 'itemGroupShareFeeRevise'
          }
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ITEMSHAREPERMISSIONPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={
            selectedBadge === 'distinct'
              ? 'itemDistinctSharePermission'
              : 'itemGroupSharePermission'
          }
          label={'permission'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'items'}
          //inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeOne={selectedBadge === 'distinct' ? 'distinct' : 'groups'}
          inputHeaderBadgeTwo={'share'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.item || {}
              : associateInfo?.informationSetupPlus?.item || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ITEM_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ITEM_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={
            selectedBadge === 'distinct'
              ? 'itemDistinctSharePermissionRevise'
              : 'itemGroupSharePermissionRevise'
          }
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'IGURUANALYTICSSELLAPPROVALPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={
            selectedBadge === 'distinct'
              ? 'iGuruAnalyticDistinctSellApproval'
              : 'iGuruAnalyticGroupSellApproval'
          }
          label={'approval'}
          listSelect={[
            { id: true, name: 'Required' },
            { id: false, name: 'Unrequired' }
          ]}
          mappingValue={'id'}
          inputHeader={'iGuru analytics'}
          //inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeOne={selectedBadge === 'distinct' ? 'distinct' : 'groups'}
          inputHeaderBadgeTwo={'sell'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.analytic || {}
              : associateInfo?.informationSetupPlus?.analytic || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ANALYTIC_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ANALYTIC_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={
            selectedBadge === 'distinct'
              ? 'iGuruAnalyticDistinctSellApprovalRevise'
              : 'iGuruAnalyticGroupSellApprovalRevise'
          }
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'IGURUANALYTICSSELLFEEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'fee'}
          headerPanelColour={'genericOne'}
          inputHeader={'iGuru analytics'}
          //inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeOne={selectedBadge === 'distinct' ? 'distinct' : 'groups'}
          inputHeaderBadgeTwo={'sell'}
          type={'number'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={false}
          actualLableValue={
            selectedBadge === 'distinct'
              ? 'iGuruAnalyticDistinctSellFee'
              : 'iGuruAnalyticGroupSellFee'
          }
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.analytic || {}
              : associateInfo?.informationSetupPlus?.analytic || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ANALYTIC_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ANALYTIC_INFO
          }
          nextPopUpValue={'ASSOCIATEPICTUREPOPUP'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          currencySymbol={associateInfo?.associateCurrency?.associateCurrencySymbol || ''}
          isRevisePermissionCheckbox={true}
          revisePermissionField={
            selectedBadge === 'distinct'
              ? 'iGuruAnalyticDistinctSellFeeRevise'
              : 'iGuruAnalyticGroupSellFeeRevise'
          }
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'IGURUANALYTICSSELLPERMISSIONPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={
            selectedBadge === 'distinct'
              ? 'iGuruAnalyticDistinctSellPermission'
              : 'iGuruAnalyticGroupSellPermission'
          }
          label={'permission'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'iGuru analytics'}
          //inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeOne={selectedBadge === 'distinct' ? 'distinct' : 'groups'}
          inputHeaderBadgeTwo={'sell'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.analytic || {}
              : associateInfo?.informationSetupPlus?.analytic || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ANALYTIC_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ANALYTIC_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={
            selectedBadge === 'distinct'
              ? 'iGuruAnalyticDistinctSellPermissionRevise'
              : 'iGuruAnalyticGroupSellPermissionRevise'
          }
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'IGURUANALYTICSSHAREAPPROVALPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={
            selectedBadge === 'distinct'
              ? 'iGuruAnalyticDistinctShareApproval'
              : 'iGuruAnalyticGroupShareApproval'
          }
          label={'approval'}
          listSelect={[
            { id: true, name: 'Required' },
            { id: false, name: 'Unrequired' }
          ]}
          mappingValue={'id'}
          inputHeader={'iGuru analytics'}
          //inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeOne={selectedBadge === 'distinct' ? 'distinct' : 'groups'}
          inputHeaderBadgeTwo={'sell'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.analytic || {}
              : associateInfo?.informationSetupPlus?.analytic || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ANALYTIC_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ANALYTIC_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={
            selectedBadge === 'distinct'
              ? 'iGuruAnalyticDistinctShareApprovalRevise'
              : 'iGuruAnalyticGroupShareApprovalRevise'
          }
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'IGURUANALYTICSSHAREFEEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'fee'}
          headerPanelColour={'genericOne'}
          inputHeader={'iGuru analytics'}
          //inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeOne={selectedBadge === 'distinct' ? 'distinct' : 'groups'}
          inputHeaderBadgeTwo={'share'}
          type={'number'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={false}
          actualLableValue={
            selectedBadge === 'distinct'
              ? 'iGuruAnalyticDistinctShareFee'
              : 'iGuruAnalyticGroupShareFee'
          }
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.analytic || {}
              : associateInfo?.informationSetupPlus?.analytic || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ANALYTIC_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ANALYTIC_INFO
          }
          nextPopUpValue={'ASSOCIATEPICTUREPOPUP'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          currencySymbol={associateInfo?.associateCurrency?.associateCurrencySymbol || ''}
          isRevisePermissionCheckbox={true}
          revisePermissionField={
            selectedBadge === 'distinct'
              ? 'iGuruAnalyticDistinctShareFeeRevise'
              : 'iGuruAnalyticGroupShareFeeRevise'
          }
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'IGURUANALYTICSSHAREPERMISSIONPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={
            selectedBadge === 'distinct'
              ? 'iGuruAnalyticDistinctSharePermission'
              : 'iGuruAnalyticGroupSharePermission'
          }
          label={'permission'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'iGuru analytics'}
          //inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeOne={selectedBadge === 'distinct' ? 'distinct' : 'groups'}
          inputHeaderBadgeTwo={'share'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.analytic || {}
              : associateInfo?.informationSetupPlus?.analytic || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_ANALYTIC_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_ANALYTIC_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={
            selectedBadge === 'distinct'
              ? 'iGuruAnalyticDistinctSharePermissionRevise'
              : 'iGuruAnalyticGroupSharePermissionRevise'
          }
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSOCIATESELLAPPROVALPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={
            selectedBadge === 'distinct'
              ? 'associateDistinctSellApproval'
              : 'associateGroupSellApproval'
          }
          label={'approval'}
          listSelect={[
            { id: true, name: 'Required' },
            { id: false, name: 'Unrequired' }
          ]}
          mappingValue={'id'}
          inputHeader={'associates'}
          //inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeOne={selectedBadge === 'distinct' ? 'distinct' : 'groups'}
          inputHeaderBadgeTwo={'sell'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={
            selectedBadge === 'distinct'
              ? 'associateDistinctSellApprovalRevise'
              : 'associateGroupSellApprovalRevise'
          }
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSOCIATESELLFEEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'fee'}
          headerPanelColour={'genericOne'}
          inputHeader={headerOne? headerOne:'associates'}
          //inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeOne={selectedBadge === 'distinct' ? 'distinct' : 'groups'}
          inputHeaderBadgeTwo={'sell'}
          type={'number'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={false}
          actualLableValue={
            selectedBadge === 'distinct' ? 'associateDistinctSellFee' : 'associateGroupSellFee'
          }
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          nextPopUpValue={'ASSOCIATEPICTUREPOPUP'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          currencySymbol={associateInfo?.associateCurrency?.associateCurrencySymbol || ''}
          isRevisePermissionCheckbox={true}
          revisePermissionField={
            selectedBadge === 'distinct'
              ? 'associateDistinctSellFeeRevise'
              : 'associateGroupSellFeeRevise'
          }
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSOCIATECREATEFEEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'fee'}
          headerPanelColour={'genericOne'}
          inputHeader={headerOne? headerOne:'associates'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'create'}
          type={'number'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={false}
          actualLableValue={'associateDistinctCreateFee'}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          nextPopUpValue={'ASSOCIATEPICTUREPOPUP'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          currencySymbol={associateInfo?.associateCurrency?.associateCurrencySymbol || ''}
          isRevisePermissionCheckbox={true}
          revisePermissionField={'associateDistinctCreateFeeRevise'}
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSOCIATESELLPERMISSIONPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={
            selectedBadge === 'distinct'
              ? 'associateDistinctSellPermission'
              : 'associateGroupSellPermission'
          }
          label={'permission'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'associates'}
          //inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeOne={selectedBadge === 'distinct' ? 'distinct' : 'groups'}
          inputHeaderBadgeTwo={'sell'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={
            selectedBadge === 'distinct'
              ? 'associateDistinctSellPermissionRevise'
              : 'associateGroupSellPermissionRevise'
          }
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSOCIATESHAREAPPROVALPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={
            selectedBadge === 'distinct'
              ? 'associateDistinctShareApproval'
              : 'associateGroupShareApproval'
          }
          label={'approval'}
          listSelect={[
            { id: true, name: 'Required' },
            { id: false, name: 'Unrequired' }
          ]}
          mappingValue={'id'}
          inputHeader={'associates'}
          //inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeOne={selectedBadge === 'distinct' ? 'distinct' : 'groups'}
          inputHeaderBadgeTwo={'share'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={
            selectedBadge === 'distinct'
              ? 'associateDistinctShareApprovalRevise'
              : 'associateGroupShareApprovalRevise'
          }
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSOCIATESHAREFEEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'fee'}
          headerPanelColour={'genericOne'}
          inputHeader={headerOne? headerOne:'associates'}
          //inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeOne={selectedBadge === 'distinct' ? 'distinct' : 'groups'}
          inputHeaderBadgeTwo={'share'}
          type={'number'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={false}
          actualLableValue={
            selectedBadge === 'distinct' ? 'associateDistinctShareFee' : 'associateGroupShareFee'
          }
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          nextPopUpValue={'ASSOCIATEPICTUREPOPUP'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          currencySymbol={associateInfo?.associateCurrency?.associateCurrencySymbol || ''}
          isRevisePermissionCheckbox={true}
          revisePermissionField={
            selectedBadge === 'distinct'
              ? 'associateDistinctShareFeeRevise'
              : 'associateGroupShareFeeRevise'
          }
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
      {isPopUpValue === 'ASSOCIATESHAREPERMISSIONPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={
            selectedBadge === 'distinct'
              ? 'associateDistinctSharePermission'
              : 'associateGroupSharePermission'
          }
          label={'permission'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeader={'associates'}
          //inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeOne={selectedBadge === 'distinct' ? 'distinct' : 'groups'}
          inputHeaderBadgeTwo={'share'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'associate'}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={
            associateInfo?.updateAssociateSetupFlag
              ? associateInfo?.informationSetup?.associate || {}
              : associateInfo?.informationSetupPlus?.associate || {}
          }
          typeOfSetObject={
            associateInfo?.updateAssociateSetupFlag
              ? UPDATE_ASSOCIATE_SETUP_INFO
              : UPDATE_ASSOCIATE_SETUPPLUS_INFO
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isRevisePermissionCheckbox={true}
          revisePermissionField={
            selectedBadge === 'distinct'
              ? 'associateDistinctSharePermissionRevise'
              : 'associateGroupSharePermissionRevise'
          }
          revisePermissionCheckboxLabel={'disable revise permission'}
          // setUpPlusRevisePermission={setUpPlusPermissions}
        />
      )}
    </div>
  );
};

export default PopUpSignOnAssociate;
