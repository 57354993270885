import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ASSOCIATE_POPUP_CLOSE,
  FILTERMODE_ENABLE,
  GET_JOBPROFILE_GROUP_REVIEW_LIST_SAGA,
  LOADER_START,
  POPUP_OPEN,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_PAGE_COUNT,
  SET_POPUP_STATE,
  SET_REQUEST_OBJECT,
  GET_JOBPROFILE_TYPE_REVIEW_LIST_SAGA,
  JOBPROFILE_ALLOCATE_SAGA,
  FILTERMODE
} from '../actionType';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import { FilterList } from '@material-ui/icons';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import {
  ANALYTICS_TYPE_REVIEW_LIST_POPUP_OPTION,
  NODE_ROLE_TYPE_REVIEW_LIST_POPUP_OPTION
} from '../PopUpConfig';
import { getJobProfileTypeApiCall } from '../Actions/ActionJobProfile';
import {
  assesseeRole,
  onClickCheckBoxSelection,
  onClickFlagSelection,
  setFlagedArray
} from '../Actions/AssesseeModuleAction';
import { getPopUpOptionList, makeJobProfileTypeObj } from '../Actions/GenericActions';
import ReviseIcon from '@material-ui/icons/RadioButtonChecked';
import Check from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import ListLoader from '../Atoms/ListLoader/ListLoader';

const MarketPlaceJobProfileTypeReviewList = (props) => {
  const dispatch = useDispatch();
  const { secondaryOptionCheckValue } = useSelector((state) => state.AssesseeCreateReducer);
  const {
    numberPage,
    countPage,
    scanCount,
    reviewListDistinctData,
    reviewListReqObj,
    middlePaneSelectedValue,
    selectedAssociateInfo,
    middlePaneHeader,
    isSelectActive,
    selectedTagsArray,
    unselectedTagsArray,
    middlePaneHeaderBadgeOne,
    middlePaneHeaderBadgeTwo,
    middlePaneHeaderBadgeThree,
    allocateStr,
    allocatedTagsArray,
    selectedFlagedArray,
    unselectedFlagedArray,
    flagedValue,
    signedAssesseePermission,
    isListLoading
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { FilterModeEnable, FilterMode } = useSelector((state) => state.FilterReducer);
  const { cardValue } = useSelector((state) => state.PopUpReducer);
  // const [isFetching, setIsFetching] = useState(false);
  // useEffect(() => {
  //   document.getElementById('middleComponentId').addEventListener('scroll', handleScroll);
  // }, []);
  // const handleScroll = (event) => {
  //   var targetPt = event.target;
  //   if (
  //     Math.ceil(targetPt.scrollHeight - targetPt.scrollTop) !== targetPt.clientHeight ||
  //     isFetching
  //   )
  //     return;
  //   setIsFetching(true);
  //   console.log(isFetching);
  // };
  const fetchData = async () => {
    if (reviewListDistinctData.length < scanCount) {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isListLoading', value: true }
      });
      let obj = {
        ...reviewListReqObj.requestObject,
        numberPage: numberPage + 1
      };
      dispatch({
        type: GET_JOBPROFILE_TYPE_REVIEW_LIST_SAGA,
        payload: {
          request: {
            module: 'Job-Profile-Type',
            action: 'Review-List',
            actionFilter: 'Shared-Job-Profile-Type',
            requestObject: obj
          },
          BadgeOne: middlePaneHeaderBadgeOne,
          BadgeTwo: middlePaneHeaderBadgeTwo,
          BadgeThree: middlePaneHeaderBadgeThree,
          isMiddlePaneList: true,
          isSelectActive: isSelectActive,
          selectedTagsArray: selectedTagsArray
        }
      });
      dispatch({ type: SET_PAGE_COUNT, payload: numberPage + 1 });
    }
  };
  // useEffect(() => {
  //   console.log(reviewListDistinctData);
  //   if (!isFetching) return;
  //   fetchMoreListItems();
  // }, [isFetching]);

  // const fetchMoreListItems = () => {
  //   fetchData();
  //   setIsFetching(false);
  // };

  useEffect(() => {
    setFlagedArray(reviewListDistinctData, 'jobProfileTypeFlag', dispatch);
  }, [reviewListDistinctData]);

  const siftApiCall = (siftKey) => {
    getJobProfileTypeApiCall(
      selectedAssociateInfo,
      siftKey,
      countPage,
      dispatch,
      'types',
      'job-profiles',
      cardValue,
      isSelectActive,
      selectedTagsArray
    );
    dispatch({ type: ASSOCIATE_POPUP_CLOSE });
    document.getElementById('middleComponentId').scrollTop = '0px';
  };

  const siftApiFilterCall = (siftKey) => {
    let requestObj = makeJobProfileTypeObj(
      selectedAssociateInfo,
      siftKey,
      countPage,
      0,
      'Shared-Job-Profile-Type'
    );
    dispatch({ type: LOADER_START });
    dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
    dispatch({ type: SET_PAGE_COUNT, payload: 0 });
    dispatch({
      type: FILTERMODE,
      payload: { FilterMode: 'jobProfileTypeDistinct' + siftKey }
    });
    dispatch({
      type: GET_JOBPROFILE_TYPE_REVIEW_LIST_SAGA,
      payload: {
        middlePaneHeader: middlePaneHeader,
        request: requestObj,
        BadgeOne: middlePaneHeaderBadgeOne,
        BadgeTwo: middlePaneHeaderBadgeTwo === 'distinct' ? middlePaneHeaderBadgeTwo : siftKey,
        BadgeThree: middlePaneHeaderBadgeTwo === 'distinct' ? siftKey : middlePaneHeaderBadgeThree,
        isMiddlePaneList: true,
        selectedTagsArray: selectedTagsArray, // for select  check box
        isSelectActive: isSelectActive // is for selection of array like multuple and all and sect option wrk
      }
    });
    dispatch({ type: ASSOCIATE_POPUP_CLOSE });
    document.getElementById('middleComponentId').scrollTop = '0px';
  };
  const onClickFooter = (e) => {
    let siftValue = e.currentTarget.getAttribute('data-value');
    dispatch({ type: FILTERMODE_ENABLE });
    if (siftValue === 'suspended' || siftValue === 'terminated') siftApiCall(siftValue);
    if (siftValue === 'bespoke' || siftValue === 'generic') siftApiFilterCall(siftValue);
    if (siftValue === 'finish') {
      console.log('allocateStr', allocateStr);
      let distinctAllocateStr =
        allocateStr === 'job-profilesdistinct'
          ? 'jobProfileDistinct'
          : allocateStr === 'jobprofilesdistinct'
          ? 'jobProfileDistinct'
          : allocateStr === 'job-profilesgroups'
          ? 'jobProfileGroup'
          : allocateStr === 'job-profilesnodes'
          ? 'jobProfileNode'
          : allocateStr === 'job-profilestypes'
          ? 'jobProfileType'
          : '';
      // if (allocateStr === '') {
      //   debugger // commenting if as it is useless
      // }
      if (distinctAllocateStr !== '' && selectedTagsArray.length !== 0) {
        if (
          distinctAllocateStr === 'jobProfileDistinct' ||
          distinctAllocateStr === 'jobProfileGroup' ||
          distinctAllocateStr === 'jobProfileNode' || //
          distinctAllocateStr === 'jobProfileType'
        ) {
          let request = {
            module: 'Job-Profile-Distinct',
            action: FilterMode.toLowerCase().includes('unallocate') ? 'Unallocate' : 'Allocate',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              jobProfileDistinctAllocate: {
                [distinctAllocateStr]: allocatedTagsArray
              },
              jobProfileDistinctAllocateInformation: {
                jobProfileType: selectedTagsArray
              }
            }
          };
          dispatch({ type: LOADER_START });
          dispatch({ type: JOBPROFILE_ALLOCATE_SAGA, payload: { request: request } });
        }
      }
    }
    if (siftValue === 'cancel') {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isSelectActive', value: '' }
      });
      dispatch({
        type: FILTERMODE,
        payload: { FilterMode: '' }
      });
    }
  };
  /* for middle pane */
  const primaryIcon = [{ label: 'sift', onClick: onClickFooter, Icon: FilterList }];
  const secondaryIcon = [
    { label: 'suspended', onClick: onClickFooter, Icon: FilterList },
    { label: 'terminated', onClick: onClickFooter, Icon: FilterList }
  ];
  const secondaryOneIcon = [
    { label: 'bespoke', onClick: onClickFooter, Icon: FilterList },
    { label: 'generic', onClick: onClickFooter, Icon: FilterList }
    // { label: 'shared', onClick: onClickFooter, Icon: FilterList },
    // { label: 'unshared', onClick: onClickFooter, Icon: FilterList }
  ];
  let signedAssociateId =
    selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary;

  const openListPopup = (e) => {
    console.log(e.currentTarget.getAttribute('tag'));
    let reviseHeader = 'job-profiles';
    let optArr = [];
    let popupArr =
      cardValue === 'Card'
        ? NODE_ROLE_TYPE_REVIEW_LIST_POPUP_OPTION
        : ANALYTICS_TYPE_REVIEW_LIST_POPUP_OPTION;
    let popupContentArrValue = popupArr.map((obj) =>
      obj.data === 'analytics' ? { ...obj, data: middlePaneHeader, dataValue: reviseHeader } : obj
    );
    let tempArr = [];

    let classification = e.currentTarget.getAttribute('data-classification');
    let isShared = e.currentTarget.getAttribute('data-shared');
    let associateId = e.currentTarget.getAttribute('associateid');
    let status = e.currentTarget.getAttribute('status');
    let cultureProfileDistictPermssion =
      signedAssesseePermission?.iGuruAnalyticDistinctPermission
        ?.assesseeiGuruAnalyticTypePermission || {};
    let popupContentArr = getPopUpOptionList(popupContentArrValue, cultureProfileDistictPermssion);
    popupContentArr.forEach((element) => {
      if (classification === 'Bespoke' && element.data === 'share' && status === 'ACTIVE')
        tempArr.push({ ...element, disabled: true });
      else if (
        isShared === 'true' &&
        associateId !== signedAssociateId &&
        (element.data === 'archive' ||
          element.data === 'flag' ||
          element.data === 'delete' ||
          element.data === 'suspend' ||
          element.data === 'move' ||
          element.data === 'terminate')
      ) {
        tempArr.push({ ...element, disabled: true });
      } else if (
        (status === 'TERMINATED' || status === 'SUSPENDED' || status === 'ARCHIVED') &&
        (element.data === 'allocate' ||
          element.data === 'revise' ||
          element.data === 'select' ||
          element.data === 'share')
      ) {
        tempArr.push({ ...element, disabled: true });
      } else tempArr.push(element);
    });
    // dispatch({
    //   type: SET_POPUP_STATE,
    //   payload: {
    //     popupHeaderOne: middlePaneHeader,
    //     duplicateHeaderOneBadgeTwo: middlePaneHeaderBadgeTwo,
    //     popupHeaderOneBadgeOne: 'type',
    //     popupHeaderOneBadgeTwo: '',
    //     isPopUpValue: '',
    //     popupOpenType: 'primary',
    //     popupContentArrValue: tempArr, //tempArr, //optArr,
    //     originPrimaryArrOprion: tempArr,
    //     selectedTagValue: e.currentTarget.getAttribute('tag'),
    //     selectedTagStatus: e.currentTarget.getAttribute('status'),
    //     selectedTagGroupId: e.currentTarget.getAttribute('data-value'),
    //     selectedTagShared: e.currentTarget.getAttribute('data-shared'),
    //     isFlaged: e.currentTarget.getAttribute('data-flag') === 'true' ? true : false
    //   }
    // });
    // dispatch({
    //   type: SET_DISPLAY_TWO_SINGLE_STATE,
    //   payload: {
    //     stateName: 'middlePaneListPopupOptions',
    //     value: tempArr
    //   }
    // });
    // dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
  };
  console.log('FilterMode', FilterMode);
  // infinite scroll
  const [hasMore, setHasMore] = useState(true);
  let elementRef = useRef(null);

  const onIntersection = (entries) => {
    const firstEntry = entries[0];
    reviewListDistinctData.length >= scanCount ? setHasMore(false) : setHasMore(true);
    if (firstEntry.isIntersecting && hasMore && !isListLoading) {
      fetchData();
    }
  };
  useEffect(() => {
    const observer = new IntersectionObserver(onIntersection, { threshold: 0.9 });

    if (observer && elementRef.current) {
      // console.log('here at observer...', elementRef.current)
      observer.observe(elementRef.current);
    }

    return () => {
      if (observer) observer.disconnect();
    };
  }, [
    isListLoading,
    hasMore,
    reviewListDistinctData,
    selectedTagsArray,
    unselectedTagsArray,
    isSelectActive
  ]);

  // infinite scroll end
  return (
    <div>
      {reviewListDistinctData &&
        reviewListDistinctData.map((item, index) => {
          return (
            <div className="containerPadding" key={index} ref={elementRef}>
              <ReviewList
                className=""
                id={index}
                tag={item.id}
                associateId={item?.associateId}
                isSelectedReviewList={middlePaneSelectedValue === item.id}
                // textOne={item.informationBasic.jobProfileTypeName}
                textOne={assesseeRole(item.informationBasic.jobProfileTypeName)}
                textTwo={item.informationBasic.jobProfileTypeDescription}
                //status={item.informationEngagement.jobProfileTypeStatus}
                status={
                  FilterMode === 'jobProfileTypeDistinctactive' ||
                  FilterMode === 'jobProfileTypeDistinctbespoke' ||
                  FilterMode === 'jobProfileTypeDistinctgeneric'
                    ? item.informationSetup?.jobProfileTypeClassification
                        ?.jobProfileTypeClassificationPrimary
                    : item.informationEngagement.jobProfileTypeStatus
                }
                // status={associateSeftId === item.associateId ? 'bespoke' : 'generic'}
                actualStatus={item.informationEngagement.jobProfileTypeStatus}
                //shared={item.jobProfileTypeShared ? 'SHARED' : 'UNSHARED'}
                isTooltipActive={false}
                // dataValue={item.informationAllocation.jobProfileTypeGroup}
                onClickEvent={openListPopup}
                isSelectActive={isSelectActive}
                isSelected={selectedTagsArray.includes(item.id)}
                onClickCheckBox={(event) => {
                  !isListLoading &&
                    onClickCheckBoxSelection(
                      selectedTagsArray,
                      unselectedTagsArray,
                      event,
                      dispatch
                    );
                }}
                // disableonClickCheckBox={item.associateId !== signedAssociateId ? true : false}
                isShared={item?.jobProfileTypeShared}
                shared={
                  item.informationSetup?.jobProfileTypeClassification
                    ?.jobProfileTypeClassificationPrimary
                }
                isFlagActive={selectedFlagedArray.includes(item.id)}
                flagedValue={flagedValue}
                onClickAddFladed={(event) => {
                  onClickFlagSelection(selectedFlagedArray, unselectedFlagedArray, event, dispatch);
                }}
              />
            </div>
          );
        })}
      {isListLoading ? (
        <div>
          {' '}
          <ListLoader />{' '}
        </div>
      ) : <div style={{ height: "60px", width: "100%" }}></div>}
    </div>
  );
};
export default MarketPlaceJobProfileTypeReviewList;
