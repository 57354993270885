import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ASSIGNMENT_REVISE_SAGA,
  CLEAR_DISPLAY_PANE_THREE,
  FILTERMODE,
  FILTERMODE_ENABLE,
  LOADER_START,
  POPUP_OPEN,
  RESET_ASSIGNMENT_REVIEW_LIST_OBJECT,
  SET_ASSIGNMENT_RELATED_LIST,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MIDDLEPANE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_POPUP_STATE
} from '../actionType';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import { FilterList } from '@material-ui/icons';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import { SECTION_SCALE_CLUSTER_REVIEW_LIST_POPUP_OPTION } from '../PopUpConfig';
import Card from '../Molecules/Card/Card';
import CrossIcon from '@material-ui/icons/Clear';
import { onClickCheckBoxOneListSelection } from '../Actions/AssesseeModuleAction';
import ReviseIcon from '@material-ui/icons/RadioButtonChecked';
import Check from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { getCultureGroupCultureDistinctApiCall } from '../Actions/ActionCultureProfile';
import { closeRelatedList, getPopUpOptionList } from '../Actions/GenericActions';
import ListLoader from '../Atoms/ListLoader/ListLoader';

const AssignmentDistinctCultureProfileDistinctReviewList = (props) => {
  const dispatch = useDispatch();
  const [isShowReviseIcon, setIsShowReviseIcon] = useState(true);
  const {
    createMode,
    responseObject,
    assignmentAssesseeList = [],
    assignmentAssessmentList = [],
    assignmentCultureProfileList = [],
    assignmentJobProfileList = [],
    assignmentAssesseeGroupList = [],
    assignmentAssessmentGroupList = [],
    assignmentCultureProfileGroupList = [],
    assignmentJobProfileGroupList = []
  } = useSelector((state) => state.DisplayPaneThreeReducer);
  const assignmentInfo = useSelector((state) => state.AssignmentReducer);
  const {
    middlePaneSelectedValue,
    reviewListDistinctData,
    selectedAssociateInfo,
    relatedReviewListDistinctData,
    middlePaneHeaderDuplicate,
    middlePaneHeaderBadgeOne,
    middlePaneHeaderDuplicateBadgeOne,
    middlePaneHeaderDuplicateBadgeTwo,
    middlePaneHeaderDuplicateBadgeThree,
    middlePaneHeaderDuplicateBadgeFour,
    selectedTagsArray,
    isSelectActive,
    unselectedTagsArray,
    typeOfMiddlePaneList,
    duplicateScanCount,
    countPage,
    signedAssesseePermission,
    isListLoading,
    middlePaneHeaderDuplicateOne,
    middlePaneHeaderDuplicateOneBadgeOne,
    middlePaneHeaderDuplicateOneBadgeTwo,
    middlePaneHeaderDuplicateOneBadgeThree,
    middlePaneHeaderDuplicateOneBadgeFour,
    previousMiddlePaneState
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { FilterMode } = useSelector((state) => state.FilterReducer);

  const onClickRevise = () => {
    console.log('ON CLICK REVISE ICON');
    setIsShowReviseIcon(false);
  };
  const onClickReviseCancel = () => {
    if (isListLoading) {
      return
    }
    console.log('ON CLICK cancel ICON');
    setIsShowReviseIcon(true);
    setPrevList();
  };
  // clear middle pane list after revise finish
  const setPrevList = () => {
    // debugger
    if (createMode === "") {
      closeRelatedList(
        dispatch,
        createMode,
        responseObject,
        middlePaneHeaderDuplicate,
        middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderDuplicateBadgeTwo,
        middlePaneHeaderDuplicateBadgeThree,
        middlePaneHeaderDuplicateBadgeFour,
        previousMiddlePaneState,
        duplicateScanCount,
        'assignmentsDistinctinactive',
        duplicateScanCount,
        middlePaneHeaderDuplicateOne,
        middlePaneHeaderDuplicateOneBadgeOne,
        middlePaneHeaderDuplicateOneBadgeTwo,
        middlePaneHeaderDuplicateOneBadgeThree,
        middlePaneHeaderDuplicateOneBadgeFour
      );
    } else {
      dispatch({
        type: SET_MIDDLEPANE_STATE,
        payload: {
          middlePaneHeader: "",
          middlePaneHeaderBadgeOne: "",
          middlePaneHeaderBadgeTwo: "",
          middlePaneHeaderBadgeThree: "",
          middlePaneHeaderBadgeFour: "",
          typeOfMiddlePaneList: "",
          scanCount: "",
          showMiddlePaneState: false,
        },
      });
    }
  };
  const onClickReviseFinish = () => {
    console.log('ON CLICK finish ICON', selectedTagsArray, unselectedTagsArray);
    setIsShowReviseIcon(true);
    setPrevList();
    if (typeOfMiddlePaneList !== '') {
      dispatch({
        type: FILTERMODE,
        payload: { FilterMode: '' }
      });
    }

    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'isSelectActive', value: '' }
    });
    dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
    dispatch({
      type: SET_ASSIGNMENT_RELATED_LIST,
      payload: { listName: 'assignmentCultureProfileList', value: selectedTagsArray }
    });
    const { id } = responseObject;
    let frameworkObj = {
      ...assignmentInfo.informationFramework,
      assignmentAssesseeDistinct: assignmentAssesseeList || [],
      assignmentAssessmentDistinct: assignmentAssessmentList || [],
      assignmentCultureProfileDistinct: selectedTagsArray || [],
      assignmentJobProfileDistinct: assignmentJobProfileList || [],
      assignmentAssesseeGroup: assignmentAssesseeGroupList || [],
      assignmentAssessmentGroup: assignmentAssessmentGroupList || [],
      assignmentCultureProfileGroup: assignmentCultureProfileGroupList || [],
      assignmentJobProfileGroup: assignmentJobProfileGroupList || []
    };
    const reqBody = {
      module: 'Assignment-Distinct',
      action: 'Revise',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        assignment: {
          id,
          informationBasic: assignmentInfo.informationBasic,
          informationAllocation: assignmentInfo.informationAllocation,
          informationFramework: frameworkObj,
          informationSetup: assignmentInfo.informationSetup
        }
      }
    };
    dispatch({ type: LOADER_START });
    dispatch({
      type: ASSIGNMENT_REVISE_SAGA,
      payload: {
        reqBody
      }
    });
    dispatch({ type: RESET_ASSIGNMENT_REVIEW_LIST_OBJECT });
    closeRelatedList(
      dispatch,
      createMode,
      responseObject,
      middlePaneHeaderDuplicate,
      middlePaneHeaderDuplicateBadgeOne,
      middlePaneHeaderDuplicateBadgeTwo,
      middlePaneHeaderDuplicateBadgeThree,
      middlePaneHeaderDuplicateBadgeFour,
      previousMiddlePaneState,
      duplicateScanCount,
      'assignmentsDistinctinactive',
      duplicateScanCount,
      middlePaneHeaderDuplicateOne,
      middlePaneHeaderDuplicateOneBadgeOne,
      middlePaneHeaderDuplicateOneBadgeTwo,
      middlePaneHeaderDuplicateOneBadgeThree,
      middlePaneHeaderDuplicateOneBadgeFour
    );
    dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
  };
  const revisePrimaryIcon = [{ label: 'revise', onClick: onClickRevise, Icon: ReviseIcon }];

  const reviseSecondaryIcons = [
    { label: 'cancel', onClick: onClickReviseCancel, Icon: ClearIcon },
    { label: 'finish', onClick: onClickReviseFinish, Icon: Check }
  ];
  // const closeRelatedList = () => {
  //   dispatch({
  //     type: SET_MIDDLEPANE_STATE,
  //     payload: {
  //       middlePaneHeader: 'assignments',
  //       middlePaneHeaderBadgeOne: middlePaneHeaderDuplicateBadgeOne,
  //       middlePaneHeaderBadgeTwo: middlePaneHeaderDuplicateBadgeTwo,
  //       middlePaneHeaderBadgeThree: '',
  //       middlePaneHeaderBadgeFour: '',
  //       typeOfMiddlePaneList: previousMiddlePaneState,
  //       scanCount: reviewListDistinctData.length,
  //       filterMode: 'assignmentsDistinctinactive',
  //       showMiddlePaneState: true
  //     }
  //   });
  //   if (FilterMode !== 'assignmentDistinctCultureProfileRevise')
  //     dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  // };


  const listDistinctData = relatedReviewListDistinctData[0];

  const siftApiCall = (siftKey) => {
    getCultureGroupCultureDistinctApiCall(
      selectedAssociateInfo,
      siftKey,
      countPage,
      dispatch,
      middlePaneHeaderBadgeOne,
      listDistinctData.id,
      '',
      false
    );
    document.getElementById('middleComponentId').scrollTop = '0px';
  };
  const onClickFooter = (e) => {
    let siftValue = e.currentTarget.getAttribute('data-value');
    if (siftValue === 'suspended' || siftValue === 'terminated') siftApiCall(siftValue);
    dispatch({ type: FILTERMODE_ENABLE });
  };
  /* for middle pane */
  const primaryIcon = [{ label: 'sift', onClick: onClickFooter, Icon: FilterList }];
  const secondaryIcon = [
    { label: 'suspended', onClick: onClickFooter, Icon: FilterList },
    { label: 'terminated', onClick: onClickFooter, Icon: FilterList }
  ];

  const openListPopup = (e) => {
    console.log(e.currentTarget.getAttribute('tag'));
    let tempArr = [];
    let permissionObject = signedAssesseePermission?.assigmentDistictPermission?.assesseeAssignmentDistinctPermission;
    let popupContentArr = getPopUpOptionList(SECTION_SCALE_CLUSTER_REVIEW_LIST_POPUP_OPTION, permissionObject);
    popupContentArr.map((element) => {
      if (
        element.data === 'archive' ||
        element.data === 'delete' ||
        element.data === 'suspend' ||
        element.data === 'share' ||
        element.data === 'flag' ||
        element.data === 'revise' ||
        element.data === 'allocate' ||
        element.data === 'select' ||
        element.data === 'terminate'
      ) {
        tempArr.push({ ...element, disabled: true });
      } else if (listDistinctData.assignmentStatus === 'PUBLISHED' && element.data === 'revise') {
        tempArr.push({ ...element, disabled: true });
      } else tempArr.push(element);
    });
    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: 'culture-profile',
        popupHeaderOneBadgeOne: middlePaneHeaderBadgeOne,
        isPopUpValue: '',
        popupOpenType: 'primary',
        popupContentArrValue: tempArr,
        selectedTagValue: e.currentTarget.getAttribute('tag'),
        selectedTagStatus: e.currentTarget.getAttribute('status')
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: tempArr
      }
    });
  };
  return (
    <div>
      {listDistinctData && (
        <Card
          textOneOne={listDistinctData?.assignmentName}
          textTwoOne={listDistinctData?.assignmentDescription}
          IconOne={CrossIcon}
          isIcon={true}
          labelTwoTwo={'distinct'}
          onClickIconOne={onClickReviseCancel}
          isAlliance
          relatedCardFixed={true}
          className={'iguru-iconboxSVG'}
        />
      )}
      {listDistinctData &&
        listDistinctData?.cultureProfile?.map((item, index) => {
          return (
            <div className="containerPadding" key={index}>
              <ReviewList
                className=""
                id={index}
                tag={item.id}
                associateId={item?.associateId}
                isSelectedReviewList={middlePaneSelectedValue === item.id}
                status={item.informationEngagement.cultureProfileStatus}
                actualStatus={item.informationEngagement.cultureProfileStatus}
                textOne={item.informationBasic.cultureProfileName}
                textTwo={item.informationBasic.cultureProfileDescription}
                isTooltipActive={false}
                onClickEvent={openListPopup}
                isShared={item?.cultureProfileShared}
                isSelectActive={isSelectActive}
                isSelected={selectedTagsArray.includes(item.id)}
                onClickCheckBox={(event) => {
                  !isListLoading &&
                    onClickCheckBoxOneListSelection(selectedTagsArray, event, dispatch);
                }}
              />
            </div>
          );
        })}
      {isListLoading ? <div> <ListLoader /> </div> : <div style={{ height: "60px", width: "100%" }}></div>}
      {FilterMode === 'assignmentDistinctCultureProfileRevise' && (
        <FooterIconTwo
          FilterModeEnable={isShowReviseIcon}
          FilterMode={FilterMode}
          onClick={onClickRevise}
          primaryIcon={revisePrimaryIcon}
          secondaryIcon={reviseSecondaryIcons}
        />
      )}
      {FilterMode === 'cultureGroupCultureDistinctinactive' && (
        <FooterIconTwo
          FilterModeEnable={isShowReviseIcon}
          FilterMode={FilterMode}
          onClick={onClickRevise}
          primaryIcon={primaryIcon}
          secondaryIcon={secondaryIcon}
        />
      )}
    </div>
  );
};
export default AssignmentDistinctCultureProfileDistinctReviewList;
