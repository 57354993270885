import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import Card from '../Molecules/Card/Card';
import CrossIcon from '@material-ui/icons/Clear';
import { SET_MIDDLEPANE_STATE, CLEAR_DISPLAY_PANE_THREE, SET_DISPLAY_TWO_SINGLE_STATE, SET_MOBILE_PANE_STATE, SET_ASSIGNMENT_RELATED_LIST, SET_DISPLAY_THREE_SINGLE_STATE, FILTERMODE, LOADER_START, ASSIGNMENT_REVISE_SAGA, ASSESSEE_MINE_ASSIGNMENT_LIST } from '../actionType';
import ReviseIcon from '@material-ui/icons/RadioButtonChecked';
import ClearIcon from '@material-ui/icons/Clear';
import Check from '@material-ui/icons/Check';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import { closeRelatedList } from '../Actions/GenericActions';
import { onClickCheckBoxOneListforAssignmentAssessee } from '../Actions/AssesseeModuleAction';

const AssignmentDistinctMineAssesseeDistinctReviewList = () => {

    const dispatch = useDispatch();
    const {
        middlePaneSelectedValue,
        reviewListDistinctData,
        selectedAssociateInfo,
        relatedReviewListDistinctData,
        middlePaneHeaderDuplicate,
        middlePaneHeaderBadgeOne,
        middlePaneHeaderBadgeTwo,
        middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderDuplicateBadgeTwo,
        middlePaneHeaderDuplicateBadgeThree,
        middlePaneHeaderDuplicateBadgeFour,
        middlePaneHeaderDuplicateOne,
        middlePaneHeaderDuplicateOneBadgeOne,
        middlePaneHeaderDuplicateOneBadgeTwo,
        middlePaneHeaderDuplicateOneBadgeThree,
        middlePaneHeaderDuplicateOneBadgeFour,
        typeOfMiddlePaneList,
        selectedTagsArray,
        isSelectActive,
        duplicateScanCount,
        countPage,
        relatedReviewListObjectCopy,
        unselectedTagsArray,
        middlePaneHeader,
        numberPage,
        scanCount
    } = useSelector((state) => state.DisplayPaneTwoReducer);
    const { FilterModeEnable, FilterMode } = useSelector((state) => state.FilterReducer);

    const [isShowReviseIcon, setIsShowReviseIcon] = useState(true);

    const {
        createMode,
        responseObject,
        assignmentAssesseeList = [],
        assignmentAssessmentList = [],
        assignmentCultureProfileList = [],
        assignmentJobProfileList = [],
        assignmentAssesseeGroupList = [],
        assignmentAssessmentGroupList = [],
        assignmentCultureProfileGroupList = [],
        assignmentJobProfileGroupList = [],
        reviewMode
    } = useSelector((state) => state.DisplayPaneThreeReducer);
    const assignmentInfo = useSelector((state) => state.AssignmentReducer);

    const onClickReviseCancel = () => {
        console.log('ON CLICK cancel ICON');
        setIsShowReviseIcon(true);
        // setPrevList();
        if (middlePaneHeaderDuplicateOne !== 'assignments') {
            // at last review corss / revise cancel call pane three reqiured to be cleared
            if (reviewMode === 'review') {
                dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
            }
            dispatch({
                type: SET_MIDDLEPANE_STATE,
                payload: {
                    middlePaneHeader: '',
                    middlePaneHeaderBadgeOne: '',
                    middlePaneHeaderBadgeTwo: '',
                    middlePaneHeaderBadgeThree: '',
                    middlePaneHeaderBadgeFour: '',
                    typeOfMiddlePaneList: '',
                    showMiddlePaneState: false
                }
            });
        }
    };

    const listDistinctData = relatedReviewListDistinctData[0];

    const onClickRevise = () => {
        console.log('ON CLICK REVISE ICON');
        setIsShowReviseIcon(false);
    };
    console.log(createMode, 'CREATEMODE')
    const setPrevList = () => {
        if (createMode === '') {
            closeRelatedList(
                dispatch,
                createMode,
                responseObject,
                middlePaneHeaderDuplicate,
                middlePaneHeaderDuplicateBadgeOne,
                middlePaneHeaderDuplicateBadgeTwo,
                middlePaneHeaderDuplicateBadgeThree,
                middlePaneHeaderDuplicateBadgeFour,
                'assignmentDistinctReviewList',
                duplicateScanCount,
                'assignmentsDistinct', //'assignmentsDistinctinactive',
                duplicateScanCount,
                middlePaneHeaderDuplicateOne,
                middlePaneHeaderDuplicateOneBadgeOne,
                middlePaneHeaderDuplicateOneBadgeTwo,
                middlePaneHeaderDuplicateOneBadgeThree,
                middlePaneHeaderDuplicateOneBadgeFour,
            );
        } else {
            dispatch({
                type: SET_MIDDLEPANE_STATE,
                payload: {
                    middlePaneHeader: '',
                    middlePaneHeaderBadgeOne: '',
                    middlePaneHeaderBadgeTwo: '',
                    middlePaneHeaderBadgeThree: '',
                    middlePaneHeaderBadgeFour: '',
                    typeOfMiddlePaneList: '',
                    scanCount: '',
                    showMiddlePaneState: false
                }
            });
        }
    };

    const onClickReviseFinish = () => {
        setIsShowReviseIcon(true);
        setPrevList();
        if (typeOfMiddlePaneList !== '') {
            dispatch({
                type: FILTERMODE,
                payload: { FilterMode: '' }
            });
        }
        dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'isSelectActive', value: '' }
        });
        dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
       
        dispatch({
            type: SET_DISPLAY_THREE_SINGLE_STATE,
            payload: { stateName: 'assignmentRelatedReviewListPaneThree', value: {} }
        });
        let minseAssesseeList = [];
        listDistinctData?.assessee.forEach((ele)=>{
            selectedTagsArray.forEach((id)=>{
                if(ele.assesseeId === id){
                    let ob = {
                        assesseeId:id,
                        assignmentId:ele.assesseeAssignmentId
                    }
                    minseAssesseeList.push(ob);
                }
            })
        })
        console.log('minseAssesseeList', minseAssesseeList);
        dispatch({
            type: SET_ASSIGNMENT_RELATED_LIST,
            payload: { listName: 'assignmentAssesseeMineDistinct', value: minseAssesseeList }
        });
        const { id } = responseObject;
        let frameworkObj = {
            ...assignmentInfo.informationFramework,
            // assignmentAssesseeDistinct: selectedTagsArray || [],
            assignmentAssesseeMineDistinct: minseAssesseeList || [],
            assignmentAssessmentDistinct: assignmentAssessmentList || [],
            assignmentCultureProfileDistinct: assignmentCultureProfileList || [],
            assignmentJobProfileDistinct: assignmentJobProfileList || [],
            assignmentAssesseeGroup: assignmentAssesseeGroupList || [],
            assignmentAssessmentGroup: assignmentAssessmentGroupList || [],
            assignmentCultureProfileGroup: assignmentCultureProfileGroupList || [],
            assignmentJobProfileGroup: assignmentJobProfileGroupList || []
        };
        const reqBody = {
            module: 'Assignment-Distinct',
            action: 'Revise',
            requestObject: {
                assesseeId: selectedAssociateInfo?.assesseeId,
                associateId:
                    selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
                assignment: {
                    id,
                    informationBasic: assignmentInfo.informationBasic,
                    informationAllocation: assignmentInfo.informationAllocation,
                    informationFramework: frameworkObj,
                    informationSetup: assignmentInfo.informationSetup
                }
            }
        };
        dispatch({ type: LOADER_START });
        dispatch({
            type: ASSIGNMENT_REVISE_SAGA,
            payload: {
                reqBody
            }
        });
        closeRelatedList(
            dispatch,
            createMode,
            responseObject,
            middlePaneHeaderDuplicate,
            middlePaneHeaderDuplicateBadgeOne,
            middlePaneHeaderDuplicateBadgeTwo,
            middlePaneHeaderDuplicateBadgeThree,
            middlePaneHeaderDuplicateBadgeFour,
            'assignmentDistinctReviewList',
            duplicateScanCount,
            'assignmentsDistinct', //'assignmentsDistinctinactive',
            duplicateScanCount,
            middlePaneHeaderDuplicateOne,
            middlePaneHeaderDuplicateOneBadgeOne,
            middlePaneHeaderDuplicateOneBadgeTwo,
            middlePaneHeaderDuplicateOneBadgeThree,
            middlePaneHeaderDuplicateOneBadgeFour,
        );
        dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
    }

    const onClickCheckBoxSelection = (selectedTagsArray, unselectedTagsArray, event, dispatch) => {
        let id = event.target.id;
        let checkedArr = [...selectedTagsArray];
        let unCheckArr = [...unselectedTagsArray];
        if (checkedArr.includes(id)) {
            checkedArr = checkedArr.filter(function (number) {
                return number !== id;
            });
            unCheckArr.push(id);
        } else {
            checkedArr.push(id);
            unCheckArr = unCheckArr.filter(function (number) {
                return number !== id;
            });
        }
        dispatch({
            type: 'SET_DISPLAY_TWO_SINGLE_STATE',
            payload: { stateName: 'selectedTagsArray', value: checkedArr }
        });
        dispatch({
            type: 'SET_DISPLAY_TWO_SINGLE_STATE',
            payload: { stateName: 'unselectedTagsArray', value: unCheckArr }
        });
    };

    const closeRelatedListx = () => {
        // dispatch({
        //     type: SET_MIDDLEPANE_STATE,
        //     payload: {
        //         middlePaneHeader: '',
        //         middlePaneHeaderBadgeOne: '',
        //         middlePaneHeaderBadgeTwo: '',
        //         middlePaneHeaderBadgeThree: '',
        //         middlePaneHeaderBadgeFour: '',
        //         typeOfMiddlePaneList: '',
        //         scanCount: 0,
        //         selectedTagsArray: [],
        //         unselectedTagsArray: [],
        //         showMiddlePaneState: false
        //     }
        // });

        closeRelatedList(
            dispatch,
            createMode,
            responseObject,
            middlePaneHeaderDuplicate,
            middlePaneHeaderDuplicateBadgeOne,
            middlePaneHeaderDuplicateBadgeTwo,
            middlePaneHeaderDuplicateBadgeThree,
            middlePaneHeaderDuplicateBadgeFour,
            'assignmentDistinctReviewList',
            duplicateScanCount,
            'assignmentsDistinct', //'assignmentsDistinctinactive',
            duplicateScanCount,
            middlePaneHeaderDuplicateOne,
            middlePaneHeaderDuplicateOneBadgeOne,
            middlePaneHeaderDuplicateOneBadgeTwo,
            middlePaneHeaderDuplicateOneBadgeThree,
            middlePaneHeaderDuplicateOneBadgeFour,
        );
        dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });

    };

    const revisePrimaryIcon = [{ label: 'revise', onClick: onClickRevise, Icon: ReviseIcon }];

    const reviseSecondaryIcons = [
        { label: 'cancel', onClick: onClickReviseCancel, Icon: ClearIcon },
        { label: 'finish', onClick: onClickReviseFinish, Icon: Check }
    ];
    console.log(listDistinctData, 'listDistinctData')
    return (
        <div>
            {listDistinctData && (
                <Card
                    textOneOne={listDistinctData?.assignmentName}
                    textTwoOne={listDistinctData?.assignmentDescription}
                    IconOne={CrossIcon}
                    isIcon={true}
                    labelTwoTwo={'mine'}
                    onClickIconOne={closeRelatedListx}
                    isAlliance
                    relatedCardFixed={true}
                    className={'iguru-iconboxSVG'}
                />
            )}
            {listDistinctData &&
                listDistinctData?.assessee.map((item, index) => {
                    return (
                        <div className="containerPadding" key={index}>
                            <ReviewList
                                className=""
                                id={item?.assesseeId}
                                tag={item?.assesseeId}
                                associateId={item?.associateId}
                                isSelectedReviewList={
                                    middlePaneSelectedValue === item.assesseeId
                                }
                                status={item?.assesseeAssignmentStatus}
                                actualStatus={item?.assesseeAssignmentStatus}
                                textOne={item?.assesseeData?.informationBasic.assesseeNameFirst +' '+ item?.assesseeData?.informationBasic.assesseeNameLast}
                                textTwo={item?.assesseeData?.informationBasic.assesseeNameOther}
                                isTooltipActive={false}
                                onClickEvent={null}
                                isSelectActive={true}
                                isSelected={selectedTagsArray.includes(item?.assesseeId)}
                                onClickCheckBox={(event) => {
                                    onClickCheckBoxOneListforAssignmentAssessee(
                                        selectedTagsArray,
                                        responseObject?.assesseeAssignmentDistinctId,
                                        event,
                                        dispatch
                                      );
                                }}
                                isDelivery={false}
                            />
                        </div>
                    );
                })}
            {FilterMode === 'assignmentDistinctMineAsseseeDistinct' && (
                // {true && (
                <FooterIconTwo
                    FilterModeEnable={isShowReviseIcon}
                    FilterMode={FilterMode}
                    onClick={onClickRevise}
                    primaryIcon={revisePrimaryIcon}
                    secondaryIcon={reviseSecondaryIcons}
                />
            )}
        </div>
    )
}

export default AssignmentDistinctMineAssesseeDistinctReviewList