import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ASSESSEE_ALLOCATE_GRP_SAGA,
  ASSESSMENT_ALLOCATE_SAGA,
  ASSIGNMENT_ALLOCATE_SAGA,
  ASSOCIATE_ALLOCATE_SAGA,
  CULTUREPROFILE_ALLOCATE_SAGA,
  FILTERMODE,
  FILTERMODE_ENABLE,
  ITEM_ALLOCATE_SAGA,
  JOBPROFILE_ALLOCATE_SAGA,
  LOADER_START,
  POPUP_OPEN,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_POPUP_SINGLE_STATE,
  SET_POPUP_STATE
} from '../actionType';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import { FilterList, AccountTree } from '@material-ui/icons';
import ListIcon from '@material-ui/icons/FormatListBulleted';
import ReviseIcon from '@material-ui/icons/RadioButtonChecked';
import Check from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import {
  ASSESSEE_NODE_ROLE_REVIEW_LIST_POPUP_OPTION,
  ASSESSMENT_NODE_TYPE_REVIEW_LIST_POPUP_OPTION,
  ASSIGNMENT_NODE_TYPE_REVIEW_LIST_POPUP_OPTION,
  ASSOCIATE_NODE_ROLE_REVIEW_LIST_POPUP_OPTION,
  NODE_ROLE_TYPE_REVIEW_LIST_POPUP_OPTION
} from '../PopUpConfig';
import SortableTree from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import FileExplorerTheme from 'react-sortable-tree-theme-full-node-drag';
import '../reactSortableTree.css';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import { Fragment } from 'react';
import { getInternalNodeApiCall } from '../Actions/AssociateModuleAction';
import Card from '../Molecules/Card/Card';
import {
  onClickCheckBoxSelection,
  onClickFlagSelection,
  setFlagedArray,
  setFlagedArrayAssociateNode
} from '../Actions/AssesseeModuleAction';
import { getPopUpOptionList } from '../Actions/GenericActions';
import ListLoader from '../Atoms/ListLoader/ListLoader';
const AssociateNodeReviewList = (props) => {
  const dispatch = useDispatch();
  const { cardValue } = useSelector((state) => state.PopUpReducer);
  const {
    reviewListDistinctData,
    middlePaneSelectedValue,
    selectedAssociateInfo,
    nodeViewState,
    scanString,
    searchFocusIndex,
    middlePaneHeader,
    middlePaneHeaderBadgeOne,
    middlePaneHeaderBadgeTwo,
    middlePaneHeaderBadgeThree,
    isSelectActive,
    selectedTagsArray,
    unselectedTagsArray,
    allocatedTagsArray,
    allocateStr,
    selectedFlagedArray,
    unselectedFlagedArray,
    flagedValue,
    signedAssesseePermission,
    countPage,
    isListLoading
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { FilterModeEnable, FilterMode } = useSelector((state) => state.FilterReducer);
  const [filterState, setFilterState] = useState('');

  const onClickFooter = (e) => {
    let siftValue = e.currentTarget.getAttribute('data-value');
    setFilterState(siftValue);
    dispatch({ type: FILTERMODE_ENABLE });
    if (siftValue === 'list' || siftValue === 'hierarchy') {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'nodeViewState', value: siftValue }
      });
      // dispatch({
      //   type: SET_POPUP_SINGLE_STATE,
      //   payload: { stateName: 'selectionValue', value: '' }
      // });
      let badgeTwo =
        middlePaneHeaderBadgeTwo === 'generic' || middlePaneHeaderBadgeTwo === 'bespoke'
          ? 'active'
          : middlePaneHeaderBadgeTwo;

      if (cardValue === 'Card') {
        badgeTwo =
          middlePaneHeaderBadgeThree === 'generic' || middlePaneHeaderBadgeThree === 'bespoke'
            ? 'active'
            : middlePaneHeaderBadgeThree;
      }
      getInternalNodeApiCall(
        selectedAssociateInfo,
        middlePaneHeaderBadgeThree? middlePaneHeaderBadgeThree: badgeTwo,
        countPage,
        dispatch,
        middlePaneHeaderBadgeOne,
        middlePaneHeaderBadgeThree,
        siftValue,
        middlePaneHeader,
        isSelectActive,
        selectedTagsArray
      );
    }
    if (
      siftValue === 'suspended' ||
      siftValue === 'terminated' ||
      siftValue === 'bespoke' ||
      siftValue === 'generic'
    ) {
      getInternalNodeApiCall(
        selectedAssociateInfo,
        siftValue,
        countPage,
        dispatch,
        middlePaneHeaderBadgeOne,
        '',
        nodeViewState,
        middlePaneHeader,
        isSelectActive,
        selectedTagsArray,
        '',
        FilterMode
      );
    }
    if (siftValue === 'finish') {
      console.log('allocateStr', allocateStr);
      let distinctAllocateStr =
        allocateStr === 'assessmentsdistinct'
          ? 'assessmentDistinct'
          : allocateStr === 'assessmentsgroups'
          ? 'assessmentGroup'
          : allocateStr === 'assessmentstypes'
          ? 'assessmentType'
          : allocateStr === 'assessmentsnodes'
          ? 'assessmentNode'
          : allocateStr === 'assignmentsdistinct'
          ? 'assignmentDistinct'
          : allocateStr === 'assignmentsgroups'
          ? 'assignmentGroup'
          : allocateStr === 'assignmentstypes'
          ? 'assignmentType'
          : allocateStr === 'assignmentsnodes'
          ? 'assignmentNode'
          : allocateStr === 'itemsdistinct'
          ? 'itemDistinct'
          : allocateStr === 'itemsgroups'
          ? 'itemGroup'
          : allocateStr === 'itemsnodes'
          ? 'itemNode'
          : allocateStr === 'itemstypes'
          ? 'itemType'
          : allocateStr === 'assesseesdistinct'
          ? 'assesseeDistinct'
          : allocateStr === 'assesseesgroups'
          ? 'assesseeGroup'
          : allocateStr === 'assesseestypes'
          ? 'assesseeType'
          : allocateStr === 'assesseesroles'
          ? 'assesseeRole'
          : allocateStr === 'assesseesnodes'
          ? 'assesseeNode'
          : allocateStr === 'administratorsgroups'
          ? 'assesseeGroup'
          : allocateStr === 'administratorsdistinct'
          ? 'assesseeDistinct'
          : allocateStr === 'administratorstypes'
          ? 'assesseeType'
          : allocateStr === 'administratorsroles'
          ? 'assesseeRole'
          : allocateStr === 'administratorsnodes'
          ? 'assesseeNode'
          : allocateStr === 'associatesdistinct'
          ? 'associateDistinct'
          : allocateStr === 'associatesgroups'
          ? 'associateGroup'
          : allocateStr === 'associatesnodes'
          ? 'associateNode'
          : allocateStr === 'associatesroles'
          ? 'associateRole'
          : allocateStr === 'associatestypes'
          ? 'associateType'
          : allocateStr === 'culture-profilesdistinct'
          ? 'cultureProfileDistinct'
          : allocateStr === 'cultureprofilesdistinct'
          ? 'cultureProfileDistinct'
          : allocateStr === 'culture-profilesgroups'
          ? 'cultureProfileGroup'
          : allocateStr === 'culture-profilesnodes'
          ? 'cultureProfileNode'
          : allocateStr === 'culture-profilestypes'
          ? 'cultureProfileType'
          : allocateStr === 'job-profilesdistinct'
          ? 'jobProfileDistinct'
          : allocateStr === 'jobprofilesdistinct'
          ? 'jobProfileDistinct'
          : allocateStr === 'job-profilesgroups'
          ? 'jobProfileGroup'
          : allocateStr === 'job-profilesnodes'
          ? 'jobProfileNode'
          : allocateStr === 'job-profilestypes'
          ? 'jobProfileType'
          : '';

      if (distinctAllocateStr !== '' && selectedTagsArray.length !== 0) {
        if (
          distinctAllocateStr === 'assessmentDistinct' ||
          distinctAllocateStr === 'assessmentGroup' ||
          distinctAllocateStr === 'assessmentType' ||
          distinctAllocateStr === 'assessmentNode'
        ) {
          let request = {
            module: 'Assessment-Distinct',
            action: FilterMode.toLowerCase().includes('unallocate') ? 'Unallocate' : 'Allocate',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              [FilterMode.toLowerCase().includes('unallocate')
                ? 'assessmentDistinctUnallocate'
                : 'assessmentDistinctAllocate']: {
                [distinctAllocateStr]: allocatedTagsArray
              },
              [FilterMode.toLowerCase().includes('unallocate')
                ? 'assessmentDistinctUnallocateInformation'
                : 'assessmentDistinctAllocateInformation']: {
                assessmentNode: selectedTagsArray
              }
            }
          };
          dispatch({ type: LOADER_START });
          dispatch({ type: ASSESSMENT_ALLOCATE_SAGA, payload: { request: request } });
          // dispatch({
          //   type: FILTERMODE,
          //   payload: { FilterMode: '' }
          // });
        }
        // if (distinctAllocateStr === 'itemDistinct') {
        //   let request = {
        //     assesseeId: selectedAssociateInfo?.assesseeId,
        //     associateId:
        //       selectedAssociateInfo?.associate?.informationEngagement.associateTag
        //         .associateTagPrimary,
        //     itemDistinctAllocate: allocatedTagsArray,
        //     itemDistinctAllocateInformation: {
        //       itemNode: selectedTagsArray
        //     }
        //   };
        //   console.log(request);
        //   dispatch({ type: LOADER_START });
        //   dispatch({ type: ITEM_ALLOCATE_SAGA, payload: { request: request } });
        //   dispatch({
        //     type: FILTERMODE,
        //     payload: { FilterMode: '' }
        //   });
        // }
        if (
          distinctAllocateStr === 'itemDistinct' ||
          distinctAllocateStr === 'itemGroup' ||
          distinctAllocateStr === 'itemNode' ||
          distinctAllocateStr === 'itemType'
        ) {
          let request = {
            module: 'Item-Distinct',
            action: FilterMode.toLowerCase().includes('unallocate') ? 'Unallocate' : 'Allocate',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              itemDistinctAllocate: {
                [distinctAllocateStr]: allocatedTagsArray
              },
              itemDistinctAllocateInformation: {
                itemNode: selectedTagsArray
              }
            }
          };
          dispatch({ type: LOADER_START });
          dispatch({ type: ITEM_ALLOCATE_SAGA, payload: { request: request } });
          // dispatch({
          //   type: FILTERMODE,
          //   payload: { FilterMode: '' }
          // });
        }
        if (
          distinctAllocateStr === 'assignmentDistinct' ||
          distinctAllocateStr === 'assignmentGroup' ||
          distinctAllocateStr === 'assignmentType' ||
          distinctAllocateStr === 'assignmentNode'
        ) {
          let request = {
            module: 'Assignment-Distinct',
            action: FilterMode.toLowerCase().includes('unallocate') ? 'Unallocate' : 'Allocate',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              [FilterMode?.toLowerCase().includes('unallocate')
                ? 'assignmentDistinctUnallocate'
                : 'assignmentDistinctAllocate']: {
                [distinctAllocateStr]: allocatedTagsArray
              },
              [FilterMode?.toLowerCase().includes('unallocate')
                ? 'assignmentDistinctUnallocateInformation'
                : 'assignmentDistinctAllocateInformation']: {
                assignmentNode: selectedTagsArray
              }
            }
          };
          dispatch({ type: LOADER_START });
          dispatch({ type: ASSIGNMENT_ALLOCATE_SAGA, payload: { request: request } });
          // dispatch({
          //   type: FILTERMODE,
          //   payload: { FilterMode: '' }
          // });
        }
        if (
          distinctAllocateStr === 'assesseeDistinct' ||
          distinctAllocateStr === 'assesseeGroup' ||
          distinctAllocateStr === 'assesseeType' ||
          distinctAllocateStr === 'assesseeRole' ||
          distinctAllocateStr === 'assesseeNode'
        ) {
          let request = {
            module: 'Assessee-Distinct',
            action: FilterMode.toLowerCase().includes('unallocate') ? 'Unallocate' : 'Allocate',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              [FilterMode.toLowerCase().includes('unallocate')
                ? 'assesseeDistinctUnallocate'
                : 'assesseeDistinctAllocate']: {
                [distinctAllocateStr]: allocatedTagsArray
              },
              [FilterMode.toLowerCase().includes('unallocate')
                ? 'assesseeDistinctUnallocateInformation'
                : 'assesseeDistinctAllocateInformation']: {
                assesseeNode: selectedTagsArray
              }
            }
          };
          dispatch({ type: LOADER_START });
          dispatch({ type: ASSESSEE_ALLOCATE_GRP_SAGA, payload: { request: request } });
        }
        if (
          distinctAllocateStr === 'associateDistinct' ||
          distinctAllocateStr === 'associateGroup' ||
          distinctAllocateStr === 'associateNode' ||
          distinctAllocateStr === 'associateRole' ||
          distinctAllocateStr === 'associateType'
        ) {
          let request = {
            module: 'Associate-Distinct',
            action: FilterMode.toLowerCase().includes('unallocate') ? 'Unallocate' : 'Allocate',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              associateDistinctAllocate: {
                [distinctAllocateStr]: allocatedTagsArray
              },
              associateDistinctAllocateInformation: {
                associateNode: selectedTagsArray
              }
            }
          };
          dispatch({ type: LOADER_START });
          dispatch({ type: ASSOCIATE_ALLOCATE_SAGA, payload: { request: request } });
          // dispatch({
          //   type: FILTERMODE,
          //   payload: { FilterMode: '' }
          // });
        }
        if (
          distinctAllocateStr === 'cultureProfileDistinct' ||
          distinctAllocateStr === 'cultureProfileGroup' ||
          distinctAllocateStr === 'cultureProfileNode' ||
          distinctAllocateStr === 'cultureProfileType'
        ) {
          let request = {
            module: 'Culture-Profile-Distinct',
            action: FilterMode.toLowerCase().includes('unallocate') ? 'Unallocate' : 'Allocate',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              cultureProfileDistinctAllocate: {
                [distinctAllocateStr]: allocatedTagsArray
              },
              cultureProfileDistinctAllocateInformation: {
                cultureProfileNode: selectedTagsArray
              }
            }
          };
          dispatch({ type: LOADER_START });
          dispatch({ type: CULTUREPROFILE_ALLOCATE_SAGA, payload: { request: request } });
        }
        if (
          distinctAllocateStr === 'jobProfileDistinct' ||
          distinctAllocateStr === 'jobProfileGroup' ||
          distinctAllocateStr === 'jobProfileNode' ||
          distinctAllocateStr === 'jobProfileType'
        ) {
          let request = {
            module: 'Job-Profile-Distinct',
            action: FilterMode.toLowerCase().includes('unallocate') ? 'Unallocate' : 'Allocate',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              jobProfileDistinctAllocate: {
                [distinctAllocateStr]: allocatedTagsArray
              },
              jobProfileDistinctAllocateInformation: {
                jobProfileNode: selectedTagsArray
              }
            }
          };
          dispatch({ type: LOADER_START });
          dispatch({ type: JOBPROFILE_ALLOCATE_SAGA, payload: { request: request } });
        }
      }
    }
    if (siftValue === 'cancel') {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isSelectActive', value: '' }
      });
      dispatch({
        type: FILTERMODE,
        payload: { FilterMode: '' }
      });
    }
  };

  /* for middle pane */
  const secondaryIconOne = [
    { label: 'suspended', onClick: onClickFooter, Icon: FilterList },
    { label: 'terminated', onClick: onClickFooter, Icon: FilterList }
  ];
  const secondaryOneIconSift = [
    { label: 'bespoke', onClick: onClickFooter, Icon: FilterList },
    { label: 'generic', onClick: onClickFooter, Icon: FilterList }
  ];

  /* for middle pane */
  const primaryIconOne = [
    { label: 'sift', onClick: onClickFooter, Icon: FilterList },
    { label: 'view', onClick: onClickFooter, Icon: FilterList }
  ];
  /* for middle pane */
  const primaryIcon = [{ label: 'sift', onClick: onClickFooter, Icon: FilterList }];
  const secondaryIcon = [
    { label: 'hierarchy', onClick: onClickFooter, Icon: AccountTree },
    { label: 'list', onClick: onClickFooter, Icon: ListIcon }
  ];

  const openNodeListPopup = (node, event, target, canUpdate, id) => {
    // debugger
    console.log(target);
    console.log(event);
    console.log('node--', node);
    let selectedGroup = {};
    let popupContentArrValue = '';
    let nodeId = node;
    let nodeStatus = event.currentTarget.getAttribute('status');
    let classification = event.currentTarget.getAttribute('data-classification');
    if (target === 'hirarchy') {
      nodeId = node.node.id;
      nodeStatus = node.node.status;
      classification = node.node.classification;
    }
    let optArr = [...NODE_ROLE_TYPE_REVIEW_LIST_POPUP_OPTION];
    if (
      middlePaneHeader === 'assessees' ||
      middlePaneHeader === 'administrators' ||
      middlePaneHeader === 'assistants' ||
      middlePaneHeader === 'managers' ||
      middlePaneHeader === 'culture-profiles' ||
      middlePaneHeader === 'job-profiles' ||
      middlePaneHeader === 'items'
    ) {
      let reviseHeader = middlePaneHeader;

      //let popupContentArrValue = ASSESSEE_NODE_ROLE_REVIEW_LIST_POPUP_OPTION.map((obj) =>
      let popupContentArr = ASSESSEE_NODE_ROLE_REVIEW_LIST_POPUP_OPTION.map((obj) =>
        obj.data === 'assessees' ? { ...obj, data: middlePaneHeader, dataValue: reviseHeader } : obj
      );
      optArr =
        cardValue === 'Card' ? NODE_ROLE_TYPE_REVIEW_LIST_POPUP_OPTION : popupContentArr;
      let associateDistinctPermission =
        signedAssesseePermission?.associateDistinctPermission?.assesseeAssociateNodePermission ||
        {};
      popupContentArrValue = getPopUpOptionList(optArr, associateDistinctPermission);
    }

    if (middlePaneHeader === 'assessments') {
      optArr = [...ASSESSMENT_NODE_TYPE_REVIEW_LIST_POPUP_OPTION];
      let associateDistinctPermission =
        signedAssesseePermission?.associateDistinctPermission?.assesseeAssociateNodePermission ||
        {};
      popupContentArrValue = getPopUpOptionList(optArr, associateDistinctPermission);
    }
    if (middlePaneHeader === 'assignments') {
      optArr = [...ASSIGNMENT_NODE_TYPE_REVIEW_LIST_POPUP_OPTION];
      let associateDistinctPermission =
        signedAssesseePermission?.associateDistinctPermission?.assesseeAssociateNodePermission ||
        {};
      popupContentArrValue = getPopUpOptionList(optArr, associateDistinctPermission);
    }
    if (middlePaneHeader === 'associates') {
      optArr = [...ASSOCIATE_NODE_ROLE_REVIEW_LIST_POPUP_OPTION];
      let associateDistinctPermission =
        signedAssesseePermission?.associateDistinctPermission?.assesseeAssociateNodePermission ||
        {};
      popupContentArrValue = getPopUpOptionList(optArr, associateDistinctPermission);
    }
    if (middlePaneHeader === 'associate') {
      let associateDistinctPermission =
        signedAssesseePermission?.associateDistinctPermission?.assesseeAssociateNodePermission;
      popupContentArrValue = getPopUpOptionList(optArr, associateDistinctPermission);
    }
    // popupContentArrValue = popupContentArrValue.map((elem) => {
    //   // console.log(!node.node.activeNodePermission[elem.permissionValue], elem, elem.permissionValue, 'elem');
    //   let disabled = false;
    //   if (node.node.activeNodePermission.hasOwnProperty(elem.permissionValue)) {
    //     disabled = !node.node.activeNodePermission[elem.permissionValue];
    //   }
    //   return { ...elem, disabled: disabled };
    // });
    let tempArr = [];
    let poupArr = [];
    // need to verify....
    console.log('**** popupContentArrValue', popupContentArrValue);
    popupContentArrValue.forEach((element) => {
      if (nodeViewState === 'list') {
        if (node.hasOwnProperty(element.permissionValue)) {
          //revise
          // console.log('value of ',element.permissionValue, node[element.permissionValue]);
          let disabled = !node[element.permissionValue];
          poupArr.push({ ...element, disabled: disabled });
        } else poupArr.push(element);
      } else {
        if (node.node.activeNodePermission.hasOwnProperty(element.permissionValue)) {
          let disabled = !node.node.activeNodePermission[element.permissionValue];
          poupArr.push({ ...element, disabled: disabled });
        } else poupArr.push(element);
      }
    });
    console.log('after node permission check', poupArr);
    let checkPoupArr = [];
    let rootNodeId = reviewListDistinctData[0]?.associateNodeRoot?.associateNode?.id;
    poupArr.forEach((element) => {
      if (
        nodeViewState === 'list' &&
        rootNodeId === node &&
        (element.data === 'archive' ||
          element.data === 'delete' ||
          element.data === 'share' ||
          element.data === 'suspend' ||
          element.data === 'terminate')
      ) {
        console.log(element.data, 'is disable this is root node');
        checkPoupArr.push({ ...element, disabled: true });
      } else if (
        nodeViewState === 'hierarchy' &&
        node.parentNode === null &&
        (element.data === 'archive' ||
          element.data === 'delete' ||
          element.data === 'share' ||
          element.data === 'suspend' ||
          element.data === 'terminate')
      ) {
        console.log(element.data, 'is disable this is root node');
        checkPoupArr.push({ ...element, disabled: true });
      } else checkPoupArr.push(element);
    });
    console.log('after root node check', checkPoupArr);
    checkPoupArr.forEach((element) => {
      if (
        (nodeStatus === 'TERMINATED' || nodeStatus === 'SUSPENDED' || nodeStatus === 'ARCHIVED') &&
        (element.data === 'allocate' ||
          element.data === 'revise' ||
          element.data === 'select' ||
          element.data === 'share')
      ) {
        tempArr.push({ ...element, disabled: true });
      } else if (element.data === 'share')
        tempArr.push({ ...element, disabled: true });
      else tempArr.push(element);
    });
    console.log('nodeViewState----', nodeViewState);
    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: middlePaneHeader,
        popupHeaderOneBadgeOne: 'node',
        popupHeaderOneBadgeTwo: '',
        isPopUpValue: '',
        popupOpenType: 'primary',
        popupContentArrValue: tempArr,
        // popupContentArrValue:
        //   cardValue === 'Card'
        //     ? NODE_ROLE_TYPE_REVIEW_LIST_POPUP_OPTION
        //     : popupContentArrValue, //optArr,
        selectedTagValue: nodeViewState === 'list' ? id : node.node.id,
        selectedTagStatus: nodeStatus
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: cardValue === 'Card' ? NODE_ROLE_TYPE_REVIEW_LIST_POPUP_OPTION : tempArr //optArr
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
    console.log(selectedGroup);
  };
  const changedNode = (node) => {
    console.log(node.id);
    let dragedNodeId = node.id;
    let dragedNodeParentId = '';
    reviewListDistinctData.map((nodeData) => {
      console.log('nodeData', nodeData);
      nodeData.children.map((chnode) => {
        console.log(chnode.id);
        if (dragedNodeId === chnode.id) {
          dragedNodeParentId = nodeData.id;
        }
      });
    });
  };
  // useEffect(() => {
  //   // dispatch({
  //   //   type: SET_DISPLAY_TWO_SINGLE_STATE,
  //   //   payload: { stateName: 'scanString', value: 'scanString' }
  //   // });
  // }, [reviewListDistinctData]);
  useEffect(() => {
    if (nodeViewState !== 'hierarchy') {
      let associateNodeDescendantAll = reviewListDistinctData[0]?.associateNodeDescendantAll || [];
      setFlagedArrayAssociateNode(associateNodeDescendantAll, 'associateNodeFlag', dispatch);
    }
  }, [reviewListDistinctData]);

  const customSearchMethod = ({ node, searchQuery }) =>
    searchQuery &&
    (node?.title?.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
      node?.subtitle?.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1);

  let signedAssociateId =
    selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary;
  console.log('FilterMode', FilterMode);

  let filterModeOptions = {
    assessmentAllocateToNode: 'allocate',
    itemAllocateToNode: 'allocate',
    assignmentAllocateToNode: 'allocate',
    assesseeAllocateToNode: 'allocate',
    assesseeUnAllocateToNode: 'unallocate',
    associatesAllocateToNode: 'allocate',
    associatesAllocateToDistinct: 'allocate',
    cultureProfileAllocateToNode: 'allocate',
    jobProfileAllocateToNode: 'allocate',
    jobProfileUnAllocateToNode: 'unallocate',
    associatesUnAllocateToDistinct: 'unallocate',
    assessmentUnAllocateToNode: 'unallocate',
    assignmentUnAllocateToNode: 'unallocate',
    associatesUnAllocateToNode: 'unallocate',
    itemUnAllocateToNode: 'unallocate',
    cultureProfileUnAllocateToNode: 'unallocate'
  };

  console.log('--', reviewListDistinctData);
  return (
    <div>
      {reviewListDistinctData.length > 0 && (
        <>
          {nodeViewState === 'hierarchy' ? (
            <div style={{ minheight: 'calc(100vh - 135px)' }} key={scanString}>
              <SortableTree
                treeData={reviewListDistinctData}
                onChange={(treeData) => {
                  treeData.length === 1 &&
                    dispatch({
                      type: SET_DISPLAY_TWO_SINGLE_STATE,
                      payload: { stateName: 'reviewListDistinctData', value: treeData }
                    });
                }}
                searchMethod={customSearchMethod}
                searchQuery={scanString}
                searchFocusOffset={searchFocusIndex}
                // canDrag={({ node }) => true && node.parentId !== null}
                // onMoveNode={({ node }) => changedNode(node)}
                searchFinishCallback={(matches) => {
                  console.log(matches);
                  dispatch({
                    type: SET_DISPLAY_TWO_SINGLE_STATE,
                    payload: {
                      stateName: 'searchFocusIndex',
                      value: matches.length > 0 ? searchFocusIndex % matches.length : 0
                    }
                  });
                }}
                theme={FileExplorerTheme}
                isVirtualized={false}
                rowHeight={55}
                scaffoldBlockPxWidth={31}
                slideRegionSize={50}
                generateNodeProps={(node) => ({
                  onClick: (event) => {
                    if (event.target.type !== 'button') {
                      openNodeListPopup(node, event, 'hirarchy', true);
                    }
                  }
                })}
              />
            </div>
          ) : (
            <Fragment>
              {reviewListDistinctData && (
                <div className="containerPadding">
                  <Card
                    textOneOne={
                      reviewListDistinctData[0].associateNodeRoot.associateNode.informationBasic
                        .associateNodeName
                    }
                    textTwoOne={
                      reviewListDistinctData[0].associateNodeRoot.associateNode.informationBasic
                        .associateNodeDescription
                    }
                    isIcon={false}
                    labelTwoTwo={''}
                    onClickIconOne={null}
                    onClick={(event) => {
                      openNodeListPopup(
                        reviewListDistinctData[0].associateNodeRoot.associateNode.id,
                        event,
                        'list',
                        true
                      );
                    }}
                    isAlliance
                    className={'iguru-iconboxSVG'}
                  />
                </div>
              )}
              {reviewListDistinctData &&
                reviewListDistinctData[0].associateNodeDescendantAll.map((item, index) => {
                  return (
                    <div className="containerPadding" key={index}>
                      <ReviewList
                        className=""
                        id={index}
                        tag={item.associateNode.id}
                        isSelectedReviewList={middlePaneSelectedValue === item.associateNode.id}
                        //status={item.associateNode.informationEngagement.associateNodeStatus}
                        status={
                          FilterMode === 'associateNodeDistinctactive' ||
                          FilterMode === 'associateNodeDistinctbespoke' ||
                          FilterMode === 'associateNodeDistinctgeneric'
                            ? item?.associateNode?.informationSetup?.associateNodeClassification
                                ?.associateNodeClassificationPrimary
                            : item.associateNode.informationEngagement.associateNodeStatus
                        }
                        actualStatus={item.associateNode.informationEngagement.associateNodeStatus}
                        textOne={item.associateNode.informationBasic.associateNodeName}
                        textTwo={item.associateNode.informationBasic.associateNodeDescription}
                        isTooltipActive={false}
                        onClickEvent={(event) => {
                          openNodeListPopup(
                            item.permission,
                            event,
                            'list',
                            true,
                            item.associateNode.id
                          ); //
                        }}
                        isShared={item?.associateNodeShared}
                        shared={
                          item.associateNode.informationSetup?.associateNodeClassification
                            ?.associateNodeClassificationPrimary
                        }
                        isSelectActive={isSelectActive}
                        isSelected={selectedTagsArray.includes(item.associateNode.id)}
                        onClickCheckBox={(event) => {
                          !isListLoading &&
                            onClickCheckBoxSelection(
                              selectedTagsArray,
                              unselectedTagsArray,
                              event,
                              dispatch
                            );
                        }}
                        // disableonClickCheckBox={
                        //   item.associateNode.associateId !== signedAssociateId ? true : false
                        // }
                        isFlagActive={selectedFlagedArray.includes(item.associateNode.id)}
                        flagedValue={flagedValue}
                        onClickAddFladed={(event) => {
                          onClickFlagSelection(
                            selectedFlagedArray,
                            unselectedFlagedArray,
                            event,
                            dispatch
                          );
                        }}
                      />
                    </div>
                  );
                  // }
                })}
            </Fragment>
          )}
        </>
      )}
      {isListLoading ? (
        <div>
          {' '}
          <ListLoader />{' '}
        </div>
      ) : <div style={{ height: "60px", width: "100%" }}></div>}
      {filterModeOptions.hasOwnProperty(FilterMode) ? (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={[
            { label: `${filterModeOptions[FilterMode]}`, onClick: onClickFooter, Icon: ReviseIcon }
          ]}
          secondaryIcon={[
            { label: 'cancel', onClick: onClickFooter, Icon: ClearIcon },
            { label: 'finish', onClick: onClickFooter, Icon: Check }
          ]}
        />
      ) : FilterMode === 'associateNodeDistinctinactive' ||
        FilterMode === 'associateNodeDistinctterminated' ||
        FilterMode === 'associateNodeDistinctsuspended' ? (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={primaryIcon}
          secondaryIcon={secondaryIconOne}
        />
      ) : (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={
            FilterMode === 'associateNodeDistinctarchived' ||
            FilterMode === 'associateNodeDistinctall'
              ? ''
              : primaryIconOne
          }
          secondaryIcon={filterState === 'sift' ? secondaryOneIconSift : secondaryIcon}
        />
      )}
      {/* )} */}
    </div>
  );
};
export default AssociateNodeReviewList;
