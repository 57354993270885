import React from 'react';
import { isMobile } from 'react-device-detect';
// import AllocationAccordian from '../Accordian/AllocationAccordian';
// import Manuscript from '@material-ui/icons/Description';
import { useDispatch, useSelector } from 'react-redux';
import DisplayPanelAccordianReviewListOne from '../Accordian/DisplayPanelAccordianReviewListOne';
import DisplayPanelAccordianInformation from '../Accordian/DisplayPanelAccordianInformation';
import { Paper } from '@material-ui/core';
import {
  FILTERMODE,
  GET_ALLOCATE_ASSESSEE,
  GET_ALLOCATE_ASSESSEE_GROUP,
  GET_ALLOCATE_ASSESSMENT,
  GET_ALLOCATE_ASSIGNMENT,
  GET_ALLOCATE_ASSOCIATE,
  GET_ALLOCATE_CULTURE,
  GET_ALLOCATE_ITEM,
  GET_ALLOCATE_JOB,
  LOADER_START,
  SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_PAGE_COUNT,
  SET_POPUP_VALUE
} from '../../actionType';
import DisplayPanelAccordianReviewListTwo from '../Accordian/DisplayPanelAccordianReviewListTwo';
import {
  makeAssesseeReviewListRequestObject,
  makeAssessmentReviewListRequestObject,
  makeAssignmentReviewListRequestObject,
  makeAssociateReviewListRequestObject,
  makeCultureProfileObj,
  makeInternalNodeObj,
  makeItemObj,
  makeJobProfileObj
} from '../../Actions/GenericActions';
import { getAssesseeNodeAssesseeDistinctApiCall } from '../../Actions/AssesseeModuleAction';
import {
  getAssociateDistinctToAllocate,
  getAssociateGroupAssociateDistinctApiCall,
  getNodeRelatedAssociateDistinctApiCall
} from '../../Actions/AssociateModuleAction';
import { getNodeRelatedAssignmentsDistinctApiCall } from '../../Actions/AssignmentModuleAction';
import { getNodeRelatedAssessmentsDistinctApiCall } from '../../Actions/AssessmentModuleAction';
import { getNodeRelatedItemsDistinctApiCall } from '../../Actions/ItemModuleAction';
import { getCultureProfileNodeCultureProfileApiCall } from '../../Actions/ActionCultureProfile';
import { getJobProfileNodeJobProfileApiCall } from '../../Actions/ActionJobProfile';

const DisplayPaneThreeSectionTwoAssociateNode = () => {
  // const [listExpand, setListExpand] = useState('');
  const {
    reviewMode,
    relatedReviewListPaneThree = [],
    selectedModule,
    createMode,
    responseObject
  } = useSelector((state) => state.DisplayPaneThreeReducer);
  const { countPage, selectedAssociateInfo, assignmentCreateType } = useSelector((state) => state.DisplayPaneTwoReducer);
  const dispatch = useDispatch();
  const { informationFramework } = responseObject;
  console.log('relatedReviewListPaneThree', relatedReviewListPaneThree);
  let ascendantAll = [];
  let ascendantPrimary = [];
  let ascendantSecondary = [];
  if (informationFramework && informationFramework.associateNodeAscendant) {
    if (
      informationFramework.associateNodeAscendant.associateNodeAscendantAll &&
      informationFramework.associateNodeAscendant.associateNodeAscendantAll.length > 0
    ) {
      informationFramework.associateNodeAscendant.associateNodeAscendantAll.forEach((ob) => {
        ascendantAll.push({
          id: ob?.id + '-',
          textOne: ob?.informationBasic?.associateNodeName || '',
          textTwo: ob?.informationBasic?.associateNodeDescription || '',
          status: ''
        });
      });
    }
    if (informationFramework.associateNodeAscendant.associateNodeAscendantPrimary) {
      let p1 = informationFramework.associateNodeAscendant.associateNodeAscendantPrimary;
      if (Array.isArray(p1)) {
        ascendantPrimary.push({
          id: p1[0].id,
          textOne: p1[0]?.informationBasic?.associateNodeName || '',
          textTwo: p1[0]?.informationBasic?.associateNodeDescription || '',
          status: ''
        });
        ascendantPrimary.push(p1[0]);
      } else {
        ascendantPrimary.push({
          id: p1.id,
          textOne: p1?.informationBasic?.associateNodeName || '',
          textTwo: p1?.informationBasic?.associateNodeDescription || '',
          status: ''
        });
      }
      // console.log('primary ======>', ascendantPrimary);
      // informationFramework.associateNodeAscendant.associateNodeAscendantPrimary.forEach((ob) => {
      //   ascendantPrimary.push({
      //     id: ob?.id + '-',
      //     textOne: ob?.informationBasic?.associateNodeName || '',
      //     textTwo: ob?.informationBasic?.associateNodeDescription || '',
      //     status: ''
      //   });
      // });
    }
    if (
      informationFramework.associateNodeAscendant.associateNodeAscendantSecondary &&
      informationFramework.associateNodeAscendant.associateNodeAscendantSecondary.length > 0
    ) {
      informationFramework.associateNodeAscendant.associateNodeAscendantSecondary.forEach((ob) => {
        ascendantSecondary.push({
          id: ob?.id + '-',
          textOne: ob?.informationBasic?.associateNodeName || '',
          textTwo: ob?.informationBasic?.associateNodeDescription || '',
          status: ''
        });
      });
    }
  }
  let descendantAll = [];
  let descendantPrimary = [];
  let descendantSecondary = [];
  if (informationFramework && informationFramework.associateNodeDescendant) {
    if (
      informationFramework.associateNodeDescendant.associateNodeDescendantAll &&
      informationFramework.associateNodeDescendant.associateNodeDescendantAll.length > 0
    ) {
      informationFramework.associateNodeDescendant.associateNodeDescendantAll.forEach((ob) => {
        descendantAll.push({
          id: ob?.id + '-',
          textOne: ob?.informationBasic?.associateNodeName || '',
          textTwo: ob?.informationBasic?.associateNodeDescription || '',
          status: ''
        });
      });
    }
    if (
      informationFramework.associateNodeDescendant.associateNodeDescendantPrimary &&
      typeof informationFramework.associateNodeDescendant.associateNodeDescendantPrimary !==
        'string' &&
      informationFramework.associateNodeDescendant.associateNodeDescendantPrimary.length > 0
    ) {
      informationFramework.associateNodeDescendant.associateNodeDescendantPrimary.forEach((ob) => {
        descendantPrimary.push({
          id: ob?.id + '-',
          textOne: ob?.informationBasic?.associateNodeName || '',
          textTwo: ob?.informationBasic?.associateNodeDescription || '',
          status: ''
        });
      });
    }
    if (
      informationFramework.associateNodeDescendant.associateNodeDescendantSecondary &&
      informationFramework.associateNodeDescendant.associateNodeDescendantSecondary.length > 0
    ) {
      informationFramework.associateNodeDescendant.associateNodeDescendantSecondary.forEach(
        (ob) => {
          descendantSecondary.push({
            id: ob?.id + '-',
            textOne: ob?.informationBasic?.associateNodeName || '',
            textTwo: ob?.informationBasic?.associateNodeDescription || '',
            status: ''
          });
        }
      );
    }
  }
  let assessee = [];
  if (
    (selectedModule === 'assessees' || selectedModule === 'associate') &&
    relatedReviewListPaneThree
  ) {
    assessee = relatedReviewListPaneThree?.assessee || [];
  }
  let assessment = [];
  if (
    (selectedModule === 'assessments' || selectedModule === 'associate') &&
    relatedReviewListPaneThree
  ) {
    assessment = relatedReviewListPaneThree?.assessment || [];
  }
  let assignment = [];
  if (
    (selectedModule === 'assignments' || selectedModule === 'associate') &&
    relatedReviewListPaneThree
  ) {
    assignment = relatedReviewListPaneThree?.assignment || [];
  }
  let item = [];
  if (
    (selectedModule === 'items' || selectedModule === 'associate') &&
    relatedReviewListPaneThree
  ) {
    item = relatedReviewListPaneThree?.item || [];
  }
  let cultureProfile = [];
  if (
    (selectedModule === 'culture-profiles' || selectedModule === 'associate') &&
    relatedReviewListPaneThree
  ) {
    cultureProfile = relatedReviewListPaneThree?.cultureProfile || [];
  }
  let jobProfile = [];
  if (
    (selectedModule === 'job-profiles' || selectedModule === 'associate') &&
    relatedReviewListPaneThree
  ) {
    jobProfile = relatedReviewListPaneThree?.jobProfile || [];
  }
  let associate = [];
  if (
    (selectedModule === 'associates' || selectedModule === 'associate') &&
    relatedReviewListPaneThree
  ) {
    associate = relatedReviewListPaneThree?.associate || [];
  }
  console.log('assesseeList', assessee);
  const assesseeNodeList = [];
  assessee.forEach((ob) => {
    const { id, informationBasic, informationEngagement, associateId } = ob;
    assesseeNodeList.push({
      id,
      textOne: `${informationBasic.assesseeNameFirst} ${informationBasic.assesseeNameOther} ${informationBasic.assesseeNameLast}`,
      textTwo: informationBasic.assesseeAlias || '',
      status: informationEngagement.assesseeStatus,
      isShared: ob?.assesseeShared,
      associateId
    });
  });
  console.log('assesseeNodeList', assesseeNodeList);
  const assessmentNodeList = [];
  assessment.forEach((ob) => {
    const { id, informationBasic, informationEngagement, associateId } = ob;
    assessmentNodeList.push({
      id,
      textOne: informationBasic?.assessmentName,
      textTwo: informationBasic?.assessmentDescription || '',
      status: informationEngagement?.assessmentStatus,
      isShared: ob?.assessmentShared,
      associateId,
    });
  });
  const assignmentNodeList = [];
  assignment.forEach((ob) => {
    const { id, informationBasic, informationEngagement, associateId } = ob;
    assignmentNodeList.push({
      id,
      textOne: informationBasic?.assignmentName,
      textTwo: informationBasic?.assignmentDescription || '',
      status: informationEngagement?.assignmentStatus,
      isShared: ob?.assignmentShared,
      associateId
    });
  });
  // console.log('assignmentNodeList', assignmentNodeList);
  const itemNodeList = [];
  item.forEach((ob) => {
    const { id, informationBasic, informationEngagement, associateId } = ob;
    itemNodeList.push({
      id,
      textOne: informationBasic?.itemName,
      textTwo: informationBasic?.itemDescription || '',
      status: informationEngagement?.itemStatus,
      isShared: ob?.itemShared,
      associateId
    });
  });
  const cultureProfileNodeList = [];
  cultureProfile.forEach((ob) => {
    const { id, informationBasic, informationEngagement, associateId } = ob;
    cultureProfileNodeList.push({
      id,
      textOne: informationBasic?.cultureProfileName,
      textTwo: informationBasic?.cultureProfileDescription || '',
      status: informationEngagement?.cultureProfileStatus,
      isShared: ob?.cultureProfileShared,
      associateId,
    });
  });
  const jobProfileNodeList = [];
  jobProfile.forEach((ob) => {
    const { id, informationBasic, informationEngagement, associateId } = ob;
    jobProfileNodeList.push({
      id,
      textOne: informationBasic?.jobProfileName,
      textTwo: informationBasic?.jobProfileDescription || '',
      status: informationEngagement?.jobProfileStatus,
      isShared: ob?.jobProfileShared,
      associateId
    });
  });
  // console.log('jobProfileNodeList', jobProfileNodeList);
  const associateNodeList = [];
  associate.forEach((ob) => {
    const { id, informationBasic, informationEngagement, associateId } = ob;
    associateNodeList.push({
      id,
      textOne: informationBasic?.associateName,
      textTwo: informationBasic?.associateDescription || '',
      status: informationEngagement?.associateStatus,
      isShared: ob?.associateShared,
      associateId
    });
  });

  const allModuleList = [
    {
      id: 'a1-01',
      labelTextOneOne: 'assessees',
      labelTextOneOneBadgeOne: '',
      labelTextOneOneBadgeTwo: '',
      labelTextOneOneBadgeThree: '',
      labelTextOneOneBadgeFour: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'distinct',
          innerList: assesseeNodeList
        },
        {
          labelTextOneOneBadge: 'group',
          innerList: []
        }
      ],
      innerInfo: 'No Information',
      isListCard: true,
      isReviewLink: true
    },
    {
      id: 'a2-02',
      labelTextOneOne: 'assessments',
      labelTextOneOneBadgeOne: '',
      labelTextOneOneBadgeTwo: '',
      labelTextOneOneBadgeThree: '',
      labelTextOneOneBadgeFour: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'distinct',
          innerList: assessmentNodeList
        },
        {
          labelTextOneOneBadge: 'group',
          innerList: []
        }
      ],
      innerInfo: 'No Information',
      isListCard: true,
      isReviewLink: true
    },
    {
      id: 'a3-03',
      labelTextOneOne: 'assignments',
      labelTextOneOneBadgeOne: '',
      labelTextOneOneBadgeTwo: '',
      labelTextOneOneBadgeThree: '',
      labelTextOneOneBadgeFour: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'distinct',
          innerList: assignmentNodeList
        },
        {
          labelTextOneOneBadge: 'group',
          innerList: []
        }
      ],
      innerInfo: 'No Information',
      isListCard: true,
      isReviewLink: true
    },
    {
      id: 'a4-04',
      labelTextOneOne: 'associates',
      labelTextOneOneBadgeOne: '',
      labelTextOneOneBadgeTwo: '',
      labelTextOneOneBadgeThree: '',
      labelTextOneOneBadgeFour: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'distinct',
          innerList: associateNodeList
        },
        {
          labelTextOneOneBadge: 'group',
          innerList: []
        }
      ],
      innerInfo: 'No Information',
      isListCard: true,
      isReviewLink: true
    }
  ];
  const analyticsModuleList = [
    {
      id: 'a61',
      labelTextOneOne: 'iGuru analytics',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'culture-profiles',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'distinct',
              innerList: cultureProfileNodeList
            },
            {
              labelTextTwoBadge: 'group',
              innerList: []
            }
          ]
        },
        {
          labelTextOneOneBadge: 'job-profiles',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'distinct',
              innerList: jobProfileNodeList
            },
            {
              labelTextTwoBadge: 'group',
              innerList: []
            }
          ]
        }
      ],
      innerInfo: 'No Information',
      isListCard: true,
      isReviewLink: true,
      isMultiList: true
    }
  ];
  const assesseeModuleList = [
    {
      id: 'a1-001',
      labelTextOneOne: 'assessees',
      labelTextOneOneBadgeOne: '',
      labelTextOneOneBadgeTwo: '',
      labelTextOneOneBadgeThree: '',
      labelTextOneOneBadgeFour: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'distinct',
          innerList: assesseeNodeList
        },
        {
          labelTextOneOneBadge: 'group',
          innerList: []
        }
      ],
      innerInfo: 'No Information',
      isListCard: true,
      isReviewLink: true
    }
  ];
  const assessmentModuleList = [
    {
      id: 'a3-002',
      labelTextOneOne: 'assessments',
      labelTextOneOneBadgeOne: '',
      labelTextOneOneBadgeTwo: '',
      labelTextOneOneBadgeThree: '',
      labelTextOneOneBadgeFour: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'distinct',
          innerList: assessmentNodeList
        },
        {
          labelTextOneOneBadge: 'group',
          innerList: []
        }
      ],
      innerInfo: 'No Information',
      isListCard: true,
      isReviewLink: true
    }
  ];
  const assignmentModuleList = [
    {
      id: 'a5-004',
      labelTextOneOne: 'assignments',
      labelTextOneOneBadgeOne: '',
      labelTextOneOneBadgeTwo: '',
      labelTextOneOneBadgeThree: '',
      labelTextOneOneBadgeFour: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'distinct',
          innerList: assignmentNodeList
        },
        {
          labelTextOneOneBadge: 'group',
          innerList: []
        }
      ],
      innerInfo: 'No Information',
      isListCard: true,
      isReviewLink: true
    }
  ];
  const itemModuleList = [
    {
      id: 'a5-005',
      labelTextOneOne: 'items',
      labelTextOneOneBadgeOne: '',
      labelTextOneOneBadgeTwo: '',
      labelTextOneOneBadgeThree: '',
      labelTextOneOneBadgeFour: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'distinct',
          innerList: itemNodeList
        },
        {
          labelTextOneOneBadge: 'group',
          innerList: []
        }
      ],
      innerInfo: 'No Information',
      isListCard: true,
      isReviewLink: true
    }
  ];
  const cultureProfileModuleList = [
    {
      id: 'a5-006',
      labelTextOneOne: 'culture-profiles',
      labelTextOneOneBadgeOne: '',
      labelTextOneOneBadgeTwo: '',
      labelTextOneOneBadgeThree: '',
      labelTextOneOneBadgeFour: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'distinct',
          innerList: cultureProfileNodeList
        },
        {
          labelTextOneOneBadge: 'group',
          innerList: []
        }
      ],
      innerInfo: 'No Information',
      isListCard: true,
      isReviewLink: true
    }
  ];
  const jobProfileModuleList = [
    {
      id: 'a5-007',
      labelTextOneOne: 'job-profiles',
      labelTextOneOneBadgeOne: '',
      labelTextOneOneBadgeTwo: '',
      labelTextOneOneBadgeThree: '',
      labelTextOneOneBadgeFour: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'distinct',
          innerList: jobProfileNodeList
        },
        {
          labelTextOneOneBadge: 'group',
          innerList: []
        }
      ],
      innerInfo: 'No Information',
      isListCard: true,
      isReviewLink: true
    }
  ];
  const associateModuleList = [
    {
      id: 'a5-008',
      labelTextOneOne: 'associates',
      labelTextOneOneBadgeOne: '',
      labelTextOneOneBadgeTwo: '',
      labelTextOneOneBadgeThree: '',
      labelTextOneOneBadgeFour: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'distinct',
          innerList: associateNodeList
        },
        {
          labelTextOneOneBadge: 'group',
          innerList: []
        }
      ],
      innerInfo: 'No Information',
      isListCard: true,
      isReviewLink: true
    }
  ];

  let list = allModuleList;
  if (selectedModule === 'assessees') {
    list = assesseeModuleList;
  }
  if (selectedModule === 'assessments') {
    list = assessmentModuleList;
  }
  if (selectedModule === 'assignments') {
    list = assignmentModuleList;
  }
  if (selectedModule === 'items') {
    list = itemModuleList;
  }
  if (selectedModule === 'culture-profiles') {
    list = cultureProfileModuleList;
  }
  if (selectedModule === 'job-profiles') {
    list = jobProfileModuleList;
  }
  if (selectedModule === 'associates') {
    list = associateModuleList;
  }

  const reviseNode = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    const innerSelectedBadgeName = e.currentTarget.getAttribute('id');

    console.log(labelName, '+++++', selectedBadgeName, '+++++', innerSelectedBadgeName);
    if (labelName === 'assessees' && selectedBadgeName === 'distinct') {
      let requestObect = makeAssesseeReviewListRequestObject(
        selectedAssociateInfo,
        '',
        0,
        countPage
      );
      let revisedRoleObject = {
        id: responseObject.id,
        associateNodeName: responseObject.informationBasic.associateNodeName,
        associateNodeDescription: responseObject.informationBasic.associateNodeDescription,
        associateNodeStatus: responseObject.informationEngagement.associateNodeStatus
      };
      let existingAssesseeId =
        relatedReviewListPaneThree &&
        relatedReviewListPaneThree?.assessee &&
        relatedReviewListPaneThree.assessee.map((val) => {
          return val.id;
        });
      dispatch({
        type: FILTERMODE,
        payload: { FilterMode: 'assesseeNodeAssesseeRevise' }
      });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'relatedReviewListDistinctData', value: [] }
      });
      dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
      dispatch({ type: SET_PAGE_COUNT, payload: 0 });
      dispatch({ type: LOADER_START });
      //set requestObject
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: {
          stateName: 'relatedReviewListObjectCopy',
          value: requestObect
        }
      });
      // dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
      dispatch({
        type: GET_ALLOCATE_ASSESSEE,
        payload: {
          request: requestObect,
          revisedGroupObject: revisedRoleObject,
          existingAssesseeId: existingAssesseeId,
          typeOfMiddlePaneList: 'assesseesNodeAssesseeReviewList'
        }
      });
    }
    if (labelName === 'assessments' && selectedBadgeName === 'distinct') {
      let requestObect = makeAssessmentReviewListRequestObject(
        selectedAssociateInfo,
        '',
        countPage,
        0,
        ''
      );
      let revisedRoleObject = {
        id: responseObject.id,
        associateNodeName: responseObject.informationBasic.associateNodeName,
        associateNodeDescription: responseObject.informationBasic.associateNodeDescription,
        associateNodeStatus: responseObject.informationEngagement.associateNodeStatus
      };
      let existingAssessmentId =
        relatedReviewListPaneThree &&
        relatedReviewListPaneThree?.assessment &&
        relatedReviewListPaneThree.assessment.map((val) => {
          return val.id;
        });
      if (relatedReviewListPaneThree && relatedReviewListPaneThree.length > 0) {
        let key = Object.keys(relatedReviewListPaneThree);
        if (key[0] === 'assessment') {
          existingAssessmentId =
            relatedReviewListPaneThree &&
            relatedReviewListPaneThree?.assessment &&
            relatedReviewListPaneThree.assessment.map((val) => {
              return val.id;
            });
        }
      }
      dispatch({
        type: FILTERMODE,
        payload: { FilterMode: 'assessmentNodeAssessmentRevise' }
      });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'relatedReviewListDistinctData', value: [] }
      });
      dispatch({ type: SET_PAGE_COUNT, payload: 0 });
      dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
      dispatch({ type: LOADER_START });
      // dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
      //set requestObject
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: {
          stateName: 'relatedReviewListObjectCopy',
          value: requestObect
        }
      });
      dispatch({
        type: GET_ALLOCATE_ASSESSMENT,
        payload: {
          request: requestObect,
          revisedGroupObject: revisedRoleObject,
          existingAssessmentId: existingAssessmentId,
          typeOfMiddlePaneList: 'assessmentNodeAssessmentReviewList'
        }
      });
    }
    if (labelName === 'assignments' && selectedBadgeName === 'distinct') {
      let requestObect = makeAssignmentReviewListRequestObject(
        selectedAssociateInfo,
        '',
        countPage,
        0,
        'iGuru_Assignment'
      );
      let revisedRoleObject = {
        id: responseObject.id,
        associateNodeName: responseObject.informationBasic.associateNodeName,
        associateNodeDescription: responseObject.informationBasic.associateNodeDescription,
        associateNodeStatus: responseObject.informationEngagement.associateNodeStatus
      };
      let existingAssignmentId =
        relatedReviewListPaneThree &&
        relatedReviewListPaneThree?.assignment &&
        relatedReviewListPaneThree.assignment.map((val) => {
          return val.id;
        });
      console.log('relatedReviewListPaneThree***', relatedReviewListPaneThree);
      console.log('existingAssignmentId***', existingAssignmentId);
      dispatch({
        type: FILTERMODE,
        payload: { FilterMode: 'assignmentNodeAssignmentRevise' }
      });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'relatedReviewListDistinctData', value: [] }
      });
      dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
      dispatch({ type: LOADER_START });
      //check the wether API call increse the page or not if is increasing the count then use reset API payload
      dispatch({ type: SET_PAGE_COUNT, payload: 0 });
      //set requestObject
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: {
          stateName: 'relatedReviewListObjectCopy',
          value: requestObect
        }
      });
      dispatch({
        type: GET_ALLOCATE_ASSIGNMENT,
        payload: {
          request: requestObect,
          revisedGroupObject: revisedRoleObject,
          existingAssignmentId: existingAssignmentId || [],
          typeOfMiddlePaneList: 'assignmentNodeAssignmentReviewList'
        }
      });
    }
    if (labelName === 'items' && selectedBadgeName === 'distinct') {
      let requestObect = makeItemObj(selectedAssociateInfo, '', countPage, 0);
      let revisedRoleObject = {
        id: responseObject.id,
        associateNodeName: responseObject.informationBasic.associateNodeName,
        associateNodeDescription: responseObject.informationBasic.associateNodeDescription,
        associateNodeStatus: responseObject.informationEngagement.associateNodeStatus
      };
      let existingItemId =
        relatedReviewListPaneThree &&
        relatedReviewListPaneThree?.item &&
        relatedReviewListPaneThree.item.map((val) => {
          return val.id;
        });
      // }
      dispatch({
        type: FILTERMODE,
        payload: { FilterMode: 'itemNodeItemRevise' }
      });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'relatedReviewListDistinctData', value: [] }
      });
      dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
      dispatch({ type: LOADER_START });
      dispatch({ type: SET_PAGE_COUNT, payload: 0 });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: {
          stateName: 'relatedReviewListObjectCopy',
          value: requestObect
        }
      });

      dispatch({
        type: GET_ALLOCATE_ITEM,
        payload: {
          request: requestObect,
          revisedGroupObject: revisedRoleObject,
          existingItemId: existingItemId,
          typeOfMiddlePaneList: 'itemNodeItemReviewList'
        }
      });
    }
    // if (labelName === 'culture-profiles' && selectedBadgeName === 'distinct') {
    //   allocateCultureProfile();
    // }
    // if (labelName === 'job-profiles' && selectedBadgeName === 'distinct') {
    //   allocateJobProfile();
    // }
    if (labelName === 'associates' && selectedBadgeName === 'distinct') {
      let requestObect = makeAssociateReviewListRequestObject(
        selectedAssociateInfo,
        '',
        0,
        countPage
      );
      dispatch({ type: SET_PAGE_COUNT, payload: 0 });

      let revisedGroupObject = {
        id: responseObject.id,
        associateNodeName: responseObject.informationBasic.associateNodeName,
        associateNodeDescription: responseObject.informationBasic.associateNodeDescription,
        associateNodeStatus: responseObject.informationEngagement.associateNodeStatus
      };
      let existingAssesseeId = [];
      existingAssesseeId =
        relatedReviewListPaneThree &&
        relatedReviewListPaneThree?.associate &&
        relatedReviewListPaneThree.associate.map((val) => {
          return val.id;
        });
      console.log('existingAssesseeId', existingAssesseeId);
      getAssociateDistinctToAllocate(
        dispatch,
        requestObect,
        revisedGroupObject,
        (existingAssesseeId && existingAssesseeId) || [],
        'associateNodeAssociateRevise',
        'nodeAssociatesReviewList'
      );
    }
    if (labelName === 'iGuru analytics' && selectedBadgeName ==='culture-profiles' && innerSelectedBadgeName === 'distinct'){
      allocateCultureProfile();
    }
    if (labelName === 'iGuru analytics' && selectedBadgeName ==='job-profiles' && innerSelectedBadgeName === 'distinct'){
      allocateJobProfile();
    }
  };
  const allocateJobProfile =()=>{
    let requestObect = makeJobProfileObj(selectedAssociateInfo, '', countPage, 0);
    let revisedRoleObject = {
      id: responseObject.id,
      associateNodeName: responseObject.informationBasic.associateNodeName,
      associateNodeDescription: responseObject.informationBasic.associateNodeDescription,
      associateNodeStatus: responseObject.informationEngagement.associateNodeStatus
    };
    let existingJobProfileId =
      relatedReviewListPaneThree &&
      relatedReviewListPaneThree?.jobProfile &&
      relatedReviewListPaneThree.jobProfile.map((val) => {
        return val.id;
      });
    dispatch({
      type: FILTERMODE,
      payload: { FilterMode: 'jobProfileNodeJobProfileRevise' }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'relatedReviewListDistinctData', value: [] }
    });
    dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
    dispatch({ type: LOADER_START });
    //check the wether API call increase the page or not if is increasing the count then use reset API payload
    dispatch({ type: SET_PAGE_COUNT, payload: 0 });
    //set requestObject
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'relatedReviewListObjectCopy',
        value: requestObect
      }
    });
    // dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
    dispatch({
      type: GET_ALLOCATE_JOB,
      payload: {
        request: requestObect,
        revisedGroupObject: revisedRoleObject,
        existingJobProfileId: existingJobProfileId || [],
        typeOfMiddlePaneList: 'jobProfileNodeJobProfileReviewList'
      }
    });
  }
  const allocateCultureProfile =()=>{
    let requestObect = makeCultureProfileObj(selectedAssociateInfo, '', countPage, 0);
    let revisedRoleObject = {
      id: responseObject.id,
      associateNodeName: responseObject.informationBasic.associateNodeName,
      associateNodeDescription: responseObject.informationBasic.associateNodeDescription,
      associateNodeStatus: responseObject.informationEngagement.associateNodeStatus
    };

    let existingCultureProfileId =
      relatedReviewListPaneThree &&
      relatedReviewListPaneThree?.cultureProfile &&
      relatedReviewListPaneThree.cultureProfile.map((val) => {
        return val.id;
      });
    dispatch({
      type: FILTERMODE,
      payload: { FilterMode: 'cultureProfileNodeCultureProfileRevise' }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'relatedReviewListDistinctData', value: [] }
    });
    dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
    dispatch({ type: LOADER_START });
    //check the wether API call increse the page or not if is increasing the count then use reset API payload
    dispatch({ type: SET_PAGE_COUNT, payload: 0 });
    //set requestObject
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'relatedReviewListObjectCopy',
        value: requestObect
      }
    });
    // dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
    dispatch({
      type: GET_ALLOCATE_CULTURE,
      payload: {
        request: requestObect,
        revisedGroupObject: revisedRoleObject,
        existingCultureProfileId: existingCultureProfileId,
        typeOfMiddlePaneList: 'cultureProfileNodeCultureProfileReviewList'
      }
    });
  }
  const reviewNode = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    const innerSelectedBadgeName = e.currentTarget.getAttribute('id');
    console.log(labelName, '+++++', selectedBadgeName, '+++++', innerSelectedBadgeName);
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'typeOfMiddlePaneList', value: '' }
    });
    if (labelName === 'assessees' && selectedBadgeName === 'distinct') {
      console.log('IN ASSSESSEE ');
      getAssesseeNodeAssesseeDistinctApiCall(
        selectedAssociateInfo,
        '',
        countPage,
        dispatch,
        'distinct',
        responseObject.id,
        '',
        false,
        'assessees'
      );
    }
    if (labelName === 'assessments' && selectedBadgeName === 'distinct') {
      getNodeRelatedAssessmentsDistinctApiCall(
        selectedAssociateInfo,
        '',
        countPage,
        dispatch,
        'distinct',
        responseObject.id,
        '',
        false,
        labelName
      );
    }
    if (labelName === 'assignments' && selectedBadgeName === 'distinct') {
      getNodeRelatedAssignmentsDistinctApiCall(
        selectedAssociateInfo,
        '',
        countPage,
        dispatch,
        'distinct',
        responseObject.id,
        '',
        false,
        'assignments'
      );
    }
    if (labelName === 'associates' && selectedBadgeName === 'distinct') {
      getNodeRelatedAssociateDistinctApiCall(
        selectedAssociateInfo,
        '',
        countPage,
        dispatch,
        'distinct',
        responseObject.id,
        '',
        false,
        'associates'
      );
    }
    if (labelName === 'items' && selectedBadgeName === 'distinct') {
      getNodeRelatedItemsDistinctApiCall(
        selectedAssociateInfo,
        '',
        countPage,
        dispatch,
        'distinct',
        responseObject.id,
        '',
        false,
        'items'
      );
    }
    if (labelName === 'iGuru analytics' && selectedBadgeName === 'culture-profiles' && innerSelectedBadgeName === 'distinct') {
      getCultureProfileNodeCultureProfileApiCall(
        selectedAssociateInfo,
        '',
        countPage,
        dispatch,
        'distinct',
        responseObject.id,
        '',
        false,
        'culture-profiles'
      );
    }
    if (labelName === 'iGuru analytics' && selectedBadgeName === 'job-profiles' && innerSelectedBadgeName === 'distinct') {
      getJobProfileNodeJobProfileApiCall(
        selectedAssociateInfo,
        '',
        countPage,
        dispatch,
        'distinct',
        responseObject.id,
        '',
        false,
        'job-profiles'
      );
    }
  };
  // console.log('associateNodeList', associateNodeList);
  return (
    <div>
      <>
        <div className={'containerPadding'}>
          <Paper className={'dossierContainerTop'}>
            {list.map((ob) => {
              return (
                <div key={ob.id}>
                  {ob.isListCard ? (
                    <>
                      {ob.isMultiList ? (
                        <DisplayPanelAccordianReviewListTwo
                          onClickReview={reviewNode}
                          onClickRevise={reviseNode}
                          accordianObject={ob}
                          mode={reviewMode}
                        />
                      ) : (
                        <DisplayPanelAccordianReviewListOne
                          onClickReview={reviewNode}
                          onClickRevise={reviseNode}
                          className=""
                          accordianObject={ob}
                          mode={reviewMode}
                        />
                      )}
                    </>
                  ) : (
                    <DisplayPanelAccordianInformation
                      onClickReview={reviewNode}
                      onClickRevise={reviseNode}
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  )}
                </div>
              );
            })}
          </Paper>
          {(selectedModule === 'associate' || createMode === 'associatesNode') && (
            <Paper className={'dossierContainerTop'}>
              {analyticsModuleList.map((ob) => {
                return (
                  <div key={ob.id}>
                    {ob.isListCard ? (
                      <>
                        {ob.isMultiList ? (
                          <DisplayPanelAccordianReviewListTwo
                            onClickReview={reviewNode}
                            onClickRevise={reviseNode}
                            accordianObject={ob}
                            mode={reviewMode}
                          />
                        ) : (
                          <DisplayPanelAccordianReviewListOne
                            onClickReview={reviewNode}
                            onClickRevise={reviseNode}
                            className=""
                            accordianObject={ob}
                            mode={reviewMode}
                          />
                        )}
                      </>
                    ) : (
                      <DisplayPanelAccordianInformation
                        onClickReview={reviewNode}
                        onClickRevise={reviseNode}
                        accordianObject={ob}
                        mode={reviewMode}
                      />
                    )}
                  </div>
                );
              })}
            </Paper>
          )}
          
          {(selectedModule === 'associate' || createMode === 'associatesNode') && (
            <Paper className={'dossierContainerTop'}>
              {itemModuleList.map((ob) => {
                return (
                  <div key={ob.id}>
                    {ob.isListCard ? (
                      <>
                        {ob.isMultiList ? (
                          <DisplayPanelAccordianReviewListTwo
                            onClickReview={reviewNode}
                            onClickRevise={reviseNode}
                            accordianObject={ob}
                            mode={reviewMode}
                          />
                        ) : (
                          <DisplayPanelAccordianReviewListOne
                            onClickReview={reviewNode}
                            onClickRevise={reviseNode}
                            className=""
                            accordianObject={ob}
                            mode={reviewMode}
                          />
                        )}
                      </>
                    ) : (
                      <DisplayPanelAccordianInformation
                        onClickReview={reviewNode}
                        onClickRevise={reviseNode}
                        accordianObject={ob}
                        mode={reviewMode}
                      />
                    )}
                  </div>
                );
              })}
            </Paper>
          )}
         
        </div>
      </>
      {isMobile && (
        <div className={'containerPadding'} style={{ height: '55px' }}>
          {' '}
        </div>
      )}
    </div>
  );
};

export default DisplayPaneThreeSectionTwoAssociateNode;
