import React, { useState } from 'react';

export const DocumentViewer = (props) => {
  
  return (
      <div className="main">
      </div>
  );
}
export default DocumentViewer;
