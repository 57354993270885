import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ASSIGNMENT_REVISE_SAGA,
  CLEAR_DISPLAY_PANE_THREE,
  LOADER_START,
  POPUP_OPEN,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MIDDLEPANE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_POPUP_STATE
} from '../actionType';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import { SECTION_SCALE_CLUSTER_REVIEW_LIST_POPUP_OPTION } from '../PopUpConfig';
import Card from '../Molecules/Card/Card';
import CrossIcon from '@material-ui/icons/Clear';
import { onClickCheckBoxSelection, onClickCheckBoxSelectionOnlyOne } from '../Actions/AssesseeModuleAction';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import ReviseIcon from '@material-ui/icons/RadioButtonChecked';
import Check from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { closeRelatedList, getPopUpOptionList } from '../Actions/GenericActions';
const AssignmentDistinctAssignmentReferenceReviewList = (props) => {
  const dispatch = useDispatch();
  const {
    reviewListDistinctData,
    relatedReviewListDistinctData,
    selectedTagsArray,
    isSelectActive,
    unselectedTagsArray,
    middlePaneHeaderDuplicate,
    middlePaneHeaderDuplicateBadgeOne,
    middlePaneHeaderDuplicateBadgeTwo,
    middlePaneHeaderDuplicateBadgeThree,
    middlePaneHeaderDuplicateBadgeFour,
    middlePaneHeaderDuplicateOne,
    middlePaneHeaderDuplicateOneBadgeOne,
    middlePaneHeaderDuplicateOneBadgeTwo,
    middlePaneHeaderDuplicateOneBadgeThree,
    middlePaneHeaderDuplicateOneBadgeFour,
    duplicateScanCount,
    selectedAssociateInfo,
    signedAssesseePermission
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const {
    responseObject,
    assignmentAssesseeList = [],
    assignmentAssessmentList = [],
    assignmentCultureProfileList = [],
    assignmentJobProfileList = [],
    assignmentAssesseeGroupList = [],
    assignmentAssessmentGroupList = [],
    assignmentCultureProfileGroupList = [],
    assignmentJobProfileGroupList = [],
    createMode,
    reviewMode
  } = useSelector((state) => state.DisplayPaneThreeReducer);
  const assignmentInfo = useSelector((state) => state.AssignmentReducer);
  const { FilterMode } = useSelector((state) => state.FilterReducer);
  const [isShowReviseIcon, setIsShowReviseIcon] = useState(true);
  const onClickRevise = () => {
    console.log('ON CLICK REVISE ICON');
    setIsShowReviseIcon(false);
  };
  const onClickReviseCancel = () => {
    console.log('ON CLICK cancel ICON');
    setIsShowReviseIcon(true);
    setPrevList();
    if (middlePaneHeaderDuplicateOne !== 'assignments') {
      if (reviewMode === 'review') {
        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
      }
      dispatch({
        type: SET_MIDDLEPANE_STATE,
        payload: {
          middlePaneHeader: '',
          middlePaneHeaderBadgeOne: '',
          middlePaneHeaderBadgeTwo: '',
          middlePaneHeaderBadgeThree: '',
          middlePaneHeaderBadgeFour: '',
          typeOfMiddlePaneList: '',
          showMiddlePaneState: false
        }
      });
    }
  };
  const setPrevList = () => {
    console.log('creteeeeeee',createMode);
    if (createMode === '') {
      closeRelatedList(
        dispatch,
        createMode,
        responseObject,
        middlePaneHeaderDuplicate,
        middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderDuplicateBadgeTwo,
        middlePaneHeaderDuplicateBadgeThree,
        middlePaneHeaderDuplicateBadgeFour,
        'assignmentDistinctReviewList',
        duplicateScanCount,
        'assignmentsDistinct',
        duplicateScanCount,
        middlePaneHeaderDuplicateOne,
        middlePaneHeaderDuplicateOneBadgeOne,
        middlePaneHeaderDuplicateOneBadgeTwo,
        middlePaneHeaderDuplicateOneBadgeThree,
        middlePaneHeaderDuplicateOneBadgeFour,
      );
    } else {
      dispatch({
        type: SET_MIDDLEPANE_STATE,
        payload: {
          middlePaneHeader: '',
          middlePaneHeaderBadgeOne: '',
          middlePaneHeaderBadgeTwo: '',
          middlePaneHeaderBadgeThree: '',
          middlePaneHeaderBadgeFour: '',
          typeOfMiddlePaneList: '',
          scanCount: '',
          showMiddlePaneState: false
        }
      });
    }
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'relatedReviewListDistinctData', value: [] }
    });
  };
  const onClickReviseFinish = () => {
    setPrevList();
    setIsShowReviseIcon(true);
    const reqBody = {
      module: 'Assignment-Distinct',
      action: 'Revise',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        assignment: {
          id: responseObject.id,
          informationBasic: assignmentInfo.informationBasic,
          informationAllocation: assignmentInfo.informationAllocation,
          informationSetup: assignmentInfo.informationSetup,
          informationFramework: {
            ...assignmentInfo.informationFramework,
            assignmentAssesseeDistinct: assignmentAssesseeList || [],
            assignmentAssessmentDistinct: assignmentAssessmentList || [],
            assignmentCultureProfileDistinct: assignmentCultureProfileList || [],
            assignmentJobProfileDistinct: assignmentJobProfileList || [],
            assignmentAssesseeGroup: assignmentAssesseeGroupList || [],
            assignmentAssessmentGroup: assignmentAssessmentGroupList || [],
            assignmentCultureProfileGroup: assignmentCultureProfileGroupList || [],
            assignmentJobProfileGroup: assignmentJobProfileGroupList || []
          },
          assignmentDistinctEvaluationRefId: selectedTagsArray
        }
      }
    };
    dispatch({ type: LOADER_START });
    dispatch({
      type: ASSIGNMENT_REVISE_SAGA,
      payload: {
        reqBody
      }
    });
    dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
  };
  const revisePrimaryIcon = [{ label: 'revise', onClick: onClickRevise, Icon: ReviseIcon }];
  const reviseSecondaryIcons = [
    { label: 'cancel', onClick: onClickReviseCancel, Icon: ClearIcon },
    { label: 'finish', onClick: onClickReviseFinish, Icon: Check }
  ];

  const listDistinctData = relatedReviewListDistinctData[0];
  console.log('listDistinctData', listDistinctData);
  const openListPopup = (e) => {
    console.log(e.currentTarget.getAttribute('tag'));
    console.log(e.currentTarget.getAttribute('status'));
    let tempArr = [];
    let stats = e.currentTarget.getAttribute('status');
    let permissionObject = signedAssesseePermission?.assigmentDistictPermission?.assesseeAssignmentDistinctPermission;
    let popupContentArr = getPopUpOptionList(SECTION_SCALE_CLUSTER_REVIEW_LIST_POPUP_OPTION, permissionObject);

    popupContentArr.map((element) => {
      if (element.data === 'revise') tempArr.push({ ...element, disabled: true });
      else tempArr.push(element);
    });
    let metrics = {
      data: 'metrics',
      dataValue: 'metrics',
      optionClass: 'optionPrimary',
      divider: 'dark',
      disabled: false
    };
    tempArr.splice(9, 0, metrics);
    console.log(tempArr);
    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: 'assignment',
        popupHeaderOneBadgeOne: 'gauge',
        isPopUpValue: '',
        popupOpenType: 'primary',
        popupContentArrValue: tempArr,
        selectedTagValue: e.currentTarget.getAttribute('tag'),
        selectedTagStatus: e.currentTarget.getAttribute('status')
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: tempArr
      }
    });
  };
  console.log('listDistinctData', listDistinctData);
  return (
    <div>
      {listDistinctData && (
        <Card
          textOneOne={listDistinctData.assignmentName}
          textTwoOne={listDistinctData.assignmentDescription}
          IconOne={CrossIcon}
          isIcon={true}
          labelTwoTwo={'assignment'}
          onClickIconOne={onClickReviseCancel}
          isAlliance
          relatedCardFixed={true}
          className={'iguru-iconboxSVG'}
        />
      )}
      {listDistinctData &&
        listDistinctData.assignment.map((item, index) => {
          return (
            <div className="containerPadding" key={index}>
              <ReviewList
                className=""
                // id={index}
                // tag={index}
                id={item.id}
                tag={item.id}
                // isSelectedReviewList={middlePaneSelectedValue === item.id}
                //isInformationValue={item.assessmentScaleWeightage}
                status={item?.informationEngagement?.assignmentStatus}
                isInformation
                actualStatus={listDistinctData?.assignmentStatus}
                textOne={item?.informationBasic?.assignmentName}
                textTwo={item?.informationBasic?.assignmentDescription}
                isTooltipActive={false}
                onClickEvent={openListPopup}
                isSelectActive={isSelectActive}
                // isSelected={selectedTagsArray.includes(item.id.toString())}
                isSelected={selectedTagsArray.includes(item.id)}
                onClickCheckBox={(event) => {
                  onClickCheckBoxSelectionOnlyOne(selectedTagsArray, unselectedTagsArray, event, dispatch);
                }}
              />
            </div>
          );
        })}
      {FilterMode === 'assignmentDistinctAssignmentReferenceRevise' && (
        <FooterIconTwo
          FilterModeEnable={isShowReviseIcon}
          FilterMode={FilterMode}
          onClick={onClickRevise}
          primaryIcon={revisePrimaryIcon}
          secondaryIcon={reviseSecondaryIcons}
        />
      )}
    </div>
  );
};
export default AssignmentDistinctAssignmentReferenceReviewList;
