import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Button, Divider, IconButton } from '@material-ui/core';
import Check from '@material-ui/icons/Check';
import Badge from '@material-ui/core/Badge';
import Notifications from '@material-ui/icons/NotificationsActive';
import { Link } from 'react-router-dom';
import { encryptData } from './GenericActions';
import { STAGE_LITE_URL } from '../endpoints';

const JsonRenderComponent = (props) => {
  const {
    setSecondaryOptionValue,
    ChangeOptionPopup,
    currentPopUpOption = [],
    secondaryOptionCheckValue,
    tertiaryOptionCheckValue = 'all',
    forthOptionCheckValue = '',
    setSecondaryOptionValueTwo = null,
    setSecondaryOptionValueThree = null,
    isNotificationAlert = false,
    assignmentBellIconCount = 0
  } = props;


  const { popupContentArrValue } = useSelector((state) => state.PopUpReducer);
  const { selectedAssociateInfo } = useSelector((state) => state.DisplayPaneTwoReducer);
  let popUpOption = currentPopUpOption.length > 0 ? currentPopUpOption : popupContentArrValue;
  let token = localStorage.getItem("refreshToken");
  const assesseeId = selectedAssociateInfo.assesseeId;
  const cred = localStorage.getItem('assesseeId');
  const associateId = selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary;

  return (
    <>
      <div id="dialog-description">
        <div className="true">
          <div className={'tickOption'}>
            {popUpOption &&
              popUpOption.map((item, index) => {
                return (
                  <div key={index}>
                    {item.dataKey === "assesseeselfassignments" ?
                      <Button className={[
                        item.optionClass,
                        item.optionClass !== 'optionPrimary' && item.disabled
                          ? 'optionSecondaryDisabled'
                          : ''
                      ].join(' ')}
                      >
                        <a style={{ textDecoration: "none", color: "inherit" }} href={`${STAGE_LITE_URL}/assignment?token=${token}&aseeId=${assesseeId}&assoId=${associateId}&cred=${cred}`} rel="noreferrer">
                          <Fragment>
                            {item.data}
                            {item.optionClass === 'optionSecondary' &&
                              secondaryOptionCheckValue === item.dataValue ? (
                              <div className={'tick'}>
                                <Check className={'selectionIcon'} />

                              </div>
                            ) : null}
                            {item.optionClass === 'optionTertiary' &&
                              tertiaryOptionCheckValue === item.dataValue ? (
                              <div className={'tick'}>
                                <Check className={'selectionIcon'} />
                              </div>
                            ) : null}
                            {item.optionClass === 'optionForth' &&
                              forthOptionCheckValue === item.dataValue ? (
                              <div className={'tick'}>
                                <Check className={'selectionIcon'} />
                              </div>
                            ) : null}
                            {isNotificationAlert &&
                              assignmentBellIconCount !== 0 &&
                              item.data === 'assignments' ? (
                              <Badge
                                overlap="rectangular"
                                className={'badgeBox'}
                                style={{ position: 'inherit' }}
                                badgeContent={assignmentBellIconCount}
                              >
                                {/* <div */}
                                <div className={'notifyIcon'} style={{ width: '56px' }}>
                                  <Notifications style={{ right: '0px' }} className={'selectionIcon'} />
                                </div>
                                {/* </div> */}
                              </Badge>
                            ) : null}
                          </Fragment>
                        </a>
                      </Button> :
                      <Button
                        className={[
                          item.optionClass,
                          item.optionClass !== 'optionPrimary' && item.disabled
                            ? 'optionSecondaryDisabled'
                            : ''
                        ].join(' ')}
                        data-value={item.dataValue}
                        data-key={item.dataKey ? item.dataKey : item.dataValue}
                        data-permission={item.permission}
                        onClick={
                          item.optionClass === 'optionSecondary'
                            ? setSecondaryOptionValue
                            : item.optionClass === 'optionTertiary'
                              ? setSecondaryOptionValueTwo
                              : item.optionClass === 'optionForth'
                                ? setSecondaryOptionValueThree
                                : ChangeOptionPopup
                        }
                        disabled={item.dataValue === 'switch' ? false : item.disabled}
                      >
                        <Fragment>
                          {item.data}
                          {item.optionClass === 'optionSecondary' &&
                            secondaryOptionCheckValue === item.dataValue ? (
                            <div className={'tick'}>
                              <Check className={'selectionIcon'} />
                            </div>
                          ) : null}
                          {item.optionClass === 'optionTertiary' &&
                            tertiaryOptionCheckValue === item.dataValue ? (
                            <div className={'tick'}>
                              <Check className={'selectionIcon'} />
                            </div>
                          ) : null}
                          {item.optionClass === 'optionForth' &&
                            forthOptionCheckValue === item.dataValue ? (
                            <div className={'tick'}>
                              <Check className={'selectionIcon'} />
                            </div>
                          ) : null}
                          {isNotificationAlert &&
                            assignmentBellIconCount !== 0 &&
                            item.data === 'assignments' ? (
                            <Badge
                              overlap="rectangular"
                              className={'badgeBox'}
                              style={{ position: 'inherit' }}
                              badgeContent={assignmentBellIconCount}
                            >
                              {/* <div */}
                              <div className={'notifyIcon'} style={{ width: '56px' }}>
                                <Notifications style={{ right: '0px' }} className={'selectionIcon'} />
                              </div>
                              {/* </div> */}
                            </Badge>
                          ) : null}
                        </Fragment>
                      </Button>}
                    {item.divider && <Divider light={item.divider === 'light'} key={index} />}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};
export default JsonRenderComponent;