import React from 'react';
import { isMobile } from 'react-device-detect';
// import AllocationAccordian from '../Accordian/AllocationAccordian';
// import Manuscript from '@material-ui/icons/Description';
import { useDispatch, useSelector } from 'react-redux';
import DisplayPanelAccordianReviewListOne from '../Accordian/DisplayPanelAccordianReviewListOne';
import DisplayPanelAccordianInformation from '../Accordian/DisplayPanelAccordianInformation';
import { Paper } from '@material-ui/core';
import { makeAssignmentReviewListRequestObject } from '../../Actions/GenericActions';
import {
  FILTERMODE,
  GET_ALLOCATE_ASSIGNMENT,
  LOADER_START,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_PAGE_COUNT
} from '../../actionType';
import {
  getAssignmentDistinctToAllocate,
  getAssignmnetGroupAssignmnetDistinctApiCall
} from '../../Actions/AssignmentModuleAction';

const DisplayPaneThreeSectionTwoAssignmentGroup = () => {
  // const [listExpand, setListExpand] = useState('');
  const dispatch = useDispatch();
  const { reviewMode, relatedReviewListPaneThree, responseObject } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  const { selectedAssociateInfo, countPage, reviewListDistinctData, assignmentCreateType } = useSelector(
    (state) => state.DisplayPaneTwoReducer
  );
  // const { informationEngagement, informationSetup } = responseObject;
  // function capitalizeFirstLetter(string) {
  //   if (!string) return '';
  //   return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  // }
  let assignmentList = [];
  if (relatedReviewListPaneThree) {
    assignmentList = relatedReviewListPaneThree?.assignment || [];
  }
  console.log('assignmentListHe', assignmentList);
  let assignmentArray = [];
  assignmentList &&
    assignmentList.forEach((ob) => {
      const { id, informationBasic, informationEngagement, associateId } = ob;
      assignmentArray.push({
        id,
        textOne: informationBasic?.assignmentName || '',
        textTwo: informationBasic?.assignmentDescription || '',
        status: informationEngagement?.assignmentStatus || '',
        isShared: ob?.assignmentShared,
        associateId
      });
    });

  const list2 = [
    {
      id: 'a1',
      labelTextOneOne: 'assignments',
      labelTextOneOneBadgeOne: '',
      labelTextOneOneBadgeTwo: '',
      labelTextOneOneBadgeThree: '',
      labelTextOneOneBadgeFour: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'distinct',
          innerList: assignmentArray
        }
      ],
      innerInfo: 'No Information',
      isListCard: true,
      isReviewLink: true
    }
  ];

  const onclickReviewAssignment = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    if (labelName === 'assignments' && selectedBadgeName === 'distinct') {
      getAssignmnetGroupAssignmnetDistinctApiCall(
        selectedAssociateInfo,
        '',
        //'all',
        -1,
        dispatch,
        'distinct',
        responseObject.id, //group id
        '',
        false,
        true
      );
    }
  };

  const onclickReviseAssignment = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    if (labelName === 'assignments' && selectedBadgeName === 'distinct') {
      // dispatch({
      //   type: SET_DISPLAY_TWO_SINGLE_STATE,
      //   payload: {
      //     stateName: 'assignmentCreateType',
      //     value: 'iGuru_Assignment'
      //   }
      // });
      let requestObect = makeAssignmentReviewListRequestObject(
        selectedAssociateInfo,
        '',
        //'activeinactive',
        countPage,
        0,
        assignmentCreateType || "iGuru_Assignment",
      );
      let revisedGroupObject = {
        id: responseObject.id,
        assignmentGroupName: responseObject.informationBasic.assignmentGroupName,
        assignmentGroupDescription: responseObject.informationBasic.assignmentGroupDescription,
        assignmentGroupStatus: responseObject.informationEngagement.assignmentGroupStatus
      };
      let existingAssignmentId = [];
      // if (relatedReviewListPaneThree && relatedReviewListPaneThree.assignment) {
      //   existingAssignmentId =
      //     relatedReviewListPaneThree &&
      //     relatedReviewListPaneThree.assignment.map((val) => {
      //       return val.id;
      //     });
      // }
      // console.log('Heloo', relatedReviewListPaneThree);
      let tempArr = relatedReviewListPaneThree?.assignment || [];
      existingAssignmentId = tempArr.map((val) => {
        return val.id;
      });
      // console.log('HelooE', existingAssignmentId);
      getAssignmentDistinctToAllocate(
        dispatch,
        requestObect,
        revisedGroupObject,
        existingAssignmentId,
        'assignmentGroupAssignmentRevise',
        'assignmentGroupAssignmentReviewList'
      );
      dispatch({ type: SET_PAGE_COUNT, payload: 0 });
    }
  };

  return (
    <div>
      <>
        <div className={'containerPadding'}>
          <Paper className={'dossierContainerTop'}>
            {list2.map((ob) => {
              return (
                <div key={ob.id}>
                  {ob.isListCard ? (
                    <DisplayPanelAccordianReviewListOne
                      onClickReview={onclickReviewAssignment}
                      onClickRevise={onclickReviseAssignment}
                      className=""
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  ) : (
                    <DisplayPanelAccordianInformation
                      onClickRevise={onclickReviseAssignment}
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  )}
                </div>
              );
            })}
          </Paper>
        </div>
      </>
      {isMobile && (
        <div className={'containerPadding'} style={{ height: '55px' }}>
          {' '}
        </div>
      )}
    </div>
  );
};

export default DisplayPaneThreeSectionTwoAssignmentGroup;
