import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CLEAR_DISPLAY_PANE_THREE,
  POPUP_OPEN,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MIDDLEPANE_STATE,
  SET_POPUP_STATE
} from '../actionType';

import ReviewList from '../Molecules/ReviewList/ReviewList';
import { SECTION_SCALE_CLUSTER_REVIEW_LIST_POPUP_OPTION } from '../PopUpConfig';
import Card from '../Molecules/Card/Card';
import CrossIcon from '@material-ui/icons/Clear';
import { onClickCheckBoxSelection } from '../Actions/AssesseeModuleAction';
import { closeRelatedList, getPopUpOptionList } from '../Actions/GenericActions';

const AssessmentDistinctSubScaleDistinct = (props) => {
  const dispatch = useDispatch();
  const {
    middlePaneSelectedValue,
    reviewListDistinctData,
    selectedAssociateInfo,
    relatedReviewListDistinctData,
    middlePaneHeaderBadgeOne,
    middlePaneHeaderBadgeTwo,
    middlePaneHeaderBadgeThree,
    middlePaneHeaderBadgeFour,
    typeOfMiddlePaneList,
    selectedTagsArray,
    unselectedTagsArray,
    isSelectActive,
    signedAssesseePermission,
    middlePaneHeaderDuplicate,
    middlePaneHeaderDuplicateBadgeOne,
    middlePaneHeaderDuplicateBadgeTwo,
    middlePaneHeaderDuplicateBadgeThree,
    middlePaneHeaderDuplicateBadgeFour,
    middlePaneHeaderDuplicateOne,
    middlePaneHeaderDuplicateOneBadgeOne,
    middlePaneHeaderDuplicateOneBadgeTwo,
    middlePaneHeaderDuplicateOneBadgeThree,
    middlePaneHeaderDuplicateOneBadgeFour,
    duplicateScanCount
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const {
    createMode,
    responseObject
  } = useSelector((state) => state.DisplayPaneThreeReducer);

  const closeRelatedListOne = () => {
    closeRelatedList(
      dispatch,
      createMode,
      responseObject,
      middlePaneHeaderDuplicate,
      middlePaneHeaderDuplicateBadgeOne,
      middlePaneHeaderDuplicateBadgeTwo,
      middlePaneHeaderDuplicateBadgeThree,
      middlePaneHeaderDuplicateBadgeFour,
      'assessmentDistinctReviewList',
      duplicateScanCount,
      'assessmentsDistinct',
      duplicateScanCount,
      middlePaneHeaderDuplicateOne,
      middlePaneHeaderDuplicateOneBadgeOne,
      middlePaneHeaderDuplicateOneBadgeTwo,
      middlePaneHeaderDuplicateOneBadgeThree,
      middlePaneHeaderDuplicateOneBadgeFour,
    );
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  };
  const closeRelatedListTwo = () => {
    let listingData = relatedReviewListDistinctData[0];
    let reviseResponseObj = {
      countTotal: listingData?.scales.length || 0,
      responseObject: [
        {
          scales: listingData?.scales || [],
          assessmentName: listingData.assessmentName,
          assessmentDescription: listingData.assessmentDescription,
          assessmentStatus: listingData.assessmentStatus
        }
      ]
    };
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'relatedReviewListDistinctData',
        value: reviseResponseObj.responseObject
      }
    });
    dispatch({
      type: SET_MIDDLEPANE_STATE,
      payload: {
        middlePaneHeader: middlePaneHeaderDuplicate,
        middlePaneHeaderBadgeOne: middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderBadgeTwo: middlePaneHeaderDuplicateBadgeTwo,
        middlePaneHeaderBadgeThree: middlePaneHeaderDuplicateBadgeThree,
        middlePaneHeaderBadgeFour: middlePaneHeaderDuplicateBadgeFour,
        middlePaneHeaderDuplicate:middlePaneHeaderDuplicate,
        middlePaneHeaderDuplicateBadgeOne:middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderDuplicateBadgeTwo:middlePaneHeaderDuplicateBadgeTwo,
        middlePaneHeaderDuplicateBadgeThree:middlePaneHeaderDuplicateBadgeThree,
        middlePaneHeaderDuplicateOne:middlePaneHeaderDuplicateOne,
        middlePaneHeaderDuplicateOneBadgeOne:middlePaneHeaderDuplicateOneBadgeOne,
        middlePaneHeaderDuplicateOneBadgeTwo:middlePaneHeaderDuplicateOneBadgeTwo,
        typeOfMiddlePaneList: 'assessmentscalesReviewList',
        scanCount: reviseResponseObj?.countTotal,
        duplicateScanCount:duplicateScanCount,
        showMiddlePaneState: true
      }
    });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  };
  const listDistinctData = relatedReviewListDistinctData[0];

  const openListPopup = (e) => {
    console.log(e.currentTarget.getAttribute('tag'));
    console.log('listDistinctData', listDistinctData);
    let tempArr = [];
    let stats = e.currentTarget.getAttribute('status');
    let permissionObject = signedAssesseePermission?.assessmentDistictPermission?.assesseeAssessmentDistinctPermission;
    let popupContentArr = getPopUpOptionList(SECTION_SCALE_CLUSTER_REVIEW_LIST_POPUP_OPTION, permissionObject);
    popupContentArr.map((element) => {
      if (
        stats === 'PUBLISHED' &&
        (element.data === 'revise' ||
          element.data === 'allocate' ||
          element.data === 'delete' ||
          element.data === 'archive' ||
          element.data === 'flag' ||
          element.data === 'suspend' ||
          element.data === 'terminate')
      )
        tempArr.push({ ...element, disabled: true });
      else if (element.data==='allocate' || 
      element.data === 'archive' ||
      element.data === 'flag' ||
      element.data === 'suspend' ||
      element.data === 'terminate')
        tempArr.push({ ...element, disabled: true });
      else tempArr.push(element);
    });
    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: 'assessment',
        popupHeaderOneBadgeOne: 'metrics',
        isPopUpValue: '',
        popupOpenType: 'primary',
        popupContentArrValue: tempArr,
        selectedTagValue: parseInt(e.currentTarget.getAttribute('tag')),
        selectedTagStatus: e.currentTarget.getAttribute('status')
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: tempArr
      }
    });
  };
  console.log('listDistinctData', listDistinctData);
  return (
    <div>
      {listDistinctData && (
        <React.Fragment>
          <Card
            textOneOne={listDistinctData?.assessmentName}
            textTwoOne={listDistinctData?.assessmentDescription}
            IconOne={CrossIcon}
            isIcon={true}
            labelTwoTwo={'distinct'}
            onClickIconOne={closeRelatedListOne}
            isAlliance
            relatedCardFixed={true}
            className={'iguru-iconboxSVG'}
          />
          <Card
            textOneOne={listDistinctData?.subscale?.assessmentScaleName}
            textTwoOne={listDistinctData?.subscale?.assessmentScaleDescription}
            IconOne={CrossIcon}
            isIcon={true}
            labelTwoTwo={'distinct'}
            onClickIconOne={closeRelatedListTwo}
            isAlliance
            relatedCardFixed={true}
            className={'iguru-iconboxSVG'}
          />
        </React.Fragment>
      )}
      {listDistinctData &&
        listDistinctData?.subscale.assessmentScaleMetric.map((item, index) => {
          return (
            <div className="containerPadding" key={index}>
              <ReviewList
                className=""
                id={index}
                tag={index}
                //isSelectedReviewList={middlePaneSelectedValue === item.id}
                status={''}
                actualStatus={listDistinctData?.assessmentStatus}
                textOne={item?.assessmentScaleMetricLabel}
                textTwo={item?.assessmentScaleMetricDescription}
                isTooltipActive={false}
                onClickEvent={openListPopup}
                isSelectActive={isSelectActive}
                isSelected={selectedTagsArray.includes(index.toString())}
                onClickCheckBox={(event) => {
                  onClickCheckBoxSelection(selectedTagsArray, unselectedTagsArray, event, dispatch);
                }}
              />
            </div>
          );
        })}
    </div>
  );
};
export default AssessmentDistinctSubScaleDistinct;
