import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CLEAR_DISPLAY_PANE_THREE,
  POPUP_OPEN,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MIDDLEPANE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_POPUP_STATE
} from '../actionType';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import { ASSESSEE_REPORT_POPUP } from '../PopUpConfig';
import Card from '../Molecules/Card/Card';
import CrossIcon from '@material-ui/icons/Clear';
import { REPORT_URL } from '../endpoints';
import store from '../store';

const AssignmentDistinctReportReviewList = (props) => {
  const dispatch = useDispatch();
  const {
    middlePaneSelectedValue,
    reviewListDistinctData,
    relatedReviewListDistinctData,
    selectedAssociateInfo
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { responseObject, headerOne, headerOneBadgeOne } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  const { assesseeAssignment } = responseObject;
  const closeRelatedList = () => {
    dispatch({
      type: SET_MIDDLEPANE_STATE,
      payload: {
        middlePaneHeader: 'assignments',
        middlePaneHeaderBadgeOne: 'distinct',
        middlePaneHeaderBadgeTwo: 'active',
        middlePaneHeaderBadgeThree: '',
        middlePaneHeaderBadgeFour: '',
        typeOfMiddlePaneList: 'assignmentDistinctReviewList',
        scanCount: reviewListDistinctData.length,
        showMiddlePaneState: true,
        middlePaneHeaderDuplicate:
          store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
        middlePaneHeaderDuplicateBadgeOne:
          store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderDuplicateBadgeTwo:
          store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
        middlePaneHeaderDuplicateBadgeThree:
          store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree, //
        middlePaneHeaderDuplicateBadgeFour:
          store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour, //
        middlePaneHeaderDuplicateOne: store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOne,
        middlePaneHeaderDuplicateOneBadgeOne: store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeOne,
        middlePaneHeaderDuplicateOneBadgeTwo: store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeTwo,
        middlePaneHeaderDuplicateOneBadgeThree: store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeThree,
        middlePaneHeaderDuplicateOneBadgeFour: store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeFour,
        duplicateScanCount: store.getState().DisplayPaneTwoReducer.duplicateScanCount,
      }
    });
    dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  };
  const listDistinctData = relatedReviewListDistinctData[0];
  const openListPopup = (e) => {
    console.log(e.currentTarget.getAttribute('tag'));
    if (e.currentTarget.getAttribute('tag') === '9' || e.currentTarget.getAttribute('tag') === '1') {
      let optArr = ASSESSEE_REPORT_POPUP;
      let tempArr = [];
      optArr.map((element) => {
        if (element.data === 'review') {
          tempArr.push(element);
        }
      });
      tempArr = [
        { ...tempArr[0], data: 'assessees', dataValue: 'reviewReport', dataKey: 'reviewReport', permissionValue: 'review' }
      ];

      dispatch({
        type: SET_POPUP_STATE,
        payload: {
          popupHeaderOne: 'assignment',
          popupHeaderOneBadgeOne: 'report',
          isPopUpValue: '',
          popupOpenType: 'primary',
          popupContentArrValue: tempArr,
          selectedTagValue: e.currentTarget.getAttribute('tag'),
          selectedTagStatus: e.currentTarget.getAttribute('status')
        }
      });
      dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: {
          stateName: 'middlePaneListPopupOptions',
          value: tempArr
        }
      });
    }
  };

  const openAssesseeReportPDF = () => {
    let signedAssesseeId = selectedAssociateInfo?.assesseeId;
    let signedAssociateId =
      selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary;
    let signedAssociateName = selectedAssociateInfo?.associate?.informationBasic.associateName;
    let reportUrl = '';
    if (headerOne === 'assessee') {
      if (signedAssociateName === 'Richard McConkey') {
        reportUrl = REPORT_URL + 'richReport';
      } else if (signedAssociateName === 'Prafulta') {
        reportUrl = REPORT_URL + 'reports';
      } else if (signedAssociateName === 'Boppo Technologies') {
        reportUrl = REPORT_URL + 'reports';
      } else {
        reportUrl = REPORT_URL + 'richReport';
      }
      let assesseeId = responseObject?.assesseeAssignment?.assesseeId;
      let assesseeAssignmentId = responseObject?.assesseeAssignment?.assesseeAssignmentId;
      let token =
        localStorage.getItem('token') + '@@@@' + signedAssesseeId + '@@@@' + signedAssociateId;
      window.open(
        reportUrl +
        '?assesseeId=' +
        assesseeId +
        '&assesseeAssignmentId=' +
        assesseeAssignmentId +
        '&token=' +
        token +
        ''
      );
    }
    if (headerOne === 'assignment') {
      reportUrl = REPORT_URL + 'meritlist/';
      let assesseeAssignmentId = responseObject?.assignmentId;
      let token =
        localStorage.getItem('token') + '@@@@' + signedAssesseeId + '@@@@' + signedAssociateId;
      window.open(reportUrl + '?assignmentId=' + assesseeAssignmentId + '&token=' + token + '');
    }
  };
  return (
    <div>
      {listDistinctData && (
        <Card
          textOneOne={listDistinctData?.assignmentName}
          textTwoOne={listDistinctData?.assignmentDescription}
          IconOne={CrossIcon}
          isIcon={true}
          labelTwoTwo={'report'}
          onClickIconOne={closeRelatedList}
          isAlliance
          relatedCardFixed={true}
          className={'iguru-iconboxSVG'}
        />
      )}
      {listDistinctData &&
        listDistinctData.report.map((item, index) => {
          return (
            <div className="containerPadding" key={index}>
              <ReviewList
                className=""
                id={index}
                tag={item.id}
                isSelectedReviewList={middlePaneSelectedValue === item.id}
                status={''}
                actualStatus={''}
                textOne={item.reportName}
                textTwo={item.reportDescription}
                isTooltipActive={false}
                onClickEvent={openListPopup}
                isListIconActive={true}
                isReportIcon={true}
                onClickListIcon={item?.id === 9 || item?.id === 1 ? openAssesseeReportPDF : () => { }}
              />
            </div>
          );
        })}
    </div>
  );
};
export default AssignmentDistinctReportReviewList;
