import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CLEAR_DISPLAY_PANE_THREE,
  FILTERMODE_ENABLE,
  POPUP_OPEN,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MIDDLEPANE_STATE,
  SET_POPUP_STATE,
  RELATED_REVIEWLIST_DISTINCT_DATA,
  GET_ASSESSEE_ASSIGNMENT_SAGA,
  LOADER_START,
  SET_PAGE_COUNT,
  SET_RELATED_REQUEST_OBJECT
} from '../actionType';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import { FilterList } from '@material-ui/icons';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import {
  REVIEW_LIST_POPUP_OPTION
} from '../PopUpConfig';
import Card from '../Molecules/Card/Card';
import CrossIcon from '@material-ui/icons/Clear';
import { onClickCheckBoxSelection } from '../Actions/AssesseeModuleAction';
import { closeRelatedList, getPopUpOptionList } from '../Actions/GenericActions';

const AssesseeAssignmentAssessmentDistinctReviewList = (props) => {
  const dispatch = useDispatch();
  const { FilterModeEnable, FilterMode } = useSelector((state) => state.FilterReducer);

  const {
    middlePaneSelectedValue,
    reviewListDistinctData,
    selectedAssociateInfo,
    relatedReviewListDistinctData,
    middlePaneHeaderBadgeOne,
    selectedTagsArray,
    unselectedTagsArray,
    isSelectActive,
    middlePaneHeaderDuplicate,
    middlePaneHeaderDuplicateBadgeOne,
    middlePaneHeaderDuplicateBadgeTwo,
    middlePaneHeaderDuplicateBadgeThree,
    middlePaneHeaderDuplicateBadgeFour,
    middlePaneHeaderDuplicateOne,
    middlePaneHeaderDuplicateOneBadgeOne,
    middlePaneHeaderDuplicateOneBadgeTwo,
    middlePaneHeaderDuplicateOneBadgeThree,
    middlePaneHeaderDuplicateOneBadgeFour,
    duplicateScanCount,
    countPage,
    previousMiddlePaneState,
    signedAssesseePermission
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const {responseObject, createMode } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  const closeRelatedListTwo = () => {
    closeRelatedList(
      dispatch,
      createMode,
      responseObject,
      middlePaneHeaderDuplicate,
      middlePaneHeaderDuplicateBadgeOne,
      middlePaneHeaderDuplicateBadgeTwo,
      middlePaneHeaderDuplicateBadgeThree,
      middlePaneHeaderDuplicateBadgeFour,
      'assesseeDistinctAssignmentDistinctReviewList',
      relatedReviewListDistinctData[0]?.assesseeAssignment?.length,
      'assesseeAssignment',
      duplicateScanCount,
      middlePaneHeaderDuplicateOne,
      middlePaneHeaderDuplicateOneBadgeOne,
      middlePaneHeaderDuplicateOneBadgeTwo,
      middlePaneHeaderDuplicateOneBadgeThree,
      middlePaneHeaderDuplicateOneBadgeFour,
    );

    dispatch({ type: RELATED_REVIEWLIST_DISTINCT_DATA, payload: [] });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  };
  const closeRelatedListOne = () => {
    // dispatch({
    //   type: SET_MIDDLEPANE_STATE,
    //   payload: {
    //     middlePaneHeader: 'assessees',
    //     middlePaneHeaderBadgeOne: 'distinct',
    //     middlePaneHeaderBadgeTwo: 'active',
    //     typeOfMiddlePaneList: previousMiddlePaneState,
    //     scanCount: reviewListDistinctData.length,
    //     showMiddlePaneState: true
    //   }
    // });
    closeRelatedList(
      dispatch,
      createMode,
      responseObject,
      middlePaneHeaderDuplicateOne,
      middlePaneHeaderDuplicateOneBadgeOne,
      middlePaneHeaderDuplicateOneBadgeTwo,
      middlePaneHeaderDuplicateOneBadgeThree,
      middlePaneHeaderDuplicateOneBadgeFour,
      previousMiddlePaneState,
      duplicateScanCount,
      'assesseeDistinct',
      duplicateScanCount,
      middlePaneHeaderDuplicateOne,
      middlePaneHeaderDuplicateOneBadgeOne,
      middlePaneHeaderDuplicateOneBadgeTwo,
      middlePaneHeaderDuplicateOneBadgeThree,
      middlePaneHeaderDuplicateOneBadgeFour,
    );

    dispatch({ type: RELATED_REVIEWLIST_DISTINCT_DATA, payload: [] });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  };
  const onClickFooter = (e) => {
    let siftValue = e.currentTarget.getAttribute('data-value');
    if (
      siftValue === 'suspended' ||
      siftValue === 'terminated' ||
      siftValue === 'started' ||
      siftValue === 'unstarted' ||
      siftValue === 'aborted' ||
      siftValue === 'finished' ||
      siftValue === 'unfinished'
    ) {
      //todo
      console.log("siftValue", siftValue);
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: "relatedReviewListDistinctData", value: [] },
      });

      let Listid = relatedReviewListDistinctData[0]?.assesseeAssignment.filter((ddd) => {
        return ddd.assesseeAssignmentId === relatedReviewListDistinctData[0]?.assesseeAssignmentInfo.assesseeAssignmentId
      });
      let listData = Listid[0]?.assesseeAssignmentAssessmentDistinct;
      let selectedAssesseeSorted = listData?.filter((dd) => {
        console.log("dd", dd);
        return (
          dd.assesseeAssignmentAssessmentStatus === siftValue?.toUpperCase()
        );
      });
      let SortData = {
        ...relatedReviewListDistinctData[0],
        assesseeAssignmentInfo: {
          ...relatedReviewListDistinctData[0].assesseeAssignmentInfo,
          ...relatedReviewListDistinctData[0].assesseeId,
          assesseeAssignmentAssessmentDistinct: selectedAssesseeSorted,
        },
      };
      console.log("SortData", SortData);
      console.log("selectedAssesseeSorted", selectedAssesseeSorted);
      console.log("listData", listData);
      console.log("Listid", Listid);

      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'relatedReviewListDistinctData', value: [SortData] }
      });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: {
          stateName: 'scanCount',
          value: selectedAssesseeSorted.length
        }
      });
    }
    dispatch({ type: FILTERMODE_ENABLE });
  };
  const primaryIcon = [{ label: 'sift', onClick: onClickFooter, Icon: FilterList }];
  const secondaryIconOne = [
    { label: 'started', onClick: onClickFooter, Icon: FilterList },
    { label: 'unstarted', onClick: onClickFooter, Icon: FilterList }
  ];
  const secondaryIconTwo = [
    { label: 'aborted', onClick: onClickFooter, Icon: FilterList },
    { label: 'finished', onClick: onClickFooter, Icon: FilterList },
    { label: 'suspended', onClick: onClickFooter, Icon: FilterList },
    { label: 'terminated', onClick: onClickFooter, Icon: FilterList },
    { label: 'unfinished', onClick: onClickFooter, Icon: FilterList }
  ];

  //const listDistinctData = relatedReviewListDistinctData[0];
  const openListPopup = (e) => {
    console.log(e.currentTarget.getAttribute('tag'));
    let newObj3 = {
      data: 'reset',
      dataValue: 'reset',
      dataKey: 'reset',
      optionClass: 'optionPrimary',
      disabled: false,
      // permissionValue: 'revise'
    };
    let assesseeDistinctPermission = signedAssesseePermission?.assesseeDistinctPermission?.assesseeAssesseeDistinctPermission;
    let optionArr = [...REVIEW_LIST_POPUP_OPTION];
    optionArr.splice(5, 0, newObj3);

  let popupContentArrValue = getPopUpOptionList(
    optionArr,
    assesseeDistinctPermission
  );
    let tempArr = [];
    popupContentArrValue.forEach((element) => {
      if (element.data === 'revise' || element.data === 'share' || element.data === 'allocate' || element.data === 'archive' || element.data === 'delete' || element.data === 'flag' || element.data === 'review' || element.data === 'select' || element.data === 'suspend' || element.data === 'terminate') {
        tempArr.push({ ...element, disabled: true });
      } else {
        tempArr.push(element);
      }
    });
    
    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: 'assessment',
        popupHeaderOneBadgeOne: '',
        popupHeaderOneBadgeTwo: '',
        isPopUpValue: '',
        popupOpenType: 'primary',
        popupContentArrValue: tempArr,
        selectedTagValue: e.currentTarget.getAttribute('tag'),
        selectedTagStatus: e.currentTarget.getAttribute('status')
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: tempArr
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
  };
  // const listDistinctData = reviewListDistinctData.filter((x) => x.id === selectedTagValue);
  const listDistinctData = relatedReviewListDistinctData[0];
  console.log('FilterMode', FilterMode);
  console.log('relatedReviewListDistinctData', relatedReviewListDistinctData);
  console.log('listDistinctData', relatedReviewListDistinctData[0]);

  return (
    <div>
      {listDistinctData && (
        <Fragment>
          <Card
            textOneOne={
              listDistinctData?.assesseeInfo?.assesseeNameFirst +
              ' ' +
              listDistinctData?.assesseeInfo?.assesseeNameOther +
              ' ' +
              listDistinctData?.assesseeInfo?.assesseeNameLast +
              ' ' +
              listDistinctData?.assesseeInfo?.assesseeNameSuffix
            }
            textTwoOne={listDistinctData?.assesseeInfo?.assesseeAlias}
            IconOne={CrossIcon}
            isIcon={true}
            labelTwoTwo={'distinct'}
            onClickIconOne={closeRelatedListOne}
            isAlliance
            relatedCardFixed={true}
            className={'iguru-iconboxSVG'}
          />
          <Card
            textOneOne={
              listDistinctData?.assesseeAssignmentInfo?.assesseeAssignmentName
            }
            textTwoOne={listDistinctData?.assesseeAssignmentInfo?.assesseeAssignmentDescription}
            IconOne={CrossIcon}
            isIcon={true}
            labelTwoTwo={'distinct'}
            onClickIconOne={closeRelatedListTwo}
            isAlliance
            relatedCardFixed={true}
            className={'iguru-iconboxSVG'}
          />
        </Fragment>
      )}
      {listDistinctData?.assesseeAssignmentInfo?.assesseeAssignmentAssessmentDistinct?.length > 0 &&
        listDistinctData?.assesseeAssignmentInfo?.assesseeAssignmentAssessmentDistinct?.map((item, index) => {
          return (
            <div className="containerPadding" key={index}>
              <ReviewList
                className=""
                id={index}
                tag={item.assesseeAssignmentAssessmentId}
                isSelectedReviewList={middlePaneSelectedValue === item.assesseeAssignmentAssessmentId}
                status={item.assesseeAssignmentAssessmentStatus === 'UNFINISHED' ? 'TIMEOUT' : item.assesseeAssignmentAssessmentStatus}
                actualStatus={item.assesseeAssignmentAssessmentStatus}
                textOne={item.assesseeAssignmentAssessmentName}
                textTwo={item.assesseeAssignmentAssessmentDescription}
                isTooltipActive={false}
                onClickEvent={openListPopup}
                isSelectActive={isSelectActive}
                isSelected={selectedTagsArray.includes(item.assesseeAssignmentAssessmentId)}
                isDelivery={false}
                onClickCheckBox={(event) => {
                  onClickCheckBoxSelection(selectedTagsArray, unselectedTagsArray, event, dispatch);
                }}
              />
            </div>
          );
        })}
      {/* {FilterMode === 'assesseeAssignmentAssessmentactive' || FilterMode === 'assesseeAssignmentAssessmentinactive' && (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={primaryIcon}
          secondaryIcon={
            FilterMode === 'assesseeAssignmentAssessmentactive' ? secondaryIconOne : secondaryIconTwo
          }
        /> 
      )}*/}
    </div>
  );
};
export default AssesseeAssignmentAssessmentDistinctReviewList;
