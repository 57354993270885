import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PopUpTextField from '../../PopUpInformation/PopUpTextField';
import PopUpConfirm from '../../PopUpGeneric/PopUpConfirm';
import {
  POPUP_CLOSE,
  LOADER_START,
  SET_POPUP_VALUE,
  GET_ASSIGNMENT_REPORT_SAGA,
  SET_DISPLAY_TWO_SINGLE_STATE
} from '../../actionType';
import PopUpReviewList from '../../PopUpInformation/PopUpReviewList';
import PopUpReport from '../../PopUpInformation/PopUpReport';

const PopUpAssignmentReport = (props) => {
  const { headerOne } = props;
  const { isPopUpValue, selectedTagValue } = useSelector((state) => state.PopUpReducer);
  const { reviewMode, responseObject, responseObj } = useSelector((state) => state.DisplayPaneThreeReducer);
  const { assignmentResponseObject, selectedAssociateInfo } = useSelector(
    (state) => state.DisplayPaneTwoReducer
  );
  const dispatch = useDispatch();
  const cultureList = [];
  const tempArr =
    assignmentResponseObject?.informationFramework?.assignmentCultureProfileDistinctPublished || [];
  tempArr.forEach((ob) => {
    cultureList.push({
      id: ob?.id + '-',
      textOne: ob?.informationBasic.cultureProfileName || '',
      textTwo: ob?.informationBasic.cultureProfileDescription || '',
      status: ''
    });
  });
  const jobList = [];
  const tempArr2 =
    assignmentResponseObject?.informationFramework?.assignmentJobProfileDistinctPublished || [];
  tempArr2.forEach((ob) => {
    jobList.push({
      id: ob?.id + '-',
      textOne: ob?.informationBasic.jobProfileName || '',
      textTwo: ob?.informationBasic.jobProfileDescription || '',
      status: ''
    });
  });
  console.log('assignmentResponseObject', assignmentResponseObject);
  const onClickGaugeSelect = (e) => {
    let tag = e.currentTarget.getAttribute('tag');
    if (tag === 'Merit-List (Culture-Fit)') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'CULTURELISTPOPUP', popupMode: 'ASSIGNMENT_REPORT_REVIEW' }
      });
    }
    if (tag === 'Merit-List (Job-Fit)') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'JOBLISTPOPUP', popupMode: 'ASSIGNMENT_REPORT_REVIEW' }
      });
    }
    if (tag === 'Merit-List (Work-Fit)') {
      dispatch({ type: LOADER_START });
      let requestObj = {
        module: 'Assessee-Assignment',
        action: 'Merit-List',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          assignmentId: assignmentResponseObject.id
        }
      };
      dispatch({
        type: GET_ASSIGNMENT_REPORT_SAGA,
        payload: {
          request: requestObj,
          assignmentId: assignmentResponseObject.id,
          assignmentName: assignmentResponseObject.informationBasic.assignmentName,
          assignmentDescription: assignmentResponseObject.informationBasic.assignmentDescription,
          reportId: tag,
          reportName: 'Sift-List',
          reportDescription: 'Work-Fit'
        }
      });

      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'relatedReviewListDistinctData', value: [] }
      });
      dispatch({ type: POPUP_CLOSE });
    }
  };

  let tempobj = {}, tempobj2 = {};

  // removing duplicates from jp/cp combinations 
  responseObj?.assesseeAssignmentCultureJobProfileDistinct?.forEach((data) => {
    tempobj[data.cultureProfileTag] = {
      name: data.cultureProfileDistinct.informationBasic.cultureProfileName,
      description: data.cultureProfileDistinct.informationBasic.cultureProfileDescription,
      id: data.cultureProfileTag
    }
  })
  responseObj?.assesseeAssignmentCultureJobProfileDistinct?.forEach((data) => {
    tempobj2[data.jobProfileTag] = {
      name: data.jobProfileDistinct.informationBasic.jobProfileName,
      description: data.jobProfileDistinct.informationBasic.jobProfileDescription,
      id: data.jobProfileTag
    }
  })

  let reportCPList = Object.values(tempobj)
  let reportJPList = Object.values(tempobj2)
  //

  return (
    <div>
      {isPopUpValue === 'GAUGELISTPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'assignment'}
          headerOneBadgeOne={'report'}
          headerOneBadgeTwo={''}
          nextPopUpValue={''}
          inputHeader={'gauge'}
          inputHeaderBadge={''}
          infoMsg={''}
          ListData={[
            {
              id: 'Sift-List (Culture-Fit)',
              informationBasic: { name: 'Sift-List', description: 'Culture-Fit' }
            },
            {
              id: 'Sift-List (Job-Fit)',
              informationBasic: { name: 'Sift-List', description: 'Job-Fit' }
            },
            {
              id: 'Sift-List (Work-Fit)',
              informationBasic: { name: 'Sift-List', description: 'Work-Fit' }
            }
          ]}
          textOne={'name'}
          textTwo={'description'}
          onClickEvent={onClickGaugeSelect}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'CULTURELISTPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'assignment'}
          headerOneBadgeOne={'report'}
          headerOneBadgeTwo={''}
          nextPopUpValue={''}
          inputHeader={'culture profile'}
          inputHeaderBadge={''}
          infoMsg={''}
          ListData={cultureList}
          textOne={'textOne'}
          textTwo={'textTwo'}
          onClickEvent={onClickGaugeSelect}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'JOBLISTPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'assignment'}
          headerOneBadgeOne={'report'}
          headerOneBadgeTwo={''}
          nextPopUpValue={''}
          inputHeader={'job profile'}
          inputHeaderBadge={''}
          infoMsg={''}
          ListData={jobList}
          textOne={'textOne'}
          textTwo={'textTwo'}
          onClickEvent={onClickGaugeSelect}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'JOBLISTPOPUP' && (
        <PopUpTextField
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'assignment'}
          headerOneBadgeOne={'report'}
          headerOneBadgeTwo={''}
          nextPopUpValue={''}
          inputHeader={'job profile'}
          inputHeaderBadge={''}
          infoMsg={''}
          ListData={jobList}
          textOne={'textOne'}
          textTwo={'textTwo'}
          onClickEvent={onClickGaugeSelect}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'REPORTPOPUPCP' && (
        <PopUpReport
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'assignment'}
          headerOneBadgeOne={'report'}
          headerOneBadgeTwo={''}
          inputHeader={'culture-profile'}
          inputHeaderBadge={'distinct'}
          textOne={'textOne'}
          textTwo={'textTwo'}
          list={reportCPList}
        />
      )}
      {isPopUpValue === 'REPORTPOPUPJP' && (
        <PopUpReport
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'assignment'}
          headerOneBadgeOne={'report'}
          headerOneBadgeTwo={''}
          inputHeader={'job-profile'}
          inputHeaderBadge={'distinct'}
          textOne={'textOne'}
          textTwo={'textTwo'}
          list={reportJPList}
        />
      )}
    </div>
  );
};

export default PopUpAssignmentReport;
