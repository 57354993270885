import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import Manuscript from '@material-ui/icons/Description';
import { useDispatch, useSelector } from 'react-redux';
import DisplayPanelAccordianReviewListOne from '../Accordian/DisplayPanelAccordianReviewListOne';
import DisplayPanelAccordianInformation from '../Accordian/DisplayPanelAccordianInformation';
import { Paper } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {
  FILTERMODE,
  GET_ALLOCATE_ITEM,
  GET_ALLOCATE_ITEM_GROUP,
  LOADER_START,
  SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
  SET_ASSESSMENT_SINGLE_STATE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_PANE_THREE_ASSESSMENT_SECTION_PREVIEW_MODE,
  SET_PANE_THREE_PREVIEW_MODE,
  SET_POPUP_VALUE
} from '../../actionType';
import {
  convertSecondsToHMmSs,
  getEditorDataInHtmlTemplate,
  makeItemGroupObj,
  makeItemObj,
  navigationString,
  setItemTypeConfigState
} from '../../Actions/GenericActions';
import { ASSESSMENT_SECTION_PREIVEW_SEQUENCE_ERROR } from '../../errorMessage';

const DisplayPaneThreeSectionTwoAssessmentSection = () => {
  const dispatch = useDispatch();
  const { responseObject, reviewMode } = useSelector((state) => state.DisplayPaneThreeReducer);
  const { selectedAssociateInfo, relatedReviewListDistinctData,assignmentCreateType } = useSelector(
    (state) => state.DisplayPaneTwoReducer
  );
  useEffect(() => {}, [responseObject]);
  const { sectionInformation } = useSelector((state) => state.SectionCreateReducer);
  let versionArr = [];
  responseObject?.assessmentVersionFramework?.forEach((comm, index) => {
    versionArr.push({ labelTextOneOneBadge: index + 1, textOne: '' });
  });
  let communiqueArr = [];
  responseObject?.assessmentSectionCommunique?.forEach((comm, index) => {
    communiqueArr.push({ labelTextOneOneBadge: index + 1, textOne: '' });
  });
  let manuscriptArr = [];
  responseObject?.assessmentSectionManuscript?.forEach((comm, index) => {
    manuscriptArr.push({ labelTextOneOneBadge: index + 1, textOne: '' });
  });
  let synopsisArr = [];
  responseObject?.assessmentSectionSynopsis?.forEach((comm, index) => {
    synopsisArr.push({ labelTextOneOneBadge: index + 1, textOne: '' });
  });
  let adminSequenseArr = ['item distinct'];
  sectionInformation?.assessmentSectionCommunique &&
    sectionInformation?.assessmentSectionCommunique.forEach((element, ind) => {
      adminSequenseArr.push(`section communiqué ${ind + 1}`);
    });
  sectionInformation?.assessmentSectionManuscript.forEach((element, ind) => {
    adminSequenseArr.push(`section manuscript ${ind + 1}`);
  });
  sectionInformation?.assessmentSectionSynopsis?.forEach((element, ind) => {
    adminSequenseArr.push(`section synopsis ${ind + 1}`);
  });
  const frameworkAll = [
    {
      id: 'administration',
      labelTextOneOne: 'administration',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'repeat',
          textOne: responseObject?.assessmentSectionAdministrationRepeat ? 'Yes' : 'No'
        },
        {
          labelTextOneOneBadge: 'reset',
          textOne: responseObject?.assessmentSectionAdministrationReset ? 'Yes' : 'No'
        },
        {
          labelTextOneOneBadge: 'sequence',
          textOne: responseObject?.assessmentSectionAdministrationSequence || 'No Information'
        }
      ],
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false
      // IconOne: Manuscript
    },
    {
      id: 'aid',
      labelTextOneOne: 'aid',
      labelTextOneOneBadges: [
        {
          labelTextTwoBadge: 'calculator',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'permission',
              innerLabelBadgeList: responseObject?.assessmentSectionAid
                ?.assessmentSectionAidCalculatorPermission
                ? 'Permitted'
                : 'Unpermitted'
            },
            {
              labelTextTwoBadge: 'type',
              innerLabelBadgeList:
                responseObject?.assessmentSectionAid?.assessmentSectionAidCalculatorType ||
                'No Information'
            }
          ]
        },
        {
          labelTextTwoBadge: 'spredsheet',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'permission',
              innerLabelBadgeList: responseObject?.assessmentSectionAid
                ?.assessmentSectionAidSpreadsheetPermission
                ? 'Permitted'
                : 'Unpermitted'
            },
            {
              labelTextTwoBadge: 'type',
              innerLabelBadgeList:
                responseObject?.assessmentSectionAid?.assessmentSectionAidSpreadsheetType ||
                'No Information'
            }
          ]
        },
        {
          labelTextTwoBadge: 'textsheet',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'permission',
              innerLabelBadgeList: responseObject?.assessmentSectionAid
                ?.assessmentSectionAidTextsheetPermission
                ? 'Permitted'
                : 'Unpermitted'
            },
            {
              labelTextTwoBadge: 'type',
              innerLabelBadgeList:
                responseObject?.assessmentSectionAid?.assessmentSectionAidTextsheetType ||
                'No Information'
            }
          ]
        }
      ],
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false,
      isReviewLink: false,
      isMultiInfoCard: true
      // IconOne: Manuscript
    },
    {
      id: 'communiquea1',
      labelTextOneOne: 'communiqué',
      labelTextOneOneBadges: communiqueArr,
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false,
      IconOne: reviewMode === 'revise' ? AddIcon : Manuscript,
      isAddIcon: reviewMode === 'revise' ? true : false
    },
    {
      id: 'a2',
      //textOneOne: 'No Information',
      labelTextOneOne: 'evaluation',
      textOneOne: responseObject?.assessmentSectionEvaluation ? 'Yes' : 'No',
      innerAssociateList: [],
      innerInfo: 'No Information',
      isListCard: false
    },
    {
      id: 'a4',
      labelTextOneOne: 'items',
      isListCard: false,
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'label',
          textOne:
            getEditorDataInHtmlTemplate(responseObject?.assessmentSectionItemFrameworkOneLabel) ||
            'No Information'
        },
        {
          labelTextOneOneBadge: 'navigation',
          textOne:
            navigationString(responseObject?.assessmentSectionItemNavigation) || 'No Information'
        },
        {
          labelTextOneOneBadge: 'total',
          textOne: responseObject?.assessmentSectionItemTotal || 'No Information'
        }
      ],
      innerAssociateList: [],
      innerInfo: 'assessment',
      IconOne: null
    },
    {
      id: 'm1',
      labelTextOneOne: 'manuscript',
      labelTextOneOneBadges: manuscriptArr,
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false,
      //IconOne: Manuscript
      IconOne: reviewMode === 'revise' ? AddIcon : Manuscript,
      isAddIcon: reviewMode === 'revise' ? true : false
    },
    {
      id: 'preview-assessment',
      labelTextOneOne: 'preview',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: '',
          textOne: ''
        },
        {
          labelTextOneOneBadge: '',
          textOne: ''
        }
      ],
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false,
      IconOne: null,
      isReviewLink: true
    },
    {
      id: 'response',
      labelTextOneOne: 'response',
      labelTextOneOneBadges: [
        {
          labelTextTwoBadge: 'extremum',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'maximum',
              innerLabelBadgeList:
                responseObject?.assessmentSectionResponseExtremum
                  ?.assessmentSectionResponseExtremumMaximum || 'No Information'
            },
            {
              labelTextTwoBadge: 'minimum',
              innerLabelBadgeList:
                responseObject?.assessmentSectionResponseExtremum
                  ?.assessmentSectionResponseExtremumMinimum || 'No Information'
            }
          ]
        },
        {
          labelTextTwoBadge: 'label',
          innerLabelBadgeList:
            getEditorDataInHtmlTemplate(
              responseObject?.assessmentSectionItemFrameworkOneResponseLabel
            ) || 'No Information'
        },
        {
          labelTextTwoBadge: 'revise',
          innerLabelBadgeList: responseObject?.assessmentSectionItemFrameworkOneResponseRevise
            ? 'Yes'
            : 'No'
        },
        {
          labelTextTwoBadge: 'unique',
          innerLabelBadgeList:
            (responseObject?.assessmentSectionItemFrameworkOneResponseUnique && 'Permitted') ||
            'Unpermitted'
        }
      ],
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false,
      isReviewLink: false,
      isMultiInfoCard: true
    },
    {
      id: 'score',
      labelTextOneOne: 'score',
      labelTextOneOneBadges: [
        {
          labelTextTwoBadge: 'extremum',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'maximum',
              innerLabelBadgeList:
                responseObject?.assessmentSectionScoreExtremum
                  ?.assessmentSectionScoreExtremumMaximum || 'No Information'
            },
            {
              labelTextTwoBadge: 'minimum',
              innerLabelBadgeList:
                responseObject?.assessmentSectionScoreExtremum
                  ?.assessmentSectionScoreExtremumMinimum || 'No Information'
            }
          ]
        }
      ],
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false,
      isReviewLink: false,
      isMultiInfoCard: true
    },
    // {
    //   id: 'a2',
    //   textOneOne: responseObject?.assessmentSectionSequence || 'No Information',
    //   labelTextOneOne: 'sequence',
    //   innerAssociateList: [],
    //   innerInfo: 'No Information',
    //   isListCard: false
    // },
    {
      id: 'synopsis',
      labelTextOneOne: 'synopsis',
      labelTextOneOneBadges: synopsisArr,
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false,
      IconOne: reviewMode === 'revise' ? AddIcon : Manuscript,
      isAddIcon: reviewMode === 'revise' ? true : false
    },
    {
      id: 'a6',
      labelTextOneOne: 'time',
      isListCard: false,
      textOneOne: convertSecondsToHMmSs(responseObject?.assessmentSectionTime) || 'No Information',
      innerAssociateList: [],
      innerInfo: 'No Information',
      IconOne: null
    },
    {
      id: 'versiona1',
      labelTextOneOne: 'version',
      labelTextOneOneBadges: versionArr,
      innerAssociateList: [],
      innerInfo: '',
      isListCard: false
    }
  ];
  const reviseFramework = (e, selectedBadgeArray) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    let badgeName = '';
    if (selectedBadgeArray) {
      if (selectedBadgeArray.length > 0) {
        selectedBadgeArray.forEach((element) => {
          badgeName = badgeName + element.labelTextTwoBadge;
        });
      }
    }
    if (labelName === 'items' && selectedBadgeName === 'group') {
      let requestObect = makeItemGroupObj(selectedAssociateInfo, 'active', -1, -1,'list',assignmentCreateType);
      let revisedGroupObject = {
        id: relatedReviewListDistinctData[0].id,
        assessmentSectionName: responseObject.assessmentSectionName,
        assessmentSectionDescription: responseObject.assessmentSectionDescription
      };
      let existingItemId = [];
      dispatch({
        type: FILTERMODE,
        payload: { FilterMode: 'assessmentSectionItemGroupRevise' }
      });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'relatedReviewListDistinctData', value: [] }
      });
      dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
      dispatch({ type: LOADER_START });
      dispatch({
        type: GET_ALLOCATE_ITEM_GROUP,
        payload: {
          request: requestObect,
          revisedGroupObject: revisedGroupObject,
          existingItemId: existingItemId,
          typeOfMiddlePaneList: 'assessmentSectionItemGroupDistinctReviewList'
        }
      });
    }
    if (labelName === 'items' && selectedBadgeName === 'distinct') {
      let requestObect = makeItemObj(selectedAssociateInfo, 'active', -1, -1);
      let revisedGroupObject = {
        id: relatedReviewListDistinctData[0].id,
        assessmentSectionName: responseObject.assessmentSectionName,
        assessmentSectionDescription: responseObject.assessmentSectionDescription,
        typeOfMiddlePaneList: 'assessmentSectionItemDistinctReviewList'
      };
      // let existingItemId=[];
      let existingItemId = sectionInformation?.assessmentSectionItemDistinct?.map((val) => {
        return val.itemId;
      });
      dispatch({
        type: FILTERMODE,
        payload: { FilterMode: 'assessmentSectionItemRevise' }
      });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'relatedReviewListDistinctData', value: [] }
      });
      dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
      dispatch({ type: LOADER_START });
      dispatch({
        type: GET_ALLOCATE_ITEM,
        payload: {
          request: requestObect,
          revisedGroupObject: revisedGroupObject,
          existingItemId: existingItemId,
          typeOfMiddlePaneList: 'assessmentSectionItemDistinctReviewList'
        }
      });
    }

    if (labelName === 'items' && selectedBadgeName === 'navigation') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'NAVIGATIONPOPUP', popupMode: 'SECTIONCREATE' }
      });
    }
    if (labelName === 'items' && selectedBadgeName === 'label') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ASSESSMENT_SECTION_ITEM_LABEL_POPUP', popupMode: 'SECTIONCREATE' }
      });
    }
    if (labelName === 'items' && selectedBadgeName === 'total') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ITEM_TOTAL_POPUP', popupMode: 'SECTIONCREATE' }
      });
    }
    if (labelName === 'preview') {
      if (responseObject.assessmentSectionAdministrationSequence.length > 0) {
        dispatch({ type: SET_PANE_THREE_ASSESSMENT_SECTION_PREVIEW_MODE, payload: true });
        dispatch({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: { stateName: 'assesseeAssignmentAssessmentData', value: responseObject }
        });
        if (
          responseObject?.assessmentVersionFramework[0]?.assessmentVersionItemDistinct.length > 0
        ) {
          setItemTypeConfigState(
            responseObject?.assessmentVersionFramework[0]?.assessmentVersionItemDistinct[0]
              .itemFrameworkOne.itemFrameworkOneType,
            dispatch
          );
        }
        dispatch({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: { stateName: 'isAssessmentStart', value: 'SECTIONPREVIEW' }
        });
        dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneSix' });
      } else {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: ASSESSMENT_SECTION_PREIVEW_SEQUENCE_ERROR,
            popupMode: 'responseErrorMsg'
          }
        });
      }
    }
    if (badgeName === 'calculator' && selectedBadgeName === 'permission') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'AID_CAL_POPUP', popupMode: 'SECTIONCREATE' }
      });
    }
    if (badgeName === 'calculator' && selectedBadgeName === 'type') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'AID_CALCULATOR_TYPE_POPUP', popupMode: 'SECTIONCREATE' }
      });
    }
    if (badgeName === 'spredsheet' && selectedBadgeName === 'permission') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'AID_SHEET_POPUP', popupMode: 'SECTIONCREATE' }
      });
    }
    if (badgeName === 'spredsheet' && selectedBadgeName === 'type') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'AID_SPREADSHEET_TYPE_POPUP', popupMode: 'SECTIONCREATE' }
      });
    }
    if (badgeName === 'textsheet' && selectedBadgeName === 'permission') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'AID_TEXT_POPUP', popupMode: 'SECTIONCREATE' }
      });
    }
    if (badgeName === 'textsheet' && selectedBadgeName === 'type') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'AID_TEXTSHEET_TYPE_POPUP', popupMode: 'SECTIONCREATE' }
      });
    }
    if (labelName === 'evaluation') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'EVALUATION_POPUP', popupMode: 'SECTIONCREATE' }
      });
    }
    if (labelName === 'administration' && selectedBadgeName === 'repeat') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'SECTION_REPEAT_POPUP', popupMode: 'SECTIONCREATE' }
      });
    }
    if (labelName === 'administration' && selectedBadgeName === 'reset') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'SECTION_RESET_POPUP', popupMode: 'SECTIONCREATE' }
      });
    }
    if (labelName === 'administration' && selectedBadgeName === 'sequence') {
      dispatch({
        type: SET_ASSESSMENT_SINGLE_STATE,
        payload: { stateName: 'assessmentAdminSequence', value: adminSequenseArr }
      });
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ADMINSEWUENCEPOPUP', popupMode: 'SECTIONCREATE' }
      });
    }
    if (labelName === 'score' && selectedBadgeName === 'maximum') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'SCOREMAXIMUMPOPUP', popupMode: 'SECTIONCREATE' }
      });
    }
    if (labelName === 'score' && selectedBadgeName === 'minimum') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'SCOREMINIMUMPOPUP', popupMode: 'SECTIONCREATE' }
      });
    }
    if (labelName === 'response' && selectedBadgeName === 'minimum') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'RESPONCE_EXTREEMINIMUMPOPUP', popupMode: 'SECTIONCREATE' }
      });
    }
    if (labelName === 'response' && selectedBadgeName === 'maximum') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'RESPONCE_EXTREEMAXIMUMPOPUP', popupMode: 'SECTIONCREATE' }
      });
    }
    if (labelName === 'response' && selectedBadgeName === 'label') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'RESPONSELABEL', popupMode: 'SECTIONCREATE' }
      });
    }
    if (labelName === 'response' && selectedBadgeName === 'revise') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'RESPONSE_REVISE_POPUP', popupMode: 'SECTIONCREATE' }
      });
    }
    if (labelName === 'response' && selectedBadgeName === 'unique') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'RESPONSE_UNIQUE_POPUP', popupMode: 'SECTIONCREATE' }
      });
    }
    if (labelName === 'sequence') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'SEQUENCE_POPUP', popupMode: 'SECTIONCREATE' }
      });
    }
    if (labelName === 'communiqué' && selectedBadgeName !== '') {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'indexPointer', value: parseInt(selectedBadgeName) }
      });
      dispatch({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'ASSESSMENT_COMMUNIQUE_PRIMARY_TEXTSHEET_POPUP',
          popupMode: 'SECTIONCREATE'
        }
      });
    }
    if (labelName === 'manuscript' && selectedBadgeName !== '') {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'indexPointer', value: parseInt(selectedBadgeName) }
      });
      dispatch({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'ASSESSMENT_MANUSCRIPT_PRIMARY_TEXTSHEET_POPUP',
          popupMode: 'SECTIONCREATE'
        }
      });
    }
    if (labelName === 'synopsis' && selectedBadgeName !== '') {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'indexPointer', value: parseInt(selectedBadgeName) }
      });
      dispatch({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'ASSESSMENT_SYNOPSIS_TEXTSHEET_POPUP',
          popupMode: 'SECTIONCREATE'
        }
      });
    }
    if (labelName === 'time') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'TIMEASSESSMENT_SECTION_POPUP', popupMode: 'SECTIONCREATE' }
      });
    }
  };

  const onClickReview = (e, badge) => {
    if (typeof e === 'object') {
      const headerOne = e.currentTarget.getAttribute('data-value');
      const badgeOne = e.currentTarget.getAttribute('data-key');
      if (headerOne === 'preview') {
        dispatch({ type: SET_PANE_THREE_ASSESSMENT_SECTION_PREVIEW_MODE, payload: true });
        dispatch({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: { stateName: 'assesseeAssignmentAssessmentData', value: responseObject }
        });
        dispatch({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: { stateName: 'isAssessmentStart', value: 'SECTIONPREVIEW' }
        });
        dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneSix' });
      }
    }
    if (reviewMode === 'review') {
      if (e === 'communiqué' && badge !== '') {
        dispatch({
          type: SET_PANE_THREE_PREVIEW_MODE,
          payload: {
            isPreviewShow: true,
            previewHeaderOne: 'assessment',
            previewHeaderOneBadgeOne: 'communiqué',
            previewHeaderOneBadgeTwo: badge,
            previewHeaderOneBadgeThree: '',
            previewInnerHTML: responseObject?.assessmentSectionCommunique[badge - 1] || ''
          }
        });
        dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
      }
      if (e === 'manuscript' && badge !== '') {
        dispatch({
          type: SET_PANE_THREE_PREVIEW_MODE,
          payload: {
            isPreviewShow: true,
            previewHeaderOne: 'assessment',
            previewHeaderOneBadgeOne: 'manuscript',
            previewHeaderOneBadgeTwo: badge,
            previewHeaderOneBadgeThree: '',
            previewInnerHTML: responseObject?.assessmentSectionManuscript[badge - 1] || ''
          }
        });
        dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
      }
      if (e === 'synopsis' && badge !== '') {
        dispatch({
          type: SET_PANE_THREE_PREVIEW_MODE,
          payload: {
            isPreviewShow: true,
            previewHeaderOne: 'assessment',
            previewHeaderOneBadgeOne: 'synopsis',
            previewHeaderOneBadgeTwo: badge,
            previewHeaderOneBadgeThree: '',
            previewInnerHTML: responseObject?.assessmentSectionSynopsis[badge - 1] || ''
          }
        });
        dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
      }
    }
    if (reviewMode === 'revise') {
      if (e === 'communiqué' && badge === '') {
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'indexPointer', value: 0 }
        });
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: 'ASSESSMENT_COMMUNIQUE_PRIMARY_TEXTSHEET_POPUP',
            popupMode: 'SECTIONCREATE'
          }
        });
      }

      if (e === 'manuscript' && badge === '') {
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'indexPointer', value: 0 }
        });
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: 'ASSESSMENT_MANUSCRIPT_PRIMARY_TEXTSHEET_POPUP',
            popupMode: 'SECTIONCREATE'
          }
        });
      }
      if (e === 'synopsis' && badge === '') {
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'indexPointer', value: 0 }
        });
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: 'ASSESSMENT_SYNOPSIS_TEXTSHEET_POPUP',
            popupMode: 'SECTIONCREATE'
          }
        });
      }
    }
  };
  return (
    <div>
      <>
        <div className={'containerPadding'}>
          <Paper className={'dossierContainerTop'}>
            {frameworkAll.map((ob) => {
              return (
                <div key={ob.id}>
                  {ob.isListCard ? (
                    <DisplayPanelAccordianReviewListOne
                      className=""
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  ) : (
                    <DisplayPanelAccordianInformation
                      accordianObject={ob}
                      mode={reviewMode}
                      onClickReview={onClickReview}
                      onClickRevise={reviseFramework}
                    />
                  )}
                </div>
              );
            })}
          </Paper>
        </div>
      </>
      {isMobile && (
        <div className={'containerPadding'} style={{ height: '55px' }}>
          {' '}
        </div>
      )}
    </div>
  );
};

export default DisplayPaneThreeSectionTwoAssessmentSection;
