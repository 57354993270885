import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ASSOCIATE_POPUP_CLOSE,
  FILTERMODE_ENABLE,
  GET_ASSOCIATE_GROUP_REVIEW_LIST_SAGA,
  LOADER_START,
  POPUP_OPEN,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_PAGE_COUNT,
  SET_POPUP_STATE,
  SET_REQUEST_OBJECT,
  GET_ASSOCIATE_TYPE_REVIEW_LIST_SAGA,
  FILTERMODE,
  ASSOCIATE_ALLOCATE_SAGA
} from '../actionType';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import { FilterList } from '@material-ui/icons';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import { getPopUpOptionList, makeAssociateTypeObj } from '../Actions/GenericActions';
import {
  ASSESSEE_NODE_ROLE_REVIEW_LIST_POPUP_OPTION,
  ASSOCIATE_NODE_ROLE_REVIEW_LIST_POPUP_OPTION,
  NODE_ROLE_TYPE_REVIEW_LIST_POPUP_OPTION
} from '../PopUpConfig';
import { getAssociatesTypeApiCall } from '../Actions/AssociateModuleAction';
import {
  assesseeRole,
  onClickCheckBoxSelection,
  onClickFlagSelection,
  setFlagedArray
} from '../Actions/AssesseeModuleAction';
import ReviseIcon from '@material-ui/icons/RadioButtonChecked';
import Check from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import ListLoader from '../Atoms/ListLoader/ListLoader';
const AssociateTypeReviewList = (props) => {
  const dispatch = useDispatch();
  // const { secondaryOptionCheckValue,  } = useSelector(
  //   (state) => state.AssesseeCreateReducer
  // );
  const {
    numberPage,
    countPage,
    scanCount,
    reviewListDistinctData,
    reviewListReqObj,
    middlePaneSelectedValue,
    selectedAssociateInfo,
    middlePaneHeader,
    isSelectActive,
    selectedTagsArray,
    unselectedTagsArray,
    middlePaneHeaderBadgeTwo,
    middlePaneHeaderBadgeOne,
    middlePaneHeaderBadgeThree,
    allocatedTagsArray,
    allocateStr,
    selectedFlagedArray,
    unselectedFlagedArray,
    flagedValue,
    signedAssesseePermission,
    isListLoading
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { FilterModeEnable, FilterMode } = useSelector((state) => state.FilterReducer);
  const { cardValue } = useSelector((state) => state.PopUpReducer);
  // const [isFetching, setIsFetching] = useState(false);
  // useEffect(() => {
  //   document.getElementById('middleComponentId').addEventListener('scroll', handleScroll);
  // }, []);
  // const handleScroll = (event) => {
  //   var targetPt = event.target;
  //   if (
  //     Math.ceil(targetPt.scrollHeight - targetPt.scrollTop) !== targetPt.clientHeight ||
  //     isFetching
  //   )
  //     return;
  //   setIsFetching(true);
  // };
  const fetchData = async () => {
    if (reviewListDistinctData.length < scanCount) {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isListLoading', value: true }
      })
      let obj = {
        ...reviewListReqObj.requestObject,
        numberPage: numberPage + 1
      };
      dispatch({
        type: GET_ASSOCIATE_TYPE_REVIEW_LIST_SAGA,
        payload: {
          request: { module: 'Associate-Type', action: 'Review-List', requestObject: obj },
          BadgeOne: middlePaneHeaderBadgeOne,
          BadgeTwo: middlePaneHeaderBadgeTwo,
          BadgeThree: middlePaneHeaderBadgeThree,
          isMiddlePaneList: true,
          isSelectActive,
          selectedTagsArray
        }
      });
      dispatch({ type: SET_PAGE_COUNT, payload: numberPage + 1 });
    }
  };
  // useEffect(() => {
  //   console.log(reviewListDistinctData);
  //   if (!isFetching) return;
  //   fetchMoreListItems();
  // }, [isFetching]);

  // const fetchMoreListItems = () => {
  //   fetchData();
  //   setIsFetching(false);
  // };
  useEffect(() => {
    setFlagedArray(reviewListDistinctData, 'associateTypeFlag', dispatch);
  }, [reviewListDistinctData]);

  const siftApiCall = (siftKey) => {
    getAssociatesTypeApiCall(
      selectedAssociateInfo,
      siftKey,
      countPage,
      dispatch,
      'types',
      'associates',
      cardValue
    );

    dispatch({ type: ASSOCIATE_POPUP_CLOSE });
    document.getElementById('middleComponentId').scrollTop = '0px';
  };
  const siftApiFilterCall = (siftKey) => {
    // getAssociatesTypeApiCall(
    //   selectedAssociateInfo,
    //   siftKey,
    //   countPage,
    //   dispatch,
    //   'types',
    //   'associates'
    // );
    let requestObj = makeAssociateTypeObj(selectedAssociateInfo, siftKey, countPage, 0);
    dispatch({ type: LOADER_START });
    dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
    dispatch({ type: SET_PAGE_COUNT, payload: 0 });
    dispatch({
      type: FILTERMODE,
      payload: { FilterMode: 'associatesTypeDistinctactive' + siftKey }
    });
    dispatch({
      type: GET_ASSOCIATE_TYPE_REVIEW_LIST_SAGA,
      payload: {
        middlePaneHeader: middlePaneHeader,
        request: requestObj,
        BadgeOne: middlePaneHeaderBadgeOne,
        BadgeTwo: middlePaneHeaderBadgeTwo === 'distinct' ? middlePaneHeaderBadgeTwo : siftKey,
        BadgeThree: middlePaneHeaderBadgeTwo === 'distinct' ? siftKey : middlePaneHeaderBadgeThree,

        isMiddlePaneList: true,
        isSelectActive: isSelectActive,
        selectedTagsArray: selectedTagsArray
      }
    });

    dispatch({ type: ASSOCIATE_POPUP_CLOSE });
    document.getElementById('middleComponentId').scrollTop = '0px';
  };
  const onClickFooter = (e) => {
    let siftValue = e.currentTarget.getAttribute('data-value');
    dispatch({ type: FILTERMODE_ENABLE });
    if (siftValue === 'suspended' || siftValue === 'terminated') siftApiCall(siftValue);
    if (siftValue === 'bespoke' || siftValue === 'generic') siftApiFilterCall(siftValue);
    if (siftValue === 'finish') {
      console.log('allocateStr', allocateStr);
      let distinctAllocateStr =
        allocateStr === 'associatesdistinct'
          ? 'associateDistinct'
          : allocateStr === 'associatesgroups'
            ? 'associateGroup'
            : allocateStr === 'associatesnodes'
              ? 'associateNode'
              : allocateStr === 'associatesroles'
                ? 'associateRole'
                : allocateStr === 'associatestypes'
                  ? 'associateType'
                  : '';
      if (distinctAllocateStr !== '' && selectedTagsArray.length !== 0) {
        if (
          distinctAllocateStr === 'associateDistinct' ||
          distinctAllocateStr === 'associateGroup' ||
          distinctAllocateStr === 'associateNode' ||
          distinctAllocateStr === 'associateRole' ||
          distinctAllocateStr === 'associateType'
        ) {
          let request = {
            module: 'Associate-Distinct',
            action: FilterMode.toLowerCase().includes('unallocate') ? 'unallocate' : 'Allocate',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              associateDistinctAllocate: {
                [distinctAllocateStr]: allocatedTagsArray
              },
              associateDistinctAllocateInformation: {
                associateType: selectedTagsArray
              }
            }
          };
          dispatch({ type: LOADER_START });
          dispatch({ type: ASSOCIATE_ALLOCATE_SAGA, payload: { request: request } });
          dispatch({
            type: FILTERMODE,
            payload: { FilterMode: '' }
          });
        }
      }
    }
    if (siftValue === 'cancel') {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isSelectActive', value: '' }
      });
      dispatch({
        type: FILTERMODE,
        payload: { FilterMode: '' }
      });
    }
  };
  /* for middle pane */
  const primaryIcon = [{ label: 'sift', onClick: onClickFooter, Icon: FilterList }];
  const secondaryIcon = [
    { label: 'suspended', onClick: onClickFooter, Icon: FilterList },
    { label: 'terminated', onClick: onClickFooter, Icon: FilterList }
  ];
  const secondaryOneIcon = [
    { label: 'bespoke', onClick: onClickFooter, Icon: FilterList },
    { label: 'generic', onClick: onClickFooter, Icon: FilterList }
    // { label: 'shared', onClick: onClickFooter, Icon: FilterList },
    // { label: 'unshared', onClick: onClickFooter, Icon: FilterList }
  ];

  let signedAssociateId =
    selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary;

  const openListPopup = (e) => {
    console.log(e.currentTarget.getAttribute('tag'));
    let tempArr = [];
    let classification = e.currentTarget.getAttribute('data-classification');
    let isShared = e.currentTarget.getAttribute('data-shared');
    let associateId = e.currentTarget.getAttribute('associateid');
    let status = e.currentTarget.getAttribute('status');
    let associateDistinctPermission =
      signedAssesseePermission?.associateDistinctPermission?.assesseeAssociateTypePermission;
    let popupArr1 =
      cardValue === 'Card'
        ? NODE_ROLE_TYPE_REVIEW_LIST_POPUP_OPTION
        : ASSESSEE_NODE_ROLE_REVIEW_LIST_POPUP_OPTION;
    let popupContentArrValue = popupArr1.map((obj) =>
      obj.data === 'assessees' ? { ...obj, data: middlePaneHeader, dataValue: 'associates' } : obj
    );
    let popupArr = getPopUpOptionList(popupContentArrValue, associateDistinctPermission);
    popupArr.map((element) => {
      //ASSOCIATE_NODE_ROLE_REVIEW_LIST_POPUP_OPTION.map((element) => {
      if (classification === 'Bespoke' && element.data === 'share' && status === 'ACTIVE')
        tempArr.push({ ...element, disabled: true });
      else if (
        isShared === 'true' &&
        associateId !== signedAssociateId &&
        (element.data === 'revise' ||
          element.data === 'archive' ||
          element.data === 'flag' ||
          element.data === 'delete' ||
          element.data === 'suspend' ||
          element.data === 'move' ||
          element.data === 'terminate')
      ) {
        tempArr.push({ ...element, disabled: true });
      } else if (
        (status === 'TERMINATED' || status === 'SUSPENDED' || status === 'ARCHIVED') &&
        (element.data === 'allocate' ||
          element.data === 'revise' ||
          element.data === 'select' ||
          element.data === 'share')
      ) {
        tempArr.push({ ...element, disabled: true });
      } else tempArr.push(element);
    });
    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: middlePaneHeader,
        popupHeaderOneBadgeOne: 'type',
        popupHeaderOneBadgeTwo: '',
        isPopUpValue: '',
        popupOpenType: 'primary',
        popupContentArrValue: tempArr, //ASSOCIATE_NODE_ROLE_REVIEW_LIST_POPUP_OPTION,
        originPrimaryArrOprion: tempArr,
        selectedTagValue: e.currentTarget.getAttribute('tag'),
        selectedTagStatus: e.currentTarget.getAttribute('status'),
        selectedTagGroupId: e.currentTarget.getAttribute('data-value'),
        selectedTagShared: e.currentTarget.getAttribute('data-shared')
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: tempArr //ASSOCIATE_NODE_ROLE_REVIEW_LIST_POPUP_OPTION
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
  };
  console.log(FilterMode, 'FilterMode');

  // infinite scroll
  const [hasMore, setHasMore] = useState(true);
  let elementRef = useRef(null);

  const onIntersection = (entries) => {
    const firstEntry = entries[0];
    reviewListDistinctData.length >= scanCount ? setHasMore(false) : setHasMore(true)
    if (firstEntry.isIntersecting && hasMore && !isListLoading) {
      fetchData();
    }
  };

  useEffect(() => {
    // console.log('####MYREF######', elementRef)
    const observer = new IntersectionObserver(onIntersection, { threshold: 0.90 });

    if (observer && elementRef.current) {
      // console.log('here at observer...', elementRef.current)
      observer.observe(elementRef.current);
    }

    return () => {
      
      if (observer) observer.disconnect();
    };
  }, [isListLoading, hasMore, reviewListDistinctData, selectedTagsArray, unselectedTagsArray, isSelectActive]);

  // infinite scroll end

  return (
    <div>
      {reviewListDistinctData &&
        reviewListDistinctData.map((item, index) => {
          return (
            <div
              className="containerPadding"
              key={index}
              ref={elementRef}
            >
              <ReviewList
                className=""
                id={index}
                tag={item.id}
                associateId={item?.associateId}
                isSelectedReviewList={middlePaneSelectedValue === item.id}
                //status={associateSeftId === item.associateId ? 'bespoke' : 'generic'}
                status={
                  FilterMode === 'associatesTypeDistinctactive' ||
                    FilterMode === 'associatesTypeDistinctactivebespoke' ||
                    FilterMode === 'associatesTypeDistinctbespoke' ||
                    FilterMode === 'associatesTypeDistinctgeneric' ||
                    FilterMode === 'associatesTypeDistinctactivegeneric'
                    ? item.informationSetup?.associateTypeClassification
                      ?.associateTypeClassificationPrimary
                    : item.informationEngagement.associateTypeStatus
                }
                // status={item.informationEngagement.associateTypeStatus}
                actualStatus={item.informationEngagement.associateTypeStatus}
                //shared={item.associateTypeShared ? 'SHARED' : 'UNSHARED'}
                // textOne={item.informationBasic.associateTypeName}
                textOne={assesseeRole(item.informationBasic.associateTypeName)}
                textTwo={item.informationBasic.associateTypeDescription}
                isTooltipActive={false}
                onClickEvent={openListPopup}
                // dataValue={item.informationAllocation.associateTypeGroup}
                isSelectActive={isSelectActive}
                isSelected={selectedTagsArray.includes(item.id)}
                onClickCheckBox={(event) => {
                  !isListLoading && onClickCheckBoxSelection(selectedTagsArray, unselectedTagsArray, event, dispatch);
                }}
                // disableonClickCheckBox={item.associateId !== signedAssociateId ? true : false}
                isShared={item?.associateTypeShared}
                shared={
                  item.informationSetup?.associateTypeClassification
                    ?.associateTypeClassificationPrimary
                }
                isFlagActive={selectedFlagedArray.includes(item.id)}
                flagedValue={flagedValue}
                onClickAddFladed={(event) => {
                  onClickFlagSelection(selectedFlagedArray, unselectedFlagedArray, event, dispatch);
                }}
              />
            </div>
          );
        })}
      {isListLoading ? <div> <ListLoader /> </div> : <div style={{ height: "60px", width: "100%" }}></div>}
      {(FilterMode === 'associatesTypeDistinctinactive' ||
        FilterMode === 'associatesTypeDistinctsuspended' ||
        FilterMode === 'associatesTypeDistinctterminated') && (
          <FooterIconTwo
            FilterModeEnable={FilterModeEnable}
            FilterMode={FilterMode}
            onClick={onClickFooter}
            primaryIcon={primaryIcon}
            secondaryIcon={secondaryIcon}
          />
        )}
      {(FilterMode === 'associatesTypeDistinctactive' ||
        FilterMode === 'associatesTypeDistinctactivebespoke' ||
        FilterMode === 'associatesTypeDistinctgeneric' ||
        FilterMode === 'associatesTypeDistinctbespoke' ||
        FilterMode === 'associatesTypeDistinctactivegeneric') && (
          <FooterIconTwo
            FilterModeEnable={FilterModeEnable}
            FilterMode={FilterMode}
            onClick={onClickFooter}
            primaryIcon={primaryIcon}
            secondaryIcon={secondaryOneIcon}
          />
        )}
      {(FilterMode === 'associatesAllocateToType' || FilterMode === 'associatesUnAllocateToType') && (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={[{ label: `${FilterMode === 'associatesAllocateToType' ? 'allocate' : 'unallocate'}`, onClick: onClickFooter, Icon: ReviseIcon }]}
          secondaryIcon={[
            { label: 'cancel', onClick: onClickFooter, Icon: ClearIcon },
            { label: 'finish', onClick: onClickFooter, Icon: Check }
          ]}
        />
      )}
    </div>
  );
};
export default AssociateTypeReviewList;
