import React, { useEffect, useState, useLayoutEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import DisplayPageOne from './Pages/DisplayPageOne/DisplayPageOne';
import DisplayPageSignOn from './Pages/DisplayPageSignOn/DisplayPageSignOn';
import DisplayPageSignIn from './Pages/DisplayPageSignIn/DisplayPageSignIn';
import DisplayPageConfirmUser from './Pages/DisplayPageConfirmUser/DisplayPageConfirmUser';
import { PLATFORM_MODULE_URL, SIGN_IN_URL } from './endpoints';
import { CircularProgress } from '@material-ui/core';
import LogoutComponent from './Molecules/LogoutComponent/LogoutComponent';
// import * as Sentry from "@sentry/react";
// import { useDispatch } from 'react-redux';
// import { LOADER_START, LOADER_STOP } from './actionType';

function App() {
  const checkAuth = () => {
    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refreshToken');
    if (!token || !refreshToken) {
      return false;
    }
    // try {
    //   const { exp } = decode(refreshToken);
    //   console.log("EXP Time", exp);
    //   console.log("new Date().getTime() / 1000", new Date().getTime() / 1000); 
    //   if (exp < new Date().getTime() / 1000) {
    //     return false;
    //   }
    // } catch (e) {
    //   return false;
    // }

    return true;
  };

  // const dispatch = useDispatch();
  const AuthRoute = ({ component: Component, ...rest }) => {

    let [isLoggedIn, setIsLoggedIn] = useState('')
    useEffect(() => {
      const token = localStorage.getItem('token');
      const refreshToken = localStorage.getItem('refreshToken');
      const assesseeId = localStorage.getItem('assesseeId');

      let reqObj = {
        module: 'Iguru-Iglobal-Sign-In',
        action: 'Refresh',
        requestObject: {
          assesseeId,
          refreshToken,
          credential: assesseeId
        }
      }

      const requestOptions = {
        method: 'POST',
        body: JSON.stringify(reqObj)
      };

      async function doFetch() {
        try {

          let res = await fetch(PLATFORM_MODULE_URL, requestOptions);

          let resJson = await res.json();

          if (resJson.responseCode === '000') {
            setIsLoggedIn('success');

            localStorage.setItem('token', resJson?.responseObject?.cognitoResponse?.accessToken);
            localStorage.setItem(
              'idToken',
              resJson?.responseObject?.cognitoResponse?.tokenType +
              ' ' +
              resJson?.responseObject?.cognitoResponse?.idToken
            );

          } else {
            setIsLoggedIn('failed')
            localStorage.clear()

          }

        } catch (err) {
          console.log(err, 'ERRR TOKEN')
          setIsLoggedIn('failed')
          localStorage.clear()

        }
      }

      if (refreshToken && assesseeId) {
        doFetch();
      } else {
        setIsLoggedIn('failed')
        localStorage.clear()
      }
    }, [])


    return (

      isLoggedIn ?
        <Route
          {...rest}
          render={(props) => {
            if (isLoggedIn === 'success') return <Component {...props} />
            if (isLoggedIn === 'failed') return <Redirect to={{ pathname: SIGN_IN_URL }} />
          }
          }
        /> :
        <div style={{ display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center", width: '100vw', height: '100vh' }}>
          <CircularProgress color="primary" size={50} />processing...
        </div>
    );
  };



  return (
    <Router>
      <div className="App">
        <Switch>
          {/* <Route exact path="/" render={() => {<Redirect to={'/dashboard'}/>}} /> */}
          <Route exact path="/" render={() => <Redirect to={'/dashboard'} />} />
          <Route path="/sign-in" component={DisplayPageSignIn} exact={true} />
          <Route path="/assessee/:id" component={DisplayPageConfirmUser} exact={true} />
          <AuthRoute path="/dashboard" component={DisplayPageOne} exact={true} />
          <Route path="/sign-on/:associateTagPrimary" component={DisplayPageSignOn} exact={true} />
          <Route path="/logout" component={LogoutComponent} exact={true} />
        </Switch>
      </div>
    </Router>
  );
}

// export default Sentry.withProfiler(App);
export default App;
