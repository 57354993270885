import React, { useEffect, useState } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import Popup from '../Molecules/PopUp/PopUp';
import PopupHeader from '../Molecules/PopUp/PopUpHeader';
import FormControl from '@material-ui/core/FormControl';
import InputFeild from '../Atoms/InputField/InputField';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import '../Molecules/PopUp/PopUp.css';
import { useDispatch, useSelector } from 'react-redux';
import { POPUP_CLOSE, SET_NEXT_POPUP } from '../actionType';
import PropTypes from 'prop-types';
import { CAN_NOT_REVISED_MESSAGE, REQUIRED_ERROR_MESSAGE } from '../errorMessage';
import InputFieldEndAdornment from '../Atoms/InputField/InputFieldEndAdornment';

const PopUpTextField = (props) => {
  const dispatch = useDispatch();
  const { popupMode } = useSelector((state) => state.PopUpReducer);
  const { updateAssociateSetupFlag } = useSelector((state) => state.AssociateCreateReducer);
  const {
    setUpAssociateModule,
    analyticSetUpModule,
    itemSetUpModule,
    assessmentSetUpModule,
    assesseeSetUpModule,
    assignmentSetUpModule
  } = useSelector((state) => state.DisplayPaneThreeReducer);
  const {
    headerPanelColour = '',
    headerOne = '',
    headerOneBadgeOne = '',
    headerOneBadgeTwo = '',
    headerOneBadgeThree = '',
    isActive = false,
    isRequired = false,
    label = '',
    nextPopUpValue = '',
    basicInfo,
    typeOfSetObject,
    actualLableValue,
    labelBadgeOne = '',
    mode,
    type = 'text',
    isNotRevised = false,
    inputHeader = '',
    inputHeaderBadgeOne = '',
    inputHeaderBadgeTwo = '',
    inputHeaderBadgeThree = '',
    currencySymbol = '',
    endAdornment = '',
    issetTime = false,
    reviewMode = 'core',
    actualLableValueVerification,
    errorMsg,
    revisePermissionCheckboxLabel,
    revisePermissionField = '',
    isRevisePermissionCheckbox = false,
    objectName,
    onClickYes = null,
    setUpPlusRevisePermission = true
  } = props;
  // console.log('props', props);
  let minimumValue = type === 'number' && label === 'fee' ? -1 : -1000;

  let setUpModule = inputHeader.includes('assessee')
    ? assesseeSetUpModule
    : inputHeader.includes('assessment')
      ? assessmentSetUpModule
      : inputHeader.includes('assignment')
        ? assignmentSetUpModule
        : inputHeader.includes('analytic')
          ? analyticSetUpModule
          : inputHeader.includes('item')
            ? itemSetUpModule
            : setUpAssociateModule;

  // console.log('setUpModule---', setUpModule);
  let isNotRevisedCheck = false;
  if (revisePermissionField && setUpModule !== null) {
    isNotRevisedCheck = setUpModule[revisePermissionField] && basicInfo[revisePermissionField];
  }
  console.log('isNotRevisedCheck', isNotRevisedCheck);
  let errorMessage = !errorMsg
    ? isNotRevised || isNotRevisedCheck
      ? CAN_NOT_REVISED_MESSAGE
      : ''
    : errorMsg;
  const [state, setState] = useState({
    error: errorMessage,
    isVerified: true
  });
  const [localObject, setLocalObject] = useState(basicInfo);
  const [checkBoxValue, setCheckBoxValue] = useState(localObject[revisePermissionField]);
  const [defaultDate, setDefaultDate] = useState(
    type === 'datetime-local'
      ? new Date(
        new Date(localObject[actualLableValue]).getTime() -
        new Date(localObject[actualLableValue]).getTimezoneOffset() * 60000
      )
        .toISOString()
        .slice(0, 16)
      : ''
  );
  useEffect(() => {
    setLocalObject(basicInfo);
  }, [basicInfo]);

  const validateFun = () => {
    let isValidate = true;
    if (isRequired) {
      if (localObject) {
        if (localObject[actualLableValue]?.trim() === '') {
          setState((prevState) => ({ ...prevState, error: REQUIRED_ERROR_MESSAGE }));
          return false;
        }
      }
    }
    return isValidate;
  };
  const handleChange = (event) => {
    if (!isNotRevised) {
      let { name, value, type } = event.target;
      // console.log('name', name, 'value', value, 'type', type);
      if (type === 'number' && value > minimumValue) {
        //dispatch({ type: typeOfSetObject, payload: { ...basicInfo, [name]: value } });
        if (label === 'fee') {
          if (value && value.toString().length > 10) {
            value = parseFloat(value.toString().slice(0, -1));
          }
          parseFloat(value).toFixed();
        }

        if (label === 'validity' && endAdornment.toLowerCase().includes('month')) {
          if (parseInt(value) <= 1) value = '1';
          if (parseInt(value) > 24) value = '24';
        }

        // dispatch({ type: typeOfSetObject, payload: { ...localObject, [name]: value } });
        // dispatch({ type: typeOfSetObject, payload: { ...localObject, [name]: value, objectName } });
        setLocalObject({ ...basicInfo, [name]: value });
        if (
          label === 'validity' &&
          endAdornment.toLowerCase().includes('month') &&
          !value.toString().match(/^[1-9]\d*$/)
        ) {
          setState((prevState) => ({
            ...prevState,
            error: 'provide a valid input',
            isVerified: false
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            error: '',
            isVerified: false
          }));
        }
      } else if (type !== 'number') {
        //dispatch({ type: typeOfSetObject, payload: { ...basicInfo, [name]: value } });
        // console.log(typeof value, value);

        if (type === 'datetime-local') {
          value = new Date(new Date(value).getTime() - new Date(value).getTimezoneOffset() * 60000)
            .toISOString()
            .slice(0, 16);
          // console.log('dateTIME', value)
          setDefaultDate(value);
        }

        setLocalObject({ ...basicInfo, [name]: value });
        setState((prevState) => ({
          ...prevState,
          error: '',
          isVerified: false
        }));
      }
    }
  };
  const handleClick = () => {
    /*according to creation mode popup sequence will chanlocalObjectge*/
    if (validateFun() && !isNotRevised) {
      Object.keys(localObject).map((k) =>
        typeof localObject[k] === 'string'
          ? (localObject[k] = localObject[k]?.trim())
          : localObject[k]
      );
      if (label === 'fee') {

        if (localObject[actualLableValue] === null || localObject[actualLableValue] === undefined || localObject[actualLableValue] === '' ) {
          setState((prevState) => ({
            ...prevState,
            error: REQUIRED_ERROR_MESSAGE,
            isVerified: false
          }));
          return
        }
        
        if (!isRevisePermissionCheckbox && parseFloat(localObject[actualLableValue]) < 0) {
          setState((prevState) => ({
            ...prevState,
            error: 'fee should be greater than setup fee',
            isVerified: false
          }));
          return
        }

        localObject[actualLableValue] = parseFloat(localObject[actualLableValue]).toFixed();
      }

      if (type === 'datetime-local') {
        let tempDate = new Date(localObject[actualLableValue]);
        localObject[actualLableValue] = tempDate;
      }

      if (label === 'validity' && endAdornment.toLowerCase().includes('month')) {
        if (!localObject[actualLableValue].toString().match(/^[1-9]\d*$/)) {
          return;
        }
      }

      dispatch({ type: typeOfSetObject, payload: { ...localObject, objectName } });
      if (reviewMode === 'revise') {
        dispatch({ type: POPUP_CLOSE });
      } else {
        dispatch({ type: SET_NEXT_POPUP, payload: { isPopUpValue: nextPopUpValue } });
      }
    }
  };
  const handleCheckbox = (e) => {
    const { name, checked } = e.target;
    setLocalObject({ ...localObject, [name]: checked });
  };
  const onClickRevisePermissionCheckbox = (event) => {
    const { name, value, checked } = event.target;
    // console.log('taget', event.target);
    console.log('name, value', name, value, checked);
    if (checked) {
      if (!isNotRevised) {
        setState((prevState) => ({
          ...prevState,
          [name]: value,
          isError: ''
        }));
        setLocalObject({ ...localObject, [name]: true });
        setCheckBoxValue(true);
      }
    } else {
      if (!isNotRevised) {
        setState((prevState) => ({
          ...prevState,
          [name]: value,
          isError: ''
        }));
        setCheckBoxValue(false);
        setLocalObject({ ...localObject, [name]: false });
      }
    }
  };
  console.log('localObject', localObject);
  let updatedValue = localObject[actualLableValue]
    ? label === 'timeline' || type === 'datetime-local'
      ? defaultDate
      : localObject[actualLableValue]
    : localObject[actualLableValue] == 0
      ? localObject[actualLableValue].toString()
      : localObject[actualLableValue] === null
        ? '0'
        : '';
  // console.log('localObject[actualLableValue]', localObject[actualLableValue]);
  // console.log(revisePermissionField, localObject[revisePermissionField]);
  // console.log('isNotRevisedCheck', isNotRevisedCheck);

  return (
    <div>
      <Popup isActive={isActive}>
        <PopupHeader
          headerPanelColour={headerPanelColour}
          headerOne={headerOne}
          headerOneBadgeOne={headerOneBadgeOne}
          headerOneBadgeTwo={headerOneBadgeTwo}
          headerOneBadgeThree={headerOneBadgeThree}
          onClick={handleClick}
          mode={mode}
          isNotRevised={isNotRevisedCheck === true ? true : isNotRevised}
        />
        <DialogContent
          className={['popupContent', 'fixed10PadDim', 'revisePopupContent'].join(' ')}
        >
          {inputHeader !== '' && (
            <div className={'fitContent'}>
              <div className={['PopupFormBox', 'labelPopupBox', 'popupMinHei'].join(' ')}>
                <InputLabel htmlFor="name-input" className={'textForLabelPopup'}>
                  <>
                    {inputHeader}&nbsp;
                    {inputHeaderBadgeOne ? (
                      <span className={'headerBadge'}>{inputHeaderBadgeOne}</span>
                    ) : null}
                    &nbsp;
                    {inputHeaderBadgeTwo ? (
                      <span className={'headerBadge'}>{inputHeaderBadgeTwo}</span>
                    ) : null}
                    &nbsp;
                    {inputHeaderBadgeThree ? (
                      <span className={'headerBadge'}>{inputHeaderBadgeThree}</span>
                    ) : null}
                  </>
                </InputLabel>
                <div className={'infoSymbol'}></div>
                <div className={'infoSymbol'}>{/* <InfoToolTip message={infoMsg} /> */}</div>
              </div>
            </div>
          )}
          <FormControl style={{ width: '100%' }}>
            {endAdornment !== '' ? (
              <InputFieldEndAdornment
                id={actualLableValue}
                label={label}
                type={type}
                value={
                  localObject[actualLableValue]
                    ? label === 'timeline' || type === 'datetime-local'
                      ? defaultDate
                      : localObject[actualLableValue]
                    : ''
                }
                onClick={handleChange}
                errorMsg={state.error}
                labelBadgeOne={labelBadgeOne}
                currencySymbol={currencySymbol}
                endAdornment={
                  label === 'validity' && localObject[actualLableValue] > 1
                    ? endAdornment + 's'
                    : endAdornment
                }
                issetTime={issetTime}
                isNotRevised={isNotRevisedCheck === true ? true : isNotRevised}
                classNames={isNotRevised || isNotRevisedCheck ? 'input-disable' : ''}
              />
            ) : (
              <InputFeild
                id={actualLableValue}
                label={label}
                type={type}
                minValue={!isRevisePermissionCheckbox ? label === 'fee' && basicInfo[actualLableValue] : ''}
                // value={basicInfo ? basicInfo : localObject[actualLableValue]}
                // value={localObject[actualLableValue] ? (label === 'timeline' ?  new Date(new Date(localObject[actualLableValue]).getTime() - (new Date(localObject[actualLableValue]).getTimezoneOffset() * 60000)).toISOString().slice(0,16): localObject[actualLableValue] ) : ''}
                value={updatedValue}
                onClick={handleChange}
                errorMsg={state.error}
                labelBadgeOne={labelBadgeOne}
                currencySymbol={currencySymbol}
                endAdornment={endAdornment}
                issetTime={issetTime}
                isNotRevised={isNotRevisedCheck === true ? true : isNotRevised}
                classNames={isNotRevised || isNotRevisedCheck ? 'input-disable' : ''}
              />
            )}
          </FormControl>
          {(label === 'name' || labelBadgeOne === 'statutory' || label === 'website address') && (
            <div className={'fitContent'}>
              <div className={['PopupFormBox', 'popupMinHei0'].join(' ')} style={{ minHeight: 0 }}>
                <div className={'contFlex'}>
                  <div
                    className={'f4'}
                    style={{ color: popupMode === 'ASSOCIATE_SIGN_ON' ? 'dimgray' : '' }}
                  >
                    verification
                  </div>
                  <div className={'checkedFontNew'}>
                    <Checkbox
                      className={''}
                      name={actualLableValueVerification}
                      checked={localObject && localObject[actualLableValueVerification]}
                      onChange={handleCheckbox}
                      color="default"
                      disableRipple={true}
                      disabled={popupMode === 'ASSOCIATE_SIGN_ON' ? true : false}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {isRevisePermissionCheckbox && !updateAssociateSetupFlag && (
            <div className={'fitContent'}>
              <div className={['PopupFormBox', 'popupMinHei0'].join(' ')}>
                <div className={'contFlex'}>
                  <div className={'f4'}>{revisePermissionCheckboxLabel}</div>
                  <div className={'checkedFontNew'}>
                    <Checkbox
                      className={''}
                      color="default"
                      name={revisePermissionField}
                      checked={checkBoxValue}
                      disableRipple={true}
                      onChange={onClickRevisePermissionCheckbox}
                      disabled={isNotRevisedCheck}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </DialogContent>
      </Popup>
    </div>
  );
};

PopUpTextField.propTypes = {
  className: PropTypes.string,
  headerPanelColour: PropTypes.oneOf([
    'displayPaneLeft',
    'displayPaneCentre',
    'displayPaneRight',
    'genericOne',
    'genericTwo'
  ]),
  headerOne: PropTypes.string,
  headerOneBadgeOne: PropTypes.string,
  headerOneBadgeTwo: PropTypes.string,
  headerOneBadgeThree: PropTypes.string,
  isActive: PropTypes.bool
};
export default PopUpTextField;
