import { DialogContent } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Popup from '../Molecules/PopUp/PopUp';
import PopupHeader from '../Molecules/PopUp/PopUpHeader';
import '../Molecules/PopUp/PopUp.css';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LOADER_STOP, POPUP_CLOSE, SET_DISPLAY_TWO_SINGLE_STATE } from '../actionType';
import { getAssesseeSelfAssignmentList, resetReducerStateForTest } from '../Actions/GenericActions';
import { TIME_OUT_ERROR } from '../errorMessage';

export const PopUpMessageError = (props) => {
  // const { isActive, errorMessage = 'Error Message' } = props;
  const { isActive, errorMessage = TIME_OUT_ERROR } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    errorResponseCode,
    selectedAssociateInfo,
    countPage,
    middlePaneHeaderBadgeOne,
    copyResponseCode
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  useEffect(() => {
    if (errorMessage === 'User unauthorized') {
      localStorage.clear();
      dispatch({ type: LOADER_STOP });
      let path = `/`;
      history.push(path);
    }
  }, [history]);
  const onClosePopup = () => {
    if (
      errorResponseCode === '354' ||
      errorResponseCode === '355' ||
      errorResponseCode === '356' ||
      errorResponseCode === '316'
    ) {
      // window.location.reload();
      getAssesseeSelfAssignmentList(
        selectedAssociateInfo,
        countPage,
        ['STARTED', 'UNSTARTED', 'ADMINISTERED'],
        dispatch,
        middlePaneHeaderBadgeOne
      );
      resetReducerStateForTest(dispatch, middlePaneHeaderBadgeOne);
    }
    // else {
    //   console.log('errorResponseCode', errorResponseCode);
    //   console.log('copyResponseCode', copyResponseCode);
    // }
    dispatch({ type: SET_DISPLAY_TWO_SINGLE_STATE, payload: { stateName: "isListLoading", value: false } })
    dispatch({ type: POPUP_CLOSE });
  };
  return (
    <div>
      <Popup isActive={isActive}>
        <PopupHeader
          headerPanelColour={'genericTwo'}
          headerOne={'Error'}
          mode={'error'}
          onClosePopUpEvent={onClosePopup}
        />
        <span
          // className={['popupContent', 'fixed10PadDim-error', 'revisePopupContent', 'popup-error-align'].join(' ')}
          className={[
            'popupContent',
            'fixed10PadDim',
            'revisePopupContent',
            'popup-error-align'
          ].join(' ')}
        >
          <DialogContent className={['popup-error-content'].join(' ')}>{errorMessage}</DialogContent>
        </span>

        {/* </div> */}
      </Popup>
    </div>
  );
};
PopUpMessageError.propTypes = {
  className: PropTypes.string,
  headerPanelColour: PropTypes.oneOf([
    'displayPaneLeft',
    'displayPaneCentre',
    'displayPaneRight',
    'genericOne',
    'genericTwo'
  ]),
  headerOne: PropTypes.string,
  headerOneBadgeOne: PropTypes.string,
  headerOneBadgeTwo: PropTypes.string,
  headerOneBadgeThree: PropTypes.string,
  isActive: PropTypes.bool
};

export default PopUpMessageError;
