import React, { Fragment, useEffect, useState } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import Popup from '../Molecules/PopUp/PopUp';
import PopupHeader from '../Molecules/PopUp/PopUpHeader';
import { InputLabel, FormControl, Checkbox } from '@material-ui/core';
import InputFeild from '../Atoms/InputField/InputField';
import SelectField from '../Atoms/SelectField/SelectField';
import '../Molecules/PopUp/PopUp.css';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  LOADER_START,
  POPUP_CLOSE,
  SET_NEXT_POPUP,
  SET_SINGLE_ASSOCIATE_INFORMATION
} from '../actionType';
import InfoToolTip from '../Atoms/InfoToolTip/InfoToolTip';
import { REQUIRED_ERROR_MESSAGE } from '../errorMessage';
import { input } from 'aws-amplify';
import {
  getAllCountryList,
  getStatesByCountryId,
  getCitiesByStateId
} from '../Actions/GenericActions';

const PopUpAddress = (props) => {
  const { popupMode } = useSelector((state) => state.PopUpReducer);
  const { allCountryList, allProvinceList, allCityList } = useSelector(
    (state) => state.DisplayPaneTwoReducer
  );
  const associateInfo = useSelector((state) => state.AssociateCreateReducer);
  const dispatch = useDispatch();
  const {
    isActive,
    primaryheader,
    inputHeader,
    headerPanelColour,
    headerOne,
    headerOneBadgeOne,
    nextPopUpValue,
    typeOfSetObject,
    basicInfo,
    isRequired = false,
    mode,
    onClickCheckbox = null,
    addressCommunication
  } = props;
  const objectKeys = Object.keys(basicInfo);
  const [countryErr, setCountryErr] = useState('');
  const [country, setCountry] = useState('');
  const [stateErr, setStateErr] = useState('');
  const [zipcodeErr, setZipcodeErr] = useState('');
  const [cityErr, setCityErr] = useState('');
  const [addressErr, setAddressErr] = useState('');
  const [localObject, setLocalObject] = useState(basicInfo);
  useEffect(() => {
    setLocalObject(basicInfo);
    let countryId = basicInfo[Object.keys(basicInfo)[1]];
    let stateId = basicInfo[Object.keys(basicInfo)[3]];
    if (countryId !== '') {
      getStatesByCountryId(dispatch, countryId);
      setCountry(countryId);
    }
    if (stateId !== '') {
      getCitiesByStateId(dispatch, countryId, stateId);
    }
  }, []);
  const validateFun = () => {
    let isValidate = true;
    if (localObject && localObject[objectKeys[0]] === '') {
      setCountryErr(REQUIRED_ERROR_MESSAGE);
      isValidate = false;
    }
    if (localObject && localObject[objectKeys[2]] === '') {
      setStateErr(REQUIRED_ERROR_MESSAGE);
      isValidate = false;
    }
    if (localObject && localObject[objectKeys[4]] === '') {
      setZipcodeErr(REQUIRED_ERROR_MESSAGE);
      //setAddressErr(REQUIRED_ERROR_MESSAGE);
      isValidate = false;
    }
    if (localObject && localObject[objectKeys[5]] === '') {
      //setStateErr(REQUIRED_ERROR_MESSAGE);
      setCityErr(REQUIRED_ERROR_MESSAGE);
      isValidate = false;
    }
    if (localObject && localObject[objectKeys[7]] === '') {
      //setStateErr(REQUIRED_ERROR_MESSAGE);
      setAddressErr(REQUIRED_ERROR_MESSAGE);
      //setZipcodeErr(REQUIRED_ERROR_MESSAGE);
      isValidate = false;
    }
    return isValidate;
  };
  const handleChange = (e) => {
    let tag = e.target.value;
    let tagName = e.currentTarget.getAttribute('data-name');
    let currentTargetId = e.currentTarget.id;
    let val = inputHeader + ' ' + primaryheader;
    console.log("currentTargetId",currentTargetId);
    if (addressCommunication === '' && val === 'work address primary') {
      associateInfo.informationContact.associateAddressWorkPrimary.associateAddressCommunication = true;
      dispatch({
        type: SET_SINGLE_ASSOCIATE_INFORMATION,
        payload: { stateName: 'tempAssociateCommunication', value: val }
      });
    }
    if (currentTargetId === 'country') {
      getStatesByCountryId(dispatch, tag);
      setCountry(tag);
    }
    if (currentTargetId === 'province') {
      getCitiesByStateId(dispatch, country, tag);
    }
    const { name, value } = e.target;
    if (objectKeys.indexOf(e.target.name) === 1) {
      setCountryErr('');
    }
    if (objectKeys.indexOf(e.target.name) === 3) {
      setStateErr('');
    }
    if (objectKeys.indexOf(e.target.name) === 4) {
      setZipcodeErr('');
    }
    if (objectKeys.indexOf(e.target.name) === 6) {
      setCityErr('');
    }
    if (objectKeys.indexOf(e.target.name) === 7) {
      setAddressErr('');
    }
    // dispatch({ type: typeOfSetObject, payload: { ...basicInfo, [name]: value } })
    if (
      currentTargetId === 'country' ||
      currentTargetId === 'province' ||
      currentTargetId === 'city'
    ) {
      console.log('name.slice(0, -3)', name.slice(0, -3));
      if (currentTargetId === 'country') {
        setLocalObject({
          ...localObject,
          [name]: tag,
          [name.slice(0, -3)]: tagName,
          [headerOne + 'AddressProvinceState']: '',
          [headerOne + 'AddressProvinceStateTag']: '',
          [headerOne + 'AddressCity']: '',
          [headerOne + 'AddressCityTag']: ''
        });
      } else if (currentTargetId === 'province') {
        setLocalObject({
          ...localObject,
          [name]: tag,
          [name.slice(0, -3)]: tagName,
          [headerOne + 'AddressCity']: '',
          [headerOne + 'AddressCityTag']: ''
        });
      } else {
        setLocalObject({ ...localObject, [name]: tag, [name.slice(0, -3)]: tagName });
      }
    } else {
      setLocalObject({ ...localObject, [name]: value });
    }
  };
  const handleClick = () => {
    Object.keys(localObject).map((k) =>
      typeof localObject[k] === 'string'
        ? (localObject[k] = localObject[k]?.trim())
        : localObject[k]
    );

    if (mode === 'revise') {
      console.log('localObject', localObject);
      if (
        Object.keys(localObject).every(function (x) {
          return localObject[x] === '' || localObject[x] === null || !localObject[x];
        }) === false
      ) {
        console.log('has something');
        if (validateFun()) {
          dispatch({ type: typeOfSetObject, payload: { ...localObject } });
          dispatch({ type: POPUP_CLOSE });
        }
      } else {
        console.log('nothing');
        dispatch({ type: typeOfSetObject, payload: { ...localObject } });
        dispatch({ type: POPUP_CLOSE });
      }
    } else {
      if (isRequired) {
        if (validateFun()) {
          dispatch({ type: typeOfSetObject, payload: { ...localObject } });
          dispatch({ type: SET_NEXT_POPUP, payload: { isPopUpValue: nextPopUpValue } });
        }
      } else {
        /*according to creation mode popup sequence will change*/
        dispatch({ type: SET_NEXT_POPUP, payload: { isPopUpValue: nextPopUpValue } });
      }
    }
  };
  return (
    <div>
      <Popup isActive={isActive}>
        <PopupHeader
          headerPanelColour={headerPanelColour}
          headerOne={headerOne}
          headerOneBadgeOne={headerOneBadgeOne}
          onClick={handleClick}
          mode={mode}
        />
        <DialogContent
          className={['popupContent', 'fixed10PadDim', 'revisePopupContent'].join(' ')}
        >
          <div className={'fitContent'}>
            <div className={['PopupFormBox', 'labelPopupBox', 'popupMinHei'].join(' ')}>
              <InputLabel htmlFor="name-input" className={'textForLabelPopup'}>
                <Fragment>
                  {inputHeader}&nbsp;
                  {primaryheader ? <span className={'headerBadge'}>{primaryheader}</span> : null}
                </Fragment>
              </InputLabel>
              <div className={'infoSymbol'}></div>
              <div className={'infoSymbol'}>
                <InfoToolTip message="Click me, I will stay visible until you click outside." />
              </div>
            </div>
          </div>
          <FormControl style={{ width: '100%' }}>
            <SelectField
              tag={objectKeys[1]}
              label={'country / region'}
              dataValue={'country'}
              listSelect={allCountryList}
              errorMsg={countryErr}
              onChange={handleChange}
              value={localObject && localObject[objectKeys[1]]}
              mappingValue={'_id'}
            />
            <SelectField
              tag={objectKeys[3]}
              label={'province / state'}
              dataValue={'province'}
              // listSelect={[
              //   { stateCode: '', name: '' },
              //   { stateCode: '211', name: 'Maharashtra' },
              //   { stateCode: '234', name: 'Karnataka' }
              // ]}
              listSelect={allProvinceList}
              errorMsg={stateErr}
              onChange={handleChange}
              mappingValue={'_id'}
              value={localObject && localObject[objectKeys[3]]}
            />

            <InputFeild
              id={objectKeys[4]}
              label={'postcode'}
              value={localObject && localObject[objectKeys[4]]}
              errorMsg={zipcodeErr}
              onClick={handleChange}
            />
            <SelectField
              tag={objectKeys[6]}
              label={'city'}
              dataValue={'city'}
              // listSelect={[
              //   { cityCode: '', name: '' },
              //   { cityCode: '345', name: 'Mumbai' },
              //   { cityCode: '356', name: 'Pune' }
              // ]}
              listSelect={allCityList}
              mappingValue={'_id'}
              errorMsg={cityErr}
              onChange={handleChange}
              value={localObject && localObject[objectKeys[6]]}
            />
            <InputFeild
              id={objectKeys[7]}
              label={'address'}
              value={localObject && localObject[objectKeys[7]]}
              errorMsg={addressErr}
              onClick={handleChange}
            />
            <div className={'fitContent'}>
              <div className={['PopupFormBox', 'popupMinHei0'].join(' ')} style={{ minHeight: 0 }}>
                <div className={'contFlex'}>
                  <div
                    className={'f4'}
                    style={{ color: popupMode === 'ASSOCIATE_SIGN_ON' ? 'dimgray' : '' }}
                  >
                    communication
                  </div>
                  <div className={'checkedFontNew'}>
                    <Checkbox
                      className={''}
                      color="default"
                      checked={addressCommunication === inputHeader + ' ' + primaryheader}
                      onClick={(e) => {
                        onClickCheckbox(e, inputHeader, primaryheader);
                      }}
                      disabled={popupMode === 'ASSOCIATE_SIGN_ON' ? true : false}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={'fitContent'}>
              <div className={['PopupFormBox', 'popupMinHei0'].join(' ')} style={{ minHeight: 0 }}>
                <div className={'contFlex'}>
                  <div
                    className={'f4'}
                    style={{ color: popupMode === 'ASSOCIATE_SIGN_ON' ? 'dimgray' : '' }}
                  >
                    verification
                  </div>
                  <div className={'checkedFontNew'}>
                    <Checkbox
                      className={''}
                      color="default"
                      disabled={popupMode === 'ASSOCIATE_SIGN_ON' ? true : false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </FormControl>
        </DialogContent>
      </Popup>
    </div>
  );
};

PopUpAddress.propTypes = {
  className: PropTypes.string,
  headerPanelColour: PropTypes.oneOf([
    'displayPaneLeft',
    'displayPaneCentre',
    'displayPaneRight',
    'genericOne',
    'genericTwo'
  ]),
  headerOne: PropTypes.string,
  headerOneBadgeOne: PropTypes.string,
  headerOneBadgeTwo: PropTypes.string,
  headerOneBadgeThree: PropTypes.string,
  isActive: PropTypes.bool
};

export default PopUpAddress;
