import React, { useEffect, useState } from 'react';
import PopUp from '../Molecules/PopUp/PopUp';
import PopupHeader from '../Molecules/PopUp/PopUpHeader';
import { useDispatch, useSelector } from 'react-redux';
import { POPUP_CLOSE, SET_NEXT_POPUP, UPDATE_ASSESSEE_MINE_ASSIGNMENTS } from '../actionType';
import crypto from "crypto";

const PopUpPaymentInfo = (props) => {
  const dispatch = useDispatch();
  const { selectedMineTotalAmount, selectedAssociateInfo, selectedTagsArray,
    unselectedTagsArray, reviewListDistinctData } = useSelector((state) => state.DisplayPaneTwoReducer);

  const {
    headerPanelColour,
    headerOne,
    headerOneBadgeOne,
    isSignOn,
    headerOneBadgeTwo,
    tempMode,
    isActive,
    basicInfo,
    nextPopUpValue
  } = props;
  const [totalAmount, setTotalAmount] = useState(0)
  // console.log(selectedAssociateInfo?.assesseeId, selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary ,'########################' )

  useEffect(() => {
    calculateTotalAmountOfSelectedAssignments();
  }, [selectedTagsArray])

  const calculateTotalAmountOfSelectedAssignments = () => {

    let tempObj = {};
    for (let i = 0; i < reviewListDistinctData?.length; i++) {
      let x = reviewListDistinctData[i];
      tempObj[x.id] = x?.informationSetup?.assignmentShareFee ? parseFloat(x.informationSetup?.assignmentShareFee) : 0.0;
    }
    let totalAmount = 0.0;
    for (let a = 0; a < selectedTagsArray.length; a++) {
      let arrVal = selectedTagsArray[a];
      totalAmount += parseFloat(tempObj[arrVal]);
    }
    setTotalAmount(totalAmount)
    dispatch({
      type: 'SET_DISPLAY_TWO_SINGLE_STATE',
      payload: { stateName: 'selectedMineTotalAmount', value: totalAmount }
    });

  }

  const handleClick = () => {
    if (!isSignOn) {
      dispatch({
        type: UPDATE_ASSESSEE_MINE_ASSIGNMENTS, payload: {
          "request": {
            "associateId": selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
            "assesseeId": selectedAssociateInfo?.assesseeId,
            "assignmentIds": selectedTagsArray,
            "totalAmount": totalAmount,
            "transactionId": crypto.randomBytes(24).toString("hex").slice(0, 24)
          }
        }
      })

      // this is pending api isnt working yet!.
      dispatch({ type: POPUP_CLOSE });
    } else {
      dispatch({ type: SET_NEXT_POPUP, payload: { isPopUpValue: nextPopUpValue } });
    }
  };

  const onClosePopUpEvent = () => {
    dispatch({
      type: 'SET_DISPLAY_TWO_SINGLE_STATE',
      payload: { stateName: 'selectedTagsArray', value: [] }
    });
    dispatch({
      type: 'SET_DISPLAY_TWO_SINGLE_STATE',
      payload: { stateName: 'unselectedTagsArray', value: [] }
    });
    dispatch({ type: POPUP_CLOSE });
  }
  return (
    <div>
      <PopUp isActive={isActive}>
        <PopupHeader
          headerPanelColour={headerPanelColour}
          headerOne={headerOne}
          headerOneBadgeOne={headerOneBadgeOne}
          headerOneBadgeTwo={headerOneBadgeTwo}
          onClick={handleClick}
          onClosePopUpEvent={onClosePopUpEvent}
        // mode={mode}
        />
        <h1>amount: {totalAmount}</h1>
      </PopUp>
    </div>
  );
};

export default PopUpPaymentInfo;
