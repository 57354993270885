import React from 'react';
import { assesseeRole } from '../Actions/AssesseeModuleAction';
import { useSelector } from 'react-redux';
import ReviewList from '../Molecules/ReviewList/ReviewList';

function AssesseeDistinctManuscriptReviewList() {
  const { reviewListDistinctData, isSelectActive, selectedTagsArray, selectedFlagedArray } =
    useSelector((state) => state.DisplayPaneTwoReducer);
  const openListPopup = (e) => {
    let selectedTag = e.currentTarget.getAttribute('tag');
    let selectedTagUrl = e.currentTarget.getAttribute('selectedTagUrl');
    console.log('selectedTag', selectedTag);
    window.open(selectedTagUrl, '_blank');

  };
  return (
    <div>
      {reviewListDistinctData &&
        reviewListDistinctData.map((item, index) => {
          return (
            <div className="containerPadding" key={index}>
              <ReviewList
                className=""
                id={index}
                tag={item?.id}
                // associateId={item?.associateId}
                textOne={item?.informationBasic?.manuscriptName}
                textTwo={item?.informationBasic?.manuscriptDescription}
                SelectedTagUrl={item?.informationSetup?.manuscriptLink}
                status={''}
                isTooltipActive={false}
                onClickEvent={openListPopup}
                isSelectActive={isSelectActive}
                isSelected={selectedTagsArray.includes(item?.id)}
                isFlagActive={selectedFlagedArray.includes(item?.id)}
              />
            </div>
          );
        })}
    </div>
  );
}

export default AssesseeDistinctManuscriptReviewList;
