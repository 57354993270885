import { useDispatch } from 'react-redux';
import { SET_DISPLAY_TWO_SINGLE_STATE } from '../../actionType';
import ReviewList from '../ReviewList/ReviewList';
import DisplayPanelAccordianHeader from './DisplayPanelAccordianHeader';

const ReviewListAccordion = (props) => {
    const dispatch = useDispatch();
    const {
        isDisplayCardExpanded,
        headerOne,
        setListExpand,
        list = [],
        mode = '',
        onClickRevise,
        onClickReview,
        getReviewList = null,
        onClickReviseIcon = null,
        listData = [],
        selectedTagsArray,
        unselectedTagsArray,
        isSelectActive,
        onClickCheckBoxSelection
    } = props;

    return (
        <div>
            <DisplayPanelAccordianHeader
                isDisplayCardExpanded={isDisplayCardExpanded}
                headerOne={headerOne}
                setListExpand={setListExpand}
            >
                {
                    listData.length > 0 && listData.map((data, index) => {
                        return (
                            <div className="containerPadding" key={index}>
                                <ReviewList
                                    id={data?.id}
                                    textOne={data?.title}
                                    textTwo={data?.subtitle}

                                    status={data?.status}
                                    actualStatus={data?.status}
                                    isSelectActive={isSelectActive}
                                    // isSelectActive={'multiple'}
                                    isTooltipActive={false}
                                    isAlertActive={false}
                                    key={data.id}
                                    tag={data.id}
                                    // isDelivery={true}
                                    isSelected={selectedTagsArray.includes(data.id)}
                                    className={'reviewListFixedWidth'}
                                    // onClickEvent={calculateTotalAmountOfSelectedAssignments}
                                    onClickCheckBox={(event) => {
                                        onClickCheckBoxSelection(selectedTagsArray, unselectedTagsArray, event, dispatch);
                                    }}
                                ></ReviewList>
                            </div>
                        )
                    })

                }
            </DisplayPanelAccordianHeader>
        </div>
    )
}

export default ReviewListAccordion