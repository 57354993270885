import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PopUpPicture from '../../PopUpInformation/PopUpPicture';
import PopUpTextField from '../../PopUpInformation/PopUpTextField';
import PopUpConfirm from '../../PopUpGeneric/PopUpConfirm';
import { CULTURE_REVIEW_LIST_POPUP_OPTION } from '../../PopUpConfig';
import {
  POPUP_CLOSE,
  CLEAR_TYPE_REDUCER_STATE,
  SET_DISPLAY_THREE_SINGLE_STATE,
  SET_CULTURE_REDUCER_STATE,
  SET_CULTURE_DYNAMIC_SINGLE_STATE,
  LOADER_START,
  CREATE_CULTURE_SAGA,
  SET_POPUP_VALUE,
  SET_NEXT_POPUP,
  SET_DISPLAY_PANE_THREE_STATE,
  SET_CULTURE_DIMENTION_STATE,
  SET_WEIGHTAGE_SELECTED,
  SET_MOBILE_PANE_STATE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_CULTURE_DYANMIC_DIMENTION_STATE,
  SET_POPUP_SINGLE_STATE,
  SET_CULTURE_SETUP_INFO_STATE
} from '../../actionType';
import PopUpReviewList from '../../PopUpInformation/PopUpReviewList';
import PopUpMessageGeneric from '../../PopUpGeneric/PopUpMessageGeneric';
import { getCultureProfileDiamentionList } from '../../Actions/ActionCultureProfile';
import PopUpDropList from '../../PopUpInformation/PopUpDropList';
import PopUpTagSecondary from '../../PopUpInformation/PopUpTagSecondary';
import { getPopUpOptionList } from '../../Actions/GenericActions';
import { CAN_NOT_REVISED_MESSAGE } from '../../errorMessage';

const PopUpCultureProfileCreate = (props) => {
  const { headerOne, reducerObeject, allocationObj } = props;
  const { isPopUpValue, popupErrorMsg } = useSelector((state) => state.PopUpReducer);
  const { cultureProfileInformation } = useSelector((state) => state.CultureProfileCreateReducer);
  const {
    reviewMode,
    headerOneBadgeTwo,
    createMode,
    statusPopUpValue = ''
  } = useSelector((state) => state.DisplayPaneThreeReducer);
  const {
    selectedAssociateInfo,
    coreNodeReviewListData,
    coreGroupReviewListData,
    coreTypeReviewListData,
    responseObject,
    selectedInformationAllorKey,
    cultureProfileDiamentionReviewList,
    signedAssesseePermission
  } = useSelector((state) => state.DisplayPaneTwoReducer);

  const dispatch = useDispatch();
  const [requiredErrorMsg, setRequiredErrorMsg] = useState('');
  const [cultureDiamentionArr, setCultureDiamentionArr] = useState('');
  const [cultureDiamentionGroup, setCultureDiamentionGroup] = useState('');

  const onClickCancelYes = () => {
    dispatch({
      type: SET_DISPLAY_THREE_SINGLE_STATE,
      payload: { stateName: 'createMode', value: '' }
    });
    dispatch({ type: CLEAR_TYPE_REDUCER_STATE });
    dispatch({ type: POPUP_CLOSE });
  };
  useEffect(() => {
    if (responseObject) {
      // dispatch({
      //   type: SET_POPUP_VALUE,
      //   payload: { isPopUpValue: 'POPUPCONTINUE', popupMode: 'CULTURECREATE' }
      // });
    }
  }, [responseObject]);
  //set default type 
  useEffect(() => {
    let tempTypeArr = coreTypeReviewListData.filter((tt) => {
      return tt.informationSetup?.cultureProfileTypeDefault;
    });
    if (tempTypeArr.length > 0) {
      cultureProfileInformation.informationAllocation.cultureProfileType.cultureProfileTypePrimary.push(
        tempTypeArr[0].id
      );
    }
  }, [coreTypeReviewListData, createMode]);
  const onClickYes = () => {
    let informationAllocation = cultureProfileInformation?.informationAllocation;
    // add default root node in allocation if node not selected
    if (
      informationAllocation.cultureProfileNode.cultureProfileNodePrimary.length === 0 &&
      coreNodeReviewListData.length !== 0
    ) {
      let rootNode = coreNodeReviewListData.filter((node) => {
        return node.informationFramework.associateNodeAscendantPrimary === null;
      });
      let rootNodeId = rootNode[0].id;
      informationAllocation.cultureProfileNode.cultureProfileNodePrimary = [
        ...informationAllocation.cultureProfileNode.cultureProfileNodePrimary,
        rootNodeId
      ];
    }
    let reqBody = {
      module: 'Culture-Profile-Distinct',
      action: 'Create',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        cultureProfile: {
          informationBasic: cultureProfileInformation?.informationBasic,
          informationAllocation: cultureProfileInformation?.informationAllocation,
          informationFramework: {
            cultureProfileCultureDimensionSelectList: []
          }
        }
      }
    };
    console.log('CREATE type api', reqBody);
    let cultureProfileDistictPermssion =
      signedAssesseePermission?.iGuruAnalyticDistinctPermission
        ?.assesseeiGuruAnalyticDistinctPermission;
    let popupContentArr = getPopUpOptionList(CULTURE_REVIEW_LIST_POPUP_OPTION, cultureProfileDistictPermssion);
    let finalPopupOptionArray = [];
    popupContentArr.forEach((element) => {
      if (element.data === 'share') {
        finalPopupOptionArray.push({ ...element, disabled: true });
      } else {
        finalPopupOptionArray.push(element);
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: finalPopupOptionArray
      }
    });
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: {
        stateName: 'popupOptionArr',
        value: finalPopupOptionArray
      }
    });
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: {
        stateName: 'originPrimaryArrOprion',
        value: finalPopupOptionArray
      }
    });
    dispatch({ type: LOADER_START });
    dispatch({ type: CREATE_CULTURE_SAGA, payload: reqBody });
  };
  const updateAllocationObj = (e, stateName, actualStateName) => {
    let tagId = e.currentTarget.getAttribute('tag');
    let isDefault = e.currentTarget.getAttribute('data-default');
    if (isDefault !== 'true') {
      let tagIdArr = cultureProfileInformation.informationAllocation[stateName][actualStateName];
      if (tagIdArr.includes(tagId)) {
        document.getElementById(tagId).style.backgroundColor = 'white';
        tagIdArr = tagIdArr.filter(function (number) {
          return number !== tagId;
        });
      } else {
        tagIdArr.push(tagId);
        document.getElementById(tagId).style.backgroundColor = '#F0F0F0';
      }
      dispatch({
        type: SET_CULTURE_DYNAMIC_SINGLE_STATE,
        payload: {
          objectName: 'informationAllocation',
          stateName: stateName,
          actualStateName: actualStateName,
          value: tagIdArr
        }
      });
    }
  };
  console.log('cultureProfileInformation', cultureProfileInformation);
  let selectedPrimaryGroup =
    cultureProfileInformation?.informationAllocation?.cultureProfileGroup
      ?.cultureProfileGroupPrimary || [];
  let selectedSecondaryGroup =
    cultureProfileInformation?.informationAllocation?.cultureProfileGroup
      ?.cultureProfileGroupSecondary || [];
  let filteredCoreGroupReviewListDataPrimary = [];
  if (coreGroupReviewListData && coreGroupReviewListData.length > 0) {
    coreGroupReviewListData.forEach((group) => {
      // for primary popup list
      if (!selectedSecondaryGroup.includes(group.id))
        filteredCoreGroupReviewListDataPrimary.push(group);
    });
  }
  let filteredCoreGroupReviewListDataSecondary = [];
  if (coreGroupReviewListData && coreGroupReviewListData.length > 0) {
    coreGroupReviewListData.forEach((group) => {
      // for Secondary popup list
      if (!selectedPrimaryGroup.includes(group.id))
        filteredCoreGroupReviewListDataSecondary.push(group);
    });
  }
  const onClickContinueNo = () => {
    dispatch({
      type: SET_DISPLAY_PANE_THREE_STATE,
      payload: {
        headerOne: 'culture-profile',
        headerOneBadgeOne: 'distinct',
        headerOneBadgeTwo: 'information',
        headerOneBadgeThree: selectedInformationAllorKey || headerOneBadgeTwo,
        responseObject: responseObject,
        reviewMode: 'revise',
        createMode: createMode
      }
    });
    // dispatch({
    //   type: SET_DISPLAY_TWO_SINGLE_STATE,
    //   payload: { stateName: 'responseObject', value: null }
    // });
    dispatch({
      type: SET_MOBILE_PANE_STATE,
      payload: 'displayPaneThree'
    });
  };
  const onClickContinueYes = () => {
    getCultureProfileDiamentionList(selectedAssociateInfo, 'Review-List', dispatch);
  };
  const onClickRevise = () => {
    dispatch({
      type: SET_DISPLAY_PANE_THREE_STATE,
      payload: {
        headerOne: 'culture-profile',
        headerOneBadgeOne: 'distinct',
        headerOneBadgeTwo: 'information',
        headerOneBadgeThree: selectedInformationAllorKey || headerOneBadgeTwo,
        responseObject: responseObject,
        reviewMode: 'revise',
        createMode: createMode
      }
    });
    dispatch({
      type: SET_MOBILE_PANE_STATE,
      payload: 'displayPaneThree'
    });

    // dispatch({
    //   type: SET_DISPLAY_TWO_SINGLE_STATE,
    //   payload: { stateName: 'responseObject', value: null }
    // });
    dispatch({
      type: SET_WEIGHTAGE_SELECTED,
      payload: true
    });
    let popupContentArrValue = [];
    CULTURE_REVIEW_LIST_POPUP_OPTION.map((obj) => popupContentArrValue.push(obj));

    let cultureProfileDistictPermssion =
      signedAssesseePermission?.iGuruAnalyticDistinctPermission
        ?.assesseeiGuruAnalyticDistinctPermission;
    let popupContentArr = getPopUpOptionList(popupContentArrValue, cultureProfileDistictPermssion);
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: popupContentArr//CULTURE_REVIEW_LIST_POPUP_OPTION
      }
    });
  };
  const updateDimention = (e) => {
    let tagId = e.currentTarget.getAttribute('tag');
    let groupId = e.currentTarget.getAttribute('data-value');
    // if (tagIdArr.includes(tagId)) {
    //   document.getElementById(tagId).style.backgroundColor = 'white';
    //   tagIdArr = cultureDiamentionArr.filter(function (number) {
    //     return number !== tagId;
    //   });
    // } else {
    //   var tarr = [];
    //   tagIdArr = [...tarr];
    //   tagIdArr.push(tagId);
    //   document.getElementById(tagId).style.backgroundColor = '#F0F0F0';
    // }
    // dispatch({
    //   type: SET_CULTURE_DYANMIC_DIMENTION_STATE,
    //   payload: { stateName: 'cultureProfileCultureDimensionSelectList', value: tagIdArr }
    // });
    let dimensionTag = cultureDiamentionArr === tagId ? '':tagId;
    let dimensionGroup = cultureDiamentionGroup === groupId ?'':groupId;
    let index = -1;
    setRequiredErrorMsg('');
   
    console.log(dimensionTag);
    console.log(dimensionGroup);
    if(reviewMode === 'revise'){
      cultureProfileInformation.informationFramework.cultureProfileCultureDimensionSelectList.forEach(
        (jj,ii) => {
          console.log('tagId',tagId);
          if(groupId === jj.iGaugeOccupationFrameworkOneTag && jj.iGaugeOccupationFrameworkOneClusterSelected === tagId){
            index=ii;
          }
          if(groupId === jj.iGaugeOccupationFrameworkOneTag){
            jj.iGaugeOccupationFrameworkOneClusterSelected = tagId;
          }
        }
      );
    }
    else{
      setCultureDiamentionArr(dimensionTag);
      setCultureDiamentionGroup(dimensionGroup);
    }
    console.log('indexindex',index);
    if(index>=0){
      cultureProfileInformation.informationFramework.cultureProfileCultureDimensionSelectList.splice(index,1)
      cultureProfileInformation.informationFramework.cultureProfileCultureDimensionCoreObj.splice(index,1)
      setCultureDiamentionArr('absdcss');
      setCultureDiamentionGroup('absdcss');
    }
    else{
      setCultureDiamentionArr(dimensionTag);
      setCultureDiamentionGroup(dimensionGroup);
    }
  };

  const setDimentionStateReducer = () => {
    let arrr = cultureProfileDiamentionReviewList
      .map((obj) => {
        let temp = '';
        if (obj.group === cultureDiamentionGroup) {
          temp = obj.cultureDimensions.filter(function (ob) {
            return ob.id === cultureDiamentionArr;
          });
        }
        return temp[0];
      })
      .filter((notUndefined) => notUndefined !== undefined);
    let newtagIdArr =
      cultureProfileInformation?.informationFramework?.cultureProfileCultureDimensionSelectList ||
      [];
    let updateTagarr = newtagIdArr.filter((jj) => {
      return jj.iGaugeOccupationFrameworkOneTag !== cultureDiamentionGroup;
    });
    let existdiamentionObj =
      cultureProfileInformation?.informationFramework?.cultureProfileCultureDimensionCoreObj || [];
    let updatedexistdiamentionObj = existdiamentionObj.filter((dd) => {
      return dd.iGaugeOccupationFrameworkOneTag != cultureDiamentionGroup;
    });
    if (arrr.length > 0) {
      let tempOb = {
        iGaugeOccupationFrameworkOneTag: cultureDiamentionGroup,
        iGaugeOccupationFrameworkOneClusterLabel: arrr[0].label,
        iGaugeOccupationFrameworkOneClusterDescription: arrr[0].description,
        iGaugeOccupationFrameworkOneClusterOneDescription: arrr[0].labelDescription,
        iGaugeOccupationFrameworkOneClusterExplanation: arrr[0].labelExplanation,
        // iGaugeOccupationFrameworkOneClusterPolarity: arrr[0].id,
        iGaugeOccupationFrameworkOneWeightage: 0
      };
      dispatch({
        type: SET_CULTURE_DIMENTION_STATE,
        payload: {
          cultureProfileCultureDimensionSelectList: [
            ...updateTagarr,
            {
              iGaugeOccupationFrameworkOneTag: cultureDiamentionGroup,
              // iGaugeOccupationFrameworkOneClusterPolarity: cultureDiamentionArr,
              iGaugeOccupationFrameworkOneClusterSelected: cultureDiamentionArr,
              iGaugeOccupationFrameworkOneWeightage: 0
            }
          ],
          cultureProfileCultureDimensionCoreObj: [...updatedexistdiamentionObj, tempOb]
        }
      });
    }
    setCultureDiamentionArr('');
    setCultureDiamentionGroup('');
  };
  console.log('cultureProfileDiamentionReviewList', cultureProfileDiamentionReviewList);
  return (
    <div>
      {isPopUpValue === 'NAMEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'name'}
          actualLableValue={'cultureProfileName'}
          actualLableValueVerification={'cultureProfileNameVerification'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'ALIASPOPUP'}
          isRequired={true}
          typeOfSetObject={SET_CULTURE_REDUCER_STATE}
          errorMsg={popupErrorMsg}
          basicInfo={cultureProfileInformation.informationBasic}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ALIASPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'description'}
          actualLableValue={'cultureProfileDescription'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'PICTUREPOPUP'}
          typeOfSetObject={SET_CULTURE_REDUCER_STATE}
          basicInfo={cultureProfileInformation.informationBasic}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'PICTUREPOPUP' && (
        <PopUpPicture
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          actualLableValue={'cultureProfilePicture'}
          actualLableValueVerification={'cultureProfilePictureVerification'}
          basicInfo={cultureProfileInformation.informationBasic}
          typeOfSetObject={SET_CULTURE_REDUCER_STATE}
          nextPopUpValue={'GROUPPOPUP'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'GROUPPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'NODEPOPUP'}
          inputHeader={'group'}
          inputHeaderBadge={'primary'}
          infoMsg={'select a group'}
          ListData={filteredCoreGroupReviewListDataPrimary}
          selectedList={
            cultureProfileInformation.informationAllocation.cultureProfileGroup
              .cultureProfileGroupPrimary
          }
          textOne={'cultureProfileGroupName'}
          textTwo={'cultureProfileGroupDescription'}
          onClickEvent={(e) => {
            updateAllocationObj(e, 'cultureProfileGroup', 'cultureProfileGroupPrimary');
          }}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          useLocalState={true}
          stateName={`cultureProfileGroup`}
          actualStateName={`cultureProfileGroupPrimary`}
          objectName={'informationAllocation'}
          updateState={SET_CULTURE_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'GROUPSECONDARYPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'NODEPOPUP'}
          inputHeader={'group'}
          inputHeaderBadge={'secondary'}
          infoMsg={'select a group'}
          ListData={filteredCoreGroupReviewListDataSecondary}
          selectedList={
            cultureProfileInformation.informationAllocation.cultureProfileGroup
              .cultureProfileGroupSecondary
          }
          textOne={'cultureProfileGroupName'}
          textTwo={'cultureProfileGroupDescription'}
          onClickEvent={(e) => {
            updateAllocationObj(e, 'cultureProfileGroup', 'cultureProfileGroupSecondary');
          }}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          useLocalState={true}
          stateName={`cultureProfileGroup`}
          actualStateName={`cultureProfileGroupSecondary`}
          objectName={'informationAllocation'}
          updateState={SET_CULTURE_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'NODEPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'TYPEPOPUP'}
          inputHeader={'node'}
          inputHeaderBadge={'primary'}
          infoMsg={'select a node'}
          isRequired={true}
          minimumSelected={1}
          setErrorMsg={setRequiredErrorMsg}
          errorMsg={requiredErrorMsg}
          ListData={coreNodeReviewListData}
          selectedList={
            cultureProfileInformation.informationAllocation.cultureProfileNode
              .cultureProfileNodePrimary
          }
          textOne={'associateNodeName'}
          textTwo={'associateNodeDescription'}
          onClickEvent={(e) => {
            updateAllocationObj(e, 'cultureProfileNode', 'cultureProfileNodePrimary');
          }}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          useLocalState={true}
          stateName={`cultureProfileNode`}
          actualStateName={`cultureProfileNodePrimary`}
          objectName={'informationAllocation'}
          updateState={SET_CULTURE_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'NODESECONDARYPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'TYPEPOPUP'}
          inputHeader={'node'}
          inputHeaderBadge={'secondary'}
          infoMsg={'select a node'}
          ListData={coreNodeReviewListData}
          selectedList={
            cultureProfileInformation.informationAllocation.cultureProfileNode
              .cultureProfileNodeSecondary
          }
          textOne={'associateNodeName'}
          textTwo={'associateNodeDescription'}
          onClickEvent={(e) => {
            updateAllocationObj(e, 'cultureProfileNode', 'cultureProfileNodeSecondary');
          }}
          setErrorMsg={setRequiredErrorMsg}
          errorMsg={requiredErrorMsg}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          useLocalState={true}
          stateName={`cultureProfileNode`}
          actualStateName={`cultureProfileNodeSecondary`}
          objectName={'informationAllocation'}
          updateState={SET_CULTURE_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'TYPEPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'CONFIRMATIONPOPUP'}
          inputHeader={'type'}
          inputHeaderBadge={'primary'}
          infoMsg={'select a type'}
          dataDefault={'cultureProfileTypeDefault'}
          ListData={coreTypeReviewListData.filter((tt) => {
            return tt.informationSetup.cultureProfileTypeDefault;
          })}
          selectedList={
            cultureProfileInformation.informationAllocation.cultureProfileType
              .cultureProfileTypePrimary
          }
          isRequired={true}
          minimumSelected={1}
          setErrorMsg={setRequiredErrorMsg}
          errorMsg={requiredErrorMsg}
          textOne={'cultureProfileTypeName'}
          textTwo={'cultureProfileTypeDescription'}
          onClickEvent={(e) => {
            updateAllocationObj(e, 'cultureProfileType', 'cultureProfileTypePrimary');
          }}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          useLocalState={true}
          stateName={`cultureProfileType`}
          actualStateName={`cultureProfileTypePrimary`}
          objectName={'informationAllocation'}
          updateState={SET_CULTURE_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'TYPESECONDARYPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'CONFIRMATIONPOPUP'}
          inputHeader={'type'}
          inputHeaderBadge={'secondary'}
          infoMsg={'select a type'}
          ListData={coreTypeReviewListData.filter((tt) => {
            return !tt.informationSetup.cultureProfileTypeDefault;
          })}
          selectedList={
            cultureProfileInformation.informationAllocation.cultureProfileType
              .cultureProfileTypeSecondary
          }
          textOne={'cultureProfileTypeName'}
          textTwo={'cultureProfileTypeDescription'}
          onClickEvent={(e) => {
            updateAllocationObj(e, 'cultureProfileType', 'cultureProfileTypeSecondary');
          }}
          setErrorMsg={setRequiredErrorMsg}
          errorMsg={requiredErrorMsg}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          useLocalState={true}
          stateName={`cultureProfileType`}
          actualStateName={`cultureProfileTypeSecondary`}
          objectName={'informationAllocation'}
          updateState={SET_CULTURE_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'POPUPASSESSMENTPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={''}
          inputHeader={'assessment'}
          inputHeaderBadge={''}
          infoMsg={'assessment list'}
          ListData={responseObject?.informationFramework?.cultureProfileAssessment || []}
          selectedList={
            responseObject?.informationFramework?.cultureProfileAssessment.filter(function (num) {
              return num.id;
            }) || []
          }
          textOne={'assessmentName'}
          textTwo={'assessmentDescription'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={true}
          errorMsg={CAN_NOT_REVISED_MESSAGE}
        />
      )}
      {isPopUpValue === 'CANCELPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'cancel'}
          headerOneBadgeOne={''}
          mode={'cancel'}
          onClickYes={onClickCancelYes}
        />
      )}
      {isPopUpValue === 'CONFIRMATIONPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'create'}
          headerOneBadgeTwo={''}
          onClickYes={onClickYes}
          onClickNoFun={onClickCancelYes}
        />
      )}
      {isPopUpValue === 'POPUPCONTINUE' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'continue'}
          headerOneBadgeTwo={''}
          onClickYes={onClickContinueYes}
          mode={'error'}
          onClickNoFun={onClickContinueNo}
        />
      )}
      {isPopUpValue === 'POPUPDIAMENTIONMSG' && (
        <PopUpMessageGeneric
          isActive={true}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'POPUPDIAMENTION0'}
          textOneOne={'select'}
          textOneTwo={'one or neither'}
          textOneThree={'culture-dimension'}
          textOneFour={`from the following twelve lists`}
          mode={'next'}
        />
      )}
      {cultureProfileDiamentionReviewList.map((value, index) => {
        let selectedArr =
          cultureProfileInformation.informationFramework.cultureProfileCultureDimensionSelectList.filter(
            (jj) => {
              return jj.iGaugeOccupationFrameworkOneTag === value.group;
            }
          );
        return (
          isPopUpValue === `POPUPDIAMENTION${index}` && (
            <PopUpReviewList
              isActive={true}
              headerPanelColour={'genericOne'}
              headerOne={headerOne}
              headerOneBadgeOne={'information'}
              nextPopUpValue={
                index < cultureProfileDiamentionReviewList.length - 1
                  ? `POPUPDIAMENTION${index + 1}`
                  : 'POPUPWEITAGENMSG'
              }
              handleClickOnCorrect={setDimentionStateReducer}
              inputHeader={'culture-dimensions'}
              inputHeaderBadge={'select-list'}
              infoMsg={'select a one neither culture dimension'}
              ListData={value.cultureDimensions}
              isTooltipActive={true}
              tooltipActiveText={'labelExplanation'}
              selectedList={
                (selectedArr.length > 0 &&
                  selectedArr[0].iGaugeOccupationFrameworkOneClusterSelected) ||
                (cultureDiamentionArr && [cultureDiamentionArr]) ||
                []
              }
              // isRequired={true}
              // minimumSelected={1}
              // setErrorMsg={setRequiredErrorMsg}
              // errorMsg={requiredErrorMsg}
              // selectedList={[cultureDiamentionArr]}
              textOne={'label'}
              textTwo={'description'}
              dataValue={value.group}
              onClickEvent={updateDimention}
              mode={'core'}
            />
          )
        );
      })}
      {isPopUpValue === 'POPUPWEITAGENMSG' && (
        <PopUpMessageGeneric
          isActive={true}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'onClickRevise'}
          textOneOne={'weightage for'}
          textOneTwo={''}
          textOneThree={'selected'}
          textOneFour={`culture-dimensions`}
          mode={'next'}
          handleClickFun={onClickRevise}
        />
      )}
      {isPopUpValue === 'STATUSPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'cultureProfileStatus'}
          label={'status'}
          listSelect={[
            { id: 'Active', name: 'Active' },
            { id: 'Suspended', name: 'Suspended' },
            { id: 'Terminated', name: 'Terminated' },
            { id: 'Unverified', name: 'Unverified' },
            { id: 'Confirmed', name: 'Confirmed' },
            { id: 'Published', name: 'Published' },
            { id: 'Unconfirmed', name: 'Unconfirmed' },
            { id: 'Unpublished', name: 'Unpublished' }
          ]}
          mappingValue={'id'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          isRequired={true}
          basicInfo={statusPopUpValue}
          nextPopUpValue={''}
          isNotRevised={true}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TAGREADONLYPRIMARYPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tag'}
          labelBadgeOne={'primary'}
          actualLableValue={'cultureProfileTagPrimary'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          basicInfo={
            responseObject?.informationEngagement?.cultureProfileTag?.cultureProfileTagPrimary || ''
          }
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={''}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TAGSECONDARYPOPUP' && (
        <PopUpTagSecondary
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          tagSecondary={responseObject?.informationEngagement || {}}
          signInSetup={responseObject?.informationSetup || {}}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TENURESATRTDATEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tenure'}
          labelBadgeOne={'start'}
          actualLableValue={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          basicInfo={
            responseObject?.informationEngagement?.cultureProfileTenure
              ?.cultureProfileTenureDateTimeStart || 'mm/dd/yyyy --:-- --'
          }
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={''}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TENUREENDDATEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tenure'}
          labelBadgeOne={'end'}
          actualLableValue={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          basicInfo={
            responseObject?.informationEngagement?.cultureProfileTenure
              ?.cultureProfileTenureDateTimeEnd || 'mm/dd/yyyy --:-- --'
          }
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={''}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'CULTURECREATEFEEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'fee'}
          headerPanelColour={'genericOne'}
          inputHeader={headerOne}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'share'}
          type={'number'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          isRequired={false}
          actualLableValue={'cultureProfileShareFee'}
          basicInfo={cultureProfileInformation.informationSetup}
          typeOfSetObject={SET_CULTURE_SETUP_INFO_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          currencySymbol={''}
        />
      )}
    </div>
  );
};

export default PopUpCultureProfileCreate;