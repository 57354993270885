import { put, takeLatest, call } from 'redux-saga/effects';
import {
  CREATE_GROUP_SAGA,
  LOADER_STOP,
  POPUP_CLOSE,
  SET_ASSESSEE_GROUP_REDUCER_STATE,
  SET_ASSESSMENT_GROUP_REDUCER_STATE,
  SET_ASSIGNMEMT_GROUP_REDUCER_STATE,
  SET_ASSOCIATE_GROUP_REDUCER_STATE,
  SET_CULTURE_GROUP_REDUCER_STATE,
  SET_DISPLAY_PANE_THREE_STATE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_GROUP_SETUP_STATE,
  SET_ITEM_GROUP_REDUCER_STATE,
  SET_JOB_GROUP_REDUCER_STATE,
  SET_MOBILE_PANE_STATE,
  SET_POPUP_SINGLE_STATE,
  SET_POPUP_VALUE
} from '../../actionType';
import {
  ASSESSEE_MODULE_URL,
  ASSESSMENT_MODULE_URL,
  ASSIGNMENT_MODULE_URL,
  ASSOCIATE_MODULE_URL,
  CULTURE_MODULE_URL,
  ITEM_MODULE_URL,
  JOB_MODULE_URL
} from '../../endpoints';
import { EXCEPTION_ERROR_MESSAGE, INFORMATION_EXIST_ERROR_MESSAGE } from '../../errorMessage';
import { GROUP_REVIEW_LIST_POPUP_OPTION } from '../../PopUpConfig';
const createGroupApi = async (requestObj) => {
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      Authorization:
        requestObj.data.whichGroupCreate === 'culture-profiles' ||
        requestObj.data.whichGroupCreate === 'job-profiles'
          ? localStorage.getItem('idToken')
          : localStorage.getItem('token')
    }),
    body: JSON.stringify(requestObj.data)
  };
  let URL = '';
  if (requestObj.data.whichGroupCreate === 'assessees') {
    URL = ASSESSEE_MODULE_URL;
  }
  if (requestObj.data.whichGroupCreate === 'assessments') {
    URL = ASSESSMENT_MODULE_URL;
  }
  if (requestObj.data.whichGroupCreate === 'assignments') {
    URL = ASSIGNMENT_MODULE_URL;
  }
  if (requestObj.data.whichGroupCreate === 'associates') {
    URL = ASSOCIATE_MODULE_URL;
  }
  if (requestObj.data.whichGroupCreate === 'items') {
    URL = ITEM_MODULE_URL;
  }
  if (requestObj.data.whichGroupCreate === 'culture-profiles') {
    URL = CULTURE_MODULE_URL;
  }
  if (requestObj.data.whichGroupCreate === 'job-profiles') {
    URL = JOB_MODULE_URL;
  }
  const response = await fetch(URL, requestOptions);
  const json = await response.json();
  return json;
};

function* workerCreateGroupSaga(data) {
  try {
    const userResponse = yield call(createGroupApi, { data: data.payload });
    if (userResponse.responseCode === '000') {
      const { previousHeaderOne } = data.payload;
      yield put({
        type: SET_DISPLAY_PANE_THREE_STATE,
        payload: {
          headerOne: previousHeaderOne ? previousHeaderOne : data.payload.whichGroupCreate,
          headerOneBadgeOne: 'group',
          headerOneBadgeTwo: 'information',
          headerOneBadgeThree: 'key',
          responseObject: userResponse.responseObject[0],
          reviewMode: 'revise',
          createMode: `${data.payload.whichGroupCreate}Group`
        }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'middlePaneSelectedValue', value: userResponse.responseObject[0].id }
      });
      yield put({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'selectedTagValue', value: userResponse.responseObject[0].id }
      });
      let status = '';
      if (data.payload.whichGroupCreate === 'assessees') {
        yield put({
          type: SET_ASSESSEE_GROUP_REDUCER_STATE,
          payload: userResponse.responseObject[0].informationBasic
        });
        status = userResponse.responseObject[0].informationEngagement?.assesseeGroupStatus;
      }
      if (
        data.payload.whichGroupCreate === 'administrators' ||
        data.payload.whichGroupCreate === 'managers' ||
        data.payload.whichGroupCreate === 'assistants'
      ) {
        yield put({
          type: SET_ASSESSEE_GROUP_REDUCER_STATE,
          payload: userResponse.responseObject[0].informationBasic
        });
        status = userResponse.responseObject[0].informationEngagement?.assesseeGroupStatus;
      }
      if (data.payload.whichGroupCreate === 'assessments') {
        yield put({
          type: SET_ASSESSMENT_GROUP_REDUCER_STATE,
          payload: userResponse.responseObject[0].informationBasic
        });
        status = userResponse.responseObject[0].informationEngagement?.assessmentGroupStatus;
      }
      if (data.payload.whichGroupCreate === 'assignments') {
        yield put({
          type: SET_ASSIGNMEMT_GROUP_REDUCER_STATE,
          payload: userResponse.responseObject[0].informationBasic
        });
        status = userResponse.responseObject[0].informationEngagement?.assignmentGroupStatus;
      }
      if (data.payload.whichGroupCreate === 'associates') {
        yield put({
          type: SET_ASSOCIATE_GROUP_REDUCER_STATE,
          payload: userResponse.responseObject[0].informationBasic
        });
        status = userResponse.responseObject[0].informationEngagement?.associateGroupStatus;
      }
      if (data.payload.whichGroupCreate === 'culture-profiles') {
        yield put({
          type: SET_CULTURE_GROUP_REDUCER_STATE,
          payload: userResponse.responseObject[0].informationBasic
        });
        status = userResponse.responseObject[0].informationEngagement?.cultureProfileGroupStatus;
      }
      if (data.payload.whichGroupCreate === 'job-profiles') {
        yield put({
          type: SET_JOB_GROUP_REDUCER_STATE,
          payload: userResponse.responseObject[0].informationBasic
        });
        status = userResponse.responseObject[0].informationEngagement?.jobProfileGroupStatus;
      }
      if (data.payload.whichGroupCreate === 'items') {
        yield put({
          type: SET_ITEM_GROUP_REDUCER_STATE,
          payload: userResponse.responseObject[0].informationBasic
        });
        status = userResponse.responseObject[0].informationEngagement?.itemGroupStatus;
      }
      yield put({
        type: SET_POPUP_SINGLE_STATE,
        payload: {
          stateName: 'selectedTagStatus',
          value: status
        }
      });
      let objectName =
        data.payload.whichGroupCreate === 'assessees' ||
        data.payload.whichGroupCreate === 'administrators' ||
        data.payload.whichGroupCreate === 'managers' ||
        data.payload.whichGroupCreate === 'assistants'
          ? 'assesseeGroup'
          : data.payload.whichGroupCreate === 'assessments'
          ? 'assessmentGroup'
          : data.payload.whichGroupCreate === 'assignments'
          ? 'assignmentGroup'
          : data.payload.whichGroupCreate === 'associates'
          ? 'associateGroup'
          : data.payload.whichGroupCreate === 'culture-profiles'
          ? 'cultureProfileGroup'
          : data.payload.whichGroupCreate === 'job-profiles'
          ? 'jobProfileGroup'
          : data.payload.whichGroupCreate === 'items'
          ? 'itemGroup'
          : 'assesseeGroup';
      yield put({
        type: SET_GROUP_SETUP_STATE,
        payload: {
          objectName: objectName,
          value: userResponse.responseObject[0].informationSetup
        }
      });
      yield put({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
      console.log('data.payload', data.payload);
      //  yield put({
      //   type: SET_DISPLAY_TWO_SINGLE_STATE,
      //   payload: {
      //     stateName: 'middlePaneListPopupOptions',
      //     value: GROUP_REVIEW_LIST_POPUP_OPTION
      //   }
      // });
      yield put({ type: POPUP_CLOSE });
    } else if (userResponse.responseCode === '301') {
      // yield put({type: SET_POPUP_SINGLE_STATE , payload: {stateName: 'popupErrorMsg', value: INFORMATION_EXIST_ERROR_MESSAGE }})
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'NAMEPOPUP',
          popupMode: `${data.payload.whichGroupCreate}GROUPCREATE`,
          popupErrorMsg: INFORMATION_EXIST_ERROR_MESSAGE
        }
      });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}

export default function* watchcreateGroupSaga() {
  yield takeLatest(CREATE_GROUP_SAGA, workerCreateGroupSaga);
}
