import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PopUpPicture from '../../PopUpInformation/PopUpPicture';
import PopUpAssesseeName from '../../PopUpInformation/PopUpAssesseeName';
import PopUpTextField from '../../PopUpInformation/PopUpTextField';
import PopUpAddressEmail from '../../PopUpInformation/PopUpAddressEmail';
import PopUpDropList from '../../PopUpInformation/PopUpDropList';
import PopUpConfirm from '../../PopUpGeneric/PopUpConfirm';
import PopUpTelephone from '../../PopUpInformation/PopUpTelephone';
import PopUpCheckbox from '../../PopUpInformation/PopUpCheckbox';
import PopUpAssignmentList from '../../PopUpInformation/PopUpAssignmentList';
import PopUpPaymentInfo from '../../PopUpInformation/PopUpPaymentInfo';
import {
  CLEAR_ASSESSEE_INFO,
  POPUP_CLOSE,
  UPDATE_ASSESSEE_PERSONAL_INFO,
  UPDATE_ASSESSEE_BASIC_INFO,
  UPDATE_ASSESSEE_MOBILE_INFO,
  UPDATE_ASSESSEE_ADDRESS_EMAIL_PRIMARY_INFO,
  UPDATE_ASSESSEE_ADDRESS_EMAIL_SECONDARY_INFO,
  UPDATE_ASSESSEE_SETUP_PRIMARY_INFO,
  UPDATE_ASSESSEE_ENGAGEMENT_INFO,
  SET_NEXT_POPUP,
  CREATE_ASSESSEE_SAGA,
  LOADER_START,
  ASSESSEE_INFO_CREATE,
  SET_ASSESSEE_DYNAMIC_SINGLE_STATE,
  UPDATE_ASSESSEE_HOMEADDRESS_INFO,
  UPDATE_ASSESSEE_TELEPHONE_WORK_PRIMARY_INFO,
  UPDATE_ASSESSEE_TELEPHONE_WORK_SECONDARY_INFO,
  UPDATE_ASSESSEE_TELEPHONE_HOME_INFO,
  UPDATE_ASSESSEE_COMMUNITY_SOCIAL_INFO,
  UPDATE_ASSESSEE_COMMUNITY_SPIRITUAL_INFO,
  UPDATE_ASSESSEE_HOMEADDRESS_SECONDARY_INFO,
  UPDATE_ASSESSEE_WORKADDRESS_INFO,
  UPDATE_ASSESSEE_WORKADDRESS_SECONDARY_INFO,
  UPDATE_ASSESSEE_TELEPHONE_HOME_SECONDARY_INFO,
  UPDATE_ASSESSEE_MOBILE_SECONDARY_INFO,
  UPDATE_ASSESSEE_TAG_STATUTORY_INFO,
  SET_DISPLAY_THREE_SINGLE_STATE,
  SET_ASSESSEE_CREATE_SINGLE_STATE,
  UPDATE_ASSESSEE_CREDENTIAL_INFO,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_POPUP_SINGLE_STATE,
  CLEAR_POPUP_INFO
} from '../../actionType';
import PopUpTagPrimary from '../../PopUpInformation/PopUpTagPrimary';
import PopUpTagSecondary from '../../PopUpInformation/PopUpTagSecondary';
import PopUpReviewList from '../../PopUpInformation/PopUpReviewList';
import PopUpAddress from '../../PopUpInformation/PopUpAddress';
import PopUpDatePicker from '../../PopUpInformation/PopUpDatePicker';
import PopUpCommunity from '../../PopUpInformation/PopUpCommunity';
import PopUpBirthplace from '../../PopUpInformation/PopUpBirthplace';
import { DEFAULT_ROLE_ID, SIGN_IN_URL } from '../../endpoints';
import PopUpFingerprint from '../../PopUpInformation/PopUpFingerprint';
import { getAllCountryList, getPopUpOptionList } from '../../Actions/GenericActions';
import { CAN_NOT_REVISED_MESSAGE, REQUIRED_ERROR_MESSAGE } from '../../errorMessage';
import { ASSESSEE_REVIEW_LIST_POPUP_OPTION } from '../../PopUpConfig';
import { checkAssesseeSignOnEmail } from '../../Actions/AssesseeModuleAction';
import PopUpQRScanner from '../../PopUpInformation/PopUpQRScanner';
import PopUpWeblink from '../../PopUpInformation/PopUpWeblink';

const PopUpAssesseeCreateSignOn = (props) => {
  const { headerOne = 'assessee' } = props;
  const { associateTagPrimary } = useParams();
  const { isPopUpValue, popupMode, popupErrorMsg } = useSelector((state) => state.PopUpReducer);
  const assesseeInfo = useSelector((state) => state.AssesseeCreateReducer);
  const {
    coreGroupReviewListData,
    selectedAssociateInfo,
    coreRoleReviewListData,
    coreNodeReviewListData,
    coreTypeReviewListData,
    allCountryList,
    signedAssesseePermission,
    selectedTagsArray,
    reviewListDistinctData,
    sectionCreateType,
    sectionCreateTypeSecondary
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const {
    reviewMode,
    statusPopUpValue = '',
    responseObject,
    createMode
  } = useSelector((state) => state.DisplayPaneThreeReducer);

  // const { selectedTagsArray } = useSelector((state) => state.SignOnReducer);

  const informationContact = assesseeInfo.informationContact;
  const [roleSelectedError, setRoleSelectedError] = useState('');
  const [defaultNodeId, setdefaultNodeId] = useState([]);
  const [selectedTagId, setSelectedTagId] = useState([]);
  const [communation, setCommunicationErr] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();

  // const { sectionCreateType, sectionCreateTypeSecondary } = useSelector((state) => state.DisplayPaneTwoReducer);

  // useEffect(() => {
  //   if (sectionCreateType !== '' && sectionCreateTypeSecondary !== '' ) {
  //     let arrTypeSecondary = [];

  //     arrTypeSecondary.push(
  //       `${process.env[`REACT_APP_ASSESSEE_${sectionCreateTypeSecondary.replace(' ', '_').toUpperCase()}`]}`
  //     );
  //     assesseeInfo.informationAllocation.assesseeType.assesseeTypeSecondary = arrTypeSecondary;
  //     // console.log(assesseeInfo.informationAllocation.assesseeType.assesseeTypeSecondary, assesseeInfo.informationAllocation)
  //   }
  // }, [sectionCreateType, sectionCreateTypeSecondary])
  useEffect(() => {
    if (sectionCreateType !== '' && reviewMode !== 'revise') {
      let secondaryTypeValue = sectionCreateType + '_ASSESSEES';
      // console.log('secondaryTypeValue--', secondaryTypeValue);
      // console.log(
      //   `REACT_APP_${secondaryTypeValue.replace(' ', '_').toUpperCase()}`,
      //   '=',
      //   `${process.env[`REACT_APP_${secondaryTypeValue.replace(' ', '_').toUpperCase()}`]}`
      // );
      let arrTypeSecondary = [];
      arrTypeSecondary.push(
        `${process.env[`REACT_APP_${secondaryTypeValue.replace(' ', '_').toUpperCase()}`]}`
      );
      // console.log('arrTypeSecondary', arrTypeSecondary);
      // console.log('Befor', assesseeInfo.informationAllocation.assesseeType.assesseeTypeSecondary);
      assesseeInfo.informationAllocation.assesseeType.assesseeTypeSecondary = arrTypeSecondary;
      // console.log('After', assesseeInfo.informationAllocation.assesseeType.assesseeTypeSecondary);
    }
  }, [coreTypeReviewListData, createMode, sectionCreateTypeSecondary, sectionCreateType]);

  useEffect(() => {
    let tempTypeArr = coreTypeReviewListData.filter((tt) => {
      return tt.informationSetup?.assesseeTypeDefault;
    });
    if (tempTypeArr.length > 0) {
      let arr = tempTypeArr.map((ob) => ob.id);
      assesseeInfo.informationAllocation.assesseeType.assesseeTypePrimary = arr;
    }
  }, [coreTypeReviewListData, createMode]);
  useEffect(() => {
    if (reviewMode !== 'revise') {
      let tempRoleArr = coreRoleReviewListData.filter((tt) => {
        return tt.informationSetup?.assesseeRoleDefault;
      });
      if (tempRoleArr.length > 0) {
        let roleArr = tempRoleArr.map((ob) => ob.id);
        assesseeInfo.informationAllocation.assesseeRole.assesseeRolePrimary = roleArr;
      }
    }
  }, [coreRoleReviewListData, createMode]);
  useEffect(() => {
    if (allCountryList.length === 0) {
      dispatch({ type: LOADER_START });
      getAllCountryList(dispatch);
    }
  }, []);
  useEffect(() => {
    if (assesseeInfo.assesseeInformationData) {
      if (popupMode === 'ASSESSEE_SIGN_ON') {
        let path = SIGN_IN_URL;
        history.push(path);
      } else {
        // onClickCancelYes();
        dispatch({ type: ASSESSEE_INFO_CREATE });
      }
    }
  }, [assesseeInfo.assesseeInformationData, history]);
  useEffect(() => {
    if (headerOne === 'assessee') {
      if (
        assesseeInfo.informationAllocation.assesseeRole.assesseeRolePrimary.includes(
          DEFAULT_ROLE_ID
        ) === false
      )
        assesseeInfo.informationAllocation.assesseeRole.assesseeRolePrimary.push(DEFAULT_ROLE_ID);
    }
    if (headerOne === 'administrator' && coreNodeReviewListData.length > 0) {
      console.log('coreNodeReviewListData', coreNodeReviewListData);
      let defaultnode = coreNodeReviewListData
        .filter((x) => x.informationFramework?.associateNodeAscendantPrimary === null)
        .map((x) => x.id);
      assesseeInfo?.informationAllocation?.assesseeNode?.assesseeNodePrimary.push(defaultnode[0]);
      setdefaultNodeId(defaultnode[0]);
    }
    // console.log("assignNodeArr",assignNodeArr);
  }, [coreRoleReviewListData, coreNodeReviewListData]);

  // console.log(
    // 'IN SIDE SIGN ON ASSESSEE+++>',
    // coreGroupReviewListData,
    // assesseeInfo?.informationAllocation?.assesseeGroup.assesseeGroupPrimary,
    // assesseeInfo?.informationAllocation?.assesseeGroup.assesseeGroupSecondary
  // );
  let selectedPrimaryGroup =
    assesseeInfo?.informationAllocation?.assesseeGroup.assesseeGroupPrimary || [];
  let selectedSecondaryGroup =
    assesseeInfo?.informationAllocation?.assesseeGroup.assesseeGroupSecondary || [];
  let filteredCoreGroupReviewListDataPrimary = [];
  if (coreGroupReviewListData && coreGroupReviewListData.length > 0) {
    coreGroupReviewListData.forEach((group) => {
      // for primary popup list
      if (!selectedSecondaryGroup.includes(group.id))
        filteredCoreGroupReviewListDataPrimary.push(group);
    });
  }
  let filteredCoreGroupReviewListDataSecondary = [];
  if (coreGroupReviewListData && coreGroupReviewListData.length > 0) {
    coreGroupReviewListData.forEach((group) => {
      // for Secondary popup list
      if (!selectedPrimaryGroup.includes(group.id))
        filteredCoreGroupReviewListDataSecondary.push(group);
    });
  }

  const onClickYes = async () => {
    const {
      informationBasic,
      informationAllocation,
      informationContact,
      informationPersonal,
      informationSetup,
      informationEngagement,
      tempCommunication
    } = assesseeInfo;
    if (headerOne === 'administrator' || headerOne === 'manager' || headerOne === 'assistant') {
      informationAllocation.assesseeRole.assesseeRolePrimary.push(DEFAULT_ROLE_ID);
    }
    if (tempCommunication === 'email address (primary)') {
      informationContact.assesseeAddressEmailPrimary.assesseeAddressEmailCommunication = true;
    }
    if (tempCommunication === 'email address (secondary)') {
      informationContact.assesseeAddressEmailSecondary.assesseeAddressEmailCommunication = true;
    }
    // add default root node in allocation if node not selected
    if (
      informationAllocation.assesseeNode.assesseeNodePrimary.length === 0 &&
      coreNodeReviewListData.length !== 0
    ) {
      let rootNode = coreNodeReviewListData.filter((node) => {
        return node.informationFramework.associateNodeAscendantPrimary === null;
      });
      let rootNodeId = rootNode[0].id;
      informationAllocation.assesseeNode.assesseeNodePrimary = [
        ...informationAllocation.assesseeNode.assesseeNodePrimary,
        rootNodeId
      ];
      console.log('rootNodeId', rootNodeId);
    }

    let requestObect = {
      module: 'Assessee-Distinct',
      action: 'Create',
      signOn: popupMode === 'ASSESSEE_SIGN_ON',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId || '',
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag
            .associateTagPrimary || associateTagPrimary,
        assessee: {
          informationBasic: informationBasic,
          informationAllocation: informationAllocation,
          informationContact: informationContact,
          informationPersonal: informationPersonal,
          informationEngagement: informationEngagement,
          informationSetup: informationSetup
        }
      }
    };
    if (popupMode === 'ASSESSEE_SIGN_ON') {
      requestObect.requestObject['assignmentIds'] = selectedTagsArray;
    }
    console.log('ONCLICK assessee Create Yes', requestObect);
    // console.log('loading start');
    dispatch({ type: LOADER_START });
    dispatch({ type: CREATE_ASSESSEE_SAGA, payload: requestObect });
    // console.log(signedAssesseePermission, 'signedAssesseePermission')
    let assesseeDistinctPermission =
      signedAssesseePermission?.assesseeDistinctPermission?.assesseeAssesseeDistinctPermission;
    let popupContentArrValue = getPopUpOptionList(
      ASSESSEE_REVIEW_LIST_POPUP_OPTION,
      assesseeDistinctPermission
    );
    let finalPopupOptionArray = [];
    popupContentArrValue.forEach((element) => {
      if (element.data === 'share') {
        finalPopupOptionArray.push({ ...element, disabled: true });
      } else {
        finalPopupOptionArray.push(element);
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: finalPopupOptionArray
      }
    });
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: {
        stateName: 'popupOptionArr',
        value: finalPopupOptionArray
      }
    });
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: {
        stateName: 'originPrimaryArrOprion',
        value: finalPopupOptionArray
      }
    });
  };

  const onClickCancelYes = () => {
    // debugger;
    dispatch({
      type: SET_DISPLAY_THREE_SINGLE_STATE,
      payload: { stateName: 'createMode', value: '' }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'selectedTagsArray', value: [] }
    })
    dispatch({ type: CLEAR_ASSESSEE_INFO });
    dispatch({ type: CLEAR_POPUP_INFO });
    dispatch({ type: POPUP_CLOSE });
  };
  const ToForceToForSign = (id, value) => {
    let tempCommunication = assesseeInfo.tempCommunication;
    console.log('id, value', id + value);
    if (tempCommunication === '') {
      setCommunicationErr(REQUIRED_ERROR_MESSAGE);
    }
  };
  const handleNextPopupValue = () => {
    let tempCommunication = assesseeInfo.tempCommunication;
    let secondemail = informationContact.assesseeAddressEmailSecondary.assesseeAddressEmail;
    if (isPopUpValue === 'EMAILPOPUP') {
      if (tempCommunication === '' || secondemail !== '') {
        dispatch({ type: SET_NEXT_POPUP, payload: { isPopUpValue: 'EMAILSECONDARYPOPUP' } });
      } else {
        dispatch({ type: SET_NEXT_POPUP, payload: { isPopUpValue: 'MOBILETELEPHONEPOPUP' } });
      }
    } else if (isPopUpValue === 'EMAILSECONDARYPOPUP') {
      if (tempCommunication === '') {
        dispatch({ type: SET_NEXT_POPUP, payload: { isPopUpValue: 'FORCETOSELECTCOMMUNICATION' } });
      } else {
        dispatch({ type: SET_NEXT_POPUP, payload: { isPopUpValue: 'MOBILETELEPHONEPOPUP' } });
      }
    } else if (isPopUpValue === 'PICTUREPOPUP') {
      if (popupMode === 'ASSESSEE_SIGN_ON') {
        dispatch({ type: SET_NEXT_POPUP, payload: { isPopUpValue: 'EMAILPOPUP' } });
      } else {
        // dispatch({ type: SET_NEXT_POPUP, payload: { isPopUpValue: 'ROLELISTPOPUP' } });
        dispatch({ type: SET_NEXT_POPUP, payload: { isPopUpValue: 'GROUPLISTPOPUP' } });
      }
    } else {
      dispatch({ type: SET_NEXT_POPUP, payload: { isPopUpValue: 'CONFIRMATIONPOPUP' } });
    }
  };

  const updateNodeIdObject = (e) => {
    let nodeid = e.currentTarget.getAttribute('tag');
    let nodeArr = assesseeInfo.informationAllocation.assesseeNode.assesseeNodePrimary;
    // if (defaultNodeId !== nodeid || headerOne !== 'administrator') {
    setRoleSelectedError('');
    if (nodeArr.includes(nodeid)) {
      document.getElementById(nodeid).style.backgroundColor = 'white';
      nodeArr = nodeArr.filter(function (number) {
        return number !== nodeid;
      });
    } else {
      nodeArr.push(nodeid);
      document.getElementById(nodeid).style.backgroundColor = '#F0F0F0';
    }
    dispatch({
      type: SET_ASSESSEE_DYNAMIC_SINGLE_STATE,
      payload: {
        stateName: 'assesseeNode',
        actualStateName: 'assesseeNodePrimary',
        value: nodeArr
      }
    });
    // }
  };
  const updateNodeIdSecondaryObject = (e) => {
    let nodeid = e.currentTarget.getAttribute('tag');
    let nodeArr = assesseeInfo.informationAllocation.assesseeNode.assesseeNodeSecondary;
    // if (defaultNodeId !== nodeid || headerOne !== 'administrator') {
    setRoleSelectedError('');
    if (nodeArr.includes(nodeid)) {
      document.getElementById(nodeid).style.backgroundColor = 'white';
      nodeArr = nodeArr.filter(function (number) {
        return number !== nodeid;
      });
    } else {
      nodeArr.push(nodeid);
      document.getElementById(nodeid).style.backgroundColor = '#F0F0F0';
    }
    dispatch({
      type: SET_ASSESSEE_DYNAMIC_SINGLE_STATE,
      payload: {
        stateName: 'assesseeNode',
        actualStateName: 'assesseeNodeSecondary',
        value: nodeArr
      }
    });
    // }
  };
  const updateRoleIdObject = (e) => {
    // console.log(e.currentTarget.getAttribute('tag'));
    // console.log(assesseeInfo.informationAllocation.assesseeRole.assesseeRolePrimary);
    let roleid = e.currentTarget.getAttribute('tag');
    let roleArr = assesseeInfo.informationAllocation.assesseeRole.assesseeRolePrimary;
    console.log(roleArr.includes(roleid));
    setRoleSelectedError('');
    let isDefault = e.currentTarget.getAttribute('data-default');
    if (isDefault !== 'true') {
      if (roleArr.includes(roleid)) {
        document.getElementById(roleid).style.backgroundColor = 'white';
        roleArr = roleArr.filter(function (number) {
          return number !== roleid;
        });
      } else {
        roleArr.push(roleid);
        document.getElementById(roleid).style.backgroundColor = '#F0F0F0';
      }
      dispatch({
        type: SET_ASSESSEE_DYNAMIC_SINGLE_STATE,
        payload: {
          stateName: 'assesseeRole',
          actualStateName: 'assesseeRolePrimary',
          value: roleArr
        }
      });
    }
  };
  const handleClickOnCorrect = () => { };
  const updateTypeIdObject = (e) => {
    let typeid = e.currentTarget.getAttribute('tag');
    let typeArr = assesseeInfo.informationAllocation.assesseeType.assesseeTypeSecondary;
    console.log(typeArr.includes(typeid));
    setRoleSelectedError('');
    if (typeArr.includes(typeid)) {
      document.getElementById(typeid).style.backgroundColor = 'white';
      typeArr = typeArr.filter(function (number) {
        return number !== typeid;
      });
    } else {
      typeArr.push(typeid);
      document.getElementById(typeid).style.backgroundColor = '#F0F0F0';
    }

    dispatch({
      type: SET_ASSESSEE_DYNAMIC_SINGLE_STATE,
      payload: {
        stateName: 'assesseeType',
        actualStateName: 'assesseeTypeSecondary',
        value: typeArr
      }
    });
  };
  const updateTypeIdPrimaryObject = (e) => {
    let typeid = e.currentTarget.getAttribute('tag');
    let typeArr = assesseeInfo.informationAllocation.assesseeType.assesseeTypePrimary;
    console.log(typeArr.includes(typeid));
    setRoleSelectedError('');
    let isDefault = e.currentTarget.getAttribute('data-default');
    if (isDefault !== 'true') {
      if (typeArr.includes(typeid)) {
        document.getElementById(typeid).style.backgroundColor = 'white';
        typeArr = typeArr.filter(function (number) {
          return number !== typeid;
        });
      } else {
        typeArr.push(typeid);
        document.getElementById(typeid).style.backgroundColor = '#F0F0F0';
      }
      dispatch({
        type: SET_ASSESSEE_DYNAMIC_SINGLE_STATE,
        payload: {
          stateName: 'assesseeType',
          actualStateName: 'assesseeTypePrimary',
          value: typeArr
        }
      });
    }
  };
  const updateRoleIdSecondaryObject = (e) => {
    let roleid = e.currentTarget.getAttribute('tag');
    let roleArr = assesseeInfo.informationAllocation.assesseeRole.assesseeRoleSecondary;
    console.log(roleArr.includes(roleid));
    setRoleSelectedError('');
    if (roleArr.includes(roleid)) {
      document.getElementById(roleid).style.backgroundColor = 'white';
      roleArr = roleArr.filter(function (number) {
        return number !== roleid;
      });
    } else {
      roleArr.push(roleid);
      document.getElementById(roleid).style.backgroundColor = '#F0F0F0';
    }
    dispatch({
      type: SET_ASSESSEE_DYNAMIC_SINGLE_STATE,
      payload: {
        stateName: 'assesseeRole',
        actualStateName: 'assesseeRoleSecondary',
        value: roleArr
      }
    });
  };
  const updateAssesseeGroups = (e) => {
    let groupid = e.currentTarget.getAttribute('tag');
    let roleArr = assesseeInfo.informationAllocation.assesseeGroup.assesseeGroupPrimary;

    if (roleArr.includes(groupid)) {
      document.getElementById(groupid).style.backgroundColor = 'white';
      roleArr = roleArr.filter(function (number) {
        return number !== groupid;
      });
    } else {
      roleArr.push(groupid);
      document.getElementById(groupid).style.backgroundColor = '#F0F0F0';
    }
    dispatch({
      type: SET_ASSESSEE_DYNAMIC_SINGLE_STATE,
      payload: {
        stateName: 'assesseeGroup',
        actualStateName: 'assesseeGroupPrimary',
        value: roleArr
      }
    });
  };
  const updateAssesseeSecondaryGroups = (e) => {
    let groupid = e.currentTarget.getAttribute('tag');
    let roleArr = assesseeInfo.informationAllocation.assesseeGroup.assesseeGroupSecondary;

    if (roleArr.includes(groupid)) {
      document.getElementById(groupid).style.backgroundColor = 'white';
      roleArr = roleArr.filter(function (number) {
        return number !== groupid;
      });
    } else {
      roleArr.push(groupid);
      document.getElementById(groupid).style.backgroundColor = '#F0F0F0';
    }
    dispatch({
      type: SET_ASSESSEE_DYNAMIC_SINGLE_STATE,
      payload: {
        stateName: 'assesseeGroup',
        actualStateName: 'assesseeGroupSecondary',
        value: roleArr
      }
    });
  };
  console.log('assesseeInfo', assesseeInfo);
  // console.log('coreNodeReviewListData', coreNodeReviewListData);
  const onClickCheckbox = (e, inputHeader, primaryheader) => {
    console.log('onClickCheckbox', e.target.checked);
    let val = inputHeader + ' ' + primaryheader;
    dispatch({
      type: SET_ASSESSEE_CREATE_SINGLE_STATE,
      payload: { stateName: 'tempAddressCommunication', value: e.target.checked ? val : '' }
    });
  };
  const onClickCheckboxMobile = (e, inputHeader, primaryheader) => {
    console.log('onClickCheckbox', e.target.checked);
    let val = inputHeader + ' ' + primaryheader;
    dispatch({
      type: SET_ASSESSEE_CREATE_SINGLE_STATE,
      payload: { stateName: 'tempTelephoneCommunication', value: e.target.checked ? val : '' }
    });
  };
  console.log(isPopUpValue, '**', reviewListDistinctData);
  return (
    <div>
      {isPopUpValue === 'ASSIGNMENTLIST' && (
        <PopUpAssignmentList
          isActive={true}
          tempTelephoneCommunication={assesseeInfo.tempTelephoneCommunication}
          basicInfo={informationContact.assesseeTelephoneMobilePrimary}
          typeOfSetObject={UPDATE_ASSESSEE_MOBILE_INFO}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          inputHeader={'assignments'}
          inputHeaderBadge={'mine'}
          infoMsg={'select the assignments'}
          nextPopUpValue={selectedTagsArray.length > 0 ? 'PAYMENTINFO' : 'CONFIRMATIONPOPUP'}
          isSignOn={true}
        // mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'PAYMENTINFO' && (
        <PopUpPaymentInfo
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          isSignOn={true}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'CONFIRMATIONPOPUP'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ASSESSEENAMEPOPUP' && (
        <PopUpAssesseeName
          isActive={true}
          inputHeader={'name'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          basicInfo={assesseeInfo.informationBasic}
          nextPopUpValue={'ALIASPOPUP'}
          typeOfSetObject={UPDATE_ASSESSEE_BASIC_INFO}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ALIASPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'alias'}
          actualLableValue={'assesseeAlias'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          basicInfo={assesseeInfo.informationBasic}
          nextPopUpValue={'PICTUREPOPUP'}
          typeOfSetObject={UPDATE_ASSESSEE_BASIC_INFO}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'PICTUREPOPUP' && (
        <PopUpPicture
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          basicInfo={assesseeInfo.informationBasic}
          actualLableValue={'assesseePicture'}
          actualLableValueVerification={'assesseePictureVerification'}
          typeOfSetObject={UPDATE_ASSESSEE_BASIC_INFO}
          // nextPopUpValue={popupMode === 'ASSESSEE_SIGN_ON' ? 'EMAILPOPUP' :'ROLELISTPOPUP'}
          handleNextPopupValue={handleNextPopupValue}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'PopUpQRScanner_environment' && (
        <PopUpQRScanner
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          basicInfo={assesseeInfo.informationBasic}
          actualLableValue={'assesseePicture'}
          actualLableValueVerification={'assesseePictureVerification'}
          typeOfSetObject={UPDATE_ASSESSEE_BASIC_INFO}
          // nextPopUpValue={popupMode === 'ASSESSEE_SIGN_ON' ? 'EMAILPOPUP' :'ROLELISTPOPUP'}
          handleNextPopupValue={handleNextPopupValue}
          mode={'environment'}
        />
      )}
      {isPopUpValue === 'PopUpQRScanner_link' && (
        <PopUpWeblink
          isActive={true}
          label={'link'}
          labelBadgeOne={''}
          actualLableValue={'link'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'PopUpQRScanner_user' && (
        <PopUpQRScanner
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          basicInfo={assesseeInfo.informationBasic}
          actualLableValue={'assesseePicture'}
          actualLableValueVerification={'assesseePictureVerification'}
          typeOfSetObject={UPDATE_ASSESSEE_BASIC_INFO}
          // nextPopUpValue={popupMode === 'ASSESSEE_SIGN_ON' ? 'EMAILPOPUP' :'ROLELISTPOPUP'}
          handleNextPopupValue={handleNextPopupValue}
          mode={'user'}
        />
      )}
      {isPopUpValue === 'TAGSTATUTORY' && (
        <PopUpTextField
          isActive={true}
          label={'tag'}
          labelBadgeOne={'statutory'}
          actualLableValue={'assesseeTagStatutory'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          basicInfo={assesseeInfo.informationBasic}
          nextPopUpValue={''}
          typeOfSetObject={UPDATE_ASSESSEE_TAG_STATUTORY_INFO}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TAGREADONLYPRIMARYPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tag'}
          labelBadgeOne={'primary'}
          actualLableValue={'assesseeTagPrimary'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          basicInfo={responseObject?.informationEngagement?.assesseeTag || ''}
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={''}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TENURESATRTDATEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tenure'}
          labelBadgeOne={'start'}
          actualLableValue={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          basicInfo={
            responseObject?.informationEngagement?.assesseeTenure?.assesseeTenureDateTimeStart ||
            'mm/dd/yyyy --:-- --'
          }
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={''}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TENUREENDDATEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tenure'}
          labelBadgeOne={'end'}
          actualLableValue={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          basicInfo={
            responseObject?.informationEngagement?.assesseeTenure?.assesseeTenureDateTimeEnd ||
            'mm/dd/yyyy --:-- --'
          }
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={''}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}

      {isPopUpValue === 'SIGNATUREPOPUP' && (
        <PopUpPicture
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'signature'}
          headerOneBadgeOne={''}
          basicInfo={assesseeInfo.informationCredential}
          actualLableValue={'assesseeSignature'}
          actualLableValueVerification={'assesseeSignatureVerification'}
          typeOfSetObject={UPDATE_ASSESSEE_CREDENTIAL_INFO}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'GROUPLISTPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'NODELISTPOPUP'}
          inputHeader={'group'}
          inputHeaderBadge={'primary'}
          infoMsg={'select a group'}
          ListData={filteredCoreGroupReviewListDataPrimary}
          textOne={'assesseeGroupName'}
          textTwo={'assesseeGroupDescription'}
          onClickEvent={updateAssesseeGroups}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          selectedList={assesseeInfo?.informationAllocation?.assesseeGroup.assesseeGroupPrimary}
          useLocalState={true}
          stateName={`assesseeGroup`}
          actualStateName={`assesseeGroupPrimary`}
          objectName={'informationAllocation'}
          updateState={SET_ASSESSEE_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'GROUPLISTSECONDARYPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'NODELISTPOPUP'}
          inputHeader={'group'}
          inputHeaderBadge={'secondary'}
          infoMsg={'select a group'}
          ListData={filteredCoreGroupReviewListDataSecondary}
          textOne={'assesseeGroupName'}
          textTwo={'assesseeGroupDescription'}
          onClickEvent={updateAssesseeSecondaryGroups}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          selectedList={assesseeInfo?.informationAllocation?.assesseeGroup.assesseeGroupSecondary}
          useLocalState={true}
          stateName={`assesseeGroup`}
          actualStateName={`assesseeGroupSecondary`}
          objectName={'informationAllocation'}
          updateState={SET_ASSESSEE_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'NODELISTPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'ROLELISTPOPUP'}
          inputHeader={'node'}
          inputHeaderBadge={'primary'}
          infoMsg={'select a node'}
          isRequired={true}
          minimumSelected={1}
          selectedList={assesseeInfo?.informationAllocation?.assesseeNode.assesseeNodePrimary}
          setErrorMsg={setRoleSelectedError}
          errorMsg={roleSelectedError}
          ListData={coreNodeReviewListData}
          // ListData={assignNodeArr}
          textOne={'associateNodeName'}
          textTwo={'associateNodeDescription'}
          onClickEvent={updateNodeIdObject}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          useLocalState={true}
          stateName={`assesseeNode`}
          actualStateName={`assesseeNodePrimary`}
          objectName={'informationAllocation'}
          updateState={SET_ASSESSEE_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'NODELISTSECONDARYPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'ROLELISTPOPUP'}
          inputHeader={'node'}
          inputHeaderBadge={'secondary'}
          infoMsg={'select a node'}
          isRequired={false}
          selectedList={assesseeInfo?.informationAllocation?.assesseeNode.assesseeNodeSecondary}
          setErrorMsg={setRoleSelectedError}
          errorMsg={roleSelectedError}
          // ListData={assignNodeArr}
          ListData={coreNodeReviewListData}
          textOne={'associateNodeName'}
          textTwo={'associateNodeDescription'}
          onClickEvent={updateNodeIdSecondaryObject}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          useLocalState={true}
          stateName={`assesseeNode`}
          actualStateName={`assesseeNodeSecondary`}
          objectName={'informationAllocation'}
          updateState={SET_ASSESSEE_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'ROLELISTPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'TYPELISTPOPUP'}
          inputHeader={'role'}
          inputHeaderBadge={'primary'}
          infoMsg={'select a role'}
          isRequired={true}
          minimumSelected={1}
          dataDefault={'assesseeRoleDefault'}
          selectedList={assesseeInfo?.informationAllocation?.assesseeRole.assesseeRolePrimary}
          setErrorMsg={setRoleSelectedError}
          errorMsg={roleSelectedError}
          ListData={
            headerOne === 'assessee' || headerOne === 'assessees'
              ? coreRoleReviewListData.filter((tt) => {
                return tt.informationSetup.assesseeRoleDefault;
              })
              : coreRoleReviewListData
          }
          textOne={'assesseeRoleName'}
          textTwo={'assesseeRoleDescription'}
          onClickEvent={updateRoleIdObject}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          useLocalState={true}
          stateName={`assesseeRole`}
          actualStateName={`assesseeRolePrimary`}
          objectName={'informationAllocation'}
          updateState={SET_ASSESSEE_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'ROLELISTSECONDARYPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'EMAILPOPUP'}
          inputHeader={'role'}
          inputHeaderBadge={'secondary'}
          infoMsg={'select a role'}
          isRequired={true}
          selectedList={assesseeInfo?.informationAllocation?.assesseeRole.assesseeRoleSecondary}
          setErrorMsg={setRoleSelectedError}
          errorMsg={roleSelectedError}
          ListData={coreRoleReviewListData}
          textOne={'assesseeRoleName'}
          textTwo={'assesseeRoleDescription'}
          onClickEvent={updateRoleIdSecondaryObject}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          useLocalState={true}
          stateName={`assesseeRole`}
          actualStateName={`assesseeRoleSecondary`}
          objectName={'informationAllocation'}
          updateState={SET_ASSESSEE_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'TYPELISTPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'EMAILPOPUP'}
          inputHeader={'type'}
          inputHeaderBadge={'primary'}
          infoMsg={'select a type'}
          ListData={coreTypeReviewListData.filter((tt) => {
            return tt.informationSetup.assesseeTypeDefault;
          })}
          dataDefault={'assesseeTypeDefault'}
          textOne={'assesseeTypeName'}
          textTwo={'assesseeTypeDescription'}
          onClickEvent={updateTypeIdPrimaryObject}
          selectedList={assesseeInfo?.informationAllocation?.assesseeType.assesseeTypePrimary}
          minimumSelected={1}
          isRequired={true}
          setErrorMsg={setRoleSelectedError}
          errorMsg={roleSelectedError}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          useLocalState={true}
          stateName={`assesseeType`}
          actualStateName={`assesseeTypePrimary`}
          objectName={'informationAllocation'}
          updateState={SET_ASSESSEE_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'TYPELISTSECONDARYPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'EMAILPOPUP'}
          inputHeader={'type'}
          inputHeaderBadge={'secondary'}
          infoMsg={'select a type'}
          ListData={coreTypeReviewListData.filter((tt) => {
            return !tt.informationSetup.assesseeTypeDefault;
          })}
          handleClickOnCorrect={handleClickOnCorrect}
          textOne={'assesseeTypeName'}
          textTwo={'assesseeTypeDescription'}
          onClickEvent={updateTypeIdObject}
          selectedList={assesseeInfo?.informationAllocation?.assesseeType.assesseeTypeSecondary}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          useLocalState={true}
          stateName={`assesseeType`}
          actualStateName={`assesseeTypeSecondary`}
          objectName={'informationAllocation'}
          updateState={SET_ASSESSEE_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'EMAILPOPUP' && (
        <PopUpAddressEmail
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          primaryLabel={'email address'}
          primaryLabelBadge={'primary'}
          tag={'assesseeAddressEmail'}
          errorMsg={popupErrorMsg}
          basicInfo={informationContact.assesseeAddressEmailPrimary}
          signInSetup={assesseeInfo.informationSetup}
          nextPopUpValue={'MOBILETELEPHONEPOPUP'}
          tempCommunication={assesseeInfo.tempCommunication}
          typeOfSetObject={UPDATE_ASSESSEE_ADDRESS_EMAIL_PRIMARY_INFO}
          handleNextPopupValue={handleNextPopupValue}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'EMAILSECONDARYPOPUP' && (
        <PopUpAddressEmail
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          primaryLabel={'email address'}
          primaryLabelBadge={'secondary'}
          tag={'assesseeAddressEmail'}
          basicInfo={informationContact.assesseeAddressEmailSecondary}
          signInSetup={assesseeInfo.informationSetup}
          // nextPopUpValue={'MOBILETELEPHONEPOPUP'}
          typeOfSetObject={UPDATE_ASSESSEE_ADDRESS_EMAIL_SECONDARY_INFO}
          handleNextPopupValue={handleNextPopupValue}
          tempCommunication={assesseeInfo.tempCommunication}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'FORCETOSELECTCOMMUNICATION' && (
        <PopUpCheckbox
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          valueArr={['email address (primary)', 'email address (secondary)']}
          availableSignInCredentialList={[
            informationContact.assesseeAddressEmailPrimary.assesseeAddressEmail &&
            'email address (primary)',
            informationContact.assesseeAddressEmailSecondary.assesseeAddressEmail &&
            'email address (secondary)'
          ]}
          basicInfo={informationContact.assesseeAddressEmailPrimary}
          nextPopUpValue={'MOBILETELEPHONEPOPUP'}
          tempCommunication={assesseeInfo.tempCommunication}
          forceToSelectSign
          onClickNext={ToForceToForSign}
          typeOfPrimarySetObject={UPDATE_ASSESSEE_ADDRESS_EMAIL_PRIMARY_INFO}
          typeOfSecondaSetObject={UPDATE_ASSESSEE_ADDRESS_EMAIL_SECONDARY_INFO}
          EmailPrimaryCommunication={informationContact.assesseeAddressEmailPrimary}
          EmailSecondaCommunication={informationContact.assesseeAddressEmailSecondary}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'MOBILETELEPHONEPOPUP' && (
        <PopUpTelephone
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          inputHeader={'mobile telephone'}
          primaryheader={'primary'}
          onClickCheckbox={onClickCheckboxMobile}
          tempTelephoneCommunication={assesseeInfo.tempTelephoneCommunication}
          basicInfo={informationContact.assesseeTelephoneMobilePrimary}
          nextPopUpValue={'SINGLEDROPDOWNPOPUP'}
          typeOfSetObject={UPDATE_ASSESSEE_MOBILE_INFO}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isMobileState={true}
        />
      )}
      {isPopUpValue === 'MOBILETELEPHONESECONDARYPOPUP' && (
        <PopUpTelephone
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          inputHeader={'mobile telephone'}
          primaryheader={'secondary'}
          onClickCheckbox={onClickCheckboxMobile}
          basicInfo={informationContact.assesseeTelephoneMobileSecondary}
          tempTelephoneCommunication={assesseeInfo.tempTelephoneCommunication}
          nextPopUpValue={'SINGLEDROPDOWNPOPUP'}
          typeOfSetObject={UPDATE_ASSESSEE_MOBILE_SECONDARY_INFO}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isMobileState={true}
        />
      )}
      {isPopUpValue === 'HOMETELEPHONEPOPUP' && (
        <PopUpTelephone
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          inputHeader={'home telephone'}
          primaryheader={'primary'}
          onClickCheckbox={onClickCheckboxMobile}
          tempTelephoneCommunication={assesseeInfo.tempTelephoneCommunication}
          basicInfo={informationContact.assesseeTelephoneHomePrimary}
          nextPopUpValue={''}
          typeOfSetObject={UPDATE_ASSESSEE_TELEPHONE_HOME_INFO}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'HOMETELEPHONESECONDARYPOPUP' && (
        <PopUpTelephone
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          inputHeader={'home telephone'}
          primaryheader={'secondary'}
          onClickCheckbox={onClickCheckboxMobile}
          tempTelephoneCommunication={assesseeInfo.tempTelephoneCommunication}
          basicInfo={informationContact.assesseeTelephoneHomeSecondary}
          nextPopUpValue={''}
          typeOfSetObject={UPDATE_ASSESSEE_TELEPHONE_HOME_SECONDARY_INFO}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'WORKTELEPHONEPOPUP' && (
        <PopUpTelephone
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          inputHeader={'work telephone'}
          primaryheader={'primary'}
          onClickCheckbox={onClickCheckboxMobile}
          tempTelephoneCommunication={assesseeInfo.tempTelephoneCommunication}
          basicInfo={informationContact.assesseeTelephoneWorkPrimary}
          nextPopUpValue={''}
          typeOfSetObject={UPDATE_ASSESSEE_TELEPHONE_WORK_PRIMARY_INFO}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'WORKTELEPHONESECONDARPOPUP' && (
        <PopUpTelephone
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          inputHeader={'work telephone'}
          primaryheader={'secondary'}
          onClickCheckbox={onClickCheckboxMobile}
          tempTelephoneCommunication={assesseeInfo.tempTelephoneCommunication}
          basicInfo={informationContact.assesseeTelephoneWorkSecondary}
          nextPopUpValue={''}
          typeOfSetObject={UPDATE_ASSESSEE_TELEPHONE_WORK_SECONDARY_INFO}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'SINGLEDROPDOWNPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assesseeGender'}
          label={'gender'}
          listSelect={[
            { id: '', name: '' },
            { id: 'Female', name: 'Female' },
            { id: 'Male', name: 'Male' },
            { id: 'Unlisted', name: 'Unlisted' }
          ]}
          mappingValue={'id'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          isRequired={true}
          basicInfo={assesseeInfo.informationPersonal}
          nextPopUpValue={
            popupMode === 'ASSESSEE_SIGN_ON' && reviewListDistinctData.length > 0
              ? 'ASSIGNMENTLIST'
              : 'CONFIRMATIONPOPUP'
          }
          typeOfSetObject={UPDATE_ASSESSEE_PERSONAL_INFO}
          handleNextPopupValue={handleNextPopupValue}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'PASSWORDSETUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assesseePassword'}
          label={'password'}
          listSelect={[
            { id: '', name: '' },
            { id: 'auto', name: 'Automatic Generate' },
            { id: 'Male', name: 'create password' }
            // { id: 'Unlisted', name: 'Unlisted' }
          ]}
          isCheckboxState={'assesseePassword'}
          isCheckboxDisplay={true}
          isCheckboxLabel={'ask assessee to change their password when they sign-in'}
          mappingValue={'id'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          isRequired={true}
          basicInfo={assesseeInfo.informationPersonal}
          nextPopUpValue={'CONFIRMATIONPOPUP'}
          typeOfSetObject={UPDATE_ASSESSEE_PERSONAL_INFO}
          handleNextPopupValue={handleNextPopupValue}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'STATUSPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assesseeStatus'}
          label={'status'}
          listSelect={[
            { id: '', name: '' },
            { id: 'Confirmed', name: 'Confirmed' },
            { id: 'Suspended', name: 'Suspended' },
            { id: 'Terminated', name: 'Terminated' },
            { id: 'Unverified', name: 'Unverified' },
            { id: 'Unconfirmed', name: 'Unconfirmed' },
            { id: 'Verified', name: 'Verified' }
          ]}
          mappingValue={'id'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          isRequired={true}
          basicInfo={statusPopUpValue}
          nextPopUpValue={''}
          typeOfSetObject={UPDATE_ASSESSEE_PERSONAL_INFO}
          handleNextPopupValue={handleNextPopupValue}
          isNotRevised={true}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TAGPRIMARYPOPUP' && (
        <PopUpTagPrimary
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          signInSetup={assesseeInfo.informationSetup}
          // nextPopUpValue={'CONFIRMATIONPOPUP'}
          handleNextPopupValue={handleNextPopupValue}
          typeOfSetObject={UPDATE_ASSESSEE_SETUP_PRIMARY_INFO}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TAGSECONDARYPOPUP' && (
        <PopUpTagSecondary
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          tagSecondary={assesseeInfo.informationEngagement}
          signInSetup={assesseeInfo.informationSetup}
          nextPopUpValue={'CONFIRMATIONPOPUP'}
          typeOfSetObject={UPDATE_ASSESSEE_ENGAGEMENT_INFO}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'FORCETOSELECTSIGNIN' && (
        <PopUpCheckbox
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          valueArr={[
            'email address (primary)',
            'email address (secondary)',
            'mobile telephone (primary)',
            'mobile telephone (secondary)',
            'tag (primary)',
            'tag (secondary)'
          ]}
          availableSignInCredentialList={assesseeInfo.availableSignInCredentialList}
          forceToSelect="signIn"
          checkedValue={assesseeInfo.currentlySignInCredential}
          typeOfSetObject={UPDATE_ASSESSEE_SETUP_PRIMARY_INFO}
          errorMsg={
            assesseeInfo.availableSignInCredentialList.length == 0 && CAN_NOT_REVISED_MESSAGE
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={assesseeInfo.availableSignInCredentialList.length == 0}
        />
      )}
      {isPopUpValue === 'CANCELPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'cancel'}
          headerOneBadgeOne={''}
          mode={'cancel'}
          onClickYes={onClickCancelYes}
        />
      )}
      {isPopUpValue === 'CONFIRMATIONPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'create'}
          onClickYes={onClickYes}
          onClickNoFun={onClickCancelYes}
        />
      )}
      {isPopUpValue === 'HOMEADDRESSPOPUP' && (
        <PopUpAddress
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          inputHeader={'home address'}
          primaryheader={'primary'}
          nextPopUpValue={''}
          isRequired={true}
          onClickCheckbox={onClickCheckbox}
          addressCommunication={assesseeInfo.tempAddressCommunication}
          basicInfo={assesseeInfo.informationContact.assesseeAddressHomePrimary}
          typeOfSetObject={UPDATE_ASSESSEE_HOMEADDRESS_INFO}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'HOMEADDRESSSECONDARYPOPUP' && (
        <PopUpAddress
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          inputHeader={'home address'}
          primaryheader={'secondary'}
          nextPopUpValue={''}
          isRequired={true}
          onClickCheckbox={onClickCheckbox}
          addressCommunication={assesseeInfo.tempAddressCommunication}
          basicInfo={assesseeInfo.informationContact.assesseeAddressHomeSecondary}
          typeOfSetObject={UPDATE_ASSESSEE_HOMEADDRESS_SECONDARY_INFO}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'WORKADDRESSPOPUP' && (
        <PopUpAddress
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          inputHeader={'work address'}
          primaryheader={'primary'}
          nextPopUpValue={''}
          isRequired={true}
          onClickCheckbox={onClickCheckbox}
          addressCommunication={assesseeInfo.tempAddressCommunication}
          basicInfo={assesseeInfo.informationContact.assesseeAddressWorkPrimary}
          typeOfSetObject={UPDATE_ASSESSEE_WORKADDRESS_INFO}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'WORKADDRESSSECONDARYPOPUP' && (
        <PopUpAddress
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          inputHeader={'work address'}
          primaryheader={'secondary'}
          nextPopUpValue={''}
          isRequired={true}
          onClickCheckbox={onClickCheckbox}
          addressCommunication={assesseeInfo.tempAddressCommunication}
          basicInfo={assesseeInfo.informationContact.assesseeAddressWorkSecondary}
          typeOfSetObject={UPDATE_ASSESSEE_WORKADDRESS_SECONDARY_INFO}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'BIRTHDATEPOPUP' && (
        <PopUpDatePicker
          isActive={true}
          primaryheader=""
          inputHeader="birthdate"
          headerPanelColour="genericOne"
          headerOne="assessees"
          headerOneBadgeOne="information"
          valueState="tenurestart"
          isVerification={true}
          basicInfo={assesseeInfo.informationPersonal}
          typeOfSetObject={UPDATE_ASSESSEE_PERSONAL_INFO}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'BIRTHMARKPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'birthmark'}
          actualLableValue={'assesseeBirthmark'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          basicInfo={assesseeInfo.informationPersonal}
          nextPopUpValue={''}
          typeOfSetObject={UPDATE_ASSESSEE_PERSONAL_INFO}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'COMMUNITYSOCIALPOPUP' && (
        <PopUpCommunity
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          inputHeader={'community'}
          primaryheader={'social'}
          nextPopUpValue={''}
          isRequired={false}
          assesseeCommunityArr={[
            { stateCode: '', name: '' },
            { stateCode: '01', name: 'Other Backward Class' },
            { stateCode: '02', name: 'Scheduled Class' },
            { stateCode: '03', name: 'Scheduled Tribe' },
            { stateCode: '04', name: 'Unlisted' }
          ]}
          mappingValue={'name'}
          basicInfo={assesseeInfo?.informationPersonal?.assesseeCommunitySocial || {}}
          typeOfSetObject={UPDATE_ASSESSEE_COMMUNITY_SOCIAL_INFO}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'COMMUNITYSPIRITUALPOPUP' && (
        <PopUpCommunity
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          inputHeader={'community'}
          primaryheader={'spiritual'}
          nextPopUpValue={''}
          isRequired={false}
          assesseeCommunityArr={[
            { stateCode: '', name: '' },
            { stateCode: '01', name: 'Other Backward Class' },
            { stateCode: '02', name: 'Scheduled Class' },
            { stateCode: '03', name: 'Scheduled Tribe' },
            { stateCode: '04', name: 'Unlisted' }
          ]}
          mappingValue={'name'}
          basicInfo={assesseeInfo?.informationPersonal?.assesseeCommunitySpiritual || {}}
          typeOfSetObject={UPDATE_ASSESSEE_COMMUNITY_SPIRITUAL_INFO}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'BIRTHPLACEPOPUP' && (
        <PopUpBirthplace
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          inputHeader={'birthplace'}
          primaryheader={''}
          nextPopUpValue={''}
          isRequired={false}
          basicInfo={assesseeInfo?.informationPersonal}
          typeOfSetObject={UPDATE_ASSESSEE_PERSONAL_INFO}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'LEFTFINGERPRINTPOPUP' && (
        <PopUpFingerprint
          isActive={true}
          headerPanelColour="genericOne"
          headerOne="fingerprint"
          headerOneBadgeOne="left hand"
          headerOneBadgeTwo=""
          mode={'revise'}
        />
      )}
      {isPopUpValue === 'RIGHTFINGERPRINTPOPUP' && (
        <PopUpFingerprint
          isActive={true}
          headerPanelColour="genericOne"
          headerOne="fingerprint"
          headerOneBadgeOne="right hand"
          headerOneBadgeTwo=""
          mode={'revise'}
        />
      )}
      {isPopUpValue === 'ASSESSEECREATEFEEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'fee'}
          headerPanelColour={'genericOne'}
          inputHeader={'assessee'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'share'}
          type={'number'}
          headerOne={'assessee'}
          headerOneBadgeOne={'information'}
          isRequired={false}
          actualLableValue={'assesseeShareFee'}
          basicInfo={assesseeInfo.informationSetup}
          typeOfSetObject={UPDATE_ASSESSEE_SETUP_PRIMARY_INFO}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          currencySymbol={''}
        />
      )}
    </div>
  );
};

export default PopUpAssesseeCreateSignOn;
