import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ASSOCIATE_POPUP_CLOSE,
  FILTERMODE_ENABLE,
  GET_ASSESSEE_ASSIGNMENT_SAGA,
  LOADER_START,
  POPUP_OPEN,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_PAGE_COUNT,
  SET_POPUP_STATE
} from '../actionType';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import { FilterList } from '@material-ui/icons';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import ListLoader from '../Atoms/ListLoader/ListLoader';
import { onClickCheckBoxSelection } from '../Actions/AssesseeModuleAction';
import {
  ASSESSEE_ASSIGNMENT_REVIEW_LIST_POPUP_OPTION,
  ASSESSEE_ASSIGNMENT_TRIPLE_POPUP_OPTION,
  RES_START_POPUP_OPTION
} from '../PopUpConfig';
import { getAssesseeSelfAssignmentList } from '../Actions/GenericActions';
const AssesseeDistinctAssignmentDistinctReviewList = (props) => {
  const dispatch = useDispatch();
  // const { secondaryOptionCheckValue } = useSelector((state) => state.AssesseeCreateReducer);
  const {
    numberPage,
    scanCount,
    reviewListDistinctData,
    reviewListReqObj,
    middlePaneSelectedValue,
    isSelectActive,
    selectedTagsArray,
    unselectedTagsArray,
    selectedAssociateInfo,
    middlePaneHeaderBadgeOne,
    countPage,
    isListLoading
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { FilterModeEnable, FilterMode } = useSelector((state) => state.FilterReducer);
  // const [isFetching, setIsFetching] = useState(false);
  // useEffect(() => {
  //   document.getElementById('middleComponentId').addEventListener('scroll', handleScroll);
  // }, []);
  // const handleScroll = (event) => {
  //   var targetPt = event.target;
  //   if (
  //     Math.ceil(targetPt.scrollHeight - targetPt.scrollTop) !== targetPt.clientHeight ||
  //     isFetching
  //   )
  //     return;
  //   setIsFetching(true);
  //   console.log(isFetching);
  // };
  const fetchData = async () => {
    if (reviewListDistinctData.length < scanCount) {
      // dispatch({ type: LOADER_START })
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isListLoading', value: true }
      });
      let obj = {
        ...reviewListReqObj.requestObject,
        numberPage: numberPage
      };
      dispatch({
        type: GET_ASSESSEE_ASSIGNMENT_SAGA,
        payload: {
          request: { module: 'Assessee-Assignment', action: 'Review-List', requestObject: obj },
          BadgeOne: middlePaneHeaderBadgeOne,
          BadgeTwo: '',
          isMiddlePaneList: true
        }
      });
      dispatch({ type: SET_PAGE_COUNT, payload: numberPage + 1 });
    }
  };
  // useEffect(() => {
  //   if (!isFetching) return;
  //   fetchMoreListItems();
  // }, [isFetching]);

  // const fetchMoreListItems = () => {
  //   fetchData();
  //   setIsFetching(false);
  // };
  const siftApiCall = (siftKey) => {
    if (siftKey === 'timeout') siftKey = 'unfinished';
    getAssesseeSelfAssignmentList(
      selectedAssociateInfo,
      countPage,
      [siftKey.toUpperCase(), 'ADMINISTERED'],
      dispatch,
      siftKey,
      siftKey
    );
    dispatch({ type: ASSOCIATE_POPUP_CLOSE });
    document.getElementById('middleComponentId').scrollTop = '0px';
  };
  const onClickFooter = (e) => {
    let siftValue = e.currentTarget.getAttribute('data-value');
    if (
      siftValue === 'suspended' ||
      siftValue === 'terminated' ||
      siftValue === 'started' ||
      siftValue === 'unstarted' ||
      siftValue === 'aborted' ||
      siftValue === 'finished' ||
      siftValue === 'timeout'
    )
      siftApiCall(siftValue);
    dispatch({ type: FILTERMODE_ENABLE });
  };
  /* for middle pane */
  const primaryIcon = [{ label: 'sift', onClick: onClickFooter, Icon: FilterList }];
  const secondaryIconOne = [
    { label: 'started', onClick: onClickFooter, Icon: FilterList },
    { label: 'unstarted', onClick: onClickFooter, Icon: FilterList }
  ];
  const secondaryIconTwo = [
    { label: 'aborted', onClick: onClickFooter, Icon: FilterList },
    { label: 'finished', onClick: onClickFooter, Icon: FilterList },
    { label: 'suspended', onClick: onClickFooter, Icon: FilterList },
    { label: 'terminated', onClick: onClickFooter, Icon: FilterList },
    { label: 'timeout', onClick: onClickFooter, Icon: FilterList }
  ];
  const openListPopup = (e) => {
    let status = e.currentTarget.getAttribute('status');
    let popupArr = [];
    ASSESSEE_ASSIGNMENT_REVIEW_LIST_POPUP_OPTION.forEach((element) => {
      if (status !== 'FINISHED' && status !== 'UNFINISHED' && element.data === 'analytics')
        popupArr.push({ ...element, disabled: true });
      else popupArr.push(element);
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'relatedReviewListDistinctData', value: [] }
    });
    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: 'assignment',
        popupHeaderOneBadgeOne: '',
        popupHeaderOneBadgeTwo: '',
        isPopUpValue: '',
        popupOpenType: 'primary',
        popupContentArrValue: popupArr,
        originPrimaryArrOprion: popupArr,
        selectedTagValue: e.currentTarget.getAttribute('tag'),
        selectedTagStatus: status
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: ASSESSEE_ASSIGNMENT_TRIPLE_POPUP_OPTION
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
  };
  const startAssignment = (e) => {
    let status = e.currentTarget.getAttribute('actualstatus');
    console.log('selectedTagValue', e.currentTarget.getAttribute('assignmentid'));
    let tempArr = RES_START_POPUP_OPTION;
    if (status === 'UNSTARTED') {
      tempArr = [{ ...tempArr[0], disabled: true }, tempArr[1]];
    } else {
      tempArr = [tempArr[0], { ...tempArr[1], disabled: true }];
    }
    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: 'assignment',
        popupHeaderOneBadgeOne: '',
        popupHeaderOneBadgeTwo: '',
        isPopUpValue: '',
        popupOpenType: 'primary',
        popupContentArrValue: tempArr,
        selectedTagValue: e.currentTarget.getAttribute('assignmentid'),
        selectedTagStatus: e.currentTarget.getAttribute('status')
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });

    // assignmentStart(e.currentTarget.getAttribute('index'));
    // setAssessmentList(reviewListDistinctData[e.currentTarget.getAttribute('index')]);
  };

  // infinite scroll
  const [hasMore, setHasMore] = useState(true);
  let elementRef = useRef(null);

  const onIntersection = (entries) => {
    const firstEntry = entries[0];
    reviewListDistinctData.length >= scanCount ? setHasMore(false) : setHasMore(true);
    if (firstEntry.isIntersecting && hasMore && !isListLoading) {
      fetchData();
    }
  };

  useEffect(() => {
    // console.log('####MYREF######', elementRef)
    const observer = new IntersectionObserver(onIntersection, { threshold: 0.90 });

    if (observer && elementRef.current) {
      // console.log('here at observer...', elementRef.current)
      observer.observe(elementRef.current);
    }

    return () => {
      if (observer) observer.disconnect();
    };
  }, [
    isListLoading,
    hasMore,
    reviewListDistinctData,
    selectedTagsArray,
    unselectedTagsArray,
    isSelectActive
  ]);

  // infinite scroll end

  return (
    <div>
      {reviewListDistinctData &&
        reviewListDistinctData.map((item, index) => {
          return (
            <div className="containerPadding" key={index} ref={elementRef}>
              <ReviewList
                className=""
                id={index}
                tag={item.assesseeAssignmentId}
                associateId={item?.associateId}
                isSelectedReviewList={middlePaneSelectedValue === item.assesseeAssignmentId}
                status={
                  item.assesseeAssignmentStatus === 'UNFINISHED'
                    ? 'TIMEOUT'
                    : item.assesseeAssignmentStatus
                }
                actualStatus={item.assesseeAssignmentStatus}
                textOne={item.assesseeAssignmentName}
                textTwo={item.assesseeAssignmentDescription}
                isTooltipActive={false}
                onClickEvent={openListPopup}
                isSelectActive={isSelectActive}
                isSelected={selectedTagsArray.includes(item.assesseeAssignmentId)}
                isDelivery={true}
                onClickArrow={
                  item.assesseeAssignmentStatus === 'FINISHED' ||
                  item.assesseeAssignmentStatus === 'UNFINISHED' ||
                  item.assesseeAssignmentStatus === 'TERMINATED' ||
                  item.assesseeAssignmentStatus === 'SUSPENDED' ||
                  item.assesseeAssignmentStatus === 'ARCHIVED'
                    ? null
                    : startAssignment
                }
                onClickCheckBox={(event) => {
                  onClickCheckBoxSelection(selectedTagsArray, unselectedTagsArray, event, dispatch);
                }}
              />
            </div>
          );
        })}
      {isListLoading ? (
        <div>
          {' '}
          <ListLoader />{' '}
        </div>
      ) : <div style={{ height: "60px", width: "100%" }}></div>}
      {(FilterMode === 'assesseeAssignmentDistinctinactive' ||
        FilterMode === 'assesseeAssignmentDistinctactive') && (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={primaryIcon}
          secondaryIcon={
            FilterMode === 'assesseeAssignmentDistinctactive' ? secondaryIconOne : secondaryIconTwo
          }
        />
      )}
    </div>
  );
};
export default AssesseeDistinctAssignmentDistinctReviewList;
