import { put, takeLatest, call } from 'redux-saga/effects';
import {
  SET_DISPLAY_PANE_THREE_STATE,
  LOADER_STOP,
  GET_ASSESSEE_INFO_SAGA,
  SET_DISPLAY_TWO_SINGLE_STATE,
  UPDATE_ASSESSEE_BASIC_INFO,
  UPDATE_ASSESSEE_PERSONAL_INFO,
  UPDATE_ASSESSEE_ENGAGEMENT_INFO,
  UPDATE_ASSESSEE_CONTACT_INFO,
  SET_ASSESSEE_DYNAMIC_SINGLE_STATE,
  UPDATE_ASSESSEE_SETUP_PRIMARY_INFO,
  SET_ASSESSEE_CREATE_SINGLE_STATE,
  ASSESSEE_INFO_REVISE_SAGA,
  SET_DISPLAY_PANE_THREE_REVIEW_MODE,
  ASSESSEE_REVIEW_DISTINCT_SAGA,
  SET_POPUP_VALUE,
  GET_SIGNED_ASSESEE_NOTIFICATION,
  ASSESSEE_MULTI_STATUS_REVISE_SAGA,
  GET_ASSESSEE_ROLE_REVIEW_LIST_SAGA,
  GET_ASSESSEE_GROUP_REVIEW_LIST_SAGA,
  GET_ASSESSEE_TYPE_REVIEW_LIST_SAGA,
  GET_SIGNED_ASSESSEE_PERMISSION_SAGA,
  CLEAR_ASSESSEE_INFO,
  GET_ASSESSEEGROUP_ASSESSEE_REVIEW_LIST,
  ASSESSEE_ASSESSMENT_INFO_REVISE_STATUS_SAGA,
  GET_ASSESSEE_ASSIGNMENT_SAGA,
  RELATED_REVIEWLIST_DISTINCT_DATA,
  SET_PAGE_COUNT,
  GET_ASSESSEETYPE_ASSESSEE_REVIEW_LIST,
  GET_ASSESSEEROLE_ASSESSEE_REVIEW_LIST,
  SET_POPUP_SINGLE_STATE,
  CLEAR_ASSESSEE_ASSESSMENT_REDUCER_STATE,
  CLEAR_PANE_TWO_REDUCER,
  RESET_ALL_REDUCER,
  GET_ASSESSEENODE_ASSESSEE_REVIEW_LIST,
  SET_MOBILE_PANE_STATE,
  SET_RELATED_REQUEST_OBJECT,
  LOADER_START,
  GET_ASSIGNMENTDISTINCT_ASSESSEES_REVIEWLIST_SAGA,
  SET_CREATE_MODE,
  RESET_ASSESSEE_PASSWORD
} from '../../actionType';
import { ASSESSEE_MODULE_URL, ASSIGNMENT_MODULE_URL, SIGN_IN_URL } from '../../endpoints';
import { EXCEPTION_ERROR_MESSAGE } from '../../errorMessage';
import Store from '../../store';

const assesseesApiInfoApi = async (requestObj) => {
  console.log(requestObj.data);
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      Authorization: localStorage.getItem('token')
    }),
    body: JSON.stringify(requestObj.data)
  };
  const response = await fetch(requestObj.URL, requestOptions);
  const json = await response.json();
  return json;
};

function* workerReviewInfoAssesseeSaga(data) {
  try {
    let assesseeId = data.payload.reqBody.requestObject.assesseeId;
    let associateId = data.payload.reqBody.requestObject.associateId;
    const userResponse = yield call(assesseesApiInfoApi, {
      data: data.payload.reqBody,
      URL: ASSESSEE_MODULE_URL
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === '000') {
      // console.log('ASSESSEE_REVIEW_INFO=======>', userResponse);
      const { isReviseMode = false, createMode } = data.payload;
      if (data.payload.setLeftPaneAssessee) {
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'leftPaneAssesseeInfo',
            value: { assessee: userResponse?.responseObject[0] }
          }
        });
        yield put({
          type: GET_SIGNED_ASSESEE_NOTIFICATION,
          payload: {
            reqBody: {
              module: 'Assessee-Assignment',
              action: 'Count',
              requestObject: {
                assesseeId: assesseeId,
                associateId: associateId
              }
            }
          }
        });
        yield put({
          type: GET_SIGNED_ASSESSEE_PERMISSION_SAGA,
          payload: {
            reqBody: {
              module: 'Assessee-Role',
              action: 'Permission-List',
              requestObject: {
                assesseeId: assesseeId,
                associateId: associateId
              }
            }
          }
        });
      } else {
        const {
          informationAllocation,
          informationBasic,
          informationContact,
          informationEngagement,
          informationPersonal,
          informationSetup
        } = userResponse.responseObject[0];
        yield put({
          type: SET_DISPLAY_PANE_THREE_STATE,
          payload: {
            headerOne: data.payload.headerOne,
            headerOneBadgeOne:
              data.payload.popupBadgeOne === 'self' ? data.payload.popupBadgeOne : 'distinct',
            headerOneBadgeTwo: 'information',
            headerOneBadgeThree: data.payload.secondaryOptionCheckValue,
            responseObject: userResponse.responseObject[0],
            reviewMode: isReviseMode ? 'revise' : '',
            createMode: createMode || ''
          }
        });
        if (
          informationAllocation &&
          informationAllocation?.assesseeGroup?.assesseeGroupPrimary &&
          informationAllocation?.assesseeGroup?.assesseeGroupPrimary.length > 0
        ) {
          let tempArr = informationAllocation.assesseeGroup.assesseeGroupPrimary.map((ob) => ob.id);
          yield put({
            type: SET_ASSESSEE_DYNAMIC_SINGLE_STATE,
            payload: {
              stateName: 'assesseeGroup',
              actualStateName: 'assesseeGroupPrimary',
              value: tempArr
            }
          });
        } else {
          yield put({
            type: SET_ASSESSEE_DYNAMIC_SINGLE_STATE,
            payload: {
              stateName: 'assesseeGroup',
              actualStateName: 'assesseeGroupPrimary',
              value: []
            }
          });
        }

        if (
          informationAllocation &&
          informationAllocation?.assesseeRole?.assesseeRolePrimary &&
          informationAllocation?.assesseeRole?.assesseeRolePrimary.length > 0
        ) {
          let tempArr = informationAllocation.assesseeRole.assesseeRolePrimary.map((ob) => ob.id);
          yield put({
            type: SET_ASSESSEE_DYNAMIC_SINGLE_STATE,
            payload: {
              stateName: 'assesseeRole',
              actualStateName: 'assesseeRolePrimary',
              value: tempArr
            }
          });
        } else {
          yield put({
            type: SET_ASSESSEE_DYNAMIC_SINGLE_STATE,
            payload: {
              stateName: 'assesseeRole',
              actualStateName: 'assesseeRolePrimary',
              value: []
            }
          });
        }
        if (
          informationAllocation &&
          informationAllocation?.assesseeType?.assesseeTypePrimary &&
          informationAllocation?.assesseeType?.assesseeTypePrimary.length > 0
        ) {
          let tempArr = informationAllocation.assesseeType.assesseeTypePrimary.map((ob) => ob.id);
          yield put({
            type: SET_ASSESSEE_DYNAMIC_SINGLE_STATE,
            payload: {
              stateName: 'assesseeType',
              actualStateName: 'assesseeTypePrimary',
              value: tempArr
            }
          });
        } else {
          yield put({
            type: SET_ASSESSEE_DYNAMIC_SINGLE_STATE,
            payload: {
              stateName: 'assesseeType',
              actualStateName: 'assesseeTypePrimary',
              value: []
            }
          });
        }
        if (
          informationAllocation &&
          informationAllocation?.assesseeNode?.assesseeNodePrimary &&
          informationAllocation?.assesseeNode?.assesseeNodePrimary.length > 0
        ) {
          let tempArr = informationAllocation.assesseeNode.assesseeNodePrimary.map((ob) => ob.id);
          yield put({
            type: SET_ASSESSEE_DYNAMIC_SINGLE_STATE,
            payload: {
              stateName: 'assesseeNode',
              actualStateName: 'assesseeNodePrimary',
              value: tempArr
            }
          });
        } else {
          yield put({
            type: SET_ASSESSEE_DYNAMIC_SINGLE_STATE,
            payload: {
              stateName: 'assesseeNode',
              actualStateName: 'assesseeNodePrimary',
              value: []
            }
          });
        }

        if (
          informationAllocation &&
          informationAllocation?.assesseeGroup?.assesseeGroupSecondary &&
          informationAllocation?.assesseeGroup?.assesseeGroupSecondary.length > 0
        ) {
          let tempArr = informationAllocation.assesseeGroup.assesseeGroupSecondary.map(
            (ob) => ob.id
          );
          yield put({
            type: SET_ASSESSEE_DYNAMIC_SINGLE_STATE,
            payload: {
              stateName: 'assesseeGroup',
              actualStateName: 'assesseeGroupSecondary',
              value: tempArr
            }
          });
        } else {
          yield put({
            type: SET_ASSESSEE_DYNAMIC_SINGLE_STATE,
            payload: {
              stateName: 'assesseeGroup',
              actualStateName: 'assesseeGroupSecondary',
              value: []
            }
          });
        }
        if (
          informationAllocation &&
          informationAllocation?.assesseeNode?.assesseeNodeSecondary &&
          informationAllocation?.assesseeNode?.assesseeNodeSecondary.length > 0
        ) {
          let tempArr = informationAllocation.assesseeNode.assesseeNodeSecondary.map((ob) => ob.id);
          yield put({
            type: SET_ASSESSEE_DYNAMIC_SINGLE_STATE,
            payload: {
              stateName: 'assesseeNode',
              actualStateName: 'assesseeNodeSecondary',
              value: tempArr
            }
          });
        } else {
          yield put({
            type: SET_ASSESSEE_DYNAMIC_SINGLE_STATE,
            payload: {
              stateName: 'assesseeNode',
              actualStateName: 'assesseeNodeSecondary',
              value: []
            }
          });
        }

        if (
          informationAllocation &&
          informationAllocation?.assesseeRole?.assesseeRoleSecondary &&
          informationAllocation?.assesseeRole?.assesseeRoleSecondary.length > 0
        ) {
          let tempArr = informationAllocation.assesseeRole.assesseeRoleSecondary.map((ob) => ob.id);
          yield put({
            type: SET_ASSESSEE_DYNAMIC_SINGLE_STATE,
            payload: {
              stateName: 'assesseeRole',
              actualStateName: 'assesseeRoleSecondary',
              value: tempArr
            }
          });
        } else {
          yield put({
            type: SET_ASSESSEE_DYNAMIC_SINGLE_STATE,
            payload: {
              stateName: 'assesseeRole',
              actualStateName: 'assesseeRoleSecondary',
              value: []
            }
          });
        }
        if (
          informationAllocation &&
          informationAllocation?.assesseeType?.assesseeTypeSecondary &&
          informationAllocation?.assesseeType?.assesseeTypeSecondary.length > 0
        ) {
          let tempArr = informationAllocation.assesseeType.assesseeTypeSecondary.map((ob) => ob.id);
          yield put({
            type: SET_ASSESSEE_DYNAMIC_SINGLE_STATE,
            payload: {
              stateName: 'assesseeType',
              actualStateName: 'assesseeTypeSecondary',
              value: tempArr
            }
          });
        } else {
          yield put({
            type: SET_ASSESSEE_DYNAMIC_SINGLE_STATE,
            payload: {
              stateName: 'assesseeType',
              actualStateName: 'assesseeTypeSecondary',
              value: []
            }
          });
        }
        // yield put({
        //   type: SET_ASSESSEE_DYNAMIC_SINGLE_STATE,
        //   payload: {
        //     stateName: 'assesseeGroup',
        //     actualStateName: 'assesseeGroupPrimary',
        //     value: roleArr
        //   }
        // });
        if (informationContact?.assesseeAddressEmailPrimary?.assesseeAddressEmailCommunication) {
          yield put({
            type: SET_ASSESSEE_CREATE_SINGLE_STATE,
            payload: { stateName: 'tempCommunication', value: 'email address (primary)' }
          });
        }
        if (informationContact?.assesseeAddressEmailSecondary?.assesseeAddressEmailCommunication) {
          yield put({
            type: SET_ASSESSEE_CREATE_SINGLE_STATE,
            payload: { stateName: 'tempCommunication', value: 'email address (secondary)' }
          });
        }
        if (informationContact?.assesseeAddressHomePrimary?.assesseeAddressCommunication) {
          yield put({
            type: SET_ASSESSEE_CREATE_SINGLE_STATE,
            payload: { stateName: 'tempAddressCommunication', value: 'home address primary' }
          });
        }
        if (informationContact?.assesseeAddressHomeSecondary?.assesseeAddressCommunication) {
          yield put({
            type: SET_ASSESSEE_CREATE_SINGLE_STATE,
            payload: { stateName: 'tempAddressCommunication', value: 'home address secondary' }
          });
        }
        if (informationContact?.assesseeAddressWorkPrimary?.assesseeAddressCommunication) {
          yield put({
            type: SET_ASSESSEE_CREATE_SINGLE_STATE,
            payload: { stateName: 'tempAddressCommunication', value: 'work address primary' }
          });
        }
        if (informationContact?.assesseeAddressWorkSecondary?.assesseeAddressCommunication) {
          yield put({
            type: SET_ASSESSEE_CREATE_SINGLE_STATE,
            payload: { stateName: 'tempAddressCommunication', value: 'work address secondary' }
          });
        }
        if (informationContact?.assesseeTelephoneHomePrimary?.assesseeTelephoneCommunication) {
          yield put({
            type: SET_ASSESSEE_CREATE_SINGLE_STATE,
            payload: { stateName: 'tempTelephoneCommunication', value: 'home telephone primary' }
          });
        }
        if (informationContact?.assesseeTelephoneHomeSecondary?.assesseeTelephoneCommunication) {
          yield put({
            type: SET_ASSESSEE_CREATE_SINGLE_STATE,
            payload: { stateName: 'tempTelephoneCommunication', value: 'home telephone secondary' }
          });
        }
        //
        if (informationContact?.assesseeTelephoneWorkPrimary?.assesseeTelephoneCommunication) {
          yield put({
            type: SET_ASSESSEE_CREATE_SINGLE_STATE,
            payload: { stateName: 'tempTelephoneCommunication', value: 'work telephone primary' }
          });
        }
        if (informationContact?.assesseeTelephoneWorkSecondary?.assesseeTelephoneCommunication) {
          yield put({
            type: SET_ASSESSEE_CREATE_SINGLE_STATE,
            payload: { stateName: 'tempTelephoneCommunication', value: 'work telephone secondary' }
          });
        }
        //
        if (informationContact?.assesseeTelephoneMobilePrimary?.assesseeTelephoneCommunication) {
          yield put({
            type: SET_ASSESSEE_CREATE_SINGLE_STATE,
            payload: { stateName: 'tempTelephoneCommunication', value: 'mobile telephone primary' }
          });
        }
        if (informationContact?.assesseeTelephoneMobileSecondary?.assesseeTelephoneCommunication) {
          yield put({
            type: SET_ASSESSEE_CREATE_SINGLE_STATE,
            payload: {
              stateName: 'tempTelephoneCommunication',
              value: 'mobile telephone secondary'
            }
          });
        }
        yield put({ type: UPDATE_ASSESSEE_BASIC_INFO, payload: informationBasic });
        yield put({ type: UPDATE_ASSESSEE_PERSONAL_INFO, payload: informationPersonal });
        yield put({ type: UPDATE_ASSESSEE_ENGAGEMENT_INFO, payload: informationEngagement });
        yield put({ type: UPDATE_ASSESSEE_CONTACT_INFO, payload: informationContact });
        yield put({ type: UPDATE_ASSESSEE_SETUP_PRIMARY_INFO, payload: informationSetup });
        console.log('loading end');
        yield put({ type: LOADER_STOP });
      }
    } else {
      if (!data.payload.setLeftPaneAssessee) yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}

function* workerReviseInfoAssesseeSaga(data) {
  try {
    console.log('reviewLsit', data);
    const userResponse = yield call(assesseesApiInfoApi, {
      data: data.payload.reqBody,
      URL: ASSESSEE_MODULE_URL
    });
    const { listType = Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo } =
      data.payload;
    console.log('payload>>>', data.payload);
    if (userResponse.responseCode === '000') {
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isSelectActive', value: '' }
      });
      yield put({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'selectionValue', value: '' }
      });
      if (data.payload.isPageRefresh) {
        localStorage.clear();
        yield put({ type: RESET_ALL_REDUCER });
        yield put({ type: CLEAR_PANE_TWO_REDUCER });
        yield put({ type: CLEAR_ASSESSEE_ASSESSMENT_REDUCER_STATE });
        window.open(SIGN_IN_URL, '_self');
        window.location.reload();
      } else if (data.payload.secondaryOptionCheckValue !== '') {
        yield put({
          type: SET_DISPLAY_PANE_THREE_STATE,
          payload: {
            headerOne: data.payload.headerOne,
            headerOneBadgeOne: data.payload.headerOneBadgeOne || 'distinct',
            headerOneBadgeThree: data.payload.headerOneBadgeThree,
            headerOneBadgeTwo: 'information',
            responseObject: userResponse.responseObject[0],
            createMode: data.payload.createMode
          }
        });
        yield put({
          type: SET_DISPLAY_PANE_THREE_REVIEW_MODE,
          payload: 'review'
        });
      }
      if (Store.getState().PopUpReducer.cardValue === 'Card') {
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'leftPaneAssesseeInfo',
            value: { assessee: userResponse?.responseObject[0] }
          }
        });
        yield put({ type: LOADER_STOP });
      } else if (data.payload.createMode === '') {
        // refreshMiddlePaneList(ASSESSEE_REVIEW_DISTINCT_SAGA, 'assessees');

        yield put({ type: SET_PAGE_COUNT, payload: 0 });
        if (data.payload.typeOfMiddlePaneList === 'assesseesTypeAssesseeReviewList') {
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'relatedReviewListDistinctData', value: [] }
          });
          yield put({
            type: GET_ASSESSEETYPE_ASSESSEE_REVIEW_LIST,
            payload: {
              HeaderOne: data.payload.headerOne || 'assessees',
              request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        } else if (data.payload.typeOfMiddlePaneList === 'assesseesGroupAssesseeReviewList') {
          //third pane revise finish
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'relatedReviewListDistinctData', value: [] }
          });
          yield put({
            type: GET_ASSESSEEGROUP_ASSESSEE_REVIEW_LIST,
            payload: {
              HeaderOne: data.payload.headerOne || 'assessees',
              request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        } else if (data.payload.typeOfMiddlePaneList === 'assesseesNodeAssesseeReviewList') {
          //third pane revise finish
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'relatedReviewListDistinctData', value: [] }
          });
          yield put({
            type: GET_ASSESSEENODE_ASSESSEE_REVIEW_LIST,
            payload: {
              HeaderOne: data.payload.headerOne || 'assessees',
              request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        } else if (data.payload.typeOfMiddlePaneList === 'assesseesRoleAssesseeReviewList') {
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'relatedReviewListDistinctData', value: [] }
          });
          yield put({
            type: GET_ASSESSEEROLE_ASSESSEE_REVIEW_LIST,
            payload: {
              HeaderOne: data.payload.headerOne || 'assessees',
              request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        } else if (data.payload.typeOfMiddlePaneList === 'administratorsDistinctReviewList') {
          yield put({
            type: ASSESSEE_REVIEW_DISTINCT_SAGA,
            payload: {
              HeaderOne: 'administrators',
              request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
        } else if (data.payload.typeOfMiddlePaneList === 'managersDistinctReviewList') {
          yield put({
            type: ASSESSEE_REVIEW_DISTINCT_SAGA,
            payload: {
              HeaderOne: 'managers',
              request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
        } else if (data.payload.typeOfMiddlePaneList === 'assistantsDistinctReviewList') {
          yield put({
            type: ASSESSEE_REVIEW_DISTINCT_SAGA,
            payload: {
              HeaderOne: 'assistants',
              request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
        } else if (data.payload.typeOfMiddlePaneList === 'assignmentDistinctAssesseeReviewList') {
          let assesseeStatus = listType;
          let secondaryOptionCheckValue = listType;
          let assesseeStatusArr = [
            'CONFIRMED',
            'DISAPPROVED',
            'SUSPENDED',
            'TERMINATED',
            'UNAPPROVED',
            'UNCONFIRMED',
            'ARCHIVED'
          ];
          let statusArr = ['ABORTED', 'FINISHED', 'STARTED', 'UNFINISHED', 'UNSTARTED', 'ARCHIVED'];
          let allStatusArr = [
            'ABORTED',
            'FINISHED',
            'STARTED',
            'UNFINISHED',
            'UNSTARTED',
            'ARCHIVED'
          ];
          if (allStatusArr.includes(secondaryOptionCheckValue?.toUpperCase())) {
            statusArr = [secondaryOptionCheckValue?.toUpperCase()];
          }

          if (assesseeStatus === 'archived') assesseeStatusArr = ['ARCHIVED'];
          if (assesseeStatus === 'active') assesseeStatusArr = ['CONFIRMED'];
          if (assesseeStatus === 'inactive')
            assesseeStatusArr = [
              'DISAPPROVED',
              'SUSPENDED',
              'TERMINATED',
              'UNAPPROVED',
              'UNCONFIRMED'
            ];

          let reqBody = {
            module: 'Assignment-Distinct',
            action: 'Review-List',
            actionFilter: 'Assignment-Assessee',
            requestObject: {
              assesseeId: data.payload.reqBody.requestObject.assesseeId,
              associateId: data.payload.reqBody.requestObject.associateId,
              assignmentId: data.payload.cachedPreviousSelectedTagValue,
              assesseeAssignmentStatus: statusArr,
              assesseeStatus: assesseeStatusArr,
              countPage: data.payload.reqBody.requestObject.countPage,
              numberPage: 0,
              orderBy: {
                columnName: 'informationBasic.assesseeNameFirst,informationBasic.assesseeNameLast',
                order: 'asc'
              },
              filter: 'true',
              searchCondition: 'AND',
              search: [
                {
                  condition: 'and',
                  searchBy: [
                    {
                      dataType: 'string',
                      conditionColumn: 'informationEngagement.assesseeStatus',
                      conditionValue: {
                        condition: 'in',
                        value: {
                          in: assesseeStatusArr
                        }
                      }
                    }
                  ]
                }
              ]
            }
          };

          yield put({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
          yield put({
            type: SET_RELATED_REQUEST_OBJECT,
            payload: reqBody
          });
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: {
              stateName: 'relatedReviewListObjectCopy',
              value: reqBody
            }
          });
          yield put({ type: LOADER_START });
          yield put({
            type: GET_ASSIGNMENTDISTINCT_ASSESSEES_REVIEWLIST_SAGA,
            payload: {
              request: reqBody,
              HeaderOne: 'assessees',
              BadgeOne: 'distinct',
              BadgeTwo: listType,
              BadgeThree: '',
              isMiddlePaneList: true
            }
          });
        } else {
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          }); // reviewListDistinctData should not empty for sub-modules ;
          yield put({
            type: ASSESSEE_REVIEW_DISTINCT_SAGA,
            payload: {
              HeaderOne: 'assessees',
              request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
            }
          });
        }
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
      } else {
        yield put({ type: LOADER_STOP });
        yield put({ type: CLEAR_ASSESSEE_INFO });
      }
    } else {
      console.log('loading end');
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}

function* workerReviseInfoAssesseeAssessmentSaga(data) {
  try {
    console.log('reviewLsit', data);
    const userResponse = yield call(assesseesApiInfoApi, {
      data: data.payload.reqBody,
      URL: ASSIGNMENT_MODULE_URL
    });
    if (userResponse.responseCode === '000') {
      // if (data.payload.secondaryOptionCheckValue !== '') {
      //   yield put({
      //     type: SET_DISPLAY_PANE_THREE_STATE,
      //     payload: {
      //       headerOne: data.payload.headerOne,
      // headerOneBadgeOne: 'distinct',
      // headerOneBadgeTwo: 'information',
      // headerOneBadgeThree: data.payload.secondaryOptionCheckValue,
      //       responseObject: userResponse.responseObject[0],
      //       createMode: data.payload.createMode
      //     }
      //   });
      //   yield put({
      //     type: SET_DISPLAY_PANE_THREE_REVIEW_MODE,
      //     payload: 'review'
      //   });
      // }
      // if (Store.getState().PopUpReducer.cardValue === 'Card') {
      //   yield put({
      //     type: SET_DISPLAY_TWO_SINGLE_STATE,
      //     payload: {
      //       stateName: 'leftPaneAssesseeInfo',
      //       value: { assessee: userResponse?.responseObject[0] }
      //     }
      //   });
      //   yield put({ type: LOADER_STOP });
      // } else if (data.payload.createMode === '') {
      //   // refreshMiddlePaneList(ASSESSEE_REVIEW_DISTINCT_SAGA, 'assessees');
      //   yield put({
      //     type: SET_DISPLAY_TWO_SINGLE_STATE,
      //     payload: { stateName: 'reviewListDistinctData', value: [] }
      //   });
      //   yield put({
      //     type: ASSESSEE_REVIEW_DISTINCT_SAGA,
      //     payload: {
      //       HeaderOne: 'assessees',
      //       request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
      //       BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
      //       BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
      //       middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
      //     }
      //   });
      // }
      // else
      if (userResponse.responseCode === '000') {
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
        yield put({
          type: GET_ASSESSEE_ASSIGNMENT_SAGA,
          payload: {
            request: data.payload.refreshListReq.request,
            BadgeOne: 'distinct',
            BadgeTwo: data.payload.middlePaneHeaderBadgeTwo, //'active',
            BadgeThree: '',
            relatedListCall: data.payload.refreshListReq.relatedListCall,
            typeOfMiddlePaneList: data.payload.typeOfMiddlePaneList,
            responseObj: data.payload.refreshListReq.responseObj
          }
        });
      } else {
        yield put({ type: LOADER_STOP });
        yield put({ type: CLEAR_ASSESSEE_INFO });
      }
    } else {
      console.log('loading end');
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}

function* workerReviseMultiStatusAssesseeSaga(data) {
  try {
    const userResponse = yield call(assesseesApiInfoApi, {
      data: data.payload.reqBody,
      URL: ASSESSEE_MODULE_URL
    });
    if (userResponse.responseCode === '000') {
      yield put({ type: SET_PAGE_COUNT, payload: 0 });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'relatedReviewListDistinctData', value: [] }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'typeOfMiddlePaneList', value: '' }
      });
      yield put({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'selectionValue', value: '' }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isSelectActive', value: '' }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'selectedTagsArray', value: [] }
      });
      if (data.payload.createMode === '') {
        if (data.payload.typeOfMiddlePaneList === 'assesseesGroupDistinctReviewList') {
          yield put({
            type: GET_ASSESSEE_GROUP_REVIEW_LIST_SAGA,
            payload: {
              HeaderOne:
                Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate || 'assessees',
              request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
        } else if (data.payload.typeOfMiddlePaneList === 'assesseeRoleDistinctReviewList') {
          yield put({
            type: GET_ASSESSEE_ROLE_REVIEW_LIST_SAGA,
            payload: {
              HeaderOne:
                Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate || 'assessees',
              request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
              BadgeFour: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeFour,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
        } else if (data.payload.typeOfMiddlePaneList === 'assesseesTypeDistinctReviewList') {
          yield put({
            type: GET_ASSESSEE_TYPE_REVIEW_LIST_SAGA,
            payload: {
              HeaderOne:
                Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate || 'assessees',
              request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
        } else if (data.payload.typeOfMiddlePaneList === 'assesseesTypeAssesseeReviewList') {
          yield put({
            type: GET_ASSESSEETYPE_ASSESSEE_REVIEW_LIST,
            payload: {
              HeaderOne:
                Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate || 'assessees',
              request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
          // yield put({
          //   type: SET_DISPLAY_TWO_SINGLE_STATE,
          //   payload: { stateName: 'reviewListDistinctData', value: [] }
          // });
        } else if (data.payload.typeOfMiddlePaneList === 'assesseesGroupAssesseeReviewList') {
          yield put({
            type: GET_ASSESSEEGROUP_ASSESSEE_REVIEW_LIST,
            payload: {
              HeaderOne:
                Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate || 'assessees',
              request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        } else if (data.payload.typeOfMiddlePaneList === 'assesseesRoleAssesseeReviewList') {
          yield put({
            type: GET_ASSESSEEROLE_ASSESSEE_REVIEW_LIST,
            payload: {
              HeaderOne:
                Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate || 'assessees',
              request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        } else if (data.payload.typeOfMiddlePaneList === 'administratorsDistinctReviewList') {
          yield put({
            type: ASSESSEE_REVIEW_DISTINCT_SAGA,
            payload: {
              HeaderOne: 'administrators',
              request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
        } else if (data.payload.typeOfMiddlePaneList === 'managersDistinctReviewList') {
          yield put({
            type: ASSESSEE_REVIEW_DISTINCT_SAGA,
            payload: {
              HeaderOne: 'managers',
              request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
        } else if (data.payload.typeOfMiddlePaneList === 'assistantsDistinctReviewList') {
          yield put({
            type: ASSESSEE_REVIEW_DISTINCT_SAGA,
            payload: {
              HeaderOne: 'managers',
              request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
        } else if (data.payload.typeOfMiddlePaneList === 'assignmentDistinctAssesseeReviewList') {
          yield put({
            type: GET_ASSIGNMENTDISTINCT_ASSESSEES_REVIEWLIST_SAGA,
            payload: {
              HeaderOne:
                Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate || 'assessees',
              request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        } else {
          yield put({
            type: ASSESSEE_REVIEW_DISTINCT_SAGA,
            payload: {
              HeaderOne:
                Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate || 'assessees',
              request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
            }
          });
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
        }
      } else {
        yield put({ type: LOADER_STOP });
      }
    } else {
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}

function* workerReviseAssesseePasswordSaga(data) {
  try {
    const userResponse = yield call(assesseesApiInfoApi, {
      data: data.payload,
      URL: ASSESSEE_MODULE_URL
    });
    if (userResponse.responseCode === '000') {
      yield put({ type: LOADER_STOP });
    } else {
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });

  }
}

export default function* watchReviewInfoAssesseeSaga() {
  yield takeLatest(GET_ASSESSEE_INFO_SAGA, workerReviewInfoAssesseeSaga);
  yield takeLatest(ASSESSEE_INFO_REVISE_SAGA, workerReviseInfoAssesseeSaga);

  yield takeLatest(
    ASSESSEE_ASSESSMENT_INFO_REVISE_STATUS_SAGA,
    workerReviseInfoAssesseeAssessmentSaga
  );
  yield takeLatest(ASSESSEE_MULTI_STATUS_REVISE_SAGA, workerReviseMultiStatusAssesseeSaga);
  yield takeLatest(RESET_ASSESSEE_PASSWORD, workerReviseAssesseePasswordSaga);
}
