import React, { Fragment, useEffect, useState } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import Popup from '../Molecules/PopUp/PopUp';
import CircleIcon from '../Molecules/IconButton/IconButton';
import PopupHeader from '../Molecules/PopUp/PopUpHeader';
import '../Molecules/PopUp/PopUp.css';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { Button, Divider, IconButton, InputLabel } from '@material-ui/core';
import { Check, Dashboard, Description } from '@material-ui/icons';
import JsonRenderComponent from '../Actions/JsonRenderComponent';
import {
  CLEAR_ASSESSEE_ASSESSMENT_REDUCER_STATE,
  CLEAR_PANE_TWO_REDUCER,
  LOADER_START,
  POPUP_CLOSE,
  POPUP_OPEN,
  RESET_ALL_REDUCER,
  SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_POPUP_SINGLE_STATE,
  SET_POPUP_STATE,
  SET_SECONDARY_CREATE_OPTION_VALUE
} from '../actionType';
import { useDispatch, useSelector } from 'react-redux';
import { ASSESSMENT_CLOSED_POPUP_OPTION } from '../PopUpConfig';
import ArrowRight from '@material-ui/icons/ChevronRight';
import ArrowLeft from '@material-ui/icons/ChevronLeft';
import { SIGN_IN_URL } from '../endpoints';
import { useHistory } from 'react-router-dom';
import { assesseeAssessmentFinihed } from '../Actions/ActionAssesseeAssessment';
import '../Molecules/PopUp/PopUp.css';
const PopUpAssessmentNavigator = (props) => {
  const {
    isActive,
    headerPanelColour = 'displayPaneRight',
    headerOne = 'assessment',
    headerOneBadgeOne = 'navigator',
    itemData,
    sectionName,
    sectionDescription,
    //onChangePrevNext,
    setcurrentQuestionIndex,
    onClickButton,
    currentQuestionIndex,
    navigatorIconObject
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { popupHeaderOneBadgeOne, secondaryOptionCheckValue, isPopUpValue, selectedTagValue } =
    useSelector((state) => state.PopUpReducer);
  const { selectedAssociateInfo, relatedReviewListDistinctData } = useSelector(
    (state) => state.DisplayPaneTwoReducer
  );
  const {
    assesseeAssignmentAssessmentData,
    assesseeAssignmentAssessmentSectionAttemptedDateTimeStart
  } = useSelector((state) => state.AssesseeAssignmentAssessmentReducer);
  console.log('relatedReviewListDistinctData', relatedReviewListDistinctData);
  const [itemNavigatorData, setNavigatorData] = useState(itemData);
  const [flagedCheck, setFlagCheck] = useState(false);
  const handleClick = () => {
    console.log('cancelled call');
    setNavigatorData(itemData);
    /*according to creation mode popup sequence will change*/
  };
  // useEffect(() => {
  //   setNavigatorData(itemData);
  // }, [itemData, isQuestionFlaged]);
  let itemArray = itemData;
  const handleOnClickFilter = (value) => {
    if (value === 'all') {
      //itemArray = itemData;
      //setNavigatorData([...itemData]);
      setFlagCheck(false);
      dispatch({
        type: SET_SECONDARY_CREATE_OPTION_VALUE,
        payload: value
      });
    } else if (value === 'flaged') {
      setFlagCheck(true);
      dispatch({
        type: SET_SECONDARY_CREATE_OPTION_VALUE,
        payload: value
      });
    } else if (value === 'finish' || value === 'terminate') {
      dispatch({
        type: SET_POPUP_STATE,
        payload: {
          popupHeaderOne: 'assessment',
          popupHeaderOneBadgeOne: value === 'finish' ? 'finish' : 'terminate',
          popupHeaderOneBadgeTwo: '',
          isPopUpValue: '',
          popupOpenType: 'primary',
          secondaryOptionCheckValue: 'assignment',
          popupContentArrValue: ASSESSMENT_CLOSED_POPUP_OPTION
        }
      });
      dispatch({ type: POPUP_OPEN, payload: 'POPUP_TERMINATE_SUSPEND' });
    } else if (value === 'dashboard') {
      itemData.forEach((item, index) => {
        if (currentQuestionIndex === index) {
          if (
            item?.itemFrameworkOneType === '61090cace50cf61d5eb440c9' ||
            item?.itemFrameworkOneType === '61161713f24e1fb765208e23'
          ) {
            item.itemFrameworkOneSection.forEach((element) => {
              element.itemFrameworkOne.itemFrameworkOneResponseCorrect = '';
            });
          }
        }
      });
      dispatch({
        type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
        payload: {
          stateName: 'questionIndex',
          value: currentQuestionIndex
        }
      });
      // console.log('itemData', itemData);
      dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneOne' });
      dispatch({ type: POPUP_CLOSE });
      // dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneOne' });
      // dispatch({ type: CLEAR_ASSESSEE_ASSESSMENT_REDUCER_STATE });
      // dispatch({ type: CLEAR_PANE_TWO_REDUCER });
      // localStorage.setItem('assessmentItem', '[]');
      // localStorage.setItem('navigationItem', '[]');
    } else {
    }
  };
  const ChangeOptionPopup = (e) => {
    let keyVal = e.currentTarget.getAttribute('data-key');
    let dataVal = e.currentTarget.getAttribute('data-value');
    console.log(dataVal);
    if (dataVal === 'yes' && secondaryOptionCheckValue === 'assignment') {
      dispatch({ type: LOADER_START });
      let assessmentStatus = popupHeaderOneBadgeOne === 'terminate' ? 'TERMINATED' : 'FINISHED';
      assesseeAssessmentFinihed(
        dispatch,
        selectedAssociateInfo,
        assesseeAssignmentAssessmentData,
        assessmentStatus,
        assesseeAssignmentAssessmentSectionAttemptedDateTimeStart
      );
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'relatedReviewListDistinctData', value: [] }
      });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'reviewListDistinctData', value: [] }
      });
    }
    if (dataVal === 'yes' && secondaryOptionCheckValue === 'sign-out') {
      let assessmentStatus = popupHeaderOneBadgeOne === 'terminate' ? 'TERMINATED' : 'FINISHED';
      assesseeAssessmentFinihed(
        dispatch,
        selectedAssociateInfo,
        assesseeAssignmentAssessmentData,
        assessmentStatus,
        assesseeAssignmentAssessmentSectionAttemptedDateTimeStart
      );
      history.push(SIGN_IN_URL);
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('parentId');
      localStorage.removeItem('assesseeId');
      localStorage.clear();
      dispatch({ type: RESET_ALL_REDUCER });
      dispatch({ type: CLEAR_PANE_TWO_REDUCER });
      dispatch({ type: CLEAR_ASSESSEE_ASSESSMENT_REDUCER_STATE });
    }
    if (dataVal === 'yes' && secondaryOptionCheckValue === 'dashboard') {
      let assessmentStatus = popupHeaderOneBadgeOne === 'terminate' ? 'TERMINATED' : 'FINISHED';
      assesseeAssessmentFinihed(
        dispatch,
        selectedAssociateInfo,
        assesseeAssignmentAssessmentData,
        assessmentStatus,
        assesseeAssignmentAssessmentSectionAttemptedDateTimeStart
      );
      dispatch({
        type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
        payload: { stateName: 'isExamMode', value: false }
      });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'typeOfMiddlePaneList', value: '' }
      });
    }
    dispatch({ type: POPUP_CLOSE });
  };
  const BackHandlerEvent = (e) => {
    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        secondaryOptionCheckValue: 'all'
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'NavigatorPOPUP' });
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: { stateName: 'headerButtons', value: false }
    });
  };
  const setSecondaryOptionValue = (e) => {
    //TODO: set secondary option in item
    var dataVal = e.currentTarget.getAttribute('data-value');
    console.log(dataVal, 'dataVal');
    dispatch({
      type: SET_SECONDARY_CREATE_OPTION_VALUE,
      payload: dataVal
    });
  };

  const onChangePrevNext = (e) => {
    let clickedValue = e.currentTarget.getAttribute('datavalue');
    if (clickedValue === 'prev') {
      let prevIndex = selectedTagValue - 1;
      if (selectedTagValue !== 0 && selectedTagValue) {
        dispatch({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: {
            stateName: 'assesseeAssessmentStartData',
            value:
              assesseeAssignmentAssessmentData?.informationFramework?.assessmentSectionFramework[
                prevIndex
              ]
          }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'middlePaneSelectedValue', value: prevIndex }
        });
        dispatch({
          type: SET_POPUP_STATE,
          payload: {
            secondaryOptionCheckValue: 'all',
            selectedTagValue: prevIndex
          }
        });
      }
    }
    if (clickedValue === 'next') {
      if (
        selectedTagValue <
        assesseeAssignmentAssessmentData?.informationFramework?.assessmentSectionFramework.length -
          1
      ) {
        if (
          !assesseeAssignmentAssessmentData?.informationFramework?.assessmentSectionFramework[
            selectedTagValue
          ].assessmentSectionEvaluation
        ) {
          let nextIndex = selectedTagValue + 1;
          dispatch({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: {
              stateName: 'assesseeAssessmentStartData',
              value:
                assesseeAssignmentAssessmentData?.informationFramework?.assessmentSectionFramework[
                  nextIndex
                ]
            }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'middlePaneSelectedValue', value: nextIndex }
          });
          dispatch({
            type: SET_POPUP_STATE,
            payload: {
              secondaryOptionCheckValue: 'all',
              selectedTagValue: nextIndex
            }
          });
        }
      }
    }
  };
  console.log('navigatorIconObject', navigatorIconObject);
  console.log('itemArray', itemArray.length);
  return (
    <div>
      <Popup isActive={isActive}>
        <PopupHeader
          headerPanelColour={headerPanelColour}
          headerOne={headerOne}
          headerOneBadgeOne={headerOneBadgeOne}
          onClick={handleClick}
          mode="error"
        />
        <DialogContent
          className={['popupContent', 'fixed10PadDim', 'revisePopupContent'].join(' ')}
        >
          <div style={{ display: 'flex' }}>
            <div style={{ flex: '1' }}>
              <IconButton
                // onClick={onChangePrevNext}
                className="MuiIconButton-root-1602"
                datavalue="prev"
              >
                <ArrowLeft />
              </IconButton>
            </div>
            {/* <div style={{ flex: '4' }}>{sectionName}
            <span>
              {sectionDescription}
            </span></div> */}
            <div style={{ flex: '4' }}>
              <div className={[sectionName !== '' ? null : 'aliasmiddle'].join(' ')}>
                {sectionName}
              </div>
              <div className={['midPaneLabel', 'textOverflow'].join(' ')}>{sectionDescription}</div>
            </div>
            <div style={{ flex: '1' }}>
              <IconButton
                // onClick={onChangePrevNext}
                onClick={null}
                className="MuiIconButton-root-1602"
                datavalue="next"
              >
                <ArrowRight />
              </IconButton>
            </div>
          </div>
          <div
            style={{
              height: '210px',
              margin: '5px 0px',
              padding: '0 5px',
              overflow: 'overlay'
            }}
          >
            <div
              style={{
                display: 'grid',
                padding: '0px 5px',
                flexWrap: 'wrap',
                counterReset: 'l',
                justifyContent: 'flex-start',
                gridTemplateColumns: 'repeat(auto-fill, minmax(50px, 1fr))'
              }}
            >
              {itemArray &&
                itemArray.map((question, index) => {
                  let btnDisabled = false;
                  if (
                    navigatorIconObject?.assessmentSectionItemNavigationSkip &&
                    !navigatorIconObject?.assessmentSectionItemNavigationFirst &&
                    !navigatorIconObject?.assessmentSectionItemNavigationPrevious &&
                    index < currentQuestionIndex
                  ) {
                    btnDisabled = true;
                  } else if (
                    !navigatorIconObject?.assessmentSectionItemNavigationSkip &&
                    navigatorIconObject?.assessmentSectionItemNavigationFirst &&
                    navigatorIconObject?.assessmentSectionItemNavigationPrevious &&
                    question?.assesseeAssignmentAssessmentItemResponseChoiceSelected &&
                    index > currentQuestionIndex
                  ) {
                    btnDisabled = true;
                  } else if (
                    !navigatorIconObject?.assessmentSectionItemNavigationSkip &&
                    !navigatorIconObject?.assessmentSectionItemNavigationFirst &&
                    !navigatorIconObject?.assessmentSectionItemNavigationPrevious &&
                    index < currentQuestionIndex
                  ) {
                    btnDisabled = true;
                  } else if (
                    !!navigatorIconObject?.assessmentSectionItemNavigationSkip &&
                    !navigatorIconObject?.assessmentSectionItemNavigationFirst &&
                    !navigatorIconObject?.assessmentSectionItemNavigationPrevious &&
                    index < currentQuestionIndex
                  ) {
                    btnDisabled = true;
                  } else if (
                    !navigatorIconObject?.assessmentSectionItemNavigationSkip &&
                    index > currentQuestionIndex
                  ) {
                    btnDisabled = true;
                  }
                  return (
                    <div
                      key={question.itemId}
                      style={{
                        padding: '5px',
                        boxSizing: 'border-box',
                        display: flagedCheck
                          ? question.assesseeAssignmentAssessmentItemFlagged === true
                            ? 'block'
                            : 'none'
                          : 'block'
                      }}
                    >
                      <div
                        style={{
                          flex: '1',
                          display: 'flex',
                          paddingTop: '0px',
                          alignItems: 'center',
                          flexDirection: 'column',
                          justifyContent: 'center'
                        }}
                      >
                        <Button
                          style={{
                            fontSize: '1.6rem',
                            width: '40px',
                            height: '40px',
                            padding: '0',
                            minWidth: '0',
                            boxShadow:
                              '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
                            borderRadius: '50%',
                            backgroundColor: currentQuestionIndex === index && 'rgb(240, 240, 240)'
                          }}
                          disabled={btnDisabled}
                          variant="text"
                          // mini
                          onClick={() => {
                            onClickButton(index);
                          }}
                        >
                          {index + 1}
                        </Button>
                        {question.assesseeAssignmentAssessmentItemFlagged ? (
                          <i className={['fa fa-flag', 'jss13409'].join(' ')}></i>
                        ) : (
                          <i></i>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div>
            <Divider light={false} style={{ marginTop: '5px' }} />
            <Button
              className="optionPrimary"
              data-value="create"
              onClick={() => {
                handleOnClickFilter('all');
              }}
            >
              <span style={{ fontSize: '1.2rem' }}>all</span>
              {secondaryOptionCheckValue === 'all' ? (
                <div className="tick" style={{ right: '10px' }}>
                  <Check
                    style={{
                      fontSize: '1.2rem',
                      position: 'relative',
                      right: '-2px',
                      color: '#0000008a'
                    }}
                  />
                </div>
              ) : null}
            </Button>
          </div>
          <div>
            <Button
              className="optionPrimary"
              data-value="create"
              onClick={() => {
                handleOnClickFilter('flaged');
              }}
            >
              <span style={{ fontSize: '1.2rem' }}>flaged</span>
              {secondaryOptionCheckValue === 'flaged' ? (
                <div className="tick" style={{ right: '10px' }}>
                  <Check
                    style={{
                      fontSize: '1.2rem',
                      position: 'relative',
                      right: '-2px',
                      color: '#0000008a'
                    }}
                  />
                </div>
              ) : null}
            </Button>
            <Divider light={false} />
          </div>
          <div style={{ display: 'flex' }}>
            <div className="in-navigator-icon">
              <div
                className="iconBoxFooter"
                style={{ paddingTop: '0px' }}
                onClick={() => {
                  handleOnClickFilter('dashboard');
                }}
              >
                <CircleIcon Icon={Dashboard} className="" colour="displayPaneLeft" label="" />
                <InputLabel
                  style={{
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    margin: '0px',
                    color: 'rgba(0,0,0,0.87)'
                  }}
                >
                  dashboard
                </InputLabel>
              </div>
            </div>
          </div>

          <div>
            <Divider light={false} />
            <Button
              className="optionPrimary"
              disabled={true}
              // onClick={() => {
              //   handleOnClickFilter('finish');
              // }}
            >
              <span style={{ fontSize: '1.2rem' }}>finish</span>
            </Button>
          </div>

          <div>
            <Divider light={false} />
            <Button className="optionPrimary" disabled={true}>
              <span style={{ fontSize: '1.2rem' }}>suspend</span>
            </Button>
          </div>
          <div>
            <Button
              className="optionPrimary"
              onClick={() => {
                handleOnClickFilter('terminate');
              }}
            >
              <span style={{ fontSize: '1.2rem' }}>terminate</span>
            </Button>
          </div>
        </DialogContent>
      </Popup>
      {isPopUpValue === 'POPUP_TERMINATE_SUSPEND' && (
        <Popup isActive={true}>
          <PopupHeader
            headerPanelColour={headerPanelColour}
            headerOne={headerOne}
            headerOneBadgeOne={popupHeaderOneBadgeOne}
            onClick={BackHandlerEvent}
            mode=""
            isNotRevised={false}
          />
          <DialogContent className={['popupContent', 'fixed05PadDim'].join(' ')}>
            <JsonRenderComponent
              setSecondaryOptionValue={setSecondaryOptionValue}
              ChangeOptionPopup={ChangeOptionPopup}
              //currentPopUpOption={popupContentArrValue}
              secondaryOptionCheckValue={secondaryOptionCheckValue}
              //tertiaryOptionCheckValue={tertiaryOptionCheckValue}
            />
          </DialogContent>
        </Popup>
      )}
    </div>
  );
};

PopUpAssessmentNavigator.propTypes = {
  className: PropTypes.string,
  headerPanelColour: PropTypes.oneOf(['genericOne']),
  headerOne: PropTypes.string,
  headerOneBadgeOne: PropTypes.string,
  headerOneBadgeTwo: PropTypes.string,
  headerOneBadgeThree: PropTypes.string,
  isActive: PropTypes.bool
};

export default PopUpAssessmentNavigator;
