import React, { useEffect, useState } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import Popup from '../Molecules/PopUp/PopUp';
import PopupHeader from '../Molecules/PopUp/PopUpHeader';
import FormControl from '@material-ui/core/FormControl';
import InputFeild from '../Atoms/InputField/InputField';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import '../Molecules/PopUp/PopUp.css';
import { useDispatch, useSelector } from 'react-redux';
import { POPUP_CLOSE, SET_NEXT_POPUP } from '../actionType';
import PropTypes from 'prop-types';
import { REQUIRED_ERROR_MESSAGE } from '../errorMessage';
import { FormHelperText, Input } from '@material-ui/core';
import { msToTime, converTimeToMiliseconds } from '../Actions/GenericActions';

const PopUpTimeFeild = (props) => {
  const dispatch = useDispatch();
  const { popupMode } = useSelector((state) => state.PopUpReducer);
  // const { reviewMode } = useSelector((state) => state.DisplayPaneThreeReducer);
  const {
    headerPanelColour = '',
    headerOne = '',
    headerOneBadgeOne = '',
    headerOneBadgeTwo = '',
    isActive = false,
    isRequired = false,
    label = '',
    nextPopUpValue = '',
    basicInfo,
    typeOfSetObject,
    actualLableValue,
    labelBadgeOne = '',
    mode,
    type = 'text',
    isNotRevised = false,
    inputHeader = '',
    inputHeaderBadgeOne = '',
    inputHeaderBadgeTwo = '',
    errorMsg = '',
    issetTime = false,
    reviewMode = 'core',
    actualLableValueVerification
  } = props;
  let errorMessage = isNotRevised ? 'this information cannot be revised' : '';
  const [state, setState] = useState({
    error: errorMessage,
    isVerified: true
  });
  const [localObject, setLocalObject] = useState(basicInfo);
  const [hourState, setHourState] = useState('');
  const [minState, setMinState] = useState('');
  useEffect(() => {
    setLocalObject(basicInfo);
    let timestring =
      ((basicInfo[actualLableValue] === 0 ||
        basicInfo[actualLableValue] === null ||
        basicInfo[actualLableValue] === undefined) &&
        '00:00') ||
      msToTime(basicInfo[actualLableValue]);
    let time = timestring.split(':') || ['00', '00'];
    if (time.length > 0) {
      setHourState(time[0]);
      setMinState(time[1]);
    }
  }, [basicInfo]);

  const validateFun = () => {
    let isValidate = true;
    if (isRequired) {
      if (localObject) {
        if (localObject[actualLableValue] === '') {
          setState((prevState) => ({ ...prevState, error: REQUIRED_ERROR_MESSAGE }));
          return false;
        }
      }
    }
    return isValidate;
  };
  const handleChange = (event) => {
    if (!isNotRevised) {
      const { name, value } = event.target;
      //dispatch({ type: typeOfSetObject, payload: { ...basicInfo, [name]: value } });
      dispatch({ type: typeOfSetObject, payload: { ...localObject, [name]: value } });
      setLocalObject({ ...basicInfo, [name]: value });
      setState((prevState) => ({
        ...prevState,
        error: '',
        isVerified: false
      }));
    }
  };
  const handleClick = () => {
    localObject[actualLableValue] = hourState + ':' + minState;
    /*according to creation mode popup sequence will chanlocalObjectge*/
    if (validateFun() && !isNotRevised) {
      Object.keys(localObject).map((k) =>
        typeof localObject[k] === 'string'
          ? (localObject[k] = localObject[k]?.trim())
          : localObject[k]
      );
      dispatch({ type: typeOfSetObject, payload: { ...localObject } });
      if (reviewMode === 'revise') {
        dispatch({ type: POPUP_CLOSE });
      } else {
        dispatch({ type: SET_NEXT_POPUP, payload: { isPopUpValue: nextPopUpValue } });
      }
    }
  };
  const handleCheckbox = (e) => {
    const { name, checked } = e.target;
    setLocalObject({ ...localObject, [name]: checked });
  };
  return (
    <div>
      <Popup isActive={isActive}>
        <PopupHeader
          headerPanelColour={headerPanelColour}
          headerOne={headerOne}
          headerOneBadgeOne={headerOneBadgeOne}
          headerOneBadgeTwo={headerOneBadgeTwo}
          headerOneBadgeThree={''}
          onClick={handleClick}
          mode={mode}
          isNotRevised={isNotRevised}
        />
        <DialogContent
          className={['popupContent', 'fixed10PadDim', 'revisePopupContent'].join(' ')}
        >
          {inputHeader !== '' && (
            <div className={'fitContent'}>
              <div className={['PopupFormBox', 'labelPopupBox', 'popupMinHei'].join(' ')}>
                <InputLabel htmlFor="name-input" className={'textForLabelPopup'}>
                  <>
                    {inputHeader}&nbsp;
                    {inputHeaderBadgeOne ? (
                      <span className={'headerBadge'}>{inputHeaderBadgeOne}</span>
                    ) : null}
                    &nbsp;
                    {inputHeaderBadgeTwo ? (
                      <span className={'headerBadge'}>{inputHeaderBadgeTwo}</span>
                    ) : null}
                  </>
                </InputLabel>
                <div className={'infoSymbol'}></div>
                <div className={'infoSymbol'}>{/* <InfoToolTip message={infoMsg} /> */}</div>
              </div>
            </div>
          )}
          <FormControl style={{ width: '100%' }} className='duration-picker-box' >
            <InputLabel style={{ fontSize: '1.6rem', padding: '1.2rem', textTransform: 'capitalize' }}>
              {label} &nbsp;
              {labelBadgeOne ? <span className={'labelheaderBadge1'}>{labelBadgeOne}</span> : null}
            </InputLabel>
            <div style={{ paddingTop: '10px' }} className='duration-picker-box'>
              <Input
                type={'number'}
                id={'hourState'}
                name={'hourState'}
                value={hourState}
                onChange={(e) => e.target.value >= 0 && setHourState(e.target.value)}
                // onChange={handleChange}
                autoComplete="off"
                autoFocus={true}
                disabled={isNotRevised}
                className={['inputFields', 'timeFeild', isNotRevised ? 'input-disable' : ''].join(
                  ' '
                )}
              />
              <span className={['timeSpanPadd'].join(' ')}>:</span>
              <Input
                type={'number'}
                id={'minState'}
                name={'minState'}
                value={minState}
                onChange={(e) => e.target.value >= 0 && setMinState(e.target.value)}
                // onChange={handleChange}
                autoComplete="off"
                autoFocus={true}
                disabled={isNotRevised}
                className={['inputFields', 'timeFeild'].join(' ')}
              />
            </div>
          </FormControl>

          <FormHelperText
            className={[isNotRevised ? 'helperText' : 'helperTextDefault', 'helptextmargin'].join(
              ' '
            )}
          >
            <span>{errorMessage}</span>
          </FormHelperText>
        </DialogContent>
      </Popup>
    </div>
  );
};

PopUpTimeFeild.propTypes = {
  className: PropTypes.string,
  headerPanelColour: PropTypes.oneOf([
    'displayPaneLeft',
    'displayPaneCentre',
    'displayPaneRight',
    'genericOne',
    'genericTwo'
  ]),
  headerOne: PropTypes.string,
  headerOneBadgeOne: PropTypes.string,
  headerOneBadgeTwo: PropTypes.string,
  headerOneBadgeThree: PropTypes.string,
  isActive: PropTypes.bool
};
export default PopUpTimeFeild;
