import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PopUpPicture from '../../PopUpInformation/PopUpPicture';
import PopUpTextField from '../../PopUpInformation/PopUpTextField';
import PopUpConfirm from '../../PopUpGeneric/PopUpConfirm';
import {
  POPUP_CLOSE,
  CREATE_TYPE_SAGA,
  LOADER_START,
  CLEAR_TYPE_REDUCER_STATE,
  SET_DISPLAY_THREE_SINGLE_STATE,
  SET_ASSESSEE_TYPE_CLASSIFICAION_STATE,
  SET_ASSESSMENT_TYPE_CLASSIFICAION_STATE,
  SET_ASSIGNMENT_TYPE_CLASSIFICAION_STATE,
  SET_ASSOCIATE_TYPE_CLASSIFICAION_STATE,
  SET_CULTUREPROFILE_TYPE_CLASSIFICAION_STATE,
  SET_JOBPROFILE_TYPE_CLASSIFICAION_STATE,
  SET_ITEM_TYPE_CLASSIFICAION_STATE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_POPUP_SINGLE_STATE
} from '../../actionType';
import PopUpReviewList from '../../PopUpInformation/PopUpReviewList';
import PopUpDropList from '../../PopUpInformation/PopUpDropList';
import PopUpTagSecondary from '../../PopUpInformation/PopUpTagSecondary';
import { getPopUpOptionList } from '../../Actions/GenericActions';
import { ASSESSEES_SUBMODULE_REVIEW_LIST_POPUP_OPTION, NODE_ROLE_TYPE_REVIEW_LIST_POPUP_OPTION } from '../../PopUpConfig';

const PopUpTypeCreate = (props) => {
  const {
    headerOne,
    reducerObeject,
    typeDescription,
    typeName,
    setReducerObject,
    objectName,
    typePicture,
    typePictureVerification,
    typeNameVerification,
    typeStatus
  } = props;
  const { isPopUpValue, popupErrorMsg } = useSelector((state) => state.PopUpReducer);
  const { reviewMode } = useSelector((state) => state.DisplayPaneThreeReducer);
  const { selectedAssociateInfo, signedAssesseePermission } = useSelector(
    (state) => state.DisplayPaneTwoReducer
  );
  const { responseObject, statusPopUpValue } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  const dispatch = useDispatch();
  const onClickCancelYes = () => {
    dispatch({
      type: SET_DISPLAY_THREE_SINGLE_STATE,
      payload: { stateName: 'createMode', value: '' }
    });
    dispatch({ type: CLEAR_TYPE_REDUCER_STATE });
    dispatch({ type: POPUP_CLOSE });
  };
  const onClickYes = () => {
    let reqBody = {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      whichTypeCreate: headerOne,
      [objectName]: reducerObeject
    };
    let permissionSetup;
    if (headerOne === 'assessees') { //chagnes
      permissionSetup =
        signedAssesseePermission?.assesseeDistinctPermission?.assesseeAssesseeTypePermission;
      reqBody = {
        whichTypeCreate: headerOne,
        module: 'Assessee-Type',
        action: 'Create',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          [objectName]: reducerObeject
        }
      };
    }
    if (
      headerOne === 'administrators' ||
      headerOne === 'managers' ||
      headerOne === 'assistants') { //chagnes
      permissionSetup =
        signedAssesseePermission?.assesseeDistinctPermission?.assesseeAssesseeTypePermission;
      reqBody = {
        previousHeaderOne: headerOne,
        whichTypeCreate: 'assessees',
        module: 'Assessee-Type',
        action: 'Create',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          [objectName]: reducerObeject
        }
      };
    }
    if (headerOne === 'assessments') {
      permissionSetup =
        signedAssesseePermission?.assessmentDistictPermission?.assesseeAssessmentTypePermission;
      reqBody = {
        whichTypeCreate: headerOne,
        module: 'Assessment-Type',
        action: 'Create',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          [objectName]: reducerObeject
        }
      };
    }
    if (headerOne === 'assignments') {
      permissionSetup =
        signedAssesseePermission?.assigmentDistictPermission?.assesseeAssignmentTypePermission;
      reqBody = {
        whichTypeCreate: headerOne,
        module: 'Assignment-Type',
        action: 'Create',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          [objectName]: reducerObeject
        }
      };
    }
    if (headerOne === 'associates') {
      permissionSetup =
        signedAssesseePermission?.associateDistinctPermission?.assesseeAssociateTypePermission;
      reqBody = {
        whichTypeCreate: headerOne,
        module: 'Associate-Type',
        action: 'Create',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          [objectName]: reducerObeject
        }
      };
    }
    if (headerOne === 'items') {
      permissionSetup = signedAssesseePermission?.itemDistictPermssion?.assesseeItemTypePermission;
      reqBody = {
        whichTypeCreate: headerOne,
        module: 'Item-Type',
        action: 'Create',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          [objectName]: reducerObeject
        }
      };
    }
    if (headerOne === 'culture-profiles') {
      permissionSetup =
        signedAssesseePermission?.iGuruAnalyticDistinctPermission
          ?.assesseeiGuruAnalyticTypePermission;
      reqBody = {
        whichTypeCreate: headerOne,
        module: 'Culture-Profile-Type',
        action: 'Create',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          [objectName]: reducerObeject
        }
      };
    }
    if (headerOne === 'job-profiles') {
      permissionSetup =
        signedAssesseePermission?.iGuruAnalyticDistinctPermission
          ?.assesseeiGuruAnalyticTypePermission;
      reqBody = {
        whichTypeCreate: headerOne,
        module: 'Job-Profile-Type',
        action: 'Create',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          [objectName]: reducerObeject
        }
      };
    }
    // let tempArray =
    //   headerOne === 'assessees' ||
    //   headerOne === 'administrators' ||
    //   headerOne === 'managers' ||
    //   headerOne === 'assistants'
    //     ? ASSESSEES_SUBMODULE_REVIEW_LIST_POPUP_OPTION
    //     : NODE_ROLE_TYPE_REVIEW_LIST_POPUP_OPTION;
    let popupContentArrValue = getPopUpOptionList(
      NODE_ROLE_TYPE_REVIEW_LIST_POPUP_OPTION,
      permissionSetup
    );
    console.log('popupContentArrValue', popupContentArrValue);
    console.log('reducerObeject', reducerObeject);
    let finalPopupOptionArray = [];
    let classification =
      reducerObeject?.informationSetup[objectName + 'Classification'][
        objectName + 'ClassificationPrimary'
      ];
    console.log('classification>>', classification);
    popupContentArrValue.forEach((element) => {
      if (classification === 'Bespoke' && element.data === 'share') {
        finalPopupOptionArray.push({ ...element, disabled: true });
      } else {
        finalPopupOptionArray.push(element);
      }
    });
    console.log('finalPopupOptionArray>>', finalPopupOptionArray);
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: finalPopupOptionArray
      }
    });
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: {
        stateName: 'popupOptionArr',
        value: finalPopupOptionArray
      }
    });
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: {
        stateName: 'originPrimaryArrOprion',
        value: finalPopupOptionArray
      }
    });
    console.log('CREATE type api', reqBody);
    dispatch({ type: LOADER_START });
    dispatch({ type: CREATE_TYPE_SAGA, payload: reqBody });
  };
  console.log('CREATE TYPE===', reducerObeject);
  let tagPrimary = '';
  let tenureStartDate = 'mm/dd/yyyy --:-- --';
  let tenureEndDate = 'mm/dd/yyyy --:-- --';
  let classificationArray = [];
  if (headerOne === 'assessees') {
    tagPrimary =
      responseObject?.informationEngagement?.assesseeTypeTag?.assesseeTypeTagPrimary || '';
    tenureStartDate =
      responseObject?.informationEngagement?.assesseeTypeTenure?.assesseeTypeTenureDateTimeStart ||
      'mm/dd/yyyy --:-- --';
    tenureEndDate =
      responseObject?.informationEngagement?.assesseeTypeTenure?.assesseeTypeTenureDateTimeEnd ||
      'mm/dd/yyyy --:-- --';
    if (
      signedAssesseePermission?.assesseeDistinctPermission?.assesseeAssesseeTypeBespokePermission
        ?.create
    ) {
      classificationArray.push({ id: 'Bespoke', name: 'Bespoke' });
    }
    if (
      signedAssesseePermission?.assesseeDistinctPermission?.assesseeAssesseeTypeGenericPermission
        ?.create
    ) {
      classificationArray.push({ id: 'Generic', name: 'Generic' });
    }
  }
  if (
    headerOne === 'administrators' ||
    headerOne === 'managers' ||
    headerOne === 'assistants') {
    tagPrimary =
      responseObject?.informationEngagement?.assesseeTypeTag?.assesseeTypeTagPrimary || '';
    tenureStartDate =
      responseObject?.informationEngagement?.assesseeTypeTenure?.assesseeTypeTenureDateTimeStart ||
      'mm/dd/yyyy --:-- --';
    tenureEndDate =
      responseObject?.informationEngagement?.assesseeTypeTenure?.assesseeTypeTenureDateTimeEnd ||
      'mm/dd/yyyy --:-- --';
    if (
      signedAssesseePermission?.assesseeDistinctPermission?.assesseeAssesseeTypeBespokePermission
        ?.create
    ) {
      classificationArray.push({ id: 'Bespoke', name: 'Bespoke' });
    }
    if (
      signedAssesseePermission?.assesseeDistinctPermission?.assesseeAssesseeTypeGenericPermission
        ?.create
    ) {
      classificationArray.push({ id: 'Generic', name: 'Generic' });
    }
  }
  if (headerOne === 'assessments') {
    tagPrimary =
      responseObject?.informationEngagement?.assessmentTypeTag?.assessmentTypeTagPrimary || '';
    tenureStartDate =
      responseObject?.informationEngagement?.assessmentTypeTenure
        ?.assessmentTypeTenureDateTimeStart || 'mm/dd/yyyy --:-- --';
    tenureEndDate =
      responseObject?.informationEngagement?.assessmentTypeTenure
        ?.assessmentTypeTenureDateTimeEnd || 'mm/dd/yyyy --:-- --';
    if (
      signedAssesseePermission?.assessmentDistictPermission?.assesseeAssessmentTypeBespokePermission
        ?.create
    ) {
      classificationArray.push({ id: 'Bespoke', name: 'Bespoke' });
    }
    if (
      signedAssesseePermission?.assessmentDistictPermission?.assesseeAssessmentTypeGenericPermission
        ?.create
    ) {
      classificationArray.push({ id: 'Generic', name: 'Generic' });
    }
  }
  if (headerOne === 'assignments') {
    tagPrimary =
      responseObject?.informationEngagement?.assignmentTypeTag?.assignmentTypeTagPrimary || '';
    tenureStartDate =
      responseObject?.informationEngagement?.assignmentTypeTenure
        ?.assignmentTypeTenureDateTimeStart || 'mm/dd/yyyy --:-- --';
    tenureEndDate =
      responseObject?.informationEngagement?.assignmentTypeTenure
        ?.assignmentTypeTenureDateTimeEnd || 'mm/dd/yyyy --:-- --';
    if (
      signedAssesseePermission?.assigmentDistictPermission?.assesseeAssignmentTypeBespokePermission
        ?.create
    ) {
      classificationArray.push({ id: 'Bespoke', name: 'Bespoke' });
    }
    if (
      signedAssesseePermission?.assigmentDistictPermission?.assesseeAssignmentTypeGenericPermission
        ?.create
    ) {
      classificationArray.push({ id: 'Generic', name: 'Generic' });
    }
  }
  if (headerOne === 'associates') {
    tagPrimary =
      responseObject?.informationEngagement?.associateTypeTag?.associateTypeTagPrimary || '';
    tenureStartDate =
      responseObject?.informationEngagement?.associateTypeTenure
        ?.associateTypeTenureDateTimeStart || 'mm/dd/yyyy --:-- --';
    tenureEndDate =
      responseObject?.informationEngagement?.associateTypeTenure?.associateTypeTenureDateTimeEnd ||
      'mm/dd/yyyy --:-- --';
    if (
      signedAssesseePermission?.associateDistinctPermission?.assesseeAssociateTypeBespokePermission
        ?.create
    ) {
      classificationArray.push({ id: 'Bespoke', name: 'Bespoke' });
    }
    if (
      signedAssesseePermission?.associateDistinctPermission?.assesseeAssociateTypeGenericPermission
        ?.create
    ) {
      classificationArray.push({ id: 'Generic', name: 'Generic' });
    }
  }
  if (headerOne === 'items') {
    tagPrimary = responseObject?.informationEngagement?.itemTypeTag?.itemTypeTagPrimary || '';
    tenureStartDate =
      responseObject?.informationEngagement?.itemTypeTenure?.itemTypeTenureDateTimeStart ||
      'mm/dd/yyyy --:-- --';
    tenureEndDate =
      responseObject?.informationEngagement?.itemTypeTenure?.itemTypeTenureDateTimeEnd ||
      'mm/dd/yyyy --:-- --';
    if (signedAssesseePermission?.itemDistictPermssion?.assesseeItemTypeBespokePermission?.create) {
      classificationArray.push({ id: 'Bespoke', name: 'Bespoke' });
    }
    if (signedAssesseePermission?.itemDistictPermssion?.assesseeItemTypeGenericPermission?.create) {
      classificationArray.push({ id: 'Generic', name: 'Generic' });
    }
  }
  if (headerOne === 'culture-profiles') {
    tagPrimary =
      responseObject?.informationEngagement?.cultureProfileTypeTag?.cultureProfileTypeTagPrimary ||
      '';
    tenureStartDate =
      responseObject?.informationEngagement?.cultureProfileTypeTenure
        ?.cultureProfileTypeTenureDateTimeStart || 'mm/dd/yyyy --:-- --';
    tenureEndDate =
      responseObject?.informationEngagement?.cultureProfileTypeTenure
        ?.cultureProfileTypeTenureDateTimeEnd || 'mm/dd/yyyy --:-- --';
    if (
      signedAssesseePermission?.iGuruAnalyticDistinctPermission
        ?.assesseeiGuruAnalyticTypeBespokePermission?.create
    ) {
      classificationArray.push({ id: 'Bespoke', name: 'Bespoke' });
    }
    if (
      signedAssesseePermission?.iGuruAnalyticDistinctPermission
        ?.assesseeiGuruAnalyticTypeGenericPermission?.create
    ) {
      classificationArray.push({ id: 'Generic', name: 'Generic' });
    }
  }
  if (headerOne === 'job-profiles') {
    tagPrimary =
      responseObject?.informationEngagement?.jobProfileTypeTag?.jobProfileTypeTagPrimary || '';
    tenureStartDate =
      responseObject?.informationEngagement?.jobProfileTypeTenure
        ?.jobProfileTypeTenureDateTimeStart || 'mm/dd/yyyy --:-- --';
    tenureEndDate =
      responseObject?.informationEngagement?.jobProfileTypeTenure
        ?.jobProfileTypeTenureDateTimeEnd || 'mm/dd/yyyy --:-- --';
    if (
      signedAssesseePermission?.iGuruAnalyticDistinctPermission
        ?.assesseeiGuruAnalyticTypeBespokePermission?.create
    ) {
      classificationArray.push({ id: 'Bespoke', name: 'Bespoke' });
    }
    if (
      signedAssesseePermission?.iGuruAnalyticDistinctPermission
        ?.assesseeiGuruAnalyticTypeGenericPermission?.create
    ) {
      classificationArray.push({ id: 'Generic', name: 'Generic' });
    }
  }
  console.log('tagPrimary', tagPrimary);
  console.log('tenureStartDate', tenureStartDate);
  console.log('tenureEndDate', tenureEndDate);
  
  const signedAssociateId =
    selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary;
  return (
    <div>
      {isPopUpValue === 'NAMEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'name'}
          actualLableValue={typeName}
          actualLableValueVerification={typeNameVerification}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'type'}
          headerOneBadgeTwo={'information'}
          nextPopUpValue={'ALIASPOPUP'}
          basicInfo={reducerObeject.informationBasic}
          typeOfSetObject={setReducerObject}
          isRequired={true}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          errorMsg={popupErrorMsg}
          isNotRevised={reviewMode === 'revise' && responseObject.associateId !== signedAssociateId}
        />
      )}
      {isPopUpValue === 'ALIASPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'description'}
          actualLableValue={typeDescription}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'type'}
          headerOneBadgeTwo={'information'}
          basicInfo={reducerObeject.informationBasic}
          nextPopUpValue={'PICTUREPOPUP'}
          typeOfSetObject={setReducerObject}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={reviewMode === 'revise' && responseObject.associateId !== signedAssociateId}

        />
      )}
      {isPopUpValue === 'PICTUREPOPUP' && (
        <PopUpPicture
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'type'}
          headerOneBadgeTwo={'information'}
          actualLableValue={typePicture}
          actualLableValueVerification={typePictureVerification}
          basicInfo={reducerObeject.informationBasic}
          typeOfSetObject={setReducerObject}
          nextPopUpValue={'GROUPPOPUP'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={reviewMode === 'revise' && responseObject.associateId !== signedAssociateId}

        />
      )}
      {isPopUpValue === 'GROUPPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={objectName + 'ClassificationPrimary'}
          label={'classification'}
          listSelect={classificationArray}
          mappingValue={'id'}
          inputHeader={''}
          inputHeaderBadgeOne={''}
          inputHeaderBadgeTwo={''}
          labelval={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'type'}
          headerOneBadgeTwo={'information'}
          isRequired={true}
          nextPopUpValue={reviewMode === 'revise' ? '' : 'CONFIRMATIONPOPUP'}
          basicInfo={reducerObeject.informationSetup[objectName + 'Classification']}
          typeOfSetObject={
            (objectName === 'assesseeType' && SET_ASSESSEE_TYPE_CLASSIFICAION_STATE) ||
            (objectName === 'assessmentType' && SET_ASSESSMENT_TYPE_CLASSIFICAION_STATE) ||
            (objectName === 'assignmentType' && SET_ASSIGNMENT_TYPE_CLASSIFICAION_STATE) ||
            (objectName === 'associateType' && SET_ASSOCIATE_TYPE_CLASSIFICAION_STATE) ||
            (objectName === 'cultureProfileType' && SET_CULTUREPROFILE_TYPE_CLASSIFICAION_STATE) ||
            (objectName === 'jobProfileType' && SET_JOBPROFILE_TYPE_CLASSIFICAION_STATE) ||
            (objectName === 'itemType' && SET_ITEM_TYPE_CLASSIFICAION_STATE)
          }
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={reviewMode === 'revise' && responseObject.associateId !== signedAssociateId}
        />
      )}
      {isPopUpValue === 'CANCELPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'cancel'}
          headerOneBadgeOne={''}
          mode={'cancel'}
          onClickYes={onClickCancelYes}
        />
      )}
      {isPopUpValue === 'CONFIRMATIONPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'type'}
          headerOneBadgeTwo={'create'}
          onClickYes={onClickYes}
          onClickNoFun={onClickCancelYes}
        />
      )}
      {isPopUpValue === 'STATUSPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={objectName + 'Status'}
          label={'status'}
          listSelect={[
            { id: 'ACTIVE', name: 'Active' },
            { id: 'SUSPENDED', name: 'Suspended' },
            { id: 'TERMINATED', name: 'Terminated' },
            { id: 'UNVERIFIED', name: 'Unverified' },
            { id: 'CONFIRMED', name: 'Confirmed' }
          ]}
          mappingValue={'id'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'type'}
          headerOneBadgeTwo={'information'}
          isRequired={true}
          basicInfo={responseObject?.informationEngagement}
          nextPopUpValue={''}
          typeOfSetObject={''}
          handleNextPopupValue={() => {}}
          isNotRevised={true}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TAGREADONLYPRIMARYPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tag'}
          labelBadgeOne={'primary'}
          actualLableValue={objectName + 'TagPrimary'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'type'}
          headerOneBadgeTwo={'information'}
          basicInfo={responseObject?.informationEngagement[objectName + 'Tag']}
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={''}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TAGSECONDARYPOPUP' && (
        <PopUpTagSecondary
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'type'}
          headerOneBadgeTwo={'information'}
          tagSecondary={reducerObeject?.informationEngagement || {}}
          signInSetup={reducerObeject?.informationSetup || {}}
          nextPopUpValue={''}
          typeOfSetObject={''}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TENURESATRTDATEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tenure'}
          labelBadgeOne={'start'}
          actualLableValue={objectName + 'TenureDateTimeStart'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'type'}
          headerOneBadgeTwo={'information'}
          basicInfo={responseObject?.informationEngagement[objectName + 'Tenure']}
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={''}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TENUREENDDATEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tenure'}
          labelBadgeOne={'end'}
          actualLableValue={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'type'}
          headerOneBadgeTwo={'information'}
          basicInfo={tenureEndDate}
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={''}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
    </div>
  );
};

export default PopUpTypeCreate;