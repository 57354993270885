import { put, takeLatest, call } from 'redux-saga/effects';
import {
  ASSESSMENT_GROUP_REVISE_INFO_SAGA,
  GET_ASSESSMENTGROUP_ASSESSMENT_REVIEWLIST_SAGA,
  GET_ASSESSMENT_GROUP_REVIEW_INFO_SAGA,
  GET_ASSESSMENT_GROUP_REVIEW_LIST_SAGA,
  LOADER_STOP,
  SET_ASSESSEE_GROUP_ASSESSEE_ID_LIST,
  SET_ASSESSMENT_GROUP_REDUCER_STATE,
  SET_DISPLAY_PANE_THREE_STATE,
  SET_DISPLAY_THREE_SINGLE_STATE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_GROUP_ALLOCATION_GROUP_ASCENDANT_STATE,
  SET_GROUP_SETUP_STATE,
  SET_POPUP_VALUE,
  SET_UNSELECTED_ASSESSEE_GROUP_ASSESSEE_ID_LIST,
  SET_GROUP_ALLOCATION_STATE
} from '../../actionType';
import { ASSESSMENT_MODULE_URL } from '../../endpoints';
import { EXCEPTION_ERROR_MESSAGE } from '../../errorMessage';
import Store from '../../store';

const assessmentGroupInfoApi = async (requestObj) => {
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      Authorization: localStorage.getItem('token')
    }),
    body: JSON.stringify(requestObj.data)
  };
  const response = await fetch(requestObj.URL, requestOptions);
  const json = await response.json();
  return json;
};

function* workerReviewAssessmentGroupInfoSaga(data) {
  try {
    const userResponse = yield call(assessmentGroupInfoApi, {
      data: data.payload.reqBody,
      URL: ASSESSMENT_MODULE_URL
    });
    if (userResponse.responseCode === '000') {
      const {
        isReviseMode = false,
        assessmentGroupAssessmentReqBody = null,
        createMode = ''
      } = data.payload;
      if (assessmentGroupAssessmentReqBody !== null) {
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
        yield put({
          type: GET_ASSESSMENTGROUP_ASSESSMENT_REVIEWLIST_SAGA,
          payload: {
            request: assessmentGroupAssessmentReqBody,
            HeaderOne: 'assessments',
            BadgeOne: '',
            BadgeTwo: '',
            BadgeThree: '',
            isMiddlePaneList: false
          }
        });
      }
      yield put({
        type: SET_DISPLAY_PANE_THREE_STATE,
        payload: {
          headerOne: 'assessments',
          headerOneBadgeOne: 'group',
          headerOneBadgeTwo: 'information',
          headerOneBadgeThree: 'key',
          createMode: createMode,
          responseObject: userResponse.responseObject[0],
          reviewMode: isReviseMode ? 'revise' : ''
        }
      });
      if (isReviseMode) {
        yield put({
          type: SET_ASSESSMENT_GROUP_REDUCER_STATE,
          payload: userResponse.responseObject[0].informationBasic
        });
        yield put({
          type: SET_GROUP_SETUP_STATE,
          payload: {
            objectName: 'assessmentGroup',
            value: userResponse.responseObject[0].informationSetup
          }
        });
        let tempArr = [];
        if (
          userResponse.responseObject[0].informationAllocation &&
          userResponse.responseObject[0].informationAllocation?.assessmentGroupAscendant
            ?.assessmentGroupAscendantPrimary &&
          userResponse.responseObject[0].informationAllocation?.assessmentGroupAscendant
            ?.assessmentGroupAscendantPrimary?.length > 0
        ) {
          tempArr =
            userResponse.responseObject[0].informationAllocation?.assessmentGroupAscendant?.assessmentGroupAscendantPrimary.map(
              (ob) => ob.id
            );
        }
        yield put({
          type: SET_GROUP_ALLOCATION_GROUP_ASCENDANT_STATE,
          payload: {
            objectName: 'assessmentGroup',
            stateName: 'assessmentGroupAscendantPrimary',
            value: tempArr
          }
        });
        if (
          userResponse.responseObject[0].informationAllocation &&
          userResponse.responseObject[0].informationAllocation?.assessmentGroupNode
            ?.assessmentGroupNodePrimary &&
          userResponse.responseObject[0].informationAllocation?.assessmentGroupNode
            ?.assessmentGroupNodePrimary?.length > 0
        ) {
          let tempArr =
            userResponse.responseObject[0].informationAllocation?.assessmentGroupNode?.assessmentGroupNodePrimary.map(
              (ob) => ob.id
            );
          yield put({
            type: SET_GROUP_ALLOCATION_STATE,
            payload: {
              objectName: 'assessmentGroup',
              stateName: 'assessmentGroupNode',
              actualStateName: 'assessmentGroupNodePrimary',
              value: tempArr
            }
          });
        }
        if (
          userResponse.responseObject[0].informationAllocation &&
          userResponse.responseObject[0].informationAllocation?.assessmentGroupNode
            ?.assessmentGroupNodeSecondary &&
          userResponse.responseObject[0].informationAllocation?.assessmentGroupNode
            ?.assessmentGroupNodeSecondary?.length > 0
        ) {
          let tempArr =
            userResponse.responseObject[0].informationAllocation?.assessmentGroupNode?.assessmentGroupNodeSecondary.map(
              (ob) => ob.id
            );
          yield put({
            type: SET_GROUP_ALLOCATION_STATE,
            payload: {
              objectName: 'assessmentGroup',
              stateName: 'assessmentGroupNode',
              actualStateName: 'assessmentGroupNodeSecondary',
              value: tempArr
            }
          });
        }

        if (
          userResponse.responseObject[0].informationAllocation &&
          userResponse.responseObject[0].informationAllocation?.assessmentGroupType
            ?.assessmentGroupTypeSecondary &&
          userResponse.responseObject[0].informationAllocation?.assessmentGroupType
            ?.assessmentGroupTypeSecondary?.length > 0
        ) {
          let tempArr =
            userResponse.responseObject[0].informationAllocation?.assessmentGroupType?.assessmentGroupTypeSecondary.map(
              (ob) => ob.id
            );
          yield put({
            type: SET_GROUP_ALLOCATION_STATE,
            payload: {
              objectName: 'assessmentGroup',
              stateName: 'assessmentGroupType',
              actualStateName: 'assessmentGroupTypeSecondary',
              value: tempArr
            }
          });
        }

      }
    } else {
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: userResponse.responseMessage,
          popupMode: 'responseErrorMsg'
        }
      });
    }

    console.log('loading end');
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
    yield put({ type: LOADER_STOP });
  }
}

function* workerReviseAssessmentGroupInfoSaga(data) {
  try {
    const userResponse = yield call(assessmentGroupInfoApi, {
      data: data.payload.reqBody,
      URL: ASSESSMENT_MODULE_URL
    });
    if (userResponse.responseCode === '000') {
      const {
        createMode = '',
        assessmentGroupAssessmentReqBody = null,
        cardValue = '',
        isReviseCallFromPaneTwo = false
      } = data.payload;
      if (assessmentGroupAssessmentReqBody !== null) {
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
        yield put({
          type: GET_ASSESSMENTGROUP_ASSESSMENT_REVIEWLIST_SAGA,
          payload: {
            request: assessmentGroupAssessmentReqBody,
            HeaderOne: 'assessments',
            BadgeOne: '',
            BadgeTwo: '',
            BadgeThree: '',
            isMiddlePaneList: false
          }
        });
        if (isReviseCallFromPaneTwo) {
          yield put({
            type: SET_DISPLAY_THREE_SINGLE_STATE,
            payload: { stateName: 'isFrameworkOpen', value: 'assessmentGroup' }
          });
        } else {
          yield put({
            type: SET_DISPLAY_THREE_SINGLE_STATE,
            payload: { stateName: 'isFrameworkOpen', value: '' }
          });
        }
        console.log('class>>>>>>>', userResponse.responseObject[0]);
        console.log('class>>>>>>>', userResponse.responseObject[0].informationSetup);
        console.log(
          'class>>>>>>>',
          userResponse.responseObject[0].informationSetup?.assessmentGroupClassification
        );
        console.log(
          'class>>>>>>>',
          userResponse.responseObject[0].informationSetup?.assessmentGroupClassification
            ?.assessmentGroupClassificationPrimary
        );
        let tempArr = [];
        Store.getState().DisplayPaneTwoReducer.middlePaneListPopupOptions.forEach((element) => {
          if (
            element.data === 'share' &&
            userResponse.responseObject[0].informationSetup?.assessmentGroupClassification
              ?.assessmentGroupClassificationPrimary === 'Bespoke'
          ) {
            tempArr.push({ ...element, disabled: true });
          } else if (
            element.data === 'share' &&
            userResponse.responseObject[0].informationSetup?.assessmentGroupClassification
              ?.assessmentGroupClassificationPrimary === 'Generic'
          ) {
            tempArr.push({ ...element, disabled: false });
          } else tempArr.push(element);
        });
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'middlePaneListPopupOptions',
            value: tempArr
          }
        });
        yield put({
          type: SET_DISPLAY_PANE_THREE_STATE,
          payload: {
            headerOne: 'assessments',
            headerOneBadgeOne: 'group',
            headerOneBadgeTwo: 'information',
            headerOneBadgeThree: 'key',
            responseObject: userResponse.responseObject[0],
            createMode,
            reviewMode: isReviseCallFromPaneTwo ? 'revise' : ''
          }
        });
      } else {
        yield put({ type: LOADER_STOP });
      }
      if (createMode === '') {
        yield put({ type: SET_ASSESSEE_GROUP_ASSESSEE_ID_LIST, payload: [] });
        yield put({
          type: SET_UNSELECTED_ASSESSEE_GROUP_ASSESSEE_ID_LIST,
          payload: []
        });
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'reviewListDistinctData', value: [] }
        });
        yield put({
          type: GET_ASSESSMENT_GROUP_REVIEW_LIST_SAGA,
          payload: {
            middlePaneHeader: 'assessees',
            request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
            BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
            isMiddlePaneList: true,
            cardValue
          }
        });
      } else {
        yield put({ type: LOADER_STOP });
      }
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: userResponse.responseMessage,
          popupMode: 'responseErrorMsg'
        }
      });
      yield put({ type: LOADER_STOP });
    }

    console.log('loading end');
    // yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
    yield put({ type: LOADER_STOP });
  }
}

export default function* watchReviewAssessmentGroupInfoSaga() {
  yield takeLatest(GET_ASSESSMENT_GROUP_REVIEW_INFO_SAGA, workerReviewAssessmentGroupInfoSaga);
  yield takeLatest(ASSESSMENT_GROUP_REVISE_INFO_SAGA, workerReviseAssessmentGroupInfoSaga);
}
