import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PopupHeader from '../Molecules/PopUp/PopUpHeader';
import Popup from '../Molecules/PopUp/PopUp';
import '../Molecules/PopUp/PopUp.css';
import { DialogContent } from '@material-ui/core';
import {
  ASSESSEE_INFO_CREATE,
  ASSESSEE_SIGN_ON,
  CLEAR_ASSESSEE_INFO,
  SET_ASSESSEE_NEXT_POPUP,
  SET_ASSESSEE_PREVIOUS_POPUP,
  SET_ASSESSEE_SECONDARY_OPTION_VALUE,
  SET_PREVIOUS_SECTION_POPUP,
  SET_DISPLAY_TWO_SINGLE_STATE,
  CLEAR_DISPLAY_PANE_THREE,
  SET_MIDDLEPANE_STATE,
  SET_POPUP_SINGLE_STATE,
  SECTION_POPUP_CLOSE,
  ASSESSEE_POPUP_OPEN
} from '../actionType';
import JsonRenderComponent from '../Actions/JsonRenderComponent';
import {
  assesseeCreateApiCalls,
  getAssesseeDistinctApiCall,
  getAssesseeGroupDistinctApiCall,
  getAssesseeRoleDistinctApiCall,
  getAssesseeTypeApiCall,
  getAssesseeMarketplaceDistinctApiCall,
  getAssesseeMarketplaceGroupApiCall
} from '../Actions/AssesseeModuleAction';
import { getInternalNodeApiCall } from '../Actions/AssociateModuleAction';

const PopUpAssesseesModule = (props) => {
  const {
    currentPopUpOption,
    assesseesPopUpType,
    assesseesHeaderOne,
    assesseesHeaderOneBadgeOne,
    secondaryOptionCheckValue,
    isBackToSectionPopUp,
    assesseesPopUpActive
  } = useSelector((state) => state.AssesseeCreateReducer);
  const {
    countPage,
    selectedAssociateInfo,
    signedAssesseePermission,
    assignmentCreateType,
    isListLoading,
    assignmentCreateTypeSecondary,
    paneOneSelectedSection
  } = useSelector((state) => state.DisplayPaneTwoReducer);

  const dispatch = useDispatch();
  const { headerPanelColour = 'displayPaneLeft' } = props;

  const setSecondaryOptionValue = (e) => {
    dispatch({
      type: SET_ASSESSEE_SECONDARY_OPTION_VALUE,
      payload: e.currentTarget.getAttribute('data-value')
    });
    dispatch({ type: SECTION_POPUP_CLOSE });
  };
  const resetDataFunction = () => {
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'allocateStr', value: '' }
    });
    // document.getElementById('middleComponentId').scrollTop = '0px';
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: { stateName: 'cardValue', value: 'NoCard' }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'typeOfMiddlePaneList', value: '' }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'middlePaneSelectedValue', value: '' }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'selectedFlagedArray', value: [] }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'unselectedFlagedArray', value: [] }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'selectedTagsArray', value: [] }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'unselectedTagsArray', value: [] }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'flagedValue', value: '' }
    });
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: { stateName: 'selectionValue', value: '' }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'nodeViewState', value: '' }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'scanString', value: '' }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'allocatedTagsArray', value: [] }
    });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
    dispatch({ type: ASSESSEE_INFO_CREATE });
    dispatch({ type: SECTION_POPUP_CLOSE });
  };
  const ChangeOptionPopup = (e) => {
    let targetValue = e.currentTarget.getAttribute('data-value');
    if (targetValue === 'information' && !isListLoading) {
      assesseeCreateApiCalls(
        selectedAssociateInfo,
        dispatch,
        secondaryOptionCheckValue,
        'assessee',
        assignmentCreateType
      );
      dispatch({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'cardValue', value: 'Create' }
      });
      console.log('thissssssssssssssssss');
      dispatch({
        type: ASSESSEE_SIGN_ON,
        payload: {
          isPopUpValue: 'ASSESSEENAMEPOPUP',
          popupMode: 'ASSESSEE_CREATE'
        }
      });
      clearMiddlePaneInfo();
    } else if (targetValue === 'distinct' && !isListLoading) {
      resetDataFunction();
      if (paneOneSelectedSection === 'paneoneSection3') {
        return getAssesseeMarketplaceDistinctApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          targetValue,
          '',
          assignmentCreateType
        );
      }
      getAssesseeDistinctApiCall(
        selectedAssociateInfo,
        secondaryOptionCheckValue,
        countPage,
        dispatch,
        targetValue,
        '',
        assignmentCreateType
      );
    } else if (targetValue === 'roles' && !isListLoading) {
      resetDataFunction();
      if (paneOneSelectedSection === 'paneoneSection3') {
        getAssesseeRoleDistinctApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          targetValue,
          dispatch,
          'noCard',
          '',
          'Shared-Assessee-List'
        );
      } else {
        getAssesseeRoleDistinctApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          targetValue,
          dispatch
        );
      }
    } else if (targetValue === 'groups' && !isListLoading) {
      resetDataFunction();
      if (paneOneSelectedSection === 'paneoneSection3') {
        return getAssesseeMarketplaceGroupApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          targetValue,
          'NoCard',
          '',
          'hierarchy',
          [],
          assignmentCreateType
        );
      }
      getAssesseeGroupDistinctApiCall(
        selectedAssociateInfo,
        secondaryOptionCheckValue,
        countPage,
        dispatch,
        targetValue,
        'NoCard',
        '',
        'hierarchy',
        [],
        assignmentCreateType
      );
    } else if (targetValue === 'nodes' && !isListLoading) {
      resetDataFunction();
      getInternalNodeApiCall(
        selectedAssociateInfo,
        secondaryOptionCheckValue,
        countPage,
        dispatch,
        targetValue,
        '',
        'hierarchy',
        'assessees'
      );
    } else if (targetValue === 'types' && !isListLoading) {
      resetDataFunction();
      if (paneOneSelectedSection === 'paneoneSection3') {
        getAssesseeTypeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          targetValue,
          'noCard',
          'assessees',
          '',
          'Shared-Assessee'
        );
      } else {
        getAssesseeTypeApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          targetValue
        );
      }
    } else {
      // let assesseeDistinctPermission = signedAssesseePermission?.assesseeDistinctPermission;
      // assesseeDistinctPermission.map((element, ind) => {

      // });
      //let popupContentArrValue = getPopUpOptionList(REVIEW_POPUP_OPTIONS, assesseeDistinctPermission);
      // dispatch({
      //   type: ASSESSEE_POPUP_OPEN, payload: {
      //     popupContentArrValue: popupContentArrValue
      //   }
      // });
      console.log('signedAssesseePermission', signedAssesseePermission);
      dispatch({
        type: SET_ASSESSEE_NEXT_POPUP,
        // payload: targetValue
        payload: {
          targetValue,
          permissionObject: signedAssesseePermission?.assesseeDistinctPermission
        }
      });
    }
  };
  const clearMiddlePaneInfo = () => {
    dispatch({
      type: SET_MIDDLEPANE_STATE,
      payload: {
        middlePaneHeader: '',
        middlePaneHeaderBadgeOne: '',
        middlePaneHeaderBadgeTwo: '',
        middlePaneHeaderBadgeThree: '',
        middlePaneHeaderBadgeFour: '',
        typeOfMiddlePaneList: '',
        scanCount: null,
        showMiddlePaneState: false
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'middlePaneSelectedValue', value: '' }
    });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  };
  const BackHandlerEvent = (e) => {
    if (isBackToSectionPopUp) {
      dispatch({ type: CLEAR_ASSESSEE_INFO });
      dispatch({ type: SET_PREVIOUS_SECTION_POPUP });
    } else {
      dispatch({
        type: SET_ASSESSEE_PREVIOUS_POPUP,
        payload: {
          permissionObject:
            signedAssesseePermission?.assesseeDistinctPermission?.assesseeAssesseeDistinctPermission
        }
      });
    }
  };
  return (
    <div>
      <Popup isActive={assesseesPopUpActive}>
        <PopupHeader
          headerPanelColour={headerPanelColour + assesseesPopUpType}
          headerOne={assesseesHeaderOne}
          headerOneBadgeOne={assesseesHeaderOneBadgeOne}
          onClick={BackHandlerEvent}
          mode={''}
        />
        <DialogContent className={['popupContent', 'fixed05PadDim'].join(' ')}>
          <JsonRenderComponent
            setSecondaryOptionValue={setSecondaryOptionValue}
            ChangeOptionPopup={ChangeOptionPopup}
            currentPopUpOption={currentPopUpOption}
            secondaryOptionCheckValue={secondaryOptionCheckValue}
          />
        </DialogContent>
      </Popup>
    </div>
  );
};

export default PopUpAssesseesModule;
