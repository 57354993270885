import React from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import DisplayPanelAccordianReviewListOne from '../Accordian/DisplayPanelAccordianReviewListOne';
import DisplayPanelAccordianInformation from '../Accordian/DisplayPanelAccordianInformation';
import { Paper } from '@material-ui/core';
import {
  FILTERMODE,
  RELATED_REVIEWLIST_DISTINCT_DATA,
  SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
  SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MIDDLEPANE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_PANE_THREE_ASSESSMENT_VERSION_PREVIEW_MODE,
  SET_POPUP_VALUE
} from '../../actionType';
import { setItemTypeConfigState } from '../../Actions/GenericActions';
import DisplayPanelAccordianReviewListTwo from '../Accordian/DisplayPanelAccordianReviewListTwo';
import { ASSESSMENT_SECTION_PREIVEW_SEQUENCE_ERROR } from '../../errorMessage';
import Store from '../../store';

const DisplayPaneThreeSectionTwoAssessmentVersion = () => {
  const dispatch = useDispatch();
  const { responseObject, reviewMode } = useSelector((state) => state.DisplayPaneThreeReducer);
  const { selectedTagValue } = useSelector((state) => state.PopUpReducer);
  const {
    assessmentResponseObject,
    middlePaneHeaderDuplicate,
    middlePaneHeaderDuplicateBadgeOne,
    middlePaneHeaderDuplicateBadgeTwo,
    middlePaneHeaderDuplicateBadgeThree,
    middlePaneHeaderDuplicateBadgeFour
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { relatedReviewListDistinctData, middlePaneSelectedValue } = useSelector(
    (state) => state.DisplayPaneTwoReducer
  );
  let sectionArr = [];
  assessmentResponseObject?.informationFramework?.assessmentSectionFramework.map((comm, index) => {
    let itemArray = [];
    let selectedVersionItem = comm?.assessmentVersionFramework[parseInt(middlePaneSelectedValue)];
    selectedVersionItem?.assessmentVersionItemDistinct.forEach((ob) => {
      const { id, informationBasic, informationEngagement } = ob;
      itemArray.push({
        id,
        textOne: informationBasic?.itemName || '',
        textTwo: informationBasic?.itemDescription || '',
        status: informationEngagement?.itemStatus || ''
      });
    });
    sectionArr.push({
      labelTextOneOneBadge: index + 1,
      innerLabelBadgeList: [
        {
          labelTextTwoBadge: 'item',
          innerList: itemArray
        },
        {
          labelTextTwoBadge: 'preview',
          innerList: []
        }
      ]
    });
  });
  let sectionPreviewArr = [];
  assessmentResponseObject?.informationFramework?.assessmentSectionFramework.map((comm, index) => {
    sectionPreviewArr.push({
      labelTextTwoBadge: index + 1,
      innerLabelBadgeList: []
    });
  });
  const frameworkAll = [
    {
      id: 'sectiona4',
      labelTextOneOne: 'section',
      labelTextOneOneBadges: sectionArr,
      innerAssociateList: [],
      innerInfo: 'No Information',
      IconOne: null,
      isMultiList: true,
      isListCard: true,
      isReviewLink: true
    }
  ];
  const reviseFramework = (e, selectedBadgeArray) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    const innerSelectedBadgeName = e.currentTarget.getAttribute('id');
    if (labelName === 'section' && selectedBadgeName !== '' && innerSelectedBadgeName === 'item') {
      dispatch({ type: SET_PANE_THREE_ASSESSMENT_VERSION_PREVIEW_MODE, payload: false });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'assessmentSelecedSection', value: selectedBadgeName - 1 }
      });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'assessmentSelecedVersion', value: parseInt(selectedTagValue) }
      });
      let revisedGroupObject = {
        id: assessmentResponseObject.id,
        assessmentSectionName: responseObject.assessmentVersionName,
        assessmentSectionDescription: responseObject.assessmentVersionDescription,
        item: assessmentResponseObject?.informationFramework?.assessmentItemDistinct
      };

      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'relatedReviewListDistinctData', value: [] }
      });
      let selectedSecSection =
        assessmentResponseObject?.informationFramework?.assessmentSectionFramework[
          selectedBadgeName - 1
        ];
      let selectedVersion = selectedSecSection.assessmentVersionFramework[selectedTagValue];
      let existingItemId =
        selectedVersion?.assessmentVersionItemDistinct &&
        selectedVersion?.assessmentVersionItemDistinct?.map((val) => {
          return val.itemTagPrimary;
        });
      dispatch({
        type: FILTERMODE,
        payload: { FilterMode: 'assessmentSectionItemRevise' }
      });
      dispatch({
        type: SET_MIDDLEPANE_STATE,
        payload: {
          middlePaneHeader: 'items',
          middlePaneHeaderBadgeOne: 'distinct',
          middlePaneHeaderBadgeTwo: 'active',
          middlePaneHeaderBadgeThree: '',
          middlePaneHeaderBadgeFour: '',
          middlePaneHeaderDuplicate: middlePaneHeaderDuplicate,
          middlePaneHeaderDuplicateBadgeOne: middlePaneHeaderDuplicateBadgeOne,
          middlePaneHeaderDuplicateBadgeTwo: middlePaneHeaderDuplicateBadgeTwo,
          middlePaneHeaderDuplicateBadgeThree: middlePaneHeaderDuplicateBadgeThree,
          middlePaneHeaderDuplicateBadgeFour: middlePaneHeaderDuplicateBadgeFour,
          middlePaneHeaderDuplicateOne:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOne,
            middlePaneHeaderDuplicateOneBadgeOne:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeOne,
            middlePaneHeaderDuplicateOneBadgeTwo:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeTwo,
            middlePaneHeaderDuplicateOneBadgeThree:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeThree,
            middlePaneHeaderDuplicateOneBadgeFour:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeFour,
            duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
          typeOfMiddlePaneList: 'assessmentSectionItemDistinctReviewList',
          scanCount: assessmentResponseObject.informationFramework?.assessmentItemDistinct.length,
          showMiddlePaneState: true,
          isSelectActive: 'multiple',
          selectedTagsArray: existingItemId


        }
      });
      dispatch({ type: RELATED_REVIEWLIST_DISTINCT_DATA, payload: [revisedGroupObject] });
      dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
    }
    if (
      labelName === 'section' &&
      selectedBadgeName !== '' &&
      innerSelectedBadgeName === 'preview'
    ) {
      let assessmentSectionAdministrationSequence =
        assessmentResponseObject?.informationFramework?.assessmentSectionFramework[
          selectedBadgeName - 1
        ].assessmentSectionAdministrationSequence || [];
      if (assessmentSectionAdministrationSequence.length > 0) {
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'assessmentSelecedSection', value: selectedBadgeName - 1 }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'assessmentSelecedVersion', value: selectedTagValue }
        });
        setTimeout(function () {
          dispatch({ type: SET_PANE_THREE_ASSESSMENT_VERSION_PREVIEW_MODE, payload: true });
          dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneFive' });
        }, 1000);

        dispatch({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: {
            stateName: 'assesseeAssessmentStartData',
            value:
              assessmentResponseObject.informationFramework.assessmentSectionFramework[
                selectedBadgeName - 1
              ].assessmentVersionFramework[selectedTagValue]
          }
        });
        dispatch({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: { stateName: 'isAssessmentStart', value: 'PROGRESS' }
        });
        setItemTypeConfigState(
          assessmentResponseObject.informationFramework.assessmentSectionFramework[
            selectedBadgeName - 1
          ].assessmentVersionFramework[selectedTagValue].assessmentVersionItemDistinct[0]
            .itemFrameworkOne.itemFrameworkOneType,
          dispatch
        );
        dispatch({
          type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
          payload: {
            stateName: 'assessmentSectionItemDistinctRevise',
            value:
              assessmentResponseObject.informationFramework.assessmentSectionFramework[
                selectedBadgeName - 1
              ].assessmentVersionFramework[selectedTagValue]?.assessmentVersionItemDistinct[0]
          }
        });
      } else {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: ASSESSMENT_SECTION_PREIVEW_SEQUENCE_ERROR,
            popupMode: 'responseErrorMsg'
          }
        });
      }
    }
  };

  const onClickReview = (e, badge) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    const innerSelectedBadgeName = e.currentTarget.getAttribute('id');
    if (labelName === 'section' && selectedBadgeName !== '' && innerSelectedBadgeName === 'item') {
      dispatch({ type: SET_PANE_THREE_ASSESSMENT_VERSION_PREVIEW_MODE, payload: false });
      let selectedSecSection =
        assessmentResponseObject?.informationFramework?.assessmentSectionFramework[
          selectedBadgeName - 1
        ];
      let selectedVersion = selectedSecSection.assessmentVersionFramework[selectedTagValue];

      let reviseResponseObj = {
        countTotal: selectedVersion?.assessmentVersionItemDistinct.length || 0,
        responseObject: [
          {
            item: selectedVersion?.assessmentVersionItemDistinct || [],
            id: relatedReviewListDistinctData[0].id,
            assessmentSectionName: selectedVersion?.assessmentVersionName,
            assessmentSectionDescription: selectedVersion?.assessmentVersionDescription
          }
        ]
      };
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'relatedReviewListDistinctData', value: [] }
      });
      dispatch({
        type: RELATED_REVIEWLIST_DISTINCT_DATA,
        payload: reviseResponseObj.responseObject
      });
      dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
      dispatch({
        type: SET_MIDDLEPANE_STATE,
        payload: {
          middlePaneHeader: 'item',
          middlePaneHeaderBadgeOne: 'distinct',
          middlePaneHeaderBadgeTwo: 'active',
          middlePaneHeaderBadgeThree: '',
          middlePaneHeaderBadgeFour: '',
          typeOfMiddlePaneList: 'assessmentSectionItemDistinctReviewList',
          middlePaneSelectedValue: '',
          scanCount: reviseResponseObj && reviseResponseObj.countTotal,
          showMiddlePaneState: true
        }
      });
    }
    if (
      labelName === 'preview' &&
      selectedBadgeName === 'sections' &&
      innerSelectedBadgeName !== ''
    ) {
      let reviseResponseObj = {
        countTotal: responseObject?.assessmentVersionItemDistinct.length || 0,
        responseObject: [
          {
            item: responseObject?.assessmentVersionItemDistinct || [],
            id: relatedReviewListDistinctData[0].id,
            assessmentSectionName: responseObject.assessmentVersionName,
            assessmentSectionDescription: responseObject.assessmentVersionDescription
          }
        ]
      };
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'relatedReviewListDistinctData', value: [] }
      });
      dispatch({
        type: RELATED_REVIEWLIST_DISTINCT_DATA,
        payload: reviseResponseObj.responseObject
      });
      dispatch({
        type: SET_MIDDLEPANE_STATE,
        payload: {
          middlePaneHeader: 'item',
          middlePaneHeaderBadgeOne: 'distinct',
          middlePaneHeaderBadgeTwo: 'active',
          middlePaneHeaderBadgeThree: '',
          middlePaneHeaderBadgeFour: '',
          typeOfMiddlePaneList: 'assessmentSectionItemDistinctReviewList',
          middlePaneSelectedValue: '',
          scanCount: reviseResponseObj && reviseResponseObj.countTotal,
          showMiddlePaneState: true
        }
      });
    }
    if (
      labelName === 'section' &&
      selectedBadgeName !== '' &&
      innerSelectedBadgeName === 'preview'
    ) {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'assessmentSelecedSection', value: selectedBadgeName - 1 }
      });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'assessmentSelecedVersion', value: selectedTagValue }
      });
      // dispatch({
      //   type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
      //   payload: {
      //     stateName: 'assesseeAssignmentAssessmentData',
      //     value:
      //       assessmentResponseObject.informationFramework.assessmentSectionFramework[
      //         selectedBadgeName - 1
      //       ]
      //   }
      // });
      dispatch({
        type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
        payload: {
          stateName: 'assesseeAssessmentStartData',
          value:
            assessmentResponseObject.informationFramework.assessmentSectionFramework[
              selectedBadgeName - 1
            ].assessmentVersionFramework[selectedTagValue]
          //assesseeAssignmentAssessmentData?.assessmentVersionFramework[selectedTagValue]
        }
      });
      setItemTypeConfigState(
        assessmentResponseObject.informationFramework.assessmentSectionFramework[
          selectedBadgeName - 1
        ].assessmentVersionFramework[selectedTagValue].assessmentVersionItemDistinct[0]
          .itemFrameworkOne.itemFrameworkOneType,
        dispatch
      );
      dispatch({
        type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
        payload: {
          stateName: 'assessmentSectionItemDistinctRevise',
          value:
            assessmentResponseObject.informationFramework.assessmentSectionFramework[
              selectedBadgeName - 1
            ].assessmentVersionFramework[selectedTagValue]?.assessmentVersionItemDistinct[0]
        }
      });
      dispatch({
        type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
        payload: { stateName: 'isAssessmentStart', value: 'PROGRESS' }
      });
      setTimeout(function () {
        dispatch({ type: SET_PANE_THREE_ASSESSMENT_VERSION_PREVIEW_MODE, payload: true });
        dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneFive' });
      }, 1000);
    }
    if (
      labelName === 'preview' &&
      innerSelectedBadgeName !== '' &&
      selectedBadgeName === 'sections'
    ) {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'assessmentSelecedSection', value: innerSelectedBadgeName - 1 }
      });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'assessmentSelecedVersion', value: selectedTagValue }
      });
      dispatch({
        type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
        payload: {
          stateName: 'assesseeAssignmentAssessmentData',
          value:
            assessmentResponseObject.informationFramework.assessmentSectionFramework[
              innerSelectedBadgeName - 1
            ]
        }
      });
      dispatch({
        type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
        payload: { stateName: 'isAssessmentStart', value: 'VERSIONPREVIEW' }
      });
      setTimeout(function () {
        dispatch({ type: SET_PANE_THREE_ASSESSMENT_VERSION_PREVIEW_MODE, payload: true });
        dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneFive' });
      }, 1000);
    }
  };
  return (
    <div>
      <>
        <div className={'containerPadding'}>
          <Paper className={'dossierContainerTop'}>
            {frameworkAll.map((ob) => {
              return (
                <div key={ob.id}>
                  {/* {ob.isListCard ? (
                    <DisplayPanelAccordianReviewListOne
                      className=""
                      accordianObject={ob}
                      mode={reviewMode}
                      onClickReview={onClickReview}
                      onClickRevise={reviseFramework}
                    />
                  ) : (
                    <DisplayPanelAccordianInformation
                      accordianObject={ob}
                      mode={reviewMode}
                      onClickReview={onClickReview}
                      onClickRevise={reviseFramework}
                    />
                  )} */}
                  {ob.isListCard ? (
                    <>
                      {ob.isMultiList ? (
                        <DisplayPanelAccordianReviewListTwo
                          onClickReview={onClickReview}
                          onClickRevise={reviseFramework}
                          accordianObject={ob}
                          mode={reviewMode}
                        />
                      ) : (
                        <DisplayPanelAccordianReviewListOne
                          className=""
                          accordianObject={ob}
                          mode={reviewMode}
                          onClickReview={onClickReview}
                          onClickRevise={reviseFramework}
                        />
                      )}
                    </>
                  ) : (
                    <DisplayPanelAccordianInformation
                      accordianObject={ob}
                      mode={reviewMode}
                      onClickReview={onClickReview}
                      onClickRevise={reviseFramework}
                    />
                  )}
                </div>
              );
            })}
          </Paper>
        </div>
      </>
      {isMobile && (
        <div className={'containerPadding'} style={{ height: '55px' }}>
          {' '}
        </div>
      )}
    </div>
  );
};

export default DisplayPaneThreeSectionTwoAssessmentVersion;
