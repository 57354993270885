import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PopUpPicture from '../../PopUpInformation/PopUpPicture';
import PopUpTextField from '../../PopUpInformation/PopUpTextField';
import PopUpConfirm from '../../PopUpGeneric/PopUpConfirm';
import {
  POPUP_CLOSE,
  SET_ASSESSMENT_BASIC_REDUCER_STATE,
  CLEAR_ASSESSMENT_INFO,
  CREATE_ASSESSMENT_SAGA,
  LOADER_START,
  SET_ASSESSMENT_DYNAMIC_SINGLE_STATE,
  SET_DISPLAY_THREE_SINGLE_STATE,
  SET_ASSESSMENT_FRAMEWORK_STATE,
  SET_ASSESSMENT_SCORE_FRAMEWORK_STATE,
  UPDATE_ASSOCIATE_BASIC_INFO,
  UPDATE_ASSOCIATE_SETUP_INFO,
  SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
  SET_ASSESSMENT_AID_FRAMEWORK_STATE,
  SET_ASSESSMENT_ADMINISTRATOR_FRAMEWORK_STATE,
  SET_ASSESSMENT_FRAMEWORK_TIMELINE_STATE,
  ASSESSMENT_PUBLISH_SAGA,
  SET_ASSESSMENT_FRAMEWORK_TEMPLATE_ALIGNMENT_STATE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_POPUP_SINGLE_STATE,
  UPDATE_ASSESSMENT_SETUP_PRIMARY_INFO,
  SET_MIDDLEPANE_SECONDARY_OPTION,
  SET_POPUP_STATE,
  POPUP_OPEN,
  SET_SECONDARY_CREATE_OPTION_VALUE
} from '../../actionType';
import PopUpReviewList from '../../PopUpInformation/PopUpReviewList';
import PopUpDropList from '../../PopUpInformation/PopUpDropList';
import PopUpTextEditor from '../../PopUpIcon/PopUpTextEditor';
import PopUpCheckbox from '../../PopUpInformation/PopUpCheckbox';
import PopUpItemTemplate from '../../PopUpInformation/PopUpItemTemplate';
import PopUpTagSecondary from '../../PopUpInformation/PopUpTagSecondary';
import PopUpTimeFeild from '../../PopUpInformation/PopUpTimeFeild';
import { distinctGroupSharePopupOpen, getPopUpOptionList } from '../../Actions/GenericActions';
import { ASSESSMENT_REVIEW_LIST_POPUP_OPTION } from '../../PopUpConfig';
import { CAN_NOT_REVISED_MESSAGE } from '../../errorMessage';

const PopUpAssessmentCreate = (props) => {
  const { headerOne } = props;
  const {
    isPopUpValue,
    selectedTagValue,
    popupErrorMsg,
    duplicateHeaderOne,
    duplicateHeaderOneBadgeOne,
    selectedTagInformationSetup
  } = useSelector((state) => state.PopUpReducer);
  const [errorMsg, setErrorMsg] = useState('');
  const {
    selectedAssociateInfo,
    coreTypeReviewListData,
    coreGroupReviewListData,
    coreNodeReviewListData,
    indexPointer,
    signedAssesseePermission,
    typeOfMiddlePaneList,
    sectionCreateType,
    sectionCreateTypeSecondary
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const {
    informationBasic,
    informationAllocation,
    informationFramework,
    assessmentAdminSequence,
    informationSetup
  } = useSelector((state) => state.AssessmentReducer);
  const { responseObject, reviewMode, statusPopUpValue, createMode } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  const dispatch = useDispatch();
  const onClickCancelYes = () => {
    dispatch({
      type: SET_DISPLAY_THREE_SINGLE_STATE,
      payload: { stateName: 'createMode', value: '' }
    });
    dispatch({ type: CLEAR_ASSESSMENT_INFO });
    dispatch({ type: POPUP_CLOSE });
  };
  useEffect(() => {
    let tempTypeArr = coreTypeReviewListData.filter((tt) => {
      return tt.informationSetup?.assessmentTypeDefault;
    });
    if (tempTypeArr.length > 0) {
      let arr = tempTypeArr.map((ob) => ob.id);
      informationAllocation.assessmentType.assessmentTypePrimary = arr;
    }
  }, [coreTypeReviewListData, createMode]);

  useEffect(() => {
    if (sectionCreateType !== '') {
      let secondaryTypeValue = sectionCreateType + '_ASSESSMENTS';
      console.log('secondaryTypeValue--', secondaryTypeValue);
      console.log(
        `REACT_APP_${secondaryTypeValue.replace(' ', '_').toUpperCase()}`,
        '=',
        `${process.env[`REACT_APP_${secondaryTypeValue.replace(' ', '_').toUpperCase()}`]}`
      );
      let arrTypeSecondary = [];
      arrTypeSecondary.push(
        `${process.env[`REACT_APP_${secondaryTypeValue.replace(' ', '_').toUpperCase()}`]}`
      );
      console.log('arrTypeSecondary', arrTypeSecondary);
      console.log('b', informationAllocation.assessmentType.assessmentTypeSecondary);
      informationAllocation.assessmentType.assessmentTypeSecondary = arrTypeSecondary;
      console.log('A', informationAllocation.assessmentType.assessmentTypeSecondary);
    }
  }, [coreTypeReviewListData, createMode, sectionCreateTypeSecondary, sectionCreateType]);

  // useEffect(() => {

  //   if (sectionCreateTypeSecondary !== '' && sectionCreateType !== '') {
  //     let arrTypeSecondary = [];

  //     arrTypeSecondary.push(
  //       `${process.env[`REACT_APP_ASSESSMENT_${sectionCreateTypeSecondary.replace(' ', '_').toUpperCase()}`]}`
  //     );
  //     informationAllocation.assessmentType.assessmentTypeSecondary = arrTypeSecondary;
  //     // console.log(assesseeInfo.informationAllocation.assesseeType.assesseeTypeSecondary, assesseeInfo.informationAllocation)
  //   }
  // }, [sectionCreateType, sectionCreateTypeSecondary])
  let primaryListTypeArr = coreTypeReviewListData.filter((tt) => {
    return tt.informationSetup.assessmentTypeDefault;
  });
  console.log('primaryListTypeArr--', primaryListTypeArr);

  const onClickYesToForce = () => {
    let reqBody = {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      assessmentId: selectedTagValue,
      forceToPublish: true
    };
    dispatch({ type: LOADER_START });
    dispatch({
      type: ASSESSMENT_PUBLISH_SAGA,
      payload: {
        secondaryOptionCheckValue: '',
        hideRightpane: true,
        headerOne: '',
        reqBody
      }
    });
    dispatch({ type: POPUP_CLOSE });
  };
  let normReviewList = [];
  informationFramework?.assessmentNormFramework?.map((element, index) => {
    let obj = {
      id: element.id,
      name: element.assessmentNormName
    };
    normReviewList.push(obj);
  });
  const onClickYes = () => {
    // add default root node in allocation if node not selected
    if (informationAllocation.assessmentNode.assessmentNodePrimary.length === 0) {
      let rootNode = coreNodeReviewListData.filter((node) => {
        return node.informationFramework.associateNodeAscendantPrimary === null;
      });
      let rootNodeId = rootNode[0].id;
      informationAllocation.assessmentNode.assessmentNodePrimary = [
        ...informationAllocation.assessmentNode.assessmentNodePrimary,
        rootNodeId
      ];
    }
    let reqBody = {
      module: 'Assessment-Distinct',
      action: 'Create',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        assessment: {
          informationBasic: informationBasic,
          informationAllocation: informationAllocation,
          informationFramework:
            delete informationFramework.assessmentSectionItemDistinctRevise && informationFramework
        }
      }
    };
    dispatch({ type: LOADER_START });
    dispatch({ type: CREATE_ASSESSMENT_SAGA, payload: reqBody });
    let assessmentDistictPermission =
      signedAssesseePermission?.assessmentDistictPermission?.assesseeAssessmentDistinctPermission;
    let popupContentArrValue = getPopUpOptionList(
      ASSESSMENT_REVIEW_LIST_POPUP_OPTION,
      assessmentDistictPermission
    );
    let finalPopupOptionArray = [];
    popupContentArrValue.forEach((element) => {
      if (element.data === 'share') {
        finalPopupOptionArray.push({ ...element, disabled: true });
      } else {
        finalPopupOptionArray.push(element);
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: finalPopupOptionArray
      }
    });
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: {
        stateName: 'popupOptionArr',
        value: finalPopupOptionArray
      }
    });
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: {
        stateName: 'originPrimaryArrOprion',
        value: finalPopupOptionArray
      }
    });
  };
  const updateAllocationObj = (e, stateName, actualStateName) => {
    let id = e.currentTarget.getAttribute('tag');
    let arr = informationAllocation[stateName][actualStateName];
    let isDefault = e.currentTarget.getAttribute('data-default');
    setErrorMsg('');
    if (isDefault !== 'true') {
      if (arr.includes(id)) {
        document.getElementById(id).style.backgroundColor = 'white';
        arr = arr.filter(function (number) {
          return number !== id;
        });
      } else {
        arr.push(id);
        document.getElementById(id).style.backgroundColor = '#F0F0F0';
      }
      dispatch({
        type: SET_ASSESSMENT_DYNAMIC_SINGLE_STATE,
        payload: {
          stateName: stateName,
          actualStateName: actualStateName,
          value: arr
        }
      });
    }
  };
  let selectedPrimaryGroup = informationAllocation?.assessmentGroup.assessmentGroupPrimary || [];
  let selectedSecondaryGroup =
    informationAllocation?.assessmentGroup.assessmentGroupSecondary || [];
  let filteredCoreGroupReviewListDataPrimary = [];
  if (coreGroupReviewListData && coreGroupReviewListData.length > 0) {
    coreGroupReviewListData.forEach((group) => {
      // for primary popup list
      if (!selectedSecondaryGroup.includes(group.id))
        filteredCoreGroupReviewListDataPrimary.push(group);
    });
  }
  let filteredCoreGroupReviewListDataSecondary = [];
  if (coreGroupReviewListData && coreGroupReviewListData.length > 0) {
    coreGroupReviewListData.forEach((group) => {
      // for Secondary popup list
      if (!selectedPrimaryGroup.includes(group.id))
        filteredCoreGroupReviewListDataSecondary.push(group);
    });
  }
  let versionArr = [];
  informationFramework?.assessmentSectionFramework[0]?.assessmentVersionFramework?.map(
    (ver, index) => {
      versionArr.push({
        id: index + 1,
        name: ver.assessmentVersionName + ' ' + ver.assessmentVersionDescription
      });
    }
  );
  // const onClickShareFee = () => {
  //   distinctGroupSharePopupOpen(
  //     dispatch,
  //     selectedTagValue,
  //     duplicateHeaderOne,
  //     duplicateHeaderOneBadgeOne
  //   );
  // };
  return (
    <div>
      {isPopUpValue === 'NAMEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'name'}
          actualLableValue={'assessmentName'}
          actualLableValueVerification={'assessmentNameVerification'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          headerOneBadgeTwo={''}
          nextPopUpValue={'ALIASPOPUP'}
          basicInfo={informationBasic}
          typeOfSetObject={SET_ASSESSMENT_BASIC_REDUCER_STATE}
          isRequired={true}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          errorMsg={popupErrorMsg}
          isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ALIASPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'description'}
          actualLableValue={'assessmentDescription'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          headerOneBadgeTwo={''}
          basicInfo={informationBasic}
          nextPopUpValue={'PICTUREPOPUP'}
          typeOfSetObject={SET_ASSESSMENT_BASIC_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList'}
        />
      )}
      {isPopUpValue === 'NOTATIONPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'notation'}
          actualLableValue={'assessmentNotation'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          headerOneBadgeTwo={''}
          basicInfo={informationBasic}
          nextPopUpValue={'PICTUREPOPUP'}
          typeOfSetObject={SET_ASSESSMENT_BASIC_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList'}
        />
      )}
      {isPopUpValue === 'PICTUREPOPUP' && (
        <PopUpPicture
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          headerOneBadgeTwo={''}
          nextPopUpValue={'GROUPPOPUP'}
          actualLableValue={'assessmentPicture'}
          actualLableValueVerification={'assessmentPictureVerification'}
          basicInfo={informationBasic}
          typeOfSetObject={SET_ASSESSMENT_BASIC_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'GROUPPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'NODEPOPUP'}
          inputHeader={'group'}
          inputHeaderBadge={'primary'}
          infoMsg={'select a group'}
          ListData={filteredCoreGroupReviewListDataPrimary}
          textOne={'assessmentGroupName'}
          textTwo={'assessmentGroupDescription'}
          onClickEvent={(e) => {
            updateAllocationObj(e, 'assessmentGroup', 'assessmentGroupPrimary');
          }}
          selectedList={informationAllocation.assessmentGroup.assessmentGroupPrimary}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList'}
          useLocalState={true}
          stateName={`assessmentGroup`}
          actualStateName={`assessmentGroupPrimary`}
          objectName={'informationAllocation'}
          updateState={SET_ASSESSMENT_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'GROUPSECONDARYPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'NODEPOPUP'}
          inputHeader={'group'}
          inputHeaderBadge={'secondary'}
          infoMsg={'select a group'}
          ListData={filteredCoreGroupReviewListDataSecondary}
          textOne={'assessmentGroupName'}
          textTwo={'assessmentGroupDescription'}
          onClickEvent={(e) => {
            updateAllocationObj(e, 'assessmentGroup', 'assessmentGroupSecondary');
          }}
          selectedList={informationAllocation.assessmentGroup.assessmentGroupSecondary}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList'}
          useLocalState={true}
          stateName={`assessmentGroup`}
          actualStateName={`assessmentGroupSecondary`}
          objectName={'informationAllocation'}
          updateState={SET_ASSESSMENT_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'NODEPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'TYPEPOPUP'}
          inputHeader={'node'}
          inputHeaderBadge={'primary'}
          isRequired={true}
          minimumSelected={1}
          setErrorMsg={setErrorMsg}
          errorMsg={errorMsg}
          infoMsg={'select a node'}
          ListData={coreNodeReviewListData}
          textOne={'associateNodeName'}
          textTwo={'associateNodeDescription'}
          onClickEvent={(e) => {
            updateAllocationObj(e, 'assessmentNode', 'assessmentNodePrimary');
          }}
          selectedList={informationAllocation.assessmentNode.assessmentNodePrimary}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList'}
          useLocalState={true}
          stateName={`assessmentNode`}
          actualStateName={`assessmentNodePrimary`}
          objectName={'informationAllocation'}
          updateState={SET_ASSESSMENT_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'NODESECONDARYPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'TYPEPOPUP'}
          inputHeader={'node'}
          inputHeaderBadge={'secondary'}
          infoMsg={'select a node'}
          ListData={coreNodeReviewListData}
          textOne={'associateNodeName'}
          textTwo={'associateNodeDescription'}
          onClickEvent={(e) => {
            updateAllocationObj(e, 'assessmentNode', 'assessmentNodeSecondary');
          }}
          selectedList={informationAllocation.assessmentNode.assessmentNodeSecondary}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList'}
          useLocalState={true}
          stateName={`assessmentNode`}
          actualStateName={`assessmentNodeSecondary`}
          objectName={'informationAllocation'}
          updateState={SET_ASSESSMENT_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'TYPEPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'CONFIRMATIONPOPUP'}
          inputHeader={'type'}
          inputHeaderBadge={'primary'}
          infoMsg={'select a type'}
          ListData={coreTypeReviewListData.filter((tt) => {
            return tt.informationSetup.assessmentTypeDefault;
          })}
          textOne={'assessmentTypeName'}
          dataDefault={'assessmentTypeDefault'}
          // isRequired={true}
          // minimumSelected={1}
          setErrorMsg={setErrorMsg}
          errorMsg={errorMsg}
          textTwo={'assessmentTypeDescription'}
          onClickEvent={(e) => {
            updateAllocationObj(e, 'assessmentType', 'assessmentTypePrimary');
          }}
          selectedList={informationAllocation.assessmentType.assessmentTypePrimary}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList'}
          useLocalState={true}
          stateName={`assessmentType`}
          actualStateName={`assessmentTypePrimary`}
          objectName={'informationAllocation'}
          updateState={SET_ASSESSMENT_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'TYPESECONDARYPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'CONFIRMATIONPOPUP'}
          inputHeader={'type'}
          inputHeaderBadge={'secondary'}
          infoMsg={'select a type'}
          ListData={coreTypeReviewListData.filter((tt) => {
            return !tt.informationSetup.assessmentTypeDefault;
          })}
          textOne={'assessmentTypeName'}
          textTwo={'assessmentTypeDescription'}
          onClickEvent={(e) => {
            updateAllocationObj(e, 'assessmentType', 'assessmentTypeSecondary');
          }}
          selectedList={informationAllocation.assessmentType.assessmentTypeSecondary}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList'}
          useLocalState={true}
          stateName={`assessmentType`}
          actualStateName={`assessmentTypeSecondary`}
          objectName={'informationAllocation'}
          updateState={SET_ASSESSMENT_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'TIMEASSESSMENTPOPUP' && (
        <PopUpTimeFeild
          isActive={true}
          label={'time'}
          type={'time'}
          actualLableValue={'assessmentTime'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          headerOneBadgeTwo={''}
          basicInfo={informationFramework}
          nextPopUpValue={''}
          typeOfSetObject={SET_ASSESSMENT_FRAMEWORK_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList'}
          issetTime={true}
          errorMsg={CAN_NOT_REVISED_MESSAGE}
        />
      )}
      {isPopUpValue === 'SCOREMINIMUMPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'score'}
          labelBadgeOne={'minimum'}
          type={'number'}
          actualLableValue={'assessmentScoreExtremumMinimum'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          headerOneBadgeTwo={''}
          basicInfo={informationFramework.assessmentScore}
          nextPopUpValue={''}
          typeOfSetObject={SET_ASSESSMENT_SCORE_FRAMEWORK_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList'}
        />
      )}

      {isPopUpValue === 'SCOREMAXIMUMPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'score'}
          labelBadgeOne={'maximum'}
          type={'number'}
          actualLableValue={'assessmentScoreExtremumMaximum'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          headerOneBadgeTwo={''}
          basicInfo={informationFramework.assessmentScore}
          nextPopUpValue={''}
          typeOfSetObject={SET_ASSESSMENT_SCORE_FRAMEWORK_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList'}
        />
      )}
      {isPopUpValue === 'SCOREMEANPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'mean'}
          labelBadgeOne={''}
          type={'number'}
          actualLableValue={'assessmentScoreMean'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          headerOneBadgeTwo={''}
          basicInfo={informationFramework.assessmentScore}
          nextPopUpValue={''}
          typeOfSetObject={SET_ASSESSMENT_SCORE_FRAMEWORK_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList'}
        />
      )}
      {isPopUpValue === 'SCOREMEDIANPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'median'}
          labelBadgeOne={''}
          type={'number'}
          actualLableValue={'assessmentScoreMedian'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          headerOneBadgeTwo={''}
          basicInfo={informationFramework.assessmentScore}
          nextPopUpValue={''}
          typeOfSetObject={SET_ASSESSMENT_SCORE_FRAMEWORK_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList'}
        />
      )}
      {isPopUpValue === 'SCORENEGATIVEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'nagative'}
          labelBadgeOne={''}
          type={'number'}
          actualLableValue={'assessmentScoreNegative'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          headerOneBadgeTwo={''}
          basicInfo={informationFramework.assessmentScore}
          nextPopUpValue={''}
          typeOfSetObject={SET_ASSESSMENT_SCORE_FRAMEWORK_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList'}
        />
      )}
      {isPopUpValue === 'SCORESTDDEVIATIONPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'standard deviation'}
          labelBadgeOne={''}
          type={'number'}
          actualLableValue={'assessmentScoreStandardDeviation'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          headerOneBadgeTwo={''}
          basicInfo={informationFramework.assessmentScore}
          nextPopUpValue={''}
          typeOfSetObject={SET_ASSESSMENT_SCORE_FRAMEWORK_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList'}
        />
      )}
      {isPopUpValue === 'ASSESSMENT_COMMUNIQUE_PRIMARY_TEXTSHEET_POPUP' && (
        <PopUpTextEditor
          isActive={true}
          headerOne={'assessment'}
          headerPanelColour={'genericOne'}
          headerOneBadgeOne={'communiqué'}
          headerOneBadgeTwo={indexPointer}
          defaultSheetValue={informationFramework.assessmentCommunique[indexPointer - 1]}
          onClickSave={(data) => {
            // if (data === '') {
            //   informationFramework.assessmentCommunique.splice(indexPointer - 1, 1);
            // }
            // dispatch({
            //   type: SET_ASSESSMENT_COMMUNIQUE_FRAMEWORK_STATE,
            //   payload: data
            // });

            let assessmentCommunique = informationFramework.assessmentCommunique;
            if (assessmentCommunique[indexPointer - 1]) {
              if (data !== '') {
                assessmentCommunique.splice(indexPointer - 1, 1, data);
              } else {
                assessmentCommunique.splice(indexPointer - 1, 1);
              }
            } else if (data !== '') {
              assessmentCommunique.push(data);
            } else {
            }
            dispatch({
              type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
              payload: {
                stateName: 'assessmentCommunique',
                value: assessmentCommunique
              }
            });
          }}
          actualLableValue={indexPointer - 1}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList'}
        />
      )}
      {isPopUpValue === 'ASSESSMENT_MANUSCRIPT_PRIMARY_TEXTSHEET_POPUP' && (
        <PopUpTextEditor
          isActive={true}
          headerOne={'assessment'}
          headerPanelColour={'genericOne'}
          headerOneBadgeOne={'manuscript'}
          headerOneBadgeTwo={indexPointer}
          defaultSheetValue={informationFramework?.assessmentManuscript[indexPointer - 1] || ''}
          actualLableValue={indexPointer - 1}
          onClickSave={(data) => {
            // if (data === '') {
            //   informationFramework.assessmentManuscript.splice(indexPointer - 1, 1);
            // }
            // dispatch({ type: SET_ASSESSMENT_MANUSCRIPT_FRAMEWORK_STATE, payload: data });
            let assessmentManuscript = informationFramework.assessmentManuscript;
            if (assessmentManuscript[indexPointer - 1]) {
              if (data !== '') {
                assessmentManuscript.splice(indexPointer - 1, 1, data);
              } else {
                assessmentManuscript.splice(indexPointer - 1, 1);
              }
            } else if (data !== '') {
              assessmentManuscript.push(data);
            } else {
            }
            dispatch({
              type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
              payload: {
                stateName: 'assessmentManuscript',
                value: assessmentManuscript
              }
            });
          }}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList'}
        />
      )}
      {isPopUpValue === 'ASSESSMENT_SYNOPSIS_TEXTSHEET_POPUP' && (
        <PopUpTextEditor
          isActive={true}
          headerOne={'assessment'}
          headerPanelColour={'genericOne'}
          headerOneBadgeOne={'synopsis'}
          headerOneBadgeTwo={indexPointer}
          defaultSheetValue={informationFramework?.assessmentSynopsis[indexPointer - 1] || ''}
          actualLableValue={indexPointer - 1}
          onClickSave={(data) => {
            // if (data === '') {
            //   informationFramework.assessmentSynopsis.splice(indexPointer - 1, 1);
            // }
            // dispatch({ type: SET_ASSESSMENT_SYNOPSIS_FRAMEWORK_STATE, payload: data });

            let assessmentSynopsis = informationFramework.assessmentSynopsis;
            if (assessmentSynopsis[indexPointer - 1]) {
              if (data !== '') {
                assessmentSynopsis.splice(indexPointer - 1, 1, data);
              } else {
                assessmentSynopsis.splice(indexPointer - 1, 1);
              }
            } else if (data !== '') {
              assessmentSynopsis.push(data);
            } else {
            }
            dispatch({
              type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
              payload: {
                stateName: 'assessmentSynopsis',
                value: assessmentSynopsis
              }
            });
          }}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList'}
        />
      )}
      {isPopUpValue === 'TIMELINESTARTPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'timeline'}
          type={'datetime-local'}
          labelBadgeOne={'start'}
          actualLableValue={'assessmentTimelineDateTimeStart'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          basicInfo={informationFramework?.assessmentTimeline || 'mm/dd/yyyy --:-- --'}
          nextPopUpValue={''}
          isNotRevised={false}
          typeOfSetObject={SET_ASSESSMENT_FRAMEWORK_TIMELINE_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TIMELINEENDPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'timeline'}
          labelBadgeOne={'end'}
          type={'datetime-local'}
          actualLableValue={'assessmentTimelineDateTimeEnd'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          basicInfo={informationFramework?.assessmentTimeline || 'mm/dd/yyyy --:-- --'}
          nextPopUpValue={''}
          typeOfSetObject={SET_ASSESSMENT_FRAMEWORK_TIMELINE_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ASSOCIATECREATEFEEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'fee'}
          headerPanelColour={'genericOne'}
          inputHeader={'assessment'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'create'}
          type={'number'}
          headerOne={'assessment'}
          headerOneBadgeOne={'setup'}
          isRequired={false}
          actualLableValue={'assesseeCreateFee'}
          basicInfo={{}}
          typeOfSetObject={UPDATE_ASSOCIATE_BASIC_INFO}
          nextPopUpValue={'ASSOCIATEPICTUREPOPUP'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList'}
        />
      )}
      {isPopUpValue === 'ASSESSMENTINFODISTINCTBASICPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assesseeCreateApproval'}
          label={'information'}
          labelBadgeOne={'basic'}
          listSelect={[
            { id: 'a', name: 'Unique Name & Description Not Rquired' },
            { id: 'b', name: 'Unique Name + Description Required' },
            { id: 'c', name: 'Unique Name Required' }
          ]}
          mappingValue={'id'}
          inputHeader={'assessments'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'information'}
          labelval={'time'}
          headerPanelColour={'genericOne'}
          headerOne={'assessment'}
          headerOneBadgeOne={'setup'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={{}}
          typeOfSetObject={UPDATE_ASSOCIATE_SETUP_INFO}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList'}
        />
      )}
      {isPopUpValue === 'CANCELPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'cancel'}
          headerOneBadgeOne={''}
          mode={'cancel'}
          onClickYes={onClickCancelYes}
        />
      )}
      {isPopUpValue === 'CONFIRMATIONPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'create'}
          headerOneBadgeTwo={''}
          onClickYes={onClickYes}
          onClickNoFun={onClickCancelYes}
        />
      )}
      {isPopUpValue === 'FORCE_TO_PUBLISH' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'displayPaneCentre'}
          headerOne={headerOne}
          headerOneBadgeOne={'publish'}
          headerOneBadgeTwo={''}
          mode={'cancel'}
          onClickYes={onClickYesToForce}
        />
      )}
      {isPopUpValue === 'NAVIGATIONPOPUP' && (
        <PopUpCheckbox
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          headerOneBadgeTwo={''}
          inputHeader={'navigation'}
          inputHeaderBadge={'item'}
          typeOfStateObj={null}
          objectName={'assesseeRole'}
          stateName={'assessmentNavigation'}
          valueArr={['first', 'last', 'next', 'previous', 'skip']}
          nextPopUpValue={''}
          isRolePermission
          typeOfSetObject={SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE}
          valueArrState={informationFramework?.assessmentNavigation}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList'}
        />
      )}
      {isPopUpValue === 'REVISIONPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={''}
          label={'response'}
          listSelect={[
            { id: false, name: 'Enable' },
            { id: true, name: 'Disable' }
          ]}
          mappingValue={'id'}
          inputHeader={'revision'}
          inputHeaderBadgeOne={''}
          inputHeaderBadgeTwo={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={null}
          // typeOfSetObject={SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList'}
        />
      )}
      {isPopUpValue === 'AID_CAL_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentAidCalculatorPermission'}
          label={'calculator'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeaderBadgeOne={''}
          inputHeaderBadgeTwo={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={informationFramework?.assessmentAid}
          typeOfSetObject={SET_ASSESSMENT_AID_FRAMEWORK_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList'}
        />
      )}
      {isPopUpValue === 'AID_SHEET_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentAidSpreadsheetPermission'}
          label={'spredsheet'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeaderBadgeOne={''}
          inputHeaderBadgeTwo={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={informationFramework?.assessmentAid}
          typeOfSetObject={SET_ASSESSMENT_AID_FRAMEWORK_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList'}
        />
      )}
      {isPopUpValue === 'AID_TEXT_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentAidTextsheetPermission'}
          label={'textsheet'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={informationFramework?.assessmentAid}
          typeOfSetObject={SET_ASSESSMENT_AID_FRAMEWORK_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList'}
        />
      )}
      {isPopUpValue === 'AID_CALCULATOR_TYPE_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentAidCalculatorType'}
          label={'type'}
          listSelect={[
            { id: 'basic', name: 'basic' },
            { id: 'business', name: 'business' },
            { id: 'financial', name: 'financial' },
            { id: 'scientific', name: 'scientific' }
          ]}
          mappingValue={'id'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={informationFramework?.assessmentAid}
          typeOfSetObject={SET_ASSESSMENT_AID_FRAMEWORK_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList'}
        />
      )}
      {isPopUpValue === 'AID_SPREADSHEET_TYPE_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentAidSpreadsheetType'}
          label={'type'}
          listSelect={[
            { id: 'goole', name: 'goole' },
            { id: 'microsoft', name: 'microsoft' },
            { id: 'spreadsheet', name: 'spreadsheet' }
          ]}
          mappingValue={'id'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={informationFramework?.assessmentAid}
          typeOfSetObject={SET_ASSESSMENT_AID_FRAMEWORK_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList'}
        />
      )}
      {isPopUpValue === 'AID_TEXTSHEET_TYPE_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentAidTextsheetType'}
          label={'type'}
          listSelect={[
            { id: 'goole', name: 'goole' },
            { id: 'microsoft', name: 'microsoft' },
            { id: 'textsheet', name: 'textsheet' }
          ]}
          mappingValue={'id'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={informationFramework?.assessmentAid}
          typeOfSetObject={SET_ASSESSMENT_AID_FRAMEWORK_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList'}
        />
      )}
      {isPopUpValue === 'ADMINPROCTORPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentAdministrationProctor'}
          label={'proctor'}
          listSelect={[
            { id: false, name: 'No' },
            { id: true, name: 'Yes' }
          ]}
          mappingValue={'id'}
          inputHeaderBadgeOne={''}
          inputHeaderBadgeTwo={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={informationFramework}
          typeOfSetObject={SET_ASSESSMENT_FRAMEWORK_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ADMINSUPERVISEPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentAdministrationSupervise'}
          label={'supervise'}
          listSelect={[
            { id: false, name: 'No' },
            { id: true, name: 'Yes' }
          ]}
          mappingValue={'id'}
          inputHeaderBadgeOne={''}
          inputHeaderBadgeTwo={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={informationFramework}
          typeOfSetObject={SET_ASSESSMENT_FRAMEWORK_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ADMINTEMPLATEPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentAdministrationTemplate'}
          label={'template'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeaderBadgeOne={''}
          inputHeaderBadgeTwo={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={informationFramework}
          typeOfSetObject={SET_ASSESSMENT_FRAMEWORK_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ADMINVERSIONPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentAdministrationVersionType'}
          label={'version type'}
          listSelect={[
            { id: 'VERSION_HYBRID', name: 'assessment (version) (hybrid)' },
            {
              id: 'VERSION_STATIC',
              name: 'assessment (version) (static) (single)'
            },
            {
              id: 'VERSION_STATIC_RANDOM',
              name: 'assessment (version) (static) (multiple)'
            }
            // { id: 'VersionDynamic', name: 'Version (dynamic)' },
            // { id: 'VersionHybrid', name: 'Version (hybrid)' },
            // { id: 'VersionStatic', name: 'Version (static)' }
            // { id: 'Fixed', name: 'Fixed' },
            // { id: 'Mixed', name: 'Mixed' },
            // { id: 'Progression', name: 'Progression' },
            // { id: 'Random', name: 'Random' }
          ]}
          mappingValue={'id'}
          inputHeaderBadgeOne={''}
          inputHeaderBadgeTwo={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={informationFramework.assessmentAdministrationVersion}
          typeOfSetObject={SET_ASSESSMENT_ADMINISTRATOR_FRAMEWORK_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ADMINVERSIONNUMBERPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentAdministrationVersionNumber'}
          label={'version'}
          listSelect={versionArr}
          mappingValue={'id'}
          inputHeaderBadgeOne={''}
          inputHeaderBadgeTwo={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          isRequired={false}
          nextPopUpValue={''}
          isCheckboxDisplay={true}
          isCheckboxLabel={'random'}
          isCheckboxState={'assessmentAdministrationVersionType'}
          basicInfo={informationFramework.assessmentAdministrationVersion}
          typeOfSetObject={SET_ASSESSMENT_ADMINISTRATOR_FRAMEWORK_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TIMELINEPLUSPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentTimelineType'}
          label={'timeline'}
          listSelect={[
            { id: 'ANYTIME', name: 'Anytime' },
            { id: 'DATE', name: 'Date' },
            { id: 'TIMELINE', name: 'Timeline' }
          ]}
          mappingValue={'id'}
          labelBadgeOne={'+'}
          inputHeaderBadgeTwo={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          isRequired={false}
          nextPopUpValue={''}
          basicInfo={informationFramework.assessmentTimeline}
          typeOfSetObject={SET_ASSESSMENT_FRAMEWORK_TIMELINE_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ADMINSEWUENCEPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentAdministrationSequence'}
          label={'sequence'}
          listSelect={assessmentAdminSequence}
          mappingValue={'id'}
          isMultiSelect={true}
          inputHeaderBadgeOne={''}
          inputHeaderBadgeTwo={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={''}
          basicInfo={informationFramework}
          typeOfSetObject={SET_ASSESSMENT_FRAMEWORK_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList'}
        />
      )}
      {isPopUpValue === 'ADMIN_SHUFFLE_POPUP' && (
        <PopUpCheckbox
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          inputHeader={'shuffle'}
          valueArr={['item', 'response-choice']}
          basicInfo={informationFramework?.assessmentAdministrationShuffle}
          valueArrState={informationFramework?.assessmentAdministrationShuffle}
          nextPopUpValue={'MOBILETELEPHONEPOPUP'}
          isObjectUpdate
          typeOfSetObject={SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE}
          stateArr={[
            'assessmentAdministrationShuffleItem',
            'assessmentAdministrationShuffleResponseChoice'
          ]}
          stateName={'assessmentAdministrationShuffle'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {/* templatepopup */}

      {/* {isPopUpValue === 'ASSESSMENTADMINISTERTEMPLATE' && (
        <PopUp isActive={true}>
          <PopupHeader
            headerPanelColour={'genericOne'}
            headerOne={headerOne}
            headerOneBadgeOne={'information'}
            onClick={BackHandlerEvent}
            mode={'revise'}
          />
          <DialogContent className={['popupContent', 'fixed05PadDim'].join(' ')}>
            <JsonRenderComponent
              setSecondaryOptionValue={setSecondaryOptionValue}
              ChangeOptionPopup={ChangeTripleDotOptionPopup}
              currentPopUpOption={itemPrimaryTriplePopupOption}
              secondaryOptionCheckValue={''}
            />
          </DialogContent>
        </PopUp>
      )} */}
      {isPopUpValue === 'ASSESSMENTMEDIA' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentItemFrameworkOneResponseChoiceAlignment'}
          label={'response-choice'}
          labelBadgeOne={'alignment'}
          listSelect={[
            { id: '', name: '' },
            { id: 'horizontal', name: 'horizontal' },
            { id: 'vertical', name: 'vertical' }
          ]}
          mappingValue={'id'}
          inputHeaderBadgeOne={''}
          inputHeaderBadgeTwo={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={informationFramework?.assessmentAdministrationTemplateAlignment}
          typeOfSetObject={SET_ASSESSMENT_FRAMEWORK_TEMPLATE_ALIGNMENT_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TEMPLATEPOPUP' && (
        <PopUpItemTemplate
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          basicInfo={informationFramework?.assessmentAdministrationTemplateMedia}
          typeOfSetObject={SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE}
          setDependantObj={'assessmentAdministrationTemplateMedia'}
          setDependantObjOne={'assessmentAdministrationTemplateMediaHtml'}
          nextPopUpValue={''}
          defaultSheetValue={informationFramework?.assessmentAdministrationTemplateMediaHtml}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'STATUSPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'itemStatus'}
          label={'status'}
          listSelect={[
            { id: 'Active', name: 'Active' },
            { id: 'Suspended', name: 'Suspended' },
            { id: 'Terminated', name: 'Terminated' },
            { id: 'Unverified', name: 'Unverified' },
            { id: 'Confirmed', name: 'Confirmed' },
            { id: 'Unconfirmed', name: 'Unconfirmed' },
            { id: 'Unpublished', name: 'Unpublished' },
            { id: 'Published', name: 'Published' }
          ]}
          mappingValue={'id'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          isRequired={true}
          basicInfo={statusPopUpValue}
          nextPopUpValue={''}
          //typeOfSetObject={SET_ITEM_DYNAMIC_SINGLE_STATE}
          isNotRevised={true}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TENURESATRTDATEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tenure'}
          labelBadgeOne={'start'}
          actualLableValue={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          basicInfo={
            responseObject?.informationEngagement?.assessmentTenure
              ?.assessmentTenureDateTimeStart || 'mm/dd/yyyy --:-- --'
          }
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={''}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TENUREENDDATEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tenure'}
          labelBadgeOne={'end'}
          actualLableValue={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          basicInfo={
            responseObject?.informationEngagement?.assessmentTenure?.assessmentTenureDateTimeEnd ||
            'mm/dd/yyyy --:-- --'
          }
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={''}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TAGREADONLYPRIMARYPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tag'}
          labelBadgeOne={'primary'}
          actualLableValue={'associateTagPrimary'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          basicInfo={
            responseObject?.informationEngagement?.assessmentTag?.assessmentTagPrimary || ''
          }
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={''}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TAGSECONDARYPOPUP' && (
        <PopUpTagSecondary
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          tagSecondary={responseObject?.informationEngagement}
          signInSetup={responseObject?.informationSetup}
          nextPopUpValue={'CONFIRMATIONPOPUP'}
          typeOfSetObject={''}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList'}
        />
      )}
      {isPopUpValue === 'ITEMTOTALPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'total'}
          labelBadgeOne={''}
          type={'number'}
          actualLableValue={'assessmentItemTotal'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          headerOneBadgeTwo={''}
          basicInfo={informationFramework?.assessmentItemTotal || 0}
          isNotRevised={true}
          nextPopUpValue={''}
          typeOfSetObject={''}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'NORMPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={''}
          inputHeader={'norm'}
          inputHeaderBadge={''}
          infoMsg={'assessment norm'}
          ListData={informationFramework?.assessmentNormFrameworkp}
          textOne={'assessmentNormName'}
          textTwo={'assessmentNormDescription'}
          selectedList={[]}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList'}
        />
        // <PopUpDropList
        //   isActive={true}
        //   tag={'assessmentNormFrameworkSelected'}
        //   label={'norm'}
        //   listSelect={normReviewList}
        //   mappingValue={'id'}
        //   inputHeaderBadgeTwo={''}
        //   headerPanelColour={'genericOne'}
        //   headerOne={headerOne}
        //   headerOneBadgeOne={'information'}
        //   isRequired={false}
        //   nextPopUpValue={''}
        //   basicInfo={informationFramework}
        //   typeOfSetObject={SET_ASSESSMENT_FRAMEWORK_STATE}
        //   //typeOfSetObject={SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE}
        //   mode={reviewMode === 'revise' ? 'revise' : 'core'}
        //   // isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList'}
        // />
      )}
      {isPopUpValue === 'NORM_SELECTED_POPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={''}
          inputHeader={'norm'}
          inputHeaderBadge={'selected'}
          infoMsg={'selected norm'}
          ListData={informationFramework?.assessmentNormFramework.filter((norm) => {
            return norm.id === informationFramework?.assessmentNormFrameworkSelected;
          })}
          textOne={'assessmentNormName'}
          textTwo={'assessmentNormDescription'}
          selectedList={[informationFramework?.assessmentNormFrameworkSelected]}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList'}
        />
      )}
      {isPopUpValue === 'INFO_NOT_REVISE_POPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={''}
          inputHeader={''}
          inputHeaderBadge={''}
          infoMsg={''}
          ListData={[]}
          textOne={''}
          textTwo={''}
          selectedList={[]}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={true}
          errorMsg={CAN_NOT_REVISED_MESSAGE}
        />
      )}
      {isPopUpValue === 'INFO_cluster_NOT_REVISE_POPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={''}
          inputHeader={'cluster'}
          inputHeaderBadge={''}
          infoMsg={'cluster'}
          ListData={informationFramework?.assessmentClusterFramework}
          textOne={'assessmentClusterName'}
          textTwo={'assessmentClusterDescription'}
          selectedList={[]}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={true}
          errorMsg={CAN_NOT_REVISED_MESSAGE}
        />
      )}
      {isPopUpValue === 'INFO_grade_NOT_REVISE_POPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={''}
          inputHeader={'grade'}
          inputHeaderBadge={''}
          infoMsg={'grade'}
          ListData={informationFramework?.assessmentScoreGradeFramework}
          textOne={'assessmentGradeName'}
          textTwo={'assessmentGradeDescription'}
          selectedList={[]}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={true}
          errorMsg={CAN_NOT_REVISED_MESSAGE}
        />
      )}
      {isPopUpValue === 'INFO_scale_NOT_REVISE_POPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={''}
          inputHeader={'scale'}
          inputHeaderBadge={''}
          infoMsg={'scale'}
          ListData={informationFramework?.assessmentScaleFramework}
          textOne={'assessmentScaleName'}
          textTwo={'assessmentScaleDescription'}
          selectedList={[]}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={true}
          errorMsg={CAN_NOT_REVISED_MESSAGE}
        />
      )}
      {isPopUpValue === 'POLARITY_NOT_REVISE_POPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={''}
          inputHeader={'polarity'}
          inputHeaderBadge={''}
          infoMsg={'polarity'}
          ListData={[
            { id: '01', informationBasic: { name: 'Negative', description: '' } },
            { id: '02', informationBasic: { name: 'Positive', description: '' } }
          ]}
          textOne={'name'}
          textTwo={'description'}
          selectedList={[]}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={true}
          errorMsg={CAN_NOT_REVISED_MESSAGE}
        />
      )}
      {isPopUpValue === 'SHAREASSOCIATECREATEFEEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'fee'}
          headerPanelColour={'genericOne'}
          inputHeader={'share'}
          inputHeaderBadgeOne={'associate'}
          inputHeaderBadgeTwo={'fee'}
          type={'number'}
          headerOne={'assessment'}
          headerOneBadgeOne={'setup'}
          isRequired={false}
          actualLableValue={'assesseeCreateFee'}
          basicInfo={{}}
          typeOfSetObject={UPDATE_ASSOCIATE_BASIC_INFO}
          nextPopUpValue={'ASSOCIATEPICTUREPOPUP'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList'}
        />
      )}
      {isPopUpValue === 'SHARENODECREATEFEEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'fee'}
          headerPanelColour={'genericOne'}
          inputHeader={'share'}
          inputHeaderBadgeOne={'node'}
          inputHeaderBadgeTwo={'fee'}
          type={'number'}
          headerOne={headerOne? headerOne:'assessment'}
          headerOneBadgeOne={'setup'}
          isRequired={false}
          actualLableValue={'assesseeCreateFee'}
          basicInfo={{}}
          typeOfSetObject={UPDATE_ASSOCIATE_BASIC_INFO}
          nextPopUpValue={'ASSOCIATEPICTUREPOPUP'}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList'}
        />
      )}
      {isPopUpValue === 'ASSESSMENTCREATEFEEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'fee'}
          headerPanelColour={'genericOne'}
          inputHeader={'assessment'}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'share'}
          type={'number'}
          headerOne={'assessment'}
          headerOneBadgeOne={'information'}
          isRequired={false}
          actualLableValue={'assessmentShareFee'}
          basicInfo={informationSetup}
          typeOfSetObject={UPDATE_ASSESSMENT_SETUP_PRIMARY_INFO}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          currencySymbol={''}
        />
      )}
      {/* {isPopUpValue === 'SHAREASSESSMENTFEEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'fee'}
          headerPanelColour={'genericOne'}
          inputHeader={'assessment'}
          inputHeaderBadgeOne={'distinct'}
          // inputHeaderBadgeTwo={'share'}
          type={'number'}
          headerOne={'assessment'}
          headerOneBadgeOne={'information'}
          isRequired={false}
          actualLableValue={'assessmentShareFee'}
          basicInfo={selectedTagInformationSetup}
          typeOfSetObject={UPDATE_ASSESSMENT_SETUP_PRIMARY_INFO}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          onClickYes={onClickShareFee}
        />
      )} */}
    </div>
  );
};

export default PopUpAssessmentCreate;
