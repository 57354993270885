import { put, takeLatest, call } from 'redux-saga/effects';
import {
  LOADER_STOP,
  SET_POPUP_VALUE,
  SET_MIDDLEPANE_STATE,
  REVIEWLIST_DISTINCT_DATA,
  SET_CORE_ROLE_REVIEW_LIST_DATA,
  GET_ITEM_REVIEW_LIST_SAGA,
  GET_ITEMGROUPITEM_REVIEW_LIST_SAGA,
  RELATED_REVIEWLIST_DISTINCT_DATA,
  SET_REVIEW_LIST_RELATE_DATA,
  GET_ITEMTYPEITEM_REVIEW_LIST_SAGA,
  GET_NODE_ITEMS_REVIEW_LIST_SAGA,
  GET_ALLOCATE_ITEM,
  SET_ITEM_FRAMWORK_TYPE,
  GET_FRAMWORK_TYPE_REVIEW_LIST_SAGA,
  LOADER_START,
  GET_ALLOCATE_ITEM_GROUP,
  SET_DISPLAY_TWO_SINGLE_STATE
} from '../../actionType';
import { ITEM_MODULE_URL } from '../../endpoints';
import { EXCEPTION_ERROR_MESSAGE } from '../../errorMessage';
import Store from '../../store';

const apiCall = async (requestObj) => {
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      Authorization: localStorage.getItem('token')
    }),
    body: JSON.stringify(requestObj.data)
  };
  const response = await fetch(requestObj.URL, requestOptions);
  const json = await response.json();
  return json;
};

function* workerReviewListItemsSaga(data) {
  console.log('data.payload', data.payload);
  try {
    const userResponse = yield call(apiCall, {
      data: data.payload.request,
      URL: ITEM_MODULE_URL
    });
    if (userResponse.responseCode === '000') {
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: 'items',
            middlePaneHeaderBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderBadgeThree: '',
            middlePaneHeaderBadgeFour: '',
            middlePaneHeaderDuplicate: 'items',
            middlePaneHeaderDuplicateBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderDuplicateBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderDuplicateBadgeThree: '',
            middlePaneHeaderDuplicateBadgeFour: '',
            typeOfMiddlePaneList: data.payload.request.actionFilter === 'Shared-Item-List' ? 'marketplaceItemsDistinctReviewList' : 'itemsDistinctReviewList',
            scanCount: userResponse && userResponse.countTotal,
            showMiddlePaneState: true,
            isSelectActive: data.payload.isSelectActive,
            selectedTagsArray: data.payload.selectedTagsArray
          }
        });
      }
      yield put({
        type: data.payload.isMiddlePaneList
          ? REVIEWLIST_DISTINCT_DATA
          : SET_CORE_ROLE_REVIEW_LIST_DATA,
        payload: userResponse.responseObject
      });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: userResponse.responseMessage,
          popupMode: 'responseErrorMsg'
        }
      });
    }

    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workeItemGroupItemReviewListSaga(data) {
  try {
    const response = yield call(apiCall, {
      data: data.payload.request,
      URL: ITEM_MODULE_URL
    });
    // const response ={responseCode:'000',countTotal:30}
    if (response.responseCode === '000') {
      let responseObj = null;
      if (Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData.length > 0) {
        responseObj = {
          ...Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData[0],
          item: [
            ...Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData[0].item,
            ...response.responseObject[0].item
          ]
        };
        //to add list more and adding all the record
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
      } else {
        responseObj = response.responseObject[0];
      }
      yield put({
        type: RELATED_REVIEWLIST_DISTINCT_DATA,
        payload: [responseObj]
      });
      yield put({
        type: RELATED_REVIEWLIST_DISTINCT_DATA,
        payload: [response.responseObject[0]]
      });
      yield put({
        type: SET_REVIEW_LIST_RELATE_DATA,
        payload: response.responseObject[0]
      });
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: data.payload.HeaderOne,
            middlePaneHeaderBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderBadgeThree: '',
            middlePaneHeaderBadgeFour: '',
            middlePaneHeaderDuplicate:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
            middlePaneHeaderDuplicateBadgeOne:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            middlePaneHeaderDuplicateBadgeTwo:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            middlePaneHeaderDuplicateBadgeThree:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
            middlePaneHeaderDuplicateBadgeFour:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour,
            typeOfMiddlePaneList: 'itemGroupItemReviewList',
            scanCount: response && response.countTotal,
            duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
            showMiddlePaneState: true
          }
        });
      }
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: response.responseMessage,
          popupMode: 'responseErrorMsg'
        }
      });
    }

    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workeItemTypeItemReviewListSaga(data) {
  try {
    const response = yield call(apiCall, {
      data: data.payload.request,
      URL: ITEM_MODULE_URL
    });
    // const response ={responseCode:'000',countTotal:30}
    if (response.responseCode === '000') {
      let responseObj = null;
      if (Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData.length > 0) {
        responseObj = {
          ...Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData[0],
          item: [
            ...Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData[0].item,
            ...response.responseObject[0].item
          ]
        };
        //to add list more and adding all the record
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
      } else {
        responseObj = response.responseObject[0];
      }
      yield put({
        type: RELATED_REVIEWLIST_DISTINCT_DATA,
        payload: [responseObj]
      });
      yield put({
        type: RELATED_REVIEWLIST_DISTINCT_DATA,
        payload: [response.responseObject[0]]
      });
      yield put({
        type: SET_REVIEW_LIST_RELATE_DATA,
        payload: response.responseObject[0]
      });
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: data.payload.HeaderOne,
            middlePaneHeaderBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderBadgeThree: data.payload.BadgeThre,
            middlePaneHeaderBadgeFour: data.payload.BadgeFour,
            middlePaneHeaderDuplicate:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
            middlePaneHeaderDuplicateBadgeOne:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            middlePaneHeaderDuplicateBadgeTwo:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            middlePaneHeaderDuplicateBadgeThree:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
            middlePaneHeaderDuplicateBadgeFour:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour,
            typeOfMiddlePaneList: 'itemTypeItemReviewList',
            scanCount: response && response.countTotal,
            duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
            showMiddlePaneState: true
          }
        });
      }
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: response.responseMessage,
          popupMode: 'responseErrorMsg'
        }
      });
    }

    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workeItemNodeItemReviewListSaga(data) {
  try {
    const response = yield call(apiCall, {
      data: data.payload.request,
      URL: ITEM_MODULE_URL
    });
    // const response ={responseCode:'000',countTotal:30}
    if (response.responseCode === '000') {
      let responseObj = null;
      if (Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData.length > 0 && data.payload.isMiddlePaneList) {
        responseObj = {
          ...Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData[0],
          item: [
            ...Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData[0]?.item,
            ...response.responseObject[0].item
          ]
        };
        //to add list more and adding all the record
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
      } else {
        responseObj = response.responseObject[0];
      }
      yield put({
        type: RELATED_REVIEWLIST_DISTINCT_DATA,
        payload: [responseObj]
      });
      yield put({
        type: RELATED_REVIEWLIST_DISTINCT_DATA,
        payload: [response.responseObject[0]]
      });
      // yield put({
      //   type: SET_REVIEW_LIST_RELATE_DATA,
      //   payload: response.responseObject
      // });
      let relatedReviewListPaneThree2 = {
        ...Store.getState().DisplayPaneThreeReducer.relatedReviewListPaneThree,
        item: response.responseObject[0].item
      }

      yield put({
        type: SET_REVIEW_LIST_RELATE_DATA,
        payload: relatedReviewListPaneThree2
      });
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: data.payload.HeaderOne,
            middlePaneHeaderBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderBadgeThree: '',
            middlePaneHeaderBadgeFour: '',
            middlePaneHeaderDuplicate:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
            middlePaneHeaderDuplicateBadgeOne:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            middlePaneHeaderDuplicateBadgeTwo:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            middlePaneHeaderDuplicateBadgeThree:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
            middlePaneHeaderDuplicateBadgeFour:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour,
            typeOfMiddlePaneList: data.payload.request.actionFilter === 'Shared-Item-Node' ? 'marketplaceItemNodeItemReviewList' : 'itemNodeItemReviewList',
            scanCount: response && response.countTotal,
            duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
            showMiddlePaneState: true
          }
        });
      }
    } else {
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: response.responseMessage,
            popupMode: 'responseErrorMsg'
          }
        });
      }
    }

    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
    yield put({ type: LOADER_STOP });
  }
}

function* workerItemGroupAllocateReviewListSaga(data) {
  try {
    const userResponse = yield call(apiCall, {
      data: data.payload.request,
      URL: ITEM_MODULE_URL
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === '000') {
      let responseObj = {
        ...data.payload.revisedGroupObject,
        itemGroup: userResponse.responseObject
      };
      yield put({
        type: SET_MIDDLEPANE_STATE,
        payload: {
          middlePaneHeader: data.payload.headerOne || 'items',
          middlePaneHeaderBadgeOne: 'group',
          middlePaneHeaderBadgeTwo: 'active',
          middlePaneHeaderBadgeThree: '',
          middlePaneHeaderBadgeFour: '',
          typeOfMiddlePaneList: data.payload.typeOfMiddlePaneList,
          scanCount: userResponse && userResponse.countTotal,
          showMiddlePaneState: true,
          isSelectActive: 'multiple',
          selectedTagsArray: data.payload.existingItemId
        }
      });
      yield put({
        type: RELATED_REVIEWLIST_DISTINCT_DATA,
        payload: [responseObj]
      });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: userResponse.responseMessage,
          popupMode: 'responseErrorMsg'
        }
      });
    }
    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerItemAllocateReviewListSaga(data) {
  try {
    const userResponse = yield call(apiCall, {
      data: data.payload.request,
      URL: ITEM_MODULE_URL
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === '000') {
      let responseObj = null;
      if (Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData.length > 0) {
        responseObj = {
          ...data.payload.revisedGroupObject,
          //assessee: userResponse.responseObject  cultureProfileGroup
          item: [
            ...Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData[0].item,
            ...userResponse.responseObject
          ]
        };
        //to add list more and adding all the record
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
        // console.log(...Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData[0].assessee,...userResponse.responseObject);
      } else {
        responseObj = {
          ...data.payload.revisedGroupObject,
          item: userResponse.responseObject
        };
      }

      yield put({
        type: SET_MIDDLEPANE_STATE,
        payload: {
          middlePaneHeader: data.payload.headerOne || 'items',
          middlePaneHeaderBadgeOne: 'distinct',
          middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
          middlePaneHeaderBadgeThree: '',
          middlePaneHeaderBadgeFour: '',
          middlePaneHeaderDuplicate: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
          middlePaneHeaderDuplicateBadgeOne:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
          middlePaneHeaderDuplicateBadgeTwo:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
          middlePaneHeaderDuplicateBadgeThree:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
          middlePaneHeaderDuplicateBadgeFour:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour,
          typeOfMiddlePaneList: data.payload.typeOfMiddlePaneList,
          scanCount: userResponse && userResponse.countTotal,
          duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
          showMiddlePaneState: true,
          isSelectActive: 'multiple',
          selectedTagsArray: data.payload.existingItemId,
          unselectedTagsArray: data.payload.unselectedTagsArray
        }
      });
      yield put({
        type: RELATED_REVIEWLIST_DISTINCT_DATA,
        payload: [responseObj]
      });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: userResponse.responseMessage,
          popupMode: 'responseErrorMsg'
        }
      });
    }
    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
    yield put({ type: LOADER_STOP });
  }
}

function* workerItemTypeReviewListSaga(data) {
  try {
    const userResponse = yield call(apiCall, {
      data: data.payload.request,
      URL: ITEM_MODULE_URL
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    yield put({ type: LOADER_START });
    if (userResponse.responseCode === '000') {
      yield put({
        type: SET_ITEM_FRAMWORK_TYPE,
        payload: userResponse?.responseObject || []
      });
    } else {
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: userResponse.responseMessage,
          popupMode: 'responseErrorMsg'
        }
      });
    }
    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
    yield put({ type: LOADER_STOP });
  }
}

export default function* watchItemReviewListSaga() {
  yield takeLatest(GET_ITEM_REVIEW_LIST_SAGA, workerReviewListItemsSaga);
  yield takeLatest(GET_ITEMGROUPITEM_REVIEW_LIST_SAGA, workeItemGroupItemReviewListSaga);
  yield takeLatest(GET_ITEMTYPEITEM_REVIEW_LIST_SAGA, workeItemTypeItemReviewListSaga);
  yield takeLatest(GET_NODE_ITEMS_REVIEW_LIST_SAGA, workeItemNodeItemReviewListSaga);
  yield takeLatest(GET_ALLOCATE_ITEM, workerItemAllocateReviewListSaga);
  yield takeLatest(GET_ALLOCATE_ITEM_GROUP, workerItemGroupAllocateReviewListSaga);
  yield takeLatest(GET_FRAMWORK_TYPE_REVIEW_LIST_SAGA, workerItemTypeReviewListSaga);
}
