import {
  ACCESS_KEY,
  ADMIN_ROLE_ID,
  ASSISTANT_ROLE_ID,
  BUCKET_NAME,
  DIR_NAME,
  MANAGER_ROLE_ID,
  REGION,
  SECRET_ACCESS_KEY
} from '../endpoints';
import S3 from 'react-aws-s3';
import {
  apiCallForItemDistinctPagination,
  getItemReviewApiCall,
  getNodeRelatedItemsDistinctApiCall
} from './ItemModuleAction';
import {
  ASSESSEE_ASSESSMENT_INFO_REVISE_STATUS_SAGA,
  ASSESSEE_INFO_REVISE_SAGA,
  ASSESSEE_MULTI_STATUS_REVISE_SAGA,
  ASSESSMENT_MULTI_STATUS_REVISE_SAGA,
  ASSIGNMENT_MULTI_STATUS_REVISE_SAGA,
  ASSOCIATE_DISTINCT_SHARE_SAGA,
  ASSOCIATE_MULTI_STATUS_REVISE_SAGA,
  ASSOCIATE_REVIEW_DISTINCT_SAGA,
  ASSOCIATE_REVIEW_DISTINCT_SHARE_SAGA,
  CLEAR_ASSESSEE_ASSESSMENT_REDUCER_STATE,
  CLEAR_DISPLAY_PANE_THREE,
  CREATE_GAUGE_SAGA,
  CULTURE_PROFILE_MULTI_STATUS_REVISE_SAGA,
  FILTERMODE,
  GET_ASSESSEE_ASSIGNMENT_SAGA,
  GET_COUNTRY_STATE_CITY_LIST_SAGA,
  GET_GAUGE_REVISE_STATUS_SAGA,
  ITEM_MULTI_STATUS_REVISE_SAGA,
  JOB_PROFILE_MULTI_STATUS_REVISE_SAGA,
  LOADER_START,
  POPUP_CLOSE,
  POPUP_OPEN,
  SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
  SET_ASSESSEE_ASSESSMENT_ITEM_LAST_ATTEMPT_SAGA,
  GET_ASSESSEE_MANUSCRIPT_VIDEOS_REVIEW_LIST_SAGA,
  SET_DISPLAY_PANE_THREE_STATE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MIDDLEPANE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_PAGE_COUNT,
  SET_POPUP_SINGLE_STATE,
  SET_POPUP_STATE,
  SET_REQUEST_OBJECT,
  SHARE_DISTINCT_SAGA
} from '../actionType';
import { getAssesseeNodeAssesseeDistinctApiCall } from './AssesseeModuleAction';
import { getNodeRelatedAssessmentsDistinctApiCall } from './AssessmentModuleAction';
import { getNodeRelatedAssignmentsDistinctApiCall } from './AssignmentModuleAction';
import { getNodeRelatedAssociateDistinctApiCall } from './AssociateModuleAction';
import { getJobProfileNodeJobProfileApiCall } from './ActionJobProfile';
import { getCultureProfileNodeCultureProfileApiCall } from './ActionCultureProfile';
const configObj = {
  bucketName: BUCKET_NAME,
  dirName: DIR_NAME /* optional */,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY
  // s3Url: 'https:/your-custom-s3-url.com/', /* optional */
};
const ReactS3Client = new S3(configObj);

export const setAssesseeCardPermissionInJson = (popupValuArr, assesseePermission) => {
  let popupContentArrValue = popupValuArr.map(function (el) {
    var o = Object.assign({}, el);
    var sss = el.permissionArr;
    o.disabled =
      assesseePermission && assesseePermission[sss]
        ? !assesseePermission[sss].includes(el.permission)
        : true;
    return o;
  });
  return popupContentArrValue;
};
function splitCamelCaseToString(s) {
  return s
    .split(/(?=[A-Z])/)
    .map(function (p) {
      return p.charAt(0).toUpperCase() + p.slice(1);
    })
    .join(' ');
}
export const convertSecondsToHMmSs = (ms) => {
  // 1- Convert to seconds:
  let seconds = '00';
  let hours = '00';
  let minutes = '00';
  if (ms !== null && ms !== '') {
    seconds = ms / 1000;
    hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour
    seconds = seconds % 3600; // seconds remaining after extracting hours
    minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
    seconds = seconds % 60;
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;
  }

  return hours + ':' + minutes + ':' + seconds;
};

export const msToTime = (duration) => {
  if (typeof duration === 'number') {
    let milliseconds = Math.floor((duration % 1000) / 100),
      seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;

    return hours + ':' + minutes + ':' + seconds + '.' + milliseconds;
  }
  return duration;
};

export const imageUploadMethod = async (file) => {
  let imgUploadData = await ReactS3Client.uploadFile(file, file.name);
  console.log('imgUploadData', imgUploadData);
  let res = { success: 1, file: { url: imgUploadData.location } };
  return res;
};
export const navigationString = (navigationObj) => {
  let str = [];
  if (navigationObj?.assessmentSectionItemNavigationFirst) str.push('First');
  if (navigationObj?.assessmentSectionItemNavigationLast) str.push('Last');
  if (navigationObj?.assessmentSectionItemNavigationNext) str.push('Next');
  if (navigationObj?.assessmentSectionItemNavigationPrevious) str.push('Previous');
  if (navigationObj?.assessmentSectionItemNavigationSkip) str.push('Skip');
  return str.join(', ');
};
export const hasDuplicates = (array) => {
  var valuesSoFar = [];
  for (var i = 0; i < array.length; ++i) {
    var value = array[i];
    if (valuesSoFar.indexOf(value) !== -1) {
      return true;
    }
    valuesSoFar.push(value);
  }
  return false;
};
export const getEditorDataInHtmlTemplate = (jsonData) => {
  let htmlString = '';
  console.log('jsonData', jsonData);
  if (jsonData?.blocks.length > 0) {
    jsonData?.blocks.forEach((blc) => {
      if (blc.type === 'paragraph') {
        htmlString = htmlString + '  ' + blc?.data?.text;
      }
    });
  }
  return htmlString;
};
export const AlpbabeticalSortByKey = (property) => {
  var sortOrder = 1;

  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }

  return function (a, b) {
    if (sortOrder == -1) {
      return b[property].localeCompare(a[property]);
    } else {
      return a[property].localeCompare(b[property]);
    }
  };
};

export const SortedList = (list, keyproperty) => {
  list.sort(function (s1, s2) {
    var l = s1[keyproperty].toLowerCase(),
      m = s2[keyproperty].toLowerCase();
    return l === m ? 0 : l > m ? 1 : -1;
  });
};
export const onClickFirst = (
  reviewListDistinctData,
  id,
  typeOfMiddlePaneList,
  selectedAssociateInfo,
  dispatch,
  information
) => {
  console.log('first Record');
  let firstIndex = 0;
  callApiFunction(
    selectedAssociateInfo,
    reviewListDistinctData,
    dispatch,
    firstIndex,
    typeOfMiddlePaneList
  );
};
export const onClickLast = (
  reviewListDistinctData,
  typeOfMiddlePaneList,
  selectedAssociateInfo,
  dispatch,
  scanCount,
  reviewListReqObj,
  numberPage,
  middlePaneHeader,
  middlePaneHeaderBadgeOne,
  middlePaneHeaderBadgeTwo
) => {
  console.log('last Record');
  let lastIndex = reviewListDistinctData.length - 1;
  if (scanCount > reviewListDistinctData.length) {
    if (typeOfMiddlePaneList === 'itemsDistinctReviewList') {
      apiCallForItemDistinctPagination(
        dispatch,
        reviewListReqObj,
        numberPage,
        middlePaneHeader,
        middlePaneHeaderBadgeOne,
        middlePaneHeaderBadgeTwo
      );
    }
  }
  callApiFunction(
    selectedAssociateInfo,
    reviewListDistinctData,
    dispatch,
    lastIndex,
    typeOfMiddlePaneList
  );
};
export const onClickNext = (
  reviewListDistinctData,
  id,
  typeOfMiddlePaneList,
  selectedAssociateInfo,
  dispatch,
  information,
  reviewListReqObj,
  numberPage,
  middlePaneHeader,
  middlePaneHeaderBadgeOne,
  middlePaneHeaderBadgeTwo
) => {
  if (reviewListDistinctData.length > 0 && dispatch) {
    let index = reviewListDistinctData.findIndex((data) => data.id === id);
    let nextIndex = index + 1;
    if (nextIndex < reviewListDistinctData.length) {
      callApiFunction(
        selectedAssociateInfo,
        reviewListDistinctData,
        dispatch,
        nextIndex,
        typeOfMiddlePaneList
      );
    } else {
      if (typeOfMiddlePaneList === 'itemsDistinctReviewList') {
        apiCallForItemDistinctPagination(
          dispatch,
          reviewListReqObj,
          numberPage,
          middlePaneHeader,
          middlePaneHeaderBadgeOne,
          middlePaneHeaderBadgeTwo
        );
      }
    }
  }
};
export const onClickPrevious = (
  reviewListDistinctData,
  id,
  typeOfMiddlePaneList,
  selectedAssociateInfo,
  dispatch,
  information
) => {
  console.log('prev Record');
  if (reviewListDistinctData.length > 0 && dispatch) {
    let index = reviewListDistinctData.findIndex((data) => data.id === id);
    let prevIndex = index - 1;
    if (prevIndex < reviewListDistinctData.length) {
      callApiFunction(
        selectedAssociateInfo,
        reviewListDistinctData,
        dispatch,
        prevIndex,
        typeOfMiddlePaneList
      );
    }
  }
};
const callApiFunction = (
  selectedAssociateInfo,
  reviewListDistinctData,
  dispatch,
  index,
  typeOfMiddlePaneList
) => {
  let nextId = reviewListDistinctData[index].id;
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'middlePaneSelectedValue', value: nextId }
  });
  if (
    typeOfMiddlePaneList === 'itemsDistinctReviewList' ||
    typeOfMiddlePaneList === 'assessmentItemReviewList' ||
    typeOfMiddlePaneList === 'itemGroupItemReviewList'
  ) {
    dispatch({ type: LOADER_START });
    getItemReviewApiCall(selectedAssociateInfo, dispatch, 'all', false, nextId);
  }
};
export const audioUploadMethod = async (file) => {
  let imgUploadData = await ReactS3Client.uploadFile(file, file.name);
  console.log(imgUploadData);
  let res = {
    data: {
      url: imgUploadData.location,
      name: 'audio.webm',
      id: '123'
    }
  };
  return res;
};
export const getPermissionStr = (permissionObj) => {
  let per = '';
  if (permissionObj) {
    let order = {
      create: 1,
      delete: 2,
      move: 3,
      review: 4,
      revise: 5,
      sell: 6,
      share: 7,
      default: Number.MAX_VALUE
    };

    let orderArrr = [];
    Object.keys(permissionObj).forEach((key, val) => {
      if (typeof permissionObj[key] === 'boolean' && permissionObj[key] === true) {
        orderArrr.push(splitCamelCaseToString(key));
        // per = per !== '' ? per + ', ' + splitCamelCaseToString(key) : splitCamelCaseToString(key);
      }
    });

    orderArrr.sort(
      (a, b) => (order[a] || order.default) - (order[b] || order.default) || a > b || -(a < b)
    );

    per = orderArrr.toString();
  }
  return per;
};
export const getEvaluationStr = (evalObj) => {
  let per = '';
  if (evalObj) {
    Object.keys(evalObj).forEach((key, val) => {
      if (typeof evalObj[key] === 'boolean' && evalObj[key] === true) {
        if (key === 'assessmentEvaluationScoreCutoff') key = 'Cutoff';
        if (key === 'assessmentEvaluationScoreGeneric') key = 'Generic';
        if (key === 'assessmentEvaluationScoreGrade') key = 'Grade';
        if (key === 'assessmentEvaluationScoreMaximum') key = 'Maximum';
        if (key === 'assessmentEvaluationScoreMinimum') key = 'Minimum';
        if (key === 'assessmentEvaluationScorePercentage') key = 'Percentage';
        if (key === 'assessmentEvaluationScorePercentile') key = 'Percentile';
        if (key === 'assessmentEvaluationScoreRank') key = 'Rank';
        if (key === 'assessmentEvaluationScoreRaw') key = 'Raw';
        if (key === 'assessmentEvaluationScoreStandard') key = 'Standard';
        if (key === 'assessmentEvaluationScoreSten') key = 'Sten';
        if (key === 'assessmentEvaluationScoreT') key = 'T';
        if (key === 'assessmentEvaluationScoreZ') key = 'Z';
        per = per !== '' ? per + ', ' + splitCamelCaseToString(key) : splitCamelCaseToString(key);
      }
    });
  }
  return per;
};
export const setAssociateCardEnableInJson = (popupValuArr) => {
  var isDisabled = true;
  let popupContentArrValue = popupValuArr.map(function (el) {
    var o = Object.assign({}, el);
    o.disabled = isDisabled;
    return o;
  });
  console.log(popupContentArrValue);

  return popupContentArrValue;
};
function getObjectValueByPartialName(obj, name, retType) {
  if (obj) {
    if (typeof obj !== 'object') {
      console.log(typeof obj, obj);
      throw new Error('object is required!');
    }
    let temp = [];
    if (name) {
      for (var prop in obj) {
        // console.log('if  condition ',  prop in obj, prop , obj);
        // console.log('prop', prop);
        // console.log('index', prop.indexOf(name));
        if (prop.toLowerCase().indexOf(name.toLowerCase()) > 0 && typeof obj[prop] === 'object') {
          if (obj[prop] && retType) temp.push(obj[prop]);
          else return obj[prop];
          // return obj[prop];
        }
      }
      return temp;
    }
  }
}
export const setAssociateCardPermissionInJson = (
  popupValuArr,
  permissionObject,
  optionClass = 'optionPrimary',
  checkSpecificPermission,
  popupHeaderOne = '',
  clickValue = '',
  paneOneSelectedSection = 'paneoneSection1'
) => {
  // console.log('paneOneSelectedSection',paneOneSelectedSection);
  let newArr = [];
  let newArrWithObj = {
    AssesseeDistinct: [],
    iGuruAnalyticDistinct: [],
    AssociateDistinct: [],
    Group: [],
    Node: [],
    Role: [],
    Type: [],
    itemDistinct: []
  };
  let popupValuArr22 = JSON.parse(JSON.stringify(popupValuArr));
  popupValuArr.forEach((per) => {
    // if (per.permissionValue === 'all') {
    if (getObjectValueByPartialName(permissionObject, per.permission)) {
      newArr.push(getObjectValueByPartialName(permissionObject, per.permission));
      newArrWithObj[per.permission] = getObjectValueByPartialName(
        permissionObject,
        per.permission,
        'arr'
      );
    }

    // }
  });
  // popupValuArr.forEach((ele) => {
  popupValuArr22.forEach((ele) => {
    // console.log('ele',ele.data ,ele.disabled);
    if (ele.optionClass === optionClass && ele.permission !== 'No') {
      // newArr = getObjectValueByPartialName(permissionObject, ele.permission);
      if (ele.permissionValue === 'all') {
        newArrWithObj[ele.permission].forEach((inner) => {
          if (ele.permission === 'Group') {
            console.log('arrr', Object.values(inner));
            console.log('temmm', Object.values(inner).includes(true));
          }
          if (Object.values(inner).includes(true)) {
            ele.disabled = false;
            return;
          }
        });
      } else {
        let temp;
        // console.log('checkSpecificPermission ',ele.data ,'-->',  checkSpecificPermission);
        if (checkSpecificPermission) {
          temp = getObjectValueByPartialName(permissionObject, checkSpecificPermission);
        } else {
          temp = getObjectValueByPartialName(permissionObject, ele.permission);
        }
        // console.log('temp', temp);
        // console.log('ele.permissionValue', ele.permissionValue);
        if (temp) {
          // console.log(ele.data, ele.disabled,'-->', !temp[ele.permissionValue]);
          ele.disabled = !temp[ele.permissionValue];
        }
        if (
          popupHeaderOne === 'groups' ||
          popupHeaderOne === 'nodes' ||
          popupHeaderOne === 'roles' ||
          popupHeaderOne === 'types'
        ) {
          if (ele.data === 'notifications' || ele.data === 'reports') ele.disabled = true;
        }
        if (clickValue === 'profilers') {
          if (ele.data === 'create') ele.disabled = true;
        }
        if (paneOneSelectedSection === 'paneoneSection3' && ele.data === 'nodes') {
          ele.disabled = true;
        }
      }
    } else if (
      paneOneSelectedSection === 'paneoneSection3' &&
      (ele.data === 'inactive' || ele.data === 'all' || ele.data === 'archived')
    ) {
      ele.disabled = true;
    }
  });
  console.log('popupValuArr22', popupValuArr22);
  return popupValuArr22;
};
export const makeAssesseeReviewListRequestObject = (
  selectedAssociateInfo,
  filterKey,
  numberPage,
  countPage,
  assignmentCreateType = ''
) => {
  let searchObj = {
    dataType: 'string',
    conditionColumn: 'informationEngagement.assesseeStatus',
    conditionValue: {
      condition: 'eq',
      value: {
        from: filterKey.toUpperCase()
      }
    }
  };
  if (filterKey === 'activeinactive') {
    searchObj = {
      dataType: 'string',
      conditionColumn: 'informationEngagement.assesseeStatus',
      conditionValue: {
        condition: 'in',
        value: {
          in: ['UNAPPROVED', 'CONFIRMED', 'UNCONFIRMED']
        }
      }
    };
  }
  if (filterKey === 'all') {
    searchObj = {
      dataType: 'string',
      conditionColumn: 'informationEngagement.assesseeStatus',
      conditionValue: {
        condition: 'in',
        value: {
          in: ['CONFIRMED', 'DISAPPROVED', 'SUSPENDED', 'TERMINATED', 'UNAPPROVED', 'UNCONFIRMED']
        }
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      dataType: 'string',
      conditionColumn: 'informationEngagement.assesseeStatus',
      conditionValue: {
        condition: 'in',
        value: {
          in: [
            'CONFIRMED',
            'DISAPPROVED',
            'SUSPENDED',
            'TERMINATED',
            'UNAPPROVED',
            'UNCONFIRMED',
            'ARCHIVED'
          ]
        }
      }
    };
  }
  let searchArr = [searchObj];
  if (
    assignmentCreateType !== '' &&
    assignmentCreateType !== 'iGuru Analytics_Career' &&
    assignmentCreateType !== 'iGuru Analytics_Education' &&
    assignmentCreateType !== 'iGuru Analytics_Occupation' &&
    assignmentCreateType !== 'iGuru Analytics_Pulse' &&
    assignmentCreateType !== 'iGuru_Assignment'
  ) {
    let searchObj = {
      dataType: 'string',
      conditionColumn: 'informationAllocation.assesseeType.assesseeTypeSecondary',
      conditionValue: {
        condition: 'in',
        value: {
          in: [
            `${process.env[
            `REACT_APP_ASSESSEE_${assignmentCreateType.replace(' ', '_').toUpperCase()}`
            ]
            }`
          ]
        }
      }
    };
    searchArr.push(searchObj);
  }
  let regObj = {
    module: 'Assessee-Distinct',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary, //'60520a349d66236bb84f8b1b',
      countPage: countPage,
      numberPage: numberPage,
      filter: 'true',
      orderBy: {
        columnName:
          'informationBasic.assesseeNameFirst,informationBasic.assesseeNameOther,informationBasic.assesseeNameLast,informationBasic.assesseeAlias',
        order: 'asc'
      },
      searchCondition: 'AND',
      search: [
        {
          condition: 'and',
          searchBy: searchArr
        }
      ]
    }
  };

  return regObj;
};

export const makeMarketPlaceAssesseeReviewListRequestObject = (
  selectedAssociateInfo,
  filterKey,
  numberPage,
  countPage
) => {
  let searchObj = {
    dataType: 'string',
    conditionColumn: 'informationEngagement.assesseeStatus',
    conditionValue: {
      condition: 'eq',
      value: {
        from: filterKey.toUpperCase()
      }
    }
  };
  if (filterKey === 'activeinactive') {
    searchObj = {
      dataType: 'string',
      conditionColumn: 'informationEngagement.assesseeStatus',
      conditionValue: {
        condition: 'in',
        value: {
          in: ['UNAPPROVED', 'CONFIRMED', 'UNCONFIRMED']
        }
      }
    };
  }
  if (filterKey === 'all') {
    searchObj = {
      dataType: 'string',
      conditionColumn: 'informationEngagement.assesseeStatus',
      conditionValue: {
        condition: 'in',
        value: {
          in: ['CONFIRMED', 'DISAPPROVED', 'SUSPENDED', 'TERMINATED', 'UNAPPROVED', 'UNCONFIRMED']
        }
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      dataType: 'string',
      conditionColumn: 'informationEngagement.assesseeStatus',
      conditionValue: {
        condition: 'in',
        value: {
          in: [
            'CONFIRMED',
            'DISAPPROVED',
            'SUSPENDED',
            'TERMINATED',
            'UNAPPROVED',
            'UNCONFIRMED',
            'ARCHIVED'
          ]
        }
      }
    };
  }
  let regObj = {
    module: 'Assessee-Distinct',
    action: 'Shared-Assessee-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary, //'60520a349d66236bb84f8b1b',
      countPage: countPage,
      numberPage: numberPage,
      filter: 'true',
      orderBy: {
        columnName:
          'informationBasic.assesseeNameFirst,informationBasic.assesseeNameOther,informationBasic.assesseeNameLast,informationBasic.assesseeAlias',
        order: 'asc'
      },
      searchCondition: 'AND',
      search: [
        {
          condition: 'or',
          searchBy: [searchObj]
        }
      ]
    }
  };

  return regObj;
};

export const makeAdministratorsReviewListRequestObject = (
  selectedAssociateInfo,
  filterKey,
  numberPage,
  countPage
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'activeinactive') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['CONFIRMED', 'UNCONFIRMED']
      }
    };
  }
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['CONFIRMED', 'DISAPPROVED', 'SUSPENDED', 'TERMINATED', 'UNAPPROVED', 'UNCONFIRMED']
      }
    };
  }
  let regObj = {
    module: 'Assessee-Distinct',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary, //'60520a349d66236bb84f8b1b',
      countPage: countPage,
      numberPage: numberPage,
      filter: 'true',
      orderBy: {
        columnName:
          'informationBasic.assesseeNameFirst,informationBasic.assesseeNameOther,informationBasic.assesseeNameLast,informationBasic.assesseeAlias',
        order: 'asc'
      },
      searchCondition: 'AND',
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assesseeStatus',
              conditionValue: searchObj
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.assesseeRole.assesseeRolePrimary',
              conditionValue: {
                condition: 'in',
                value: {
                  in: ADMIN_ROLE_ID
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.assesseeRole.assesseeRoleSecondary',
              conditionValue: {
                condition: 'in',
                value: {
                  in: ADMIN_ROLE_ID
                }
              }
            }
          ]
        }
      ]
    }
  };

  return regObj;
};
export const makeManagersReviewListRequestObject = (
  selectedAssociateInfo,
  filterKey,
  numberPage,
  countPage
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'activeinactive') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['CONFIRMED', 'UNCONFIRMED']
      }
    };
  }
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['CONFIRMED', 'DISAPPROVED', 'SUSPENDED', 'TERMINATED', 'UNAPPROVED', 'UNCONFIRMED']
      }
    };
  }
  let regObj = {
    module: 'Assessee-Distinct',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary, //'60520a349d66236bb84f8b1b',
      countPage: countPage,
      numberPage: numberPage,
      filter: 'true',
      orderBy: {
        columnName:
          'informationBasic.assesseeNameFirst,informationBasic.assesseeNameOther,informationBasic.assesseeNameLast,informationBasic.assesseeAlias',
        order: 'asc'
      },
      searchCondition: 'AND',
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assesseeStatus',
              conditionValue: searchObj
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.assesseeRole.assesseeRolePrimary',
              conditionValue: {
                condition: 'in',
                value: {
                  in: process.env.REACT_APP_MANAGER_ROLE_ID.split(',')
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.assesseeRole.assesseeRoleSecondary',
              conditionValue: {
                condition: 'in',
                value: {
                  in: process.env.REACT_APP_MANAGER_ROLE_ID.split(',')
                }
              }
            }
          ]
        }
      ]
    }
  };

  return regObj;
};
export const makeAssistantsReviewListRequestObject = (
  selectedAssociateInfo,
  filterKey,
  numberPage,
  countPage
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'activeinactive') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['CONFIRMED', 'UNCONFIRMED']
      }
    };
  }
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['CONFIRMED', 'DISAPPROVED', 'SUSPENDED', 'TERMINATED', 'UNAPPROVED', 'UNCONFIRMED']
      }
    };
  }
  let regObj = {
    module: 'Assessee-Distinct',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary, //'60520a349d66236bb84f8b1b',
      countPage: countPage,
      numberPage: numberPage,
      filter: 'true',
      orderBy: {
        columnName:
          'informationBasic.assesseeNameFirst,informationBasic.assesseeNameOther,informationBasic.assesseeNameLast,informationBasic.assesseeAlias',
        order: 'asc'
      },
      searchCondition: 'AND',
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assesseeStatus',
              conditionValue: searchObj
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.assesseeRole.assesseeRolePrimary',
              conditionValue: {
                condition: 'in',
                value: {
                  in: process.env.REACT_APP_ASSISTANT_ROLE_ID.split(',')
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.assesseeRole.assesseeRoleSecondary',
              conditionValue: {
                condition: 'in',
                value: {
                  in: process.env.REACT_APP_ASSISTANT_ROLE_ID.split(',')
                }
              }
            }
          ]
        }
      ]
    }
  };

  return regObj;
};

export const makeAdminScanRequestObject = (
  selectedAssociateInfo,
  filterKey,
  numberPage,
  countPage,
  searchStr
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'activeinactive') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['CONFIRMED', 'UNCONFIRMED']
      }
    };
  }
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['CONFIRMED', 'DISAPPROVED', 'SUSPENDED', 'TERMINATED', 'UNAPPROVED', 'UNCONFIRMED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: [
          'CONFIRMED',
          'DISAPPROVED',
          'SUSPENDED',
          'TERMINATED',
          'UNAPPROVED',
          'UNCONFIRMED',
          'ARCHIVED'
        ]
      }
    };
  }
  let regObj = {
    module: 'Assessee-Distinct',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      filter: 'true',
      orderBy: {
        columnName:
          'informationBasic.assesseeNameFirst,informationBasic.assesseeNameOther,informationBasic.assesseeNameLast,informationBasic.assesseeAlias',
        order: 'asc'
      },
      searchCondition: 'AND',
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assesseeNameFirst',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assesseeNameOther',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assesseeNameLast',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assesseeAlias',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assesseeTag.assesseeTagPrimary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn:
                'informationContact.assesseeAddressEmailPrimary.assesseeAddressEmail',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn:
                'informationContact.assesseeAddressEmailSecondary.assesseeAddressEmail',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn:
                'informationContact.assesseeTelephoneMobilePrimary.assesseeTelephoneNumber',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn:
                'informationContact.assesseeTelephoneMobileSecondary.assesseeTelephoneNumber',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.assesseeRole.assesseeRolePrimary',
              conditionValue: {
                condition: 'in',
                value: {
                  in: ADMIN_ROLE_ID
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.assesseeRole.assesseeRoleSecondary',
              conditionValue: {
                condition: 'in',
                value: {
                  in: ADMIN_ROLE_ID
                }
              }
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assesseeStatus',
              conditionValue: searchObj
            }
          ]
        }
      ]
    }
  };

  return regObj;
};
export const makeAssesseeScanRequestObject = (
  selectedAssociateInfo,
  filterKey,
  numberPage,
  countPage,
  searchStr,
  assignmentCreateType = ''
) => {
  let searchArr = [];
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'activeinactive') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['CONFIRMED', 'UNCONFIRMED']
      }
    };
  }
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['CONFIRMED', 'DISAPPROVED', 'SUSPENDED', 'TERMINATED', 'UNAPPROVED', 'UNCONFIRMED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: [
          'CONFIRMED',
          'DISAPPROVED',
          'SUSPENDED',
          'TERMINATED',
          'UNAPPROVED',
          'UNCONFIRMED',
          'ARCHIVED'
        ]
      }
    };
  }
  searchArr.push({
    dataType: 'string',
    conditionColumn: 'informationEngagement.assesseeStatus',
    conditionValue: searchObj
  });
  if (
    assignmentCreateType !== '' &&
    assignmentCreateType !== 'iGuru Analytics_Career' &&
    assignmentCreateType !== 'iGuru Analytics_Education' &&
    assignmentCreateType !== 'iGuru Analytics_Occupation' &&
    assignmentCreateType !== 'iGuru Analytics_Pulse' &&
    assignmentCreateType !== 'iGuru_Assignment'
  ) {
    let searchObj = {
      dataType: 'string',
      conditionColumn: 'informationAllocation.assesseeType.assesseeTypeSecondary',
      conditionValue: {
        condition: 'in',
        value: {
          in: [
            `${process.env[
            `REACT_APP_ASSESSEE_${assignmentCreateType.replace(' ', '_').toUpperCase()}`
            ]
            }`
          ]
        }
      }
    };
    searchArr.push(searchObj);
  }

  let regObj = {
    module: 'Assessee-Distinct',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      filter: 'true',
      orderBy: {
        columnName:
          'informationBasic.assesseeNameFirst,informationBasic.assesseeNameOther,informationBasic.assesseeNameLast,informationBasic.assesseeAlias',
        order: 'asc'
      },
      searchCondition: 'AND',
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assesseeNameFirst',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assesseeNameOther',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assesseeNameLast',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assesseeAlias',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assesseeTag.assesseeTagPrimary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn:
                'informationContact.assesseeAddressEmailPrimary.assesseeAddressEmail',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn:
                'informationContact.assesseeAddressEmailSecondary.assesseeAddressEmail',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn:
                'informationContact.assesseeTelephoneMobilePrimary.assesseeTelephoneNumber',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn:
                'informationContact.assesseeTelephoneMobileSecondary.assesseeTelephoneNumber',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            }
          ]
        },
        {
          condition: 'and',
          searchBy: searchArr
        }
      ]
    }
  };

  return regObj;
};
export const makeAssignmentReferenceListReqObj = (
  selectedAssociateInfo,
  assignmentId,
  countPage
) => {
  let reqBody = {
    module: 'Iguru-Mine',
    action: 'Assignment-Ref',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.assignmentName,informationBasic.assignmentDescription',
        order: 'asc'
      },
      numberPage: -1,
      countPage: -1,
      assignmentId: assignmentId,
      allRefAssignmentWithoutValidation: false,
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assignmentStatus',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: 'ACTIVE'
                }
              }
            }
          ]
        }
      ]
    }
  };
  return reqBody;
};

export const makeScanAssignmentReferenceListReqObj = (
  selectedAssociateInfo,
  assignmentId,
  countPage,
  scanString
) => {
  let reqBody = {
    module: 'Iguru-Mine',
    action: 'Assignment-Ref',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.assignmentName,informationBasic.assignmentDescription',
        order: 'asc'
      },
      numberPage: -1,
      countPage: -1,
      assignmentId: assignmentId,
      allRefAssignmentWithoutValidation: false,
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assignmentStatus',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: 'ACTIVE'
                }
              }
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assignmentName',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: scanString
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assignmentDescription',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: scanString
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assignmentTag.assignmentTagPrimary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: scanString
                }
              }
            }
          ]
        }
      ]
    }
  };
  return reqBody;
};
export const makeAssociateReviewListRequestObject = (
  selectedAssociateInfo,
  filterKey,
  numberPage,
  countPage
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'activeinactive') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['CONFIRMED', 'UNCONFIRMED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: [
          'CONFIRMED',
          'DISAPPROVED',
          'SUSPENDED',
          'TERMINATED',
          'UNAPPROVED',
          'UNCONFIRMED',
          'ARCHIVED'
        ]
      }
    };
  }
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['CONFIRMED', 'DISAPPROVED', 'SUSPENDED', 'TERMINATED', 'UNAPPROVED', 'UNCONFIRMED']
      }
    };
  }
  if (filterKey === 'inactive') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['DISAPPROVED', 'SUSPENDED', 'TERMINATED', 'UNAPPROVED', 'UNCONFIRMED']
      }
    };
  }
  let regObj = {
    module: 'Associate-Distinct',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.associateName,informationBasic.associateDescripton',
        order: 'asc'
      },
      searchCondition: 'AND',
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.associateStatus',
              conditionValue: searchObj
            }
          ]
        }
      ]
    }
  };

  return regObj;
};
export const makeAssociateSahreDistinctListObject = (
  selectedAssociateInfo,
  filterKey,
  numberPage,
  countPage
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'activeinactive') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['CONFIRMED', 'UNCONFIRMED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: [
          'CONFIRMED',
          'DISAPPROVED',
          'SUSPENDED',
          'TERMINATED',
          'UNAPPROVED',
          'UNCONFIRMED',
          'ARCHIVED'
        ]
      }
    };
  }
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['CONFIRMED', 'DISAPPROVED', 'SUSPENDED', 'TERMINATED', 'UNAPPROVED', 'UNCONFIRMED']
      }
    };
  }
  if (filterKey === 'inactive') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['DISAPPROVED', 'SUSPENDED', 'TERMINATED', 'UNAPPROVED', 'UNCONFIRMED']
      }
    };
  }

  let regObj = {
    module: 'Associate-Distinct',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.associateName,informationBasic.associateDescripton',
        order: 'asc'
      },
      searchCondition: 'AND',
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.associateStatus',
              conditionValue: searchObj
            }
          ]
        }
      ]
    }
  };

  return regObj;
};
export const makeAssociateScanRequestObject = (
  selectedAssociateInfo,
  filterKey,
  numberPage,
  countPage,
  searchStr
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'active') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['CONFIRMED']
      }
    };
  }

  if (filterKey === 'inactive') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'UNAPPROVED', 'UNCONFIRMED', 'DISAPPROVED']
      }
    };
  }
  if (filterKey === 'activeinactive') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'UNAPPROVED', 'UNCONFIRMED', 'DISAPPROVED']
      }
    };
  }
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['CONFIRMED', 'DISAPPROVED', 'SUSPENDED', 'TERMINATED', 'UNAPPROVED', 'UNCONFIRMED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: [
          'CONFIRMED',
          'DISAPPROVED',
          'SUSPENDED',
          'TERMINATED',
          'UNAPPROVED',
          'UNCONFIRMED',
          'ARCHIVED'
        ]
      }
    };
  }
  let regObj = {
    module: 'Associate-Distinct',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.associateName,informationBasic.associateDescription',
        order: 'asc'
      },
      searchCondition: 'AND',
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.associateStatus',
              conditionValue: searchObj
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.associateName',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.associateDescription',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn:
                'informationContact.associateAddressWebsitePrimary.associateAddressWebsite',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn:
                'informationContact.associateAddressWebsiteSecondary.associateAddressWebsite',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.associateTag.associateTagPrimary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.associateTag.associateTagSecondary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            }
          ]
        }
      ]
    }
  };

  return regObj;
};
export const makeAssesseeRoleCreateObj = (
  selectedAssociateInfo,
  filterKey,
  numberPage,
  countPage
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: 'ACTIVE'
    }
  };
  let requestObj = {
    module: 'Assessee-Role',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      associateAscendantPrimary:
        localStorage.getItem('parentId') === 'null' ? null : localStorage.getItem('parentId'),
      countPage: countPage,
      numberPage: numberPage,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.assesseeRoleName,informationBasic.assesseeRoleDescription',
        order: 'asc'
      },
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assesseeRoleStatus',
              conditionValue: searchObj
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assesseeRoleName',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: 'assessee'
                }
              }
            }
            // {
            //   dataType: 'boolean',
            //   conditionColumn: 'informationSetup.assesseeRoleDefault',
            //   conditionValue: {
            //     condition: 'eq',
            //     value: {
            //       from: 'true'
            //     }
            //   }
            // }
          ]
        }
      ]
    }
  };
  return requestObj;
};
export const makeManagerRoleCreateObj = (
  selectedAssociateInfo,
  filterKey,
  numberPage,
  countPage
) => {
  console.log('reviewlist--- manager || assistant role');
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  let requestObj = {};
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    requestObj = {
      module: 'Assessee-Role',
      action: 'Review-List',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        associateAscendantPrimary:
          localStorage.getItem('parentId') === 'null' ? null : localStorage.getItem('parentId'),
        countPage: countPage,
        numberPage: numberPage,
        filter: 'true',
        orderBy: {
          columnName: 'informationBasic.assesseeRoleName,informationBasic.assesseeRoleDescription',
          order: 'asc'
        },
        searchCondition: 'and',
        search: [
          {
            condition: 'and',
            searchBy: [
              {
                dataType: 'string',
                conditionColumn: 'informationEngagement.assesseeRoleStatus',
                conditionValue: {
                  condition: 'eq',
                  value: {
                    from: 'ACTIVE'
                  }
                }
              },
              {
                dataType: 'string',
                conditionColumn: 'informationBasic.assesseeRoleName',
                conditionValue: {
                  condition: 'ct',
                  value: {
                    from: 'manager'
                  }
                }
              },
              {
                dataType: 'string',
                conditionColumn:
                  'informationSetup.assesseeRoleClassification.assesseeRoleClassificationPrimary',
                conditionValue: {
                  condition: 'in',
                  value: {
                    in: [filterKey === 'bespoke' ? 'Bespoke' : 'Generic']
                  }
                }
              }
            ]
          }
        ]
      }
    };
  } else {
    requestObj = {
      module: 'Assessee-Role',
      action: 'Review-List',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        associateAscendantPrimary:
          localStorage.getItem('parentId') === 'null' ? null : localStorage.getItem('parentId'),
        countPage: countPage,
        numberPage: numberPage,
        filter: 'true',
        orderBy: {
          columnName: 'informationBasic.assesseeRoleName,informationBasic.assesseeRoleDescription',
          order: 'asc'
        },
        searchCondition: 'and',
        search: [
          {
            condition: 'and',
            searchBy: [
              {
                dataType: 'string',
                conditionColumn: 'informationEngagement.assesseeRoleStatus',
                conditionValue: searchObj
              },
              {
                dataType: 'string',
                conditionColumn: 'informationBasic.assesseeRoleName',
                conditionValue: {
                  condition: 'ct',
                  value: {
                    from: 'manager'
                  }
                }
              }
            ]
          }
        ]
      }
    };
  }

  return requestObj;
};
export const makeAssistantsRoleCreateObj = (
  selectedAssociateInfo,
  filterKey,
  numberPage,
  countPage
) => {
  console.log('reviewlist--- assistant || assistant role');
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  let requestObj = {};
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    requestObj = {
      module: 'Assessee-Role',
      action: 'Review-List',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        associateAscendantPrimary:
          localStorage.getItem('parentId') === 'null' ? null : localStorage.getItem('parentId'),
        countPage: countPage,
        numberPage: numberPage,
        filter: 'true',
        orderBy: {
          columnName: 'informationBasic.assesseeRoleName,informationBasic.assesseeRoleDescription',
          order: 'asc'
        },
        searchCondition: 'and',
        search: [
          {
            condition: 'and',
            searchBy: [
              {
                dataType: 'string',
                conditionColumn: 'informationEngagement.assesseeRoleStatus',
                conditionValue: {
                  condition: 'eq',
                  value: {
                    from: 'ACTIVE'
                  }
                }
              },
              {
                dataType: 'string',
                conditionColumn: 'informationBasic.assesseeRoleName',
                conditionValue: {
                  condition: 'ct',
                  value: {
                    from: 'assistant'
                  }
                }
              },
              {
                dataType: 'string',
                conditionColumn:
                  'informationSetup.assesseeRoleClassification.assesseeRoleClassificationPrimary',
                conditionValue: {
                  condition: 'in',
                  value: {
                    in: [filterKey === 'bespoke' ? 'Bespoke' : 'Generic']
                  }
                }
              }
            ]
          }
        ]
      }
    };
  } else {
    requestObj = {
      module: 'Assessee-Role',
      action: 'Review-List',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        associateAscendantPrimary:
          localStorage.getItem('parentId') === 'null' ? null : localStorage.getItem('parentId'),
        countPage: countPage,
        numberPage: numberPage,
        filter: 'true',
        orderBy: {
          columnName: 'informationBasic.assesseeRoleName,informationBasic.assesseeRoleDescription',
          order: 'asc'
        },
        searchCondition: 'and',
        search: [
          {
            condition: 'and',
            searchBy: [
              {
                dataType: 'string',
                conditionColumn: 'informationEngagement.assesseeRoleStatus',
                conditionValue: searchObj
              },
              {
                dataType: 'string',
                conditionColumn: 'informationBasic.assesseeRoleName',
                conditionValue: {
                  condition: 'ct',
                  value: {
                    from: 'assistant'
                  }
                }
              }
            ]
          }
        ]
      }
    };
  }

  return requestObj;
};
export const makeAdministratorRoleCreateObj = (
  selectedAssociateInfo,
  filterKey,
  numberPage,
  countPage
) => {
  console.log('reviewlist---administrators role');
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };

  if (filterKey === "all") {
    searchObj = {
      "condition": "in",
      "value": {
        "in": [
          "SUSPENDED",
          "TERMINATED",
          "ACTIVE"
        ]
      }
    }
  }

  let requestObj = {};
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    requestObj = {
      module: 'Assessee-Role',
      action: 'Review-List',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        associateAscendantPrimary:
          localStorage.getItem('parentId') === 'null' ? null : localStorage.getItem('parentId'),
        countPage: countPage,
        numberPage: numberPage,
        filter: 'true',
        orderBy: {
          columnName: 'informationBasic.assesseeRoleName,informationBasic.assesseeRoleDescription',
          order: 'asc'
        },
        searchCondition: 'and',
        search: [
          {
            condition: 'and',
            searchBy: [
              {
                dataType: 'string',
                conditionColumn: 'informationEngagement.assesseeRoleStatus',
                conditionValue: {
                  condition: 'eq',
                  value: {
                    from: 'ACTIVE'
                  }
                }
              },
              {
                dataType: 'string',
                conditionColumn: 'informationBasic.assesseeRoleName',
                conditionValue: {
                  condition: 'ct',
                  value: {
                    from: 'administrator'
                  }
                }
              },
              {
                dataType: 'string',
                conditionColumn:
                  'informationSetup.assesseeRoleClassification.assesseeRoleClassificationPrimary',
                conditionValue: {
                  condition: 'in',
                  value: {
                    in: [filterKey === 'bespoke' ? 'Bespoke' : 'Generic']
                  }
                }
              }
            ]
          }
        ]
      }
    };
  } else {
    requestObj = {
      module: 'Assessee-Role',
      action: 'Review-List',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        associateAscendantPrimary:
          localStorage.getItem('parentId') === 'null' ? null : localStorage.getItem('parentId'),
        countPage: countPage,
        numberPage: numberPage,
        filter: 'true',
        orderBy: {
          columnName: 'informationBasic.assesseeRoleName,informationBasic.assesseeRoleDescription',
          order: 'asc'
        },
        searchCondition: 'and',
        search: [
          {
            condition: 'and',
            searchBy: [
              {
                dataType: 'string',
                conditionColumn: 'informationEngagement.assesseeRoleStatus',
                conditionValue: searchObj
              },
              {
                dataType: 'string',
                conditionColumn: 'informationBasic.assesseeRoleName',
                conditionValue: {
                  condition: 'ct',
                  value: {
                    from: 'administrator'
                  }
                }
              }
            ]
          }
        ]
      }
    };
  }
  return requestObj;
};
export const makeAssesseeRoleObj = (
  selectedAssociateInfo,
  filterKey,
  numberPage,
  countPage,
  actionFilter = ''
) => {
  console.log('reviewlist--- AssesseeRole');
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  let requestObj = {};
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    requestObj = {
      module: 'Assessee-Role',
      action: 'Review-List',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        countPage: countPage,
        associateAscendantPrimary:
          localStorage.getItem('parentId') === 'null' ? null : localStorage.getItem('parentId'),
        numberPage: numberPage,
        filter: 'true',
        orderBy: {
          columnName: 'informationBasic.assesseeRoleName,informationBasic.assesseeRoleDescription',
          order: 'asc'
        },
        search: [
          {
            condition: 'and',
            searchBy: [
              {
                dataType: 'string',
                conditionColumn: 'informationEngagement.assesseeRoleStatus',
                conditionValue: {
                  condition: 'eq',
                  value: {
                    from: 'ACTIVE'
                  }
                }
              },
              {
                dataType: 'string',
                conditionColumn:
                  'informationSetup.assesseeRoleClassification.assesseeRoleClassificationPrimary',
                conditionValue: {
                  condition: 'in',
                  value: {
                    in: [filterKey === 'bespoke' ? 'Bespoke' : 'Generic']
                  }
                }
              }
            ]
          }
        ]
      }
    };
  } else {
    requestObj = {
      module: 'Assessee-Role',
      action: 'Review-List',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        countPage: countPage,
        associateAscendantPrimary:
          localStorage.getItem('parentId') === 'null' ? null : localStorage.getItem('parentId'),
        numberPage: numberPage,
        filter: 'true',
        orderBy: {
          columnName: 'informationBasic.assesseeRoleName,informationBasic.assesseeRoleDescription',
          order: 'asc'
        },
        search: [
          {
            condition: 'and',
            searchBy: [
              {
                dataType: 'string',
                conditionColumn: 'informationEngagement.assesseeRoleStatus',
                conditionValue: searchObj
              }
            ]
          }
        ]
      }
    };
  }
  if (actionFilter) requestObj['actionFilter'] = actionFilter;
  return requestObj;
};

export const makeAssesseeRoleAllocateObj = (
  selectedAssociateInfo,
  filterKey,
  numberPage,
  countPage,
  allocatedTagsArray
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  let requestObj = {};
  requestObj = {
    module: 'Assessee-Role',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      associateAscendantPrimary:
        localStorage.getItem('parentId') === 'null' ? null : localStorage.getItem('parentId'),
      numberPage: numberPage,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.assesseeRoleName,informationBasic.assesseeRoleDescription',
        order: 'asc'
      },
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assesseeRoleStatus',
              conditionValue: searchObj
            },
            {
              dataType: 'string',
              conditionColumn: 'id',
              conditionValue: {
                condition: 'nin',
                value: {
                  in: allocatedTagsArray
                }
              }
            }
          ]
        }
      ]
    }
  };
  return requestObj;
};
export const makeAssociateRoleAllocateObj = (
  selectedAssociateInfo,
  filterKey,
  numberPage,
  countPage,
  allocatedTagsArray
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  let requestObj = {
    module: 'Associate-Role',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.associateRoleName,informationBasic.associateRoleDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      searchCondition: 'AND',
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.associateRoleStatus',
              conditionValue: searchObj
            },
            {
              dataType: 'string',
              conditionColumn: 'id',
              conditionValue: {
                condition: 'nin',
                value: {
                  in: allocatedTagsArray
                }
              }
            }
          ]
        }
      ]
    }
  };
  return requestObj;
};
export const makeAssociateRoleObj = (selectedAssociateInfo, filterKey, numberPage, countPage) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  let requestObj = {};
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    requestObj = {
      module: 'Associate-Role',
      action: 'Review-List',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        filter: 'true',
        orderBy: {
          columnName:
            'informationBasic.associateRoleName,informationBasic.associateRoleDescription',
          order: 'asc'
        },
        numberPage: 0,
        countPage: 25,
        searchCondition: 'AND',
        search: [
          {
            condition: 'and',
            searchBy: [
              {
                dataType: 'string',
                conditionColumn: 'informationEngagement.associateRoleStatus',
                conditionValue: {
                  condition: 'eq',
                  value: {
                    from: 'ACTIVE'
                  }
                }
              },
              {
                dataType: 'string',
                conditionColumn:
                  'informationSetup.associateRoleClassification.associateRoleClassificationPrimary',
                conditionValue: {
                  condition: 'in',
                  value: {
                    in: [filterKey === 'bespoke' ? 'Bespoke' : 'Generic']
                  }
                }
              }
            ]
          }
        ]
      }
    };
  } else {
    requestObj = {
      module: 'Associate-Role',
      action: 'Review-List',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        filter: 'true',
        orderBy: {
          columnName:
            'informationBasic.associateRoleName,informationBasic.associateRoleDescription',
          order: 'asc'
        },
        numberPage: numberPage,
        countPage: countPage,
        searchCondition: 'AND',
        search: [
          {
            condition: 'or',
            searchBy: [
              {
                dataType: 'string',
                conditionColumn: 'informationEngagement.associateRoleStatus',
                conditionValue: searchObj
              }
            ]
          }
        ]
      }
    };
  }

  return requestObj;
};
export const makeAssociateRoleScanRequestObject = (
  selectedAssociateInfo,
  filterKey,
  numberPage,
  countPage,
  searchStr
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['ACTIVE']
      }
    };
  }
  let requestObj = {
    module: 'Associate-Role',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.associateRoleName,informationBasic.associateRoleDescription',
        order: 'asc'
      },
      numberPage: 0,
      countPage: 25,
      searchCondition: 'AND',
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.associateRoleStatus',
              conditionValue: searchObj
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.associateRoleName',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.associateRoleDescription',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn:
                'informationSetup.associateRoleClassification.associateRoleClassificationPrimary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from:
                    filterKey === 'bespoke' ? 'Bespoke' : filterKey === 'generic' ? 'Generic' : ''
                }
              }
            }
          ]
        }
      ]
    }
  };
  return requestObj;
};
export const makeAssociateRoleAllocateScanRequestObject = (
  selectedAssociateInfo,
  filterKey,
  numberPage,
  countPage,
  searchStr,
  allocatedTagsArray
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['ACTIVE']
      }
    };
  }
  let requestObj = {
    module: 'Associate-Role',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.associateRoleName,informationBasic.associateRoleDescription',
        order: 'asc'
      },
      numberPage: 0,
      countPage: 25,
      searchCondition: 'AND',
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.associateRoleStatus',
              conditionValue: searchObj
            },
            {
              dataType: 'string',
              conditionColumn: 'id',
              conditionValue: {
                condition: 'nin',
                value: {
                  in: allocatedTagsArray
                }
              }
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.associateRoleName',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.associateRoleDescription',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn:
                'informationSetup.associateRoleClassification.associateRoleClassificationPrimary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from:
                    filterKey === 'bespoke' ? 'Bespoke' : filterKey === 'generic' ? 'Generic' : ''
                }
              }
            }
          ]
        }
      ]
    }
  };
  return requestObj;
};
export const makeAssesseeRoleScanRequestObject = (
  selectedAssociateInfo,
  filterKey,
  numberPage,
  countPage,
  searchStr,
  actionFilter = ''
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['ACTIVE']
      }
    };
  }
  let requestObj = {
    action: 'Review-List',
    module: 'Assessee-Role',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.assesseeRoleName,informationBasic.assesseeRoleDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      searchCondition: 'AND',
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assesseeRoleStatus',
              conditionValue: searchObj
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assesseeRoleName',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assesseeRoleDescription',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn:
                'informationSetup.assesseeRoleClassification.assesseeRoleClassificationPrimary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from:
                    filterKey === 'bespoke' ? 'Bespoke' : filterKey === 'generic' ? 'Generic' : ''
                }
              }
            }
          ]
        }
      ]
    }
  };
  if (actionFilter) requestObj['actionFilter'] = actionFilter;
  return requestObj;
};
export const makeAssesseeRoleAllocateScanRequestObject = (
  selectedAssociateInfo,
  filterKey,
  numberPage,
  countPage,
  searchStr,
  allocatedTagsArray
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['ACTIVE']
      }
    };
  }
  let requestObj = {
    action: 'Review-List',
    module: 'Assessee-Role',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.assesseeRoleName,informationBasic.assesseeRoleDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      searchCondition: 'AND',
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assesseeRoleStatus',
              conditionValue: searchObj
            },
            {
              dataType: 'string',
              conditionColumn: 'id',
              conditionValue: {
                condition: 'nin',
                value: {
                  in: allocatedTagsArray
                }
              }
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assesseeRoleName',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assesseeRoleDescription',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn:
                'informationSetup.assesseeRoleClassification.assesseeRoleClassificationPrimary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from:
                    filterKey === 'bespoke' ? 'Bespoke' : filterKey === 'generic' ? 'Generic' : ''
                }
              }
            }
          ]
        }
      ]
    }
  };
  return requestObj;
};
export const makeAdminmManagerRoleScanRequestObject = (
  selectedAssociateInfo,
  filterKey,
  numberPage,
  countPage,
  searchStr,
  searchArr
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  let requestObj = {
    action: 'Review-List',
    module: 'Assessee-Role',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.assesseeRoleName,informationBasic.assesseeRoleDescription',
        order: 'asc'
      },
      numberPage: 0,
      countPage: 25,
      searchCondition: 'AND',
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assesseeRoleStatus',
              conditionValue: searchObj
            },
            {
              dataType: 'string',
              conditionColumn: 'id',
              conditionValue: {
                condition: 'in',
                value: {
                  in: searchArr
                }
              }
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assesseeRoleName',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assesseeRoleDescription',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            }
          ]
        }
      ]
    }
  };
  return requestObj;
};
export const makeAdminmManagerRoleAllocateScanRequestObject = (
  selectedAssociateInfo,
  filterKey,
  numberPage,
  countPage,
  searchStr,
  searchArr,
  allocatedTagsArray,
  actionFilter
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  let requestObj = {
    action: 'Review-List',
    module: 'Assessee-Role',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.assesseeRoleName,informationBasic.assesseeRoleDescription',
        order: 'asc'
      },
      numberPage: 0,
      countPage: countPage,
      searchCondition: 'AND',
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assesseeRoleStatus',
              conditionValue: searchObj
            },
            {
              dataType: 'string',
              conditionColumn: 'id',
              conditionValue: {
                condition: 'in',
                value: {
                  in: searchArr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'id',
              conditionValue: {
                condition: 'nin',
                value: {
                  in: allocatedTagsArray
                }
              }
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assesseeRoleName',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assesseeRoleDescription',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            }
          ]
        }
      ]
    }
  };
  if (actionFilter) requestObj['actionFilter'] = actionFilter;
  return requestObj;
};
export const makeAssesseeGroupObj = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  groupViewState,
  assignmentCreateType = ''
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  let requestObj = {};
  if (groupViewState === 'hierarchy') {
    requestObj = {
      module: 'Assessee-Group',
      action: 'Review-List',
      actionFilter: 'Hierarchy',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        filterTypeName:
          assignmentCreateType === 'iGuru_Assignment'
            // assignmentCreateType.toLowerCase().includes('analytics')
            ? ''
            : assignmentCreateType,
        status: filterKey.toUpperCase()
      }
    };
  } else {
    if (filterKey === 'bespoke' || filterKey === 'generic') {
      requestObj = {
        module: 'Assessee-Group',
        action: 'Review-List',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          filterTypeName:
            assignmentCreateType === 'iGuru_Assignment' 
              // assignmentCreateType.toLowerCase().includes('analytics')
              ? ''
              : assignmentCreateType,
          filter: 'true',
          orderBy: {
            columnName:
              'informationBasic.assesseeGroupName,informationBasic.assesseeGroupDescription',
            order: 'asc'
          },
          numberPage: numberPage,
          countPage: countPage,
          search: [
            {
              condition: 'and',
              searchBy: [
                {
                  dataType: 'string',
                  conditionColumn: 'informationEngagement.assesseeGroupStatus',
                  conditionValue: {
                    condition: 'eq',
                    value: {
                      from: 'ACTIVE'
                    }
                  }
                },
                {
                  dataType: 'string',
                  conditionColumn:
                    'informationSetup.assesseeGroupClassification.assesseeGroupClassificationPrimary',
                  conditionValue: {
                    condition: 'in',
                    value: {
                      in: [filterKey === 'bespoke' ? 'Bespoke' : 'Generic']
                    }
                  }
                }
              ]
            }
          ]
        }
      };
    } else {
      requestObj = {
        module: 'Assessee-Group',
        action: 'Review-List',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          filterTypeName:
            assignmentCreateType === 'iGuru_Assignment' 
              // assignmentCreateType.toLowerCase().includes('analytics')
              ? ''
              : assignmentCreateType,
          filter: 'true',
          orderBy: {
            columnName:
              'informationBasic.assesseeGroupName,informationBasic.assesseeGroupDescription',
            order: 'asc'
          },
          numberPage: numberPage,
          countPage: countPage,
          search: [
            {
              condition: 'and',
              searchBy: [
                {
                  dataType: 'string',
                  conditionColumn: 'informationEngagement.assesseeGroupStatus',
                  conditionValue: searchObj
                }
              ]
            }
          ]
        }
      };
    }
  }
  return requestObj;
};
export const makeAssesseeAllocateGroupObj = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  groupViewState,
  assignmentCreateType = '',
  allocatedTagsArray
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  let requestObj = {};
  requestObj = {
    module: 'Assessee-Group',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filterTypeName:
        assignmentCreateType === 'iGuru_Assignment' 
          // assignmentCreateType.toLowerCase().includes('analytics')
          ? ''
          : assignmentCreateType,

      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.assesseeGroupName,informationBasic.assesseeGroupDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assesseeGroupStatus',
              conditionValue: searchObj
            },
            {
              dataType: 'string',
              conditionColumn: 'id',
              conditionValue: {
                condition: 'nin',
                value: {
                  in: allocatedTagsArray
                }
              }
            }
          ]
        }
      ]
    }
  };
  return requestObj;
};
export const makeAssesseeTypeObj = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  actionFilter = ''
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  let requestObj = {};
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    requestObj = {
      module: 'Assessee-Type',
      action: 'Review-List',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        filter: 'true',
        orderBy: {
          columnName: 'informationBasic.assesseeTypeName,informationBasic.assesseeTypeDescription',
          order: 'asc'
        },
        numberPage: numberPage,
        countPage: countPage,
        search: [
          {
            condition: 'and',
            searchBy: [
              {
                dataType: 'string',
                conditionColumn: 'informationEngagement.assesseeTypeStatus',
                conditionValue: {
                  condition: 'eq',
                  value: {
                    from: 'ACTIVE'
                  }
                }
              },
              {
                dataType: 'string',
                conditionColumn:
                  'informationSetup.assesseeTypeClassification.assesseeTypeClassificationPrimary',
                conditionValue: {
                  condition: 'in',
                  value: {
                    in: [filterKey === 'bespoke' ? 'Bespoke' : 'Generic']
                  }
                }
              }
            ]
          }
        ]
      }
    };
  } else {
    requestObj = {
      module: 'Assessee-Type',
      action: 'Review-List',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        filter: 'true',
        orderBy: {
          columnName: 'informationBasic.assesseeTypeName,informationBasic.assesseeTypeDescription',
          order: 'asc'
        },
        numberPage: numberPage,
        countPage: countPage,
        search: [
          {
            condition: 'and',
            searchBy: [
              {
                dataType: 'string',
                conditionColumn: 'informationEngagement.assesseeTypeStatus',
                conditionValue: searchObj
              }
            ]
          }
        ]
      }
    };
  }
  if (actionFilter) requestObj['actionFilter'] = actionFilter;
  return requestObj;
};

export const makeAssesseeWithoutmineTypeObj = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  let requestObj = {};
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    requestObj = {
      module: 'Assessee-Type',
      action: 'Review-List',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        filter: 'true',
        orderBy: {
          columnName: 'informationBasic.assesseeTypeName,informationBasic.assesseeTypeDescription',
          order: 'asc'
        },
        numberPage: numberPage,
        countPage: countPage,
        search: [
          {
            condition: 'and',
            searchBy: [
              {
                dataType: 'string',
                conditionColumn: 'informationEngagement.assesseeTypeStatus',
                conditionValue: {
                  condition: 'eq',
                  value: {
                    from: 'ACTIVE'
                  }
                }
              },
              {
                dataType: 'string',
                conditionColumn:
                  'informationSetup.assesseeTypeClassification.assesseeTypeClassificationPrimary',
                conditionValue: {
                  condition: 'in',
                  value: {
                    in: [filterKey === 'bespoke' ? 'Bespoke' : 'Generic']
                  }
                }
              }
            ]
          }
        ]
      }
    };
  } else {
    requestObj = {
      module: 'Assessee-Type',
      action: 'Review-List',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        filter: 'true',
        orderBy: {
          columnName: 'informationBasic.assesseeTypeName,informationBasic.assesseeTypeDescription',
          order: 'asc'
        },
        numberPage: numberPage,
        countPage: countPage,
        search: [
          {
            condition: 'and',
            searchBy: [
              {
                dataType: 'string',
                conditionColumn: 'informationEngagement.assesseeTypeStatus',
                conditionValue: searchObj
              },
              {
                dataType: 'string',
                conditionColumn: 'id',
                conditionValue: {
                  condition: 'nin',
                  value: {
                    in: [
                      process.env.REACT_APP_ASSESSEE_IGURU_MINE_CAREER,
                      process.env.REACT_APP_ASSESSEE_IGURU_MINE_EDUCATION,
                      process.env.REACT_APP_ASSESSEE_IGURU_MINE_OCCUPATION,
                      process.env.REACT_APP_ASSESSEE_IGURU_MINE_PULSE
                    ]
                  }
                }
              }
            ]
          }
        ]
      }
    };
  }
  return requestObj;
};
export const makeAssesseeAllocateTypeObj = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  allocatedTagsArray
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  let requestObj = {
    module: 'Assessee-Type',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.assesseeTypeName,informationBasic.assesseeTypeDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assesseeTypeStatus',
              conditionValue: searchObj
            },
            {
              dataType: 'string',
              conditionColumn: 'id',
              conditionValue: {
                condition: 'nin',
                value: {
                  in: allocatedTagsArray
                }
              }
            }
          ]
        }
      ]
    }
  };
  return requestObj;
};
export const makeAssociateTypeObj = (selectedAssociateInfo, filterKey, countPage, numberPage) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  let requestObj = {};
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    requestObj = {
      module: 'Associate-Type',
      action: 'Review-List',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        filter: 'true',
        orderBy: {
          columnName:
            'informationBasic.associateTypeName,informationBasic.associateTypeDescription',
          order: 'asc'
        },
        numberPage: numberPage,
        countPage: countPage,
        search: [
          {
            condition: 'and',
            searchBy: [
              {
                dataType: 'string',
                conditionColumn: 'informationEngagement.associateTypeStatus',
                conditionValue: {
                  condition: 'eq',
                  value: {
                    from: 'ACTIVE'
                  }
                }
              },
              {
                dataType: 'string',
                conditionColumn:
                  'informationSetup.associateTypeClassification.associateTypeClassificationPrimary',
                conditionValue: {
                  condition: 'in',
                  value: {
                    in: [filterKey === 'bespoke' ? 'Bespoke' : 'Generic']
                  }
                }
              }
            ]
          }
        ]
      }
    };
  } else {
    requestObj = {
      module: 'Associate-Type',
      action: 'Review-List',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        filter: 'true',
        orderBy: {
          columnName:
            'informationBasic.associateTypeName,informationBasic.associateTypeDescription',
          order: 'asc'
        },
        numberPage: numberPage,
        countPage: countPage,
        search: [
          {
            condition: 'and',
            searchBy: [
              {
                dataType: 'string',
                conditionColumn: 'informationEngagement.associateTypeStatus',
                conditionValue: searchObj
              }
            ]
          }
        ]
      }
    };
  }
  return requestObj;
};
export const makeAssociateAllocateTypeObj = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  allocatedTagsArray
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  let requestObj = {};
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    requestObj = {
      module: 'Associate-Type',
      action: 'Review-List',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        filter: 'true',
        orderBy: {
          columnName:
            'informationBasic.associateTypeName,informationBasic.associateTypeDescription',
          order: 'asc'
        },
        numberPage: numberPage,
        countPage: countPage,
        search: [
          {
            condition: 'and',
            searchBy: [
              {
                dataType: 'string',
                conditionColumn: 'informationEngagement.associateTypeStatus',
                conditionValue: {
                  condition: 'eq',
                  value: {
                    from: 'ACTIVE'
                  }
                }
              },
              {
                dataType: 'string',
                conditionColumn:
                  'informationSetup.associateTypeClassification.associateTypeClassificationPrimary',
                conditionValue: {
                  condition: 'in',
                  value: {
                    in: [filterKey === 'bespoke' ? 'Bespoke' : 'Generic']
                  }
                }
              }
            ]
          }
        ]
      }
    };
  } else {
    requestObj = {
      module: 'Associate-Type',
      action: 'Review-List',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        filter: 'true',
        orderBy: {
          columnName:
            'informationBasic.associateTypeName,informationBasic.associateTypeDescription',
          order: 'asc'
        },
        numberPage: numberPage,
        countPage: countPage,
        search: [
          {
            condition: 'and',
            searchBy: [
              {
                dataType: 'string',
                conditionColumn: 'informationEngagement.associateTypeStatus',
                conditionValue: searchObj
              },
              {
                dataType: 'string',
                conditionColumn: 'id',
                conditionValue: {
                  condition: 'nin',
                  value: {
                    in: allocatedTagsArray
                  }
                }
              }
            ]
          }
        ]
      }
    };
  }
  return requestObj;
};
export const makeItemsTypeObj = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  actionFilter = ''
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  let requestObj = {};
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    requestObj = {
      action: 'Review-List',
      module: 'Item-Type',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        filter: 'true',
        orderBy: {
          columnName: 'informationBasic.itemTypeName,informationBasic.itemTypeDescription',
          order: 'asc'
        },
        numberPage: numberPage,
        countPage: countPage,
        search: [
          {
            condition: 'and',
            searchBy: [
              {
                dataType: 'string',
                conditionColumn: 'informationEngagement.itemTypeStatus',
                conditionValue: {
                  condition: 'eq',
                  value: {
                    from: 'ACTIVE'
                  }
                }
              },
              {
                dataType: 'string',
                conditionColumn:
                  'informationSetup.itemTypeClassification.itemTypeClassificationPrimary',
                conditionValue: {
                  condition: 'in',
                  value: {
                    in: [filterKey === 'bespoke' ? 'Bespoke' : 'Generic']
                  }
                }
              }
            ]
          }
        ]
      }
    };
  } else {
    requestObj = {
      action: 'Review-List',
      module: 'Item-Type',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        filter: 'true',
        orderBy: {
          columnName: 'informationBasic.itemTypeName,informationBasic.itemTypeDescription',
          order: 'asc'
        },
        numberPage: numberPage,
        countPage: countPage,
        search: [
          {
            condition: 'and',
            searchBy: [
              {
                dataType: 'string',
                conditionColumn: 'informationEngagement.itemTypeStatus',
                conditionValue: searchObj
              }
            ]
          }
        ]
      }
    };
  }

  if (actionFilter) requestObj['actionFilter'] = actionFilter;
  return requestObj;
};
export const makeItemsAllocateTypeObj = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  allocatedTagsArray
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  let requestObj = {};
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    requestObj = {
      action: 'Review-List',
      module: 'Item-Type',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        filter: 'true',
        orderBy: {
          columnName: 'informationBasic.itemTypeName,informationBasic.itemTypeDescription',
          order: 'asc'
        },
        numberPage: numberPage,
        countPage: countPage,
        search: [
          {
            condition: 'and',
            searchBy: [
              {
                dataType: 'string',
                conditionColumn: 'informationEngagement.itemTypeStatus',
                conditionValue: {
                  condition: 'eq',
                  value: {
                    from: 'ACTIVE'
                  }
                }
              },
              {
                dataType: 'string',
                conditionColumn:
                  'informationSetup.itemTypeClassification.itemTypeClassificationPrimary',
                conditionValue: {
                  condition: 'in',
                  value: {
                    in: [filterKey === 'bespoke' ? 'Bespoke' : 'Generic']
                  }
                }
              }
            ]
          }
        ]
      }
    };
  } else {
    requestObj = {
      action: 'Review-List',
      module: 'Item-Type',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        filter: 'true',
        orderBy: {
          columnName: 'informationBasic.itemTypeName,informationBasic.itemTypeDescription',
          order: 'asc'
        },
        numberPage: numberPage,
        countPage: countPage,
        search: [
          {
            condition: 'and',
            searchBy: [
              {
                dataType: 'string',
                conditionColumn: 'informationEngagement.itemTypeStatus',
                conditionValue: searchObj
              },
              {
                dataType: 'string',
                conditionColumn: 'id',
                conditionValue: {
                  condition: 'nin',
                  value: {
                    in: allocatedTagsArray
                  }
                }
              }
            ]
          }
        ]
      }
    };
  }

  return requestObj;
};
export const makeAssesseeTypeScanRequestObject = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  searchStr,
  actionFilter
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['ACTIVE']
      }
    };
  }
  let requestObj = {
    module: 'Assessee-Type',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.assesseeTypeName,informationBasic.assesseeTypeDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assesseeTypeStatus',
              conditionValue: searchObj
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assesseeTypeName',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assesseeTypeDescription',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              //
              conditionColumn:
                'informationSetup.assesseeTypeClassification.assesseeTypeClassificationPrimary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from:
                    filterKey === 'bespoke' ? 'Bespoke' : filterKey === 'generic' ? 'Generic' : ''
                }
              }
            }
          ]
        }
      ]
    }
  };
  if (actionFilter) requestObj['actionFilter'] = actionFilter;
  return requestObj;
};
export const makeAssesseeTypeAllocateScanRequestObject = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  searchStr,
  allocatedTagsArray
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['ACTIVE']
      }
    };
  }
  let requestObj = {
    module: 'Assessee-Type',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.assesseeTypeName,informationBasic.assesseeTypeDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assesseeTypeStatus',
              conditionValue: searchObj
            },
            {
              dataType: 'string',
              conditionColumn: 'id',
              conditionValue: {
                condition: 'nin',
                value: {
                  in: allocatedTagsArray
                }
              }
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assesseeTypeName',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assesseeTypeDescription',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              //
              conditionColumn:
                'informationSetup.assesseeTypeClassification.assesseeTypeClassificationPrimary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from:
                    filterKey === 'bespoke' ? 'Bespoke' : filterKey === 'generic' ? 'Generic' : ''
                }
              }
            }
          ]
        }
      ]
    }
  };
  return requestObj;
};
export const makeAssociateTypeScanRequestObject = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  searchStr
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['ACTIVE']
      }
    };
  }
  let requestObj = {
    module: 'Associate-Type',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.associateTypeName,informationBasic.associateTypeDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.associateTypeStatus',
              conditionValue: searchObj
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.associateTypeName',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.associateTypeDescription',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn:
                'informationSetup.associateTypeClassification.associateTypeClassificationPrimary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from:
                    filterKey === 'bespoke' ? 'Bespoke' : filterKey === 'generic' ? 'Generic' : ''
                }
              }
            }
          ]
        }
      ]
    }
  };
  return requestObj;
};
export const makeAssesseeGroupScanRequestObject = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  searchStr,
  assignmentCreateType = ''
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }

  if (filterKey === 'bespoke' || filterKey === 'generic') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['ACTIVE']
      }
    };
  }
  let searchArr = [
    {
      condition: 'and',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn: 'informationEngagement.assesseeGroupStatus',
          conditionValue: searchObj
        }
      ]
    },
    {
      condition: 'or',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.assesseeGroupName',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.assesseeGroupDescription',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        }
      ]
    }
  ];

  if (filterKey === 'bespoke' || filterKey === 'generic') {
    searchArr.push({
      condition: 'or',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn:
            'informationSetup.assesseeGroupClassification.assesseeGroupClassificationPrimary',
          conditionValue: {
            condition: 'ct',
            value: {
              from: filterKey === 'bespoke' ? 'Bespoke' : filterKey === 'generic' ? 'Generic' : ''
            }
          }
        }
      ]
    });
  }

  let requestObj = {
    module: 'Assessee-Group',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      filterTypeName: assignmentCreateType === 'iGuru_Assignment' ? '' : assignmentCreateType,
      // filterTypeName:
      //   assignmentCreateType === 'iGuru_Assignment' ||
      //     assignmentCreateType.toLowerCase().includes('analytics')
      //     ? ''
      //     : assignmentCreateType,
      orderBy: {
        columnName: 'informationBasic.assesseeGroupName,informationBasic.assesseeGroupDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      searchCondition: 'AND',
      search: searchArr
    }
  };

  return requestObj;
};
export const makeAssesseeGroupAllocateScanRequestObject = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  searchStr,
  allocatedTagsArray,
  assignmentCreateType
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }

  if (filterKey === 'bespoke' || filterKey === 'generic') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['ACTIVE']
      }
    };
  }
  let searchArr = [
    {
      condition: 'and',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn: 'informationEngagement.assesseeGroupStatus',
          conditionValue: searchObj
        },
        {
          dataType: 'string',
          conditionColumn: 'id',
          conditionValue: {
            condition: 'nin',
            value: {
              in: allocatedTagsArray
            }
          }
        }
      ]
    },
    {
      condition: 'or',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.assesseeGroupName',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.assesseeGroupDescription',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        }
      ]
    }
  ];

  let requestObj = {
    module: 'Assessee-Group',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      filterTypeName:
        assignmentCreateType === 'iGuru_Assignment' 
          // assignmentCreateType.toLowerCase().includes('analytics')
          ? ''
          : assignmentCreateType,
      orderBy: {
        columnName: 'informationBasic.assesseeGroupName,informationBasic.assesseeGroupDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      searchCondition: 'AND',
      search: searchArr
    }
  };
  return requestObj;
};
export const makeAssociateGroupObj = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  groupViewState,
  assignmentCreateType = ''
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  let requestObj = {};
  if (groupViewState === 'hierarchy') {
    requestObj = {
      module: 'Associate-Group',
      action: 'Review-List',
      actionFilter: 'Hierarchy',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        filterTypeName: assignmentCreateType === 'iGuru_Assignment' ? '' : assignmentCreateType,
        // filterTypeName:
        //   assignmentCreateType === 'iGuru_Assignment' ||
        //     assignmentCreateType.toLowerCase().includes('analytics') ||
        //     assignmentCreateType.toLowerCase().includes('marketplace') //temp
        //     ? ''
        //     : assignmentCreateType,
        status: filterKey.toUpperCase()
      }
    };
  } else {
    if (filterKey === 'bespoke' || filterKey === 'generic') {
      requestObj = {
        module: 'Associate-Group',
        action: 'Review-List',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          filterTypeName: assignmentCreateType === 'iGuru_Assignment' ? '' : assignmentCreateType,
          // filterTypeName:
          //   assignmentCreateType === 'iGuru_Assignment' ||
          //     assignmentCreateType.toLowerCase().includes('analytics') ||
          //     assignmentCreateType.toLowerCase().includes('marketplace') //temp
          //     ? ''
          //     : assignmentCreateType,
          filter: 'true',
          orderBy: {
            columnName:
              'informationBasic.associateGroupName,informationBasic.associateGroupDescription',
            order: 'asc'
          },
          numberPage: numberPage,
          countPage: countPage,
          search: [
            {
              condition: 'and',
              searchBy: [
                {
                  dataType: 'string',
                  conditionColumn: 'informationEngagement.associateGroupStatus',
                  conditionValue: {
                    condition: 'eq',
                    value: {
                      from: 'ACTIVE'
                    }
                  }
                },
                {
                  dataType: 'string',
                  conditionColumn:
                    'informationSetup.associateGroupClassification.associateGroupClassificationPrimary',
                  conditionValue: {
                    condition: 'in',
                    value: {
                      in: [filterKey === 'bespoke' ? 'Bespoke' : 'Generic']
                    }
                  }
                }
              ]
            }
          ]
        }
      };
    } else {
      requestObj = {
        module: 'Associate-Group',
        action: 'Review-List',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          filterTypeName: assignmentCreateType === 'iGuru_Assignment' ? '' : assignmentCreateType,
          // filterTypeName:
          //   assignmentCreateType === 'iGuru_Assignment' ||
          //     assignmentCreateType.toLowerCase().includes('analytics') ||
          //     assignmentCreateType.toLowerCase().includes('marketplace') //temp
          //     ? ''
          //     : assignmentCreateType,
          filter: 'true',
          orderBy: {
            columnName:
              'informationBasic.associateGroupName,informationBasic.associateGroupDescription',
            order: 'asc'
          },
          numberPage: numberPage,
          countPage: countPage,
          search: [
            {
              condition: 'and',
              searchBy: [
                {
                  dataType: 'string',
                  conditionColumn: 'informationEngagement.associateGroupStatus',
                  conditionValue: searchObj
                }
                // {
                //   dataType: 'string',
                //   conditionColumn: 'informationSetup.associateGroupClassification.associateGroupClassificationPrimary',
                //   conditionValue: searchObj
                // }
              ]
            }
          ]
        }
      };
    }
  }
  return requestObj;
};

export const makeAssociateAllocateGroupObj = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  groupViewState,
  assignmentCreateType = '',
  allocatedTagsArray
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  let requestObj = {
    module: 'Associate-Group',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filterTypeName: assignmentCreateType === 'iGuru_Assignment' ? '' : assignmentCreateType,
      // filterTypeName:
      //   assignmentCreateType === 'iGuru_Assignment' ||
      //     assignmentCreateType.toLowerCase().includes('analytics')
      //     ? ''
      //     : assignmentCreateType,
      filter: 'true',
      orderBy: {
        columnName:
          'informationBasic.associateGroupName,informationBasic.associateGroupDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.associateGroupStatus',
              conditionValue: searchObj
            },
            {
              dataType: 'string',
              conditionColumn: 'id',
              conditionValue: {
                condition: 'nin',
                value: {
                  in: allocatedTagsArray
                }
              }
            }
          ]
        }
      ]
    }
  };
  return requestObj;
};
export const makeItemObj = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  actionFilter = ''
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'activeinactive') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['UNPUBLISHED', 'PUBLISHED']
      }
    };
  }
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'UNPUBLISHED', 'PUBLISHED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'UNPUBLISHED', 'PUBLISHED', 'ARCHIVED']
      }
    };
  }
  let requestObj = {
    module: 'Item-Distinct',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.itemName,informationBasic.itemDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.itemStatus',
              conditionValue: searchObj
            }
          ]
        }
      ]
    }
  };
  if (actionFilter) requestObj['actionFilter'] = actionFilter;
  return requestObj;
};
export const makeItemScanObj = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  searchStr,
  actionFilter = ''
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED']
      }
    };
  }
  if (filterKey === 'activeinactive') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'UNPUBLISHED', 'PUBLISHED', 'ARCHIVED']
      }
    };
  }
  let requestObj = {
    module: 'Item-Distinct',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.itemName,informationBasic.itemDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.itemStatus',
              conditionValue: searchObj
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.itemName',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.itemDescription',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.itemTag.itemTagPrimary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            }
          ]
        }
      ]
    }
  };

  if (actionFilter) requestObj['actionFilter'] = actionFilter;

  return requestObj;
};
export const makeItemGroupObj = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  groupViewState,
  assignmentCreateType = '',
  actionFilter = ''
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  let requestObj = {};
  if (groupViewState === 'hierarchy') {
    requestObj = {
      module: 'Item-Group',
      action: 'Review-List',
      actionFilter: actionFilter ? 'Shared-Hierarchy' : 'Hierarchy',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        filterTypeName: assignmentCreateType === 'iGuru_Assignment' ? '' : assignmentCreateType,
        // filterTypeName:
        //   assignmentCreateType === 'iGuru_Assignment' ||
        //     assignmentCreateType.toLowerCase().includes('analytics')
        //     ? ''
        //     : assignmentCreateType,
        status: filterKey.toUpperCase()
      }
    };
  } else {
    if (filterKey === 'bespoke' || filterKey === 'generic') {
      requestObj = {
        module: 'Item-Group',
        action: 'Review-List',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          filterTypeName: assignmentCreateType === 'iGuru_Assignment' ? '' : assignmentCreateType,
          // filterTypeName:
          //   assignmentCreateType === 'iGuru_Assignment' ||
          //     assignmentCreateType.toLowerCase().includes('analytics')
          //     ? ''
          //     : assignmentCreateType,
          filter: 'true',
          orderBy: {
            columnName: 'informationBasic.itemGroupName,informationBasic.itemGroupDescription',
            order: 'asc'
          },
          numberPage: numberPage,
          countPage: countPage,
          search: [
            {
              condition: 'and',
              searchBy: [
                {
                  dataType: 'string',
                  conditionColumn: 'informationEngagement.itemGroupStatus',
                  conditionValue: {
                    condition: 'eq',
                    value: {
                      from: 'ACTIVE'
                    }
                  }
                },
                {
                  dataType: 'string',
                  conditionColumn:
                    'informationSetup.itemGroupClassification.itemGroupClassificationPrimary',
                  conditionValue: {
                    condition: 'in',
                    value: {
                      in: [filterKey === 'bespoke' ? 'Bespoke' : 'Generic']
                    }
                  }
                }
              ]
            }
          ]
        }
      };
      if (actionFilter) requestObj['actionFilter'] = actionFilter;
    } else {
      requestObj = {
        module: 'Item-Group',
        action: 'Review-List',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          filterTypeName: assignmentCreateType === 'iGuru_Assignment' ? '' : assignmentCreateType,
          // filterTypeName:
          //   assignmentCreateType === 'iGuru_Assignment' ||
          //     assignmentCreateType.toLowerCase().includes('analytics')
          //     ? ''
          //     : assignmentCreateType,
          filter: 'true',
          orderBy: {
            columnName: 'informationBasic.itemGroupName,informationBasic.itemGroupDescription',
            order: 'asc'
          },
          numberPage: numberPage,
          countPage: countPage,
          search: [
            {
              condition: 'and',
              searchBy: [
                {
                  dataType: 'string',
                  conditionColumn: 'informationEngagement.itemGroupStatus',
                  conditionValue: searchObj
                }
              ]
            }
          ]
        }
      };

      if (actionFilter) requestObj['actionFilter'] = actionFilter;
    }
  }
  return requestObj;
};
export const makeItemGroupAllocateObj = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  groupViewState,
  assignmentCreateType = '',
  allocatedTagsArray
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  let requestObj = {
    module: 'Item-Group',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filterTypeName: assignmentCreateType === 'iGuru_Assignment' ? '' : assignmentCreateType,
      // filterTypeName:
      //   assignmentCreateType === 'iGuru_Assignment' ||
      //     assignmentCreateType.toLowerCase().includes('analytics')
      //     ? ''
      //     : assignmentCreateType,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.itemGroupName,informationBasic.itemGroupDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.itemGroupStatus',
              conditionValue: searchObj
            },
            {
              dataType: 'string',
              conditionColumn: 'id',
              conditionValue: {
                condition: 'nin',
                value: {
                  in: allocatedTagsArray
                }
              }
            }
          ]
        }
      ]
    }
  };
  return requestObj;
};
export const makeItemGroupScanObj = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  searchStr,
  actionFilter = ''
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['ACTIVE']
      }
    };
  }
  let searchArr = [
    {
      condition: 'and',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn: 'informationEngagement.itemGroupStatus',
          conditionValue: searchObj
        }
      ]
    },
    {
      condition: 'or',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.itemGroupName',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.itemGroupDescription',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        }
      ]
    }
  ];
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    searchArr.push({
      condition: 'or',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn:
            'informationSetup.itemGroupClassification.itemGroupClassificationPrimary',
          conditionValue: {
            condition: 'ct',
            value: {
              from: filterKey === 'bespoke' ? 'Bespoke' : filterKey === 'generic' ? 'Generic' : ''
            }
          }
        }
      ]
    });
  }

  let requestObj = {
    module: 'Item-Group',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.itemGroupName,informationBasic.itemGroupDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      searchCondition: 'AND',
      search: searchArr
    }
  };
  if (actionFilter) requestObj['actionFilter'] = actionFilter;
  return requestObj;
};
export const makeItemGroupAllocateScanObj = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  searchStr,
  allocatedTagsArray
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['ACTIVE']
      }
    };
  }
  let searchArr = [
    {
      condition: 'and',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn: 'informationEngagement.itemGroupStatus',
          conditionValue: searchObj
        },
        {
          dataType: 'string',
          conditionColumn: 'id',
          conditionValue: {
            condition: 'nin',
            value: {
              in: allocatedTagsArray
            }
          }
        }
      ]
    },
    {
      condition: 'or',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.itemGroupName',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.itemGroupDescription',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        }
      ]
    }
  ];
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    searchArr.push({
      condition: 'or',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn:
            'informationSetup.itemGroupClassification.itemGroupClassificationPrimary',
          conditionValue: {
            condition: 'ct',
            value: {
              from: filterKey === 'bespoke' ? 'Bespoke' : filterKey === 'generic' ? 'Generic' : ''
            }
          }
        }
      ]
    });
  }

  let requestObj = {
    module: 'Item-Group',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.itemGroupName,informationBasic.itemGroupDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      searchCondition: 'AND',
      search: searchArr
    }
  };
  return requestObj;
};
export const makeItemTypeScanObj = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  searchStr,
  actionFilter = ''
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['ACTIVE']
      }
    };
  }
  let requestObj = {
    module: 'Item-Type',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.itemTypeName,informationBasic.itemTypeDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.itemTypeStatus',
              conditionValue: searchObj
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.itemTypeName',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.itemTypeDescription',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn:
                'informationSetup.itemTypeClassification.itemTypeClassificationPrimary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from:
                    filterKey === 'bespoke' ? 'Bespoke' : filterKey === 'generic' ? 'Generic' : ''
                }
              }
            }
          ]
        }
      ]
    }
  };
  if (actionFilter) requestObj['actionFilter'] = actionFilter;
  return requestObj;
};
export const makeItemTypeAllocateScanObj = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  searchStr,
  allocatedTagsArray
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['ACTIVE']
      }
    };
  }
  let requestObj = {
    module: 'Item-Type',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.itemTypeName,informationBasic.itemTypeDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.itemTypeStatus',
              conditionValue: searchObj
            },
            {
              dataType: 'string',
              conditionColumn: 'id',
              conditionValue: {
                condition: 'nin',
                value: {
                  in: allocatedTagsArray
                }
              }
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.itemTypeName',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.itemTypeDescription',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn:
                'informationSetup.itemTypeClassification.itemTypeClassificationPrimary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from:
                    filterKey === 'bespoke' ? 'Bespoke' : filterKey === 'generic' ? 'Generic' : ''
                }
              }
            }
          ]
        }
      ]
    }
  };
  return requestObj;
};

export const makeAssociateGroupScanRequestObject = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  searchStr,
  assignmentCreateType = ''
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['ACTIVE']
      }
    };
  }
  let searchArr = [
    {
      condition: 'and',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn: 'informationEngagement.associateGroupStatus',
          conditionValue: searchObj
        }
      ]
    },
    {
      condition: 'or',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.associateGroupName',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.associateGroupDescription',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        }
      ]
    }
  ];
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    searchArr.push({
      condition: 'or',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn:
            'informationSetup.associateGroupClassification.associateGroupClassificationPrimary',
          conditionValue: {
            condition: 'ct',
            value: {
              from: filterKey === 'bespoke' ? 'Bespoke' : filterKey === 'generic' ? 'Generic' : ''
            }
          }
        }
      ]
    });
  }
  let requestObj = {
    module: 'Associate-Group',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      filterTypeName: assignmentCreateType === 'iGuru_Assignment' ? '' : assignmentCreateType,
      // filterTypeName:
      //   assignmentCreateType === 'iGuru_Assignment' ||
      //     assignmentCreateType.toLowerCase().includes('analytics') ||
      //     assignmentCreateType.toLowerCase().includes('marketplace') //temp
      //     ? ''
      //     : assignmentCreateType,
      orderBy: {
        columnName:
          'informationBasic.associateGroupName,informationBasic.associateGroupDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      search: searchArr
    }
  };
  return requestObj;
};
export const makeAssociateGroupAllocateScanRequestObject = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  searchStr,
  allocatedTagsArray
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['ACTIVE']
      }
    };
  }
  let searchArr = [
    {
      condition: 'and',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn: 'informationEngagement.associateGroupStatus',
          conditionValue: searchObj
        },
        {
          dataType: 'string',
          conditionColumn: 'id',
          conditionValue: {
            condition: 'nin',
            value: {
              in: allocatedTagsArray
            }
          }
        }
      ]
    },
    {
      condition: 'or',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.associateGroupName',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.associateGroupDescription',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        }
      ]
    }
  ];
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    searchArr.push({
      condition: 'or',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn:
            'informationSetup.associateGroupClassification.associateGroupClassificationPrimary',
          conditionValue: {
            condition: 'ct',
            value: {
              from: filterKey === 'bespoke' ? 'Bespoke' : filterKey === 'generic' ? 'Generic' : ''
            }
          }
        }
      ]
    });
  }
  let requestObj = {
    module: 'Associate-Group',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName:
          'informationBasic.associateGroupName,informationBasic.associateGroupDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      search: searchArr
    }
  };
  return requestObj;
};
export const makeAssessmentGroupObj = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  groupViewState,
  assignmentCreateType = ''
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  let requestObj = {};
  if (groupViewState === 'hierarchy') {
    requestObj = {
      module: 'Assessment-Group',
      action: 'Review-List',
      actionFilter: 'Hierarchy',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        filterTypeName: assignmentCreateType === 'iGuru_Assignment' ? '' : assignmentCreateType,
        // filterTypeName:
        //   assignmentCreateType === 'iGuru_Assignment' ||
        //     assignmentCreateType.toLowerCase().includes('analytics')
        //     ? ''
        //     : assignmentCreateType,
        status: filterKey.toUpperCase()
      }
    };
  } else {
    if (filterKey === 'bespoke' || filterKey === 'generic') {
      requestObj = {
        module: 'Assessment-Group',
        action: 'Review-List',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          filterTypeName: assignmentCreateType === 'iGuru_Assignment' ? '' : assignmentCreateType,
          // filterTypeName:
          //   assignmentCreateType === 'iGuru_Assignment' ||
          //     assignmentCreateType.toLowerCase().includes('analytics')
          //     ? ''
          //     : assignmentCreateType,
          filter: 'true',
          orderBy: {
            columnName:
              'informationBasic.assessmentGroupName,informationBasic.assessmentGroupDescription',
            order: 'asc'
          },
          numberPage: numberPage,
          countPage: countPage,
          search: [
            {
              condition: 'and',
              searchBy: [
                {
                  dataType: 'string',
                  conditionColumn: 'informationEngagement.assessmentGroupStatus',
                  conditionValue: {
                    condition: 'eq',
                    value: {
                      from: 'ACTIVE'
                    }
                  }
                },
                {
                  dataType: 'string',
                  conditionColumn:
                    'informationSetup.assessmentGroupClassification.assessmentGroupClassificationPrimary',
                  conditionValue: {
                    condition: 'eq',
                    value: {
                      from: filterKey === 'bespoke' ? 'Bespoke' : 'Generic'
                    }
                  }
                }
              ]
            }
          ]
        }
      };
    } else {
      requestObj = {
        module: 'Assessment-Group',
        action: 'Review-List',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          filterTypeName: assignmentCreateType === 'iGuru_Assignment' ? '' : assignmentCreateType,
          // filterTypeName:
          //   assignmentCreateType === 'iGuru_Assignment' ||
          //     assignmentCreateType.toLowerCase().includes('analytics')
          //     ? ''
          //     : assignmentCreateType,
          filter: 'true',
          orderBy: {
            columnName:
              'informationBasic.assessmentGroupName,informationBasic.assessmentGroupDescription',
            order: 'asc'
          },
          numberPage: numberPage,
          countPage: countPage,
          search: [
            {
              condition: 'and',
              searchBy: [
                {
                  dataType: 'string',
                  conditionColumn: 'informationEngagement.assessmentGroupStatus',
                  conditionValue: searchObj
                }
              ]
            }
          ]
        }
      };
    }
  }

  return requestObj;
};
export const makeAssessmentGroupAllocatedObj = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  groupViewState,
  assignmentCreateType = '',
  allocatedTagsArray
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  let requestObj = {
    module: 'Assessment-Group',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filterTypeName: assignmentCreateType === 'iGuru_Assignment' ? '' : assignmentCreateType,
      // filterTypeName:
      //   assignmentCreateType === 'iGuru_Assignment' ||
      //     assignmentCreateType.toLowerCase().includes('analytics')
      //     ? ''
      //     : assignmentCreateType,
      filter: 'true',
      orderBy: {
        columnName:
          'informationBasic.assessmentGroupName,informationBasic.assessmentGroupDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assessmentGroupStatus',
              conditionValue: searchObj
            },
            {
              dataType: 'string',
              conditionColumn: 'id',
              conditionValue: {
                condition: 'nin',
                value: {
                  in: allocatedTagsArray
                }
              }
            }
          ]
        }
      ]
    }
  };
  return requestObj;
};
export const makeAssessmentGroupScanRequestObject = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  searchStr,
  assignmentCreateType = ''
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['ACTIVE']
      }
    };
  }
  let searchArr = [
    {
      condition: 'and',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn: 'informationEngagement.assessmentGroupStatus',
          conditionValue: searchObj
        }
      ]
    },
    {
      condition: 'or',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.assessmentGroupName',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.assessmentGroupDescription',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        }
      ]
    }
  ];
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    searchArr.push({
      condition: 'or',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn:
            'informationSetup.assessmentGroupClassification.assessmentGroupClassificationPrimary',
          conditionValue: {
            condition: 'ct',
            value: {
              from: filterKey === 'bespoke' ? 'Bespoke' : filterKey === 'generic' ? 'Generic' : ''
            }
          }
        }
      ]
    });
  }
  let requestObj = {
    module: 'Assessment-Group',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      filterTypeName: assignmentCreateType === 'iGuru_Assignment' ? '' : assignmentCreateType,
      // filterTypeName:
      //   assignmentCreateType === 'iGuru_Assignment' ||
      //     assignmentCreateType.toLowerCase().includes('analytics')
      //     ? ''
      //     : assignmentCreateType,
      orderBy: {
        columnName: 'informationBasic.assessmentGroupName,assessmentGroupDescription',
        order: 'asc'
      },
      searchCondition: 'AND',
      numberPage: numberPage,
      countPage: countPage,
      search: searchArr
    }
  };
  return requestObj;
};
export const makeAssessmentGroupAllocateScanRequestObject = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  searchStr,
  allocatedTagsArray
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['ACTIVE']
      }
    };
  }
  let searchArr = [
    {
      condition: 'and',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn: 'informationEngagement.assessmentGroupStatus',
          conditionValue: searchObj
        },
        {
          dataType: 'string',
          conditionColumn: 'id',
          conditionValue: {
            condition: 'nin',
            value: {
              in: allocatedTagsArray
            }
          }
        }
      ]
    },
    {
      condition: 'or',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.assessmentGroupName',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.assessmentGroupDescription',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        }
      ]
    }
  ];
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    searchArr.push({
      condition: 'or',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn:
            'informationSetup.assessmentGroupClassification.assessmentGroupClassificationPrimary',
          conditionValue: {
            condition: 'ct',
            value: {
              from: filterKey === 'bespoke' ? 'Bespoke' : filterKey === 'generic' ? 'Generic' : ''
            }
          }
        }
      ]
    });
  }
  let requestObj = {
    module: 'Assessment-Group',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.assessmentGroupName,assessmentGroupDescription',
        order: 'asc'
      },
      searchCondition: 'AND',
      numberPage: numberPage,
      countPage: countPage,
      search: searchArr
    }
  };
  return requestObj;
};
export const makeAssignmentGroupObj = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  groupViewState,
  assignmentCreateType
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  let requestObj = {};
  if (groupViewState === 'hierarchy') {
    requestObj = {
      filterTypeName: assignmentCreateType === 'iGuru_Assignment' ? '' : assignmentCreateType,
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      status: filterKey.toUpperCase()
    };
  } else {
    if (filterKey === 'bespoke' || filterKey === 'generic') {
      requestObj = {
        filterTypeName: assignmentCreateType === 'iGuru_Assignment' ? '' : assignmentCreateType,
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        filter: 'true',
        orderBy: {
          columnName:
            'informationBasic.assignmentGroupName,informationBasic.assignmentGroupDescription',
          order: 'asc'
        },
        numberPage: numberPage,
        countPage: countPage,
        search: [
          {
            condition: 'and',
            searchBy: [
              {
                dataType: 'string',
                conditionColumn: 'informationEngagement.assignmentGroupStatus',
                conditionValue: {
                  condition: 'eq',
                  value: {
                    from: 'ACTIVE'
                  }
                }
              },
              {
                dataType: 'string',
                conditionColumn:
                  'informationSetup.assignmentGroupClassification.assignmentGroupClassificationPrimary',
                conditionValue: {
                  condition: 'eq',
                  value: {
                    from: filterKey === 'bespoke' ? 'Bespoke' : 'Generic'
                  }
                }
              }
            ]
          }
        ]
      };
    } else {
      requestObj = {
        filterTypeName: assignmentCreateType,
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        filter: 'true',
        filterTypeName: assignmentCreateType === 'iGuru_Assignment' ? '' : assignmentCreateType,
        orderBy: {
          columnName:
            'informationBasic.assignmentGroupName,informationBasic.assignmentGroupDescription',
          order: 'asc'
        },
        numberPage: numberPage,
        countPage: countPage,
        search: [
          {
            condition: 'and',
            searchBy: [
              {
                dataType: 'string',
                conditionColumn: 'informationEngagement.assignmentGroupStatus',
                conditionValue: searchObj
              }
            ]
          }
        ]
      };
    }
  }

  return requestObj;
};
export const makeAssignmentGroupAllocateObj = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  groupViewState,
  assignmentCreateType,
  allocatedTagsArray
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  let requestObj = {
    filterTypeName: assignmentCreateType,
    assesseeId: selectedAssociateInfo?.assesseeId,
    associateId:
      selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
    filter: 'true',
    filterTypeName: assignmentCreateType === 'iGuru_Assignment' ? '' : assignmentCreateType,
    orderBy: {
      columnName:
        'informationBasic.assignmentGroupName,informationBasic.assignmentGroupDescription',
      order: 'asc'
    },
    numberPage: numberPage,
    countPage: countPage,
    search: [
      {
        condition: 'and',
        searchBy: [
          {
            dataType: 'string',
            conditionColumn: 'informationEngagement.assignmentGroupStatus',
            conditionValue: searchObj
          },
          {
            dataType: 'string',
            conditionColumn: 'id',
            conditionValue: {
              condition: 'nin',
              value: {
                in: allocatedTagsArray
              }
            }
          }
        ]
      }
    ]
  };
  return requestObj;
};
export const makeAssignmentGroupScanRequestObject = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  searchStr,
  assignmentCreateType
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['ACTIVE']
      }
    };
  }
  let searchArr = [
    {
      condition: 'and',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn: 'informationEngagement.assignmentGroupStatus',
          conditionValue: searchObj
        }
      ]
    },
    {
      condition: 'or',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.assignmentGroupName',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.assignmentGroupDescription',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        }
      ]
    }
  ];
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    searchArr.push({
      condition: 'or',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn:
            'informationSetup.assignmentGroupClassification.assignmentGroupClassificationPrimary',
          conditionValue: {
            condition: 'ct',
            value: {
              from: filterKey === 'bespoke' ? 'Bespoke' : filterKey === 'generic' ? 'Generic' : ''
            }
          }
        }
      ]
    });
  }
  let requestObj = {
    assesseeId: selectedAssociateInfo?.assesseeId,
    associateId:
      selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
    filter: 'true',
    filterTypeName:
      assignmentCreateType === 'iGuru_Assignment' ||
        assignmentCreateType.toLowerCase().includes('marketplace')
        ? ''
        : assignmentCreateType,
    orderBy: {
      columnName:
        'informationBasic.assignmentGroupName,informationBasic.assignmentGroupDescription',
      order: 'asc'
    },
    numberPage: numberPage,
    countPage: countPage,
    search: searchArr
  };
  return requestObj;
};
export const makeAssignmentGroupAllocateScanRequestObject = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  searchStr,
  assignmentCreateType,
  allocatedTagsArray
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['ACTIVE']
      }
    };
  }
  let searchArr = [
    {
      condition: 'and',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn: 'informationEngagement.assignmentGroupStatus',
          conditionValue: searchObj
        },
        {
          dataType: 'string',
          conditionColumn: 'id',
          conditionValue: {
            condition: 'nin',
            value: {
              in: allocatedTagsArray
            }
          }
        }
      ]
    },
    {
      condition: 'or',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.assignmentGroupName',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.assignmentGroupDescription',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        }
      ]
    }
  ];
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    searchArr.push({
      condition: 'or',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn:
            'informationSetup.assignmentGroupClassification.assignmentGroupClassificationPrimary',
          conditionValue: {
            condition: 'ct',
            value: {
              from: filterKey === 'bespoke' ? 'Bespoke' : filterKey === 'generic' ? 'Generic' : ''
            }
          }
        }
      ]
    });
  }
  let requestObj = {
    assesseeId: selectedAssociateInfo?.assesseeId,
    associateId:
      selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
    filter: 'true',
    filterTypeName: assignmentCreateType === 'iGuru_Assignment' ? '' : assignmentCreateType,
    orderBy: {
      columnName:
        'informationBasic.assignmentGroupName,informationBasic.assignmentGroupDescription',
      order: 'asc'
    },
    numberPage: numberPage,
    countPage: countPage,
    search: searchArr
  };
  return requestObj;
};
export const makeAssessmentTypeObj = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  actionFilter = ''
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  let requestObj = {};
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    requestObj = {
      module: 'Assessment-Type',
      action: 'Review-List',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        filter: 'true',
        orderBy: {
          columnName:
            'informationBasic.assessmentTypeName,informationBasic.assessmentTypeDescription',
          order: 'asc'
        },
        numberPage: numberPage,
        countPage: countPage,
        search: [
          {
            condition: 'and',
            searchBy: [
              {
                dataType: 'string',
                conditionColumn: 'informationEngagement.assessmentTypeStatus',
                conditionValue: {
                  condition: 'eq',
                  value: {
                    from: 'ACTIVE'
                  }
                }
              },
              {
                dataType: 'string',
                conditionColumn:
                  'informationSetup.assessmentTypeClassification.assessmentTypeClassificationPrimary',
                conditionValue: {
                  condition: 'eq',
                  value: {
                    from: filterKey === 'bespoke' ? 'Bespoke' : 'Generic'
                  }
                }
              }
            ]
          }
        ]
      }
    };
  } else {
    requestObj = {
      module: 'Assessment-Type',
      action: 'Review-List',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        filter: 'true',
        orderBy: {
          columnName:
            'informationBasic.assessmentTypeName,informationBasic.assessmentTypeDescription',
          order: 'asc'
        },
        numberPage: numberPage,
        countPage: countPage,
        search: [
          {
            condition: 'and',
            searchBy: [
              {
                dataType: 'string',
                conditionColumn: 'informationEngagement.assessmentTypeStatus',
                conditionValue: searchObj
              }
            ]
          }
        ]
      }
    };
  }

  if (actionFilter) requestObj['actionFilter'] = actionFilter;
  return requestObj;
};
export const makeAssessmentTypeAllocateObj = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  allocatedTagsArray
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  let requestObj = {
    module: 'Assessment-Type',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName:
          'informationBasic.assessmentTypeName,informationBasic.assessmentTypeDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assessmentTypeStatus',
              conditionValue: searchObj
            },
            {
              dataType: 'string',
              conditionColumn: 'id',
              conditionValue: {
                condition: 'nin',
                value: {
                  in: allocatedTagsArray
                }
              }
            }
          ]
        }
      ]
    }
  };
  return requestObj;
};
export const makeAssignmentTypeObj = (selectedAssociateInfo, filterKey, countPage, numberPage) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  if (filterKey === 'inactive') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED']
      }
    };
  }
  let requestObj = {};
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    requestObj = {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName:
          'informationBasic.assignmentTypeName,informationBasic.assignmentTypeDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assignmentTypeStatus',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: 'ACTIVE'
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn:
                'informationSetup.assignmentTypeClassification.assignmentTypeClassificationPrimary',
              conditionValue: {
                condition: 'in',
                value: {
                  in: [filterKey === 'bespoke' ? 'Bespoke' : 'Generic']
                }
              }
            }
          ]
        }
      ]
    };
  } else {
    requestObj = {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName:
          'informationBasic.assignmentTypeName,informationBasic.assignmentTypeDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assignmentTypeStatus',
              conditionValue: searchObj
            }
          ]
        }
      ]
    };
  }

  return requestObj;
};
export const makeAssignmentTypeAllocateObj = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  allocatedTagsArray
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  if (filterKey === 'inactive') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED']
      }
    };
  }
  let requestObj = {
    assesseeId: selectedAssociateInfo?.assesseeId,
    associateId:
      selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
    filter: 'true',
    orderBy: {
      columnName: 'informationBasic.assignmentTypeName,informationBasic.assignmentTypeDescription',
      order: 'asc'
    },
    numberPage: numberPage,
    countPage: countPage,
    search: [
      {
        condition: 'and',
        searchBy: [
          {
            dataType: 'string',
            conditionColumn: 'informationEngagement.assignmentTypeStatus',
            conditionValue: searchObj
          },
          {
            dataType: 'string',
            conditionColumn: 'id',
            conditionValue: {
              condition: 'nin',
              value: {
                in: allocatedTagsArray
              }
            }
          }
        ]
      }
    ]
  };
  return requestObj;
};
export const makeAssignmentReviewListRequestObject = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  assignmentCreateType,
  actionFilter = ''
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED', 'SUSPENDED', 'TERMINATED', 'ADMINISTERED']
      }
    };
  }
  if (filterKey === 'activeinactive') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED', 'ADMINISTERED']
      }
    };
  }
  if (filterKey === 'active') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'ADMINISTERED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED', 'SUSPENDED', 'TERMINATED', 'ADMINISTERED', 'ARCHIVED']
      }
    };
  }
  let searchArr = [
    {
      dataType: 'string',
      conditionColumn: 'informationEngagement.assignmentStatus',
      conditionValue: searchObj
    }
  ];
  if (assignmentCreateType === 'iGuru_Assignment') {
    let searchObj = {
      dataType: 'string',
      conditionColumn: 'informationAllocation.assignmentType.assignmentTypeSecondary',
      conditionValue: {
        condition: 'nin',
        value: {
          in: [
            process.env.REACT_APP_IGURU_ANALYTICS_CAREER,
            process.env.REACT_APP_IGURU_ANALYTICS_EDUCATION,
            process.env.REACT_APP_IGURU_ANALYTICS_OCCUPATION,
            process.env.REACT_APP_IGURU_ANALYTICS_PULSE,
            process.env.REACT_APP_IGURU_MINE_CAREER,
            process.env.REACT_APP_IGURU_MINE_EDUCATION,
            process.env.REACT_APP_IGURU_MINE_OCCUPATION,
            process.env.REACT_APP_IGURU_MINE_PULSE,
            process.env.REACT_APP_IGURU_MARKETPLACE_CAREER_ASSIGNMENTS,
            process.env.REACT_APP_IGURU_MARKETPLACE_EDUCATION_ASSIGNMENTS,
            process.env.REACT_APP_IGURU_MARKETPLACE_OCCUPATION_ASSIGNMENTS,
            process.env.REACT_APP_IGURU_MARKETPLACE_PULSE_ASSIGNMENTS,
            process.env.REACT_APP_IGURU_ANALYTICS_CAREER_ASSIGNMENTS,
            process.env.REACT_APP_IGURU_ANALYTICS_EDUCATION_ASSIGNMENTS,
            process.env.REACT_APP_IGURU_ANALYTICS_OCCUPATION_ASSIGNMENTS,
            process.env.REACT_APP_IGURU_ANALYTICS_PULSE_ASSIGNMENTS
          ]
        }
      }
    };
    searchArr.push(searchObj);
  }
  let requestObj = {
    module: 'Assignment-Distinct',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filterTypeName: assignmentCreateType,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.assignmentName,informationBasic.assignmentDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      search: [
        {
          condition: 'and',
          searchBy: searchArr
        }
      ]
    }
  };
  if (actionFilter) requestObj['actionFilter'] = actionFilter;
  return requestObj;
};

export const makeAssessmentReviewListRequestObject = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  assignmentCreateType = ''
) => {
  let searchBy = [];
  let tempSearchObj = {
    dataType: 'string',
    conditionColumn: 'informationEngagement.assessmentStatus',
    conditionValue: searchObj
  };
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'activeinactive') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED']
      }
    };
  }
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED', 'ARCHIVED']
      }
    };
  }
  tempSearchObj.conditionValue = searchObj;

  searchBy.push(tempSearchObj);

  if (assignmentCreateType !== '') {
    let id =
      process.env[`REACT_APP_${assignmentCreateType.replace(' ', '_').toUpperCase()}_ASSESSMENTS`];
    if (id) {
      let x = {
        dataType: 'string',
        conditionColumn: 'informationAllocation.assessmentType.assessmentTypeSecondary',
        conditionValue: {
          condition: 'in',
          value: {
            in: [
              `${process.env[
              `REACT_APP_${assignmentCreateType.replace(' ', '_').toUpperCase()}_ASSESSMENTS`
              ]
              }`
            ]
          }
        }
      };
      searchBy.push(x);
    }
  }
  let requestObj = {
    module: 'Assessment-Distinct',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.assessmentName,informationBasic.assessmentDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      search: [
        {
          condition: 'and',
          searchBy: searchBy
        }
      ]
    }
  };
  return requestObj;
};
export const makeAssignmentTypeScanRequestObject = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  searchStr
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['ACTIVE']
      }
    };
  }
  let requestObj = {
    assesseeId: selectedAssociateInfo?.assesseeId,
    associateId:
      selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
    filter: 'true',
    orderBy: {
      columnName: 'informationBasic.assignmentTypeName,informationBasic.assignmentTypeDescription',
      order: 'asc'
    },
    numberPage: numberPage,
    countPage: countPage,
    search: [
      {
        condition: 'and',
        searchBy: [
          {
            dataType: 'string',
            conditionColumn: 'informationEngagement.assignmentTypeStatus',
            conditionValue: searchObj
          }
        ]
      },
      {
        condition: 'or',
        searchBy: [
          {
            dataType: 'string',
            conditionColumn: 'informationBasic.assignmentTypeName',
            conditionValue: {
              condition: 'ct',
              value: {
                from: searchStr
              }
            }
          },
          {
            dataType: 'string',
            conditionColumn: 'informationBasic.assignmentTypeDescription',
            conditionValue: {
              condition: 'ct',
              value: {
                from: searchStr
              }
            }
          }
        ]
      },
      {
        condition: 'or',
        searchBy: [
          {
            dataType: 'string',
            conditionColumn:
              'informationSetup.assignmentTypeClassification.assignmentTypeClassificationPrimary',
            conditionValue: {
              condition: 'ct',
              value: {
                from: filterKey === 'bespoke' ? 'Bespoke' : filterKey === 'generic' ? 'Generic' : ''
              }
            }
          }
        ]
      }
    ]
  };
  return requestObj;
};
export const makeAssignmentTypeAllocateScanRequestObject = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  searchStr,
  allocatedTagsArray
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['ACTIVE']
      }
    };
  }
  let requestObj = {
    assesseeId: selectedAssociateInfo?.assesseeId,
    associateId:
      selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
    filter: 'true',
    orderBy: {
      columnName: 'informationBasic.assignmentTypeName,informationBasic.assignmentTypeDescription',
      order: 'asc'
    },
    numberPage: numberPage,
    countPage: countPage,
    search: [
      {
        condition: 'and',
        searchBy: [
          {
            dataType: 'string',
            conditionColumn: 'informationEngagement.assignmentTypeStatus',
            conditionValue: searchObj
          },
          {
            dataType: 'string',
            conditionColumn: 'id',
            conditionValue: {
              condition: 'nin',
              value: {
                in: allocatedTagsArray
              }
            }
          }
        ]
      },
      {
        condition: 'or',
        searchBy: [
          {
            dataType: 'string',
            conditionColumn: 'informationBasic.assignmentTypeName',
            conditionValue: {
              condition: 'ct',
              value: {
                from: searchStr
              }
            }
          },
          {
            dataType: 'string',
            conditionColumn: 'informationBasic.assignmentTypeDescription',
            conditionValue: {
              condition: 'ct',
              value: {
                from: searchStr
              }
            }
          }
        ]
      },
      {
        condition: 'or',
        searchBy: [
          {
            dataType: 'string',
            conditionColumn:
              'informationSetup.assignmentTypeClassification.assignmentTypeClassificationPrimary',
            conditionValue: {
              condition: 'ct',
              value: {
                from: filterKey === 'bespoke' ? 'Bespoke' : filterKey === 'generic' ? 'Generic' : ''
              }
            }
          }
        ]
      }
    ]
  };
  return requestObj;
};
export const makeAssessmentTypeScanRequestObject = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  searchStr,
  actionFilter = ''
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['ACTIVE']
      }
    };
  }
  let requestObj = {
    module: 'Assessment-Type',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName:
          'informationBasic.assessmentTypeName,informationBasic.assessmentTypeDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      searchCondition: 'AND',
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assessmentTypeStatus',
              conditionValue: searchObj
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assessmentTypeName',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assessmentTypeDescription',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              //
              conditionColumn:
                'informationSetup.assessmentTypeClassification.assessmentTypeClassificationPrimary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from:
                    filterKey === 'bespoke' ? 'Bespoke' : filterKey === 'generic' ? 'Generic' : ''
                }
              }
            }
          ]
        }
      ]
    }
  };

  if (actionFilter) requestObj['actionFilter'] = actionFilter;
  return requestObj;
};
export const makeAssessmentTypeAllocateScanRequestObject = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  searchStr,
  allocatedTagsArray
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['ACTIVE']
      }
    };
  }
  let requestObj = {
    module: 'Assessment-Type',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName:
          'informationBasic.assessmentTypeName,informationBasic.assessmentTypeDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      searchCondition: 'AND',
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assessmentTypeStatus',
              conditionValue: searchObj
            },
            {
              dataType: 'string',
              conditionColumn: 'id',
              conditionValue: {
                condition: 'nin',
                value: {
                  in: allocatedTagsArray
                }
              }
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assessmentTypeName',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assessmentTypeDescription',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              //
              conditionColumn:
                'informationSetup.assessmentTypeClassification.assessmentTypeClassificationPrimary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from:
                    filterKey === 'bespoke' ? 'Bespoke' : filterKey === 'generic' ? 'Generic' : ''
                }
              }
            }
          ]
        }
      ]
    }
  };
  return requestObj;
};
export const makeAssignmentScanRequestObject = (
  selectedAssociateInfo,
  filterKey,
  numberPage,
  countPage,
  searchStr,
  assignmentCreateType
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED', 'SUSPENDED', 'TERMINATED', 'ADMINISTERED']
      }
    };
  }
  if (filterKey === 'activeinactive') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED', 'ADMINISTERED']
      }
    };
  }
  if (filterKey === 'active') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'ADMINISTERED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED', 'SUSPENDED', 'TERMINATED', 'ADMINISTERED', 'ARCHIVED']
      }
    };
  }
  let searchArr = [
    {
      dataType: 'string',
      conditionColumn: 'informationEngagement.assignmentStatus',
      conditionValue: searchObj
    }
  ];
  if (assignmentCreateType === 'iGuru_Assignment') {
    let searchObj = {
      dataType: 'string',
      conditionColumn: 'informationAllocation.assignmentType.assignmentTypeSecondary',
      conditionValue: {
        condition: 'nin',
        value: {
          in: [
            process.env.REACT_APP_IGURU_ANALYTICS_CAREER,
            process.env.REACT_APP_IGURU_ANALYTICS_EDUCATION,
            process.env.REACT_APP_IGURU_ANALYTICS_OCCUPATION,
            process.env.REACT_APP_IGURU_ANALYTICS_PULSE,
            process.env.REACT_APP_IGURU_MINE_CAREER,
            process.env.REACT_APP_IGURU_MINE_EDUCATION,
            process.env.REACT_APP_IGURU_MINE_OCCUPATION,
            process.env.REACT_APP_IGURU_MINE_PULSE,
            process.env.REACT_APP_IGURU_MARKETPLACE_CAREER_ASSIGNMENTS,
            process.env.REACT_APP_IGURU_MARKETPLACE_EDUCATION_ASSIGNMENTS,
            process.env.REACT_APP_IGURU_MARKETPLACE_OCCUPATION_ASSIGNMENTS,
            process.env.REACT_APP_IGURU_MARKETPLACE_PULSE_ASSIGNMENTS,
            process.env.REACT_APP_IGURU_ANALYTICS_CAREER_ASSIGNMENTS,
            process.env.REACT_APP_IGURU_ANALYTICS_EDUCATION_ASSIGNMENTS,
            process.env.REACT_APP_IGURU_ANALYTICS_OCCUPATION_ASSIGNMENTS,
            process.env.REACT_APP_IGURU_ANALYTICS_PULSE_ASSIGNMENTS
          ]
        }
      }
    };
    searchArr.push(searchObj);
  }
  let regObj = {
    action: 'Review-List',
    module: 'Assignment-Distinct',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      filterTypeName: assignmentCreateType,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.assignmentName,informationBasic.assignmentDescription',
        order: 'asc'
      },
      searchCondition: 'AND',
      search: [
        {
          condition: 'and',
          searchBy: searchArr
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assignmentName',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assignmentDescription',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assignmentTag.assignmentTagPrimary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            }
          ]
        }
      ]
    }
  };
  return regObj;
};

export const makeMineAssignmentScanRequestObject = (
  assesseeId,
  associateId,
  filterKey,
  numberPage,
  countPage,
  searchStr,
  assignmentCreateType,
  isScan,
  isSignOn
) => {
  // let searchObj = {
  //   condition: 'eq',
  //   value: {
  //     from: filterKey.toUpperCase()
  //   }
  // };
  // if (filterKey === 'all') {
  // searchObj = {
  //   condition: 'in',
  //   value: {
  //     in: ['ADMINISTERED']
  //   }
  // };
  // }
  // if (filterKey === 'activeinactive') {
  //   searchObj = {
  //     condition: 'in',
  //     value: {
  //       in: ['UNPUBLISHED', 'PUBLISHED', 'ADMINISTERED']
  //     }
  //   };
  // }
  // if (filterKey === '') {
  //   searchObj = {
  //     condition: 'in',
  //     value: {
  //       in: ['PUBLISHED', 'UNPUBLISHED', 'SUSPENDED', 'TERMINATED', 'ADMINISTERED', 'ARCHIVED']
  //     }
  //   };
  // }
  // if(assignmentCreateType === 'iGuru_Assignment'){
  //   let searchObj = {
  //     dataType: 'string',
  //     conditionColumn: 'informationAllocation.assignmentType.assignmentTypeSecondary',
  //     conditionValue:{
  //       condition: 'in',
  //       value:{
  //         in:[
  //           process.env.REACT_APP_IGURU_ANALYTICS_CAREER,
  //           process.env.REACT_APP_IGURU_ANALYTICS_EDUCATION,
  //           process.env.REACT_APP_IGURU_ANALYTICS_OCCUPATION,
  //           process.env.REACT_APP_IGURU_ANALYTICS_PULSE,
  //           process.env.REACT_APP_IGURU_MINE_CAREER,
  //           process.env.REACT_APP_IGURU_MINE_EDUCATION,
  //           process.env.REACT_APP_IGURU_MINE_OCCUPATION,
  //           process.env.REACT_APP_IGURU_MINE_PULSE,
  //           process.env.REACT_APP_IGURU_MARKETPLACE_CAREER,
  //           process.env.REACT_APP_IGURU_MARKETPLACE_EDUCATION,
  //           process.env.REACT_APP_IGURU_MARKETPLACE_OCCUPATION,
  //           process.env.REACT_APP_IGURU_MARKETPLACE_PULSE,
  //         ]
  //       }
  //     }
  //   }
  //   searchArr.push(searchObj)
  // }

  let searchObj = {
    condition: 'in',
    value: {
      in: ['ADMINISTERED']
    }
  };

  let searchArr = [
    {
      dataType: 'string',
      conditionColumn: 'informationEngagement.assignmentStatus',
      conditionValue: searchObj
    }
  ];

  let regObj = {
    action: 'Review-List',
    module: 'Assignment-Distinct',
    actionFilter: 'Assignment-Mine',
    signOn: isSignOn,
    requestObject: {
      assesseeId: assesseeId,
      associateId: associateId,
      countPage: countPage,
      numberPage: numberPage,
      // filterTypeName: assignmentCreateType,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.assignmentName,informationBasic.assignmentDescription',
        order: 'asc'
      },
      searchCondition: 'AND',
      search: [
        {
          condition: 'and',
          searchBy: searchArr
        }
      ]
    }
  };

  if (isScan) {
    regObj.requestObject.search.push({
      condition: 'or',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.assignmentName',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.assignmentDescription',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        }
      ]
    });
  }

  return regObj;
};

export const makeAssessmentScanRequestObject = (
  selectedAssociateInfo,
  filterKey,
  numberPage,
  countPage,
  searchStr
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED']
      }
    };
  }
  if (filterKey === 'activeinactive') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['UNPUBLISHED', 'PUBLISHED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED', 'SUSPENDED', 'TERMINATED', 'ARCHIVED']
      }
    };
  }
  let regObj = {
    module: 'Assessment-Distinct',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.assessmentName,informationBasic.assessmentDescription',
        order: 'asc'
      },
      searchCondition: 'AND',
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assessmentStatus',
              conditionValue: searchObj
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assessmentName',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assessmentDescription',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assessmentTag.assessmentTagPrimary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            }
          ]
        }
      ]
    }
  };

  return regObj;
};

export const makeInternalNodeObj = (
  selectedAssociateInfo,
  filterKey,
  numberPage,
  countPage,
  nodeViewState,
  customActionFilter = ''
) => {
  let filterValue = '';

  if (
    filterKey === 'assessees' ||
    filterKey === 'assessments' ||
    filterKey === 'assignments' ||
    filterKey === 'associates' ||
    filterKey === 'items' ||
    filterKey === 'job-profiles' ||
    filterKey === 'culture-profiles'
  ) {
    filterValue = 'ACTIVE';
  } else {
    filterValue = filterKey.toUpperCase();
  }
  let requestObj = {
    module: 'Associate-Node',
    action: 'Review-List',
    actionFilter:
      customActionFilter !== ''
        ? customActionFilter
        : nodeViewState === 'hierarchy'
          ? 'Associate-Node-Hierarchy'
          : 'Associate-Node-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      //status: filterKey.toUpperCase()
      status: filterValue
    }
  };
  return requestObj;
};
export const makeInternalAllocateNodeObj = (
  selectedAssociateInfo,
  filterKey,
  numberPage,
  countPage,
  nodeViewState,
  allocatedTagsArray
) => {
  let filterValue = '';

  if (
    filterKey === 'assessees' ||
    filterKey === 'assessments' ||
    filterKey === 'assignments' ||
    filterKey === 'associates' ||
    filterKey === 'items' ||
    filterKey === 'job-profiles' ||
    filterKey === 'culture-profiles'
  ) {
    filterValue = 'ACTIVE';
  } else {
    filterValue = filterKey.toUpperCase();
  }
  let requestObj = {
    module: 'Associate-Node',
    action: 'Review-List',
    actionFilter:
      nodeViewState === 'hierarchy' ? 'Associate-Node-Hierarchy' : 'Associate-Self-Node-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      //status: filterKey.toUpperCase()
      status: filterValue,
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'id',
              conditionValue: {
                condition: 'nin',
                value: {
                  in: allocatedTagsArray
                }
              }
            }
          ]
        }
      ]
    }
  };
  return requestObj;
};
export const makeAssesseeGroupClassificationObj = (
  selectedAssociateInfo,
  filterKey,
  numberPage,
  countPage
) => {
  let requestObj = {
    assesseeId: selectedAssociateInfo?.assesseeId,
    associateId:
      selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary
  };
  return requestObj;
};
export const makeAssociateNodeObj = (
  selectedAssociateInfo,
  filterKey,
  numberPage,
  countPage,
  nodeViewState
) => {
  let requestObj = {
    module: 'Associate-Node',
    action: 'Review-List',
    actionFilter:
      nodeViewState === 'hierarchy' ? 'Iguru-Node-Hierarchy' : 'Associate-Node-List-Create-Revise',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      status: 'ACTIVE'
    }
  };
  return requestObj;
};
export const makeAssociateDistinctShareObj = (selectedAssociateInfo) => {
  let requestObj = {
    module: 'Associate-Distinct',
    action: 'associateSharedReviewList',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: true,
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'String',
              conditionColumn: 'id',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: selectedAssociateInfo?.associate?.informationEngagement.associateTag
                    .associateTagPrimary
                }
              }
            }
          ]
        }
      ]
    }
  };
  return requestObj;
};

export const getAssesseeTypeAssesseeReqObj = (
  selectedAssociateInfo,
  typeId,
  filterKey,
  numberPage,
  countPage
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: [
          'CONFIRMED',
          'DISAPPROVED',
          'SUSPENDED',
          'TERMINATED',
          'UNAPPROVED',
          'UNCONFIRMED',
          'ARCHIVED'
        ]
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: [
          'CONFIRMED',
          'DISAPPROVED',
          'SUSPENDED',
          'TERMINATED',
          'UNAPPROVED',
          'UNCONFIRMED',
          'ARCHIVED',
          'ARCHIVED'
        ]
      }
    };
  }
  return {
    module: 'Assessee-Type',
    action: 'Review-List',
    actionFilter: 'Assessee',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      typeId: typeId,
      filter: 'true',
      orderBy: {
        columnName:
          'informationBasic.assesseeNameFirst,informationBasic.assesseeNameOther,informationBasic.assesseeNameLast,informationBasic.assesseeAlias',
        order: 'asc'
      },
      searchCondition: 'AND',
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.assesseeType.assesseeTypePrimary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: typeId
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.assesseeType.assesseeTypeSecondary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: typeId
                }
              }
            }
          ]
        },
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assesseeStatus',
              conditionValue: searchObj
            }
          ]
        }
      ]
    }
  };
};
export const getAssesseeTypeAssesseeScanReqObj = (
  selectedAssociateInfo,
  typeId,
  filterKey,
  numberPage,
  countPage,
  searchStr
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['CONFIRMED', 'DISAPPROVED', 'SUSPENDED', 'TERMINATED', 'UNAPPROVED', 'UNCONFIRMED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: [
          'CONFIRMED',
          'DISAPPROVED',
          'SUSPENDED',
          'TERMINATED',
          'UNAPPROVED',
          'UNCONFIRMED',
          'ARCHIVED',
          'ARCHIVED'
        ]
      }
    };
  }
  return {
    module: 'Assessee-Type',
    action: 'Review-List',
    actionFilter: 'Assessee',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      typeId: typeId,
      filter: 'true',
      searchCondition: 'AND',
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.assesseeType.assesseeTypePrimary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: typeId
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.assesseeType.assesseeTypeSecondary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: typeId
                }
              }
            }
          ]
        },
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assesseeStatus',
              conditionValue: searchObj
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assesseeNameFirst',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assesseeNameOther',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assesseeNameLast',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assesseeAlias',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn:
                'informationContact.assesseeAddressEmailPrimary.assesseeAddressEmail',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn:
                'informationContact.assesseeAddressEmailSecondary.assesseeAddressEmail',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn:
                'informationContact.assesseeTelephoneMobilePrimary.assesseeTelephoneNumber',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn:
                'informationContact.assesseeTelephoneMobileSecondary.assesseeTelephoneNumber',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assesseeTag.assesseeTagPrimary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            }
          ]
        }
      ]
    }
  };
};

export const getAssociateTypeAssociateReqObj = (
  selectedAssociateInfo,
  typeId,
  filterKey,
  numberPage,
  countPage
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: [
          'CONFIRMED',
          'DISAPPROVED',
          'SUSPENDED',
          'TERMINATED',
          'UNAPPROVED',
          'UNCONFIRMED',
          'ARCHIVED'
        ]
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: [
          'CONFIRMED',
          'DISAPPROVED',
          'SUSPENDED',
          'TERMINATED',
          'UNAPPROVED',
          'UNCONFIRMED',
          'ARCHIVED'
        ]
      }
    };
  }
  return {
    module: 'Associate-Type',
    action: 'Review-List',
    actionFilter: 'Associate-Type-Associate',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      typeId: typeId,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.associateName,informationBasic.associateDescription',
        order: 'asc'
      },
      searchCondition: 'AND',
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.associateType.associateTypePrimary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: typeId
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.associateType.associateTypeSecondary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: typeId
                }
              }
            }
          ]
        },
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.associateStatus',
              conditionValue: searchObj
            }
          ]
        }
      ]
    }
  };
};
export const getAssociateTypeAssociateScanReqObj = (
  selectedAssociateInfo,
  typeId,
  filterKey,
  numberPage,
  countPage,
  searchStr
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'active') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['CONFIRMED']
      }
    };
  }
  if (filterKey === 'inactive') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['DISAPPROVED', 'SUSPENDED', 'TERMINATED', 'UNAPPROVED', 'UNCONFIRMED']
      }
    };
  }
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['CONFIRMED', 'DISAPPROVED', 'SUSPENDED', 'TERMINATED', 'UNAPPROVED', 'UNCONFIRMED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: [
          'CONFIRMED',
          'DISAPPROVED',
          'SUSPENDED',
          'TERMINATED',
          'UNAPPROVED',
          'UNCONFIRMED',
          'ARCHIVED'
        ]
      }
    };
  }
  return {
    module: 'Associate-Type',
    action: 'Review-List',
    actionFilter: 'Associate-Type-Associate',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      typeId: typeId,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.associateName,informationBasic.associateDescription',
        order: 'asc'
      },
      searchCondition: 'AND',
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.associateName',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.associateDescription',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn:
                'informationContact.associateAddressWebsitePrimary.associateAddressWebsite',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn:
                'informationContact.associateAddressWebsiteSecondary.associateAddressWebsite',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.associateTag.associateTagPrimary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.associateTag.associateTagSecondary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            }
          ]
        },
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.associateStatus',
              conditionValue: searchObj
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.associateType.associateTypePrimary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: typeId
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.associateType.associateTypeSecondary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: typeId
                }
              }
            }
          ]
        }
      ]
    }
  };
};
export const getNodeAssociatesReqObj = (
  selectedAssociateInfo,
  nodeId,
  filterKey,
  numberPage,
  countPage
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['CONFIRMED', 'DISAPPROVED', 'SUSPENDED', 'TERMINATED', 'UNAPPROVED', 'UNCONFIRMED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: [
          'CONFIRMED',
          'DISAPPROVED',
          'SUSPENDED',
          'TERMINATED',
          'UNAPPROVED',
          'UNCONFIRMED',
          'ARCHIVED'
        ]
      }
    };
  }
  return {
    module: 'Associate-Node',
    action: 'Review-List',
    actionFilter: 'Associate-Node-Associate',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      nodeId: nodeId,
      orderBy: {
        columnName: 'informationBasic.associateName,informationBasic.associateDescription',
        order: 'asc'
      },
      filter: 'true',
      searchCondition: 'AND',
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.associateNode.associateNodePrimary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: nodeId
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.associateNode.associateNodeSecondary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: nodeId
                }
              }
            }
          ]
        },
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.associateStatus',
              conditionValue: searchObj
            }
          ]
        }
      ]
    }
  };
};
export const getNodeAssociatesScanReqObj = (
  selectedAssociateInfo,
  nodeId,
  filterKey,
  numberPage,
  countPage,
  searchStr
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['CONFIRMED', 'DISAPPROVED', 'SUSPENDED', 'TERMINATED', 'UNAPPROVED', 'UNCONFIRMED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: [
          'CONFIRMED',
          'DISAPPROVED',
          'SUSPENDED',
          'TERMINATED',
          'UNAPPROVED',
          'UNCONFIRMED',
          'ARCHIVED'
        ]
      }
    };
  }
  return {
    module: 'Associate-Node',
    action: 'Review-List',
    actionFilter: 'Associate-Node-Associate',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      nodeId: nodeId,
      filter: 'true',
      searchCondition: 'AND',
      orderBy: {
        columnName: 'informationBasic.associateName,informationBasic.associateDescription',
        order: 'asc'
      },
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.associateName',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.associateDescription',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn:
                'informationContact.associateAddressWebsitePrimary.associateAddressWebsite',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn:
                'informationContact.associateTelephoneWorkPrimary.associateTelephoneExtension',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn:
                'informationContact.associateTelephoneWorkSecondary.associateTelephoneExtension',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.associateTag.associateTagPrimary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.associateTag.associateTagPrimary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.associateTag.associateTagSecondary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            }
          ]
        },
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.associateStatus',
              conditionValue: searchObj
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.associateNode.associateNodePrimary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: nodeId
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.associateNode.associateNodeSecondary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: nodeId
                }
              }
            }
          ]
        }
      ]
    }
  };
};

export const getNodeAssessmentsReqObj = (
  selectedAssociateInfo,
  nodeId,
  filterKey,
  numberPage,
  countPage
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED', 'SUSPENDED', 'TERMINATED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED', 'SUSPENDED', 'TERMINATED', 'ARCHIVED']
      }
    };
  }
  return {
    assesseeId: selectedAssociateInfo?.assesseeId,
    associateId:
      selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
    countPage: countPage,
    numberPage: numberPage,
    nodeId: nodeId,
    orderBy: {
      columnName: 'informationBasic.assessmentName,informationBasic.assessmentDescription',
      order: 'asc'
    },
    filter: 'true',
    searchCondition: 'AND',
    search: [
      {
        condition: 'or',
        searchBy: [
          {
            dataType: 'string',
            conditionColumn: 'informationAllocation.assessmentNode.assessmentNodePrimary',
            conditionValue: {
              condition: 'eq',
              value: {
                from: nodeId
              }
            }
          },
          {
            dataType: 'string',
            conditionColumn: 'informationAllocation.assessmentNode.assessmentNodeSecondary',
            conditionValue: {
              condition: 'eq',
              value: {
                from: nodeId
              }
            }
          }
        ]
      },
      {
        condition: 'and',
        searchBy: [
          {
            dataType: 'string',
            conditionColumn: 'informationEngagement.assessmentStatus',
            conditionValue: searchObj
          }
        ]
      }
    ]
  };
};
export const getNodeAssessmentsScanReqObj = (
  selectedAssociateInfo,
  nodeId,
  filterKey,
  numberPage,
  countPage,
  searchStr
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED', 'SUSPENDED', 'TERMINATED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED', 'SUSPENDED', 'TERMINATED', 'ARCHIVED']
      }
    };
  }
  return {
    assesseeId: selectedAssociateInfo?.assesseeId,
    associateId:
      selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
    countPage: countPage,
    numberPage: numberPage,
    nodeId: nodeId,
    filter: 'true',
    searchCondition: 'AND',
    orderBy: {
      columnName: 'informationBasic.assessmentName,informationBasic.assessmentDescription',
      order: 'asc'
    },
    search: [
      {
        condition: 'or',
        searchBy: [
          {
            dataType: 'string',
            conditionColumn: 'informationAllocation.assessmentNode.assessmentNodePrimary',
            conditionValue: {
              condition: 'eq',
              value: {
                from: nodeId
              }
            }
          },
          {
            dataType: 'string',
            conditionColumn: 'informationAllocation.assessmentNode.assessmentNodeSecondary',
            conditionValue: {
              condition: 'eq',
              value: {
                from: nodeId
              }
            }
          }
        ]
      },
      {
        condition: 'and',
        searchBy: [
          {
            dataType: 'string',
            conditionColumn: 'informationEngagement.assessmentStatus',
            conditionValue: searchObj
          }
        ]
      },
      {
        condition: 'or',
        searchBy: [
          {
            dataType: 'string',
            conditionColumn: 'informationBasic.assessmentName',
            conditionValue: {
              condition: 'ct',
              value: {
                from: searchStr
              }
            }
          },
          {
            dataType: 'string',
            conditionColumn: 'informationBasic.assessmentDescription',
            conditionValue: {
              condition: 'ct',
              value: {
                from: searchStr
              }
            }
          },
          {
            dataType: 'string',
            conditionColumn: 'informationEngagement.assessmentTag.assessmentTagPrimary',
            conditionValue: {
              condition: 'ct',
              value: {
                from: searchStr
              }
            }
          }
        ]
      }
    ]
  };
};
export const getNodeAssignmentsReqObj = (
  selectedAssociateInfo,
  nodeId,
  filterKey,
  numberPage,
  countPage
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED', 'SUSPENDED', 'TERMINATED', 'ADMINISTERED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED', 'SUSPENDED', 'TERMINATED', 'ADMINISTERED', 'ARCHIVED']
      }
    };
  }
  return {
    assesseeId: selectedAssociateInfo?.assesseeId,
    associateId:
      selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
    countPage: countPage,
    numberPage: numberPage,
    nodeId: nodeId,
    filter: 'true',
    searchCondition: 'AND',
    orderBy: {
      columnName: 'informationBasic.assignmentName,informationBasic.assignmentDescription',
      order: 'asc'
    },
    search: [
      {
        condition: 'or',
        searchBy: [
          {
            dataType: 'string',
            conditionColumn: 'informationAllocation.assignmentNode.assignmentNodePrimary',
            conditionValue: {
              condition: 'eq',
              value: {
                from: nodeId
              }
            }
          },
          {
            dataType: 'string',
            conditionColumn: 'informationAllocation.assignmentNode.assignmentNodeSecondary',
            conditionValue: {
              condition: 'eq',
              value: {
                from: nodeId
              }
            }
          }
        ]
      },
      {
        condition: 'and',
        searchBy: [
          {
            dataType: 'string',
            conditionColumn: 'informationEngagement.assignmentStatus',
            conditionValue: searchObj
          }
        ]
      }
    ]
  };
};
export const getNodeAssignmentsScanReqObj = (
  selectedAssociateInfo,
  nodeId,
  filterKey,
  numberPage,
  countPage,
  searchStr
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED', 'SUSPENDED', 'TERMINATED', 'ADMINISTERED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED', 'SUSPENDED', 'TERMINATED', 'ADMINISTERED', 'ARCHIVED']
      }
    };
  }
  return {
    assesseeId: selectedAssociateInfo?.assesseeId,
    associateId:
      selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
    countPage: countPage,
    numberPage: numberPage,
    nodeId: nodeId,
    filter: 'true',
    searchCondition: 'AND',
    orderBy: {
      columnName: 'informationBasic.assignmentName,informationBasic.assignmentDescription',
      order: 'asc'
    },
    search: [
      {
        condition: 'or',
        searchBy: [
          {
            dataType: 'string',
            conditionColumn: 'informationAllocation.assignmentNode.assignmentNodePrimary',
            conditionValue: {
              condition: 'eq',
              value: {
                from: nodeId
              }
            }
          },
          {
            dataType: 'string',
            conditionColumn: 'informationAllocation.assignmentNode.assignmentNodeSecondary',
            conditionValue: {
              condition: 'eq',
              value: {
                from: nodeId
              }
            }
          }
        ]
      },
      {
        condition: 'and',
        searchBy: [
          {
            dataType: 'string',
            conditionColumn: 'informationEngagement.assignmentStatus',
            conditionValue: searchObj
          }
        ]
      },
      {
        condition: 'or',
        searchBy: [
          {
            dataType: 'string',
            conditionColumn: 'informationBasic.assignmentName',
            conditionValue: {
              condition: 'ct',
              value: {
                from: searchStr
              }
            }
          },
          {
            dataType: 'string',
            conditionColumn: 'informationBasic.assignmentDescription',
            conditionValue: {
              condition: 'ct',
              value: {
                from: searchStr
              }
            }
          },
          {
            dataType: 'string',
            conditionColumn: 'informationEngagement.assignmentTag.assignmentTagPrimary',
            conditionValue: {
              condition: 'ct',
              value: {
                from: searchStr
              }
            }
          }
        ]
      }
    ]
  };
};
export const getNodeItemsReqObj = (
  selectedAssociateInfo,
  nodeId,
  filterKey,
  numberPage,
  countPage,
  actionFilter = ''
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED', 'ARCHIVED']
      }
    };
  }
  return {
    module: 'Item-Distinct',
    action: 'Review-List',
    actionFilter: actionFilter ? actionFilter : 'Item-Node',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      nodeId: nodeId,
      filter: 'true',
      searchCondition: 'AND',
      orderBy: {
        columnName: 'informationBasic.itemName,informationBasic.itemDescription',
        order: 'asc'
      },
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.itemNode.itemNodePrimary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: nodeId
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.itemNode.itemNodeSecondary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: nodeId
                }
              }
            }
          ]
        },
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.itemStatus',
              conditionValue: searchObj
            }
          ]
        }
      ]
    }
  };
};
export const getNodeItemsScanReqObj = (
  selectedAssociateInfo,
  nodeId,
  filterKey,
  numberPage,
  countPage,
  searchStr,
  actionFilter = ''
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED', 'ARCHIVED']
      }
    };
  }
  return {
    module: 'Item-Distinct',
    action: 'Review-List',
    actionFilter: actionFilter ? actionFilter : 'Item-Node',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: -1,
      numberPage: -1,
      nodeId: nodeId,
      filter: 'true',
      searchCondition: 'AND',
      orderBy: {
        columnName: 'informationBasic.itemName,informationBasic.itemDescription',
        order: 'asc'
      },
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.itemNode.itemNodePrimary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: nodeId
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.itemNode.itemNodeSecondary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: nodeId
                }
              }
            }
          ]
        },
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.itemStatus',
              conditionValue: searchObj
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.itemName',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.itemDescription',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.itemTag.itemTagPrimary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            }
          ]
        }
      ]
    }
  };
};

export const getItemGroupItemReqObj = (
  selectedAssociateInfo,
  groupId,
  filterKey,
  numberPage,
  countPage
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED', 'ARCHIVED']
      }
    };
  }
  return {
    module: 'Item-Group',
    action: 'Review-List',
    actionFilter: 'Item',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      orderBy: {
        columnName: 'informationBasic.itemName,informationBasic.itemDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      groupId: groupId,
      filter: 'true',
      searchCondition: 'AND',
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.itemGroup.itemGroupPrimary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: groupId
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.itemGroup.itemGroupSecondary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: groupId
                }
              }
            }
          ]
        },
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.itemStatus',
              conditionValue: searchObj
            }
          ]
        }
      ]
    }
  };
};
export const getItemGroupItemScanReqObj = (
  selectedAssociateInfo,
  groupId,
  filterKey,
  numberPage,
  countPage,
  searchStr
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED', 'ARCHIVED']
      }
    };
  }
  return {
    module: 'Item-Group',
    action: 'Review-List',
    actionFilter: 'Item',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      groupId: groupId,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.itemName,informationBasic.itemDescription',
        order: 'asc'
      },
      searchCondition: 'AND',
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.itemName',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.itemDescription',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.itemTag.itemTagPrimary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            }
          ]
        },
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.itemStatus',
              conditionValue: searchObj
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.itemGroup.itemGroupPrimary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: groupId
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.itemGroup.itemGroupSecondary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: groupId
                }
              }
            }
          ]
        }
      ]
    }
  };
};

export const getItemTypeItemReqObj = (
  selectedAssociateInfo,
  typeId,
  filterKey,
  numberPage,
  countPage
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'UNPUBLISHED', 'PUBLISHED', 'ARCHIVED']
      }
    };
  }
  return {
    action: 'Review-List',
    module: 'Item-Type',
    actionFilter: 'Item',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      orderBy: {
        columnName: 'informationBasic.itemName,informationBasic.itemDescription',
        order: 'asc'
      },
      typeId: typeId,
      filter: 'true',
      searchCondition: 'AND',
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.itemType.itemTypePrimary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: typeId
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.itemType.itemTypeSecondary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: typeId
                }
              }
            }
          ]
        },
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.itemStatus',
              conditionValue: searchObj
            }
          ]
        }
      ]
    }
  };
};
export const getItemTypeItemScanReqObj = (
  selectedAssociateInfo,
  typeId,
  filterKey,
  numberPage,
  countPage,
  searchStr
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED', 'ARCHIVED']
      }
    };
  }
  return {
    action: 'Review-List',
    module: 'Item-Type',
    actionFilter: 'Item',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      typeId: typeId,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.itemName,informationBasic.itemDescription',
        order: 'asc'
      },
      searchCondition: 'AND',
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.itemName',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.itemDescription',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.itemTag.itemTagPrimary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            }
          ]
        },
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.itemStatus',
              conditionValue: searchObj
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.itemType.itemTypePrimary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: typeId
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.itemType.itemTypeSecondary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: typeId
                }
              }
            }
          ]
        }
      ]
    }
  };
};
export const getAssessmentGroupAssessmentReqObj = (
  selectedAssociateInfo,
  groupId,
  filterKey,
  numberPage,
  countPage
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  console.log('filterKey>>>>', filterKey);
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED', 'ARCHIVED']
      }
    };
  }
  return {
    module: 'Assessment-Group',
    action: 'Review-List',
    actionFilter: 'Assessment',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      orderBy: {
        columnName: 'informationBasic.assessmentName,informationBasic.assessmentDescription',
        order: 'asc'
      },
      groupId: groupId,
      filter: 'true',
      searchCondition: 'AND',
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.assessmentGroup.assessmentGroupPrimary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: groupId
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.assessmentGroup.assessmentGroupSecondary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: groupId
                }
              }
            }
          ]
        },
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assessmentStatus',
              conditionValue: searchObj
            }
          ]
        }
      ]
    }
  };
};
export const getAssessmentGroupAssessmentScanReqObj = (
  selectedAssociateInfo,
  groupId,
  filterKey,
  numberPage,
  countPage,
  searchStr
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED', 'SUSPENDED', 'TERMINATED', 'ARCHIVED']
      }
    };
  }
  return {
    module: 'Assessment-Group',
    action: 'Review-List',
    actionFilter: 'Assessment',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      groupId: groupId,
      orderBy: {
        columnName: 'informationBasic.assessmentName,informationBasic.assessmentDescription',
        order: 'asc'
      },
      filter: 'true',
      searchCondition: 'AND',
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assessmentName',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assessmentDescription',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assessmentTag.assessmentTagPrimary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            }
          ]
        },
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assessmentStatus',
              conditionValue: searchObj
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.assessmentGroup.assessmentGroupPrimary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: groupId
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.assessmentGroup.assessmentGroupSecondary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: groupId
                }
              }
            }
          ]
        }
      ]
    }
  };
};
export const getAssessmentItemReqObj = (
  selectedAssociateInfo,
  assessmentId,
  filterKey,
  numberPage,
  countPage
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED']
      }
    };
  }
  return {
    assesseeId: selectedAssociateInfo?.assesseeId,
    associateId:
      selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
    countPage: countPage,
    numberPage: numberPage,
    orderBy: {
      columnName: 'informationBasic.itemName,informationBasic.itemDescription',
      order: 'asc'
    },
    assessmentId: assessmentId,
    filter: 'true',
    searchCondition: 'AND',
    search: [
      {
        condition: 'and',
        searchBy: [
          {
            dataType: 'string',
            conditionColumn: 'informationEngagement.itemStatus',
            conditionValue: searchObj
          }
        ]
      }
    ]
  };
};
export const getAssessmentItemScanReqObj = (
  selectedAssociateInfo,
  assessmentId,
  filterKey,
  numberPage,
  countPage,
  searchStr
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED']
      }
    };
  }
  return {
    assesseeId: selectedAssociateInfo?.assesseeId,
    associateId:
      selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
    countPage: countPage,
    numberPage: numberPage,
    orderBy: {
      columnName: 'informationBasic.itemName,informationBasic.itemDescription',
      order: 'asc'
    },
    assessmentId: assessmentId,
    filter: 'true',
    searchCondition: 'AND',
    search: [
      {
        "condition": "or",
        searchBy: [
          {
            dataType: 'string',
            conditionColumn: 'informationBasic.itemName',
            conditionValue: {
              condition: 'ct',
              value: {
                from: searchStr
              }
            }
          },
          {
            dataType: 'string',
            conditionColumn: 'informationBasic.itemDescription',
            conditionValue: {
              condition: 'ct',
              value: {
                from: searchStr
              }
            }
          },
        ]
      },
      {
        condition: 'and',
        searchBy: [
          {
            dataType: 'string',
            conditionColumn: 'informationEngagement.itemStatus',
            conditionValue: searchObj
          }
        ]
      }
    ]
  };
};
export const getAssignmentGroupAssignmentReqObj = (
  selectedAssociateInfo,
  groupId,
  filterKey,
  numberPage,
  countPage,
  assignmentCreateType = ''
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED', 'SUSPENDED', 'TERMINATED', 'ADMINISTERED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED', 'SUSPENDED', 'TERMINATED', 'ADMINISTERED', 'ARCHIVED']
      }
    };
  }
  return {
    assesseeId: selectedAssociateInfo?.assesseeId,
    associateId:
      selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
    countPage: countPage,
    numberPage: numberPage,
    filterTypeName: assignmentCreateType,
    orderBy: {
      columnName: 'informationBasic.assignmentName,informationBasic.assignmentDescription',
      order: 'asc'
    },
    groupId: groupId,
    filter: 'true',
    // orderBy: {
    //   columnName:
    //     'informationBasic.assesseeNameFirst,informationBasic.assesseeNameOther,informationBasic.assesseeNameLast,informationBasic.assesseeAlias',
    //   order: 'asc'
    // },
    searchCondition: 'AND',
    search: [
      {
        condition: 'or',
        searchBy: [
          {
            dataType: 'string',
            conditionColumn: 'informationAllocation.assignmentGroup.assignmentGroupPrimary',
            conditionValue: {
              condition: 'eq',
              value: {
                from: groupId
              }
            }
          },
          {
            dataType: 'string',
            conditionColumn: 'informationAllocation.assignmentGroup.assignmentGroupSecondary',
            conditionValue: {
              condition: 'eq',
              value: {
                from: groupId
              }
            }
          }
        ]
      },
      {
        condition: 'and',
        searchBy: [
          {
            dataType: 'string',
            conditionColumn: 'informationEngagement.assignmentStatus',
            conditionValue: searchObj
          }
        ]
      }
    ]
  };
};

export const getAssignmentGroupAssignmentScanReqObj = (
  selectedAssociateInfo,
  groupId,
  filterKey,
  numberPage,
  countPage,
  searchStr,
  assignmentCreateType
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED', 'SUSPENDED', 'TERMINATED', 'ADMINISTERED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED', 'SUSPENDED', 'TERMINATED', 'ADMINISTERED', 'ARCHIVED']
      }
    };
  }
  return {
    assesseeId: selectedAssociateInfo?.assesseeId,
    associateId:
      selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
    countPage: countPage,
    numberPage: numberPage,
    filterTypeName: assignmentCreateType,
    orderBy: {
      columnName: 'informationBasic.assignmentName,informationBasic.assignmentDescription',
      order: 'asc'
    },
    groupId: groupId,
    filter: 'true',
    searchCondition: 'AND',
    search: [
      {
        condition: 'or',
        searchBy: [
          {
            dataType: 'string',
            conditionColumn: 'informationBasic.assignmentName',
            conditionValue: {
              condition: 'ct',
              value: {
                from: searchStr
              }
            }
          },
          {
            dataType: 'string',
            conditionColumn: 'informationBasic.assignmentDescription',
            conditionValue: {
              condition: 'ct',
              value: {
                from: searchStr
              }
            }
          },
          {
            // sesarch
            dataType: 'string',
            conditionColumn: 'informationEngagement.assignmentTag.assignmentTagPrimary',
            conditionValue: {
              condition: 'ct',
              value: {
                from: searchStr
              }
            }
          }
        ]
      },
      {
        condition: 'and',
        searchBy: [
          {
            dataType: 'string',
            conditionColumn: 'informationEngagement.assignmentStatus',
            conditionValue: searchObj
          }
        ]
      },
      {
        condition: 'or',
        searchBy: [
          {
            dataType: 'string',
            conditionColumn: 'informationAllocation.assignmentGroup.assignmentGroupPrimary',
            conditionValue: {
              condition: 'eq',
              value: {
                from: groupId
              }
            }
          },
          {
            dataType: 'string',
            conditionColumn: 'informationAllocation.assignmentGroup.assignmentGroupSecondary',
            conditionValue: {
              condition: 'eq',
              value: {
                from: groupId
              }
            }
          }
        ]
      }
    ]
  };
};

export const getAssessmentTypeAssessmentReqObj = (
  selectedAssociateInfo,
  typeId,
  filterKey,
  numberPage,
  countPage
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED', 'ARCHIVED']
      }
    };
  }
  return {
    module: 'Assessment-Type',
    action: 'Review-List',
    actionFilter: 'Assessment',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      typeId: typeId,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.assessmentName,informationBasic.assessmentDescription',
        order: 'asc'
      },
      searchCondition: 'AND',
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.assessmentType.assessmentTypePrimary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: typeId
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.assessmentType.assessmentTypeSecondary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: typeId
                }
              }
            }
          ]
        },
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assessmentStatus',
              conditionValue: searchObj
            }
          ]
        }
      ]
    }
  };
};
export const getAssessmentTypeAssessmentScanReqObj = (
  selectedAssociateInfo,
  typeId,
  filterKey,
  numberPage,
  countPage,
  searchStr
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED', 'ARCHIVED']
      }
    };
  }
  return {
    module: 'Assessment-Type',
    action: 'Review-List',
    actionFilter: 'Assessment',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      typeId: typeId,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.assessmentName,informationBasic.assessmentDescription',
        order: 'asc'
      },
      searchCondition: 'AND',
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assessmentName',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.assessmentDescription',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assessmentTag.assessmentTagPrimary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            }
          ]
        },
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assessmentStatus',
              conditionValue: searchObj
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.assessmentType.assessmentTypePrimary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: typeId
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.assessmentType.assessmentTypeSecondary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: typeId
                }
              }
            }
          ]
        }
      ]
    }
  };
};

export const getAssignmentTypeAssignmentReqObj = (
  selectedAssociateInfo,
  typeId,
  filterKey,
  numberPage,
  countPage,
  assignmentCreateType = ''
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED', 'SUSPENDED', 'TERMINATED', 'ADMINISTERED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED', 'SUSPENDED', 'TERMINATED', 'ADMINISTERED', 'ARCHIVED']
      }
    };
  }
  return {
    assesseeId: selectedAssociateInfo?.assesseeId,
    associateId:
      selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
    countPage: countPage,
    numberPage: numberPage,
    filterTypeName: assignmentCreateType,
    orderBy: {
      columnName: 'informationBasic.assignmentName,informationBasic.assignmentDescription',
      order: 'asc'
    },
    typeId: typeId,
    filter: 'true',
    searchCondition: 'AND',
    search: [
      {
        condition: 'or',
        searchBy: [
          {
            dataType: 'string',
            conditionColumn: 'informationAllocation.assignmentType.assignmentTypePrimary',
            conditionValue: {
              condition: 'eq',
              value: {
                from: typeId
              }
            }
          },
          {
            dataType: 'string',
            conditionColumn: 'informationAllocation.assignmentType.assignmentTypeSecondary',
            conditionValue: {
              condition: 'eq',
              value: {
                from: typeId
              }
            }
          }
        ]
      },
      {
        condition: 'and',
        searchBy: [
          {
            dataType: 'string',
            conditionColumn: 'informationEngagement.assignmentStatus',
            conditionValue: searchObj
          }
        ]
      }
    ]
  };
};
export const getAssignmentTypeAssignmentScanReqObj = (
  selectedAssociateInfo,
  typeId,
  filterKey,
  numberPage,
  countPage,
  searchStr
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED', 'SUSPENDED', 'TERMINATED', 'ADMINISTERED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED', 'SUSPENDED', 'TERMINATED', 'ADMINISTERED', 'ARCHIVED']
      }
    };
  }
  return {
    assesseeId: selectedAssociateInfo?.assesseeId,
    associateId:
      selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
    countPage: countPage,
    numberPage: numberPage,
    typeId: typeId,
    filter: 'true',
    orderBy: {
      columnName: 'informationBasic.assignmentName,informationBasic.assignmentDescription',
      order: 'asc'
    },
    searchCondition: 'AND',
    search: [
      {
        condition: 'or',
        searchBy: [
          {
            dataType: 'string',
            conditionColumn: 'informationBasic.assignmentName',
            conditionValue: {
              condition: 'ct',
              value: {
                from: searchStr
              }
            }
          },
          {
            dataType: 'string',
            conditionColumn: 'informationBasic.assignmentDescription',
            conditionValue: {
              condition: 'ct',
              value: {
                from: searchStr
              }
            }
          },
          {
            dataType: 'string',
            conditionColumn: 'informationEngagement.assignmentTag.assignmentTagPrimary',
            conditionValue: {
              condition: 'ct',
              value: {
                from: searchStr
              }
            }
          }
        ]
      },
      {
        condition: 'and',
        searchBy: [
          {
            dataType: 'string',
            conditionColumn: 'informationEngagement.assignmentStatus',
            conditionValue: searchObj
          }
        ]
      },
      {
        condition: 'or',
        searchBy: [
          {
            dataType: 'string',
            conditionColumn: 'informationAllocation.assignmentType.assignmentTypePrimary',
            conditionValue: {
              condition: 'eq',
              value: {
                from: typeId
              }
            }
          },
          {
            dataType: 'string',
            conditionColumn: 'informationAllocation.assignmentType.assignmentTypeSecondary',
            conditionValue: {
              condition: 'eq',
              value: {
                from: typeId
              }
            }
          }
        ]
      }
    ]
  };
};

export const makeCultureProfileObj = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  actionFilter = ''
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'activeinactive') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['UNPUBLISHED', 'PUBLISHED']
      }
    };
  }
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'UNPUBLISHED', 'PUBLISHED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED', 'ARCHIVED']
      }
    };
  }
  let requestObj = {
    module: 'Culture-Profile-Distinct',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName:
          'informationBasic.cultureProfileName,informationBasic.cultureProfileDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.cultureProfileStatus',
              conditionValue: searchObj
            }
          ]
        }
      ]
    }
  };
  if (actionFilter) {
    requestObj['actionFilter'] = actionFilter;
  }
  return requestObj;
};
export const makeCultureProfileScanObj = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  searchStr,
  actionFilter = ''
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey?.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'UNPUBLISHED', 'PUBLISHED']
      }
    };
  }
  if (filterKey === 'activeinactive') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['UNPUBLISHED', 'PUBLISHED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED', 'ARCHIVED']
      }
    };
  }
  let requestObj = {
    module: 'Culture-Profile-Distinct',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName:
          'informationBasic.cultureProfileName,informationBasic.cultureProfileDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.cultureProfileStatus',
              conditionValue: searchObj
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.cultureProfileName',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.cultureProfileDescription',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.cultureProfileTag.cultureProfileTagPrimary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.cultureProfileTag.cultureProfileTagSecondary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            }
          ]
        }
      ]
    }
  };
  if (actionFilter) {
    requestObj['actionFilter'] = actionFilter;
  }
  return requestObj;
};
export const makeCultureProfileGroupObj = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  groupViewState,
  assignmentCreateType = '',
  actionFilter = ''
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  let requestObj = {};
  if (groupViewState === 'hierarchy') {
    requestObj = {
      module: 'Culture-Profile-Group',
      action: 'Review-List',
      actionFilter:
        actionFilter === 'Culture-Profile-Shared-Group' ? 'Shared-Hierarchy' : 'Hierarchy',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        filterTypeName: assignmentCreateType === 'iGuru_Assignment' ? '' : assignmentCreateType,
        // filterTypeName:
        //   assignmentCreateType === 'iGuru_Assignment' ||
        //     assignmentCreateType.toLowerCase().includes('analytics')
        //     ? ''
        //     : assignmentCreateType,
        status: filterKey.toUpperCase()
      }
    };
  } else {
    if (filterKey === 'bespoke' || filterKey === 'generic') {
      requestObj = {
        module: 'Culture-Profile-Group',
        action: 'Review-List',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          filterTypeName: assignmentCreateType === 'iGuru_Assignment' ? '' : assignmentCreateType,
          // filterTypeName:
          //   assignmentCreateType === 'iGuru_Assignment' ||
          //     assignmentCreateType.toLowerCase().includes('analytics')
          //     ? ''
          //     : assignmentCreateType,
          filter: 'true',
          orderBy: {
            columnName:
              'informationBasic.cultureProfileGroupName,informationBasic.cultureProfileGroupDescription',
            order: 'asc'
          },
          numberPage: numberPage,
          countPage: countPage,
          search: [
            {
              condition: 'and',
              searchBy: [
                {
                  dataType: 'string',
                  conditionColumn: 'informationEngagement.cultureProfileGroupStatus',
                  conditionValue: {
                    condition: 'eq',
                    value: {
                      from: 'ACTIVE'
                    }
                  }
                },
                {
                  dataType: 'string',
                  conditionColumn:
                    'informationSetup.cultureProfileGroupClassification.cultureProfileGroupClassificationPrimary',
                  conditionValue: {
                    condition: 'eq',
                    value: {
                      from: filterKey === 'bespoke' ? 'Bespoke' : 'Generic'
                    }
                  }
                }
              ]
            }
          ]
        }
      };
      if (actionFilter) {
        requestObj['actionFilter'] = actionFilter;
      }
    } else {
      requestObj = {
        module: 'Culture-Profile-Group',
        action: 'Review-List',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          filterTypeName: assignmentCreateType === 'iGuru_Assignment' ? '' : assignmentCreateType,
          // filterTypeName:
          //   assignmentCreateType === 'iGuru_Assignment' ||
          //     assignmentCreateType.toLowerCase().includes('analytics')
          //     ? ''
          //     : assignmentCreateType,
          filter: 'true',
          orderBy: {
            columnName:
              'informationBasic.cultureProfileGroupName,informationBasic.cultureProfileGroupDescription',
            order: 'asc'
          },
          numberPage: numberPage,
          countPage: countPage,
          search: [
            {
              condition: 'and',
              searchBy: [
                {
                  dataType: 'string',
                  conditionColumn: 'informationEngagement.cultureProfileGroupStatus',
                  conditionValue: searchObj
                }
              ]
            }
          ]
        }
      };
    }
    if (actionFilter) {
      requestObj['actionFilter'] = actionFilter;
    }
  }

  return requestObj;
};
export const makeCultureProfileAllocateGroupObj = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  groupViewState,
  assignmentCreateType = '',
  allocatedTagsArray
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  let requestObj = {
    module: 'Culture-Profile-Group',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filterTypeName: assignmentCreateType === 'iGuru_Assignment' ? '' : assignmentCreateType,
      // filterTypeName:
      //   assignmentCreateType === 'iGuru_Assignment' ||
      //     assignmentCreateType.toLowerCase().includes('analytics')
      //     ? ''
      //     : assignmentCreateType,
      filter: 'true',
      orderBy: {
        columnName:
          'informationBasic.cultureProfileGroupName,informationBasic.cultureProfileGroupDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.cultureProfileGroupStatus',
              conditionValue: searchObj
            },
            {
              dataType: 'string',
              conditionColumn: 'id',
              conditionValue: {
                condition: 'nin',
                value: {
                  in: allocatedTagsArray
                }
              }
            }
          ]
        }
      ]
    }
  };
  return requestObj;
};
export const makeCultureProfileGroupScanObj = (
  selectedAssociateInfo,
  filterKey,
  numberPage,
  countPage,
  searchStr,
  actionFilter
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }

  if (filterKey === 'bespoke' || filterKey === 'generic') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['ACTIVE']
      }
    };
  }
  let searchArr = [
    {
      condition: 'and',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn: 'informationEngagement.cultureProfileGroupStatus',
          conditionValue: searchObj
        }
      ]
    },
    {
      condition: 'or',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.cultureProfileGroupName',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.cultureProfileGroupDescription',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        }
      ]
    }
  ];
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    searchArr.push({
      condition: 'or',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn:
            'informationSetup.cultureProfileGroupClassification.cultureProfileGroupClassificationPrimary',
          conditionValue: {
            condition: 'ct',
            value: {
              from: filterKey === 'bespoke' ? 'Bespoke' : filterKey === 'generic' ? 'Generic' : ''
            }
          }
        }
      ]
    });
  }
  let requestObj = {
    module: 'Culture-Profile-Group',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName:
          'informationBasic.cultureProfileGroupName,informationBasic.cultureProfileGroupDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      searchCondition: 'AND',
      search: searchArr
    }
  };
  if (actionFilter) {
    requestObj['actionFilter'] = actionFilter;
  }
  return requestObj;
};
export const makeCultureProfileGroupAllocateScanObj = (
  selectedAssociateInfo,
  filterKey,
  numberPage,
  countPage,
  searchStr,
  allocatedTagsArray
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }

  if (filterKey === 'bespoke' || filterKey === 'generic') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['ACTIVE']
      }
    };
  }
  let searchArr = [
    {
      condition: 'and',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn: 'informationEngagement.cultureProfileGroupStatus',
          conditionValue: searchObj
        },
        {
          dataType: 'string',
          conditionColumn: 'id',
          conditionValue: {
            condition: 'nin',
            value: {
              in: allocatedTagsArray
            }
          }
        }
      ]
    },
    {
      condition: 'or',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.cultureProfileGroupName',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.cultureProfileGroupDescription',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        }
      ]
    }
  ];
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    searchArr.push({
      condition: 'or',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn:
            'informationSetup.cultureProfileGroupClassification.cultureProfileGroupClassificationPrimary',
          conditionValue: {
            condition: 'ct',
            value: {
              from: filterKey === 'bespoke' ? 'Bespoke' : filterKey === 'generic' ? 'Generic' : ''
            }
          }
        }
      ]
    });
  }
  let requestObj = {
    module: 'Culture-Profile-Group',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName:
          'informationBasic.cultureProfileGroupName,informationBasic.cultureProfileGroupDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      searchCondition: 'AND',
      search: searchArr
    }
  };
  return requestObj;
};
export const makeCultureProfileTypeObj = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  actionFilter = ''
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };

  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  if (filterKey === 'inactive') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED']
      }
    };
  }
  let requestObj = {};
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    requestObj = {
      module: 'Culture-Profile-Type',
      action: 'Review-List',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        filter: 'true',
        orderBy: {
          columnName:
            'informationBasic.cultureProfileTypeName,informationBasic.cultureProfileTypeDescription',
          order: 'asc'
        },
        numberPage: numberPage,
        countPage: countPage,
        search: [
          {
            condition: 'and',
            searchBy: [
              {
                dataType: 'string',
                conditionColumn: 'informationEngagement.cultureProfileTypeStatus',
                conditionValue: {
                  condition: 'eq',
                  value: {
                    from: 'ACTIVE'
                  }
                }
              },
              {
                dataType: 'string',
                conditionColumn:
                  'informationSetup.cultureProfileTypeClassification.cultureProfileTypeClassificationPrimary',
                conditionValue: {
                  condition: 'in',
                  value: {
                    in: [filterKey === 'bespoke' ? 'Bespoke' : 'Generic']
                  }
                }
              }
            ]
          }
        ]
      }
    };
  } else {
    requestObj = {
      module: 'Culture-Profile-Type',
      action: 'Review-List',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        filter: 'true',
        orderBy: {
          columnName:
            'informationBasic.cultureProfileTypeName,informationBasic.cultureProfileTypeDescription',
          order: 'asc'
        },
        numberPage: numberPage,
        countPage: countPage,
        search: [
          {
            condition: 'and',
            searchBy: [
              {
                dataType: 'string',
                conditionColumn: 'informationEngagement.cultureProfileTypeStatus',
                conditionValue: searchObj
              }
            ]
          }
        ]
      }
    };
  }
  if (actionFilter) requestObj['actionFilter'] = actionFilter;
  return requestObj;
};
export const makeCultureProfileAllocateTypeObj = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  allocatedTagsArray
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };

  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  if (filterKey === 'inactive') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED']
      }
    };
  }
  let requestObj = {};
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    requestObj = {
      module: 'Culture-Profile-Type',
      action: 'Review-List',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        filter: 'true',
        orderBy: {
          columnName:
            'informationBasic.cultureProfileTypeName,informationBasic.cultureProfileTypeDescription',
          order: 'asc'
        },
        numberPage: numberPage,
        countPage: countPage,
        search: [
          {
            condition: 'and',
            searchBy: [
              {
                dataType: 'string',
                conditionColumn: 'informationEngagement.cultureProfileTypeStatus',
                conditionValue: {
                  condition: 'eq',
                  value: {
                    from: 'ACTIVE'
                  }
                }
              },
              {
                dataType: 'string',
                conditionColumn:
                  'informationSetup.cultureProfileTypeClassification.cultureProfileTypeClassificationPrimary',
                conditionValue: {
                  condition: 'in',
                  value: {
                    in: [filterKey === 'bespoke' ? 'Bespoke' : 'Generic']
                  }
                }
              }
            ]
          }
        ]
      }
    };
  } else {
    requestObj = {
      module: 'Culture-Profile-Type',
      action: 'Review-List',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        filter: 'true',
        orderBy: {
          columnName:
            'informationBasic.cultureProfileTypeName,informationBasic.cultureProfileTypeDescription',
          order: 'asc'
        },
        numberPage: numberPage,
        countPage: countPage,
        search: [
          {
            condition: 'and',
            searchBy: [
              {
                dataType: 'string',
                conditionColumn: 'informationEngagement.cultureProfileTypeStatus',
                conditionValue: searchObj
              },
              {
                dataType: 'string',
                conditionColumn: 'id',
                conditionValue: {
                  condition: 'nin',
                  value: {
                    in: allocatedTagsArray
                  }
                }
              }
            ]
          }
        ]
      }
    };
  }
  return requestObj;
};
export const makeCultureProfileTypeScanObj = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  searchStr,
  actionFilter = ''
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  if (filterKey === 'inactive') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED']
      }
    };
  }
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['ACTIVE']
      }
    };
  }
  let requestObj = {
    module: 'Culture-Profile-Type',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName:
          'informationBasic.cultureProfileTypeName,informationBasic.cultureProfileTypeDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.cultureProfileTypeStatus',
              conditionValue: searchObj
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.cultureProfileTypeName',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.cultureProfileTypeDescription',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn:
                'informationSetup.cultureProfileTypeClassification.cultureProfileTypeClassificationPrimary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from:
                    filterKey === 'bespoke' ? 'Bespoke' : filterKey === 'generic' ? 'Generic' : ''
                }
              }
            }
          ]
        }
      ]
    }
  };
  if (actionFilter) requestObj['actionFilter'] = actionFilter;
  return requestObj;
};
export const makeCultureProfileTypeAllocateScanObj = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  searchStr,
  allocatedTagsArray
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  if (filterKey === 'inactive') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED']
      }
    };
  }
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['ACTIVE']
      }
    };
  }
  let requestObj = {
    module: 'Culture-Profile-Type',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName:
          'informationBasic.cultureProfileTypeName,informationBasic.cultureProfileTypeDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.cultureProfileTypeStatus',
              conditionValue: searchObj
            },
            {
              dataType: 'string',
              conditionColumn: 'id',
              conditionValue: {
                condition: 'nin',
                value: {
                  in: allocatedTagsArray
                }
              }
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.cultureProfileTypeName',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.cultureProfileTypeDescription',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn:
                'informationSetup.cultureProfileTypeClassification.cultureProfileTypeClassificationPrimary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from:
                    filterKey === 'bespoke' ? 'Bespoke' : filterKey === 'generic' ? 'Generic' : ''
                }
              }
            }
          ]
        }
      ]
    }
  };
  return requestObj;
};
export const makeJobProfileObj = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  actionFilter = ''
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'UNPUBLISHED', 'PUBLISHED']
      }
    };
  }
  if (filterKey === 'activeinactive') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['UNPUBLISHED', 'PUBLISHED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED', 'ARCHIVED']
      }
    };
  }
  let requestObj = {
    module: 'Job-Profile-Distinct',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.jobProfileName,informationBasic.jobProfileDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.jobProfileStatus',
              conditionValue: searchObj
            }
          ]
        }
      ]
    }
  };
  if (actionFilter) requestObj['actionFilter'] = actionFilter;
  return requestObj;
};
export const makeJobProfileScanObj = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  searchStr,
  actionFilter = ''
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'UNPUBLISHED', 'PUBLISHED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED', 'ARCHIVED']
      }
    };
  }
  if (filterKey === 'activeinactive') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['UNPUBLISHED', 'PUBLISHED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['UNPUBLISHED', 'PUBLISHED', 'SUSPENDED', 'TERMINATED', 'ARCHIVED']
      }
    };
  }
  let requestObj = {
    module: 'Job-Profile-Distinct',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.jobProfileName,informationBasic.jobProfileDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.jobProfileStatus',
              conditionValue: searchObj
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.jobProfileName',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.jobProfileDescription',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.jobProfileTag.jobProfileTagPrimary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.jobProfileTag.jobProfileTagSecondary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            }
          ]
        }
      ]
    }
  };
  if (actionFilter) requestObj['actionFilter'] = actionFilter;
  return requestObj;
};
export const makeJobProfileGroupObj = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  groupViewState,
  assignmentCreateType = '',
  actionFilter = ''
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  let requestObj = {};
  if (groupViewState === 'hierarchy') {
    requestObj = {
      module: 'Job-Profile-Group',
      action: 'Review-List',
      actionFilter: actionFilter ? 'Shared-Hierarchy' : 'Hierarchy',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        filterTypeName: assignmentCreateType === 'iGuru_Assignment' ? '' : assignmentCreateType,
        // filterTypeName:
        //   assignmentCreateType === 'iGuru_Assignment' ||
        //     assignmentCreateType.toLowerCase().includes('analytics')
        //     ? ''
        //     : assignmentCreateType,
        status: filterKey.toUpperCase()
      }
    };
  } else {
    if (filterKey === 'bespoke' || filterKey === 'generic') {
      requestObj = {
        module: 'Job-Profile-Group',
        action: 'Review-List',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          filterTypeName: assignmentCreateType === 'iGuru_Assignment' ? '' : assignmentCreateType,
          // filterTypeName:
          //   assignmentCreateType === 'iGuru_Assignment' ||
          //     assignmentCreateType.toLowerCase().includes('analytics')
          //     ? ''
          //     : assignmentCreateType,
          filter: 'true',
          orderBy: {
            columnName:
              'informationBasic.jobProfileGroupName,informationBasic.jobProfileGroupDescription',
            order: 'asc'
          },
          numberPage: numberPage,
          countPage: countPage,
          search: [
            {
              condition: 'and',
              searchBy: [
                {
                  dataType: 'string',
                  conditionColumn: 'informationEngagement.jobProfileGroupStatus',
                  conditionValue: {
                    condition: 'eq',
                    value: {
                      from: 'ACTIVE'
                    }
                  }
                },
                {
                  dataType: 'string',
                  conditionColumn:
                    'informationSetup.jobProfileGroupClassification.jobProfileGroupClassificationPrimary',
                  conditionValue: {
                    condition: 'eq',
                    value: {
                      from: filterKey === 'bespoke' ? 'Bespoke' : 'Generic'
                    }
                  }
                }
              ]
            }
          ]
        }
      };
      if (actionFilter) requestObj['actionFilter'] = actionFilter;
    } else {
      requestObj = {
        module: 'Job-Profile-Group',
        action: 'Review-List',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          filterTypeName: assignmentCreateType === 'iGuru_Assignment' ? '' : assignmentCreateType,
          // filterTypeName:
          //   assignmentCreateType === 'iGuru_Assignment' ||
          //     assignmentCreateType.toLowerCase().includes('analytics')
          //     ? ''
          //     : assignmentCreateType,
          filter: 'true',
          orderBy: {
            columnName:
              'informationBasic.jobProfileGroupName,informationBasic.jobProfileGroupDescription',
            order: 'asc'
          },
          numberPage: numberPage,
          countPage: countPage,
          search: [
            {
              condition: 'and',
              searchBy: [
                {
                  dataType: 'string',
                  conditionColumn: 'informationEngagement.jobProfileGroupStatus',
                  conditionValue: searchObj
                }
              ]
            }
          ]
        }
      };
    }
    if (actionFilter) requestObj['actionFilter'] = actionFilter;
  }

  return requestObj;
};
export const makeJobProfileGroupAllocateObj = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  groupViewState,
  assignmentCreateType = '',
  allocatedTagsArray
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  let requestObj = {
    module: 'Job-Profile-Group',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filterTypeName: assignmentCreateType === 'iGuru_Assignment' ? '' : assignmentCreateType,
      filterTypeName:
        assignmentCreateType === 'iGuru_Assignment' 
          // assignmentCreateType.toLowerCase().includes('analytics')
          ? ''
          : assignmentCreateType,
      filter: 'true',
      orderBy: {
        columnName:
          'informationBasic.jobProfileGroupName,informationBasic.jobProfileGroupDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.jobProfileGroupStatus',
              conditionValue: searchObj
            },
            {
              dataType: 'string',
              conditionColumn: 'id',
              conditionValue: {
                condition: 'nin',
                value: {
                  in: allocatedTagsArray
                }
              }
            }
          ]
        }
      ]
    }
  };
  return requestObj;
};
export const makeJobProfileGroupScanObj = (
  selectedAssociateInfo,
  filterKey,
  numberPage,
  countPage,
  searchStr,
  actionFilter = ''
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['ACTIVE']
      }
    };
  }
  let searchArr = [
    {
      condition: 'and',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn: 'informationEngagement.jobProfileGroupStatus',
          conditionValue: searchObj
        }
      ]
    },
    {
      condition: 'or',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.jobProfileGroupName',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.jobProfileGroupDescription',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        }
      ]
    }
  ];
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    searchArr.push({
      condition: 'or',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn:
            'informationSetup.jobProfileGroupClassification.jobProfileGroupClassificationPrimary',
          conditionValue: {
            condition: 'ct',
            value: {
              from: filterKey === 'bespoke' ? 'Bespoke' : filterKey === 'generic' ? 'Generic' : ''
            }
          }
        }
      ]
    });
  }
  let requestObj = {
    module: 'Job-Profile-Group',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName:
          'informationBasic.jobProfileGroupName,informationBasic.jobProfileGroupDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      search: searchArr
    }
  };
  if (actionFilter) requestObj['actionFilter'] = actionFilter;
  return requestObj;
};
export const makeJobProfileGroupAllocateScanObj = (
  selectedAssociateInfo,
  filterKey,
  numberPage,
  countPage,
  searchStr,
  allocatedTagsArray
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['ACTIVE']
      }
    };
  }
  let searchArr = [
    {
      condition: 'and',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn: 'informationEngagement.jobProfileGroupStatus',
          conditionValue: searchObj
        },
        {
          dataType: 'string',
          conditionColumn: 'id',
          conditionValue: {
            condition: 'nin',
            value: {
              in: allocatedTagsArray
            }
          }
        }
      ]
    },
    {
      condition: 'or',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.jobProfileGroupName',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.jobProfileGroupDescription',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        }
      ]
    }
  ];
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    searchArr.push({
      condition: 'or',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn:
            'informationSetup.jobProfileGroupClassification.jobProfileGroupClassificationPrimary',
          conditionValue: {
            condition: 'ct',
            value: {
              from: filterKey === 'bespoke' ? 'Bespoke' : filterKey === 'generic' ? 'Generic' : ''
            }
          }
        }
      ]
    });
  }
  let requestObj = {
    module: 'Job-Profile-Group',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName:
          'informationBasic.jobProfileGroupName,informationBasic.jobProfileGroupDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      search: searchArr
    }
  };
  console.log('requestObj>>>', requestObj);
  return requestObj;
};
export const makeJobProfileTypeObj = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  actionFilter = ''
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  if (filterKey === 'inactive') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED']
      }
    };
  }
  let requestObj = {};
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    requestObj = {
      module: 'Job-Profile-Type',
      action: 'Review-List',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        filter: 'true',
        orderBy: {
          columnName:
            'informationBasic.jobProfileTypeName,informationBasic.jobProfileTypeDescription',
          order: 'asc'
        },
        numberPage: numberPage,
        countPage: countPage,
        search: [
          {
            condition: 'and',
            searchBy: [
              {
                dataType: 'string',
                conditionColumn: 'informationEngagement.jobProfileTypeStatus',
                conditionValue: {
                  condition: 'eq',
                  value: {
                    from: 'ACTIVE'
                  }
                }
              },
              {
                dataType: 'string',
                conditionColumn:
                  'informationSetup.jobProfileTypeClassification.jobProfileTypeClassificationPrimary',
                conditionValue: {
                  condition: 'in',
                  value: {
                    in: [filterKey === 'bespoke' ? 'Bespoke' : 'Generic']
                  }
                }
              }
            ]
          }
        ]
      }
    };
  } else {
    requestObj = {
      module: 'Job-Profile-Type',
      action: 'Review-List',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        filter: 'true',
        orderBy: {
          columnName:
            'informationBasic.jobProfileTypeName,informationBasic.jobProfileTypeDescription',
          order: 'asc'
        },
        numberPage: numberPage,
        countPage: countPage,
        search: [
          {
            condition: 'and',
            searchBy: [
              {
                dataType: 'string',
                conditionColumn: 'informationEngagement.jobProfileTypeStatus',
                conditionValue: searchObj
              }
            ]
          }
        ]
      }
    };
  }

  if (actionFilter) requestObj['actionFilter'] = actionFilter;
  return requestObj;
};
export const makeJobProfileTypeAllocateObj = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  allocatedTagsArray
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  if (filterKey === 'inactive') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED']
      }
    };
  }
  let requestObj = {};
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    requestObj = {
      module: 'Job-Profile-Type',
      action: 'Review-List',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        filter: 'true',
        orderBy: {
          columnName:
            'informationBasic.jobProfileTypeName,informationBasic.jobProfileTypeDescription',
          order: 'asc'
        },
        numberPage: numberPage,
        countPage: countPage,
        search: [
          {
            condition: 'and',
            searchBy: [
              {
                dataType: 'string',
                conditionColumn: 'informationEngagement.jobProfileTypeStatus',
                conditionValue: {
                  condition: 'eq',
                  value: {
                    from: 'ACTIVE'
                  }
                }
              },
              {
                dataType: 'string',
                conditionColumn:
                  'informationSetup.jobProfileTypeClassification.jobProfileTypeClassificationPrimary',
                conditionValue: {
                  condition: 'in',
                  value: {
                    in: [filterKey === 'bespoke' ? 'Bespoke' : 'Generic']
                  }
                }
              }
            ]
          }
        ]
      }
    };
  } else {
    requestObj = {
      module: 'Job-Profile-Type',
      action: 'Review-List',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        filter: 'true',
        orderBy: {
          columnName:
            'informationBasic.jobProfileTypeName,informationBasic.jobProfileTypeDescription',
          order: 'asc'
        },
        numberPage: numberPage,
        countPage: countPage,
        search: [
          {
            condition: 'and',
            searchBy: [
              {
                dataType: 'string',
                conditionColumn: 'informationEngagement.jobProfileTypeStatus',
                conditionValue: searchObj
              },
              {
                dataType: 'string',
                conditionColumn: 'id',
                conditionValue: {
                  condition: 'nin',
                  value: {
                    in: allocatedTagsArray
                  }
                }
              }
            ]
          }
        ]
      }
    };
  }
  return requestObj;
};
export const makeJobProfileTypeScanObj = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  searchStr,
  actionFilter = ''
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['ACTIVE']
      }
    };
  }
  let requestObj = {
    module: 'Job-Profile-Type',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName:
          'informationBasic.jobProfileTypeName,informationBasic.jobProfileTypeDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.jobProfileTypeStatus',
              conditionValue: searchObj
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.jobProfileTypeName',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.jobProfileTypeDescription',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn:
                'informationSetup.jobProfileTypeClassification.jobProfileTypeClassificationPrimary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from:
                    filterKey === 'bespoke' ? 'Bespoke' : filterKey === 'generic' ? 'Generic' : ''
                }
              }
            }
          ]
        }
      ]
    }
  };

  if (actionFilter) requestObj['actionFilter'] = actionFilter;
  return requestObj;
};
export const makeJobProfileTypeAllocateScanObj = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  searchStr,
  allocatedTagsArray
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  if (filterKey === 'bespoke' || filterKey === 'generic') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['ACTIVE']
      }
    };
  }
  let requestObj = {
    module: 'Job-Profile-Type',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName:
          'informationBasic.jobProfileTypeName,informationBasic.jobProfileTypeDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.jobProfileTypeStatus',
              conditionValue: searchObj
            },
            {
              dataType: 'string',
              conditionColumn: 'id',
              conditionValue: {
                condition: 'nin',
                value: {
                  in: allocatedTagsArray
                }
              }
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.jobProfileTypeName',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.jobProfileTypeDescription',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn:
                'informationSetup.jobProfileTypeClassification.jobProfileTypeClassificationPrimary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from:
                    filterKey === 'bespoke' ? 'Bespoke' : filterKey === 'generic' ? 'Generic' : ''
                }
              }
            }
          ]
        }
      ]
    }
  };
  return requestObj;
};
export const getJobProfileTypeJobProfileReqObj = (
  selectedAssociateInfo,
  typeId,
  filterKey,
  numberPage,
  countPage
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey?.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED', 'ARCHIVED']
      }
    };
  }
  return {
    module: 'Job-Profile-Type',
    action: 'Review-List',
    actionFilter: 'Job-Profile',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      orderBy: {
        columnName: 'informationBasic.jobProfileName,informationBasic.jobProfileDescription',
        order: 'asc'
      },
      typeId: typeId,
      filter: 'true',
      searchCondition: 'AND',
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.jobProfileType.jobProfileTypePrimary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: typeId
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.jobProfileType.jobProfileTypeSecondary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: typeId
                }
              }
            }
          ]
        },
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.jobProfileStatus',
              conditionValue: searchObj
            }
          ]
        }
      ]
    }
  };
};
export const getJobProfileTypeJobProfileScanReqObj = (
  selectedAssociateInfo,
  typeId,
  filterKey,
  numberPage,
  countPage,
  searchStr
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey?.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['ACTIVE', 'SUSPENDED', 'TERMINATED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED', 'ARCHIVED']
      }
    };
  }
  return {
    module: 'Job-Profile-Type',
    action: 'Review-List',
    actionFilter: 'Job-Profile',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      typeId: typeId,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.jobProfileName,informationBasic.jobProfileDescription',
        order: 'asc'
      },
      searchCondition: 'AND',
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.jobProfileType.jobProfileTypePrimary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: typeId
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.jobProfileType.jobProfileTypeSecondary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: typeId
                }
              }
            }
          ]
        },
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.jobProfileStatus',
              conditionValue: searchObj
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.jobProfileName',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.jobProfileDescription',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.jobProfileTag.jobProfileTagPrimary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            }
          ]
        }
      ]
    }
  };
};
export const getJobProfileGroupJobProfileReqObj = (
  selectedAssociateInfo,
  groupId,
  filterKey,
  numberPage,
  countPage
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey?.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED', 'ARCHIVED']
      }
    };
  }
  return {
    module: 'Job-Profile-Group',
    action: 'Review-List',
    actionFilter: 'Job-Profile',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      groupId: groupId,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.jobProfileName,informationBasic.jobProfileDescription',
        order: 'asc'
      },
      searchCondition: 'AND',
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.jobProfileGroup.jobProfileGroupPrimary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: groupId
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.jobProfileGroup.jobProfileGroupSecondary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: groupId
                }
              }
            }
          ]
        },
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.jobProfileStatus',
              conditionValue: searchObj
            }
          ]
        }
      ]
    }
  };
};
export const getJobProfileGroupJobProfileScanReqObj = (
  selectedAssociateInfo,
  groupId,
  filterKey,
  numberPage,
  countPage,
  searchStr
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey?.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['ACTIVE', 'SUSPENDED', 'TERMINATED', 'UNPUBLISHED', 'PUBLISHED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED', 'ARCHIVED']
      }
    };
  }
  return {
    module: 'Job-Profile-Group',
    action: 'Review-List',
    actionFilter: 'Job-Profile',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      groupId: groupId,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.jobProfileName,informationBasic.jobProfileDescription',
        order: 'asc'
      },
      searchCondition: 'AND',
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.jobProfileGroup.jobProfileGroupPrimary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: groupId
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.jobProfileGroup.jobProfileGroupSecondary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: groupId
                }
              }
            }
          ]
        },
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.jobProfileStatus',
              conditionValue: searchObj
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.jobProfileName',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.jobProfileDescription',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.jobProfileTag.jobProfileTagPrimary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            }
          ]
        }
      ]
    }
  };
};
export const getJobGroupJobScanReqObj = (
  selectedAssociateInfo,
  groupId,
  filterKey,
  numberPage,
  countPage,
  searchStr
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['ACTIVE', 'SUSPENDED', 'TERMINATED']
      }
    };
  }
  return {
    assesseeId: selectedAssociateInfo?.assesseeId,
    associateId:
      selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
    countPage: countPage,
    numberPage: numberPage,
    groupId: groupId,
    orderBy: {
      columnName: 'informationBasic.jobProfileName,informationBasic.jobProfileDescription',
      order: 'asc'
    },
    filter: 'true',
    searchCondition: 'AND',
    search: [
      {
        condition: 'or',
        searchBy: [
          {
            dataType: 'string',
            conditionColumn: 'informationAllocation.cultureProfileGroup.cultureProfileGroupPrimary',
            conditionValue: {
              condition: 'eq',
              value: {
                from: groupId
              }
            }
          },
          {
            dataType: 'string',
            conditionColumn:
              'informationAllocation.cultureProfileGroup.cultureProfileGroupSecondary',
            conditionValue: {
              condition: 'eq',
              value: {
                from: groupId
              }
            }
          },
          {
            dataType: 'string',
            conditionColumn: 'informationBasic.cultureProfileGroupName',
            conditionValue: {
              condition: 'ct',
              value: {
                from: searchStr
              }
            }
          },
          {
            dataType: 'string',
            conditionColumn: 'informationBasic.cultureProfileGroupDescription',
            conditionValue: {
              condition: 'ct',
              value: {
                from: searchStr
              }
            }
          }
        ]
      },
      {
        condition: 'and',
        searchBy: [
          {
            dataType: 'string',
            conditionColumn: 'informationEngagement.cultureProfileGroupStatus',
            conditionValue: searchObj
          }
        ]
      }
    ]
  };
};
export const getNodeJobProfileReqObj = (
  selectedAssociateInfo,
  nodeId,
  filterKey,
  numberPage,
  countPage,
  actionFilter = ''
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED', 'SUSPENDED', 'TERMINATED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED', 'SUSPENDED', 'TERMINATED', 'ARCHIVED']
      }
    };
  }
  let reqBody = {
    module: 'Job-Profile-Distinct',
    action: 'Review-List',
    actionFilter: 'Job-Profile-Node',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      nodeId: nodeId,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.jobProfileName,informationBasic.jobProfileDescription',
        order: 'asc'
      },
      searchCondition: 'AND',
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.jobProfileNode.jobProfileNodePrimary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: nodeId
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.jobProfileNode.jobProfileNodeSecondary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: nodeId
                }
              }
            }
          ]
        },
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.jobProfileStatus',
              conditionValue: searchObj
            }
          ]
        }
      ]
    }
  };

  if (actionFilter) reqBody['actionFilter'] = actionFilter;
  return reqBody;
};
export const getNodeJobProfileScanReqObj = (
  selectedAssociateInfo,
  nodeId,
  filterKey,
  numberPage,
  countPage,
  searchStr
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED', 'SUSPENDED', 'TERMINATED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED', 'SUSPENDED', 'TERMINATED', 'ARCHIVED']
      }
    };
  }
  return {
    module: 'Job-Profile-Distinct',
    action: 'Review-List',
    actionFilter: 'Job-Profile-Node',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      nodeId: nodeId,
      filter: 'true',
      searchCondition: 'AND',
      orderBy: {
        columnName: 'informationBasic.jobProfileName,informationBasic.jobProfileDescription',
        order: 'asc'
      },
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.jobProfileNode.jobProfileNodePrimary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: nodeId
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.jobProfileNode.jobProfileNodeSecondary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: nodeId
                }
              }
            }
          ]
        },
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.jobProfileStatus',
              conditionValue: searchObj
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.jobProfileName',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.jobProfileDescription',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.jobProfileTag.jobProfileTagPrimary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            }
          ]
        }
      ]
    }
  };
};
export const getCultureTypeCultureReqObj = (
  selectedAssociateInfo,
  typeId,
  filterKey,
  numberPage,
  countPage
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey?.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED', 'ARCHIVED']
      }
    };
  }
  return {
    module: 'Culture-Profile-Type',
    action: 'Review-List',
    actionFilter: 'Culture-Profile',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      orderBy: {
        columnName:
          'informationBasic.cultureProfileName,informationBasic.cultureProfileDescription',
        order: 'asc'
      },
      typeId: typeId,
      filter: 'true',
      searchCondition: 'AND',
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.cultureProfileType.cultureProfileTypePrimary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: typeId
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn:
                'informationAllocation.cultureProfileType.cultureProfileTypeSecondary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: typeId
                }
              }
            }
          ]
        },
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.cultureProfileStatus',
              conditionValue: searchObj
            }
          ]
        }
      ]
    }
  };
};
export const getCultureTypeCultureScanReqObj = (
  selectedAssociateInfo,
  typeId,
  filterKey,
  numberPage,
  countPage,
  searchStr
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey?.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED', 'ARCHIVED']
      }
    };
  }
  return {
    module: 'Culture-Profile-Type',
    action: 'Review-List',
    actionFilter: 'Culture-Profile',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      typeId: typeId,
      filter: 'true',
      searchCondition: 'AND',
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.cultureProfileName',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.cultureProfileDescription',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.cultureProfileTag.cultureProfileTagPrimary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            }
          ]
        },
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.cultureProfileTypeStatus',
              conditionValue: searchObj
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.cultureProfileType.cultureProfileTypePrimary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: typeId
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn:
                'informationAllocation.cultureProfileType.cultureProfileTypeSecondary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: typeId
                }
              }
            }
          ]
        }
      ]
    }
  };
};
export const getCultureGroupCultureReqObj = (
  selectedAssociateInfo,
  groupId,
  filterKey,
  numberPage,
  countPage
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED', 'ARCHIVED']
      }
    };
  }
  return {
    module: 'Culture-Profile-Group',
    action: 'Review-List',
    actionFilter: 'Culture-Profile',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      groupId: groupId,
      orderBy: {
        columnName:
          'informationBasic.cultureProfileName,informationBasic.cultureProfileDescription',
        order: 'asc'
      },
      filter: 'true',
      searchCondition: 'AND',
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn:
                'informationAllocation.cultureProfileGroup.cultureProfileGroupPrimary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: groupId
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn:
                'informationAllocation.cultureProfileGroup.cultureProfileGroupSecondary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: groupId
                }
              }
            }
          ]
        },
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.cultureProfileStatus',
              conditionValue: searchObj
            }
          ]
        }
      ]
    }
  };
};
export const getCultureGroupCultureScanReqObj = (
  selectedAssociateInfo,
  groupId,
  filterKey,
  numberPage,
  countPage,
  searchStr
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED', 'SUSPENDED', 'TERMINATED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED', 'ARCHIVED']
      }
    };
  }
  return {
    module: 'Culture-Profile-Group',
    action: 'Review-List',
    actionFilter: 'Culture-Profile',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      groupId: groupId,
      filter: 'true',
      orderBy: {
        columnName:
          'informationBasic.cultureProfileName,informationBasic.cultureProfileDescription',
        order: 'asc'
      },
      searchCondition: 'AND',
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.cultureProfileName',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.cultureProfileDescription',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.cultureProfileTag.cultureProfileTagPrimary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            }
          ]
        },
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.cultureProfileStatus',
              conditionValue: searchObj
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn:
                'informationAllocation.cultureProfileGroup.cultureProfileGroupPrimary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: groupId
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn:
                'informationAllocation.cultureProfileGroup.cultureProfileGroupSecondary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: groupId
                }
              }
            }
          ]
        }
      ]
    }
  };
};
export const getNodeCultureProfileReqObj = (
  selectedAssociateInfo,
  nodeId,
  filterKey,
  numberPage,
  countPage,
  actionFilter = ''
) => {
  console.log(actionFilter, 'ACTIONFILTER');
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED', 'SUSPENDED', 'TERMINATED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED', 'SUSPENDED', 'TERMINATED', 'ARCHIVED']
      }
    };
  }
  let reqBody = {
    module: 'Culture-Profile-Distinct',
    action: 'Review-List',
    actionFilter: actionFilter ? actionFilter : 'Culture-Profile-Node',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      nodeId: nodeId,
      filter: 'true',
      orderBy: {
        columnName:
          'informationBasic.cultureProfileName,informationBasic.cultureProfileDescription',
        order: 'asc'
      },
      searchCondition: 'AND',
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.cultureProfileNode.cultureProfileNodePrimary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: nodeId
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn:
                'informationAllocation.cultureProfileNode.cultureProfileNodeSecondary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: nodeId
                }
              }
            }
          ]
        },
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.cultureProfileStatus',
              conditionValue: searchObj
            }
          ]
        }
      ]
    }
  };
  return reqBody;
};
export const getNodeCultureProfileScanReqObj = (
  selectedAssociateInfo,
  nodeId,
  filterKey,
  numberPage,
  countPage,
  searchStr,
  actionFilter = ''
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED', 'SUSPENDED', 'TERMINATED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED', 'SUSPENDED', 'TERMINATED', 'ARCHIVED']
      }
    };
  }
  return {
    module: 'Culture-Profile-Distinct',
    action: 'Review-List',
    actionFilter: 'Culture-Profile-Node',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      nodeId: nodeId,
      filter: 'true',
      searchCondition: 'AND',
      orderBy: {
        columnName:
          'informationBasic.cultureProfileName,informationBasic.cultureProfileDescription',
        order: 'asc'
      },
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.cultureProfileNode.cultureProfileNodePrimary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: nodeId
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn:
                'informationAllocation.cultureProfileNode.cultureProfileNodeSecondary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: nodeId
                }
              }
            }
          ]
        },
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.cultureProfileStatus',
              conditionValue: searchObj
            }
          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.cultureProfileName',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationBasic.cultureProfileDescription',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.cultureProfileTag.cultureProfileTagPrimary',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: searchStr
                }
              }
            }
          ]
        }
      ]
    }
  };
};

export const getAssesseeSelfAssignmentList = (
  selectedAssociateInfo,
  countPage,
  statusArr,
  dispatch,
  secondaryOptionCheckValue,
  siftKey
) => {
  // debugger
  let reqBody = {
    module: 'Assessee-Assignment',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      assesseeAssignmentStatus: statusArr,
      filterTypeName: 'iGuru_Assignment',
      countPage: -1,
      numberPage: 0,
      filter: true,
      orderBy: {
        columnName: 'assesseeAssignmentName',
        order: 'asc'
      },
      searchCondition: 'AND',
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'assesseeAssignmentStatus',
              conditionValue: {
                condition: 'in',
                value: {
                  in: [
                    'STARTED',
                    'UNSTARTED',
                    'ABORTED',
                    'FINISHED',
                    'UNFINISHED',
                    'SUSPENDED',
                    'TERMINATED',
                    'ARCHIVED'
                  ]
                }
              }
            }
          ]
        }
      ]
    }
  };
  dispatch({ type: SET_REQUEST_OBJECT, payload: reqBody });
  dispatch({ type: SET_PAGE_COUNT, payload: 1 });
  dispatch({ type: LOADER_START });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'typeOfMiddlePaneList', value: '' }
  });
  dispatch({
    type: GET_ASSESSEE_ASSIGNMENT_SAGA,
    payload: {
      request: reqBody,
      BadgeOne: secondaryOptionCheckValue === 'unfinished' ? 'timeout' : secondaryOptionCheckValue,
      BadgeTwo: siftKey === secondaryOptionCheckValue ? '' : siftKey,
      BadgeThree: ''
    }
  });
};

export const resetReducerStateForTest = (dispatch, secondaryOptionCheckValue) => {
  // debugger
  dispatch({ type: CLEAR_ASSESSEE_ASSESSMENT_REDUCER_STATE });
  dispatch({
    type: SET_POPUP_SINGLE_STATE,
    payload: { stateName: 'cardValue', value: 'Card' }
  });
  dispatch({
    type: FILTERMODE,
    payload: {
      FilterMode: 'assesseeAssignmentDistinct' + secondaryOptionCheckValue
    }
  });
  dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'relatedReviewListDistinctData', value: [] }
  });
  dispatch({
    type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
    payload: { stateName: 'assesseeAssignmentAssessmentData', value: null }
  });
  dispatch({
    type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
    payload: { stateName: 'assesseeAssessmentStartData', value: null }
  });
  dispatch({
    type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
    payload: { stateName: 'isAssessmentStart', value: '' }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
};
export const getAssesseeSelfAssignmentScanList = (
  selectedAssociateInfo,
  countPage,
  dispatch,
  secondaryOptionCheckValue,
  siftKey,
  scanString,
  middlePaneHeaderBadgeTwo = ''
) => {
  console.log(countPage, secondaryOptionCheckValue, siftKey, scanString);
  console.log('&&&&&');
  let reqBodyAll = makeAssesseeAssignmentRequestObj(
    selectedAssociateInfo?.assesseeId,
    selectedAssociateInfo
  );
  let reqBody = getAssesseeRelatedAssigmentReqObj(
    selectedAssociateInfo,
    selectedAssociateInfo?.assesseeId,
    secondaryOptionCheckValue,
    countPage,
    scanString,
    middlePaneHeaderBadgeTwo
  );
  dispatch({ type: SET_REQUEST_OBJECT, payload: reqBodyAll });
  dispatch({ type: SET_PAGE_COUNT, payload: 1 });
  dispatch({ type: LOADER_START });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'typeOfMiddlePaneList', value: '' }
  });
  let BadgeTwo = secondaryOptionCheckValue === siftKey ? '' : siftKey;
  dispatch({
    type: GET_ASSESSEE_ASSIGNMENT_SAGA,
    payload: {
      request: reqBody,
      BadgeOne: secondaryOptionCheckValue,
      BadgeTwo: middlePaneHeaderBadgeTwo ? middlePaneHeaderBadgeTwo : BadgeTwo,
      BadgeThree: ''
    }
  });
};

export function convertToLocalTime(milisec) {
  var finalformat = '00:00:00';
  if (milisec) {
    var dd = new Date(milisec);
    var h = dd.getHours(),
      m = dd.getMinutes();
    var _time =
      h > 12
        ? h - 12 + ':' + (m < 10 ? '0' + m : m) + ' PM'
        : h + ':' + (m < 10 ? '0' + m : m) + ' AM';
    finalformat =
      ('0' + dd.getDate()).slice(-2) +
      '/' +
      ('0' + (dd.getMonth() + 1)).slice(-2) +
      '/' +
      dd.getFullYear() +
      ', ' +
      _time;
  }

  return finalformat;
}

export function DisplayDate(date) {
  let FormatedDate = new Date(date).toLocaleString('en-US', {
    timeZone: 'Asia/Kolkata',
    hour12: true,
    dateStyle: 'short', timeStyle: 'short'
  });

  return FormatedDate;
}

// export function dateConverter(utcDate){
//   let dateUTC = new Date(utcDate);
//    dateUTC = dateUTC.getTime()
//   let dateIST = new Date(dateUTC);
//   // let dateIST = new Date(utcDate)
//   //date shifting for IST timezone (+5 hours and 30 minutes)
//   dateIST.setHours(dateIST.getHours() + 5);
//   dateIST.setMinutes(dateIST.getMinutes() + 30);

//   return dateIST
// }

export function calculateTime(milisec) {
  let duration = '00 Hr, 00 Mins, 00 Secs';
  if (milisec) {
    // var dd = new Date(milisec);
    // var seconds = dd.getSeconds();
    // var minutes = dd.getUTCMinutes();
    // // var hours = dd.getUTCHours();

    // // hours = hours < 10 && '0' + hours;
    // minutes = minutes < 10 ? '0' + minutes : minutes;
    // seconds = seconds < 10 ? '0' + seconds : seconds;

    // duration = minutes + ' Mins, ' + seconds + ' Secs';
    //new code
    // 1- Convert to seconds:
    var seconds = milisec / 1000;
    // 2- Extract hours:
    var hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour
    var hh = hours < 10 ? '0' + hours : hours;
    seconds = seconds % 3600; // seconds remaining after extracting hours
    // 3- Extract minutes:
    var minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
    var mm = minutes < 10 ? '0' + minutes : minutes;
    // 4- Keep only seconds not extracted to minutes:
    seconds = parseInt(seconds % 60);
    var ss = seconds < 10 ? '0' + seconds : seconds;
    // duration=hh+"Hr : "+minutes+"Mins : "+seconds+"Secs";
    duration = hh + ' Hr, ' + mm + ' Mins, ' + ss + ' Secs';
  }

  return duration;
}
export const setItemTypeConfigState = (itemFrameworkOneType, dispatch, isItemModule = false) => {
  console.log('itemFrameworkOneType', itemFrameworkOneType);
  console.log('isItemModule', isItemModule);
  let reviseSetting = {
    blankState: true,
    classificationState: !isItemModule,
    levelState: true,
    polarityState: !isItemModule,
    scaleState: !isItemModule,
    scoreState: true,
    timeState: true,
    weightageState: true,
    noOfItemState: true,
    noOfResponseState: true,
    responseLabel: !isItemModule
  };
  if (itemFrameworkOneType === '61090cace50cf61d5eb440c9') {
    // "Rating-Scale (Multiple-Select)"
    reviseSetting = {
      blankState: false,
      classificationState: !isItemModule,
      levelState: false,
      polarityState: !isItemModule,
      scaleState: !isItemModule,
      scoreState: true,
      timeState: true,
      weightageState: true,
      noOfItemState: true,
      noOfResponseState: true,
      responseLabel: !isItemModule
    };
  }
  if (itemFrameworkOneType === '61090cace50cf61d5eb440ce') {
    //"Response-Choice (Single-Select)"
    reviseSetting = {
      blankState: false,
      classificationState: false,
      levelState: true,
      polarityState: false,
      scaleState: false,
      scoreState: true,
      timeState: true,
      weightageState: false,
      noOfItemState: false,
      noOfResponseState: true,
      responseLabel: !isItemModule
    };
  }
  if (itemFrameworkOneType === '61090cace50cf61d5eb440c4') {
    //"Fill-in-the-Blank (Response-Choice)"
    reviseSetting = {
      blankState: true,
      classificationState: false,
      levelState: true,
      polarityState: false,
      scaleState: false,
      scoreState: true,
      timeState: true,
      weightageState: false,
      noOfItemState: false,
      noOfResponseState: true,
      responseLabel: !isItemModule
    };
  }
  if (
    itemFrameworkOneType === '61090cace50cf61d5eb440cc' ||
    itemFrameworkOneType === '61090cace50cf61d5eb440cd'
  ) {
    //"Response (Long) (Short)"
    reviseSetting = {
      blankState: false,
      classificationState: false,
      levelState: true,
      polarityState: false,
      scaleState: false,
      scoreState: true,
      timeState: true,
      weightageState: false,
      noOfItemState: false,
      noOfResponseState: false,
      responseLabel: !isItemModule
    };
  }
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'itemConfigStates', value: reviseSetting }
  });
};

export function converTimeToMiliseconds(time) {
  console.log('time', time, typeof time);
  if (time && typeof time === 'string' && time?.includes(':')) {
    let timeConvesion = time.split(':');
    let milisec = (Number(timeConvesion[0]) * 60 * 60 + Number(timeConvesion[1]) * 60) * 1000;
    return milisec;
  } else {
    return time;
  }
}
export const callApiFunctionLastAttempted = (
  selectedAssociateInfo,
  assesseeAssignmentAssessmentData,
  dispatch,
  lastAttempted
) => {
  let reqBody = {
    module: 'Assessee-Assignment',
    action: 'Last-Attempted-Item',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      assignmentId: assesseeAssignmentAssessmentData.assignmentId,
      assessmentId: assesseeAssignmentAssessmentData.assessmentId,
      //assesseeAssignmentAssessmentItemLastAttempted: lastAttempted
      assesseeAssignmentAssessmentItemAttemptedLast: lastAttempted
    }
  };
  dispatch({
    type: SET_ASSESSEE_ASSESSMENT_ITEM_LAST_ATTEMPT_SAGA,
    payload: { request: reqBody }
  });
};

/** 
export const updateReviseStatus = (
  selectedAssociateInfo,
  selectedValue,
  dispatch,
  searchKey,
  keyVal,
  typeOfMiddlePaneList,
  createMode = ''
) => {
  let reqBody = {
    assesseeId: selectedAssociateInfo?.assesseeId,
    associateId:
      selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
    [searchKey]: selectedValue,
    reviseStatus: keyVal
  };
  if (
    typeOfMiddlePaneList === 'assesseesDistinctReviewList' ||
    typeOfMiddlePaneList === 'assesseesGroupDistinctReviewList' ||
    typeOfMiddlePaneList === 'assesseesTypeDistinctReviewList' ||
    typeOfMiddlePaneList === 'assesseeRoleDistinctReviewList' ||
    typeOfMiddlePaneList === 'assesseesGroupAssesseeReviewList'
  ) {
    reqBody = {
      module: 'Assessee-Distinct',
      action: 'Revise',
      actionFilter: 'Status',
      requestObject: { ...reqBody }
    };
  }
  if (
    typeOfMiddlePaneList === 'assessmentDistinctReviewList' ||
    typeOfMiddlePaneList === 'assessmentsTypeDistinctReviewList' ||
    typeOfMiddlePaneList === 'assessmentsGroupDistinctReviewList'
  ) {
    reqBody = {
      module: 'Assessment-Distinct',
      action: 'Revise',
      actionFilter: 'Status',
      requestObject: { ...reqBody }
    };
  }
  if (
    typeOfMiddlePaneList === 'assignmentDistinctReviewList' ||
    typeOfMiddlePaneList === 'assignmentsGroupDistinctReviewList' ||
    typeOfMiddlePaneList === 'assignmentsTypeDistinctReviewList'
  ) {
    reqBody = {
      module: 'Assignment-Distinct',
      action: 'Revise',
      actionFilter: 'Status',
      requestObject: { ...reqBody }
    };
  }
  if (
    typeOfMiddlePaneList === 'associateDistinctReviewList' ||
    typeOfMiddlePaneList === 'associatesGroupDistinctReviewList' ||
    typeOfMiddlePaneList === 'associatesTypeDistinctReviewList' ||
    typeOfMiddlePaneList === 'associateRoleDistinctReviewList' ||
    typeOfMiddlePaneList === 'associateNodeDistinctReviewList'
  ) {
    reqBody = {
      module: 'Associate-Distinct',
      action: 'Revise',
      actionFilter: 'Status',
      requestObject: { ...reqBody }
    };
  }
  if (
    typeOfMiddlePaneList === 'itemsDistinctReviewList' ||
    typeOfMiddlePaneList === 'itemsGroupDistinctReviewList' ||
    typeOfMiddlePaneList === 'itemsTypeDistinctReviewList'
  ) {
    reqBody = {
      module: 'Item-Distinct',
      action: 'Revise',
      actionFilter: 'Status',
      requestObject: { ...reqBody }
    };
  }
  if (
    typeOfMiddlePaneList === 'cultureProfilesDistinctReviewList' ||
    typeOfMiddlePaneList === 'cultureProfilesGroupDistinctReviewList' ||
    typeOfMiddlePaneList === 'cultureProfilesTypeDistinctReviewList'
  ) {
    reqBody = {
      module: 'Culture-Profile-Distinct',
      action: 'Revise',
      actionFilter: 'Status',
      requestObject: { ...reqBody }
    };
  }
  if (
    typeOfMiddlePaneList === 'jobProfilesDistinctReviewList' ||
    typeOfMiddlePaneList === 'jobProfilesGroupDistinctReviewList' ||
    typeOfMiddlePaneList === 'jobProfilesTypeDistinctReviewList'
  ) {
    reqBody = {
      module: 'Job-Profile-Distinct',
      action: 'Revise',
      actionFilter: 'Status',
      requestObject: { ...reqBody }
    };
  }
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'relatedReviewListDistinctData',
      value: []
    }
  });
  dispatch({ type: LOADER_START });
  // if (keyVal === 'PUBLISHED') {
  //   if (typeOfMiddlePaneList === 'itemsDistinctReviewList') {
  //     reqBody = {
  //       module: 'Item-Distinct',
  //       action: 'Revise',
  //       actionFilter: 'Status',
  //       requestObject: { ...reqBody }
  //     };
  //     dispatch({
  //       type: ITEM_MULTI_STATUS_REVISE_SAGA,
  //       payload: { secondaryOptionCheckValue: '', headerOne: '', reqBody, typeOfMiddlePaneList }
  //     });
  //   }
  // } else {
  dispatch({
    type:
      typeOfMiddlePaneList === 'itemsDistinctReviewList' ||
      typeOfMiddlePaneList === 'itemsGroupDistinctReviewList' ||
      typeOfMiddlePaneList === 'itemsTypeDistinctReviewList'
        ? ITEM_MULTI_STATUS_REVISE_SAGA
        : typeOfMiddlePaneList === 'assessmentDistinctReviewList' ||
          typeOfMiddlePaneList === 'assessmentsTypeDistinctReviewList' ||
          typeOfMiddlePaneList === 'assessmentsGroupDistinctReviewList'
        ? ASSESSMENT_MULTI_STATUS_REVISE_SAGA
        : typeOfMiddlePaneList === 'assesseesDistinctReviewList' ||
          typeOfMiddlePaneList === 'assesseesGroupDistinctReviewList' ||
          typeOfMiddlePaneList === 'assesseesTypeDistinctReviewList' ||
          typeOfMiddlePaneList === 'assesseeRoleDistinctReviewList' ||
          typeOfMiddlePaneList === 'assesseesGroupAssesseeReviewList'
        ? ASSESSEE_MULTI_STATUS_REVISE_SAGA
        : typeOfMiddlePaneList === 'assignmentDistinctReviewList' ||
          typeOfMiddlePaneList === 'assignmentsGroupDistinctReviewList' ||
          typeOfMiddlePaneList === 'assignmentsTypeDistinctReviewList'
        ? ASSIGNMENT_MULTI_STATUS_REVISE_SAGA
        : typeOfMiddlePaneList === 'associateDistinctReviewList' ||
          typeOfMiddlePaneList === 'associatesGroupDistinctReviewList' ||
          typeOfMiddlePaneList === 'associatesTypeDistinctReviewList' ||
          typeOfMiddlePaneList === 'associateRoleDistinctReviewList' ||
          typeOfMiddlePaneList === 'associateNodeDistinctReviewList'
        ? ASSOCIATE_MULTI_STATUS_REVISE_SAGA
        : typeOfMiddlePaneList === 'cultureProfilesDistinctReviewList' ||
          typeOfMiddlePaneList === 'cultureProfilesGroupDistinctReviewList' ||
          typeOfMiddlePaneList === 'cultureProfilesTypeDistinctReviewList'
        ? CULTURE_PROFILE_MULTI_STATUS_REVISE_SAGA
        : typeOfMiddlePaneList === 'jobProfilesDistinctReviewList' ||
          typeOfMiddlePaneList === 'jobProfilesGroupDistinctReviewList' ||
          typeOfMiddlePaneList === 'jobProfilesTypeDistinctReviewList'
        ? JOB_PROFILE_MULTI_STATUS_REVISE_SAGA
        : '',
    payload: {
      secondaryOptionCheckValue: '',
      headerOne: '',
      reqBody,
      typeOfMiddlePaneList,
      reviseStatus: keyVal,
      createMode
    }
  });
  // }
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'typeOfMiddlePaneList', value: '' }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'isSelectActive', value: '' }
  });
  dispatch({
    type: SET_POPUP_SINGLE_STATE,
    payload: { stateName: 'selectionValue', value: '' }
  });
  dispatch({ type: POPUP_CLOSE });
};  
*/
export const UpdateGaugeReviseStatus = (
  selectedAssociateInfo,
  selectedTagValue,
  dispatch,
  keyVal,
  typeOfMiddlePaneList,
  createMode
) => {
  console.log('selectedTagValue>>>', selectedTagValue);
  let reqBody;
  reqBody = {
    module: 'Analytics',
    action: 'UpdateStatus',
    requestObject: {
      id: selectedTagValue,
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      igaugeOccupationFrameworkOneGaugeStatus: keyVal
    }
  };
  console.log('createMode>>>', createMode);

  dispatch({
    type: GET_GAUGE_REVISE_STATUS_SAGA,
    payload: {
      secondaryOptionCheckValue: '',
      headerOne: '',
      reqBody,
      typeOfMiddlePaneList,
      reviseStatus: keyVal,
      createMode: createMode
    }
  });
  dispatch({ type: POPUP_CLOSE });
};

export const updateReviseAssesseeAssignmentStatus = (
  selectedAssociateInfo,
  selectedTagValue,
  dispatch,
  keyVal,
  typeOfMiddlePaneList,
  relatedReviewListDistinctData,
  middlePaneHeaderBadgeTwo
) => {
  let reqBody;
  reqBody = {
    module: 'Assessee-Assignment',
    action: 'Update-Status',
    requestObject: {
      updateAssignmentStatus: keyVal,
      assignmentId: selectedTagValue,
      assignmentAssesseeId: relatedReviewListDistinctData[0]?.assesseeId,
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary
    }
  };
  dispatch({ type: LOADER_START });
  let requestBody = makeAssesseeAssignmentRequestObj(
    relatedReviewListDistinctData[0]?.assesseeId,
    selectedAssociateInfo,
    middlePaneHeaderBadgeTwo
  );
  dispatch({
    type: ASSESSEE_ASSESSMENT_INFO_REVISE_STATUS_SAGA,
    payload: {
      reqBody: reqBody,
      typeOfMiddlePaneList: typeOfMiddlePaneList,
      middlePaneHeaderBadgeTwo: middlePaneHeaderBadgeTwo,
      refreshListReq: {
        request: requestBody,
        relatedListCall: 'assesseeDistinct',
        responseObj: {
          assesseeId: relatedReviewListDistinctData[0]?.assesseeId,
          assesseeInfo: relatedReviewListDistinctData[0]?.assesseeInfo
        }
      }
    }
  });
  dispatch({
    type: SET_POPUP_SINGLE_STATE,
    payload: { stateName: 'selectionValue', value: '' }
  });
  dispatch({ type: POPUP_CLOSE });
};

export const getGuageDistinctApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  dispatch,
  clickValue,
  searchValue,
  isSelectActive = '',
  selectedTagsArray = ''
) => {
  console.log('secondaryOptionCheckValue', secondaryOptionCheckValue);
  let activeArr = [secondaryOptionCheckValue.toUpperCase()];
  if (secondaryOptionCheckValue === 'all') {
    activeArr = ['PUBLISHED', 'UNPUBLISHED', 'SUSPENDED', 'TERMINATED'];
  }
  if (secondaryOptionCheckValue === 'inactive') {
    activeArr = ['UNPUBLISHED', 'SUSPENDED', 'TERMINATED'];
  }
  if (secondaryOptionCheckValue === 'active') {
    activeArr = ['PUBLISHED'];
  }
  let requestObj = {
    module: 'Analytics',
    action: 'Create-Gauge',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      igaugeOccupationFrameworkOneGaugeStatus: activeArr,
      searchKey: searchValue,
      action: 'review-list' //reviewId//reviewList
    }
  };
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
  dispatch({ type: SET_PAGE_COUNT, payload: 1 });
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: 'Gauge' + secondaryOptionCheckValue }
  });
  dispatch({ type: POPUP_CLOSE });
  dispatch({ type: LOADER_START });
  // resetDataFunction();
  dispatch({
    type: CREATE_GAUGE_SAGA,
    payload: {
      reqBody: requestObj,
      action: 'reviewList',
      isMiddlePaneList: true,
      BadgeOne: clickValue,
      BadgeTwo: secondaryOptionCheckValue,
      isSelectActive: isSelectActive,
      selectedTagsArray: selectedTagsArray
    }
  });
};

export const updateReviseStatus = (
  selectedAssociateInfo,
  selectedValue,
  dispatch,
  searchKey,
  keyVal,
  typeOfMiddlePaneList,
  createMode = ''
) => {
  let reqBody = {
    assesseeId: selectedAssociateInfo?.assesseeId,
    associateId:
      selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
    [searchKey]: selectedValue,
    reviseStatus: keyVal
  };
  if (
    typeOfMiddlePaneList === 'assesseesDistinctReviewList' ||
    typeOfMiddlePaneList === 'assesseesGroupDistinctReviewList' ||
    typeOfMiddlePaneList === 'assesseesTypeDistinctReviewList' ||
    typeOfMiddlePaneList === 'assesseeRoleDistinctReviewList' ||
    typeOfMiddlePaneList === 'assesseesTypeAssesseeReviewList' ||
    typeOfMiddlePaneList === 'assesseesGroupAssesseeReviewList' ||
    typeOfMiddlePaneList === 'administratorsDistinctReviewList' ||
    typeOfMiddlePaneList === 'assistantsDistinctReviewList' ||
    typeOfMiddlePaneList === 'managersDistinctReviewList' ||
    createMode === 'assessee' ||
    createMode === 'assesseesRole' ||
    createMode === 'assesseesGroup' ||
    createMode === 'assesseesType'
  ) {
    reqBody = {
      module: 'Assessee-Distinct',
      action: 'Revise',
      actionFilter: 'Status',
      requestObject: { ...reqBody }
    };
  }
  if (
    typeOfMiddlePaneList === 'assessmentDistinctReviewList' ||
    typeOfMiddlePaneList === 'assessmentsTypeDistinctReviewList' ||
    typeOfMiddlePaneList === 'assessmentsGroupDistinctReviewList' ||
    typeOfMiddlePaneList === 'assessmentGroupAssessmentReviewList' ||
    typeOfMiddlePaneList === 'assessmentTypeAssessmentReviewList' || // added
    createMode === 'assessmentsGroup' ||
    createMode === 'assessment' ||
    createMode === 'assessmentsType'
  ) {
    reqBody = {
      module: 'Assessment-Distinct',
      action: 'Revise',
      actionFilter: 'Status',
      requestObject: { ...reqBody }
    };
  }
  if (
    typeOfMiddlePaneList === 'assignmentsGroupDistinctReviewList' ||
    typeOfMiddlePaneList === 'assignmentsTypeDistinctReviewList' ||
    createMode === 'assignmentsGroup' ||
    createMode === 'assignmentsType' ||
    createMode === 'assignment'
  ) {
    reqBody = {
      module: 'Assignment-Distinct',
      action: 'Revise',
      actionFilter: 'Status',
      requestObject: { ...reqBody }
    };
  }
  if (
    typeOfMiddlePaneList === 'assignmentDistinctReviewList' ||
    typeOfMiddlePaneList === 'assesseeDistinctAssignmentDistinctReviewList' ||
    typeOfMiddlePaneList === 'assignmentGroupAssignmentReviewList' ||
    typeOfMiddlePaneList === 'assignmentTypeAssignmentReviewList'
  ) {
    reqBody = {
      module: 'Assignment-Distinct',
      // action: 'Update-Status',
      action: 'Revise',
      actionFilter: 'Status',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        // assignmentId: selectedValue,
        // assignmentStatus: keyVal
        assignmentDistinct: selectedValue,
        reviseStatus: keyVal
      }
    };
  }
  if (
    typeOfMiddlePaneList === 'associateDistinctReviewList' ||
    typeOfMiddlePaneList === 'associatesGroupDistinctReviewList' ||
    typeOfMiddlePaneList === 'associatesTypeDistinctReviewList' ||
    typeOfMiddlePaneList === 'associateRoleDistinctReviewList' ||
    typeOfMiddlePaneList === 'associateNodeDistinctReviewList' ||
    typeOfMiddlePaneList === 'associatesGroupAssociateReviewList' ||
    //typeOfMiddlePaneList === 'associatesNodeDistinctReviewList'||
    createMode === 'associatesRole' ||
    createMode === 'associatesGroup' ||
    createMode === 'associate' ||
    createMode === 'associatesType'
  ) {
    reqBody = {
      module: 'Associate-Distinct',
      action: 'Revise',
      actionFilter: 'Status',
      requestObject: { ...reqBody }
    };
  }
  if (typeOfMiddlePaneList === 'associatesNodeDistinctReviewList') {
    reqBody = {
      module: 'Associate-Distinct',
      action: 'Revise',
      actionFilter: 'Status',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        [searchKey]: selectedValue,
        reviseStatus: keyVal
      }
    };
  }
  if (
    typeOfMiddlePaneList === 'itemsDistinctReviewList' ||
    typeOfMiddlePaneList === 'itemsGroupDistinctReviewList' ||
    typeOfMiddlePaneList === 'itemsTypeDistinctReviewList' ||
    typeOfMiddlePaneList === 'itemGroupItemReviewList' ||
    typeOfMiddlePaneList === 'itemTypeItemReviewList' ||
    typeOfMiddlePaneList === 'assessmentItemReviewList' ||
    typeOfMiddlePaneList === 'itemNodeItemReviewList' ||
    createMode === 'item' ||
    createMode === 'itemsGroup' ||
    createMode === 'itemsType'
  ) {
    reqBody = {
      module: 'Item-Distinct',
      action: 'Revise',
      actionFilter: 'Status',
      requestObject: { ...reqBody }
    };
  }
  if (
    typeOfMiddlePaneList === 'cultureProfilesDistinctReviewList' ||
    typeOfMiddlePaneList === 'cultureProfilesGroupDistinctReviewList' ||
    typeOfMiddlePaneList === 'cultureProfilesTypeDistinctReviewList' ||
    typeOfMiddlePaneList === 'cultureProfileTypeCultureProfileReviewList' ||
    typeOfMiddlePaneList === 'cultureProfileGroupCultureProfileReviewList' ||
    createMode === 'culture-profilesRole' ||
    createMode === 'culture-profilesGroup' ||
    createMode === 'culture-profilesType'
  ) {
    reqBody = {
      module: 'Culture-Profile-Distinct',
      action: 'Revise',
      actionFilter: 'Status',
      requestObject: { ...reqBody }
    };
  }
  if (
    typeOfMiddlePaneList === 'jobProfilesDistinctReviewList' ||
    typeOfMiddlePaneList === 'jobProfileTypeJobProfileReviewList' ||
    typeOfMiddlePaneList === 'jobProfilesGroupDistinctReviewList' ||
    typeOfMiddlePaneList === 'jobProfilesTypeDistinctReviewList' ||
    typeOfMiddlePaneList === 'jobProfileGroupJobProfileReviewList' ||
    createMode === 'job-profilesType' ||
    createMode === 'job-profilesGroup'
  ) {
    reqBody = {
      module: 'Job-Profile-Distinct',
      action: 'Revise',
      actionFilter: 'Status',
      requestObject: { ...reqBody }
    };
  }
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'relatedReviewListDistinctData',
      value: []
    }
  });

  dispatch({ type: LOADER_START });
  // if (keyVal === 'PUBLISHED') {
  //   if (typeOfMiddlePaneList === 'itemsDistinctReviewList') {
  //     reqBody = {
  //       module: 'Item-Distinct',
  //       action: 'Revise',
  //       actionFilter: 'Status',
  //       requestObject: { ...reqBody }
  //     };
  //     dispatch({
  //       type: ITEM_MULTI_STATUS_REVISE_SAGA,
  //       payload: { secondaryOptionCheckValue: '', headerOne: '', reqBody, typeOfMiddlePaneList }
  //     });
  //   }
  // } else {

  dispatch({
    type:
      typeOfMiddlePaneList === 'itemsDistinctReviewList' ||
        typeOfMiddlePaneList === 'itemsGroupDistinctReviewList' ||
        typeOfMiddlePaneList === 'itemsTypeDistinctReviewList' ||
        typeOfMiddlePaneList === 'itemGroupItemReviewList' ||
        typeOfMiddlePaneList === 'itemTypeItemReviewList' ||
        createMode === 'item' ||
        createMode === 'itemsGroup' ||
        createMode === 'itemsType'
        ? ITEM_MULTI_STATUS_REVISE_SAGA
        : typeOfMiddlePaneList === 'assessmentDistinctReviewList' ||
          typeOfMiddlePaneList === 'assessmentTypeAssessmentReviewList' ||
          typeOfMiddlePaneList === 'assessmentsTypeDistinctReviewList' ||
          typeOfMiddlePaneList === 'assessmentsGroupDistinctReviewList' ||
          typeOfMiddlePaneList === 'assessmentGroupAssessmentReviewList' ||
          createMode === 'assessmentsGroup' ||
          createMode === 'assessmentsType' || // added
          createMode === 'assessment'
          ? ASSESSMENT_MULTI_STATUS_REVISE_SAGA
          : typeOfMiddlePaneList === 'assesseesDistinctReviewList' ||
            typeOfMiddlePaneList === 'assesseesGroupDistinctReviewList' ||
            typeOfMiddlePaneList === 'assesseesTypeDistinctReviewList' ||
            typeOfMiddlePaneList === 'assesseesTypeAssesseeReviewList' ||
            typeOfMiddlePaneList === 'assesseeRoleDistinctReviewList' ||
            typeOfMiddlePaneList === 'assesseesGroupAssesseeReviewList' ||
            typeOfMiddlePaneList === 'administratorsDistinctReviewList' ||
            typeOfMiddlePaneList === 'assistantsDistinctReviewList' ||
            typeOfMiddlePaneList === 'managersDistinctReviewList' ||
            createMode === 'assessee' ||
            createMode === 'assesseesRole' ||
            createMode === 'assesseesGroup' ||
            createMode === 'assesseesType'
            ? ASSESSEE_MULTI_STATUS_REVISE_SAGA
            : typeOfMiddlePaneList === 'assignmentDistinctReviewList' ||
              typeOfMiddlePaneList === 'assignmentsGroupDistinctReviewList' ||
              typeOfMiddlePaneList === 'assignmentTypeAssignmentReviewList' ||
              typeOfMiddlePaneList === 'assignmentsTypeDistinctReviewList' ||
              typeOfMiddlePaneList === 'assignmentGroupAssignmentReviewList' ||
              createMode === 'assignmentsGroup' ||
              createMode === 'assignmentsType' ||
              createMode === 'assignment'
              ? ASSIGNMENT_MULTI_STATUS_REVISE_SAGA
              : typeOfMiddlePaneList === 'associateDistinctReviewList' ||
                typeOfMiddlePaneList === 'associatesGroupDistinctReviewList' ||
                typeOfMiddlePaneList === 'associatesTypeDistinctReviewList' ||
                typeOfMiddlePaneList === 'associateRoleDistinctReviewList' ||
                typeOfMiddlePaneList === 'associateNodeDistinctReviewList' ||
                typeOfMiddlePaneList === 'associatesNodeDistinctReviewList' ||
                typeOfMiddlePaneList === 'associatesGroupAssociateReviewList' ||
                createMode === 'associatesRole' ||
                createMode === 'associatesGroup' ||
                createMode === 'associate' ||
                createMode === 'associatesType'
                ? ASSOCIATE_MULTI_STATUS_REVISE_SAGA
                : typeOfMiddlePaneList === 'cultureProfilesDistinctReviewList' ||
                  typeOfMiddlePaneList === 'cultureProfilesGroupDistinctReviewList' ||
                  typeOfMiddlePaneList === 'cultureProfilesTypeDistinctReviewList' ||
                  typeOfMiddlePaneList === 'cultureProfileTypeCultureProfileReviewList' ||
                  typeOfMiddlePaneList === 'cultureProfileGroupCultureProfileReviewList' ||
                  createMode === 'culture-profilesRole' ||
                  createMode === 'culture-profilesGroup' ||
                  createMode === 'culture-profilesType' ||
                  createMode === 'cultureProfile'
                  ? CULTURE_PROFILE_MULTI_STATUS_REVISE_SAGA
                  : typeOfMiddlePaneList === 'jobProfilesDistinctReviewList' ||
                    typeOfMiddlePaneList === 'jobProfileTypeJobProfileReviewList' ||
                    typeOfMiddlePaneList === 'jobProfilesGroupDistinctReviewList' ||
                    typeOfMiddlePaneList === 'jobProfilesTypeDistinctReviewList' ||
                    typeOfMiddlePaneList === 'jobProfileGroupJobProfileReviewList' ||
                    createMode === 'job-profilesType' ||
                    createMode === 'job-profilesGroup' ||
                    createMode === 'jobProfile'
                    ? JOB_PROFILE_MULTI_STATUS_REVISE_SAGA
                    : '',
    payload: {
      secondaryOptionCheckValue: '',
      headerOne: '',
      reqBody,
      typeOfMiddlePaneList,
      reviseStatus: keyVal,
      createMode
    }
  });
  // }
  dispatch({
    type: SET_DISPLAY_PANE_THREE_STATE,
    payload: {
      isReviewRevise: false,
      headerOne: '',
      headerOneBadgeOne: '',
      headerOneBadgeTwo: '',
      headerOneBadgeThree: '',
      reviewMode: 'review'
    }
  });
  dispatch({ type: POPUP_CLOSE });
};

export const updateAssesseeAproveStatus = (
  selectedAssociateInfo,
  assesseeIds,
  dispatch,
  reviseStatus,
  createMode = ''
) => {
  let reqBody = {
    module: 'Assessee-Distinct',
    action: 'Approval',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      assesseeApprovalList: assesseeIds,
      approval: reviseStatus === 'APPROVED'
    }
  };
  dispatch({ type: LOADER_START });
  dispatch({
    type: ASSESSEE_INFO_REVISE_SAGA,
    payload: {
      secondaryOptionCheckValue: '',
      headerOne: '',
      reqBody,
      createMode
    }
  });
  dispatch({ type: POPUP_CLOSE });
};
export const callApiFunctionShared = (
  selectedAssociateInfo,
  dispatch,
  typeSharedKey,
  typeShareInformation,
  apiCall
) => { };
export const getAllCountryList = (dispatch) => {
  let reqBody = {
    module: 'Iguru-Iglobal',
    action: 'Country-information',
    requestObject: {
      country: null,
      state: null
    }
  };
  dispatch({
    type: GET_COUNTRY_STATE_CITY_LIST_SAGA,
    payload: { request: reqBody, listName: 'country' }
  });
};
export const getStatesByCountryId = (dispatch, countryId) => {
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'allProvinceList',
      value: []
    }
  });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'allCityList',
      value: []
    }
  });
  if (countryId) {
    let reqBody = {
      module: 'Iguru-Iglobal',
      action: 'Country-information',
      requestObject: {
        country: countryId,
        state: null
      }
    };
    dispatch({ type: LOADER_START });
    dispatch({
      type: GET_COUNTRY_STATE_CITY_LIST_SAGA,
      payload: { request: reqBody, listName: 'states' }
    });
  }
};
export const getCitiesByStateId = (dispatch, countryId, stateId) => {
  let reqBody = {
    module: 'Iguru-Iglobal',
    action: 'Country-information',
    requestObject: {
      country: countryId,
      state: stateId
    }
  };
  dispatch({ type: LOADER_START });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'allCityList',
      value: []
    }
  });
  dispatch({
    type: GET_COUNTRY_STATE_CITY_LIST_SAGA,
    payload: { request: reqBody, listName: 'city' }
  });
};
export const getCitiesByCountryId = (dispatch, countryId) => {
  let reqBody = {
    module: 'Iguru-Iglobal',
    action: 'Country-information',
    requestObject: {
      country: countryId,
      state: null,
      isCountryCity: true
    }
  };
  dispatch({
    type: GET_COUNTRY_STATE_CITY_LIST_SAGA,
    payload: { request: reqBody, listName: 'countryCity' }
  });
};

export const getPopUpOptionList = (
  popupOptionArr,
  signedAssesseePermission,
  optionClass = 'optionPrimary'
) => {
  const optionArr = popupOptionArr;
  optionArr.forEach((element) => {
    let data = element?.permissionValue;
    if (signedAssesseePermission && optionClass === element?.optionClass) {
      if (
        data === 'review & other' &&
        element.data === 'select' &&
        signedAssesseePermission?.review &&
        (signedAssesseePermission?.revise ||
          signedAssesseePermission?.create ||
          signedAssesseePermission?.delete ||
          signedAssesseePermission?.share ||
          signedAssesseePermission.sell)
      ) {
        // console.log(element, '*')
        element.disabled = false;
      } else {
        // console.log(element, '**')
        if (data in signedAssesseePermission) {
          // console.log(element, '***')
          element.disabled = !signedAssesseePermission[data];
        }
      }
    }
    // for role permission check
    // console.log(
    //   element?.data,
    //   'option in signedAssesseePermission is',
    //   data in signedAssesseePermission,
    //   'disabled',
    //   !signedAssesseePermission[data]
    // );
  });

  return optionArr;
};
export const getPopUpOptionListAll = (popupOptionArr, signedAssesseePermission) => {
  const optionArr = popupOptionArr;
  console.log(signedAssesseePermission);
  optionArr.forEach((element) => {
    let data = element.permissionValue;
    if (data in signedAssesseePermission) {
      element.disabled = !signedAssesseePermission[data];
    }
  });
  return optionArr;
};

export const makeAssessmentPreviewSequenceObj = (responseObject, dispatch) => {
  let sequenceArr = [];
  let informationFramework = responseObject?.informationFramework;
  let assessmentAdministrationSequence =
    responseObject?.informationFramework?.assessmentAdministrationSequence;
  if (assessmentAdministrationSequence.length > 0) {
    for (let i = 0; i < assessmentAdministrationSequence.length; i++) {
      let currentValue = informationFramework?.assessmentAdministrationSequence[i];
      let lastIndexValue = parseInt(currentValue.substring(currentValue.length - 1));
      let matchValue = currentValue;
      matchValue = matchValue.substring(0, matchValue.length - 1);
      if (matchValue.trim() === 'assessment communiqué') {
        sequenceArr.push({
          name: matchValue.trim(),
          value: informationFramework?.assessmentCommunique[lastIndexValue - 1],
          originalValue: currentValue
        });
      } else if (matchValue.trim() === 'assessment synopsis') {
        sequenceArr.push({
          name: matchValue.trim(),
          value: informationFramework?.assessmentSynopsis[lastIndexValue - 1],
          originalValue: currentValue
        });
      } else if (matchValue.trim() === 'assessment manuscript') {
        sequenceArr.push({
          name: matchValue.trim(),
          value: informationFramework?.assessmentManuscript[lastIndexValue - 1],
          originalValue: currentValue
        });
      } else if (matchValue.trim() === 'assessment section') {
        let assessmentSectionFramework = informationFramework?.assessmentSectionFramework || [];
        let sectionObj = assessmentSectionFramework[lastIndexValue - 1];
        if (sectionObj?.assessmentSectionAdministrationSequence.length > 0) {
          for (let j = 0; j < sectionObj?.assessmentSectionAdministrationSequence.length; j++) {
            let currentSectionSequenceValue =
              sectionObj?.assessmentSectionAdministrationSequence[j];
            let lastIndexValue = parseInt(
              currentSectionSequenceValue.substring(currentSectionSequenceValue.length - 1)
            );
            let matchValue = currentSectionSequenceValue;
            matchValue = matchValue.substring(0, matchValue.length - 1);
            if (matchValue.trim() === 'section communiqué') {
              sequenceArr.push({
                name: matchValue.trim(),
                value: sectionObj?.assessmentSectionCommunique[lastIndexValue - 1],
                originalValue: currentSectionSequenceValue
              });
            } else if (matchValue.trim() === 'section synopsis') {
              sequenceArr.push({
                name: matchValue.trim(),
                value: sectionObj?.assessmentSectionSynopsis[lastIndexValue - 1],
                originalValue: currentSectionSequenceValue
              });
            } else if (matchValue.trim() === 'section manuscript') {
              sequenceArr.push({
                name: matchValue.trim(),
                value: sectionObj?.assessmentSectionManuscript[lastIndexValue - 1],
                originalValue: currentSectionSequenceValue
              });
            } else if (currentSectionSequenceValue.trim() === 'item distinct') {
              sequenceArr.push({
                name: currentSectionSequenceValue.trim(),
                value: sectionObj?.assessmentSectionItemDistinct,
                originalValue: currentValue
              });
            }
          }
        } else {
          sequenceArr.push({
            name: matchValue.trim(),
            value: sectionObj?.assessmentSectionItemDistinct,
            originalValue: currentValue
          });
        }
      } else if (currentValue.trim() === 'item trial') {
        sequenceArr.push({
          name: currentValue.trim(),
          value: informationFramework?.assessmentItemTrial,
          originalValue: currentValue.trim()
        });
      }
    }
    // console.log(sequenceArr);
    dispatch({
      type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
      payload: { stateName: 'assessmentsequenceObject', value: sequenceArr }
    });
  }
};
export const makeAssesseeAssignmentRequestObj = (
  selectedTagValue,
  selectedAssociateInfo,
  secondaryOptionCheckValue = '',
  assignmentCreateType = ''
) => {
  let statusArr = [
    'STARTED',
    'UNSTARTED',
    'ABORTED',
    'FINISHED',
    'UNFINISHED',
    'SUSPENDED',
    'TERMINATED',
    'ADMINISTERED'
  ];
  if (secondaryOptionCheckValue === 'active') statusArr = ['STARTED', 'UNSTARTED', 'ADMINISTERED'];
  if (secondaryOptionCheckValue === 'inactive')
    statusArr = ['ABORTED', 'FINISHED', 'UNFINISHED', 'SUSPENDED', 'TERMINATED', 'ADMINISTERED'];
  if (secondaryOptionCheckValue === 'archived') statusArr = ['ARCHIVED', 'ADMINISTERED'];
  if (
    secondaryOptionCheckValue === 'started' ||
    secondaryOptionCheckValue === 'unstarted' ||
    secondaryOptionCheckValue === 'aborted' ||
    secondaryOptionCheckValue === 'unfinished' ||
    secondaryOptionCheckValue === 'finished' ||
    secondaryOptionCheckValue === 'terminated' ||
    secondaryOptionCheckValue === 'suspended'
  )
    statusArr = [`${secondaryOptionCheckValue.toUpperCase()}`, 'ADMINISTERED'];
  let reqBody = {
    module: 'Assessee-Assignment',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedTagValue,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filterTypeName: assignmentCreateType,
      assesseeAssignmentStatus: statusArr,
      countPage: -1,
      numberPage: 0,
      filter: true,
      orderBy: {
        columnName: 'assesseeAssignmentName',
        order: 'asc'
      },
      searchCondition: 'AND',
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'assesseeAssignmentStatus',
              conditionValue: {
                condition: 'in',
                value: {
                  in: [
                    'STARTED',
                    'UNSTARTED',
                    'ABORTED',
                    'FINISHED',
                    'UNFINISHED',
                    'SUSPENDED',
                    'TERMINATED',
                    'ARCHIVED'
                  ]
                }
              }
            }
          ]
        }
        // assignmentCreateType === "iGuru_Assignment" && {
        //   dataType: 'string',
        //   conditionColumn: 'informationAllocation.assignmentType.assignmentTypeSecondary',
        //   conditionValue: {
        //     condition: 'nin',
        //     value: {
        //       in: [
        //         process.env.REACT_APP_IGURU_ANALYTICS_CAREER,
        //         process.env.REACT_APP_IGURU_ANALYTICS_EDUCATION,
        //         process.env.REACT_APP_IGURU_ANALYTICS_OCCUPATION,
        //         process.env.REACT_APP_IGURU_ANALYTICS_PULSE,
        //         process.env.REACT_APP_IGURU_MINE_CAREER,
        //         process.env.REACT_APP_IGURU_MINE_EDUCATION,
        //         process.env.REACT_APP_IGURU_MINE_OCCUPATION,
        //         process.env.REACT_APP_IGURU_MINE_PULSE,
        //         process.env.REACT_APP_IGURU_MARKETPLACE_CAREER_ASSIGNMENTS,
        //         process.env.REACT_APP_IGURU_MARKETPLACE_EDUCATION_ASSIGNMENTS,
        //         process.env.REACT_APP_IGURU_MARKETPLACE_OCCUPATION_ASSIGNMENTS,
        //         process.env.REACT_APP_IGURU_MARKETPLACE_PULSE_ASSIGNMENTS
        //       ]
        //     }
        //   }
        // }
      ]
    }
  };
  return reqBody;
};

export const getAssesseeRelatedAssigmentReqObj = (
  selectedAssociateInfo,
  assesseeId,
  middlePaneHeaderBadgeOne,
  countPage,
  scanString,
  middlePaneHeaderBadgeTwo = '',
  assignmentCreateType = ''
) => {

  console.log(assesseeId, middlePaneHeaderBadgeOne, countPage, scanString);
  console.log('****');

  let statusArr = [middlePaneHeaderBadgeOne?.toUpperCase(), 'ADMINISTERED'];
  console.log('------', middlePaneHeaderBadgeOne.toUpperCase() === 'ACTIVE');

  if (middlePaneHeaderBadgeOne.toUpperCase() === 'ACTIVE') {
    statusArr = ['STARTED', 'UNSTARTED', 'ADMINISTERED'];
  }
  if (middlePaneHeaderBadgeOne.toUpperCase() === 'INACTIVE') {
    statusArr = ['ABORTED', 'FINISHED', 'UNFINISHED', 'SUSPENDED', 'TERMINATED', 'ADMINISTERED'];
  }
  if (middlePaneHeaderBadgeOne.toUpperCase() === 'ALL') {
    statusArr = [
      'STARTED',
      'UNSTARTED',
      'ABORTED',
      'FINISHED',
      'UNFINISHED',
      'SUSPENDED',
      'TERMINATED',
      'ADMINISTERED'
    ];
  }
  if (middlePaneHeaderBadgeOne.toUpperCase() === 'ARCHIVED') {
    statusArr = ['ARCHIVED', 'ADMINISTERED'];
  }
  if (middlePaneHeaderBadgeTwo) {
    statusArr = [middlePaneHeaderBadgeTwo?.toUpperCase(), 'ADMINISTERED'];
  }
  let reqBody = {
    module: 'Assessee-Assignment',
    action: 'Review-List',
    requestObject: {
      assesseeId: assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filterTypeName: assignmentCreateType === "iGuru_Assignment" ? "" : assignmentCreateType,
      assesseeAssignmentStatus: statusArr,
      countPage: countPage,
      numberPage: 0,
      filter: true,
      orderBy: {
        columnName: 'assesseeAssignmentName',
        order: 'asc'
      },
      searchCondition: 'AND',
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'assesseeAssignmentStatus',
              conditionValue: {
                condition: 'in',
                value: {
                  in: [
                    'STARTED',
                    'UNSTARTED',
                    'ABORTED',
                    'FINISHED',
                    'UNFINISHED',
                    'SUSPENDED',
                    'TERMINATED',
                    'ARCHIVED'
                  ]
                }
              }
            },

          ]
        },
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'assesseeAssignmentName',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: scanString
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'assesseeAssignmentDescription',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: scanString
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'assesseeAssignmentId',
              conditionValue: {
                condition: 'ct',
                value: {
                  from: scanString
                }
              }
            }
          ]
        }
      ]
    }
  };
  return reqBody;
};
export const closeRelatedList = (
  dispatch,
  createMode,
  responseObject,
  middlePaneHeaderDuplicate,
  middlePaneHeaderDuplicateBadgeOne,
  middlePaneHeaderDuplicateBadgeTwo,
  middlePaneHeaderDuplicateBadgeThree,
  middlePaneHeaderDuplicateBadgeFour,
  typeOfMiddlePaneList,
  scanCount,
  filterMode,
  duplicateScanCount,
  middlePaneHeaderDuplicateOne = '',
  middlePaneHeaderDuplicateOneBadgeOne = '',
  middlePaneHeaderDuplicateOneBadgeTwo = '',
  middlePaneHeaderDuplicateOneBadgeThree = '',
  middlePaneHeaderDuplicateOneBadgeFour = '',
  duplicateScanCountOne
) => {
  // debugger
  if (createMode === '') {
    dispatch({
      type: SET_MIDDLEPANE_STATE,
      payload: {
        middlePaneHeader: middlePaneHeaderDuplicate,
        middlePaneHeaderBadgeOne: middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderBadgeTwo: middlePaneHeaderDuplicateBadgeTwo,
        middlePaneHeaderBadgeThree: middlePaneHeaderDuplicateBadgeThree,
        middlePaneHeaderBadgeFour: middlePaneHeaderDuplicateBadgeFour,
        middlePaneHeaderDuplicate: middlePaneHeaderDuplicate,
        middlePaneHeaderDuplicateBadgeOne: middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderDuplicateBadgeTwo: middlePaneHeaderDuplicateBadgeTwo,
        middlePaneHeaderDuplicateBadgeThree: middlePaneHeaderDuplicateBadgeThree,
        middlePaneHeaderDuplicateBadgeFour: middlePaneHeaderDuplicateBadgeFour,
        middlePaneHeaderDuplicateOne: middlePaneHeaderDuplicateOne,
        middlePaneHeaderDuplicateOneBadgeOne: middlePaneHeaderDuplicateOneBadgeOne,
        middlePaneHeaderDuplicateOneBadgeTwo: middlePaneHeaderDuplicateOneBadgeTwo,
        middlePaneHeaderDuplicateOneBadgeThree: middlePaneHeaderDuplicateOneBadgeThree,
        middlePaneHeaderDuplicateOneBadgeFour: middlePaneHeaderDuplicateOneBadgeFour,
        typeOfMiddlePaneList: typeOfMiddlePaneList,
        scanCount: scanCount,
        duplicateScanCount: duplicateScanCount,
        duplicateScanCountOne: duplicateScanCountOne,
        showMiddlePaneState: true
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'scanString', value: '' }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'isSelectActive', value: '' }
    });
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: { stateName: 'selectionValue', value: '' }
    });
    let mode =
      filterMode +
      (middlePaneHeaderDuplicateBadgeTwo === 'distinct'
        ? middlePaneHeaderDuplicateBadgeThree
        : middlePaneHeaderDuplicateBadgeTwo);
    dispatch({
      type: FILTERMODE,
      payload: {
        FilterMode: mode
      }
    });
    dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });

    // dispatch({
    //   type: SET_DISPLAY_TWO_SINGLE_STATE,
    //   payload: {
    //     stateName: 'relatedReviewListDistinctData',
    //     value: []
    //   }
    // });
    // dispatch({
    //   type: SET_DISPLAY_TWO_SINGLE_STATE,
    //   payload: {
    //     stateName: 'relatedReviewListDistinctDataDuplicate',
    //     value: []
    //   }
    // });
    if (!responseObject) dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  } else {
    dispatch({
      type: SET_MIDDLEPANE_STATE,
      payload: {
        middlePaneHeader: '',
        middlePaneHeaderBadgeOne: '',
        middlePaneHeaderBadgeTwo: '',
        middlePaneHeaderBadgeThree: '',
        middlePaneHeaderBadgeFour: '',
        typeOfMiddlePaneList: ''
      }
    });
    dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });

    // dispatch({
    //   type: SET_DISPLAY_TWO_SINGLE_STATE,
    //   payload: {
    //     stateName: 'relatedReviewListDistinctData',
    //     value: []
    //   }
    // });
    // dispatch({
    //   type: SET_DISPLAY_TWO_SINGLE_STATE,
    //   payload: {
    //     stateName: 'relatedReviewListDistinctDataDuplicate',
    //     value: []
    //   }
    // });
  }
  if (typeOfMiddlePaneList === "") {
    dispatch({ type: SET_DISPLAY_TWO_SINGLE_STATE, payload: { stateName: "showMiddlePaneState", value: false } })
  }
};
export const getUpdatedTypeOfMiddlePaneList = (typeOfMiddlePaneList) => {
  let middlepaneliststr = typeOfMiddlePaneList;
  //for groups
  if (typeOfMiddlePaneList === 'assesseesGroupAssesseeReviewList')
    middlepaneliststr = 'assesseesGroupDistinctReviewList';
  if (typeOfMiddlePaneList === 'assessmentGroupAssessmentReviewList')
    middlepaneliststr = 'assessmentsGroupDistinctReviewList';
  if (typeOfMiddlePaneList === 'assignmentGroupAssignmentReviewList')
    middlepaneliststr = 'assignmentsGroupDistinctReviewList';
  if (typeOfMiddlePaneList === 'associatesGroupAssociateReviewList')
    middlepaneliststr = 'associatesGroupDistinctReviewList';
  if (typeOfMiddlePaneList === 'cultureProfileGroupCultureProfileReviewList')
    middlepaneliststr = 'cultureProfilesGroupDistinctReviewList';
  if (typeOfMiddlePaneList === 'jobProfileGroupJobProfileReviewList')
    middlepaneliststr = 'jobProfilesGroupDistinctReviewList';
  if (typeOfMiddlePaneList === 'itemGroupItemReviewList')
    middlepaneliststr = 'itemsGroupDistinctReviewList';
  //for type
  if (typeOfMiddlePaneList === 'assesseesTypeAssesseeReviewList')
    middlepaneliststr = 'assesseesTypeDistinctReviewList';
  if (typeOfMiddlePaneList === 'assessmentTypeAssessmentReviewList')
    middlepaneliststr = 'assessmentsTypeDistinctReviewList';
  if (typeOfMiddlePaneList === 'assignmentTypeAssignmentReviewList')
    middlepaneliststr = 'assignmentsTypeDistinctReviewList';
  if (typeOfMiddlePaneList === 'associatesTypeAssociateReviewList')
    middlepaneliststr = 'associatesTypeDistinctReviewList';
  if (typeOfMiddlePaneList === 'cultureProfileTypeCultureProfileReviewList')
    middlepaneliststr = 'cultureProfilesTypeDistinctReviewList';
  if (typeOfMiddlePaneList === 'jobProfileTypeJobProfileReviewList')
    middlepaneliststr = 'jobProfilesTypeDistinctReviewList';
  if (typeOfMiddlePaneList === 'itemTypeItemReviewList')
    middlepaneliststr = 'itemsTypeDistinctReviewList';
  //for node
  if (
    typeOfMiddlePaneList === 'assesseesNodeAssesseeReviewList' ||
    typeOfMiddlePaneList === 'assessmentNodeAssessmentReviewList' ||
    typeOfMiddlePaneList === 'assignmentNodeAssignmentReviewList' ||
    typeOfMiddlePaneList === 'associatesNodeAssociateReviewList' ||
    typeOfMiddlePaneList === 'cultureProfileNodeCultureProfileReviewList' ||
    typeOfMiddlePaneList === 'jobProfileNodeJobProfileReviewList' ||
    typeOfMiddlePaneList === 'itemNodeItemReviewList'
  )
    middlepaneliststr = 'associateNodeDistinctReviewList';
  //for role
  if (typeOfMiddlePaneList === 'assesseesRoleAssesseeReviewList')
    middlepaneliststr = 'assesseeRoleDistinctReviewList';
  if (typeOfMiddlePaneList === 'associatesRoleAssociateReviewList')
    middlepaneliststr = 'associateRoleDistinctReviewList';
  //for distinct
  if (
    typeOfMiddlePaneList === 'assignmentDistinctAssesseeReviewList' ||
    typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList' ||
    typeOfMiddlePaneList === 'assignmentDistinctAssessmentGroupReviewList' ||
    typeOfMiddlePaneList === 'assignmentDistinctAssesseeGroupReviewList' ||
    typeOfMiddlePaneList === 'assignmentDistinctCultureProfileReviewList' ||
    typeOfMiddlePaneList === 'assignmentDistinctJobProfileReviewList'
  )
    middlepaneliststr = 'assignmentDistinctReviewList';
  return middlepaneliststr;
};
export const getRelatedDataAPICall = (selectedAssociateInfo, dispatch, selectedTagValue) => {
  getAssesseeNodeAssesseeDistinctApiCall(
    selectedAssociateInfo,
    '',
    -1,
    dispatch,
    'distinct',
    selectedTagValue,
    '',
    false,
    'assessees',
    false
  );
  getNodeRelatedAssessmentsDistinctApiCall(
    selectedAssociateInfo,
    '',
    -1,
    dispatch,
    'distinct',
    selectedTagValue,
    '',
    false,
    'assessments',
    false
  );
  getNodeRelatedAssignmentsDistinctApiCall(
    selectedAssociateInfo,
    '',
    -1,
    dispatch,
    'distinct',
    selectedTagValue,
    '',
    false,
    'assignments',
    false
  );
  getNodeRelatedAssociateDistinctApiCall(
    selectedAssociateInfo,
    '',
    -1,
    dispatch,
    'distinct',
    selectedTagValue,
    '',
    false,
    'associates',
    false
  );
  getCultureProfileNodeCultureProfileApiCall(
    selectedAssociateInfo,
    '',
    -1,
    dispatch,
    'distinct',
    selectedTagValue,
    '',
    false,
    'culture-profiles',
    false
  );
  getNodeRelatedItemsDistinctApiCall(
    selectedAssociateInfo,
    '',
    -1,
    dispatch,
    'distinct',
    selectedTagValue,
    '',
    false,
    'items',
    false
  );
  getJobProfileNodeJobProfileApiCall(
    selectedAssociateInfo,
    '',
    -1,
    dispatch,
    'distinct',
    selectedTagValue,
    '',
    false,
    'job-profiles',
    false
  );
};

export const getAssociateDistinctListApiCall = (
  selectedAssociateInfo,
  secondaryOptionCheckValue,
  dispatch,
  countPage,
  shareCall
) => {
  // let requestObect = makeAssociateSahreDistinctListObject(
  //   selectedAssociateInfo,
  //   'active',
  //   0,
  //   countPage
  // );
  let requestObect = {
    module: 'Associate-Distinct',
    action: 'associateSharedReviewList',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      // countPage: countPage,
      // numberPage: 0,
      filter: true,
      search: [
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'String',
              conditionColumn: 'id',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: selectedAssociateInfo?.associate?.informationEngagement.associateTag
                    .associateTagPrimary
                }
              }
            }
          ]
        }
      ]
    }
  };
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: {
      stateName: 'tempStr',
      value: shareCall === 'shareApiCall' ? 'Share' : 'Unshare'
    }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({
    type: SET_DISPLAY_TWO_SINGLE_STATE,
    payload: { stateName: 'typeOfMiddlePaneList', value: '' }
  });
  dispatch({
    type: FILTERMODE,
    payload: { FilterMode: 'AssociateDistinctShareReviewList' }
  });
  // dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({
    type: ASSOCIATE_REVIEW_DISTINCT_SHARE_SAGA,
    payload: {
      request: requestObect,
      BadgeOne: 'distinct',
      BadgeTwo: '',
      BadgeThree: '',
      isSelectActive: 'multiple'
    }
  });
  // console.log('req-----', reqBody);
};
export const getDistinctShareApicall = (
  //d
  selectedAssociateInfo,
  dispatch,
  module,
  action,
  shareStr,
  shareVal,
  selectedTagValue,
  selectedTagsArray,
  allocatedTagsArray,
  shareFeePopupValue
) => {
  let SharedArr = [];
  allocatedTagsArray.length > 0 &&
    allocatedTagsArray.map((item, index) => {
      SharedArr.push({
        [shareStr + 'Id']: item
      });
    });
  console.log('SharedArr----', SharedArr);
  let reqBody = {
    action: action,
    module: module,
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      [shareStr + 'Share']: 'ASSOCIATE_DISTINCT',
      [shareStr + 'ShareInformation']: selectedTagsArray,
      [shareStr + 'Shared']:
        SharedArr.length > 0
          ? SharedArr
          : [
            {
              [shareStr + 'Id']: selectedTagValue
            }
          ]
    }
  };
  let keyString = `${shareStr}ShareFee`;
  shareFeePopupValue >= 0 &&
    shareStr.toLowerCase().includes('group') &&
    (reqBody.requestObject[keyString] = shareFeePopupValue);
  dispatch({ type: LOADER_START });
  dispatch({
    type: ASSOCIATE_DISTINCT_SHARE_SAGA,
    payload: { request: reqBody, shareValue: shareVal }
  });
};
export const getDistinctShareDistinctApicall = (
  //td
  selectedAssociateInfo,
  dispatch,
  module,
  action,
  shareStr,
  shareVal,
  selectedTagValue,
  selectedTagsArray,
  allocatedTagsArray,
  shareFeePopupValue
) => {
  let SharedArr = [];
  let str = shareStr.split('D')[0];
  allocatedTagsArray.length > 0 &&
    allocatedTagsArray.map((item, index) => {
      SharedArr.push({
        [str + 'Id']: item
      });
    });
  let reqBody = {
    action: action,
    module: module,
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      [str + 'DistinctShare']: 'ASSOCIATE_DISTINCT',
      [str + 'DistinctShareInformation']: selectedTagsArray,
      [str + 'DistinctShared']:
        SharedArr.length > 0
          ? SharedArr
          : [
            {
              [str + 'Id']: selectedTagValue
            }
          ]
    }
  };

  let keyString = `${str}ShareFee`;
  shareFeePopupValue >= 0 && (reqBody.requestObject[keyString] = shareFeePopupValue);

  dispatch({ type: LOADER_START });
  dispatch({
    type: SHARE_DISTINCT_SAGA,
    payload: { request: reqBody, shareValue: shareVal }
  });
};
// export function distinctGroupSharePopupOpen(
//   dispatch,
//   selectedTagValue,
//   popupHeaderOne,
//   popupHeaderOneBadgeOne
// ) {
//   console.log('selectedTagValue', selectedTagValue);
//   dispatch({
//     type: SET_POPUP_STATE,
//     payload: {
//       popupHeaderOne,
//       popupHeaderOneBadgeOne,
//       popupOpenType: 'primary',
//       selectedTagValue,
//       popupContentArrValue: SHARE_NEW_POPUP,
//       secondaryOptionCheckValue: 'associate',
//       tertiaryOptionCheckValue: 'primary',
//       forthOptionCheckValue: 'ascendant'
//     }
//   });
//   dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
// }
export function MySort(alphabet) {
  return function (a, b) {
    var index_a = alphabet.indexOf(a[0]),
      index_b = alphabet.indexOf(b[0]);

    if (index_a === index_b) {
      // same first character, sort regular
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      }
      return 0;
    } else {
      return index_a - index_b;
    }
  };
}

export const makeAssesseeMarketplaceDistinctReviewListRequestObject = (
  selectedAssociateInfo,
  filterKey,
  numberPage,
  countPage,
  assignmentCreateType,
  searchStr = ''
) => {
  assignmentCreateType = '';
  let searchArr = [];
  console.debug({ filterKey, searchStr });
  let searchObj = {
    dataType: 'string',
    conditionColumn: 'informationEngagement.assesseeStatus',
    conditionValue: {
      condition: 'eq',
      value: {
        from: filterKey.toUpperCase()
      }
    }
  };
  if (filterKey === 'activeinactive') {
    searchObj = {
      dataType: 'string',
      conditionColumn: 'informationEngagement.assesseeStatus',
      conditionValue: {
        condition: 'in',
        value: {
          in: ['UNAPPROVED', 'CONFIRMED', 'UNCONFIRMED']
        }
      }
    };
  }
  if (filterKey === 'all') {
    searchObj = {
      dataType: 'string',
      conditionColumn: 'informationEngagement.assesseeStatus',
      conditionValue: {
        condition: 'in',
        value: {
          in: ['CONFIRMED', 'DISAPPROVED', 'SUSPENDED', 'TERMINATED', 'UNAPPROVED', 'UNCONFIRMED']
        }
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      dataType: 'string',
      conditionColumn: 'informationEngagement.assesseeStatus',
      conditionValue: {
        condition: 'in',
        value: {
          in: [
            'CONFIRMED',
            'DISAPPROVED',
            'SUSPENDED',
            'TERMINATED',
            'UNAPPROVED',
            'UNCONFIRMED',
            'ARCHIVED'
          ]
        }
      }
    };
  }
  // searchArr = [searchObj];
  searchArr.push({ condition: 'AND', searchBy: [searchObj] });
  if (searchStr !== '') {
    searchArr.push({
      condition: 'or',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.assesseeNameFirst',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.assesseeNameOther',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.assesseeNameLast',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.assesseeAlias',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationEngagement.assesseeTag.assesseeTagPrimary',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationContact.assesseeAddressEmailPrimary.assesseeAddressEmail',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationContact.assesseeAddressEmailSecondary.assesseeAddressEmail',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn:
            'informationContact.assesseeTelephoneMobilePrimary.assesseeTelephoneNumber',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn:
            'informationContact.assesseeTelephoneMobileSecondary.assesseeTelephoneNumber',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        }
      ]
    });
  }

  let requestObject = {
    assesseeId: selectedAssociateInfo?.assesseeId,
    associateId:
      selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
    countPage: countPage,
    numberPage: numberPage,
    filter: 'true',
    filterTypeName: assignmentCreateType || '',
    orderBy: {
      columnName:
        'informationBasic.assesseeNameFirst,informationBasic.assesseeNameOther,informationBasic.assesseeNameLast,informationBasic.assesseeAlias',
      order: 'asc'
    },
    searchCondition: 'AND',
    // search: [
    //   {
    //     condition: 'and',
    //     searchBy: searchArr
    //   }
    // ]
    search: searchArr
  };

  return {
    module: 'Assessee-Distinct',
    action: 'Shared-Assessee-List',
    requestObject
  };
};

export const makeAssesseeMarketplaceGroupReviewListRequestObject = (
  selectedAssociateInfo,
  filterKey,
  numberPage,
  countPage,
  assignmentCreateType = '',
  groupViewState = 'hierarchy',
  searchStr = ''
) => {
  assignmentCreateType = '';
  let searchArr = [];
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (searchStr !== '') {
    searchArr.push({
      condition: 'or',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.assesseeGroupName',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.assesseeGroupDescription',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        }
      ]
    });
  }
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  let requestObj = {};
  if (groupViewState === 'hierarchy') {
    requestObj = {
      module: 'Assessee-Group',
      action: 'Review-List',
      actionFilter: 'Shared-Hierarchy',

      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        filterTypeName: assignmentCreateType === 'iGuru_Assignment' ? '' : assignmentCreateType,
        status: filterKey.toUpperCase()
      }
    };
  } else {
    if (filterKey === 'bespoke' || filterKey === 'generic') {
      requestObj = {
        module: 'Assessee-Group',
        action: 'Review-List',
        actionFilter: 'AssesseeGroup-Shared-List',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          filterTypeName: assignmentCreateType === 'iGuru_Assignment' ? '' : assignmentCreateType,
          filter: 'true',
          orderBy: {
            columnName:
              'informationBasic.assesseeGroupName,informationBasic.assesseeGroupDescription',
            order: 'asc'
          },
          numberPage: numberPage,
          countPage: countPage,
          search: [
            {
              condition: 'and',
              searchBy: [
                {
                  dataType: 'string',
                  conditionColumn: 'informationEngagement.assesseeGroupStatus',
                  conditionValue: {
                    condition: 'eq',
                    value: {
                      from: 'ACTIVE'
                    }
                  }
                },
                {
                  dataType: 'string',
                  conditionColumn:
                    'informationSetup.assesseeGroupClassification.assesseeGroupClassificationPrimary',
                  conditionValue: {
                    condition: 'in',
                    value: {
                      in: [filterKey === 'bespoke' ? 'Bespoke' : 'Generic']
                    }
                  }
                }
              ]
            },
            ...searchArr
          ]
        }
      };
    } else {
      requestObj = {
        module: 'Assessee-Group',
        action: 'Review-List',
        actionFilter: 'AssesseeGroup-Shared-List',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          filterTypeName: assignmentCreateType === 'iGuru_Assignment' ? '' : assignmentCreateType,
          filter: 'true',
          orderBy: {
            columnName:
              'informationBasic.assesseeGroupName,informationBasic.assesseeGroupDescription',
            order: 'asc'
          },
          numberPage: numberPage,
          countPage: countPage,
          search: [
            {
              condition: 'and',
              searchBy: [
                {
                  dataType: 'string',
                  conditionColumn: 'informationEngagement.assesseeGroupStatus',
                  conditionValue: searchObj
                }
              ]
            },
            ...searchArr
          ]
        }
      };
    }
  }
  return requestObj;
};

export const makeAssesseeMarketplaceNodeReviewListRequestObject = (
  selectedAssociateInfo,
  nodeId,
  filterKey,
  numberPage,
  countPage,
  searchStr = ''
) => {
  console.debug({ reqObj: searchStr });
  let searchArr = [];
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['CONFIRMED', 'DISAPPROVED', 'SUSPENDED', 'TERMINATED', 'UNAPPROVED', 'UNCONFIRMED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: [
          'CONFIRMED',
          'DISAPPROVED',
          'SUSPENDED',
          'TERMINATED',
          'UNAPPROVED',
          'UNCONFIRMED',
          'ARCHIVED'
        ]
      }
    };
  }
  if (searchStr !== '') {
    searchArr.push({
      condition: 'or',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.assesseeNameFirst',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.assesseeNameOther',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.assesseeNameLast',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.assesseeAlias',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationEngagement.assesseeTag.assesseeTagPrimary',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationContact.assesseeAddressEmailPrimary.assesseeAddressEmail',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationContact.assesseeAddressEmailSecondary.assesseeAddressEmail',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn:
            'informationContact.assesseeTelephoneMobilePrimary.assesseeTelephoneNumber',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn:
            'informationContact.assesseeTelephoneMobileSecondary.assesseeTelephoneNumber',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        }
      ]
    });
  }
  return {
    module: 'Assessee-Distinct',
    action: 'Review-List',
    actionFilter: 'Associate-Node-Shared-Assessee',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      nodeId: nodeId,
      filter: 'true',
      searchCondition: 'AND',
      orderBy: {
        columnName:
          'informationBasic.assesseeNameFirst,informationBasic.assesseeNameOther,informationBasic.assesseeNameLast,informationBasic.assesseeAlias',
        order: 'asc'
      },
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.assesseeNode.assesseeNodePrimary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: nodeId
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.assesseeNode.assesseeNodeSecondary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: nodeId
                }
              }
            }
          ]
        },
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assesseeStatus',
              conditionValue: searchObj
            }
          ]
        },
        ...searchArr
      ]
    }
  };
};

export const makeAssesseeMarketplaceRoleReviewListRequestObject = (
  selectedAssociateInfo,
  roleId,
  filterKey,
  numberPage,
  countPage,
  searchStr = ''
) => {
  let searchArr = [];
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'activeinactive') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['CONFIRMED', 'UNCONFIRMED']
      }
    };
  }
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['CONFIRMED', 'DISAPPROVED', 'SUSPENDED', 'TERMINATED', 'UNAPPROVED', 'UNCONFIRMED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: [
          'CONFIRMED',
          'DISAPPROVED',
          'SUSPENDED',
          'TERMINATED',
          'UNAPPROVED',
          'UNCONFIRMED',
          'ARCHIVED'
        ]
      }
    };
  }
  if (searchStr !== '') {
    searchArr.push({
      condition: 'or',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.assesseeNameFirst',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.assesseeNameOther',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.assesseeNameLast',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.assesseeAlias',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationEngagement.assesseeTag.assesseeTagPrimary',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationContact.assesseeAddressEmailPrimary.assesseeAddressEmail',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationContact.assesseeAddressEmailSecondary.assesseeAddressEmail',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn:
            'informationContact.assesseeTelephoneMobilePrimary.assesseeTelephoneNumber',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn:
            'informationContact.assesseeTelephoneMobileSecondary.assesseeTelephoneNumber',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        }
      ]
    });
  }
  return {
    module: 'Assessee-Role',
    action: 'Review-List',
    actionFilter: 'Shared-Assessee-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      roleId: roleId,
      orderBy: {
        columnName:
          'informationBasic.assesseeNameFirst,informationBasic.assesseeNameOther,informationBasic.assesseeNameLast,informationBasic.assesseeAlias',
        order: 'asc'
      },
      filter: 'true',
      searchCondition: 'AND',
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.assesseeRole.assesseeRolePrimary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: roleId
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.assesseeRole.assesseeRoleSecondary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: roleId
                }
              }
            }
          ]
        },
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assesseeStatus',
              conditionValue: searchObj
            }
          ]
        },
        ...searchArr
      ]
    }
  };
};
export const makeAssesseeMarketplaceTypeReviewListRequestObject = (
  selectedAssociateInfo,
  typeId,
  filterKey,
  numberPage,
  countPage,
  searchStr = ''
) => {
  let searchArr = [];
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: [
          'CONFIRMED',
          'DISAPPROVED',
          'SUSPENDED',
          'TERMINATED',
          'UNAPPROVED',
          'UNCONFIRMED',
          'ARCHIVED'
        ]
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: [
          'CONFIRMED',
          'DISAPPROVED',
          'SUSPENDED',
          'TERMINATED',
          'UNAPPROVED',
          'UNCONFIRMED',
          'ARCHIVED',
          'ARCHIVED'
        ]
      }
    };
  }

  if (searchStr !== '') {
    searchArr.push({
      condition: 'or',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.assesseeNameFirst',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.assesseeNameOther',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.assesseeNameLast',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.assesseeAlias',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationContact.assesseeAddressEmailPrimary.assesseeAddressEmail',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationContact.assesseeAddressEmailSecondary.assesseeAddressEmail',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn:
            'informationContact.assesseeTelephoneMobilePrimary.assesseeTelephoneNumber',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn:
            'informationContact.assesseeTelephoneMobileSecondary.assesseeTelephoneNumber',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: '_id',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        }
      ]
    });
  }

  return {
    module: 'Assessee-Type',
    action: 'Review-List',
    actionFilter: 'Shared-Type-Assessee',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      typeId: typeId,
      filter: 'true',
      orderBy: {
        columnName:
          'informationBasic.assesseeNameFirst,informationBasic.assesseeNameOther,informationBasic.assesseeNameLast,informationBasic.assesseeAlias',
        order: 'asc'
      },
      searchCondition: 'AND',
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.assesseeType.assesseeTypePrimary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: typeId
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.assesseeType.assesseeTypeSecondary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: typeId
                }
              }
            }
          ]
        },
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assesseeStatus',
              conditionValue: searchObj
            }
          ]
        },
        ...searchArr
      ]
    }
  };
};

export const makeMarketplaceAssessmentDistinctReviewListRequestObject = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  assignmentCreateType = '',
  searchStr = ''
) => {
  assignmentCreateType = '';
  let searchArr = [];
  let searchBy = [];
  let tempSearchObj = {
    dataType: 'string',
    conditionColumn: 'informationEngagement.assessmentStatus',
    conditionValue: searchObj
  };
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'activeinactive') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED']
      }
    };
  }
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED', 'ARCHIVED']
      }
    };
  }
  if (searchStr !== '') {
    searchArr.push({
      condition: 'or',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.assessmentName',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.assessmentDescription',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationEngagement.assessmentTag.assessmentTagPrimary',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        }
      ]
    });
  }
  tempSearchObj.conditionValue = searchObj;

  searchBy.push(tempSearchObj);

  if (assignmentCreateType !== '') {
    let id =
      process.env[`REACT_APP_${assignmentCreateType.replace(' ', '_').toUpperCase()}_ASSESSMENTS`];
    if (id) {
      let x = {
        dataType: 'string',
        conditionColumn: 'informationAllocation.assessmentType.assessmentTypeSecondary',
        conditionValue: {
          condition: 'in',
          value: {
            in: [
              `${process.env[
              `REACT_APP_${assignmentCreateType.replace(' ', '_').toUpperCase()}_ASSESSMENTS`
              ]
              }`
            ]
          }
        }
      };
      searchBy.push(x);
    }
  }
  let requestObj = {
    module: 'Assessment-Distinct',
    action: 'Review-List',
    actionFilter: 'Assessment-Shared-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.assessmentName,informationBasic.assessmentDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      search: [
        {
          condition: 'and',
          searchBy: searchBy
        },
        ...searchArr
      ]
    }
  };
  return requestObj;
};
export const makeMarketplaceAssessmentGroupReviewListRequestObject = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  groupViewState,
  assignmentCreateType = '',
  searchStr = ''
) => {
  assignmentCreateType = '';
  let searchArr = [];
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  if (searchStr !== '') {
    searchArr.push({
      condition: 'or',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.assessmentGroupName',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.assessmentGroupDescription',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        }
      ]
    });
  }
  let requestObj = {};
  if (groupViewState === 'hierarchy') {
    requestObj = {
      module: 'Assessment-Group',
      action: 'Review-List',
      actionFilter: 'Shared-Hierarchy',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        filterTypeName: '',
        status: filterKey.toUpperCase()
      }
    };
  } else {
    if (filterKey === 'bespoke' || filterKey === 'generic') {
      requestObj = {
        module: 'Assessment-Group',
        action: 'Review-List',
        actionFilter: 'Assessment-GroupShared-List',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          filterTypeName: '',
          filter: 'true',
          orderBy: {
            columnName:
              'informationBasic.assessmentGroupName,informationBasic.assessmentGroupDescription',
            order: 'asc'
          },
          numberPage: numberPage,
          countPage: countPage,
          search: [
            {
              condition: 'and',
              searchBy: [
                {
                  dataType: 'string',
                  conditionColumn: 'informationEngagement.assessmentGroupStatus',
                  conditionValue: {
                    condition: 'eq',
                    value: {
                      from: 'ACTIVE'
                    }
                  }
                },
                {
                  dataType: 'string',
                  conditionColumn:
                    'informationSetup.assessmentGroupClassification.assessmentGroupClassificationPrimary',
                  conditionValue: {
                    condition: 'eq',
                    value: {
                      from: filterKey === 'bespoke' ? 'Bespoke' : 'Generic'
                    }
                  }
                }
              ]
            },
            ...searchArr
          ]
        }
      };
    } else {
      requestObj = {
        module: 'Assessment-Group',
        action: 'Review-List',
        actionFilter: 'Assessment-GroupShared-List',
        requestObject: {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          filterTypeName: '',
          filter: 'true',
          orderBy: {
            columnName:
              'informationBasic.assessmentGroupName,informationBasic.assessmentGroupDescription',
            order: 'asc'
          },
          numberPage: numberPage,
          countPage: countPage,
          search: [
            {
              condition: 'and',
              searchBy: [
                {
                  dataType: 'string',
                  conditionColumn: 'informationEngagement.assessmentGroupStatus',
                  conditionValue: searchObj
                }
              ]
            },
            ...searchArr
          ]
        }
      };
    }
  }

  return requestObj;
};

export const makeMarketplaceAssessmentNodeReviewListRequestObject = (
  selectedAssociateInfo,
  nodeId,
  filterKey,
  numberPage,
  countPage,
  searchStr = ''
) => {
  let searchArr = [];
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED', 'SUSPENDED', 'TERMINATED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED', 'SUSPENDED', 'TERMINATED', 'ARCHIVED']
      }
    };
  }
  if (searchStr === '') {
    searchArr.push({
      condition: 'or',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.assessmentName',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.assessmentDescription',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationEngagement.assessmentTag.assessmentTagPrimary',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        }
      ]
    });
  }
  return {
    assesseeId: selectedAssociateInfo?.assesseeId,
    associateId:
      selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
    countPage: countPage,
    numberPage: numberPage,
    nodeId: nodeId,
    filter: 'true',
    searchCondition: 'AND',
    orderBy: {
      columnName: 'informationBasic.assessmentName,informationBasic.assessmentDescription',
      order: 'asc'
    },
    search: [
      {
        condition: 'or',
        searchBy: [
          {
            dataType: 'string',
            conditionColumn: 'informationAllocation.assessmentNode.assessmentNodePrimary',
            conditionValue: {
              condition: 'eq',
              value: {
                from: nodeId
              }
            }
          },
          {
            dataType: 'string',
            conditionColumn: 'informationAllocation.assessmentNode.assessmentNodeSecondary',
            conditionValue: {
              condition: 'eq',
              value: {
                from: nodeId
              }
            }
          }
        ]
      },
      {
        condition: 'and',
        searchBy: [
          {
            dataType: 'string',
            conditionColumn: 'informationEngagement.assessmentStatus',
            conditionValue: searchObj
          }
        ]
      },
      ...searchArr
    ]
  };
};

export const makeMarketplaceAssessmentTypeReviewListRequestObject = (
  selectedAssociateInfo,
  typeId,
  filterKey,
  numberPage,
  countPage,
  searchStr = ''
) => {
  let searchArr = [];
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED', 'ARCHIVED']
      }
    };
  }
  if (searchStr !== '') {
    searchArr.push({
      condition: 'or',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.assessmentTypeName',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.assessmentTypeDescription',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        }
      ]
    });
  }
  return {
    module: 'Assessment-Type',
    action: 'Review-List',
    actionFilter: 'AssessmentType-Shared-Assessment',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      typeId: typeId,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.assessmentName,informationBasic.assessmentDescription',
        order: 'asc'
      },
      searchCondition: 'AND',
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.assessmentType.assessmentTypePrimary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: typeId
                }
              }
            },
            {
              dataType: 'string',
              conditionColumn: 'informationAllocation.assessmentType.assessmentTypeSecondary',
              conditionValue: {
                condition: 'eq',
                value: {
                  from: typeId
                }
              }
            }
          ]
        },
        {
          condition: 'and',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.assessmentStatus',
              conditionValue: searchObj
            }
          ]
        },
        ...searchArr
      ]
    }
  };
};

export const makeMarketplaceAssignmentDistinctReviewListRequestObject = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  assignmentCreateType,
  searchStr = ''
) => {
  assignmentCreateType = '';
  let searchArray = [];
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED', 'SUSPENDED', 'TERMINATED', 'ADMINISTERED']
      }
    };
  }
  if (filterKey === 'activeinactive') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED', 'ADMINISTERED']
      }
    };
  }
  if (filterKey === 'active') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'ADMINISTERED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED', 'SUSPENDED', 'TERMINATED', 'ADMINISTERED', 'ARCHIVED']
      }
    };
  }
  let searchArr = [
    {
      dataType: 'string',
      conditionColumn: 'informationEngagement.assignmentStatus',
      conditionValue: searchObj
    }
  ];
  if (searchStr !== '') {
    searchArray.push({
      condition: 'or',
      searchBy: [
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.assignmentName',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationBasic.assignmentDescription',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        },
        {
          dataType: 'string',
          conditionColumn: 'informationEngagement.assignmentTag.assignmentTagPrimary',
          conditionValue: {
            condition: 'ct',
            value: {
              from: searchStr
            }
          }
        }
      ]
    });
  }
  // if (assignmentCreateType === 'iGuru_Assignment') {
  //   let searchObj = {
  //     dataType: 'string',
  //     conditionColumn: 'informationAllocation.assignmentType.assignmentTypeSecondary',
  //     conditionValue: {
  //       condition: 'nin',
  //       value: {
  //         in: [
  //           process.env.REACT_APP_IGURU_ANALYTICS_CAREER,
  //           process.env.REACT_APP_IGURU_ANALYTICS_EDUCATION,
  //           process.env.REACT_APP_IGURU_ANALYTICS_OCCUPATION,
  //           process.env.REACT_APP_IGURU_ANALYTICS_PULSE,
  //           process.env.REACT_APP_IGURU_MINE_CAREER,
  //           process.env.REACT_APP_IGURU_MINE_EDUCATION,
  //           process.env.REACT_APP_IGURU_MINE_OCCUPATION,
  //           process.env.REACT_APP_IGURU_MINE_PULSE,
  //           process.env.REACT_APP_IGURU_MARKETPLACE_CAREER,
  //           process.env.REACT_APP_IGURU_MARKETPLACE_EDUCATION,
  //           process.env.REACT_APP_IGURU_MARKETPLACE_OCCUPATION,
  //           process.env.REACT_APP_IGURU_MARKETPLACE_PULSE
  //         ]
  //       }
  //     }
  //   };
  //   searchArr.push(searchObj);
  // }
  let requestObj = {
    module: 'Assignment-Distinct',
    action: 'Review-List',
    actionFilter: 'Shared-Assignment',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filterTypeName: assignmentCreateType,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.assignmentName,informationBasic.assignmentDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      search: [
        {
          condition: 'and',
          searchBy: searchArr
        },
        ...searchArray
      ]
    }
  };
  return requestObj;
};

export const makeMarketplaceAssignmentGroupReviewListRequestObject = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  groupViewState,
  assignmentCreateType,
  searchStr = ''
) => {
  assignmentCreateType = '';
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'ACTIVE']
      }
    };
  }
  let requestObj = {};
  if (groupViewState === 'hierarchy') {
    requestObj = {
      filterTypeName: assignmentCreateType === 'iGuru_Assignment' ? '' : assignmentCreateType,
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      status: filterKey.toUpperCase()
    };
  } else {
    if (filterKey === 'bespoke' || filterKey === 'generic') {
      requestObj = {
        filterTypeName: assignmentCreateType === 'iGuru_Assignment' ? '' : assignmentCreateType,
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        filter: 'true',
        orderBy: {
          columnName:
            'informationBasic.assignmentGroupName,informationBasic.assignmentGroupDescription',
          order: 'asc'
        },
        numberPage: numberPage,
        countPage: countPage,
        search: [
          {
            condition: 'and',
            searchBy: [
              {
                dataType: 'string',
                conditionColumn: 'informationEngagement.assignmentGroupStatus',
                conditionValue: {
                  condition: 'eq',
                  value: {
                    from: 'ACTIVE'
                  }
                }
              },
              {
                dataType: 'string',
                conditionColumn:
                  'informationSetup.assignmentGroupClassification.assignmentGroupClassificationPrimary',
                conditionValue: {
                  condition: 'eq',
                  value: {
                    from: filterKey === 'bespoke' ? 'Bespoke' : 'Generic'
                  }
                }
              }
            ]
          }
        ]
      };
    } else {
      requestObj = {
        filterTypeName: assignmentCreateType,
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        filter: 'true',
        filterTypeName: assignmentCreateType === 'iGuru_Assignment' ? '' : assignmentCreateType,
        orderBy: {
          columnName:
            'informationBasic.assignmentGroupName,informationBasic.assignmentGroupDescription',
          order: 'asc'
        },
        numberPage: numberPage,
        countPage: countPage,
        search: [
          {
            condition: 'and',
            searchBy: [
              {
                dataType: 'string',
                conditionColumn: 'informationEngagement.assignmentGroupStatus',
                conditionValue: searchObj
              }
            ]
          }
        ]
      };
    }
  }

  return requestObj;
};

export const makeMarketplaceAssignmenNodeReviewListRequestObject = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  assignmentCreateType = ''
) => {
  let searchBy = [];
  let tempSearchObj = {
    dataType: 'string',
    conditionColumn: 'informationEngagement.assessmentStatus',
    conditionValue: searchObj
  };
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'activeinactive') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED']
      }
    };
  }
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED', 'ARCHIVED']
      }
    };
  }
  tempSearchObj.conditionValue = searchObj;

  searchBy.push(tempSearchObj);

  if (assignmentCreateType !== '') {
    let id =
      process.env[`REACT_APP_${assignmentCreateType.replace(' ', '_').toUpperCase()}_ASSESSMENTS`];
    if (id) {
      let x = {
        dataType: 'string',
        conditionColumn: 'informationAllocation.assessmentType.assessmentTypeSecondary',
        conditionValue: {
          condition: 'in',
          value: {
            in: [
              `${process.env[
              `REACT_APP_${assignmentCreateType.replace(' ', '_').toUpperCase()}_ASSESSMENTS`
              ]
              }`
            ]
          }
        }
      };
      searchBy.push(x);
    }
  }
  let requestObj = {
    module: 'Assessment-Distinct',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.assessmentName,informationBasic.assessmentDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      search: [
        {
          condition: 'and',
          searchBy: searchBy
        }
      ]
    }
  };
  return requestObj;
};

export const makeMarketplaceAssignmentTypeReviewListRequestObject = (
  selectedAssociateInfo,
  typeId,
  filterKey,
  numberPage,
  countPage,
  assignmentCreateType = ''
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED', 'SUSPENDED', 'TERMINATED', 'ADMINISTERED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED', 'SUSPENDED', 'TERMINATED', 'ADMINISTERED', 'ARCHIVED']
      }
    };
  }
  return {
    module: 'Assignment-Type',
    action: 'Review-List',
    actionFilter: 'Assignment-Type-Shared-List',
    assesseeId: selectedAssociateInfo?.assesseeId,
    associateId:
      selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
    countPage: countPage,
    numberPage: numberPage,
    filterTypeName: assignmentCreateType,
    orderBy: {
      columnName: 'informationBasic.assignmentName,informationBasic.assignmentDescription',
      order: 'asc'
    },
    typeId: typeId,
    filter: 'true',
    searchCondition: 'AND',
    search: [
      {
        condition: 'or',
        searchBy: [
          {
            dataType: 'string',
            conditionColumn: 'informationAllocation.assignmentType.assignmentTypePrimary',
            conditionValue: {
              condition: 'eq',
              value: {
                from: typeId
              }
            }
          },
          {
            dataType: 'string',
            conditionColumn: 'informationAllocation.assignmentType.assignmentTypeSecondary',
            conditionValue: {
              condition: 'eq',
              value: {
                from: typeId
              }
            }
          }
        ]
      },
      {
        condition: 'and',
        searchBy: [
          {
            dataType: 'string',
            conditionColumn: 'informationEngagement.assignmentStatus',
            conditionValue: searchObj
          }
        ]
      }
    ]
  };
};

export const makeMarketplaceAssociateDistinctReviewListRequestObject = (
  selectedAssociateInfo,
  filterKey,
  numberPage,
  countPage
) => {
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'activeinactive') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['CONFIRMED', 'UNCONFIRMED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: [
          'CONFIRMED',
          'DISAPPROVED',
          'SUSPENDED',
          'TERMINATED',
          'UNAPPROVED',
          'UNCONFIRMED',
          'ARCHIVED'
        ]
      }
    };
  }
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['CONFIRMED', 'DISAPPROVED', 'SUSPENDED', 'TERMINATED', 'UNAPPROVED', 'UNCONFIRMED']
      }
    };
  }
  if (filterKey === 'inactive') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['DISAPPROVED', 'SUSPENDED', 'TERMINATED', 'UNAPPROVED', 'UNCONFIRMED']
      }
    };
  }
  let regObj = {
    module: 'Associate-Distinct',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      countPage: countPage,
      numberPage: numberPage,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.associateName,informationBasic.associateDescripton',
        order: 'asc'
      },
      searchCondition: 'AND',
      search: [
        {
          condition: 'or',
          searchBy: [
            {
              dataType: 'string',
              conditionColumn: 'informationEngagement.associateStatus',
              conditionValue: searchObj
            }
          ]
        }
      ]
    }
  };

  return regObj;
};

export const makeMarketplaceAssociateGroupReviewListRequestObject = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  assignmentCreateType = ''
) => {
  let searchBy = [];
  let tempSearchObj = {
    dataType: 'string',
    conditionColumn: 'informationEngagement.assessmentStatus',
    conditionValue: searchObj
  };
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'activeinactive') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED']
      }
    };
  }
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED', 'ARCHIVED']
      }
    };
  }
  tempSearchObj.conditionValue = searchObj;

  searchBy.push(tempSearchObj);

  if (assignmentCreateType !== '') {
    let id =
      process.env[`REACT_APP_${assignmentCreateType.replace(' ', '_').toUpperCase()}_ASSESSMENTS`];
    if (id) {
      let x = {
        dataType: 'string',
        conditionColumn: 'informationAllocation.assessmentType.assessmentTypeSecondary',
        conditionValue: {
          condition: 'in',
          value: {
            in: [
              `${process.env[
              `REACT_APP_${assignmentCreateType.replace(' ', '_').toUpperCase()}_ASSESSMENTS`
              ]
              }`
            ]
          }
        }
      };
      searchBy.push(x);
    }
  }
  let requestObj = {
    module: 'Assessment-Distinct',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.assessmentName,informationBasic.assessmentDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      search: [
        {
          condition: 'and',
          searchBy: searchBy
        }
      ]
    }
  };
  return requestObj;
};

export const makeMarketplaceAssociateNodeReviewListRequestObject = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  assignmentCreateType = ''
) => {
  let searchBy = [];
  let tempSearchObj = {
    dataType: 'string',
    conditionColumn: 'informationEngagement.assessmentStatus',
    conditionValue: searchObj
  };
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'activeinactive') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED']
      }
    };
  }
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED', 'ARCHIVED']
      }
    };
  }
  tempSearchObj.conditionValue = searchObj;

  searchBy.push(tempSearchObj);

  if (assignmentCreateType !== '') {
    let id =
      process.env[`REACT_APP_${assignmentCreateType.replace(' ', '_').toUpperCase()}_ASSESSMENTS`];
    if (id) {
      let x = {
        dataType: 'string',
        conditionColumn: 'informationAllocation.assessmentType.assessmentTypeSecondary',
        conditionValue: {
          condition: 'in',
          value: {
            in: [
              `${process.env[
              `REACT_APP_${assignmentCreateType.replace(' ', '_').toUpperCase()}_ASSESSMENTS`
              ]
              }`
            ]
          }
        }
      };
      searchBy.push(x);
    }
  }
  let requestObj = {
    module: 'Assessment-Distinct',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.assessmentName,informationBasic.assessmentDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      search: [
        {
          condition: 'and',
          searchBy: searchBy
        }
      ]
    }
  };
  return requestObj;
};

export const makeMarketplaceAssociateRoleReviewListRequestObject = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  assignmentCreateType = ''
) => {
  let searchBy = [];
  let tempSearchObj = {
    dataType: 'string',
    conditionColumn: 'informationEngagement.assessmentStatus',
    conditionValue: searchObj
  };
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'activeinactive') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED']
      }
    };
  }
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED', 'ARCHIVED']
      }
    };
  }
  tempSearchObj.conditionValue = searchObj;

  searchBy.push(tempSearchObj);

  if (assignmentCreateType !== '') {
    let id =
      process.env[`REACT_APP_${assignmentCreateType.replace(' ', '_').toUpperCase()}_ASSESSMENTS`];
    if (id) {
      let x = {
        dataType: 'string',
        conditionColumn: 'informationAllocation.assessmentType.assessmentTypeSecondary',
        conditionValue: {
          condition: 'in',
          value: {
            in: [
              `${process.env[
              `REACT_APP_${assignmentCreateType.replace(' ', '_').toUpperCase()}_ASSESSMENTS`
              ]
              }`
            ]
          }
        }
      };
      searchBy.push(x);
    }
  }
  let requestObj = {
    module: 'Assessment-Distinct',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.assessmentName,informationBasic.assessmentDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      search: [
        {
          condition: 'and',
          searchBy: searchBy
        }
      ]
    }
  };
  return requestObj;
};

export const makeMarketplaceAssociateTypeReviewListRequestObject = (
  selectedAssociateInfo,
  filterKey,
  countPage,
  numberPage,
  assignmentCreateType = ''
) => {
  let searchBy = [];
  let tempSearchObj = {
    dataType: 'string',
    conditionColumn: 'informationEngagement.assessmentStatus',
    conditionValue: searchObj
  };
  let searchObj = {
    condition: 'eq',
    value: {
      from: filterKey.toUpperCase()
    }
  };
  if (filterKey === 'activeinactive') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['PUBLISHED', 'UNPUBLISHED']
      }
    };
  }
  if (filterKey === 'all') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED']
      }
    };
  }
  if (filterKey === '') {
    searchObj = {
      condition: 'in',
      value: {
        in: ['SUSPENDED', 'TERMINATED', 'PUBLISHED', 'UNPUBLISHED', 'ARCHIVED']
      }
    };
  }
  tempSearchObj.conditionValue = searchObj;

  searchBy.push(tempSearchObj);

  if (assignmentCreateType !== '') {
    let id =
      process.env[`REACT_APP_${assignmentCreateType.replace(' ', '_').toUpperCase()}_ASSESSMENTS`];
    if (id) {
      let x = {
        dataType: 'string',
        conditionColumn: 'informationAllocation.assessmentType.assessmentTypeSecondary',
        conditionValue: {
          condition: 'in',
          value: {
            in: [
              `${process.env[
              `REACT_APP_${assignmentCreateType.replace(' ', '_').toUpperCase()}_ASSESSMENTS`
              ]
              }`
            ]
          }
        }
      };
      searchBy.push(x);
    }
  }
  let requestObj = {
    module: 'Assessment-Distinct',
    action: 'Review-List',
    requestObject: {
      assesseeId: selectedAssociateInfo?.assesseeId,
      associateId:
        selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
      filter: 'true',
      orderBy: {
        columnName: 'informationBasic.assessmentName,informationBasic.assessmentDescription',
        order: 'asc'
      },
      numberPage: numberPage,
      countPage: countPage,
      search: [
        {
          condition: 'and',
          searchBy: searchBy
        }
      ]
    }
  };
  return requestObj;
};

export const getManuscriptVideosListApiCall = (
  selectedAssociateInfo,
  dispatch,
  targetValue,
  listType = '',
  isSelectActive = '',
  scanValue = '',
  secondaryOptionCheckValue = 'active'
) => {
  let requestObj = {
    module: 'Iguru-Iglobal',
    action: listType === 'video' ? 'video-distinct' : 'manuscript-distinct',
    requestObject: {
      searchBy: scanValue
    }
  };
  dispatch({
    type: FILTERMODE,
    payload: {
      FilterMode: listType === 'video' ? 'assesseeDistinctVideos' : 'assesseeDistinctManuscripts'
    }
  });
  dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
  dispatch({ type: LOADER_START });
  dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
  dispatch({ type: SET_PAGE_COUNT, payload: 0 });
  dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  dispatch({
    type: GET_ASSESSEE_MANUSCRIPT_VIDEOS_REVIEW_LIST_SAGA,
    payload: {
      request: requestObj,
      HeaderOne: targetValue,
      BadgeOne: 'distinct',
      BadgeTwo: secondaryOptionCheckValue,
      listType: listType,
      isMiddlePaneList: true,
      isSelectActive: isSelectActive
    }
  });
};
// export const getManuscriptsListApiCall = (dispatch, targetValue, isSelectActive = '') => {
//   let requestObj = {
//     module: 'Assessee-Distinct',
//     action: 'Review-List',
//     actionFilter: 'manuscripts-videos',
//     requestObject: {
//       assesseeId: '625652b84e36d82b6982ef06',
//       associateId: '623dab305c151e50182f1412',
//       type: 'video',
//       searchBy: ''
//     }
//   };
//   dispatch({
//     type: FILTERMODE,
//     payload: { FilterMode: 'assesseeDistinctManuscripts' }
//   });
//   dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
//   dispatch({ type: LOADER_START });
//   // dispatch({ type: SET_REQUEST_OBJECT, payload: requestObj });
//   dispatch({ type: SET_PAGE_COUNT, payload: 0 });
//   dispatch({
//     type: GET_ASSESSEE_DISTINCT_MANUSCRIPTS_SAGA,
//     payload: {
//       request: requestObj,
//       BadgeOne: targetValue,
//       BadgeTwo: 'all',
//       BadgeThree: '',
//       isMiddlePaneList: true,
//       isSelectActive: isSelectActive
//     }
//   });
// };
