import { put, takeLatest, call, retry } from 'redux-saga/effects';
import {
  GET_ASSESSEE_SIGN_IN_INFO,
  ASSESSEE_SIGN_IN_SAGA,
  SET_SIGN_IN_STATUS,
  SET_SELECTED_ASSOCIATE,
  SET_ASSESSEE_PERMISSION,
  SET_USER,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_USER_STATE,
  SET_MIDDLEPANE_STATE,
  CLEAR_DISPLAY_PANE_THREE,
  SET_MOBILE_PANE_STATE,
  LOADER_START,
  GET_ASSESSEE_INFO_SAGA,
  SET_ASSESSEE_REVISE_PASSWORD,
  LOADER_STOP,
  POPUP_CLOSE,
  SET_POPUP_VALUE,
  GET_SIGNED_ASSESEE_NOTIFICATION,
  GET_SIGNED_ASSESSEE_PERMISSION_SAGA,
  RESET_ALL_REDUCER,
  CLEAR_PANE_TWO_REDUCER,
  CLEAR_ASSESSEE_ASSESSMENT_REDUCER_STATE,
  REFRESH_TOKENS
} from '../../actionType';
import {
  ASSESSEE_CHANGE_PASSWORD_URL,
  SIGN_IN_URL,
  ASSIGNMENT_MODULE_URL,
  ASSESSEE_MODULE_URL,
  PLATFORM_MODULE_URL
} from '../../endpoints';
import { ASSESSEE_CREDENTIALRESET_ERROR_MESSAGE, ASSESSEE_RESETPWD_ERROR_MESSAGE, ASSESSEE_UNCONFIRM_ERROR_MESSAGE } from '../../errorMessage';
import Store from '../../store';

const apiCallFunction = async (requestObj) => {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(requestObj.data)
  };
  const response = await fetch(requestObj.URL, requestOptions);
  const json = await response.json();
  return json;
};

function* workerSignInAssesseeSaga(data) {
  try {
    const userResponse = yield call(apiCallFunction, {
      data: data.payload.request,
      // URL: ASSESSEE_SIGN_IN_URL
      URL: PLATFORM_MODULE_URL
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === '000') {


      if (userResponse.responseObject.redirectionUrl) {
        console.log("redirect_url", { stateName: "redirect_url", value: userResponse.responseObject.redirectionUrl });
        yield put({ type: SET_USER_STATE, payload: { stateName: "redirect_url", value: userResponse.responseObject.redirectionUrl } })
        return
      }
      console.log('SIGN IN ASSESSEE=======>', userResponse);
      localStorage.setItem('token', userResponse?.responseObject?.cognitoResponse?.accessToken);
      localStorage.setItem(
        'idToken',
        userResponse?.responseObject?.cognitoResponse?.tokenType +
        ' ' +
        userResponse?.responseObject?.cognitoResponse?.idToken
      );
      localStorage.setItem('assesseeId', data.payload.request.requestObject.credential);
      localStorage.setItem('assessmentItem', '[]');
      localStorage.setItem('navigationItem', '[]');
      localStorage.setItem(
        'refreshToken',
        userResponse?.responseObject?.cognitoResponse?.refreshToken
      );
      if (userResponse?.responseObject?.signInResponse?.length > 1) {
        yield put({ type: SET_USER, payload: userResponse?.responseObject?.signInResponse });
        localStorage.setItem('userConfirmed', 'true');
      } else if (userResponse?.responseObject?.signInResponse.length === 1) {
        const selectedAssociate = userResponse?.responseObject?.signInResponse[0];
        localStorage.setItem('userConfirmed', 'true');
        localStorage.setItem(
          'parentId',
          userResponse?.responseObject?.signInResponse[0]?.associate?.informationFramework
            ?.associateAscendantPrimary || null
        );
        console.log('SELECTED ASSOCIATE +++++ >', selectedAssociate);
        yield put({ type: LOADER_START });
        yield put({
          type: SET_SELECTED_ASSOCIATE,
          payload: selectedAssociate
        });
        yield put({ type: LOADER_START });
        yield put({
          type: GET_ASSESSEE_INFO_SAGA,
          payload: {
            secondaryOptionCheckValue: 'all',
            headerOne: '',
            setLeftPaneAssessee: true,
            reqBody: {
              module: 'Assessee-Distinct',
              action: 'Review',
              requestObject: {
                assesseeId: selectedAssociate.assesseeId,
                associateId:
                  selectedAssociate.associate.informationEngagement.associateTag
                    .associateTagPrimary,
                filter: 'true',
                isAssesseeSelf: 'true',
                searchCondition: 'AND',
                search: [
                  {
                    condition: 'and',
                    searchBy: [
                      {
                        dataType: 'string',
                        conditionColumn: 'id',
                        conditionValue: {
                          condition: 'eq',
                          value: {
                            from: selectedAssociate.assesseeId
                          }
                        }
                      }
                    ]
                  }
                ]
              }
            }
          }
        });
        yield put({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneOne' });
      } else {
        let isAssesseeAssent = userResponse?.responseObject?.signInResponse?.isAssesseeAssent;
        let isAssociateAssent = userResponse?.responseObject?.signInResponse?.isAssociateAssent;
        let resetPassword = userResponse?.responseObject?.signInResponse?.resetPassword;
        let resetCredential = userResponse?.responseObject?.signInResponse?.resetCredential;


        if (data?.payload?.isfromSignPage && isAssesseeAssent && isAssociateAssent && resetPassword) {
          yield put({ type: SET_SIGN_IN_STATUS, payload: 'responseError' });
          localStorage.setItem('responseError', ASSESSEE_RESETPWD_ERROR_MESSAGE);
          return;
        }
        if (data?.payload?.isfromSignPage && isAssesseeAssent && isAssociateAssent && resetCredential) {
          yield put({ type: SET_SIGN_IN_STATUS, payload: 'responseError' });
          localStorage.setItem('responseError', ASSESSEE_CREDENTIALRESET_ERROR_MESSAGE);
          return;
        }
        if (data?.payload?.isfromSignPage) {
          yield put({ type: SET_SIGN_IN_STATUS, payload: 'responseError' });
          localStorage.setItem('responseError', ASSESSEE_UNCONFIRM_ERROR_MESSAGE);
          return;
        }
      }
      yield put({
        type: SET_USER_STATE,
        payload: {
          stateName: 'loginUserName',
          value: data.payload.request.requestObject.credential
        }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: {
          stateName: 'signInRes',
          value: userResponse?.responseObject
        }
      });
      yield put({
        type: SET_MIDDLEPANE_STATE,
        payload: {
          middlePaneHeader: '',
          middlePaneHeaderBadgeOne: '',
          middlePaneHeaderBadgeTwo: '',
          middlePaneHeaderBadgeThree: '',
          middlePaneHeaderBadgeFour: '',
          typeOfMiddlePaneList: '',
          scanCount: null,
          showMiddlePaneState: false
        }
      });
      yield put({ type: CLEAR_DISPLAY_PANE_THREE });
      // yield put({
      //   type: SET_SELECTED_ASSOCIATE,
      //   payload: ''
      // });
      yield put({
        type: SET_ASSESSEE_PERMISSION,
        payload: ''
      });
      yield put({ type: SET_SIGN_IN_STATUS, payload: 'success' });
      yield put({
        type: SET_USER_STATE,
        payload: { stateName: 'assesseeConfirmStatus', value: null }
      });
    } else {
      if (userResponse.responseMessage !== '') {
        yield put({ type: SET_SIGN_IN_STATUS, payload: 'responseError' });
        localStorage.setItem('responseError', userResponse.responseMessage || null);
      } else {
        yield put({ type: SET_SIGN_IN_STATUS, payload: 'error' });
      }
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({ type: SET_SIGN_IN_STATUS, payload: 'error' });
  }
}
const revisePasswordApi = async (requestObj) => {
  // console.log('requestObj.data');
  // console.log(requestObj.data);
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      Authorization: localStorage.getItem('token')
    }),
    body: JSON.stringify(requestObj.data)
  };
  const response = await fetch(requestObj.URL, requestOptions);
  const json = await response.json();
  return json;
};
const apiCall = async (requestObj) => {
  const requestOptions = {
    method: 'GET',
    headers: new Headers({
      Authorization: localStorage.getItem('token')
    }),
  };
  const response = await fetch("https://isdl4c2gae.execute-api.ap-southeast-1.amazonaws.com/dev/insight-guru/api/assesseeAssignmentAssessmentGet/123", requestOptions);
  const json = await response.json();

  return json;
};
function* workerSetPassword(data) {
  try {
    const response = yield call(revisePasswordApi, {
      data: data.payload.reqObj,
      URL: ASSESSEE_CHANGE_PASSWORD_URL
    });
    if (response.responseCode === '000') {
      yield put({ type: LOADER_STOP });
      yield put({ type: POPUP_CLOSE });
      localStorage.clear();
      yield put({ type: RESET_ALL_REDUCER });
      yield put({ type: CLEAR_PANE_TWO_REDUCER });
      yield put({ type: CLEAR_ASSESSEE_ASSESSMENT_REDUCER_STATE });
      yield put({ type: CLEAR_DISPLAY_PANE_THREE });
      yield put({
        type: SET_USER_STATE,
        payload: { stateName: 'assesseeConfirmStatus', value: 'passwordReviseSuccess' }
      });

    } else {
      yield put({
        type: SET_USER_STATE,
        payload: { stateName: 'assesseeConfirmStatus', value: 'incorrectPassword' }
      });
    }
    yield put({ type: LOADER_STOP });
  } catch (error) {
    yield put({ type: LOADER_STOP });
  }
}
function* getSignedAsseseePermissionSaga(data) {
  try {
    yield put({ type: LOADER_START });
    const response = yield call(revisePasswordApi, {
      data: data.payload.reqBody,
      URL: ASSESSEE_MODULE_URL
    });
    if (response.responseCode === '000') {
      let responseObject = response.responseObject[0];
      let dummyobj = { create: false, delete: false, review: false, revise: false, share: false, sell: false, move: false }
      let assistantDistinctPermission;
      let managerDistinctPermission;
      let adminDistinctPermission;
      if (responseObject?.assesseeRoles?.assesseeAdminRole) {
        assistantDistinctPermission = responseObject?.assesseeDistinctPermission?.assesseeAssesseeDistinctPermission;
        managerDistinctPermission = responseObject?.assesseeDistinctPermission?.assesseeAssesseeDistinctPermission;
        adminDistinctPermission = responseObject?.assesseeDistinctPermission?.assesseeAssesseeDistinctPermission;
      }
      if (responseObject?.assesseeRoles?.assesseeMangerRole) {
        assistantDistinctPermission = responseObject?.assesseeDistinctPermission?.assesseeAssesseeDistinctPermission;
        managerDistinctPermission = responseObject?.assesseeDistinctPermission?.assesseeAssesseeDistinctPermission;
        adminDistinctPermission = dummyobj;
      }
      if (responseObject?.assesseeRoles?.assesseeAssistantRole) {
        assistantDistinctPermission = responseObject?.assesseeDistinctPermission?.assesseeAssesseeDistinctPermission;
        managerDistinctPermission = dummyobj;
        adminDistinctPermission = dummyobj;
      }
      let reviseResponseObj = {
        ...responseObject,
        associateSelfPermission: {
          ...responseObject.associateSelfPermission,
          assesseeAssistantDistinctPermission: assistantDistinctPermission,
          assesseeAdminDistinctPermission: adminDistinctPermission,
          assesseeManagerDistinctPermission: managerDistinctPermission
        }
      }
      // console.log('reviseResponseObjreviseResponseObj', reviseResponseObj);
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: {
          stateName: 'signedAssesseePermission',
          value: reviseResponseObj
        }
      });

      yield put({
        type: SET_USER_STATE,
        payload: {
          stateName: 'assesseePermissionLoaded',
          value: true
        }
      });


    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: response.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    yield put({ type: POPUP_CLOSE });
    yield put({ type: LOADER_STOP });
  } catch (error) {
    yield put({ type: LOADER_STOP });
  }
}
function* getSignedAsseseeNotificationSaga(data) {
  try {
    yield put({ type: LOADER_START });
    const response = yield call(revisePasswordApi, {
      data: data.payload.reqBody,
      URL: ASSIGNMENT_MODULE_URL
    });
    let b = Store.getState().DisplayPaneTwoReducer.leftPaneAssesseeInfo;
    if (response.responseCode === '000') {
      let newB = { ...b, notifications: response.responseObject };
      // console.log('newBbb', newB);
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: {
          stateName: 'leftPaneAssesseeInfo',
          value: newB
        }
      });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: response.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    yield put({ type: POPUP_CLOSE });
  } catch (error) {
    yield put({ type: LOADER_STOP });
  }
}
function* workerSignInAssesseeInfo(data) {
  try {
    const userResponse = yield call(apiCallFunction, {
      data: data.payload.request,
      // URL: ASSESSEE_SIGN_IN_INFO_URL
      URL: PLATFORM_MODULE_URL
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    let { getRefreshToken = false, request } = data.payload;
    // console.log('****', userResponse, getRefreshToken, userResponse?.responseObject?.cognitoResponse?.refreshToken, '****')
    if (userResponse.responseCode === '000') {
      if (getRefreshToken) {
        if (userResponse?.responseObject?.cognitoResponse?.refreshToken) {
          localStorage.setItem('token', userResponse?.responseObject?.cognitoResponse?.accessToken);
          localStorage.setItem(
            'idToken',
            userResponse?.responseObject?.cognitoResponse?.tokenType +
            ' ' +
            userResponse?.responseObject?.cognitoResponse?.idToken
          );
          localStorage.setItem(
            'refreshToken',
            userResponse?.responseObject?.cognitoResponse?.refreshToken
          );
        } else {
          yield put({ type: LOADER_STOP });
          localStorage.clear();
          yield put({ type: RESET_ALL_REDUCER });
          yield put({ type: CLEAR_PANE_TWO_REDUCER });
          yield put({ type: CLEAR_ASSESSEE_ASSESSMENT_REDUCER_STATE });
          window.open(SIGN_IN_URL, '_self');
        }
      } else {
        yield put({ type: SET_USER, payload: userResponse?.responseObject?.signInResponse });
        if (userResponse?.responseObject.signInResponse.length > 1) {
          yield put({ type: SET_USER, payload: userResponse?.responseObject.signInResponse });
          yield put({ type: LOADER_STOP });
        } else if (userResponse?.responseObject.signInResponse.length === 1) {
          const selectedAssociate = userResponse?.responseObject.signInResponse[0];
          yield put({ type: LOADER_START });
          yield put({
            type: SET_SELECTED_ASSOCIATE,
            payload: selectedAssociate
          });

          yield put({
            type: GET_ASSESSEE_INFO_SAGA,
            payload: {
              secondaryOptionCheckValue: 'all',
              headerOne: '',
              setLeftPaneAssessee: true,
              reqBody: {
                module: 'Assessee-Distinct',
                action: 'Review',
                requestObject: {
                  assesseeId: selectedAssociate.assesseeId,
                  associateId:
                    selectedAssociate.associate.informationEngagement.associateTag
                      .associateTagPrimary,
                  filter: 'true',
                  isAssesseeSelf: 'true',
                  searchCondition: 'AND',
                  search: [
                    {
                      condition: 'and',
                      searchBy: [
                        {
                          dataType: 'string',
                          conditionColumn: 'id',
                          conditionValue: {
                            condition: 'eq',
                            value: {
                              from: selectedAssociate.assesseeId
                            }
                          }
                        }
                      ]
                    }
                  ]
                }
              }
            }
          });
          //yield put({ type: LOADER_STOP });
          yield put({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneOne' });
        } else {
          //show message
        }
        yield put({
          type: SET_USER_STATE,
          payload: {
            stateName: 'loginUserName',
            value: data.payload.request.requestObject.credential
          }
        });
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'signInRes',
            value: userResponse?.responseObject
          }
        });
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: '',
            middlePaneHeaderBadgeOne: '',
            middlePaneHeaderBadgeTwo: '',
            middlePaneHeaderBadgeThree: '',
            middlePaneHeaderBadgeFour: '',
            typeOfMiddlePaneList: '',
            scanCount: null,
            showMiddlePaneState: false
          }
        });
        yield put({ type: CLEAR_DISPLAY_PANE_THREE });
        yield put({
          type: SET_ASSESSEE_PERMISSION,
          payload: ''
        });
      }
    } else {
      yield put({ type: LOADER_STOP });
      localStorage.clear();
      yield put({ type: RESET_ALL_REDUCER });
      yield put({ type: CLEAR_PANE_TWO_REDUCER });
      yield put({ type: CLEAR_ASSESSEE_ASSESSMENT_REDUCER_STATE });
      window.open(SIGN_IN_URL, '_self');
    }
  } catch (e) {
    console.log('ERROR==', e);
  }
}

// function* workerTokenRefresher(data) {
//   try {
//     const userResponse = yield call(apiCallFunction, {
//       data: data.payload.request,
//       // URL: ASSESSEE_SIGN_IN_URL
//       URL: PLATFORM_MODULE_URL
//     });

//     console.log(userResponse, '**', localStorage.getItem("token"))
//     if (userResponse.responseCode === '000') {
//       console.log(userResponse?.responseObject?.cognitoResponse?.accessToken)
//       localStorage.setItem('token', userResponse?.responseObject?.cognitoResponse?.accessToken);
//       localStorage.setItem(
//         'idToken',
//         userResponse?.responseObject?.cognitoResponse?.tokenType +
//         ' ' +
//         userResponse?.responseObject?.cognitoResponse?.idToken
//       );
//       localStorage.setItem(
//         'refreshToken',
//         userResponse?.responseObject?.cognitoResponse?.refreshToken
//       );
//       yield put({ type: LOADER_STOP });
//     } else {
//       yield put({ type: LOADER_STOP });
//       window.open(SIGN_IN_URL, '_self');
//       localStorage.clear();
//       yield put({ type: RESET_ALL_REDUCER });
//       yield put({ type: CLEAR_PANE_TWO_REDUCER });
//       yield put({ type: CLEAR_ASSESSEE_ASSESSMENT_REDUCER_STATE });
//     }

//   } catch (err) {
//     yield put({ type: LOADER_STOP });
//     window.open(SIGN_IN_URL, '_self');
//     localStorage.clear();
//     yield put({ type: RESET_ALL_REDUCER });
//     yield put({ type: CLEAR_PANE_TWO_REDUCER });
//     yield put({ type: CLEAR_ASSESSEE_ASSESSMENT_REDUCER_STATE });
//   }
// }

function* retrySaga(data) {
  try {
    const SECOND = 1000
    const userResponse = yield retry(2, 150 * SECOND, apiCallFunction, {
      data: data.payload.request,
      // URL: ASSESSEE_SIGN_IN_URL
      URL: PLATFORM_MODULE_URL
    })


    if (userResponse.responseCode === '000') {
      // console.log(userResponse?.responseObject?.cognitoResponse?.refreshToken)
      localStorage.setItem('token', userResponse?.responseObject?.cognitoResponse?.accessToken);
      localStorage.setItem(
        'idToken',
        userResponse?.responseObject?.cognitoResponse?.tokenType +
        ' ' +
        userResponse?.responseObject?.cognitoResponse?.idToken
      );
      // localStorage.setItem(
      //   'refreshToken',
      //   userResponse?.responseObject?.cognitoResponse?.idToken
      // );
      yield put({ type: LOADER_STOP });
    } else {
      yield put({ type: LOADER_STOP });
    }

    // yield put({ type: 'REQUEST_SUCCESS', payload: response })
  } catch (error) {
    console.log(error, 'this error')
    yield put({ type: LOADER_STOP });
    window.open(SIGN_IN_URL, '_self');
    localStorage.clear();
    yield put({ type: RESET_ALL_REDUCER });
    yield put({ type: CLEAR_PANE_TWO_REDUCER });
    yield put({ type: CLEAR_ASSESSEE_ASSESSMENT_REDUCER_STATE });
    // yield put({ type: 'REQUEST_FAIL', payload: { error } })
  }
}

export default function* watchSignInAssesseeSaga() {
  yield takeLatest(ASSESSEE_SIGN_IN_SAGA, workerSignInAssesseeSaga);
  yield takeLatest(GET_ASSESSEE_SIGN_IN_INFO, workerSignInAssesseeInfo);
  yield takeLatest(SET_ASSESSEE_REVISE_PASSWORD, workerSetPassword);
  yield takeLatest(GET_SIGNED_ASSESEE_NOTIFICATION, getSignedAsseseeNotificationSaga);
  yield takeLatest(GET_SIGNED_ASSESSEE_PERMISSION_SAGA, getSignedAsseseePermissionSaga);
  // yield takeLatest(REFRESH_TOKENSX, workerTokenRefresher);
  yield takeLatest(REFRESH_TOKENS, retrySaga);
}
