import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CLEAR_DISPLAY_PANE_THREE,
  FILTERMODE,
  FILTERMODE_ENABLE,
  POPUP_OPEN,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MIDDLEPANE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_POPUP_STATE,
  SET_ASSIGNMENT_RELATED_LIST,
  LOADER_START,
  ASSIGNMENT_REVISE_SAGA,
  SET_DISPLAY_THREE_SINGLE_STATE,
  GET_ALLOCATE_ASSESSEE,
  SET_PAGE_COUNT,
  GET_ASSIGNMENTDISTINCT_ASSESSEES_REVIEWLIST_SAGA,
  RESET_ASSIGNMENT_REVIEW_LIST_OBJECT,
  SET_POPUP_SINGLE_STATE
} from '../actionType';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import { FilterList } from '@material-ui/icons';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import {
  ASSIGNMENT_ASSESSEE_REVIEW_LIST_POPUP_OPTION,
  ASSOCIATE_REVIEW_LIST_POPUP_OPTION
} from '../PopUpConfig';
import Card from '../Molecules/Card/Card';
import CrossIcon from '@material-ui/icons/Clear';
import {
  getAssesseeGroupAssesseeDistinctApiCall,
  onClickCheckBoxOneListforAssignmentAssessee,
  onClickCheckBoxOneListSelection
} from '../Actions/AssesseeModuleAction';
import { assesseeStatus } from '../Actions/StatusAction';
import ReviseIcon from '@material-ui/icons/RadioButtonChecked';
import Check from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { getAssignmnetAssesseeDistinctApiCall } from '../Actions/AssignmentModuleAction';
import { closeRelatedList, getPopUpOptionList } from '../Actions/GenericActions';
import ListLoader from '../Atoms/ListLoader/ListLoader';

const AssignmentDistinctAssesseeDistinctReviewList = (props) => {
  const dispatch = useDispatch();
  const [isShowReviseIcon, setIsShowReviseIcon] = useState(true);
  const {
    middlePaneSelectedValue,
    reviewListDistinctData,
    selectedAssociateInfo,
    relatedReviewListDistinctData,
    middlePaneHeaderDuplicate,
    middlePaneHeaderBadgeOne,
    middlePaneHeaderBadgeTwo,
    middlePaneHeaderDuplicateBadgeOne,
    middlePaneHeaderDuplicateBadgeTwo,
    middlePaneHeaderDuplicateBadgeThree,
    middlePaneHeaderDuplicateBadgeFour,
    middlePaneHeaderDuplicateOne,
    middlePaneHeaderDuplicateOneBadgeOne,
    middlePaneHeaderDuplicateOneBadgeTwo,
    middlePaneHeaderDuplicateOneBadgeThree,
    middlePaneHeaderDuplicateOneBadgeFour,
    typeOfMiddlePaneList,
    selectedTagsArray,
    isSelectActive,
    duplicateScanCount,
    countPage,
    relatedReviewListObjectCopy,
    unselectedTagsArray,
    middlePaneHeader,
    numberPage,
    scanCount,
    isListLoading,
    previousMiddlePaneState,
    signedAssesseePermission
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const {
    createMode,
    responseObject,
    reviewMode,
    headerOneBadgeOne,
    headerOneBadgeTwo,
    assignmentAssessmentList = [],
    assignmentCultureProfileList = [],
    assignmentJobProfileList = [],
    assignmentAssesseeGroupList = [],
    assignmentAssessmentGroupList = [],
    assignmentCultureProfileGroupList = [],
    assignmentJobProfileGroupList = []
  } = useSelector((state) => state.DisplayPaneThreeReducer);
  const assignmentInfo = useSelector((state) => state.AssignmentReducer);
  const { FilterModeEnable, FilterMode } = useSelector((state) => state.FilterReducer);
  // const [isFetching, setIsFetching] = useState(false);
  //// pagination code here
  // useEffect(() => {
  //   document.getElementById('middleComponentId').addEventListener('scroll', handleScroll);
  // }, []);
  // const handleScroll = (event) => {
  //   var targetPt = event.target;
  //   if (
  //     Math.ceil(targetPt.scrollHeight - targetPt.scrollTop) !== targetPt.clientHeight ||
  //     isFetching
  //   )
  //     return;
  //   setIsFetching(true);
  // };
  const fetchData = async () => {
    if (relatedReviewListDistinctData.length > 0) {
      if (relatedReviewListDistinctData[0].assessee.length < scanCount) {
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'isListLoading', value: true }
        });
        // dispatch({ type: LOADER_START })
        let obj = {
          ...relatedReviewListObjectCopy.requestObject,
          numberPage: numberPage + 1
        };
        // IF the selected mode is Revise and pagination
        if (reviewMode === 'revise') {
          dispatch({
            type: GET_ALLOCATE_ASSESSEE,
            payload: {
              existingAssesseeId: selectedTagsArray,
              unselectedTagsArray,
              request: {
                module: 'Assessee-Distinct',
                action: 'Review-List',
                requestObject: obj
              },
              HeaderOne: middlePaneHeader,
              BadgeOne: 'distinct',
              // BadgeTwo:
              //   FilterMode === 'assesseeGroupAssesseeRevise' ? 'all' : middlePaneHeaderBadgeTwo,
              BadgeTwo: middlePaneHeaderBadgeTwo,
              isMiddlePaneList: true,
              typeOfMiddlePaneList: 'assignmentDistinctAssesseeReviewList',
              revisedGroupObject: {
                id: responseObject.id,
                assignmentName: responseObject.informationBasic.assignmentName,
                assignmentDescription: responseObject.informationBasic.assignmentDescription,
                assignmentStatus: responseObject.informationEngagement.assignmentStatus
              }
            }
          });
        } else {
          dispatch({
            type: GET_ASSIGNMENTDISTINCT_ASSESSEES_REVIEWLIST_SAGA,
            payload: {
              request: {
                module: 'Assignment-Distinct',
                action: 'Review-List',
                actionFilter: 'Assignment-Assessee',
                requestObject: obj
              },
              HeaderOne: 'assessees',
              BadgeOne: middlePaneHeaderBadgeOne,
              BadgeTwo: middlePaneHeaderBadgeTwo,
              BadgeThree: '',
              isMiddlePaneList: true
            }
          });
        }

        dispatch({ type: SET_PAGE_COUNT, payload: numberPage + 1 });
      }
    }
  };

  // useEffect(() => {
  //   if (!isFetching) return;
  //   fetchMoreListItems();
  // }, [isFetching]);

  // const fetchMoreListItems = () => {
  //   fetchData();
  //   setIsFetching(false);
  // };
  ////
  const onClickRevise = () => {
    console.log('ON CLICK REVISE ICON');
    setIsShowReviseIcon(false);
  };
  const onClickReviseCancel = () => {

    if(isListLoading){
      return
    }

    console.log('ON CLICK cancel ICON');
    setIsShowReviseIcon(true);
    setPrevList();
    // if (middlePaneHeaderDuplicateOne !== 'assignments') {
    //   // at last review corss / revise cancel call pane three reqiured to be cleared
    //   // if (reviewMode === 'review') {
    //   //   dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
    //   // }
    //   console.log("X_X_X_X_X")
    //   dispatch({
    //     type: SET_MIDDLEPANE_STATE,
    //     payload: {
    //       middlePaneHeader: '',
    //       middlePaneHeaderBadgeOne: '',
    //       middlePaneHeaderBadgeTwo: '',
    //       middlePaneHeaderBadgeThree: '',
    //       middlePaneHeaderBadgeFour: '',
    //       typeOfMiddlePaneList: '',
    //       showMiddlePaneState: false
    //     }
    //   });
    // }
  };
  const setPrevList = () => {
    // debugger
    if (createMode === '') {

      if (headerOneBadgeOne === 'analytics' || headerOneBadgeTwo === 'analytics') {
        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
      }

      let middlepanelistfallback = "assignmentDistinctReviewList"
      if (middlePaneHeaderDuplicateBadgeOne === "groups") {
        middlepanelistfallback = "assignmentsGroupDistinctReviewList"
      } else if (middlePaneHeaderDuplicateBadgeOne === "types") {
        middlepanelistfallback = "assignmentsTypeDistinctReviewList"
      } else if (middlePaneHeaderDuplicateBadgeOne === "nodes") {
        middlepanelistfallback = "associateNodeDistinctactive"
      }

      closeRelatedList(
        dispatch,
        createMode,
        responseObject,
        middlePaneHeaderDuplicate,
        middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderDuplicateBadgeTwo,
        middlePaneHeaderDuplicateBadgeThree,
        middlePaneHeaderDuplicateBadgeFour,
        previousMiddlePaneState,
        duplicateScanCount,
        'assignmentsDistinct', //'assignmentsDistinctinactive',
        duplicateScanCount,
        middlePaneHeaderDuplicateOne,
        middlePaneHeaderDuplicateOneBadgeOne,
        middlePaneHeaderDuplicateOneBadgeTwo,
        middlePaneHeaderDuplicateOneBadgeThree,
        middlePaneHeaderDuplicateOneBadgeFour
      );
    } else {
      dispatch({
        type: SET_MIDDLEPANE_STATE,
        payload: {
          middlePaneHeader: '',
          middlePaneHeaderBadgeOne: '',
          middlePaneHeaderBadgeTwo: '',
          middlePaneHeaderBadgeThree: '',
          middlePaneHeaderBadgeFour: '',
          typeOfMiddlePaneList: '',
          scanCount: '',
          showMiddlePaneState: false
        }
      });
    }
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'relatedReviewListDistinctData', value: [] }
    });
  };
  const onClickReviseFinish = () => {
    setIsShowReviseIcon(true);
    setPrevList();
    if (typeOfMiddlePaneList !== '') {
      dispatch({
        type: FILTERMODE,
        payload: { FilterMode: '' }
      });
    }
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'isSelectActive', value: '' }
    });
    dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
    dispatch({
      type: SET_ASSIGNMENT_RELATED_LIST,
      payload: { listName: 'assignmentAssesseeList', value: selectedTagsArray }
    });
    dispatch({
      type: SET_DISPLAY_THREE_SINGLE_STATE,
      payload: { stateName: 'assignmentRelatedReviewListPaneThree', value: {} }
    });
    const { id } = responseObject;
    let frameworkObj = {
      ...assignmentInfo.informationFramework,
      assignmentAssesseeDistinct: selectedTagsArray || [],
      assignmentAssessmentDistinct: assignmentAssessmentList || [],
      assignmentCultureProfileDistinct: assignmentCultureProfileList || [],
      assignmentJobProfileDistinct: assignmentJobProfileList || [],
      assignmentAssesseeGroup: assignmentAssesseeGroupList || [],
      assignmentAssessmentGroup: assignmentAssessmentGroupList || [],
      assignmentCultureProfileGroup: assignmentCultureProfileGroupList || [],
      assignmentJobProfileGroup: assignmentJobProfileGroupList || []
    };
    const reqBody = {
      module: 'Assignment-Distinct',
      action: 'Revise',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        assignment: {
          id,
          informationBasic: assignmentInfo.informationBasic,
          informationAllocation: assignmentInfo.informationAllocation,
          informationFramework: frameworkObj,
          informationSetup: assignmentInfo.informationSetup
        }
      }
    };
    dispatch({ type: LOADER_START });
    dispatch({
      type: ASSIGNMENT_REVISE_SAGA,
      payload: {
        reqBody
      }
    });
    dispatch({ type: RESET_ASSIGNMENT_REVIEW_LIST_OBJECT });
    closeRelatedList(
      dispatch,
      createMode,
      responseObject,
      middlePaneHeaderDuplicate,
      middlePaneHeaderDuplicateBadgeOne,
      middlePaneHeaderDuplicateBadgeTwo,
      middlePaneHeaderDuplicateBadgeThree,
      middlePaneHeaderDuplicateBadgeFour,
      previousMiddlePaneState,
      duplicateScanCount,
      'assignmentsDistinct', //'assignmentsDistinctinactive',
      duplicateScanCount,
      middlePaneHeaderDuplicateOne,
      middlePaneHeaderDuplicateOneBadgeOne,
      middlePaneHeaderDuplicateOneBadgeTwo,
      middlePaneHeaderDuplicateOneBadgeThree,
      middlePaneHeaderDuplicateOneBadgeFour
    );
    dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
  };
  const revisePrimaryIcon = [{ label: 'revise', onClick: onClickRevise, Icon: ReviseIcon }];

  const reviseSecondaryIcons = [
    { label: 'cancel', onClick: onClickReviseCancel, Icon: ClearIcon },
    { label: 'finish', onClick: onClickReviseFinish, Icon: Check }
  ];
  const listDistinctData = relatedReviewListDistinctData[0];

  const siftApiCall = (siftKey) => {
    getAssesseeGroupAssesseeDistinctApiCall(
      selectedAssociateInfo,
      siftKey,
      countPage,
      dispatch,
      middlePaneHeaderBadgeOne,
      listDistinctData.id,
      '',
      false,
      middlePaneHeaderBadgeOne
    );
    // document.getElementById('middleComponentId').scrollTop = '0px';
  };
  const siftApiFilterCall = (siftKey) => {
    let assesseeStatus = FilterMode.replace('assignmentDistinctAssesseeDistinct', '');
    if (siftKey === 'timeout') siftKey = 'unfinished';
    getAssignmnetAssesseeDistinctApiCall(
      selectedAssociateInfo,
      siftKey,
      countPage,
      dispatch,
      middlePaneHeaderBadgeOne,
      listDistinctData.id,
      '',
      false,
      assesseeStatus,
      true,
      "displayPaneTwo",
      true
    );
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'typeOfMiddlePaneList', value: '' }
    });
    dispatch({ type: SET_PAGE_COUNT, payload: 0 });
    // document.getElementById('middleComponentId').scrollTop = '0px';
  };
  const onClickFooter = (e) => {
    let siftValue = e.currentTarget.getAttribute('data-value');
    // if (
    //   siftValue === "suspended" ||
    //   siftValue === "terminated" ||
    //   siftValue === "disapproved" ||
    //   siftValue === "unapproved" ||
    //   siftValue === "unconfirmed"
    // )
    //   siftApiCall(siftValue);

    if (
      siftValue === 'started' ||
      siftValue === 'unstarted' ||
      siftValue === 'aborted' ||
      siftValue === 'finished' ||
      siftValue === 'suspended' ||
      siftValue === 'terminated' ||
      siftValue === 'timeout'
    )
      siftApiFilterCall(siftValue);

    dispatch({ type: FILTERMODE_ENABLE });
  };
  /* for middle pane */
  const primaryIcon = [{ label: 'sift', onClick: onClickFooter, Icon: FilterList }];
  const secondaryIcon = [
    { label: 'aborted', onClick: onClickFooter, Icon: FilterList },
    { label: 'finished', onClick: onClickFooter, Icon: FilterList },
    { label: 'started', onClick: onClickFooter, Icon: FilterList },
    { label: 'timeout', onClick: onClickFooter, Icon: FilterList },
    { label: 'unstarted', onClick: onClickFooter, Icon: FilterList }
  ];

  const secondaryIconOne = [
    { label: 'finished', onClick: onClickFooter, Icon: FilterList },
    { label: 'started', onClick: onClickFooter, Icon: FilterList },
    { label: 'suspended', onClick: onClickFooter, Icon: FilterList },
    { label: 'terminated', onClick: onClickFooter, Icon: FilterList },
    { label: 'timeout', onClick: onClickFooter, Icon: FilterList },
    { label: 'unstarted', onClick: onClickFooter, Icon: FilterList }
  ];

  const openListPopup = (e) => {
    console.log(e.currentTarget.getAttribute('tag'));
    let status = e.currentTarget.getAttribute('status');
    let associateId = e.currentTarget.getAttribute('associateid');
    let isShared = e.currentTarget.getAttribute('data-shared') === 'true' && true;
    let signedAssociateId =
      selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary;
    let tempArr = [];
    let statusArr = ['STARTED', 'UNSTARTED', 'ABORTED', 'FINISHED', 'UNFINISHED'];
    let assigmentDistictPermission =
      signedAssesseePermission?.assigmentDistictPermission?.assesseeAssignmentDistinctPermission;
    let popupContentArrValue = getPopUpOptionList(
      ASSIGNMENT_ASSESSEE_REVIEW_LIST_POPUP_OPTION,
      assigmentDistictPermission
    );
    console.log('associateId !== signedAssociateId', associateId !== signedAssociateId);
    console.log('associateId !== signedAssociateId', associateId + '  ' + signedAssociateId);
    popupContentArrValue.forEach((element) => {
      if ((status !== 'FINISHED' && status !== 'TIMEOUT') && element.data === 'analytics')
        tempArr.push({ ...element, disabled: true });
      else if (!statusArr.includes(status) && element.data === 'assessments') {
        tempArr.push({ ...element, disabled: true });
      } else if (
        (status === 'TERMINATED' || status === 'SUSPENDED' || status === 'ARCHIVED') &&
        (element.data === 'allocate' ||
          element.data === 'revise' ||
          element.data === 'select' ||
          element.data === 'share')
      ) {
        tempArr.push({ ...element, disabled: true });
      } else if (status === 'FINISHED') {
        if (element.data === "analytics") {
          tempArr.push({ ...element, disabled: false });
        } else {
          tempArr.push({ ...element, disabled: true });
        }
      } else if (
        associateId !== signedAssociateId &&
        (element.data === 'revise' ||
          element.data === 'allocate' ||
          element.data === 'reset' ||
          element.data === 'resend' ||
          element.data === 'share' ||
          element.data === 'review' ||
          element.data === 'archive' ||
          element.data === 'flag' ||
          element.data === 'delete' ||
          element.data === 'suspend' ||
          element.data === 'move' ||
          element.data === 'assessments' ||
          element.data === 'terminate')
      )
        tempArr.push({ ...element, disabled: true });
      else tempArr.push(element);
    });

    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: 'assessee',
        popupHeaderOneBadgeOne: middlePaneHeaderBadgeOne,
        isPopUpValue: '',
        popupOpenType: 'primary',
        popupContentArrValue: tempArr,
        originPrimaryArrOprion: tempArr,
        popupOptionArr: tempArr,
        selectedTagValue: e.currentTarget.getAttribute('tag'),
        selectedTagStatus: e.currentTarget.getAttribute('status')
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: tempArr
      }
    });
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: {
        stateName: 'popupOptionArr',
        value: tempArr
      }
    });
  };
  console.log('listDistinctData', listDistinctData);

  // infinite scroll
  const [hasMore, setHasMore] = useState(true);
  let elementRef = useRef(null);

  const onIntersection = (entries) => {
    const firstEntry = entries[0];
    relatedReviewListDistinctData[0].assessee.length >= scanCount
      ? setHasMore(false)
      : setHasMore(true);
    console.log(firstEntry.isIntersecting, hasMore, !isListLoading);
    if (firstEntry.isIntersecting && hasMore && !isListLoading) {
      fetchData();
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(onIntersection, { threshold: 0.90 });

    if (observer && elementRef.current) {
      // console.log('here at observer...', elementRef.current)
      observer.observe(elementRef.current);
    }

    return () => {
      if (observer) observer.disconnect();
    };
  }, [
    isListLoading,
    hasMore,
    relatedReviewListDistinctData,
    selectedTagsArray,
    unselectedTagsArray,
    isSelectActive
  ]);

  // infinite scroll end

  return (
    <div>
      {listDistinctData && (
        <Card
          textOneOne={listDistinctData.assignmentName}
          textTwoOne={listDistinctData.assignmentDescription}
          IconOne={CrossIcon}
          isIcon={true}
          labelTwoTwo={'distinct'}
          onClickIconOne={onClickReviseCancel}
          isAlliance
          relatedCardFixed={true}
          className={'iguru-iconboxSVG'}
        />
      )}

      {listDistinctData &&
        listDistinctData?.assessee.map((item, index) => {
          return (
            <div className="containerPadding" key={index} ref={elementRef}>
              <ReviewList
                className=""
                id={index}
                tag={item?.assesseeId || item?.id}
                associateId={item?.associateId}
                isSelectedReviewList={middlePaneSelectedValue === (item?.assesseeId || item?.id)}
                status={
                  item?.assignmentData?.assesseeAssignmentStatus &&
                    item?.assignmentData?.assesseeAssignmentStatus === 'UNFINISHED'
                    ? 'TIMEOUT'
                    : item?.assignmentData?.assesseeAssignmentStatus || item?.informationEngagement?.assesseeStatus
                }
                actualStatus={
                  item?.assignmentData?.assesseeAssignmentStatus &&
                    item?.assignmentData?.assesseeAssignmentStatus === 'UNFINISHED'
                    ? 'TIMEOUT'
                    : item?.assignmentData?.assesseeAssignmentStatus || item?.informationEngagement?.assesseeStatus
                }
                textOne={
                  item?.informationBasic?.assesseeNameFirst +
                  ' ' +
                  item?.informationBasic?.assesseeNameOther +
                  ' ' +
                  item?.informationBasic?.assesseeNameLast +
                  ' ' +
                  item?.informationBasic?.assesseeNameSuffix
                }
                textTwo={item?.informationBasic?.assesseeAlias}
                isTooltipActive={false}
                isShared={item?.assesseeShared}
                onClickEvent={openListPopup}
                isSelectActive={isSelectActive}
                isSelected={selectedTagsArray.includes(item?.assesseeId || item?.id)}
                onClickCheckBox={(event) => {
                  !isListLoading &&
                    onClickCheckBoxOneListforAssignmentAssessee(
                      selectedTagsArray,
                      responseObject?.assesseeAssignmentDistinctId,
                      event,
                      dispatch
                    );
                }}
              />
            </div>
          );
        })}
      {isListLoading ? (
        <div>
          {' '}
          <ListLoader />{' '}
        </div>
      ) : <div style={{ height: "60px", width: "100%" }}></div>}
      {FilterMode === 'assignmentDistinctAssesseeRevise' && (
        <FooterIconTwo
          FilterModeEnable={isShowReviseIcon}
          FilterMode={FilterMode}
          onClick={onClickRevise}
          primaryIcon={revisePrimaryIcon}
          secondaryIcon={reviseSecondaryIcons}
        />
      )}
      {/* {FilterMode === 'assesseeAssignmentAssessmentactive' && (
        <FooterIconTwo
          FilterModeEnable={isShowReviseIcon}
          FilterMode={FilterMode}
          onClick={onClickRevise}
          primaryIcon={primaryIcon}
          secondaryIcon={secondaryIcon}
        />
      )} */}
      {(FilterMode === 'assignmentDistinctAssesseeDistinctactive' ||
        FilterMode === 'assignmentDistinctAssesseeDistinctinactive') && (
          <FooterIconTwo
            FilterModeEnable={FilterModeEnable}
            FilterMode={FilterMode}
            onClick={onClickFooter}
            primaryIcon={primaryIcon}
            secondaryIcon={secondaryIcon}
          />
        )}
      {(FilterMode === 'assesseeAssignmentunstarted' ||
        FilterMode === 'assesseeAssignmentstarted' ||
        FilterMode === 'assesseeAssignmentsuspended' ||
        FilterMode === 'assesseeAssignmentrteminated' ||
        FilterMode === 'assesseeAssignmentaborted' ||
        FilterMode === 'assesseeAssignmentfinished' ||
        FilterMode === 'assesseeAssignmentunfinished') && (
          <FooterIconTwo
            FilterModeEnable={FilterModeEnable}
            FilterMode={FilterMode}
            onClick={onClickFooter}
            primaryIcon={primaryIcon}
            secondaryIcon={secondaryIconOne}
          />
        )}
    </div>
  );
};
export default AssignmentDistinctAssesseeDistinctReviewList;
