import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GET_ASSESSEE_REPORT_SAGA } from '../../actionType';
import PopUpTextEditor from '../../PopUpIcon/PopUpTextEditor';
import PopUpTextSheet from '../../PopUpIcon/PopUpTextSheet';

const PopUpAssesseeReport = (props) => {
  const { headerOne } = props;
  const { isPopUpValue, selectedTagValue } = useSelector((state) => state.PopUpReducer);
  const { reviewMode, responseObject } = useSelector((state) => state.DisplayPaneThreeReducer);
  const { selectedAssociateInfo } = useSelector((state) => state.DisplayPaneTwoReducer);
  const dispatch = useDispatch();
  const onClickSaveFeedBack = (data) => {
    dispatch({
      type: GET_ASSESSEE_REPORT_SAGA,
      payload: {
        righpaneheaderOne: 'analytics',
        request: {
          module: 'Assessee-Assignment',
          action: 'Result',
          requestObject: {
            action: 'revise',
            assesseeId: selectedAssociateInfo?.assesseeId,
            associateId:
              selectedAssociateInfo?.associate?.informationEngagement.associateTag
                .associateTagPrimary,
            assesseeAssignmentDistinct: {
              assesseeId: responseObject?.assessee?.id,
              id: responseObject?.assesseeAssignment?.id,
              assesseeAssignmentFeedback: data
            }
          }
        }
      }
    });
  };
  return (
    <div>
      {isPopUpValue === 'REVISE_ASSESSEE_FEEDBACK' && (
        <PopUpTextEditor
          isActive={true}
          headerOne={'assessment'}
          headerPanelColour={'genericOne'}
          headerOneBadgeOne={'report'}
          headerOneBadgeTwo={''}
          defaultSheetValue={responseObject?.assesseeAssignment?.assesseeAssignmentFeedback}
          onClickSave={(data) => {
            onClickSaveFeedBack(data);
          }}
          actualLableValue={'assesseeAssignmentFeedback'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
    </div>
  );
};

export default PopUpAssesseeReport;
