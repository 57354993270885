import { put, takeLatest, call } from 'redux-saga/effects';
import {
  GET_CULTURE_GROUP_REVIEW_INFO_SAGA,
  CULTURE_GROUP_REVISE_INFO_SAGA,
  LOADER_STOP,
  SET_ASSESSEE_GROUP_ASSESSEE_ID_LIST,
  SET_DISPLAY_PANE_THREE_STATE,
  SET_UNSELECTED_ASSESSEE_GROUP_ASSESSEE_ID_LIST,
  SET_CULTURE_GROUP_REDUCER_STATE,
  CULTURE_GROUP_CULTURE_REVIEWLIST_SAGA,
  SET_POPUP_VALUE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  GET_CULTUREPROFILE_GROUP_REVIEW_LIST_SAGA,
  SET_GROUP_SETUP_STATE,
  SET_DISPLAY_THREE_SINGLE_STATE,
  SET_GROUP_ALLOCATION_GROUP_ASCENDANT_STATE,
  SET_GROUP_ALLOCATION_STATE
} from '../../actionType';
import { CULTURE_MODULE_URL } from '../../endpoints';
import { EXCEPTION_ERROR_MESSAGE } from '../../errorMessage';
import Store from '../../store';

const cultureProfileGroupInfoApi = async (requestObj) => {
  console.log(requestObj.data);
  let URL = CULTURE_MODULE_URL;
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      Authorization: localStorage.getItem('idToken')
    }),
    body: JSON.stringify(requestObj.data)
  };
  const response = await fetch(URL, requestOptions);
  const json = await response.json();
  return json;
};

function* workerReviewCultureProfileGroupInfoSaga(data) {
  try {
    const userResponse = yield call(cultureProfileGroupInfoApi, {
      data: data.payload.reqBody
    });
    if (userResponse.responseCode === '000') {
      const {
        isReviseMode = false,
        cultureGroupCultureReqBody = null,
        createMode = ''
      } = data.payload;
      if (cultureGroupCultureReqBody !== null) {
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
        yield put({
          type: CULTURE_GROUP_CULTURE_REVIEWLIST_SAGA,
          payload: {
            request: cultureGroupCultureReqBody,
            HeaderOne: 'culture-profiles',
            BadgeOne: '',
            BadgeTwo: '',
            BadgeThree: '',
            isMiddlePaneList: false
          }
        });
      }
      yield put({
        type: SET_DISPLAY_PANE_THREE_STATE,
        payload: {
          headerOne: 'culture-profiles',
          headerOneBadgeOne: 'group',
          headerOneBadgeTwo: 'information',
          headerOneBadgeThree: 'key',
          createMode: createMode,
          responseObject: userResponse.responseObject[0],
          reviewMode: isReviseMode ? 'revise' : ''
        }
      });
      if (isReviseMode) {
        yield put({
          type: SET_CULTURE_GROUP_REDUCER_STATE,
          payload: userResponse.responseObject[0].informationBasic
        });
        yield put({
          type: SET_GROUP_SETUP_STATE,
          payload: {
            objectName: 'cultureProfileGroup',
            value: userResponse.responseObject[0].informationSetup
          }
        });
        let tempArr = [];
        if (
          userResponse.responseObject[0].informationAllocation &&
          userResponse.responseObject[0].informationAllocation?.cultureProfileGroupAscendant
            ?.cultureProfileGroupAscendantPrimary &&
          userResponse.responseObject[0].informationAllocation?.cultureProfileGroupAscendant
            ?.cultureProfileGroupAscendantPrimary?.length > 0
        ) {
          tempArr =
            userResponse.responseObject[0].informationAllocation?.cultureProfileGroupAscendant?.cultureProfileGroupAscendantPrimary.map(
              (ob) => ob.id
            );
        }
        yield put({
          type: SET_GROUP_ALLOCATION_GROUP_ASCENDANT_STATE,
          payload: {
            objectName: 'cultureProfileGroup',
            stateName: 'cultureProfileGroupAscendantPrimary',
            value: tempArr
          }
        });
        if (
          userResponse.responseObject[0].informationAllocation &&
          userResponse.responseObject[0].informationAllocation?.cultureProfileGroupNode
            ?.cultureProfileGroupNodePrimary &&
          userResponse.responseObject[0].informationAllocation?.cultureProfileGroupNode
            ?.cultureProfileGroupNodePrimary?.length > 0
        ) {
          let tempArr =
            userResponse.responseObject[0].informationAllocation?.cultureProfileGroupNode?.cultureProfileGroupNodePrimary.map(
              (ob) => ob.id
            );
          yield put({
            type: SET_GROUP_ALLOCATION_STATE,
            payload: {
              objectName: 'cultureProfileGroup',
              stateName: 'cultureProfileGroupNode',
              actualStateName: 'cultureProfileGroupNodePrimary',
              value: tempArr
            }
          });
        }
        if (
          userResponse.responseObject[0].informationAllocation &&
          userResponse.responseObject[0].informationAllocation?.cultureProfileGroupNode
            ?.cultureProfileGroupNodeSecondary &&
          userResponse.responseObject[0].informationAllocation?.cultureProfileGroupNode
            ?.cultureProfileGroupNodeSecondary?.length > 0
        ) {
          let tempArr =
            userResponse.responseObject[0].informationAllocation?.cultureProfileGroupNode?.cultureProfileGroupNodeSecondary.map(
              (ob) => ob.id
            );
          yield put({
            type: SET_GROUP_ALLOCATION_STATE,
            payload: {
              objectName: 'cultureProfileGroup',
              stateName: 'cultureProfileGroupNode',
              actualStateName: 'cultureProfileGroupNodeSecondary',
              value: tempArr
            }
          });
        }

        if (
          userResponse.responseObject[0].informationAllocation &&
          userResponse.responseObject[0].informationAllocation?.cultureProfileGroupType
            ?.cultureProfileGroupTypeSecondary &&
          userResponse.responseObject[0].informationAllocation?.cultureProfileGroupType
            ?.cultureProfileGroupTypeSecondary?.length > 0
        ) {
          let tempArr =
            userResponse.responseObject[0].informationAllocation?.cultureProfileGroupType?.cultureProfileGroupTypeSecondary.map(
              (ob) => ob.id
            );
          yield put({
            type: SET_GROUP_ALLOCATION_STATE,
            payload: {
              objectName: 'cultureProfileGroup',
              stateName: 'cultureProfileGroupType',
              actualStateName: 'cultureProfileGroupTypeSecondary',
              value: tempArr
            }
          });
        }

      }
    } else {
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: userResponse.responseMessage,
          popupMode: 'responseErrorMsg'
        }
      });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
    yield put({ type: LOADER_STOP });
  }
}

function* workerReviseCultureProfileGroupInfoSaga(data) {
  try {
    const userResponse = yield call(cultureProfileGroupInfoApi, {
      data: data.payload.reqBody
    });
    if (userResponse.responseCode === '000') {
      const {
        createMode = '',
        cardValue = 'noCard',
        cultureGroupCultureReqBody = null,
        isReviseCallFromPaneTwo = false
      } = data.payload;
      if (cultureGroupCultureReqBody !== null) {
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
        yield put({
          type: CULTURE_GROUP_CULTURE_REVIEWLIST_SAGA,
          payload: {
            request: cultureGroupCultureReqBody,
            HeaderOne: 'culture-profiles',
            BadgeOne: '',
            BadgeTwo: '',
            BadgeThree: '',
            isMiddlePaneList: false
          }
        });
        if (isReviseCallFromPaneTwo) {
          yield put({
            type: SET_DISPLAY_THREE_SINGLE_STATE,
            payload: {
              stateName: 'isFrameworkOpen',
              value: 'cultureProfileGroup'
            }
          });
          // yield put({
          //   type: SET_DISPLAY_THREE_SINGLE_STATE,
          //   payload: { stateName: "isFrameworkOpen", value: "cultureProfileGroup" },
          // });
        } else {
          yield put({
            type: SET_DISPLAY_THREE_SINGLE_STATE,
            payload: { stateName: 'isFrameworkOpen', value: '' }
          });
        }
        let tempArr = [];
        Store.getState().DisplayPaneTwoReducer.middlePaneListPopupOptions.forEach((element) => {
          if (
            element.data === 'share' &&
            userResponse.responseObject[0].informationSetup?.cultureProfileGroupClassification
              ?.cultureProfileGroupClassificationPrimary === 'Bespoke'
          ) {
            tempArr.push({ ...element, disabled: true });
          } else if (
            element.data === 'share' &&
            userResponse.responseObject[0].informationSetup?.cultureProfileGroupClassification
              ?.cultureProfileGroupClassificationPrimary === 'Generic'
          ) {
            tempArr.push({ ...element, disabled: false });
          } else tempArr.push(element);
        });
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'middlePaneListPopupOptions',
            value: tempArr
          }
        });
        yield put({
          type: SET_DISPLAY_PANE_THREE_STATE,
          payload: {
            headerOne: 'culture-profiles',
            headerOneBadgeOne: 'group',
            headerOneBadgeTwo: 'information',
            headerOneBadgeThree: 'key',
            responseObject: userResponse.responseObject[0],
            createMode,
            reviewMode: isReviseCallFromPaneTwo ? 'revise' : ''
          }
        });
      }
      if (createMode === '') {
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'reviewListDistinctData', value: [] }
        });
        yield put({
          type: GET_CULTUREPROFILE_GROUP_REVIEW_LIST_SAGA,
          payload: {
            HeaderOne: 'culture-profiles',
            request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
            BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
            isMiddlePaneList: true,
            cardValue
          }
        });
        yield put({ type: SET_ASSESSEE_GROUP_ASSESSEE_ID_LIST, payload: [] });
        yield put({
          type: SET_UNSELECTED_ASSESSEE_GROUP_ASSESSEE_ID_LIST,
          payload: []
        });
      } else {
        yield put({ type: LOADER_STOP });
      }
    } else {
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: userResponse.responseMessage,
          popupMode: 'responseErrorMsg'
        }
      });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: EXCEPTION_ERROR_MESSAGE,
        popupMode: 'responseErrorMsg'
      }
    });
    yield put({ type: LOADER_STOP });
  }
}

export default function* watchReviewCultureProfileGroupInfoSaga() {
  yield takeLatest(GET_CULTURE_GROUP_REVIEW_INFO_SAGA, workerReviewCultureProfileGroupInfoSaga);
  yield takeLatest(CULTURE_GROUP_REVISE_INFO_SAGA, workerReviseCultureProfileGroupInfoSaga);
}
