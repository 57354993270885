import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ASSESSEE_INFO_CREATE,
  ASSESSMENT_ALLOCATE_SAGA,
  ASSOCIATE_POPUP_CLOSE,
  CLEAR_DISPLAY_PANE_THREE,
  FILTERMODE,
  FILTERMODE_ENABLE,
  GET_ITEM_GROUP_REVIEW_LIST_SAGA,
  ITEM_ALLOCATE_ASSESSMENT_SAGA,
  ITEM_ALLOCATE_SAGA,
  LOADER_START,
  POPUP_OPEN,
  SECTION_POPUP_CLOSE,
  SET_DISPLAY_PANE_THREE_STATE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_PAGE_COUNT,
  SET_POPUP_SINGLE_STATE,
  SET_POPUP_STATE,
  SET_REQUEST_OBJECT
} from '../actionType';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import { FilterList } from '@material-ui/icons';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import {
  ASSESSEE_GROUP_REVIEW_LIST_POPUP_OPTION,
  GROUP_REVIEW_LIST_POPUP_OPTION
} from '../PopUpConfig';
import { getItemGroupDistinctApiCall } from '../Actions/ItemModuleAction';
import {
  onClickCheckBoxSelection,
  onClickFlagSelection,
  setFlagedArray
} from '../Actions/AssesseeModuleAction';
import ReviseIcon from '@material-ui/icons/RadioButtonChecked';
import Check from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { getPopUpOptionList, makeItemGroupObj } from '../Actions/GenericActions';
import { AccountTree } from '@material-ui/icons';
import ListIcon from '@material-ui/icons/FormatListBulleted';
import FileExplorerTheme from 'react-sortable-tree-theme-full-node-drag';
import SortableTree from 'react-sortable-tree';
import ListLoader from '../Atoms/ListLoader/ListLoader';

const ItemGroupReviewList = (props) => {
  const dispatch = useDispatch();
  // const {  } = useSelector((state) => state.AssesseeCreateReducer);
  const {
    numberPage,
    scanCount,
    countPage,
    reviewListDistinctData,
    reviewListReqObj,
    middlePaneSelectedValue,
    selectedAssociateInfo,
    middlePaneHeader,
    allocatedTagsArray,
    selectedTagsArray,
    isSelectActive,
    unselectedTagsArray,
    allocateStr,
    nodeViewState,
    scanString,
    searchFocusIndex,
    middlePaneHeaderBadgeOne,
    middlePaneHeaderBadgeTwo,
    middlePaneHeaderBadgeThree,
    selectedFlagedArray,
    unselectedFlagedArray,
    flagedValue,
    signedAssesseePermission,
    assignmentCreateType,
    isListLoading
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { FilterModeEnable, FilterMode } = useSelector((state) => state.FilterReducer);
  const { cardValue } = useSelector((state) => state.PopUpReducer);
  // const [isFetching, setIsFetching] = useState(false);
  const [filterState, setFilterState] = useState('');
  // useEffect(() => {
  //   document.getElementById('middleComponentId').addEventListener('scroll', handleScroll);
  // }, []);
  // const handleScroll = (event) => {
  //   var targetPt = event.target;
  //   if (
  //     Math.ceil(targetPt.scrollHeight - targetPt.scrollTop) !== targetPt.clientHeight ||
  //     isFetching
  //   )
  //     return;
  //   setIsFetching(true);
  //   console.log(isFetching);
  // };
  const fetchData = async () => {
    if (reviewListDistinctData.length < scanCount && nodeViewState === 'list') {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isListLoading', value: true }
      })
      let obj = {
        module: 'Item-Group',
        action: 'Review-List',
        requestObject: {
          ...reviewListReqObj.requestObject,
          numberPage: numberPage + 1
        }
      };
      dispatch({
        type: GET_ITEM_GROUP_REVIEW_LIST_SAGA,
        payload: {
          request: obj,
          BadgeOne: middlePaneHeaderBadgeOne,
          BadgeTwo: middlePaneHeaderBadgeTwo,
          BadgeThree: middlePaneHeaderBadgeThree,
          isMiddlePaneList: true,
          cardValue: cardValue,
          isSelectActive: isSelectActive,
          selectedTagsArray: selectedTagsArray
        }
      });
      dispatch({ type: SET_PAGE_COUNT, payload: numberPage + 1 });
    }
  };
  // useEffect(() => {
  //   console.log(reviewListDistinctData);
  //   if (!isFetching) return;
  //   fetchMoreListItems();
  // }, [isFetching]);

  // const fetchMoreListItems = () => {
  //   fetchData();
  //   setIsFetching(false);
  // };
  useEffect(() => {
    if (nodeViewState !== 'hierarchy')
      setFlagedArray(reviewListDistinctData, 'itemGroupFlag', dispatch);
  }, [reviewListDistinctData]);

  const siftApiCall = (siftKey) => {
    //  getItemGroupDistinctApiCall(selectedAssociateInfo, siftKey, countPage, dispatch, 'groups');
    dispatch({ type: ASSOCIATE_POPUP_CLOSE });
    document.getElementById('middleComponentId').scrollTop = '0px';
  };

  //resetdata function call

  const resetDataFunction = () => {
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'allocateStr', value: '' }
    });
    // document.getElementById('middleComponentId').scrollTop = '0px';
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: { stateName: 'cardValue', value: 'NoCard' }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'typeOfMiddlePaneList', value: '' }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'middlePaneSelectedValue', value: '' }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'selectedFlagedArray', value: [] }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'unselectedFlagedArray', value: [] }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'selectedTagsArray', value: [] }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'unselectedTagsArray', value: [] }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'flagedValue', value: '' }
    });
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: { stateName: 'selectionValue', value: '' }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'scanString', value: '' }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'allocatedTagsArray', value: [] }
    });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
    dispatch({ type: ASSESSEE_INFO_CREATE });
    dispatch({ type: SECTION_POPUP_CLOSE });
  };
  //

  const siftApiFilterCall = (siftKey, requestObect) => {
    dispatch({ type: LOADER_START });
    dispatch({
      type: FILTERMODE,
      payload: { FilterMode: 'itemGroupDistinct' + siftKey }
    });
    dispatch({
      type: GET_ITEM_GROUP_REVIEW_LIST_SAGA,
      payload: {
        request: requestObect,
        BadgeOne: middlePaneHeaderBadgeOne,
        BadgeTwo: middlePaneHeaderBadgeTwo === 'distinct' ? middlePaneHeaderBadgeTwo : siftKey,
        BadgeThree: middlePaneHeaderBadgeTwo === 'distinct' ? siftKey : middlePaneHeaderBadgeThree,
        isMiddlePaneList: true,
        isSelectActive: isSelectActive,
        cardValue: cardValue,
        selectedTagsArray: selectedTagsArray
      }
    });
    dispatch({
      type: SET_DISPLAY_PANE_THREE_STATE,
      payload: {
        isReviewRevise: false,
        headerOne: '',
        headerOneBadgeOne: '',
        headerOneBadgeTwo: '',
        headerOneBadgeThree: '',
        reviewMode: 'review'
      }
    });
    console.log('reviewListDistinctData>>>>', reviewListDistinctData);
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'typeOfMiddlePaneList', value: '' }
    });
    dispatch({ type: ASSOCIATE_POPUP_CLOSE });
    document.getElementById('middleComponentId').scrollTop = '0px';
  };
  const onClickFooter = (e) => {
    let siftValue = e.currentTarget.getAttribute('data-value');
    dispatch({ type: FILTERMODE_ENABLE });
    setFilterState(siftValue);
    if (
      (siftValue === 'suspended' ||
        siftValue === 'terminated' ||
        siftValue === 'bespoke' ||
        siftValue === 'generic') &&
      nodeViewState === 'list'
    ) {
      let requestObect = makeItemGroupObj(selectedAssociateInfo, siftValue, countPage, 0, 'list', assignmentCreateType);
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
        dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'scanString', value: '' }
        });
      siftApiFilterCall(siftValue, requestObect);
    }
    if ((siftValue === 'bespoke' || siftValue === 'generic') && nodeViewState === 'hierarchy') {
      let requestObect = makeItemGroupObj(
        selectedAssociateInfo,
        siftValue,
        countPage,
        0,
        'hierarchy',
        assignmentCreateType
      );
      dispatch({ type: SET_PAGE_COUNT, payload: 0 });
      dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'scanString', value: '' }
      });
      siftApiFilterCall(siftValue, requestObect);
    }

    if (siftValue === 'list' || siftValue === 'hierarchy') {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'scanString', value: '' }
      });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'nodeViewState', value: siftValue }
      });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'typeOfMiddlePaneList', value: '' }
      });
      //resetDataFunction();
      let badgeTwo =
        middlePaneHeaderBadgeTwo === 'generic' || middlePaneHeaderBadgeTwo === 'bespoke'
          ? 'active'
          : middlePaneHeaderBadgeTwo;
      if (cardValue === 'Card') {
        badgeTwo =
          middlePaneHeaderBadgeThree === 'generic' || middlePaneHeaderBadgeThree === 'bespoke'
            ? 'active'
            : middlePaneHeaderBadgeThree;
      }
      dispatch({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'selectionValue', value: '' }
      });
      getItemGroupDistinctApiCall(
        selectedAssociateInfo,
        badgeTwo,
        countPage,
        dispatch,
        'groups',
        cardValue,
        '',
        siftValue,
        [],
        false,
        assignmentCreateType
      );
    }

    if (siftValue === 'finish') {
      console.log('allocateStr', allocateStr);
      let distinctAllocateStr =
        allocateStr === 'itemsdistinct'
          ? 'itemDistinct'
          : allocateStr === 'itemsgroups'
            ? 'itemGroup'
            : allocateStr === 'itemsnodes'
              ? 'itemNode'
              : allocateStr === 'itemstypes'
                ? 'itemType'
                : allocateStr === 'assessmentsdistinct'
                  ? 'assessmentDistinct'
                  : allocateStr === 'assessmentsgroups'
                    ? 'assessmentGroup'
                    : allocateStr === 'assessmentstypes'
                      ? 'assessmentType'
                      : allocateStr === 'assessmentsnodes'
                        ? 'assessmentNode'
                        : '';
      if (distinctAllocateStr !== '' && selectedTagsArray.length !== 0) {
        // if (distinctAllocateStr === 'itemDistinct') {
        //   let request = {
        //     assesseeId: selectedAssociateInfo?.assesseeId,
        //     associateId:
        //       selectedAssociateInfo?.associate?.informationEngagement.associateTag
        //         .associateTagPrimary,
        //     itemDistinctAllocate: allocatedTagsArray,
        //     itemDistinctAllocateInformation: {
        //       itemGroup: selectedTagsArray
        //     }
        //   };
        //   console.log(request);
        //   dispatch({ type: LOADER_START });
        //   dispatch({ type: ITEM_ALLOCATE_SAGA, payload: { request: request } });
        //   dispatch({
        //     type: FILTERMODE,
        //     payload: { FilterMode: '' }
        //   });
        // }
        if (
          distinctAllocateStr === 'itemGroup' ||
          distinctAllocateStr === 'itemType' ||
          distinctAllocateStr === 'itemDistinct' ||
          distinctAllocateStr === 'itemNode'
        ) {
          let request = {
            module: 'Item-Distinct',
            action: FilterMode.toLowerCase().includes('unallocate') ? 'Unallocate' : 'Allocate',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              itemDistinctAllocate: {
                [distinctAllocateStr]: allocatedTagsArray
              },
              itemDistinctAllocateInformation: {
                itemGroup: selectedTagsArray
              }
            }
          };
          dispatch({ type: LOADER_START });
          dispatch({ type: ITEM_ALLOCATE_SAGA, payload: { request: request } });
          // dispatch({
          //   type: FILTERMODE,
          //   payload: { FilterMode: '' }
          // });
        }
        if (
          distinctAllocateStr === 'assessmentDistinct' ||
          distinctAllocateStr === 'assessmentGroup' ||
          distinctAllocateStr === 'assessmentType' ||
          distinctAllocateStr === 'assessmentNode'
        ) {
          // let request = {
          //   module: 'Assessment-Distinct',
          //   action: FilterMode.toLowerCase().includes('unallocate') ? 'Unallocate' : 'Allocate',
          //   actionFilter: 'Allocate-Item-In-Item',
          //   requestObject: {
          //     assesseeId: selectedAssociateInfo?.assesseeId,
          //     associateId:
          //       selectedAssociateInfo?.associate?.informationEngagement.associateTag
          //         .associateTagPrimary,
          //     itemDistinctAllocate: { itemGroup: selectedTagsArray },
          //     itemDistinctAllocateInformation: {
          //       [distinctAllocateStr]: allocatedTagsArray
          //     }
          //   }
          // };
          let request = {
            module: 'Assessment-Distinct',
            action: FilterMode.toLowerCase().includes('unallocate') ? 'Unallocate' : 'Allocate',
            actionFilter: 'Item-In-Assessment',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              [FilterMode?.toLowerCase().includes('unallocate')
                ? 'assessmentDistinctUnallocate'
                : 'assessmentDistinctAllocate']: { [distinctAllocateStr]: allocatedTagsArray },
              [FilterMode?.toLowerCase().includes('unallocate')
                ? 'assessmentDistinctUnallocateInformation'
                : 'assessmentDistinctAllocateInformation']: {
                itemGroup: selectedTagsArray
              }
            }
          };
          dispatch({ type: LOADER_START });
          dispatch({ type: ASSESSMENT_ALLOCATE_SAGA, payload: { request: request } });
          dispatch({
            type: FILTERMODE,
            payload: { FilterMode: '' }
          });
        }
      }
    }
    if (siftValue === 'cancel') {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isSelectActive', value: '' }
      });
      dispatch({
        type: FILTERMODE,
        payload: { FilterMode: '' }
      });
    }
  };
  /* for middle pane */
  const primaryIconOne = [
    { label: 'sift', onClick: onClickFooter, Icon: FilterList },
    { label: 'view', onClick: onClickFooter, Icon: FilterList }
  ];
  const primaryIcon = [{ label: 'sift', onClick: onClickFooter, Icon: FilterList }];

  const secondaryIcon = [
    { label: 'hierarchy', onClick: onClickFooter, Icon: AccountTree },
    { label: 'list', onClick: onClickFooter, Icon: ListIcon }
  ];

  const secondaryIconOne = [
    { label: 'suspended', onClick: onClickFooter, Icon: FilterList },
    { label: 'terminated', onClick: onClickFooter, Icon: FilterList }
  ];

  const secondaryOneIconSift = [
    { label: 'bespoke', onClick: onClickFooter, Icon: FilterList },
    { label: 'generic', onClick: onClickFooter, Icon: FilterList }
  ];
  // const secondaryOneIcon = [
  //   { label: 'bespoke', onClick: onClickFooter, Icon: FilterList },
  //   { label: 'generic', onClick: onClickFooter, Icon: FilterList },
  //   // { label: 'shared', onClick: onClickFooter, Icon: FilterList },
  //   // { label: 'unshared', onClick: onClickFooter, Icon: FilterList }
  // ];

  /* for middle pane */
  //  const primaryIcon = [{ label: 'view', onClick: onClickFooter, Icon: FilterList }];

  // const secondaryIconOne = [
  //   { label: 'suspended', onClick: onClickFooter, Icon: FilterList },
  //   { label: 'terminated', onClick: onClickFooter, Icon: FilterList }
  // ];

  /* for middle pane */
  // const primaryIconOne = [
  //   { label: 'view', onClick: onClickFooter, Icon: FilterList },
  //   { label: 'sift', onClick: onClickFooter, Icon: FilterList }
  // ];
  // const secondaryIcon = [
  //   { label: 'hierarchy', onClick: onClickFooter, Icon: AccountTree },
  //   { label: 'list', onClick: onClickFooter, Icon: ListIcon }
  // ];

  let signedAssociateId =
    selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary;

  const openListPopup = (node, e, target, canUpdate) => {
    console.log(e.currentTarget.getAttribute('tag'));
    let classification = e.currentTarget.getAttribute('data-classification');
    let isShared = e.currentTarget.getAttribute('data-shared');
    let associateId = e.currentTarget.getAttribute('associateid');
    let status = e.currentTarget.getAttribute('status');
    let groupId = e.currentTarget.getAttribute('tag');
    let flaged = e.currentTarget.getAttribute('data-flag') === 'true' && true;
    console.log('node', node);
    if (target === 'hirarchy') {
      groupId = node.node.id;
      status = node.node.status;
      flaged = node.node.flag;
      isShared = node.node.itemGroupShared;
      classification = node.node.classification;
      associateId = node.node.associateId;
      flaged = node.node.flag;
    }
    let optArr = [];
    let tempArr = [];
    let popupContentArrValue = ASSESSEE_GROUP_REVIEW_LIST_POPUP_OPTION.map((obj) =>
      obj.data === 'assessees'
        ? { ...obj, data: middlePaneHeader, dataValue: middlePaneHeader }
        : obj
    );
    let itemDistictPermssion =
      signedAssesseePermission?.itemDistictPermssion?.assesseeItemGroupPermission;
    let popupContentArr = getPopUpOptionList(
      cardValue === 'Card' ? GROUP_REVIEW_LIST_POPUP_OPTION : popupContentArrValue,
      itemDistictPermssion
    );
    popupContentArr.map((element) => {
      if (classification === 'Bespoke' && element.data === 'share' && status === 'ACTIVE')
        tempArr.push({ ...element, disabled: true });
      else if (
        (isShared === 'true' || isShared) &&
        associateId !== signedAssociateId &&
        (element.data === 'archive' ||
          element.data === 'delete' ||
          element.data === 'flag' ||
          element.data === 'publish' ||
          element.data === 'suspend' ||
          element.data === 'terminate')
      ) {
        tempArr.push({ ...element, disabled: true });
      } else if (
        (status === 'TERMINATED' || status === 'SUSPENDED' || status === 'ARCHIVED') &&
        (element.data === 'allocate' ||
          element.data === 'revise' ||
          element.data === 'select' ||
          element.data === 'share')
      ) {
        tempArr.push({ ...element, disabled: true });
      } else tempArr.push(element);
    });
    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: middlePaneHeader,
        popupHeaderOneBadgeOne: 'group',
        duplicateHeaderOneBadgeTwo: middlePaneHeaderBadgeTwo,
        popupHeaderOneBadgeTwo: '',
        isPopUpValue: '',
        popupOpenType: 'primary',
        popupContentArrValue: tempArr, //tempArr, //optArr,
        originPrimaryArrOprion: tempArr,
        isShared: isShared,
        selectedTagValue: groupId, //e.currentTarget.getAttribute('tag'),
        selectedTagStatus: status, //e.currentTarget.getAttribute('status'),
        selectedTagGroupId: e.currentTarget.getAttribute('data-value'),
        isFlaged: flaged === 'true' ? true : false, //e.currentTarget.getAttribute('data-flag') === 'true' ? true : false
        selectedTagInformationSetup: JSON.parse(e.currentTarget.getAttribute('informationsetup')),
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: tempArr
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
  };
  console.log('FilterMode', FilterMode);
  const changedNode = (node) => {
    console.log(node.id);
    let dragedNodeId = node.id;
    let dragedNodeParentId = '';
    reviewListDistinctData.map((nodeData) => {
      console.log('nodeData', nodeData);
      nodeData.children.map((chnode) => {
        console.log(chnode.id);
        if (dragedNodeId === chnode.id) {
          dragedNodeParentId = nodeData.id;
        }
      });
    });
  };
  const customSearchMethod = ({ node, searchQuery }) =>
    searchQuery &&
    (node?.title?.toLowerCase().indexOf(searchQuery?.toLowerCase()) > -1 ||
      node?.subtitle?.toLowerCase().indexOf(searchQuery?.toLowerCase()) > -1);

  // infinite scroll
  const [hasMore, setHasMore] = useState(true);
  let elementRef = useRef(null);

  const onIntersection = (entries) => {
    const firstEntry = entries[0];
    reviewListDistinctData.length >= scanCount ? setHasMore(false) : setHasMore(true)
    if (firstEntry.isIntersecting && hasMore && !isListLoading) {
      fetchData();
    }
  };
  // console.log(reviewListDistinctData.length, scanCount , '##########')
  useEffect(() => {
    // console.log('####MYREF######', elementRef)
    if (nodeViewState !== 'hierarchy') {
      const observer = new IntersectionObserver(onIntersection, { threshold: 0.90 });

      if (observer && elementRef.current) {
        // console.log('here at observer...', elementRef.current)
        observer.observe(elementRef.current);
      }
      // console.log(reviewListDistinctData.length, scanCount ,'##########')
      return () => {
        if (observer) observer.disconnect();
      };
    }
  }, [isListLoading, hasMore, reviewListDistinctData, isSelectActive, selectedTagsArray, unselectedTagsArray]);

  // infinite scroll end

  return (
    <div>
      {reviewListDistinctData && nodeViewState === 'hierarchy' ? (
        <div style={{ minheight: 'calc(100vh - 135px)' }} key={scanString}>
          <SortableTree
            treeData={reviewListDistinctData}
            onChange={(treeData) => {
              treeData.length === 1 &&
                dispatch({
                  type: SET_DISPLAY_TWO_SINGLE_STATE,
                  payload: {
                    stateName: 'reviewListDistinctData',
                    value: treeData
                  }
                });
            }}
            searchMethod={customSearchMethod}
            searchQuery={scanString}
            searchFocusOffset={searchFocusIndex}
            canDrag={({ node }) => true && node.parentId !== null}
            onMoveNode={({ node }) => changedNode(node)}
            searchFinishCallback={(matches) => {
              console.log(matches);
              dispatch({
                type: SET_DISPLAY_TWO_SINGLE_STATE,
                payload: {
                  stateName: 'searchFocusIndex',
                  value: matches.length > 0 ? searchFocusIndex % matches.length : 0
                }
              });
            }}
            theme={FileExplorerTheme}
            isVirtualized={false}
            rowHeight={55}
            scaffoldBlockPxWidth={31}
            slideRegionSize={50}
            generateNodeProps={(node) => ({
              onClick: (event) => {
                if (event.target.type !== 'button') {
                  openListPopup(node, event, 'hirarchy', true);
                  //  openListHierarchyPopup(node,event, 'hierarchy', true);
                }
              }
            })}
          />
        </div>
      ) : (
        reviewListDistinctData &&
        reviewListDistinctData.map((item, index) => {
          return (
            <div
              className="containerPadding"
              key={index}
              ref={elementRef}
            >
              <ReviewList
                className=""
                id={index}
                tag={item.id}
                associateId={item?.associateId}
                isSelectedReviewList={middlePaneSelectedValue === item.id}
                informationSetup={item?.informationSetup}
                //status={item.informationEngagement.itemGroupStatus}
                status={
                  FilterMode === 'itemGroupDistinctactive' ||
                    FilterMode === 'itemGroupDistinctbespoke' ||
                    FilterMode === 'itemGroupDistinctgeneric' ||
                    FilterMode === 'itemGroupDistinctactivebespoke' ||
                    FilterMode === 'itemGroupDistinctactivegeneric'
                    ? item.informationSetup?.itemGroupClassification?.itemGroupClassificationPrimary
                    : item.informationEngagement.itemGroupStatus
                }
                actualStatus={item.informationEngagement.itemGroupStatus}
                textOne={item.informationBasic.itemGroupName}
                textTwo={item.informationBasic.itemGroupDescription}
                isTooltipActive={false}
                onClickEvent={(event) => {
                  openListPopup(item.id, event, 'list', true);
                }}
                // disableonClickCheckBox={item.associateId !== signedAssociateId ? true : false}
                //onClickEvent={openListPopup}
                isSelectActive={isSelectActive}
                isSelected={selectedTagsArray.includes(item.id)}
                onClickCheckBox={(event) => {
                  !isListLoading && onClickCheckBoxSelection(selectedTagsArray, unselectedTagsArray, event, dispatch);
                }}
                // dataValue={item.informationAllocation.itemGroup}
                isShared={item?.itemGroupShared}
                //shared={item.itemGroupShared ? 'SHARED' : 'UNSHARED'}
                shared={
                  item?.informationSetup?.itemGroupClassification?.itemGroupClassificationPrimary
                }
                isFlagActive={selectedFlagedArray.includes(item.id)}
                flagedValue={flagedValue}
                onClickAddFladed={(event) => {
                  onClickFlagSelection(selectedFlagedArray, unselectedFlagedArray, event, dispatch);
                }}
              />
            </div>
          );
        })
      )}
      {isListLoading ? <div> <ListLoader /> </div> : <div style={{ height: "60px", width: "100%" }}></div>}
      {(FilterMode === 'itemGroupDistinctinactive' ||
        FilterMode === 'itemGroupDistinctsuspended' ||
        FilterMode === 'itemGroupDistinctterminated') && (
          <FooterIconTwo
            FilterModeEnable={FilterModeEnable}
            FilterMode={FilterMode}
            onClick={onClickFooter}
            primaryIcon={primaryIcon}
            secondaryIcon={secondaryIconOne}
          />
        )}
      {(FilterMode === 'itemGroupDistinctactive' ||
        FilterMode === 'itemGroupDistinctactivebespoke' ||
        FilterMode === 'itemGroupDistinctactivegeneric' ||
        FilterMode === 'itemGroupDistinctbespoke' ||
        FilterMode === 'itemGroupDistinctgeneric') && (
          <FooterIconTwo
            FilterModeEnable={FilterModeEnable}
            FilterMode={FilterMode}
            onClick={onClickFooter}
            primaryIcon={primaryIconOne}
            secondaryIcon={filterState === 'sift' ? secondaryOneIconSift : secondaryIcon}
          />
        )}
      {(FilterMode === 'itemAllocateToGroup' || FilterMode === 'itemUnAllocateToGroup') && (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={[{ label: `${FilterMode === 'itemAllocateToGroup' ? 'allocate' : 'unallocate'}`, onClick: onClickFooter, Icon: ReviseIcon }]}
          secondaryIcon={[
            { label: 'cancel', onClick: onClickFooter, Icon: ClearIcon },
            { label: 'finish', onClick: onClickFooter, Icon: Check }
          ]}
        />
      )}
    </div>
  );
};
export default ItemGroupReviewList;
