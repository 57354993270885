import React from "react";
import { isMobile } from "react-device-detect";
import Manuscript from "@material-ui/icons/Description";
import { useDispatch, useSelector } from "react-redux";
import DisplayPanelAccordianReviewListOne from "../Accordian/DisplayPanelAccordianReviewListOne";
import DisplayPanelAccordianInformation from "../Accordian/DisplayPanelAccordianInformation";
import { Paper } from "@material-ui/core";
import {
  GET_ASSESSMENT_GROUP_REVIEW_LIST_SAGA,
  GET_ASSESSMENT_TYPE_REVIEW_LIST_SAGA,
  INTERNAL_NODE_LIST_WITH_PERMISSIONS_SAGA,
  LOADER_START,
  SET_CORE_GROUP_REVIEW_LIST_REQ_OBJECT,
  SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT,
  SET_CORE_TYPE_REVIEW_LIST_REQ_OBJECT,
  SET_POPUP_VALUE,
  SET_STATUS_POPUP_VALUE,
} from "../../actionType";
import DisplayPanelAccordianReviewListTwo from "../Accordian/DisplayPanelAccordianReviewListTwo";
import { makeAssessmentGroupObj, makeAssessmentTypeObj, makeInternalNodeObj } from "../../Actions/GenericActions";

const DisplayPaneThreeSectionOneAssessmentGroup = () => {
  // const [listExpand, setListExpand] = useState('');
  const { responseObject, reviewMode } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  const dispatch = useDispatch();
  const { selectedAssociateInfo, assignmentCreateType } = useSelector(
    (state) => state.DisplayPaneTwoReducer
  );
  const { informationEngagement, informationSetup, informationAllocation } =
    responseObject;
  //ascendant
  let ascendantAll = [];
  if (
    informationAllocation?.assessmentGroupAscendant
      ?.assessmentGroupAscendantAll &&
    informationAllocation?.assessmentGroupAscendant?.assessmentGroupAscendantAll
      .length > 0
  ) {
    const tempArr =
      informationAllocation?.assessmentGroupAscendant
        ?.assessmentGroupAscendantAll;
    tempArr.forEach((ob) => {
      ascendantAll.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.assessmentGroupName || "",
        textTwo: ob?.informationBasic?.assessmentGroupDescription || "",
        status: ob?.informationEngagement?.assessmentGroupStatus || "",
      });
    });
  }
  let ascendantPrimary = [];
  // check in information allocation
  if (
    informationAllocation?.assessmentGroupAscendant
      ?.assessmentGroupAscendantPrimary &&
    informationAllocation?.assessmentGroupAscendant
      ?.assessmentGroupAscendantPrimary.length > 0
  ) {
    const tempArr =
      informationAllocation?.assessmentGroupAscendant?.assessmentGroupAscendantPrimary.sort(
        (a, b) =>
          a.informationBasic?.assessmentGroupName.toLowerCase() !==
            b.informationBasic?.assessmentGroupName.toLowerCase()
            ? a.informationBasic?.assessmentGroupName.toLowerCase() <
              b.informationBasic?.assessmentGroupName.toLowerCase()
              ? -1
              : 1
            : 0
      );
    tempArr.forEach((ob) => {
      ascendantPrimary.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.assessmentGroupName || "",
        textTwo: ob?.informationBasic?.assessmentGroupDescription || "",
        status: ob?.informationEngagement?.assessmentGroupStatus || "",
      });
    });
  }
  let ascendantSecondary = [];
  if (
    informationAllocation?.assessmentGroupAscendant
      ?.assessmentGroupAscendantSecondary &&
    informationAllocation?.assessmentGroupAscendant
      ?.assessmentGroupAscendantSecondary.length > 0
  ) {
    const tempArr =
      informationAllocation?.assessmentGroupAscendant?.assessmentGroupAscendantSecondary.sort(
        (a, b) =>
          a.informationBasic?.assessmentGroupName.toLowerCase() !==
            b.informationBasic?.assessmentGroupName.toLowerCase()
            ? a.informationBasic?.assessmentGroupName.toLowerCase() <
              b.informationBasic?.assessmentGroupName.toLowerCase()
              ? -1
              : 1
            : 0
      );
    tempArr.forEach((ob) => {
      ascendantSecondary.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.assessmentGroupName || "",
        textTwo: ob?.informationBasic?.assessmentGroupDescription || "",
        status: ob?.informationEngagement?.assessmentGroupStatus || "",
      });
    });
  }
  //decendent
  let descendantAll = [];
  if (
    informationAllocation?.assessmentGroupDescendant
      ?.assessmentGroupDescendantAll &&
    informationAllocation?.assessmentGroupDescendant
      ?.assessmentGroupDescendantAll.length > 0
  ) {
    const tempArr =
      informationAllocation?.assessmentGroupDescendant?.assessmentGroupDescendantAll.sort(
        (a, b) =>
          a.informationBasic?.assessmentGroupName.toLowerCase() !==
            b.informationBasic?.assessmentGroupName.toLowerCase()
            ? a.informationBasic?.assessmentGroupName.toLowerCase() <
              b.informationBasic?.assessmentGroupName.toLowerCase()
              ? -1
              : 1
            : 0
      );
    tempArr.forEach((ob) => {
      descendantAll.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.assessmentGroupName || "",
        textTwo: ob?.informationBasic?.assessmentGroupDescription || "",
        status: ob?.informationEngagement?.assessmentGroupStatus || "",
      });
    });
  }
  let descendantPrimary = [];
  if (
    informationAllocation?.assessmentGroupDescendant
      ?.assessmentGroupDescendantPrimary &&
    informationAllocation?.assessmentGroupDescendant
      ?.assessmentGroupDescendantPrimary.length > 0
  ) {
    const tempArr =
      informationAllocation?.assessmentGroupDescendant?.assessmentGroupDescendantPrimary.sort(
        (a, b) =>
          a.informationBasic?.assessmentGroupName.toLowerCase() !==
            b.informationBasic?.assessmentGroupName.toLowerCase()
            ? a.informationBasic?.assessmentGroupName.toLowerCase() <
              b.informationBasic?.assessmentGroupName.toLowerCase()
              ? -1
              : 1
            : 0
      );
    tempArr.forEach((ob) => {
      descendantPrimary.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.assessmentGroupName || "",
        textTwo: ob?.informationBasic?.assessmentGroupDescription || "",
        status: ob?.informationEngagement?.assessmentGroupStatus || "",
      });
    });
  }

  let descendantSecondary = [];
  if (
    informationAllocation?.assessmentGroupDescendant
      ?.assessmentGroupDescendantSecondary &&
    informationAllocation?.assessmentGroupDescendant
      ?.assessmentGroupDescendantSecondary.length > 0
  ) {
    const tempArr =
      informationAllocation?.assessmentGroupDescendant?.assessmentGroupDescendantSecondary.sort(
        (a, b) =>
          a.informationBasic?.assessmentGroupName.toLowerCase() !==
            b.informationBasic?.assessmentGroupName.toLowerCase()
            ? a.informationBasic?.assessmentGroupName.toLowerCase() <
              b.informationBasic?.assessmentGroupName.toLowerCase()
              ? -1
              : 1
            : 0
      );
    tempArr.forEach((ob) => {
      descendantSecondary.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.assessmentGroupName || "",
        textTwo: ob?.informationBasic?.assessmentGroupDescription || "",
        status: ob?.informationEngagement?.assessmentGroupStatus || "",
      });
    });
  }
  // node primary list
  let nodePrimary = [];
  if (
    informationAllocation?.assessmentGroupNode?.assessmentGroupNodePrimary &&
    informationAllocation?.assessmentGroupNode?.assessmentGroupNodePrimary
      .length > 0
  ) {
    const tempArr =
      informationAllocation?.assessmentGroupNode?.assessmentGroupNodePrimary;
    tempArr.forEach((ob) => {
      nodePrimary.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.associateNodeName || "",
        textTwo: ob?.informationBasic?.associateNodeDescription || "",
        status: ob?.informationEngagement?.associateNodeStatus || "",
      });
    });
  }

  // node secondary list
  let nodeSecondary = [];
  if (
    informationAllocation?.assessmentGroupNode?.assessmentGroupNodeSecondary &&
    informationAllocation?.assessmentGroupNode?.assessmentGroupNodeSecondary
      .length > 0
  ) {
    const tempArr =
      informationAllocation?.assessmentGroupNode?.assessmentGroupNodeSecondary;
    tempArr.forEach((ob) => {
      nodeSecondary.push({
        id: ob?.id + '-',
        textOne: ob?.informationBasic?.associateNodeName || "",
        textTwo: ob?.informationBasic?.associateNodeDescription || "",
        status: ob?.informationEngagement?.associateNodeStatus || "",
      });
    });
  }

  let groupTypeListPrimary = [];
  if (
    informationAllocation?.assessmentGroupType?.assessmentGroupTypePrimary &&
    informationAllocation?.assessmentGroupType?.assessmentGroupTypePrimary.length > 0
  ) {
    const tempArr = informationAllocation?.assessmentGroupType?.assessmentGroupTypePrimary;
    tempArr.forEach((ob) => {
      groupTypeListPrimary.push({
        id: ob?.id + '-' || '',
        textOne: ob?.informationBasic?.assessmentTypeName || '',
        textTwo: ob?.informationBasic?.assessmentTypeDescription || '',
        status: ob?.informationEngagement?.assessmentTypeStatus || ''
      });
    });
  }
  let groupTypeListSecondary = [];
  if (
    informationAllocation?.assessmentGroupType?.assessmentGroupTypeSecondary &&
    informationAllocation?.assessmentGroupType?.assessmentGroupTypeSecondary.length > 0
  ) {
    const tempArr = informationAllocation?.assessmentGroupType?.assessmentGroupTypeSecondary;
    tempArr.forEach((ob) => {
      groupTypeListSecondary.push({
        id: ob?.id + '-' || '',
        textOne: ob?.informationBasic?.assessmentTypeName || '',
        textTwo: ob?.informationBasic?.assessmentTypeDescription || '',
        status: ob?.informationEngagement?.assessmentTypeStatus || ''
      });
    });
  }
  function capitalizeFirstLetter(string) {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  const allocationList = [
    {
      id: "a1",
      labelTextOneOne: "group",
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: "ascendant",
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: "all",
              innerList: ascendantAll.sort((a, b) =>
                a.textOne.toLowerCase() !== b.textOne.toLowerCase()
                  ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
                    ? -1
                    : 1
                  : 0
              ),
            },
            {
              labelTextTwoBadge: "primary",
              innerList: ascendantPrimary.sort((a, b) =>
                a.textOne.toLowerCase() !== b.textOne.toLowerCase()
                  ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
                    ? -1
                    : 1
                  : 0
              ),
            },
            {
              labelTextTwoBadge: "secondary",
              innerList: ascendantSecondary.sort((a, b) =>
                a.textOne.toLowerCase() !== b.textOne.toLowerCase()
                  ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
                    ? -1
                    : 1
                  : 0
              ),
            },
          ],
        },
        {
          labelTextOneOneBadge: "descendant",
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: "all",
              innerList: descendantAll.sort((a, b) =>
                a.textOne.toLowerCase() !== b.textOne.toLowerCase()
                  ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
                    ? -1
                    : 1
                  : 0
              ),
            },
            {
              labelTextTwoBadge: "primary",
              innerList: descendantPrimary.sort((a, b) =>
                a.textOne.toLowerCase() !== b.textOne.toLowerCase()
                  ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
                    ? -1
                    : 1
                  : 0
              ),
            },
            {
              labelTextTwoBadge: "secondary",
              innerList: descendantSecondary.sort((a, b) =>
                a.textOne.toLowerCase() !== b.textOne.toLowerCase()
                  ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
                    ? -1
                    : 1
                  : 0
              ),
            },
          ],
        },
      ],
      innerInfo: "No Information",
      isListCard: true,
      isReviewLink: false,
      isMultiList: true,
    },
    {
      id: "a3",
      labelTextOneOne: "node",
      labelTextOneOneBadgeOne: "primary",
      labelTextOneOneBadgeTwo: "secondary",
      labelTextOneOneBadgeThree: "",
      labelTextOneOneBadgeFour: "",
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'primary',
          innerLis: nodePrimary.sort((a, b) =>
            a.textOne.toLowerCase() !== b.textOne.toLowerCase()
              ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
                ? -1
                : 1
              : 0
          )
        },
        {
          labelTextOneOneBadge: 'secondary',
          innerList: nodeSecondary.sort((a, b) =>
            a.textOne.toLowerCase() !== b.textOne.toLowerCase()
              ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
                ? -1
                : 1
              : 0
          )
        }
      ],rInfo: "No Information",
      isListCard: true,
    },
    {
      id: "a4",
      labelTextOneOne: "type",
      labelTextOneOneBadgeOne: "primary",
      labelTextOneOneBadgeTwo: "secondary",
      labelTextOneOneBadgeThree: "",
      labelTextOneOneBadgeFour: "",
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: "primary",
          innerList: groupTypeListPrimary.sort((a, b) =>
          a.textOne.toLowerCase() !== b.textOne.toLowerCase()
            ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
              ? -1
              : 1
            : 0
        ),
        },
        {
          labelTextOneOneBadge: "secondary",
          innerList: groupTypeListSecondary.sort((a, b) =>
            a.textOne.toLowerCase() !== b.textOne.toLowerCase()
              ? a.textOne.toLowerCase() < b.textOne.toLowerCase()
                ? -1
                : 1
              : 0
          ),
        },
      ],
      innerInfo: "No Information",
      isListCard: true,
    },
  ];
  const engagementList = [
    {
      id: "a1",
      labelTextOneOne: "log",
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: "all",
          textOne: "",
        },
        {
          labelTextOneOneBadge: "key",
          textOne: "",
        },
      ],
      innerAssociateList: [],
      innerInfo: "assessees",
      isListCard: false,
      IconOne: Manuscript,
    },
    {
      id: "a2",
      textOneOne:
        capitalizeFirstLetter(informationEngagement?.assessmentGroupStatus) ||
        "No Information",
      labelTextOneOne: "status",
      innerAssociateList: [],
      innerInfo: "No Information",
      isListCard: false,
    },
    {
      id: "a3",
      labelTextOneOne: "tag",
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: "primary",
          textOne:
            informationEngagement?.assessmentGroupTag
              ?.assessmentGroupTagPrimary || "No Information",
        },
        {
          labelTextOneOneBadge: "secondary",
          textOne:
            informationEngagement?.assessmentGroupTag
              ?.assessmentGroupTagSecondary || "No Information",
        },
      ],
      innerAssociateList: [],
      innerInfo: "No Information",
      isListCard: false,
    },
    {
      id: "a4",
      labelTextOneOne: "tenure",
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: "start",
          textOne:
            informationEngagement?.assessmentGroupTenure
              ?.assessmentGroupTenureDateTimeStart || "No Information",
        },
        {
          labelTextOneOneBadge: "end",
          textOne:
            informationEngagement?.assessmentGroupTenure
              ?.assessmentGroupTenureDateTimeEnd || "No Information",
        },
      ],
      innerAssociateList: [],
      innerInfo: "Assessee",
      isListCard: false,
    },
  ];

  const classificationList = [
    {
      id: "a1",
      textOneOne:
        informationSetup?.assessmentGroupClassification
          .assessmentGroupClassificationPrimary || "No Information",
      labelTextOneOne: "classification",
      innerAssociateList: [],
      innerInfo: "No Information",
      isListCard: false,
    },
    {
      id: 'setupList1',
      labelTextOneOne: 'fee',
      labelTextOneOneBadges: 
      [
        {
          labelTextOneOneBadge: 'move',
          textOne: informationSetup?.assessmentGroupMoveFee || 0
        },
        {
          labelTextOneOneBadge: 'sell',
          textOne: informationSetup?.assessmentGroupSellFee || 0
        },
        {
          labelTextOneOneBadge: 'share',
          textOne: informationSetup?.assessmentGroupShareFee ||  0
        }
      ],
      innerInfo: 'No',
      isListCard: false,
      isReviewLink: false,
      isMultiInfoCard: false
    }
  ];

  const reviseAllocation = (e) => {
    const labelName = e.currentTarget.getAttribute("data-value");
    const selectedBadgeName = e.currentTarget.getAttribute("data-key");
    const innerSelectedBadgeName = e.currentTarget.getAttribute("id");
    console.log("=====>", labelName, selectedBadgeName);
    //group click open popup
    if (labelName === "group") {
      if (
        selectedBadgeName === "ascendant" &&
        innerSelectedBadgeName === "primary"
      ) {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: "GROUPLISTPOPUP",
            popupMode: "assessmentsGROUPCREATE",
          },
        });
        dispatch({ type: LOADER_START });
        let requestObj = makeAssessmentGroupObj(
          selectedAssociateInfo,
          "active",
          0,
          -1,
          'list',
          assignmentCreateType
        );
        dispatch({
          type: SET_CORE_GROUP_REVIEW_LIST_REQ_OBJECT,
          payload: requestObj,
        });
        dispatch({ type: LOADER_START });
        dispatch({
          type: GET_ASSESSMENT_GROUP_REVIEW_LIST_SAGA,
          payload: {
            request: requestObj,
            BadgeOne: "",
            BadgeTwo: "",
            BadgeThree: "",
            isMiddlePaneList: false,
          },
        });
      }
    }
    //
    if (labelName === "node") {
      if (selectedBadgeName === "primary") {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: "NODELISTPOPUP",
            popupMode: "assessmentsGROUPCREATE",
          },
        });
        dispatch({ type: LOADER_START });
        let nodeRequestObj = makeInternalNodeObj(selectedAssociateInfo, 'active', 0, -1, '', 'Associate-Self-Node-List-Create');
        dispatch({ type: SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT, payload: nodeRequestObj });
        console.log('nodeRequestObj', nodeRequestObj);
        dispatch({ type: LOADER_START });
        dispatch({
          type: INTERNAL_NODE_LIST_WITH_PERMISSIONS_SAGA,
          payload: {
            request: nodeRequestObj,
            BadgeOne: '',
            BadgeTwo: '',
            BadgeThree: '',
            nodeViewState: 'list',
            isMiddlePaneList: false
          }
        });
      }
      if (selectedBadgeName === "secondary") {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: "NODESECONDARYLISTPOPUP",
            popupMode: "assessmentsGROUPCREATE",
          },
        });
        dispatch({ type: LOADER_START });
        let nodeRequestObj = makeInternalNodeObj(selectedAssociateInfo, 'active', 0, -1, '', 'Associate-Self-Node-List-Create');
        dispatch({ type: SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT, payload: nodeRequestObj });
        console.log('nodeRequestObj', nodeRequestObj);
        dispatch({ type: LOADER_START });
        dispatch({
          type: INTERNAL_NODE_LIST_WITH_PERMISSIONS_SAGA,
          payload: {
            request: nodeRequestObj,
            BadgeOne: '',
            BadgeTwo: '',
            BadgeThree: '',
            nodeViewState: 'list',
            isMiddlePaneList: false
          }
        });
      }
    }
    if (labelName === "type") {
      dispatch({ type: LOADER_START });
      let roleRequestObj = makeAssessmentTypeObj(selectedAssociateInfo, 'active', 0, -1);
      dispatch({ type: SET_CORE_TYPE_REVIEW_LIST_REQ_OBJECT, payload: roleRequestObj });
      dispatch({
        type: GET_ASSESSMENT_TYPE_REVIEW_LIST_SAGA,
        payload: {
          request: roleRequestObj,
          BadgeOne: '',
          BadgeTwo: '',
          BadgeThree: '',
          isMiddlePaneList: false
        }
      });
      if (selectedBadgeName === "primary") {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: "TYPELISTPOPUP",
            popupMode: "assessmentsGROUPCREATE",
          },
        });
      }
      if (selectedBadgeName === "secondary") {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: "TYPESECONDARYLISTPOPUP",
            popupMode: "assessmentsGROUPCREATE",
          },
        });
      }
    }
  };
  const reviseEngagement = (e) => {
    const labelName = e.currentTarget.getAttribute("data-value");
    const selectedBadgeName = e.currentTarget.getAttribute("data-key");
    console.log("=====>", labelName);
    if (labelName === "status") {
      dispatch({
        type: SET_STATUS_POPUP_VALUE,
        payload: capitalizeFirstLetter(
          informationEngagement?.assessmentGroupStatus
        ),
      });
      dispatch({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: "STATUSPOPUP",
          popupMode: "assessmentsGROUPCREATE",
        },
      });
    }
    if (labelName === "tag") {
      if (selectedBadgeName === "primary") {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: "TAGREADONLYPRIMARYPOPUP",
            popupMode: "assessmentsGROUPCREATE",
          },
        });
      }
      if (selectedBadgeName === "secondary") {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: "TAGSECONDARYPOPUP",
            popupMode: "assessmentsGROUPCREATE",
          },
        });
      }
    }
    if (labelName === "tenure") {
      if (selectedBadgeName === "start") {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: "TENURESATRTDATEPOPUP",
            popupMode: "assessmentsGROUPCREATE",
          },
        });
      }
      if (selectedBadgeName === "end") {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: {
            isPopUpValue: "TENUREENDDATEPOPUP",
            popupMode: "assessmentsGROUPCREATE",
          },
        });
      }
    }
  };

  const reviseClassification = (e,selectedBadgeArray) => {
    const labelName = e.currentTarget.getAttribute("data-value");
    const selectedBadgeName = e.currentTarget.getAttribute("data-key");
    console.log('=====>', labelName);
    let badgeName = '';
    if (selectedBadgeArray) {
      if (selectedBadgeArray.length > 0) {
        selectedBadgeArray.forEach((element) => {
          badgeName = badgeName + element.labelTextTwoBadge;
        });
      }
    }
    console.log('=====>', badgeName);
    if (labelName === 'fee' && selectedBadgeName === 'move') {
      console.log('open move popup');
      dispatch({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'GROUPSASSOCIATEMOVEFEEPOPUP',
          popupMode: 'assessmentsGROUPCREATE'
        }
      });
    }
    if (labelName === 'fee' && selectedBadgeName === 'sell') {
      console.log('open sell popup');
      dispatch({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'GROUPSASSOCIATESELLFEEPOPUP',
          popupMode: 'assessmentsGROUPCREATE'
        }
      });
    }
    if (labelName === 'fee'  && selectedBadgeName === 'share') {
      console.log('open share popup');
      dispatch({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'GROUPSASSOCIATESHAREFEEPOPUP',
          popupMode: 'assessmentsGROUPCREATE'
        }
      });
    }
    if (labelName === "classification") {
      dispatch({
        type: SET_STATUS_POPUP_VALUE,
        payload: capitalizeFirstLetter(
          informationSetup?.assessmentGroupClassification
            .assessmentGroupClassificationPrimary
        ),
      });
      dispatch({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: "CLASSIFICATIONLISTPOPUP",
          popupMode: "assessmentsGROUPCREATE",
        },
      });
    }
  };

  return (
    <div>
      <>
        <div className="containerPadding">
          <Paper className={"dossierContainerTop"}>
            {allocationList.map((ob) => {
              return (
                <div key={ob.id}>
                  {ob.isListCard ? (
                    <>
                      {ob.isMultiList ? (
                        <DisplayPanelAccordianReviewListTwo
                          onClickReview={null}
                          onClickRevise={reviseAllocation}
                          accordianObject={ob}
                          mode={reviewMode}
                        />
                      ) : (
                        <DisplayPanelAccordianReviewListOne
                          onClickRevise={reviseAllocation}
                          className=""
                          accordianObject={ob}
                          mode={reviewMode}
                        />
                      )}
                    </>
                  ) : (
                    <DisplayPanelAccordianInformation
                      onClickRevise={reviseAllocation}
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  )}
                  {/* {ob.isListCard ? (
                    <DisplayPanelAccordianReviewListOne
                      onClickRevise={reviseAllocation}
                      className=""
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  ) : (
                    <DisplayPanelAccordianInformation
                      onClickRevise={reviseAllocation}
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  )} */}
                </div>
              );
            })}
          </Paper>
        </div>
        <div className="containerPadding">
          <Paper className={"dossierContainerTop"}>
            {engagementList.map((ob) => {
              return (
                <div key={ob.id}>
                  {ob.isListCard ? (
                    <DisplayPanelAccordianReviewListOne
                      onClickRevise={reviseEngagement}
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  ) : (
                    <DisplayPanelAccordianInformation
                      onClickRevise={reviseEngagement}
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  )}
                </div>
              );
            })}
          </Paper>
        </div>

        <div className="containerPadding">
          <Paper className={"dossierContainerTop"}>
            {classificationList.map((ob) => {
              return (
                <div key={ob.id}>
                  {ob.isListCard ? (
                    <DisplayPanelAccordianReviewListOne
                      onClickRevise={reviseClassification}
                      className=""
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  ) : (
                    <DisplayPanelAccordianInformation
                      onClickRevise={reviseClassification}
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  )}
                </div>
              );
            })}
          </Paper>
        </div>
      </>
      {isMobile && (
        <div className={"containerPadding"} style={{ height: "55px" }}>
          {" "}
        </div>
      )}
    </div>
  );
};

export default DisplayPaneThreeSectionOneAssessmentGroup;
