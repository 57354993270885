import React, { useState } from 'react'
import PopUp from '../Molecules/PopUp/PopUp'
import PopupHeader from '../Molecules/PopUp/PopUpHeader'
import { DialogContent, FormControl, FormHelperText, Input } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { LOADER_START, POPUP_CLOSE, QRURL_VERIFICATION_SAGA } from '../actionType'
import InputLabel from '@material-ui/core/InputLabel';
import '../Molecules/PopUp/PopUp.css';
import '../Atoms/InputField/InputField.css'

const PopUpWeblink = (props) => {
    const dispatch = useDispatch()
    const { headerPanelColour, headerOneBadgeOne, headerOneBadgeTwo, headerOne, inputHeader, label, labelBadgeOne, isActive = true } = props;
    const [valState, setValState] = useState('');
    const [isErrorMsg, setIsErrorMsg] = useState('');

    const {
        selectedAssociateInfo,
        QRApisCallSuccessStatus
    } = useSelector((state) => state.DisplayPaneTwoReducer);
    const { responseObject, } =
        useSelector((state) => state.DisplayPaneThreeReducer);

    const handleClick = (ev) => {

        if (valState) {

            if (valState === '') {
                return;
            }

            dispatch({ type: POPUP_CLOSE })
            dispatch({ type: LOADER_START })
            dispatch({
                type: QRURL_VERIFICATION_SAGA,
                payload: {
                    "module": "Assessee-Assignment",
                    "action": "URL-Validate",
                    "requestObject": {
                        "assesseeId": selectedAssociateInfo?.assesseeId,
                        "associateId": selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
                        "assignmentId": responseObject.id,
                        "certificateUrl": valState.trim()
                    }
                }
            })
        }

    }

    const onValChange = (e) => {
        setValState(e.target.value)
    }

    return (
        <div>
            <PopUp isActive={isActive}>
                <PopupHeader
                    headerPanelColour={headerPanelColour}
                    headerOne={headerOne}
                    headerOneBadgeOne={headerOneBadgeOne}
                    headerOneBadgeTwo={headerOneBadgeTwo}
                    onClick={handleClick}
                    mode={'search'}
                // isNotRevised={true}
                />

                <DialogContent className={['popupContent', 'fixed10PadDim', 'revisePopupContent'].join(' ')}>
                    {inputHeader !== '' && (
                        <div className={'fitContent'}>
                            <div className={['PopupFormBox', 'labelPopupBox', 'popupMinHei'].join(' ')}>
                                <InputLabel htmlFor="name-input" className={'textForLabelPopup'}>
                                    <>
                                        {'certificate'}&nbsp;
                                        {true ? (
                                            <span className={'headerBadge'}>{'link'}</span>
                                        ) : null}
                                        &nbsp;
                                        {/* {inputHeaderBadgeTwo ? (
                                            <span className={'headerBadge'}>{inputHeaderBadgeTwo}</span>
                                        ) : null} */}
                                    </>
                                </InputLabel>
                                <div className={'infoSymbol'}></div>
                                <div className={'infoSymbol'}>{/* <InfoToolTip message={infoMsg} /> */}</div>
                            </div>
                        </div>
                    )}


                    <div className='popup-form-box'>
                        <FormControl style={{ width: '100%' }}>
                            <InputLabel
                                htmlFor={'link'}
                                style={{ fontSize: '1.6rem' }}

                            >
                                {label} &nbsp;
                                {labelBadgeOne ? <span className={'labelheaderBadge1'}>{labelBadgeOne}</span> : null}
                            </InputLabel>

                            <Input
                                type={'url'}
                                id={'link'}
                                name={'link'}
                                value={valState}
                                onChange={onValChange}
                                error={false}
                                autoComplete="off"
                                autoFocus={false}
                                className={['inputFields'].join(' ')}
                            // inputProps={{}}
                            >
                            </Input>
                            <FormHelperText
                                className={[isErrorMsg ? 'helperText' : 'helperTextDefault', 'helptextmargin'].join(
                                    ' '
                                )}
                            >
                                <span>{isErrorMsg}</span>
                            </FormHelperText>
                        </FormControl>
                    </div>
                </DialogContent>
            </PopUp>
        </div>
    )
}

export default PopUpWeblink