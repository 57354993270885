import React from 'react';
import { isMobile } from 'react-device-detect';
// import AllocationAccordian from '../Accordian/AllocationAccordian';
// import Manuscript from '@material-ui/icons/Description';
import { useDispatch, useSelector } from 'react-redux';
import DisplayPanelAccordianReviewListOne from '../Accordian/DisplayPanelAccordianReviewListOne';
import DisplayPanelAccordianInformation from '../Accordian/DisplayPanelAccordianInformation';
import { Paper } from '@material-ui/core';
import {
  makeAssessmentReviewListRequestObject,
  makeCultureProfileObj
} from '../../Actions/GenericActions';
import {
  SET_PAGE_COUNT
} from '../../actionType';
import {
  getCultureGroupCultureDistinctApiCall,
  getCultureProfileAllocate
} from '../../Actions/ActionCultureProfile';

const DisplayPaneThreeSectionTwoCultureProfileGroup = () => {
  // const [listExpand, setListExpand] = useState('');
  const { reviewMode, relatedReviewListPaneThree, responseObject } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  const { selectedAssociateInfo, countPage, reviewListDistinctData } = useSelector(
    (state) => state.DisplayPaneTwoReducer
  );
  const dispatch = useDispatch();
  const { informationEngagement, informationSetup } = responseObject;

  function capitalizeFirstLetter(string) {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  let cultureProfileList = [];
  if (relatedReviewListPaneThree?.length > 0) {
    cultureProfileList = relatedReviewListPaneThree[0].cultureProfile;
  }
  let cultureProfileArray = [];
  cultureProfileList &&
    cultureProfileList.forEach((ob) => {
      const { id, informationBasic, informationEngagement, associateId } = ob;
      cultureProfileArray.push({
        id,
        textOne: informationBasic?.cultureProfileName || '',
        textTwo: informationBasic?.cultureProfileDescription || '',
        status: informationEngagement?.cultureProfileStatus || '',
        isShared: ob?.cultureProfileShared,
      associateId
      });
    });

  const list2 = [
    {
      id: 'a1',
      labelTextOneOne: 'culture-profiles',
      labelTextOneOneBadgeOne: '',
      labelTextOneOneBadgeTwo: '',
      labelTextOneOneBadgeThree: '',
      labelTextOneOneBadgeFour: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'distinct',
          innerList: cultureProfileArray
        }
      ],
      innerInfo: 'No Information',
      isListCard: true,
      isReviewLink: true
    }
  ];

  const onclickReviewCultureProfile = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    if (labelName === 'culture-profiles' && selectedBadgeName === 'distinct') {
      getCultureGroupCultureDistinctApiCall(
        selectedAssociateInfo,
        '',
        //'all',
        -1,
        dispatch,
        'distinct',
        responseObject.id, //group id
        '',
        false,
        true
      );
    }
  };

  const onclickReviseCultureProfile = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    if (labelName === 'culture-profiles' && selectedBadgeName === 'distinct') {
      let requestObect = makeCultureProfileObj(
        selectedAssociateInfo,
        '',
        //'activeinactive',
        countPage,
        0
      );
      dispatch({ type: SET_PAGE_COUNT, payload: 0 });

      let revisedGroupObject = {
        id: responseObject.id,
        cultureProfileGroupName: responseObject.informationBasic.cultureProfileGroupName,
        cultureProfileGroupDescription:
          responseObject.informationBasic.cultureProfileGroupDescription,
        cultureProfileGroupStatus: responseObject.informationEngagement.cultureProfileGroupStatus
      };
      getCultureProfileAllocate(
        dispatch,
        requestObect,
        revisedGroupObject,
        relatedReviewListPaneThree,
        'cultureProfileGroupCultureProfileRevise',
        'cultureProfileGroupCultureProfileReviewList'
      );
      dispatch({ type: SET_PAGE_COUNT, payload: 0 });
    }
  };

  return (
    <div>
      <>
        <div className={'containerPadding'}>
          <Paper className={'dossierContainerTop'}>
            {list2.map((ob) => {
              return (
                <div key={ob.id}>
                  {ob.isListCard ? (
                    <DisplayPanelAccordianReviewListOne
                      onClickReview={onclickReviewCultureProfile}
                      onClickRevise={onclickReviseCultureProfile}
                      className=""
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  ) : (
                    <DisplayPanelAccordianInformation
                      onClickRevise={onclickReviseCultureProfile}
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  )}
                </div>
              );
            })}
          </Paper>
        </div>
      </>
      {isMobile && (
        <div className={'containerPadding'} style={{ height: '55px' }}>
          {' '}
        </div>
      )}
    </div>
  );
};

export default DisplayPaneThreeSectionTwoCultureProfileGroup;
