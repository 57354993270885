import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PopupHeader from '../Molecules/PopUp/PopUpHeader';
import Popup from '../Molecules/PopUp/PopUp';
import '../Molecules/PopUp/PopUp.css';
import { DialogContent, FormControl, FormHelperText, Input, InputLabel } from '@material-ui/core';
import JsonRenderComponent from '../Actions/JsonRenderComponent';
import {
  GET_ASSESSEE_GROUP_REVIEW_INFO_SAGA,
  GET_ASSESSMENT_GROUP_REVIEW_INFO_SAGA,
  GET_ASSESSMENT_INFO_SAGA,
  GET_ASSESSMENT_TYPE_REVIEW_INFO_SAGA,
  GET_ASSIGNMENT_GROUP_REVIEW_INFO_SAGA,
  GET_ASSIGNMENT_INFO_SAGA,
  GET_ASSIGNMENT_TYPE_REVIEW_INFO_SAGA,
  GET_ASSOCIATE_GROUP_REVIEW_INFO_SAGA,
  GET_ASSOCIATE_INFO_SAGA,
  GET_ASSOCIATE_ROLE_REVIEW_INFO_SAGA,
  LOADER_START,
  POPUP_CLOSE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MIDDLEPANE_SECONDARY_OPTION,
  SET_MOBILE_PANE_STATE,
  SET_SECONDARY_CREATE_OPTION_VALUE,
  FILTERMODE,
  CLEAR_DISPLAY_PANE_THREE,
  GET_ASSOCIATE_NODE_REVIEW_INFO_SAGA,
  CLEAR_NODE_REDUCER_STATE,
  SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT,
  INTERNAL_NODE_LIST_SAGA,
  SET_POPUP_VALUE,
  SET_NODE_DYNAMIC_SINGLE_STATE,
  GET_ASSESSEE_TYPE_REVIEW_INFO_SAGA,
  GET_ASSOCIATE_TYPE_REVIEW_INFO_SAGA,
  GET_ITEM_GROUP_REVIEW_INFO_SAGA,
  GET_ITEM_TYPE_REVIEW_INFO_SAGA,
  GET_ASSESSEENODE_ASSESSEE_REVIEW_LIST,
  GET_NODE_ASSESSMENTS_REVIEW_LIST_SAGA,
  GET_CULTURE_PROFILE_INFO_SAGA,
  GET_CULTURE_GROUP_REVIEW_INFO_SAGA,
  GET_JOB_GROUP_REVIEW_INFO_SAGA,
  GET_CULTURE_TYPE_REVIEW_INFO_SAGA,
  GET_JOB_TYPE_REVIEW_INFO_SAGA,
  GET_JOB_PROFILE_INFO_SAGA,
  GET_NODE_ITEMS_REVIEW_LIST_SAGA,
  GET_NODE_ASSIGNMENTS_REVIEW_LIST_SAGA,
  GET_CULTURE_NODE_CULTURE_REVIEW_LIST_SAGA,
  GET_JOB_NODE_JOB_REVIEW_LIST_SAGA,
  GET_NODE_ASSOCIATE_REVIEW_LIST,
  SET_POPUP_SINGLE_STATE,
  SET_MIDDLEPANE_STATE,
  RELATED_REVIEWLIST_DISTINCT_DATA,
  ASSESSMENT_START_SAGA,
  GET_FRAMWORK_TYPE_REVIEW_LIST_SAGA,
  LOADER_STOP,
  SET_DISPLAY_PANE_THREE_STATE,
  GET_ASSESSEE_REPORT_SAGA,
  GET_ASSESSMENT_SEC_INFO_SAGA,
  SET_RELATED_REQUEST_OBJECT,
  SET_SCALE_REDUCER_STATE,
  SET_CLUSTER_REDUCER_STATE,
  CLEAR_SCALE_REDUCER_STATE,
  CLEAR_CLUSTER_REDUCER_STATE,
  CLEAR_SECTION_REDUCER_STATE,
  SET_SECTION_REDUCER_STATE,
  SET_ASSESSMENT_SECTION_DYNAMIC_FRAMEWORK_STATE,
  SET_VERSION_REDUCER_STATE,
  CLEAR_VERSION_REDUCER_STATE,
  SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
  GET_ASSIGNMENT_ASSESSMENT_INFO_SAGA,
  ASSESSMENT_INFO_REVISE_SAGA,
  SHARE_GROUPS_ROLES_TYPES_SAGA,
  ASSESSEE_ASSESSMENT_START_SAGA,
  ASSESSMENT_CLUSTER_REVISE_SAGA,
  SET_SCORE_REDUCER_STATE,
  SET_GRADE_REDUCER_STATE,
  CLEAR_GRADE_REDUCER_STATE,
  SET_SCALE_INFO_REDUCER_STATE,
  CLEAR_NORM_REDUCER_STATE,
  SHARE_DISTINCT_SAGA,
  SET_GUAGE_REDUCER_STATE,
  SET_GUAGE_INFO_REDUCER_STATE,
  CLEAR_GUAGE_REDUCER_STATE,
  GET_ASSIGNMENT_GUAGE_INFO_SAGA,
  GET_ASSIGNMENT_REPORT_SAGA,
  GET_ASSIGNMENT_REPORT_INFO_SAGA,
  CREATE_GAUGE_SAGA,
  SET_JOB_CULTURE_PROFILER_INFO_REDUCER_STATE,
  SET_JOB_CULTURE_PROFILER_REDUCER_STATE,
  SET_JOB_CULTURE_PROFILER_CLUSTER_REDUCER_STATE,
  SET_NORM_REDUCER_STATE,
  SET_DISPLAY_THREE_SINGLE_STATE,
  SET_PAGE_COUNT,
  SET_CORE_TYPE_REVIEW_LIST_REQ_OBJECT,
  GET_ASSESSEE_TYPE_REVIEW_LIST_SAGA,
  GET_ASSESSMENT_TYPE_REVIEW_LIST_SAGA,
  GET_ASSIGNMENT_TYPE_REVIEW_LIST_SAGA,
  GET_CULTUREPROFILE_TYPE_REVIEW_LIST_SAGA,
  GET_JOBPROFILE_TYPE_REVIEW_LIST_SAGA,
  GET_ITEM_TYPE_REVIEW_LIST_SAGA,
  GET_ASSOCIATE_TYPE_REVIEW_LIST_SAGA,
  SET_POPUP_STATE,
  SET_MIDDLEPANE_REVIEW_LIST_PREVIOUS_POPUP,
  RESEND_ASSIGNMENT_NOTIFICATION,
  RESET_ASSESSEE_PASSWORD,
  ASSESSEE_MARKETPLACE_REVIEW_NODE_SAGA
} from '../actionType';
import {
  assesseeAssignmentAssessmentResetApiCall,
  assesseeAssignmentResetApiCall,
  assesseeAssignmentReviewListApi,
  assesseeReviewInformation,
  assesseeRole,
  assesseeRoleReviewInformation,
  assesseeRollReviseApiCall,
  assignemtAsseseeAssessmentResetApiCall,
  assignmentAssesseeResetApiCall,
  getAssesseeAllocateTypeApiCall,
  getAssesseeDistinctApiCall,
  getAssesseeGroupAllocateDistinctApiCall,
  getAssesseeGroupAssesseeDistinctApiCall,
  getAssesseeGroupAssesseeReqObj,
  getAssesseeGroupDistinctApiCall,
  getAssesseeNodeAssesseeDistinctApiCall,
  getAssesseeNodeAssesseeReqObj,
  getAssesseeRoleAllocateDistinctApiCall,
  getAssesseeRoleAssesseeDistinctApiCall,
  getAssesseeRoleDistinctApiCall,
  getAssesseeTypeApiCall,
  getAssesseeTypeAssesseeDistinctApiCall,
  updateAssesseeDistinctStatus,
  updateAssesseeFlagUpdate,
  updateAssesseeGroupStatus,
  getAssesseeMarketplaceNodeApiCall,
  getAssesseeMarketplaceRoleApiCall,
  getAssesseeMarketplaceTypeApiCall
} from '../Actions/AssesseeModuleAction';
import {
  associateCreatePopup,
  getAssociateDistinctApiCall,
  getAssociateGroupAllocateDistinctApiCall,
  getAssociateGroupAssociateDistinctApiCall,
  getAssociateGroupAssociateReqObj,
  getAssociateGroupDistinctApiCall,
  getAssociateRoleAllocateDistinctApiCall,
  getAssociateRoleAssociateDistinctApiCall,
  getAssociateRoleAssociateReqObj,
  getAssociateRoleDistinctApiCall,
  getAssociatesAllocateTypeApiCall,
  getAssociatesTypeApiCall,
  getAssociateTypeAssociateDistinctApiCall,
  getInternalNodeAllocateApiCall,
  getInternalNodeApiCall,
  getNodeRelatedAssociateDistinctApiCall,
  updateAssociateDistinctStatus,
  updateAssociateFlagUpdate,
  updateAssociateGroupStatus,
  updateAssociateNodeStatus,
  updateAssociateTypeStatus
} from '../Actions/AssociateModuleAction';
import {
  getAssessmentGroupAssessmentReqObj,
  makeInternalNodeObj,
  getAssignmentGroupAssignmentReqObj,
  getAssessmentTypeAssessmentReqObj,
  getNodeAssessmentsReqObj,
  getItemGroupItemReqObj,
  getItemTypeItemReqObj,
  getAssignmentTypeAssignmentReqObj,
  getCultureGroupCultureReqObj,
  getCultureTypeCultureReqObj,
  getJobProfileGroupJobProfileReqObj,
  getJobProfileTypeJobProfileReqObj,
  getNodeItemsReqObj,
  getNodeAssignmentsReqObj,
  getNodeAssociatesReqObj,
  getNodeCultureProfileReqObj,
  getNodeJobProfileReqObj,
  getAssociateTypeAssociateReqObj,
  getAssesseeTypeAssesseeReqObj,
  setItemTypeConfigState,
  convertSecondsToHMmSs,
  updateReviseStatus,
  UpdateGaugeReviseStatus,
  makeAssesseeTypeObj,
  updateReviseAssesseeAssignmentStatus,
  getRelatedDataAPICall,
  makeAssessmentTypeObj,
  makeAssignmentTypeObj,
  makeCultureProfileTypeObj,
  makeJobProfileTypeObj,
  makeItemsTypeObj,
  makeAssociateTypeObj,
  getAssociateDistinctListApiCall,
  makeInternalAllocateNodeObj
} from '../Actions/GenericActions';
import {
  getItemGroupDistinctAllocateApiCall,
  getItemGroupDistinctApiCall,
  getItemGroupItemDistinctApiCall,
  getItemReviewApiCall,
  getItemsDistinctApiCall,
  getItemsTypeAllocateApiCall,
  getItemsTypeApiCall,
  getItemTypeItemDistinctApiCall,
  getNodeRelatedItemsDistinctApiCall,
  updateItemDistinctFlagStatus,
  updateItemDistinctStatus,
  updateItemGroupFlagStatus,
  updateItemGroupStatus,
  updateItemTypeFlagStatus,
  updateItemTypeStatus
} from '../Actions/ItemModuleAction';
import {
  getAssessmentDistinctApiCall,
  getAssessmentGroupAllocateApiCall,
  getAssessmentGroupApiCall,
  getAssessmentGroupAssessmentDistinctApiCall,
  getAssessmentItemDistinctApiCall,
  getAssessmentTypeAllocateApiCall,
  getAssessmentTypeApiCall,
  getAssessmentTypeAssessmentDistinctApiCall,
  getNodeRelatedAssessmentsDistinctApiCall,
  updateAssessmentDistinctStatus,
  updateAssessmentFlagUpdate,
  updateAssessmentTypeStatus,
  getMarketplaceAssessmentTypeApiCall,
  getMarketplaceAssessmentNodeApiCall
} from '../Actions/AssessmentModuleAction';
import {
  assignmentsDistinctApiCall,
  getAssignmnetAssesseeDistinctApiCall,
  getAssignmnetAssessmentDistinctApiCall,
  getAssignmnetGroupAssignmnetDistinctApiCall,
  getAssignmnetTypeAssignmnetDistinctApiCall,
  getNodeRelatedAssignmentsDistinctApiCall,
  updateAssignmentAdminister,
  updateAssignmentDistinctStatus,
  updateAssignmentGroupStatus,
  updateAssignmentTypeStatus,
  updateAssignmentFlagStatus,
  assignmentTypeApiCall,
  assignmentsGroupApiCall,
  assignmentResetApiCall,
  assignmentAssessmentResetApiCall,
  assignmentsGroupAllocateApiCall,
  assignmentTypeAllocateApiCall,
  getMarketplaceAssignmentTypeApiCall
} from '../Actions/AssignmentModuleAction';
import {
  getAssignmneCultureProfileDistinctApiCall,
  getCultureGroupCultureDistinctApiCall,
  getCultureProfileAssessmentDistinctApiCall,
  getCultureProfileNodeCultureProfileApiCall,
  getCultureTypeCultureDistinctApiCall,
  updateCultureProfileDistinctStatus,
  updateCultureProfileFlagUpdate,
  updateCultureProfileGroupStatus,
  updateCultureProfileTypeStatus,
  getCultureProfileGroupApiCall,
  getCultureProfilesDistinctApiCall,
  getCultureProfileTypeApiCall,
  getCultureProfileDiamentionList,
  getCultureProfileAllocateGroupApiCall,
  getCultureProfileAllocateTypeApiCall
} from '../Actions/ActionCultureProfile';
import {
  getAssignmneJobProfileDistinctApiCall,
  getJobProfileAssessmentDistinctApiCall,
  getJobProfileGroupJobProfileDistinctApiCall,
  getJobProfileNodeJobProfileApiCall,
  getJobProfileTypeJobProfileDistinctApiCall,
  updateJobProfileDistinctStatus,
  updateJobProfileFlagUpdate,
  getJobProfilesDistinctApiCall,
  getJobProfileGroupApiCall,
  getJobProfileTypeApiCall,
  getJobProfileGroupAllocateApiCall,
  getJobProfileTypeApiAllcoateCall
} from '../Actions/ActionJobProfile';
import {
  ALLOCATE_ASSESSEE_DISTINCT,
  ALLOCATE_ASSESSEE_SUBMODULE_DISTINCT,
  ALLOCATE_ASSESSMENT_ACTIVE_DISTINCT,
  ALLOCATE_ASSESSMENT_DISTINCT,
  ALLOCATE_ASSESSMENT_INACTIVE_DISTINCT,
  ALLOCATE_ASSESSMENT_SUBMODULE,
  ALLOCATE_ASSIGNMENT_DISTINCT,
  ALLOCATE_ASSIGNMENT_DISTINCT_ANALYTICS,
  ALLOCATE_ASSIGNMENT_ACTIVE_DISTINCT,
  ALLOCATE_ASSIGNMENT_SUBMODULE,
  ALLOCATE_ASSOCIATE_DISTINCT,
  ALLOCATE_ASSOCIATE_NODE_DISTINCT,
  ALLOCATE_ASSOCIATE_SUBMODULE,
  ALLOCATE_CULTUREPROFILE_DISTINCT,
  ALLOCATE_CULTUREPROFILE_SUBMODULE_DISTINCT,
  ALLOCATE_ITEM_DISTINCT,
  ALLOCATE_ITEM_SUBMODULE_DISTINCT,
  ALLOCATE_JOB_DISTINCT,
  ALLOCATE_JOB_SUBMODULE,
  SHARE_NEW_POPUP,
  SHARE_POPUP_FEE_POPUP,
  ALLOCATE_CULTUREPROFILE_DISTINCT_INACTIVE,
  ALLOCATE_JOB_DISTINCT_INACTIVE,
  ALLOCATE_ASSIGNMENT_ACTIVE_DISTINCT_ANALYTICS,
  ALLOCATE_ASSIGNMENT_INACTIVE_DISTINCT_ANALYTICS,
  ALLOCATE_ASSIGNMENT_SUBMODULE_NODE
} from '../PopUpConfig';
import { a } from 'aws-amplify';
import { REQUIRED_ERROR_MESSAGE } from '../errorMessage';
const PopUpDisplayPaneTwoReviewList = (props) => {
  const {
    popupHeaderOne,
    popupHeaderOneBadgeOne,
    popupHeaderOneBadgeTwo,
    popupHeaderOneBadgeThree,
    popupOpenType,
    secondaryOptionCheckValue,
    tertiaryOptionCheckValue = 'all',
    forthOptionCheckValue,
    selectedTagValue,
    selectedTagStatus,
    selectedTagGroupId,
    cardValue,
    popupContentArrValue,
    cachedPreviousSelectedTagValue,
    popupMode,
    selectedTagInformationSetup,
    selectedSectionPopup
  } = useSelector((state) => state.PopUpReducer);
  const PopUpReducer = useSelector((state) => state.PopUpReducer);
  const { FilterMode } = useSelector((state) => state.FilterReducer);
  const assessmentInfo = useSelector((state) => state.AssessmentReducer);
  const { createMode, responseObject, headerOne, headerOneBadgeOne, reviewMode } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );

  const [shareFeePopup, setShareFeePopup] = useState(false);
  // const [updatedShareFee, setUpdatedShareFee] = useState(0);

  const { associateRole, assesseeRole } = useSelector((state) => state.RoleCreateReducer);
  const {
    selectedAssociateInfo,
    countPage,
    middlePaneHeader,
    reviewListDistinctData,
    relatedReviewListDistinctData,
    middlePaneHeaderBadgeOne,
    assessmentResponseObject,
    assignmentReportsData,
    assignmentReportResponse,
    cultureProfileDiamentionReviewList,
    jobProfilerReviewList,
    clusterProfiler,
    assignmentCreateType,
    selectedTagsArray,
    middlePaneSelectedValue,
    middlePaneHeaderBadgeTwo,
    middlePaneHeaderBadgeThree,
    middlePaneHeaderBadgeFour,
    middlePaneHeaderDuplicate,
    middlePaneHeaderDuplicateBadgeOne,
    middlePaneHeaderDuplicateBadgeTwo,
    middlePaneHeaderDuplicateBadgeThree,
    middlePaneHeaderDuplicateBadgeFour,
    middlePaneHeaderDuplicateOne,
    middlePaneHeaderDuplicateOneBadgeOne,
    middlePaneHeaderDuplicateOneBadgeTwo,
    middlePaneHeaderDuplicateOneBadgeThree,
    middlePaneHeaderDuplicateOneBadgeFour,
    duplicateScanCount,
    nodeViewState,
    signedAssesseePermission,
    middlePaneListPopupOptions,
    isListLoading,
    allocatedTagsArray,
    relatedReviewListObjectCopy,
    paneOneSelectedSection
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const {
    assesseeAssignmentAssessmentData,
    assessmentsequenceObject,
    currentSequenceIndex,
    isAssessmentStart,
    assesseeAssessmentStartData
  } = useSelector((state) => state.AssesseeAssignmentAssessmentReducer);
  const {
    assesseeGroup,
    assessmentGroup,
    assignmentGroup,
    associateGroup,
    itemGroup,
    cultureProfileGroup,
    jobProfileGroup
  } = useSelector((state) => state.GroupCreateReducer);
  const assignmentInfo = useSelector((state) => state.AssignmentReducer);
  const [isReviseMode, setIsReviseMode] = useState(false);
  const dispatch = useDispatch();
  const {
    headerPanelColour = 'displayPaneCentre',
    isActive,
    popupAllClose,
    typeOfMiddlePaneList
  } = props;
  // let selectedTagValue = responseObject?.id || selectedId;
  const setSecondaryOptionValue = (e) => {
    if (
      popupHeaderOneBadgeTwo === 'allocate' &&
      secondaryOptionCheckValue !== e.currentTarget.getAttribute('data-value')
    ) {
      setEmabledDisableOptionForAllocate(
        e.currentTarget.getAttribute('data-value'),
        assignmentCreateType
      );
    }
    dispatch({
      type: SET_SECONDARY_CREATE_OPTION_VALUE,
      payload: e.currentTarget.getAttribute('data-value')
    });
  };
  const setEmabledDisableOptionForAllocate = (clickVal, assignmentCreateType) => {
    // debugger
    let arr = [];
    let arrayValue =
      (popupHeaderOne === 'assessee' && ALLOCATE_ASSESSEE_DISTINCT) ||
      (popupHeaderOne === 'administrator' && ALLOCATE_ASSESSEE_DISTINCT) ||
      (popupHeaderOne === 'manager' && ALLOCATE_ASSESSEE_DISTINCT) ||
      (popupHeaderOne === 'assistant' && ALLOCATE_ASSESSEE_DISTINCT) ||
      (popupHeaderOne === 'assessees' && ALLOCATE_ASSESSEE_SUBMODULE_DISTINCT) ||
      (popupHeaderOne === 'assessment' &&
        middlePaneHeaderBadgeTwo === 'all' &&
        ALLOCATE_ASSESSMENT_DISTINCT) ||
      (popupHeaderOne === 'assessment' &&
        middlePaneHeaderBadgeTwo === 'active' &&
        ALLOCATE_ASSESSMENT_ACTIVE_DISTINCT) ||
      (popupHeaderOne === 'assessment' &&
        // middlePaneHeaderBadgeTwo === 'inactive' &&
        ALLOCATE_ASSESSMENT_INACTIVE_DISTINCT) ||
      (popupHeaderOne === 'assessments' && ALLOCATE_ASSESSMENT_SUBMODULE) ||
      (popupHeaderOne === 'assignment' &&
        middlePaneHeaderBadgeTwo === 'active' &&
        assignmentCreateType !== 'iGuru Analytics_Occupation' &&
        ALLOCATE_ASSIGNMENT_ACTIVE_DISTINCT) ||
      (popupHeaderOne === 'assignment' &&
        assignmentCreateType !== 'iGuru Analytics_Occupation' &&
        ALLOCATE_ASSIGNMENT_DISTINCT) ||
      (popupHeaderOne === 'assignment' &&
        middlePaneHeaderBadgeTwo === 'active' &&
        assignmentCreateType === 'iGuru Analytics_Occupation' &&
        ALLOCATE_ASSIGNMENT_ACTIVE_DISTINCT_ANALYTICS) ||
      (popupHeaderOne === 'assignment' &&
        middlePaneHeaderBadgeTwo === 'inactive' &&
        assignmentCreateType === 'iGuru Analytics_Occupation' &&
        ALLOCATE_ASSIGNMENT_INACTIVE_DISTINCT_ANALYTICS) ||
      (popupHeaderOne === 'assignment' &&
        assignmentCreateType === 'iGuru Analytics_Occupation' &&
        ALLOCATE_ASSIGNMENT_DISTINCT_ANALYTICS) ||
      (popupHeaderOne === 'assignments' && assignmentCreateType === "iGuru Analytics_Occupation" && ALLOCATE_ASSIGNMENT_DISTINCT_ANALYTICS) ||
      (popupHeaderOne === 'assignments' && ALLOCATE_ASSIGNMENT_SUBMODULE) ||
      (popupHeaderOne === 'associate' &&
        middlePaneHeaderBadgeOne === 'nodes' &&
        ALLOCATE_ASSOCIATE_NODE_DISTINCT) || // associate self node allocate
      (popupHeaderOne === 'associate' && ALLOCATE_ASSOCIATE_DISTINCT) ||
      (popupHeaderOne === 'associates' && ALLOCATE_ASSOCIATE_SUBMODULE) ||
      (popupHeaderOne === 'job-profile' &&
        middlePaneHeaderBadgeTwo === 'inactive' &&
        ALLOCATE_JOB_DISTINCT_INACTIVE) ||
      (popupHeaderOne === 'job-profile' && createMode === 'jobProfile' &&
        ALLOCATE_JOB_DISTINCT_INACTIVE) ||
      (popupHeaderOne === 'job-profile' && ALLOCATE_JOB_DISTINCT) ||
      (popupHeaderOne === 'job-profiles' && ALLOCATE_JOB_SUBMODULE) ||
      (popupHeaderOne === 'item' && ALLOCATE_ITEM_DISTINCT) ||
      (popupHeaderOne === 'items' && ALLOCATE_ITEM_SUBMODULE_DISTINCT) ||
      (popupHeaderOne === 'culture-profile' &&
        middlePaneHeaderBadgeTwo === 'inactive' &&
        ALLOCATE_CULTUREPROFILE_DISTINCT_INACTIVE) ||
      (popupHeaderOne === 'culture-profile' &&
        createMode === 'cultureProfile' &&
        ALLOCATE_CULTUREPROFILE_DISTINCT_INACTIVE) ||
      (popupHeaderOne === 'culture-profile' && ALLOCATE_CULTUREPROFILE_DISTINCT) ||
      (popupHeaderOne === 'culture-profiles' && ALLOCATE_CULTUREPROFILE_SUBMODULE_DISTINCT);
    if (arrayValue) {
      console.log('popupContentArrValue', popupContentArrValue);
      popupContentArrValue.map((element) => {
        // console.log('element.data', element.data,'disable',arrayValue[clickVal.replace('-', '')]?.includes(element.data));
        arr.push({
          ...element,
          disabled: !arrayValue[clickVal.replace('-', '')]?.includes(element.data)
        });
      });
      if (
        tertiaryOptionCheckValue &&
        !arrayValue[clickVal.replace('-', '')]?.includes(tertiaryOptionCheckValue)
      ) {
        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'tertiaryOptionCheckValue', value: '' }
        });
      }
      if (
        secondaryOptionCheckValue &&
        !arrayValue[clickVal.replace('-', '')]?.includes(secondaryOptionCheckValue)
      ) {
        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'secondaryOptionCheckValue', value: '' }
        });
      }
      // if (
      //   (clickVal === 'distinct' ||
      //     clickVal === 'groups' ||
      //     clickVal === 'nodes' ||
      //     clickVal === 'roles' ||
      //     clickVal === 'types') &&
      //   secondaryOptionCheckValue !== 'all'
      // ) {
      //   dispatch({
      //     type: SET_POPUP_SINGLE_STATE,
      //     payload: { stateName: 'secondaryOptionCheckValue', value: '' }
      //   });
      // }
    }
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: {
        stateName: 'popupContentArrValue',
        value: arr.length > 0 ? arr : popupContentArrValue
      }
    });
  };
  const setSecondaryOptionValueTwo = (e) => {
    let sharePoupArr =
      popupHeaderOneBadgeOne === 'distinct' || popupHeaderOneBadgeOne === 'group'
        ? SHARE_POPUP_FEE_POPUP
        : SHARE_NEW_POPUP;
    if (popupHeaderOneBadgeTwo === 'share') {
      let arr = [];
      let clickValue = e.currentTarget.getAttribute('data-value');
      console.log('clickValue', clickValue);
      if (clickValue === 'distinct') {
        sharePoupArr.forEach((element) => {
          if (popupHeaderOneBadgeOne === 'distinct') {
            if (
              element.data === 'ascendant' ||
              element.data === 'peer' ||
              element.data === 'descendant' ||
              element.data === 'node'
            ) {
              arr.push({ ...element, disabled: true });
            } else {
              arr.push(element);
            }
          } else {
            if (
              element.data === 'ascendant' ||
              element.data === 'peer' ||
              element.data === 'descendant' ||
              element.data === 'node'
            ) {
              arr.push({ ...element, disabled: true });
            } else {
              arr.push({ ...element, disabled: false });
            }
          }
        });
        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: {
            stateName: 'popupContentArrValue',
            value: arr
          }
        });
        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: {
            stateName: 'forthOptionCheckValue',
            value: ''
          }
        });
      } else {
        sharePoupArr.forEach((element) => {
          if (popupHeaderOneBadgeOne === 'distinct') {
            if (element.data === 'node') {
              arr.push({ ...element, disabled: true });
            } else {
              arr.push({ ...element, disabled: false });
            }
          } else {
            if (element.data === 'node') {
              arr.push({ ...element, disabled: true });
            } else {
              arr.push({ ...element, disabled: false });
            }
          }
        });
        // console.log('2');
        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: {
            stateName: 'popupContentArrValue',
            value: arr
          }
        });
        if (forthOptionCheckValue === '') {
          dispatch({
            type: SET_POPUP_SINGLE_STATE,
            payload: {
              stateName: 'forthOptionCheckValue',
              value: 'ascendant'
            }
          });
        }
      }
    }
    if (
      popupHeaderOneBadgeTwo === 'allocate'
      // tertiaryOptionCheckValue !== e.currentTarget.getAttribute('data-value')
    ) {
      setEmabledDisableOptionForAllocate(
        e.currentTarget.getAttribute('data-value'),
        assignmentCreateType
      );
    }
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: {
        stateName: 'tertiaryOptionCheckValue',
        value: e.currentTarget.getAttribute('data-value')
      }
    });
  };
  let arr = [];
  const setSecondaryOptionValueThree = (e) => {
    let valueThree = e.currentTarget.getAttribute('data-value');
    let sharePoupArr =
      popupHeaderOneBadgeOne === 'distinct' || popupHeaderOneBadgeOne === 'group'
        ? SHARE_POPUP_FEE_POPUP
        : SHARE_NEW_POPUP;
    if (valueThree === 'peer') {
      // if (popupHeaderOneBadgeOne === 'share') {
      sharePoupArr.forEach((element) => {
        if (popupHeaderOneBadgeOne === 'distinct') {
          if (
            element.data === 'distinct' ||
            element.data === 'primary' ||
            element.data === 'secondary' ||
            element.data === 'node'
          ) {
            arr.push({ ...element, disabled: true });
          } else {
            arr.push({ ...element, disabled: false });
          }
        } else {
          if (
            element.data === 'distinct' ||
            element.data === 'primary' ||
            element.data === 'secondary' ||
            element.data === 'node'
          ) {
            arr.push({ ...element, disabled: true });
          } else {
            arr.push({ ...element, disabled: false });
          }
        }
      });
      dispatch({
        type: SET_POPUP_SINGLE_STATE,
        payload: {
          stateName: 'tertiaryOptionCheckValue',
          value: 'all'
        }
      });
      // }
    } else {
      sharePoupArr.forEach((element) => {
        if (popupHeaderOneBadgeOne === 'distinct') {
          if (element.data === 'distinct' || element.data === 'node') {
            arr.push({ ...element, disabled: true });
          } else {
            arr.push({ ...element, disabled: false });
          }
        } else {
          if (element.data === 'distinct' || element.data === 'node') {
            arr.push({ ...element, disabled: true });
          } else {
            arr.push({ ...element, disabled: false });
          }
        }
      });
      // dispatch({
      //   type: SET_POPUP_SINGLE_STATE,
      //   payload: {
      //     stateName: 'tertiaryOptionCheckValue',
      //     value: 'primary'
      //   }
      // });
    }

    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: {
        stateName: 'popupContentArrValue',
        value: arr
      }
    });

    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: {
        stateName: 'forthOptionCheckValue',
        value: e.currentTarget.getAttribute('data-value')
      }
    });
  };

  // console.log('secondaryOptionCheckValue->>>>', secondaryOptionCheckValue);
  // console.log('tertiaryOptionCheckValue->>>>', tertiaryOptionCheckValue); //

  const ChangeOptionPopup = (e) => {
    let keyVal = e.currentTarget?.getAttribute('data-key');
    let dataVal = e.currentTarget?.getAttribute('data-value');
    let updatedShareFee = 0;
    if (e.customKeys) {
      keyVal = e.customKeyVal;
      dataVal = e.customDataVal;
      updatedShareFee = e.updatedShareFee;
    }
    let isValidAllocationOrUnallocation = false;
    let filterModeKey = '';
    console.log('dataVal', dataVal, 'keyVal', keyVal);
    console.log('popupHeaderOne', popupHeaderOne, typeOfMiddlePaneList, createMode);

    if (dataVal === 'select') {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isSelectActive', value: secondaryOptionCheckValue }
      });
      dispatch({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'selectionValue', value: secondaryOptionCheckValue }
      });
      dispatch({ type: POPUP_CLOSE });
    } else if (dataVal === 'unselect') {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isSelectActive', value: '' }
      });
      dispatch({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'selectionValue', value: '' }
      });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'selectedTagsArray', value: [] }
      });
      dispatch({ type: POPUP_CLOSE });
    }

    if (dataVal === 'resend') {
      dispatch({
        type: SET_MIDDLEPANE_SECONDARY_OPTION,
        payload: { badgeValue: dataVal, keyValue: keyVal, isPopUpFrom: 'reviewList' }
      });
    }

    if (dataVal === 'information' && popupHeaderOneBadgeTwo !== 'create') {
      dispatch({ type: LOADER_START });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'paneThreeSelectedSection', value: 'section1' }
      });
      if (
        typeOfMiddlePaneList === 'assesseesDistinctReviewList' ||
        typeOfMiddlePaneList === 'assesseesSelfReview' ||
        typeOfMiddlePaneList === 'administratorsDistinctReviewList' ||
        typeOfMiddlePaneList === 'assistantsDistinctReviewList' ||
        typeOfMiddlePaneList === 'assesseesGroupAssesseeReviewList' ||
        typeOfMiddlePaneList === 'assesseesRoleAssesseeReviewList' ||
        typeOfMiddlePaneList === 'assesseesNodeAssesseeReviewList' ||
        typeOfMiddlePaneList === 'assignmentDistinctAssesseeReviewList' ||
        typeOfMiddlePaneList === 'assesseesTypeAssesseeReviewList' ||
        typeOfMiddlePaneList === 'managersDistinctReviewList' ||
        createMode === 'assessee'
      ) {
        assesseeReviewInformation(
          selectedAssociateInfo,
          dispatch,
          secondaryOptionCheckValue,
          isReviseMode,
          typeOfMiddlePaneList,
          selectedTagValue,
          createMode,
          popupHeaderOne
        );
      }
      if (
        typeOfMiddlePaneList === 'assesseeRoleDistinctReviewList' ||
        createMode === 'assesseesRole'
      ) {
        assesseeRoleReviewInformation(
          selectedAssociateInfo,
          dispatch,
          secondaryOptionCheckValue,
          isReviseMode,
          typeOfMiddlePaneList,
          selectedTagValue,
          countPage,
          createMode,
          popupHeaderOne
        );
      }
      if (
        typeOfMiddlePaneList === 'associateRoleDistinctReviewList' ||
        createMode === 'associatesRole'
      ) {
        let associateRoleAssociateReqBody = getAssociateRoleAssociateReqObj(
          selectedAssociateInfo,
          selectedTagValue,
          '',
          -1,
          -1
        );
        dispatch({
          type: GET_ASSOCIATE_ROLE_REVIEW_INFO_SAGA,
          payload: {
            secondaryOptionCheckValue,
            associateRoleAssociateReqBody,
            isReviseMode,
            createMode,
            reqBody: {
              module: 'Associate-Role',
              action: 'Review',
              requestObject: {
                assesseeId: selectedAssociateInfo?.assesseeId,
                associateId:
                  selectedAssociateInfo?.associate?.informationEngagement.associateTag
                    .associateTagPrimary,
                filter: 'true',
                searchCondition: 'AND',
                search: [
                  {
                    condition: 'and',
                    searchBy: [
                      {
                        dataType: 'string',
                        conditionColumn: 'id',
                        conditionValue: {
                          condition: 'eq',
                          value: {
                            from: selectedTagValue
                          }
                        }
                      }
                    ]
                  }
                ]
              }
            }
          }
        });
      }
      if (
        typeOfMiddlePaneList === 'associateDistinctReviewList' ||
        typeOfMiddlePaneList === 'associatesRoleAssociateReviewList' ||
        typeOfMiddlePaneList === 'associatesGroupAssociateReviewList' ||
        typeOfMiddlePaneList === 'associateSelfReview' ||
        typeOfMiddlePaneList === 'associatesNodeDistinctReviewList' ||
        typeOfMiddlePaneList === 'nodeAssociatesReviewList' ||
        typeOfMiddlePaneList === 'associatesTypeAssociateReviewList' ||
        createMode === 'associate'
      ) {
        dispatch({
          type: GET_ASSOCIATE_INFO_SAGA,
          payload: {
            secondaryOptionCheckValue,
            popupHeaderOneBadgeOne,
            isReviseMode,
            createMode,
            reqBody: {
              module: 'Associate-Distinct',
              action: 'Review',
              requestObject: {
                assesseeId: selectedAssociateInfo?.assesseeId,
                associateId:
                  selectedAssociateInfo?.associate?.informationEngagement.associateTag
                    .associateTagPrimary, //605255729d3c823d3964e0ec
                filter: true,
                search: [
                  {
                    condition: 'and',
                    searchBy: [
                      {
                        dataType: 'String',
                        conditionColumn: 'id',
                        conditionValue: {
                          condition: 'eq',
                          value: {
                            from: selectedTagValue
                          }
                        }
                      }
                    ]
                  }
                ]
              }
            }
          }
        });
      }
      if (
        typeOfMiddlePaneList === 'associateNodeDistinctReviewList' ||
        createMode === 'associatesNode'
      ) {

        dispatch({ type: LOADER_START });
        let associateNodeReqBody = '';
        let sagaCall = '';
        let isShowAllModule = false;
        if (popupHeaderOne === 'assessees') {
          associateNodeReqBody = getAssesseeNodeAssesseeReqObj(
            selectedAssociateInfo,
            selectedTagValue,
            '',
            -1,
            -1
          );
          isShowAllModule = false;
          sagaCall = GET_ASSESSEENODE_ASSESSEE_REVIEW_LIST;
        }
        if (popupHeaderOne === 'assessments') {
          associateNodeReqBody = {
            module: 'Assessment-Distinct',
            action: 'Review-List',
            actionFilter: 'Associate-Node-Assessment',
            requestObject: getNodeAssessmentsReqObj(
              selectedAssociateInfo,
              selectedTagValue,
              '',
              -1,
              -1
            )
          };
          isShowAllModule = false;
          sagaCall = GET_NODE_ASSESSMENTS_REVIEW_LIST_SAGA;
        }
        if (popupHeaderOne === 'assignments') {
          associateNodeReqBody = {
            module: 'Assignment-Distinct',
            action: 'Review-List',
            actionFilter: 'Associate-Node-Assignment',
            requestObject: getNodeAssignmentsReqObj(
              selectedAssociateInfo,
              selectedTagValue,
              '',
              -1,
              -1
            )
          };
          isShowAllModule = false;
          sagaCall = GET_NODE_ASSIGNMENTS_REVIEW_LIST_SAGA;
        }
        if (popupHeaderOne === 'associate') {
          isShowAllModule = true;
          getRelatedDataAPICall(selectedAssociateInfo, dispatch, selectedTagValue);
        }
        if (popupHeaderOne === 'associates') {
          associateNodeReqBody = getNodeAssociatesReqObj(
            selectedAssociateInfo,
            selectedTagValue,
            '',
            -1,
            -1
          );
          isShowAllModule = false;
          sagaCall = GET_NODE_ASSOCIATE_REVIEW_LIST;
        }
        if (popupHeaderOne === 'items') {
          associateNodeReqBody = getNodeItemsReqObj(
            selectedAssociateInfo,
            selectedTagValue,
            '',
            -1,
            -1
          );
          isShowAllModule = false;
          sagaCall = GET_NODE_ITEMS_REVIEW_LIST_SAGA;
        }
        if (popupHeaderOne === 'culture-profiles') {
          associateNodeReqBody = getNodeCultureProfileReqObj(
            selectedAssociateInfo,
            selectedTagValue,
            '',
            -1,
            -1
          );
          isShowAllModule = false;
          sagaCall = GET_CULTURE_NODE_CULTURE_REVIEW_LIST_SAGA;
        }
        if (popupHeaderOne === 'job-profiles') {
          associateNodeReqBody = getNodeJobProfileReqObj(
            selectedAssociateInfo,
            selectedTagValue,
            '',
            -1,
            -1
          );
          isShowAllModule = false;
          sagaCall = GET_JOB_NODE_JOB_REVIEW_LIST_SAGA;
        }
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
        dispatch({
          type: GET_ASSOCIATE_NODE_REVIEW_INFO_SAGA,
          payload: {
            secondaryOptionCheckValue,
            associateNodeReqBody: associateNodeReqBody,
            selectedModule: middlePaneHeader ? middlePaneHeader : 'associate',
            getReviewListSaga: sagaCall,
            isShowAllModule,
            isReviseMode,
            createMode,
            reqBody: {
              module: 'Associate-Node',
              action: 'Review',
              requestObject: {
                assesseeId: selectedAssociateInfo?.assesseeId,
                associateId:
                  selectedAssociateInfo?.associate?.informationEngagement.associateTag
                    .associateTagPrimary, //605255729d3c823d3964e0ec
                filter: true,
                search: [
                  {
                    condition: 'and',
                    searchBy: [
                      {
                        dataType: 'String',
                        conditionColumn: 'id',
                        conditionValue: {
                          condition: 'eq',
                          value: {
                            from: selectedTagValue
                          }
                        }
                      }
                    ]
                  }
                ]
              }
            }
          }
        });
      }
      if (
        typeOfMiddlePaneList === 'assessmentDistinctReviewList' ||
        typeOfMiddlePaneList === 'assessmentGroupAssessmentReviewList' ||
        typeOfMiddlePaneList === 'assessmentTypeAssessmentReviewList' ||
        typeOfMiddlePaneList === 'assessmentNodeAssessmentReviewList' ||
        typeOfMiddlePaneList === 'jobProfilepAssessmentReviewList' ||
        typeOfMiddlePaneList === 'cultureProfileAssessmentReviewList' ||
        createMode === 'assessment'
      ) {
        dispatch({
          type: GET_ASSESSMENT_INFO_SAGA,
          payload: {
            secondaryOptionCheckValue,
            isReviseMode,
            createMode,
            reqBody: {
              module: 'Assessment-Distinct',
              action: 'Review',
              requestObject: {
                assesseeId: selectedAssociateInfo?.assesseeId,
                associateId:
                  selectedAssociateInfo?.associate?.informationEngagement.associateTag
                    .associateTagPrimary, //605255729d3c823d3964e0ec
                filter: true,
                search: [
                  {
                    condition: 'and',
                    searchBy: [
                      {
                        dataType: 'String',
                        conditionColumn: 'id',
                        conditionValue: {
                          condition: 'eq',
                          value: {
                            from: selectedTagValue
                          }
                        }
                      }
                    ]
                  }
                ]
              }
            }
          }
        });
      }
      if (typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList') {
        dispatch({
          type: GET_ASSIGNMENT_ASSESSMENT_INFO_SAGA,
          payload: {
            secondaryOptionCheckValue,
            isReviseMode,
            reqBody: {
              module: 'Assessment-Distinct',
              action: 'Review',
              actionFilter: 'Assignment-Assessment',
              requestObject: {
                assesseeId: selectedAssociateInfo?.assesseeId,
                associateId:
                  selectedAssociateInfo?.associate?.informationEngagement.associateTag
                    .associateTagPrimary,
                assignmentId: relatedReviewListDistinctData[0]?.id,
                filter: true,
                search: [
                  {
                    condition: 'and',
                    searchBy: [
                      {
                        dataType: 'String',
                        conditionColumn: 'id',
                        conditionValue: {
                          condition: 'eq',
                          value: {
                            from: selectedTagValue
                          }
                        }
                      }
                    ]
                  }
                ]
              }
            }
          }
        });
      }
      if (
        typeOfMiddlePaneList === 'assignmentDistinctReviewList' ||
        typeOfMiddlePaneList === 'assignmentTypeAssignmentReviewList' ||
        typeOfMiddlePaneList === 'assignmentNodeAssignmentReviewList' ||
        typeOfMiddlePaneList === 'assignmentGroupAssignmentReviewList' ||
        typeOfMiddlePaneList === 'assesseeGroupAssesseeAssignmentDistinct' ||
        createMode === 'assignment'
      ) {
        let relatedReqObj = {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          assignmentId: selectedTagValue
        };
        dispatch({
          type: GET_ASSIGNMENT_INFO_SAGA,
          payload: {
            secondaryOptionCheckValue,
            relatedReqObj,
            isReviseMode,
            createMode,
            reqBody: {
              module: 'Assignment-Distinct',
              action: 'Review',
              requestObject: {
                assesseeId: selectedAssociateInfo?.assesseeId,
                associateId:
                  selectedAssociateInfo?.associate?.informationEngagement.associateTag
                    .associateTagPrimary, //605255729d3c823d3964e0ec
                filter: true,
                search: [
                  {
                    condition: 'and',
                    searchBy: [
                      {
                        dataType: 'String',
                        conditionColumn: 'id',
                        conditionValue: {
                          condition: 'eq',
                          value: {
                            from: selectedTagValue
                          }
                        }
                      }
                    ]
                  }
                ]
              }
            }
          }
        });
      }
      if (
        typeOfMiddlePaneList === 'itemsDistinctReviewList' ||
        typeOfMiddlePaneList === 'assessmentItemReviewList' ||
        typeOfMiddlePaneList === 'itemGroupItemReviewList' ||
        typeOfMiddlePaneList === 'itemNodeItemReviewList' ||
        typeOfMiddlePaneList === 'itemTypeItemReviewList' ||
        createMode === 'item'
      ) {
        getItemReviewApiCall(
          selectedAssociateInfo,
          dispatch,
          secondaryOptionCheckValue,
          isReviseMode,
          selectedTagValue,
          createMode
        );
        dispatch({
          type: GET_FRAMWORK_TYPE_REVIEW_LIST_SAGA,
          payload: {
            request: {
              module: 'Item-Distinct',
              action: 'Review-List',
              actionFilter: 'Item-Framework-One',
              requestObject: {
                assesseeId: selectedAssociateInfo?.assesseeId,
                associateId:
                  selectedAssociateInfo?.associate?.informationEngagement.associateTag
                    .associateTagPrimary
              }
            }
          }
        });
      }
      if (
        typeOfMiddlePaneList === 'jobProfilesDistinctReviewList' ||
        typeOfMiddlePaneList === 'jobProfileGroupJobProfileReviewList' ||
        typeOfMiddlePaneList === 'jobProfileTypeJobProfileReviewList' ||
        typeOfMiddlePaneList === 'assignmentDistinctJobProfileReviewList' ||
        typeOfMiddlePaneList === 'jobProfileNodeJobProfileReviewList' ||
        createMode === 'jobProfile' ||
        createMode === 'job-profile'
      ) {
        let searchObj = {
          condition: 'eq',
          value: {
            from: secondaryOptionCheckValue.toUpperCase()
          }
        };
        if (secondaryOptionCheckValue === 'all') {
          searchObj = {
            condition: 'in',
            value: {
              in: ['SUSPENDED', 'TERMINATED', 'ACTIVE', 'ARCHIVED']
            }
          };
        }
        let jobProfileReqBody = {
          module: 'Job-Profile-Distinct',
          action: 'Review-List',
          actionFilter: 'Job-Profile-Assessment',
          requestObject: {
            assesseeId: selectedAssociateInfo?.assesseeId,
            associateId:
              selectedAssociateInfo?.associate?.informationEngagement.associateTag
                .associateTagPrimary,
            filter: 'true',
            numberPage: 0,
            countPage: countPage,
            searchCondition: 'AND',
            jobProfileId: selectedTagValue,
            orderBy: {
              columnName: 'informationBasic.assessmentName,informationBasic.assessmentDescription',
              order: 'asc'
            },
            search: [
              {
                condition: 'and',
                searchBy: [
                  {
                    dataType: 'string',
                    conditionColumn: 'informationEngagement.assessmentStatus',
                    conditionValue: searchObj
                  }
                ]
              }
            ]
          }
        };
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'jobProfilerReviewList', value: null }
        });
        dispatch({
          type: GET_JOB_PROFILE_INFO_SAGA,
          payload: {
            secondaryOptionCheckValue,
            isReviseMode,
            jobProfileReqBody,
            createMode,
            reqBody: {
              module: 'Job-Profile-Distinct',
              action: 'Review',
              requestObject: {
                assesseeId: selectedAssociateInfo?.assesseeId,
                associateId:
                  selectedAssociateInfo?.associate?.informationEngagement.associateTag
                    .associateTagPrimary, //605255729d3c823d3964e0ec
                filter: true,
                search: [
                  {
                    condition: 'and',
                    searchBy: [
                      {
                        dataType: 'String',
                        conditionColumn: 'id',
                        conditionValue: {
                          condition: 'eq',
                          value: {
                            from: selectedTagValue
                          }
                        }
                      }
                    ]
                  }
                ]
              }
            }
          }
        });
      }
      if (
        typeOfMiddlePaneList === 'cultureProfilesDistinctReviewList' ||
        typeOfMiddlePaneList === 'cultureProfileGroupCultureProfileReviewList' ||
        typeOfMiddlePaneList === 'cultureProfileTypeCultureProfileReviewList' ||
        typeOfMiddlePaneList === 'assignmentDistinctCultureProfileReviewList' ||
        typeOfMiddlePaneList === 'cultureProfileNodeCultureProfileReviewList' ||
        typeOfMiddlePaneList === 'cultureProfileTypeCultureProfileReviewList' ||
        createMode === 'cultureProfile'
      ) {
        let searchObj = {
          condition: 'eq',
          value: {
            from: secondaryOptionCheckValue.toUpperCase()
          }
        };
        if (secondaryOptionCheckValue === 'all') {
          searchObj = {
            condition: 'in',
            value: {
              in: ['SUSPENDED', 'TERMINATED', 'ACTIVE', 'ARCHIVED', 'PUBLISHED', 'UNPUBLISHED']
            }
          };
        }
        const cultureAssessmentReqBody = {
          module: 'Culture-Profile-Distinct',
          action: 'Review-List',
          actionFilter: 'Culture-Profile-Assessment',
          requestObject: {
            assesseeId: selectedAssociateInfo?.assesseeId,
            associateId:
              selectedAssociateInfo?.associate?.informationEngagement.associateTag
                .associateTagPrimary,
            filter: 'true',
            numberPage: 0,
            countPage: countPage,
            searchCondition: 'AND',
            cultureProfileId: selectedTagValue,
            orderBy: {
              columnName: 'informationBasic.assessmentName,informationBasic.assessmentDescription',
              order: 'asc'
            },
            search: [
              {
                condition: 'and',
                searchBy: [
                  {
                    dataType: 'string',
                    conditionColumn: 'informationEngagement.assessmentStatus',
                    conditionValue: searchObj
                  }
                ]
              }
            ]
          }
        };
        dispatch({
          type: GET_CULTURE_PROFILE_INFO_SAGA,
          payload: {
            secondaryOptionCheckValue,
            isReviseMode,
            cultureAssessmentReqBody,
            createMode,
            reqBody: {
              module: 'Culture-Profile-Distinct',
              action: 'Review',
              requestObject: {
                assesseeId: selectedAssociateInfo?.assesseeId,
                associateId:
                  selectedAssociateInfo?.associate?.informationEngagement.associateTag
                    .associateTagPrimary, //605255729d3c823d3964e0ec
                filter: true,
                search: [
                  {
                    condition: 'and',
                    searchBy: [
                      {
                        dataType: 'String',
                        conditionColumn: 'id',
                        conditionValue: {
                          condition: 'eq',
                          value: {
                            from: selectedTagValue
                          }
                        }
                      }
                    ]
                  }
                ]
              }
            }
          }
        });
      }
      if (
        typeOfMiddlePaneList === 'assesseesGroupDistinctReviewList' ||
        createMode === 'assesseesGroup'
      ) {
        let assesseeGroupAssesseeReqBody = getAssesseeGroupAssesseeReqObj(
          selectedAssociateInfo,
          selectedTagValue,
          '',
          -1,
          -1
        );
        dispatch({
          type: GET_ASSESSEE_GROUP_REVIEW_INFO_SAGA,
          payload: {
            middlePaneHeader: popupHeaderOne,
            secondaryOptionCheckValue: 'key',
            isReviseMode,
            assesseeGroupAssesseeReqBody,
            createMode,
            reqBody: {
              module: 'Assessee-Group',
              action: 'Review',
              requestObject: {
                assesseeId: selectedAssociateInfo?.assesseeId,
                associateId:
                  selectedAssociateInfo?.associate?.informationEngagement.associateTag
                    .associateTagPrimary, //605255729d3c823d3964e0ec
                filter: true,
                search: [
                  {
                    condition: 'and',
                    searchBy: [
                      {
                        dataType: 'String',
                        conditionColumn: 'id',
                        conditionValue: {
                          condition: 'eq',
                          value: {
                            from: selectedTagValue
                          }
                        }
                      }
                    ]
                  }
                ]
              }
            }
          }
        });
      }
      if (
        typeOfMiddlePaneList === 'associatesGroupDistinctReviewList' ||
        createMode === 'associatesGroup'
      ) {
        let associateGroupAssociateReqBody = getAssociateGroupAssociateReqObj(
          selectedAssociateInfo,
          selectedTagValue,
          '', //'all',
          -1,
          -1
        );
        dispatch({
          type: GET_ASSOCIATE_GROUP_REVIEW_INFO_SAGA,
          payload: {
            secondaryOptionCheckValue: 'key',
            isReviseMode,
            associateGroupAssociateReqBody,
            createMode,
            reqBody: {
              module: 'Associate-Group',
              action: 'Review',
              requestObject: {
                assesseeId: selectedAssociateInfo?.assesseeId,
                associateId:
                  selectedAssociateInfo?.associate?.informationEngagement.associateTag
                    .associateTagPrimary, //605255729d3c823d3964e0ec
                filter: true,
                search: [
                  {
                    condition: 'and',
                    searchBy: [
                      {
                        dataType: 'String',
                        conditionColumn: 'id',
                        conditionValue: {
                          condition: 'eq',
                          value: {
                            from: selectedTagValue
                          }
                        }
                      }
                    ]
                  }
                ]
              }
            }
          }
        });
      }
      if (
        typeOfMiddlePaneList === 'assessmentsGroupDistinctReviewList' ||
        createMode === 'assessmentsGroup'
      ) {
        let assessmentGroupAssessmentReqBody = getAssessmentGroupAssessmentReqObj(
          selectedAssociateInfo,
          selectedTagValue,
          '',
          -1,
          -1
        );
        dispatch({ type: LOADER_START });
        dispatch({
          type: GET_ASSESSMENT_GROUP_REVIEW_INFO_SAGA,
          payload: {
            secondaryOptionCheckValue: 'key',
            isReviseMode,
            assessmentGroupAssessmentReqBody,
            createMode,
            reqBody: {
              module: 'Assessment-Group',
              action: 'Review',
              requestObject: {
                assesseeId: selectedAssociateInfo?.assesseeId,
                associateId:
                  selectedAssociateInfo?.associate?.informationEngagement.associateTag
                    .associateTagPrimary,
                filter: 'true',
                search: [
                  {
                    condition: 'and',
                    searchBy: [
                      {
                        dataType: 'string',
                        conditionColumn: 'id',
                        conditionValue: {
                          condition: 'eq',
                          value: {
                            from: selectedTagValue
                          }
                        }
                      }
                    ]
                  }
                ]
              }
            }
          }
        });
      }
      if (
        typeOfMiddlePaneList === 'assignmentsGroupDistinctReviewList' ||
        createMode === 'assignmentsGroup'
      ) {
        let assignmentGroupAssignmentReqBody = {
          module: 'Assignment-Group',
          action: 'Review-List',
          actionFilter: 'Assignment-Group-Assignment',
          requestObject: getAssignmentGroupAssignmentReqObj(
            selectedAssociateInfo,
            selectedTagValue,
            '', //'all',
            -1,
            -1
          )
        };
        dispatch({
          type: GET_ASSIGNMENT_GROUP_REVIEW_INFO_SAGA,
          payload: {
            secondaryOptionCheckValue: 'key',
            isReviseMode,
            assignmentGroupAssignmentReqBody,
            createMode,
            reqBody: {
              module: 'Assignment-Group',
              action: 'Review',
              requestObject: {
                assesseeId: selectedAssociateInfo?.assesseeId,
                associateId:
                  selectedAssociateInfo?.associate?.informationEngagement.associateTag
                    .associateTagPrimary,
                filter: 'true',
                search: [
                  {
                    condition: 'and',
                    searchBy: [
                      {
                        dataType: 'string',
                        conditionColumn: 'id',
                        conditionValue: {
                          condition: 'eq',
                          value: {
                            from: selectedTagValue
                          }
                        }
                      }
                    ]
                  }
                ]
              }
            }
          }
        });
      }

      if (
        typeOfMiddlePaneList === 'cultureProfilesGroupDistinctReviewList' ||
        createMode === 'culture-profilesGroup'
      ) {
        let cultureGroupCultureReqBody = getCultureGroupCultureReqObj(
          selectedAssociateInfo,
          selectedTagValue,
          '', //'all',
          -1,
          -1
        );
        dispatch({
          type: GET_CULTURE_GROUP_REVIEW_INFO_SAGA,
          payload: {
            secondaryOptionCheckValue: 'key',
            isReviseMode,
            cultureGroupCultureReqBody,
            createMode,
            reqBody: {
              module: 'Culture-Profile-Group',
              action: 'Review',
              requestObject: {
                assesseeId: selectedAssociateInfo?.assesseeId,
                associateId:
                  selectedAssociateInfo?.associate?.informationEngagement.associateTag
                    .associateTagPrimary,
                filter: 'true',
                search: [
                  {
                    condition: 'and',
                    searchBy: [
                      {
                        dataType: 'string',
                        conditionColumn: 'id',
                        conditionValue: {
                          condition: 'eq',
                          value: {
                            from: selectedTagValue
                          }
                        }
                      }
                    ]
                  }
                ]
              }
            }
          }
        });
      }
      if (
        typeOfMiddlePaneList === 'jobProfilesGroupDistinctReviewList' ||
        createMode === 'job-profilesGroup'
      ) {
        let jobGroupJobReqBody = getJobProfileGroupJobProfileReqObj(
          selectedAssociateInfo,
          selectedTagValue,
          '',
          -1,
          -1
        );
        dispatch({
          type: GET_JOB_GROUP_REVIEW_INFO_SAGA,
          payload: {
            secondaryOptionCheckValue: 'key',
            isReviseMode,
            jobGroupJobReqBody,
            createMode,
            reqBody: {
              module: 'Job-Profile-Group',
              action: 'Review',
              requestObject: {
                assesseeId: selectedAssociateInfo?.assesseeId,
                associateId:
                  selectedAssociateInfo?.associate?.informationEngagement.associateTag
                    .associateTagPrimary,
                filter: 'true',
                search: [
                  {
                    condition: 'and',
                    searchBy: [
                      {
                        dataType: 'string',
                        conditionColumn: 'id',
                        conditionValue: {
                          condition: 'eq',
                          value: {
                            from: selectedTagValue
                          }
                        }
                      }
                    ]
                  }
                ]
              }
            }
          }
        });
      }
      if (typeOfMiddlePaneList === 'itemsGroupDistinctReviewList' || createMode === 'itemsGroup') {
        let itemGroupItemReqBody = getItemGroupItemReqObj(
          selectedAssociateInfo,
          selectedTagValue,
          '',
          -1,
          -1
        );
        dispatch({
          type: GET_ITEM_GROUP_REVIEW_INFO_SAGA,
          payload: {
            secondaryOptionCheckValue: 'key',
            isReviseMode,
            itemGroupItemReqBody,
            createMode,
            reqBody: {
              module: 'Item-Group',
              action: 'Review',
              requestObject: {
                assesseeId: selectedAssociateInfo?.assesseeId,
                associateId:
                  selectedAssociateInfo?.associate?.informationEngagement.associateTag
                    .associateTagPrimary,
                filter: 'true',
                search: [
                  {
                    condition: 'and',
                    searchBy: [
                      {
                        dataType: 'string',
                        conditionColumn: 'id',
                        conditionValue: {
                          condition: 'eq',
                          value: {
                            from: selectedTagValue
                          }
                        }
                      }
                    ]
                  }
                ]
              }
            }
          }
        });
        let typeRequestObj = makeItemsTypeObj(selectedAssociateInfo, 'active', 0, -1);
        dispatch({ type: SET_CORE_TYPE_REVIEW_LIST_REQ_OBJECT, payload: typeRequestObj });
        dispatch({
          type: GET_ITEM_TYPE_REVIEW_LIST_SAGA,
          payload: {
            request: typeRequestObj,
            BadgeOne: '',
            BadgeTwo: '',
            BadgeThree: '',
            isMiddlePaneList: false
          }
        });
      }
      if (
        typeOfMiddlePaneList === 'cultureProfilesTypeDistinctReviewList' ||
        createMode === 'culture-profilesType'
      ) {
        let cultureTypeCultureReqBody = getCultureTypeCultureReqObj(
          selectedAssociateInfo,
          selectedTagValue,
          '',
          0,
          countPage
        );
        dispatch({
          type: GET_CULTURE_TYPE_REVIEW_INFO_SAGA,
          payload: {
            secondaryOptionCheckValue: 'key',
            isReviseMode,
            createMode,
            cultureTypeCultureReqBody,
            reqBody: {
              module: 'Culture-Profile-Type',
              action: 'Review',
              requestObject: {
                assesseeId: selectedAssociateInfo?.assesseeId,
                associateId:
                  selectedAssociateInfo?.associate?.informationEngagement.associateTag
                    .associateTagPrimary,
                filter: 'true',
                search: [
                  {
                    condition: 'and',
                    searchBy: [
                      {
                        dataType: 'string',
                        conditionColumn: 'id',
                        conditionValue: {
                          condition: 'eq',
                          value: {
                            from: selectedTagValue
                          }
                        }
                      }
                    ]
                  }
                ]
              }
            }
          }
        });
      }
      if (
        typeOfMiddlePaneList === 'jobProfilesTypeDistinctReviewList' ||
        createMode === 'job-profilesType'
      ) {
        let jobTypeJobReqBody = getJobProfileTypeJobProfileReqObj(
          selectedAssociateInfo,
          selectedTagValue,
          '', //'active',
          0,
          countPage
        );
        dispatch({
          type: GET_JOB_TYPE_REVIEW_INFO_SAGA,
          payload: {
            secondaryOptionCheckValue: 'key',
            isReviseMode,
            createMode,
            jobTypeJobReqBody,
            reqBody: {
              module: 'Job-Profile-Type',
              action: 'Review',
              requestObject: {
                assesseeId: selectedAssociateInfo?.assesseeId,
                associateId:
                  selectedAssociateInfo?.associate?.informationEngagement.associateTag
                    .associateTagPrimary,
                filter: 'true',
                search: [
                  {
                    condition: 'and',
                    searchBy: [
                      {
                        dataType: 'string',
                        conditionColumn: 'id',
                        conditionValue: {
                          condition: 'eq',
                          value: {
                            from: selectedTagValue
                          }
                        }
                      }
                    ]
                  }
                ]
              }
            }
          }
        });
      }
      if (typeOfMiddlePaneList === 'itemsTypeDistinctReviewList' || createMode === 'itemsType') {
        let itemTypeItemReqBody = getItemTypeItemReqObj(
          selectedAssociateInfo,
          selectedTagValue,
          '',
          0,
          countPage
        );
        dispatch({
          type: GET_ITEM_TYPE_REVIEW_INFO_SAGA,
          payload: {
            secondaryOptionCheckValue: 'key',
            isReviseMode,
            createMode,
            itemTypeItemReqBody,
            reqBody: {
              action: 'Review',
              module: 'Item-Type',
              requestObject: {
                assesseeId: selectedAssociateInfo?.assesseeId,
                associateId:
                  selectedAssociateInfo?.associate?.informationEngagement.associateTag
                    .associateTagPrimary,
                filter: 'true',
                search: [
                  {
                    condition: 'and',
                    searchBy: [
                      {
                        dataType: 'string',
                        conditionColumn: 'id',
                        conditionValue: {
                          condition: 'eq',
                          value: {
                            from: selectedTagValue
                          }
                        }
                      }
                    ]
                  }
                ]
              }
            }
          }
        });
      }
      if (
        typeOfMiddlePaneList === 'assessmentsTypeDistinctReviewList' ||
        createMode === 'assessmentsType'
      ) {
        let assessmentTypeAssessmentReqBody = getAssessmentTypeAssessmentReqObj(
          selectedAssociateInfo,
          selectedTagValue,
          '', // 'active',
          0,
          countPage
        );
        dispatch({
          type: GET_ASSESSMENT_TYPE_REVIEW_INFO_SAGA,
          payload: {
            secondaryOptionCheckValue: 'key',
            isReviseMode,
            createMode,
            assessmentTypeAssessmentReqBody,
            reqBody: {
              module: 'Assessment-Type',
              action: 'Review',
              requestObject: {
                assesseeId: selectedAssociateInfo?.assesseeId,
                associateId:
                  selectedAssociateInfo?.associate?.informationEngagement.associateTag
                    .associateTagPrimary,
                filter: 'true',
                search: [
                  {
                    condition: 'and',
                    searchBy: [
                      {
                        dataType: 'string',
                        conditionColumn: 'id',
                        conditionValue: {
                          condition: 'eq',
                          value: {
                            from: selectedTagValue
                          }
                        }
                      }
                    ]
                  }
                ]
              }
            }
          }
        });
      }
      if (
        typeOfMiddlePaneList === 'assignmentsTypeDistinctReviewList' ||
        createMode === 'assignmentsType'
      ) {
        let assignmentTypeAssignmentReqBody = {
          module: 'Assignment-Type',
          action: 'Review-List',
          actionFilter: 'Assignment-Type-Assignment',
          requestObject: getAssignmentTypeAssignmentReqObj(
            selectedAssociateInfo,
            selectedTagValue,
            '',
            //'active',
            0,
            countPage,
            assignmentCreateType
          )
        };
        dispatch({
          type: GET_ASSIGNMENT_TYPE_REVIEW_INFO_SAGA,
          payload: {
            secondaryOptionCheckValue: 'key',
            isReviseMode,
            createMode,
            assignmentTypeAssignmentReqBody,
            reqBody: {
              module: 'Assignment-Type',
              action: 'Review',
              requestObject: {
                assesseeId: selectedAssociateInfo?.assesseeId,
                associateId:
                  selectedAssociateInfo?.associate?.informationEngagement.associateTag
                    .associateTagPrimary,
                filter: 'true',
                search: [
                  {
                    condition: 'and',
                    searchBy: [
                      {
                        dataType: 'string',
                        conditionColumn: 'id',
                        conditionValue: {
                          condition: 'eq',
                          value: {
                            from: selectedTagValue
                          }
                        }
                      }
                    ]
                  }
                ]
              }
            }
          }
        });
      }
      if (
        typeOfMiddlePaneList === 'assesseesTypeDistinctReviewList' ||
        createMode === 'assesseesType'
      ) {
        let assesseeTypeAssesseeReqBody = getAssesseeTypeAssesseeReqObj(
          selectedAssociateInfo,
          selectedTagValue,
          '',
          -1,
          -1
        );
        dispatch({
          type: GET_ASSESSEE_TYPE_REVIEW_INFO_SAGA,
          payload: {
            middlePaneHeader: popupHeaderOne,
            secondaryOptionCheckValue: 'key',
            assesseeTypeAssesseeReqBody,
            isReviseMode,
            createMode,
            reqBody: {
              module: 'Assessee-Type',
              action: 'Review',
              requestObject: {
                assesseeId: selectedAssociateInfo?.assesseeId,
                associateId:
                  selectedAssociateInfo?.associate?.informationEngagement.associateTag
                    .associateTagPrimary,
                filter: 'true',
                search: [
                  {
                    condition: 'and',
                    searchBy: [
                      {
                        dataType: 'string',
                        conditionColumn: 'id',
                        conditionValue: {
                          condition: 'eq',
                          value: {
                            from: selectedTagValue
                          }
                        }
                      }
                    ]
                  }
                ]
              }
            }
          }
        });
      }
      if (
        typeOfMiddlePaneList === 'associatesTypeDistinctReviewList' ||
        createMode === 'associatesType'
      ) {
        let associateTypeAssociateReqBody = getAssociateTypeAssociateReqObj(
          selectedAssociateInfo,
          selectedTagValue,
          '',
          -1,
          -1
        );
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
        dispatch({
          type: GET_ASSOCIATE_TYPE_REVIEW_INFO_SAGA,
          payload: {
            secondaryOptionCheckValue: 'key',
            isReviseMode,
            createMode,
            associateTypeAssociateReqBody,
            reqBody: {
              module: 'Associate-Type',
              action: 'Review',
              requestObject: {
                assesseeId: selectedAssociateInfo?.assesseeId,
                associateId:
                  selectedAssociateInfo?.associate?.informationEngagement.associateTag
                    .associateTagPrimary,
                filter: 'true',
                search: [
                  {
                    condition: 'and',
                    searchBy: [
                      {
                        dataType: 'string',
                        conditionColumn: 'id',
                        conditionValue: {
                          condition: 'eq',
                          value: {
                            from: selectedTagValue
                          }
                        }
                      }
                    ]
                  }
                ]
              }
            }
          }
        });
      }
      if (typeOfMiddlePaneList === 'assessmentsectionsReviewList') {
        console.log('assessmentResponseObject', assessmentResponseObject);
        // let sectionResponseData = relatedReviewListDistinctData[0].sections[selectedTagValue];
        let sectionResponseData =
          assessmentResponseObject.informationFramework.assessmentSectionFramework[
          selectedTagValue
          ];
        dispatch({
          type: SET_DISPLAY_PANE_THREE_STATE,
          payload: {
            headerOne: 'assessment',
            headerOneBadgeOne: 'section',
            headerOneBadgeTwo: 'information',
            headerOneBadgeThree: 'key',
            responseObject: sectionResponseData,
            reviewMode: isReviseMode ? 'revise' : ''
          }
        });
        if (sectionResponseData.assessmentSectionTime) {
          sectionResponseData = {
            ...sectionResponseData,
            assessmentSectionTime: sectionResponseData.assessmentSectionTime
          };
        }
        dispatch({
          type: SET_SECTION_REDUCER_STATE,
          payload: sectionResponseData
        });
        dispatch({
          type: SET_ASSESSMENT_SECTION_DYNAMIC_FRAMEWORK_STATE,
          payload: {
            stateName: 'assessmentSectionItemFrameworkOneDistinct',
            value: sectionResponseData?.assessmentVersionFramework[0]?.assessmentVersionItemDistinct
            //value: sectionResponseData.assessmentSectionItemDistinct
          }
        });
        //if (sectionResponseData.assessmentSectionItemDistinct) {
        if (sectionResponseData?.assessmentVersionFramework) {
          setItemTypeConfigState(
            // sectionResponseData.assessmentSectionItemDistinct[0].itemFrameworkOne
            //   .itemFrameworkOneType,
            sectionResponseData?.assessmentVersionFramework[0]?.assessmentVersionItemDistinct[0]
              ?.itemFrameworkOne?.itemFrameworkOneType,
            dispatch
          );
          dispatch({
            type: SET_ASSESSMENT_SECTION_DYNAMIC_FRAMEWORK_STATE,
            payload: {
              stateName: 'assessmentSectionItemDistinctReviseObject',
              value:
                sectionResponseData?.assessmentVersionFramework[0]?.assessmentVersionItemDistinct[0]
              // assessmentSectionItemDistinct[0]
            }
          });
        }

        dispatch({ type: LOADER_STOP });
      }
      if (typeOfMiddlePaneList === 'assessmentscalesReviewList') {
        let responseObj = relatedReviewListDistinctData[0].scales.filter((dd) => {
          return dd.id === selectedTagValue;
        });
        dispatch({
          type: SET_DISPLAY_PANE_THREE_STATE,
          payload: {
            headerOne: 'assessment',
            headerOneBadgeOne: 'scale',
            headerOneBadgeTwo: 'information',
            headerOneBadgeThree: 'key',
            responseObject: responseObj[0],
            reviewMode: isReviseMode ? 'revise' : ''
          }
        });
        dispatch({
          type: SET_SCALE_REDUCER_STATE,
          payload: responseObj[0]
        });
        dispatch({ type: LOADER_STOP });
      }
      if (typeOfMiddlePaneList === 'assessmentscoresReviewList') {
        let responseObj = relatedReviewListDistinctData[0].scores.filter((dd) => {
          return dd.id === selectedTagValue;
        });
        console.log(
          'relatedReviewListDistinctData[0].scores >>>',
          relatedReviewListDistinctData[0].scores
        );
        console.log('responseObj[0]>>>>', responseObj[0]);
        dispatch({
          type: SET_DISPLAY_PANE_THREE_STATE,
          payload: {
            headerOne: 'assessment',
            headerOneBadgeOne: 'score',
            headerOneBadgeTwo: 'information',
            headerOneBadgeThree: 'key',
            responseObject: responseObj[0],
            reviewMode: isReviseMode ? 'revise' : ''
          }
        });
        dispatch({
          type: SET_SCORE_REDUCER_STATE,
          payload: responseObj[0]
        });
        dispatch({ type: LOADER_STOP });
      }
      if (typeOfMiddlePaneList === 'assessmentgradesReviewList') {
        let responseObj = relatedReviewListDistinctData[0].grades.filter((dd) => {
          return dd.id === selectedTagValue;
        });
        dispatch({
          type: SET_DISPLAY_PANE_THREE_STATE,
          payload: {
            headerOne: 'assessment',
            headerOneBadgeOne: 'grade',
            headerOneBadgeTwo: 'information',
            headerOneBadgeThree: 'key',
            responseObject: responseObj[0],
            reviewMode: isReviseMode ? 'revise' : ''
          }
        });
        dispatch({
          type: SET_GRADE_REDUCER_STATE,
          payload: responseObj[0]
        });
        dispatch({ type: LOADER_STOP });
      }
      if (typeOfMiddlePaneList === 'assessmentclustersReviewList') {
        let responseObj = relatedReviewListDistinctData[0].clusters.filter((dd) => {
          return dd.id === selectedTagValue;
        });
        dispatch({
          type: SET_DISPLAY_PANE_THREE_STATE,
          payload: {
            headerOne: 'assessment',
            headerOneBadgeOne: 'cluster',
            headerOneBadgeTwo: 'information',
            headerOneBadgeThree: 'key',
            responseObject: responseObj[0],
            reviewMode: isReviseMode ? 'revise' : ''
          }
        });
        dispatch({
          type: SET_CLUSTER_REDUCER_STATE,
          payload: responseObj[0]
        });
        dispatch({ type: LOADER_STOP });
      }
      if (typeOfMiddlePaneList === 'assessmentnormsReviewList') {
        let responseObj = relatedReviewListDistinctData[0].norms.filter((dd) => {
          return dd.id === selectedTagValue;
        });
        dispatch({
          type: SET_DISPLAY_PANE_THREE_STATE,
          payload: {
            headerOne: 'assessment',
            headerOneBadgeOne: 'norm',
            headerOneBadgeTwo: 'information',
            headerOneBadgeThree: 'key',
            responseObject: responseObj[0],
            reviewMode: isReviseMode ? 'revise' : ''
          }
        });
        dispatch({
          type: SET_NORM_REDUCER_STATE,
          payload: responseObj[0]
        });
        dispatch({ type: LOADER_STOP });
      }
      if (typeOfMiddlePaneList === 'assessmentversionsReviewList') {
        dispatch({
          type: SET_DISPLAY_PANE_THREE_STATE,
          payload: {
            headerOne: 'assessment',
            headerOneBadgeOne: 'version',
            headerOneBadgeTwo: 'information',
            headerOneBadgeThree: 'key',
            responseObject: relatedReviewListDistinctData[0].versions[selectedTagValue],
            reviewMode: isReviseMode ? 'revise' : ''
          }
        });
        let objectData = JSON.parse(
          JSON.stringify(
            relatedReviewListDistinctData[0].versions[selectedTagValue]
              .assessmentVersionItemDistinct
          )
        );
        let itemIds = objectData.map((v) => {
          return v.itemId;
        });
        let reducerObj = {
          ...relatedReviewListDistinctData[0].versions[selectedTagValue],
          assessmentVersionItemDistinct: itemIds
        };
        dispatch({
          type: SET_VERSION_REDUCER_STATE,
          payload: reducerObj
        });
        dispatch({ type: LOADER_STOP });
      }
      if (typeOfMiddlePaneList === 'assessmentDistinctSubScaleDistinct') {
        //let responseObj = relatedReviewListDistinctData[0].scales[selectedTagValue];
        let selectedMetrics =
          relatedReviewListDistinctData[0].subscale.assessmentScaleMetric[selectedTagValue];
        let responseObject = [
          {
            assessmentId: relatedReviewListDistinctData[0].subscale.assessmentId,
            assessmentScaleDescription:
              relatedReviewListDistinctData[0].subscale.assessmentScaleDescription,
            assessmentScaleFlag: relatedReviewListDistinctData[0].subscale.assessmentScaleFlag,
            assessmentScaleName: relatedReviewListDistinctData[0].subscale.assessmentScaleName,
            assessmentScaleNameVerification:
              relatedReviewListDistinctData[0].subscale.assessmentScaleNameVerification,
            assessmentScalePicture:
              relatedReviewListDistinctData[0].subscale.assessmentScalePicture,
            assessmentScalePictureVerification:
              relatedReviewListDistinctData[0].subscale.assessmentScalePictureVerification,
            id: relatedReviewListDistinctData[0].subscale.id,
            assessmentScale: selectedMetrics
          }
        ];
        dispatch({
          type: SET_SCALE_REDUCER_STATE,
          payload: relatedReviewListDistinctData[0].subscale
        });
        dispatch({
          type: SET_SCALE_INFO_REDUCER_STATE,
          payload: selectedMetrics
        });
        setTimeout(function () {
          dispatch({
            type: SET_DISPLAY_PANE_THREE_STATE,
            payload: {
              headerOne: 'assessment',
              headerOneBadgeOne: 'scale',
              headerOneBadgeTwo: 'information',
              headerOneBadgeThree: 'key',
              responseObject: responseObject[0],
              reviewMode: isReviseMode ? 'revise' : ''
            }
          });
          dispatch({ type: LOADER_STOP });
          dispatch({ type: POPUP_CLOSE });
        }, 2000);
      }
      if (typeOfMiddlePaneList === 'assignmentgaugesReviewList') {
        let responseObj = relatedReviewListDistinctData[0].guages.filter((dd) => {
          return dd.id === selectedTagValue;
        });
        dispatch({
          type: SET_DISPLAY_PANE_THREE_STATE,
          payload: {
            headerOne: 'assignment',
            headerOneBadgeOne: 'gauge',
            headerOneBadgeTwo: 'information',
            headerOneBadgeThree: 'key',
            responseObject: responseObj[0],
            reviewMode: isReviseMode ? 'revise' : ''
          }
        });
        dispatch({
          type: SET_GUAGE_REDUCER_STATE,
          payload: responseObj[0]
        });
        dispatch({ type: LOADER_STOP });
      }
      if (typeOfMiddlePaneList === 'assignmentDistinctMetricsDistinct') {
        console.log('tttt', relatedReviewListDistinctData[0]);
        let selectedMetrics =
          relatedReviewListDistinctData[0].guages[0].iGaugeOccupationFrameworkOneGaugeMetric[
          selectedTagValue
          ];
        let responseObject = [
          {
            assignmentId: relatedReviewListDistinctData[0].assignmentId,
            iGaugeOccupationFrameworkOneGaugeDescription:
              relatedReviewListDistinctData[0].guages[0]
                .iGaugeOccupationFrameworkOneGaugeDescription,
            iGaugeOccupationFrameworkOneGaugeFlag:
              relatedReviewListDistinctData[0].guages[0].iGaugeOccupationFrameworkOneGaugeFlag,
            iGaugeOccupationFrameworkOneGaugeName:
              relatedReviewListDistinctData[0].guages[0].iGaugeOccupationFrameworkOneGaugeName,
            iGaugeOccupationFrameworkOneGaugeNameVerification:
              relatedReviewListDistinctData[0].guages[0]
                .iGaugeOccupationFrameworkOneGaugeNameVerification,
            iGaugeOccupationFrameworkOneGaugePicture:
              relatedReviewListDistinctData[0].guages[0].iGaugeOccupationFrameworkOneGaugePicture,
            iGaugeOccupationFrameworkOneGaugePictureVerification:
              relatedReviewListDistinctData[0].guages[0]
                .iGaugeOccupationFrameworkOneGaugePictureVerification,
            id: relatedReviewListDistinctData[0].guages[0].id,
            iGaugeOccupationFrameworkOneGaugeMetric: selectedMetrics
          }
        ];
        dispatch({
          type: SET_GUAGE_REDUCER_STATE,
          payload: relatedReviewListDistinctData[0].guages[0]
        });
        dispatch({
          type: SET_GUAGE_INFO_REDUCER_STATE,
          payload: selectedMetrics
        });
        setTimeout(function () {
          dispatch({
            type: SET_DISPLAY_PANE_THREE_STATE,
            payload: {
              headerOne: 'assignment',
              headerOneBadgeOne: 'gauge',
              headerOneBadgeTwo: 'information',
              headerOneBadgeThree: 'key',
              responseObject: responseObject[0],
              reviewMode: isReviseMode ? 'revise' : ''
            }
          });
          dispatch({ type: LOADER_STOP });
          dispatch({ type: POPUP_CLOSE });
        }, 2000);
      }
      if (typeOfMiddlePaneList === 'gaugeDistinctMetricDistinctReviewList') {
        console.log('relatedReviewListDistinctData[0]', relatedReviewListDistinctData[0]);
        console.log('selectedTagValue', selectedTagValue);
        // let responseObj = relatedReviewListDistinctData[0].filter((dd) => {
        //   return dd.id === selectedTagValue;
        // });
        let selectedMetrics =
          relatedReviewListDistinctData[0].iGaugeOccupationFrameworkOneGaugeMetric[
          selectedTagValue
          ];
        console.log(
          'relatedReviewListDistinctData[0].iGaugeOccupationFrameworkOneGaugeMetric',
          relatedReviewListDistinctData[0].iGaugeOccupationFrameworkOneGaugeMetric
        );
        console.log('selectedMetrics', selectedMetrics);
        let responseObject = {
          ...relatedReviewListDistinctData[0],
          iGaugeOccupationFrameworkOneGaugeMetric: selectedMetrics
        };
        console.log('responseObject', responseObject);
        dispatch({
          type: SET_GUAGE_REDUCER_STATE,
          payload: relatedReviewListDistinctData[0]
        });
        dispatch({
          type: SET_GUAGE_INFO_REDUCER_STATE,
          payload: selectedMetrics
        });
        setTimeout(function () {
          dispatch({
            type: SET_DISPLAY_PANE_THREE_STATE,
            payload: {
              headerOne: 'metrics',
              headerOneBadgeOne: 'distinct',
              headerOneBadgeTwo: 'information',
              headerOneBadgeTwo: 'key',
              headerOneBadgeThree: '',
              responseObject: responseObject,
              reviewMode: isReviseMode ? 'revise' : ''
            }
          });
          dispatch({ type: LOADER_STOP });
          dispatch({ type: POPUP_CLOSE });
        }, 1000);
      }
      if (typeOfMiddlePaneList === 'profilerDistinctReviewList') {
        // let selectedProfiler = reviewListDistinctData[selectedTagValue - 1];
        let selectedProfiler = reviewListDistinctData.filter((dd) => {
          return dd.id === selectedTagValue;
        });
        console.log('selectedProfiler', selectedProfiler);
        dispatch({ type: LOADER_START });
        getCultureProfileDiamentionList(selectedAssociateInfo, 'Review-List', dispatch);
        setTimeout(function () {
          dispatch({
            type: SET_DISPLAY_PANE_THREE_STATE,
            payload: {
              headerOne: 'profiler',
              headerOneBadgeOne: 'distinct',
              headerOneBadgeTwo: 'information',
              headerOneBadgeThree: 'all',
              responseObject: selectedProfiler[0],
              reviewMode: isReviseMode ? 'revise' : ''
            }
          });
          dispatch({ type: LOADER_STOP });
          dispatch({ type: POPUP_CLOSE });
        }, 1000);
      }
      if (typeOfMiddlePaneList === 'gaugeDistinctReviewList' || createMode === 'gauge') {
        let requestObj = {
          module: 'Analytics',
          action: 'Create-Gauge',
          requestObject: {
            assesseeId: selectedAssociateInfo?.assesseeId,
            associateId:
              selectedAssociateInfo?.associate?.informationEngagement.associateTag
                .associateTagPrimary,
            action: 'review',
            reviewId: selectedTagValue
          }
        };
        dispatch({
          type: CREATE_GAUGE_SAGA,
          payload: {
            reqBody: requestObj,
            secondaryOptionCheckValue,
            action: 'review',
            createMode: createMode,
            reviewMode: isReviseMode ? 'revise' : ''
          }
        });
      }
      if (typeOfMiddlePaneList === 'cultureDimensionsDistinctReviewList') {
        console.log('selectedTagValue', selectedTagValue);
        console.log(
          'relatedReviewListDistinctData[0].cultureDimensions',
          relatedReviewListDistinctData[0].cultureDimensions
        );
        let response = relatedReviewListDistinctData[0].cultureDimensions[selectedTagValue];

        let responseObj = {
          culturejobProfilerName: response.name,
          culturejobProfilerNameVerification: false,
          culturejobProfilerDescription: response.description,
          culturejobProfilerPicture: '',
          culturejobProfilerPictureVerification: false
        };
        console.log('response', response);
        let clusterInfo = clusterProfiler?.filter((dd) => {
          return dd.id === response.tag;
        });
        console.log('clusterInfo', clusterInfo);
        dispatch({
          type: SET_JOB_CULTURE_PROFILER_CLUSTER_REDUCER_STATE,
          payload: clusterInfo[0]
        });
        dispatch({
          type: SET_JOB_CULTURE_PROFILER_REDUCER_STATE,
          payload: responseObj
        });
        dispatch({
          type: SET_JOB_CULTURE_PROFILER_INFO_REDUCER_STATE,
          payload: response
        });
        // SET_JOB_CULTURE_PROFILER_CLUSTER_REDUCER_STATE
        dispatch({ type: LOADER_START });
        setTimeout(function () {
          dispatch({
            type: SET_DISPLAY_PANE_THREE_STATE,
            payload: {
              headerOne: 'culture-dimension',
              headerOneBadgeOne: 'distinct',
              headerOneBadgeTwo: 'information',
              headerOneBadgeThree: 'all',
              responseObject: response,
              reviewMode: isReviseMode ? 'revise' : ''
            }
          });
          dispatch({ type: LOADER_STOP });
          dispatch({ type: POPUP_CLOSE });
        }, 1000);
      }
      if (typeOfMiddlePaneList === 'clusterDistinctReviewList') {
        let response = relatedReviewListDistinctData[0].clustersDimension[selectedTagValue];
        let responseObj = {
          culturejobProfilerName: response?.iGaugeOccupationFrameworkOneClusterName,
          culturejobProfilerNameVerification: false,
          culturejobProfilerDescription: response?.iGaugeOccupationFrameworkOneClusterDescription,
          culturejobProfilerPicture: '',
          culturejobProfilerPictureVerification: false
        };
        dispatch({
          type: SET_JOB_CULTURE_PROFILER_REDUCER_STATE,
          payload: responseObj
        });
        dispatch({
          type: SET_JOB_CULTURE_PROFILER_CLUSTER_REDUCER_STATE,
          payload: response
        });
        dispatch({ type: LOADER_START });
        setTimeout(function () {
          dispatch({
            type: SET_DISPLAY_PANE_THREE_STATE,
            payload: {
              headerOne: 'cluster',
              headerOneBadgeOne: 'distinct',
              headerOneBadgeTwo: 'information',
              headerOneBadgeThree: 'all',
              responseObject: response,
              reviewMode: isReviseMode ? 'revise' : ''
            }
          });
          dispatch({ type: LOADER_STOP });
          dispatch({ type: POPUP_CLOSE });
        }, 2000);
      }
      if (typeOfMiddlePaneList === 'jobCometancyDistinctReviewList') {
        console.log('dadsadas', jobProfilerReviewList);
        let rightpaneresposne;
        jobProfilerReviewList.jobCompetency.forEach((dd) => {
          if (dd.id === selectedTagValue) {
            rightpaneresposne = dd;
          }
        });
        console.log('rightpaneresposne', rightpaneresposne);
        // let response = relatedReviewListDistinctData[0].jobCometancy[selectedTagValue];
        // let responseObj = {
        //   culturejobProfilerName: response.textOne,
        //   culturejobProfilerNameVerification: false,
        //   culturejobProfilerDescription: response.textTwo,
        //   culturejobProfilerPicture: '',
        //   culturejobProfilerPictureVerification: false
        // };
        // dispatch({
        //   type: SET_JOB_CULTURE_PROFILER_REDUCER_STATE,
        //   payload: responseObj
        // });
        dispatch({
          type: SET_JOB_CULTURE_PROFILER_CLUSTER_REDUCER_STATE,
          payload: rightpaneresposne
        });
        dispatch({
          type: SET_JOB_CULTURE_PROFILER_CLUSTER_REDUCER_STATE,
          payload: rightpaneresposne
        });
        dispatch({ type: LOADER_START });
        setTimeout(function () {
          dispatch({
            type: SET_DISPLAY_PANE_THREE_STATE,
            payload: {
              headerOne: 'job-competency',
              headerOneBadgeOne: 'distinct',
              headerOneBadgeTwo: 'information',
              headerOneBadgeThree: 'all',
              responseObject: rightpaneresposne,
              reviewMode: isReviseMode ? 'revise' : ''
            }
          });
          dispatch({ type: LOADER_STOP });
          dispatch({ type: POPUP_CLOSE });
        }, 2000);
      }
      if (typeOfMiddlePaneList === 'jobDomainDistinctReviewList') {
        let response = relatedReviewListDistinctData[0].jobDomain[selectedTagValue];
        let responseObj = {
          culturejobProfilerName: response.textOne,
          culturejobProfilerNameVerification: false,
          culturejobProfilerDescription: response.textTwo,
          culturejobProfilerPicture: '',
          culturejobProfilerPictureVerification: false
        };
        dispatch({
          type: SET_JOB_CULTURE_PROFILER_REDUCER_STATE,
          payload: responseObj
        });
        dispatch({
          type: SET_JOB_CULTURE_PROFILER_INFO_REDUCER_STATE,
          payload: response
        });
        dispatch({ type: LOADER_START });
        setTimeout(function () {
          dispatch({
            type: SET_DISPLAY_PANE_THREE_STATE,
            payload: {
              headerOne: 'job-domain',
              headerOneBadgeOne: 'distinct',
              headerOneBadgeTwo: 'information',
              headerOneBadgeThree: 'all',
              responseObject: response,
              reviewMode: isReviseMode ? 'revise' : ''
            }
          });
          dispatch({ type: LOADER_STOP });
          dispatch({ type: POPUP_CLOSE });
        }, 2000);
      }
      if (typeOfMiddlePaneList === 'jobFunctionDistinctReviewList') {
        let response = relatedReviewListDistinctData[0].jobFunction[selectedTagValue];
        let responseObj = {
          culturejobProfilerName: response.textOne,
          culturejobProfilerNameVerification: false,
          culturejobProfilerDescription: response.textTwo,
          culturejobProfilerPicture: '',
          culturejobProfilerPictureVerification: false
        };
        dispatch({
          type: SET_JOB_CULTURE_PROFILER_REDUCER_STATE,
          payload: responseObj
        });
        dispatch({
          type: SET_JOB_CULTURE_PROFILER_INFO_REDUCER_STATE,
          payload: response
        });
        dispatch({ type: LOADER_START });
        setTimeout(function () {
          dispatch({
            type: SET_DISPLAY_PANE_THREE_STATE,
            payload: {
              headerOne: 'job-function',
              headerOneBadgeOne: 'distinct',
              headerOneBadgeTwo: 'information',
              headerOneBadgeThree: 'all',
              responseObject: response,
              reviewMode: isReviseMode ? 'revise' : ''
            }
          });
          dispatch({ type: LOADER_STOP });
          dispatch({ type: POPUP_CLOSE });
        }, 2000);
      }
      if (typeOfMiddlePaneList === 'jobRoleDistinctReviewList') {
        let response = relatedReviewListDistinctData[0].jobRole[selectedTagValue];
        let responseObj = {
          culturejobProfilerName: response.textOne,
          culturejobProfilerNameVerification: false,
          culturejobProfilerDescription: response.textTwo,
          culturejobProfilerPicture: '',
          culturejobProfilerPictureVerification: false
        };
        dispatch({
          type: SET_JOB_CULTURE_PROFILER_REDUCER_STATE,
          payload: responseObj
        });
        dispatch({
          type: SET_JOB_CULTURE_PROFILER_INFO_REDUCER_STATE,
          payload: response
        });
        dispatch({ type: LOADER_START });
        setTimeout(function () {
          dispatch({
            type: SET_DISPLAY_PANE_THREE_STATE,
            payload: {
              headerOne: 'job-role',
              headerOneBadgeOne: 'distinct',
              headerOneBadgeTwo: 'information',
              headerOneBadgeThree: 'all',
              responseObject: response,
              reviewMode: isReviseMode ? 'revise' : ''
            }
          });
          dispatch({ type: LOADER_STOP });
          dispatch({ type: POPUP_CLOSE });
        }, 2000);
      }
      dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneThree' });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'middlePaneSelectedValue', value: selectedTagValue }
      });
      popupAllClose();
      dispatch({
        type: SET_POPUP_SINGLE_STATE,
        payload: {
          stateName: 'popupOptionArr',
          value: middlePaneListPopupOptions
        }
      });
      setIsReviseMode(false);
      // dispatch({ type: LOADER_STOP });

      // onClickInformation(secondaryOptionCheckValue);
    } else if (dataVal === 'distinct') {
      if (typeOfMiddlePaneList === 'assesseesGroupAssesseeReviewList') {
        // assessee
        console.log('relatedReviewListDistinctData', relatedReviewListDistinctData);
        console.log('reviewListDistinctData', reviewListDistinctData);
        let slectedGroupObj = reviewListDistinctData?.filter(
          (x) => x.id === relatedReviewListDistinctData[0].id
        );
        console.log('slectedGroupObj', slectedGroupObj);

        let responseObj = relatedReviewListDistinctData[0]?.assessee.filter(
          (x, index) => x.id === selectedTagValue
        );

        let index = relatedReviewListDistinctData[0]?.assessee.findIndex(
          (x) => x.id === selectedTagValue
        );
        let relatedObj = {
          ...relatedReviewListDistinctData[0],
          index,
          assesseeInfo: responseObj[0].informationBasic,
          assesseeGroupInfo: slectedGroupObj[0].informationBasic
        };
        assesseeAssignmentReviewListApi(
          selectedAssociateInfo,
          selectedTagValue,
          dispatch,
          responseObj[0].id,
          relatedObj,
          secondaryOptionCheckValue,
          '',
          'assesseeGroupAssesseeAssignmentDistinct',
          false,
          selectedTagsArray,
          assignmentCreateType
        );
        dispatch({ type: POPUP_CLOSE });
      }
      if (typeOfMiddlePaneList === 'assesseeGroupAssesseeAssignmentDistinct') {
        dispatch({ type: LOADER_START });
        dispatch({
          type: GET_ASSESSEE_REPORT_SAGA,
          payload: {
            request: {
              module: 'Assessee-Assignment',
              action: 'Result',
              requestObject: {
                action: 'review',
                assesseeId: selectedAssociateInfo?.assesseeId,
                associateId:
                  selectedAssociateInfo?.associate?.informationEngagement.associateTag
                    .associateTagPrimary,
                assesseeResult: {
                  assesseeId: relatedReviewListDistinctData[0].assesseeId,
                  assignmentId: selectedTagValue
                }
              }
            },
            righpaneheaderOne: popupHeaderOne
          }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'middlePaneSelectedValue', value: selectedTagValue }
        });
        dispatch({ type: LOADER_START });
        dispatch({ type: POPUP_CLOSE });
      }
      if (typeOfMiddlePaneList === 'assesseesGroupDistinctReviewList') {
        // Request OBject for API call on worker
        getAssesseeGroupAssesseeDistinctApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          dataVal,
          selectedTagValue, //group id
          '',
          false,
          true,
          '',
          [],
          middlePaneHeader
        );
        dispatch({
          type: FILTERMODE,
          payload: { FilterMode: 'assesseeGroupAssesseeDistinct' + secondaryOptionCheckValue }
        });
        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
        dispatch({ type: POPUP_CLOSE });
      }
      if (
        typeOfMiddlePaneList === 'assesseeRoleDistinctReviewList' ||
        createMode === 'assesseesRole'
      ) {
        if (paneOneSelectedSection === 'paneoneSection3') {
          getAssesseeMarketplaceRoleApiCall(
            selectedAssociateInfo,
            secondaryOptionCheckValue,
            countPage,
            dispatch,
            dataVal,
            selectedTagValue,
            '',
            false,
            middlePaneHeader
          );
        } else {
          getAssesseeRoleAssesseeDistinctApiCall(
            selectedAssociateInfo,
            secondaryOptionCheckValue,
            countPage,
            dispatch,
            dataVal,
            selectedTagValue,
            '',
            false,
            middlePaneHeader
          );
        }
        dispatch({
          type: FILTERMODE,
          payload: { FilterMode: 'assesseeRoleAssesseeDistinct' + secondaryOptionCheckValue }
        });
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
        dispatch({ type: POPUP_CLOSE });
      }
      if (
        typeOfMiddlePaneList === 'assesseesTypeDistinctReviewList' ||
        createMode === 'assesseesType'
      ) {
        if (paneOneSelectedSection === 'paneoneSection3') {
          getAssesseeMarketplaceTypeApiCall(
            selectedAssociateInfo,
            secondaryOptionCheckValue,
            countPage,
            dispatch,
            dataVal,
            selectedTagValue,
            '',
            false,
            middlePaneHeader
          );
        } else {
          getAssesseeTypeAssesseeDistinctApiCall(
            selectedAssociateInfo,
            secondaryOptionCheckValue,
            countPage,
            dispatch,
            dataVal,
            selectedTagValue,
            '',
            false,
            middlePaneHeader
          );
        }
        dispatch({
          type: FILTERMODE,
          payload: { FilterMode: 'assesseeTypeAssesseeDistinct' + secondaryOptionCheckValue }
        });
        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
        dispatch({ type: POPUP_CLOSE });
      }
      if (
        typeOfMiddlePaneList === 'associateNodeDistinctReviewList' ||
        createMode === 'associatesNode'
      ) {
        if (
          popupHeaderOne === 'assessees' ||
          popupHeaderOne === 'administrators' ||
          popupHeaderOne === 'assistants' ||
          popupHeaderOne === 'managers'
        ) {
          if (paneOneSelectedSection === 'paneoneSection3') {
            getAssesseeMarketplaceNodeApiCall(
              selectedAssociateInfo,
              secondaryOptionCheckValue,
              countPage,
              dispatch,
              dataVal,
              selectedTagValue,
              '',
              false,
              middlePaneHeader
            );
            dispatch({
              type: FILTERMODE,
              payload: { FilterMode: 'assesseeNodeAssesseeDistinct' + secondaryOptionCheckValue }
            });
            dispatch({ type: POPUP_CLOSE });
            return;
          }
          getAssesseeNodeAssesseeDistinctApiCall(
            selectedAssociateInfo,
            secondaryOptionCheckValue,
            countPage,
            dispatch,
            dataVal,
            selectedTagValue,
            '',
            false,
            middlePaneHeader
          );
          dispatch({
            type: FILTERMODE,
            payload: { FilterMode: 'assesseeNodeAssesseeDistinct' + secondaryOptionCheckValue }
          });
        }
        if (popupHeaderOne === 'associates') {
          if (paneOneSelectedSection === 'paneoneSection3') {
            getAssesseeMarketplaceNodeApiCall(
              selectedAssociateInfo,
              secondaryOptionCheckValue,
              countPage,
              dispatch,
              dataVal,
              selectedTagValue,
              '',
              false,
              middlePaneHeader
            );
            dispatch({
              type: FILTERMODE,
              payload: { FilterMode: 'assesseeNodeAssesseeDistinct' + secondaryOptionCheckValue }
            });
            dispatch({ type: POPUP_CLOSE });
            return;
          }
          getNodeRelatedAssociateDistinctApiCall(
            selectedAssociateInfo,
            secondaryOptionCheckValue,
            countPage,
            dispatch,
            dataVal,
            selectedTagValue,
            '',
            false,
            middlePaneHeader
          );
          dispatch({
            type: FILTERMODE,
            payload: { FilterMode: 'associateNodeAssociateDistinct' + secondaryOptionCheckValue }
          });
        }
        if (popupHeaderOne === 'assessments') {
          // alert("Something")
          if (paneOneSelectedSection === 'paneoneSection3') {
            getAssesseeMarketplaceNodeApiCall(
              selectedAssociateInfo,
              secondaryOptionCheckValue,
              countPage,
              dispatch,
              dataVal,
              selectedTagValue,
              '',
              false,
              middlePaneHeader
            );
            dispatch({
              type: FILTERMODE,
              payload: { FilterMode: 'assesseeNodeAssesseeDistinct' + secondaryOptionCheckValue }
            });
            dispatch({ type: POPUP_CLOSE });
            return;
          }
          getNodeRelatedAssessmentsDistinctApiCall(
            selectedAssociateInfo,
            secondaryOptionCheckValue,
            countPage,
            dispatch,
            dataVal,
            selectedTagValue,
            '',
            false,
            middlePaneHeader
          );
          dispatch({
            type: FILTERMODE,
            payload: { FilterMode: 'assessmentNodeAssessmentDistinct' + secondaryOptionCheckValue }
          });
        }
        if (popupHeaderOne === 'assignments') {
          if (paneOneSelectedSection === 'paneoneSection3') {
            getAssesseeMarketplaceNodeApiCall(
              selectedAssociateInfo,
              secondaryOptionCheckValue,
              countPage,
              dispatch,
              dataVal,
              selectedTagValue,
              '',
              false,
              middlePaneHeader
            );
            dispatch({
              type: FILTERMODE,
              payload: { FilterMode: 'assesseeNodeAssesseeDistinct' + secondaryOptionCheckValue }
            });
            dispatch({ type: POPUP_CLOSE });
            return;
          }
          getNodeRelatedAssignmentsDistinctApiCall(
            selectedAssociateInfo,
            secondaryOptionCheckValue,
            countPage,
            dispatch,
            dataVal,
            selectedTagValue,
            '',
            false,
            middlePaneHeader
          );
          dispatch({
            type: FILTERMODE,
            payload: {
              FilterMode: 'AssignmentNodeAssignmentReviewListDistinct' + secondaryOptionCheckValue
            }
          });
        }
        if (popupHeaderOne === 'items') {

          if (selectedSectionPopup === 'paneoneSection3') {
            getNodeRelatedItemsDistinctApiCall(
              selectedAssociateInfo,
              secondaryOptionCheckValue,
              countPage,
              dispatch,
              dataVal,
              selectedTagValue,
              '',
              false,
              middlePaneHeader,
              true,
              'Shared-Item-Node'
            );
            // dispatch({
            //   type: FILTERMODE,
            //   payload: { FilterMode: 'itemNodeItemDistinct' + secondaryOptionCheckValue }
            // });
          } else {
            getNodeRelatedItemsDistinctApiCall(
              selectedAssociateInfo,
              secondaryOptionCheckValue,
              countPage,
              dispatch,
              dataVal,
              selectedTagValue,
              '',
              false,
              middlePaneHeader
            );
            dispatch({
              type: FILTERMODE,
              payload: { FilterMode: 'itemNodeItemDistinct' + secondaryOptionCheckValue }
            });
          }
        }
        if (popupHeaderOne === 'culture-profiles') {

          if (selectedSectionPopup === 'paneoneSection3') {
            getCultureProfileNodeCultureProfileApiCall(
              selectedAssociateInfo,
              secondaryOptionCheckValue,
              countPage,
              dispatch,
              dataVal,
              selectedTagValue,
              '',
              false,
              middlePaneHeader,
              true,
              'Shared-Culture-Profile-Node'
            );
            // dispatch({
            //   type: FILTERMODE,
            //   payload: {
            //     FilterMode: 'cultureProfileNodeCultureProfileDistinct' + secondaryOptionCheckValue
            //   }
            // });

          } else {
            getCultureProfileNodeCultureProfileApiCall(
              selectedAssociateInfo,
              secondaryOptionCheckValue,
              countPage,
              dispatch,
              dataVal,
              selectedTagValue,
              '',
              false,
              middlePaneHeader
            );
            dispatch({
              type: FILTERMODE,
              payload: {
                FilterMode: 'cultureProfileNodeCultureProfileDistinct' + secondaryOptionCheckValue
              }
            });
          }

        }
        if (popupHeaderOne === 'job-profiles') {
          if (selectedSectionPopup === 'paneoneSection3') {
            getJobProfileNodeJobProfileApiCall(
              selectedAssociateInfo,
              secondaryOptionCheckValue,
              countPage,
              dispatch,
              dataVal,
              selectedTagValue,
              '',
              false,
              middlePaneHeader,
              true,
              'Share-Job-Profile-Node'
            );
            // dispatch({
            //   type: FILTERMODE,
            //   payload: { FilterMode: 'jobProfileNodeJobProfileDistinct' + secondaryOptionCheckValue }
            // });
          } else {
            getJobProfileNodeJobProfileApiCall(
              selectedAssociateInfo,
              secondaryOptionCheckValue,
              countPage,
              dispatch,
              dataVal,
              selectedTagValue,
              '',
              false,
              middlePaneHeader
            );
            dispatch({
              type: FILTERMODE,
              payload: { FilterMode: 'jobProfileNodeJobProfileDistinct' + secondaryOptionCheckValue }
            });
          }
        }

        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
        dispatch({ type: POPUP_CLOSE });
      }
      if (typeOfMiddlePaneList === 'associatesGroupDistinctReviewList') {
        getAssociateGroupAssociateDistinctApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          dataVal,
          selectedTagValue,
          '',
          false
        );
        dispatch({
          type: FILTERMODE,
          payload: { FilterMode: 'associateGroupAssociateDistinct' + secondaryOptionCheckValue }
        });
        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
        dispatch({ type: POPUP_CLOSE });
      }
      if (
        typeOfMiddlePaneList === 'associateRoleDistinctReviewList' ||
        createMode === 'associatesRole'
      ) {
        getAssociateRoleAssociateDistinctApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          dataVal,
          selectedTagValue,
          '',
          false
        );
        dispatch({
          type: FILTERMODE,
          payload: { FilterMode: 'associateRoleAssociateDistinct' + secondaryOptionCheckValue }
        });
        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
        dispatch({ type: POPUP_CLOSE });
      }
      if (
        typeOfMiddlePaneList === 'associatesTypeDistinctReviewList' ||
        createMode === 'associatesType'
      ) {
        getAssociateTypeAssociateDistinctApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          dataVal,
          selectedTagValue,
          '',
          false
        );
        dispatch({
          type: FILTERMODE,
          payload: { FilterMode: 'associateTypeAssociateDistinct' + secondaryOptionCheckValue }
        });
        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
        dispatch({ type: POPUP_CLOSE });
      }
      if (typeOfMiddlePaneList === 'itemsGroupDistinctReviewList' || createMode === 'itemsGroup') {
        getItemGroupItemDistinctApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          dataVal,
          selectedTagValue,
          '',
          false
        );
        dispatch({
          type: FILTERMODE,
          payload: { FilterMode: 'itemGroupItemDistinct' + secondaryOptionCheckValue }
        });
        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
        dispatch({ type: POPUP_CLOSE });
      }
      if (typeOfMiddlePaneList === 'itemsTypeDistinctReviewList' || createMode === 'itemsType') {
        getItemTypeItemDistinctApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          dataVal,
          selectedTagValue,
          '',
          false
        );
        dispatch({
          type: FILTERMODE,
          payload: { FilterMode: 'itemTypeItemDistinct' + secondaryOptionCheckValue }
        });
        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
        dispatch({ type: POPUP_CLOSE });
      }
      if (typeOfMiddlePaneList === 'assessmentDistinctReviewList' || createMode === 'assessment') {
        if (popupHeaderOne === 'items') {
          getAssessmentItemDistinctApiCall(
            selectedAssociateInfo,
            secondaryOptionCheckValue,
            countPage,
            dispatch,
            dataVal,
            selectedTagValue,
            '',
            false
          );
          dispatch({
            type: FILTERMODE,
            payload: { FilterMode: 'assessmentItem' + secondaryOptionCheckValue }
          });
        }
        if (
          popupHeaderOneBadgeOne === 'scales' ||
          popupHeaderOneBadgeOne === 'sections' ||
          popupHeaderOneBadgeOne === 'versions' ||
          popupHeaderOneBadgeOne === 'clusters' ||
          popupHeaderOneBadgeOne === 'scores' ||
          popupHeaderOneBadgeOne === 'norms' ||
          popupHeaderOneBadgeOne === 'grades'
        ) {
          // debugger;
          let reqBody = {
            module: 'Assessment-Distinct',
            action: 'Review',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary, //605255729d3c823d3964e0ec
              filter: true,
              search: [
                {
                  condition: 'and',
                  searchBy: [
                    {
                      dataType: 'String',
                      conditionColumn: 'id',
                      conditionValue: {
                        condition: 'eq',
                        value: {
                          from: selectedTagValue
                        }
                      }
                    }
                  ]
                }
              ]
            }
          };
          dispatch({ type: LOADER_START });
          dispatch({
            type: SET_RELATED_REQUEST_OBJECT,
            payload: reqBody
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'relatedReviewListDistinctData', value: [] }
          });
          dispatch({
            type: GET_ASSESSMENT_SEC_INFO_SAGA,
            payload: {
              reqBody: reqBody,
              typeOfMiddlePaneList: 'assessment' + popupHeaderOneBadgeOne + 'ReviewList'
            }
          });
          dispatch({
            type: FILTERMODE,
            payload: { FilterMode: 'assessmentsDistinctinactive' + popupHeaderOneBadgeOne }
          });
        }

        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
        dispatch({ type: POPUP_CLOSE });
      }
      if (
        typeOfMiddlePaneList === 'assessmentsGroupDistinctReviewList' ||
        createMode === 'assessmentsGroup' // assesment
      ) {
        getAssessmentGroupAssessmentDistinctApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          dataVal,
          selectedTagValue,
          '',
          false
        );
        dispatch({
          type: FILTERMODE,
          payload: { FilterMode: 'assessmentGroupAssessment' + secondaryOptionCheckValue }
        });
        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
        dispatch({ type: POPUP_CLOSE });
      }
      if (
        typeOfMiddlePaneList === 'assessmentsTypeDistinctReviewList' ||
        createMode === 'assessmentsType'
      ) {
        if (paneOneSelectedSection === "paneoneSection3") {
          getMarketplaceAssessmentTypeApiCall(selectedAssociateInfo,
            secondaryOptionCheckValue,
            countPage,
            dispatch,
            dataVal,
            selectedTagValue,
            '',
            false)
        }
        else {
          getAssessmentTypeAssessmentDistinctApiCall(
            selectedAssociateInfo,
            secondaryOptionCheckValue,
            countPage,
            dispatch,
            dataVal,
            selectedTagValue,
            '',
            false
          )
        };
        dispatch({
          type: FILTERMODE,
          payload: { FilterMode: 'assessmentTypeAssessment' + secondaryOptionCheckValue }
        });
        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
        dispatch({ type: POPUP_CLOSE });
      }
      if (
        typeOfMiddlePaneList === 'assignmentsTypeDistinctReviewList' ||
        createMode === 'assignmentsType'
      ) {
        if (paneOneSelectedSection === "paneoneSection3") {
          getMarketplaceAssignmentTypeApiCall(
            selectedAssociateInfo,
            secondaryOptionCheckValue,
            countPage,
            dispatch,
            dataVal,
            selectedTagValue,
            '',
            false,
            assignmentCreateType
          )
        }
        else {
          getAssignmnetTypeAssignmnetDistinctApiCall(
            selectedAssociateInfo,
            secondaryOptionCheckValue,
            countPage,
            dispatch,
            dataVal,
            selectedTagValue,
            '',
            false,
            assignmentCreateType
          )
        };
        dispatch({
          type: FILTERMODE,
          payload: { FilterMode: 'assignmentTypeAssignment' + secondaryOptionCheckValue }
        });
        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
        dispatch({ type: POPUP_CLOSE });
      }
      if (
        typeOfMiddlePaneList === 'assignmentsGroupDistinctReviewList' || //  assignment
        createMode === 'assignmentsGroup'
      ) {
        getAssignmnetGroupAssignmnetDistinctApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          dataVal,
          selectedTagValue,
          '',
          false,
          assignmentCreateType
        );
        dispatch({
          type: FILTERMODE,
          payload: { FilterMode: 'assignmentGroupAssignment' + secondaryOptionCheckValue }
        });
        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
        dispatch({ type: POPUP_CLOSE });
      }
      if (typeOfMiddlePaneList === 'assignmentDistinctReviewList' || createMode === 'assignment') {
        if (popupHeaderOne === 'assessees') {
          getAssignmnetAssesseeDistinctApiCall(
            selectedAssociateInfo,
            secondaryOptionCheckValue,
            countPage,
            dispatch,
            dataVal,
            selectedTagValue,
            '',
            false,
            secondaryOptionCheckValue
          );
          dispatch({
            type: FILTERMODE,
            payload: {
              FilterMode: 'assignmentDistinctAssesseeDistinct' + secondaryOptionCheckValue
            }
          });
        }
        if (popupHeaderOne === 'assessments') {
          getAssignmnetAssessmentDistinctApiCall(
            selectedAssociateInfo,
            secondaryOptionCheckValue,
            countPage,
            dispatch,
            dataVal,
            selectedTagValue,
            '',
            false,
            createMode
          );
          dispatch({
            type: FILTERMODE,
            payload: { FilterMode: 'assignmentDistinctAssessment' + secondaryOptionCheckValue }
          });
        }
        if (popupHeaderOne === 'culture-profiles') {
          getAssignmneCultureProfileDistinctApiCall(
            selectedAssociateInfo,
            secondaryOptionCheckValue,
            countPage,
            dispatch,
            dataVal,
            selectedTagValue,
            '',
            false
          );
          dispatch({
            type: FILTERMODE,
            payload: { FilterMode: 'assignmentDistinctAssessment' + secondaryOptionCheckValue }
          });
        }
        if (popupHeaderOne === 'job-profiles') {
          getAssignmneJobProfileDistinctApiCall(
            selectedAssociateInfo,
            secondaryOptionCheckValue,
            countPage,
            dispatch,
            dataVal,
            selectedTagValue,
            '',
            false
          );
          dispatch({
            type: FILTERMODE,
            payload: { FilterMode: 'assignmentDistinctAssessment' + secondaryOptionCheckValue }
          });
        }
        if (popupHeaderOne === 'analytics') {
          dispatch({ type: LOADER_START });
          let requestObj = {
            module: 'Assessee-Assignment',
            action: 'Merit-List',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              assignmentId: selectedTagValue,
              resultResponse: ''
            }
          };
          let relatedData = reviewListDistinctData;
          let responseObj = relatedData.filter((dd) => {
            return dd.id === selectedTagValue;
          });
          dispatch({
            type: GET_ASSIGNMENT_REPORT_SAGA,
            payload: {
              request: requestObj,
              assignmentId: selectedTagValue,
              assignmentName: responseObj[0]?.informationBasic?.assignmentName,
              assignmentDescription: responseObj[0]?.informationBasic?.assignmentDescription,
              isRightPaneReview: true,
              righpaneheaderOne: popupHeaderOne
            }
          });
          dispatch({ type: POPUP_CLOSE });
        }
        if (popupHeaderOne === 'gauges') {
          let reqBody = {
            assesseeId: selectedAssociateInfo?.assesseeId,
            associateId:
              selectedAssociateInfo?.associate?.informationEngagement.associateTag
                .associateTagPrimary, //605255729d3c823d3964e0ec
            filter: true,
            search: [
              {
                condition: 'and',
                searchBy: [
                  {
                    dataType: 'String',
                    conditionColumn: 'id',
                    conditionValue: {
                      condition: 'eq',
                      value: {
                        from: selectedTagValue
                      }
                    }
                  }
                ]
              }
            ]
          };
          dispatch({ type: LOADER_START });
          dispatch({
            type: SET_RELATED_REQUEST_OBJECT,
            payload: reqBody
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'relatedReviewListDistinctData', value: [] }
          });
          //assignmentguagesReviewList
          dispatch({
            type: GET_ASSIGNMENT_GUAGE_INFO_SAGA,
            payload: {
              reqBody: reqBody,
              typeOfMiddlePaneList: 'assignment' + popupHeaderOne + 'ReviewList'
            }
          });
        }
        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
        dispatch({ type: POPUP_CLOSE });
      }
      if (
        typeOfMiddlePaneList === 'cultureProfilesGroupDistinctReviewList' ||
        createMode === 'culture-profilesGroup'
      ) {
        getCultureGroupCultureDistinctApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          dataVal,
          selectedTagValue,
          '',
          false
        );
        dispatch({
          type: FILTERMODE,
          payload: { FilterMode: 'cultureGroupCultureDistinct' + secondaryOptionCheckValue }
        });
        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
        dispatch({ type: POPUP_CLOSE });
      }
      if (
        typeOfMiddlePaneList === 'cultureProfilesTypeDistinctReviewList' ||
        createMode === 'culture-profilesType'
      ) {
        getCultureTypeCultureDistinctApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          dataVal,
          selectedTagValue,
          '',
          false
        );
        dispatch({
          type: FILTERMODE,
          payload: { FilterMode: 'cultureTypeCultureDistinct' + secondaryOptionCheckValue }
        });
        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
        dispatch({ type: POPUP_CLOSE });
      }
      if (
        typeOfMiddlePaneList === 'cultureProfilesDistinctReviewList' ||
        createMode === 'cultureProfile'
      ) {
        getCultureProfileAssessmentDistinctApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          dataVal,
          selectedTagValue,
          '',
          false
        );
        dispatch({
          type: FILTERMODE,
          payload: { FilterMode: 'cultureProfileAssessmentDistinct' + secondaryOptionCheckValue }
        });
        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
        dispatch({ type: POPUP_CLOSE });
      }
      if (
        typeOfMiddlePaneList === 'jobProfilesDistinctReviewList' ||
        createMode === 'jobProfile' ||
        createMode === 'job-profile'
      ) {
        getJobProfileAssessmentDistinctApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          dataVal,
          selectedTagValue,
          '',
          false
        );
        dispatch({
          type: FILTERMODE,
          payload: { FilterMode: 'jobProfileAssessmentDistinct' + secondaryOptionCheckValue }
        });
        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
        dispatch({ type: POPUP_CLOSE });
      }
      if (
        typeOfMiddlePaneList === 'jobProfilesGroupDistinctReviewList' ||
        createMode === 'jobProfilesGroup' ||
        createMode === 'job-ProfilesGroup'
      ) {
        getJobProfileGroupJobProfileDistinctApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          dataVal,
          selectedTagValue,
          '',
          false
        );
        dispatch({
          type: FILTERMODE,
          payload: { FilterMode: 'jobProfileGroupJobProfileDistinct' + secondaryOptionCheckValue }
        });
        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
        dispatch({ type: POPUP_CLOSE });
      }
      if (
        typeOfMiddlePaneList === 'jobProfilesTypeDistinctReviewList' ||
        createMode === 'job-profilesType'
      ) {
        getJobProfileTypeJobProfileDistinctApiCall(
          selectedAssociateInfo,
          secondaryOptionCheckValue,
          countPage,
          dispatch,
          dataVal,
          selectedTagValue,
          '',
          false
        );
        dispatch({
          type: FILTERMODE,
          payload: { FilterMode: 'jobProfileTypeJobProfileDistinct' + secondaryOptionCheckValue }
        });
        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
        dispatch({ type: POPUP_CLOSE });
      }
      if (
        typeOfMiddlePaneList === 'assignmentDistinctAssesseeReviewList' &&
        popupHeaderOne === 'analytics'
      ) {
        let relatedData = relatedReviewListDistinctData[0];
        let assesseeData = relatedData.assessee.filter((dd) => {
          return dd.assesseeIdTwo === selectedTagValue;
        });
        dispatch({ type: LOADER_START });
        dispatch({
          type: GET_ASSESSEE_REPORT_SAGA,
          payload: {
            request: {
              module: 'Assessee-Assignment',
              action: 'Result',
              requestObject: {
                action: 'review',
                assesseeId: selectedAssociateInfo?.assesseeId,
                associateId:
                  selectedAssociateInfo?.associate?.informationEngagement.associateTag
                    .associateTagPrimary,
                assesseeResult: {
                  assesseeId: assesseeData[0]?.assesseeIdTwo,
                  assignmentId: assesseeData[0]?.assignmentData?.assesseeAssignmentId
                }
              }
            },
            righpaneheaderOne: popupHeaderOne
          }
        });
        dispatch({ type: LOADER_START });
        dispatch({ type: POPUP_CLOSE });
        // let relatedData = relatedReviewListDistinctData[0];
        // let reportsData = [
        //   {
        //     id: 1,
        //     reportName: 'Assessee Report',
        //     reportDescription: 'Bespoke'
        //   },
        //   {
        //     id: 2,
        //     reportName: 'Assessee Report',
        //     reportDescription: 'Generic'
        //   }
        // ];
        // let assesseeData = relatedData.assessee.filter((dd) => {
        //   return dd.assesseeId === selectedTagValue;
        // });
        // console.log('assesseeData', assesseeData);

        // let response = { ...relatedData, report: reportsData, assesseeReport: assesseeData[0] };
        // dispatch({
        //   type: SET_DISPLAY_TWO_SINGLE_STATE,
        //   payload: { stateName: 'relatedReviewListDistinctData', value: [response] }
        // });
        // setTimeout(function () {
        //   dispatch({
        //     type: SET_MIDDLEPANE_STATE,
        //     payload: {
        //       middlePaneHeader: 'assessee',
        //       middlePaneHeaderBadgeOne: 'reports',
        //       middlePaneHeaderBadgeTwo: 'unread',
        //       middlePaneHeaderBadgeThree: '',
        //       middlePaneHeaderBadgeFour: '',
        //       typeOfMiddlePaneList: 'assesseesReportReviewList',
        //       scanCount: 2,
        //       showMiddlePaneState: true
        //     }
        //   });
        //   dispatch({ type: LOADER_STOP });
        //   dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
        //   dispatch({ type: POPUP_CLOSE });
        // }, 2000);
      }
      if (typeOfMiddlePaneList === 'assesseeGroupAssesseeAssignmentDistinct') {
        dispatch({ type: LOADER_START });
        dispatch({
          type: GET_ASSESSEE_REPORT_SAGA,
          payload: {
            request: {
              module: 'Assessee-Assignment',
              action: 'Result',
              requestObject: {
                action: 'review',
                assesseeId: selectedAssociateInfo?.assesseeId,
                associateId:
                  selectedAssociateInfo?.associate?.informationEngagement.associateTag
                    .associateTagPrimary,
                assesseeResult: {
                  assesseeId: relatedReviewListDistinctData[0].assesseeId,
                  assignmentId: selectedTagValue
                }
              }
            },
            righpaneheaderOne: popupHeaderOne
          }
        });
        dispatch({ type: LOADER_START });
        dispatch({ type: POPUP_CLOSE });
      }
      if (
        typeOfMiddlePaneList === 'assignmentDistinctAssesseeReviewList' &&
        popupHeaderOne === 'reports'
      ) {
        dispatch({ type: POPUP_CLOSE });
      }
      if (typeOfMiddlePaneList === 'assessmentscalesReviewList' && popupHeaderOne === 'metrics') {
        dispatch({ type: LOADER_START });
        let relatedData = relatedReviewListDistinctData[0];
        let responseObj = relatedData.scales.filter((dd) => {
          return dd.id === selectedTagValue;
        });
        let response = { ...relatedData, subscale: responseObj[0] };
        // response.subscale.assessmentScaleMetric.sort((a, b) =>
        //   a.assessmentScaleMetricLabel !== b.assessmentScaleMetricLabel
        //     ? a.assessmentScaleMetricLabel < b.assessmentScaleMetricLabel
        //       ? -1
        //       : 1
        //     : 0
        // );
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'assessmentSelectedScale', value: selectedTagValue }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [response] }
        });
        setTimeout(function () {
          dispatch({
            type: SET_MIDDLEPANE_STATE,
            payload: {
              middlePaneHeader: 'assessment',
              middlePaneHeaderBadgeOne: 'metrics',
              middlePaneHeaderBadgeTwo: 'distinct',
              middlePaneHeaderBadgeThree: '',
              middlePaneHeaderBadgeFour: '',
              typeOfMiddlePaneList: 'assessmentDistinctSubScaleDistinct',
              middlePaneHeaderDuplicate: middlePaneHeaderDuplicate,
              middlePaneHeaderDuplicateBadgeOne: middlePaneHeaderDuplicateBadgeOne,
              middlePaneHeaderDuplicateBadgeTwo: middlePaneHeaderDuplicateBadgeTwo,
              middlePaneHeaderDuplicateBadgeThree: middlePaneHeaderDuplicateBadgeThree,
              middlePaneHeaderDuplicateBadgeFour: middlePaneHeaderDuplicateBadgeFour,
              middlePaneHeaderDuplicateOne: middlePaneHeaderDuplicateOne,
              middlePaneHeaderDuplicateOneBadgeOne: middlePaneHeaderDuplicateOneBadgeOne,
              middlePaneHeaderDuplicateOneBadgeTwo: middlePaneHeaderDuplicateOneBadgeTwo,
              middlePaneHeaderDuplicateOneBadgeThree: middlePaneHeaderDuplicateOneBadgeThree,
              middlePaneHeaderDuplicateOneBadgeFour: middlePaneHeaderDuplicateOneBadgeFour,
              scanCount: responseObj[0].assessmentScaleMetric.length,
              duplicateScanCount: duplicateScanCount,
              showMiddlePaneState: true
            }
          });
          console.log('metricsresponse', response);

          dispatch({ type: LOADER_STOP });
          dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
          dispatch({ type: POPUP_CLOSE });
        }, 2000);
      }
      if (typeOfMiddlePaneList === 'assignmentgaugesReviewList' && popupHeaderOne === 'metrics') {
        dispatch({ type: LOADER_START });
        let relatedData = relatedReviewListDistinctData[0];
        let responseObj = relatedData.guages.filter((dd) => {
          return dd.id === selectedTagValue;
        });
        console.log('relatedData', relatedData);

        let response = {
          ...relatedData,
          assignmentStatus: relatedData.assignmentStatus,
          assingmentId: relatedData.assingmentId,
          metrics: responseObj[0]
        };
        console.log('responsetttt', response);

        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'assignmentSelectedGuage', value: selectedTagValue }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [response] }
        });
        setTimeout(function () {
          dispatch({
            type: SET_MIDDLEPANE_STATE,
            payload: {
              middlePaneHeader: 'assignment',
              middlePaneHeaderBadgeOne: 'metrics',
              middlePaneHeaderBadgeTwo: 'distinct',
              middlePaneHeaderBadgeThree: '',
              middlePaneHeaderBadgeFour: '',
              typeOfMiddlePaneList: 'assignmentDistinctMetricsDistinct',
              scanCount: responseObj[0].iGaugeOccupationFrameworkOneGaugeMetric?.length,
              showMiddlePaneState: true
            }
          });
          console.log('metricsresponse', response);
          dispatch({ type: LOADER_STOP });
          dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
          dispatch({ type: POPUP_CLOSE });
        }, 2000);
      }
      if (
        typeOfMiddlePaneList === 'assesseesDistinctReviewList' ||
        typeOfMiddlePaneList === 'administratorsDistinctReviewList' ||
        typeOfMiddlePaneList === 'assistantsDistinctReviewList' ||
        typeOfMiddlePaneList === 'managersDistinctReviewList' ||
        createMode === 'assessee'
      ) {
        if (popupHeaderOne === 'assignments') {
          let responseObj = reviewListDistinctData.filter((x) => x.id === selectedTagValue);
          assesseeAssignmentReviewListApi(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            responseObj[0].id,
            responseObj[0].informationBasic,
            secondaryOptionCheckValue,
            '',
            'assesseeDistinctAssignmentDistinctReviewList',
            '',
            selectedTagsArray,
            assignmentCreateType

          );
        }
        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
        dispatch({ type: POPUP_CLOSE });
      }
      if (
        typeOfMiddlePaneList === 'gaugeDistinctReviewList' ||
        (headerOne === 'gauge' && popupHeaderOneBadgeOne === 'metrics') ||
        createMode === 'gauge'
      ) {
        let requestObj = {
          module: 'Analytics',
          action: 'Create-Gauge',
          requestObject: {
            assesseeId: selectedAssociateInfo?.assesseeId,
            associateId:
              selectedAssociateInfo?.associate?.informationEngagement.associateTag
                .associateTagPrimary,
            action: 'review',
            reviewId: selectedTagValue
          }
        };
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'assignmentSelectedGuage', value: selectedTagValue }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: '' }
        });
        dispatch({
          type: FILTERMODE,
          payload: { FilterMode: 'GaugeMetrics' }
        });
        dispatch({
          type: CREATE_GAUGE_SAGA,
          payload: {
            reqBody: requestObj,
            secondaryOptionCheckValue,
            action: 'reviewMetricReviewList',
            createMode: createMode,
            isMiddlePaneList: true
          }
        });
        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
        dispatch({ type: POPUP_CLOSE });
        dispatch({ type: LOADER_START });
      }
      if (typeOfMiddlePaneList === 'profilerDistinctReviewList') {
        let selectedProfiler = reviewListDistinctData.find((x) => x.id === selectedTagValue);
        if (selectedProfiler.id === 'iGauge Occupation (Culture-Profiler)') {
          const cultureDiamentionArr = [
            {
              id: 1,
              name: 'clusters',
              description: ''
            },
            {
              id: 2,
              name: 'culture-dimensions',
              description: ''
            }
          ];
          let response = {
            profilerName: selectedProfiler?.profilerName,
            profilerDescription: selectedProfiler?.profilerDescription,
            cultureDimensions: cultureDiamentionArr
          };
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'relatedReviewListDistinctData', value: [response] }
          });
          dispatch({ type: LOADER_START });
          setTimeout(() => {
            dispatch({
              type: SET_MIDDLEPANE_STATE,
              payload: {
                middlePaneHeader: popupHeaderOne,
                middlePaneHeaderBadgeOne: 'distinct',
                middlePaneHeaderBadgeTwo: secondaryOptionCheckValue,
                middlePaneHeaderDuplicate: 'profilers',
                middlePaneHeaderDuplicateBadgeOne: 'distinct',
                middlePaneHeaderDuplicateBadgeTwo: middlePaneHeaderBadgeTwo
                  ? middlePaneHeaderBadgeTwo
                  : 'not found',
                middlePaneHeaderDuplicateBadgeThree: '',
                middlePaneHeaderDuplicateBadgeFour: '',
                typeOfMiddlePaneList: 'cultureProfilerDistinctReviewList',
                scanCount: cultureDiamentionArr.length,
                duplicateScanCount: 2, // change
                showMiddlePaneState: true
              }
            });
            dispatch({ type: LOADER_STOP });
            dispatch({ type: POPUP_CLOSE });
          }, 3000);
        } else if (selectedProfiler.id === 'iGauge Occupation (Job-Profiler)') {
          const jobArr = [
            {
              id: 1,
              textOne: 'job-competencies',
              textTwo: ''
            },
            {
              id: 2,
              textOne: 'job-domains',
              textTwo: ''
            },
            {
              id: 3,
              textOne: 'job-functions',
              textTwo: ''
            },
            {
              id: 4,
              textOne: 'job-roles',
              textTwo: ''
            }
          ];
          let response = {
            profilerName: selectedProfiler?.profilerName,
            profilerDescription: selectedProfiler?.profilerDescription,
            jobProfilerList: jobArr
          };
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'relatedReviewListDistinctData', value: [response] }
          });
          dispatch({ type: LOADER_START });
          setTimeout(() => {
            dispatch({
              type: SET_MIDDLEPANE_STATE,
              payload: {
                middlePaneHeader: popupHeaderOne,
                middlePaneHeaderBadgeOne: 'distinct',
                middlePaneHeaderBadgeTwo: secondaryOptionCheckValue,
                middlePaneHeaderDuplicate: 'profilers',
                middlePaneHeaderDuplicateBadgeOne: 'distinct',
                middlePaneHeaderDuplicateBadgeTwo: middlePaneHeaderBadgeTwo
                  ? middlePaneHeaderBadgeTwo
                  : 'not found',
                middlePaneHeaderDuplicateBadgeThree: '',
                middlePaneHeaderDuplicateBadgeFour: '',
                typeOfMiddlePaneList: 'jobProfilerDistinctReviewList',
                scanCount: jobArr.length,
                duplicateScanCount: 2, // change
                showMiddlePaneState: true
              }
            });
            dispatch({ type: LOADER_STOP });
            dispatch({ type: POPUP_CLOSE });
          }, 3000);
        }
        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
        dispatch({ type: POPUP_CLOSE });
        dispatch({
          type: FILTERMODE,
          payload: {
            FilterMode: selectedProfiler.id.replace('-', '').replace(/[{()},'-'," "]/gi, '')
          }
        });
      }
      if (
        typeOfMiddlePaneList === 'cultureProfilerDistinctReviewList' &&
        popupHeaderOne === 'culture-dimensions'
      ) {
        const cultureDiamentionArr = [];
        console.log('cultureProfileDiamentionReviewList', cultureProfileDiamentionReviewList);
        if (cultureProfileDiamentionReviewList.length > 0) {
          cultureProfileDiamentionReviewList.map((element) => {
            element.cultureDimensions.map((data) => {
              let obj = {
                id: data.id,
                name: data.label,
                textOne: data.label,
                description: data.labelDescription,
                explanation: data.labelExplanation,
                cluster: data.labelCluster,
                polarity: data.labelPolarity,
                tag: data.tag
              };
              cultureDiamentionArr.push(obj);
            });
          });
        }
        let relatedData = relatedReviewListDistinctData[0].cultureDimensions[selectedTagValue];
        let response = {
          profilerName: relatedReviewListDistinctData[0].profilerName,
          profilerDescription: relatedReviewListDistinctData[0].profilerDescription,
          ...relatedData,
          cultureDimensions: cultureDiamentionArr.sort((a, b) =>
            a.name !== b.name ? (a.name < b.name ? -1 : 1) : 0
          )
        };
        dispatch({ type: LOADER_START });
        setTimeout(() => {
          dispatch({
            type: SET_MIDDLEPANE_STATE,
            payload: {
              middlePaneHeader: popupHeaderOne,
              middlePaneHeaderBadgeOne: 'distinct',
              middlePaneHeaderBadgeTwo: secondaryOptionCheckValue,
              middlePaneHeaderDuplicate: 'profilers',
              middlePaneHeaderDuplicateBadgeOne: 'distinct',
              middlePaneHeaderDuplicateBadgeTwo: middlePaneHeaderBadgeTwo
                ? middlePaneHeaderBadgeTwo
                : 'not found',
              middlePaneHeaderDuplicateBadgeThree: '',
              middlePaneHeaderDuplicateBadgeFour: '',
              typeOfMiddlePaneList: 'cultureDimensionsDistinctReviewList',
              scanCount: cultureDiamentionArr.length,
              duplicateScanCount: 2,
              showMiddlePaneState: true
            }
          });
          dispatch({ type: LOADER_STOP });
          dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
          dispatch({ type: POPUP_CLOSE });
        }, 3000);
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [response] }
        });
      }
      if (
        typeOfMiddlePaneList === 'cultureProfilerDistinctReviewList' &&
        popupHeaderOne === 'clusters'
      ) {
        console.log('clusterProfiler', clusterProfiler);
        let relatedData = relatedReviewListDistinctData[0].cultureDimensions[selectedTagValue];
        let response = {
          profilerName: relatedReviewListDistinctData[0].profilerName,
          profilerDescription: relatedReviewListDistinctData[0].profilerDescription,
          ...relatedData,
          clustersDimension: clusterProfiler
        };
        dispatch({ type: LOADER_START });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [response] }
        });
        setTimeout(() => {
          dispatch({
            type: SET_MIDDLEPANE_STATE,
            payload: {
              middlePaneHeader: popupHeaderOne,
              middlePaneHeaderBadgeOne: 'distinct',
              middlePaneHeaderBadgeTwo: secondaryOptionCheckValue,
              middlePaneHeaderDuplicate: 'profilers',
              middlePaneHeaderDuplicateBadgeOne: 'distinct',
              middlePaneHeaderDuplicateBadgeTwo: middlePaneHeaderBadgeTwo
                ? middlePaneHeaderBadgeTwo
                : 'not found',
              middlePaneHeaderDuplicateBadgeThree: '',
              middlePaneHeaderDuplicateBadgeFour: '',
              typeOfMiddlePaneList: 'clusterDistinctReviewList',
              scanCount: clusterProfiler.length,
              duplicateScanCount: 2,
              showMiddlePaneState: true
            }
          });
          dispatch({ type: LOADER_STOP });
          dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
          dispatch({ type: POPUP_CLOSE });
        }, 3000);
      }
      if (
        typeOfMiddlePaneList === 'jobProfilerDistinctReviewList' &&
        popupHeaderOne === 'job-competencies'
      ) {
        //const jobCometancy = jobProfilerReviewList?.jobCompetency || [];
        const jobCometancy = [];
        console.log('jobProfilerReviewList', jobProfilerReviewList);
        console.log('jobProfilerReviewList?.jobCompetency', jobProfilerReviewList?.jobCompetency);
        jobProfilerReviewList &&
          jobProfilerReviewList?.jobCompetency.map((data) => {
            let obj = {
              id: data.id,
              textOne: data.iGaugeOccupationFrameworkOneClusterName,
              textTwo: data.iGaugeOccupationFrameworkOneClusterDescription,
              status: '',
              explanation: data.iGaugeOccupationFrameworkOneClusterOneExplanation,
              sequence: data.iGaugeOccupationFrameworkOneClusterSequence,
              cluster: data.iGaugeOccupationFrameworkOneClusterName,
              polarity: data.iGaugeOccupationFrameworkOneClusterOnePolarity
            };
            jobCometancy.push(obj);
          });
        let relatedData = relatedReviewListDistinctData[0].jobProfilerList[selectedTagValue];
        let response = {
          profilerName: relatedReviewListDistinctData[0].profilerName,
          profilerDescription: relatedReviewListDistinctData[0].profilerDescription,
          ...relatedData,
          jobCometancy: jobCometancy,
          jobProfilerList: relatedReviewListDistinctData[0].jobProfilerList
        };
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [response] }
        });
        dispatch({ type: LOADER_START });
        setTimeout(() => {
          dispatch({
            type: SET_MIDDLEPANE_STATE,
            payload: {
              middlePaneHeader: popupHeaderOne,
              middlePaneHeaderBadgeOne: 'distinct',
              middlePaneHeaderBadgeTwo: secondaryOptionCheckValue,
              middlePaneHeaderDuplicate: 'profilers',
              middlePaneHeaderDuplicateBadgeOne: 'distinct',
              middlePaneHeaderDuplicateBadgeTwo: middlePaneHeaderBadgeTwo
                ? middlePaneHeaderBadgeTwo
                : 'not found',
              middlePaneHeaderDuplicateBadgeThree: '',
              middlePaneHeaderDuplicateBadgeFour: '',
              typeOfMiddlePaneList: 'jobCometancyDistinctReviewList',
              scanCount: jobCometancy.length,
              duplicateScanCount: 4,
              showMiddlePaneState: true
            }
          });
          dispatch({ type: LOADER_STOP });
          dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
          dispatch({ type: POPUP_CLOSE });
        }, 3000);
      }
      if (
        typeOfMiddlePaneList === 'jobProfilerDistinctReviewList' &&
        popupHeaderOne === 'job-domains'
      ) {
        const jobDomain = [];
        console.log('jobProfilerReviewList', jobProfilerReviewList);
        jobProfilerReviewList &&
          jobProfilerReviewList?.jobDomain.map((data) => {
            let obj = {
              id: data.id,
              textOne: data.iGaugeOccupationFrameworkOneClusterName,
              textTwo: data.iGaugeOccupationFrameworkOneClusterDescription,
              status: '',
              cluster: data.iGaugeOccupationFrameworkOneClusterName,
              explanation: data.iGaugeOccupationFrameworkOneClusterOneExplanation,
              sequence: data.iGaugeOccupationFrameworkOneClusterSequence,
              polarity: data.iGaugeOccupationFrameworkOneClusterOnePolarity
            };
            jobDomain.push(obj);
          });
        let relatedData = relatedReviewListDistinctData[0].jobProfilerList[selectedTagValue];
        let response = {
          profilerName: relatedReviewListDistinctData[0].profilerName,
          profilerDescription: relatedReviewListDistinctData[0].profilerDescription,
          ...relatedData,
          jobDomain: jobDomain,
          jobProfilerList: relatedReviewListDistinctData[0].jobProfilerList
        };
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [response] }
        });
        dispatch({ type: LOADER_START });
        setTimeout(() => {
          dispatch({
            type: SET_MIDDLEPANE_STATE,
            payload: {
              middlePaneHeader: popupHeaderOne,
              middlePaneHeaderBadgeOne: 'distinct',
              middlePaneHeaderBadgeTwo: secondaryOptionCheckValue,
              middlePaneHeaderDuplicate: 'profilers',
              middlePaneHeaderDuplicateBadgeOne: 'distinct',
              middlePaneHeaderDuplicateBadgeTwo: middlePaneHeaderBadgeTwo
                ? middlePaneHeaderBadgeTwo
                : 'not found',
              middlePaneHeaderDuplicateBadgeThree: '',
              middlePaneHeaderDuplicateBadgeFour: '',
              typeOfMiddlePaneList: 'jobDomainDistinctReviewList',
              scanCount: jobDomain.length,
              duplicateScanCount: 4,
              showMiddlePaneState: true
            }
          });
          dispatch({ type: LOADER_STOP });
          dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
          dispatch({ type: POPUP_CLOSE });
        }, 3000);
      }
      if (
        typeOfMiddlePaneList === 'jobProfilerDistinctReviewList' &&
        popupHeaderOne === 'job-functions'
      ) {
        const jobFunction = [];
        jobProfilerReviewList &&
          jobProfilerReviewList?.jobFunction.map((data) => {
            let obj = {
              id: data.id,
              textOne: data.iGaugeOccupationFrameworkOneClusterName,
              textTwo: data.iGaugeOccupationFrameworkOneClusterDescription,
              status: '',
              cluster: data.iGaugeOccupationFrameworkOneClusterName,
              explanation: data.iGaugeOccupationFrameworkOneClusterOneExplanation,
              sequence: data.iGaugeOccupationFrameworkOneClusterSequence,
              polarity: data.iGaugeOccupationFrameworkOneClusterOnePolarity
            };
            jobFunction.push(obj);
          });
        let relatedData = relatedReviewListDistinctData[0].jobProfilerList[selectedTagValue];
        let response = {
          profilerName: relatedReviewListDistinctData[0].profilerName,
          profilerDescription: relatedReviewListDistinctData[0].profilerDescription,
          ...relatedData,
          jobFunction: jobFunction,
          jobProfilerList: relatedReviewListDistinctData[0].jobProfilerList
        };
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [response] }
        });
        dispatch({ type: LOADER_START });
        setTimeout(() => {
          dispatch({
            type: SET_MIDDLEPANE_STATE,
            payload: {
              middlePaneHeader: popupHeaderOne,
              middlePaneHeaderBadgeOne: 'distinct',
              middlePaneHeaderBadgeTwo: secondaryOptionCheckValue,
              middlePaneHeaderDuplicate: 'profilers',
              middlePaneHeaderDuplicateBadgeOne: 'distinct',
              middlePaneHeaderDuplicateBadgeTwo: middlePaneHeaderBadgeTwo
                ? middlePaneHeaderBadgeTwo
                : 'not found',
              middlePaneHeaderDuplicateBadgeThree: '',
              middlePaneHeaderDuplicateBadgeFour: '',
              typeOfMiddlePaneList: 'jobFunctionDistinctReviewList',
              scanCount: jobFunction.length,
              duplicateScanCount: 4,
              showMiddlePaneState: true
            }
          });
          dispatch({ type: LOADER_STOP });
          dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
          dispatch({ type: POPUP_CLOSE });
        }, 3000);
      }
      if (
        typeOfMiddlePaneList === 'jobProfilerDistinctReviewList' &&
        popupHeaderOne === 'job-roles'
      ) {
        const jobRole = [];
        jobProfilerReviewList &&
          jobProfilerReviewList?.jobRole.map((data) => {
            let obj = {
              id: data.id,
              textOne: data.iGaugeOccupationFrameworkOneClusterName,
              textTwo: data.iGaugeOccupationFrameworkOneClusterDescription,
              status: '',
              cluster: data.iGaugeOccupationFrameworkOneClusterName,
              explanation: data.iGaugeOccupationFrameworkOneClusterOneExplanation,
              sequence: data.iGaugeOccupationFrameworkOneClusterSequence,
              polarity: data.iGaugeOccupationFrameworkOneClusterOnePolarity
            };
            jobRole.push(obj);
          });
        let relatedData = relatedReviewListDistinctData[0].jobProfilerList[selectedTagValue];
        let response = {
          profilerName: relatedReviewListDistinctData[0].profilerName,
          profilerDescription: relatedReviewListDistinctData[0].profilerDescription,
          ...relatedData,
          jobRole: jobRole,
          jobProfilerList: relatedReviewListDistinctData[0].jobProfilerList
        };
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [response] }
        });
        dispatch({ type: LOADER_START });
        setTimeout(() => {
          dispatch({
            type: SET_MIDDLEPANE_STATE,
            payload: {
              middlePaneHeader: popupHeaderOne,
              middlePaneHeaderBadgeOne: 'distinct',
              middlePaneHeaderBadgeTwo: secondaryOptionCheckValue,
              middlePaneHeaderDuplicate: 'profilers',
              middlePaneHeaderDuplicateBadgeOne: 'distinct',
              middlePaneHeaderDuplicateBadgeTwo: middlePaneHeaderBadgeTwo
                ? middlePaneHeaderBadgeTwo
                : 'not found',
              middlePaneHeaderDuplicateBadgeThree: '',
              middlePaneHeaderDuplicateBadgeFour: '',
              typeOfMiddlePaneList: 'jobRoleDistinctReviewList',
              scanCount: jobRole.length,
              duplicateScanCount: 4,
              showMiddlePaneState: true
            }
          });
          dispatch({ type: LOADER_STOP });
          dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
          dispatch({ type: POPUP_CLOSE });
        }, 3000);
      }
      if (typeOfMiddlePaneList === 'assesseeDistinctAssignmentDistinctReviewList') {
        if (popupHeaderOne === 'assessments') {
          dispatch({ type: LOADER_START });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'relatedReviewListDistinctData', value: [] }
          });
          let statusArr = ['ABORTED', 'FINISHED', 'STARTED', 'UNFINISHED', 'UNSTARTED'];
          if (secondaryOptionCheckValue === 'active')
            statusArr = ['ABORTED', 'FINISHED', 'STARTED', 'UNFINISHED', 'UNSTARTED'];
          if (secondaryOptionCheckValue === 'all')
            statusArr = ['ABORTED', 'FINISHED', 'STARTED', 'UNFINISHED', 'UNSTARTED'];
          if (secondaryOptionCheckValue === 'archived') statusArr = ['ARCHIVED'];
          if (secondaryOptionCheckValue === 'inactive') statusArr = [];
          let filterAssessmentList = [];
          let selectedAssignment = relatedReviewListDistinctData[0].assesseeAssignment.filter(
            (id) => id.assesseeAssignmentId === selectedTagValue
          );
          if (secondaryOptionCheckValue === 'active' || secondaryOptionCheckValue === 'all') {
            if (selectedAssignment.length > 0) {
              filterAssessmentList =
                selectedAssignment[0].assesseeAssignmentAssessmentDistinct.filter((ass) =>
                  statusArr.includes(ass.assesseeAssignmentAssessmentStatus)
                );
            }
          }
          let reviseSelectedAssignmentObj = {
            ...selectedAssignment[0],
            assesseeAssignmentAssessmentDistinct: filterAssessmentList
          };
          // for sorting the list
          filterAssessmentList?.sort((a, b) =>
            a.assesseeAssignmentAssessmentName.localeCompare(b.assesseeAssignmentAssessmentName)
          );
          console.log('filterAssessmentList', filterAssessmentList);
          let responseObj = {
            ...relatedReviewListDistinctData[0],
            assesseeAssignmentInfo: reviseSelectedAssignmentObj
          };
          console.log('responseObj', responseObj);
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'relatedReviewListDistinctData', value: [responseObj] }
          });
          dispatch({
            type: FILTERMODE,
            payload: { FilterMode: 'assesseeAssignmentAssessment' + secondaryOptionCheckValue }
          });
          setTimeout(function () {
            dispatch({
              type: SET_MIDDLEPANE_STATE,
              payload: {
                middlePaneHeader: 'assessments',
                middlePaneHeaderBadgeOne: 'distinct',
                middlePaneHeaderBadgeTwo: secondaryOptionCheckValue, //'active',
                middlePaneHeaderBadgeThree: '',
                middlePaneHeaderBadgeFour: '',
                middlePaneHeaderDuplicate: middlePaneHeader,
                middlePaneHeaderDuplicateBadgeOne: middlePaneHeaderBadgeOne,
                middlePaneHeaderDuplicateBadgeTwo: middlePaneHeaderBadgeTwo,
                middlePaneHeaderDuplicateBadgeThree: middlePaneHeaderBadgeThree,
                middlePaneHeaderDuplicateBadgeFour: middlePaneHeaderBadgeFour,
                middlePaneHeaderDuplicateOne: middlePaneHeaderDuplicateOne,
                middlePaneHeaderDuplicateOneBadgeOne: middlePaneHeaderDuplicateOneBadgeOne,
                middlePaneHeaderDuplicateOneBadgeTwo: middlePaneHeaderDuplicateOneBadgeTwo,
                middlePaneHeaderDuplicateOneBadgeThree: middlePaneHeaderDuplicateOneBadgeThree,
                middlePaneHeaderDuplicateOneBadgeFour: middlePaneHeaderDuplicateOneBadgeFour,
                typeOfMiddlePaneList: 'assesseeAssignmentAssessmentReviewList',
                scanCount: filterAssessmentList.length,
                duplicateScanCount: duplicateScanCount,
                showMiddlePaneState: true
              }
            });
            dispatch({ type: LOADER_STOP });
            dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
            dispatch({ type: POPUP_CLOSE });
          }, 100);
        }
        if (popupHeaderOne === 'analytics') {
          dispatch({ type: LOADER_START });
          dispatch({
            type: GET_ASSESSEE_REPORT_SAGA,
            payload: {
              righpaneheaderOne: 'analytics',
              request: {
                module: 'Assessee-Assignment',
                action: 'Result',
                requestObject: {
                  action: 'review',
                  assesseeId: selectedAssociateInfo?.assesseeId,
                  associateId:
                    selectedAssociateInfo?.associate?.informationEngagement.associateTag
                      .associateTagPrimary,
                  assesseeResult: {
                    assesseeId: relatedReviewListDistinctData[0]?.assesseeId,
                    assignmentId: selectedTagValue
                  }
                }
              }
            }
          });
          dispatch({ type: POPUP_CLOSE });
        }
      }
      if (typeOfMiddlePaneList === 'assignmentDistinctAssesseeReviewList') {
        if (popupHeaderOne === 'assessments') {
          console.log('relatedReviewListDistinctData[0]', relatedReviewListDistinctData[0]);
          dispatch({ type: LOADER_START });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'relatedReviewListDistinctData', value: [] }
          });
          let statusArr = ['ABORTED', 'FINISHED', 'STARTED', 'UNFINISHED', 'UNSTARTED'];
          if (secondaryOptionCheckValue === 'active')
            statusArr = ['STARTED', 'UNFINISHED', 'UNSTARTED'];
          if (secondaryOptionCheckValue === 'all')
            statusArr = [
              'ABORTED',
              'FINISHED',
              'STARTED',
              'UNFINISHED',
              'UNSTARTED',
              'SUSPENDED',
              'TERMINATED'
            ];
          if (secondaryOptionCheckValue === 'archived') statusArr = ['ARCHIVED'];
          if (secondaryOptionCheckValue === 'inactive')
            statusArr = ['ABORTED', 'FINISHED', 'UNFINISHED', 'SUSPENDED', 'TERMINATED'];
          let filterAssessmentList = [];
          let selectedAssignment = relatedReviewListDistinctData[0].assessee.filter(
            (id) => id.assesseeIdTwo === selectedTagValue
          );
          if (
            secondaryOptionCheckValue === 'active' ||
            secondaryOptionCheckValue === 'all' ||
            secondaryOptionCheckValue === 'inactive'
          ) {
            if (selectedAssignment.length > 0) {
              filterAssessmentList =
                selectedAssignment[0].assignmentData.assesseeAssignmentAssessmentDistinct.filter(
                  (ass) => statusArr.includes(ass.assesseeAssignmentAssessmentStatus)
                );
            }
          }
          let reviseSelectedAssignmentObj = {
            ...selectedAssignment[0],
            assesseeAssignmentAssessmentDistinct: filterAssessmentList
          };
          filterAssessmentList?.sort((a, b) =>
            a.assesseeAssignmentAssessmentName.localeCompare(b.assesseeAssignmentAssessmentName)
          );
          console.log('selectedAssignment', selectedAssignment);
          console.log('filterAssessmentList', filterAssessmentList);
          let responseObj = {
            ...relatedReviewListDistinctData[0],
            assignmentAssesseeInfo: reviseSelectedAssignmentObj
          };
          console.log('responseObj', responseObj);
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'relatedReviewListDistinctData', value: [responseObj] }
          });
          dispatch({
            type: FILTERMODE,
            payload: { FilterMode: 'assesseeAssignmentAssessment' + secondaryOptionCheckValue }
          });
          setTimeout(function () {
            dispatch({
              type: SET_MIDDLEPANE_STATE,
              payload: {
                middlePaneHeader: 'assessments',
                middlePaneHeaderBadgeOne: 'distinct',
                middlePaneHeaderBadgeTwo: secondaryOptionCheckValue,
                middlePaneHeaderBadgeThree: '',
                middlePaneHeaderBadgeFour: '',
                middlePaneHeaderDuplicate: middlePaneHeader,
                middlePaneHeaderDuplicateBadgeOne: middlePaneHeaderBadgeOne,
                middlePaneHeaderDuplicateBadgeTwo: middlePaneHeaderBadgeTwo,
                middlePaneHeaderDuplicateBadgeThree: middlePaneHeaderBadgeThree,
                middlePaneHeaderDuplicateBadgeFour: middlePaneHeaderBadgeFour,
                middlePaneHeaderDuplicateOne: middlePaneHeaderDuplicateOne,
                middlePaneHeaderDuplicateOneBadgeOne: middlePaneHeaderDuplicateOneBadgeOne,
                middlePaneHeaderDuplicateOneBadgeTwo: middlePaneHeaderDuplicateOneBadgeTwo,
                middlePaneHeaderDuplicateOneBadgeThree: middlePaneHeaderDuplicateOneBadgeThree,
                middlePaneHeaderDuplicateOneBadgeFour: middlePaneHeaderDuplicateOneBadgeFour,
                typeOfMiddlePaneList: 'assignmentAssesseeAssessmentDistinctReviewList',
                scanCount: filterAssessmentList.length,
                duplicateScanCount: duplicateScanCount,
                showMiddlePaneState: true
              }
            });
            dispatch({ type: LOADER_STOP });
            dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
            dispatch({ type: POPUP_CLOSE });
          }, 100);
        }
      }
      if (typeOfMiddlePaneList === 'assesseeAssignmentDistinctReviewList') {
        console.log('selectedTagValue', selectedTagValue);
        dispatch({ type: LOADER_START });
        dispatch({
          type: GET_ASSESSEE_REPORT_SAGA,
          payload: {
            righpaneheaderOne: 'analytics',
            request: {
              module: 'Assessee-Assignment',
              action: 'Result',
              requestObject: {
                action: 'review',
                assesseeId: selectedAssociateInfo?.assesseeId,
                associateId:
                  selectedAssociateInfo?.associate?.informationEngagement.associateTag
                    .associateTagPrimary,
                assesseeResult: {
                  assesseeId: selectedAssociateInfo?.assesseeId,
                  assignmentId: selectedTagValue
                }
              }
            }
          }
        });
        dispatch({ type: POPUP_CLOSE });
      }
    } else if (dataVal === 'reviewReport') {
      if (typeOfMiddlePaneList === 'assignmentReportReviewList') {
        let listDistinctData = relatedReviewListDistinctData[0];
        let reportData = assignmentReportsData.filter((data) => {
          return data.id === parseInt(selectedTagValue);
        });

        if (!reportData || reportData?.length === 0) {
          let assignmentReportsData2 = [
            {
              id: 4,
              reportName: 'Merit List',
              reportDescription: 'Culture-Fit'
            },
            {
              id: 6,
              reportName: 'Merit List',
              reportDescription: 'Job-Fit'
            },
            {
              id: 9,
              reportName: 'Merit List',
              reportDescription: 'Work-Fit'
            }
          ];
          reportData = assignmentReportsData2.filter((data) => {
            return data.id === parseInt(selectedTagValue);
          });
        }

        dispatch({ type: LOADER_START });
        let requestObj = {
          module: 'Assessee-Assignment',
          action: 'Merit-List',
          requestObject: {
            assesseeId: selectedAssociateInfo?.assesseeId,
            associateId:
              selectedAssociateInfo?.associate?.informationEngagement.associateTag
                .associateTagPrimary,
            assignmentId: listDistinctData.assingmentId,
            resultResponse: 'Overall-Fit'
          }
        };
        dispatch({
          type: GET_ASSIGNMENT_REPORT_SAGA,
          payload: {
            request: requestObj,
            assignmentId: listDistinctData.assingmentId,
            assignmentName: listDistinctData.assignmentName,
            assignmentDescription: listDistinctData.assignmentDescription,
            isRightPaneReview: false,
            righpaneheaderOne: 'reports',
            reportId: selectedTagValue,
            reportName: reportData[0].reportName,
            reportDescription: reportData[0].reportDescription
          }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
        dispatch({ type: POPUP_CLOSE });
      }
      if (typeOfMiddlePaneList === 'assesseesReportReviewList') {
        dispatch({ type: LOADER_START });
        dispatch({
          type: GET_ASSESSEE_REPORT_SAGA,
          payload: {
            request: {
              module: 'Assessee-Assignment',
              action: 'Result',
              requestObject: {
                action: 'review',
                assesseeId: selectedAssociateInfo?.assesseeId,
                associateId:
                  selectedAssociateInfo?.associate?.informationEngagement.associateTag
                    .associateTagPrimary,
                assesseeResult: {
                  assesseeId: relatedReviewListDistinctData[0]?.assesseeReport?.assesseeId,
                  assignmentId: relatedReviewListDistinctData[0]?.id
                }
              }
            }
          }
        });
        dispatch({ type: POPUP_CLOSE });
      }
      if (typeOfMiddlePaneList === 'assignmentMeritListReportList') {
        dispatch({ type: LOADER_START });
        dispatch({
          type: GET_ASSESSEE_REPORT_SAGA,
          payload: {
            request: {
              module: 'Assessee-Assignment',
              action: 'Result',
              requestObject: {
                action: 'review',
                assesseeId: selectedAssociateInfo?.assesseeId,
                associateId:
                  selectedAssociateInfo?.associate?.informationEngagement.associateTag
                    .associateTagPrimary,
                assesseeResult: {
                  assesseeId: selectedTagValue,
                  assignmentId: selectedTagStatus
                }
              }
            },
            righpaneheaderOne: 'analytics'
          }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'assesseeAssignmentReportData', value: assignmentReportResponse }
        });
        dispatch({ type: POPUP_CLOSE });
      }
    } else if (dataVal === 'reviseReport') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'REVISE_ASSESSEE_FEEDBACK', popupMode: 'ASSESSEE_REPORT_REVISE' }
      });
    } else if (dataVal === 'revise') {
      setIsReviseMode(true);
      dispatch({
        type: SET_MIDDLEPANE_SECONDARY_OPTION,
        payload: { badgeValue: dataVal, keyValue: keyVal, isPopUpFrom: 'reviewList' }
      });
    } else if (dataVal === 'shareApiCall' || dataVal === 'unshareApiCall') {

      let reqBody = null;
      let shareVal = '';
      let typeShare =
        secondaryOptionCheckValue.toUpperCase() +
        '_' +
        forthOptionCheckValue.toUpperCase() +
        '_' +
        tertiaryOptionCheckValue.toUpperCase();
      if (
        typeOfMiddlePaneList === 'assesseeRoleDistinctReviewList' ||
        createMode === 'assesseesRole'
      ) {
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'assesseeRole' }
          });
        } else {
          reqBody = {
            module: 'Assessee-Role',
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              assesseeRoleShare: typeShare,
              assesseeRoleShareInformation: [],
              assesseeRoleShared: [
                {
                  assesseeRoleId: selectedTagValue
                  // assesseeRoleGroupId: selectedTagGroupId
                }
              ]
            }
          };
          shareVal = 'assesseeRole';
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
        }
      }
      if (
        typeOfMiddlePaneList === 'associateRoleDistinctReviewList' ||
        createMode === 'associatesRole'
      ) {
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'associateRole' }
          });
        } else {
          reqBody = {
            module: 'Associate-Role',
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              associateRoleShare: typeShare,
              associateRoleShareInformation: [],
              associateRoleShared: [
                {
                  associateRoleId: selectedTagValue
                  // associateRoleGroupId: selectedTagGroupId
                }
              ]
            }
          };
          shareVal = 'associateRole';
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
        }
      }
      if (
        typeOfMiddlePaneList === 'associatesNodeDistinctReviewList' ||
        typeOfMiddlePaneList === 'associatesGroupAssociateReviewList'
      ) {
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'associateDistinct' }
          });
        } else {
          reqBody = {
            module: 'Associate-Distinct',
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              associateNodeShare: typeShare,
              associateNodeShareInformation: [],
              associateNodeShared: [
                {
                  associateNodeId: selectedTagValue
                }
              ]
            }
          };
          shareVal = 'associateDistinct';
        }
      }
      if (
        typeOfMiddlePaneList === 'assesseesTypeDistinctReviewList' ||
        createMode === 'assesseesType'
      ) {
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'assesseeType' }
          });
        } else {
          reqBody = {
            module: 'Assessee-Type',
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              assesseeTypeShare: typeShare,
              assesseeTypeShareInformation: [],
              assesseeTypeShared: [
                {
                  assesseeTypeId: selectedTagValue
                  // assesseeTypeGroupId: selectedTagGroupId
                }
              ]
            }
          };
          shareVal = 'assesseeType';
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
        }
      }
      if (
        typeOfMiddlePaneList === 'assessmentsTypeDistinctReviewList' ||
        createMode === 'assessmentsType'
      ) {
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'assessmentType' }
          });
        } else {
          reqBody = {
            module: 'Assessment-Type',
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              assessmentTypeShare: typeShare,
              assessmentTypeShareInformation: [],
              assessmentTypeShared: [
                {
                  assessmentTypeId: selectedTagValue
                  // assessmentTypeGroupId: selectedTagGroupId
                }
              ]
            }
          };
          shareVal = 'assessmentType';
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
        }
      }
      if (
        typeOfMiddlePaneList === 'assignmentsTypeDistinctReviewList' ||
        createMode === 'assignmentsType'
      ) {
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'assignmentType' }
          });
        } else {
          reqBody = {
            module: 'Assignment-Type',
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              assignmentTypeShare: typeShare,
              assignmentTypeShareInformation: [],
              assignmentTypeShared: [
                {
                  assignmentTypeId: selectedTagValue
                  // assignmentTypeGroupId: selectedTagGroupId
                }
              ]
            }
          };
          shareVal = 'assignmentType';
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
        }
      }
      if (
        typeOfMiddlePaneList === 'associatesTypeDistinctReviewList' ||
        createMode === 'associatesType'
      ) {
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'associateType' }
          });
        } else {
          reqBody = {
            module: 'Associate-Type',
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              associateTypeShare: typeShare,
              associateTypeShareInformation: [],
              associateTypeShared: [
                {
                  associateTypeId: selectedTagValue
                  // associateTypeGroupId: selectedTagGroupId
                }
              ]
            }
          };
          shareVal = 'associateType';
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
        }
      }
      if (typeOfMiddlePaneList === 'itemsTypeDistinctReviewList' || createMode === 'itemsType') {
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'itemType' }
          });
        } else {
          reqBody = {
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            module: 'Item-Type',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              itemTypeShare: typeShare,
              itemTypeShareInformation: [],
              itemTypeShared: [
                {
                  itemTypeId: selectedTagValue
                  // itemTypeGroupId: selectedTagGroupId
                }
              ]
            }
          };
          shareVal = 'itemType';
        }
      }
      if (
        typeOfMiddlePaneList === 'cultureProfilesTypeDistinctReviewList' ||
        createMode === 'culture-profilesType'
      ) {
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'cultureProfileType' }
          });
        } else {
          reqBody = {
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            module: 'Culture-Profile-Type',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              cultureProfileTypeShare: typeShare,
              cultureProfileTypeShareInformation: [],
              cultureProfileTypeShared: [
                {
                  cultureProfileTypeId: selectedTagValue
                  // cultureProfileTypeGroupId: selectedTagGroupId
                }
              ]
            }
          };
          shareVal = 'cultureProfileType';
        }
      }
      if (
        typeOfMiddlePaneList === 'jobProfilesTypeDistinctReviewList' ||
        createMode === 'job-profilesType'
      ) {
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'jobProfileType' }
          });
        } else {
          reqBody = {
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            module: 'Job-Profile-Type',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              jobProfileTypeShare: typeShare,
              jobProfileTypeShareInformation: [],
              jobProfileTypeShared: [
                {
                  jobProfileTypeId: selectedTagValue
                  // jobProfileTypeGroupId: selectedTagGroupId
                }
              ]
            }
          };
          shareVal = 'jobProfileType';
        }
      }
      if (
        typeOfMiddlePaneList === 'assesseesGroupDistinctReviewList' ||
        createMode === 'assesseesGroup'
      ) {
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'assesseeGroup' }
          });
        } else {
          reqBody = {
            module: 'Assessee-Group',
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              assesseeGroupShare: typeShare,
              assesseeGroupShare: typeShare,
              assesseeGroupShareInformation: [],
              assesseeGroupShared: [
                {
                  assesseeGroupId: selectedTagValue
                  // assesseeRoleGroupId: selectedTagGroupId
                }
              ]
            }
          };
          shareVal = 'assesseeGroup';
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
        }
      }
      if (
        typeOfMiddlePaneList === 'assessmentsGroupDistinctReviewList' ||
        createMode === 'assessmentsGroup'
      ) {
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'assessmentGroup' }
          });
        } else {
          reqBody = {
            module: 'Assessment-Group',
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              assessmentGroupShare: typeShare,
              assessmentGroupShareInformation: [],
              assessmentGroupShared: [
                {
                  assessmentGroupId: selectedTagValue
                  // assesseeRoleGroupId: selectedTagGroupId
                }
              ]
            }
          };
          shareVal = 'assessmentGroup';
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
        }
      }
      if (
        typeOfMiddlePaneList === 'assignmentsGroupDistinctReviewList' ||
        createMode === 'assignmentsGroup'
      ) {
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'assignmentGroup' }
          });
        } else {
          reqBody = {
            module: 'Assignment-Group',
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              assignmentGroupShare: typeShare,
              // assignmentGroupShareFee: assignmentGroup.informationSetup.assignmentGroupShareFee,
              assignmentGroupShareInformation: [],
              assignmentGroupShared: [
                {
                  assignmentGroupId: selectedTagValue
                  // assesseeRoleGroupId: selectedTagGroupId
                }
              ]
            }
          };
          shareVal = 'assignmentGroup';
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
        }
      }
      if (
        typeOfMiddlePaneList === 'associatesGroupDistinctReviewList' ||
        createMode === 'associatesGroup'
      ) {
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'associateGroup' }
          });
        } else {
          reqBody = {
            module: 'Associate-Group',
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              associateGroupShare: typeShare,
              associateGroupShareInformation: [],
              associateGroupShared: [
                {
                  associateGroupId: selectedTagValue
                  // assesseeRoleGroupId: selectedTagGroupId
                }
              ]
            }
          };
          shareVal = 'associateGroup';
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
        }
      }
      if (
        typeOfMiddlePaneList === 'cultureProfilesGroupDistinctReviewList' ||
        createMode === 'culture-profilesGroup'
      ) {
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'cultureProfileGroup' }
          });
        } else {
          reqBody = {
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            module: 'Culture-Profile-Group',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              cultureProfileGroupShare: typeShare,
              cultureProfileGroupShareInformation: [],
              cultureProfileGroupShared: [
                {
                  cultureProfileGroupId: selectedTagValue
                  // assesseeRoleGroupId: selectedTagGroupId
                }
              ]
            }
          };
          shareVal = 'cultureProfileGroup';
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
        }
      }
      if (
        typeOfMiddlePaneList === 'jobProfilesGroupDistinctReviewList' ||
        createMode === 'job-ProfilesGroup' ||
        createMode === 'jobProfilesGroup'
      ) {
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'jobProfileGroup' }
          });
        } else {
          reqBody = {
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            module: 'Job-Profile-Group',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              jobProfileGroupShare: typeShare,
              jobProfileGroupShareInformation: [],
              jobProfileGroupShared: [
                {
                  jobProfileGroupId: selectedTagValue
                  // assesseeRoleGroupId: selectedTagGroupId
                }
              ]
            }
          };
          shareVal = 'jobProfileGroup';
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
        }
      }
      if (typeOfMiddlePaneList === 'itemsGroupDistinctReviewList' || createMode === 'itemsGroup') {
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'itemGroup' }
          });
        } else {
          reqBody = {
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            module: 'Item-Group',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              itemGroupShare: typeShare,
              itemGroupShareInformation: [],
              itemGroupShared: [
                {
                  itemGroupId: selectedTagValue
                  // assesseeRoleGroupId: selectedTagGroupId
                }
              ]
            }
          };
          shareVal = 'itemGroup';
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
        }
      }
      if (
        typeOfMiddlePaneList === 'assesseesDistinctReviewList' ||
        typeOfMiddlePaneList === 'administratorsDistinctReviewList' ||
        typeOfMiddlePaneList === 'assistantsDistinctReviewList' ||
        typeOfMiddlePaneList === 'managersDistinctReviewList' ||
        typeOfMiddlePaneList === 'assesseesGroupAssesseeReviewList' ||
        typeOfMiddlePaneList === 'assesseesTypeAssesseeReviewList' ||
        createMode === 'assessee'
      ) {
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'assesseeDistinct' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'isDistinct', value: true }
          });
        } else {
          reqBody = {
            module: 'Assessee-Distinct',
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              assesseeDistinctShare: typeShare,
              assesseeDistinctShareInformation: [],
              assesseeDistinctShared: [
                {
                  assesseeId: selectedTagValue
                }
              ]
            }
          };
          shareVal = 'assesseeDistinct';
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { type: 'typeOfMiddlePaneList', value: '' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'relatedReviewListDistinctData', value: [] }
          });
        }
      }
      if (
        typeOfMiddlePaneList === 'assessmentDistinctReviewList' ||
        typeOfMiddlePaneList === 'assessmentGroupAssessmentReviewList' ||
        typeOfMiddlePaneList === 'assessmentTypeAssessmentReviewList' ||
        createMode === 'assessment'
      ) {
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'assessmentDistinct' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'isDistinct', value: true }
          });
        } else {
          reqBody = {
            module: 'Assessment-Distinct',
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              assessmentDistinctShare: typeShare,
              assessmentShareFee: assessmentInfo.informationSetup.assessmentShareFee,
              assessmentDistinctShareInformation: [],
              assessmentDistinctShared: [
                {
                  assessmentId: selectedTagValue
                }
              ]
            }
          };
          shareVal = 'assessmentDistinct';
          dispatch({ type: SET_PAGE_COUNT, value: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
          // dispatch({
          //   type:SET_DISPLAY_TWO_SINGLE_STATE,
          //   payload:{stateName:'typeOfMiddlePaneList',value:''}
          // })
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'relatedReviewListDistinctData', value: [] }
          });
        }
      }
      if (
        typeOfMiddlePaneList === 'assignmentDistinctReviewList' ||
        typeOfMiddlePaneList === 'assignmentGroupAssignmentReviewList' ||
        typeOfMiddlePaneList === 'assignmentTypeAssignmentReviewList' ||
        createMode === 'assignment'
      ) {
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'assignmentDistinct' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'isDistinct', value: true }
          });
        } else {
          reqBody = {
            module: 'Assignment-Distinct',
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              // assignmentShareFee: assignmentInfo.informationSetup.assignmentShareFee,
              assignmentDistinctShare: typeShare,
              assignmentDistinctShareInformation: [],
              assignmentDistinctShared: [
                {
                  assignmentId: selectedTagValue
                }
              ]
            }
          };

          shareVal = 'assignmentDistinct';
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'relatedReviewListDistinctData', value: [] }
          });
        }
      }
      if (
        typeOfMiddlePaneList === 'jobProfilesDistinctReviewList' ||
        typeOfMiddlePaneList === 'jobProfileGroupJobProfileReviewList' ||
        typeOfMiddlePaneList === 'jobProfileTypeJobProfileReviewList' ||
        createMode === 'jobProfile'
      ) {
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'jobProfileDistinct' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'isDistinct', value: true }
          });
        } else {
          reqBody = {
            module: 'Job-Profile-Distinct',
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              jobProfileDistinctShare: typeShare,
              jobProfileDistinctShareInformation: [],
              jobProfileDistinctShared: [
                {
                  jobProfileId: selectedTagValue
                }
              ]
            }
          };
          shareVal = 'jobProfileDistinct';
          dispatch({ type: SET_PAGE_COUNT, value: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
          // dispatch({
          //   type:SET_DISPLAY_TWO_SINGLE_STATE,
          //   payload:{stateName:'typeOfMiddlePaneList',value:''}
          // })
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'relatedReviewListDistinctData', value: [] }
          });
        }
      }
      if (
        typeOfMiddlePaneList === 'cultureProfilesDistinctReviewList' ||
        typeOfMiddlePaneList === 'cultureProfileGroupCultureProfileReviewList' ||
        typeOfMiddlePaneList === 'cultureProfileTypeCultureProfileReviewList' ||
        createMode === 'cultureProfile'
      ) {
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'cultureProfileDistinct' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'isDistinct', value: true }
          });
        } else {
          reqBody = {
            module: 'Culture-Profile-Distinct',
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              cultureProfileDistinctShare: typeShare,
              cultureProfileDistinctShareInformation: [],
              cultureProfileDistinctShared: [
                {
                  cultureProfileId: selectedTagValue
                }
              ]
            }
          };
          shareVal = 'cultureProfileDistinct';
          dispatch({ type: SET_PAGE_COUNT, value: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
          // dispatch({
          //   type:SET_DISPLAY_TWO_SINGLE_STATE,
          //   payload:{stateName:'typeOfMiddlePaneList',value:''}
          // })
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'relatedReviewListDistinctData', value: [] }
          });
        }
      }
      if (
        typeOfMiddlePaneList === 'itemsDistinctReviewList' ||
        typeOfMiddlePaneList === 'itemTypeItemReviewList' ||
        typeOfMiddlePaneList === 'itemGroupItemReviewList' ||
        createMode === 'item'
      ) {
        if (typeShare === 'ASSOCIATE__DISTINCT') {
          getAssociateDistinctListApiCall(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            countPage,
            dataVal
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'previousList', value: 'itemDistinct' }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'isDistinct', value: true }
          });
        } else {
          reqBody = {
            action: dataVal === 'shareApiCall' ? 'Share' : 'Unshare',
            module: 'Item-Distinct',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              itemDistinctShare: typeShare,
              itemDistinctShareInformation: [],
              itemDistinctShared: [
                {
                  itemId: selectedTagValue
                }
              ]
            }
          };
          shareVal = 'itemDistinct';
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'relatedReviewListDistinctData', value: [] }
          });
        }
      }
      // }
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'middlePaneSelectedValue', value: selectedTagValue }
      });
      if (reqBody) {
        dispatch({ type: LOADER_START });
        if (
          shareVal === 'assesseeDistinct' ||
          shareVal === 'assessmentDistinct' ||
          shareVal === 'assignmentDistinct' ||
          shareVal === 'associateDistinct' ||
          shareVal === 'cultureProfileDistinct' ||
          shareVal === 'jobProfileDistinct' ||
          shareVal === 'itemDistinct'
        ) {
          if (reqBody.action.toLocaleLowerCase() === 'share') {
            let header =
              popupHeaderOneBadgeOne === 'distinct' ? popupHeaderOne + 's' : popupHeaderOne;
            let keyString = `${header.slice(0, -1)}ShareFee`;

            if (header === 'culture-profiles' || header === 'job-profiles') {
              keyString = header.split('-').join('').replaceAll('profiles', 'Profile') + 'ShareFee';
            }
            updatedShareFee >= 0 && (reqBody.requestObject[keyString] = updatedShareFee);
          }
          console.log('updatedShareFee---', updatedShareFee);

          dispatch({
            //type: SHARE_ROLES_TYPES_SAGA,
            type: SHARE_DISTINCT_SAGA,
            payload: {
              secondaryOptionCheckValue: '',
              headerOne: '',
              request: reqBody,
              apiCall: dataVal,
              shareValue: shareVal
            }
          });
        } else {
          if (
            reqBody.module.toLocaleLowerCase().includes('group') &&
            reqBody.action.toLocaleLowerCase() === 'share'
          ) {
            let header =
              popupHeaderOneBadgeOne === 'distinct' ? popupHeaderOne + 's' : popupHeaderOne;
            let keyString = `${header.slice(0, -1)}GroupShareFee`;

            if (header === 'culture-profiles' || header === 'job-profiles') {
              keyString =
                header.split('-').join('').replaceAll('profiles', 'Profile') + 'GroupShareFee';
            }
            updatedShareFee >= 0 && (reqBody.requestObject[keyString] = updatedShareFee);
          }
          console.log('updatedShareFee---', updatedShareFee);

          dispatch({
            //type: SHARE_ROLES_TYPES_SAGA,
            type: SHARE_GROUPS_ROLES_TYPES_SAGA,
            payload: {
              secondaryOptionCheckValue: '',
              headerOne: '',
              request: reqBody,
              apiCall: dataVal,
              shareValue: shareVal,
              createMode: createMode,
              cardValue: cardValue
            }
          });
        }
      }
      dispatch({ type: POPUP_CLOSE });
      dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
    } else if (dataVal === 'flagedApiCall' || dataVal === 'unflagedApiCall') {
      let reqBody = null;
      if (typeOfMiddlePaneList === 'itemsDistinctReviewList' || createMode === 'item') {
        let value = dataVal === 'flagedApiCall' ? true : false;
        updateItemDistinctFlagStatus(selectedAssociateInfo, selectedTagValue, dispatch, value);
        dispatch({ type: POPUP_CLOSE });
      }
      if (typeOfMiddlePaneList === 'itemsGroupDistinctReviewList' || createMode === 'itemsGroup') {
        let value = dataVal === 'flagedApiCaitemsGroupDistinctReviewListll' ? true : false;
        updateItemGroupFlagStatus(
          selectedAssociateInfo,
          selectedTagValue,
          dispatch,
          value,
          createMode
        );
        dispatch({ type: POPUP_CLOSE });
      }
      if (typeOfMiddlePaneList === 'itemsTypeDistinctReviewList' || createMode === 'itemsType') {
        let value = dataVal === 'flagedApiCall' ? true : false;
        updateItemTypeFlagStatus(selectedAssociateInfo, selectedTagValue, dispatch, value);
        dispatch({ type: POPUP_CLOSE });
      }
      if (typeOfMiddlePaneList === 'assignmentDistinctReviewList' || createMode === 'assignment') {
        let value = dataVal === 'flagedApiCall' ? true : false;
        let keyFlagVal = 'assignment';
        let flagVal = 'assignmentFlag';
        updateAssignmentFlagStatus(
          selectedAssociateInfo,
          selectedTagValue,
          dispatch,
          value,
          keyFlagVal,
          flagVal,
          createMode
        );
        dispatch({ type: POPUP_CLOSE });
      }
      if (
        typeOfMiddlePaneList === 'assignmentsGroupDistinctReviewList' ||
        createMode === 'assignmentsGroup'
      ) {
        let value = dataVal === 'flagedApiCall' ? true : false;
        let keyFlagVal = 'assignmentGroup';
        let flagVal = 'assignmentGroupFlag';
        updateAssignmentFlagStatus(
          selectedAssociateInfo,
          selectedTagValue,
          dispatch,
          value,
          keyFlagVal,
          flagVal
        );
        dispatch({ type: POPUP_CLOSE });
      }
      if (
        typeOfMiddlePaneList === 'assignmentsTypeDistinctReviewList' ||
        createMode === 'assignmentsType'
      ) {
        let value = dataVal === 'flagedApiCall' ? true : false;
        let keyFlagVal = 'assignmentType';
        let flagVal = 'assignmentTypeFlag';
        updateAssignmentFlagStatus(
          selectedAssociateInfo,
          selectedTagValue,
          dispatch,
          value,
          keyFlagVal,
          flagVal
        );
        dispatch({ type: POPUP_CLOSE });
      }
      if (
        typeOfMiddlePaneList === 'assesseeRoleDistinctReviewList' ||
        createMode === 'assesseesRole'
      ) {
        let value = dataVal === 'flagedApiCall' ? true : false;
        let keyFlagVal = 'assesseeRole';
        let flagVal = 'assesseeRoleFlag';
        updateAssesseeFlagUpdate(
          selectedAssociateInfo,
          selectedTagValue,
          dispatch,
          value,
          keyFlagVal,
          flagVal
        );
        dispatch({ type: POPUP_CLOSE });
      }
      if (
        typeOfMiddlePaneList === 'assesseesTypeDistinctReviewList' ||
        createMode === 'assesseesType'
      ) {
        let value = dataVal === 'flagedApiCall' ? true : false;
        let keyFlagVal = 'assesseeType';
        let flagVal = 'assesseeTypeFlag';
        updateAssesseeFlagUpdate(
          selectedAssociateInfo,
          selectedTagValue,
          dispatch,
          value,
          keyFlagVal,
          flagVal
        );
        dispatch({ type: POPUP_CLOSE });
      }
      if (
        typeOfMiddlePaneList === 'assesseesGroupDistinctReviewList' ||
        createMode === 'assesseesGroup'
      ) {
        let value = dataVal === 'flagedApiCall' ? true : false;
        let keyFlagVal = 'assesseeGroup';
        let flagVal = 'assesseeGroupFlag';
        updateAssesseeFlagUpdate(
          selectedAssociateInfo,
          selectedTagValue,
          dispatch,
          value,
          keyFlagVal,
          flagVal
        );
        dispatch({ type: POPUP_CLOSE });
      }
      if (typeOfMiddlePaneList === 'assesseesDistinctReviewList' || createMode === 'assessee') {
        let value = dataVal === 'flagedApiCall' ? true : false;
        let keyFlagVal = 'assessee';
        let flagVal = 'assesseeFlag';
        updateAssesseeFlagUpdate(
          selectedAssociateInfo,
          selectedTagValue,
          dispatch,
          value,
          keyFlagVal,
          flagVal
        );
        dispatch({ type: POPUP_CLOSE });
      }
      if (
        typeOfMiddlePaneList === 'associatesNodeDistinctReviewList' ||
        typeOfMiddlePaneList === 'associateDistinctReviewList' ||
        createMode === 'associate'
      ) {
        let value = dataVal === 'flagedApiCall' ? true : false;
        let keyFlagVal = 'associateDistinct';
        let flagVal = 'associateFlag';
        updateAssociateFlagUpdate(
          selectedAssociateInfo,
          selectedTagValue,
          dispatch,
          value,
          keyFlagVal,
          flagVal,
          createMode
        );
        dispatch({ type: POPUP_CLOSE });
      }
      if (
        typeOfMiddlePaneList === 'associatesGroupDistinctReviewList' ||
        createMode === 'associatesGroup'
      ) {
        let value = dataVal === 'flagedApiCall' ? true : false;
        let keyFlagVal = 'associateGroup';
        let flagVal = 'associateGroupFlag';
        updateAssociateFlagUpdate(
          selectedAssociateInfo,
          selectedTagValue,
          dispatch,
          value,
          keyFlagVal,
          flagVal
        );
        dispatch({ type: POPUP_CLOSE });
      }
      if (
        typeOfMiddlePaneList === 'associatesTypeDistinctReviewList' ||
        createMode === 'associatesType'
      ) {
        let value = dataVal === 'flagedApiCall' ? true : false;
        let keyFlagVal = 'associateType';
        let flagVal = 'associateTypeFlag';
        updateAssociateFlagUpdate(
          selectedAssociateInfo,
          selectedTagValue,
          dispatch,
          value,
          keyFlagVal,
          flagVal
        );
        dispatch({ type: POPUP_CLOSE });
      }
      if (
        typeOfMiddlePaneList === 'associateNodeDistinctReviewList' ||
        createMode === 'associatesNode'
      ) {
        let value = dataVal === 'flagedApiCall' ? true : false;
        let keyFlagVal = 'associateNode';
        let flagVal = 'associateNodeFlag';
        updateAssociateFlagUpdate(
          selectedAssociateInfo,
          selectedTagValue,
          dispatch,
          value,
          keyFlagVal,
          flagVal
        );
        dispatch({ type: POPUP_CLOSE });
      }
      if (
        typeOfMiddlePaneList === 'associateRoleDistinctReviewList' ||
        createMode === 'associatesRole'
      ) {
        let value = dataVal === 'flagedApiCall' ? true : false;
        let keyFlagVal = 'associateRole';
        let flagVal = 'associateRoleFlag';
        updateAssociateFlagUpdate(
          selectedAssociateInfo,
          selectedTagValue,
          dispatch,
          value,
          keyFlagVal,
          flagVal
        );
        dispatch({ type: POPUP_CLOSE });
      }
      if (
        typeOfMiddlePaneList === 'cultureProfilesDistinctReviewList' ||
        createMode === 'cultureProfile'
      ) {
        let value = dataVal === 'flagedApiCall' ? true : false;
        let keyFlagVal = 'cultureProfile';
        let flagVal = 'cultureProfileFlag';
        updateCultureProfileFlagUpdate(
          selectedAssociateInfo,
          selectedTagValue,
          dispatch,
          value,
          keyFlagVal,
          flagVal
        );
        dispatch({ type: POPUP_CLOSE });
      }
      if (
        typeOfMiddlePaneList === 'cultureProfilesGroupDistinctReviewList' ||
        createMode === 'culture-profilesGroup'
      ) {
        let value = dataVal === 'flagedApiCall' ? true : false;
        let keyFlagVal = 'cultureProfileGroup';
        let flagVal = 'cultureProfileGroupFlag';
        updateCultureProfileFlagUpdate(
          selectedAssociateInfo,
          selectedTagValue,
          dispatch,
          value,
          keyFlagVal,
          flagVal
        );
        dispatch({ type: POPUP_CLOSE });
      }
      if (
        typeOfMiddlePaneList === 'cultureProfilesTypeDistinctReviewList' ||
        createMode === 'culture-profilesType'
      ) {
        let value = dataVal === 'flagedApiCall' ? true : false;
        let keyFlagVal = 'cultureProfileType';
        let flagVal = 'cultureProfileTypeFlag';
        updateCultureProfileFlagUpdate(
          selectedAssociateInfo,
          selectedTagValue,
          dispatch,
          value,
          keyFlagVal,
          flagVal
        );
        dispatch({ type: POPUP_CLOSE });
      }
      if (typeOfMiddlePaneList === 'jobProfilesDistinctReviewList' || createMode === 'jobProfile') {
        let value = dataVal === 'flagedApiCall' ? true : false;
        let keyFlagVal = 'jobProfile';
        let flagVal = 'jobProfileFlag';
        updateJobProfileFlagUpdate(
          selectedAssociateInfo,
          selectedTagValue,
          dispatch,
          value,
          keyFlagVal,
          flagVal,
          createMode
        );
        dispatch({ type: POPUP_CLOSE });
      }
      if (
        typeOfMiddlePaneList === 'jobProfilesGroupDistinctReviewList' ||
        createMode === 'jobProfilesGroup' ||
        createMode === 'job-ProfilesGroup'
      ) {
        let value = dataVal === 'flagedApiCall' ? true : false;
        let keyFlagVal = 'jobProfileGroup';
        let flagVal = 'jobProfileGroupFlag';
        updateJobProfileFlagUpdate(
          selectedAssociateInfo,
          selectedTagValue,
          dispatch,
          value,
          keyFlagVal,
          flagVal
        );
        dispatch({ type: POPUP_CLOSE });
      }
      if (
        typeOfMiddlePaneList === 'jobProfilesTypeDistinctReviewList' ||
        createMode === 'job-profilesType'
      ) {
        let value = dataVal === 'flagedApiCall' ? true : false;
        let keyFlagVal = 'jobProfileType';
        let flagVal = 'jobProfileTypeFlag';
        updateJobProfileFlagUpdate(
          selectedAssociateInfo,
          selectedTagValue,
          dispatch,
          value,
          keyFlagVal,
          flagVal
        );
        dispatch({ type: POPUP_CLOSE });
      }
      if (typeOfMiddlePaneList === 'assessmentDistinctReviewList' || createMode === 'assessment') {
        let value = dataVal === 'flagedApiCall' ? true : false;
        let keyFlagVal = 'assessment';
        let flagVal = 'assessmentFlag';
        updateAssessmentFlagUpdate(
          selectedAssociateInfo,
          selectedTagValue,
          dispatch,
          value,
          keyFlagVal,
          flagVal,
          createMode,
          secondaryOptionCheckValue
        );
        dispatch({ type: POPUP_CLOSE });
      }
      if (
        typeOfMiddlePaneList === 'assessmentsGroupDistinctReviewList' ||
        createMode === 'assessmentsGroup'
      ) {
        let value = dataVal === 'flagedApiCall' ? true : false;
        let keyFlagVal = 'assessmentGroup';
        let flagVal = 'assessmentGroupFlag';
        updateAssessmentFlagUpdate(
          selectedAssociateInfo,
          selectedTagValue,
          dispatch,
          value,
          keyFlagVal,
          flagVal,
          createMode,
          secondaryOptionCheckValue
        );
        dispatch({ type: POPUP_CLOSE });
      }
      if (
        typeOfMiddlePaneList === 'assessmentsTypeDistinctReviewList' ||
        createMode === 'assessmentsType'
      ) {
        let value = dataVal === 'flagedApiCall' ? true : false;
        let keyFlagVal = 'assessmentType';
        let flagVal = 'assessmentTypeFlag';
        updateAssessmentFlagUpdate(
          selectedAssociateInfo,
          selectedTagValue,
          dispatch,
          value,
          keyFlagVal,
          flagVal,
          createMode,
          secondaryOptionCheckValue
        );
        dispatch({ type: POPUP_CLOSE });
      }
    } else if (
      dataVal === 'suspendApiCall' ||
      dataVal === 'terminateApiCall' ||
      dataVal === 'unsuspendApiCall' ||
      dataVal === 'unarchiveApiCall' ||
      dataVal === 'publishApiCall' ||
      dataVal === 'unpublishApiCall' ||
      dataVal === 'archiveApiCall' ||
      dataVal === 'yesApiCall' ||
      dataVal === 'approveApiCall' ||
      dataVal === 'unapproveApiCall' ||
      dataVal === 'unterminateApiCall'
    ) {
      if (
        typeOfMiddlePaneList === 'assesseesDistinctReviewList' ||
        typeOfMiddlePaneList === 'assesseesSelfReview' ||
        typeOfMiddlePaneList === 'administratorsDistinctReviewList' ||
        typeOfMiddlePaneList === 'assistantsDistinctReviewList' ||
        typeOfMiddlePaneList === 'managersDistinctReviewList' ||
        typeOfMiddlePaneList === 'assesseesGroupAssesseeReviewList' ||
        typeOfMiddlePaneList === 'assesseesRoleAssesseeReviewList' ||
        typeOfMiddlePaneList === 'assesseesNodeAssesseeReviewList' ||
        typeOfMiddlePaneList === 'assignmentDistinctAssesseeReviewList' ||
        typeOfMiddlePaneList === 'assesseesTypeAssesseeReviewList' ||
        createMode === 'assessee'
      ) {
        dispatch({ type: LOADER_START });
        updateAssesseeDistinctStatus(
          selectedAssociateInfo,
          selectedTagValue,
          dispatch,
          keyVal,
          createMode,
          typeOfMiddlePaneList,
          cachedPreviousSelectedTagValue,
          middlePaneHeaderBadgeTwo
        );
      }

      if (typeOfMiddlePaneList === 'gaugeDistinctReviewList' || createMode === 'gauge') {
        dispatch({ type: LOADER_START });
        UpdateGaugeReviseStatus(
          selectedAssociateInfo,
          [selectedTagValue],
          dispatch,
          keyVal,
          typeOfMiddlePaneList,
          createMode
        );
      }

      if (
        typeOfMiddlePaneList === 'assesseesGroupDistinctReviewList' ||
        createMode === 'assesseesGroup'
      ) {
        dispatch({ type: LOADER_START });
        let selectedType = 'assesseeGroup';
        // if (keyVal === 'UNSUSPENDED' || keyVal === 'UNTERMINATED' || keyVal === 'UNARCHIVED') {
        //          keyVal = 'ACTIVE';
        //        }
        updateReviseStatus(
          selectedAssociateInfo,
          [selectedTagValue],
          dispatch,
          selectedType,
          keyVal,
          typeOfMiddlePaneList,
          createMode
        );
        // updateAssesseeGroupStatus(selectedAssociateInfo, selectedTagValue, dispatch, keyVal);
      }

      if (
        typeOfMiddlePaneList === 'assesseeDistinctAssignmentDistinctReviewList'
        //|| createMode === 'assesseesGroup'
      ) {
        updateReviseAssesseeAssignmentStatus(
          selectedAssociateInfo,
          [selectedTagValue],
          dispatch,
          keyVal,
          typeOfMiddlePaneList,
          relatedReviewListDistinctData,
          middlePaneHeaderBadgeTwo
        );
      }

      // if (typeOfMiddlePaneList === 'assesseeRoleDistinctReviewList') {
      //   dispatch({ type: LOADER_START });
      //   updateAssesseeRoleStatus(selectedAssociateInfo, selectedTagValue, dispatch, keyVal);
      // }
      if (
        typeOfMiddlePaneList === 'assesseeRoleDistinctReviewList' ||
        createMode === 'assesseesRole'
      ) {
        // if (keyVal === 'UNSUSPENDED' || keyVal === 'UNTERMINATED' || keyVal === 'UNARCHIVED') {
        //          keyVal = 'ACTIVE';
        //        }
        dispatch({ type: LOADER_START });
        if (createMode === 'assesseesRole') {
          let assesseeRoleRevise = {
            ...assesseeRole,
            informationEngagement: {
              assesseeRoleStatus: keyVal
            }
          };
          assesseeRollReviseApiCall(
            responseObject,
            selectedAssociateInfo,
            [],
            [],
            assesseeRoleRevise,
            dispatch,
            createMode
          );
        } else {
          updateReviseStatus(
            selectedAssociateInfo,
            [selectedTagValue],
            dispatch,
            'assesseeRole',
            keyVal,
            typeOfMiddlePaneList,
            createMode
          );
        }
      }

      if (
        typeOfMiddlePaneList === 'assesseesTypeDistinctReviewList' ||
        createMode === 'assesseesType'
      ) {
        let selectedType = 'assesseeType';
        dispatch({ type: LOADER_START });
        // if (keyVal === 'UNSUSPENDED' || keyVal === 'UNTERMINATED' || keyVal === 'UNARCHIVED') {
        //          keyVal = 'ACTIVE';
        //        }
        updateReviseStatus(
          selectedAssociateInfo,
          [selectedTagValue],
          dispatch,
          selectedType,
          keyVal,
          typeOfMiddlePaneList,
          createMode
        );
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
      }
      if (
        typeOfMiddlePaneList === 'assessmentDistinctReviewList' ||
        typeOfMiddlePaneList === 'assessmentTypeAssessmentReviewList' ||
        typeOfMiddlePaneList === 'assessmentNodeAssessmentReviewList' ||
        typeOfMiddlePaneList === 'jobProfilepAssessmentReviewList' ||
        typeOfMiddlePaneList === 'cultureProfileAssessmentReviewList' ||
        createMode === 'assessment'
      ) {
        let selectedType = 'assessmentDistinct';
        dispatch({ type: LOADER_START });
        if (keyVal === 'PUBLISHED') {
          updateAssessmentDistinctStatus(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            keyVal,
            createMode,
            secondaryOptionCheckValue
          );
        } else {
          updateReviseStatus(
            selectedAssociateInfo,
            [selectedTagValue],
            dispatch,
            selectedType,
            keyVal,
            typeOfMiddlePaneList,
            createMode
          );
        }
      }

      // code added before push "assessmentGroupAssessmentReviewList"
      if (
        typeOfMiddlePaneList === 'assessmentsGroupDistinctReviewList' ||
        createMode === 'assessmentsGroup'
      ) {
        dispatch({ type: LOADER_START });
        let selectedType;
        // selectedType = 'assessmentGroup'
        typeOfMiddlePaneList === 'assessmentGroupAssessmentReviewList'
          ? (selectedType = 'assessmentDistinct')
          : (selectedType = 'assessmentGroup');
        // if (
        //   (keyVal === 'UNSUSPENDED' || keyVal === 'UNTERMINATED' || keyVal === 'UNARCHIVED') &&
        //   typeOfMiddlePaneList === 'assessmentsGroupDistinctReviewList'
        // ) {
        //   keyVal = 'ACTIVE';
        // }
        updateReviseStatus(
          selectedAssociateInfo,
          [selectedTagValue],
          dispatch,
          selectedType,
          keyVal,
          typeOfMiddlePaneList,
          createMode
        );
      }
      if (
        typeOfMiddlePaneList === 'assessmentsTypeDistinctReviewList' ||
        createMode === 'assessmentsType'
      ) {
        dispatch({ type: LOADER_START });
        // if (keyVal === 'UNSUSPENDED' || keyVal === 'UNTERMINATED' || keyVal === 'UNARCHIVED') {
        //          keyVal = 'ACTIVE';
        //        }
        let selectedType = 'assessmentType';
        updateReviseStatus(
          selectedAssociateInfo,
          [selectedTagValue],
          dispatch,
          selectedType,
          keyVal,
          typeOfMiddlePaneList,
          createMode
        );
        //updateAssessmentTypeStatus(selectedAssociateInfo, selectedTagValue, dispatch, keyVal);
      }
      if (
        typeOfMiddlePaneList === 'assignmentDistinctReviewList' ||
        typeOfMiddlePaneList === 'assignmentTypeAssignmentReviewList' ||
        typeOfMiddlePaneList === 'assignmentNodeAssignmentReviewList' ||
        typeOfMiddlePaneList === 'assignmentGroupAssignmentReviewList' ||
        typeOfMiddlePaneList === 'assesseeGroupAssesseeAssignmentDistinct' ||
        createMode === 'assignment'
      ) {
        if (keyVal !== 'UNADMINISTER') {
          updateAssignmentDistinctStatus(
            selectedAssociateInfo,
            selectedTagValue,
            dispatch,
            keyVal,
            createMode,
            secondaryOptionCheckValue,
            typeOfMiddlePaneList
          );
          dispatch({ type: SET_PAGE_COUNT, payload: 0 });
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'relatedReviewListDistinctData', value: [] }
          });
        }
      }
      if (
        typeOfMiddlePaneList === 'assignmentsGroupDistinctReviewList' ||
        createMode === 'assignmentsGroup'
      ) {
        dispatch({ type: LOADER_START });
        //let selectedType = 'assignmentGroup';
        let selectedType;
        typeOfMiddlePaneList === 'assignmentGroupAssignmentReviewList'
          ? (selectedType = 'assignmentDistinct')
          : (selectedType = 'assignmentGroup');
        // if (
        //   (keyVal === 'UNSUSPENDED' || keyVal === 'UNTERMINATED' || keyVal === 'UNARCHIVED') &&
        //   typeOfMiddlePaneList === 'assignmentsGroupDistinctReviewList'
        // ) {
        //   keyVal = 'ACTIVE';
        // }
        updateReviseStatus(
          selectedAssociateInfo,
          [selectedTagValue],
          dispatch,
          selectedType,
          keyVal,
          typeOfMiddlePaneList,
          createMode
        );
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
        // updateAssignmentGroupStatus(selectedAssociateInfo, selectedTagValue, dispatch, keyVal);
      }
      if (
        typeOfMiddlePaneList === 'assignmentsTypeDistinctReviewList' ||
        createMode === 'assignmentsType'
      ) {
        dispatch({ type: LOADER_START });
        // if (keyVal === 'UNSUSPENDED' || keyVal === 'UNTERMINATED' || keyVal === 'UNARCHIVED') {
        //          keyVal = 'ACTIVE';
        //        }
        let selectedType = 'assignmentType';
        updateReviseStatus(
          selectedAssociateInfo,
          [selectedTagValue],
          dispatch,
          selectedType,
          keyVal,
          typeOfMiddlePaneList,
          createMode
        );
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
        //updateAssignmentTypeStatus(selectedAssociateInfo, selectedTagValue, dispatch, keyVal);
      }
      if (
        typeOfMiddlePaneList === 'associateDistinctReviewList' ||
        typeOfMiddlePaneList === 'associatesNodeDistinctReviewList' ||
        typeOfMiddlePaneList === 'associatesRoleAssociateReviewList' ||
        typeOfMiddlePaneList === 'associatesGroupAssociateReviewList' ||
        typeOfMiddlePaneList === 'associateSelfReview' ||
        typeOfMiddlePaneList === 'nodeAssociatesReviewList' ||
        typeOfMiddlePaneList === 'associatesTypeAssociateReviewList' ||
        createMode === 'associate'
      ) {
        dispatch({ type: LOADER_START });
        updateAssociateDistinctStatus(
          selectedAssociateInfo,
          [selectedTagValue],
          dispatch,
          keyVal,
          createMode,
          typeOfMiddlePaneList
        );
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
      }
      if (
        typeOfMiddlePaneList === 'associatesGroupDistinctReviewList' ||
        createMode === 'associatesGroup'
      ) {
        dispatch({ type: LOADER_START });
        let selectedType = 'associateGroup';
        // if (keyVal === 'UNSUSPENDED' || keyVal === 'UNTERMINATED' || keyVal === 'UNARCHIVED') {
        //          keyVal = 'ACTIVE';
        //        }
        updateReviseStatus(
          selectedAssociateInfo,
          [selectedTagValue],
          dispatch,
          selectedType,
          keyVal,
          typeOfMiddlePaneList,
          createMode
        );
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
        // updateAssociateGroupStatus(selectedAssociateInfo, selectedTagValue, dispatch, keyVal);
      }
      if (
        typeOfMiddlePaneList === 'associatesTypeDistinctReviewList' ||
        createMode === 'associatesType'
      ) {
        dispatch({ type: LOADER_START });
        // if (keyVal === 'UNSUSPENDED' || keyVal === 'UNTERMINATED' || keyVal === 'UNARCHIVED') {
        //          keyVal = 'ACTIVE';
        //        }
        let selectedType = 'associateType';
        updateReviseStatus(
          selectedAssociateInfo,
          [selectedTagValue],
          dispatch,
          selectedType,
          keyVal,
          typeOfMiddlePaneList,
          createMode
        );
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
        //updateAssociateTypeStatus(selectedAssociateInfo, selectedTagValue, dispatch, keyVal);
      }
      if (
        typeOfMiddlePaneList === 'associateRoleDistinctReviewList' ||
        createMode === 'associatesRole'
      ) {
        dispatch({ type: LOADER_START });
        let selectedType = 'associateRole';
        // if (keyVal === 'UNSUSPENDED' || keyVal === 'UNTERMINATED' || keyVal === 'UNARCHIVED') {
        //          keyVal = 'ACTIVE';
        //        }
        updateReviseStatus(
          selectedAssociateInfo,
          [selectedTagValue],
          dispatch,
          selectedType,
          keyVal,
          typeOfMiddlePaneList,
          createMode
        );
      }
      if (
        typeOfMiddlePaneList === 'associateNodeDistinctReviewList' ||
        createMode === 'associatesNode'
      ) {
        // if (keyVal === 'UNSUSPENDED' || keyVal === 'UNTERMINATED' || keyVal === 'UNARCHIVED') {
        //          keyVal = 'ACTIVE';
        //        }
        updateAssociateNodeStatus(
          selectedAssociateInfo,
          [selectedTagValue],
          dispatch,
          keyVal,
          createMode
        );
      }
      if (
        typeOfMiddlePaneList === 'itemsDistinctReviewList' ||
        typeOfMiddlePaneList === 'itemTypeItemReviewList' ||
        typeOfMiddlePaneList === 'itemGroupItemReviewList' ||
        typeOfMiddlePaneList === 'itemNodeItemReviewList' ||
        typeOfMiddlePaneList === 'assessmentItemReviewList' ||
        createMode === 'item'
      ) {
        dispatch({ type: LOADER_START });
        let selectedType = 'itemDistinct';
        updateReviseStatus(
          selectedAssociateInfo,
          [selectedTagValue],
          dispatch,
          selectedType,
          keyVal,
          typeOfMiddlePaneList,
          createMode
        );
        // updateItemDistinctStatus(selectedAssociateInfo, selectedTagValue, dispatch, keyVal)
      }
      if (typeOfMiddlePaneList === 'itemsGroupDistinctReviewList' || createMode === 'itemsGroup') {
        dispatch({ type: LOADER_START });
        let selectedType = 'itemGroup';
        // if (keyVal === 'UNSUSPENDED' || keyVal === 'UNTERMINATED' || keyVal === 'UNARCHIVED') {
        //          keyVal = 'ACTIVE';
        //        }
        updateReviseStatus(
          selectedAssociateInfo,
          [selectedTagValue],
          dispatch,
          selectedType,
          keyVal,
          typeOfMiddlePaneList,
          createMode
        );
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
      }
      if (typeOfMiddlePaneList === 'itemsTypeDistinctReviewList' || createMode === 'itemsType') {
        // let selectedType = 'itemType';
        dispatch({ type: LOADER_START });
        // if (keyVal === 'UNSUSPENDED' || keyVal === 'UNTERMINATED' || keyVal === 'UNARCHIVED') {
        //          keyVal = 'ACTIVE';
        //        }
        updateReviseStatus(
          selectedAssociateInfo,
          [selectedTagValue],
          dispatch,
          'itemType',
          keyVal,
          typeOfMiddlePaneList,
          createMode
        );
      }
      if (
        typeOfMiddlePaneList === 'cultureProfilesDistinctReviewList' ||
        typeOfMiddlePaneList === 'cultureProfileGroupCultureProfileReviewList' ||
        typeOfMiddlePaneList === 'cultureProfileTypeCultureProfileReviewList' ||
        typeOfMiddlePaneList === 'cultureProfileNodeCultureProfileReviewList' ||
        typeOfMiddlePaneList === 'assignmentDistinctCultureProfileReviewList' ||
        createMode === 'cultureProfile'
      ) {
        updateCultureProfileDistinctStatus(
          selectedAssociateInfo,
          selectedTagValue,
          dispatch,
          keyVal,
          createMode,
          typeOfMiddlePaneList
        );
      }
      if (
        typeOfMiddlePaneList === 'cultureProfilesGroupDistinctReviewList' ||
        typeOfMiddlePaneList === 'cultureProfileGroupCultureProfileReviewList' ||
        createMode === 'cultureProfilesGroup' ||
        createMode === 'culture-profilesGroup'
      ) {
        dispatch({ type: LOADER_START });
        //let selectedType = 'cultureProfileGroup';
        let selectedType;
        typeOfMiddlePaneList === 'cultureProfileGroupCultureProfileReviewList'
          ? (selectedType = 'cultureProfileDistinct')
          : (selectedType = 'cultureProfileGroup');
        // if (
        //   (keyVal === 'UNSUSPENDED' || keyVal === 'UNTERMINATED' || keyVal === 'UNARCHIVED') &&
        //   typeOfMiddlePaneList === 'cultureProfilesGroupDistinctReviewList'
        // ) {
        //   keyVal = 'ACTIVE';
        // }
        updateReviseStatus(
          selectedAssociateInfo,
          [selectedTagValue],
          dispatch,
          selectedType,
          keyVal,
          typeOfMiddlePaneList,
          createMode
        );
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
        //updateCultureProfileGroupStatus(selectedAssociateInfo, selectedTagValue, dispatch, keyVal);
      }
      if (
        typeOfMiddlePaneList === 'cultureProfilesTypeDistinctReviewList' ||
        createMode === 'culture-profilesType'
      ) {
        let selectedType = 'cultureProfileType';
        dispatch({ type: LOADER_START });
        // if (keyVal === 'UNSUSPENDED' || keyVal === 'UNTERMINATED' || keyVal === 'UNARCHIVED') {
        //          keyVal = 'ACTIVE';
        //        }
        updateReviseStatus(
          selectedAssociateInfo,
          [selectedTagValue],
          dispatch,
          selectedType,
          keyVal,
          typeOfMiddlePaneList,
          createMode
        );
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
        //updateCultureProfileTypeStatus(selectedAssociateInfo, selectedTagValue, dispatch, keyVal);
      }
      if (
        typeOfMiddlePaneList === 'jobProfilesDistinctReviewList' ||
        typeOfMiddlePaneList === 'jobProfileTypeJobProfileReviewList' ||
        typeOfMiddlePaneList === 'jobProfileGroupJobProfileReviewList' ||
        typeOfMiddlePaneList === 'assignmentDistinctJobProfileReviewList' ||
        typeOfMiddlePaneList === 'jobProfileNodeJobProfileReviewList' ||
        createMode === 'jobProfile'
      ) {
        let selectedType = 'jobProfileDistinct';
        dispatch({ type: LOADER_START });
        updateJobProfileDistinctStatus(
          selectedAssociateInfo,
          selectedTagValue,
          dispatch,
          keyVal,
          createMode,
          typeOfMiddlePaneList
        );
        /**
        updateReviseStatus(
          selectedAssociateInfo,
          [selectedTagValue],
          dispatch,
          selectedType,
          keyVal,
          typeOfMiddlePaneList,
          createMode
        );
         */
      }
      if (
        typeOfMiddlePaneList === 'jobProfilesGroupDistinctReviewList' ||
        typeOfMiddlePaneList === 'jobProfileGroupJobProfileReviewList' ||
        createMode === 'job-profilesGroup' ||
        createMode === 'jobProfilesGroup'
      ) {
        dispatch({ type: LOADER_START });
        // let selectedType = 'jobProfileGroup';
        let selectedType;
        typeOfMiddlePaneList === 'jobProfileGroupJobProfileReviewList'
          ? (selectedType = 'jobProfileDistinct')
          : (selectedType = 'jobProfileGroup');
        // if (
        //   (keyVal === 'UNSUSPENDED' || keyVal === 'UNTERMINATED' || keyVal === 'UNARCHIVED') &&
        //   typeOfMiddlePaneList === 'jobProfilesGroupDistinctReviewList'
        // ) {
        //   keyVal = 'ACTIVE';
        // }
        updateReviseStatus(
          selectedAssociateInfo,
          [selectedTagValue],
          dispatch,
          selectedType,
          keyVal,
          typeOfMiddlePaneList,
          createMode
        );
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
      }
      if (
        typeOfMiddlePaneList === 'jobProfilesTypeDistinctReviewList' ||
        createMode === 'job-profilesType'
      ) {
        updateReviseStatus(
          selectedAssociateInfo,
          [selectedTagValue],
          dispatch,
          'jobProfileType',
          //keyVal,
          // keyVal === 'UNSUSPENDED' || keyVal === 'UNTERMINATED' || keyVal === 'UNARCHIVED'
          //   ? (keyVal = 'ACTIVE')
          keyVal,
          typeOfMiddlePaneList,
          createMode
        );
        dispatch({ type: SET_PAGE_COUNT, payload: 0 });
      }
      if (typeOfMiddlePaneList === 'assesseeAssignmentDistinctReviewList') {
        console.log('selectedTagValue', selectedTagValue);
        dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
        let assessmentList = reviewListDistinctData.filter((data) => {
          return data.assesseeAssignmentId === selectedTagValue;
        });
        assessmentList[0].assesseeAssignmentAssessmentDistinct?.sort((a, b) =>
          a.assesseeAssignmentAssessmentName.localeCompare(b.assesseeAssignmentAssessmentName)
        );
        dispatch({
          type: RELATED_REVIEWLIST_DISTINCT_DATA,
          payload: assessmentList
        });
        let reqBody = {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          assignmentId: selectedTagValue,
          assignmentStartTime: new Date().getTime()
        };
        dispatch({ type: LOADER_START });
        dispatch({
          type: FILTERMODE,
          payload: { FilterMode: 'assesseeAssignmentAssessmentDistinctall' }
        });
        dispatch({
          type: ASSESSEE_ASSESSMENT_START_SAGA,
          payload: {
            request: reqBody,
            isAssignmentStart: true,
            assessmentList,
            selectedTagValue,
            popupHeaderOneBadgeTwo
          }
        });
      }
      if (typeOfMiddlePaneList === 'assesseesAssginmentAssessmentReviewList') {
        console.log('relatedReviewListDistinctData', relatedReviewListDistinctData);
        let assessmentStatus =
          relatedReviewListDistinctData[0].assesseeAssignmentAssessmentDistinct.filter(
            (assessment) => {
              if (assessment.assesseeAssignmentAssessmentId === selectedTagValue)
                return assessment.assesseeAssignmentAssessmentStatus;
            }
          );
        console.log('assessmentStatusassessmentStatus', assessmentStatus);
        let reqBody = {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          assignmentId: relatedReviewListDistinctData[0].assesseeAssignmentId,
          assessmentId: selectedTagValue,
          assessmentStartTime: new Date().getTime()
        };
        dispatch({ type: LOADER_START });
        dispatch({
          type: ASSESSMENT_START_SAGA,
          payload: {
            request: reqBody,
            assesseeAssignmentAssessmentItemAttemptedLast:
              assessmentStatus[0].assesseeAssignmentAssessmentItemAttemptedLast
          }
        });
      }
      if (typeOfMiddlePaneList === 'acutalAssessmentStart') {
        let currentSeq = '';
        let assesseeAssignmentAssessmentItemAttemptedLast =
          assesseeAssignmentAssessmentData?.assesseeAssignmentAssessmentItemAttemptedLast || '';
        let isPresent = assesseeAssignmentAssessmentItemAttemptedLast.indexOf(':') !== -1;
        let currentValue = -1;
        if (isPresent) {
          let arr = assesseeAssignmentAssessmentItemAttemptedLast.split(':');
          currentValue = arr[1];
          currentSeq = arr[0];
        }
        if (popupHeaderOneBadgeTwo === 'resume') {
          //assesseeAssignmentAssessmentData?.assesseeAssignmentAssessmentItemLastAttempted;
          // let isPresent = assesseeAssignmentAssessmentItemAttemptedLast.indexOf(':') !== -1;
          // if (isPresent) {
          //   let arr = assesseeAssignmentAssessmentItemAttemptedLast.split(':');
          //   let currentValue = arr[1];
          dispatch({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: {
              stateName: 'questionIndex',
              value: parseInt(currentValue) + 1
            }
          });

          let sec = null;
          if (assesseeAssessmentStartData?.assessmentSectionTime > 0) {
            sec = assesseeAssessmentStartData?.assessmentSectionTime / 1000;
          } else {
            sec = assesseeAssignmentAssessmentData?.informationFramework?.assessmentTime / 1000;
          }

          dispatch({ type: 'START_COUNTDOWN', payload: sec });
          // }
        }
        if (popupHeaderOneBadgeTwo === 'start') {
          dispatch({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: {
              stateName: 'questionIndex',
              value: parseInt(currentValue) + 1
            }
          });

          let sec = null;
          if (assesseeAssessmentStartData?.assessmentSectionTime > 0) {
            sec = assesseeAssessmentStartData?.assessmentSectionTime / 1000;
          } else {
            sec = assesseeAssignmentAssessmentData?.informationFramework?.assessmentTime / 1000;
          }

          dispatch({ type: 'START_COUNTDOWN', payload: sec });
        }
        // else {
        dispatch({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: {
            stateName: 'assesseeAssessmentStartData',
            value:
              assesseeAssignmentAssessmentData?.informationFramework?.assessmentSectionFramework[
              selectedTagValue
              ]
            //value: assesseeAssignmentAssessmentData?.informationFramework?.assessmentSection[selectedTagValue]
          }
        });
        // }

        dispatch({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: { stateName: 'isAssessmentStart', value: 'PROGRESS' }
        });
        let assessmentStartTime = new Date().getTime();
        dispatch({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: {
            stateName: 'assesseeAssignmentAssessmentSectionAttemptedDateTimeStart',
            value: assessmentStartTime
          }
        });
        let reqBody = {
          assesseeId: selectedAssociateInfo?.assesseeId,
          associateId:
            selectedAssociateInfo?.associate?.informationEngagement.associateTag
              .associateTagPrimary,
          assignmentId: assesseeAssignmentAssessmentData.assignmentId,
          assessmentId: assesseeAssignmentAssessmentData.assessmentId,
          assessmentStartTime: assessmentStartTime
        };
        dispatch({ type: LOADER_START });
        dispatch({ type: ASSESSEE_ASSESSMENT_START_SAGA, payload: { request: reqBody } });
        dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneSeven' });
        dispatch({
          type: SET_POPUP_SINGLE_STATE,
          payload: { stateName: 'headerButtons', value: true }
        });
      }
      if (typeOfMiddlePaneList === 'assessmentgradesReviewList') {
        if (keyVal === 'DELETED') {
          let id = relatedReviewListDistinctData[0].id;
          const reqBody = {
            module: 'Assessment-Distinct',
            action: 'Revise',
            actionFilter: 'Section-Cluster-Scale',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              assessmentId: id,
              removeAssessmentScoreGradeFramework: true,
              removeAssessmentScoreGrades: [selectedTagValue]
            }
          };
          dispatch({ type: LOADER_START });
          dispatch({
            type: ASSESSMENT_CLUSTER_REVISE_SAGA,
            payload: {
              secondaryOptionCheckValue: '',
              headerOne: 'assessment',
              reqBody,
              hideRightpane: true,
              assessmentSector: 'assessmentclustersReviewList'
            }
          });
          dispatch({ type: POPUP_CLOSE });
        }
      }
      if (typeOfMiddlePaneList === 'assessmentclustersReviewList') {
        if (keyVal === 'DELETED') {
          let seleTag = parseInt(selectedTagValue);
          let id = relatedReviewListDistinctData[0].id;
          const reqBody = {
            module: 'Assessment-Distinct',
            action: 'Revise',
            actionFilter: 'Section-Cluster-Scale',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              assessmentId: id,
              removeAssessmentClusters: [selectedTagValue],
              removeAssessmentClusterFramework: true
            }
          };
          dispatch({ type: LOADER_START });
          dispatch({
            type: ASSESSMENT_CLUSTER_REVISE_SAGA,
            payload: {
              secondaryOptionCheckValue: '',
              headerOne: 'assessment',
              reqBody,
              hideRightpane: true,
              assessmentSector: 'assessmentclustersReviewList'
            }
          });
          dispatch({ type: POPUP_CLOSE });
        }
      }
      if (typeOfMiddlePaneList === 'assessmentsectionsReviewList') {
        if (keyVal === 'DELETED') {
          let seleTag = parseInt(selectedTagValue);
          let sectionObj = assessmentInfo.informationFramework.assessmentSectionFramework;
          let filteredArr = sectionObj.filter(function (number, index) {
            return seleTag !== index;
          });
          let id = relatedReviewListDistinctData[0].id;
          const reqBody = {
            module: 'Assessment-Distinct',
            action: 'Revise',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              assessment: {
                id,
                informationBasic: assessmentInfo.informationBasic,
                informationAllocation: assessmentInfo.informationAllocation,
                informationSetup: assessmentInfo.informationSetup,
                informationFramework: {
                  assessmentSectionFramework: filteredArr
                }
              }
            }
          };
          dispatch({ type: LOADER_START });
          dispatch({
            type: ASSESSMENT_INFO_REVISE_SAGA,
            payload: {
              secondaryOptionCheckValue: '',
              headerOne: 'assessment',
              reqBody,
              hideRightpane: true,
              assessmentSector: 'assessmentsectionsReviewList'
            }
          });
          dispatch({ type: POPUP_CLOSE });
        }
      }
      if (typeOfMiddlePaneList === 'assessmentversionsReviewList') {
        if (keyVal === 'DELETED') {
          let seleTag = parseInt(selectedTagValue);
          let sectionObj = assessmentInfo?.informationFramework?.assessmentSectionFramework || [];
          let newSectionObj = [];
          sectionObj.forEach((sec) => {
            let versionObj = sec?.assessmentVersionFramework || [];
            let filteredArr = versionObj.filter(function (number, index) {
              return seleTag !== index;
            });
            newSectionObj.push({ ...sec, assessmentVersionFramework: filteredArr });
          });
          // let versionObj = sectionObj[0]?.assessmentVersionFramework || [];
          // let filteredArr = versionObj.filter(function (number, index) {
          //   return seleTag !== index;
          // });
          // sectionObj[0].assessmentVersionFramework = filteredArr;
          let id = relatedReviewListDistinctData[0].id;
          const reqBody = {
            module: 'Assessment-Distinct',
            action: 'Revise',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              assessment: {
                id,
                informationBasic: assessmentInfo.informationBasic,
                informationAllocation: assessmentInfo.informationAllocation,
                informationSetup: assessmentInfo.informationSetup,
                informationFramework: {
                  assessmentSectionFramework: newSectionObj
                }
              }
            }
          };
          dispatch({ type: LOADER_START });
          dispatch({
            type: ASSESSMENT_INFO_REVISE_SAGA,
            payload: {
              secondaryOptionCheckValue: '',
              headerOne: 'assessment',
              reqBody,
              hideRightpane: true,
              assessmentSector: 'assessmentversionsReviewList'
            }
          });
          dispatch({ type: POPUP_CLOSE });
        }
      }
      if (typeOfMiddlePaneList === 'assessmentscalesReviewList') {
        if (keyVal === 'DELETED') {
          let scaleObj = assessmentInfo?.informationFramework?.assessmentScaleFramework || [];
          let filteredArr = scaleObj.filter(function (number, index) {
            return selectedTagValue !== number.id;
          });
          let id = relatedReviewListDistinctData[0].id;
          const reqBody = {
            module: 'Assessment-Distinct',
            action: 'Revise',
            actionFilter: 'Section-Cluster-Scale',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              assessmentId: id,
              removeAssessmentScales: [selectedTagValue],
              removeAssessmentScaleFramework: true
              // assessment: {
              //   id,
              //   informationFramework: {
              //     assessmentScaleFramework: filteredArr
              //   }
              // }
            }
          };
          dispatch({ type: LOADER_START });
          dispatch({
            type: ASSESSMENT_CLUSTER_REVISE_SAGA,
            payload: {
              secondaryOptionCheckValue: '',
              headerOne: 'assessment',
              reqBody,
              hideRightpane: true,
              assessmentSector: 'assessmentscalesReviewList'
            }
          });
          dispatch({ type: POPUP_CLOSE });
        }
      }
      if (typeOfMiddlePaneList === 'assessmentnormsReviewList') {
        if (keyVal === 'DELETED') {
          let seleTag = parseInt(selectedTagValue);
          let id = relatedReviewListDistinctData[0].id;
          const reqBody = {
            module: 'Assessment-Distinct',
            action: 'Revise',
            actionFilter: 'Section-Cluster-Scale',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              assessmentId: id,
              removeAssessmentNorms: [selectedTagValue],
              removeAssessmentNormFramework: true
            }
          };
          dispatch({ type: LOADER_START });
          dispatch({
            type: ASSESSMENT_CLUSTER_REVISE_SAGA,
            payload: {
              secondaryOptionCheckValue: '',
              headerOne: 'assessment',
              reqBody,
              hideRightpane: true,
              assessmentSector: 'assessmentnormsReviewList'
            }
          });
          dispatch({ type: POPUP_CLOSE });
        }
      }
      if (typeOfMiddlePaneList === 'assessmentDistinctSubScaleDistinct') {
        if (keyVal === 'DELETED') {
          let seleTag = parseInt(selectedTagValue);
          let sclaleId = relatedReviewListDistinctData[0].subscale.id;
          console.log('relatedReviewListDistinctData', relatedReviewListDistinctData);
          let scaleObj = JSON.parse(
            JSON.stringify(assessmentInfo?.informationFramework?.assessmentScaleFramework)
          );
          console.log('scaleObj', scaleObj);
          scaleObj.map((sss) => {
            // delete sss.assessmentId || sss;
            console.log(sss);
            if (sclaleId === sss.id) {
              sss.assessmentScaleMetric.splice(seleTag);
            }
            delete sss?.assessmentId;
          });
          let id = relatedReviewListDistinctData[0].id;
          const reqBody = {
            module: 'Assessment-Distinct',
            action: 'Revise',
            actionFilter: 'Section-Cluster-Scale',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              assessmentId: id,
              assessmentScaleFramework: scaleObj[0]
              // assessment: {
              //   id,
              //   informationFramework: {
              //     assessmentScaleFramework: scaleObj
              //   }
              // }
            }
          };
          console.log('reqBody', reqBody);
          dispatch({ type: LOADER_START });
          dispatch({
            type: ASSESSMENT_CLUSTER_REVISE_SAGA,
            payload: {
              headerOne: 'assessment',
              reqBody,
              assessmentSector: 'assessmentscalesReviewList',
              selectedSector: parseInt(selectedTagValue),
              typeOfMiddlePaneList: typeOfMiddlePaneList
            }
          });
          dispatch({ type: POPUP_CLOSE });
        }
      }
      // if (typeOfMiddlePaneList === 'assignmentDistinctAssesseeReviewList') {
      //   dispatch({
      //     type: GET_ASSESSEE_REPORT_SAGA,
      //     payload: {
      //       righpaneheaderOne: 'analytics',
      //       request: {
      //         module: 'Assessee-Assignment',
      //         action: 'Result',
      //         requestObject: {
      //           action: 'revise',
      //           assesseeId: selectedAssociateInfo?.assesseeId,
      //           associateId:
      //             selectedAssociateInfo?.associate?.informationEngagement.associateTag
      //               .associateTagPrimary,
      //           assesseeAssignmentDistinct: {
      //             assesseeId: responseObject?.assessee?.id,
      //             id: responseObject?.assesseeAssignment?.id,
      //             assesseeAssignmentStatusReport: keyVal
      //           }
      //         }
      //       }
      //     }
      //   });
      // }
      if (typeOfMiddlePaneList !== 'assignmentDistinctAssesseeReviewList') {
        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
      }
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'middlePaneSelectedValue', value: selectedTagValue }
      });
      dispatch({ type: POPUP_CLOSE });
    } else if (dataVal === 'resetYesApiCall') {
      if (typeOfMiddlePaneList === 'assesseeDistinctAssignmentDistinctReviewList') {
        //reset assessee...assignment
        let assignmentAssesseeId = relatedReviewListDistinctData[0].assesseeId;
        let selectedAssesseeInfo = relatedReviewListDistinctData[0].assesseeInfo;
        assesseeAssignmentResetApiCall(
          selectedAssociateInfo,
          selectedTagValue,
          dispatch,
          assignmentAssesseeId,
          selectedAssesseeInfo,
          typeOfMiddlePaneList,
          middlePaneHeaderBadgeTwo
        );
      }
      if (
        typeOfMiddlePaneList === 'assignmentDistinctAssesseeReviewList' &&
        popupHeaderOneBadgeTwo === 'resend'
      ) {
        // console.log(selectedAssociateInfo, selectedTagValue,)
        let reqPayload = {
          module: 'Assignment-Distinct',
          action: 'resend',
          requestObject: {
            associateId:
              selectedAssociateInfo?.associate?.informationEngagement.associateTag
                .associateTagPrimary,
            resendAssesseeId: selectedTagValue,
            assignmentId: cachedPreviousSelectedTagValue
          }
        };
        dispatch({ type: LOADER_START });
        dispatch({ type: RESEND_ASSIGNMENT_NOTIFICATION, payload: reqPayload });
      } else if (typeOfMiddlePaneList === 'assignmentDistinctAssesseeReviewList') {
        //reset assessee...assignment
        let assignmentAssesseeId = selectedTagValue;
        let assignmentId = relatedReviewListDistinctData[0].id;
        assignmentAssesseeResetApiCall(
          selectedAssociateInfo,
          assignmentId,
          dispatch,
          assignmentAssesseeId,
          typeOfMiddlePaneList,
          relatedReviewListObjectCopy
        );
      }
      if (typeOfMiddlePaneList === 'assignmentDistinctReviewList') {
        //reset assignment
        assignmentResetApiCall(selectedAssociateInfo, selectedTagValue, dispatch);
      }
      if (typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList') {
        //reset assignment assessment
        let assesseeAssignmentId = relatedReviewListDistinctData[0].id;
        assignmentAssessmentResetApiCall(
          selectedAssociateInfo,
          assesseeAssignmentId,
          selectedTagValue,
          dispatch
        );
      }
      if (
        typeOfMiddlePaneList === 'assesseeAssignmentAssessmentReviewList' ||
        typeOfMiddlePaneList === 'assesseeAssignmentAssessmentReviewList'
      ) {
        console.log('relatedReviewListDistinctData', relatedReviewListDistinctData);
        //reset assessee assignment assessment
        let assignmentAssesseeId = relatedReviewListDistinctData[0].assesseeId;
        let assesseeAssignmentId =
          relatedReviewListDistinctData[0].assesseeAssignmentInfo?.assesseeAssignmentId;
        let selectedAssesseeInfo = relatedReviewListDistinctData[0].assesseeInfo;
        let assesseeAssignmentAssessmentId = selectedTagValue;
        assesseeAssignmentAssessmentResetApiCall(
          selectedAssociateInfo,
          assesseeAssignmentId,
          dispatch,
          assesseeAssignmentAssessmentId,
          assignmentAssesseeId,
          selectedAssesseeInfo
        );
      }
      dispatch({ type: POPUP_CLOSE });

      if (typeOfMiddlePaneList === 'assignmentAssesseeAssessmentDistinctReviewList') {
        //reset assessee assignment assessment
        let assignmentAssesseeId =
          relatedReviewListDistinctData[0]?.assignmentAssesseeInfo?.assesseeIdTwo;
        let assesseeAssignmentId = relatedReviewListDistinctData[0]?.id;
        let selectedAssesseeInfo = relatedReviewListDistinctData[0].assignmentAssesseeInfo;
        let assesseeAssignmentAssessmentId = selectedTagValue;
        assignemtAsseseeAssessmentResetApiCall(
          selectedAssociateInfo,
          assesseeAssignmentId,
          dispatch,
          assesseeAssignmentAssessmentId,
          assignmentAssesseeId,
          selectedAssesseeInfo,
          middlePaneHeaderBadgeTwo
        );
      }
      dispatch({ type: POPUP_CLOSE });
    } else if (dataVal === 'administerApiCall') {
      dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
      updateAssignmentAdminister(selectedAssociateInfo, selectedTagValue, dispatch, keyVal);
      dispatch({ type: POPUP_CLOSE });
    } else if (dataVal === 'activation' && popupHeaderOneBadgeTwo === 'reset') {
      dispatch({ type: POPUP_CLOSE });
    } else if (dataVal === 'password' && popupHeaderOneBadgeTwo === 'reset') {
      dispatch({ type: POPUP_CLOSE });
      dispatch({ type: LOADER_START });
      dispatch({
        type: RESET_ASSESSEE_PASSWORD,
        payload: {
          module: 'Assessee-Distinct',
          action: 'resetPassword',
          requestObject: {
            associateId: `${selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary}`,
            assesseeId: `${selectedAssociateInfo?.assesseeId}`,
            resetAssesseeId: `${selectedTagValue}`
          }
        }
      });
    } else if (
      dataVal === 'information' &&
      popupHeaderOneBadgeTwo === 'create' &&
      middlePaneHeader === 'associate'
    ) {
      dispatch({ type: POPUP_CLOSE });
      let requestObj = makeInternalNodeObj(selectedAssociateInfo, 'active', 0, countPage);
      dispatch({ type: CLEAR_NODE_REDUCER_STATE });
      dispatch({ type: SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT, payload: requestObj });
      dispatch({
        type: INTERNAL_NODE_LIST_SAGA,
        payload: { request: requestObj, nodeViewState: 'list', isMiddlePaneList: false }
      });
      dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
      dispatch({
        type: SET_NODE_DYNAMIC_SINGLE_STATE,
        payload: {
          objectName: 'informationFramework',
          stateName: 'associateNodeAscendant',
          actualStateName: 'associateNodeAscendantPrimary',
          value: [selectedTagValue]
        }
      });
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'NODECREATE' }
      });
    } else if (
      dataVal === 'information' &&
      popupHeaderOneBadgeTwo === 'create' &&
      middlePaneHeader === 'associates'
    ) {
      dispatch({ type: POPUP_CLOSE });
      dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
      associateCreatePopup(
        selectedAssociateInfo,
        dispatch,
        secondaryOptionCheckValue,
        dataVal,
        selectedTagValue
      );
    } else if (
      dataVal === 'information' &&
      popupHeaderOneBadgeTwo === 'create' &&
      popupHeaderOneBadgeOne === 'sections'
    ) {
      dispatch({ type: CLEAR_SECTION_REDUCER_STATE });
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'SECTIONCREATE' }
      });
    } else if (
      dataVal === 'information' &&
      popupHeaderOneBadgeTwo === 'create' &&
      popupHeaderOneBadgeOne === 'clusters'
    ) {
      dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
      dispatch({ type: CLEAR_CLUSTER_REDUCER_STATE });
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'CLUSTERCREATE' }
      });
    } else if (
      dataVal === 'information' &&
      popupHeaderOneBadgeTwo === 'create' &&
      popupHeaderOneBadgeOne === 'scales'
    ) {
      dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
      dispatch({ type: CLEAR_SCALE_REDUCER_STATE });
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'SCALECREATE' }
      });
    } else if (
      dataVal === 'information' &&
      popupHeaderOneBadgeTwo === 'create' &&
      popupHeaderOneBadgeOne === 'versions'
    ) {
      dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
      dispatch({ type: CLEAR_VERSION_REDUCER_STATE });
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'VERSIONCREATE' }
      });
    } else if (
      dataVal === 'information' &&
      popupHeaderOneBadgeTwo === 'create' &&
      popupHeaderOneBadgeOne === 'norms'
    ) {
      dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
      dispatch({ type: CLEAR_NORM_REDUCER_STATE });
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'NORMCREATE' }
      });
    } else if (
      dataVal === 'information' &&
      popupHeaderOneBadgeTwo === 'create' &&
      popupHeaderOneBadgeOne === 'scores'
    ) {
      dispatch({ type: POPUP_CLOSE });
    } else if (
      dataVal === 'information' &&
      popupHeaderOneBadgeTwo === 'create' &&
      popupHeaderOneBadgeOne === 'grades'
    ) {
      dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
      dispatch({ type: CLEAR_GRADE_REDUCER_STATE });
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'NAMEPOPUP', popupMode: 'GRADECREATE' }
      });
    } else if (
      dataVal === 'information' &&
      popupHeaderOneBadgeTwo === 'create' &&
      popupHeaderOneBadgeOne === 'metrics'
    ) {
      if (typeOfMiddlePaneList === 'assessmentscalesReviewList') {
        dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
        dispatch({ type: CLEAR_SCALE_REDUCER_STATE });
        let responseObj = relatedReviewListDistinctData[0].scales.filter((dd) => {
          return dd.id === selectedTagValue;
        });
        dispatch({
          type: SET_SCALE_REDUCER_STATE,
          payload: responseObj[0]
        });

        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'LABELPOPUP', popupMode: 'SCALECREATE' }
        });
      }
      if (typeOfMiddlePaneList === 'gaugeDistinctReviewList' || createMode === 'gauge') {
        console.log('createMode', createMode);
        // dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
        dispatch({ type: CLEAR_GUAGE_REDUCER_STATE });
        let responseObj = reviewListDistinctData.filter((dd) => {
          return dd.id === selectedTagValue;
        });
        dispatch({
          type: SET_GUAGE_REDUCER_STATE,
          payload: responseObj[0]
        });
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'LABELPOPUP', popupMode: 'GUAGEMETRICCREATE' }
        });
        dispatch({
          type: SET_DISPLAY_THREE_SINGLE_STATE,
          payload: { stateName: 'reviewMode', value: 'core' }
        });
      }
      if (createMode === 'gauge') {
        console.log('createMode', createMode);
        // dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
        // dispatch({ type: CLEAR_GUAGE_REDUCER_STATE });
        let responseObj = reviewListDistinctData.filter((dd) => {
          return dd.id === selectedTagValue;
        });
        dispatch({
          type: SET_GUAGE_REDUCER_STATE,
          payload: responseObject
        });
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'LABELPOPUP', popupMode: 'GUAGEMETRICCREATE' }
        });
        dispatch({
          type: SET_DISPLAY_THREE_SINGLE_STATE,
          payload: { stateName: 'reviewMode', value: 'core' }
        });
      }
    } else if (dataVal === 'allocate' || dataVal === 'allocateApi') {
      console.log(
        createMode,
        middlePaneHeader,
        secondaryOptionCheckValue,
        tertiaryOptionCheckValue,
        nodeViewState,
        '###############'
      );
      if (
        middlePaneHeader === 'assessments' ||
        createMode === 'assessment' ||
        createMode === 'assessmentsType' ||
        createMode === 'assessmentsGroup'
      ) {
        if (
          secondaryOptionCheckValue === 'assessments' &&
          tertiaryOptionCheckValue === 'distinct' &&
          middlePaneHeaderBadgeOne !== 'distinct' //
        ) {
          if (paneOneSelectedSection === 'paneoneSection3') {
            getMarketplaceAssessmentTypeApiCall(
              selectedAssociateInfo,
              'all',
              countPage,
              dispatch,
              'distinct',
              cardValue,
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
            );
          }
          else {
            getAssessmentDistinctApiCall(
              selectedAssociateInfo,
              'all',
              countPage,
              dispatch,
              'distinct',
              cardValue,
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
            )
          };
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'allocateToAssessment';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assessments' &&
          tertiaryOptionCheckValue === 'groups'
        ) {
          getAssessmentGroupAllocateApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'groups',
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            "list",
            [],
            assignmentCreateType,
            [selectedTagValue]
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'assessmentAllocateToGroup';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assessments' &&
          tertiaryOptionCheckValue === 'types'
        ) {
          getAssessmentTypeAllocateApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'types',
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            [selectedTagValue]
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'assessmentAllocateToType';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assessments' &&
          tertiaryOptionCheckValue === 'nodes'
        ) {
          getInternalNodeApiCall(
            selectedAssociateInfo,
            secondaryOptionCheckValue,
            countPage,
            dispatch,
            'nodes',
            '',
            'list',
            secondaryOptionCheckValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'assessmentAllocateToNode';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assignments' &&
          tertiaryOptionCheckValue == 'distinct'
        ) {
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          assignmentsDistinctApiCall(
            selectedAssociateInfo,
            'unpublished',
            countPage,
            dispatch,
            'distinct',
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            assignmentCreateType
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'allocateToAssignment';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assignments' &&
          tertiaryOptionCheckValue == 'groups'
        ) {
          assignmentsGroupAllocateApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'groups',
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            "list",
            [],
            assignmentCreateType,
            [selectedTagValue]
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'assignmentAllocateToGroup';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'items' &&
          tertiaryOptionCheckValue === 'distinct'
        ) {
          getItemsDistinctApiCall(
            selectedAssociateInfo,
            // 'all',
            'published',
            countPage,
            'distinct',
            dispatch,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'allocateToItem';
          dispatch({ type: POPUP_CLOSE });
        } else if (secondaryOptionCheckValue === 'items' && tertiaryOptionCheckValue === 'groups') {
          getItemGroupDistinctApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'groups',
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            'list',
            [],
            false,
            assignmentCreateType
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'itemAllocateToGroup';
          dispatch({ type: POPUP_CLOSE });
        } else if (dataVal === 'allocate') {
          // debugger;
          dispatch({
            type: SET_MIDDLEPANE_SECONDARY_OPTION,
            payload: {
              badgeValue: dataVal,
              keyValue: keyVal,
              middlePaneHeaderBadgeOne,
              middlePaneHeaderBadgeTwo,
              isPopUpFrom: 'reviewList'
            }
          });
        }
      } else if (
        middlePaneHeader === 'items' ||
        createMode === 'item' ||
        createMode === 'itemsType' ||
        createMode === 'itemsGroup'
      ) {
        if (
          secondaryOptionCheckValue === 'items' &&
          // popupHeaderOneBadgeTwo !== 'allocate' && //
          middlePaneHeaderBadgeOne !== 'distinct' &&
          tertiaryOptionCheckValue === 'distinct'
        ) {
          getItemsDistinctApiCall(
            selectedAssociateInfo,
            '', // 'all',
            countPage,
            'distinct',
            dispatch,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'allocateToItem';
          dispatch({ type: POPUP_CLOSE });
        } else if (secondaryOptionCheckValue === 'items' && tertiaryOptionCheckValue === 'groups') {
          getItemGroupDistinctAllocateApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'groups',
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            'list',
            [],
            false,
            assignmentCreateType,
            [selectedTagValue]
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'itemAllocateToGroup';
          dispatch({ type: POPUP_CLOSE });
        } else if (secondaryOptionCheckValue === 'items' && tertiaryOptionCheckValue === 'nodes') {
          getInternalNodeApiCall(
            selectedAssociateInfo,
            secondaryOptionCheckValue,
            countPage,
            dispatch,
            'nodes',
            '',
            'list',
            secondaryOptionCheckValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'itemAllocateToNode';
          dispatch({ type: POPUP_CLOSE });
        } else if (secondaryOptionCheckValue === 'items' && tertiaryOptionCheckValue === 'types') {
          getItemsTypeAllocateApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'types',
            middlePaneHeader,
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            [selectedTagValue]
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'itemAllocateToType';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assessments' &&
          tertiaryOptionCheckValue === 'distinct'
        ) {
          getAssessmentDistinctApiCall(
            selectedAssociateInfo,
            'unpublished',
            countPage,
            dispatch,
            'distinct',
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'itemAllocateToAssessment';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assessments' &&
          tertiaryOptionCheckValue === 'groups'
        ) {
          getAssessmentGroupApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'groups',
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            "list",
            [],
            assignmentCreateType
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'assessmentAllocateToGroup';
          dispatch({ type: POPUP_CLOSE });
        } else if (dataVal === 'allocate') {
          // debugger;
          dispatch({
            type: SET_MIDDLEPANE_SECONDARY_OPTION,
            payload: { badgeValue: dataVal, keyValue: keyVal, isPopUpFrom: 'reviewList' }
          });
        }
      } else if (
        middlePaneHeader === 'assignments' ||
        createMode === 'assignment' ||
        createMode === 'assignmentsType' ||
        createMode === 'assignmentsGroup'
      ) {
        if (
          secondaryOptionCheckValue === 'assignments' &&
          // popupHeaderOneBadgeTwo !== 'allocate' &&
          middlePaneHeaderBadgeOne !== 'distinct' &&
          tertiaryOptionCheckValue == 'distinct'
        ) {
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          assignmentsDistinctApiCall(
            selectedAssociateInfo,
            '',
            countPage,
            dispatch,
            'distinct',
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            assignmentCreateType
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'allocateToAssignment';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assignments' &&
          tertiaryOptionCheckValue == 'groups'
        ) {
          assignmentsGroupAllocateApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'groups',
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            "list",
            [],
            assignmentCreateType,
            [selectedTagValue]
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'assignmentAllocateToGroup';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assignments' &&
          tertiaryOptionCheckValue == 'types'
        ) {
          assignmentTypeAllocateApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'types',
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            [selectedTagValue]
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'assignmentAllocateToType';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assignments' &&
          tertiaryOptionCheckValue == 'nodes'
        ) {
          getInternalNodeApiCall(
            selectedAssociateInfo,
            secondaryOptionCheckValue,
            countPage,
            dispatch,
            'nodes',
            '',
            'list',
            secondaryOptionCheckValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'assignmentAllocateToNode';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assessees' &&
          tertiaryOptionCheckValue == 'distinct'
        ) {
          getAssesseeDistinctApiCall(
            selectedAssociateInfo,
            'activeinactive',
            countPage,
            dispatch,
            'distinct',
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'allocateToAssessee';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assessees' &&
          tertiaryOptionCheckValue == 'groups'
        ) {
          getAssesseeGroupDistinctApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'groups',
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            "list",
            [],
            assignmentCreateType
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'assesseeAllocateToGroup';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assessments' &&
          tertiaryOptionCheckValue === 'distinct'
        ) {
          getAssessmentDistinctApiCall(
            selectedAssociateInfo,
            'published',
            countPage,
            dispatch,
            'distinct',
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'allocateToAssessment';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assessments' &&
          tertiaryOptionCheckValue === 'groups'
        ) {
          getAssessmentGroupApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'groups',
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            "list",
            [],
            assignmentCreateType
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'assessmentAllocateToGroup';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'culture-profiles' &&
          tertiaryOptionCheckValue === 'distinct'
        ) {
          getCultureProfilesDistinctApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            '',
            dispatch,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'allocateToCultureProfile';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'culture-profiles' &&
          tertiaryOptionCheckValue == 'groups'
        ) {
          // debugger
          getCultureProfileGroupApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'groups',
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            [],
            'list',
            false,
            ""
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'allocateToCultureProfileGroup';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'job-profiles' &&
          tertiaryOptionCheckValue == 'distinct'
        ) {
          getJobProfilesDistinctApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            '',
            dispatch,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'allocateToJobProfile';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'job-profiles' &&
          tertiaryOptionCheckValue == 'groups'
        ) {
          getJobProfileGroupApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'groups',
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            [],
            'list',
            false,
            // assignmentCreateType
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'jobProfileAllocateToGroup';
          dispatch({ type: POPUP_CLOSE });
        } else if (dataVal === 'allocate') {
          // debugger;
          dispatch({
            type: SET_MIDDLEPANE_SECONDARY_OPTION,
            payload: {
              badgeValue: dataVal,
              keyValue: keyVal,
              assignmentCreateType,
              isPopUpFrom: 'reviewList',
              middlePaneHeaderBadgeOne,
              middlePaneHeaderBadgeTwo
            }
          });
        }
      } else if (
        middlePaneHeader === 'assessees' ||
        middlePaneHeader === 'administrators' ||
        createMode === 'assessee' ||
        createMode === 'assesseesType' ||
        createMode === 'assesseesRole' ||
        createMode === 'assesseesGroup'
      ) {
        if (
          secondaryOptionCheckValue === 'assessees' &&
          // popupHeaderOneBadgeTwo !== 'allocate' &&
          popupHeaderOneBadgeTwo == 'allocate' &&
          tertiaryOptionCheckValue == 'distinct'
        ) {
          getAssesseeDistinctApiCall(
            selectedAssociateInfo,
            '',
            countPage,
            dispatch,
            'distinct',
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'allocateToAssessee';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assessees' &&
          tertiaryOptionCheckValue == 'groups'
        ) {
          getAssesseeGroupAllocateDistinctApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'groups',
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            "list",
            [],
            assignmentCreateType,
            [selectedTagValue]
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'assesseeAllocateToGroup';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assessees' &&
          tertiaryOptionCheckValue == 'types'
        ) {
          getAssesseeAllocateTypeApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'types',
            cardValue,
            'assessees',
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            [selectedTagValue]
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'assesseeAllocateToType';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assessees' &&
          tertiaryOptionCheckValue == 'nodes'
        ) {
          // getInternalNodeAllocateApiCall(
          //   selectedAssociateInfo,
          //   secondaryOptionCheckValue,
          //   countPage,
          //   dispatch,
          //   'nodes',
          //   '',
          //   'list',
          //   secondaryOptionCheckValue,
          //   popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
          //   [],
          //   '',
          //   FilterMode,
          //   [selectedTagValue]
          // );
          getInternalNodeApiCall(
            selectedAssociateInfo,
            secondaryOptionCheckValue,
            countPage,
            dispatch,
            'nodes',
            '',
            'list',
            secondaryOptionCheckValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'assesseeAllocateToNode';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assessees' &&
          tertiaryOptionCheckValue == 'roles'
        ) {
          getAssesseeRoleAllocateDistinctApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            'roles',
            dispatch,
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            [selectedTagValue]
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'assesseeAllocateToRole';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assignments' &&
          tertiaryOptionCheckValue == 'distinct'
        ) {
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          assignmentsDistinctApiCall(
            selectedAssociateInfo,
            'activeinactive',
            countPage,
            dispatch,
            'distinct',
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            assignmentCreateType
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'allocateToAssignment';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assignments' &&
          tertiaryOptionCheckValue == 'groups'
        ) {
          assignmentsGroupAllocateApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'groups',
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            "list",
            [],
            assignmentCreateType,
            [selectedTagValue]
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'assignmentAllocateToGroup';
          dispatch({ type: POPUP_CLOSE });
        } else if (dataVal === 'allocate') {
          // debugger;
          dispatch({
            type: SET_MIDDLEPANE_SECONDARY_OPTION,
            payload: { badgeValue: dataVal, keyValue: keyVal, isPopUpFrom: 'reviewList' }
          });
        }
      } else if (
        middlePaneHeader === 'associates' ||
        middlePaneHeader === 'associate' ||
        createMode === 'associate' ||
        createMode === 'associatesType' ||
        createMode === 'associatesRole' ||
        createMode === 'associatesNode' ||
        createMode === 'associatesGroup'
      ) {
        if (
          secondaryOptionCheckValue === 'associates' &&
          // popupHeaderOneBadgeTwo !== 'allocate' &&
          middlePaneHeaderBadgeOne !== 'distinct' &&
          tertiaryOptionCheckValue == 'distinct'
        ) {
          getAssociateDistinctApiCall(
            selectedAssociateInfo,
            '',
            dispatch,
            countPage,
            'distinct',
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            'Card'
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'associatesAllocateToDistinct';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'associates' &&
          tertiaryOptionCheckValue == 'groups'
        ) {
          getAssociateGroupAllocateDistinctApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'groups',
            '',
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            "list",
            [],
            assignmentCreateType,
            [selectedTagValue]
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'associateAllocateToGroup';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'associates' &&
          tertiaryOptionCheckValue == 'nodes'
        ) {
          getInternalNodeApiCall(
            selectedAssociateInfo,
            secondaryOptionCheckValue,
            countPage,
            dispatch,
            'nodes',
            '',
            'list',
            secondaryOptionCheckValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'associatesAllocateToNode';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'associates' &&
          tertiaryOptionCheckValue == 'roles'
        ) {
          getAssociateRoleAllocateDistinctApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            cardValue,
            'roles',
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            [selectedTagValue]
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'associatesAllocateToRole';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'associates' &&
          tertiaryOptionCheckValue == 'types'
        ) {
          getAssociatesAllocateTypeApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'types',
            'associates',
            '',
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            [selectedTagValue]
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'associatesAllocateToType';
          dispatch({ type: POPUP_CLOSE });
        } else if (dataVal === 'allocate') {
          // debugger;
          dispatch({
            type: SET_MIDDLEPANE_SECONDARY_OPTION,
            payload: { badgeValue: dataVal, keyValue: keyVal, isPopUpFrom: 'reviewList' }
          });
        }
      } else if (
        middlePaneHeader === 'culture-profiles' ||
        popupHeaderOne === 'culture-profile' ||
        createMode === 'cultureprofile' ||
        createMode === 'cultureProfilesType' ||
        createMode === 'culture-profilesType' ||
        createMode === 'culture-profilesGroup' ||
        createMode === 'culture-profilesGroup'
      ) {
        if (
          secondaryOptionCheckValue === 'culture-profiles' &&
          // popupHeaderOneBadgeTwo !== 'allocate' &&
          middlePaneHeaderBadgeOne !== 'distinct' &&
          tertiaryOptionCheckValue === 'distinct'
        ) {
          getCultureProfilesDistinctApiCall(
            selectedAssociateInfo,
            'all',
            countPage,
            '',
            dispatch,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'allocateToCultureProfile';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'culture-profiles' &&
          tertiaryOptionCheckValue === 'groups'
        ) {
          getCultureProfileAllocateGroupApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'groups',
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            [],
            'list',
            false,
            assignmentCreateType,
            [selectedTagValue]
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'allocateToCultureProfileGroup';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'culture-profiles' &&
          tertiaryOptionCheckValue === 'nodes'
        ) {
          getInternalNodeApiCall(
            selectedAssociateInfo,
            secondaryOptionCheckValue,
            countPage,
            dispatch,
            'nodes',
            '',
            'list',
            secondaryOptionCheckValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'cultureProfileAllocateToNode';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'culture-profiles' &&
          tertiaryOptionCheckValue === 'types'
        ) {
          getCultureProfileAllocateTypeApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'types',
            secondaryOptionCheckValue,
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            [],
            true,
            [selectedTagValue]
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'allocateToCultureProfileType';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assignments' &&
          tertiaryOptionCheckValue === 'distinct'
        ) {
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          assignmentsDistinctApiCall(
            selectedAssociateInfo,
            'unpublished',
            countPage,
            dispatch,
            'distinct',
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            'iGuru Analytics_Occupation'
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: {
              stateName: 'assignmentCreateType',
              value: 'iGuru Analytics_Occupation'
            }
          });
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'allocateToAssignment';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assignments' &&
          tertiaryOptionCheckValue == 'groups'
        ) {
          assignmentsGroupAllocateApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'groups',
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            "list",
            [],
            assignmentCreateType,
            [selectedTagValue]
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'assignmentAllocateToGroup';
          dispatch({ type: POPUP_CLOSE });
        } else if (dataVal === 'allocate') {
          // debugger;
          dispatch({
            type: SET_MIDDLEPANE_SECONDARY_OPTION,
            payload: {
              badgeValue: dataVal,
              keyValue: keyVal,
              isPopUpFrom: 'reviewList',
              middlePaneHeaderBadgeOne,
              middlePaneHeaderBadgeTwo
            }
          });
        }
      } else if (
        middlePaneHeader === 'job-profiles' ||
        popupHeaderOne === 'job-profile' ||
        createMode === 'jobprofile' ||
        createMode === 'jobProfilesType' ||
        createMode === 'job-profilesType' ||
        createMode === 'job-ProfilesGroup' ||
        createMode === 'jobProfilesGroup'
      ) {
        if (
          secondaryOptionCheckValue === 'job-profiles' &&
          // popupHeaderOneBadgeTwo !== 'allocate' &&
          middlePaneHeaderBadgeOne !== 'distinct' &&
          tertiaryOptionCheckValue === 'distinct'
        ) {
          getJobProfilesDistinctApiCall(
            selectedAssociateInfo,
            '',
            countPage,
            '',
            dispatch,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'allocateToJobProfile';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'job-profiles' &&
          tertiaryOptionCheckValue == 'groups'
        ) {
          getJobProfileGroupAllocateApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'groups',
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            [],
            'list',
            false,
            assignmentCreateType,
            [selectedTagValue]
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'jobProfileAllocateToGroup';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'job-profiles' &&
          tertiaryOptionCheckValue == 'nodes'
        ) {
          getInternalNodeApiCall(
            selectedAssociateInfo,
            secondaryOptionCheckValue,
            countPage,
            dispatch,
            'nodes',
            '',
            'list',
            secondaryOptionCheckValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'jobProfileAllocateToNode';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'job-profiles' &&
          tertiaryOptionCheckValue == 'types'
        ) {
          getJobProfileTypeApiAllcoateCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'types',
            secondaryOptionCheckValue,
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            [],
            false,
            [selectedTagValue]
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'jobProfileAllocateToType';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assignments' &&
          tertiaryOptionCheckValue == 'distinct'
        ) {
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          assignmentsDistinctApiCall(
            selectedAssociateInfo,
            'unpublished',
            countPage,
            dispatch,
            'distinct',
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            'iGuru Analytics_Occupation'
          );
          dispatch({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: {
              stateName: 'assignmentCreateType',
              value: 'iGuru Analytics_Occupation'
            }
          });
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'allocateToAssignment';
          dispatch({ type: POPUP_CLOSE });
        } else if (
          secondaryOptionCheckValue === 'assignments' &&
          tertiaryOptionCheckValue == 'groups'
        ) {
          assignmentsGroupAllocateApiCall(
            selectedAssociateInfo,
            'active',
            countPage,
            dispatch,
            'groups',
            cardValue,
            popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
            "list",
            [],
            assignmentCreateType,
            [selectedTagValue]
          );
          isValidAllocationOrUnallocation = true;
          filterModeKey = 'assignmentAllocateToGroup';
          dispatch({ type: POPUP_CLOSE });
        } else if (dataVal === 'allocate') {
          // debugger;
          dispatch({
            type: SET_MIDDLEPANE_SECONDARY_OPTION,
            payload: { badgeValue: dataVal, keyValue: keyVal, isPopUpFrom: 'reviewList' }
          });
        }
      } else if (dataVal !== 'allocateApi') {

        dispatch({
          type: SET_MIDDLEPANE_SECONDARY_OPTION,
          payload: { badgeValue: dataVal, keyValue: keyVal, isPopUpFrom: 'reviewList' }
        });
      }
    } else if (dataVal === 'unallocate' || dataVal === 'unallocateApi') {
      console.log(
        middlePaneHeader,
        '*',
        secondaryOptionCheckValue,
        '*',
        tertiaryOptionCheckValue,
        '*',
        popupHeaderOne,
        '*',
        popupHeaderOneBadgeOne,
        middlePaneHeaderBadgeOne,
        '*',
        middlePaneHeaderBadgeTwo
      );
      if (
        (middlePaneHeader === 'assessees' || middlePaneHeader === 'administrators' || popupHeaderOne === 'assessee') &&
        secondaryOptionCheckValue === 'assessees'
      ) {
        switch (tertiaryOptionCheckValue) {
          case 'distinct': {
            getAssesseeDistinctApiCall(
              selectedAssociateInfo,
              '',
              countPage,
              dispatch,
              'distinct',
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
            );
            filterModeKey = 'unAllocateToAssessee';
            dispatch({ type: POPUP_CLOSE });
            isValidAllocationOrUnallocation = true;
            break;
          }
          case 'groups': {
            getAssesseeGroupAllocateDistinctApiCall(
              selectedAssociateInfo,
              'active',
              countPage,
              dispatch,
              'groups',
              cardValue,
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
              "list",
              [],
              assignmentCreateType,
              [selectedTagValue]
            );
            filterModeKey = 'assesseeUnAllocateToGroup';
            dispatch({ type: POPUP_CLOSE });
            isValidAllocationOrUnallocation = true;
            break;
          }
          case 'nodes': {
            getInternalNodeApiCall(
              selectedAssociateInfo,
              secondaryOptionCheckValue,
              countPage,
              dispatch,
              'nodes',
              '',
              'list',
              secondaryOptionCheckValue,
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
            );
            filterModeKey = 'assesseeUnAllocateToNode';
            dispatch({ type: POPUP_CLOSE });
            isValidAllocationOrUnallocation = true;
            break;
          }
          case 'roles': {
            getAssesseeRoleAllocateDistinctApiCall(
              selectedAssociateInfo,
              'active',
              countPage,
              'roles',
              dispatch,
              cardValue,
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
              [selectedTagValue]
            );
            filterModeKey = 'assesseeUnAllocateToRole';
            dispatch({ type: POPUP_CLOSE });
            isValidAllocationOrUnallocation = true;
            break;
          }
          case 'types': {
            getAssesseeAllocateTypeApiCall(
              selectedAssociateInfo,
              'active',
              countPage,
              dispatch,
              'types',
              cardValue,
              'assessees',
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
              [selectedTagValue]
            );
            filterModeKey = 'assesseeUnAllocateToType';
            dispatch({ type: POPUP_CLOSE });
            isValidAllocationOrUnallocation = true;
            break;
          }
        }
      } else if (
        (middlePaneHeader === 'assessees' || middlePaneHeader === 'administrators' || popupHeaderOne === 'assessee') &&
        secondaryOptionCheckValue === 'assignments'
      ) {
        switch (tertiaryOptionCheckValue) {
          case 'groups': {
            assignmentsGroupAllocateApiCall(
              selectedAssociateInfo,
              'active',
              countPage,
              dispatch,
              'groups',
              cardValue,
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
              "list",
              [],
              assignmentCreateType,
              [selectedTagValue]
            );
            isValidAllocationOrUnallocation = true;
            filterModeKey = 'assignmentUnAllocateToGroup';
            dispatch({ type: POPUP_CLOSE });
            break;
          }
          case 'distinct': {
            dispatch({
              type: SET_DISPLAY_TWO_SINGLE_STATE,
              payload: { stateName: 'typeOfMiddlePaneList', value: '' }
            });
            assignmentsDistinctApiCall(
              selectedAssociateInfo,
              'activeinactive',
              countPage,
              dispatch,
              'distinct',
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
              assignmentCreateType
            );
            filterModeKey = 'unAllocateToAssignment';
            dispatch({ type: POPUP_CLOSE });
            isValidAllocationOrUnallocation = true;
            break;
          }
        }
      } else if (
        (middlePaneHeader === 'assessments' || popupHeaderOne === 'assessment') &&
        secondaryOptionCheckValue === 'assessments'
      ) {
        switch (tertiaryOptionCheckValue) {
          case 'distinct': {
            if (middlePaneHeaderBadgeOne !== 'distinct') {
              getAssessmentDistinctApiCall(
                selectedAssociateInfo,
                '',
                countPage,
                dispatch,
                'distinct',
                cardValue,
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
              );
              filterModeKey = 'unAllocateToAssessment';
              dispatch({ type: POPUP_CLOSE });
            }
            isValidAllocationOrUnallocation = true;
            break;
          }
          case 'groups': {
            getAssessmentGroupAllocateApiCall(
              selectedAssociateInfo,
              'active',
              countPage,
              dispatch,
              'groups',
              cardValue,
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
              "list",
              [],
              assignmentCreateType,
              [selectedTagValue]
            );
            filterModeKey = 'assessmentUnAllocateToGroup';
            dispatch({ type: POPUP_CLOSE });
            isValidAllocationOrUnallocation = true;
            break;
          }
          case 'nodes': {
            getInternalNodeApiCall(
              selectedAssociateInfo,
              secondaryOptionCheckValue,
              countPage,
              dispatch,
              'nodes',
              '',
              'list',
              secondaryOptionCheckValue,
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
            );
            filterModeKey = 'assessmentUnAllocateToNode';
            dispatch({ type: POPUP_CLOSE });
            isValidAllocationOrUnallocation = true;
            break;
          }
          case 'types': {
            getAssessmentTypeAllocateApiCall(
              selectedAssociateInfo,
              'active',
              countPage,
              dispatch,
              'types',
              cardValue,
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
              [selectedTagValue]
            );
            filterModeKey = 'assessmentUnAllocateToType';
            dispatch({ type: POPUP_CLOSE });
            isValidAllocationOrUnallocation = true;
            break;
          }
        }
      } else if (
        (middlePaneHeader === 'assessments' || popupHeaderOne === 'assessment') &&
        secondaryOptionCheckValue === 'assignments'
      ) {
        switch (tertiaryOptionCheckValue) {
          case 'distinct': {
            dispatch({
              type: SET_DISPLAY_TWO_SINGLE_STATE,
              payload: { stateName: 'typeOfMiddlePaneList', value: '' }
            });

            assignmentsDistinctApiCall(
              selectedAssociateInfo,
              'unpublished',
              countPage,
              dispatch,
              'distinct',
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
              assignmentCreateType
            );
            filterModeKey = 'unAllocateToAssignment';
            dispatch({ type: POPUP_CLOSE });
            isValidAllocationOrUnallocation = true;
            break;
          }
          case 'groups': {
            assignmentsGroupAllocateApiCall(
              selectedAssociateInfo,
              'active',
              countPage,
              dispatch,
              'groups',
              cardValue,
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
              "list",
              [],
              assignmentCreateType,
              [selectedTagValue]
            );
            filterModeKey = 'assignmentUnAllocateToGroup';
            dispatch({ type: POPUP_CLOSE });
            isValidAllocationOrUnallocation = true;
            break;
          }
        }
      } else if (
        (middlePaneHeader === 'assessments' || popupHeaderOne === 'assessment') &&
        secondaryOptionCheckValue === 'items'
      ) {
        switch (tertiaryOptionCheckValue) {
          case 'distinct': {
            getItemsDistinctApiCall(
              selectedAssociateInfo,
              // 'all',
              'published',
              countPage,
              'distinct',
              dispatch,
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
            );
            filterModeKey = 'unAllocateToItem';
            dispatch({ type: POPUP_CLOSE });
            isValidAllocationOrUnallocation = true;
            break;
          }
          case 'groups': {
            getItemGroupDistinctApiCall(
              selectedAssociateInfo,
              'active',
              countPage,
              dispatch,
              'groups',
              cardValue,
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
              'list',
              [],
              false,
              assignmentCreateType
            );
            filterModeKey = 'itemUnAllocateToGroup';
            dispatch({ type: POPUP_CLOSE });
            isValidAllocationOrUnallocation = true;
            break;
          }
        }
      } else if (
        (middlePaneHeader === 'assignments' || popupHeaderOne === 'assignment') &&
        secondaryOptionCheckValue === 'assignments'
      ) {
        switch (tertiaryOptionCheckValue) {
          case 'distinct': {
            if (middlePaneHeaderBadgeOne !== 'distinct') {
              dispatch({
                type: SET_DISPLAY_TWO_SINGLE_STATE,
                payload: { stateName: 'typeOfMiddlePaneList', value: '' }
              });
              assignmentsDistinctApiCall(
                selectedAssociateInfo,
                '',
                countPage,
                dispatch,
                'distinct',
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
                assignmentCreateType
              );
              filterModeKey = 'unAllocateToAssignment';
              dispatch({ type: POPUP_CLOSE });
            }
            isValidAllocationOrUnallocation = true;
            break;
          }
          case 'groups': {
            assignmentsGroupAllocateApiCall(
              selectedAssociateInfo,
              'active',
              countPage,
              dispatch,
              'groups',
              cardValue,
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
              "list",
              [],
              assignmentCreateType,
              [selectedTagValue]
            );
            filterModeKey = 'assignmentUnAllocateToGroup';
            dispatch({ type: POPUP_CLOSE });
            isValidAllocationOrUnallocation = true;
            break;
          }
          case 'types': {
            assignmentTypeAllocateApiCall(
              selectedAssociateInfo,
              'active',
              countPage,
              dispatch,
              'types',
              cardValue,
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
              [selectedTagValue]
            );
            filterModeKey = 'assignmentUnAllocateToType';
            dispatch({ type: POPUP_CLOSE });
            isValidAllocationOrUnallocation = true;
            break;
          }
          case 'nodes': {
            getInternalNodeApiCall(
              selectedAssociateInfo,
              secondaryOptionCheckValue,
              countPage,
              dispatch,
              'nodes',
              '',
              'list',
              secondaryOptionCheckValue,
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
            );
            filterModeKey = 'assignmentUnAllocateToNode';
            dispatch({ type: POPUP_CLOSE });
            isValidAllocationOrUnallocation = true;
            break;
          }
        }
      } else if (
        (middlePaneHeader === 'assignments' || popupHeaderOne === 'assignment') &&
        secondaryOptionCheckValue === 'assessees'
      ) {
        switch (tertiaryOptionCheckValue) {
          case 'distinct': {
            getAssesseeDistinctApiCall(
              selectedAssociateInfo,
              'activeinactive',
              countPage,
              dispatch,
              'distinct',
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
            );
            filterModeKey = 'unAllocateToAssessee';
            dispatch({ type: POPUP_CLOSE });
            isValidAllocationOrUnallocation = true;
            break;
          }
          case 'groups': {
            getAssesseeGroupDistinctApiCall(
              selectedAssociateInfo,
              'active',
              countPage,
              dispatch,
              'groups',
              cardValue,
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
              "list",
              [],
              assignmentCreateType
            );
            filterModeKey = 'assesseeUnAllocateToGroup';
            dispatch({ type: POPUP_CLOSE });
            isValidAllocationOrUnallocation = true;
            break;
          }
        }
      } else if (
        (middlePaneHeader === 'assignments' || popupHeaderOne === 'assignment') &&
        secondaryOptionCheckValue === 'assessments'
      ) {
        switch (tertiaryOptionCheckValue) {
          case 'distinct': {
            getAssessmentDistinctApiCall(
              selectedAssociateInfo,
              'published',
              countPage,
              dispatch,
              'distinct',
              cardValue,
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
            );
            filterModeKey = 'unAllocateToAssessment';
            dispatch({ type: POPUP_CLOSE });
            isValidAllocationOrUnallocation = true;
            break;
          }
          case 'groups': {
            getAssessmentGroupApiCall(
              selectedAssociateInfo,
              'active',
              countPage,
              dispatch,
              'groups',
              cardValue,
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
              "list",
              [],
              assignmentCreateType
            );
            filterModeKey = 'assessmentUnAllocateToGroup';
            dispatch({ type: POPUP_CLOSE });
            isValidAllocationOrUnallocation = true;
            break;
          }
        }
      } else if (
        (middlePaneHeader === 'assignments' || popupHeaderOne === 'assignment') &&
        secondaryOptionCheckValue === 'culture-profiles'
      ) {
        switch (tertiaryOptionCheckValue) {
          case 'distinct': {
            getCultureProfilesDistinctApiCall(
              selectedAssociateInfo,
              'active',
              countPage,
              '',
              dispatch,
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
            );
            filterModeKey = 'unAllocateToCultureProfile';
            dispatch({ type: POPUP_CLOSE });
            isValidAllocationOrUnallocation = true;
            break;
          }
          case 'groups': {
            getCultureProfileGroupApiCall(
              selectedAssociateInfo,
              'active',
              countPage,
              dispatch,
              'groups',
              cardValue,
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
              [],
              'list',
              false,
              ""
            );
            filterModeKey = 'unAllocateToCultureProfileGroup';
            dispatch({ type: POPUP_CLOSE });
            isValidAllocationOrUnallocation = true;
            break;
          }
        }
      } else if (
        (middlePaneHeader === 'assignments' || popupHeaderOne === 'assignment') &&
        secondaryOptionCheckValue === 'job-profiles'
      ) {
        switch (tertiaryOptionCheckValue) {
          case 'distinct': {
            getJobProfilesDistinctApiCall(
              selectedAssociateInfo,
              'active',
              countPage,
              '',
              dispatch,
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
            );
            filterModeKey = 'unAllocateToJobProfile';
            dispatch({ type: POPUP_CLOSE });
            isValidAllocationOrUnallocation = true;
            break;
          }
          case 'groups': {
            getJobProfileGroupApiCall(
              selectedAssociateInfo,
              'active',
              countPage,
              dispatch,
              'groups',
              cardValue,
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
              [],
              'list',
              false,
              // assignmentCreateType
            );
            filterModeKey = 'jobProfileUnAllocateToGroup';
            dispatch({ type: POPUP_CLOSE });
            isValidAllocationOrUnallocation = true;
            break;
          }
        }
      } else if (
        (middlePaneHeader === 'associates' ||
          middlePaneHeader === 'associate' ||
          popupHeaderOne === 'associate') &&
        secondaryOptionCheckValue === 'associates'
      ) {
        switch (tertiaryOptionCheckValue) {
          case 'distinct': {
            if (middlePaneHeaderBadgeOne !== 'distinct') {
              getAssociateDistinctApiCall(
                selectedAssociateInfo,
                '',
                dispatch,
                countPage,
                'distinct',
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
                'Card'
              );
              isValidAllocationOrUnallocation = true;
              filterModeKey = 'associatesUnAllocateToDistinct';
              dispatch({ type: POPUP_CLOSE });
            }
            break;
          }
          case 'groups': {
            getAssociateGroupAllocateDistinctApiCall(
              selectedAssociateInfo,
              'active',
              countPage,
              dispatch,
              'groups',
              '',
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
              "list",
              [],
              assignmentCreateType,
              [selectedTagValue]
            );
            filterModeKey = 'associateUnAllocateToGroup';
            dispatch({ type: POPUP_CLOSE });
            isValidAllocationOrUnallocation = true;
            break;
          }
          case 'nodes': {
            getInternalNodeApiCall(
              selectedAssociateInfo,
              secondaryOptionCheckValue,
              countPage,
              dispatch,
              'nodes',
              '',
              'list',
              secondaryOptionCheckValue,
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
            );
            filterModeKey = 'associatesUnAllocateToNode';
            dispatch({ type: POPUP_CLOSE });
            isValidAllocationOrUnallocation = true;
            break;
          }
          case 'roles': {
            getAssociateRoleDistinctApiCall(
              selectedAssociateInfo,
              'active',
              countPage,
              dispatch,
              cardValue,
              'roles',
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
            );
            filterModeKey = 'associatesUnAllocateToRole';
            dispatch({ type: POPUP_CLOSE });
            isValidAllocationOrUnallocation = true;
            break;
          }
          case 'types': {
            getAssociatesTypeApiCall(
              selectedAssociateInfo,
              'active',
              countPage,
              dispatch,
              'types',
              'associates',
              '',
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
            );
            filterModeKey = 'associatesUnAllocateToType';
            dispatch({ type: POPUP_CLOSE });
            isValidAllocationOrUnallocation = true;
            break;
          }
        }
      } else if (
        (middlePaneHeader === 'culture-profiles' || popupHeaderOne === 'culture-profile') &&
        secondaryOptionCheckValue === 'culture-profiles'
      ) {
        switch (tertiaryOptionCheckValue) {
          case 'distinct': {
            getCultureProfilesDistinctApiCall(
              selectedAssociateInfo,
              '',
              countPage,
              '',
              dispatch,
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
            );
            filterModeKey = 'unAllocateToCultureProfile';
            dispatch({ type: POPUP_CLOSE });
            isValidAllocationOrUnallocation = true;
            break;
          }
          case 'groups': {
            getCultureProfileAllocateGroupApiCall(
              selectedAssociateInfo,
              'active',
              countPage,
              dispatch,
              'groups',
              cardValue,
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
              [],
              'list',
              false,
              assignmentCreateType,
              [selectedTagValue]
            );
            filterModeKey = 'unAllocateToCultureProfileGroup';
            dispatch({ type: POPUP_CLOSE });
            isValidAllocationOrUnallocation = true;
            break;
          }
          case 'nodes': {
            getInternalNodeApiCall(
              selectedAssociateInfo,
              secondaryOptionCheckValue,
              countPage,
              dispatch,
              'nodes',
              '',
              'list',
              secondaryOptionCheckValue,
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
            );
            filterModeKey = 'cultureProfileUnAllocateToNode';
            dispatch({ type: POPUP_CLOSE });
            isValidAllocationOrUnallocation = true;
            break;
          }
          case 'types': {
            getCultureProfileAllocateTypeApiCall(
              selectedAssociateInfo,
              'active',
              countPage,
              dispatch,
              'types',
              secondaryOptionCheckValue,
              cardValue,
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
              [],
              true,
              [selectedTagValue]
            );
            filterModeKey = 'unAllocateToCultureProfileType';
            dispatch({ type: POPUP_CLOSE });
            isValidAllocationOrUnallocation = true;
            break;
          }
        }
      } else if (
        (middlePaneHeader === 'culture-profiles' || popupHeaderOne === 'culture-profile') &&
        secondaryOptionCheckValue === 'assignments'
      ) {
        switch (tertiaryOptionCheckValue) {
          case 'distinct': {
            dispatch({
              type: SET_DISPLAY_TWO_SINGLE_STATE,
              payload: { stateName: 'typeOfMiddlePaneList', value: '' }
            });

            assignmentsDistinctApiCall(
              selectedAssociateInfo,
              'unpublished',
              countPage,
              dispatch,
              'distinct',
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
              'iGuru Analytics_Occupation'
            );
            dispatch({
              type: SET_DISPLAY_TWO_SINGLE_STATE,
              payload: {
                stateName: 'assignmentCreateType',
                value: 'iGuru Analytics_Occupation'
              }
            });
            filterModeKey = 'unAllocateToAssignment';
            dispatch({ type: POPUP_CLOSE });
            isValidAllocationOrUnallocation = true;
            break;
          }
          case 'groups': {
            assignmentsGroupAllocateApiCall(
              selectedAssociateInfo,
              'active',
              countPage,
              dispatch,
              'groups',
              cardValue,
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
              "list",
              [],
              assignmentCreateType,
              [selectedTagValue]
            );
            filterModeKey = 'assignmentUnAllocateToGroup';
            dispatch({ type: POPUP_CLOSE });
            isValidAllocationOrUnallocation = true;
            break;
          }
        }
      } else if (
        (middlePaneHeader === 'job-profiles' || popupHeaderOne === 'job-profile') &&
        secondaryOptionCheckValue === 'job-profiles'
      ) {
        switch (tertiaryOptionCheckValue) {
          case 'distinct': {
            if (middlePaneHeaderBadgeOne !== 'distinct') {
              getJobProfilesDistinctApiCall(
                selectedAssociateInfo,
                '',
                countPage,
                '',
                dispatch,
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
              );
              filterModeKey = 'unAllocateToJobProfile';
              dispatch({ type: POPUP_CLOSE });
            }
            isValidAllocationOrUnallocation = true;
            break;
          }
          case 'groups': {
            getJobProfileGroupAllocateApiCall(
              selectedAssociateInfo,
              'active',
              countPage,
              dispatch,
              'groups',
              cardValue,
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
              [],
              'list',
              false,
              assignmentCreateType,
              [selectedTagValue]
            );
            filterModeKey = 'jobProfileUnAllocateToGroup';
            dispatch({ type: POPUP_CLOSE });
            isValidAllocationOrUnallocation = true;
            break;
          }
          case 'nodes': {
            getInternalNodeApiCall(
              selectedAssociateInfo,
              secondaryOptionCheckValue,
              countPage,
              dispatch,
              'nodes',
              '',
              'list',
              secondaryOptionCheckValue,
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
            );
            filterModeKey = 'jobProfileUnAllocateToNode';
            dispatch({ type: POPUP_CLOSE });
            isValidAllocationOrUnallocation = true;
            break;
          }
          case 'types': {
            getJobProfileTypeApiAllcoateCall(
              selectedAssociateInfo,
              'active',
              countPage,
              dispatch,
              'types',
              secondaryOptionCheckValue,
              cardValue,
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
              [],
              false,
              [selectedTagValue]
            );
            filterModeKey = 'jobProfileUnAllocateToType';
            dispatch({ type: POPUP_CLOSE });
            isValidAllocationOrUnallocation = true;
            break;
          }
        }
      } else if (
        (middlePaneHeader === 'job-profiles' || popupHeaderOne === 'job-profile') &&
        secondaryOptionCheckValue === 'assignments'
      ) {
        switch (tertiaryOptionCheckValue) {
          case 'distinct': {
            dispatch({
              type: SET_DISPLAY_TWO_SINGLE_STATE,
              payload: { stateName: 'typeOfMiddlePaneList', value: '' }
            });

            assignmentsDistinctApiCall(
              selectedAssociateInfo,
              'unpublished',
              countPage,
              dispatch,
              'distinct',
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
              'iGuru Analytics_Occupation'
            );
            dispatch({
              type: SET_DISPLAY_TWO_SINGLE_STATE,
              payload: {
                stateName: 'assignmentCreateType',
                value: 'iGuru Analytics_Occupation'
              }
            });
            filterModeKey = 'unAllocateToAssignment';
            dispatch({ type: POPUP_CLOSE });
            isValidAllocationOrUnallocation = true;
            break;
          }
          case 'groups': {
            getJobProfileGroupApiCall(
              selectedAssociateInfo,
              'active',
              countPage,
              dispatch,
              'groups',
              cardValue,
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
              [],
              'list',
              false,
              // assignmentCreateType
            );
            filterModeKey = 'jobProfileUnAllocateToGroup';
            dispatch({ type: POPUP_CLOSE });
            isValidAllocationOrUnallocation = true;
            break;
          }
        }
      } else if (
        (middlePaneHeader === 'items' || popupHeaderOne === 'item') &&
        secondaryOptionCheckValue === 'items'
      ) {
        switch (tertiaryOptionCheckValue) {
          case 'distinct': {
            if (middlePaneHeaderBadgeOne !== 'distinct') {
              getItemsDistinctApiCall(
                selectedAssociateInfo,
                '', // 'all',
                countPage,
                'distinct',
                dispatch,
                popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
              );
              isValidAllocationOrUnallocation = true;
              filterModeKey = 'unAllocateToItem';
              dispatch({ type: POPUP_CLOSE });
            }
            break;
          }
          case 'groups': {
            getItemGroupDistinctAllocateApiCall(
              selectedAssociateInfo,
              'active',
              countPage,
              dispatch,
              'groups',
              cardValue,
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
              'list',
              [],
              false,
              assignmentCreateType,
              [selectedTagValue]
            );
            filterModeKey = 'itemUnAllocateToGroup';
            dispatch({ type: POPUP_CLOSE });
            isValidAllocationOrUnallocation = true;
            break;
          }
          case 'nodes': {
            getInternalNodeApiCall(
              selectedAssociateInfo,
              secondaryOptionCheckValue,
              countPage,
              dispatch,
              'nodes',
              '',
              'list',
              secondaryOptionCheckValue,
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
            );
            filterModeKey = 'itemUnAllocateToNode';
            dispatch({ type: POPUP_CLOSE });
            isValidAllocationOrUnallocation = true;
            break;
          }
          case 'types': {
            getItemsTypeAllocateApiCall(
              selectedAssociateInfo,
              'active',
              countPage,
              dispatch,
              'types',
              middlePaneHeader,
              cardValue,
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
              [selectedTagValue]
            );
            filterModeKey = 'itemUnAllocateToType';
            dispatch({ type: POPUP_CLOSE });
            isValidAllocationOrUnallocation = true;
            break;
          }
        }
      } else if (
        (middlePaneHeader === 'items' || popupHeaderOne === 'item') &&
        secondaryOptionCheckValue === 'assessments'
      ) {
        switch (tertiaryOptionCheckValue) {
          case 'distinct': {
            getAssessmentDistinctApiCall(
              selectedAssociateInfo,
              'unpublished',
              countPage,
              dispatch,
              'distinct',
              cardValue,
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : ''
            );
            filterModeKey = 'itemUnAllocateToAssessment';
            dispatch({ type: POPUP_CLOSE });
            isValidAllocationOrUnallocation = true;
            break;
          }
          case 'groups': {
            getAssessmentGroupApiCall(
              selectedAssociateInfo,
              'active',
              countPage,
              dispatch,
              'groups',
              cardValue,
              popupHeaderOneBadgeTwo === 'allocate' ? 'multiple' : '',
              "list",
              [],
              assignmentCreateType
            );
            filterModeKey = 'assessmentUnAllocateToGroup';
            dispatch({ type: POPUP_CLOSE });
            isValidAllocationOrUnallocation = true;
            break;
          }
        }
      }
    } else if (dataVal === 'no') {
      // if (middlePaneHeader === 'assignments') {
      //   dispatch({ type: POPUP_CLOSE });
      // } else
      if (popupHeaderOne === 'assessment' && isAssessmentStart !== 'START') {
        dispatch({ type: POPUP_CLOSE });
      } else if (popupHeaderOne === 'assessment' && isAssessmentStart === 'START') {
        dispatch({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: { stateName: 'isAssessmentStart', value: '' }
        });
        dispatch({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: { stateName: 'isExamMode', value: false }
        });
        dispatch({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: 'assessments',
            middlePaneHeaderBadgeOne: 'active',
            middlePaneHeaderBadgeTwo: '',
            middlePaneHeaderBadgeThree: '',
            middlePaneHeaderBadgeFour: '',
            typeOfMiddlePaneList: 'assesseesAssginmentAssessmentReviewList',
            middlePaneHeaderDuplicate: middlePaneHeaderDuplicate,
            middlePaneHeaderDuplicateBadgeOne: middlePaneHeaderDuplicateBadgeOne,
            middlePaneHeaderDuplicateBadgeTwo: '',
            middlePaneHeaderDuplicateBadgeThree: '',
            middlePaneHeaderDuplicateBadgeFour: '',
            scanCount:
              relatedReviewListDistinctData[0]?.assesseeAssignmentAssessmentDistinct?.length || 0,
            showMiddlePaneState: true
          }
        });
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'relatedReviewListDistinctData',
            value: relatedReviewListDistinctData
          }
        });
        dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
        dispatch({ type: POPUP_CLOSE });
      }
      // for all the deleted multiple
      else {
        dispatch({ type: POPUP_CLOSE });
      }
      dispatch({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'headerButtons', value: true }
      });
    } else if (popupHeaderOneBadgeTwo === 'move') {
      switch (dataVal) {
        case 'groups': {
          // debugger;
          dispatch({ type: POPUP_CLOSE });
          break;
        }
        case 'nodes': {
          // debugger;
          dispatch({ type: POPUP_CLOSE });
          break;
        }
        case 'roles': {
          // debugger;
          dispatch({ type: POPUP_CLOSE });
          break;
        }
        case 'types': {
          // debugger;
          dispatch({ type: POPUP_CLOSE });
          break;
        }
      }
    } else if (dataVal === 'shareValue') {
      let PopUpValue;
      let popUpMode;
      let obj = {
        assessees: 'distinct_groups',
        assessments: 'distinct_groups',
        assignments: 'distinct_groups',
        associates: 'distinct_groups',
        'culture-profiles': 'distinct_groups',
        'job-profiles': 'distinct_groups',
        items: 'distinct_groups'
      };
      let header = popupHeaderOneBadgeOne === 'distinct' ? popupHeaderOne + 's' : popupHeaderOne;
      if (obj[header].includes(popupHeaderOneBadgeOne)) {
        setShareFeePopup(true);
        dispatch({
          type: SET_POPUP_STATE,
          payload: { ...PopUpReducer, isPopUpFrom: 'reviewList', popupOpenType: 'secondary' }
        });
      } else console.log('condition not found');
      dispatch({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'previousPopupOpenType', value: 'secondary' }
      });
    } else {
      if (
        dataVal === 'clusters' ||
        dataVal === 'grades' ||
        dataVal === 'sections' ||
        dataVal === 'versions' ||
        dataVal === 'scales' ||
        dataVal === 'norms' ||
        dataVal === 'norms'
      ) {
        let permissionObject =
          signedAssesseePermission?.assessmentDistictPermission
            ?.assesseeAssessmentDistinctPermission;
        // debugger;
        dispatch({
          type: SET_MIDDLEPANE_SECONDARY_OPTION,
          payload: {
            badgeValue: dataVal,
            keyValue: keyVal,
            permissionObject: permissionObject,
            checkCardPermission: 'singlepopup',
            isPopUpFrom: 'reviewList'
          }
        });
      } else if (dataVal === 'metrics') {
        let permissionObject =
          signedAssesseePermission?.iGuruAnalyticDistinctPermission
            ?.assesseeiGuruAnalyticDistinctPermission;
        dispatch({
          type: SET_MIDDLEPANE_SECONDARY_OPTION,
          payload: {
            badgeValue: dataVal,
            keyValue: keyVal,
            permissionObject: permissionObject,
            checkCardPermission: 'singlepopup',
            isPopUpFrom: 'reviewList'
          }
        });
      } else {
        // debugger;
        dispatch({
          type: SET_MIDDLEPANE_SECONDARY_OPTION, //this
          payload: { badgeValue: dataVal, keyValue: keyVal, isPopUpFrom: 'reviewList' }
        });
      }
    }

    if (popupHeaderOne === 'assignments' && dataVal === 'pay') {
      dispatch({
        type: 'SET_DISPLAY_TWO_SINGLE_STATE',
        payload: { stateName: 'selectedTagsArray', value: [selectedTagValue] }
      });

      dispatch({
        type: SET_POPUP_STATE,
        payload: {
          popupHeaderOne: 'assignments',
          popupHeaderOneBadgeOne: 'mine',
          popupHeaderOneBadgeTwo: '',
          isPopUpValue: 'PAYMENTINFO',
          popupOpenType: 'primary'
          // popupContentArrValue: tempArr,
        }
      });
    }

    if (
      popupHeaderOneBadgeTwo === 'allocate' &&
      tertiaryOptionCheckValue !== '' &&
      secondaryOptionCheckValue !== 'all' &&
      isValidAllocationOrUnallocation === true
    ) {
      if (createMode) {
        let allocateString =
          headerOneBadgeOne !== 'information' ? createMode + 's' + headerOneBadgeOne : createMode + 'sdistinct';
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'allocateStr',
            value: allocateString.toLowerCase()
          }
        });
      } else {
        let middlePaneHeaderStr = middlePaneHeader ? middlePaneHeader : popupHeaderOne;
        let middlePaneHeaderBadgeOneStr = middlePaneHeaderBadgeOne
          ? middlePaneHeaderBadgeOne
          : popupHeaderOneBadgeOne + 's';
        dispatch({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'allocateStr',
            value: middlePaneHeaderStr + middlePaneHeaderBadgeOneStr
          }
        });
      }
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'allocatedTagsArray', value: [selectedTagValue] }
      });
      dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
      dispatch({
        type: FILTERMODE,
        payload: { FilterMode: filterModeKey }
      });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isSelectActive', value: 'multiple' }
      });
    }
  };

  let header = popupHeaderOneBadgeOne === 'distinct' ? popupHeaderOne + 's' : popupHeaderOne;
  let keyString = `${header.slice(0, -1)}`;

  if (header === 'culture-profiles' || header === 'job-profiles') {
    keyString = header.split('-').join('').replaceAll('profiles', 'Profile');
  }
  keyString =
    popupHeaderOneBadgeOne === 'distinct' ? `${keyString}ShareFee` : `${keyString}GroupShareFee`;
  console.log('keyString', keyString);

  const [value, setValue] = useState(selectedTagInformationSetup?.[keyString] || 0);
  const [minValue, setMinValue] = useState(selectedTagInformationSetup?.[keyString] || 0);
  const [isErrorMsg, setIsErrorMsg] = useState('');
  useEffect(() => {
    console.log('fee value', value);
  }, []);
  let type = 'number';

  const BackHandlerEvent = (e) => {
    setShareFeePopup((prevState) => prevState && !prevState);
    setValue(selectedTagInformationSetup?.[keyString]);
    setIsErrorMsg('');
    dispatch({ type: SET_MIDDLEPANE_REVIEW_LIST_PREVIOUS_POPUP });
    setIsReviseMode(false);
  };

  const onChange = (e) => {
    let val = e.target.value;

    if (val && val.toString().length > 10) {
      val = parseFloat(val.toString().slice(0, -1));
    }

    setValue(val);
    if (parseFloat(val) < parseFloat(minValue)) {
      setIsErrorMsg('fee should be greater than setup fee');
    } else {
      setIsErrorMsg('');
    }
  };

  const onNext = (e) => {
    let isValidate = false;

    if (value === '' || value === undefined || value === null) {
      setIsErrorMsg(REQUIRED_ERROR_MESSAGE);
      isValidate = false;
      return;
    }

    if (parseFloat(value) === 0) {
      setIsErrorMsg('');
      isValidate = true;
    }

    if (parseFloat(value) < parseFloat(minValue)) {
      isValidate = false;
      setIsErrorMsg('fee should be greater than setup fee');
      return;
    } else {
      isValidate = true;
      setIsErrorMsg('');
    }

    if (isValidate) {
      setShareFeePopup((prevState) => prevState && !prevState);
      // setUpdatedShareFee(parseFloat(value));
      dispatch({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'shareFeePopupValue', value: parseFloat(value).toFixed(2) }
      });
      // dispatch({
      //   type: SET_MIDDLEPANE_SECONDARY_OPTION,
      //   payload: { badgeValue: 'share', keyValue: 'share', isPopUpFrom: 'reviewList' }
      // });
      ChangeOptionPopup({
        customKeys: true,
        customDataVal: 'shareApiCall',
        customKeyVal: 'shareunshareTertiary',
        updatedShareFee: parseFloat(value).toFixed(2)
      });
    }
  };

  return (
    <div>
      <Popup isActive={isActive}>
        <PopupHeader
          headerPanelColour={headerPanelColour + popupOpenType}
          headerOne={popupHeaderOne}
          headerOneBadgeOne={popupHeaderOneBadgeOne}
          headerOneBadgeTwo={
            popupHeaderOneBadgeTwo ? popupHeaderOneBadgeTwo : shareFeePopup ? 'share' : ''
          }
          headerOneBadgeThree={popupHeaderOneBadgeThree}
          onClick={BackHandlerEvent}
          // onClosePopUpEvent={(popupHeaderOne === 'assignments' && middlePaneHeaderBadgeOne === 'distinct' && middlePaneHeaderBadgeTwo === 'mine') ? onClosePopUpEvent : null}
          forwardArrow={shareFeePopup}
          forwardArrowFn={onNext}
          mode={isAssessmentStart === 'START' ? 'cancel' : ''}
        />

        {(popupHeaderOneBadgeOne === 'distinct' || popupHeaderOneBadgeOne === 'group') &&
          shareFeePopup ? (
          <DialogContent
            className={['popupContent', 'fixed10PadDim', 'revisePopupContent'].join(' ')}
          >
            {popupHeaderOne !== '' && (
              <div className={'fitContent'}>
                <div className={['PopupFormBox', 'labelPopupBox', 'popupMinHei'].join(' ')}>
                  <InputLabel htmlFor="name-input" className={'textForLabelPopup'}>
                    <>
                      {popupHeaderOne}&nbsp;
                      {popupHeaderOneBadgeOne ? (
                        <span className={'headerBadge'}>{popupHeaderOneBadgeOne}</span>
                      ) : null}
                      &nbsp;
                      {/* {popupHeaderOneBadgeTwo ? ( */}
                      <span className={'headerBadge'}>{'share'}</span>
                      {/* ) : null} */}
                      &nbsp;
                    </>
                  </InputLabel>
                  <div className={'infoSymbol'}></div>
                  <div className={'infoSymbol'}></div>
                </div>
              </div>
            )}
            <div className="popup-form-box">
              <FormControl style={{ width: '100%' }}>
                <InputLabel
                  htmlFor={'fee'}
                  style={{ fontSize: '1.6rem' }}
                  {...(type === 'datetime-local' && { shrink: true })}
                >
                  {'fee'} &nbsp;
                </InputLabel>
                <Input
                  type={'number'}
                  id={'fee'}
                  name={'fees'}
                  value={value}
                  onChange={onChange}
                  error={value < minValue || isErrorMsg !== ''}
                  // step={0.01}
                  autoComplete="off"
                  autoFocus={true}
                  className={['inputFields'].join(' ')}
                  inputProps={{
                    min: minValue,
                    max: '9999999999',
                    onKeyDown: (e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
                  }}
                />
              </FormControl>
              <FormHelperText
                className={[isErrorMsg ? 'helperText' : 'helperTextDefault', 'helptextmargin'].join(
                  ' '
                )}
              >
                <span>{isErrorMsg}</span>
              </FormHelperText>
            </div>
          </DialogContent>
        ) : (
          <DialogContent className={['popupContent', 'fixed05PadDim'].join(' ')}>
            <JsonRenderComponent
              setSecondaryOptionValue={setSecondaryOptionValue}
              setSecondaryOptionValueTwo={setSecondaryOptionValueTwo}
              setSecondaryOptionValueThree={setSecondaryOptionValueThree}
              ChangeOptionPopup={(e) => {
                !isListLoading && ChangeOptionPopup(e);
              }}
              secondaryOptionCheckValue={secondaryOptionCheckValue}
              tertiaryOptionCheckValue={tertiaryOptionCheckValue}
              forthOptionCheckValue={forthOptionCheckValue}
            />
            {/* )} */}
          </DialogContent>
        )}
      </Popup>
    </div>
  );
};

export default PopUpDisplayPaneTwoReviewList;
