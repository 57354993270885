import { CircularProgress } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useHistory, useParams } from "react-router-dom";
import {
  CLEAR_ASSESSEE_ASSESSMENT_REDUCER_STATE,
  CLEAR_PANE_TWO_REDUCER,
  RESET_ALL_REDUCER,
} from "../../actionType";
import { SIGN_IN_URL } from "../../endpoints";

const LogoutComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    history.push(SIGN_IN_URL);
    localStorage.clear();
    dispatch({ type: RESET_ALL_REDUCER });
    dispatch({ type: CLEAR_PANE_TWO_REDUCER });
    dispatch({ type: CLEAR_ASSESSEE_ASSESSMENT_REDUCER_STATE });
  }, []);
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        width: "100vw",
        height: "100vh",
      }}
    >
      <CircularProgress color="primary" size={50} />
      processing...
    </div>
  );
};

export default LogoutComponent;
