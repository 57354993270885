import React, { useEffect } from 'react';
import HeaderCard from '../../Molecules/Header/HeaderCard';
import { useDispatch, useSelector } from 'react-redux';
import './DisplayPaneTwo.css';
import AssesseeRelatedAssociateReviewList from '../../ReviewListComponent/AssesseeRelatedAssociateReviewList';
import AssesseeDistinctReviewList from '../../ReviewListComponent/AssesseeDistinctReviewList';
import AssociateDistinctReviewList from '../../ReviewListComponent/AssociateDistinctReviewList';
import AssociateDistinctShareReviewList from '../../ReviewListComponent/AssociateDistinctShareReviewList';
import AssesseeRoleDistinctReviewList from '../../ReviewListComponent/AssesseeRoleDistinctReviewList';
import AssociateRoleDistinctReviewList from '../../ReviewListComponent/AssociateRoleDistinctReviewList';
import PopUpScan from '../../PopUpInformation/PopUpScan';
import AssesseeGroupReviewList from '../../ReviewListComponent/AssesseeGroupReviewList';
import AssociateGroupReviewList from '../../ReviewListComponent/AssociateGroupReviewList';
import AssessmentGroupReviewList from '../../ReviewListComponent/AssessmentGroupReviewList';
import AssignmentGroupReviewList from '../../ReviewListComponent/AssignmentGroupReviewList';
import AssessmentTypeReviewList from '../../ReviewListComponent/AssessmentTypeReviewList';
import AssignmentTypeReviewList from '../../ReviewListComponent/AssignmentTypeReviewList';
import AssignmentDistinctReviewList from '../../ReviewListComponent/AssignmentDistinctReviewList';
import AssessmentDistinctReviewList from '../../ReviewListComponent/AssessmentDistinctReviewList';
import AssesseeGroupAssesseeDistinctReviewList from '../../ReviewListComponent/AssesseeGroupAssesseeDistinctReviewList';
import AssociateGroupAssociateDistinctReviewList from '../../ReviewListComponent/AssociateGroupAssociateDistinctReviewList';
import AssesseeRoleAssesseeDistinctReviewList from '../../ReviewListComponent/AssesseeRoleAssesseeDistinctReviewList';
import AssociateRoleAssociateDistinctReviewList from '../../ReviewListComponent/AssociateRoleAssociateDistinctReviewList';
import IguruNodeReviewList from '../../ReviewListComponent/IguruNodeReviewList';
import AssociateNodeReviewList from '../../ReviewListComponent/AssociateNodeReviewList';
import AssesseeNodeAssesseeDistinctReviewList from '../../ReviewListComponent/AssesseeNodeAssesseeDistinctReviewList';
import AssesseeAssociateReviewList from '../../ReviewListComponent/AssesseeAssociateReviewList';
import AssesseeTypeReviewList from '../../ReviewListComponent/AssesseeTypeReviewList';
import AssociateTypeReviewList from '../../ReviewListComponent/AssociateTypeReviewList';
import AssesseeTypeAssesseeDistinctReviewList from '../../ReviewListComponent/AssesseeTypeAssesseeDistinctReviewList';
import AssociateTypeAssociateDistinctReviewList from '../../ReviewListComponent/AssociateTypeAssociateDistinctReviewList';
import ItemReviewList from '../../ReviewListComponent/ItemReviewList';
import ItemGroupReviewList from '../../ReviewListComponent/ItemGroupReviewList';
import NodeRelatedAssociateReviewList from '../../ReviewListComponent/NodeRelatedAssociateReviewList';
import ItemTypeReviewList from '../../ReviewListComponent/ItemTypeReviewList';
import ItemGroupItemDistinctReviewList from '../../ReviewListComponent/ItemGroupItemDistinctReviewList';
import ItemTypeItemDistinctReviewList from '../../ReviewListComponent/ItemTypeItemDistinctReviewList';
import AssessmentTypeAssessmentDistinctReviewList from '../../ReviewListComponent/AssessmentTypeAssessmentDistinctReviewList';
import AssessmentNodeAssessmentDistinctReviewList from '../../ReviewListComponent/AssessmentNodeAssessmentDistinctReviewList';
import ItemNodeItemDistinctReviewList from '../../ReviewListComponent/ItemNodeItemDistinctReviewList';
import AssignmentTypeAssignmentDistinctReviewList from '../../ReviewListComponent/AssignmentTypeAssignmentDistinctReviewList';
import AssignmentGroupAssignmentDistinctReviewList from '../../ReviewListComponent/AssignmentGroupAssignmentDistinctReviewList';
import AssignmentNodeAssignmentDistinctReviewList from '../../ReviewListComponent/AssignmentNodeAssignmentDistinctReviewList';
import CultureProfileDistinctReviewList from '../../ReviewListComponent/CultureProfileDistinctReviewList';
import CultureProfileGroupReviewList from '../../ReviewListComponent/CultureProfileGroupReviewList';
import CultureProfileTypeReviewList from '../../ReviewListComponent/CultureProfileTypeReviewList';
import JobProfileDistinctReviewList from '../../ReviewListComponent/JobProfileDistinctReviewList';
import CultureProfileGroupCultureProfileDistinctReviewList from '../../ReviewListComponent/CultureProfileGroupCultureProfileDistinctReviewList';
import CultureProfileTypeCultureProfileDistinctReviewList from '../../ReviewListComponent/CultureProfileTypeCultureProfileDistinctReviewList';
import JobProfileGroupReviewList from '../../ReviewListComponent/JobProfileGroupReviewList';
import JobProfileTypeReviewList from '../../ReviewListComponent/JobProfileTypeReviewList';
import AssessmentGroupAssessmentDistinctReviewList from '../../ReviewListComponent/AssessmentGroupAssessmentDistinctReviewList';
import JobProfileGroupJobProfileDistinctReviewList from '../../ReviewListComponent/JobProfileGroupJobProfileDistinctReviewList';
import JobProfileTypeJobProfileDistinctReviewList from '../../ReviewListComponent/JobProfileTypeJobProfileDistinctReviewList';
import CultureProfileNodeCultureProfileDistinctReviewList from '../../ReviewListComponent/CultureProfileNodeCultureProfileDistinctReviewList';
import JobProfileNodeJobProfileDistinctReviewList from '../../ReviewListComponent/JobProfileNodeJobProfileDistinctReviewList';
import AssignmentDistinctAssesseeDistinctReviewList from '../../ReviewListComponent/AssignmentDistinctAssesseeDistinctReviewList';
import AssignmentDistinctAssessmentDistinctReviewList from '../../ReviewListComponent/AssignmentDistinctAssessmentDistinctReviewList';
import AssignmentDistinctCultureProfileDistinctReviewList from '../../ReviewListComponent/AssignmentDistinctCultureProfileDistinctReviewList';
import AssignmentDistinctJobProfileDistinctReviewList from '../../ReviewListComponent/AssignmentDistinctJobProfileDistinctReviewList';
import CultureProfileAssessmentDistinctReviewList from '../../ReviewListComponent/CultureProfileAssessmentDistinctReviewList';
import JobProfileAssessmentDistinctReviewList from '../../ReviewListComponent/JobProfileAssessmentDistinctReviewList';
import AssesseeDistinctAssignmentDistinctReviewList from '../../ReviewListComponent/AssesseeDistinctAssignmentDistinctReviewList';
import AssessmentDistinctItemDistinctReviewList from '../../ReviewListComponent/AssessmentDistinctItemDistinctReviewList';
import AssessmentDistinctScaleDistinctReviewList from '../../ReviewListComponent/AssessmentDistinctScaleDistinctReviewList';
import AssesseeDistinctAssessmentDistinctReviewList from '../../ReviewListComponent/AssesseeDistinctAssessmentDistinctReviewList';
import AssesseeDistinctReportDistinctReviewList from '../../ReviewListComponent/AssesseeDistinctReportDistinctReviewList';
import AssessmentDistinctSectionDistinctReviewList from '../../ReviewListComponent/AssessmentDistinctSectionDistinctReviewList';
import AssessmentDistinctClusterDistinctReviewList from '../../ReviewListComponent/AssessmentDistinctClusterDistinctReviewList';
import AssessmentSectionItemDistinctReviewList from '../../ReviewListComponent/AssessmentSectionItemDistinctReviewList';
import AssessmentSectionItemGroupDistinctReviewList from '../../ReviewListComponent/AssessmentSectionItemGroupDistinctReviewList';
import AssignmentDistinctAssesseeGroupReviewList from '../../ReviewListComponent/AssignmentDistinctAssesseeGroupReviewList';
import AssignmentDistinctAssessmentGroupReviewList from '../../ReviewListComponent/AssignmentDistinctAssessmentGroupReviewList';
import AssignmentDistinctCultureProfileGroupReviewList from '../../ReviewListComponent/AssignmentDistinctCultureProfileGroupReviewList';
import AssignmentDistinctJobProfileGroupReviewList from '../../ReviewListComponent/AssignmentDistinctJobProfileGroupReviewList';
import AssessmentDistinctVersionDistinctReviewList from '../../ReviewListComponent/AssessmentDistinctVersionDistinctReviewList';
import AssesseeDistinctAssignmentReviewList from '../../ReviewListComponent/AssesseeDistinctAssignmentReviewList';
import AssessmentDistinctNormsDistinctReviewList from '../../ReviewListComponent/AssessmentDistinctNormsDistinctReviewList';
import AssessmentDistinctScoresDistinctReviewList from '../../ReviewListComponent/AssessmentDistinctScoresDistinctReviewList';
import AssessmentDistinctGradesDistinctReviewList from '../../ReviewListComponent/AssessmentDistinctGradesDistinctReviewList';
import AssessmentDistinctSubScaleDistinct from '../../ReviewListComponent/AssessmentDistinctSubScaleDistinct';
import AssignmentDistinctReportReviewList from '../../ReviewListComponent/AssignmentDistinctReportReviewList';
import AssignmentDistinctGuageDistinctReviewList from '../../ReviewListComponent/AssignmentDistinctGuageDistinctReviewList';
import AssignmentDistinctGaugeMetricsDistinct from '../../ReviewListComponent/AssignmentDistinctGaugeMetricsDistinct';
import AssignmentDistinctReportMetricReviewList from '../../ReviewListComponent/AssignmentDistinctReportMetricReviewList';
import ProfilerDistinctReviewList from '../../ReviewListComponent/ProfilerDistinctReviewList';
import GaugeDistinctReviewList from '../../ReviewListComponent/GaugeDistinctReviewList';
import GaugeDistinctMetricDistinctReviewList from '../../ReviewListComponent/GaugeDistinctMetricDistinctReviewList';
import CultureDimensionsDistinctReviewList from '../../ReviewListComponent/CultureDimensionsDistinctReviewList';
import JobRoleDistinctReviewList from '../../ReviewListComponent/JobRoleDistinctReviewList';
import JobFunctionDistinctReviewList from '../../ReviewListComponent/JobFunctionDistinctReviewList';
import JobDomainDistinctReviewList from '../../ReviewListComponent/JobDomainDistinctReviewList';
import JobCompetancyDistinctReviewList from '../../ReviewListComponent/JobCometancyDistinctReviewList';
import CultureProfilerDistinctReviewList from '../../ReviewListComponent/CultureProfilerDistinctReviewList';
import JobProfilerDistinctReviewList from '../../ReviewListComponent/JobProfilerDistinctReviewList';
import ClusterDistinctReviewList from '../../ReviewListComponent/ClusterDistinctReviewList';
import AssesseeAssignmentAssessmentDistinctReviewList from '../../ReviewListComponent/AssesseeAssignmentAssessmentDistinctReviewList';
import AssignmentDistinctAssignmentReferenceReviewList from '../../ReviewListComponent/AssignmentDistinctAssignmentReferenceReviewList';
import AssignmentAssessmentNormDistinctReviewList from '../../ReviewListComponent/AssignmentAssessmentNormDistinctReviewList';
import AssesseeGroupAssesseeAssignmentDistinctReviewList from '../../ReviewListComponent/AssesseeGroupAssesseeAssignmentDistinctReviewList';
import AssignmentAssesseeAssessmentDistinctReviewList from '../../ReviewListComponent/AssignmentAssesseeAssessmentDistinctReviewList';
import AssesseeDistinctAssignmentMineDistinctReviewList from '../../ReviewListComponent/AssesseeDistinctAssignmentMineDistinctReviewList';
import AssignmentDistinctMineAssesseeDistinctReviewList from '../../ReviewListComponent/AssignmentDistinctMineAssesseeDistinctReviewList';
import AssesseeGroupMineReviewList from '../../ReviewListComponent/AssesseeGroupMineReviewList';
import MarketplaceAssesseeDistinctReviewList from '../../ReviewListComponent/MarketplaceAssesseeDistinctReviewList';
import MarketplaceAssesseeGroupReviewList from '../../ReviewListComponent/MarketplaceAssesseeGroupReviewList';
import MarketplaceAssesseeNodeReviewList from '../../ReviewListComponent/MarketplaceAssesseeNodeReviewList';
import MarketplaceAssesseeRoleReviewList from '../../ReviewListComponent/MarketplaceAssesseeRoleReviewList';
import MarketplaceAssesseeTypeReviewList from '../../ReviewListComponent/MarketplaceAssesseeTypeReviewList';
import MarketplaceAssessmentDistinctReviewList from '../../ReviewListComponent/MarketplaceAssessmentDistinctReviewList';
import MarketplaceAssessmentGroupReviewList from '../../ReviewListComponent/MarketplaceAssessmentGroupReviewList';
import MarketplaceAssessmentNodeReviewList from '../../ReviewListComponent/MarketplaceAssessmentNodeReviewList';
import MarketplaceAssessmentTypeReviewList from '../../ReviewListComponent/MarketplaceAssessmentTypeReviewList';
import MarketplaceAssignmentDistinctReviewList from '../../ReviewListComponent/MarketplaceAssignmentDistinctReviewList';
import MarketplaceAssignmentGroupReviewList from '../../ReviewListComponent/MarketplaceAssignmentGroupReviewList';
import MarketplaceAssignmentNodeReviewList from '../../ReviewListComponent/MarketplaceAssignmentNodeReviewList';
import MarketplaceAssignmentTypeReviewList from '../../ReviewListComponent/MarketplaceAssignmentTypeReviewList';

import AssesseeMarketPlaceDistinctReviewList from '../../ReviewListComponent/AssesseeMarketPlaceDistinctReviewList';
import MarketPlaceCultureProfileDistinctReviewList from '../../ReviewListComponent/MarketPlaceCultureProfileDistinctReviewList';
import MarketPlaceCultureProfileGroupReviewList from '../../ReviewListComponent/MarketPlaceCultureProfileGroupReviewList';
import MarketPlaceCultureProfileTypeReviewList from '../../ReviewListComponent/MarketPlaceCultureProfileTypeReviewList';
import MarketPlaceCultureProfileNodeCultureProfileDistinctReviewList from '../../ReviewListComponent/MarketPlaceCultureProfileNodeCultureProfileDistinctReviewList';
import MarketPlaceJobProfileNodeJobProfileDistinctReviewList from '../../ReviewListComponent/MarketPlaceCultureProfileNodeCultureProfileDistinctReviewList';
import MarketplaceJobProfileDistinctReviewList from '../../ReviewListComponent/MarketplaceJobProfileDistinctReviewList';
import MarketplaceJobProfileGroupReviewList from '../../ReviewListComponent/MarketplaceJobProfileGroupReviewList';
import MarketPlaceJobProfileTypeReviewList from '../../ReviewListComponent/MarketPlaceJobProfileTypeReviewList';
import MarketplaceItemReviewList from '../../ReviewListComponent/MarketplaceItemReviewList';
import MarketplaceItemGroupReviewList from '../../ReviewListComponent/MarketplaceItemGroupReviewList';
import MarketplaceItemTypeReviewList from '../../ReviewListComponent/MarketplaceItemTypeReviewList';
import MarketplaceItemNodeItemDistinctReviewList from '../../ReviewListComponent/MarketplaceItemNodeItemDistinctReviewList';
import MarketplaceAssessmentsTypeDistinctReviewList from '../../ReviewListComponent/MarketplaceAssessmentsTypeDistinctReviewList';
import MarketplaceAssignmentsTypeDistinctReviewList from '../../ReviewListComponent/MarketplaceAssignmentsTypeDistinctReviewList';
import MarketplaceAssesseeRoleDistinctReviewList from '../../ReviewListComponent/MarketplaceAssesseeRoleDistinctReviewList';
import MarketplaceAssesseeTypeAssesseeReviewList from '../../ReviewListComponent/MarketplaceAssesseeTypeAssesseeReviewList';
import AsseseeDistinctReportReviewList from '../../ReviewListComponent/AsseseeDistinctReportReviewList';
import AssesseeSelfReportReviewList from '../../ReviewListComponent/AssesseeSelfReportReviewList';
import AssesseeDistinctVideosReviewList from '../../ReviewListComponent/AssesseeDistinctVideosReviewList';
import AssesseeDistinctManuscriptReviewList from '../../ReviewListComponent/AssesseeDistinctManuscriptReviewList';

export const DisplayPaneTwo = (props) => {
  const { popupAllClose } = props;
  const { popupMode } = useSelector((state) => state.PopUpReducer);
  const {
    isAssociateSelected,
    middlePaneHeader,
    middlePaneHeaderBadgeOne,
    middlePaneHeaderBadgeTwo,
    middlePaneHeaderBadgeThree,
    middlePaneHeaderBadgeFour,
    typeOfMiddlePaneList,
    scanCount,
    showMiddlePaneState,
    isListLoading
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  useEffect(() => {
    document.getElementById('middleComponentId').scrollTop = '0px';
  }, [typeOfMiddlePaneList]);

  const dispatch = useDispatch();

  const { isAssessmentStart, isExamMode } = useSelector(
    (state) => state.AssesseeAssignmentAssessmentReducer
  );

  // console.log(isExamMode, 'EXAMMODE');
  useEffect(() => {
    if (!isExamMode) {
      dispatch({
        type: 'STOP_COUNTDOWN'
      });
    }
  }, [isExamMode]);

  // console.log(typeOfMiddlePaneList, isListLoading, 'typeOfMiddlePaneList');
  return (
    <div>
      <div>
        <HeaderCard
          className=""
          displayPane="centre"
          headerOne={middlePaneHeader}
          headerOneBadgeOne={middlePaneHeaderBadgeOne}
          headerOneBadgeTwo={middlePaneHeaderBadgeTwo}
          headerOneBadgeThree={middlePaneHeaderBadgeThree}
          headerOneBadgeFour={middlePaneHeaderBadgeFour}
          headerPanelColour="green"
          scanCount={scanCount}
          isAssociateSelected={isAssociateSelected}
          showMiddlePaneState={showMiddlePaneState}
        />
      </div>
      {popupMode === 'SCAN_POPUP_FUN' && <PopUpScan />}

      <div className="containerPadding" id={'middleComponentId'}>
        <div
          style={{
            height: 'calc(100vh - 135px)',
            overflow: 'overlay'
          }}
        >
          {typeOfMiddlePaneList === 'assesseeRelatedAssociate' && (
            <AssesseeRelatedAssociateReviewList />
          )}
          {(typeOfMiddlePaneList === 'assesseesDistinctReviewList' ||
            typeOfMiddlePaneList === 'administratorsDistinctReviewList' ||
            typeOfMiddlePaneList === 'assistantsDistinctReviewList' ||
            typeOfMiddlePaneList === 'managersDistinctReviewList') && (
            <AssesseeDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'associateDistinctReviewList' && (
            <AssociateDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assesseeAssignmentDistinctReviewList' && (
            <AssesseeDistinctAssignmentDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'associateMineAssignmentDistinctReviewList' && (
            <AssesseeDistinctAssignmentMineDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assignmentDistinctMineAssesseeDistinctReviewList' && (
            <AssignmentDistinctMineAssesseeDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assesseesAssginmentAssessmentReviewList' && (
            <AssesseeDistinctAssessmentDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assesseeRoleDistinctReviewList' && (
            <AssesseeRoleDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'marketplaceAssesseeRoleDistinctReviewList' && (
            <MarketplaceAssesseeRoleDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'associateRoleDistinctReviewList' && (
            <AssociateRoleDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assesseesGroupDistinctReviewList' && (
            <AssesseeGroupReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assessmentsGroupDistinctReviewList' && (
            <AssessmentGroupReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assignmentsGroupDistinctReviewList' && (
            <AssignmentGroupReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'associatesGroupDistinctReviewList' && (
            <AssociateGroupReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assessmentsTypeDistinctReviewList' && (
            <AssessmentTypeReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'MarketplaceAssessmentsTypeDistinctReviewList' && (
            <MarketplaceAssessmentsTypeDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assessmentGroupAssessmentReviewList' && (
            <AssessmentGroupAssessmentDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assignmentAssessmentNormReviewList' && (
            <AssignmentAssessmentNormDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assignmentsTypeDistinctReviewList' && (
            <AssignmentTypeReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'MarketplaceAssignmentsTypeDistinctReviewList' && (
            <MarketplaceAssignmentsTypeDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assignmentDistinctReviewList' && (
            <AssignmentDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assignmentGroupAssignmentReviewList' && (
            <AssignmentGroupAssignmentDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assignmentTypeAssignmentReviewList' && (
            <AssignmentTypeAssignmentDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assignmentNodeAssignmentReviewList' && (
            <AssignmentNodeAssignmentDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assessmentDistinctReviewList' && (
            <AssessmentDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assessmentTypeAssessmentReviewList' && (
            <AssessmentTypeAssessmentDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assessmentNodeAssessmentReviewList' && (
            <AssessmentNodeAssessmentDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {(typeOfMiddlePaneList === 'assessmentItemReviewList' ||
            typeOfMiddlePaneList === 'assessmentSectionTrialDistinctReviewList') && (
            <AssessmentDistinctItemDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assessmentscalesReviewList' && (
            <AssessmentDistinctScaleDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assessmentsectionsReviewList' && (
            <AssessmentDistinctSectionDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assessmentversionsReviewList' && (
            <AssessmentDistinctVersionDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assessmentclustersReviewList' && (
            <AssessmentDistinctClusterDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assessmentnormsReviewList' && (
            <AssessmentDistinctNormsDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assessmentscoresReviewList' && (
            <AssessmentDistinctScoresDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assessmentgradesReviewList' && (
            <AssessmentDistinctGradesDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {(typeOfMiddlePaneList === 'assesseesGroupAssesseeReviewList' ||
            typeOfMiddlePaneList === 'administratorsGroupAssesseeReviewList' ||
            typeOfMiddlePaneList === 'assesseesGroupAssesseeReviewList' ||
            typeOfMiddlePaneList === 'managersGroupAssesseeReviewList ') && (
            <AssesseeGroupAssesseeDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assignmentDistinctAssesseeReviewList' && (
            <AssignmentDistinctAssesseeDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assignmentDistinctAssesseeGroupReviewList' && (
            <AssignmentDistinctAssesseeGroupReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assesseesReportReviewList' && (
            <AssesseeDistinctReportDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList' && (
            <AssignmentDistinctAssessmentDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assignmentDistinctAssessmentGroupReviewList' && (
            <AssignmentDistinctAssessmentGroupReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assignmentDistinctCultureProfileReviewList' && (
            <AssignmentDistinctCultureProfileDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assignmentDistinctCultureProfileGroupReviewList' && (
            <AssignmentDistinctCultureProfileGroupReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assignmentDistinctJobProfileGroupReviewList' && (
            <AssignmentDistinctJobProfileGroupReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assignmentDistinctJobProfileReviewList' && (
            <AssignmentDistinctJobProfileDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'associatesGroupAssociateReviewList' && (
            <AssociateGroupAssociateDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assesseesRoleAssesseeReviewList' && (
            <AssesseeRoleAssesseeDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assignmentDistinctAssignmentReference' && (
            <AssignmentDistinctAssignmentReferenceReviewList popupAllClose={popupAllClose} />
          )}
          {/* {typeOfMiddlePaneList === 'assignmentAssessmentNormReviewList' && (
          <AssignmentAssessmentNormDistinctReviewList popupAllClose={popupAllClose} />
        )} */}
          {typeOfMiddlePaneList === 'assesseesTypeAssesseeReviewList' && (
            <AssesseeTypeAssesseeDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assesseeGroupAssesseeAssignmentDistinct' && (
            <AssesseeGroupAssesseeAssignmentDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'associatesRoleAssociateReviewList' && (
            <AssociateRoleAssociateDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'nodeAssociatesReviewList' && (
            <NodeRelatedAssociateReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'associatesTypeAssociateReviewList' && (
            <AssociateTypeAssociateDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'associatesNodeDistinctReviewList' && (
            <IguruNodeReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'associateNodeDistinctReviewList' && (
            <AssociateNodeReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assesseesNodeAssesseeReviewList' && (
            <AssesseeNodeAssesseeDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assesseeassociatesReviewList' && (
            <AssesseeAssociateReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assesseesTypeDistinctReviewList' && (
            <AssesseeTypeReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'MarketplaceAssesseesTypeDistinctReviewList' && (
            <MarketplaceAssesseeTypeReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'associatesTypeDistinctReviewList' && (
            <AssociateTypeReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'itemsDistinctReviewList' && (
            <ItemReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'marketplaceItemsDistinctReviewList' && (
            <MarketplaceItemReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'itemsTypeDistinctReviewList' && (
            <ItemTypeReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'marketplaceItemsTypeDistinctReviewList' && (
            <MarketplaceItemTypeReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'itemsGroupDistinctReviewList' && (
            <ItemGroupReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'marketplaceItemsGroupDistinctReviewList' && (
            <MarketplaceItemGroupReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'itemGroupItemReviewList' && (
            <ItemGroupItemDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'itemNodeItemReviewList' && (
            <ItemNodeItemDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'marketplaceItemNodeItemReviewList' && (
            <MarketplaceItemNodeItemDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'itemTypeItemReviewList' && (
            <ItemTypeItemDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'cultureProfilesDistinctReviewList' && (
            <CultureProfileDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'marketPlaceCultureProfilesDistinctReviewList' && (
            <MarketPlaceCultureProfileDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'cultureProfilesGroupDistinctReviewList' && (
            <CultureProfileGroupReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'marketPlaceCultureProfilesGroupDistinctReviewList' && (
            <MarketPlaceCultureProfileGroupReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'MarketPlaceCultureProfileTypeReviewList' && (
            <MarketPlaceCultureProfileTypeReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'cultureProfilesTypeDistinctReviewList' && (
            <CultureProfileTypeReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'cultureProfileGroupCultureProfileReviewList' && (
            <CultureProfileGroupCultureProfileDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'cultureProfileTypeCultureProfileReviewList' && (
            <CultureProfileTypeCultureProfileDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'cultureProfileNodeCultureProfileReviewList' && (
            <CultureProfileNodeCultureProfileDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList ===
            'MarketPlaceCultureProfileNodeCultureProfileDistinctReviewList' && (
            <MarketPlaceCultureProfileNodeCultureProfileDistinctReviewList
              popupAllClose={popupAllClose}
            />
          )}
          {typeOfMiddlePaneList === 'cultureProfileAssessmentReviewList' && (
            <CultureProfileAssessmentDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'MarketplaceJobProfilesDistinctReviewList' && (
            <MarketplaceJobProfileDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'jobProfilesDistinctReviewList' && (
            <JobProfileDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'jobProfilesGroupDistinctReviewList' && (
            <JobProfileGroupReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'MarketplaceJobProfileGroupReviewList' && (
            <MarketplaceJobProfileGroupReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'jobProfilesTypeDistinctReviewList' && (
            <JobProfileTypeReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'MarketplaceJobProfilesTypeDistinctReviewList' && (
            <MarketPlaceJobProfileTypeReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'jobProfileGroupJobProfileReviewList' && (
            <JobProfileGroupJobProfileDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'jobProfileTypeJobProfileReviewList' && (
            <JobProfileTypeJobProfileDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'jobProfileNodeJobProfileReviewList' && (
            <JobProfileNodeJobProfileDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'jobProfileNodeMarketPlaceJobProfileReviewList' && (
            <JobProfileNodeJobProfileDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'jobProfilepAssessmentReviewList' && (
            <JobProfileAssessmentDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assessmentSectionItemDistinctReviewList' && (
            <AssessmentSectionItemDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assessmentSectionItemGroupDistinctReviewList' && (
            <AssessmentSectionItemGroupDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assesseeDistinctAssignmentDistinctReviewList' && (
            <AssesseeDistinctAssignmentReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assesseeAssignmentAssessmentReviewList' && (
            <AssesseeAssignmentAssessmentDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assessmentDistinctSubScaleDistinct' && (
            <AssessmentDistinctSubScaleDistinct popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assignmentReportReviewList' && (
            <AssignmentDistinctReportReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assignmentgaugesReviewList' && (
            <AssignmentDistinctGuageDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {/* {typeOfMiddlePaneList === 'assignmentDistinctAssignmentReference' && (
          <AssignmentDistinctAssignmentReferenceReviewList popupAllClose={popupAllClose} />
        )} */}
          {typeOfMiddlePaneList === 'assignmentDistinctMetricsDistinct' && (
            <AssignmentDistinctGaugeMetricsDistinct popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assignmentMeritListReportList' && (
            <AssignmentDistinctReportMetricReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'profilerDistinctReviewList' && (
            <ProfilerDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'cultureProfilerDistinctReviewList' && (
            <CultureProfilerDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'jobProfilerDistinctReviewList' && (
            <JobProfilerDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'cultureDimensionsDistinctReviewList' && (
            <CultureDimensionsDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'clusterDistinctReviewList' && (
            <ClusterDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'jobCometancyDistinctReviewList' && (
            <JobCompetancyDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'jobDomainDistinctReviewList' && (
            <JobDomainDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'jobFunctionDistinctReviewList' && (
            <JobFunctionDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'jobRoleDistinctReviewList' && (
            <JobRoleDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'gaugeDistinctReviewList' && (
            <GaugeDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'gaugeDistinctMetricDistinctReviewList' && (
            <GaugeDistinctMetricDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assignmentAssesseeAssessmentDistinctReviewList' && (
            <AssignmentAssesseeAssessmentDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'associateDistinctShareReviewList' && (
            <AssociateDistinctShareReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'AssesseeMarketPlaceDistinctReviewList' && (
            <AssesseeMarketPlaceDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'AssesseeGroupMineReviewList' && (
            <AssesseeGroupMineReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'MarketplaceAssesseeDistinctReviewList' && (
            <MarketplaceAssesseeDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'MarketplaceAssesseeGroupReviewList' && (
            <MarketplaceAssesseeGroupReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'MarketplaceAssesseeNodeReviewList' && (
            <MarketplaceAssesseeNodeReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'MarketplaceAssesseeRoleReviewList' && (
            <MarketplaceAssesseeRoleReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'MarketplaceAssesseeTypeassesseeReviewList' && (
            <MarketplaceAssesseeTypeAssesseeReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'MarketplaceAssessmentDistinctReviewList' && (
            <MarketplaceAssessmentDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'MarketplaceAssessmentGroupReviewList' && (
            <MarketplaceAssessmentGroupReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'MarketplaceAssessmentNodeReviewList' && (
            <MarketplaceAssessmentNodeReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'MarketplaceAssessmentTypeReviewList' && (
            <MarketplaceAssessmentTypeReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'MarketplaceAssignmentDistinctReviewList' && (
            <MarketplaceAssignmentDistinctReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'MarketplaceAssignmentGroupReviewList' && (
            <MarketplaceAssignmentGroupReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'MarketplaceAssignmentNodeReviewList' && (
            <MarketplaceAssignmentNodeReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'MarketplaceAssignmentTypeReviewList' && (
            <MarketplaceAssignmentTypeReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'AsseseeDistinctReportReviewList' && (
            <AsseseeDistinctReportReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assesseeSelfReportsReviewList' && (
            <AssesseeSelfReportReviewList popupAllClose={popupAllClose} />
          )}

          {typeOfMiddlePaneList === 'assesseeDistinctVideosReviewList' && (
            <AssesseeDistinctVideosReviewList popupAllClose={popupAllClose} />
          )}
          {typeOfMiddlePaneList === 'assesseeDistinctManuscriptReviewList' && (
            <AssesseeDistinctManuscriptReviewList popupAllClose={popupAllClose} />
          )}
        </div>
      </div>
    </div>
  );
};

export default DisplayPaneTwo;
