import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PopUpTextField from '../../PopUpInformation/PopUpTextField';
import PopUpConfirm from '../../PopUpGeneric/PopUpConfirm';
import PopUpDropList from '../../PopUpInformation/PopUpDropList'; //'../../PopUpInformation/PopUpDropList';
import PopUpCheckbox from '../../PopUpInformation/PopUpCheckbox';
import {
  POPUP_CLOSE,
  LOADER_START,
  SET_DISPLAY_THREE_SINGLE_STATE,
  SET_SECTION_REDUCER_STATE,
  CLEAR_CLUSTER_REDUCER_STATE,
  CREATE_ASSESSMENT_SECTION_SAGA,
  SET_ASSESSMENT_SECTION_AID_FRAMEWORK_STATE,
  SET_ASSESSMENT_SECTION_SCORE_FRAMEWORK_STATE,
  SET_ASSESSMENT_SECTION_RESPONCE_FRAMEWORK_STATE,
  SET_ASSESSMENT_SECTION_DYNAMIC_FRAMEWORK_STATE
} from '../../actionType';
import PopUpTextEditor from '../../PopUpIcon/PopUpTextEditor';
import PopUpPicture from '../../PopUpInformation/PopUpPicture';
import PopUpTimeFeild from '../../PopUpInformation/PopUpTimeFeild';
import PopUpTagSecondary from '../../PopUpInformation/PopUpTagSecondary';

const PopUpSectionCreate = (props) => {
  const { headerOne } = props;
  const { isPopUpValue, selectedTagValue } = useSelector((state) => state.PopUpReducer);
  const { sectionInformation } = useSelector((state) => state.SectionCreateReducer);
  const { reviewMode,responseObject } = useSelector((state) => state.DisplayPaneThreeReducer);
  const { selectedAssociateInfo } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { indexPointer } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { assessmentAdminSequence } = useSelector((state) => state.AssessmentReducer);
  const dispatch = useDispatch();
  const onClickCancelYes = () => {
    dispatch({
      type: SET_DISPLAY_THREE_SINGLE_STATE,
      payload: { stateName: 'createMode', value: '' }
    });
    dispatch({ type: CLEAR_CLUSTER_REDUCER_STATE });
    dispatch({ type: POPUP_CLOSE });
  };
  const onClickYes = () => {
    let requestObj = {
      module: 'Assessment-Distinct',
      action: 'Create',
      actionFilter: 'Section-Cluster-Scale',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        assessmentId: selectedTagValue,
        assessmentSectionFramework: sectionInformation
      }
    };
    console.log('requestObj', requestObj);
    dispatch({ type: POPUP_CLOSE });
    dispatch({ type: LOADER_START });
    dispatch({ type: CREATE_ASSESSMENT_SECTION_SAGA, payload: { requestObj } });
  };
  console.log('sectionInformation', sectionInformation);
  let tenureStartDate = 'mm/dd/yyyy --:-- --';
  let tenureEndDate = 'mm/dd/yyyy --:-- --';
  return (
    <div>
      {isPopUpValue === 'NAMEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'name'}
          actualLableValue={'assessmentSectionName'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'section'}
          headerOneBadgeTwo={'information'}
          nextPopUpValue={'ALIASPOPUP'}
          basicInfo={sectionInformation}
          typeOfSetObject={SET_SECTION_REDUCER_STATE}
          isRequired={true}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ALIASPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'description'}
          actualLableValue={'assessmentSectionDescription'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'section'}
          headerOneBadgeTwo={'information'}
          basicInfo={sectionInformation}
          nextPopUpValue={'PICTUREPOPUP'}
          typeOfSetObject={SET_SECTION_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'PICTUREPOPUP' && (
        <PopUpPicture
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'section'}
          headerOneBadgeTwo={'information'}
          nextPopUpValue={'CONFIRMATIONPOPUP'}
          actualLableValue={'assessmentSectionPicture'}
          actualLableValueVerification={'assessmentSectionPictureVerification'}
          basicInfo={sectionInformation}
          typeOfSetObject={SET_SECTION_REDUCER_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'CANCELPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'cancel'}
          headerOneBadgeOne={''}
          mode={'cancel'}
          onClickYes={onClickCancelYes}
        />
      )}
      {isPopUpValue === 'CONFIRMATIONPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'section'}
          headerOneBadgeTwo={'create'}
          onClickYes={onClickYes}
        />
      )}
      {isPopUpValue === 'AID_CAL_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentSectionAidCalculatorPermission'}
          label={'calculator'}
          labelBadgeOne={'permission'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          headerOneBadgeOne={'section'}
          headerOneBadgeTwo={'information'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={sectionInformation?.assessmentSectionAid}
          typeOfSetObject={SET_ASSESSMENT_SECTION_AID_FRAMEWORK_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'AID_SHEET_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentSectionAidSpreadsheetPermission'}
          label={'spredsheet'}
          labelBadgeOne={'permission'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          inputHeaderBadgeOne={''}
          inputHeaderBadgeTwo={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'section'}
          headerOneBadgeTwo={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={sectionInformation?.assessmentSectionAid}
          typeOfSetObject={SET_ASSESSMENT_SECTION_AID_FRAMEWORK_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'AID_TEXT_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentSectionAidTextsheetPermission'}
          label={'textsheet'}
          labelBadgeOne={'permission'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'section'}
          headerOneBadgeTwo={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={sectionInformation?.assessmentSectionAid}
          typeOfSetObject={SET_ASSESSMENT_SECTION_AID_FRAMEWORK_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'AID_CALCULATOR_TYPE_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentSectionAidCalculatorType'}
          label={'calculator'}
          labelBadgeOne={'type'}
          listSelect={[
            { id: 'basic', name: 'basic' },
            { id: 'business', name: 'business' },
            { id: 'financial', name: 'financial' },
            { id: 'scientific', name: 'scientific' }
          ]}
          mappingValue={'id'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'section'}
          headerOneBadgeTwo={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={sectionInformation?.assessmentSectionAid}
          typeOfSetObject={SET_ASSESSMENT_SECTION_AID_FRAMEWORK_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}

      {isPopUpValue === 'AID_SPREADSHEET_TYPE_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentSectionAidSpreadsheetType'}
          label={'spreadsheet'}
          labelBadgeOne={'type'}
          listSelect={[
            { id: 'goole', name: 'goole' },
            { id: 'microsoft', name: 'microsoft' },
            { id: 'spreadsheet', name: 'spreadsheet' }
          ]}
          mappingValue={'id'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'section'}
          headerOneBadgeTwo={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={sectionInformation?.assessmentSectionAid}
          typeOfSetObject={SET_ASSESSMENT_SECTION_AID_FRAMEWORK_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'AID_TEXTSHEET_TYPE_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentSectionAidTextsheetType'}
          label={'textsheet'}
          labelBadgeOne={'type'}
          listSelect={[
            { id: 'goole', name: 'goole' },
            { id: 'microsoft', name: 'microsoft' },
            { id: 'textsheet', name: 'textsheet' }
          ]}
          mappingValue={'id'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'section'}
          headerOneBadgeTwo={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={sectionInformation?.assessmentSectionAid}
          typeOfSetObject={SET_ASSESSMENT_SECTION_AID_FRAMEWORK_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'EVALUATION_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentSectionEvaluation'}
          label={'evaluation'}
          listSelect={[
            { id: false, name: 'No' },
            { id: true, name: 'Yes' }
          ]}
          mappingValue={'id'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'section'}
          headerOneBadgeTwo={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={sectionInformation}
          typeOfSetObject={SET_SECTION_REDUCER_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'SECTION_RESET_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentSectionAdministrationReset'}
          label={'reset'}
          listSelect={[
            { id: true, name: 'Yes' },
            { id: false, name: 'No' }
          ]}
          mappingValue={'id'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'section'}
          headerOneBadgeTwo={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={sectionInformation}
          typeOfSetObject={SET_SECTION_REDUCER_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'SECTION_REPEAT_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentSectionAdministrationRepeat'}
          label={'repeat'}
          listSelect={[
            { id: false, name: 'No' },
            { id: true, name: 'Yes' }
          ]}
          mappingValue={'id'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'section'}
          headerOneBadgeTwo={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={sectionInformation}
          typeOfSetObject={SET_SECTION_REDUCER_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'SCOREMINIMUMPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'score'}
          labelBadgeOne={'minimum'}
          type={'number'}
          actualLableValue={'assessmentSectionScoreMinimum'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'section'}
          headerOneBadgeTwo={'information'}
          basicInfo={sectionInformation?.assessmentSectionScore}
          nextPopUpValue={''}
          typeOfSetObject={SET_ASSESSMENT_SECTION_SCORE_FRAMEWORK_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'SCOREMAXIMUMPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'score'}
          labelBadgeOne={'maximum'}
          type={'number'}
          actualLableValue={'assessmentSectionScoreMaximum'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'section'}
          headerOneBadgeTwo={'information'}
          basicInfo={sectionInformation?.assessmentSectionScore}
          nextPopUpValue={''}
          typeOfSetObject={SET_ASSESSMENT_SECTION_SCORE_FRAMEWORK_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}

      {isPopUpValue === 'RESPONCE_EXTREEMINIMUMPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'responce'}
          labelBadgeOne={'minimum'}
          type={'number'}
          actualLableValue={'assessmentSectionResponseExtremumMinimum'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'section'}
          headerOneBadgeTwo={'information'}
          basicInfo={sectionInformation?.assessmentSectionResponse}
          nextPopUpValue={''}
          typeOfSetObject={SET_ASSESSMENT_SECTION_RESPONCE_FRAMEWORK_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'RESPONCE_EXTREEMAXIMUMPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'responce'}
          labelBadgeOne={'maximum'}
          type={'number'}
          actualLableValue={'assessmentSectionResponseExtremumMaximum'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'section'}
          headerOneBadgeTwo={'information'}
          basicInfo={sectionInformation?.assessmentSectionResponse}
          nextPopUpValue={''}
          typeOfSetObject={SET_ASSESSMENT_SECTION_RESPONCE_FRAMEWORK_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'RESPONSE_REVISE_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentSectionItemFrameworkOneResponseRevise'}
          label={'response'}
          labelBadgeOne={'revise'}
          listSelect={[
            { id: false, name: 'No' },
            { id: true, name: 'Yes' }
          ]}
          mappingValue={'id'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'section'}
          headerOneBadgeTwo={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={sectionInformation}
          typeOfSetObject={SET_SECTION_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'RESPONSE_UNIQUE_POPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentSectionItemFrameworkOneResponseUnique'}
          label={'response'}
          labelBadgeOne={'unique'}
          listSelect={[
            { id: true, name: 'Permitted' },
            { id: false, name: 'Unpermitted' }
          ]}
          mappingValue={'id'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'section'}
          headerOneBadgeTwo={'information'}
          isRequired={true}
          nextPopUpValue={''}
          basicInfo={sectionInformation}
          typeOfSetObject={SET_SECTION_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}

      {isPopUpValue === 'SEQUENCE_POPUP' && (
        <PopUpTextField
          isActive={true}
          label={'sequence'}
          actualLableValue={'assessmentSectionSequence'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'section'}
          headerOneBadgeTwo={'information'}
          basicInfo={sectionInformation}
          nextPopUpValue={'CONFIRMATIONPOPUP'}
          typeOfSetObject={SET_SECTION_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ASSESSMENT_COMMUNIQUE_PRIMARY_TEXTSHEET_POPUP' && (
        <PopUpTextEditor
          isActive={true}
          headerOne={headerOne}
          headerPanelColour={'genericOne'}
          headerOneBadgeOne={'section'}
          headerOneBadgeTwo={'communiqué'}
          headerOneBadgeThree={indexPointer}
          defaultSheetValue={
            sectionInformation?.assessmentSectionCommunique[indexPointer - 1] || ''
          }
          onClickSave={(data) => {
            let assessmentSectionCommunique = sectionInformation?.assessmentSectionCommunique;
            if (assessmentSectionCommunique[indexPointer - 1]) {
              if (data !== '') {
                assessmentSectionCommunique.splice(indexPointer - 1, 1, data);
              } else {
                assessmentSectionCommunique.splice(indexPointer - 1, 1);
              }
            } else if (data !== '') {
              assessmentSectionCommunique.push(data);
            } else {
            }
            dispatch({
              type: SET_ASSESSMENT_SECTION_DYNAMIC_FRAMEWORK_STATE,
              payload: {
                stateName: 'assessmentSectionCommunique',
                value: assessmentSectionCommunique
              }
            });
          }}
          actualLableValue={indexPointer - 1}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}

      {isPopUpValue === 'ASSESSMENT_MANUSCRIPT_PRIMARY_TEXTSHEET_POPUP' && (
        <PopUpTextEditor
          isActive={true}
          headerOne={headerOne}
          headerOneBadgeOne={'section'}
          headerOneBadgeTwo={'manuscript'}
          headerOneBadgeThree={indexPointer}
          headerPanelColour={'genericOne'}
          defaultSheetValue={
            sectionInformation?.assessmentSectionManuscript[indexPointer - 1] || ''
          }
          actualLableValue={indexPointer - 1}
          onClickSave={(data) => {
            let assessmentSectionManuscript = sectionInformation?.assessmentSectionManuscript;
            if (assessmentSectionManuscript[indexPointer - 1]) {
              if (data !== '') {
                assessmentSectionManuscript.splice(indexPointer - 1, 1, data);
              } else {
                assessmentSectionManuscript.splice(indexPointer - 1, 1);
              }
            } else if (data !== '') {
              assessmentSectionManuscript.push(data);
            } else {
            }
            dispatch({
              type: SET_ASSESSMENT_SECTION_DYNAMIC_FRAMEWORK_STATE,
              payload: {
                stateName: 'assessmentSectionManuscript',
                value: assessmentSectionManuscript
              }
            });
          }}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}

      {isPopUpValue === 'ASSESSMENT_SYNOPSIS_TEXTSHEET_POPUP' && (
        <PopUpTextEditor
          isActive={true}
          headerOne={headerOne}
          headerPanelColour={'genericOne'}
          headerOneBadgeOne={'section'}
          headerOneBadgeTwo={'synopsis'}
          headerOneBadgeThree={indexPointer}
          defaultSheetValue={sectionInformation?.assessmentSectionSynopsis[indexPointer - 1] || ''}
          actualLableValue={indexPointer - 1}
          onClickSave={(data) => {
            let assessmentSectionSynopsis = sectionInformation?.assessmentSectionSynopsis;
            if (assessmentSectionSynopsis[indexPointer - 1]) {
              if (data !== '') {
                assessmentSectionSynopsis.splice(indexPointer - 1, 1, data);
              } else {
                assessmentSectionSynopsis.splice(indexPointer - 1, 1);
              }
            } else if (data !== '') {
              assessmentSectionSynopsis.push(data);
            } else {
            }
            dispatch({
              type: SET_ASSESSMENT_SECTION_DYNAMIC_FRAMEWORK_STATE,
              payload: {
                stateName: 'assessmentSectionSynopsis',
                value: assessmentSectionSynopsis
              }
            });
          }}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TIMEASSESSMENT_SECTION_POPUP' && (
        <PopUpTimeFeild
          isActive={true}
          label={'time'}
          type={'time'}
          actualLableValue={'assessmentSectionTime'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'section'}
          headerOneBadgeTwo={'information'}
          basicInfo={sectionInformation}
          nextPopUpValue={''}
          typeOfSetObject={SET_SECTION_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          issetTime={true}
        />
      )}
      {isPopUpValue === 'ADMINSEWUENCEPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentSectionAdministrationSequence'}
          label={'sequence'}
          listSelect={assessmentAdminSequence}
          isMultiSelect={true}
          inputHeaderBadgeOne={''}
          inputHeaderBadgeTwo={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'section'}
          headerOneBadgeTwo={'information'}
          nextPopUpValue={''}
          basicInfo={sectionInformation}
          typeOfSetObject={SET_SECTION_REDUCER_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'NAVIGATIONPOPUP' && (
        <PopUpCheckbox
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'section'}
          headerOneBadgeTwo={'information'}
          inputHeader={'navigation'}
          inputHeaderBadge={'item'}
          typeOfStateObj={null}
          removeString={true}
          objectName={'assesseeRole'}
          stateName={'assessmentSectionItemNavigation'}
          valueArr={[
            'assessmentSectionItemNavigationFirst',
            'assessmentSectionItemNavigationLast',
            'assessmentSectionItemNavigationNext',
            'assessmentSectionItemNavigationPrevious',
            'assessmentSectionItemNavigationSkip'
          ]}
          nextPopUpValue={''}
          isRolePermission
          typeOfSetObject={SET_ASSESSMENT_SECTION_DYNAMIC_FRAMEWORK_STATE}
          valueArrState={sectionInformation?.assessmentSectionItemNavigation}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ASSESSMENT_SECTION_ITEM_LABEL_POPUP' && (
        <PopUpTextEditor
          isActive={true}
          headerOne={headerOne}
          headerPanelColour={'genericOne'}
          headerOneBadgeOne={'section'}
          headerOneBadgeTwo={'information'}
          label={'item'}
          labelBadgeOne={'label'}
          basicInfo={sectionInformation}
          defaultSheetValue={sectionInformation?.assessmentSectionItemFrameworkOneLabel || ''}
          // onClickSave={(data) => {
          //   dispatch({ type: SET_SECTION_REDUCER_STATE, payload: data });
          // }}
          typeOfSetObject={SET_SECTION_REDUCER_STATE}
          actualLableValue={'assessmentSectionItemFrameworkOneLabel'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ITEM_TOTAL_POPUP' && (
        <PopUpTextField
          isActive={true}
          label={'item'}
          labelBadgeOne={'total'}
          type={'number'}
          minimumValue={0}
          actualLableValue={'assessmentSectionItemTotal'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'section'}
          headerOneBadgeTwo={'information'}
          basicInfo={sectionInformation}
          nextPopUpValue={''}
          typeOfSetObject={SET_SECTION_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'RESPONSELABEL' && (
        <PopUpTextEditor
          isActive={true}
          headerOne={'section'}
          headerPanelColour={'genericOne'}
          headerOneBadgeOne={'section'}
          headerOneBadgeTwo={'information'}
          label={'response'}
          labelBadgeOne={'label'}
          basicInfo={sectionInformation}
          defaultSheetValue={
            sectionInformation?.assessmentSectionItemFrameworkOneResponseLabel || ''
          }
          typeOfSetObject={SET_SECTION_REDUCER_STATE}
          actualLableValue={'assessmentSectionItemFrameworkOneResponseLabel'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}

{isPopUpValue === 'TENURESATRTDATEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tenure'}
          labelBadgeOne={'start'}
          actualLableValue={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'section'}
          headerOneBadgeTwo={'information'}
          basicInfo={tenureStartDate}
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={SET_SECTION_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TENUREENDDATEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tenure'}
          labelBadgeOne={'end'}
          actualLableValue={''}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'section'}
          headerOneBadgeTwo={'information'}
          basicInfo={tenureEndDate}
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={SET_SECTION_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'STATUSPOPUP' && (
        <PopUpDropList
          isActive={true}
          tag={'assessmentStatus'}
          label={'status'}
          listSelect={[
            { id: 'Active', name: 'Active' },
            { id: 'Archived', name: 'Archived' },
            { id: 'Inactive', name: 'Inactive' },
            { id: 'Suspended', name: 'Suspended' },
            { id: 'Terminated', name: 'Terminated' }
          ]}
          mappingValue={'id'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'section'}
          headerOneBadgeTwo={'information'}
          isRequired={true}
          basicInfo={''}
          nextPopUpValue={''}
          typeOfSetObject={SET_SECTION_REDUCER_STATE}
          handleNextPopupValue={() => {}}
          isNotRevised={true}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TAGREADONLYPRIMARYPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'tag'}
          labelBadgeOne={'primary'}
          actualLableValue={'id'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'section'}
          headerOneBadgeTwo={'information'}
          basicInfo={ responseObject}
          nextPopUpValue={''}
          isNotRevised={true}
          typeOfSetObject={SET_SECTION_REDUCER_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'TAGSECONDARYPOPUP' && (
        <PopUpTagSecondary
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'section'}
          headerOneBadgeTwo={'information'}
          tagSecondary={'' || {}}
          signInSetup={'' || {}}
          nextPopUpValue={''}
          typeOfSetObject={SET_SECTION_REDUCER_STATE}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
    </div>
  );
};

export default PopUpSectionCreate;
