import { put, takeLatest, call } from 'redux-saga/effects';
import { convertSecondsToHMmSs } from '../../Actions/GenericActions';
import {
  SET_DISPLAY_PANE_THREE_STATE,
  LOADER_STOP,
  GET_ASSESSMENT_INFO_SAGA,
  GET_ASSESSMENT_SEC_INFO_SAGA,
  SET_ASSESSMENT_BASIC_REDUCER_STATE,
  ASSESSMENT_INFO_REVISE_SAGA,
  SET_ASSESSMENT_DYNAMIC_SINGLE_STATE,
  SET_POPUP_VALUE,
  SET_DISPLAY_TWO_SINGLE_STATE,
  ASSESSMENT_REVIEW_DISTINCT_SAGA,
  SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
  ASSESSMENT_PUBLISH_SAGA,
  SET_MIDDLEPANE_STATE,
  RELATED_REVIEWLIST_DISTINCT_DATA,
  ASSESSMENT_INFO_PREVIEW_SAGA,
  GET_ASSIGNMENT_ASSESSMENT_INFO_SAGA,
  ASSESSMENT_MULTI_STATUS_REVISE_SAGA,
  GET_ASSESSMENT_GROUP_REVIEW_LIST_SAGA,
  GET_ASSESSMENT_TYPE_REVIEW_LIST_SAGA,
  LOADER_START,
  SET_ASSESSMENT_FRAMEWORK_STATE,
  ASSESSMENT_CLUSTER_REVISE_SAGA,
  SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
  SET_PANE_THREE_ASSESSMENT_PREVIEW_MODE,
  ASSESSMENT_REVISE_SAGA,
  SET_DISPLAY_THREE_SINGLE_STATE,
  GET_ASSESSMENTGROUP_ASSESSMENT_REVIEWLIST_SAGA,
  GET_ASSESSMENTTYPE_ASSESSMENT_REVIEWLIST_SAGA,
  SET_PAGE_COUNT,
  SET_POPUP_SINGLE_STATE,
  UPDATE_ASSESSMENT_SETUP_PRIMARY_INFO
} from '../../actionType';
import { ASSESSMENT_MODULE_URL } from '../../endpoints';
import { EXCEPTION_ERROR_MESSAGE } from '../../errorMessage';
import Store from '../../store';

const assessmentInfoApi = async (requestObj) => {
  let URL = requestObj.URL;
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      Authorization: localStorage.getItem('token')
    }),
    body: JSON.stringify(requestObj.data)
  };
  const response = await fetch(URL, requestOptions);
  const json = await response.json();
  return json;
};

function* workerReviewInfoAssignmentAssessmentSaga(data) {
  try {
    const userResponse = yield call(assessmentInfoApi, {
      data: data.payload.reqBody,
      URL: ASSESSMENT_MODULE_URL
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === '000') {
      const { isReviseMode = false, createMode } = data.payload;
      yield put({
        type: SET_DISPLAY_PANE_THREE_STATE,
        payload: {
          headerOne: 'assessment',
          headerOneBadgeOne: 'distinct',
          headerOneBadgeTwo: 'information',
          headerOneBadgeThree: data.payload.secondaryOptionCheckValue,
          responseObject: userResponse.responseObject[0],
          reviewMode: isReviseMode ? 'revise' : '',
          createMode
        }
      });
      // if (isReviseMode) {
      const { informationBasic, informationAllocation, informationFramework, informationSetup } =
        userResponse.responseObject[0];
      yield put({
        type: SET_ASSESSMENT_FRAMEWORK_STATE,
        payload: informationFramework
      });
      yield put({
        type: UPDATE_ASSESSMENT_SETUP_PRIMARY_INFO,
        payload: informationSetup
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: {
          stateName: 'assessmentResponseObject',
          value: userResponse.responseObject[0]
        }
      });
      yield put({
        type: SET_ASSESSMENT_BASIC_REDUCER_STATE,
        payload: informationBasic
      });
      if (
        informationAllocation &&
        informationAllocation?.assessmentGroup?.assessmentGroupPrimary &&
        informationAllocation?.assessmentGroup?.assessmentGroupPrimary.length > 0
      ) {
        let tempArr = informationAllocation.assessmentGroup.assessmentGroupPrimary.map(
          (ob) => ob.id
        );
        yield put({
          type: SET_ASSESSMENT_DYNAMIC_SINGLE_STATE,
          payload: {
            stateName: 'assessmentGroup',
            actualStateName: 'assessmentGroupPrimary',
            value: tempArr
          }
        });
      } else {
        yield put({
          type: SET_ASSESSMENT_DYNAMIC_SINGLE_STATE,
          payload: {
            stateName: 'assessmentGroup',
            actualStateName: 'assessmentGroupPrimary',
            value: []
          }
        });
      }
      if (
        informationAllocation &&
        informationAllocation?.assessmentGroup?.assessmentGroupSecondary &&
        informationAllocation?.assessmentGroup?.assessmentGroupSecondary.length > 0
      ) {
        let tempArr = informationAllocation.assessmentGroup.assessmentGroupSecondary.map(
          (ob) => ob.id
        );
        yield put({
          type: SET_ASSESSMENT_DYNAMIC_SINGLE_STATE,
          payload: {
            stateName: 'assessmentGroup',
            actualStateName: 'assessmentGroupSecondary',
            value: tempArr
          }
        });
      } else {
        yield put({
          type: SET_ASSESSMENT_DYNAMIC_SINGLE_STATE,
          payload: {
            stateName: 'assessmentGroup',
            actualStateName: 'assessmentGroupSecondary',
            value: []
          }
        });
      }
      if (
        informationAllocation &&
        informationAllocation?.assessmentNode?.assessmentNodePrimary &&
        informationAllocation?.assessmentNode?.assessmentNodePrimary.length > 0
      ) {
        let tempArr = informationAllocation.assessmentNode.assessmentNodePrimary.map((ob) => ob.id);
        yield put({
          type: SET_ASSESSMENT_DYNAMIC_SINGLE_STATE,
          payload: {
            stateName: 'assessmentNode',
            actualStateName: 'assessmentNodePrimary',
            value: tempArr
          }
        });
      } else {
        yield put({
          type: SET_ASSESSMENT_DYNAMIC_SINGLE_STATE,
          payload: {
            stateName: 'assessmentNode',
            actualStateName: 'assessmentNodePrimary',
            value: []
          }
        });
      }
      if (
        informationAllocation &&
        informationAllocation?.assessmentNode?.assessmentNodeSecondary &&
        informationAllocation?.assessmentNode?.assessmentNodeSecondary.length > 0
      ) {
        let tempArr = informationAllocation.assessmentNode.assessmentNodeSecondary.map(
          (ob) => ob.id
        );
        yield put({
          type: SET_ASSESSMENT_DYNAMIC_SINGLE_STATE,
          payload: {
            stateName: 'assessmentNode',
            actualStateName: 'assessmentNodeSecondary',
            value: tempArr
          }
        });
      } else {
        yield put({
          type: SET_ASSESSMENT_DYNAMIC_SINGLE_STATE,
          payload: {
            stateName: 'assessmentNode',
            actualStateName: 'assessmentNodeSecondary',
            value: []
          }
        });
      }
      if (
        informationAllocation &&
        informationAllocation?.assessmentType?.assessmentTypePrimary &&
        informationAllocation?.assessmentType?.assessmentTypePrimary.length > 0
      ) {
        let tempArr = informationAllocation.assessmentType.assessmentTypePrimary.map((ob) => ob.id);
        yield put({
          type: SET_ASSESSMENT_DYNAMIC_SINGLE_STATE,
          payload: {
            stateName: 'assessmentType',
            actualStateName: 'assessmentTypePrimary',
            value: tempArr
          }
        });
      } else {
        yield put({
          type: SET_ASSESSMENT_DYNAMIC_SINGLE_STATE,
          payload: {
            stateName: 'assessmentType',
            actualStateName: 'assessmentTypePrimary',
            value: []
          }
        });
      }
      if (
        informationAllocation &&
        informationAllocation?.assessmentType?.assessmentTypeSecondary &&
        informationAllocation?.assessmentType?.assessmentTypeSecondary.length > 0
      ) {
        let tempArr = informationAllocation.assessmentType.assessmentTypeSecondary.map(
          (ob) => ob.id
        );
        yield put({
          type: SET_ASSESSMENT_DYNAMIC_SINGLE_STATE,
          payload: {
            stateName: 'assessmentType',
            actualStateName: 'assessmentTypeSecondary',
            value: tempArr
          }
        });
      } else {
        yield put({
          type: SET_ASSESSMENT_DYNAMIC_SINGLE_STATE,
          payload: {
            stateName: 'assessmentType',
            actualStateName: 'assessmentTypeSecondary',
            value: []
          }
        });
      }
      const communiqueObject = informationFramework?.assessmentCommunique || [];
      yield put({
        type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
        payload: { stateName: 'assessmentCommunique', value: communiqueObject }
      });
      let assessmentItem = informationFramework.assessmentItemDistinct.map(
        (ob) => ob.itemTagPrimary
      );
      yield put({
        type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
        payload: { stateName: 'assessmentItemDistinct', value: assessmentItem }
      });
      let assessmentItemTrial = informationFramework.assessmentItemTrial.map(
        (ob) => ob.itemTagPrimary
      );
      yield put({
        type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
        payload: { stateName: 'assessmentItemTrial', value: assessmentItemTrial }
      });
      const timeAssessment = convertSecondsToHMmSs(informationFramework?.assessmentTime) || 0;
      yield put({
        type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
        payload: { stateName: 'assessmentTime', value: timeAssessment }
      });
      const menuScriptAssessment = informationFramework?.assessmentManuscript || [];
      yield put({
        type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
        payload: { stateName: 'assessmentManuscript', value: menuScriptAssessment }
      });

      const assessmentSynopsis = informationFramework?.assessmentSynopsis || [];
      yield put({
        type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
        payload: { stateName: 'assessmentSynopsis', value: assessmentSynopsis }
      });

      let sectionArr = [];
      informationFramework?.assessmentSectionFramework.forEach((sec) => {
        let versionArr = [];
        sec.assessmentVersionFramework.forEach((ver) => {
          let tempArr = [];
          if (ver.assessmentVersionItemDistinct) {
            for (let i = 0; i < ver.assessmentVersionItemDistinct?.length; i++) {
              tempArr.push({
                itemTagPrimary: ver.assessmentVersionItemDistinct[i].itemTagPrimary,
                itemSequence: ver.assessmentVersionItemDistinct[i].itemSequence
              });
            }
          }
          let reviseObj = { ...ver, assessmentVersionItemDistinct: tempArr };
          versionArr.push(reviseObj);
        });
        let reviseObj2 = { ...sec, assessmentVersionFramework: versionArr };
        sectionArr.push(reviseObj2);
      });
      yield put({
        type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
        payload: { stateName: 'assessmentSectionFramework', value: sectionArr }
      });
      const assessmentScaleFramework = informationFramework?.assessmentScaleFramework || [];

      yield put({
        type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
        payload: { stateName: 'assessmentScaleFramework', value: assessmentScaleFramework }
      });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerReviewInfoAssessmentSaga(data) {
  try {
    const userResponse = yield call(assessmentInfoApi, {
      data: data.payload.reqBody,
      URL: ASSESSMENT_MODULE_URL
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === '000') {
      let assessmentInfo = userResponse.responseObject[0];

      const { isReviseMode = false } = data.payload;
      yield put({
        type: SET_DISPLAY_PANE_THREE_STATE,
        payload: {
          headerOne: 'assessment',
          headerOneBadgeOne: 'distinct',
          headerOneBadgeTwo: 'information',
          headerOneBadgeThree: data.payload.secondaryOptionCheckValue,
          responseObject: userResponse.responseObject[0],
          reviewMode: isReviseMode ? 'revise' : '',
          createMode: data.payload.createMode
        }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: {
          stateName: 'assessmentResponseObject',
          value: assessmentInfo
        }
      });
      // if (isReviseMode) {
      const { informationBasic, informationAllocation, informationFramework, informationSetup } =
        userResponse.responseObject[0];
      yield put({
        type: SET_ASSESSMENT_BASIC_REDUCER_STATE,
        payload: informationBasic
      });
      yield put({
        type: SET_ASSESSMENT_FRAMEWORK_STATE,
        payload: informationFramework
      });
      yield put({
        type: UPDATE_ASSESSMENT_SETUP_PRIMARY_INFO,
        payload: informationSetup
      });
      if (
        informationAllocation &&
        informationAllocation?.assessmentGroup?.assessmentGroupPrimary &&
        informationAllocation?.assessmentGroup?.assessmentGroupPrimary.length > 0
      ) {
        let tempArr = informationAllocation.assessmentGroup.assessmentGroupPrimary.map(
          (ob) => ob.id
        );
        yield put({
          type: SET_ASSESSMENT_DYNAMIC_SINGLE_STATE,
          payload: {
            stateName: 'assessmentGroup',
            actualStateName: 'assessmentGroupPrimary',
            value: tempArr
          }
        });
      } else {
        yield put({
          type: SET_ASSESSMENT_DYNAMIC_SINGLE_STATE,
          payload: {
            stateName: 'assessmentGroup',
            actualStateName: 'assessmentGroupPrimary',
            value: []
          }
        });
      }
      if (
        informationAllocation &&
        informationAllocation?.assessmentGroup?.assessmentGroupSecondary &&
        informationAllocation?.assessmentGroup?.assessmentGroupSecondary.length > 0
      ) {
        let tempArr = informationAllocation.assessmentGroup.assessmentGroupSecondary.map(
          (ob) => ob.id
        );
        yield put({
          type: SET_ASSESSMENT_DYNAMIC_SINGLE_STATE,
          payload: {
            stateName: 'assessmentGroup',
            actualStateName: 'assessmentGroupSecondary',
            value: tempArr
          }
        });
      } else {
        yield put({
          type: SET_ASSESSMENT_DYNAMIC_SINGLE_STATE,
          payload: {
            stateName: 'assessmentGroup',
            actualStateName: 'assessmentGroupSecondary',
            value: []
          }
        });
      }
      if (
        informationAllocation &&
        informationAllocation?.assessmentNode?.assessmentNodePrimary &&
        informationAllocation?.assessmentNode?.assessmentNodePrimary.length > 0
      ) {
        let tempArr = informationAllocation.assessmentNode.assessmentNodePrimary.map((ob) => ob.id);
        yield put({
          type: SET_ASSESSMENT_DYNAMIC_SINGLE_STATE,
          payload: {
            stateName: 'assessmentNode',
            actualStateName: 'assessmentNodePrimary',
            value: tempArr
          }
        });
      } else {
        yield put({
          type: SET_ASSESSMENT_DYNAMIC_SINGLE_STATE,
          payload: {
            stateName: 'assessmentNode',
            actualStateName: 'assessmentNodePrimary',
            value: []
          }
        });
      }
      if (
        informationAllocation &&
        informationAllocation?.assessmentNode?.assessmentNodeSecondary &&
        informationAllocation?.assessmentNode?.assessmentNodeSecondary.length > 0
      ) {
        let tempArr = informationAllocation.assessmentNode.assessmentNodeSecondary.map(
          (ob) => ob.id
        );
        yield put({
          type: SET_ASSESSMENT_DYNAMIC_SINGLE_STATE,
          payload: {
            stateName: 'assessmentNode',
            actualStateName: 'assessmentNodeSecondary',
            value: tempArr
          }
        });
      } else {
        yield put({
          type: SET_ASSESSMENT_DYNAMIC_SINGLE_STATE,
          payload: {
            stateName: 'assessmentNode',
            actualStateName: 'assessmentNodeSecondary',
            value: []
          }
        });
      }
      if (
        informationAllocation &&
        informationAllocation?.assessmentType?.assessmentTypePrimary &&
        informationAllocation?.assessmentType?.assessmentTypePrimary.length > 0
      ) {
        let tempArr = informationAllocation.assessmentType.assessmentTypePrimary.map((ob) => ob.id);
        yield put({
          type: SET_ASSESSMENT_DYNAMIC_SINGLE_STATE,
          payload: {
            stateName: 'assessmentType',
            actualStateName: 'assessmentTypePrimary',
            value: tempArr
          }
        });
      } else {
        yield put({
          type: SET_ASSESSMENT_DYNAMIC_SINGLE_STATE,
          payload: {
            stateName: 'assessmentType',
            actualStateName: 'assessmentTypePrimary',
            value: []
          }
        });
      }
      if (
        informationAllocation &&
        informationAllocation?.assessmentType?.assessmentTypeSecondary &&
        informationAllocation?.assessmentType?.assessmentTypeSecondary.length > 0
      ) {
        let tempArr = informationAllocation.assessmentType.assessmentTypeSecondary.map(
          (ob) => ob.id
        );
        yield put({
          type: SET_ASSESSMENT_DYNAMIC_SINGLE_STATE,
          payload: {
            stateName: 'assessmentType',
            actualStateName: 'assessmentTypeSecondary',
            value: tempArr
          }
        });
      } else {
        yield put({
          type: SET_ASSESSMENT_DYNAMIC_SINGLE_STATE,
          payload: {
            stateName: 'assessmentType',
            actualStateName: 'assessmentTypeSecondary',
            value: []
          }
        });
      }
      const communiqueObject = informationFramework?.assessmentCommunique || [];
      yield put({
        type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
        payload: { stateName: 'assessmentCommunique', value: communiqueObject }
      });
      let assessmentItem = informationFramework.assessmentItemDistinct.map(
        (ob) => ob.itemTagPrimary
      );
      yield put({
        type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
        payload: { stateName: 'assessmentItemDistinct', value: assessmentItem }
      });
      let assessmentItemTrial = informationFramework.assessmentItemTrial.map(
        (ob) => ob.itemTagPrimary
      );
      yield put({
        type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
        payload: { stateName: 'assessmentItemTrial', value: assessmentItemTrial }
      });
      let assessmentNorm = informationFramework.assessmentNormFramework.map((ob) => ob.id);
      yield put({
        type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
        payload: { stateName: 'assessmentNormFramework', value: assessmentNorm }
      });
      // const scoreObject = informationFramework?.assessmentScore || {
      //   assessmentScoreMaximum: 0,
      //   assessmentScoreMinimum: 0
      // };
      // yield put({ type: SET_ASSESSMENT_SCORE_FRAMEWORK_STATE, payload: scoreObject });
      const timeAssessment = informationFramework?.assessmentTime || 0;
      yield put({
        type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
        payload: { stateName: 'assessmentTime', value: timeAssessment }
      });
      const menuScriptAssessment = informationFramework?.assessmentManuscript || [];
      yield put({
        type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
        payload: { stateName: 'assessmentManuscript', value: menuScriptAssessment }
      });

      const assessmentSynopsis = informationFramework?.assessmentSynopsis || [];
      yield put({
        type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
        payload: { stateName: 'assessmentSynopsis', value: assessmentSynopsis }
      });

      let sectionArr = [];
      informationFramework?.assessmentSectionFramework?.forEach((sec) => {
        let versionArr = [];
        sec.assessmentVersionFramework.forEach((ver) => {
          let tempArr = [];
          if (ver.assessmentVersionItemDistinct) {
            for (let i = 0; i < ver.assessmentVersionItemDistinct?.length; i++) {
              tempArr.push({
                itemTagPrimary: ver.assessmentVersionItemDistinct[i].itemTagPrimary,
                itemSequence: ver.assessmentVersionItemDistinct[i].itemSequence
              });
            }
          }
          let reviseObj = { ...ver, assessmentVersionItemDistinct: tempArr };
          versionArr.push(reviseObj);
        });

        let reviseObj2 = { ...sec, assessmentVersionFramework: versionArr };
        sectionArr.push(reviseObj2);
      });
      yield put({
        type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
        payload: { stateName: 'assessmentSectionFramework', value: sectionArr }
      });
      const assessmentScaleFramework = informationFramework?.assessmentScaleFramework || [];

      yield put({
        type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
        payload: { stateName: 'assessmentScaleFramework', value: assessmentScaleFramework }
      });

      // yield put({
      //   type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
      //   payload: {
      //     stateName: 'assessmentSectionItemDistinctRevise',
      //     value: assessmentSectionFramework[0].assessmentSectionItemDistinct[0]
      //   }
      // });

      // setItemTypeConfigState(
      //   assessmentSectionFramework[0].assessmentSectionItemDistinct[0].itemFrameworkOne
      //     ?.itemFrameworkOneType,
      //   yield put
      // );
      // const itemFrameworkOneType =
      //   assessmentSectionFramework[0].assessmentSectionItemDistinct[0].itemFrameworkOne
      //     ?.itemFrameworkOneType;
      // let reviseSetting = {
      //   blankState: true,
      //   classificationState: false,
      //   levelState: true,
      //   polarityState: true,
      //   scaleState: true,
      //   scoreState: true,
      //   timeState: true,
      //   weightageState: true,
      //   noOfItemState: true,
      //   noOfResponseState: true
      // };
      // if (itemFrameworkOneType === '61090cace50cf61d5eb440c9') {
      //   // "Rating-Scale (Multiple-Select)"
      //   reviseSetting = {
      //     blankState: false,
      //     classificationState: false,
      //     levelState: true,
      //     polarityState: true,
      //     scaleState: true,
      //     scoreState: true,
      //     timeState: true,
      //     weightageState: false,
      //     noOfItemState: true,
      //     noOfResponseState: false
      //   };
      // }
      // if (itemFrameworkOneType === '61090cace50cf61d5eb440ce') {
      //   //"Response-Choice (Single-Select)"
      //   reviseSetting = {
      //     blankState: false,
      //     classificationState: false,
      //     levelState: true,
      //     polarityState: false,
      //     scaleState: false,
      //     scoreState: true,
      //     timeState: true,
      //     weightageState: false,
      //     noOfItemState: false,
      //     noOfResponseState: true
      //   };
      // }
      // if (itemFrameworkOneType === '61090cace50cf61d5eb440c4') {
      //   //"Fill-in-the-Blank (Response-Choice)"
      //   reviseSetting = {
      //     blankState: true,
      //     classificationState: false,
      //     levelState: true,
      //     polarityState: false,
      //     scaleState: false,
      //     scoreState: true,
      //     timeState: true,
      //     weightageState: false,
      //     noOfItemState: false,
      //     noOfResponseState: true
      //   };
      // }
      // if (
      //   itemFrameworkOneType === '61090cace50cf61d5eb440cc' ||
      //   itemFrameworkOneType === '61090cace50cf61d5eb440cd'
      // ) {
      //   //"Response (Long) (Short)"
      //   reviseSetting = {
      //     blankState: false,
      //     classificationState: false,
      //     levelState: true,
      //     polarityState: false,
      //     scaleState: false,
      //     scoreState: true,
      //     timeState: true,
      //     weightageState: false,
      //     noOfItemState: false,
      //     noOfResponseState: false
      //   };
      // }
      // yield put({
      //   type: SET_DISPLAY_TWO_SINGLE_STATE,
      //   payload: { stateName: 'itemConfigStates', value: reviseSetting }
      // });
      // const assessmentSection = userResponse.responseObject[0].informationFramework?.assessmentSection || [];
      //   yield put({
      //     type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
      //     payload: { stateName: 'assessmentSection', value: assessmentSection }
      //   });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
const assessmentReviseInfoApi = async (requestObj) => {
  let URL = ASSESSMENT_MODULE_URL;
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      Authorization: localStorage.getItem('token')
    }),
    body: JSON.stringify(requestObj.data)
  };
  const response = await fetch(URL, requestOptions);
  const json = await response.json();
  return json;
};
function* workerReviewInfoAssessmentSecSaga(data) {
  try {
    const userResponse = yield call(assessmentInfoApi, {
      data: data.payload.reqBody,
      URL: ASSESSMENT_MODULE_URL
    });
    if (userResponse.responseCode === '000') {
      let assessmentInfo = userResponse.responseObject[0];

      yield put({
        type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
        payload: {
          stateName: 'assessmentScaleFramework',
          value: assessmentInfo.informationFramework.assessmentScaleFramework
        }
      });
      let informationAllocation = assessmentInfo.informationAllocation;
      yield put({
        type: SET_ASSESSMENT_BASIC_REDUCER_STATE,
        payload: assessmentInfo?.informationBasic
      });
      yield put({
        type: SET_ASSESSMENT_FRAMEWORK_STATE,
        payload: assessmentInfo?.informationFramework
      });
      yield put({
        type: UPDATE_ASSESSMENT_SETUP_PRIMARY_INFO,
        payload: assessmentInfo?.informationSetup
      });
      let assessmentItem =
        assessmentInfo?.informationFramework.assessmentItemDistinct.map(
          (ob) => ob.itemTagPrimary
        ) || [];
      yield put({
        type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
        payload: { stateName: 'assessmentItemDistinct', value: assessmentItem }
      });
      let assessmentItemTrial = assessmentInfo?.informationFramework.assessmentItemTrial.map(
        (ob) => ob.itemTagPrimary
      );
      yield put({
        type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
        payload: { stateName: 'assessmentItemTrial', value: assessmentItemTrial }
      });
      let assessmentNorm = assessmentInfo.informationFramework.assessmentNormFramework.map(
        (ob) => ob.id
      );
      yield put({
        type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
        payload: { stateName: 'assessmentNormFramework', value: assessmentNorm }
      });
      yield put({
        type: SET_ASSESSMENT_DYNAMIC_SINGLE_STATE,
        payload: {
          stateName: 'assessmentGroup',
          actualStateName: 'assessmentGroupPrimary',
          value:
            informationAllocation.assessmentGroup.assessmentGroupPrimary?.map((ob) => ob.id) || []
        }
      });
      yield put({
        type: SET_ASSESSMENT_DYNAMIC_SINGLE_STATE,
        payload: {
          stateName: 'assessmentGroup',
          actualStateName: 'assessmentGroupSecondary',
          value:
            informationAllocation.assessmentGroup.assessmentGroupSecondary?.map((ob) => ob.id) || []
        }
      });

      yield put({
        type: SET_ASSESSMENT_DYNAMIC_SINGLE_STATE,
        payload: {
          stateName: 'assessmentNode',
          actualStateName: 'assessmentNodePrimary',
          value:
            informationAllocation.assessmentNode.assessmentNodePrimary?.map((ob) => ob.id) || []
        }
      });
      yield put({
        type: SET_ASSESSMENT_DYNAMIC_SINGLE_STATE,
        payload: {
          stateName: 'assessmentNode',
          actualStateName: 'assessmentNodeSecondary',
          value:
            informationAllocation.assessmentNode.assessmentGroupSecondary?.map((ob) => ob.id) || []
        }
      });
      yield put({
        type: SET_ASSESSMENT_DYNAMIC_SINGLE_STATE,
        payload: {
          stateName: 'assessmentType',
          actualStateName: 'assessmentTypePrimary',
          value:
            informationAllocation.assessmentType.assessmentTypePrimary?.map((ob) => ob.id) || []
        }
      });
      yield put({
        type: SET_ASSESSMENT_DYNAMIC_SINGLE_STATE,
        payload: {
          stateName: 'assessmentType',
          actualStateName: 'assessmentTypeSecondary',
          value:
            informationAllocation.assessmentType.assessmentGroupSecondary?.map((ob) => ob.id) || []
        }
      });
      let sectionArr = [];
      assessmentInfo.informationFramework?.assessmentSectionFramework.forEach((sec) => {
        let versionArr = [];
        sec.assessmentVersionFramework.forEach((ver) => {
          let tempArr = [];
          if (ver.assessmentVersionItemDistinct) {
            for (let i = 0; i < ver.assessmentVersionItemDistinct?.length; i++) {
              tempArr.push({
                itemTagPrimary: ver.assessmentVersionItemDistinct[i].itemTagPrimary,
                itemSequence: ver.assessmentVersionItemDistinct[i].itemSequence
              });
            }
          }
          let reviseObj = { ...ver, assessmentVersionItemDistinct: tempArr };
          versionArr.push(reviseObj);
        });
        let reviseObj2 = { ...sec, assessmentVersion: versionArr };
        sectionArr.push(reviseObj2);
      });
      yield put({
        type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
        payload: { stateName: 'assessmentSectionFramework', value: sectionArr }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: {
          stateName: 'assessmentResponseObject',
          value: assessmentInfo
        }
      });
      yield put({
        type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
        payload: {
          stateName: 'assessmentClusterFramework',
          value: assessmentInfo.informationFramework.assessmentClusterFramework
        }
      });
      let BadgeOne = 'scales';
      let reviseResponseObj = {
        countTotal: assessmentInfo?.informationFramework?.assessmentScaleFramework?.length || 0,
        responseObject: [
          {
            scales: assessmentInfo?.informationFramework?.assessmentScaleFramework || [],
            assessmentName: assessmentInfo.informationBasic.assessmentName,
            assessmentDescription: assessmentInfo.informationBasic.assessmentDescription,
            assessmentStatus: assessmentInfo.informationEngagement.assessmentStatus,
            id: assessmentInfo.id,
            assessmentId: assessmentInfo.id
          }
        ]
      };
      if (data.payload.typeOfMiddlePaneList === 'assessmentsectionsReviewList') {
        let array = assessmentInfo?.informationFramework?.assessmentSectionFramework || [];
        BadgeOne = 'sections';
        reviseResponseObj = {
          countTotal: array?.length || 0,
          responseObject: [
            {
              sections: array || [],
              assessmentName: assessmentInfo.informationBasic.assessmentName,
              assessmentDescription: assessmentInfo.informationBasic.assessmentDescription,
              assessmentStatus: assessmentInfo.informationEngagement.assessmentStatus,
              id: assessmentInfo.id,
              assessmentId: assessmentInfo.id
            }
          ]
        };
      }
      if (data.payload.typeOfMiddlePaneList === 'assessmentclustersReviewList') {
        BadgeOne = 'clusters';
        reviseResponseObj = {
          countTotal: assessmentInfo?.informationFramework?.assessmentClusterFramework?.length || 0,
          responseObject: [
            {
              clusters: assessmentInfo?.informationFramework?.assessmentClusterFramework || [],
              assessmentName: assessmentInfo.informationBasic.assessmentName,
              assessmentDescription: assessmentInfo.informationBasic.assessmentDescription,
              assessmentStatus: assessmentInfo.informationEngagement.assessmentStatus,
              id: assessmentInfo.id,
              assessmentId: assessmentInfo.id
            }
          ]
        };
      }
      if (data.payload.typeOfMiddlePaneList === 'assessmentversionsReviewList') {
        BadgeOne = 'versions';
        reviseResponseObj = {
          countTotal:
            assessmentInfo?.informationFramework?.assessmentSectionFramework[0]
              .assessmentVersionFramework?.length || 0,
          responseObject: [
            {
              versions:
                assessmentInfo?.informationFramework?.assessmentSectionFramework[0]
                  ?.assessmentVersionFramework || [],
              assessmentName: assessmentInfo.informationBasic.assessmentName,
              assessmentDescription: assessmentInfo.informationBasic.assessmentDescription,
              assessmentStatus: assessmentInfo.informationEngagement.assessmentStatus,
              id: assessmentInfo.id,
              assessmentId: assessmentInfo.id
            }
          ]
        };
      }
      if (data.payload.typeOfMiddlePaneList === 'assessmentnormsReviewList') {
        BadgeOne = 'norms';
        reviseResponseObj = {
          countTotal: assessmentInfo?.informationFramework?.assessmentNormFramework?.length || 0,
          responseObject: [
            {
              norms: assessmentInfo?.informationFramework?.assessmentNormFramework || [],
              assessmentName: assessmentInfo.informationBasic.assessmentName,
              assessmentDescription: assessmentInfo.informationBasic.assessmentDescription,
              assessmentStatus: assessmentInfo.informationEngagement.assessmentStatus,
              id: assessmentInfo.id,
              assessmentId: assessmentInfo.id
            }
          ]
        };
      }
      if (data.payload.typeOfMiddlePaneList === 'assessmentscoresReviewList') {
        BadgeOne = 'scores';
        reviseResponseObj = {
          countTotal: assessmentInfo?.informationFramework?.assessmentScoreFramework?.length || 0,
          responseObject: [
            {
              scores: assessmentInfo?.informationFramework?.assessmentScoreFramework || [],
              assessmentName: assessmentInfo.informationBasic.assessmentName,
              assessmentDescription: assessmentInfo.informationBasic.assessmentDescription,
              assessmentStatus: assessmentInfo.informationEngagement.assessmentStatus,
              id: assessmentInfo.id,
              assessmentId: assessmentInfo.id
            }
          ]
        };
      }
      if (data.payload.typeOfMiddlePaneList === 'assessmentgradesReviewList') {
        BadgeOne = 'grades';
        reviseResponseObj = {
          countTotal:
            assessmentInfo?.informationFramework?.assessmentScoreGradeFramework?.length || 0,
          responseObject: [
            {
              grades: assessmentInfo?.informationFramework?.assessmentScoreGradeFramework || [],
              assessmentName: assessmentInfo.informationBasic.assessmentName,
              assessmentDescription: assessmentInfo.informationBasic.assessmentDescription,
              assessmentStatus: assessmentInfo.informationEngagement.assessmentStatus,
              id: assessmentInfo.id,
              assessmentId: assessmentInfo.id
            }
          ]
        };
      }
      if (data.payload.typeOfMiddlePaneList === 'assessmentDistinctSubScaleDistinct') {
        BadgeOne = 'matrics';
        let assessmentSelectedScale =
          Store.getState().DisplayPaneTwoReducer.assessmentSelectedScale;
        let scaleObj = assessmentInfo?.informationFramework?.assessmentScaleFramework.filter(
          (dd) => {
            return dd.id === assessmentSelectedScale;
          }
        );
        reviseResponseObj = {
          countTotal: scaleObj.assessmentScale?.length || 0,
          responseObject: [
            {
              scales: assessmentInfo?.informationFramework?.assessmentScaleFramework || [],
              assessmentName: assessmentInfo.informationBasic.assessmentName,
              assessmentDescription: assessmentInfo.informationBasic.assessmentDescription,
              assessmentStatus: assessmentInfo.informationEngagement.assessmentStatus,
              id: assessmentInfo.id,
              assessmentId: assessmentInfo.id,
              subscale: scaleObj[0]
            }
          ]
        };
      }
      yield put({
        type: SET_MIDDLEPANE_STATE,
        payload: {
          middlePaneHeader: 'assessment',
          middlePaneHeaderBadgeOne: BadgeOne,
          middlePaneHeaderBadgeTwo: 'distinct',
          middlePaneHeaderBadgeThree: '',
          middlePaneHeaderBadgeFour: '',
          middlePaneHeaderDuplicate: 'assessment',
          middlePaneHeaderDuplicateBadgeOne: BadgeOne,
          middlePaneHeaderDuplicateBadgeTwo: 'distinct',
          middlePaneHeaderDuplicateBadgeThree: '',
          middlePaneHeaderDuplicateBadgeFour: '',
          middlePaneHeaderDuplicateOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOne,
          middlePaneHeaderDuplicateOneBadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeOne,
          middlePaneHeaderDuplicateOneBadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeTwo,
          middlePaneHeaderDuplicateOneBadgeThree: '',
          middlePaneHeaderDuplicateOneBadgeFour: '',
          typeOfMiddlePaneList: data.payload.typeOfMiddlePaneList,
          middlePaneSelectedValue: assessmentInfo.id,
          scanCount: reviseResponseObj && reviseResponseObj.countTotal,
          duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
          showMiddlePaneState: true
        }
      });
      yield put({
        type: RELATED_REVIEWLIST_DISTINCT_DATA,
        payload: reviseResponseObj.responseObject
      });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerReviseClusterAssessmentSaga(data) {
  try {
    const userResponse = yield call(assessmentInfoApi, {
      data: data.payload.reqBody,
      URL: ASSESSMENT_MODULE_URL
    });
    if (userResponse.responseCode === '000') {
      const {
        createMode,
        assessmentSector = '',
        selectedSector = '',
        typeOfMiddlePaneList
      } = data.payload;
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: {
          stateName: 'assessmentResponseObject',
          value: userResponse.responseObject[0]
        }
      });
      if (assessmentSector === 'cluster') {
        let responseObj =
          userResponse.responseObject[0].informationFramework?.assessmentClusterFramework?.filter(
            (dd) => {
              return dd.id === selectedSector;
            }
          );
        yield put({
          type: SET_DISPLAY_PANE_THREE_STATE,
          payload: {
            headerOne: 'assessment',
            headerOneBadgeOne: 'cluster',
            headerOneBadgeTwo: 'information',
            headerOneBadgeThree: 'key',
            responseObject: responseObj[0],
            reviewMode: createMode
          }
        });
        yield put({ type: LOADER_STOP });
      } else if (assessmentSector === 'norm') {
        let responseObj =
          userResponse.responseObject[0].informationFramework?.assessmentNormFramework?.filter(
            (dd) => {
              return dd.id === selectedSector;
            }
          );
        yield put({
          type: SET_DISPLAY_PANE_THREE_STATE,
          payload: {
            headerOne: 'assessment',
            headerOneBadgeOne: 'norm',
            headerOneBadgeTwo: 'information',
            headerOneBadgeThree: 'key',
            responseObject: responseObj[0],
            reviewMode: createMode
          }
        });
        yield put({ type: LOADER_STOP });
      } else if (assessmentSector === 'grade') {
        let responseObj =
          userResponse.responseObject[0].informationFramework?.assessmentScoreGradeFramework?.filter(
            (dd) => {
              return dd.id === selectedSector;
            }
          );
        yield put({
          type: SET_DISPLAY_PANE_THREE_STATE,
          payload: {
            headerOne: 'assessment',
            headerOneBadgeOne: 'grade',
            headerOneBadgeTwo: 'information',
            headerOneBadgeThree: 'key',
            responseObject: responseObj[0],
            reviewMode: createMode
          }
        });
        yield put({ type: LOADER_STOP });
      } else if (assessmentSector === 'scale' || assessmentSector === 'updatescale') {
        let responseObj = [];
        if (typeOfMiddlePaneList === 'assessmentDistinctSubScaleDistinct') {
          let selectedscaleid = data?.payload?.reqBody?.requestObject?.assessmentScaleFramework?.id;
          let selectedMetricsObj =
            userResponse.responseObject[0].informationFramework?.assessmentScaleFramework.filter(
              (ss) => {
                return ss.id === selectedscaleid;
              }
            );
          console.log('selectedMetricsObj', selectedMetricsObj);
          let selectedMetrics = selectedMetricsObj[0].assessmentScaleMetric[selectedSector];
          responseObj = [
            {
              assessmentId: userResponse.responseObject[0].id,
              assessmentScaleDescription: selectedMetricsObj[0].assessmentScaleDescription,
              assessmentScaleFlag: selectedMetricsObj[0].assessmentScaleFlag,
              assessmentScaleName: selectedMetricsObj[0].assessmentScaleName,
              assessmentScaleNameVerification:
                selectedMetricsObj[0].assessmentScaleNameVerification,
              assessmentScalePicture: selectedMetricsObj[0].assessmentScalePicture,
              assessmentScalePictureVerification:
                selectedMetricsObj[0].assessmentScalePictureVerification,
              id: selectedscaleid,
              assessmentScale: selectedMetrics
            }
          ];
        } else {
          responseObj =
            userResponse.responseObject[0].informationFramework?.assessmentScaleFramework?.filter(
              (dd) => {
                return dd.id === selectedSector;
              }
            );
        }
        yield put({
          type: SET_DISPLAY_PANE_THREE_STATE,
          payload: {
            headerOne: 'assessment',
            headerOneBadgeOne: 'scale',
            headerOneBadgeTwo: 'information',
            headerOneBadgeThree: 'key',
            responseObject: responseObj[0],
            reviewMode: createMode
          }
        });
        yield put({ type: LOADER_STOP });
      } else if (assessmentSector === 'score') {
        let responseObj =
          userResponse.responseObject[0].informationFramework?.assessmentScoreFramework?.filter(
            (dd) => {
              return dd.id === selectedSector;
            }
          );
        yield put({
          type: SET_DISPLAY_PANE_THREE_STATE,
          payload: {
            headerOne: 'assessment',
            headerOneBadgeOne: 'score',
            headerOneBadgeTwo: 'information',
            headerOneBadgeThree: 'key',
            responseObject: responseObj[0],
            reviewMode: createMode
          }
        });
        yield put({ type: LOADER_STOP });
      } else {
        yield put({ type: LOADER_STOP });
      }
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isSelectActive', value: '' }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'selectedTagsArray', value: [] }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'relatedReviewListDistinctData', value: [] }
      });
      // if (typeOfMiddlePaneList !== 'assessmentDistinctSubScaleDistinct') {
      yield put({
        type: GET_ASSESSMENT_SEC_INFO_SAGA,
        payload: {
          HeaderOne: 'assessments',
          //request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
          reqBody: Store.getState().DisplayPaneTwoReducer.relatedReviewListReqObj,
          BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
          BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
          BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
          typeOfMiddlePaneList: Store.getState().DisplayPaneTwoReducer.typeOfMiddlePaneList
        }
      });
      // }
    } else {
      console.log('loading end');
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
      yield put({ type: LOADER_STOP });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerAssessmentReviseSaga(data) {
  const userResponse = yield call(assessmentReviseInfoApi, { data: data.payload.reqBody });
  if (userResponse.responseCode === '000') {
    yield put({
      type: SET_DISPLAY_THREE_SINGLE_STATE,
      payload: { stateName: 'isFrameworkOpen', value: 'assessmentFramework' }
    });
    yield put({
      type: SET_DISPLAY_THREE_SINGLE_STATE,
      payload: { stateName: 'responseObject', value: userResponse.responseObject[0] }
    });
    yield put({ type: LOADER_STOP });
  } else {
    console.log('hereeee 4');
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: userResponse?.responseMessage, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerReviseInfoAssessmentSaga(data) {
  try {
    const userResponse = yield call(assessmentReviseInfoApi, { data: data.payload.reqBody });
    if (userResponse.responseCode === '000') {
      const {
        createMode = '',
        assessmentSector = '',
        selectedSector = '',
        selectedVersion = '',
        hideRightpane = false,
        isStatusRevise = false,
        isItemTrial
      } = data.payload;
      let assessmentInfo = userResponse.responseObject[0];
      let BadgeOne = '';
      let reviseResponseObj;
      let typeOfMiddlePaneList = '';
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: {
          stateName: 'assessmentResponseObject',
          value: assessmentInfo
        }
      });
      yield put({
        type: SET_DISPLAY_THREE_SINGLE_STATE,
        payload: { stateName: 'isFrameworkOpen', value: '' }
      });
      if (assessmentSector === 'section') {
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
        yield put({
          type: SET_DISPLAY_PANE_THREE_STATE,
          payload: {
            headerOne: 'assessment',
            headerOneBadgeOne: 'section',
            headerOneBadgeTwo: 'information',
            headerOneBadgeThree: 'key',
            responseObject:
              assessmentInfo.informationFramework.assessmentSectionFramework[selectedSector],
            reviewMode: createMode
          }
        });
        let array = assessmentInfo?.informationFramework?.assessmentSectionFramework || [];
        BadgeOne = 'sections';
        reviseResponseObj = {
          countTotal: array?.length || 0,
          responseObject: [
            {
              sections: array || [],
              assessmentName: assessmentInfo.informationBasic.assessmentName,
              assessmentDescription: assessmentInfo.informationBasic.assessmentDescription,
              assessmentStatus: assessmentInfo.informationEngagement.assessmentStatus,
              id: assessmentInfo.id
            }
          ]
        };
        typeOfMiddlePaneList = 'assessmentsectionsReviewList';
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: 'assessment',
            middlePaneHeaderBadgeOne: BadgeOne,
            middlePaneHeaderBadgeTwo: 'distinct',
            middlePaneHeaderBadgeThree: '',
            middlePaneHeaderBadgeFour: '',
            middlePaneHeaderDuplicate:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
            middlePaneHeaderDuplicateBadgeOne:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            middlePaneHeaderDuplicateBadgeTwo:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            middlePaneHeaderDuplicateBadgeThree:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
            middlePaneHeaderDuplicateBadgeFour: '',
            middlePaneHeaderDuplicateOne:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOne,
            middlePaneHeaderDuplicateOneBadgeOne:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeOne,
            middlePaneHeaderDuplicateOneBadgeTwo:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeTwo,
            middlePaneHeaderDuplicateOneBadgeThree:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeThree,
            middlePaneHeaderDuplicateOneBadgeFour:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOneBadgeFour,
            duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
            typeOfMiddlePaneList: typeOfMiddlePaneList,
            middlePaneSelectedValue: assessmentInfo.id,
            scanCount: reviseResponseObj && reviseResponseObj.countTotal,
            showMiddlePaneState: true
          }
        });
        yield put({
          type: RELATED_REVIEWLIST_DISTINCT_DATA,
          payload: reviseResponseObj.responseObject
        });
        yield put({ type: LOADER_STOP });
      } else if (assessmentSector === 'version') {
        yield put({
          type: SET_DISPLAY_PANE_THREE_STATE,
          payload: {
            headerOne: 'assessment',
            headerOneBadgeOne: 'version',
            headerOneBadgeTwo: 'information',
            headerOneBadgeThree: 'key',
            responseObject:
              userResponse.responseObject[0].informationFramework.assessmentSectionFramework[
                selectedSector
              ].assessmentVersionFramework[parseInt(selectedVersion)],
            reviewMode: createMode
          }
        });
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: {
            stateName: 'assessmentSelecedSectionVersionData',
            value:
              userResponse.responseObject[0].informationFramework.assessmentSectionFramework[
                selectedSector
              ].assessmentVersionFramework[parseInt(selectedVersion)]
          }
        });
        yield put({
          type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
          payload: {
            stateName: 'assesseeAssessmentStartData',
            value:
              userResponse.responseObject[0].informationFramework.assessmentSectionFramework[
                selectedSector
              ].assessmentVersionFramework[parseInt(selectedVersion)]
          }
        });
        yield put({ type: LOADER_STOP });
      } else if (assessmentSector === 'score') {
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
        let responseObject = assessmentInfo.informationFramework?.assessmentScoreFramework?.filter(
          (dd) => {
            return dd.id === selectedSector;
          }
        );
        yield put({
          type: SET_DISPLAY_PANE_THREE_STATE,
          payload: {
            headerOne: 'assessment',
            headerOneBadgeOne: 'score',
            headerOneBadgeTwo: 'information',
            headerOneBadgeThree: 'key',
            responseObject: responseObject[0],
            reviewMode: createMode
          }
        });
        let array = assessmentInfo?.informationFramework?.assessmentScoreFramework || [];
        reviseResponseObj = {
          scores: array || [],
          assessmentName: assessmentInfo.informationBasic.assessmentName,
          assessmentDescription: assessmentInfo.informationBasic.assessmentDescription,
          assessmentStatus: assessmentInfo.informationEngagement.assessmentStatus,
          id: assessmentInfo.id
        };
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [reviseResponseObj] }
        });
        yield put({ type: LOADER_STOP });
      } else if (assessmentSector === 'grade') {
        yield put({
          type: SET_DISPLAY_PANE_THREE_STATE,
          payload: {
            headerOne: 'assessment',
            headerOneBadgeOne: 'grade',
            headerOneBadgeTwo: 'information',
            headerOneBadgeThree: 'key',
            responseObject:
              userResponse.responseObject[0].informationFramework.AssessmentScoreGradeFramework[
              selectedSector
              ],
            reviewMode: createMode
          }
        });
        yield put({ type: LOADER_STOP });
      } else if (assessmentSector === 'assessmentsectionsReviewList') {
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'isSelectActive', value: '' }
        });
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'selectedTagsArray', value: [] }
        });
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
        yield put({
          type: GET_ASSESSMENT_SEC_INFO_SAGA,
          payload: {
            HeaderOne: 'assessments',
            //request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
            reqBody: Store.getState().DisplayPaneTwoReducer.relatedReviewListReqObj,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
            BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
            typeOfMiddlePaneList: Store.getState().DisplayPaneTwoReducer.typeOfMiddlePaneList
          }
        });
      } else if (assessmentSector === 'assessmentversionsReviewList') {
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'isSelectActive', value: '' }
        });
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'selectedTagsArray', value: [] }
        });
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
        yield put({
          type: GET_ASSESSMENT_SEC_INFO_SAGA,
          payload: {
            HeaderOne: 'assessments',
            //request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
            reqBody: Store.getState().DisplayPaneTwoReducer.relatedReviewListReqObj,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
            BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
            typeOfMiddlePaneList: Store.getState().DisplayPaneTwoReducer.typeOfMiddlePaneList
          }
        });
      } else if (assessmentSector === '' && !hideRightpane && createMode === '') {
        if (!isStatusRevise) {
          yield put({
            type: SET_DISPLAY_PANE_THREE_STATE,
            payload: {
              headerOne: 'assessment',
              headerOneBadgeOne: data.payload.headerOneBadgeOne || 'distinct',
              headerOneBadgeThree: data.payload.headerOneBadgeThree,
              headerOneBadgeTwo:  'information',
              responseObject: userResponse.responseObject[0],
              createMode
            }
          });
        }
        if (isItemTrial) {
          yield put({ type: SET_PANE_THREE_ASSESSMENT_PREVIEW_MODE, payload: true });
          yield put({
            type: SET_ASSESSEE_ASSESSMENT_DYNAMIC_STATE,
            payload: {
              stateName: 'assesseeAssessmentStartData',
              value: assessmentInfo?.informationFramework?.assessmentItemTrial
            }
          });
        } //
        if (data.payload.createMode === '') {
          if (data.payload.typeOfMiddlePaneList === 'assessmentGroupAssessmentReviewList') {
            yield put({ type: LOADER_START });
            yield put({
              type: SET_DISPLAY_TWO_SINGLE_STATE,
              payload: { stateName: 'typeOfMiddlePaneList', value: '' }
            });
            yield put({
              type: SET_DISPLAY_TWO_SINGLE_STATE,
              payload: { stateName: 'relatedReviewListDistinctData', value: [] }
            });
            yield put({
              type: GET_ASSESSMENTGROUP_ASSESSMENT_REVIEWLIST_SAGA,
              payload: {
                HeaderOne: 'assessments',
                request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
                BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
                BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
                BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
                middlePaneSelectedValue:
                  Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
                isMiddlePaneList: true
              }
            });
          } else if (data.payload.typeOfMiddlePaneList === 'assessmentTypeAssessmentReviewList') {
            yield put({ type: LOADER_START });
            // yield put({
            //   type: SET_DISPLAY_TWO_SINGLE_STATE,
            //   payload: { stateName: 'typeOfMiddlePaneList', value: '' }
            // });
            yield put({
              type: SET_DISPLAY_TWO_SINGLE_STATE,
              payload: { stateName: 'relatedReviewListDistinctData', value: [] }
            });
            yield put({
              type: GET_ASSESSMENTTYPE_ASSESSMENT_REVIEWLIST_SAGA,
              payload: {
                HeaderOne: 'assessments',
                request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
                BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
                BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
                BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
                middlePaneSelectedValue:
                  Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
                isMiddlePaneList: true
              }
            });
          } else {
            yield put({
              type: SET_DISPLAY_TWO_SINGLE_STATE,
              payload: { stateName: 'reviewListDistinctData', value: [] }
            });
            yield put({
              type: ASSESSMENT_REVIEW_DISTINCT_SAGA,
              payload: {
                HeaderOne: 'assessments',
                request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
                BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
                BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
                BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
                isMiddlePaneList: true,
                middlePaneSelectedValue:
                  Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue
              }
            });
          }
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
        }
      } else {
        yield put({
          type: SET_DISPLAY_PANE_THREE_STATE,
          payload: {
            headerOne: 'assessment',
            headerOneBadgeOne: 'distinct',
            headerOneBadgeTwo: 'information',
            headerOneBadgeThree: data.payload.secondaryOptionCheckValue,
            responseObject: userResponse.responseObject[0],
            createMode
          }
        });
        yield put({ type: LOADER_STOP });
      }
    } else {
      console.log('loading end');
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
      yield put({ type: LOADER_STOP });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerPublishAssessmentSaga(data) {
  try {
    const userResponse = yield call(assessmentInfoApi, {
      data: data.payload.reqBody,
      URL: ASSESSMENT_MODULE_URL
    });
    if (userResponse.responseCode === '000') {
      if (Store.getState().DisplayPaneTwoReducer.typeOfMiddlePaneList) {
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'reviewListDistinctData', value: [] }
        });
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'typeOfMiddlePaneList', value: '' }
        });
        yield put({
          type: ASSESSMENT_REVIEW_DISTINCT_SAGA,
          payload: {
            HeaderOne: 'assessments',
            request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
            BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
            BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
            BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
            middlePaneSelectedValue: Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
            isMiddlePaneList: true
          }
        });
      } else {
        yield put({ type: LOADER_STOP });
      }
    } else if (userResponse?.responseObject?.forceToPublish) {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isForceToOpenNextPopup', value: 'FORCE_TO_PUBLISH' }
      });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
      yield put({ type: LOADER_STOP });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerPreviewAssessmentSaga(data) {
  try {
    const userResponse = yield call(assessmentInfoApi, {
      data: data.payload.reqBody,
      URL: ASSESSMENT_MODULE_URL
    });
    if (userResponse.responseCode === '000') {
      const { createMode, selectedSector, assessmentSector = '' } = data.payload;
      if (assessmentSector === 'assessment') {
        yield put({
          type: SET_DISPLAY_PANE_THREE_STATE,
          payload: {
            headerOne: 'assessment',
            headerOneBadgeOne: 'distinct',
            headerOneBadgeTwo: 'information',
            headerOneBadgeThree: 'all',
            responseObject: userResponse.responseObject[0],
            createMode
          }
        });
      } else {
        yield put({
          type: SET_DISPLAY_PANE_THREE_STATE,
          payload: {
            headerOne: 'assessment',
            headerOneBadgeOne: 'section',
            headerOneBadgeTwo: 'information',
            responseObject:
              userResponse.responseObject[0].informationFramework.assessmentSectionFramework[
              selectedSector
              ],
            createMode
          }
        });
      }

      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: {
          stateName: 'assessmentResponseObject',
          value: userResponse.responseObject[0]
        }
      });
      const { informationFramework } = userResponse.responseObject[0];

      const assessmentSectionFramework = informationFramework?.assessmentSectionFramework || [];
      yield put({
        type: SET_ASSESSMENT_DYNAMIC_FRAMEWORK_STATE,
        payload: { stateName: 'assessmentSectionFramework', value: assessmentSectionFramework }
      });

      yield put({ type: LOADER_STOP });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
      yield put({ type: LOADER_STOP });
    }
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerReviseMultuStatusAssessmentSaga(data) {
  try {
    const userResponse = yield call(assessmentInfoApi, {
      data: data.payload.reqBody,
      URL: ASSESSMENT_MODULE_URL
    });
    if (userResponse.responseCode === '000') {
      yield put({ type: SET_PAGE_COUNT, payload: 0 });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'relatedReviewListDistinctData', value: [] }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'typeOfMiddlePaneList', value: '' }
      });
      yield put({
        type: SET_POPUP_SINGLE_STATE,
        payload: { stateName: 'selectionValue', value: '' }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isSelectActive', value: '' }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'selectedTagsArray', value: [] }
      });
      if (data.payload.createMode === '') {
        if (data.payload.typeOfMiddlePaneList === 'assessmentsTypeDistinctReviewList') {
          yield put({ type: LOADER_START });
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
          yield put({
            type: GET_ASSESSMENT_TYPE_REVIEW_LIST_SAGA,
            payload: {
              HeaderOne: 'assessments',
              request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        } else if (data.payload.typeOfMiddlePaneList === 'assessmentsGroupDistinctReviewList') {
          yield put({ type: LOADER_START });
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
          yield put({
            type: GET_ASSESSMENT_GROUP_REVIEW_LIST_SAGA,
            payload: {
              HeaderOne: 'assessments',
              request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        } else if (data.payload.typeOfMiddlePaneList === 'assessmentGroupAssessmentReviewList') {
          yield put({ type: LOADER_START });
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          yield put({
            type: GET_ASSESSMENTGROUP_ASSESSMENT_REVIEWLIST_SAGA,
            payload: {
              HeaderOne: 'assessments',
              request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        } else if (data.payload.typeOfMiddlePaneList === 'assessmentTypeAssessmentReviewList') {
          yield put({ type: LOADER_START });
          // yield put({
          //   type: SET_DISPLAY_TWO_SINGLE_STATE,
          //   payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          // });
          yield put({
            type: GET_ASSESSMENTTYPE_ASSESSMENT_REVIEWLIST_SAGA,
            payload: {
              HeaderOne: 'assessments',
              request: Store.getState().DisplayPaneTwoReducer.relatedReviewListObjectCopy,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        } else {
          yield put({ type: LOADER_START });
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'typeOfMiddlePaneList', value: '' }
          });
          yield put({
            type: SET_DISPLAY_TWO_SINGLE_STATE,
            payload: { stateName: 'reviewListDistinctData', value: [] }
          });
          yield put({
            type: ASSESSMENT_REVIEW_DISTINCT_SAGA,
            payload: {
              HeaderOne: 'assessments',
              request: Store.getState().DisplayPaneTwoReducer.reviewListReqObj,
              BadgeOne: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
              BadgeTwo: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
              BadgeThree: Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
              middlePaneSelectedValue:
                Store.getState().DisplayPaneTwoReducer.middlePaneSelectedValue,
              isMiddlePaneList: true
            }
          });
        }
      } else {
        yield put({ type: LOADER_STOP });
      }
    } else {
      yield put({ type: LOADER_STOP });
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    //yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}

export default function* watchReviewInfoAssessmentSaga() {
  yield takeLatest(GET_ASSESSMENT_INFO_SAGA, workerReviewInfoAssessmentSaga);
  yield takeLatest(GET_ASSIGNMENT_ASSESSMENT_INFO_SAGA, workerReviewInfoAssignmentAssessmentSaga);
  yield takeLatest(GET_ASSESSMENT_SEC_INFO_SAGA, workerReviewInfoAssessmentSecSaga);
  yield takeLatest(ASSESSMENT_INFO_REVISE_SAGA, workerReviseInfoAssessmentSaga);
  yield takeLatest(ASSESSMENT_REVISE_SAGA, workerAssessmentReviseSaga);
  yield takeLatest(ASSESSMENT_CLUSTER_REVISE_SAGA, workerReviseClusterAssessmentSaga);
  yield takeLatest(ASSESSMENT_PUBLISH_SAGA, workerPublishAssessmentSaga);
  yield takeLatest(ASSESSMENT_INFO_PREVIEW_SAGA, workerPreviewAssessmentSaga);
  yield takeLatest(ASSESSMENT_MULTI_STATUS_REVISE_SAGA, workerReviseMultuStatusAssessmentSaga);
}
