import {
  SET_GUAGE_REDUCER_STATE,
  CLEAR_GUAGE_REDUCER_STATE,
  SET_GUAGE_INFO_REDUCER_STATE
} from '../actionType';

const initialState = {
  gaugeInformation: {
    iGaugeOccupationFrameworkOneGaugeName: '',
    iGaugeOccupationFrameworkOneGaugeNameVerification: false,
    iGaugeOccupationFrameworkOneGaugeDescription: '',
    iGaugeOccupationFrameworkOneGaugePicture: '',
    iGaugeOccupationFrameworkOneGaugeFlag: false,
    iGaugeOccupationFrameworkOneGaugePictureVerification: false,
    iGaugeOccupationFrameworkOneGaugeMetric: [],
    iGaugeOccupationFrameworkOneGaugeValue: '',
    // iGaugeOccupationFrameworkOneGaugeNotification: false
  },
  gaugeInformationrmation: {
    iGaugeOccupationFrameworkOneGaugeMetricLabel: '',
    iGaugeOccupationFrameworkOneGaugeMetricDescription: '',
    iGaugeOccupationFrameworkOneGaugeMetricExplanation: '',
    iGaugeOccupationFrameworkOneGaugeMetricExtremumMaximum: 0,
    iGaugeOccupationFrameworkOneGaugeMetricExtremumMinimum: 0
  }
};
const GaugeCreateReducer = (istate = JSON.parse(JSON.stringify(initialState)), action) => {
  switch (action.type) {
    case SET_GUAGE_REDUCER_STATE:
      return {
        ...istate,
        gaugeInformation: action.payload
      };
    case SET_GUAGE_INFO_REDUCER_STATE:
      return {
        ...istate,
        gaugeInformationrmation: action.payload
      };
    case CLEAR_GUAGE_REDUCER_STATE:
      return JSON.parse(JSON.stringify(initialState));
    default:
      return istate;
  }
};

export default GaugeCreateReducer;
