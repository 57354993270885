import React, { useState, Fragment, useEffect } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import Popup from '../Molecules/PopUp/PopUp';
import PopupHeader from '../Molecules/PopUp/PopUpHeader';
import { InputLabel, FormControl, Checkbox } from '@material-ui/core';
import InputFeild from '../Atoms/InputField/InputField';
import SelectField from '../Atoms/SelectField/SelectField';
import '../Molecules/PopUp/PopUp.css';
import '../Atoms/InputField/InputField.css';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  POPUP_CLOSE,
  SET_ASSESSEE_CREATE_SINGLE_STATE,
  SET_NEXT_POPUP,
  SET_SINGLE_ASSOCIATE_INFORMATION
} from '../actionType';
import { REQUIRED_ERROR_MESSAGE, VALID_PHONE_NUMBER } from '../errorMessage';
import { getCitiesByCountryId } from '../Actions/GenericActions';

const PopUpTelephone = (props) => {
  const dispatch = useDispatch();
  const { reviewMode } = useSelector((state) => state.DisplayPaneThreeReducer);
  const { popupMode } = useSelector((state) => state.PopUpReducer);
  const { allCountryList } = useSelector((state) => state.DisplayPaneTwoReducer);
  const associateInfo = useSelector((state) => state.AssociateCreateReducer);
  const {
    isActive,
    primaryheader = 'primary',
    inputHeader = 'mobile telephone',
    headerPanelColour = '',
    headerOne = '',
    headerOneBadgeOne = '',
    nextPopUpValue,
    basicInfo,
    typeOfSetObject,
    isMobileState = false,
    isRequired = false,
    mode,
    onClickCheckbox = null,
    tempTelephoneCommunication = '',
    checkboxValue = inputHeader + ' ' + primaryheader
  } = props;
  const [zeroErr, setzeroErr] = useState('');
  const [oneErr, setoneErr] = useState('');
  const [twoErr, settwoErr] = useState('');
  const [threeErr, setthreeErr] = useState('');
  const [localObject, setLocalObject] = useState(basicInfo);
  useEffect(() => {
    setLocalObject(basicInfo);
  }, []);
  // const objectKeys = Object.keys(basicInfo);
  const objectKeys = Object.keys(localObject);
  const telephoneValidate = () => {
    let isValidate = true;
    if (localObject && localObject[objectKeys[1]] === '' && !localObject[objectKeys[1]]) {
      setzeroErr(REQUIRED_ERROR_MESSAGE);
      isValidate = false;
    }
    if (localObject && localObject[objectKeys[2]] === '') {
      setoneErr(REQUIRED_ERROR_MESSAGE);
      isValidate = false;
    }
    if (localObject && localObject[objectKeys[4]] === '') {
      settwoErr(REQUIRED_ERROR_MESSAGE);
      isValidate = false;
    }

    if (!(/^\d{10}$/.test(localObject[objectKeys[4]]))) {
      settwoErr(VALID_PHONE_NUMBER);
      isValidate = false;
    }
    // if (localObject && localObject[objectKeys[2]] === '') {
    //   setthreeErr(REQUIRED_ERROR_MESSAGE);
    //   isValidate = false;
    // }
    /* validation of moile number but still its not required
   let regex = new RegExp(/^(\+\d{1,3}[- ]?)?\d{10}$/);
    let mobilestr = localObject.mobileNumber;
    if (regex.test(mobilestr) === false && mobilestr !=='') {
      setState((prevState) => ({
        ...prevState,
        error: 'this information is incorrect'
      }));
      isValidate=false
    }*/
    return isValidate;
  };
  const validate = () => {
    let isValidate = true;
    console.log('objectKeys', objectKeys);
    console.log('objectKeys[1]', objectKeys[1]);
    console.log('localObject[objectKeys[1]]', localObject[objectKeys[1]]);
    if (
      localObject &&
      (localObject[objectKeys[1]] === '' ||
        localObject[objectKeys[1]] === undefined ||
        localObject[objectKeys[1]] === null)
    ) {
      setzeroErr(REQUIRED_ERROR_MESSAGE);
      isValidate = false;
    }
    if (localObject && localObject[objectKeys[2]] === '') {
      setoneErr(REQUIRED_ERROR_MESSAGE);
      isValidate = false;
    }
    if (localObject && localObject[objectKeys[4]] === '') {
      settwoErr(REQUIRED_ERROR_MESSAGE);
      isValidate = false;
    }
    // if (localObject && localObject[objectKeys[2]] === '') {
    //   setthreeErr(REQUIRED_ERROR_MESSAGE);
    //   isValidate = false;
    // }
    /* validation of moile number but still its not required
   let regex = new RegExp(/^(\+\d{1,3}[- ]?)?\d{10}$/);
    let mobilestr = localObject.mobileNumber;
    if (regex.test(mobilestr) === false && mobilestr !=='') {
      setState((prevState) => ({
        ...prevState,
        error: 'this information is incorrect'
      }));
      isValidate=false
    }*/
    return isValidate;
  };
  const handleChange = (event) => {
    event.preventDefault();
    const { name, value, type } = event.target;
    let dataKey = event.currentTarget.getAttribute('data-key');
    let currentTargetId = event.currentTarget.id;

    if (objectKeys.indexOf(event.target.name) === 1) {
      setzeroErr('');
    }
    if (objectKeys.indexOf(event.target.name) === 2) {
      setoneErr('');
    }
    if (objectKeys.indexOf(event.target.name) === 4) {
      settwoErr('');
    }
    if (objectKeys.indexOf(event.target.name) === 5) {
      setthreeErr('');
    }
    if (
      currentTargetId === 'country' ||
      currentTargetId === 'province' ||
      currentTargetId === 'city'
    ) {
      console.log('name.slice(0, -3)', name.slice(0, -3));
      setLocalObject({ ...localObject, [name]: value, [name.slice(0, -3)]: dataKey });
      getCitiesByCountryId(dispatch, value);
    } else {
      if (isMobileState) {
        if (new RegExp('^[0-9]*$').test(value)) {
          setLocalObject({ ...localObject, [name]: value });
        }
      } else {
        setLocalObject({ ...localObject, [name]: value });
      }
    }
    if (name === 'assesseeTelephoneNumber' || name === 'associateTelephoneNumber') {
      if (value === '') {
        dispatch({
          type: SET_ASSESSEE_CREATE_SINGLE_STATE,
          payload: {
            stateName: 'tempTelephoneCommunication',
            value: ''
          }
        });
      } else {
        if (tempTelephoneCommunication === '' && new RegExp('^[0-9]*$').test(value)) {
          dispatch({
            type: SET_ASSESSEE_CREATE_SINGLE_STATE,
            payload: { stateName: 'tempTelephoneCommunication', value: checkboxValue }
          });
        }
      }
    }
    let val = inputHeader + ' ' + primaryheader;
    if (tempTelephoneCommunication === '' && val === 'work telephone primary') {
      associateInfo.informationContact.associateAddressWorkPrimary.associateAddressCommunication = true;
      dispatch({
        type: SET_SINGLE_ASSOCIATE_INFORMATION,
        payload: { stateName: 'tempAssociateTeleCommunication', value: val }
      });
    }
  };

  const handleClick = () => {
    if (reviewMode === 'revise') {
      if (isMobileState) {
        if (localObject[objectKeys[1]] !== '' && localObject[objectKeys[2]] === '') {
          setoneErr(REQUIRED_ERROR_MESSAGE);
        } else if (localObject[objectKeys[1]] === '' && localObject[objectKeys[2]] !== '') {
          setzeroErr(REQUIRED_ERROR_MESSAGE);
        } else if (localObject[objectKeys[1]] !== '' && localObject[objectKeys[2]] !== '') {
          let validatn = true
          if(objectKeys[2] === 'assesseeTelephoneNumber' || objectKeys[2] === 'associateTelephoneNumber'){
            if (!(/^\d{10}$/.test(localObject[objectKeys[2]]))) {
              setoneErr(VALID_PHONE_NUMBER)
              return;
            }
          } 
          dispatch({ type: typeOfSetObject, payload: { ...localObject } });
          dispatch({ type: POPUP_CLOSE });
        } else {
          dispatch({ type: POPUP_CLOSE });
        }
      } else {
        if (
          Object.keys(localObject).every(function (x) {
            return localObject[x] === '' || localObject[x] === null || !localObject[x];
          }) === false
        ) {
          console.log('has something');
          if (telephoneValidate()) {
            dispatch({ type: typeOfSetObject, payload: { ...localObject } });
            dispatch({ type: POPUP_CLOSE });
          }
        } else {
          console.log('nothing');
          dispatch({ type: POPUP_CLOSE });
        }
      }
    } else {
      if (isRequired) {
        if (validate()) {
          /*according to creation mode popup sequence will change*/
          dispatch({ type: typeOfSetObject, payload: { ...localObject } });
          dispatch({ type: SET_NEXT_POPUP, payload: { isPopUpValue: nextPopUpValue } });
        }
      } else if (isMobileState) {
        if (localObject[objectKeys[1]] !== '' && localObject[objectKeys[2]] === '') {
          setoneErr(REQUIRED_ERROR_MESSAGE);
        } else if (localObject[objectKeys[1]] === '' && localObject[objectKeys[2]] !== '') {
          setzeroErr(REQUIRED_ERROR_MESSAGE);
        } else if (localObject[objectKeys[1]] !== '' && localObject[objectKeys[2]] !== '') {
          if(objectKeys[2] === 'assesseeTelephoneNumber' || objectKeys[2] === 'associateTelephoneNumber' ){
            if (!(/^\d{10}$/.test(localObject[objectKeys[2]]))) {
              setoneErr(VALID_PHONE_NUMBER)
              return;
            }
          } 
          dispatch({ type: typeOfSetObject, payload: { ...localObject } });
          dispatch({ type: SET_NEXT_POPUP, payload: { isPopUpValue: nextPopUpValue } });
        } else {
          dispatch({ type: SET_NEXT_POPUP, payload: { isPopUpValue: nextPopUpValue } });
        }
      } else {
        dispatch({ type: SET_NEXT_POPUP, payload: { isPopUpValue: nextPopUpValue } });
      }
    }
  };
  console.log('props', props);
  return (
    <div>
      <Popup isActive={isActive}>
        <PopupHeader
          headerPanelColour={headerPanelColour}
          headerOne={headerOne}
          headerOneBadgeOne={headerOneBadgeOne}
          onClick={handleClick}
          mode={mode}
        />
        <DialogContent
          className={['popupContent', 'fixed10PadDim', 'revisePopupContent'].join(' ')}
        >
          <div className={'fitContent'}>
            <div className={['PopupFormBox', 'labelPopupBox', 'popupMinHei'].join(' ')}>
              <InputLabel htmlFor="name-input" className={'textForLabelPopup'}>
                <Fragment>
                  {inputHeader}&nbsp;
                  {primaryheader ? <span className={'headerBadge'}>{primaryheader}</span> : null}
                </Fragment>
                <div className={'infoSymbol'}></div>
              </InputLabel>
            </div>
          </div>
          <FormControl style={{ width: '100%' }}>
            <SelectField
              tag={objectKeys[1]}
              label={'country / region'}
              listSelect={allCountryList}
              mappingValue={'_id'}
              dataValue={'country'}
              dataValueKey={'phone_code'}
              errorMsg={zeroErr}
              onChange={handleChange}
              value={localObject && localObject[objectKeys[1]]}
            />

            {isMobileState === false ? (
              <Fragment>
                {/* <SelectField
                  tag={objectKeys[2]}
                  label={'area / city'}
                  listSelect={allCountryCityList}
                  mappingValue={'_id'}
                  errorMsg={oneErr}
                  onChange={handleChange}
                  value={localObject && localObject[objectKeys[2]]}
                /> */}
                <InputFeild
                  type={'text'}
                  id={objectKeys[2]}
                  label={'area / city'}
                  value={localObject && localObject[objectKeys[2]]}
                  errorMsg={oneErr}
                  onClick={handleChange}
                />
                <InputFeild
                  type={'tel'}
                  id={objectKeys[4]}
                  label={'telephone number'}
                  value={localObject && localObject[objectKeys[4]]}
                  errorMsg={twoErr}
                  onClick={handleChange}
                />
              </Fragment>
            ) : null}

            <InputFeild
              type={isMobileState ? 'tel' : 'number'}
              id={isMobileState ? objectKeys[2] : objectKeys[5]}
              label={isMobileState ? 'telephone number' : 'extension number'}
              value={
                localObject && isMobileState
                  ? localObject[objectKeys[2]]
                  : localObject[objectKeys[5]]
              }
              errorMsg={isMobileState ? oneErr : threeErr}
              onClick={handleChange}
            />
            <div className={'fitContent'}>
              <div className={['PopupFormBox', 'popupMinHei0'].join(' ')} style={{ minHeight: 0 }}>
                <div className={'contFlex'}>
                  <div
                    className={'f4'}
                    style={{
                      color: 'dimgray'
                    }}
                  >
                    communication
                  </div>
                  <div className={'checkedFontNew'}>
                    <Checkbox
                      className={''}
                      color="default"
                      onClick={(e) => {
                        onClickCheckbox(e, inputHeader, primaryheader);
                      }}
                      value={tempTelephoneCommunication}
                      checked={tempTelephoneCommunication === checkboxValue}
                      disabled={
                        popupMode === 'ASSOCIATE_SIGN_ON' || popupMode === 'ASSESSEE_SIGN_ON'
                          ? true
                          : false
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={'fitContent'}>
              <div className={['PopupFormBox', 'popupMinHei0'].join(' ')} style={{ minHeight: 0 }}>
                <div className={'contFlex'}>
                  <div
                    className={'f4'}
                    style={{
                      color: 'dimgray'
                    }}
                  >
                    verification
                  </div>
                  <div className={'checkedFontNew'}>
                    <Checkbox className={''} color="default" disabled={false} />
                  </div>
                </div>
              </div>
            </div>
          </FormControl>
        </DialogContent>
      </Popup>
    </div>
  );
};

PopUpTelephone.propTypes = {
  className: PropTypes.string,
  headerPanelColour: PropTypes.oneOf(['genericOne']),
  headerOne: PropTypes.string,
  headerOneBadgeOne: PropTypes.string,
  headerOneBadgeTwo: PropTypes.string,
  headerOneBadgeThree: PropTypes.string,
  isActive: PropTypes.bool
};

export default PopUpTelephone;
