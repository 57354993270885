import React from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import DisplayPanelAccordianReviewListOne from '../Accordian/DisplayPanelAccordianReviewListOne';
import DisplayPanelAccordianInformation from '../Accordian/DisplayPanelAccordianInformation';
import { Paper } from '@material-ui/core';
import {
  CLEAR_DISPLAY_PANE_THREE,
  LOADER_START,
  LOADER_STOP,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MIDDLEPANE_STATE,
  SET_MOBILE_PANE_STATE,
  SET_POPUP_VALUE
} from '../../actionType';

const DisplayPaneThreeSectionTwoAssessmentScale = () => {
  const dispatch = useDispatch();
  const { responseObject, reviewMode } = useSelector((state) => state.DisplayPaneThreeReducer);
  const { typeOfMiddlePaneList, relatedReviewListDistinctData } = useSelector(
    (state) => state.DisplayPaneTwoReducer
  );
  const { selectedTagValue } = useSelector((state) => state.PopUpReducer);
  let frameworkList = [];
  if (responseObject.assessmentScaleMetric && responseObject.assessmentScaleMetric.length > 0) {
    let tempArr = responseObject?.assessmentScaleMetric || [];
    tempArr.forEach((ob) => {
      frameworkList.push({
        id: ob?.id + '-',
        textOne: ob?.assessmentScaleMetricLabel || '',
        textTwo: ob?.assessmentScaleMetricDescription || '',
        status:
          (ob?.assessmentScaleMetricWeightage !== null && 'weightage') ||
          (ob?.assessmentScaleMetricScore !== null && 'score') ||
          '',
        isInformation: true,
        isInformationValue:
          (ob?.assessmentScaleMetricWeightage !== null && ob?.assessmentScaleMetricWeightage) ||
          (ob?.assessmentScaleMetricScore !== null && ob?.assessmentScaleMetricScore) ||
          '0'
      });
    });
  }
  // const frameworkAll = [
  //   {
  //     id: 'a3',
  //     labelTextOneOne: 'scale',
  //     labelTextOneOneBadgeOne: 'distinct',
  //     labelTextOneOneBadgeTwo: '',
  //     labelTextOneOneBadgeThree: '',
  //     labelTextOneOneBadgeFour: '',
  //     labelTextOneOneBadges: [
  //       {
  //         labelTextOneOneBadge: 'distinct',
  //         innerList: frameworkList
  //       }
  //     ],
  //     innerInfo: 'No Information',
  //     isListCard: true
  //   },
  //   // {
  //   //   id: 'scalea3',
  //   //   labelTextOneOne: 'create',
  //   //   labelTextOneOneBadgeOne: 'create',
  //   //   labelTextOneOneBadgeTwo: '',
  //   //   labelTextOneOneBadgeThree: '',
  //   //   labelTextOneOneBadgeFour: '',
  //   //   labelTextOneOneBadges: [],
  //   //   innerInfo: 'No Information',
  //   //   isListCard: false
  //   // }
  // ];
  let frameworkAll = [];
  if (typeOfMiddlePaneList === 'assessmentDistinctSubScaleDistinct') {
    //frameworkAll.push(scale);
    frameworkAll = [
      {
        id: 'a3',
        labelTextOneOne: 'metrics',
        labelTextOneOneBadges: [
          {
            labelTextOneOneBadge: 'label',
            textOne: responseObject.assessmentScale?.assessmentScaleMetricLabel || 'No Information'
          },
          {
            labelTextOneOneBadge: 'description',
            textOne:
              responseObject.assessmentScale?.assessmentScaleMetricDescription || 'No Information'
          },
          {
            labelTextOneOneBadge: 'explanation',
            textOne:
              responseObject.assessmentScale?.assessmentScaleMetricExplanation || 'No Information'
          },
          {
            labelTextOneOneBadge: 'score',
            textOne: responseObject?.assessmentScale?.assessmentScaleMetricScore || 'No Information'
          },
          {
            labelTextOneOneBadge: 'weight',
            textOne:
              responseObject?.assessmentScale?.assessmentScaleMetricWeightage || 'No Information'
          }
        ],
        innerAssociateList: [],
        innerInfo: 'No Information',
        isListCard: false
      }
    ];
  } else {
    frameworkAll = [
      {
        id: 'a3',
        labelTextOneOne: 'metrics',
        labelTextOneOneBadgeOne: 'distinct',
        labelTextOneOneBadgeTwo: '',
        labelTextOneOneBadgeThree: '',
        labelTextOneOneBadgeFour: '',
        labelTextOneOneBadges: [
          {
            labelTextOneOneBadge: 'distinct',
            innerList: frameworkList.sort((a, b) =>
            a.textOne !== b.textOne ? (a.textOne < b.textOne ? -1 : 1) : 0
          )
          }
        ],
        innerInfo: 'No Information',
        isListCard: true
      }
    ];
  }

  // const frameworkAll = [
  //   {
  //     id: 'a6',
  //     labelTextOneOne: 'score',
  //     isListCard: false,
  //     textOneOne: responseObject?.assessmentScaleScore || 'No Information',
  //     innerAssociateList: [],
  //     innerInfo: 'No Information',
  //     IconOne: null
  //   },
  //   {
  //     id: 'a7',
  //     labelTextOneOne: 'weightage',
  //     isListCard: false,
  //     textOneOne: responseObject?.assessmentScaleWeightage || 'No Information',
  //     innerAssociateList: [],
  //     innerInfo: 'No Information',
  //     IconOne: null
  //   }
  // ];

  const onClickRevise = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    console.log('labelName', labelName);
    console.log('selectedBadgeName', selectedBadgeName);
    // if (labelName === 'create') {
    //   dispatch({
    //     type: SET_POPUP_VALUE,
    //     payload: { isPopUpValue: 'LABELPOPUP', popupMode: 'SCALECREATE' }
    //   });
    // }
    // if (labelName === 'weightage') {
    //   dispatch({
    //     type: SET_POPUP_VALUE,
    //     payload: { isPopUpValue: 'WEIGTAGEPOPUP', popupMode: 'SCALECREATE' }
    //   });
    // }
    // if (labelName === 'score') {
    //   dispatch({
    //     type: SET_POPUP_VALUE,
    //     payload: { isPopUpValue: 'SCOREPOPUP', popupMode: 'SCALECREATE' }
    //   });
    // }
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: { stateName: 'assessmentSelectedMetric', value: selectedTagValue }
    });
    if (labelName === 'metrics' && selectedBadgeName === 'label') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'LABELPOPUP', popupMode: 'SCALECREATE' }
      });
    }
    if (labelName === 'metrics' && selectedBadgeName === 'explanation') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'EXPLANATIONPOPUP', popupMode: 'SCALECREATE' }
      });
    }
    if (labelName === 'metrics' && selectedBadgeName === 'weight') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'WEIGTAGEPOPUP', popupMode: 'SCALECREATE' }
      });
    }
    if (labelName === 'metrics' && selectedBadgeName === 'score') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'SCOREPOPUP', popupMode: 'SCALECREATE' }
      });
    }
    if (labelName === 'metrics' && selectedBadgeName === 'distinct') {
      dispatch({ type: SET_MOBILE_PANE_STATE, payload: 'displayPaneTwo' });
      dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
      dispatch({ type: LOADER_START });
      let relatedData = relatedReviewListDistinctData[0];
      let responseObj = relatedData.scales.filter((dd) => {
        return dd.id === selectedTagValue;
      });
      let response = { ...relatedData, subscale: responseObj[0] };
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'assessmentSelectedScale', value: selectedTagValue }
      });
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'relatedReviewListDistinctData', value: [response] }
      });
      setTimeout(function () {
        dispatch({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: 'assessment',
            middlePaneHeaderBadgeOne: 'metrics',
            middlePaneHeaderBadgeTwo: 'distinct',
            middlePaneHeaderBadgeThree: '',
            middlePaneHeaderBadgeFour: '',
            typeOfMiddlePaneList: 'assessmentDistinctSubScaleDistinct',
            scanCount: responseObj[0].assessmentScaleMetric.length,
            showMiddlePaneState: true
          }
        });
        console.log('metricsresponse', response);
        dispatch({ type: LOADER_STOP });
        //dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
      }, 2000);
    }
  };
  return (
    <div>
      <>
        <div className={'containerPadding'}>
          <Paper className={'dossierContainerTop'}>
            {frameworkAll.map((ob) => {
              return (
                <div key={ob.id}>
                  {ob.isListCard ? (
                    <DisplayPanelAccordianReviewListOne
                      className=""
                      accordianObject={ob}
                      mode={reviewMode}
                      onClickRevise={onClickRevise}
                    />
                  ) : (
                    <DisplayPanelAccordianInformation
                      accordianObject={ob}
                      mode={reviewMode}
                      onClickRevise={onClickRevise}
                    />
                  )}
                </div>
              );
            })}
          </Paper>
        </div>
      </>
      {isMobile && (
        <div className={'containerPadding'} style={{ height: '55px' }}>
          {' '}
        </div>
      )}
    </div>
  );
};

export default DisplayPaneThreeSectionTwoAssessmentScale;
