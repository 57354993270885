import React, { Fragment, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import NextIcon from '@material-ui/icons/ArrowForward';
import MoreVert from '@material-ui/icons/MoreVert';
import SearchIcon from '@material-ui/icons/Search';
import Clear from '@material-ui/icons/Clear';
import { isMobile } from 'react-device-detect';
import './HeaderCard.css';
import {
  POPUP_OPEN,
  SET_POPUP_VALUE,
  SET_POPUP_STATE,
  SET_SCAN_POPUP_STATE,
  SET_MOBILE_PANE_STATE,
  SET_POPUP_SINGLE_STATE
} from '../../actionType';
import { useDispatch, useSelector } from 'react-redux';
import {
  ASSESSEE_ASSOCIATE_TRIPPLE_DOT_POPUP_OPTION,
  LEFT_TRIPPLE_DOT_POPUP_OPTION,
  TRIPPLE_DOT_POPUP_OPTION,
  ASSESSMENT_FINISH_POPUP_OPTION,
  ASSESSEE_ASSIGNMENT_TRIPLE_POPUP_OPTION,
  MINE_ASSIGNMENT_POPUP,
  TRIPPLE_DOT_ASSESSMENT_ASSIGNMENT_ASSOCIATE_POPUP_OPTION,
  SUBMODULE_SELF_TRIPPLE_DOT_POPUP_OPTION,
  ASSESSEE_ASSIGNMENT_ASSESSMENT_TRIPLE_POPUP_OPTION
} from '../../PopUpConfig';
import {
  closeRelatedList,
  getPopUpOptionList,
  getUpdatedTypeOfMiddlePaneList
} from '../../Actions/GenericActions';

const HeaderCard = (props) => {
  const {
    headerOne = '',
    headerOneBadgeOne = '',
    headerOneBadgeTwo = '',
    headerOneBadgeThree = '',
    headerOneBadgeFour = '',
    displayPane = '',
    scanCount,
    headerPanelColour,
    showMiddlePaneState,
    onClickClearInfo = null,
    showClearIcon = false
  } = props;
  const dispatch = useDispatch();
  const {
    typeOfMiddlePaneList,
    middlePaneHeader,
    middlePaneHeaderBadgeOne,
    middlePaneHeaderBadgeTwo,
    middlePaneHeaderBadgeThree,
    nodeViewState,
    middlePaneSelectedValue,
    middlePaneListPopupOptions,
    selectedTagsArray,
    signedAssesseePermission,
    relatedReviewListDistinctData,
    reviewListDistinctData,
    middlePaneHeaderDuplicate,
    middlePaneHeaderDuplicateBadgeOne,
    middlePaneHeaderDuplicateBadgeTwo,
    middlePaneHeaderDuplicateBadgeThree,
    middlePaneHeaderDuplicateBadgeFour,
    duplicateScanCount,
    classification,
    isSelectActive
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const {
    headerOne: rightPaneHeaderOne,
    headerOneBadgeOne: rightPaneBadgeOne,
    reviewMode,
    responseObject,
    createMode
  } = useSelector((state) => state.DisplayPaneThreeReducer);
  const { FilterMode } = useSelector((state) => state.FilterReducer);
  const {
    selectedTagValue,
    isPopUpValue,
    cardValue,
    selectedTagStatus,
    selectedTagShared,
    isFlaged,
    originPrimaryArrOprion,
    popupOptionArr
  } = useSelector((state) => state.PopUpReducer);
  const { assesseeAssessmentStartData, isExamMode, isItemTrial } = useSelector(
    (state) => state.AssesseeAssignmentAssessmentReducer
  );
  const onClickScan = () => {
    dispatch({
      type: SET_SCAN_POPUP_STATE,
      payload: {
        scanHeader: middlePaneHeader,
        scanHeaderBadgeOne:
          // middlePaneHeaderBadgeOne === 'distinct' ||
          middlePaneHeaderBadgeOne === 'active' ||
            middlePaneHeaderBadgeOne === 'inactive' ||
            middlePaneHeaderBadgeOne === 'all' ||
            middlePaneHeaderBadgeOne === 'archived'
            ? ''
            : middlePaneHeaderBadgeOne,
        scanHeaderBadgeTwo: 'scan'
      }
    });
    dispatch({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: typeOfMiddlePaneList,
        popupMode: 'SCAN_POPUP_FUN'
      }
    });
  };
  const openMiddlePaneTripleDotPopup = () => {
    let optArr = [];
    debugger
    if (middlePaneHeaderBadgeOne !== 'distinct' && cardValue !== 'Card') {
      middlePaneHeader === 'assessees' ||
        middlePaneHeader === 'administrators' ||
        middlePaneHeader === 'managers' ||
        middlePaneHeader === 'assistants'
        ? (optArr = [...TRIPPLE_DOT_POPUP_OPTION])
        : (optArr = [...TRIPPLE_DOT_ASSESSMENT_ASSIGNMENT_ASSOCIATE_POPUP_OPTION]);
      let newObj = {
        data: middlePaneHeader,
        dataValue: middlePaneHeader,
        dataKey: 'reviewDistinct',
        optionClass: 'optionPrimary',
        // divider: 'dark',
        disabled: false,
        permission:
          middlePaneHeader.slice(0, -1).charAt(0).toUpperCase() +
          middlePaneHeader.slice(1, -1) +
          'Distinct',
        permissionValue: 'all'
      };
      optArr.splice(11, 0, newObj);
      console.log('origin optArr', optArr);
    } else if (
      middlePaneHeader === 'assignments' &&
      middlePaneHeaderBadgeTwo === 'mine' &&
      typeOfMiddlePaneList === 'associateMineAssignmentDistinctReviewList'
    ) {
      console.log('MINE_ASSIGNMENT_POPUP', MINE_ASSIGNMENT_POPUP);
      MINE_ASSIGNMENT_POPUP.forEach((element) => {
        if (scanCount === 0) {
          if (element.data === 'select' || element.data === 'pay') {
            optArr.push({ ...element, disabled: true });
          } else {
            optArr.push({ ...element });
          }
        } else if (selectedTagsArray.length === 0) {
          if (element.data === 'pay') {
            optArr.push({ ...element, disabled: true });
          } else {
            optArr.push({ ...element });
          }
        } else optArr.push({ ...element });
      });

      dispatch({
        type: SET_POPUP_VALUE,
        payload: {
          isPopUpValue: 'You must select assignments Before proceeding to pay',
          popupMode: 'responseErrorMsg'
        }
      });
      // optArr = MINE_ASSIGNMENT_POPUP;
    } else if (
      middlePaneHeader === 'assignments' &&
      middlePaneHeaderBadgeOne === 'distinct' &&
      typeOfMiddlePaneList === 'assignmentDistinctReviewList'
    ) {
      optArr = [...ASSESSEE_ASSOCIATE_TRIPPLE_DOT_POPUP_OPTION];
      let newObj = {
        data: 'administer',
        dataValue: 'administer',
        dataKey: 'administer',
        optionClass: 'optionPrimary',
        disabled: true,
        permissionValue: 'revise'
      };
      let newObj1 = {
        data: 'publish',
        dataValue: 'publish',
        dataKey: 'publish',
        optionClass: 'optionPrimary',
        disabled: true,
        permissionValue: 'revise'
      };
      optArr.splice(0, 0, newObj);
      optArr.splice(7, 0, newObj1);
    } else if (
      (middlePaneHeader === 'items' || middlePaneHeader === 'assessments') &&
      middlePaneHeaderBadgeOne === 'distinct'
    ) {
      optArr = [...ASSESSEE_ASSOCIATE_TRIPPLE_DOT_POPUP_OPTION];
      let newObj1 = {
        data: 'publish',
        dataValue: 'publish',
        dataKey: 'publish',
        optionClass: 'optionPrimary',
        disabled: true,
        permissionValue: 'revise'
      };
      optArr.splice(6, 0, newObj1);
    } else if (
      (middlePaneHeader === 'assessees' ||
        middlePaneHeader === 'managers' ||
        middlePaneHeader === 'administrators' ||
        middlePaneHeader === 'assistants') &&
      middlePaneHeaderBadgeOne === 'distinct'
    ) {
      optArr = [...ASSESSEE_ASSOCIATE_TRIPPLE_DOT_POPUP_OPTION];
      let newObj1 = {
        data: 'approve',
        dataValue: 'approve',
        dataKey: 'approve',
        optionClass: 'optionPrimary',
        disabled: false,
        permissionValue: 'revise'
      };
      optArr.splice(1, 0, newObj1);
    } else if (middlePaneHeader === 'gauges' && middlePaneHeaderBadgeOne === 'distinct') {
      optArr = [...SUBMODULE_SELF_TRIPPLE_DOT_POPUP_OPTION];
    } else if (middlePaneHeader === 'associate' && middlePaneHeaderBadgeOne === 'nodes') {
      optArr = [...SUBMODULE_SELF_TRIPPLE_DOT_POPUP_OPTION];
    } else if (middlePaneHeader === 'associates' && middlePaneHeaderBadgeOne === 'distinct') {
      optArr = [...ASSESSEE_ASSOCIATE_TRIPPLE_DOT_POPUP_OPTION];
      let newObj1 = {
        data: 'approve',
        dataValue: 'approve',
        dataKey: 'approve',
        optionClass: 'optionPrimary',
        disabled: false,
        permissionValue: 'revise'
      };
      optArr.splice(1, 0, newObj1);
    } else if (
      cardValue == 'Card' &&
      (middlePaneHeaderBadgeOne === 'groups' ||
        middlePaneHeaderBadgeOne === 'roles' ||
        middlePaneHeaderBadgeOne === 'nodes' ||
        middlePaneHeaderBadgeOne === 'types')
    ) {
      optArr = SUBMODULE_SELF_TRIPPLE_DOT_POPUP_OPTION;
    } else {
      optArr = ASSESSEE_ASSOCIATE_TRIPPLE_DOT_POPUP_OPTION;
    }
    if (
      middlePaneHeader === 'assignments' &&
      middlePaneHeaderBadgeOne !== 'distinct' &&
      middlePaneHeaderBadgeOne !== 'groups' &&
      middlePaneHeaderBadgeOne !== 'nodes' &&
      middlePaneHeaderBadgeOne !== 'types'
    ) {
      optArr = ASSESSEE_ASSIGNMENT_TRIPLE_POPUP_OPTION;
    }
    if (
      middlePaneHeader === 'assessments' &&
      middlePaneHeaderBadgeOne !== 'distinct' &&
      middlePaneHeaderBadgeOne !== 'groups' &&
      middlePaneHeaderBadgeOne !== 'nodes' &&
      middlePaneHeaderBadgeOne !== 'types'
    ) {
      optArr = ASSESSEE_ASSIGNMENT_ASSESSMENT_TRIPLE_POPUP_OPTION;
    }
    if (middlePaneHeader === 'certificates' || middlePaneHeader === 'reports') {
      optArr = ASSESSEE_ASSIGNMENT_ASSESSMENT_TRIPLE_POPUP_OPTION;
    }
    let tempArr = [...optArr];
    console.log('tempArr', tempArr);
    let popupContentArrValue = [];
    // if (selectedTagsArray.length > 0) {
    if (middlePaneHeader === 'assessees') {
      let assesseeDistinctPermission = '';
      if (middlePaneHeader === 'assessees' && middlePaneHeaderBadgeOne === 'distinct') {
        assesseeDistinctPermission =
          signedAssesseePermission?.assesseeDistinctPermission?.assesseeAssesseeDistinctPermission;
        popupContentArrValue = getPopUpOptionList(tempArr, assesseeDistinctPermission);
      } else if (middlePaneHeader === 'assessees' && middlePaneHeaderBadgeOne === 'groups') {
        assesseeDistinctPermission =
          signedAssesseePermission?.assesseeDistinctPermission?.assesseeAssesseeGroupPermission;
        popupContentArrValue = getPopUpOptionList(tempArr, assesseeDistinctPermission);
      } else if (middlePaneHeader === 'assessees' && middlePaneHeaderBadgeOne === 'roles') {
        assesseeDistinctPermission =
          signedAssesseePermission?.assesseeDistinctPermission?.assesseeAssesseeRolePermission;
        popupContentArrValue = getPopUpOptionList(tempArr, assesseeDistinctPermission);
      } else if (middlePaneHeader === 'assessees' && middlePaneHeaderBadgeOne === 'nodes') {
        assesseeDistinctPermission =
          signedAssesseePermission?.assesseeDistinctPermission?.assesseeAssociateNodePermission;
        popupContentArrValue = getPopUpOptionList(tempArr, assesseeDistinctPermission);
      } else if (middlePaneHeader === 'assessees' && middlePaneHeaderBadgeOne === 'types') {
        assesseeDistinctPermission =
          signedAssesseePermission?.assesseeDistinctPermission?.assesseeAssesseeTypePermission;
        popupContentArrValue = getPopUpOptionList(tempArr, assesseeDistinctPermission);
      }
    } else if (middlePaneHeader === 'assessments') {
      let assessmentDistinctPermission = '';
      if (middlePaneHeader === 'assessments' && middlePaneHeaderBadgeOne === 'distinct') {
        let assessmentDistinctPermission =
          signedAssesseePermission?.assessmentDistictPermission
            ?.assesseeAssessmentDistinctPermission;
        assessmentDistinctPermission =
          signedAssesseePermission?.assessmentDistictPermission
            ?.assesseeAssessmentDistinctPermission;
        popupContentArrValue = getPopUpOptionList(tempArr, assessmentDistinctPermission);
      } else if (middlePaneHeader === 'assessments' && middlePaneHeaderBadgeOne === 'groups') {
        assessmentDistinctPermission =
          signedAssesseePermission?.assessmentDistictPermission?.assesseeAssessmentGroupPermission;
        popupContentArrValue = getPopUpOptionList(tempArr, assessmentDistinctPermission);
      } else if (middlePaneHeader === 'assessments' && middlePaneHeaderBadgeOne === 'nodes') {
        assessmentDistinctPermission =
          signedAssesseePermission?.assessmentDistictPermission?.assesseeAssociateNodePermission;
        popupContentArrValue = getPopUpOptionList(tempArr, assessmentDistinctPermission);
      } else if (middlePaneHeader === 'assessments' && middlePaneHeaderBadgeOne === 'types') {
        assessmentDistinctPermission =
          signedAssesseePermission?.assessmentDistictPermission?.assesseeAssessmentTypePermission;
        popupContentArrValue = getPopUpOptionList(tempArr, assessmentDistinctPermission);
      }
    } else if (middlePaneHeader === 'assignments') {
      let assigmentDistictPermission = '';
      if (middlePaneHeader === 'assignments' && middlePaneHeaderBadgeOne === 'distinct') {
        assigmentDistictPermission =
          signedAssesseePermission?.assigmentDistictPermission
            ?.assesseeAssignmentDistinctPermission;
        popupContentArrValue = getPopUpOptionList(tempArr, assigmentDistictPermission);
      } else if (middlePaneHeader === 'assignments' && middlePaneHeaderBadgeOne === 'groups') {
        assigmentDistictPermission =
          signedAssesseePermission?.assigmentDistictPermission?.assesseeAssignmentGroupPermission;
        popupContentArrValue = getPopUpOptionList(tempArr, assigmentDistictPermission);
      } else if (middlePaneHeader === 'assignments' && middlePaneHeaderBadgeOne === 'nodes') {
        assigmentDistictPermission =
          signedAssesseePermission?.assigmentDistictPermission?.assesseeAssociateNodePermission;
        popupContentArrValue = getPopUpOptionList(tempArr, assigmentDistictPermission);
      } else if (middlePaneHeader === 'assignments' && middlePaneHeaderBadgeOne === 'types') {
        assigmentDistictPermission =
          signedAssesseePermission?.assigmentDistictPermission?.assesseeAssignmentTypePermission;
        popupContentArrValue = getPopUpOptionList(tempArr, assigmentDistictPermission);
      }
    } else if (middlePaneHeader === 'associates') {
      let associateDistinctPermission = '';
      if (middlePaneHeader === 'associates' && middlePaneHeaderBadgeOne === 'distinct') {
        associateDistinctPermission =
          signedAssesseePermission?.associateDistinctPermission
            ?.assesseeAssociateDistinctPermission;
        popupContentArrValue = getPopUpOptionList(tempArr, associateDistinctPermission);
      } else if (middlePaneHeader === 'associates' && middlePaneHeaderBadgeOne === 'groups') {
        associateDistinctPermission =
          signedAssesseePermission?.associateDistinctPermission?.assesseeAssociateGroupPermission;
        popupContentArrValue = getPopUpOptionList(tempArr, associateDistinctPermission);
      } else if (middlePaneHeader === 'associates' && middlePaneHeaderBadgeOne === 'nodes') {
        associateDistinctPermission =
          signedAssesseePermission?.associateDistinctPermission?.assesseeAssociateNodePermission;
        popupContentArrValue = getPopUpOptionList(tempArr, associateDistinctPermission);
      } else if (middlePaneHeader === 'associates' && middlePaneHeaderBadgeOne === 'types') {
        associateDistinctPermission =
          signedAssesseePermission?.associateDistinctPermission?.assesseeAssociateTypePermission;
        popupContentArrValue = getPopUpOptionList(tempArr, associateDistinctPermission);
      } else if (middlePaneHeader === 'associates' && middlePaneHeaderBadgeOne === 'roles') {
        associateDistinctPermission =
          signedAssesseePermission?.associateDistinctPermission?.assesseeAssociateRolePermission;
        popupContentArrValue = getPopUpOptionList(tempArr, associateDistinctPermission);
      }
    } else if (middlePaneHeader === 'associate') {
      let associateDistinctPermission = '';
      if (middlePaneHeader === 'associate' && middlePaneHeaderBadgeOne === 'distinct') {
        associateDistinctPermission =
          signedAssesseePermission?.associateDistinctPermission
            ?.assesseeAssociateDistinctPermission;
        popupContentArrValue = getPopUpOptionList(tempArr, associateDistinctPermission);
      } else if (middlePaneHeader === 'associate' && middlePaneHeaderBadgeOne === 'groups') {
        associateDistinctPermission =
          signedAssesseePermission?.associateDistinctPermission?.assesseeAssociateGroupPermission;
        popupContentArrValue = getPopUpOptionList(tempArr, associateDistinctPermission);
      } else if (middlePaneHeader === 'associate' && middlePaneHeaderBadgeOne === 'nodes') {
        associateDistinctPermission =
          signedAssesseePermission?.associateDistinctPermission?.assesseeAssociateNodePermission;
        popupContentArrValue = getPopUpOptionList(tempArr, associateDistinctPermission);
      } else if (middlePaneHeader === 'associate' && middlePaneHeaderBadgeOne === 'types') {
        associateDistinctPermission =
          signedAssesseePermission?.associateDistinctPermission?.assesseeAssociateTypePermission;
        popupContentArrValue = getPopUpOptionList(tempArr, associateDistinctPermission);
      } else if (middlePaneHeader === 'associate' && middlePaneHeaderBadgeOne === 'roles') {
        associateDistinctPermission =
          signedAssesseePermission?.associateDistinctPermission?.assesseeAssociateRolePermission;
        popupContentArrValue = getPopUpOptionList(tempArr, associateDistinctPermission);
      }
    } else if (middlePaneHeader === 'items') {
      let associateDistinctPermission = '';
      if (middlePaneHeader === 'items' && middlePaneHeaderBadgeOne === 'distinct') {
        associateDistinctPermission =
          signedAssesseePermission?.itemDistictPermssion?.assesseeItemDistinctPermission;
        popupContentArrValue = getPopUpOptionList(tempArr, associateDistinctPermission);
      } else if (middlePaneHeader === 'items' && middlePaneHeaderBadgeOne === 'groups') {
        associateDistinctPermission =
          signedAssesseePermission?.itemDistictPermssion?.assesseeItemGroupPermission;
        popupContentArrValue = getPopUpOptionList(tempArr, associateDistinctPermission);
      } else if (middlePaneHeader === 'items' && middlePaneHeaderBadgeOne === 'nodes') {
        associateDistinctPermission =
          signedAssesseePermission?.associateDistinctPermission?.assesseeAssociateNodePermission;
        popupContentArrValue = getPopUpOptionList(tempArr, associateDistinctPermission);
      } else if (middlePaneHeader === 'items' && middlePaneHeaderBadgeOne === 'types') {
        associateDistinctPermission =
          signedAssesseePermission?.itemDistictPermssion?.assesseeItemTypePermission;
        popupContentArrValue = getPopUpOptionList(tempArr, associateDistinctPermission);
      }
    } else if (middlePaneHeader === 'culture-profiles') {
      let associateDistinctPermission = '';
      if (middlePaneHeader === 'culture-profiles' && middlePaneHeaderBadgeOne === 'distinct') {
        associateDistinctPermission =
          signedAssesseePermission?.iGuruAnalyticDistinctPermission
            ?.assesseeiGuruAnalyticDistinctPermission;
        popupContentArrValue = getPopUpOptionList(tempArr, associateDistinctPermission);
      } else if (middlePaneHeader === 'culture-profiles' && middlePaneHeaderBadgeOne === 'groups') {
        associateDistinctPermission =
          signedAssesseePermission?.iGuruAnalyticDistinctPermission
            ?.assesseeiGuruAnalyticGroupPermission;
        popupContentArrValue = getPopUpOptionList(tempArr, associateDistinctPermission);
      } else if (middlePaneHeader === 'culture-profiles' && middlePaneHeaderBadgeOne === 'nodes') {
        associateDistinctPermission =
          signedAssesseePermission?.associateDistinctPermission?.assesseeAssociateNodePermission;
        popupContentArrValue = getPopUpOptionList(tempArr, associateDistinctPermission);
      } else if (middlePaneHeader === 'culture-profiles' && middlePaneHeaderBadgeOne === 'types') {
        associateDistinctPermission =
          signedAssesseePermission?.iGuruAnalyticDistinctPermission
            ?.assesseeiGuruAnalyticTypePermission || {};
        popupContentArrValue = getPopUpOptionList(tempArr, associateDistinctPermission);
      }
    } else if (middlePaneHeader === 'job-profiles') {
      let associateDistinctPermission = '';
      if (middlePaneHeader === 'job-profiles' && middlePaneHeaderBadgeOne === 'distinct') {
        associateDistinctPermission =
          signedAssesseePermission?.iGuruAnalyticDistinctPermission
            ?.assesseeiGuruAnalyticDistinctPermission;
        popupContentArrValue = getPopUpOptionList(tempArr, associateDistinctPermission);
      } else if (middlePaneHeader === 'job-profiles' && middlePaneHeaderBadgeOne === 'groups') {
        associateDistinctPermission =
          signedAssesseePermission?.iGuruAnalyticDistinctPermission
            ?.assesseeiGuruAnalyticGroupPermission;
        popupContentArrValue = getPopUpOptionList(tempArr, associateDistinctPermission);
      } else if (middlePaneHeader === 'job-profiles' && middlePaneHeaderBadgeOne === 'nodes') {
        associateDistinctPermission =
          signedAssesseePermission?.associateDistinctPermission?.assesseeAssociateNodePermission;
        popupContentArrValue = getPopUpOptionList(tempArr, associateDistinctPermission);
      } else if (middlePaneHeader === 'job-profiles' && middlePaneHeaderBadgeOne === 'types') {
        associateDistinctPermission =
          signedAssesseePermission?.iGuruAnalyticDistinctPermission
            ?.assesseeiGuruAnalyticTypePermission || {};
        popupContentArrValue = getPopUpOptionList(tempArr, associateDistinctPermission);
      }
    } else if (middlePaneHeader === 'gauges') {
      let associateDistinctPermission = '';
      if (middlePaneHeader === 'gauges' && middlePaneHeaderBadgeOne === 'distinct') {
        associateDistinctPermission =
          signedAssesseePermission?.iGuruAnalyticDistinctPermission
            ?.assesseeiGuruAnalyticDistinctPermission;
        // popupContentArrValue = getPopUpOptionList(tempArr, associateDistinctPermission);

        console.log(associateDistinctPermission, "##")

        tempArr.forEach((element) => {
          let data = element?.permissionValue;
          if (
            data === 'review & other' &&
            element.data === 'select' &&
            associateDistinctPermission?.review &&
            (associateDistinctPermission?.revise ||
              associateDistinctPermission?.create ||
              associateDistinctPermission?.delete ||
              associateDistinctPermission.sell)
          ) {

            element.disabled = false;
          } else {
            if (data === 'review & other' && element.data === 'select' && associateDistinctPermission?.review) {
              element.disabled = true;
            } else if (data in associateDistinctPermission) {
              element.disabled = !associateDistinctPermission[data];
            }
          }
        })

        popupContentArrValue = tempArr;
      } else if (middlePaneHeader === 'gauges' && middlePaneHeaderBadgeOne === 'groups') {
        associateDistinctPermission =
          signedAssesseePermission?.iGuruAnalyticDistinctPermission
            ?.assesseeiGuruAnalyticGroupPermission;
        popupContentArrValue = getPopUpOptionList(tempArr, associateDistinctPermission);
      } else if (middlePaneHeader === 'gauges' && middlePaneHeaderBadgeOne === 'nodes') {
        associateDistinctPermission =
          signedAssesseePermission?.associateDistinctPermission?.assesseeAssociateNodePermission;
        popupContentArrValue = getPopUpOptionList(tempArr, associateDistinctPermission);
      } else if (middlePaneHeader === 'gauges' && middlePaneHeaderBadgeOne === 'types') {
        associateDistinctPermission =
          signedAssesseePermission?.iGuruAnalyticDistinctPermission
            ?.assesseeiGuruAnalyticTypePermission || {};
        popupContentArrValue = getPopUpOptionList(tempArr, associateDistinctPermission);
      }
    } else if (middlePaneHeader === 'profilers') {
      let associateDistinctPermission = '';
      if (middlePaneHeader === 'profilers' && middlePaneHeaderBadgeOne === 'distinct') {
        associateDistinctPermission =
          signedAssesseePermission?.iGuruAnalyticDistinctPermission
            ?.assesseeiGuruAnalyticDistinctPermission;
        popupContentArrValue = getPopUpOptionList(tempArr, associateDistinctPermission);
      }
    } else if (middlePaneHeader === 'administrators') {
      let assesseeDistinctPermission = '';
      if (middlePaneHeader === 'administrators' && middlePaneHeaderBadgeOne === 'distinct') {
        assesseeDistinctPermission =
          signedAssesseePermission?.assesseeDistinctPermission?.assesseeAssesseeDistinctPermission;
        popupContentArrValue = getPopUpOptionList(tempArr, assesseeDistinctPermission);
      } else if (middlePaneHeader === 'administrators' && middlePaneHeaderBadgeOne === 'groups') {
        assesseeDistinctPermission =
          signedAssesseePermission?.assesseeDistinctPermission?.assesseeAssesseeGroupPermission;
        popupContentArrValue = getPopUpOptionList(tempArr, assesseeDistinctPermission);
      } else if (middlePaneHeader === 'administrators' && middlePaneHeaderBadgeOne === 'roles') {
        assesseeDistinctPermission =
          signedAssesseePermission?.assesseeDistinctPermission?.assesseeAssesseeRolePermission;
        popupContentArrValue = getPopUpOptionList(tempArr, assesseeDistinctPermission);
      } else if (middlePaneHeader === 'administrators' && middlePaneHeaderBadgeOne === 'nodes') {
        assesseeDistinctPermission =
          signedAssesseePermission?.assesseeDistinctPermission?.assesseeAssociateNodePermission;
        popupContentArrValue = getPopUpOptionList(tempArr, assesseeDistinctPermission);
      } else if (middlePaneHeader === 'administrators' && middlePaneHeaderBadgeOne === 'types') {
        assesseeDistinctPermission =
          signedAssesseePermission?.assesseeDistinctPermission?.assesseeAssesseeTypePermission;
        popupContentArrValue = getPopUpOptionList(tempArr, assesseeDistinctPermission);
      }
    } else if (middlePaneHeader === 'managers' || middlePaneHeader === 'assistants') {
      let assesseeDistinctPermission = '';
      if (
        (middlePaneHeader === 'managers' || middlePaneHeader === 'assistants') &&
        middlePaneHeaderBadgeOne === 'distinct'
      ) {
        assesseeDistinctPermission =
          signedAssesseePermission?.assesseeDistinctPermission?.assesseeAssesseeDistinctPermission;
        popupContentArrValue = getPopUpOptionList(tempArr, assesseeDistinctPermission);
      } else if (
        (middlePaneHeader === 'managers' || middlePaneHeader === 'assistants') &&
        middlePaneHeaderBadgeOne === 'groups'
      ) {
        assesseeDistinctPermission =
          signedAssesseePermission?.assesseeDistinctPermission?.assesseeAssesseeGroupPermission;
        popupContentArrValue = getPopUpOptionList(tempArr, assesseeDistinctPermission);
      } else if (
        (middlePaneHeader === 'managers' || middlePaneHeader === 'assistants') &&
        middlePaneHeaderBadgeOne === 'roles'
      ) {
        assesseeDistinctPermission =
          signedAssesseePermission?.assesseeDistinctPermission?.assesseeAssesseeRolePermission;
        popupContentArrValue = getPopUpOptionList(tempArr, assesseeDistinctPermission);
      } else if (
        (middlePaneHeader === 'managers' || middlePaneHeader === 'assistants') &&
        middlePaneHeaderBadgeOne === 'nodes'
      ) {
        assesseeDistinctPermission =
          signedAssesseePermission?.assesseeDistinctPermission?.assesseeAssociateNodePermission;
        popupContentArrValue = getPopUpOptionList(tempArr, assesseeDistinctPermission);
      } else if (
        (middlePaneHeader === 'managers' || middlePaneHeader === 'assistants') &&
        middlePaneHeaderBadgeOne === 'types'
      ) {
        assesseeDistinctPermission =
          signedAssesseePermission?.assesseeDistinctPermission?.assesseeAssesseeTypePermission;
        popupContentArrValue = getPopUpOptionList(tempArr, assesseeDistinctPermission);
      }
    }
    // }
    // console.log('after per chk popupContentArrValue', popupContentArrValue);
    let finalPopupOptionArray = [];
    if (typeOfMiddlePaneList === 'assessmentgradesReviewList') {
      popupContentArrValue.forEach((element) => {
        if (
          relatedReviewListDistinctData[0].assessmentStatus === 'PUBLISHED' &&
          element.data === 'create'
        ) {
          finalPopupOptionArray.push({ ...element, disabled: true });
        } else {
          finalPopupOptionArray.push(element);
        }
      });
    } else if (
      typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList' ||
      typeOfMiddlePaneList === 'assesseeAssignmentAssessmentReviewList' ||
      typeOfMiddlePaneList === 'assignmentDistinctAssesseeReviewList'
    ) {
      tempArr.forEach((element) => {
        if (element.data === 'create' || element.data === 'select') {
          finalPopupOptionArray.push({ ...element, disabled: true });
        } else {
          finalPopupOptionArray.push(element);
        }
      });
    } else if (
      FilterMode === 'assesseeRoleDistinctinactive' ||
      FilterMode === 'associateDistinctinactive' ||
      FilterMode === 'assesseesTypeDistinctinactive' ||
      FilterMode === 'assessmentsTypeDistinctinactive' ||
      FilterMode === 'assignmentsTypeDistinctinactive' ||
      FilterMode === 'associatesTypeDistinctterminated' ||
      FilterMode === 'associatesTypeDistinctinactive' ||
      FilterMode === 'associatesTypeDistinctsuspended' ||
      FilterMode === 'cultureProfileTypeDistinctterminated' ||
      FilterMode === 'cultureProfileTypeDistinctinactive' ||
      FilterMode === 'cultureProfileTypeDistinctsuspended' ||
      FilterMode === 'jobProfileTypeDistinctterminated' ||
      FilterMode === 'jobProfileTypeDistinctinactive' ||
      FilterMode === 'jobProfileTypeDistinctsuspended' ||
      FilterMode === 'itemsTypeDistinctterminated' ||
      FilterMode === 'itemsTypeDistinctinactive' ||
      FilterMode === 'itemsTypeDistinctsuspended' ||
      FilterMode === 'assesseesGroupDistinctinactive' ||
      FilterMode === 'assessmentGroupDistinctinactive' ||
      FilterMode === 'assignmentsGroupDistinctinactive' ||
      FilterMode === 'associateGroupDistinctinactive' ||
      FilterMode === 'cultureProfileGroupterminated' ||
      FilterMode === 'cultureProfileGroupinactive' ||
      FilterMode === 'cultureProfileGroupsuspended' ||
      FilterMode === 'jobProfileTypeDistinctterminated' ||
      FilterMode === 'jobProfileGroupinactive' ||
      FilterMode === 'jobProfileGroupsuspended' ||
      FilterMode === 'itemsTypeDistinctterminated' ||
      FilterMode === 'itemsTypeDistinctinactive' ||
      FilterMode === 'assesseeGroupAssesseeDistinctinactive' ||
      FilterMode === 'assignmentsDistinctsuspended' || //
      FilterMode === 'assignmentsDistinctterminated' ||
      FilterMode === 'assignmentsDistinctunpublished' ||
      FilterMode === 'cultureProfileDistinctsuspended' ||
      FilterMode === 'cultureProfileDistinctterminated' ||
      FilterMode === 'jobProfileDistinctsuspended' ||
      FilterMode === 'jobProfileDistinctterminated' ||
      FilterMode === 'allocateToAssessee' ||
      FilterMode === 'associateRoleDistinctinactive' ||
      FilterMode === 'assesseesTypeDistinctarchived' ||
      FilterMode === 'assessmentsTypeDistinctarchived' ||
      FilterMode === 'assignmentsTypeDistinctarchived' ||
      FilterMode === 'associatesTypeDistinctarchived' ||
      FilterMode === 'itemsTypeDistinctarchived' ||
      FilterMode === 'associateDistinctarchived' ||
      // FilterMode === 'assesseeDistinctactive' ||
      FilterMode === 'associateNodeDistinctinactive' ||
      FilterMode === 'itemsTypeDistinctsuspended' ||
      FilterMode === `assesseeAssignment${middlePaneHeaderBadgeTwo}`
    ) {
      tempArr.forEach((element) => {
        if (
          selectedTagsArray.length > 0 &&
          (element.data === 'allocate' ||
            element.data === 'share' ||
            element.data === 'create' ||
            element.data === 'review')
        ) {
          finalPopupOptionArray.push({ ...element, disabled: true });
        } else {
          finalPopupOptionArray.push(element);
        }
      });
      // } else if(FilterMode === 'assesseeDistinctactive'){
      // tempArr.forEach((element) => {
      //   if (
      //     selectedTagsArray.length > 0 &&
      //     ( element.data === 'create')
      //   ) {
      //     finalPopupOptionArray.push({ ...element, disabled: true });
      //   } else {
      //     finalPopupOptionArray.push(element);
      //   }
      // });
    } else if (selectedTagsArray.length > 0) {
      tempArr.forEach((element) => {
        if (
          element.data === 'create' ||
          element.data === 'review' ||
          // element.data === 'move' ||
          element.data === 'select' ||
          (element.data === 'administer' && headerOneBadgeTwo !== 'active') ||
          // element.data === 'publish' ||
          element.data === 'notifications' ||
          element.data === 'reports' ||
          (element.data === 'share' &&
            middlePaneHeader === 'associates' &&
            middlePaneHeaderBadgeOne === 'distinct')
        )
          finalPopupOptionArray.push({ ...element, disabled: true });
        else if (
          (middlePaneHeaderBadgeTwo === 'bespoke' ||
            middlePaneHeaderBadgeThree === 'bespoke' ||
            middlePaneHeaderBadgeTwo === 'unpublished' ||
            middlePaneHeaderBadgeTwo === 'disapproved' ||
            middlePaneHeaderBadgeTwo === 'unapproved' ||
            middlePaneHeaderBadgeTwo === 'unconfirmed' ||
            middlePaneHeaderBadgeTwo === 'archived' ||
            middlePaneHeaderBadgeThree === 'archived' ||
            middlePaneHeaderBadgeTwo === 'terminated' ||
            middlePaneHeaderBadgeTwo === 'suspended') &&
          element.data === 'share'
        ) {
          finalPopupOptionArray.push({ ...element, disabled: true });
        } else if (
          (middlePaneHeaderBadgeTwo === 'archived' ||
            middlePaneHeaderBadgeThree === 'archived' ||
            middlePaneHeaderBadgeTwo === 'terminated' ||
            middlePaneHeaderBadgeThree === 'terminated' ||
            middlePaneHeaderBadgeTwo === 'suspended' ||
            middlePaneHeaderBadgeThree === 'suspended') &&
          (element.data === 'allocate' || element.data === 'approve')
        ) {
          finalPopupOptionArray.push({ ...element, disabled: true });
        } else if (middlePaneHeaderBadgeTwo === 'inactive' && element.data === 'share') {
          finalPopupOptionArray.push({ ...element, disabled: true });
        } else finalPopupOptionArray.push({ ...element, disabled: element.disabled });
      });
    } else {
      // console.log('last condtion for tempArr', tempArr);
      finalPopupOptionArray = [...popupContentArrValue];
    }
    // console.log('finalPopupOptionArray', finalPopupOptionArray);
    let allPermissionCheckPopUpArr = [];
    finalPopupOptionArray.forEach((element) => {
      if (selectedTagsArray.length === 0) {
        if (
          element.data === 'allocate' ||
          element.data === 'administer' ||
          element.data === 'archive' ||
          element.data === 'flag' ||
          element.data === 'share' ||
          element.data === 'suspend' ||
          element.data === 'publish' ||
          element.data === 'approve' ||
          element.data === 'terminate' ||
          element.data === 'delete' ||
          element.data === 'move' ||
          element.data === 'notifications' ||
          element.data === 'reports' ||
          (middlePaneHeader === 'profilers' && element.data === 'select') ||
          (nodeViewState === 'hierarchy' && element.data === 'select')
        ) {
          // console.log(element.data + '  11  ' + element.disabled);
          allPermissionCheckPopUpArr.push({ ...element, disabled: true });
        } else {
          // console.log(element.data + '  22 ' + element.disabled);
          allPermissionCheckPopUpArr.push({ ...element, disabled: element.disabled });
        }
      } else if (selectedTagsArray.length > 0) {
        if (
          element.data === 'create' ||
          element.data === 'review' ||
          // element.data === 'move' ||
          element.data === 'select' ||
          // element.data === 'administer' ||
          // element.data === 'publish' ||
          element.data === 'notifications' ||
          element.data === 'reports' ||
          (middlePaneHeader === 'gauges' &&
            (element.data === 'allocate' || element.data === 'flag' || element.data === 'share')) //||
          // (element.data === 'share' &&
          //   middlePaneHeaderBadgeOne === 'types' &&
          //   middlePaneHeaderBadgeThree === 'active')
        )
          allPermissionCheckPopUpArr.push({ ...element, disabled: true });
        else {
          // console.log(element.data + ' 33  ' + element.disabled);
          allPermissionCheckPopUpArr.push({ ...element, disabled: element.disabled });
        }
      } //else allPermissionCheckPopUpArr.push({ ...element, disabled: false });
    });

    // console.log('last condtion for allPermissionCheckPopUpArr', allPermissionCheckPopUpArr);
    let selfAssignmentBadges = {
      active: 'self',
      inactive: 'self',
      all: 'self',
      archived: 'self',
      finished: 'self',
      unfinished: 'self',
      unpublished: 'self',
      started: 'self',
      unstarted: 'self',
      suspended: 'self',
      terminated: 'self',
      upcoming: 'self'
    };
    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: middlePaneHeader,
        popupHeaderOneBadgeOne: selfAssignmentBadges.hasOwnProperty(middlePaneHeaderBadgeOne)
          ? ''
          : middlePaneHeaderBadgeOne,
        // middlePaneHeaderBadgeOne === 'distinct' ? '' : middlePaneHeaderBadgeOne,
        // popupHeaderOneBadgeTwo: middlePaneHeaderBadgeTwo === 'distinct' ? '' : middlePaneHeaderBadgeTwo,
        originPrimaryArrOprion: originPrimaryArrOprion,
        isPopUpValue: '',
        popupOpenType: 'primary',
        //popupContentArrValue: tempArr
        // popupContentArrValue: (finalPopupOptionArray.length > 0 && finalPopupOptionArray) || tempArr
        popupContentArrValue:
          (allPermissionCheckPopUpArr.length > 0 && allPermissionCheckPopUpArr) || tempArr
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'middlePaneTrippleDotPopup' });
  };
  const openLeftPaneTripleDotPopup = () => {
    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: 'dashboard',
        popupHeaderOneBadgeOne: '',
        isPopUpValue: '',
        popupOpenType: 'primary',
        popupContentArrValue: LEFT_TRIPPLE_DOT_POPUP_OPTION,
        originPrimaryArrOprion: originPrimaryArrOprion
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'leftPaneTrippleDotPopup' });
  };
  const openRightPaneTripleDotPopup = () => {

    if (
      (typeOfMiddlePaneList === 'assesseesGroupAssesseeReviewList' &&
        headerOneBadgeOne === 'group') ||
      (typeOfMiddlePaneList === 'assessmentGroupAssessmentReviewList' &&
        headerOneBadgeOne === 'group') ||
      (typeOfMiddlePaneList === 'assignmentGroupAssignmentReviewList' &&
        headerOneBadgeOne === 'group') ||
      (typeOfMiddlePaneList === 'associatesGroupAssociateReviewList' &&
        headerOneBadgeOne === 'group') ||
      (typeOfMiddlePaneList === 'cultureProfileGroupCultureProfileReviewList' &&
        headerOneBadgeOne === 'group') ||
      (typeOfMiddlePaneList === 'jobProfileGroupJobProfileReviewList' &&
        headerOneBadgeOne === 'group') ||
      (typeOfMiddlePaneList === 'itemGroupItemReviewList' && headerOneBadgeOne === 'group') ||
      (typeOfMiddlePaneList === 'assesseesNodeAssesseeReviewList' &&
        headerOneBadgeOne === 'node') ||
      (typeOfMiddlePaneList === 'assessmentNodeAssessmentReviewList' &&
        headerOneBadgeOne === 'node') ||
      (typeOfMiddlePaneList === 'assignmentNodeAssignmentReviewList' &&
        headerOneBadgeOne === 'node') ||
      (typeOfMiddlePaneList === 'associatesNodeAssociateReviewList' &&
        headerOneBadgeOne === 'node') ||
      (typeOfMiddlePaneList === 'cultureProfileNodeCultureProfileReviewList' &&
        headerOneBadgeOne === 'node') ||
      (typeOfMiddlePaneList === 'jobProfileNodeJobProfileReviewList' &&
        headerOneBadgeOne === 'node') ||
      (typeOfMiddlePaneList === 'itemNodeItemReviewList' && headerOneBadgeOne === 'node') ||
      (typeOfMiddlePaneList === 'assesseesTypeAssesseeReviewList' &&
        headerOneBadgeOne === 'type') ||
      (typeOfMiddlePaneList === 'assessmentTypeAssessmentReviewList' &&
        headerOneBadgeOne === 'type') ||
      (typeOfMiddlePaneList === 'assignmentTypeAssignmentReviewList' &&
        headerOneBadgeOne === 'type') ||
      (typeOfMiddlePaneList === 'associatesTypeAssociateReviewList' &&
        headerOneBadgeOne === 'type') ||
      (typeOfMiddlePaneList === 'cultureProfileTypeCultureProfileReviewList' &&
        headerOneBadgeOne === 'type') ||
      (typeOfMiddlePaneList === 'jobProfileTypeJobProfileReviewList' &&
        headerOneBadgeOne === 'type') ||
      (typeOfMiddlePaneList === 'itemTypeItemReviewList' && headerOneBadgeOne === 'type') ||
      (typeOfMiddlePaneList === 'assesseesRoleAssesseeReviewList' &&
        headerOneBadgeOne === 'role') ||
      (typeOfMiddlePaneList === 'associatesRoleAssociateReviewList' &&
        headerOneBadgeOne === 'role') ||
      (typeOfMiddlePaneList === 'assignmentDistinctAssesseeReviewList' &&
        headerOne === 'assignment') ||
      (typeOfMiddlePaneList === 'assignmentDistinctAssessmentReviewList' &&
        headerOne === 'assignment') ||
      (typeOfMiddlePaneList === 'assignmentDistinctAssesseeGroupReviewList' &&
        headerOne === 'assignment') ||
      (typeOfMiddlePaneList === 'assignmentDistinctAssessmentGroupReviewList' &&
        headerOne === 'assignment') ||
      (typeOfMiddlePaneList === 'assignmentDistinctCultureProfileReviewList' &&
        headerOne === 'assignment') ||
      (typeOfMiddlePaneList === 'assignmentDistinctJobProfileReviewList' &&
        headerOne === 'assignment')
    ) {
      let middlepaneliststr = getUpdatedTypeOfMiddlePaneList(typeOfMiddlePaneList);
      closeRelatedList(
        dispatch,
        createMode,
        responseObject,
        middlePaneHeaderDuplicate,
        middlePaneHeaderDuplicateBadgeOne,
        middlePaneHeaderDuplicateBadgeTwo,
        middlePaneHeaderDuplicateBadgeThree,
        middlePaneHeaderDuplicateBadgeFour,
        middlepaneliststr,
        duplicateScanCount,
        FilterMode,
        duplicateScanCount
      );
    }

    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: rightPaneHeaderOne,
        popupHeaderOneBadgeOne:
          rightPaneBadgeOne === 'information' ? 'distinct' : rightPaneBadgeOne,
        // popupHeaderOneBadgeOne: rightPaneBadgeOne === 'information' ? '' : rightPaneBadgeOne,
        // popupHeaderOneBadgeTwo:
        //   cardValue === 'Card' && rightPaneHeaderOne === 'assessee' ? 'self' : '',
        isPopUpValue:
          cardValue === 'Card' && rightPaneHeaderOne === 'assessee'
            ? 'ASSESSEE_CARD_POPUP'
            : isPopUpValue,
        popupOpenType: 'primary',
        popupContentArrValue: popupOptionArr,
        originPrimaryArrOprion: popupOptionArr,
        selectedTagStatus: selectedTagStatus,
        selectedTagShared: selectedTagShared,
        selectedTagsArray: selectedTagsArray,
        isSelectActive: isSelectActive,
        selectedTagValue: middlePaneSelectedValue ? middlePaneSelectedValue : selectedTagValue,
        selectedTagInformationSetup: responseObject?.informationSetup,
        isFlaged: isFlaged,
        typeOfMiddlePaneList: typeOfMiddlePaneList
      }
    });
    if (cardValue === 'Card' && rightPaneHeaderOne === 'assessee') {
      dispatch({ type: POPUP_OPEN, payload: 'assesseeSelfPopup' });
    } else {
      dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
    }
  };

  // useEffect(() => {
  //   // const sec = (assesseeAssessmentStartData?.assessmentTime % 60000) / 1000;
  // }, [assesseeAssessmentStartData]);

  const openTripleDotPopup = () => {
    dispatch({
      type: SET_POPUP_VALUE,
      payload: {
        isPopUpValue: 'ITEM_TRIPLE_DOT_PRIMARY_POPUP',
        popupMode: ''
      }
    });
  };
  // const startLoader = () =>{
  //   dispatch({ type: LOADER_START });
  // }
  let disableAllocateUnAllocateFiltermodes = {
    allocateToItem: 'allocate',
    allocateToAssessee: 'allocate',
    allocateToAssociate: 'allocate',
    itemAllocateToGroup: 'allocate',
    allocateToJobProfile: 'allocate',
    allocateToAssignment: 'allocate',
    allocateToAssessment: 'allocate',
    associatesAllocateToRole: 'allocate',
    assesseeAllocateToNode: 'allocate',
    assesseeAllocateToRole: 'allocate',
    assesseeAllocateToType: 'allocate',
    assesseeAllocateToGroup: 'allocate',
    allocateToCultureProfile: 'allocate',
    associateAllocateToGroup: 'allocate',
    associatesAllocateToNode: 'allocate',
    assignmentAllocateToNode: 'allocate',
    assignmentAllocateToType: 'allocate',
    associatesAllocateToType: 'allocate',
    assignmentAllocateToGroup: 'allocate',
    jobProfileAllocateToGroup: 'allocate',
    jobProfileAllocateToNode: 'allocate',
    assessmentAllocateToGroup: 'allocate',
    associatesAllocateToDistinct: 'allocate',
    allocateToCultureProfileGroup: 'allocate',
    // '',
    unAllocateToItem: 'unallocate',
    unAllocateToAssessee: 'unallocate',
    unAllocateToAssociate: 'unallocate',
    unAllocateToAssessment: 'unallocate',
    unAllocateToAssignment: 'unallocate',
    unAllocateToJobProfile: 'unallocate',
    unAllocateToCultureProfile: 'unallocate',
    itemUnAllocateToGroup: 'unallocate',
    assesseeUnAllocateToNode: 'unallocate',
    assesseeUnAllocateToRole: 'unallocate',
    assesseeUnAllocateToType: 'unallocate',
    assesseeUnAllocateToGroup: 'unallocate',
    associatesUnAllocateToRole: 'unallocate',
    associateUnAllocateToGroup: 'unallocate',
    jobProfileUnAllocateToNode: 'unallocate',
    associatesUnAllocateToNode: 'unallocate',
    assignmentUnAllocateToNode: 'unallocate',
    assignmentUnAllocateToType: 'unallocate',
    associatesUnAllocateToType: 'unallocate',
    jobProfileUnAllocateToGroup: 'unallocate',
    assessmentUnAllocateToGroup: 'unallocate',
    assignmentUnAllocateToGroup: 'unallocate',
    associatesUnAllocateToDistinct: 'unallocate',
    unAllocateToCultureProfileGroup: 'unallocate',
    itemAllocateToAssessment: 'allocate',
    itemUnAllocateToAssessment: 'unallocate',
    //
    assignmentDistinctAssesseeGroupRevise: 'revise',
    assignmentDistinctCultureProfileGroupRevise: 'revise',
    assignmentDistinctAssignmentReferenceRevise: 'revise',
    assignmentDistinctJobProfileGroupRevise: 'revise',
    cultureProfileAssessmentDistinctactive: 'realtedList',
    cultureProfileAssessmentDistinctinactive: 'realtedList',
    cultureProfileAssessmentDistinctall: 'realtedList',
    jobProfileAssessmentDistinctactive: 'realtedList',
    jobProfileAssessmentDistinctinactive: 'realtedList',
    jobProfileAssessmentDistinctall: 'realtedList',
    assesseeDistinctVideos: 'VideosReviewList',
    assesseeDistinctManuscripts: 'Manuscript'
  };
  const onClickNavigator = () => {
    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        secondaryOptionCheckValue: 'all',
        selectedTagValue: middlePaneSelectedValue,
        originPrimaryArrOprion: originPrimaryArrOprion
      }
    });
    dispatch({
      type: POPUP_OPEN,
      payload: 'NavigatorPOPUP'
    });
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: { stateName: 'headerButtons', value: false }
    });
  };
  return (
    <div className={'iguru-leftpanel'}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'iguru-usercardcontainer'}>
        <Paper
          className={[
            `iguru-assesseescontainer`,
            'iguru-box-shadow',
            `iguru-assesseescontainer${headerPanelColour}`
          ].join(' ')}
        >
          <div style={{ width: '-webkit-fill-available', padding: '2.5px' }}>
            <div className={'iguru-componentinnerdiv-header'}>
              <div className={'iguru-moretextpanelheader'}>
                <div>
                  <span>{headerOne}</span>&nbsp;
                  {headerOneBadgeOne !== '' ? (
                    <Fragment>
                      <span className={'iguru-header-badge1_0'}>{headerOneBadgeOne}</span>
                      &nbsp;
                    </Fragment>
                  ) : null}
                  {headerOneBadgeTwo !== '' ? (
                    <Fragment>
                      <span className={'iguru-header-badge1_0'}>{headerOneBadgeTwo}</span>
                      &nbsp;
                    </Fragment>
                  ) : null}
                  {headerOneBadgeThree !== '' ? (
                    <Fragment>
                      <span className={'iguru-header-badge1_0'}>{headerOneBadgeThree}</span>
                      &nbsp;
                    </Fragment>
                  ) : null}
                  {headerOneBadgeFour !== '' ? (
                    <Fragment>
                      <span className={'iguru-header-badge1_0'}>{headerOneBadgeFour}</span>
                      &nbsp;
                    </Fragment>
                  ) : null}
                </div>
              </div>
              {(displayPane === 'itemPreview' && (
                <Fragment>
                  <div className={'iguru-moretextpanelheader'}></div>
                  <div className={'iguru-iconbox'}></div>
                  <div className={'iguru-iconbox'}></div>
                  <div className={'iguru-iconbox'}>
                    {displayPane === 'itemPreview' && isExamMode && assesseeAssessmentStartData ? (
                      <></>
                    ) : (
                      <IconButton onClick={onClickClearInfo}>
                        <Clear className={'iguru-iconbardefault'} />
                      </IconButton>
                    )}
                  </div>
                  <div className={'iguru-iconbox'}>
                    {displayPane === 'itemPreview' && isExamMode && assesseeAssessmentStartData ? (
                      <IconButton onClick={isItemTrial ? null : onClickNavigator}>
                        <OpenWithIcon className={'iguru-iconbardefault'} />
                      </IconButton>
                    ) : (
                      <Fragment>
                        <IconButton onClick={openTripleDotPopup}>
                          <MoreVert className={'iguru-iconbardefault'} />
                        </IconButton>
                      </Fragment>
                    )}
                  </div>
                </Fragment>
              )) || (
                  <Fragment>
                    <div className={'iguru-iconbox'}>
                      {displayPane === 'five' ? (
                        <div></div>
                      ) : displayPane === 'centre' && showMiddlePaneState ? (
                        <IconButton onClick={onClickScan}>
                          <Fragment>
                            <SearchIcon className={'iguru-iconbardefault'} />
                            <span className={'iguru-headerbadge'}>{scanCount}</span>
                          </Fragment>
                        </IconButton>
                      ) : (displayPane === 'right' && reviewMode !== 'revise' && headerOne !== '') ||
                        showClearIcon ? (
                        <IconButton onClick={onClickClearInfo}>
                          <Clear className={'iguru-iconbardefault'} />
                        </IconButton>
                      ) : null}
                    </div>
                    <div className={'iguru-iconbox'}>
                      {displayPane === 'five' && assesseeAssessmentStartData ? (
                        <IconButton
                          onClick={() => {
                            dispatch({
                              type: POPUP_OPEN,
                              payload: 'NavigatorPOPUP'
                            });
                            dispatch({
                              type: SET_POPUP_SINGLE_STATE,
                              payload: { stateName: 'headerButtons', value: false }
                            });
                          }}
                        >
                          <OpenWithIcon className={'iguru-iconbardefault'} />
                        </IconButton>
                      ) : displayPane === 'centre' && showMiddlePaneState ? (
                        <IconButton
                          disabled={
                            FilterMode === 'assesseeTypeAssesseeRevise' ||
                            FilterMode === 'assessmentTypeAssessmentRevise' ||
                            FilterMode === 'assignmentTypeAssignmentRevise' ||
                            FilterMode === 'associateTypeAssociateRevise' ||
                            FilterMode === 'itemTypeItemeRevise' ||
                            FilterMode === 'cultureProfileTypeCultureProfileRevise' ||
                            FilterMode === 'jobProfileTypeJobProfileRevise' ||
                            FilterMode === 'assesseeGroupAssesseeRevise' ||
                            FilterMode === 'assessmentGroupAssessmenteRevise' ||
                            FilterMode === 'assignmentGroupAssignmentRevise' ||
                            FilterMode === 'associateGroupAssociateRevise' ||
                            FilterMode === 'itemGroupItemeRevise' ||
                            FilterMode === 'cultureProfileGroupCultureProfileRevise' ||
                            FilterMode === 'jobProfileGroupJobProfileRevise' ||
                            FilterMode === 'assesseeRoleAssesseeRevise' ||
                            FilterMode === 'associateRoleAssociateRevise' ||
                            FilterMode === 'assesseeGroupAssesseeAssignment' ||
                            FilterMode === 'assesseeRelatedAssociate' ||
                            FilterMode === 'assignmentDistinctAssesseeRevise' ||
                            FilterMode === 'assignmentDistinctAssessmentRevise' ||
                            FilterMode === 'assignmentDistinctAssessmentGroupRevise' ||
                            FilterMode === 'assesseeGroupAssesseeAssignmentactive' ||
                            FilterMode === 'assesseeGroupAssesseeAssignmentinactive' ||
                            FilterMode === 'assesseeGroupAssesseeAssignmentall' ||
                            FilterMode === 'assesseeGroupAssesseeAssignmentarchived' ||
                            FilterMode === 'allocateToAssignment' ||
                            FilterMode === 'assignmentAllocateToGroup' ||
                            FilterMode === 'itemAllocateToGroup' ||
                            FilterMode === 'allocateToItem' ||
                            FilterMode === 'assesseeAssignmentAssessmentinactive' ||
                            FilterMode === 'allocateToAssignment' ||
                            FilterMode === 'allocateToAssessee' ||
                            FilterMode === 'allocateToAssessment' ||
                            FilterMode === 'allocateToAssociate' ||
                            FilterMode === 'associateAllocateToGroup' ||
                            FilterMode === 'associatesAllocateToNode' ||
                            FilterMode === 'associatesAllocateToDistinct' ||
                            FilterMode === 'allocateToAssignment' ||
                            FilterMode === 'allocateToCultureProfile' ||
                            FilterMode === 'allocateToJobProfile' ||
                            FilterMode === 'assesseeAllocateToGroup' ||
                            FilterMode === 'assessmentAllocateToGroup' ||
                            FilterMode === 'assignmentAllocateToGroup' ||
                            FilterMode === 'allocateToCultureProfileGroup' ||
                            FilterMode === 'jobProfileAllocateToGroup' ||
                            FilterMode === 'assignmentAllocateToNode' ||
                            FilterMode === 'assignmentAllocateToType' ||
                            FilterMode === 'associatesAllocateToRole' ||
                            FilterMode === 'assesseeAllocateToNode' ||
                            FilterMode === 'assesseeAllocateToRole' ||
                            FilterMode === 'assesseeAllocateToType' ||
                            FilterMode === 'associatesAllocateToType' ||
                            FilterMode === 'assesseeNodeAssesseeRevise' ||
                            FilterMode === 'assessmentNodeAssessmentRevise' ||
                            FilterMode === 'assignmentNodeAssignmentRevise' ||
                            FilterMode === 'cultureProfileNodeCultureProfileRevise' ||
                            FilterMode === 'jobProfileNodeJobProfileRevise' ||
                            FilterMode === 'itemNodeItemRevise' ||
                            FilterMode === 'associateNodeAssociateRevise' ||
                            FilterMode === 'assessmentsDistinctinactivescores' ||
                            FilterMode === 'iGaugeOccupationCultureProfiler' ||
                            FilterMode === 'iGaugeOccupationJobProfiler' ||
                            FilterMode === 'assignmentDistinctAssesseeDistinctactive' ||
                            FilterMode === 'assignmentDistinctAssesseeDistinctinactive' ||
                            FilterMode === 'assignmentDistinctAssesseeDistinctall' ||
                            FilterMode === 'assignmentDistinctAssesseeDistinctarchived' ||
                            FilterMode === 'assesseeAssignmentAssessmentactive' ||
                            FilterMode === 'assessmentItemRevise' ||
                            FilterMode === 'assessmentSectionItemGroupRevise' ||
                            FilterMode === 'assessmentSectionItemRevise' ||
                            FilterMode ===
                            `assignmentDistinctAssessment${middlePaneHeaderBadgeTwo}` ||
                            disableAllocateUnAllocateFiltermodes.hasOwnProperty(FilterMode) ||
                            typeOfMiddlePaneList === 'associateDistinctShareReviewList' ||
                            FilterMode === `assesseeAssignment${middlePaneHeaderBadgeTwo}` ||
                            typeOfMiddlePaneList.toLowerCase().includes('market') ||
                            typeOfMiddlePaneList.toLowerCase().includes('marketplace')
                            // FilterMode === 'assignmentDistinctMineAsseseeDistinct'
                          }
                          onClick={openMiddlePaneTripleDotPopup}
                        >
                          <MoreVert className={'iguru-iconbardefault'} />
                        </IconButton>
                      ) : displayPane === 'left' ? (
                        <IconButton onClick={openLeftPaneTripleDotPopup}>
                          <MoreVert className={'iguru-iconbardefault'} />
                        </IconButton>
                      ) : displayPane === 'right' && headerOne !== '' ? (
                        <IconButton
                          disabled={
                            FilterMode === 'assesseeGroupAssesseeRevise' ||
                            FilterMode === 'assessmentGroupAssessmenteRevise' ||
                            FilterMode === 'assignmentGroupAssignmentRevise' ||
                            FilterMode === 'associateGroupAssociateRevise' ||
                            FilterMode === 'cultureProfileGroupCultureProfileRevise' ||
                            FilterMode === 'assesseeNodeAssesseeRevise' ||
                            FilterMode === 'associateRoleAssociateRevise' ||
                            FilterMode === 'assessmentNodeAssessmentRevise' ||
                            FilterMode === 'cultureProfileNodeCultureProfileRevise' ||
                            FilterMode === 'jobProfileNodeJobProfileRevise' ||
                            FilterMode === 'assignmentNodeAssignmentRevise' ||
                            FilterMode === 'itemNodeItemRevise' ||
                            FilterMode === 'itemTypeItemeRevise' ||
                            FilterMode === 'jobProfileGroupJobProfileRevise' ||
                            FilterMode === 'itemGroupItemeRevise' ||
                            FilterMode === 'associateNodeAssociateRevise' ||
                            FilterMode === 'assesseeRoleAssesseeRevise' ||
                            FilterMode === 'assesseeTypeAssesseeRevise' ||
                            // FilterMode === 'assessmentsTypeDistinctactive' ||
                            FilterMode === 'assignmentTypeAssignmentRevise' ||
                            FilterMode === 'associateTypeAssociateRevise' ||
                            FilterMode === 'cultureProfileTypeCultureProfileRevise' ||
                            FilterMode === 'jobProfileTypeJobProfileRevise' ||
                            FilterMode === 'itemTypeItemeRevise' ||
                            FilterMode === 'assesseeNodeAssesseeRevise'
                          }
                          onClick={openRightPaneTripleDotPopup}
                        >
                          <MoreVert className={'iguru-iconbardefault'} />
                        </IconButton>
                      ) : null}
                    </div>
                  </Fragment>
                )}
            </div>
          </div>
        </Paper>
      </Grid>
    </div>
  );
};

export default HeaderCard;
