import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import AllocationAccordian from '../Accordian/AllocationAccordian';
import { useDispatch, useSelector } from 'react-redux';
import { Paper } from '@material-ui/core';
import DisplayPanelAccordianReviewListOne from '../Accordian/DisplayPanelAccordianReviewListOne';
import DisplayPanelAccordianInformation from '../Accordian/DisplayPanelAccordianInformation';
import { GET_JOBDOMAIN_REVIEW_LIST_SAGA, SET_NEXT_POPUP, SET_POPUP_VALUE } from '../../actionType';
import DisplayPanelAccordianReviewListTwo from '../Accordian/DisplayPanelAccordianReviewListTwo';

const DisplayPaneThreeSectionTwoJobProfileDistinct = () => {
  const {
    headerOneBadgeTwo,
    headerOneBadgeThree,
    reviewMode,
    isRangeSelected = false,
    isJobWeightageSelected = false,
    responseObject,
    relatedReviewListPaneThree
  } = useSelector((state) => state.DisplayPaneThreeReducer);

  const { selectedAssociateInfo, jobProfilerReviewList } = useSelector(
    (state) => state.DisplayPaneTwoReducer
  );
  let selectedList = isRangeSelected || isJobWeightageSelected ? 'framework' : '';
  const [listExpand, setListExpand] = useState(selectedList);
  const dispatch = useDispatch();

  let jobCoreList = [];
  const tempCoreList =
    responseObject?.informationFramework?.jobProfileJobCompetencySelectList || [];
  if (tempCoreList) {
    tempCoreList.forEach((ob) => {
      jobCoreList.push({
        id: ob?.id + '-',
        textOne: ob?.iGaugeOccupationFrameworkOneClusterName || '',
        textTwo: ob?.iGaugeOccupationFrameworkOneClusterDescription || '',
        status: ''
      });
    });
  }
  let jobDomainList = [];
  const tempDomainList = responseObject?.informationFramework?.jobProfileJobDomain || [];
  if (tempDomainList) {
    tempDomainList.forEach((ob) => {
      jobDomainList.push({
        id: ob?.id + '-',
        textOne: ob?.iGaugeOccupationFrameworkOneClusterName || '',
        textTwo: ob?.iGaugeOccupationFrameworkOneClusterDescription || '',
        status: ''
      });
    });
  }
  let jobFunctionList = [];
  const tempFunctionList = responseObject?.informationFramework?.jobProfileJobFunction || [];
  if (tempFunctionList) {
    tempFunctionList.forEach((ob) => {
      jobFunctionList.push({
        id: ob?.id + '-',
        textOne: ob?.iGaugeOccupationFrameworkOneClusterName || '',
        textTwo: ob?.iGaugeOccupationFrameworkOneClusterDescription || '',
        status: ''
      });
    });
  }
  let jobRoleList = [];
  const tempRoleList = responseObject?.informationFramework?.jobProfileJobRole || [];
  if (tempRoleList) {
    tempRoleList.forEach((ob) => {
      jobRoleList.push({
        id: ob?.id + '-',
        textOne: ob?.iGaugeOccupationFrameworkOneClusterName || '',
        textTwo: ob?.iGaugeOccupationFrameworkOneClusterDescription || '',
        status: ''
      });
    });
  }
  let jobShortList = [];
  const tempShortList =
    responseObject?.informationFramework?.jobProfileJobCompetencyShortList || [];
  if (tempShortList) {
    tempShortList.forEach((ob) => {
      jobShortList.push({
        id: ob?.id + '-',
        textOne: ob?.iGaugeOccupationFrameworkOneClusterName || '',
        textTwo: ob?.iGaugeOccupationFrameworkOneClusterDescription || '',
        status: ''
      });
    });
  }
  let jobSiftList = [];
  if (tempShortList) {
    tempShortList.forEach((ob) => {
      jobSiftList.push({
        id: ob?.id + '-',
        textOne: ob?.iGaugeOccupationFrameworkOneClusterName || '',
        textTwo: ob?.iGaugeOccupationFrameworkOneClusterDescription || '',
        status: ob?.iGaugeOccupationFrameworkOneClusterSelected || ''
      });
    });
  }
  let assessmentList =
    relatedReviewListPaneThree?.assessment ||
    responseObject?.informationFramework?.jobProfileAssessment ||
    [];
  let assessmentArray = [];
  assessmentList.forEach((ob) => {
    const { id, informationBasic } = ob;
    assessmentArray.push({
      id,
      textOne: informationBasic?.assessmentName || '',
      textTwo: informationBasic?.assessmentDescription || '',
      status: ''
    });
  });
  const frameworkAll = [
    {
      id: 'JP-Assessment001',
      labelTextOneOne: 'assessments',
      isListCard: true,
      labelTextOneOneBadgeOne: 'distinct',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'distinct',
          innerList: assessmentArray.sort((a, b) =>
          a.textOne.toLowerCase().localeCompare(b.textOne.toLowerCase())
        )
        }
      ],
      innerAssociateList: [],
      innerInfo: 'No Information',
      IconOne: null
    },
    {
      id: 'job001',
      labelTextOneOne: 'job',
      isListCard: true,
      labelTextOneOneBadgeOne: '',
      labelTextOneOneBadgeTwo: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'domain',
          innerList: jobDomainList.sort((a, b) =>
          a.textOne.toLowerCase().localeCompare(b.textOne.toLowerCase())
        )
        },
        {
          labelTextOneOneBadge: 'function',
          innerList: jobFunctionList.sort((a, b) =>
          a.textOne.toLowerCase().localeCompare(b.textOne.toLowerCase())
        )
        },
        {
          labelTextOneOneBadge: 'role',
          innerList: jobRoleList.sort((a, b) =>
          a.textOne.toLowerCase().localeCompare(b.textOne.toLowerCase())
        )
        }
      ],
      innerAssociateList: [],
      innerInfo: 'No Information',
      IconOne: null
    },
    {
      id: 'a1',
      labelTextOneOne: 'job-competencies',
      labelTextOneOneBadgeOne: '',
      labelTextOneOneBadgeTwo: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'select-list',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'distinct',
              innerList: jobCoreList.sort((a, b) =>
              a.textOne.toLowerCase().localeCompare(b.textOne.toLowerCase())
            )
            },
            {
              labelTextTwoBadge: 'range',
              innerList: []
            },
            {
              labelTextTwoBadge: 'weightage',
              innerList: []
            }
          ]
        },
        {
          labelTextOneOneBadge: 'short-list',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'distinct',
              innerList: jobShortList.sort((a, b) =>
                a.textOne.toLowerCase().localeCompare(b.textOne.toLowerCase())
              )
            }
          ]
        },
        {
          labelTextOneOneBadge: 'sift-list',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'distinct',
              innerList: jobSiftList.sort((a, b) =>
                a.textOne.toLowerCase().localeCompare(b.textOne.toLowerCase())
              )
            }
          ]
        }
      ],
      isListCard: true,
      isMultiList: true,
      innerAssociateList: [],
      innerInfo: 'No Information',
      IconOne: null
    },
    // {
    //   id: 'job002',
    //   labelTextOneOne: 'job competencies',
    //   isListCard: true,
    //   labelTextOneOneBadgeOne: '',
    //   labelTextOneOneBadgeTwo: '',
    //   labelTextOneOneBadges: [
    //     {
    //       labelTextOneOneBadge: 'select-list',
    //       innerList: jobCoreList
    //     },
    //     {
    //       labelTextOneOneBadge: 'short list',
    //       innerList: jobShortList
    //     },
    //     {
    //       labelTextOneOneBadge: 'sift list',
    //       innerList: jobSiftList
    //     },
    //     {
    //       labelTextOneOneBadge: 'range',
    //       innerLabelBadgeList: []
    //     },
    //     {
    //       labelTextOneOneBadge: 'weightage',
    //       innerLabelBadgeList: []
    //     }
    //   ],
    //   innerAssociateList: [],
    //   innerInfo: 'No Information',
    //   IconOne: null,
    //   isMultiList: false
    // },
    {
      id: 'job003',
      labelTextOneOne: 'preview',
      isListCard: false,
      labelTextOneOneBadgeOne: '',
      labelTextOneOneBadgeTwo: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: '',
          innerList: ''
        }
      ],
      innerAssociateList: [],
      innerInfo: 'No Information',
      IconOne: null
    }
  ];

  const reviseFramework = (e, selectedBadgeArray) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    const innerBadgeName = e.currentTarget.getAttribute('id');
    console.log('labelName', labelName);
    console.log('selectedBadgeName', selectedBadgeName);
    console.log('innerBadgeName', innerBadgeName);
    let popupValue = '';
    if (labelName === 'assessments' && selectedBadgeName === 'distinct') {
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'POPUPASSESSMENTPOPUP', popupMode: 'JOBCREATE' }
      });
    } else if (labelName !== '' && selectedBadgeName !== '') {
      popupValue = 'POPUPCORECOMPEMSG';
      if (
        labelName === 'job-competencies' &&
        selectedBadgeName === 'select-list' &&
        innerBadgeName === 'distinct'
      ) {
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'POPUPCORECOMPEMSG', popupMode: 'JOBCREATE' }
        });
      }
      if (
        labelName === 'job-competencies' &&
        selectedBadgeName === 'select-list' &&
        (innerBadgeName === 'range' || innerBadgeName === 'weightage')
      ) {
        popupValue = '';
      }
      if (
        labelName === 'job-competencies' &&
        selectedBadgeName === 'sift-list' &&
        innerBadgeName === 'distinct'
      ) {
        // popupValue = 'POPUPSIFTMSG';
        popupValue = 'POPUPCOMPEMSG';
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'POPUPCOMPEMSG', popupMode: 'JOBCREATE' }
        });
        // dispatch({ type: SET_NEXT_POPUP, payload: { isPopUpValue: 'POPUPSIFTLIST0' } });
      }
      if (labelName === 'job-competencies' && selectedBadgeName === 'short-list') {
        // popupValue = 'POPUPCOMPEMSG';
        popupValue = 'POPUPSIFTMSG';
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'POPUPSIFTMSG', popupMode: 'JOBCREATE' }
        });
      }

      if (labelName === 'job' && selectedBadgeName === 'domain') {
        popupValue = 'POPUPDOMAINMSG';
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'POPUPDOMAINMSG', popupMode: 'JOBCREATE' }
        });
      }
      if (labelName === 'job' && selectedBadgeName === 'function') {
        popupValue = 'POPUPFUNCTIONMSG';
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'POPUPFUNCTIONMSG', popupMode: 'JOBCREATE' }
        });
      }
      if (labelName === 'job' && selectedBadgeName === 'role') {
        popupValue = 'POPUPROLEMSG';
        dispatch({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: 'POPUPROLEMSG', popupMode: 'JOBCREATE' }
        });
      }
      if (
        jobProfilerReviewList === null &&
        (labelName === 'job-competencies' || labelName === 'job') &&
        innerBadgeName !== 'range' &&
        innerBadgeName !== '' &&
        innerBadgeName !== 'weightage'
      ) {
        let requestObj = {
          module: 'Profiler',
          action: 'Create',
          requestObject: {
            action: 'Review-List',
            assesseeId: selectedAssociateInfo?.assesseeId,
            associateId:
              selectedAssociateInfo?.associate?.informationEngagement.associateTag
                .associateTagPrimary
          }
        };
        dispatch({
          type: GET_JOBDOMAIN_REVIEW_LIST_SAGA,
          payload: {
            request: requestObj,
            BadgeOne: '',
            BadgeTwo: '',
            BadgeThree: '',
            isMiddlePaneList: false,
            nextPopupValue: popupValue
          }
        });
      } else {
      }
    }
  };

  return (
    <div>
      {headerOneBadgeThree === 'all' ? (
        <>
          <div className={'containerPadding'}>
            <AllocationAccordian
              headerOne="framework"
              isDisplayCardExpanded={listExpand === 'framework'}
              setListExpand={setListExpand}
              list={frameworkAll}
              mode={reviewMode}
              onClickRevise={reviseFramework}
            />
          </div>
        </>
      ) : (
        <>
          <div className={'containerPadding'}>
            <Paper className={'dossierContainerTop'}>
              {frameworkAll.map((ob) => {
                return (
                  <div key={ob.id}>
                    {/* {ob.isListCard ? (
                      <DisplayPanelAccordianReviewListOne
                        onClickRevise={reviseFramework}
                        className=""
                        accordianObject={ob}
                        mode={reviewMode}
                      />
                    ) : (
                      <DisplayPanelAccordianInformation
                        onClickRevise={reviseFramework}
                        accordianObject={ob}
                        mode={reviewMode}
                      />
                    )} */}
                    {ob.isListCard ? (
                      <>
                        {ob.isMultiList ? (
                          <DisplayPanelAccordianReviewListTwo
                            onClickReview={null}
                            onClickRevise={reviseFramework}
                            accordianObject={ob}
                            mode={reviewMode}
                          />
                        ) : (
                          <DisplayPanelAccordianReviewListOne
                            onClickRevise={reviseFramework}
                            className=""
                            accordianObject={ob}
                            mode={reviewMode}
                          />
                        )}
                      </>
                    ) : (
                      <DisplayPanelAccordianInformation
                        onClickRevise={reviseFramework}
                        accordianObject={ob}
                        mode={reviewMode}
                      />
                    )}
                  </div>
                );
              })}
            </Paper>
          </div>
        </>
      )}
      {isMobile && (
        <div className={'containerPadding'} style={{ height: '55px' }}>
          {' '}
        </div>
      )}
    </div>
  );
};

export default DisplayPaneThreeSectionTwoJobProfileDistinct;
