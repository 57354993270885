import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SET_ITEM_FRAMEWORK_DYNAMIC_SINGLE_STATE, SET_POPUP_VALUE } from '../../actionType';
import ReactHTMLParser from 'react-html-parser';
import EditorJs from 'react-editor-js';
import Paragraph from '@editorjs/paragraph';
import EditorTemplate from './EditorTemplate';
import PopUpTextEditor from '../../PopUpIcon/PopUpTextEditor';
import { FormControl } from '@material-ui/core';

const DisplayPaneFiveItemTemplate = (props) => {
  const dispatch = useDispatch();
  const {
    setSelectedChoiceObject,
    itemType,
    itemFrameworkOne,
    itemInformation,
    isResponseAlign = 'horizontal',
    isItemAlign = 'vertical'
  } = props;
  // const { itemInformation } = useSelector((state) => state.ItemCreateReducer);
  const instanceRef = useRef(null);
  let node = React.createRef();
  const { isPopUpValue, popupMode } = useSelector((state) => state.PopUpReducer);
  const { reviewMode } = useSelector((state) => state.DisplayPaneThreeReducer);
  const [innerContent, setInnerContent] = useState('');
  const optionLabel = '<span>response-choice</span>&nbsp';
  const optionLabel1 = '<span>response-choice</span>&nbsp;';
  const itemLabelText =
    "<span>item</span> &nbsp <span class='iguru-item-badge1_0'>label</span>&nbsp;";
  const itemText = '<span>item</span>&nbsp';
  const passageText =
    "<span>item</span>&nbsp<span class='iguru-item-badge1_0'>passage</span>&nbsp;";
  const itemExplanationText =
    "<span>item</span> &nbsp <span class='iguru-item-badge1_0'>explanation</span>&nbsp;";
  const responseLabelText =
    "<span>response</span> &nbsp <span class='iguru-item-badge1_0'>label</span>&nbsp;";
  const responseText = '<span>response</span>';
  const responseExplanationText =
    "<span>response</span> &nbsp <span class='iguru-item-badge1_0'>explanation</span>";
  const responseChoiceDescription =
    "<span>response-choice</span> &nbsp <span class='iguru-item-badge1_0'>explanation</span>&nbsp;";
  // const responseChoiceDescription1 =
  //   "<span>response-choice</span> &nbsp <span class='iguru-header-badge1_1'>explanation</span>&nbsp;";
  const responseChoiceDescription1 =
    "<span>response-choice</span> &nbsp <span class='iguru-item-badge1_0'>explanation</span>&nbsp;";
  const itemExplain =
    "<span>response-choice</span> &nbsp <span class='iguru-item-badge1_0'>explanation</span>&nbsp;";
  const numberOfNoOptions =
    itemInformation?.informationFramework?.itemFrameworkOne?.itemFrameworkOneResponseChoice || [];
  const numberOfResponseLabel =
    itemInformation?.informationFramework?.itemFrameworkOne
      ?.itemFrameworkOneResponseChoiceSequenceAdministration || [];

  const itemFrameworkOneSection = itemFrameworkOne.itemFrameworkOneSection;
  console.log('itemFrameworkOne', itemFrameworkOne);
  const itemFrameworkOneResponseChoiceAlignment =
    itemFrameworkOne?.itemFrameworkOneResponseChoiceAlignment || 'vertical';
  const [scaleList, setScaleList] = useState(['scale-1', 'scale-2', 'scale-3']);
  const [liketcorrect, setliketcorrect] = useState('');
  const [lab, setLabel] = useState(true);
  const onClickPopupLabel = (clickedLabel) => {
    if (clickedLabel === 'item-label') {
    }
  };
console.log('itemType',itemType);
  const handleClick = (event) => {
    if (itemFrameworkOne.itemFrameworkOneResponseCorrect[0] === event.target.value) {
      dispatch({
        type: SET_ITEM_FRAMEWORK_DYNAMIC_SINGLE_STATE,
        payload: {
          stateName: 'itemFrameworkOneResponseCorrect',
          value: []
        }
      });
    } else {
      dispatch({
        type: SET_ITEM_FRAMEWORK_DYNAMIC_SINGLE_STATE,
        payload: {
          stateName: 'itemFrameworkOneResponseCorrect',
          value: [event.target.value]
        }
      });
    }
  };
  useEffect(() => {
    window.MathJax.Hub.Config({
      extensions: ["tex2jax.js"],
      jax: ["input/TeX", "output/HTML-CSS"], 
      tex2jax:  { 
        inlineMath: [ ['$','$'], ["\\(","\\)"] ],
        displayMath: [ ['$$','$$'], ["\\[","\\]"] ],
        processEscapes: true,
     }, //used for  other ['$','$'],
      showMathMenu: false,
      messageStyle: 'none',
      errorSettings: { message: ['[Math Processing Error]'] },
      MathEvents: {
        hover: 'none'
      },
      "HTML-CSS": { scale: 90,imageFont: null, preferredFont: null, availableFonts: ['Roboto'], webFont: "STIX-Web"},
      SVG: { font: "STIX-Web"}
    });
    // window.MathJax.Hub.Config({ extensions: ["tex2jax.js"], jax: ["input/TeX", "output/HTML-CSS"], tex2jax: { inlineMath: [ ['$','$'], ["\\(","\\)"] ], displayMath: [ ['$$','$$'], ["\\[","\\]"] ], processEscapes: true }, "HTML-CSS": { availableFonts: ["TeX"] } });
    window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub, node.current]);
  }, [itemFrameworkOne]);

  useEffect(() => {
    let allClasses = document.getElementsByClassName('innerheightAdjust');
    if (allClasses.length > 0) {
      for (let i = 0; i < allClasses.length; i++) {
        let currentDivHeight = allClasses[i].clientHeight;
        if (currentDivHeight > 55) {
          allClasses[i].style.height = '';
        }
      }
    }
    if (allClasses.length > 0) {
      for (let i = 0; i < allClasses.length; i++) {
        let currentDivHeight = allClasses[i].clientHeight;
        if (currentDivHeight > 55) {
          let reminder = currentDivHeight / 55;
          allClasses[i].style.height = 50 + 55 * Math.floor(reminder) + 'px';
        } // else {
        //   allClasses[i].style.height = '50px';
        // }
      }
    }
  }, [itemFrameworkOne]);
  console.log('itemType',itemType);
  return (
    <div ref={node}>
      {/* Item Label */}
      {(itemFrameworkOne?.itemFrameworkOneLabel?.itemFrameworkOneLabelMedia ||
        reviewMode === 'revise') && (
        <div className={['innerpadding'].join(' ')}>
          <div className={'innerheightAdjust'}>
            {(itemFrameworkOne?.itemFrameworkOneLabel?.itemFrameworkOneLabelMedia ||
              reviewMode === 'revise') && (
              <div
                className={['ex_container', 'ig-label'].join(' ')}
                style={{ cursor: reviewMode === 'revise' && 'pointer' }}
                onClick={
                  reviewMode === 'revise'
                    ? () => {
                        dispatch({
                          type: SET_POPUP_VALUE,
                          payload: {
                            isPopUpValue: 'ITEM_LABEL_PRIMARY_POPUP',
                            popupMode: 'OPEN_ITEM_LABEL_POPUP'
                          }
                        });
                      }
                    : null
                }
              >
                {(!itemFrameworkOne?.itemFrameworkOneLabel?.itemFrameworkOneLabelMedia &&
                  reviewMode === 'revise' &&
                  ReactHTMLParser(itemLabelText)) || (
                  <EditorTemplate
                    label={'itemLabel'}
                    jsonData={itemFrameworkOne?.itemFrameworkOneLabel?.itemFrameworkOneLabelMedia}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      )}

      {/* Passage */}
      {(itemType === 'Comprehension' || itemType === 'Template') && (
        <div className={['innerpadding'].join(' ')}>
          <div className={'innerheightAdjust'}>
            <div
              className={['ex_container', 'ig-itemGeneric'].join(' ')}
              style={{ cursor: reviewMode === 'revise' && 'pointer' }}
              onClick={
                reviewMode === 'revise'
                  ? () => {
                      dispatch({
                        type: SET_POPUP_VALUE,
                        payload: {
                          isPopUpValue: 'PASSAGE_PRIMARY_POPUP',
                          popupMode: popupMode
                        }
                      });
                    }
                  : null
              }
            >
              {(!itemFrameworkOne?.itemFrameworkOnePassage?.itemFrameworkOnePassageMedia &&
                reviewMode === 'revise' &&
                ReactHTMLParser(passageText)) || (
                <EditorTemplate
                  jsonData={itemFrameworkOne?.itemFrameworkOnePassage?.itemFrameworkOnePassageMedia}
                  label={'passage'}
                />
              )}
            </div>
          </div>
        </div>
      )}
      {/* Item */}
      {(itemFrameworkOne?.itemFrameworkOneMedia || reviewMode === 'revise') && (
        <div className={['innerpadding', ''].join(' ')}>
          <div className="innerheightAdjust">
            <div
              className={['ex_container', 'ig-itemGeneric'].join(' ')}
              style={{ cursor: reviewMode === 'revise' && 'pointer' }}
              onClick={
                reviewMode === 'revise'
                  ? () => {
                      dispatch({
                        type: SET_POPUP_VALUE,
                        payload: {
                          isPopUpValue: 'ITEM_PRIMARY_POPUP',
                          popupMode: popupMode
                        }
                      });
                    }
                  : null
              }
            >
              {(!itemFrameworkOne?.itemFrameworkOneMedia &&
                reviewMode === 'revise' &&
                ReactHTMLParser(itemText)) || (
                <EditorTemplate label={'item'} jsonData={itemFrameworkOne?.itemFrameworkOneMedia} />
              )}
            </div>
          </div>
        </div>
      )}
      {/* Item explanation */}
      {(itemFrameworkOne?.itemFrameworkOneExplanation?.itemFrameworkOneExplanationMedia ||
        reviewMode === 'revise') && (
        <div className={['innerpadding', ''].join(' ')}>
          <div className="innerheightAdjust">
            <div
              className={['ex_container', 'ig-explanation'].join(' ')}
              style={{ cursor: reviewMode === 'revise' && 'pointer' }}
              onClick={
                reviewMode === 'revise'
                  ? () => {
                      dispatch({
                        type: SET_POPUP_VALUE,
                        payload: {
                          isPopUpValue: 'ITEM_EXPLANATION_PRIMARY_POPUP',
                          popupMode: popupMode
                        }
                      });
                    }
                  : null
              }
            >
              {(!itemFrameworkOne?.itemFrameworkOneExplanation?.itemFrameworkOneExplanationMedia &&
                reviewMode === 'revise' &&
                ReactHTMLParser(itemExplanationText)) || (
                <EditorTemplate
                  jsonData={
                    itemFrameworkOne?.itemFrameworkOneExplanation?.itemFrameworkOneExplanationMedia
                  }
                  label={'itemFrameworkOneExplanationMedia'}
                />
              )}
            </div>
          </div>
        </div>
      )}
      {/* for sub item  */}
      {itemType === 'Rating-Scale (Multiple-Select)' ? (
        <div>
          <div
            style={{
              display:
                itemFrameworkOneResponseChoiceAlignment === 'horizontal' &&
                itemFrameworkOneResponseChoiceAlignment !== ''
                  ? 'block'
                  : 'none'
            }}
          >
            <div className="innerpadding">
              <FormControl component="fieldset" style={{ width: '100%' }}>
                {itemFrameworkOneSection.map((ob, keys) => {
                  return (
                    <div
                      className={['innerheightAdjust', 'rating-scale-container'].join(' ')}
                      // style={{
                      //   display: 'grid',
                      //   gridTemplateColumns: `repeat(${12},minmax(0,1fr))`
                      // }}
                    >
                      <Fragment>
                        <div
                          style={{
                            gridColumn: `span ${
                              12 -
                              (ob?.itemFrameworkOne?.itemFrameworkOneResponseChoice.length <= 3
                                ? 6
                                : ob?.itemFrameworkOne?.itemFrameworkOneResponseChoice.length * 2)
                            } / span ${
                              12 -
                              (ob?.itemFrameworkOne?.itemFrameworkOneResponseChoice.length <= 3
                                ? 6
                                : ob?.itemFrameworkOne?.itemFrameworkOneResponseChoice.length * 2)
                            }`
                          }}
                        >
                          <div className={['innerheightAdjust', 'ex_container'].join(' ')}>
                            <div
                              className="item"
                              subQuestionId={ob.itemFrameworkOneSectionSequence}
                              style={{
                                cursor: reviewMode === 'revise' ? 'pointer' : ''
                              }}
                              onClick={
                                reviewMode === 'revise'
                                  ? () => {
                                      dispatch({
                                        type: SET_POPUP_VALUE,
                                        payload: {
                                          isPopUpValue: 'SUB_ITEM_PRIMARY_POPUP',
                                          popupMode: `LIKERT_ITEM_MEDIA_TEXT_${keys}`
                                        }
                                      });
                                    }
                                  : null
                              }
                            >
                              {(!ob.itemFrameworkOne?.itemFrameworkOneMedia &&
                                reviewMode === 'revise' &&
                                ReactHTMLParser('<span>item</span>-' + `${keys + 1}`)) || (
                                <EditorTemplate
                                  label={'sub item'}
                                  jsonData={ob.itemFrameworkOne?.itemFrameworkOneMedia}
                                />
                              )}
                            </div>
                          </div>
                          {!ob.itemFrameworkOne?.itemFrameworkOneExplanation
                            ?.itemFrameworkOneExplanationMedia && (
                            <div
                              className={['', '', 'innerheightAdjust', 'ex_container'].join(' ')}
                            >
                              <div
                                subQuestionId={ob.itemFrameworkOneSectionSequence}
                                style={{
                                  cursor: reviewMode === 'revise' ? 'pointer' : ''
                                }}
                                className={[
                                  'ig-explanation',
                                  'item-explanation',
                                  'ex_container-One'
                                ].join(' ')}
                                onClick={
                                  reviewMode === 'revise'
                                    ? () => {
                                        dispatch({
                                          type: SET_POPUP_VALUE,
                                          payload: {
                                            isPopUpValue: 'SUB_ITEM_EXP_PRIMARY_POPUP',
                                            popupMode: `LIKERT_ITEM_EXPLANATION_MEDIA_TEXT_${keys}`
                                          }
                                        });
                                      }
                                    : null
                                }
                              >
                                {(!ob.itemFrameworkOne?.itemFrameworkOneExplanation
                                  ?.itemFrameworkOneExplanationMedia &&
                                  reviewMode === 'revise' &&
                                  ReactHTMLParser(
                                    '<span>item</span>-' +
                                      `${keys + 1}` +
                                      "&nbsp <span class='iguru-header-badge1_0'>explanation</span>"
                                  )) || (
                                  <EditorTemplate
                                    label={'sub item'}
                                    jsonData={
                                      ob.itemFrameworkOne?.itemFrameworkOneExplanation
                                        ?.itemFrameworkOneExplanationMedia
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          )}
                          {isPopUpValue === `LIKERT_ITEM_MEDIA_TEXT_${keys}` && (
                            <PopUpTextEditor
                              isActive={true}
                              headerOne={'item'}
                              headerPanelColour={'genericOne'}
                              // headerOneBadgeOne={'media'}
                              headerOneBadgeTwo={''}
                              basicInfo={{}}
                              typeOfSetObject={''}
                              defaultSheetValue={ob.itemFrameworkOne?.itemFrameworkOneMedia || ''}
                              actualLableValue={''}
                              mode={'revise'}
                              onClickSave={(innerText) => {
                                // setInnerContent(innerText);
                                let opArr = itemFrameworkOne.itemFrameworkOneSection;
                                opArr.forEach((element) => {
                                  if (
                                    element.itemFrameworkOneSectionSequence ===
                                    ob.itemFrameworkOneSectionSequence
                                  ) {
                                    element.itemFrameworkOne.itemFrameworkOneMedia = innerText;
                                  }
                                });
                                dispatch({
                                  type: SET_ITEM_FRAMEWORK_DYNAMIC_SINGLE_STATE,
                                  payload: {
                                    stateName: 'itemFrameworkOneSection',
                                    value: opArr
                                  }
                                });
                              }}
                            />
                          )}
                          {isPopUpValue === `LIKERT_ITEM_EXPLANATION_MEDIA_TEXT_${keys}` && (
                            <PopUpTextEditor
                              isActive={true}
                              headerOne={'item'}
                              headerPanelColour={'genericOne'}
                              headerOneBadgeOne={'explanation'}
                              headerOneBadgeTwo={''}
                              basicInfo={{}}
                              typeOfSetObject={''}
                              defaultSheetValue={
                                ob.itemFrameworkOne?.itemFrameworkOneExplanation
                                  ?.itemFrameworkOneExplanationMedia || ''
                              }
                              actualLableValue={''}
                              mode={'revise'}
                              onClickSave={(innerText) => {
                                // setInnerContent(innerText);
                                let opArr = itemFrameworkOne.itemFrameworkOneSection;
                                opArr.forEach((element) => {
                                  if (
                                    element.itemFrameworkOneSectionSequence ===
                                    ob.itemFrameworkOneSectionSequence
                                  ) {
                                    element.itemFrameworkOne.itemFrameworkOneExplanation.itemFrameworkOneExplanationMedia = innerText;
                                  }
                                });
                                dispatch({
                                  type: SET_ITEM_FRAMEWORK_DYNAMIC_SINGLE_STATE,
                                  payload: {
                                    stateName: 'itemFrameworkOneSection',
                                    value: opArr
                                  }
                                });
                              }}
                            />
                          )}
                        </div>
                        {ob.itemFrameworkOne?.itemFrameworkOneResponseChoice &&
                          ob.itemFrameworkOne?.itemFrameworkOneResponseChoice.map((opt, key) => {
                            return (
                              <>
                                <div
                                  style={{
                                    gridColumn: `span ${2} / span ${2}`
                                  }}
                                >
                                  <div key={`op-${key}`} className={'likert_choice-sclae'}>
                                    <input
                                      type="radio"
                                      name={`option1-${ob.itemFrameworkOneSectionSequence}`}
                                      value={`${keys}-${key}`}
                                      style={{
                                        cursor: reviewMode === 'revise' ? 'pointer' : ''
                                      }}
                                    />
                                    <div
                                      className={'likert-choice-font'}
                                      style={{
                                        cursor: reviewMode === 'revise' ? 'pointer' : ''
                                      }}
                                      onClick={
                                        reviewMode === 'revise'
                                          ? () => {
                                              dispatch({
                                                type: SET_POPUP_VALUE,
                                                payload: {
                                                  isPopUpValue: 'ITEM_OPTION_PRIMARY_POPUP',
                                                  popupMode: `OPTION_LIKRT_${keys}_${key}`
                                                }
                                              });
                                              setSelectedChoiceObject(opt);
                                            }
                                          : null
                                      }
                                    >
                                      {(!opt.itemFrameworkOneResponseChoiceMedia &&
                                        reviewMode === 'revise' &&
                                        ReactHTMLParser(optionLabel1)) || (
                                        <EditorTemplate
                                          label={'subitemchoice'}
                                          jsonData={opt.itemFrameworkOneResponseChoiceMedia}
                                        />
                                      )}
                                    </div>
                                  </div>
                                  {opt.itemFrameworkOneResponseChoiceExplanation
                                    ?.itemFrameworkOneResponseChoiceExplanationMedia ||
                                    (reviewMode === 'revise' && (
                                      <div className={'likert_choice-sclae'}>
                                        <div
                                          className={[
                                            'likert-choice-font-Two',
                                            'ig-explanation',
                                            'ex_container'
                                          ].join(' ')}
                                          style={{
                                            cursor: reviewMode === 'revise' ? 'pointer' : ''
                                          }}
                                          onClick={
                                            reviewMode === 'revise'
                                              ? () => {
                                                  dispatch({
                                                    type: SET_POPUP_VALUE,
                                                    payload: {
                                                      isPopUpValue:
                                                        'ITEM_RESPONSE_CHOICE_EXPLANATION_POPUP',
                                                      popupMode: `LIKERT_RESPONSE_CHOICE_DESCRIPTION_${keys}_${key}`
                                                    }
                                                  });
                                                }
                                              : null
                                          }
                                        >
                                          {(!opt.itemFrameworkOneResponseChoiceExplanation
                                            ?.itemFrameworkOneResponseChoiceExplanationMedia &&
                                            reviewMode === 'revise' &&
                                            ReactHTMLParser(responseChoiceDescription1)) || (
                                            <EditorTemplate
                                              jsonData={
                                                opt.itemFrameworkOneResponseChoiceExplanation
                                                  ?.itemFrameworkOneResponseChoiceExplanationMedia
                                              }
                                            />
                                          )}
                                        </div>
                                      </div>
                                    ))}
                                </div>
                                {isPopUpValue === `OPTION_LIKRT_${keys}_${key}` && (
                                  <PopUpTextEditor
                                    isActive={true}
                                    headerOne={'response-choice'}
                                    headerPanelColour={'genericOne'}
                                    basicInfo={{}}
                                    typeOfSetObject={''}
                                    defaultSheetValue={
                                      opt?.itemFrameworkOneResponseChoiceMedia || ''
                                    }
                                    actualLableValue={'assessmentManuscriptSecondary'}
                                    mode={'revise'}
                                    onClickSave={(innerText) => {
                                      let opArr = itemFrameworkOne.itemFrameworkOneSection;
                                      opArr.forEach((element, index) => {
                                        if (index === keys) {
                                          element.itemFrameworkOne.itemFrameworkOneResponseChoice[
                                            key
                                          ].itemFrameworkOneResponseChoiceMedia = innerText;
                                        }
                                      });
                                      dispatch({
                                        type: SET_ITEM_FRAMEWORK_DYNAMIC_SINGLE_STATE,
                                        payload: {
                                          stateName: 'itemFrameworkOneSection',
                                          value: opArr
                                        }
                                      });
                                    }}
                                  />
                                )}
                                {isPopUpValue ===
                                  `LIKERT_RESPONSE_CHOICE_DESCRIPTION_${keys}_${key}` && (
                                  <PopUpTextEditor
                                    isActive={true}
                                    headerOne={'response-choice'}
                                    headerPanelColour={'genericOne'}
                                    headerOneBadgeTwo={`explanation`}
                                    basicInfo={{}}
                                    typeOfSetObject={''}
                                    defaultSheetValue={
                                      opt.itemFrameworkOneResponseChoiceExplanation
                                        .itemFrameworkOneResponseChoiceExplanationMedia
                                    }
                                    actualLableValue={'assessmentManuscriptSecondary'}
                                    mode={'revise'}
                                    onClickSave={(innerText) => {
                                      let opArr = itemFrameworkOne.itemFrameworkOneSection;
                                      opArr.forEach((element, index) => {
                                        if (
                                          element.itemFrameworkOne
                                            .itemFrameworkOneSectionSequence ===
                                          opt.itemFrameworkOneSectionSequence
                                        ) {
                                          if (index === keys) {
                                            element.itemFrameworkOne.itemFrameworkOneResponseChoice[
                                              key
                                            ].itemFrameworkOneResponseChoiceExplanation.itemFrameworkOneResponseChoiceExplanationMedia = innerText;
                                          }
                                        }
                                      });
                                      dispatch({
                                        type: SET_ITEM_FRAMEWORK_DYNAMIC_SINGLE_STATE,
                                        payload: {
                                          stateName: 'itemFrameworkOneSection',
                                          value: opArr
                                        }
                                      });
                                    }}
                                  />
                                )}
                              </>
                            );
                          })}
                      </Fragment>
                    </div>
                  );
                })}
              </FormControl>
            </div>
          </div>
          <div
            style={{
              display:
                itemFrameworkOneResponseChoiceAlignment !== 'horizontal' &&
                itemFrameworkOneResponseChoiceAlignment !== ''
                  ? 'block'
                  : 'none'
            }}
          >
            <FormControl component="fieldset" style={{ width: '100%' }}>
              {itemFrameworkOne?.itemFrameworkOneScale?.length > 0 && (
                <div className="likart">
                  <div className="innerheightAdjust">
                    <div class="item"></div>

                    {itemFrameworkOne?.itemFrameworkOneScale.map((ob, key) => {
                      return (
                        <div className={'likert_choice-sclae'} style={{ fontSize: '1.2rem' }}>
                          {ob.itemFrameworkOneScale}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {itemFrameworkOneSection.map((ob, keys) => {
                return (
                  <Fragment>
                    <div>
                      {(ob.itemFrameworkOne?.itemFrameworkOneMedia || reviewMode === 'revise') && (
                        <div
                        //  className="likartscale"
                        >
                          <div className={['innerpadding', 'innerheightAdjust'].join(' ')}>
                            <div
                              className={['ex_container', 'ig-itemGeneric'].join(' ')}
                              //className="item"
                              subQuestionId={ob.itemFrameworkOneSectionSequence}
                              style={{
                                cursor: reviewMode === 'revise' ? 'pointer' : ''
                              }}
                              onClick={
                                reviewMode === 'revise'
                                  ? () => {
                                      dispatch({
                                        type: SET_POPUP_VALUE,
                                        payload: {
                                          isPopUpValue: 'SUB_ITEM_PRIMARY_POPUP',
                                          popupMode: `LIKERT_ITEM_MEDIA_TEXT_${keys}`
                                        }
                                      });
                                    }
                                  : null
                              }
                            >
                              {(!ob.itemFrameworkOne?.itemFrameworkOneMedia &&
                                reviewMode === 'revise' &&
                                ReactHTMLParser('<span>item</span>-' + `${keys + 1}`)) || (
                                <EditorTemplate
                                  label={'sub item'}
                                  jsonData={ob.itemFrameworkOne?.itemFrameworkOneMedia}
                                />
                              )}
                            </div>
                          </div>
                          <div className={['innerpadding', 'innerheightAdjust'].join(' ')}>
                            <div
                              className={['ex_container', 'ig-explanation'].join(' ')}
                              //className={['ig-explanation', 'item-explanation'].join(' ')}
                              subQuestionId={ob.itemFrameworkOneSectionSequence}
                              style={{
                                cursor: reviewMode === 'revise' ? 'pointer' : ''
                              }}
                              onClick={
                                reviewMode === 'revise'
                                  ? () => {
                                      dispatch({
                                        type: SET_POPUP_VALUE,
                                        payload: {
                                          isPopUpValue: 'SUB_ITEM_EXP_PRIMARY_POPUP',
                                          popupMode: `LIKERT_ITEM_EXPLANATION_MEDIA_TEXT_${keys}`
                                        }
                                      });
                                    }
                                  : null
                              }
                            >
                              {(!ob.itemFrameworkOne?.itemFrameworkOneExplanation
                                ?.itemFrameworkOneExplanationMedia &&
                                reviewMode === 'revise' &&
                                ReactHTMLParser(
                                  '<span>item</span>-' +
                                    `${keys + 1}` +
                                    " <span class='iguru-header-badge1_0'>explanation</span>"
                                )) || (
                                <EditorTemplate
                                  label={'sub item'}
                                  jsonData={
                                    ob.itemFrameworkOne?.itemFrameworkOneExplanation
                                      ?.itemFrameworkOneExplanationMedia
                                  }
                                />
                              )}
                            </div>
                          </div>
                          <Fragment>
                            {isPopUpValue === `LIKERT_ITEM_MEDIA_TEXT_${keys}` && (
                              <PopUpTextEditor
                                isActive={true}
                                headerOne={'item'}
                                headerPanelColour={'genericOne'}
                                headerOneBadgeTwo={''}
                                basicInfo={{}}
                                typeOfSetObject={''}
                                defaultSheetValue={ob.itemFrameworkOne?.itemFrameworkOneMedia || ''}
                                actualLableValue={''}
                                mode={'revise'}
                                onClickSave={(innerText) => {
                                  let opArr = itemFrameworkOne.itemFrameworkOneSection;
                                  opArr.forEach((element) => {
                                    if (
                                      element.itemFrameworkOneSectionSequence ===
                                      ob.itemFrameworkOneSectionSequence
                                    ) {
                                      element.itemFrameworkOne.itemFrameworkOneMedia = innerText;
                                    }
                                  });
                                  dispatch({
                                    type: SET_ITEM_FRAMEWORK_DYNAMIC_SINGLE_STATE,
                                    payload: {
                                      stateName: 'itemFrameworkOneSection',
                                      value: opArr
                                    }
                                  });
                                }}
                              />
                            )}
                            <PopUpTextEditor
                              isActive={
                                isPopUpValue === `LIKERT_ITEM_EXPLANATION_MEDIA_TEXT_${keys}`
                              }
                              headerOne={'item'}
                              headerPanelColour={'genericOne'}
                              headerOneBadgeOne={'explanation'}
                              headerOneBadgeTwo={''}
                              basicInfo={{}}
                              typeOfSetObject={''}
                              defaultSheetValue={
                                ob.itemFrameworkOne?.itemFrameworkOneExplanation
                                  ?.itemFrameworkOneExplanationMedia || ''
                              }
                              actualLableValue={''}
                              mode={'revise'}
                              onClickSave={(innerText) => {
                                let opArr = itemFrameworkOne.itemFrameworkOneSection;
                                opArr.forEach((element) => {
                                  if (
                                    element.itemFrameworkOneSectionSequence ===
                                    ob.itemFrameworkOneSectionSequence
                                  ) {
                                    element.itemFrameworkOne.itemFrameworkOneExplanation.itemFrameworkOneExplanationMedia = innerText;
                                  }
                                });
                                dispatch({
                                  type: SET_ITEM_FRAMEWORK_DYNAMIC_SINGLE_STATE,
                                  payload: {
                                    stateName: 'itemFrameworkOneSection',
                                    value: opArr
                                  }
                                });
                              }}
                            />
                          </Fragment>
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        display:
                          itemFrameworkOneResponseChoiceAlignment === 'vertical' ||
                          itemFrameworkOneResponseChoiceAlignment === ''
                            ? 'block'
                            : 'flex'
                      }}
                    >
                      {ob.itemFrameworkOne?.itemFrameworkOneResponseChoice &&
                        ob.itemFrameworkOne?.itemFrameworkOneResponseChoice.map((opt, key) => {
                          return (
                            <>
                              <div
                                key={`op-${key}`}
                                className={['innerpadding', 'innerheightAdjust'].join(' ')}
                              >
                                <div
                                  className="option-container ex_container"
                                  key={`option-${key}`}
                                >
                                  <div
                                    style={{
                                      paddingRight: '5px',
                                      height: '20px',
                                      display: 'flex',
                                      alignItems: 'center',
                                      paddingTop: '7px'
                                    }}
                                  >
                                    <input
                                      type="radio"
                                      name={`option1-${ob.itemFrameworkOneSectionSequence}`}
                                      value={`${keys}-${key}`}
                                      style={{
                                        cursor: reviewMode === 'revise' ? 'pointer' : ''
                                      }}
                                    />
                                  </div>

                                  <div
                                    className={'ig-itemGeneric'}
                                    style={{
                                      paddingLeft: '5px',
                                      paddingTop: '7px',
                                      cursor: reviewMode === 'revise' ? 'pointer' : ''
                                    }}
                                    onClick={
                                      reviewMode === 'revise'
                                        ? () => {
                                            dispatch({
                                              type: SET_POPUP_VALUE,
                                              payload: {
                                                isPopUpValue: 'ITEM_OPTION_PRIMARY_POPUP',
                                                popupMode: `OPTION_LIKRT_${keys}_${key}`
                                              }
                                            });
                                            setSelectedChoiceObject(opt);
                                          }
                                        : null
                                    }
                                  >
                                    {(!opt.itemFrameworkOneResponseChoiceMedia &&
                                      reviewMode === 'revise' &&
                                      ReactHTMLParser(optionLabel1)) || (
                                      <EditorTemplate
                                        label={'subitemchoice'}
                                        jsonData={opt.itemFrameworkOneResponseChoiceMedia}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div
                                key={`op-${key}`}
                                className={['innerpadding', 'innerheightAdjust'].join(' ')}
                              >
                                <div
                                  className={['ex_container', 'ig-explanation'].join(' ')}
                                  style={{
                                    paddingLeft: '35px',
                                    display: 'inline-block'
                                  }}
                                >
                                  <div
                                    className={['', 'ig-explanation'].join(' ')}
                                    style={{
                                      cursor: reviewMode === 'revise' ? 'pointer' : ''
                                    }}
                                    onClick={
                                      reviewMode === 'revise'
                                        ? () => {
                                            dispatch({
                                              type: SET_POPUP_VALUE,
                                              payload: {
                                                isPopUpValue:
                                                  'ITEM_RESPONSE_CHOICE_EXPLANATION_POPUP',
                                                popupMode: `LIKERT_RESPONSE_CHOICE_DESCRIPTION_${keys}_${key}`
                                              }
                                            });
                                          }
                                        : null
                                    }
                                  >
                                    {(!opt.itemFrameworkOneResponseChoiceExplanation
                                      ?.itemFrameworkOneResponseChoiceExplanationMedia &&
                                      reviewMode === 'revise' &&
                                      ReactHTMLParser(responseChoiceDescription1)) || (
                                      <EditorTemplate
                                        jsonData={
                                          opt.itemFrameworkOneResponseChoiceExplanation
                                            ?.itemFrameworkOneResponseChoiceExplanationMedia
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                              {isPopUpValue === `OPTION_LIKRT_${keys}_${key}` && (
                                <PopUpTextEditor
                                  isActive={true}
                                  headerOne={'response-choice'}
                                  headerPanelColour={'genericOne'}
                                  basicInfo={{}}
                                  typeOfSetObject={''}
                                  defaultSheetValue={opt?.itemFrameworkOneResponseChoiceMedia || ''}
                                  actualLableValue={'assessmentManuscriptSecondary'}
                                  mode={'revise'}
                                  onClickSave={(innerText) => {
                                    let opArr = itemFrameworkOne.itemFrameworkOneSection;
                                    opArr.forEach((element, index) => {
                                      if (index === keys) {
                                        element.itemFrameworkOne.itemFrameworkOneResponseChoice[
                                          key
                                        ].itemFrameworkOneResponseChoiceMedia = innerText;
                                      }
                                    });
                                    // temp.itemFrameworkOneResponseChoiceMedia = innerText;
                                    dispatch({
                                      type: SET_ITEM_FRAMEWORK_DYNAMIC_SINGLE_STATE,
                                      payload: {
                                        stateName: 'itemFrameworkOneSection',
                                        value: opArr
                                      }
                                    });
                                  }}
                                />
                              )}
                              <PopUpTextEditor
                                isActive={
                                  isPopUpValue ===
                                  `LIKERT_RESPONSE_CHOICE_DESCRIPTION_${keys}_${key}`
                                }
                                headerOne={'response-choice'}
                                headerPanelColour={'genericOne'}
                                headerOneBadgeTwo={`explanation`}
                                basicInfo={{}}
                                typeOfSetObject={''}
                                defaultSheetValue={
                                  opt.itemFrameworkOneResponseChoiceExplanation
                                    ?.itemFrameworkOneResponseChoiceExplanationMedia
                                }
                                actualLableValue={'assessmentManuscriptSecondary'}
                                mode={'revise'}
                                onClickSave={(innerText) => {
                                  let opArr = itemFrameworkOne.itemFrameworkOneSection;
                                  opArr.forEach((element, index) => {
                                    if (
                                      element.itemFrameworkOne.itemFrameworkOneSectionSequence ===
                                      opt.itemFrameworkOneSectionSequence
                                    ) {
                                      if (index === keys) {
                                        element.itemFrameworkOne.itemFrameworkOneResponseChoice[
                                          key
                                        ].itemFrameworkOneResponseChoiceExplanation.itemFrameworkOneResponseChoiceExplanationMedia = innerText;
                                      }
                                    }
                                  });
                                  dispatch({
                                    type: SET_ITEM_FRAMEWORK_DYNAMIC_SINGLE_STATE,
                                    payload: {
                                      stateName: 'itemFrameworkOneSection',
                                      value: opArr
                                    }
                                  });
                                }}
                              />
                            </>
                          );
                        })}
                    </div>
                  </Fragment>
                );
              })}
            </FormControl>
          </div>
        </div>
      ) : null}
      {/* {itemType === 'Rating-Scale (Multiple-Select)' || itemType === 'Template' ? */}
      {itemType === 'Template' ? (
        <div>
          <div className="">
            <FormControl component="fieldset" style={{ width: '100%' }}>
              {itemFrameworkOne?.itemFrameworkOneScale?.length > 0 && (
                <div className="likart">
                  <div className="innerheightAdjust">
                    <div class="item"></div>

                    {itemFrameworkOne?.itemFrameworkOneScale.map((ob, key) => {
                      return (
                        <div className={'likert_choice-sclae'} style={{ fontSize: '1.2rem' }}>
                          {ob.itemFrameworkOneScale}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {itemFrameworkOneSection.map((ob, keys) => {
                return (
                  <Fragment>
                    <div>
                      {(ob.itemFrameworkOne?.itemFrameworkOneMedia || reviewMode === 'revise') && (
                        <div className="">
                          <div className={['innerpadding', 'innerheightAdjust'].join(' ')}>
                            <div
                              className={['ex_container', 'ig-itemGeneric'].join(' ')}
                              //className="item"
                              subQuestionId={ob.itemFrameworkOneSectionSequence}
                              style={{
                                cursor: reviewMode === 'revise' ? 'pointer' : ''
                              }}
                              onClick={
                                reviewMode === 'revise'
                                  ? () => {
                                      dispatch({
                                        type: SET_POPUP_VALUE,
                                        payload: {
                                          isPopUpValue: 'SUB_ITEM_PRIMARY_POPUP',
                                          popupMode: `LIKERT_ITEM_MEDIA_TEXT_${keys}`
                                        }
                                      });
                                    }
                                  : null
                              }
                            >
                              {(!ob.itemFrameworkOne?.itemFrameworkOneMedia &&
                                reviewMode === 'revise' &&
                                ReactHTMLParser('<span>item</span>-' + `${keys + 1}`)) || (
                                <EditorTemplate
                                  label={'sub item'}
                                  jsonData={ob.itemFrameworkOne?.itemFrameworkOneMedia}
                                />
                              )}
                            </div>
                          </div>
                          <div className={['innerpadding', 'innerheightAdjust'].join(' ')}>
                            <div
                              className={['ex_container', 'ig-explanation'].join(' ')}
                              //className={['ig-explanation', 'item-explanation'].join(' ')}
                              subQuestionId={ob.itemFrameworkOneSectionSequence}
                              style={{
                                cursor: reviewMode === 'revise' ? 'pointer' : ''
                              }}
                              onClick={
                                reviewMode === 'revise'
                                  ? () => {
                                      dispatch({
                                        type: SET_POPUP_VALUE,
                                        payload: {
                                          isPopUpValue: 'SUB_ITEM_EXP_PRIMARY_POPUP',
                                          popupMode: `LIKERT_ITEM_EXPLANATION_MEDIA_TEXT_${keys}`
                                        }
                                      });
                                    }
                                  : null
                              }
                            >
                              {(!ob.itemFrameworkOne?.itemFrameworkOneExplanation
                                ?.itemFrameworkOneExplanationMedia &&
                                reviewMode === 'revise' &&
                                //"<span>response</span> &nbsp <span class='iguru-item-badge1_0'>choice</span>&nbsp; <span class='iguru-item-badge1_0'>explanation</span>&nbsp;";
                                ReactHTMLParser(
                                  '<span>item</span>-' +
                                    `${keys + 1}` +
                                    " <span class='iguru-header-badge1_0'>explanation</span>"
                                )) || (
                                <EditorTemplate
                                  label={'sub item'}
                                  jsonData={
                                    ob.itemFrameworkOne?.itemFrameworkOneExplanation
                                      ?.itemFrameworkOneExplanationMedia
                                  }
                                />
                              )}
                            </div>
                          </div>
                          <Fragment>
                            {isPopUpValue === `LIKERT_ITEM_MEDIA_TEXT_${keys}` && (
                              <PopUpTextEditor
                                isActive={true}
                                headerOne={'item'}
                                headerPanelColour={'genericOne'}
                                headerOneBadgeTwo={''}
                                basicInfo={{}}
                                typeOfSetObject={''}
                                defaultSheetValue={ob.itemFrameworkOne?.itemFrameworkOneMedia || ''}
                                actualLableValue={''}
                                mode={'revise'}
                                onClickSave={(innerText) => {
                                  let opArr = itemFrameworkOne.itemFrameworkOneSection;
                                  opArr.forEach((element) => {
                                    if (
                                      element.itemFrameworkOneSectionSequence ===
                                      ob.itemFrameworkOneSectionSequence
                                    ) {
                                      element.itemFrameworkOne.itemFrameworkOneMedia = innerText;
                                    }
                                  });
                                  dispatch({
                                    type: SET_ITEM_FRAMEWORK_DYNAMIC_SINGLE_STATE,
                                    payload: {
                                      stateName: 'itemFrameworkOneSection',
                                      value: opArr
                                    }
                                  });
                                }}
                              />
                            )}
                            <PopUpTextEditor
                              isActive={
                                isPopUpValue === `LIKERT_ITEM_EXPLANATION_MEDIA_TEXT_${keys}`
                              }
                              headerOne={'item'}
                              headerPanelColour={'genericOne'}
                              headerOneBadgeOne={'explanation'}
                              headerOneBadgeTwo={''}
                              basicInfo={{}}
                              typeOfSetObject={''}
                              defaultSheetValue={
                                ob.itemFrameworkOne?.itemFrameworkOneExplanation
                                  ?.itemFrameworkOneExplanationMedia || ''
                              }
                              actualLableValue={''}
                              mode={'revise'}
                              onClickSave={(innerText) => {
                                // setInnerContent(innerText);
                                let opArr = itemFrameworkOne.itemFrameworkOneSection;
                                opArr.forEach((element) => {
                                  if (
                                    element.itemFrameworkOneSectionSequence ===
                                    ob.itemFrameworkOneSectionSequence
                                  ) {
                                    element.itemFrameworkOne.itemFrameworkOneExplanation.itemFrameworkOneExplanationMedia = innerText;
                                  }
                                });
                                dispatch({
                                  type: SET_ITEM_FRAMEWORK_DYNAMIC_SINGLE_STATE,
                                  payload: {
                                    stateName: 'itemFrameworkOneSection',
                                    value: opArr
                                  }
                                });
                              }}
                            />
                          </Fragment>
                        </div>
                      )}
                    </div>
                    <div
                      className={['', 'rating-scale-container'].join(' ')}
                      style={{
                        display:
                          itemFrameworkOneResponseChoiceAlignment === 'vertical' ||
                          itemFrameworkOneResponseChoiceAlignment === ''
                            ? 'block'
                            : 'grid'
                      }}
                    >
                      <div style={{ gridColumn: `span ${2} / span ${2}` }}></div>
                      {ob.itemFrameworkOne?.itemFrameworkOneResponseChoice &&
                        ob.itemFrameworkOne?.itemFrameworkOneResponseChoice.map((opt, key) => {
                          return (
                            <>
                              <div
                                key={`op-${key}`}
                                //style={{ flex: '2' }}
                                style={{
                                  gridColumn: `span ${2} / span ${2}`
                                }}
                              >
                                <div className={['innerpadding', 'innerheightAdjust'].join(' ')}>
                                  <div
                                    className="option-container ex_container"
                                    key={`option-${key}`}
                                  >
                                    <div
                                      style={{
                                        paddingRight: '5px',
                                        height: '20px',
                                        display: 'flex',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <input
                                        type="radio"
                                        name={`option1-${ob.itemFrameworkOneSectionSequence}`}
                                        value={`${keys}-${key}`}
                                        // onChange={handleClick}
                                        style={{
                                          cursor: reviewMode === 'revise' ? 'pointer' : ''
                                        }}
                                      />
                                    </div>

                                    <div
                                      className={'ig-itemGeneric'}
                                      style={{
                                        paddingLeft: '5px',
                                        cursor: reviewMode === 'revise' ? 'pointer' : ''
                                      }}
                                      onClick={
                                        reviewMode === 'revise'
                                          ? () => {
                                              dispatch({
                                                type: SET_POPUP_VALUE,
                                                payload: {
                                                  isPopUpValue: 'ITEM_OPTION_PRIMARY_POPUP',
                                                  popupMode: `OPTION_LIKRT_${keys}_${key}`
                                                }
                                              });
                                              setSelectedChoiceObject(opt);
                                            }
                                          : null
                                      }
                                    >
                                      {(!opt.itemFrameworkOneResponseChoiceMedia &&
                                        reviewMode === 'revise' &&
                                        ReactHTMLParser(optionLabel1)) || (
                                        <EditorTemplate
                                          label={'subitemchoice'}
                                          jsonData={opt.itemFrameworkOneResponseChoiceMedia}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className={['innerpadding', 'innerheightAdjust'].join(' ')}>
                                  <div
                                    style={{
                                      paddingLeft: '30px',
                                      display: 'inline-block'
                                    }}
                                  >
                                    <div
                                      className={['ex_container', 'ig-explanation'].join(' ')}
                                      style={{
                                        cursor: reviewMode === 'revise' ? 'pointer' : ''
                                      }}
                                      onClick={
                                        reviewMode === 'revise'
                                          ? () => {
                                              dispatch({
                                                type: SET_POPUP_VALUE,
                                                payload: {
                                                  isPopUpValue:
                                                    'ITEM_RESPONSE_CHOICE_EXPLANATION_POPUP',
                                                  popupMode: `LIKERT_RESPONSE_CHOICE_DESCRIPTION_${keys}_${key}`
                                                }
                                              });
                                            }
                                          : null
                                      }
                                    >
                                      {(!opt.itemFrameworkOneResponseChoiceExplanation
                                        ?.itemFrameworkOneResponseChoiceExplanationMedia &&
                                        reviewMode === 'revise' &&
                                        ReactHTMLParser(responseChoiceDescription1)) || (
                                        <EditorTemplate
                                          jsonData={
                                            opt.itemFrameworkOneResponseChoiceExplanation
                                              ?.itemFrameworkOneResponseChoiceExplanationMedia
                                          }
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {isPopUpValue === `OPTION_LIKRT_${keys}_${key}` && (
                                <PopUpTextEditor
                                  isActive={true}
                                  headerOne={'response-choice'}
                                  headerPanelColour={'genericOne'}
                                  basicInfo={{}}
                                  typeOfSetObject={''}
                                  defaultSheetValue={opt?.itemFrameworkOneResponseChoiceMedia || ''}
                                  actualLableValue={'assessmentManuscriptSecondary'}
                                  mode={'revise'}
                                  onClickSave={(innerText) => {
                                    let opArr = itemFrameworkOne.itemFrameworkOneSection;
                                    opArr.forEach((element, index) => {
                                      if (index === keys) {
                                        element.itemFrameworkOne.itemFrameworkOneResponseChoice[
                                          key
                                        ].itemFrameworkOneResponseChoiceMedia = innerText;
                                      }
                                    });
                                    // temp.itemFrameworkOneResponseChoiceMedia = innerText;
                                    dispatch({
                                      type: SET_ITEM_FRAMEWORK_DYNAMIC_SINGLE_STATE,
                                      payload: {
                                        stateName: 'itemFrameworkOneSection',
                                        value: opArr
                                      }
                                    });
                                  }}
                                />
                              )}
                              {isPopUpValue ===
                                `LIKERT_RESPONSE_CHOICE_DESCRIPTION_${keys}_${key}` && (
                                <PopUpTextEditor
                                  isActive={true}
                                  headerOne={'response-choice'}
                                  headerPanelColour={'genericOne'}
                                  headerOneBadgeTwo={`explanation`}
                                  basicInfo={{}}
                                  typeOfSetObject={''}
                                  defaultSheetValue={
                                    opt.itemFrameworkOneResponseChoiceExplanation
                                      .itemFrameworkOneResponseChoiceExplanationMedia
                                  }
                                  actualLableValue={'assessmentManuscriptSecondary'}
                                  mode={'revise'}
                                  onClickSave={(innerText) => {
                                    let opArr = itemFrameworkOne.itemFrameworkOneSection;
                                    opArr.forEach((element, index) => {
                                      if (
                                        element.itemFrameworkOne.itemFrameworkOneSectionSequence ===
                                        opt.itemFrameworkOneSectionSequence
                                      ) {
                                        if (index === keys) {
                                          element.itemFrameworkOne.itemFrameworkOneResponseChoice[
                                            key
                                          ].itemFrameworkOneResponseChoiceExplanation.itemFrameworkOneResponseChoiceExplanationMedia = innerText;
                                        }
                                      }
                                    });
                                    dispatch({
                                      type: SET_ITEM_FRAMEWORK_DYNAMIC_SINGLE_STATE,
                                      payload: {
                                        stateName: 'itemFrameworkOneSection',
                                        value: opArr
                                      }
                                    });
                                  }}
                                />
                              )}
                            </>
                          );
                        })}
                    </div>
                  </Fragment>
                );
              })}
            </FormControl>
          </div>
        </div>
      ) : null}

      {/* {itemType === 'Template' ?
        itemFrameworkOne?.itemFrameworkOneResponseChoiceAlignment === 'horizontal'
          //&& itemFrameworkOne?.itemFrameworkOneAlignment === 'vertical' 
          ? (
            <div style={{
              display: itemFrameworkOne?.itemFrameworkOneResponseChoiceAlignment === 'horizontal'
                //&& itemFrameworkOne?.itemFrameworkOneAlignment === 'vertical' 
                ? 'block' : 'none'
            }}>
              <div className="likartscale">
                <FormControl component="fieldset" style={{ width: '100%' }}>
                  {itemFrameworkOne?.itemFrameworkOneScale?.length > 0 && (
                    <div className="likart">
                      <div className="innerheightAdjust">
                        <div class="item"></div>

                        {itemFrameworkOne?.itemFrameworkOneScale.map((ob, key) => {
                          return (
                            <div className={'likert_choice-sclae'} style={{ fontSize: '1.2rem' }}>
                              {ob.itemFrameworkOneScale}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}

                  {itemFrameworkOneSection.map((ob, keys) => {
                    return (
                      <Fragment>
                        <div className="innerheightAdjust">
                          {(ob.itemFrameworkOne?.itemFrameworkOneMedia || reviewMode === 'revise') && (
                            <div className="likart">
                              <Fragment>
                                <div style={{ flex: '6' }}>
                                  <div
                                    className="item"
                                    subQuestionId={ob.itemFrameworkOneSectionSequence}
                                    style={{
                                      cursor: reviewMode === 'revise' ? 'pointer' : ''
                                    }}
                                    onClick={
                                      reviewMode === 'revise'
                                        ? () => {
                                          dispatch({
                                            type: SET_POPUP_VALUE,
                                            payload: {
                                              isPopUpValue: 'SUB_ITEM_PRIMARY_POPUP',
                                              popupMode: `LIKERT_ITEM_MEDIA_TEXT_${keys}`
                                            }
                                          });
                                        }
                                        : null
                                    }
                                  >
                                    {(!ob.itemFrameworkOne?.itemFrameworkOneMedia &&
                                      reviewMode === 'revise' &&
                                      ReactHTMLParser('<span>item</span>-' + `${keys + 1}`)) || (
                                        <EditorTemplate
                                          label={'sub item'}
                                          jsonData={ob.itemFrameworkOne?.itemFrameworkOneMedia}
                                        />
                                      )}
                                  </div>
                                  <div
                                    subQuestionId={ob.itemFrameworkOneSectionSequence}
                                    style={{
                                      cursor: reviewMode === 'revise' ? 'pointer' : ''
                                    }}
                                    className={['ig-explanation', 'item-explanation'].join(' ')}
                                    onClick={
                                      reviewMode === 'revise'
                                        ? () => {
                                          dispatch({
                                            type: SET_POPUP_VALUE,
                                            payload: {
                                              isPopUpValue: 'SUB_ITEM_EXP_PRIMARY_POPUP',
                                              popupMode: `LIKERT_ITEM_EXPLANATION_MEDIA_TEXT_${keys}`
                                            }
                                          });
                                        }
                                        : null
                                    }
                                  >
                                    {(!ob.itemFrameworkOne?.itemFrameworkOneExplanation
                                      ?.itemFrameworkOneExplanationMedia &&
                                      reviewMode === 'revise' &&
                                      //"<span>response</span> &nbsp <span class='iguru-item-badge1_0'>choice</span>&nbsp; <span class='iguru-item-badge1_0'>explanation</span>&nbsp;";
                                      ReactHTMLParser(
                                        '<span>item</span>-' +
                                        `${keys + 1}` +
                                        "&nbsp <span class='iguru-item-badge1_0'>explanation</span>"
                                      )) || (
                                        <EditorTemplate
                                          label={'sub item'}
                                          jsonData={
                                            ob.itemFrameworkOne?.itemFrameworkOneExplanation
                                              ?.itemFrameworkOneExplanationMedia
                                          }
                                        />
                                      )}
                                  </div>
                                </div>

                                <PopUpTextEditor
                                  isActive={isPopUpValue === `LIKERT_ITEM_MEDIA_TEXT_${keys}`}
                                  headerOne={'item'}
                                  headerPanelColour={'genericOne'}
                                  // headerOneBadgeOne={'media'}
                                  headerOneBadgeTwo={''}
                                  basicInfo={{}}
                                  typeOfSetObject={''}
                                  defaultSheetValue={ob.itemFrameworkOne?.itemFrameworkOneMedia || ''}
                                  actualLableValue={''}
                                  mode={'revise'}
                                  onClickSave={(innerText) => {
                                    // setInnerContent(innerText);
                                    let opArr = itemFrameworkOne.itemFrameworkOneSection;
                                    opArr.forEach((element) => {
                                      if (
                                        element.itemFrameworkOneSectionSequence ===
                                        ob.itemFrameworkOneSectionSequence
                                      ) {
                                        element.itemFrameworkOne.itemFrameworkOneMedia = innerText;
                                      }
                                    });
                                    dispatch({
                                      type: SET_ITEM_FRAMEWORK_DYNAMIC_SINGLE_STATE,
                                      payload: {
                                        stateName: 'itemFrameworkOneSection',
                                        value: opArr
                                      }
                                    });
                                  }}
                                />
                                <PopUpTextEditor
                                  isActive={isPopUpValue === `LIKERT_ITEM_EXPLANATION_MEDIA_TEXT_${keys}`}
                                  headerOne={'item'}
                                  headerPanelColour={'genericOne'}
                                  headerOneBadgeOne={'explanation'}
                                  headerOneBadgeTwo={''}
                                  basicInfo={{}}
                                  typeOfSetObject={''}
                                  defaultSheetValue={
                                    ob.itemFrameworkOne?.itemFrameworkOneExplanation
                                      ?.itemFrameworkOneExplanationMedia || ''
                                  }
                                  actualLableValue={''}
                                  mode={'revise'}
                                  onClickSave={(innerText) => {
                                    // setInnerContent(innerText);
                                    let opArr = itemFrameworkOne.itemFrameworkOneSection;
                                    opArr.forEach((element) => {
                                      if (
                                        element.itemFrameworkOneSectionSequence ===
                                        ob.itemFrameworkOneSectionSequence
                                      ) {
                                        element.itemFrameworkOne.itemFrameworkOneExplanation.itemFrameworkOneExplanationMedia = innerText;
                                      }
                                    });
                                    dispatch({
                                      type: SET_ITEM_FRAMEWORK_DYNAMIC_SINGLE_STATE,
                                      payload: {
                                        stateName: 'itemFrameworkOneSection',
                                        value: opArr
                                      }
                                    });
                                  }}
                                />
                                {ob.itemFrameworkOne?.itemFrameworkOneResponseChoice &&
                                  ob.itemFrameworkOne?.itemFrameworkOneResponseChoice.map((opt, key) => {
                                    return (
                                      <>
                                        <div
                                          key={`op-${key}`}
                                          className={'likert_choice-sclae'}
                                          style={{ display: 'inline-table' }}
                                        >
                                          <input
                                            type="radio"
                                            name={`option1-${ob.itemFrameworkOneSectionSequence}`}
                                            value={`${keys}-${key}`}
                                            // onChange={handleClick}
                                            style={{
                                              cursor: reviewMode === 'revise' ? 'pointer' : ''
                                            }}
                                          />
                                          <div
                                            className={'likert-choice-font'}
                                            style={{
                                              cursor: reviewMode === 'revise' ? 'pointer' : ''
                                            }}
                                            onClick={
                                              reviewMode === 'revise'
                                                ? () => {
                                                  dispatch({
                                                    type: SET_POPUP_VALUE,
                                                    payload: {
                                                      isPopUpValue: 'ITEM_OPTION_PRIMARY_POPUP',
                                                      popupMode: `OPTION_LIKRT_${keys}_${key}`
                                                    }
                                                  });
                                                  setSelectedChoiceObject(opt);
                                                }
                                                : null
                                            }
                                          >
                                            {(!opt.itemFrameworkOneResponseChoiceMedia &&
                                              reviewMode === 'revise' &&
                                              ReactHTMLParser(optionLabel1)) || (
                                                <EditorTemplate
                                                  label={'subitemchoice'}
                                                  jsonData={opt.itemFrameworkOneResponseChoiceMedia}
                                                />
                                              )}
                                          </div>
                                          <div
                                            className={['likert-choice-font', 'ig-explanation'].join(' ')}
                                            style={{
                                              cursor: reviewMode === 'revise' ? 'pointer' : ''
                                            }}
                                            onClick={
                                              reviewMode === 'revise'
                                                ? () => {
                                                  dispatch({
                                                    type: SET_POPUP_VALUE,
                                                    payload: {
                                                      isPopUpValue:
                                                        'ITEM_RESPONSE_CHOICE_EXPLANATION_POPUP',
                                                      popupMode: `LIKERT_RESPONSE_CHOICE_DESCRIPTION_${keys}_${key}`
                                                    }
                                                  });
                                                }
                                                : null
                                            }
                                          >
                                            {(!opt.itemFrameworkOneResponseChoiceExplanation
                                              ?.itemFrameworkOneResponseChoiceExplanationMedia &&
                                              reviewMode === 'revise' &&
                                              ReactHTMLParser(responseChoiceDescription1)) || (
                                                <EditorTemplate
                                                  jsonData={
                                                    opt.itemFrameworkOneResponseChoiceExplanation
                                                      ?.itemFrameworkOneResponseChoiceExplanationMedia
                                                  }
                                                />
                                              )}
                                          </div>
                                        </div>
                                        <PopUpTextEditor
                                          isActive={isPopUpValue === `OPTION_LIKRT_${keys}_${key}`}
                                          headerOne={'response-choice'}
                                          headerPanelColour={'genericOne'}
                                          //headerOneBadgeOne={'choice'}
                                          // headerOneBadgeTwo={`${key + 1}`}
                                          basicInfo={{}}
                                          typeOfSetObject={''}
                                          defaultSheetValue={
                                            opt?.itemFrameworkOneResponseChoiceMedia || ''
                                          }
                                          actualLableValue={'assessmentManuscriptSecondary'}
                                          mode={'revise'}
                                          onClickSave={(innerText) => {
                                            let opArr = itemFrameworkOne.itemFrameworkOneSection;
                                            opArr.forEach((element, index) => {
                                              if (index === keys) {
                                                element.itemFrameworkOne.itemFrameworkOneResponseChoice[
                                                  key
                                                ].itemFrameworkOneResponseChoiceMedia = innerText;
                                              }
                                            });
                                            // temp.itemFrameworkOneResponseChoiceMedia = innerText;
                                            dispatch({
                                              type: SET_ITEM_FRAMEWORK_DYNAMIC_SINGLE_STATE,
                                              payload: {
                                                stateName: 'itemFrameworkOneSection',
                                                value: opArr
                                              }
                                            });
                                          }}
                                        />
                                        <PopUpTextEditor
                                          isActive={
                                            isPopUpValue ===
                                            `LIKERT_RESPONSE_CHOICE_DESCRIPTION_${keys}_${key}`
                                          }
                                          headerOne={'response-choice'}
                                          headerPanelColour={'genericOne'}
                                          //headerOneBadgeOne={'choice'}
                                          headerOneBadgeTwo={`explanation`}
                                          basicInfo={{}}
                                          typeOfSetObject={''}
                                          defaultSheetValue={
                                            opt.itemFrameworkOneResponseChoiceExplanation
                                              .itemFrameworkOneResponseChoiceExplanationMedia
                                          }
                                          actualLableValue={'assessmentManuscriptSecondary'}
                                          mode={'revise'}
                                          onClickSave={(innerText) => {
                                            let opArr = itemFrameworkOne.itemFrameworkOneSection;
                                            opArr.forEach((element, index) => {
                                              if (
                                                element.itemFrameworkOne
                                                  .itemFrameworkOneSectionSequence ===
                                                opt.itemFrameworkOneSectionSequence
                                              ) {
                                                if (index === keys) {
                                                  element.itemFrameworkOne.itemFrameworkOneResponseChoice[
                                                    key
                                                  ].itemFrameworkOneResponseChoiceExplanation.itemFrameworkOneResponseChoiceExplanationMedia = innerText;
                                                }
                                              }
                                            });
                                            dispatch({
                                              type: SET_ITEM_FRAMEWORK_DYNAMIC_SINGLE_STATE,
                                              payload: {
                                                stateName: 'itemFrameworkOneSection',
                                                value: opArr
                                              }
                                            });
                                          }}
                                        />
                                      </>
                                    );
                                  })}
                              </Fragment>
                            </div>
                          )}
                        </div>
                      </Fragment>
                    );
                  })}
                </FormControl>
              </div>
            </div>
          ) : (
            <div className="likartscale">
              <FormControl component="fieldset" style={{ width: '100%' }}>
                {itemFrameworkOne?.itemFrameworkOneScale?.length > 0 && (
                  <div className="likart">
                    <div className="innerheightAdjust">
                      <div class="item"></div>

                      {itemFrameworkOne?.itemFrameworkOneScale.map((ob, key) => {
                        return (
                          <div className={'likert_choice-sclae'} style={{ fontSize: '1.2rem' }}>
                            {ob.itemFrameworkOneScale}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                {itemFrameworkOneSection.map((ob, keys) => {
                  return (
                    <Fragment>
                      <div>
                        {(ob.itemFrameworkOne?.itemFrameworkOneMedia || reviewMode === 'revise') && (
                          <div className="likartscale">
                            <div className={['innerpadding', 'innerheightAdjust'].join(' ')}>
                              <div
                                className={['ex_container', 'ig-itemGeneric'].join(' ')}
                                //className="item"
                                subQuestionId={ob.itemFrameworkOneSectionSequence}
                                style={{
                                  cursor: reviewMode === 'revise' ? 'pointer' : ''
                                }}
                                onClick={
                                  reviewMode === 'revise'
                                    ? () => {
                                      dispatch({
                                        type: SET_POPUP_VALUE,
                                        payload: {
                                          isPopUpValue: 'SUB_ITEM_PRIMARY_POPUP',
                                          popupMode: `LIKERT_ITEM_MEDIA_TEXT_${keys}`
                                        }
                                      });
                                    }
                                    : null
                                }
                              >
                                {(!ob.itemFrameworkOne?.itemFrameworkOneMedia &&
                                  reviewMode === 'revise' &&
                                  ReactHTMLParser('<span>item</span>-' + `${keys + 1}`)) || (
                                    <EditorTemplate
                                      label={'sub item'}
                                      jsonData={ob.itemFrameworkOne?.itemFrameworkOneMedia}
                                    />
                                  )}
                              </div>
                            </div>
                            <div className={['innerpadding', 'innerheightAdjust'].join(' ')}>
                              <div
                                className={['ex_container', 'ig-explanation'].join(' ')}
                                //className={['ig-explanation', 'item-explanation'].join(' ')}
                                subQuestionId={ob.itemFrameworkOneSectionSequence}
                                style={{
                                  cursor: reviewMode === 'revise' ? 'pointer' : ''
                                }}
                                onClick={
                                  reviewMode === 'revise'
                                    ? () => {
                                      dispatch({
                                        type: SET_POPUP_VALUE,
                                        payload: {
                                          isPopUpValue: 'SUB_ITEM_EXP_PRIMARY_POPUP',
                                          popupMode: `LIKERT_ITEM_EXPLANATION_MEDIA_TEXT_${keys}`
                                        }
                                      });
                                    }
                                    : null
                                }
                              >
                                {(!ob.itemFrameworkOne?.itemFrameworkOneExplanation
                                  ?.itemFrameworkOneExplanationMedia &&
                                  reviewMode === 'revise' &&
                                  //"<span>response</span> &nbsp <span class='iguru-item-badge1_0'>choice</span>&nbsp; <span class='iguru-item-badge1_0'>explanation</span>&nbsp;";
                                  ReactHTMLParser(
                                    '<span>item</span>-' +
                                    `${keys + 1}` +
                                    " <span class='iguru-item-badge1_0'>explanation</span>"
                                  )) || (
                                    <EditorTemplate
                                      label={'sub item'}
                                      jsonData={
                                        ob.itemFrameworkOne?.itemFrameworkOneExplanation
                                          ?.itemFrameworkOneExplanationMedia
                                      }
                                    />
                                  )}
                              </div>
                            </div>
                            <Fragment>                           

                              <PopUpTextEditor
                                isActive={isPopUpValue === `LIKERT_ITEM_MEDIA_TEXT_${keys}`}
                                headerOne={'item'}
                                headerPanelColour={'genericOne'}
                                headerOneBadgeTwo={''}
                                basicInfo={{}}
                                typeOfSetObject={''}
                                defaultSheetValue={ob.itemFrameworkOne?.itemFrameworkOneMedia || ''}
                                actualLableValue={''}
                                mode={'revise'}
                                onClickSave={(innerText) => {
                                  let opArr = itemFrameworkOne.itemFrameworkOneSection;
                                  opArr.forEach((element) => {
                                    if (
                                      element.itemFrameworkOneSectionSequence ===
                                      ob.itemFrameworkOneSectionSequence
                                    ) {
                                      element.itemFrameworkOne.itemFrameworkOneMedia = innerText;
                                    }
                                  });
                                  dispatch({
                                    type: SET_ITEM_FRAMEWORK_DYNAMIC_SINGLE_STATE,
                                    payload: {
                                      stateName: 'itemFrameworkOneSection',
                                      value: opArr
                                    }
                                  });
                                }}
                              />
                              <PopUpTextEditor
                                isActive={isPopUpValue === `LIKERT_ITEM_EXPLANATION_MEDIA_TEXT_${keys}`}
                                headerOne={'item'}
                                headerPanelColour={'genericOne'}
                                headerOneBadgeOne={'explanation'}
                                headerOneBadgeTwo={''}
                                basicInfo={{}}
                                typeOfSetObject={''}
                                defaultSheetValue={
                                  ob.itemFrameworkOne?.itemFrameworkOneExplanation
                                    ?.itemFrameworkOneExplanationMedia || ''
                                }
                                actualLableValue={''}
                                mode={'revise'}
                                onClickSave={(innerText) => {
                                  // setInnerContent(innerText);
                                  let opArr = itemFrameworkOne.itemFrameworkOneSection;
                                  opArr.forEach((element) => {
                                    if (
                                      element.itemFrameworkOneSectionSequence ===
                                      ob.itemFrameworkOneSectionSequence
                                    ) {
                                      element.itemFrameworkOne.itemFrameworkOneExplanation.itemFrameworkOneExplanationMedia = innerText;
                                    }
                                  });
                                  dispatch({
                                    type: SET_ITEM_FRAMEWORK_DYNAMIC_SINGLE_STATE,
                                    payload: {
                                      stateName: 'itemFrameworkOneSection',
                                      value: opArr
                                    }
                                  });
                                }}
                              />
                            </Fragment>
                          </div>
                        )}
                      </div>
                      <div style={{ display: itemFrameworkOne?.itemFrameworkOneResponseChoiceAlignment === 'vertical' ? 'block' : 'flex' }}>
                        {ob.itemFrameworkOne?.itemFrameworkOneResponseChoice &&
                          ob.itemFrameworkOne?.itemFrameworkOneResponseChoice.map((opt, key) => {
                            return (
                              <>
                                <div
                                  key={`op-${key}`}
                                  className={['innerpadding', 'innerheightAdjust'].join(' ')}
                                >
                                  <div className="option-container ex_container" key={`option-${key}`}>
                                    <div
                                      style={{
                                        paddingRight: '5px',
                                        height: '20px',
                                        display: 'flex',
                                        alignItems: 'center'
                                      }}
                                    >
                                      <input
                                        type="radio"
                                        name={`option1-${ob.itemFrameworkOneSectionSequence}`}
                                        value={`${keys}-${key}`}
                                        // onChange={handleClick}
                                        style={{
                                          cursor: reviewMode === 'revise' ? 'pointer' : ''
                                        }}
                                      />
                                    </div>

                                    <div
                                      className={'ig-itemGeneric'}
                                      style={{
                                        cursor: reviewMode === 'revise' ? 'pointer' : ''
                                      }}
                                      onClick={
                                        reviewMode === 'revise'
                                          ? () => {
                                            dispatch({
                                              type: SET_POPUP_VALUE,
                                              payload: {
                                                isPopUpValue: 'ITEM_OPTION_PRIMARY_POPUP',
                                                popupMode: `OPTION_LIKRT_${keys}_${key}`
                                              }
                                            });
                                            setSelectedChoiceObject(opt);
                                          }
                                          : null
                                      }
                                    >
                                      {(!opt.itemFrameworkOneResponseChoiceMedia &&
                                        reviewMode === 'revise' &&
                                        ReactHTMLParser(optionLabel1)) || (
                                          <EditorTemplate
                                            label={'subitemchoice'}
                                            jsonData={opt.itemFrameworkOneResponseChoiceMedia}
                                          />
                                        )}
                                    </div>
                                  </div>
                                  <div style={{ paddingLeft: '25px', display: 'inline-block' }}>
                                    <div
                                      className={['', 'ig-explanation'].join(' ')}
                                      style={{
                                        cursor: reviewMode === 'revise' ? 'pointer' : ''
                                      }}
                                      onClick={
                                        reviewMode === 'revise'
                                          ? () => {
                                            dispatch({
                                              type: SET_POPUP_VALUE,
                                              payload: {
                                                isPopUpValue:
                                                  'ITEM_RESPONSE_CHOICE_EXPLANATION_POPUP',
                                                popupMode: `LIKERT_RESPONSE_CHOICE_DESCRIPTION_${keys}_${key}`
                                              }
                                            });
                                          }
                                          : null
                                      }
                                    >
                                      {(!opt.itemFrameworkOneResponseChoiceExplanation
                                        ?.itemFrameworkOneResponseChoiceExplanationMedia &&
                                        reviewMode === 'revise' &&
                                        ReactHTMLParser(responseChoiceDescription1)) || (
                                          <EditorTemplate
                                            jsonData={
                                              opt.itemFrameworkOneResponseChoiceExplanation
                                                ?.itemFrameworkOneResponseChoiceExplanationMedia
                                            }
                                          />
                                        )}
                                    </div>
                                  </div>
                                </div>
                                <PopUpTextEditor
                                  isActive={isPopUpValue === `OPTION_LIKRT_${keys}_${key}`}
                                  headerOne={'response-choice'}
                                  headerPanelColour={'genericOne'}
                                  //headerOneBadgeOne={'choice'}
                                  // headerOneBadgeTwo={`${key + 1}`}
                                  basicInfo={{}}
                                  typeOfSetObject={''}
                                  defaultSheetValue={
                                    opt?.itemFrameworkOneResponseChoiceMedia || ''
                                  }
                                  actualLableValue={'assessmentManuscriptSecondary'}
                                  mode={'revise'}
                                  onClickSave={(innerText) => {
                                    let opArr = itemFrameworkOne.itemFrameworkOneSection;
                                    opArr.forEach((element, index) => {
                                      if (index === keys) {
                                        element.itemFrameworkOne.itemFrameworkOneResponseChoice[
                                          key
                                        ].itemFrameworkOneResponseChoiceMedia = innerText;
                                      }
                                    });
                                    // temp.itemFrameworkOneResponseChoiceMedia = innerText;
                                    dispatch({
                                      type: SET_ITEM_FRAMEWORK_DYNAMIC_SINGLE_STATE,
                                      payload: {
                                        stateName: 'itemFrameworkOneSection',
                                        value: opArr
                                      }
                                    });
                                  }}
                                />
                                <PopUpTextEditor
                                  isActive={
                                    isPopUpValue ===
                                    `LIKERT_RESPONSE_CHOICE_DESCRIPTION_${keys}_${key}`
                                  }
                                  headerOne={'response-choice'}
                                  headerPanelColour={'genericOne'}
                                  //headerOneBadgeOne={'choice'}
                                  headerOneBadgeTwo={`explanation`}
                                  basicInfo={{}}
                                  typeOfSetObject={''}
                                  defaultSheetValue={
                                    opt.itemFrameworkOneResponseChoiceExplanation
                                      .itemFrameworkOneResponseChoiceExplanationMedia
                                  }
                                  actualLableValue={'assessmentManuscriptSecondary'}
                                  mode={'revise'}
                                  onClickSave={(innerText) => {
                                    let opArr = itemFrameworkOne.itemFrameworkOneSection;
                                    opArr.forEach((element, index) => {
                                      if (
                                        element.itemFrameworkOne
                                          .itemFrameworkOneSectionSequence ===
                                        opt.itemFrameworkOneSectionSequence
                                      ) {
                                        if (index === keys) {
                                          element.itemFrameworkOne.itemFrameworkOneResponseChoice[
                                            key
                                          ].itemFrameworkOneResponseChoiceExplanation.itemFrameworkOneResponseChoiceExplanationMedia = innerText;
                                        }
                                      }
                                    });
                                    dispatch({
                                      type: SET_ITEM_FRAMEWORK_DYNAMIC_SINGLE_STATE,
                                      payload: {
                                        stateName: 'itemFrameworkOneSection',
                                        value: opArr
                                      }
                                    });
                                  }}
                                />
                              </>
                            );
                          })}
                      </div>
                    </Fragment>
                  );
                })}
              </FormControl>
            </div>
          ) : null} */}

      {/* response label */}
      {(itemType === 'Response-Choice (Single-Select)' ||
        itemType === 'Template' ||
        itemType === 'False-True' ||
        itemType === 'Fill-in-the-Blank (Response-Choice)') &&
        (itemFrameworkOne?.itemFrameworkOneResponseLabel?.itemFrameworkOneResponseLabelMedia ||
          reviewMode === 'revise') && (
          <div className={['innerpadding', 'innerheightAdjust'].join(' ')}>
            <div
              className={['ex_container', 'ig-label'].join(' ')}
              style={{
                cursor: reviewMode === 'revise' ? 'pointer' : ''
              }}
              onClick={
                reviewMode === 'revise'
                  ? () => {
                      dispatch({
                        type: SET_POPUP_VALUE,
                        payload: {
                          isPopUpValue: 'ITEM_CHOICE_LABEL_PRIMARY_POPUP',
                          popupMode: popupMode
                        }
                      });
                    }
                  : null
              }
            >
              {(!itemFrameworkOne?.itemFrameworkOneResponseLabel
                ?.itemFrameworkOneResponseLabelMedia &&
                reviewMode === 'revise' &&
                ReactHTMLParser(responseLabelText)) || (
                <EditorTemplate
                  jsonData={
                    itemFrameworkOne?.itemFrameworkOneResponseLabel
                      ?.itemFrameworkOneResponseLabelMedia
                  }
                  label={'itemFrameworkOneResponseLabelMedia'}
                />
              )}
            </div>
          </div>
        )}

      {/* response */}
      {(itemType === 'Response (Long) (Short)' || itemType === 'Template') && (
        <div className={['innerpadding', 'innerheightAdjust'].join(' ')}>
          <div
            className={'ex_container'}
            style={{
              cursor: reviewMode === 'revise' ? 'pointer' : ''
            }}
            onClick={
              reviewMode === 'revise'
                ? () => {
                    dispatch({
                      type: SET_POPUP_VALUE,
                      payload: {
                        isPopUpValue: 'RESPONSE_PRIMARY_POPUP',
                        popupMode: 'RESPONSE_SECONDARY_POPUP'
                      }
                    });
                  }
                : null
            }
          >
            {(!itemFrameworkOne?.itemFrameworkOnePassage?.itemFrameworkOnePassageMedia &&
              reviewMode === 'revise' &&
              ReactHTMLParser(responseText)) || (
              // <EditorJs instanceRef={(instance) => (instanceRef.current = instance)} data={''} />
              <Fragment>
                <div className={[`iguru-editor`].join(' ')}>
                  <EditorJs
                    key={innerContent}
                    instanceRef={(instance) => (instanceRef.current = instance)}
                    data={innerContent}
                    onChange={setInnerContent}
                    tools={{
                      paragraph: {
                        config: {
                          placeholder: 'Type here...'
                        },
                        class: Paragraph
                      }
                    }}
                  />
                </div>
              </Fragment>
            )}
          </div>
        </div>
      )}
      {/* response explanation */}
      {(itemType === 'Response-Choice (Single-Select)' ||
        itemType === 'Template' ||
        itemType === 'False-True' ||
        itemType === 'Fill-in-the-Blank (Response-Choice)') && (
        <Fragment>
          {(itemFrameworkOne?.itemFrameworkOneResponseExplanation
            ?.itemFrameworkOneResponseExplanationMedia ||
            reviewMode === 'revise') && (
            <div className={['innerpadding', 'innerheightAdjust'].join(' ')}>
              <div
                className={['ex_container', 'ig-explanation '].join(' ')}
                style={{ cursor: reviewMode === 'revise' && 'pointer' }}
                onClick={
                  reviewMode === 'revise'
                    ? () => {
                        dispatch({
                          type: SET_POPUP_VALUE,
                          payload: {
                            isPopUpValue: 'RESPONSE_EXPLANATION_POPUP',
                            popupMode: 'RESPONSE_DESCRIPTION_TEXT'
                          }
                        });
                      }
                    : null
                }
              >
                {(!itemFrameworkOne?.itemFrameworkOneResponseExplanation
                  ?.itemFrameworkOneResponseExplanationMedia &&
                  reviewMode === 'revise' &&
                  ReactHTMLParser(responseExplanationText)) || (
                  <EditorTemplate
                    jsonData={
                      itemFrameworkOne?.itemFrameworkOneResponseExplanation
                        ?.itemFrameworkOneResponseExplanationMedia
                    }
                    label={'itemFrameworkOneResponseExplanationMedia'}
                  />
                )}
              </div>
            </div>
          )}
        </Fragment>
      )}

      {/* for response choice */}
      {(itemType === 'Response-Choice (Single-Select)' ||
        itemType === 'Template' ||
        itemType === 'False-True' ||
        itemType === 'Fill-in-the-Blank (Response-Choice)') && (
        <div
          className={[
            'rating-scale-container',
            itemFrameworkOneResponseChoiceAlignment !== 'vertical' ? 'innerheightAdjust' : ''
          ].join(' ')}
          style={{
            display:
              itemFrameworkOneResponseChoiceAlignment === 'vertical' ||
              itemFrameworkOneResponseChoiceAlignment === ''
                ? 'block'
                : 'grid'
          }}
        >
          <div style={{ gridColumn: `span ${2} / span ${2}` }}></div>
          {numberOfNoOptions.map((op, key) => {
            return (
              <Fragment>
                {(op.itemFrameworkOneResponseChoiceMedia || reviewMode === 'revise') && (
                  <div
                    key={`op-${key}`}
                    //style={{ flex: '2' }}
                    style={{
                      gridColumn: `span ${2} / span ${2}`
                    }}
                  >
                    <div
                      className={[
                        'innerpadding',
                        itemFrameworkOneResponseChoiceAlignment === 'vertical'
                          ? 'innerheightAdjust'
                          : ''
                      ].join(' ')}
                    >
                      <div className="option-container ex_container" key={`option-${key}`}>
                        <div
                          style={{
                            paddingRight: '5px',
                            height: '20px',
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          <input
                            type="radio"
                            name="option1"
                            value={`${op.itemFrameworkOneResponseChoiceNumber}`}
                            onChange={handleClick}
                            checked={
                              (itemFrameworkOne.itemFrameworkOneResponseCorrect &&
                                op.itemFrameworkOneResponseChoiceNumber ===
                                  itemFrameworkOne.itemFrameworkOneResponseCorrect[0]) ||
                              false
                            }
                          />
                        </div>
                        {numberOfResponseLabel && numberOfResponseLabel && (
                          <div className={'responselabel'}>
                            {numberOfResponseLabel &&
                              numberOfResponseLabel[key]?.itemFrameworkOneResponseChoiceLabel}
                          </div>
                        )}

                        <div
                          className={['ig-itemGeneric '].join(' ')}
                          style={{
                            paddingLeft: '5px',
                            cursor: reviewMode === 'revise' ? 'pointer' : ''
                          }}
                          onClick={
                            reviewMode === 'revise'
                              ? () => {
                                  dispatch({
                                    type: SET_POPUP_VALUE,
                                    payload: {
                                      isPopUpValue: 'ITEM_OPTION_PRIMARY_POPUP',
                                      popupMode: `OPTION_${key}`
                                    }
                                  });
                                  setSelectedChoiceObject(op);
                                }
                              : null
                          }
                        >
                          {(!op.itemFrameworkOneResponseChoiceMedia &&
                            reviewMode === 'revise' &&
                            ReactHTMLParser(optionLabel)) || (
                            <EditorTemplate
                              jsonData={op.itemFrameworkOneResponseChoiceMedia}
                              label={'itemFrameworkOneResponseChoiceMedia'}
                            />
                          )}
                        </div>

                        {isPopUpValue === `OPTION_${key}` && (
                          <PopUpTextEditor
                            isActive={true}
                            headerOne={'response-choice'}
                            headerPanelColour={'genericOne'}
                            basicInfo={{}}
                            typeOfSetObject={''}
                            defaultSheetValue={op?.itemFrameworkOneResponseChoiceMedia || ''}
                            actualLableValue={'assessmentManuscriptSecondary'}
                            mode={'revise'}
                            onClickSave={(innerText) => {
                              let opArr = numberOfNoOptions;
                              // setQuestionOptionList((opArr) => {
                              opArr.forEach((element) => {
                                if (
                                  element.itemFrameworkOneResponseChoiceNumber ===
                                  op.itemFrameworkOneResponseChoiceNumber
                                ) {
                                  element.itemFrameworkOneResponseChoiceMedia = innerText;
                                }
                              });
                              dispatch({
                                type: SET_ITEM_FRAMEWORK_DYNAMIC_SINGLE_STATE,
                                payload: {
                                  stateName: 'itemFrameworkOneResponseChoice',
                                  value: opArr
                                }
                              });
                              // });
                            }}
                          />
                        )}

                        {isPopUpValue === `RESPONSE_CHOICE_DESCRIPTION_${key}` && (
                          <PopUpTextEditor
                            isActive={true}
                            headerOne={'response-choice'}
                            headerPanelColour={'genericOne'}
                            headerOneBadgeTwo={`explanation`}
                            basicInfo={{}}
                            typeOfSetObject={''}
                            defaultSheetValue={
                              op.itemFrameworkOneResponseChoiceExplanation
                                ?.itemFrameworkOneResponseChoiceExplanationMedia
                            }
                            actualLableValue={'assessmentManuscriptSecondary'}
                            mode={'revise'}
                            onClickSave={(innerText) => {
                              let opArr = numberOfNoOptions;
                              opArr.forEach((element) => {
                                if (
                                  element.itemFrameworkOneResponseChoiceNumber ===
                                  op.itemFrameworkOneResponseChoiceNumber
                                ) {
                                  element.itemFrameworkOneResponseChoiceExplanation.itemFrameworkOneResponseChoiceExplanationMedia = innerText;
                                }
                              });
                              dispatch({
                                type: SET_ITEM_FRAMEWORK_DYNAMIC_SINGLE_STATE,
                                payload: {
                                  stateName: 'itemFrameworkOneResponseChoice',
                                  value: opArr
                                }
                              });
                            }}
                          />
                        )}
                      </div>
                    </div>
                    {(op.itemFrameworkOneResponseChoiceExplanation
                      ?.itemFrameworkOneResponseChoiceExplanationMedia ||
                      reviewMode === 'revise') && (
                      <div
                        className={[
                          'innerpadding',
                          itemFrameworkOneResponseChoiceAlignment === 'vertical'
                            ? 'innerheightAdjust'
                            : ''
                        ].join(' ')}
                      >
                        <div
                          style={{
                            paddingLeft: '30px',
                            display: 'inline-block'
                          }}
                        >
                          {(op.itemFrameworkOneResponseChoiceExplanation
                            ?.itemFrameworkOneResponseChoiceExplanationMedia ||
                            reviewMode === 'revise') && (
                            <div
                              className={['ex_container', 'ig-explanation '].join(' ')}
                              style={{
                                cursor: reviewMode === 'revise' ? 'pointer' : ''
                              }}
                              onClick={
                                reviewMode === 'revise'
                                  ? () => {
                                      dispatch({
                                        type: SET_POPUP_VALUE,
                                        payload: {
                                          isPopUpValue: 'ITEM_RESPONSE_CHOICE_EXPLANATION_POPUP',
                                          popupMode: `RESPONSE_CHOICE_DESCRIPTION_${key}`
                                        }
                                      });
                                    }
                                  : null
                              }
                            >
                              {(!op.itemFrameworkOneResponseChoiceExplanation
                                ?.itemFrameworkOneResponseChoiceExplanationMedia &&
                                reviewMode === 'revise' &&
                                ReactHTMLParser(responseChoiceDescription)) || (
                                <EditorTemplate
                                  jsonData={
                                    op.itemFrameworkOneResponseChoiceExplanation
                                      ?.itemFrameworkOneResponseChoiceExplanationMedia
                                  }
                                  label={'itemFrameworkOneResponseChoiceExplanationMedia'}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </Fragment>
            );
          })}
        </div>
      )}
      <div>
        <div
          style={{
            height: '55px'
          }}
        ></div>
      </div>
    </div>
  );
};

export default DisplayPaneFiveItemTemplate;
