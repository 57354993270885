import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import AllocationAccordian from '../Accordian/AllocationAccordian';
import Unverified from '../../images/unverified.svg';
import { useDispatch, useSelector } from 'react-redux';
import { Paper } from '@material-ui/core';
import DisplayPanelAccordianReviewListOne from '../Accordian/DisplayPanelAccordianReviewListOne';
import DisplayPanelAccordianInformation from '../Accordian/DisplayPanelAccordianInformation';
import {
  ASSOCIATE_SIGN_ON,
  GET_ASSOCIATES_NODE_REVIEW_LIST_SAGA,
  LOADER_START,
  SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT,
  SET_POPUP_VALUE,
  SET_SINGLE_ASSOCIATE_INFORMATION
} from '../../actionType';
import DisplayPanelAccordianReviewListTwo from '../Accordian/DisplayPanelAccordianReviewListTwo';
import { makeAssociateDistinctShareObj, makeAssociateNodeObj } from '../../Actions/GenericActions';
import MailOutline from '@material-ui/icons/MailOutline';
import MailIcon from '@material-ui/icons/Mail';

const DisplayPaneThreeSectionTwoAssociate = () => {
  const [listExpand, setListExpand] = useState('');
  const { responseObject, headerOneBadgeTwo, headerOneBadgeThree, reviewMode } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  const { countPage, selectedAssociateInfo } = useSelector((state) => state.DisplayPaneTwoReducer);
  const dispatch = useDispatch();
  const { informationContact, informationCredential, informationFramework } = responseObject;
  const {
    associateAddressCountryRegion = null,
    associateAddressProvinceState = null,
    associateAddressPostcode = null,
    associateAddressCity = null,
    associateAddress = null
  } = informationContact?.associateAddressWorkPrimary || {};
  let workAddressPrimary =
    ((associateAddress && associateAddress + ', ') || '') +
    ((associateAddressCity && associateAddressCity + ', ') || '') +
    ((associateAddressProvinceState && associateAddressProvinceState + ' ') || '') +
    ((associateAddressPostcode && associateAddressPostcode + ', ') || '') +
    associateAddressCountryRegion;
  if (!workAddressPrimary.trim()) {
    workAddressPrimary = 'No Information';
  }
  const {
    associateAddressCountryRegion: associateAddressCountryRegionSecondary = '',
    associateAddressProvinceState: associateAddressProvinceStateSecondary = '',
    associateAddressPostcode: associateAddressPostcodeSecondary = '',
    associateAddressCity: associateAddressCitySecondary = '',
    associateAddress: associateAddressSecondary = ''
  } = informationContact?.associateAddressWorkSecondary || {};

  // let workAddressSecondary = `${associateAddressSecondary}${associateAddressCitySecondary}${associateAddressProvinceStateSecondary}${associateAddressPostcodeSecondary}${associateAddressCountryRegionSecondary}`;
  let workAddressSecondary =
    ((associateAddressSecondary && associateAddressSecondary + ', ') || '') +
    ((associateAddressCitySecondary && associateAddressCitySecondary + ', ') || '') +
    ((associateAddressProvinceStateSecondary && associateAddressProvinceStateSecondary + ' ') ||
      '') +
    ((associateAddressPostcodeSecondary && associateAddressPostcodeSecondary + ', ') || '') +
    associateAddressCountryRegionSecondary;
  if (!workAddressSecondary.trim()) {
    workAddressSecondary = 'No Information';
  }
  // +91 (citycode) number 'extension' extennumber
  // +91 (80) 786899 extension 987
  const {
    associateTelephoneAreaCity = '',
    associateTelephoneCountryRegion = '',
    associateTelephoneExtension = '',
    associateTelephoneNumber = ''
  } = informationContact?.associateTelephoneWorkPrimary || {};
  let workTelephonePrimary = 'No Information';
  if (
    associateTelephoneAreaCity ||
    associateTelephoneCountryRegion ||
    associateTelephoneExtension ||
    associateTelephoneNumber
  ) {
    workTelephonePrimary = `+${associateTelephoneCountryRegion} (${associateTelephoneAreaCity}) ${associateTelephoneNumber}  ${
      associateTelephoneExtension && 'extension ' + associateTelephoneExtension
    }`;
  }

  const {
    associateTelephoneAreaCity: associateTelephoneAreaCitySecondary = '',
    associateTelephoneCountryRegion: associateTelephoneCountryRegionSecondary = '',
    associateTelephoneExtension: associateTelephoneExtensionSecondary = '',
    associateTelephoneNumber: associateTelephoneNumberSecondary = ''
  } = informationContact?.associateTelephoneWorkSecondary || {};
  let workTelephoneSecondary = 'No Information';
  if (
    associateTelephoneAreaCitySecondary ||
    associateTelephoneCountryRegionSecondary ||
    associateTelephoneExtensionSecondary ||
    associateTelephoneNumberSecondary
  ) {
    workTelephoneSecondary = `+${associateTelephoneCountryRegionSecondary} (${associateTelephoneAreaCitySecondary}) ${associateTelephoneNumberSecondary} ${
      associateTelephoneExtensionSecondary && 'extension ' + associateTelephoneExtensionSecondary
    }`;
  }
  const workAddressPrimaryCommunication =
    informationContact?.associateAddressWorkPrimary?.associateAddressCommunication || false;
  const workAddressSecondaryCommunication =
    informationContact?.associateAddressWorkSecondary?.associateAddressCommunication || false;
  const list1 = [
    {
      id: 'a1',
      labelTextOneOne: 'website address',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'primary',
          textOne:
            informationContact?.associateAddressWebsitePrimary?.associateAddressWebsite ||
            'No Information'
        },
        {
          labelTextOneOneBadge: 'secondary',
          textOne:
            informationContact?.associateAddressWebsiteSecondary?.associateAddressWebsite ||
            'No Information'
        }
      ],
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false,
      IconOne: null,
      IconTwo: null
    },
    {
      id: 'a2',
      labelTextOneOne: 'work address',
      multiline: workAddressPrimary.length > 40,
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'primary',
          textOne: workAddressPrimary,
          IconOne: workAddressPrimaryCommunication ? MailIcon : MailOutline
        },
        {
          labelTextOneOneBadge: 'secondary',
          textOne: workAddressSecondary,
          IconOne: workAddressSecondaryCommunication ? MailIcon : MailOutline
        }
      ],
      labelTextOneOneBadgeThree: '',
      labelTextOneOneBadgeFour: '',
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false,
      IconOne: MailOutline,
      IconTwo: () => {
        return <img src={Unverified} alt="Unverified" />;
      }
    },
    {
      id: 'a3',
      labelTextOneOne: 'work telephone',
      multiline: false,
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'primary',
          textOne: workTelephonePrimary
        },
        {
          labelTextOneOneBadge: 'secondary',
          textOne: workTelephoneSecondary
        }
      ],
      labelTextOneOneBadgeThree: '',
      labelTextOneOneBadgeFour: '',
      innerAssociateList: [],
      innerInfo: 'assessees',
      isListCard: false,
      IconOne: null,
      IconTwo: null
    }
  ];
  const list2 = [
    {
      id: 'a4',
      labelTextOneOne: 'tag',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'statutory',
          textOne: informationCredential || 'No Information'
        }
      ],
      labelTextOneOneBadgeTwo: '',
      labelTextOneOneBadgeThree: '',
      labelTextOneOneBadgeFour: '',
      innerAssociateList: [],
      innerInfo: 'associate',
      isListCard: false,
      IconTwo: () => {
        return <img src={Unverified} alt="Unverified" />;
      }
    }
  ];

  let ascendantAll = [];
  let ascendantPrimary = [];
  let ascendantSecondary = [];
  if (informationFramework && informationFramework.associateAscendant) {
    if (
      informationFramework.associateAscendant.associateAscendantAll &&
      informationFramework.associateAscendant.associateAscendantAll.length > 0
    ) {
      informationFramework.associateAscendant.associateAscendantAll.forEach((ob) => {
        ascendantAll.push({
          id: ob?.id + '-',
          textOne: ob?.associateName || '',
          textTwo: ob?.associateDescription || '',
          status: ''
        });
      });
    }
    if (
      informationFramework.associateAscendant.associateAscendantPrimary &&
      informationFramework.associateAscendant.associateAscendantPrimary.length > 0
    ) {
      informationFramework.associateAscendant.associateAscendantPrimary.forEach((ob) => {
        ascendantPrimary.push({
          id: ob?.id + '-',
          textOne: ob?.associateName || '',
          textTwo: ob?.associateDescription || '',
          status: ''
        });
      });
    }
    if (
      informationFramework.associateAscendant.associateAscendantSecondary &&
      informationFramework.associateAscendant.associateAscendantSecondary.length > 0
    ) {
      informationFramework.associateAscendant.associateAscendantSecondary.forEach((ob) => {
        ascendantSecondary.push({
          id: ob?.id + '-',
          textOne: ob?.associateName || '',
          textTwo: ob?.associateDescription || '',
          status: ''
        });
      });
    }
  }
  let descendantAll = [];
  let descendantPrimary = [];
  let descendantSecondary = [];
  if (informationFramework && informationFramework.associateDescendant) {
    if (
      informationFramework.associateDescendant.associateDescendantAll &&
      informationFramework.associateDescendant.associateDescendantAll.length > 0
    ) {
      informationFramework.associateDescendant.associateDescendantAll.forEach((ob) => {
        descendantAll.push({
          id: ob?.id + '-',
          textOne: ob?.associateName || '',
          textTwo: ob?.associateDescription || '',
          status: ''
        });
      });
    }
    if (
      informationFramework.associateDescendant.associateDescendantPrimary &&
      typeof informationFramework.associateDescendant.associateDescendantPrimary !== 'string' &&
      informationFramework.associateDescendant.associateDescendantPrimary.length > 0
    ) {
      informationFramework.associateDescendant.associateDescendantPrimary.forEach((ob) => {
        descendantPrimary.push({
          id: ob?.id + '-',
          textOne: ob?.associateName || '',
          textTwo: ob?.associateDescription || '',
          status: ''
        });
      });
    }
    if (
      informationFramework.associateDescendant.associateDescendantSecondary &&
      informationFramework.associateDescendant.associateDescendantSecondary.length > 0
    ) {
      informationFramework.associateDescendant.associateDescendantSecondary.forEach((ob) => {
        descendantSecondary.push({
          id: ob?.id + '-',
          textOne: ob?.associateName || '',
          textTwo: ob?.associateDescription || '',
          status: ''
        });
      });
    }
  }
  const list3 = [
    {
      id: 'a1',
      labelTextOneOne: 'associates',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'ascendant',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'all',
              innerList: ascendantAll.sort((a, b) =>
                a.textOne?.toLowerCase() !== b.textOne?.toLowerCase()
                  ? a.textOne?.toLowerCase() < b.textOne?.toLowerCase()
                    ? -1
                    : 1
                  : 0
              )
            },
            {
              labelTextTwoBadge: 'primary',
              innerList: ascendantPrimary.sort((a, b) =>
                a.textOne?.toLowerCase() !== b.textOne?.toLowerCase()
                  ? a.textOne?.toLowerCase() < b.textOne?.toLowerCase()
                    ? -1
                    : 1
                  : 0
              )
            },
            {
              labelTextTwoBadge: 'secondary',
              innerList: ascendantSecondary.sort((a, b) =>
                a.textOne?.toLowerCase() !== b.textOne?.toLowerCase()
                  ? a.textOne?.toLowerCase() < b.textOne?.toLowerCase()
                    ? -1
                    : 1
                  : 0
              )
            }
          ]
        },
        {
          labelTextOneOneBadge: 'descendant',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'all',
              innerList: descendantAll.sort((a, b) =>
                a.textOne?.toLowerCase() !== b.textOne?.toLowerCase()
                  ? a.textOne?.toLowerCase() < b.textOne?.toLowerCase()
                    ? -1
                    : 1
                  : 0
              )
            },
            {
              labelTextTwoBadge: 'primary',
              innerList: descendantPrimary.sort((a, b) =>
                a.textOne?.toLowerCase() !== b.textOne?.toLowerCase()
                  ? a.textOne?.toLowerCase() < b.textOne?.toLowerCase()
                    ? -1
                    : 1
                  : 0
              )
            },
            {
              labelTextTwoBadge: 'secondary',
              innerList: descendantSecondary.sort((a, b) =>
                a.textOne?.toLowerCase() !== b.textOne?.toLowerCase()
                  ? a.textOne?.toLowerCase() < b.textOne?.toLowerCase()
                    ? -1
                    : 1
                  : 0
              )
            }
          ]
        }
      ],
      innerInfo: 'No Information',
      isListCard: true,
      isReviewLink: false,
      isMultiList: true
    },
    {
      id: 'AS-001',
      labelTextOneOne: 'associates',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'assistant',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'primary',
              innerList: ascendantPrimary
            },
            {
              labelTextTwoBadge: 'secondary',
              innerList: ascendantSecondary
            }
          ]
        },
        {
          labelTextOneOneBadge: 'collaborator',
          innerLabelBadgeList: [
            {
              labelTextTwoBadge: 'primary',
              innerList: descendantPrimary
            },
            {
              labelTextTwoBadge: 'secondary',
              innerList: descendantSecondary
            }
          ]
        }
      ],
      innerInfo: 'No Information',
      isListCard: true,
      isReviewLink: false,
      isMultiList: true
    }
  ];

  const reviseContact = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    console.log('=====>', labelName, selectedBadgeName);
    if (labelName === 'website address') {
      if (selectedBadgeName === 'primary') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: { isPopUpValue: 'WEBSITEADDRESSPOPUP', popupMode: 'ASSOCIATE_CREATE' }
        });
      }
      if (selectedBadgeName === 'secondary') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: { isPopUpValue: 'WEBSITEADDRESSSECONDARYPOPUP', popupMode: 'ASSOCIATE_CREATE' }
        });
      }
    }
    if (labelName === 'work address') {
      if (selectedBadgeName === 'primary') {
        dispatch({
          type: SET_SINGLE_ASSOCIATE_INFORMATION,
          payload: { stateName: 'tempAssociateCommunication', value: 'work address primary' }
        });
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: { isPopUpValue: 'WORKADDRESSPOPUP', popupMode: 'ASSOCIATE_CREATE' }
        });
      }
      if (selectedBadgeName === 'secondary') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: { isPopUpValue: 'WORKADDRESSSECONDARYPOPUP', popupMode: 'ASSOCIATE_CREATE' }
        });
      }
    }
    if (labelName === 'work telephone') {
      dispatch({
        type: SET_SINGLE_ASSOCIATE_INFORMATION,
        payload: { stateName: 'tempAssociateTeleCommunication', value: 'work telephone primary' }
      });
      if (selectedBadgeName === 'primary') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: { isPopUpValue: 'WORKTELEPHONE', popupMode: 'ASSOCIATE_CREATE' }
        });
      }
      if (selectedBadgeName === 'secondary') {
        dispatch({
          type: ASSOCIATE_SIGN_ON,
          payload: { isPopUpValue: 'WORKTELEPHONESECONDARY', popupMode: 'ASSOCIATE_CREATE' }
        });
      }
    }
  };

  const reviseCredential = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    console.log('=====>', labelName);
    if (labelName === 'tag') {
      dispatch({
        type: ASSOCIATE_SIGN_ON,
        payload: { isPopUpValue: 'TAGSTATUTORYPOPUP', popupMode: 'ASSOCIATE_CREATE' }
      });
    }
  };
  const reviseFramework = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    const innerSelectedBadgeName = e.currentTarget.getAttribute('id');
    console.log(labelName, '+++++', selectedBadgeName, '+++++', innerSelectedBadgeName);
    if (
      labelName === 'associates' &&
      selectedBadgeName === 'ascendant' &&
      innerSelectedBadgeName === 'primary'
    ) {
      dispatch({ type: LOADER_START });
      let requestObj = makeAssociateDistinctShareObj(selectedAssociateInfo);
      dispatch({ type: SET_CORE_NODE_REVIEW_LIST_REQ_OBJECT, payload: requestObj });
      dispatch({
        type: GET_ASSOCIATES_NODE_REVIEW_LIST_SAGA,
        payload: { request: requestObj, nodeViewState: 'list', isMiddlePaneList: false, isAllAssociates: true }
      });
      dispatch({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: 'ASSOCIATESPARENTLISTPOPUP', popupMode: 'ASSOCIATE_CREATE' }
      });
    }
  };

  return (
    <div>
      {headerOneBadgeThree === 'all' ? (
        <>
          <div className="containerPadding">
            <AllocationAccordian
              headerOne="contact"
              isDisplayCardExpanded={listExpand === 'contact'}
              setListExpand={setListExpand}
              list={list1}
              mode={reviewMode}
              onClickRevise={reviseContact}
            />
          </div>
          <div className="containerPadding">
            <AllocationAccordian
              headerOne="credential"
              isDisplayCardExpanded={listExpand === 'credential'}
              setListExpand={setListExpand}
              list={list2}
              mode={reviewMode}
              onClickRevise={reviseCredential}
            />
          </div>
          <div className="containerPadding">
            <AllocationAccordian
              headerOne="framework"
              isDisplayCardExpanded={listExpand === 'framework'}
              setListExpand={setListExpand}
              list={list3}
              mode={reviewMode}
              onClickRevise={reviseFramework}
            />
          </div>
          {/* <div className='containerPadding' style={{ height: '50px' }}>
          </div> */}
        </>
      ) : (
        <>
          <div className="containerPadding">
            <Paper className={'dossierContainerTop'}>
              {list1.map((ob) => {
                return (
                  <div key={ob?.id}>
                    {ob.isListCard ? (
                      <DisplayPanelAccordianReviewListOne
                        onClickRevise={reviseContact}
                        className=""
                        accordianObject={ob}
                        mode={reviewMode}
                      />
                    ) : (
                      <DisplayPanelAccordianInformation
                        onClickRevise={reviseContact}
                        accordianObject={ob}
                        mode={reviewMode}
                      />
                    )}
                  </div>
                );
              })}
            </Paper>
          </div>
          <div className="containerPadding">
            <Paper className={'dossierContainerTop'}>
              {list2.map((ob) => {
                return (
                  <div key={ob?.id}>
                    {ob.isListCard ? (
                      <DisplayPanelAccordianReviewListOne
                        onClickRevise={reviseCredential}
                        className=""
                        accordianObject={ob}
                        mode={reviewMode}
                      />
                    ) : (
                      <DisplayPanelAccordianInformation
                        onClickRevise={reviseCredential}
                        accordianObject={ob}
                        mode={reviewMode}
                      />
                    )}
                  </div>
                );
              })}
            </Paper>
          </div>
          {/* <div className='containerPadding' style={{ height: '50px' }}>
          </div> */}
        </>
      )}
      {isMobile && (
        <div className={'containerPadding'} style={{ height: '55px' }}>
          {' '}
        </div>
      )}
    </div>
  );
};

export default DisplayPaneThreeSectionTwoAssociate;
