import {
  POPUP_CLOSE,
  POPUP_OPEN,
  ASSESSEE_SIGN_ON,
  ASSOCIATE_SIGN_ON,
  SET_NEXT_POPUP,
  PREVIOUS_POPUP,
  SET_POPUP_STATE,
  SET_GRID_COLUMN_COUNT_VALUE,
  SET_SECONDARY_OPTION_VALUE,
  SET_SECONDARY_CREATE_OPTION_VALUE,
  SET_POPUP_VALUE,
  SET_MIDDLEPANE_SECONDARY_OPTION,
  SET_MIDDLEPANE_PREVIOUS_POPUP,
  CLEAR_POPUP_INFO,
  SET_POPUP_SINGLE_STATE,
  SET_MIDDLEPANE_REVIEW_LIST_PREVIOUS_POPUP
} from '../actionType';
import CalculatorAdvancedIcon from '@material-ui/icons/KeyboardHide';
import CalculatorIcon from '@material-ui/icons/Keyboard';
import {
  ALLOCATE_POPUP,
  ARCHIVE_POPUP,
  ASSIGNMENT_DISTINCT_POPUP,
  DELETE_POPUP,
  FLAG_TRIPLE_DOT_PUPUP,
  NOTIFICATION_REPORT_POPUP,
  REVIEW_POPUP_OPTIONS,
  REVIEW_REVISE_POPUP,
  SELECT_PUPUP,
  SUSPEND_PUPUP,
  TERMINATE_PUPUP,
  SELECT_OPTION_PUPUP,
  ASSESSEE_REVIEW_REVISE_POPUP,
  GROUP_NODE_ROLE_TYPE_POPUP_OPTION,
  REVIEW_DISTINCT_POPUP_OPTION,
  CREATE_INFORMATION_POPUP,
  PUBLISH_PUPUP,
  EXCHANGE_POPUP_OPTION,
  SHARE_NEW_POPUP,
  ADMINISTRAION_POPUP,
  RESET_POPUP,
  ADMINISTER_POPUP,
  APPROVE_POPUP,
  CLUSTER_SCALE_POPUP_OPTION,
  MOVE_PUPUP,
  GROUP_POPUP_OPTION,
  TYPE_POPUP_OPTION,
  NODE_POPUP_OPTION,
  SCORE_POPUP_OPTION,
  REVIEW_LIST_FLAG_POPUP,
  ADMINISTRATOR_RESET_POPUP,
  SHARE_ASSESSMENT_ASSIGNMENT_ASSOCIATE_POPUP,
  ASSESSEE_SELF_ASSIGNMENT_DISTINCT_POPUP,
  ASSESSEE_SELF_ASSIGNMENT_ASSESSMENT_DISTINCT_POPUP,
  SHARE_POPUP_FEE_POPUP
  // RESET_POPUP
} from '../PopUpConfig';
import { getPopUpOptionList, setAssociateCardPermissionInJson } from '../Actions/GenericActions';

const initialState = {
  reportCombinations: {},
  shareFeePopupValue: 0,
  selectedSectionPopup : '',
  popupErrorMsg: '',
  isPopUpOpen: false,
  isFlaged: false,
  isPopUpValue: '',
  prevPopUpValue: '',
  popupMode: '',
  popupHeaderOne: '',
  popupOptionArr: [],
  primaryArrOprion: [],
  originPrimaryArrOprion: [],
  duplicateBadgeOne: '',
  duplicateBadgeTwo: '',
  duplicateHeaderOne: '',
  duplicateHeaderOneBadgeOne: '',
  duplicateHeaderOneBadgeTwo: '',
  popupHeaderOneBadgeOne: '',
  popupHeaderOneBadgeTwo: '',
  cardValue: 'NoCard',
  popupContentArrValue: [
    { lable: 'basic', dataValue: 'basic', Icon: CalculatorIcon },
    { lable: 'Business', dataValue: 'Business', Icon: CalculatorIcon },
    {
      lable: 'financial',
      dataValue: 'financial',
      Icon: CalculatorAdvancedIcon
    },
    {
      lable: 'scientific',
      dataValue: 'scientific',
      Icon: CalculatorAdvancedIcon
    }
  ],
  popupContentArrValueCopy: [],
  popupOpenType: '',
  previousPopupOpenType: '',
  gridColumnCountValue: 0,
  secondaryOptionCheckValue: '',
  tertiaryOptionCheckValue: '',
  forthOptionCheckValue: '',
  whichReviewList: '',
  selectedTagValue: '',
  cachedPreviousSelectedTagValue: '',
  selectedTagStatus: '',
  selectedTagGroupId: '',
  selectedTagShared: '',
  selectedTagInformationSetup: {},
  currentPopUpOption: [],
  secondaryPopUpOptions: {
    allocate: ALLOCATE_POPUP,
    archive: ARCHIVE_POPUP,
    share: SHARE_NEW_POPUP,
    shareFee: SHARE_POPUP_FEE_POPUP,
    administer: ADMINISTER_POPUP,
    reset: RESET_POPUP,
    resend: RESET_POPUP,
    administer: ADMINISTER_POPUP,
    resetAdminister: ADMINISTRATOR_RESET_POPUP,
    approve: APPROVE_POPUP,
    delete: DELETE_POPUP,
    start: DELETE_POPUP,
    resume: DELETE_POPUP,
    flag: FLAG_TRIPLE_DOT_PUPUP,
    flagKey: REVIEW_LIST_FLAG_POPUP,
    publish: PUBLISH_PUPUP,
    select: SELECT_PUPUP,
    suspend: SUSPEND_PUPUP,
    terminate: TERMINATE_PUPUP,
    review: REVIEW_REVISE_POPUP,
    reviewKey: CREATE_INFORMATION_POPUP,
    reviseKey: CREATE_INFORMATION_POPUP,
    revise: REVIEW_REVISE_POPUP,
    notifications: NOTIFICATION_REPORT_POPUP,
    reports: NOTIFICATION_REPORT_POPUP,
    analytics: NOTIFICATION_REPORT_POPUP,
    reviewDistinct: REVIEW_POPUP_OPTIONS,
    reviewDistinctKey: REVIEW_DISTINCT_POPUP_OPTION,
    reviewSelf: ASSESSEE_SELF_ASSIGNMENT_DISTINCT_POPUP,
    reviewSelfAssessment: ASSESSEE_SELF_ASSIGNMENT_ASSESSMENT_DISTINCT_POPUP,
    selection: SELECT_OPTION_PUPUP,
    create: REVIEW_REVISE_POPUP,
    assesseeCreate: ASSESSEE_REVIEW_REVISE_POPUP,
    createKey: CREATE_INFORMATION_POPUP,
    assessees: REVIEW_DISTINCT_POPUP_OPTION,
    administrators: REVIEW_DISTINCT_POPUP_OPTION,
    assistants: REVIEW_DISTINCT_POPUP_OPTION,
    managers: REVIEW_DISTINCT_POPUP_OPTION,
    assessments: REVIEW_DISTINCT_POPUP_OPTION,
    assignments: ASSIGNMENT_DISTINCT_POPUP,
    associates: REVIEW_DISTINCT_POPUP_OPTION,
    items: REVIEW_DISTINCT_POPUP_OPTION,
    cultureprofiles: REVIEW_DISTINCT_POPUP_OPTION,
    jobprofiles: REVIEW_DISTINCT_POPUP_OPTION,
    clusters: CLUSTER_SCALE_POPUP_OPTION,
    scales: CLUSTER_SCALE_POPUP_OPTION,
    sections: CLUSTER_SCALE_POPUP_OPTION,
    versions: CLUSTER_SCALE_POPUP_OPTION,
    norms: CLUSTER_SCALE_POPUP_OPTION,
    scores: SCORE_POPUP_OPTION,
    grades: CLUSTER_SCALE_POPUP_OPTION,
    move: MOVE_PUPUP,
    metrics: CLUSTER_SCALE_POPUP_OPTION,
    gauges: CLUSTER_SCALE_POPUP_OPTION,
    culturedimension: REVIEW_DISTINCT_POPUP_OPTION,
    cluster: REVIEW_DISTINCT_POPUP_OPTION,
    jobcompetencies: REVIEW_DISTINCT_POPUP_OPTION,
    jobdomains: REVIEW_DISTINCT_POPUP_OPTION,
    jobfunctions: REVIEW_DISTINCT_POPUP_OPTION,
    jobroles: REVIEW_DISTINCT_POPUP_OPTION,
    framerwork: REVIEW_DISTINCT_POPUP_OPTION
  },
  headerButtons: true,
  shareString: ''
};

const PopUpReducer = (istate = initialState, action) => {
  // if (action.type !== 'UPDATE_COUNTDOWN') {
  //   console.log(action);
  // }
  switch (action.type) {
    case POPUP_OPEN:
      return {
        ...istate,
        isPopUpOpen: true,
        isPopUpValue: action.payload
      };
    case POPUP_CLOSE:
      return {
        ...istate,
        isPopUpValue: '',
        isPopUpOpen: false,
        popupMode: '',
        popupContentArrValue: '',
        popupHeaderOne: '',
        popupHeaderOneBadgeOne: '',
        popupHeaderOneBadgeTwo: ''
      };
    case ASSESSEE_SIGN_ON:
      return {
        ...istate,
        isPopUpOpen: true,
        isPopUpValue: action.payload.isPopUpValue,
        popupMode: action.payload.popupMode,
        popupHeaderOne: action.payload.popupHeaderOne
      };
    case ASSOCIATE_SIGN_ON:
      return {
        ...istate,
        isPopUpOpen: true,
        isPopUpValue: action.payload.isPopUpValue,
        popupMode: action.payload.popupMode
      };
    case SET_POPUP_VALUE:
      return {
        ...istate,
        isPopUpOpen: true,
        isPopUpValue: action.payload.isPopUpValue,
        popupMode: action.payload.popupMode,
        popupErrorMsg: action.payload.popupErrorMsg,
        popupHeaderOne: action.payload.popupHeaderOne
      };
    case SET_NEXT_POPUP:
      return {
        ...istate,
        isPopUpValue: action.payload.isPopUpValue
      };
    case PREVIOUS_POPUP:
      return {
        ...istate,
        prevPopUpValue: action.payload.prevPopUpValue
      };
    case SET_POPUP_STATE:
      return {
        ...istate,
        isPopUpValue: action.payload.isPopUpValue,
        popupOpenType: action.payload.popupOpenType,
        popupContentArrValue: action.payload.popupContentArrValue,
        originPrimaryArrOprion:
          action.payload.originPrimaryArrOprion?.length > 0
            ? action.payload.originPrimaryArrOprion
            : action.payload.popupContentArrValue,
        primaryArrOprion: action.payload.popupContentArrValue,
        popupHeaderOne: action.payload.popupHeaderOne,
        duplicateHeaderOne: action.payload.popupHeaderOne,
        duplicateHeaderOneBadgeOne: action.payload.popupHeaderOneBadgeOne,
        duplicateHeaderOneBadgeTwo: action.payload.duplicateHeaderOneBadgeTwo,
        popupHeaderOneBadgeOne: action.payload.popupHeaderOneBadgeOne,
        duplicateBadgeOne: action.payload.popupHeaderOneBadgeOne,
        popupHeaderOneBadgeTwo: action.payload.popupHeaderOneBadgeTwo,
        duplicateBadgeTwo: action.payload.popupHeaderOneBadgeTwo,
        secondaryOptionCheckValue: action.payload.secondaryOptionCheckValue,
        tertiaryOptionCheckValue: action.payload.tertiaryOptionCheckValue,
        forthOptionCheckValue: action.payload.forthOptionCheckValue,
        popupMode: action.payload.popupMode,
        selectedTagValue: action.payload.selectedTagValue,
        selectedTagStatus: action.payload.selectedTagStatus,
        selectedTagGroupId: action.payload.selectedTagGroupId,
        selectedTagShared: action.payload.selectedTagShared,
        currentPopUpOption: action.payload.currentPopUpOption,
        selectedTagInformationSetup: action.payload.selectedTagInformationSetup,
        isFlaged: action.payload.isFlaged,
        cachedPreviousSelectedTagValue:
          action.payload.cachedPreviousSelectedTagValue || istate.cachedPreviousSelectedTagValue
      };
    case SET_GRID_COLUMN_COUNT_VALUE:
      return {
        ...istate,
        gridColumnCountValue: action.payload
      };
    case SET_SECONDARY_CREATE_OPTION_VALUE:
      return {
        ...istate,
        secondaryOptionCheckValue: action.payload
      };
    case SET_SECONDARY_OPTION_VALUE: //242-393//
      // return {
      //   ...istate,
      //   secondaryOptionCheckValue: action.payload
      // };
      if (
        action.payload.dataValue !== 'assessees' &&
        action.payload.dataValue !== 'assessments' &&
        action.payload.dataValue !== 'assignments' &&
        action.payload.dataValue !== 'associates' &&
        action.payload.dataValue !== 'interviews' &&
        action.payload.dataValue !== 'analytics' &&
        action.payload.dataValue !== 'profilers' &&
        action.payload.dataValue !== 'gauges' &&
        action.payload.dataValue !== 'assessment centres' &&
        action.payload.dataValue !== 'culture-profiles' &&
        action.payload.dataValue !== 'job-profiles' &&
        action.payload.dataValue !== 'education-profiles' &&
        action.payload.dataValue !== 'occupation-profiles' &&
        action.payload.dataValue !== 'personal-profiles' &&
        action.payload.dataValue !== 'items'
      ) {
        return {
          ...istate,
          secondaryOptionCheckValue: action.payload.dataValue,
          currentPopUpOption: REVIEW_DISTINCT_POPUP_OPTION
        };
      } else if (
        istate.popupHeaderOne === 'groups' &&
        (action.payload.dataValue === 'assessees' ||
          action.payload.dataValue === 'assessments' ||
          action.payload.dataValue === 'assignments' ||
          action.payload.dataValue === 'associates' ||
          action.payload.dataValue === 'interviews' ||
          action.payload.dataValue === 'culture-profiles' ||
          action.payload.dataValue === 'assessment centres' ||
          action.payload.dataValue === 'job-profiles' ||
          action.payload.dataValue === 'items')
      ) {
        let tempArr = setAssociateCardPermissionInJson(
          action.payload.originPrimaryArrOprion,
          action.payload.permissionObject,
          'optionPrimary',
          action.payload.dataPermission,
          action.payload.popupHeaderOne
        );
        return {
          ...istate,
          secondaryOptionCheckValue: action.payload.dataValue,
          currentPopUpOption: tempArr
        };
      } else if (
        istate.popupHeaderOne === 'types' &&
        (action.payload.dataValue === 'assessees' ||
          action.payload.dataValue === 'assessments' ||
          action.payload.dataValue === 'assignments' ||
          action.payload.dataValue === 'associates' ||
          action.payload.dataValue === 'interviews' ||
          action.payload.dataValue === 'culture-profiles' ||
          action.payload.dataValue === 'assessment centres' ||
          action.payload.dataValue === 'job-profiles' ||
          action.payload.dataValue === 'items')
      ) {
        let tempArr = setAssociateCardPermissionInJson(
          action.payload.originPrimaryArrOprion,
          action.payload.permissionObject,
          'optionPrimary',
          action.payload.dataPermission
        );
        // GROUP_TYPE_POPUP_OPTION.forEach((element) => {
        //   tempArr.push({ ...element, disabled: false });
        // });
        return {
          ...istate,
          secondaryOptionCheckValue: action.payload.dataValue,
          currentPopUpOption: tempArr
        };
      } else if (
        istate.popupHeaderOne === 'roles' &&
        (action.payload.dataValue === 'assessees' || action.payload.dataValue === 'associates')
      ) {
        let tempArr = setAssociateCardPermissionInJson(
          action.payload.originPrimaryArrOprion,
          action.payload.permissionObject,
          'optionPrimary',
          action.payload.dataPermission
        );
        return {
          ...istate,
          secondaryOptionCheckValue: action.payload.dataValue,
          currentPopUpOption: tempArr
        };
      } else if (istate.popupHeaderOne === 'nodes' && action.payload.dataValue === 'associates') {
        let tempArr = setAssociateCardPermissionInJson(
          action.payload.originPrimaryArrOprion,
          action.payload.permissionObject,
          'optionPrimary',
          action.payload.dataPermission
        );
        return {
          ...istate,
          secondaryOptionCheckValue: action.payload.dataValue,
          currentPopUpOption: tempArr
        };
      } else if (
        istate.popupHeaderOne === 'exchange' &&
        (action.payload.dataValue === 'assessees' ||
          action.payload.dataValue === 'assessments' ||
          action.payload.dataValue === 'assignments' ||
          action.payload.dataValue === 'associates' ||
          action.payload.dataValue === 'assessment centres' ||
          action.payload.dataValue === 'culture-profiles' ||
          action.payload.dataValue === 'job-profiles' ||
          action.payload.dataValue === 'interviews' ||
          action.payload.dataValue === 'items')
      ) {
        let tempArr = [];
        EXCHANGE_POPUP_OPTION.forEach((element) => {
          if (element.data === 'assessment centres') tempArr.push({ ...element, disabled: true });
          else tempArr.push({ ...element, disabled: false });
        });
        return {
          ...istate,
          secondaryOptionCheckValue: action.payload.dataValue,
          currentPopUpOption: tempArr
        };
      } else if (
        istate.popupHeaderOne === 'analytics' &&
        (action.payload.dataValue === 'culture-profiles' ||
          action.payload.dataValue === 'job-profiles' ||
          action.payload.dataValue === 'profilers' ||
          action.payload.dataValue === 'gauges' ||
          action.payload.dataValue === 'assessment centres' ||
          action.payload.dataValue === 'education-profiles' ||
          action.payload.dataValue === 'occupation-profiles' ||
          action.payload.dataValue === 'personal-profiles' ||
          action.payload.dataValue === 'items')
      ) {
        //
        // let tempArr = setAssociateCardPermissionInJson(
        //   istate.secondaryPopUpOptions[action.payload.targetValue],
        //   action.payload.permissionObject,
        //   'optionPrimary'
        // );
        let tempArr = setAssociateCardPermissionInJson(
          action.payload.originPrimaryArrOprion,
          action.payload.permissionObject,
          'optionPrimary',
          action.payload.dataPermission,
          '',
          action.payload.dataValue
        );
        return {
          ...istate,
          secondaryOptionCheckValue: action.payload.dataValue,
          currentPopUpOption: tempArr
        };
      } else {
        let tempArr = [];
        GROUP_NODE_ROLE_TYPE_POPUP_OPTION.forEach((element) => {
          tempArr.push({ ...element, disabled: false });
        });
        return {
          ...istate,
          secondaryOptionCheckValue: action.payload.dataValue,
          currentPopUpOption: tempArr
        };
      }
    case SET_MIDDLEPANE_SECONDARY_OPTION: {
      // badgeValue: dataVal,
      // keyValue: keyVal,
      console.log('keyValue', action.payload.keyValue);
      let arrVal11 =
        action.payload.keyValue === 'reviseKey' ||
        action.payload.keyValue === 'reviewKey' ||
        action.payload.keyValue === 'createKey' ||
        action.payload.keyValue === 'assesseeCreate' ||
        action.payload.keyValue === 'reviewDistinctKey' ||
        action.payload.keyValue === 'reviewSelf' ||
        action.payload.keyValue === 'reviewSelfAssessment' ||
        action.payload.keyValue === 'flag' ||
        action.payload.keyValue === 'flagKey' ||
        action.payload.keyValue === 'reviewDistinct'
          ? istate.secondaryPopUpOptions[action.payload.keyValue]
          : istate.secondaryPopUpOptions[action.payload.badgeValue.split('-').join('')];
      console.log('arrVal11', arrVal11);
      // console.log('isSelectActive', action.payload.secondaryOptionCheckValue);
      // console.log('isSelectActive', istate.secondaryOptionCheckValue);
      // console.log('isSelectActive', istate.selectionValue);
      // console.log('istate.popupOpenType', istate.popupOpenType);
      let arrVal = [];
      arrVal11?.length > 0 &&
        arrVal11.forEach((element) => {
          if (element.data === 'unselect' && istate.selectionValue === '') {
            arrVal.push({ ...element, disabled: true });
          } else if (element.data === 'select' && istate.selectionValue !== '') {
            arrVal.push({ ...element, disabled: true });
          } else {
            arrVal.push(element);
          }
        });
      // console.log('arrVal', arrVal);
      let permissionArr = [];
      // console.log('signedAssesseePermission from action.payload.permissionObject',action.payload.permissionObject);
      if (action.payload?.checkCardPermission === 'singlepopup') {
        permissionArr = getPopUpOptionList(arrVal, action.payload.permissionObject);
      } else {
        permissionArr = setAssociateCardPermissionInJson(
          arrVal,
          action.payload.permissionObject,
          'optionPrimary'
        );
      }
      console.log('permissionArr', permissionArr);
      if (istate.popupOpenType === 'primary') {
        if (
          action.payload.badgeValue === 'notifications' ||
          action.payload.badgeValue === 'administrators' ||
          action.payload.badgeValue === 'assistants' ||
          action.payload.badgeValue === 'managers' ||
          action.payload.badgeValue === 'items' ||
          action.payload.badgeValue === 'assessees' ||
          action.payload.badgeValue === 'assessments' ||
          action.payload.badgeValue === 'assignments' ||
          action.payload.badgeValue === 'associates' ||
          action.payload.badgeValue === 'culture-profiles' ||
          action.payload.badgeValue === 'job-profiles' ||
          action.payload.badgeValue === 'reports' ||
          action.payload.badgeValue === 'analytics'
        ) {
          // debugger;
          return {
            ...istate,
            isPopUpOpen: true,
            popupHeaderOne:
              action.payload.badgeValue === 'flag'
                ? istate.duplicateHeaderOne
                : action.payload.badgeValue,
            popupHeaderOneBadgeOne:
              action.payload.badgeValue === 'flag'
                ? action.payload.badgeValue
                : action.payload.keyValue === 'reviewDistinctKey'
                ? 'review'
                : action.payload.keyValue === 'reviewSelf'
                ? 'review'
                : action.payload.keyValue === 'reviewSelfAssessment'
                ? 'review'
                : action.payload.keyValue === 'reviewDistinct'
                ? 'review'
                : action.payload.keyValue,
            popupHeaderOneBadgeTwo: '',
            popupOpenType: 'secondary',
            // popupContentArrValue: arrVal,
            popupContentArrValue: permissionArr,
            primaryArrOprion:
              action.payload.isPopUpFrom === 'reviewList' ? permissionArr : istate.primaryArrOprion,
            secondaryOptionCheckValue:
              action.payload.badgeValue === 'flag'
                ? 'multiple'
                : action.payload.badgeValue === 'notifications' ||
                  action.payload.badgeValue === 'reports' ||
                  action.payload.badgeValue === 'analytics'
                ? 'unread'
                : 'active'
          };
        } else {
          if (
            (action.payload.badgeValue === 'suspend' ||
              action.payload.badgeValue === 'terminate' ||
              action.payload.badgeValue === 'publish' ||
              action.payload.badgeValue === 'archive' ||
              action.payload.badgeValue === 'flag' ||
              action.payload.badgeValue === 'flagKey' ||
              action.payload.badgeValue === 'approve') &&
            (istate.selectedTagStatus === 'CONFIRMED' ||
              istate.selectedTagStatus === 'UNCONFIRMED' ||
              // istate.selectedTagStatus === 'SHARED' ||
              // istate.selectedTagStatus === 'UNSHARED' ||
              istate.selectedTagStatus === 'UNPUBLISHED' ||
              istate.selectedTagStatus === 'PUBLISHED' ||
              istate.selectedTagStatus === 'DISAPPROVED' ||
              istate.selectedTagStatus === 'ADMINISTERED' ||
              istate.selectedTagStatus === 'ACTIVE')
          ) {
            permissionArr = [permissionArr[0], { ...permissionArr[1], disabled: true }];
          }
          if (
            //revielist
            (action.payload.badgeValue === 'suspend' && istate.selectedTagStatus === 'SUSPENDED') ||
            (action.payload.badgeValue === 'terminate' &&
              istate.selectedTagStatus === 'TERMINATED') ||
            (action.payload.badgeValue === 'archive' && istate.selectedTagStatus === 'ARCHIVED')
          ) {
            permissionArr = [{ ...permissionArr[0], disabled: true }, permissionArr[1]];
          }
          if (
            (action.payload.badgeValue === 'suspend' &&
              (istate.selectedTagStatus === 'ARCHIVED' ||
                istate.selectedTagStatus === 'STARTED' ||
                istate.selectedTagStatus === 'UNSTARTED' ||
                istate.selectedTagStatus === 'FINISHED' ||
                istate.selectedTagStatus === 'ABORTED' ||
                istate.selectedTagStatus === 'UNAPPROVED')) ||
            (action.payload.badgeValue === 'terminate' &&
              (istate.selectedTagStatus === 'ARCHIVED' ||
                istate.selectedTagStatus === 'STARTED' ||
                istate.selectedTagStatus === 'UNSTARTED' ||
                istate.selectedTagStatus === 'FINISHED' ||
                istate.selectedTagStatus === 'ABORTED' ||
                istate.selectedTagStatus === 'UNAPPROVED')) ||
            (istate.duplicateHeaderOneBadgeTwo === 'archive' &&
              action.payload.badgeValue === 'suspend') ||
            (istate.duplicateHeaderOneBadgeTwo === 'archive' &&
              action.payload.badgeValue === 'terminate')
          ) {
            permissionArr = [permissionArr[0], { ...permissionArr[1], disabled: true }];
          }
          if (
            action.payload.badgeValue === 'approve' &&
            istate.selectedTagStatus === 'DISAPPROVED'
          ) {
            permissionArr = [
              { ...permissionArr[0], disabled: false },
              { ...permissionArr[1], disabled: true }
            ];
          }

          if (
            action.payload.badgeValue === 'archive' &&
            (istate.selectedTagStatus === 'UNAPPROVED' ||
              istate.selectedTagStatus === 'SUSPENDED' ||
              istate.selectedTagStatus === 'TERMINATED' ||
              istate.selectedTagStatus === 'STARTED' ||
              istate.selectedTagStatus === 'UNSTARTED' ||
              istate.selectedTagStatus === 'FINISHED' ||
              istate.selectedTagStatus === 'ABORTED')
          ) {
            permissionArr = [
              { ...permissionArr[0], disabled: false },
              { ...permissionArr[1], disabled: true }
            ];
          }
          if (action.payload.badgeValue === 'publish' && istate.selectedTagStatus === 'PUBLISHED') {
            permissionArr = [
              { ...permissionArr[0], disabled: true },
              { ...permissionArr[1], disabled: false }
            ];
          }
          if (
            action.payload.badgeValue === 'publish' &&
            istate.selectedTagStatus === 'UNPUBLISHED'
          ) {
            permissionArr = [
              { ...permissionArr[0], disabled: false },
              { ...permissionArr[1], disabled: true }
            ];
          }
          if (
            (action.payload.badgeValue === 'suspend' &&
              istate.selectedTagStatus === 'TERMINATED') ||
            (action.payload.badgeValue === 'terminate' && istate.selectedTagStatus === 'SUSPENDED')
          ) {
            permissionArr = [permissionArr[0], { ...permissionArr[1], disabled: true }];
          }
          // if (action.payload.badgeValue === 'share' && istate.selectedTagShared) {
          //   //permissionArr = [{ ...permissionArr[0], disabled: true }, permissionArr[1]];
          // }
          if (action.payload.badgeValue === 'share' && !istate.selectedTagShared) {
            let arr = [];
            permissionArr.map((element) => {
              // if (element.data === 'unshare') {
              //   arr.push({ ...element, disabled: true });
              // } else {
              arr.push({ ...element, disabled: false });
              // }
            });
            permissionArr = arr;
            //arrVal = [arrVal[0], { ...arrVal[1], disabled: true }];
          }
          if (action.payload.keyValue === 'flagKey' && istate.isFlaged) {
            permissionArr = [{ ...permissionArr[0], disabled: true }, permissionArr[1]];
          }
          if (action.payload.keyValue === 'flagKey' && !istate.isFlaged) {
            permissionArr = [permissionArr[0], { ...permissionArr[1], disabled: true }];
          }
          if (action.payload.keyValue === 'move') {
            let arr = [];
            if (
              istate.popupHeaderOne !== 'administrator' &&
              istate.popupHeaderOne !== 'administrators' &&
              istate.popupHeaderOne !== 'assistant' &&
              istate.popupHeaderOne !== 'assistants' &&
              istate.popupHeaderOne !== 'manager' &&
              istate.popupHeaderOne !== 'managers' &&
              istate.popupHeaderOne !== 'assessee' &&
              istate.popupHeaderOne !== 'assessees' &&
              istate.popupHeaderOne !== 'associate' &&
              istate.popupHeaderOne !== 'associates'
            ) {
              permissionArr.forEach((element) => {
                if (element.data === 'roles') {
                  arr.push({ ...element, disabled: true });
                } else {
                  arr.push({ ...element, disabled: false });
                }
              });
            } else {
              console.log(permissionArr);
              arr = [...permissionArr];
            }
            console.log('----', arr);
            permissionArr = [...arr];
          }
          if (
            action.payload.keyValue === 'share' &&
            istate.popupHeaderOneBadgeOne !== ''
            // && istate.popupHeaderOneBadgeOne !== 'distinct'
          ) {
            if (action.payload.keyValue === 'share') {
              let arr = [];
              permissionArr.map((element) => {
                // console.log(element.data, element.disabled);
                if (element.data === 'node') {
                  arr.push({ ...element, disabled: true });
                } else {
                  arr.push({ ...element });
                }
              });
              permissionArr = arr;
            }
            // else if (action.payload.badgeValue === 'share' && !istate.selectedTagShared) {
            //   let arr = [];
            //   permissionArr.map((element) => {
            //     if (element.data === 'unshare' || element.data === 'node') {
            //       arr.push({ ...element, disabled: true });
            //     } else {
            //       arr.push({ ...element, disabled: false });
            //     }
            //   });
            //   permissionArr = arr;
            // }
            // let arr = [];
            // permissionArr.map((element) => {
            //   if (element.data === 'node') {
            //     arr.push({ ...element, disabled: true });
            //   } else {
            //     arr.push({ ...element, disabled: false });
            //   }
            // });
            // permissionArr = arr;
          }
          if (action.payload.keyValue === 'allocate') {
            let arr = [];
            if (
              istate.popupHeaderOne === 'assessee' ||
              istate.popupHeaderOne === 'assessees' ||
              istate.popupHeaderOne === 'assistant' ||
              istate.popupHeaderOne === 'assistants' ||
              istate.popupHeaderOne === 'managers' ||
              istate.popupHeaderOne === 'manager' ||
              istate.popupHeaderOne === 'administrators'
            ) {
              console.log('permissionArr---------', permissionArr);
              permissionArr.map((element) => {
                if (
                  // element.data === 'assessees' ||
                  element.data === 'assessments' ||
                  // element.data === 'assignments' ||
                  element.data === 'associates' ||
                  element.data === 'culture-profiles' ||
                  element.data === 'job-profiles' ||
                  element.data === 'items'
                ) {
                  console.log(element.data, 'is disable');
                  arr.push({ ...element, disabled: true });
                } else {
                  arr.push({ ...element, disabled: false });
                }
              });
              console.log('arr---------', arr);
              permissionArr = [...arr];
            }
            if (istate.popupHeaderOne === 'assessment' || istate.popupHeaderOne === 'assessments') {
              if (action.payload.middlePaneHeaderBadgeOne !== 'distinct') {
                permissionArr.map((element) => {
                  if (
                    element.data === 'assessees' ||
                    // element.data === 'assessments' ||
                    // element.data === 'assignments' ||
                    element.data === 'associates' ||
                    element.data === 'culture-profiles' ||
                    element.data === 'job-profiles' ||
                    element.data === 'items' ||
                    element.data === 'roles'
                  ) {
                    arr.push({ ...element, disabled: true });
                  } else {
                    arr.push({ ...element, disabled: false });
                  }
                });
                permissionArr = [...arr];
              } else if (
                action.payload.middlePaneHeaderBadgeOne === 'distinct' &&
                action.payload.middlePaneHeaderBadgeTwo === 'inactive'
              ) {
                permissionArr.map((element) => {
                  if (
                    element.data === 'assessees' ||
                    // element.data === 'assessments' ||
                    element.data === 'assignments' ||
                    element.data === 'associates' ||
                    element.data === 'culture-profiles' ||
                    element.data === 'job-profiles' ||
                    element.data === 'roles'
                    // element.data === 'items'
                  ) {
                    arr.push({ ...element, disabled: true });
                  } else {
                    arr.push({ ...element, disabled: false });
                  }
                });
                permissionArr = [...arr];
              } else if (
                action.payload.middlePaneHeaderBadgeOne === 'distinct' &&
                action.payload.middlePaneHeaderBadgeTwo === 'active'
              ) {
                permissionArr.map((element) => {
                  if (
                    element.data === 'assessees' ||
                    // element.data === 'assessments' ||
                    // element.data === 'assignments' ||
                    element.data === 'associates' ||
                    element.data === 'culture-profiles' ||
                    element.data === 'job-profiles' ||
                    element.data === 'roles' ||
                    element.data === 'items'
                  ) {
                    arr.push({ ...element, disabled: true });
                  } else {
                    arr.push({ ...element, disabled: false });
                  }
                });
                permissionArr = [...arr];
              } else if (
                action.payload.middlePaneHeaderBadgeOne === 'distinct' &&
                action.payload.middlePaneHeaderBadgeTwo === 'all'
              ) {
                permissionArr.map((element) => {
                  if (
                    element.data === 'assessees' ||
                    // element.data === 'assessments' ||
                    // element.data === 'assignments' ||
                    element.data === 'associates' ||
                    element.data === 'culture-profiles' ||
                    element.data === 'job-profiles' ||
                    element.data === 'roles'
                    // element.data === 'items'
                  ) {
                    arr.push({ ...element, disabled: true });
                  } else {
                    arr.push({ ...element, disabled: false });
                  }
                });
                permissionArr = [...arr];
              }
            }
            if (istate.popupHeaderOne === 'assignment' || istate.popupHeaderOne === 'assignments') {
              permissionArr.map((element) => {
                if (
                  element.data === 'assessees' ||
                  element.data === 'assessments' ||
                  // element.data === 'assignments' ||
                  element.data === 'associates' ||
                  element.data === 'culture-profiles' ||
                  element.data === 'job-profiles' ||
                  element.data === 'items' ||
                  element.data === 'roles'
                ) {
                  arr.push({ ...element, disabled: true });
                } else {
                  arr.push({ ...element, disabled: false });
                }
              });
              permissionArr = [...arr];
            }
            if (istate.popupHeaderOne === 'associate' || istate.popupHeaderOne === 'associates') {
              permissionArr.map((element) => {
                if (
                  element.data === 'assessees' ||
                  element.data === 'assessments' ||
                  element.data === 'assignments' ||
                  // element.data === 'associates' ||
                  element.data === 'culture-profiles' ||
                  element.data === 'job-profiles' ||
                  // element.data === 'roles' ||
                  element.data === 'items'
                ) {
                  arr.push({ ...element, disabled: true });
                } else {
                  arr.push({ ...element, disabled: false });
                }
              });
              permissionArr = [...arr];
            }
            if (istate.popupHeaderOne === 'item' || istate.popupHeaderOne === 'items') {
              permissionArr.map((element) => {
                if (
                  element.data === 'assessees' ||
                  // element.data === 'assessments' ||
                  element.data === 'assignments' ||
                  element.data === 'associates' ||
                  element.data === 'culture-profiles' ||
                  element.data === 'job-profiles' ||
                  // element.data === 'items' ||
                  element.data === 'roles'
                ) {
                  arr.push({ ...element, disabled: true });
                } else {
                  arr.push({ ...element, disabled: false });
                }
              });
              permissionArr = [...arr];
            }
            if (
              istate.popupHeaderOne === 'culture-profile' ||
              istate.popupHeaderOne === 'culture-profiles'
            ) {
              if (
                action.payload.middlePaneHeaderBadgeOne === 'distinct' &&
                action.payload.middlePaneHeaderBadgeTwo === 'inactive'
              ) {
                permissionArr.map((element) => {
                  if (
                    element.data === 'assessees' ||
                    element.data === 'assessments' ||
                    element.data === 'assignments' ||
                    element.data === 'associates' ||
                    // element.data === 'culture-profiles' ||
                    element.data === 'job-profiles' ||
                    element.data === 'items' ||
                    element.data === 'roles'
                  ) {
                    arr.push({ ...element, disabled: true });
                  } else {
                    arr.push({ ...element, disabled: false });
                  }
                });
                permissionArr = [...arr];
              } else
              permissionArr.map((element) => {
                if (
                  element.data === 'assessees' ||
                  element.data === 'assessments' ||
                  // element.data === 'assignments' ||
                  element.data === 'associates' ||
                  // element.data === 'culture-profiles' ||
                  element.data === 'job-profiles' ||
                  element.data === 'items' ||
                  element.data === 'roles'
                ) {
                  arr.push({ ...element, disabled: true });
                } else {
                  arr.push({ ...element, disabled: false });
                }
              });
              permissionArr = [...arr];
            }
            if (
              istate.popupHeaderOne === 'job-profile' ||
              istate.popupHeaderOne === 'job-profiles'
            ) {if (
              action.payload.middlePaneHeaderBadgeOne === 'distinct' &&
              action.payload.middlePaneHeaderBadgeTwo === 'inactive'
            ) {
              permissionArr.map((element) => {
                if (
                  element.data === 'assessees' ||
                  element.data === 'assessments' ||
                  element.data === 'assignments' ||
                  element.data === 'associates' ||
                  element.data === 'culture-profiles' ||
                  // element.data === 'job-profiles' ||
                  element.data === 'items' ||
                  element.data === 'roles'
                ) {
                  arr.push({ ...element, disabled: true });
                } else {
                  arr.push({ ...element, disabled: false });
                }
              });
              permissionArr = [...arr];} else
              permissionArr.map((element) => {
                if (
                  element.data === 'assessees' ||
                  element.data === 'assessments' ||
                  // element.data === 'assignments' ||
                  element.data === 'associates' ||
                  element.data === 'culture-profiles' ||
                  // element.data === 'job-profiles' ||
                  element.data === 'items' ||
                  element.data === 'roles'
                ) {
                  arr.push({ ...element, disabled: true });
                } else {
                  arr.push({ ...element, disabled: false });
                }
              });
              permissionArr = [...arr];
            }
          }
          let tempArr = [];
          if (
            (action.payload.badgeValue === 'clusters' ||
              action.payload.badgeValue === 'grades' ||
              action.payload.badgeValue === 'scales' ||
              action.payload.badgeValue === 'scores' ||
              action.payload.badgeValue === 'gauges' ||
              action.payload.badgeValue === 'metrics' ||
              action.payload.badgeValue === 'sections' ||
              action.payload.badgeValue === 'norms' ||
              action.payload.badgeValue === 'versions') &&
            istate.selectedTagStatus === 'PUBLISHED'
          ) {
            permissionArr.map((ele) => {
              if (ele.dataValue === 'create') {
                tempArr.push({ ...ele, disabled: true });
              } else {
                tempArr.push(ele);
              }
            });
            permissionArr = tempArr;
          }
          if (
            istate.duplicateHeaderOneBadgeTwo === 'archived' &&
            action.payload.badgeValue === 'archive'
          ) {
            permissionArr = [{ ...permissionArr[0], disabled: true }, permissionArr[1]];
          }
          if (
            istate.duplicateHeaderOneBadgeTwo === 'active' &&
            (action.payload.badgeValue === 'archive' ||
              action.payload.badgeValue === 'suspend' ||
              action.payload.badgeValue === 'terminate')
          ) {
            permissionArr = [permissionArr[0], { ...permissionArr[1], disabled: true }];
          }
          if (
            istate.duplicateHeaderOneBadgeTwo === 'inactive' &&
            action.payload.badgeValue === 'archive'
          ) {
            permissionArr = [permissionArr[0], { ...permissionArr[1], disabled: true }];
          }
          // if (
          //   (action.payload.badgeValue === 'suspend' ||
          //     action.payload.badgeValue === 'terminate') &&
          //   (istate.selectedTagStatus === 'TERMINATED' || istate.selectedTagStatus === 'SUSPENDED')
          // )
          //  {
          //   permissionArr = [{ ...permissionArr[0], disabled: true }, permissionArr[1]];
          // }
          // permissionArr = [...arrVal];
          // debugger;
          return {
            ...istate,
            popupHeaderOne:
              action.payload.keyValue === 'clusters' ||
              action.payload.keyValue === 'grades' ||
              action.payload.badgeValue === 'items' ||
              action.payload.keyValue === 'norms' ||
              action.payload.keyValue === 'scales' ||
              action.payload.keyValue === 'scores' ||
              action.payload.keyValue === 'sections' ||
              action.payload.badgeValue === 'metrics' ||
              action.payload.badgeValue === 'framerwork' ||
              action.payload.keyValue === 'versions'
                ? istate.popupHeaderOne
                : action.payload.keyValue === 'metrics'
                ? action.payload.keyValue
                : action.payload.keyValue === 'gauges'
                ? action.payload.keyValue
                : action.payload.keyValue === 'culturedimension'
                ? 'culture-dimensions'
                : action.payload.keyValue === 'cluster'
                ? 'clusters'
                : action.payload.keyValue === 'framerwork'
                ? 'framerwork'
                : action.payload.keyValue === 'jobcompetency'
                ? 'job-competencies'
                : action.payload.keyValue === 'jobdomain'
                ? 'job-domains'
                : action.payload.keyValue === 'jobfunctions'
                ? 'job-functions'
                : action.payload.keyValue === 'jobroles'
                ? 'job-roles'
                : istate.popupHeaderOne,
            isPopUpOpen: true,
            popupHeaderOneBadgeOne:
              action.payload.keyValue === 'clusters' ||
              action.payload.keyValue === 'grades' ||
              action.payload.keyValue === 'norms' ||
              action.payload.keyValue === 'scales' ||
              action.payload.keyValue === 'scores' ||
              action.payload.keyValue === 'sections' ||
              action.payload.keyValue === 'versions'
                ? action.payload.keyValue
                : action.payload.badgeValue === 'items' ||
                  action.payload.badgeValue === 'metrics' ||
                  action.payload.badgeValue === 'framerwork'
                ? action.payload.badgeValue
                : action.payload.badgeValue === 'gauges'
                ? ''
                : istate.popupHeaderOneBadgeOne,
            popupHeaderOneBadgeTwo:
              action.payload.keyValue === 'clusters'
                ? ''
                : action.payload.keyValue === 'sections'
                ? ''
                : action.payload.keyValue === 'scales'
                ? ''
                : action.payload.keyValue === 'versions'
                ? ''
                : action.payload.keyValue === 'norms'
                ? ''
                : action.payload.keyValue === 'scores'
                ? ''
                : action.payload.keyValue === 'grades'
                ? ''
                : action.payload.keyValue === 'metrics'
                ? ''
                : action.payload.keyValue === 'gauges'
                ? ''
                : action.payload.keyValue === 'reviewDistinct' &&
                  action.payload.badgeValue !== 'review'
                ? ''
                : action.payload.keyValue === 'culturedimension'
                ? ''
                : action.payload.keyValue === 'cluster'
                ? ''
                : action.payload.keyValue === 'jobcompetencies'
                ? ''
                : action.payload.keyValue === 'jobdomains'
                ? ''
                : action.payload.keyValue === 'jobfunctions'
                ? ''
                : action.payload.keyValue === 'jobroles'
                ? ''
                : action.payload.keyValue === 'framerwork'
                ? ''
                : action.payload.badgeValue === 'metrics' ||
                  action.payload.badgeValue === 'framerwork'
                ? ''
                : // : action.payload.keyValue === 'flag'
                // ? ''
                action.payload.keyValue === 'flagKey'
                ? 'flag'
                : action.payload.badgeValue === 'resetAdminister'
                ? 'reset'
                : action.payload.badgeValue === 'share'
                ? 'share'
                : action.payload.badgeValue === 'shareFee'
                ? 'share'
                : action.payload.badgeValue,
            popupOpenType: 'secondary',
            popupContentArrValue: permissionArr,
            // popupContentArrValue: arrVal,
            primaryArrOprion:
              action.payload.isPopUpFrom === 'reviewList'
                ? istate.originPrimaryArrOprion
                : istate.primaryArrOprion,
            popupContentArrValueCopy: permissionArr,
            previousPopupOpenType: istate.popupOpenType,
            tertiaryOptionCheckValue: action.payload.keyValue === 'share' ? 'primary' : '',
            forthOptionCheckValue: action.payload.keyValue === 'share' ? 'ascendant' : '',
            secondaryOptionCheckValue:
              action.payload.keyValue === 'reviseKey' ||
              action.payload.keyValue === 'reviewKey' ||
              action.payload.keyValue === 'createKey'
                ? 'key'
                : action.payload.keyValue === 'reviewDistinct' ||
                  action.payload.keyValue === 'reviewSelf' ||
                  action.payload.keyValue === 'reviewSelfAssessment' ||
                  action.payload.keyValue === 'reviewDistinctKey'
                ? 'active'
                : action.payload.keyValue === 'select'
                ? 'multiple'
                : action.payload.keyValue === 'flag'
                ? 'multiple'
                : action.payload.keyValue === 'share' &&
                  istate.popupHeaderOneBadgeOne === 'distinct'
                ? 'associate'
                : action.payload.keyValue === 'share' &&
                  istate.popupHeaderOneBadgeOne !== 'distinct'
                ? 'associate'
                : 'all'
          };
        }
      } else if (istate.popupOpenType === 'secondary') {
        console.log('arrVal--', arrVal);
        // debugger;
        return {
          ...istate,
          popupHeaderOne:
            istate.popupHeaderOneBadgeTwo === 'clusters'
              ? 'clusters'
              : istate.popupHeaderOneBadgeTwo === 'scales'
              ? 'scales'
              : istate.popupHeaderOneBadgeTwo === 'sections'
              ? 'sections'
              : istate.popupHeaderOneBadgeTwo === 'versions'
              ? 'versions'
              : istate.popupHeaderOneBadgeTwo === 'norms'
              ? 'norms'
              : istate.popupHeaderOneBadgeTwo === 'scores'
              ? 'scores'
              : istate.popupHeaderOneBadgeTwo === 'grades'
              ? 'grades'
              : istate.popupHeaderOneBadgeTwo === 'metrics'
              ? 'metrics'
              : istate.popupHeaderOneBadgeTwo === 'gauges'
              ? 'gauges'
              : action.payload.badgeValue === 'reviewDistinctKey'
              ? action.payload.badgeValue
              : istate.popupHeaderOne,
          isPopUpOpen: true,
          popupHeaderOneBadgeOne: istate.popupHeaderOneBadgeOne,
          duplicateBadgeOne: istate.popupHeaderOneBadgeOne,
          popupHeaderOneBadgeTwo: action.payload.badgeValue,
          duplicateBadgeTwo: action.payload.badgeValue,
          popupOpenType: 'secondary',
          popupContentArrValue: arrVal,
          primaryArrOprion: istate.popupContentArrValueCopy, // crete/ revie
          previousPopupOpenType: istate.popupOpenType,
          tertiaryOptionCheckValue: action.payload.keyValue === 'share' ? 'primary' : '',
          forthOptionCheckValue: action.payload.keyValue === 'share' ? 'ascendant' : '',
          secondaryOptionCheckValue:
            action.payload.keyValue === 'reviseKey' ||
            action.payload.keyValue === 'reviewKey' ||
            action.payload.keyValue === 'createKey'
              ? 'key'
              : action.payload.keyValue === 'reviewDistinct' ||
                action.payload.keyValue === 'reviewSelf' ||
                action.payload.keyValue === 'reviewSelfAssessment' ||
                action.payload.keyValue === 'reviewDistinctKey'
              ? 'active'
              : action.payload.keyValue === 'flag' || action.payload.keyValue === 'unflag'
              ? 'all'
              : action.payload.keyValue === 'select'
              ? 'multiple'
              : action.payload.keyValue === 'share'
              ? 'associate'
              : 'all'
        };
      } else {
        return istate;
      }
    }
    case SET_MIDDLEPANE_REVIEW_LIST_PREVIOUS_POPUP:
      if (istate.popupOpenType === 'primary') {
        return {
          ...istate,
          isPopUpValue: '',
          isPopUpOpen: false,
          popupContentArrValue: []
        };
      } else if (istate.previousPopupOpenType === 'secondary') {
        console.log('popupArr----', istate.popupContentArrValueCopy);
        return {
          ...istate,
          // shareFeePopupStatus: istate.shareFeePopupStatus === 'active' && 'prevactive' ,
          popupContentArrValue: istate.popupContentArrValueCopy,
          popupHeaderOne: istate.duplicateHeaderOne,
          popupHeaderOneBadgeOne: istate.duplicateBadgeOne,
          popupHeaderOneBadgeTwo: istate.duplicateBadgeTwo,
          tertiaryOptionCheckValue: istate.popupHeaderOneBadgeTwo === 'share' ? 'primary' : 'all',
          forthOptionCheckValue: 'ascendant',
          previousPopupOpenType: 'primary',
          popupOpenType: 'secondary'
        };
      } else if (istate.popupOpenType === 'secondary') {
        console.log('popupArr----', istate.originPrimaryArrOprion);
        return {
          ...istate,
          popupContentArrValue: istate.originPrimaryArrOprion,
          popupHeaderOne: istate.duplicateHeaderOne,
          popupHeaderOneBadgeOne: istate.duplicateBadgeOne,
          tertiaryOptionCheckValue: 'all',
          forthOptionCheckValue: 'ascendant',
          popupHeaderOneBadgeTwo: '',
          popupOpenType: 'primary'
        };
      } else {
        return istate;
      }
    case SET_MIDDLEPANE_PREVIOUS_POPUP:
      if (istate.popupOpenType === 'primary') {
        return {
          ...istate,
          isPopUpValue: '',
          isPopUpOpen: false,
          popupContentArrValue: []
        };
      } else if (istate.popupOpenType === 'secondary') {
        return {
          ...istate,
          popupContentArrValue: istate.primaryArrOprion,
          popupHeaderOne: istate.duplicateHeaderOne,
          popupHeaderOneBadgeOne: istate.duplicateBadgeOne,
          tertiaryOptionCheckValue: 'all',
          forthOptionCheckValue: 'ascendant',
          popupHeaderOneBadgeTwo: '',
          popupOpenType: 'primary'
        };
      } else {
        return istate;
      }
    case SET_POPUP_SINGLE_STATE:
      return {
        ...istate,
        [action.payload.stateName]: action.payload.value
      };
    case CLEAR_POPUP_INFO:
      return initialState;
    default:
      return istate;
  }
};

export default PopUpReducer;
