import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CLEAR_DISPLAY_PANE_THREE,
  FILTERMODE,
  POPUP_OPEN,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MIDDLEPANE_STATE,
  SET_POPUP_STATE
} from '../actionType';

import ReviewList from '../Molecules/ReviewList/ReviewList';
import { PROFILER_REVIEW_LIST_POPUP_OPTION } from '../PopUpConfig';
import Card from '../Molecules/Card/Card';
import CrossIcon from '@material-ui/icons/Clear';
import { closeRelatedList, getPopUpOptionList } from '../Actions/GenericActions';
import { onClickCheckBoxSelection } from '../Actions/AssesseeModuleAction';

const JobFunctionDistinctReviewList = (props) => {
  const dispatch = useDispatch();
  const {
    reviewListDistinctData,
    relatedReviewListDistinctData,
    selectedTagsArray,
    unselectedTagsArray,
    middlePaneHeaderDuplicate,
    middlePaneHeaderDuplicateBadgeOne,
    middlePaneHeaderDuplicateBadgeTwo,
    middlePaneHeaderDuplicateBadgeThree,
    middlePaneHeaderDuplicateBadgeFour,
    duplicateScanCount,
    isSelectActive,
    signedAssesseePermission,
    middlePaneHeaderDuplicateOne,
    middlePaneHeaderDuplicateOneBadgeOne,
    middlePaneHeaderDuplicateOneBadgeTwo,
    middlePaneHeaderDuplicateOneBadgeThree,
    middlePaneHeaderDuplicateOneBadgeFour,
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { createMode, responseObject } = useSelector((state) => state.DisplayPaneThreeReducer);
  const closeRelated = () => {
    closeRelatedList(
      dispatch,
      createMode,
      responseObject,
      middlePaneHeaderDuplicate,
      middlePaneHeaderDuplicateBadgeOne,
      'active',
      middlePaneHeaderDuplicateBadgeThree,
      middlePaneHeaderDuplicateBadgeFour,
      'profilerDistinctReviewList',
      reviewListDistinctData.length,
      'profilerDistinct',
      duplicateScanCount,
      middlePaneHeaderDuplicateOne,
      middlePaneHeaderDuplicateOneBadgeOne,
      middlePaneHeaderDuplicateOneBadgeTwo,
      middlePaneHeaderDuplicateOneBadgeThree,
      middlePaneHeaderDuplicateOneBadgeFour,
    );
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  };
  const listDistinctData = relatedReviewListDistinctData[0];

  const openListPopup = (e) => {
    console.log(e.currentTarget.getAttribute('tag'));
    let stats = e.currentTarget.getAttribute('status');
    let cultureProfileDistictPermssion =
      signedAssesseePermission?.iGuruAnalyticDistinctPermission
        ?.assesseeiGuruAnalyticDistinctPermission;
    let popupContentArr = getPopUpOptionList(PROFILER_REVIEW_LIST_POPUP_OPTION, cultureProfileDistictPermssion);

    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: 'job-function',
        popupHeaderOneBadgeOne: '',
        isPopUpValue: '',
        popupOpenType: 'primary',
        popupContentArrValue: popupContentArr,
        selectedTagValue: e.currentTarget.getAttribute('tag'),
        selectedTagStatus: stats
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: popupContentArr
      }
    });
  };
  const closeRelatedListTwo = () => {
    let listingData = relatedReviewListDistinctData[0];
    let reviseResponseObj = {
      responseObject: [
        {
          profilerName: listingData.profilerName,
          profilerDescription: listingData.profilerDescription,
          jobProfilerList: listingData.jobProfilerList
        }
      ]
    };
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'relatedReviewListDistinctData',
        value: reviseResponseObj.responseObject
      }
    });
    closeRelatedList(
      dispatch,
      createMode,
      responseObject,
      middlePaneHeaderDuplicate,
      middlePaneHeaderDuplicateBadgeOne,
      middlePaneHeaderDuplicateBadgeTwo,
      middlePaneHeaderDuplicateBadgeThree,
      middlePaneHeaderDuplicateBadgeFour,
      'jobProfilerDistinctReviewList',
      4,
      '',
      2,
      middlePaneHeaderDuplicateOne,
      middlePaneHeaderDuplicateOneBadgeOne,
      middlePaneHeaderDuplicateOneBadgeTwo,
      middlePaneHeaderDuplicateOneBadgeThree,
      middlePaneHeaderDuplicateOneBadgeFour,
    );
    dispatch({
      type: FILTERMODE,
      payload: {
        FilterMode: 'iGaugeOccupationJobProfiler'
      }
    });
    dispatch({ type: CLEAR_DISPLAY_PANE_THREE });
  };
  console.log('listDistinctData', listDistinctData);
  return (
    <div>
      {listDistinctData && (
        <React.Fragment>
          <Card
            textOneOne={listDistinctData?.profilerName}
            textTwoOne={listDistinctData?.profilerDescription}
            IconOne={CrossIcon}
            isIcon={true}
            labelTwoTwo={'distinct'}
            onClickIconOne={closeRelated}
            isAlliance
            relatedCardFixed={true}
            className={'iguru-iconboxSVG'}
          />
          <Card
            textOneOne={listDistinctData?.textOne}
            textTwoOne={listDistinctData?.textTwo}
            IconOne={CrossIcon}
            isIcon={true}
            labelTwoTwo={'distinct'}
            onClickIconOne={closeRelatedListTwo}
            isAlliance
            relatedCardFixed={true}
            className={'iguru-iconboxSVG'}
          />
        </React.Fragment>
      )}
      {listDistinctData &&
        listDistinctData?.jobFunction.map((item, index) => {
          return (
            <div className="containerPadding" key={index}>
              <ReviewList
                className=""
                id={index}
                tag={index}
                status={''}
                actualStatus={''}
                textOne={item?.textOne || item?.iGaugeOccupationFrameworkOneClusterName || ''}
                textTwo={
                  item?.textTwo || item?.iGaugeOccupationFrameworkOneClusterDescription || ''
                }
                isTooltipActive={false}
                onClickEvent={openListPopup}
                isSelectActive={isSelectActive}
                isSelected={selectedTagsArray.includes(index.toString())}
                onClickCheckBox={(event) => {
                  onClickCheckBoxSelection(selectedTagsArray, unselectedTagsArray, event, dispatch);
                }}
              />
            </div>
          );
        })}
    </div>
  );
};
export default JobFunctionDistinctReviewList;
