import { put, takeLatest, call, takeEvery } from 'redux-saga/effects';
import {
  LOADER_STOP,
  REVIEWLIST_DISTINCT_DATA,
  SET_MIDDLEPANE_STATE,
  ASSOCIATE_REVIEW_DISTINCT_SAGA,
  GET_ASSOCIATEGROUP_ASSOCIATE_REVIEW_LIST_SAGA,
  RELATED_REVIEWLIST_DISTINCT_DATA,
  GET_ASSOCIATEROLE_ASSOCIATE_REVIEW_LIST_SAGA,
  SET_POPUP_VALUE,
  SET_REVIEW_LIST_RELATE_DATA,
  LOADER_START,
  GET_ALLOCATE_ASSOCIATE,
  GET_ASSOCIATETYPE_ASSOCIATE_REVIEW_LIST_SAGA,
  GET_NODE_ASSOCIATE_REVIEW_LIST,
  SET_DISPLAY_TWO_SINGLE_STATE,
  ASSOCIATE_REVIEW_DISTINCT_SHARE_SAGA
} from '../../actionType';
import { ASSOCIATE_MODULE_URL } from '../../endpoints';
import { EXCEPTION_ERROR_MESSAGE } from '../../errorMessage';
import Store from '../../store';

const reviewListDistinctApi = async (requestObj) => {
  // console.log(requestObj.data);
  const requestOptions = {
    method: 'POST',
    headers: new Headers({
      Authorization: localStorage.getItem('token')
    }),
    body: JSON.stringify(requestObj.data)
  };
  const response = await fetch(requestObj.URL, requestOptions);
  const json = await response.json();
  return json;
};

function* workerReviewListAssociateSaga(data) {
  try {
    const userResponse = yield call(reviewListDistinctApi, {
      data: data.payload.request,
      URL: ASSOCIATE_MODULE_URL
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === '000') {
      yield put({
        type: SET_MIDDLEPANE_STATE,
        payload: {
          middlePaneHeader: 'associates',
          middlePaneHeaderBadgeOne: data.payload.BadgeOne,
          middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
          middlePaneHeaderBadgeThree: data.payload.BadgeThree,
          middlePaneHeaderBadgeFour: '',
          middlePaneHeaderDuplicate:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
          middlePaneHeaderDuplicateBadgeOne:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
          middlePaneHeaderDuplicateBadgeTwo:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
          middlePaneHeaderDuplicateBadgeThree:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
          middlePaneHeaderDuplicateBadgeFour:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour,
          
          middlePaneHeaderDuplicateOne:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOne,
          middlePaneHeaderDuplicateOneBadgeOne:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeOne,
          middlePaneHeaderDuplicateOneBadgeTwo:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeTwo,
          middlePaneHeaderDuplicateOneBadgeThree:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderBadgeThree,
          middlePaneHeaderDuplicateOneBadgeFour:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateOne,
          typeOfMiddlePaneList: data.payload?.typeOfMiddlePaneList || 'associateDistinctReviewList',
          scanCount: userResponse && userResponse.countTotal,
          showMiddlePaneState: true,
          isSelectActive: data.payload.isSelectActive,
          selectedTagsArray: data.payload.selectedTagsArray // selected array should nt get remove from search
        }
      });
      yield put({ type: REVIEWLIST_DISTINCT_DATA, payload: userResponse.responseObject });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerShareReviewListAssociateSaga(data) {
  try {
    const userResponse = yield call(reviewListDistinctApi, {
      data: data.payload.request,
      URL: ASSOCIATE_MODULE_URL
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === '000') {
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'reviewListDistinctData', value: [] }
      });
      yield put({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'reviewListDistinctDataDuplicate', value: [] }
      });
      let temArr = [
        ...userResponse.responseObject[0]?.associatesAscendantAll || [],
        ...userResponse.responseObject[0]?.associatesDescendantAll || [],
        ...userResponse.responseObject[0]?.associatesPeerAll ||[]
      ];
      let associateDistinctList = temArr.sort((a, b) =>
        a.informationBasic.associateName.toLowerCase() !==
          b.informationBasic.associateName.toLowerCase()
          ? a.informationBasic.associateName.toLowerCase() <
            b.informationBasic.associateName.toLowerCase()
            ? -1
            : 1
          : 0
      );
      yield put({
        type: SET_MIDDLEPANE_STATE,
        payload: {
          middlePaneHeader: 'associates',
          middlePaneHeaderBadgeOne: data.payload.BadgeOne,
          middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
          middlePaneHeaderBadgeThree: data.payload.BadgeThree,
          middlePaneHeaderBadgeFour: '',
          middlePaneHeaderDuplicate:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
          middlePaneHeaderDuplicateBadgeOne:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
          middlePaneHeaderDuplicateBadgeTwo:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
          middlePaneHeaderDuplicateBadgeThree:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
          middlePaneHeaderDuplicateBadgeFour:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour,
          typeOfMiddlePaneList: data.payload?.typeOfMiddlePaneList || 'associateDistinctShareReviewList',
          scanCount: associateDistinctList && associateDistinctList.length,
          duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
          showMiddlePaneState: true,
          isSelectActive: data.payload.isSelectActive,
          selectedTagsArray: data.payload.selectedTagsArray // selected array should nt get remove from search
        }
      });
      yield put({ type: REVIEWLIST_DISTINCT_DATA, payload: associateDistinctList });
      yield put({ type: SET_DISPLAY_TWO_SINGLE_STATE, payload: {stateName:'reviewListDistinctDataDuplicate',value:associateDistinctList} });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerReviewListAssociateGroupAssociateSaga(data) {
  // yield put({ type: LOADER_START });
  try {
    const userResponse = yield call(reviewListDistinctApi, {
      data: data.payload.request,
      URL: ASSOCIATE_MODULE_URL
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === '000') {
      let responseObj = null;
      if (Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData.length > 0) {
        responseObj = {
          ...Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData[0],
          associate: [
            ...Store.getState().DisplayPaneTwoReducer?.relatedReviewListDistinctData[0]?.associate,
            ...userResponse.responseObject[0]?.associate
          ]
        };
        //to add list more and adding all the record
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
      } else {
        responseObj = userResponse.responseObject[0];
      }
      yield put({
        type: RELATED_REVIEWLIST_DISTINCT_DATA,
        payload: [responseObj]
      });
      // yield put({ type: RELATED_REVIEWLIST_DISTINCT_DATA, payload: userResponse.responseObject });
      // yield put({ type: SET_REVIEW_LIST_RELATE_DATA, payload: userResponse.responseObject });
      yield put({
        type: RELATED_REVIEWLIST_DISTINCT_DATA,
        payload: [userResponse.responseObject[0]]
      });
      yield put({
        type: SET_REVIEW_LIST_RELATE_DATA,
        payload: userResponse.responseObject[0]
      });
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: 'associates',
            middlePaneHeaderBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderBadgeThree: '',
            middlePaneHeaderBadgeFour: '',
            middlePaneHeaderDuplicate:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
            middlePaneHeaderDuplicateBadgeOne:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            middlePaneHeaderDuplicateBadgeTwo:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            middlePaneHeaderDuplicateBadgeThree:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
            middlePaneHeaderDuplicateBadgeFour:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour,
            typeOfMiddlePaneList: 'associatesGroupAssociateReviewList',
            scanCount: userResponse && userResponse.countTotal,
            duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
            showMiddlePaneState: true
          }
        });
      }
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerReviewListAssociateRoleAssociateSaga(data) {
  // yield put({ type: LOADER_START });
  try {
    const userResponse = yield call(reviewListDistinctApi, {
      data: data.payload.request,
      URL: ASSOCIATE_MODULE_URL
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === '000') {
      let responseObj = null;
      if (Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData.length > 0) {
        responseObj = {
          ...Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData[0],
          associate: [
            ...Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData[0].associate,
            ...userResponse.responseObject[0].associate
          ]
        };
        //to add list more and adding all the record
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
      } else {
        responseObj = userResponse.responseObject[0];
      }
      yield put({
        type: RELATED_REVIEWLIST_DISTINCT_DATA,
        payload: [responseObj]
      });
      yield put({
        type: SET_REVIEW_LIST_RELATE_DATA,
        payload: userResponse.responseObject
      });
      // yield put({ type: RELATED_REVIEWLIST_DISTINCT_DATA, payload: userResponse.responseObject });
      yield put({ type: SET_REVIEW_LIST_RELATE_DATA, payload: userResponse.responseObject[0] });
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: 'associates',
            middlePaneHeaderBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderBadgeThree: data.payload.BadgeThre,
            middlePaneHeaderBadgeFour: data.payload.BadgeFour,
            middlePaneHeaderDuplicate:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
            middlePaneHeaderDuplicateBadgeOne:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            middlePaneHeaderDuplicateBadgeTwo:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            middlePaneHeaderDuplicateBadgeThree:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
            middlePaneHeaderDuplicateBadgeFour:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour,
            typeOfMiddlePaneList: 'associatesRoleAssociateReviewList',
            scanCount: userResponse && userResponse.countTotal,
            duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
            showMiddlePaneState: true
          }
        });
      }
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerAssociateTypeAssociateSaga(data) {
  // yield put({ type: LOADER_START });
  try {
    const userResponse = yield call(reviewListDistinctApi, {
      data: data.payload.request,
      URL: ASSOCIATE_MODULE_URL
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === '000') {
      let responseObj = null;
      if (Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData.length > 0) {
        responseObj = {
          ...Store.getState().DisplayPaneTwoReducer?.relatedReviewListDistinctData[0],
          associate: [
            ...Store.getState()?.DisplayPaneTwoReducer?.relatedReviewListDistinctData[0]?.associate,
            ...userResponse.responseObject[0]?.associate
          ]
        };
        //to add list more and adding all the record
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
      } else {
        responseObj = userResponse.responseObject[0];
      }
      yield put({
        type: RELATED_REVIEWLIST_DISTINCT_DATA,
        payload: [responseObj]
      });
      yield put({ type: RELATED_REVIEWLIST_DISTINCT_DATA, payload: userResponse.responseObject });
      yield put({ type: SET_REVIEW_LIST_RELATE_DATA, payload: userResponse.responseObject });
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: 'associates',
            middlePaneHeaderBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderBadgeThree: data.payload.BadgeThre,
            middlePaneHeaderBadgeFour: data.payload.BadgeFour,
            middlePaneHeaderDuplicate:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
            middlePaneHeaderDuplicateBadgeOne:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            middlePaneHeaderDuplicateBadgeTwo:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            middlePaneHeaderDuplicateBadgeThree:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
            middlePaneHeaderDuplicateBadgeFour:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour,
            typeOfMiddlePaneList: 'associatesTypeAssociateReviewList',
            scanCount: userResponse && userResponse.countTotal,
            duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
            showMiddlePaneState: true
          }
        });
      }
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}
function* workerNodeAssociateSaga(data) {
  // yield put({ type: LOADER_START });
  try {
    const userResponse = yield call(reviewListDistinctApi, {
      data: data.payload.request,
      URL: ASSOCIATE_MODULE_URL
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === '000') {
      let responseObj = null;
      if (
        Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData.length > 0 &&
        data.payload.isMiddlePaneList
      ) {
        responseObj = {
          ...Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData[0],
          associate: [
            ...Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData[0]?.associate,
            ...userResponse.responseObject[0].associate
          ]
        };
        //to add list more and adding all the record
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
      } else {
        responseObj = userResponse.responseObject[0];
      }
      yield put({
        type: RELATED_REVIEWLIST_DISTINCT_DATA,
        payload: [responseObj]
      });
      yield put({ type: RELATED_REVIEWLIST_DISTINCT_DATA, payload: userResponse.responseObject });
      // yield put({ type: SET_REVIEW_LIST_RELATE_DATA, payload: userResponse.responseObject });
      let relatedReviewListPaneThree2 = {
        ...Store.getState().DisplayPaneThreeReducer.relatedReviewListPaneThree,
        associate: userResponse.responseObject[0].associate
      };

      yield put({
        type: SET_REVIEW_LIST_RELATE_DATA,
        payload: relatedReviewListPaneThree2
      });
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_MIDDLEPANE_STATE,
          payload: {
            middlePaneHeader: 'associates',
            middlePaneHeaderBadgeOne: data.payload.BadgeOne,
            middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
            middlePaneHeaderBadgeThree: '',
            middlePaneHeaderBadgeFour: '',
            middlePaneHeaderDuplicate:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
            middlePaneHeaderDuplicateBadgeOne:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
            middlePaneHeaderDuplicateBadgeTwo:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
            middlePaneHeaderDuplicateBadgeThree:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree,
            middlePaneHeaderDuplicateBadgeFour:
              Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour,
            typeOfMiddlePaneList: 'nodeAssociatesReviewList',
            scanCount: userResponse && userResponse.countTotal,
            duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
            showMiddlePaneState: true
          }
        });
      }
    } else {
      if (data.payload.isMiddlePaneList) {
        yield put({
          type: SET_POPUP_VALUE,
          payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
        });
      }
    }
    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}

function* workerReviewListAssociateAllocateSaga(data) {
  try {
    const userResponse = yield call(reviewListDistinctApi, {
      data: data.payload.request,
      URL: ASSOCIATE_MODULE_URL
      // URL: EXTERNAL_NODE_LIST_URL
    });
    // const userResponse ={responseCode:'000',countTotal:30}
    if (userResponse.responseCode === '000') {
      // let Arr1 = userResponse.responseObject[0].associateRoot;
      // let Arr2 = userResponse.responseObject[0]?.associateDescendantAll || [];
      // Arr2.unshift(Arr1);
      // let responseObj = {
      //   ...data.payload.revisedGroupObject,
      //   associate: Arr2
      // };

      let responseObj = null;
      if (Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData.length > 0) {
        responseObj = {
          ...data.payload.revisedGroupObject,
          associate: [
            ...Store.getState().DisplayPaneTwoReducer.relatedReviewListDistinctData[0].associate,
            ...userResponse.responseObject
          ]
        };
        // to add list more and adding all the record
        yield put({
          type: SET_DISPLAY_TWO_SINGLE_STATE,
          payload: { stateName: 'relatedReviewListDistinctData', value: [] }
        });
      } else {
        responseObj = {
          ...data.payload.revisedGroupObject,
          associate: userResponse.responseObject
        };
      }

      yield put({
        type: SET_MIDDLEPANE_STATE,
        payload: {
          middlePaneHeader: data.payload.headerOne || 'associate',
          middlePaneHeaderBadgeOne: 'distinct',
          // middlePaneHeaderBadgeTwo: data.payload.BadgeTwo || 'active',
          middlePaneHeaderBadgeTwo: data.payload.BadgeTwo,
          middlePaneHeaderBadgeThree: '',
          middlePaneHeaderBadgeFour: '',
          middlePaneHeaderDuplicate:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicate,
          middlePaneHeaderDuplicateBadgeOne:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeOne,
          middlePaneHeaderDuplicateBadgeTwo:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeTwo,
          middlePaneHeaderDuplicateBadgeThree:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeThree, //
          middlePaneHeaderDuplicateBadgeFour:
            Store.getState().DisplayPaneTwoReducer.middlePaneHeaderDuplicateBadgeFour, //
          typeOfMiddlePaneList: data.payload.typeOfMiddlePaneList,
          scanCount: userResponse && userResponse.countTotal,
          showMiddlePaneState: true,
          duplicateScanCount: Store.getState().DisplayPaneTwoReducer.duplicateScanCount,
          isSelectActive: 'multiple',
          selectedTagsArray: data.payload.existingAssesseeId,
          unselectedTagsArray: data.payload.unselectedTagsArray
        }
      });
      yield put({ type: RELATED_REVIEWLIST_DISTINCT_DATA, payload: [responseObj] });
      // yield put({
      //   type: SET_REVIEW_LIST_RELATE_DATA,
      //   payload: responseObj
      // });
    } else {
      yield put({
        type: SET_POPUP_VALUE,
        payload: { isPopUpValue: userResponse.responseMessage, popupMode: 'responseErrorMsg' }
      });
    }
    console.log('loading end');
    yield put({ type: LOADER_STOP });
  } catch (e) {
    console.log('ERROR==', e);
    yield put({
      type: SET_POPUP_VALUE,
      payload: { isPopUpValue: EXCEPTION_ERROR_MESSAGE, popupMode: 'responseErrorMsg' }
    });
    yield put({ type: LOADER_STOP });
  }
}

export default function* watchReviewListAssociateSaga() {
  yield takeLatest(ASSOCIATE_REVIEW_DISTINCT_SAGA, workerReviewListAssociateSaga);
  yield takeLatest(ASSOCIATE_REVIEW_DISTINCT_SHARE_SAGA, workerShareReviewListAssociateSaga);
  yield takeLatest(GET_ASSOCIATETYPE_ASSOCIATE_REVIEW_LIST_SAGA, workerAssociateTypeAssociateSaga);
  yield takeLatest(GET_NODE_ASSOCIATE_REVIEW_LIST, workerNodeAssociateSaga);
  yield takeEvery(
    GET_ASSOCIATEROLE_ASSOCIATE_REVIEW_LIST_SAGA,
    workerReviewListAssociateRoleAssociateSaga
  );
  yield takeLatest(
    GET_ASSOCIATEGROUP_ASSOCIATE_REVIEW_LIST_SAGA,
    workerReviewListAssociateGroupAssociateSaga
  );
  yield takeLatest(GET_ALLOCATE_ASSOCIATE, workerReviewListAssociateAllocateSaga);
}
