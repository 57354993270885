import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PopUpPicture from '../../PopUpInformation/PopUpPicture';
import PopUpTextField from '../../PopUpInformation/PopUpTextField';
import PopUpConfirm from '../../PopUpGeneric/PopUpConfirm';
import {
  POPUP_CLOSE,
  CLEAR_TYPE_REDUCER_STATE,
  SET_DISPLAY_THREE_SINGLE_STATE,
  SET_JOB_REDUCER_STATE,
  SET_JOB_DYNAMIC_SINGLE_STATE,
  SET_JOB_DYNAMIC_ARRAY_STATE,
  LOADER_START,
  CREATE_JOB_SAGA,
  SET_DISPLAY_PANE_THREE_STATE,
  SET_NEXT_POPUP,
  GET_JOBDOMAIN_REVIEW_LIST_SAGA,
  SET_MOBILE_PANE_STATE,
  SET_JOB_SIFTLIST_STATE,
  SET_WEIGHTAGE_SELECTED,
  SET_RANGE_SELECTED,
  SETJOB_WEIGHTAGE_SELECTED,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_POPUP_SINGLE_STATE,
  SET_JOB_SETUP_INFO_STATE
} from '../../actionType';
import PopUpReviewList from '../../PopUpInformation/PopUpReviewList';
import PopUpMessageGeneric from '../../PopUpGeneric/PopUpMessageGeneric';
import PopUpCheckbox from '../../PopUpInformation/PopUpCheckbox';
import { keys } from '@material-ui/core/styles/createBreakpoints';
import { CAN_NOT_REVISED_MESSAGE } from '../../errorMessage';
import { getPopUpOptionList } from '../../Actions/GenericActions';
import { JOB_DISTINCT_REVIEW_LIST_POPUP_ITERBLE_OPTION } from '../../PopUpConfig';

const PopUpJobProfileCreate = (props) => {
  const { headerOne, reducerObeject, allocationObj } = props;
  const { isPopUpValue, popupErrorMsg } = useSelector((state) => state.PopUpReducer);
  const { jobProfileInformation } = useSelector((state) => state.JobProfileCreateReducer);
  const { reviewMode, headerOneBadgeTwo, createMode } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  const {
    selectedAssociateInfo,
    coreNodeReviewListData,
    coreGroupReviewListData,
    jobProfilerReviewList,
    coreTypeReviewListData,
    selectedInformationAllorKey,
    responseObject,
    signedAssesseePermission
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const dispatch = useDispatch();
  const [requiredErrorMsg, setRequiredErrorMsg] = useState('');
  const onClickCancelYes = () => {
    dispatch({
      type: SET_DISPLAY_THREE_SINGLE_STATE,
      payload: { stateName: 'createMode', value: '' }
    });
    dispatch({ type: CLEAR_TYPE_REDUCER_STATE });
    dispatch({ type: POPUP_CLOSE });
  };
  useEffect(() => {
    let tempTypeArr = coreTypeReviewListData.filter((tt) => {
      return tt.informationSetup?.jobProfileTypeDefault;
    });
    if (tempTypeArr.length > 0) {
      jobProfileInformation.informationAllocation.jobProfileType.jobProfileTypePrimary.push(
        tempTypeArr[0].id
      );
    }
  }, [coreTypeReviewListData, createMode]);
  const onClickYes = () => {
    // jobProfileInformation
    // let tempjobProfileJobCompetencySiftedOb =
    //   jobProfileInformation?.informationFramework?.jobProfileJobCompetencySifted || {};
    // const JobCompetencySiftedArray = [];
    // if (tempjobProfileJobCompetencySiftedOb) {
    //   for (const [key, value] of Object.entries(tempjobProfileJobCompetencySiftedOb)) {
    //     JobCompetencySiftedArray.push({
    //       jobProfileJobCompetencySift: key,
    //       jobProfileJobCompetencyTag: value
    //     });
    //   }
    // }
    let informationAllocation = jobProfileInformation.informationAllocation;
    // add default root node in allocation if node not selected
    if (
      informationAllocation.jobProfileNode.jobProfileNodePrimary.length === 0 &&
      coreNodeReviewListData.length !== 0
    ) {
      let rootNode = coreNodeReviewListData.filter((node) => {
        return node.informationFramework.associateNodeAscendantPrimary === null;
      });
      let rootNodeId = rootNode[0].id;
      informationAllocation.jobProfileNode.jobProfileNodePrimary = [
        ...informationAllocation.jobProfileNode.jobProfileNodePrimary,
        rootNodeId
      ];
      console.log('rootNodeId', rootNodeId);
    }
    let reqBody = {
      module: 'Job-Profile-Distinct',
      action: 'Create',
      requestObject: {
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        jobProfile: {
          informationAllocation: jobProfileInformation.informationAllocation,
          informationBasic: jobProfileInformation.informationBasic,
          informationFramework: {
            jobProfileJobDomain: [],
            jobProfileJobFunction: [],
            jobProfileJobRole: [],
            jobProfileJobCompetencySelectList: [],
            jobProfileJobCompetencyShortList: []
          }
        }
      }
    };
    let obj = {
      data: 'assessments',
      dataValue: 'assessments',
      dataKey: 'review',
      optionClass: 'optionPrimary',
      divider: 'dark',
      disabled: false,
      permissionValue: 'review'
    };
    let popupContentArrValue = [];
    JOB_DISTINCT_REVIEW_LIST_POPUP_ITERBLE_OPTION.map((obj) => popupContentArrValue.push(obj));
    popupContentArrValue.splice(11, 0, obj);

    let cultureProfileDistictPermssion =
      signedAssesseePermission?.iGuruAnalyticDistinctPermission
        ?.assesseeiGuruAnalyticDistinctPermission;
    let popupContentArr = getPopUpOptionList(popupContentArrValue, cultureProfileDistictPermssion);
    let finalPopupOptionArray = [];
    popupContentArr.forEach((element) => {
      if (element.data === 'share') {
        finalPopupOptionArray.push({ ...element, disabled: true });
      } else {
        finalPopupOptionArray.push(element);
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: finalPopupOptionArray
      }
    });
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: {
        stateName: 'popupOptionArr',
        value: finalPopupOptionArray
      }
    });
    dispatch({
      type: SET_POPUP_SINGLE_STATE,
      payload: {
        stateName: 'originPrimaryArrOprion',
        value: finalPopupOptionArray
      }
    });
    console.log('CREATE api', reqBody);
    dispatch({ type: LOADER_START });
    dispatch({ type: CREATE_JOB_SAGA, payload: reqBody });
  };

  let jobDomainArr = [];

  console.log('jobProfilerReviewList', jobProfilerReviewList);
  console.log('jobDomainArr', jobDomainArr);

  let jobFunctionArr = [];

  console.log('jobFunctionArr', jobFunctionArr);

  let jobRoleArr = [];

  console.log('jobRoleArr', jobRoleArr);

  const updateAllocationObj = (e, stateName, actualStateName) => {
    let tagId = e.currentTarget.getAttribute('tag');
    let isDefault = e.currentTarget.getAttribute('data-default');
    setRequiredErrorMsg('');
    if (isDefault !== 'true') {
      let tagIdArr = jobProfileInformation.informationAllocation[stateName][actualStateName];
      if (tagIdArr.includes(tagId)) {
        document.getElementById(tagId).style.backgroundColor = 'white';
        tagIdArr = tagIdArr.filter(function (number) {
          return number !== tagId;
        });
      } else {
        tagIdArr.push(tagId);
        document.getElementById(tagId).style.backgroundColor = '#F0F0F0';
      }
      dispatch({
        type: SET_JOB_DYNAMIC_SINGLE_STATE,
        payload: {
          objectName: 'informationAllocation',
          stateName: stateName,
          actualStateName: actualStateName,
          value: tagIdArr
        }
      });
    }
  };
  const updateFrameworkObj = (e, objectName, stateName) => {
    let tagId = e.currentTarget.getAttribute('tag');
    let tagIdArr = jobProfileInformation.informationFramework[stateName] || [];
    if (tagIdArr.includes(tagId)) {
      document.getElementById(tagId).style.backgroundColor = 'white';
      tagIdArr = tagIdArr.filter(function (number) {
        return number !== tagId;
      });
    } else {
      tagIdArr.push(tagId);
      document.getElementById(tagId).style.backgroundColor = '#F0F0F0';
    }
    dispatch({
      type: SET_JOB_DYNAMIC_ARRAY_STATE,
      payload: {
        objectName: objectName,
        stateName: stateName,
        value: tagIdArr
      }
    });
  };
  console.log('jobProfileInformation', jobProfileInformation);
  let selectedPrimaryGroup =
    jobProfileInformation?.informationAllocation?.jobProfileGroup?.jobProfileGroupPrimary || [];
  let selectedSecondaryGroup =
    jobProfileInformation?.informationAllocation?.jobProfileGroup?.jobProfileGroupSecondary || [];
  let filteredCoreGroupReviewListDataPrimary = [];
  if (coreGroupReviewListData && coreGroupReviewListData.length > 0) {
    coreGroupReviewListData.forEach((group) => {
      // for primary popup list
      if (!selectedSecondaryGroup.includes(group.id))
        filteredCoreGroupReviewListDataPrimary.push(group);
    });
  }
  let filteredCoreGroupReviewListDataSecondary = [];
  if (coreGroupReviewListData && coreGroupReviewListData.length > 0) {
    coreGroupReviewListData.forEach((group) => {
      // for Secondary popup list
      if (!selectedPrimaryGroup.includes(group.id))
        filteredCoreGroupReviewListDataSecondary.push(group);
    });
  }
  const onClickContinueNo = () => {
    dispatch({
      type: SET_DISPLAY_PANE_THREE_STATE,
      payload: {
        headerOne: 'job-profile',
        headerOneBadgeOne: 'distinct',
        headerOneBadgeTwo: 'information',
        headerOneBadgeThree: selectedInformationAllorKey || headerOneBadgeTwo,
        responseObject: responseObject,
        reviewMode: 'revise',
        createMode: createMode
      }
    });
  };
  const onClickRevise = () => {
    dispatch({
      type: SET_DISPLAY_PANE_THREE_STATE,
      payload: {
        headerOne: 'job-profile',
        headerOneBadgeOne: 'distinct',
        headerOneBadgeTwo: 'information',
        headerOneBadgeThree: selectedInformationAllorKey || headerOneBadgeTwo,
        responseObject: responseObject,
        reviewMode: 'revise',
        createMode: createMode
      }
    });
    dispatch({
      type: SET_MOBILE_PANE_STATE,
      payload: 'displayPaneThree'
    });
    // dispatch({
    //   type: SET_DISPLAY_TWO_SINGLE_STATE,
    //   payload: { stateName: 'responseObject', value: null }
    // });
    dispatch({
      type: SET_WEIGHTAGE_SELECTED,
      payload: true
    });
  };
  const onClickContinueYes = () => {
    // let requestObj = {
    //   assesseeId: selectedAssociateInfo?.assesseeId,
    //   associateId:
    //     selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary
    // };
    let requestObj = {
      module: 'Profiler',
      action: 'Create',
      requestObject: {
        action: 'Review-List',
        assesseeId: selectedAssociateInfo?.assesseeId,
        associateId:
          selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary,
        filter: 'true'
      }
    };
    dispatch({
      type: GET_JOBDOMAIN_REVIEW_LIST_SAGA,
      payload: {
        request: requestObj,
        BadgeOne: '',
        BadgeTwo: '',
        BadgeThree: '',
        isMiddlePaneList: false
      }
    });
  };
  const setCompetancyCoreStateReducer = () => {
    let coreList = [];
    let jobCompetancyCore =
      jobProfileInformation.informationFramework.jobProfileJobCompetencyShortlisted;

    if (reviewMode === 'revise') {
      let newcoreList = JSON.parse(
        JSON.stringify(jobProfileInformation.informationFramework.jobProfileJobCompetencyCoreObj)
      );
      newcoreList.forEach((ee) => {
        if (jobCompetancyCore.includes(ee.id)) coreList.push(ee);
      });
      let reviseCoreList = coreList.map((ob) => {
        return ob.id;
      });
      jobProfilerReviewList.jobCompetency.map((job) => {
        job.jobCompetency.forEach((jj) => {
          if (jobCompetancyCore.includes(jj.id) && !reviseCoreList.includes(jj.id)) {
            coreList.push({
              ...jj,
              iGaugeOccupationFrameworkOneSelected: '',
              iGaugeOccupationFrameworkOneRangeExtremumMaximum: 0,
              iGaugeOccupationFrameworkOneRangeExtremumMinimum: 0,
              iGaugeOccupationFrameworkOneWeightage: 0
            });
          }
        });
      });
    } else {
      jobProfilerReviewList.jobCompetency.map((job) => {
        job.jobCompetency.forEach((jj) => {
          if (jobCompetancyCore.includes(jj.id)) {
            console.log('find');
            coreList.push({
              ...jj,
              iGaugeOccupationFrameworkOneSelected: '',
              iGaugeOccupationFrameworkOneRangeExtremumMaximum: 0,
              iGaugeOccupationFrameworkOneRangeExtremumMinimum: 0,
              iGaugeOccupationFrameworkOneWeightage: 0
            });
          }
        });
      });
    }

    console.log('coreList', coreList);
    let obj = {
      ...jobProfileInformation.informationFramework,
      jobProfileJobCompetencyCoreObj: coreList
    };
    dispatch({
      type: SET_JOB_SIFTLIST_STATE,
      payload: obj
    });
    dispatch({
      type: SET_NEXT_POPUP,
      payload: { isPopUpValue: 'POPUPSIFTLIST0' }
    });
  };
  // useEffect(() => {
  //   if (jobProfileInformation.informationFramework.jobProfileJobCompetencyCoreObj.length > 0) {
  //     dispatch({ type: SET_NEXT_POPUP, payload: { isPopUpValue: 'POPUPSIFTLIST0' } });
  //   }
  // }, [jobProfileInformation.informationFramework.jobProfileJobCompetencyCoreObj]);
  const updateCompetencySiftList = (id, key) => {
    console.log(id, key);
    let shortlist =
      jobProfileInformation.informationFramework?.jobProfileJobCompetencyShortList || [];
    let coreObj = jobProfileInformation.informationFramework?.jobProfileJobCompetencyCoreObj || [];
    let siftRemove =
      jobProfileInformation.informationFramework?.jobProfileJobCompetencySiftRemovable || [];
    let siftListArr =
      jobProfileInformation.informationFramework?.jobProfileJobCompetencySiftList || [];
    if (key) {
      siftRemove = siftRemove.filter(function (number) {
        return number !== id;
      });
      console.log('afterss', siftRemove);
      siftListArr = siftListArr.filter(function (num) {
        return num !== id;
      });
      console.log('after', siftListArr);

      coreObj.forEach((job) => {
        if (job.id === id) {
          job.iGaugeOccupationFrameworkOneSelected = key;
        }
      });
      if (key !== 'removable' && siftListArr.includes(id) === false) {
        siftListArr.push(id);
        if (siftRemove.includes(id)) {
          //
        }
      }
      if (key === 'removable' && siftRemove.includes(id) === false) {
        siftRemove.push(id);
        if (siftListArr.includes(id)) {
          //
        }
      }
      console.log('siftRemove', siftRemove);
      console.log('siftListArr', siftListArr);
      let obj = {
        ...jobProfileInformation.informationFramework,
        jobProfileJobCompetencyCoreObj: coreObj,
        jobProfileJobCompetencySiftRemovable: siftRemove,
        jobProfileJobCompetencySiftList: siftListArr
      };
      dispatch({
        type: SET_JOB_SIFTLIST_STATE,
        payload: obj
      });
      // if(key ==='removable'){
      //   var removeIndex = coreObj.map(jj => jj.id).indexOf(id);
      //   console.log('removeIndex',removeIndex);
      //   coreObj.splice(removeIndex,1);
      // }
    }
  };
  const openRightPaneForRange = (type) => {
    let jobCoreListObj = [];
    let coreListObj =
      jobProfileInformation?.informationFramework?.jobProfileJobCompetencyCoreObj || [];
    let coreList = jobProfileInformation?.informationFramework?.jobProfileJobCompetencyCore || [];
    if (coreListObj && type === 'range') {
      coreListObj.forEach((element) => {
        if (coreList.includes(element.id)) {
          jobCoreListObj.push(element);
        }
      });
      let obj = {
        ...jobProfileInformation.informationFramework,
        jobProfileJobCompetencyCore: jobCoreListObj
      };
      dispatch({
        type: SET_JOB_SIFTLIST_STATE,
        payload: obj
      });
    }

    dispatch({
      type: SET_DISPLAY_PANE_THREE_STATE,
      payload: {
        headerOne: 'job-profile',
        headerOneBadgeOne: 'distinct',
        headerOneBadgeTwo: 'information',
        headerOneBadgeThree: selectedInformationAllorKey || headerOneBadgeTwo,
        responseObject: responseObject,
        reviewMode: 'revise',
        createMode: createMode
      }
    });
    dispatch({
      type: SET_MOBILE_PANE_STATE,
      payload: 'displayPaneThree'
    });

    if (type === 'weightage') {
      dispatch({
        type: SETJOB_WEIGHTAGE_SELECTED,
        payload: true
      });
    }
    if (type === 'range') {
      dispatch({
        type: SET_RANGE_SELECTED,
        payload: true
      });
    }
  };
  const jobProfileSifted = jobProfileInformation.informationFramework.jobProfileJobCompetencySifted;
  const jobProfileCoreObj =
    jobProfileInformation.informationFramework.jobProfileJobCompetencyCoreObj;
  let siftlen = jobProfileInformation?.informationFramework?.jobProfileJobCompetencySiftList.length;
  let removelen =
    jobProfileInformation?.informationFramework?.jobProfileJobCompetencySiftRemovable.length;
  console.log('length', siftlen, removelen, isPopUpValue);
  console.log(
    'isPopUpValue',
    siftlen + removelen ===
      jobProfileInformation?.informationFramework?.jobProfileJobCompetencyCoreObj.length
  );
  return (
    <div>
      {isPopUpValue === 'NAMEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'name'}
          actualLableValue={'jobProfileName'}
          actualLableValueVerification={'jobProfileNameVerification'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'ALIASPOPUP'}
          isRequired={true}
          typeOfSetObject={SET_JOB_REDUCER_STATE}
          basicInfo={jobProfileInformation.informationBasic}
          errorMsg={popupErrorMsg}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'ALIASPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'description'}
          actualLableValue={'jobProfileDescription'}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'PICTUREPOPUP'}
          typeOfSetObject={SET_JOB_REDUCER_STATE}
          basicInfo={jobProfileInformation.informationBasic}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'PICTUREPOPUP' && (
        <PopUpPicture
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          actualLableValue={'jobProfilePicture'}
          actualLableValueVerification={'jobProfilePictureVerification'}
          basicInfo={jobProfileInformation.informationBasic}
          typeOfSetObject={SET_JOB_REDUCER_STATE}
          nextPopUpValue={'GROUPPOPUP'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'GROUPPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'NODEPOPUP'}
          inputHeader={'group'}
          inputHeaderBadge={'primary'}
          infoMsg={'select a group'}
          ListData={filteredCoreGroupReviewListDataPrimary}
          selectedList={
            jobProfileInformation.informationAllocation.jobProfileGroup.jobProfileGroupPrimary
          }
          textOne={'jobProfileGroupName'}
          textTwo={'jobProfileGroupDescription'}
          onClickEvent={(e) => {
            updateAllocationObj(e, 'jobProfileGroup', 'jobProfileGroupPrimary');
          }}
          setErrorMsg={setRequiredErrorMsg}
          errorMsg={requiredErrorMsg}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
        />
      )}
      {isPopUpValue === 'GROUPSECONDARYPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'NODEPOPUP'}
          inputHeader={'group'}
          inputHeaderBadge={'secondary'}
          infoMsg={'select a group'}
          ListData={filteredCoreGroupReviewListDataSecondary}
          selectedList={
            jobProfileInformation.informationAllocation.jobProfileGroup.jobProfileGroupSecondary
          }
          textOne={'jobProfileGroupName'}
          textTwo={'jobProfileGroupDescription'}
          onClickEvent={(e) => {
            updateAllocationObj(e, 'jobProfileGroup', 'jobProfileGroupSecondary');
          }}
          setErrorMsg={setRequiredErrorMsg}
          errorMsg={requiredErrorMsg}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          useLocalState={true}
          stateName={`jobProfileGroup`}
          actualStateName={`jobProfileGroupSecondary`}
          objectName={'informationAllocation'}
          updateState={SET_JOB_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'NODEPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'TYPEPOPUP'}
          inputHeader={'node'}
          inputHeaderBadge={'primary'}
          infoMsg={'select a node'}
          ListData={coreNodeReviewListData}
          selectedList={
            jobProfileInformation.informationAllocation.jobProfileNode.jobProfileNodePrimary
          }
          isRequired={true}
          minimumSelected={1}
          setErrorMsg={setRequiredErrorMsg}
          errorMsg={requiredErrorMsg}
          textOne={'associateNodeName'}
          textTwo={'associateNodeDescription'}
          onClickEvent={(e) => {
            updateAllocationObj(e, 'jobProfileNode', 'jobProfileNodePrimary');
          }}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          useLocalState={true}
          stateName={`jobProfileNode`}
          actualStateName={`jobProfileNodePrimary`}
          objectName={'informationAllocation'}
          updateState={SET_JOB_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'NODESECONDARYPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'TYPEPOPUP'}
          inputHeader={'node'}
          inputHeaderBadge={'secondary'}
          infoMsg={'select a node'}
          ListData={coreNodeReviewListData}
          selectedList={
            jobProfileInformation.informationAllocation.jobProfileNode.jobProfileNodeSecondary
          }
          textOne={'associateNodeName'}
          textTwo={'associateNodeDescription'}
          onClickEvent={(e) => {
            updateAllocationObj(e, 'jobProfileNode', 'jobProfileNodeSecondary');
          }}
          setErrorMsg={setRequiredErrorMsg}
          errorMsg={requiredErrorMsg}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          useLocalState={true}
          stateName={`jobProfileNode`}
          actualStateName={`jobProfileNodeSecondary`}
          objectName={'informationAllocation'}
          updateState={SET_JOB_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'TYPEPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'CONFIRMATIONPOPUP'}
          inputHeader={'type'}
          inputHeaderBadge={'primary'}
          infoMsg={'select a type'}
          dataDefault={'jobProfileTypeDefault'}
          ListData={coreTypeReviewListData.filter((tt) => {
            return tt.informationSetup.jobProfileTypeDefault;
          })}
          selectedList={
            jobProfileInformation.informationAllocation.jobProfileType.jobProfileTypePrimary
          }
          textOne={'jobProfileTypeName'}
          textTwo={'jobProfileTypeDescription'}
          onClickEvent={(e) => {
            updateAllocationObj(e, 'jobProfileType', 'jobProfileTypePrimary');
          }}
          setErrorMsg={setRequiredErrorMsg}
          errorMsg={requiredErrorMsg}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          useLocalState={true}
          stateName={`jobProfileType`}
          actualStateName={`jobProfileTypePrimary`}
          objectName={'informationAllocation'}
          updateState={SET_JOB_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'TYPESECONDARYPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'CONFIRMATIONPOPUP'}
          inputHeader={'type'}
          inputHeaderBadge={'secondary'}
          infoMsg={'select a type'}
          ListData={coreTypeReviewListData.filter((tt) => {
            return !tt.informationSetup.jobProfileTypeDefault;
          })}
          selectedList={
            jobProfileInformation.informationAllocation.jobProfileType.jobProfileTypeSecondary
          }
          textOne={'jobProfileTypeName'}
          textTwo={'jobProfileTypeDescription'}
          onClickEvent={(e) => {
            updateAllocationObj(e, 'jobProfileType', 'jobProfileTypeSecondary');
          }}
          setErrorMsg={setRequiredErrorMsg}
          errorMsg={requiredErrorMsg}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          useLocalState={true}
          stateName={`jobProfileType`}
          actualStateName={`jobProfileTypeSecondary`}
          objectName={'informationAllocation'}
          updateState={SET_JOB_DYNAMIC_SINGLE_STATE}
        />
      )}
      {isPopUpValue === 'POPUPASSESSMENTPOPUP' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={''}
          inputHeader={'assessment'}
          inputHeaderBadge={''}
          infoMsg={'assessment list'}
          ListData={responseObject?.informationFramework?.jobProfileAssessment || []}
          selectedList={
            responseObject?.informationFramework?.jobProfileAssessment?.filter(function (num) {
              return num.id;
            }) || []
          }
          textOne={'assessmentName'}
          textTwo={'assessmentDescription'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          isNotRevised={true}
          errorMsg={CAN_NOT_REVISED_MESSAGE}
        />
      )}
      {isPopUpValue === 'CANCELPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={'cancel'}
          headerOneBadgeOne={''}
          mode={'cancel'}
          onClickYes={onClickCancelYes}
        />
      )}
      {isPopUpValue === 'CONFIRMATIONPOPUP' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'create'}
          headerOneBadgeTwo={''}
          onClickYes={onClickYes}
          onClickNoFun={onClickCancelYes}
        />
      )}
      {isPopUpValue === 'POPUPCONTINUE' && (
        <PopUpConfirm
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'continue'}
          headerOneBadgeTwo={''}
          onClickYes={onClickContinueYes}
          mode={'error'}
          onClickNoFun={onClickContinueNo}
        />
      )}
      {isPopUpValue === 'POPUPDOMAINMSG' && (
        <PopUpMessageGeneric
          isActive={true}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'POPUPJOBDOMAIN'}
          textOneOne={'select'}
          textOneTwo={'one or more'}
          textOneThree={'job-domains'}
          mode={'next'}
          textOneFour={'from the following list'}
        />
      )}
      {isPopUpValue === 'POPUPJOBDOMAIN' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'POPUPFUNCTIONMSG'}
          prevPopUpValue={'POPUPDOMAINMSG'}
          inputHeader={'job-domains'}
          inputHeaderBadge={''}
          infoMsg={'select a job domain'}
          isRequired={true}
          minimumSelected={1}
          ListData={jobProfilerReviewList?.jobDomain}
          textOne={'iGaugeOccupationFrameworkOneClusterName'}
          textTwo={'iGaugeOccupationFrameworkOneClusterDescription'}
          onClickEvent={(e) => {
            updateFrameworkObj(e, 'informationFramework', 'jobProfileJobDomain');
          }}
          isTooltipActive={true}
          tooltipActiveText={'iGaugeOccupationFrameworkOneClusterExplanation'}
          selectedList={jobProfileInformation.informationFramework.jobProfileJobDomain}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          useLocalState={true}
          stateName={`jobProfileJobDomain`}
          objectName={'informationFramework'}
          updateState={SET_JOB_DYNAMIC_ARRAY_STATE}
        />
      )}
      {isPopUpValue === 'POPUPFUNCTIONMSG' && (
        <PopUpMessageGeneric
          isActive={true}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'POPUPJOBFUNCTION'}
          textOneOne={'select'}
          textOneTwo={'one or more'}
          textOneThree={'job-functions'}
          mode={'next'}
          textOneFour={'from the following list'}
        />
      )}
      {isPopUpValue === 'POPUPJOBFUNCTION' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'POPUPROLEMSG'}
          prevPopUpValue={'POPUPFUNCTIONMSG'}
          inputHeader={'job-functions'}
          inputHeaderBadge={''}
          infoMsg={'select a job function'}
          isRequired={true}
          minimumSelected={1}
          onClickEvent={(e) => {
            updateFrameworkObj(e, 'informationFramework', 'jobProfileJobFunction');
          }}
          ListData={jobProfilerReviewList?.jobFunction}
          textOne={'iGaugeOccupationFrameworkOneClusterName'}
          textTwo={'iGaugeOccupationFrameworkOneClusterDescription'}
          selectedList={jobProfileInformation.informationFramework.jobProfileJobFunction}
          setErrorMsg={null}
          errorMsg={''}
          isTooltipActive={true}
          tooltipActiveText={'iGaugeOccupationFrameworkOneClusterExplanation'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          useLocalState={true}
          stateName={`jobProfileJobFunction`}
          objectName={'informationFramework'}
          updateState={SET_JOB_DYNAMIC_ARRAY_STATE}
        />
      )}
      {isPopUpValue === 'POPUPROLEMSG' && (
        <PopUpMessageGeneric
          isActive={true}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'POPUPJOBROLE'}
          textOneOne={'select'}
          textOneTwo={'one or more'}
          textOneThree={'job-roles'}
          mode={'next'}
          textOneFour={'from the following list'}
        />
      )}
      {isPopUpValue === 'POPUPJOBROLE' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'POPUPCOMPEMSG'}
          prevPopUpValue={'POPUPROLEMSG'}
          inputHeader={'job-roles'}
          inputHeaderBadge={''}
          infoMsg={'select a job role'}
          isRequired={true}
          minimumSelected={1}
          ListData={jobProfilerReviewList?.jobRole}
          textOne={'iGaugeOccupationFrameworkOneClusterName'}
          textTwo={'iGaugeOccupationFrameworkOneClusterDescription'}
          //ListData={jobProfilerReviewList?.jobRole}
          onClickEvent={(e) => {
            updateFrameworkObj(e, 'informationFramework', 'jobProfileJobRole');
          }}
          selectedList={jobProfileInformation.informationFramework.jobProfileJobRole}
          setErrorMsg={null}
          errorMsg={''}
          isTooltipActive={true}
          tooltipActiveText={'iGaugeOccupationFrameworkOneClusterExplanation'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          useLocalState={true}
          stateName={`jobProfileJobRole`}
          objectName={'informationFramework'}
          updateState={SET_JOB_DYNAMIC_ARRAY_STATE}
        />
      )}
      {isPopUpValue === 'POPUPCOMPEMSG' && (
        <PopUpMessageGeneric
          isActive={true}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'POPUPCOMPITANCY0'}
          textOneOne={'sift'}
          textOneTwo={'eight or more'}
          textOneThree={'job-competencies'}
          textOneFour={'from the following eleven lists'}
          mode={'next'}
          // handleClickFun={onClickRevise}
          // nextPopUpValue={'onClickRevise'}
        />
      )}
      {jobProfilerReviewList
        ? jobProfilerReviewList?.jobCompetency.map((value, index) => {
            return (
              isPopUpValue === `POPUPCOMPITANCY${index}` && (
                <PopUpReviewList
                  isActive={true}
                  headerPanelColour={'genericOne'}
                  headerOne={headerOne}
                  headerOneBadgeOne={'information'}
                  inputHeader={'job-competencies'}
                  inputHeaderBadge={'sift-list'}
                  infoMsg={'eight or more job competencies'}
                  nextPopUpValue={
                    index < jobProfilerReviewList?.jobCompetency.length - 1
                      ? `POPUPCOMPITANCY${index + 1}`
                      : jobProfileInformation.informationFramework
                          .jobProfileJobCompetencyShortlisted.length >= 8
                      ? 'POPUPSIFTMSG'
                      : 'POPUPCOMPEMSG'
                  }
                  // nextPopUpValue={`POPUPCOMPITANCY${index + 1}`}
                  ListData={value.jobCompetency}
                  // onClickEvent={updateJobCompetancy}
                  onClickEvent={(e) => {
                    updateFrameworkObj(
                      e,
                      'informationFramework',
                      'jobProfileJobCompetencyShortlisted'
                    );
                  }}
                  selectedList={
                    jobProfileInformation.informationFramework.jobProfileJobCompetencyShortlisted
                  }
                  textOne={'iGaugeOccupationFrameworkOneClusterName'}
                  textTwo={'iGaugeOccupationFrameworkOneClusterDescription'}
                  isTooltipActive={false}
                  tooltipActiveText={'iGaugeOccupationFrameworkOneClusterExplanation'}
                  dataValue={value.group}
                  jobCompetencyValue={true}
                  useLocalState={true}
                  stateName={`jobProfileJobCompetencyShortlisted`}
                  objectName={'informationFramework'}
                  updateState={SET_JOB_DYNAMIC_ARRAY_STATE}
                />
              )
            );
          })
        : null}
      {isPopUpValue === 'POPUPSIFTMSG' && (
        <PopUpMessageGeneric
          isActive={true}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'onClickRevise'}
          handleClickFun={setCompetancyCoreStateReducer}
          textOneOne={'short-list'}
          textOneTwo={'minimum eight'}
          textOneThree={'job-competencies'}
          textOneFour={'from the following lists'}
          textOneSix={'crucial * desirable * probable'}
          mode={'next'}
        />
      )}
      {jobProfilerReviewList
        ? jobProfileInformation?.informationFramework?.jobProfileJobCompetencyCoreObj.map(
            (value, index) => {
              return (
                isPopUpValue === `POPUPSIFTLIST${index}` && (
                  <PopUpCheckbox
                    isActive={true}
                    headerPanelColour={'genericOne'}
                    headerOne={headerOne}
                    headerOneBadgeOne={'information'}
                    inputHeader={'job-competencies'}
                    inputHeaderBadge={'short-list'}
                    infoMsg={'short job-competencies'}
                    isChecked={
                      jobProfileInformation.informationFramework.jobProfileJobCompetencyCoreObj
                        .map((ob) => {
                          if (ob.id === value.id) return ob.iGaugeOccupationFrameworkOneSelected;
                        })
                        .filter((notUndefined) => notUndefined !== undefined)[0]
                    }
                    onClickNext={updateCompetencySiftList}
                    isJobProfileList={true}
                    id={value.id}
                    textOne={value.iGaugeOccupationFrameworkOneClusterName}
                    textTwo={value.iGaugeOccupationFrameworkOneClusterDescription}
                    // isTooltipActive={true}
                    // tooltipActiveText={value.iGaugeOccupationFrameworkOneClusterExplanation}
                    valueArr={['crucial', 'desirable', 'probable', 'removable']}
                    nextPopUpValue={
                      index <
                      jobProfileInformation?.informationFramework?.jobProfileJobCompetencyCoreObj
                        .length -
                        1
                        ? `POPUPSIFTLIST${index + 1}`
                        : siftlen > 7 &&
                          siftlen + removelen ===
                            jobProfileInformation?.informationFramework
                              ?.jobProfileJobCompetencyCoreObj.length
                        ? 'POPUPCORECOMPEMSG'
                        : 'SIFTLISTMSG'
                    }
                    // nextPopUpValue={'POPUPCORECOMPEMSG'}
                  />
                )
              );
            }
          )
        : null}
      {isPopUpValue === 'POPUPCORECOMPEMSG' && (
        <PopUpMessageGeneric
          isActive={true}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          // nextPopUpValue={'POPUPRANGEMSG'}
          nextPopUpValue={'POPUPCOMPETENCYLIST'}
          textOneOne={'select'}
          textOneTwo={'maximum twelve or minimum eight'}
          textOneThree={'job-competencies'}
          textOneFour={'from the following list'}
          mode={'next'}
        />
      )}
      {isPopUpValue === 'SIFTLISTMSG' && (
        <PopUpMessageGeneric
          isActive={true}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          // nextPopUpValue={'POPUPRANGEMSG'}
          nextPopUpValue={'POPUPSIFTLIST0'}
          textOneOne={'sift'}
          textOneTwo={'minimum eight'}
          textOneThree={'job-competencies'}
          textOneFour={'from the following lists'}
          textOneSix={'crucial * desirable * probable'}
          mode={'next'}
        />
      )}
      {isPopUpValue === 'POPUPCOMPETENCYLIST' && (
        <PopUpReviewList
          isActive={true}
          headerPanelColour={'genericOne'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'POPUPRANGEMSG'}
          prevPopUpValue={'POPUPCORECOMPEMSG'}
          inputHeader={'job-competencies'}
          inputHeaderBadge={'select-list'}
          infoMsg={'select a job competencies'}
          isRequired={true}
          minimumSelected={8}
          // ListData={jobProfileInformation.informationFramework.jobProfileJobCompetencyCoreObj}
          ListData={jobProfileCoreObj
            .filter((job) => {
              return (
                job.iGaugeOccupationFrameworkOneSelected !== '' &&
                job.iGaugeOccupationFrameworkOneSelected !== 'removable'
              );
            })
            .sort((a, b) =>
              a.iGaugeOccupationFrameworkOneClusterName?.toLowerCase() !==
              b.iGaugeOccupationFrameworkOneClusterName?.toLowerCase()
                ? a.iGaugeOccupationFrameworkOneClusterName?.toLowerCase() <
                  b.iGaugeOccupationFrameworkOneClusterName?.toLowerCase()
                  ? -1
                  : 1
                : 0
            )}
          onClickEvent={(e) => {
            updateFrameworkObj(e, 'informationFramework', 'jobProfileJobCompetencyCore');
          }}
          selectedList={
            reviewMode === 'revise'
              ? jobProfileInformation.informationFramework.jobProfileJobCompetencyCore.map((ob) => {
                  return ob.id;
                })
              : jobProfileInformation.informationFramework.jobProfileJobCompetencyCore
          }
          textOne={'iGaugeOccupationFrameworkOneClusterName'}
          textTwo={'iGaugeOccupationFrameworkOneClusterDescription'}
          isInformationTwo={true}
          statusTwo={'iGaugeOccupationFrameworkOneSelected'}
          isTooltipActive={true}
          tooltipActiveText={'iGaugeOccupationFrameworkOneClusterExplanation'}
          setErrorMsg={null}
          errorMsg={''}
          mode={'core'}
          jobCompetencyValue={true}
          useLocalState={true}
          stateName={`jobProfileJobCompetencyCore`}
          objectName={'informationFramework'}
          updateState={SET_JOB_DYNAMIC_ARRAY_STATE}
        />
      )}
      {isPopUpValue === 'POPUPRANGEMSG' && (
        <PopUpMessageGeneric
          isActive={true}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          // nextPopUpValue={'is'}
          nextPopUpValue={'onClickRevise'}
          handleClickFun={() => {
            openRightPaneForRange('range');
          }}
          textOneOne={'range'}
          textOneThree={'for'}
          textOneFour={'selected'}
          textOneFive={'job-competencies'}
          mode={'next'}
        />
      )}
      {isPopUpValue === 'POPUPWEIGHTEMSG' && (
        <PopUpMessageGeneric
          isActive={true}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          nextPopUpValue={'onClickRevise'}
          textOneOne={'weightage'}
          textOneThree={'for'}
          textOneFour={'selected'}
          textOneFive={'job-competencies'}
          mode={'next'}
          handleClickFun={() => {
            openRightPaneForRange('weightage');
          }}
        />
      )}
      
      {isPopUpValue === 'JOBCREATEFEEPOPUP' && (
        <PopUpTextField
          isActive={true}
          label={'fee'}
          headerPanelColour={'genericOne'}
          inputHeader={headerOne}
          inputHeaderBadgeOne={'distinct'}
          inputHeaderBadgeTwo={'share'}
          type={'number'}
          headerOne={headerOne}
          headerOneBadgeOne={'information'}
          isRequired={false}
          actualLableValue={'jobProfileShareFee'}
          basicInfo={jobProfileInformation.informationSetup}
          typeOfSetObject={SET_JOB_SETUP_INFO_STATE}
          reviewMode={reviewMode === 'revise' ? 'revise' : 'core'}
          mode={reviewMode === 'revise' ? 'revise' : 'core'}
          currencySymbol={''}
        />
      )}
    </div>
  );
};

export default PopUpJobProfileCreate;
