import React from 'react';
import { isMobile } from 'react-device-detect';
// import AllocationAccordian from '../Accordian/AllocationAccordian';
// import Manuscript from '@material-ui/icons/Description';
import { useDispatch, useSelector } from 'react-redux';
import DisplayPanelAccordianReviewListOne from '../Accordian/DisplayPanelAccordianReviewListOne';
import DisplayPanelAccordianInformation from '../Accordian/DisplayPanelAccordianInformation';
import { Paper } from '@material-ui/core';
import {
  FILTERMODE,
  GET_ALLOCATE_ASSIGNMENT,
  LOADER_START,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_MOBILE_PANE_STATE
} from '../../actionType';
import { makeAssignmentReviewListRequestObject } from '../../Actions/GenericActions';
import {
  getAssignmentDistinctToAllocate,
  getAssignmnetTypeAssignmnetDistinctApiCall
} from '../../Actions/AssignmentModuleAction';

const DisplayPaneThreeSectionTwoAssignmentType = () => {
  // const [listExpand, setListExpand] = useState('');
  const dispatch = useDispatch();
  const { reviewMode, relatedReviewListPaneThree, responseObject } = useSelector(
    (state) => state.DisplayPaneThreeReducer
  );
  const { selectedAssociateInfo, countPage, assignmentCreateType } = useSelector(
    (state) => state.DisplayPaneTwoReducer
  );
  // const { informationEngagement, informationSetup } = responseObject;
  // function capitalizeFirstLetter(string) {
  //   if (!string) return '';
  //   return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  // }
  let assignmentList = [];
  if (relatedReviewListPaneThree) {
    assignmentList = relatedReviewListPaneThree?.assignment || [];
  }
  let assignmentArray = [];
  assignmentList.forEach((ob) => {
    const { id, informationBasic, informationEngagement, associateId } = ob;
    assignmentArray.push({
      id,
      textOne: informationBasic?.assignmentName || '',
      textTwo: informationBasic?.assignmentDescription || '',
      status: informationEngagement?.assignmentStatus || '',
      isShared: ob?.assignmentShared,
      associateId
    });
  });
  const onclickReviewAssignment = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    if (labelName === 'assignments' && selectedBadgeName === 'distinct') {
      console.log(
        'relatedReviewListPaneThree',
        relatedReviewListPaneThree.length,
        relatedReviewListPaneThree
      );
      getAssignmnetTypeAssignmnetDistinctApiCall(
        selectedAssociateInfo,
        '',
        -1,
        dispatch,
        'distinct',
        responseObject.id, //group id
        '',
        false,
        assignmentCreateType
      );
    }
  };
  const onclickReviseAssignment = (e) => {
    const labelName = e.currentTarget.getAttribute('data-value');
    const selectedBadgeName = e.currentTarget.getAttribute('data-key');
    if (labelName === 'assignments' && selectedBadgeName === 'distinct') {
      console.log('assignment CLICK :::::::>>>>>>>', relatedReviewListPaneThree);
      let requestObect = makeAssignmentReviewListRequestObject(
        selectedAssociateInfo,
        '',
        //'activeinactive',
        countPage,
        0,
        assignmentCreateType //'iGuru_Assignment' // type of list name, string typename
      );
      let revisedGroupObject = {
        id: responseObject.id,
        assignmentTypeName: responseObject.informationBasic.assignmentTypeName,
        assignmentTypeDescription: responseObject.informationBasic.assignmentTypeDescription,
        assignmentTypeStatus: responseObject.informationEngagement.assignmentTypeStatus
      };
      let existingAssignmentId = [];
      if (relatedReviewListPaneThree && relatedReviewListPaneThree?.assignment) {
        existingAssignmentId =
          relatedReviewListPaneThree &&
          relatedReviewListPaneThree.assignment.map((val) => {
            return val.id;
          });
      }
      getAssignmentDistinctToAllocate(
        dispatch,
        requestObect,
        revisedGroupObject,
        existingAssignmentId,
        'assignmentTypeAssignmentRevise',
        'assignmentTypeAssignmentReviewList'
      );
    }
  };
  const list2 = [
    {
      id: 'assignmentsa1',
      labelTextOneOne: 'assignments',
      labelTextOneOneBadgeOne: '',
      labelTextOneOneBadgeTwo: '',
      labelTextOneOneBadgeThree: '',
      labelTextOneOneBadgeFour: '',
      labelTextOneOneBadges: [
        {
          labelTextOneOneBadge: 'distinct',
          innerList: assignmentArray
        }
      ],
      innerInfo: 'No Information',
      isListCard: true,
      isReviewLink: true
    }
  ];

  return (
    <div>
      <>
        <div className={'containerPadding'}>
          <Paper className={'dossierContainerTop'}>
            {list2.map((ob) => {
              return (
                <div key={ob.id}>
                  {ob.isListCard ? (
                    <DisplayPanelAccordianReviewListOne
                      onClickRevise={onclickReviseAssignment}
                      onClickReview={onclickReviewAssignment}
                      className=""
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  ) : (
                    <DisplayPanelAccordianInformation
                      onClickRevise={onclickReviseAssignment}
                      accordianObject={ob}
                      mode={reviewMode}
                    />
                  )}
                </div>
              );
            })}
          </Paper>
        </div>
      </>
      {isMobile && (
        <div className={'containerPadding'} style={{ height: '55px' }}>
          {' '}
        </div>
      )}
    </div>
  );
};

export default DisplayPaneThreeSectionTwoAssignmentType;
