import React, { useState, Fragment } from 'react';
import PopUp from '../Molecules/PopUp/PopUp';
import PopupHeader from '../Molecules/PopUp/PopUpHeader';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import { ClickAwayListener, DialogContent, InputLabel } from '@material-ui/core';
// import { onClickCheckBoxSelection } from '../Actions/AssesseeModuleAction';
import { REQUIRED_ERROR_MESSAGE } from '../errorMessage';
import { POPUP_CLOSE, SET_NEXT_POPUP } from '../actionType';
import InfoToolTip from '../Atoms/InfoToolTip/InfoToolTip';

const PopUpAssignmentList = (props) => {
  const dispatch = useDispatch();
  const { popupMode } = useSelector((state) => state.PopUpReducer);
  //   const {} = useSelector
  const {
    headerPanelColour,
    headerOne,
    headerOneBadgeOne,
    headerOneBadgeTwo,
    mode,
    isActive,
    basicInfo,
    nextPopUpValue,
    inputHeader,
    inputHeaderBadge,
    inputHeaderBadgeTwo,
    infoMsg = 'select one or more'
  } = props;

  // const { assignmentListData, selectedTagsArray, unselectedTagsArray } = useSelector((state) => state.SignOnReducer);
  const {
    reviewListDistinctData,
    middlePaneSelectedValue,
    isSelectActive,
    selectedTagsArray,
    unselectedTagsArray,
    selectedAssociateInfo,
    selectedMineTotalAmount
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const handleClick = () => {
    // dispatch({ type: typeOfSetObject, payload: { ...localObject } });
    if (mode === 'revise') {
      dispatch({ type: POPUP_CLOSE });
    } else {
      dispatch({ type: SET_NEXT_POPUP, payload: { isPopUpValue: nextPopUpValue } });
    }
  };

  const onClickCheckBoxSelection = (selectedTagsArray, unselectedTagsArray, event, dispatch) => {
    let id = event.target.id;
    let checkedArr = [...selectedTagsArray];
    let unCheckArr = [...unselectedTagsArray];
    if (checkedArr.includes(id)) {
      unCheckArr.push(id);
    }
    dispatch({
      type: 'SET_DISPLAY_TWO_SINGLE_STATE',
      payload: { stateName: 'selectedTagsArray', value: [id] }
    });
    dispatch({
      type: 'SET_DISPLAY_TWO_SINGLE_STATE',
      payload: { stateName: 'unselectedTagsArray', value: unCheckArr }
    });
  };

  return (
    <div>
      <PopUp isActive={isActive}>
        <PopupHeader
          headerPanelColour={headerPanelColour}
          headerOne={headerOne}
          headerOneBadgeOne={headerOneBadgeOne}
          headerOneBadgeTwo={headerOneBadgeTwo}
          onClick={handleClick}
          mode={mode}
        />

        <DialogContent
          className={['popupContent', 'fixed10PadDim', 'revisePopupContent'].join(' ')}
        >
          <div className={'fitContent'}>
            <div className={['PopupFormBox', 'labelPopupBox', 'popupMinHei'].join(' ')}>
              <InputLabel htmlFor="name-input" className={'textForLabelPopup'}>
                <Fragment>
                  {inputHeader}&nbsp;
                  {inputHeaderBadge ? (
                    <span className={'headerBadge'}>{inputHeaderBadge}</span>
                  ) : null}
                  &nbsp;
                  {inputHeaderBadgeTwo ? (
                    <span className={'headerBadge'}>{inputHeaderBadgeTwo}</span>
                  ) : null}
                </Fragment>
              </InputLabel>
              <div className={'infoSymbol'}></div>
              <div className={'infoSymbol'}>
                <InfoToolTip message={infoMsg} />
              </div>
            </div>
          </div>

          {reviewListDistinctData.length > 0 &&
            reviewListDistinctData.map((data) => (
              <ReviewList
                textOne={data.informationBasic.assignmentName}
                textTwo={data.informationBasic.assignmentDescription}
                isFlagActive={false}
                status={`₹ ${
                  data?.informationSetup?.assignmentShareFee
                    ? parseFloat(
                        data?.informationSetup?.assignmentShareFee.toFixed()
                      ).toLocaleString('en-IN')
                    : 0
                }`}
                isSelectActive={'multiple'}
                isAlertActive={false}
                key={data.id}
                tag={data.id}
                isSelected={selectedTagsArray.includes(data.id)}
                className={'reviewListFixedWidth'}
                onClickCheckBox={(event) => {
                  onClickCheckBoxSelection(selectedTagsArray, unselectedTagsArray, event, dispatch);
                }}
              ></ReviewList>
            ))}
        </DialogContent>
      </PopUp>
    </div>
  );
};

PopUpAssignmentList.propTypes = {
  className: PropTypes.string,
  headerPanelColour: PropTypes.oneOf([
    'displayPaneLeft',
    'displayPaneCentre',
    'displayPaneRight',
    'genericOne',
    'genericTwo'
  ]),
  headerOne: PropTypes.string,
  headerOneBadgeOne: PropTypes.string,
  headerOneBadgeTwo: PropTypes.string,
  headerOneBadgeThree: PropTypes.string,
  isActive: PropTypes.bool
};

export default PopUpAssignmentList;
