import React from 'react';
import PropTypes from 'prop-types';
import './InputField.css';
import { Input, InputLabel, FormControl, FormHelperText, IconButton } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Visibility, VisibilityOff } from '@material-ui/icons';
export const InputField = (props) => {
  const {
    id,
    label,
    type = 'text',
    errorMsg = '',
    onClick,
    value,
    labelBadgeOne,
    isRequired = true,
    autoFocus = false,
    classNames = '',
    isErrorMsg = true,
    currencySymbol = '',
    issetTime = false,
    isEndAdornment = false,
    minValue = 0.0,
    endText = ''
  } = props;
  let attributes = {
    ...(type === 'datetime-local'
      ? { min: new Date().toISOString().slice(0, 16) }
      : type === 'number' &&
      label === 'fee' && {
        min: 0.0,
        max: '9999999999',
        // step: 0.01,
        onKeyDown: (e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
      })
  };

  const [values, setValues] = React.useState({
    showPassword: false
  });
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="popup-form-box">
      <FormControl style={{ width: '100%' }}>
        <InputLabel
          htmlFor={id}
          style={{ fontSize: '1.6rem' }}
          {...(type === 'datetime-local' && { shrink: true })}
        >
          {label} &nbsp;
          {labelBadgeOne ? <span className={'labelheaderBadge1'}>{labelBadgeOne}</span> : null}
        </InputLabel>

        {currencySymbol === '' ? (
          issetTime ? (
            <Input
              type={type}
              id={id}
              name={id}
              value={issetTime ? (value === 0 ? '00:00:00' : value) : value}
              onChange={onClick}
              error={value < 0}
              autoComplete="off"
              autoFocus={autoFocus}
              className={['inputFields', classNames].join(' ')}
              inputProps={attributes}
            //multiline={isMultiLine}
            //rows={5}
            />
          ) : (
            <Input
              type={type === 'password' ? (values.showPassword ? 'text' : 'password') : type}
              id={id}
              name={id}
              value={issetTime ? (value === 0 ? '00:00:00' : value) : value}
              onChange={onClick}
              autoComplete="off"
              autoFocus={autoFocus}
              error={
                (type !== 'text' && value < 0) ||
                (type === 'tel' && value !== '' && !/^\d{10}$/.test(value)) ||
                (label === 'fee' && parseFloat(value) < 0)
              }
              className={['inputFields', classNames].join(' ')}
              inputProps={attributes}
              endAdornment={
                type === 'password' && (
                  <InputAdornment position="end">
                    {value !== '' && (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    )}
                  </InputAdornment>
                )
              }
            //multiline={isMultiLine}
            //rows={5}
            />
          )
        ) : (
          <Input
            type={type}
            id={id}
            name={id}
            inputProps={attributes}
            value={value}
            onChange={onClick}
            autoComplete="off"
            autoFocus={autoFocus}
            error={value < 0}
            className={['inputFields', classNames].join(' ')}
            startAdornment={
              <InputAdornment
                style={{ paddingRight: '5px' }}
                className={'inputFieldsAdorment'}
                position="start"
              >
                {currencySymbol}
              </InputAdornment>
            }
          />
        )}

    
      </FormControl>
      {isRequired && (
        <FormHelperText
          className={[isErrorMsg ? 'helperText' : 'helperTextDefault', 'helptextmargin'].join(' ')}
        >
          <span>{errorMsg}</span>
        </FormHelperText>
      )}
    </div>
  );
};

InputField.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.oneOf(['text', 'password', 'datetime-local', 'date', 'tel', 'number'])
};

export default InputField;
