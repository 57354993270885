import { createStore, applyMiddleware } from 'redux';
// import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
// import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

import rootReducer from '../Reducers';
import sagas from '../sagas';

export const history = createBrowserHistory();

// const enhancers = [];

const sagaMiddleware = createSagaMiddleware({
  onError: (err) => {
    if (err.response && err.response.data) {
      const errorData = err.response.data;
      store.dispatch({ type: 'SET_ERROR_STATE', errorData });
    }
  }
});

// const middleware = [routerMiddleware(history), sagaMiddleware];

// const composedEnhancers = composeWithDevTools(applyMiddleware(...middleware), ...enhancers);



const countdownMiddleware = (store) => (next) => (action) => {
  if (action.type === 'START_COUNTDOWN') {
    // Clear any existing intervals
    if (store.getState().CountDownReducer.intervalId) {
      console.log(store.getState().CountDownReducer.intervalId, 'INTERVALID FROM MIDDLEWARe')
      clearInterval(store.getState().CountDownReducer.intervalId);
      store.dispatch ({ type: 'STOP_COUNTDOWN' });
      store.dispatch({ type: 'SET_INTERVAL_ID', payload: null });
    }

    // Start the countdown
    const intervalId = setInterval(() => {
      store.dispatch({
        type: 'UPDATE_COUNTDOWN',
      });

      const { hours, minutes, seconds } = store.getState().CountDownReducer;

      if (hours === 0 && minutes === 0 && seconds === 0) {
        clearInterval(intervalId);
        store.dispatch({type: 'SET_COMPLETED'});
      }
    }, 1000);

    // Store the intervalId in the state
    store.dispatch({ type: 'SET_INTERVAL_ID', payload: intervalId });
  }

  return next(action);
};


const store = createStore(rootReducer, applyMiddleware(sagaMiddleware, countdownMiddleware));

sagaMiddleware.run(sagas);

export default store;
