import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ASSESSEE_ALLOCATE_GRP_SAGA,
  ASSOCIATE_POPUP_CLOSE,
  FILTERMODE,
  FILTERMODE_ENABLE,
  GET_ASSESSEE_TYPE_REVIEW_LIST_SAGA,
  LOADER_START,
  POPUP_OPEN,
  SET_DISPLAY_TWO_SINGLE_STATE,
  SET_PAGE_COUNT,
  SET_POPUP_STATE,
  SET_REQUEST_OBJECT
} from '../actionType';
import FooterIconTwo from '../Molecules/FooterIcon/FooterIconTwo';
import { FilterList } from '@material-ui/icons';
import ReviewList from '../Molecules/ReviewList/ReviewList';
import { getPopUpOptionList, makeAssesseeTypeObj } from '../Actions/GenericActions';
import {
  ASSESSEE_NODE_ROLE_REVIEW_LIST_POPUP_OPTION,
  ASSESSEES_SUBMODULE_REVIEW_LIST_POPUP_OPTION
} from '../PopUpConfig';
import {
  assesseeRole,
  onClickCheckBoxSelection,
  onClickFlagSelection,
  setFlagedArray
} from '../Actions/AssesseeModuleAction';
import ListLoader from '../Atoms/ListLoader/ListLoader';
import ReviseIcon from '@material-ui/icons/RadioButtonChecked';
import Check from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
const AssesseeTypeReviewList = (props) => {
  const dispatch = useDispatch();
  // const { secondaryOptionCheckValue,  } = useSelector(
  //   (state) => state.AssesseeCreateReducer
  // );
  const { cardValue } = useSelector((state) => state.PopUpReducer);
  const {
    numberPage,
    countPage,
    scanCount,
    reviewListDistinctData,
    reviewListReqObj,
    middlePaneSelectedValue,
    selectedAssociateInfo,
    middlePaneHeader,
    middlePaneHeaderBadgeOne,
    middlePaneHeaderBadgeTwo,
    middlePaneHeaderBadgeThree,
    isSelectActive,
    selectedTagsArray,
    unselectedTagsArray,
    allocatedTagsArray,
    allocateStr,
    selectedFlagedArray,
    unselectedFlagedArray,
    flagedValue,
    signedAssesseePermission,
    isListLoading
  } = useSelector((state) => state.DisplayPaneTwoReducer);
  const { FilterModeEnable, FilterMode } = useSelector((state) => state.FilterReducer);
  // const { isPopUpValue, selectedTagValue } = useSelector((state) => state.PopUpReducer);
  // const [isFetching, setIsFetching] = useState(false);
  // useEffect(() => {
  //   document.getElementById('middleComponentId').addEventListener('scroll', handleScroll);
  // }, []);
  // const handleScroll = (event) => {
  //   var targetPt = event.target;
  //   if (
  //     Math.ceil(targetPt.scrollHeight - targetPt.scrollTop) !== targetPt.clientHeight ||
  //     isFetching
  //   )
  //     return;
  //   setIsFetching(true);
  //   console.log(isFetching);
  // };
  const fetchData = async () => {
    if (reviewListDistinctData.length < scanCount) {

      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isListLoading', value: true }
      })

      let obj = {
        ...reviewListReqObj.requestObject,
        numberPage: numberPage + 1
      };
      dispatch({
        type: GET_ASSESSEE_TYPE_REVIEW_LIST_SAGA,
        payload: {
          request: {
            module: 'Assessee-Type',
            action: 'Review-List',
            requestObject: obj
          },
          BadgeOne: middlePaneHeaderBadgeOne,
          BadgeTwo: middlePaneHeaderBadgeTwo,
          BadgeThree: middlePaneHeaderBadgeThree,
          isMiddlePaneList: true,
          isSelectActive,
          selectedTagsArray
        }
      });
      dispatch({ type: SET_PAGE_COUNT, payload: numberPage + 1 });
    }
  };
  // useEffect(() => {
  //   console.log(reviewListDistinctData);
  //   if (!isFetching) return;
  //   fetchMoreListItems();
  // }, [isFetching]);

  // const fetchMoreListItems = () => {
  //   fetchData();
  //   setIsFetching(false);
  // };
  useEffect(() => {
    setFlagedArray(reviewListDistinctData, 'assesseeTypeFlag', dispatch);
  }, [reviewListDistinctData]);
  const siftApiCall = (siftKey) => {
    let requestObect = makeAssesseeTypeObj(selectedAssociateInfo, siftKey, countPage, 0);
    dispatch({ type: LOADER_START });
    dispatch({ type: SET_REQUEST_OBJECT, payload: requestObect });
    dispatch({
      type: FILTERMODE,
      payload: { FilterMode: 'assesseesTypeDistinctactive' + siftKey }
    });

    dispatch({
      type: GET_ASSESSEE_TYPE_REVIEW_LIST_SAGA,
      payload: {
        request: requestObect,
        middlePaneHeader: middlePaneHeader,
        // BadgeOne: 'distinct',
        BadgeOne: middlePaneHeaderBadgeOne,
        BadgeTwo: middlePaneHeaderBadgeTwo === 'distinct' ? middlePaneHeaderBadgeTwo : siftKey,
        BadgeThree: middlePaneHeaderBadgeTwo === 'distinct' ? siftKey : middlePaneHeaderBadgeThree,
        isMiddlePaneList: true,
        isSelectActive: isSelectActive,
        selectedTagsArray: selectedTagsArray
      }
    });
    dispatch({ type: SET_PAGE_COUNT, payload: 0 });
    dispatch({ type: ASSOCIATE_POPUP_CLOSE });
    // document.getElementById('middleComponentId').scrollTop = '0px';
  };
  const onClickFooter = (e) => {
    let siftValue = e.currentTarget.getAttribute('data-value');
    dispatch({ type: FILTERMODE_ENABLE });
    if (siftValue === 'suspended' || siftValue === 'terminated') siftApiCall(siftValue);
    if (siftValue === 'bespoke' || siftValue === 'generic') siftApiCall(siftValue);

    if (siftValue === 'finish') {
      console.log('allocateStr', allocateStr);
      let distinctAllocateStr =
        allocateStr === 'assesseesdistinct'
          ? 'assesseeDistinct'
          : allocateStr === 'assesseesgroups'
            ? 'assesseeGroup'
            : allocateStr === 'assesseestypes'
              ? 'assesseeType'
              : allocateStr === 'assesseesroles'
                ? 'assesseeRole'
                : allocateStr === 'assesseesnodes'
                  ? 'assesseeNode'
                  : allocateStr === 'administratorsgroups'
                    ? 'assesseeGroup'
                    : allocateStr === 'administratorsdistinct'
                      ? 'assesseeDistinct'
                      : allocateStr === 'administratorstypes'
                        ? 'assesseeType'
                        : allocateStr === 'administratorsroles'
                          ? 'assesseeRole'
                          : allocateStr === 'administratorsnodes'
                            ? 'assesseeNode'
                            : '';

      if (distinctAllocateStr !== '' && selectedTagsArray.length !== 0) {
        if (
          distinctAllocateStr === 'assesseeDistinct' ||
          distinctAllocateStr === 'assesseeGroup' ||
          distinctAllocateStr === 'assesseeType' ||
          distinctAllocateStr === 'assesseeRole' ||
          distinctAllocateStr === 'assesseeNode'
        ) {
          let request = {
            module: 'Assessee-Distinct',
            action: FilterMode?.toLowerCase().includes('unallocate') ? 'unallocate' : 'Allocate',
            requestObject: {
              assesseeId: selectedAssociateInfo?.assesseeId,
              associateId:
                selectedAssociateInfo?.associate?.informationEngagement.associateTag
                  .associateTagPrimary,
              [FilterMode?.toLowerCase().includes('unallocate')
                ? 'assesseeDistinctUnallocate'
                : 'assesseeDistinctAllocate']: {
                [distinctAllocateStr]: allocatedTagsArray
              },
              [FilterMode?.toLowerCase().includes('unallocate')
                ? 'assesseeDistinctUnallocateInformation'
                : 'assesseeDistinctAllocateInformation']: {
                assesseeType: selectedTagsArray
              }
            }
          };
          dispatch({ type: LOADER_START });
          dispatch({
            type: ASSESSEE_ALLOCATE_GRP_SAGA,
            payload: { request: request }
          });
        }
      }
    }
    if (siftValue === 'cancel') {
      dispatch({
        type: SET_DISPLAY_TWO_SINGLE_STATE,
        payload: { stateName: 'isSelectActive', value: '' }
      });
      dispatch({
        type: FILTERMODE,
        payload: { FilterMode: '' }
      });
    }
  };
  /* for middle pane */
  const primaryIcon = [{ label: 'sift', onClick: onClickFooter, Icon: FilterList }];
  const secondaryIcon = [
    { label: 'suspended', onClick: onClickFooter, Icon: FilterList },
    { label: 'terminated', onClick: onClickFooter, Icon: FilterList }
  ];
  const secondaryOneIcon = [
    { label: 'bespoke', onClick: onClickFooter, Icon: FilterList },
    { label: 'generic', onClick: onClickFooter, Icon: FilterList }
    // { label: 'shared', onClick: onClickFooter, Icon: FilterList },
    // { label: 'unshared', onClick: onClickFooter, Icon: FilterList }
  ];

  let signedAssociateId =
    selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary;

  const openListPopup = (e) => {
    // console.log(e.currentTarget.getAttribute('tag'));
    let tempArr = [];
    let classification = e.currentTarget.getAttribute('data-classification');
    let isShared = e.currentTarget.getAttribute('data-shared');
    let status = e.currentTarget.getAttribute('status');
    let associateId = e.currentTarget.getAttribute('associateid');
    // console.log(cardValue, 'CARDVALUE')
    let data =
      cardValue === 'Card'
        ? ASSESSEES_SUBMODULE_REVIEW_LIST_POPUP_OPTION
        : ASSESSEE_NODE_ROLE_REVIEW_LIST_POPUP_OPTION;
    let popupContentArrValue = data.map((obj) =>
      obj.data === 'assessees'
        ? { ...obj, data: middlePaneHeader, dataValue: middlePaneHeader }
        : obj
    );
    // console.log(popupContentArrValue, 'ARRVAL')
    let assesseeDistinctPermission =
      signedAssesseePermission?.assesseeDistinctPermission?.assesseeAssesseeTypePermission;
    let popupContentArr = getPopUpOptionList(popupContentArrValue, assesseeDistinctPermission);
    // console.log(popupContentArr, classification, 'CLASSIFICATION')
    popupContentArr.map((element) => {
      if (classification === 'Bespoke' && element.data === 'share' && status === 'ACTIVE')
        tempArr.push({ ...element, disabled: true });
      else if (
        isShared === 'true' &&
        associateId !== signedAssociateId &&
        (element.data === 'archive' ||
          element.data === 'flag' ||
          element.data === 'delete' ||
          element.data === 'suspend' ||
          element.data === 'move' ||
          element.data === 'select' ||
          element.data === 'terminate')
      ) {
        tempArr.push({ ...element, disabled: true });
      } else if (
        (status === 'TERMINATED' || status === 'SUSPENDED' || status === 'ARCHIVED') &&
        (element.data === 'allocate' ||
          element.data === 'revise' ||
          element.data === 'select' ||
          element.data === 'share')
      ) {
        tempArr.push({ ...element, disabled: true });
      } else tempArr.push(element);
    });
    dispatch({
      type: SET_POPUP_STATE,
      payload: {
        popupHeaderOne: middlePaneHeader,
        popupHeaderOneBadgeOne: 'type',
        popupHeaderOneBadgeTwo: '',
        duplicateHeaderOneBadgeTwo: middlePaneHeaderBadgeTwo,
        isPopUpValue: '',
        popupOpenType: 'primary',
        popupContentArrValue: tempArr, //tempArr, //popupContentArrValue,
        originPrimaryArrOprion: tempArr,
        selectedTagValue: e.currentTarget.getAttribute('tag'),
        selectedTagStatus: status,
        selectedTagGroupId: e.currentTarget.getAttribute('data-value'),
        selectedTagShared: e.currentTarget.getAttribute('data-shared') === 'true' ? true : false,
        isFlaged: e.currentTarget.getAttribute('data-flag') === 'true' ? true : false
      }
    });
    dispatch({
      type: SET_DISPLAY_TWO_SINGLE_STATE,
      payload: {
        stateName: 'middlePaneListPopupOptions',
        value: tempArr //tempArr //popupContentArrValue
      }
    });
    dispatch({ type: POPUP_OPEN, payload: 'middlePaneListPopup' });
  };

  // infinite scroll
  const [hasMore, setHasMore] = useState(true);
  let elementRef = useRef(null);

  const onIntersection = (entries) => {
    const firstEntry = entries[0];
    reviewListDistinctData.length >= scanCount ? setHasMore(false) : setHasMore(true)
    if (firstEntry.isIntersecting && hasMore && !isListLoading) {
      fetchData();
    }
  };

  useEffect(() => {
    // console.log('####MYREF######', elementRef)
    const observer = new IntersectionObserver(onIntersection, { threshold: 0.90 });

    if (observer && elementRef.current) {
      // console.log('here at observer...', elementRef.current)
      observer.observe(elementRef.current);
    }

    return () => {
      if (observer) observer.disconnect();
    };
  }, [isListLoading, hasMore, reviewListDistinctData, selectedTagsArray, unselectedTagsArray, isSelectActive]);

  // infinite scroll end

  const associateSeftId =
    selectedAssociateInfo?.associate?.informationEngagement.associateTag.associateTagPrimary;

  return (
    <div>
      {reviewListDistinctData &&
        reviewListDistinctData.map((item, index) => {
          return (
            <div className="containerPadding" key={index} ref={elementRef}>
              <ReviewList
                className=""
                id={index}
                tag={item.id}
                associateId={item?.associateId}
                isSelectedReviewList={middlePaneSelectedValue === item.id}
                // status={associateSeftId === item.associateId ? 'bespoke' : 'generic'}
                // actualStatus={item.assesseeTypeShared ? 'SHARED' : 'UNSHARED'}
                // dataValue={item.informationAllocation.assesseeTypeGroup}
                //status={item.informationEngagement.assesseeTypeStatus}
                status={
                  FilterMode === 'assesseesTypeDistinctactive' ||
                    FilterMode === 'assesseesTypeDistinctactivegeneric' ||
                    FilterMode === 'assesseesTypeDistinctbespoke' ||
                    FilterMode === 'assesseesTypeDistinctgeneric' ||
                    FilterMode === 'assesseesTypeDistinctactivebespoke'
                    ? item.informationSetup?.assesseeTypeClassification
                      ?.assesseeTypeClassificationPrimary
                    : item.informationEngagement.assesseeTypeStatus
                }
                actualStatus={item.informationEngagement.assesseeTypeStatus}
                //shared={item.assesseeTypeShared ? 'SHARED' : 'UNSHARED'}
                shared={
                  item.informationSetup?.assesseeTypeClassification
                    ?.assesseeTypeClassificationPrimary
                }
                // actualStatus={item.informationEngagement.assesseeTypeStatus}
                // textOne={item.informationBasic.assesseeTypeName}
                textOne={assesseeRole(item.informationBasic.assesseeTypeName)}
                textTwo={item.informationBasic.assesseeTypeDescription}
                isTooltipActive={false}
                onClickEvent={openListPopup}
                isSelectActive={isSelectActive}
                isSelected={selectedTagsArray.includes(item.id)}
                onClickCheckBox={(event) => {
                  !isListLoading && onClickCheckBoxSelection(selectedTagsArray, unselectedTagsArray, event, dispatch);
                }}
                // disableonClickCheckBox={item.associateId !== signedAssociateId ? true : false}
                isShared={item?.assesseeTypeShared}
                isFlagActive={selectedFlagedArray.includes(item.id)}
                flagedValue={flagedValue}
                onClickAddFladed={(event) => {
                  onClickFlagSelection(selectedFlagedArray, unselectedFlagedArray, event, dispatch);
                }}
              />
            </div>
          );
        })}
      {isListLoading ? <div> <ListLoader /> </div> : <div style={{ height: "60px", width: "100%" }}></div>}
      {(FilterMode === 'assesseesTypeDistinctinactive' ||
        FilterMode === 'assesseesTypeDistinctactiveterminated' ||
        FilterMode === 'assesseesTypeDistinctactivesuspended') && (
          <FooterIconTwo
            FilterModeEnable={FilterModeEnable}
            FilterMode={FilterMode}
            onClick={onClickFooter}
            primaryIcon={primaryIcon}
            secondaryIcon={secondaryIcon}
          />
        )}
      {(FilterMode === 'assesseesTypeDistinctactive' ||
        FilterMode === 'assesseesTypeDistinctactivebespoke' ||
        FilterMode === 'assesseesTypeDistinctbespoke' ||
        FilterMode === 'assesseesTypeDistinctgeneric' ||
        FilterMode === 'assesseesTypeDistinctactivegeneric') && (
          <FooterIconTwo
            FilterModeEnable={FilterModeEnable}
            FilterMode={FilterMode}
            onClick={onClickFooter}
            primaryIcon={primaryIcon}
            secondaryIcon={secondaryOneIcon}
          />
        )}
      {(FilterMode === 'assesseeAllocateToType' || FilterMode === 'assesseeUnAllocateToType') && (
        <FooterIconTwo
          FilterModeEnable={FilterModeEnable}
          FilterMode={FilterMode}
          onClick={onClickFooter}
          primaryIcon={[
            FilterMode === 'assesseeAllocateToType'
              ? { label: 'allocate', onClick: onClickFooter, Icon: ReviseIcon }
              : { label: 'unallocate', onClick: onClickFooter, Icon: ReviseIcon }
          ]}
          secondaryIcon={[
            { label: 'cancel', onClick: onClickFooter, Icon: ClearIcon },
            { label: 'finish', onClick: onClickFooter, Icon: Check }
          ]}
        />
      )}
    </div>
  );
};
export default AssesseeTypeReviewList;
